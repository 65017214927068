/* @flow */
/* eslint-disable react/no-multi-comp */

import React from 'react'
import moize from 'moize'
import type { AccountingDocument } from 'types'
import { type WithTranslateProps } from 'wrappers'
import { formatToDateString } from 'utils/formatters'
import { Column } from 'modules/data-grid-next/components'
import type { Direction } from 'modules/data-grid-next/types'
import EmailIcon from 'components/svg-icons/communication/email'
import IconButton from 'components/icon-button'
import { colors } from 'variables'
import { DATA_GRID_CACHE_SIZE } from 'trivi-constants'

export type Props = {|
	width?: number | string,
	defaultSortDirection?: Direction,
	onEmailClick?: (accountingDocument: AccountingDocument) => void,
	...WithTranslateProps,
|}

const styles = {
	date: {
		display: 'flex',
	},
	emailButton: {
		marginLeft: 3,
	},
}

export default function getUploadedDateColumn(props: Props) {
	function columnRenderer(value: string, row: AccountingDocument) {
		let email = row._sourceData && row._sourceData.email

		function clickHandler() {
			props.onEmailClick && props.onEmailClick(row)
		}

		return (
			<div style={styles.date}>
				{formatToDateString(value)}
				{email && (
					<span style={styles.emailButton}>
						<IconButton
							tooltip={props.t('grid.action.showEmail')}
							size={20}
							onClick={clickHandler}
							hoverColor={colors.black}
							autoTestId="uploaded-date-show-email"
						>
							<EmailIcon size={14} />
						</IconButton>
					</span>
				)}
			</div>
		)
	}

	const columnRendererCashed = moize(columnRenderer, { maxSize: DATA_GRID_CACHE_SIZE })

	return (
		<Column
			title={props.t('accountingDocument.columns.uploadedDate')}
			width={props.width}
			columnId="issueDate"
			key="uploadedDate"
			render={columnRendererCashed}
			sortable
			defaultSortDirection={props.defaultSortDirection || undefined}
		/>
	)
}
