/* @flow */
/** @jsx jsx */

import { Component } from 'react'
import memoize from 'memoize-one'
import { jsx } from '@emotion/core'
import Avatar from 'components/avatar'
import ContentAdd from 'components/svg-icons/content/add'
import { colors } from 'variables'

export type Props = {|
	disabled?: boolean,
	style?: Object,
	labelText: ?string,
	onClick?: () => void,
|}

class AddEntity extends Component<Props> {
	getStyles = memoize((disabled: ?boolean) => ({
		root: {
			borderStyle: 'dashed',
			borderWidth: 1,
			borderColor: colors.gray400,
			borderRadius: 3,
			background: 'rgba(225, 225, 225, 0)',
			fontSize: 16,
			color: colors.black,
			lineHeight: '20px',
			textAlign: 'center',
			cursor: disabled ? 'default' : 'pointer',
			pointerEvents: disabled ? 'none' : 'all',
			transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1)',
			minWidth: 100,
			minHeight: 80,
			position: 'relative',
			width: '100%',
			'&:hover': {
				background: 'rgba(225, 225, 225, 0.2)',
			},
		},
		content: {
			width: '80%',
			position: 'absolute',
			top: '50%',
			right: 'auto',
			bottom: 'auto',
			left: '50%',
			transform: 'translate(-50%, -50%)',
			'&:hover': {
				textDecoration: 'underline',
				color: colors.black,
			},
		},
		avatar: {
			marginBottom: 10,
		},
	}))

	onClick = () => {
		this.props.onClick && !this.props.disabled && this.props.onClick()
	}

	render() {
		const { disabled } = this.props
		const style = this.getStyles(disabled)

		return (
			<div style={this.props.style} css={style.root} onClick={this.onClick}>
				<div css={style.content}>
					<div css={style.avatar}>
						<Avatar
							size={32}
							backgroundColor={disabled ? colors.disabled : colors.orange}
							hoverColor={colors.white}
							icon={<ContentAdd />}
						/>
					</div>
					{this.props.labelText}
				</div>
			</div>
		)
	}
}

export default AddEntity
