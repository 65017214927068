/* @flow */

import React, { Component } from 'react'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import { getUrlFromBlob, getMimeTypeBlob, handleDownloadFile, isObscureImageType } from 'modules/file/domain'
import { getFileData } from 'modules/file/actions'
import DownloadButton from 'components/image-viewer/download-button'
import ImageViewer from 'components/image-viewer'
import Button from 'components/button'
import Loader from 'components/loader'
import styles from './file-viewer.css'
import { lookup } from 'mime-types'

const LOADER_HEIGHT = 40

type Props = {|
	...WithTranslateProps,
	downloadable?: boolean,
	tokenDownload?: boolean,
	fileId: string,
	isInternal?: boolean,
	readonly?: boolean,
	/**
	 * Stretches image to full width and set its height to auto
	 */
	stretch?: boolean,
	/**
	 * Set height according to content (works for images only)
	 */
	autoHeight?: boolean,
|}

type State = {|
	fileUrl: ?string,
	fileName: ?string,
	contentType: ?string,
	loading: boolean,
|}

class FileViewer extends Component<Props, State> {
	state = {
		fileUrl: null,
		fileName: null,
		contentType: null,
		loading: false,
	}

	viewer: ?HTMLElement = null

	componentDidMount() {
		this.getFile(this.props.fileId, this.props.isInternal)
	}

	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		if (this.props.fileId !== nextProps.fileId) {
			this.getFile(nextProps.fileId, nextProps.isInternal)
		}
	}

	async getFile(fileId: string, isInternal?: boolean) {
		this.setState({ loading: true })

		const fileData = await getFileData(fileId, { useDownloadRequest: !!isInternal })
		if (!fileData) return this.setState({ loading: false })

		this.setState({
			fileUrl: getUrlFromBlob(fileData.blob),
			fileName: fileData.fileName,
			contentType: (fileData.fileName && lookup(fileData.fileName)) || getMimeTypeBlob(fileData.blob),
			loading: false,
		})
	}

	handleDownloadClick = async () => {
		this.props.fileId && this.state.fileName && this.downloadFile()
	}

	downloadFile = async () => {
		this.props.fileId &&
			this.state.fileName &&
			(await handleDownloadFile(this.props.fileId, this.state.fileName, true, this.props.tokenDownload))
	}

	renderFileDownload = () => {
		return (
			<div className={styles.noPreview}>
				<div className={styles.noPreviewHeadline}>{this.props.t('fileViewer.noPreviewAvailable')}</div>
				{this.props.downloadable && (
					<div className={styles.download}>
						<Button
							autoTestId="file-viewer-download"
							onClick={this.handleDownloadClick}
							labelText={this.props.t('fileViewer.download')}
						/>
					</div>
				)}
			</div>
		)
	}

	renderPDFContent = (fileUrl: string, fileName: ?string, contentType: ?string) => {
		return (
			<>
				<DownloadButton downloadUrl={fileUrl} fileName={fileName} />
				<object
					data={fileUrl}
					width="100%"
					height="100%"
					style={{
						display: 'block',
						minHeight: this.state.loading ? LOADER_HEIGHT * 2 : 'unset',
						objectFit: 'contain',
						height: 'calc(100% - 40px)',
					}}
					type={contentType}
				>
					<param name="wmode" value="transparent" />
				</object>
			</>
		)
	}

	renderImageContent = (fileUrl: ?string, fileName: ?string, readonly?: boolean) => {
		return (
			fileUrl && (
				<ImageViewer
					style={{
						minHeight: this.state.loading ? LOADER_HEIGHT * 2 : this.viewer ? this.viewer.offsetHeight : 'unset',
					}}
					src={fileUrl}
					readonly={readonly || this.props.readonly}
					stretch={this.props.stretch}
					fileName={fileName}
				/>
			)
		)
	}

	renderFilePreview = (fileUrl: ?string, fileName: ?string, contentType: ?string, readonly?: boolean) => {
		if (!fileUrl || !contentType) return null

		switch (contentType) {
			case 'image/jpeg':
			case 'image/jpg':
			case 'image/png':
			case 'image/bmp':
			case 'image/gif':
			case 'image/pjpeg':
				return this.renderImageContent(fileUrl, fileName, readonly)
			case 'application/pdf':
				return this.renderPDFContent(fileUrl, fileName, contentType)
			default:
				if (isObscureImageType(fileName, contentType)) {
					this.renderImageContent(fileUrl, fileName, readonly)
				} else {
					return this.renderFileDownload()
				}
		}
	}

	bindViewer = (element: ?HTMLElement) => {
		this.viewer = element
	}

	render() {
		return (
			<div className={this.props.autoHeight ? styles.autoHeight : styles.normal}>
				<div
					ref={this.bindViewer}
					className={this.state.loading ? styles.loadingViewer : styles.viewer}
					style={{
						height: this.props.autoHeight ? '100%' : 'calc(100vh - 40px)',
						overflowY: this.props.autoHeight ? 'auto' : 'initial',
						minHeight: this.state.loading ? LOADER_HEIGHT * 2 : 'unset',
					}}
				>
					{this.renderFilePreview(this.state.fileUrl, this.state.fileName, this.state.contentType, this.props.readonly)}
					<Loader visible={this.state.loading} />
				</div>
			</div>
		)
	}
}

export default withTranslate(FileViewer)
