import React from 'react'
import pure from 'recompose/pure'
import SvgIcon from 'components/SvgIcon'

let Tasks = props => (
	<SvgIcon {...props}>
		<defs>
			<polygon id="path-1" points="4 20.8142178 19.4517875 20.8142178 19.4517875 3 4 3" />
		</defs>
		<g id="Page-1" stroke="none" strokeWidth="1" fillRule="evenodd">
			<g id="Apple-TV">
				<g id="Group">
					<g id="tasks">
						<path
							d="M7.40854789,6.59341829 C6.50908232,6.59341829 5.77759995,7.32490067 5.77759995,8.22436624 L5.77759995,17.4065588 C5.77759995,18.3051355 6.50908232,19.0366179 7.40854789,19.0366179 L16.0432396,19.0366179 C16.9427052,19.0366179 17.6741876,18.3051355 17.6741876,17.4065588 L17.6741876,8.22436624 C17.6741876,7.32490067 16.9427052,6.59341829 16.0432396,6.59341829 L7.40854789,6.59341829 Z M16.0432396,20.8142178 L7.40854789,20.8142178 C5.52873595,20.8142178 4,19.2854819 4,17.4065588 L4,8.22436624 C4,6.3445543 5.52873595,4.81581834 7.40854789,4.81581834 L16.0432396,4.81581834 C17.9230516,4.81581834 19.4517875,6.3445543 19.4517875,8.22436624 L19.4517875,17.4065588 C19.4517875,19.2854819 17.9230516,20.8142178 16.0432396,20.8142178 Z"
							id="Fill-1"
						/>
						<g id="Clip-4" />
						<polygon id="Fill-3" points="9.16748304 5.10912233 14.2834157 5.10912233 14.2834157 3 9.16748304 3" />
						<polygon
							id="Fill-5"
							points="8.08225827 7.64842386 15.3695292 7.64842386 15.3695292 5.53930152 8.08225827 5.53930152"
						/>
						<polygon
							id="Fill-6"
							points="11.470186 15.7669894 8.4011597 12.6988519 9.65792287 11.4420888 11.470186 13.2534631 14.7409699 9.98267922 15.9977331 11.2394424"
						/>
					</g>
				</g>
			</g>
		</g>
	</SvgIcon>
)

Tasks = pure(Tasks)

export default Tasks
