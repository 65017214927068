/* @flow */

import { connect } from 'react-redux'
import type { CommonAction, Dispatch } from 'types'
import { hotkeyEvent } from '../actions'
import AppHotKeys, { type HandlerMapProps } from '../components/app-hotkeys'
import { main as hotkeyMap } from '../constants/hotkeys'
import type { HotKeyHandler, HotKeyMap } from '../types'

type StateProps = {|
	hotkeyMap: HotKeyMap,
|}

const mapStateToProps = (): StateProps => {
	return {
		hotkeyMap,
	}
}

type DispatchProps = HandlerMapProps

const mapDispatchToProps = (dispatch: Dispatch<CommonAction>): DispatchProps => {
	return (
		Object.keys(hotkeyMap)
			.map((hotkey: string) => ({
				hotkey,
				handler: (event: SyntheticKeyboardEvent<HTMLElement>, key: string) => {
					dispatch(hotkeyEvent(key, event))
				},
			}))
			// $FlowFixMe - prosím o pomoc s typováním
			.reduce((acc: {| [string]: HotKeyHandler |}, current: { hotkey: string, handler: HotKeyHandler }) => {
				acc[current.hotkey] = current.handler
				return acc
			}, {})
	)
}

export default connect(mapStateToProps, mapDispatchToProps)(AppHotKeys)
