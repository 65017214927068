/* @flow */

import React, { Component } from 'react'

import type { SearchFilter } from 'types'
import UserPicker from 'modules/user/containers/user-picker'

export type Props = {
	className?: string,
	key?: string,
	labelText?: string,
	name: string,
	filter?: SearchFilter,
	onChange: (filter: SearchFilter) => void,
	style?: {},
}

export default class FilterUser extends Component<Props> {
	static defaultProps = {
		onChange: () => {},
	}

	handleOwnerUserChange = (value: ?string): void => {
		let filter: SearchFilter = {
			field: this.props.name,
		}

		if (value !== null) {
			filter.value = value
		}

		this.props.onChange(filter)
	}

	render() {
		let { filter } = this.props

		return (
			<div className={this.props.className}>
				<UserPicker
					isClearable
					showMe
					labelText={this.props.labelText}
					onChange={this.handleOwnerUserChange}
					value={filter && filter.value ? filter.value.toString() : undefined}
					compact
				/>
			</div>
		)
	}
}
