// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1h0EYxmSmH5-ZOBtOWdp9z {\n\tdisplay: inline-block;\n}\n\n._25APe_2cSOheP-iisBi_Jz {\n  font-weight: normal;\n  transform: rotate(-45deg);\n  width: 20px;\n  height: 20px;\n}\n.Wu_ToafaZXDhDGgLHygTH {\n\tmin-width: 80px;\n\tmax-width: 380px;\n\twidth: auto;\n}\n\n._3ZEDllyVRP4eXobOW0CnfZ {\n\tpadding: 8px 0;\n}\n", "",{"version":3,"sources":["webpack://./modules/task/components/task-list-attachments.css"],"names":[],"mappings":"AAAA;CACC,qBAAA;AACD;;AAEA;EACE,mBAAA;EACA,yBAAA;EACA,WAAA;EACA,YAAA;AACF;AACA;CACC,eAAA;CACA,gBAAA;CACA,WAAA;AACD;;AAEA;CACC,cAAA;AACD","sourcesContent":[".root {\n\tdisplay: inline-block;\n}\n\n.attachmentIcon {\n  font-weight: normal;\n  transform: rotate(-45deg);\n  width: 20px;\n  height: 20px;\n}\n.popover {\n\tmin-width: 80px;\n\tmax-width: 380px;\n\twidth: auto;\n}\n\n.children {\n\tpadding: 8px 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "_1h0EYxmSmH5-ZOBtOWdp9z",
	"attachmentIcon": "_25APe_2cSOheP-iisBi_Jz",
	"popover": "Wu_ToafaZXDhDGgLHygTH",
	"children": "_3ZEDllyVRP4eXobOW0CnfZ"
};
export default ___CSS_LOADER_EXPORT___;
