//@flow
import type { Group } from 'types'
import React, { Component } from 'react'
import GroupEdit from './group-edit'
import { emptyGroup } from 'modules/groups'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import Delete from 'components/svg-icons/action/delete'
import Edit from 'components/svg-icons/image/edit'
import DeleteDialog from 'components/delete-dialog'
import Button from 'components/button'
import Tooltip from 'components/tooltip'
import Table from 'components/table/table'
import TableBody from 'components/table/table-body'
import TableRow from 'components/table/table-row'
import TableCell from 'components/table/table-cell'
import TableHeader from 'components/table/table-header'
import TableHeaderCell from 'components/table/table-header-cell'
import IconMenu from 'components/icon-menu'
import MenuItem from 'components/menu-item'
import styles from '../settings.css'

type Props = {|
	...WithTranslateProps,
	canSeeNonDemoFeatures: boolean,
	groups: ?Array<{ group: Group, userCount: number }>,
	createGroup?: (group: Group) => void,
	removeGroup?: (group: Group) => void,
	updateGroup?: (group: Group) => void,
|}

type State = {|
	editedGroup: ?Group,
|}

class GroupList extends Component<Props, State> {
	state: State = {
		editedGroup: null,
	}

	hadleNewGroup = () => {
		if (!this.props.canSeeNonDemoFeatures) {
			return
		}
		this.setState({
			editedGroup: emptyGroup(),
		})
	}

	handleGroupSave = (group: Group) => {
		const { createGroup, updateGroup } = this.props

		if (!group.id) {
			if (createGroup != null) createGroup(group)
		} else {
			if (updateGroup != null) updateGroup(group)
		}
	}

	createRenderGroup = (length: number) => {
		const { removeGroup, updateGroup, t } = this.props

		return (item: { group: Group, userCount: number }, index: number) => {
			const removeHandler = () => {
				if (removeGroup) {
					DeleteDialog()
						.then(() => {
							removeGroup(item.group)
						})
						.catch(() => {})
				}
			}

			const updateHandler = () => {
				updateGroup && this.setState({ editedGroup: item.group })
			}

			return (
				<TableRow key={item.group.id || item.group.name || `${index}|${length}`}>
					<TableCell>{item.group.name}</TableCell>
					<TableCell>{item.userCount}</TableCell>
					<TableCell>
						<Tooltip
							inline
							label={(!this.props.canSeeNonDemoFeatures && t('settings.groups.demoUserCantEdit')) || null}
						>
							<IconMenu autoTestId="settings-group-list-actions" disabled={!this.props.canSeeNonDemoFeatures} context>
								<MenuItem
									key={'edit'}
									primaryText={t('grid.action.edit')}
									icon={<Edit />}
									onClick={updateHandler} //eslint-disable-line react/jsx-no-bind
									tall
								/>
								<MenuItem
									key={'remove'}
									primaryText={t('grid.action.delete')}
									icon={<Delete />}
									onClick={removeHandler} //eslint-disable-line react/jsx-no-bind
									tall
								/>
							</IconMenu>
						</Tooltip>
					</TableCell>
				</TableRow>
			)
		}
	}

	renderGroups() {
		const { groups, t } = this.props

		if (groups && groups.length) {
			return (
				<Table>
					<TableHeader>
						<TableRow>
							<TableHeaderCell>{t('settings.groups.name')}</TableHeaderCell>
							<TableHeaderCell width={150}>{t('settings.groups.count')}</TableHeaderCell>
							<TableHeaderCell width={35} />
						</TableRow>
					</TableHeader>
					<TableBody>{groups.map(this.createRenderGroup(groups.length))}</TableBody>
				</Table>
			)
		} else {
			return null
		}
	}

	onGroupClose = () => {
		this.setState({
			editedGroup: null,
		})
	}

	render() {
		const { t } = this.props
		return (
			<div>
				<div className={styles.addButton}>
					<Tooltip inline label={(!this.props.canSeeNonDemoFeatures && t('settings.groups.demoUserCantAdd')) || null}>
						<Button
							primary
							labelText={t('settings.groups.addGroupLabel')}
							onClick={this.hadleNewGroup}
							disabled={!this.props.canSeeNonDemoFeatures}
							autoTestId="settings-group-list-add"
						/>
					</Tooltip>
				</div>
				{this.renderGroups()}
				<GroupEdit
					open={this.state.editedGroup != null}
					onClose={this.onGroupClose}
					value={this.state.editedGroup}
					onSave={this.handleGroupSave}
				/>
			</div>
		)
	}
}

export default withTranslate(GroupList)
