// @flow
import React, { Component } from 'react'
import { Tooltip, TriviLink } from 'components'
import type {
	AccountingDocumentDirection,
	Filter,
	OrganizationStatisticsKeysAccountingDocumentsSimpleStatistics,
	OrganizationStatisticsKeysCashRegister,
	OrganizationStatisticsKeysCashRegisters,
	SearchFilter,
} from 'types'
import styles from './extracted-documents-direction-tooltip.css'
import { withTranslate, type WithTranslateProps } from 'wrappers'

const TRANSLATION_PREFIX = 'internal.widget.organizationStatistics.cashRegistersTooltip.'

type Props = {|
	renderValue: ?number,
	organizationId: ?string,
	receivedValue: ?number,
	issuedValue: ?number,
	filter: Filter,
	onFilterChange: (organizationID: string, direction: AccountingDocumentDirection, filter: Filter) => Promise<void>,
	cashRegisters?: OrganizationStatisticsKeysCashRegisters,
	onRedirect: (organizationId: string, cashRegisterId: string, filter: Filter) => Promise<void>,
	...WithTranslateProps,
|}

class ExtractedDocumentsDirectionTooltip extends Component<Props> {
	getDirectionHandler = (direction: AccountingDocumentDirection) => () => {
		const { organizationId, onFilterChange, filter } = this.props
		organizationId && onFilterChange(organizationId, direction, filter)
	}

	getCountColumnHandler = (filter: SearchFilter, cashRegisterId?: string) => () => {
		const { onRedirect, organizationId } = this.props
		cashRegisterId && organizationId && onRedirect(organizationId, cashRegisterId, [filter])
	}

	renderCountColumn(value?: OrganizationStatisticsKeysAccountingDocumentsSimpleStatistics, clickHandler: () => void) {
		return (
			<td>
				<TriviLink onClick={clickHandler}>{value && value.count ? value.count : 0}</TriviLink>
			</td>
		)
	}

	renderCashRegisterRow = (cashRegister: OrganizationStatisticsKeysCashRegister) => {
		const { extractedDrafts } = cashRegister.accountingDocuments || {}
		return (
			<tr key={cashRegister.id}>
				<td>{cashRegister.name}</td>
				{this.renderCountColumn(
					extractedDrafts,
					this.getCountColumnHandler(
						{
							field: 'state',
							value: 'Extracted',
						},
						cashRegister.id,
					),
				)}
			</tr>
		)
	}

	renderLabel = () => {
		const { receivedValue, issuedValue, cashRegisters: source, t } = this.props
		const cashRegisters = source && source.cashRegisters ? source.cashRegisters : []
		return (
			<div>
				<table className={styles.tooltipTable}>
					<tbody>
						<tr>
							<th>
								<div onClick={this.getDirectionHandler('received')} className={styles.link}>
									{t('internal.widget.organizationStatistics.received')}
								</div>
							</th>
							<th>
								<div onClick={this.getDirectionHandler('issued')} className={styles.link}>
									{t('internal.widget.organizationStatistics.issued')}
								</div>
							</th>
						</tr>
						<tr>
							<td>{receivedValue || 0}</td>
							<td>{issuedValue || 0}</td>
						</tr>
					</tbody>
				</table>
				<table className={styles.tooltipTable}>
					<tbody>
						<tr className={styles.thBorder}>
							<th>{t(TRANSLATION_PREFIX + 'cashRegister')}</th>
							<th />
						</tr>
						{cashRegisters.map(this.renderCashRegisterRow)}
					</tbody>
				</table>
			</div>
		)
	}

	render() {
		const { renderValue } = this.props
		return (
			<Tooltip hoverable inline label={this.renderLabel()}>
				{renderValue}
			</Tooltip>
		)
	}
}

export default withTranslate(ExtractedDocumentsDirectionTooltip)
