//@flow
import type { State } from 'types'

export function getInvitationBannerOpenState(state: State): boolean {
	return state.dashboard.invitation.invitationBannerOpened
}

export function getInvitationEmails(state: State): Array<string> {
	return state.dashboard.invitation.selectedEmails
}

export function getInvitationSendingFlag(state: State): boolean {
	return state.dashboard.invitation.sending
}

export function getInvitationButtonDisabledFlag(state: State): boolean {
	return state.dashboard.invitation.buttonDisabled
}

export function getInvitationInputDisabledFlag(state: State): boolean {
	return state.dashboard.invitation.inputDisabled
}

export function getInvitationDialogOpenState(state: State): boolean {
	return state.dashboard.invitation.invitationDialogOpened
}
