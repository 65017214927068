// @flow

import { connect } from 'react-redux'
import type {
	AccountingDocumentReduxAction,
	CashRegisterAction,
	CommonAction,
	Dispatch,
	Enum,
	Scan,
	ScanFile,
	State,
	UploadScanAction,
	OrganizationAction,
	UploadScanMassChange,
	AccountingDocumentCategory,
} from 'types'
import {
	changeBranch,
	changeCashRegister,
	changeCategory,
	changeCustomerInstructions,
	changePaymentType,
	changeProject,
	checkToggle,
	checkToggleAll,
	deleteScan,
	updateScan,
	addScan,
} from '../../actions'
import { loadPaymentTypes } from 'modules/common/actions'
import { loadBranches, loadProjects } from 'modules/organization/actions'
import UploadScanList from '../../components/upload-scan/upload-scan-list'
import { getOrganizationProjects, getOrganizationBranches } from 'modules/organization/selectors'

type StateProps = {|
	branches: ?Enum,
	projects: ?Enum,
	paymentTypes: ?Enum,
	uploadingScanIds: Array<number>,
|}

function mapStateToProps(state: State): StateProps {
	return {
		branches: getOrganizationBranches(state),
		projects: getOrganizationProjects(state),
		paymentTypes: state.common.paymentTypes.data,
		uploadingScanIds: state.accountingDocument.uploadScan.uploadingScanIds,
	}
}

type DispatchProps = {|
	loadBranches: () => void,
	loadProjects: () => void,
	loadPaymentTypes: () => void,
	onToggle: (number, boolean) => void,
	uncheckAll: boolean => void,
	onBranchChange: (number, ?string) => void,
	onProjectChange: (number, ?string) => void,
	onPaymentTypeChange: (number, string) => void,
	onCustomerInstructionsChange: (number, string) => void,
	onCashRegisterChange: (number, string) => void,
	onMassChange: (items: Array<Scan>, change: UploadScanMassChange) => void,
	onDelete: (Array<Scan>) => void,
	onJoin: (Array<Scan>) => void,
	onUnjoin: Scan => void,
	onCategoryChange: (scanId: number, category: ?string) => void,
|}

type Action = AccountingDocumentReduxAction | CommonAction | CashRegisterAction | UploadScanAction | OrganizationAction
function mapDispatchToProps(dispatch: Dispatch<Action>, ownProps: OwnProps): DispatchProps {
	return {
		loadBranches: () => {
			dispatch(loadBranches())
		},
		loadProjects: () => {
			dispatch(loadProjects())
		},
		loadPaymentTypes: () => {
			dispatch(loadPaymentTypes())
		},
		uncheckAll: () => {
			dispatch(checkToggleAll(false))
		},
		onToggle: (id: number, value: boolean) => {
			dispatch(checkToggle(id, value))
		},
		onBranchChange: (id: number, value: ?string) => {
			dispatch(changeBranch(id, value))
		},
		onProjectChange: (id: number, value: ?string) => {
			dispatch(changeProject(id, value))
		},
		onPaymentTypeChange: (id: number, value: string) => {
			dispatch(changePaymentType(id, value))
		},
		onCustomerInstructionsChange: (id: number, value: string) => {
			dispatch(changeCustomerInstructions(id, value))
		},
		onCategoryChange: (scanId: number, category: ?string) => {
			dispatch(changeCategory(scanId, category))
		},
		onCashRegisterChange: (id: number, value: string) => {
			dispatch(changeCashRegister(id, value))
		},
		onMassChange: (items: Array<Scan>, change: UploadScanMassChange) => {
			items.forEach((item: Scan) => {
				dispatch(updateScan({ id: item.id, ...change }))
			})
		},
		onDelete: (items: Array<Scan>) => {
			items.forEach((item: Scan) => {
				dispatch(deleteScan(item.id))
				item.files && item.files.forEach((f: ScanFile) => ownProps.onFileDeleted && ownProps.onFileDeleted(f.fileId))
			})
		},
		onJoin: (items: Array<Scan>) => {
			if (items.length > 1) {
				let newFiles: Array<ScanFile> = []
				let joinScanId: ?number = null
				items.forEach((item: Scan, index: number) => {
					item.files.forEach((file: ScanFile) => {
						newFiles.push(file)
						if (index > 0) {
							dispatch(deleteScan(item.id))
						} else {
							joinScanId = item.id
						}
					})
				})
				dispatch(
					updateScan({
						id: joinScanId,
						files: newFiles,
						checked: false,
					}),
				)
			}
		},
		onUnjoin: (scan: Scan) => {
			scan.files.forEach((file: ScanFile, index: number) => {
				if (index !== 0) {
					dispatch(
						addScan({
							...scan,
							files: [scan.files[index]],
						}),
					)
				}
			})
			dispatch(
				updateScan({
					id: scan.id,
					files: [scan.files[0]],
					checked: false,
				}),
			)
		},
	}
}

type OwnProps = {|
	scans: Array<Scan>,
	reUploadScan?: (scanId: string) => void,
	reUploadAllScans?: () => void,
	errorScans?: Array<Scan>,
	documentCategories?: ?Array<AccountingDocumentCategory>,
	compact?: boolean,
	onFileDeleted?: (id: string) => void,
	onFilePreview?: (fileId: string) => void,
|}

export default connect(mapStateToProps, mapDispatchToProps)(UploadScanList)
