/* @flow */

import type { State } from 'types'
import { currentUserIsInternal } from 'modules/user/selectors'
import ProcessingState from './processing-state'
import { connect } from 'react-redux'

type StateProps = {|
	internal: boolean,
|}

function mapStateToProps(state: State): StateProps {
	return {
		internal: currentUserIsInternal(state),
	}
}

export default connect(mapStateToProps)(ProcessingState)
