// @flow
import type { AccountingDocument } from 'types'

export function filterDocumentsWithPaymentOrders(documents: Array<AccountingDocument>): Array<string> {
	return documents.reduce((result: Array<string>, item: AccountingDocument) => {
		if (item.hasPaymentOrders && item.id) {
			result.push(item.id)
		}
		return result
	}, [])
}
