// @flow
import type {
	AccountingDocumentLineItem,
	AccountingDocumentTotals,
	AccountingDocument,
	Template,
	RoundingTypeName,
	TemplateScheduler,
} from 'types'
import { emptyAccountingDocument } from 'types/empty'
import { createEmptyLineItem } from 'modules/accounting-document/domain/accounting-document'
import { AccountingDocumentAssignedDirection_Number, AccountingDocumentType_Number } from 'types/convertor'

function getUnitPrice(params: {
	qt: number,
	vatCoef: number,
	totalVatExcl: number,
}): { unitPriceVatExcl: number, unitPrice: number } {
	const { qt, vatCoef, totalVatExcl } = params
	const unitPriceVatExcl: number = totalVatExcl / qt
	const unitPrice: number = unitPriceVatExcl * vatCoef
	return { unitPriceVatExcl, unitPrice }
}

function getTotalPrice(params: {
	qt: number,
	vatCoef: number,
	unitPriceVatExcl: number,
}): { totalVatExcl: number, total: number } {
	const { qt, vatCoef, unitPriceVatExcl } = params
	const totalVatExcl: number = unitPriceVatExcl * qt
	const total: number = totalVatExcl * vatCoef
	return { totalVatExcl, total }
}

export function autocalculateLineItem(lineItem: AccountingDocumentLineItem): AccountingDocumentLineItem {
	const qt: number = lineItem.qt || 0
	const vatRate: number = lineItem.vatRate || 0
	// only one of following will be set on autocalcing
	let { unitPriceVatExcl, unitPrice, totalVatExcl, total } = lineItem

	const vatCoef: number = (100 + vatRate) / 100

	if (totalVatExcl != null) {
		total = totalVatExcl * vatCoef
		unitPriceVatExcl = getUnitPrice({ qt, vatCoef, totalVatExcl }).unitPriceVatExcl
		unitPrice = getUnitPrice({ qt, vatCoef, totalVatExcl }).unitPrice
	} else if (total != null) {
		totalVatExcl = total / vatCoef
		unitPriceVatExcl = getUnitPrice({ qt, vatCoef, totalVatExcl }).unitPriceVatExcl
		unitPrice = getUnitPrice({ qt, vatCoef, totalVatExcl }).unitPrice
	} else if (unitPriceVatExcl != null) {
		unitPrice = unitPriceVatExcl * vatCoef
		totalVatExcl = getTotalPrice({ qt, vatCoef, unitPriceVatExcl }).totalVatExcl
		total = getTotalPrice({ qt, vatCoef, unitPriceVatExcl }).total
	} else if (unitPrice != null) {
		unitPriceVatExcl = unitPrice / vatCoef
		totalVatExcl = getTotalPrice({ qt, vatCoef, unitPriceVatExcl }).totalVatExcl
		total = getTotalPrice({ qt, vatCoef, unitPriceVatExcl }).total
	}

	let result: AccountingDocumentLineItem = Object.assign(lineItem, {
		unitPriceVatExcl,
		unitPrice,
		qt,
		totalVatExcl,
		total,
	})
	return result
}

export function calculateTotals(lineItems: Array<AccountingDocumentLineItem>): AccountingDocumentTotals {
	const totals = {
		total: 0,
		totalVatExcl: 0,
		roundingAmount: 0,
		vatAmount: 0,
		amountToPay: 0,
	}
	lineItems.forEach((lineItem: AccountingDocumentLineItem) => {
		const calculatedLineItem = autocalculateLineItem(lineItem)
		totals.total += calculatedLineItem.total
		totals.totalVatExcl += calculatedLineItem.totalVatExcl
	})
	totals.amountToPay = totals.total
	totals.vatAmount = totals.total - totals.totalVatExcl
	return totals
}

export function updateTotalsOnAccountingDocument(
	accDoc: AccountingDocument,
	totals: AccountingDocumentTotals,
): AccountingDocument {
	return {
		...accDoc,
		total: totals.total || 0,
		totalVatExcl: totals.totalVatExcl || 0,
		roundingAmount: totals.roundingAmount || 0,
		totalVatAmount: totals.vatAmount || 0,
	}
}

export function generateNewTemplate(isOrganizationVatFree: boolean): Template {
	let lineItem = createEmptyLineItem()
	const content = emptyAccountingDocument({ vatCountryType: 1 })
	if (isOrganizationVatFree) {
		lineItem = {
			...lineItem,
			vatRateId: '40',
		}
	}
	content.type = AccountingDocumentType_Number('invoice')
	content.direction = AccountingDocumentAssignedDirection_Number('issued')
	content.lineItems = [lineItem]
	content.roundingType = 1
	return { content: updateTotalsOnAccountingDocument(content, calculateTotals(content.lineItems)) }
}

export function isRoundingVisible(accountingDocument: AccountingDocument): ?boolean {
	return accountingDocument && accountingDocument.vatRecapCalculationMode !== 2
}

export function getRoundedTotals(
	totals: AccountingDocumentTotals,
	roundingType: ?RoundingTypeName,
): AccountingDocumentTotals {
	switch (roundingType) {
		case 'roundNearest':
			return {
				...totals,
				total: Math.round(totals.total || 0),
				amountToPay: Math.round(totals.total || 0),
				roundingAmount: Math.abs(Math.round(totals.total || 0) - (totals.total || 0)),
			}
		case 'roundUp':
			return {
				...totals,
				total: Math.ceil(totals.total || 0),
				amountToPay: Math.ceil(totals.total || 0),
				roundingAmount: Math.ceil(totals.total || 0) - (totals.total || 0),
			}
		case 'roundDown':
			return {
				...totals,
				total: Math.floor(totals.total || 0),
				amountToPay: Math.floor(totals.total || 0),
				roundingAmount: (totals.total || 0) - Math.floor(totals.total || 0),
			}
		default:
			return totals
	}
}

export function isSchedulerSetToSendEmails(scheduler?: ?TemplateScheduler): boolean {
	return !!(
		(scheduler && scheduler.emailRecipients && scheduler.emailRecipients.length > 0) ||
		(scheduler && scheduler.emailTemplateId)
	)
}
