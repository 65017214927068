//@flow
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import type {
	State,
	ISignalMessage,
	Dispatch,
	NotificationsAction,
	ISignalMessageParams,
	ISignalMessageGroup,
} from 'types'
import NotificationList from '../components/notification-list'
import { getAllNotification } from '../selectors'
import { markAllAsRead, markAsRead } from '../actions'
import { getNotificationRoute } from '../routing/routes'

type StateProps = {|
	notifications: Array<ISignalMessage>,
|}

const mapStateToProps = (state: State): StateProps => {
	return {
		notifications: getAllNotification(state),
	}
}

type DispatchProps = {|
	markAllAsRead: () => void,
	markAsRead: (id: string) => void,
	handleNotificationClick: (params: ISignalMessageParams, group: ISignalMessageGroup, notificationId: string) => void,
|}

const mapDispatchToProps = (dispatch: Dispatch<NotificationsAction>): DispatchProps => {
	return {
		markAllAsRead: () => {
			dispatch(markAllAsRead())
		},
		markAsRead: (id: string) => {
			dispatch(markAsRead(id))
		},
		handleNotificationClick: (params: ISignalMessageParams, group: ISignalMessageGroup, notificationId: string) => {
			dispatch(markAsRead(notificationId))
			const route = getNotificationRoute(group, params)
			route && dispatch(push(route))
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationList)
