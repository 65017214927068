/* @flow */

import { connect } from 'react-redux'
import type { Dispatch, State, AccountingDocument } from 'types'
import { loadAccountingDocumentHeader, resetExchangeRate } from '../../actions/accounting-document'
import {
	getAccountingDocumentExchRate,
	getAccountingDocumentExchRateDefault,
	getAccountingDocumentTaxDate,
} from '../../selectors'
import ExchangeRateInfo from '../../components/invoice-settings/exchange-rate-info'

type OwnProps = {|
	isTemplate: boolean,
	isInHeader?: boolean,
	exchRate: ?number,
	vatExchRate: ?number,
	sadExchRate: ?number,
	exchRateDefault: ?number,
	accountingDocumentId: string,
	template?: ?AccountingDocument,
	exchRateValue: ?number,
|}

type StateProps = {|
	value: ?number,
	taxDate?: ?Date,
	isInHeader?: boolean,
	exchRateValue?: ?number,
|}

function mapStateToProps(state: State, ownProps: OwnProps): StateProps {
	return ownProps.isTemplate
		? {
				value: (ownProps.template && ownProps.template.exchRate) || null,
				taxDate: ownProps.template && ownProps.template.taxDate ? new Date(ownProps.template.taxDate) : null,
				isInHeader: ownProps.isInHeader,
				exchRateValue: ownProps.exchRateValue,
		  }
		: {
				value:
					getAccountingDocumentExchRate(state, ownProps.accountingDocumentId) ??
					getAccountingDocumentExchRateDefault(state, ownProps.accountingDocumentId),
				taxDate: getAccountingDocumentTaxDate(state, ownProps.accountingDocumentId),
				isInHeader: ownProps.isInHeader,
				exchRateValue: ownProps.exchRateValue,
		  }
}

type DispatchProps = {|
	onRefresh: () => Promise<any>,
|}

function mapDispatchToProps(dispatch: Dispatch<*>, ownProps: OwnProps): DispatchProps {
	return {
		onRefresh: async function onRefresh() {
			await dispatch(loadAccountingDocumentHeader(ownProps.accountingDocumentId))
			return await dispatch(resetExchangeRate(ownProps.accountingDocumentId))
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ExchangeRateInfo)
