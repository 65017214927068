//@flow
/** @jsx jsx */

import { Component } from 'react'
import { jsx } from '@emotion/core'
import { uniq } from 'lodash-es'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import type { Invitation, InvitationCode } from 'types'
import Send from 'components/svg-icons/content/send'
import Delete from 'components/svg-icons/action/delete'
import Time from 'components/svg-icons/device/access-time'
import Tooltip from 'components/tooltip'
import TableRow from 'components/table/table-row'
import TableCell from 'components/table/table-cell'
import CopyToClipboard from 'components/copy-to-clipboard'
import IconMenu from 'components/icon-menu'
import MenuItem from 'components/menu-item'
import FullText from 'components/full-text'

type Props = {|
	invitation: Invitation,
	onInvite: (string, Array<string>) => void,
	onDelete: string => void,
	canSeeNonDemoFeatures: boolean,
	getGroupNameById: string => string,
	...WithTranslateProps,
|}

class UserGroupInvitationItem extends Component<Props> {
	handleClickInvite = () => {
		if (!this.props.canSeeNonDemoFeatures) {
			return
		}

		this.props.invitation.email && this.props.onInvite(this.props.invitation.email, this.getGroupsIds())
	}

	handleClickDelete = () => {
		this.props.invitation.email && this.props.onDelete(this.props.invitation.email)
	}

	getGroupsIds() {
		const { invitation } = this.props
		return uniq(
			(invitation.invitationCodes || [])
				.reduce((groups: Array<string>, code: InvitationCode) => {
					return groups.concat(code.groups || [])
				}, [])
				.map((id: string | number) => {
					return id.toString()
				}),
		)
	}

	renderGroups() {
		return (
			<FullText
				text={this.getGroupsIds()
					.map((groupId: string) => this.props.getGroupNameById(groupId))
					.join(', ')}
			/>
		)
	}

	render() {
		const { invitation, t } = this.props

		return (
			<TableRow key={invitation.userId}>
				<TableCell>
					<span css={styles.name}>
						<Time color={'#9FB2B6'} disabled size={20} style={styles.time} />
						<span css={styles.space} />
						<CopyToClipboard
							copyTooltip={`${t('application.emailCopy')} ${invitation.email || ''}`}
							copiedTooltip={t('application.emailCopied')}
							textToCopy={invitation.email}
						>
							{invitation.email || invitation.userId || t('settings.users.invited')}
						</CopyToClipboard>
					</span>
				</TableCell>
				<TableCell />
				<TableCell>{this.renderGroups()}</TableCell>
				<TableCell>
					<Tooltip inline label={(!this.props.canSeeNonDemoFeatures && t('settings.users.demoUserCantInvite')) || null}>
						<IconMenu autoTestId="settings-group-invite-actions" disabled={!this.props.canSeeNonDemoFeatures} context>
							{[
								<MenuItem
									key={'again'}
									primaryText={t('settings.users.inviteAgain')}
									icon={<Send />}
									onClick={this.handleClickInvite}
									tall
								/>,
								<MenuItem
									key={'delete'}
									primaryText={this.props.t('grid.action.delete')}
									icon={<Delete />}
									onClick={this.handleClickDelete}
									tall
								/>,
							]}
						</IconMenu>
					</Tooltip>
				</TableCell>
			</TableRow>
		)
	}
}

const styles = {
	name: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-start',
	},
	space: {
		flex: '0 0 6px',
	},
	time: {
		flex: '0 0 20px',
	},
}

export default withTranslate(UserGroupInvitationItem)
