/* @flow */
/** @jsx jsx */

import { Component } from 'react'
import { jsx } from '@emotion/core'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import type { WithRouterProps, AccountingDocumentAction } from 'types'
import PopupSection from 'components/PopupSection'
import MultiImport from 'modules/accounting-document/containers/import/multi-import'
import MultiImportDetail from 'modules/accounting-document/containers/import/multi-import-detail'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import { withRouter } from 'react-router-dom'
import { accountingDocumentsRoute } from 'modules/accounting-document/routing/routes'

type ComponentProps = WithTranslateProps & WithRouterProps

class ImportPage extends Component<ComponentProps> {
	renderImportTutorial() {
		return (
			<div>
				<MultiImport />
				{/* HISTORY OF IMPORTS */}
			</div>
		)
	}

	renderImportDetail(importId: string) {
		return <MultiImportDetail importId={importId} />
	}

	render() {
		const { t } = this.props
		const { importId } = this.props.match.params

		return (
			<PopupSection open onRequestClose={this.props.close}>
				<div css={styles.root}>
					<h1>{t('accountingDocument.multiImport.title')}</h1>
					{importId ? this.renderImportDetail(importId) : this.renderImportTutorial()}
				</div>
			</PopupSection>
		)
	}
}

const styles = {
	root: {
		maxWidth: 970,
		marginTop: 30,
		marginLeft: 'auto',
		marginRight: 'auto',
	},
}

type DisaptchProps = {|
	close: () => void,
|}

const mapDispatchToProps = (dispatch: Dispatch<AccountingDocumentAction>): DisaptchProps => {
	return {
		close: () => {
			dispatch(push(accountingDocumentsRoute()))
		},
	}
}

export default connect(null, mapDispatchToProps)(withRouter(withTranslate(ImportPage)))
