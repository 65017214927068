/* @flow */

import { connect } from 'react-redux'
import type { Dispatch, State } from 'types'
import { isSomeTemplateCreating } from 'modules/accounting-document/selectors/templates'
import { isAccountingDocumentCreating } from 'modules/accounting-document/selectors'
import { navigateToMainScreen } from 'modules/navigation/actions'
import AccountingDocumentLoader from '../components/accounting-document-loader'

export type OwnProps = {|
	type: string,
	direction: string,
|}

export type StateProps = {|
	isCreating: boolean,
|}

const mapStateToProps = (state: State): StateProps => {
	return {
		isCreating: isAccountingDocumentCreating(state) || isSomeTemplateCreating(state),
	}
}

export type DispatchProps = {|
	navigateToMainScreen: () => void,
|}

const mapDispatchToProps = (dispatch: Dispatch<*>): DispatchProps => {
	return {
		navigateToMainScreen: () => {
			dispatch(navigateToMainScreen())
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountingDocumentLoader)
