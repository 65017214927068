/* @flow */

import React, { Component } from 'react'
import NavigationChevronLeft from 'components/svg-icons/navigation/chevron-left'
import NavigationChevronRight from 'components/svg-icons/navigation/chevron-right'
import IconButton from 'components/icon-button'
import CalendarSlide from './calendar-slide'

export type Props = {|
	DateTimeFormat: (string, Object) => ?string,
	displayDate: ?Date,
	locale: string,
	nextMonth: boolean,
	prevMonth: boolean,
	onMonthChange: number => void,
|}

export type State = {|
	transitionDirection: 'left' | 'right',
|}

const styles = {
	root: {
		display: 'flex',
		justifyContent: 'space-between',
		backgroundColor: 'inherit',
		height: 48,
		lineHeight: '48px',
		borderBottom: '1px solid #D6D9DC',
		margin: '0 -20px',
	},
	titleDiv: {
		fontSize: 14,
		fontWeight: '500',
		textAlign: 'center',
		width: '100%',
		color: '#000000',
	},
	titleText: {
		height: 'inherit',
		lineHeight: 'inherit',
		paddingTop: 0,
	},
}

class CalendarToolbar extends Component<Props, State> {
	static defaultProps = {
		nextMonth: true,
		prevMonth: true,
	}

	state = {
		transitionDirection: 'left',
	}

	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		if (nextProps.displayDate && this.props.displayDate && nextProps.displayDate !== this.props.displayDate) {
			const direction = nextProps.displayDate > this.props.displayDate ? 'left' : 'right'
			this.setState({
				transitionDirection: direction,
			})
		}
	}

	handleClickPrevMonth = () => {
		this.props.onMonthChange && this.props.onMonthChange(-1)
	}

	handleClickNextMonth = () => {
		this.props.onMonthChange && this.props.onMonthChange(1)
	}

	render() {
		const { DateTimeFormat, locale, displayDate } = this.props

		const dateTimeFormatted = new DateTimeFormat(locale, {
			month: 'long',
			year: 'numeric',
		}).format(displayDate)

		return (
			<div style={styles.root}>
				<IconButton disabled={!this.props.prevMonth} onClick={this.handleClickPrevMonth}>
					<NavigationChevronLeft />
				</IconButton>
				<CalendarSlide direction={this.state.transitionDirection} style={styles.titleDiv}>
					<div key={dateTimeFormatted} style={styles.titleText}>
						{dateTimeFormatted}
					</div>
				</CalendarSlide>
				<IconButton disabled={!this.props.nextMonth} onClick={this.handleClickNextMonth}>
					<NavigationChevronRight />
				</IconButton>
			</div>
		)
	}
}

export default CalendarToolbar
