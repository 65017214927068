// @flow

import React, { Component } from 'react'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import DoneStateIcon from 'components/svg-icons/trivi/state/done'
import ErrorStateIcon from 'components/svg-icons/trivi/state/error'
import WaitStateIcon from 'components/svg-icons/trivi/state/wait'
import Chip, { type ChipColor } from 'components/chip'
import Tooltip from 'components/tooltip'

type Props = {|
	...WithTranslateProps,
	processingState: ?number,
	processingMessage?: string,
	notProcessedLabel?: string,
	processedLabel?: string,
	waitingLabel?: string,
	errorLabel?: string,
	errorTooltip?: string,
	internal: boolean,
	inline?: boolean,
	top?: boolean,
|}

class ProcessingState extends Component<Props> {
	getMessage(): string {
		let message: ?string = null
		switch (this.props.processingState) {
			case 0:
				message = this.props.notProcessedLabel
				break
			case 1:
				message = this.props.processedLabel
				break
			case 2:
				message = this.props.waitingLabel
				break
			case 3:
				message = this.props.errorLabel
				break
		}
		return message || ''
	}

	getProcessingStateIcon(): ?React$Element<any> {
		switch (this.props.processingState) {
			case 1:
				return <DoneStateIcon size={12} />
			case 2:
				return <WaitStateIcon size={12} />
			case 3:
				return <ErrorStateIcon size={12} />
			default:
				return null
		}
	}

	getProcessingStateColor(): ChipColor {
		switch (this.props.processingState) {
			case 0:
				return 'blue'
			case 1:
				return 'green'
			case 2:
				return 'orange'
			case 3:
				return 'red'
			default:
				return 'blue'
		}
	}

	render() {
		const message = this.getMessage()
		const errorTooltip =
			this.props.processingState === 3
				? this.props.errorTooltip || this.props.t('accountingDocument.processingStates.errorTooltip')
				: null

		if (!message) {
			return null
		}

		return (
			<Tooltip
				inline={this.props.inline}
				label={this.props.internal ? this.props.processingMessage : errorTooltip}
				multiLine
			>
				<Chip light top={this.props.top} color={this.getProcessingStateColor()} icon={this.getProcessingStateIcon()}>
					{message}
				</Chip>
			</Tooltip>
		)
	}
}

export default withTranslate(ProcessingState)
