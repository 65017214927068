// @flow

import { connect } from 'react-redux'
import withAutoload from 'wrappers/with-autoload'
import type { Dispatch, EmailTemplate, State } from 'types'
import { deleteEmailTemplate, editEmailTemplate, loadEmailTemplates } from 'modules/settings/actions'
import type { Action as EmailAction } from 'modules/settings/actions/email-action-types'
import Emails from 'modules/settings/components/trivi-settings/emails'

type StateProps = {|
	emailTemplates: ?Array<EmailTemplate>,
|}

const mapStateToProps = (state: State): StateProps => {
	return {
		emailTemplates: state.settings.emailTemplates,
	}
}

type DispatchProps = {|
	autoload: () => void,
	editEmailTemplate: (emailTemplateId: string, emailTemplate: EmailTemplate) => void,
	deleteEmailTemplate: (emailTemplateId: string) => void,
|}

const mapDispatchToProps = (dispatch: Dispatch<EmailAction>): DispatchProps => {
	return {
		autoload: () => {
			dispatch(loadEmailTemplates())
		},
		editEmailTemplate: (emailTemplateId: string, emailTemplate: EmailTemplate) => {
			dispatch(editEmailTemplate(emailTemplateId, emailTemplate))
		},
		deleteEmailTemplate: (emailTemplateId: string) => {
			dispatch(deleteEmailTemplate(emailTemplateId))
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(withAutoload(Emails))
