/* @flow */

import React, { Component } from 'react'
import { Validator } from 'utils'
import validate from 'wrappers/validate'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import type { FormValidationProps } from 'types'
import PasswordFormLayout from '../pages/password-form-layout'
import Button from 'components/button'
import EmailInput from 'components/email-input'
import styles from './styles.css'

type Props = {|
	...WithTranslateProps,
	...FormValidationProps,
	resetPassword: (email: string) => void,
|}

type State = {|
	email: string,
|}

class ResetPasswordForm extends Component<Props, State> {
	state = {
		email: '',
	}

	handleFormSubmit = (event?: Event) => {
		event && event.preventDefault()
		this.props.resetPassword(this.state.email)
	}

	handleEmailChange = (event: SyntheticInputEvent<HTMLInputElement>, email: ?string) => {
		if (email !== null && email !== undefined) {
			this.setState({ email })
		}
	}

	getClientValidationErrors() {
		const errors = {}
		const { t } = this.props

		if (!this.state.email) {
			errors.email = t('application.validation.mandatory')
		}

		if (this.state.email && !Validator.email(this.state.email)) {
			errors.email = t('clientError.notAnEmail')
		}

		return errors
	}

	render() {
		const { t } = this.props
		this.props.validateForm(this.getClientValidationErrors())

		return (
			<PasswordFormLayout>
				<form onSubmit={this.handleFormSubmit} className={styles.wrapper} noValidate>
					<div className={styles.field}>
						<EmailInput
							fullWidth
							name="email"
							type="email"
							hintText={t('user.login.loginLabel')}
							onChange={this.handleEmailChange}
							value={this.props.validationValue('email', this.state.email)}
							clientError={this.props.validationMessage('email')}
							autoTestId="reset-password-email"
						/>
					</div>
					<div className={styles.button}>
						<Button
							tall
							fullWidth
							type="submit"
							autoTestId="reset-password-submit"
							disabled={!this.props.isFormValid()}
							labelText={t('user.login.recoverPasswordButton')}
						/>
					</div>
				</form>
			</PasswordFormLayout>
		)
	}
}

export default validate('FINISH_CHANGING_PASSWORD')(withTranslate(ResetPasswordForm))
