/* @flow */
/** @jsx jsx */

// $FlowFixMe - there is useEffect
import { useState, useEffect } from 'react'
import { jsx, css } from '@emotion/core'
import { getCSSBlurSupport, setBlurOnBackground, setScrollLockOnBody } from './logic'
import styles from './overlay.styles'

type Props = {|
	opaque?: boolean,
	open: boolean,
|}

export default function Overlay(props: Props) {
	const [isBlurSupported] = useState<boolean>(getCSSBlurSupport())

	useEffect(() => {
		setBlurOnBackground(props.open)
		setScrollLockOnBody(props.open)
		return () => {
			setScrollLockOnBody(false)
			setBlurOnBackground(false)
		}
	}, [props.open])

	return (
		<div
			css={css`
			${styles.overlay} ${props.open ? styles.opened : styles.closed} ${
				!isBlurSupported || props.opaque ? styles.opaque : styles.transparent
			}
			`}
		/>
	)
}
