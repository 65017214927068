// @flow

import Highcharts from 'highcharts'
import type { BaseChartProps } from '../highcharts/highchart'

export function pickDefaultOptions(props: { ...BaseChartProps, [string]: any }): BaseChartProps {
	const { width, height, backgroundColor, title, overlayContent, disabled, padding } = props

	return { width, height, backgroundColor, title, overlayContent, disabled, padding }
}

export function format(string: string, context: { [string]: any }): string {
	return Highcharts.format(string, context)
}
