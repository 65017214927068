/* @flow */

import React from 'react'
import { Route, Redirect } from 'react-router'
import { UserIsAuthenticated } from 'lib/auth'
import MainLayout from 'layouts/main-layout'
import { AccDocListFactory } from 'modules/accounting-document/pages/accounting-document-list'
import ImportsPage from 'modules/accounting-document/pages/import'
import {
	EditAccountingDocument,
	ShareablePublicAccountingDocument,
	EditRedirAccountingDocument,
	EditTemplate,
	ShowAccountingDocument,
	CreateAccountingDocument,
	UploadScan,
} from '../pages'

const CreateComponent = UserIsAuthenticated(MainLayout('accountingDocument')(CreateAccountingDocument))
const PublicComponent = UserIsAuthenticated(MainLayout('accountingDocument')(ShareablePublicAccountingDocument))
const ShowComponent = UserIsAuthenticated(MainLayout('accountingDocument')(ShowAccountingDocument))
const EditComponent = UserIsAuthenticated(MainLayout('accountingDocument')(EditAccountingDocument))
const EditRedirComponent = UserIsAuthenticated(MainLayout('accountingDocument')(EditRedirAccountingDocument))
const ExtractComponent = UserIsAuthenticated(MainLayout('extractAccountingDocument')(EditAccountingDocument))
const UploadComponent = UserIsAuthenticated(MainLayout('accountingDocument')(UploadScan))
const EditTemplateComponent = UserIsAuthenticated(MainLayout('accountingDocument')(EditTemplate))
const ImportComponent = UserIsAuthenticated(MainLayout('accountingDocumentMultipleImport')(ImportsPage))
const AccDocListComponent = {
	received: UserIsAuthenticated(MainLayout('accountingDocument')(AccDocListFactory.received)),
	issued: UserIsAuthenticated(MainLayout('accountingDocument')(AccDocListFactory.issued)),
	uploaded: UserIsAuthenticated(MainLayout('accountingDocument')(AccDocListFactory.uploaded)),
	templates: UserIsAuthenticated(MainLayout('accountingDocument')(AccDocListFactory.templates)),
}

const AccountingDocumentRouting = (
	canExtract: boolean,
	canSeeDetail: boolean,
	canSeeEdit: boolean,
	canSeeAccDocs: boolean,
) => [
	<Route
		exact
		key="create"
		path="/:organizationId/accounting-documents/:direction/:type/create"
		component={CreateComponent}
	/>,
	<Route
		exact
		key="upload-scan"
		path="/:organizationId/accounting-documents/upload-scan"
		component={UploadComponent}
	/>,
	<Route
		exact
		key="templateEdit"
		path="/:organizationId/accounting-documents/templates/:id/edit"
		component={EditTemplateComponent}
	/>,
	<Route
		exact
		key="accountingDocumentMultipleImport"
		path="/:organizationId/accounting-documents/import/:importId?"
		component={ImportComponent}
	/>,
	canSeeDetail ? (
		<Route exact key="detail" path="/:organizationId/accounting-documents/:id/detail" component={ShowComponent} />
	) : null,
	canExtract
		? ((
				<Route
					exact
					key="extract"
					path="/:organizationId/accounting-documents/:id/extract"
					component={ExtractComponent}
				/>
		  ),
		  (
				<Route
					exact
					key="extract-dashboard"
					path="/:organizationId/accounting-documents/:id/edit/:source"
					component={ExtractComponent}
				/>
		  ))
		: null,
	canSeeAccDocs ? (
		<Route
			exact
			key="accDocListReceived"
			path="/:organizationId/accounting-documents"
			component={AccDocListComponent.received}
		>
			<Redirect to="/:organizationId/accounting-documents/received" />
		</Route>
	) : null,
	canSeeAccDocs ? (
		<Route
			exact
			key="accDocListReceived"
			path="/:organizationId/accounting-documents/received"
			component={AccDocListComponent.received}
		/>
	) : null,
	canSeeAccDocs ? (
		<Route
			exact
			key="accDocListIssued"
			path="/:organizationId/accounting-documents/issued"
			component={AccDocListComponent.issued}
		/>
	) : null,
	canSeeAccDocs ? (
		<Route
			exact
			key="accDocListUploaded"
			path="/:organizationId/accounting-documents/uploaded"
			component={AccDocListComponent.uploaded}
		/>
	) : null,
	canSeeAccDocs ? (
		<Route
			exact
			key="accountingDocumentsList"
			path="/:organizationId/accounting-documents/templates"
			component={AccDocListComponent.templates}
		/>
	) : null,
	<Route exact key="public" path="/accounting-documents/:uniqueId" component={PublicComponent} />,
	canSeeEdit ? (
		<Route exact key="edit_redir" path="/:organizationId/accounting-documents/:id" component={EditRedirComponent} />
	) : null,
	canSeeEdit ? (
		<Route exact key="edit" path="/:organizationId/accounting-documents/:id/:type" component={EditComponent} />
	) : null,
]

export default AccountingDocumentRouting
