//@flow

import type { Dispatch } from 'types'
import { ossHistory as ossHistoryApi } from 'modules/common/models/api-model'
import type { Action as OssAction } from './oss-action-types'

export function loadOrganizationSettingsOss() {
	return async (dispatch: Dispatch<OssAction>) => {
		try {
			const oss = await ossHistoryApi.get()
			dispatch({
				type: 'FINISH_LOADING_OSS_HISTORY',
				oss,
			})
		} catch (serverError) {
			dispatch({
				type: 'FINISH_LOADING_OSS_HISTORY',
				oss: null,
				serverError,
			})
		}
	}
}
