/* @flow */

import React, { Component } from 'react'
import memoize from 'memoize-one'
import { withRouter } from 'react-router-dom'
import type { BankAccount, WithRouterProps } from 'types'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import { bankAccountRoute } from 'modules/bank/routing/routes'
import BankAccountItem from './bank-account-item'
import BankAccountItemNew from './bank-account-item-new'
import styles from './bank-account-grid.css'

type Props = {|
	...WithRouterProps,
	...WithTranslateProps,
	loading: boolean,
	bankAccounts: Array<BankAccount>,
	canSeeBankAccounts: boolean,
	loadData: () => void,
|}

class BankAccountGrid extends Component<Props> {
	componentDidMount() {
		this.props.loadData && this.props.loadData()
	}

	getBankAccounts = memoize((bankAccounts: Array<BankAccount>) => {
		return bankAccounts.map((item: BankAccount, index: number) => {
			const onClick = () => {
				this.props.history.push(bankAccountRoute(item.id || ''))
			}
			return (
				<div className={styles.item} key={index}>
					<BankAccountItem
						onClick={this.props.canSeeBankAccounts ? onClick : undefined}
						bankAccount={item}
						showProcessingState
					/>
				</div>
			)
		})
	})

	render() {
		return (
			<div className={styles.container}>
				{this.getBankAccounts(this.props.bankAccounts)}
				<div className={styles.item}>
					<BankAccountItemNew />
				</div>
			</div>
		)
	}
}

export default withTranslate(withRouter(BankAccountGrid))
