/* @flow */

import { connect } from 'react-redux'
import type { BankAction, Dispatch, State, Bank } from 'types'
import BankCodeAutocomplete from '../components/bank-code-autocomplete'
import { loadBanks } from '../actions'

type StateProps = {|
	bankAccounts: ?Array<Bank>,
|}

const mapStateToProps = (state: State): StateProps => {
	return {
		bankAccounts: state.bank.banks.data,
	}
}

type DispatchProps = {|
	loadBanks: () => void,
|}

const mapDispatchToProps = (dispatch: Dispatch<BankAction>): DispatchProps => {
	return {
		loadBanks: (): void => {
			dispatch(loadBanks())
		},
	}
}

const autocomplete = connect(mapStateToProps, mapDispatchToProps)(BankCodeAutocomplete)
export default autocomplete
