//@flow

import React, { Component } from 'react'
import { withNotify, withTranslate, type WithTranslateProps, type WithNotifyProps } from 'wrappers'
import type { Group, UserProfile, Invitation, UserGroup, GroupsAction } from 'types'
import { hasUserBulkProcessResponseServerError } from '../../domain/users'
import UserGroupUserItem from './user-group-user-item'
import UserGroupInvitationItem from './user-group-invitation-item'
import UserEdit from './user-edit'
import Table from 'components/table/table'
import TableHeader from 'components/table/table-header'
import TableHeaderCell from 'components/table/table-header-cell'
import TableBody from 'components/table/table-body'
import TableRow from 'components/table/table-row'
import DeleteDialog from 'components/delete-dialog'
import ConfirmDialog from 'components/confirm-dialog'

type Props = {|
	canSetUsers: boolean,
	canSeeNonDemoFeatures: boolean,
	profiles: Array<UserProfile>,
	invitations: Array<Invitation>,
	groups: Array<Group>,
	currentUserId?: string,
	onInviteAgain?: (string, Array<string>) => void,
	onRemoveUserInvitations?: (user: UserProfile) => void,
	onRemoveInvitation?: (email: string) => void,
	bulkProcessUserGroups: (
		profile: UserProfile,
		requests: Array<{ groupId: string, operation: 'add' | 'remove' }>,
	) => Promise<*>,
	...WithTranslateProps,
	...WithNotifyProps,
|}

type State = {|
	editedUser: ?UserProfile,
	groupsById: ?{ [string]: Group },
|}

class UserGroupList extends Component<Props, State> {
	state = {
		editedUser: null,
		groupsById: null,
	}

	componentDidMount() {
		if (this.props.groups) {
			let groupsById: { [string]: Group } = {}
			this.props.groups.forEach((g: Group) => {
				groupsById[g.id || ''] = g
			})
			this.setState({ // eslint-disable-line
				groupsById: groupsById,
			})
		}
	}

	handleItemUpdate = (editedUser: UserProfile) => {
		this.setState({
			editedUser: editedUser,
		})
	}

	handleEditRequestClose = () => {
		this.setState({
			editedUser: null,
		})
	}

	handleInviteAgain = (email: string, groups: Array<string>) => {
		this.props.onInviteAgain && this.props.onInviteAgain(email, groups)
	}

	handleDeleteUserInvitations = (user: UserProfile) => {
		const { onRemoveUserInvitations } = this.props
		if (onRemoveUserInvitations) {
			DeleteDialog()
				.then(() => {
					onRemoveUserInvitations(user)
				})
				.catch(() => {})
		}
	}

	handleDeleteUser = (user: UserProfile) => {
		const { t, bulkProcessUserGroups } = this.props
		if (bulkProcessUserGroups) {
			ConfirmDialog(t('dialogs.removeQuestion'), {
				okLabel: t('dialogs.yesOption'),
				cancelLabel: t('dialogs.noOption'),
				waitForConfirm: true,
			}).then(() => {
				const requests = [
					...(user.groups || []).map((g: UserGroup) => {
						return { groupId: g.id || '', operation: 'remove' }
					}),
				]
				bulkProcessUserGroups(user, requests).then((resp: Array<GroupsAction>) => {
					const isError = hasUserBulkProcessResponseServerError(resp)
					!isError && this.props.notify(t('settings.users.removed'), 'success')
				})
			})
		}
	}

	handleDeleteInvitation = (email: string) => {
		const { onRemoveInvitation } = this.props
		if (onRemoveInvitation) {
			DeleteDialog()
				.then(() => {
					onRemoveInvitation(email)
				})
				.catch(() => {})
		}
	}

	getGroupNameById = (groupId: string): string => {
		if (!this.state.groupsById) {
			return groupId
		}
		const group: Group = this.state.groupsById[groupId]
		return (group && group.name) || groupId
	}

	render() {
		const {
			profiles,
			invitations,
			groups,
			bulkProcessUserGroups,
			canSetUsers,
			canSeeNonDemoFeatures,
			currentUserId,
			t,
		} = this.props
		const { editedUser } = this.state
		const canDelete = (userId?: string) => {
			if (currentUserId && userId) {
				return currentUserId !== userId
			}
			return true
		}

		if (editedUser) {
			return (
				<UserEdit
					user={editedUser}
					groups={groups}
					onClose={this.handleEditRequestClose}
					bulkProcessUserGroups={bulkProcessUserGroups}
				/>
			)
		}

		return (
			<Table>
				<TableHeader>
					<TableRow>
						<TableHeaderCell>{this.props.t('settings.users.email')}</TableHeaderCell>
						<TableHeaderCell>{this.props.t('settings.users.name')}</TableHeaderCell>
						<TableHeaderCell>{this.props.t('settings.users.groupList')}</TableHeaderCell>
						<TableHeaderCell width={35} />
					</TableRow>
				</TableHeader>
				<TableBody>
					{profiles.map((userProfile: UserProfile, i: number) => (
						<UserGroupUserItem
							key={`user-${i}`}
							userProfile={userProfile}
							canDelete={canDelete(userProfile.id)}
							editable={canSetUsers && canSeeNonDemoFeatures}
							onEdit={this.handleItemUpdate}
							onDelete={this.handleDeleteUser}
							notEditableReason={(!canSeeNonDemoFeatures && t('settings.users.demoUserCantInvite')) || undefined}
						/>
					))}
					{invitations.map((invitation: Invitation, i: number) => (
						<UserGroupInvitationItem
							key={`pending-${i}`}
							onInvite={this.handleInviteAgain}
							onDelete={this.handleDeleteInvitation}
							invitation={invitation}
							canSeeNonDemoFeatures={canSeeNonDemoFeatures}
							getGroupNameById={this.getGroupNameById}
						/>
					))}
				</TableBody>
			</Table>
		)
	}
}

export default withTranslate(withNotify(UserGroupList))
