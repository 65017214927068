// @flow

import type {
	AccountingDocument,
	AccountingDocumentAction,
	AccountingDocumentActionListState,
	AccountingDocumentActions,
	AccountingDocumentDirection,
	AccountingDocumentInfo,
	AccountingDocumentLineItem,
	Contact,
	ContactAddress,
	ContactPerson,
	I18NextTranslateFn,
	RelatedAccountingDocumentInfo,
	WithRouterProps,
} from 'types'

import { AccountingDocumentTypeNumber_isCashRegister } from 'types/operations'
import CashbotIcon from 'components/svg-icons/trivi/cashbot'
import Check from 'components/svg-icons/navigation/check'
import type { Action as DataGridAction } from 'modules/data-grid/types'
import Delete from 'components/svg-icons/action/delete'
import Edit from 'components/svg-icons/editor/mode-edit'
import { Number_AccountingDocumentDirection } from 'types/convertor'
import type { Permission } from 'permissions'
import React from 'react'
import Subdirectory from 'components/svg-icons/navigation/subdirectory-arrow-right'
import View from 'components/svg-icons/action/visibility'
import { isDraft } from 'helpers'
import memoize from 'memoize-one'
import { sortedUniq } from 'lodash-es'

export * from './accounting-document-bank-accounts'

type PermissionsType = {
	[p: Permission]: boolean,
}

type IsVisibleFunction = (actionType: string) => (row: AccountingDocument) => boolean

export const getAccountingDocumentActions = memoize(
	(
		t: I18NextTranslateFn,
		direction: ?AccountingDocumentDirection,
		availableActions: AccountingDocumentActionListState,
		hiddenActions?: Array<string>,
		isVisibleActionFunction?: IsVisibleFunction,
		isVisibleMoveActionFunction?: IsVisibleFunction,
		isInternal?: boolean,
	): Array<DataGridAction<AccountingDocument>> => {
		isVisibleActionFunction = isVisibleActionFunction || isVisibleAction(direction, availableActions, undefined)
		isVisibleMoveActionFunction = isVisibleMoveActionFunction || isVisibleMoveAction(availableActions)

		let actions = [
			// document actions
			{
				name: t('accountingDocument.gridActions.approve'),
				type: 'accdoc_approve',
				isVisibleFn: isVisibleActionFunction('accdoc_approve'),
				primary: true,
				icon: <Check />,
			},
			{
				name: t('accountingDocument.gridActions.view'),
				type: 'accdoc_show',
				isVisibleFn: isVisibleActionFunction('accdoc_show'),
				primary: true,
				icon: <View />,
			},
			{
				name:
					isInternal && direction === 'unknown'
						? t('accountingDocument.gridActions.extract')
						: t('accountingDocument.gridActions.edit'),
				type: 'accdoc_edit',
				isVisibleFn: isVisibleActionFunction('accdoc_edit'),
				primary: true,
				icon: <Edit />,
			},
			{
				name: t('grid.action.delete'),
				type: 'accdoc_remove',
				isVisibleFn: isVisibleActionFunction('accdoc_remove'),
				primary: true,
				icon: <Delete />,
			},
			{
				name: t('accountingDocument.gridActions.editAttributes'),
				type: 'accdoc_edit_attributes',
				isVisibleFn:
					isVisibleActionFunction('accdoc_change_category') ||
					isVisibleActionFunction('accdoc_change_branch') ||
					isVisibleActionFunction('accdoc_change_project') ||
					isVisibleActionFunction('accdoc_change_cashregister') ||
					isVisibleActionFunction('accdoc_change_paymenttype'),
				primary: true,
			},
			{
				name: t('grid.action.download_pdf'),
				type: 'accdoc_print',
				isVisibleFn: isVisibleActionFunction('accdoc_print'),
				primary: true,
			},
			{
				name: t('accountingDocument.gridActions.pay'),
				type: 'accdoc_pay_transfer',
				isVisibleFn: isVisibleActionFunction('accdoc_pay_transfer'),
				primary: true,
			},
			{
				name: t('grid.action.createAccDoc'),
				type: 'accdoc_create_cr_accdoc',
				isVisibleFn: isVisibleActionFunction('accdoc_create_cr_accdoc'),
				primary: true,
			},
			{
				name: t('grid.action.sendEmail'),
				type: 'accdoc_send_email',
				isVisibleFn: isVisibleActionFunction('accdoc_send_email'),
				primary: true,
			},
			{
				name: t('grid.action.sendReminder'),
				type: 'accdoc_send_due_date',
				isVisibleFn: isVisibleActionFunction('accdoc_send_due_date'),
				primary: true,
			},
			{
				name: t('grid.action.getPermalink'),
				type: 'accdoc_get_permalink',
				isVisibleFn: isVisibleActionFunction('accdoc_get_permalink'),
				primary: true,
			},
			{
				name: t('grid.action.createTemplate'),
				type: 'accdoc_create_template',
				isVisibleFn: isVisibleActionFunction('accdoc_create_template'),
				primary: true,
			},
			{
				name: t('grid.action.copyDocument'),
				type: 'accdoc_copy',
				isVisibleFn: isVisibleActionFunction('accdoc_copy'),
				primary: false,
			},
			{
				name: t('grid.action.sendReminder'),
				type: 'accdoc_send_reminder',
				isVisibleFn: isVisibleActionFunction('accdoc_send_reminder'),
				primary: false,
			},
			{
				name: t('grid.action.createTaxAdvance'),
				type: 'accdoc_create_taxadvance',
				isVisibleFn: isVisibleActionFunction('accdoc_create_taxadvance'),
				primary: false,
			},
			// {
			// 	name: t('grid.action.createTask'),
			// 	type: 'accdoc_task_create',
			// 	isVisibleFn: isVisibleActionFunction('accdoc_task_create'),
			// },
			// {
			// 	name: t('invoice.settings.eet.sendButton'),
			// 	type: 'accdoc_send_eet',
			// 	isVisibleFn: isVisibleActionFunction('accdoc_send_eet'),
			// },
			// {
			// 	name: t('invoice.settings.eet.cancelButton'),
			// 	type: 'accdoc_cancel_eet',
			// 	isVisibleFn: isVisibleActionFunction('accdoc_cancel_eet'),
			// },
			{
				name: t('accountingDocument.gridActions.markAsPaid'),
				type: 'accdoc_manually_paid',
				isVisibleFn: isVisibleActionFunction('accdoc_manually_paid'),
			},
			{
				name: t('accountingDocument.gridActions.markAsUnpaid'),
				type: 'accdoc_manually_unpaid',
				isVisibleFn: isVisibleActionFunction('accdoc_manually_unpaid'),
			},
			{
				name: t('accountingDocument.gridActions.forceProcessing'),
				type: 'accdoc_force_processing',
				isVisibleFn: isVisibleActionFunction('accdoc_force_processing'),
			},
			{
				name: t('accountingDocument.gridActions.confirmCreditNote'),
				type: 'accdoc_confirm_creditnote',
				isVisibleFn: isVisibleActionFunction('accdoc_confirm_creditnote'),
			},
			{
				name: t('accountingDocument.gridActions.reextract'),
				type: 'accdoc_reextract',
				isVisibleFn: isVisibleActionFunction('accdoc_reextract'),
			},
			{
				name: t('accountingDocument.gridActions.createCreditNote'),
				type: 'accdoc_create_creditnote',
				isVisibleFn: isVisibleActionFunction('accdoc_create_creditnote'),
			},
			{
				name: t('accountingDocument.multiActions.move'),
				type: 'accdoc_move',
				icon: <Subdirectory />,
				isVisibleFn: isVisibleActionFunction('accdoc_move'),
				primary: true,
			},
			// move actions
			{
				name: t('accountingDocument.gridActions.changeStateToDraft'),
				type: 'accdoc_movewf_draft',
				isVisibleFn: isVisibleMoveActionFunction('Draft'),
			},
			{
				name: t('accountingDocument.gridActions.changeStateToWaitingForApproval'),
				type: 'accdoc_movewf_approval',
				isVisibleFn: isVisibleMoveActionFunction('Waiting for approval'),
			},
			{
				name: t('accountingDocument.gridActions.changeStateToProcessed'),
				type: 'accdoc_movewf_processed',
				isVisibleFn: isVisibleMoveActionFunction('Processed'),
			},
			{
				name: t('accountingDocument.gridActions.changeStateToFixing'),
				type: 'accdoc_movewf_fixing',
				isVisibleFn: isVisibleMoveActionFunction('Fixing'),
				primary: true,
				icon: <Edit />,
			},
			{
				name: t('grid.action.setProcessinfState'),
				type: 'accdoc_set_processing_state',
				isVisibleFn: isVisibleActionFunction('accdoc_set_processing_state'),
			},
			{
				name: t('accountingDocument.multiActions.accdocUnjoin'),
				type: 'accdoc_unjoin',
				isVisibleFn: isVisibleActionFunction('accdoc_unjoin'),
			},
			{
				primary: true,
				icon: <CashbotIcon />,
				type: 'accdoc_cashbot',
				name: t('accountingDocument.gridActions.accdocCashbot'),
				isVisibleFn: isVisibleActionFunction('accdoc_cashbot'),
			},
		]

		if (hiddenActions) {
			actions = actions.filter((action: { type: string }) => hiddenActions && hiddenActions.indexOf(action.type) === -1)
		}

		return actions
	},
)

// not used yet because aour favorite redux grid ;)
export function isVisibleAction(
	direction: ?AccountingDocumentDirection,
	availableActions: AccountingDocumentActionListState,
	permissions: ?PermissionsType,
) {
	return (type: string) => (row: AccountingDocument): boolean => {
		if (!availableActions || (permissions && !isGridActionAllowed(permissions, type, row))) {
			return false
		}

		const availableActionsState: ?{ data?: ?AccountingDocumentActions } = availableActions[row.id || '']
		const _availableActions: ?AccountingDocumentActions = availableActionsState && availableActionsState.data

		if (!_availableActions) {
			return false
		}

		if (type === 'accdoc_copy') {
			const canCopy = canBeAccDocCopied(row.type)
			if (!canCopy) return false
		}

		return (
			_availableActions.findIndex((availabileAction: AccountingDocumentAction) => availabileAction.type === type) !== -1
		)
	}
}

// not used yet because aour favorite redux grid ;)
export function isVisibleMoveAction(availableActions: AccountingDocumentActionListState) {
	return function(state: string): (row: AccountingDocument) => boolean {
		const type = 'accdoc_movewf'
		return (row: AccountingDocument): boolean => {
			if (!availableActions) {
				return false
			}
			const availableActionsState: ?{ data?: ?AccountingDocumentActions } = availableActions[row.id || '']
			const _availableActions: ?AccountingDocumentActions = availableActionsState && availableActionsState.data

			if (!_availableActions) {
				return false
			}

			const action = _availableActions.find(
				(availabileAction: AccountingDocumentAction) => availabileAction.type === type,
			)

			if (!action || !action.params || !action.params.possibleStates) {
				return false
			}

			return (
				action.params.possibleStates.findIndex((item: string) => {
					return item === state
				}) !== -1
			)
		}
	}
}

export function accountingDocumentNumber(
	accountingDocument: AccountingDocument | AccountingDocumentInfo | RelatedAccountingDocumentInfo,
) {
	let id = null
	if (accountingDocument.accountingDocumentNo) {
		id = accountingDocument.accountingDocumentNo
	} else {
		id = 'ID:' + (accountingDocument.id || accountingDocument.accountingDocumentId || '')
	}

	if (accountingDocument.variableSymbol) {
		id += ' (VS: ' + accountingDocument.variableSymbol + ')'
	}
	return id
}

export function accountingDocumentNumberRaw(accountingDocument: AccountingDocument | AccountingDocumentInfo) {
	if (accountingDocument.accountingDocumentNo) {
		return accountingDocument.accountingDocumentNo
	} else {
		return accountingDocument.id || accountingDocument.accountingDocumentId || ''
	}
}

export function relatedAccountingDocumentNumber(accountingDocument: AccountingDocument) {
	let id = null
	if (accountingDocument.relatedAccountingDocumentNo) {
		id = accountingDocument.relatedAccountingDocumentNo
	} else {
		id = 'ID:' + (accountingDocument.relatedAccountingDocumentId || '')
	}
	return id
}

// viz TESTREP-866
// U pokladních dokladů ponechat možnost Zduplikovat pouze u Zjednodušeného dokladu.
export function canBeAccDocCopied(type: ?number) {
	if (type && AccountingDocumentTypeNumber_isCashRegister(type)) {
		return type === 8 // simplified_invoice
	}
	return true
}

export function isGridActionAllowed(permissions: PermissionsType, type: string, row: AccountingDocument): boolean {
	if (type === 'accdoc_edit') {
		switch (Number_AccountingDocumentDirection(row.direction)) {
			case 'issued':
				return !!permissions.editIssuedAccountingDocuments
			case 'received':
				return !!permissions.editReceivedAccountingDocuments
			case 'unknown':
				return !!permissions.editUnknownAccountingDocuments
			default:
				return true
		}
	}

	if (type === 'accdoc_remove' && !isDraft(row)) {
		switch (Number_AccountingDocumentDirection(row.direction)) {
			case 'issued':
				return !!permissions.removeIssuedAccountingDocuments
			case 'received':
				return !!permissions.removeReceivedAccountingDocuments
			case 'unknown':
				return !!permissions.removeUnknownAccountingDocuments
			default:
				return true
		}
	}

	if (type === 'accdoc_force_processing') {
		return !!permissions['processAccountingDocuments']
	}

	return true
}

export function getIsExtractionQueue(props: WithRouterProps) {
	return props.match.params.source && props.match.params.source === 'queue'
}

export function getContactEmails(contact: Contact): Array<string> {
	let emails: Array<string> = []

	if (contact && contact.persons) {
		contact.persons.forEach((p: ContactPerson) => {
			if (p.email) {
				emails.push(p.email)
			}
		})
	}

	if (contact && contact.email) {
		emails.push(contact.email)
	}

	if (contact && contact.addresses) {
		contact.addresses.forEach((a: ContactAddress) => {
			if (a.email) {
				emails.push(a.email)
			}
		})
	}
	emails = sortedUniq(emails)
	return emails
}

export function clearVatRatesInLineItem(lineItem: AccountingDocumentLineItem) {
	const adli = Object.assign({}, lineItem)
	delete adli.vatRate
	delete adli.vatRateType
	delete adli.vatRateId
	return adli
}

export function clearVatRatesInLineItems(accDoc: AccountingDocument): AccountingDocument {
	let result = accDoc
	if (accDoc && accDoc.lineItems) {
		const clearedLineItems = accDoc.lineItems.map((lineItem: AccountingDocumentLineItem) =>
			clearVatRatesInLineItem(lineItem),
		)
		result = { ...accDoc, lineItems: clearedLineItems }
	}
	return result
}
