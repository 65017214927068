// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[3].use[1]!../../../../../node_modules/postcss-loader/dist/cjs.js!../../../../variables/colors.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2BNFYEfkvy8rLlZrMxv5LK {\n\tposition: relative;\n\tmin-height: 14px;\n}\n\n._3p1J-p3alYAgkDJt6OYgPQ {\n\tdisplay: flex;\n\talign-items: center;\n\tfont-size: 14px;\n\tcolor: " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["blue"] + ";\n\tfont-weight: bold;\n\tcursor: pointer;\n\tmargin-left: -6px;\n\tmargin-right: -6px;\n\tbox-shadow: none !important;\n\tborder: 0 !important;\n\tbackground-color: transparent !important;\n}\n\n._1r2lS-aKRJS7ESVa41o9zI {\n\tdisplay: inline-block;\n\tpadding: 2px 0;\n}\n", "",{"version":3,"sources":["webpack://./modules/accounting-document/components/invoice-elements/add-bank-account.css"],"names":[],"mappings":"AAEA;CACC,kBAAA;CACA,gBAAA;AAWD;;AARA;CACC,aAAA;CACA,mBAAA;CACA,eAAA;CACA,mDAAA;CACA,iBAAA;CACA,eAAA;CACA,iBAAA;CACA,kBAAA;CACA,2BAAA;CACA,oBAAA;CACA,wCAAA;AAWD;;AARA;CACC,qBAAA;CACA,cAAA;AAWD","sourcesContent":["@value blue, blue100, blueFaded20 from \"variables/colors.css\";\n\n.root {\n\tposition: relative;\n\tmin-height: 14px;\n}\n\n.add {\n\tdisplay: flex;\n\talign-items: center;\n\tfont-size: 14px;\n\tcolor: blue;\n\tfont-weight: bold;\n\tcursor: pointer;\n\tmargin-left: -6px;\n\tmargin-right: -6px;\n\tbox-shadow: none !important;\n\tborder: 0 !important;\n\tbackground-color: transparent !important;\n}\n\n.addNext {\n\tdisplay: inline-block;\n\tpadding: 2px 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"blue": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["blue"] + "",
	"blue100": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["blue100"] + "",
	"blueFaded20": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["blueFaded20"] + "",
	"root": "_2BNFYEfkvy8rLlZrMxv5LK",
	"add": "_3p1J-p3alYAgkDJt6OYgPQ",
	"addNext": "_1r2lS-aKRJS7ESVa41o9zI"
};
export default ___CSS_LOADER_EXPORT___;
