// @flow
/** @jsx jsx */

import { jsx } from '@emotion/core'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import Button from 'components/button'

type Props = {|
	...WithTranslateProps,
	uniqueId: string,
	onDownload: (id: string) => void,
|}

function DownloadButton(props: Props) {
	function onDownload() {
		props.onDownload(props.uniqueId)
	}

	return (
		<span css={style.root}>
			<Button autoTestId="download-button" onClick={onDownload} labelText={props.t('grid.action.download_pdf')} />
		</span>
	)
}

const style = {
	root: {
		marginLeft: 25,
	},
}

export default withTranslate(DownloadButton)
