/* @flow */

import React, { Component } from 'react'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import type { OrganizationMember, Task, TasksSearchResult, UserProfile } from 'types'
import MyTasks from '../components/my-tasks'
import { internalUsersRoute, billingRoute } from '../routing/routes'
import OrganizationOverview from '../components/organization-overview'
import OrganizationStatisticsGrid from '../containers/organization-statistics-grid'
import StickyNotes from '../containers/sticky-notes'
import { Button, TriviLink, EmailInput } from 'components'
import styles from './internal-dashboard.css'
import { isEnabled } from 'utils/features'
import Helmet from 'react-helmet'

type Props = {|
	...WithTranslateProps,
	me: ?UserProfile,
	myTasks: ?TasksSearchResult,
	organizations: ?Array<OrganizationMember>,
	showExtract: boolean,
	showEditInternalUsers: boolean,
	canLogInAsUser: boolean,
	onTaskClick: (task: Task) => void,
	logAsUser: (login: string) => Promise<void>,
	changeCurrentOrganization: (organizationId: string) => Promise<void>,
	extractNextAccountingDocument: () => void,
	getAccountingDocumentsToExtractCount: () => Promise<number>,
|}

type State = {|
	accountingDocumentsCountToExtract: number,
	login: string,
|}

class InternalDashboard extends Component<Props, State> {
	state: State = {
		accountingDocumentsCountToExtract: 0,
		login: '',
	}

	UNSAFE_componentWillMount() {
		if (this.props.showExtract) {
			this.props.getAccountingDocumentsToExtractCount().then((accountingDocumentsCountToExtract: number) => {
				this.setState({ accountingDocumentsCountToExtract })
			})
		}
	}

	onLoginChange = (event: SyntheticInputEvent<HTMLInputElement>, value: ?string) => {
		if (value !== null && value !== undefined) {
			this.setState({ login: value })
		}
	}

	onLoginAsUser = () => {
		this.state.login && this.props.logAsUser(this.state.login)
	}

	render() {
		const { t, me, changeCurrentOrganization, myTasks, organizations } = this.props

		if (!organizations || !me) {
			return null
		}

		return (
			<div className={styles.root}>
				<Helmet>
					<title>{t('internal.header')}</title>
				</Helmet>
				<h1>{t('internal.header')}</h1>
				{this.props.canLogInAsUser && (
					<div className={styles.loginAs}>
						<div className={styles.MultiEmailInput}>
							<EmailInput
								labelText={t('internal.logInAsUser')}
								compact
								value={this.state.login}
								onChange={this.onLoginChange}
								autoTestId="internal-login-as-user"
							/>
						</div>
						<Button tertiary labelText={t('user.login.submitLabel')} onClick={this.onLoginAsUser} />
					</div>
				)}
				<div className={styles.container}>
					<div className={styles.row}>
						<OrganizationStatisticsGrid
							changeCurrentOrganization={changeCurrentOrganization}
							extractNextAccountingDocument={this.props.extractNextAccountingDocument}
							showExtract={this.props.showExtract}
							accountingDocumentsCountToExtract={this.state.accountingDocumentsCountToExtract}
							organizationsIds={
								this.props.organizations &&
								this.props.organizations.map((o: OrganizationMember) => o.organizationId || '')
							}
						/>
					</div>
					<div className={styles.row}>
						<div className={styles.left}>
							<StickyNotes />
						</div>
						<div className={styles.right}>
							<MyTasks myTasks={myTasks} organizations={organizations} onTaskClick={this.props.onTaskClick} />
						</div>
					</div>
					<div className={styles.row}>
						<div className={styles.full}>
							<OrganizationOverview changeCurrentOrganization={changeCurrentOrganization} currentUser={me} />
						</div>
					</div>
					<div className={styles.row}>
						{this.props.showEditInternalUsers && (
							<div className={styles.full}>
								<TriviLink routeFn={internalUsersRoute}>
									<Button tall wide labelText={t('internalUsers.header')} />
								</TriviLink>
							</div>
						)}
						{isEnabled('billings') && (
							<div className={styles.full}>
								<TriviLink routeFn={billingRoute}>
									<Button tall wide labelText={t('internal.billing')} />
								</TriviLink>
							</div>
						)}
					</div>
				</div>
			</div>
		)
	}
}

export default withTranslate(InternalDashboard)
