/* @flow */
/** @jsx jsx */

import { type WithTranslateProps, withTranslate } from 'wrappers'

import Avatar from 'components/avatar'
import Block from 'components/block/block'
import type { CashRegister } from 'types'
import CashRegisterItem from './cash-register-item'
import CashRegisterItemNew from './cash-register-item-new'
import { Component } from 'react'
import Separator from 'components/Separator'
import { colors } from 'variables'
import { jsx } from '@emotion/core'
import styles from './cash-register-grid.css'

export type Props = {|
	...WithTranslateProps,
	loading: boolean,
	isInternalUser: boolean,
	cashRegisters: Array<CashRegister>,
	loadCashRegisters: () => void,
|}

class CashRegisterGrid extends Component<Props> {
	componentDidMount() {
		this.props.loadCashRegisters()
	}

	renderLoader = () => {
		return (
			<div>
				<div className={styles.container}>
					<div className={styles.item}>
						<Block loading />
					</div>
					<div className={styles.item} css={style.light}>
						<Block loading />
					</div>
				</div>
			</div>
		)
	}

	renderItems = (cashRegisters: Array<CashRegister>) => {
		return cashRegisters.map((item: CashRegister) => {
			return (
				<div className={styles.item} key={item.id}>
					<CashRegisterItem key={item.id} cashRegister={item} isInternalUser={this.props.isInternalUser} />
				</div>
			)
		})
	}

	renderCashRegisters() {
		const { cashRegisters, t } = this.props
		const activeItems: Array<CashRegister> =
			(Array.isArray(cashRegisters) && cashRegisters.filter((item: CashRegister) => !!item.isActive)) || []
		const inactiveItems: Array<CashRegister> =
			(Array.isArray(cashRegisters) && cashRegisters.filter((item: CashRegister) => !item.isActive)) || []
		return (
			<div>
				<div className={styles.container}>
					{this.renderItems(activeItems)}
					<div className={styles.item}>
						<CashRegisterItemNew />
					</div>
				</div>

				{!!inactiveItems.length && (
					<div>
						<Separator
							left={
								<div className={styles.title}>
									{t('cashRegister.separatorInactive')}&nbsp;&nbsp;
									<Avatar size={20} backgroundColor={colors.grey600}>
										{inactiveItems.length}
									</Avatar>
								</div>
							}
						/>
						<div className={styles.inactiveContainer}>{this.renderItems(inactiveItems)}</div>
					</div>
				)}
			</div>
		)
	}

	render() {
		return this.props.loading && !(this.props.cashRegisters && this.props.cashRegisters.length)
			? this.renderLoader()
			: this.renderCashRegisters()
	}
}

const style = {
	light: { opacity: 0.5 },
}

export default withTranslate(CashRegisterGrid)
