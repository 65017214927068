// @flow
/** @jsx jsx */

import { jsx } from '@emotion/core'
import React, { Fragment, PureComponent, type Node } from 'react'
import memoize from 'memoize-one'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import { passAutoTestId, type AutoTestProps } from 'utils/tests/autotest'
import TextField from 'components/TextField'
import MenuItem from 'components/menu-item'
import MenuCategory from 'components/menu-category'
import Divider from 'components/divider'
import DropDownArrow from 'components/svg-icons/navigation/arrow-drop-down'
import IconButton from 'components/icon-button'
import Menu from 'components/menu'
import Popover from 'components/Popover/Popover'
import CloseIcon from 'components/svg-icons/navigation/close'
import { colors } from 'variables'

type Props = {|
	...WithTranslateProps,
	...AutoTestProps,
	autoWidth?: boolean,
	compact?: boolean,
	name?: string,
	inline?: boolean,
	children: ?Array<?React$Element<typeof MenuItem | typeof Divider | typeof MenuCategory>>,
	disabled?: boolean,
	clientError?: Node,
	labelText?: ?string,
	fullWidth?: boolean,
	hintStyle?: Object,
	hintText?: ?string,
	infoText?: ?string,
	infoTextAlign?: 'bottom-center' //eslint-disable-line
		| 'bottom-right'
		| 'center-left'
		| 'center-right'
		| 'top-center'
		| 'top-left'
		| 'top-left-center'
		| 'top-right'
		| 'top-right-center',
	infoTextAlignArrow?: 'bottom-center' | 'top-center' | 'center-left' | 'center-right',
	iconChildrenStyle?: Object,
	id?: string,
	maxHeight?: number,
	multiple?: boolean,
	nullable?: boolean,
	nullableWithoutValue?: boolean,
	style?: Object,
	value?: any,
	autoFocus?: boolean,
	filterable?: boolean,
	filterableByTextAndValue?: boolean,
	onBlur?: (?SyntheticInputEvent<HTMLInputElement>) => void,
	onChange?: (SyntheticEvent<HTMLElement>, ?number, any) => void,
	onFocus?: (SyntheticInputEvent<HTMLInputElement>) => void,
	onFilterChange?: (filter: ?string) => void,
|}

type State = {|
	open: boolean,
	filter: ?string,
	anchorEl: ?HTMLElement,
|}

class SelectField extends PureComponent<Props, State> {
	root: any = null
	menu: any = null
	arrow: any = null

	anchorOrigin = {
		vertical: 'top',
		horizontal: 'left',
	}

	static defaultProps = {
		maxHeight: 380,
		filterableByTextAndValue: false,
	}

	state = {
		open: false,
		filter: null,
		anchorEl: null,
	}

	handleClickControl = (event: MouseEvent) => {
		event.preventDefault()
		if (!this.props.disabled && event.detail > 0) {
			if (this.state.open) {
				this.close()
			} else {
				this.open(this.menu)
			}
		}
	}

	handleRequestCloseMenu = () => {
		this.close()
	}

	handleEscKeyDownMenu = () => {
		this.close()
	}

	handleItemClick = (event: SyntheticEvent<HTMLElement>, child: React$Element<any>, index: number) => {
		if (this.props.multiple) {
			this.state.open && this.open()
		} else {
			event.persist && event.persist()
			this.state.open &&
				this.setState({ open: false }, () => {
					this.close()
					this.props.onChange && this.props.onChange(event, index, child.props.value)
				})
		}
	}

	handleChange = (event: SyntheticEvent<HTMLElement>, value: any) => {
		this.props.multiple && this.props.onChange && this.props.onChange(event, null, value)
	}

	open = (anchorEl: ?HTMLElement) => {
		this.setState({ open: true, anchorEl: anchorEl || this.state.anchorEl })
	}

	close = () => {
		this.setState(
			{
				open: false,
				filter: '',
			},
			() => {
				this.props.onBlur && this.props.onBlur()
				this.arrow && this.arrow.focus()
			},
		)
	}

	focus = () => {
		this.arrow && this.arrow.focus()
	}

	isMenuAutoWidth = () => {
		return this.props.fullWidth ? false : this.props.autoWidth
	}

	renderNoDataNote() {
		const styles = this.getStyles(
			this.props.inline,
			this.props.fullWidth,
			this.props.disabled,
			this.props.compact,
			this.state.open,
			this.props.style,
		)
		return <p css={styles.note}>{this.props.t('application.noData')}</p>
	}

	renderNotFoundNote() {
		const styles = this.getStyles(
			this.props.inline,
			this.props.fullWidth,
			this.props.disabled,
			this.props.compact,
			this.state.open,
			this.props.style,
		)
		return <p css={styles.note}>{this.props.t('application.noItems')}</p>
	}

	renderMenu() {
		const { anchorEl } = this.state

		let menuStyle
		if (anchorEl && !this.isMenuAutoWidth()) {
			menuStyle = {
				minWidth: Math.max(80, anchorEl.clientWidth - 2), // Substract 2px because of popover's border (2x1px)
				width: 'auto',
			}
		}

		return (
			this.state.open && (
				<Menu
					multiple={this.props.multiple}
					value={this.props.value}
					onEscKeyDown={this.handleEscKeyDownMenu}
					style={menuStyle}
					onItemClick={this.handleItemClick}
					onChange={this.handleChange}
					autoWidth={this.isMenuAutoWidth()}
					{...passAutoTestId(this.props.autoTestId, 'menu')}
				>
					{[
						this.props.nullableWithoutValue || (this.props.nullable && this.props.value != null) ? (
							<MenuItem
								key={null}
								value={null}
								primaryText={
									<div>
										<CloseIcon style={{ position: 'relative', top: 3, marginRight: 7 }} disabled size={16} />
										{this.props.t('application.cancelSelection')}
									</div>
								}
							/>
						) : null,
						...(this.renderChildren() || []),
					]}
				</Menu>
			)
		)
	}

	bindRoot = (element: any) => {
		if (element) this.root = element
	}

	bindMenu = (element: any) => {
		if (element) this.menu = element
	}

	bindArrow = (element: any) => {
		if (element) this.arrow = element
	}

	onFilterChange = (event: SyntheticInputEvent<HTMLInputElement>, filter: ?string) => {
		this.setState({ filter })
		this.props.onFilterChange && this.props.onFilterChange(filter)
	}

	getCleanString(string: string): string {
		return string
			.replace(/\s/g, '')
			.toLowerCase()
			.normalize('NFD')
			.replace(/[\u0300-\u036f]/g, '')
	}

	renderChildren() {
		const { filter } = this.state
		if (!this.props.onFilterChange && this.props.children && this.isFilterable() && filter && filter.length > 0) {
			const cleanFilter = this.getCleanString(filter)
			const items =
				this.props.children &&
				// $FlowFixMe: typeof Divider was not changed but flow has problem now
				this.props.children.filter((child: ?React$Element<typeof MenuItem | typeof Divider | typeof MenuCategory>) => {
					if (child && child.type && child.type === MenuItem && child.props) {
						let matchFilter =
							child.props.primaryText &&
							typeof child.props.primaryText === 'string' &&
							this.getCleanString(child.props.primaryText).indexOf(cleanFilter) > -1
						if (this.props.filterableByTextAndValue) {
							matchFilter =
								matchFilter ||
								(child.props &&
									child.props.value &&
									typeof child.props.value === 'string' &&
									this.getCleanString(child.props.value).indexOf(cleanFilter) > -1)
						}
						return matchFilter
					}
					return false
				})
			return items
		} else {
			return this.props.children
		}
	}

	isFilterable = () => {
		return this.props.filterable && this.props.children && this.props.children.length > 10
	}

	renderDropDownMenu = () => {
		const styles = this.getStyles(
			this.props.inline,
			this.props.fullWidth,
			this.props.disabled,
			this.props.compact,
			this.state.open,
			this.props.style,
		)

		let displayValue = this.props.hintText || ''
		if (!this.props.multiple) {
			React.Children.forEach(this.props.children, (child: ?React$Element<any>) => {
				if (child && this.props.value === child.props.value) {
					displayValue = child.props.primaryText || this.props.hintText || ''
				}
			})
		} else {
			const values = []
			React.Children.forEach(this.props.children, (child: ?React$Element<any>) => {
				if (
					child &&
					this.props.value &&
					this.props.value.indexOf &&
					typeof this.props.value.indexOf === 'function' &&
					child.props.value != null &&
					this.props.value.indexOf(child.props.value) > -1
				) {
					values.push(child.props.primaryText)
				}
			})

			if (values && values.length) displayValue = values.join(', ')
		}

		const labelIsHintStyle = displayValue === this.props.hintText ? { color: colors.grey, ...this.props.hintStyle } : {}

		return (
			<div>
				<div ref={this.bindMenu} css={styles.dropDownMenu}>
					<div css={styles.control} onClick={this.handleClickControl}>
						{displayValue && (
							<div
								css={{
									...styles.hint,
									...labelIsHintStyle,
								}}
							>
								{displayValue}
							</div>
						)}
						<IconButton disabled={this.props.disabled} ref={this.bindArrow} style={styles.icon}>
							<DropDownArrow style={(styles.iconChildren, this.props.iconChildrenStyle)} />
						</IconButton>
					</div>
					<Popover
						anchorOrigin={this.anchorOrigin}
						anchorEl={this.state.anchorEl}
						open={this.state.open}
						onRequestClose={this.handleRequestCloseMenu}
						maxHeight={this.props.maxHeight}
						zDepth={4}
						{...passAutoTestId(this.props.autoTestId, 'popover')}
					>
						{this.isFilterable() && (
							<div css={styles.search}>
								<TextField
									fullWidth
									autoFocus
									hintText={this.props.t('application.filter.searchHint')}
									onChange={this.onFilterChange}
									disabled={this.props.disabled}
									style={styles.searchField}
									value={this.state.filter}
								/>
							</div>
						)}

						{this.props.children ? this.renderMenu() : this.renderNoDataNote()}
					</Popover>
				</div>
			</div>
		)
	}

	getStyles = memoize(
		(
			inline: ?boolean,
			fullWidth: ?boolean,
			disabled: ?boolean,
			compact: ?boolean,
			open: boolean,
			extraStyle: ?Object,
		) => {
			const styles = {
				control: {
					cursor: disabled ? 'not-allowed' : 'pointer',
					height: 24,
					position: 'relative',
					whiteSpace: 'nowrap',
					display: 'flex',
					justifyContent: 'flex-end',
					alignContent: 'center',
					alignItems: 'center',
				},
				icon: {
					fill: 'inherit',
					padding: 0,
					width: 24,
					height: 24,
					borderWidth: 0,
					flex: '0 0 24px',
					display: disabled ? 'none' : 'block',
				},
				iconChildren: {
					fill: 'inherit',
				},
				hint: {
					height: 'auto',
					minHeight: 24,
					lineHeight: '24px',
					overflow: 'hidden',
					opacity: open ? 0 : 1,
					position: 'relative',
					paddingRight: 4,
					textOverflow: 'ellipsis',
					whiteSpace: 'nowrap',
					flex: '1 1 100%',
				},
				note: {
					minWidth: 200,
					textAlign: 'center',
					paddingLeft: 15,
					paddingRight: 15,
					fontWeight: 'normal',
				},
				dropDownMenu: {
					display: 'block',
					fontSize: 'inherit',
					height: 'auto',
					outline: 'none',
					position: 'relative',
					transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1)',
					verticalAlign: 'middle',
					opacity: open ? 1 : undefined,
					width: 'auto',
					overflow: inline ? 'hidden' : 'visible',
					padding: inline ? 0 : compact ? '4px 8px' : '11px 18px',
				},
				root: {
					fontSize: compact && !inline ? 14 : 'inherit',
					lineHeight: '24px',
					width: fullWidth ? '100%' : 'auto',
					margin: 0,
					padding: 0,
					pointerEvents: disabled ? 'none' : 'all',
					...extraStyle,
				},
				input: {
					display: 'block',
					width: 'auto',
					overflow: inline ? 'hidden' : 'visible',
				},
				search: {
					margin: '15px 15px 10px 15px',
				},
				searchField: {
					borderRadius: 16,
					fontSize: 12,
					height: 32,
					padding: '4px 12px',
				},
				label: {
					marginRight: 4,
					cursor: 'pointer',
					verticalAlign: 'middle',
				},
			}

			if (!inline) {
				styles.input = { ...styles.input, padding: compact ? '4px 8px' : '11px 18px' }
			}

			return styles
		},
	)

	render = () => {
		const styles = this.getStyles(
			this.props.inline,
			this.props.fullWidth,
			this.props.disabled,
			this.props.compact,
			this.state.open,
			this.props.style,
		)

		return (
			<Fragment>
				{this.props.inline && this.props.labelText && (
					<span onClick={this.handleClickControl} css={styles.label}>
						{this.props.labelText}
					</span>
				)}
				<TextField
					style={styles.root}
					disabled={this.props.disabled}
					labelText={!this.props.inline ? this.props.labelText : null}
					hintStyle={this.props.hintStyle}
					fullWidth={this.props.fullWidth}
					clientError={this.props.clientError}
					infoText={!this.state.open ? this.props.infoText : null}
					infoTextAlign={this.props.infoTextAlign}
					infoTextAlignArrow={this.props.infoTextAlignArrow}
					onFocus={this.props.onFocus}
					onBlur={this.props.onBlur}
					id={this.props.id}
					inline={this.props.inline}
					inputStyle={styles.input}
					compact={this.props.compact}
					ref={this.bindRoot}
					name={this.props.name}
					autoTestId={this.props.autoTestId}
					disableHover
					{...passAutoTestId(this.props.autoTestId, 'text-field')}
				>
					{this.renderDropDownMenu()}
				</TextField>
			</Fragment>
		)
	}
}

export default withTranslate(SelectField, { withRef: true })
