/* @flow */
/** @jsx jsx */

import React, { Component } from 'react'
import { jsx } from '@emotion/core'
import PathMatch from 'path-match'
import { parsePhoneNumber } from 'libphonenumber-js'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import { autoTestId, type AutoTestProps } from 'utils/tests/autotest'
import UserProfileAvatar from 'modules/user/components/user-icon/user-profile-avatar'
import PhoneIcon from 'components/svg-icons/communication/phone'
import EmailIcon from 'components/svg-icons/communication/email'
import CopyToClipboard from 'components/copy-to-clipboard'
import { colors } from 'variables'
import styles2 from './support.css'
import NavigationChevronRight from 'components/svg-icons/navigation/chevron-right'
import NavigationChevronLeft from 'components/svg-icons/navigation/chevron-left'
import { MAIN_ACCOUNTANT_EMAIL, WAGES_ACCOUNTANT_EMAIL } from 'trivi-constants'

type Props = {|
	...AutoTestProps,
	...WithTranslateProps,
	firstname?: string,
	lastname?: string,
	email?: string,
	phone?: string,
	id?: string,
	avatarUrl?: string,
	accountantType?: 'wages' | 'main',
	organizationId: ?string,
	loadAvatar: (avatarId: string) => Promise<void>,
|}

type State = {|
	open: boolean,
	hovered: boolean,
	organizationId: ?string,
|}

const matchRoute = PathMatch({ sensitive: false, strict: true, end: false })

const DISABLED_ROUTES: Array<string> = [
	'/:organizationId/accounting-documents/templates/:id/edit',
	'/:organizationId/bank-account/:id/edit',
	'/:organizationId/cash-register/create',
	'/tasks/create',
	'/tasks/:id/edit',
	'/:organizationId/file/:fileId',
	'/:organizationId/cash-register/:id/edit',
	'/:organizationId/bank-account/create',
	'/:organizationId/accounting-documents/:id/detail',
	'/:organizationId/accounting-documents/:id/edit',
	'/:organizationId/accounting-documents/:id/preview',
	'/:organizationId/address-book/:id',
	'/:organizationId/accounting-documents/:direction/:type/create',
	'/:organizationId/documents-extraction/:id',
]

class Support extends Component<Props, State> {
	box: ?HTMLElement = null
	anchorOrigin = { horizontal: 'right', vertical: 'bottom' }
	targetOrigin = { horizontal: 'right', vertical: 'top' }

	state = {
		open: false,
		hovered: false,
		organizationId: null,
	}

	async componentWillReceiveProps(nextProps: Props) {
		if (nextProps && nextProps.id && nextProps.organizationId !== this.state.organizationId) {
			if (nextProps.avatarUrl === null || nextProps.avatarUrl === undefined) {
				await nextProps.loadAvatar(nextProps.id)
			}
			this.setState({
				open: false,
				organizationId: nextProps.organizationId,
			})
		}
	}

	handleOnRequestClose = () => {
		this.setState({
			open: false,
		})
	}

	onOpenClick = () => {
		this.setState({ open: true })
	}

	onCloseClick = () => {
		this.setState({ open: false, hovered: false })
	}

	handleMouseEnter = () => {
		this.setState({
			hovered: true,
		})
	}

	handleMouseOut = () => {
		this.setState({
			hovered: false,
		})
	}

	bindBox = (element: ?HTMLElement) => {
		this.box = element
	}

	isHidden = () => {
		const pathName = window.location.pathname

		return !!DISABLED_ROUTES.find((route: string) => {
			return matchRoute(route)(pathName)
		})
	}

	isReachable = () => {
		return !!this.props.email || !!this.props.phone
	}

	render() {
		if (this.isHidden()) return null
		if (!this.props.firstname && !this.props.lastname && !this.props.phone) {
			return null
		}

		const { t, accountantType, phone } = this.props

		const name = `${this.props.firstname || ''} ${this.props.lastname || ''}`
		const styles = getStyles(this.props, this.state)

		const accountantEmail = accountantType === 'main' ? MAIN_ACCOUNTANT_EMAIL : WAGES_ACCOUNTANT_EMAIL

		return (
			<div css={styles.root} {...autoTestId(this.props.autoTestId)}>
				{this.state.open ? (
					<div className={`${styles2.box} ${styles2.boxOpen}`} ref={this.bindBox}>
						<div
							css={styles.arrow}
							onClick={this.isReachable() ? this.onCloseClick : null}
							onMouseEnter={this.handleMouseEnter}
							onMouseLeave={this.handleMouseOut}
						>
							{this.state.hovered ? (
								<NavigationChevronRight size={20} color={colors.black} hoverColor={colors.black} />
							) : (
								<NavigationChevronRight size={20} color={colors.white} hoverColor={colors.white} />
							)}
						</div>
						<div>
							{accountantType === 'wages' ? t('support.wagesAccountant') : t('support.yourAccountant')}
							{!this.isReachable() ? <br /> : null}
							{` ${name}`}
							<div css={styles.openLabel}>
								{phone && (
									<>
										<div css={styles.icon}>
											<PhoneIcon size={11} color={colors.white} disabled />
										</div>
										{parsePhoneNumber(phone, 'CZ').formatNational()}
									</>
								)}
								<>
									{phone && <div css={styles.shiftedIcon} />}
									<div css={styles.icon}>
										<EmailIcon size={11} color={colors.white} disabled />
									</div>
									<CopyToClipboard
										copyTooltip={t('application.emailCopy')}
										copiedTooltip={t('application.emailCopied')}
										textToCopy={accountantEmail}
									>
										{accountantEmail}
									</CopyToClipboard>
								</>
							</div>
						</div>
						<div css={styles.avatar}>
							<UserProfileAvatar name={name} avatar={this.props.avatarUrl} size={40} style={styles.userProfileAvatar} />
						</div>
					</div>
				) : (
					<div
						className={`${styles2.box}  ${styles2.boxClosed}`}
						ref={this.bindBox}
						onClick={this.isReachable() ? this.onOpenClick : null}
					>
						<div css={styles.arrow}>
							<NavigationChevronLeft color={colors.black} hoverColor={colors.black} size={20} />
						</div>
						{this.props.accountantType === 'wages' ? (
							<div css={styles.closedLabel}>{t('support.wages')}</div>
						) : (
							<div css={styles.closedLabel}>{t('support.yourAccountant')}</div>
						)}
						<div css={styles.avatar}>
							<UserProfileAvatar name={name} avatar={this.props.avatarUrl} size={40} style={styles.userProfileAvatar} />
						</div>
					</div>
				)}
			</div>
		)
	}
}

function getStyles(props: Props, state: State) {
	const { open } = state
	const wages = props.accountantType === 'wages'

	return {
		root: {
			marginLeft: wages ? 20 : 0,
		},
		avatar: {
			position: 'absolute',
			top: -1,
			right: -1,
			pointerEvents: 'none',
		},
		userProfileAvatar: {
			border: `1px solid ${colors.white}`,
			pointerEvents: 'none',
		},
		contact: {
			fontWeight: 'bold',
			pointerEvents: 'none',
		},
		availability: {
			fontSize: 9,
			color: colors.white,
			textAlign: 'center',
			lineHeight: '26px',
			padding: '0 10px',
			background: colors.blue,
		},
		contacts: {
			padding: 5,
			fontSize: 12,
			color: colors.black,
			lineHeight: '16px',
			display: 'flex',
		},
		column: {
			flex: '0 0 auto',
			padding: 5,
		},
		openLabel: {
			fontWeight: 'bold',
			display: 'flex',
			justifyContent: 'flex-end',
			alignItems: 'center',
		},
		closedLabel: {
			display: 'flex',
			justifyContent: 'flex-end',
			alignItems: 'center',
		},
		icon: {
			fontWeight: 'bold',
			marginRight: 4,
			height: 14,
			position: 'relative',
			top: 1,
		},
		shiftedIcon: {
			marginLeft: 5,
		},
		arrow: {
			cursor: 'pointer',
			margin: open ? '0 25px 0 -15px' : '0 0 0 -15px',
			width: 20,
			height: 20,
		},
	}
}

export default withTranslate(Support)
