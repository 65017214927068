/* @flow */

import type {
	BankAction,
	DataGridId,
	DataGridNextAction,
	Dispatch,
	FileAction,
	Filter,
	State,
	Statement,
	StatementsView,
} from 'types'
import {
	changeStatementsView,
	deleteStatement,
	processStatement,
	renameStatement,
	toggleStatementOptionsDialog,
	updateProccesStateStatement,
	uploadStatement,
} from 'modules/bank/actions'
import { currentUserIsAdmin, currentUserIsInternal } from 'modules/user/selectors'
import { getBackAccountCode, getStatementsView } from 'modules/bank/selectors'

import BankStatementsGrid from './bank-statements-grid-component'
import type { DataType } from '../../types'
import Features from 'utils/features'
import { connect } from 'react-redux'
import { refreshGridData } from '../../actions'
import { userHasAccess } from 'permissions'

type OwnProps = {
	id: string,
}

type StateProps = {|
	uploadInProgress: boolean,
	deleteInProgress: boolean,
	statementOptionsDialogOpen: boolean,
	statementOptionsButton: boolean,
	showUpload: boolean,
	canDeleteStatement: boolean,
	canProcessStatement: boolean,
	canManageCsas: boolean,
	view: StatementsView,
	isUserInternal: boolean,
	isUserAdmin: boolean,
|}
const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => {
	const uploadInProgress: boolean =
		state.bank.uploadedStatements.bankAccountId && state.bank.uploadedStatements.bankAccountId == ownProps.id
			? state.bank.uploadedStatements.loading
			: false
	const deleteInProgress: boolean =
		state.bank.deletedStatement.bankAccountId && state.bank.deletedStatement.bankAccountId == ownProps.id
			? state.bank.deletedStatement.loading
			: false

	const canEditBankAccounts: boolean = userHasAccess(state, 'editBankAccounts')
	const accountCode: ?string = getBackAccountCode(state)

	return {
		isUserInternal: currentUserIsInternal(state),
		isUserAdmin: currentUserIsAdmin(state),
		uploadInProgress: uploadInProgress,
		deleteInProgress: deleteInProgress,
		statementOptionsDialogOpen: state.bank.statementOptions.dialogOpen,
		statementOptionsButton:
			!!accountCode &&
			accountCode.toString() === '2010' &&
			Features.isEnabled('bankAccountStatementOptions') &&
			canEditBankAccounts,
		showUpload: canEditBankAccounts,
		canDeleteStatement: canEditBankAccounts,
		canProcessStatement: canEditBankAccounts,
		canManageCsas:
			!!accountCode &&
			accountCode.toString() === '0800' &&
			userHasAccess(state, 'manageCsas') &&
			Features.isEnabled('bankAccountStatementOptions'),
		view: getStatementsView(),
	}
}

type DispatchProps = {|
	uploadStatements: (files: Array<File>, id: string) => Promise<void>,
	deleteStatement: (bankAccountId: string, id: string) => Promise<void>,
	renameStatement: (statementId: string, bankAccountId: string, id: string) => Promise<void>,
	processStatement: (bankAccountId: string, id: string) => Promise<void>,
	updateProccesStateStatement: (bankAccountId: string, statmentId: string, processState: string) => Promise<void>,
	statementOptionsDialogToggle: (state: 'OPEN' | 'CLOSE', format: 'pdf' | 'gpc' | 'cba_xml') => void,
	refreshGrid: (gridId: DataGridId, gridDataType: DataType, defaultFilter?: Filter) => void,
	onViewChange?: (view: StatementsView) => void,
|}

const mapDispatchToProps = (dispatch: Dispatch<FileAction | BankAction | DataGridNextAction>): DispatchProps => {
	return {
		uploadStatements: (files: Array<File>, id: string): Promise<void> => {
			const bankAccountId: string = id
			const statements = files.map((file: File) => {
				const statement: Statement = {
					name: file.name,
					processingState: 0,
				}
				return {
					file: file,
					statement: statement,
				}
			})
			const uploadDispatch = dispatch(uploadStatement(bankAccountId, statements))
			return uploadDispatch
		},
		deleteStatement: (bankAccountId: string, id: string): Promise<void> => {
			return dispatch(deleteStatement(id, bankAccountId))
		},
		renameStatement: (statementId: string, bankAccountId: string, newStatementName: string): Promise<void> => {
			return dispatch(renameStatement(statementId, bankAccountId, newStatementName))
		},
		processStatement: (bankAccountId: string, id: string): Promise<void> => {
			return dispatch(processStatement(id, bankAccountId))
		},
		updateProccesStateStatement: (bankAccountId: string, statmentId: string, processState: string): Promise<void> => {
			return dispatch(updateProccesStateStatement(statmentId, bankAccountId, processState))
		},
		statementOptionsDialogToggle: (state: 'OPEN' | 'CLOSE', format: 'pdf' | 'gpc' | 'cba_xml'): void => {
			dispatch(toggleStatementOptionsDialog(state, format))
		},
		refreshGrid: (gridId: DataGridId, gridDataType: DataType, defaultFilter?: Filter) => {
			dispatch(refreshGridData(gridDataType, gridId, gridId, defaultFilter))
		},
		onViewChange: (view: StatementsView): void => {
			dispatch(changeStatementsView(view))
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(BankStatementsGrid)
