// @flow

import React, { Component } from 'react'
import TableRow from './table-row'

type Props = {|
	children: React$Element<typeof TableRow> | Array<React$Element<typeof TableRow>>,
|}

class TableHeader extends Component<Props> {
	render() {
		return <thead>{this.props.children}</thead>
	}
}

export default TableHeader
