/* @flow */

// $FlowFixMe - there is useMemo
import React, { type Node, useMemo } from 'react'
import type { AccountingDocumentDirection } from 'types'
import { ACCOUNTING_DOCUMENT_KNOWN_DIRECTION_TYPES, AccountingDocumentDirection_Number } from 'types/convertor'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import SelectNext, { type ValueType } from 'components/select-next'

type Props = {|
	...WithTranslateProps,
	value: ?AccountingDocumentDirection | string | number,
	clientError?: Node,
	isCashRegister?: boolean,
	currentUserIsInternal: boolean,
	onChange: (type: number) => void,
|}

const entries = Object.entries(ACCOUNTING_DOCUMENT_KNOWN_DIRECTION_TYPES)

function AccountingDocumentDirectionSelector(props: Props) {
	const documentDirection =
		props.isCashRegister || props.currentUserIsInternal ? props.value : AccountingDocumentDirection_Number('issued')

	const { t } = props
	const TRANSLATE_SUBKEY = props.isCashRegister ? 'directionsCashRegister' : 'directions'

	function handleChange(value: ValueType) {
		const newDirection: ?string = value && !Array.isArray(value) ? value.value : null
		newDirection && props.onChange(parseInt(newDirection))
	}

	const options = useMemo(
		() =>
			entries.map(([type: string, name: string]: any) => ({
				value: type,
				label: t(`accountingDocument.${TRANSLATE_SUBKEY}.${name}`),
			})),
		[t, TRANSLATE_SUBKEY],
	)

	return (
		<SelectNext
			error={props.clientError}
			label={t('accountingDocument.filter.direction.label')}
			placeholder={t('invoice.classification.selectDirection')}
			onChange={handleChange}
			value={null != documentDirection ? String(documentDirection) : null}
			options={options}
		/>
	)
}

export default withTranslate(AccountingDocumentDirectionSelector)
