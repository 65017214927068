// @flow
import React from 'react'
import type { ActivityResponse } from 'types'
import { Tooltip, CircularProgress } from 'components'
import styles from './activities.css'
import { getInitialsFromName } from 'utils'
import { formatToDateTimeString } from 'utils/formatters'
import { withTranslate, type WithTranslateProps } from 'wrappers'

type Props = {|
	activities: Array<ActivityResponse>,
	loading?: boolean,
|}

type State = {|
	activities: Array<ExtendedActivity>,
|}

type ExtendedActivity = {
	...ActivityResponse,
	visible: boolean,
}

class Activities extends React.Component<Props & WithTranslateProps, State> {
	state = {
		activities: [],
	}

	UNSAFE_componentWillMount() {
		this.mapPropsToState(this.props)
	}

	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		if (nextProps !== this.props) {
			this.mapPropsToState(nextProps)
		}
	}

	mapPropsToState = (props: Props) => {
		const { activities } = props
		const extendedActivities: Array<ExtendedActivity> = activities.map((activity: ActivityResponse) => ({
			...activity,
			visible: false,
		}))
		this.setState({ activities: extendedActivities })
	}

	handleHover = (visible: boolean, activityIndex: number) => () => {
		const { activities } = this.state
		const updatedActivities: Array<ExtendedActivity> = activities.map((i: ExtendedActivity, index: number) => {
			if (index === activityIndex) {
				return {
					...i,
					visible,
				}
			}
			return i
		})
		this.setState({ activities: updatedActivities })
	}

	render() {
		const { activities } = this.state
		const { t, loading } = this.props
		return (
			<div className={styles.activities}>
				{loading && (
					<div className={styles.loadingBar}>
						<CircularProgress />
					</div>
				)}
				<div className={styles.border} />
				{activities.map((item: ExtendedActivity, index: number) => {
					const { authorName, name, time, visible, parameters } = item
					const destinationState = parameters && parameters.destinationState ? parameters.destinationState : ''
					const initials = authorName ? getInitialsFromName(authorName) : ''
					return (
						<div className={styles.activity} key={index}>
							<Tooltip visible={visible} label={authorName} inline>
								<div
									onMouseEnter={this.handleHover(true, index)}
									onMouseLeave={this.handleHover(false, index)}
									className={styles.avatar}
								>
									{initials}
								</div>
							</Tooltip>
							<div>
								{name && <b>{t(`accountingDocument.activities.${name}`, { state: destinationState })}</b>}
								{time && <div>{formatToDateTimeString(time)}</div>}
							</div>
						</div>
					)
				})}
			</div>
		)
	}
}

export default withTranslate(Activities)
