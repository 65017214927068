/* @flow */

import React, { Component } from 'react'
import { find } from 'lodash-es'
import { translate } from 'react-i18next'
import type { I18NextProps, StickyNote } from 'types'
import CheckIcon from 'components/svg-icons/navigation/check'
import type { Color } from './simple-color-picker'
import ContentClear from 'components/svg-icons/content/clear'
import { IconButton } from 'components'
import RenewIcon from 'components/svg-icons/action/autorenew'
import SimpleColorPicker from './simple-color-picker'
import styles from './sticky-note.css'

export type Props = {|
	stickyNote: StickyNote,
	onChange: StickyNote => void,
	onRemove: StickyNote => void,
	upperFontSizeLimit: number,
|}

class StickyNoteItem extends Component<Props & I18NextProps> {
	updateTimer: ?TimeoutID = null
	updateAction: ?() => void

	clearTimer = () => {
		if (this.updateTimer != null) {
			clearTimeout(this.updateTimer)
			this.updateTimer = null
		}
	}
	finalizeAction = () => {
		if (this.updateAction != null) {
			this.updateAction()
			this.updateAction = null
		}
	}

	setAction = (action: () => void, useTimer: boolean) => {
		if (useTimer) {
			this.updateAction = action
			if (this.updateTimer == null)
				this.updateTimer = setTimeout(() => {
					this.finalizeAction()
					this.clearTimer()
				}, 1500)
		} else {
			this.clearTimer()
			this.finalizeAction()

			action()
		}
	}

	componentWillUnmount() {
		this.finalizeAction()
		this.clearTimer()
	}

	textPre: ?HTMLElement = null
	bindTextPre = (pre: ?HTMLElement) => {
		this.textPre = pre
		this.adjustFontSize(pre)
	}
	focus = () => {
		if (this.textPre != null) this.textPre.focus()
	}

	constructor(props: Props & I18NextProps) {
		super(props)
	}

	shouldComponentUpdate(nextProps: Props & I18NextProps) {
		const shouldUpdate = this.props.stickyNote.color != nextProps.stickyNote.color //$FlowFixMe
		return shouldUpdate
	}

	hanleColorChange = (color: Color) => {
		const { onChange, stickyNote } = this.props
		let text: string = stickyNote.text || ''
		if (this.textPre != null) {
			text = this.textPre.innerText || ''
		}

		const newNote = Object.assign(getEmptyStickyNote(), stickyNote, { color: color.id, text: text })
		this.setAction(() => onChange((newNote: StickyNote)), false)
	}

	getHandleKeyUp = () => {
		const { onChange, stickyNote } = this.props

		return () => {
			if (this.textPre != null) {
				const text: string = this.textPre.innerText || ''
				const newNote = Object.assign(getEmptyStickyNote(), stickyNote, { text })
				const update = () => onChange(newNote)

				this.setAction(update, true)
				this.adjustFontSize(this.textPre)
			}
		}
	}

	handleToggleNoteState = () => {
		const { onChange, stickyNote } = this.props
		const nextNoteState = stickyNote.state == 1 ? 2 : 1
		let text: ?string = stickyNote.text
		if (this.textPre != null) {
			text = this.textPre.innerText || ''
		}

		const newNote = Object.assign({}, (stickyNote: any), { state: nextNoteState, text })
		this.setAction(() => onChange((newNote: StickyNote)), false)
	}

	handleRemoveNote = () => {
		this.props.onRemove(this.props.stickyNote)
	}

	adjustFontSize(element: ?HTMLElement) {
		if (element == null || element.clientHeight == 0) return

		const parentHeight = element.parentElement != null ? element.parentElement.clientHeight : 270
		const noteHeightLimit = parentHeight * 0.8

		while (element.clientHeight < noteHeightLimit) {
			const fontSize = element.style.fontSize
			const size = parseFloat(fontSize.replace('px', ''))
			if (this.props.upperFontSizeLimit < size) break
			element.style.fontSize = `${size + 1}px`
		}
		while (element.clientHeight > noteHeightLimit) {
			const fontSize = element.style.fontSize
			const size = parseFloat(fontSize.replace('px', ''))
			element.style.fontSize = `${size - 1}px`
		}
	}

	renderActionButton() {
		return (
			<span className={styles.complete}>
				<IconButton
					tooltip={
						this.props.stickyNote.state === 1
							? this.props.t('internal.widget.stickyNotes.setAsDoneTooltip')
							: this.props.t('internal.widget.stickyNotes.setAsOpenTooltip')
					}
					onClick={this.handleToggleNoteState}
					circled
					style={{
						width: '100%',
						height: '100%',
					}}
				>
					{this.props.stickyNote.state === 1 ? <CheckIcon size={18} /> : <RenewIcon size={18} />}
				</IconButton>
			</span>
		)
	}

	render() {
		const { stickyNote } = this.props

		const colors = [
			{
				id: '#C8EA50',
				value: '#C8EA50',
			},
			{
				id: '#FED130',
				value: '#FED130',
			},
			{
				id: '#FA45A5',
				value: '#FA45A5',
			},
			{
				id: '#96D9F8',
				value: '#96D9F8',
			},
		]

		const selectedColor = find(colors, (_color: Color) => _color.id == (stickyNote.color || '1')) || colors[0]
		const style = {
			backgroundColor: selectedColor.value,
		}

		return (
			<div className={styles.container} style={style}>
				<pre
					ref={this.bindTextPre}
					className={styles.note}
					onKeyUp={this.getHandleKeyUp()}
					suppressContentEditableWarning
					contentEditable
					style={{ fontSize: '12px' }}
					data-plc={this.props.t('internal.widget.stickyNotes.defaultText')}
				>
					{stickyNote.text}
				</pre>
				<div className={styles.colorPicker}>
					<SimpleColorPicker colors={colors} onChange={this.hanleColorChange} selected={selectedColor} />
				</div>
				{this.renderActionButton()}
				<span className={styles.delete}>
					<IconButton
						tooltip={this.props.t('internal.widget.stickyNotes.deleteTooltip')}
						onClick={this.handleRemoveNote}
						circled
						style={{
							width: '100%',
							height: '100%',
						}}
					>
						<ContentClear size={18} />
					</IconButton>
				</span>
				<div className={styles.corner} />
			</div>
		)
	}
}
export default translate(undefined, { withRef: true })(StickyNoteItem)

const getEmptyStickyNote: () => StickyNote = () => {
	return { text: '' }
}
