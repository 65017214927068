/* @flow */

// $FlowFixMe - there is ReactNode
import React, { Component, type Node, type ReactNode } from 'react'

import InfoIcon from 'components/svg-icons/trivi/input/info'
import Paper from 'components/Paper'
import type { SearchFilter } from 'types'
import Toggle from 'components/toggle'
import Tooltip from 'components/tooltip'
import { colors } from 'variables'

type Props = {|
	name: string,
	style?: {},
	className?: string,
	fieldStyle?: {},
	filter?: SearchFilter,
	hintText?: string,
	labelText?: string,
	clientError?: Node,
	inline?: boolean,
	onChange: (filter: SearchFilter) => void,
	onValueChange?: (event: SyntheticEvent<HTMLInputElement>, value: ?boolean) => void,
	header?: ReactNode,
|}

type State = {|
	value: boolean,
|}

class FilterToggle extends Component<Props, State> {
	static defaultProps = {
		onChange: () => {},
	}

	state = {
		value: (this.props.filter && this.props.filter.value) ?? false,
	}

	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		if (this.props.filter !== nextProps.filter) {
			this.setState({
				value: (nextProps.filter && nextProps.filter.value) ?? false,
			})
		}
	}

	handleTextFieldChange = (event: SyntheticEvent<HTMLInputElement>, value: ?boolean) => {
		this.setState({
			value: value ?? false,
		})

		this.props.onValueChange && this.props.onValueChange(event, !value)
		this.fireChange()
	}

	fireChange() {
		const value = this.state.value

		const filter: SearchFilter = {
			field: this.props.name,
		}

		filter.value = !value

		this.props.onChange(filter)
	}

	render() {
		return (
			<div style={styles.root}>
				{this.props.header}
				<Tooltip label={this.props.labelText} multiLine wrapperStyle={styles.tooltip}>
					<InfoIcon size={15} />
				</Tooltip>
				<Toggle autoWidth onToggle={this.handleTextFieldChange} toggled={!this.state.value} />
			</div>
		)
	}
}

const styles = {
	root: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		userSelect: 'none',
		whiteSpace: 'nowrap',
		fontSize: 11,
		marginRight: 20,
	},
	tooltip: {
		display: 'flex',
		alignItems: 'center',
		padding: '0px 15px 0px 5px',
	},
}

export default FilterToggle
