//@flow

import type { CrpdphSearchResult, Dispatch } from 'types'
import type { Action as CountryVariantAction } from './country-variant-action-types'

import { crpdph as crpdphApi } from 'modules/common/models/api-model'

export function searchCRPDPH_DB(companyVATRegNo: string) {
	return async (dispatch: Dispatch<CountryVariantAction>) => {
		dispatch({
			type: 'START_SEARCH_CONTACT_IN_CRPDPH_DB',
			companyVATRegNo,
		})
		try {
			const response: CrpdphSearchResult = await crpdphApi.post({ companyVATRegNo })
			return dispatch({
				type: 'FINISH_SEARCH_CONTACT_IN_CRPDPH_DB',
				companyVATRegNo,
				response,
			})
		} catch (error) {
			return dispatch({
				type: 'FINISH_SEARCH_CONTACT_IN_CRPDPH_DB',
				companyVATRegNo,
				serverError: error,
			})
		}
	}
}
