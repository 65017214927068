/* @flow */

import React, { Component } from 'react'
import { connect } from 'react-redux'
import type {
	AccountingDocument,
	AccountingDocumentAssignedDirection,
	AccountingDocumentContact,
	FormFieldErrorContext,
} from 'types'
import { emptyAccountingDocument } from 'types/empty'
import Contact from '../invoice-elements/contact/contact'

type OwnProps = {|
	formFieldErrorContext?: FormFieldErrorContext,
	accountingDocument: AccountingDocument,
	onChange: (accountingDocument: AccountingDocument) => void,
	direction: AccountingDocumentAssignedDirection,
	isSupplier?: boolean,
|}

type ComponentProps = OwnProps

class ContactTemplate extends Component<ComponentProps> {
	onChange = (contact: AccountingDocumentContact) => {
		const newAccDoc: AccountingDocument = {
			...emptyAccountingDocument(),
			...this.props.accountingDocument,
			...{
				contact,
			},
		}

		this.props.onChange(newAccDoc)
	}

	render() {
		return (
			<Contact
				direction={this.props.direction}
				readonly={false}
				contact={this.props.accountingDocument.contact}
				onChange={this.onChange}
				isSupplier={this.props.isSupplier}
				formFieldErrorContext={this.props.formFieldErrorContext}
			/>
		)
	}
}

export default connect()(ContactTemplate)
