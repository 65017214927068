/* @flow */

import React, { Component, type Node } from 'react'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import SelectNext, { type OptionsType, type OptionType, type ValueType } from 'components/select-next'
import type { CashRegister } from 'types'
import memoize from 'memoize-one'

type Props = {
	...WithTranslateProps,
	cashRegisters?: ?Array<CashRegister>,
	autoWidth?: boolean,
	inline?: boolean,
	value: ?string,
	isClearable?: boolean,
	placeholder?: ?string,
	autoSelect?: boolean,
	compact?: boolean,
	label?: string,
	error?: ?Node,
	onChange: (cashRegisterId: ?string) => void,
	loadCashRegisters?: () => void,
}

class CashRegisterSelector extends Component<Props> {
	componentDidMount() {
		if (this.hasCashRegisters() && this.props.autoSelect) {
			this.autoSelect()
		} else {
			this.props.loadCashRegisters && this.props.loadCashRegisters()
		}
	}

	componentDidUpdate(prevProps: Props) {
		if (
			this.props.autoSelect &&
			(!prevProps.cashRegisters || !prevProps.cashRegisters.length) &&
			this.hasCashRegisters()
		) {
			this.autoSelect()
		}
	}

	hasCashRegisters = () => {
		return this.props.cashRegisters && this.props.cashRegisters.length
	}

	autoSelect = () => {
		if (this.props.cashRegisters && this.props.cashRegisters.length === 1) {
			this.props.cashRegisters[0].id && this.props.onChange(this.props.cashRegisters[0].id)
		}
	}

	getOptions = memoize((cashRegisters: ?Array<CashRegister>): OptionsType => {
		if (!cashRegisters) return []
		return cashRegisters.map((cashRegister: CashRegister): OptionType => ({
			value: cashRegister.id,
			label: cashRegister.name,
		}))
	})

	onChange = (value: ValueType) => {
		const newCashRegisterId = value && !Array.isArray(value) ? value.value : null
		this.props.onChange(newCashRegisterId)
	}

	render() {
		return (
			<SelectNext
				placeholder={!this.props.autoSelect ? this.props.t('cashRegister.selectCashRegister') : undefined}
				autoWidth={this.props.autoWidth}
				label={this.props.label}
				isClearable={!!this.props.isClearable}
				options={this.getOptions(this.props.cashRegisters)}
				value={this.props.value}
				onChange={this.onChange}
				compact={this.props.compact}
				inline={this.props.inline}
				autoTestId="cash-register-selector"
				error={this.props.error}
				portal
			/>
		)
	}
}

export default withTranslate(CashRegisterSelector)
