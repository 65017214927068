// @flow
/** @jsx jsx */

import React from 'react'
import { jsx } from '@emotion/core'
import { PopupSection, Paper, Button } from 'components'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import type { AccountingDocumentScan } from 'types'
import Divider from 'components/divider'
import { colors } from 'variables'
import UnjoinScanLine from './unjoin-scan-line'
import memoize from 'memoize-one'

type Props = {|
	open: boolean,
	onClose: () => void,
	onUnJoin: (scans: Array<AccountingDocumentScan>) => void,
	accountingDocumentScans: ?Array<AccountingDocumentScan>,
	...WithTranslateProps,
|}

type State = {|
	open: boolean,
	scans: Array<AccountingDocumentScan>,
|}

class UnJoinDialog extends React.Component<Props, State> {
	state = {
		open: false,
		scans: [],
	}

	static getDerivedStateFromProps(nextProps: Props, prevState: State) {
		if (!prevState.open && nextProps.open) {
			return { scans: [], open: true }
		}
		return null
	}

	onSave = () => {
		this.setState({ open: false })
		this.props.onUnJoin && this.props.onUnJoin(this.state.scans)
	}

	onClose = () => {
		this.setState({ open: false })
		this.props.onClose()
	}

	onLineCheck = (guid: string) => {
		const scan =
			this.props.accountingDocumentScans &&
			this.props.accountingDocumentScans.find((scan: AccountingDocumentScan) => scan.guid === guid)
		if (scan) {
			if (this.state.scans.some((selectedScan: AccountingDocumentScan) => selectedScan.guid === scan.guid)) {
				const newScans = this.state.scans.filter(
					(selectedScan: AccountingDocumentScan) => selectedScan.guid !== scan.guid,
				)
				this.setState({ scans: [...newScans] })
			} else {
				this.setState({ scans: [...this.state.scans, scan] })
			}
		}
	}

	getUnjoinScanLines = memoize((scans: Array<AccountingDocumentScan>) => {
		return scans.map((scan: AccountingDocumentScan) => {
			return (
				scan.guid &&
				scan.filename && (
					<UnjoinScanLine key={scan.guid} onCheck={this.onLineCheck} guid={scan.guid} name={scan.filename} />
				)
			)
		})
	})

	render() {
		const { t, open, accountingDocumentScans } = this.props
		if (!open) return null
		return (
			<PopupSection opaque open={open} onRequestClose={this.onClose}>
				<div css={styles.container}>
					<div css={styles.content}>
						<div css={styles.head}>
							<h1 css={styles.h1}>{t('accountingDocument.unJoinDialog.unJoinTitle')}</h1>
							<Button
								disabled={this.state.scans.length === 0}
								labelText={t('accountingDocument.unJoinDialog.unJoin')}
								onClick={this.onSave}
								autoTestId="unjoin-dialog-save"
							/>
						</div>
						<Paper rounded zDepth={3} css={styles.body}>
							<div>
								<div css={styles.mainContainer}>
									<div css={styles.name}>{t('accountingDocument.unJoinDialog.unJoinHeadTitle')}</div>
									<div css={styles.subName}>{t('accountingDocument.unJoinDialog.unJoinHeadSubTitle')}</div>
								</div>
								<Divider />
							</div>
							<div css={styles.othersContainer}>
								{accountingDocumentScans && this.getUnjoinScanLines(accountingDocumentScans)}
							</div>
						</Paper>
					</div>
				</div>
			</PopupSection>
		)
	}
}

const styles = {
	container: {
		position: 'relative',
		maxWidth: 1170,
		marginLeft: 'auto',
		marginRight: 'auto',
		marginBottom: 50,
		display: 'flex',
		justifyContent: 'center',
	},
	content: {
		width: '66.6%',
	},
	head: {
		padding: '20px 0',
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	body: {
		position: 'relative',
		overflow: 'hidden',
	},
	mainContainer: {
		backgroundColor: '#F9F9F9',
		padding: 30,
	},
	othersContainer: {
		padding: '20px 10px',
		fontSize: 14,
	},
	h1: {
		fontSize: 32,
		lineHeight: '39px',
		margin: 0,
		color: colors.black,
		fontWeight: 'normal',
	},
	name: {
		fontSize: 24,
		color: colors.black,
	},
	subName: {
		marginTop: '6px',
	},
}

export default withTranslate(UnJoinDialog)
