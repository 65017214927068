/* @flow */

import { connect } from 'react-redux'
import type {
	AccountingDocumentDefaults,
	AccountingDocumentReduxAction,
	AccountingDocumentType,
	Dispatch,
	SettingsAction,
	State,
} from 'types'
import { editAccountingDocumentDefaults, loadAccountingDocumentDefaults } from '../actions'
import type { DefaultsType } from '../reducers'
import CustomizationValues from 'modules/settings/components/accounting/customization-values'

type StateProps = {|
	loading: boolean,
	defaults: DefaultsType,
|}

const mapStateToProps = (state: State): StateProps => {
	return {
		loading:
			state.settings.accountingDocumentDefaults.issued && !!state.settings.accountingDocumentDefaults.issued.loading,
		defaults: state.settings.accountingDocumentDefaults,
	}
}

type DispatchProps = {|
	loadAccountingDocumentDefaults: (accountingDocumentType: ?AccountingDocumentType) => void,
	editAccountingDocumentDefaults: (
		oldDefaults: ?AccountingDocumentDefaults,
		newDefaults: AccountingDocumentDefaults,
		type: ?AccountingDocumentType,
	) => Promise<any>,
|}

function mapDispatchToProps(dispatch: Dispatch<SettingsAction | AccountingDocumentReduxAction>): DispatchProps {
	return {
		loadAccountingDocumentDefaults: (accountingDocumentType: ?AccountingDocumentType) => {
			dispatch(loadAccountingDocumentDefaults(accountingDocumentType, 'issued'))
		},
		editAccountingDocumentDefaults: async (
			oldDefaults: ?AccountingDocumentDefaults,
			newDefaults: AccountingDocumentDefaults,
			type: ?AccountingDocumentType,
		) => {
			await dispatch(editAccountingDocumentDefaults(oldDefaults, newDefaults, 'issued', type))
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomizationValues)
