// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[3].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js!../../../variables/colors.css";
import ___CSS_LOADER_ICSS_IMPORT_1___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[3].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js!../../../variables/grid.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_1___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1NxHYyB0_364e4C94YCMkh {\n\tposition: relative;\n\tmargin-bottom: 50px;\n\tmax-width: 80%;\n\twidth: 960px;\n}\n\n.hmmMezJjg0GSEnvsxu-mA {\n\tmargin: 20px 0;\n\tdisplay: flex;\n\tjustify-content: space-between;\n\talign-items: center;\n}\n\n._2iGTskJDUXD7KRbY6bHaQ4 {\n\tfont-size: 32px;\n\tline-height: 39px;\n\tmargin: 0;\n\tcolor: #000000;\n\tfont-weight: normal;\n\toverflow: hidden;\n\ttext-overflow: ellipsis;\n\twhite-space: nowrap;\n}\n\n._2EQefJnJVXnUckUk-YJJag {\n\tpadding: 10px 0 0 10px;\n}\n\n._2UUWAQER4u5esRyVa6wNoT {\n\tpadding: 45px;\n}\n", "",{"version":3,"sources":["webpack://./modules/user/pages/user-settings.css"],"names":[],"mappings":"AAEA;CACC,kBAAA;CAEA,mBAAA;CACA,cAAA;CACA,YAAA;AASD;;AANA;CACC,cAAA;CACA,aAAA;CACA,8BAAA;CACA,mBAAA;AASD;;AANA;CACC,eAAA;CACA,iBAAA;CACA,SAAA;CACA,cAAA;CACA,mBAAA;CACA,gBAAA;CACA,uBAAA;CACA,mBAAA;AASD;;AANA;CACC,sBAAA;AASD;;AANA;CACC,aAAA;AASD","sourcesContent":["@value black, grey200 from \"variables/colors.css\";\n\n.container {\n\tposition: relative;\n\tcomposes: popupContainer from 'variables/grid.css';\n\tmargin-bottom: 50px;\n\tmax-width: 80%;\n\twidth: 960px;\n}\n\n.header {\n\tmargin: 20px 0;\n\tdisplay: flex;\n\tjustify-content: space-between;\n\talign-items: center;\n}\n\n.h1 {\n\tfont-size: 32px;\n\tline-height: 39px;\n\tmargin: 0;\n\tcolor: #000000;\n\tfont-weight: normal;\n\toverflow: hidden;\n\ttext-overflow: ellipsis;\n\twhite-space: nowrap;\n}\n\n.tabs {\n\tpadding: 10px 0 0 10px;\n}\n\n.content {\n\tpadding: 45px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"black": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["black"] + "",
	"grey200": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["grey200"] + "",
	"container": "_1NxHYyB0_364e4C94YCMkh " + ___CSS_LOADER_ICSS_IMPORT_1___.locals["popupContainer"] + "",
	"header": "hmmMezJjg0GSEnvsxu-mA",
	"h1": "_2iGTskJDUXD7KRbY6bHaQ4",
	"tabs": "_2EQefJnJVXnUckUk-YJJag",
	"content": "_2UUWAQER4u5esRyVa6wNoT"
};
export default ___CSS_LOADER_EXPORT___;
