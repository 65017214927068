/* @flow */

import React, { Component } from 'react'
import { emptyAccountingDocument } from 'types/empty'
import type { AccountingDocument } from 'types'
import InvoiceHeaderOrderNo from '../../components/invoice-elements/header-order-no'

type Props = {|
	accountingDocument: AccountingDocument,
	onChange: (accountingDocument: AccountingDocument) => void,
|}

export default class HeaderOrderNoTemplate extends Component<Props> {
	onChange = (orderNo: string) => {
		const newDocument: AccountingDocument = Object.assign(emptyAccountingDocument(), this.props.accountingDocument, {
			orderNo,
		})
		this.props.onChange(newDocument)
	}

	render() {
		return <InvoiceHeaderOrderNo orderNo={this.props.accountingDocument.orderNo} onOrderNumberChange={this.onChange} />
	}
}
