//@flow
import type { DashboardAction, Dispatch } from 'types'
import storage from 'utils/local-storage'
import { INVITATION_KEY } from '../constants'
import { invitations as invitationsApi } from '../../common/models/api-model'

function updateInvitationBannerStateOnLocalStorage(opened: boolean) {
	storage.set(INVITATION_KEY, opened)
}

export function switchInvitationBanner(payload: boolean) {
	updateInvitationBannerStateOnLocalStorage(payload)
	return (dispatch: Dispatch<DashboardAction>) => {
		dispatch({
			type: 'SWITCH_INVITATION_BANNER',
			payload,
		})
	}
}

export function updateInvitationEmails(payload: Array<string>) {
	return (dispatch: Dispatch<DashboardAction>) => {
		dispatch({
			type: 'UPDATE_INVITATION_EMAILS',
			payload,
		})
	}
}

export const resetInvitationDialog = () => ({ type: 'RESET_INVITATION_DIALOG' })

export const switchInvitationDialog = (payload: boolean) => ({ type: 'SWITCH_INVITATION_DIALOG', payload })

export function confirmInvitationEmails(organizationId: string, payload: Array<string>) {
	return async (dispatch: Dispatch<DashboardAction>) => {
		dispatch({ type: 'CONFIRM_INVITATION_EMAILS_START' })
		try {
			await invitationsApi.post({ organizationId }, { recipientEmailAddresses: payload })
			dispatch({ type: 'CONFIRM_INVITATION_EMAILS_SUCCESS' })
		} catch (serverError) {
			dispatch({ type: 'CONFIRM_INVITATION_EMAILS_ERROR', serverError })
			return Promise.reject(new Error())
		}
	}
}
