/* @flow */

import { accountingDocumentAggregationReports as api } from 'modules/common/models/api-model'
import type { BillingSummaryFilter } from 'modules/dashboard/types'
import type { AccountingDocumentReportAggregationRequest, DashboardAction, Dispatch } from 'types'
import { intervalToDates } from 'utils/formatters'

export function loadBillingSummaryReportAggregations(filter: BillingSummaryFilter) {
	return async (dispatch: Dispatch<DashboardAction>) => {
		dispatch({
			type: 'START_LOAD_BILLING_SUMMARY_REPORT_AGGREGATIONS',
		})
		try {
			const dates = intervalToDates(filter.interval)
			const request: AccountingDocumentReportAggregationRequest = {
				dateFrom: dates[0].toISOString(),
				dateTo: dates[1].toISOString(),
				timeGranularity: filter.timeGranularity,
			}
			if (filter.currency) {
				request.filterCurrency = filter.currency
			} else {
				request.convertToDomesticCurrency = true
			}
			const result = await api.post(request)

			return dispatch({
				type: 'FINISH_LOAD_BILLING_SUMMARY_REPORT_AGGREGATIONS',
				filter,
				data: result.aggregatedItems,
			})
		} catch (error) {
			dispatch({
				type: 'FINISH_LOAD_BILLING_SUMMARY_REPORT_AGGREGATIONS',
				filter,
				serverError: error,
			})
		}
	}
}

export function setBillingSummaryFilter(filter: BillingSummaryFilter) {
	return async (dispatch: Dispatch<DashboardAction>) => {
		return dispatch({
			type: 'SET_BILLING_SUMMARY_FILTER',
			filter,
		})
	}
}
