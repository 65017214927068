// @flow
/** @jsx jsx */

import { PureComponent, type Node } from 'react'
import { jsx } from '@emotion/core'
import memoize from 'memoize-one'
import type { Direction } from '../types'
import ArrowDownward from 'components/svg-icons/navigation/arrow-downward'
import ArrowUpward from 'components/svg-icons/navigation/arrow-upward'
import Tooltip from 'components/tooltip'
import InfoIcon from 'components/svg-icons/action/info'
import colors from 'variables/colors.css'

type Props = {|
	id: string,
	title: Node,
	sortable: boolean,
	direction: ?Direction,
	style?: Object,
	infoTooltip?: string,
	onDirectionChange: (columnId: string, direction: Direction) => void,
|}

export default class ColumnHeader extends PureComponent<Props> {
	static defaultProps = {
		sortable: false,
		onDirectionChange: () => {},
	}

	handleDirectionChange = () => {
		this.props.onDirectionChange(this.props.id, this.props.direction === 'ASC' ? 'DESC' : 'ASC')
	}

	getStyles = memoize((direction: ?Direction, sortable: ?boolean, style: ?Object) => {
		return {
			root: {
				fontSize: 12,
				textTransform: 'uppercase',
				fontWeight: direction ? 600 : 'normal',
				cursor: sortable ? 'pointer' : 'default',
				color: colors.blackFaded60,
				whiteSpace: 'nowrap',
				textOverflow: 'ellipsis',
				...style,

				'&:hover': {
					color: sortable ? colors.blue : colors.blackFaded60,
					svg: {
						fill: sortable ? colors.blue : colors.blackFaded60,
					},
				},
			},
			title: {
				verticalAlign: 'middle',
				lineHeight: '15px',
			},
			iconWrapper: {
				display: direction ? 'inline-block' : 'none',
				width: 12,
				height: 12,
				overflow: 'hidden',
				marginLeft: 4,
				verticalAlign: 'middle',
				position: 'relative',
			},
			icons: {
				width: 12,
				height: 30,
				position: 'absolute',
				overflow: 'hidden',
				top: 0,
				left: 0,
				transition: 'all 700ms cubic-bezier(0.77, 0, 0.175, 1)',
				transform: `translateY(${direction === 'ASC' ? 0 : -18}px)`,
				willChange: 'transform',
			},
			arrowUpward: {
				position: 'absolute',
				left: 0,
				top: 18,
			},
			arrowDownward: {
				position: 'absolute',
				left: 0,
				top: 0,
			},
			tooltipWrapper: {
				marginLeft: 5,
				height: 16,
				lineHeight: '16px',
				verticalAlign: 'middle',
			},
		}
	})

	render() {
		const style = this.getStyles(this.props.direction, this.props.sortable, this.props.style)
		return (
			<div css={style.root} onClick={this.props.sortable ? this.handleDirectionChange : null}>
				<span css={style.title}>{this.props.title}</span>
				{this.props.sortable && (
					<span css={style.iconWrapper}>
						<div css={style.icons}>
							<ArrowUpward color={colors.blackFaded60} style={style.arrowUpward} size={12} />
							<ArrowDownward className color={colors.blackFaded60} style={style.arrowDownward} size={12} />
						</div>
					</span>
				)}
				{this.props.infoTooltip && (
					<Tooltip label={this.props.infoTooltip} wrapperStyle={style.tooltipWrapper} inline>
						<InfoIcon color={colors.gray} disabled size={16} />
					</Tooltip>
				)}
			</div>
		)
	}
}
