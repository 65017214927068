//@flow
import type { Dispatch, NotificationsAction, ISignalMessage } from 'types'
import { HubConnection } from '@aspnet/signalr'

export function initializeConnection() {
	return (dispatch: Dispatch<NotificationsAction>) => {
		dispatch({ type: 'ESTABLISHING_CONNECTION_START' })
	}
}

export function connectionInitializationSuccess(connection: HubConnection) {
	return (dispatch: Dispatch<NotificationsAction>) => {
		dispatch({
			type: 'ESTABLISHING_CONNECTION_SUCCESS',
			payload: connection,
		})
	}
}

export function addNewMessage(message: ISignalMessage) {
	return (dispatch: Dispatch<NotificationsAction>) => {
		dispatch({
			type: 'ADD_NOTIFICATION_MESSAGE',
			payload: message,
		})
	}
}

export function loadHistory(messages: Array<ISignalMessage>) {
	return (dispatch: Dispatch<NotificationsAction>) => {
		dispatch({
			type: 'LOAD_HISTORY',
			payload: messages,
		})
	}
}

export function markAllAsRead() {
	return (dispatch: Dispatch<NotificationsAction>) => {
		dispatch({
			type: 'MARK_ALL_AS_READ',
		})
	}
}

export function markAsRead(id: string) {
	return (dispatch: Dispatch<NotificationsAction>) => {
		dispatch({
			type: 'MARK_AS_READ',
			payload: id,
		})
	}
}

export function removeReadMessages(messages: Array<string>) {
	return (dispatch: Dispatch<NotificationsAction>) => {
		dispatch({
			type: 'REMOVE_READ_MESSAGES',
			payload: messages,
		})
	}
}

export function stopConnection() {
	return (dispatch: Dispatch<NotificationsAction>) => {
		dispatch({
			type: 'STOP_CONNECTION',
		})
	}
}

export function showNotificationPreview(message: ISignalMessage) {
	return (dispatch: Dispatch<NotificationsAction>) => {
		dispatch({
			type: 'SHOW_NOTIFICATION_PREVIEW',
			payload: message,
		})
	}
}

export function hideNotificationPreview() {
	return (dispatch: Dispatch<NotificationsAction>) => {
		dispatch({
			type: 'HIDE_NOTIFICATION_PREVIEW',
		})
	}
}
