//@flow

import { getReports } from '../../selectors/report'
import type { FinancialAccount, GenericReportRequest, State, GenericReport } from 'types'
import { addFavoriteFinancialAccount, removeFavoriteFinancialAccount } from 'modules/organization/actions/favorites'
import { loadFinancialAccounts } from 'modules/organization/actions'
import { deleteReport, loadReport, loadReports, requestNewGenericReport } from '../../actions'
import type { Action } from '../../actions/action-types'
import ReportDetail from '../../components/report/report-detail'
import type { ReportScheme } from '../../constants/report'
import { connect } from 'react-redux'
import { getFavoriteFinancialAccountIds } from 'modules/organization/selectors/favorites'
import { getFinancialAccounts } from 'modules/organization/selectors'

export type OwnProps = {
	reportScheme: ?ReportScheme,
}

type StateProps = {|
	reports: Array<GenericReport>,
	financialAccounts: ?Array<FinancialAccount>,
	favoriteFinancialAccountIds: Array<string>,
|}

const mapStateToProps = (state: State): StateProps => {
	return {
		reports: getReports(state),
		financialAccounts: getFinancialAccounts(state, null),
		favoriteFinancialAccountIds: getFavoriteFinancialAccountIds(state),
	}
}

type DispatchProps = {|
	loadReports: (pageSize?: number, page?: number) => ?Promise<*>,
	loadReport: (reportId: string) => void,
	deleteReport: (fileId: string) => Promise<void>,
	requestReport: GenericReportRequest => Promise<Action>,
	onFinancialAccountFavorite: (no: ?string, isFavorite: boolean) => void,
	loadFinancialAccounts: () => void,
|}

const mapDispatchToProps = (dispatch: Function, ownProps: OwnProps): DispatchProps => {
	return {
		loadReports: (pageSize?: number, page?: number) => {
			if (ownProps.reportScheme != null)
				return dispatch(loadReports({ type: ownProps.reportScheme.type, pageSize, page }))
			else return null
		},
		loadReport: (reportId: string) => dispatch(loadReport(reportId)),
		deleteReport: async (reportId: string) => {
			await dispatch(deleteReport(reportId))
		},
		requestReport: (requestParams: GenericReportRequest) => {
			return dispatch(requestNewGenericReport(requestParams))
		},
		loadFinancialAccounts: () => dispatch(loadFinancialAccounts()),

		onFinancialAccountFavorite: (no: ?string, isFavorite: boolean) => {
			if (isFavorite) {
				no && dispatch(addFavoriteFinancialAccount(no))
			} else {
				no && dispatch(removeFavoriteFinancialAccount(no))
			}
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportDetail)
