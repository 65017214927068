// @flow

import type { Contact, ContactBankAccount, CrpdphBankAccount } from 'types'
import { CrpdphBankAccounts_ContactBankAccounts } from 'types/convertor'

export function mergeContactContactBankAccount(contact: Contact, bankAccounts: Array<ContactBankAccount>): Contact {
	return {
		...contact,
		bankAccounts: [...(contact.bankAccounts || []), ...(bankAccounts || [])],
	}
}

export function mergeContactCrdphBankAccount(contact: Contact, bankAccounts: Array<CrpdphBankAccount>): Contact {
	return mergeContactContactBankAccount(contact, CrpdphBankAccounts_ContactBankAccounts(bankAccounts))
}

export function ContactBankAccounts_findById(
	bankAccounts: Array<ContactBankAccount>,
	bankAccountId: string,
): ?ContactBankAccount {
	return bankAccounts.find((ba: ContactBankAccount) => {
		return ba.id === bankAccountId
	})
}
