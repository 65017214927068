/* @flow */

import { connect } from 'react-redux'
import { getIsCashbotAuthenticated } from 'modules/cashbot/selectors'
import { loginToCashbot } from '../../actions'
import CashbotPanelLogic, { type OwnProps as CashbotPanelProps } from './helpers/cashbot-panel-logic'
import type { CashbotAction } from '../../actions/action-types'
import type { State } from 'types'

type StateProps = {|
	isCashbotAuthenticated: boolean,
|}

const mapStateToProps = (state: State): StateProps => {
	return {
		isCashbotAuthenticated: getIsCashbotAuthenticated(state),
	}
}

type DispatchProps = {|
	authenticateCashbot: () => Promise<void>,
|}
const mapDispatchToProps = (dispatch: Dispatch<CashbotAction>): DispatchProps => {
	return {
		authenticateCashbot: async () => {
			return dispatch(loginToCashbot())
		},
	}
}

const mergeProps = (stateProps: StateProps, dispatchProps: DispatchProps): CashbotPanelProps => {
	return {
		onlyAuthentication: true,
		isCashbotAuthenticated: stateProps.isCashbotAuthenticated,
		authenticateCashbot: dispatchProps.authenticateCashbot,
		//
		cashbotInvoiceId: null,
		isCashbotInvoiceLoading: false,
		cashbotInvoice: null,
		cashbotOffers: null,
		isCashbotOffersLoading: false,
		isMarkedToBeFinanced: false,
	}
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(CashbotPanelLogic)
