/* @flow */

import React, { Component } from 'react'
import { Spring } from 'react-spring'
import type { ScanFactoryField } from 'types'
import { type WithTranslateProps, withTranslate } from 'wrappers'
import { isActiveField, isSomeFieldCursorEqual } from '../domain/field'
import { getFieldSections } from '../domain/section'
import { getFieldError } from '../domain/validator'
import { type ExtractionDocument, type FieldCursor, type FieldSection } from '../types'
import DocumentFieldsScroller from './document-fields-scroller'
import DocumentField from './document-field'
import styles from './document-fields.css'

export type StateProps = {|
	extractionDocument: ExtractionDocument,
	fieldCursor: ?FieldCursor,
	fieldEditing: boolean,
	highlightFields: ?Array<FieldCursor>,
|}

export type DispatchProps = {|
	onFieldClick: (field: FieldCursor) => void,
	onFieldDoubleClick: (field: FieldCursor) => void,
	onFieldResetClick: (field: FieldCursor) => void,
	onFieldValueChange: (field: FieldCursor, value?: string, hotkey?: string) => void,
	onFieldMouseOver: (field: FieldCursor) => void,
	onFieldMouseOut: (field: FieldCursor) => void,
	addRepeatableSection: (section: string) => void,
	removeRepeatableSection: (section: string, index: number) => void,
|}

export type State = {|
	scroll: number,
|}

class DocumentFields extends Component<{| ...StateProps, ...DispatchProps, ...WithTranslateProps |}, State> {
	activeField: ?React$Component<any> = null
	fieldClicked: boolean = false
	state = {
		scroll: 0,
	}

	UNSAFE_componentWillReceiveProps(nextProps: {| ...StateProps, ...DispatchProps, ...WithTranslateProps |}) {
		if (nextProps.fieldCursor !== this.props.fieldCursor) {
			if (!this.fieldClicked) {
				this.setScrollPosition()
			}

			this.fieldClicked = false
		}
	}

	setScrollPosition() {
		setTimeout(() => {
			this.activeField &&
				this.setState({
					// $FlowFixMe
					scroll: Math.ceil(this.activeField.getWrappedInstance().refs.root.offsetTop - 56),
				})
		}, 0)
	}

	setActiveFieldRef = (node: ?React$Component<any>) => {
		this.activeField = node
	}

	render() {
		return (
			<Spring
				config={{ tension: 220, friction: 22, clamp: true }}
				to={{
					scroll: this.state.scroll,
				}}
			>
				{(props: Object) => (
					<DocumentFieldsScroller scroll={props.scroll}>
						{getFieldSections().map((section: FieldSection) =>
							section.isRepeatable ? this.renderRepeatableSection(section) : this.renderSection(section),
						)}
					</DocumentFieldsScroller>
				)}
			</Spring>
		)
	}

	renderSection(section: FieldSection) {
		const fields: Array<ScanFactoryField> =
			(this.props.extractionDocument.metadata && this.props.extractionDocument.metadata[section.name]) || []

		return (
			<div className={styles.section} key={section.name}>
				<h4 className={styles.header}>{this.props.t('documentExtraction.' + section.name)}</h4>
				{this.renderFields(fields, 0, section)}
			</div>
		)
	}

	renderRepeatableSection(section: FieldSection) {
		const fields: Array<Array<ScanFactoryField>> =
			(this.props.extractionDocument.metadata && this.props.extractionDocument.metadata[section.name]) || []
		const allowRemove = fields.length > 1

		return (
			<div className={styles.section} key={section.name}>
				<h4 className={styles.header}>{this.props.t('documentExtraction.' + section.name)}</h4>
				{fields.map((fields: Array<ScanFactoryField>, i: number) =>
					this.renderRepeatableSectionFields(fields, i, section, allowRemove),
				)}
				<div className={styles.add} onClick={this.addSection(section.name)}>
					{this.props.t('documentExtraction.add')}
				</div>
			</div>
		)
	}

	renderRepeatableSectionFields(
		fields: Array<ScanFactoryField>,
		index: number,
		section: FieldSection,
		allowRemove: boolean,
	) {
		return (
			<div key={index}>
				<div className={styles.subheader}>
					{this.props.t('documentExtraction.section')} {index}{' '}
					{allowRemove && this.renderRemoveLink(section.name, index)}
				</div>
				{this.renderFields(fields, index, section, 1)}
			</div>
		)
	}

	renderRemoveLink(section: string, index: number) {
		return (
			<span onClick={this.removeSection(section, index)} className={styles.remove}>
				{this.props.t('documentExtraction.remove')}
			</span>
		)
	}

	renderFields(fields: Array<ScanFactoryField>, index: number, section: FieldSection, depth?: number) {
		return (
			<ul key={index || 0} className={styles.fields}>
				{fields.map((field: ScanFactoryField) => this.renderField(field, index, section, depth))}
			</ul>
		)
	}

	renderField(field: ScanFactoryField, index: number, section: FieldSection, depth?: number) {
		const active = !!this.props.fieldCursor && isActiveField(this.props.fieldCursor, field, index, section)
		const fieldCursor: FieldCursor = { index, section: section.name, name: field.propertyName || '' }
		const error = getFieldError(this.props.extractionDocument.errors, fieldCursor)

		return (
			<DocumentField
				key={field.propertyName}
				editing={this.props.fieldEditing && active}
				fieldCursor={fieldCursor}
				field={field}
				onFieldClick={this.onFieldClick()}
				onFieldDoubleClick={this.props.onFieldDoubleClick}
				onFieldResetClick={this.props.onFieldResetClick}
				onFieldValueChange={this.props.onFieldValueChange}
				onFieldMouseOver={this.props.onFieldMouseOver}
				onFieldMouseOut={this.props.onFieldMouseOut}
				highlight={isSomeFieldCursorEqual(fieldCursor, this.props.highlightFields)}
				ref={active ? this.setActiveFieldRef : null}
				active={active}
				depth={depth || 0}
				error={error}
			/>
		)
	}

	onFieldClick() {
		return (fieldCursor: FieldCursor) => {
			this.fieldClicked = true
			this.props.onFieldClick(fieldCursor)
		}
	}

	addSection(section: string) {
		return () => this.props.addRepeatableSection(section)
	}

	removeSection(section: string, index: number) {
		return () => this.props.removeRepeatableSection(section, index)
	}
}

export default withTranslate(DocumentFields)
