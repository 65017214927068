/* @flow */

import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import type { WithRouterProps } from 'types'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import DocumentExtraction from '../containers/document-extraction'

type Props = {
	...WithRouterProps,
	...WithTranslateProps,
}

class DocumentExtractionPage extends Component<Props> {
	render() {
		const {
			t,
			match: {
				params: { id, organizationId },
			},
		} = this.props
		return (
			<div>
				<Helmet>
					<title>{t('documentExtraction.headline')}</title>
				</Helmet>
				<DocumentExtraction documentId={id} organizationId={organizationId} />
			</div>
		)
	}
}

export default withTranslate(DocumentExtractionPage)
