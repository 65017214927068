/* @flow */

import type { DataGridReduxAction, Dispatch, Organization, OrganizationMembers, State, UserAction } from 'types'
import OrganizationSwitcher from '../components/organization-switcher'
import { connect } from 'react-redux'
import { createOrganizationRoute } from 'modules/organization/routing/routes'
import { dashboardRoute } from 'modules/dashboard/routing/routes'
import { push } from 'react-router-redux'
import { getCurrentUserOrganizations } from '../../user/selectors'
import { getAccDocFilterName } from 'modules/accounting-document/domain/accounting-document'
import { getCashRegisterFilterName } from '../../cash-register/domain/cash-register'
import { clearAllGridFilters } from 'modules/data-grid/actions'

type StateProps = {|
	organizations: ?OrganizationMembers,
	organizationDetail: ?Organization,
	value: ?string,
|}

const mapStateToProps = (state: State): StateProps => {
	return {
		organizations: getCurrentUserOrganizations(state),
		organizationDetail: (state.organization && state.organization.organizationDetail.data) || null,
		value: state.user.currentOrganizationId,
	}
}

type DispatchProps = {|
	onChange?: (organizationId: string) => void,
|}

const filtersToReset = [
	getAccDocFilterName('received'),
	getAccDocFilterName('issued'),
	// $FlowFixMe
	getAccDocFilterName('uploaded'),

	getCashRegisterFilterName('unknown'),
	getCashRegisterFilterName('issued'),
	getCashRegisterFilterName('received'),
]

const mapDispatchToProps = (dispatch: Dispatch<UserAction | DataGridReduxAction>): DispatchProps => {
	return {
		onChange: (organizationId: string) => {
			if (organizationId === 'new') {
				dispatch(push(createOrganizationRoute()))
			} else {
				dispatch(push(dashboardRoute(organizationId)))
			}
			filtersToReset.forEach((filterName: string) => {
				dispatch(clearAllGridFilters(filterName))
			})
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationSwitcher)
