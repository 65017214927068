// @flow

import type { FinishLoadBalanceSummary } from 'modules/dashboard/actions/action-types'
import type { AccountingDocumentsReport, DashboardAction } from 'types'
import { convertToDomesticKey } from '../constants'
import type { BalanceSummary, SummaryType } from '../types'

export type State = {|
	loading: boolean,
	data: BalanceSummary,
|}

export const initialState: State = {
	data: Object.freeze({}),
	loading: false,
}

export default (state: State = initialState, action: DashboardAction): State => {
	switch (action.type) {
		case 'START_LOAD_BALANCE_SUMMARY':
			return { ...state, loading: true }
	}
	switch (action.type) {
		case 'FINISH_LOAD_BALANCE_SUMMARY':
			return finishLoadBalanceSummary(state, action)
	}

	return state
}

function finishLoadBalanceSummary(state: State, action: FinishLoadBalanceSummary): State {
	let newState = { ...state, loading: false }
	const currency = action.request.filterCurrency || convertToDomesticKey
	const data = action.data || []

	data.forEach((report: AccountingDocumentsReport) => {
		newState = setBalanceSummary(newState, report, currency)
	})

	return newState
}

function setBalanceSummary(state: State, report: AccountingDocumentsReport, currency: string): State {
	const type: SummaryType = 0 === report.type || 1 === report.type || 2 === report.type ? report.type : 0
	return {
		...state,
		data: {
			...state.data,
			[report.type]: {
				...state.data[type],
				[currency]: {
					nonPaidSum: report.nonPaidSum || 0,
					nonPaidExpiredDueDateSum: report.nonPaidExpiredDueDateSum || 0,
					waitingForApprovalSum: report.waitingForApprovalSum || 0,
				},
			},
		},
	}
}
