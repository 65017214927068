// @flow

import React, { Component } from 'react'
import {
	AVAILABLE_ACCOUNTING_DOCUMENT_LANGUAGES,
	getAccountingDocumentLanguageName,
	toAvailableAccountingDocumentLanguage,
} from 'locales'
import type { AccountingDocumentLanguage } from 'types'
import LanguageSelectorNext from './_language-selector-next'

export type Props = {|
	language: ?AccountingDocumentLanguage,
	onChange: AccountingDocumentLanguage => void,
	labelText?: string,
	hintText?: string,
	readonly?: boolean,
	infoText?: string,
	compact?: boolean,
|}

const availableLanguages = AVAILABLE_ACCOUNTING_DOCUMENT_LANGUAGES.map((language: AccountingDocumentLanguage) => {
	return {
		key: language,
		name: getAccountingDocumentLanguageName(language),
	}
})

export default class AccountingDocumentLanguageSelector extends Component<Props> {
	onLanguageChange = (language: ?AccountingDocumentLanguage) => {
		this.props.onChange && language && this.props.onChange(language)
	}

	render() {
		return (
			<LanguageSelectorNext
				labelText={this.props.labelText}
				hintText={this.props.hintText}
				infoText={this.props.infoText}
				infoTextAlign={'top-center'}
				infoTextAlignArrow={'bottom-center'}
				availableLanguages={availableLanguages}
				language={toAvailableAccountingDocumentLanguage(this.props.language)}
				onChange={this.onLanguageChange}
				readonly={this.props.readonly}
				compact={this.props.compact}
			/>
		)
	}
}
