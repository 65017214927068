/* @flow */

import React, { Component } from 'react'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import SelectNext from 'components/select-next'
import type { OptionsType, OptionType } from 'components/select-next'

type Props = {|
	...WithTranslateProps,
	value: ?number,
	disabled?: boolean,
	inline?: boolean,
	labelText?: ?string,
	openMenuPosition?: 'top' | 'bottom',
	onChange?: (signingCode: number) => void,
|}

class SigningPersonCodeSelector extends Component<Props> {
	onChange = (option: ?OptionType | ?OptionsType) => {
		const value: number = parseInt(Array.isArray(option) ? option && option[0].value : option && option.value)
		this.props.onChange && this.props.onChange(value)
	}

	getItems() {
		const { t } = this.props
		return [
			{
				label: t('user.taxRepresentativeTypes.legalRepresentative'),
				value: '10',
			},
			{
				label: t('user.taxRepresentativeTypes.representativeAppointed'),
				value: '20',
			},
			{
				label: t('user.taxRepresentativeTypes.jointRepresentative'),
				value: '30',
			},
			{
				label: t('user.taxRepresentativeTypes.generalAttorney'),
				value: '41',
			},
			{
				label: t('user.taxRepresentativeTypes.naturalPersonTaxAdvisorOrLawyer'),
				value: '42',
			},
			{
				label: t('user.taxRepresentativeTypes.juridicalPersonPerformingTaxAdvice'),
				value: '43',
			},
		]
	}

	render() {
		const label = this.props.hasOwnProperty('labelText')
			? this.props.labelText
			: this.props.t('user.fields.signingPersonCode')
		return (
			<SelectNext
				options={this.getItems()}
				value={this.props.value ? this.props.value.toString() : undefined}
				onChange={this.onChange}
				label={label ? label : ''}
				disabled={this.props.disabled}
				inline={this.props.inline}
				openMenuPosition={this.props.openMenuPosition}
			/>
		)
	}
}

export default withTranslate(SigningPersonCodeSelector)
