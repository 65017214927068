// @flow

import React, { PureComponent } from 'react'
import memoize from 'memoize-one'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import { Number_AccountingDocumentType, Type_AccountingDocumentName } from 'types/convertor'
import type { Filter, SearchFilter, I18NextTranslateFn, EnumItem, Enum } from 'types'
import { getFilter } from 'modules/data-grid-next/domain/filter'
import {
	FilterRange,
	FilterSelectField,
	FilterTabSwitch,
	FilterTabSwitches,
	FilterTextField,
	FilterVariableSymbol,
	FilterState,
} from 'modules/data-grid/components/data-grid-filter'
import FilterSequence from 'modules/data-grid/containers/filter-sequence'
import BasicGridFilter from 'modules/data-grid/components/basic-grid-filter'
import styles from './cash-register-payments-advanced-filter.css'
import {
	processingStateFilterTransform,
	paymentFilterTransform,
	paymentToFilterItemTransform,
	processingStateToFilterItemTransform,
} from 'utils/filter-transformers'
import { flipAccDocDirection } from '../selectors'
import { EMPTY_ARRAY } from 'trivi-constants'

const ACCOUNTING_DOCUMENT_ALLOWED_TYPES = [7, 8, 10, 11, 12]

export type Props = {|
	direction?: 'issued' | 'received' | 'unknown',
	name?: string,
	type?: number,
	compact?: boolean,
	cashRegisterId: string,
	defaultFilter?: Filter,
	gridId: string,
	internal: ?boolean,
	branches?: ?Enum,
	projects?: ?Enum,
	...WithTranslateProps,
|}

const ALLOWED_TYPES = [7, 8, 10, 11, 12]

class CashRegisterPaymentsAdvancedFilter extends PureComponent<Props> {
	getTypeOptions = memoize((t: I18NextTranslateFn) => {
		const types = []
		ALLOWED_TYPES.forEach((type: number) => {
			const typeName = Type_AccountingDocumentName(type)
			typeName && types.push({ value: type, label: t(`accountingDocument.types.${typeName}`) })
		})

		return types
	})

	getPreferredBranchOptions = () => {
		if (!this.props.branches) return EMPTY_ARRAY
		return this.props.branches.map((branch: EnumItem) => ({ value: branch.key, label: branch.value }))
	}

	getPreferredProjectOptions = () => {
		if (!this.props.projects) return EMPTY_ARRAY
		return this.props.projects.map((project: EnumItem) => ({ value: project.key, label: project.value }))
	}

	getProcessingStateOptions = memoize((t: I18NextTranslateFn) => {
		return [
			{ value: 'processedWithOk', label: t('accountingDocument.filter.processingState.processedWithOk') },
			{ value: 'processedWithoutOk', label: t('accountingDocument.filter.processingState.processedWithoutOk') },
			{
				value: 'waitingForProcessing',
				label: t('accountingDocument.filter.processingState.waitingForProcessing'),
			},
			{ value: 'processedWithError', label: t('accountingDocument.filter.processingState.processedWithError') },
		]
	})

	getExtractingStateOptions = memoize((t: I18NextTranslateFn) => {
		return [{ value: 4, label: t('accountingDocument.filter.extractingState.problemWithExtracting') }]
	})

	render() {
		const { t } = this.props

		// RELEASE HOTFIX - z nejakeho duvodu se na Pokladne v ciselnych radach ve smeru pro Prijem zobrazuje Vydej a naopak (na Dokladech to ale funguje spravne)
		// https://triviit.atlassian.net/browse/TRV-305
		const sequencesFilterDirection = this.props.direction && flipAccDocDirection(this.props.direction)
		const checkboxFilterName = 'excludeAccDocSearchThresholdId'
		const checkboxFilterLabelText = t('invoice.accDocThreshold')

		return (
			<BasicGridFilter
				name={this.props.name}
				gridId={this.props.gridId}
				dataType="accountingDocuments"
				defaultFilter={this.props.defaultFilter}
				textFilterName="searchText"
				dateFilterName="issueDate"
				noFilterLabel={t('accountingDocument.filter.allDocuments')}
				filterTransformFn={filterTransformFn}
				compact={this.props.compact}
				direction={this.props.direction}
				checkboxFilterName={checkboxFilterName}
				checkboxFilterLabelText={checkboxFilterLabelText}
				showOptimizeFilter
			>
				<FilterTextField
					className={styles.fixedColumn}
					name="accountingDocumentNo"
					labelText={t('accountingDocument.filter.number')}
				/>
				<FilterTextField
					className={styles.fixedColumn}
					name="contact.companyName"
					labelText={t('accountingDocument.filter.companyName')}
				/>
				<FilterTextField
					className={styles.fixedColumn}
					name="contact.companyRegNo"
					labelText={t('accountingDocument.filter.companyRegNo')}
				/>
				<FilterVariableSymbol
					className={styles.fixedColumn}
					name="variableSymbol"
					labelText={t('accountingDocument.filter.variableSymbol')}
				/>
				<FilterState name="state" className={styles.fixedColumn} />
				<FilterSelectField
					className={styles.fixedColumn}
					name="processingState"
					labelText={t('accountingDocument.filter.processingState.label')}
					options={this.getProcessingStateOptions(this.props.t)}
					compact
					isClearable
					isSearchable
				/>
				<FilterRange className={styles.fixedColumn} name="total" />

				<FilterSequence
					className={styles.fixedColumn}
					name="sequenceId"
					labelText={t('accountingDocument.filter.sequence')}
					allowedAccountingDocumentTypes={ACCOUNTING_DOCUMENT_ALLOWED_TYPES}
					direction={sequencesFilterDirection}
					type={Number_AccountingDocumentType(this.props.type)}
					cashRegisterId={this.props.cashRegisterId}
				/>

				<FilterSelectField
					className={styles.fixedColumn}
					name="type"
					labelText={t('accountingDocument.filter.type.label')}
					options={this.getTypeOptions(this.props.t)}
					compact
					isClearable
					isSearchable
				/>

				{this.props.internal && (
					<FilterSelectField
						className={styles.fixedColumn}
						name="ExtractingState"
						labelText={t('accountingDocument.filter.extractingState.label')}
						options={this.getExtractingStateOptions(t)}
						compact
						isClearable
						isSearchable
					/>
				)}

				{this.props.branches && this.props.branches.length > 0 && (
					<FilterSelectField
						key={'preferredBranch'}
						compact
						className={styles.fixedColumn}
						name={'lineItem.tag.branch'}
						labelText={t('accountingDocument.filter.preferredBranch')}
						options={this.getPreferredBranchOptions()}
						isClearable
						isSearchable
						multiple
					/>
				)}

				{this.props.projects && this.props.projects.length > 0 && (
					<FilterSelectField
						key={'preferredProject'}
						compact
						className={styles.fixedColumn}
						name={'lineItem.tag.project'}
						labelText={t('accountingDocument.filter.preferredProject')}
						options={this.getPreferredProjectOptions()}
						isClearable
						isSearchable
						multiple
					/>
				)}

				<FilterTabSwitches
					name="payment"
					className={styles.column}
					label={t('accountingDocument.filter.payment.label')}
				>
					<FilterTabSwitch label={t('accountingDocument.filter.payment.all')} value="" />
					<FilterTabSwitch label={t('accountingDocument.filter.payment.paid')} value="paid" />
					<FilterTabSwitch label={t('accountingDocument.filter.payment.unpaid')} value="unpaid" />
					<FilterTabSwitch label={t('accountingDocument.filter.payment.paidPartially')} value="paidPartially" />
				</FilterTabSwitches>
			</BasicGridFilter>
		)
	}
}

const filterTransformFn = {
	fromFilterItem: (item: SearchFilter): Filter => {
		let filter: Filter = []
		switch (item.field) {
			case 'processingState':
				filter = processingStateFilterTransform(item)
				break
			case 'payment':
				filter = paymentFilterTransform(item)
				break
			default:
				filter.push(item)
		}
		return filter
	},
	toFilterItem: (filter: Filter, field: string): ?SearchFilter => {
		if (field === 'payment') {
			return paymentToFilterItemTransform(filter, field)
		}
		if (field === 'processingState') {
			return processingStateToFilterItemTransform(filter, field)
		}
		return getFilter(filter, field)
	},
}

export default withTranslate(CashRegisterPaymentsAdvancedFilter)
