/* @flow */

import type { Dispatch, InternalUser, OrganizationAction, State, UserProfile } from 'types'
import { changeUserMembership, loadUsers, unassignUserFromOrganization } from 'modules/organization/actions'

import InternalUsers from 'modules/settings/components/users/internal-users'
import { connect } from 'react-redux'
import { loadInternalUsers } from 'modules/user/actions'
import { getCurrentOrganizationAccountant, getCurrentOrganizationWagesAccountant } from 'modules/organization/selectors'

type StateProps = {|
	internalUsers: ?Array<InternalUser>,
	users: ?Array<UserProfile>,
	me: ?UserProfile,
	disabledTypes: Array<number>,
|}

export const mapStateToProps = (state: State): StateProps => {
	const accountant = getCurrentOrganizationAccountant(state)
	const wagesAccountant = getCurrentOrganizationWagesAccountant(state)
	const disabledTypes = []

	if (accountant) disabledTypes.push(2)
	if (wagesAccountant) disabledTypes.push(4)

	return {
		internalUsers: state.user.internalUsers.data,
		users: state.organization.users.data,
		me: state.user.me.data,
		disabledTypes: disabledTypes,
	}
}

type DispatchProps = {|
	loadInternalUsers: () => void,
	changeUserMembership: (userId: string, membershipType: number) => void,
	unassignUserFromOrganization: (userId: string) => void,
|}

const mapDispatchToProps = (dispatch: Dispatch<OrganizationAction>): DispatchProps => {
	return {
		loadInternalUsers: () => {
			dispatch(loadInternalUsers())
		},
		changeUserMembership: (userId: string, membershipType: number) => {
			dispatch(changeUserMembership(userId, membershipType)).then(() => {
				dispatch(loadUsers())
			})
		},
		unassignUserFromOrganization: (userId: string) => {
			dispatch(unassignUserFromOrganization(userId)).then(() => {
				dispatch(loadUsers())
			})
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(InternalUsers)
