/* @flow */
/** @jsx jsx */

import type { DataGridId, Filter, Statement, StatementsView } from 'types'
import { Dropzone, TriviLink } from 'components'
import {
	default as SingleLineDataGrid,
	type Props as SingleLineDataGridProps,
} from 'modules/data-grid-next/components/single-line-data-grid'
import { type WithTranslateProps, withTranslate } from 'wrappers'
import { handleDownloadFile, openFile } from 'modules/file/domain'

import AdvancedFilter from './advanced-filter'
import BankAccountStatementOptions from '../../../bank/containers/bank-account-statement-options'
import { Column } from 'modules/data-grid-next/components'
import { Component } from 'react'
import CsasStatementsOptions from '../../../bank/containers/csas-statements-options'
import type { Action as DataGridAction } from 'modules/data-grid/types'
import type { DataType } from '../../types'
import Delete from 'components/svg-icons/action/delete'
import DeleteDialog from 'components/delete-dialog'
import FullText from 'components/full-text'
import TextField from 'components/TextField'
import { bool } from 'prop-types'
import { formatToDateString } from 'utils/formatters'
import { getProcessingStateLabel } from 'helpers/helpers'
import { jsx } from '@emotion/core'
import memoize from 'memoize-one'

const GRID_ID: DataGridId = 'statements'
const DATA_TYPE = 'statements'
const DataGrid: React$ComponentType<SingleLineDataGridProps<typeof DATA_TYPE>> = SingleLineDataGrid
const focusTextField = (input: ?TextField) => {
	if (input) {
		setTimeout(() => {
			// $FlowFixMe
			input && input.getWrappedInstance().focus()
		}, 200)
	}
}

type Props = {|
	...WithTranslateProps,
	refreshGrid: (gridId: DataGridId, gridDataType: DataType, defaultFilter?: Filter) => void,
	uploadStatements: (files: Array<File>, authorName: string) => Promise<void>,
	uploadInProgress: boolean,
	deleteStatement: (bankAccountId: string, id: string) => Promise<void>,
	renameStatement: (statementId: string, bankAccountId: string, newStatementName: string) => Promise<void>,
	updateProccesStateStatement: (bankAccountId: string, statmentId: string, processState: string) => Promise<void>,
	canDeleteStatement: boolean,
	canProcessStatement: boolean,
	isUserInternal: boolean,
	isUserAdmin: boolean,
	deleteInProgress: boolean,
	processStatement: (bankAccountId: string, id: string) => Promise<void>,
	id: string,
	statementOptionsDialogOpen: boolean,
	statementOptionsDialogToggle: (open: 'OPEN' | 'CLOSE', format: 'pdf' | 'gpc' | 'cba_xml') => void,
	statementOptionsButton: boolean,
	showUpload: boolean,
	canManageCsas: boolean,
	view: StatementsView,
	onViewChange?: (view: StatementsView) => void,
|}

type State = {|
	editedStatementNameFieldId: ?string,
	editedStatementNewNameField: ?string,
|}

class BankStatementsGrid extends Component<Props, State> {
	dropzone: ?Dropzone

	state = {
		editedStatementNameFieldId: null,
		editedStatementNewNameField: null,
	}

	getDefaultFilter = memoize((id: string, view: StatementsView) => {
		const filter = [
			{
				field: 'bankAccountId',
				value: id,
			},
		]

		if ('all' !== view)
			filter.push({
				field: 'formatClassification',
				value: view,
			})

		return filter
	})

	getActions = (): Array<DataGridAction<Statement>> => {
		const { t } = this.props
		return [
			{
				name: t('grid.action.rename'),
				type: 'rename',
				isVisibleFn: () => this.props.isUserInternal,
			},
			{
				name: t('grid.action.download'),
				type: 'download',
				isVisibleFn: (row: Statement) => !!(row.fileIds && row.fileIds.length > 0),
			},
			{
				name: t('bank.gridAction.processed'),
				type: 'processed',
				isVisibleFn: (row: Statement) => this.props.isUserAdmin && row.processingState !== 1,
			},
			{
				name: t('bank.gridAction.notprocessable'),
				type: 'notprocessable',
				isVisibleFn: (row: Statement) => this.props.isUserAdmin && row.processingState !== 4,
			},
			{
				name: t('bank.gridAction.process'),
				type: 'process',
				isVisibleFn: (row: Statement) =>
					this.props.canProcessStatement && (row.processingState == 0 || row.processingState === 2),
			},
			{
				name: t('grid.action.delete'),
				type: 'delete',
				icon: <Delete />,
				isVisibleFn: () => this.props.canDeleteStatement,
			},
		]
	}

	onAction = async (action: string, statement: Statement) => {
		const { t } = this.props
		switch (action) {
			case 'delete':
				DeleteDialog(t('dialogs.deleteQuestion'), {
					okLabel: t('dialogs.delete'),
					cancelLabel: t('dialogs.dontDelete'),
				})
					.then(() => {
						this.props.deleteStatement(statement.bankAccountId || '', statement.id || '').then(() => {
							this.props.refreshGrid(GRID_ID, DATA_TYPE, this.getDefaultFilter(this.props.id, this.props.view))
						})
					})
					.catch(() => {})
				break
			case 'process':
				statement.id &&
					this.props.processStatement(statement.bankAccountId || '', statement.id).then(() => {
						this.props.refreshGrid(GRID_ID, DATA_TYPE, this.getDefaultFilter(this.props.id, this.props.view))
					})
				break
			case 'rename':
				statement.id && statement.name && this.handleEditName(statement.id, statement.name)
				break
			case 'download':
				statement.name &&
					statement.fileIds &&
					statement.fileIds[0] &&
					(await handleDownloadFile(statement.fileIds[0].toString(), statement.name || '', true))
				break
			case 'processed':
				statement.id &&
					this.props.updateProccesStateStatement(statement.bankAccountId || '', statement.id, '1').then(() => {
						this.props.refreshGrid(GRID_ID, DATA_TYPE, this.getDefaultFilter(this.props.id, this.props.view))
					})
				break
			case 'notprocessable':
				statement.id &&
					this.props.updateProccesStateStatement(statement.bankAccountId || '', statement.id, '4').then(() => {
						this.props.refreshGrid(GRID_ID, DATA_TYPE, this.getDefaultFilter(this.props.id, this.props.view))
					})
				break
			default:
				break
		}
	}

	handleEditName = (statementId: string, statementName: string) => {
		this.setState({
			editedStatementNameFieldId: statementId,
			editedStatementNewNameField: statementName,
		})
	}

	getOpenPreview = (item: Statement) => {
		return () => {
			item.fileIds && item.fileIds.length && this.openPreview(item.fileIds[0], item.name || '')
		}
	}

	openPreview = (fileId: number, fileName: string) => {
		openFile(`${fileId}`, fileName, 'popupPage')
	}

	onDropFiles = (files: Array<File>): Promise<void> => {
		const uploadDispatch = this.props.uploadStatements(files, this.props.id)
		uploadDispatch.then(() => {
			this.props.refreshGrid(GRID_ID, DATA_TYPE, this.getDefaultFilter(this.props.id, this.props.view))
		})

		return uploadDispatch
	}

	openDropzone = () => {
		this.dropzone &&
			this.dropzone
				// $FlowFixMe
				.getWrappedInstance()
				.getWrappedInstance()
				.open()
	}

	dropzoneRefFn = (el: ?Dropzone) => {
		this.dropzone = el
	}

	statementNameRenderer = (value: string, statement: Statement) => {
		return this.state.editedStatementNameFieldId !== statement.id ? (
			<TriviLink onClick={this.getOpenPreview(statement)}>
				<div css={style.name}>
					<FullText text={value} />
				</div>
			</TriviLink>
		) : (
			this.renderStatementNameInput()
		)
	}

	renderStatementNameInput() {
		return (
			<TextField
				ref={focusTextField}
				onKeyDown={this.handleTextFieldKeyPress}
				value={this.state.editedStatementNewNameField}
				onBlur={this.handleItemNameBlur}
				onChange={this.handleItemNameChange}
				autoWidth
				autoFocus
				inline
				name="name"
				autoTestId="statment-item-name"
			/>
		)
	}

	handleItemNameChange = (event: SyntheticInputEvent<HTMLInputElement>, value: ?string) => {
		if (value !== null && value !== undefined) {
			this.setState({
				editedStatementNewNameField: value,
			})
		}
	}

	handleItemNameBlur = (event: ?SyntheticInputEvent<HTMLInputElement>) => {
		event && event.preventDefault()
		this.handleSave()
	}

	handleTextFieldKeyPress = (event: SyntheticKeyboardEvent<HTMLInputElement>) => {
		if (event.key === 'Enter') {
			event && event.preventDefault()
			this.handleSave()
		}
		if (event.key === 'Escape') {
			this.clearState()
		}
	}

	handleSave = () => {
		if (this.state.editedStatementNameFieldId && this.state.editedStatementNewNameField) {
			this.props
				.renameStatement(this.state.editedStatementNameFieldId, this.props.id, this.state.editedStatementNewNameField)
				.then(() => {
					this.props.refreshGrid(GRID_ID, DATA_TYPE, this.getDefaultFilter(this.props.id, this.props.view))
				})
		}
		this.clearState()
	}

	clearState = () => {
		this.setState({
			editedStatementNameFieldId: null,
			editedStatementNewNameField: null,
		})
	}

	uploadedRenderer = (value: string) => {
		return formatToDateString(value)
	}

	processingStateRenderer = (value: number, statement: Statement) => {
		return (
			<span title={statement.processingState === 2 ? statement.processingMessage : ''}>
				{this.props.t(`bank.processingStates.${getProcessingStateLabel(value)}`)}
			</span>
		)
	}

	render() {
		const { t, id, statementOptionsButton, statementOptionsDialogToggle, canManageCsas } = this.props
		const defaultFilter = this.getDefaultFilter(this.props.id, this.props.view)

		return (
			<div>
				<AdvancedFilter
					gridId={GRID_ID}
					view={this.props.view}
					defaultFilter={defaultFilter}
					onViewChange={this.props.onViewChange}
					statementOptionsButton={statementOptionsButton}
					statementOptionsDialogToggle={statementOptionsDialogToggle}
					customStatementsOptionsRenderer={canManageCsas && <CsasStatementsOptions id={id} />}
				/>
				{this.props.statementOptionsDialogOpen && <BankAccountStatementOptions id={id} />}
				{this.props.showUpload && (
					<div css={style.dropzone}>
						<Dropzone onDrop={this.onDropFiles} ref={this.dropzoneRefFn} minHeight={100} multiple />
					</div>
				)}
				<DataGrid
					compactPager={false}
					id={GRID_ID}
					dataType={DATA_TYPE}
					filterId={GRID_ID}
					defaultFilter={defaultFilter}
					actions={this.getActions()}
					onAction={this.onAction}
				>
					<Column
						title={t('bank.columns.statementName')}
						width="45%"
						columnId="name"
						key="name"
						sortable
						render={this.statementNameRenderer}
					/>
					<Column
						title={t('bank.columns.uploaded')}
						width="15%"
						columnId="uploaded"
						key="uploaded"
						sortable
						defaultSortDirection="DESC"
						render={this.uploadedRenderer}
					/>
					<Column title={t('bank.columns.author')} width="25%" columnId="authorName" key="authorName" sortable />
					<Column
						title={t('bank.columns.processingState')}
						width="15%"
						columnId="processingState"
						key="processingState"
						sortable
						render={this.processingStateRenderer}
					/>
				</DataGrid>
			</div>
		)
	}
}

const style = {
	dropzone: {
		marginTop: 15,
		marginBottom: 15,
	},
	name: {
		paddingRight: 10,
	},
}

export default withTranslate(BankStatementsGrid)
