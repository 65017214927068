/* @flow */

import React, { Component } from 'react'
import Dialog from '../containers/dialog'
import Icon from '../containers/icon'
import styles from './contact-me.css'

type Props = {|
	show: boolean,
|}

class ContactMe extends Component<Props> {
	render() {
		if (!this.props.show) {
			return null
		}
		return (
			<div className={styles.contactMe}>
				<Icon />
				<Dialog />
			</div>
		)
	}
}

export default ContactMe
