//@flow
import type { State, Dispatch, DashboardAction } from 'types'
import { getInvitationBannerOpenState } from '../../selectors'
import { switchInvitationBanner } from '../../actions'
import { connect } from 'react-redux'
import InvitationBannerSwitch from '../../components/invitation/invitation-banner-switch'

type StateProps = {|
	open: boolean,
|}

const mapStateToProps = (state: State): StateProps => {
	return {
		open: getInvitationBannerOpenState(state),
	}
}

type DispatchProps = {|
	switchInvitationBanner: (value: boolean) => void,
|}

const mapDispatchToProps = (dispatch: Dispatch<DashboardAction>): DispatchProps => {
	return {
		switchInvitationBanner: (value: boolean) => {
			dispatch(switchInvitationBanner(value))
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(InvitationBannerSwitch)
