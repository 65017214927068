/* @flow */
import React, { Component } from 'react'
import { withNotify, withTranslate, type WithTranslateProps, type WithNotifyProps } from 'wrappers'
import type { FinishCreateStickyNotes } from '../actions/action-types'
import type { StickyNote } from 'types'
import StickyNoteItem from './sticky-note'
import { filter } from 'lodash-es'
import { AddEntity, Tab, Tabs } from 'components'
import styles from './sticky-notes.css'

type Props = {|
	...WithTranslateProps,
	...WithNotifyProps,
	stickyNotes: Array<StickyNote>,
	createStickyNote: (stickyNote: StickyNote) => Promise<any>,
	updateStickyNote: (stickyNote: StickyNote) => ?Promise<any>,
	deleteStickyNote: (stickyNoteId: string) => Promise<void>,
	getStickyNotes: () => Promise<Array<StickyNote>>,
|}

type NoteState = 1 | 2
type State = {|
	noteState: NoteState,
|}

class StickyNotes extends Component<Props, State> {
	state: State = {
		noteState: 1,
	}

	defaultStickyNote: StickyNote = {
		text: '', //this.props.t('internal.widget.stickyNotes.defaultText'),
		state: 1,
	}

	stickyNoteRefs: { [id: string]: any } = {}

	removeStickyNote = (note: StickyNote) => {
		if (note.id != null) this.props.deleteStickyNote(note.id)
	}

	renderStickyNote = (note: StickyNote, index: number) => {
		const { updateStickyNote, deleteStickyNote } = this.props

		let removeHandler = (note: StickyNote) => note.id != null && deleteStickyNote(note.id)
		let bindStickyNote = (noteElement: ?any) => {
			if (noteElement != null) this.stickyNoteRefs[note.id || ''] = noteElement.getWrappedInstance()
		}

		return (
			<StickyNoteItem
				ref={bindStickyNote}
				key={`${index}|${note.id || ''}`}
				stickyNote={note}
				upperFontSizeLimit={30}
				onChange={updateStickyNote}
				onRemove={removeHandler}
			/>
		)
	}

	createStickyNote = () => {
		const { createStickyNote } = this.props

		createStickyNote(this.defaultStickyNote).then((createAction: FinishCreateStickyNotes) => {
			//focus
			if (createAction.response != null && createAction.type === 'FINISH_CREATE_STICKY_NOTE') {
				const noteRef = this.stickyNoteRefs[createAction.response.id || '']

				if (noteRef != null) {
					noteRef.focus()
				}
			}
		})
	}

	handleNoteStateChange = (noteState: NoteState) => {
		this.setState({ noteState })
	}

	renderStickyNotes = () => {
		const { stickyNotes } = this.props

		this.stickyNoteRefs = {}

		const filtered: Array<StickyNote> = filter(stickyNotes, (x: StickyNote) => x.state == this.state.noteState)

		return filtered.map(this.renderStickyNote)
	}

	renderAddEntity = () => {
		if (this.state.noteState == 2) return null

		return (
			<div className={styles.addEntityContainer}>
				<div className={styles.addEntity}>
					<AddEntity labelText={this.props.t('internal.widget.stickyNotes.add')} onClick={this.createStickyNote} />
				</div>
			</div>
		)
	}

	render() {
		const { t } = this.props

		return (
			<div>
				<style>
					{`[contentEditable=true]:empty:not(:focus):before {
						content:attr(data-plc)
					}`}
				</style>
				<Tabs
					large
					tall
					tabItemContainerStyle={{
						borderBottom: 'none',
					}}
					value={this.state.noteState}
					onChange={this.handleNoteStateChange}
				>
					<Tab label={t('internal.widget.stickyNotes.myNotes')} value={90} disabled />
					<Tab label={t('internal.widget.stickyNotes.opened')} value={1} />
					<Tab label={t('internal.widget.stickyNotes.closed')} value={2} />
				</Tabs>
				<div className={styles.container}>
					{this.renderStickyNotes()}
					{this.renderAddEntity()}
				</div>
			</div>
		)
	}
}
export default withTranslate(withNotify(StickyNotes))
