/* @flow */

import React, { PureComponent, type ChildrenArray, type Element } from 'react'
import DataGridContainer from '../containers/data-grid'
import type { DataTypes, DataGridId, MultiAction } from '../types'
import type { Action, Filter } from 'modules/data-grid/types'
import { Column } from '../components'

export type Props<K: $Keys<DataTypes>> = {|
	id: DataGridId,
	dataType: K,
	filterId?: string,
	defaultFilter?: Filter,
	actions?: Array<Action<$ElementType<DataTypes, K>>>,
	multiActions?: Array<Action<Array<$ElementType<DataTypes, K>>>>,
	selectTooltip?: string,
	deselectTooltip?: string,
	selectOnClick?: boolean,
	selectedRows?: Array<string>,
	currentRowId?: string,
	light?: boolean,
	showPageSize?: boolean,
	showItemsOfTotal?: boolean,
	showPrevButton?: boolean,
	showNextButton?: boolean,
	compactPager?: boolean,
	hideHeader?: boolean,
	style?: Object,
	children: ChildrenArray<Element<typeof Column> | null | false>,
	onAction?: (string, $ElementType<DataTypes, K>) => Promise<void>,
	onMultiAction?: (MultiAction, Array<$ElementType<DataTypes, K>>) => Promise<?MultiAction>,
	onActionMenuOpen?: ($ElementType<DataTypes, K>, () => void) => ?Promise<void>,
	onNeedUpdateMultiActionVisiblity?: (Array<$ElementType<DataTypes, K>>) => Promise<Array<string>>,
	onSelectionChange?: (row: $ElementType<DataTypes, K>, selected: boolean) => void,
	onCheckAllChange?: (rows: { [string]: $ElementType<DataTypes, K> }, selected?: boolean) => void,
	checkAllOption?: boolean,
	beforeTableComponent?: any,
	expandedRowRender?: (
		record: $ElementType<DataTypes, K>,
		index: number,
		indent: number,
		expanded: boolean,
	) => ?React$Element<any>,
	expandRowByClick?: boolean,
|}

export default class SingleLineDataGrid extends PureComponent<Props<$Keys<DataTypes>>> {
	static defaultProps = {
		compactPager: true,
	}

	render() {
		return <DataGridContainer style={styles} {...this.props} compactPager={this.props.compactPager} />
	}
}

const styles = {
	root: {
		td: {
			height: 48,
			whiteSpace: 'nowrap',
			textOverflow: 'ellipsis',
		},
	},
	loading: {
		minHeight: 480,
	},
}
