/* @flow */

import React, { Component } from 'react'
import type { CashRegisterAction, CommonAction, Dispatch, OrganizationAction, WithRouterProps } from 'types'
import CashRegisterEdit from '../containers/cash-register-edit'
import PopupSection from 'components/PopupSection'
import { cashRegisterRoute } from 'modules/cash-register/routing/routes'
import { connect } from 'react-redux'
import { loadCashRegister } from '../actions'
import { loadCurrencies } from 'modules/common/actions'
import { loadUsers } from 'modules/organization/actions'
import { push } from 'react-router-redux'
import withAutoload from 'wrappers/with-autoload'
import Helmet from 'react-helmet'
import { withTranslate, type WithTranslateProps } from 'wrappers'

type Props = {|
	...WithRouterProps,
	...WithTranslateProps,
	close: () => void,
|}

class CashRegisterEditPage extends Component<Props> {
	render() {
		const id: string = this.props.match.params.id

		return (
			<PopupSection open onRequestClose={this.props.close}>
				<Helmet>
					<title>{this.props.t('cashRegister.edit.header')}</title>
				</Helmet>
				<CashRegisterEdit id={id} />
			</PopupSection>
		)
	}
}

const mapDispatchToProps = (
	dispatch: Dispatch<CashRegisterAction | OrganizationAction | CommonAction>,
	ownProps: Props & WithRouterProps,
) => {
	return {
		autoload: () => {
			dispatch(loadCashRegister(ownProps.match.params.id))
			dispatch(loadUsers())
			dispatch(loadCurrencies())
		},
		close: () => {
			dispatch(push(cashRegisterRoute(ownProps.match.params.id)))
		},
	}
}

export default withTranslate(connect(null, mapDispatchToProps)(withAutoload(CashRegisterEditPage)))
