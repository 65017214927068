/* @flow */

import React from 'react'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import FullStar from 'components/svg-icons/toggle/star'
import StarBorder from 'components/svg-icons/toggle/star-border'
import { IconButton } from 'components'
import { colors } from 'variables'

type Props = {
	...WithTranslateProps,
	isFavorite?: boolean,
	onClick: (event: SyntheticEvent<HTMLElement>) => void,
}

function Star(props: Props) {
	return (
		<IconButton
			onClick={props.onClick}
			color={colors.orange}
			hoverColor={colors.orange600}
			tooltip={props.isFavorite ? props.t('favorites.removeFromFavorites') : props.t('favorites.addToFavorites')}
			size={24}
		>
			{props.isFavorite ? <FullStar size={16} /> : <StarBorder size={16} />}
		</IconButton>
	)
}

export default withTranslate(Star)
