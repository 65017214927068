//@flow

import SignalR from '../components/SignalR'
import { connect } from 'react-redux'
import type { Dispatch, NotificationsAction, ISignalMessage, State } from 'types'
import {
	initializeConnection,
	addNewMessage,
	stopConnection,
	loadHistory,
	connectionInitializationSuccess,
	removeReadMessages,
	showNotificationPreview,
	hideNotificationPreview,
} from '../actions'
import { tokenToString } from 'lib/apiHandler'
import { HubConnection } from '@aspnet/signalr'
import { getHistoryLoadedFlag } from '../selectors'
import { getAccessToken } from 'utils/local-storage'

type StateProps = {|
	accessToken: string,
	historyLoaded: boolean,
|}

const mapStateToProps = (state: State): StateProps => {
	return {
		accessToken: tokenToString(getAccessToken()) || '',
		historyLoaded: getHistoryLoadedFlag(state),
	}
}

type DispatchProps = {|
	initializeConnection: () => void,
	addNewMessage: (message: ISignalMessage) => void,
	stopConnection: () => void,
	loadHistory: (messages: Array<ISignalMessage>) => void,
	connectionInitializationSuccess: (connection: HubConnection) => void,
	removeReadMessages: (messages: Array<string>) => void,
	showNotificationPreview: (message: ISignalMessage) => void,
|}

const mapDispatchToProps = (dispatch: Dispatch<NotificationsAction>): DispatchProps => {
	return {
		stopConnection: () => {
			dispatch(stopConnection())
		},
		initializeConnection: () => {
			dispatch(initializeConnection())
		},
		addNewMessage: (message: ISignalMessage) => {
			dispatch(addNewMessage(message))
		},
		loadHistory: (messages: Array<ISignalMessage>) => {
			dispatch(loadHistory(messages))
		},
		connectionInitializationSuccess: (connection: HubConnection) => {
			dispatch(connectionInitializationSuccess(connection))
		},
		removeReadMessages: (messages: Array<string>) => {
			dispatch(removeReadMessages(messages))
		},
		showNotificationPreview: (message: ISignalMessage) => {
			dispatch(showNotificationPreview(message))
			setTimeout(() => {
				dispatch(hideNotificationPreview())
			}, 10000)
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(SignalR)
