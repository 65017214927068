/* @flow */

import type { Dispatch, State } from 'types'
import { loadContact } from 'modules/address-book'
import ContactEmailsSelector from '../../components/invoice-settings/contact-emails-selector'
import { connect } from 'react-redux'
import { getContact } from 'modules/address-book/selectors'

type OwnProps = {|
	contactId: ?string,
	readonly: boolean,
	clientError?: ?string,
	onEmailsChange: (emails: Array<string>) => void,
	emails?: Array<string>,
|}

function mapStateToProps(state: State, ownProps: OwnProps) {
	return {
		contact: ownProps.contactId ? getContact(state, ownProps.contactId) : null,
	}
}

function mapDispatchToProps(dispatch: Dispatch<*>) {
	return {
		loadContact: (contactId: string) => {
			return dispatch(loadContact(contactId))
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactEmailsSelector)
