// @flow

import React, { Component } from 'react'
import memoize from 'memoize-one'
import validate from 'wrappers/validate'
import { getTooltipErrorLabel } from 'helpers'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import type {
	AccountingDocument,
	FormValidationProps,
	I18NextTranslateFn,
	FormFieldErrorContext,
	FormFieldErrorMessage,
} from 'types'
import { AccountingDocumentTypeNumber_isCashRegister } from 'types/operations'
import { autoTestId, type AutoTestProps } from 'utils/tests/autotest'
import CashRegisterSelector from 'modules/common/containers/cash-register-selector'
import SelectField from 'components/select-field'
import MenuItem from 'components/menu-item'
import styles from './classification.css'

export type Props = {|
	accountingDocumentId: string,
	accountingDocument: ?AccountingDocument,
	classification: ClassificationType,
	errors: ?Array<FormFieldErrorMessage>,
	formFieldErrorContext?: FormFieldErrorContext,
	onChange: (classification: ClassificationType) => void,
	...AutoTestProps,
|}

export type ClassificationType = {|
	direction: number,
	type: number,
	cashRegisterId?: string,
|}

type ComponentProps = { ...Props, ...WithTranslateProps, ...FormValidationProps }

class Classification extends Component<ComponentProps, void> {
	static defaultProps = {
		classification: {
			direction: 0,
			type: 0,
		},
	}

	handleOnDirectionChange = (event: void, index: number, direction: number) => {
		this.props.onChange(
			Object.freeze({
				...this.props.classification,
				direction,
			}),
		)
	}

	handleOnTypeChange = (event: void, index: number, type: number) => {
		this.props.onChange(
			Object.freeze({
				...this.props.classification,
				type,
				cashRegisterId: AccountingDocumentTypeNumber_isCashRegister(type)
					? this.getCashRegisterId() || undefined
					: undefined,
			}),
		)
	}

	handleCashRegisterChange = (cashRegisterId: ?string) => {
		this.props.onChange(
			Object.freeze({
				...this.props.classification,
				cashRegisterId: cashRegisterId || undefined,
			}),
		)
	}

	getDirectionOptions = memoize((t: I18NextTranslateFn) => {
		return [
			<MenuItem key={0} value={0} primaryText={t('invoice.classification.buy')} />,
			<MenuItem key={1} value={1} primaryText={t('invoice.classification.sell')} />,
		]
	})

	getTypeOptions = memoize((t: I18NextTranslateFn) => {
		return [
			<div key={'accdocs'} className={styles.sectionHeadline}>
				{t('invoice.classification.accountingDocuments')}
			</div>,
			<MenuItem key={0} value={0} primaryText={t('invoice.classification.invoice')} />,
			<MenuItem key={1} value={1} primaryText={t('invoice.classification.advance')} />,
			<MenuItem key={2} value={2} primaryText={t('invoice.classification.taxAdvance')} />,
			<MenuItem key={3} value={3} primaryText={t('invoice.classification.creditNote')} />,

			<div key={'cashregister'} className={styles.sectionHeadline}>
				{t('invoice.classification.cashRegisterItems')}
			</div>,
			<MenuItem key={8} value={8} primaryText={t('invoice.classification.simplifiedInvoice')} />,
			this.props.classification.direction === 0 && (
				<MenuItem key={10} value={10} primaryText={t('invoice.classification.employeeAdvance')} />
			),
			<MenuItem key={11} value={11} primaryText={t('invoice.classification.cashTransfer')} />,
			<MenuItem key={7} value={7} primaryText={t('invoice.classification.cashReceipt')} />,
			<MenuItem key={12} value={12} primaryText={t('invoice.classification.cashUncategorized')} />,
		]
	})

	getCashRegisterId = () => {
		return (
			this.props.classification.cashRegisterId ||
			(this.props.accountingDocument && this.props.accountingDocument.cashRegisterId)
		)
	}

	render() {
		if (!this.props.accountingDocument) return null

		return (
			<div className={styles.root}>
				<div className={styles.row}>
					<div className={styles.third}>
						<SelectField
							fullWidth
							compact
							value={this.props.classification.direction}
							labelText={this.props.t('invoice.classification.selectDirection')}
							autoTestId="invoice-classification-direction"
							onChange={this.handleOnDirectionChange}
							{...autoTestId('classification-direction')}
						>
							{this.getDirectionOptions(this.props.t)}
						</SelectField>
					</div>
					<div className={styles.third}>
						<SelectField
							fullWidth
							compact
							value={this.props.classification.type}
							labelText={this.props.t('invoice.classification.selectType')}
							autoTestId="invoice-classification-type"
							onChange={this.handleOnTypeChange}
							maxHeight={145}
							{...autoTestId(this.props.autoTestId, 'classification-type')}
						>
							{this.getTypeOptions(this.props.t)}
						</SelectField>
					</div>
					<div className={styles.third}>
						{AccountingDocumentTypeNumber_isCashRegister(this.props.classification.type) && (
							<CashRegisterSelector
								compact
								onChange={this.handleCashRegisterChange}
								value={this.getCashRegisterId()}
								label={this.props.t('uploadScan.chooseCashRegister')}
								error={getTooltipErrorLabel(this.props.errors, this.props.t)}
							/>
						)}
					</div>
				</div>
			</div>
		)
	}
}

export default withTranslate(validate(['FINISH_ACKNOWLEDGE_ACCOUNTING_DOCUMENT'])(Classification))
