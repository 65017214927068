/* @flow */

import { connect } from 'react-redux'
import type { Dispatch, State, TemplatesAction } from 'types'
import { createNewTemplate } from 'modules/accounting-document/actions'
import { isSomeTemplateCreating } from '../../selectors'
import CreateTemplateButton from '../../components/accounting-document-list/create-template-button'

type StateProps = {|
	isSomeTemplateCreating: boolean,
|}

const mapStateToProps = (state: State): StateProps => {
	return {
		isSomeTemplateCreating: isSomeTemplateCreating(state),
	}
}

type DispatchProps = {|
	onCreateTemplate: () => void,
|}

const mapDispatchToProps = (dispatch: Dispatch<TemplatesAction>): DispatchProps => {
	return {
		onCreateTemplate: () => {
			dispatch(createNewTemplate())
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateTemplateButton)
