/* @flow */
import type {
	AccountingDocumentReduxAction,
	AccountingDocumentSearchExport,
	Dispatch,
	SearchFilter,
	State,
} from 'types'

import { EMPTY_ARRAY } from 'trivi-constants'
import ExportButton from './export-button-component'
import { connect } from 'react-redux'
import { exportAccountingDocuments } from 'modules/accounting-document/actions'
import { getAccountingDocumentsExporting } from 'modules/accounting-document/selectors'

type OwnProps = {|
	filterId: string,
	defaultFilter: Array<SearchFilter>,
	sortingField?: string,
	sortingDirection?: 'ASC' | 'DESC',
|}

const mapStateToProps = (state: State, ownProps: OwnProps) => {
	return {
		exporting: getAccountingDocumentsExporting(state),
		filters: state.grid.filter[ownProps.filterId] || EMPTY_ARRAY,
	}
}

const mapDispatchToProps = (dispatch: Dispatch<AccountingDocumentReduxAction>) => {
	return {
		onExport: (searchExport: AccountingDocumentSearchExport) => {
			dispatch(exportAccountingDocuments(searchExport))
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ExportButton)
