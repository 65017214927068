/* @flow */

import React from 'react'
import { Route } from 'react-router'
import MainLayout from 'layouts/main-layout'

// Libraries
import { UserIsAuthenticated } from 'lib/auth'

import FileView from '../pages/file-view'

const ViewComponent = UserIsAuthenticated(MainLayout()(FileView))

const FileRouting = () => [
	<Route exact key="/:organizationId/file/:fileId" path="/:organizationId/file/:fileId" component={ViewComponent} />,
]

export default FileRouting
