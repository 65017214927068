/* @flow */

import type { ScanFactoryDocumentFileMetadata } from 'types'
import type { AutoFillEntry, AutoFillMap, FieldCursor } from '../types'
import { getField } from './field'

export async function autoFill(
	metadata: ScanFactoryDocumentFileMetadata,
	fieldCursor: FieldCursor,
	fillingMap: AutoFillMap,
	editField: (fieldCursor: FieldCursor, value: string) => void,
) {
	const field = getField(metadata, fieldCursor)
	if (!field) {
		return
	}

	return await Promise.all(
		fillingMap.map(async (fillingEntry: AutoFillEntry) => {
			if (field.propertyName && fillingEntry.fieldName === field.propertyName) {
				let data = fillingEntry.get(field)
				if (!data) {
					await fillingEntry.load(field)
					data = fillingEntry.get(field)
				}

				if (data) {
					for (const fillingProperty in fillingEntry.filling) {
						const editingCursor = { ...fieldCursor, ...{ name: fillingProperty } }
						const editingField = getField(metadata, editingCursor)

						const value = fillingEntry.filling[fillingProperty](data)
						if (value && editingField && !editingField.value) {
							editField(editingCursor, value)
						}
					}
				}
			}
		}),
	)
}
