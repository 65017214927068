import React from 'react'
import pure from 'recompose/pure'
import SvgIcon from 'components/SvgIcon'

let AddressBook = props => (
	<SvgIcon {...props}>
		<defs>
			<polygon id="path-1" points="4 21.5535994 20.6721099 21.5535994 20.6721099 2 4 2" />
		</defs>
		<g id="Page-1" stroke="none" strokeWidth="1" fillRule="evenodd">
			<g id="Apple-TV">
				<g id="Group">
					<g id="addressbook">
						<path
							d="M7.40854789,3.77759995 C6.50908232,3.77759995 5.77759995,4.50908232 5.77759995,5.40854789 L5.77759995,18.1459403 C5.77759995,19.0445171 6.50908232,19.7759995 7.40854789,19.7759995 L16.0432396,19.7759995 C16.9427052,19.7759995 17.6741876,19.0445171 17.6741876,18.1459403 L17.6741876,5.40854789 C17.6741876,4.50908232 16.9427052,3.77759995 16.0432396,3.77759995 L7.40854789,3.77759995 Z M16.0432396,21.5535994 L7.40854789,21.5535994 C5.52873595,21.5535994 4,20.0248634 4,18.1459403 L4,5.40854789 C4,3.52873595 5.52873595,2 7.40854789,2 L16.0432396,2 C17.9230516,2 19.4517875,3.52873595 19.4517875,5.40854789 L19.4517875,18.1459403 C19.4517875,20.0248634 17.9230516,21.5535994 16.0432396,21.5535994 Z"
							id="Fill-1"
						/>
						<path
							d="M11.7088288,8.53970132 C10.9684584,8.53970132 10.365852,9.1423077 10.365852,9.88267808 C10.365852,10.6230485 10.9684584,11.224766 11.7088288,11.224766 C12.4491992,11.224766 13.0518056,10.6230485 13.0518056,9.88267808 C13.0518056,9.1423077 12.4491992,8.53970132 11.7088288,8.53970132 M11.7088288,12.113566 C10.4787296,12.113566 9.47705207,11.1127772 9.47705207,9.88267808 C9.47705207,8.65169011 10.4787296,7.65090135 11.7088288,7.65090135 C12.938928,7.65090135 13.9406055,8.65169011 13.9406055,9.88267808 C13.9406055,11.1127772 12.938928,12.113566 11.7088288,12.113566"
							id="Fill-3"
						/>
						<path
							d="M8.78254377,14.8450261 L14.6344028,14.8450261 C14.4059812,13.4602757 13.1367748,12.3270558 11.7084733,12.3270558 C10.2810605,12.3270558 9.01185417,13.4602757 8.78254377,14.8450261 L8.78254377,14.8450261 Z M15.1170212,15.7338261 L8.0066214,15.7338261 L8.0066214,15.2894261 C8.0066214,13.2016349 9.47403015,11.4382558 11.5618213,11.4382558 C13.6496124,11.4382558 15.1170212,13.2016349 15.1170212,15.2894261 L15.1170212,15.7338261 Z"
							id="Fill-5"
						/>
						<mask id="mask-2">
							<use xlinkHref="#path-1" />
						</mask>
						<g id="Clip-8" />
						<polygon
							id="Fill-7"
							opacity="0.800000012"
							mask="url(#mask-2)"
							points="18.5629875 8.6233374 20.6721099 8.6233374 20.6721099 5.40854789 18.5629875 5.40854789"
						/>
						<polygon
							id="Fill-9"
							opacity="0.800000012"
							mask="url(#mask-2)"
							points="18.5629875 12.8451373 20.6721099 12.8451373 20.6721099 9.63034776 18.5629875 9.63034776"
						/>
						<polygon
							id="Fill-10"
							opacity="0.800000012"
							mask="url(#mask-2)"
							points="18.5629875 17.0669371 20.6721099 17.0669371 20.6721099 13.8521476 18.5629875 13.8521476"
						/>
					</g>
				</g>
			</g>
		</g>
	</SvgIcon>
)
AddressBook = pure(AddressBook)

export default AddressBook
