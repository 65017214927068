// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PNlsq3ONzwa_Y7hLZk-dD {\n\tdisplay: flex;\n\twidth: 250px;\n}\n", "",{"version":3,"sources":["webpack://./modules/internal/components/billing/period-picker.css"],"names":[],"mappings":"AAAA;CACC,aAAA;CACA,YAAA;AACD","sourcesContent":[".wrapper {\n\tdisplay: flex;\n\twidth: 250px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "PNlsq3ONzwa_Y7hLZk-dD"
};
export default ___CSS_LOADER_EXPORT___;
