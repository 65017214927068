/* @flow */
/** @jsx jsx */
/* eslint-disable react/no-multi-comp */

import moize from 'moize'
import { jsx } from '@emotion/core'
import { type WithTranslateProps } from 'wrappers'
import { ProcessingState, Tooltip } from 'components'
import { Column } from 'modules/data-grid-next/components'
import type { AccountingDocument } from 'types'
import type { Direction } from 'modules/data-grid-next/types'
import { DATA_GRID_CACHE_SIZE } from 'trivi-constants'
import AlertError from 'components/svg-icons/alert/error'
import { colors } from 'variables'

export type Props = {|
	showProcessingState: boolean,
	currentUserIsInternal: boolean,
	width?: number | string,
	defaultSortDirection?: Direction,
	...WithTranslateProps,
|}

export default function getStateColumn(props: Props) {
	function columnRenderer(value: string, row: AccountingDocument) {
		let state = ''
		switch (value.toLocaleLowerCase()) {
			case 'draft':
				state = props.t('accountingDocument.columns.stateDraft')
				break
			case 'waiting for approval':
				state = props.t('accountingDocument.columns.stateWaiting')
				break
			case 'processed':
				state = props.t('accountingDocument.columns.stateProcessed')
				break
			case 'extracting':
				state = props.t('accountingDocument.columns.stateExtracting')
				break
			case 'extracted':
				state = props.t('accountingDocument.columns.stateExtracted')
				break
			case 'fixing':
				state = props.t('accountingDocument.columns.stateFixing')
				break
			case 'needhelp':
				state = props.currentUserIsInternal
					? props.t('accountingDocument.columns.stateNeedHelp')
					: props.t('accountingDocument.columns.stateExtracting')
				break
			case 'rejected':
				state = props.t('accountingDocument.columns.stateRejected')
				break
		}

		const hasExtractingProblem = row && row.__extractingState === 4

		const defaultStateColumn = (
			<span>
				{state}
				{props.showProcessingState && (
					<div>
						<ProcessingState
							processingState={row.processingState}
							processingMessage={row.processingMessage}
							notProcessedLabel={props.t('accountingDocument.processingStates.notProcessed')}
							processedLabel={props.t('accountingDocument.processingStates.processed')}
							waitingLabel={props.t('accountingDocument.processingStates.waiting')}
							errorLabel={props.t('accountingDocument.processingStates.error')}
						/>
					</div>
				)}
			</span>
		)

		return props.currentUserIsInternal && hasExtractingProblem ? (
			<div css={styles.extractingProblemWrapper}>
				{defaultStateColumn}
				<div css={styles.extractingProblem}>
					<Tooltip label={props.t('accountingDocument.filter.extractingState.problemWithExtracting')} inline>
						<AlertError disabled color={colors.redFaded60} />
					</Tooltip>
				</div>
			</div>
		) : (
			defaultStateColumn
		)
	}

	const columnRendererCashed = moize(columnRenderer, { maxSize: DATA_GRID_CACHE_SIZE })

	return (
		<Column
			title={props.t('accountingDocument.columns.state')}
			width={props.width}
			columnId="state"
			key="state"
			render={columnRendererCashed}
			sortable
			defaultSortDirection={props.defaultSortDirection || undefined}
		/>
	)
}

const styles = {
	extractingProblemWrapper: {
		display: 'flex',
		alignItems: 'center',
	},
	extractingProblem: {
		marginLeft: 10,
	},
}
