/* @flow */
import type { AccountingDocumentSearchExport, SearchFilter } from 'types'
import { IconButton, Loader } from 'components'
import React, { Component } from 'react'
import { type WithTranslateProps, withTranslate } from 'wrappers'

import EjectIcon from 'components/svg-icons/file/file-upload'

type Props = {|
	...WithTranslateProps,
	filterId: string,
	defaultFilter: Array<SearchFilter>,
	sortingField?: string,
	sortingDirection?: 'ASC' | 'DESC',
	exporting: boolean,
	filters: Array<SearchFilter>,
	onExport: (searchExport: AccountingDocumentSearchExport) => void,
|}

class ExportButton extends Component<Props> {
	onExport = () => {
		const filters: Array<SearchFilter> = [...this.props.defaultFilter]

		//filter collection comparison
		for (let index = 0; index < this.props.filters.length; index++) {
			let filterIndex = filters.findIndex((item: SearchFilter) => item.field === this.props.filters[index].field)

			if (filterIndex > -1) {
				if (filters[filterIndex].value !== null && filters[filterIndex].value !== undefined) {
					filters[filterIndex].value = this.props.filters[index].value
				} else {
					filters[filterIndex].valueContains = this.props.filters[index].valueContains
				}
			} else {
				filters.push(this.props.filters[index])
			}
		}

		let searchText = undefined
		const searchTextIndex: number = filters.findIndex((filterItem: SearchFilter) => filterItem.field === 'searchText')
		if (searchTextIndex > -1) {
			searchText = filters[searchTextIndex].value || undefined
			filters.splice(searchTextIndex, 1)
		}

		this.props.onExport({
			search: {
				filters: filters,
				sortingField: this.props.sortingField,
				sortingDirection: this.props.sortingDirection,
				page: undefined,
				pageSize: undefined,
				searchText: searchText,
			},
			language: 'CS',
		})
	}

	render() {
		return (
			<IconButton
				disabled={this.props.exporting}
				onClick={this.onExport}
				tooltip={this.props.t('accountingDocument.exportTooltip')}
				autoTestId="grid-export"
				circled
			>
				<EjectIcon />
				<Loader transparent visible={this.props.exporting} size={40} />
			</IconButton>
		)
	}
}

export default withTranslate(ExportButton)
