/* @flow */
import React from 'react'
import BalanceSummary from '../containers/balance-summary/balance-summary'
import BillingSummary from '../containers/billing-summary/billing-summary'

function Dashboard() {
	return (
		<div>
			<div style={styles.billingSummary}>
				<BillingSummary />
			</div>
			<div style={styles.balanceSummary}>
				<BalanceSummary />
			</div>
		</div>
	)
}

export default Dashboard

const styles = {
	billingSummary: {
		marginBottom: '3em',
	},
	balanceSummary: {
		marginBottom: '1em',
	},
}
