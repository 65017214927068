/* @flow */

import 'whatwg-fetch'

import { __setAppComponent, i18n } from 'locales'
import store, { history } from 'store'

// Import Components
import { App, Routes } from 'app'
import { colors, depths } from 'variables'

// Import Modules
import { I18nextProvider } from 'react-i18next'
import { NProgress } from 'redux-nprogress'
import { Provider } from 'react-redux'
import React from 'react'
import ReactDOM from 'react-dom'
import { Route } from 'react-router'
import { ConnectedRouter } from 'react-router-redux'
import Tracking from 'app/tracking'
import { isEnabled } from 'utils/features'
import SignalR from './modules/notifications/containers/SignalR'
import PermissionsTestTool from 'app/permissions/test-tool/test-tool-container'

// TODO-CASHBOT [start-of-block] just for testing - delete after release --------------
import { getCompanyInfo, getCompanyList, createInvoice, login, getInvoiceDetail } from 'modules/cashbot/api'
import { testCashbotInvoiceCreate } from 'modules/cashbot/api/test-data'
import { getCashbotToken } from 'modules/cashbot/selectors'
import storage from 'utils/local-storage'

const token = () => getCashbotToken(store.getState()) || ''

window.CASHBOT = {
	login: async () => {
		return await login()
	},
	getCompanyInfo: async (companyRegNo: string) => {
		return await getCompanyInfo(token(), companyRegNo)
	},
	getCompanyList: async () => {
		return await getCompanyList(token())
	},
	createInvoice: async () => {
		return await createInvoice(token(), testCashbotInvoiceCreate)
	},
	getInvoiceDetail: async () => {
		return await getInvoiceDetail(token(), 6)
	},
	setTestToken: (testToken: string) => {
		storage.set('cashbotTestToken', testToken)
	},
	removeTestToken: () => {
		storage.remove('cashbotTestToken')
	},
	getReduxData: () => {
		window.STORE = store
		return store.getState().cashbot
	},
}
// TODO-CASHBOT [end-of-block] just for testing - delete after release --------------

const style = {
	progressWrapper: {
		position: 'fixed',
		pointerEvents: 'none',
		height: 4,
		top: 0,
		left: 0,
		right: 0,
		zIndex: depths.appLoader,
	},
	progress: {
		minimum: 0.3,
		trickleSpeed: 200,
		showSpinner: true,
		speed: 500,
		parent: '#progress-wrapper',
	},
}

// Router
const router = (
	<Provider store={store}>
		<I18nextProvider i18n={i18n}>
			<div>
				<div id="progress-wrapper" style={style.progressWrapper}>
					<NProgress color={colors.orange} nprogress={style.progress} />
				</div>
				<ConnectedRouter history={history}>
					<Route
						path="/:organizationId?"
						render={() => ( // eslint-disable-line
							<div>
								<App>
									<Routes />
								</App>
								<Tracking />
								{isEnabled('notifications') ? <SignalR /> : null}
								<PermissionsTestTool />
							</div>
						)}
					/>
				</ConnectedRouter>
			</div>
		</I18nextProvider>
	</Provider>
)

const root = document.getElementById('root')

if (root) {
	const APP_REACT_COMPONENT = ReactDOM.render(router, root)
	__setAppComponent(APP_REACT_COMPONENT)
}
