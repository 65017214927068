/* @flow */

import React from 'react'
import { Route } from 'react-router'
import MainLayout from 'layouts/main-layout'

// Libraries
import { UserIsAuthenticated, UserInternalPermission } from 'lib/auth'

import CashRegisterList from 'modules/cash-register/pages/cash-register-list'
import CashRegister from 'modules/cash-register/pages/cash-register'
import CashRegisterEdit from 'modules/cash-register/pages/cash-register-edit'
import CashRegisterCreate from 'modules/cash-register/pages/cash-register-create'
import CashRegisterMakeInventory from 'modules/cash-register/pages/cash-register-make-inventory'

const ListComponent = UserIsAuthenticated(MainLayout('cashRegister')(CashRegisterList))
const CreateComponent = UserInternalPermission(UserIsAuthenticated(MainLayout('cashRegister')(CashRegisterCreate)))
const DetailComponent = UserIsAuthenticated(MainLayout('cashRegister')(CashRegister))
const EditComponent = UserIsAuthenticated(MainLayout('cashRegister')(CashRegisterEdit))
const MakeInventoryComponent = UserIsAuthenticated(MainLayout('cashRegister')(CashRegisterMakeInventory))

const CashRegisterRouting = () => [
	<Route exact key="/:organizationId/cash-register" path="/:organizationId/cash-register" component={ListComponent} />,
	<Route
		exact
		key="/:organizationId/cash-register/create"
		path="/:organizationId/cash-register/create"
		component={CreateComponent}
	/>,
	<Route
		exact
		key="/:organizationId/cash-register/:id"
		path="/:organizationId/cash-register/:id"
		component={DetailComponent}
	/>,
	<Route
		exact
		key="/:organizationId/cash-register/:id/unknown"
		path="/:organizationId/cash-register/:id/unknown"
		component={DetailComponent}
	/>,
	<Route
		exact
		key="/:organizationId/cash-register/:id/received"
		path="/:organizationId/cash-register/:id/received"
		component={DetailComponent}
	/>,
	<Route
		exact
		key="/:organizationId/cash-register/:id/issued"
		path="/:organizationId/cash-register/:id/issued"
		component={DetailComponent}
	/>,
	<Route
		exact
		key="/:organizationId/cash-register/:id/edit"
		path="/:organizationId/cash-register/:id/edit"
		component={EditComponent}
	/>,
	<Route
		exact
		key="/:organizationId/cash-register/:id/make-inventory"
		path="/:organizationId/cash-register/:id/make-inventory"
		component={MakeInventoryComponent}
	/>,
]

export default CashRegisterRouting
