// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[3].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js!../../../variables/colors.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1DiV87wwfmTHbF26Bjz210 {\n\tmargin-left: -5px;\n\tborder-radius: 3px;\n\tpadding: 0 5px;\n\tdisplay: inline-block;\n\tuser-select: none;\n\tcursor: pointer;\n}\n\n._1DiV87wwfmTHbF26Bjz210:hover {\n\tbackground: " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["grey200"] + ";\n}\n\n._15uLMLR3sXEF42KYVfsjhC {\n\tbackground: " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["grey200"] + ";\n}\n\n._15zl6WlNvwhwCcaMCMVjea {\n\tmin-width: 170px;\n}\n\n._3UD-iiGL4c3DenJGvDbFQM {\n\tpadding: 8px 0;\n}\n", "",{"version":3,"sources":["webpack://./modules/data-grid/components/data-grid-popover.css"],"names":[],"mappings":"AAEA;CACC,iBAAA;CACA,kBAAA;CACA,cAAA;CACA,qBAAA;CACA,iBAAA;CACA,eAAA;AAeD;;AAZA;CACC,wDAAA;AAeD;;AAZA;CAEC,wDAAA;AAeD;;AAZA;CACC,gBAAA;AAeD;;AAZA;CACC,cAAA;AAeD","sourcesContent":["@value white, black, grey400, grey200, grey300 from \"variables/colors.css\";\n\n.label {\n\tmargin-left: -5px;\n\tborder-radius: 3px;\n\tpadding: 0 5px;\n\tdisplay: inline-block;\n\tuser-select: none;\n\tcursor: pointer;\n}\n\n.label:hover {\n\tbackground: grey200;\n}\n\n.openedLabel {\n\tcomposes: label;\n\tbackground: grey200;\n}\n\n.popover {\n\tmin-width: 170px;\n}\n\n.children {\n\tpadding: 8px 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"white": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["white"] + "",
	"black": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["black"] + "",
	"grey400": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["grey400"] + "",
	"grey200": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["grey200"] + "",
	"grey300": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["grey300"] + "",
	"label": "_1DiV87wwfmTHbF26Bjz210",
	"openedLabel": "_15uLMLR3sXEF42KYVfsjhC _1DiV87wwfmTHbF26Bjz210",
	"popover": "_15zl6WlNvwhwCcaMCMVjea",
	"children": "_3UD-iiGL4c3DenJGvDbFQM"
};
export default ___CSS_LOADER_EXPORT___;
