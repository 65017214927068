// @flow

import type { AccountingDocumentDirection, Dispatch, Enum, SearchFilter, State, DataGridReduxAction } from 'types'
import { currentUserIsInternal } from 'modules/user/selectors'
import CashRegisterBalancesComponent from '../components/cash-register-balances'
import { connect } from 'react-redux'
import { getOrganizationBranches, getOrganizationProjects } from '../../organization/selectors'
import { clearGridFilterField } from '../../data-grid/actions'
import { CASH_REGISTER_FILTER_ID } from '../constants'

type StateProps = {|
	showProcessingState: boolean,
	direction: ?AccountingDocumentDirection,
	internal: ?boolean,
	type: ?number,
	branches: ?Enum,
	projects: ?Enum,
|}

const mapStateToProps = (state: State): StateProps => {
	// const directionItem: ?SearchFilter =
	// 	state.grid.filter.cashRegisterPayments &&
	// 	state.grid.filter.cashRegisterPayments.find((item: SearchFilter) => item.field === 'direction')
	// const direction: ?number = directionItem && parseInt(directionItem.value)

	const typeItem: ?SearchFilter =
		state.grid.filter.cashRegisterPayments &&
		state.grid.filter.cashRegisterPayments.find((item: SearchFilter) => item.field === 'type')
	const type: ?number = typeItem && parseInt(typeItem.value)

	return {
		showProcessingState: true,
		internal: currentUserIsInternal(state),
		direction: state.cashRegister.direction,
		type,
		branches: getOrganizationBranches(state),
		projects: getOrganizationProjects(state),
	}
}

type DispatchProps = {|
	resetSequencesFilter: (filterName: string) => void,
|}

const mapDispatchToProps = (dispatch: Dispatch<DataGridReduxAction>): DispatchProps => {
	return {
		resetSequencesFilter: () => {
			dispatch(clearGridFilterField(`${CASH_REGISTER_FILTER_ID}/unknown`, 'sequenceId'))
			dispatch(clearGridFilterField(`${CASH_REGISTER_FILTER_ID}/issued`, 'sequenceId'))
			dispatch(clearGridFilterField(`${CASH_REGISTER_FILTER_ID}/received`, 'sequenceId'))
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(CashRegisterBalancesComponent)
