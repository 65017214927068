//@flow

import React, { Component } from 'react'
import type { Signatures } from 'types'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import SignaturesList from './signatures-list'
import SignaturesUpload from './signatures-upload'
import styles from '../settings.css'

type Props = {|
	...WithTranslateProps,
	loading: boolean,
	signatures: ?Signatures,
	signaturesUploadProgress: {|
		[key: string]: {
			progress: ?number,
			name: ?string,
		},
	|},
	uploadSignatures: (Array<File>) => Promise<*>,
	listSignatures: () => void,
	removeSignature: (signatureId: string) => void,
	dismissUploadProgress: (key: string) => void,
|}

class SignaturesComponent extends Component<Props> {
	componentDidMount() {
		this.props.listSignatures && this.props.listSignatures()
	}

	render() {
		const { t, signatures } = this.props

		return (
			<div>
				{(this.props.loading || signatures) && (
					<div>
						<h3 className={styles.firstH3}>{t('settings.signatures.list')}</h3>
						<SignaturesList removeSignature={this.props.removeSignature} signatures={signatures} />
					</div>
				)}
				<h3 className={signatures ? styles.h3 : styles.firstH3}>{t('settings.signatures.upload')}</h3>
				<SignaturesUpload
					uploadSignatures={this.props.uploadSignatures}
					uploadProgress={this.props.signaturesUploadProgress}
					onDismissUploadProgress={this.props.dismissUploadProgress}
				/>
			</div>
		)
	}
}

export default withTranslate(SignaturesComponent)
