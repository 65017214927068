/* @flow */
/** @jsx jsx */

import { Component } from 'react'
import { jsx } from '@emotion/core'
import {
	withTranslate,
	withAccountingDocumentActions,
	type WithAccountingDocumentActionsProps,
	type WithTranslateProps,
} from 'wrappers'
import { getPaymentTypeByName } from 'modules/accounting-document/domain/accounting-document-multi-actions'
import type { AccountingDocumentAction, AccountingDocumentCategory, Enum, EnumItem, AccountingDocument } from 'types'
import PaymentTypeSelector from 'modules/common/components/selectors/payment-type-selector'
import { getNullableCategoriesMenuItems } from 'modules/accounting-document/domain/categories'
import CashRegisterSelector from 'modules/common/containers/cash-register-selector'
import SelectNext from 'components/select-next'
import Dialog from 'components/dialog'
import Button from 'components/button'
import type { OptionType } from 'components/select-next'

type Props = {|
	...WithTranslateProps,
	...WithAccountingDocumentActionsProps,
	open: boolean,
	accountingDocument: ?AccountingDocument,
	documentCategories: ?Array<AccountingDocumentCategory>,
	branches: ?Enum,
	projects: ?Enum,
	onClose: () => void,
|}

type State = {|
	accountingDocument: ?AccountingDocument,
|}

class EditAttributesDialog extends Component<Props, State> {
	state: State = {
		accountingDocument: this.props.accountingDocument,
	}

	isActionVisible = (type: string): boolean => {
		const { accountingDocument } = this.state
		if (!this.props.accountingDocument || !this.props.accountingDocument.id) return false
		const data =
			(this.props.availableActions[this.props.accountingDocument.id] &&
				this.props.availableActions[this.props.accountingDocument.id].data) ||
			[]

		const paymentType =
			(accountingDocument && accountingDocument.paymentType) || this.props.accountingDocument.paymentType || undefined
		if (type === 'accdoc_change_cashregister' && paymentType !== getPaymentTypeByName('cash')) {
			return false
		}

		return !!data.find((availabileAction: AccountingDocumentAction) => availabileAction.type === type)
	}

	save = async () => {
		if (!this.state.accountingDocument || !this.props.accountingDocument) return null
		await this.props.updateAccountingDocument(this.props.accountingDocument, this.state.accountingDocument)
		this.props.onClose()
	}

	onBranchChange = (preferredBranch: OptionType) => {
		this.setState({
			accountingDocument: {
				...this.state.accountingDocument,
				preferredBranch: preferredBranch && preferredBranch.value ? preferredBranch.value : undefined,
			},
		})
	}

	onProjectChange = (preferredProject: OptionType) => {
		this.setState({
			accountingDocument: {
				...this.state.accountingDocument,
				preferredProject: preferredProject && preferredProject.value ? preferredProject.value : undefined,
			},
		})
	}

	onPaymentTypeChange = (paymentType: string) => {
		const originalCashRegisterId =
			(this.state.accountingDocument && this.state.accountingDocument.cashRegisterId) || undefined

		this.setState({
			accountingDocument: {
				...this.state.accountingDocument,
				cashRegisterId: 2 === paymentType ? originalCashRegisterId : undefined,
				paymentType: parseInt(paymentType),
			},
		})
	}

	onCashRegisterChange = (cashRegisterId: ?string) => {
		this.setState({
			accountingDocument: {
				...this.state.accountingDocument,
				cashRegisterId: cashRegisterId || undefined,
			},
		})
	}

	onCategoryChange = (categoryId: OptionType) => {
		this.setState({
			accountingDocument: {
				...this.state.accountingDocument,
				categoryId: categoryId && categoryId.value ? categoryId.value : undefined,
			},
		})
	}

	renderForm = () => {
		if (!this.props.open) return null

		const { t, branches, projects, documentCategories } = this.props
		const { accountingDocument } = this.state

		return (
			<div css={styles.column}>
				{this.isActionVisible('accdoc_change_category') && (
					<div css={styles.row}>
						<SelectNext
							onChange={this.onCategoryChange}
							label={t('uploadScan.documentType')}
							value={(accountingDocument && accountingDocument.categoryId) || null}
							autoTestId="edit-attributes-dialog-type"
							options={getNullableCategoriesMenuItems(documentCategories, t)}
						/>
					</div>
				)}
				{this.isActionVisible('accdoc_change_branch') && (
					<div css={styles.row}>
						{branches && branches.length > 0 && (
							<SelectNext
								label={t('uploadScan.branchAttribute')}
								autoTestId="edit-attributes-dialog-branch"
								onChange={this.onBranchChange}
								value={accountingDocument && accountingDocument.preferredBranch}
								isClearable
								isSearchable
								editCursor
								options={branches.map((item: EnumItem) => {
									return { value: item.key, label: item.value }
								})}
							/>
						)}
					</div>
				)}
				{this.isActionVisible('accdoc_change_project') && (
					<div css={styles.row}>
						{projects && projects.length > 0 && (
							<SelectNext
								label={t('uploadScan.projectAttribute')}
								autoTestId="edit-attributes-dialog-project"
								onChange={this.onProjectChange}
								value={accountingDocument && accountingDocument.preferredProject}
								isClearable
								isSearchable
								editCursor
								options={projects.map((item: EnumItem) => {
									return { value: item.key, label: item.value }
								})}
							/>
						)}
					</div>
				)}
				{this.isActionVisible('accdoc_change_paymenttype') && (
					<div css={styles.row}>
						<PaymentTypeSelector
							label={t('uploadScan.paymentTypeAttribute')}
							value={accountingDocument && accountingDocument.paymentType && accountingDocument.paymentType.toString()}
							onChange={this.onPaymentTypeChange}
							portal
						/>
					</div>
				)}
				{this.isActionVisible('accdoc_change_cashregister') && (
					<div css={styles.row}>
						<CashRegisterSelector
							isClearable
							onChange={this.onCashRegisterChange}
							value={accountingDocument && accountingDocument.cashRegisterId}
							label={t('invoice.classification.cashRegister')}
						/>
					</div>
				)}
			</div>
		)
	}

	render() {
		const { t } = this.props

		const actions = [
			<Button
				autoTestId="edit-attributes-dialog-ok"
				primary
				wide
				key="okButton"
				labelText={t('settings.forms.saveButton')}
				onClick={this.save}
			/>,
			<Button
				secondary
				wide
				key="cancelButton"
				labelText={this.props.t('settings.forms.cancelButton')}
				onClick={this.props.onClose}
				autoTestId="edit-attributes-dialog-cancel"
			/>,
		]

		return (
			<Dialog
				title={t('accountingDocument.gridActions.editAttributes')}
				actions={actions}
				open={this.props.open}
				autoTestId="edit-attributes-dialog"
			>
				{this.renderForm()}
			</Dialog>
		)
	}
}

const styles = {
	column: {
		width: 300,
		margin: '10px auto 0 auto',
	},
	row: {
		marginBottom: 18,
	},
}

export default withTranslate(withAccountingDocumentActions(EditAttributesDialog))
