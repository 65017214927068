// @flow

import { uniq } from 'lodash-es'
import storage from 'utils/local-storage'

const SEND_EMAIL_AUTOCOMPLETE_STORAGE_KEY = 'sendEmailAutocompleteItems'

export function getSavedEmails(): Array<string> {
	const value = storage.get(SEND_EMAIL_AUTOCOMPLETE_STORAGE_KEY)
	return value && Array.isArray(value) ? uniq(value) : []
}

export function saveNewEmails(emails: Array<string>) {
	const saved = getSavedEmails()
	storage.set(SEND_EMAIL_AUTOCOMPLETE_STORAGE_KEY, uniq([...saved, ...emails]))
}
