// @flow
/** @jsx jsx */

import { PureComponent } from 'react'
import { jsx } from '@emotion/core'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import NoteIcon from 'components/svg-icons/av/note'
import ChevronRight from 'components/svg-icons/navigation/chevron-right'
import Paper from 'components/Paper'
import IconButton from 'components/icon-button'
import memoize from 'memoize-one'

const OPENABLE_FROM_CHARS = 30

type Props = {|
	...WithTranslateProps,
	note: ?string,
	internal: boolean,
	extractable: boolean,
	accountingDocumentId: string,
	getOrganizationNoteExtraction: () => void,
|}

type State = {|
	open: boolean,
|}

class ExtractionNote extends PureComponent<Props, State> {
	state = {
		open: false,
	}

	componentDidMount() {
		if (!this.props.note && this.props.internal && this.props.extractable) {
			// Non-internal users dont have permission to load extraction note
			this.props.getOrganizationNoteExtraction()
		}
	}

	onArrowClick = () => {
		this.setState({ open: !this.state.open })
	}

	getStyles = memoize((open: boolean) => {
		return {
			root: {
				fontSize: 14,
				lineHeight: '17px',
				display: 'flex',
				justifyContent: 'flex-start',
				alignItems: 'flex-start',
				paddingLeft: 2,
				paddingRight: 4,
			},
			icon: {
				marginLeft: 5,
				marginRight: 5,
				flex: '0 0 auto',
				position: 'relative',
				textAlign: 'center',
				top: 4,
			},
			arrow: {
				flex: '0 0 24px',
				transform: open ? 'rotate(90deg)' : 'rotate(0deg)',
				transition: 'all 100ms ease-in-out',
			},
			text: {
				flex: '1 1 auto',
				overflow: 'hidden',
				whiteSpace: open ? 'pre-line' : 'nowrap',
				textOverflow: open ? 'initial' : 'ellipsis',
				lineHeight: '17px',
				padding: '3px 2px',
			},
			label: {
				fontWeight: 600,
				lineHeight: '17px',
			},
			note: {
				lineHeight: '17px',
			},
		}
	})

	render() {
		if (!this.props.note || !this.props.internal || !this.props.extractable) return null
		const showArrow = this.props.note.length > OPENABLE_FROM_CHARS
		const styles = this.getStyles(this.state.open)

		return (
			<Paper zDepth={4} rounded>
				<div css={styles.root}>
					<div css={styles.icon}>
						<NoteIcon disabled size={14} />
					</div>
					<div css={styles.text}>
						<span css={styles.label}>{this.props.t('invoice.classification.extractionNoteLabel')}:&nbsp;</span>
						<span>{this.props.note}</span>
					</div>
					{showArrow && (
						<div css={styles.arrow}>
							<IconButton size={24} onClick={this.onArrowClick}>
								<ChevronRight size={22} />
							</IconButton>
						</div>
					)}
				</div>
			</Paper>
		)
	}
}

export default withTranslate(ExtractionNote)
