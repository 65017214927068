/* @flow */

import React, { Component } from 'react'
import { Transition } from 'react-transition-group'

export type Props = {|
	children: any,
	direction: 'left' | 'right',
	style?: Object,
	in?: boolean,
|}

const styles = {
	root: {
		position: 'absolute',
		height: '100%',
		width: '100%',
		top: 0,
		left: 0,
		transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1)',
	},
}
class CalendarSlideChild extends Component<Props> {
	getTransitionStyles() {
		return {
			entering: {
				opacity: 0,
				transform: `translateX(${this.props.direction === 'left' ? '100%' : '-100%'})`,
			},
			entered: {
				opacity: 1,
				transform: 'translateX(0)',
			},
			exiting: {
				opacity: 0,
				transform: `translateX(${this.props.direction === 'left' ? '-100%' : '100%'})`,
			},
			exited: {
				opacity: 0,
				transform: `translateX(${this.props.direction === 'left' ? '-100%' : '100%'})`,
			},
		}
	}

	render() {
		return (
			<Transition unmountOnExit timeout={0} in={this.props.in}>
				{(status: string) => (
					<div style={{ ...styles.root, ...this.getTransitionStyles()[status] }}>{this.props.children}</div>
				)}
			</Transition>
		)
	}
}

export default CalendarSlideChild
