/* @flow */
/** @jsx jsx */

import { jsx } from '@emotion/core'
import { withRouter } from 'react-router'
import type { UnmatchedPaymentMatch, WithRouterProps } from 'types'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import { editAccountingDocumentRoute } from 'modules/accounting-document/routing/routes'
import DeleteIcon from 'components/svg-icons/action/delete'
import deleteDialog from 'components/delete-dialog'
import IconButton from 'components/icon-button'
import FileIcon from 'components/file-icon'
import TriviLink from '../../../common/components/trivi-link'

type Props = {|
	...WithRouterProps,
	...WithTranslateProps,
	document: UnmatchedPaymentMatch,
	isInternal: boolean,
	onDelete?: (match: UnmatchedPaymentMatch) => void,
|}

function Attachment(props: Props) {
	function onDelete() {
		deleteDialog().then(() => props.onDelete && props.onDelete(props.document))
	}

	function onClick() {
		props.history.push(
			editAccountingDocumentRoute(
				props.document.accountingDocumentId || '',
				undefined,
				!props.isInternal ? 'preview' : undefined,
			),
		)
	}

	return (
		<div css={styles.attachment}>
			<div css={styles.wrapper} onClick={onClick}>
				<div css={styles.icon}>
					<FileIcon fileName={props.document.accountingDocumentId} />
				</div>
				<div css={styles.content}>
					<TriviLink>
						<div css={styles.cccountingDocumentId} className="name">
							{props.document.accountingDocumentNo ||
								props.t('datagrid.implemented.unmatchedPayments.attachmentLabel', {
									id: props.document.accountingDocumentId,
								})}
						</div>
						{props.document.note && (
							<div css={styles.note}>
								<span css={styles.noteLabel}>{props.t('datagrid.implemented.unmatchedPayments.note')}:</span>{' '}
								{props.document.note}
							</div>
						)}
					</TriviLink>
				</div>
			</div>
			{!!props.onDelete && (
				<div css={styles.delete} className="delete">
					<IconButton autoTestId="grid-expanded-attachment-delete" style={styles.iconButton} onClick={onDelete}>
						<DeleteIcon size={14} />
					</IconButton>
				</div>
			)}
		</div>
	)
}

export default withRouter(withTranslate(Attachment))

const styles = {
	attachment: {
		paddingTop: 10,
		paddingBottom: 10,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-start',
		'&:hover': {
			'.delete': {
				opacity: 1,
				pointerEvents: 'all',
			},
		},
	},
	wrapper: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-start',
		cursor: 'pointer',
		'&:hover': {
			'.name': {
				textDecoration: 'underline',
			},
		},
	},
	icon: {
		flex: '0 0 30px',
	},
	content: {
		flex: '0 1 auto',
		position: 'relative',
		top: 1,
	},
	delete: {
		flex: '0 0 24px',
		alignSelf: 'center',
		display: 'flex',
		alignItems: 'center',
		transition: 'all 300ms',
		pointerEvents: 'none',
		marginLeft: 5,
		opacity: 0,
	},
	iconButton: {
		width: 24,
		height: 24,
	},
	cccountingDocumentId: {
		minHeight: 24,
		lineHeight: '24px',
		whiteSpace: 'nowrap',
	},
	note: {
		color: '#4D4D4D',
		whiteSpace: 'normal',
	},
	noteLabel: {
		fontWeight: 600,
	},
}
