//@flow
import type { State, ISignalMessage } from 'types'
import { HubConnection } from '@aspnet/signalr'

export const getAllNotification = (state: State): Array<ISignalMessage> => {
	return (state && state.notifications && state.notifications.messages) || []
}

export const getHubConnection = (state: State): ?HubConnection => {
	return state && state.notifications && state.notifications.hubConnection
}

export const getNotificationPreview = (state: State): ?ISignalMessage => {
	return state && state.notifications && state.notifications.notificationPreview
}

export function getHistoryLoadedFlag(state: State): boolean {
	return state && state.notifications && state.notifications.historyLoaded
}
