/* @flow */
/** @jsx jsx */

import { Component } from 'react'
import { jsx } from '@emotion/core'
import { AccountingDocumentDirection_Number } from 'types/convertor'
import type {
	AccountingDocument,
	AccountingDocumentAction,
	AccountingDocumentActions,
	DataGridId,
	Filter,
	FileDescription,
	Task,
} from 'types'
import {
	accountingDocumentNumberRaw,
	canBeAccDocCopied,
	getAccountingDocumentActions,
	relatedAccountingDocumentNumber,
} from 'helpers'
import TriviLink from 'modules/common/components/trivi-link'
import ConfirmDialog from 'components/confirm-dialog'
import DeleteDialog from 'components/delete-dialog'
import { formatToDateString } from 'utils/formatters'
import { showAccountingDocumentRoute, editAccountingDocumentRoute } from 'modules/accounting-document/routing/routes'
import { withAccountingDocumentActions, withTranslate } from 'wrappers'
import type { WithAccountingDocumentActionsProps, WithTranslateProps } from 'wrappers'
import { DataGrid as DataGridNext, type DataGridProps as DataGridNextProps } from 'modules/data-grid-next/containers'
import { Column } from 'modules/data-grid-next/components'
import { getStateColumn } from 'modules/accounting-document/components/data-grid-columns'
import Direction from 'components/direction'
import AmountDisplay from 'components/amount-display'
import DirectionAmountDisplay from 'components/direction-amount-display'
import SendEmailDialog from 'modules/common/containers/send-email-dialog'
import TaskEdit from 'modules/task/containers/task-edit'
import styles from './cash-register-payments.css'
import { colors } from 'variables'
import CreditNoteDialog from 'modules/accounting-document/containers/invoice-elements/credit-note-dialog'
// import EETBadge from 'modules/accounting-document/components/accounting-document-list/eet-badge'
import AccountingDocumentSentIcon from 'modules/accounting-document/components/accounting-document-sent-icon'
import LocalOfferIcon from 'components/svg-icons/maps/local-offer'
import Tooltip from 'components/tooltip'
import Tracking from 'utils/tracking'

const AccountingDocumentDataGrid: React$ComponentType<DataGridNextProps<'accountingDocuments'>> = DataGridNext

type Props = {|
	...WithAccountingDocumentActionsProps,
	...WithTranslateProps,
	id: string,
	defaultFilter: Filter,
	gridId: DataGridId,
	filterId: string,
	showProcessingState?: ?boolean,
	internal: ?boolean,
	isMeCashRegisterOwner: boolean,
	currentSortColumnId: ?string,
	canEditCashRegisters: boolean,
	refreshGrid: (id: DataGridId, defaultFilter?: Filter, filterId: string) => Promise<void>,
	validateAccountingDocument: (accountingDocumentId: string, state: string) => Promise<boolean>,
|}

type State = {
	creditNoteOpen: boolean,
	dialogRelatedDocument: ?string,
	sendEmailDialogDocument: ?AccountingDocument,
	// newTaskDialogDocument: ?AccountingDocument,
}
class CashRegisterPayments extends Component<Props, State> {
	state: State = {
		creditNoteOpen: false,
		dialogRelatedDocument: null,
		sendEmailDialogDocument: null,
		// newTaskDialogDocument: null,
	}

	columns: Array<{}>
	gridRefresh: Function

	shouldComponentUpdate(nextProps: Props, nextState: State) {
		return (
			this.props.id !== nextProps.id ||
			this.props.filterId !== nextProps.filterId ||
			this.props.showProcessingState !== nextProps.showProcessingState ||
			this.props.availableActions !== nextProps.availableActions ||
			this.state.dialogRelatedDocument !== nextState.dialogRelatedDocument ||
			this.state.sendEmailDialogDocument !== nextState.sendEmailDialogDocument ||
			// this.state.newTaskDialogDocument !== nextState.newTaskDialogDocument ||
			this.props.currentSortColumnId !== nextProps.currentSortColumnId
		)
	}

	hideCreditNote = () => {
		this.setState({ creditNoteOpen: false })
	}

	saveCreditNote = (value: { date: string, files: Array<FileDescription> }) => {
		const { dialogRelatedDocument } = this.state
		if (dialogRelatedDocument) {
			this.setState({ creditNoteOpen: false })
			this.props.confirmCreditNote(dialogRelatedDocument, value)
		}
	}

	closeEmailSendDialog = () => {
		this.setState({ sendEmailDialogDocument: null })
	}

	onSendDocument = (emails: Array<string>, templateId: number) => {
		const { sendEmailDialogDocument } = this.state
		this.props.sendAccountingDocument((sendEmailDialogDocument && sendEmailDialogDocument.id) || '', templateId, emails)
		this.closeEmailSendDialog()
		this.refreshGrid()
	}

	// generateNewTask = (): Task => {
	// 	const { newTaskDialogDocument } = this.state
	// 	return {
	// 		attachedAccountingDocumentIds: [
	// 			{
	// 				accountingDocumentId: newTaskDialogDocument ? newTaskDialogDocument.id : '',
	// 				accountingDocumentNo: newTaskDialogDocument ? newTaskDialogDocument.accountingDocumentNo : '',
	// 			},
	// 		],
	// 	}
	// }

	// closeNewTaskDialog = () => {
	// 	this.setState({ newTaskDialogDocument: null })
	// }

	refreshGrid = () => {
		this.props.refreshGrid(this.props.gridId, this.props.defaultFilter, this.props.filterId)
	}

	isVisibleAction = (type: string) => {
		return (row: AccountingDocument): boolean => {
			if (!this.props.availableActions) {
				return false
			}
			const availableActionsState: ?{ data?: ?AccountingDocumentActions } = this.props.availableActions[row.id || '']
			const availableActions: ?AccountingDocumentActions = availableActionsState && availableActionsState.data

			// Pokladna - doklady, které nemají pripojeny scan, nemají mít možnost Znovu vytěžit.
			if (type === 'accdoc_reextract') {
				if (!row.scans || !row.scans.length) {
					return false
				}
			}

			if (!availableActions) {
				return false
			}

			if (type === 'accdoc_copy') {
				const canCopy = canBeAccDocCopied(row.type)
				if (!canCopy) return false
			}

			if (type === 'accdoc_send_email') {
				return row.direction === 1
			}

			if (row && row.processingState === 1) {
				if (type === 'accdoc_set_processing_state') return false
			}

			return (
				availableActions.findIndex((availabileAction: AccountingDocumentAction) => availabileAction.type === type) !==
				-1
			)
		}
	}

	isVisibleMoveAction = (state: string) => {
		const type = 'accdoc_movewf'
		return (row: AccountingDocument): boolean => {
			if (!this.props.availableActions) {
				return false
			}
			const availableActionsState: ?{ data?: ?AccountingDocumentActions } = this.props.availableActions[row.id || '']
			const availableActions: ?AccountingDocumentActions = availableActionsState && availableActionsState.data

			if (!availableActions) {
				return false
			}

			const action = availableActions.find(
				(availabileAction: AccountingDocumentAction) => availabileAction.type === type,
			)

			if (!action || !action.params || !action.params.possibleStates) {
				return false
			}

			return action.params.possibleStates.findIndex((item: string) => item === state) !== -1
		}
	}

	handleActionMenuOpen = async (row: AccountingDocument, needUpdate: () => void) => {
		if (row.id) {
			await this.props.loadAvailableActions([row.id])
			needUpdate()
		}
	}

	handleAction = async (action: string, row: AccountingDocument) => {
		const { t } = this.props
		const deleteDocument = async () => {
			if (row.id) {
				await this.props.removeAccountingDocument(row.id).then(await this.refreshGrid)
				row.id && Tracking.trackAccDocDeleted(row.id, 'manual')
			}
		}
		switch (action) {
			case 'accdoc_remove':
				DeleteDialog().then(() => {
					if (row.processingState == 0) return deleteDocument()
					ConfirmDialog(t('dialogs.deleteFromESO'), {
						okLabel: t('dialogs.yesOption'),
						cancelLabel: t('dialogs.noOption'),
						waitForConfirm: true,
					}).then(deleteDocument)
				})
				break
			case 'accdoc_confirm_creditnote': {
				if (row.id) {
					this.setState({ dialogRelatedDocument: row.id, creditNoteOpen: true })
				}
				break
			}
			case 'accdoc_send_email': {
				this.setState({ sendEmailDialogDocument: row })
				break
			}
			case 'accdoc_reextract':
				ConfirmDialog(t('dialogs.reextractQuestion'), {
					okLabel: t('dialogs.yesOption'),
					cancelLabel: t('dialogs.noOption'),
				}).then(() => {
					row.id && this.props.reextractAccountingDocument(row).then(this.refreshGrid)
				})
				break
			case 'accdoc_copy':
				ConfirmDialog(t('dialogs.copyDocumentQuestion'), {
					okLabel: t('dialogs.yesOption'),
					cancelLabel: t('dialogs.noOption'),
				}).then(() => {
					// $FlowFixMe
					delete row.context
					this.props.copyAccountingDocument(row).then(this.refreshGrid)
				})
				break
			// case 'accdoc_task_create':
			// 	if (row.id != null) {
			// 		this.setState({ newTaskDialogDocument: row })
			// 	}
			// 	break
			case 'accdoc_movewf_fixing':
				if (!this.props.internal) {
					ConfirmDialog(t('dialogs.nonInternalChangeToFixingTitle'), {
						body: t('dialogs.nonInternalChangeToFixingBody'),
						okLabel: t('dialogs.nonInternalChangeToFixingYesOption'),
						cancelLabel: t('dialogs.goBackOption'),
					}).then(() => {
						this.props.defaultOnAction(action, row, () => {
							row.id && this.props.goOnEditAccountingDocumentRoute(row.id)
						})
					})
				} else {
					this.props.defaultOnAction(action, row, () => {
						row.id && this.props.goOnEditAccountingDocumentRoute(row.id)
					})
				}
				break
			case 'accdoc_movewf_processed':
				row.id &&
					this.props.validateAccountingDocument(row.id, 'Processed').then((valid: boolean) => {
						if (true === valid) {
							this.props.defaultOnAction(action, row, this.refreshGrid)
						} else {
							ConfirmDialog(t('dialogs.changeStateToProcessedWhenInvalid'), {
								okLabel: t('dialogs.changeStateToProcessedWhenInvalidYesOption'),
								cancelLabel: t('dialogs.closeOption'),
							}).then(() => {
								row.id && this.props.goOnEditAccountingDocumentRoute(row.id)
							})
						}
					})
				break
			default:
				this.props.defaultOnAction(action, row, this.refreshGrid)
		}
	}

	directionRenderer(value: number) {
		return (
			<Direction
				transparent
				issued={value === AccountingDocumentDirection_Number('issued')}
				received={value === AccountingDocumentDirection_Number('received')}
			/>
		)
	}

	accountingDocumentNoRenderer = (value: string, row: AccountingDocument) => {
		const routeFn =
			row.state !== 'Processed' && (this.props.canEditCashRegisters || this.props.isMeCashRegisterOwner)
				? editAccountingDocumentRoute
				: showAccountingDocumentRoute
		const firstLineItemDescription: string =
			row.lineItems && row.lineItems.length > 0 ? row.lineItems[0].description || '' : ''
		return (
			<div>
				<div css={style.no}>
					<TriviLink routeFn={routeFn} routeParams={[row.id || '']}>
						<span className={styles.no}>{accountingDocumentNumberRaw(row)}</span>
					</TriviLink>
					{/*<div css={style.eet}>*/}
					{/*	<EETBadge countryVariantSpecific={row.countryVariantSpecific} />*/}
					{/*</div>*/}
					{(row.customerInstructions || '').trim() && (
						<span css={style.customerInstructions}>
							<Tooltip inline label={row.customerInstructions} multiLine>
								<LocalOfferIcon hoverColor={colors.black} size={16} style={style.icon} />
							</Tooltip>
						</span>
					)}
				</div>
				<span className={styles.info}>{row.type === 8 ? firstLineItemDescription : row.description}</span>
			</div>
		)
	}

	issueDateRenderer(value: Date, row: AccountingDocument) {
		return (
			<div css={style.row}>
				<div css={style.date}>{formatToDateString(value)}</div>
				<div css={style.sent}>
					<AccountingDocumentSentIcon accountingDocument={row} />
				</div>
			</div>
		)
	}

	relatedAccountingDocumentIdRenderer(value: string, row: AccountingDocument) {
		return row.relatedAccountingDocumentId ? (
			<div>
				<TriviLink routeFn={showAccountingDocumentRoute} routeParams={[row.relatedAccountingDocumentId || '']}>
					<span className={styles.no}>{relatedAccountingDocumentNumber(row)}</span>
				</TriviLink>
			</div>
		) : null
	}

	totalRenderer(value: number, row: AccountingDocument) {
		return (
			<div css={style.total}>
				<DirectionAmountDisplay
					amount={row.total}
					currency={row.currency}
					income={row.direction === AccountingDocumentDirection_Number('issued')}
					outcome={row.direction === AccountingDocumentDirection_Number('received')}
				/>
			</div>
		)
	}

	getBalanceColumn(props: Props, width: string) {
		function renderer(value?: number, row: AccountingDocument) {
			const { balance, currency } = row
			return (
				<span>
					{balance != null && <AmountDisplay currency={currency} amount={balance} />}
					{balance == null && (
						<span
							style={{
								display: 'inline-block',
								userSelect: 'none',
								width: 12,
								height: 1,
								background: colors.gray400,
								verticalAlign: 'middle',
							}}
						/>
					)}
				</span>
			)
		}

		const infoTooltip =
			props.currentSortColumnId !== 'issueDate'
				? props.t('cashRegister.transaction.balanceOnlyWithIssueDate')
				: undefined

		return (
			<Column
				title={props.t('cashRegister.transaction.balance')}
				width={width}
				columnId="balance"
				key="balance"
				render={renderer}
				infoTooltip={infoTooltip}
			/>
		)
	}

	render() {
		const { t } = this.props

		const actions = getAccountingDocumentActions(
			t,
			'unknown',
			this.props.availableActions,
			undefined,
			this.isVisibleAction,
			this.isVisibleMoveAction,
		)

		const { sendEmailDialogDocument /*newTaskDialogDocument*/ } = this.state

		return (
			<div>
				<AccountingDocumentDataGrid
					id={this.props.gridId}
					filterId={this.props.filterId}
					dataType="accountingDocuments"
					defaultFilter={this.props.defaultFilter}
					actions={actions}
					onAction={this.handleAction}
					onActionMenuOpen={this.handleActionMenuOpen}
				>
					<Column width="56px" columnId="direction" key="direction" render={this.directionRenderer} sortable />
					<Column
						title={t('cashRegister.transaction.number')}
						width="24%"
						columnId="accountingDocumentNo"
						key="accountingDocumentNo"
						render={this.accountingDocumentNoRenderer}
						sortable
					/>
					<Column
						title={t('cashRegister.transaction.date')}
						defaultSortDirection="DESC"
						width="16%"
						columnId="issueDate"
						key="issueDate"
						render={this.issueDateRenderer}
						sortable
					/>
					{getStateColumn({
						t,
						showProcessingState: !!this.props.showProcessingState,
						width: '18%',
						currentUserIsInternal: this.props.internal || false,
					})}
					<Column
						title={t('cashRegister.transaction.accountingDocument')}
						width="16%"
						columnId="relatedAccountingDocumentId"
						key="relatedAccountingDocumentId"
						render={this.relatedAccountingDocumentIdRenderer}
					/>
					<Column
						title={t('cashRegister.transaction.amount')}
						width="16%"
						columnId="total"
						key="total"
						render={this.totalRenderer}
						sortable
						headerStyle={style.header}
					/>
					{/* TODO TRV-1326 retun column after solved search service {this.getBalanceColumn(this.props, '16%')} */}
				</AccountingDocumentDataGrid>

				<CreditNoteDialog
					open={this.state.creditNoteOpen}
					hideCreditNote={this.hideCreditNote}
					saveCreditNote={this.saveCreditNote}
				/>

				<SendEmailDialog
					open={!!sendEmailDialogDocument}
					onClose={this.closeEmailSendDialog}
					onSend={this.onSendDocument}
					accountingDocumentId={sendEmailDialogDocument && sendEmailDialogDocument.id}
				/>

				{/*{!!newTaskDialogDocument && (*/}
				{/*	<TaskEdit redirectBack onClose={this.closeNewTaskDialog} defaultData={this.generateNewTask()} />*/}
				{/*)}*/}
			</div>
		)
	}
}

const style = {
	header: {
		textAlign: 'right',
		paddingRight: 20,
	},
	no: {
		display: 'flex',
		alignItems: 'center',
	},
	eet: {
		display: 'inline-flex',
		marginBottom: 1,
		marginLeft: 5,
	},
	row: {
		display: 'flex',
		overflow: 'hidden',
		alignItems: 'center',
	},
	date: {
		flex: '1 1 auto',
		overflow: 'hidden',
	},
	sent: {
		flex: '0 1 30px',
		paddingRight: 15,
	},
	customerInstructions: {
		marginLeft: 6,
		marginRight: 2,
		flex: '0 0 16px',
	},
	icon: {
		verticalAlign: 'text-top',
	},
	total: {
		textAlign: 'right',
		paddingRight: 20,
	},
}

export default withTranslate(withAccountingDocumentActions(CashRegisterPayments))
