// @flow
/** @jsx jsx */

import { jsx } from '@emotion/core'
import type { PaymentReminder, EmailTemplate } from 'types'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import Delete from 'components/svg-icons/action/delete'
import Edit from 'components/svg-icons/image/edit'
import DeleteDialog from 'components/delete-dialog'
import TableCell from 'components/table/table-cell'
import TableRow from 'components/table/table-row'
import MenuItem from 'components/menu-item'
import IconMenu from 'components/icon-menu'
import FullText from 'components/full-text'
import { findTemplateById } from '../../domain/emails'

type Props = {|
	...WithTranslateProps,
	reminder: PaymentReminder,
	emailTemplates: ?Array<EmailTemplate>,
	onEdit: (reminderId: string) => void,
	onDelete: (reminderId: string) => void,
	onTemplateEdit: (emailTemplateId: string) => void,
|}

function PaymentReminderItem(props: Props) {
	function onEdit() {
		props.reminder.id && props.onEdit(props.reminder.id)
	}

	function onTemplateEdit() {
		props.reminder.emailTemplateId && props.onTemplateEdit(props.reminder.emailTemplateId)
	}

	function onDelete() {
		if (!props.reminder.id) return
		DeleteDialog().then(() => {
			props.reminder.id && props.onDelete(props.reminder.id)
		})
	}

	const found = findTemplateById(props.reminder.emailTemplateId, props.emailTemplates)
	const name = (found && found.name) || props.reminder.emailTemplateId

	return (
		<TableRow>
			<TableCell onClick={onEdit}>
				<span css={style.link}>
					<FullText text={name} />
				</span>
			</TableCell>
			<TableCell>{props.reminder.exceededDays}</TableCell>
			<TableCell>
				<IconMenu autoTestId="settings-email-template-item-actions" context>
					{[
						<MenuItem key="edit" primaryText={props.t('grid.action.edit')} icon={<Edit />} onClick={onEdit} tall />,
						<MenuItem
							key="edit-template"
							primaryText={props.t('settings.paymentReminders.editTemplate')}
							icon={<Edit />}
							onClick={onTemplateEdit}
							tall
						/>,
						<MenuItem
							primaryText={props.t('grid.action.delete')}
							icon={<Delete />}
							onClick={onDelete}
							key="delete"
							tall
						/>,
					]}
				</IconMenu>
			</TableCell>
		</TableRow>
	)
}

const style = {
	link: {
		'&:hover': {
			cursor: 'pointer',
			textDecoration: 'underline',
		},
	},
}

export default withTranslate(PaymentReminderItem)
