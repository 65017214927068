// @flow

import type { ApiApp, ApiAppsSearchResult, Dispatch } from 'types'
import {
	organizationApiApp as organizationApiAppApi,
	organizationApiApps as organizationApiAppsApi,
} from 'modules/common/models/api-model'

import type { Action } from './api-apps-action-types'

export function loadOrganizationApiApps() {
	return async (dispatch: Dispatch<Action>) => {
		dispatch({
			type: 'START_LOADING_ORGANIZATION_API_APPS',
		})
		try {
			const result: ApiAppsSearchResult = await organizationApiAppsApi.get({})
			dispatch({
				type: 'FINISH_LOADING_ORGANIZATION_API_APPS',
				apiApps: result.apiApps,
			})
		} catch (serverError) {
			dispatch({
				serverError,
				type: 'FINISH_LOADING_ORGANIZATION_API_APPS',
				apiApps: null,
			})
		}
	}
}

export function addOrganizationApiApp(name: string) {
	return async (dispatch: Dispatch<Action>) => {
		dispatch({
			type: 'START_ADDING_ORGANIZATION_API_APP',
		})
		try {
			const body: ApiApp = { name }
			const apiApp: ApiApp = await organizationApiAppsApi.post({}, body)
			dispatch({
				type: 'FINISH_ADDING_ORGANIZATION_API_APP',
				apiApp,
			})
		} catch (serverError) {
			dispatch({
				serverError,
				type: 'FINISH_ADDING_ORGANIZATION_API_APP',
				apiApp: null,
			})
		}
	}
}

export function removeOrganizationApiApp(apiAppId: number) {
	return async (dispatch: Dispatch<Action>) => {
		dispatch({
			type: 'START_REMOVING_ORGANIZATION_API_APP',
		})
		try {
			await organizationApiAppApi.delete({ apiAppId })
			dispatch({
				type: 'FINISH_REMOVING_ORGANIZATION_API_APP',
				apiAppId,
			})
		} catch (serverError) {
			dispatch({
				serverError,
				type: 'FINISH_REMOVING_ORGANIZATION_API_APP',
				apiAppId: null,
			})
		}
	}
}

export function editOrganizationApiApp(apiAppId: number, apiApp: ApiApp) {
	return async (dispatch: Dispatch<Action>) => {
		dispatch({
			type: 'START_EDITING_ORGANIZATION_API_APP',
		})
		try {
			const result: ApiApp = await organizationApiAppApi.put({ apiAppId }, apiApp)
			dispatch({
				type: 'FINISH_EDITING_ORGANIZATION_API_APP',
				apiApp: result,
			})
		} catch (serverError) {
			dispatch({
				serverError,
				type: 'FINISH_EDITING_ORGANIZATION_API_APP',
				apiApp: null,
			})
		}
	}
}
