/* @flow */

import React, { PureComponent } from 'react'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import type { AccountingDocumentContact } from 'types'
import FullStar from 'components/svg-icons/toggle/star'
import StarBorder from 'components/svg-icons/toggle/star-border'
import { IconButton } from 'components'
import { colors } from 'variables'

type Props = {
	...WithTranslateProps,
	isFavorite: boolean,
	contact: AccountingDocumentContact,
	onFavorite: (any, boolean) => void,
}

class ContactSelectStar extends PureComponent<Props> {
	onClick = (event: SyntheticEvent<HTMLElement>) => {
		event.stopPropagation()
		this.props.onFavorite(this.props.contact, !this.props.isFavorite)
	}

	render() {
		return (
			<IconButton
				onClick={this.onClick}
				color={colors.orange}
				hoverColor={colors.orange600}
				tooltip={
					this.props.isFavorite
						? this.props.t('favorites.removeFromFavorites')
						: this.props.t('favorites.addToFavorites')
				}
			>
				{this.props.isFavorite ? <FullStar size={16} /> : <StarBorder size={16} />}
			</IconButton>
		)
	}
}

export default withTranslate(ContactSelectStar)
