// @flow

import { connect } from 'react-redux'
import type { Dispatch, AccountingDocumentReduxAction } from 'types'
import { printAccountingDocument } from 'modules/accounting-document/actions'
import PreviewActions from 'modules/accounting-document/components/invoice-elements/preview-actions'

type DispatchProps = {|
	onDownload: (id: string) => void,
|}

function mapDispatchToProps(dispatch: Dispatch<AccountingDocumentReduxAction>): DispatchProps {
	return {
		onDownload: (accountingDocumentId: string) => {
			dispatch(printAccountingDocument(accountingDocumentId))
		},
	}
}

export default connect(undefined, mapDispatchToProps)(PreviewActions)
