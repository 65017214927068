import React from 'react'
import SvgIcon from 'components/SvgIcon'
import pure from 'recompose/pure'

let Add = props => (
	<SvgIcon {...props}>
		<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<circle stroke="#121D27" strokeWidth="2" cx="12" cy="12" r="11" />
			<polygon
				fill="#121D27"
				points="16 12.5953878 16 11.2704403 12.7892377 11.2704403 12.7892377 8 11.2286996 8 11.2286996 11.2704403 8 11.2704403 8 12.5953878 11.2286996 12.5953878 11.2286996 16 12.7892377 16 12.7892377 12.5953878"
			/>
		</g>
	</SvgIcon>
)
Add = pure(Add)

export default Add
