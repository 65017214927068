/* @flow */

import { set } from 'lodash'
import type { AccountingDocument, Dispatch, State, OssMode, FormFieldErrorContext } from 'types'
import { emptyAccountingDocument } from 'types/empty'
import {
	loadAccountingDocumentVatRecapInfo,
	updateAccountingDocumentSadExchRate,
	updateAccountingDocumentVatCountryType,
	updateAccountingDocumentVatExchRate,
	updateAccountingDocumentCreditNoteDelayedVat,
	updateAccountingDocumentOssMode,
	fetchAccountingDocumentVatRates,
	loadAccountingDocumentLineItems,
	resetAccountingDocumentOssMode,
	clearLineItemsVatRates,
} from 'modules/accounting-document/actions'
import {
	getAccountingDocumentOssMode,
	getAccountingDocumentOssValue,
	getAccountingDocumentCurrency,
	getAccountingDocumentExchRate,
	getAccountingDocumentExchRateDefault,
	getAccountingDocumentSadExchRate,
	getAccountingDocumentVatCountryType,
	getAccountingDocumentVatExchRate,
	getAccountingDocument,
	getAccountingDocumentCreditNoteDelayedVatFlag,
	isAccountingDocumentOssEnabled,
} from 'modules/accounting-document/selectors'
import { getCurrentOrganizationDomesticCurrency } from 'modules/organization/selectors'
import BookingSettings from '../../components/invoice-settings/booking-settings'
import { connect } from 'react-redux'
import { AccountingDocumentTypeNumber_isCreditNote } from 'types/operations'
import { clearVatRatesInLineItems } from 'modules/accounting-document/helpers'
import { getOssRegistrationsByCountry } from '../../../settings/selectors'

type OwnProps = {|
	isTemplate: boolean,
	readonly?: boolean,
	accountingDocumentId: string,
	template: ?AccountingDocument,
	onTemplateChange?: (accountingDocument: AccountingDocument) => void,
	onBackwardClick: () => void,
	formFieldErrorContext?: FormFieldErrorContext,
|}

type StateProps = {|
	currencyId: ?string,
	vatCountryType: ?number,
	exchRate: ?number,
	exchRateDefault: ?number,
	vatExchRate: ?number,
	sadExchRate: ?number,
	creditNoteDelayedVat: boolean,
	isCreditNote?: boolean,
	domesticCurrencyId: ?string,
	isOssEnabled?: boolean,
	ossMode?: ?OssMode,
	ossValue?: ?OssMode,
	formFieldErrorContext?: FormFieldErrorContext,
|}

function mapStateToProps(state: State, ownProps: OwnProps): StateProps {
	const accountingDocument: ?AccountingDocument = ownProps.isTemplate
		? ownProps.template
		: getAccountingDocument(state, ownProps.accountingDocumentId)
	const isCreditNote = AccountingDocumentTypeNumber_isCreditNote(accountingDocument ? accountingDocument.type : null)
	const template: AccountingDocument = ownProps.template || emptyAccountingDocument()

	return ownProps.isTemplate
		? {
				vatCountryType: template.vatCountryType,
				exchRateDefault: template.exchRateDefault || null,
				exchRate: template.exchRate || null,
				vatExchRate: template.vatExchRate || null,
				sadExchRate: template.sadExchRate || null,
				creditNoteDelayedVat: template.creditNoteDelayedVat || false,
				domesticCurrencyId: getCurrentOrganizationDomesticCurrency(state),
				currencyId: template.currency || null,
				isOssEnabled: !!(
					template.contact &&
					template.contact.country &&
					getOssRegistrationsByCountry(state, template.contact.country)
				),
				formFieldErrorContext: ownProps.formFieldErrorContext,
		  }
		: {
				vatCountryType: getAccountingDocumentVatCountryType(state, ownProps.accountingDocumentId),
				exchRate: getAccountingDocumentExchRate(state, ownProps.accountingDocumentId),
				exchRateDefault: getAccountingDocumentExchRateDefault(state, ownProps.accountingDocumentId),
				vatExchRate: getAccountingDocumentVatExchRate(state, ownProps.accountingDocumentId),
				sadExchRate: getAccountingDocumentSadExchRate(state, ownProps.accountingDocumentId),
				isCreditNote,
				creditNoteDelayedVat: getAccountingDocumentCreditNoteDelayedVatFlag(state, ownProps.accountingDocumentId),
				domesticCurrencyId: getCurrentOrganizationDomesticCurrency(state),
				currencyId: getAccountingDocumentCurrency(state, ownProps.accountingDocumentId),
				isOssEnabled: isAccountingDocumentOssEnabled(state, ownProps.accountingDocumentId),
				ossMode: getAccountingDocumentOssMode(state, ownProps.accountingDocumentId),
				ossValue: getAccountingDocumentOssValue(state, ownProps.accountingDocumentId),
				formFieldErrorContext: ownProps.formFieldErrorContext,
		  }
}

function mapDispatchToProps(dispatch: Dispatch<*>, ownProps: OwnProps) {
	return ownProps.isTemplate
		? {
				onVatCountryTypeChange: (vatCountryType: number) => {
					const accDocWithUpdatedLines = ownProps.template && clearVatRatesInLineItems(ownProps.template)
					const newDocument: AccountingDocument = Object.assign(emptyAccountingDocument(), accDocWithUpdatedLines, {
						vatCountryType: vatCountryType || undefined,
					})
					ownProps.onTemplateChange && ownProps.onTemplateChange(newDocument)
				},

				onVatExchRateChange: (vatExchRate: ?number) => {
					const newDocument: AccountingDocument = Object.assign(emptyAccountingDocument(), ownProps.template, {
						vatExchRate: vatExchRate || undefined,
					})
					ownProps.onTemplateChange && ownProps.onTemplateChange(newDocument)
				},
				onSadExchRateChange: (sadExchRate: ?number) => {
					const newDocument: AccountingDocument = Object.assign(emptyAccountingDocument(), ownProps.template, {
						sadExchRate: sadExchRate || undefined,
					})
					ownProps.onTemplateChange && ownProps.onTemplateChange(newDocument)
				},

				enableCreditNoteDelayedVatFlag: (confirmed: boolean) => {
					const newDocument: AccountingDocument = Object.assign(emptyAccountingDocument(), ownProps.template, {
						creditNoteDelayedVat: confirmed,
					})
					ownProps.onTemplateChange && ownProps.onTemplateChange(newDocument)
					return Promise.resolve()
				},
				onOssModeChange: (mode: ?OssMode) => {
					const newDocument: AccountingDocument = { ...emptyAccountingDocument(), ...ownProps.template }
					set(newDocument, 'settings.oss.forcedValue', mode)
					ownProps.onTemplateChange && ownProps.onTemplateChange(newDocument)
				},
		  }
		: {
				onVatCountryTypeChange: async (vatCountryType: number) => {
					await dispatch(updateAccountingDocumentVatCountryType(ownProps.accountingDocumentId, vatCountryType))
					await dispatch(resetAccountingDocumentOssMode(ownProps.accountingDocumentId))
					await dispatch(clearLineItemsVatRates(ownProps.accountingDocumentId))
					dispatch(loadAccountingDocumentVatRecapInfo(ownProps.accountingDocumentId))
					dispatch(fetchAccountingDocumentVatRates(ownProps.accountingDocumentId))
				},
				onVatExchRateChange: (vatExchRate: ?number) => {
					dispatch(updateAccountingDocumentVatExchRate(ownProps.accountingDocumentId, vatExchRate)).then(() => {
						dispatch(loadAccountingDocumentVatRecapInfo(ownProps.accountingDocumentId))
					})
				},
				onSadExchRateChange: (sadExchRate: ?number) => {
					dispatch(updateAccountingDocumentSadExchRate(ownProps.accountingDocumentId, sadExchRate)).then(() => {
						dispatch(loadAccountingDocumentVatRecapInfo(ownProps.accountingDocumentId))
					})
				},
				onOssModeChange: (mode: ?OssMode) => {
					dispatch(updateAccountingDocumentOssMode(ownProps.accountingDocumentId, mode)).then(() => {
						dispatch(loadAccountingDocumentVatRecapInfo(ownProps.accountingDocumentId))
						dispatch(fetchAccountingDocumentVatRates(ownProps.accountingDocumentId))
						dispatch(loadAccountingDocumentLineItems(ownProps.accountingDocumentId))
					})
				},
				enableCreditNoteDelayedVatFlag: (confirmed: boolean) => {
					return dispatch(updateAccountingDocumentCreditNoteDelayedVat(ownProps.accountingDocumentId, confirmed))
				},
		  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BookingSettings)
