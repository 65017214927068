/* @flow */
/** @jsx jsx */

import { jsx } from '@emotion/core'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import CopyToClipboard from 'components/copy-to-clipboard'
import { colors } from 'variables'

const FORMAT_PLACEHOLDERS: Array<string> = ['(yyyy)', '(yy)', '(mm)', '(c+)']

type Props = {
	...WithTranslateProps,
}

function SequenceFormatHelper(props: Props) {
	return (
		<div css={styles.helper}>
			<div css={styles.helperHeadline}>{props.t('settings.sequences.placeholderInfo')}</div>
			<div css={styles.helperBox}>
				{FORMAT_PLACEHOLDERS.map((placeholder: string, index: number) => (
					<div key={'placeholder-' + index} css={styles.placeholder}>
						<div css={styles.placeholderCode}>
							<div css={styles.helperBoxPlaceholder}>
								<CopyToClipboard hoverStyle={styles.hover} textToCopy={placeholder}>
									{placeholder}
								</CopyToClipboard>
							</div>
						</div>
						<div css={styles.placeholderInfo}>{props.t(`settings.sequences.placeholders.${placeholder}`)}</div>
					</div>
				))}
			</div>
		</div>
	)
}

const styles = {
	hover: {
		color: colors.blue,
		textDecoration: 'underline',
	},
	helper: {
		margin: '20px 0',
	},
	helperHeadline: {
		fontSize: 14,
		lineHheight: '17px',
		marginBottom: 10,
	},
	helperBox: {
		borderRadius: 2,
		backgroundColor: colors.blue100,
		padding: '15px 25px',
	},
	helperBoxPlaceholder: {
		display: 'inline-flex',
	},
	placeholder: {
		lineHeight: '17px',
		padding: '6px 0',
		fontSize: 14,
		display: 'flex',
	},
	placeholderCode: {
		fontSize: 14,
		fontFamily: '"Courier New", serif',
		flex: '0 0 50%',
		paddingRight: 15,
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
	},
	placeholderInfo: {
		flex: '0 0 50%',
		paddingLeft: 15,
	},
}

export default withTranslate(SequenceFormatHelper)
