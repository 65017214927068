/* @flow */

import { connect } from 'react-redux'
import type { Dispatch, AccountingDocumentExtractingState, AccountingDocument } from 'types'
import { setAccountingDocumentExtractingState } from 'modules/accounting-document/actions'
import ApproveDialog from 'modules/accounting-document/components/accounting-document-list/approve-dialog'

type OwnProps = {|
	open: boolean,
	accountingDocument: ?AccountingDocument,
	showConfirmation?: boolean,
	onClose: () => void,
|}

type DispatchProps = {|
	onStateChange: (extractingState: AccountingDocumentExtractingState) => Promise<*>,
|}

const mapDispatchToProps = (dispatch: Dispatch<*>, ownProps: OwnProps): DispatchProps => {
	return {
		onStateChange: async (extractingState: AccountingDocumentExtractingState) => {
			const id = ownProps.accountingDocument ? ownProps.accountingDocument.id || '' : ''
			dispatch(setAccountingDocumentExtractingState(id, extractingState))
		},
	}
}

export default connect(undefined, mapDispatchToProps)(ApproveDialog)
