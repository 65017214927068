//@flow
/** @jsx jsx */

import { jsx } from '@emotion/core'
import { Component } from 'react'
import { getValues } from 'helpers'
import Dropzone from 'components/dropzone'
import LinearProgress from 'components/LinearProgress'
import { colors } from 'variables'

type ProgressType = {
	progress: ?number,
	name: ?string,
}

type Props = {|
	uploadSignatures: (Array<File>) => Promise<*>,
	uploadProgress: {|
		[key: string]: ProgressType,
	|},
	onDismissUploadProgress: (key: string) => void,
|}

class SignaturesUpload extends Component<Props> {
	renderProgress = (p: ProgressType) => {
		const key = escape(p.name || '')
		const progress = p.progress || 0

		if (progress >= 100) {
			this.props.onDismissUploadProgress && this.props.onDismissUploadProgress(key)
			return null
		}

		return (
			<div css={style.progress} key={key}>
				{p.name}
				<LinearProgress mode="determinate" min={0} max={100} value={progress} />
			</div>
		)
	}

	render() {
		const { uploadProgress } = this.props

		let progress = null

		if (uploadProgress) {
			const values: Array<ProgressType> = getValues(uploadProgress)
			progress = values.map(this.renderProgress)
		}

		return (
			<div>
				{progress && <div css={style.container}>{progress}</div>}
				<Dropzone
					multiple
					accept={'image/jpeg,image/jpg,image/png,image/gif,image/tiff'}
					onDrop={this.props.uploadSignatures}
					minHeight={100}
				/>
			</div>
		)
	}
}

const style = {
	container: { marginBottom: 15 },
	progress: {
		fontSize: 13,
		marginTop: 8,
		color: colors.grey900,
	},
}

export default SignaturesUpload
