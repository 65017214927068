// @flow
import { connect } from 'react-redux'
import type { State } from 'types'
import { getCurrentOrganizationId } from 'modules/user/selectors/index'
import CreditNoteDialog from '../../components/invoice-elements/credit-note-dialog'

type StateProps = {|
	organizationId: ?string,
|}

function mapStateToProps(state: State): StateProps {
	return {
		organizationId: getCurrentOrganizationId(state),
	}
}

export default connect(mapStateToProps)(CreditNoteDialog)
