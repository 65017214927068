/* @flow */
/** @jsx jsx */

// $FlowFixMe - there is useEffect
import React, { useState, useEffect, useCallback } from 'react'
import { jsx, css } from '@emotion/core'
import { autoTestId, type AutoTestProps } from 'utils/tests/autotest'
import RenderToLayer from 'components/internal/RenderToLayer'
import Overlay from './overlay'
import styles from './dialog.styles'

type Props = {|
	...AutoTestProps,
	maxContentWidth?: number,
	actions?: React$Element<any> | Array<React$Element<any>>,
	children?: any,
	opaque?: boolean,
	open: boolean,
	title?: string,
	onClosed?: () => void,
	isScrollable?: boolean,
|}

export default function Dialog(props: Props) {
	const [render, setRender] = useState(props.open)

	const handleKeyPress = useCallback((event: KeyboardEvent) => {
		const element = document.getElementById('scrollable-dialog')

		// $FlowFixMe - there is scrollBy in HTMLElement
		if ('ArrowUp' === event.code) element && element.scrollBy && element.scrollBy(0, -16)
		// $FlowFixMe - there is scrollBy in HTMLElement
		if ('ArrowDown' === event.code) element && element.scrollBy && element.scrollBy(0, 16)
	}, [])

	useEffect(() => {
		void (props.open && setRender(true))
		document.addEventListener('keydown', handleKeyPress)
		return () => {
			document.removeEventListener('keydown', handleKeyPress)
		}
	}, [props.open, handleKeyPress])
	if (!render) return null

	function onAnimationEnd() {
		if (props.open) return
		setRender(false)
		props.onClosed && props.onClosed()
	}

	function renderLayer() {
		return (
			<div
				css={css`
					${styles.root} ${props.isScrollable ? styles.rootScrollable : ''}
				`}
				{...autoTestId(props.autoTestId)}
				id={props.isScrollable && 'scrollable-dialog'}
			>
				<Overlay open={props.open} opaque={props.opaque} />
				<div css={styles.scroller}>
					<div
						css={css`
							${styles.content} ${props.open ? styles.openContent : ''}
						`}
						style={{ maxWidth: props.maxContentWidth || 640 }}
						onAnimationEnd={onAnimationEnd}
					>
						<div>
							{props.title && <h3 style={styles.title}>{props.title}</h3>}
							<div css={styles.body}>{props.children}</div>
							{!!React.Children.count(props.actions) && (
								<div css={styles.actionsContainer}>{React.Children.toArray(props.actions)}</div>
							)}
						</div>
					</div>
				</div>
			</div>
		)
	}

	return <RenderToLayer render={renderLayer} open useLayerForClickAway={false} />
}
