//@flow
import React, { Component } from 'react'
import { type AutoTestProps } from 'utils/tests/autotest'
import Paper from 'components/Paper'
import styles from './page.css'

type Props = {|
	...AutoTestProps,
	page: number,
	activePage: number,
	compact?: boolean,
	onClick: (page: number) => void,
|}

class Page extends Component<Props> {
	onClick = () => {
		this.props.onClick && this.props.onClick(this.props.page)
	}

	render() {
		return (
			<Paper
				autoTestId={this.props.autoTestId}
				className={
					this.props.activePage == this.props.page
						? this.props.compact
							? styles.compactActive
							: styles.active
						: this.props.compact
						? styles.compactNormal
						: styles.normal
				}
				onClick={this.onClick}
				zDepth={1}
				circle
			>
				{this.props.page}
			</Paper>
		)
	}
}

export default Page
