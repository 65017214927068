/* @flow */

import type { State } from 'types'
import store from 'store'

function getOrganizationId() {
	const state: State = store.getState()
	return state.user.currentOrganizationId || 'error'
}

export function cashRegistersRoute() {
	return `/${getOrganizationId()}/cash-register`
}

export function cashRegisterCreateRoute() {
	return `/${getOrganizationId()}/cash-register/create`
}

export function cashRegisterRoute(id: string, organizationId?: string) {
	return `/${organizationId ? organizationId : getOrganizationId()}/cash-register/${id}`
}

export function cashRegisterRouteFiltered(id: string, direction: string) {
	return `/${getOrganizationId()}/cash-register/${id}/${direction}`
}

export function cashRegisterEditRoute(id: string) {
	return `/${getOrganizationId()}/cash-register/${id}/edit`
}

export function cashRegisterMakeInventoryRoute(id: string) {
	return `/${getOrganizationId()}/cash-register/${id}/make-inventory`
}
