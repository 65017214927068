/* @flow */

import React from 'react'
import { Route, Switch } from 'react-router'
import AccountingDocumentRouting from 'modules/accounting-document/routing'
import AddressBookRouting from 'modules/address-book/routing'
import BankRouting from 'modules/bank/routing'
import CashRegisterRouting from 'modules/cash-register/routing'
import DashboardRouting, { EmptyDashboardRouting } from 'modules/dashboard/routing'
import DocumentRouting from 'modules/document/routing'
import DocumentExtractionRouting from 'modules/document-extraction/routing'
import FileRouting from 'modules/file/routing'
import InternalRouting from 'modules/internal/routing'
import NotFound from 'modules/common/containers/not-found'
import OrganizationRouting from 'modules/organization/routing'
import SettingsRouting from 'modules/settings/routing'
import FeaturesRouting from 'modules/features/routing'
import TaskRouting from 'modules/task/routing'
import { UserIsAuthenticated, UserIsNotLoggedAsDemo } from 'lib/auth'
import UserRouting from 'modules/user/routing'
import TriviStoreRouting from 'modules/trivi-store/routing'
import { withRouter } from 'react-router-dom'
import { withNavigationPermissions, type WithNavigationPermissionsProps } from 'wrappers'
import DemoPage from 'modules/common/containers/demo-page'
import BasicLayout from 'layouts/basic-layout'
import type { WithRouterProps } from 'types'

const DemoPageContainer = BasicLayout()(DemoPage)

type Props = {|
	...WithNavigationPermissionsProps,
	...WithRouterProps,
|}

const Routes = (props: Props) => {
	const {
		canSeeAccountingDocuments,
		canSeeAccountingDocumentsDetail,
		canSeeAccountingDocumentsEdit,
		canSeeContacts,
		canSeeInternalDashboard,
		canExtractAccountingDocuments,
		canSeeDashboard,
		canSeeEmptyDashboard,
		canSeeCashRegisters,
		canSeeSettings,
		canSeeDocuments,
		canSeeFeatures,
		canSeeNonDemoFeatures,
		canSeeBankAccounts,
		canSeeTasks,
		canSeeFiles,
		canSeeTriviStore,
		canSeeRegistration,
	} = props

	return (
		<Switch>
			<Route exact path="/" component={UserIsAuthenticated(() => null)} />
			{UserRouting(canSeeNonDemoFeatures, canSeeRegistration)}
			{OrganizationRouting()}
			{canSeeDashboard && DashboardRouting()}
			{canSeeEmptyDashboard && EmptyDashboardRouting()}
			{canSeeTasks && TaskRouting()}
			{canSeeInternalDashboard && InternalRouting()}
			{(canSeeAccountingDocuments ||
				canSeeAccountingDocumentsDetail ||
				canSeeAccountingDocumentsEdit ||
				canExtractAccountingDocuments) &&
				AccountingDocumentRouting(
					canExtractAccountingDocuments,
					canSeeAccountingDocumentsDetail,
					canSeeAccountingDocumentsEdit,
					canSeeAccountingDocuments,
				)}
			{canExtractAccountingDocuments && DocumentExtractionRouting()}
			{canSeeContacts && AddressBookRouting()}
			{canSeeCashRegisters && CashRegisterRouting()}
			{canSeeBankAccounts && BankRouting()}
			{canSeeDocuments && DocumentRouting()}
			{canSeeFiles && FileRouting()}
			{canSeeSettings && SettingsRouting()}
			{canSeeFeatures && FeaturesRouting()}
			{canSeeTriviStore && TriviStoreRouting()}
			<Route path="/demo" component={UserIsNotLoggedAsDemo(DemoPageContainer)} />
			<Route component={UserIsAuthenticated(NotFound)} />
		</Switch>
	)
}

export default withNavigationPermissions(withRouter(Routes))
