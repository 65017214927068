//@flow
/* eslint-disable react/jsx-no-bind */

import React, { Component } from 'react'
import { Checkbox, Button } from 'components'
import styles from './features-list.css'
import type { Feature, FeatureId } from 'types'
import DeleteIcon from 'components/svg-icons/action/delete'
import { colors } from 'variables'
import { isEqual } from 'lodash-es'

type Props = {|
	availableFeatures: Array<Feature>,
	serverEnabledFeatures: Array<Feature>,
	userEnabledFeatures: Array<Feature>,
	userDisabledFeatures: Array<Feature>,
	onEnableFeature: (feature: Feature) => void,
	onDisableFeature: (feature: Feature) => void,
	onRemoveFeature: (feature: Feature) => void,
	onReloadPress: () => void,
|}

type State = {|
	defaultEnabled: Array<FeatureId>,
	currentEnabled: Array<FeatureId>,
|}

export default class FeaturesList extends Component<Props, State> {
	state: State = {
		defaultEnabled: this.getEnabledIds(this.props),
		currentEnabled: this.getEnabledIds(this.props),
	}

	getEnabledIds(props: Props): Array<FeatureId> {
		return props.serverEnabledFeatures.concat(props.userEnabledFeatures).map((f: Feature) => {
			return f.id
		})
	}

	isntChange = () => {
		return isEqual(this.state.defaultEnabled.sort(), this.state.currentEnabled.sort())
	}

	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		this.setState({
			currentEnabled: this.getEnabledIds(nextProps),
		})
	}

	renderFeature = (feature: Feature, index: number) => {
		const onCheck = (event: SyntheticInputEvent<HTMLInputElement>, checked: boolean) => {
			checked ? this.props.onEnableFeature(feature) : this.props.onDisableFeature(feature)
		}
		const isServerEnabled = this.props.serverEnabledFeatures.findIndex((f: Feature) => f.id === feature.id) > -1
		const isUserEnabled = this.props.userEnabledFeatures.findIndex((f: Feature) => f.id === feature.id) > -1
		const isUserDisabled = this.props.userDisabledFeatures.findIndex((f: Feature) => f.id === feature.id) > -1
		const notSetByUser = !isUserEnabled && !isUserDisabled
		const isChecked = notSetByUser ? isServerEnabled : isUserEnabled
		const extraStyle = notSetByUser ? { opacity: 0.5 } : null

		const clearButton = notSetByUser ? null : (
			<DeleteIcon
				color={colors.grey500}
				style={{ cursor: 'pointer' }}
				onClick={() => {
					this.props.onRemoveFeature(feature)
				}}
			/>
		)

		return (
			<div key={index} style={extraStyle} className={styles.row}>
				<div style={{ width: '50px' }}>{clearButton}</div>
				<div className={styles.rowTexts}>
					<Checkbox label={feature.name} checked={isChecked} onCheck={onCheck} />
					<div className={styles.description}>{feature.description}</div>
				</div>
			</div>
		)
	}

	render() {
		return (
			<div>
				{this.props.availableFeatures.map(this.renderFeature)}
				<div className={styles.buttonWrapper}>
					<Button labelText="Reload" tertiary disabled={this.isntChange()} onClick={this.props.onReloadPress} />
				</div>
			</div>
		)
	}
}
