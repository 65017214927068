/* @flow */

import type { DashboardAction, Dispatch, GenericReport, GenericReportRequest, GenericReportsSearchResult } from 'types'
import type { ReportsGetParams, ReportsPostResponse } from 'modules/common/models/api-model'
import { report as reportApi, reports as reportsApi } from 'modules/common/models/api-model'

export function loadReports(request: ReportsGetParams) {
	return async (dispatch: Dispatch<DashboardAction>) => {
		dispatch({
			type: 'START_LOAD_REPORTS',
		})
		try {
			const result: GenericReportsSearchResult = await reportsApi.get(request)

			return dispatch({
				type: 'FINISH_LOAD_REPORTS',
				reports: result,
			})
		} catch (error) {
			dispatch({
				type: 'FAIL_LOAD_REPORTS',
				serverError: error,
			})
		}
	}
}

export function loadReport(reportId: string) {
	return async (dispatch: Dispatch<DashboardAction>) => {
		dispatch({
			type: 'START_LOAD_REPORT',
			reportId,
		})
		try {
			const result: GenericReport = await reportApi.get({ reportId })
			dispatch({
				type: 'FINISH_LOAD_REPORT',
				report: result,
			})
		} catch (error) {
			dispatch({
				type: 'FAIL_LOAD_REPORT',
				serverError: error,
			})
		}
	}
}

export function requestNewGenericReport(request: GenericReportRequest) {
	return async (dispatch: Dispatch<DashboardAction>) => {
		dispatch({
			type: 'START_REQUEST_NEW_GENERIC_REPORT',
			reportRequest: request,
		})
		try {
			const result: ReportsPostResponse = await reportsApi.post(request)
			return dispatch({
				type: 'FINISH_REQUEST_NEW_GENERIC_REPORT',
				apiResponse: result,
			})
		} catch (error) {
			return dispatch({
				type: 'FAIL_REQUEST_NEW_GENERIC_REPORT',
				serverError: error,
			})
		}
	}
}

export function deleteReport(reportId: string) {
	return async (dispatch: Dispatch<DashboardAction>) => {
		dispatch({
			type: 'START_DELETE_REPORT',
			reportId,
		})
		try {
			await reportApi.delete({
				reportId,
			})
			dispatch({
				type: 'FINISH_DELETE_REPORT',
				reportId,
				success: true,
			})
		} catch (error) {
			dispatch({
				type: 'FINISH_DELETE_REPORT',
				reportId,
				success: false,
				serverError: error,
			})
		}
	}
}
