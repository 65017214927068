/* @flow */

import React, { Component } from 'react'
import type { AccountingDocumentDirection, AccountingDocumentView } from 'types'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import { STANDARD_LIST_VIEWS } from 'modules/accounting-document/constants'
import TabSwitches from 'components/TabSwitches/TabSwitches'
import TabSwitch from 'components/TabSwitches/TabSwitch'

export type Props = {|
	...WithTranslateProps,
	showTabs: boolean,
	view: AccountingDocumentView,
	direction: ?AccountingDocumentDirection,
	onChangeDirection: (direction: AccountingDocumentDirection, redirect?: boolean) => void,
	resetView: () => Promise<void>,
|}

class DetailDirectionViewSwitcher extends Component<Props> {
	changeDirection = (direction: ?AccountingDocumentDirection) => {
		if (!direction || direction !== this.props.direction) return
		!STANDARD_LIST_VIEWS.includes(this.props.view) && this.props.resetView()
		this.props.onChangeDirection(direction)
	}

	render() {
		if (!this.props.showTabs) return null
		const { t } = this.props

		return (
			<TabSwitches
				autoTestId="direction-view-compact-tabs"
				onChange={this.props.onChangeDirection}
				value={this.props.direction}
				fullWidth
				compact
			>
				<TabSwitch key="received" value="received" label={t('accountingDocument.purchase')} />
				<TabSwitch key="issued" value="issued" label={t('accountingDocument.sale')} />
			</TabSwitches>
		)
	}
}

export default withTranslate(DetailDirectionViewSwitcher)
