/* @flow */

import React, { Component } from 'react'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import type { EnumItem } from 'types'
import { PERMISSION_ENUM } from '../constants/permissions'
import TreeEnumSelector from 'components/tree-enum-selector'
import MenuItem from 'components/menu-item'

type Props = {|
	...WithTranslateProps,
	fullWidth?: boolean,
	labelText?: string,
	value?: Array<string>,
	onChange: (items: string | Array<string>) => void,
	disableItemFn?: (key: string) => boolean,
|}

class PermissionPicker extends Component<Props> {
	renderItem = (item: EnumItem) => {
		const key: ?string = item.key ? item.key.toString() : null
		return key === 'FIXED' ? (
			<MenuItem key={item.key} primaryText={item.value} checked disabled />
		) : (
			<MenuItem
				key={(item.value || '') + (item.key || '')}
				value={key || ''}
				primaryText={item.value}
				checked={PERMISSION_ENUM.indexOf(item.value) > -1}
				disabled={this.props.disableItemFn && key ? this.props.disableItemFn(key) : false}
			/>
		)
	}

	render() {
		return (
			<TreeEnumSelector
				fullWidth={this.props.fullWidth}
				labelText={this.props.labelText}
				value={this.props.value}
				onChange={this.props.onChange}
				disableItemFn={this.props.disableItemFn}
				autoTestId="permissions-picker"
				renderItem={this.renderItem}
				multiple
				showCategoryLabels
				values={PERMISSION_ENUM}
				hintText={this.props.t('internalUsers.permissionsHintText')}
			/>
		)
	}
}

export default withTranslate(PermissionPicker)
