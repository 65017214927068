/* @flow */
/** @jsx jsx */

import moment from 'moment'
import { jsx } from '@emotion/core'
import type { Sequence } from 'types'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import { formatToDateString } from 'utils/formatters/date-formatter'
import type { LastGeneratedNumberType } from './sequence-list'
import IconMenu from 'components/icon-menu'
import MenuItem from 'components/menu-item'
import FullText from 'components/full-text'
import TableRow from 'components/table/table-row'
import TableCell from 'components/table/table-cell'
import Delete from 'components/svg-icons/action/delete'
import Edit from 'components/svg-icons/image/edit'
import { colors } from 'variables'

type Props = {
	sequence: Sequence,
	readOnly?: boolean,
	onDeleteButtonClick: () => void,
	onEditButtonClick: () => void,
	...WithTranslateProps,
}

function SequenceItem(props: Props) {
	const { sequence } = props
	const invalidated =
		sequence.validTo &&
		moment(sequence.validTo)
			.startOf('day')
			.isBefore(moment().startOf('day'))

	const tooltip = invalidated
		? props.t('settings.sequences.invalidated', { date: sequence.validTo && formatToDateString(sequence.validTo) })
		: undefined

	return (
		<TableRow tooltip={tooltip}>
			<TableCell>
				<div css={invalidated && styles.invalidated}>
					<div css={styles.primary}>
						<FullText text={sequence.name} />
					</div>
					<div css={styles.secondary} />
				</div>
			</TableCell>
			<TableCell>
				<div css={invalidated && styles.invalidated}>
					<div css={styles.primary}>{sequence.format}</div>
					<div css={styles.secondary}>{sequence.exampleNo}</div>
				</div>
			</TableCell>
			<TableCell>
				<div css={invalidated && styles.invalidated}>
					<div css={styles.primary}>{formatToDateString(sequence.validFrom)}</div>
					<div css={styles.secondary} />
				</div>
			</TableCell>
			<TableCell>
				<div css={invalidated && styles.invalidated}>
					<div css={styles.primary}>{sequence.validTo && formatToDateString(sequence.validTo)}</div>
					<div css={styles.secondary} />
				</div>
			</TableCell>
			<TableCell>
				<div css={invalidated && styles.invalidated}>
					{sequence.lastGeneratedNumbers &&
						sequence.lastGeneratedNumbers.map((number: LastGeneratedNumberType, i: number) => (
							<div key={(sequence.id || '') + '|' + (number.lastGeneratedNumber || '') + '|' + i}>
								{number.year}: <span style={{ fontWeight: 'bold' }}>{number.lastGeneratedNumber}</span>
							</div>
						))}
				</div>
			</TableCell>
			<TableCell>
				{!props.readOnly ? (
					<IconMenu autoTestId="sequence-item-actions" context>
						<MenuItem
							primaryText={props.t('settings.forms.editButton')}
							onClick={props.onEditButtonClick}
							icon={<Edit />}
							tall
						/>
						<MenuItem
							primaryText={props.t('settings.forms.removeButton')}
							onClick={props.onDeleteButtonClick}
							icon={<Delete />}
							tall
						/>
					</IconMenu>
				) : null}
			</TableCell>
		</TableRow>
	)
}

const styles = {
	primary: {
		fontSize: 14,
		minHeight: 17,
		lineHeight: '17px',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
		color: colors.black,
	},
	secondary: {
		fontSize: 12,
		minHeight: 17,
		lineHeight: '17px',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
		color: colors.blackFaded60,
	},
	invalidated: { opacity: 0.6 },
}

export default withTranslate(SequenceItem)
