// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[3].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js!../../../variables/depths.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1Ik7uTCNKnnPlsiZj55e0c {\n\tposition: absolute;\n\tbottom: 0;\n\tleft: 0;\n\twidth: 100%;\n\theight: 0;\n\tz-index: " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["chat"] + ";\n}\n", "",{"version":3,"sources":["webpack://./modules/contact-me/components/contact-me.css"],"names":[],"mappings":"AAEA;CACC,kBAAA;CACA,SAAA;CACA,OAAA;CACA,WAAA;CACA,SAAA;CACA,qDAAA;AAKD","sourcesContent":["@value chat from \"variables/depths.css\";\n\n.contactMe {\n\tposition: absolute;\n\tbottom: 0;\n\tleft: 0;\n\twidth: 100%;\n\theight: 0;\n\tz-index: chat;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"chat": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["chat"] + "",
	"contactMe": "_1Ik7uTCNKnnPlsiZj55e0c"
};
export default ___CSS_LOADER_EXPORT___;
