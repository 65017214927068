// @flow

import React, { Component } from 'react'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import CommunicationEmail from 'components/svg-icons/communication/email'
import IconButton from 'components/icon-button'

type Props = {|
	...WithTranslateProps,
	onClick: () => void,
|}

class InboundEmailsButton extends Component<Props> {
	render() {
		const { t, onClick } = this.props
		return (
			<IconButton autoTestId="inbound-emails-open" tooltip={t('inboundEmail.openButton')} onClick={onClick} circled>
				<CommunicationEmail size={20} />
			</IconButton>
		)
	}
}

export default withTranslate(InboundEmailsButton)
