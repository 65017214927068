/* @flow */

import React, { Children, PureComponent, cloneElement } from 'react'
import type { ChildrenArray } from 'react'
import type { Filter, SearchFilter } from 'types'
import FilterDate from './filter-date'
import type { FilterElement } from '../types'
import FilterOrganization from './filter-organization'
import FilterRange from './filter-range'
import FilterSelectField from './filter-select-field'
import FilterTab from './filter-tab'
import FilterTabSwitch from './filter-tab-switch'
import FilterTabSwitches from './filter-tab-switches'
import FilterTabs from './filter-tabs'
import FilterTextField from './filter-text-field'
import FilterNumberInput from './filter-number-input'
import FilterVariableSymbol from './filter-variable-symbol'
import FilterUser from './filter-user'
import FilterState from './filter-state'
import styles from './data-grid-filter.css'
import { isSearchFilterEmpty } from 'modules/data-grid-next/domain/filter'

type Child = FilterElement | null

export type Props = {|
	name: string,
	filter: Filter,
	defaultFilter?: Filter,
	children: ChildrenArray<Child>,
	className?: string,
	clearFilterField: (ilterName: string, fieldName: string) => void,
	onChange?: (name: string, filter: Filter) => void,
	filterTransformFn?: {
		fromFilterItem: SearchFilter => Filter,
		toFilterItem: (Filter, string) => ?SearchFilter,
	},
|}

class DataGridFilter extends PureComponent<Props> {
	static defaultProps = {
		filter: [],
	}
	filter: Filter

	constructor(props: Props) {
		super(props)
		this.filter = props.filter
	}

	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		this.filter = nextProps.filter

		if (this.props.children !== nextProps.children) {
			Children.forEach(this.props.children, (item: Child) => {
				if (
					item &&
					Children.toArray(nextProps.children).findIndex(
						(nextItem: Child) => nextItem && item && item.props.name === nextItem.props.name,
					) === -1
				) {
					if (item) {
						this.applyFilterChange({
							field: item.props.name,
						})
					}
				}
			})
		}
	}

	applyFilterChange(newFilter: SearchFilter) {
		if (isSearchFilterEmpty(newFilter) && newFilter.field)
			return this.props.clearFilterField(this.props.name, newFilter.field)

		const filter: Filter = [...this.filter]
		const filterItemIndex: number = this.findFilterIndex(filter, newFilter.field)

		if (
			newFilter.value !== undefined ||
			newFilter.valueTo !== undefined ||
			newFilter.valueFrom !== undefined ||
			newFilter.valueContains !== undefined
		) {
			if (filterItemIndex !== -1) {
				filter[filterItemIndex] = newFilter
			} else {
				filter.push(newFilter)
			}
		} else if (filterItemIndex !== -1) {
			filter.splice(filterItemIndex, 1)
		}

		this.filter = filter
		this.props.onChange && this.props.onChange(this.props.name, filter)
	}

	handleFilterChange = (newFilter: SearchFilter) => {
		if (this.props.filterTransformFn) {
			this.props.filterTransformFn
				.fromFilterItem(newFilter)
				.forEach((item: SearchFilter) => this.applyFilterChange(item))
		} else {
			this.applyFilterChange(newFilter)
		}
	}

	render() {
		const filters: Array<FilterElement> = Children.map(this.props.children, (filter: Child) => {
			if (filter) {
				return cloneElement(filter, {
					filter: this.findFilter(this.props.filter, filter.props.name) || {
						field: filter.props.name,
					},
					onChange: this.handleFilterChange,
				})
			}
		})
		return <div className={`${styles.gridFilter} ${this.props.className || ''}`}>{filters}</div>
	}

	findFilter(filters: Filter, field: string): ?SearchFilter {
		if (this.props.filterTransformFn) {
			return this.props.filterTransformFn.toFilterItem(filters, field)
		} else {
			const index = this.findFilterIndex(filters, field)
			return index !== -1 ? filters[index] : null
		}
	}

	findFilterIndex(filters: Filter, field?: string): number {
		return filters.findIndex((element: SearchFilter) => element.field === field)
	}
}

export default DataGridFilter

export {
	FilterDate,
	FilterRange,
	FilterSelectField,
	FilterTabSwitches,
	FilterTabSwitch,
	FilterTabs,
	FilterTab,
	FilterTextField,
	FilterNumberInput,
	FilterOrganization,
	FilterVariableSymbol,
	FilterUser,
	FilterState,
}
