/* @flow */

import type {
	AresContact,
	Contact,
	ContactAddress,
	ContactBankAccount,
	ContactPerson,
	FormValidationProps,
	WithRouterProps,
} from 'types'
import { emptyContactAddress, emptyContact } from 'types/empty'
import { Button } from 'components'
import React, { Component } from 'react'
import ContactEdit from './contact-edit'
import styles from './address-book-edit.css'
import { withTranslate, type WithTranslateProps, validate } from 'wrappers'
import type { FinishSaveContactAction } from 'modules/address-book/actions/action-types'
import { withRouter } from 'react-router-dom'
import { getContactAddressByType, isContactAddressEmpty } from '../domain/contact-address'
import { Validator } from 'utils'
import { isEqual } from 'lodash-es'
import Tooltip from 'components/tooltip'

type Props = {|
	...WithTranslateProps,
	...FormValidationProps,
	...WithRouterProps,
	contact: ?Contact,
	loadContact: (id: string) => void,
	saveContact: (contact: Contact) => Promise<FinishSaveContactAction>,
	loadContactFromARES: (contact: Contact) => void,
	aresContacts: ?Array<AresContact>,
	loading: boolean,
	contactId: string,
	canEditContacts: boolean,
	saved: boolean,
	setSaved: (saved: boolean) => void,
|}

type State = {|
	billingIsSameAsShipping: boolean,
	disabled: boolean,
	contact: Contact,
	originalContact: Contact,
|}

class AddressBookEdit extends Component<Props, State> {
	state = {
		billingIsSameAsShipping: isBillingSameAsDelivery(this.props.contact),
		disabled: false,
		contact: this.props.contact || emptyContact(),
		originalContact: this.props.contact || emptyContact(),
	}

	UNSAFE_componentWillMount() {
		this.props.contactId && this.props.loadContact(this.props.contactId)
	}

	UNSAFE_componentWillReceiveProps = (nextProps: Props) => {
		if (nextProps.contact && nextProps.contact !== this.props.contact) {
			this.setState({
				contact: nextProps.contact,
				billingIsSameAsShipping: isBillingSameAsDelivery(nextProps.contact),
			})
		}
	}

	setBillingIsSameAsShipping = (billingIsSameAsShipping: boolean) => {
		this.setState({ billingIsSameAsShipping })

		let newContact = {
			...emptyContact(),
			...this.state.contact,
		}

		newContact.addresses = newContact.addresses || []

		if (!billingIsSameAsShipping && !(newContact.addresses.length > 1)) {
			newContact.addresses.push(emptyContactAddress(2))
			this.setContactState(newContact)
		}
	}

	save = async () => {
		this.setState({ disabled: true })

		let newContact = {
			...this.state.contact,
		}

		if (
			this.state.billingIsSameAsShipping ||
			(newContact.addresses.length > 1 && isContactAddressEmpty(newContact.addresses[1]))
		) {
			newContact.addresses = [newContact.addresses[0]]
		}

		const resp: FinishSaveContactAction = await this.props.saveContact(newContact)
		this.setState({ disabled: false }, () => {
			if (!resp.serverError) {
				this.props.history.goBack()
			}
		})
		this.props.setSaved(true)
	}

	getClientValidationErrors(contact: ?Contact) {
		const errors: Object = {}
		const { t } = this.props

		if (contact != null) {
			const billingAddress: ContactAddress = getContactAddressByType(contact.addresses, 1) || emptyContactAddress(1)
			const deliveryAddress: ContactAddress = getContactAddressByType(contact.addresses, 2) || emptyContactAddress(2)

			if (!contact.firstName && !contact.lastName && !contact.companyName) {
				errors.firstName = t('application.validation.mandatory')
				errors.lastName = t('application.validation.mandatory')
				errors.companyName = t('application.validation.mandatory')
			}

			contact.persons &&
				contact.persons.map((person: ContactPerson, index: number) => {
					if (person.email && !Validator.email(person.email)) {
						errors[`person${index}_email`] = t('clientError.notAnEmail')
					}
					if (person.phone && !Validator.phone(person.phone)) {
						errors[`person${index}_phone`] = t('clientError.notAPhone')
					}
				})

			contact.bankAccounts &&
				contact.bankAccounts.map((bankAccount: ContactBankAccount, index: number) => {
					if (!bankAccount.accountNo) {
						errors[`account_${index}_number`] = t('application.validation.mandatory')
					}
					if (bankAccount.accountNo && !Validator.accountNo(bankAccount.accountNo)) {
						errors[`account_${index}_number`] = t('clientError.notAnAccountNumber')
					}
					if (!bankAccount.accountCode) {
						errors[`account_${index}_code`] = t('application.validation.mandatory')
					}
					if (bankAccount.accountIban && !Validator.iban(bankAccount.accountIban)) {
						errors[`account_${index}_iban`] = t('clientError.notAnIban')
					}
					if (bankAccount.accountSwift && !Validator.swift(bankAccount.accountSwift)) {
						errors[`account_${index}_swift`] = t('clientError.notASwift')
					}
				})

			if (!billingAddress.country) {
				errors.country = t('application.validation.mandatory')
				errors.address1_country = t('application.validation.mandatory')
			}

			if (contact.email && !Validator.email(contact.email)) {
				errors.email = t('clientError.notAnEmail')
			}

			if (billingAddress.country == 'CZ' && contact.companyName && !contact.companyRegNo) {
				errors.companyRegNo = t('clientError.fillRegNo')
			}

			if (!this.state.billingIsSameAsShipping && !deliveryAddress.country) {
				errors.country = t('application.validation.mandatory')
				errors.address2_country = t('application.validation.mandatory')
			}

			if (billingAddress.email && !Validator.email(billingAddress.email)) {
				errors.address1_email = t('clientError.notAnEmail')
			}

			if (deliveryAddress.email && !Validator.email(deliveryAddress.email)) {
				errors.address2_email = t('clientError.notAnEmail')
			}

			if (billingAddress.phone && !Validator.phone(billingAddress.phone)) {
				errors.address1_phone = t('clientError.notAPhone')
			}

			if (deliveryAddress.phone && !Validator.phone(deliveryAddress.phone)) {
				errors.address2_phone = t('clientError.notAPhone')
			}
		}

		return errors
	}

	setContactState = (contact: Contact) => {
		this.setState({ contact })
		this.props.setSaved(isEqual(this.state.contact, this.state.originalContact))
	}

	setSaved = () => {
		this.props.setSaved(true)
	}

	getSaveButtonWithTooltip = (hasErrors: boolean): React$Element<any> => {
		const { t } = this.props

		const saveButton = (
			<Button
				disabled={this.state.disabled || !this.props.canEditContacts || this.props.saved || hasErrors}
				labelText={t(`contactEdit.edit.${this.props.saved && !hasErrors ? 'saved' : 'save'}`)}
				onClick={this.props.validationSubmit(this.save, undefined, this.setSaved)}
				autoTestId="address-book-edit-save"
			/>
		)

		if (hasErrors && !this.props.saved) {
			return <Tooltip label={t('templates.cantSaveErrors')}>{saveButton}</Tooltip>
		}
		if (this.props.saved) {
			return <Tooltip label={t('templates.cantSaveNoChanges')}>{saveButton}</Tooltip>
		}
		return saveButton
	}

	render() {
		const { t } = this.props
		const { contact } = this.state
		const isNew = !this.props.contactId

		this.props.validateForm(this.getClientValidationErrors(contact))

		const errors = this.getClientValidationErrors(contact)
		const hasErrors = Object.keys(errors).length > 0

		return (
			<div className={styles.container} id={'contact-edit-container'}>
				<div className={styles.header}>
					<div className={styles.headings} id={'contact-edit-header'}>
						<h1 className={styles.h1}>{isNew ? t('contactEdit.new.title') : t('contactEdit.edit.title')}</h1>
					</div>
					<div className={styles.actions}>{this.getSaveButtonWithTooltip(hasErrors)}</div>
				</div>
				<ContactEdit
					isNew={isNew}
					contact={contact}
					setContactState={this.setContactState}
					setBillingIsSameAsShipping={this.setBillingIsSameAsShipping}
					billingIsSameAsShipping={this.state.billingIsSameAsShipping}
					loadContactFromARES={this.props.loadContactFromARES}
					disabled={this.state.disabled || !this.props.canEditContacts}
					{...this.props.validationProps()}
				/>
			</div>
		)
	}
}

const isBillingSameAsDelivery = (contact: ?Contact): boolean => {
	if (contact && contact.addresses) {
		return contact.addresses.filter((adr: ContactAddress) => adr.type == 2).length == 0
	} else {
		return true
	}
}

export default withTranslate(validate(['FINISH_SAVE_CONTACT', 'FINISH_CREATE_CONTACT'])(withRouter(AddressBookEdit)))
