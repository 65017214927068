/* @flow */

import Popover from 'components/Popover/Popover'
import React, { Component, type Ref } from 'react'
import { HotKeys } from 'react-hotkeys'
import Calendar from './calendar'

export type Props = {|
	anchorEl: any,
	DateTimeFormat: (string, Object) => ?string,
	maxDate: Date,
	minDate: Date,
	initialDate: ?Date,
	today: string,
	todayLabel: string,
	utils: Object,
	onAccept: any => void,
	onChange?: (void, ?Object) => void,
	onDismiss?: () => void,
	onShow?: () => void,
	keyRef?: Ref<typeof Component>,
|}

export type State = {|
	open: boolean,
|}

class DatePickerDialog extends Component<Props, State> {
	anchorOrigin = { horizontal: 'middle', vertical: 'bottom' }
	targetOrigin = { horizontal: 'middle', vertical: 'top' }

	state = {
		open: false,
	}

	show = () => {
		this.props.onShow && !this.state.open && this.props.onShow()

		this.setState({
			open: true,
		})
	}

	dismiss = () => {
		this.props.onDismiss && this.state.open && this.props.onDismiss()

		this.setState({
			open: false,
		})
	}

	handleClickDay = () => {
		setTimeout(this.handleClickOk, 300)
	}

	handleClickCancel = () => {
		this.dismiss()
	}

	handleRequestClose = () => {
		this.dismiss()
	}

	handleClickOk = (event?: Event) => {
		if (event && 'keydown' === event.type) {
			event.stopPropagation()
			event.preventDefault()
		}

		this.props.onAccept &&
			!this.refs.calendar.isSelectedDateDisabled() &&
			this.props.onAccept(this.refs.calendar.getSelectedDate())

		this.dismiss()
	}

	handlers = {
		esc: this.dismiss,
		enter: this.handleClickOk,
	}

	keyMap = {
		esc: 'esc',
		enter: 'enter',
	}

	render() {
		const {
			DateTimeFormat,
			initialDate,
			maxDate,
			minDate,
			onAccept, // eslint-disable-line no-unused-vars
			onDismiss, // eslint-disable-line no-unused-vars
			onShow, // eslint-disable-line no-unused-vars
			utils,
			today,
			anchorEl,
			todayLabel,
			keyRef,
			...other
		} = this.props

		const { open } = this.state

		const styles = {
			popover: {
				padding: 0,
				minHeight: 220,
				minWidth: 240,
			},
		}
		return (
			<div {...other} ref="root">
				<Popover
					anchorOrigin={this.anchorOrigin}
					targetOrigin={this.targetOrigin}
					anchorEl={anchorEl}
					ref="dialog"
					open={open}
					onRequestClose={this.handleRequestClose}
					style={styles.popover}
					zDepth={6}
					arrow={'top'}
				>
					<HotKeys handlers={this.handlers} keyMap={this.keyMap} attach={this.props.keyRef} focused>
						<Calendar
							DateTimeFormat={DateTimeFormat}
							initialDate={initialDate}
							onClickDay={this.handleClickDay}
							maxDate={maxDate}
							minDate={minDate}
							open={open}
							ref="calendar"
							onClickCancel={this.handleClickCancel}
							onClickOk={this.handleClickOk}
							utils={utils}
							today={today}
							todayLabel={todayLabel}
							keyRef={this.props.keyRef}
						/>
					</HotKeys>
				</Popover>
			</div>
		)
	}
}

export default DatePickerDialog
