/* @flow */
/** @jsx jsx */

import { jsx } from '@emotion/core'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import { GOOGLE_APP_LINK, APPLE_BETA_LINK } from '../constants'
import PopupSection from 'components/PopupSection'
import Paper from 'components/Paper'
import AppStoreIcon from 'components/mobile/app-store-icon'
import GooglePlayIcon from 'components/mobile/google-play-icon'
import mobileAppImg from 'assets/images/mobile-app/mobile-app-part.png'
import appStoreQr from 'assets/images/mobile-app/app-store-qr.svg'
import googlePlayQr from 'assets/images/mobile-app/google-play-qr.svg'
import { colors } from 'variables'
import { Z_INDEXES } from 'trivi-constants'

type Props = {|
	...WithTranslateProps,
	open: boolean,
	onClose: () => void,
|}

function MobileAppInvitation(props: Props) {
	return (
		<PopupSection open={props.open} onRequestClose={props.onClose} zIndex={Z_INDEXES.dialogs.mobileApp}>
			<div css={styles.root}>
				<Paper style={styles.dialog}>
					<h1 css={styles.headline}>{props.t('mobileAppInvitation.headline')}</h1>
					<div css={styles.content}>{props.t('mobileAppInvitation.content')}</div>
					<div css={styles.stores}>
						<div css={styles.store}>
							<img css={styles.qr} width={170} src={googlePlayQr} />
							<GooglePlayIcon width={176} />
						</div>
						<div css={styles.store}>
							<img css={styles.qr} width={170} src={appStoreQr} />
							<AppStoreIcon width={170} />
						</div>
					</div>
					<div css={styles.footer}>
						{props.t('mobileAppInvitation.footer.body')}{' '}
						<a css={styles.link} href={GOOGLE_APP_LINK} target="_blank">
							{props.t('mobileAppInvitation.footer.android')}
						</a>{' '}
						{props.t('mobileAppInvitation.footer.or')}{' '}
						<a css={styles.link} href={APPLE_BETA_LINK} target="_blank">
							{props.t('mobileAppInvitation.footer.ios')}
						</a>
						{props.t('mobileAppInvitation.footer.dot')}
					</div>
				</Paper>
			</div>
		</PopupSection>
	)
}

const styles = {
	root: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		height: '100vh',
	},
	content: {
		fontSize: 18,
	},
	dialog: {
		backgroundImage: `url(${mobileAppImg})`,
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center right',
		backgroundSize: 'auto 420px',
		padding: 30,
		width: 775,
		paddingRight: 200,
	},
	headline: {
		fontWeight: 'bold',
		fontSize: '1.4em',
	},
	stores: {
		display: 'flex',
		flexDirection: 'row',
	},
	store: {
		flex: 1,
		flexDirection: 'column',
		display: 'flex',
		alignItems: 'center',
	},
	qr: {
		margin: 20,
	},
	footer: {
		fontSize: 12,
		color: colors.grey,
		textAlign: 'center',
		margin: '30px 30px 10px 30px',
	},
	link: {
		textDecoration: 'underline',
	},
}

export default withTranslate(MobileAppInvitation)
