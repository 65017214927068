// @flow

import type {
	Categories as ColumnChartCategories,
	Data as ColumnChartData,
	DataItem as ColumnChartDataItem,
} from 'components/charts/column-chart'
import type { DataNumbers } from 'components/charts/highcharts/types'
import type { AccountingDocumentReportAggregationResponseAggregatedItem as ApiDataItem } from 'types'
import { getRangeName } from 'utils/formatters'
import { colors } from 'variables'
import { i18n } from 'locales'

export function convertData(data: Array<ApiDataItem>): ColumnChartData {
	const seriesObject = {
		issuedVat: [],
		issuedExcludingVat: [],
		receivedExcludingVat: [],
		receivedVat: [],
	}

	data.forEach((item: ApiDataItem) => {
		seriesObject.issuedVat.push(item.issuedVat || 0)
		seriesObject.issuedExcludingVat.push(item.issuedExcludingVat || 0)
		seriesObject.receivedExcludingVat.push(-(item.receivedExcludingVat || 0))
		seriesObject.receivedVat.push(-(item.receivedVat || 0))
	})

	const columns = Object.keys(seriesObject).map((label: string) => ({
		label: i18n.t(`dashboard.billingSummary.${label}`),
		values: seriesObject[label],
		color: getColumnColor(label),
		index: getColumnIndex(label),
		id: label,
	}))

	const splines = [createSplineData(data)]

	return {
		columns,
		splines,
	}
}

function createSplineData(data: Array<ApiDataItem>): ColumnChartDataItem {
	return {
		values: (data.map((item: ApiDataItem) => calculateTotal(item)): DataNumbers),
		label: i18n.t('dashboard.billingSummary.difference'),
		color: colors.blue,
		index: 5,
		id: 'difference',
	}
}

function calculateTotal(data: ApiDataItem): number {
	return data.issuedExcludingVat + data.issuedVat - (data.receivedExcludingVat + data.receivedVat)
}

function getColumnColor(name: string): string {
	switch (name) {
		case 'issuedVat':
			return '#e1f6da'
		case 'issuedExcludingVat':
			return '#c0f7a9'
		case 'receivedExcludingVat':
			return '#fda4b0'
		case 'receivedVat':
			return '#feccd2'
	}

	return colors.blue
}

function getColumnIndex(name: string): number {
	switch (name) {
		case 'issuedVat':
			return 0
		case 'issuedExcludingVat':
			return 1
		case 'receivedExcludingVat':
			return 3
		case 'receivedVat':
			return 2
		default:
			return 0
	}
}

export function convertCategories(data: Array<ApiDataItem>): ColumnChartCategories {
	return data.map((item: ApiDataItem, i: number) => {
		if (!item.dateFrom || !item.dateTo) {
			return i.toString()
		}

		return getRangeName(new Date(item.dateFrom), new Date(item.dateTo), true)
	})
}

export function createMonthData() {
	return [
		createDataItem(0, 0, 'M', 2018, 1),
		createDataItem(0, 2000, 'M', 2018, 2),
		createDataItem(2000, 0, 'M', 2018, 3),
		createDataItem(6000, 5000, 'M', 2018, 4),
		createDataItem(10000, 2000, 'M', 2018, 5),
		createDataItem(14000, 3000, 'M', 2018, 6),
		createDataItem(9000, 300, 'M', 2018, 7),
		createDataItem(5000, 1900, 'M', 2018, 8),
		createDataItem(4000, 2300, 'M', 2018, 9),
		createDataItem(10000, 1000, 'M', 2018, 10),
		createDataItem(2400, 2400, 'M', 2018, 11),
		createDataItem(7000, 500, 'M', 2018, 12),
	]
}

export function createQuarterData() {
	return [
		createDataItem(10000000, 500000, 'Q', 2018, 1),
		createDataItem(14000000, 300000, 'Q', 2018, 4),
		createDataItem(8000000, 90000, 'Q', 2018, 7),
		createDataItem(12000000, 20000, 'Q', 2018, 10),
	]
}

function createDataItem(
	issuedExcludingVat: number,
	receivedExcludingVat: number,
	granularity: 'M' | 'Q' | 'Y',
	year: number,
	month: number,
): ApiDataItem {
	let dateTo: string
	const jsMonth = month - 1

	if ('M' === granularity) {
		dateTo = new Date(Date.UTC(year, jsMonth + 1, 0, 0, 0, 0)).toISOString()
	} else if ('Q' === granularity) {
		dateTo = new Date(Date.UTC(year, jsMonth + 3, 0, 0, 0, 0)).toISOString()
	} else {
		dateTo = new Date(Date.UTC(year, jsMonth + 12, 0, 0, 0, 0)).toISOString()
	}

	return {
		issuedVat: calculateVat(issuedExcludingVat),
		issuedExcludingVat,
		receivedExcludingVat: receivedExcludingVat,
		receivedVat: calculateVat(receivedExcludingVat),
		dateFrom: new Date(Date.UTC(year, jsMonth, 1, 0, 0, 0)).toISOString(),
		dateTo,
	}
}

function calculateVat(n: number): number {
	return (n / 100) * 21
}
