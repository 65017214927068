/* @flow */

import Mixpanel from 'mixpanel-browser'
import storage from 'utils/local-storage'
import type { AppSection, UserType, UserProfile } from 'types'
import { getUserFullName } from 'modules/user/domain/user'

export type Ids = { [key: string]: ?string }
export type DeletedReason = 'manual' | 'concept' | 'reextract'

declare var MIXPANEL_ID: string //global variable defined in webpack.config.js

const IS_TRACKING_DISABLED_KEY = 'isTrackingDisabled'

function trackEvent(name: string, options?: {}) {
	isTrackingEnabled() && Mixpanel.track(name, options)
}

function init() {
	if (!isTrackingEnabled()) return
	Mixpanel.init(MIXPANEL_ID)
	Mixpanel.register_once({
		$system: 'portal',
	})
}

function trackStateChangeToExtracting(accDocId: string, isQueue?: boolean) {
	trackEvent('AccDoc Extracting', {
		extSource: isQueue ? 'queue' : 'normal',
		accDocId,
	})
}

function trackStateChangeToExtracted(accDocId: string, isQueue?: boolean) {
	trackEvent('AccDoc Extracted', {
		extSource: isQueue ? 'queue' : 'normal',
		accDocId,
	})
}

function trackExtractingQueuePop(accDocId: string) {
	trackEvent('AccDoc Queue Pop', {
		accDocId,
	})
}

function trackSentEmail(accDocId: string, recipientsCount: number) {
	trackEvent('AccDoc E-mail Sent', {
		accDocId,
		recipientsCount,
	})
}

function changeOrganizationId(organizationId: ?string) {
	isTrackingEnabled() && Mixpanel.register({ organizationId })
	trackEvent('Organization Switched', {
		organizationId,
	})
}

function changeOrganizationName(organizationName: ?string) {
	isTrackingEnabled() && Mixpanel.register({ organizationName })
	trackEvent('Organization Name Switched', {
		organizationName,
	})
}

function trackUploadedDocuments(count: number) {
	trackEvent('AccDoc(s) Uploaded', {
		count,
	})
}

function trackCreateBankOrder(bankCode: string) {
	trackEvent('Banking Order Created', {
		bankCode,
	})
}

function trackStateChange(accDocId: string, state: string, prevState?: ?string) {
	trackEvent('AccDoc State Changed', {
		prevState,
		accDocId,
		state,
	})

	if ('Fixing' === state) trackStateChangeToFixing(accDocId)
	if ('Processed' === state) trackStateChangeToProcessed(accDocId)
}

function trackStateChangeToFixing(accDocId: string) {
	trackEvent('AccDoc Fixing', {
		accDocId,
	})
}

function trackStateChangeToProcessed(accDocId: string) {
	trackEvent('AccDoc Processed', {
		accDocId,
	})
}

function trackStateChangeToNeedHelp(accDocId: string) {
	trackEvent('AccDoc Extracting Help Needed', {
		accDocId,
		extSource: 'queue',
	})
}

function trackAccDocReextract(accDocId: string) {
	trackEvent('Acc Doc Reextract', {
		accDocId,
	})
}

function trackAccDocExtractingCanceled(accDocId: string) {
	trackEvent('AccDoc Extracting Canceled', {
		accDocId,
		extSource: 'queue',
	})
}

function trackAccDocDeleted(accDocId: string, reason: DeletedReason) {
	trackEvent('Acc Doc Deleted', {
		accDocId,
		reason,
	})
}

function changeUserProfile(userProfile: ?UserProfile) {
	if (!isTrackingEnabled()) return

	if (!userProfile || !userProfile.id) {
		return
	}

	Mixpanel.identify(userProfile.id)
	Mixpanel.people.set({
		email: userProfile.email,
		firstname: userProfile.firstname,
		lastname: userProfile.lastname,
		$email: userProfile.email,
		$name: getUserFullName(userProfile),
	})
}

function changeUserType(userType: ?UserType) {
	if (!isTrackingEnabled()) return
	Mixpanel.people.set({
		userType,
	})
}

function changeUserIsExtractor(isExtractor: boolean) {
	if (!isTrackingEnabled()) return
	Mixpanel.people.set({
		isExtractor,
	})
}

function changeMainAccountantId(mainAccountantId: ?string) {
	isTrackingEnabled() && Mixpanel.register({ mainAccountantId })
	trackEvent('Main Accountant Changed', {
		mainAccountantId,
	})
}

function changeMainAccountantName(mainAccountantName: ?string) {
	isTrackingEnabled() && Mixpanel.register({ mainAccountantName })
	trackEvent('Main Accountant Name Changed', {
		mainAccountantName,
	})
}

function changePayrollAccountantId(payrollAccountantId: ?string) {
	trackEvent('Payroll Accountant Changed', {
		payrollAccountantId,
	})
}

function changePayrollAccountantName(payrollAccountantName: ?string) {
	isTrackingEnabled() && Mixpanel.register({ payrollAccountantName })
	trackEvent('Payroll Accountant Name Changed', {
		payrollAccountantName,
	})
}

function changeURL(url: string, sectionName: AppSection, ids: Ids) {
	trackEvent('Page View', {
		url,
		sectionName,
		...ids,
	})
}

function enableTracking() {
	storage.set(IS_TRACKING_DISABLED_KEY, false)
}

function disableTracking() {
	storage.set(IS_TRACKING_DISABLED_KEY, true)
}

function isTrackingEnabled(): boolean {
	return !(true === storage.get(IS_TRACKING_DISABLED_KEY))
}

export default {
	init,
	changeUserType,
	changeUserProfile,
	trackStateChangeToExtracting,
	trackStateChangeToExtracted,
	trackExtractingQueuePop,
	trackSentEmail,
	changeOrganizationId,
	changeOrganizationName,
	changeUserIsExtractor,
	changeMainAccountantId,
	changeMainAccountantName,
	changePayrollAccountantId,
	changePayrollAccountantName,
	trackUploadedDocuments,
	trackCreateBankOrder,
	trackStateChange,
	trackStateChangeToFixing,
	trackStateChangeToProcessed,
	enableTracking,
	disableTracking,
	isTrackingEnabled,
	trackStateChangeToNeedHelp,
	trackAccDocExtractingCanceled,
	trackAccDocReextract,
	trackAccDocDeleted,
	changeURL,
}
