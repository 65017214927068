/* @flow */
/** @jsx jsx */

import { Component } from 'react'
import { jsx } from '@emotion/core'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import TriviLink from 'modules/common/components/trivi-link'
import TriviLogo from 'modules/common/components/trivi-logo'
import { getLoginRoute } from 'modules/user/routing/routes'
import Paper from 'components/Paper'
import ChangeLanguageForNonLoggedIn from 'modules/user/components/change-lang-for-not-logged-in'
import styles from './styles.css'
import { colors } from 'variables'

type Props = {|
	...WithTranslateProps,
	children?: any,
|}

class PasswordFormLayout extends Component<Props> {
	render() {
		const { t } = this.props
		return (
			<div className={styles.root}>
				<Paper zDepth={6} rounded className={styles.container}>
					<div className={styles.inner}>
						<div css={style.language}>
							<ChangeLanguageForNonLoggedIn />
						</div>
						<div className={styles.centered}>
							<div className={styles.logo}>
								<TriviLogo />
							</div>
							{this.props.children}
							<TriviLink routeFn={getLoginRoute} style={style.link}>
								{t('user.login.backToLogin')}
							</TriviLink>
						</div>
					</div>
				</Paper>
			</div>
		)
	}
}

const style = {
	link: {
		fontWeight: 'normal',
		color: `${colors.blue} !important`,
		textDecoration: 'underline',
	},
	language: {
		display: 'block',
		position: 'absolute',
		top: 15,
		right: 15,
	},
}

export default withTranslate(PasswordFormLayout)
