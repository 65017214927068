/* @flow */
/* eslint-disable react/no-multi-comp */

import React from 'react'
import moize from 'moize'
import type { AccountingDocument } from 'types'
import { type WithTranslateProps } from 'wrappers'
import Tooltip from 'components/tooltip'
import { formatToDateString } from 'utils/formatters'
import { Column } from 'modules/data-grid-next/components'
import type { Direction } from 'modules/data-grid-next/types'
import { DATA_GRID_CACHE_SIZE } from 'trivi-constants'

export type Props = {|
	width?: number | string,
	defaultSortDirection?: Direction,
	...WithTranslateProps,
|}

const styles = {
	root: {
		textAlign: 'left',
		fontWeight: 'normal',
		margin: '10px 8px',
	},
	tr: {
		height: 20,
		lineHeight: '20px',
	},
	th: {
		fontSize: 10,
		fontWeight: 'normal',
		padding: '0 10px',
	},
	td: {
		padding: '0 10px',
	},
	tooltip: {
		marginBottom: 21,
	},
}

export default function getTaxDateColumn(props: Props) {
	function columnRenderer(value: string, row: AccountingDocument) {
		const taxDateStr: string = formatToDateString(value)
		const label = (
			<table style={styles.root}>
				<tbody>
					<tr style={styles.tr}>
						{row.issueDate && <th style={styles.th}>{props.t('accountingDocument.columns.issueDate')}</th>}
						{row.taxReturnDate && <th style={styles.th}>{props.t('accountingDocument.columns.taxReturnDatePopup')}</th>}
						{row.bookingDate && <th style={styles.th}>{props.t('accountingDocument.columns.bookingDate')}</th>}
					</tr>
					<tr style={styles.tr}>
						{row.issueDate && <td style={styles.td}>{row.issueDate && formatToDateString(row.issueDate)}</td>}
						{row.taxReturnDate && (
							<td style={styles.td}>{row.taxReturnDate && formatToDateString(row.taxReturnDate)}</td>
						)}
						{row.bookingDate && <td style={styles.td}>{row.bookingDate && formatToDateString(row.bookingDate)}</td>}
					</tr>
				</tbody>
			</table>
		)
		return (
			<Tooltip wrapperStyle={styles.tooltip} label={label} inline>
				{taxDateStr}
			</Tooltip>
		)
	}

	const columnRendererCashed = moize(columnRenderer, { maxSize: DATA_GRID_CACHE_SIZE })

	return (
		<Column
			title={props.t('accountingDocument.columns.taxDate')}
			width={props.width}
			columnId="taxDate"
			key="taxDate"
			render={columnRendererCashed}
			sortable
			defaultSortDirection={props.defaultSortDirection || undefined}
		/>
	)
}
