/* @flow */
/** @jsx jsx */

import { Component } from 'react'
import { jsx } from '@emotion/core'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import type { PortalLanguage, TriviStoreCard as TriviStoreCardType } from 'types'
import { getCardUrl } from '../domain'
import PopupSection from 'components/PopupSection'
import Paper from 'components/Paper'
import Button from 'components/button'

type Props = {|
	...WithTranslateProps,
	card: ?TriviStoreCardType,
	language: ?PortalLanguage,
	onRequestClose: () => void,
|}

type State = {|
	onClickLoading: boolean,
|}

class TriviStoreDialog extends Component<Props, State> {
	state = {
		onClickLoading: false,
	}

	onButtonClick = async () => {
		const { card } = this.props
		this.setState({ onClickLoading: true })
		card && card.button && card.button.onClick && (await card.button.onClick())
		this.setState({ onClickLoading: false })
	}

	render() {
		if (!this.props.card) return null
		const { card, t } = this.props

		return (
			<PopupSection open onRequestClose={this.props.onRequestClose}>
				<div css={styles.root}>
					<Paper zDepth={3}>
						<div css={styles.paper}>
							<div css={styles.head}>
								<div css={styles.headlines}>
									{card.headline && <div css={styles.headline}>{t(card.headline)}</div>}
									{card.subheadline && <div css={styles.subheadline}>{t(card.subheadline)}</div>}
								</div>
								<div css={styles.logo}>{card.logo}</div>
							</div>
							{card.about && <div css={styles.about}>{t(card.about)}</div>}
							{card.formattedText && <div css={styles.about}>{card.formattedText(t)}</div>}
							{card.button && (
								<div css={styles.url}>
									<Button
										labelText={t(card.button.label)}
										href={getCardUrl(this.props.card, this.props.language)}
										disabled={(card.button && card.button.disabled) || this.state.onClickLoading}
										onClick={this.onButtonClick}
										autoTestId="trivi-store-dialog-button"
									/>
								</div>
							)}
						</div>
					</Paper>
				</div>
			</PopupSection>
		)
	}
}

const styles = {
	root: {
		padding: 80,
		display: 'flex',
		justifyContent: 'center',
	},
	paper: {
		width: 920,
		padding: '45px 30px',
	},
	head: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		marginBottom: 65,
	},
	headlines: {
		flex: '1 1 auto',
	},
	headline: {
		fontSize: 34,
		fontWeight: 700,
	},
	subheadline: {
		fontSize: 24,
	},
	logo: {
		paddingLeft: 40,
		flex: '1 0 auto',
		textAlign: 'right',

		'> img': {
			width: '100%',
			height: 'auto',
		},
	},
	about: {
		fontSize: 20,
		lineHeight: '26px',
	},
	url: {
		marginTop: 50,
	},
}

export default withTranslate(TriviStoreDialog)
