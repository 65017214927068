/* @flow */

import React, { Component } from 'react'
import {
	withNavigationPermissions,
	type WithNavigationPermissionsProps,
	withTranslate,
	type WithTranslateProps,
} from 'wrappers'
import type { NavigationItem } from '../types'
import { passAutoTestId } from 'utils/tests/autotest'
import {
	ACCOUNTING_DOCUMENT,
	ADDRESS_BOOK,
	BANK,
	CASH_REGISTER,
	DASHBOARD_NEW,
	DOCUMENT,
	EMPTY_DASHBOARD,
	FEATURES,
	INTERNAL_DASHBOARD,
	SETTINGS,
	TASK,
	// TASK,
	TRIVI_STORE,
} from 'modules/navigation/constants'
import TriviLink from 'modules/common/components/trivi-link'
import Avatar from 'components/avatar'
import Tooltip from 'components/tooltip'
import { colors } from 'variables'

type Props = {|
	...WithTranslateProps,
	...WithNavigationPermissionsProps,
	inOrganization: boolean,
	selected: string,
	compact: boolean,
	showOrganizationRelatedItems: boolean,
	currentUserPermissions: Array<string>,
	currentUserIsInternal: boolean,
|}

class NavigationPrimary extends Component<Props> {
	getNavigationItems(): Array<NavigationItem> {
		const {
			canSeeEmptyDashboard,
			canSeeInternalDashboard,
			canSeeDashboard,
			canSeeAccountingDocuments,
			canSeeBankAccounts,
			canSeeDocuments,
			canSeeContacts,
			canSeeTasks,
			canSeeSettings,
			canSeeFeatures,
			canSeeTriviStore,
			currentUserIsInternal,
		} = this.props

		const result: Array<NavigationItem> = []

		/* eslint-disable */
		canSeeInternalDashboard   && result.push(INTERNAL_DASHBOARD)
		canSeeEmptyDashboard      && result.push(EMPTY_DASHBOARD)
		canSeeDashboard           && result.push(DASHBOARD_NEW)
		canSeeAccountingDocuments && result.push(ACCOUNTING_DOCUMENT)
									 result.push(CASH_REGISTER)
		canSeeBankAccounts        && result.push(BANK)
		canSeeDocuments           && result.push(DOCUMENT)
		canSeeContacts            && result.push(ADDRESS_BOOK)
		canSeeTasks && currentUserIsInternal && result.push(TASK)
		canSeeSettings            && result.push(SETTINGS)
		canSeeFeatures            && result.push(FEATURES)
		canSeeTriviStore          && result.push(TRIVI_STORE)
		/* eslint-enable */

		return result
	}

	render() {
		const { t, selected } = this.props

		return (
			<div style={styles.root} id={'nav-primary'}>
				{this.getNavigationItems().map(
					(item: NavigationItem, index: number) =>
						item.routeFn && (
							<Tooltip
								key={index}
								align="center-right"
								arrowAlign="center-left"
								label={this.props.compact ? t(item.label) : ''}
							>
								<TriviLink hoverStyle={styles.hoveredLink} style={styles.link} routeFn={item.routeFn}>
									<Avatar
										style={selected === item.id ? { ...styles.avatar, ...styles.selectedAvatar } : styles.avatar}
										icon={item.icon && React.createElement(item.icon, { style: styles.icon })}
										size={50}
										color={colors.white}
										hoverColor={colors.white}
										backgroundColor={colors.blue}
										{...passAutoTestId(t(item.label))}
									/>
									<span>{!this.props.compact ? t(item.label) : null}</span>
								</TriviLink>
							</Tooltip>
						),
				)}
				{/*{!canSeeNonDemoFeatures && <DemoVideoButton compact={compact} />}*/}
			</div>
		)
	}
}

const styles = {
	root: {
		width: 'auto',
		paddingTop: 5,
		paddingBottom: 20,
		zIndex: 1000,
		height: '100%',
		fontSize: 14,
	},
	link: {
		display: 'flex',
		justifyCotent: 'flex-start',
		alignCotent: 'center',
		alignItems: 'center',
		height: 60,
	},
	hoveredLink: {
		backgroundColor: '#08A0BB',
		color: colors.white,
		textDecoration: 'none',
	},
	icon: {
		opacity: 0.8,
	},
	avatar: {
		border: '2px solid transparent',
		backgroundColor: 'transparent',
		marginRight: 16,
		marginLeft: 16,
		transition: 'all 100ms ease-out',
	},
	selectedAvatar: {
		border: '2px solid #0CC4E3',
		backgroundColor: '#09ABC7',
		boxShadow: 'inset 3px 6px 0 0 rgba(0,0,0,0.08)',
	},
}

export default withTranslate(withNavigationPermissions(NavigationPrimary))
