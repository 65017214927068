// @flow

import { history } from 'store'
import { fileRoute } from 'modules/file/routing/routes'
import { getFileData } from 'modules/file/actions'
import { i18n } from 'locales'

const supportedMimeTypesForPreview = new Set(['image/gif', 'image/jpeg', 'image/png', 'application/pdf'])

// do we support give mimetype for preview? - we prompt download dialog for others
export const isSupportedForPreview = (mimetype: string) => supportedMimeTypesForPreview.has(mimetype)

// open preview in popup
function openForPreview(blobUrl: string, newWindow: window) {
	setTimeout(() => {
		newWindow.location.href = blobUrl
	}, 100)
}

// blob url dispose
function deleteBlobUrl(blobUrl: string) {
	setTimeout(function() {
		//delay deleting, otherwise firefox wont download anything
		window.URL.revokeObjectURL(blobUrl)
	}, 250)
}

// direct prompt for download (mimetypes not supported for preview)
function promptDownload(blobUrl: string, fileName: string) {
	if ('download' in document.createElement('a')) {
		let a = document.createElement('a')
		a.setAttribute('href', blobUrl)
		a.setAttribute('download', fileName)
		a.onclick = deleteBlobUrl(blobUrl)
		document.body && document.body.appendChild(a)
		a.click()
		document.body && document.body.removeChild(a)
	} else {
		openForPreview(blobUrl)
		deleteBlobUrl(blobUrl)
	}
}

export const getUrlFromBlob = (blob: Blob) => URL.createObjectURL(blob)
export const getMimeTypeBlob = (blob: Blob) => blob.type || 'application/octet-stream'

// handle for download for anything not supported for preview
export async function handleDownloadFile(
	fileId: string,
	fileName: string,
	downloadOnly?: boolean,
	generateDownloadURL?: boolean,
) {
	const file = await getFileData(fileId, { useDownloadRequest: !!generateDownloadURL })
	if (!file) return false
	const fileType = getMimeTypeBlob(file.blob)
	if (window.navigator && window.navigator.msSaveOrOpenBlob) {
		window.navigator.msSaveOrOpenBlob(file.blob, fileName)
		return true
	} else if (
		(!isSupportedForPreview(fileType) && !isObscureImageType(fileName, fileType)) ||
		(!isSupportedForPreview(fileType) &&
			isObscureImageType(fileName, fileType) &&
			fileType === 'application/octet-stream') ||
		downloadOnly
	) {
		/* Firefox HOTFIX - API always returns application/pdf independently on actual file type */
		const blob = generateDownloadURL && downloadOnly ? new Blob([file.blob], { type: '' }) : new Blob([file.blob])
		promptDownload(getUrlFromBlob(blob), fileName)
		return true
	}
	return false
}

async function openInPopup(fileId: string) {
	let blankWindow = window.open(
		'about:blank',
		'_blank',
		'width=600, height=600, toolbar=no, menubar=no, resizable=yes, location=no, titlebar=no',
	)
	blankWindow.document.open('text/html', 'replace')
	blankWindow.document.write('<p>' + i18n.t('application.downloading') + '</p>')

	const file = await getFileData(fileId)
	if (!file) return null
	openForPreview(getUrlFromBlob(file.blob), blankWindow)
}

export function openAspopupPage(fileId: string) {
	history.push(fileRoute(fileId))
}

// preview file for supported mimetypes and prompt download for others
export async function openFile(fileId: string, fileName: string, previewTarget: string) {
	const handledViaDownload = await handleDownloadFile(fileId, fileName)
	if (!handledViaDownload) {
		previewTarget === 'popupPage' ? openAspopupPage(fileId) : openInPopup(fileId)
	}
}

export function getFileNameFromContentDisposition(contentDisposition: ?string): ?string {
	if (contentDisposition) {
		const parts = contentDisposition.match(/filename\*=(UTF-\d['"]*)?((['"]).*?[.]$\2|[^;\n]*)?/)
		if (parts && parts.length > 0) {
			return decodeURIComponent(parts[2])
		}
	}
	return null
}

export function isFilePreviewable(contentType: ?string): boolean {
	switch (contentType) {
		case 'image/jpeg':
		case 'image/jpg':
		case 'image/png':
		case 'image/bmp':
		case 'image/gif':
		case 'application/pdf':
			return true
		default:
			return false
	}
}

export function isObscureImageType(fileName: ?string, contentType: ?string): boolean {
	if (contentType && fileName && (contentType.startsWith('image/') || contentType === 'application/octet-stream')) {
		if (fileName.endsWith('.pjp')) {
			return true
		}
		if (fileName.endsWith('.pjpeg')) {
			return true
		}
		if (fileName.endsWith('.jfif')) {
			return true
		}
	}
	return false
}
