/* @flow */
/** @jsx jsx */

import { Component } from 'react'
import { jsx } from '@emotion/core'
import memoize from 'memoize-one'
import type { AccountingDocumentBankAccount, ContactBankAccount } from 'types'
import IconButton from 'components/icon-button'
import IbanInput from 'components/iban-input'
import SwiftInput from 'components/swift-input'
import BankCodeAutocomplete from 'modules/bank/containers/bank-code-autocomplete'
import ContentClear from 'components/svg-icons/content/clear'
import ContentAdd from 'components/svg-icons/content/add'
import AutoComplete from 'components/auto-complete'
import { validate, withTranslate, type FormValidationProps, type WithTranslateProps } from 'wrappers'
import { emptyAccountingDocumentBankAccount } from 'types/empty'
import { AccountingDocumentBankAccount_isEmpty, AccountingDocumentBankAccountNumber_isEmpty } from 'types/operations'
import { ContactBankAccounts_findById } from 'modules/address-book/domain/contact-bankaccounts'
import { removeAllWhitespaces } from 'utils/formatters/string-formatter'
// import { Validator } from 'utils'

type Props = {|
	...FormValidationProps,
	...WithTranslateProps,
	direction: 'issued' | 'received',
	readonly?: boolean,
	canBeSaved?: boolean,
	bankAccount: AccountingDocumentBankAccount,
	contactBankAccounts: Array<ContactBankAccount>,
	remainingBankAccounts: Array<AccountingDocumentBankAccount>,
	onBankAccountRemove: (bankAccount: AccountingDocumentBankAccount) => void,
	onBankAccountAdd: (bankAccount: AccountingDocumentBankAccount) => void,
	onAddBankAccountToContact: (bankAccount: AccountingDocumentBankAccount) => void,
|}

type State = {|
	bankAccount: AccountingDocumentBankAccount,
|}

class BankAccount extends Component<Props, State> {
	state: State = {
		bankAccount: this.props.bankAccount || emptyAccountingDocumentBankAccount(),
	}

	onIbanChange = (event: SyntheticInputEvent<HTMLInputElement>, iban: ?string) => {
		this.setState({ bankAccount: { ...this.state.bankAccount, iban: iban || '' } })
	}

	onSwiftChange = (event: SyntheticInputEvent<HTMLInputElement>, swift: ?string) => {
		this.setState({ bankAccount: { ...this.state.bankAccount, swift: swift || '' } })
	}

	onBankCodeChange = (value: string) => {
		this.setState(
			{
				bankAccount: {
					...this.state.bankAccount,
					bankCode: value || '',
				},
			},
			this.onFieldBlur,
		)
	}

	onNumberChange = async (value: any) => {
		const account = value && value.account ? value.account : null
		const number = account && account.number ? account.number : value || ''
		await this.setState(
			{
				bankAccount: {
					...this.state.bankAccount,
					number: removeAllWhitespaces(number),
					bankCode: account ? account.bankCode || '' : this.state.bankAccount.bankCode,
					swift: account ? account.swift || '' : this.state.bankAccount.swift,
					iban: account ? account.iban || '' : this.state.bankAccount.iban,
				},
			},
			this.onFieldBlur,
		)
	}

	onFieldBlur = () => {
		this.props.onBankAccountAdd(this.state.bankAccount)
	}

	getAccountName = (account: ?ContactBankAccount) =>
		account ? (account.name || '') + ' ' + (account.currency || '') : null

	getDisplayNumberOptions = memoize(
		(accounts: Array<AccountingDocumentBankAccount>, contactBankAccounts: Array<ContactBankAccount>) => {
			return accounts.map((account: AccountingDocumentBankAccount) => {
				const contactAccount = account.bankAccountId
					? ContactBankAccounts_findById(contactBankAccounts, account.bankAccountId)
					: null
				return {
					label: account.displayNumber,
					value: account.displayNumber,
					rightText: this.getAccountName(contactAccount),
					account,
				}
			})
		},
	)

	getNumberOptions = memoize(
		(accounts: Array<AccountingDocumentBankAccount>, contactBankAccounts: Array<ContactBankAccount>) => {
			return accounts.map((account: AccountingDocumentBankAccount) => {
				const contactAccount = account.bankAccountId
					? ContactBankAccounts_findById(contactBankAccounts, account.bankAccountId)
					: null
				return {
					label: (account.number || '') + '/' + (account.bankCode || ''),
					value: (account.number || '') + '/' + (account.bankCode || ''),
					rightText: this.getAccountName(contactAccount),
					account,
				}
			})
		},
	)

	onRemoveClick = (event: Object) => {
		event.preventDefault()
		this.state.bankAccount.localId && this.props.onBankAccountRemove(this.state.bankAccount)
	}

	onSaveClick = (event: Object) => {
		event.preventDefault()
		this.state.bankAccount.localId && this.props.onAddBankAccountToContact(this.state.bankAccount)
	}

	validateForm = (): Object => {
		/*
		const {
			bankAccount: { number },
		} = this.state
		*/
		const errors = {}

		// [TWU-3476] Disable bank account validation number temporarily
		/*
		if (number && !Validator.accountNo(number)) {
			errors.number = this.props.t('clientError.notAnAccountNumber')
		}
		*/

		return errors
	}

	render = () => {
		const { t, direction } = this.props
		const { bankAccount } = this.state
		const disabled = direction === 'issued' || !!this.props.readonly
		this.props.validateForm(this.validateForm())

		return (
			<div css={styles.account}>
				{bankAccount.displayNumber ? (
					<div>
						<div css={styles.number}>
							<AutoComplete
								autoTestId="invoice-bank-account-display-number"
								placeholder={t('invoice.bankAccountNumberHint')}
								onChange={this.onNumberChange}
								options={this.getDisplayNumberOptions(this.props.remainingBankAccounts, this.props.contactBankAccounts)}
								value={bankAccount.displayNumber}
								disabled={disabled}
								autoWidth
								inline
							/>
						</div>
					</div>
				) : (
					<div>
						<div css={styles.number}>
							<AutoComplete
								autoTestId="invoice-bank-account-number"
								placeholder={t('invoice.bankAccountNumberHint')}
								onChange={this.onNumberChange}
								options={this.getNumberOptions(this.props.remainingBankAccounts, this.props.contactBankAccounts)}
								value={this.props.validationValue('number', bankAccount.number)}
								error={this.props.validationMessage('number')}
								disabled={disabled}
								autoWidth
								inline
							/>
							<div css={styles.slash}>&nbsp;/&nbsp;</div>
							<BankCodeAutocomplete
								hintText={t('invoice.bankCodeHint')}
								value={bankAccount.bankCode || ''}
								disabled={disabled}
								autoWidth
								inline
								onChange={this.onBankCodeChange}
								isCreatable
								noPortal
							/>
						</div>
						{(bankAccount.iban || (direction === 'received' && !disabled)) && (
							<span css={styles.iban}>
								<IbanInput
									disabled={disabled}
									name="iban"
									hintText={t('invoice.ibanHint')}
									autoTestId="invoice-bank-account-iban"
									value={bankAccount.iban}
									onBlur={this.onFieldBlur}
									onChange={this.onIbanChange}
									inline
									autoWidth
								/>
							</span>
						)}
						{(bankAccount.swift || (direction === 'received' && !disabled)) && (
							<span css={styles.swift}>
								<SwiftInput
									disabled={disabled}
									name="swift"
									hintText={t('invoice.swiftHint')}
									autoTestId="invoice-bank-account-swift"
									value={bankAccount.swift}
									onBlur={this.onFieldBlur}
									onChange={this.onSwiftChange}
									inline
									autoWidth
								/>
							</span>
						)}
					</div>
				)}
				<div css={styles.inlineButtons}>
					{!this.props.readonly &&
						this.props.canBeSaved &&
						!AccountingDocumentBankAccount_isEmpty(bankAccount) &&
						!AccountingDocumentBankAccountNumber_isEmpty(bankAccount) &&
						this.props.isFormValid() &&
						bankAccount.localId != null && (
							<IconButton
								size={24}
								style={styles.icon}
								tooltip={t('invoice.bankAccountAddTooltip')}
								autoTestId="invoice-bank-account-item-add"
								onClick={this.onSaveClick}
							>
								<ContentAdd size={14} />
							</IconButton>
						)}
					{!this.props.readonly &&
						bankAccount.localId !== null &&
						bankAccount.localId !== undefined &&
						!AccountingDocumentBankAccount_isEmpty(bankAccount) && (
							<IconButton
								size={24}
								style={styles.icon}
								tooltip={t('invoice.bankAccountDeleteTooltip')}
								autoTestId="invoice-bank-account-item-remove"
								onClick={this.onRemoveClick}
							>
								<ContentClear size={14} />
							</IconButton>
						)}
				</div>
			</div>
		)
	}
}

const styles = {
	icon: { padding: 5 },
	account: {
		position: 'relative',
		margin: '0 0 8px 0',
		display: 'flex',
		justifyContent: 'space-between',
		alignContent: 'center',
		alignItems: 'center',
		minHeight: 24,
		lineHeight: '24px',
		'&:last-child': {
			margin: 0,
		},
	},
	inlineButtons: {
		display: 'flex',
	},
	number: {
		marginTop: -4,
		marginBottom: -4,
		marginLeft: -4,
		lineHeight: '20px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-start',
		cursor: 'text',
	},
	slash: {
		marginRight: -4,
		marginLeft: -4,
	},
	iban: {
		fontSize: 10,
		marginRight: 10,
		lineHeight: '10px',
	},
	swift: {
		fontSize: 10,
		lineHeight: '10px',
	},
}

export default withTranslate(validate()(BankAccount))
