/* @flow */

import { connect } from 'react-redux'
import type {
	AccountingDocumentAttachment,
	AccountingDocumentConnection,
	AccountingDocumentReduxAction,
	Dispatch,
	IssueAction,
	State,
} from 'types'
import {
	disconnectAccountingDocumentConnection,
	loadAccountingDocumentAttachments,
	loadAccountingDocumentConnections,
	removeAccountingDocumentAttachment,
	loadAccountingDocumentVatRecapInfo,
} from '../../actions'
import { getAccountingDocumentAttachments, getAccountingDocumentConnections } from '../../selectors'
import AttachmentList from '../../components/invoice-elements/attachment-list'

type StateProps = {|
	attachments: ?Array<AccountingDocumentAttachment>,
	connections: ?Array<AccountingDocumentConnection>,
|}

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => {
	return {
		attachments: getAccountingDocumentAttachments(state, ownProps.accountingDocumentId),
		connections: getAccountingDocumentConnections(state, ownProps.accountingDocumentId),
	}
}

type DispatchProps = {|
	onRemoveAttachment: (attachment: AccountingDocumentAttachment) => void,
	onRemoveConnection: (connection: AccountingDocumentConnection) => Promise<any>,
	onComponentUnmount: () => void,
	loadAccountingDocumentAttachments: () => void,
	loadAccountingDocumentConnections: () => void,
|}

const mapDispatchToProps = (
	dispatch: Dispatch<AccountingDocumentReduxAction | IssueAction>,
	ownProps: OwnProps,
): DispatchProps => {
	return {
		onRemoveAttachment: (attachment: AccountingDocumentAttachment) => {
			dispatch(removeAccountingDocumentAttachment(ownProps.accountingDocumentId, attachment))
		},
		onRemoveConnection: async (connection: AccountingDocumentConnection) => {
			await dispatch(disconnectAccountingDocumentConnection(ownProps.accountingDocumentId, connection))
			dispatch(loadAccountingDocumentVatRecapInfo(ownProps.accountingDocumentId))
		},
		onComponentUnmount: () => {
			dispatch(loadAccountingDocumentConnections(ownProps.accountingDocumentId))
		},
		loadAccountingDocumentAttachments: () => {
			dispatch(loadAccountingDocumentAttachments(ownProps.accountingDocumentId))
		},
		loadAccountingDocumentConnections: () => {
			dispatch(loadAccountingDocumentConnections(ownProps.accountingDocumentId))
		},
	}
}

type OwnProps = {|
	accountingDocumentId: string,
	showAccountingDocuments?: boolean,
	showDocuments?: boolean,
	onRequestClose: () => void,
	readonly?: boolean,
|}

export default connect(mapStateToProps, mapDispatchToProps)(AttachmentList)
