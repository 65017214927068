/* @flow */
/** @jsx jsx */

import { type Node, Fragment } from 'react'
import { jsx } from '@emotion/core'
import Divider from 'components/divider'
import Paper from 'components/Paper'
import { colors } from 'variables'

export type Props = {|
	actions?: Node,
	headline?: Node,
	header?: Node,
	children: Node,
|}

export default function FormBox(props: Props) {
	return (
		<div css={styles.container}>
			<div css={styles.header}>
				<h1 css={styles.h1}>{props.headline}</h1>
				<div css={styles.actions}>{props.actions}</div>
			</div>

			<Paper rounded zDepth={3} css={styles.paper}>
				{props.header ? (
					<Fragment>
						<div css={styles.main}>{props.header}</div>
						<Divider />
					</Fragment>
				) : null}
				<div css={styles.content}>{props.children}</div>
			</Paper>
		</div>
	)
}

const styles = {
	container: {
		position: 'relative',
		marginLeft: 'auto',
		marginRight: 'auto',
		marginBottom: 50,
		maxWidth: '80%',
		width: 970,
	},
	header: {
		paddingTop: 20,
		paddingBottom: 20,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	h1: {
		fontSize: 32,
		lineHeight: '39px',
		margin: 0,
		color: colors.black,
		fontWeight: 'normal',
		flex: '1 1 100%',
	},
	actions: {
		flex: '1 1 auto',
		position: 'relative',
		fontSize: 0,
		textAlign: 'right',
	},
	paper: {
		position: 'relative',
		overflow: 'hidden',
	},
	main: {
		backgroundColor: '#F9F9F9',
		padding: 30,
		color: colors.black,
		fontWeight: 'normal',
	},
	content: {
		padding: '0 30px 30px 30px',
		marginTop: 30,
		fontSize: 14,
	},
}
