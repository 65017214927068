/* @flow */

import React, { Component } from 'react'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { userHasAccess } from 'permissions'
import type { WithRouterProps, State } from 'types'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import VerticalTab from 'components/VerticalTabs/VerticalTab'
import VerticalTabs from 'components/VerticalTabs'
import {
	settingsAccountingRoute,
	settingsCompanyRoute,
	settingsReportingRoute,
	settingsTriviSettingsRoute,
	settingsUsersRoute,
} from 'modules/settings/routing/routes'
import AccountingPage from './accounting'
import CompanyPage from '../containers/company-page'
import ReportingPage from './reporting'
import TriviSettings from 'modules/settings/containers/trivi-settings-page'
import UsersPage from './users'
import { Breadcrumbs, Breadcrumb } from 'components/breadcrumbs'
import styles from './settings-layout.css'

declare var BUILD_NUMBER: string

export type SubSectionType =
	| 'basics'
	| 'eso'
	| 'responsibility'
	| 'signatures'
	| 'eet'
	| 'extraction'
	| 'accountingSettings'
	| 'sequences'
	| 'accountingDocuments'
	| 'financialAccounts'
	| 'branches'
	| 'projects'
	| 'internal'
	| 'groups'
	| 'emails'
	| 'api'
	| 'paymentReminders'
	| 'accountingDocumentCategories'
	| 'administration'

export type SectionType = 'company' | 'accounting' | 'reporting' | 'users' | 'triviSettings'

type TabsType = { [section: SectionType]: () => string }

const TABS: TabsType = {
	company: settingsCompanyRoute,
	accounting: settingsAccountingRoute,
	reporting: settingsReportingRoute,
	users: settingsUsersRoute,
	triviSettings: settingsTriviSettingsRoute,
}

export type Props = {
	section: SectionType,
	subSection?: SubSectionType,
	canEditUsers: boolean,
	canEditProfile: boolean,
	canEditAccounting: boolean,
}

class SettingsLayout extends Component<Props & WithRouterProps & WithTranslateProps> {
	handleTabChange = (url: string) => {
		this.props.history.push(url)
	}

	filterTABS = () => {
		const tabs = {}
		Object.entries(TABS)
			.filter(([section]: any) => {
				switch (section) {
					case 'company':
						return this.props.canEditProfile
					case 'users':
						return this.props.canEditUsers
					case 'accounting':
						return this.props.canEditAccounting
					case 'reporting':
						return this.props.canEditAccounting
					default:
						return true
				}
			})
			.forEach(([section, url]: any) => {
				tabs[section] = url()
			})
		return tabs
	}

	renderTabs() {
		const { t } = this.props
		const filteredTABS = this.filterTABS()
		const initialSelectedIndex = Object.keys(filteredTABS).findIndex((item: string) => item === this.props.section)

		return (
			<div className={styles.tabs}>
				<VerticalTabs onChange={this.handleTabChange} initialSelectedIndex={initialSelectedIndex}>
					{Object.entries(filteredTABS).map(([section, url]: any) => (
						<VerticalTab
							key={section}
							value={url}
							label={t(`settings.nav.${section}`)}
							subLabel={t(`settings.nav.${section}SubTitle`)}
						/>
					))}
				</VerticalTabs>
			</div>
		)
	}

	renderTabContent() {
		switch (this.props.section) {
			case 'company':
				return <CompanyPage subSection={this.props.subSection} />
			case 'accounting':
				return <AccountingPage subSection={this.props.subSection} />
			case 'reporting':
				return <ReportingPage subSection={this.props.subSection} />
			case 'users':
				return <UsersPage subSection={this.props.subSection} />
			case 'triviSettings':
				return <TriviSettings subSection={this.props.subSection} />
			default:
				return <div>{this.props.section}</div>
		}
	}

	render() {
		const { t, section } = this.props
		return (
			<div className={styles.root}>
				<Helmet>
					<title>{`${t(`settings.nav.${section}`)} - ${t('user.settings.link')}`}</title>
				</Helmet>
				<Breadcrumbs>
					<Breadcrumb section>{t('settings.headline')}</Breadcrumb>
					<Breadcrumb>{t(`settings.nav.${section}`)}</Breadcrumb>
				</Breadcrumbs>
				<div className={styles.container}>
					{this.renderTabs()}
					<div className={styles.content}>{this.renderTabContent()}</div>
				</div>
				<div className={styles.build}>Build: {BUILD_NUMBER}</div>
			</div>
		)
	}
}

type StateProps = {|
	canEditUsers: boolean,
	canEditProfile: boolean,
	canEditAccounting: boolean,
|}

function mapStateToProps(state: State): StateProps {
	return {
		canEditUsers: userHasAccess(state, 'editUsers'),
		canEditProfile: userHasAccess(state, 'setOrganizationProfile'),
		canEditAccounting: userHasAccess(state, 'setOrganizationAccounting'),
	}
}

export default connect(mapStateToProps)(withRouter(withTranslate(SettingsLayout)))
