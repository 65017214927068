/* @flow */

import React, { Component } from 'react'
import CircularProgress from 'components/circular-progress'
import colors from 'variables/colors.css'

type Props = {|
	size: number,
	visible?: boolean,
	transparent?: boolean,
|}

export default class Loader extends Component<Props> {
	static defaultProps = {
		size: 40,
	}

	render() {
		if (!this.props.visible) return null
		const styles = getStyles(this.props)

		return (
			<div style={styles.container}>
				<CircularProgress size={this.props.size} />
			</div>
		)
	}
}

function getStyles(props: Props) {
	return {
		container: {
			backgroundColor: props.transparent ? colors.transparent : colors.white,
			opacity: 0.8,
			position: 'absolute',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			zIndex: 999,
		},
	}
}
