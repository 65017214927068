/* @flow */

import React, { Component } from 'react'
import { autoTestId } from 'utils/tests/autotest'
import { colors } from 'variables'
import type { AutoTestProps } from 'utils/tests/autotest'

export type Props = {|
	...AutoTestProps,
	/**
	 * The backgroundColor of the avatar. Does not apply to image avatars.
	 */
	backgroundColor?: string,
	/**
	 * The borderColor of the avatar.
	 */
	borderColor?: string,
	borderWidth: number,
	/**
	 * Can be used, for instance, to render a letter inside the avatar.
	 */
	children?: any,
	/**
	 * The css class name of the root `div` or `img` element.
	 */
	className?: string,
	/**
	 * The icon or letter's color.
	 */
	color?: string,
	/**
	 * The icon or letter's hover color.
	 */
	hoverColor?: string,
	/**
	 * This is the SvgIcon to be used inside the avatar.
	 */
	icon?: React$Element<any>,
	/**
	 * This is the size of the avatar in pixels.
	 */
	size: number,
	/**
	 * If passed in, this component will render an img element. Otherwise, a div will be rendered.
	 */
	src?: string,
	/**
	 * Override the inline-styles of the root element.
	 */
	style?: Object,
|}

function getStyles(props: Props) {
	const { backgroundColor, borderColor, borderWidth, color, size } = props
	const iconSize = Math.round((size * 0.65) / 2) * 2

	const styles = {
		root: {
			color: color || colors.white,
			backgroundColor: backgroundColor || colors.grey,
			border: borderColor ? `${borderWidth}px solid ${borderColor}` : 'none',
			userSelect: 'none',
			display: 'inline-flex',
			alignItems: 'center',
			justifyContent: 'center',
			fontSize: size / 2,
			borderRadius: '50%',
			height: size,
			width: size,
		},
		icon: {
			color: color || colors.white,
			width: iconSize,
			height: iconSize,
			fontSize: iconSize,
			margin: (size - iconSize) / 2,
		},
	}

	return styles
}

class Avatar extends Component<Props> {
	static defaultProps = {
		borderWidth: 1,
		size: 24,
	}

	render() {
		const styles = getStyles(this.props)

		if (this.props.src) {
			return (
				<img
					style={Object.assign(styles.root, this.props.style)}
					src={this.props.src}
					className={this.props.className}
					{...autoTestId(this.props.autoTestId)}
				/>
			)
		} else {
			return (
				<div
					style={Object.assign(styles.root, this.props.style)}
					className={this.props.className}
					{...autoTestId(this.props.autoTestId)}
				>
					{this.props.icon &&
						React.cloneElement(this.props.icon, {
							color: styles.icon.color,
							hoverColor: this.props.hoverColor,
							style: Object.assign(styles.icon, this.props.icon.props.style),
						})}
					{this.props.children}
				</div>
			)
		}
	}
}

export default Avatar
