/* @flow */

import type {
	AccountingDocumentCategory,
	Dispatch,
	State,
	AccountingDocumentCategoryUpdateRequest,
	OrganizationSettings,
	OrganizationAction,
	SettingsAction,
	AccountingDocumentListAction,
	DataGridNextAction,
} from 'types'
import {
	loadAccountingDocumentCategories,
	loadOrganizationSettings,
	updateOrganizationSettings,
	editAccountingDocumentCategory,
	deleteAccountingDocumentCategory,
	createAccountingDocumentCategory,
} from 'modules/settings/actions'
import { changeAccountingDocumentsView } from 'modules/accounting-document/actions'
import AccountingDocumentCategories from 'modules/settings/components/trivi-settings/accounting-document-categories'
import type {
	Action as AccountingDocumentCategoriesAction,
	FinishEditingAccountingDocumentCategory,
} from 'modules/settings/actions/accounting-document-categories-action-types'
import { connect } from 'react-redux'
import { getAccountingDocumentCategories } from 'modules/settings/selectors'
import { changeCurrentPage } from 'modules/data-grid-next/actions'

type StateProps = {|
	accountingDocumentCategories: ?Array<AccountingDocumentCategory>,
	organizationSettings: ?OrganizationSettings,
|}

const mapStateToProps = (state: State): StateProps => {
	return {
		accountingDocumentCategories: getAccountingDocumentCategories(state),
		organizationSettings: state.settings.organizationSettings,
	}
}

type DispatchProps = {|
	loadAccountingDocumentCategories: () => void,
	editAccountingDocumentCategory: (
		id: string,
		data: AccountingDocumentCategoryUpdateRequest,
	) => Promise<FinishEditingAccountingDocumentCategory>,
	deleteAccountingDocumentCategory: (id: string) => void,
	createAccountingDocumentCategory: (category: AccountingDocumentCategory) => void,
	loadOrganizationSettings: () => void,
	updateOrganizationSettings: (oldSettings: OrganizationSettings, newSettings: OrganizationSettings) => Promise<any>,
	resetAccountingDocumentsView: () => Promise<void>,
|}

type Action =
	| OrganizationAction
	| AccountingDocumentCategoriesAction
	| SettingsAction
	| AccountingDocumentListAction
	| DataGridNextAction

const mapDispatchToProps = (dispatch: Dispatch<Action>): DispatchProps => {
	return {
		loadAccountingDocumentCategories: () => {
			dispatch(loadAccountingDocumentCategories())
		},
		editAccountingDocumentCategory: async (id: string, data: AccountingDocumentCategoryUpdateRequest) => {
			return dispatch(editAccountingDocumentCategory(id, data))
		},
		deleteAccountingDocumentCategory: (id: string) => {
			dispatch(deleteAccountingDocumentCategory(id))
		},
		createAccountingDocumentCategory: (category: AccountingDocumentCategory) => {
			dispatch(createAccountingDocumentCategory(category))
		},
		loadOrganizationSettings: () => {
			dispatch(loadOrganizationSettings())
		},
		updateOrganizationSettings: async (oldSettings: OrganizationSettings, newSettings: OrganizationSettings) => {
			await dispatch(updateOrganizationSettings(oldSettings, newSettings))
		},
		resetAccountingDocumentsView: async (): Promise<void> => {
			await dispatch(changeCurrentPage('accountingDocuments', 0))
			await dispatch(changeAccountingDocumentsView('all'))
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountingDocumentCategories)
