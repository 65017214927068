/* @flow */
/** @jsx jsx */

import { jsx } from '@emotion/core'
import { Component, Fragment } from 'react'
import {
	withTranslate,
	withAccountingDocumentActions,
	type WithTranslateProps,
	type WithAccountingDocumentActionsProps,
} from 'wrappers'
import type {
	AccountingDocumentIssues,
	AccountingDocumentIssue,
	CountryVariantSpecific,
	AccountingDocumentAssignedDirection,
	// EETOperation,
} from 'types'
import confirmStateChange from 'components/confirm-dialog'
import DocumentPreviewDialog from '../accounting-document-list/document-preview-dialog'
// import { isEetSent } from 'modules/accounting-document/domain/eet'
import ButtonSelect from 'components/raised-button-select'
import MenuItem from 'components/menu-item'
import Tooltip from 'components/tooltip'

type Props = {|
	...WithTranslateProps,
	...WithAccountingDocumentActionsProps,
	saveAndStopExtraction: ?boolean,
	possibleStates: ?Array<string>,
	loading: boolean,
	storing: boolean,
	issues: ?AccountingDocumentIssues,
	countryVariantSpecific: ?CountryVariantSpecific,
	direction: AccountingDocumentAssignedDirection,
	isInternal: boolean,
	accountingDocumentId: string,
	showConfirmDialog: boolean,
	useBlueStyle?: boolean,
	loadPossibleStates: () => void,
	onStateChange: (state: string) => Promise<any>,
	validateAccountingDocument: (accountingDocumentId: string, state: string) => Promise<boolean>,
	// changeEET: (operation: EETOperation) => void,
|}

type State = {|
	clicked: boolean,
	isConfirmDialogOpen: boolean,
|}

class StateChanger extends Component<Props, State> {
	state = {
		clicked: false,
		isConfirmDialogOpen: false,
	}

	componentDidMount() {
		this.props.loadPossibleStates()
	}

	componentDidUpdate(prevProps: Props) {
		if (prevProps.accountingDocumentId !== this.props.accountingDocumentId) {
			this.props.loadPossibleStates()
		}

		if (prevProps.storing && !this.props.storing && this.state.clicked) {
			this.processClick()
		}
	}

	/**
	 * return false, if eet is not sent and document can be edited
	 */
	// checkStateChangeValidity = () => {
	// 	const { possibleStates } = this.props
	// 	const isEditAction = possibleStates && possibleStates[0].toLocaleLowerCase() === 'fixing'
	// 	// const isSendEetAction = isEetSent(this.props.countryVariantSpecific)
	// 	return isEditAction //&& isSendEetAction
	// }

	processClick = async () => {
		const issues = (this.props.issues && this.props.issues.issues) || []
		const connectedIssues = (this.props.issues && this.props.issues.connectedIssues) || []
		if (this.compareSeverity([...issues, ...connectedIssues], 250)) {
			confirmStateChange(this.props.t('dialogs.changeStateQuestion'), {
				okLabel: this.props.t('dialogs.yesOption'),
			}).then(() => {
				this.proceedStateChange()
			})
		}
		// else if (this.checkStateChangeValidity()) {
		// 	confirmStateChange(this.props.t('dialogs.documentStateChange'), {
		// 		okLabel: this.props.t('dialogs.documentStateChangeYes'),
		// 		cancelLabel: this.props.t('dialogs.documentStateChangeNo'),
		// 	}).then(
		// 		// () => {
		// 		// 	this.props.changeEET('cancel_eet')
		// 		// 	this.proceedStateChange()
		// 		// },
		// 		() => this.proceedStateChange(),
		// 	)
		// }
		else {
			this.proceedStateChange()
		}
		this.setState({ clicked: false })
	}

	onPrimaryStateClick = () => {
		if (this.props.storing) {
			this.setState({ clicked: true })
		} else {
			this.processClick()
		}
	}

	onOtherStateClick = (event: SyntheticEvent<HTMLElement>, item: any) => {
		this.props.onStateChange(item.key)
	}

	renderOtherItems = () => {
		const { possibleStates, t } = this.props
		if (!possibleStates || possibleStates.length <= 1) return null
		const states: Array<string> = possibleStates ? possibleStates.slice(1) : []
		return (
			states.map((state: string) => {
				return <MenuItem key={state} primaryText={t('accountingDocument.states.' + state.toLowerCase()) || state} />
			}) || null
		)
	}

	compareSeverity = (issues: Array<AccountingDocumentIssue>, severity: number): boolean => {
		return issues.some((i: AccountingDocumentIssue) => i.severity && i.severity >= severity)
	}

	proceedStateChange = async () => {
		if (!this.props.possibleStates) return null
		const primaryState = this.props.possibleStates[0]

		if (!this.props.isInternal && 'Fixing' === primaryState) {
			confirmStateChange(this.props.t('dialogs.nonInternalChangeToFixingTitle'), {
				body: this.props.t('dialogs.nonInternalChangeToFixingBody'),
				okLabel: this.props.t('dialogs.nonInternalChangeToFixingYesOption'),
				cancelLabel: this.props.t('dialogs.goBackOption'),
			}).then(() => {
				this.props.onStateChange(primaryState)
			})
		} else if (this.props.showConfirmDialog && 'Processed' === primaryState) {
			const valid = await this.props.validateAccountingDocument(this.props.accountingDocumentId, primaryState)
			if (true === valid) {
				this.openConfirmDialog()
			}
		} else {
			this.props.onStateChange(primaryState)
		}
	}

	openConfirmDialog = () => this.setState({ isConfirmDialogOpen: true })
	closeConfirmDialog = () => this.setState({ isConfirmDialogOpen: false })

	onConfirmDocument = () => {
		this.closeConfirmDialog()
		this.props.onStateChange('Processed')
	}

	render() {
		const { t, possibleStates, saveAndStopExtraction } = this.props
		if (!possibleStates || !possibleStates.length) return null

		const state = possibleStates[0]
		const labelText = saveAndStopExtraction
			? t('accountingDocument.columns.stateSaveAndFinish')
			: t('accountingDocument.states.' + state.toLowerCase()) || state

		return (
			<Fragment>
				<div css={style.root}>
					<Tooltip
						label={!saveAndStopExtraction && t('accountingDocument.stateTooltips.' + state.toLowerCase())}
						inline
					>
						<ButtonSelect
							disabled={this.props.loading || this.state.clicked}
							onClick={this.onPrimaryStateClick}
							onItemClick={this.onOtherStateClick}
							autoTestId="state-changer-button"
							labelText={labelText}
							useBlueStyle={state === 'Extracted'}
						>
							{this.renderOtherItems()}
						</ButtonSelect>
					</Tooltip>

					<DocumentPreviewDialog
						open={this.state.isConfirmDialogOpen}
						onClose={this.closeConfirmDialog}
						onConfirm={this.onConfirmDocument}
						accountingDocumentId={this.props.accountingDocumentId}
					/>
				</div>
			</Fragment>
		)
	}
}

const style = {
	root: {
		display: 'block',
		lineHeight: 1,
	},
}

export default withTranslate(withAccountingDocumentActions(StateChanger))
