// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OpjdyhkIGBQs6xzuVXFe5 {\n\ttext-align: left;\n\tfont-weight: normal;\n\tmargin: 10px 8px;\n\tborder-collapse: collapse;\n}\n\n.OpjdyhkIGBQs6xzuVXFe5 tr {\n\theight: 20px;\n\tline-height: 20px;\n\tborder: none;\n\tpadding: 0;\n}\n\n.OpjdyhkIGBQs6xzuVXFe5 th {\n\tfont-size: 12px;\n\tfont-weight: normal;\n\tpadding: 0 12px;\n}\n\n.OpjdyhkIGBQs6xzuVXFe5 td {\n\tpadding: 0 12px;\n}\n\n.OpjdyhkIGBQs6xzuVXFe5 th:first-child, .OpjdyhkIGBQs6xzuVXFe5 td:first-child {\n\tborder-right: 1px solid whiteFaded10;\n}\n", "",{"version":3,"sources":["webpack://./modules/internal/components/statistics-grid/statements-summary.css"],"names":[],"mappings":"AAAA;CACC,gBAAA;CACA,mBAAA;CACA,gBAAA;CACA,yBAAA;AACD;;AAEA;CACC,YAAA;CACA,iBAAA;CACA,YAAA;CACA,UAAA;AACD;;AAEA;CACC,eAAA;CACA,mBAAA;CACA,eAAA;AACD;;AAEA;CACC,eAAA;AACD;;AAEA;CACC,oCAAA;AACD","sourcesContent":[".tooltipTable {\n\ttext-align: left;\n\tfont-weight: normal;\n\tmargin: 10px 8px;\n\tborder-collapse: collapse;\n}\n\n.tooltipTable tr {\n\theight: 20px;\n\tline-height: 20px;\n\tborder: none;\n\tpadding: 0;\n}\n\n.tooltipTable th {\n\tfont-size: 12px;\n\tfont-weight: normal;\n\tpadding: 0 12px;\n}\n\n.tooltipTable td {\n\tpadding: 0 12px;\n}\n\n.tooltipTable th:first-child, .tooltipTable td:first-child {\n\tborder-right: 1px solid whiteFaded10;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tooltipTable": "OpjdyhkIGBQs6xzuVXFe5"
};
export default ___CSS_LOADER_EXPORT___;
