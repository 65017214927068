/* @flow */

import type { AccountingDocumentExtractionStats, ScanFactoryDocumentFileMetadata, ScanFactoryField } from 'types'

export type ExtractionDocument = $ReadOnly<{
	metadata: ScanFactoryDocumentFileMetadata,
	fieldCursor: FieldCursor,
	fieldEditing: boolean,
	errors?: ExtractionErrors,
}>

export type FieldCursor = $ReadOnly<{| name: string, index: number, section: string |}>
export type BbWordCursor = $ReadOnly<{| pageIndex: number, bbWordIndex: number |}>

export type DocumentExtractionState = $ReadOnly<{|
	extractionDocument?: ExtractionDocument,
	extractionStats?: AccountingDocumentExtractionStats,
	previewWidth?: number,
	saving?: boolean,
	loading?: boolean,
|}>

export const initialState = {}

export type FieldSection = {| name: string, isRepeatable: boolean |}
export type FieldSections = Array<FieldSection>

export type BbWordFieldMap = {
	[bbWordKey: string]: Array<FieldCursor>,
}

export type FieldBbWordMap = {
	[fieldKey: string]: Array<BbWordCursor>,
}

export type AutoFillMap = Array<AutoFillEntry>
export type AutoFillEntry = {
	fieldName: string,
	filling: { [fillingFieldName: string]: (data: any) => ?string },
	load: (field: ScanFactoryField) => Promise<void>,
	get: (field: ScanFactoryField) => any,
}

export type ExtractionErrors = {
	[fieldKey: string]: string,
}

export type ExtractionStatParameters = { [key: string]: any }
