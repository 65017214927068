/* @flow */

import type { Dispatch, State } from 'types'

function eventMiddleware(listeners: Array<Listener>) {
	const dispatcher = new EventDispatcher(listeners)

	return (store: any) => (next: any) => (action: any) => {
		let result = next(action)

		dispatcher.dispatch(action, store.getState, store.dispatch)

		return result
	}
}

export type Action = { type: string }

export type Listener = {|
	actionType: string,
	listener: (action: Action, getState: () => State, dispatch: Dispatch<Action>) => void,
|}

export class EventDispatcher {
	listeners: Array<Listener>

	constructor(listeners: Array<Listener>) {
		this.listeners = listeners
	}

	dispatch(action: Action, getState: () => State, dispatch: Dispatch<Action>) {
		const listeners = this.findListeners(action)

		listeners.forEach((listener: Listener) => listener.listener(action, getState, dispatch))
	}

	findListeners(action: Action): Array<Listener> {
		return this.listeners.filter((listener: Listener) => listener.actionType === action.type)
	}
}

export default eventMiddleware
