/* @flow */

import { connect } from 'react-redux'
import Tracking from 'utils/tracking'
import StateChanger from 'modules/accounting-document/components/invoice-elements/state-changer'
import type {
	State,
	Dispatch,
	AccountingDocumentReduxAction,
	AccountingDocumentListAction,
	WithRouterProps,
	AccountingDocumentIssues,
	CountryVariantSpecific,
	// EETOperation,
	AccountingDocumentAssignedDirection,
} from 'types'
import {
	changeAccountingDocumentState,
	loadAccountingDocumentPossibleStates,
	fetchNextAccountingDocumentToExtract,
	// countryVariantUpdate,
	resetAccountingDocumentsDirection,
	loadAccDocGreenboxSuggestion,
} from 'modules/accounting-document/actions'
import type { FinishFetchingNextAccountingDocumentToExtract } from 'modules/accounting-document/actions/accounting-document-action-types'
import {
	getAccountingDocumentCountryVariantSpecific,
	getAccountingDocumentIssues,
	getAccountingDocumentItem,
	getPreProcessingAccountingDocumentPreview,
} from 'modules/accounting-document/selectors'
import type { AccountingDocumentItem } from 'modules/accounting-document/reducers/accounting-documents'
import {
	extractAccountingDocumentRoute,
	editAccountingDocumentRoute,
	showAccountingDocumentRoute,
	extractDashboardAccountingDocumentRoute,
} from 'modules/accounting-document/routing/routes'
import { push } from 'react-router-redux'
import { withRouter } from 'react-router-dom'
import { internalDashboardRoute } from 'modules/internal/routing/routes'
import { currentUserIsInternal, getCurrentOrganizationId } from 'modules/user/selectors'
import { isAccDocValid } from 'modules/accounting-document/domain/accounting-document'
import { getIsExtractionQueue } from 'modules/accounting-document/helpers'
import { cashRegisterRoute } from 'modules/cash-register/routing/routes'

type StateProps = {|
	storing: boolean,
	loading: boolean,
	possibleStates: ?Array<string>,
	issues: ?AccountingDocumentIssues,
	countryVariantSpecific: ?CountryVariantSpecific,
	organizationId: ?string,
	isInternal: boolean,
	showConfirmDialog: boolean,
|}

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => {
	const item: ?AccountingDocumentItem = getAccountingDocumentItem(state, ownProps.accountingDocumentId)
	const isInternal = currentUserIsInternal(state)

	return {
		possibleStates: item && item.possibleStates,
		storing: !!(item && item.storing),
		loading: !!(item && item.loading),
		issues: getAccountingDocumentIssues(state, ownProps.accountingDocumentId),
		countryVariantSpecific: getAccountingDocumentCountryVariantSpecific(state, ownProps.accountingDocumentId),
		showConfirmDialog: getPreProcessingAccountingDocumentPreview(state),
		organizationId: getCurrentOrganizationId(state),
		isInternal,
	}
}

type DispatchProps = {|
	onStateChange: (state: string) => Promise<any>,
	loadPossibleStates: () => void,
	validateAccountingDocument: (accountingDocumentId: string, state: string) => Promise<boolean>,
	// changeEET: (operation: EETOperation) => void,
|}

const mapDispatchToProps = (
	dispatch: Dispatch<AccountingDocumentReduxAction | AccountingDocumentListAction>,
	ownProps: OwnProps,
): DispatchProps => {
	return {
		onStateChange: async (state: string) => {
			if (ownProps.onStateChange) await ownProps.onStateChange(state)
			const response = await dispatch(changeAccountingDocumentState(ownProps.accountingDocumentId, state))
			const editAccDocRoute = editAccountingDocumentRoute(
				ownProps.accountingDocumentId,
				ownProps.organizationId,
				'edit',
			)
			const isExtractionQueue = getIsExtractionQueue(ownProps)
			const isEditPage = window.location.href.split('/').pop() == 'edit'
			if (state !== 'Extracting' && isEditPage) {
				dispatch(loadAccDocGreenboxSuggestion(ownProps.accountingDocumentId))
			}

			if (state === 'Extracted') {
				Tracking.trackStateChangeToExtracted(ownProps.accountingDocumentId, isExtractionQueue)

				if (!isExtractionQueue) {
					// do nothing. (I can revert condition because extract route needs organizationId and I don't know it here)
					return
				}

				if (ownProps.saveAndStopExtraction) {
					dispatch(push(internalDashboardRoute()))
					return
				}

				const resp: FinishFetchingNextAccountingDocumentToExtract = await dispatch(
					fetchNextAccountingDocumentToExtract(ownProps.accountingDocumentId),
				)

				const id: ?string = resp && resp.result && resp.result.accountingDocumentId
				const organizationId: ?string = resp && resp.result && resp.result.organizationId

				if (!id) {
					dispatch(push(internalDashboardRoute()))
					return
				}

				if (ownProps.repeatExtraction) {
					if (id && organizationId) {
						dispatch(push(extractDashboardAccountingDocumentRoute(id, organizationId)))
					}
				} else {
					id && dispatch(push(extractAccountingDocumentRoute(id)))
				}

				if (state === 'Fixing' && isExtractionQueue) {
					dispatch(push(editAccDocRoute))
					Tracking.trackStateChangeToFixing(ownProps.accountingDocumentId)
				}

				if (response && !response.serverError && !response.validationErrorAccDoc && state === 'Processed') {
					if (ownProps.cashRegisterId) {
						dispatch(push(cashRegisterRoute(ownProps.cashRegisterId)))
					} else {
						dispatch(resetAccountingDocumentsDirection())
						dispatch(push(showAccountingDocumentRoute(ownProps.accountingDocumentId, ownProps.organizationId)))
					}
				}
			} else {
				if (response && !response.serverError && !response.validationErrorAccDoc && state === 'Processed') {
					dispatch(resetAccountingDocumentsDirection())
					dispatch(push(showAccountingDocumentRoute(ownProps.accountingDocumentId, ownProps.organizationId)))
				}
			}
		},
		loadPossibleStates: () => {
			dispatch(loadAccountingDocumentPossibleStates(ownProps.accountingDocumentId))
		},
		validateAccountingDocument: async (accountingDocumentId: string, state: string): Promise<boolean> =>
			isAccDocValid(accountingDocumentId, state, dispatch),
		// changeEET: (operation: EETOperation) => {
		// 	dispatch(countryVariantUpdate(ownProps.accountingDocumentId, { cz: { eet: { operation } } }))
		// },
	}
}

type OwnProps = {|
	...WithRouterProps,
	accountingDocumentId: string,
	repeatExtraction: ?boolean,
	saveAndStopExtraction: ?boolean,
	cashRegisterId: ?string,
	direction: AccountingDocumentAssignedDirection,
	onStateChange?: (state: string) => Promise<any>,
|}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StateChanger))
