/* @flow */

import React, { Component } from 'react'
import type { InternalUser } from 'types'
import { withNotify, withTranslate, type WithNotifyProps, type WithTranslateProps } from 'wrappers'
import DataGrid from 'modules/data-grid/containers/data-grid'
import { internalUsers } from 'modules/common/models/api-model'
import { TriviLink } from 'components'
import DeleteDialog from 'components/delete-dialog'
import DeleteIcon from 'components/svg-icons/action/delete'
import EditIcon from 'components/svg-icons/image/edit'

const TRANSLATION_PREFIX = 'internalUsers.'

const TRANSLATIONS = {
	email: TRANSLATION_PREFIX + 'email',
	firstname: TRANSLATION_PREFIX + 'firstname',
	lastname: TRANSLATION_PREFIX + 'lastname',
	permissions: TRANSLATION_PREFIX + 'permissions',
	intenalUsersHeader: TRANSLATION_PREFIX + 'header',
	editAction: TRANSLATION_PREFIX + 'editAction',
	deleteAction: TRANSLATION_PREFIX + 'deleteAction',
	deleteActionSuccess: TRANSLATION_PREFIX + 'deleteActionSuccess',
	userSaved: TRANSLATION_PREFIX + 'userSaved',
}

type RendererArgType = {|
	column: any,
	value: string,
	row: InternalUser,
|}

type Props = {|
	...WithNotifyProps,
	...WithTranslateProps,
	onEdit: (user: InternalUser) => void,
	removeInternalUser: (id: string) => Promise<void>,
	getRefresh: (refresh: Function) => void, //HACK
|}

class InternalUsersGrid extends Component<Props> {
	gridRefresh: Function = () => {}

	getColumns() {
		const { t } = this.props
		return {
			email: {
				name: t(TRANSLATIONS.email),
				dataIndex: 'email',
				width: '',
				renderer: ({ row }: RendererArgType) => (
					//eslint-disable-next-line react/jsx-no-bind
					<TriviLink key={row.id} onClick={() => this.props.onEdit(row)}>
						{row.email || ''}
					</TriviLink>
				),
			},
			firstname: {
				name: t(TRANSLATIONS.firstname),
				dataIndex: 'firstname',
				width: '',
			},
			lastname: {
				name: t(TRANSLATIONS.lastname),
				dataIndex: 'lastname',
				width: '',
			},
			permissions: {
				name: t(TRANSLATIONS.permissions),
				dataIndex: 'permissions',
				width: '',
				renderer: ({ row }: RendererArgType) => (
					<span>
						{row.permissions &&
							row.permissions.map((permission: string) => t(`user.permission.${permission}`)).join(', ')}
					</span>
				),
			},
		}
	}

	render() {
		const { t, removeInternalUser } = this.props
		const { email, firstname, lastname, permissions } = this.getColumns()

		let columns = [email, firstname, lastname, permissions]

		const actions = [
			{
				name: t(TRANSLATIONS.editAction),
				type: 'edit_user',
				primary: true,
				icon: <EditIcon />,
			},
			{
				name: t(TRANSLATIONS.deleteAction),
				type: 'delete_user',
				primary: true,
				icon: <DeleteIcon />,
			},
		]

		const onAction = (action: string, user: InternalUser) => {
			switch (action) {
				case 'edit_user':
					this.props.onEdit(user)
					break
				case 'delete_user':
					DeleteDialog().then(
						() => {
							removeInternalUser(user.id || '').then(this.gridRefresh)
							this.props.notify(t(TRANSLATIONS.deleteActionSuccess, { user: user.email }), 'success')
						},
						() => {
							//this.props.notify()
						},
					)
					break
			}
		}

		const gridConf = {
			getRefresh: (refresh: Function) => {
				//HACK
				this.gridRefresh = refresh
				this.props.getRefresh(refresh)
			},
			columns,
			actions,
			onAction,
			stateKey: 'internalUsersGrid',
			apiFunction: internalUsers.get,
			dataFieldName: '', //internalUsers
		}

		// $FlowFixMe Stávající datagid funguje pouze s edpointy na vyhledávání
		return <DataGrid {...gridConf} pageSize={5} border />
	}
}
export default withTranslate(withNotify(InternalUsersGrid))
