/* @flow */
/* eslint-disable react/no-multi-comp */

import React from 'react'
import moize from 'moize'
import memoize from 'memoize-one'
import type { AccountingDocument, AccountingDocumentLineItem } from 'types'
import { type WithTranslateProps } from 'wrappers'
import { accountingDocumentNumberRaw } from 'helpers'
import { formatToDateString } from 'utils/formatters'
import { Column } from 'modules/data-grid-next/components'
import type { Direction } from 'modules/data-grid-next/types'
import AccountingDocumentGridTotalColumn from '../accounting-document-list/grid-total-column'
import EditIcon from 'components/svg-icons/image/edit'
import Tooltip from 'components/tooltip'
import LocalOfferIcon from 'components/svg-icons/maps/local-offer'
import CreditCard from 'components/svg-icons/action/credit-card'
import AccountingDocumentSentIcon from '../accounting-document-sent-icon'
import { DATA_GRID_CACHE_SIZE } from 'trivi-constants'
import { colors } from 'variables'

export type Props = {|
	width?: number | string,
	defaultSortDirection?: Direction,
	onClick?: (accountingDocument: AccountingDocument, isAccounting?: boolean) => void,
	forceGoOnEditRoute: boolean,
	isAccounting: ?boolean,
	...WithTranslateProps,
|}

const getStyles = memoize((forceGoOnEditRoute: ?boolean) => {
	return {
		row: {
			overflow: 'hidden',
			display: 'flex',
			flexWrap: 'nowrap',
			justifyContent: 'flex-start',
			alignItems: 'flex-start',
			lineHeight: '20px',
		},
		idVS: {
			overflow: 'hidden',
			flex: '0 0 auto',
			textAlign: 'left',
			minHeight: 20,
		},
		nameNote: {
			overflow: 'hidden',
			flex: '1 1 auto',
			textAlign: 'left',
			paddingLeft: 15,
		},
		id: {
			fontSize: 14,
			fontWeight: 600,
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',
			flex: '0 0 auto',
			minHeight: 20,
		},
		name: {
			fontSize: 14,
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',
			flex: '1 1 auto',
			minHeight: 20,
			paddingRight: forceGoOnEditRoute ? 20 : 0,
		},
		vs: {
			fontSize: 12,
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',
			flex: '0 0 auto',
			minHeight: 20,
		},
		note: {
			fontSize: 12,
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',
			flex: '1 1 auto',
			minHeight: 20,
			paddingRight: forceGoOnEditRoute ? 20 : 0,
		},
		duzp: {
			fontSize: 12,
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',
			flex: '0 0 auto',
			textAlign: 'left',
		},
		total: {
			overflow: 'hidden',
			flex: '1 1 auto',
			textAlign: 'right',
			margin: '-2px 0 -2px 0',
		},
		link: {
			display: 'block',
			cursor: 'pointer',
			color: 'initial',
			textDecoration: 'none',
		},
		editIcon: {
			position: 'absolute',
			display: 'block',
			top: 13,
			right: 18,
		},
		icons: {
			position: 'absolute',
			display: 'flex',
			flexDirection: 'column',
			top: -1,
			right: 0,
		},
		icon: {
			border: `1px solid ${colors.gray400}`,
			borderRadius: '0 3px 3px 0',
			background: colors.white,
			transform: 'translateX(100%)',
			padding: '2px 3px 2px 4px',
			marginBottom: 2,
		},
		tooltip: { marginLeft: 3 },
		blockIcon: { display: 'block' },
	}
})

export default function getCompactInfoColumn(props: Props) {
	function columnRenderer(value: string, row: AccountingDocument) {
		function clickHandler() {
			if (!props.isAccounting) {
				props.onClick && props.onClick(row, true)
			} else {
				props.onClick && props.onClick(row)
			}
		}

		const companyName: string =
			(row.contact && row.contact.companyName) ||
			(row.contact && (row.contact.firstname || '') + ' ' + (row.contact.lastname || '')) ||
			''

		const firstLineItem: ?AccountingDocumentLineItem =
			row.lineItems && row.lineItems.length > 0 ? row.lineItems[0] : null
		const taxDateLabel = `${props.t('accountingDocument.columns.taxDate')}: `

		const styles = getStyles(props.forceGoOnEditRoute)

		const hasPaymentOrders = row && row.hasPaymentOrders

		return (
			<div>
				<div onClick={clickHandler} style={styles.link}>
					<div style={styles.row}>
						<div style={styles.idVS}>
							<div style={styles.id}>{accountingDocumentNumberRaw(row)}</div>
							<div style={styles.vs}>{row.variableSymbol}</div>
							{row.taxDate && (
								<div style={styles.duzp}>
									{taxDateLabel}
									{formatToDateString(row.taxDate)}
								</div>
							)}
						</div>
						<div style={styles.nameNote}>
							<div style={styles.name}>{companyName}</div>
							<div style={styles.note}>{firstLineItem ? firstLineItem.description : ''}</div>
							<div style={styles.total}>
								<AccountingDocumentGridTotalColumn compact accountingDocument={row} />
							</div>
						</div>
						{props.forceGoOnEditRoute && (
							<div style={styles.editIcon}>
								<Tooltip label={props.t('accountingDocument.gridActions.edit')} inline>
									<EditIcon size={18} color={colors.gray} />
								</Tooltip>
							</div>
						)}
					</div>
				</div>
				<div style={styles.icons}>
					{(row.customerInstructions || '').trim() && (
						<Tooltip
							wrapperStyle={styles.icon}
							style={styles.tooltip}
							align={'bottom-left'}
							arrowAlign={'top-left'}
							label={row.customerInstructions}
							multiLine
						>
							<LocalOfferIcon style={styles.blockIcon} hoverColor={colors.black} size={16} />
						</Tooltip>
					)}
					{hasPaymentOrders && (
						<Tooltip
							wrapperStyle={styles.icon}
							style={styles.tooltip}
							align={'bottom-left'}
							arrowAlign={'top-left'}
							label={props.t('accountingDocument.gridTotalColumn.paymentOrdersInfo')}
						>
							<CreditCard style={styles.blockIcon} disabled size={16} />
						</Tooltip>
					)}
					<AccountingDocumentSentIcon accountingDocument={row} style={styles.icon} />
				</div>
			</div>
		)
	}

	const columnRendererCashed = moize(columnRenderer, { maxSize: DATA_GRID_CACHE_SIZE })

	return (
		<Column
			width={props.width}
			columnId="accountingDocumentNo"
			key="compactInfo"
			render={columnRendererCashed}
			sortable
			defaultSortDirection={props.defaultSortDirection || undefined}
		/>
	)
}
