/* @flow */

import React from 'react'
import type { FormFieldErrorMessage, FormFieldErrorContext } from 'types'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import { getTooltipErrorLabel } from 'helpers'
import TextField from 'components/TextField'
import { colors } from 'variables'

type Props = {|
	...WithTranslateProps,
	title: string,
	placeholder?: string,
	errors?: ?Array<FormFieldErrorMessage>,
	formFieldErrorContext?: FormFieldErrorContext,
	onChange?: (title: ?string) => void,
	removeError?: () => void,
|}

function Name(props: Props) {
	function onChange(event: SyntheticInputEvent<HTMLInputElement>, input: ?string) {
		props.onChange && props.onChange(input)
		props.removeError && props.removeError()
	}

	return (
		<TextField
			inline
			autoWidth
			autoFocus
			name="title"
			style={style}
			value={props.title}
			onChange={onChange}
			disabled={!props.onChange}
			hintText={props.placeholder}
			clientError={getTooltipErrorLabel(props.errors, props.t)}
		/>
	)
}

const style = {
	margin: 0,
	fontSize: 24,
	color: colors.black,
	fontWeight: 'normal',
	lineHeight: 'inherit',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
}

export default withTranslate(Name)
