/* @flow */

import styles from './side-button.css'
import React, { Component, type Node } from 'react'

export type Props = {|
	onClick?: () => void,
	children: Node,
|}

export default class SideButton extends Component<Props> {
	render() {
		const extraStyle = {
			cursor: this.props.onClick ? 'pointer' : undefined,
		}

		return (
			<div onClick={this.props.onClick} className={styles.container} style={extraStyle}>
				{this.props.children}
			</div>
		)
	}
}
