/* @flow */

import React, { Component } from 'react'
import EnhancedButton from 'components/internal/EnhancedButton'
import { colors } from 'variables'

type Props = {|
	labelText: string,
	onClick: () => void,
|}

type State = {|
	hovered: boolean,
|}

class SettingsButton extends Component<Props, State> {
	state = {
		hovered: false,
	}

	handleMouseLeave = () => {
		this.setState({
			hovered: false,
		})
	}

	handleMouseEnter = () => {
		this.setState({
			hovered: true,
		})
	}

	render() {
		const styles = getStyles(this.props, this.state)

		return (
			<EnhancedButton
				onClick={this.props.onClick}
				onMouseLeave={this.handleMouseLeave}
				onMouseEnter={this.handleMouseEnter}
				style={styles.root}
			>
				<span style={styles.label}>{this.props.labelText}</span>
			</EnhancedButton>
		)
	}
}

function getStyles(props: Props, state: State) {
	return {
		root: {
			display: 'inline-block',
			transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1)',
			borderStyle: 'solid',
			borderWidth: 1,
			overflow: 'hidden',
			height: 30,
			lineHeight: '28px',
			boxShadow: `2px 2px 0 ${colors.blackFaded8}`,
			textAlign: 'center',
			borderRadius: 3,
			backgroundColor: state.hovered ? colors.blackFaded3 : colors.white,
			borderColor: colors.grey300,
		},
		label: {
			position: 'relative',
			verticalAlign: 'middle',
			whiteSpace: 'nowrap',
			opacity: 1,
			fontSize: 12,
			letterSpacing: 0,
			textTransform: 'uppercase',
			fontWeight: 'bold',
			margin: 0,
			userSelect: 'none',
			color: colors.black,
			paddingLeft: 24,
			paddingRight: 24,
		},
	}
}

export default SettingsButton
