/* @flow */

import React, { type Node } from 'react'
import memoize from 'memoize-one'
import type { Bank } from 'types'
import { type AutoTestProps, passAutoTestId } from 'utils/tests/autotest'
import SelectNext, { type OptionsType, type OptionType, type ValueType } from 'components/select-next'

type Props = {|
	...AutoTestProps,
	value: ?string,
	bankAccounts: ?Array<Bank>,
	isCreatable?: boolean,
	hintText?: string,
	labelText?: string,
	disabled?: boolean,
	clientError?: Node,
	inline?: boolean,
	autoWidth?: boolean,
	onChange: (code: string) => void,
	loadBanks: () => void,
	noPortal?: boolean,
|}

class BankCodeAutocomplete extends React.Component<Props> {
	UNSAFE_componentWillMount() {
		!this.props.bankAccounts && !this.props.disabled && this.props.loadBanks && this.props.loadBanks()
	}

	onChange = (option: ValueType) => {
		option && !Array.isArray(option) && option.value && this.props.onChange(option.value)
	}

	formatOptionLabel = (option: OptionType) => option.value

	getOptions = memoize((bankAccounts?: ?Array<Bank>): OptionsType => {
		if (!bankAccounts) return []
		return bankAccounts.map((account: Bank): OptionType => {
			return {
				label: account.name,
				value: account.bankCode,
				labelPrefix: account.bankCode,
			}
		})
	})

	render() {
		return (
			<SelectNext
				isSearchable
				hideIndicator={this.props.inline}
				isCreatable={this.props.isCreatable}
				placeholder={this.props.hintText}
				label={this.props.labelText}
				error={this.props.clientError}
				autoWidth={this.props.autoWidth}
				disabled={this.props.disabled}
				inline={this.props.inline}
				onChange={this.onChange}
				options={this.getOptions(this.props.bankAccounts)}
				formatOptionLabel={this.formatOptionLabel}
				value={this.props.value}
				{...passAutoTestId(this.props.autoTestId)}
				portal={!this.props.noPortal}
			/>
		)
	}
}

export default BankCodeAutocomplete
