import React from 'react'
import pure from 'recompose/pure'
import SvgIcon from 'components/SvgIcon'

let Red = props => (
	<SvgIcon {...props}>
		<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<mask fill="white">
				<path d="M13.2631579,12 L16,9.26315789 L14.7368421,8 L12,10.7368421 L9.26315789,8 L8,9.26315789 L10.7368421,12 L8,14.7368421 L9.26315789,16 L12,13.2631579 L14.7368421,16 L16,14.7368421 L13.2631579,12 Z M4,12 C4,7.581722 7.59071231,4 12,4 C16.418278,4 20,7.59071231 20,12 C20,16.418278 16.4092877,20 12,20 C7.581722,20 4,16.4092877 4,12 Z" />
			</mask>
			<path
				d="M13.2631579,12 L16,9.26315789 L14.7368421,8 L12,10.7368421 L9.26315789,8 L8,9.26315789 L10.7368421,12 L8,14.7368421 L9.26315789,16 L12,13.2631579 L14.7368421,16 L16,14.7368421 L13.2631579,12 Z M4,12 C4,7.581722 7.59071231,4 12,4 C16.418278,4 20,7.59071231 20,12 C20,16.418278 16.4092877,20 12,20 C7.581722,20 4,16.4092877 4,12 Z"
				fill="rgba(225, 29, 59, 0.6)"
			/>
		</g>
	</SvgIcon>
)
Red = pure(Red)

export default Red
