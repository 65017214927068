// @flow

/*
	https://github.com/sindresorhus/multi-download
 */

const delay = (ms: number) => new Promise((resolve: any) => setTimeout(resolve, ms))

const download = async (url: string, name: string) => {
	const a = document.createElement('a')
	a.download = name
	a.href = url
	a.style.display = 'none'
	document.body && document.body.appendChild(a)
	a.click()
	await delay(100)
	document.body && document.body.removeChild(a)
}

export async function taskDelay(ms: number) {
	await delay(ms)
}

export async function multiDownload(urls: Array<string>, options: any = {}) {
	for (const [index, url] of urls.entries()) {
		const name =
			options && options.rename && typeof options.rename === 'function' ? options.rename({ url, index, urls }) : ''

		await delay(index * 1000)
		await download(url, name)
	}
}
