/* @flow */

import MenuItem from 'components/menu-item'
import SelectField from 'components/select-field'
import React, { Component } from 'react'
import { getIntervalName, intervalToDates } from 'utils/formatters/date-formatter'
import { withTranslate, type WithTranslateProps } from 'wrappers'

export type Interval = {|
	interval: 'year' | 'month' | 'quarter',
	count: number,
	shift: number,
	initialOffset?: 0 | 1,
|}

export type Intervals = Array<Interval>

export type Props = {|
	value?: number,
	intervals?: Intervals,
	onChange?: (event: Event, index: number, { interval: Interval, dateFrom: Date, dateTo: Date }) => void,
	showLabel?: boolean,
	label?: string,
|}

class IntervalSelector extends Component<{| ...Props, ...WithTranslateProps |}> {
	static defaultProps = {
		intervals: [],
		showLabel: true,
	}

	onChange = (event: Event, index: number, value: number) => {
		if (!this.props.intervals || !this.props.intervals[value]) {
			return
		}
		const interval = this.props.intervals[value]
		const dates = intervalToDates(interval)
		this.props.onChange && this.props.onChange(event, value, { interval, dateFrom: dates[0], dateTo: dates[1] })
	}

	renderIntervals(intervals: Intervals) {
		return intervals.map((interval: Interval, index: number) => {
			const label = getIntervalName(interval)
			return (
				<MenuItem
					key={index}
					value={index}
					primaryText={this.props.t('application.interval.' + label.name, { ...label.params, postProcess: 'interval' })}
				/>
			)
		})
	}

	render() {
		return (
			<SelectField
				autoTestId="interval-selector"
				labelText={this.props.showLabel && (this.props.label || this.props.t('application.interval.label'))}
				value={this.props.value}
				onChange={this.onChange}
				inline
			>
				{this.renderIntervals(this.props.intervals || [])}
			</SelectField>
		)
	}
}

export default withTranslate(IntervalSelector)
