/* @flow */

import React, { Component } from 'react'
import memoize from 'memoize-one'
import { connect } from 'react-redux'
import type { AccountingDocumentType, AccountingDocumentDirection, State, Sequence } from 'types'
import SelectNext, { type ValueType } from 'components/select-next'
import { getAccountingDocumentSequences } from 'modules/accounting-document/selectors'
import { useSimplifiedNumbering } from 'modules/accounting-document/domain/accounting-document'

type StateProps = {|
	sequences: Array<Sequence>,
|}

const mapStateToProps = (state: State, { type, direction, cashRegisterId }: OwnProps): StateProps => {
	const simplifiedNumbering = useSimplifiedNumbering(type, state)
	return {
		sequences: getAccountingDocumentSequences(
			state,
			direction === 'unknown' ? null : direction,
			type,
			cashRegisterId,
			undefined,
			!!simplifiedNumbering,
		),
	}
}

type OwnProps = {|
	value: ?number,
	type?: ?AccountingDocumentType,
	direction?: ?AccountingDocumentDirection,
	cashRegisterId?: ?string,
	labelText?: string,
	hintText?: string,
	name?: string,
	compact?: boolean,
	onChange: (sequenceId: ?number) => void,
|}

type Props = {| ...StateProps, ...OwnProps |}

class SequencesSelector extends Component<Props> {
	onSequenceChange = (option: ValueType) => {
		this.props.onChange(option && option.value ? parseInt(option.value) : null)
	}

	getOptions = memoize((sequences: Array<Sequence>) => {
		return sequences
			? sequences.map((sequence: Sequence) => {
					return {
						label: sequence.name + ' ' + sequence.format,
						value: sequence.id,
					}
			  })
			: []
	})

	render() {
		return (
			<SelectNext
				label={this.props.labelText}
				onChange={this.onSequenceChange}
				options={this.getOptions(this.props.sequences)}
				value={this.props.value ? this.props.value.toString() : null}
				placeholder={this.props.hintText}
				compact={this.props.compact}
				autoTestId="sequence-code-select"
				isSearchable
				isClearable
			/>
		)
	}
}

export default connect(mapStateToProps)(SequencesSelector)
