//@flow

import type {
	AccountingDocument,
	BankAccount,
	PaymentOrder,
	State,
	AccountingDocument_IssuedPaymentOrders,
} from 'types'
import PaymentDialog from 'modules/common/components/dialogs/payment-dialog/payment-dialog'
import { connect } from 'react-redux'
import { placePaymentOrder } from '../actions'
import withAutoload from 'wrappers/with-autoload'
import { updateSelectedDocumentsIds, loadPaymentOrdersOfAccDocs } from 'modules/common/actions'
import { ordersOfAllSelectedDocumentsSelector, getAllPaymentOrdersSelector } from 'modules/common/selectors'

export type Props = {|
	onRequestClose: (reason: string) => void,
	accountingDocuments: Array<AccountingDocument>,
	open: boolean,
|}

type StateProps = {|
	bankAccounts: Array<BankAccount>,
	ordersForSelectedDocuments: AccountingDocument_IssuedPaymentOrders,
	allDocumentsWithPaymentOrders: AccountingDocument_IssuedPaymentOrders,
|}

const mapStateToProps = (state: State): StateProps => {
	return {
		bankAccounts: state.bank.bankAccounts.data,
		ordersForSelectedDocuments: ordersOfAllSelectedDocumentsSelector(state),
		allDocumentsWithPaymentOrders: getAllPaymentOrdersSelector(state),
	}
}

type DispatchProps = {|
	placePaymentOrder: (paymentOrder: PaymentOrder) => Promise<*>,
	loadPaymentOrders: (accDocIds: Array<string>) => void,
	removeSelectedDocumentIds: () => void,
	updateSelectedDocumentsIds: (accDocIds: Array<string>) => void,
|}

const mapDispatchToProps = (dispatch: Function, ownProps: Props): DispatchProps => {
	return {
		placePaymentOrder: (paymentOrder: PaymentOrder) => {
			return dispatch(placePaymentOrder({ bankAccountId: paymentOrder.bankAccountId }, paymentOrder)).then(() => {
				ownProps.onRequestClose && ownProps.onRequestClose('success')
			})
		},
		loadPaymentOrders: (accDocIds: Array<string>) => {
			dispatch(loadPaymentOrdersOfAccDocs(accDocIds))
		},
		removeSelectedDocumentIds: () => {
			dispatch(updateSelectedDocumentsIds([]))
		},
		updateSelectedDocumentsIds: (accDocIds: Array<string>) => {
			dispatch(updateSelectedDocumentsIds(accDocIds))
		},
	}
}

const mergeProps = (state: StateProps, dispatch: DispatchProps, ownProps: Props) => {
	return {
		...ownProps,
		bankAccounts: state.bankAccounts,
		ordersForSelectedDocuments: state.ordersForSelectedDocuments,
		placePaymentOrder: dispatch.placePaymentOrder,
		removeSelectedDocumentIds: dispatch.removeSelectedDocumentIds,
		loadPaymentOrders: (accDocIds: Array<string>) => {
			dispatch.updateSelectedDocumentsIds(accDocIds)
			const idsWithoutLoadedOrders = accDocIds.filter((id: string) => !state.allDocumentsWithPaymentOrders[id])
			dispatch.loadPaymentOrders(idsWithoutLoadedOrders)
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(withAutoload(PaymentDialog))
