// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[3].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js!../../../variables/colors.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._10ZAYarO-C9nRAKZuLRiUT {\n\n}\n\n.-A0n1NQnqdWyAfqwWo9m4 {\n\n}\n\n._3K6lU4pR_6nsHBP36FNGZo {\n\tcolor: " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["black"] + ";\n\tfont-size: 24px;\n\tline-height: 80px;\n\tfont-weight: normal;\n\tmargin: 0;\n}\n\n.bgOvauZPfVZdkSKkRhsoW {\n\tdisplay: flex;\n\tjustify-content: space-between;\n\talign-content: center;\n\talign-items: center;\n}\n\n._1tu5pxVDwmWiJtkDcs0MyR {\n\n}\n\n._38cjgErSLZuP28Q7z58WCo {\n\tflex: 0 0 auto;\n\talign-self: flex-start;\n\tmargin-right: 30px;\n}\n\n._1hnNopAtWZO7nl1eJWf97D {\n\tflex: 1 0 auto;\n}\n\n.-A0n1NQnqdWyAfqwWo9m4 .bgOvauZPfVZdkSKkRhsoW {\n\tdisplay: block;\n}\n\n.-A0n1NQnqdWyAfqwWo9m4 ._1hnNopAtWZO7nl1eJWf97D {\n\twidth: 100%;\n}\n\n.-A0n1NQnqdWyAfqwWo9m4 ._38cjgErSLZuP28Q7z58WCo {\n\twidth: 100%;\n}\n", "",{"version":3,"sources":["webpack://./modules/internal/components/organization-overview.css"],"names":[],"mappings":"AAEA;;AAUA;;AANA;;AAUA;;AANA;CACC,mDAAA;CACA,eAAA;CACA,iBAAA;CACA,mBAAA;CACA,SAAA;AASD;;AANA;CACC,aAAA;CACA,8BAAA;CACA,qBAAA;CACA,mBAAA;AASD;;AANA;;AAUA;;AANA;CACC,cAAA;CACA,sBAAA;CACA,kBAAA;AASD;;AANA;CACC,cAAA;AASD;;AANA;CACC,cAAA;AASD;;AANA;CACC,WAAA;AASD;;AANA;CACC,WAAA;AASD","sourcesContent":["@value black, grey400 from \"variables/colors.css\";\n\n.normal {\n\n}\n\n.opened {\n\n}\n\n.headline {\n\tcolor: black;\n\tfont-size: 24px;\n\tline-height: 80px;\n\tfont-weight: normal;\n\tmargin: 0;\n}\n\n.head {\n\tdisplay: flex;\n\tjustify-content: space-between;\n\talign-content: center;\n\talign-items: center;\n}\n\n.grid {\n\n}\n\n.tabs {\n\tflex: 0 0 auto;\n\talign-self: flex-start;\n\tmargin-right: 30px;\n}\n\n.filter {\n\tflex: 1 0 auto;\n}\n\n.opened .head {\n\tdisplay: block;\n}\n\n.opened .filter {\n\twidth: 100%;\n}\n\n.opened .tabs {\n\twidth: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"black": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["black"] + "",
	"grey400": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["grey400"] + "",
	"normal": "_10ZAYarO-C9nRAKZuLRiUT",
	"opened": "-A0n1NQnqdWyAfqwWo9m4",
	"headline": "_3K6lU4pR_6nsHBP36FNGZo",
	"head": "bgOvauZPfVZdkSKkRhsoW",
	"grid": "_1tu5pxVDwmWiJtkDcs0MyR",
	"tabs": "_38cjgErSLZuP28Q7z58WCo",
	"filter": "_1hnNopAtWZO7nl1eJWf97D"
};
export default ___CSS_LOADER_EXPORT___;
