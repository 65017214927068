// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[3].use[1]!../../../../../node_modules/postcss-loader/dist/cjs.js!../../../../variables/colors.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._17iHZ6b9WY6CuPYudN8biN {\n\tbackground-color: white;\n\tmargin-bottom: 20px;\n\tborder: 1px solid " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["gray400"] + ";\n\tborder-left-width: 0px;\n\tborder-radius: 2px;\n\tborder-top-left-radius: 0px;\n\tborder-bottom-left-radius: 0px;\n}\n", "",{"version":3,"sources":["webpack://./modules/accounting-document/components/invoice-elements/side-button.css"],"names":[],"mappings":"AAEA;CACC,uBAAA;CACA,mBAAA;CACA,8DAAA;CACA,sBAAA;CACA,kBAAA;CACA,2BAAA;CACA,8BAAA;AAKD","sourcesContent":["@value gray400 from \"variables/colors.css\";\n\n.container {\n\tbackground-color: white;\n\tmargin-bottom: 20px;\n\tborder: 1px solid gray400;\n\tborder-left-width: 0px;\n\tborder-radius: 2px;\n\tborder-top-left-radius: 0px;\n\tborder-bottom-left-radius: 0px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"gray400": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["gray400"] + "",
	"container": "_17iHZ6b9WY6CuPYudN8biN"
};
export default ___CSS_LOADER_EXPORT___;
