/* @flow */

import type { State } from 'types'
import store from 'store'

function getOrganizationId() {
	const state: State = store.getState()
	return state.user.currentOrganizationId || 'error'
}

export function triviStoreRoute() {
	return `/${getOrganizationId()}/trivi-store`
}
