// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HGmSEoaUpN8m2eXiaxT9V {\n\ttext-align: right !important;\n}\n", "",{"version":3,"sources":["webpack://./modules/task/components/task-list.css"],"names":[],"mappings":"AAAA;CACC,4BAAA;AACD","sourcesContent":[".right {\n\ttext-align: right !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"right": "HGmSEoaUpN8m2eXiaxT9V"
};
export default ___CSS_LOADER_EXPORT___;
