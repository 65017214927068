// @flow
import { type ComponentType, type ElementConfig, type Component } from 'react'
import { connect } from 'react-redux'
import type { State } from 'types'
import { getEnabledFeatures } from 'modules/features/selectors'

type StateProps = {|
	enabledFeatures: Array<string>,
|}

const mapStateToProps = (state: State): StateProps => {
	return {
		enabledFeatures: getEnabledFeatures(state),
	}
}

export type Props = StateProps
export type WithFeaturesProps = Props
export default function withFeatures<P: any, C: ComponentType<P>>(
	WrappedComponent: C,
): Class<Component<$Diff<ElementConfig<C>, Props>, any>> {
	return connect(mapStateToProps)(WrappedComponent)
}
