/* @flow */

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import type { CommonAction, Dispatch, Enum, EnumItem, State } from 'types'
import { loadLegalForms } from 'modules/common/actions'
import SelectNext from 'components/select-next'
import type { OptionsType, OptionType } from 'components/select-next'

type StateProps = {|
	availableLegalForms: ?Enum,
|}

const mapStateToProps = (state: State): StateProps => {
	return {
		availableLegalForms: state.common.legalForms,
	}
}

type DispatchProps = {|
	loadLegalForms: () => void,
|}

const mapDispatchToProps = (dispatch: Dispatch<CommonAction>): DispatchProps => {
	return {
		loadLegalForms: () => {
			dispatch(loadLegalForms())
		},
	}
}

type OwnProps = {|
	value: ?number,
	inline?: boolean,
	disabled?: boolean,
	labelText?: ?string,
	openMenuPosition?: 'top' | 'bottom',
	onChange?: (signingCode: number) => void,
|}

const mergeProps = (stateProps: StateProps, dispatchProps: DispatchProps, ownProps: OwnProps): ComponentProps => {
	return {
		availableLegalForms: stateProps.availableLegalForms,
		loadLegalForms: dispatchProps.loadLegalForms,
		onChange: ownProps.onChange,
		value: ownProps.value,
		disabled: ownProps.disabled,
		inline: ownProps.inline,
		labelText: ownProps.labelText,
		openMenuPosition: ownProps.openMenuPosition,
	}
}

export type ComponentProps = {| ...StateProps, ...DispatchProps, ...OwnProps |}

class SigningPersonCodeSelector extends Component<{| ...ComponentProps, ...WithTranslateProps |}> {
	componentDidMount() {
		if (!this.props.availableLegalForms) {
			this.props.loadLegalForms && this.props.loadLegalForms()
		}
	}

	onChange = (option: ?OptionType | ?OptionsType) => {
		const parsedValue: number = parseInt(Array.isArray(option) ? option && option[0].value : option && option.value)
		parsedValue && this.props.onChange && this.props.onChange(parsedValue)
	}

	render() {
		const {
			t,
			value,
			availableLegalForms,
			onChange, //eslint-disable-line
			loadLegalForms, //eslint-disable-line
			labelText,
			...rest
		} = this.props

		const items =
			availableLegalForms &&
			availableLegalForms.map((item: EnumItem) => ({
				value: item.key,
				label: t('user.taxRepresentativeTypes.' + (item.value || '')),
			}))

		return (
			items && (
				<SelectNext
					options={items}
					value={value ? value.toString() : undefined}
					onChange={this.onChange}
					label={labelText == null ? undefined : labelText || t('user.fields.legalForm')}
					autoTestId="legal-form-selector"
					{...rest}
				/>
			)
		)
	}
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(withTranslate(SigningPersonCodeSelector))
