// @flow

import { createSelector } from 'reselect'
import { createGridMappingKey } from '../utils'
import type { SearchFilter, State } from 'types'
import type { Sort } from '../types'
import type { DataGridView, DataGridViews, DataGridState } from '../reducers'
import type { Filter } from 'modules/data-grid/types'
import { EMPTY_ARRAY, EMPTY_OBJECT } from 'trivi-constants'

const getDataGridViews = (state: State): DataGridViews => {
	return (state.dataGrid && state.dataGrid.view) || EMPTY_OBJECT
}

const getAllDataGridData = (state: State, key: string) => {
	return state.dataGrid && state.dataGrid.data[key]
}

const getDataGridState = (state: State, key: string): ?DataGridState => {
	return state.dataGrid && state.dataGrid.gridState[key]
}

export const getDataGridFilter = createSelector(
	(state: State, filterId?: string) => state.grid.filter[filterId || ''],
	(state: State, filterId?: string, defaultFilter?: Filter) => defaultFilter,
	(filter?: Filter, defaultFilter?: Filter): Filter | void => {
		// delete default filter if user filter on the same field is set
		const uniqueDefaultFilter = (defaultFilter || EMPTY_ARRAY).filter((defaultSF: SearchFilter) => {
			return !(filter || EMPTY_ARRAY).some((SF: SearchFilter) => defaultSF.field === SF.field)
		})
		const allFilters = [...(uniqueDefaultFilter || EMPTY_ARRAY), ...(filter || EMPTY_ARRAY)]
		return allFilters.length > 0 ? allFilters : undefined
	},
)

const getDataGridView = createSelector(
	(state: State, key: string, stateKey: string) => getDataGridState(state, stateKey),
	getDataGridViews,
	(state: State, key: string) => key,
	(state: State, key: string, stateKey: string, filterId?: string, defaultFilter?: Filter) =>
		getDataGridFilter(state, filterId, defaultFilter),
	(dataGridState: ?DataGridState, dataGridViews: DataGridViews, key: string, filter?: Filter): ?DataGridView => {
		const dataMappingKey = createGridMappingKey(key, (dataGridState && dataGridState.currentSort) || undefined, filter)
		return dataGridViews[dataMappingKey]
	},
)

const getDataGridViewList = createSelector(
	getDataGridView,
	(dataGridView: ?DataGridView) => dataGridView && dataGridView.list,
)

export const getDataGridData = createSelector(
	getDataGridViewList,
	(state: State, key: string, stateKey: string) => getDataGridState(state, stateKey),
	getAllDataGridData,
	(dataGridViewList: ?Array<string>, dataGridState: ?DataGridState, dataGridData: { [string]: Object }) => {
		if (dataGridData != null && dataGridViewList) {
			const pageSize = (dataGridState && dataGridState.currentPageSize) || 10
			const startIndex = ((dataGridState && dataGridState.currentPage) || 0) * pageSize
			const endIndex = startIndex + pageSize
			const result = dataGridViewList
				.slice(startIndex, endIndex)
				.filter((id: string) => !!dataGridData && !!dataGridData[id])
				.map((id: string) => dataGridData[id])
			return result && result.length ? result : EMPTY_ARRAY
		} else {
			return EMPTY_ARRAY
		}
	},
)

export const getDataGridCurrentPage = createSelector(getDataGridState, (dataGridState: ?DataGridState): number =>
	dataGridState && dataGridState.currentPage ? dataGridState.currentPage : 0,
)

export const getDataGridCurrentPageSize = createSelector(getDataGridState, (dataGridState: ?DataGridState): number =>
	dataGridState && dataGridState.currentPageSize ? dataGridState.currentPageSize : 10,
)

const getDataGridCurrentPageSizeWithDefault = (state: State, id: string, defaultPageSize: number) => {
	const dataGridState = getDataGridState(state, id)
	return (dataGridState && dataGridState.currentPageSize) ?? defaultPageSize
}

export const getDataGridCurrentPageSizeWithDefaultSelector = createSelector(
	getDataGridCurrentPageSizeWithDefault,
	(pageSize: ?number): number => pageSize ?? 10,
)

export const getDataGridCurrentSort = createSelector(getDataGridState, (dataGridState: ?DataGridState): ?Sort =>
	dataGridState && dataGridState.currentSort ? dataGridState.currentSort : null,
)

export const getDataGridItemsCount = createSelector(getDataGridView, (dataGridView: ?DataGridView): number =>
	dataGridView && dataGridView.count ? dataGridView.count : 0,
)

export const getDataGridItemsLoading = createSelector(
	getDataGridView,
	(dataGridView: ?DataGridView): boolean => !!dataGridView && !!dataGridView.loading,
)

export const findFilterItemByFieldName = (filter: Filter, fieldName: string): ?SearchFilter => {
	return filter.find((item: SearchFilter) => item.field === fieldName)
}
