// @flow

import React, { Component, type ComponentType, type ElementConfig } from 'react'
import i18next from 'i18next'
import type { I18NextTranslateFn } from 'types'
import type { TranslateOptions } from 'react-i18next' //eslint-disable-line
import { translate } from 'react-i18next'

export type Props = {|
	t: I18NextTranslateFn,
|}

type I18NextProps = {|
	t: I18NextTranslateFn,
	i18n: Object,
	i18nLoadedAt: Date,
|}

//eslint-disable-next-line no-unused-vars
interface Wrapped<C, P, S> extends Component<P, S> {
	// getWrappedInstance(): C;
}

type MyComponent<C, Props> = Wrapped<C, $Diff<ElementConfig<C>, Props>, any>

let cachedTranslateFn = null

i18next.on('languageChanged', () => {
	cachedTranslateFn = null
})

// HOC withTranslate should be removed when translate HOC properly typed.
export default function withTranslate<P: any, C: ComponentType<P>>(
	WrappedComponent: C,
	args?: ?TranslateOptions,
): Class<MyComponent<C, Props>> {
	class Wrapper extends Component<ElementConfig<C> & I18NextProps> {
		wrappedInstance: C

		render() {
			const { t, i18n, i18nLoadedAt, ...rest } = this.props
			const extraProps = {}

			if (!cachedTranslateFn) cachedTranslateFn = t

			if (args && args.withRef) {
				extraProps.ref = (c: C) => {
					this.wrappedInstance = c
				}
			}

			return <WrappedComponent {...rest} {...extraProps} t={cachedTranslateFn} />
		}
	}

	const TranslatedComponent = translate(undefined, args)(Wrapper)

	// $FlowFixMe This is just a hack. HOC withTranslate should be removed when translate HOC properly typed.
	TranslatedComponent.prototype.getWrappedInstance = function() {
		return this.wrappedInstance && this.wrappedInstance.wrappedInstance
	}

	return TranslatedComponent
}
