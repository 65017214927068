/* @flow */

import React, { Component } from 'react'
import { internalUploads as internalUploadsAPI } from 'modules/common/models/api-model'
import type { FileDescription } from 'types'
import { Dropzone, FileThumbnail } from 'components'
import styles from './task-edit-files.css'

type Props = {|
	value: Array<FileDescription>,
	onChange: (Array<FileDescription>) => void,
	internal: boolean,
|}

class TaskEdit extends Component<Props> {
	static defaultProps = {
		value: [],
		onChange: () => {},
		internal: false,
	}

	handleDropFiles = (files: Array<File>): ?Promise<void> => {
		let fileDescriptions: Array<FileDescription> = [...this.props.value]

		files.forEach((file: File) => {
			const fileDescription: FileDescription = {
				id: Math.floor(Math.random() * 10000).toString(),
				name: file.name,
				loading: true,
			}
			fileDescriptions.push(fileDescription)

			internalUploadsAPI.post({ file }).then(
				(result: { id?: string }) => {
					fileDescriptions = [...this.props.value]
					if (result.id) {
						fileDescriptions.splice(fileDescriptions.indexOf(fileDescription), 1, {
							id: fileDescription.id,
							name: fileDescription.name,
							fileId: result.id,
							loading: false,
						})
					} else {
						fileDescriptions.splice(fileDescriptions.indexOf(fileDescription), 1)
					}
					this.props.onChange(fileDescriptions)
				},
				() => {
					fileDescriptions = [...this.props.value]
					fileDescriptions.splice(fileDescriptions.indexOf(fileDescription), 1)
					this.props.onChange(fileDescriptions)
				},
			)
		})

		this.props.onChange(fileDescriptions)
	}

	handleFileRemove = (file: FileDescription) => {
		const fileDescriptions = [...this.props.value]
		fileDescriptions.splice(fileDescriptions.indexOf(file), 1)
		this.props.onChange(fileDescriptions)
	}

	render() {
		return (
			<div>
				<div className={styles.row}>
					{this.props.value.map((file: FileDescription) => (
						<FileThumbnail key={file.id} file={file} onRemove={this.handleFileRemove} />
					))}
				</div>
				<div className={styles.dropzone}>
					<Dropzone multiple onDrop={this.handleDropFiles} minHeight={100} />
				</div>
			</div>
		)
	}
}

export default TaskEdit
