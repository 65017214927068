/* @flow */

import { userHasAccess } from 'app/permissions'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { type AutoTestProps } from 'utils/tests/autotest'
import type {
	Dispatch,
	InternalUser,
	OrganizationAction,
	OrganizationInternalUser,
	State,
	UserAction,
	UserProfile,
} from 'types'
import { loadUsers } from '../../organization/actions'
import {
	getCurrentOrganizationInternalUsers,
	getCurrentOrganizationUsers,
	getUsers,
	isCurrentOrganizationUsersLoading,
	isUsersLoading,
} from '../../organization/selectors'
import { loadInternalUsers } from '../actions'
import UserPicker, { type FieldProps, type Props } from '../components/user-picker'
import { getInternalUsers, getMyProfile, isInternalUsersLoading } from '../selectors'
import memoize from 'memoize-one'
import withTranslate from '../../../wrappers/with-translate'
import type { WithTranslateProps } from '../../../wrappers'

type User = UserProfile | InternalUser | OrganizationInternalUser

type UserLoadingProps = {|
	me?: ?UserProfile,
	show?: 'INTERNAL' | 'ORGANIZATION' | 'ALL',
	showMe?: boolean,
	selectedOrganizationUsers?: ?Array<any>,
|}

type OwnProps = {| ...AutoTestProps, ...UserLoadingProps, ...FieldProps |}

type StateProps = {|
	me: ?UserProfile,
	users?: ?Array<User>,
	disabled?: boolean,
|}

export const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => {
	let users: ?Array<User>,
		loading: boolean,
		me = getMyProfile(state)

	switch (ownProps.show) {
		case 'INTERNAL':
			if (userHasAccess(state, 'seeInternalUsers')) {
				users = [...(getInternalUsers(state) || [])]
				loading = 0 === users.length && isInternalUsersLoading(state)
			} else {
				users = [...(getCurrentOrganizationInternalUsers(state) || [])]
				loading = false // not loading attribute for organization internal users (loaded in bootstrap)
			}
			break
		case 'ORGANIZATION':
			users = [...(getCurrentOrganizationUsers(state) || [])]
			loading = 0 === users.length && isCurrentOrganizationUsersLoading(state)
			break
		default:
			users = getUsers(state) || []
			loading = 0 === users.length && isUsersLoading(state)
	}

	const meIndex = users.findIndex((user: User) => me && (user.id || user.userId || 0) === me.id)
	const meInUsers = -1 !== meIndex
	const meIsInternal = me && true === me.isInternal

	if (meInUsers && me && (!ownProps.showMe || ('INTERNAL' !== ownProps.show && meIsInternal))) {
		users.splice(meIndex, 1)
		users = users.filter((user: User) => (user.id || user.userId || '') !== me.id)
	}
	if (ownProps.showMe && me && !meInUsers && 'INTERNAL' !== ownProps.show && !meIsInternal) {
		users.push(me)
	}

	return {
		me,
		users,
		disabled: loading,
	}
}

type DispatchProps = {|
	loadUsers: (props: UserLoadingProps) => void,
|}

const mapDispatchToProps = (dispatch: Dispatch<OrganizationAction | UserAction>): DispatchProps => {
	return {
		loadUsers: (props: UserLoadingProps) => {
			if ('INTERNAL' !== props.show) {
				dispatch(loadUsers())
			}
			if ('ORGANIZATION' !== props.show) {
				dispatch(loadInternalUsers())
			}
		},
	}
}

const mergeProps = (
	stateProps: StateProps,
	dispatchProps: DispatchProps,
	ownProps: OwnProps,
): {| ...Props, ...DispatchProps, ...UserLoadingProps |} => {
	return {
		me: stateProps.me,
		users: stateProps.users,
		isClearable: ownProps.isClearable,
		compact: ownProps.compact,
		onChange: ownProps.onChange,
		labelText: ownProps.labelText,
		placeholder: ownProps.placeholder,
		value: ownProps.value,
		clientError: ownProps.clientError,
		disabled: stateProps.disabled || ownProps.disabled,
		loadUsers: dispatchProps.loadUsers,
		show: ownProps.show,
		showMe: ownProps.showMe,
		selectedOrganizationUsers: ownProps.selectedOrganizationUsers,
	}
}

type UserPickerContainerState = {
	preselectedUserId: ?string,
}

class UserPickerContainer extends Component<
	{| ...Props, ...DispatchProps, ...UserLoadingProps, ...WithTranslateProps |},
	UserPickerContainerState,
> {
	state = {
		preselectedUserId: null,
	}
	componentDidMount() {
		this.props.loadUsers({ show: this.props.show, showMe: this.props.showMe })
	}

	UNSAFE_componentWillReceiveProps(nextProps: {|
		...Props,
		...DispatchProps,
		...UserLoadingProps,
		...WithTranslateProps,
	|}) {
		if (nextProps.show !== this.props.show) {
			this.props.loadUsers({ show: nextProps.show, showMe: nextProps.showMe })
		}
	}

	getUsers = memoize((showMe: ?boolean, me: ?UserProfile, users: ?Array<any>) => {
		if (users && users.length > 0) {
			const result = [...users]
			if (me && !!showMe) {
				const meIndex = users.findIndex((user: User) => me && (user.id || user.userId || 0) === me.id)
				const meInUsers = -1 !== meIndex
				if (!meInUsers) result.push(me)
			}
			return result
		}
		return null
	})

	getUserValue = memoize((selectedUsers: ?Array<User>, value: ?string): ?string => {
		if (!selectedUsers || selectedUsers.length === 0 || !value) return null
		// $FlowFixMe
		const user = selectedUsers.find((user: User) => (user.id || user.userId) === value)
		return (user && user.id) || (user && user.userId) || null
	})

	render() {
		const { disabled, isClearable, showMe, me, selectedOrganizationUsers: users } = this.props
		const selectedUsers = this.getUsers(showMe, me, users)
		let usersList = selectedUsers || this.props.users || []

		const value = this.props.value || this.state.preselectedUserId || null
		const valueExists = usersList.find((user: User) => (user.id || user.userId || '') === this.props.value)

		if (value && !disabled && !valueExists) {
			usersList = [...usersList, { id: value, firstname: this.props.t('user.userSelect.unknownUser') }]
		}

		return (
			<UserPicker
				isClearable={isClearable}
				users={usersList}
				compact={this.props.compact}
				onChange={this.props.onChange}
				labelText={this.props.labelText}
				placeholder={this.props.placeholder}
				value={this.getUserValue(usersList, this.props.value || this.state.preselectedUserId)}
				clientError={this.props.clientError}
				disabled={this.props.disabled}
			/>
		)
	}
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(withTranslate(UserPickerContainer))
