/* @flow */

import type {
	AccountingDocumentAttachment,
	AccountingDocumentConnection,
	State,
	AccountingDocumentReduxAction,
} from 'types'
import {
	getAccountingDocumentAttachments,
	getAccountingDocumentConnections,
} from 'modules/accounting-document/selectors'

import {
	loadAccountingDocumentAttachments,
	loadAccountingDocumentConnections,
} from 'modules/accounting-document/actions'
import AttachmentButton from 'modules/accounting-document/components/invoice-elements/attachment-button'
import { connect } from 'react-redux'

type StateProps = {|
	attachments: ?Array<AccountingDocumentAttachment>,
	connections: ?Array<AccountingDocumentConnection>,
|}

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => {
	return {
		attachments: getAccountingDocumentAttachments(state, ownProps.accountingDocumentId),
		connections: getAccountingDocumentConnections(state, ownProps.accountingDocumentId),
	}
}

type DispatchProps = {|
	loadAccountingDocumentAttachments: () => void,
	loadAccountingDocumentConnections: () => void,
|}

const mapDispatchToProps = (dispatch: Dispatch<AccountingDocumentReduxAction>, ownProps: OwnProps): DispatchProps => {
	return {
		loadAccountingDocumentAttachments: () => {
			dispatch(loadAccountingDocumentAttachments(ownProps.accountingDocumentId))
		},
		loadAccountingDocumentConnections: () => {
			dispatch(loadAccountingDocumentConnections(ownProps.accountingDocumentId))
		},
	}
}
type OwnProps = {|
	accountingDocumentId: string,
	onClick: () => void,
	hideIfNoAttachment?: boolean,
	hideIfOnlyAccountingDocuments?: boolean,
|}

export default connect(mapStateToProps, mapDispatchToProps)(AttachmentButton)
