/* @flow */
/** @jsx jsx */

// $FlowFixMe - there is useState, useEffect, useRef
import { useState, useEffect, useRef } from 'react'
import { jsx } from '@emotion/core'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import TextField from 'components/TextField'

type Props = {|
	...WithTranslateProps,
	readonly?: boolean,
	accountingDocumentId?: string,
	note: ?string,
	defaultNote: ?string,
	onChange: (note: ?string) => void,
|}

function PrintingNote(props: Props) {
	const [note, setNote] = useState<string>(props.note || '')
	const previous = useRef(props.defaultNote || '')

	useEffect(() => {
		const newNote = props.defaultNote || ''
		if (note === previous.current && newNote !== note) {
			setNote(newNote)
			props.onChange(newNote)
		}
		previous.current = newNote
	}, [props.defaultNote]) // eslint-disable-line react-hooks/exhaustive-deps

	function onChange(event: SyntheticInputEvent<HTMLInputElement>, note: ?string) {
		setNote(note)
	}

	function onBlur() {
		if ((note || '') === (props.defaultNote || '')) return
		props.onChange(note)
	}

	if (props.readonly && !note) return null

	return (
		<div css={styles.root}>
			<h3 css={styles.headline}>{props.t('invoice.notes')}</h3>
			<TextField
				inline
				multiLine
				fullWidth
				autoHeight
				tabIndex={-1}
				value={note}
				onBlur={onBlur}
				hintText={props.t('invoice.addNewNoteHint')}
				disabled={props.readonly}
				onChange={onChange}
				name="printing-note"
				autoTestId="invoice-printing-note-input"
			/>
		</div>
	)
}

const styles = {
	root: {
		marginBottom: 10,
		lineHeight: '24px',
	},
	headline: {
		marginBottom: 5,
		marginTop: 0,
	},
}

export default withTranslate(PrintingNote)
