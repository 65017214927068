// @flow

import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { merge } from 'lodash-es'
import React, { type Node } from 'react'
import { pure } from 'recompose'
import './theme'
import { getDecimalPoint, getThousandsSeparator } from 'utils/formatters'
import type { Options } from './types'

export type Props = {|
	options: Options,
	...BaseChartProps,
|}

export type BaseChartProps = {|
	// Width in pixels (option chart.width)
	width?: number,

	// Height in pixels (option chart.height)
	height?: number,

	// The distance between the outer edge of the chart and the content (option chart.spacing)
	padding?: [number, number, number, number],

	// (option chart.backgroundColor)
	backgroundColor?: string,

	// Chart title (option chart.title.text)
	title?: string,

	// Add content over the chart
	overlayContent?: Node,

	// Disable user events and set partial opacity
	disabled?: boolean,
|}

function Highchart(props: Props) {
	Highcharts.setOptions({
		lang: {
			decimalPoint: getDecimalPoint(),
			thousandsSep: getThousandsSeparator(),
		},
	})

	const styles = getStyles(props)
	return (
		<div style={styles.root}>
			<div style={styles.chart}>
				<HighchartsReact highcharts={Highcharts} options={convertProps(props)} immutable />
			</div>
			{props.overlayContent && <div style={styles.overlay}>{props.overlayContent}</div>}
		</div>
	)
}

export default pure(Highchart)

export function convertProps(props: Props): Options {
	const options = merge({ credits: { enabled: false } }, props.options)

	if (props.width) {
		options.chart = options.chart || {}
		options.chart.width = props.width
	}

	if (props.height) {
		options.chart = options.chart || {}
		options.chart.height = props.height
	}

	if (props.backgroundColor) {
		options.chart = options.chart || {}
		options.chart.backgroundColor = props.backgroundColor
	}

	if (props.title && props.title.length) {
		options.title = options.title || {}
		options.title.text = props.title
	} else if (!(options.title && options.title.text)) {
		options.title = options.title || {}
		options.title.text = undefined
	}

	if (props.padding) {
		options.chart = options.chart || {}
		options.chart.spacing = props.padding
	}

	return options
}

function getStyles(props: Props) {
	const chartDisabledStyles = props.disabled ? { opacity: 0.5, pointerEvents: 'none' } : {}

	return {
		root: {
			position: 'relative',
		},
		overlay: {
			position: 'absolute',
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			pointerEvents: 'none',
		},
		chart: { ...chartDisabledStyles },
	}
}
