/* @flow */

import { connect } from 'react-redux'
import type { State, BankAccount } from 'types'
import AdvancedFilter from './advanced-filter'
import { getBankAccounts } from '../../../accounting-document/selectors'
import { EMPTY_ARRAY } from 'trivi-constants'

type StateProps = {|
	bankAccounts: Array<BankAccount>,
|}

const mapStateToProps = (state: State): StateProps => {
	return {
		bankAccounts: getBankAccounts(state) || EMPTY_ARRAY,
	}
}

export default connect(mapStateToProps)(AdvancedFilter)
