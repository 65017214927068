//@flow
import React, { Component } from 'react'
import styles from './trivi-store.css'
import Helmet from 'react-helmet'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import TriviStoreGrid from 'modules/trivi-store/containers/trivi-store-grid'

type Props = {|
	...WithTranslateProps,
|}

class TriviStorePage extends Component<Props> {
	render() {
		return (
			<div className={styles.root}>
				<Helmet>
					<title>{this.props.t('triviStore.headline')}</title>
				</Helmet>
				<h1>{this.props.t('triviStore.header')}</h1>
				<TriviStoreGrid />
			</div>
		)
	}
}

export default withTranslate(TriviStorePage)
