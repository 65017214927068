/* @flow */

import React, { Component } from 'react'
import { connect } from 'react-redux'
import type { Dispatch, Group, GroupsAction, State } from 'types'
import { getGroups } from '../actions'
import { selectGroups } from '../selectors'
import SelectNext from 'components/select-next'
import type { OptionsType } from 'components/select-next'

type OwnProps = {
	value?: Array<string>,
	onChange: (items: string | Array<string>) => void,
	label?: string,
	fullWidth?: boolean,
	isMulti?: boolean,
}

type StateProps = {|
	groups: ?Array<Group>,
|}

function mapStateToProps(state: State): StateProps {
	return {
		groups: selectGroups(state),
	}
}

type DispatchProps = {|
	loadGroups: () => void,
|}

function mapDispatchToProps(dispatch: Dispatch<GroupsAction>): DispatchProps {
	return {
		loadGroups: () => {
			dispatch(getGroups())
		},
	}
}

type ComponentProps = StateProps & DispatchProps & OwnProps

class GroupSelector extends Component<ComponentProps> {
	componentDidMount() {
		if (!this.props.groups) {
			this.props.loadGroups()
		}
	}

	render() {
		const { groups, loadGroups, fullWidth, ...rest } = this.props

		const groupEnum: OptionsType = (groups || []).map((g: Group) => {
			return {
				value: g.id,
				label: g.name,
			}
		})
		return <SelectNext {...rest} autoTestId="group-selector" autoWidth={!fullWidth} options={groupEnum} />
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(GroupSelector)
