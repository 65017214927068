/* @flow */

import React, { Component } from 'react'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import type { AccountingDocumentConnection, AccountingDocumentVatRecapLine } from 'types'
import Issues from 'modules/accounting-document/containers/issues/issues'
import { CurrencyInput, Popover } from 'components'
import styles from './edit-total-amounts.css'

type Props = {|
	...WithTranslateProps,
	connection: AccountingDocumentConnection,
	onConnectionChange: (connection: AccountingDocumentConnection) => void,
	onConnectionBlur: (connection: AccountingDocumentConnection) => void,
	open: boolean,
	anchorEl: ?Object,
	onRequestClose: () => void,
	disabled: boolean,
|}

type State = {|
	connection: AccountingDocumentConnection,
|}

class EditTotalPrices extends Component<Props, State> {
	anchorOrigin = { horizontal: 'middle', vertical: 'bottom' }
	targetOrigin = { horizontal: 'middle', vertical: 'top' }

	connectionToSend: AccountingDocumentConnection

	constructor(props: Props) {
		super(props)
		this.state = {
			connection: props.connection,
		}

		this.connectionToSend = this.getInitConnection(props)
	}

	UNSAFE_componentWillReceiveProps(newProps: Props) {
		this.setState({ connection: newProps.connection })
		this.connectionToSend.vatAmounts = [...(newProps.connection.vatAmounts || [])]
	}

	getInitConnection(props: Props): AccountingDocumentConnection {
		return Object.seal({
			...props.connection,
			amount: undefined,
			vatAmounts: [...(props.connection.vatAmounts || [])],
		})
	}

	onConnectionChange = (index: number, field: string) => {
		return (event: SyntheticInputEvent<HTMLInputElement>, value: ?number) => {
			const connection: AccountingDocumentConnection = Object.seal({ ...this.props.connection })

			if (connection.vatAmounts && connection.vatAmounts[index] && this.connectionToSend.vatAmounts) {
				this.connectionToSend.vatAmounts[index] = {
					...connection.vatAmounts[index],
					[field]: value,
				}
				if (connection.vatAmounts && connection.vatAmounts[index]) {
					connection.vatAmounts[index][field] = value
				}
			}

			this.setState({ connection }, () => {
				this.props.onConnectionChange(this.state.connection)
			})
		}
	}

	onBlur = () => {
		this.props.onConnectionBlur(this.connectionToSend)
	}

	renderAmounts(vatAmounts: ?Array<AccountingDocumentVatRecapLine>) {
		const { disabled } = this.props
		return (
			vatAmounts &&
			vatAmounts.map((item: AccountingDocumentVatRecapLine, index: number) => {
				return (
					<tr key={'line' + index}>
						<td className={styles.vatRateLine}>{item.vatRate} %</td>
						<td className={styles.taxLine}>
							<CurrencyInput
								autoWidth
								right
								value={item.base}
								onChange={this.onConnectionChange(index, 'base')}
								onBlur={this.onBlur}
								disabled={disabled}
								inline
								name="base"
							/>
						</td>
						<td className={styles.vatAmountLine}>
							<CurrencyInput
								autoWidth
								right
								value={item.vatAmount}
								onChange={this.onConnectionChange(index, 'vatAmount')}
								onBlur={this.onBlur}
								disabled={disabled}
								inline
								name="vat-amount"
							/>
						</td>
						<td className={styles.amountLine}>
							<CurrencyInput
								autoWidth
								right
								value={item.total}
								onChange={this.onConnectionChange(index, 'total')}
								onBlur={this.onBlur}
								disabled={disabled}
								inline
								name="total"
							/>
						</td>
					</tr>
				)
			})
		)
	}

	render() {
		const { t } = this.props

		return (
			<Popover
				open={this.props.open}
				anchorEl={this.props.anchorEl}
				onRequestClose={this.props.onRequestClose}
				anchorOrigin={this.anchorOrigin}
				targetOrigin={this.targetOrigin}
				arrow={'top'}
				zDepth={6}
				style={style.popover}
				rounded
			>
				<div className={styles.container}>
					<Issues accountingDocumentId={this.props.connection.connectedAccountingDocumentId} hideTitle />
					<table className={styles.table}>
						<thead className={styles.header}>
							<tr>
								<th className={styles.vatRate}>{t('invoice.totals.vatRate')}</th>
								<th className={styles.tax}>{t('invoice.totals.taxBase')}</th>
								<th className={styles.vatAmount}>{t('invoice.totals.vat')}</th>
								<th className={styles.amount}>{t('invoice.totals.amount')}</th>
							</tr>
						</thead>
						<tbody>{this.props.connection.vatAmounts && this.renderAmounts(this.props.connection.vatAmounts)}</tbody>
					</table>
				</div>
			</Popover>
		)
	}
}

const style = {
	popover: { overflow: 'hidden' },
}

export default withTranslate(EditTotalPrices)
