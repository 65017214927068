/* @flow */

import { connect } from 'react-redux'
import { type AutoTestProps } from 'utils/tests/autotest'
import type { Currency, Dispatch, State, CommonAction, FormFieldErrorContext, FormFieldErrorMessage } from 'types'
import CurrencySelectorOld from '../components/currency-selector-old'
import { loadCurrencies } from 'modules/common/actions'
import { loadCurrenciesUsedInOrg } from 'modules/organization/actions'
import { getOrganizationCurrencies } from 'modules/organization/selectors'
import { getAllCurrencies, getFormFieldErrors } from 'modules/common/selectors'
import { removeError } from 'helpers'

type OwnProps = {|
	...AutoTestProps,
	autoFocus?: boolean,
	allowEmpty?: boolean,
	emptyText?: string,
	labelText?: string,
	disabled?: boolean,
	fullWidth?: boolean,
	inline?: boolean,
	compact?: boolean,
	onlyUsedInOrg?: boolean,
	value?: ?string | ?number | Array<any>,
	onChange?: (?Currency) => void,
	formFieldErrorContext?: FormFieldErrorContext,
|}

type StateProps = {|
	currencies: ?Array<Currency>,
	errors: ?Array<FormFieldErrorMessage>,
|}

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => {
	return {
		currencies: ownProps.onlyUsedInOrg ? getOrganizationCurrencies(state) : getAllCurrencies(state),
		errors: ownProps.formFieldErrorContext && getFormFieldErrors(state, ownProps.formFieldErrorContext),
	}
}

type DispatchProps = {|
	loadCurrencies: () => void,
	removeError: () => void,
|}

const mapDispatchToProps = (dispatch: Dispatch<CommonAction>, ownProps: OwnProps): DispatchProps => {
	return {
		loadCurrencies: () => {
			ownProps.onlyUsedInOrg ? dispatch(loadCurrenciesUsedInOrg()) : dispatch(loadCurrencies())
		},
		removeError: () => {
			removeError({
				formFieldErrorContext: ownProps.formFieldErrorContext,
				dispatch,
			})
		},
	}
}

function mergeProps(stateProps: StateProps, dispatchProps: DispatchProps, ownProps: OwnProps) {
	return {
		autoTestId: ownProps.autoTestId,
		currencies: stateProps.currencies,
		autoFocus: ownProps.autoFocus,
		allowEmpty: ownProps.allowEmpty,
		emptyText: ownProps.emptyText,
		labelText: ownProps.labelText,
		disabled: ownProps.disabled,
		fullWidth: ownProps.fullWidth,
		inline: ownProps.inline,
		compact: ownProps.compact,
		onlyUsedInOrg: ownProps.onlyUsedInOrg,
		value: ownProps.value,
		onChange: ownProps.onChange,
		loadCurrencies: dispatchProps.loadCurrencies,
		errors: stateProps.errors,
		removeError: dispatchProps.removeError,
	}
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(CurrencySelectorOld)
