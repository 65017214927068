/* @flow */

import React, { Component } from 'react'
import Folder from 'components/svg-icons/file/folder'
import loader from 'assets/images/loader-document.svg'
import { colors } from 'variables'

export type Props = {|
	opacity?: number,
	width?: number,
|}

class DocumentListLoaderItem extends Component<Props> {
	render() {
		const styles = getStyles(this.props)
		const iconColor = '#EBECEC'

		return (
			<div style={styles.root}>
				<span style={styles.icon}>
					<Folder size={20} color={iconColor} hoverColor={iconColor} />
				</span>
				<img style={styles.image} src={loader} />
				<div style={styles.separator} />
			</div>
		)
	}
}

function getStyles(props: Props) {
	return {
		root: {
			height: 50,
			padding: '0 15px',
			backgroundColor: colors.white,
			display: 'flex',
			alignContent: 'center',
			alignItems: 'center',
			justifyContent: 'flex-start',
			position: 'relative',
		},
		separator: {
			display: 'block',
			position: 'absolute',
			left: 15,
			right: 15,
			top: -1,
			height: 1,
			background: '#DFDFDF',
		},
		icon: {
			flex: '0 0 24px',
			marginRight: 13,
			marginLeft: 28,
		},
		image: {
			display: 'block',
			opacity: props.opacity || 1,
			width: props.width || 150,
			height: 15,
		},
	}
}

export default DocumentListLoaderItem
