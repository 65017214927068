import React from 'react'
import SvgIcon from 'components/SvgIcon'
import pure from 'recompose/pure'

let Wait = props => {
	props = Object.assign({}, props, { viewBox: '0 0 12 12' })

	return (
		<SvgIcon {...props}>
			<g stroke="none" fill="none">
				<path
					d="M6,5.736 L7.992,3.744 L7.992,1.992 L4.008,1.992 L4.008,3.744 L6,5.736 Z M7.992,8.244 L6,6.252 L4.008,8.244 L4.008,10.008 L7.992,10.008 L7.992,8.244 Z M3,1.008 L9,1.008 L9,4.008 L7.008,6 L9,7.992 L9,10.992 L3,10.992 L3,7.992 L4.992,6 L3,4.008 L3,1.008 Z"
					fill="#FFFFFF"
				/>
			</g>
		</SvgIcon>
	)
}
Wait = pure(Wait)

export default Wait
