/* @flow */

import { connect } from 'react-redux'
import type { Dispatch } from 'types'
import type { Action as FileAction } from 'modules/file/actions/action-types'
import FileViewer from 'modules/file/components/file-viewer'
import { downloadWithToken } from 'modules/file/actions'

type DispatchProps = {|
	downloadFile: (fileId: string) => void,
|}

const mapDispatchToProps = (dispatch: Dispatch<FileAction>): DispatchProps => {
	return {
		downloadFile: (fileId: string): void => {
			dispatch(downloadWithToken(fileId))
		},
	}
}

export default connect(undefined, mapDispatchToProps)(FileViewer)
