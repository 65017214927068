/* @flow */

import { UserInternalPermission, UserIsAuthenticated } from 'lib/auth'

import InternalUsers from '../containers/internal-user-settings'
import InternalDashboard from 'modules/internal/containers/internal-dashboard-page'
import Billing from 'modules/internal/containers/billing/billing-page'
import MainLayout from 'layouts/main-layout'
import React from 'react'
import { Route } from 'react-router'

const DashboardComponent = UserInternalPermission(
	UserIsAuthenticated(MainLayout('internalDashboard')(InternalDashboard)),
)
const UsersComponent = UserInternalPermission(UserIsAuthenticated(MainLayout('internalUsers')(InternalUsers)))
const BillingComponent = UserInternalPermission(UserIsAuthenticated(MainLayout('internalDashboard')(Billing)))

const InternalRouting = () => [
	<Route exact key="/internal-dashboard" path="/internal-dashboard" component={DashboardComponent} />,
	<Route exact key="/internal-dashboard-users" path="/internal-dashboard/users" component={UsersComponent} />,
	<Route exact key="/internal-dashboard-billing" path="/internal-dashboard/billing" component={BillingComponent} />,
]

export default InternalRouting
