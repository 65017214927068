/* @flow */

import React, { Component } from 'react'
import BankAccounts from '../invoice-elements/bank-accounts'
import { emptyAccountingDocument } from 'types/empty'
import type { AccountingDocumentBankAccount, AccountingDocument, FormFieldErrorContext } from 'types'
import { findBankAccountIndex } from 'types/operations'
import { EMPTY_ARRAY } from 'trivi-constants'

type Props = {|
	direction: 'issued' | 'received',
	accountingDocument: AccountingDocument,
	formFieldErrorContext?: FormFieldErrorContext,
	onChange: (accountingDocument: AccountingDocument) => void,
|}

export default class BankAccountsTemplate extends Component<Props> {
	findIndex = (bankAccount: AccountingDocumentBankAccount): number => {
		return findBankAccountIndex(this.props.accountingDocument.bankAccounts || [], bankAccount)
	}

	onBankAccountRemove = (accountingDocumentId: string, bankAccount: AccountingDocumentBankAccount) => {
		const foundIndex: number = this.findIndex(bankAccount)
		if (foundIndex > -1) {
			let bankAccounts = Array.from(this.props.accountingDocument.bankAccounts || [])
			bankAccounts.splice(foundIndex, 1)
			const newDocument: AccountingDocument = Object.assign(emptyAccountingDocument(), this.props.accountingDocument, {
				bankAccounts,
			})
			this.props.onChange(newDocument)
		}
	}

	onBankAccountChange = (accountingDocumentId: string, bankAccount: AccountingDocumentBankAccount) => {
		const foundIndex: number = this.findIndex(bankAccount)
		if (foundIndex < 0) {
			// New bank account
			bankAccount.localId =
				(this.props.accountingDocument.bankAccounts && this.props.accountingDocument.bankAccounts.length.toString()) ||
				'0'
			const oldBankAccounts = this.props.accountingDocument.bankAccounts || []
			const newDocument: AccountingDocument = Object.freeze({
				...this.props.accountingDocument,
				bankAccounts: [...oldBankAccounts, bankAccount],
			})
			this.props.onChange(newDocument)
		}
	}

	render() {
		return (
			<BankAccounts
				accountingDocumentId={this.props.accountingDocument.id || ''}
				loading={false}
				direction={this.props.direction}
				readonly={false}
				bankAccounts={this.props.accountingDocument.bankAccounts || EMPTY_ARRAY}
				formFieldErrorContext={this.props.formFieldErrorContext}
				onBankAccountRemove={this.onBankAccountRemove}
				onBankAccountAdd={this.onBankAccountChange}
			/>
		)
	}
}
