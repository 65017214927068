/* @flow */

import { connect } from 'react-redux'
import type { Dispatch, State, UserAction, UserProfile, OrganizationAction } from 'types'
import { type AutoTestProps } from 'utils/tests/autotest'
import {
	getCurrentOrganizationAccountant,
	getCurrentOrganizationWagesAccountant,
	getOrganizationUserAvatar,
} from 'modules/organization/selectors'
import support from '../components/support'
import { loadUserAvatar } from '../../organization/actions'
import type { UserAvatar } from '../../organization/types'

type Props = {|
	...AutoTestProps,
	accountantType?: 'wages' | 'main',
	organizationId: ?string,
|}

type StateProps = {|
	firstname: ?string,
	lastname: ?string,
	phone: ?string,
	email: ?string,
	id: ?string,
	avatarUrl: ?string,
|}

type DispatchProps = {|
	loadAvatar: (avatarId: string) => Promise<void>,
|}

const mapStateToProps = (state: State, ownProps: Props): StateProps => {
	let accountant: ?UserProfile
	if (ownProps.accountantType === 'wages') {
		accountant = getCurrentOrganizationWagesAccountant(state)
	} else {
		accountant = getCurrentOrganizationAccountant(state)
	}

	const accountantAvatar: ?UserAvatar =
		(accountant && accountant.id && getOrganizationUserAvatar(state, accountant.id)) || null
	const avatarUrl = accountantAvatar && accountantAvatar.avatar

	return {
		firstname: accountant ? accountant.firstname : null,
		lastname: accountant ? accountant.lastname : null,
		phone: accountant ? accountant.phone : null,
		email: accountant ? accountant.email : null,
		id: accountant ? accountant.id : null,
		avatarUrl: accountant && accountant.id ? avatarUrl : null,
	}
}

const mapDispatchToProps = (dispatch: Dispatch<UserAction | OrganizationAction>): DispatchProps => {
	return {
		loadAvatar: async (avatarId: string) => {
			await dispatch(loadUserAvatar(avatarId))
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(support)
