/* @flow */
/** @jsx jsx */

import { Component, type Node } from 'react'
import { jsx } from '@emotion/core'
import type { SearchFilter } from 'types'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import NumberInput from 'components/number-input'

type Props = {|
	name: string,
	className?: string,
	filter?: SearchFilter,
	onChange?: (filter: SearchFilter) => void,
	...WithTranslateProps,
|}

type State = {|
	clientError: Node,
	isValid: boolean,
	isMicrovalidationEnabled: boolean,
	filter: SearchFilter,
|}

class FilterRange extends Component<Props, State> {
	state = {
		clientError: null,
		isValid: true,
		isMicrovalidationEnabled: false,
		filter: {
			valueFrom: null,
			valueTo: null,
		},
	}
	_isMounted = false

	constructor(props: Props & WithTranslateProps) {
		super(props)
		this.setFilter(props)
	}

	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		this.setFilter(nextProps)
	}

	componentDidMount() {
		this._isMounted = true
		this.setFilter(this.props)
	}

	componentWillUnmount() {
		this._isMounted = false
	}
	setFilter(props: Props) {
		if (this._isMounted && props.filter) {
			this.setState({
				filter: {
					field: props.name,
					valueFrom: props.filter.valueFrom,
					valueTo: props.filter.valueTo,
				},
			})
		}
	}

	toInt(value: ?string): ?number {
		return parseInt(value, 10) || null
	}

	handleBlur = () => {
		!this.state.clientError && this.props.onChange && this.props.onChange(this.state.filter)
	}

	handleFilterChange = (filter: SearchFilter) => {
		const newFilter = {
			...this.state.filter,
			...filter,
		}

		this.setState({
			clientError:
				newFilter.valueFrom !== null && newFilter.valueTo !== null && newFilter.valueFrom > newFilter.valueTo
					? this.props.t('application.filter.rangeReverted')
					: null,
			isMicrovalidationEnabled: true,
			filter: newFilter,
		})
	}

	handleFromChange = (event: SyntheticInputEvent<HTMLInputElement>, value: ?number) => {
		this.handleFilterChange({
			valueFrom: value,
		})
	}

	handleToChange = (event: SyntheticInputEvent<HTMLInputElement>, value: ?number) => {
		this.handleFilterChange({
			valueTo: value,
		})
	}

	render() {
		const { t } = this.props

		return (
			<div style={styles.root} className={this.props.className}>
				<div css={styles.from}>
					<NumberInput
						value={this.state.filter.valueFrom}
						clientError={this.state.isMicrovalidationEnabled ? this.state.clientError : null}
						onChange={this.handleFromChange}
						labelText={t('application.filter.rangeFrom')}
						onBlur={this.handleBlur}
						compact
						name="range-from"
					/>
				</div>
				<div css={styles.to}>
					<NumberInput
						value={this.state.filter.valueTo}
						clientError={this.state.isMicrovalidationEnabled ? this.state.clientError : null}
						onChange={this.handleToChange}
						labelText={t('application.filter.rangeTo')}
						onBlur={this.handleBlur}
						compact
						name="range-to"
					/>
				</div>
			</div>
		)
	}
}

const styles = {
	root: {
		display: 'flex',
	},
	from: {
		paddingRight: 4,
	},
	to: {
		paddingLeft: 4,
	},
}

export default withTranslate(FilterRange)
