/* @flow */

import React, { Component } from 'react'
import type { ScanFactoryField } from 'types'
import { type WithTranslateProps, withTranslate } from 'wrappers'
import { formatToDateString } from 'utils/formatters'
import { type FieldCursor } from '../types'
import DocumentFieldEditor from './document-field-editor'
import DocumentFieldName from './document-field-name'
import { IconButton, Button } from 'components'
import ContentClear from 'components/svg-icons/content/clear'
import { colors } from 'variables'
import styles from './document-field.css'
import { upperFirst } from 'lodash-es'

const getStyles = (props: Props, state: State) => {
	return {
		root: {
			padding: `7px 14px 7px ${14 + props.depth * 7}px`,
			cursor: props.active ? 'default' : 'pointer',
			transition: `all ${props.active ? 300 : 100}ms ease-in-out`,
			background: props.highlight
				? colors.redFaded15
				: props.active
				? colors.blueFaded60
				: state.hovered || props.field.value
				? colors.grey200
				: colors.white,
		},
		name: {
			fontSize: props.field.value ? 12 : 15,
			color: props.highlight ? colors.red : props.field.value ? colors.blackFaded80 : colors.black,
		},
		error: {
			color: colors.red,
		},
		value: {
			paddingTop: 2,
			fontSize: 16,
			color: props.highlight ? colors.red : colors.black,
		},
		reset: {
			float: 'right',
			padding: 0,
		},
	}
}

export type StateProps = {|
	editing: boolean,
	field: ScanFactoryField,
	fieldCursor: FieldCursor,
	active: boolean,
	highlight: boolean,
	depth: number,
	error: ?string,
|}

export type DispatchProps = {|
	onFieldClick: (field: FieldCursor) => void,
	onFieldDoubleClick: (field: FieldCursor) => void,
	onFieldResetClick: (field: FieldCursor) => void,
	onFieldValueChange: (field: FieldCursor, value?: string, hotkey?: string) => void,
	onFieldMouseOver: (field: FieldCursor) => void,
	onFieldMouseOut: (field: FieldCursor) => void,
|}

type Props = {| ...StateProps, ...DispatchProps, ...WithTranslateProps |}

type State = {|
	value: ?string,
	hovered: boolean,
|}

class DocumentField extends Component<Props, State> {
	constructor(props: Props) {
		super(props)

		this.state = {
			value: this.props.field.value,
			hovered: false,
		}
	}

	render() {
		const { field, editing, error } = this.props
		const style = getStyles(this.props, this.state)

		return (
			<li
				onClick={this.onFieldClick}
				onDoubleClick={this.onFieldDoubleClick}
				onMouseOver={this.onFieldMouseOver}
				onMouseOut={this.onFieldMouseOut}
				style={style.root}
				ref={'root'}
			>
				<div style={style.name}>
					<DocumentFieldName field={field} />
				</div>
				{error && <div style={style.error}>{this.props.t(`application.validation.invalid${upperFirst(error)}`)}</div>}
				{(field.value || editing) && (
					<div style={style.value}>
						{editing
							? this.renderEditValue(this.state.value || '', field.interpreterName || '')
							: this.renderValue(field.value || '', field.interpreterName || '')}
						{this.renderResetField(style.reset, !!field.value && !editing)}
					</div>
				)}
			</li>
		)
	}

	renderValue(value: string, interpreter: string) {
		return 'dateInterpreter' === interpreter ? formatToDateString(value) : value
	}

	renderEditValue(value: string, interpreter: string) {
		return (
			<div className={styles.edit}>
				<DocumentFieldEditor
					value={value}
					interpreter={interpreter}
					onChange={this.onValueChange}
					onSave={this.onValueSave}
					onCancel={this.onValueCancel}
				/>
				<span style={{ marginLeft: 5 }}>
					<Button
						tertiary
						type="submit"
						labelText={this.props.t('documentExtraction.submit')}
						onClick={this.onValueSave}
					/>
				</span>
			</div>
		)
	}

	renderResetField(style: Object, render: boolean) {
		if (!render) {
			return null
		}

		return (
			<IconButton
				style={style}
				onClick={this.onFieldResetClick}
				color={this.props.highlight ? colors.red : this.state.hovered ? colors.black : colors.gray}
				hoverColor={colors.blue}
				tooltip={this.props.t('documentExtraction.reset')}
				size={18}
			>
				<ContentClear size={18} />
			</IconButton>
		)
	}

	onFieldClick = () => {
		!this.props.editing && this.props.onFieldClick(this.props.fieldCursor)
	}

	onFieldDoubleClick = () => {
		!this.props.editing && this.props.onFieldDoubleClick(this.props.fieldCursor)
	}

	onFieldResetClick = () => {
		this.props.onFieldResetClick(this.props.fieldCursor)
	}

	onValueChange = (value: ?string) => {
		this.setState({ value })
	}

	onValueSave = (hotkey?: string) => {
		if ('string' !== typeof hotkey) hotkey = undefined
		this.props.onFieldValueChange(this.props.fieldCursor, this.state.value || '', hotkey)
	}

	onValueCancel = (hotkey?: string) => {
		this.setState({ value: this.props.field.value }, () => {
			this.props.onFieldValueChange(this.props.fieldCursor, undefined, hotkey)
		})
	}

	onFieldMouseOver = () => {
		this.setState({
			hovered: true,
		})
		this.props.onFieldMouseOver(this.props.fieldCursor)
	}

	onFieldMouseOut = () => {
		this.setState({
			hovered: false,
		})
		this.props.onFieldMouseOut(this.props.fieldCursor)
	}
}

export default withTranslate(DocumentField, { withRef: true })
