/* @flow */
/** @jsx jsx */

import { type AutoTestProps, autoTestId } from 'utils/tests/autotest'
import { Component, type Node } from 'react'
import { Config, createFilter } from 'react-select/lib/filters'
import { type WithTranslateProps, withTranslate } from 'wrappers'

import ClearIndicator from './clear-indicator'
import Creatable from 'react-select/lib/Creatable'
import DropdownIndicator from './dropdown-indicator'
import { EMPTY_OBJECT } from 'trivi-constants'
import { Input } from 'react-select/lib/animated'
import MultilineInput from '../favorite-auto-complete/MultilineInput'
import Option from './option'
import Select from 'react-select'
import Tooltip from 'components/tooltip'
import { colors } from 'variables'
import { jsx } from '@emotion/core'
import memoize from 'memoize-one'

export type OptionType = { value: ?string, label: ?string, labelPrefix?: ?string, rightText?: ?string }
export type OptionsType = Array<OptionType>
export type ValueType = ?OptionType | ?OptionsType
export type GroupType = {
	label: ?string,
	options: OptionsType,
}

export type ActionTypes =
	| 'clear'
	| 'create-option'
	| 'deselect-option'
	| 'pop-value'
	| 'remove-value'
	| 'select-option'
	| 'set-value'
export type ActionMeta = { action: ActionTypes }

export type FormatOptionLabelContext = 'menu' | 'value'
export type FormatOptionLabelMeta = {
	context: FormatOptionLabelContext,
	inputValue: string,
	selectValue: ValueType,
}

type Props = {|
	...AutoTestProps,
	...WithTranslateProps,
	options: OptionsType,
	error?: Node,
	label?: string,
	autoFocus?: boolean,
	compact?: boolean,
	inline?: boolean,
	autoWidth?: boolean,
	disabled?: boolean,
	editCursor?: boolean,
	placeholder?: string,
	value?: ?string,
	defaultValue?: ?string,
	defaultInputValue?: ?string,
	isClearable?: boolean,
	isSearchable?: boolean,
	isCreatable?: boolean,
	isHighlighted?: boolean,
	isHidden?: boolean,
	isMulti?: boolean,
	hideScrollbar?: boolean,
	portal?: boolean,
	getOptionLabel?: OptionType => string,
	iconButtonColor?: string,
	hideIndicator?: boolean,
	labelInRow?: boolean,
	tabIndex?: number,
	maxLength?: number,
	userPanelStyle?: boolean,
	openMenuPosition?: 'top' | 'bottom',
	formatOptionLabel?: (OptionType, FormatOptionLabelMeta) => Node,
	onChange: (ValueType, ActionMeta) => void,
	useKeyboardNavigation?: boolean,
	newLineTabIndex?: number,
	isInLastLineItem?: boolean,
	onLastLineEnter?: () => Promise<void>,
	multiline?: boolean,
	selectWithoutArrow?: boolean,
	useInputValue?: boolean,
	filterOptionConfig?: Config,
|}

type State = {|
	open: boolean,
	inputValue: string,
	wasArrowUsed: boolean,
|}

class SelectNext extends Component<Props, State> {
	defaultComponents = {
		DropdownIndicator: !this.props.hideIndicator ? DropdownIndicator : null,
		ClearIndicator,
		Option,
	}

	components = !this.props.useKeyboardNavigation
		? this.defaultComponents
		: {
				...this.defaultComponents,
				Input: (props: Object) => (
					<MultilineInput {...props} maxRows={this.props.autoWidth && this.props.isSearchable ? 1 : undefined} />
				),
		  }
	select: any = null

	state = {
		open: false,
		inputValue: '',
		wasArrowUsed: false,
	}

	onMenuOpen = () => void this.setState({ open: true })
	onMenuClose = () => void this.setState({ open: false })

	getNoOptionsMessage = () => this.props.t('application.noItems')

	formatCreateLabel = (value: string) => {
		return this.props.t('application.createOption', { value })
	}

	findOptionByValue = memoize((options: OptionsType, value: ?string | Array<string>) => {
		if (value !== null && value !== undefined && Array.isArray(value)) {
			return options.filter(
				(option: OptionType) =>
					value !== null &&
					value !== undefined &&
					option.value !== null &&
					option.value !== undefined &&
					value.includes(option.value),
			)
		} else {
			const found = options && options.find((option: OptionType) => option.value === value)
			return found ? found : value ? { value: value, label: value } : null
		}
	})

	onFocus = () => {
		this.forceUpdate()
	}

	getStyles = memoize(
		(
			compact?: boolean,
			inline?: boolean,
			autoWidth?: boolean,
			isSearchable?: boolean,
			isClearable?: boolean,
			disabled?: boolean,
			editCursor?: boolean,
			isInvalid?: boolean,
			isMulti?: boolean,
			isEmpty?: boolean,
			isHidden?: boolean,
			isHighlighted?: boolean,
			hideScrollbar?: boolean,
			menuOpenPosition?: string,
			userPanelStyle?: boolean,
			iconButtonColor?: string,
			setValue?: ?string,
			inputValue?: string,
			labelInRow?: boolean,
			multiline?: boolean,
			keyboard?: boolean,
		) => {
			const normalTheme = {
				invalid: {
					backgroundColor: colors.redFaded4,
					borderColor: colors.red,
					'&:hover': {
						borderColor: colors.red,
					},
				},
				disabled: {
					backgroundColor: '#f9f9f9',
					borderColor: colors.grayLight200,
				},
				focused: {
					backgroundColor: colors.blue100Faded40,
					borderColor: colors.blue,
					'&:hover': {
						borderColor: colors.blue,
					},
				},
				normal: {
					backgroundColor: colors.white,
					borderColor: colors.gray400,
					'&:hover': {
						borderColor: colors.blackFaded40,
					},
				},
			}

			const inlineTheme = {
				invalid: {
					backgroundColor: colors.redFaded4,
					borderColor: colors.red,
					'&:hover': {
						borderColor: colors.red,
					},
				},
				disabled: {
					backgroundColor: colors.transparent,
					borderColor: colors.transparent,
				},
				focused: {
					backgroundColor: isEmpty ? 'transparent' : colors.blue100Faded40,
					borderColor: isEmpty ? 'transparent' : colors.blue,
					'&:hover': {
						backgroundColor: isEmpty ? 'transparent' : colors.blue100Faded40,
						borderColor: isEmpty ? 'transparent' : colors.blue,
					},
				},
				normal: {
					backgroundColor: colors.transparent,
					borderColor: colors.transparent,
					'&:hover': {
						borderColor: isEmpty ? 'transparent' : colors.blueFaded40,
						backgroundColor: isEmpty ? 'transparent' : colors.blue100Faded40,
					},
				},
			}

			const userPanelInlineTheme = {
				invalid: {
					backgroundColor: colors.redFaded4,
					borderColor: colors.red,
					'&:hover': {
						borderColor: colors.red,
					},
				},
				disabled: {
					backgroundColor: colors.transparent,
					borderColor: colors.transparent,
				},
				focused: {
					backgroundColor: colors.blue,
					borderColor: colors.blue,
					'&:hover': {
						borderColor: colors.blue,
						backgroundColor: colors.blue,
					},
				},
				normal: {
					backgroundColor: colors.blue,
					borderColor: colors.blue,
					'&:hover': {
						borderColor: colors.blue,
						backgroundColor: colors.blue,
					},
				},
			}

			const theme = userPanelStyle ? userPanelInlineTheme : inline ? inlineTheme : normalTheme

			const openMenuPosition =
				menuOpenPosition === 'top'
					? {
							top: null,
							bottom: '100%',
					  }
					: EMPTY_OBJECT

			const scrollbar = hideScrollbar
				? {
						maxHeight: 310,
						overflowY: 'hidden',
				  }
				: EMPTY_OBJECT

			return {
				root: {
					visibility: isHidden ? 'hidden' : undefined,
					position: 'relative',
					display: (inline && autoWidth) || labelInRow ? 'inline-flex' : 'block',
					maxWidth: '100%',
					width: labelInRow ? '100%' : undefined,
					'&:hover': {
						'.dropdown-indicator__arrow': {
							svg: {
								fill: inline && isEmpty ? `${colors.blue} !important` : undefined,
							},
						},
					},
				},
				tooltip: {
					maxWidth: '100%',
					width: labelInRow ? '100%' : undefined,
					cursor: 'text',
				},
				label: {
					display: 'block',
					color: colors.black,
					whiteSpace: 'nowrap',
					fontSize: 14,
					paddingBottom: inline ? 0 : 7,
					lineHeight: inline ? '24px' : '17px',
					marginTop: labelInRow && 8,
					marginRight: labelInRow && 8,
					cursor: disabled ? (editCursor ? 'text' : 'default') : 'pointer',
				},
				indicatorSeparator: () => ({ display: 'none' }),
				indicatorsContainer: (provided: Object) => ({
					...provided,
					marginRight: inline ? 0 : compact ? 2 : 8,
				}),
				dropdownIndicator: (provided: Object) => ({
					...provided,
					display: provided.display,
					padding: compact || inline ? 0 : provided.padding,
					alignItems: 'center',
					cursor: 'pointer',
					height: inline ? 22 : provided.height,
					width: inline ? 22 : provided.width,
					indicatorColor: iconButtonColor && iconButtonColor,
					indicatorHoverColor: iconButtonColor && iconButtonColor,
				}),
				clearIndicator: (provided: Object) => ({
					...provided,
					marginRight: compact ? 0 : 4,
				}),
				multiValueRemove: (provided: Object) => ({
					...provided,
					cursor: 'default',
				}),
				control: (provided: Object, state: Object) => ({
					...provided,
					...(isInvalid
						? theme.invalid
						: state.isDisabled
						? theme.disabled
						: state.isFocused
						? theme.focused
						: theme.normal),
					minHeight: inline ? 22 : compact ? 32 : 46,
					height: compact && !isMulti ? 32 : provided.height,
					borderRadius: compact || inline ? 2 : 3,
					boxShadow: 'none',
					width: labelInRow ? '100%' : undefined,
					marginLeft: isHighlighted && '2px',
					fontSize: 14,
					'.select__value-container': {
						border: state.isFocused && isHighlighted && `1px solid ${colors.blue}`,
						width: isHighlighted && '42px',
					},
					'.select__value-container div:not(.class)': {
						width:
							isHighlighted &&
							((!setValue && inputValue) || (setValue && inputValue) || (!setValue && !inputValue)) &&
							'32px',
					},
					cursor: isSearchable ? 'text' : 'pointer',
					'.select__single-value': {
						color:
							state.isFocused && state.menuIsOpen && userPanelStyle
								? colors.white
								: state.isFocused && state.menuIsOpen
								? colors.grey
								: disabled && !inline
								? colors.disabledText
								: userPanelStyle
								? colors.white
								: colors.black,
					},
					'.select__control--is-disabled': {
						pointerEvents: 'none!important',
						userSelect: 'text!important',
					},
				}),
				valueContainer: (provided: Object) => ({
					...provided,
					paddingTop: 1,
					paddingBottom: 1,
					paddingLeft: inline && isEmpty && !isSearchable ? 0 : inline ? 1 : compact ? 8 : 15,
					paddingRight: inline && isEmpty && !isSearchable ? 0 : inline ? 1 : compact ? 8 : 15,
					flexDirection: autoWidth && keyboard ? 'row' : autoWidth ? 'row-reverse' : provided.flexDirection,
					minHeight: inline && keyboard ? 22 : provided.minHeight,
				}),
				placeholder: (provided: Object) => ({
					...provided,
					color: colors.blackFaded40,
					marginLeft: autoWidth && isSearchable && !keyboard ? -4 : provided.marginLeft,
					position: autoWidth && !keyboard ? 'static' : provided.position,
					transform: autoWidth && !keyboard ? 'none' : provided.transform,
					lineHeight: '20px',
				}),
				input: (provided: Object) => ({
					...provided,
					fontSize: 14,
					color: colors.black,
					padding: inline ? 0 : provided.padding,
					margin: inline ? '0 2px' : provided.margin,
					height: inline ? 20 : provided.height,
					lineHeight: inline ? '20px' : provided.lineHeight,
					div: {
						overflow: 'hidden !important',
						verticalAlign: 'baseline',
					},
					input: {
						fontFamily: 'inherit',
					},
				}),
				singleValue: (provided: Object) => ({
					...provided,
					paddingLeft: keyboard ? 5 : undefined,
					position: autoWidth && !keyboard ? 'static' : provided.position,
					transform: autoWidth && !keyboard ? 'none' : provided.transform,
					marginLeft: autoWidth && isSearchable ? -3 : provided.marginLeft,
					marginRight: autoWidth && isSearchable ? 5 : provided.marginRight,
					maxWidth: autoWidth && isSearchable ? provided.maxWidth : 'none',
					textOverflow: autoWidth ? 'clip' : 'ellipsis',
					lineHeight: inline ? '20px' : provided.lineHeight,
					color: userPanelStyle && colors.white,
				}),
				menuPortal: (provided: Object) => ({
					...provided,
					zIndex: 2000,
				}),
				menu: (provided: Object) => ({
					...provided,
					...openMenuPosition,
					borderWidth: 1,
					borderStyle: 'solid',
					borderColor: colors.gray400,
					borderRadius: compact || inline ? 2 : 3,
					boxShadow: `${colors.blackFaded8} 4px 4px 0`,
					width: 'fit-content',
					minWidth: autoWidth ? 0 : provided.width,
					whiteSpace: autoWidth ? 'nowrap' : provided.whiteSpace,
					overflow: 'hidden',
					maxWidth: 580,
					zIndex: 2000,
				}),
				menuList: (provided: Object) => ({
					...provided,
					...scrollbar,
					paddingTop: 3,
					paddingBottom: 3,
					div: {
						color: userPanelStyle && colors.black + '!important',
					},
				}),
				noOptionsMessage: (provided: Object) => ({
					...provided,
					fontSize: 14,
					color: colors.grey,
				}),
			}
		},
	)

	onInputChange = (inputValue: any) => {
		this.setState({ inputValue: inputValue })

		if (this.props.maxLength == null) {
			return inputValue
		}
		if (typeof inputValue !== 'string') {
			return inputValue
		}

		return inputValue.length <= this.props.maxLength ? inputValue : inputValue.substr(0, this.props.maxLength)
	}

	onLabelClick = () => void (this.select && this.select.focus && this.select.focus())

	bindSelect = (element: ?HTMLElement) => {
		this.select = element
	}

	isEmpty = () => {
		return !this.props.value && (!this.props.placeholder || !this.props.placeholder.length)
	}

	handleKeyPress = async (event: KeyboardEvent) => {
		if ('Escape' === event.key) {
			event.preventDefault()
			this.select && this.select.blur()
		}
		if ('Enter' === event.key) {
			event.preventDefault()
			if (this.select && this.select.select.state.focusedOption) {
				if (this.state.wasArrowUsed) {
					this.props.onChange(this.select.select.state.focusedOption, { action: 'select-option' })
				}
				if (this.props.selectWithoutArrow && !this.state.wasArrowUsed) {
					this.props.onChange(
						this.props.useInputValue
							? { value: this.select.state.inputValue, label: this.select.state.inputValue.toString() }
							: this.select.select.state.focusedOption,
						{ action: this.props.useInputValue ? 'set-value' : 'select-option' },
					)
				}
				this.select.blur()

				if (this.state.wasArrowUsed) {
					document.querySelectorAll(`[tabindex="${this.props.tabIndex + 1}"]`)[0].focus()
				} else {
					if (this.props.isInLastLineItem) {
						this.props.onLastLineEnter && (await this.props.onLastLineEnter())
					}
					if (this.props.newLineTabIndex && this.props.isInLastLineItem) {
						document.querySelectorAll(`[tabindex="${this.props.newLineTabIndex}"]`)[0].focus()
					} else {
						document.querySelectorAll(`[tabindex="${this.props.tabIndex + 1}"]`)[0].focus()
					}
				}
				this.setState({ wasArrowUsed: false })
			}
		}

		if ('Tab' === event.key) {
			event.preventDefault()
			if (this.select && this.select.select.state.focusedOption) {
				if (this.state.wasArrowUsed) {
					this.props.onChange(this.select.select.state.focusedOption, { action: 'select-option' })
				}
				if (this.props.selectWithoutArrow && !this.state.wasArrowUsed) {
					this.props.onChange(this.select.select.state.focusedOption, { action: 'select-option' })
				}
				this.select.blur()
				document.querySelectorAll(`[tabindex="${this.props.tabIndex + 1}"]`)[0].focus()
				this.setState({ wasArrowUsed: false })
			}
		}

		if ('ArrowUp' === event.key || 'ArrowDown' === event.key) {
			this.setState({ wasArrowUsed: true })
		}
	}

	render() {
		const styles = this.getStyles(
			this.props.compact,
			this.props.inline,
			this.props.autoWidth,
			this.props.isSearchable,
			this.props.isClearable,
			this.props.disabled,
			this.props.editCursor,
			!!this.props.error,
			this.props.isMulti,
			this.isEmpty(),
			this.props.isHidden,
			this.props.isHighlighted,
			this.props.hideScrollbar,
			this.props.openMenuPosition,
			this.props.userPanelStyle,
			this.props.iconButtonColor,
			this.props.value,
			this.state.inputValue,
			this.props.labelInRow,
			this.props.multiline,
			this.props.useKeyboardNavigation,
		)
		const SelectComponent = this.props.isCreatable ? Creatable : Select

		return (
			<div css={styles.root} {...autoTestId(this.props.autoTestId)}>
				{this.props.label && (
					<label onClick={this.onLabelClick} style={styles.label}>
						{this.props.label}
					</label>
				)}
				<Tooltip
					disabled={this.state.open}
					inline={!!this.props.inline && !!this.props.autoWidth}
					label={!this.props.disabled ? this.props.error : ''}
					wrapperStyle={styles.tooltip}
					editCursor={this.props.disabled || this.props.editCursor}
				>
					<SelectComponent
						ref={this.bindSelect}
						onInputChange={this.onInputChange}
						isSearchable={!!this.props.isSearchable}
						isClearable={this.props.isClearable}
						isMulti={this.props.isMulti}
						options={this.props.options}
						isDisabled={this.props.disabled}
						autoFocus={this.props.autoFocus}
						placeholder={this.props.placeholder || ''}
						onChange={this.props.onChange}
						onFocus={this.onFocus}
						noOptionsMessage={this.getNoOptionsMessage}
						formatCreateLabel={this.formatCreateLabel}
						components={this.components}
						defaultInputValue={this.props.defaultInputValue}
						value={this.findOptionByValue(this.props.options, this.props.value)}
						formatOptionLabel={this.props.formatOptionLabel}
						getOptionLabel={this.props.getOptionLabel}
						menuPortalTarget={this.props.portal ? document.getElementById('render-to-layer') : null}
						closeMenuOnScroll={this.props.portal}
						tabIndex={this.props.tabIndex}
						onMenuClose={this.onMenuClose}
						onMenuOpen={this.onMenuOpen}
						menuPlacement="auto"
						menuShouldScrollIntoView
						classNamePrefix={'select'}
						menuShouldBlockScroll
						openMenuOnFocus
						styles={styles}
						onKeyDown={this.props.useKeyboardNavigation ? this.handleKeyPress : undefined}
						filterOption={createFilter(this.props.filterOptionConfig)}
					/>
				</Tooltip>
			</div>
		)
	}
}

export default withTranslate(SelectNext)
