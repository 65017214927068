/* @flow */
/** @jsx jsx */

import { Component } from 'react'
import { jsx } from '@emotion/core'
import { withRouter } from 'react-router-dom'
import type { WithRouterProps } from 'types'
import {
	dashboardRoute,
	todoRoute,
	todoBankRoute,
	reportsRoute,
	todoHistoryRoute,
	todoBankRouteFiltered,
} from '../routing/routes'
import withTranslate, { type Props as WithTranslateProps } from 'wrappers/with-translate'
import InvitationBannerSwitch from '../containers/invitation/invitation-banner-switch'
import { Tab, Tabs } from '../../../components/Tabs'

const styles = {
	root: { marginTop: 10 },
	tabs: { marginTop: 10 },
	todo: {
		display: 'flex',
		alignItems: 'center',
	},
	circle: {
		marginLeft: 7,
		width: 28,
		lineHeight: '28px',
		borderRadius: '50%',
		fontSize: 18,
		color: 'white',
		backgroundColor: '#24a7c3',
		textAlign: 'center',
	},
	todoLabelContainer: { display: 'flex', alignItems: 'center' },
	tabItemContainerStyle: { border: 'none' },
	label: {
		display: 'flex',
		alignItems: 'flex-end',
	},
	hardSpace: {
		marginRight: 5,
	},
}

type Props = {|
	...WithTranslateProps,
	...WithRouterProps,
	uncompletedCardsCount: number,
	showInvitationBanner: boolean,
|}

class Menu extends Component<Props> {
	render() {
		return (
			<div css={styles.root}>
				{this.props.showInvitationBanner && <InvitationBannerSwitch />}
				<div css={styles.tabs}>
					<Tabs
						large
						autoTestId="dashboard-menu-tabs"
						value={this.getActiveTab()}
						tabItemContainerStyle={styles.tabItemContainerStyle}
					>
						<Tab
							value={'dashboard'}
							label={this.renderDashboardLink()}
							onActive={this.goToDashboard}
							designVersion="2"
						/>
						<Tab
							value={'todo'}
							label={
								<div css={styles.todo}>
									<div>{this.props.t('dashboard.todo.headline')}</div>
									{!!this.props.uncompletedCardsCount && (
										<div css={styles.circle}>{this.props.uncompletedCardsCount}</div>
									)}
								</div>
							}
							onActive={this.goToTodo}
							designVersion="2"
						/>
						<Tab
							value={'reports'}
							label={this.props.t('dashboard.reports.headline')}
							onActive={this.goToReports}
							designVersion="2"
						/>
					</Tabs>
				</div>
			</div>
		)
	}

	getBankRoutes() {
		return [
			todoBankRouteFiltered(undefined, 'waiting'),
			todoBankRouteFiltered(undefined, 'processing'),
			todoBankRouteFiltered(undefined, 'done'),
		]
	}

	getActiveTab() {
		if (
			[todoRoute(), todoBankRoute(), todoHistoryRoute(), ...this.getBankRoutes()].includes(this.props.location.pathname)
		) {
			return 'todo'
		} else if (reportsRoute() === this.props.location.pathname) {
			return 'reports'
		} else {
			return 'dashboard'
		}
	}

	goToDashboard = () => {
		this.props.history.push(dashboardRoute())
	}

	goToTodo = () => {
		this.props.history.push(todoRoute())
	}

	goToReports = () => {
		this.props.history.push(reportsRoute())
	}

	renderDashboardLink() {
		return this.props.t('dashboard.userDashboard.title')
	}
}

export default withRouter(withTranslate(Menu))
