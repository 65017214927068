// @flow
/** @jsx jsx */

// $FlowFixMe - there is useState
import { Fragment, useState } from 'react'
import { jsx } from '@emotion/core'
import type { EmailTemplate } from 'types'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import EditEmailTemplate from '../../containers/edit-email-template'
import TableHeaderCell from 'components/table/table-header-cell'
import TableHeader from 'components/table/table-header'
import EmailTemplateItem from './email-template-item'
import TableBody from 'components/table/table-body'
import TableRow from 'components/table/table-row'
import DeleteDialog from 'components/delete-dialog'
import Table from 'components/table/table'
import Button from 'components/button'

export type Props = {|
	...WithTranslateProps,
	emailTemplates: ?Array<EmailTemplate>,
	editEmailTemplate: (emailTemplateId: string, emailTemplate: EmailTemplate) => void,
	deleteEmailTemplate: (emailTemplateId: string) => void,
|}

function Emails(props: Props) {
	const [edit, setEdit] = useState<string>(undefined)
	const [create, setCreate] = useState<boolean>(false)

	function editTemplate(id: string) {
		setEdit(id)
		setCreate(true)
	}

	function createTemplate() {
		setEdit(undefined)
		setCreate(true)
	}

	function closeDialog() {
		setEdit(undefined)
		setCreate(false)
	}

	function onDelete(id: string) {
		DeleteDialog().then(() => {
			props.deleteEmailTemplate(id)
		})
	}

	return (
		<Fragment>
			<span>
				<Button
					primary
					autoTestId="settings-emails-new"
					labelText={props.t('settings.emails.newEmailTemplate')}
					onClick={createTemplate}
				/>
			</span>
			<div css={style.table}>
				<Table>
					<TableHeader>
						<TableRow>
							<TableHeaderCell width="65%">{props.t('settings.emails.name')}</TableHeaderCell>
							<TableHeaderCell width="35%">{props.t('settings.emails.type')}</TableHeaderCell>
							<TableHeaderCell width={35} />
						</TableRow>
					</TableHeader>
					<TableBody>
						{props.emailTemplates && !!props.emailTemplates.length
							? props.emailTemplates.map((template: EmailTemplate) => (
									<EmailTemplateItem
										key={template.id || template.name}
										template={template}
										onEdit={editTemplate}
										onDelete={onDelete}
									/>
							  ))
							: null}
					</TableBody>
				</Table>
			</div>
			{(edit || create) && <EditEmailTemplate open id={edit} onRequestClose={closeDialog} />}
		</Fragment>
	)
}

const style = {
	table: {
		marginTop: 25,
	},
}

export default withTranslate(Emails)
