import React from 'react'
import SvgIcon from 'components/SvgIcon'
import pure from 'recompose/pure'

let LargeExcel = props => {
	props = {
		viewBox: '0 0 24 30',
		...props,
	}
	return (
		<SvgIcon {...props}>
			<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g transform="translate(-557.000000, -10404.000000)">
					<g id="excel-ico-24" transform="translate(557.000000, 10404.000000)">
						<path
							d="M2.985,0 C1.335,0 0,1.35 0,3 L0.015,27 C0.015,28.65 1.35,30 3,30 L15,30 L24,21 L24,3 C24,1.35 22.65,0 21,0 L2.985,0 Z"
							fill="#34424E"
							transform="translate(12.000000, 15.000000) scale(1, -1) translate(-12.000000, -15.000000) "
						/>
						<path
							d="M16.5374449,22.4361233 L13.9603524,19.3744493 L16.3832599,16.5991189 C16.5594714,16.3788546 16.7797357,15.9823789 16.7797357,15.5638767 C16.7797357,14.7488987 16.1189427,14 15.215859,14 C14.8414097,14 14.4229075,14.0881057 14.0704846,14.5286344 L12,17.0837004 L9.90748899,14.5286344 C9.57709251,14.0881057 9.13656388,14 8.78414097,14 C7.88105727,14 7.19823789,14.7488987 7.19823789,15.5638767 C7.19823789,15.9823789 7.4185022,16.3788546 7.59471366,16.5991189 L10.0176211,19.3964758 L7.44052863,22.4361233 C7.22026432,22.6784141 7,23.0748899 7,23.4713656 C7,24.2643172 7.63876652,25.0352423 8.54185022,25.0352423 C8.98237885,25.0352423 9.40088106,24.9030837 9.68722467,24.5286344 L11.9779736,21.6211454 L14.2907489,24.5506608 C14.5770925,24.9251101 14.9955947,25.0352423 15.4361233,25.0352423 C16.339207,25.0352423 17,24.2643172 17,23.4713656 C17,23.0748899 16.7797357,22.6784141 16.5374449,22.4361233 Z"
							fill="#FFFFFF"
						/>
						<polygon
							fill="#52D046"
							transform="translate(19.500000, 4.500000) rotate(-90.000000) translate(-19.500000, -4.500000) "
							points="15 9 15 0 24 0"
						/>
					</g>
				</g>
			</g>
		</SvgIcon>
	)
}
LargeExcel = pure(LargeExcel)

export default LargeExcel
