// @flow
/** @jsx jsx */

import { Component } from 'react'
import memoize from 'memoize-one'
import { jsx } from '@emotion/core'
import type { Currency, FormFieldErrorMessage } from 'types'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import { type AutoTestProps } from 'utils/tests/autotest'
import SelectNext from 'components/select-next'
import { EMPTY_ARRAY } from 'trivi-constants'
import { getTooltipErrorLabel } from 'helpers'
import type { OptionsType, OptionType } from 'components/select-next'

type Props = {|
	...AutoTestProps,
	...WithTranslateProps,
	currencies: ?Array<Currency>,
	autoFocus?: boolean,
	allowEmpty?: boolean,
	emptyText?: string,
	labelText?: string,
	disabled?: boolean,
	fullWidth?: boolean,
	portal?: boolean,
	inline?: boolean,
	compact?: boolean,
	onlyUsedInOrg?: boolean,
	value?: ?string,
	isHighlighted?: boolean,
	onChange?: (?Currency) => void,
	errors: ?Array<FormFieldErrorMessage>,
	removeError: () => void,
	loadCurrencies: () => void,
|}

class CurrencySelector extends Component<Props> {
	select: any

	static defaultProps = {
		currencies: EMPTY_ARRAY,
		emptyText: '',
		value: null,
	}

	componentDidMount() {
		if (!this.props.currencies) {
			this.props.loadCurrencies()
		}
	}

	focus() {
		this.select && this.select.getWrappedInstance().focus && this.select.getWrappedInstance().focus()
	}

	handleCurrencyChange = (currencyOption: ?OptionType | ?OptionsType) => {
		let currency: ?Currency

		let currencyId = currencyOption && currencyOption.value && currencyOption.value

		currency =
			this.props.currencies &&
			this.props.currencies.find((c: Currency) => {
				return c.id === currencyId
			})

		this.props.onChange && this.props.onChange(currency)
		this.props.removeError()
	}

	getOptions = memoize((currencies: ?Array<Currency>, allowEmpty: ?boolean, text: ?string) => {
		const options = currencies
			? currencies.map((currency: Currency) => ({ value: currency.id, label: currency.id }))
			: EMPTY_ARRAY

		// $FlowFixMe
		return [allowEmpty ? { value: null, label: text } : [], ...options]
	})

	render() {
		const { errors, t } = this.props

		return (
			<SelectNext
				label={this.props.labelText}
				disabled={this.props.disabled}
				autoWidth={this.props.onlyUsedInOrg || !this.props.fullWidth}
				inline={this.props.inline}
				value={this.props.value}
				autoFocus={this.props.autoFocus}
				onChange={this.handleCurrencyChange}
				compact={this.props.compact}
				autoTestId={this.props.autoTestId}
				error={getTooltipErrorLabel(errors, t)}
				isSearchable
				options={this.getOptions(this.props.currencies, this.props.allowEmpty, this.props.emptyText)}
				portal={this.props.portal}
				isHighlighted={this.props.isHighlighted}
			/>
		)
	}
}

export default withTranslate(CurrencySelector)
