/* @flow */
/** @jsx jsx */

// $FlowFixMe - there is useState
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import moment from 'moment'
import { jsx, keyframes } from '@emotion/core'
import { getTooltipErrorLabel } from 'helpers'
import type { FormFieldErrorMessage, AccountingDocument, FormFieldErrorContext } from 'types'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import Autorenew from 'components/svg-icons/action/autorenew'
import Clear from 'components/svg-icons/content/clear'
import CurrencyInput from 'components/currency-input'
import IconButton from 'components/icon-button'
import Tooltip from 'components/tooltip'

type Props = {|
	...WithTranslateProps,
	name?: string,
	value: ?number,
	exchRate: ?number,
	vatExchRate: ?number,
	sadExchRate: ?number,
	exchRateDefault: ?number,
	compact?: boolean,
	disabled?: boolean,
	fullWidth?: boolean,
	defaultValue: ?number,
	isTemplate?: boolean,
	isInHeader?: boolean,
	template?: ?AccountingDocument,
	errors?: ?Array<FormFieldErrorMessage>,
	formFieldErrorContext?: FormFieldErrorContext,
	accountingDocumentId: string,
	taxDate?: ?Date,
	onRefresh: () => Promise<any>,
	onChange: (exchRate: ?number) => void,
	changeExchRateValue?: (exchRate: ?number) => void,
	onTemplateChange?: (accountingDocument: AccountingDocument) => void,
	removeError?: () => void,
	currency?: ?string,
|}

function ExchangeRateInput(props: Props) {
	const { onRefresh, removeError, onChange, value, changeExchRateValue, currency, taxDate } = props

	const rate = value
	const [inputValue, setInputValue] = useState<null | number>(isNaN(rate) ? null : rate)
	const [loading, setLoading] = useState<boolean>(false)

	const isCustomRate = !!inputValue
	const isNoRate = !isCustomRate
	const isRefreshEnabled = useMemo(() => {
		return taxDate && moment(taxDate).isSameOrBefore(moment(), 'day', '[]')
	}, [taxDate])

	const isDefaultUsed = props.value === props.exchRateDefault

	const errorTooltip = getTooltipErrorLabel(props.errors, props.t)

	useEffect(() => setInputValue(rate), [rate])

	useEffect(() => removeError && removeError(), [currency])
	useEffect(() => {
		removeError && removeError()
	}, [isRefreshEnabled])

	const handleRefresh = useCallback(async () => {
		if (!isRefreshEnabled) return
		setLoading(true)
		await onRefresh()
		setLoading(false)
		setInputValue(rate)
		changeExchRateValue && changeExchRateValue(inputValue)
	}, [isRefreshEnabled, onRefresh, changeExchRateValue, inputValue, rate])

	const handleChange = useCallback((event: ?SyntheticInputEvent<HTMLInputElement>, exchRate: ?number) => {
		setInputValue(isNaN(exchRate) ? null : exchRate)
	}, [])

	const handleReset = useCallback(() => {
		setInputValue(null)
		onChange(null)
		removeError && removeError()
		changeExchRateValue && changeExchRateValue(inputValue)
	}, [onChange, removeError, changeExchRateValue, inputValue])

	const handleBlur = useCallback(() => {
		inputValue !== value && onChange(inputValue)
		inputValue !== value && removeError && removeError()
		changeExchRateValue && changeExchRateValue(inputValue)
	}, [inputValue, value, onChange, removeError, changeExchRateValue])

	const styles = useMemo(() => {
		return {
			container: {
				position: 'relative',
			},
			icon: {
				position: 'absolute',
				bottom: props.compact ? 8 : 16,
				transition: 'opacity 200ms linear',
				animationName: loading ? spin : 'none',
				animationIterationCount: 'infinite',
				animationTimingFunction: 'linear',
				animationDuration: '1000ms',
				cursor: loading || !isRefreshEnabled ? 'default' : 'pointer',
				opacity: loading || !isRefreshEnabled ? 0.4 : 1,
				display: 'flex',
				zIndex: 100,
				right: 8,
			},
			input: {
				paddingRight: props.compact ? 22 : 14,
				opacity: isDefaultUsed ? 0.4 : 1,
			},
		}
	}, [props.compact, props.isInHeader, loading, isRefreshEnabled, isDefaultUsed])

	const input = (
		<CurrencyInput
			precision={3}
			value={inputValue === 0 ? null : inputValue}
			onBlur={handleBlur}
			onChange={handleChange}
			name={props.name}
			compact={props.compact}
			disabled={props.disabled}
			fullWidth={props.fullWidth}
			inputStyle={styles.input}
			labelText={props.t('invoice.settings.booking.exchRateLabel')}
			hintText={
				props.isInHeader
					? props.t('invoice.settings.booking.exchRateLabel')
					: props.t('invoice.settings.booking.exchRateSetRateHint').toString()
			}
			clientError={errorTooltip}
			hasButton={props.isInHeader}
			useStyle
		/>
	)

	const content = (
		<>
			{props.isInHeader && !props.isTemplate && !inputValue && !errorTooltip ? (
				<Tooltip label={props.t('invoice.settings.booking.exchRateHeaderSetRateHint')}>{input}</Tooltip>
			) : (
				input
			)}
			{isCustomRate && !props.isInHeader && !isDefaultUsed && (
				<div css={styles.icon}>
					<IconButton tooltip={props.t('invoice.settings.booking.resetExchRate')} onClick={handleReset} size={16}>
						<Clear />
					</IconButton>
				</div>
			)}
			{!props.isTemplate && isNoRate && (
				<div css={styles.icon}>
					<IconButton
						tooltip={
							isRefreshEnabled
								? props.t('invoice.settings.booking.refreshExchRate')
								: props.t('invoice.settings.booking.cantRefreshExchRate')
						}
						disabled={loading}
						onClick={handleRefresh}
						size={16}
					>
						<Autorenew />}
					</IconButton>
				</div>
			)}
		</>
	)

	if (props.isTemplate && props.isInHeader) {
		return null
	}

	return (
		<div css={styles.container}>
			{props.isTemplate ? (
				<Tooltip label={props.t('invoice.settings.booking.templateExchRate')}>{content}</Tooltip>
			) : (
				content
			)}
		</div>
	)
}

const spin = keyframes`
	0%{
		transform: rotate(0deg);
	}
	100%{
		transform: rotate(360deg);
	}
`

export default withTranslate(ExchangeRateInput)
