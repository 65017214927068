// @flow
/** @jsx jsx */

import { jsx } from '@emotion/core'
import { getPlaceholders } from '../../domain/emails'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import CopyToClipboard from 'components/copy-to-clipboard'
import { colors } from 'variables'

type Props = {|
	...WithTranslateProps,
	type?: number,
|}

function Placeholders(props: Props) {
	const placeholders = getPlaceholders(props.type)
	if (!placeholders) return null

	return (
		<div css={styles.helper}>
			<div css={styles.helperHeadline}>{props.t('settings.emails.placeholderInfo')}</div>
			<div css={styles.helperBox}>
				{placeholders.map((placeholder: string, index: number) => (
					<div key={'placeholder-' + index} css={styles.placeholder}>
						<div css={styles.placeholderCode}>
							<CopyToClipboard
								textToCopy={placeholder}
								hoverStyle={{ color: colors.blue, textDecoration: 'underline' }}
							>
								{placeholder}
							</CopyToClipboard>
						</div>
						<div css={styles.placeholderInfo}>{props.t(`settings.emails.placeholders.${placeholder}`)}</div>
					</div>
				))}
			</div>
		</div>
	)
}

const styles = {
	hover: {
		color: colors.blue,
		textDecoration: 'underline',
	},
	helper: {
		margin: '20px 0',
	},
	helperHeadline: {
		fontSize: 14,
		lineHheight: '17px',
		marginBottom: 10,
	},
	helperBox: {
		borderRadius: 2,
		backgroundColor: colors.blue100,
		padding: '15px 25px',
	},
	helperBoxPlaceholder: {
		display: 'inline-flex',
	},
	placeholder: {
		lineHeight: '17px',
		padding: '6px 0',
		fontSize: 14,
		display: 'flex',
	},
	placeholderCode: {
		fontSize: 14,
		fontFamily: '"Courier New", serif',
		flex: '0 0 50%',
		paddingRight: 15,
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
	},
	placeholderInfo: {
		flex: '0 0 50%',
		paddingLeft: 15,
	},
}

export default withTranslate(Placeholders)
