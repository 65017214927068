/* @flow */

import { set, upperFirst } from 'lodash-es'
import type { AccountingDocument, ScanFactoryDocumentFileMetadata } from 'types'
import {
	emptyAccountingDocument,
	emptyAccountingDocumentBankAccount,
	emptyAccountingDocumentLineItem,
	emptyAccountingDocumentVatRecapLine,
	emptyAccountingDocumentContact,
} from 'types/empty'
import type { FieldCursor } from '../types'
import { getField } from './field'

export function metadataToAccountingDocument(
	metadata: ScanFactoryDocumentFileMetadata,
	id: string,
): AccountingDocument {
	let accountingDocument: AccountingDocument = emptyAccountingDocument()
	accountingDocument.id = id

	accountingDocument = convertFields(metadata, accountingDocument)
	accountingDocument = convertBankAccounts(metadata, accountingDocument)
	accountingDocument = convertLineItems(metadata, accountingDocument)
	accountingDocument = convertRecapItems(metadata, accountingDocument)

	return accountingDocument
}

type ConvertMapEntry = { metadata: string, accountingDocument: string, convertFn?: string => ?any }
type ConvertMap = Array<ConvertMapEntry>

function createConvertMap(fields: Array<string>): ConvertMap {
	return fields.map((field: string) => ({
		metadata: upperFirst(field),
		accountingDocument: field,
	}))
}

function getFieldValue(metadata: ScanFactoryDocumentFileMetadata, fieldCursor: FieldCursor): ?string {
	const field = getField(metadata, fieldCursor)
	if (field) {
		return field.value
	}
}

function convertSection<T: Object>(
	metadata: ScanFactoryDocumentFileMetadata,
	object: T,
	convertMap: ConvertMap,
	section: string,
	index: number,
): T {
	convertMap.map((entry: ConvertMapEntry) => {
		const value = getFieldValue(metadata, { section, index, name: entry.metadata })
		if (value) {
			set(object, entry.accountingDocument, value)
		}

		return object
	})

	return object
}

function convertRepeatableSection<T: Array<Object>, R: Object>(
	metadata: ScanFactoryDocumentFileMetadata,
	objectCollection: Array<T>,
	convertMap: ConvertMap,
	defaultFn: () => R,
	section: string,
): Array<R> {
	const result: Array<R> = []

	objectCollection.forEach((object: T, index: number) => {
		let accountingDocumentObject = defaultFn()
		accountingDocumentObject = convertSection(metadata, accountingDocumentObject, convertMap, section, index)

		if (JSON.stringify(accountingDocumentObject) === JSON.stringify(defaultFn())) {
			return
		}

		result.push(accountingDocumentObject)
	})

	return result
}

function convertFields(
	metadata: ScanFactoryDocumentFileMetadata,
	accountingDocument: AccountingDocument,
): AccountingDocument {
	let accountingDocumentFields = accountingDocument
	const convertMap = createConvertMap([
		'orderNo',
		'externalNo',
		'variableSymbol',
		'specificSymbol',
		'constSymbol',
		'issueDate',
		'taxDate',
		'dueDate',
		'exchRate',
		'total',
		'totalVatExcl',
		'totalVatAmount',
		'currency',
	])

	const contactConvertMap = [
		{
			metadata: 'CounterpartyFirstname',
			accountingDocument: 'firstname',
		},
		{
			metadata: 'CounterpartyLastname',
			accountingDocument: 'lastname',
		},
		{
			metadata: 'CounterpartyCompanyRegNo',
			accountingDocument: 'companyRegNo',
		},
		{
			metadata: 'CounterpartyCompanyName',
			accountingDocument: 'companyName',
		},
		{
			metadata: 'CounterpartyTaxId',
			accountingDocument: 'taxId',
		},
		{
			metadata: 'CounterpartyCity',
			accountingDocument: 'city',
		},
		{
			metadata: 'CounterpartyStreet',
			accountingDocument: 'street',
		},
		{
			metadata: 'CounterpartyCountry',
			accountingDocument: 'country',
		},
		{
			metadata: 'CounterpartyZipCode',
			accountingDocument: 'zipCode',
		},
		{
			metadata: 'CounterpartyEmail',
			accountingDocument: 'email',
		},
		{
			metadata: 'CounterpartyExternalId',
			accountingDocument: 'externalId',
		},
	]

	accountingDocumentFields = convertSection(metadata, accountingDocumentFields, convertMap, 'fields', 0)
	accountingDocumentFields.contact = convertSection(
		metadata,
		{ ...emptyAccountingDocumentContact() },
		contactConvertMap,
		'fields',
		0,
	)

	return accountingDocumentFields
}

function convertBankAccounts(
	metadata: ScanFactoryDocumentFileMetadata,
	accountingDocument: AccountingDocument,
): AccountingDocument {
	const convertMap = createConvertMap(['number', 'bankCode', 'iban', 'swift'])

	accountingDocument.bankAccounts = convertRepeatableSection(
		metadata,
		metadata.bankAccounts || [],
		convertMap,
		emptyAccountingDocumentBankAccount,
		'bankAccounts',
	)

	return accountingDocument
}

function convertLineItems(
	metadata: ScanFactoryDocumentFileMetadata,
	accountingDocument: AccountingDocument,
): AccountingDocument {
	const convertMap = createConvertMap([
		'description',
		'unitPrice',
		'unitPriceVatExcl',
		'unitName',
		'qt',
		'vatValue',
		'vatRate',
		'total',
		'totalVatExcl',
	])

	accountingDocument.lineItems = convertRepeatableSection(
		metadata,
		metadata.lineItems || [],
		convertMap,
		emptyAccountingDocumentLineItem,
		'lineItems',
	)

	return accountingDocument
}

function convertRecapItems(
	metadata: ScanFactoryDocumentFileMetadata,
	accountingDocument: AccountingDocument,
): AccountingDocument {
	const convertMap = createConvertMap(['vatRate', 'base', 'vatAmount', 'total'])

	accountingDocument.vatRecap = convertRepeatableSection(
		metadata,
		metadata.recapItems || [],
		convertMap,
		emptyAccountingDocumentVatRecapLine,
		'recapItems',
	)

	return accountingDocument
}
