/* @flow */

import React, { Component } from 'react'
import {
	withTranslate,
	withOrganizationSettings,
	type WithTranslateProps,
	type WithOrganizationSettingsProps,
} from 'wrappers'
import {
	NON_CASH_REGISTER_ACCOUNTING_DOCUMENT_TYPES,
	CASH_REGISTER_ACCOUNTING_DOCUMENT_TYPES,
	ADVANCED_CASH_REGISTER_ACCOUNTING_DOCUMENTS,
} from 'modules/accounting-document/constants'
import { AccountingDocumentType_Number, Type_AccountingDocumentName } from 'types/convertor'
import type {
	AccountingDocumentAssignedDirection,
	AccountingDocumentAssignedType,
	Sequence,
	OrganizationSettings,
	SequencesData,
} from 'types'
import CashRegisterSelector from 'modules/common/containers/cash-register-selector'
import Button from 'components/button'
import Toggle from 'components/toggle'
import Direction from 'components/direction'
import SequenceList from './sequence-list'
import styles from '../settings.css'
import AddSequenceDialog from './add-sequence-dialog'
import { EMPTY_ARRAY } from 'trivi-constants'

const DIRECTIONS: Array<AccountingDocumentAssignedDirection> = ['issued', 'received']

type State = {
	dialogOpen: boolean,
	cashRegisterId: ?string,
}

type Props = {|
	...WithTranslateProps,
	...WithOrganizationSettingsProps,
	sequences: SequencesData,
	currentUserIsInternal: boolean,
	editSequence: (sequenceId: string, sequence: Sequence, cashRegisterId?: string) => void,
	createSequence: (sequence: Sequence, cashRegisterId?: ?string) => void,
	removeSequence: (sequence: Sequence, cashRegisterId?: string) => void,
	changeLastGeneratedNumber: (
		sequence: Sequence,
		lastGeneratedNumber: number,
		year: number,
		cashRegisterId?: string,
	) => void,
|}

class Sequences extends Component<Props, State> {
	state: State = {
		dialogOpen: false,
		cashRegisterId: null,
	}

	componentDidMount() {
		if (!this.props.organizationSettings) {
			this.props.loadOrganizationSettings()
		}
	}

	onAddButtonClick = () => void this.setState({ dialogOpen: true })
	onDialogClose = () => void this.setState({ dialogOpen: false })

	onCashRegisterIdChange = (cashRegisterId: ?string) => {
		this.setState({ cashRegisterId: null }, () => {
			this.setState({ cashRegisterId })
		})
	}

	onToggle = (event: SyntheticEvent<HTMLInputElement>, useOnlyOneSequenceSetPerCashRegister: boolean) => {
		const { organizationSettings } = this.props
		if (!organizationSettings) {
			return
		}
		const newSettings: OrganizationSettings = Object.freeze({
			...this.props.organizationSettings,
			useOnlyOneSequenceSetPerCashRegister,
		})
		this.props.updateOrganizationSettings(organizationSettings, newSettings)
	}

	onEditSequence = (cashRegisterId?: string) => (sequenceId: string, sequence: Sequence) => {
		this.props.editSequence(sequenceId, sequence, cashRegisterId)
	}

	onRemoveSequence = (cashRegisterId?: string) => (sequence: Sequence) => {
		this.props.removeSequence(sequence, cashRegisterId)
	}

	onChangeLastGeneratedNumber = (cashRegisterId?: string) => (
		sequence: Sequence,
		lastGeneratedNumber: number,
		year: number,
	) => {
		this.props.changeLastGeneratedNumber(sequence, lastGeneratedNumber, year, cashRegisterId)
	}

	renderSequenceLists(
		keyPrefix: string,
		directions: Array<AccountingDocumentAssignedDirection>,
		types: Array<AccountingDocumentAssignedType>,
		cashRegisterId?: string,
		disabledTypes?: Array<AccountingDocumentAssignedType>,
	) {
		const { t } = this.props

		return directions.map((direction: AccountingDocumentAssignedDirection) => {
			const dirTitle: string = cashRegisterId
				? t(`settings.sequences.directionsCashRegister.${direction}`)
				: t(`settings.sequences.directions.${direction}`)

			return (
				<div key={keyPrefix + 'list' + direction} className={styles.sequenceSection}>
					<div className={styles.direction}>
						<span>{dirTitle}</span>
						<Direction issued={direction === 'issued'} received={direction === 'received'} />
					</div>
					{types.map((type: AccountingDocumentAssignedType) => {
						const isEmployeeAdvancedSequence = direction == 'issued' && type == 'employee_advance' && cashRegisterId

						const typeNumber = AccountingDocumentType_Number(type)
						const title: string =
							type === 'simplified_invoice' && this.isUsedOnlyOneSequenceForCashRegister()
								? t('accountingDocument.types.simpleCashRegisterDocument')
								: t(`accountingDocument.types.${Type_AccountingDocumentName(typeNumber) || ''}`)

						return (
							(!disabledTypes || (disabledTypes && disabledTypes.indexOf(type) === -1)) &&
							!isEmployeeAdvancedSequence && (
								<SequenceList
									title={title}
									key={keyPrefix + 'typeHideOut' + direction + type}
									editSequence={this.onEditSequence(cashRegisterId)}
									removeSequence={this.onRemoveSequence(cashRegisterId)}
									changeLastGeneratedNumber={this.onChangeLastGeneratedNumber(cashRegisterId)}
									sequences={
										this.props.sequences[direction][type] &&
										this.props.sequences[direction][type][cashRegisterId || 'unknown']
									}
								/>
							)
						)
					})}
				</div>
			)
		})
	}

	isUsedOnlyOneSequenceForCashRegister(): boolean {
		const { organizationSettings } = this.props
		return !!(organizationSettings && organizationSettings.useOnlyOneSequenceSetPerCashRegister)
	}

	render() {
		const { t } = this.props
		const isUsedOnlyOneSequenceForCashRegister = this.isUsedOnlyOneSequenceForCashRegister()

		const disabledTypes: Array<AccountingDocumentAssignedType> = isUsedOnlyOneSequenceForCashRegister
			? ADVANCED_CASH_REGISTER_ACCOUNTING_DOCUMENTS
			: EMPTY_ARRAY
		const directionsForNonCashRegister = this.props.currentUserIsInternal ? DIRECTIONS : ['issued']

		return (
			<div>
				<div className={styles.addButton}>
					<Button
						autoTestId="sequences-new-sequence"
						primary
						labelText={t('settings.sequences.newSequenceDialogTitle')}
						onClick={this.onAddButtonClick}
					/>
				</div>
				<h2 className={styles.firstH2}>{t('settings.sequences.nonCashRegisterSequences')}</h2>
				{this.renderSequenceLists(
					'nonCashRegisterSequences',
					directionsForNonCashRegister,
					NON_CASH_REGISTER_ACCOUNTING_DOCUMENT_TYPES,
				)}

				<div className={styles.halfRow}>
					<h2 className={styles.h2}>{t('settings.sequences.cashRegisterSequences')}</h2>
					<Toggle
						autoTestId="sequences-onlyOneSequenceSetPerCashRegister"
						label={t('settings.sequences.onlyOneSequenceSetPerCashRegister')}
						toggled={isUsedOnlyOneSequenceForCashRegister}
						onToggle={this.onToggle}
					/>
				</div>
				<div className={styles.halfRow}>
					<CashRegisterSelector value={this.state.cashRegisterId} onChange={this.onCashRegisterIdChange} />
				</div>
				{!!this.state.cashRegisterId &&
					this.renderSequenceLists(
						'cashRegisterSequences',
						DIRECTIONS,
						CASH_REGISTER_ACCOUNTING_DOCUMENT_TYPES,
						this.state.cashRegisterId,
						disabledTypes,
					)}
				<AddSequenceDialog
					open={this.state.dialogOpen}
					createSequence={this.props.createSequence}
					currentUserIsInternal={this.props.currentUserIsInternal}
					useOnlyOneSequenceSetPerCashRegister={isUsedOnlyOneSequenceForCashRegister}
					onRequestClose={this.onDialogClose}
				/>
			</div>
		)
	}
}

export default withTranslate(withOrganizationSettings(Sequences))
