//@flow
import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
	withTranslate,
	withOrganizationSettings,
	type WithTranslateProps,
	type WithOrganizationSettingsProps,
} from 'wrappers'
import ConfirmDialog from 'components/confirm-dialog'
import Button from 'components/button'
import styles from '../settings.css'
import DatePicker from 'components/date-picker/date-picker'
import { convertToDate, formatDateToIsoString } from 'utils/formatters'
import type { BootstrapAction, Dispatch, OrganizationSettingsOpen, State } from 'types'
import type { OrganizationContractState, OrganizationContractStateRequest } from '../../../common/models/swagger-model'
import { getCurrentOrganizationContractState } from '../../selectors'
import { getOrganizationContractState, updateOrganizationContractState } from '../../actions/basics-actions'
import diff from 'json-patch-gen'
import { organizationSettingsOpen as organizationSettingsOpenApi } from 'types'
import { setOrganizationSettingsOpen } from 'modules/user/actions'
import { currentUserIsAdmin, currentUserIsInternal } from 'modules/user/selectors'

export type Props = {|
	...StateProps,
	...DispatchProps,
	...WithOrganizationSettingsProps,
	...WithTranslateProps,
	organizationId: string,
	removeOrganization: (organizationId?: string) => Promise<any>,
|}

type StateProps = {|
	contractState: ?OrganizationContractState,
	currentUserIsAdmin: boolean,
	currentUserIsInternal: boolean,
|}

type DispatchProps = {|
	getOrganizationContractState: () => void,
	updateOrganizationContractState: (contract: OrganizationContractStateRequest, organizationId?: string) => void,
|}

type ComponentState = {|
	processing: boolean,
	inTriviUntil: ?string,
	inTriviUntilChanged: boolean,
	inTriviSince: ?string,
	inTriviSinceChanged: boolean,
	updateState: boolean,
	defaultUntil: ?string,
	defaultSince: ?string,
|}

class TriviServices extends Component<Props, ComponentState> {
	state: ComponentState = {
		processing: false,
		inTriviUntil: undefined,
		inTriviUntilChanged: false,
		inTriviSince: undefined,
		inTriviSinceChanged: false,
		updateState: false,
		defaultUntil: undefined,
		defaultSince: undefined,
	}

	static getDerivedStateFromProps(nextProps: Props, prevState: ComponentState) {
		if (prevState.updateState && nextProps && nextProps.contractState && nextProps.contractState.inTriviUntil) {
			const { inTriviUntil } = nextProps.contractState
			return { inTriviUntil: inTriviUntil, defaultUntil: inTriviUntil, updateState: false }
		}
		if (prevState.updateState && nextProps && nextProps.contractState && nextProps.contractState.inTriviSince) {
			const { inTriviSince } = nextProps.contractState
			return { inTriviSince: inTriviSince, defaultSince: inTriviSince, updateState: false }
		}
		return null
	}

	componentDidMount() {
		if (!this.props.organizationSettings) {
			this.props.loadOrganizationSettings()
		}
		if (!this.props.contractState) {
			this.setState({ updateState: true }) // eslint-disable-line
			this.props.getOrganizationContractState()
		} else {
			this.setDefaultState()
		}
	}

	setDefaultState = () => {
		this.setState({
			inTriviUntil: this.props.contractState ? this.props.contractState.inTriviUntil : null,
			inTriviSince: this.props.contractState ? this.props.contractState.inTriviSince : null,
			defaultUntil: this.props.contractState ? this.props.contractState.inTriviUntil : null,
			defaultSince: this.props.contractState ? this.props.contractState.inTriviSince : null,
		})
	}

	terminateTrivi = async () => {
		const { t, contractState, organizationSettings } = this.props
		const { inTriviUntil } = this.state
		const oldContract: ?OrganizationContractState =
			contractState || (organizationSettings && organizationSettings.contractState)

		if (!inTriviUntil) return
		const newContract = { ...oldContract, inTriviUntil }
		const patch = diff(oldContract, newContract, { op: 'replace' })

		if (convertToDate(inTriviUntil) <= Date.now()) {
			ConfirmDialog(t('dialogs.terminateTriviQuestion'), {
				okLabel: t('dialogs.terminate'),
				cancelLabel: t('dialogs.dontDelete'),
				waitForConfirm: true,
			}).then(async () => {
				this.props.updateOrganizationContractState(Object.freeze(patch), this.props.organizationId)
				this.setState({ inTriviUntilChanged: false, defaultUntil: inTriviUntil })
			})
		} else {
			this.props.updateOrganizationContractState(Object.freeze(patch), this.props.organizationId)
			this.setState({ inTriviUntilChanged: false, defaultUntil: inTriviUntil })
		}
	}

	setStartTrivi = async () => {
		const { t, contractState, organizationSettings } = this.props
		const { inTriviSince } = this.state
		const oldContract: ?OrganizationContractState =
			contractState || (organizationSettings && organizationSettings.contractState)

		if (!inTriviSince) return
		const newContract = { ...oldContract, inTriviSince }
		const patch = diff(oldContract, newContract, { op: 'replace' })

		if (convertToDate(inTriviSince) > Date.now()) {
			ConfirmDialog(t('dialogs.setStartTriviQuestion'), {
				okLabel: t('dialogs.yesOption'),
				cancelLabel: t('dialogs.noOption'),
				waitForConfirm: true,
			}).then(async () => {
				this.props.updateOrganizationContractState(Object.freeze(patch), this.props.organizationId)
				this.setState({ inTriviSinceChanged: false, defaultSince: inTriviSince })
			})
		} else {
			this.props.updateOrganizationContractState(Object.freeze(patch), this.props.organizationId)
			this.setState({ inTriviSinceChanged: false, defaultSince: inTriviSince })
		}
	}

	resumeTrivi = () => {
		const { contractState, organizationSettings } = this.props
		const oldContract: ?OrganizationContractState =
			contractState || (organizationSettings && organizationSettings.contractState)
		const newContract = { ...oldContract, inTriviUntil: null }
		const patch = diff(oldContract, newContract, { op: 'replace' })
		this.setState({
			inTriviUntil: null,
			inTriviUntilChanged: false,
		})
		this.props.updateOrganizationContractState(Object.freeze(patch), this.props.organizationId)
	}

	onInTriviChange = (event: ?SyntheticInputEvent<HTMLInputElement>, inTriviSince: ?Date) => {
		if (!inTriviSince && inTriviSince === this.state.defaultSince) {
			this.setState({ inTriviSinceChanged: false, inTriviSince: undefined })
			return
		}
		const defaultValueSelected = inTriviSince && `${formatDateToIsoString(inTriviSince)}Z` === this.state.defaultSince

		this.setState({
			inTriviSinceChanged: !!inTriviSince && !defaultValueSelected,
			inTriviSince: inTriviSince ? `${formatDateToIsoString(inTriviSince)}Z` : undefined,
		})
	}

	onUntilTriviChange = (event: ?SyntheticInputEvent<HTMLInputElement>, inTriviUntil: Date) => {
		if (!inTriviUntil && inTriviUntil === this.state.defaultUntil) {
			this.setState({ inTriviUntilChanged: false, inTriviUntil: undefined })
			return
		}
		const defaultValueSelected = inTriviUntil && `${formatDateToIsoString(inTriviUntil)}Z` === this.state.defaultUntil

		this.setState({
			inTriviUntilChanged: !!inTriviUntil && !defaultValueSelected,
			inTriviUntil: inTriviUntil ? `${formatDateToIsoString(inTriviUntil)}Z` : undefined,
		})
	}

	render() {
		const { t, contractState, currentUserIsInternal } = this.props
		const isResumeTriviDisplayed = contractState && contractState.inTriviUntil
		const isTerminated = (contractState && contractState.isTerminated) || false

		return (
			<div>
				{currentUserIsInternal && (
					<div className={styles.row}>
						<div className={styles.columnOneHalf}>
							<DatePicker
								value={this.state.inTriviSince ? new Date(this.state.inTriviSince) : null}
								onChange={this.onInTriviChange}
								labelText={t('settings.accountingSettings.inTriviSince')}
								// disabled={!currentUserIsInternal}
								fullWidth
								autoTestId="in-trivi-since-date"
							/>
						</div>
						<div className={styles.columnOneHalf}>
							<Button
								secondary
								simulateOutsideLabel
								labelText={t('settings.administration.setStart')}
								onClick={this.setStartTrivi}
								disabled={!this.state.inTriviSinceChanged || isTerminated}
								autoTestId="settings-services-set-start"
							/>
						</div>
					</div>
				)}
				{currentUserIsInternal && (
					<div className={styles.row}>
						<div className={styles.columnOneHalf}>
							<DatePicker
								value={this.state.inTriviUntil ? convertToDate(this.state.inTriviUntil) : null}
								onChange={this.onUntilTriviChange}
								labelText={
									isTerminated
										? t('settings.administration.isTerminated')
										: t('settings.administration.processingUntil')
								}
								fullWidth
								disabled={isTerminated}
								autoTestId="processing-until-date"
							/>
						</div>
						<div className={styles.columnOneHalf}>
							<Button
								secondary
								simulateOutsideLabel
								labelText={t('settings.administration.terminateProcessing')}
								onClick={this.terminateTrivi}
								disabled={!this.state.inTriviUntilChanged || isTerminated}
								autoTestId="settings-services-terminate"
							/>
						</div>
					</div>
				)}
				<div className={styles.row}>
					<div className={styles.columnOneHalf}>
						{isResumeTriviDisplayed && (
							<Button
								primary
								labelText={t('settings.administration.removeTerminateProcessing')}
								onClick={this.resumeTrivi}
								disabled={isTerminated}
								autoTestId="settings-services-remove-terminate"
							/>
						)}
					</div>
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state: State): StateProps => {
	return {
		contractState: getCurrentOrganizationContractState(state),
		currentUserIsAdmin: currentUserIsAdmin(state),
		currentUserIsInternal: currentUserIsInternal(state),
	}
}

const mapDispatchToProps = (dispatch: Dispatch<BootstrapAction>): DispatchProps => {
	return {
		getOrganizationContractState: () => {
			dispatch(getOrganizationContractState())
		},
		updateOrganizationContractState: (contractState: OrganizationContractStateRequest, organizationId?: string) => {
			dispatch(updateOrganizationContractState(contractState)).then(async () => {
				const organizationSettingsOpen: OrganizationSettingsOpen = await organizationSettingsOpenApi.get({
					organizationId,
				})
				dispatch(setOrganizationSettingsOpen(organizationSettingsOpen))
			})
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslate(withOrganizationSettings(TriviServices)))
