/* @flow */
/** @jsx jsx */

import { jsx } from '@emotion/core'
import { Fragment, Component, type Node } from 'react'
import memoize from 'memoize-one'
import { connect } from 'react-redux'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import type { Dispatch, EmailTemplate, Enum, EnumItem, State } from 'types'
import EditEmailTemplate from 'modules/settings/containers/edit-email-template'
import type { Action as EmailAction } from 'modules/settings/actions/email-action-types'
import { loadEmailTemplates } from 'modules/settings/actions'
import Snackbar from 'components/snackbar'
import SelectNext from 'components/select-next'
import type { OptionsType, OptionType, ValueType } from 'components/select-next'
import { colors } from 'variables'

type StateProps = {|
	emailTemplates: ?Array<EmailTemplate>,
|}

const mapStateToProps = (state: State): StateProps => {
	return {
		emailTemplates: state.settings.emailTemplates,
	}
}

type DispatchProps = {|
	loadEmailTemplates: () => void,
|}

const mapDispatchToProps = (dispatch: Dispatch<EmailAction>): DispatchProps => {
	return {
		loadEmailTemplates: () => {
			dispatch(loadEmailTemplates())
		},
	}
}

type OwnProps = {|
	value: ?string,
	allowedTypes?: ?Array<number>,
	label?: ?string,
	disabled?: boolean,
	clientError?: Node,
	allowCreateTemplate?: boolean,
	autoSelect?: boolean,
	onChange?: (emailTemplateId: string) => void,
	defaultEmailType?: number,
|}

type Props = {| ...StateProps, ...DispatchProps, ...OwnProps, ...WithTranslateProps |}

type ComponentState = {|
	selected: ?string,
	isDialogOpen: boolean,
|}

class EmailTemplateSelector extends Component<Props, ComponentState> {
	state: ComponentState = {
		selected: this.props.value || null,
		isDialogOpen: false,
	}

	componentDidMount() {
		if (this.hasEmailTemplates()) {
			// eslint-disable-next-line react/no-did-mount-set-state
			this.setState({ selected: this.props.value })
			this.autoSelect()
		} else {
			this.props.loadEmailTemplates()
		}
	}

	componentDidUpdate(prevProps: Props) {
		if (this.props.value !== prevProps.value) {
			// eslint-disable-next-line react/no-did-update-set-state
			this.setState({ selected: this.props.value })
		}

		if ((!prevProps.emailTemplates || !prevProps.emailTemplates.length) && this.hasEmailTemplates()) {
			// eslint-disable-next-line react/no-did-update-set-state
			this.setState({ selected: this.props.value })
			this.autoSelect()
		}
	}

	hasEmailTemplates = () => {
		return this.props.emailTemplates && this.props.emailTemplates.length
	}

	autoSelect = () => {
		const filteredTemplates = this.getFilteredTemplates()

		if (filteredTemplates && filteredTemplates.length === 1) {
			filteredTemplates[0].id && this.props.onChange && this.props.onChange(filteredTemplates[0].id)
		}
	}

	onChange = (value: ValueType) => {
		const template = value && !Array.isArray(value) ? value.value : null
		template && this.props.onChange && this.props.onChange(template)
	}

	getFilteredTemplates() {
		const { emailTemplates, allowedTypes } = this.props

		let filteredTemplates: ?Array<EmailTemplate> = emailTemplates
		if (allowedTypes && filteredTemplates) {
			filteredTemplates = filteredTemplates.filter(
				(template: EmailTemplate) => template.type && allowedTypes.indexOf(template.type) !== -1,
			)
		}
		return filteredTemplates
	}

	getTemplatesEnum(): ?Enum {
		const filteredTemplates = this.getFilteredTemplates()

		const templatesEnum: ?Enum =
			filteredTemplates &&
			filteredTemplates.map((template: EmailTemplate) => ({
				key: template.id,
				value: template.name,
			}))

		return templatesEnum && templatesEnum.length > 0 ? templatesEnum : null
	}

	getOptions = memoize((templates: ?Enum): OptionsType => {
		if (!templates) return []
		return templates.map((template: EnumItem): OptionType => ({
			value: template.key,
			label: template.value,
		}))
	})

	openDialog = () => {
		this.setState({ isDialogOpen: true })
	}

	closeDialog = () => {
		this.setState({ isDialogOpen: false })
	}

	render() {
		const { selected } = this.state
		const { t, label, disabled, allowCreateTemplate, clientError, defaultEmailType } = this.props
		const templatesEnum = this.getTemplatesEnum()

		if (!templatesEnum && allowCreateTemplate) {
			const message = (
				<div>
					{t('settings.emails.noTemplateRegistered')}
					<div onClick={this.openDialog} css={style.create}>
						{t('settings.emails.createTemplate')}
					</div>
				</div>
			)

			return (
				<div
					style={{
						marginTop: 25,
						marginBottom: 30,
					}}
				>
					<Snackbar open inline showIcon message={message} type={'error'} />
					{this.state.isDialogOpen && (
						<EditEmailTemplate
							open={this.state.isDialogOpen}
							onRequestClose={this.closeDialog}
							defaultEmailType={defaultEmailType}
						/>
					)}
				</div>
			)
		}

		return (
			<Fragment>
				<SelectNext
					disabled={!!disabled || !templatesEnum}
					options={this.getOptions(templatesEnum)}
					value={selected || null}
					onChange={this.onChange}
					label={label || t('settings.emails.emailTemplate')}
					placeholder={
						templatesEnum ? t('settings.emails.noTemplateSelected') : t('settings.emails.noTemplateRegistered')
					}
					error={clientError}
					portal
					autoTestId="email-template-selector"
				/>
				{selected ? (
					<div onClick={this.openDialog} css={style.edit}>
						{t('settings.emails.editEmailTemplate')}
					</div>
				) : null}
				{this.state.isDialogOpen && selected && (
					<EditEmailTemplate
						id={selected}
						open={this.state.isDialogOpen}
						onRequestClose={this.closeDialog}
						defaultEmailType={defaultEmailType}
					/>
				)}
			</Fragment>
		)
	}
}

const style = {
	create: {
		marginTop: 10,
		cursor: 'pointer',
		textDecoration: 'underline',
		'&:hover': {
			textDecoration: 'none',
		},
	},
	edit: {
		fontSize: 14,
		marginTop: 8,
		cursor: 'pointer',
		textDecoration: 'underline',
		textAlign: 'left',
		'&:hover': {
			color: colors.blue,
		},
	},
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslate(EmailTemplateSelector))
