/* @flow */

import React, { Component } from 'react'
import TaskListContainer from '../containers/task-list'
import styles from './task-list.css'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import Helmet from 'react-helmet'

class TaskList extends Component<WithTranslateProps> {
	render() {
		const { t } = this.props

		return (
			<div className={styles.root}>
				<Helmet>
					<title>{this.props.t('task.headline')}</title>
				</Helmet>
				<h1>{t('task.headline')}</h1>
				<TaskListContainer />
			</div>
		)
	}
}

export default withTranslate(TaskList)
