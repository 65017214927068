/* @flow */

import type { State } from 'types'
import store from 'store'

function getOrganizationId() {
	const state: State = store.getState()
	return state.user.currentOrganizationId || 'error'
}

export function dashboardRoute(organizationId: ?string) {
	return `/${organizationId || getOrganizationId()}/dashboard/`
}

export function todoRoute(organizationId: ?string) {
	return `/${organizationId || getOrganizationId()}/dashboard/todo`
}

export function todoHistoryRoute(organizationId: ?string) {
	return `/${organizationId || getOrganizationId()}/dashboard/todo/history`
}

export function todoBankRoute(organizationId: ?string) {
	return `/${organizationId || getOrganizationId()}/dashboard/todo/bank`
}

export function todoBankRouteFiltered(organizationId: ?string, filter: string) {
	return `/${organizationId || getOrganizationId()}/dashboard/todo/bank/${filter}`
}

export function reportsRoute(organizationId: ?string) {
	return `/${organizationId || getOrganizationId()}/dashboard/reports`
}

export function emptyDashboardRoute() {
	return '/empty-dashboard'
}
