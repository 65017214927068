// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1NUsF1dItZll-0bAGMo0Rt {\n\twidth: 300px;\n\tmargin: 10px auto 0 auto;\n}\n\n._3_fS6nDqVkSF6gGPq7MSXM {\n\tmargin-bottom: 18px;\n}\n\n._3_fS6nDqVkSF6gGPq7MSXM:before {\n\tcontent: '';\n\tdisplay: table;\n}\n\n._3_fS6nDqVkSF6gGPq7MSXM:after {\n\tcontent: '';\n\tdisplay: table;\n\tclear: both;\n}\n", "",{"version":3,"sources":["webpack://./components/dialogs/text-input-dialog/styles.css"],"names":[],"mappings":"AAAA;CACC,YAAA;CACA,wBAAA;AACD;;AAEA;CACC,mBAAA;AACD;;AAEA;CACC,WAAA;CACA,cAAA;AACD;;AAEA;CACC,WAAA;CACA,cAAA;CACA,WAAA;AACD","sourcesContent":[".oneColumnDialog {\n\twidth: 300px;\n\tmargin: 10px auto 0 auto;\n}\n\n.row {\n\tmargin-bottom: 18px;\n}\n\n.row:before {\n\tcontent: '';\n\tdisplay: table;\n}\n\n.row:after {\n\tcontent: '';\n\tdisplay: table;\n\tclear: both;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"oneColumnDialog": "_1NUsF1dItZll-0bAGMo0Rt",
	"row": "_3_fS6nDqVkSF6gGPq7MSXM"
};
export default ___CSS_LOADER_EXPORT___;
