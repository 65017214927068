/* @flow */

import React, { Component } from 'react'
import type { SearchFilter } from 'types'
import VariableSymbolInput from 'components/variable-symbol-input'
import { Validator } from 'utils'

export type Props = {
	name: string,
	className?: string,
	filter?: SearchFilter,
	hintText?: string,
	onChange: (filter: SearchFilter) => void,
	onValueChange?: (event: SyntheticInputEvent<HTMLInputElement>, value: ?string) => void,
}

type State = {|
	value: string,
|}

class FilterVariableSymbol extends Component<Props, State> {
	static defaultProps = {
		onChange: () => {},
	}

	state = {
		value: this.props.filter && this.props.filter.value ? this.props.filter.value.toString() : '',
	}

	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		if (this.props.filter !== nextProps.filter) {
			this.setState({
				value: ((nextProps.filter && nextProps.filter.value) || '').toString(),
			})
		}
	}

	handleTextFieldChange = (event: SyntheticInputEvent<HTMLInputElement>, value: ?string) => {
		this.setState({
			value: value || '',
		})

		this.props.onValueChange && this.props.onValueChange(event, value)
	}

	handleTextFieldBlur = () => {
		this.fireChange()
	}

	handleTextFieldEnter = () => {
		this.fireChange()
	}

	fireChange() {
		const value = this.state.value

		if (value.length && !Validator.variableSymbol(value)) return null

		if ((!this.props.filter && value === '') || (this.props.filter && value === this.props.filter.value)) {
			return
		}

		const filter: SearchFilter = {
			field: this.props.name,
		}

		if (value) {
			filter.value = value
		}

		this.props.onChange(filter)
	}

	render() {
		let {
			name, // eslint-disable-line no-unused-vars
			filter, // eslint-disable-line no-unused-vars
			onChange, // eslint-disable-line no-unused-vars
			className,
			onValueChange, // eslint-disable-line no-unused-vars
			...rest
		} = this.props

		return (
			<div className={className}>
				<VariableSymbolInput
					onChange={this.handleTextFieldChange}
					onBlur={this.handleTextFieldBlur}
					onKeyEnter={this.handleTextFieldEnter}
					value={this.state.value}
					fullWidth
					compact
					{...rest}
				/>
			</div>
		)
	}
}

export default FilterVariableSymbol
