// @flow

import { type ComponentType, type ElementConfig, type Component } from 'react'
import { connect } from 'react-redux'
import type { State } from 'types'
import { getCurrentOrganizationAccountant, getCurrentOrganizationWagesAccountant } from '../../organization/selectors'

type StateProps = {|
	mainAccountantId: ?string,
	mainAccountantName: ?string,
	wagesAccountantId: ?string,
	wagesAccountantName: ?string,
|}

const mapStateToProps = (state: State): StateProps => {
	const mainAccountant = getCurrentOrganizationAccountant(state)
	const wagesAccountant = getCurrentOrganizationWagesAccountant(state)

	return {
		mainAccountantId: mainAccountant && mainAccountant.id,
		mainAccountantName:
			mainAccountant &&
			mainAccountant.firstname &&
			mainAccountant.lastname &&
			`${mainAccountant.firstname} ${mainAccountant.lastname}`,
		wagesAccountantId: wagesAccountant && wagesAccountant.id,
		wagesAccountantName:
			wagesAccountant &&
			wagesAccountant.firstname &&
			wagesAccountant.lastname &&
			`${wagesAccountant.firstname} ${wagesAccountant.lastname}`,
	}
}

export type Props = StateProps
export default function withAccountantInfo<P: any, C: ComponentType<P>>(
	WrappedComponent: C,
): Class<Component<$Diff<ElementConfig<C>, Props>, any>> {
	return connect(mapStateToProps)(WrappedComponent)
}
