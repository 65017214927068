/* @flow */

import { connect } from 'react-redux'
import type { Dispatch, DocumentExtractionAction, State } from 'types'
import {
	addRepeatableSection,
	editField,
	finishEditField,
	removeRepeatableSection,
	resetField,
	selectField,
} from '../actions'
import DocumentFields, { type DispatchProps, type StateProps } from '../components/document-fields'
import { getFieldCursor, isFieldEditing } from '../selectors'
import type { ExtractionDocument, FieldCursor } from '../types'

type OwnProps = {|
	extractionDocument: ExtractionDocument,
	onFieldMouseOver: (field: FieldCursor) => void,
	onFieldMouseOut: (field: FieldCursor) => void,
	highlightFields: ?Array<FieldCursor>,
|}

export const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => {
	return {
		extractionDocument: ownProps.extractionDocument,
		fieldCursor: getFieldCursor(state),
		fieldEditing: isFieldEditing(state),
		highlightFields: ownProps.highlightFields,
	}
}

const mapDispatchToProps = (dispatch: Dispatch<DocumentExtractionAction>, ownProps: OwnProps): DispatchProps => {
	return {
		onFieldClick: (fieldCursor: FieldCursor) => {
			dispatch(selectField(fieldCursor))
		},
		onFieldDoubleClick: (fieldCursor: FieldCursor) => {
			dispatch(editField(fieldCursor))
		},
		onFieldResetClick: (fieldCursor: FieldCursor) => {
			dispatch(resetField(fieldCursor))
		},
		onFieldValueChange: (fieldCursor: FieldCursor, value?: string, hotkey?: string) => {
			dispatch(finishEditField(fieldCursor, value, hotkey))
		},
		onFieldMouseOver: (field: FieldCursor) => {
			ownProps.onFieldMouseOver(field)
		},
		onFieldMouseOut: (field: FieldCursor) => {
			ownProps.onFieldMouseOut(field)
		},
		addRepeatableSection: (section: string) => {
			dispatch(addRepeatableSection(section))
		},
		removeRepeatableSection: (section: string, index: number) => {
			dispatch(removeRepeatableSection(section, index))
		},
	}
}

const mergeProps = (stateProps: StateProps, dispatchProps: DispatchProps): {| ...StateProps, ...DispatchProps |} => {
	return { ...stateProps, ...dispatchProps }
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(DocumentFields)
