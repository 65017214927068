/* @flow */
/** @jsx jsx */

import { jsx } from '@emotion/core'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import noItemsImage from 'assets/images/grid-no-items.svg'
import { colors } from 'variables'

export type Props = {|
	...WithTranslateProps,
|}

const DocumentListNotFound = (props: Props) => {
	return (
		<div css={styles.empty}>
			<div>{props.t('grid.noDataMessage')}</div>
			<img src={noItemsImage} css={styles.image} />
		</div>
	)
}

const styles = {
	empty: {
		background: colors.white,
		width: '100%',
		textAlign: 'center',
		color: colors.black,
		fontSize: 24,
		lineHeight: '32px',
		padding: '80px 20px 20px 20px',
	},
	image: {
		display: 'block',
		position: 'relative',
		width: 363,
		maxWidth: '75%',
		height: 'auto',
		margin: '40px auto 0 auto',
		pointerEvents: 'none',
		userSelect: 'none',
	},
}

export default withTranslate(DocumentListNotFound)
