// @flow
import { userHasAccess } from './acl-logic'
import type { State } from 'types'

export { userHasAccess } from './acl-logic'
export type { Permission } from './acl'

export function canLoadTaxPaymentHistory(state: State): boolean {
	return userHasAccess(state, 'setOrganizationProfile')
}

export function canLoadOrganizationSettings(state: State): boolean {
	return userHasAccess(state, 'setOrganizationProfile')
}
