/* @flow */
/** @jsx jsx */

import { Component } from 'react'
import memoize from 'memoize-one'
import { jsx } from '@emotion/core'
import { Validator } from 'utils/validators'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import type { AccountingDocumentContact } from 'types'
import Popover from 'components/Popover'
import TextField from 'components/TextField'
import ContactSelectItem from './contact-select-item'
import Loader from 'components/loader'
import SearchIcon from 'components/svg-icons/action/search'
import ContentAdd from 'components/svg-icons/content/add'
import Tooltip from 'components/tooltip'
import IconTextButton from 'components/icon-text-button'
import { colors } from 'variables'

const DEFAULT_WAIT_TIME: number = 1000

type Props = {|
	...WithTranslateProps,
	isSupplier: boolean,
	show: boolean,
	autoFocus?: boolean,
	favoriteContactIds: Array<string>,
	enableAddContact?: boolean,
	onAdd: () => void,
	onSearchInAres: (value: string) => void,
	searchContact: (value: string) => Promise<Array<AccountingDocumentContact>>,
	onChange: (contact: AccountingDocumentContact) => void,
	onContactFavorite: (contact: AccountingDocumentContact, isFavorite: boolean) => void,
	hideSearch: () => void,
|}

type State = {|
	text: string,
	loading: boolean,
	contacts: Array<AccountingDocumentContact>,
	open: boolean,
	filter: string,
|}

class ContactSelect extends Component<Props, State> {
	anchorOrigin = { horizontal: 'left', vertical: 'bottom' }
	targetOrigin = { horizontal: 'left', vertical: 'top' }
	lastSearchFilter: string = ''
	timeout: any = null
	root = null

	constructor(props: Props) {
		super(props)
		this.state = {
			text: '',
			loading: false,
			contacts: [],
			filter: '',
			open: !!this.props.autoFocus,
		}
	}

	componentWillUnmount() {
		this.clearTimeout()
	}

	clearTimeout = () => {
		this.timeout && clearTimeout(this.timeout)
	}

	searchForContacts = (filter: string) => {
		this.setState({ text: filter })

		const searchImmediately = this.timeout === null
		this.setState({
			text: filter,
		})

		if (this.lastSearchFilter != filter || !this.state.contacts.length) {
			this.setState({ loading: true })
		}

		const oldContacts: Array<AccountingDocumentContact> = this.state.contacts
		this.lastSearchFilter = filter
		this.clearTimeout()

		this.timeout = setTimeout(
			() => {
				this.props.searchContact &&
					this.props
						.searchContact(filter.slice().trim())
						.then((foundContacts: Array<AccountingDocumentContact>) => {
							if (filter === this.lastSearchFilter) {
								this.setState({
									contacts: foundContacts,
								})
							}
						})
						.catch(() => {
							if (filter === this.lastSearchFilter) {
								this.setState({
									contacts: oldContacts,
								})
							}
						})
						.finally(() => {
							this.setState({
								loading: false,
							})
						})
			},
			searchImmediately ? 0 : DEFAULT_WAIT_TIME,
		)
	}

	closePopup = () => {
		this.setState({
			open: false,
		})
	}

	onFocus = (event: any) => {
		event.preventDefault()
		event.target && event.target.select && event.target.select()

		this.searchForContacts(this.state.text)
		this.setState({
			open: true,
			filter: this.state.text,
		})
	}

	onSelectItem = (item: any) => {
		this.setState({
			filter: '',
			text: '',
			open: false,
		})
		this.props.hideSearch()
		this.props.onChange(item)
	}

	onRequestClose = (reason: string) => {
		if (reason === 'clickAway') {
			this.closePopup()
			this.props.hideSearch()
		}
	}

	onFilterChange = (event: ?SyntheticInputEvent<HTMLInputElement>, filter: ?string) => {
		if (filter !== null && filter !== undefined) {
			this.setState({
				filter: filter,
				text: filter,
				open: true,
			})
		}

		filter && this.searchForContacts(filter)
	}

	onSearchInAres = () => {
		const search = this.state.text
		this.setState({ text: '' }, () => {
			this.props.onSearchInAres(search)
			this.closePopup()
		})
	}

	onAdd = () => {
		this.setState({ text: '' }, () => {
			this.props.onAdd()
			this.closePopup()
		})
	}

	bindRoot = (el: any) => {
		this.root = el
	}

	getFavoriteContacts = memoize((contacts: Array<AccountingDocumentContact>, favoriteContactIds: Array<string>) => {
		return (
			contacts &&
			contacts.filter((contact: AccountingDocumentContact) => favoriteContactIds.includes(contact.contactId))
		)
	})

	getNonFavoriteContacts = memoize((contacts: Array<AccountingDocumentContact>, favoriteContactIds: Array<string>) => {
		return (
			contacts &&
			contacts.filter((contact: AccountingDocumentContact) => !favoriteContactIds.includes(contact.contactId))
		)
	})

	renderContacts = (contacts: Array<AccountingDocumentContact>, isFavorite: ?boolean, enableAddContact?: boolean) => {
		return contacts && contacts.length ? (
			<div css={isFavorite ? styles.favorites : enableAddContact ? styles.lines : null}>
				{contacts.map((contact: AccountingDocumentContact, index: number) => {
					return (
						<ContactSelectItem
							key={contact.contactId}
							contact={contact}
							index={index}
							isFavorite={!!isFavorite}
							onClick={this.onSelectItem}
							onFavorite={this.props.onContactFavorite}
						/>
					)
				})}
			</div>
		) : null
	}

	render() {
		const { t, enableAddContact } = this.props
		if (!this.props.show) return null

		const hintText = this.props.isSupplier
			? t('invoice.contact.searchBoxHintSupplier')
			: t('invoice.contact.searchBoxHintCustommer')
		const isICO = Validator.ICO(this.state.text)

		const nonFavoriteContacts = this.getNonFavoriteContacts(this.state.contacts, this.props.favoriteContactIds)
		const favoriteContacts = this.getFavoriteContacts(this.state.contacts, this.props.favoriteContactIds)

		return (
			<div>
				<div css={styles.search}>
					<div ref={this.bindRoot}>
						<TextField
							onFocus={this.onFocus}
							onChange={this.onFilterChange}
							value={this.state.text}
							hintText={hintText}
							inline
							fullWidth
							style={styles.picker}
							hintStyle={styles.hint}
							autoFocus={this.props.autoFocus}
							autoComplete={false}
							name="picker"
							autoTestId="contact-picker"
						/>
						<Popover
							open={this.state.open}
							anchorEl={this.root}
							anchorOrigin={this.anchorOrigin}
							targetOrigin={this.targetOrigin}
							onRequestClose={this.onRequestClose}
							zDepth={5}
							stretch
							style={styles.popover}
						>
							<div css={styles.popoverRoot}>
								<div css={this.state.loading ? styles.loadingContent : styles.content}>
									<div css={styles.items}>
										{!this.state.contacts.length && <div css={styles.empty}>{this.props.t('application.noItems')}</div>}
										{this.renderContacts(favoriteContacts, true, enableAddContact)}
										{this.renderContacts(nonFavoriteContacts, false, enableAddContact)}
									</div>

									{this.state.loading && (
										<div css={styles.loader}>
											<Loader visible size={36} />
										</div>
									)}
								</div>

								{enableAddContact && (
									<div css={styles.actions}>
										<div css={styles.action}>
											<Tooltip
												label={!isICO ? this.props.t('invoice.contact.notValidCompanyID') : undefined}
												align={'center-left'}
												arrowAlign={'center-right'}
											>
												<IconTextButton
													icon={<SearchIcon />}
													labelText={
														this.state.text
															? this.props.t('invoice.contact.searchTextInAresHint', { value: this.state.text })
															: this.props.t('invoice.contact.searchAresHint')
													}
													disabled={!isICO}
													onClick={this.onSearchInAres}
													autoTestId="invoice-contact-select-search-ares"
												/>
											</Tooltip>
										</div>
										<div css={styles.action}>
											<IconTextButton
												icon={<ContentAdd />}
												labelText={
													this.props.isSupplier
														? this.props.t('invoice.contact.addNewSupplier')
														: this.props.t('invoice.contact.addNewCustomer')
												}
												onClick={this.onAdd}
												autoTestId="invoice-contact-select-add-new-contact"
											/>
										</div>
									</div>
								)}
							</div>
						</Popover>
					</div>
				</div>
			</div>
		)
	}
}

const styles = {
	search: {
		padding: '0 5px',
		margin: '-2px 0 2px 0',
		position: 'relative',
	},
	popover: {
		fontSize: 14,
		lineHeight: '20px',
	},
	popoverRoot: {
		overflow: 'hidden',
		maxHeight: 510,
	},
	picker: {
		height: 30,
		lineHeight: '30px',
		padding: '0 8px',
	},
	hint: {
		fontSize: 13,
		fontWeight: 600,
		color: colors.black,
		WebkitTextFillColor: colors.black,
	},
	favorites: {
		padding: '7px 0',
		background: colors.grey200,
		borderBottom: '1px solid #DFDFDF',
	},
	lines: {
		borderBottom: '1px solid #DFDFDF',
	},
	empty: {
		padding: 20,
		textAlign: 'center',
		lineHeight: '20px',
	},
	content: {
		loadingContent: {
			position: 'relative',
			minHeight: 60,
		},
	},
	items: {},
	loadingContent: {
		items: {
			filter: 'blur(1.4px)',
		},
	},
	loader: {
		position: 'absolute',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		textAlign: 'center',
		background: 'rgba(255, 255, 255, 0.2)',
	},
	action: {
		padding: '5px 0',
	},
	actions: {
		padding: '5px 15px',
	},
}

export default withTranslate(ContactSelect)
