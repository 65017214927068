/* @flow */

import { connect } from 'react-redux'
import { AVAILABLE_PAYMENT_TYPES } from 'modules/accounting-document/constants'
import PaymentType from '../../components/invoice-elements/payment-type'
import { getFormFieldErrors } from 'modules/common/selectors'
import type { FormFieldErrorContext, FormFieldErrorMessage, State } from 'types'
import { removeError } from 'helpers'

export type InterfaceProps = {|
	paymentType: ?number,
	onPaymentTypeChange: (paymentType: number) => void,
	readonly?: boolean,
	formFieldErrorContext?: FormFieldErrorContext,
|}

type StateProps = {|
	availablePaymentTypes: Array<{ type: number, name: string }>,
	errors: ?Array<FormFieldErrorMessage>,
|}

const mapStateToProps = (state: State, ownProps: InterfaceProps): StateProps => {
	return {
		availablePaymentTypes: AVAILABLE_PAYMENT_TYPES,
		errors: ownProps.formFieldErrorContext && getFormFieldErrors(state, ownProps.formFieldErrorContext),
	}
}

type DispatchProps = {|
	removeError: () => void,
|}

const mapDispatchToProps = (dispatch: Dispatch<any>, ownProps: InterfaceProps): DispatchProps => {
	return {
		removeError: () => {
			removeError({
				formFieldErrorContext: ownProps.formFieldErrorContext,
				dispatch,
			})
		},
	}
}

const mergeProps = (stateProps: StateProps, dispatchProps: DispatchProps, ownProps: InterfaceProps) => {
	return {
		readonly: ownProps.readonly,
		paymentType: ownProps.paymentType,
		availablePaymentTypes: stateProps.availablePaymentTypes,
		onPaymentTypeChange: ownProps.onPaymentTypeChange,
		errors: stateProps.errors,
		removeError: dispatchProps.removeError,
	}
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(PaymentType)
