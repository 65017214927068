/* @flow */

import { Component } from 'react'

export type Props = {
	onLogout: () => void,
}

class Logout extends Component<Props> {
	componentDidMount() {
		this.props.onLogout()
	}

	render() {
		return null
	}
}

export default Logout
