import React from 'react'
import SvgIcon from 'components/SvgIcon'
import pure from 'recompose/pure'

let Invoice = props => {
	props = Object.assign({}, { viewBox: '0 0 27 22' }, { style: { width: 27, height: 22, transition: 'none' } }, props)
	return (
		<SvgIcon {...props}>
			<g stroke="none" transform="translate(3.000000, 0.000000)">
				<path d="M16.0972,21 L6.7662,21 L6.7662,19 L16.0972,19 C17.1092,19 17.9322,18.177 17.9322,17.166 L17.9322,3.834 C17.9322,2.823 17.1092,2 16.0972,2 L6.7662,2 C5.7552,2 4.9322,2.823 4.9322,3.834 L4.9322,13.5 L2.9322,13.5 L2.9322,3.834 C2.9322,1.72 4.6522,0 6.7662,0 L16.0972,0 C18.2122,0 19.9322,1.72 19.9322,3.834 L19.9322,17.166 C19.9322,19.28 18.2122,21 16.0972,21 Z" />
				<path d="M2,15.3306 L2,17.1656 C2,18.1776 2.823,18.9996 3.835,18.9996 L13.328,18.9996 C13.124,18.4986 13,17.8926 13,17.1656 L13,15.3306 L2,15.3306 Z M16.097,20.9996 L3.835,20.9996 C1.72,20.9996 0,19.2796 0,17.1656 L0,13.3306 L15,13.3306 L15,17.1656 C15,18.9786 16.086,18.9996 16.097,18.9996 L16.097,20.9996 Z" />
				<path
					d="M13.2346,5.4167 C13.2346,4.58245852 12.5580739,3.9057 11.7236,3.9057 C10.8900099,3.9057 10.2136,4.58257478 10.2136,5.4167 C10.2136,6.25082522 10.8900099,6.9277 11.7236,6.9277 C12.5580739,6.9277 13.2346,6.25094148 13.2346,5.4167 Z M14.2346,5.4167 C14.2346,6.80314935 13.1104355,7.9277 11.7236,7.9277 C10.3375713,7.9277 9.2136,6.80295623 9.2136,5.4167 C9.2136,4.03044377 10.3375713,2.9057 11.7236,2.9057 C13.1104355,2.9057 14.2346,4.03025065 14.2346,5.4167 Z"
					fillRule="nonzero"
				/>
				<polygon opacity="0.600000024" points="6.722 11.187 12.659 11.187 12.659 9.875 6.722 9.875" />
				<polygon opacity="0.600000024" points="14.221 11.187 16.221 11.187 16.221 9.875 14.221 9.875" />
				<polygon opacity="0.600000024" points="6.643 13.328 12.58 13.328 12.58 12.578 6.643 12.578" />
			</g>
		</SvgIcon>
	)
}
Invoice = pure(Invoice)

export default Invoice
