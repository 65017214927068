export const POSSIBLE_DOCUMENT_SOURCES = ['public_api', 'private_api_internal', 'private_api_standard']

export const POSSIBLE_DOCUMENT_TYPES = ['all', 'changed', 'sanctioned']

export const POSSIBLE_STATEMENT_TYPES = ['all', 'changed']

export const SORTED_DOCUMENT_ITEM = {
	count: 0,
	price: 0,
	list: [],
}

export const SORTED_STATEMENT_ITEM = {
	count: 0,
	price: 0,
}
