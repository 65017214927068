/* @flow */

import { connect } from 'react-redux'
import type { AccountingDocumentReduxAction, Dispatch, Base64Image, SettingsAction, State, UserAction } from 'types'
import { getOrganizationLogo } from 'modules/settings/actions'
import { getCurrentOrganizationLogo } from 'modules/settings/selectors'
import { withAutoload } from 'wrappers'
import Logo from '../../components/invoice-elements/logo'

export type OwnProps = {|
	showPlaceholder?: boolean,
|}

type StateProps = {|
	binary: ?Base64Image,
|}

const mapStateToProps = (state: State): StateProps => {
	return {
		binary: getCurrentOrganizationLogo(state),
	}
}

type DispatchProps = {|
	autoload: () => void,
|}

const mapDispatchToProps = (
	dispatch: Dispatch<AccountingDocumentReduxAction | UserAction | SettingsAction>,
): DispatchProps => {
	return {
		autoload: () => {
			dispatch(getOrganizationLogo())
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(withAutoload(Logo))
