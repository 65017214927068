/* @flow */

import { getAresContacts } from 'modules/user/selectors'
import type { AresContact, Contact, ContactAddress, ContactBankAccount, CrpdphBankAccount, State } from 'types'
import type { WithCountryVariantProps, WithNotifyProps, WithTranslateProps } from 'wrappers'
import { emptyContactAddress, emptyContactBankAccount } from 'types/empty'
import { loadContact, saveContact } from '../actions'
import { withCountryVariant, withNotify, withTranslate } from 'wrappers'
import Edit from '../components/address-book-edit'
import { combine } from 'utils'
import { connect } from 'react-redux'
import { find } from 'lodash-es'
import { loadAresContacts } from 'modules/user/actions'
import { getContact, getContactLoading, getContactSaved } from '../selectors'
import type { FinishSaveContactAction } from 'modules/address-book/actions/action-types'
import { userHasAccess } from 'permissions'

type Props = {
	...WithNotifyProps,
	...WithTranslateProps,
	...WithCountryVariantProps,
	contactId: string,
}

type StateProps = {|
	contact: ?Contact,
	loading: boolean,
	saved: boolean,
	aresContacts: ?Array<AresContact>,
	canEditContacts: boolean,
|}

const mapStateToProps = (state: State, ownProps: Props): StateProps => {
	return {
		contact: getContact(state, ownProps.contactId),
		loading: getContactLoading(state, ownProps.contactId),
		saved: getContactSaved(state),
		aresContacts: getAresContacts(state),
		canEditContacts: userHasAccess(state, 'editContacts'),
	}
}

type DispatchProps = {|
	loadContact: (id: string) => void,
	saveContact: (contact: Contact) => Promise<FinishSaveContactAction>,
	loadContactFromARES: (contact: Contact) => Promise<*>,
	setSaved: (saved: boolean) => void,
|}

function setVerifiedBankAccounts(contact: Contact, verifiedBankAccounts: Array<CrpdphBankAccount>): Contact {
	const bankAccounts: Array<ContactBankAccount> = contact.bankAccounts || []

	const combiner: (cBa: ?ContactBankAccount, crpBa: ?CrpdphBankAccount) => ContactBankAccount = (
		cBa: ?ContactBankAccount,
		crpBa: ?CrpdphBankAccount,
	): ContactBankAccount => {
		if (crpBa != null)
			return Object.assign(emptyContactBankAccount(), cBa, {
				accountNo: crpBa.accountNumber,
				accountCode: crpBa.accountCode,
				countryVariantSpecific: {
					cz: {
						isRegistered: true,
					},
				},
			})
		else if (cBa != null) return Object.assign(emptyContactBankAccount(), cBa)

		throw new Error('Cannot combine null and null value.')
	}

	const combinedAccounts: Array<ContactBankAccount> = combine(
		bankAccounts,
		verifiedBankAccounts,
		(cba: ContactBankAccount, crpBa: CrpdphBankAccount) =>
			crpBa.accountCode == cba.accountCode && crpBa.accountNumber == cba.accountNo,
		combiner,
	)

	contact.bankAccounts = combinedAccounts

	return contact
}

const mapDispatchToProps = (dispatch: Function, ownProps: Props): DispatchProps => {
	return {
		loadContactFromARES: async (contact: Contact) => {
			const { notify, t } = ownProps

			let crpdphPromise = new Promise((resolve: any) => {
				resolve()
			})
			let aresPromise = dispatch(loadAresContacts(contact.companyRegNo || ''))
			aresPromise.then((action: any) => {
				if (action && action.aresContacts && action.aresContacts.length) {
					let firstContact = action.aresContacts[0]
					if (firstContact != null) {
						if (firstContact.taxId != 'Skupinove_DPH' && firstContact.taxId != null)
							crpdphPromise = ownProps.loadContantsFromCrpDphDb(firstContact.taxId)
					}
				} else {
					if (contact.taxId != null) crpdphPromise = ownProps.loadContantsFromCrpDphDb(contact.taxId)
				}
			})

			await aresPromise
			aresPromise.then(onAresContactsLoaded(contact))

			let promise = Promise.all([aresPromise, crpdphPromise])
			promise.then((results: Array<{}>) => {
				let aresFinished: any = find(results, {
					type: 'FINISH_LOADING_ARES_CONTACTS',
				})
				let crpdphFinished: any = find(results, {
					type: 'FINISH_SEARCH_CONTACT_IN_CRPDPH_DB',
				})

				const crpdphResult = crpdphFinished != null ? crpdphFinished.response : null
				const aresContacts = aresFinished != null ? aresFinished.aresContacts : null

				let aresFound = aresContacts != null && aresContacts.length > 0

				if (!aresFound) notify(t('contactEdit.ares.loadFail', { companyRegNo: contact.companyRegNo || '' }), 'error')
				else {
					if (crpdphResult != null) {
						setVerifiedBankAccounts(contact, crpdphResult.registeredBankAccounts)

						if (crpdphResult.unreliableVATPayerType != 3) {
							if (crpdphResult.unreliableVATPayerType == 1) {
								//YES
								notify(t('contactEdit.ares.unreliableVATPayer', { ico: contact.companyRegNo || '' }), 'error')
							}
							if (crpdphResult.unreliableVATPayerType == 2) {
								//NO
								notify(t('contactEdit.ares.reliableVATPayer', { ico: contact.companyRegNo || '' }), 'success')
							}
							return
						}
					}
					notify(t('contactEdit.ares.loadSuccess', { companyRegNo: contact.companyRegNo || '' }), 'success')
				}
			})

			return promise
		},
		loadContact: (id: string) => {
			dispatch(loadContact(id, true))
		},
		saveContact: (contact: Contact): Promise<FinishSaveContactAction> => {
			return dispatch(saveContact(contact))
		},
		setSaved: (saved: boolean) => {
			dispatch({
				type: 'SET_CONTACT_SAVED',
				saved,
			})
		},
	}
}

function onAresContactsLoaded(contact: Contact) {
	return (action: any) => {
		if (action && action.aresContacts && action.aresContacts.length) {
			let firstContact = action.aresContacts[0]
			if (firstContact != null) {
				contact.firstName = firstContact.firstname
				contact.lastName = firstContact.lastname
				contact.companyRegNo = firstContact.companyRegNo
				contact.companyName = firstContact.companyName
				if (firstContact.taxId != 'Skupinove_DPH') contact.taxId = firstContact.taxId
				contact.addresses = contact.addresses || []
				let billingAddress =
					contact.addresses.find((address: ContactAddress) => address.type == 1) || emptyContactAddress()
				if (firstContact.address != null)
					billingAddress = Object.assign(billingAddress, {
						city: firstContact.address.city,
						country: firstContact.address.country,
						street: firstContact.address.street,
						zipCode: firstContact.address.zipCode,
					})
				if (contact.addresses != null) {
					contact.addresses[0] = billingAddress
				}
			}
		}
	}
}

export default withTranslate(withNotify(withCountryVariant(connect(mapStateToProps, mapDispatchToProps)(Edit))))
