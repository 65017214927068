/* @flow */
/** @jsx jsx */

import { jsx } from '@emotion/core'
import { colors } from 'variables'

const styles = {
	margin: 0,
	height: 1,
	border: 'none',
	outline: 'none',
	boxShadow: 'none',
	backgroundColor: colors.gray400,
}

function Divider() {
	return <hr css={styles} />
}

export default Divider
