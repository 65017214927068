import React from 'react'
import pure from 'recompose/pure'
import SvgIcon from 'components/SvgIcon'

let NestedClose = props => (
	<SvgIcon {...props}>
		<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g transform="translate(6, 6)">
				<rect
					stroke="#08B0CD"
					fillOpacity="0.6"
					fill="#FFFFFF"
					opacity="0.7"
					x="0.5"
					y="0.5"
					width="11"
					height="11"
					rx="3"
				/>
				<polygon fill="#08B0CD" points="7.44 6.292 4.56 6.292 4.56 5.5 7.44 5.5" />
			</g>
		</g>
	</SvgIcon>
)
NestedClose = pure(NestedClose)

export default NestedClose
