//@flow

type Level = 'info' | 'warning' | 'error'

type AdditionalData = {|
	level?: Level,
	tags?: { [key: string]: string },
	extra?: Object,
|}

function logMessage(message: string, extra?: Object) {
	const additionalData: AdditionalData = { level: 'info', extra }
	window.Raven && window.Raven.captureMessage(message, additionalData)
}

export default { logMessage }
