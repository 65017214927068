// @flow
/** @jsx jsx */

import { jsx } from '@emotion/core'
import { Component } from 'react'
import { autoTestId, type AutoTestProps } from 'utils/tests/autotest'
import { colors } from 'variables'
import Paper from 'components/Paper'

type Props = {|
	...AutoTestProps,
	autoWidth?: boolean,
	disabled?: boolean,
	label?: string,
	labelPosition?: 'left' | 'right',
	labelInside?: string,
	labelInsideChecked?: string,
	toggled: boolean,
	onToggle?: (event: SyntheticEvent<HTMLInputElement>, isInputChecked: boolean) => void,
|}
class Toggle extends Component<Props> {
	handleChange = (event: SyntheticEvent<HTMLInputElement>) => {
		const isInputChecked = this.refs.checkbox.checked
		this.props.onToggle && this.props.onToggle(event, isInputChecked)
	}

	render() {
		const styles = getStyles(this.props)

		const label = this.props.label && <label style={styles.label}>{this.props.label}</label>

		const thumb = (
			<div style={styles.wrap}>
				<div style={styles.track}>
					{this.props.labelInside && (
						<div style={styles.labelInside}>
							{this.props.labelInsideChecked && this.props.toggled
								? this.props.labelInsideChecked
								: this.props.labelInside}
						</div>
					)}
				</div>
				<Paper style={styles.thumb} zDepth={3} circle>
					{' '}
				</Paper>
			</div>
		)

		return (
			<div style={styles.root} {...autoTestId(this.props.autoTestId)}>
				<input
					ref="checkbox"
					type="checkbox"
					style={styles.input}
					disabled={this.props.disabled}
					onChange={this.handleChange}
					checked={this.props.toggled}
				/>
				<div style={styles.controls}>
					{this.props.labelPosition === 'right' ? thumb : label}
					{this.props.labelPosition === 'right' ? label : thumb}
				</div>
			</div>
		)
	}
}

function getStyles(props: Props) {
	const toggleSize = props.labelInside ? 24 : 20
	const toggleTrackWidth = props.labelInside ? 'auto' : 36

	return {
		root: {
			cursor: props.disabled ? 'not-allowed' : 'pointer',
			position: 'relative',
			overflow: 'visible',
			display: 'table',
			alignItems: 'center',
			height: 'auto',
			width: props.autoWidth ? 'auto' : '100%',
			transition: 'all 200ms ease-in-out',
		},
		input: {
			position: 'absolute',
			cursor: 'inherit',
			pointerEvents: 'all',
			opacity: 0,
			width: '100%',
			height: '100%',
			zIndex: 2,
			left: 0,
			boxSizing: 'border-box',
			padding: 0,
			margin: 0,
		},
		labelInside: {
			color: colors.white,
			fontSize: 10,
			fontWeight: 'bold',
			lineHeight: '20px',
			paddingLeft: props.toggled ? 12 : 30,
			paddingRight: props.toggled ? 30 : 12,
			textTransform: 'uppercase',
			transition: 'all 200ms ease-in-out',
		},
		controls: {
			width: '100%',
			height: '100%',
			display: 'flex',
			justifyContent: 'flex-start',
			alignItems: 'center',
			alignContent: 'center',
		},
		label: {
			float: 'left',
			position: 'relative',
			display: 'block',
			color: props.disabled ? colors.disabled : 'inherit',
			width: props.labelInside || props.autoWidth ? 'auto' : `calc(100% - ${toggleTrackWidth + 10}px)`,
			lineHeight: props.labelInside ? '30px' : '24px',
		},
		wrap: {
			position: 'relative',
			display: 'block',
			flexShrink: 0,
			marginRight: 'right' === props.labelPosition && props.label ? 8 : 0,
			marginLeft: 'right' !== props.labelPosition && props.label ? 8 : 0,
			width: props.labelInside ? 'auto' : 36,
			padding: '5px 0px 5px 2px',
			transition: 'all 200ms ease-in-out',
		},
		track: {
			transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1)',
			width: props.labelInside ? 'auto' : '100%',
			height: props.labelInside ? 20 : 14,
			borderRadius: 30,
			backgroundColor: props.disabled ? colors.disabled : props.toggled ? colors.green : colors.grey300,
		},
		thumb: {
			transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1)',
			position: 'absolute',
			top: 2,
			left: props.toggled ? '100%' : 0,
			marginLeft: props.toggled ? 0 - toggleSize : 0,
			width: toggleSize,
			height: toggleSize,
			lineHeight: '24px',
			backgroundColor: props.disabled ? colors.disabled : colors.white,
			borderColor: props.toggled && !props.disabled ? (props.labelInside ? colors.green : '#0A8F2B') : colors.grey300,
			borderWidth: 2,
		},
	}
}

export default Toggle
