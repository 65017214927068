// @flow

import type {
	AccountingDocumentDirection,
	AccountingDocumentListAction,
	CommonAction,
	AccountingDocumentView,
	Dispatch,
	ValidationError,
} from 'types'
import {
	accountingDocumentActions as accountingDocumentActionsApi,
	accountingDocumentBulkActions as accountingDocumentBulkActionsApi,
} from 'modules/common/models/api-model'

export function changeAccountingDocumentsDirection(
	direction: AccountingDocumentDirection,
): AccountingDocumentListAction {
	return {
		type: 'CHANGE_ACCOUNTING_DOCUMENT_LIST_DIRECTION',
		direction: direction,
	}
}

export function resetAccountingDocumentsDirection(): AccountingDocumentListAction {
	return {
		type: 'CHANGE_ACCOUNTING_DOCUMENT_LIST_DIRECTION',
		direction: undefined,
	}
}

export function changeAccountingDocumentsView(view: AccountingDocumentView): AccountingDocumentListAction {
	return {
		type: 'CHANGE_ACCOUNTING_DOCUMENT_LIST_VIEW',
		view: view,
	}
}

export function loadAccountingDocumentActions(ids: Array<string>) {
	return async (dispatch: Dispatch<AccountingDocumentListAction>) => {
		dispatch({
			type: 'START_LOAD_ACCOUNTING_DOCUMENT_ACTIONS',
			ids: ids,
		})

		try {
			const result = await accountingDocumentActionsApi.post(
				{},
				{
					accountingDocumentIds: ids,
				},
			)
			return dispatch({
				type: 'FINISH_LOAD_ACCOUNTING_DOCUMENT_ACTIONS',
				ids: ids,
				actions: result,
			})
		} catch (error) {
			return dispatch({
				type: 'FINISH_LOAD_ACCOUNTING_DOCUMENT_ACTIONS',
				ids: ids,
				actions: null,
				serverError: error,
			})
		}
	}
}

export function accountingDocumentsBulkAction(operation: string, ids: Array<string>, additionalData: {}) {
	return async (dispatch: Dispatch<AccountingDocumentListAction | CommonAction>) => {
		dispatch({
			type: 'START_LOAD_ACCOUNTING_DOCUMENT_BULK_ACTION',
			operation,
			ids,
			additionalData,
		})

		try {
			const result = await accountingDocumentBulkActionsApi.post({
				operation,
				ids,
				additionalData,
			})

			if (result.result) {
				for (let i = 0; i < result.result.length; i++) {
					const validationError: ?ValidationError = result.result[i].validationError
					if (validationError) {
						dispatch({
							type: 'SHOW_SERVER_ERROR',
							serverError: validationError,
						})
					}
				}
			}

			return dispatch({
				type: 'FINISH_LOAD_ACCOUNTING_DOCUMENT_BULK_ACTION',
				operation,
				ids,
				additionalData,
				result,
			})
		} catch (error) {
			return dispatch({
				type: 'FINISH_LOAD_ACCOUNTING_DOCUMENT_BULK_ACTION',
				operation,
				ids,
				additionalData,
				serverError: error,
			})
		}
	}
}
