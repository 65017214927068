/* @flow */

import { connect } from 'react-redux'
import type { OrganizationMembers, State } from 'types'
import OrganizationSelect from 'modules/organization/components/organization-select'
import { getCurrentUserOrganizations } from '../../user/selectors'

type StateProps = {|
	organizations: ?OrganizationMembers,
|}

const mapStateToProps = (state: State): StateProps => {
	return {
		organizations: getCurrentUserOrganizations(state),
	}
}

export default connect(mapStateToProps, () => ({}))(OrganizationSelect)
