/* @flow */

import { t } from 'i18next'
import { loadUsers } from 'modules/organization/actions'
import { userHasAccess } from 'permissions'
import { loadInternalUsers } from 'modules/user/actions'
import type { ChatAction, Dispatch, OrganizationAction, State, UserAction, ValidationError } from 'types'
import { chatUsersInfo } from '../../common/models/api-model'
import { getChannelPosition, mergeChannelLocalState } from '../chat/channel'
import ChatClient from '../chat/chat'
import { cleanWindowProps, getWindowProps, getWindowState, saveWindowProps } from '../chat/window'
import { getChannel, getCurrentUserId, getUnknownUsersIds, getUser } from '../selectors'
import type { Channel, Member, Message, WindowState } from '../types'

let chatClient: ?ChatClient
function getChatClient(): ChatClient {
	if (!chatClient) {
		throw new Error('Chat client is not created yet')
	}
	return chatClient
}

export function toggleChannelList(window?: WindowState) {
	return function(dispatch: Dispatch<ChatAction>, getState: () => State) {
		dispatch({
			type: 'TOGGLE_CHANNEL_LIST',
			window: getWindowState(getState().chat.channelList.window, window),
		})
	}
}

export function toggleChannelNew(window?: WindowState) {
	return function(dispatch: Dispatch<ChatAction>, getState: () => State) {
		dispatch({
			type: 'TOGGLE_CHANNEL_NEW',
			window: getWindowState(getState().chat.channelNew.window, window),
		})
	}
}

export function toggleChannel(channel: Channel, window?: WindowState) {
	return function(dispatch: Dispatch<ChatAction>, getState: () => State) {
		window = getWindowState(getState().chat.channels[channel.sid].window, window)
		const position = getChannelPosition(getState().chat.channels, channel, window)

		saveWindowProps(channel.sid, { window, position })

		dispatch({
			type: 'TOGGLE_CHANNEL',
			channel,
			window,
			position,
		})
	}
}

export function addMessage(message: Message, channel: Channel, options?: { optimistic?: boolean }) {
	return async function(dispatch: Dispatch<ChatAction>) {
		const optimistic = options && options.optimistic && true === options.optimistic
		message.status = 'SENDING'

		dispatch({
			type: 'ADD_MESSAGE',
			message,
			channel,
		})

		if (!optimistic) {
			try {
				const newMessageIndex = await getChatClient().sendMessage(channel, message)
				await dispatch(updateLastConsumedMessage(channel, newMessageIndex))
			} catch (e) {
				message.status = 'SENDING_ERROR'
				dispatch({
					type: 'FINISH_ADD_MESSAGE',
					message,
					channel,
					serverError: wrapError(e, t('chat.errors.add_message')),
				})
			}
		}
	}
}

export function receiveMessage(message: Message, channel: Channel) {
	return {
		type: 'FINISH_LOAD_MESSAGES',
		channel,
		messages: [message],
	}
}

export function loadMessages(channel: Channel, limit?: number, fromIndex?: number) {
	return async function(dispatch: Dispatch<ChatAction>) {
		dispatch({ type: 'LOAD_MESSAGES', channel })
		try {
			const messages = await getChatClient().getMessages(channel, limit, fromIndex)
			dispatch({ type: 'FINISH_LOAD_MESSAGES', channel, messages })
		} catch (e) {
			dispatch({
				type: 'FINISH_LOAD_MESSAGES',
				channel,
				serverError: wrapError(e, t('chat.errors.load_messages')),
			})
		}
	}
}

export function updateLastConsumedMessage(channel: Channel, index: number) {
	return async function(dispatch: Dispatch<ChatAction>, getState: () => State) {
		const actionData = {
			userId: getCurrentUserId(getState()),
			channelSid: channel.sid,
			index,
			timestamp: new Date(),
		}
		dispatch(create('UPDATE_LAST_CONSUMED_MESSAGE', actionData))
		try {
			if ('LOADED' === channel.status) {
				await getChatClient().updateLastConsumedMessage(channel, index)
			}
			dispatch(create('FINISH_UPDATE_LAST_CONSUMED_MESSAGE', actionData))
		} catch (e) {
			dispatch(
				create('FINISH_UPDATE_LAST_CONSUMED_MESSAGE', actionData, {
					serverError: wrapError(e, 'chat.errors.update_last_consumed_message'),
				}),
			)
		}
	}
}

export function updateMember(member: Member, channel: Channel) {
	return {
		type: 'UPDATE_MEMBER',
		member,
		channel,
	}
}

export function createChannel(channel: Channel, message: Message) {
	return async function(dispatch: Dispatch<ChatAction>) {
		dispatch({
			type: 'CREATE_CHANNEL',
			channel,
			message,
		})
		dispatch(updateLastConsumedMessage(channel, 0))
		dispatch(addMessage(message, channel, { optimistic: true }))

		try {
			const createdChannel = await getChatClient().createChannel(channel)
			await dispatch(updateLastConsumedMessage(createdChannel, 0))
			createdChannel.window = 'OPEN'
			dispatch({
				type: 'FINISH_CREATE_CHANNEL',
				channel: createdChannel,
				localSid: channel.sid,
			})
			await dispatch(addMessage(message, createdChannel))
			dispatch(toggleChannel(createdChannel, createdChannel.window))
		} catch (e) {
			dispatch({
				type: 'FINISH_CREATE_CHANNEL',
				channel,
				serverError: wrapError(e, 'chat.errors.create_channel'),
			})
		}
	}
}

export function receiveChannel(channel: Channel) {
	return async function(dispatch: Dispatch<ChatAction>, getState: () => State) {
		return dispatch({
			type: 'RECEIVE_CHANNEL',
			channel: mergeChannelLocalState(channel, getChannel(getState(), channel.sid), getWindowProps(channel.sid)),
		})
	}
}

export function initChat() {
	return async function(dispatch: Dispatch<ChatAction>, getState: () => State) {
		if (chatClient) {
			return
		}

		dispatch({
			type: 'INIT_CHAT',
		})

		try {
			chatClient = new ChatClient(dispatch, (userId: string) => getUser(getState(), userId).appId)
			await chatClient.init()
			return {
				type: 'FINISH_INIT_CHAT',
			}
		} catch (e) {
			return dispatch({
				type: 'FINISH_INIT_CHAT',
				serverError: wrapError(e, 'chat.errors.init_chat'),
			})
		}
	}
}

export function shutdownChat() {
	return async function(dispatch: Dispatch<ChatAction>) {
		if (chatClient) {
			await chatClient.shutdown()
			chatClient = null
		}

		cleanWindowProps()

		dispatch({
			type: 'FINISH_SHUTDOWN_CHAT',
		})
	}
}

export function loadUsersIfEmpty() {
	return async function(dispatch: Dispatch<OrganizationAction | UserAction | ChatAction>, getState: () => State) {
		const internalUsers = getState().user.internalUsers
		if (userHasAccess(getState(), 'seeInternalUsers') && !internalUsers.data) {
			await dispatch(loadInternalUsers())
		}

		const users = getState().organization.users
		if (!users.data) {
			await dispatch(loadUsers())
		}

		const unknownUserIds = getUnknownUsersIds(getState())
		if (unknownUserIds.length) {
			await dispatch(loadChatUsers(unknownUserIds))
		}
	}
}

export function loadChatUsers(userIds: Array<string>) {
	return async function(dispatch: Dispatch<ChatAction>, getState: () => State) {
		if (getState().chat.usersLoading) {
			return
		}

		dispatch({ type: 'LOAD_CHAT_USERS', userIds })
		try {
			const response = await chatUsersInfo.post({ chatIdentities: userIds })
			dispatch({ type: 'FINISH_LOAD_CHAT_USERS', userIds, users: response.chatUsersInfo || [] })
		} catch (e) {
			// Ignore error
			dispatch({ type: 'FINISH_LOAD_CHAT_USERS', userIds, users: [] })
		}
	}
}

export function saveUnsentMessage(message: ?Message, channel: Channel) {
	return {
		type: 'SAVE_UNSENT_MESSAGE',
		message,
		channel,
	}
}

// Utility

function create<T: string, A: {}, B: {}>(type: T, data: A, extra?: B): $Exact<{ ...A, ...B, type: T }> {
	return Object.freeze({ ...{ type }, ...data, ...extra })
}

function wrapError(error: ValidationError, message: string) {
	return { ...error, ...{ message, params: { twilioMessage: error.message } } }
}
