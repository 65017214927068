/* @flow */

import { connect } from 'react-redux'
import PathMatch from 'path-match'
import { COMPACT_NAVIGATION_ROUTES } from '../constants'
import type { AccountingDocumentReduxAction, Dispatch, NavigationAction, SettingsAction, State } from 'types'
import Navigation from '../components/navigation'
import { currentUserIsInternal } from '../../user/selectors'

const matchRoute = PathMatch({ sensitive: false, strict: true, end: false })

type StateProps = {|
	compact: boolean,
	isCompactRoute: boolean,
	organizationId: ?string,
	isInternal?: boolean,
|}

const mapStateToProps = (state: State): StateProps => {
	const isCompactRoute = !!COMPACT_NAVIGATION_ROUTES.find((route: string) => {
		return matchRoute(route)(window.location.pathname)
	})

	return {
		isCompactRoute: isCompactRoute,
		compact: state.navigation.compact,
		organizationId: state.user.currentOrganizationId,
		isInternal: currentUserIsInternal(state),
	}
}

type DispatchProps = {|
	setCompact: (compact: boolean) => void,
|}

const mapDispatchToProps = (
	dispatch: Dispatch<AccountingDocumentReduxAction | NavigationAction | SettingsAction>,
): DispatchProps => {
	return {
		setCompact: (compact: boolean) => {
			dispatch({ type: 'SET_NAVIGATION_COMPACT', compact })
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Navigation)
