// @flow

import { connect } from 'react-redux'
import type { Dispatch, PaymentReminder, EmailTemplate, State } from 'types'
import { deletePaymentReminder, loadPaymentReminders, loadEmailTemplates } from 'modules/settings/actions'
import type { Action as EmailAction } from 'modules/settings/actions/email-action-types'
import PaymentReminders from 'modules/settings/components/trivi-settings/payment-reminders'
import type { Action as PaymentRemindersAction } from 'modules/settings/actions/payment-reminders-action-types'

type StateProps = {|
	emailTemplates: ?Array<EmailTemplate>,
	paymentReminders: ?Array<PaymentReminder>,
|}

const mapStateToProps = (state: State): StateProps => {
	return {
		emailTemplates: state.settings.emailTemplates,
		paymentReminders: state.settings.paymentReminders,
	}
}

type DispatchProps = {|
	loadEmailTemplates: () => void,
	loadPaymentReminders: () => void,
	deletePaymentReminder: (id: string) => void,
|}

const mapDispatchToProps = (dispatch: Dispatch<PaymentRemindersAction | EmailAction>): DispatchProps => {
	return {
		loadEmailTemplates: () => {
			dispatch(loadEmailTemplates())
		},
		loadPaymentReminders: () => {
			dispatch(loadPaymentReminders())
		},
		deletePaymentReminder: (id: string) => {
			dispatch(deletePaymentReminder(id))
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentReminders)
