// @flow
import type { CashbotInvoice, CashbotOffer } from '../types'
import type { UserAction } from 'types'
import type { CashbotAction } from '../actions/action-types'
import Produce from 'immer'

type CashbotData = {|
	loading: boolean,
	creating: boolean,
	cashbotInvoiceId: ?number,
	cashbotInvoice: ?CashbotInvoice,
	cashbotOffers: ?Array<CashbotOffer>,
	isCashbotOffersLoading: boolean,
	isMarkedToBeFinanced: boolean,
|}

export type State = {
	token: ?string,
	data: {
		[accDocId: string]: CashbotData,
	},
}

export const initialState: State = {
	token: null,
	data: {},
}

export default function cashbotReducer(state: State = initialState, action: CashbotAction | UserAction): State {
	return Produce(state, (draft: State) => {
		switch (action.type) {
			case 'START_LOAD_CASHBOT_INVOICE':
				createEmptyCashbotDataIfNecessary(draft, action.accDocId)
				draft.data[action.accDocId].loading = true
				draft.data[action.accDocId].cashbotInvoiceId = action.cashbotInvoiceId
				break
			case 'FINISH_LOAD_CASHBOT_INVOICE':
				createEmptyCashbotDataIfNecessary(draft, action.accDocId)
				draft.data[action.accDocId].loading = false
				draft.data[action.accDocId].cashbotInvoiceId = action.cashbotInvoiceId
				draft.data[action.accDocId].cashbotInvoice = action.cashbotInvoice
				break
			case 'FINISH_LOAD_BOOTSTRAP':
				draft.token =
					(action.data &&
						action.data.security &&
						action.data.security.tokens &&
						action.data.security.tokens.cashbot &&
						action.data.security.tokens.cashbot.token) ||
					null
				break
			case 'START_CREATE_CASHBOT_INVOICE':
				createEmptyCashbotDataIfNecessary(draft, action.accDocId)
				draft.data[action.accDocId].creating = true
				break
			case 'FINISH_CREATE_CASHBOT_INVOICE':
				createEmptyCashbotDataIfNecessary(draft, action.accDocId)
				draft.data[action.accDocId].creating = false
				draft.data[action.accDocId].cashbotInvoiceId = action.cashbotInvoice && action.cashbotInvoice.id
				draft.data[action.accDocId].cashbotInvoice = action.cashbotInvoice
				break
			case 'START_DOWNLOAD_CASHBOT_OFFERS':
				createEmptyCashbotDataIfNecessary(draft, action.accDocId)
				draft.data[action.accDocId].isCashbotOffersLoading = true
				break
			case 'FINISH_DOWNLOAD_CASHBOT_OFFERS':
				createEmptyCashbotDataIfNecessary(draft, action.accDocId)
				draft.data[action.accDocId].cashbotOffers = action.cashbotOffers
				draft.data[action.accDocId].isCashbotOffersLoading = false
				break
			case 'FINISH_CONFIRM_CASHBOT_OFFER':
				createEmptyCashbotDataIfNecessary(draft, action.accDocId)
				if (action.cashbotInvoice) {
					draft.data[action.accDocId].cashbotInvoice = action.cashbotInvoice
				}
				break
			case 'MARK_TO_BE_FINANCED':
				createEmptyCashbotDataIfNecessary(draft, action.accDocId)
				draft.data[action.accDocId].isMarkedToBeFinanced = action.toBeFinanced
				break
			case 'LOGOUT_FROM_CASHBOT':
				if (action.phase === 'SUCCESS') {
					draft.token = null
				}
				break
			default:
				// TODO - má se vracet state nebo draft? Zvolili jsme state, aby nedocházelo
				// k vytváření nového objektu a případnéme rerenderu, když se nic nemění
				return state
		}
	})
}

function createEmptyCashbotDataIfNecessary(draft: State, accDocId: string) {
	if (!draft.data[accDocId]) {
		draft.data[accDocId] = emptyCashbotData()
	}
}

function emptyCashbotData(): CashbotData {
	return {
		loading: false,
		creating: false,
		cashbotInvoiceId: null,
		cashbotInvoice: null,
		cashbotOffers: null,
		isCashbotOffersLoading: false,
		isMarkedToBeFinanced: false,
	}
}
