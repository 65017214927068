// @flow

export type AutoTestProps = {|
	autoTestId?: string,
|}

export function autoTestId(id: ?string, suffix?: string) {
	return id ? { 'data-autotest-id': id + (suffix ? `-${suffix}` : '') } : {}
}

export function passAutoTestId(autoTestId?: string, suffix?: string): ?AutoTestProps {
	if (autoTestId) {
		return { autoTestId: autoTestId + (suffix ? `-${suffix}` : '') }
	}
}
