// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3FJjEYfd23rSF-wxWtnfQ0 {\n\tmargin: 40px auto;\n\ttext-align: left;\n\tmax-width: 340px;\n\twidth: 100%;\n}\n\n._1xq0-CYAv2gUb4Q12lm2Ry {\n\tmargin-bottom: 15px;\n}\n", "",{"version":3,"sources":["webpack://./modules/user/components/internal/internal-register-form.css"],"names":[],"mappings":"AAAA;CACC,iBAAA;CACA,gBAAA;CACA,gBAAA;CACA,WAAA;AACD;;AAEA;CACC,mBAAA;AACD","sourcesContent":[".root {\n\tmargin: 40px auto;\n\ttext-align: left;\n\tmax-width: 340px;\n\twidth: 100%;\n}\n\n.row {\n\tmargin-bottom: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "_3FJjEYfd23rSF-wxWtnfQ0",
	"row": "_1xq0-CYAv2gUb4Q12lm2Ry"
};
export default ___CSS_LOADER_EXPORT___;
