/* @flow */

// Redux

export type ChatState = {|
	messagesByChannel: MessagesByChannel,
	channels: Channels,
	chat: {|
		enabled: boolean,
	|},
	channelList: {
		window: WindowState,
	},
	channelNew: {
		window: WindowState,
	},
	users: Array<User>,
	usersLoading: boolean,
|}

export const initialState: ChatState = {
	messagesByChannel: {},
	channels: {},
	chat: {
		enabled: true,
	},
	channelList: {
		window: 'CLOSED',
	},
	channelNew: {
		window: 'CLOSED',
	},
	users: [],
	usersLoading: false,
}

// Messages

export type Messages = Array<Message>

export type MessagesByChannel = {
	[channelSid: string]: Messages,
}

export type Message = {|
	sid: string, // Twilio unique ID (for the new message is SID temporary different, generated locally)
	created: Date,
	updated?: Date,
	body: string, // The contents of the message
	index?: number, // The index of the message within the Channel
	from: string, // Trivi ID of the message's author
	status: MessageStatus, // internal message status
	attributes: { [string]: any },
|}

export type MessageStatus =
	| 'NEW' // New message
	| 'SENDING'
	| 'SENDING_ERROR'
	| 'SENT'
	| 'LOADED' // Message was loaded from Twilio service

// Channels

export type Channels = {
	[channelSid: string]: Channel,
}

export type Channel = {|
	sid: string, // Twilio unique ID (for the new channel is SID temporary different, generated locally)
	name: string,
	members: Array<Member>,
	status: ChannelStatus,
	window: WindowState,
	position?: number, // Position of conversation tab
	unsentMessage?: ?Message,
|}

export type Member = {|
	id: string,
	lastConsumedMessageIndex?: number,
	lastConsumptionTimestamp?: Date,
|}

export type Members = Array<Member>

export type ChannelStatus =
	| 'NEW' // New channel
	| 'LOADED' // Channel was loaded from Twilio service

// Users

export type Users = {
	[userId: string]: User,
}

export type User = {|
	id: string, // Twilio identity (generated on Trivi backend based on Trivi ID)
	appId: string, // Trivi ID
	name: string,
	organization: string,
|}

// Utils

export type WindowState = 'OPEN' | 'CLOSED' | 'MINIMIZED'
