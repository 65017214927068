/* @flow */

import React, { Component } from 'react'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import type { BankAccount } from 'types'
import Chip from 'components/chip'
import IconMenu from 'components/icon-menu'
import MenuItem from 'components/menu-item'
import styles from './bank-account-detail.css'

export type Props = {|
	bankAccount: ?BankAccount,
	onEdit?: () => void,
	showEdit: boolean,
|}

class BankAccountDetail extends Component<{| ...WithTranslateProps, ...Props |}> {
	render() {
		const { bankAccount, t } = this.props

		if (!bankAccount) {
			return null
		}

		return (
			<div>
				<div className={styles.header}>
					<div className={styles.nameContainer}>
						<div className={styles.nameRow}>
							<h1 className={styles.name} id={'bank-account-name'}>
								{bankAccount.name}
							</h1>
							<div className={styles.chips}>
								<span className={styles.chip}>
									<Chip>{bankAccount.currency}</Chip>
								</span>
								{bankAccount.isMain && <Chip>{t('bank.main')}</Chip>}
							</div>
						</div>
						<div className={styles.accountNumber}>
							{bankAccount.displayNumber
								? bankAccount.displayNumber
								: (bankAccount.accountNo || '') + '/' + (bankAccount.accountCode || '')}
						</div>
					</div>
					{this.props.showEdit && (
						<div className={styles.right}>
							<div className={styles.menuContainer}>
								<IconMenu autoTestId="bank-account-detail-settings">
									{[<MenuItem key={'settings'} primaryText={t('bank.settings')} onClick={this.props.onEdit} />]}
								</IconMenu>
							</div>
						</div>
					)}
				</div>
			</div>
		)
	}
}

export default withTranslate(BankAccountDetail)
