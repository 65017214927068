//@flow

import type { Group, State, UserGroup, UserProfile, OrganizationMember, OrganizationInternalUser } from 'types'
import {
	getMyProfile,
	getCurrentUserOrganizations,
	getCurrentOrganizationId,
	getCurrentUserUsername,
	getCurrentUserEmail,
} from 'modules/user/selectors'
import { selectGroups } from 'modules/groups/selectors'
import { getCurrentOrganizationInternalUsers } from 'modules/organization/selectors'
import { isTestPermissionEnabled, isTestPermissionDisabled } from 'modules/features/selectors'
import Features from 'utils/features'
import { createSelector } from 'reselect'
import ACL, { type Permission, type PermissionStruc } from './acl'
import { uniq } from 'lodash-es'

/**
 * Permissions user has to be an admin of current organization. If user is not an admin,
 * these permissions are removed from list of allowed permissions
 */
const ADMIN_INTERNAL_PERMISSIONS: Array<string> = [
	'internal_assigned_access',
	'internal_assigned_access_accountingdocuments_issued',
	'internal_assigned_access_accountingdocuments_received',
]

/**
 * Returns true if user has allowed given permission. False otherwise
 *
 * @param {State} state - redux state of application
 * @param {Permission} permission - permission you want to know if it's allowed
 */
export function userHasAccess(state: State, permission: Permission): boolean {
	if (Features.isEnabled('permissionsTestTool')) {
		if (isTestPermissionEnabled(state, permission)) {
			return true
		}
		if (isTestPermissionDisabled(state, permission)) {
			return false
		}
	}

	const allowedPermissions: Array<string> = _currentUserPermissions(state)
	const allowedInternalPermissions: Array<string> = _currentUserInternalPermissions(state)

	const struc: ?PermissionStruc = ACL[permission]

	if (!struc) {
		throw new Error(`Unsupported permission '${permission}'`)
	}

	let myProfile: ?UserProfile = null
	let allowed: boolean = false

	if (struc.isAdmin !== undefined) {
		myProfile = getMyProfile(state)
		allowed = !!(myProfile && myProfile.isAdmin === struc.isAdmin)
	}

	if (allowed) {
		return allowed
	}

	if (struc.isInternal !== undefined) {
		myProfile = myProfile || getMyProfile(state)
		allowed = !!(myProfile && myProfile.isInternal === struc.isInternal)
	}

	if (allowed) {
		return allowed
	}

	let permissionHit: boolean = false

	if (struc.rights) {
		permissionHit = allowedPermissions.some(
			(allowedPermission: string) => struc.rights && struc.rights.some((p: string) => p === allowedPermission),
		)
	}

	// if permissionHit is allready true -> no need to check internalPermissions
	if (struc.internalRights) {
		permissionHit =
			permissionHit ||
			allowedInternalPermissions.some(
				(allowedPermission: string) =>
					struc.internalRights && struc.internalRights.some((p: string) => p === allowedPermission),
			)
	}

	return permissionHit
}

/**
 * returns user's permissions that are given by group.
 */
const _currentUserPermissions = createSelector(
	getMyProfile,
	selectGroups,
	getCurrentUserUsername,
	getCurrentUserEmail,
	(
		currentUser: ?UserProfile,
		groups: ?Array<Group>,
		currentUserUsername: ?string,
		currentUserEmail: ?string,
	): Array<string> => {
		const permissions = []

		// [TWU-1612] hack for demo users permission
		if (currentUserUsername !== 'demo@trivi.com' && currentUserEmail !== 'demo@trivi.com') {
			permissions.push('see_nondemo')
		} else {
			permissions.push('demo_user')
		}

		const currentUserGroups: ?Array<UserGroup> = currentUser && currentUser.groups

		if (!currentUserGroups || !groups) {
			return permissions
		}

		// add group permissions of all groups user is member in
		currentUserGroups.forEach((currentUserGroup: UserGroup) => {
			const userGroup: ?Group = groups && groups.find((g: Group) => g.id === currentUserGroup.id)
			if (userGroup && userGroup.permissions) {
				userGroup.permissions.forEach((p: string) => permissions.push(p))
			}
		})

		return uniq(permissions)
	},
)

/**
 * returns user's internal permissions. Permissions are filtered accoording whether user is admin of current organization or not.
 */
const _currentUserInternalPermissions = createSelector(
	getCurrentUserOrganizations,
	getCurrentOrganizationId,
	getMyProfile,
	(state: State) => getCurrentOrganizationInternalUsers(state),
	(
		organizationMembers: ?Array<OrganizationMember>,
		currentOrganizationId: ?string,
		currentUser: ?UserProfile,
		internalUsers: ?Array<OrganizationInternalUser>,
	): Array<string> => {
		const org: ?OrganizationMember =
			organizationMembers &&
			organizationMembers.find((o: OrganizationMember) => o.organizationId === currentOrganizationId)

		// unique values from union of userInternalPermissions and currentOrganizationInternalPermissions
		let uniquePermissions: Array<string> = uniq([
			...((org && org.permissions) || []),
			...((currentUser && currentUser.internalPermissions) || []),
		])

		// flag if user is also internalUser in current organization
		const currentUserIsOrganizationAssigned: ?boolean =
			currentUser &&
			internalUsers &&
			internalUsers.find((iu: OrganizationInternalUser) => currentUser && iu.userId === currentUser.id) != null

		// if user is not as internal user in current organization -> remove all admin rights he might have for another organization
		if (!currentUserIsOrganizationAssigned) {
			uniquePermissions = uniquePermissions.filter((p: string) => ADMIN_INTERNAL_PERMISSIONS.indexOf(p) === -1)
		}

		return uniquePermissions
	},
)
