/* @flow */

import React from 'react'
import store from 'store'
import { Route, Redirect } from 'react-router'
import MainLayout from 'layouts/main-layout'
import TriviStorePage from 'modules/trivi-store/pages/trivi-store'

// Libraries
import { UserIsAuthenticated } from 'lib/auth'

const TriviStoreComponent = UserIsAuthenticated(MainLayout('triviStore')(TriviStorePage))

function getOrganizationId() {
	const state = store.getState()
	return state.user.currentOrganizationId || 'error'
}

function renderStore() {
	return <Redirect to={`/${getOrganizationId()}/trivi-store`} />
}

export default () => [
	<Route
		exact
		key="/:organizationId/trivi-store"
		path="/:organizationId/trivi-store"
		component={TriviStoreComponent}
	/>,
	<Route exact key="/trivi-store" path="/trivi-store" render={renderStore} />,
]
