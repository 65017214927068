// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._160COddibIwM48P_WgXv0 {\n  width: auto;\n  max-width: 1620px;\n  position: relative;\n\tmargin-left: auto;\n\tmargin-right: auto;\n}\n\n._3WQw-3w9yyRAQgREm-Tkoc {\n\tpadding-bottom: 50px;\n}\n\n._1GrNuo5YOKmKRg_Am1shIf {\n\tdisplay: block;\n\tdisplay: flex;\n\tjustify-content: left;\n\talign-items: flex-start;\n\talign-content: flex-start;\n\tmargin-bottom: 50px;\n}\n\n.zXapM5CO2Qhb4Ejra_iNF {\n\tdisplay: block;\n\tmargin-right: 20px;\n}\n\n._2LeGeEjUkwHiaV2gT8hMXC {\n\tdisplay: block;\n\tflex: 0 1 1200px;\n\tmargin-right: 30px;\n}\n\n._3NEFjEzg6crOJunN3IK9U2 {\n\tdisplay: block;\n\tflex: 0 0 380px;\n}\n\n._3Gove8bv5zVNY8GICDqs-a {\n\tdisplay: flex;\n\talign-items: flex-end;\n\tmargin-bottom: 15px;\n}\n\n._3ppsBUPNkJhu5sL015CXkq {\n\tmargin-right: 25px;\n}\n", "",{"version":3,"sources":["webpack://./modules/internal/pages/internal-dashboard.css"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,iBAAA;EACA,kBAAA;CACD,iBAAA;CACA,kBAAA;AACD;;AAEA;CACC,oBAAA;AACD;;AAEA;CACC,cAAA;CACA,aAAA;CACA,qBAAA;CACA,uBAAA;CACA,yBAAA;CACA,mBAAA;AACD;;AAEA;CACC,cAAA;CACA,kBAAA;AACD;;AAEA;CACC,cAAA;CACA,gBAAA;CACA,kBAAA;AACD;;AAEA;CACC,cAAA;CACA,eAAA;AACD;;AAEA;CACC,aAAA;CACA,qBAAA;CACA,mBAAA;AACD;;AAEA;CACC,kBAAA;AACD","sourcesContent":[".root {\n  width: auto;\n  max-width: 1620px;\n  position: relative;\n\tmargin-left: auto;\n\tmargin-right: auto;\n}\n\n.container {\n\tpadding-bottom: 50px;\n}\n\n.row {\n\tdisplay: block;\n\tdisplay: flex;\n\tjustify-content: left;\n\talign-items: flex-start;\n\talign-content: flex-start;\n\tmargin-bottom: 50px;\n}\n\n.full {\n\tdisplay: block;\n\tmargin-right: 20px;\n}\n\n.left {\n\tdisplay: block;\n\tflex: 0 1 1200px;\n\tmargin-right: 30px;\n}\n\n.right {\n\tdisplay: block;\n\tflex: 0 0 380px;\n}\n\n.loginAs {\n\tdisplay: flex;\n\talign-items: flex-end;\n\tmargin-bottom: 15px;\n}\n\n.MultiEmailInput {\n\tmargin-right: 25px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "_160COddibIwM48P_WgXv0",
	"container": "_3WQw-3w9yyRAQgREm-Tkoc",
	"row": "_1GrNuo5YOKmKRg_Am1shIf",
	"full": "zXapM5CO2Qhb4Ejra_iNF",
	"left": "_2LeGeEjUkwHiaV2gT8hMXC",
	"right": "_3NEFjEzg6crOJunN3IK9U2",
	"loginAs": "_3Gove8bv5zVNY8GICDqs-a",
	"MultiEmailInput": "_3ppsBUPNkJhu5sL015CXkq"
};
export default ___CSS_LOADER_EXPORT___;
