//@flow

import React from 'react'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import MenuItem from 'components/menu-item'
import SelectField from 'components/select-field'

const TYPES = [
	0, // ostatní
	1, // uživatel
	2, // hlavní učetní
	3, // zástupce hlavního učetního
	4, // mzdový učetní
	5, // zástupce mzdového učetního
]

export type Props = {
	value: ?number,
	fullWidth?: boolean,
	hiddenTypes?: Array<number>,
	disabledTypes?: Array<number>,
	hiddenTypes?: Array<number>,
	onChange: (type: number) => void,
	...WithTranslateProps,
}

function MembershipTypeSelector(props: Props) {
	function handleChange(event: SyntheticEvent<HTMLElement>, index: ?number, value: number) {
		props.onChange(value)
	}

	const types = props.hiddenTypes
		? TYPES.filter((t: number) => props.hiddenTypes && props.hiddenTypes.indexOf(t) < 0)
		: TYPES
	const isDisabled = (type: number) => {
		return props.disabledTypes && props.disabledTypes.indexOf(type) >= 0
	}

	return (
		<SelectField
			value={props.value}
			fullWidth={props.fullWidth}
			labelText={props.t('membershipType.label')}
			onChange={handleChange}
		>
			{types.map((type: number) => (
				<MenuItem
					disabled={props.disabledTypes && props.disabledTypes.indexOf(type) >= 0}
					key={type}
					value={type}
					primaryText={props.t(`membershipType.${type}.singular`)}
					tooltip={isDisabled(type) ? props.t(getTooltipMsgByType(type)) : undefined}
				/>
			))}
		</SelectField>
	)
}

function getTooltipMsgByType(type: number) {
	switch (type) {
		case 2:
			return 'membershipType.accountantNotAvailable'
		case 4:
			return 'membershipType.wagesAccountantNotAvailable'
		default:
			return ''
	}
}

export default withTranslate(MembershipTypeSelector)
