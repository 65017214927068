/* @flow */

import React, { Component } from 'react'
import colors from 'variables/colors.css'

type Props = {|
	value?: ?string,
	placeholder?: string,
	type?: string,
	name?: string,
	disabled?: boolean,
	onChange?: Event => void,
|}

type State = {|
	focused: boolean,
|}

class NativeInput extends Component<Props, State> {
	state = {
		focused: false,
	}

	handleFocus = () => {
		this.setState({
			focused: true,
		})
	}

	handleBlur = () => {
		this.setState({
			focused: false,
		})
	}

	render() {
		const styles = getStyles(this.props, this.state)

		return (
			<input
				onChange={this.props.onChange}
				value={this.props.value || ''}
				placeholder={this.props.placeholder}
				type={this.props.type}
				name={this.props.name}
				disabled={this.props.disabled}
				style={styles.field}
				onFocus={this.handleFocus}
				onBlur={this.handleBlur}
			/>
		)
	}
}

function getStyles(props: Props, state: State) {
	return {
		field: {
			opacity: props.disabled ? 0.6 : 1,
			display: 'block',
			width: '100%',
			borderRadius: 3,
			padding: '11px 18px',
			borderWidth: 1,
			borderStyle: 'solid',
			marginBottom: 25,
			outline: 'none',
			transition: 'all 200ms ease-in-out',
			fontSize: 14,
			lineHeight: '24px',
			WebkitAppearance: 'none',
			backgroundColor: `${colors.white} !important`,
			borderColor: state.focused ? colors.blue : colors.gray400,
			WebkitBoxShadow: `0 0 0 50px ${state.focused ? '#E6F7FA' : colors.white} inset`,
		},
	}
}

export default NativeInput
