/* @flow */

import React, { Component } from 'react'
import { withTranslate } from 'wrappers'
import { Validator } from 'utils'
import TextField, { type Props as TextFieldProps } from 'components/TextField'

export type Props = {|
	required?: boolean,
	...TextFieldProps,
|}

export type State = {|
	isValid: boolean,
	isMicrovalidationEnabled: boolean,
|}

class PhoneInput extends Component<Props, State> {
	state = {
		isValid: true,
		isMicrovalidationEnabled: false,
	}

	isValid = (value: ?string | ?number): boolean => {
		const isValueValid = Validator.phone(value)
		return this.props.required ? isValueValid : !value || isValueValid
	}

	onChange = (event: SyntheticInputEvent<HTMLInputElement>, value: ?string) => {
		const isValid = this.isValid(value)
		this.setState({
			isValid: isValid,
			isMicrovalidationEnabled: isValid ? true : this.state.isMicrovalidationEnabled,
		})
		this.props.onChange && this.props.onChange(event, value)
	}

	onBlur = (event: SyntheticInputEvent<HTMLInputElement>) => {
		this.setState({
			isMicrovalidationEnabled: true,
		})
		this.props.onBlur && this.props.onBlur(event)
	}

	render() {
		const { clientError, onChange, onBlur, t, ...props } = this.props

		return (
			<TextField
				clientError={
					clientError || (this.state.isMicrovalidationEnabled && !this.state.isValid)
						? this.props.value
							? this.props.t('clientError.notAPhone')
							: this.props.t('clientError.noFieldMicrovalidation', { field: this.props.t('fields.phone') })
						: null
				}
				onChange={this.onChange}
				onBlur={this.onBlur}
				type={'tel'}
				{...props}
			/>
		)
	}
}

export default withTranslate(PhoneInput)
