/* @flow */

import type { ContactMeAction, Dispatch } from 'types'
import { contactMe as contactMeApi } from '../../common/models/api-model'

export function toggleForm(isOpen?: boolean) {
	return function(dispatch: Dispatch<ContactMeAction>) {
		dispatch({
			type: 'TOGGLE_CONTACT_ME_FORM',
			isOpen: isOpen,
		})
	}
}

export function sendContact(contact: string) {
	return async (dispatch: Dispatch<ContactMeAction>) => {
		dispatch({ type: 'SEND_CONTACT_START' })
		try {
			await contactMeApi.post({ contactText: contact })
			return dispatch({ type: 'SEND_CONTACT_SUCCESS' })
		} catch (serverError) {
			return dispatch({ type: 'SEND_CONTACT_ERROR', serverError })
		}
	}
}
