/* @flow */

import { combineReducers } from 'redux'
import accountingDocumentList, {
	type State as AccountingDocumentListState,
	initialState as accountingDocumentListInitialState,
} from './accounting-document-list'
import accountingDocuments, {
	type State as AccountingDocumentsState,
	initialState as accountingDocumentsInitialState,
} from './accounting-documents'
import uploadScan, { type State as UploadScanState, initialState as uploadScanInitialState } from './upload-scan'
import vatRate, { type State as VatRateState, initialState as vatRateInitialState } from './vat-rate'
import sequences, { type State as SequencesState, initialState as sequencesInitialState } from './sequences'
import templates, { type State as TemplatesState, initialState as templatesInitialState } from './templates'
import imports, { type State as ImportsState, initialState as importsInitialState } from './imports'

export type State = {|
	accountingDocumentList: AccountingDocumentListState,
	accountingDocuments: AccountingDocumentsState,
	uploadScan: UploadScanState,
	vatRate: VatRateState,
	sequences: SequencesState,
	templates: TemplatesState,
	imports: ImportsState,
|}

export const initialState: State = {
	accountingDocumentList: accountingDocumentListInitialState,
	accountingDocuments: accountingDocumentsInitialState,
	uploadScan: uploadScanInitialState,
	vatRate: vatRateInitialState,
	sequences: sequencesInitialState,
	templates: templatesInitialState,
	imports: importsInitialState,
}
export default combineReducers({
	accountingDocumentList,
	accountingDocuments,
	uploadScan,
	vatRate,
	sequences,
	templates,
	imports,
})
