/* @flow */
/** @jsx jsx */

// $FlowFixMe - there is useState available
import React, { useState, memo } from 'react'
import { jsx } from '@emotion/core'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import InboundEmails from 'modules/accounting-document/containers/accounting-document-list/inbound-emails'
import MobileAppInvitation from 'modules/navigation/components/mobile-app-invitation'
import Button from 'components/button'
import { colors } from 'variables'

function HowToUpload(props: WithTranslateProps) {
	const [inboundEmailsOpen, setInboundEmailsOpen]: [boolean, (boolean) => void] = useState(false)
	const [mobileDialogOpen, setMobileDialogOpen]: [boolean, (boolean) => void] = useState(false)

	function onCloseInboundEmailsPopup() {
		setInboundEmailsOpen(false)
	}

	function onOpenInboundEmailsClick() {
		setInboundEmailsOpen(true)
	}

	function onCloseMobileDialog() {
		setMobileDialogOpen(false)
	}

	function onOpenMobileDialogClick() {
		setMobileDialogOpen(true)
	}

	return (
		<React.Fragment>
			<div css={styles.root}>
				<div css={styles.headline}>{props.t('uploadScan.howTo.headline')}</div>
				<div css={styles.box}>
					<div css={styles.option}>
						<h5>{props.t('uploadScan.howTo.portal.headline')}</h5>
						<p>{props.t('uploadScan.howTo.portal.paragraph')}</p>
						<br />
						<p>{props.t('uploadScan.howTo.portal.secondParagraph')}</p>
					</div>
					<div css={styles.option}>
						<h5>{props.t('uploadScan.howTo.email.headline')}</h5>
						<p>{props.t('uploadScan.howTo.email.paragraph')}</p>
						<div css={styles.button}>
							<Button primary onClick={onOpenInboundEmailsClick} labelText={props.t('uploadScan.howTo.email.button')} />
						</div>
					</div>
					<div css={styles.option}>
						<h5>{props.t('uploadScan.howTo.mobile.headline')}</h5>
						<p>{props.t('uploadScan.howTo.mobile.paragraph')}</p>
						<div css={styles.button}>
							<Button primary onClick={onOpenMobileDialogClick} labelText={props.t('uploadScan.howTo.mobile.button')} />
						</div>
					</div>
				</div>
			</div>
			<InboundEmails open={inboundEmailsOpen} onClose={onCloseInboundEmailsPopup} />
			<MobileAppInvitation open={mobileDialogOpen} onClose={onCloseMobileDialog} />
		</React.Fragment>
	)
}

const styles = {
	root: {
		paddingLeft: 15,
		width: 400,
		flex: '0 0 400px',
	},
	headline: {
		fontSize: 25,
		lineHeight: '30px',
		marginBottom: 17,
	},
	box: {
		backgroundColor: colors.white,
		padding: '15px 30px 15px 20px',
	},
	option: {
		color: colors.black,
		marginBottom: 18,
		h5: {
			fontSize: 16,
			fontWeight: 'bold',
			lineHeight: '20px',
			margin: '0 0 10px 0',
			padding: 0,
		},
		p: {
			fontSize: 14,
			lineHeight: '19px',
			margin: 0,
			padding: 0,
		},
	},
	button: {
		marginTop: 15,
	},
	selectButton: {
		marginTop: 15,
		marginBottom: 13,
	},
}

export default withTranslate(memo(HowToUpload))
