//@flow

export function fetchAsBlob(url: any): Promise<Blob> {
	return fetch(url).then((response: Response) => response.blob())
}

export function convertBlobToBase64(blob: Blob): Promise<string> {
	return new Promise((resolve: any, reject: any) => {
		const reader = new FileReader()
		reader.onerror = reject
		reader.onload = () => {
			resolve(reader.result)
		}
		reader.readAsDataURL(blob)
	})
}

export async function fetchBlobAsBase64(blobUrl: string): Promise<string> {
	const blob: Blob = await fetchAsBlob(blobUrl)
	const base64 = await convertBlobToBase64(blob)
	const mimeType = blob.type
	// TODO-CASHBOT nevím jestli takovéhle odstranění začátku stringu je konzistentní
	return base64.replace(`data:${mimeType};base64,`, '')
}
