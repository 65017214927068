import React from 'react'
import pure from 'recompose/pure'
import SvgIcon from 'components/SvgIcon'

let Cashdesk = props => (
	<SvgIcon {...props}>
		<defs>
			<polygon
				id="path-1"
				points="7.8137072 19.5535994 15.5388899 19.5535994 15.5388899 0 7.8137072 0 0.0885244773 0 0.0885244773 19.5535994 7.8137072 19.5535994"
			/>
		</defs>
		<g id="Page-1" stroke="none" strokeWidth="1" fillRule="evenodd">
			<g id="Apple-TV">
				<g id="Group">
					<g id="cashregister">
						<g id="Group-3" transform="translate(1.000000, 2.000000)">
							<mask id="mask-2">
								<use xlinkHref="#path-1" />
							</mask>
							<g id="Clip-2" />
							<path
								d="M3.49618357,1.77759995 C2.596718,1.77759995 1.86612442,2.50908232 1.86612442,3.40854789 L1.86612442,16.1459403 C1.86612442,17.0445171 2.596718,17.7759995 3.49618357,17.7759995 L12.1308753,17.7759995 C13.0303409,17.7759995 13.7618233,17.0445171 13.7618233,16.1459403 L13.7618233,3.40854789 C13.7618233,2.50908232 13.0303409,1.77759995 12.1308753,1.77759995 L3.49618357,1.77759995 Z M12.1308753,19.5535994 L3.49618357,19.5535994 C1.61726043,19.5535994 0.0885244773,18.0248634 0.0885244773,16.1459403 L0.0885244773,3.40854789 C0.0885244773,1.52873595 1.61726043,0 3.49618357,0 L12.1308753,0 C14.0106872,0 15.5394232,1.52873595 15.5394232,3.40854789 L15.5394232,16.1459403 C15.5394232,18.0248634 14.0106872,19.5535994 12.1308753,19.5535994 L12.1308753,19.5535994 Z"
								id="Fill-1"
								mask="url(#mask-2)"
							/>
						</g>
						<polygon
							id="Fill-4"
							points="4.2743391 11.4026149 6.32568944 11.4026149 6.32568944 9.35126457 4.2743391 9.35126457"
						/>
						<polygon
							id="Fill-6"
							points="7.69266379 11.4026149 9.74401413 11.4026149 9.74401413 9.35126457 7.69266379 9.35126457"
						/>
						<polygon
							id="Fill-8"
							points="4.2743391 14.7356148 6.32568944 14.7356148 6.32568944 12.6842645 4.2743391 12.6842645"
						/>
						<polygon
							id="Fill-10"
							points="7.69266379 14.7356148 9.74401413 14.7356148 9.74401413 12.6842645 7.69266379 12.6842645"
						/>
						<polygon
							id="Fill-12"
							points="4.2743391 17.8970763 6.32568944 17.8970763 6.32568944 15.845726 4.2743391 15.845726"
						/>
						<polygon
							id="Fill-14"
							points="7.69266379 17.8970763 9.74401413 17.8970763 9.74401413 15.845726 7.69266379 15.845726"
						/>
						<polygon
							id="Fill-16"
							points="11.3936269 11.4026149 13.4449772 11.4026149 13.4449772 9.35126457 11.3936269 9.35126457"
						/>
						<polygon
							id="Fill-18"
							points="4.2752279 7.93451742 13.4449772 7.93451742 13.4449772 5.0992455 4.2752279 5.0992455"
						/>
						<polygon
							id="Fill-20"
							points="11.3936269 17.8970763 13.4449772 17.8970763 13.4449772 12.5127261 11.3936269 12.5127261"
						/>
						<path
							d="M19.13703,14.5117261 C17.8100516,14.5117261 16.9985773,15.0156757 16.9985773,15.2903149 C16.9985773,15.5649541 17.8100516,16.0680148 19.13703,16.0680148 C20.4640084,16.0680148 21.2754827,15.5649541 21.2754827,15.2903149 C21.2754827,15.0156757 20.4640084,14.5117261 19.13703,14.5117261 M19.13703,16.9568148 C17.4109805,16.9568148 16.1097773,16.240442 16.1097773,15.2903149 C16.1097773,14.3401877 17.4109805,13.6229261 19.13703,13.6229261 C20.8630795,13.6229261 22.1642827,14.3401877 22.1642827,15.2903149 C22.1642827,16.240442 20.8630795,16.9568148 19.13703,16.9568148"
							id="Fill-21"
						/>
						<path
							d="M19.6048943,19.1779259 C17.6122048,19.1779259 16.109244,18.3655628 16.109244,17.289226 C16.109244,16.5568548 16.7989528,15.9302508 17.9543928,15.6120605 L18.1908135,16.4688636 C17.4077808,16.6839532 16.998044,17.044806 16.998044,17.289226 C16.998044,17.6962964 18.0139424,18.289126 19.6048943,18.289126 C21.1967351,18.289126 22.2117446,17.6962964 22.2117446,17.289226 C22.2117446,17.0928012 21.9379942,16.7932756 21.3273887,16.5675204 L21.6358022,15.7338261 C22.5663758,16.0777916 23.1005446,16.644846 23.1005446,17.289226 C23.1005446,18.3655628 21.5975838,19.1779259 19.6048943,19.1779259"
							id="Fill-22"
						/>
						<path
							d="M19.6048943,20.9555259 C17.6122048,20.9555259 16.109244,20.1431627 16.109244,19.066826 C16.109244,18.7992972 16.2052344,18.400226 16.6603,18.018042 L17.2317984,18.697974 C17.1251424,18.7877428 16.998044,18.924618 16.998044,19.066826 C16.998044,19.4738963 18.0139424,20.0667259 19.6048943,20.0667259 C21.1967351,20.0667259 22.2117446,19.4738963 22.2117446,19.066826 C22.2117446,18.8828444 22.0037654,18.7104172 21.8802222,18.6233148 L22.3903934,17.8953876 C22.9770014,18.306902 23.1005446,18.7593012 23.1005446,19.066826 C23.1005446,20.1431627 21.5975838,20.9555259 19.6048943,20.9555259"
							id="Fill-23"
						/>
					</g>
				</g>
			</g>
		</g>
	</SvgIcon>
)
Cashdesk = pure(Cashdesk)

export default Cashdesk
