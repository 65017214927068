/* @flow */
/** @jsx jsx */

// $FlowFixMe - there is useCallback
import React, { useCallback } from 'react'
import { jsx } from '@emotion/core'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import { Button } from 'components'

type Props = {|
	...WithTranslateProps,
	isSomeTemplateCreating: boolean,
	onCreateTemplate: () => void,
|}

function CreateTemplateButton(props: Props) {
	const { isSomeTemplateCreating, onCreateTemplate } = props

	const onCreateTemplateClick = useCallback(() => {
		if (isSomeTemplateCreating) return
		onCreateTemplate && onCreateTemplate()
	}, [isSomeTemplateCreating, onCreateTemplate])

	return (
		<div className={style.addContainer}>
			<Button labelText={props.t('templates.createNewTemplate')} onClick={onCreateTemplateClick} />
		</div>
	)
}

export default withTranslate(CreateTemplateButton)

const style = {
	addContainer: {
		minHeight: '185px',
		position: 'relative',
		padding: 0,
	},
}
