/* @flow */

import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { loadMyIconAvatar, logout, updateMyLanguage } from 'modules/user/actions'
import { getAvatar, getMyProfile } from 'modules/user/selectors'
import { UserIcon } from 'components'
import { setAppLanguage, setAppLocale } from 'locales'
import type {
	Dispatch,
	State,
	UserAction,
	UserProfile,
	PortalLanguage,
	ISignalMessage,
	NotificationsAction,
} from 'types'
import { getAllNotification } from 'modules/notifications/selectors'

type StateProps = {|
	name: ?string,
	avatar: ?string,
	locale: ?string,
	language: ?PortalLanguage,
	notifications: Array<ISignalMessage>,
|}

type DispatchProps = {|
	loadAvatar: () => void,
	onLogout: () => void,
	onLanguageChange: (language: ?PortalLanguage, updateLocale: boolean) => void,
|}

const mapStateToProps = (state: State): StateProps => {
	const myUserProfile: ?UserProfile = getMyProfile(state)
	const firstName: string = (myUserProfile && myUserProfile.firstname) || ''
	const lastName: string = (myUserProfile && myUserProfile.lastname) || ''
	const fullName: string = firstName + ' ' + lastName
	return {
		name: fullName,
		notifications: getAllNotification(state),
		avatar: getAvatar(state, '40'),
		locale: (myUserProfile && myUserProfile.locale) || null,
		language: (myUserProfile && myUserProfile.language) || null,
	}
}

const mapDispatchToProps = (dispatch: Dispatch<UserAction | NotificationsAction>): DispatchProps => {
	return {
		onLogout: () => {
			dispatch(logout())
			dispatch(push('/login'))
		},
		onLanguageChange: (language: ?PortalLanguage, updateLocale: boolean) => {
			setAppLanguage(language)

			if (updateLocale) {
				setAppLocale()
			}

			dispatch(updateMyLanguage(language))
		},
		loadAvatar: () => {
			dispatch(loadMyIconAvatar())
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(UserIcon)
