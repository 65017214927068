//@flow
import { connect } from 'react-redux'
import type { State, Dispatch, DashboardAction } from 'types'
import { getInvitationBannerOpenState } from '../../selectors'
import InvitationBanner from '../../components/invitation/invitation-banner'
import { switchInvitationBanner, switchInvitationDialog } from '../../actions'

type StateToProps = {|
	isOpened: boolean,
|}

const mapStateToProps = (state: State): StateToProps => {
	return {
		isOpened: getInvitationBannerOpenState(state),
	}
}

type DispatchToProps = {|
	switchInvitationBanner: (value: boolean) => void,
	switchInvitationDialog: (value: boolean) => void,
|}

const mapDispatchToProps = (dispatch: Dispatch<DashboardAction>): DispatchToProps => {
	return {
		switchInvitationBanner: (value: boolean) => {
			dispatch(switchInvitationBanner(value))
		},
		switchInvitationDialog: (value: boolean) => {
			dispatch(switchInvitationDialog(value))
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(InvitationBanner)
