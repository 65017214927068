/* @flow */
/** @jsx jsx */

import { Component } from 'react'
import { jsx } from '@emotion/core'
import { withTranslate, type WithTranslateProps, validate, withFeatures, type WithFeaturesProps } from 'wrappers'
import type { AccountingDocumentNote, UserProfile, FormValidationProps } from 'types'
import { emptyAccountingDocumentNote } from 'types/empty'
import { getUserDisplayName } from 'modules/user/user/user'
import HelpIcon from 'components/svg-icons/action/help-outline'
import TextField from 'components/TextField'
import Dialog from 'components/dialog'
import Button from 'components/button'
import { colors } from 'variables'

type Props = {|
	...WithFeaturesProps,
	...WithTranslateProps,
	...FormValidationProps,
	me: ?UserProfile,
	accountingDocumentId: string,
	onSend: (note: ?AccountingDocumentNote) => Promise<*>,
|}

export type State = {|
	isDialogOpen: boolean,
	note: ?string,
|}

class NeedHelp extends Component<Props, State> {
	state = {
		isDialogOpen: false,
		note: null,
	}

	handleTextFieldChange = (event: SyntheticInputEvent<HTMLInputElement>, note: ?string) => {
		this.setState({ note })
	}

	openDialog = () => {
		this.props.resetValidation()
		this.setState({ isDialogOpen: true })
	}

	closeDialog = () => {
		this.setState({ isDialogOpen: false, note: null })
	}

	onSend = () => {
		const { me } = this.props
		this.props.onSend(
			this.state.note
				? {
						...emptyAccountingDocumentNote(),
						authorUniqueId: me ? me.id : '',
						authorName: me ? getUserDisplayName(me.firstname, me.lastname, me.email) : '',
						text: this.state.note || undefined,
				  }
				: null,
		)
		this.closeDialog()
	}

	getClientValidationErrors() {
		const errors = {}
		if (!this.state.note) errors.note = this.props.t('application.validation.mandatory')

		return errors
	}

	renderDialog() {
		if (!this.state.isDialogOpen) return null

		const { t } = this.props
		this.props.validateForm(this.getClientValidationErrors())

		const actions = [
			<Button
				autoTestId="need-help-dialog-confirm"
				key="confirm"
				labelText={t('dialogs.sendButtonLabel')}
				onClick={this.props.validationSubmit(this.onSend)}
				primary
				wide
			/>,
			<Button
				key="cancel"
				labelText={t('accountingDocument.states.backToEdit')}
				autoTestId="need-help-dialog-cancel"
				onClick={this.closeDialog}
				secondary
				wide
			/>,
		]

		return (
			<Dialog
				title={t('accountingDocument.states.setNeedHelpHeadline')}
				autoTestId="need-help-dialog"
				actions={actions}
				open
			>
				<div css={styles.form}>
					<TextField
						value={this.props.validationValue('note', this.state.note)}
						clientError={this.props.validationMessage('note')}
						fullWidth
						onChange={this.handleTextFieldChange}
						multiLine
						rows={4}
						name="need-help"
						autoTestId="need-help-input"
						autoFocus
					/>
				</div>
			</Dialog>
		)
	}

	render() {
		return (
			<div css={styles.root}>
				<div onClick={this.openDialog} css={styles.button}>
					<span css={styles.label}>
						{this.props.t('accountingDocument.states.needhelp')}
						<HelpIcon size={14} disabled style={styles.icon} />
					</span>
				</div>
				{this.renderDialog()}
			</div>
		)
	}
}

const styles = {
	root: {
		marginLeft: 15,
		height: 34,
	},
	button: {
		display: 'block',
		transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1)',
		borderRadius: 17,
		borderStyle: 'solid',
		borderWidth: 1,
		borderColor: colors.grey400,
		backgroundColor: colors.white,
		overflow: 'hidden',
		height: 34,
		lineHeight: '32px',
		padding: 0,
		textAlign: 'center',
		userSelect: 'none',
		cursor: 'pointer',
		'&:hover': {
			borderColor: colors.grey400,
			backgroundColor: colors.white,
		},
	},
	label: {
		position: 'relative',
		verticalAlign: 'middle',
		whiteSpace: 'nowrap',
		opacity: 1,
		fontSize: 12,
		letterSpacing: 0,
		textTransform: 'uppercase',
		margin: 0,
		userSelect: 'none',
		color: colors.black,
		paddingLeft: 20,
		paddingRight: 20,
	},
	icon: {
		verticalAlign: 'text-top',
		marginLeft: 5,
	},
	form: {
		margin: '30px auto 20px auto',
		maxWidth: 480,
	},
}

export default withFeatures(withTranslate(validate()(NeedHelp)))
