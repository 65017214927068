/* @flow */
/** @jsx jsx */

import { PureComponent, type Node } from 'react'
import { jsx } from '@emotion/core'
import ReactDOM from 'react-dom'
import moment from 'moment'
import memoize from 'memoize-one'
import { passAutoTestId, autoTestId, type AutoTestProps } from 'utils/tests/autotest'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import { isEqualDate, defaultUtils, dateTimeFormat } from './date-utils'
import DatePickerDialog from './date-picker-dialog'
import ActionDateRange from 'components/svg-icons/action/date-range'
import IconButton from 'components/icon-button'
import TextField from 'components/TextField'
import { colors } from 'variables'

const NOW = new Date()

export type Props = {|
	clientError?: Node,
	serverError?: string,
	required?: boolean,
	/**
	 * The hint content to display.
	 */
	hintText?: string | Date,
	/**
	 * Disables the DatePicker.
	 */
	disabled?: boolean,
	/**
	 * The ending of a range of valid dates. The range includes the endDate.
	 * The default value is current date + 100 years.
	 */
	maxDate?: ?Date,
	/**
	 * The beginning of a range of valid dates. The range includes the startDate.
	 * The default value is current date - 100 years.
	 */
	minDate?: ?Date,
	/**
	 * Callback function that is fired when the date value changes.
	 *
	 * @param {null} null Since there is no particular event associated with the change,
	 * the first argument will always be null.
	 * @param {object} date The new date.
	 */
	onChange?: (?SyntheticInputEvent<HTMLInputElement>, ?Date) => void,
	/**
	 * Callback function that is fired when the Date Picker's `TextField` gains focus.
	 */
	onFocus?: (SyntheticInputEvent<HTMLInputElement>) => void,
	/**
	 * Callback function that is fired when a click event occurs on the Date Picker's `TextField`.
	 *
	 * @param {object} event Click event targeting the `TextField`.
	 */
	onClick?: (SyntheticEvent<HTMLElement>) => void,
	/**
	 * Sets the date for the Date Picker programmatically.
	 */
	value: ?Date,
	/**
	 * Auto-focus date picker
	 */
	defaultDate?: Date,
	inline?: boolean,
	autoFocus?: boolean,
	labelText?: string,
	fullWidth?: boolean,
	autoWidth?: boolean,
	compact?: boolean,
	onKeyEscape?: (SyntheticKeyboardEvent<HTMLInputElement>) => void,
	onKeyEnter?: (SyntheticKeyboardEvent<HTMLInputElement>) => void,
	...WithTranslateProps,
	...AutoTestProps,
|}

export type State = {|
	date: ?Date,
	parsedDate: ?Date,
	dialogDate: ?Date,
	dateText: ?string,
	anchorEl: any,
	isDialogOpened: boolean,
	clientError: Node,
|}

class DatePicker extends PureComponent<Props, State> {
	button: any = null

	state = {
		date: null,
		parsedDate: null,
		dialogDate: null,
		dateText: null,
		anchorEl: null,
		isDialogOpened: false,
		clientError: null,
	}

	UNSAFE_componentWillMount() {
		const initialDate = this.props.value
		this.setState({
			date: initialDate,
			parsedDate: initialDate,
			dateText: initialDate ? this.formatDate(initialDate) : undefined,
		})
	}

	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		const newDate = nextProps.value
		if (!isEqualDate(this.state.date, newDate)) {
			this.setState({
				date: newDate,
				parsedDate: newDate,
				dateText: newDate ? this.formatDate(newDate) : undefined,
				clientError: newDate
					? this.isADate(newDate)
						? null
						: this.props.t('clientError.notADate')
					: this.props.required
					? this.props.t('clientError.noFieldMicrovalidation', { field: this.props.t('fields.date') })
					: null,
			})
		}
	}

	getParsedDate = (value: Object | string): Date => {
		let val = moment(value, 'l')
		if (!val.isValid()) val = moment(value, 'DDMM')

		return val.toDate() //parse(value, 'MM/DD/YYYY')
	}

	handleChangeDatePicker = (event: Event, date: Object) => {
		this.setState({ date: date, dateText: this.formatDate(date) })
	}

	handleDateInputChange = (event: ?SyntheticInputEvent<HTMLInputElement>, value: ?string) => {
		const { t } = this.props
		const dateText = (value && value.replace(/[^0-9. ]+/, '')) || value

		this.setState({ dateText })

		if (value) {
			if (value && this.isADate(this.getParsedDate(value))) {
				this.setState({
					clientError: null,
				})
			} else {
				this.setState({
					clientError: t('clientError.notADate'),
				})
			}
		} else {
			this.setState({
				clientError: this.props.required ? t('clientError.noFieldMicrovalidation', { field: t('fields.date') }) : null,
			})
		}
	}

	handleDateInputConfirm = (
		event: ?SyntheticInputEvent<HTMLInputElement> | ?SyntheticKeyboardEvent<HTMLInputElement>,
	) => {
		const { t } = this.props
		const value = event && event.currentTarget instanceof HTMLInputElement ? event.currentTarget.value : null
		if (!value || value.length < 1) {
			this.setState({
				date: this.props.defaultDate ? this.props.defaultDate : undefined,
				dateText: this.props.defaultDate ? this.formatDate(this.props.defaultDate) : '',
				clientError: this.props.defaultDate ? null : t('clientError.invalidValue'),
			})
			if (this.props.defaultDate) {
				this.props.value && this.props.onChange && this.props.onChange(null, this.props.defaultDate)
			} else {
				this.props.value && this.props.onChange && this.props.onChange()
			}

			return
		}

		let parsedDate = this.getParsedDate(value ?? this.props.defaultDate)

		if (isEqualDate(this.state.parsedDate, parsedDate)) return

		if (this.props.minDate && parsedDate && parsedDate < this.props.minDate) {
			if (this.props.value) parsedDate = this.props.value
			this.setState({
				clientError: t('clientError.invalidValue'),
			})
		} else if (this.props.maxDate && parsedDate && parsedDate > this.props.maxDate) {
			if (this.props.value) parsedDate = this.props.value
			this.setState({
				clientError: t('clientError.invalidValue'),
			})
		} else {
			this.setState({
				clientError: null,
			})
		}

		if (this.isADate(parsedDate)) {
			this.setState({
				date: parsedDate,
				dateText: this.formatDate(parsedDate),
				clientError: null,
			})
		} else if (this.state.date) {
			this.setState({
				dateText: this.formatDate(this.state.date),
				clientError: this.isADate(this.state.date) ? null : t('clientError.notADate'),
			})
		} else {
			this.setState({
				clientError: t('clientError.notADate'),
			})
		}

		this.props.onChange &&
			this.isADate(parsedDate) &&
			(!this.props.value || new Date(parsedDate).getTime() !== new Date(this.props.value || 0).getTime()) &&
			this.props.onChange(null, parsedDate)
	}

	isADate = (maybeDate: ?any) => {
		if (maybeDate && Object.prototype.toString.call(maybeDate) === '[object Date]') {
			if (isNaN(maybeDate.getTime())) {
				return false
			} else {
				return true
			}
		} else {
			return false
		}
	}

	/**
	 * Open the date-picker dialog programmatically from a parent.
	 */
	openDialog() {
		this.setState(
			{
				dialogDate: this.state.date || NOW,
				isDialogOpened: true,
			},
			this.refs.dialogWindow.show,
		)
	}

	/**
	 * Alias for `openDialog()` for an api consistent with TextField.
	 */
	focus() {
		this.openDialog()
	}

	handleAccept = (date: any) => {
		this.props.onChange && this.props.onChange(null, date)
	}

	handleDismiss = () => {
		this.setState({
			isDialogOpened: false,
		})
	}

	handleFocus = (event: SyntheticInputEvent<HTMLInputElement>) => {
		event.target.blur()
		this.props.onFocus && this.props.onFocus(event)
	}

	handleClick = (event: SyntheticEvent<HTMLElement>) => {
		this.props.onClick && this.props.onClick(event)

		this.setState({
			anchorEl: event.currentTarget,
		})

		if (!this.props.disabled) {
			setTimeout(() => {
				this.openDialog()
			}, 0)
		}
	}

	formatDate = (date: any) => {
		return moment(date).format('l')
	}

	bindButton = (element: any) => {
		this.button = ReactDOM.findDOMNode(element)
	}

	onKeyEnter = (event: SyntheticKeyboardEvent<HTMLInputElement>) => {
		this.handleDateInputConfirm(event)
		setTimeout(() => {
			this.props.onKeyEnter && this.props.onKeyEnter(event)
			this.button.focus()
		}, 0)
	}

	getStyles = memoize((inline: ?boolean, autoWidth: ?boolean, compact: ?boolean, isDialogOpened: boolean) => {
		let iconButtonPosition
		if (!inline) {
			if (autoWidth && compact)
				iconButtonPosition = {
					bottom: 4,
					right: 3,
				}
			else if (compact)
				iconButtonPosition = {
					bottom: 4,
					right: 10,
				}
			else
				iconButtonPosition = {
					bottom: 12,
					right: 10,
				}
		}

		return {
			root: {
				display: inline ? 'inline-block' : autoWidth ? 'inline-block' : 'block',
				minWidth: !inline && !autoWidth ? 93 : 0,
			},
			date: {
				color: 'inherit',
				fontSize: inline ? 12 : 14,
				lineHeight: '24px',
				height: inline ? 24 : 'auto',
				whiteSpace: 'nowrap',
				position: 'relative',
				display: 'flex',
			},
			textField: {
				fontSize: 'inherit',
				color: 'inherit',
				lineHeight: 'inherit',
				paddingRight: !inline && autoWidth ? 30 : 0,
			},
			iconButton: {
				fontSize: 'inherit',
				padding: '2px 0',
				borderRadius: '50%',
				borderStyle: 'solid',
				borderWidth: 1,
				borderColor: isDialogOpened ? colors.gray400 : 'transparent',
				backgroundColor: isDialogOpened ? '#F2F2F2' : 'transparent',
				boxShadow: isDialogOpened ? 'inset 2px 4px 0 0 rgba(0, 0, 0, 0.08)' : 'none',
				marginTop: -1,
				position: inline ? 'relative' : 'absolute',
				...iconButtonPosition,
			},
			iconFocusStyle: {
				color: isDialogOpened ? colors.gray400 : undefined,
			},
		}
	})

	render() {
		const { t } = this.props
		const styles = this.getStyles(
			this.props.inline,
			this.props.autoWidth,
			this.props.compact,
			this.state.isDialogOpened,
		)

		const isInvalid = this.props.clientError || this.state.clientError || this.props.serverError

		const hintText =
			typeof this.props.hintText !== 'string' ? this.formatDate(this.props.hintText) : this.props.hintText

		return (
			<div css={styles.root} ref="datePicker" {...autoTestId(this.props.autoTestId)}>
				<div css={styles.date}>
					<TextField
						ref="input"
						style={styles.textField}
						value={this.state.dateText || ''}
						hintText={hintText}
						clientError={this.props.clientError || this.state.clientError}
						onChange={this.handleDateInputChange}
						onBlur={this.handleDateInputConfirm}
						onKeyEnter={this.onKeyEnter}
						onKeyEscape={this.props.onKeyEscape}
						autoFocus={this.props.autoFocus}
						labelText={this.props.labelText}
						fullWidth={this.props.fullWidth}
						autoWidth={this.props.autoWidth}
						compact={this.props.compact}
						inline={this.props.inline}
						disabled={this.props.disabled}
						maxLength={12}
						editCursor
						{...passAutoTestId(this.props.autoTestId, 'text-field')}
					/>
					{!this.props.disabled ? (
						<IconButton
							ref={this.bindButton}
							size={24}
							style={styles.iconButton}
							disabled={this.props.disabled}
							onFocus={this.handleFocus}
							onClick={this.handleClick}
							color={isInvalid ? colors.red : colors.black}
							hoverColor={isInvalid ? colors.red400 : colors.blue}
							tooltip={this.props.serverError}
							tooltipVisible={!!this.props.serverError && !this.state.isDialogOpened}
							{...passAutoTestId(this.props.autoTestId, 'icon')}
						>
							<ActionDateRange style={styles.iconFocusStyle} size={16} />
						</IconButton>
					) : null}
				</div>
				<DatePickerDialog
					DateTimeFormat={dateTimeFormat}
					initialDate={this.state.dialogDate}
					minDate={this.props.minDate || defaultUtils.addYears(NOW, -100)}
					maxDate={this.props.maxDate || defaultUtils.addYears(NOW, 100)}
					onAccept={this.handleAccept}
					onDismiss={this.handleDismiss}
					ref="dialogWindow"
					utils={defaultUtils}
					anchorEl={this.button}
					today={this.formatDate(NOW)}
					todayLabel={t('application.today')}
					keyRef={this.refs.datePicker}
				/>
			</div>
		)
	}
}

export default withTranslate(DatePicker, { withRef: true })
