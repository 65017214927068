/* @flow */
/** @jsx jsx */

import { PureComponent, type Node } from 'react'
import { jsx } from '@emotion/core'
import memoize from 'memoize-one'
import { autoTestId, type AutoTestProps } from 'utils/tests/autotest'
import CheckIcon from 'components/svg-icons/trivi/check'
import Tooltip from 'components/tooltip'
import { colors } from 'variables'

type Props = {|
	...AutoTestProps,
	checked?: boolean,
	disabled?: boolean,
	icon?: Node,
	primaryText?: ?Node | string,
	tall?: boolean,
	value?: any,
	rightText?: string,
	onClick?: (SyntheticMouseEvent<HTMLElement>) => void,
	onItemClick?: (SyntheticMouseEvent<HTMLElement>) => void,
	tooltip?: string,
	fontWeight?: string,
|}

class MenuItem extends PureComponent<Props> {
	getStyles = memoize(
		(tall: ?boolean, disabled: ?boolean, icon: ?Node, checked: ?boolean, rightText: ?string, fontWeight: ?string) => {
			const indent = 42
			const sidePadding = 16
			const minHeight = tall ? 36 : 30
			const iconTop = Math.floor((minHeight - 24) / 2)

			return {
				root: {
					border: 0,
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					boxSizing: 'border-box',
					cursor: disabled ? 'default' : 'pointer',
					outline: 'none',
					zIndex: 1,
					color: checked && !disabled ? colors.blue : disabled ? colors.disabled : 'inherit',
					fontSize: 14,
					fontWeight: fontWeight ? fontWeight : 'normal',
					position: 'relative',
					opacity: disabled ? 0.8 : 1,
					pointerEvents: disabled ? 'none' : 'all',
					paddingLeft: icon ? indent : sidePadding,
					paddingRight: checked ? indent : sidePadding,
					minHeight: minHeight,
					lineHeight: `${minHeight}px`,
					whiteSpace: 'nowrap',
					overflow: 'hidden',
					'&:hover': {
						backgroundColor: colors.blueFaded10,
						color: colors.blue,
						svg: {
							fill: colors.blue,
						},
					},
				},
				icon: {
					height: 24,
					width: 24,
					position: 'absolute',
					transition: 'none',
					lineHeight: '24px',
					textAlign: 'center',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					left: 10,
					margin: 0,
					top: iconTop,
				},
				rightIcon: {
					height: 24,
					width: 24,
					position: 'absolute',
					transition: 'none',
					lineHeight: '24px',
					textAlign: 'center',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					right: 10,
					margin: 0,
					top: iconTop,
				},
				primaryText: {
					display: 'block',
					verticalAlign: 'middle',
					width: 'auto',
					overflow: 'hidden',
					whiteSpace: 'nowrap',
					textOverflow: 'ellipsis',
					flex: '1 1 100%',
				},
				rightText: {
					display: 'block',
					verticalAlign: 'middle',
					fontSize: 10,
					color: colors.blackFaded60,
					overflow: 'hidden',
					textAlign: 'right',
					textOverflow: 'ellipsis',
					whiteSpace: 'nowrap',
					paddingLeft: 10,
					flex: '0 0 auto',
				},
			}
		},
	)

	onClick = (event: SyntheticMouseEvent<HTMLElement>) => {
		this.props.onClick && this.props.onClick(event)
		this.props.onItemClick && this.props.onItemClick(event)
	}

	render() {
		const styles = this.getStyles(
			this.props.tall,
			this.props.disabled,
			this.props.icon,
			this.props.checked,
			this.props.rightText,
			this.props.fontWeight,
		)

		return (
			<Tooltip label={this.props.tooltip} inline={false}>
				<div
					onClick={!this.props.disabled ? this.onClick : undefined}
					css={styles.root}
					{...autoTestId(this.props.autoTestId)}
				>
					{this.props.icon && <div style={styles.icon}>{this.props.icon}</div>}
					{this.props.checked && (
						<div css={styles.rightIcon}>
							<CheckIcon color={this.props.disabled ? colors.blackFaded80 : colors.blue} />
						</div>
					)}
					{this.props.primaryText && <div css={styles.primaryText}>{this.props.primaryText}</div>}
					{this.props.rightText && <div css={styles.rightText}>{this.props.rightText}</div>}
				</div>
			</Tooltip>
		)
	}
}

export default MenuItem
