/* @flow */

import React, { Component, type Node } from 'react'
import memoize from 'memoize-one'
import { type AutoTestProps } from 'utils/tests/autotest'
import type { InternalUser, OrganizationInternalUser, UserProfile } from 'types'
import { getUserFullName } from 'modules/user/domain/user'
import SelectNext, { type ValueType } from 'components/select-next'

type User = UserProfile | InternalUser | OrganizationInternalUser

type UserProps = {|
	users?: ?Array<User>,
|}

export type FieldProps = {|
	onChange: (?string) => void,
	labelText?: string,
	placeholder?: string,
	value?: ?string,
	clientError?: Node,
	disabled?: boolean,
	compact?: boolean,
	isClearable?: boolean,
	displayEmail?: boolean,
|}

export type Props = {| ...AutoTestProps, ...UserProps, ...FieldProps |}

class UserPicker extends Component<Props> {
	onSelectFieldChange = (value: ValueType) => {
		const newValue = value && !Array.isArray(value) ? value.value : null
		this.props.onChange(newValue)
	}

	createLabel(user: User): string {
		const userName = getUserFullName(user)
		const label = !userName.length ? user.email : userName
		return label || ''
	}

	sortByLabel = <T: User>(users: ?Array<T>): ?Array<T> => {
		if (!users) {
			return users
		}
		return users.sort((user1: T, user2: T) => {
			const label1 = this.createLabel(user1)
			const label2 = this.createLabel(user2)
			return label1.localeCompare(label2)
		})
	}

	getOptions = memoize(<T: User>(dataUsers: ?Array<T>) => {
		const users = this.sortByLabel(dataUsers)
		if (!users) return []
		return users.map(this.renderUser)
	})

	formatNameWithEmail = (user: User, displayEmail: ?boolean) => {
		const email = displayEmail ? (user.email ? ` <${user.email}>` : '') : ''
		return getUserFullName(user) + email
	}

	renderUser = (user: User) => {
		const userId = user && user.id ? user.id : user && user.userId ? user.userId : ''
		return {
			value: userId.toString(),
			label: user ? this.formatNameWithEmail(user, this.props.displayEmail) : '',
		}
	}

	render() {
		const { isClearable, labelText, disabled, clientError, value } = this.props

		return (
			<SelectNext
				isSearchable
				isClearable={isClearable}
				label={labelText}
				error={clientError}
				disabled={disabled}
				onChange={this.onSelectFieldChange}
				options={this.getOptions(this.props.users)}
				autoTestId={this.props.autoTestId}
				placeholder={this.props.placeholder}
				value={value}
			/>
		)
	}
}

export default UserPicker
