import React from 'react'
import SvgIcon from 'components/SvgIcon'
import pure from 'recompose/pure'

let Waiting = props => (
	<SvgIcon {...props}>
		<g stroke="none" strokeWidth="4" fill="none" fillRule="evenodd">
			<g fill="#FFFFFF" stroke="#08B0CD" strokeWidth="4">
				<rect x="2" y="2" width="20" height="20" rx="11" />
			</g>
		</g>
	</SvgIcon>
)
Waiting = pure(Waiting)

export default Waiting
