// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[3].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js!../../../variables/colors.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NTrvYDsiu7Ykji6D4YDJb {\n\tmax-width: 1920px;\n\tmargin-left: auto;\n\tmargin-right: auto;\n\tpadding-top: 20px;\n}\n\n.x94q-G0H6IgvQHjH4we9c {\n\tline-height: 80px;\n\tmargin: 0;\n}\n\n._3QZV-1UINC8imuX38Mspjw {\n\tdisplay: flex;\n\tjustify-content: space-between;\n\talign-items: center;\n\talign-content: center;\n}\n\n.zouxxk5MMxhiy2Tiphr4n {\n\tdisplay: block;\n\tfont-size: 18px;\n\tline-height: 60px;\n\tcursor: pointer;\n\tuser-select: none;\n}\n\n.zouxxk5MMxhiy2Tiphr4n:hover {\n\ttext-decoration: underline;\n\tcolor: " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["blue"] + ";\n}\n\n._3Ql3cod5d6NYeM4EPUzXMa {\n\tmargin-left: 14px;\n\tvertical-align: middle;\n}\n\n._1odHYws6UX_QjIGRdAooe2 {\n\twidth: 18px !important;\n\theight: 18px !important;\n\tmargin: 0 !important;\n}\n\n", "",{"version":3,"sources":["webpack://./modules/internal/components/internal-user-settings.css"],"names":[],"mappings":"AAEA;CACC,iBAAA;CACA,iBAAA;CACA,kBAAA;CACA,iBAAA;AAWD;;AARA;CACC,iBAAA;CACA,SAAA;AAWD;;AARA;CACC,aAAA;CACA,8BAAA;CACA,mBAAA;CACA,qBAAA;AAWD;;AARA;CACC,cAAA;CACA,eAAA;CACA,iBAAA;CACA,eAAA;CACA,iBAAA;AAWD;;AARA;CACC,0BAAA;CACA,mDAAA;AAWD;;AARA;CACC,iBAAA;CACA,sBAAA;AAWD;;AARA;CACC,sBAAA;CACA,uBAAA;CACA,oBAAA;AAWD","sourcesContent":["@value black, white, blue from \"variables/colors.css\";\n\n.root {\n\tmax-width: 1920px;\n\tmargin-left: auto;\n\tmargin-right: auto;\n\tpadding-top: 20px;\n}\n\n.headline {\n\tline-height: 80px;\n\tmargin: 0;\n}\n\n.header {\n\tdisplay: flex;\n\tjustify-content: space-between;\n\talign-items: center;\n\talign-content: center;\n}\n\n.add {\n\tdisplay: block;\n\tfont-size: 18px;\n\tline-height: 60px;\n\tcursor: pointer;\n\tuser-select: none;\n}\n\n.add:hover {\n\ttext-decoration: underline;\n\tcolor: blue;\n}\n\n.addAvatar {\n\tmargin-left: 14px;\n\tvertical-align: middle;\n}\n\n.addIcon {\n\twidth: 18px !important;\n\theight: 18px !important;\n\tmargin: 0 !important;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"black": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["black"] + "",
	"white": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["white"] + "",
	"blue": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["blue"] + "",
	"root": "NTrvYDsiu7Ykji6D4YDJb",
	"headline": "x94q-G0H6IgvQHjH4we9c",
	"header": "_3QZV-1UINC8imuX38Mspjw",
	"add": "zouxxk5MMxhiy2Tiphr4n",
	"addAvatar": "_3Ql3cod5d6NYeM4EPUzXMa",
	"addIcon": "_1odHYws6UX_QjIGRdAooe2"
};
export default ___CSS_LOADER_EXPORT___;
