/* @flow */

import { connect } from 'react-redux'
import type { Dispatch, State, AccountingDocumentReduxAction } from 'types'
import withAutoload from 'wrappers/with-autoload'
import { loadAccountingDocumentPdf } from 'modules/accounting-document/actions'
import { getAccDocPdfUrl } from 'modules/accounting-document/selectors'
import AccountingDocumentPreview from '../components/accounting-document-preview'

type OwnProps = {|
	accountingDocumentId: ?string,
|}

type StateProps = {|
	pdfUrl?: ?string,
|}

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => {
	return {
		pdfUrl: ownProps.accountingDocumentId && getAccDocPdfUrl(state, ownProps.accountingDocumentId),
	}
}

type DispatchProps = {|
	autoload: () => void,
|}

const mapDispatchToProps = (dispatch: Dispatch<AccountingDocumentReduxAction>, ownProps: OwnProps): DispatchProps => {
	return {
		autoload: () => {
			ownProps.accountingDocumentId && dispatch(loadAccountingDocumentPdf(ownProps.accountingDocumentId))
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(withAutoload(AccountingDocumentPreview))
