/* @flow */
/** @jsx jsx */
/* eslint-disable react/no-multi-comp */

import moize from 'moize'
import { jsx } from '@emotion/core'
import { type WithTranslateProps } from 'wrappers'
import { Column } from 'modules/data-grid-next/components'
import type { Direction } from 'modules/data-grid-next/types'
import { DATA_GRID_CACHE_SIZE } from 'trivi-constants'
import Tooltip from 'components/tooltip'

export type Props = {|
	width?: number | string,
	defaultSortDirection?: Direction,
	...WithTranslateProps,
|}

export default function getProcessingMessageColumn(props: Props) {
	function columnRenderer(value: string) {
		return (
			<Tooltip label={value} multiLine renderToLayer>
				<div css={styles.root} title={value}>
					{value}
				</div>
			</Tooltip>
		)
	}

	const columnRendererCashed = moize(columnRenderer, { maxSize: DATA_GRID_CACHE_SIZE })

	return (
		<Column
			title={props.t('accountingDocument.columns.error')}
			width={props.width}
			columnId="processingMessage"
			key="processingMessage"
			render={columnRendererCashed}
			sortable
			defaultSortDirection={props.defaultSortDirection || undefined}
		/>
	)
}

const styles = {
	root: {
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		maxWidth: '100%',
		lineHeight: '21px',
		maxHeight: 42,
		display: '-webkit-box',
		lineClamp: '2',
		boxOrient: 'vertical',
		WebkitLineClamp: '2',
		WebkitBoxOrient: 'vertical',
		wordBreak: 'break-all',
	},
}
