/* @flow */

import type { Dispatch, State, UserAction, UserProfile } from 'types'
import type { FinishDeletingMyAvatar, FinishUpdateMe } from 'modules/user/actions/action-types'
import {
	changeMyAvatar,
	cropMyAvatar,
	deleteMyAvatar,
	loadMySettingsAvatar,
	updateMe,
	loadMyIconAvatar,
} from 'modules/user/actions'
import { getAvatar, getUncroppedAvatar, currentUserIsInternal } from 'modules/user/selectors'
import type { MyAvatarGetResponse } from 'modules/common/models/api-model'
import UserSettingsPage from 'modules/user/pages/user-settings'
import { connect } from 'react-redux'
import { userPassword as userPasswordApi } from 'modules/common/models/api-model'
import { navigateToMainScreen } from 'modules/navigation/actions'
import { push } from 'react-router-redux'
import withAutoload from 'wrappers/with-autoload'

type StateProps = {|
	me: ?UserProfile,
	avatar: MyAvatarGetResponse,
	originalAvatar: any,
	isInternal: boolean,
|}

const mapStateToProps = (state: State): StateProps => {
	return {
		me: state.user.me.data,
		avatar: getAvatar(state, '200'),
		originalAvatar: getUncroppedAvatar(state, '200'),
		isInternal: currentUserIsInternal(state),
	}
}

type DispatchProps = {|
	changePassword: (oldPassword: string, newPassword: string) => Promise<any>,
	updateMe: (userProfile: UserProfile) => Promise<FinishUpdateMe>,
	deleteMyAvatar: () => Promise<FinishDeletingMyAvatar>,
	changeMyAvatar: (file: File, localFilePath: string) => Promise<any>,
	cropMyAvatar: (x: number, y: number, height: number, width: number) => Promise<any>,
	redirectToMainScreen: () => void,
	redirectToPreviousPage: (url: string) => void,
	loadSettingsAvatar: (refresh?: boolean) => Promise<void>,
|}

const mapDispatchToProps = (dispatch: Dispatch<UserAction>): DispatchProps => {
	return {
		/** @throws exceptions if oldPassword does not match */
		changePassword: async (oldPassword: string, newPassword: string) => {
			return await userPasswordApi.post({ oldPassword, newPassword })
		},
		updateMe: async (userProfile: UserProfile): Promise<FinishUpdateMe> => {
			return dispatch(updateMe(userProfile))
		},
		deleteMyAvatar: async () => {
			return dispatch(deleteMyAvatar())
		},
		changeMyAvatar: async (file: File, localFilePath: string) => {
			// $FlowFixMe temporary until image loading is resolved
			return dispatch(changeMyAvatar(file, localFilePath))
		},
		cropMyAvatar: async (x: number, y: number, height: number, width: number) => {
			return dispatch(cropMyAvatar(x, y, height, width))
		},
		loadSettingsAvatar: async (refresh?: boolean) => {
			await dispatch(loadMySettingsAvatar())
			if (refresh) {
				await dispatch(loadMyIconAvatar())
			}
		},
		redirectToMainScreen: () => {
			dispatch(navigateToMainScreen())
		},
		redirectToPreviousPage: (referer: string) => {
			if (referer) {
				dispatch(push(referer))
			} else {
				dispatch(navigateToMainScreen())
			}
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(withAutoload(UserSettingsPage))
