/* @flow */

import type { CashRegisterAction, Dispatch, WithRouterProps } from 'types'
import React, { Component } from 'react'
import CashRegisterBalances from '../containers/cash-register-balances'
import CashRegisterDetail from '../containers/cash-register-detail'
import { connect } from 'react-redux'
import { loadCashRegister } from '../actions/index'
import styles from './cash-register.css'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import Helmet from 'react-helmet'

type Props = {|
	...WithTranslateProps,
	...DispatchProps,
	...WithRouterProps,
	params: {
		id: string,
	},
|}

class CashRegister extends Component<Props> {
	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		if (this.props.match.params.id !== nextProps.match.params.id) {
			this.reload(nextProps.match.params.id)
		}
	}

	componentDidMount() {
		this.reload(this.props.match.params.id)
	}

	reload(id: string) {
		this.props.loadCashRegister(id)
	}

	render() {
		const id: string = this.props.match.params.id

		return (
			<div className={styles.root}>
				<Helmet>
					<title>{this.props.t('cashRegister.detail.header')}</title>
				</Helmet>
				<CashRegisterDetail id={id} />
				<CashRegisterBalances cashRegisterId={id} />
			</div>
		)
	}
}

type DispatchProps = {|
	loadCashRegister: (id: string) => void,
|}

const mapDispatchToProps = (dispatch: Dispatch<CashRegisterAction>): DispatchProps => {
	return {
		loadCashRegister: (id: string) => {
			dispatch(loadCashRegister(id))
		},
	}
}

export default withTranslate(connect(null, mapDispatchToProps)(CashRegister))
