//@flow
import React, { Component } from 'react'
import { Checkbox, Button } from 'components'
import styles from './permissions-list.css'
import type { Permission } from 'types'
import DeleteIcon from 'components/svg-icons/action/delete'
import { colors } from 'variables'
import { isEqual } from 'lodash-es'

type Props = {|
	availablePermissions: Array<Permission>,
	serverEnabledPermissions: Array<Permission>,
	userEnabledPermissions: Array<Permission>,
	userDisabledPermissions: Array<Permission>,
	onEnablePermission: (permission: Permission) => void,
	onDisablePermission: (permission: Permission) => void,
	onRemovePermission: (permission: Permission) => void,
	onReloadPress: () => void,
|}

type State = {|
	defaultEnabled: Array<Permission>,
	currentEnabled: Array<Permission>,
|}

export default class PermissionsList extends Component<Props, State> {
	state: State = {
		defaultEnabled: this.getEnabledIds(this.props),
		currentEnabled: this.getEnabledIds(this.props),
	}

	getEnabledIds(props: Props): Array<Permission> {
		return props.serverEnabledPermissions.concat(props.userEnabledPermissions)
	}

	isntChange = () => {
		return isEqual(this.state.defaultEnabled.sort(), this.state.currentEnabled.sort())
	}

	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		this.setState({
			currentEnabled: this.getEnabledIds(nextProps),
		})
	}

	getOnRemovePermissionClick = (permission: Permission) => {
		return () => {
			this.props.onRemovePermission(permission)
		}
	}

	getOnCheckPermission = (permission: Permission) => {
		return (event: SyntheticInputEvent<HTMLInputElement>, checked: boolean) => {
			checked ? this.props.onEnablePermission(permission) : this.props.onDisablePermission(permission)
		}
	}

	renderPermission = (permission: Permission, index: number) => {
		const isServerEnabled = this.props.serverEnabledPermissions.findIndex((p: Permission) => p === permission) > -1
		const isUserEnabled = this.props.userEnabledPermissions.findIndex((p: Permission) => p === permission) > -1
		const isUserDisabled = this.props.userDisabledPermissions.findIndex((p: Permission) => p === permission) > -1
		const notSetByUser = !isUserEnabled && !isUserDisabled
		const isChecked = notSetByUser ? isServerEnabled : isUserEnabled
		const extraStyle = notSetByUser ? { opacity: 0.5 } : null

		const deleteButton = notSetByUser ? null : (
			<DeleteIcon color={colors.grey500} style={style.delete} onClick={this.getOnRemovePermissionClick(permission)} />
		)

		return (
			<div key={index} style={extraStyle} className={styles.row}>
				<div className={styles.deleteButtonWrapper}>{deleteButton}</div>
				<div className={styles.rowTexts}>
					<Checkbox label={permission} checked={isChecked} onCheck={this.getOnCheckPermission(permission)} />
				</div>
			</div>
		)
	}

	render() {
		return (
			<div>
				{this.props.availablePermissions.map(this.renderPermission)}
				<div className={styles.buttonWrapper}>
					<Button
						autoTestId="permission-list-reload"
						labelText="Reload"
						tertiary
						disabled={this.isntChange()}
						onClick={this.props.onReloadPress}
					/>
				</div>
			</div>
		)
	}
}

const style = {
	delete: {
		cursor: 'pointer',
	},
}
