/* @flow */

import React, { Component } from 'react'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import Helmet from 'react-helmet'
import styles from './dashboard.css'
import Header from '../containers/header'
import UnmatchedPayments from '../components/unmatched-payments/unmatched-payments'
import { Button } from '../../../components'
import { todoRoute } from '../routing/routes'
import { withRouter } from 'react-router-dom'
import type { WithRouterProps } from '../../../types'

type Props = {|
	...WithTranslateProps,
	...WithRouterProps,
|}

class DashboardPage extends Component<Props> {
	goToTodo = () => {
		this.props.history.push(todoRoute())
	}

	render() {
		return (
			<div className={styles.root} id={'trivi-dashboard-todo'}>
				<Helmet>
					<title>{this.props.t('dashboard.todo.headline')}</title>
				</Helmet>
				<Header />
				<div className={styles.buttonWrapper}>
					<Button
						autoTestId="bank-back-button"
						labelText={this.props.t('application.back')}
						tertiary
						onClick={this.goToTodo}
					/>
				</div>
				<UnmatchedPayments />
			</div>
		)
	}
}

export default withRouter(withTranslate(DashboardPage))
