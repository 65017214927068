/* @flow */
/** @jsx jsx */

import { jsx } from '@emotion/core'
import { formatToMoney } from 'utils/formatters'
import { colors } from 'variables'

export type Props = {|
	amount: ?number,
	currency: ?string,
	income?: boolean,
	outcome?: boolean,
|}

export default function DirectionAmountDisplay(props: Props) {
	const color = props.outcome ? colors.red : colors.black
	const multiplier = props.outcome ? -1 : 1
	const amount = multiplier * (props.amount ?? 0)

	return (
		<span style={{ color }} css={styles.root}>
			{formatToMoney(amount, { currency: props.currency })}
		</span>
	)
}

const styles = {
	root: {
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
	},
}
