/* @flow */

import React from 'react'
import { Route } from 'react-router'
import { UserIsAuthenticated } from 'lib/auth'
import MainLayout from 'layouts/main-layout'

import { ContactEdit, AddressBookList } from '../pages'

const ListComponent = UserIsAuthenticated(MainLayout('addressBook')(AddressBookList))
const EditComponent = UserIsAuthenticated(MainLayout('addressBook')(ContactEdit))

const AddressBookRouting = () => [
	<Route exact key="/:organizationId/address-book" path="/:organizationId/address-book" component={ListComponent} />,
	<Route
		exact
		key="/:organizationId/address-book/new"
		path="/:organizationId/address-book/new"
		component={EditComponent}
	/>,
	<Route
		exact
		key="/:organizationId/address-book/:id"
		path="/:organizationId/address-book/:id"
		component={EditComponent}
	/>,
]

export default AddressBookRouting
