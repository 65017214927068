//@flow
import { isValidNumber } from 'libphonenumber-js'
import IBAN from 'iban'
import BIC from 'bic'

export type Validators = {
	ICO: (value: ?string | ?number) => boolean,
	integer: (value: ?string | ?number, options?: IntegerValidatorOptions) => boolean,
	decimal: (value: ?string | ?number, options?: DecimalValidatorOptions) => boolean,
	email: (value: ?string | ?number) => boolean,
	phone: (value: ?string | ?number) => boolean,
	zip: (value: ?string | ?number) => boolean,
	date: (value: ?string) => boolean,
	accountNo: (value: ?string) => boolean,
	iban: (value: ?string | ?number) => boolean,
	swift: (value: ?string | ?number) => boolean,
	variableSymbol: (value: ?string | ?number) => boolean,
	password: (value: ?string) => boolean,
}

type IntegerValidatorOptions = {|
	forceDataType?: boolean,
|}

type DecimalValidatorOptions = {|
	decimalSeparator?: '.' | ',',
	partialValidation?: boolean,
	...IntegerValidatorOptions,
|}

const defaultIntegerValidatorOptions = { forceDataType: true }
const defaultDecimalValidatorOptions = {
	decimalSeparator: '.',
	partialValidation: false,
	...defaultIntegerValidatorOptions,
}

export const Validator: Validators = {
	ICO: (value: ?string | ?number) => {
		if (value == null) return false

		let ico = ''
		if (typeof value === 'number') ico = value.toString()
		if (typeof value === 'string') ico = value.replace(/\s+/g, '')

		let shape = /\d{8}/
		if (!shape.test(ico) || ico.length != 8) {
			return false
		}

		let sum = 0
		let last = parseInt(ico[7])
		for (let p = 0; p < 7; p++) {
			sum += [8, 7, 6, 5, 4, 3, 2][p] * parseInt(ico[p])
		}

		let modulo = (11 - (sum % 11)) % 10

		return last == modulo
	},
	integer: (value: ?string | ?number, options: IntegerValidatorOptions = defaultIntegerValidatorOptions): boolean => {
		if (true === options.forceDataType) {
			return Number.isInteger(value)
		} else {
			const re = /^[0-9]+$/
			return re.test(String(value))
		}
	},
	decimal: (value: ?string | ?number, options: DecimalValidatorOptions = defaultDecimalValidatorOptions): boolean => {
		if (true === options.forceDataType) {
			return 'number' === typeof value
		} else {
			const re = new RegExp(
				`^-?[0-9]+(\\${options.decimalSeparator || '.'}[0-9]${options.partialValidation ? '*' : '+'})?$`,
			)
			return re.test(String(value))
		}
	},
	email: (value: ?string | ?number): boolean => {
		// https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
		const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ //eslint-disable-line no-useless-escape
		return re.test(String(value).toLowerCase())
	},
	phone: (value: ?string | ?number): boolean => {
		// https://github.com/catamphetamine/libphonenumber-js#isvalidnumbernumber-defaultcountry
		return isValidNumber(value) || isValidNumber(value, 'CZ')
	},
	accountNo: (value: ?string): boolean => {
		return (value && /^[0-9][0-9-]+[0-9]$/.test(value.toString())) || false
	},
	iban: (value: ?string | ?number): boolean => {
		return IBAN.isValid(value)
	},
	swift: (value: ?string | ?number): boolean => {
		return BIC.isValid(value)
	},
	variableSymbol: (value: ?string | ?number): boolean => {
		return (value && value.toString().length <= 10 && /^[0-9]+$/.test(value.toString())) || false
	},
	zip: (value: ?string | ?number): boolean => {
		const re = /^[0-9]{5}$/
		return re.test(String(value))
	},
	date: (value: ?string): boolean => {
		const re = /^\d{4}-\d{1,2}-\d{1,2}$/
		return !!value && re.test(String(value)) && 'Invalid Date' !== new Date(value).toString()
	},
	password: (value: ?string): boolean => {
		const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d#$@!%&*?.]{6,}$/
		return !!value && re.test(String(value))
	},
}
