/* @flow */
import { connect } from 'react-redux'
import type { AccountingDocumentReduxAction, Dispatch, State, Signature } from 'types'
import type { AccountingDocumentItem } from 'modules/accounting-document/reducers/accounting-documents'
import SignaturePicker from 'modules/user/containers/signature-picker'
import { updateAccountingDocumentSignatureId } from 'modules/accounting-document/actions'
import { getAccountingDocumentItem } from 'modules/accounting-document/selectors'

export type OwnProps = {|
	accountingDocumentId: string,
	readonly: boolean,
|}

type StateProps = {|
	value: ?string,
|}

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => {
	const item: ?AccountingDocumentItem = getAccountingDocumentItem(state, ownProps.accountingDocumentId)
	return {
		value: item && item.data && item.data.signatureId,
	}
}

type DispatchProps = {|
	onChange: (signature: ?Signature) => void,
|}

const mapDispatchToProps = (dispatch: Dispatch<AccountingDocumentReduxAction>, ownProps: OwnProps): DispatchProps => {
	return {
		onChange: (signature: ?Signature) => {
			dispatch(updateAccountingDocumentSignatureId(ownProps.accountingDocumentId, signature ? signature.id : null))
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(SignaturePicker)
