// @flow

import React, { Component } from 'react'
import { AVAILABLE_LOCALES, getLocaleName } from 'locales'
import { colors } from 'variables'
import LanguageSelectorNext from './_language-selector-next'
import type { OptionType } from 'react-select/src/types'

type Props = {|
	locale: ?string,
	onLocaleChange: (locale: ?string) => void,
	labelText: string,
	readonly?: boolean,
	userPanelStyle?: boolean,
|}

const AUTOMATIC = '__automatic__'

export default class UserLanguageSelector extends Component<Props> {
	onLocaleChange = (locale: ?OptionType) => {
		locale && this.props.onLocaleChange(locale.value === AUTOMATIC ? null : locale.value)
	}

	getExtraProps = (): Object => {
		if (!this.props.userPanelStyle) {
			return {}
		}
		return {
			autoWidth: true,
			inline: true,
			labelStyle: {
				color: colors.white,
				marginRight: '10px',
			},
			iconChildrenStyle: {
				fill: colors.white,
			},
		}
	}

	getLabelText = (): string => {
		if (!this.props.userPanelStyle) {
			return this.props.labelText
		} else {
			return this.props.labelText + ':'
		}
	}

	render() {
		const autoValue = {
			key: AUTOMATIC,
			name: 'Automaticky',
		}

		const availableLocales = AVAILABLE_LOCALES.map((lang: string) => {
			return {
				key: lang,
				name: getLocaleName(lang) || '',
			}
		})

		return (
			<LanguageSelectorNext
				labelText={this.getLabelText()}
				availableLanguages={[autoValue, ...availableLocales]}
				language={this.props.locale || AUTOMATIC}
				onChange={this.onLocaleChange}
				readonly={this.props.readonly}
				_extraProps={this.getExtraProps()}
			/>
		)
	}
}
