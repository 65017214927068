//@flow
import type { AccountingDocument, BankAccount, Dispatch, State } from 'types'
import MoveDialog, { type DestinationType } from '../components/dialogs/move-dialog'
import {
	moveAccountingDocumentToBankStatements,
	moveAccountingDocumentToDocuments,
} from 'modules/accounting-document/actions'

import { connect } from 'react-redux'
import { currentUserIsInternal } from 'modules/user/selectors'
import { loadBankAccounts } from 'modules/bank/actions'
import { taskDelay } from 'helpers/multi-download'
import { userHasAccess } from 'permissions'
import withAutoload from 'wrappers/with-autoload'

type StateProps = {|
	bankAccounts: Array<BankAccount>,
	canRemoveToFolderSystemTrivi: boolean,
|}

const mapStateToProps = (state: State): StateProps => {
	return {
		bankAccounts: state.bank.bankAccounts.data,
		canRemoveToFolderSystemTrivi: userHasAccess(state, 'removeDocuments') && currentUserIsInternal(state),
	}
}

type DispatchProps = {|
	autoload: () => void,
	moveDocuments: (Array<AccountingDocument>, DestinationType, string) => Promise<*>,
|}

const mapDispatchToProps = (dispatch: Dispatch<*>): DispatchProps => {
	return {
		autoload: () => {
			dispatch(loadBankAccounts(1, 200))
		},
		moveDocuments: async (
			accountingDocuments: Array<AccountingDocument>,
			destinationType: DestinationType,
			destinationId: string,
		) => {
			await Promise.all(
				accountingDocuments.map((accountingDocument: AccountingDocument) => {
					if ('documents' === destinationType)
						return dispatch(moveAccountingDocumentToDocuments(accountingDocument.id || '', destinationId))
					if ('banks' === destinationType)
						return dispatch(moveAccountingDocumentToBankStatements(accountingDocument.id || '', destinationId))
				}),
			)
			await taskDelay(1000)
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(withAutoload(MoveDialog))
