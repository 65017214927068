import React from 'react'
import SvgIcon from 'components/SvgIcon'
import pure from 'recompose/pure'

let Contact = props => {
	props = Object.assign({}, { viewBox: '0 0 27 22' }, { style: { width: 27, height: 22, transition: 'none' } }, props)
	return (
		<SvgIcon {...props}>
			<g stroke="none" strokeWidth="1" transform="translate(5.000000, 0.000000)" fillRule="evenodd">
				<path d="M3.7500719,2 C2.76048318,2 1.95570894,2.823 1.95570894,3.835 L1.95570894,18.166 C1.95570894,19.177 2.76048318,20 3.7500719,20 L13.2499281,20 C14.2395168,20 15.0442911,19.177 15.0442911,18.166 L15.0442911,3.835 C15.0442911,2.823 14.2395168,2 13.2499281,2 L3.7500719,2 Z M13.2499281,22 L3.7500719,22 C1.68190969,22 0,20.28 0,18.166 L0,3.835 C0,1.72 1.68190969,0 3.7500719,0 L13.2499281,0 C15.3180903,0 17,1.72 17,3.835 L17,18.166 C17,20.28 15.3180903,22 13.2499281,22 Z" />
				<path d="M8.5,6.99581757 C7.67064914,6.99581757 6.99561928,7.67098188 6.99561928,8.50049791 C6.99561928,9.33001394 7.67064914,10.0041824 8.5,10.0041824 C9.32935086,10.0041824 10.0043807,9.33001394 10.0043807,8.50049791 C10.0043807,7.67098188 9.32935086,6.99581757 8.5,6.99581757 M8.5,11 C7.12206292,11 6,9.87870942 6,8.50049791 C6,7.12129058 7.12206292,6 8.5,6 C9.87793708,6 11,7.12129058 11,8.50049791 C11,9.87870942 9.87793708,11 8.5,11" />
				<path d="M5.873,14.9654459 L12.457,14.9654459 C12.2,13.3536106 10.772,12.0345541 9.165,12.0345541 C7.559,12.0345541 6.131,13.3536106 5.873,14.9654459 L5.873,14.9654459 Z M13,16 L5,16 L5,15.4827229 C5,13.0525553 6.651,11 9,11 C11.349,11 13,13.0525553 13,15.4827229 L13,16 Z" />
				<polygon opacity="0.8" points="16 8 18 8 18 4 16 4" />
				<polygon opacity="0.8" points="16 13 18 13 18 9 16 9" />
				<polygon opacity="0.8" points="16 17 18 17 18 13 16 13" />
			</g>
		</SvgIcon>
	)
}
Contact = pure(Contact)

export default Contact
