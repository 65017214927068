/* @flow */

import type {
	AccountingDocument,
	AccountingDocumentContact,
	CashRegister,
	Dispatch,
	FormFieldErrorContext,
	State,
} from 'types'
import { currentUserIsInternal } from 'modules/user/selectors'
import {
	getAccountingDocumentContact,
	getAccountingDocumentDirection,
	getAccountingDocument,
} from 'modules/accounting-document/selectors'
import SettingsPanel from '../../components/invoice-settings/settings-panel'
import { connect } from 'react-redux'
import { getCashRegister } from 'modules/cash-register/selectors/index'
import { loadCashRegister } from 'modules/cash-register/actions'

type OwnProps = {|
	accountingDocumentId: string,
	direction: 'issued' | 'received',
	readonly?: boolean,
	cashRegisterId?: ?string,
	showBookingSettings?: boolean,
	showRemindersSettings?: boolean,
	showDuePeriodSettings?: boolean,
	accountingDocument?: AccountingDocument,
	onChange?: (accountingDocument: AccountingDocument) => void,
	formFieldErrorContext?: FormFieldErrorContext,
|}

function mapStateToProps(state: State, ownProps: OwnProps) {
	const doc: ?AccountingDocument = getAccountingDocument(state, ownProps.accountingDocumentId)
	const cashRegister: ?CashRegister = (doc && doc.cashRegisterId && getCashRegister(state, doc.cashRegisterId)) || null
	const contact: ?AccountingDocumentContact = getAccountingDocumentContact(state, ownProps.accountingDocumentId)
	const internalUser = currentUserIsInternal(state)
	const accountingDocumentDirection = getAccountingDocumentDirection(state, ownProps.accountingDocumentId)

	if (accountingDocumentDirection == null) {
		throw new Error('accounting document direction cannot be void')
	}

	return {
		direction: accountingDocumentDirection,
		internalUser: !!internalUser,
		cashRegister,
		contactId: (contact && contact.contactId) || undefined,
		formFieldErrorContext: ownProps.formFieldErrorContext,
	}
}

type DispatchProps = {|
	loadCashRegister?: (id: string) => void,
|}

const mapDispatchToProps = (dispatch: Dispatch<*>): DispatchProps => {
	return {
		loadCashRegister: (id: string) => {
			dispatch(loadCashRegister(id))
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(SettingsPanel)
