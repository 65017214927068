/* @flow */
/** @jsx jsx */

// $FlowFixMe - there is useState
import { useState } from 'react'
import { jsx } from '@emotion/core'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import type { AccountingDocumentScan } from 'types'
import FileViewer from 'modules/file/containers/file-viewer'
import Tabs from 'components/Tabs/Tabs'
import Tab from 'components/Tabs/Tab'
import Paper from 'components/Paper'

type Props = {|
	...WithTranslateProps,
	scans?: AccountingDocumentScan[],
|}

function ScanView(props: Props) {
	const [tab, setTab] = useState(0)
	if (!props.scans || !props.scans.length) return null

	const single = 1 === props.scans?.length

	function renderTab(scan: AccountingDocumentScan, i: number) {
		return <Tab key={scan.fileId} value={i ?? scan.fileId} label={`${props.t('scans.scanTabLabel')} ${i + 1}`} />
	}

	function renderScan(scan: AccountingDocumentScan, i: number) {
		if (tab !== i) return null
		return (
			<Paper rounded zDepth={3} css={styles.paper}>
				<div css={styles.content}>
					<FileViewer fileId={scan.fileId || ''} autoHeight downloadable />
				</div>
			</Paper>
		)
	}

	return (
		<div css={styles.root}>
			{!single && (
				<Tabs onChange={setTab} autoTestId="scan-view-tabs">
					{props.scans.map(renderTab)}
				</Tabs>
			)}
			{props.scans.map(renderScan)}
		</div>
	)
}

export default withTranslate(ScanView)

const styles = {
	root: {
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
	},
	paper: {
		flex: 1,
		position: 'relative',
	},
	content: {
		position: 'relative',
		overflow: 'hidden',
		padding: '15px 30px 30px 30px',
		height: '100%',
	},
}
