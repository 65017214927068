import React from 'react'
import pure from 'recompose/pure'
import SvgIcon from 'components/SvgIcon'

let Bank = props => (
	<SvgIcon {...props}>
		<g id="Page-1" stroke="none" strokeWidth="1" fillRule="evenodd">
			<g id="Apple-TV">
				<g id="Group">
					<g id="bank">
						<g id="Group-3" transform="translate(2.000000, 4.000000)">
							<path
								d="M2.7002632,2.32483409 C2.19186961,2.32483409 1.77768883,2.73901488 1.77768883,3.24740846 L1.77768883,13.2988473 C1.77768883,13.8072409 2.19186961,14.2205329 2.7002632,14.2205329 L16.853514,14.2205329 C17.3619075,14.2205329 17.7760883,13.8072409 17.7760883,13.2988473 L17.7760883,3.24740846 C17.7760883,2.73901488 17.3619075,2.32483409 16.853514,2.32483409 L2.7002632,2.32483409 Z M16.853514,15.9981329 L2.7002632,15.9981329 C1.21152324,15.9981329 8.88799972e-05,14.7866985 8.88799972e-05,13.2988473 L8.88799972e-05,3.24740846 C8.88799972e-05,1.75866851 1.21152324,0.547234143 2.7002632,0.547234143 L16.853514,0.547234143 C18.3422539,0.547234143 19.5536883,1.75866851 19.5536883,3.24740846 L19.5536883,13.2988473 C19.5536883,14.7866985 18.3422539,15.9981329 16.853514,15.9981329 L16.853514,15.9981329 Z"
								id="Fill-1"
							/>
						</g>
						<polygon
							id="Fill-4"
							points="6.44399986 16.4431996 9.11039978 16.4431996 9.11039978 14.6655997 6.44399986 14.6655997"
						/>
						<polygon
							id="Fill-6"
							points="10.8879997 16.4431996 13.5543996 16.4431996 13.5543996 14.6655997 10.8879997 14.6655997"
						/>
						<polygon
							id="Fill-8"
							points="15.3319996 16.4431996 17.9983995 16.4431996 17.9983995 14.6655997 15.3319996 14.6655997"
						/>
						<path
							d="M6.88839985,8.44028252 C6.15127283,8.44028252 5.5513063,9.04036854 5.5513063,9.77764236 C5.5513063,10.5149162 6.15127283,11.1141171 6.88839985,11.1141171 C7.62552687,11.1141171 8.2254934,10.5149162 8.2254934,9.77764236 C8.2254934,9.04036854 7.62552687,8.44028252 6.88839985,8.44028252 M6.88839985,11.9991998 C5.66368941,11.9991998 4.66639992,11.0025967 4.66639992,9.77764236 C4.66639992,8.55180293 5.66368941,7.55519989 6.88839985,7.55519989 C8.11311029,7.55519989 9.11039978,8.55180293 9.11039978,9.77764236 C9.11039978,11.0025967 8.11311029,11.9991998 6.88839985,11.9991998"
							id="Fill-10"
						/>
						<path
							d="M8.66599979,8.44028252 C7.92887278,8.44028252 7.32890625,9.04036854 7.32890625,9.77764236 C7.32890625,10.5149162 7.92887278,11.1141171 8.66599979,11.1141171 C9.40312681,11.1141171 10.0030933,10.5149162 10.0030933,9.77764236 C10.0030933,9.04036854 9.40312681,8.44028252 8.66599979,8.44028252 M8.66599979,11.9991998 C7.44128936,11.9991998 6.44399986,11.0025967 6.44399986,9.77764236 C6.44399986,8.55180293 7.44128936,7.55519989 8.66599979,7.55519989 C9.89071023,7.55519989 10.8879997,8.55180293 10.8879997,9.77764236 C10.8879997,11.0025967 9.89071023,11.9991998 8.66599979,11.9991998"
							id="Fill-12"
						/>
					</g>
				</g>
			</g>
		</g>
	</SvgIcon>
)
Bank = pure(Bank)

export default Bank
