/* @flow */

import { connect } from 'react-redux'
import type { Dispatch, UserAction, ContactMeAction, CommonAction, InvitationProcessResponse } from 'types'
import { authenticate, changeCurrentOrganization, authenticateAndProcessInvitation } from 'modules/user/actions'
import LoginForm from 'modules/user/components/login-form/login-form'
import { dashboardRoute } from 'modules/dashboard/routing/routes'
import { push } from 'react-router-redux'

type DispatchProps = {|
	onLogin: (username: string, password: string, invitationToken?: string) => Promise<any>,
|}

const mapDispatchToProps = (dispatch: Dispatch<UserAction | ContactMeAction | CommonAction>): DispatchProps => {
	return {
		onLogin: async (username: string, password: string, invitationToken?: string): Promise<any> => {
			await dispatch(changeCurrentOrganization(undefined))
			if (invitationToken) {
				const result: InvitationProcessResponse = await dispatch(
					authenticateAndProcessInvitation(username, password, invitationToken),
				)
				if (result && result.state === 1) {
					dispatch(push(dashboardRoute()))
					dispatch({
						type: 'SET_NOTIFICATION',
						notification: {
							message: 'user.register.invitationAccepted',
							type: 'success',
						},
					})
				}
			} else {
				dispatch(authenticate(username, password))
			}
		},
	}
}

export default connect(undefined, mapDispatchToProps)(LoginForm)
