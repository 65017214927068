//@flow
import React, { Component } from 'react'
import { Paper } from 'components'
import styles from './billing-table.css'
import TableHeader from './table-header'
import OrganizationItemContainer from 'modules/internal/containers/billing/organization-item'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import Loader from 'components/loader'

type Props = {|
	organizationNames: Array<string>,
	loading: boolean,
	...WithTranslateProps,
|}

const Styles = {
	table: {
		border: '1px solid black',
		borderCollapse: 'collapse',
		margin: '0 auto',
		width: '100%',
	},
}

class BillingTable extends Component<Props> {
	render() {
		const { organizationNames, t, loading } = this.props
		return (
			<div className={styles.wrapper}>
				<Paper className={styles.paper}>
					{organizationNames.length > 0 && (
						<table style={Styles.table}>
							<TableHeader />
							{organizationNames.map((item: string, index: number) => (
								<OrganizationItemContainer name={item} index={index} key={index} />
							))}
						</table>
					)}
					{organizationNames.length < 1 && !loading && <div>{t('internal.billings.noBillingMessage')}</div>}
					{loading && <Loader visible />}
				</Paper>
			</div>
		)
	}
}

export default withTranslate(BillingTable)
