//@flow
/** @jsx jsx */

import type { AccountingDocument, FormFieldErrorContext, FormFieldErrorMessage, TemplateScheduler } from 'types'
import { type FormValidationProps, type WithTranslateProps, validate, withTranslate } from 'wrappers'
import type { OptionType, OptionsType } from 'components/select-next'
import { RadioButton, RadioButtonGroup } from 'components/RadioButton'

import ActionInfo from 'components/svg-icons/action/info'
import Checkbox from 'components/checkbox'
import { Component } from 'react'
import DatePicker from 'components/date-picker'
import EmailSettings from '../../containers/invoice-settings/email-settings'
import NumberInput from 'components/number-input'
import SelectNext from 'components/select-next'
import SettingsHeading from './settings-heading'
import { colors } from 'variables'
import { emptyTemplateScheduler } from 'types/empty'
import { formatDateToIsoString } from 'utils/formatters'
import { getTooltipErrorLabel } from 'helpers'
import { jsx } from '@emotion/core'
import moment from 'moment'
import { parseIntOr } from 'utils/math'

export type SchedulerSettingsProps = {|
	contactEmail: ?string,
	templateScheduler: ?TemplateScheduler,
	accountingDocument?: AccountingDocument,
	formFieldErrorContext?: FormFieldErrorContext,
	onChange: (templateScheduler: ?TemplateScheduler, sendEmailAutomatically: boolean) => void,
|}

type Props = {|
	...WithTranslateProps,
	...FormValidationProps,
	...SchedulerSettingsProps,
	errors?: ?Array<FormFieldErrorMessage>,
	removeError?: () => void,
	removeTemplateErrors?: () => void,
	onBack: () => void,
	templateId?: ?string,
|}

type State = {
	sendEmailAutomatically: boolean,
}

type RepeatingType = 'infinity' | 'count' | 'endDate'

class SchedulerSettings extends Component<Props, State> {
	state: State = {
		sendEmailAutomatically: !!(
			this.props.templateScheduler &&
			this.props.templateScheduler.emailRecipients &&
			this.props.templateScheduler.emailRecipients.length > 0
		),
	}

	getTomorrowDate = () => {
		return moment()
			.add(1, 'day')
			.toDate()
	}

	//1 - Daily, 2 - Weekly, 3 - Monthly, 4 - Yearly
	getFrequencyIntervals = () => {
		const { t } = this.props
		return [
			{ value: null, label: t('invoice.settings.scheduler.frequency.none') },
			{ value: '1', label: t('invoice.settings.scheduler.frequency.daily') },
			{ value: '2', label: t('invoice.settings.scheduler.frequency.weekly') },
			{ value: '3', label: t('invoice.settings.scheduler.frequency.monthly') },
			{ value: '4', label: t('invoice.settings.scheduler.frequency.yearly') },
		]
	}

	onFrequencyChange = (frequency: ?OptionType | ?OptionsType) => {
		let templateScheduler: ?TemplateScheduler = copyOrEmptyScheduler(this.props.templateScheduler)

		if (templateScheduler) {
			templateScheduler.generateOnEndOfMonth = false
		}

		if (frequency) {
			if (frequency.value === null) {
				templateScheduler = null
			} else if (templateScheduler && frequency.value) {
				// (typeof frequency: 1 | 2 | 3 | 4, typeof value: number)
				// $FlowFixMe (number 1 from 'value' is incompatible with number literal 1 from 'frequency')
				templateScheduler.frequency = parseInt(frequency.value)
			}
		}

		if (templateScheduler && templateScheduler.frequency !== null && !templateScheduler.start) {
			templateScheduler.start = formatDateToIsoString(this.getTomorrowDate())
		}

		this.props.onChange(Object.freeze(templateScheduler), this.state.sendEmailAutomatically)
		if (!templateScheduler) {
			this.props.removeTemplateErrors && this.props.removeTemplateErrors()
		}
	}

	onStartDateChange = (event: ?SyntheticInputEvent<HTMLInputElement>, date: ?Date) => {
		if (date) {
			let templateScheduler: TemplateScheduler = copyOrEmptyScheduler(this.props.templateScheduler)
			templateScheduler.start = formatDateToIsoString(date)
			this.props.onChange(templateScheduler, this.state.sendEmailAutomatically)
		}
		this.props.removeError && this.props.removeError()
	}

	onRepeatingTypeChange = (event: Event, repeatingType: RepeatingType) => {
		if (repeatingType === 'infinity') {
			let templateScheduler: TemplateScheduler = copyOrEmptyScheduler(this.props.templateScheduler)
			templateScheduler.end = undefined
			templateScheduler.generateMaxCount = undefined
			this.props.onChange(templateScheduler, this.state.sendEmailAutomatically)
		}
	}

	onProcessingChange = (event: Event, useWorkflow: 'true' | 'false') => {
		let templateScheduler: TemplateScheduler = copyOrEmptyScheduler(this.props.templateScheduler)
		templateScheduler.useWorkflow = useWorkflow === 'true'
		const sendEmailAutomatically = templateScheduler.useWorkflow && this.state.sendEmailAutomatically
		this.props.onChange(templateScheduler, sendEmailAutomatically)
		if (useWorkflow !== 'true') {
			this.props.removeTemplateErrors && this.props.removeTemplateErrors()
		}
		this.setState({ sendEmailAutomatically })
	}

	onEndDateChange = (event: ?SyntheticInputEvent<HTMLInputElement>, date: ?Date) => {
		let templateScheduler: TemplateScheduler = copyOrEmptyScheduler(this.props.templateScheduler)
		if (date) {
			templateScheduler.end = formatDateToIsoString(date)
		} else {
			templateScheduler.end = undefined
		}
		templateScheduler.generateMaxCount = undefined
		this.props.onChange(templateScheduler, this.state.sendEmailAutomatically)
	}

	onRepeatingCountChangeEvent = (event: SyntheticInputEvent<HTMLInputElement>, repeatingCount: ?number) => {
		this.onRepeatingCountChange(repeatingCount)
	}

	onRepeatingCountChange = (repeatingCount: ?number) => {
		let templateScheduler: TemplateScheduler = copyOrEmptyScheduler(this.props.templateScheduler)
		templateScheduler.end = undefined
		templateScheduler.generateMaxCount = parseIntOr(repeatingCount)
		this.props.onChange(templateScheduler, this.state.sendEmailAutomatically)
	}

	onEmailSettingsChange = (emails: Array<string>, templateId: ?string) => {
		let templateScheduler: TemplateScheduler = copyOrEmptyScheduler(this.props.templateScheduler)
		templateScheduler.emailRecipients = emails
		// $FlowFixMe
		templateScheduler.emailTemplateId = templateId
		this.props.onChange(templateScheduler, this.state.sendEmailAutomatically)
	}

	onSendEmailAutomaticallyChange = (sendEmailAutomatically: boolean) => {
		this.setState({ sendEmailAutomatically })
		this.props.onChange(copyOrEmptyScheduler(this.props.templateScheduler), sendEmailAutomatically)
	}

	isFrequencySet = () => {
		return !!(this.props.templateScheduler && this.props.templateScheduler.frequency !== null)
	}

	isFrequencyMounthSet = () => {
		return (
			this.props.templateScheduler &&
			this.props.templateScheduler.frequency !== null &&
			this.props.templateScheduler.frequency !== undefined &&
			this.props.templateScheduler.frequency === 3
		)
	}

	renderFrequency = () => {
		return (
			<div css={styles.optionSection}>
				<SelectNext
					value={
						this.props.templateScheduler &&
						this.props.templateScheduler.frequency &&
						this.props.templateScheduler.frequency.toString()
					}
					label={this.props.t('invoice.settings.scheduler.frequency.title')}
					autoTestId="invoice-settings-scheduler-frequency"
					onChange={this.onFrequencyChange}
					options={this.getFrequencyIntervals()}
				/>
			</div>
		)
	}

	getStartDate = (): ?Date => {
		const templateScheduler: ?TemplateScheduler = this.props.templateScheduler
		if (!this.isFrequencySet()) return null
		return (templateScheduler && templateScheduler.start && new Date(templateScheduler.start)) || null
	}

	getEndDate = (): ?Date => {
		const templateScheduler: ?TemplateScheduler = this.props.templateScheduler
		return (templateScheduler && templateScheduler.end && new Date(templateScheduler.end)) || null
	}

	getClientValidationErrors() {
		const errors: Object = {}
		const { t } = this.props

		const startDate = this.getStartDate()

		if (startDate && startDate < new Date()) {
			errors.startDate = t('invoice.settings.scheduler.processing.startDateBeforeNow')
		}

		return errors
	}

	getEndMonth = (): boolean => {
		const templateScheduler: ?TemplateScheduler = this.props.templateScheduler
		return (templateScheduler && templateScheduler.generateOnEndOfMonth) || false
	}

	onCheck = (event: SyntheticInputEvent<HTMLInputElement>, checked: boolean) => {
		let templateScheduler: TemplateScheduler = copyOrEmptyScheduler(this.props.templateScheduler)
		templateScheduler.generateOnEndOfMonth = checked
		let startDate = this.getStartDate()
		if (startDate && checked) {
			// set start date as end of month
			startDate = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0)
			templateScheduler.start = formatDateToIsoString(startDate)
		}
		this.props.onChange(templateScheduler, this.state.sendEmailAutomatically)
	}

	renderStartDate = () => {
		const startDateError = this.props.validationMessage('startDate')
		const checkboxEndOfMonth = this.isFrequencyMounthSet() && (
			<div>
				<Checkbox
					checked={this.getEndMonth()}
					onCheck={this.onCheck}
					label={this.props.t('invoice.settings.scheduler.endMonth')}
					inline
					autoTestId="end-month-checkbox"
				/>
			</div>
		)

		return (
			<div css={styles.optionSection}>
				<DatePicker
					clientError={getTooltipErrorLabel(this.props.errors, this.props.t)}
					labelText={this.props.t('invoice.settings.scheduler.startDate')}
					onChange={this.onStartDateChange}
					value={this.getStartDate()}
					minDate={this.getTomorrowDate()}
					disabled={!this.isFrequencySet()}
					autoTestId="scheduler-settings-start-date"
					fullWidth
				/>
				{checkboxEndOfMonth}
				{startDateError && <div css={styles.dateError}>{startDateError}</div>}
			</div>
		)
	}

	handleRepeatingCountClick = () => {
		const templateScheduler: ?TemplateScheduler = this.props.templateScheduler
		const repeatingCount = templateScheduler && templateScheduler.generateMaxCount && templateScheduler.generateMaxCount
		this.refs.repeatingCount.getWrappedInstance().focus()
		if (!repeatingCount) this.onRepeatingCountChange(1)
	}

	handleEndDateClick = () => {
		this.refs.endDate.getWrappedInstance().focus()
	}

	renderRepeating = () => {
		const templateScheduler: ?TemplateScheduler = this.props.templateScheduler
		const repeatingCount = !this.isFrequencySet()
			? null
			: templateScheduler && templateScheduler.generateMaxCount && templateScheduler.generateMaxCount
		const endDate: ?Date = !this.isFrequencySet()
			? null
			: (templateScheduler && templateScheduler.end && new Date(templateScheduler.end)) || null

		const countTextField = (
			<NumberInput
				compact
				autoWidth
				inline
				ref="repeatingCount"
				onChange={this.onRepeatingCountChangeEvent}
				value={repeatingCount}
				hintText={this.props.t('invoice.settings.scheduler.repeating.countHint')}
				minValue={0}
				disabled={!this.isFrequencySet()}
				name="repeating-count"
			/>
		)

		const endDatePicker = (
			<DatePicker
				compact
				ref="endDate"
				onChange={this.onEndDateChange}
				value={!this.isFrequencySet() ? null : endDate}
				minDate={this.getTomorrowDate()}
				fullWidth
				disabled={!this.isFrequencySet()}
				autoTestId="scheduler-settings-end-date"
			/>
		)

		const INFINITY: RepeatingType = 'infinity'
		const COUNT: RepeatingType = 'count'
		const END_DATE: RepeatingType = 'endDate'

		let valueSelected: ?RepeatingType = INFINITY
		if (this.props.templateScheduler) {
			this.props.templateScheduler.end && (valueSelected = END_DATE)
			this.props.templateScheduler.generateMaxCount && (valueSelected = COUNT)
		}
		if (!this.isFrequencySet()) valueSelected = INFINITY

		return (
			<div css={styles.optionSection}>
				<p css={styles.optionSectionHeading}>{this.props.t('invoice.settings.scheduler.repeating.title')}</p>
				<div css={styles.repeatingContainer}>
					<div css={styles.repeating}>
						<RadioButtonGroup onChange={this.onRepeatingTypeChange} forceSelected={valueSelected} name="repeating">
							<RadioButton
								value={INFINITY}
								label={this.props.t('invoice.settings.scheduler.repeating.infinity')}
								style={styles.radioButton}
								disabled={!this.isFrequencySet()}
							/>
							<RadioButton
								onClick={this.handleRepeatingCountClick}
								value={COUNT}
								labelStyle={styles.countLabel}
								label={
									<div>
										<span css={styles.countField}>{countTextField}</span>
										<span css={styles.repeatLabel}>{this.props.t('invoice.settings.scheduler.repeating.count')}</span>
									</div>
								}
								style={styles.radioButton}
								disabled={!this.isFrequencySet()}
							/>
							<RadioButton
								onClick={this.handleEndDateClick}
								value={END_DATE}
								label={
									<div>
										<span css={styles.dateLabel}>{this.props.t('invoice.settings.scheduler.repeating.endDate')}</span>
									</div>
								}
								style={styles.radioButton}
								disabled={!this.isFrequencySet()}
							/>
						</RadioButtonGroup>
					</div>
					<div css={styles.repeatingEndDate}>{endDatePicker}</div>
				</div>
			</div>
		)
	}

	renderProcessing = () => {
		const error = this.props.validationMessage('workflow')
		const templateScheduler: ?TemplateScheduler = this.props.templateScheduler
		const useWorkflow: boolean = (templateScheduler && templateScheduler.useWorkflow) || false
		let valueSelected: 'true' | 'false' = useWorkflow ? 'true' : 'false'
		if (!this.isFrequencySet()) valueSelected = 'false'

		return (
			<div css={styles.optionSection}>
				<p css={styles.optionSectionHeading}>{this.props.t('invoice.settings.scheduler.processing.title')}</p>
				<RadioButtonGroup onChange={this.onProcessingChange} forceSelected={valueSelected} name="processing">
					<RadioButton
						value={'false'}
						label={this.props.t('invoice.settings.scheduler.processing.createConcept')}
						style={styles.radioButton}
						disabled={!this.isFrequencySet()}
					/>
					<RadioButton
						value={'true'}
						label={this.props.t('invoice.settings.scheduler.processing.moveToAccept')}
						style={styles.radioButton}
						disabled={!this.isFrequencySet()}
					/>
				</RadioButtonGroup>
				{error && <div css={styles.error}>{error}</div>}
			</div>
		)
	}

	getNextDate = (): ?Date => {
		const startDate: ?Date = this.getStartDate()
		if (!startDate) {
			return null
		}
		const frequency: ?number = this.props.templateScheduler && this.props.templateScheduler.frequency
		const period = (frequency && { '1': 'days', '2': 'weeks', '3': 'months', '4': 'years' }[frequency]) || null
		return (
			startDate &&
			period &&
			new Date(
				moment(startDate)
					.add(1, period)
					.toISOString(),
			)
		)
	}

	renderInfo = () => {
		const { t } = this.props
		const startDate: ?Date = this.getStartDate()
		if (!startDate || !this.isFrequencySet()) return null

		const nextDate: ?Date = this.getNextDate()
		const endDate: ?Date = this.getEndDate()
		const onlyOneTime =
			endDate != null && nextDate != null
				? endDate < nextDate
				: this.props.templateScheduler && this.props.templateScheduler.generateMaxCount === 1

		let startDateString = moment(startDate).format('l')
		let nextDateString = moment(nextDate).format('l')
		const mailInfo = t('invoice.settings.scheduler.invoiceIssueInfo.mail', { count: 1 })

		const templateScheduler: ?TemplateScheduler = this.props.templateScheduler
		const useWorkflow: boolean = (templateScheduler && templateScheduler.useWorkflow) || false
		const showMailText = this.state.sendEmailAutomatically && useWorkflow

		const mailText = this.props.contactEmail ? ' ' + mailInfo : null

		if (
			templateScheduler &&
			templateScheduler.frequency &&
			templateScheduler.frequency === 3 &&
			templateScheduler.generateOnEndOfMonth
		) {
			startDateString = moment(startDate)
				.endOf('month')
				.format('l')
			nextDateString = moment(nextDate)
				.endOf('month')
				.format('l')
		}

		return (
			<div css={styles.iconContainer}>
				<ActionInfo style={styles.iconContainerIcon} size={20} color={colors.blue} />
				{onlyOneTime && (
					<div>
						{t('invoice.settings.scheduler.invoiceIssueInfo.invoice.oneTime') + ' '}
						<span css={styles.textNoWrap}>{startDateString}</span> {showMailText ? mailText : ''}
					</div>
				)}
				{!onlyOneTime && (
					<div>
						{t('invoice.settings.scheduler.invoiceIssueInfo.invoice.moreTimes.start') + ' '}
						<span css={styles.textNoWrap}>{startDateString}</span>
						{t('invoice.settings.scheduler.invoiceIssueInfo.invoice.moreTimes.next') + ' '}
						<span css={styles.textNoWrap}>{nextDateString}</span> {showMailText ? mailText : ''}
					</div>
				)}
			</div>
		)
	}

	renderEmailConfiguration = () => {
		let templateScheduler: TemplateScheduler = copyOrEmptyScheduler(this.props.templateScheduler)
		if (templateScheduler.useWorkflow) {
			return (
				<EmailSettings
					onChange={this.onEmailSettingsChange}
					scheduler={this.props.templateScheduler}
					sendEmailAutomatically={!!this.state.sendEmailAutomatically}
					onSendEmailAutomaticallyChange={this.onSendEmailAutomaticallyChange}
					disabled={!this.isFrequencySet()}
					contactId={
						this.props.accountingDocument &&
						this.props.accountingDocument.contact &&
						this.props.accountingDocument.contact.contactId
					}
				/>
			)
		}
	}

	render() {
		this.props.validateForm(this.getClientValidationErrors())
		return (
			<div css={styles.root}>
				<SettingsHeading onGoBack={this.props.onBack} text={this.props.t('invoice.settings.scheduler.headline')} />
				<div css={styles.containerOptions}>
					{this.renderFrequency()}
					{this.renderStartDate()}
					{this.renderRepeating()}
					{this.renderProcessing()}
				</div>
				{this.renderInfo()}
				{this.renderEmailConfiguration()}
			</div>
		)
	}
}

function copyOrEmptyScheduler(scheduler: ?TemplateScheduler): TemplateScheduler {
	return (scheduler && { ...scheduler }) || emptyTemplateScheduler()
}

const styles = {
	root: {
		fontSize: 14,
		paddingRight: 10,
	},
	containerOptions: {
		marginTop: 20,
		marginBottom: 40,
	},
	optionSection: {
		marginBottom: 24,
	},
	optionSectionHeading: {
		fontWeight: 700,
		marginBottom: 10,
		width: '100%',
	},
	iconContainer: {
		position: 'relative',
		paddingLeft: 26,
		lineHeight: '20px',
		maxWidth: 270,
		marginBottom: 40,
	},
	iconContainerIcon: {
		position: 'absolute',
		top: 0,
		left: 0,
	},
	textNoWrap: {
		whiteSpace: 'nowrap',
	},
	dateLabel: {
		verticalAlign: 'middle',
	},
	repeatLabel: {
		marginLeft: 7,
	},
	countField: {
		marginRight: -3,
		position: 'relative',
		top: -1,
	},
	countLabel: {
		overflow: 'visible',
	},
	repeatingContainer: {
		display: 'flex',
		justifyContent: 'flex-start',
	},
	repeating: {
		flex: '1 1 55%',
	},
	repeatingEndDate: {
		flex: '1 1 45%',
		paddingTop: 70,
		paddingLeft: 10,
	},
	radioButton: {
		marginBottom: 12,
	},
	error: {
		color: colors.red,
	},
	dateError: {
		color: colors.red,
		marginTop: 10,
	},
}

export default withTranslate(validate(['TEMPLATE_SCHEDULER_VALIDATION'])(SchedulerSettings))
