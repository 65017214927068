/* @flow */

export function getLoginRoute() {
	return '/login'
}

export function getRegisterRoute() {
	return '/register'
}

export function getInvitationRegisterRoute() {
	return '/invitation'
}

export function logoutRoute() {
	return '/logout'
}

export function getResetPasswordRoute() {
	return '/account/resetPassword'
}

export function getChangePasswordRoute(token: string) {
	return `/account/changePassword/${token}`
}

export function getUserSettingsRoute() {
	return '/account/settings/profile'
}

export function getUserSettingsProfileRoute() {
	return '/account/settings/profile'
}

export function getUserSettingsPasswordRoute() {
	return '/account/settings/password'
}

export function getUserSettingsMainRoute() {
	return '/account/settings/main'
}

export function getUserSettingsEmailsRoute() {
	return '/account/settings/emails'
}

export function getUserSettingsDataBoxRoute() {
	return '/account/settings/dataBox'
}

export function getUserSettingsNotificationsRoute() {
	return '/account/settings/notifications'
}
