/* @flow */
/** @jsx jsx */

import { Component } from 'react'
import { jsx } from '@emotion/core'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import type { CashRegister, CashRegisterInventory, CashRegisterInventories } from 'types'
import { convertToDate, formatToDateString, formatDateToIsoString } from 'utils/formatters'
import { DatePicker, Button, Loader } from 'components'
import { Validator } from 'utils'
import FormBox from '../../../components/form-box'

export type Props = {|
	date: ?Date,
	cashRegister: ?CashRegister,
	inventories: ?CashRegisterInventories,
	loading?: boolean,
	saving: boolean,
	makeInventory?: () => void,
	loadCashRegister?: () => void,
	loadInventories?: () => void,
	onChange?: (?Date) => void,
|}

type ComponentProps = {| ...WithTranslateProps, ...Props |}

class CashRegisterMakeInventory extends Component<ComponentProps> {
	componentDidMount() {
		if (!this.props.cashRegister) {
			this.props.loadCashRegister && this.props.loadCashRegister()
		}
		this.props.loadInventories && this.props.loadInventories()
	}

	handleDatePickerChange = (event: ?SyntheticInputEvent<HTMLInputElement>, date: ?Date) => {
		let dateToIsoString: string = formatDateToIsoString(date || new Date())
		if (!Validator.date(dateToIsoString)) {
			return
		}
		this.props.onChange && this.props.onChange(date)
	}

	handleConfirmClick = () => {
		this.props.makeInventory && this.props.makeInventory()
	}

	renderActionButton() {
		const { cashRegister, inventories, saving, t } = this.props

		if (!cashRegister || !inventories) {
			return null
		}

		let lastInventoryDate: ?Date
		inventories.forEach((inventory: CashRegisterInventory) => {
			const date = convertToDate(inventory.date)
			if (!lastInventoryDate || date > lastInventoryDate) {
				lastInventoryDate = date
			}
		})

		return (
			<Button
				autoTestId="cash-register-make-inventory"
				labelText={t('cashRegister.inventory.save')}
				onClick={this.handleConfirmClick}
				disabled={saving}
			/>
		)
	}

	renderHeader() {
		const { cashRegister, inventories, saving, t } = this.props
		const date = this.props.date || new Date()

		if (!cashRegister || !inventories) {
			return null
		}

		let lastInventoryDate: ?Date
		inventories.forEach((inventory: CashRegisterInventory) => {
			const date = convertToDate(inventory.date)
			if (!lastInventoryDate || date > lastInventoryDate) {
				lastInventoryDate = date
			}
		})

		return (
			<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
				<div>
					<div>{cashRegister.name}</div>
				</div>
				<div>
					<DatePicker
						labelText={t('cashRegister.inventory.date')}
						hintText={t('cashRegister.dateHint')}
						autoTestId="cash-register-inventory-date"
						value={date}
						onChange={this.handleDatePickerChange}
						minDate={lastInventoryDate}
						maxDate={new Date()}
						disabled={saving}
					/>
				</div>
			</div>
		)
	}

	render() {
		const { cashRegister, inventories, saving, t } = this.props
		const date = this.props.date || new Date()

		if (!cashRegister || !inventories) {
			return null
		}

		let lastInventoryDate: ?Date
		inventories.forEach((inventory: CashRegisterInventory) => {
			const date = convertToDate(inventory.date)
			if (!lastInventoryDate || date > lastInventoryDate) {
				lastInventoryDate = date
			}
		})

		return (
			<FormBox
				header={this.renderHeader()}
				actions={this.renderActionButton()}
				headline={t('cashRegister.inventory.header')}
			>
				<div>
					<div>
						{lastInventoryDate ? (
							<div>{t('cashRegister.inventory.lastInventory', { date: formatToDateString(lastInventoryDate) })}</div>
						) : (
							<div>{t('cashRegister.inventory.noLastInventory')}</div>
						)}
					</div>
					{saving && (
						<div css={styles.savingContainer}>
							<div>{t('cashRegister.inventory.savingMessage', { date: formatToDateString(date) })}</div>
						</div>
					)}
					<Loader visible={this.props.loading} />
				</div>
			</FormBox>
		)
	}
}

const styles = {
	savingContainer: {
		marginTop: 30,
	},
}

export default withTranslate(CashRegisterMakeInventory)
