//@flow

import React, { Component } from 'react'
import {
	type WithOrganizationSettingsProps,
	type WithTranslateProps,
	withOrganizationSettings,
	withTranslate,
} from 'wrappers'

import type { OrganizationSettings } from 'types'
import Toggle from 'components/toggle'
import styles from '../settings.css'

export type Props = {|
	...WithOrganizationSettingsProps,
	...WithTranslateProps,
|}

type ComponentState = {|
	organizationSettings: ?OrganizationSettings,
|}

class UploadReminder extends Component<Props, ComponentState> {
	state = this.getStateFromProps(this.props)

	getStateFromProps(props: Props): ComponentState {
		return {
			organizationSettings: props.organizationSettings,
		}
	}

	UNSAFE_componentWillReceiveProps(newProps: Props) {
		this.setState(this.getStateFromProps(newProps))
	}

	componentDidMount() {
		if (!this.props.organizationSettings) {
			this.props.loadOrganizationSettings()
		}
	}

	togglePermalink = () => {
		const { organizationSettings } = this.props

		if (!organizationSettings) return

		const newSettings: OrganizationSettings = Object.freeze({
			...organizationSettings,
			enableAccountingDocumentUploadReminder: !organizationSettings.enableAccountingDocumentUploadReminder,
		})

		this.props.organizationSettings &&
			this.props.updateOrganizationSettings(this.props.organizationSettings, newSettings)
	}

	render() {
		const { t } = this.props

		return (
			<div>
				<div className={styles.row}>
					<Toggle
						autoTestId="accounting-documents-permalink"
						label={t('settings.uploadReminder.remindMe')}
						toggled={
							!!(
								this.state.organizationSettings &&
								this.state.organizationSettings.enableAccountingDocumentUploadReminder
							)
						}
						onToggle={this.togglePermalink}
					/>
				</div>
			</div>
		)
	}
}

export default withTranslate(withOrganizationSettings(UploadReminder))
