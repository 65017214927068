/* @flow */

import Menu from '../components/menu'
import { connect } from 'react-redux'
import { getUncompletedCardsCount } from '../selectors'
import type { State } from 'types'

type StateProps = {|
	uncompletedCardsCount: number,
|}

const mapStateToProps = (state: State): StateProps => {
	return {
		uncompletedCardsCount: getUncompletedCardsCount(state),
	}
}

export default connect(mapStateToProps)(Menu)
