/* @flow */
/** @jsx jsx */

import { Component } from 'react'
import { jsx } from '@emotion/core'
import Separator from 'components/Separator'
import { colors } from 'variables'

type Props = {|
	text?: string,
|}

class SettingsSeparator extends Component<Props> {
	render() {
		return (
			<div css={styles.root}>
				<Separator left={this.props.text && <span css={styles.text}>{this.props.text}</span>} />
			</div>
		)
	}
}

const styles = {
	root: {
		margin: '15px 0 5px 0',
	},
	text: {
		color: colors.grey900,
		fontSize: 14,
		fontWeight: 'bold',
		marginRight: 10,
	},
}

export default SettingsSeparator
