/* @flow */

import { connect } from 'react-redux'
import type { AccountingDocument, AccountingDocumentContact, Contact, Dispatch, State } from 'types'
import { AccountingDocumentContact_Contact } from 'types/convertor'
import { updateAccountingDocumentDatesByDuePeriod } from 'modules/accounting-document/actions'
import { getAccountingDocument, getAccountingDocumentContact } from 'modules/accounting-document/selectors'
import { saveContact } from 'modules/address-book'
import { getContact } from 'modules/address-book/selectors'
import ContactSettings from '../../components/invoice-settings/contact-settings'

export type OwnProps = {|
	accountingDocumentId: ?string,
	readonly?: boolean,
	isTemplate?: boolean,
	onBackwardClick: () => void,
|}

function mapStateToProps(state: State, ownProps: OwnProps) {
	const contact: ?AccountingDocumentContact = getAccountingDocumentContact(state, ownProps.accountingDocumentId || '')
	const accountingDocument: ?AccountingDocument = getAccountingDocument(state, ownProps.accountingDocumentId || '')

	return {
		contact: ownProps.isTemplate
			? accountingDocument &&
			  accountingDocument.contact &&
			  AccountingDocumentContact_Contact(accountingDocument.contact)
			: contact && contact.contactId
			? getContact(state, contact.contactId)
			: null,
		contactId: contact && contact.contactId,
	}
}

function mapDispatchToProps(dispatch: Dispatch<*>, ownProps: OwnProps) {
	return {
		saveContact: (contact: Contact) => {
			contact.defaultDuePeriod != null &&
				dispatch(
					updateAccountingDocumentDatesByDuePeriod(ownProps.accountingDocumentId || '', contact.defaultDuePeriod),
				)
			return dispatch(saveContact(contact))
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactSettings)
