/* @flow */
/** @jsx jsx */

import { Component } from 'react'
import { jsx } from '@emotion/core'
import type { AccountingDocumentAttachment, AccountingDocumentConnection } from 'types'
import {
	withTranslate,
	type WithTranslateProps,
	withAccountingDocumentActions,
	type WithAccountingDocumentActionsProps,
} from 'wrappers'
import Clear from 'components/svg-icons/content/clear'
import IconButton from 'components/icon-button'
import Button from 'components/button'
import Attachment from './attachment'
import Connection from './connection'
import colors from 'variables/colors.css'

type Props = {|
	...WithTranslateProps,
	...WithAccountingDocumentActionsProps,
	accountingDocumentId: string,
	attachments: ?Array<AccountingDocumentAttachment>,
	connections: ?Array<AccountingDocumentConnection>,
	onRemoveAttachment: (attachment: AccountingDocumentAttachment) => void,
	onRemoveConnection: (connection: AccountingDocumentConnection) => Promise<any>,
	onComponentUnmount: () => void,
	loadAccountingDocumentAttachments: () => void,
	loadAccountingDocumentConnections: () => void,
	showAccountingDocuments?: boolean,
	showDocuments?: boolean,
	onRequestClose: () => void,
	readonly?: boolean,
|}

class AttachmentList extends Component<Props> {
	static defaultProps = {
		showAccountingDocuments: true,
		showDocuments: true,
	}

	UNSAFE_componentWillMount() {
		!this.props.attachments && this.props.loadAccountingDocumentAttachments()
		!this.props.connections && this.props.loadAccountingDocumentConnections()
	}

	componentWillUnmount = () => {
		this.props.onComponentUnmount()
	}

	renderCloseButton(onRemove: () => void) {
		return (
			!this.props.readonly && (
				<div css={style.icon}>
					<IconButton
						size={30}
						onClick={onRemove}
						autoTestId="attachment-list-remove"
						tooltip={this.props.t('attachments.removeAttachment')}
					>
						<Clear size={14} />
					</IconButton>
				</div>
			)
		)
	}

	renderAttachments() {
		return (
			this.props.attachments &&
			this.props.attachments.map((attachment: AccountingDocumentAttachment) => (
				<Attachment
					attachment={attachment}
					readonly={this.props.readonly}
					onRemove={this.props.onRemoveAttachment}
					key={attachment.documentName}
				/>
			))
		)
	}

	renderConnections() {
		return (
			this.props.connections &&
			this.props.connections.map((connection: AccountingDocumentConnection) => (
				<Connection
					connection={connection}
					readonly={this.props.readonly}
					onRemove={this.props.onRemoveConnection}
					key={connection.connectedAccountingDocumentId || connection.connectedAccountingDocumentNo}
				/>
			))
		)
	}

	render() {
		const { t, showDocuments, showAccountingDocuments, attachments, connections, onRequestClose } = this.props
		const noConnections: boolean = !connections || connections.length < 1
		const noAttachments: boolean = !attachments || attachments.length < 1
		return (
			<div>
				{showDocuments && attachments && attachments.length > 0 && (
					<div>
						<span css={style.h4}>{t('attachments.documents')}</span>
						<div css={style.container}>{this.renderAttachments()}</div>
					</div>
				)}

				{showAccountingDocuments && connections && connections.length > 0 && (
					<div>
						<span css={style.h4}>{t('attachments.papers')}</span>
						<div css={style.container}>{this.renderConnections()}</div>
					</div>
				)}

				{noConnections && noAttachments && <div css={style.noitems}>{t('attachments.noAttachments')}</div>}

				{!this.props.readonly && (
					<span css={style.button}>
						<Button
							autoTestId="attachment-list-back"
							onClick={onRequestClose}
							labelText={t('attachments.backToDocument')}
						/>
					</span>
				)}
			</div>
		)
	}
}

const style = {
	icon: {
		position: 'absolute',
		padding: 0,
		right: 0,
		top: 0,
	},
	h4: {
		opacity: 0.6,
		color: colors.black,
		lineHeight: '20px',
		textTransform: 'uppercase',
		marginBottom: 2,
		fontSize: 12,
	},
	noitems: {
		color: colors.blackFaded60,
		lineHeight: '20px',
		margin: '5px 0 10px 0',
		fontSize: 14,
	},
	container: {
		marginBottom: 15,
	},
	button: {
		marginTop: 15,
	},
}

export default withTranslate(withAccountingDocumentActions(AttachmentList))
