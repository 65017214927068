/* @flow */
/** @jsx jsx */

import { Component } from 'react'
import { jsx } from '@emotion/core'
import { emptyContactAddress } from 'types/empty'
import type { ContactAddress, Country, FormValidationProps } from 'types'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import MailIcon from 'components/svg-icons/action/perm-contact-calendar'
import PhoneIcon from 'components/svg-icons/communication/phone'
import ExploreIcon from 'components/svg-icons/action/explore'
import { TextField, Button, EmailInput, CountrySelector, PhoneInput } from 'components'
import styles from './contact-address-edit.css'

export type AddressType = 1 | 2

export type Props = {|
	...FormValidationProps,
	...WithTranslateProps,
	address: ?ContactAddress,
	onBlur: (address: ContactAddress) => void,
	onDelete?: () => void,
	allowDelete?: boolean,
	type: AddressType,
	disabled?: boolean,
|}

type State = {|
	address: ContactAddress,
|}

class ContactAddressEdit extends Component<Props, State> {
	state: State = {
		address: this.props.address || emptyContactAddress(this.props.type),
	}

	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		if (nextProps.address) {
			this.setState({ address: nextProps.address })
		}
	}

	getOnFieldChange = (name: string, immediatePropagation: boolean) => {
		return (event: ?SyntheticInputEvent<HTMLInputElement>, value: ?string) => {
			const address: ContactAddress = {
				...this.state.address,
				[name]: value,
			}

			this.setState({ address })

			if (immediatePropagation) {
				this.props.onBlur(address)
			}
		}
	}

	onEmailChange = (event: SyntheticInputEvent<HTMLInputElement>, value: ?string) => {
		this.getOnFieldChange('email', true)(event, value)
	}

	onFieldBlur = () => {
		this.props.onBlur(this.state.address)
	}

	onCountryChange = (item: Country) => {
		this.getOnFieldChange('country', true)(null, item.id || '')
	}

	getValidationKey = (id: string): string => {
		return 'address' + this.props.type.toString() + '_' + id
	}

	render() {
		const { t, allowDelete } = this.props
		const { address } = this.state

		return (
			<div className={styles.root} id={'contact-edit-container'}>
				{allowDelete && !this.props.disabled && (
					<Button
						autoTestId="contact-address-edit-delete"
						labelText={t('contactEdit.edit.delete')}
						onClick={this.props.onDelete}
					/>
				)}
				<div>
					<div className={styles.iconized}>
						<TextField
							inline
							autoWidth
							disabled={this.props.disabled}
							name={'street'}
							value={address.street || ''}
							onChange={this.getOnFieldChange('street', true)}
							onBlur={this.onFieldBlur}
							hintText={t('contactEdit.tile.hint.street')}
							autoTestId="contact-address-edit-street"
						/>
						<ExploreIcon disabled size={16} style={style.icon} />
					</div>
					<TextField
						inline
						autoWidth
						disabled={this.props.disabled}
						name={'zipCode'}
						value={address.zipCode || ''}
						onChange={this.getOnFieldChange('zipCode', true)}
						onBlur={this.onFieldBlur}
						hintText={t('contactEdit.tile.hint.zipCode')}
						autoTestId="contact-address-edit-zip"
					/>
					&nbsp; &nbsp;
					<TextField
						inline
						autoWidth
						name={'city'}
						disabled={this.props.disabled}
						value={address.city || ''}
						onChange={this.getOnFieldChange('city', true)}
						onBlur={this.onFieldBlur}
						hintText={t('contactEdit.tile.hint.city')}
						autoTestId="contact-address-edit-city"
					/>
					<br />
					<div css={style.country}>
						<CountrySelector
							onChange={this.onCountryChange}
							value={this.props.validationValue(this.getValidationKey('country'), address.country)}
							disabled={this.props.disabled}
							placeholder={t('invoice.contact.countryHint')}
							error={this.props.validationMessage(this.getValidationKey('country'))}
							autoTestId="contact-address-edit-country"
							inline
							autoWidth
						/>
					</div>
				</div>
				<div className={styles.contact}>
					<div className={styles.iconized}>
						<EmailInput
							inline
							autoWidth
							disabled={this.props.disabled}
							name={'email'}
							value={address.email}
							onChange={this.onEmailChange}
							onBlur={this.onFieldBlur}
							hintText={t('contactEdit.tile.hint.email')}
							clientError={this.props.validationMessage(this.getValidationKey('email'))}
							autoTestId="contact-address-edit-email"
						/>
						<MailIcon disabled size={16} style={style.icon} />
					</div>
					<div className={styles.iconized}>
						<PhoneInput
							inline
							autoWidth
							disabled={this.props.disabled}
							name={'phone'}
							value={this.props.validationValue(this.getValidationKey('phone'), address.phone)}
							onChange={this.getOnFieldChange('phone', true)}
							onBlur={this.onFieldBlur}
							hintText={t('contactEdit.tile.hint.phone')}
							autoTestId="contact-address-edit-phone"
							clientError={this.props.validationMessage(this.getValidationKey('phone'))}
						/>
						<PhoneIcon disabled size={16} style={style.icon} />
					</div>
				</div>
			</div>
		)
	}
}

const style = {
	icon: {
		position: 'absolute',
		top: 5,
		left: -30,
	},
	country: {
		marginLeft: -6,
	},
}

export default withTranslate(ContactAddressEdit)
