/* @flow */

import React from 'react'

export type Props = {|
	width?: number,
|}

type ComponentProps = {|
	...Props,
	image: string,
	link: string,
	alt: string,
|}

function StoreIcon(props: ComponentProps) {
	return (
		<a rel="noopener noreferrer" target="_blank" href={props.link}>
			<img style={{ width: props.width }} src={props.image} alt={props.alt} />
		</a>
	)
}

export default StoreIcon
