/* @flow */

import React, { Component } from 'react'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import type { AccountingDocument, AccountingDocumentIntegrationResponse } from 'types'
import { getSentEmailsFromDocument } from '../domain/accounting-document'
import { formatToDateString } from 'utils/formatters'
import SendIcon from 'components/svg-icons/content/send'
import Tooltip from 'components/tooltip'
import { colors } from 'variables'

type Props = {|
	...WithTranslateProps,
	accountingDocument: AccountingDocument,
	style?: Object,
|}

class AccountingDocumentSentIcon extends Component<Props> {
	getLabel = () => {
		const integrations = getSentEmailsFromDocument(this.props.accountingDocument)
		if (!integrations || !integrations.length) return null

		return (
			<span>
				{integrations.map((integration: AccountingDocumentIntegrationResponse) => {
					const emails =
						integration.data &&
						integration.data.email &&
						integration.data.email.recipients &&
						integration.data.email.recipients.join(this.props.t('invoice.wasSentToEmailsDelimiter'))

					return (
						<div key={integration.timestamp}>
							{this.props.t('invoice.wasSent')}&nbsp;
							<span style={{ fontWeight: 'bold' }}>
								{integration.timestamp ? formatToDateString(integration.timestamp) : ''}
							</span>
							{emails && (
								<span>
									&nbsp;
									{this.props.t('invoice.wasSentTo')}
									&nbsp;
									<span style={{ fontWeight: 'bold' }}>{emails}</span>
								</span>
							)}
						</div>
					)
				})}
			</span>
		)
	}

	render() {
		const label = this.getLabel()
		if (!label) return null

		return (
			<Tooltip wrapperStyle={this.props.style} label={label}>
				<SendIcon disabled style={{ display: 'block' }} color={colors.black} size={16} />
			</Tooltip>
		)
	}
}

export default withTranslate(AccountingDocumentSentIcon)
