// @flow

export function internalUsersRoute() {
	return '/internal-dashboard/users'
}

export function internalDashboardRoute() {
	return '/internal-dashboard'
}

export function billingRoute() {
	return '/internal-dashboard/billing'
}
