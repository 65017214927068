/* @flow */
import type { I18NextTranslateFn } from 'types'
import type { Section } from '../../accounting-document/components/accounting-document-list/document-button-sections'

type SectionRules = {|
	showIssued: boolean,
	showReceived: boolean,
	showUpload: boolean,
	showCreateContact: boolean,
	// showCreateTask: boolean,
|}

function getIssuedSection(t: I18NextTranslateFn): Section {
	return {
		label: t('cashRegister.createAccountingDocument.separator.issued'),
		type: 'issued',
		items: [
			{
				id: 'SimplifiedInvoice',
				label: t('accountingDocument.typesLong.simplifiedInvoice'),
			},
			{
				id: 'CashReceipt',
				value: 'cash_receipt',
				label: t('cashRegister.createAccountingDocument.issuedCashReceipt'),
			},
			{
				id: 'CashTransfer',
				value: 'cash_transfer',
				label: t('cashRegister.createAccountingDocument.issuedCashTransfer'),
			},
			{
				id: 'CashUncategorized',
				value: 'cash_uncategorized',
				label: t('cashRegister.createAccountingDocument.issuedUncategorized'),
			},
		],
	}
}

function getReceivedSection(t: I18NextTranslateFn): Section {
	return {
		label: t('cashRegister.createAccountingDocument.separator.received'),
		type: 'received',
		items: [
			{
				id: 'SimplifiedInvoice',
				label: t('accountingDocument.typesLong.simplifiedInvoice'),
			},
			{
				id: 'CashReceipt',
				value: 'cash_receipt',
				label: t('cashRegister.createAccountingDocument.receivedCashReceipt'),
			},
			{
				id: 'CashTransfer',
				value: 'cash_transfer',
				label: t('cashRegister.createAccountingDocument.receivedCashTransfer'),
			},
			{
				id: 'EmployeeAdvance',
				value: 'employee_advance',
				label: t('cashRegister.createAccountingDocument.receivedEmployeeAdvance'),
			},
			{
				id: 'CashUncategorized',
				value: 'cash_uncategorized',
				label: t('cashRegister.createAccountingDocument.receivedUncategorized'),
			},
		],
	}
}

export function getCashRegisterSections(
	t: I18NextTranslateFn,
	sectionRules: SectionRules,
	mode: string,
): Array<Section> {
	let sections: Array<Section> = []

	if (mode !== 'combined') {
		const section = mode === 'issued' ? getIssuedSection(t) : getReceivedSection(t)
		section && sections.push(section)
	} else {
		getIssuedSection(t) && sections.push(getIssuedSection(t))
		getReceivedSection(t) && sections.push(getReceivedSection(t))
	}

	return sections
}
