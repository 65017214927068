/* @flow */
import React, { Component } from 'react'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import type { State as AppState, Enum, EnumItem, Tag } from 'types'
import Dialog from 'components/dialog'
import Button from 'components/button'
import TextField from 'components/TextField'
import styles from '../settings.css'
import { getOrganizationBranches, getOrganizationProjects } from '../../../organization/selectors'
import { connect } from 'react-redux'
import type { FormValidationProps } from 'wrappers'
import validate from 'wrappers/validate'
import { ACCDOC_TAG_TYPE, ACCDOC_TAG_MAXLENGTH } from 'modules/accounting-document/constants'

type Props = {|
	...WithTranslateProps,
	...FormValidationProps,
	...StateProps,
	open: boolean,
	tag: ?Tag,
	onCancel?: () => void,
	onConfirm: (tag: Tag) => Promise<void>,
|}

type StateProps = {|
	branches: ?Enum,
	projects: ?Enum,
|}

type State = {|
	tag: ?Tag,
|}

class TagEditDialog extends Component<Props, State> {
	state = this.getStateFromProps(this.props)

	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		this.setState(this.getStateFromProps(nextProps))
	}

	getStateFromProps(props: Props): State {
		return {
			tag: { ...props.tag },
		}
	}

	onCancel = () => {
		this.props.resetValidation()
		this.props.onCancel && this.props.onCancel()
	}

	onConfirm = async () => {
		this.props.resetValidation()
		this.state.tag && (await this.props.onConfirm(this.state.tag))
	}

	onNameChange = (event: SyntheticInputEvent<HTMLInputElement>, name: string) => {
		this.setState({
			tag: {
				...this.state.tag,
				name,
			},
		})
	}

	onCodeChange = (event: SyntheticInputEvent<HTMLInputElement>, code: string) => {
		this.setState({
			tag: {
				...this.state.tag,
				code,
			},
		})
	}

	isEdit = () => {
		return this.state.tag && this.state.tag.id
	}

	getHeadline = () => {
		return this.state.tag
			? this.props.t(
					`settings.reporting.${this.isEdit() ? 'edit' : 'add'}${
						this.state.tag && this.state.tag.type === ACCDOC_TAG_TYPE.branch ? 'Branch' : 'Project'
					}TagHeadline`,
			  )
			: ''
	}

	getActions = () => {
		const isValid = this.props.isFormValid()
		return [
			<Button
				primary
				wide
				key="confirmModalButton"
				labelText={
					this.isEdit() ? this.props.t('settings.forms.editButton') : this.props.t('settings.forms.createButton')
				}
				onClick={this.onConfirm}
				disabled={!isValid}
				autoTestId="tag-edit-confirm"
			/>,
			<Button
				secondary
				wide
				key="cancelModalButton"
				labelText={this.props.t('settings.forms.cancelButton')}
				onClick={this.onCancel}
				autoTestId="tag-edit-cancel"
			/>,
		]
	}

	isTagUniq = (newOrEditedTag: Tag, sortingDirection: 'name' | 'code'): boolean => {
		if (this.props.tag && this.props.projects && this.props.branches) {
			const { tag } = this.props
			const key = sortingDirection === 'code' ? 'key' : 'value'
			const newValue = newOrEditedTag[sortingDirection] && newOrEditedTag[sortingDirection].toLowerCase()
			if (newOrEditedTag.type === ACCDOC_TAG_TYPE.branch) {
				return !this.props.branches
					.filter((branch: EnumItem) =>
						tag.id !== null ? !(branch.key === tag.code && branch.value === tag.name) : true,
					)
					.some((branch: EnumItem) => branch[key] && branch[key].toLowerCase() === newValue)
			} else if (newOrEditedTag.type === ACCDOC_TAG_TYPE.project) {
				return !this.props.projects
					.filter((project: EnumItem) =>
						tag.id !== null ? !(project.key === tag.code && project.value === tag.name) : true,
					)
					.some((project: EnumItem) => project[key] && project[key].toLowerCase() === newValue)
			}
		}
		return true
	}

	getClientValidationErrors() {
		const errors = {}
		const { t } = this.props
		const { tag } = (this.state: State)

		if (tag && !tag.name) {
			errors.name = t('application.validation.mandatory')
		}

		if (tag && !tag.code) {
			errors.code = t('application.validation.mandatory')
		}

		if (tag && !this.isTagUniq(tag, 'name')) {
			errors.name = t('clientError.uniqName')
		}

		if (tag && !this.isTagUniq(tag, 'code')) {
			errors.code = t('clientError.uniqCode')
		}

		return errors
	}

	render() {
		this.props.validateForm(this.getClientValidationErrors())
		const isEmptyNew = this.state.tag && !this.state.tag.id && this.state.tag.name === '' && this.state.tag.code === ''

		if (
			!isEmptyNew &&
			!this.props.isFormValid() &&
			!this.props.validationMessage('code') &&
			!this.props.validationMessage('name')
		) {
			this.props.resetValidation()
			this.props.validateForm(this.getClientValidationErrors())
		}

		return (
			<Dialog
				autoTestId="tag-edit-dialog"
				title={this.getHeadline()}
				actions={this.getActions()}
				open={this.props.open}
			>
				<div className={styles.oneColumnDialog}>
					<div className={styles.row}>
						<TextField
							onChange={this.onNameChange}
							labelText={this.props.t('settings.reporting.name')}
							clientError={this.props.open ? this.props.validationMessage('name') : null}
							value={this.props.validationValue('name', this.state.tag && this.state.tag.name)}
							autoTestId="tag-edit-name"
							fullWidth
							name="name"
							maxLength={100}
						/>
					</div>
					<div className={styles.row}>
						<TextField
							onChange={this.onCodeChange}
							labelText={this.props.t('settings.reporting.code')}
							clientError={this.props.open ? this.props.validationMessage('code') : null}
							value={this.props.validationValue('code', this.state.tag && this.state.tag.code)}
							autoTestId="tag-edit-code"
							maxLength={
								this.state.tag && this.state.tag.type === ACCDOC_TAG_TYPE.branch
									? ACCDOC_TAG_MAXLENGTH.branch
									: ACCDOC_TAG_MAXLENGTH.project
							}
							disabled={this.props.tag && this.props.tag.name && this.props.tag.code}
							fullWidth
							name="code"
						/>
					</div>
				</div>
			</Dialog>
		)
	}
}

function mapStateToProps(state: AppState): StateProps {
	return {
		branches: getOrganizationBranches(state),
		projects: getOrganizationProjects(state),
	}
}

export default connect(mapStateToProps)(withTranslate(validate()(TagEditDialog)))
