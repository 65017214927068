/* @flow */

import { connect } from 'react-redux'
import type { Dispatch } from 'types'
import { type OrganizationAction } from 'types'
import MobileAppInvitationToggle from '../components/mobile-app-invitation-toggle'
import { removeFavoriteAlreadySeen } from '../../organization/actions/favorites'

type DispatchProps = {|
	onClick: () => void,
|}

const mapDispatchToProps = (dispatch: Dispatch<OrganizationAction>): DispatchProps => {
	return {
		onClick: () => {
			dispatch(removeFavoriteAlreadySeen('mobile_beta_popup'))
		},
	}
}

export default connect(undefined, mapDispatchToProps)(MobileAppInvitationToggle)
