/* @flow */

import type {
	AccountingDocumentDefaults,
	AccountingDocumentDirection,
	AccountingDocumentReduxAction,
	AccountingDocumentType,
	Dispatch,
	SettingsAction,
	State,
	VatRate,
} from 'types'
import { editAccountingDocumentDefaults, loadAccountingDocumentDefaults } from '../actions'
import Defaults from 'modules/settings/components/accounting/accounting-document-defaults'
import type { DefaultsType } from '../reducers'
import { connect } from 'react-redux'
import { getDefaultVatRates } from 'modules/accounting-document/selectors'
import { getIsOrganizationVatFree } from 'modules/settings/selectors'

export type OwnProps = {|
	direction: AccountingDocumentDirection,
	readOnly?: boolean,
	open: boolean,
	onOpen: () => void,
	onClose: () => void,
|}

type StateProps = {|
	loading: boolean,
	defaults: DefaultsType,
	vatRates: {
		[direction: AccountingDocumentDirection]: Array<VatRate>,
	},
	isOrganizationVatFree: boolean,
|}

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => {
	return {
		loading:
			state.settings.accountingDocumentDefaults[ownProps.direction] &&
			!!state.settings.accountingDocumentDefaults[ownProps.direction].loading,
		defaults: state.settings.accountingDocumentDefaults,
		vatRates: {
			issued: getDefaultVatRates(state, 1),
			received: getDefaultVatRates(state, 0),
		},
		isOrganizationVatFree: getIsOrganizationVatFree(state),
	}
}

type DispatchProps = {|
	loadAccountingDocumentDefaults: (
		accountingDocumentType: ?AccountingDocumentType,
		accountingDocumentDirection: ?AccountingDocumentDirection,
	) => void,
	editAccountingDocumentDefaults: (
		oldDefaults: ?AccountingDocumentDefaults,
		newDefaults: AccountingDocumentDefaults,
		direction: ?AccountingDocumentDirection,
		type: ?AccountingDocumentType,
	) => Promise<any>,
|}

function mapDispatchToProps(dispatch: Dispatch<SettingsAction | AccountingDocumentReduxAction>): DispatchProps {
	return {
		loadAccountingDocumentDefaults: (
			accountingDocumentType: ?AccountingDocumentType,
			accountingDocumentDirection: ?AccountingDocumentDirection,
		) => {
			dispatch(loadAccountingDocumentDefaults(accountingDocumentType, accountingDocumentDirection))
		},
		editAccountingDocumentDefaults: async (
			oldDefaults: ?AccountingDocumentDefaults,
			newDefaults: AccountingDocumentDefaults,
			direction: ?AccountingDocumentDirection,
			type: ?AccountingDocumentType,
		) => {
			await dispatch(editAccountingDocumentDefaults(oldDefaults, newDefaults, direction, type))
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Defaults)
