/* @flow */

import { connect } from 'react-redux'
import type { FormFieldErrorContext, FormFieldErrorMessage, State } from 'types'
import { getFormFieldErrors } from 'modules/common/selectors'
import Name from '../../components/invoice-elements/name'
import { removeError } from 'helpers'

type OwnProps = {|
	title: string,
	placeholder: string,
	formFieldErrorContext?: FormFieldErrorContext,
	onChange: (title: ?string) => void,
|}

type StateProps = {|
	errors: ?Array<FormFieldErrorMessage>,
|}

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => {
	return {
		errors: ownProps.formFieldErrorContext && getFormFieldErrors(state, ownProps.formFieldErrorContext),
	}
}

type DispatchProps = {|
	removeError?: () => void,
|}

const mapDispatchToProps = (dispatch: Dispatch<any>, ownProps: OwnProps): DispatchProps => {
	return {
		removeError: () => {
			void removeError({
				formFieldErrorContext: ownProps.formFieldErrorContext,
				dispatch,
			})
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Name)
