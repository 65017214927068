/* @flow */

import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import type {
	State,
	Dispatch,
	CashRegister,
	CashRegisterInventory,
	CashRegisterInventories,
	CashRegisterAction,
} from 'types'
import type { WithNotifyProps, WithTranslateProps } from 'wrappers'
import { withNotify, withTranslate } from 'wrappers'
import { getMakeInventorySaving } from '../selectors/index'
import { loadCashRegister, loadInventories, makeInventory, changeMakeInventory } from '../actions/index'
import { formatDateToIsoString, formatToDateString } from 'utils/formatters'
import type { CashRegisterState } from '../reducers'
import CashRegisterMakeInventory from '../components/cash-register-make-inventory'
import type { Props as CashRegisterMakeInventoryProps } from '../components/cash-register-make-inventory'
import { cashRegisterRoute } from 'modules/cash-register/routing/routes'

type Props = {|
	...WithNotifyProps,
	...WithTranslateProps,
	id: string,
|}

type StateProps = {
	date: ?Date,
	cashRegister: ?CashRegister,
	inventories: ?CashRegisterInventories,
	loading: boolean,
	saving: boolean,
}

const mapStateToProps = (state: State, ownProps: Props): StateProps => {
	const cashRegister: ?CashRegisterState = state.cashRegister.cashRegister.find(
		(cashRegister: CashRegisterState): boolean => cashRegister.id === ownProps.id,
	)
	const date = state.cashRegister.makeInventory.date
	const cashRegisterLoading = cashRegister && cashRegister.loading
	const inventories: ?CashRegisterInventories =
		state.cashRegister.inventories[ownProps.id] && state.cashRegister.inventories[ownProps.id].data
	const inventoriesLoading =
		state.cashRegister.inventories[ownProps.id] && state.cashRegister.inventories[ownProps.id].loading

	return {
		date: date,
		cashRegister: cashRegister ? cashRegister.data : undefined,
		inventories: inventories,
		loading: cashRegisterLoading || inventoriesLoading,
		saving: getMakeInventorySaving(state),
	}
}

type DispatchProps = {
	makeInventory: (inventory: CashRegisterInventory) => void,
	loadCashRegister: () => void,
	loadInventories: () => void,
	onChange: (?Date) => void,
}

const mapDispatchToProps = (dispatch: Dispatch<CashRegisterAction>, ownProps: Props): DispatchProps => {
	return {
		makeInventory: (inventory: CashRegisterInventory) => {
			const promise: Promise<CashRegisterAction> = dispatch(makeInventory(ownProps.id, inventory))
			promise.then((action: CashRegisterAction) => {
				if (action.inventory && action.inventory.id) {
					ownProps.notify(
						ownProps.t('cashRegister.inventory.requestSuccess', { date: formatToDateString(action.inventory.date) }),
						'success',
					)
					dispatch(push(cashRegisterRoute(ownProps.id)))
				}
			})
		},
		loadCashRegister: () => {
			dispatch(loadCashRegister(ownProps.id))
		},
		loadInventories: () => {
			dispatch(loadInventories(ownProps.id))
		},
		onChange: (date: ?Date) => {
			date && dispatch(changeMakeInventory(date))
		},
	}
}

const mergeProps = (stateProps: StateProps, dispatchProps: DispatchProps): CashRegisterMakeInventoryProps => {
	return {
		date: stateProps.date,
		cashRegister: stateProps.cashRegister,
		inventories: stateProps.inventories,
		loading: stateProps.loading,
		makeInventory: () => dispatchProps.makeInventory({ date: formatDateToIsoString(stateProps.date || new Date()) }),
		loadCashRegister: dispatchProps.loadCashRegister,
		loadInventories: dispatchProps.loadInventories,
		onChange: dispatchProps.onChange,
		saving: stateProps.saving,
	}
}

export default withTranslate(
	withNotify(connect(mapStateToProps, mapDispatchToProps, mergeProps)(CashRegisterMakeInventory)),
)
