/* @flow */

import React, { Component } from 'react'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import { FilterTextField } from 'modules/data-grid/components/data-grid-filter'
import { BasicGridFilter } from 'components'
import styles from './organization-advanced-filter.css'
import type { Filter } from 'types'

type Props = {|
	...WithTranslateProps,
	onOpen?: () => void,
	onClose?: () => void,
	filterName: string,
	defaultFilter?: Filter,
|}

class OrganizationAdvancedFilter extends Component<Props> {
	render() {
		const { t } = this.props

		return (
			<BasicGridFilter
				onOpen={this.props.onOpen}
				onClose={this.props.onClose}
				name={this.props.filterName}
				textFilterName="searchText"
				dataType={'organizations'}
				gridId={this.props.filterName}
				noFilterLabel={t('user.organizationSelect.allOrganizations')}
				defaultFilter={this.props.defaultFilter}
			>
				<FilterTextField
					className={styles.column3}
					name="firstName"
					labelText={t('addressBook.grid.filter.firstName')}
				/>
				<FilterTextField className={styles.column3} name="lastName" labelText={t('addressBook.grid.filter.lastName')} />
				<FilterTextField className={styles.column3} name="name" labelText={t('addressBook.grid.filter.companyName')} />
			</BasicGridFilter>
		)
	}
}

export default withTranslate(OrganizationAdvancedFilter)
