/* @flow */
/** @jsx jsx */
/* eslint-disable react/no-multi-comp */

import moize from 'moize'
import { jsx } from '@emotion/core'
import type { AccountingDocument } from 'types'
import { type WithTranslateProps } from 'wrappers'
import AccountingDocumentGridTotalColumn from '../accounting-document-list/grid-total-column'
import { Column } from 'modules/data-grid-next/components'
import DirectionComponent from 'components/direction'
import type { Direction } from 'modules/data-grid-next/types'
import { formatToMoney } from 'utils/formatters'
import { DATA_GRID_CACHE_SIZE } from 'trivi-constants'

export type Props = {|
	width?: number | string,
	type?: 'simple' | 'standard',
	offsetCashRegisterDocuments?: boolean,
	defaultSortDirection?: Direction,
	...WithTranslateProps,
|}

export default function getTotalColumn(props: Props) {
	const isSimple = 'simple' === props.type

	function columnRenderer(value: string, row: AccountingDocument) {
		const isUnknown = 2 === row.direction

		if (isSimple) {
			return (
				<div css={styles.simple}>
					<span css={styles.amount}>
						{isUnknown
							? props.t('accountingDocument.states.notExtracted')
							: formatToMoney(row.total, { currency: row.currency })}
					</span>
					<div css={styles.icon}>
						<DirectionComponent size={20} issued={0 === row.direction} received={1 === row.direction} transparent />
					</div>
				</div>
			)
		} else {
			return (
				<AccountingDocumentGridTotalColumn
					showTotalToPay
					accountingDocument={row}
					offsetCashRegisterDocuments={props.offsetCashRegisterDocuments}
				/>
			)
		}
	}

	const columnRendererCashed = moize(columnRenderer, { maxSize: DATA_GRID_CACHE_SIZE })

	return (
		<Column
			title={props.t('accountingDocument.columns.total')}
			width={props.width}
			columnId="total"
			key="total"
			render={columnRendererCashed}
			sortable
			defaultSortDirection={props.defaultSortDirection || undefined}
			headerStyle={styles.header}
		/>
	)
}

const styles = {
	header: {
		paddingRight: 50,
		textAlign: 'right',
		paddingLeft: 0,
	},
	amount: { marginRight: 10 },
	icon: { width: 20, flex: '0 0 20px' },
	simple: {
		textAlign: 'right',
		fontWeight: 600,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'right',
	},
}
