/* @flow */

import type {
	AccountingDocument,
	AccountingDocumentDirection,
	AccountingDocumentLanguage,
	AccountingDocumentLineItem,
	AccountingDocumentReduxAction,
	AccountingDocumentType,
	Dispatch,
	IssueAction,
	State,
	GreenboxSuggestionResponse,
	FinancialAccount,
	OrganizationAction,
	FormFieldErrorContext,
} from 'types'
import {
	addAccountingDocumentLineItem,
	loadAccountingDocumentVatRecapInfo,
	removeAccountingDocumentLineItem,
	updateAccountingDocumentLineItem,
	updateAccountingDocumentVatRecapCalculationMode,
	loadAccDocGreenboxSuggestion,
	createAccountingDocumentLineItem,
} from 'modules/accounting-document/actions'
import { loadFinancialAccounts } from 'modules/organization/actions'
import { beginTask, endTask } from 'utils/loader'
import {
	getAccountingDocument,
	getAccountingDocumentCurrency,
	getAccountingDocumentLanguage,
	getAccountingDocumentLineItems,
	isAccountingDocumentVatFree,
	getAccDocGreenboxSuggestion,
	getCalculatingLineItemIds,
} from 'modules/accounting-document/selectors'
import { getFinancialAccounts } from 'modules/organization/selectors'
import type { AccountingDocumentItem } from 'modules/accounting-document/reducers/accounting-documents'
import LineItems from '../invoice-elements/line-items'
import { connect } from 'react-redux'
import withAutoload from 'wrappers/with-autoload'
import { generateKeyFromFinancialAccountsGetParams } from 'modules/organization/utils'
import { AccountingDocumentType_Number, AccountingDocumentDirection_Number } from 'types/convertor'
import type { ValidationMessageType, ValidationValueType } from 'wrappers/validate'
import { EMPTY_ARRAY } from 'trivi-constants'

export type OwnProps = {|
	public?: boolean,
	readonly?: boolean,
	extracting?: boolean,
	accountingDocumentId: string,
	direction: AccountingDocumentDirection,
	type: AccountingDocumentType,
	validationMessage?: ValidationMessageType,
	validationValue?: ValidationValueType,
	formFieldErrorContext?: FormFieldErrorContext,
	disabledFinAccount?: boolean,
	documentState?: string,
|}

type StateProps = {|
	items: Array<AccountingDocumentLineItem>,
	calculatingLineItemIds: Array<string>,
	currency?: string,
	language: ?AccountingDocumentLanguage,
	accountingDocument: ?AccountingDocument,
	lineItemsProcessing: boolean,
	addingLineItem?: boolean,
	isAccDocVatFree?: boolean,
	greenboxSuggestion: ?GreenboxSuggestionResponse,
	financialAccounts: Array<FinancialAccount>,
|}
function mapStateToProps(state: State, ownProps: OwnProps): StateProps {
	const accountingDocument: ?AccountingDocument = getAccountingDocument(state, ownProps.accountingDocumentId)

	const docItem: ?AccountingDocumentItem =
		state.accountingDocument.accountingDocuments.items[ownProps.accountingDocumentId]

	const financialAccountsKey = generateKeyFromFinancialAccountsGetParams({
		accountingDocumentType: AccountingDocumentType_Number(ownProps.type),
		accountingDocumentDirection: AccountingDocumentDirection_Number(ownProps.direction),
	})

	return {
		accountingDocument,
		items: getAccountingDocumentLineItems(state, ownProps.accountingDocumentId),
		calculatingLineItemIds: getCalculatingLineItemIds(state, ownProps.accountingDocumentId),
		currency: getAccountingDocumentCurrency(state, ownProps.accountingDocumentId) || undefined,
		language: getAccountingDocumentLanguage(state, ownProps.accountingDocumentId),
		lineItemsProcessing: docItem ? docItem.lineItemsProcessing : false,
		addingLineItem: docItem ? docItem.addingLineItem : false,
		isAccDocVatFree: isAccountingDocumentVatFree(state, ownProps.accountingDocumentId),
		greenboxSuggestion: getAccDocGreenboxSuggestion(state, ownProps.accountingDocumentId),
		financialAccounts: getFinancialAccounts(state, financialAccountsKey) || EMPTY_ARRAY,
	}
}

type DispatchProps = {|
	// onChange: (items: Array<AccountingDocumentLineItem>) => void,
	onLineItemCreate: () => Promise<void>,
	onLineItemRemove: (lineItem: AccountingDocumentLineItem, index: number) => void,
	onLineItemClone: (lineItem: AccountingDocumentLineItem) => void,
	onLineItemChange: (
		lineItem: AccountingDocumentLineItem,
		autocalc: boolean,
		index: number,
		accountingDocument: ?AccountingDocument,
	) => Promise<any>,
	autoload: () => void,
|}

const mapDispatchToProps = (
	dispatch: Dispatch<AccountingDocumentReduxAction | IssueAction | OrganizationAction>,
	ownProps: OwnProps,
): DispatchProps => {
	const isEditPage = window.location.href.split('/').pop() == 'edit'

	return {
		onLineItemCreate: async () => {
			const loaderId = 'lineItems_onLineItemCreate'
			const id: string = ownProps.accountingDocumentId
			beginTask(loaderId)
			await dispatch(createAccountingDocumentLineItem(id))
				.then(() => {
					dispatch(loadAccountingDocumentVatRecapInfo(id))
				})
				.finally(() => endTask(loaderId))
		},
		onLineItemRemove: (lineItem: AccountingDocumentLineItem) => {
			const id: string = ownProps.accountingDocumentId
			dispatch(removeAccountingDocumentLineItem(id, lineItem.id || '')).then(() => {
				dispatch(loadAccountingDocumentVatRecapInfo(id))
			})
		},
		onLineItemClone: (lineItem: AccountingDocumentLineItem) => {
			const loaderId = 'lineItems_onLineItemClone'
			const documentId: string = ownProps.accountingDocumentId
			beginTask(loaderId)
			dispatch(addAccountingDocumentLineItem(documentId, lineItem))
				.then(() => {
					dispatch(loadAccountingDocumentVatRecapInfo(documentId))
				})
				.finally(() => endTask(loaderId))
		},
		onLineItemChange: async (
			lineItem: AccountingDocumentLineItem,
			autocalc: boolean,
			index: number,
			accountingDocument: ?AccountingDocument,
		) => {
			if (!accountingDocument) {
				return
			}
			if (ownProps.documentState !== 'Extracting' && isEditPage) {
				dispatch(loadAccDocGreenboxSuggestion(ownProps.accountingDocumentId))
			}

			const changeVatRecapCalculationMode: boolean =
				index === 0 &&
				accountingDocument.vatRecapCalculationMode !== 2 &&
				!(
					lineItem.hasOwnProperty('unitPriceVatExcl') &&
					lineItem.hasOwnProperty('unitPrice') &&
					lineItem.hasOwnProperty('totalVatExcl') &&
					lineItem.hasOwnProperty('total')
				)
			const id: string = ownProps.accountingDocumentId

			const loaderId = 'lineItems_onLineItemChange'
			autocalc && beginTask(loaderId)

			await dispatch(updateAccountingDocumentLineItem(id, lineItem, autocalc)).finally(() => {
				autocalc && endTask(loaderId)
			})

			if (changeVatRecapCalculationMode) {
				const mode = lineItem.hasOwnProperty('unitPriceVatExcl') || lineItem.hasOwnProperty('totalVatExcl') ? 0 : 1
				if (mode !== accountingDocument.vatRecapCalculationMode) {
					await dispatch(updateAccountingDocumentVatRecapCalculationMode(ownProps.accountingDocumentId, mode))
				}
			}
			await dispatch(loadAccountingDocumentVatRecapInfo(id))
		},
		autoload: () => {
			if (!ownProps.public && ownProps.documentState !== 'Extracting' && (isEditPage || ownProps.readonly)) {
				dispatch(loadAccDocGreenboxSuggestion(ownProps.accountingDocumentId))

				dispatch(
					loadFinancialAccounts({
						accountingDocumentType: AccountingDocumentType_Number(ownProps.type),
						accountingDocumentDirection: AccountingDocumentDirection_Number(ownProps.direction),
					}),
				)
			}
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(withAutoload(LineItems))
