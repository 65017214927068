//@flow
/** @jsx jsx */

import { jsx } from '@emotion/core'
import { Component } from 'react'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import Button from 'components/button'
import Toggle from 'components/toggle'
import DeleteDialog from 'components/delete-dialog'

type Props = {|
	...WithTranslateProps,
	currentUserHasInternalFullAccess: boolean,
	isCashbotEnabled: boolean,
	isCashbotAuthenticated: boolean,
	toggleCashbotEnabled: (enabled: boolean) => void,
	loginToCashbot: () => Promise<void>,
	logoutFromCashbot: () => Promise<void>,
|}

type State = {|
	isAuthenticating: boolean,
|}

class CashbotSettings extends Component<Props, State> {
	//
	state: State = {
		isAuthenticating: false,
	}

	onToggleCashbot = (event: SyntheticEvent<HTMLInputElement>, isInputChecked: boolean) => {
		this.props.toggleCashbotEnabled(isInputChecked)
	}

	onLogoutClick = async () => {
		DeleteDialog({
			title: this.props.t('cashbot.logoutDialog.title'),
			okLabel: this.props.t('cashbot.logoutDialog.ok'),
			cancelLabel: this.props.t('cashbot.logoutDialog.cancel'),
			// body: 'BODY EHM',
		}).then(async () => {
			this.setState({ isAuthenticating: true })
			await this.props.logoutFromCashbot()
			this.setState({ isAuthenticating: false })
		})
	}

	onLoginClick = async () => {
		this.setState({ isAuthenticating: true })
		const res = await this.props.loginToCashbot()
		if (res && res.serverError) {
			// Pouze při chybě znovu aktivovat tlačítko,
			// při úspěchu by uživatel mohl stihnout stisknout tlačítko znovu, než dojde k přesměrování
			this.setState({ isAuthenticating: false })
		}
	}

	renderButton = () => {
		if (!this.props.isCashbotEnabled) {
			return null
		}
		if (this.props.isCashbotAuthenticated) {
			return (
				<Button
					danger
					disabled={this.state.isAuthenticating}
					labelText={this.props.t('cashbot.logout')}
					onClick={this.onLogoutClick}
					autoTestId="cashbot-logout"
				/>
			)
		}
		return (
			<Button
				primary
				disabled={this.state.isAuthenticating}
				labelText={this.props.t('cashbot.login')}
				onClick={this.onLoginClick}
				autoTestId="cashbot-login"
			/>
		)
	}

	render() {
		return (
			<div>
				{this.props.currentUserHasInternalFullAccess && (
					<div css={style.toggle}>
						<Toggle
							autoTestId="cashbot-is-enabled"
							toggled={this.props.isCashbotEnabled}
							onToggle={this.onToggleCashbot}
							label={this.props.t('cashbot.use')}
							labelPosition={'right'}
						/>
					</div>
				)}
				{this.renderButton()}
			</div>
		)
	}
}

const style = {
	toggle: {
		marginBottom: 30,
	},
}

export default withTranslate(CashbotSettings)
