// @flow

import memoize from 'memoize-one'
import moment from 'moment'
import type { State, AccountingDocumentDirection, AccountingDocumentType, Sequence, SequencesData } from 'types'
import {
	NON_CASH_REGISTER_ACCOUNTING_DOCUMENT_TYPES,
	CASH_REGISTER_ACCOUNTING_DOCUMENT_TYPES,
	ACCOUNTING_DOCUMENT_ASSIGNED_DIRECTION_TYPES,
} from 'modules/accounting-document/constants'
import { EMPTY_ARRAY } from 'trivi-constants'
import { curry } from 'lodash-es'

export function getAccountingDocumentSequencesData(state: State): SequencesData {
	return state.accountingDocument.sequences.data
}

export function getSequencesData(state: State): SequencesData {
	return state.accountingDocument.sequences.data
}

const isSequenceValidForDate = curry((validityDate?: Date, sequence: Sequence): boolean => {
	const date = validityDate || new Date()
	if (!sequence.validFrom && sequence.validTo) return moment(sequence.validTo).isSameOrAfter(date, 'day')
	if (!sequence.validTo && sequence.validFrom) return moment(sequence.validFrom).isSameOrBefore(date, 'day')
	if (sequence.validTo && sequence.validFrom)
		return moment(date).isBetween(sequence.validFrom, sequence.validTo, 'day', '[]')
	return true
})

export function getAccountingDocumentSequences(
	state: State,
	direction: ?AccountingDocumentDirection,
	type: ?AccountingDocumentType,
	cashRegisterId: ?string,
	validityDate?: Date,
	useSimplifiedNumbering?: boolean,
): Array<Sequence> {
	const {
		sequences: { loading, data },
	} = state.accountingDocument
	if (loading) {
		return EMPTY_ARRAY
	}

	// if simplified numbering is enabled, search only sequences from `simplified_invoice`
	const resultType = useSimplifiedNumbering ? 'simplified_invoice' : type

	if (!direction && !resultType) {
		return _getSequencesForAllDirectionsAndTypes(cashRegisterId || 'unknown', data, validityDate)
	}
	if (!resultType && direction) {
		return _getSequenciesForAllTypesInDirection(direction, cashRegisterId || 'unknown', data, validityDate)
	}
	if (!direction && resultType) {
		return _getSequenciesForTypeInAllDirections(resultType, cashRegisterId || 'unknown', data, validityDate)
	}
	if (direction && resultType) {
		return _getSequenciesForTypeAndDirection(resultType, direction, cashRegisterId || 'unknown', data, validityDate)
	}
	return EMPTY_ARRAY
}

export const _getSequencesForAllDirectionsAndTypes = memoize(
	(cashRegisterId: string, sequencesData: SequencesData, validityDate?: Date): Array<Sequence> => {
		const isSequenceValid = isSequenceValidForDate(validityDate)
		const types = [...CASH_REGISTER_ACCOUNTING_DOCUMENT_TYPES, ...NON_CASH_REGISTER_ACCOUNTING_DOCUMENT_TYPES]
		let sequencies = []
		ACCOUNTING_DOCUMENT_ASSIGNED_DIRECTION_TYPES.forEach((direction: AccountingDocumentDirection) => {
			types.forEach((type: AccountingDocumentType) => {
				const foundSequencies = filterSequencesByDirectionAndType(sequencesData, type, direction, cashRegisterId)
				sequencies = [...sequencies, ...foundSequencies]
			})
		})
		return validityDate ? sequencies.filter(isSequenceValid) : sequencies
	},
)

export const _getSequenciesForAllTypesInDirection = memoize(
	(
		direction: AccountingDocumentDirection,
		cashRegisterId: string,
		sequencesData: SequencesData,
		validityDate?: Date,
	): Array<Sequence> => {
		const isSequenceValid = isSequenceValidForDate(validityDate)
		const types = [...CASH_REGISTER_ACCOUNTING_DOCUMENT_TYPES, ...NON_CASH_REGISTER_ACCOUNTING_DOCUMENT_TYPES]
		let sequencies = []
		types.forEach((type: AccountingDocumentType) => {
			const foundSequencies = filterSequencesByDirectionAndType(sequencesData, type, direction, cashRegisterId)
			sequencies = [...sequencies, ...foundSequencies]
		})
		return validityDate ? sequencies.filter(isSequenceValid) : sequencies
	},
)

export const _getSequenciesForTypeInAllDirections = memoize(
	(
		type: AccountingDocumentType,
		cashRegisterId: string,
		sequencesData: SequencesData,
		validityDate?: Date,
	): Array<Sequence> => {
		let sequencies = []
		const isSequenceValid = isSequenceValidForDate(validityDate)
		ACCOUNTING_DOCUMENT_ASSIGNED_DIRECTION_TYPES.forEach((direction: AccountingDocumentDirection) => {
			const foundSequencies = filterSequencesByDirectionAndType(sequencesData, type, direction, cashRegisterId)
			sequencies = [...sequencies, ...foundSequencies]
		})
		return validityDate ? sequencies.filter(isSequenceValid) : sequencies
	},
)

export const _getSequenciesForTypeAndDirection = memoize(
	(
		type: AccountingDocumentType,
		direction: AccountingDocumentDirection,
		cashRegisterId: string,
		sequencesData: SequencesData,
		validityDate?: Date,
	): Array<Sequence> => {
		const isSequenceValid = isSequenceValidForDate(validityDate)
		let sequencies = filterSequencesByDirectionAndType(sequencesData, type, direction, cashRegisterId)
		return validityDate ? sequencies.filter(isSequenceValid) : sequencies
	},
)

function filterSequencesByDirectionAndType(
	sequencesData: SequencesData,
	type: AccountingDocumentType,
	direction: AccountingDocumentDirection,
	cashRegisterId: string,
): Array<Sequence> {
	if (sequencesData[direction][type] && sequencesData[direction][type][cashRegisterId]) {
		return sequencesData[direction][type][cashRegisterId]
	}
	return EMPTY_ARRAY
}
