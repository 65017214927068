/* @flow */

export function unmatchedPaymentsRoute(organizationId: string) {
	return `/${organizationId}/dashboard/todo/bank`
}

export function unmatchedPaymentsWaitingRoute(organizationId: string) {
	return `/${organizationId}/dashboard/todo/bank/waiting`
}

export function unmatchedPaymentsProcessingRoute(organizationId: string) {
	return `/${organizationId}/dashboard/todo/bank/processing`
}

export function matchedPaymentsRoute(organizationId: string) {
	return `/${organizationId}/dashboard/todo/bank/done`
}
