//@flow

export function hideSplashscreen() {
	// HIDE_SPLASHSCREEN is a global function
	// imported in "src/index.html" from "/assets/splash-screen/index.js"
	// (JZ)
	// $FlowFixMe
	HIDE_SPLASHSCREEN() //eslint-disable-line no-undef
}

export function showSplashscreen() {
	// SHOW_SPLASHSCREEN is a global function
	// imported in "src/index.html" from "/assets/splash-screen/index.js"
	// (JZ)
	// $FlowFixMe
	SHOW_SPLASHSCREEN() //eslint-disable-line no-undef
}
