// @flow

import React, { Component } from 'react'
import { Spring, animated } from 'react-spring'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import { PERSIST_STATE_MULTI_ACTION_TYPES, MULTI_ACTION_TYPE_CLEAR_SELECTION } from 'trivi-constants'
import type { Action } from '../types'
import type { MultiAction, EnumItem } from 'types'
import { Number_PaymentType } from 'types/convertor'
import Button from 'components/button'
import IconButton from 'components/icon-button'
import Paper from 'components/Paper'
import Delete from 'components/svg-icons/action/delete'
import ChainIcon from 'components/svg-icons/trivi/chain'
import ActionLinkOff from 'components/svg-icons/action/link-off'
import NavigationSubdirectoryArrowRight from 'components/svg-icons/navigation/subdirectory-arrow-right'
import SelectNext, { type OptionType } from 'components/select-next'
import styles from './multi-actions-toolbar.css'
import { colors } from 'variables'

type Props<Item> = {|
	...WithTranslateProps,
	actions: Array<Action<Array<Item>>>,
	selectedItems: Array<Item>,
	visibleMultiActions: Array<string>,
	visible?: ?boolean,
	onMultiAction: (action: MultiAction, items: Array<any>, clearSelection: ?boolean) => Promise<?MultiAction>,
|}

class MultiActionsToolbar<Item> extends Component<Props<Item>> {
	handleDeleteFiles = () => {
		this.handleMultiAction('remove', this.props.selectedItems)
	}

	handleJoinScans = () => {
		this.handleMultiAction('accdoc_join', this.props.selectedItems)
	}

	handleUnjoinScans = () => {
		this.handleMultiAction('accdoc_unjoin', this.props.selectedItems)
	}

	handleMoveFiles = () => {
		this.handleMultiAction('accdoc_move', this.props.selectedItems)
	}

	handleMultiAction = (action: MultiAction, selectedItems: Array<any>) => {
		const clearSelection = action === MULTI_ACTION_TYPE_CLEAR_SELECTION ? true : !this.shouldPersistSelection(action)
		this.props.onMultiAction(action, selectedItems, clearSelection)
	}

	shouldPersistSelection = (action: string): boolean => {
		return PERSIST_STATE_MULTI_ACTION_TYPES.some((item: string) => item === action)
	}

	getCommonValueFromSelected = (key: ?string, items: ?Array<any>) => {
		if (!key || !items || !items.length) return null
		return (
			items &&
			items.reduce((result: ?string, current: any) => {
				return current[key] === result ? result : null
			}, items[0][key])
		)
	}

	render() {
		const { t } = this.props
		return (
			<Spring native from={{ height: 0 }} to={{ height: this.props.visible ? 'auto' : 0 }}>
				{(props: Object) => (
					<animated.div className={styles.root} style={props}>
						<Paper rounded zDepth={3} className={styles.toolbar} style={{ borderWidth: 2, borderColor: colors.blue }}>
							<div className={styles.counter}>
								<div>
									<span className={styles.counterNumber}>{this.props.selectedItems.length}</span>
									<br />
									{t('accountingDocument.multiActions.generic.chosen')}
								</div>
							</div>
							<div className={styles.toolbarItems}>
								{this.props.actions.reduce((result: Array<*>, action: Action<Array<Item>>) => {
									if (
										this.props.visibleMultiActions.indexOf(action.type) === -1 ||
										action.type === 'remove' ||
										action.type === 'accdoc_join' ||
										action.type === 'accdoc_move'
									) {
										return result
									}

									if (action.items != null) {
										let onChange = (option: ?OptionType) => {
											const value = option ? option.value : null
											if (action.onAction != null) {
												action.onAction(value || null, this.props.selectedItems)
												if (!this.shouldPersistSelection(action.type)) {
													this.handleMultiAction(MULTI_ACTION_TYPE_CLEAR_SELECTION, this.props.selectedItems)
												}
											}
										}

										if (action.items.length > 0) {
											const isPamyentType = 'change_paymenttype' === action.type
											const isCleareableAndSearchable =
												action.type === 'change_category' ||
												action.type === 'change_branch' ||
												action.type === 'change_project'
											const value = this.getCommonValueFromSelected(action.key, this.props.selectedItems)

											result.push(
												<div className={styles.selectField} key={action.type} id="testid">
													<SelectNext
														inline
														portal
														autoWidth
														onChange={onChange}
														label={action.hintText}
														isClearable={isCleareableAndSearchable}
														isSearchable={isCleareableAndSearchable}
														autoTestId={`multi-action-toolbar-${action.type}`}
														value={
															isPamyentType && value
																? t(
																		`accountingDocument.multiActions.paymentTypes.${
																			value ? Number_PaymentType(value) : 'unknown'
																		}`,
																  )
																: value
														}
														options={
															action.items &&
															action.items.map((item: EnumItem) => {
																const text = isPamyentType
																	? t(`accountingDocument.multiActions.paymentTypes.${item.value || ''}`)
																	: item.value
																return { value: item.key, label: text }
															})
														}
													/>
												</div>,
											)
										}
										return result
									} else
										result.push(
											<div className={styles.toolbarButtonItem} key={action.type}>
												<Button
													autoTestId={`multi-action-toolbar-${action.type}`}
													labelText={action.name}
													transparent
													onClick={() => this.handleMultiAction(action.type, this.props.selectedItems)} //eslint-disable-line react/jsx-no-bind
												/>
											</div>,
										)

									return result
								}, [])}
							</div>
							<div className={styles.toolbarActions}>
								{this.props.visibleMultiActions.indexOf('accdoc_move') !== -1 && (
									<div className={styles.toolbarActionItem}>
										<IconButton
											autoTestId="multi-actions-toolbar-move"
											tooltip={t('accountingDocument.multiActions.move')}
											onClick={this.handleMoveFiles}
											style={style.iconButton}
											circledWhenHovered
											size={34}
										>
											<NavigationSubdirectoryArrowRight />
										</IconButton>
									</div>
								)}
								{this.props.visibleMultiActions.indexOf('accdoc_join') !== -1 && (
									<div className={styles.toolbarActionItem}>
										<IconButton
											autoTestId="multi-actions-toolbar-join"
											tooltip={t('accountingDocument.multiActions.accdocJoin')}
											onClick={this.handleJoinScans}
											style={style.iconButton}
											circledWhenHovered
											size={34}
										>
											<ChainIcon />
										</IconButton>
									</div>
								)}
								{this.props.visibleMultiActions.indexOf('accdoc_unjoin') !== -1 && (
									<div className={styles.toolbarActionItem}>
										<IconButton
											autoTestId="multi-actions-toolbar-unjoin"
											tooltip={t('accountingDocument.multiActions.accdocUnjoin')}
											onClick={this.handleUnjoinScans}
											style={style.iconButton}
											circledWhenHovered
											size={34}
										>
											<ActionLinkOff size={20} />
										</IconButton>
									</div>
								)}
								{this.props.visibleMultiActions.indexOf('remove') !== -1 && (
									<div className={styles.toolbarActionItem}>
										<IconButton
											autoTestId="multi-actions-toolbar-delete"
											tooltip={t('grid.action.delete')}
											disabled={this.props.selectedItems.length < 1}
											onClick={this.handleDeleteFiles}
											style={style.iconButton}
											circledWhenHovered
											size={34}
										>
											<Delete size={22} />
										</IconButton>
									</div>
								)}
							</div>
						</Paper>
					</animated.div>
				)}
			</Spring>
		)
	}
}

const style = {
	iconButton: {
		verticalAlign: 'middle',
	},
}

export default withTranslate(MultiActionsToolbar)
