/* @flow */
/** @jsx jsx */

import { Component } from 'react'
import { jsx } from '@emotion/core'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import { ACCOUNTING_DOCUMENT_TITLES } from '../constants'
import PopupSection from 'components/PopupSection'
import Paper from 'components/Paper'
import Direction from 'components/direction'
import invoice from 'assets/images/loader-invoice.svg'
import cashRegister from 'assets/images/loader-cash-register-document.svg'
import settings from 'assets/images/loader-settings.svg'
import classification from 'assets/images/loader-classification.svg'

type Props = {|
	...WithTranslateProps,
	type: string,
	direction: string,
	isCreating: boolean,
	navigateToMainScreen: () => void,
|}

class AccountingDocumentLoader extends Component<Props> {
	componentWillMount() {
		!this.props.isCreating && this.props.navigateToMainScreen()
	}

	isCashRegisterDocument = (type: string) => {
		return (
			'cash_receipt' === type ||
			'cash_transfer' === type ||
			'employee_advance' === type ||
			'cash_uncategorized' === type ||
			'simplified_invoice' === type
		)
	}

	renderCashRegisterDocument() {
		return (
			<PopupSection open notClosable>
				<div css={styles.root}>
					<div css={styles.container}>
						{this.renderHeader()}
						<Paper zDepth={3} rounded style={styles.paper}>
							<img src={cashRegister} css={styles.mainLoader} />
						</Paper>
					</div>
				</div>
			</PopupSection>
		)
	}

	renderAccountingDocument() {
		return (
			<div css={styles.root}>
				<div css={styles.container}>
					{this.renderHeader()}
					<img src={classification} css={styles.classification} />
					<Paper zDepth={3} rounded style={styles.paper}>
						<img src={invoice} css={styles.mainLoader} />
					</Paper>
				</div>
				<div css={styles.menu}>
					{this.props.t('invoice.settings.main.headline')}
					<img src={settings} css={styles.settings} />
				</div>
			</div>
		)
	}

	renderHeader() {
		return (
			<div css={styles.header}>
				<div css={styles.headline}>
					{this.props.t(ACCOUNTING_DOCUMENT_TITLES[this.props.direction][this.props.type])}
				</div>
				<div css={styles.direction}>
					<Direction
						showLabel
						issued={'issued' === this.props.direction}
						received={'received' === this.props.direction}
						isCashRegister={this.isCashRegisterDocument(this.props.type)}
					/>
				</div>
			</div>
		)
	}

	render() {
		return (
			<PopupSection open notClosable>
				{this.isCashRegisterDocument(this.props.type)
					? this.renderCashRegisterDocument()
					: this.renderAccountingDocument()}
			</PopupSection>
		)
	}
}

const styles = {
	root: {
		display: 'flex',
		maxWidth: 1170,
		justifyContent: 'center',
		alignItems: 'flex-start',
		margin: '0 auto',
		paddingBottom: 50,
	},
	container: {
		flex: '0 0 770px',
	},
	header: {
		margin: '20px 0',
		minHeight: 51,
		lineHeight: '51px',
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'center',
		flexWrap: 'wrap',
	},
	headline: {
		fontSize: 24,
	},
	direction: {
		height: 50,
		marginLeft: 12,
		position: 'relative',
		top: -1,
	},
	classification: {
		marginTop: 32,
		marginBottom: 20,
		display: 'block',
		width: 768,
		height: 'auto',
	},
	paper: {
		overflow: 'hidden',
	},
	mainLoader: {
		display: 'block',
		width: 768,
		height: 'auto',
	},
	menu: {
		maxWidth: 400,
		marginTop: 90,
		paddingLeft: 30,
		paddingRight: 10,
		fontSize: 24,
		fontWeight: 'bold',
		lineHeight: '32px',
	},
	settings: {
		marginTop: 25,
		display: 'block',
		width: 360,
		height: 'auto',
	},
}

export default withTranslate(AccountingDocumentLoader)
