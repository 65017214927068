/* @flow */

import type { ApiApp, Dispatch, State } from 'types'
import {
	addOrganizationApiApp,
	editOrganizationApiApp,
	loadOrganizationApiApps,
	removeOrganizationApiApp,
} from '../actions'
import { currentUserIsInternal } from 'modules/user/selectors'
import Api from 'modules/settings/components/trivi-settings/api'
import type { Action as ApiAppsAction } from '../actions/api-apps-action-types'
import { connect } from 'react-redux'
import { userHasAccess } from 'permissions'

type StateProps = {
	currentUserIsInternal: boolean,
	apiApps: ?Array<ApiApp>,
}

const mapStateToProps = (state: State): StateProps => {
	return {
		currentUserIsInternal: currentUserIsInternal(state),
		apiApps: state.settings.apiApps,
		canSetApi: userHasAccess(state, 'setOrganizationApiApps'),
	}
}

type DispatchProps = {
	loadApiApps: () => void,
	onApiAppAdded: (name: string) => void,
	onApiAppDelete: (apiAppId: number) => void,
	onApiAppChange: (apiAppId: number, apiApp: ApiApp) => void,
}

const mapDispatchToProps = (dispatch: Dispatch<ApiAppsAction>): DispatchProps => {
	return {
		loadApiApps: () => {
			dispatch(loadOrganizationApiApps())
		},
		onApiAppAdded: (name: string) => {
			dispatch(addOrganizationApiApp(name))
		},
		onApiAppDelete: (apiAppId: number) => {
			dispatch(removeOrganizationApiApp(apiAppId))
		},
		onApiAppChange: (apiAppId: number, apiApp: ApiApp) => {
			dispatch(editOrganizationApiApp(apiAppId, apiApp))
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Api)
