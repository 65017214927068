/* @flow */

import { loadContact, findOrCreateContact } from 'modules/address-book/actions'
import { getContact } from 'modules/address-book/selectors'
import { isAresContactsLoading } from 'modules/user/selectors'
import { connect } from 'react-redux'
import type { AddressBookAction, Contact, Dispatch, State } from 'types'
import { emptyContact } from 'types/empty'
import { withAutoload } from 'wrappers'
import ContactForm, { type EditType } from '../../../components/invoice-elements/contact/contact-form'

type OwnProps = {|
	searchCompany?: ?string,
	contactId: ?string,
	onCancel: () => void,
	onSave: (contact: ?Contact) => void,
	editType: EditType,
	inline?: boolean,
|}

type StateProps = {
	contact: ?Contact,
	aresLoading: boolean,
}

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => {
	return {
		contact:
			'UPDATE' === ownProps.editType && ownProps.contactId ? getContact(state, ownProps.contactId) : emptyContact(),
		aresLoading: isAresContactsLoading(state),
	}
}

type DispatchProps = {
	onSave: (contact: ?Contact) => Promise<void>,
	autoload: () => void,
}

const mapDispatchToProps = (dispatch: Dispatch<AddressBookAction>, ownProps: OwnProps): DispatchProps => {
	return {
		onSave: async (contact: ?Contact) => {
			if (contact) {
				const action = await dispatch(findOrCreateContact(contact, { addCrdphBankAccounts: true }))
				if (['FINISH_SAVE_CONTACT', 'FINISH_CREATE_CONTACT'].includes(action.type) && !action.serverError) {
					ownProps.onSave(action.contact)
				} else {
					ownProps.onCancel()
				}
			}
		},
		autoload: () => {
			if ('UPDATE' === ownProps.editType && ownProps.contactId) {
				dispatch(loadContact(ownProps.contactId))
			}
		},
	}
}

const mergeProps = (stateProps: StateProps, dispatchProps: DispatchProps, ownProps: OwnProps) => {
	return {
		contact: stateProps.contact,
		onSave: dispatchProps.onSave,
		onCancel: ownProps.onCancel,
		editType: ownProps.editType,
		inline: ownProps.inline,
		autoload: dispatchProps.autoload,
		aresLoading: stateProps.aresLoading,
		searchCompany: ownProps.searchCompany,
	}
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(withAutoload(ContactForm))
