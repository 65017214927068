/* @flow */
/** @jsx jsx */

import React, { PureComponent, type Node } from 'react'
import { jsx } from '@emotion/core'
import ReactDOM from 'react-dom'
import keycode from 'keycode'
import { autoTestId, passAutoTestId, type AutoTestProps } from 'utils/tests/autotest'
import withTranslate, { type Props as WithTranslateProps } from 'wrappers/with-translate'
import TextFieldHint from './TextFieldHint'
import TextFieldLabel from './TextFieldLabel'
import EnhancedTextarea from './EnhancedTextarea'
import Tooltip from 'components/tooltip'
import InfoIcon from 'components/svg-icons/trivi/input/info'
import { colors } from 'variables'

export type Props = {|
	autoFocus?: boolean,
	autoComplete?: boolean,
	children?: React$Element<any>,
	inline?: boolean,
	compact?: boolean,
	disabled?: boolean,
	disableHover?: boolean,
	infoText?: ?string,
	infoTextAlign?: 'bottom-center' //eslint-disable-line
		| 'bottom-right'
		| 'center-left'
		| 'center-right'
		| 'top-center'
		| 'top-left'
		| 'top-left-center'
		| 'top-right'
		| 'top-right-center',
	infoTextAlignArrow?: 'bottom-center' | 'top-center' | 'center-left' | 'center-right',
	/**
	 * The error content to display as a tooltip.
	 */
	clientError?: Node,
	/**
	 * The error content to display under the field.
	 */
	serverError?: ?string,
	/**
	 * Create bigger margin on right side of the input to accomodate a button
	 */
	hasButton?: boolean,
	editCursor?: boolean,
	required?: boolean,
	labelText?: ?string,
	fullWidth?: boolean,
	hintStyle?: Object,
	hintText?: string,
	id?: string,
	right?: boolean,
	autoWidth?: boolean,
	autoHeight?: boolean,
	style?: Object,
	containerStyle?: Object,
	inputStyle?: Object,
	useStyle?: boolean,
	multiLine?: boolean,
	maxLength?: number,
	name?: string,
	onBlur?: (SyntheticInputEvent<HTMLInputElement>) => void,
	/**
	 * Callback function that is fired when the textfield's value changes.
	 *
	 * @param {object} event Change event targeting the text field.
	 * @param {string} newValue The new value of the text field.
	 */
	onChange?: (SyntheticInputEvent<HTMLInputElement>, ?string) => void,
	onFocus?: (SyntheticInputEvent<HTMLInputElement>) => void,
	onKeyEnter?: (SyntheticKeyboardEvent<HTMLInputElement>) => void,
	onKeyEscape?: (SyntheticKeyboardEvent<HTMLInputElement>) => void,
	onKeyDown?: (SyntheticKeyboardEvent<HTMLInputElement>) => void,
	/**
	 * Number of colls to display.
	 */
	size?: number,
	rows?: number,
	rowsMax?: number,
	type?: string,
	transparent?: boolean,
	tabIndex?: number,
	title?: string,
	value?: ?string | ?number,
	minWidth?: number,
	useKeyboardNavigation?: boolean,
	newLineTabIndex?: number,
	isInLastLineItem?: boolean,
	onLastLineEnter?: () => Promise<void>,
	nextItemTabIndex?: number,
	navigateToNextLine?: boolean,
	...AutoTestProps,
	...WithTranslateProps,
|}

type State = {|
	isFocused: boolean,
	clientValidText: ?string,
	serverValidText: ?string,
	hasValue: boolean,
	width: ?number,
	infoText: ?string,
	isValid: boolean,
	isMicrovalidationEnabled: boolean,
|}

class TextField extends PureComponent<Props, State> {
	uniqueId = ''
	input: any = null
	maxWidth = null
	autofillTimer: ?TimeoutID = null

	static defaultProps = {
		autoComplete: true,
		type: 'text',
		infoTextAlign: 'center-right',
		infoTextAlignArrow: 'center-left',
	}

	state = {
		isFocused: false,
		clientValidText: null,
		serverValidText: null,
		hasValue: false,
		width: null,
		infoText: null,
		isValid: true,
		isMicrovalidationEnabled: false,
	}

	UNSAFE_componentWillMount() {
		const { children, name, hintText, labelText } = this.props

		const propsLeaf = children ? children.props : this.props

		this.setState({
			hasValue: this.isValueValid(propsLeaf.value),
		})

		const uniqueId = `${name || ''}-${hintText || ''}-${labelText || ''}-${Math.floor(Math.random() * 0xffff)}`
		this.uniqueId = uniqueId.replace(/[^A-Za-z0-9-]/gi, '')
	}

	componentDidMount() {
		if ('number' === this.props.type) {
			console.warn('Don\'t use <TextField type="number" />, use <NumberInput /> instead.') // eslint-disable-line
		}

		this.syncWidthWithShadow(this.props.value, this.props)

		const input = this.getInputNode()
		this.autofillTimer = setTimeout(() => {
			if (this.state.hasValue) return // could have been switched to true during the timer
			let hasValue
			try {
				hasValue = input ? input.matches(':autofill') : false
			} catch (err) {
				try {
					hasValue = input ? input.matches(':-webkit-autofill') : false
				} catch (err) {
					hasValue = false
				}
			}
			this.setState({ hasValue })
		}, 500)

		this.updateMaxWidth()
	}

	componentWillUnmount() {
		this.autofillTimer && clearTimeout(this.autofillTimer)
	}

	componentDidUpdate(prevProps: Props) {
		if (
			this.props.children !== prevProps.children ||
			this.props.inline !== prevProps.inline ||
			this.props.compact !== prevProps.compact ||
			this.props.labelText !== prevProps.labelText ||
			this.props.fullWidth !== prevProps.fullWidth ||
			this.props.hintText !== prevProps.hintText ||
			this.props.autoWidth !== prevProps.autoWidth ||
			this.props.autoHeight !== prevProps.autoHeight ||
			this.props.size !== prevProps.size ||
			this.props.rows !== prevProps.rows ||
			this.props.rowsMax !== prevProps.rowsMax ||
			this.props.multiLine !== prevProps.multiLine
		) {
			this.updateMaxWidth()
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		if (nextProps.hasOwnProperty('value')) {
			this.setState(
				{
					hasValue: this.isValueValid(nextProps.value),
				},
				() => {
					if (this.props.clientError && !nextProps.clientError && (nextProps.required || this.state.hasValue)) {
						this.setState({
							clientValidText: this.props.t('application.validation.isValidNow'),
						})
						setTimeout(() => {
							this.setState({
								clientValidText: null,
							})
						}, 3000)
					} else {
						this.setState({
							clientValidText: null,
						})
					}
				},
			)
		}

		if (this.props.serverError && !nextProps.serverError) {
			this.setState({
				serverValidText: this.props.t('application.validation.isValidNow'),
			})
			setTimeout(() => {
				this.setState({
					serverValidText: null,
				})
			}, 3000)
		} else {
			this.setState({
				serverValidText: null,
			})
		}

		if (nextProps.value !== this.props.value) {
			this.handleValueChange(nextProps.value)
			this.syncWidthWithShadow(nextProps.value, nextProps)
		}

		if (nextProps.disabled && !this.props.disabled) {
			this.setState({ isFocused: false })
		}

		if (nextProps.children && nextProps.children.props) nextProps = nextProps.children.props
	}

	/**
	 * Check if a value is valid to be displayed inside an input.
	 *
	 * @param The value to check.
	 * @returns True if the string provided is valid, false otherwise.
	 */
	isValueValid = (value: any): boolean => {
		return value !== '' && value !== null && value !== undefined && !(Array.isArray(value) && !value.length)
	}

	isValid = (value: ?string | ?number): boolean => {
		const isValueValid = this.isValueValid(value)
		return this.props.required ? isValueValid : true
	}

	handleValueChange(value: ?string | ?number) {
		if (this.props.required) {
			const isValid = this.isValid(value)
			this.setState({
				isValid: isValid,
				isMicrovalidationEnabled: isValid ? true : this.state.isMicrovalidationEnabled,
			})
		}
	}

	updateMaxWidth() {
		if (!this.refs.container || !this.refs.root || this.props.fullWidth) return
		this.refs.container.style.width = '100%'
		this.maxWidth =
			this.refs.container.scrollWidth -
			parseInt(this.refs.root.style.paddingLeft || 0) -
			parseInt(this.refs.root.style.paddingRight || 0)
		this.refs.container.style.width = 'auto'
	}

	blur() {
		const input = this.getInputNode()
		input && input.blur()
	}

	focus(options: Object) {
		const input = this.getInputNode()
		if (input) {
			// $FlowFixMe - focus accepts option argument
			input.focus(options)
			input.select()
		}
	}

	select() {
		const input = this.getInputNode()
		input && input.select()
	}

	getInputNode(): ?HTMLInputElement | ?HTMLTextAreaElement {
		const element = this.getInputNodeElement()
		return element && (element instanceof HTMLInputElement || element instanceof HTMLTextAreaElement) ? element : null
	}

	getInputNodeElement(): null | Element | Text {
		if (typeof this.input.getWrappedInstance === 'function') this.input = this.input.getWrappedInstance()

		return this.props.children || this.props.multiLine
			? this.input && this.input.getInputNode && this.input.getInputNode()
			: ReactDOM.findDOMNode(this.input)
	}

	handleInputBlur = (event: SyntheticInputEvent<HTMLInputElement>) => {
		this.setState({
			isFocused: false,
			isMicrovalidationEnabled: this.props.required ? true : false,
		})
		this.props.onBlur && this.props.onBlur(event)
	}

	shouldSetSize = () => {
		return this.props.autoWidth || (this.props.fullWidth && this.props.right && this.props.labelText)
	}

	syncWidthWithShadow(newValue, props) { // eslint-disable-line
		if (!this.shouldSetSize()) return
		const shadow = this.refs.shadow
		const displayText =
			this.props.hintText && (newValue === '' || newValue === undefined || newValue === null)
				? this.props.hintText
				: newValue

		if (displayText !== undefined) {
			shadow.textContent = String(displayText).replace(/ /g, '\u00A0')
		}

		// Add 2 px to overcome browser imperfections and prevent wrapping
		let newWidth = Math.ceil(shadow.scrollWidth) + 2

		// Guarding for jsdom, where scrollWidth isn't present.
		// See https://github.com/tmpvar/jsdom/issues/1013
		if (newWidth === undefined) return

		props = props || this.props

		if (this.state.width !== newWidth) {
			this.setState({
				width: Math.max(newWidth, props.minWidth || 0),
			})
			this.input.refs && this.input.refs.input && this.preventInputWrapping(newWidth)
		}
	}

	//prevent input from wrapping until it gets to full width
	preventInputWrapping(inputWidth: number) {
		if (this.props.fullWidth || !this.maxWidth) return
		this.input.refs.input.style.whiteSpace = inputWidth < this.maxWidth ? 'nowrap' : 'normal'
	}

	onBlur = (event: SyntheticInputEvent<HTMLInputElement>) => {
		this.setState({
			isMicrovalidationEnabled: true,
		})
		this.props.onBlur && this.props.onBlur(event)
	}

	handleInputChange = (event: SyntheticInputEvent<HTMLInputElement>) => {
		const currentValue = this.props.hasOwnProperty('value') ? this.props.value : event.target.value
		!this.props.hasOwnProperty('value') && this.setState({ hasValue: this.isValueValid(event.target.value) })
		this.syncWidthWithShadow(currentValue, this.props)
		this.handleValueChange(currentValue)
		this.props.onChange && this.props.onChange(event, event.target.value)
	}

	handleInputFocus = (event: SyntheticInputEvent<HTMLInputElement>) => {
		if (this.props.disabled) return
		this.setState({ isFocused: true })
		this.props.onFocus && this.props.onFocus(event)
	}

	handleInputKeyup = (event: SyntheticKeyboardEvent<HTMLInputElement>) => {
		if (this.props.disabled) return
		if ('enter' === keycode(event)) {
			this.setState({ isFocused: false })
			this.props.onKeyEnter && this.props.onKeyEnter(event)
		}
		if ('esc' === keycode(event)) {
			this.props.onKeyEscape && this.props.onKeyEscape(event)
			const input = this.getInputNode()
			input && input.blur && input.blur()
		}
	}

	handleInputKeydown = async (event: SyntheticKeyboardEvent<HTMLInputElement>) => {
		if (this.props.disabled) return

		if ('Enter' === event.key) {
			// Cannot cast SyntheticKeyboardEvent<HTMLInputElement> to SyntheticInputEvent<HTMLInputElement>,
			// $FlowFixMe even tho they are basically the same, and we only need properties that are same in both types
			this.handleInputBlur((event: SyntheticInputEvent<HTMLInputElement>))
			if (this.props.isInLastLineItem) {
				this.props.onLastLineEnter && (await this.props.onLastLineEnter())
			}
			if (this.props.newLineTabIndex && this.props.isInLastLineItem) {
				document.querySelectorAll(`[tabindex="${this.props.newLineTabIndex}"]`)[0].focus()
			} else if (this.props.navigateToNextLine && this.props.newLineTabIndex) {
				document.querySelectorAll(`[tabindex="${this.props.newLineTabIndex}"]`)[0].focus()
			} else {
				document.querySelectorAll(`[tabindex="${this.props.nextItemTabIndex ?? this.props.tabIndex + 1}"]`)[0].focus()
			}
		}

		this.props.onKeyDown && this.props.onKeyDown(event)
	}

	handleClick = (event: SyntheticInputEvent<HTMLInputElement>) => {
		if (this.props.disabled) return
		if (this.input && this.input.focus) {
			this.input.focus()
			this.setState({ isFocused: true })
			this.props.onFocus && this.props.onFocus(event)
		}
	}

	_isControlled() {
		return this.props.hasOwnProperty('value')
	}

	bindInput = (element: any) => (this.input = element)

	render() {
		const {
			autoFocus,
			children,
			disabled,
			serverError,
			infoText,
			infoTextAlign,
			infoTextAlignArrow,
			labelText,
			hintText,
			id,
			autoWidth,
			multiLine,
			type,
			size,
			rows,
			rowsMax,
			hintStyle,
			inputStyle,
			containerStyle,
			style,
			right,
			t,
			autoHeight,
			autoComplete,
			tabIndex,
			name,
			value,
		} = this.props

		const styles = getStyles(this.props, this.state)
		const inputId = id || this.uniqueId

		const infoTextElement = this.props.infoText && (
			<div css={styles.info}>
				<Tooltip
					label={infoText}
					align={infoTextAlign}
					arrowAlign={infoTextAlignArrow}
					renderToLayer
					style={styles.tooltip}
					inline
				>
					<InfoIcon color={colors.gray} hoverColor={colors.gray900} size={18} />
				</Tooltip>
			</div>
		)

		const labelTextElement = labelText && !this.props.inline && (
			<TextFieldLabel style={styles.label} htmlFor={inputId} {...passAutoTestId(this.props.autoTestId, 'label')}>
				{labelText}
			</TextFieldLabel>
		)

		const inputProps = {
			id: inputId,
			ref: this.bindInput,
			size: this.props.size,
			autoFocus: autoFocus,
			disabled: this.props.disabled,
			onBlur: this.handleInputBlur,
			onChange: this.handleInputChange,
			onFocus: this.handleInputFocus,
			onKeyUp: this.handleInputKeyup,
			onKeyDown: this.handleInputKeydown,
		}

		const childStyleMerged = { ...styles.input, inputStyle }

		let inputElement
		if (children) {
			inputElement = React.cloneElement(children, {
				...inputProps,
				...children.props,
				style: childStyleMerged,
			})
		} else {
			inputElement = multiLine ? (
				<EnhancedTextarea
					style={childStyleMerged}
					rows={rows}
					rowsMax={rowsMax}
					hintText={hintText}
					autoWidth={autoWidth}
					autoHeight={autoHeight}
					disableNewlines={autoWidth && multiLine}
					autoComplete={autoComplete}
					right={right}
					name={name}
					size={size}
					id={inputId}
					onBlur={this.handleInputBlur}
					onChange={this.handleInputChange}
					onFocus={this.handleInputFocus}
					onKeyUp={this.handleInputKeyup}
					onKeyDown={this.handleInputKeydown}
					ref={this.bindInput}
					tabIndex={tabIndex}
					disabled={disabled}
					autoFocus={autoFocus}
					{...(this._isControlled() ? { value: value || '' } : {})}
				/>
			) : (
				<input
					name={name}
					type={type}
					id={inputId}
					css={childStyleMerged}
					autoComplete={autoComplete ? 'on' : 'off'}
					onBlur={this.handleInputBlur}
					onChange={this.handleInputChange}
					onFocus={this.handleInputFocus}
					onKeyUp={this.handleInputKeyup}
					onKeyDown={this.handleInputKeydown}
					ref={this.bindInput}
					disabled={this.props.disabled}
					tabIndex={tabIndex}
					autoFocus={autoFocus}
					noValidate={'novalidate'}
					maxLength={this.props.maxLength}
					title={this.props.title || ''}
					{...autoTestId(this.props.autoTestId)}
					{...(this._isControlled() ? { value: value || '' } : {})}
				/>
			)
		}

		let shadowInputElement
		if (this.shouldSetSize()) {
			shadowInputElement = (
				<div ref="shadow" css={{ ...styles.input, ...styles.shadow }}>
					.
				</div>
			)
		}

		const serverErrorElement =
			serverError || this.state.serverValidText ? (
				<div css={styles.serverError}>{serverError || this.state.serverValidText}</div>
			) : null

		return (
			<div
				{...autoTestId(this.props.autoTestId, 'container')}
				css={{ ...styles.container, ...containerStyle }}
				id={name ? `TextField-${name}` : null}
				ref="container"
			>
				{labelTextElement}
				<Tooltip
					label={
						this.props.clientError
							? this.props.clientError
							: isClientErrorVisible(this.props, this.state)
							? t('clientError.fillThisField')
							: this.state.clientValidText || ''
					}
					wrapperStyle={styles.tooltipWrapper}
					inline
					{...passAutoTestId(this.props.autoTestId, 'tooltip')}
				>
					<div
						ref="root"
						css={{ ...styles.root, ...style }}
						onClick={this.handleClick}
						{...autoTestId(this.props.autoTestId, 'root-field-element')}
					>
						<div css={{ ...styles.frame, ...style }} className={'TextField__frame'} />
						<div ref="wrapper" css={styles.inputWrapper}>
							{hintText ? (
								<TextFieldHint
									visible={
										!(this.state.hasValue || (labelText && !this.state.isFocused)) ||
										(!this.state.hasValue && !!labelText && !this.state.isFocused)
									}
									style={Object.assign(styles.hint, hintStyle)}
									text={hintText}
								/>
							) : null}
							{inputElement}
							{this.shouldSetSize() ? shadowInputElement : null}
						</div>
						{infoTextElement}
						{serverErrorElement}
					</div>
				</Tooltip>
			</div>
		)
	}
}

const isClientErrorVisible = (props: Props, state: State): boolean => {
	return !!props.clientError || (state.isMicrovalidationEnabled && !state.isValid)
}

const getStyles = (props: Props, state: State) => {
	const {
		fullWidth,
		compact,
		autoWidth,
		hasButton,
		editCursor,
		disabled,
		right,
		labelText,
		inline,
		children,
		multiLine,
		disableHover,
		transparent,
		serverError,
		inputStyle,
		useStyle,
	} = props
	const { infoText, clientValidText, serverValidText, isFocused, width } = state

	const showClientError = isClientErrorVisible(props, state)

	const styles: any = {
		container: {
			display: 'inline-block',
			position: 'relative',
			height: 'auto',
			width: 'auto',
			cursor: 'auto',
			maxWidth: '100%',
			textAlign: right ? 'right' : 'left',
			'&:after': {
				content: '""',
				position: 'absolute',
				display: 'block',
				height: 1,
				bottom: 0,
				left: 0,
				right: 0,
				borderWidth: 0.5,
				borderStyle: 'dashed',
				borderColor: 'transparent',
			},
		},
		root: {
			display: 'flex',
			justifyContent: 'flex-start',
			alignItems: 'center',
			alignContent: 'center',
			position: 'relative',
			fontSize: inline ? 'inherit' : 14,
			lineHeight: inline ? 'inherit' : '24px',
			height: 'auto',
			width: 'auto',
			maxWidth: '100%',
			cursor: editCursor ? 'text' : 'auto',
			padding: '11px 18px',
			boxSizing: 'border-box',
			verticalAlign: 'initial',
		},
		frame: {
			display: 'block',
			position: 'absolute',
			top: 0,
			left: 0,
			bottom: 0,
			right: 0,
			borderRadius: 3,
			borderWidth: 1,
			borderStyle: 'solid',
			borderColor: transparent ? colors.transparent : colors.gray400,
			backgroundColor: transparent ? colors.transparent : colors.white,
		},
		info: {
			position: 'absolute',
			top: '-10px',
			right: '-10px',
			transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1)',
			display: disabled ? 'none' : 'block',
		},
		label: {},
		inputWrapper: {
			position: 'relative',
			display: 'block',
			width: 'auto',
			maxWidth: '100%',
			padding: 0,
			boxSizing: 'border-box',
			flex: '1 1 auto',
			marginLeft: autoWidth ? 'auto' : 0,
			marginRight: autoWidth ? 'auto' : hasButton ? '18px' : 0,
		},
		input: {
			position: 'relative',
			padding: 0,
			border: 'none',
			outline: 'none',
			backgroundColor: 'transparent',
			cursor: 'inherit',
			font: 'inherit',
			margin: 0,
			boxSizing: 'border-box',
			textAlign: right ? 'right' : 'left',
			width: '100%',
			textOverflow: autoWidth ? 'clip' : 'ellipsis',
			overflow: 'hidden',
			maxWidth: '100%',
			height: 'auto',
			color: disabled && !inline ? colors.disabledText : colors.black,
		},
		shadow: {
			position: 'fixed',
			left: '-9999px',
			width: 'auto',
			opacity: 0,
			pointerEvents: 'none',
		},
		hint: {
			padding: 0,
			border: 'none',
			outline: 'none',
			cursor: 'inherit',
			font: 'inherit',
			boxSizing: 'border-box',
			textAlign: right ? 'right' : 'left',
			textOverflow: autoWidth ? 'clip' : 'ellipsis',
		},
		tooltipWrapper: {
			width: fullWidth ? '100%' : 'auto',
			maxWidth: '100%',
			verticalAlign: 'middle',
			display: 'inline-flex',
		},
		serverError: {
			position: 'absolute',
			fontSize: 12,
			fontWeight: 'bold',
			color: colors.red,
			lineHeight: '24px',
			top: '100%',
			left: 0,
			right: 0,
			textOverflow: 'ellipsis',
			overflow: 'hidden',
			whiteSpace: 'nowrap',
		},
		tooltip: {
			marginTop: -2,
		},
	}

	if (((fullWidth && right && labelText) || autoWidth) && !children && width) {
		styles.input.width = width
		styles.inputWrapper.width = width
		styles.inputWrapper.maxWidth = '100%'
		styles.inputWrapper.flex = `0 0 ${width}px`
	}

	if (inline) {
		styles.frame.left = -4
		styles.frame.right = -4
		styles.root.padding = 0
		styles.root.margin = 0
		styles.frame.backgroundColor = 'transparent'
		styles.frame.borderRadius = 2
		styles.frame.borderColor = 'transparent'
		styles.container.verticalAlign = 'middle'
	}

	if (compact && !inline) {
		styles.frame.borderRadius = 2
		styles.root.padding = '4px 8px'
		styles.root.height = '32px'
		styles.root.fontSize = '14px'
		styles.root.lineHeight = '22px'
	}

	if (fullWidth) {
		styles.container.width = '100%'
		styles.container.boxSizing = 'border-box'
		styles.root.width = '100%'
		styles.root.boxSizing = 'border-box'
		styles.hint.width = '100%'
	}

	if (inline && !disableHover && !disabled && !transparent && !isFocused) {
		styles.container['&:hover'] = {
			'.TextField__frame': {
				borderColor: colors.blueFaded40,
				backgroundColor: colors.blue100Faded40,
			},
		}
	}

	if (isFocused && !transparent && !disableHover) {
		styles.frame.borderColor = colors.blue
		styles.frame.backgroundColor = '#E6F7FA'
	}

	if (disabled && !inline) {
		styles.frame.backgroundColor = '#f9f9f9'
		styles.frame.borderColor = colors.grayLight200
	}

	if (serverError || infoText) styles.container.overflow = 'visible'

	if (inline) {
		if (serverError || showClientError) {
			if (!transparent) {
				styles.frame.borderColor = colors.red
				styles.frame.backgroundColor = 'rgba(225,29,59,0.04)'
				styles.container['&:hover'] = {
					'.TextField__frame': {
						borderColor: colors.red,
						backgroundColor: 'rgba(225,29,59,0.04)',
					},
				}
			} else {
				styles.container['&:after'].borderColor = colors.red
			}

			if (disabled) {
				styles.container.opacity = 0.6
			}
		}

		if (clientValidText || serverValidText) {
			styles.container.borderBottom = `1px dashed ${colors.green}`
		}
	} else {
		if (showClientError || serverError) {
			styles.frame.borderColor = colors.red
			if (disabled) {
				styles.container.opacity = 0.6
			}
		}

		if (serverError) {
			styles.frame.boxShadow = '0 2px 0 0 rgba(225,29,59,0.2)'
		}

		if (showClientError) {
			styles.frame.backgroundColor = 'rgba(225,29,59,0.04)'
		}

		if (clientValidText || serverValidText) {
			styles.frame.borderColor = colors.green
		}
	}

	if (!multiLine) {
		styles.root.wordBreak = 'keep-all'
		styles.root.whiteSpace = 'nowrap'
	}

	if (((fullWidth && right && labelText) || autoWidth) && right) {
		styles.inputWrapper.display = 'inline-block'
		styles.label.float = 'none'
		styles.root.float = 'right'
		styles.label.paddingRight = 2
		styles.label.verticalAlign = styles.inputWrapper.verticalAlign = 'middle'
	}

	if (autoWidth && multiLine) {
		styles.root.width = '100%'
		styles.root.display = 'block'
		styles.inputWrapper.width = '100%'
		styles.tooltipWrapper.width = 'inherit'
	}

	if (fullWidth && right && labelText) {
		styles.inputWrapper.flex = '0 0 auto'
		styles.label.flex = '1 1 100%'
	}

	if (useStyle && inputStyle) {
		Object.assign(styles.input, inputStyle)
	}

	return styles
}

export default withTranslate(TextField, { withRef: true })
