/* @flow */

import type {
	AccountingDocumentDirection,
	AccountingDocumentView,
	DataGridId,
	Enum,
	EnumItem,
	Filter,
	I18NextTranslateFn,
	SearchFilter,
} from 'types'
import { BasicGridFilter, Tab, Tabs } from 'components'
import {
	FilterDate,
	FilterRange,
	FilterSelectField,
	FilterState,
	FilterTabSwitch,
	FilterTabSwitches,
	FilterTextField,
	FilterVariableSymbol,
} from 'modules/data-grid/components/data-grid-filter'
import { PAYMENT_TYPES, Type_AccountingDocumentName } from 'types/convertor'
import React, { type Node, PureComponent } from 'react'
import { type WithTranslateProps, withTranslate } from 'wrappers'
import {
	paymentFilterTransform,
	paymentToFilterItemTransform,
	processingStateFilterTransform,
	processingStateToFilterItemTransform,
} from 'utils/filter-transformers'

import type { DataTypes } from 'modules/data-grid-next/types'
import { EMPTY_ARRAY } from 'trivi-constants'
import ExportButton from '../export/export-button'
import { FilterCurrency } from 'modules/data-grid/containers/data-grid-filter'
import type { FilterElement } from 'modules/data-grid/types'
import FilterSequence from 'modules/data-grid/containers/filter-sequence'
import IconButton from 'components/icon-button'
import TemplateButton from './template-button'
import { getFilter } from 'modules/data-grid-next/domain/filter'
import memoize from 'memoize-one'
import styles from './advanced-filter.css'

const STANDARD_FILTERS_RECEIVED: Array<FilterItem> = [
	'accountingDocumentNo',
	'externalNo',
	'contact.normalizedName',
	'contact.companyRegNo',
	'variableSymbol',
	'currency',
	'total',
	'type',
	'state',
	'processingState',
	'sequence',
	'vatCountryType',
	'extractingState',
	'taxReturnDate',
	'paymentType',
	'preferredBranch',
	'preferredProject',
	'payment',
]

const STANDARD_FILTERS_ISSUED: Array<FilterItem> = [
	'accountingDocumentNo',
	'contact.normalizedName',
	'contact.companyRegNo',
	'variableSymbol',
	'currency',
	'total',
	'type',
	'state',
	'processingState',
	'sequence',
	'vatCountryType',
	'extractingState',
	'paymentType',
	'preferredBranch',
	'preferredProject',
	'payment',
]

const UPLOADED_DOCUMENTS_FILTERS: Array<FilterItem> = [
	'scans.filename',
	'authorName',
	'preferredBranch',
	'preferredProject',
	'extractingState',
	'paymentType',
]

type FilterItem =
	| 'authorName'
	| 'externalNo'
	| 'preferredBranch'
	| 'preferredProject'
	| 'accountingDocumentNo'
	| 'scans.filename'
	| 'contact.normalizedName'
	| 'contact.companyRegNo'
	| 'currency'
	| 'total'
	| 'payment'
	| 'paymentType'
	| 'type'
	| 'processingState'
	| 'sequence'
	| 'state'
	| 'vatCountryType'
	| 'variableSymbol'
	| 'extractingState'
	| 'taxReturnDate'

type Props = {|
	...WithTranslateProps,
	name: string,
	showProcessingState?: boolean,
	direction?: ?AccountingDocumentDirection,
	dataType?: $Keys<DataTypes>, // This is only used to show result count
	gridId?: DataGridId, // This is only used to show result count
	defaultFilter?: Filter, // This is only used to show result count
	allowedTypes?: ?Array<number>,
	allowedPaymentTypes?: Array<number | string>,
	showViewSwitch?: boolean,
	showApprovedSwitch?: boolean,
	showOptimizeFilter?: boolean,
	isUsedOptimizeFilter?: boolean,
	view?: string,
	compact?: boolean,
	hideCurrency?: boolean,
	exportButton?: React$Element<typeof ExportButton>,
	templateButton?: React$Element<typeof TemplateButton>,
	importButton?: ?React$Element<typeof IconButton>,
	filterItems?: Array<FilterItem>,
	rightElement?: Node,
	onViewChange?: (tab: AccountingDocumentView) => void,
	internal?: ?boolean,
	branches?: ?Enum,
	projects?: ?Enum,
	isFilterOpenDefault?: boolean,
	onOpen?: () => void,
	onClose?: () => void,
|}

class AccountingDocumentAdvancedFilter extends PureComponent<Props> {
	static defaultProps = {
		onOpen: () => {},
		onClose: () => {},
	}

	getProcessingStateOptions = memoize((t: I18NextTranslateFn) => {
		return [
			{ value: 'processedWithOk', label: t('accountingDocument.filter.processingState.processedWithOk') },
			{ value: 'processedWithoutOk', label: t('accountingDocument.filter.processingState.processedWithoutOk') },
			{
				value: 'waitingForProcessing',
				label: t('accountingDocument.filter.processingState.waitingForProcessing'),
			},
			{ value: 'processedWithError', label: t('accountingDocument.filter.processingState.processedWithError') },
		]
	})

	getTypeOptions = memoize((t: I18NextTranslateFn, allowedTypes: ?Array<number>) => {
		const types = []
		allowedTypes &&
			allowedTypes.forEach((type: number) => {
				const typeName = Type_AccountingDocumentName(type)
				typeName && types.push({ value: type, label: t(`accountingDocument.types.${typeName}`) })
			})

		return types
	})

	getVatCountryOptions = memoize((t: I18NextTranslateFn) => {
		return [
			{ value: 1, label: t('invoice.settings.booking.vatCountryTypes.domestic') },
			{ value: 2, label: t('invoice.settings.booking.vatCountryTypes.eu') },
			{ value: 3, label: t('invoice.settings.booking.vatCountryTypes.foreign') },
		]
	})

	getExtractingStateOptions = memoize((t: I18NextTranslateFn) => {
		return [{ value: 4, label: t('accountingDocument.filter.extractingState.problemWithExtracting') }]
	})

	getPreferredBranchOptions = () => {
		if (!this.props.branches) return EMPTY_ARRAY
		return this.props.branches.map((branch: EnumItem) => ({ value: branch.key, label: branch.value }))
	}

	getPreferredProjectOptions = () => {
		if (!this.props.projects) return EMPTY_ARRAY
		return this.props.projects.map((project: EnumItem) => ({ value: project.key, label: project.value }))
	}

	hasBranches = () => {
		return this.props.branches && this.props.branches.length > 0
	}

	hasProjects = () => {
		return this.props.projects && this.props.projects.length > 0
	}

	getFilterFields = memoize(
		(
			t: I18NextTranslateFn,
			filterItems?: Array<FilterItem>,
			allowedTypes?: ?Array<number>,
			allowedPaymentTypes?: Array<number | string>,
			showProcessingState?: boolean,
			direction: ?AccountingDocumentDirection,
			hideCurrency?: boolean,
			internal: ?boolean,
		) => {
			return this.getRelevantFilters(filterItems, direction).map(
				(name: FilterItem, index: number): FilterElement | null => {
					switch (name) {
						case 'accountingDocumentNo':
							return (
								<FilterTextField
									key={name}
									className={styles.fixedColumn}
									name="accountingDocumentNo"
									labelText={t('accountingDocument.filter.number')}
								/>
							)
						case 'scans.filename':
							return (
								<FilterTextField
									key={name}
									className={styles.fixedColumn}
									name="scans.filename"
									labelText={t('accountingDocument.filter.fileName')}
								/>
							)
						case 'contact.normalizedName':
							return (
								<FilterTextField
									key={name}
									className={styles.fixedColumn}
									name="normalizedName"
									labelText={t('accountingDocument.filter.companyName')}
								/>
							)
						case 'contact.companyRegNo':
							return (
								<FilterTextField
									key={name}
									className={styles.fixedColumn}
									name="contact.companyRegNo"
									labelText={t('accountingDocument.filter.companyRegNo')}
								/>
							)
						case 'currency':
							return !hideCurrency ? (
								<FilterCurrency
									key={name}
									className={styles.fixedColumn}
									labelText={t('accountingDocument.filter.currency')}
									name="currency"
									operation="Equal"
								/>
							) : null
						case 'total':
							return <FilterRange key={name} className={styles.fixedColumn} name="total" />

						case 'type':
							return (
								<FilterSelectField
									key={name}
									className={styles.fixedColumn}
									name="type"
									labelText={t('accountingDocument.filter.type.label')}
									options={this.getTypeOptions(t, allowedTypes)}
									multiple
									compact
									isClearable
									isSearchable
								/>
							)
						case 'processingState':
							if (!showProcessingState) {
								return null
							}
							return (
								<FilterSelectField
									key={name}
									compact
									className={styles.fixedColumn}
									name="processingState"
									labelText={t('accountingDocument.filter.processingState.label')}
									options={this.getProcessingStateOptions(t)}
									isClearable
									isSearchable
								/>
							)
						case 'sequence':
							return (
								<FilterSequence
									key={name}
									className={styles.fixedColumn}
									name="sequenceId"
									labelText={t('accountingDocument.filter.sequence')}
									direction={direction}
									type={null}
									allowedAccountingDocumentTypes={allowedTypes}
								/>
							)
						case 'state':
							return <FilterState name="state" key={name} className={styles.fixedColumn} operation="Equal" />

						case 'vatCountryType':
							return (
								<FilterSelectField
									key={name}
									compact
									className={styles.fixedColumn}
									name="vatCountryType"
									labelText={t('invoice.settings.booking.vatCountryHeadline')}
									options={this.getVatCountryOptions(t)}
									isClearable
									isSearchable
								/>
							)

						case 'variableSymbol':
							return (
								<FilterVariableSymbol
									key={name}
									className={styles.fixedColumn}
									name={name}
									labelText={t(`accountingDocument.filter.${name}`)}
								/>
							)

						case 'extractingState':
							if (!internal) return null
							return (
								<FilterSelectField
									key={name}
									compact
									className={styles.fixedColumn}
									name="ExtractingState"
									labelText={t('accountingDocument.filter.extractingState.label')}
									options={this.getExtractingStateOptions(t)}
									isClearable
									isSearchable
								/>
							)

						case 'taxReturnDate':
							// // eslint-disable-next-line no-case-declarations
							// const showExtraColumn =
							// 	!this.props.internal &&
							// 	((this.hasBranches() && !this.hasProjects()) || (this.hasProjects() && !this.hasBranches()))
							// // eslint-disable-next-line no-case-declarations
							// const showExtraColumn2 =
							// 	!this.props.internal &&
							// 	((this.hasBranches() && this.hasProjects()) || (!this.hasProjects() && !this.hasBranches())) &&
							// 	hideCurrency
							return (
								<FilterDate
									key={name}
									className={`${styles.fixedColumn}`}
									name="taxReturnDate"
									labelText={t('accountingDocument.filter.taxReturnDate.label')}
								/>
							)

						case 'paymentType':
							return (
								<FilterSelectField
									key={name}
									className={styles.fixedColumn}
									name="paymentType"
									labelText={t('accountingDocument.filter.paymentType.label')}
									options={Object.entries(PAYMENT_TYPES)
										// eslint-disable-next-line no-unused-vars
										.filter(([val, label]: any) =>
											!allowedPaymentTypes ? true : allowedPaymentTypes.indexOf(parseInt(val)) !== -1,
										)
										.map(([val, label]: any) => ({
											label: t(`accountingDocument.paymentTypes.${label}`),
											value: parseInt(val),
										}))}
									compact
									isClearable
								/>
							)

						case 'preferredBranch':
							if (!this.props.branches || !this.props.branches.length) return null
							return (
								<FilterSelectField
									key={name}
									compact
									className={styles.fixedColumn}
									name={
										this.props.direction === 'issued' || this.props.direction === 'received'
											? 'lineItem.tag.branch'
											: name
									}
									labelText={t(`accountingDocument.filter.${name}`)}
									options={this.getPreferredBranchOptions()}
									operation={this.props.direction === 'unknown' ? 'Equal' : null}
									isClearable
									isSearchable
									multiple={this.props.direction === 'issued' || this.props.direction === 'received'}
								/>
							)

						case 'preferredProject':
							if (!this.props.projects || !this.props.projects.length) return null
							return (
								<FilterSelectField
									key={name}
									compact
									className={styles.fixedColumn}
									name={
										this.props.direction === 'issued' || this.props.direction === 'received'
											? 'lineItem.tag.project'
											: name
									}
									labelText={t(`accountingDocument.filter.${name}`)}
									options={this.getPreferredProjectOptions()}
									operation={this.props.direction === 'unknown' ? 'Equal' : null}
									isClearable
									isSearchable
									multiple={this.props.direction === 'issued' || this.props.direction === 'received'}
								/>
							)

						case 'payment':
							return (
								<FilterTabSwitches
									key={name}
									className={`${styles.autoColumn} ${styles.autoColumnNewLine}`}
									name="payment"
									label={t('accountingDocument.filter.payment.label')}
								>
									<FilterTabSwitch label={t('accountingDocument.filter.payment.all')} value="" />
									<FilterTabSwitch label={t('accountingDocument.filter.payment.paid')} value="paid" />
									<FilterTabSwitch label={t('accountingDocument.filter.payment.unpaid')} value="unpaid" />
									<FilterTabSwitch label={t('accountingDocument.filter.payment.paidPartially')} value="paidPartially" />
									<FilterTabSwitch label={t('accountingDocument.filter.payment.paidManually')} value="paidManually" />
								</FilterTabSwitches>
							)

						default:
							return (
								<FilterTextField
									key={name + index}
									className={styles.fixedColumn}
									name={name}
									labelText={t(`accountingDocument.filter.${name}`)}
								/>
							)
					}
				},
			)
		},
	)

	getRelevantFilters = (filterItems: ?Array<FilterItem>, direction: ?AccountingDocumentDirection) => {
		if (filterItems) return filterItems

		switch (direction) {
			case 'unknown':
				return UPLOADED_DOCUMENTS_FILTERS
			case 'received':
				return STANDARD_FILTERS_RECEIVED
			case 'issued':
				return STANDARD_FILTERS_ISSUED
			default:
				return STANDARD_FILTERS_RECEIVED
		}
	}

	getLeftElement = memoize(
		(
			t: I18NextTranslateFn,
			showProcessingState: ?boolean,
			showApprovedSwitch: ?boolean,
			showViewSwitch: ?boolean,
			internal: ?boolean,
			view: ?string,
			onViewChange: ?(tab: AccountingDocumentView) => void,
		) => {
			return showViewSwitch && internal ? (
				<Tabs autoTestId="grid-filter-view-tabs" onChange={onViewChange} value={view}>
					<Tab className={styles.tab} label={t('accountingDocument.filter.tabs.all')} value="all" />
					<Tab
						className={styles.tab}
						label={t('accountingDocument.filter.tabs.isProcessedWithoutOK')}
						value="isProcessedWithoutOK"
					/>
					{showProcessingState && (
						<Tab className={styles.tab} label={t('accountingDocument.filter.tabs.errors')} value="errors" />
					)}
				</Tabs>
			) : showApprovedSwitch ? (
				<Tabs autoTestId="grid-filter-approve-tabs" onChange={onViewChange} value={view}>
					<Tab className={styles.tab} label={t('accountingDocument.filter.tabs.all')} value="all" />
					<Tab
						className={styles.tab}
						label={t('accountingDocument.filter.tabs.approvedAndWaitingApproval')}
						value="approvedAndWaitingApproval"
					/>
					<Tab className={styles.tab} label={t('accountingDocument.filter.tabs.approved')} value="approved" />
					<Tab className={styles.tab} label={t('accountingDocument.filter.tabs.rejected')} value="rejected" />
				</Tabs>
			) : null
		},
	)

	handleOpen = () => {
		this.props.onOpen && this.props.onOpen()
	}

	handleClose = () => {
		this.props.onClose && this.props.onClose()
	}

	render() {
		const { t } = this.props
		const dateFilterName = this.props.direction !== 'unknown' ? 'taxDate' : 'issueDate'
		const dateFilterHintText = this.props.direction !== 'unknown' ? t('invoice.taxDate') : t('invoice.uploadedDate')
		const exportButton = this.props.direction !== 'unknown' ? this.props.exportButton : null
		const checkboxFilterName = 'excludeAccDocSearchThresholdId'
		const checkboxFilterLabelText = t('invoice.accDocThreshold')

		return (
			<div className={styles.root}>
				<BasicGridFilter
					compact={this.props.compact}
					name={this.props.name}
					dataType={this.props.dataType}
					gridId={this.props.gridId}
					defaultFilter={this.props.defaultFilter}
					dateFilterName={dateFilterName}
					dateFilterHintText={dateFilterHintText}
					textFilterName="searchText"
					noFilterLabel={t('accountingDocument.filter.allDocuments')}
					filterTransformFn={filterTransformFn}
					exportButton={exportButton}
					templateButton={this.props.templateButton}
					importButton={this.props.importButton}
					rightElement={this.props.rightElement}
					isFilterOpenDefault={this.props.isFilterOpenDefault}
					checkboxFilterName={checkboxFilterName}
					checkboxFilterLabelText={checkboxFilterLabelText}
					onOpen={this.handleOpen}
					onClose={this.handleClose}
					showOptimizeFilter={this.props.showOptimizeFilter}
					isUsedOptimizeFilter={this.props.isUsedOptimizeFilter}
					leftElement={this.getLeftElement(
						this.props.t,
						this.props.showProcessingState,
						this.props.showApprovedSwitch,
						this.props.showViewSwitch,
						this.props.internal,
						this.props.view,
						this.props.onViewChange,
					)}
				>
					{this.getFilterFields(
						t,
						this.props.filterItems,
						this.props.allowedTypes,
						this.props.allowedPaymentTypes,
						this.props.showProcessingState,
						this.props.direction,
						this.props.hideCurrency,
						this.props.internal,
					)}
				</BasicGridFilter>
			</div>
		)
	}
}

const filterTransformFn = {
	fromFilterItem: (item: SearchFilter): Filter => {
		let filter: Filter = []
		switch (item.field) {
			case 'processingState':
				filter = processingStateFilterTransform(item)
				break
			case 'payment':
				filter = paymentFilterTransform(item)
				break
			default:
				filter.push(item)
		}
		return filter
	},
	toFilterItem: (filter: Filter, field: string): ?SearchFilter => {
		if (field === 'payment') {
			return paymentToFilterItemTransform(filter, field)
		}
		if (field === 'processingState') {
			return processingStateToFilterItemTransform(filter, field)
		}
		return getFilter(filter, field)
	},
}

export default withTranslate(AccountingDocumentAdvancedFilter)
