// @flow

import React, { Fragment } from 'react'
import { uniqBy } from 'lodash-es'
import type {
	I18NextTranslateFn,
	FormFieldErrorContext,
	FormFieldErrorMessage,
	FormFieldErrorsMap,
	FormFieldErrorSubPath,
	FormFieldErrorResourceType,
	ReferencesResourceField,
	ValidationError,
	ValidationFieldError,
	AccountingDocumentFormFieldErrorSubPath,
	AccountingDocumentIssue,
	MultiFormFieldMessages,
	FormFieldError,
	ReferencesResourceFieldResource,
} from 'types'
import {
	ACCDOC_ERROR_PATHS_TO_SNACKBAR,
	ACCDOC_ERROR_MAIN_PATHS_ONLY,
	ACCDOC_ERROR_PATHS_SPECIAL_MAPPING,
} from 'modules/accounting-document/constants'
import { getServerErrorMessage } from 'helpers'

export function getFormFieldErrorUniquePath(
	resource: ?ReferencesResourceFieldResource,
	path: ?Array<string>,
	multiple?: boolean,
) {
	const pathString = path ? path.join('.') : ''
	const name = resource && resource.name != null ? resource.name : 'undefResourceName'
	const id = resource && resource.id != null ? resource.id : 'undefResourceId'
	const uniqueId = `${name}.${id}.${pathString}`
	return multiple ? `${uniqueId}.` : uniqueId
}

export function isErrorPathForSnackbar(
	path?: Array<string>,
	snackbarPaths: Array<AccountingDocumentFormFieldErrorSubPath>,
) {
	return path && snackbarPaths.includes(path[0])
}

export function onlyMainErrorPath(path?: Array<string>) {
	return path && ACCDOC_ERROR_MAIN_PATHS_ONLY.includes(path[0])
}

export function getFinalUniquePath(resource: ReferencesResourceFieldResource, path?: Array<string>) {
	if (path) {
		const mainPath = ((path[0].toString(): any): AccountingDocumentFormFieldErrorSubPath)
		if (onlyMainErrorPath(path)) {
			path = [mainPath]
		}
		const specialPath = ACCDOC_ERROR_PATHS_SPECIAL_MAPPING[mainPath]
		if (specialPath) {
			path = [...specialPath, ...path.slice(1)]
		}
	}
	return getFormFieldErrorUniquePath(resource, path)
}

export function addAndTransformError(
	acc: { serverErrorFieldsAffected?: Array<ValidationFieldError>, validationError?: FormFieldErrorsMap },
	field: ValidationFieldError,
	resource: ReferencesResourceFieldResource,
	path?: Array<string>,
) {
	const newAcc = { ...acc }
	const { code, message, params } = field
	const errorMessage = { code, message, params }
	if (isErrorPathForSnackbar(path, ACCDOC_ERROR_PATHS_TO_SNACKBAR)) {
		if (!newAcc.serverErrorFieldsAffected) newAcc.serverErrorFieldsAffected = []
		newAcc.serverErrorFieldsAffected.push(field)
	} else {
		const uniqueId = getFinalUniquePath(resource, path)
		if (!newAcc.validationError) newAcc.validationError = {}
		if (newAcc.validationError[uniqueId]) {
			newAcc.validationError[uniqueId].messages && newAcc.validationError[uniqueId].messages.push(errorMessage)
		} else {
			newAcc.validationError[uniqueId] = {
				resource,
				path,
				messages: [errorMessage],
			}
		}
	}
	return newAcc
}

export function addAndTransformErrorBasedOnResource(
	acc: { serverErrorFieldsAffected?: Array<ValidationFieldError>, validationError?: FormFieldErrorsMap },
	field: ValidationFieldError,
	reference: ReferencesResourceField,
) {
	const { resource, path } = reference
	let newAcc = { ...acc }
	if (!resource) return newAcc
	const { name } = resource
	if (name === 'AccountingDocument') {
		newAcc = addAndTransformError(acc, field, resource, path)
	}
	if (name === 'Template' || name === 'TemplateScheduler') {
		const templatePath = path && 'content' === path[0] ? path.slice(1) : path
		newAcc = addAndTransformError(acc, field, resource, templatePath)
	}
	return newAcc
}

export function createFinalServerAndValidationErrors(
	error: ValidationError | Object,
	partialResult: {
		serverErrorFieldsAffected?: Array<ValidationFieldError>,
		validationError?: FormFieldErrorsMap,
	},
) {
	const result = {}
	if (partialResult.serverErrorFieldsAffected && partialResult.serverErrorFieldsAffected.length > 0)
		result.serverError = {
			...error,
			fieldsAffected: partialResult.serverErrorFieldsAffected,
		}
	if (partialResult.validationError && Object.keys(partialResult.validationError).length > 0)
		result.validationError = partialResult.validationError
	return result
}

export function getServerAndValidationErrorsForField(
	acc: { serverErrorFieldsAffected?: Array<ValidationFieldError>, validationError?: FormFieldErrorsMap },
	field: ValidationFieldError,
) {
	let newAcc = { ...acc }
	const { references } = field
	if (references && references.length > 0) {
		references.forEach((reference: ReferencesResourceField) => {
			newAcc = addAndTransformErrorBasedOnResource(newAcc, field, reference)
		})
	} else {
		if (!newAcc.serverErrorFieldsAffected) newAcc.serverErrorFieldsAffected = []
		newAcc.serverErrorFieldsAffected.push(field)
	}
	return newAcc
}

/**
 * @returns Object {serverError?, validationError?}:
 * 		validationError = error transformovany podla affectedFields ktore maju referencie (jednotliva chyba sa ukaze v prislusnom formField)
 * 		serverError = error bez validationError, resp affectedFields ktore maju prazdne referencie (jednotliva chyba sa ukaze v snackbare)
 */
export function getServerAndValidationErrors(
	error: ValidationError,
): { serverError?: ValidationError, validationError?: FormFieldErrorsMap } {
	if (!(error && error.fieldsAffected && error.fieldsAffected.length > 0)) return { serverError: error }

	const partialResult = error.fieldsAffected.reduce(
		(
			acc: { serverErrorFieldsAffected?: Array<ValidationFieldError>, validationError?: FormFieldErrorsMap },
			field: ValidationFieldError,
		) => {
			return getServerAndValidationErrorsForField(acc, field)
		},
		{},
	)

	return createFinalServerAndValidationErrors(error, partialResult)
}

export function convertAccDocIssue2ValidationFieldError(accDocIssue: AccountingDocumentIssue): ValidationFieldError {
	return {
		message: accDocIssue.message,
		code: accDocIssue.code,
		params: accDocIssue.params,
		references: accDocIssue.references,
	}
}

// export function addDummyIssuesPaths(
// 	accDocIssues: ?AccountingDocumentIssues,
// 	resourceId: string,
// ): ?AccountingDocumentIssues {
// 	if (!accDocIssues) return accDocIssues
// 	const { issues, connectedIssues } = accDocIssues
// 	if (!(issues && issues.length > 0) && !(connectedIssues && connectedIssues.length > 0)) return accDocIssues

// 	let mergedIssues = (([]: any): Array<AccountingDocumentIssue>)
// 	if (issues) mergedIssues = [...mergedIssues, ...issues]
// 	if (connectedIssues) mergedIssues = [...mergedIssues, ...connectedIssues]

// 	const allIssues = mergedIssues.map((issue: AccountingDocumentIssue): AccountingDocumentIssue => {
// 		const resource = `/accountingdocuments/${resourceId}`
// 		const path = getDummyPath(issue.code)
// 		return {
// 			...issue,
// 			references: [{ resource, path }],
// 		}
// 	})

// 	return { issues: allIssues }
// }

// export function getServerAndValidationErrorsFromIssues(
// 	accDocIssues: ?AccountingDocumentIssues,
// ): { serverError?: ValidationError, validationError?: FormFieldErrorsMap } {
// 	if (!accDocIssues) return {}
// 	const { issues, connectedIssues } = accDocIssues
// 	if (!(issues && issues.length > 0) && !(connectedIssues && connectedIssues.length > 0)) return {}

// 	let mergedIssues = (([]: any): Array<AccountingDocumentIssue>)
// 	if (issues) mergedIssues = [...mergedIssues, ...issues]
// 	if (connectedIssues) mergedIssues = [...mergedIssues, ...connectedIssues]

// 	const partialResult = mergedIssues.reduce(
// 		(
// 			acc: { serverErrorFieldsAffected?: Array<ValidationFieldError>, validationError?: FormFieldErrorsMap },
// 			accDocIssue: AccountingDocumentIssue,
// 		) => {
// 			const validationFieldError = convertAccDocIssue2ValidationFieldError(accDocIssue)
// 			return getServerAndValidationErrorsForField(acc, validationFieldError)
// 		},
// 		{},
// 	)

// 	return createFinalServerAndValidationErrors({}, partialResult)
// }

// function getIssuesFoundInServerError(
// 	issues: Array<AccountingDocumentIssue>,
// 	serverError: ValidationError,
// ): ?Array<AccountingDocumentIssue> {
// 	let result = []
// 	if (serverError && serverError.fieldsAffected && serverError.fieldsAffected.length > 0) {
// 		if (issues && issues.length > 0) {
// 			issues.forEach((issue: AccountingDocumentIssue) => {
// 				const foundIssueInServerError =
// 					serverError &&
// 					serverError.fieldsAffected &&
// 					serverError.fieldsAffected.find((field: ValidationFieldError) => {
// 						return issue.code === field.code
// 					})
// 				if (foundIssueInServerError) {
// 					result.push(issue)
// 				}
// 			})
// 		}
// 	}
// 	return result.length > 0 ? result : undefined
// }

// export function getIssuesAndValidationErrorsFromIssues(
// 	accDocIssues: ?AccountingDocumentIssues,
// ): { issues?: ?AccountingDocumentIssues, validationError?: FormFieldErrorsMap } {
// 	if (!accDocIssues) return {}
// 	const { issues, connectedIssues } = accDocIssues
// 	if (!(issues && issues.length > 0) && !(connectedIssues && connectedIssues.length > 0)) return {}

// 	const { serverError, validationError } = getServerAndValidationErrorsFromIssues(accDocIssues)
// 	let resultIssues = {}
// 	const snackbarIssues = issues && serverError && getIssuesFoundInServerError(issues, serverError)
// 	if (snackbarIssues) resultIssues.issues = snackbarIssues
// 	const snackbarConnectedIssues =
// 		connectedIssues && serverError && getIssuesFoundInServerError(connectedIssues, serverError)
// 	if (snackbarConnectedIssues) resultIssues.connectedIssues = snackbarConnectedIssues

// 	const result = Object.keys(resultIssues).length > 0 ? { issues: resultIssues, validationError } : { validationError }
// 	return ((result: any): { issues?: ?AccountingDocumentIssues, validationError?: FormFieldErrorsMap })
// }

export function getFormFieldErrorContexts(
	paths: Array<FormFieldErrorSubPath>,
	resourceName: FormFieldErrorResourceType,
	resourceId: string,
): { [FormFieldErrorSubPath]: FormFieldErrorContext } {
	const result = paths.reduce(
		(acc: { [FormFieldErrorSubPath]: FormFieldErrorContext }, path: FormFieldErrorSubPath) => {
			acc[path] = {
				resource: {
					name: resourceName,
					id: resourceId,
				},
				path: [path],
			}
			return acc
		},
		{},
	)
	return result
}

export function getTooltipErrorLabel(errors: ?Array<FormFieldErrorMessage>, t: I18NextTranslateFn) {
	if (!hasErrors(errors)) return null
	return (
		<Fragment>
			{errors &&
				uniqBy(errors, 'code').map((error: FormFieldErrorMessage, index: number) => {
					const message = getServerErrorMessage(t, error)
					return <div key={index}>{message}</div>
				})}
		</Fragment>
	)
}

export function addSubPath(formFieldErrorContext?: FormFieldErrorContext, subPath: string) {
	let result = formFieldErrorContext
	if (formFieldErrorContext && formFieldErrorContext.path) {
		const newPath = [...formFieldErrorContext.path, subPath]
		result = { ...formFieldErrorContext, path: newPath }
	}
	return result
}

export function hasFormFieldErrorsInMultiErrors(errors: ?MultiFormFieldMessages, formField: string) {
	return !!errors && errors[formField]
}

export function getFormFieldErrorsFromMultiErros(errors: ?MultiFormFieldMessages, formField: string) {
	return errors && errors[formField]
}

export function hasErrors(errors: ?Array<FormFieldErrorMessage>) {
	return errors && errors.length > 0
}

type RemoveErrorParams = {|
	formFieldErrorContext: ?FormFieldErrorContext,
	dispatch: Dispatch<any>,
	accDocId?: string,
	extraPath?: Array<string>,
|}

export function removeError(params: RemoveErrorParams) {
	const { formFieldErrorContext, dispatch, accDocId, extraPath } = params

	if (
		formFieldErrorContext &&
		formFieldErrorContext.resource &&
		formFieldErrorContext.resource.id &&
		formFieldErrorContext.path
	) {
		const { resource, path } = formFieldErrorContext
		let newPath = [...path]
		let newResource = { ...resource }

		if (extraPath) newPath.push(...extraPath)

		if (
			'Template' === formFieldErrorContext.resource.name ||
			'TemplateScheduler' === formFieldErrorContext.resource.name
		) {
			dispatch({
				type: 'REMOVE_TEMPLATE_ERROR',
				templateId: formFieldErrorContext.resource.id,
				errorUniqueId: getFormFieldErrorUniquePath(newResource, newPath),
			})
		} else if ('AccountingDocument' === formFieldErrorContext.resource.name) {
			dispatch({
				type: 'REMOVE_ACCDOC_ERROR',
				accountingDocumentId: accDocId || formFieldErrorContext.resource.id,
				errorUniqueId: getFormFieldErrorUniquePath(newResource, newPath),
			})
			if (newPath[0] === 'paymentType') {
				dispatch({
					type: 'REMOVE_ACCDOC_ERROR',
					accountingDocumentId: accDocId || (formFieldErrorContext.resource && formFieldErrorContext.resource.id),
					errorUniqueId: getFormFieldErrorUniquePath(newResource, ['bankAccounts']),
				})
			}
		}
	}
}

export function toMultiFormFieldErrors(errors: ?FormFieldErrorsMap, uniqueId: string): ?MultiFormFieldMessages {
	return (
		errors &&
		Object.entries(errors)
			.filter((entry: [string, mixed]) => {
				const [id: string] = entry
				return id.includes(uniqueId)
			})
			.reduce((acc: MultiFormFieldMessages, entry: [string, mixed]) => {
				const [id: string, ffeMixed: mixed] = entry
				const ffe = ((ffeMixed: any): FormFieldError)
				const splitId = id.split(uniqueId)
				const fieldName = splitId && splitId.length > 1 && splitId[1]
				if (fieldName) {
					acc[fieldName] = ffe.messages
				}
				return acc
			}, {})
	)
}
