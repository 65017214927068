/* @flow */

import { find, findLastKey } from 'lodash-es'
import type { Dispatch, ScanFactoryDocumentFileMetadata, State } from 'types'
import { finishEditField, selectField } from '../actions'
import { getMetadata } from '../selectors'
import type { AutoFillMap, FieldCursor } from '../types'
import { autoFill } from './autofill'
import { createAutoFillMap } from './autofill-map'
import { getNextFieldCursor } from './field'

export default (async function runAutoFill(dispatch: Dispatch<any>, getState: () => State, fieldCursor: FieldCursor) {
	const state = getState()
	const metadata = getMetadata(state)

	if (metadata) {
		const map = createAutoFillMap(dispatch, getState)

		skipFilledFields(dispatch, metadata, map, fieldCursor)

		return autoFill(metadata, fieldCursor, map, (fieldCursor: FieldCursor, value: string) => {
			dispatch(finishEditField(fieldCursor, value, undefined, true))
		})
	}
})

function skipFilledFields(
	dispatch: Dispatch<any>,
	metadata: ScanFactoryDocumentFileMetadata,
	fillingMap: AutoFillMap,
	fieldCursor: FieldCursor,
) {
	const fieldFillingMap = find(fillingMap, { fieldName: fieldCursor.name })

	if (fieldFillingMap) {
		const lastFillingField = findLastKey(fieldFillingMap.filling)
		const lastFillingFieldCursor = { ...fieldCursor, name: lastFillingField }
		const nextFieldCursor = getNextFieldCursor(metadata, lastFillingFieldCursor)

		if (nextFieldCursor) {
			dispatch(selectField(nextFieldCursor))
		}
	}
}
