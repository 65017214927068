/* @flow */

import deepEqual from 'deep-equal'
import type {
	Bank,
	BankAccount,
	BankAccountStatementOptions,
	BankStatementType,
	PaymentOrder,
	Statement,
	BankAccountStatementOptionsFormatType,
	BankAction,
} from 'types'
import { EMPTY_ARRAY, EMPTY_OBJECT } from 'trivi-constants'

type BankAccountsState = {
	loading: boolean,
	data: Array<BankAccount>,
}

type BankAccountState = {
	loading: boolean,
	data: ?BankAccount,
}

type PaymentOrdersState = {
	bankAccountId?: string,
	loading: boolean,
	data: Array<PaymentOrder>,
}

type StatementsState = {
	bankAccountId?: string,
	loading: boolean,
	data: Array<Statement>,
}

type StatementState = {
	loading: boolean,
	data: ?Statement,
	bankAccountId?: string,
}

type StatementOptionsState = {
	data: { [key: string]: BankAccountStatementOptions },
	dialogOpen: boolean,
	format: ?BankAccountStatementOptionsFormatType,
	loading: boolean,
}

type ProcessedStatementState = {
	loading: boolean,
	statementId?: string,
	bankAccountId?: string,
}

type BankState = {
	loading: boolean,
	data: ?Array<Bank>,
}

type StatementTypesState = {
	loading: boolean,
	data: Array<BankStatementType>,
	bankId?: string,
}

type PaymentOrderTypesState = {
	loading: boolean,
	data: Array<BankStatementType>,
	bankId?: string,
}

export type State = {
	bankAccounts: BankAccountsState,
	bankAccount: BankAccountState,
	paymentOrders: PaymentOrdersState,
	statements: StatementsState,
	statementOptions: StatementOptionsState,
	uploadedStatements: StatementsState,
	deletedStatement: StatementState,
	processedStatement: ProcessedStatementState,
	banks: BankState,
	statementTypes: StatementTypesState,
	paymentOrderTypes: PaymentOrderTypesState,
}

export const initialState: State = {
	bankAccounts: {
		loading: false,
		data: EMPTY_ARRAY,
	},
	bankAccount: {
		loading: false,
		data: null,
	},
	paymentOrders: {
		loading: false,
		data: EMPTY_ARRAY,
	},
	statements: {
		loading: false,
		data: EMPTY_ARRAY,
	},
	statementOptions: {
		loading: false,
		dialogOpen: false,
		format: null,
		data: EMPTY_OBJECT,
	},
	uploadedStatements: {
		loading: false,
		data: EMPTY_ARRAY,
	},
	deletedStatement: {
		loading: false,
		data: null,
	},
	processedStatement: {
		loading: false,
	},
	banks: {
		loading: false,
		data: null,
	},
	statementTypes: {
		loading: false,
		data: EMPTY_ARRAY,
	},
	paymentOrderTypes: {
		loading: false,
		data: EMPTY_ARRAY,
	},
}

// TODO: Ve flow je chyba, u disjoint union typů nerozpozná případy, který nemůžou nastat, ale pracujou na tom
// https://github.com/facebook/flow/issues/2399

export default (state: State = initialState, action: BankAction): State => {
	switch (action.type) {
		case 'START_LOAD_BANK_ACCOUNTS': {
			const bankAccounts = {
				loading: true,
				data: state.bankAccounts.data,
			}
			return {
				...state,
				bankAccounts: bankAccounts,
			}
		}

		case 'FINISH_LOAD_BANK_ACCOUNTS': {
			return {
				...state,
				bankAccounts: {
					loading: false,
					data: deepEqual(state.bankAccounts.data, action.bankAccounts) ? state.bankAccounts.data : action.bankAccounts,
				},
			}
		}

		case 'START_LOAD_BANK_ACCOUNT': {
			return {
				...state,
				bankAccount: {
					...state.bankAccount,
					loading: true,
				},
			}
		}

		case 'FINISH_LOAD_BANK_ACCOUNT': {
			const bankAccount = {
				loading: false,
				data: action.bankAccount,
			}
			return {
				...state,
				bankAccount: bankAccount,
			}
		}

		case 'START_CREATE_BANK_ACCOUNT': {
			const bankAccount = {
				loading: true,
				data: state.bankAccount.data,
			}
			return {
				...state,
				bankAccount: bankAccount,
			}
		}

		case 'FINISH_CREATE_BANK_ACCOUNT': {
			const bankAccount = {
				loading: false,
				data: action.bankAccount,
			}
			return {
				...state,
				bankAccount: bankAccount,
			}
		}

		case 'START_UPDATE_BANK_ACCOUNT': {
			const bankAccount = {
				loading: true,
				data: state.bankAccount.data,
			}
			return {
				...state,
				bankAccount: bankAccount,
			}
		}

		case 'FINISH_UPDATE_BANK_ACCOUNT': {
			const bankAccount = {
				loading: false,
				data: action.bankAccount,
			}
			return {
				...state,
				bankAccount: bankAccount,
			}
		}

		case 'START_LOAD_PAYMENT_ORDERS': {
			const paymentOrders: PaymentOrdersState = {
				loading: true,
				data: state.paymentOrders.data,
				bankAccountId: action.bankAccountId,
			}
			return {
				...state,
				paymentOrders: paymentOrders,
			}
		}

		case 'FINISH_LOAD_PAYMENT_ORDERS': {
			const paymentOrders: PaymentOrdersState = {
				loading: false,
				data: action.paymentOrders,
				bankAccountId: action.bankAccountId,
			}
			return {
				...state,
				paymentOrders: paymentOrders,
			}
		}

		case 'START_LOAD_STATEMENTS': {
			const statements: StatementsState = {
				...state.statements,
				loading: true,
				data: state.statements.data,
				bankAccountId: action.bankAccountId,
			}
			return {
				...state,
				statements: statements,
			}
		}

		case 'FINISH_LOAD_STATEMENTS': {
			const statements: StatementsState = {
				...state.statements,
				loading: false,
				data: action.statements,
				bankAccountId: action.bankAccountId,
			}
			return {
				...state,
				statements: statements,
			}
		}

		case 'START_UPLOAD_STATEMENTS': {
			const uploadedStatements: StatementsState = {
				loading: true,
				data: EMPTY_ARRAY,
				bankAccountId: action.bankAccountId,
			}
			return {
				...state,
				uploadedStatements: uploadedStatements,
			}
		}

		case 'FINISH_UPLOAD_STATEMENTS': {
			const uploadedStatements: StatementsState = {
				loading: false,
				data: action.statements,
				bankAccountId: action.bankAccountId,
			}
			return {
				...state,
				uploadedStatements: uploadedStatements,
			}
		}

		case 'START_DELETE_STATEMENT': {
			const deletedStatement: StatementState = {
				loading: true,
				data: null,
				bankAccountId: action.bankAccountId,
			}
			return {
				...state,
				deletedStatement: deletedStatement,
			}
		}

		case 'FINISH_DELETE_STATEMENT': {
			const deletedStatement: StatementState = {
				loading: false,
				data: action.statement,
				bankAccountId: action.bankAccountId,
			}
			return {
				...state,
				deletedStatement: deletedStatement,
			}
		}

		case 'START_PROCESS_STATEMENT': {
			const processedStatement: ProcessedStatementState = {
				loading: true,
				statementId: action.statementId,
				bankAccountId: action.bankAccountId,
			}
			return {
				...state,
				processedStatement: processedStatement,
			}
		}

		case 'FINISH_PROCESS_STATEMENT': {
			const processedStatement: ProcessedStatementState = {
				loading: false,
				statementId: action.statementId,
				bankAccountId: action.bankAccountId,
			}
			return {
				...state,
				processedStatement: processedStatement,
			}
		}

		case 'START_LOAD_BANK_STATEMENT_TYPES': {
			const statementTypes: StatementTypesState = {
				loading: true,
				data: state.statementTypes.data,
				bankId: action.bankId,
			}
			return {
				...state,
				statementTypes: statementTypes,
			}
		}

		case 'FINISH_LOAD_BANK_STATEMENT_TYPES': {
			const statementTypes: StatementTypesState = {
				loading: true,
				bankId: action.bankId,
				data: action.statementTypes,
			}
			return {
				...state,
				statementTypes: statementTypes,
			}
		}

		case 'START_LOAD_BANK_STATEMENT_OPTIONS': {
			return {
				...state,
				statementOptions: {
					...state.statementOptions,
					loading: true,
				},
			}
		}

		case 'FINISH_LOAD_BANK_STATEMENT_OPTIONS': {
			return {
				...state,
				statementOptions: {
					...state.statementOptions,
					loading: false,
					data: {
						...state.statementOptions.data,
						[action.bankAccountId]: action.statementOptions,
					},
				},
			}
		}

		case 'START_SAVE_BANK_STATEMENT_OPTIONS':
			return updateBankStatementOptions(state, action.bankAccountId, action.statementOptions)

		case 'FINISH_SAVE_BANK_STATEMENT_OPTIONS': {
			if (!action.validationError) {
				return state
			}

			return updateBankStatementOptions(state, action.bankAccountId, action.statementOptions)
		}

		case 'BANK_STATEMENT_OPTIONS_DIALOG_TOGGLE': {
			return {
				...state,
				statementOptions: {
					...state.statementOptions,
					dialogOpen: action.open,
					format: action.format,
				},
			}
		}

		case 'START_LOAD_BANK_PAYMENT_ORDER_TYPES': {
			const paymentOrderTypes: PaymentOrderTypesState = {
				loading: true,
				data: state.paymentOrderTypes.data,
				bankId: action.bankId,
			}
			return {
				...state,
				paymentOrderTypes: paymentOrderTypes,
			}
		}

		case 'FINISH_LOAD_BANK_PAYMENT_ORDER_TYPES': {
			const paymentOrderTypes: PaymentOrderTypesState = {
				loading: false,
				data: action.paymentOrderTypes,
				bankId: action.bankId,
			}
			return {
				...state,
				paymentOrderTypes: paymentOrderTypes,
			}
		}

		case 'START_LOAD_BANKS': {
			const banks: BankState = {
				loading: true,
				data: EMPTY_ARRAY,
			}
			return {
				...state,
				banks: banks,
			}
		}

		case 'FINISH_LOAD_BANKS': {
			const banks: BankState = {
				loading: false,
				data: action.banks,
			}
			return {
				...state,
				banks: banks,
			}
		}

		default:
			return state
	}
}

function updateBankStatementOptions(
	state: State,
	bankAccountId: string,
	statementOptions: BankAccountStatementOptions,
): State {
	return {
		...state,
		statementOptions: {
			...state.statementOptions,
			dialogOpen: false,
			data: {
				...state.statementOptions.data,
				[bankAccountId]: statementOptions,
			},
		},
	}
}
