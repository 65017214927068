/* @flow */

import type {
	AccountingDocument,
	AccountingDocumentAction,
	AccountingDocumentActions,
	AccountingDocumentCategory,
	AccountingDocumentDirection,
	AccountingDocumentScan,
	AccountingDocumentView,
	CashRegister,
	CashRegisters,
	Currency,
	DataGridId,
	DeferredPromise,
	DialogResult,
	Enum,
	EnumItem,
	FileDescription,
	Filter,
	I18NextTranslateFn,
	MultiAction,
	SearchFilter,
	Task,
	WithRouterProps,
} from 'types'
import { DataGrid as DataGridNext, type DataGridProps as DataGridNextProps } from 'modules/data-grid-next/containers'
import { EMPTY_ARRAY, MULTI_ACTION_TYPE_CLEAR_SELECTION } from 'trivi-constants'
import React, { Component } from 'react'
import type {
	WithAccountingDocumentActionsProps,
	WithNotifyProps,
	WithOrganizationSettingsProps,
	WithTranslateProps,
} from 'wrappers'
import {
	accountingDocumentImportRoute,
	editAccountingDocumentRoute,
	showAccountingDocumentRoute,
} from 'modules/accounting-document/routing/routes'
import { canBeAccDocCopied, createDeferredPromise, getAccountingDocumentActions, isGridActionAllowed } from 'helpers'
import { compact, uniq } from 'lodash-es'
import {
	getAccDocFilterName,
	isAccountingDocumentLocked,
	isAccountingDocumentNeedHelp,
} from '../../domain/accounting-document'
import {
	getAccountingDocumentNoColumn,
	getAuthorColumn,
	getCategoryColumn,
	getCompanyColumn,
	getDueDateColumn,
	getExtractingStateColumn,
	getIssueDateColumn,
	getNoteColumn,
	getProcessingMessageColumn,
	getStateColumn,
	getTaxDateColumn,
	getTotalColumn,
	getUploadDetailsColumn,
	getUploadedDateColumn,
} from '../data-grid-columns'
import {
	showCashRegisterMultiAction,
	showJoinMultiAction,
	showUnJoinMultiAction,
} from 'modules/accounting-document/domain/accounting-document-multi-actions'
import { showCashbotPanelOnAccDocGrid, showCashbotPayOnAccDocGridLine } from 'modules/cashbot/domain'
import { withAccountingDocumentActions, withNotify, withOrganizationSettings, withTranslate } from 'wrappers'

import AccountingDocumentAdvancedFilter from './advanced-filter'
import ApproveDialog from '../../containers/accounting-document-list/approve-dialog'
import CashRegisterDialog from 'modules/common/containers/cash-register-dialog'
import CashbotPanelAuthOnly from 'modules/cashbot/components/cashbot-panel/cashbot-panel-authonly'
import ConfirmDialog from 'components/confirm-dialog'
import CreditNoteDialog from '../../containers/invoice-elements/credit-note-dialog'
import type { Action as DataGridAction } from 'modules/data-grid/types'
import DeleteDialog from 'components/delete-dialog'
import DocumentPreviewDialog from './document-preview-dialog'
import DocumentsCounter from 'modules/accounting-document/components/accounting-document-list/documents-counter'
import EditAttributesDialog from 'modules/accounting-document/components/accounting-document-list/edit-attributes-dialog'
import ExportButton from '../export/export-button'
import Features from 'utils/features'
import IconButton from 'components/icon-button'
import ImportIcon from 'components/svg-icons/trivi/import'
import InboundEmails from '../../containers/accounting-document-list/inbound-emails'
import InboundEmailsButton from './inbound-emails-button'
import MoveDialog from 'modules/common/containers/move-dialog'
import PaymentDialog from 'modules/common/containers/payment-dialog'
import PreviewDialog from '../../components/accounting-document-list/preview-dialog'
import SendEmailDialog from 'modules/common/containers/send-email-dialog'
import SendReminderEmailDialog from '../../../common/containers/send-reminder-email-dialog'
import ShowEmailDialog from 'modules/common/components/dialogs/show-email-dialog'
import TaskEdit from 'modules/task/containers/task-edit'
import TemplateButton from './template-button'
import Tracking from 'utils/tracking'
import { UPLOAD_TO_BE_APPROVED } from 'modules/accounting-document/constants'
import UnJoinDialog from './unjoin-dialog'
import { accountingDocumentPublicRoute } from '../../routing/routes'
import { getCategoriesEnum } from '../../domain/categories'
import memoize from 'memoize-one'
import { openFile } from '../../../file/domain'
import styles from './grid.css'
import { withRouter } from 'react-router-dom'

// import CashbotDialog from 'modules/cashbot/components/cashbot-dialog'
// import { isEetSent } from 'modules/accounting-document/domain/eet'

const ALLOWED_TYPES = [0, 1, 2, 3]
const GRID_ID: DataGridId = 'accountingDocuments'
const DATA_TYPE = 'accountingDocuments'
const AccountingDocumentDataGrid: React$ComponentType<DataGridNextProps<typeof DATA_TYPE>> = DataGridNext

export type Props = {|
	direction: AccountingDocumentDirection,
	view: AccountingDocumentView,
	showProcessingState: boolean,
	automaticApproval: boolean,
	currentUserIsInternal: boolean,
	documentCategories: ?Array<AccountingDocumentCategory>,
	branches: ?Enum,
	projects: ?Enum,
	currencies: Array<Currency>,
	cashRegisters: ?CashRegisters,
	paymentTypes: ?Enum,
	preProcessingAccountingDocumentPreview: boolean,
	isAdvancedFilterOpen: boolean,
	loadBranches: () => void,
	loadCurrencies: () => void,
	loadProjects: () => void,
	loadCashRegisters: () => void,
	loadPaymentTypes: () => void,
	loadAccountingDocumentCategories: () => void,
	onCustomerInstructionsChange: (string, AccountingDocument) => void,
	onViewChange: (view: string) => void,
	refreshGrid: (defaultFilter?: Filter, filterName: string) => Promise<void>,
	refreshGridLine: (accountingDocumentId: string) => void,
	// changeEET: (accountingDocument: AccountingDocument, operation: EETOperation) => void,
	onOpenFilter: () => void,
	onCloseFilter: () => void,
|}

function reduceCashRegisters(cashRegisters: ?CashRegisters): ?Enum {
	if (!cashRegisters) return null
	return cashRegisters.reduce((result: Enum, cashRegister: CashRegister) => {
		if (null == cashRegister.id) return result
		return [...result, { key: cashRegister.id, value: cashRegister.name || '' }]
	}, [])
}

type State = {
	accountingDocuments: ?Array<AccountingDocument>,
	paymentOpen: boolean,
	inboundEmailsOpen: boolean,
	dialogRelatedDocument: ?string,
	sendEmailDialogDocument: ?AccountingDocument,
	sendReminderEmailDialogDocument: ?AccountingDocument,
	editAttributesDialogDocument: ?AccountingDocument,
	showEmailDialogDocument: ?AccountingDocument,
	showMoveDialogDocuments: ?Array<AccountingDocument>,
	approvalDialogDocument: ?AccountingDocument,
	previewDialogDocument: ?AccountingDocument,
	// newTaskDialogOpened: boolean,
	selectedDocument: ?AccountingDocument,
	cashRegisterSelectionDialogOpened: boolean,
	isConfirmDialogOpen: boolean,
	unJoinDialog: ?boolean,
	cashbotDialog: ?boolean,
	clickedRowDocument: ?AccountingDocument,
}

type ComponentProps = Props &
	WithOrganizationSettingsProps &
	WithTranslateProps &
	WithAccountingDocumentActionsProps &
	WithRouterProps &
	WithNotifyProps

class AccountingDocumentGrid extends Component<ComponentProps, State> {
	state: State = {
		accountingDocuments: null,
		paymentOpen: false,
		inboundEmailsOpen: false,
		dialogRelatedDocument: null,
		sendEmailDialogDocument: null,
		sendReminderEmailDialogDocument: null,
		editAttributesDialogDocument: null,
		showEmailDialogDocument: null,
		showMoveDialogDocuments: null,
		approvalDialogDocument: null,
		previewDialogDocument: null,
		// newTaskDialogOpened: false,
		selectedDocument: null,
		cashRegisterSelectionDialogOpened: false,
		isConfirmDialogOpen: false,
		unJoinDialog: null,
		cashbotDialog: null,
		clickedRowDocument: null,
	}

	columns: { [string]: {} }
	defaultFilter: Array<SearchFilter> = EMPTY_ARRAY
	onMoveDialogDocumentClosePrm: DeferredPromise<?DialogResult>

	constructor(props: ComponentProps) {
		super(props)
		this.defaultFilter = this.getDefaultFilter(props.direction, props.view)
	}

	UNSAFE_componentWillMount() {
		if (!this.props.documentCategories || this.props.documentCategories.length < 1) {
			this.props.loadAccountingDocumentCategories()
		}
		if (!this.props.currencies || this.props.currencies.length < 1) this.props.loadCurrencies()
		if (!this.props.branches || this.props.branches.length < 1) this.props.loadBranches()
		if (!this.props.projects || this.props.projects.length < 1) this.props.loadProjects()
		if (!this.props.cashRegisters || this.props.cashRegisters.length < 1) this.props.loadCashRegisters()
		if (!this.props.paymentTypes || this.props.paymentTypes.length < 1) this.props.loadPaymentTypes()
	}

	componentDidMount() {
		if (!this.props.organizationSettings) {
			this.props.loadOrganizationSettings()
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps: ComponentProps) {
		if (this.props.direction !== nextProps.direction || this.props.view !== nextProps.view) {
			this.defaultFilter = this.getDefaultFilter(nextProps.direction, nextProps.view)
		}
	}

	getDefaultFilter = memoize((direction: ?AccountingDocumentDirection, view: AccountingDocumentView) => {
		const defaultFilter: Array<SearchFilter> = []

		switch (direction) {
			case 'received':
			case 'issued':
				defaultFilter.push({
					field: 'direction',
					value: direction === 'received' ? 0 : 1,
				})
				defaultFilter.push({
					field: 'excludeAccDocSearchThresholdId',
					value: true,
				})
				switch (view) {
					case 'all':
						defaultFilter.push({
							field: 'type',
							valueContains: [0, 1, 2, 3],
						})
						break
					case 'errors':
						defaultFilter.push(
							{
								field: 'processingState',
								value: 3,
							},
							{
								field: 'type',
								valueContains: [0, 1, 2, 3],
							},
						)
						break
					case 'isProcessedWithoutOK':
						defaultFilter.push(
							{
								field: 'type',
								valueContains: [0, 1, 2, 3],
							},
							{
								field: 'isProcessedWithOK',
								value: false,
							},
						)
						break
					case 'drafts':
						defaultFilter.push(
							{
								field: 'type',
								valueContains: [0, 1, 2, 3],
							},
							{
								field: 'state',
								value: 'Draft',
							},
						)
						break
				}
				break
			case 'unknown':
				defaultFilter.push({
					field: 'type',
					value: 9,
				})
				switch (view) {
					case 'all':
						defaultFilter.push({
							field: 'ExtractingState',
							valueContains: [0, 1, 2, 3, 4, 5, 6, 7],
						})
						break
					case 'approved':
						defaultFilter.push({
							field: 'ExtractingState',
							valueContains: [6],
						})
						break
					case 'rejected':
						defaultFilter.push({
							field: 'ExtractingState',
							valueContains: [7],
						})
						break
					case 'approvedAndWaitingApproval':
						defaultFilter.push({
							field: 'ExtractingState',
							valueContains: [2, 4, 5, 6],
						})
						break
				}
		}

		return defaultFilter
	})

	refreshGrid = async () => {
		const filterName = getAccDocFilterName(this.props.direction)
		await this.props.refreshGrid(this.defaultFilter, filterName)
	}

	refreshGridLine = (accountingDocumentId: string) => {
		this.props.refreshGridLine(accountingDocumentId)
	}

	isImportEnabled = () => {
		return (
			Features.isEnabled('accountingDocumentImport') &&
			(this.props.canEditReceivedAccountingDocuments || this.props.canEditIssuedAccountingDocuments)
		)
	}

	isAccountingDocumentApproved = (accountingDocument: AccountingDocument) => {
		return (
			this.props.automaticApproval ||
			(accountingDocument.__extractingState !== 5 && accountingDocument.__extractingState !== 7)
		)
	}

	areAccountingDocumentsApproved = (accountingDocuments: Array<AccountingDocument>): boolean => {
		return accountingDocuments.reduce((approved: boolean, accountingDocument: AccountingDocument) => {
			return !!(approved && this.isAccountingDocumentApproved(accountingDocument))
		}, true)
	}

	isVisibleOptimizedFilter = (view: string) => {
		return view != 'unknown'
	}

	isVisibleAction = (type: string) => {
		return (row: AccountingDocument): boolean => {
			const {
				availableActions,
				currentUserIsInternal,
				canEditIssuedAccountingDocuments,
				canEditReceivedAccountingDocuments,
				canEditUnknownAccountingDocuments,
				canProcessAccountingDocuments,
				canRemoveIssuedAccountingDocuments,
				canRemoveReceivedAccountingDocuments,
				canRemoveUnknownAccountingDocuments,
			} = this.props
			const permissions = {
				editIssuedAccountingDocuments: canEditIssuedAccountingDocuments,
				editReceivedAccountingDocuments: canEditReceivedAccountingDocuments,
				editUnknownAccountingDocuments: canEditUnknownAccountingDocuments,
				processAccountingDocuments: canProcessAccountingDocuments,
				removeIssuedAccountingDocuments: canRemoveIssuedAccountingDocuments,
				removeReceivedAccountingDocuments: canRemoveReceivedAccountingDocuments,
				removeUnknownAccountingDocuments: canRemoveUnknownAccountingDocuments,
			}

			if (
				(type === 'accdoc_edit' ||
					type === 'accdoc_remove' ||
					type === 'accdoc_move' ||
					type === 'accdoc_change_category' ||
					type === 'accdoc_change_branch' ||
					type === 'accdoc_change_project' ||
					type === 'accdoc_change_cashregister' ||
					type === 'accdoc_change_paymenttype') &&
				this.props.direction === 'unknown' &&
				this.props.currentUserIsInternal &&
				!this.isAccountingDocumentApproved(row)
			) {
				return false
			}

			if (type === 'accdoc_move' && this.props.direction === 'unknown') return true

			// Pokladna - doklady, které nemají pripojeny scan, nemají mít možnost Znovu vytěžit.
			if (type === 'accdoc_reextract') {
				if (!row.scans || !row.scans.length) {
					return false
				}
			}

			if (
				type === 'accdoc_approve' &&
				!this.props.automaticApproval &&
				this.props.direction === 'unknown' &&
				row.__extractingState === UPLOAD_TO_BE_APPROVED
			)
				return true

			if (!availableActions || !isGridActionAllowed(permissions, type, row)) {
				return false
			}

			const availableActionsState: ?{ data?: ?AccountingDocumentActions } = availableActions[row.id || '']
			const _availableActions: ?AccountingDocumentActions = availableActionsState && availableActionsState.data

			// Vynutit zaúčtování a Změnit stav na Zaúčtováno nedavají u již zaúčtovaných dokladů smysl.
			// POZN: Vynutit zaúčtování - pro účetní nechat, je to znovuposlání již zaúčtovaného dokladu // do ESO (smaže doklad v ESO a potřebuje ho tam z nějakého důvodu poslat znovu,
			// např. změna měny atd)
			// viz TESTREP-305
			if (row && row.processingState === 1) {
				if (type === 'accdoc_force_processing' && !currentUserIsInternal) return false
				if (type === 'accdoc_set_processing_state') return false
			}

			if (type === 'accdoc_copy') {
				const canCopy =
					(this.props.currentUserIsInternal || (!this.props.currentUserIsInternal && row.direction === 1)) &&
					canBeAccDocCopied(row.type)
				if (!canCopy) return false
			}

			if (type === 'accdoc_unjoin') {
				return !!(row.scans && row.scans.length > 1 && 1 !== row.processingState)
			}

			if (type === 'accdoc_cashbot') {
				return showCashbotPayOnAccDocGridLine(row)
			}

			if (type === 'accdoc_send_email') {
				return row.direction === 1
			}

			if (!_availableActions) {
				return false
			}

			if (type === 'accdoc_send_due_date') {
				return (
					_availableActions &&
					_availableActions.findIndex((availableAction: AccountingDocumentAction) => availableAction.type === type) !==
						-1 &&
					row.contact !== null
				)
			}

			return (
				_availableActions.findIndex((availableAction: AccountingDocumentAction) => availableAction.type === type) !== -1
			)
		}
	}

	handleUpdateMultiActionVisibility = async (rows: Array<AccountingDocument>): Promise<Array<string>> => {
		let uploadedActions = []
		if (this.props.direction === 'unknown') {
			uploadedActions.push(
				'change_category',
				'change_branch',
				'change_project',
				'change_cashregister',
				'change_paymenttype',
				'accdoc_move',
			)
			if (showJoinMultiAction(rows)) uploadedActions.push('accdoc_join')
			if (showUnJoinMultiAction(rows)) uploadedActions.push('accdoc_unjoin')
		}

		let availableActions: AccountingDocumentActions = await this.props.loadAvailableActions(
			rows.map((item: AccountingDocument) => item.id || ''),
		)

		if (
			this.props.direction === 'unknown' &&
			this.props.currentUserIsInternal &&
			!this.areAccountingDocumentsApproved(rows)
		) {
			availableActions = availableActions.filter((action: AccountingDocumentAction) => {
				return !(
					action.type === 'accdoc_edit' ||
					action.type === 'accdoc_remove' ||
					action.type === 'accdoc_move' ||
					action.type === 'accdoc_change_category' ||
					action.type === 'accdoc_change_branch' ||
					action.type === 'accdoc_change_project' ||
					action.type === 'accdoc_change_cashregister' ||
					action.type === 'accdoc_change_paymenttype'
				)
			})
		}

		if (!availableActions) {
			return uploadedActions
		}

		const index: number = availableActions.findIndex(
			(item: AccountingDocumentAction) => item.type === 'force_processing',
		)
		if (index > -1 && !this.props.canProcessAccountingDocuments) {
			availableActions.splice(index, 1)
		}

		const moveActionsList: Array<string> = []
		let actionsList: Array<string> = availableActions.map((availabileAction: AccountingDocumentAction) => {
			if (
				availabileAction.type === 'accdoc_movewf' &&
				availabileAction.params &&
				availabileAction.params.possibleStates
			) {
				availabileAction.params.possibleStates.forEach((possibleState: string) =>
					moveActionsList.push('movewf_' + possibleState.replace(' ', '_').toLowerCase()),
				)
			}
			return (availabileAction.type || '').replace('accdoc_', '')
		})

		if (!showCashRegisterMultiAction(rows)) {
			actionsList = actionsList.filter((action: string) => action !== 'change_cashregister')
			uploadedActions = uploadedActions.filter((action: string) => action !== 'change_cashregister')
		}

		return uniq([...actionsList, ...moveActionsList, ...uploadedActions])
	}

	isVisibleMoveAction = (state: string) => {
		const type = 'accdoc_movewf'
		return (row: AccountingDocument): boolean => {
			if (!this.props.availableActions) {
				return false
			}
			const availableActionsState: ?{ data?: ?AccountingDocumentActions } = this.props.availableActions[row.id || '']
			const availableActions: ?AccountingDocumentActions = availableActionsState && availableActionsState.data

			if (!availableActions) {
				return false
			}

			const action = availableActions.find(
				(availabileAction: AccountingDocumentAction) => availabileAction.type === type,
			)

			if (!action || !action.params || !action.params.possibleStates) {
				return false
			}

			return action.params.possibleStates.findIndex((item: string) => item === state) !== -1
		}
	}

	handlePaymentClose = () => {
		this.setState(
			{
				accountingDocuments: null,
				paymentOpen: false,
			},
			() => {
				this.refreshGrid()
			},
		)
	}

	hideCreditNote = () => {
		this.setState({ dialogRelatedDocument: null })
	}

	saveCreditNote = (value: { date: string, files: Array<FileDescription> }) => {
		const { dialogRelatedDocument } = this.state
		this.setState({ dialogRelatedDocument: null })
		this.props.confirmCreditNote(dialogRelatedDocument, value)
	}

	onUnJoinDocument = (scans: Array<AccountingDocumentScan>) => {
		this.closeUnJoinDialog()
		if (this.state.selectedDocument) {
			this.props.unJoinAccountingDocuments(this.state.selectedDocument, scans).then(this.refreshGrid)
		}
	}

	closeEmailSendDialog = () => {
		this.setState({ sendEmailDialogDocument: null })
	}

	closeReminderEmailSendDialog = () => {
		this.setState({ sendReminderEmailDialogDocument: null })
	}

	closeEditAttributesDialog = () => {
		this.setState({ editAttributesDialogDocument: null })
		this.refreshGrid()
	}

	closeEmailShowDialog = () => {
		this.setState({ showEmailDialogDocument: null })
	}

	closeMoveDialog = () => {
		this.setState({ showMoveDialogDocuments: null })
		this.refreshGrid()
	}

	closeApprovalDialog = () => {
		this.setState({ approvalDialogDocument: null })
		this.refreshGrid()
	}

	closePreviewDialog = () => {
		this.setState({ previewDialogDocument: null })
	}

	closeUnJoinDialog = () => {
		this.setState({ unJoinDialog: null })
		this.refreshGrid()
	}

	closeCashbotDialog = () => {
		this.setState({ cashbotDialog: null })
		this.refreshGrid()
	}

	onSendDocument = (emails: Array<string>, templateId: number) => {
		const { sendEmailDialogDocument } = this.state
		this.props.sendAccountingDocument((sendEmailDialogDocument && sendEmailDialogDocument.id) || '', templateId, emails)
		this.closeEmailSendDialog()
		this.refreshGrid()
	}

	onSendReminder = (emails: Array<string>, templateId: number) => {
		const { sendReminderEmailDialogDocument } = this.state
		this.props.sendReminderAccountingDocument(
			(sendReminderEmailDialogDocument && sendReminderEmailDialogDocument.id) || '',
			templateId,
			emails,
		)
		this.closeReminderEmailSendDialog()
		this.refreshGrid()
	}

	getMultiActions = memoize(
		(
			t: I18NextTranslateFn,
			bulkAction: (string, Array<string>, Object) => Promise<*>,
			documentCategories: ?Array<AccountingDocumentCategory>,
			branches: ?Enum,
			projects: ?Enum,
			cashRegisters: ?CashRegisters,
			paymentTypes: ?Enum,
		): Array<DataGridAction<Array<AccountingDocument>>> => {
			const assignAndUpdate = (operation: string, selected: Array<AccountingDocument>, additionalData?: {}) => {
				let updated = compact(selected.map((x: AccountingDocument) => x.id))
				if (updated.length > 0) {
					bulkAction(operation, updated, additionalData || {}).then(() => {
						this.refreshGrid()
					})
				}
			}

			return [
				{
					name: t('accountingDocument.multiActions.accdocJoin'),
					type: 'accdoc_join',
				},
				{
					name: t('accountingDocument.multiActions.manuallyPaid'),
					type: 'manually_paid',
				},
				{
					name: t('accountingDocument.multiActions.manuallyUnpaid'),
					type: 'manually_unpaid',
				},
				{
					name: t('accountingDocument.multiActions.movewfDraft'),
					type: 'movewf_draft',
				},
				{
					name: t('accountingDocument.multiActions.movewfWaitingForApproval'),
					type: 'movewf_waiting_for_approval',
				},
				{
					name: t('accountingDocument.multiActions.movewfProcessed'),
					type: '',
				},
				{
					name: t('accountingDocument.multiActions.payTransfer'),
					type: 'pay_transfer',
				},
				{
					name: t('accountingDocument.multiActions.remove'),
					type: 'remove',
				},
				{
					name: t('accountingDocument.multiActions.forceProcessing'),
					type: 'force_processing',
				},
				{
					name: 'branch',
					type: 'change_branch',
					key: 'preferredBranch',
					items: branches,
					hintText: t('accountingDocument.multiActions.change_branch.emptyValue'),
					onAction: (value: ?string, selected: Array<AccountingDocument>) =>
						assignAndUpdate('setpreferredbranch', selected, {
							preferredBranchId: value,
						}),
				},
				{
					name: 'project',
					type: 'change_project',
					key: 'preferredProject',
					items: projects,
					hintText: t('accountingDocument.multiActions.change_project.emptyValue'),
					onAction: (value: ?string, selected: Array<AccountingDocument>) =>
						assignAndUpdate('setpreferredproject', selected, {
							preferredProjectId: value,
						}),
				},
				{
					name: 'paymenttype',
					type: 'change_paymenttype',
					key: 'paymentType',
					items: paymentTypes,
					hintText: t('accountingDocument.multiActions.change_paymenttype.emptyValue'),
					onAction: (value: ?string, selected: Array<AccountingDocument>) => {
						const paymentType = parseInt(value)
						assignAndUpdate('setpaymenttype', selected, {
							paymentType,
						})

						2 !== paymentType &&
							assignAndUpdate('setcashregister', selected, {
								cashRegisterId: null,
							})
					},
				},
				{
					name: 'cashregister',
					type: 'change_cashregister',
					key: 'cashRegisterId',
					items: reduceCashRegisters(cashRegisters),
					hintText: t('accountingDocument.multiActions.change_cashregister.emptyValue'),
					onAction: (value: ?string, selected: Array<AccountingDocument>) =>
						assignAndUpdate('setcashregister', selected, {
							cashRegisterId: parseInt(value),
						}),
				},
				{
					name: 'category',
					type: 'change_category',
					key: 'categoryName',
					items: getCategoriesEnum(documentCategories),
					hintText: t('accountingDocument.multiActions.change_category.emptyValue'),
					onAction: (value: ?string, selected: Array<AccountingDocument>) =>
						assignAndUpdate('setcategory', selected, {
							categoryId: parseInt(value),
						}),
				},
				{
					name: t('accountingDocument.multiActions.move'),
					type: 'accdoc_move',
				},
			]
		},
	)

	openInboundEmails = () => {
		this.setState({ inboundEmailsOpen: true })
	}

	closeInboundEmails = () => {
		this.setState({ inboundEmailsOpen: false })
	}

	goToImport = () => {
		this.props.history.push(accountingDocumentImportRoute())
	}

	handleExtractClick = (approvalDialogDocument: AccountingDocument) => {
		this.onExtractClick(approvalDialogDocument)
	}

	onExtractClick = async (approvalDialogDocument: AccountingDocument) => {
		let dontShowPreview = false

		const isLocked = isAccountingDocumentLocked(approvalDialogDocument)
		const isNeedHelp = isAccountingDocumentNeedHelp(approvalDialogDocument)

		if (this.props.currentUserIsInternal && (isLocked || isNeedHelp)) {
			const handler = this.getAccountingDocumentClickHandler()
			return handler && handler(approvalDialogDocument)
		}

		if (!this.props.currentUserIsInternal) {
			if (this.props.automaticApproval || isLocked || isNeedHelp) {
				approvalDialogDocument.scans &&
					approvalDialogDocument.scans[0].fileId &&
					approvalDialogDocument.scans[0].filename &&
					openFile(approvalDialogDocument.scans[0].fileId, approvalDialogDocument.scans[0].filename, 'popupPage')
				dontShowPreview = true
			} else {
				dontShowPreview = false
			}
		}

		!dontShowPreview && this.setState({ approvalDialogDocument })
	}

	onEditAccountingDocument = (accountingDocument: AccountingDocument) => {
		this.props.goOnEditAccountingDocumentRoute(accountingDocument.id || '')
	}

	onShowAccountingDocument = (accountingDocument: AccountingDocument) => {
		this.props.goOnShowAccountingDocumentRoute(accountingDocument.id || '')
	}

	getAccountingDocumentClickHandler = () => {
		const { direction } = this.props

		if (this.isEdit()) {
			return this.onEditAccountingDocument
		} else if (direction !== 'unknown') {
			return this.onShowAccountingDocument
		} else {
			return undefined
		}
	}

	isEdit = () => {
		const { direction } = this.props

		return (
			(direction === 'unknown' && this.props.canEditUnknownAccountingDocuments) ||
			(direction === 'issued' && this.props.canEditIssuedAccountingDocuments) ||
			(direction === 'received' && this.props.canEditReceivedAccountingDocuments)
		)
	}

	generateNewTask = (): Task => {
		const { selectedDocument } = this.state
		return {
			attachedAccountingDocumentIds: [
				{
					accountingDocumentId: selectedDocument ? selectedDocument.id : '',
					accountingDocumentNo: selectedDocument ? selectedDocument.accountingDocumentNo : '',
				},
			],
		}
	}

	// closeNewTaskDialog = () => {
	// 	this.setState({ newTaskDialogOpened: false, selectedDocument: null })
	// }

	closeCasRegisterDialog = () => {
		this.setState({ cashRegisterSelectionDialogOpened: false, selectedDocument: null })
	}

	handleCashRegisterSelectionSubmit = (cashRegister: ?CashRegister): Promise<*> => {
		const { accDocCreateAccDoc } = this.props
		const { selectedDocument } = this.state
		return accDocCreateAccDoc(cashRegister, selectedDocument)
	}

	getAccountingDocumentRoute = () => {
		if (this.isEdit()) {
			return editAccountingDocumentRoute
		}
		return showAccountingDocumentRoute
	}

	openConfirmDialog = () => {
		this.setState({ isConfirmDialogOpen: true })
	}

	closeConfirmDialog = () => {
		this.setState({ isConfirmDialogOpen: false })
	}

	onConfirmDocument = (accountingDocument?: AccountingDocument) => {
		this.closeConfirmDialog()
		this.props.defaultOnAction(
			'accdoc_movewf_processed',
			accountingDocument || this.state.clickedRowDocument,
			this.refreshGrid,
		)
	}

	onActionMenuOpen = async (row: AccountingDocument, needUpdate: () => void) => {
		if (row.id) {
			await this.props.loadAvailableActions([row.id])
			needUpdate()
		}
	}

	onEmailClick = (row: AccountingDocument) => {
		this.setState({ showEmailDialogDocument: row })
	}

	onAction = async (action: string, row: AccountingDocument): Promise<*> => {
		const { t } = this.props
		let promise: ?Promise<any> = null

		const deleteDocument = async () => {
			if (row.id) {
				await this.props.removeAccountingDocument(row.id).then(await this.refreshGrid)
				row.id && Tracking.trackAccDocDeleted(row.id, 'manual')
			}
		}
		const createTemplate = async () => {
			if (row.id) {
				const resp: any = await this.props.createTemplate(row.id)
				resp && resp.success && this.props.notify(t('templates.templateCreated'), 'success')
			}
		}
		switch (action) {
			case 'accdoc_move':
				this.setState({ showMoveDialogDocuments: [row] })
				this.onMoveDialogDocumentClosePrm = createDeferredPromise()
				break
			case 'accdoc_approve':
				this.setState({ approvalDialogDocument: row })
				break
			case 'accdoc_set_processing_state':
				ConfirmDialog(t('dialogs.changeStateToProcessed'), {
					okLabel: t('dialogs.yesOption'),
					waitForConfirm: true,
				}).then(() => {
					this.props.defaultOnAction(action, row, this.refreshGrid)
				})
				break
			case 'accdoc_remove':
				promise = new Promise((resolve: boolean => void, reject: boolean => void) => {
					DeleteDialog({
						title: t('dialogs.deleteQuestion'),
					})
						.then(async () => {
							const nextStep = async () => {
								if (row.processingState == 0) {
									resolve(true)
									return await deleteDocument()
								}
								await ConfirmDialog(t('dialogs.deleteFromESO'), {
									okLabel: t('dialogs.yesOption'),
									cancelLabel: t('dialogs.noOption'),
									waitForConfirm: true,
								}).then(async () => {
									resolve(true)
									await deleteDocument()
								})
							}
							// if (isEetSent(row.countryVariantSpecific)) {
							// 	await ConfirmDialog(t('dialogs.documentStateChange'), {
							// 		okLabel: this.props.t('dialogs.documentStateChangeYes'),
							// 		cancelLabel: this.props.t('dialogs.documentStateChangeNo'),
							// 	}).then(
							// 		async () => {
							// 			this.props.changeEET(row, 'cancel_eet')
							// 			await nextStep()
							// 		},
							// 		async () => await nextStep(),
							// 	)
							// } else {
							nextStep()
							// }
						})
						.catch(() => reject(false))
				})
				break
			case 'accdoc_send_email': {
				row.id && this.setState({ sendEmailDialogDocument: row })
				break
			}
			case 'accdoc_get_permalink': {
				row.permalinkToken && this.props.history.push(accountingDocumentPublicRoute(row.permalinkToken))
				break
			}
			case 'accdoc_create_template': {
				createTemplate()
				break
			}
			case 'accdoc_edit_attributes': {
				row.id && this.setState({ editAttributesDialogDocument: row })
				break
			}
			case 'accdoc_pay_transfer':
				if (row.id != null) {
					this.setState({
						accountingDocuments: [row],
						paymentOpen: true,
					})
				}
				break
			case 'accdoc_confirm_creditnote':
				row.id && this.setState({ dialogRelatedDocument: row.id })
				break
			case 'accdoc_reextract':
				ConfirmDialog(t('dialogs.reextractQuestion'), {
					okLabel: t('dialogs.yesOption'),
					cancelLabel: t('dialogs.noOption'),
				}).then(() => {
					row.id && this.props.reextractAccountingDocument(row).then(this.refreshGridLine)
				})
				break
			case 'accdoc_copy':
				ConfirmDialog(t('dialogs.copyDocumentQuestion'), {
					okLabel: t('dialogs.yesOption'),
					cancelLabel: t('dialogs.noOption'),
				}).then(() => {
					// $FlowFixMe
					delete row.context
					this.props.copyAccountingDocument(row).then(this.refreshGrid)
				})
				break
			case 'accdoc_send_due_date':
				row.id && this.setState({ sendReminderEmailDialogDocument: row })
				break
			case 'accdoc_create_taxadvance':
				// $FlowFixMe
				delete row.context
				this.props.createTaxAdvanceAccountingDocument(row).then(this.refreshGrid)
				break
			// case 'accdoc_task_create':
			// 	if (row.id != null) {
			// 		this.setState({ newTaskDialogOpened: true, selectedDocument: row })
			// 	}
			// 	break
			// case 'accdoc_send_eet':
			// 	ConfirmDialog(t('dialogs.sendEet'), {
			// 		okLabel: t('dialogs.yesOption'),
			// 		cancelLabel: t('dialogs.noOption'),
			// 	}).then(() => {
			// 		row.id && this.props.sendEet(row.id).then(this.refreshGrid)
			// 	})
			// 	break
			// case 'accdoc_cancel_eet':
			// 	if (row && isEetSent(row.countryVariantSpecific)) {
			// 		ConfirmDialog(t('dialogs.cancelEet'), {
			// 			okLabel: t('dialogs.yesOption'),
			// 			cancelLabel: t('dialogs.noOption'),
			// 		}).then(() => {
			// 			row.id && this.props.cancelEet(row.id).then(this.refreshGrid)
			// 		})
			// 	} else {
			// 		row.id && this.props.cancelEet(row.id).then(this.refreshGrid)
			// 	}
			// 	break
			case 'accdoc_create_cr_accdoc':
				this.setState({ cashRegisterSelectionDialogOpened: true, selectedDocument: row })
				break
			case 'accdoc_create_creditnote':
				this.props.createCreditNote(row)
				break
			case 'accdoc_movewf_processed':
				this.props.validateAccountingDocument(row.id, 'Processed').then((valid: boolean) => {
					if (true === valid) {
						if (this.props.preProcessingAccountingDocumentPreview) {
							this.setState({ clickedRowDocument: row })
							this.openConfirmDialog()
						} else {
							this.onConfirmDocument(row)
						}
					} else {
						ConfirmDialog(t('dialogs.changeStateToProcessedWhenInvalid'), {
							okLabel: t('dialogs.changeStateToProcessedWhenInvalidYesOption'),
							cancelLabel: t('dialogs.closeOption'),
						}).then(() => {
							this.props.goOnEditAccountingDocumentRoute(row.id)
						})
					}
				})
				break
			case 'accdoc_movewf_fixing':
				if (!this.props.currentUserIsInternal) {
					ConfirmDialog(t('dialogs.nonInternalChangeToFixingTitle'), {
						body: t('dialogs.nonInternalChangeToFixingBody'),
						okLabel: t('dialogs.nonInternalChangeToFixingYesOption'),
						cancelLabel: t('dialogs.goBackOption'),
					}).then(() => {
						this.props.defaultOnAction(action, row, () => {
							this.props.goOnEditAccountingDocumentRoute(row.id)
						})
					})
				} else {
					this.props.defaultOnAction(action, row, () => {
						this.props.goOnEditAccountingDocumentRoute(row.id)
					})
				}
				break
			case 'accdoc_unjoin':
				if (row && row.scans && row.scans.length === 2) {
					this.setState({ selectedDocument: row }, () => {
						row && row.scans && row.scans.length && this.onUnJoinDocument([...row.scans])
					})
				} else {
					this.setState({ unJoinDialog: true, selectedDocument: row })
				}
				break
			case 'accdoc_cashbot':
				// TODO-CASHBOT dialog místo přesměrování na detail
				this.setState({ cashbotDialog: true, clickedRowDocument: row })
				this.props.markToBeFinancedByCashbot(row.id)
				this.props.goOnShowAccountingDocumentRoute(row.id)
				break
			default:
				this.props.defaultOnAction(action, row, this.refreshGrid)
		}

		return promise ? promise : Promise.resolve()
	}

	onMultiAction = async (action: MultiAction, rows: Array<AccountingDocument>): Promise<?MultiAction> => {
		const { t, updateAccountingDocumentPaymentInfo, bulkAction } = this.props
		let promise: ?Promise<any> = null

		switch (action) {
			case 'accdoc_join': {
				promise = this.props.joinAccountingDocuments(rows).then(this.refreshGrid)
				break
			}
			case 'accdoc_unjoin': {
				if (rows[0] && rows[0].scans && rows[0].scans.length === 2) {
					this.setState({ selectedDocument: rows[0] }, () => {
						rows[0] && rows[0].scans && rows[0].scans.length && this.onUnJoinDocument([...rows[0].scans])
					})
				} else {
					this.setState({ unJoinDialog: true, selectedDocument: rows[0] })
				}
				break
			}
			case 'manually_paid':
				promise = Promise.all(
					rows.reduce((result: Array<Promise<*>>, row: AccountingDocument) => {
						if (row.id) {
							result.push(updateAccountingDocumentPaymentInfo(row.id, true))
						}
						return result
					}, []),
				).then(this.refreshGrid)
				break
			case 'manually_unpaid':
				promise = Promise.all(
					rows.reduce((result: Array<Promise<*>>, row: AccountingDocument) => {
						if (row.id) {
							result.push(updateAccountingDocumentPaymentInfo(row.id, false))
						}
						return result
					}, []),
				).then(this.refreshGrid)
				break
			case 'accdoc_move':
				this.setState({ showMoveDialogDocuments: rows })
				this.onMoveDialogDocumentClosePrm = createDeferredPromise()
				promise = this.onMoveDialogDocumentClosePrm.then(
					(result: DialogResult) => {
						return result === 'confirmed' ? MULTI_ACTION_TYPE_CLEAR_SELECTION : undefined
					},
					(reason: ?DialogResult) => {
						return reason === 'confirmed' ? MULTI_ACTION_TYPE_CLEAR_SELECTION : undefined
					},
				)
				break
			case 'movewf_draft':
				promise = bulkAction(
					'movewf',
					rows.map((item: AccountingDocument) => item.id || ''),
					{
						state: 'Draft',
					},
				).then(this.refreshGrid)
				break
			case 'movewf_approval':
				promise = bulkAction(
					'movewf',
					rows.map((item: AccountingDocument) => item.id || ''),
					{
						state: 'Waiting for approval',
					},
				).then(this.refreshGrid)
				break
			case 'movewf_processed':
				promise = bulkAction(
					'movewf',
					rows.map((item: AccountingDocument) => item.id || ''),
					{
						state: 'Processed',
					},
				).then(this.refreshGrid)
				break
			case 'pay_transfer': {
				this.setState({
					accountingDocuments: rows.reduce((result: Array<AccountingDocument>, item: AccountingDocument) => {
						if (item.id != null && item.accountingDocumentNo != null) result.push(item)
						return result
					}, []),
					paymentOpen: true,
				})
				break
			}
			case 'remove':
				promise = DeleteDialog().then(
					async () => {
						const deleteDocuments = () => {
							bulkAction(
								'remove',
								rows.map((item: AccountingDocument) => item.id || ''),
								{},
							).then(this.refreshGrid)
						}
						const containsProcessed: boolean = rows.some((item: AccountingDocument) => item.processingState === 1)
						if (containsProcessed) {
							const result = await ConfirmDialog(t('dialogs.deleteFromESO'), {
								okLabel: t('dialogs.yesOption'),
								cancelLabel: t('dialogs.noOption'),
								waitForConfirm: true,
							}).then(
								() => {
									deleteDocuments()
									return MULTI_ACTION_TYPE_CLEAR_SELECTION
								},
								() => {},
							)
							return result
						} else {
							deleteDocuments()
							return MULTI_ACTION_TYPE_CLEAR_SELECTION
						}
					},
					() => {},
				)
				break
			case 'force_processing': {
				const { forceProcess } = this.props
				promise = Promise.all(
					rows.reduce((result: Array<Promise<any>>, row: AccountingDocument) => {
						if (row.id && forceProcess) result.push(forceProcess(parseInt(row.id)))
						return result
					}, []),
				).then(this.refreshGrid)
				break
			}
		}

		return promise ? promise : Promise.resolve()
	}

	getBeforeTableComponent = memoize((direction: string) => {
		return direction !== 'unknown' ? <DocumentsCounter /> : null
	})
	getTemplateButton = memoize(() => {
		return <TemplateButton />
	})
	getInboundEmailsButton = memoize(() => {
		return <InboundEmailsButton onClick={this.openInboundEmails} />
	})

	getExportButton = memoize((defaultFilter: Filter, filterName: string) => {
		return (
			<ExportButton filterId={filterName} defaultFilter={defaultFilter} sortingField={'id'} sortingDirection={'DESC'} />
		)
	})

	getImportButton = memoize((tooltip: string, isEnabled: boolean) => {
		return isEnabled ? (
			<IconButton autoTestId="grid-import" tooltip={tooltip} onClick={this.goToImport} circled>
				<ImportIcon />
			</IconButton>
		) : null
	})

	getHiddenActions = memoize((direction: string) => {
		let hiddenActions = []
		if (direction === 'unknown') {
			hiddenActions.push('accdoc_show')
		}
		// if (!isEetSettingsDefined) {
		// 	hiddenActions.push('accdoc_send_eet')
		// }
	})

	getDataGridChildren = memoize(
		(
			t: I18NextTranslateFn,
			direction: ?AccountingDocumentDirection,
			automaticApproval: boolean,
			currentUserIsInternal: boolean,
			onCustomerInstructionsChange: (string, AccountingDocument) => void,
			showProcessingState: boolean,
			isOrganizationVatFree: boolean,
			view: AccountingDocumentView,
		) => {
			return direction === 'unknown'
				? [
						!automaticApproval &&
							getExtractingStateColumn({
								t,
								onClick: !automaticApproval ? this.handleExtractClick : undefined,
								currentUserIsInternal: currentUserIsInternal,
							}),
						getCategoryColumn({
							t,
							width: '30%',
							currentUserIsInternal: currentUserIsInternal,
							automaticApproval: automaticApproval,
							onClickToPreview: this.handleExtractClick,
							onClickToEditing: this.getAccountingDocumentClickHandler(),
							openPreview: true,
						}),
						getUploadDetailsColumn({ t, width: '31%' }),
						getUploadedDateColumn({ t, width: '100px', onEmailClick: this.onEmailClick }),
						getAuthorColumn({ t, width: '65px' }),
						getNoteColumn({
							t,
							onChangeNote: onCustomerInstructionsChange,
							prop: 'customerInstructions',
						}),
				  ]
				: [
						getAccountingDocumentNoColumn({ t, width: 155, routeFn: this.getAccountingDocumentRoute() }),
						getCompanyColumn({ t, width: '22%', onClick: this.getAccountingDocumentClickHandler() }),
						isOrganizationVatFree ? getIssueDateColumn({ t, width: '100px' }) : getTaxDateColumn({ t, width: '100px' }),
						view !== 'errors' && getDueDateColumn({ t, width: 125 }),
						getTotalColumn({ t, width: 200 }),
						getStateColumn({
							t,
							showProcessingState: showProcessingState,
							width: 125,
							currentUserIsInternal,
						}),
						view === 'errors' && getProcessingMessageColumn({ t, width: '20%' }),
				  ]
		},
	)

	getPaymentTypeKeys = memoize((paymentTypes: ?Enum, direction: ?AccountingDocumentDirection) => {
		return direction === 'unknown' ? (paymentTypes || []).map((type: EnumItem) => parseInt(type.key)) : undefined
	})

	onOpenFilter = () => {
		this.props.onOpenFilter()
	}

	onCloseFilter = () => {
		this.props.onCloseFilter()
	}

	render() {
		const { t, direction, availableActions, view /* , isEetSettingsDefined */ } = this.props
		const filterName = getAccDocFilterName(direction)
		const showOptimizedFilter = this.isVisibleOptimizedFilter(direction)

		const {
			sendEmailDialogDocument,
			sendReminderEmailDialogDocument,
			editAttributesDialogDocument,
			showEmailDialogDocument,
			showMoveDialogDocuments,
			dialogRelatedDocument,
			approvalDialogDocument,
			previewDialogDocument,
		} = this.state

		return (
			<div>
				<div className={styles.topBar}>
					<AccountingDocumentAdvancedFilter
						view={view}
						name={filterName}
						direction={direction}
						showViewSwitch={direction !== 'unknown'}
						showApprovedSwitch={direction === 'unknown' && !this.props.automaticApproval}
						showProcessingState={this.props.showProcessingState}
						onViewChange={this.props.onViewChange}
						allowedPaymentTypes={this.getPaymentTypeKeys(this.props.paymentTypes, direction)}
						allowedTypes={ALLOWED_TYPES}
						dataType="accountingDocuments"
						gridId={GRID_ID}
						defaultFilter={this.defaultFilter}
						templateButton={this.getTemplateButton()}
						exportButton={this.getExportButton(this.defaultFilter, filterName)}
						importButton={this.getImportButton(
							t('accountingDocument.multiImport.startImportTooltip'),
							this.isImportEnabled(),
						)}
						internal={this.props.currentUserIsInternal}
						branches={this.props.branches}
						projects={this.props.projects}
						hideCurrency={!this.props.currencies || this.props.currencies.length <= 1}
						isFilterOpenDefault={this.props.isAdvancedFilterOpen}
						onClose={this.onCloseFilter}
						onOpen={this.onOpenFilter}
						showOptimizeFilter={showOptimizedFilter}
					/>
				</div>

				{showCashbotPanelOnAccDocGrid(direction) && <CashbotPanelAuthOnly />}

				{direction && (
					<AccountingDocumentDataGrid
						id={GRID_ID}
						dataType="accountingDocuments"
						filterId={filterName}
						defaultFilter={this.defaultFilter}
						actions={getAccountingDocumentActions(
							t,
							direction,
							availableActions,
							this.getHiddenActions(
								direction,
								//isEetSettingsDefined
							),
							this.isVisibleAction,
							this.isVisibleMoveAction,
							this.props.currentUserIsInternal,
						)}
						multiActions={this.getMultiActions(
							this.props.t,
							this.props.bulkAction,
							this.props.documentCategories,
							this.props.branches,
							this.props.projects,
							this.props.cashRegisters,
							this.props.paymentTypes,
						)}
						onAction={this.onAction}
						onMultiAction={this.onMultiAction}
						onActionMenuOpen={this.onActionMenuOpen}
						onNeedUpdateMultiActionVisiblity={this.handleUpdateMultiActionVisibility}
						checkAllOption
						beforeTableComponent={this.getBeforeTableComponent(direction)}
					>
						{this.getDataGridChildren(
							t,
							direction,
							this.props.automaticApproval,
							this.props.currentUserIsInternal,
							this.props.onCustomerInstructionsChange,
							this.props.showProcessingState,
							this.props.isOrganizationVatFree,
							this.props.view,
						)}
					</AccountingDocumentDataGrid>
				)}
				{this.props.canSeeBankAccounts && this.state.paymentOpen && (
					<PaymentDialog
						onRequestClose={this.handlePaymentClose}
						accountingDocuments={this.state.accountingDocuments || EMPTY_ARRAY}
						open
					/>
				)}
				{this.state.cashRegisterSelectionDialogOpened && (
					<CashRegisterDialog
						onRequestClose={this.closeCasRegisterDialog}
						onSubmit={this.handleCashRegisterSelectionSubmit}
						currency={this.state.selectedDocument ? this.state.selectedDocument.currency : ''}
						open
					/>
				)}
				{dialogRelatedDocument && (
					<CreditNoteDialog hideCreditNote={this.hideCreditNote} saveCreditNote={this.saveCreditNote} open />
				)}
				{sendEmailDialogDocument && (
					<SendEmailDialog
						onClose={this.closeEmailSendDialog}
						onSend={this.onSendDocument}
						accountingDocumentId={sendEmailDialogDocument && sendEmailDialogDocument.id}
						open
					/>
				)}
				{sendReminderEmailDialogDocument && (
					<SendReminderEmailDialog
						onClose={this.closeReminderEmailSendDialog}
						onSend={this.onSendReminder}
						accountingDocumentId={sendReminderEmailDialogDocument && sendReminderEmailDialogDocument.id}
						open
					/>
				)}
				{editAttributesDialogDocument && (
					<EditAttributesDialog
						accountingDocument={editAttributesDialogDocument}
						key={editAttributesDialogDocument ? editAttributesDialogDocument.id : ''}
						onClose={this.closeEditAttributesDialog}
						documentCategories={this.props.documentCategories}
						branches={this.props.branches}
						projects={this.props.projects}
						open
					/>
				)}
				{showEmailDialogDocument && (
					<ShowEmailDialog
						onRequestClose={this.closeEmailShowDialog}
						accountingDocument={showEmailDialogDocument}
						open
					/>
				)}
				{showMoveDialogDocuments && (
					<MoveDialog
						onRequestClose={this.closeMoveDialog}
						accountingDocuments={showMoveDialogDocuments}
						onMoveDialogDocumentClosePrm={this.onMoveDialogDocumentClosePrm}
						open
					/>
				)}
				{this.state.inboundEmailsOpen && <InboundEmails open onClose={this.closeInboundEmails} />}
				{/*{this.state.newTaskDialogOpened && (*/}
				{/*	<TaskEdit redirectBack onClose={this.closeNewTaskDialog} defaultData={this.generateNewTask()} />*/}
				{/*)}*/}
				{approvalDialogDocument && (
					<ApproveDialog
						onClose={this.closeApprovalDialog}
						accountingDocument={approvalDialogDocument}
						showConfirmation={this.props.currentUserIsInternal}
						open
					/>
				)}
				{previewDialogDocument && (
					<PreviewDialog onClose={this.closePreviewDialog} accountingDocument={previewDialogDocument} open />
				)}
				{this.state.isConfirmDialogOpen && (
					<DocumentPreviewDialog
						onClose={this.closeConfirmDialog}
						onConfirm={this.onConfirmDocument}
						accountingDocumentId={this.state.clickedRowDocument ? this.state.clickedRowDocument.id : null}
						open
					/>
				)}
				{this.state.unJoinDialog && (
					<UnJoinDialog
						onClose={this.closeUnJoinDialog}
						onUnJoin={this.onUnJoinDocument}
						accountingDocumentScans={this.state.selectedDocument && this.state.selectedDocument.scans}
						open
					/>
				)}
				{this.state.cashbotDialog && null
				// TODO-CASHBOT
				// <CashbotDialog onClose={this.closeCashbotDialog} type={null} open registerCompanyName="TODO-CASHBOT" />
				}
			</div>
		)
	}
}

export default withTranslate(
	withOrganizationSettings(withAccountingDocumentActions(withRouter(withNotify(AccountingDocumentGrid)))),
)
