// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[3].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js!../../../variables/grid.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._28Pzo-4-KE4mEuroDI1McV {\n\tfont-size: 14px;\n\tpadding-left: 28px;\n\tline-height: 26px;\n}\n\n._3cwOo4O8lyyxXcbdDqCLwK {\n\tmargin-top: 20px;\n}\n\n._1VwSfKVB7e4dhjH9XiSStA {\n\tposition: relative;\n}\n", "",{"version":3,"sources":["webpack://./modules/address-book/components/contact-address-edit.css"],"names":[],"mappings":"AAAA;CAEC,eAAA;CACA,kBAAA;CACA,iBAAA;AACD;;AAEA;CACC,gBAAA;AACD;;AAEA;CACC,kBAAA;AACD","sourcesContent":[".root {\n\tcomposes: columnOneHalf from 'variables/grid.css';\n\tfont-size: 14px;\n\tpadding-left: 28px;\n\tline-height: 26px;\n}\n\n.contact {\n\tmargin-top: 20px;\n}\n\n.iconized {\n\tposition: relative;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "_28Pzo-4-KE4mEuroDI1McV " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["columnOneHalf"] + "",
	"contact": "_3cwOo4O8lyyxXcbdDqCLwK",
	"iconized": "_1VwSfKVB7e4dhjH9XiSStA"
};
export default ___CSS_LOADER_EXPORT___;
