import React from 'react'
import pure from 'recompose/pure'
import SvgIcon from '../../SvgIcon'

let UKFlag = props => (
	<SvgIcon {...props} viewBox="0 0 28 14">
		<g id="Onboarding" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g id="1-Login" transform="translate(-1172.000000, -228.000000)" fillRule="nonzero">
				<g id="eng-flag" transform="translate(1172.000000, 228.000000)">
					<rect id="_x31_4" fill="#FFFFFF" x="0.045" y="0.045" width="26.98587" height="13.49847" />
					<polygon
						id="_x31_3"
						fill="#CF142B"
						points="12.18852 8.13996 12.18852 13.54347 14.88744 13.54347 14.88744 8.13996 27.03096 8.13996 27.03096 5.4414 14.88744 5.4414 14.88744 0.045 12.18852 0.045 12.18852 5.4414 0.045 5.4414 0.045 8.13996"
					/>
					<polygon id="_x31_2" fill="#00247D" points="15.78735 4.41441 15.78735 0.045 24.52455 0.045" />
					<polygon id="_x31_1" fill="#00247D" points="15.78735 9.17397 15.78735 13.54347 24.52455 13.54347" />
					<polygon id="_x31_0" fill="#00247D" points="11.28852 9.17397 11.28852 13.54347 2.55141 13.54347" />
					<polygon id="_x39_" fill="#00247D" points="11.28852 4.41441 11.28852 0.045 2.55141 0.045" />
					<polygon id="_x38_" fill="#00247D" points="0.04509 1.29978 0.04509 4.54437 6.53292 4.54437" />
					<polygon id="_x37_" fill="#00247D" points="27.03087 1.29978 27.03087 4.54437 20.54304 4.54437" />
					<polygon id="_x36_" fill="#00247D" points="27.03087 12.28869 27.03087 9.0441 20.54304 9.0441" />
					<polygon id="_x35_" fill="#00247D" points="0.04509 12.28869 0.04509 9.0441 6.53292 9.0441" />
					<polyline
						id="_x34_"
						fill="#CF142B"
						points="27.03087 0.045 25.53201 0.045 16.551 4.54437 18.04986 4.54437 27.045 0.045"
					/>
					<polyline
						id="_x33_"
						fill="#CF142B"
						points="10.52487 9.04383 9.02601 9.04383 0.045 13.5432 1.54386 13.5432 10.539 9.04383"
					/>
					<polygon id="_x32_" fill="#CF142B" points="7.53912 4.54788 9.03789 4.54788 0.045 0.045 0.045 0.79965" />
					<polygon
						id="_x31_"
						fill="#CF142B"
						points="19.53207 9.04212 18.03321 9.04212 27.0261 13.545 27.0261 12.79035"
					/>
				</g>
			</g>
		</g>
	</SvgIcon>
)
UKFlag = pure(UKFlag)

export default UKFlag
