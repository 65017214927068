/* @flow */

import type { AccountingDocumentContact, Dispatch, PaymentReminder, State } from 'types'
import {
	createAccountingDocumentPaymentReminder,
	deleteAccountingDocumentPaymentReminder,
	loadAccountingDocumentPaymentReminders,
	updateAccountingDocumentPaymentReminder,
} from 'modules/accounting-document/actions'
import {
	getAccountingDocumentContact,
	getAccountingDocumentDirection,
	getAccountingDocumentPaymentReminders,
} from 'modules/accounting-document/selectors'
import { loadContact } from 'modules/address-book'
import RemindersSettings from '../../components/invoice-settings/reminders-settings'
import { connect } from 'react-redux'
import { getContact } from 'modules/address-book/selectors'

export type OwnProps = {|
	accountingDocumentId: string,
	readonly?: boolean,
	onBackwardClick: () => void,
|}

function mapStateToProps(state: State, ownProps: OwnProps) {
	const contact: ?AccountingDocumentContact = getAccountingDocumentContact(state, ownProps.accountingDocumentId)
	const accountingDocumentDirection = getAccountingDocumentDirection(state, ownProps.accountingDocumentId)

	if (accountingDocumentDirection == null) {
		throw new Error('accounting document direction cannot be void')
	}

	return {
		contact: contact && contact.contactId ? getContact(state, contact.contactId) : null,
		contactId: contact && contact.contactId,
		paymentReminders: getAccountingDocumentPaymentReminders(state, ownProps.accountingDocumentId),
		emailTemplates: state.settings.emailTemplates,
	}
}

function mapDispatchToProps(dispatch: Dispatch<*>) {
	return {
		loadContact: (contactId: string) => {
			return dispatch(loadContact(contactId))
		},
		loadAccountingDocumentPaymentReminders: (accountingDocumentId: string) => {
			dispatch(loadAccountingDocumentPaymentReminders(accountingDocumentId))
		},
		createAccountingDocumentPaymentReminder: (accountingDocumentId: string, paymentReminder: PaymentReminder) => {
			dispatch(createAccountingDocumentPaymentReminder(accountingDocumentId, paymentReminder))
		},
		updateAccountingDocumentPaymentReminder: (
			accountingDocumentId: string,
			reminderId: string,
			paymentReminder: PaymentReminder,
		) => {
			dispatch(updateAccountingDocumentPaymentReminder(accountingDocumentId, reminderId, paymentReminder))
		},

		deleteAccountingDocumentPaymentReminder: (accountingDocumentId: string, reminderId: string) => {
			dispatch(deleteAccountingDocumentPaymentReminder(accountingDocumentId, reminderId))
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(RemindersSettings)
