/* @flow */

import type { Dispatch, UserAction } from 'types'

import Logout from '../components/logout'
import type { Props as LogoutProps } from '../components/logout'
import { connect } from 'react-redux'
import { logout } from '../actions'
import { push } from 'react-router-redux'

const mapDispatchToProps = (dispatch: Dispatch<UserAction>): LogoutProps => {
	return {
		onLogout: (): void => {
			dispatch(logout())
			dispatch(push('/'))
		},
	}
}

export default connect(undefined, mapDispatchToProps)(Logout)
