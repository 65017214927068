/* @flow */
/** @jsx jsx */

import type { AccountingDocument, CashRegister, FormFieldErrorContext, SchedulerSettingsProps } from 'types'
import { Component, Fragment } from 'react'
import {
	type WithNotifyProps,
	type WithOrganizationSettingsProps,
	type WithTranslateProps,
	withNotify,
	withOrganizationSettings,
	withTranslate,
} from 'wrappers'

import BookingSettings from 'modules/accounting-document/containers/invoice-settings/booking-settings'
import ContactSettings from 'modules/accounting-document/containers/invoice-settings/contact-settings'
import Issues from '../../containers/issues/issues'
import MainSettings from './main-settings'
import RemindersSettings from 'modules/accounting-document/containers/invoice-settings/reminders-settings'
import SchedulerSettings from 'modules/accounting-document/containers/invoice-settings/scheduler-settings'
import SwipeableViews from 'react-swipeable-views'
import { jsx } from '@emotion/core'

type Props = {
	...WithTranslateProps,
	...WithNotifyProps,
	...WithOrganizationSettingsProps,
	direction: 'issued' | 'received',
	showBookingSettings: boolean,
	readonly?: boolean,
	cashRegisterId?: ?string,
	direction: ?number,
	showSchedulerSettings?: boolean,
	showDuePeriodSettings?: boolean,
	showRemindersSettings?: boolean,
	showPeriodPlaceholders?: boolean,
	contactId?: ?string,
	isTemplate?: boolean,

	internalUser: ?boolean,
	accountingDocumentId: ?string,
	accountingDocument?: AccountingDocument,

	schedulerSettingsProps?: ?SchedulerSettingsProps,
	cashRegister?: ?CashRegister,
	loadCashRegister?: (id: string) => void,

	loadContact?: string => Promise<any>,
	onChange?: (accountingDocument: AccountingDocument) => void,

	formFieldErrorContext?: FormFieldErrorContext,
	templateId?: string,
	goToScheduler?: boolean,
	onResetGoToScheduler?: () => void,
}

type State = {|
	index: number,
	views: Array<View>,
|}

type View = 'main' | 'booking' | 'contact' | 'reminders' | 'scheduler'

class SettingsPanel extends Component<Props, State> {
	static defaultProps = {
		showBookingSettings: true,
		showRemindersSettings: true,
	}

	state: State = {
		index: 0,
		views: ['main'],
	}

	componentDidMount() {
		if (this.props.cashRegisterId && !this.props.cashRegister && this.props.loadCashRegister) {
			this.props.loadCashRegister(this.props.cashRegisterId)
		}
		if (!this.props.organizationSettingsOpen) {
			this.props.loadOrganizationSettingsOpen()
		}
	}

	componentWillReceiveProps(nextProps: Props) {
		if (this.props.goToScheduler !== nextProps.goToScheduler && !!nextProps.goToScheduler) {
			this.goToSchedulerSettings()
			this.props.onResetGoToScheduler && this.props.onResetGoToScheduler()
		}
	}

	goToWiew = (view: View) => {
		switch (view) {
			case 'main':
				this.setState({
					index: 0,
				})
				break
			case 'booking':
				this.setState({
					index: 1,
					views: ['main', 'booking'],
				})
				break
			case 'contact':
				this.props.contactId && this.props.loadContact && this.props.loadContact(this.props.contactId)
				this.setState({
					index: 1,
					views: ['main', 'contact'],
				})
				break
			case 'reminders':
				this.setState({
					index: 1,
					views: ['main', 'reminders'],
				})
				break
			case 'scheduler':
				this.setState({
					index: 1,
					views: ['main', 'scheduler'],
				})
				break
		}
	}

	goToMainSettings = () => this.goToWiew('main')
	goToBookingSettings = () => this.goToWiew('booking')
	goToContactSettings = () => this.goToWiew('contact')
	goToRemindersSettings = () => this.goToWiew('reminders')
	goToSchedulerSettings = () => this.goToWiew('scheduler')

	renderMainView = () => {
		return (
			<div key={'main-settings'} style={styles.container}>
				<MainSettings
					showSchedulerSettings={this.props.showSchedulerSettings}
					showDuePeriodSettings={this.props.showDuePeriodSettings}
					showRemindersSettings={this.props.showRemindersSettings}
					showBookingSettings={this.props.showBookingSettings}
					showPeriodPlaceholders={this.props.showPeriodPlaceholders}
					displayContactSettings={this.props.isTemplate || (this.props.contactId != null && this.props.direction == 1)}
					onBookingSettingsClick={this.goToBookingSettings}
					onContactSettingsClick={this.goToContactSettings}
					onRemindersSettingsClick={this.goToRemindersSettings}
					accountingDocumentId={this.props.accountingDocumentId}
					onSchedulerSettingsClick={this.goToSchedulerSettings}
				/>
			</div>
		)
	}

	renderBookingSettings = () => {
		return (
			<div key={'booking-settings'}>
				<BookingSettings
					template={this.props.accountingDocument}
					accountingDocumentId={this.props.accountingDocumentId || ''}
					onBackwardClick={this.goToMainSettings}
					isTemplate={!!this.props.isTemplate}
					readonly={this.props.readonly}
					onTemplateChange={this.props.onChange}
					formFieldErrorContext={this.props.formFieldErrorContext}
				/>
			</div>
		)
	}

	renderSchedulerSettings = () => {
		return (
			<div key={'scheduler-settings'}>
				<SchedulerSettings
					templateId={this.props.templateId}
					onBack={this.goToMainSettings}
					{...this.props.schedulerSettingsProps}
				/>
			</div>
		)
	}

	renderContactSettings = () => {
		return (
			<div key={'contact-settings'}>
				<ContactSettings
					accountingDocumentId={this.props.accountingDocumentId}
					isTemplate={this.props.isTemplate}
					readonly={this.props.readonly}
					onBackwardClick={this.goToMainSettings}
				/>
			</div>
		)
	}

	renderReminders = () => {
		return (
			this.props.accountingDocumentId &&
			this.props.showRemindersSettings && (
				<RemindersSettings
					accountingDocumentId={this.props.accountingDocumentId}
					readonly={this.props.readonly}
					onBackwardClick={this.goToMainSettings}
				/>
			)
		)
	}

	render() {
		const viewComponents = this.state.views.map((view: View) => {
			switch (view) {
				case 'main':
					return this.renderMainView()
				case 'booking':
					return this.renderBookingSettings()
				case 'contact':
					return this.renderContactSettings()
				case 'reminders':
					return this.renderReminders()
				case 'scheduler':
					return this.renderSchedulerSettings()
			}
		})

		if (!viewComponents.find((v: any) => v !== null)) {
			return null
		}

		return (
			viewComponents.length > 0 && (
				<Fragment>
					{this.props.accountingDocumentId && <Issues accountingDocumentId={this.props.accountingDocumentId} />}
					<SwipeableViews index={this.state.index}>{viewComponents}</SwipeableViews>
				</Fragment>
			)
		)
	}
}

const styles = {
	container: {
		overflow: 'hidden',
	},
}

export default withTranslate(withNotify(withOrganizationSettings(SettingsPanel)))
