// @flow

import memoize from 'memoize-one'
import type { AccountingDocumentBankAccount } from 'types'
import { EMPTY_ARRAY } from 'trivi-constants'
import { removeAllWhitespaces } from '../../../utils/formatters/string-formatter'

export const getRemainingBankAccounts = memoize(
	(
		availableBankAccounts: ?Array<AccountingDocumentBankAccount>,
		bankAccounts: ?Array<AccountingDocumentBankAccount>,
	): Array<AccountingDocumentBankAccount> => {
		const accounts: Array<AccountingDocumentBankAccount> = availableBankAccounts || EMPTY_ARRAY

		return accounts.filter(
			(availableAccount: AccountingDocumentBankAccount) =>
				bankAccounts &&
				bankAccounts.findIndex((bankAccount: AccountingDocumentBankAccount) => {
					if (bankAccount.bankAccountId != null && availableAccount.bankAccountId != null) {
						return availableAccount.bankAccountId === bankAccount.bankAccountId
					} else {
						return (
							removeAllWhitespaces(bankAccount.number) === removeAllWhitespaces(availableAccount.number) &&
							bankAccount.bankCode === availableAccount.bankCode
						)
					}
				}) === -1,
		)
	},
)
