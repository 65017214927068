/* @flow */

import type { AccountingDocument } from 'types'
import { PAYMENT_TYPES } from 'types/convertor'

export function getPaymentTypeByName(name: string): number {
	const typeValues = Object.values(PAYMENT_TYPES)
	const index = typeValues.indexOf(name)
	const result = index !== -1 ? Object.keys(PAYMENT_TYPES)[index] : -1
	return result ? parseInt(result) : -1
}

export function showCashRegisterMultiAction(rows: Array<AccountingDocument>): boolean {
	return !rows.some((row: AccountingDocument) => row.paymentType !== getPaymentTypeByName('cash'))
}

export function showJoinMultiAction(rows: Array<AccountingDocument>): boolean {
	return rows.length > 1
}

export function showUnJoinMultiAction(rows: Array<AccountingDocument>): boolean {
	if (rows.length === 1) {
		if (rows[0].scans && rows[0].scans.length > 1) {
			return true
		}
	}
	return false
}
