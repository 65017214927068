// @flow
/** @jsx jsx */

import React from 'react'
import { jsx } from '@emotion/core'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import type { AccountingDocumentCategory } from 'types'
import Tracking from 'utils/tracking'
import Dropzone from 'components/dropzone'
import MenuItem from 'components/menu-item'
import SelectButton from 'components/select-button'
import Button from 'components/button'
import type {
	FinishUploadScanAction,
	ErrorUploadScanAction,
} from 'modules/accounting-document/actions/upload-scan-action-types'
import { getCategoriesMenuItems } from '../../domain/categories'
import DragDrop from 'components/svg-icons/trivi/drag-drop'
import type { CategoriesMenuItemsType } from '../../domain/categories'

type Props = {|
	...WithTranslateProps,
	documentCategories?: ?Array<AccountingDocumentCategory>,
	uploading: boolean,
	noCategories?: boolean,
	accept?: string,
	onFileUploaded?: (id: string) => void,
	uploadScan: (file: File, category: ?string) => Promise<any>,
|}

type State = {|
	documentCategory: ?string,
|}

class UploadScanZone extends React.Component<Props, State> {
	state: State = {
		documentCategory: null,
	}

	dropzone: any = null

	handleUpload = async (files: Array<File>): Promise<any> => {
		await Promise.all(
			files.map((file: File) => {
				return this.props
					.uploadScan(file, this.state.documentCategory)
					.then((resp: FinishUploadScanAction | ErrorUploadScanAction) => {
						resp.confirmedFileId && this.props.onFileUploaded && this.props.onFileUploaded(resp.confirmedFileId)
					})
			}),
		)
		Tracking.trackUploadedDocuments(files.length)
	}

	onCancel = () => this.setState({ documentCategory: null })

	handleClick = () => {
		this.setState({ documentCategory: null })
		this.dropzone &&
			this.dropzone
				.getWrappedInstance()
				.getWrappedInstance()
				.open()
	}

	handleItemClick = (event: Event, item: MenuItem) => {
		this.setState({ documentCategory: item.props.value })
		this.dropzone &&
			this.dropzone
				.getWrappedInstance()
				.getWrappedInstance()
				.open()
	}

	bindDropZone = (d: any) => {
		this.dropzone = d
	}

	render() {
		const { documentCategories, noCategories } = this.props
		const hasCategories: boolean = !!(documentCategories && documentCategories.length > 0)

		return (
			<Dropzone
				disableClick
				ref={this.bindDropZone}
				onDrop={this.handleUpload}
				accept={this.props.accept}
				autoTestId="upload-select-dropzone"
				onFileDialogCancel={this.onCancel}
				minHeight={270}
			>
				<div css={style.dropzoneContent}>
					<DragDrop size={68} disabled />
					<div css={style.dragDropInfo}>{this.props.t('uploadScan.dragDropInfo')}</div>
					<div css={style.or}>{this.props.t('uploadScan.or')}</div>
					{hasCategories && !noCategories ? (
						<SelectButton
							labelText={this.props.t('uploadScan.selectFilesButtonLabel')}
							onClick={this.handleClick}
							onItemClick={this.handleItemClick}
							iconTooltip={this.props.t('uploadScan.uploadToCategory')}
							disabled={this.props.uploading}
							autoTestId="upload-select-button"
						>
							<div css={style.uploadToCategory}>{this.props.t('uploadScan.uploadToCategory')}</div>
							{getCategoriesMenuItems(documentCategories).map((category: CategoriesMenuItemsType) => (
								<MenuItem value={category.id} primaryText={category.name} key={category.id} />
							))}
						</SelectButton>
					) : (
						<Button
							primary
							autoTestId="upload-scan-select-button"
							labelText={this.props.t('uploadScan.selectFilesButtonLabel')}
							onClick={this.handleClick}
						/>
					)}
				</div>
			</Dropzone>
		)
	}
}

const style = {
	dropzoneContent: {
		textAlign: 'center',
		padding: 20,
	},
	dragDropInfo: {
		marginTop: 20,
		fontSize: 14,
		fontWeight: 'bold',
	},
	or: {
		marginTop: 10,
		marginBottom: 12,
		fontSize: 14,
	},
	uploadToCategory: {
		marginTop: 10,
		marginBottom: 2,
		fontSize: 12,
		lineHeight: '20px',
		textTransform: 'uppercase',
		padding: '0 14px',
		fontWeight: 'bold',
	},
}

export default withTranslate(UploadScanZone)
