/* @flow */

import {
	updateAccountingDocumentContact,
	fetchAccountingDocumentVatRates,
	loadAccountingDocumentLineItems,
	loadAccDocGreenboxSuggestion,
} from 'modules/accounting-document/actions'
import {
	getAccountingDocument,
	getAccountingDocumentContact,
	getAccountingDocumentLoading,
} from 'modules/accounting-document/selectors'
import { getCurrentOrganizationADContact } from 'modules/organization/selectors'
import { getFormFieldErrors } from 'modules/common/selectors'
import { connect } from 'react-redux'
import type {
	AccountingDocument,
	AccountingDocumentAssignedDirection,
	AccountingDocumentVatRatesAction,
	AccountingDocumentReduxAction,
	AccountingDocumentContact,
	Dispatch,
	SettingsAction,
	State,
	UserAction,
	FormFieldErrorContext,
	FormFieldErrorMessage,
} from 'types'
import { withAutoload } from 'wrappers'
import Contact from '../../../components/invoice-elements/contact/contact'
import { userHasAccess } from 'permissions'
import { removeError } from 'helpers'

type ContactSpec =
	| {| contact: ?AccountingDocumentContact |}
	| {| accountingDocumentId: string, isMe?: boolean |}
	| {| accountingDocument: ?AccountingDocument, isMe?: boolean |}
	| {| isMe: boolean |}

export type OwnProps = {|
	...ContactSpec,
	direction: AccountingDocumentAssignedDirection,
	isSupplier?: boolean,
	readonly?: boolean,
	public?: boolean,
	view?: string,
	onChange?: (contact: AccountingDocumentContact) => void,
	formFieldErrorContext?: FormFieldErrorContext,
	contact?: ?AccountingDocumentContact,
|}

type StateProps = {|
	isSupplier?: boolean,
	contact: ?AccountingDocumentContact,
	accountingDocument: ?AccountingDocument,
	loading?: boolean,
	canEditContacts: boolean,
	canSeeContacts: boolean,
	errors: ?Array<FormFieldErrorMessage>,
|}

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => {
	return {
		contact: getContact(state, ownProps),
		// $FlowFixMe
		accountingDocument: getAccountingDocument(state, ownProps.accountingDocumentId),
		loading: !!ownProps.accountingDocumentId && getAccountingDocumentLoading(state, ownProps.accountingDocumentId),
		canEditContacts: userHasAccess(state, 'editContacts'),
		canSeeContacts: userHasAccess(state, 'seeContacts') || !!ownProps.public,
		errors: ownProps.formFieldErrorContext && getFormFieldErrors(state, ownProps.formFieldErrorContext),
	}
}

type DispatchProps = {|
	onChange?: (contact: AccountingDocumentContact, accountingDocument: ?AccountingDocument) => Promise<void>,
	removeError: () => void,
|}

const mapDispatchToProps = (
	dispatch: Dispatch<AccountingDocumentReduxAction | AccountingDocumentVatRatesAction | UserAction | SettingsAction>,
	ownProps: OwnProps,
): DispatchProps => {
	const accountingDocumentId = getAccountingDocumentId(ownProps)
	const isEditPage = window.location.href.split('/').pop() == 'edit'
	return {
		onChange: async (contact: AccountingDocumentContact, accDoc: ?AccountingDocument) => {
			if (accountingDocumentId) await dispatch(updateAccountingDocumentContact(accountingDocumentId, contact))
			ownProps.onChange && ownProps.onChange(contact)
			if (accountingDocumentId) await dispatch(fetchAccountingDocumentVatRates(accountingDocumentId))
			if (accountingDocumentId) await dispatch(loadAccountingDocumentLineItems(accountingDocumentId))
			if (accountingDocumentId && isEditPage && accDoc && accDoc.state !== 'Extracting') {
				dispatch(loadAccDocGreenboxSuggestion(accountingDocumentId))
			}
		},
		removeError: () => {
			removeError({
				formFieldErrorContext: ownProps.formFieldErrorContext,
				dispatch,
				accDocId: accountingDocumentId || undefined,
			})
		},
	}
}

const mergeProps = (stateProps: StateProps, dispatchProps: DispatchProps, ownProps: OwnProps) => {
	return {
		direction: ownProps.direction,
		isMe: ownProps.isMe || false,
		isSupplier: ownProps.isSupplier,
		readonly: ownProps.readonly,
		view: ownProps.view,
		loading: stateProps.loading,
		onChange: dispatchProps.onChange,
		contact: stateProps.contact,
		canEditContacts: stateProps.canEditContacts,
		canSeeContacts: stateProps.canSeeContacts,
		errors: stateProps.errors,
		removeError: dispatchProps.removeError,
		public: ownProps.public,
		accountingDocument: stateProps.accountingDocument,
	}
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(withAutoload(Contact))

function getContact(state: State, ownProps: OwnProps): ?AccountingDocumentContact {
	if (ownProps.contact) {
		return ownProps.contact
	}

	if (ownProps.isMe) {
		return getCurrentOrganizationADContact(state)
	}

	if (ownProps.accountingDocument && ownProps.accountingDocument.contact) {
		return ownProps.accountingDocument.contact
	}

	if (ownProps.accountingDocumentId) {
		return getAccountingDocumentContact(state, ownProps.accountingDocumentId)
	}
}

function getAccountingDocumentId(ownProps: OwnProps): ?string {
	return ownProps.accountingDocumentId || (ownProps.accountingDocument && ownProps.accountingDocument.id)
}
