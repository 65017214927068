/* @flow */
/** @jsx jsx */

import { jsx } from '@emotion/core'
import { colors } from 'variables'

export type ChipColor = 'orange' | 'blue' | 'red' | 'green'

export type Props = {|
	top?: boolean,
	light?: boolean,
	children: string,
	icon?: ?React$Element<any>,
	color?: ChipColor,
|}

function Chip(props: Props) {
	const styles = getStyles(props)

	return (
		<div css={styles.root}>
			<span css={styles.label}>{props.children}</span>
			{props.icon && <span css={styles.icon}>{props.icon}</span>}
		</div>
	)
}

function getStyles(props: Props) {
	const color = props.color || 'green'
	const backgroundColor = colors[`${color}${props.light ? 'Faded60' : ''}`]
	const borderColor = props.light ? 'transparent' : colors[`${color}600`]

	return {
		root: {
			backgroundColor: backgroundColor,
			borderRadius: props.top ? '0 0 3px 3px' : 3,
			borderWidth: 1,
			borderStyle: 'solid',
			borderColor,
			whiteSpace: 'nowrap',
			width: 'auto',
			height: 'auto',
			lineHeight: '16px',
			overflow: 'hidden',
			textAlign: 'center',
			display: 'inline-flex',
			alignItems: 'center',
			padding: '0 7px',
			verticalAlign: 'middle',
		},
		label: {
			color: colors.white,
			fontSize: 10,
			fontWeight: 'bold',
			lineHeight: '18px',
			height: 18,
			userSelect: 'none',
			whiteSpace: 'nowrap',
			textTransform: 'uppercase',
			display: 'flex',
			alignItems: 'center',
			alignContent: 'center',
			justifyContent: 'flex-start',
		},
		icon: {
			marginLeft: 5,
			marginRight: -2,
			display: 'flex',
			alignItems: 'center',
		},
	}
}

export default Chip
