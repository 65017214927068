// @flow

import React, { PureComponent, type Node } from 'react'
import memoize from 'memoize-one'
import { passAutoTestId, autoTestId, type AutoTestProps } from 'utils/tests/autotest'
import Menu from 'components/menu'
import Popover from 'components/Popover'
import IconButton from 'components/icon-button'
import Loader from 'components/loader'
import MoreHorizontalIcon from 'components/svg-icons/navigation/more-horiz'
import CloseIcon from 'components/svg-icons/navigation/close'
import { colors } from 'variables'

type Props = {|
	...AutoTestProps,
	/**
	 * Show arrow. Values are none, top, bottom, left, right
	 */
	arrow?: string,
	icon?: Node,
	closeIcon?: Node,
	/**
	 * This is the point on the icon where the menu
	 * `targetOrigin` will attach.
	 * Options:
	 * vertical: [top, center, bottom]
	 * horizontal: [left, middle, right].
	 */
	anchorOrigin: { vertical: 'top' | 'center' | 'bottom', horizontal: 'left' | 'middle' | 'right' },
	/**
	 * Should be used to pass `MenuItem` components.
	 */
	children: ?Node,
	context?: boolean,
	disabled?: boolean,
	loading?: boolean,
	/**
	 * If true, the `IconMenu` is opened.
	 */
	open?: boolean,
	/**
	 * This is the point on the menu which will stick to the menu origin.
	 */
	targetOrigin: { vertical: 'top' | 'center' | 'bottom', horizontal: 'left' | 'middle' | 'right' },
	/**
	 * Callback function fired when the `open` state of the menu is requested to be changed.
	 *
	 * @param {boolean} open If true, the menu was requested to be opened.
	 */
	onRequestChange?: boolean => void,
	onOpen?: () => void,
	onClose?: () => void,
	onChange?: (SyntheticEvent<HTMLElement>, any) => void,
	onClick?: (SyntheticEvent<HTMLElement>) => void,
	offsetX?: number,
|}

type State = {|
	anchorEl: ?HTMLElement,
	open: boolean,
|}

class IconMenu extends PureComponent<Props, State> {
	timerCloseId: ?TimeoutID = null
	iconButton: ?IconButton = null
	container: ?HTMLElement = null

	static defaultProps = {
		anchorOrigin: {
			vertical: 'bottom',
			horizontal: 'middle',
		},
		targetOrigin: {
			vertical: 'top',
			horizontal: 'middle',
		},
		arrow: 'top',
	}

	state = {
		open: false,
		anchorEl: null,
	}

	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		if (nextProps.open != null) {
			this.setState({
				open: nextProps.open,
				anchorEl: this.container,
			})
		}
	}

	componentWillUnmount() {
		this.timerCloseId && clearTimeout(this.timerCloseId)
	}

	isOpen() {
		return this.state.open
	}

	isControlled = () => {
		return this.props.hasOwnProperty('open')
	}

	close() {
		if (!this.state.open) return

		if (this.isControlled()) {
			this.props.onRequestChange && this.props.onRequestChange(false)
		} else {
			this.setState({ open: false }, () => {
				this.props.onClose && this.props.onClose()
			})
		}
	}

	open(event: SyntheticEvent<HTMLElement>) {
		if (this.isControlled()) {
			this.props.onRequestChange && this.props.onRequestChange(true)

			return this.setState({
				anchorEl: event.currentTarget,
			})
		}

		this.setState({
			open: true,
			anchorEl: event.currentTarget,
		})

		event.preventDefault()
		this.props.onOpen && this.props.onOpen()
	}

	handleItemClick = () => {
		this.timerCloseId = setTimeout(() => {
			this.close()
		}, 100)
	}

	handleRequestClose = () => {
		this.close()
	}

	handleEscKeyDownMenu = () => {
		this.close()
	}

	handleIconButtonClick = (event: SyntheticEvent<HTMLElement>) => {
		if (this.state.open) {
			this.close()
		} else {
			this.open(event)
		}
	}

	handleClick = (event: SyntheticEvent<HTMLElement>) => {
		!this.props.disabled && this.props.onClick && this.props.onClick(event)
	}

	bindContainer = (element: ?HTMLElement) => {
		if (element) this.container = element
	}

	bindIconButton = (element: ?IconButton) => {
		if (element) this.iconButton = element
	}

	getStyles = memoize((context: ?boolean, disabled: ?boolean, open: boolean) => {
		const styles = {
			root: {
				display: 'inline-block',
				pointerEvents: disabled ? 'none' : 'all',
				position: 'relative',
				verticalAlign: 'middle',
			},
			iconButton: {
				overflow: 'hidden',
			},
			menu: {
				position: 'relative',
				paddingTop: context ? 0 : 10,
				paddingBottom: context ? 0 : 10,
			},
		}

		if (open) {
			styles.iconButton = {
				...styles.iconButton,
				backgroundColor: colors.grey200,
				boxShadow: 'inset 2px 4px 0 0 rgba(0,0,0,0.08)',
				borderColor: colors.grey400,
				borderWidth: 1,
				'&:hover': {
					svg: {
						fill: colors.gray600,
					},
				},
			}
		}

		return styles
	})

	render() {
		const { context } = this.props
		const { open, anchorEl } = this.state

		const styles = this.getStyles(this.props.context, this.props.disabled, this.state.open)

		return (
			<div
				ref={this.bindContainer}
				onClick={this.handleClick}
				style={styles.root}
				{...autoTestId(this.props.autoTestId)}
			>
				<IconButton
					disabled={this.props.loading || this.props.disabled}
					circled={!context}
					style={styles.iconButton}
					onClick={this.handleIconButtonClick}
					ref={this.bindIconButton}
					hoverColor={open ? colors.gray600 : colors.blue}
					color={open ? colors.gray : colors.black}
					size={context ? 24 : 40}
				>
					{open
						? this.props.closeIcon || <CloseIcon size={context ? 12 : 18} />
						: this.props.icon || <MoreHorizontalIcon size={context ? 20 : 24} />}
					<Loader transparent visible={this.props.loading} size={context ? 24 : 40} />
				</IconButton>
				<Popover
					arrow={this.props.arrow}
					anchorOrigin={this.props.anchorOrigin}
					targetOrigin={this.props.targetOrigin}
					open={this.props.children && open}
					anchorEl={anchorEl}
					onRequestClose={this.handleRequestClose}
					zDepth={3}
					stretch={false}
					offsetY={8}
					offsetX={this.props.offsetX}
					{...passAutoTestId(this.props.autoTestId, 'popover')}
				>
					<Menu
						onEscKeyDown={this.handleEscKeyDownMenu}
						onItemClick={this.handleItemClick}
						style={styles.menu}
						onChange={this.props.onChange}
						{...passAutoTestId(this.props.autoTestId, 'menu')}
					>
						{this.props.children}
					</Menu>
				</Popover>
			</div>
		)
	}
}

export default IconMenu
