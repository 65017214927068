/* @flow */
/** @jsx jsx */

import { jsx } from '@emotion/core'
import { PureComponent } from 'react'
import type { Action } from '../types'
import IconMenu from 'components/icon-menu'
import MenuItem from 'components/menu-item'

const TARGET_ORIGIN = { horizontal: 'right', vertical: 'top' }

export type Props<Item> = {|
	row: Item,
	actions: Array<Action<Item>>,
	onAction: ?(string, Item) => Promise<void>,
	onActionMenuOpen?: (Item, () => void) => ?Promise<void>,
|}

type State = {|
	open: boolean,
	loading: boolean,
|}

class DataGridActions<Item> extends PureComponent<Props<Item>, State> {
	state = {
		open: false,
		loading: false,
	}

	handleActionChange = (event: SyntheticEvent<HTMLElement>, actionName: string) => {
		this.props.onAction && this.props.onAction(actionName, this.props.row)
	}

	handleMenuRequestChange = (open: boolean) => {
		if (open && this.props.onActionMenuOpen) {
			this.setState({ loading: true })
			if (this.props.onActionMenuOpen) {
				this.props.onActionMenuOpen(this.props.row, () => {
					this.setState({ loading: false })
					this.setState({ open })
				})
			}
		} else {
			this.setState({ open })
		}
	}

	getRelevantActions(): Array<Action<Item>> {
		const { row, actions } = this.props
		return actions.filter((action: Action<Item>) => {
			return !action.isVisibleFn || action.isVisibleFn(row)
		})
	}

	stopPropagation = (event: SyntheticEvent<HTMLElement>) => event.stopPropagation()

	render() {
		if (!this.props.actions.length) return null

		const rootStyle = this.state.open || this.state.loading ? { opacity: 1 } : {}
		let relevantActions = this.getRelevantActions()

		return (
			<div className="actions" style={rootStyle} css={styles.root}>
				<IconMenu
					targetOrigin={TARGET_ORIGIN}
					onClick={this.stopPropagation}
					onChange={this.handleActionChange}
					onRequestChange={this.handleMenuRequestChange}
					open={this.state.open}
					arrow={'topRight'}
					loading={this.state.loading}
					offsetX={19}
					context
					autoTestId="data-grid-actions"
				>
					{relevantActions.map((action: Action<Item>) => (
						<MenuItem
							key={action.type}
							primaryText={action.name}
							icon={action.icon || <span />}
							value={action.type}
							tall
						/>
					))}
				</IconMenu>
			</div>
		)
	}
}

const styles = {
	root: {
		opacity: 0,
		pointerEvents: 'none',
		transition: 'none',
		margin: '0 12px',
	},
}

export default DataGridActions
