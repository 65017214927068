// @flow

import React from 'react'
import type { OrganizationContractState } from '../models/swagger-model'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import { getInTriviUntil, isContractTerminated } from '../domain/contract'
import Tooltip from 'components/tooltip'
import Chip from 'components/chip'

type Props = {|
	...WithTranslateProps,
	contractState: ?OrganizationContractState,
|}

function ContractIcon({ t, contractState }: Props) {
	if (!contractState || !contractState.inTriviUntil) return null

	const message = getInTriviUntil(t, contractState)
	const color = isContractTerminated(contractState) ? 'red' : 'orange'

	return (
		<Tooltip inline label={message}>
			<Chip color={color}>{t('settings.administration.terminationWarning')}</Chip>
		</Tooltip>
	)
}

export default withTranslate(ContractIcon)
