/* @flow */

import React from 'react'
import withTranslate from 'wrappers/with-translate'
import type { Props as WithTranslateProps } from 'wrappers/with-translate'
import IconButton from 'components/icon-button'
import RotateLeft from 'components/svg-icons/image/rotate-left'
import RotateRight from 'components/svg-icons/image/rotate-right'
import ZoomIn from 'components/svg-icons/action/zoom-in'
import ZoomOut from 'components/svg-icons/action/zoom-out'
import DownloadButton from 'components/image-viewer/download-button'
import Reset from 'components/svg-icons/action/autorenew'

type Props = {|
	showReset?: boolean,
	downloadUrl?: string,
	fileName?: ?string,
	onControl: (props: ControlProps) => void,
	...WithTranslateProps,
|}

export type ControlRotateProps = {|
	type: 'rotate',
	dir: 'left' | 'right',
|}

export type ControlScaleProps = {|
	type: 'scale',
	dir: 'in' | 'out',
|}

export type ControlResetProps = {|
	type: 'reset',
|}

export type ControlProps = ControlRotateProps | ControlScaleProps | ControlResetProps

function Controls(props: Props) {
	const onControl = (controlProps: ControlProps) => {
		return () => {
			{
				props.onControl(controlProps)
			}
		}
	}

	return (
		<div
			style={{
				display: 'flex',
			}}
		>
			{props.downloadUrl && <DownloadButton downloadUrl={props.downloadUrl} fileName={props.fileName} />}

			<IconButton
				autoTestId="image-viewer-rotate-left"
				tooltip={props.t('imageViewer.rotateLeft')}
				onClick={onControl({ type: 'rotate', dir: 'left' })}
			>
				<RotateLeft />
			</IconButton>
			<IconButton
				autoTestId="image-viewer-rotate-right"
				tooltip={props.t('imageViewer.rotateRight')}
				onClick={onControl({ type: 'rotate', dir: 'right' })}
			>
				<RotateRight />
			</IconButton>
			<IconButton
				autoTestId="image-viewer-zoom-in"
				tooltip={props.t('imageViewer.zoomIn')}
				onClick={onControl({ type: 'scale', dir: 'in' })}
			>
				<ZoomIn />
			</IconButton>
			<IconButton
				autoTestId="image-viewer-zoom-out"
				tooltip={props.t('imageViewer.zoomOut')}
				onClick={onControl({ type: 'scale', dir: 'out' })}
			>
				<ZoomOut />
			</IconButton>
			<div
				style={{
					pointerEvents: props.showReset ? 'all' : 'none',
					opacity: props.showReset ? 1 : 0,
					transition: 'all 300ms',
				}}
			>
				<IconButton
					autoTestId="image-viewer-reset"
					tooltip={props.t('imageViewer.reset')}
					onClick={onControl({ type: 'reset' })}
				>
					<Reset />
				</IconButton>
			</div>
		</div>
	)
}

export default withTranslate(Controls)
