/* @flow */

import React, { Component } from 'react'
import memoize from 'memoize-one'
import { connect } from 'react-redux'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import type { CommonAction, Dispatch, Enum, EnumItem, State } from 'types'
import { loadNaceCodes } from 'modules/common/actions'
import SelectNext, { type OptionType } from 'components/select-next'

type StateProps = {|
	availableNaceCodes: ?Enum,
|}

const mapStateToProps = (state: State): StateProps => {
	return {
		availableNaceCodes: state.common.naceCodes,
	}
}

type DispatchProps = {|
	loadNaceCodes: () => void,
|}

const mapDispatchToProps = (dispatch: Dispatch<CommonAction>): DispatchProps => {
	return {
		loadNaceCodes: () => {
			dispatch(loadNaceCodes())
		},
	}
}

type Props = {|
	disabled?: boolean,
	value: ?string,
	main?: boolean,
	onChange: (naceCodes: ?string) => void,
	...StateProps,
	...DispatchProps,
	...WithTranslateProps,
|}

class NaceCodeSelector extends Component<Props> {
	onCodeChange = (option: OptionType) => {
		this.props.onChange(option.value)
	}

	componentDidMount() {
		!this.props.availableNaceCodes && this.props.loadNaceCodes()
	}

	getOptions = memoize((availableNaceCodes: ?Enum) => {
		return availableNaceCodes
			? availableNaceCodes.map((naceCode: EnumItem) => {
					return {
						label: (naceCode.key || '') + ' - ' + (naceCode.value || ''),
						value: naceCode.key,
					}
			  })
			: []
	})

	render() {
		const { t } = this.props
		return (
			<SelectNext
				label={this.props.main ? t('settings.basics.mainNaceCode') : t('settings.basics.sideNaceCode')}
				autoTestId="nace-code-select"
				onChange={this.onCodeChange}
				options={this.getOptions(this.props.availableNaceCodes)}
				disabled={this.props.disabled}
				value={this.props.value}
				isSearchable
			/>
		)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslate(NaceCodeSelector))
