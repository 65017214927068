//@flow

import { connect } from 'react-redux'
import type { ContactMeAction, ChatAction, Dispatch, State } from 'types'
import { toggleForm, sendContact } from '../actions'
import Dialog from '../components/dialog'
import { getContactMeOpen } from '../selectors'
import Tracking from 'utils/tracking'

// We need to compare with this object because of flow protection
const SUCCESS_ACTION: ContactMeAction = { type: 'SEND_CONTACT_SUCCESS' }

type StateProps = {|
	isOpen: boolean,
|}
const mapStateToProps = (state: State): StateProps => {
	return {
		isOpen: getContactMeOpen(state),
	}
}

type DispatchProps = {|
	onSubmit: (contact: string) => Promise<any>,
	onClose: () => void,
|}
const mapDispatchToProps = (dispatch: Dispatch<ContactMeAction | ChatAction>): DispatchProps => {
	return {
		onSubmit: (contact: string) => {
			return dispatch(sendContact(contact)).then((result: ContactMeAction) => {
				const success = result.type === SUCCESS_ACTION.type
				Tracking.submitDemoContactForm(success)
			})
		},
		onClose: () => {
			dispatch(toggleForm())
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Dialog)
