//@flow
/** @jsx jsx */

import { Component } from 'react'
import { jsx } from '@emotion/core'
import type { ISignalMessage, ISignalMessageParams, ISignalMessageGroup } from 'types'
import NotificationItem from './notification-item'
import Clear from 'components/svg-icons/content/clear'
import IconButton from 'components/icon-button'
import Paper from 'components/Paper'

type Props = {|
	open: boolean,
	notification: ?ISignalMessage,
	onRequestClose: () => void,
	handleNotificationClick: (params: ISignalMessageParams, group: ISignalMessageGroup, notificationId: string) => void,
|}

export default class NotificationPreview extends Component<Props> {
	render() {
		if (!this.props.open) return null
		const { onRequestClose, notification, handleNotificationClick } = this.props

		return (
			<Paper zDepth={2} rounded style={styles.root}>
				{notification && (
					<div onClick={onRequestClose}>
						<NotificationItem notification={notification} onNotificationClick={handleNotificationClick} />
						<div css={styles.close}>
							<IconButton onClick={onRequestClose}>
								<Clear size={20} />
							</IconButton>
						</div>
					</div>
				)}
			</Paper>
		)
	}
}

const styles = {
	root: {
		position: 'absolute',
		overflow: 'hidden',
		width: 360,
		top: 55,
		right: 0,
	},
	close: {
		position: 'absolute',
		top: -3,
		right: -3,
	},
}
