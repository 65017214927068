//@flow
/** @jsx jsx */

import { Component } from 'react'
import { jsx } from '@emotion/core'
import storage from 'utils/local-storage'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import InvitationDialog from '../../containers/invitation/invitation-dialog'
import { INVITATION_KEY } from '../../constants'
import Paper from 'components/Paper'
import Button from 'components/button'
import scenery from 'assets/images/scenery.svg'

type Props = {|
	isOpened: boolean,
	switchInvitationBanner: (value: boolean) => void,
	switchInvitationDialog: (value: boolean) => void,
|}

class InvitationBanner extends Component<Props & WithTranslateProps> {
	UNSAFE_componentWillMount = () => {
		const invitationBannerState: ?boolean = storage.get(INVITATION_KEY)
		if (invitationBannerState !== null && invitationBannerState !== undefined) {
			this.props.switchInvitationBanner(invitationBannerState)
		}
	}

	openInvitationDialog = () => {
		window.location.hash = 'reference-dialog'
		this.props.switchInvitationDialog(true)
		this.props.switchInvitationBanner(true)
	}
	checkDialog = () => {
		if (window.location.hash === '#reference-dialog') this.openInvitationDialog()
	}
	componentDidMount = () => {
		window.addEventListener('hashchange', this.checkDialog)
	}
	componentWillUnmount = () => {
		window.removeEventListener('hashchange', this.checkDialog)
	}
	renderBanner = () => {
		const { t } = this.props
		this.checkDialog()

		return (
			<div css={styles.root}>
				<Paper>
					<div css={styles.container}>
						<img src={scenery} css={styles.image} />
						<div css={styles.text}>
							<div css={styles.title}>{t('dashboard.invitation.banner.title')}</div>
							<div>{t('dashboard.invitation.banner.body')}</div>
						</div>
						<div css={styles.button}>
							<Button
								autoTestId="invitation-banner-button"
								labelText={t('dashboard.invitation.banner.buttonLabel')}
								onClick={this.openInvitationDialog}
							/>
						</div>
					</div>
				</Paper>
				<InvitationDialog />
			</div>
		)
	}

	render() {
		const { isOpened } = this.props
		return isOpened ? this.renderBanner() : null
	}
}

const styles = {
	root: {
		marginTop: 30,
	},
	container: {
		display: 'flex',
		placeContent: 'center space-between',
		alignItems: 'center',
		padding: 8,
	},
	image: {
		height: 61,
		width: 'auto',
	},
	text: {
		padding: '0 24px',
		flex: '1 1 100%',
	},
	title: {
		fontWeight: 'bold',
	},
	button: {
		padding: 10,
	},
}

export default withTranslate(InvitationBanner)
