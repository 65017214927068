// @flow

// $FlowFixMe - there is useEffect
import React, { useEffect, useCallback } from 'react'
import { connect } from 'react-redux'
import Helmet from 'react-helmet'
import { userHasAccess } from 'permissions'
import type { OrganizationMembers, State } from 'types'
import { autoTestId } from 'utils/tests/autotest'
import AddButton from 'modules/navigation/containers/add-button'
import AppHotKeys from 'modules/common/containers/app-hotkeys'
import ContactMe from 'modules/contact-me/containers/contact-me'
import SupportContainer from 'modules/navigation/containers/support'
import Navigation from 'modules/navigation/containers/navigation'
import NotificationCenter from 'modules/common/containers/notification-center'
import UserIconContainer from 'modules/user/containers/user-icon'
import TermsDialog from 'modules/user/containers/terms-dialog'
import styles from './main-layout.css'
import MobileAppInvitation from 'modules/navigation/containers/mobile-app-invitation'
import ContractState from 'modules/common/containers/contract-state'
import { currentUserIsInternal } from 'modules/user/selectors'
import { getAccessToken } from 'utils/local-storage'
import { getCurrentUserOrganizations } from '../modules/user/selectors'

type Props = {|
	...StateProps,
	children: React$Element<any>,
	mainMenuSection: ?string,
|}

function scrollToStart() {
	const element = document.getElementById('main')
	// $FlowFixMe - there is scrollTo in HTMLElement
	element && element.scrollTo && element.scrollTo(0, 0)
}

function scrollToEnd() {
	const element = document.getElementById('main')
	// $FlowFixMe - there is scrollTo in HTMLElement
	element && element.scrollHeight && element.scrollTo && element.scrollTo(0, element.scrollHeight)
}

function scrollBy(scroll: number) {
	const element = document.getElementById('main')
	// $FlowFixMe - there is scrollBy in HTMLElement
	element && element.scrollBy && element.scrollBy(0, scroll)
}

function scrollByPage(direction: number) {
	scrollBy(window.innerHeight * 0.9 * direction)
}

function MainLayout(props: Props) {
	const eventCodes = ['PageUp', 'PageDown', 'ArrowUp', 'ArrowDown', 'Home', 'End']

	const handleKeyPress = useCallback((event: KeyboardEvent) => {
		if (event.srcElement.nodeName == 'INPUT') {
			if (eventCodes.includes(event.code)) event.preventDefault()
		} else {
			if ('PageUp' === event.code) scrollByPage(-1)
			if ('PageDown' === event.code) scrollByPage(1)
			if ('Space' === event.code) scrollByPage(1)
			if ('ArrowUp' === event.code) scrollBy(-32)
			if ('ArrowDown' === event.code) scrollBy(32)
			if ('Home' === event.code) scrollToStart()
			if ('End' === event.code) scrollToEnd()
		}
	}, [])

	useEffect(() => {
		document.addEventListener('keydown', handleKeyPress)
		return () => void document.removeEventListener('keydown', handleKeyPress)
	}, [handleKeyPress])

	if (!getAccessToken() || !props.organizations) return null
	const hasOrganization = props.organizations && !!props.organizations.length

	return (
		<AppHotKeys>
			<div className={styles.root} id="main__wrapper">
				<Helmet titleTemplate="%s - Trivi" defaultTitle="Trivi" />
				{(hasOrganization || props.currentUserIsInternal) && (
					<Navigation
						selected={props.mainMenuSection}
						showOrganizationSelect={hasOrganization || props.seeOrganizationSwitcher}
						showOrganizationRelatedItems={!!props.organizationId}
					/>
				)}
				{/* Outline css onf ocus */}
				<div data-popover-target className={styles.main} id="main" role="main">
					<div className={styles.container} id="main__container">
						<div className={styles.inner} id="main__inner">
							{props.children}
						</div>
					</div>
				</div>
				{props.organizationId && <AddButton autoTestId={'main__add-button'} />}
				<UserIconContainer classNames={styles.userIcon} autoTestId={'main__profile-menu'} />
				<div className={styles.support} {...autoTestId('support__wrapper')}>
					<SupportContainer
						organizationId={props.organizationId}
						accountantType={'main'}
						autoTestId={'support__main'}
					/>
					<SupportContainer
						organizationId={props.organizationId}
						accountantType={'wages'}
						autoTestId={'support__wages'}
					/>
				</div>
				{props.organizationId && (
					<>
						{/*<Chat />*/}
						<ContactMe />
						<ContractState />
					</>
				)}
				<div id="render-to-layer" /> {/* This is the place where RenderToLayer will place layers */}
				<NotificationCenter />
				<TermsDialog />
				<MobileAppInvitation />
			</div>
		</AppHotKeys>
	)
}

type StateProps = {|
	organizationId: ?string,
	organizations: ?OrganizationMembers,
	seeOrganizationSwitcher: boolean,
	currentUserIsInternal: boolean,
|}

const mapStateToProps = (state: State): StateProps => {
	return {
		organizationId: state.user.currentOrganizationId,
		organizations: getCurrentUserOrganizations(state),
		seeOrganizationSwitcher: userHasAccess(state, 'seeOrganizationSwitcher'),
		currentUserIsInternal: currentUserIsInternal(state),
	}
}

export default function mainLayout(mainMenuSection?: string) {
	return (Content: React$ComponentType<any>): React$ComponentType<any> => {
		return connect(mapStateToProps)(
			({ organizationId, organizations, seeOrganizationSwitcher, currentUserIsInternal, ...restProps }: StateProps) => (
				<MainLayout
					organizationId={organizationId}
					organizations={organizations}
					mainMenuSection={mainMenuSection}
					seeOrganizationSwitcher={seeOrganizationSwitcher}
					currentUserIsInternal={currentUserIsInternal}
				>
					<Content {...restProps} />
				</MainLayout>
			),
		)
	}
}
