//@flow
/** @jsx jsx */

import { Component } from 'react'
import { jsx } from '@emotion/core'
import memoize from 'memoize-one'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import ArrowDown from 'components/svg-icons/navigation/arrow-drop-down'
import ArrowUp from 'components/svg-icons/navigation/arrow-drop-up'

type Props = {|
	open: boolean,
	switchInvitationBanner: (value: boolean) => void,
	...WithTranslateProps,
|}

class InvitationBannerSwitch extends Component<Props> {
	renderIcon = () => {
		return this.props.open ? <ArrowUp size={20} /> : <ArrowDown size={20} />
	}

	switchInvitationBanner = () => {
		this.props.switchInvitationBanner(!this.props.open)
	}

	getStyles = memoize((open: boolean) => {
		return {
			root: {
				display: 'flex',
				flex: '1 0 auto',
				alignSelf: 'center',
				alignItems: 'center',
				justifyContent: 'flex-end',
				cursor: 'pointer',
				fontSize: 11,
				marginTop: open ? 0 : 30,
			},
			line: {
				lineHeight: '22px',
			},
			icon: {
				position: 'relative',
				top: 1,
			},
		}
	})

	render() {
		const styles = this.getStyles(this.props.open)
		return (
			<div css={styles.root} onClick={this.switchInvitationBanner}>
				<span css={styles.line}>{this.props.t('dashboard.invitation.invitationButton')}</span>
				<span css={styles.icon}>{this.renderIcon()}</span>
			</div>
		)
	}
}

export default withTranslate(InvitationBannerSwitch)
