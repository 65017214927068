//@flow
import React, { PureComponent } from 'react'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import { autoTestId, passAutoTestId, type AutoTestProps } from 'utils/tests/autotest'
import ArrowLeft from 'components/svg-icons/hardware/keyboard-arrow-left'
import ArrowRight from 'components/svg-icons/hardware/keyboard-arrow-right'
import MenuItem from 'components/menu-item'
import Paper from 'components/Paper'
import SelectField from 'components/select-field'
import Page from './page'
import styles from './pager.css'

const PAGE_RANGE_DISPLAYED = 4
const PAGE_SIZE_OPTIONS = [
	<MenuItem key={5} value={5} primaryText={'5'} />,
	<MenuItem key={10} value={10} primaryText={'10'} />,
	<MenuItem key={20} value={20} primaryText={'20'} />,
	<MenuItem key={50} value={50} primaryText={'50'} />,
]

type Props = {|
	...AutoTestProps,
	...WithTranslateProps,
	activePage: number,
	lastPage: number,
	pageSize?: number,
	itemsCount?: number,
	showPrevButton: boolean,
	showNextButton: boolean,
	showPageSize?: boolean,
	showItemsOfTotal: boolean,
	compact?: boolean,
	onPageChange: (page: number) => void,
	onPageSizeChange?: (pageSize: number) => void,
|}

type State = {|
	activePage: number,
|}

class Pager extends PureComponent<Props, State> {
	static defaultProps = {
		pageSize: 10,
		showPageSize: true,
		showItemsOfTotal: true,
		showPrevButton: true,
		showNextButton: true,
	}

	state = {
		activePage: this.props.activePage,
	}

	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		if (nextProps.activePage != this.props.activePage)
			this.setState({
				activePage: nextProps.activePage,
			})
	}

	renderPrevButton(handler: Function, caption: string, disabled: boolean = false) {
		if (!this.props.showPrevButton) return null
		const className = disabled ? styles.pagerButtonPrevDisabled : styles.pagerButtonLeft

		return (
			<Paper
				key={'<'}
				onClick={handler}
				className={className}
				zDepth={2}
				{...passAutoTestId(this.props.autoTestId, 'prev-button')}
			>
				<ArrowLeft size={18} style={style.buttonIconLeft} />
				{caption || '<'}
			</Paper>
		)
	}

	renderNextButton(handler: Function, caption: string, disabled: boolean = false) {
		if (!this.props.showNextButton) return null
		const className = disabled ? styles.pagerButtonNextDisabled : styles.pagerButton

		return (
			<Paper
				key={'>'}
				onClick={handler}
				className={className}
				zDepth={2}
				{...passAutoTestId(this.props.autoTestId, 'next-button')}
			>
				{caption || '>'}
				<ArrowRight size={18} style={style.buttonIconRight} />
			</Paper>
		)
	}

	constructPager() {
		const { lastPage } = this.props
		const { activePage } = this.state

		let offset = 1

		let lowerBound = activePage - offset
		if (lowerBound >= lastPage - PAGE_RANGE_DISPLAYED) lowerBound = lastPage - PAGE_RANGE_DISPLAYED

		let upperBound = activePage + (PAGE_RANGE_DISPLAYED - offset - 1)
		if (upperBound <= PAGE_RANGE_DISPLAYED) upperBound = 1 + PAGE_RANGE_DISPLAYED

		let pagerArray = []

		activePage > 1 ? pagerArray.push('<') : pagerArray.push('<_disabled')

		for (let i = 1; i <= lastPage; i++) {
			if (i >= lowerBound && i <= upperBound) {
				pagerArray.push(i)
				continue
			}
			if (i == 1) {
				pagerArray.push('<<')
				if (lowerBound != 2) pagerArray.push('...s')
				continue
			}

			if (i == lastPage) {
				if (upperBound != lastPage - 1) pagerArray.push('...e')
				pagerArray.push('>>')
				continue
			}
		}

		activePage < lastPage ? pagerArray.push('>') : pagerArray.push('>_disabled')

		return pagerArray
	}

	getRenderPagerItem() {
		const { lastPage, compact, t } = this.props
		const { activePage } = this.state

		return (item: any) => {
			if (typeof item === 'number') {
				return (
					<Page
						{...passAutoTestId(this.props.autoTestId, `page-${item}`)}
						key={item}
						page={item}
						onClick={this.getHandlePageChange()}
						activePage={activePage}
						compact={compact}
					/>
				)
			}
			if (typeof item === 'string') {
				switch (item) {
					case '<':
						return this.renderPrevButton(this.getHandlePageChange(activePage - 1), t('grid.pager.prev'))
					case '<_disabled':
						return this.renderPrevButton(() => {}, t('grid.pager.prev'), true)
					case '<<':
						return (
							<Page
								key={item}
								page={1}
								onClick={this.getHandlePageChange()}
								activePage={activePage}
								compact={compact}
								{...passAutoTestId(this.props.autoTestId, 'first-page-button')}
							/>
						)
					case '>':
						return this.renderNextButton(this.getHandlePageChange(activePage + 1), t('grid.pager.next'))
					case '>_disabled':
						return this.renderNextButton(() => {}, t('grid.pager.next'), true)
					case '>>':
						return (
							<Page
								key={item}
								page={lastPage}
								onClick={this.getHandlePageChange()}
								activePage={activePage}
								compact={compact}
								{...passAutoTestId(this.props.autoTestId, 'last-page-button')}
							/>
						)
					case '...e':
					case '...s':
						return (
							<span key={item} className={styles.dots}>
								...
							</span>
						)
					default:
						return null
				}
			}
		}
	}

	onPageChange = (newPage: number) => {
		if (newPage < 1) return
		if (newPage > this.props.lastPage) return
		this.setState({ activePage: newPage })
		this.props.onPageChange(newPage)
	}

	getHandlePageChange(newPage?: number) {
		if (newPage == null)
			return (page?: number) => {
				if (page != null) this.onPageChange(page)
			}
		else
			return () => {
				if (newPage != null) this.onPageChange(newPage)
			}
	}

	handlePageSizeChange = (event: any, index: ?number, value: number) => {
		const { onPageSizeChange } = this.props

		this.setState({
			activePage: 1,
		})

		if (onPageSizeChange != null) onPageSizeChange(value)
	}

	renderItemsOfTotal() {
		const { lastPage, itemsCount, pageSize, t } = this.props
		const { activePage } = this.state
		let _pageSize = pageSize || 10

		if (!this.props.showItemsOfTotal) return null

		let total = itemsCount ?? _pageSize * lastPage
		let start = 1 + (activePage - 1) * _pageSize
		let end = itemsCount && activePage === lastPage ? itemsCount : _pageSize > total ? total : activePage * _pageSize

		return (
			<span className={styles.pageInfo} {...autoTestId(this.props.autoTestId, 'items-of-total')}>
				<span>{`${start}-${end}`}</span> {t('grid.pager.outOf')} <span>{total}</span>
			</span>
		)
	}

	render() {
		const pagerArray = this.constructPager()

		return (
			<div className={styles.root} {...autoTestId(this.props.autoTestId)}>
				{this.renderItemsOfTotal()}
				{this.props.showPageSize && (
					<div style={style.select} {...autoTestId(this.props.autoTestId, 'size-select-wrapper')}>
						<SelectField
							{...passAutoTestId(this.props.autoTestId, 'size-select')}
							onChange={this.handlePageSizeChange}
							value={this.props.pageSize}
							inline
						>
							{PAGE_SIZE_OPTIONS}
						</SelectField>
					</div>
				)}

				{pagerArray.map(this.getRenderPagerItem())}
			</div>
		)
	}
}

const style = {
	select: {
		marginRight: 20,
	},
	buttonIconLeft: {
		verticalAlign: 'middle',
		position: 'relative',
		top: -1,
		marginRight: 8,
		marginLeft: -4,
	},
	buttonIconRight: {
		verticalAlign: 'middle',
		position: 'relative',
		top: -1,
		marginLeft: 8,
		marginRight: -4,
	},
}

export default withTranslate(Pager)
