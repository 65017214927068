// @flow

import React from 'react'
import type { OrganizationContractState } from '../models/swagger-model'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import { getInTriviUntil, isContractTerminated } from '../domain/contract'
import { Snackbar } from 'components'

type Props = {|
	...WithTranslateProps,
	contractState: ?OrganizationContractState,
|}

function ContractState({ t, contractState }: Props) {
	if (!contractState || !contractState.inTriviUntil) return null

	const message = getInTriviUntil(t, contractState)
	const type = isContractTerminated(contractState) ? 'error' : 'warn'

	return <Snackbar open persist showIcon type={type} message={message} />
}

export default withTranslate(ContractState)
