// @flow
import React, { Component } from 'react'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import styles from './csas-statements-options-dialog.css'
import Dialog from 'components/dialog'
import Button from 'components/button'
import Toggle from 'components/toggle'
import Snackbar from 'components/snackbar'
import Helmet from 'react-helmet'

type Props = {|
	open: boolean,
	handleConfirmButtonClick: () => void,
	handleClose: () => void,
	automaticDownloadEnabled: ?boolean,
	...WithTranslateProps,
|}

type State = {|
	toggleValue: ?boolean,
|}

class CsasStatementsOptionsDialog extends Component<Props, State> {
	state = {
		toggleValue: false,
	}

	UNSAFE_componentWillMount() {
		this.updateStateFromProps(this.props)
	}

	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		const { automaticDownloadEnabled: nextAutomaticDownloadEnabled } = nextProps
		const { automaticDownloadEnabled } = this.props
		if (automaticDownloadEnabled !== nextAutomaticDownloadEnabled) {
			this.updateStateFromProps(nextProps)
		}
	}

	updateStateFromProps(props: Props) {
		this.setState({ toggleValue: props.automaticDownloadEnabled })
	}

	switchToggleButton = () => {
		this.setState((state: State) => {
			return {
				...state,
				toggleValue: !state.toggleValue,
			}
		})
	}

	handleSave = () => {
		const { toggleValue } = this.state
		if (toggleValue) {
			this.props.handleConfirmButtonClick()
			this.props.handleClose()
		}
	}

	render() {
		const { toggleValue } = this.state
		const { open, handleClose, t } = this.props

		const actions = [
			<Button
				wide
				primary
				key="saveModalButton"
				labelText={t('application.confirm')}
				disabled={!toggleValue}
				onClick={this.handleSave}
				autoTestId="csas-statement-dialog-confirm"
			/>,
			<Button
				autoTestId="csas-statement-dialog-cancel"
				secondary
				key="cancelModalButton"
				labelText={t('forms.cancelButtonLabel')}
				onClick={handleClose}
				wide
			/>,
		]
		return (
			<Dialog
				autoTestId="csas-statement-dialog"
				open={open}
				title={t('bank.statementOptions.csasDialogTitle')}
				actions={actions}
			>
				<div style={{ maxWidth: 450, margin: '10px auto 0 auto' }}>
					<Helmet>
						<title>{this.props.t('bank.statementOptions.header')}</title>
					</Helmet>
					<div className={styles.row}>
						<Snackbar open inline showIcon message={t('bank.statementOptions.confirmationMessage')} type={'error'} />
					</div>
					<div className={styles.row}>
						<div className={styles.column}>
							<div style={{ display: 'flex', justifyContent: 'center' }}>
								<Toggle
									label={t('bank.statementOptions.toggleLabel')}
									labelInside={t('bank.statementOptions.off')}
									labelInsideChecked={t('bank.statementOptions.on')}
									toggled={!!toggleValue}
									onToggle={this.switchToggleButton}
									labelPosition={'right'}
									autoWidth
									autoTestId="bank-statement-options-toggle"
								/>
							</div>
						</div>
					</div>
				</div>
			</Dialog>
		)
	}
}

export default withTranslate(CsasStatementsOptionsDialog)
