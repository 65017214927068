/* @flow */
/** @jsx jsx */

import { Fragment, type Node } from 'react'
import { jsx } from '@emotion/core'
import Paper from 'components/Paper'
import loader from 'assets/images/loader-block.svg'
import { colors } from 'variables'
import styles from './block.styles'

type Props = {|
	title?: string,
	primary?: string,
	secondary?: string,
	third?: string,
	accentColor?: string,
	titleColor?: string,
	selected?: boolean,
	disabled?: boolean,
	loading?: boolean,
	chips?: Node,
	onClick?: () => void,
|}

export default function Block(props: Props) {
	const backgroundColor = props.accentColor || colors.blue
	const color = props.titleColor || colors.white

	return (
		<div css={{ ...styles.root, ...(props.disabled ? styles.disabled : {}) }}>
			<Paper
				style={{ ...styles.container, ...(props.selected ? styles.selected : {}) }}
				onClick={props.disabled ? undefined : props.onClick}
				zDepth={3}
			>
				{props.loading ? (
					<img css={styles.loader} src={loader} />
				) : (
					<Fragment>
						<div css={styles.circle} style={{ backgroundColor }} />
						<div css={styles.title} style={{ color }}>
							{props.title}
						</div>
						<div css={styles.info}>
							<div css={styles.primary}>{props.primary}</div>
							<div css={styles.secondary}>{props.secondary}</div>
							<div css={styles.secondary}>{props.third}</div>
						</div>
						<div css={styles.chips}>{props.chips}</div>
					</Fragment>
				)}
			</Paper>
		</div>
	)
}
