/* @flow */
/** @jsx jsx */

import { Component } from 'react'
import { jsx } from '@emotion/core'
import type { AccountingDocument, BankAccount, DeferredPromise, DialogResult } from 'types'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import BankAccountItem from 'modules/bank/components/bank-account-item'
import DocumentTreeView from 'modules/document/containers/document-tree-view'
import DocumentsIcon from 'components/svg-icons/trivi/menu/documents'
import BankIcon from 'components/svg-icons/trivi/menu/bank'
import PopupSection from 'components/PopupSection'
import FormBox from 'components/form-box'
import Avatar from 'components/avatar'
import Button from 'components/button'
import { colors } from 'variables'

export type DestinationType = 'banks' | 'documents'

type Props = {|
	...WithTranslateProps,
	open: boolean,
	accountingDocuments: ?Array<AccountingDocument>,
	bankAccounts: Array<BankAccount>,
	moveDocuments: (Array<AccountingDocument>, DestinationType, string) => Promise<*>,
	onRequestClose: () => void,
	autoload: () => void,
	canRemoveToFolderSystemTrivi: boolean,
	onMoveDialogDocumentClosePrm: DeferredPromise<?DialogResult>,
|}

type State = {|
	dialogSubmitted: boolean,
	destination: ?DestinationType,
	selectedFolder: ?string,
	selectedBankAccount: ?string,
|}

class MoveDialog extends Component<Props, State> {
	state = {
		dialogSubmitted: false,
		destination: null,
		selectedFolder: null,
		selectedBankAccount: null,
	}

	isPrmPending = true

	handleMoveClick = () => {
		this.setState({ dialogSubmitted: true }, () => {
			this.props.accountingDocuments &&
				this.state.destination &&
				this.props
					.moveDocuments(
						this.props.accountingDocuments,
						this.state.destination,
						this.state.selectedFolder || this.state.selectedBankAccount || '',
					)
					.then(() => {
						this.props.onMoveDialogDocumentClosePrm && this.props.onMoveDialogDocumentClosePrm.resolve('confirmed')
						this.isPrmPending = false
						this.onRequestClose()
					})
		})
	}

	onRequestClose = () => {
		this.setState({
			dialogSubmitted: false,
			destination: null,
			selectedFolder: null,
			selectedBankAccount: null,
		})
		if (this.isPrmPending && this.props.onMoveDialogDocumentClosePrm) {
			this.props.onMoveDialogDocumentClosePrm.resolve('cancelled')
		}
		this.isPrmPending = true
		this.props.onRequestClose()
	}

	renderBox = (label: string, icon: React$Element<any>, onClick: () => void) => {
		return (
			<div css={styles.box} onClick={onClick}>
				<Avatar
					size={42}
					borderColor={colors.orange600}
					backgroundColor={colors.orange}
					hoverColor={colors.white}
					icon={icon}
				/>
				<div css={styles.boxLabel}>{label}</div>
			</div>
		)
	}

	getDestinationHandler = (destination: ?DestinationType) => {
		return () => {
			this.setState({
				destination,
			})
		}
	}

	handleDocumentClick = (uniqueId: ?string, isFolder: boolean) => {
		if (this.state.selectedFolder === uniqueId || !isFolder) {
			this.setState({ selectedFolder: null })
		} else {
			this.setState({ selectedFolder: uniqueId })
		}
	}

	handleBankAccountClick = (account: BankAccount) => {
		this.setState({ selectedBankAccount: account.id })
	}

	renderDestinationSelection = () => {
		return (
			<div css={styles.row}>
				<div css={styles.column}>
					{this.renderBox(
						this.props.t('dialogs.moveDocument.banks'),
						<BankIcon />,
						this.getDestinationHandler('banks'),
					)}
				</div>
				<div css={styles.column}>
					{this.renderBox(
						this.props.t('dialogs.moveDocument.documents'),
						<DocumentsIcon />,
						this.getDestinationHandler('documents'),
					)}
				</div>
			</div>
		)
	}

	renderDocumentSelection = () => {
		return (
			<DocumentTreeView
				selected={this.state.selectedFolder ? [this.state.selectedFolder] : []}
				onDocumentClick={this.handleDocumentClick}
				hideFolderSystemTrivi={!this.props.canRemoveToFolderSystemTrivi}
			/>
		)
	}

	renderBankSelection = () => {
		return (
			<div css={styles.accounts}>
				{this.props.bankAccounts.map((item: BankAccount) => (
					<div css={styles.account} key={item.id}>
						<BankAccountItem
							key={item.id}
							bankAccount={item}
							onClick={this.handleBankAccountClick}
							selected={item.id === this.state.selectedBankAccount}
						/>
					</div>
				))}
			</div>
		)
	}

	renderBody = () => {
		switch (this.state.destination) {
			case 'banks':
				return this.renderBankSelection()
			case 'documents':
				return this.renderDocumentSelection()
			default:
				return this.renderDestinationSelection()
		}
	}

	isFormValid = () => {
		return this.state.destination && (this.state.selectedFolder || this.state.selectedBankAccount)
	}

	render() {
		const { t } = this.props
		const count = (this.props.accountingDocuments || []).length

		return (
			<PopupSection opaque open={this.props.open} onRequestClose={this.onRequestClose}>
				<FormBox
					actions={
						<Button
							autoTestId="move-dialog-move"
							labelText={t('dialogs.moveDocument.moveButton')}
							onClick={this.handleMoveClick}
							disabled={!this.isFormValid() || this.state.dialogSubmitted}
						/>
					}
					headline={t('dialogs.moveDocument.headline', { count })}
					header={<div css={styles.name}>{t('dialogs.moveDocument.moveto', { count })}</div>}
				>
					{this.renderBody()}
				</FormBox>
			</PopupSection>
		)
	}
}

const styles = {
	row: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-between',
		alignItems: 'center',
		margin: -15,
	},
	column: {
		flex: '1 1 50%',
		padding: 15,
	},
	name: {
		fontSize: 24,
		color: colors.black,
	},
	box: {
		boxShadow: `2px 2px 0 0 ${colors.blackFaded8}`,
		borderWidth: 1,
		borderStyle: 'solid',
		borderColor: colors.gray400,
		borderRadius: 3,
		background: 'rgba(225, 225, 225, 0)',
		padding: 25,
		cursor: 'pointer',
		transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1)',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		alignContent: 'center',

		'&:hover': {
			borderColor: colors.blue,
			backgroundColor: colors.blueFaded10,
			boxShadow: `0px 0px 0 0 ${colors.blackFaded8}`,
		},
	},
	boxLabel: {
		marginTop: 10,
		fontSize: 14,
		color: colors.black,
	},
	accounts: {
		display: 'flex',
		flexWrap: 'wrap',
		margin: -8,
	},
	account: {
		flex: '0 1 50%',
		padding: 8,
	},
}

export default withTranslate(MoveDialog)
