// @flow
/** @jsx jsx */

import { jsx } from '@emotion/core'
import type { AccountingDocument } from 'types'
import { withTranslate, type WithTranslateProps } from 'wrappers'

type Props = {|
	...WithTranslateProps,
	accountingDocument: ?AccountingDocument,
|}

function CustomerInstructions(props: Props) {
	if (!props.accountingDocument || !props.accountingDocument.customerInstructions) return null

	return (
		<div css={styles.description}>
			<span css={styles.bold}>{`${props.t('invoice.classification.descriptionLabel')}: `}</span>
			{props.accountingDocument && props.accountingDocument.customerInstructions}
		</div>
	)
}

const styles = {
	description: {
		marginBottom: 20,
		marginTop: 20,
		padding: '12px 16px 0 16px',
		backgroundColor: '#FFE4B6',
		position: 'relative',
		lineHeight: '22px',
		fontSize: 14,
		'&:before': {
			content: '""',
			height: 14,
			position: 'absolute',
			top: '100%',
			left: 0,
			right: 14,
			background: '#FFE4B6',
			boxShadow: 'inset 0 -2px 0 0 #F3D093',
		},
		'&:after': {
			content: '""',
			width: 0,
			height: 0,
			marginLeft: -7,
			position: 'absolute',
			top: '100%',
			right: 0,
			border: '7px solid #F3D093',
			borderRight: '7px solid transparent',
			borderBottom: '7px solid transparent',
		},
	},
	bold: {
		fontWeight: 'bold',
	},
}

export default withTranslate(CustomerInstructions)
