/* @flow */

import { connect } from 'react-redux'
import type { State, AccountingDocumentIssues } from 'types'
import Issues from '../../components/issues/issues'
import { loadIssues } from '../../actions'
import withAutoload from 'wrappers/with-autoload'
import { getAccountingDocumentIssues } from '../../selectors'

type Props = {|
	accountingDocumentId: string,
	hideTitle?: boolean,
|}

type StateProps = {|
	issuesResponse: ?AccountingDocumentIssues,
|}

const mapStateToProps = (state: State, ownProps: Props): StateProps => {
	return {
		issuesResponse: getAccountingDocumentIssues(state, ownProps.accountingDocumentId),
	}
}

type DispatchProps = {|
	autoload: () => void,
|}

const mapDispatchToProps = (dispatch: Function, ownProps: Props): DispatchProps => {
	return {
		autoload: () => {
			dispatch(loadIssues(ownProps.accountingDocumentId))
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(withAutoload(Issues))
