/* @flow */
/** @jsx jsx */

import { Component } from 'react'
import { jsx } from '@emotion/core'
import { find, unionBy, uniq } from 'lodash-es'
import memoize from 'memoize-one'
import { withTranslate, type WithTranslateProps } from 'wrappers'

import { createCheckedDocItem, getDocumentItemName, getShowDocListItemCheckbox } from 'helpers'
import { multiDownload } from 'helpers/multi-download'
import ContentAdd from 'components/svg-icons/content/add'
import EyeIcon from 'components/svg-icons/trivi/eye'
import MultiActionsToolbar from 'modules/data-grid/components/multi-actions-toolbar'
import type { CheckedDocItem, DocumentDescriptor, DocumentItem, I18NextTranslateFn, MultiAction } from 'types'
import DocumentListItem from './document-list-item'
import DocumentListLoaderItem from './document-list-loader-item'
import DocumentListNotFound from './document-list-not-found'
import DocumentParentItem from './document-parent-item'
import DocumentSelector from './document-selector'
import Avatar from 'components/avatar'
import Dropzone from 'components/dropzone'
import { Pager } from 'components/Pagination'
import TextField from 'components/TextField'
import { Breadcrumbs, Breadcrumb } from 'components/breadcrumbs'
import DeleteDialog from 'components/delete-dialog'
import styles from './document-list.css'
import { colors } from 'variables'
import { downloadRequest as downloadRequestApi } from 'types'
import { buildPrivateUrl } from 'lib/apiHandler'
import { beginTask, endTask } from 'utils/loader'
import { Checkbox } from 'components'
import { DOCUMENT_ITEM_TYPE_FILE, DOCUMENT_ITEM_TYPE_FOLDER, EMPTY_ARRAY } from 'trivi-constants'
import ColumnHeader from '../../data-grid-next/components/column-header'

type Props = {|
	...WithTranslateProps,
	itemId?: string,
	canUserChange: boolean,
	canUserRemove: boolean,
	canUserRemoveToFolderSystemTrivi: boolean,
	searchText: string,
	documents: Array<DocumentItem>, //documents on current page
	currentDocument: ?DocumentItem,
	parentDocumentInfo: ?DocumentDescriptor,
	loading: boolean,
	onNavigationChange: (itemId?: string) => ?Promise<void>,
	onBreadcrumbItemClick: (itemId?: string) => Promise<void>,
	onGoToParent: (itemId?: string) => Promise<void>,
	onFileOpen: (file: DocumentItem) => void,
	onFileView: ?(file: DocumentItem) => void,
	onItemCheck: ?(
		file: DocumentItem,
		checked: boolean,
		createCheckedDocItemWithDocItemOnly: (item: DocumentItem) => CheckedDocItem,
	) => void,
	checkedDocItems?: Array<CheckedDocItem>, //checked documents can be on any page(s)
	saveItemMetadata: (item: DocumentItem) => Promise<void>,
	createFolder: (item: DocumentItem) => Promise<void>,
	uploadDocuments: (files: Array<File>) => Promise<void>,
	deleteDocument: (itemId: string, refreshGrid?: boolean) => Promise<void>,
	moveDocuments: (itemIds: Array<string>, target: string) => Promise<void>,
	showAddButton: boolean,
	showActions: boolean,
	showFilter?: boolean,
	showHeader?: boolean,
	showFileCheckbox?: boolean,
	showFolderCheckbox?: boolean,
	deselectTooltip?: string,
	selectTooltip?: string,
	loadDocuments: (
		itemId?: string,
		searchText: ?string,
		sortingDirection: 'ASC' | 'DESC',
		page: number,
		pageSize: number,
	) => Promise<void>,
	pagerProps: {
		//pagesCount: number,
		//pageSize: number,
		itemsCount: number,
		currentPage: number,
	},
	sortingDirection: 'ASC' | 'DESC',
	showBreadcrumbs?: boolean,
	smallBreadcrumbs?: boolean,
	changeSort: (sortingDirection: 'ASC' | 'DESC') => Promise<void>,
	onSearchTextChange: (searchText: string) => ?Promise<void>,
	light?: boolean,
	isAttachDocumentList?: boolean,
|}

type State = {|
	creatingFolder: boolean,
	pageSize: number,
	searchText: string,
	movedDocItems?: Array<CheckedDocItem>,
	checkedDocItems: Array<CheckedDocItem>, //checked documents can be on any page(s)
|}

class DocumentList extends Component<Props, State> {
	state: State = {
		creatingFolder: false,
		pageSize: 10,
		searchText: this.props.searchText || '',
		checkedDocItems: this.getCheckedDocItems(this.props.checkedDocItems),
	}

	componentDidMount() {
		this.props.loadDocuments(
			this.props.itemId,
			this.props.searchText,
			this.props.sortingDirection,
			this.props.pagerProps.currentPage,
			this.state.pageSize,
		)
	}

	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		if (this.props.itemId !== nextProps.itemId || this.props.searchText !== nextProps.searchText) {
			this.props.loadDocuments(
				nextProps.itemId,
				nextProps.searchText,
				nextProps.sortingDirection,
				nextProps.pagerProps.currentPage,
				this.state.pageSize,
			)
			this.setState({
				searchText: nextProps.searchText || '',
			})
		}
		if (this.props.checkedDocItems !== nextProps.checkedDocItems) {
			const checkedDocItems = this.getCheckedDocItems(nextProps.checkedDocItems, this.state.checkedDocItems)
			this.setState({ checkedDocItems })
		}
	}

	getCheckedDocItems(propsCheckedDocItems: ?Array<CheckedDocItem>, stateCheckedDocItems?: Array<CheckedDocItem>) {
		const checkedDocItems = propsCheckedDocItems || stateCheckedDocItems || EMPTY_ARRAY
		return [...checkedDocItems]
	}

	isSearching = () => this.state.searchText.length > 0

	handleDocumentItemClick = (item: DocumentItem) => {
		if (item.itemType === DOCUMENT_ITEM_TYPE_FILE && item.fileId && item.name) {
			this.props.onFileOpen(item)
		} else if (item.itemType === DOCUMENT_ITEM_TYPE_FOLDER) {
			this.props.onNavigationChange(item.uniqueId)
		}
	}

	handleParentClick = () => {
		this.props.onGoToParent((this.props.parentDocumentInfo && this.props.parentDocumentInfo.uniqueId) || '')
	}

	handleNewFolderChange = (item: DocumentItem) => {
		this.setState({
			creatingFolder: false,
		})
		this.props.createFolder(item)
	}

	handleNewFolderCancelEdit = () => {
		this.setState({
			creatingFolder: false,
		})
	}

	createNewFolder = (event: Event) => {
		event.preventDefault()
		this.setState({
			creatingFolder: true,
		})
	}

	handlePageChange = (page: number) => {
		this.props.loadDocuments(
			this.props.itemId,
			this.props.searchText,
			this.props.sortingDirection,
			page,
			this.state.pageSize,
		)
	}

	handlePageSizeChange = (pageSize: number) => {
		this.setState({
			pageSize,
		})

		this.props.loadDocuments(this.props.itemId, this.props.searchText, this.props.sortingDirection, 1, pageSize)
	}

	handleNameClick = () => {
		this.props.changeSort(this.props.sortingDirection === 'ASC' ? 'DESC' : 'ASC')
	}

	handleSearchChange = (event: SyntheticInputEvent<HTMLInputElement>, searchText: ?string) => {
		if (searchText !== null && searchText !== undefined) {
			this.setState({
				searchText,
			})
		}
	}

	handleSearchBlur = () => {
		this.props.onSearchTextChange(this.state.searchText)
	}

	createItemKey(itemId?: string = '0') {
		return `${itemId}`
	}

	onBreadcrumbItemClick = (itemId?: string) => {
		this.props.onBreadcrumbItemClick(itemId)
	}

	renderBreadcrumbs = () => {
		return (
			<Breadcrumbs small={this.props.smallBreadcrumbs}>
				<Breadcrumb
					section
					key={'headline'}
					onClick={this.isSearching() || this.props.currentDocument ? this.onBreadcrumbItemClick : null}
				>
					{this.props.t('document.headline')}
				</Breadcrumb>
				{this.props.showBreadcrumbs && this.renderBreadcrumb()}
				{this.props.searchText && <Breadcrumb key={'search'}>{this.props.t('document.searchHeadline')}</Breadcrumb>}
			</Breadcrumbs>
		)
	}

	renderBreadcrumb = () => {
		const { parentDocumentInfo, currentDocument } = this.props
		const res = []
		let item: ?DocumentDescriptor = parentDocumentInfo
		let index = 0

		while (item && item.uniqueId) {
			res.unshift(
				<Breadcrumb key={index} value={item.uniqueId || ''} onClick={this.onBreadcrumbItemClick}>
					{getDocumentItemName(this.props.t, item)}
				</Breadcrumb>,
			)
			item = item.parentDocumentInfo
			index++
		}

		if (currentDocument && currentDocument.uniqueId) {
			res.push(<Breadcrumb key={'current'}>{getDocumentItemName(this.props.t, currentDocument)}</Breadcrumb>)
		}
		return res
	}

	renderUploadButton = () => {
		if (!this.props.showAddButton) return null

		return (
			<div className={styles.action}>
				<Dropzone multiple onDrop={this.props.uploadDocuments} />
			</div>
		)
	}

	renderCreateFolderButton = () => {
		if (!this.props.showAddButton) return null

		return (
			<div className={styles.action}>
				<div className={styles.create} onClick={this.createNewFolder}>
					<Avatar
						className={styles.avatar}
						size={32}
						backgroundColor={colors.orange}
						icon={<ContentAdd hoverColor={colors.white} />}
					/>
					{this.props.t('document.newFolder')}
				</div>
			</div>
		)
	}

	hasDocuments = () => {
		return this.props.documents && this.props.documents.length > 0
	}

	renderLoaders = () => {
		if (!this.props.loading || this.hasDocuments()) return null
		return (
			<div>
				<DocumentListLoaderItem />
				<DocumentListLoaderItem width={92} />
				<DocumentListLoaderItem width={140} opacity={0.7} />
				<DocumentListLoaderItem width={123} opacity={0.45} />
				<DocumentListLoaderItem width={104} opacity={0.45} />
			</div>
		)
	}

	renderNotFound = () => {
		if (this.props.loading) return null
		if (this.isSearching() && !this.hasDocuments()) {
			return <DocumentListNotFound />
		}
	}

	onSearchKeyDown = (ev: Object) => {
		// enter pressed
		if (ev.keyCode === 13) {
			this.handleSearchBlur()
		}
	}

	renderReadonlyWarning(style: Object) {
		if (this.props.searchText && this.props.searchText.length > 0) return null
		return (
			<div className={styles.warning}>
				<div className={styles.cantUpload}>
					<EyeIcon style={style.icon} color={'#6D7379'} disabled />
					<span>{this.props.t('document.uploadDisabled')}</span>
				</div>
			</div>
		)
	}

	renderButtons(style: Object) {
		const { t, canUserChange, currentDocument, showAddButton, showFilter } = this.props

		const canBeModified: ?boolean =
			showAddButton && currentDocument && currentDocument.canChildrenBeCreated && canUserChange

		return showFilter || showAddButton ? (
			<div className={styles.toolbar}>
				{canBeModified ? (
					<div className={styles.actions}>
						{this.renderUploadButton()}
						{this.renderCreateFolderButton()}
					</div>
				) : showAddButton ? (
					this.renderReadonlyWarning(style)
				) : null}

				{showFilter && (
					<div className={styles.filters}>
						<TextField
							hintText={t('application.filter.searchHint')}
							autoTestId="document-list-search"
							style={style.searchHint}
							value={this.state.searchText}
							onChange={this.handleSearchChange}
							onBlur={this.handleSearchBlur}
							onKeyDown={this.onSearchKeyDown}
							name="filter"
						/>
					</div>
				)}
			</div>
		) : null
	}

	getDocListItemCanBeChanged = (doc: DocumentItem, canUserChange: boolean): boolean => {
		return !!(doc.canBeChanged && canUserChange)
	}

	getDocListItemCanBeRemoved = (doc: DocumentItem, canUserRemove: boolean): boolean => {
		return !!(doc.canBeChanged && canUserRemove)
	}

	getIsCheckedDocumentSpecialySelectable(checkedDocItem: CheckedDocItem): boolean {
		return !!(checkedDocItem && !checkedDocItem.hasVisibleCheckboxBasic && checkedDocItem.hasVisibleRightActions)
	}

	getIsDocumentSelectable(item: DocumentItem): boolean {
		return getShowDocListItemCheckbox(
			item,
			this.props.showFileCheckbox,
			this.props.showFolderCheckbox,
			this.props.showActions,
			this.getDocListItemCanBeChanged(item, this.props.canUserChange),
			this.getDocListItemCanBeRemoved(item, this.props.canUserRemove),
		)
	}

	isDocumentSelected(checkedDocItems: Array<CheckedDocItem>, uniqueId: string) {
		return !!find(checkedDocItems, ['uniqueId', uniqueId])
	}

	getSelectableDocItemsOnCurrentPage = memoize((documents: Array<DocumentItem>) => {
		return documents
			.filter((doc: DocumentItem) => this.getIsDocumentSelectable(doc))
			.map((doc: DocumentItem) => {
				return this.createCheckedDocItemWithDocItemOnly(doc)
			})
	})

	getAreAllDocsOnCurrentPageSelected = memoize(
		(documents: Array<DocumentItem>, checkedDocItems: Array<CheckedDocItem>): boolean => {
			const selectableDocItemsOnCurPage = this.getSelectableDocItemsOnCurrentPage(documents)
			const selectedDocsOnCurPage = documents.filter((doc: DocumentItem) =>
				this.isDocumentSelected(checkedDocItems, doc.uniqueId || ''),
			)
			return selectedDocsOnCurPage.length > 0 && selectedDocsOnCurPage.length === selectableDocItemsOnCurPage.length
		},
	)

	getAreAnyDocumentsSeletected = memoize(
		(documents: Array<DocumentItem>, checkedDocItems: Array<CheckedDocItem>): boolean => {
			return documents.some(
				(doc: DocumentItem) =>
					this.getIsDocumentSelectable(doc) && this.isDocumentSelected(checkedDocItems, doc.uniqueId || ''),
			)
		},
	)

	onCheckAll = (event: any, checked: boolean) => {
		const newCheckedDocItems = checked
			? unionBy(this.state.checkedDocItems, this.getSelectableDocItemsOnCurrentPage(this.props.documents), 'uniqueId')
			: this.state.checkedDocItems.filter(
					(doc: CheckedDocItem) => !find(this.props.documents, ['uniqueId', doc.uniqueId]),
			  )
		this.setState({ checkedDocItems: uniq(newCheckedDocItems) })
	}

	render() {
		const {
			canUserChange,
			canUserRemove,
			documents,
			currentDocument,
			showActions,
			showFileCheckbox,
			showFolderCheckbox,
			smallBreadcrumbs,
			showHeader,
			loading,
			light,
			parentDocumentInfo,
			pagerProps,
			t,
			deleteDocument,
			saveItemMetadata,
		} = this.props
		const { checkedDocItems, pageSize } = this.state

		const { currentPage, itemsCount } = pagerProps
		const parentFolderName = parentDocumentInfo
			? getDocumentItemName(t, parentDocumentInfo)
			: t('document.rootFolderName')
		const selectableDocItemsOnCurPage = this.getSelectableDocItemsOnCurrentPage(documents)
		const areAllDocumentsSelected = this.getAreAllDocsOnCurrentPageSelected(documents, checkedDocItems)
		const areAnyDocumentsSelected = this.getAreAnyDocumentsSeletected(documents, checkedDocItems)
		const showHeaderCheckbox = (showFileCheckbox || showFolderCheckbox) && selectableDocItemsOnCurPage.length > 0
		const style = this.getStyles(areAnyDocumentsSelected, showFileCheckbox, showFolderCheckbox)

		return (
			<div className={light ? styles.light : styles.normal}>
				{!smallBreadcrumbs && this.renderBreadcrumbs()}

				{this.renderButtons(style)}
				{!this.props.isAttachDocumentList &&
					this.renderMultiActionsToolbar(canUserChange, canUserRemove, checkedDocItems, showActions, t)}

				{showHeader && (
					<div className={styles.headerContainer}>
						<div className={showHeaderCheckbox ? styles.headerWithCheck : styles.header}>
							{showHeaderCheckbox ? (
								<div css={style.headerCheckbox}>
									<Checkbox
										checked={areAllDocumentsSelected || areAnyDocumentsSelected}
										showMinusIcon={areAnyDocumentsSelected && !areAllDocumentsSelected}
										onCheck={this.onCheckAll}
									/>
								</div>
							) : (
								<span css={style.headerCheckbox} />
							)}
							<span className={styles.name}>
								<ColumnHeader
									id={'companyName'}
									title={t('document.header.name')}
									sortable
									direction={this.props.sortingDirection}
									onDirectionChange={this.handleNameClick}
								/>
							</span>
							<span className={styles.note}>
								<ColumnHeader id={'note'} direction={this.props.sortingDirection} title={t('document.header.note')} />
							</span>
							<span className={styles.menu} />
						</div>
					</div>
				)}

				<div className={styles.list}>
					{smallBreadcrumbs && <div css={style.smallBreadcrumbs}>{this.renderBreadcrumbs()}</div>}

					{currentDocument && (
						<DocumentParentItem
							labelText={t('document.backToFolder', { folder: parentFolderName })}
							key="parentKey"
							id="parentKey"
							onClick={this.handleParentClick}
							style={style.fileFolderCheckbox}
						/>
					)}
					{this.state.creatingFolder && (
						<DocumentListItem
							key={0}
							item={{
								itemType: DOCUMENT_ITEM_TYPE_FOLDER,
								name: t('document.newFolderDefaultName'),
								folderType: 0,
							}}
							showInEditMode
							onChange={this.handleNewFolderChange}
							onCancelEdit={this.handleNewFolderCancelEdit}
							style={style.fileFolderCheckbox}
						/>
					)}
					{documents &&
						documents.map((item: DocumentItem) => (
							<DocumentListItem
								key={this.createItemKey(item.uniqueId)}
								item={item}
								canBeChanged={this.getDocListItemCanBeChanged(item, canUserChange)}
								canBeRemoved={this.getDocListItemCanBeRemoved(item, canUserRemove)}
								checked={this.isDocumentSelected(checkedDocItems, item.uniqueId || '')}
								showActions={showActions}
								deselectTooltip={this.props.deselectTooltip || ''}
								selectTooltip={this.props.selectTooltip || ''}
								showFileCheckbox={showFileCheckbox}
								showFolderCheckbox={showFolderCheckbox}
								onClick={this.handleDocumentItemClick}
								onDelete={deleteDocument}
								onChange={saveItemMetadata}
								onMove={this.onDocumentMove}
								onItemCheck={this.onItemCheck}
								isAttachDocumentList={this.props.isAttachDocumentList}
							/>
						))}
					{this.renderNotFound()}
					{this.renderLoaders()}
				</div>

				{!loading && documents && documents.length === 0 && null}

				<Pager
					activePage={currentPage || 1}
					lastPage={Math.ceil(itemsCount / pageSize) || 1}
					pageSize={pageSize}
					onPageChange={this.handlePageChange}
					onPageSizeChange={this.handlePageSizeChange}
					itemsCount={itemsCount}
					autoTestId="document-list-pager"
				/>
				{this.renderMoveDialog()}
			</div>
		)
	}

	renderMoveDialog() {
		return (
			<DocumentSelector
				amount={this.state.movedDocItems ? this.state.movedDocItems.length : 0}
				open={!!this.state.movedDocItems}
				onSelect={this.handleDocumentMove}
				onClose={this.handlerDocumentMoveClose}
				hideFolderSystemTrivi={!this.props.canUserRemoveToFolderSystemTrivi}
			/>
		)
	}

	createCheckedDocItemWithDocItemOnly = (item: DocumentItem): CheckedDocItem => {
		return createCheckedDocItem(
			item,
			this.props.showFileCheckbox,
			this.props.showFolderCheckbox,
			this.props.showActions,
			this.getDocListItemCanBeChanged(item, this.props.canUserChange),
			this.getDocListItemCanBeRemoved(item, this.props.canUserRemove),
		)
	}

	onDocumentMove = (item: DocumentItem) => {
		const checkedDocItem = this.createCheckedDocItemWithDocItemOnly(item)
		this.setState({ movedDocItems: [checkedDocItem] })
	}

	handleDocumentMove = async (target: string) => {
		if (this.state.movedDocItems) {
			const documents = this.state.movedDocItems
				.filter((document: CheckedDocItem) => document.uniqueId !== target)
				.map((document: CheckedDocItem) => document.uniqueId)
			await this.props.moveDocuments(documents, target)
			this.setState({
				movedDocItems: undefined,
				checkedDocItems: [],
			})
		}
	}

	handlerDocumentMoveClose = () => {
		this.setState({ movedDocItems: undefined })
	}

	getCheckedDocumentsFileIds = memoize((checkedDocItems: Array<CheckedDocItem>): Array<string> => {
		return checkedDocItems
			.filter((checkedDoc: CheckedDocItem) => checkedDoc.itemType === DOCUMENT_ITEM_TYPE_FILE && checkedDoc.fileId)
			.map((checkedDoc: CheckedDocItem) => checkedDoc.fileId)
	})

	renderMultiActionsToolbar = memoize(
		(
			canUserChange: boolean,
			canUserRemove: boolean,
			checkedDocItems: Array<CheckedDocItem>,
			showActions: boolean,
			t: I18NextTranslateFn,
		) => {
			const actions = []
			const visibleActions = []
			const areFoldersSelected = checkedDocItems.some(
				(doc: CheckedDocItem) => doc.itemType === DOCUMENT_ITEM_TYPE_FOLDER,
			)
			const areSelectedFilesWithFileIds = this.getCheckedDocumentsFileIds(checkedDocItems).length > 0
			const areAnySpecialySelectable = checkedDocItems.some((doc: CheckedDocItem) =>
				this.getIsCheckedDocumentSpecialySelectable(doc),
			)

			if (canUserChange && !areAnySpecialySelectable) {
				actions.push({ name: t('document.moveButton'), type: 'move' })
				visibleActions.push('move')
			}
			if (canUserChange && canUserRemove && !areAnySpecialySelectable) {
				actions.push({ name: t('grid.action.delete'), type: 'delete' })
				visibleActions.push('delete')
			}

			if (!areFoldersSelected && areSelectedFilesWithFileIds) {
				actions.push({ name: t('grid.action.download'), type: 'download' })
				visibleActions.push('download')
			}

			return (
				<MultiActionsToolbar
					onMultiAction={this.onMultiAction}
					visible={showActions && checkedDocItems.length > 0}
					visibleMultiActions={visibleActions}
					selectedItems={checkedDocItems.map((doc: CheckedDocItem) => doc.uniqueId)}
					actions={actions}
				/>
			)
		},
	)

	downloadMultipleFiles = async () => {
		const loaderId = 'downloadMultipleFiles'
		const copyCheckedFileIds = this.getCheckedDocumentsFileIds(this.state.checkedDocItems)

		this.setState({ checkedDocItems: [] })

		try {
			beginTask(loaderId)
			const downloadTokens = await Promise.all(
				copyCheckedFileIds.map((fileId: string) => {
					return downloadRequestApi.post({ fileId })
				}),
			)
			if (downloadTokens && downloadTokens.length > 0) {
				const downloadUrls = downloadTokens.map((resp: { token?: string }) => {
					return (resp && resp.token && buildPrivateUrl(`uploads/${resp.token}`)) || ''
				})
				await multiDownload(downloadUrls)
				endTask(loaderId)
			} else {
				endTask(loaderId)
			}
		} catch (e) {
			// Do nothing
		}
	}

	deleteMultipleFiles = async () => {
		const length = this.state.checkedDocItems.length
		try {
			await DeleteDialog()
			await Promise.all(
				this.state.checkedDocItems.filter(Boolean).map((doc: CheckedDocItem, idx: number) => {
					const isLastDoc = length === idx + 1
					return this.props.deleteDocument && this.props.deleteDocument(doc.uniqueId, isLastDoc)
				}),
			)
			this.setState({ checkedDocItems: [] })
		} catch (e) {
			// Do nothing
		}
	}

	onMultiAction = async (action: MultiAction) => {
		if ('move' === action) {
			this.setState({ movedDocItems: this.state.checkedDocItems })
		}
		if ('download' === action) {
			this.downloadMultipleFiles()
		}
		if ('delete' === action) {
			this.deleteMultipleFiles()
		}
	}

	onItemCheck = (file: DocumentItem, checked: boolean) => {
		this.props.onItemCheck
			? this.props.onItemCheck(file, checked, this.createCheckedDocItemWithDocItemOnly)
			: ((file: DocumentItem, checked: boolean) => {
					this.setState((state: State) => {
						const checkedDocItem = this.createCheckedDocItemWithDocItemOnly(file)
						const checkedDocItems = checked
							? [...state.checkedDocItems, checkedDocItem]
							: state.checkedDocItems.filter((doc: CheckedDocItem) => file.uniqueId !== doc.uniqueId)
						return { ...state, checkedDocItems }
					})
			  })(file, checked)
	}

	getStyles = memoize((areAnyDocumentsSelected: boolean, showFileCheckbox: ?boolean, showFolderCheckbox: ?boolean) => {
		return {
			icon: {
				marginRight: 12,
				verticalAlign: 'middle',
				position: 'relative',
				top: -1,
			},
			headerCheckbox: {
				opacity: areAnyDocumentsSelected ? 1 : 0,
				'&:hover': {
					opacity: 1,
				},
				display: 'inline-flex',
				flex: 'auto',
				marginRight: 13,
				marginLeft: 8,
				left: -12,
				width: 34,
				height: 24,
				lineHeight: '24px',
				alignItems: 'center',
				position: 'relative',
			},
			searchHint: {
				borderRadius: 21,
				borderRight: `1px solid ${colors.grey400}`,
				height: 40,
				padding: '8px 16px',
				fontSize: 12,
			},
			smallBreadcrumbs: {
				paddingTop: 15,
				paddingBottom: 15,
				paddingLeft: 25,
			},
			fileFolderCheckbox: {
				paddingLeft: showFileCheckbox || showFolderCheckbox ? 35 : 15,
			},
		}
	})
}

export default withTranslate(DocumentList)
