/* @flow */

// $FlowFixMe - there is useState
import React, { useState, Fragment } from 'react'
import DefaultValues from 'modules/settings/containers/accounting-document-defaults'
import CustomizationValues from 'modules/settings/containers/customization-values'

type Props = {|
	currentUserIsInternal: boolean,
|}

export default function AcountingDocuments(props: Props) {
	const [view, setView] = useState<string>(null)

	function onClose() {
		setView(null)
	}

	function onValuesOpen() {
		setView('values')
	}

	function onIssuedOpen() {
		setView('issued')
	}

	function onReceivedOpen() {
		setView('received')
	}

	return (
		<Fragment>
			<CustomizationValues open={'values' === view} onClose={onClose} onOpen={onValuesOpen} />
			<DefaultValues open={'issued' === view} direction={'issued'} onClose={onClose} onOpen={onIssuedOpen} />
			{props.currentUserIsInternal && (
				<DefaultValues open={'received' === view} direction={'received'} onClose={onClose} onOpen={onReceivedOpen} />
			)}
		</Fragment>
	)
}
