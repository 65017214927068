/* @flow */
/** @jsx jsx */

import { PureComponent, type Node } from 'react'
import { autoTestId, type AutoTestProps } from 'utils/tests/autotest'
import memoize from 'memoize-one'
import { jsx } from '@emotion/core'
import { colors } from 'variables'

type Props = {|
	...AutoTestProps,
	children: Node,
	style?: Object,
	htmlFor?: string,
|}

class TextFieldLabel extends PureComponent<Props> {
	getStyles = memoize((style: ?Object) => {
		return {
			root: {
				display: 'block',
				transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1)',
				zIndex: 1,
				pointerEvents: 'auto',
				userSelect: 'none',
				color: colors.black,
				whiteSpace: 'nowrap',
				flex: '0 0 auto',
				fontSize: 14,
				paddingRight: 4,
				paddingBottom: 7,
				lineHeight: '17px',
				...style,
			},
		}
	})

	render() {
		const styles = this.getStyles(this.props.style)

		return (
			<label css={styles.root} htmlFor={this.props.htmlFor} {...autoTestId(this.props.autoTestId)}>
				{this.props.children}
			</label>
		)
	}
}

export default TextFieldLabel
