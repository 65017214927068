/* @flow */
/** @jsx jsx */

import { jsx } from '@emotion/core'
import { formatToMoney } from 'utils/formatters'

export type Props = {|
	amount: ?number,
	currency: ?string,
|}

export default function AmountDisplay(props: Props) {
	return <span css={styles.root}>{formatToMoney(props.amount ?? 0, { currency: props.currency })}</span>
}

const styles = {
	root: {
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
	},
}
