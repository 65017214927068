/* @flow */
import deepEqual from 'deep-equal'
import Produce from 'immer'
import type {
	AccountingDocumentDefaults,
	ApiApp,
	EmailTemplate,
	Base64Image,
	OrganizationSettings,
	PaymentReminder,
	Signatures,
	AccountingDocumentCategory,
	OrganizationSettingsTaxPaymentHistory,
	OrganizationSettingsOpen,
	OrganizationContractState,
	OrganizationNote,
	OrganizationSettingsOssCountries,
} from 'types'
import type { Action } from '../actions/action-types'
import { sortAccountingDocumentCategories } from '../domain/categories'
import { EMPTY_OBJECT } from 'trivi-constants'

export type DefaultsType = {
	[direction: string]: {
		[type: string]: ?AccountingDocumentDefaults,
	},
}

export type State = {
	organizationSettings: ?OrganizationSettings,
	organizationSettingsOpen: ?OrganizationSettingsOpen,
	organizationLogo: ?Base64Image,
	apiApps: ?Array<ApiApp>,
	loading: boolean,
	signatures: {
		list: ?Signatures,
		loading: boolean,
	},
	signaturesUploadProgress: {
		[key: string]: {
			progress: ?number,
			name: ?string,
		},
	},
	emailTemplates: ?Array<EmailTemplate>,
	accountingDocumentDefaults: DefaultsType,
	paymentReminders: ?Array<PaymentReminder>,
	accountingDocumentCategories: ?Array<AccountingDocumentCategory>,
	taxPaymentHistory: ?Array<OrganizationSettingsTaxPaymentHistory>,
	contractState: ?OrganizationContractState,
	oss: ?OrganizationSettingsOssCountries,
	notes: ?OrganizationNote,
}

export const initialState: State = {
	organizationSettings: null,
	organizationSettingsOpen: null,
	organizationLogo: null,
	apiApps: null,
	loading: false,
	signatures: {
		list: null,
		loading: false,
	},
	signaturesUploadProgress: {},
	emailTemplates: null,
	accountingDocumentDefaults: {},
	paymentReminders: null,
	accountingDocumentCategories: null,
	taxPaymentHistory: null,
	contractState: null,
	notes: null,
	oss: null,
}

export default (state: State = initialState, action: Action): State => {
	switch (action.type) {
		case 'START_LOADING_ORGANIZATION_SETTINGS': {
			return { ...state, loading: true }
		}

		case 'FINISH_LOADING_ORGANIZATION_SETTINGS': {
			return {
				...state,
				organizationSettings:
					action.organizationSettings && !deepEqual(action.organizationSettings, state.organizationSettings)
						? action.organizationSettings
						: state.organizationSettings,
				loading: false,
			}
		}

		case 'START_LOADING_ORGANIZATION_NOTE_EXTRACTION': {
			return { ...state, loading: true }
		}

		case 'FINISH_LOADING_ORGANIZATION_NOTE_EXTRACTION': {
			return {
				...state,
				notes: action.note,
				loading: false,
			}
		}

		case 'START_LOADING_ORGANIZATION_SETTINGS_OPEN': {
			return { ...state, loading: true }
		}

		case 'FINISH_LOADING_ORGANIZATION_SETTINGS_OPEN': {
			return {
				...state,
				organizationSettingsOpen:
					action.organizationSettingsOpen && !deepEqual(action.organizationSettingsOpen, state.organizationSettingsOpen)
						? action.organizationSettingsOpen
						: state.organizationSettingsOpen,
				loading: false,
			}
		}

		case 'START_UPDATING_ORGANIZATION_SETTINGS': {
			const newTaxPaymentHistory = state.taxPaymentHistory && [...state.taxPaymentHistory]
			if (
				newTaxPaymentHistory &&
				state.taxPaymentHistory &&
				state.taxPaymentHistory.length &&
				action.newOrganizationSettings
			) {
				newTaxPaymentHistory[newTaxPaymentHistory.length - 1] = {
					...state.taxPaymentHistory[state.taxPaymentHistory.length - 1],
					dateFrom: action.newOrganizationSettings.payerTaxTypeFrom || '',
					note: action.newOrganizationSettings.payerTaxTypeNote || '',
				}
			}

			return {
				...state,
				organizationSettings: action.newOrganizationSettings,
				taxPaymentHistory: newTaxPaymentHistory,
				loading: true,
			}
		}

		case 'START_UPDATING_ORGANIZATION_NOTE_EXTRACTION': {
			return {
				...state,
				loading: true,
			}
		}

		case 'FINISH_UPDATING_ORGANIZATION_NOTE_EXTRACTION': {
			return {
				...state,
				notes: action.serverError ? null : action.note,
				loading: false,
			}
		}

		case 'FINISH_UPDATING_ORGANIZATION_SETTINGS': {
			return {
				...state,
				organizationSettings: action.serverError ? action.oldOrganizationSettings : action.newOrganizationSettings,
				loading: false,
			}
		}

		case 'START_LOADING_ORGANIZATION_API_APPS': {
			return {
				...state,
				loading: true,
			}
		}

		case 'FINISH_LOADING_ORGANIZATION_API_APPS': {
			return {
				...state,
				apiApps: action.apiApps,
			}
		}

		case 'FINISH_ADDING_ORGANIZATION_API_APP': {
			if (action.apiApp) {
				return {
					...state,
					apiApps: [...(state.apiApps || []), action.apiApp],
				}
			}
			return state
		}

		case 'FINISH_REMOVING_ORGANIZATION_API_APP': {
			if (action.apiAppId) {
				const apiApps: Array<ApiApp> = (state.apiApps || []).filter(
					(app: ApiApp) => action.apiAppId && app.id != action.apiAppId.toString(),
				)
				return {
					...state,
					apiApps,
				}
			}
			return state
		}

		case 'FINISH_EDITING_ORGANIZATION_API_APP': {
			if (action.apiApp) {
				const apiApps: Array<ApiApp> = (state.apiApps || []).map((app: ApiApp) => {
					return action.apiApp && app.id === action.apiApp.id ? action.apiApp : app
				})
				return {
					...state,
					apiApps,
				}
			}
			return state
		}

		case 'START_LIST_SIGNATURES': {
			return {
				...state,
				signatures: {
					...state.signatures,
					loading: true,
				},
			}
		}

		case 'FINISH_LIST_SIGNATURES': {
			const { signatures } = action
			return {
				...state,
				signatures: {
					list: signatures,
					loading: false,
				},
			}
		}

		case 'UPDATE_UPLOAD_PROGRESS': {
			const { uploadProgress } = action
			return {
				...state,
				signaturesUploadProgress: {
					...state.signaturesUploadProgress,
					...uploadProgress,
				},
			}
		}

		case 'DISMISS_UPLOAD_PROGRESS': {
			const { key } = action
			let newState = {
				...state,
				signaturesUploadProgress: {
					...state.signaturesUploadProgress,
				},
			}

			delete newState.signaturesUploadProgress[key]
			return newState
		}

		case 'FINISH_LOADING_EMAIL_TEMPLATES': {
			return {
				...state,
				emailTemplates: action.emailTemplates,
			}
		}

		//optimistic update
		case 'START_EDITING_EMAIL_TEMPLATE': {
			return {
				...state,
				emailTemplates:
					state.emailTemplates &&
					state.emailTemplates.map((t: EmailTemplate) => {
						return action.newEmailTemplate && t.id === action.newEmailTemplate.id ? action.newEmailTemplate : t
					}),
			}
		}

		case 'FINISH_EDITING_EMAIL_TEMPLATE': {
			return {
				...state,
				emailTemplates:
					state.emailTemplates &&
					state.emailTemplates.map((t: EmailTemplate) => {
						if (action.newEmailTemplate && t.id === action.newEmailTemplate.id) {
							return action.serverError ? action.oldEmailTemplate : action.newEmailTemplate
						}
						return t
					}),
			}
		}

		// optimistic update
		case 'START_DELETING_EMAIL_TEMPLATE': {
			return {
				...state,
				emailTemplates: action.newEmailTemplates,
			}
		}

		case 'FINISH_DELETING_EMAIL_TEMPLATE': {
			return {
				...state,
				emailTemplates: action.serverError ? action.oldEmailTemplates : action.newEmailTemplates,
			}
		}

		case 'FINISH_CREATING_EMAIL_TEMPLATE': {
			if (action.emailTemplate) {
				return {
					...state,
					emailTemplates: [...(state.emailTemplates || []), action.emailTemplate],
				}
			}
			return state
		}

		case 'FINISH_LOADING_ACCOUNTING_DOCUMENT_DEFAULTS': {
			if (!action.serverError) {
				return {
					...state,
					accountingDocumentDefaults: {
						...state.accountingDocumentDefaults,
						[action.accDirection || '']: {
							...(state.accountingDocumentDefaults && state.accountingDocumentDefaults[action.accDirection || '']),
							[action.accType || '']: action.defaults,
						},
					},
				}
			}
			return state
		}

		// optimistic update:
		case 'START_EDITING_ACCOUNTING_DOCUMENT_DEFAULTS': {
			return {
				...state,
				accountingDocumentDefaults: {
					...state.accountingDocumentDefaults,
					[action.accDirection || '']: {
						...(state.accountingDocumentDefaults && state.accountingDocumentDefaults[action.accDirection || '']),
						[action.accType || '']: action.newDefaults,
						loading: true,
					},
				},
			}
		}

		case 'FINISH_EDITING_ACCOUNTING_DOCUMENT_DEFAULTS': {
			return {
				...state,
				accountingDocumentDefaults: {
					...state.accountingDocumentDefaults,
					[action.accDirection || '']: {
						...(state.accountingDocumentDefaults && state.accountingDocumentDefaults[action.accDirection || '']),
						[action.accType || '']: !action.serverError ? action.newDefaults : action.oldDefaults,
						loading: false,
					},
				},
			}
		}

		case 'FINISH_GET_ORGANIZATION_LOGO': {
			if (action.serverError == null) return { ...state, organizationLogo: action.response }
			else return { ...state, organizationLogo: null }
		}

		case 'FINISH_UPLOAD_ORGANIZATION_LOGO': {
			return { ...state, organizationLogo: null }
		}

		case 'FINISH_REMOVE_ORGANIZATION_LOGO': {
			return { ...state, organizationLogo: null }
		}

		case 'FINISH_LOADING_PAYMENT_REMINDERS': {
			return {
				...state,
				paymentReminders: action.serverError ? state.paymentReminders : action.paymentReminders,
			}
		}

		case 'FINISH_CREATING_PAYMENT_REMINDER': {
			if (action.paymentReminder) {
				return {
					...state,
					paymentReminders: [...(state.paymentReminders || []), action.paymentReminder],
				}
			}
			return state
		}

		case 'START_UPDATING_PAYMENT_REMINDER':
		case 'FINISH_UPDATING_PAYMENT_REMINDER':
		case 'START_DELETING_PAYMENT_REMINDER':
		case 'FINISH_DELETING_PAYMENT_REMINDER': {
			return {
				...state,
				paymentReminders: action.serverError ? action.oldReminders : action.newReminders,
			}
		}

		case 'FINISH_LOADING_ACCOUNTING_DOCUMENT_CATEGORIES': {
			if (action.serverError) {
				return state
			}
			return {
				...state,
				accountingDocumentCategories: sortAccountingDocumentCategories(action.accountingDocumentCategories),
			}
		}

		case 'START_CREATING_ACCOUNTING_DOCUMENT_CATEGORY':
		case 'FINISH_CREATING_ACCOUNTING_DOCUMENT_CATEGORY':
		case 'START_EDITING_ACCOUNTING_DOCUMENT_CATEGORY':
		case 'FINISH_EDITING_ACCOUNTING_DOCUMENT_CATEGORY':
		case 'START_DELETING_ACCOUNTING_DOCUMENT_CATEGORY':
		case 'FINISH_DELETING_ACCOUNTING_DOCUMENT_CATEGORY': {
			return {
				...state,
				accountingDocumentCategories: action.serverError
					? action.oldAccountingDocumentCategories
					: action.newAccountingDocumentCategories,
			}
		}

		case 'FINISH_LOADING_TAX_PAYMENT_HISTORY': {
			if (action.serverError) {
				return state
			}
			return {
				...state,
				taxPaymentHistory: action.taxPaymentHistory,
			}
		}

		case 'START_PATCH_CONTRACT_STATE': {
			return { ...state, loading: true }
		}

		case 'FINISH_PATCH_CONTRACT_STATE': {
			if (action.serverError) {
				return state
			}
			return {
				...state,
				contractState: action.contractState,
				loading: false,
			}
		}

		case 'START_LOADING_CONTRACT_STATE': {
			return { ...state, loading: true }
		}

		case 'FINISH_LOADING_CONTRACT_STATE': {
			if (action.serverError) {
				return state
			}
			return {
				...state,
				contractState: action.contractState,
				loading: false,
			}
		}

		case 'SET_CASHBOT_ENABLED': {
			const enabled = action.enabled
			return Produce(state, (draft: State) => {
				if (!draft.organizationSettingsOpen) {
					// $FlowFixMe
					draft.organizationSettingsOpen = {}
				}
				if (!draft.organizationSettingsOpen.features) {
					draft.organizationSettingsOpen.features = {}
				}
				if (!draft.organizationSettingsOpen.features.integrations) {
					draft.organizationSettingsOpen.features.integrations = {}
				}
				if (!draft.organizationSettingsOpen.features.integrations.cashbot) {
					draft.organizationSettingsOpen.features.integrations.cashbot = {}
				}
				draft.organizationSettingsOpen.features.integrations.cashbot.enabled = enabled
			})
		}

		case 'FINISH_LOADING_OSS_HISTORY': {
			if (action.serverError) {
				return state
			}
			return {
				...state,
				oss: action.oss ? action.oss.countries || EMPTY_OBJECT : EMPTY_OBJECT,
			}
		}

		default:
			return state
	}
}
