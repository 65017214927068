// @flow

import React, { Component } from 'react'
import { SvgIcon } from 'components'
import styles from './trivi-logo.css'

const style = {
	logo: {
		width: '100%',
		height: 'auto',
	},
	securityIcon: {
		width: 103,
		height: 92,
	},
}

export default class TriviLogo extends Component<any> {
	render() {
		return (
			<SvgIcon style={style.logo} className={styles.logo} viewBox="0 0 151 54">
				<g transform="translate(-895.000000, -180.000000)" fill="#08B0CD">
					<g transform="translate(335.000000, 120.000000)">
						<g transform="translate(560.000000, 60.000000)">
							<path d="M148.959956,20.5295509 L148.597556,20.5295509 L148.597556,19.7563288 L149.099548,19.7563288 C149.370005,19.7563288 149.628383,19.8288813 149.628383,20.1405886 C149.628383,20.5188024 149.296854,20.5295509 148.959956,20.5295509 L148.959956,20.5295509 Z M149.902868,20.1405886 C149.902868,19.7408778 149.680059,19.5232201 149.177396,19.5232201 L148.322401,19.5232201 L148.322401,21.7521958 L148.597556,21.7521958 L148.597556,20.7626595 L149.059281,20.7626595 L149.685428,21.7521958 L150.006219,21.7521958 L149.343161,20.7626595 C149.648516,20.7048862 149.902868,20.5329098 149.902868,20.1405886 L149.902868,20.1405886 Z" />
							<path d="M149.02237,22.2914359 C148.084828,22.2914359 147.395596,21.5605361 147.395596,20.6381782 C147.395596,19.7151485 148.084828,18.9842487 149.02237,18.9842487 C149.949845,18.9842487 150.649143,19.7151485 150.649143,20.6381782 C150.649143,21.5605361 149.949845,22.2914359 149.02237,22.2914359 M149.02237,18.7081459 C147.92913,18.7081459 147.09561,19.5545924 147.09561,20.6381782 C147.09561,21.7210922 147.92913,22.5675386 149.02237,22.5675386 C150.11561,22.5675386 150.94913,21.7210922 150.94913,20.6381782 C150.94913,19.5545924 150.11561,18.7081459 149.02237,18.7081459" />
							<g transform="translate(0.000000, 0.239222)">
								<path d="M26.627944,50.669695 C13.6164418,50.669695 3.06925956,40.1112833 3.06925956,27.0867565 C3.06925956,14.0629016 13.6164418,3.50516161 26.627944,3.50516161 C39.6394462,3.50516161 50.1866284,14.0629016 50.1866284,27.0867565 C50.1866284,40.1112833 39.6394462,50.669695 26.627944,50.669695 M26.627944,0.432426649 C11.944704,0.432426649 0.000268444444,12.3901622 0.000268444444,27.0867565 C0.000268444444,41.7853663 11.944704,53.74243 26.627944,53.74243 C41.311184,53.74243 53.2556196,41.7853663 53.2556196,27.0867565 C53.2556196,12.3901622 41.311184,0.432426649 26.627944,0.432426649" />
							</g>
							<polygon points="19.6055044 21.8854103 25.0408333 21.8854103 25.0408333 35.9444827 28.2151889 35.9444827 28.2151889 21.8854103 33.6505178 21.8854103 33.6505178 18.708549 19.6055044 18.708549" />
							<path d="M96.1776044,24.7348446 C96.0574756,25.0223677 95.8682222,25.2554764 95.6118578,25.4355142 C95.3548222,25.6162238 95.0246356,25.7445343 94.6219689,25.8217893 C94.2193022,25.8990444 93.7361022,25.937336 93.1703556,25.937336 L88.9557778,25.937336 L88.9557778,21.4867743 L93.4918178,21.4867743 C94.4682844,21.4867743 95.1897289,21.6621097 95.6574933,22.0147957 C96.1232444,22.3661381 96.3574622,22.9190155 96.3574622,23.6734276 C96.3574622,24.0939637 96.2970622,24.4473215 96.1776044,24.7348446 M97.7835733,29.0947156 C97.4929822,28.6708206 97.1627956,28.3221653 96.7936844,28.0474061 C97.6681422,27.6523977 98.3560311,27.0840694 98.85668,26.3424211 C99.3573289,25.6007728 99.6089956,24.7106604 99.6089956,23.6734276 C99.6089956,22.9613378 99.4694044,22.2667143 99.1908933,21.5895571 C98.9117111,20.9117282 98.49428,20.3380256 97.9379289,19.866434 C97.6211644,19.5997362 97.2909778,19.3894681 96.94804,19.2356299 C96.6057733,19.0811198 96.2467289,18.9655731 95.8682222,18.8883181 C95.4917289,18.8110631 95.0977867,18.7613511 94.6870667,18.7405259 C94.2756756,18.7197006 93.8421378,18.7082803 93.3891378,18.7082803 L85.7820933,18.7082803 L85.7820933,35.6875929 L88.9557778,35.6875929 L88.9557778,28.8569045 L92.3482444,28.8569045 C92.6985644,28.8569045 93.0307644,28.9133342 93.3441733,29.0241784 C93.65624,29.1363661 93.9414622,29.2902044 94.1984978,29.4877086 C94.4548622,29.6852128 94.6823689,29.9183214 94.8796756,30.1883782 C95.0769822,30.4591067 95.2313378,30.7533476 95.3420711,31.0697573 L96.9607911,35.6875929 L100.379431,35.6875929 L98.55468,30.6586262 C98.3318711,30.0405859 98.0741644,29.5199542 97.7835733,29.0947156" />
							<polygon points="107.564347 35.6878616 110.7132 35.6878616 110.7132 18.708549 107.564347 18.708549" />
							<polygon points="125.106721 31.3783072 120.454579 18.7084818 117.062112 18.7084818 123.655108 35.6877945 126.571757 35.6877945 133.176161 18.7084818 129.796446 18.7084818" />
							<polygon points="139.525342 35.6878616 142.674196 35.6878616 142.674196 18.708549 139.525342 18.708549" />
							<polygon points="65.1036164 21.8858134 70.5396164 21.8858134 70.5396164 35.9448858 73.7133009 35.9448858 73.7133009 21.8858134 79.1486298 21.8858134 79.1486298 18.7089521 65.1036164 18.7089521" />
						</g>
					</g>
				</g>
			</SvgIcon>
		)
	}
}
