/* @flow */
/** @jsx jsx */

import { Component } from 'react'
import { jsx } from '@emotion/core'
import { Column } from 'modules/data-grid-next/components'
import {
	default as SingleLineDataGrid,
	type Props as SingleLineDataGridProps,
} from 'modules/data-grid-next/components/single-line-data-grid'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import type {
	UnmatchedPayment,
	UnmatchedPaymentMatch,
	UnmatchedPaymentMatchesRequestBulk,
	WithRouterProps,
} from 'types'
import { formatUnmatchedPaymentBankAccount } from 'utils/formatters/bank-account-formatter'
import { dateRenderer } from '../../components/renderer/date-renderer'
import { moneyRenderer } from '../../components/renderer/number-renderer'
import { directionRenderer } from '../../components/renderer/accounting-renderer'
import DataGridAttachments from 'components/svg-icons/trivi/data-grid-attachments'
import DataGridComments from 'components/svg-icons/trivi/data-grid-comments'
import Tooltip from 'components/tooltip/tooltip'
import ExpandedRow from './expanded-row'
import {
	isWaitingForAccountant,
	getUnmatchedPaymentAttachmentCount,
	getUnmatchedPaymentCommentCount,
	addAccountingDocumentIds,
} from './unmatched-payment'
import AddCommentDialog from './add-comment-dialog'
import UpdateAccountingDocumentsDialog from './update-accounting-documents-dialog'
import AdvancedFilter from './advanced-filter-container'
import ConfirmDialog from 'components/confirm-dialog'
import Chip from 'components/chip/chip'
import { GRID_ID, DATA_TYPE } from './constants'
import TabSwitches from 'components/TabSwitches/TabSwitches'
import TabSwitch from 'components/TabSwitches/TabSwitch'
import Separator from 'components/Separator'
import { withRouter } from 'react-router-dom'
import Snackbar from 'components/snackbar'

const DataGrid: React$ComponentType<SingleLineDataGridProps<typeof DATA_TYPE>> = SingleLineDataGrid

const tabOptions = ['processing', 'waiting', 'done']

type SelectedTabType = 'processing' | 'waiting' | 'done'

type Props = {|
	...WithTranslateProps,
	...WithRouterProps,
	currentUserIsInternal: boolean,
	updateMatches: (
		unmatchedPayment: UnmatchedPayment,
		unmatchedPaymentMatches: UnmatchedPaymentMatchesRequestBulk,
	) => Promise<boolean>,
	updateUnmatchedPayment: (
		unmatchedPayment: UnmatchedPayment,
		newUnmatchedPayment: { ...UnmatchedPayment & { isPaymentAlreadyMatched?: boolean } },
	) => Promise<boolean>,
	selectedTab: ?SelectedTabType,
	onTabChange: (direction: string, isInternal?: boolean) => void,
	goToProcessingTab: (isInternal?: boolean) => void,
|}

type State = {|
	editingUnmatchedPayment?: UnmatchedPayment,
	editingType?: EditingType,
	selectedTab: SelectedTabType,
	handedOverToAccountant: boolean,
|}

type EditingType = 'comment' | 'accounting-document'

class UnmatchedPaymentsGridComponent extends Component<Props, State> {
	state = {
		editingUnmatchedPayment: undefined,
		editingType: undefined,
		selectedTab: 'processing',
		handedOverToAccountant: false,
	}

	onAddAccountingDocuments = (unmatchedPayment: UnmatchedPayment, ids: Array<string>) => {
		this.props.updateMatches(unmatchedPayment, addAccountingDocumentIds(unmatchedPayment, ids))
		this.onHandOverToAccountant(unmatchedPayment, !!this.props.currentUserIsInternal)
	}

	componentDidMount() {
		this.init()
	}

	init() {
		this.props.onTabChange(this.props.match.params.paymentState, this.props.currentUserIsInternal)
		if (this.props.match.params.paymentState === 'done') {
			return this.setState({ selectedTab: 'done' })
		}
		if (this.props.match.params.paymentState === 'waiting') {
			return this.setState({ selectedTab: 'waiting' })
		}
		if (this.props.match.params.paymentState === 'processing') {
			return this.setState({ selectedTab: 'processing' })
		}
	}

	onTabChange = (value: SelectedTabType) => {
		if (tabOptions.includes(value)) {
			this.props.onTabChange(value, this.props.currentUserIsInternal)
			return this.setState({ selectedTab: value })
		}
	}

	render() {
		const { t } = this.props
		return (
			<div>
				<Separator
					center={
						<TabSwitches
							autoTestId="direction-view-compact-tabs"
							onChange={this.onTabChange}
							value={this.state.selectedTab}
						>
							<TabSwitch
								key="payment-orders"
								value="waiting"
								label={t('dashboard.todo.unmatchedPayments.waitingForAction')}
							/>
							<TabSwitch key="statements" value="processing" label={t('dashboard.todo.unmatchedPayments.inAction')} />
							<TabSwitch key="statements" value="done" label={t('dashboard.todo.unmatchedPayments.done')} />
						</TabSwitches>
					}
				/>
				<div css={styles.filter}>
					<AdvancedFilter />
				</div>
				<DataGrid
					id={GRID_ID}
					filterId={GRID_ID}
					dataType={DATA_TYPE}
					expandedRowRender={this.renderDetails}
					style={styles.header}
					expandRowByClick
					compactPager
					light
				>
					<Column width="56px" columnId="direction" render={this.directionCellRenderer} />
					<Column
						title={t('datagrid.implemented.unmatchedPayments.paymentDate')}
						width="15%"
						columnId="paymentDate"
						render={dateRenderer}
						sortable
					/>
					<Column
						title={t('datagrid.implemented.unmatchedPayments.description')}
						width="40%"
						columnId="description"
						key="description"
						render={this.descriptionRenderer}
					/>
					<Column
						title={t('datagrid.implemented.unmatchedPayments.counterpartBankAccount')}
						width="20%"
						columnId="counterpartBankAccount"
						render={this.bankAccountRenderer}
					/>
					<Column
						title={t('datagrid.implemented.unmatchedPayments.amount')}
						width="20%"
						columnId="amount"
						key="amount"
						render={moneyRenderer}
						headerStyle={styles.amountHeader}
					/>
					<Column
						title={t('datagrid.implemented.unmatchedPayments.activity')}
						width="50px"
						columnId="attachments"
						render={this.countRenderer}
					/>
				</DataGrid>
				<Snackbar
					open={this.state.handedOverToAccountant && !this.props.currentUserIsInternal}
					onRequestClose={this.onSnackBarClose}
					showIcon
					message={this.renderSnackbarMessage()}
					type="success"
				/>
				{this.renderEditing()}
			</div>
		)
	}

	handleSnackbarLink = () => {
		this.props.goToProcessingTab(this.props.currentUserIsInternal)
		this.setState({ selectedTab: 'processing' })
		this.onSnackBarClose()
	}
	renderSnackbarMessage = () => {
		const { t } = this.props
		return (
			<div>
				{t('datagrid.implemented.unmatchedPayments.handedOverToAccountantSnackbar')}{' '}
				<span css={styles.snackbarLink} onClick={this.handleSnackbarLink}>
					{t('datagrid.implemented.unmatchedPayments.handedOverToAccountantSnackbarLink')}
				</span>
			</div>
		)
	}

	directionCellRenderer(value: ?number) {
		return <div css={styles.direction}>{directionRenderer(value)}</div>
	}

	bankAccountRenderer = (value: ?string, unmatchedPayment: UnmatchedPayment) => {
		const formatted = formatUnmatchedPaymentBankAccount(unmatchedPayment)
		return (
			<div>
				{formatted}
				{formatted && <br />}
				{unmatchedPayment.variableSymbol && (
					<span css={styles.variableSymbol}>
						{this.props.t('datagrid.implemented.unmatchedPayments.variableSymbolAbbr')}:{' '}
						{unmatchedPayment.variableSymbol}
					</span>
				)}
			</div>
		)
	}

	descriptionRenderer = (value: ?string, unmatchedPayment: UnmatchedPayment) => {
		const { t, currentUserIsInternal } = this.props

		const waitingForAccountant = isWaitingForAccountant(unmatchedPayment)
		const accountantColor = currentUserIsInternal ? 'orange' : 'green'
		const clientColor = currentUserIsInternal ? 'green' : 'orange'
		const alreadyMatchedColor = 'blue'
		const accountantLabel = currentUserIsInternal
			? t('datagrid.implemented.unmatchedPayments.waitingForAction')
			: t('datagrid.implemented.unmatchedPayments.waitingForAccountant')
		const clientLabel = currentUserIsInternal
			? t('datagrid.implemented.unmatchedPayments.waitingForClient')
			: t('datagrid.implemented.unmatchedPayments.waitingForAction')
		const alreadyMatchedLabel = t('datagrid.implemented.unmatchedPayments.setDone')

		return (
			<div>
				{value}
				<br />
				{!unmatchedPayment.isPaymentAlreadyMatched ? (
					<Chip light color={waitingForAccountant ? accountantColor : clientColor}>
						{waitingForAccountant ? accountantLabel : clientLabel}
					</Chip>
				) : (
					<Chip light color={alreadyMatchedColor}>
						{alreadyMatchedLabel}
					</Chip>
				)}
			</div>
		)
	}

	countRenderer = (value: ?string, unmatchedPayment: UnmatchedPayment) => {
		const attachments = getUnmatchedPaymentAttachmentCount(unmatchedPayment)
		const comments = getUnmatchedPaymentCommentCount(unmatchedPayment)

		return (
			<div css={styles.icons}>
				{!!attachments && (
					<div css={styles.icon}>
						<Tooltip
							label={this.props.t('datagrid.implemented.unmatchedPayments.attachmentCount', { count: attachments })}
							inline
						>
							<div css={styles.iconCount}>
								<DataGridAttachments size={14} disabled />
							</div>
						</Tooltip>
					</div>
				)}
				{!!comments && (
					<div css={styles.icon}>
						<Tooltip
							label={this.props.t('datagrid.implemented.unmatchedPayments.commentCount', { count: comments })}
							inline
						>
							<div css={styles.iconCount}>
								<DataGridComments size={15} disabled />
							</div>
						</Tooltip>
					</div>
				)}
			</div>
		)
	}

	renderDetails = (record: UnmatchedPayment, index: number, indent: number, expanded: boolean) => {
		return expanded ? (
			<ExpandedRow
				onDelete={this.onDelete}
				unmatchedPayment={record}
				onAddComment={this.onEditingOpen(record, 'comment')}
				onSetAsDone={this.onSetAsDoneExpandedRow(record)}
				onAttachAccountingDocument={this.onEditingOpen(record, 'accounting-document')}
				handOverToAccountant={this.onHandOverToAccountant}
				showSetAsDoneButton={this.props.currentUserIsInternal}
				canDeleteAttachments={
					(this.props.currentUserIsInternal || !isWaitingForAccountant(record)) && !record.isPaymentAlreadyMatched
				}
				onAddAccountingDocuments={this.onAddAccountingDocuments}
				canDeleteComments={false}
				isInternal={this.props.currentUserIsInternal}
			/>
		) : null
	}

	editingClose = () => {
		this.setState({ editingType: undefined, editingUnmatchedPayment: undefined })
	}

	onEditingOpen = (editingUnmatchedPayment: UnmatchedPayment, editingType: EditingType) => {
		return () => this.setState({ editingUnmatchedPayment, editingType })
	}
	onHandOverToAccountant = (unmatchedPayment: UnmatchedPayment, toUser?: boolean) => {
		this.props.updateUnmatchedPayment(unmatchedPayment, { ...unmatchedPayment, waitingFor: toUser ? 1 : 2 })
		if (this.state.selectedTab === 'waiting') {
			this.setState({ handedOverToAccountant: true })
		}
	}
	onSnackBarClose = () => {
		this.setState({ handedOverToAccountant: false })
	}

	onAddCommentAndHandOver = async (unmatchedPayment: UnmatchedPayment, message: string) => {
		await this.onAddComment(unmatchedPayment, message)
		this.onHandOverToAccountant(unmatchedPayment)
	}

	onAddComment = async (unmatchedPayment: UnmatchedPayment, message: string) => {
		const waitingFor =
			this.props.currentUserIsInternal && isWaitingForAccountant(unmatchedPayment) ? 1 : unmatchedPayment.waitingFor
		await this.props.updateUnmatchedPayment(unmatchedPayment, { ...unmatchedPayment, waitingFor })
		if (await this.props.updateMatches(unmatchedPayment, { matchesToBeCreated: [{ note: message }] })) {
			this.editingClose()
		}
	}

	onDelete = (unmatchedPayment: UnmatchedPayment, match: UnmatchedPaymentMatch) => {
		this.props.updateMatches(unmatchedPayment, { matchesToBeDeleted: [match.id || ''] })
	}

	onSetAsDoneExpandedRow = (record: UnmatchedPayment) => {
		return () => this.onSetAsDone(record)
	}

	onSetAsDone = (unmatchedPayment: UnmatchedPayment) => {
		ConfirmDialog(this.props.t('datagrid.implemented.unmatchedPayments.setDoneQuestion'), {
			okLabel: this.props.t('datagrid.implemented.unmatchedPayments.setDone'),
		}).then(() => {
			const newUnmatchedPayment = { ...unmatchedPayment, isPaymentAlreadyMatched: true }
			this.props.updateUnmatchedPayment(unmatchedPayment, newUnmatchedPayment)
		})
	}

	onUpdateMatches = async (unmatchedPayment: UnmatchedPayment, matches: UnmatchedPaymentMatchesRequestBulk) => {
		if (await this.props.updateMatches(unmatchedPayment, matches)) {
			this.editingClose()
		}
	}

	renderEditing() {
		if (this.state.editingUnmatchedPayment && 'comment' === this.state.editingType) {
			return (
				<AddCommentDialog
					unmatchedPayment={this.state.editingUnmatchedPayment}
					onAddComment={
						!this.props.currentUserIsInternal && !isWaitingForAccountant(this.state.editingUnmatchedPayment)
							? this.onAddCommentAndHandOver
							: this.onAddComment
					}
					onClose={this.editingClose}
				/>
			)
		}

		if (this.state.editingUnmatchedPayment && 'accounting-document' === this.state.editingType) {
			return (
				<UpdateAccountingDocumentsDialog
					unmatchedPayment={this.state.editingUnmatchedPayment}
					updateMatches={this.onUpdateMatches}
					handOverToAccountant={this.onHandOverToAccountant}
					onClose={this.editingClose}
					internal={this.props.currentUserIsInternal}
				/>
			)
		}

		return null
	}
}

const styles = {
	header: {
		root: {
			th: {
				overflow: 'visible',
			},
		},
	},
	direction: {
		paddingRight: 10,
		div: {
			display: 'flex',
			justifyContent: 'flex-end',
		},
	},
	iconCount: {
		display: 'flex',
		alignItems: 'center',
	},
	filter: {
		padding: 20,
		overflow: 'hidden',
	},
	variableSymbol: {
		fontSize: 11,
	},
	amountHeader: {
		textAlign: 'right',
		paddingRight: 40,
	},
	icons: {
		display: 'flex',
		alignItems: 'center',
	},
	icon: {
		width: 25,
	},
	snackbarLink: {
		textDecoration: 'underline',
		cursor: 'pointer',
	},
}

export default withTranslate(withRouter(UnmatchedPaymentsGridComponent))
