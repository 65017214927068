/* @flow */
/** @jsx jsx */

import { Component } from 'react'
import { jsx } from '@emotion/core'
import { withTranslate } from 'wrappers'
import type { WithNotifyProps, FormValidationProps, WithTranslateProps } from 'wrappers'
import { emptyCashRegister } from 'types/empty'
import { parseFloatOr } from 'utils/math'
import validate from 'wrappers/validate'
import UserPicker from 'modules/user/containers/user-picker'
import type {
	CashRegister,
	CashRegisterAction,
	CashRegisterSettings,
	Currency,
	// FormEetSettings,
	OrganizationCountrySpecificSettingsCzEet,
} from 'types'
import { CurrencySelector, Button, Separator, TextField, NumberInput } from 'components'
import { getCashierName } from '../domain/cash-register'
// import EetSettings from 'components/eet-settings'
import styles from './cash-register-edit.css'
import { MAX_NUMBER_VALUE } from 'trivi-constants'
import FormBox from '../../../components/form-box'
import { getValues } from 'helpers'

type Props = {|
	...WithNotifyProps,
	...FormValidationProps,
	...WithTranslateProps,
	id?: string,
	cashRegister: CashRegister,
	disabledCurrency?: boolean,
	currentUserIsInternal: boolean,
	canEditProfile: boolean,
	editCashRegisters: boolean,
	isCreatingNewCashRegister?: boolean,
	onCreate: (data: CashRegister) => ?Promise<CashRegisterAction>,
	onUpdateSettings: (data: CashRegisterSettings, oldCashRegister?: CashRegister) => ?Promise<CashRegisterAction>,
	// onUpdateEet: (data: OrganizationCountrySpecificSettingsCzEet) => ?Promise<CashRegisterAction>,
|}

export type State = {|
	name: string,
	currency: string,
	initialAmount: number,
	ownerUserId: ?number,
	countryVariantSpecific?: {|
		cz?: {|
			eet?: OrganizationCountrySpecificSettingsCzEet,
		|},
	|},
|}

class CashRegisterEdit extends Component<Props, State> {
	state: State = {
		name: '',
		currency: 'CZK',
		initialAmount: 0,
		ownerUserId: null,
	}

	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		const cashRegister: CashRegister = nextProps.cashRegister || emptyCashRegister()
		this.setState({ //eslint-disable-line
			name: cashRegister.name || this.state.name,
			currency: cashRegister.currency || this.state.currency,
			initialAmount: cashRegister.initialAmount || this.state.initialAmount,
			ownerUserId: cashRegister.ownerUserId || this.state.ownerUserId,
		})
	}

	handleNameChange = (event: SyntheticInputEvent<HTMLInputElement>, name: ?string) => {
		this.setState({ name: name || '' })
	}

	handleCurrencyChange = (value: ?Currency) => {
		value && this.setState({ currency: value.id })
	}

	handleInitialAmountChange = (event: SyntheticInputEvent<HTMLInputElement>, value: ?number) => {
		this.setState({ initialAmount: parseFloatOr(value) })
	}

	handleOwnerUserChange = (value: ?string) => {
		this.setState({ ownerUserId: value ? parseInt(value, 10) : null })
	}

	handleSaveClick = async () => {
		const { cashRegister } = this.props
		const { name, currency, ownerUserId, initialAmount, countryVariantSpecific } = this.state
		const newCashRegister: CashRegister = Object.freeze({
			...(cashRegister || {}),
			...(name ? { name } : {}),
			...(currency ? { currency } : {}),
			...(ownerUserId ? { ownerUserId } : {}),
			...(countryVariantSpecific ? { countryVariantSpecific } : {}),
			initialAmount,
		})

		if (this.props.isCreatingNewCashRegister) {
			this.props.onCreate && (await this.props.onCreate(newCashRegister))
		} else {
			this.props.onUpdateSettings &&
				(await this.props.onUpdateSettings(
					{
						name,
						initialAmount,
						...(ownerUserId ? { ownerUserId } : {}),
					},
					this.props.cashRegister,
				))
		}
	}

	// saveEet = async (input: FormEetSettings) => {
	// 	const { cashRegister } = this.props
	// 	const eet = {
	// 		...(input.certificate ? { certificate: input.certificate } : {}),
	// 		...(input.password ? { password: input.password } : {}),
	// 		place: input && input.place ? input.place : DEFAULT_EET_SETTINGS_PLACE,
	// 	}
	//
	// 	if (this.props.isCreatingNewCashRegister) {
	// 		this.setState({
	// 			countryVariantSpecific: {
	// 				...((cashRegister && cashRegister.countryVariantSpecific) || {}),
	// 				cz: {
	// 					...((cashRegister && cashRegister.countryVariantSpecific && cashRegister.countryVariantSpecific.cz) || {}),
	// 					eet,
	// 				},
	// 			},
	// 		})
	// 	} else {
	// 		this.props.onUpdateEet && (await this.props.onUpdateEet(eet))
	// 	}
	// }

	getClientValidationErrors() {
		const errors = {}
		const { t } = this.props

		if (!this.state.name) {
			errors.name = t('application.validation.mandatory')
		}

		if (!this.state.currency) {
			errors.currency = t('application.validation.mandatory')
		}

		return errors
	}

	isNewCashRegister = () => {
		return !this.props.cashRegister || !this.props.cashRegister.id
	}

	renderHeader = () => {
		const { t, currentUserIsInternal, cashRegister } = this.props
		const ownerUserId = this.state.ownerUserId ? this.state.ownerUserId.toString() : undefined

		return (
			<div>
				<div className={styles.name}>
					<TextField
						style={{ fontSize: '24px' }}
						inline
						autoFocus
						autoWidth
						value={this.props.validationValue('name', this.state.name)}
						onChange={this.handleNameChange}
						hintText={t('cashRegister.edit.nameHint')}
						clientError={this.props.validationMessage('name')}
						autoTestId="cash-register-edit-name"
						maxLength={50}
						name="name"
					/>
				</div>
				<div className={styles.row}>
					<div className={styles.currency}>
						<CurrencySelector
							labelText={t('cashRegister.edit.currency')}
							autoTestId="cash-register-edit-currency"
							fullWidth
							value={this.props.validationValue('currency', this.state.currency)}
							onChange={this.handleCurrencyChange}
							disabled={!currentUserIsInternal || this.props.disabledCurrency}
						/>
					</div>
					<div className={styles.user}>
						{currentUserIsInternal ? (
							<UserPicker
								labelText={t('cashRegister.cashier')}
								onChange={this.handleOwnerUserChange}
								show={'ORGANIZATION'}
								showMe
								value={ownerUserId}
								disabled={!currentUserIsInternal}
								autoTestId="cash-register-edit-cashier"
								placeholder={t('cashRegister.selectCashier')}
							/>
						) : (
							<TextField
								fullWidth
								value={getCashierName(cashRegister)}
								labelText={t('cashRegister.cashier')}
								hintText={t('cashRegister.edit.selectCashier')}
								autoTestId="cash-register-edit-cashier"
								disabled={!currentUserIsInternal}
							/>
						)}
					</div>
				</div>
			</div>
		)
	}

	renderActions = () => {
		const { t, currentUserIsInternal, canEditProfile, editCashRegisters } = this.props
		const isNew = this.isNewCashRegister()

		const errors = this.getClientValidationErrors()
		const hasErrors = getValues(errors).length > 0

		return currentUserIsInternal || (canEditProfile && editCashRegisters) ? (
			<Button
				autoTestId="cash-register-edit-create-save"
				labelText={isNew ? t('application.create') : t('application.save')}
				onClick={this.props.validationSubmit(this.handleSaveClick)}
				disabled={hasErrors}
			/>
		) : null
	}

	render() {
		const { t, currentUserIsInternal /* , canEditProfile, editCashRegisters */ } = this.props
		const isNew = this.isNewCashRegister()
		this.props.validateForm(this.getClientValidationErrors())

		return (
			<FormBox
				actions={this.renderActions()}
				headline={
					!currentUserIsInternal
						? t('cashRegister.edit.headerReadOnly')
						: isNew
						? t('cashRegister.create.header')
						: t('cashRegister.edit.header')
				}
				header={this.renderHeader()}
			>
				<div>
					<Separator left={<span className={styles.separatorText}>{t('cashRegister.edit.initialAmount')}</span>} />
					<div className={styles.rowInitial}>
						<div className={styles.amount}>
							<NumberInput
								value={this.state.initialAmount}
								labelText={t('cashRegister.edit.amount')}
								fullWidth
								onChange={this.handleInitialAmountChange}
								maxValue={MAX_NUMBER_VALUE}
								disabled={!currentUserIsInternal}
								name="amount"
							/>
						</div>
					</div>
					{/*<Separator left={<span className={styles.separatorText}>{t('settings.eet.title')}</span>} />*/}
					{/*<div className={styles.rowInitial}>*/}
					{/*	<EetSettings*/}
					{/*		eet={*/}
					{/*			this.props.cashRegister &&*/}
					{/*			this.props.cashRegister.countryVariantSpecific &&*/}
					{/*			this.props.cashRegister.countryVariantSpecific.cz &&*/}
					{/*			this.props.cashRegister.countryVariantSpecific.cz.eet*/}
					{/*		}*/}
					{/*		onChange={this.saveEet}*/}
					{/*		disabled={!(currentUserIsInternal || (canEditProfile && editCashRegisters))}*/}
					{/*	/>*/}
					{/*</div>*/}
				</div>
			</FormBox>
		)
	}
}

const style = {
	name: {
		fontSize: 24,
	},
}

export default withTranslate(validate(['FINISH_CREATE_CASH_REGISTER', 'FINISH_UPDATE_CASH_REGISTER'])(CashRegisterEdit))
