/* @flow */

import React, { Component, type Node } from 'react'
import memoize from 'memoize-one'
import { type AutoTestProps } from 'utils/tests/autotest'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import type { Enum, EnumItem } from 'types'
import SelectField from 'components/select-field'
import MenuItem from 'components/menu-item'
import MenuCategory from 'components/menu-category'

type Props = {|
	...AutoTestProps,
	compact?: boolean,
	name?: string,
	labelText?: ?string,
	hintText?: ?string,
	values: ?Enum,
	value: ?string | ?Array<string>,
	showCategoryLabels?: boolean,
	maxDeep?: number,
	multiple?: boolean,
	disabled?: boolean,
	filterable?: boolean,
	nullable?: boolean,
	inline?: boolean,
	fullWidth: boolean,
	clientError?: Node,
	onChange: (value: ?string | Array<string>) => void,
	renderItem?: (el: EnumItem) => React$Element<typeof MenuItem>,
	renderCategoryTitle?: (el: EnumItem) => React$Element<any>,
	...WithTranslateProps,
|}

type State = {|
	filter: ?string,
|}

class TreeEnumSelector extends Component<Props, State> {
	state: State = {
		filter: null,
	}

	onChange = (event: SyntheticEvent<HTMLElement>, index: ?number, value: ?string | Array<string>) => {
		this.props.onChange(value)
	}

	getItemDom = (item: EnumItem) => {
		const { values } = this.props
		const key: ?string = item.key ? item.key.toString() : null

		const option = this.props.renderItem ? (
			this.props.renderItem(item)
		) : (
			<MenuItem
				key={(item.value || '') + (item.key || '')}
				value={key || ''}
				primaryText={item.value}
				checked={!!values && values.indexOf(item.value) > -1}
			/>
		)

		return option
	}

	getTitleDom = (item: EnumItem): any => {
		const title: any = this.props.renderCategoryTitle ? (
			this.props.renderCategoryTitle(item)
		) : (
			<MenuCategory key={'heading' + (item.key || '')}>{item.value}</MenuCategory>
		)

		return title
	}

	//renders tree structured options
	renderOptions = memoize((items: ?Enum, currentDeep?: number = 1): Array<any> => {
		let result: Array<React$Element<typeof MenuItem>> = []
		const { maxDeep, filterable } = this.props
		const { filter } = this.state
		if (maxDeep && maxDeep < currentDeep) {
			return result
		}

		let options: Enum = items || []
		if (filterable && filter) {
			options = options.filter(
				(item: EnumItem) =>
					item.value &&
					item.value
						.replace(/\s/g, '')
						.toLowerCase()
						.normalize('NFD')
						.replace(/[\u0300-\u036f]/g, '')
						.indexOf(
							filter
								.replace(/\s/g, '')
								.toLowerCase()
								.normalize('NFD')
								.replace(/[\u0300-\u036f]/g, ''),
						) > -1,
			)
		}

		options.forEach((item: EnumItem) => {
			if ((maxDeep == null && !item.children) || (maxDeep != null && maxDeep === currentDeep)) {
				result.push(this.getItemDom(item))
			} else if (item.children && item.children.length > 0) {
				const children: Array<EnumItem> = item.children

				this.props.showCategoryLabels && result.push(this.getTitleDom(item))

				result = [...result, ...this.renderOptions(children, currentDeep + 1)]
			}
		})
		return result
	})

	onFilterChange = (filter: ?string) => {
		this.setState({
			filter: filter
				? filter
						.replace(/\s/g, '')
						.toLowerCase()
						.normalize('NFD')
						.replace(/[\u0300-\u036f]/g, '')
				: null,
		})
	}

	render() {
		return (
			<SelectField
				fullWidth={this.props.fullWidth}
				inline={this.props.inline}
				onChange={this.onChange}
				clientError={this.props.clientError}
				labelText={this.props.labelText}
				hintText={this.props.hintText}
				nullable={this.props.nullable && !this.props.multiple}
				multiple={this.props.multiple}
				disabled={this.props.disabled}
				compact={this.props.compact}
				name={this.props.name}
				filterable={this.props.filterable}
				onFilterChange={this.onFilterChange}
				value={this.props.value}
				autoTestId={this.props.autoTestId}
			>
				{this.renderOptions(this.props.values)}
			</SelectField>
		)
	}
}

export default withTranslate(TreeEnumSelector)
