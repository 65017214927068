/* @flow */

import type { Dispatch, OrganizationAction, OrganizationCreateRequest, State } from 'types'
import { changeCurrentOrganization, loadAresContacts, loadOrganizations } from 'modules/user/actions'

import CreateOrganizationPage from 'modules/organization/pages/organization-create'
import type { FinishCreateOrganization } from 'modules/organization/actions/action-types'
import type { FinishLoadingAresContacts } from 'modules/user/actions/action-types'
import { connect } from 'react-redux'
import { createOrganization } from 'modules/organization/actions'
import { push } from 'react-router-redux'
import { dashboardRoute } from 'modules/dashboard/routing/routes'

type StateProps = {|
	currentOrganizationId: ?string,
|}

const mapStateToProps = (state: State): StateProps => {
	return {
		currentOrganizationId: state.user.currentOrganizationId,
	}
}

type DispatchProps = {|
	createOrganization: (data: OrganizationCreateRequest) => Promise<any>,
	loadAresContacts: (companyRegNo: string) => Promise<FinishLoadingAresContacts>,
|}

const mapDispatchToProps = (dispatch: Dispatch<OrganizationAction>): DispatchProps => {
	return {
		createOrganization: async (data: OrganizationCreateRequest) => {
			const res: FinishCreateOrganization = await dispatch(createOrganization(data))
			const orgId: ?string = res.organization && res.organization.id
			if (orgId) {
				await dispatch(loadOrganizations()) // to load createdOrganization as OrganizationMember
				dispatch(changeCurrentOrganization(orgId))
				dispatch(push(dashboardRoute(orgId)))
			}
		},
		loadAresContacts: async (companyRegNo: string) => {
			return dispatch(loadAresContacts(companyRegNo))
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateOrganizationPage)
