// @flow

import type { Contact, ContactAddress } from 'types'
import type { Action } from '../actions/action-types'

export type ContactItem = {|
	contact: ?Contact,
	contactLoading: boolean,
|}

type ContactItems = { [contactId: string]: ContactItem }

export type State = {
	contacts: {
		loading: boolean,
		data?: Array<Contact>,
	},
	contactItems: ContactItems,
	saved: boolean,
}

export const initialState: State = {
	contacts: {
		loading: false,
		data: [],
	},
	contactItems: {},
	saved: true,
}

export default (state: State = initialState, action: Action): State => {
	switch (action.type) {
		case 'START_LOAD_CONTACTS': {
			return {
				...state,
				contacts: {
					loading: true,
					data: (state.contacts.data && [...state.contacts.data]) || [],
				},
			}
		}
		case 'FINISH_LOAD_CONTACTS': {
			return setContactItems(state, action.contacts, false)
		}

		case 'START_LOAD_CONTACT':
		case 'START_SAVE_CONTACT': {
			return setContactItem(state, action.contact || null, true)
		}

		case 'FINISH_CREATE_CONTACT':
		case 'FINISH_SAVE_CONTACT': {
			return setContactItem(state, action.contact || null, false)
		}
		case 'FINISH_LOAD_CONTACT': {
			if (action.serverError) {
				return state
			}
			return setContactItem(state, action.contact, false)
		}

		case 'SET_CONTACT_SAVED': {
			return setSaved(state, action.saved)
		}

		default:
			return state
	}
}

function setContactItems(state: State, contacts?: Array<Contact>, loading: boolean): State {
	return {
		...state,
		contacts: {
			loading,
			data: contacts ? contacts.map(getCleanContact) : contacts,
		},
	}
}

function setSaved(state: State, saved: boolean): State {
	return {
		...state,
		saved,
	}
}

function setContactItem(state: State, contact: ?Contact, contactLoading: boolean): State {
	const contactId: string = (contact && contact.id) || 'new'
	return {
		...state,
		contactItems: {
			...state.contactItems,
			[contactId]: {
				contact: contact ? getCleanContact(contact) : contact,
				contactLoading,
			},
		},
	}
}

function getCleanContact(contact: Contact) {
	if (!contact) return contact

	return {
		...contact,
		email: contact.email ? contact.email.trim() : contact.email,
		addresses: contact.addresses ? contact.addresses.map(getCleanAddress) : contact.addresses,
	}
}

function getCleanAddress(address: ContactAddress) {
	return {
		...address,
		email: address && address.email ? address.email.trim() : address.email,
	}
}
