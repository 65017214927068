// @flow
import { connect } from 'react-redux'
import type { State, SortedAccountingDocuments, SortedStatements } from 'types'
import OrganizationItem from 'modules/internal/components/billing/organization-item'
import { sortedDocumentsSelector, sortedStatementsSelector, totalPriceForOrganizationSelector } from '../../selectors'

type OwnProps = {|
	name: string,
	index: number,
|}

type StateProps = {|
	sortedDocuments: SortedAccountingDocuments,
	sortedStatements: SortedStatements,
	totalPrice: number,
|}

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => {
	return {
		sortedDocuments: sortedDocumentsSelector(ownProps.index)(state),
		sortedStatements: sortedStatementsSelector(ownProps.index)(state),
		totalPrice: totalPriceForOrganizationSelector(ownProps.index)(state),
	}
}

export default connect(mapStateToProps)(OrganizationItem)
