/* @flow */

import { connect } from 'react-redux'
import type { AccountingDocument, Dispatch, State, FormFieldErrorContext, FormFieldErrorMessage } from 'types'
import { emptyAccountingDocument } from 'types/empty'
import {
	loadAccountingDocumentVatRecapInfo,
	resetExchangeRate,
	updateAccountingDocumentExchRate,
} from 'modules/accounting-document/actions'
import {
	getAccountingDocumentCurrency,
	getAccountingDocumentExchRate,
	getAccountingDocumentExchRateDefault,
} from 'modules/accounting-document/selectors'
import ExchangeRateInput from '../../components/invoice-elements/exchange-rate-input'
import { getFormFieldErrors } from 'modules/common/selectors'
import { removeError as removeErrorFn } from 'helpers'
import { loadAccountingDocumentHeader } from '../../actions/accounting-document'
import { getAccountingDocumentTaxDate } from '../../selectors'

type OwnProps = {|
	exchRate?: ?number,
	vatExchRate?: ?number,
	sadExchRate?: ?number,
	exchRateDefault?: ?number,
	isInHeader?: boolean,
	isTemplate: boolean,
	template: ?AccountingDocument,
	accountingDocumentId: string,
	fullWidth?: boolean,
	disabled?: boolean,
	compact?: boolean,
	name?: string,
	formFieldErrorContext?: FormFieldErrorContext,
	onTemplateChange?: (accountingDocument: AccountingDocument) => void,
	changeExchRateValue?: (exchRate: ?number) => void,
|}

type StateProps = {|
	value: ?number,
	taxDate?: ?Date,
	defaultValue: ?number,
	errors: ?Array<FormFieldErrorMessage>,
	isInHeader?: boolean,
	exchRate?: ?number,
	vatExchRate?: ?number,
	sadExchRate?: ?number,
	exchRateDefault?: ?number,
	currency?: ?string,
|}

function mapStateToProps(state: State, ownProps: OwnProps): StateProps {
	const template: AccountingDocument = ownProps.template || emptyAccountingDocument()
	const errors = ownProps.formFieldErrorContext && getFormFieldErrors(state, ownProps.formFieldErrorContext)

	return ownProps.isTemplate
		? {
				value: template.exchRate || null,
				defaultValue: template.exchRateDefault || null,
				taxDate: template.taxDate ? new Date(template.taxDate) : null,
				errors,
				isInHeader: ownProps.isInHeader,
				exchRate: ownProps.exchRate,
				vatExchRate: ownProps.vatExchRate,
				sadExchRate: ownProps.sadExchRate,
				exchRateDefault: ownProps.exchRateDefault,
				currency: getAccountingDocumentCurrency(state, ownProps.accountingDocumentId),
		  }
		: {
				value:
					getAccountingDocumentExchRate(state, ownProps.accountingDocumentId) ??
					getAccountingDocumentExchRateDefault(state, ownProps.accountingDocumentId),
				defaultValue: getAccountingDocumentExchRateDefault(state, ownProps.accountingDocumentId),
				taxDate: getAccountingDocumentTaxDate(state, ownProps.accountingDocumentId),
				errors,
				isInHeader: ownProps.isInHeader,
				exchRate: ownProps.exchRate,
				vatExchRate: ownProps.vatExchRate,
				sadExchRate: ownProps.sadExchRate,
				exchRateDefault: ownProps.exchRateDefault,
				currency: getAccountingDocumentCurrency(state, ownProps.accountingDocumentId),
		  }
}

type DispatchProps = {|
	onChange: (exchRate: ?number) => void,
	onRefresh: () => Promise<any>,
	removeError?: () => void,
|}

function mapDispatchToProps(dispatch: Dispatch<*>, ownProps: OwnProps): DispatchProps {
	async function onRefresh() {
		await dispatch(loadAccountingDocumentHeader(ownProps.accountingDocumentId))
		return await dispatch(resetExchangeRate(ownProps.accountingDocumentId))
	}

	function removeError() {
		removeErrorFn({
			formFieldErrorContext: ownProps.formFieldErrorContext,
			dispatch,
		})
	}

	return ownProps.isTemplate
		? {
				removeError,
				onRefresh,
				onChange: (exchRate: ?number) => {
					const newDocument: AccountingDocument = Object.assign(emptyAccountingDocument(), ownProps.template, {
						exchRate: exchRate || undefined,
					})
					ownProps.onTemplateChange && ownProps.onTemplateChange(newDocument)
				},
		  }
		: {
				removeError,
				onRefresh,
				onChange: (exchRate: ?number) => {
					dispatch(updateAccountingDocumentExchRate(ownProps.accountingDocumentId, exchRate)).then(() => {
						dispatch(loadAccountingDocumentVatRecapInfo(ownProps.accountingDocumentId))
					})
				},
		  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ExchangeRateInput)
