/* @flow */
/** @jsx jsx */

import type { Contact, EmailTemplate, PaymentReminder, WithRouterProps } from 'types'
import { type FormValidationProps, type WithTranslateProps, validate, withTranslate } from 'wrappers'

import Button from 'components/button'
import { Component } from 'react'
import ContactEmailsSelector from '../../containers/invoice-settings/contact-emails-selector'
import EmailTemplateSelector from 'modules/common/components/selectors/email-template-selector'
import InfoIcon from 'components/svg-icons/action/info'
import NumberInput from 'components/number-input'
import SettingsHeading from './settings-heading'
import Snackbar from 'components/snackbar'
import Toggle from 'components/toggle'
import TriviLink from 'modules/common/components/trivi-link'
import { addressBookRoute } from 'modules/address-book/routing/routes'
import { colors } from 'variables'
import { jsx } from '@emotion/core'
import { parseIntOr } from 'utils/math'
import { withRouter } from 'react-router-dom'

const ALLOWED_TYPES = [1]

type Props = {|
	...WithRouterProps,
	...WithTranslateProps,
	...FormValidationProps,
	accountingDocumentId: ?string,
	readonly: boolean,
	paymentReminders: ?Array<PaymentReminder>,
	emailTemplates: ?Array<EmailTemplate>,
	contact: ?Contact,
	contactId: ?string,
	loadContact: (id: string) => Promise<*>,
	loadAccountingDocumentPaymentReminders: (id: string) => void,
	createAccountingDocumentPaymentReminder: (accountingDodumentId: string, paymentReminder: PaymentReminder) => void,
	updateAccountingDocumentPaymentReminder: (
		accountingDodumentId: string,
		reminderId: string,
		paymentReminder: PaymentReminder,
	) => void,
	deleteAccountingDocumentPaymentReminder: (accountingDodumentId: string, reminderId: string) => void,
	onBackwardClick: () => void,
|}

type State = {|
	open: boolean,
	emailTemplateId: ?string,
	exceededDays: ?number,
	emails: Array<string>,
|}

class RemindersSettings extends Component<Props, State> {
	state: State = {
		open: this.props.paymentReminders != null && this.props.paymentReminders.length > 0,
		emailTemplateId: null,
		exceededDays: null,
		emails: [],
	}

	componentWillMount() {
		if (!this.props.paymentReminders) {
			this.props.loadAccountingDocumentPaymentReminders(this.props.accountingDocumentId)
		}

		if (!this.props.contact && this.props.contactId) {
			this.props.loadContact(this.props.contactId)
		}

		if (this.props.paymentReminders && this.props.paymentReminders.length > 0) {
			this.setState(this.getStateFromProps(this.props))
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		if (
			nextProps.paymentReminders &&
			nextProps.paymentReminders.length > 0 &&
			nextProps.paymentReminders !== this.props.paymentReminders
		) {
			this.setState(this.getStateFromProps(nextProps))
		}

		if (nextProps.contactId !== this.props.contactId) {
			nextProps.contactId && this.props.loadContact(nextProps.contactId)
			this.setState({ emails: [] })
		}
	}

	getStateFromProps = (props: Props) => {
		const reminder: PaymentReminder = props.paymentReminders[0]
		return {
			open: true,
			emailTemplateId: reminder.emailTemplateId != null ? reminder.emailTemplateId : null,
			emails: reminder.recipientEmails || [],
			exceededDays: reminder.exceededDays,
		}
	}

	onToggle = () => {
		this.setState({ open: !this.state.open })
	}

	onEmailTemplateChange = (emailTemplateId: string) => {
		this.setState({ emailTemplateId })
	}

	onEmailsChange = (emails: Array<string>) => {
		this.setState({ emails })
	}

	onExceededDaysChange = (event: SyntheticInputEvent<HTMLInputElement>, value: ?number) => {
		this.setState({ exceededDays: parseIntOr(value, null) })
	}

	save = () => {
		const { paymentReminders, accountingDocumentId } = this.props
		const { emails, open } = this.state

		if (!open) {
			paymentReminders &&
				paymentReminders.length > 0 &&
				this.props.deleteAccountingDocumentPaymentReminder(
					this.props.accountingDocumentId,
					paymentReminders[0].id || '',
				)
			return this.props.onBackwardClick()
		}

		const paymentReminder: PaymentReminder = {
			exceededDays: this.state.exceededDays || 0,
			emailTemplateId: this.state.emailTemplateId || '',
			recipientEmails: emails,
		}

		if (!paymentReminders || paymentReminders.length <= 0) {
			//create new one
			this.props.createAccountingDocumentPaymentReminder(accountingDocumentId, paymentReminder)
		} else {
			//update current one
			paymentReminder.id = paymentReminders[0].id || ''
			this.props.updateAccountingDocumentPaymentReminder(
				this.props.accountingDocumentId,
				paymentReminder.id,
				paymentReminder,
			)
		}

		this.props.onBackwardClick()
	}

	onAddContactPerson = () => {
		const { contact, history } = this.props
		contact && history.push(addressBookRoute(contact.id || ''))
	}

	renderNoContact() {
		const { t, contact } = this.props

		const message = (
			<div>
				{t('invoice.settings.reminders.noContactPerson', {
					name: contact && contact.companyName,
				})}
				<div css={styles.snackbarLink}>
					<TriviLink
						style={styles.link}
						hoverStyle={styles.hoveredLink}
						routeFn={addressBookRoute}
						routeParams={[contact.id || '']}
					>
						{t('contactEdit.edit.addContactPerson')}
					</TriviLink>
				</div>
			</div>
		)

		return (
			<div css={styles.warning}>
				<Snackbar open inline showIcon message={message} type={'error'} />
			</div>
		)
	}

	renderContent() {
		const { t, emailTemplates, contact } = this.props

		const emailTemplate: ?EmailTemplate =
			emailTemplates && emailTemplates.find((t: EmailTemplate) => t.id === this.state.emailTemplateId)

		const emailTemplateSelector = (
			<EmailTemplateSelector
				label={t('invoice.settings.reminders.reminderTemplate')}
				value={this.props.validationValue('emailTemplateId', this.state.emailTemplateId)}
				clientError={this.props.validationMessage('emailTemplateId')}
				onChange={this.onEmailTemplateChange}
				disabled={this.props.readonly}
				allowedTypes={ALLOWED_TYPES}
				allowCreateTemplate
				autoSelect
			/>
		)

		return (
			<div>
				<div css={styles.field}>{emailTemplateSelector}</div>
				{emailTemplate && (
					<div css={styles.info}>
						<InfoIcon style={styles.infoIcon} size={18} color={colors.blue} disabled />
						{emailTemplate.body}
					</div>
				)}
				<ContactEmailsSelector
					readonly={this.props.readonly}
					contactId={contact && contact.id}
					onEmailsChange={this.onEmailsChange}
					emails={this.props.validationValue('emails', this.state.emails)}
					clientError={this.props.validationMessage('emails')}
				/>
				<div css={styles.field}>
					<NumberInput
						fullWidth
						labelText={t('invoice.settings.reminders.exceededDaysLabel')}
						value={this.props.validationValue('exceededDays', this.state.exceededDays)}
						clientError={this.props.validationMessage('exceededDays')}
						onChange={this.onExceededDaysChange}
						disabled={this.props.readonly}
						name="exceeded-days"
					/>
				</div>
			</div>
		)
	}

	getClientValidationErrors() {
		const errors = {}

		const { t } = this.props
		const { emailTemplateId, emails, exceededDays } = this.state

		if (!emails || emails.length < 1) errors.emails = t('application.validation.mandatory')
		if (!emailTemplateId) errors.emailTemplateId = t('application.validation.mandatory')
		if (exceededDays === null || exceededDays === undefined) errors.exceededDays = t('application.validation.mandatory')

		return errors
	}

	render() {
		const { t } = this.props
		this.props.validateForm(this.getClientValidationErrors())

		return (
			<div css={styles.root}>
				<SettingsHeading text={t('invoice.settings.reminders.headline')} onGoBack={this.props.onBackwardClick} />
				<div css={styles.toggle}>
					<Toggle
						label={t('invoice.settings.reminders.label')}
						labelInside={t('invoice.settings.reminders.off')}
						labelInsideChecked={t('invoice.settings.reminders.on')}
						toggled={this.state.open}
						disabled={this.props.readonly}
						onToggle={this.onToggle}
						autoTestId="inovice-settings-reminders-toggle"
					/>
				</div>
				{this.state.open && this.renderContent()}
				<div css={styles.actions}>
					<Button
						autoTestId="inovice-settings-reminders-save"
						tertiary
						labelText={t('invoice.settings.reminders.save')}
						onClick={this.props.validationSubmit(this.save)}
					/>
					<Button
						autoTestId="inovice-settings-reminders-back"
						transparent
						labelText={t('invoice.settings.reminders.back')}
						onClick={this.props.onBackwardClick}
					/>
				</div>
			</div>
		)
	}
}

const styles = {
	root: {
		paddingRight: 10,
	},
	toggle: {
		marginBottom: 20,
		marginTop: 20,
		fontSize: 16,
	},
	info: {
		marginTop: -10,
		marginBottom: 30,
		color: colors.blackFaded60,
		fontSize: 14,
		fontStyle: 'italic',
		lineHeight: '20px',
		paddingLeft: 25,
		position: 'relative',
		paddingTop: 5,
		whiteSpace: 'pre-wrap',
	},
	infoIcon: {
		position: 'absolute',
		top: 5,
		left: 0,
	},
	field: {
		marginBottom: 15,
	},
	actions: {
		paddingTop: 10,
	},
	warning: {
		marginTop: 25,
		marginBottom: 30,
	},
	snackbarLink: {
		display: 'block',
		marginTop: 10,
	},
	link: {
		textDecoration: 'underline',
	},
	hoveredLink: {
		color: colors.white,
		textDecoration: 'none',
	},
}

export default withRouter(
	withTranslate(
		validate([
			'FINISH_CREATING_ACCOUNTING_DOCUMENT_PAYMENT_REMINDERS',
			'FINISH_UPDATING_ACCOUNTING_DOCUMENT_PAYMENT_REMINER',
		])(RemindersSettings),
	),
)
