/* @flow */

import React from 'react'
import { Route } from 'react-router'
import { UserIsNotAuthenticated } from 'lib/auth'
import ChangePasswordForm from 'modules/user/containers/change-password-form'
import Login from 'modules/user/containers/login-page'
import Logout from 'modules/user/containers/logout'
import InvitationPage from 'modules/user/containers/invitation-page'
import Register from 'modules/user/containers/register-page'
import InvitationRegisterPage from 'modules/user/containers/invitation-register-page'
import ResetPasswordForm from 'modules/user/containers/reset-password-form'
import UserSettingsPage from 'modules/user/containers/user-settings'
import basicLayout from 'layouts/basic-layout'
import mainLayout from 'layouts/main-layout'

const UserSettings = mainLayout()(UserSettingsPage)
const LoginComponent = UserIsNotAuthenticated(basicLayout()(Login))
const RegisterComponent = basicLayout()(Register)
const InvitationComponent = basicLayout()(InvitationPage)
const InvitationRegisterComponent = basicLayout()(InvitationRegisterPage)
const ResetPasswordComponent = basicLayout()(ResetPasswordForm)
const ChangePasswordComponent = basicLayout()(ChangePasswordForm)
const ProfileSettingsComponent = () => <UserSettings section="profile" /> // eslint-disable-line
const PasswordSettingsComponent = () => <UserSettings section="password" /> // eslint-disable-line
const MainSettingsComponent = () => <UserSettings section="settings" /> // eslint-disable-line
const EmailSettingsComponent = () => <UserSettings section="emails" /> // eslint-disable-line
const DataBoxSettingsComponent = () => <UserSettings section="dataBox" /> // eslint-disable-line
const NotificationsSettingsComponent = () => <UserSettings section="notifications" /> // eslint-disable-line

const UserRouting = (canSeeNonDemoFeatures: boolean, canSeeRegistration: boolean) => {
	return [
		<Route exact key="login" path="/login" component={LoginComponent} />,
		canSeeRegistration && <Route exact key="register" path="/register" component={RegisterComponent} />,
		<Route exact key="logout" path="/logout" component={Logout} />,
		<Route exact key="invitation" path="/invitation/:token" component={InvitationComponent} />,
		<Route exact key="invitation" path="/invitation" component={InvitationRegisterComponent} />,
		<Route exact key="resetPassword" path="/account/resetPassword" component={ResetPasswordComponent} />,
		<Route exact key="changePassword" path="/account/changePassword/:token" component={ChangePasswordComponent} />,
		<Route exact key="userSettings" path="/account/settings/profile" render={ProfileSettingsComponent} />,
		canSeeNonDemoFeatures && (
			<Route exact key="userSettings" path="/account/settings/password" render={PasswordSettingsComponent} />
		),
		<Route exact key="userSettings" path="/account/settings/main" render={MainSettingsComponent} />,
		<Route exact key="userSettings" path="/account/settings/emails" render={EmailSettingsComponent} />,
		<Route exact key="userSettings" path="/account/settings/dataBox" render={DataBoxSettingsComponent} />,
		<Route exact key="userSettings" path="/account/settings/notifications" render={NotificationsSettingsComponent} />,
	]
}

export default UserRouting
