/* @flow */

import { connect } from 'react-redux'
import type {
	Dispatch,
	State,
	Template,
	TemplatesAction,
	CommonAction,
	AccountingDocumentType,
	AccountingDocumentDirection,
	BootstrapAction,
	FormFieldErrorContext,
	AccountingDocumentLineItem,
	MultiFormFieldMessages,
} from 'types'
import {
	getCurrentTemplatesLoading,
	getTemplate,
	getTemplateLoading,
	getTemplateUpdating,
	getLastCreatedTemplate,
	getCurrentEditingTemplate,
} from 'modules/accounting-document/selectors/templates'
import {
	createDraft,
	loadTemplate,
	removeTemplate,
	setCurrentTemplate,
	updateTemplate,
} from 'modules/accounting-document/actions/templates'
import { getVatCountryTypeByCountry } from 'modules/accounting-document/selectors'
import EditTemplateSubtype from './edit-template-subtype'
import withAutoload from 'wrappers/with-autoload'
import { isCurrentOrganizationVatFree } from 'modules/accounting-document/domain/accounting-document'
import {
	getCurrentOrganizationDomesticCurrency,
	getOrganizationColor,
	// isOrganizationEetActiveSelector,
} from 'modules/organization/selectors'
import { loadTemplates } from '../../actions/templates'
import { loadAccountingDocumentDefaults } from 'modules/settings/actions'
import { push } from 'react-router-redux'
import { accountingDocumentsTemplatesRoute, editAccountingDocumentRoute } from '../../routing/routes'
import { addSubPath, getFormFieldErrorContexts, getValues } from 'helpers'
import { TEMPLATE_ERROR_MAIN_PATHS, TEMPLATE_SCHEDULER_ERROR_MAIN_PATHS } from '../../constants'
import { getFormFieldErrors, makeGetMultiFormFieldErrors } from '../../../common/selectors'
import { removeErrors } from '../../helpers/templates'

type OwnProps = {|
	templateId: string,
|}

type StateProps = {|
	template: ?Template,
	loading: boolean,
	saving: boolean,
	isOrganizationVatFree: ?boolean,
	color: ?string,
	domesticCurrencyId: ?string,
	// isEetActive: boolean,
	lastCreatedTemplate: ?Template,
	getVatCountryTypeByCountry: (country: ?string) => ?number,
	errorCount?: number,
|}
const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => {
	const formFieldErrorContexts = getFormFieldErrorContexts(TEMPLATE_ERROR_MAIN_PATHS, 'Template', ownProps.templateId)
	const fieldErrors: Array<FormFieldErrorContext> = getValues(formFieldErrorContexts)

	const schedulerErrorContexts = getFormFieldErrorContexts(
		TEMPLATE_SCHEDULER_ERROR_MAIN_PATHS,
		'TemplateScheduler',
		ownProps.templateId,
	)

	const schedulerFieldErrors: Array<FormFieldErrorContext> = getValues(schedulerErrorContexts)
	let errorCount: number = 0

	formFieldErrorContexts &&
		fieldErrors.forEach((e: FormFieldErrorContext) => {
			if (e) {
				const error = getFormFieldErrors(state, e)
				if (error && error.length > 0) {
					errorCount += error.length
				}
			}
		})

	schedulerErrorContexts &&
		schedulerFieldErrors.forEach((e: FormFieldErrorContext) => {
			if (e) {
				const error = getFormFieldErrors(state, e)
				if (error && error.length > 0) {
					errorCount += error.length
				}
			}
		})

	const getMultiFormFieldErrors = makeGetMultiFormFieldErrors()
	const lineItemErrors = []

	const template = getCurrentEditingTemplate(state)

	template &&
		template.content &&
		template.content.lineItems &&
		template.content.lineItems.forEach((li: AccountingDocumentLineItem, index: number) => {
			let formFieldErrorContext: ?FormFieldErrorContext =
				formFieldErrorContexts && addSubPath(formFieldErrorContexts['lineItems'], li.id || `@${index}`)
			const error: ?MultiFormFieldMessages =
				formFieldErrorContext && getMultiFormFieldErrors(state, formFieldErrorContext)
			if (error && getValues(error).length > 0) {
				lineItemErrors.push(error)
			}
		})

	if (lineItemErrors && lineItemErrors.length > 0) {
		errorCount += lineItemErrors.length
	}

	return {
		template: getTemplate(state, ownProps.templateId),
		loading: getTemplateLoading(state, ownProps.templateId) || getCurrentTemplatesLoading(state),
		saving: getTemplateUpdating(state, ownProps.templateId),
		isOrganizationVatFree: isCurrentOrganizationVatFree(state),
		// isEetActive: isOrganizationEetActiveSelector(state),
		color: getOrganizationColor(state),
		lastCreatedTemplate: getLastCreatedTemplate(state),
		domesticCurrencyId: getCurrentOrganizationDomesticCurrency(state),
		getVatCountryTypeByCountry: (country: ?string): ?number => {
			return getVatCountryTypeByCountry(state, country)
		},
		errorCount,
	}
}

type DispatchProps = {|
	autoload: () => void,
	removeErrors: (template: Template) => void,
	onSave: (template: Template) => Promise<any>,
	onRemove: (template: Template) => Promise<void>,
	loadAccountingDocumentDefaults: (
		accountingDocumentType: ?AccountingDocumentType,
		accountingDocumentDirection: ?AccountingDocumentDirection,
	) => void,
	onCreateAccDoc: (template: Template) => void,
	setCurrentTemplate: (template: ?Template) => void,
|}

const mapDispatchToProps = (
	dispatch: Dispatch<BootstrapAction | TemplatesAction | CommonAction>,
	ownProps: OwnProps,
): DispatchProps => {
	return {
		autoload: () => {
			dispatch(loadTemplate(ownProps.templateId))
		},
		onSave: async (template: Template): Promise<void> => {
			try {
				await dispatch(updateTemplate(template))
				dispatch(push(accountingDocumentsTemplatesRoute()))
			} catch (e) {
				return Promise.reject()
			}
		},
		onRemove: async (template: Template): Promise<void> => {
			await dispatch(removeTemplate(template))
			await dispatch(loadTemplates(1, 20))
		},
		onCreateAccDoc: (template: Template) => {
			dispatch(createDraft(template)).then((action: TemplatesAction) => {
				if (action.confirmedDocument && action.confirmedDocument.id) {
					//přesměrovat na editaci nově vytvořeného dokumentu
					dispatch(push(editAccountingDocumentRoute(action.confirmedDocument.id)))
				}
			})
		},
		loadAccountingDocumentDefaults: (
			accountingDocumentType: ?AccountingDocumentType,
			accountingDocumentDirection: ?AccountingDocumentDirection,
		) => {
			dispatch(loadAccountingDocumentDefaults(accountingDocumentType, accountingDocumentDirection))
		},
		setCurrentTemplate: (template: ?Template) => {
			dispatch(setCurrentTemplate(template))
		},
		removeErrors: (template: Template) => {
			removeErrors(ownProps.templateId, template.content, dispatch)
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(withAutoload(EditTemplateSubtype))
