/* @flow */
/* eslint-disable react/no-multi-comp */

import React from 'react'
import moize from 'moize'
import type { AccountingDocument, AccountingDocumentScan } from 'types'
import { type WithTranslateProps } from 'wrappers'
import { FileIcon } from 'components'
import TriviLink from 'modules/common/components/trivi-link'
import { openFile } from 'modules/file/domain'
import DataGridPopover from 'modules/data-grid/components/data-grid-popover'
import { Column } from 'modules/data-grid-next/components'
import type { Direction } from 'modules/data-grid-next/types'
import { UPLOAD_APPROVED } from 'modules/accounting-document/constants'
import { DATA_GRID_CACHE_SIZE } from 'trivi-constants'
import FullText from 'components/full-text'
import { colors } from 'variables'

export type Props = {|
	width?: number | string,
	defaultSortDirection?: Direction,
	currentUserIsInternal?: boolean,
	onClickToEditing?: (accountingDocument: AccountingDocument) => void,
	onClickToPreview?: (accountingDocument: AccountingDocument) => void,
	automaticApproval?: boolean,
	openPreview?: boolean,
	...WithTranslateProps,
|}

const styles = {
	category: {
		color: colors.blackFaded60,
		fontSize: 12,
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		lineHeight: '16px',
		minHeight: 16,
		paddingLeft: 29,
	},
	files: {
		overflow: 'hidden',
	},
	fileIcons: {
		lineHeight: '22px',
	},
	file: {
		display: 'flex',
	},
	icon: {
		verticalAlign: 'middle',
		marginRight: 5,
		marginLeft: -3,
		position: 'relative',
		top: -1,
		height: 24,
		width: 24,
		display: 'inline-block',
	},
	more: {
		marginLeft: 28,
		display: 'inline-block',
	},
	item: {
		display: 'block',
		fontSize: 16,
		lineHeight: '20px',
		padding: '2px 15px',
	},
	fileWrapper: {
		display: 'block',
		overflow: 'hidden',
		marginRight: 15,
	},
	filename: {
		lineHeight: '22px',
		verticalAlign: 'middle',
		overflow: 'hidden',
		paddingRight: 10,
	},
	link: { display: 'block' },
}

export function renderContent(props: Props, value: string, row: AccountingDocument) {
	// https://gitlab.trivi.local/server-team/Trivi.Portal/wikis/portal/schvalovani
	function clickHandler() {
		const goOnEditRoute = UPLOAD_APPROVED === row.__extractingState && props.currentUserIsInternal
		if (props.onClickToEditing && (goOnEditRoute || props.automaticApproval)) {
			props.onClickToEditing(row)
		} else if (props.onClickToPreview) {
			props.onClickToPreview(row)
		}
	}

	const inline = row.scans && row.scans.length < 3 ? row.scans : row.scans ? row.scans.slice(0, 1) : null
	const dropdown = row.scans && row.scans.length >= 3 ? row.scans.slice(1) : null
	let result = (
		<div style={styles.files}>
			<span style={styles.fileIcons}>
				{inline &&
					inline.map((s: AccountingDocumentScan, index: number) => {
						let _file = (
							<div style={styles.file} key={`scan-${s.guid || ''}-file-${s.filename || ''}-idx${index}`}>
								<span style={styles.icon}>
									<FileIcon fileName={s.filename} />
								</span>
								<span style={styles.filename}>
									<FullText text={s.filename} />
								</span>
							</div>
						)
						return _file
					})}
			</span>
		</div>
	)

	if (props.openPreview) {
		result = (
			<TriviLink style={styles.link} onClick={clickHandler}>
				{result}
			</TriviLink>
		)
	}

	result = (
		<div>
			{result}
			{dropdown && (
				<DataGridPopover style={styles.more} label={props.t('grid.moreItemsOnClick', { count: dropdown.length })}>
					{dropdown &&
						dropdown.map((s: AccountingDocumentScan, i: number) => {
							let _file = (
								<div key={i} style={styles.item}>
									{s.filename}
								</div>
							)

							if (props.openPreview) {
								const click = () => s.fileId && s.filename && openFile(s.fileId, s.filename, 'popupPage')
								_file = (
									<TriviLink
										style={styles.link}
										onClick={click} //eslint-disable-line
										key={`scan-${s.guid || ''}-file-${s.filename || ''}-idx${i}`}
									>
										{_file}
									</TriviLink>
								)
							}
							return _file
						})}
				</DataGridPopover>
			)}
			{(value || (row.scans && row.scans.length < 2)) && <div style={styles.category}>{value}</div>}
		</div>
	)

	return result
}

export default function getCategoryColumn(props: Props) {
	function columnRenderer(value: string, row: AccountingDocument) {
		return renderContent(props, value, row)
	}

	const columnRendererCashed = moize(columnRenderer, { maxSize: DATA_GRID_CACHE_SIZE })

	return (
		<Column
			title={props.t('accountingDocument.columns.fileName')}
			width={props.width}
			columnId="categoryName"
			key="categoryName"
			render={columnRendererCashed}
			sortable
			defaultSortDirection={props.defaultSortDirection || undefined}
		/>
	)
}
