/* @flow */

import { push } from 'react-router-redux'
import { mergeContactCrdphBankAccount } from 'modules/address-book/domain/contact-bankaccounts'
import { searchCRPDPH_DB } from 'modules/common/actions'
import type { Action as CountryVariantAction } from 'modules/common/actions/country-variant-action-types'
import type {
	AddressBookAction,
	AccountingDocumentReduxAction,
	Contact,
	ContactsSearchResult,
	Dispatch,
	AccountingDocumentBankAccount,
	State,
} from 'types'
import {
	contact as contactApi,
	contacts as contactsApi,
	contactsSearch as contactsSearchApi,
} from 'modules/common/models/api-model'
import { getOrLoadContact } from 'modules/accounting-document/actions'
import { API_NOT_FOUND_CODE, FE_NOT_FOUND_CODE } from 'trivi-constants'
import { emptyContact } from 'types/empty'
import { AccountingDocumentBankAccounts_ContactBankAccounts } from 'types/convertor'
import { notFoundRoute } from 'modules/navigation/routing/routes'
import { getContact } from 'modules/address-book/selectors'
import { getCrdphResult } from 'modules/common/selectors'

type SaveContactOptions = {|
	addCrdphBankAccounts?: boolean,
|}

export function loadContacts(page: number, pageSize: number) {
	return async (dispatch: Dispatch<AddressBookAction>) => {
		dispatch({
			type: 'START_LOAD_CONTACTS',
		})
		try {
			const result: ContactsSearchResult = await contactsSearchApi.post({
				page: 0,
				pageSize: pageSize,
				//mode: 'no-cors',
			})

			dispatch({
				type: 'FINISH_LOAD_CONTACTS',
				contacts: result.contacts,
			})
		} catch (serverError) {
			dispatch({
				type: 'FINISH_LOAD_CONTACTS',
				serverError,
			})
		}
	}
}

export function loadContact(contactId: string, redirectOnError?: boolean, showError?: boolean = true) {
	return async (dispatch: Dispatch<AddressBookAction>) => {
		dispatch({
			type: 'START_LOAD_CONTACT',
			contactId,
		})

		if (contactId == 'new') {
			return dispatch({
				type: 'FINISH_LOAD_CONTACT',
				contactId,
				contact: emptyContact(),
			})
		}

		try {
			const result: Contact = await contactApi.get({ contactId })
			return dispatch({
				type: 'FINISH_LOAD_CONTACT',
				contactId,
				contact: result,
			})
		} catch (serverError) {
			const action = await dispatch({
				type: 'FINISH_LOAD_CONTACT',
				contactId,
				serverError: showError ? serverError : undefined,
			})
			if (redirectOnError && (serverError.code === API_NOT_FOUND_CODE || serverError.code === FE_NOT_FOUND_CODE)) {
				dispatch(push(notFoundRoute()))
			}
			return action
		}
	}
}

export function loadContactByRegNo(regNo: string) {
	return async (dispatch: Dispatch<AddressBookAction>) => {
		dispatch({
			type: 'START_LOAD_CONTACT_BY_REGNO',
			regNo,
		})

		try {
			const result = await contactsSearchApi.post({ filters: [{ field: 'companyRegNo', value: regNo }] })
			if (result && result.contacts && result.contacts.length) {
				const found = result.contacts.shift()
				dispatch({
					type: 'FINISH_LOAD_CONTACT',
					contactId: found.id || '',
					contact: found,
				})
			} else {
				dispatch({
					type: 'FINISH_LOAD_CONTACT_BY_REGNO',
					regNo,
				})
			}
		} catch (serverError) {
			dispatch({
				type: 'FINISH_LOAD_CONTACT_BY_REGNO',
				regNo,
				serverError,
			})
		}
	}
}

export function saveContact(contact: Contact, options?: SaveContactOptions) {
	return async (dispatch: Dispatch<AddressBookAction>, getState: () => State) => {
		const oldContact = getContact(getState(), contact.id) || undefined
		dispatch({
			type: 'START_SAVE_CONTACT',
			contact,
		})
		try {
			if (contact.id) {
				const confirmedContact: Contact = await contactApi.put({ contactId: contact.id }, contact)
				return dispatch({
					type: 'FINISH_SAVE_CONTACT',
					contact: confirmedContact,
				})
			} else {
				return dispatch(createContact(contact, options))
			}
		} catch (serverError) {
			return dispatch({
				type: 'FINISH_SAVE_CONTACT',
				contact: oldContact,
				serverError,
			})
		}
	}
}

export function findOrCreateContact(contact: Contact, options?: SaveContactOptions) {
	return async (dispatch: Dispatch<AddressBookAction>) => {
		dispatch({
			type: 'START_SAVE_CONTACT',
			contact,
		})
		try {
			if (contact.id) {
				const confirmedContact: Contact = await contactApi.put({ contactId: contact.id }, contact)
				return dispatch({
					type: 'FINISH_SAVE_CONTACT',
					contact: confirmedContact,
				})
			} else {
				if (contact.companyRegNo || contact.taxId) {
					const contactExist = await contactsSearchApi.post({
						filters: contact.companyRegNo
							? [{ field: 'companyRegNo', value: contact.companyRegNo }]
							: [{ field: 'taxId', value: contact.taxId }],
					})
					if (contactExist && contactExist.contacts && contactExist.contacts.length) {
						const found = contactExist.contacts.shift()
						return dispatch({
							type: 'FINISH_SAVE_CONTACT',
							contact: found,
						})
					}
				}
				return dispatch(createContact(contact, options))
			}
		} catch (serverError) {
			return dispatch({
				type: 'FINISH_SAVE_CONTACT',
				serverError,
			})
		}
	}
}

export function createContact(contact: Contact, options?: SaveContactOptions) {
	return async (dispatch: Dispatch<AddressBookAction>) => {
		dispatch({
			type: 'START_CREATE_CONTACT',
		})
		try {
			const preparedContact = await dispatch(preCreateContact(contact, options))
			const result = await contactsApi.post(preparedContact)
			return dispatch({
				type: 'FINISH_CREATE_CONTACT',
				contact: result,
			})
		} catch (serverError) {
			return dispatch({
				type: 'FINISH_CREATE_CONTACT',
				serverError,
			})
		}
	}
}

export const deleteContact = (contactId: string) => {
	return async (dispatch: Dispatch<AddressBookAction>) => {
		dispatch({
			type: 'START_DELETE_CONTACT',
		})
		try {
			await contactApi.delete({ contactId })

			dispatch({ type: 'FINISH_DELETE_CONTACT' })
		} catch (serverError) {
			dispatch({
				type: 'FINISH_DELETE_CONTACT',
				serverError,
			})
		}
	}
}

export function addBankAccountToContact(bankAccount: AccountingDocumentBankAccount, contactId: string) {
	return addBankAccountsToContact([bankAccount], contactId)
}

export function addBankAccountsToContact(bankAccounts: Array<AccountingDocumentBankAccount>, contactId: string) {
	return async (dispatch: Dispatch<AccountingDocumentReduxAction | AddressBookAction>, getState: () => State) => {
		const contact: ?Contact = await getOrLoadContact(getState(), dispatch, contactId)
		if (contact) {
			contact.bankAccounts = Array.from(contact.bankAccounts || []).concat(
				AccountingDocumentBankAccounts_ContactBankAccounts(bankAccounts),
			)
			return dispatch(saveContact(contact))
		}
	}
}

// Private helper (sub)actions

function preCreateContact(contact: Contact, options?: SaveContactOptions) {
	return async (dispatch: Dispatch<any>) => {
		if (options && options.addCrdphBankAccounts) {
			contact = await dispatch(addContactCrdphBankAccounts(contact))
		}

		return (contact: any)
	}
}

function addContactCrdphBankAccounts(contact: Contact) {
	return async (dispatch: Dispatch<CountryVariantAction>, getState: () => State) => {
		const companyRegNo = contact.companyRegNo
		if (companyRegNo) {
			await dispatch(searchCRPDPH_DB(companyRegNo))
			const crdphResult = getCrdphResult(getState(), companyRegNo)
			contact = mergeContactCrdphBankAccount(contact, (crdphResult && crdphResult.registeredBankAccounts) || [])
		}

		return contact
	}
}
