/* @flow */

import React, { Component } from 'react'
import { withTranslate, withNotify, type WithTranslateProps, type WithNotifyProps } from 'wrappers'
import type { WithRouterProps } from 'types'
import PasswordFormLayout from '../pages/password-form-layout'
import Button from 'components/button'
import TextField from 'components/TextField'
import styles from './styles.css'
import { Validator } from 'utils'

export type Props = {|
	changePassword: (newPassword: string, token: string) => void,
|}

type State = {|
	newPassword: string,
	verifyPassword: string,
|}

type ComponentProps = {| ...WithRouterProps, ...WithTranslateProps, ...WithNotifyProps, ...Props |}

class ChangePasswordForm extends Component<ComponentProps, State> {
	state = {
		newPassword: '',
		verifyPassword: '',
	}

	validate = (): boolean => {
		const { t } = this.props
		const { newPassword, verifyPassword } = this.state

		if (!newPassword || !verifyPassword) {
			this.props.notify(t('clientError.fillAll'), 'error')
			return false
		}

		if (!Validator.password(newPassword)) {
			this.props.notify(t('clientError.newPasswordInvalid'), 'error')
			return false
		}

		if (newPassword != verifyPassword) {
			this.props.notify(t('clientError.newAndConfirmPasswordDontMatch'), 'error')
			return false
		}

		return true
	}

	handleFormSubmit = (ev: Object) => {
		ev.preventDefault()
		if (this.validate()) {
			this.props.changePassword(this.state.newPassword, this.props.match.params.token)
		}
	}

	handleNewPasswordChange = (event: SyntheticInputEvent<HTMLInputElement>, newPassword: ?string) => {
		if (newPassword !== null && newPassword !== undefined) {
			this.setState({ newPassword })
		}
	}

	handleVerifyPasswordChange = (event: SyntheticInputEvent<HTMLInputElement>, verifyPassword: ?string) => {
		if (verifyPassword !== null && verifyPassword !== undefined) {
			this.setState({ verifyPassword })
		}
	}

	render() {
		const { t } = this.props
		return (
			<PasswordFormLayout>
				<form onSubmit={this.handleFormSubmit} className={styles.wrapper} noValidate>
					<div className={styles.field}>
						<TextField
							fullWidth
							name="password"
							type="password"
							hintText={t('user.login.newPasswordLabel')}
							onChange={this.handleNewPasswordChange}
							autoTestId="change-password-password"
						/>
					</div>
					<div className={styles.field}>
						<TextField
							fullWidth
							name="verify"
							type="password"
							hintText={t('user.login.verifyPasswordLabel')}
							onChange={this.handleVerifyPasswordChange}
							autoTestId="change-password-verify"
						/>
					</div>
					<div className={styles.instructions}>{t('user.login.newPasswordInstructions')}</div>
					<div className={styles.button}>
						<Button
							fullWidth
							tall
							type="submit"
							labelText={t('user.login.changePasswordButton')}
							autoTestId="change-password-submit"
						/>
					</div>
				</form>
			</PasswordFormLayout>
		)
	}
}

export default withTranslate(withNotify(ChangePasswordForm))
