/* @flow */

import { PureComponent } from 'react'
import { connect } from 'react-redux'
import { replace } from 'react-router-redux'
import type { State, AccountingDocumentReduxAction } from 'types'
import { userHasAccess } from 'permissions/acl-logic'
import {
	settingsAccountingRoute,
	settingsCompanyRoute,
	settingsTriviSettingsRoute,
	settingsUsersRoute,
} from 'modules/settings/routing/routes'

type StateProps = {|
	redirRoute: string,
|}

type DispatchProps = {|
	redirectFn: (route: string) => void,
|}

type MergedProps = {
	redirect: () => void,
}

function mapStateToProps(state: State): StateProps {
	const canEditUsers = userHasAccess(state, 'editUsers')
	const canEditProfile = userHasAccess(state, 'setOrganizationProfile')
	const canEditAccounting = userHasAccess(state, 'setOrganizationAccounting')

	let redirRoute = settingsTriviSettingsRoute()
	if (canEditProfile) {
		redirRoute = settingsCompanyRoute()
	} else if (canEditAccounting) {
		redirRoute = settingsAccountingRoute()
	} else if (canEditUsers) {
		redirRoute = settingsUsersRoute()
	}

	return {
		redirRoute,
	}
}

function mapDispatchToProps(dispatch: Dispatch<AccountingDocumentReduxAction>): DispatchProps {
	return {
		redirectFn: (route: string) => {
			dispatch(replace(route, { fromRedirect: true }))
		},
	}
}

function mergeProps(stateProps: StateProps, dispatchProps: DispatchProps): MergedProps {
	return {
		redirect: () => {
			dispatchProps.redirectFn(stateProps.redirRoute)
		},
	}
}

class SettingsRedir extends PureComponent<MergedProps> {
	componentDidMount() {
		this.props.redirect()
	}

	render() {
		return null
	}
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(SettingsRedir)
