/* @flow */
/** @jsx jsx */

// $FlowFixMe - there is useEffect
import { useEffect } from 'react'
import { jsx } from '@emotion/core'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import Loader from 'components/loader'
import Paper from 'components/Paper'
import style from './qr.style'

type Props = {|
	...WithTranslateProps,
	qr?: string,
	uniqueId?: string,
	loadQrCode: (id: string) => void,
|}

function Qr({ qr, uniqueId, loadQrCode, t }: Props) {
	useEffect(() => {
		uniqueId && loadQrCode(uniqueId)
	}, [loadQrCode, uniqueId])

	if (null === qr) return null

	return (
		<Paper zDepth={3}>
			<div css={style.root}>
				<div css={style.qr}>{qr ? <img src={qr} css={style.image} title="QR" /> : <Loader visible />}</div>
				<div css={style.text}>
					<div css={style.headline}>{t('invoice.qr.header')}</div>
					<div css={style.paragraph}>{t('invoice.qr.paragraph')}</div>
				</div>
			</div>
		</Paper>
	)
}

export default withTranslate(Qr)
