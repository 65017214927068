/* @flow */

import { merge } from 'lodash-es'
import memoize from 'memoize-one'
import { keyframes } from '@emotion/core'
import { colors } from 'variables'

const loading = keyframes`
	0% {
		background-position: -668px 0;
	}
	100% {
		background-position: 668px 0;
	}
`

const getStyles = memoize(
	(light?: boolean = false, pageSize: number, rowsSelected: boolean, extraStyles: ?Object = {}) => {
		const borderOffset = light ? 0 : 15

		const style = {
			root: {
				table: {
					width: '100%',
					margin: 0,
					padding: 0,
					color: colors.black,
					tableLayout: 'fixed',
					borderSpacing: 0,
					borderCollapse: 'separate',
					textAlign: 'left',
				},
				td: {
					height: 70,
					position: 'relative',
				},
				thead: {
					tr: {
						overflow: 'hidden',
						height: 40,
						fontSize: 12,
						color: colors.black,
						textTransform: 'uppercase',
						display: 'table-row',
					},
				},

				tbody: {
					borderRadius: 2,
					background: colors.white,
					boxShadow: light ? 'none' : '0 2px 2px 0 rgba(0, 0, 0, 0.14)',
				},

				th: {
					lineHeight: '40px',
					height: '40px',
					overflow: 'hidden',
					'&:first-of-type': { paddingLeft: 10 },
				},

				'.row': {
					display: 'table-row',
					margin: 0,
					padding: 0,
					fontSize: 14,
					lineHeight: '21px',
					color: colors.black,
					fontWeight: 'normal',
					position: 'relative',
					'&:hover': {
						td: {
							'&:before': {
								content: '""',
								left: 0,
								right: 0,
								opacity: 1,
								background: colors.grey400,
								zIndex: '5',
							},
							'&:after': {
								left: 0,
								right: 0,
								background: colors.grey400,
								zIndex: '5',
							},
						},
						'.checkbox': {
							opacity: 1,
							pointerEvents: 'all',
						},
						'.actions': {
							opacity: 1,
							pointerEvents: 'all',
						},
					},
					'&:first-of-type': {
						td: {
							'&:before': { opacity: light ? 1 : 0 },
							'&:first-of-type': { borderTopLeftRadius: 2 },
							'&:last-of-type': { borderTopRightRadius: 2 },
						},
					},
					'&:last-of-type': {
						td: {
							'&:first-of-type': { borderBottomLeftRadius: 2 },
							'&:last-of-type': { borderBottomRightRadius: 2 },
							'&:after': { display: 'none !important' },
						},
					},
					td: {
						'&:before': {
							content: '""',
							display: 'block',
							position: 'absolute',
							left: 0,
							right: 0,
							top: -1,
							height: 1,
							opacity: 0,
							background: '#e6e6e6',
						},
						'&:after': {
							content: '""',
							display: 'block',
							position: 'absolute',
							left: 0,
							right: 0,
							bottom: 0,
							height: 1,
							background: '#e6e6e6',
						},
						'&:first-of-type': {
							paddingLeft: 10,
							'&:before': { left: borderOffset },
							'&:after': { left: borderOffset },
						},
						'&:last-of-type': {
							textAlign: 'right',
							paddingRight: 20,
							'&:before': { right: borderOffset },
							'&:after': { right: borderOffset },
						},
					},
				},

				'.selectedRow': {
					display: 'table-row',
					position: 'relative',
					background: colors.blue100,
					td: {
						'&:before, &:after': {
							background: `${colors.blue} !important`,
							opacity: '1 !important',
							left: '0 !important',
							right: '0 !important',
							zIndex: '10',
						},
					},
				},

				'.clickableRow': {
					cursor: 'pointer',
					'&:hover td': { background: colors.blue100Faded30 },
				},

				'.expandableRowByClick': {
					cursor: 'pointer',
				},

				'.consumedRow': {
					display: 'table-row',
					position: 'relative',
					background: 'rgb(231, 231, 231)',
					opacity: 0.5,
					'&:hover': { opacity: 1 },
					td: {
						'&:before': {
							content: '""',
							display: 'block',
							position: 'absolute',
							left: 0,
							right: 0,
							top: -1,
							height: 1,
							opacity: 0,
							background: 'rgb(177, 177, 177)',
						},
						'&:after': {
							content: '""',
							display: 'block',
							position: 'absolute',
							left: 0,
							right: 0,
							bottom: 0,
							height: 1,
							background: 'rgb(177, 177, 177)',
						},
					},
				},

				'.selectedConsumedRow': {
					display: 'table-row',
					position: 'relative',
					background: colors.blue300,
					td: {
						'&:before, &:after': {
							background: `${colors.blue} !important`,
							opacity: '1 !important',
							left: '0 !important',
							right: '0 !important',
						},
					},
				},
			},
			checkbox: {
				opacity: 0,
				pointerEvents: 'none',
				transition: 'none',
			},
			checkedCheckbox: {
				opacity: 1,
				pointerEvents: 'all',
				transition: 'none',
			},
			headerCheckbox: {
				position: 'relative',
				left: -5,
				opacity: rowsSelected ? 1 : 0,
				'&:hover': {
					opacity: 1,
				},
			},
			empty: {
				display: 'flex',
				alignContent: 'center',
				alignItems: 'center',
				justifyContent: 'space-around',
				background: 'white',
				width: '100%',
				textAlign: 'center',
				color: colors.black,
				fontSize: 24,
				lineHeight: '32px',
				padding: '80px 20px 40px 20px',
				minHeight: 350,
			},
			emptyVisual: {
				display: 'block',
				position: 'relative',
				width: 363,
				maxWidth: '75%',
				height: 'auto',
				margin: '40px auto 0 auto',
				pointerEvents: 'none',
				userSelect: 'none',
			},
			loading: {
				background: 'white',
				width: '100%',
				textAlign: 'center',
				color: colors.black,
				fontSize: 24,
				lineHeight: '32px',
				paddingTop: 70,
				minHeight: pageSize * 70,
			},
			loadingContainer: {
				display: 'block',
				position: 'relative',
				margin: '45px 20px 10px 20px',
				pointerEvents: 'none',
				userSelect: 'none',
				overflow: 'hidden',
			},
			loadingRow: {
				position: 'relative',
				height: 80,
				borderBottom: '1px solid #EAEAEA',
				'&:nth-of-type(2)': {
					opacity: 0.5,
					'span:nth-of-type(2)': { animationDelay: '-650ms' },
					'span:nth-of-type(3)': { animationDelay: '-400ms' },
					'span:nth-of-type(4)': { animationDelay: '-200ms' },
					'span:nth-of-type(5)': { animationDelay: '0ms' },
					'span:nth-of-type(6)': { animationDelay: '350ms' },
					'span:nth-of-type(8)': { animationDelay: '-650ms' },
					'span:nth-of-type(9)': { animationDelay: '-200ms' },
					'span:nth-of-type(10)': { animationDelay: '225ms' },
				},
				'&:nth-of-type(3)': {
					opacity: 0.25,
					'span:nth-of-type(2)': { animationDelay: '-550ms' },
					'span:nth-of-type(3)': { animationDelay: '-300ms' },
					'span:nth-of-type(4)': { animationDelay: '-100ms' },
					'span:nth-of-type(5)': { animationDelay: '100ms' },
					'span:nth-of-type(6)': { animationDelay: '450ms' },
					'span:nth-of-type(8)': { animationDelay: '450ms' },
					'span:nth-of-type(9)': { animationDelay: '-100ms' },
					'span:nth-of-type(10)': { animationDelay: '325ms' },
				},
				'&:nth-of-type(4)': {
					opacity: 0.1,
					borderBottom: 'none',
					'span:nth-of-type(2)': { animationDelay: '-450ms' },
					'span:nth-of-type(3)': { animationDelay: '-200ms' },
					'span:nth-of-type(4)': { animationDelay: '0ms' },
					'span:nth-of-type(5)': { animationDelay: '200ms' },
					'span:nth-of-type(6)': { animationDelay: '550ms' },
					'span:nth-of-type(8)': { animationDelay: '-450ms' },
					'span:nth-of-type(9)': { animationDelay: '0ms' },
					'span:nth-of-type(10)': { animationDelay: '425ms' },
				},
				span: {
					display: 'block',
					width: 110,
					height: 12,
					backgroundColor: '#D6D6D6',
					position: 'absolute',
					top: 20,
					animationName: `${loading}`,
					animationDuration: '2000ms',
					animationDelay: '-1000ms',
					animationIterationCount: 'infinite',
					animationTimingFunction: 'linear',
					background: 'linear-gradient(to right, #D6D6D6 8%, #b9b9b9 18%, #D6D6D6 33%)',
					WebkitBackgroundSize: '1200px 80px',

					'&:nth-of-type(1)': {
						left: 15,
						width: 110,
					},
					'&:nth-of-type(2)': {
						left: 155,
						width: 140,
						animationDelay: '-750ms',
					},
					'&:nth-of-type(3)': {
						left: 345,
						width: 70,
						animationDelay: '-500ms',
					},
					'&:nth-of-type(4)': {
						left: 475,
						width: 70,
						animationDelay: '-300ms',
					},
					'&:nth-of-type(5)': {
						left: 640,
						width: 100,
						animationDelay: '-100ms',
						top: 32,
					},
					'&:nth-of-type(6)': {
						left: 825,
						width: 80,
						animationDelay: '-250ms',
						top: 32,
						opacity: 0.35,
					},
					'&:nth-of-type(7)': {
						left: 15,
						width: 83,
						top: 44,
						opacity: 0.35,
					},
					'&:nth-of-type(8)': {
						left: 155,
						width: 83,
						animationDelay: '-750ms',
						top: 44,
						opacity: 0.35,
						display: 'block',
					},
					'&:nth-of-type(9)': {
						left: 474,
						width: 83,
						animationDelay: '-300ms',
						top: 44,
						opacity: 0.3,
						display: 'block',
					},
					'&:nth-of-type(10)': {
						left: 770,
						width: 20,
						animationDelay: '125ms',
						top: 28,
						height: 20,
						borderRadius: '50%',
						opacity: 0.95,
					},
				},
			},
			pager: {
				padding: light ? '0 25px' : 0,
			},
		}

		return merge(style, extraStyles)
	},
)

export default getStyles
