/* @flow */

import React, { Component } from 'react'
import memoize from 'memoize-one'
import { convertToDate, getDayCountString } from 'utils/formatters'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import DatePicker from 'components/date-picker'
import VariableSymbolInput from 'components/variable-symbol-input'
import styles from './payment-details.css'
import moment from 'moment'
import type { AccountingDocument as AccDoc } from 'types'

type Props = {|
	...WithTranslateProps,
	accountingDocumentId: string,
	accDoc?: AccDoc,
	issueDate: ?string,
	hintTextDueDate?: ?number,
	dueDate: ?string,
	readonly?: boolean,
	readonlyDueDate?: ?boolean,
	accDocDefaultInterval?: number,
	onDueDateChange: (dueDate: ?Date) => void,
	variableSymbol: ?string,
	variableSymbolPreview: ?string,
	onVariableSymbolChange: (variableSymbol: ?string) => void,
	isTemplate?: boolean,
	loadDefaults?: (accDoc: AccDoc) => void,
|}

export type State = {|
	dueDate: ?Date,
	variableSymbol: ?string,
	editingField: ?string,
|}

class PaymentDetails extends Component<Props, State> {
	state: State = {
		dueDate: this.props.dueDate ? convertToDate(this.props.dueDate) : null,
		variableSymbol: this.props.variableSymbol,
		editingField: null,
	}

	componentDidMount() {
		this.props.accDoc && this.props.loadDefaults && this.props.loadDefaults(this.props.accDoc)
	}

	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		if (
			this.props.accountingDocumentId !== nextProps.accountingDocumentId ||
			this.props.accDoc !== nextProps.accDoc ||
			this.props.issueDate !== nextProps.issueDate ||
			this.props.dueDate !== nextProps.dueDate ||
			this.props.accDocDefaultInterval !== nextProps.accDocDefaultInterval
		) {
			const { editingField } = this.state

			let setDate

			setDate = this.getDueDate(nextProps.dueDate)

			if (editingField === 'dueDate') {
				setDate = this.state.dueDate
			}

			this.setState({
				dueDate: setDate && setDate,
				variableSymbol: editingField === 'variableSymbol' ? this.state.variableSymbol : nextProps.variableSymbol,
			})
			this.setState({ editingField: null })
		}
	}

	onVariableSymbolChange = (event: SyntheticInputEvent<HTMLInputElement>, variableSymbol: ?string) => {
		this.setState({ variableSymbol, editingField: 'variableSymbol' })
	}

	onDueDateChange = (event: ?SyntheticInputEvent<HTMLInputElement>, dueDate: ?Date) => {
		this.props.onDueDateChange && this.props.onDueDateChange(dueDate)
		this.setState({ dueDate, editingField: 'dueDate' })
	}

	onVariableSymbolBlur = () => {
		if (this.state.variableSymbol !== this.props.variableSymbol) {
			this.props.onVariableSymbolChange && this.props.onVariableSymbolChange(this.state.variableSymbol)
		}
		this.setState({ editingField: null })
	}

	getIssueDate = memoize((date: ?string) => {
		return date ? convertToDate(date) : null
	})

	getDueDate = memoize((date: ?string) => {
		return date ? convertToDate(date) : null
	})

	getDueDateHintText = memoize((days: ?number): ?string => {
		return days != null ? `${getDayCountString(days)}  ` : null
	})

	getDefaultDate = (days: ?number): Date => {
		return moment(this.props.issueDate)
			.add(days, 'days')
			.toDate()
	}

	render() {
		const { t, readonly, readonlyDueDate, isTemplate } = this.props
		const { dueDate } = this.state
		return (
			<div className={styles.root}>
				<div className={styles.vs}>
					<div className={styles.header}>{t('invoice.variableSymbolLabel')}</div>
					<VariableSymbolInput
						name="variableSymbol"
						value={this.state.variableSymbol || ''}
						onChange={this.onVariableSymbolChange}
						onBlur={this.onVariableSymbolBlur}
						hintText={!readonly ? this.props.variableSymbolPreview || t('invoice.variableSymbolHint') : null}
						disabled={readonly}
						inline
						autoTestId="invoice-payment-details-variableSymbol"
					/>
				</div>
				<div className={styles.due}>
					<div className={styles.header}>{t('invoice.dueDateLabel')}</div>
					<div>
						<DatePicker
							value={dueDate}
							onChange={this.onDueDateChange}
							hintText={
								isTemplate
									? this.getDueDateHintText(this.props.hintTextDueDate)
									: readonly || !!readonlyDueDate
									? null
									: t('invoice.dueDateHint')
							}
							disabled={readonly || !!readonlyDueDate}
							minDate={this.getIssueDate(this.props.issueDate)}
							autoTestId="payment-details-dueDate"
							autoWidth
							inline
							required={!isTemplate}
							defaultDate={this.getDefaultDate(this.props.accDocDefaultInterval)}
						/>
					</div>
				</div>
			</div>
		)
	}
}

export default withTranslate(PaymentDetails)
