/* @flow */

import diff from 'json-patch-gen'
import type {
	ActionFunction,
	Currency,
	Dispatch,
	EmailListResponse,
	Enum,
	FinancialAccount,
	FinancialAccountsGetParams,
	FinancialAccountsGetResponse,
	InternalMembership,
	Invitation,
	InvitationRequest,
	Organization,
	OrganizationAction,
	OrganizationAddress,
	OrganizationCreateRequest,
	OrganizationCreator,
	OrganizationPersonAuthorizedToSign,
	OrganizationTaxRepresentative,
	State,
	UserProfile,
	OrganizationProcessIssues,
	UserInvitationsByUserDeleteParams,
	UserInvitationsByEmailDeleteParams,
} from 'types'
import {
	branches as branchesApi,
	currencies as currenciesApi,
	favorites as favoritesApi,
	favoritesSection as favoritesSectionApi,
	financialAccounts as financialAccountsApi,
	inboundEmails as inboundEmailsApi,
	internalUserMembership as internalUserMembershipApi,
	userInvitations as invitationsApi,
	organizationAddress as organizationAddressApi,
	organization as organizationApi,
	organizationCreator as organizationCreatorApi,
	organizationCreators as organizationCreatorsApi,
	organizationSignAuthorizedPerson as organizationSignAuthorizedPersonApi,
	organizationSignAuthorizedPersons as organizationSignAuthorizedPersonsApi,
	organizationTaxRepresentative as organizationTaxRepresentativeApi,
	organizationTaxRepresentatives as organizationTaxRepresentativesApi,
	organizations as organizationsApi,
	projects as projectsApi,
	users as usersApi,
	processOrganizationIssues as processOrganizationIssuesApi,
	userInvitationsByUser as userInvitationsByUserApi,
	userInvitationsByEmail as userInvitationsByEmailApi,
} from 'modules/common/models/api-model'
import {
	isCurrentOrganizationUsersLoading,
	isCurrentOrganizationInvitationsLoading,
	getFinancialAccounts,
	getFinancialAccountsCurrentLoadingKey,
	getCurrentOrganizationAccountant,
	getCurrentOrganizationWagesAccountant,
} from '../selectors'
import { needsRefresh, clearLastFetchedTime } from 'utils/fetch-throttle'
import { getCurrentOrganizationId } from 'modules/user/selectors'
import { generateKeyFromFinancialAccountsGetParams } from '../utils'
import { isFavoritesLoaded } from '../selectors/favorites'
import { EMPTY_ARRAY } from 'trivi-constants'
import Tracking from 'utils/tracking'
import { avatar } from 'types'
import { convertBlobToBase64 } from 'utils/blob'

export function loadBranches(): ActionFunction<OrganizationAction> {
	return async (dispatch: Dispatch<OrganizationAction>) => {
		dispatch({
			type: 'START_LOAD_BRANCHES',
		})
		try {
			const result: Enum = await branchesApi.get({})
			dispatch({
				type: 'FINISH_LOAD_BRANCHES',
				branches: result || [],
			})
		} catch (error) {
			dispatch({
				type: 'FINISH_LOAD_BRANCHES',
				branches: null,
				serverError: error,
			})
		}
	}
}

export function loadProjects(): ActionFunction<OrganizationAction> {
	return async (dispatch: Dispatch<OrganizationAction>) => {
		dispatch({
			type: 'START_LOAD_PROJECTS',
		})
		try {
			const result: Enum = await projectsApi.get({})
			dispatch({
				type: 'FINISH_LOAD_PROJECTS',
				projects: result || [],
			})
		} catch (error) {
			dispatch({
				type: 'FINISH_LOAD_PROJECTS',
				projects: null,
				serverError: error,
			})
		}
	}
}

export function loadFinancialAccounts(requestParams?: FinancialAccountsGetParams): ActionFunction<OrganizationAction> {
	return async (dispatch: Dispatch<OrganizationAction>, getState: () => State) => {
		const state = getState()
		const key = generateKeyFromFinancialAccountsGetParams(requestParams)
		const currentLoadingKey = getFinancialAccountsCurrentLoadingKey(state)
		const cashedFinancialAccounts = getFinancialAccounts(state, key)
		if (!cashedFinancialAccounts && key !== currentLoadingKey) {
			dispatch({
				type: 'START_LOAD_FINANCIAL_ACCOUNTS',
				key,
			})
			try {
				const result: FinancialAccountsGetResponse = await financialAccountsApi.get(requestParams || {})
				dispatch({
					type: 'FINISH_LOAD_FINANCIAL_ACCOUNTS',
					financialAccounts: transformFinancialAccounts(result) || EMPTY_ARRAY,
					key,
				})
			} catch (error) {
				dispatch({
					type: 'FINISH_LOAD_FINANCIAL_ACCOUNTS',
					serverError: error,
					key,
				})
			}
		}
	}
}

//HELPER function
export const transformFinancialAccounts: (Array<FinancialAccount>) => Array<FinancialAccount> = (
	financialAccounts: Array<FinancialAccount>,
) => {
	let result: Array<FinancialAccount> = []
	financialAccounts.forEach((acc: FinancialAccount) => {
		if (acc.category) {
			const resultIndex: number = result.findIndex((a: FinancialAccount) => a.category === acc.category)
			if (resultIndex > -1) {
				result[resultIndex].subFinAccounts && result[resultIndex].subFinAccounts.push(acc)
			} else {
				result.push({
					name: acc.name,
					subFinAccounts: [acc],
					category: acc.category,
				})
			}
		} else {
			result.push(acc)
		}
	})
	return result
}

export function loadOrganizationDetail(): ActionFunction<OrganizationAction> {
	return async (dispatch: Dispatch<OrganizationAction>) => {
		dispatch({
			type: 'START_LOAD_ORGANIZATION_DETAIL',
		})
		try {
			const organization: Organization = await organizationApi.get({})
			return dispatch({
				type: 'FINISH_LOAD_ORGANIZATION_DETAIL',
				organization,
				success: true,
			})
		} catch (error) {
			return dispatch({
				type: 'FINISH_LOAD_ORGANIZATION_DETAIL',
				organization: null,
				success: false,
				serverError: error,
			})
		}
	}
}

export function loadCurrenciesUsedInOrg(): ActionFunction<OrganizationAction> {
	return async (dispatch: Dispatch<OrganizationAction>, getState: () => State) => {
		const state = getState()
		const orgId = state.user.currentOrganizationId

		if (orgId && needsRefresh('currenciesUsedInOrg' + orgId)) {
			dispatch({
				type: 'START_LOAD_CURRENCIES_USED_IN_ORG',
			})

			try {
				const result: Array<Currency> = await currenciesApi.get({
					usedInOrg: true,
				})
				dispatch({
					type: 'FINISH_LOAD_CURRENCIES_USED_IN_ORG',
					currencies: result,
				})
			} catch (error) {
				clearLastFetchedTime('currenciesUsedInOrg' + orgId)
				dispatch({
					type: 'FINISH_LOAD_CURRENCIES_USED_IN_ORG',
					currencies: null,
					serverError: error,
				})
			}
		}
	}
}

export function changeOrganizationAddress(address: OrganizationAddress): ActionFunction<OrganizationAction> {
	return async (dispatch: Dispatch<OrganizationAction>, getState: () => State) => {
		const state: State = getState()
		const detail: ?Organization = state.organization.organizationDetail.data

		const oldAddresses: ?Array<OrganizationAddress> = detail && detail.addresses
		const index: number = oldAddresses
			? oldAddresses.findIndex((a: OrganizationAddress) => a.type === address.type)
			: -1
		const oldAddress: ?OrganizationAddress = oldAddresses ? oldAddresses[index] : null

		let newAddresses: Array<OrganizationAddress> = []
		if (index > -1 && oldAddresses) {
			newAddresses = [...oldAddresses]
			newAddresses[index] = address
		} else {
			newAddresses = [...(oldAddresses || []), address]
		}
		//HACK: json-patch-gen crashes if on left side is missing property and on right side is this property undefined
		if (address.hasOwnProperty('emailSender') && oldAddress && !oldAddress.hasOwnProperty('emailSender')) {
			oldAddress.emailSender = undefined
		}

		dispatch({
			type: 'START_CHANGING_ORGANIZATION_ADDRESS',
			newAddresses,
		})

		try {
			const patch = diff(oldAddress || { type: address.type }, address)
			if (oldAddress) {
				await organizationAddressApi.patch({ addressType: parseInt(address.type || 0) }, patch)
			} else {
				await organizationAddressApi.put({ addressType: parseInt(address.type || 0) }, address)
			}

			dispatch({
				type: 'FINISH_CHANGING_ORGANIZATION_ADDRESS',
				newAddresses,
				oldAddresses: null,
			})
		} catch (serverError) {
			dispatch({
				type: 'FINISH_CHANGING_ORGANIZATION_ADDRESS',
				serverError,
				oldAddresses,
				newAddresses: null,
			})
		}
	}
}

export function removeOrganizationAddress(address: OrganizationAddress): ActionFunction<OrganizationAction> {
	return async (dispatch: Dispatch<OrganizationAction>, getState: () => State) => {
		const state: State = getState()
		const detail: ?Organization = state.organization.organizationDetail.data

		const oldAddresses: ?Array<OrganizationAddress> = detail && detail.addresses

		let newAddresses: ?Array<OrganizationAddress> =
			oldAddresses && oldAddresses.filter((a: OrganizationAddress) => a.type !== address.type)

		dispatch({
			type: 'START_REMOVING_ORGANIZATION_ADDRESS',
			newAddresses,
		})

		try {
			await organizationAddressApi.delete({ addressType: parseInt(address.type || 0) })
			dispatch({
				type: 'FINISH_REMOVING_ORGANIZATION_ADDRESS',
				newAddresses,
				oldAddresses: null,
			})
		} catch (serverError) {
			dispatch({
				type: 'FINISH_REMOVING_ORGANIZATION_ADDRESS',
				serverError,
				oldAddresses,
				newAddresses: null,
			})
		}
	}
}

export function loadFavoriteSection(section: string): ActionFunction<OrganizationAction> {
	return async (dispatch: Dispatch<OrganizationAction>, getState: () => State) => {
		if (isFavoritesLoaded(getState(), section)) {
			return
		}

		dispatch({
			type: 'START_LOAD_FAVORITE_SECTION',
			section,
		})
		try {
			const keys: Array<string> = await favoritesSectionApi.get({
				section,
			})
			dispatch({
				type: 'FINISH_LOAD_FAVORITE_SECTION',
				section,
				keys,
				success: true,
			})
		} catch (error) {
			dispatch({
				type: 'FINISH_LOAD_FAVORITE_SECTION',
				section,
				keys: [],
				success: false,
				serverError: error,
			})
		}
	}
}

export function createFavoriteItem(section: string, key: string): ActionFunction<OrganizationAction> {
	return async (dispatch: Dispatch<OrganizationAction>) => {
		dispatch({
			type: 'START_CREATE_FAVORITE_ITEM',
			section,
			key,
		})
		try {
			await favoritesApi.put({ section, key })
			dispatch({
				type: 'FINISH_CREATE_FAVORITE_ITEM',
				section,
				key,
				success: true,
			})
		} catch (error) {
			dispatch({
				type: 'FINISH_CREATE_FAVORITE_ITEM',
				section,
				key,
				success: false,
				serverError: error,
			})
		}
	}
}

export function removeFavoriteItem(section: string, key: string): ActionFunction<OrganizationAction> {
	return async (dispatch: Dispatch<OrganizationAction>) => {
		dispatch({
			type: 'START_REMOVE_FAVORITE_ITEM',
			section,
			key,
		})
		try {
			await favoritesApi.delete({ section, key })
			dispatch({
				type: 'FINISH_REMOVE_FAVORITE_ITEM',
				section,
				key,
				success: true,
			})
		} catch (error) {
			dispatch({
				type: 'FINISH_REMOVE_FAVORITE_ITEM',
				section,
				key,
				success: false,
				serverError: error,
			})
		}
	}
}

export function changeUserMembership(
	userId: string,
	internalMembershipType: number,
): ActionFunction<OrganizationAction> {
	return async (dispatch: Dispatch<OrganizationAction>, getState: () => State) => {
		const state: State = getState()
		const mainAccountant = getCurrentOrganizationAccountant(state)
		const payrollAccountant = getCurrentOrganizationWagesAccountant(state)
		const mainAccountantId = (mainAccountant && mainAccountant.id) || null
		const payrollAccountantId = (payrollAccountant && payrollAccountant.id) || null

		dispatch({ type: 'START_CHANGING_MEMBERSHIP' })
		try {
			const result: InternalMembership = await internalUserMembershipApi.put(
				{ userId: parseInt(userId) },
				{ internalMembershipType },
			)
			await dispatch({
				type: 'FINISH_CHANGING_MEMBERSHIP',
				membershipType: result.internalMembershipType,
				userId,
			})

			const newMainAccountant = getCurrentOrganizationAccountant(state)
			const newPayrollAccountant = getCurrentOrganizationWagesAccountant(state)
			const newMainAccountantId =
				2 === result.internalMembershipType ? userId : (newMainAccountant && newMainAccountant.id) || null
			const newMainAccountantName =
				newMainAccountant && newMainAccountant.firstname && newMainAccountant.lastname
					? `${newMainAccountant.firstname} ${newMainAccountant.lastname}`
					: null
			const newPayrollAccountantId =
				4 === result.internalMembershipType ? userId : (newPayrollAccountant && newPayrollAccountant.id) || null
			const newPayrollAccountantName =
				newPayrollAccountant && newPayrollAccountant.firstname && newPayrollAccountant.lastname
					? `${newPayrollAccountant.firstname} ${newPayrollAccountant.lastname}`
					: null

			if (mainAccountantId !== newMainAccountantId && newMainAccountantName)
				Tracking.changeMainAccountant(newMainAccountantId, newMainAccountantName)
			if (payrollAccountantId !== newPayrollAccountantId && newPayrollAccountantName)
				Tracking.changePayrollAccountant(newPayrollAccountantId, newPayrollAccountantName)
		} catch (serverError) {
			dispatch({
				type: 'FINISH_CHANGING_MEMBERSHIP',
				serverError,
				membershipType: null,
				userId,
			})
		}
	}
}

export function unassignUserFromOrganization(userId: string): ActionFunction<OrganizationAction> {
	return async (dispatch: Dispatch<OrganizationAction>, getState: () => State) => {
		const state: State = getState()
		const mainAccountant = getCurrentOrganizationAccountant(state)
		const payrollAccountant = getCurrentOrganizationWagesAccountant(state)
		dispatch({ type: 'START_UNASSIGN_USER_FROM_ORGANIZATION' })
		try {
			await internalUserMembershipApi.delete({ userId: parseInt(userId) })
			dispatch({
				type: 'FINISH_UNASSIGN_USER_FROM_ORGANIZATION',
				userId,
			})
			if (mainAccountant && mainAccountant.id === userId) Tracking.changeMainAccountant(null)
			if (payrollAccountant && payrollAccountant.id === userId) Tracking.changePayrollAccountant(null)
		} catch (serverError) {
			dispatch({
				type: 'FINISH_UNASSIGN_USER_FROM_ORGANIZATION',
				serverError,
				userId: null,
			})
		}
	}
}

export function loadOrganizationSignAuthorizedPersons(): ActionFunction<OrganizationAction> {
	return async (dispatch: Dispatch<OrganizationAction>) => {
		dispatch({
			type: 'START_LOADING_ORGANIZATION_SIGN_AUTHORIZED_PERSONS',
		})
		try {
			let signAuthorized: Array<OrganizationPersonAuthorizedToSign> = []
			signAuthorized = await organizationSignAuthorizedPersonsApi.get({})
			dispatch({
				type: 'FINISH_LOADING_ORGANIZATION_SIGN_AUTHORIZED_PERSONS',
				signAuthorized,
			})
		} catch (serverError) {
			dispatch({
				type: 'FINISH_LOADING_ORGANIZATION_SIGN_AUTHORIZED_PERSONS',
				serverError,
				signAuthorized: null,
			})
		}
	}
}

export function addOrganizationSignAuthorizedPerson(
	signAuthorized: OrganizationPersonAuthorizedToSign,
): ActionFunction<OrganizationAction> {
	return async (dispatch: Dispatch<OrganizationAction>) => {
		dispatch({
			type: 'START_ADDING_ORGANIZATION_SIGN_AUTHORIZED_PERSON',
		})
		try {
			const result: OrganizationPersonAuthorizedToSign = await organizationSignAuthorizedPersonsApi.post(
				{},
				signAuthorized,
			)
			dispatch({
				type: 'FINISH_ADDING_ORGANIZATION_SIGN_AUTHORIZED_PERSON',
				person: result,
			})
		} catch (serverError) {
			dispatch({
				type: 'FINISH_ADDING_ORGANIZATION_SIGN_AUTHORIZED_PERSON',
				serverError,
				person: null,
			})
		}
	}
}

export function updateOrganizationSignAuthorizedPerson(
	personAuthorizedToSignId: string,
	newPerson: OrganizationPersonAuthorizedToSign,
): ActionFunction<OrganizationAction> {
	return async (dispatch: Dispatch<OrganizationAction>, getState: () => State) => {
		const state: State = getState()
		const orgDetail = state.organization.organizationDetail
		const oldSignAuthorized: ?Array<OrganizationPersonAuthorizedToSign> = orgDetail && orgDetail.signAuthorized

		if (!oldSignAuthorized) {
			return
		}

		const newSignAuthorized: ?Array<OrganizationPersonAuthorizedToSign> = oldSignAuthorized.map(
			(sa: OrganizationPersonAuthorizedToSign) => {
				return sa.id === personAuthorizedToSignId ? newPerson : sa
			},
		)

		dispatch({
			type: 'START_UPDATING_ORGANIZATION_SIGN_AUTHORIZED_PERSON',
			newSignAuthorized,
		})
		try {
			await organizationSignAuthorizedPersonApi.put({ personAuthorizedToSignId }, newPerson)
			dispatch({
				type: 'FINISH_UPDATING_ORGANIZATION_SIGN_AUTHORIZED_PERSON',
				newSignAuthorized,
				oldSignAuthorized: null,
			})
		} catch (serverError) {
			dispatch({
				type: 'FINISH_UPDATING_ORGANIZATION_SIGN_AUTHORIZED_PERSON',
				serverError,
				newSignAuthorized: null,
				oldSignAuthorized,
			})
		}
	}
}

export function deleteOrganizationSignAuthorizedPerson(
	personAuthorizedToSignId: string,
): ActionFunction<OrganizationAction> {
	return async (dispatch: Dispatch<OrganizationAction>, getState: () => State) => {
		const state: State = getState()
		const orgDetail = state.organization.organizationDetail
		const oldSignAuthorized: ?Array<OrganizationPersonAuthorizedToSign> = orgDetail && orgDetail.signAuthorized

		if (!oldSignAuthorized) {
			return
		}

		const newSignAuthorized: ?Array<OrganizationPersonAuthorizedToSign> = oldSignAuthorized.filter(
			(sa: OrganizationPersonAuthorizedToSign) => sa.id !== personAuthorizedToSignId,
		)

		dispatch({
			type: 'START_DELETING_ORGANIZATION_SIGN_AUTHORIZED_PERSON',
			newSignAuthorized,
		})
		try {
			await organizationSignAuthorizedPersonApi.delete({ personAuthorizedToSignId })
			dispatch({
				type: 'FINISH_DELETING_ORGANIZATION_SIGN_AUTHORIZED_PERSON',
				newSignAuthorized,
				oldSignAuthorized: null,
			})
		} catch (serverError) {
			dispatch({
				type: 'FINISH_DELETING_ORGANIZATION_SIGN_AUTHORIZED_PERSON',
				serverError,
				newSignAuthorized: null,
				oldSignAuthorized,
			})
		}
	}
}

export function loadOrganizationCreators(): ActionFunction<OrganizationAction> {
	return async (dispatch: Dispatch<OrganizationAction>) => {
		dispatch({
			type: 'START_LOADING_ORGANIZATION_CREATORS',
		})
		try {
			const creators: Array<OrganizationCreator> = await organizationCreatorsApi.get({})

			dispatch({
				type: 'FINISH_LOADING_ORGANIZATION_CREATORS',
				creators,
			})
		} catch (serverError) {
			dispatch({
				type: 'FINISH_LOADING_ORGANIZATION_CREATORS',
				serverError,
				creators: null,
			})
		}
	}
}

export function deleteOrganizationCreator(organizationCreatorId: string): ActionFunction<OrganizationAction> {
	return async (dispatch: Dispatch<OrganizationAction>, getState: () => State) => {
		const state: State = getState()
		const orgDetail = state.organization.organizationDetail
		const oldCreators: ?Array<OrganizationCreator> = orgDetail && orgDetail.creators

		if (!oldCreators) {
			return
		}

		const newCreators: ?Array<OrganizationCreator> = oldCreators.filter(
			(c: OrganizationCreator) => c.id !== organizationCreatorId,
		)

		dispatch({
			type: 'START_DELETING_ORGANIZATION_CREATORS',
			newCreators,
		})
		try {
			await organizationCreatorApi.delete({ organizationCreatorId })
			dispatch({
				type: 'FINISH_DELETING_ORGANIZATION_CREATORS',
				newCreators,
				oldCreators: null,
			})
		} catch (serverError) {
			dispatch({
				type: 'FINISH_DELETING_ORGANIZATION_CREATORS',
				serverError,
				newCreators: null,
				oldCreators,
			})
		}
	}
}

export function addOrganizationCreator(creator: OrganizationCreator): ActionFunction<OrganizationAction> {
	return async (dispatch: Dispatch<OrganizationAction>) => {
		dispatch({
			type: 'START_ADDING_ORGANIZATION_CREATOR',
		})
		try {
			const result: OrganizationCreator = await organizationCreatorsApi.post({}, creator)
			dispatch({
				type: 'FINISH_ADDING_ORGANIZATION_CREATOR',
				creator: result,
			})
		} catch (serverError) {
			dispatch({
				type: 'FINISH_ADDING_ORGANIZATION_CREATOR',
				serverError,
				creator: null,
			})
		}
	}
}

export function updateOrganizationCreator(
	organizationCreatorId: string,
	creator: OrganizationCreator,
): ActionFunction<OrganizationAction> {
	return async (dispatch: Dispatch<OrganizationAction>, getState: () => State) => {
		const state: State = getState()
		const orgDetail = state.organization.organizationDetail
		const oldCreators: ?Array<OrganizationCreator> = orgDetail && orgDetail.creators

		if (!oldCreators) {
			return
		}

		const newCreators: ?Array<OrganizationCreator> = oldCreators.map((c: OrganizationCreator) => {
			return c.id === organizationCreatorId ? creator : c
		})

		dispatch({
			type: 'START_UPDATING_ORGANIZATION_CREATOR',
			newCreators,
		})
		try {
			await organizationCreatorApi.put({ organizationCreatorId }, creator)
			dispatch({
				type: 'FINISH_UPDATING_ORGANIZATION_CREATOR',
				newCreators,
				oldCreators: null,
			})
		} catch (serverError) {
			dispatch({
				type: 'FINISH_UPDATING_ORGANIZATION_CREATOR',
				serverError,
				newCreators: null,
				oldCreators,
			})
		}
	}
}

export function loadOrganizationTaxRepresentatives(): ActionFunction<OrganizationAction> {
	return async (dispatch: Dispatch<OrganizationAction>) => {
		dispatch({
			type: 'START_LOADING_ORGANIZATION_TAX_REPRESENTATIVES',
		})
		try {
			let taxRepresentatives: Array<OrganizationTaxRepresentative> = []
			taxRepresentatives = await organizationTaxRepresentativesApi.get({})
			dispatch({
				type: 'FINISH_LOADING_ORGANIZATION_TAX_REPRESENTATIVES',
				taxRepresentatives,
			})
		} catch (serverError) {
			dispatch({
				type: 'FINISH_LOADING_ORGANIZATION_TAX_REPRESENTATIVES',
				serverError,
				taxRepresentatives: null,
			})
		}
	}
}

export function addOrganizationTaxRepresentative(
	taxRepresentative: OrganizationTaxRepresentative,
): ActionFunction<OrganizationAction> {
	return async (dispatch: Dispatch<OrganizationAction>) => {
		dispatch({
			type: 'START_ADDING_ORGANIZATION_TAX_REPRESENTATIVE',
		})
		try {
			const result: OrganizationTaxRepresentative = await organizationTaxRepresentativesApi.post({}, taxRepresentative)
			dispatch({
				type: 'FINISH_ADDING_ORGANIZATION_TAX_REPRESENTATIVE',
				taxRepresentative: result,
			})
		} catch (serverError) {
			dispatch({
				type: 'FINISH_ADDING_ORGANIZATION_TAX_REPRESENTATIVE',
				serverError,
				taxRepresentative: null,
			})
		}
	}
}

export function updateOrganizationTaxRepresentative(
	taxRepresentativeId: string,
	taxRepresentative: OrganizationTaxRepresentative,
): ActionFunction<OrganizationAction> {
	return async (dispatch: Dispatch<OrganizationAction>, getState: () => State) => {
		const state: State = getState()
		const orgDetail = state.organization.organizationDetail
		const oldTaxRepresentatives: ?Array<OrganizationTaxRepresentative> = orgDetail && orgDetail.taxRepresentatives

		if (!oldTaxRepresentatives) {
			return
		}

		const newTaxRepresentatives: ?Array<OrganizationTaxRepresentative> = oldTaxRepresentatives.map(
			(tr: OrganizationTaxRepresentative) => {
				return tr.id === taxRepresentativeId ? taxRepresentative : tr
			},
		)

		dispatch({
			type: 'START_UPDATING_ORGANIZATION_TAX_REPRESENTATIVE',
			newTaxRepresentatives,
		})
		try {
			await organizationTaxRepresentativeApi.put({ taxRepresentativeId }, taxRepresentative)
			dispatch({
				type: 'FINISH_UPDATING_ORGANIZATION_TAX_REPRESENTATIVE',
				newTaxRepresentatives,
				oldTaxRepresentatives: null,
			})
		} catch (serverError) {
			dispatch({
				type: 'FINISH_UPDATING_ORGANIZATION_TAX_REPRESENTATIVE',
				serverError,
				newTaxRepresentatives: null,
				oldTaxRepresentatives,
			})
		}
	}
}

export function deleteOrganizationTaxRepresentative(taxRepresentativeId: string): ActionFunction<OrganizationAction> {
	return async (dispatch: Dispatch<OrganizationAction>, getState: () => State) => {
		const state: State = getState()
		const orgDetail = state.organization.organizationDetail
		const oldTaxRepresentatives: ?Array<OrganizationTaxRepresentative> = orgDetail && orgDetail.taxRepresentatives

		if (!oldTaxRepresentatives) {
			return
		}

		let newTaxRepresentatives: ?Array<OrganizationTaxRepresentative> = []
		newTaxRepresentatives = oldTaxRepresentatives.filter(
			(tr: OrganizationTaxRepresentative) => tr.id !== taxRepresentativeId,
		)

		dispatch({
			type: 'START_DELETING_ORGANIZATION_TAX_REPRESENTATIVE',
			newTaxRepresentatives,
		})
		try {
			await organizationTaxRepresentativeApi.delete({ taxRepresentativeId })
			dispatch({
				type: 'FINISH_DELETING_ORGANIZATION_TAX_REPRESENTATIVE',
				newTaxRepresentatives,
				oldTaxRepresentatives: null,
			})
		} catch (serverError) {
			dispatch({
				type: 'FINISH_DELETING_ORGANIZATION_TAX_REPRESENTATIVE',
				newTaxRepresentatives: null,
				oldTaxRepresentatives,
				serverError,
			})
		}
	}
}

async function doLoadUsers(dispatch: Dispatch<OrganizationAction>) {
	try {
		const users: Array<UserProfile> = await usersApi.get({})
		dispatch({
			type: 'FINISH_LOAD_USERS',
			users,
		})
	} catch (error) {
		dispatch({
			type: 'FINISH_LOAD_USERS',
			users: null,
			serverError: error,
		})
	}
}
let loadUsersPromise = Promise.resolve()
export function loadUsers(): ActionFunction<OrganizationAction> {
	return async (dispatch: Dispatch<OrganizationAction>, getState: () => State) => {
		if (isCurrentOrganizationUsersLoading(getState())) {
			return loadUsersPromise
		}

		dispatch({
			type: 'START_LOAD_USERS',
		})
		loadUsersPromise = doLoadUsers(dispatch)
		return loadUsersPromise
	}
}

export function loadInvitations(): ActionFunction<OrganizationAction> {
	return async (dispatch: Dispatch<OrganizationAction>, getState: () => State) => {
		if (isCurrentOrganizationInvitationsLoading(getState())) {
			return
		}

		dispatch({
			type: 'START_LOAD_INVITATIONS',
		})
		try {
			const invitations: Array<Invitation> = await invitationsApi.get({})

			dispatch({
				type: 'FINISH_LOAD_INVITATIONS',
				invitations,
			})
		} catch (error) {
			dispatch({
				type: 'FINISH_LOAD_INVITATIONS',
				invitations: null,
				serverError: error,
			})
		}
	}
}

export function inviteUser(invitationRequest: InvitationRequest): ActionFunction<OrganizationAction> {
	return async (dispatch: Dispatch<OrganizationAction>) => {
		dispatch({
			type: 'START_INVITATION_SENDING',
			invitationRequest,
		})
		try {
			await invitationsApi.post({}, invitationRequest)
			return dispatch({
				type: 'FINISH_INVITATION_SENDING',
			})
		} catch (serverError) {
			return dispatch({
				type: 'FINISH_INVITATION_SENDING',
				serverError,
			})
		}
	}
}

export function getInboundEmails(): ActionFunction<OrganizationAction> {
	return async (dispatch: Dispatch<OrganizationAction>) => {
		dispatch({
			type: 'START_GET_INBOUND_EMAILS',
		})
		try {
			const response: EmailListResponse = await inboundEmailsApi.get({})
			return dispatch({
				type: 'FINISH_GET_INBOUND_EMAILS',
				response,
			})
		} catch (serverError) {
			return dispatch({
				type: 'FINISH_GET_INBOUND_EMAILS',
				response: null,
				serverError,
			})
		}
	}
}

export function createOrganization(data: OrganizationCreateRequest): ActionFunction<?OrganizationAction> {
	return async (dispatch: Dispatch<OrganizationAction>): Promise<?OrganizationAction> => {
		dispatch({
			type: 'START_CREATE_ORGANIZATION',
		})
		try {
			const organization: Organization = await organizationsApi.post(data)
			return dispatch({
				type: 'FINISH_CREATE_ORGANIZATION',
				organization,
			})
		} catch (serverError) {
			return dispatch({
				type: 'FINISH_CREATE_ORGANIZATION',
				serverError,
			})
		}
	}
}

export function removeOrganization(organizationId?: string): ActionFunction<?OrganizationAction> {
	return async (dispatch: Dispatch<OrganizationAction>): Promise<OrganizationAction> => {
		dispatch({
			type: 'START_DELETE_ORGANIZATION',
			organizationId,
		})
		try {
			await organizationApi.delete({ organizationId })
			return dispatch({
				type: 'FINISH_DELETE_ORGANIZATION',
			})
		} catch (serverError) {
			return dispatch({
				type: 'FINISH_DELETE_ORGANIZATION',
				serverError,
			})
		}
	}
}

export function loadCurrentOrganizationProcessingIssues(): ActionFunction<?OrganizationAction> {
	return async (dispatch: Dispatch<OrganizationAction>, getState: () => State): Promise<?OrganizationAction> => {
		const state: State = getState()
		const currentOrganizationId: ?string = state.user.currentOrganizationId

		if (!currentOrganizationId) {
			return
		}

		dispatch({
			type: 'START_LOADING_PROCESSING_ISSUES',
		})
		try {
			const resp: OrganizationProcessIssues = await processOrganizationIssuesApi.get({
				organizationId: currentOrganizationId,
			})
			return dispatch({
				type: 'FINISH_LOADING_PROCESSING_ISSUES',
				issues: resp.issues,
			})
		} catch (serverError) {
			return dispatch({
				type: 'FINISH_LOADING_PROCESSING_ISSUES',
				serverError,
			})
		}
	}
}

export function removeUserInvitations(userId: string): ActionFunction<OrganizationAction> {
	return async (dispatch: Dispatch<OrganizationAction>, getState: () => State) => {
		const state: State = getState()
		const currentOrganizationId: ?string = getCurrentOrganizationId(state)

		if (!currentOrganizationId) {
			return
		}

		const params: UserInvitationsByUserDeleteParams = { organizationId: currentOrganizationId, userId: userId }

		dispatch({
			type: 'START_DELETE_USER_INVITATIONS',
			params,
		})
		try {
			await userInvitationsByUserApi.delete(params)
			return dispatch({
				type: 'FINISH_DELETE_USER_INVITATIONS',
			})
		} catch (serverError) {
			return dispatch({
				type: 'FINISH_DELETE_USER_INVITATIONS',
				serverError,
			})
		}
	}
}

export function removeInvitation(email: string): ActionFunction<OrganizationAction> {
	return async (dispatch: Dispatch<OrganizationAction>, getState: () => State) => {
		const state: State = getState()
		const currentOrganizationId: ?string = getCurrentOrganizationId(state)

		if (!currentOrganizationId) {
			return
		}

		const params: UserInvitationsByEmailDeleteParams = { organizationId: currentOrganizationId, email: email }

		dispatch({
			type: 'START_DELETE_INVITATION',
			params,
		})
		try {
			await userInvitationsByEmailApi.delete(params)
			return dispatch({
				type: 'FINISH_DELETE_INVITATION',
			})
		} catch (serverError) {
			return dispatch({
				type: 'FINISH_DELETE_INVITATION',
				serverError,
			})
		}
	}
}

export function loadUserAvatar(avatarId: string): ActionFunction<OrganizationAction> {
	return async (dispatch: Dispatch<OrganizationAction>) => {
		try {
			const userAvatar: ?Blob = await avatar.get({ avatarId })
			const base64 = userAvatar && (await convertBlobToBase64(userAvatar))
			return dispatch({
				type: 'FINISH_LOAD_USER_AVATAR',
				userId: avatarId,
				avatar: base64,
				success: true,
			})
		} catch (error) {
			return dispatch({
				type: 'FINISH_LOAD_USER_AVATAR',
				userId: avatarId,
				avatar: null,
				success: false,
			})
		}
	}
}
