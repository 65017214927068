// @flow

import React, { Component } from 'react'
import { TriviLink } from 'components'
import { showAccountingDocumentRoute } from 'modules/accounting-document/routing/routes'
import { accountingDocumentNumber } from 'helpers'
import type { RelatedAccountingDocumentInfo } from 'types'

type Props = {|
	item: RelatedAccountingDocumentInfo,
	onClick: (accountingDocumentId: string) => void,
|}

export default class RelatedAccountingDocument extends Component<Props> {
	static defaultProps = {
		onClick: () => {},
	}

	handleClick = () => {
		const item = this.props.item

		if (item.accountingDocumentId != null) {
			this.props.onClick(item.accountingDocumentId)
		}
	}

	render() {
		const item = this.props.item

		return (
			<TriviLink
				routeFn={item.accountingDocumentId ? showAccountingDocumentRoute : undefined}
				routeParams={[item.accountingDocumentId ? item.accountingDocumentId : '', item.organizationId]}
				onClick={this.handleClick}
			>
				{accountingDocumentNumber(item)}
			</TriviLink>
		)
	}
}
