/* @flow */

import React, { Component } from 'react'
import deepEqual from 'deep-equal'
import { connect } from 'react-redux'
import { withNotify, withTranslate, type WithTranslateProps, type WithNotifyProps } from 'wrappers'
import { showServerError, clearValidationError } from 'modules/common/actions'
import type { ValidationError, ValidationFieldError, State, Dispatch, CommonAction } from 'types'
import { SILENT_MESSAGE_CODE } from 'trivi-constants'

export type ValidateFormType = (errors: ?{ [string]: any }) => void
export type ValidationMessageType = (id: string, customMessageFn: ?(code: string, params: any) => ?string) => ?string
export type ValidationValueType = <T>(id: string, value: T, transformFn?: (T) => any) => T

export type FormValidationProps = {|
	globalValidationMessage: (customMessageFn: ?(code: string, params: any) => ?string) => ?string,
	validateForm: ValidateFormType,
	isFormValid: () => boolean,
	validationSubmit: <A: Array<any>, R>(
		handler: (...args: A) => R,
		errorMessage: ?string,
		invalidHandler?: () => void,
	) => (...args: A) => ?R,
	validationProps: () => FormValidationProps,
	validationValue: <T>(id: string, value: T, transformFn?: (T) => any) => T,
	validationMessage: (id: string, customMessageFn: ?(code: string, params: any) => ?string) => ?string,
	resetValidation: () => void,
|}

export const formValidationDefaults: FormValidationProps = {
	globalValidationMessage: () => {},
	validateForm: () => {},
	isFormValid: () => false,
	validationSubmit: <A: Array<any>, R>(handler: (...args: A) => R): ((...args: A) => ?R) => (...args: A) =>
		handler.apply(null, args),
	validationProps: () => formValidationDefaults,
	validationValue: (id: string, value: any) => value,
	validationMessage: () => {},
	resetValidation: () => {},
}

type StateProps = {|
	validationError: ?ValidationError,
|}

type DispatchProps = {|
	onUnhandledError: ValidationError => void,
	clearValidationError: () => void,
|}

/**
 * Documentation: https://gitlab.trivi.local/server-team/Trivi.Portal/wikis/Frontend/Validace-Formul%C3%A1%C5%99%C5%AF
 */
export default function validate(type: string | Array<string> | void) {
	return <P, C: React$ComponentType<P>>(
		WrappedComponent: C,
	): Class<Component<$Diff<React$ElementConfig<C>, FormValidationProps>, any>> => {
		class ValidationErrorHandler extends Component<{|
			...React$ElementConfig<C>,
			...StateProps,
			...DispatchProps,
			...WithTranslateProps,
			...WithNotifyProps,
		|}> {
			registeredGlobal: boolean = false
			registeredFields: { [string]: any } = {}
			serverErrors: { [string]: ValidationFieldError } = {}
			clientErrors: { [string]: string } = {}
			initialValues: { [string]: any } = {}
			dirtyFields: { [string]: boolean } = {}

			globalValidationMessage = (customMessageFn: ?(code: string, params: any) => ?string): ?string => {
				this.registeredGlobal = true
				if (this.props.validationError) {
					const validationError: ValidationError = this.props.validationError
					const customMessage = customMessageFn && customMessageFn(validationError.code || '', validationError.params)

					if (customMessage) {
						return customMessage
					}

					return this.props.t(
						['serverError.' + (validationError.code || ''), validationError.message || 'serverError.fe0001'],
						validationError.params,
					)
				}
			}

			resetValidation = () => {
				this.registeredGlobal = false
				this.registeredFields = {}
				this.dirtyFields = {}
				this.initialValues = {}
				this.clientErrors = {}
				this.serverErrors = {}
			}

			validationValue = <T>(id: string, value: T, transformFn?: T => any): T => {
				if (this.initialValues.hasOwnProperty(id)) {
					if (transformFn) {
						if (transformFn(this.initialValues[id]) !== transformFn(value)) {
							this.dirtyFields[id] = true
						}
					} else if (!deepEqual(this.initialValues[id], value)) {
						this.dirtyFields[id] = true
					}
				} else {
					this.dirtyFields[id] = false
					this.initialValues[id] = value
				}
				return value
			}

			validationMessage = (id: string, customMessageFn: ?(code: string, params: any) => ?string): ?string => {
				this.registeredFields = this.registeredFields || {}
				this.registeredFields[id] = true
				if (this.clientErrors[id] && this.dirtyFields[id] !== false) {
					return this.clientErrors[id]
				}

				if (this.serverErrors[id]) {
					const field = this.serverErrors[id]
					const customMessage = customMessageFn && customMessageFn(field.code || '', field.params)

					if (customMessage) {
						return customMessage
					}

					return this.props.t(
						['serverError.' + (field.code || ''), field.message || 'serverError.fe0001'],
						field.params,
					)
				}
			}

			validationSubmit = <A: Array<any>, R>(
				handler: (...args: A) => R,
				errorMessage: ?string,
				invalidHandler?: () => void,
			): ((...args: A) => ?R) => {
				return (...args: A) => {
					if (this.isFormValid()) {
						return handler.apply(null, args)
					} else {
						errorMessage && this.props.notify(errorMessage, 'error')
						this.dirtyFields = {}
						if (invalidHandler) {
							invalidHandler()
						}
						this.forceUpdate()
					}
				}
			}

			validateForm = (errors: ?{ [string]: string }): void => {
				this.registeredFields = this.registeredFields || {}
				this.clientErrors = errors || {}
			}

			isFormValid = (): boolean => Object.getOwnPropertyNames(this.clientErrors).length === 0

			validationProps = (): FormValidationProps => ({
				globalValidationMessage: this.globalValidationMessage,
				validationValue: this.validationValue,
				validationMessage: this.validationMessage,
				validationSubmit: this.validationSubmit,
				validateForm: this.validateForm,
				isFormValid: this.isFormValid,
				validationProps: this.validationProps,
				resetValidation: this.resetValidation,
			})

			constructor(props: StateProps & DispatchProps & WithTranslateProps) {
				super(props)
				this.updateServerErrors(props.validationError)
			}

			UNSAFE_componentWillReceiveProps(nextProps: StateProps & DispatchProps & WithTranslateProps) {
				if (this.props.validationError !== nextProps.validationError) {
					this.updateServerErrors(nextProps.validationError)
				}
			}

			componentDidMount() {
				if (this.props.validationError) {
					this.props.clearValidationError()
				}
			}

			componentWillUnmount() {
				if (this.props.validationError) {
					this.props.clearValidationError()
				}
			}

			transformFieldName = (fieldName: string) => {
				return fieldName[0].toLowerCase() + fieldName.substring(1)
			}

			updateServerErrors(validationError: ?ValidationError) {
				this.serverErrors = {}

				if (validationError && validationError.fieldsAffected && validationError.fieldsAffected.length > 0) {
					validationError.fieldsAffected.forEach((field: ValidationFieldError) => {
						if (field.name && field.message) {
							this.serverErrors[this.transformFieldName(field.name)] = field
						}
					}, this)
				}
			}

			render() {
				const { validationError, onUnhandledError, clearValidationError, ...rest } = this.props

				return <WrappedComponent {...rest} {...this.validationProps()} />
			}

			componentDidUpdate(prevProps: StateProps & DispatchProps & WithTranslateProps) {
				if (this.props.validationError && prevProps.validationError !== this.props.validationError) {
					if (this.props.validationError.fieldsAffected && this.props.validationError.fieldsAffected.length > 0) {
						const unshownError: ValidationError = {
							code: this.props.validationError.code,
							message: this.props.validationError.message,
							params: this.props.validationError.params,
							fieldsAffected: this.props.validationError.fieldsAffected.filter(
								(field: ValidationFieldError) =>
									field.name && !this.registeredFields[this.transformFieldName(field.name)],
							),
						}
						if (unshownError.fieldsAffected) {
							this.props.onUnhandledError(unshownError)
						}
					} else if (!this.registeredGlobal) {
						this.props.onUnhandledError(this.props.validationError)
					}
				}
			}
		}

		return connect(mapStateToProps, mapDispatchToProps)(withNotify(withTranslate(ValidationErrorHandler)))
	}

	function mapStateToProps(state: State): StateProps {
		const types = Array.isArray(type) ? type : type ? [type] : []
		return {
			validationError:
				state.common.validationError && types.indexOf(state.common.validationError.type) !== -1
					? state.common.validationError.data
					: null,
		}
	}

	function mapDispatchToProps(dispatch: Dispatch<CommonAction>): DispatchProps {
		return {
			onUnhandledError: (error: ValidationError) => {
				if (error.code !== SILENT_MESSAGE_CODE) {
					dispatch(showServerError(error))
				}
			},
			clearValidationError: () => {
				dispatch(clearValidationError())
			},
		}
	}
}
