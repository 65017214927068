/* @flow */

import { connect } from 'react-redux'
import type { Signature, Signatures, State } from 'types'
import type { Action as SignatureAction } from 'modules/settings/actions/signature-action-types'
import { listSignatures } from 'modules/settings/actions'
import withAutoload from 'wrappers/with-autoload'
import { getSignatureList } from 'modules/settings/selectors'
import SignaturePicker from '../components/signature-picker'

export type Props = {|
	value: ?string,
	readonly?: boolean,
	onChange?: (signature: ?Signature) => void,
|}

type StateProps = {|
	signatures: ?Signatures,
|}

const mapStateToProps = (state: State): StateProps => {
	return {
		signatures: getSignatureList(state),
	}
}

type DispatchProps = {|
	autoload: (props: Props & StateProps) => void,
|}

const mapDispatchToProps = (dispatch: Dispatch<SignatureAction>): DispatchProps => {
	return {
		autoload: (props: Props & StateProps) => {
			!props.signatures && dispatch(listSignatures({}))
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(withAutoload(SignaturePicker))
