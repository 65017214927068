/* @flow */

import type {
	AccountingDocumentBankAccount,
	AccountingDocumentContact,
	AddressBookAction,
	BankAccount,
	BankAction,
	Contact,
	ContactBankAccount,
	Dispatch,
	State,
} from 'types'
import {
	getAccountingDocumentAvailableBankAccounts,
	getAccountingDocumentContact,
	getAccountingDocumentContactBankAccounts,
	getIssuedAccountingDocumentBankAccounts,
} from 'modules/accounting-document/selectors'

import AddBankAccount from 'modules/accounting-document/components/invoice-elements/add-bank-account'
import { EMPTY_ARRAY } from 'trivi-constants'
import { connect } from 'react-redux'
import { getBankAccounts } from 'modules/bank/selectors'
import { getContact } from 'modules/address-book/selectors'
import { loadContact } from 'modules/address-book/actions'

export type OwnProps = {|
	accountingDocumentId: string,
	direction: 'issued' | 'received',
	readonly?: boolean,
	onBankAccountAttach: (bankAccount: AccountingDocumentBankAccount) => void,
	onBankAccountCreate?: (bankAccount?: AccountingDocumentBankAccount) => void,
	bankAccounts: Array<AccountingDocumentBankAccount>,
	buttonText?: string,
	hideWhenZeroAccounts?: boolean,
	isCreditNote?: boolean,
|}

type StateProps = {|
	availableBankAccounts: Array<AccountingDocumentBankAccount>,
	accountingDocumentContact: ?Contact,
	accountingDocumentContactId: ?string,
	organizationBankAccounts: Array<BankAccount>,
|}

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => {
	const c: ?AccountingDocumentContact = getAccountingDocumentContact(state, ownProps.accountingDocumentId)
	return {
		accountingDocumentContactId: c && c.contactId,
		accountingDocumentContact: c && c.contactId ? getContact(state, c.contactId) : null,
		organizationBankAccounts:
			ownProps.direction === 'issued' && ownProps.isCreditNote
				? getAccountingDocumentContactBankAccounts(state, ownProps).map<BankAccount>((x: ContactBankAccount) => ({
						id: x.id,
						name: x.name,
						bankId: x.id ?? '',
						bankName: x.name,
						currency: x.currency ?? '',
						accountNo: x.accountNo ?? '',
				  }))
				: getBankAccounts(state) || EMPTY_ARRAY,
		availableBankAccounts:
			ownProps.direction === 'issued'
				? ownProps.isCreditNote
					? getAccountingDocumentAvailableBankAccounts(state, ownProps)
					: getIssuedAccountingDocumentBankAccounts(state)
				: ownProps.isCreditNote
				? getIssuedAccountingDocumentBankAccounts(state)
				: getAccountingDocumentAvailableBankAccounts(state, ownProps),
	}
}

type DispatchProps = {|
	loadContact: (id: string) => void,
|}

const mapDispatchToProps = (dispatch: Dispatch<BankAction | AddressBookAction>): DispatchProps => {
	return {
		loadContact: (id: string) => {
			dispatch(loadContact(id, false, false))
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AddBankAccount)
