// @flow

import type { I18NextTranslateFn, OrganizationContractState } from 'types'
import { formatToDateString, convertToDate } from 'utils/formatters'

export function getInTriviUntil(t: I18NextTranslateFn, contractState?: ?OrganizationContractState): string | null {
	if (!contractState || !contractState.inTriviUntil) return null
	return t('settings.administration.processingUntilSnackbar', {
		inTriviUntil: formatToDateString(contractState.inTriviUntil),
	})
}

export function isContractTerminated(contractState?: ?OrganizationContractState): boolean {
	if (!contractState) return false
	return contractState.isTerminated || convertToDate(contractState.inTriviUntil || '') <= Date.now()
}
