//@flow

import type { Dispatch, Group, GroupUser, GroupsAction } from 'types'
import type {
	GroupDeleteParams,
	GroupGetParams,
	GroupPutParams,
	GroupUserPostParams,
	GroupUserRemoveDeleteParams,
} from 'modules/common/models/api-model'
import {
	group as groupApi,
	groupUser as groupUserApi,
	groupUserRemove as groupUserRemoveApi,
	groups as groupsApi,
} from 'modules/common/models/api-model'

export function removeUserFromGroup(params: GroupUserRemoveDeleteParams) {
	return async (dispatch: Dispatch<GroupsAction>) => {
		dispatch({
			type: 'START_REMOVE_USER_FROM_GROUP',
			params,
		})

		try {
			await groupUserRemoveApi.delete(params)
			return dispatch({
				type: 'FINISH_REMOVE_USER_FROM_GROUP',
			})
		} catch (serverError) {
			return dispatch({
				type: 'FINISH_REMOVE_USER_FROM_GROUP',
				serverError,
			})
		}
	}
}

export function assignUserToGroup(params: GroupUserPostParams, body: GroupUser) {
	return async (dispatch: Dispatch<GroupsAction>) => {
		try {
			const response: ?GroupUser = await groupUserApi.post(params, body)
			dispatch({
				type: 'FINISH_ASSIGN_USER_TO_GROUP',
				response,
			})
		} catch (serverError) {
			return dispatch({
				type: 'FINISH_ASSIGN_USER_TO_GROUP',
				response: null,
				serverError,
			})
		}
	}
}

export function getGroups() {
	return async (dispatch: Dispatch<GroupsAction>) => {
		dispatch({
			type: 'START_GET_GROUPS',
		})
		try {
			const groups: ?Array<Group> = await groupsApi.get({})
			dispatch({
				type: 'FINISH_GET_GROUPS',
				groups,
			})
		} catch (serverError) {
			return dispatch({
				type: 'FINISH_GET_GROUPS',
				groups: null,
				serverError,
			})
		}
	}
}

export function createGroup(group: Group) {
	return async (dispatch: Dispatch<GroupsAction>): Promise<GroupsAction> => {
		dispatch({
			type: 'START_CREATE_GROUP',
			group,
		})
		try {
			const result: ?Group = await groupsApi.post(group)
			return dispatch({
				type: 'FINISH_CREATE_GROUP',
				group: result,
			})
		} catch (serverError) {
			return dispatch({
				type: 'FINISH_CREATE_GROUP',
				group: null,
				serverError,
			})
		} finally {
			dispatch(getGroups())
		}
	}
}

export function getGroup(params: GroupGetParams) {
	return async (dispatch: Dispatch<GroupsAction>): Promise<GroupsAction> => {
		dispatch({
			type: 'START_GET_GROUP',
			params,
		})
		try {
			const result: ?Group = await groupApi.get(params)
			return dispatch({
				type: 'FINISH_GET_GROUP',
				group: result,
			})
		} catch (serverError) {
			return dispatch({
				type: 'FINISH_GET_GROUP',
				group: null,
				serverError,
			})
		}
	}
}

export function updateGroup(params: GroupPutParams, body: Group) {
	return async (dispatch: Dispatch<GroupsAction>): Promise<GroupsAction> => {
		dispatch({
			type: 'START_UPDATE_GROUP',
			params,
			body,
		})
		try {
			const result: ?Group = await groupApi.put(params, body)
			return dispatch({
				type: 'FINISH_UPDATE_GROUP',
				group: result,
			})
		} catch (serverError) {
			return dispatch({
				type: 'FINISH_UPDATE_GROUP',
				group: null,
				serverError,
			})
		} finally {
			dispatch(getGroups())
		}
	}
}

export function removeGroup(params: GroupDeleteParams) {
	return async (dispatch: Dispatch<GroupsAction>): Promise<GroupsAction> => {
		dispatch({
			type: 'START_DELETE_GROUP',
			params,
		})
		try {
			await groupApi.delete(params)
			return dispatch({
				type: 'FINISH_DELETE_GROUP',
			})
		} catch (serverError) {
			return dispatch({
				type: 'FINISH_DELETE_GROUP',
				serverError,
			})
		}
	}
}
