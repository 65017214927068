/* @flow */
/** @jsx jsx */

// $FlowFixMe - there is useState
import { type Node, useEffect, useMemo, useRef, useState } from 'react'
import { type WithTranslateProps, withTranslate } from 'wrappers'

import type { AccountingDocumentLineItem } from 'types'
import { type AutoCompleteActionMeta } from 'components/auto-complete'
import CircularProgress from 'components/circular-progress'
import FavoriteAutoComplete from 'components/favorite-auto-complete'
import { type FavoriteOptionType } from 'components/favorite-select/favorite-option'
import { formatToMoney } from 'utils/formatters'
import { jsx } from '@emotion/core'

type Props = {|
	...WithTranslateProps,
	value: ?string,
	error?: Node,
	tabIndex?: number,
	disabled?: boolean,
	currency: ?string,
	accountingDocumentId: string,
	isAccDocVatFree: boolean,
	favoriteLineItemIds: Array<string>,
	defaultLineItems?: Array<AccountingDocumentLineItem> | null,
	onLineItemFavorite: (id: ?string, isFavorite: boolean) => void,
	searchLineItems: (filter?: string, accountingDocumentId: string) => void,
	onDescriptionChange: (value: string) => void,
	onLineItemChange: (item: AccountingDocumentLineItem) => void,
	newLineTabIndex?: number,
	isInLastLineItem?: boolean,
	onLastLineEnter?: () => void,
|}

function getOptionText(item: AccountingDocumentLineItem, isAccDocVatFree: boolean, currency: ?string) {
	const info = []
	!isAccDocVatFree && item.vatRate && info.push(`${item.vatRate} %`)
	undefined !== item.qt && item.unitName && info.push(`${item.qt} ${item.unitName}`)
	info.push(formatToMoney(item.total, { currency }))
	return info.join(', ')
}

function LineItemDescription(props: Props) {
	const timeout = useRef<null | TimeoutID>(null)
	const [loading, setLoading] = useState<boolean>(false)
	const [lineItems, setLineItems] = useState<null | Array<AccountingDocumentLineItem>>(props.defaultLineItems || null)
	const [disableSearchFor, setDisableSearchFor] = useState<string | null>(null)

	useEffect(() => {
		setLineItems(props.defaultLineItems)
	}, [props.defaultLineItems])

	function debounceLoadOptions() {
		return (filter: string, action: AutoCompleteActionMeta) => {
			if ('input-change' !== action.action) return
			if (!filter || !filter.length) {
				timeout.current && clearTimeout(timeout.current)
				setLineItems(props.defaultLineItems || null)
				return void setLoading(false)
			}
			if (disableSearchFor && filter.startsWith(disableSearchFor)) {
				setLineItems([])
				setLoading(false)
				return
			} else {
				setLoading(true)
			}
			const later = async () => {
				timeout.current = null
				const result = await props.searchLineItems(filter, props.accountingDocumentId)
				if (!result || !result.lineItems || !result.lineItems.length) setDisableSearchFor(filter)
				setLineItems(result ? result.lineItems || [] : [])
				setLoading(false)
			}
			timeout.current && clearTimeout(timeout.current)
			timeout.current = setTimeout(later, 500)
		}
	}

	const options = useMemo(() => {
		const items = (null === lineItems ? props.defaultLineItems : lineItems) || []
		return items
			.map((item: AccountingDocumentLineItem) => {
				return {
					value: item.id,
					label: item.description,
					isFavorite: props.favoriteLineItemIds && props.favoriteLineItemIds.includes(item.id),
					onFavorite: props.onLineItemFavorite,
					rightText: getOptionText(item, props.isAccDocVatFree, props.currency),
				}
			})
			.sort((item: FavoriteOptionType) => (item.isFavorite ? -1 : 1))
	}, [
		props.defaultLineItems,
		props.onLineItemFavorite,
		props.favoriteLineItemIds,
		props.currency,
		props.isAccDocVatFree,
		lineItems,
	])

	function onSelect(value: FavoriteOptionType) {
		const foundLineItem =
			lineItems.find((item: AccountingDocumentLineItem) => item.id === value.value) ||
			(props.defaultLineItems &&
				props.defaultLineItems.find((item: AccountingDocumentLineItem) => item.id === value.value))

		foundLineItem && props.onLineItemChange(foundLineItem)
	}

	return (
		<div css={style.root}>
			<FavoriteAutoComplete
				onType={debounceLoadOptions()}
				options={options}
				onChange={props.onDescriptionChange}
				onSelect={onSelect}
				value={props.value}
				error={props.error}
				autoTestId="description"
				placeholder={props.t('invoice.lineItems.itemNameHint')}
				tabIndex={props.tabIndex}
				disabled={props.disabled}
				multiline
				inline
				newLineTabIndex={props.newLineTabIndex}
				isInLastLineItem={props.isInLastLineItem}
				onLastLineEnter={props.onLastLineEnter}
			/>
			{loading && (
				<div css={style.loading}>
					<CircularProgress size={14} />
				</div>
			)}
		</div>
	)
}

const style = {
	root: {
		position: 'relative',
	},
	loading: {
		width: 20,
		position: 'absolute',
		right: -24,
		top: 0,
		bottom: 0,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		pointerEvents: 'none',
	},
}

export default withTranslate(LineItemDescription)
