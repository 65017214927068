//@flow

import { connect } from 'react-redux'
import type { Dispatch, State, OrganizationAction, OrganizationProcessIssue, Organization } from 'types'
import { processOrganization as processOrganizationApi } from 'modules/common/models/api-model'
import { loadCurrentOrganizationProcessingIssues, loadOrganizationDetail } from 'modules/organization/actions'
import { getCurrentOrganizationDetail, getCurrentOrganization } from 'modules/organization/selectors'
import type { OrganizationDetailState } from 'modules/organization/reducers'
import { getCurrentOrganizationId } from 'modules/user/selectors'
import EsoComponent from 'modules/settings/components/company/eso'

type StateProps = {|
	currentOrganizationId: ?string,
	processingIssues: ?Array<OrganizationProcessIssue>,
	connectionState: ?number,
|}

const mapStateToProps = (state: State): StateProps => {
	const detail: ?OrganizationDetailState = getCurrentOrganizationDetail(state)
	const org: ?Organization = getCurrentOrganization(state)
	return {
		currentOrganizationId: getCurrentOrganizationId(state),
		processingIssues: detail && detail.processingIssues,
		connectionState: org && org.connectionState,
	}
}

type DispatchProps = {|
	processOrganization: (organizationId: string) => Promise<void>,
	loadProcessingIssues: () => void,
|}

const mapDispatchToProps = (dispatch: Dispatch<OrganizationAction>): DispatchProps => {
	return {
		processOrganization: async (organizationId: string) => {
			const result: Promise<void> = await processOrganizationApi.post({ organizationId })
			dispatch(loadCurrentOrganizationProcessingIssues())
			dispatch(loadOrganizationDetail())
			return result
		},
		loadProcessingIssues: () => {
			dispatch(loadCurrentOrganizationProcessingIssues())
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(EsoComponent)
