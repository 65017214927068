/* @flow */

import React, { Component } from 'react'
import type {
	AccountingDocument,
	AccountingDocumentType,
	TemplateScheduler,
	AccountingDocumentPrintingInfo,
} from 'types'
import AddBankAccountTemplate from '../invoice-elements-template/add-bank-account'
import BankAccountsTemplate from '../invoice-elements-template/bank-accounts'
import Logo from '../invoice-elements/logo'
import Contact from '../invoice-elements/contact/contact'
import ContactTemplate from '../invoice-elements-template/contact'
import HeaderDatesTemplate from '../invoice-elements-template/header-dates'
import HeaderNoTemplate from '../invoice-elements-template/header-no'
import HeaderOrderNoTemplate from '../invoice-elements-template/header-order-no'
import PrintingNoteTemplate from '../invoice-elements-template/printing-note'
import InvoiceSignatureTemplate from '../invoice-elements-template/invoice-signature'
//INVOICE ELEMENTS
import Invoice from '../../components/invoices/invoice'
import LineItemsTemplate from '../invoice-elements-template/line-items'
import LineItemsHeaderContainer from '../invoice-elements-template/line-items-header'
import { Number_AccountingDocumentType } from 'types/convertor'
import PaymentDetailsTemplate from '../invoice-elements-template/payment-details'
import PaymentTypeTemplate from '../invoice-elements-template/payment-type'
import SettingsPanelTemplate from '../invoice-settings/settings-panel-template'
import UpperSettingsTemplate from '../invoice-elements/upper-settings-template'
// import VatRecapTemplate from '../invoice-elements-template/vat-recap'
import NameTemplate from '../invoice-elements-template/name'
import TotalToPayTemplate from '../invoice-elements-template/total-to-pay'
import TypeSwitcherTemplate from '../invoice-elements-template/type-switcher'
import TotalsTemplate from '../invoice-elements-template/totals'
import { accountingDocumentsTemplatesRoute } from 'modules/accounting-document/routing/routes'
import { getFormFieldErrorContexts } from 'helpers/errors-helpers'
import { TEMPLATE_ERROR_MAIN_PATHS, TEMPLATE_SCHEDULER_ERROR_MAIN_PATHS } from 'modules/accounting-document/constants'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import { clearVatRatesInLineItems } from '../../helpers'

type Props = {|
	...WithTranslateProps,
	title: string,
	saved: boolean,
	saving: boolean,
	color: ?string,
	templateId: string,
	// isEetActive: boolean,
	accountingDocument: AccountingDocument,
	templateScheduler: ?TemplateScheduler,
	saveButton: React$Element<any>,
	createAccDocButton: React$Element<any>,
	isOrganizationVatFree: boolean,
	domesticCurrencyId: ?string,
	onTitleChange: (title: ?string) => void,
	onChange: (accountingDocument: AccountingDocument) => void,
	onPrintingChange: (printing: $Shape<AccountingDocumentPrintingInfo>) => void,
	onTemplateSchedulerChange: (templateScheduler: ?TemplateScheduler, sendEmailAutomatically: boolean) => void,
	getVatCountryTypeByCountry: (country: ?string) => ?number,
	onCloseCallback: () => void,
	goToScheduler: boolean,
	onResetGoToScheduler: () => void,
|}

class TemplateIssuedInvoice extends Component<Props> {
	static supportedDirections = ['issued']
	static supportedTypes = ['invoice', 'advance']

	onChangeContactBayer = (accountingDocument: AccountingDocument): void => {
		let newAccountingDocument = { ...accountingDocument }
		const newCountry = newAccountingDocument.contact && newAccountingDocument.contact.country
		const newVatCountryType = this.props.getVatCountryTypeByCountry(newCountry)
		if (newVatCountryType) {
			if (newAccountingDocument.vatCountryType !== newVatCountryType) {
				newAccountingDocument = clearVatRatesInLineItems(newAccountingDocument)
			}
			newAccountingDocument.vatCountryType = newVatCountryType
		}
		this.props.onChange(newAccountingDocument)
	}

	render() {
		const TYPE: AccountingDocumentType = Number_AccountingDocumentType(this.props.accountingDocument.type) || 'unknown'
		const DIRECTION = 'issued'

		const formFieldErrorContexts = getFormFieldErrorContexts(
			TEMPLATE_ERROR_MAIN_PATHS,
			'Template',
			this.props.templateId,
		)

		const schedulerErrorContexts = getFormFieldErrorContexts(
			TEMPLATE_SCHEDULER_ERROR_MAIN_PATHS,
			'TemplateScheduler',
			this.props.templateId,
		)

		const SettingsPanel = (
			<SettingsPanelTemplate
				onChange={this.props.onChange}
				templateId={this.props.templateId}
				templateScheduler={this.props.templateScheduler}
				domesticCurrencyId={this.props.domesticCurrencyId}
				accountingDocument={this.props.accountingDocument}
				onTemplateSchedulerChange={this.props.onTemplateSchedulerChange}
				formFieldErrorContext={schedulerErrorContexts['start']}
				goToScheduler={this.props.goToScheduler}
				onResetGoToScheduler={this.props.onResetGoToScheduler}
			/>
		)

		const UpperSettings = (
			<UpperSettingsTemplate
				formFieldErrorContext={formFieldErrorContexts['upperSettings']}
				templateScheduler={this.props.templateScheduler}
				accountingDocument={this.props.accountingDocument}
				onChange={this.props.onChange}
				// isEetActive={this.props.isEetActive}
			/>
		)

		const PrintingNote = (
			<PrintingNoteTemplate accountingDocument={this.props.accountingDocument} onChange={this.props.onPrintingChange} />
		)

		const InvoiceSignature = (
			<InvoiceSignatureTemplate accountingDocument={this.props.accountingDocument} onChange={this.props.onChange} />
		)

		const HeaderOrderNo = (
			<HeaderOrderNoTemplate accountingDocument={this.props.accountingDocument} onChange={this.props.onChange} />
		)

		const HeaderNo = (
			<HeaderNoTemplate
				formFieldErrorContext={formFieldErrorContexts['headerNo']}
				accountingDocument={this.props.accountingDocument}
				onChange={this.props.onChange}
				direction={DIRECTION}
				type={TYPE}
			/>
		)
		const LineItemsHeader = (
			<LineItemsHeaderContainer
				accountingDocument={this.props.accountingDocument}
				onChange={this.props.onPrintingChange}
			/>
		)
		const LineItems = (
			<LineItemsTemplate
				formFieldErrorContext={formFieldErrorContexts['lineItems']}
				accountingDocument={this.props.accountingDocument}
				onChange={this.props.onChange}
				direction={DIRECTION}
				type={TYPE}
				isOrganizationVatFree={this.props.isOrganizationVatFree}
			/>
		)
		const ContactBuyer = (
			<ContactTemplate
				formFieldErrorContext={formFieldErrorContexts['contact']}
				accountingDocument={this.props.accountingDocument}
				onChange={this.onChangeContactBayer}
				direction={DIRECTION}
			/>
		)
		const BankAccounts = (
			<BankAccountsTemplate
				formFieldErrorContext={formFieldErrorContexts['bankAccounts']}
				accountingDocument={this.props.accountingDocument}
				onChange={this.props.onChange}
				direction={DIRECTION}
			/>
		)
		const AddBankAccount = (
			<AddBankAccountTemplate
				hideWhenZeroAccounts
				accountingDocument={this.props.accountingDocument}
				onChange={this.props.onChange}
				direction={DIRECTION}
			/>
		)
		const PaymentDetails = (
			<PaymentDetailsTemplate accDoc={this.props.accountingDocument} onChange={this.props.onChange} readonlyDueDate />
		)
		const HeaderDates = (
			<HeaderDatesTemplate accountingDocument={this.props.accountingDocument} onChange={this.props.onChange} readonly />
		)
		const TypeSwitcher = (
			<TypeSwitcherTemplate
				accountingDocument={this.props.accountingDocument}
				onChange={this.props.onChange}
				availableTypes={TemplateIssuedInvoice.supportedTypes}
			/>
		)
		const PaymentType = (
			<PaymentTypeTemplate
				formFieldErrorContext={formFieldErrorContexts['paymentType']}
				accountingDocument={this.props.accountingDocument}
				onChange={this.props.onChange}
			/>
		)
		const TotalsToPay = <TotalToPayTemplate accountingDocument={this.props.accountingDocument} />
		const ContactSupplier = <Contact direction={DIRECTION} isMe />
		const InvoiceLogo = <Logo showPlaceholder={DIRECTION === 'issued'} />

		const Totals = (
			<TotalsTemplate
				isOrganizationVatFree={this.props.isOrganizationVatFree}
				accountingDocument={this.props.accountingDocument}
				onChange={this.props.onChange}
			/>
		)

		const Name = (
			<NameTemplate
				title={this.props.title}
				onChange={this.props.onTitleChange}
				placeholder={this.props.t('templates.templateTitle')}
				formFieldErrorContext={formFieldErrorContexts['name']}
			/>
		)

		return (
			<Invoice
				formFieldErrorContext={formFieldErrorContexts}
				onCloseRedirectUrl={accountingDocumentsTemplatesRoute()}
				onCloseCallback={this.props.onCloseCallback}
				confirmClose={!this.props.saved}
				disableAttachments
				TypeSwitcher={TypeSwitcher}
				readonly={false}
				enabledViews={{ default: true, scans: false }}
				view="default"
				color={this.props.color}
				isTemplate
				contactSupplierTitle={this.props.t('invoice.contact.supplier')}
				contactBuyerTitle={this.props.t('invoice.contact.custommer')}
				LineItemsHeader={LineItemsHeader}
				LineItems={LineItems}
				HeaderNo={HeaderNo}
				HeaderOrderNo={HeaderOrderNo}
				Name={Name}
				Logo={InvoiceLogo}
				ContactSupplier={ContactSupplier}
				ContactBuyer={ContactBuyer}
				BankAccounts={BankAccounts}
				AddBankAccount={AddBankAccount}
				HeaderDates={HeaderDates}
				StateChanger={this.props.saveButton}
				CreateAccDocButton={this.props.createAccDocButton}
				PaymentDetails={PaymentDetails}
				PaymentType={PaymentType}
				TotalsToPay={TotalsToPay}
				VatRecap={null}
				Totals={Totals}
				PrintingNote={PrintingNote}
				InvoiceSignature={InvoiceSignature}
				SettingsPanel={SettingsPanel}
				UpperSettings={UpperSettings}
			/>
		)
	}
}

export default withTranslate(TemplateIssuedInvoice)
