/* @flow */
/** @jsx jsx */

// $FlowFixMe - there is useEffect
import { useReducer, useCallback, useMemo } from 'react'
import { jsx } from '@emotion/core'
import moment from 'moment'
import type { Sequence } from 'types'
import { validate, withTranslate, type WithTranslateProps, type FormValidationProps } from 'wrappers'
import {
	NON_CASH_REGISTER_ACCOUNTING_DOCUMENT_TYPES,
	SIMPLIFIED_CASH_REGISTER_ACCOUNTING_DOCUMENTS,
	ADVANCED_CASH_REGISTER_ACCOUNTING_DOCUMENTS,
	MAX_DOCUMENT_NUMBER_LENGTH,
} from 'modules/accounting-document/constants'
import { AccountingDocumentDirection_Number } from 'types/convertor'
import { formatToIsoDateString } from 'utils/formatters'
import SequenceFormatHelper from './sequence-format-helper'
import { AccountingDocumentTypeNumber_isCashRegister } from 'types/operations'
import AccountingDocumentDirectionSelector from 'modules/common/components/selectors/accounting-document-direction-selector'
import AccountingDocumentTypeSelector from 'modules/common/components/selectors/accounting-document-type-selector'
import CashRegisterSelector from 'modules/common/containers/cash-register-selector'
import DatePicker from 'components/date-picker'
import TextField from 'components/TextField'
import Dialog from 'components/dialog'
import Button from 'components/button'
import styles from '../settings.css'

type Props = {|
	...WithTranslateProps,
	...FormValidationProps,
	open: boolean,
	currentUserIsInternal: boolean,
	useOnlyOneSequenceSetPerCashRegister: boolean,
	createSequence: (sequence: Sequence, cashRegisterId?: ?string) => void,
	onRequestClose: () => void,
|}

type Action = {|
	type: 'reset' | 'set',
	field: string,
	value: any,
|}

type FormData = {|
	cashRegisterId?: string,
	name: string,
	format: string,
	accountingDocumentType: number,
	accountingDocumentDirection: number,
	validFrom: Date,
	validTo?: Date,
|}

function getInitialSequence() {
	return {
		validFrom: moment()
			.startOf('day')
			.toDate(),
	}
}

function reducer(state: Sequence, action: Action) {
	if (action.type) return getInitialSequence()
	return { ...state, [action.field]: action.value }
}

function AddSequenceDialog(props: Props) {
	const [sequence, dispatch] = useReducer<(state: FormData, action: Action) => FormData, FormData>(
		reducer,
		getInitialSequence(),
	)

	const onNameChange = useCallback((e: any, value: ?string) => dispatch({ field: 'name', value: value || '' }), [
		dispatch,
	])
	const onFormatChange = useCallback((e: any, value: ?string) => dispatch({ field: 'format', value: value || '' }), [
		dispatch,
	])
	const onValidFromChange = useCallback((e: any, value: ?Date) => dispatch({ field: 'validFrom', value }), [dispatch])
	const onValidToChange = useCallback((e: any, value: ?Date) => dispatch({ field: 'validTo', value }), [dispatch])
	const onTypeChange = useCallback((value: ?number) => dispatch({ field: 'accountingDocumentType', value }), [dispatch])
	const onCashRegisterChange = useCallback((value: ?string) => dispatch({ field: 'cashRegisterId', value }), [dispatch])
	const onDirectionChange = useCallback((value: ?number) => dispatch({ field: 'accountingDocumentDirection', value }), [
		dispatch,
	])

	const isCashRegister = AccountingDocumentTypeNumber_isCashRegister(sequence.accountingDocumentType)

	function resetForm() {
		dispatch({ type: 'reset' })
		props.resetValidation()
	}

	const allowedTypes = useMemo(
		() => [
			...NON_CASH_REGISTER_ACCOUNTING_DOCUMENT_TYPES,
			...SIMPLIFIED_CASH_REGISTER_ACCOUNTING_DOCUMENTS,
			...(!props.useOnlyOneSequenceSetPerCashRegister ? ADVANCED_CASH_REGISTER_ACCOUNTING_DOCUMENTS : []),
		],
		[props.useOnlyOneSequenceSetPerCashRegister],
	)

	const getAddSequence = () => () => addSequence(sequence)
	function addSequence(sequence: FormData) {
		const newAccountingDocumentDirection =
			isCashRegister || props.currentUserIsInternal
				? sequence.accountingDocumentDirection
				: AccountingDocumentDirection_Number('issued')

		if (newAccountingDocumentDirection == null || sequence.accountingDocumentType == null) return
		const newSequence: Sequence = {
			name: sequence.name || '',
			format: sequence.format || '',
			accountingDocumentType: sequence.accountingDocumentType,
			accountingDocumentDirection: newAccountingDocumentDirection,
			validTo: sequence.validTo ? formatToIsoDateString(sequence.validTo) : undefined,
			validFrom: formatToIsoDateString(sequence.validFrom),
		}

		props.createSequence(newSequence, sequence.cashRegisterId)
		props.onRequestClose()
	}

	const errors = {}
	const { t } = props

	if (sequence.accountingDocumentType == null) errors.accountingDocumentType = t('application.validation.mandatory')
	if (isCashRegister && null == sequence.cashRegisterId) errors.cashRegisterId = t('application.validation.mandatory')
	if (sequence.accountingDocumentDirection == null && (props.currentUserIsInternal || sequence.cashRegisterId))
		errors.accountingDocumentDirection = t('application.validation.mandatory')

	if (!sequence.name) errors.name = t('application.validation.mandatory')
	if (!sequence.validFrom) errors.validFrom = t('application.validation.mandatory')
	if (sequence.validFrom && sequence.validTo) {
		if (moment(sequence.validFrom).startOf('day') > moment(sequence.validTo).startOf('day')) {
			errors.validTo = t('settings.sequences.validToLowerThanValidFrom')
		}
	}
	if (!isCashRegister && !props.currentUserIsInternal) {
		sequence.cashRegisterId = undefined
		sequence.accountingDocumentDirection = 1
	}

	if (sequence.format) {
		const regexp = /\(c+\)|\(yyyy\)|\(yy\)|\(mm\)/gi
		const match = sequence.format.match(regexp) || []
		const countOfBrackets = match.length * 2
		if (sequence.format.length - countOfBrackets > MAX_DOCUMENT_NUMBER_LENGTH) {
			errors.format = t('settings.sequences.documentNumberMaxLength', { maxLength: MAX_DOCUMENT_NUMBER_LENGTH })
		}
	} else {
		errors.format = t('application.validation.mandatory')
	}

	props.validateForm(errors)

	const actions = [
		<Button
			primary
			wide
			key="addModalButton"
			labelText={t('settings.forms.addButton')}
			onClick={props.validationSubmit(getAddSequence())}
			autoTestId="sequences-add"
		/>,
		<Button
			secondary
			wide
			key="cancelModalButton"
			labelText={t('settings.forms.cancelButton')}
			onClick={props.onRequestClose}
			autoTestId="sequences-cancel"
		/>,
	]

	return (
		<Dialog
			open={props.open}
			autoTestId="sequences-dialog"
			title={t('settings.sequences.newSequenceDialogTitle')}
			onClosed={resetForm}
			actions={actions}
		>
			<div className={styles.row}>
				<div className={styles.column}>
					<TextField
						fullWidth
						onChange={onNameChange}
						value={props.validationValue('name', sequence.name)}
						clientError={props.validationMessage('name')}
						labelText={t('settings.sequences.name')}
						autoTestId="sequences-name"
						name="name"
					/>
				</div>
				<div className={styles.column}>
					<TextField
						fullWidth
						onChange={onFormatChange}
						value={props.validationValue('format', sequence.format)}
						infoText={t('settings.sequences.placeholderTooltip')}
						clientError={props.validationMessage('format')}
						labelText={t('settings.sequences.format')}
						autoTestId="sequences-format"
						name="format"
					/>
				</div>
			</div>
			<div className={styles.row}>
				<div className={styles.column}>
					<DatePicker
						onChange={onValidFromChange}
						value={props.validationValue('validFrom', sequence.validFrom)}
						clientError={props.validationMessage('validFrom')}
						labelText={t('settings.sequences.validFrom')}
						autoTestId="sequence-valid-to-date"
						fullWidth
					/>
				</div>
				<div className={styles.column}>
					<DatePicker
						onChange={onValidToChange}
						value={props.validationValue('validTo', sequence.validTo)}
						clientError={props.validationMessage('validTo')}
						labelText={t('settings.sequences.validTo')}
						autoTestId="sequence-valid-to-date"
						fullWidth
					/>
				</div>
			</div>

			<div className={styles.row}>
				<AccountingDocumentTypeSelector
					value={props.validationValue('accountingDocumentType', sequence.accountingDocumentType)}
					clientError={props.validationMessage('accountingDocumentType')}
					onChange={onTypeChange}
					allowedTypes={allowedTypes}
				/>
			</div>

			<div className={isCashRegister ? styles.row : styles.hidden}>
				<CashRegisterSelector
					label={t('settings.sequences.cashRegister')}
					error={props.validationMessage('cashRegisterId')}
					value={props.validationValue('cashRegisterId', sequence.cashRegisterId)}
					onChange={onCashRegisterChange}
					isCashRegister={isCashRegister}
				/>
			</div>

			<div className={sequence.cashRegisterId || props.currentUserIsInternal ? styles.row : styles.hidden}>
				<AccountingDocumentDirectionSelector
					onChange={onDirectionChange}
					isCashRegister={isCashRegister}
					currentUserIsInternal={props.currentUserIsInternal}
					value={props.validationValue('accountingDocumentDirection', sequence.accountingDocumentDirection)}
					clientError={props.validationMessage('accountingDocumentDirection')}
				/>
			</div>

			<SequenceFormatHelper />
		</Dialog>
	)
}

export default withTranslate(validate('FINISH_CREATE_SEQUENCE')(AddSequenceDialog))
