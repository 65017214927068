// @flow

import type { EmailTemplate } from 'types'

export function findTemplateById(id: ?string, templates: ?Array<EmailTemplate>): EmailTemplate | null {
	if (!id || !templates || !templates.length) return null
	return templates.find((template: EmailTemplate) => id === template.id) || null
}

export function getPlaceholders(type: ?number) {
	switch (type) {
		case 1:
			return REMINDER_PLACEHOLDERS
		case 2:
			return DOCUMENT_PLACEHOLDERS
		default:
			return null
	}
}

export const REMINDER_PLACEHOLDERS: Array<string> = [
	'@PublicAccountingDocumentUrl@',
	'@AccountingDocumentNo@',
	'@DueDate@',
	'@TotalAmount@',
	'@FormattedTotalAmountWithCurrency@',
	'@OverdueDays@',
	'@OverdueDaysAbs@',
	'@VendorCompanyName@',
	'@VendorFullName@',
	'@VendorNormalizedName@',
	'@CompanyName@',
]

export const DOCUMENT_PLACEHOLDERS: Array<string> = [
	'@PublicAccountingDocumentUrl@',
	'@AccountingDocumentNo@',
	'@DueDate@',
	'@TotalAmount@',
	'@FormattedTotalAmountWithCurrency@',
	'@VendorFullName@',
	'@VendorCompanyName@',
	'@VendorNormalizedName@',
	'@CompanyName@',
]

export const THIS_PERIOD_PLACEHOLDERS: Array<string> = ['@ThisYear@', '@ThisMonth@', '@ThisWeek@', '@ThisDay@']

export const NEXT_PERIOD_PLACEHOLDERS: Array<string> = ['@NextYear@', '@NextMonth@', '@NextWeek@', '@NextDay@']

export const PREV_PERIOD_PLACEHOLDERS: Array<string> = [
	'@PreviousYear@',
	'@PreviousMonth@',
	'@PreviousWeek@',
	'@PreviousDay@',
]
