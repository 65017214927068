// @flow

import type { Dispatch, AccountingDocumentReduxAction, Import, Scan, State, UploadScanAction } from 'types'

import MultiImport from 'modules/accounting-document/components/import/multi-import'
import { connect } from 'react-redux'
import { createAccountingDocumentImport, uploadScan } from 'modules/accounting-document/actions'
import type { FinishCreatingImport } from 'modules/accounting-document/actions/import-action-types'
import { push } from 'react-router-redux'
import { accountingDocumentImportDetailRoute } from 'modules/accounting-document/routing/routes'

type StateProps = {|
	scans: Array<Scan>,
	uploading: boolean,
|}

function mapStateToProps(state: State): StateProps {
	return {
		scans: state.accountingDocument.uploadScan.scans,
		uploading: state.accountingDocument.uploadScan.uploadingScanIds.length > 0,
	}
}

type DispatchProps = {|
	createImport: (importObject: Import) => void,
	uploadScan: (file: File, documentCategory: ?string, customUploadId?: number) => Promise<any>,
|}

const mapDispatchToProps = (dispatch: Dispatch<AccountingDocumentReduxAction | UploadScanAction>): DispatchProps => {
	return {
		createImport: (importObject: Import) => {
			dispatch(createAccountingDocumentImport(importObject)).then((resp: FinishCreatingImport) => {
				if (resp.import) {
					dispatch(push(accountingDocumentImportDetailRoute(resp.import.id)))
				}
			})
		},
		uploadScan: async (file: File, documentCategory: ?string, customUploadId?: number): Promise<any> => {
			return dispatch(uploadScan(file, documentCategory, customUploadId))
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(MultiImport)
