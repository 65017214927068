/* @flow */

import React, { Component } from 'react'
import UserPanel from '../user-panel'
import { autoTestId, type AutoTestProps } from 'utils/tests/autotest'
import NotificationPreview from 'modules/notifications/containers/notification-preview'
import UserProfileAvatar from './user-profile-avatar'
import styles from './user-icon.css'
import type { PortalLanguage, ISignalMessage } from 'types'
import { isEnabled } from 'utils/features'

type Props = {|
	...AutoTestProps,
	classNames?: string,
	notifications: Array<ISignalMessage>,
	name: ?string,
	onClick?: () => void,
	onLogout: () => void,
	avatar: ?string,
	locale: ?string,
	language: ?PortalLanguage,
	onLanguageChange: (language: ?PortalLanguage, updateLocale: boolean) => void,
	loadAvatar: () => void,
|}

type State = {|
	isPanelOpened: boolean,
|}

class UserIcon extends Component<Props, State> {
	state = {
		isPanelOpened: false,
	}

	componentDidMount() {
		if (this.props.avatar === null || this.props.avatar === undefined) {
			this.props.loadAvatar()
		}
	}

	onClick = () => {
		let { onClick } = this.props
		if (onClick && onClick instanceof Function) {
			onClick()
		}
		this.setState({
			isPanelOpened: !this.state.isPanelOpened,
		})
	}

	onRequestClose = () => {
		this.setState({
			isPanelOpened: false,
		})
	}

	onLanguageChange = (language: ?PortalLanguage) => {
		this.props.onLanguageChange(language, !this.props.locale)
	}

	hasProgressNotification = (notifications: Array<ISignalMessage>) => {
		return !!notifications.find((notification: ISignalMessage) => {
			return 'START' === notification.type
		})
	}

	render() {
		const { name, notifications, onLogout, classNames, avatar } = this.props

		return (
			<div ref={'root'} className={styles.icon + ' ' + (classNames || '')} {...autoTestId(this.props.autoTestId)}>
				<div className={`${styles.root} ${styles.icon ? styles.icon : ''}`} onClick={this.onClick}>
					{isEnabled('notifications') ? (
						<UserProfileAvatar
							size={40}
							avatar={avatar}
							name={name}
							notifications={notifications && notifications.length}
							progress={this.hasProgressNotification(notifications)}
						/>
					) : (
						<UserProfileAvatar size={40} avatar={avatar} name={name} />
					)}
				</div>
				<UserPanel
					name={name}
					avatar={avatar}
					open={this.state.isPanelOpened}
					anchorEl={this.refs.root}
					onRequestClose={this.onRequestClose}
					onLogout={onLogout}
					language={this.props.language}
					onLanguageChange={this.onLanguageChange}
				/>
				{isEnabled('notifications') && <NotificationPreview />}
			</div>
		)
	}
}

export default UserIcon
