// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[3].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js!../../../variables/colors.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3bRc5K8qhEeIyT9itknF4f {\n\tmargin-left: 30px;\n\tmargin-bottom: 10px;\n\tfont-size: 14px;\n\tcolor: gray;\n}\n\n.cmel5QSfTvOELbX2zAp5Z {\n\tmargin-top: 50px;\n}\n\n._2D-bRdc84_nupq75HkEMuW {\n\tflex-direction: row;\n\tdisplay: flex;\n}\n\n._39hb5Dm1nuCVofkdw2MVGm {\n\tflex-direction: column;\n}\n", "",{"version":3,"sources":["webpack://./modules/features/components/features-list.css"],"names":[],"mappings":"AAEA;CACC,iBAAA;CACA,mBAAA;CACA,eAAA;CACA,WAAA;AAOD;;AAJA;CACC,gBAAA;AAOD;;AAJA;CACC,mBAAA;CACA,aAAA;AAOD;;AAJA;CACC,sBAAA;AAOD","sourcesContent":["@value grey from \"variables/colors.css\";\n\n.description {\n\tmargin-left: 30px;\n\tmargin-bottom: 10px;\n\tfont-size: 14px;\n\tcolor: gray;\n}\n\n.buttonWrapper {\n\tmargin-top: 50px;\n}\n\n.row {\n\tflex-direction: row;\n\tdisplay: flex;\n}\n\n.rowTexts {\n\tflex-direction: column;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"grey": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["grey"] + "",
	"description": "_3bRc5K8qhEeIyT9itknF4f",
	"buttonWrapper": "cmel5QSfTvOELbX2zAp5Z",
	"row": "_2D-bRdc84_nupq75HkEMuW",
	"rowTexts": "_39hb5Dm1nuCVofkdw2MVGm"
};
export default ___CSS_LOADER_EXPORT___;
