/* @flow */

import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import type { AccountingDocumentDirection, WithRouterProps, InvoiceSideButton, FormFieldErrorContext } from 'types'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import AttachmentButton from '../../containers/invoice-elements-document/attachment-button'
import { Direction, Paper, PopupSection } from 'components'
import { autoTestId } from 'utils/tests/autotest'
import SideButtons from '../../components/invoice-elements/side-buttons'
import SplitView from './split-view'
import colors from 'variables/colors.css'
import styles from './invoice.css'
import { jsx } from '@emotion/core'

type View = 'default' | 'scans'

export type Props = {|
	formFieldErrorContext?: FormFieldErrorContext,
	accountingDocumentId: string,
	enabledViews: { [key: View]: boolean },
	view: View,
	isTemplate?: boolean,
	readonly?: boolean,
	color: ?string,
	contactSupplierTitle?: string,
	contactBuyerTitle?: string,
	disableAttachments?: boolean,
	storing?: boolean,
	scansCount?: number,
	isCashRegister?: boolean,
	exchRate: ?number,
	exchRateDefault: ?number,
	vatExchRate: ?number,
	sadExchRate: ?number,
	// ---- ELEMENTS ----
	Name: any,
	UpperSettings: any,
	CashbotPanel: any,
	TypeSwitcher?: any,
	HeaderDates?: any,
	HeaderNo: any,
	HeaderOrderNo?: any,
	Logo?: any,
	ContactSupplier?: any,
	ContactBuyer?: any,
	CreditNoteDescription?: any,
	LineItems?: any,
	LineItemsHeader?: any,
	Attachments?: any,
	StateChanger: any,
	createAccDocButton: any,
	NeedHelp: any,
	PaymentType?: any,
	PaymentDetails?: any,
	TotalsToPay?: any,
	BankAccounts?: any,
	AddBankAccount?: any,
	VatRecap?: any,
	Totals?: any,
	SettingsPanel?: any,
	Scans?: any,
	ScansSaveButton?: any,
	Summary?: any,
	MoreActions?: any,
	Note?: any,
	PrintingNote?: any,
	InvoiceSignature?: any,
	ActivitiesDialog?: any,
	ExtractionNote?: React$Element<any>,
	// ----
	onViewChange?: ('default' | 'scans') => void,
	children?: any,
	confirmClose?: boolean,
	onCloseRedirectUrl?: string,
	onCloseCallback?: () => void,
	direction?: AccountingDocumentDirection,
	sideButtons?: Array<InvoiceSideButton>,
	showOrganizationInfo?: boolean,
|}

type State = {|
	isAttachmensOpen: boolean,
|}

type ComponentProps = { ...Props, ...WithTranslateProps, ...WithRouterProps }

class Invoice extends Component<ComponentProps, State> {
	state: State = {
		isAttachmensOpen: false,
	}

	componentWillMount() {
		if (this.props.history.location.state && this.props.history.location.state.isAttachmensOpen) {
			this.setState({
				isAttachmensOpen: true,
			})
		}
	}

	onOpenAttachments = () => {
		this.setState({
			isAttachmensOpen: !this.state.isAttachmensOpen,
		})
	}

	onCloseAttachments = () => {
		this.setState({
			isAttachmensOpen: !this.state.isAttachmensOpen,
		})
	}

	renderInvoice() {
		const color = this.props.color || colors.blue
		return (
			<div>
				<SplitView
					formFieldErrorContext={this.props.formFieldErrorContext}
					enabledViews={this.props.enabledViews}
					view={this.props.view}
					readonly={this.props.readonly}
					storing={this.props.storing}
					scansCount={this.props.scansCount}
					onViewChange={this.props.onViewChange}
					onCloseRedirectUrl={this.props.onCloseRedirectUrl}
					onCloseCallback={this.props.onCloseCallback}
					confirmClose={this.props.confirmClose}
					Name={this.props.Name}
					TypeSwitcher={this.props.TypeSwitcher}
					StateChanger={this.props.StateChanger}
					CreateAccDocButton={this.props.CreateAccDocButton}
					NeedHelp={this.props.NeedHelp}
					SettingsPanel={this.props.SettingsPanel}
					Scans={this.props.Scans}
					ScansSaveButton={this.props.ScansSaveButton}
					Summary={this.props.Summary}
					MoreActions={this.props.MoreActions}
					Note={this.props.Note}
					UpperSettings={this.props.UpperSettings}
					CashbotPanel={this.props.CashbotPanel}
					ExtractionNote={this.props.ExtractionNote}
					Direction={
						<Direction
							showLabel
							issued={this.props.direction === 'issued'}
							received={this.props.direction === 'received'}
							isCashRegister={this.props.isCashRegister}
						/>
					}
					// ExchRateInfo={
					// 	!isTemplate && currencyId && domesticCurrencyId && currencyId === domesticCurrencyId ? null : (
					// 		<ExchangeRateInfo
					// 			exchRate={this.props.exchRate}
					// 			vatExchRate={this.props.vatExchRate}
					// 			sadExchRate={this.props.sadExchRate}
					// 			exchRateDefault={this.props.exchRateDefault}
					// 			accountingDocumentId={this.props.accountingDocumentId}
					// 			isTemplate={this.props.isTemplate}
					// 			template={this.props.template}
					// 			isInHeader
					// 		/>
					// 	)
					// }
					showOrganizationInfo={this.props.showOrganizationInfo}
				>
					<Paper rounded zDepth={3} className={styles.invoice} id={'invoice-container'}>
						<div className={this.props.disableAttachments ? styles.invoiceHeader : styles.tallInvoiceHeader}>
							{this.props.HeaderOrderNo}
							{this.props.HeaderNo}
						</div>

						<div className={styles.fullhr} />
						<div className={styles.row}>
							{this.props.ContactSupplier && (
								<div className={styles.contact}>
									<div {...autoTestId('invoice-contact-left')}>
										<div className={styles.h3} {...autoTestId('left-title')}>
											{this.props.contactSupplierTitle}
										</div>
										<div className={styles.supplierContact}>
											<div className={styles.supplier}>{this.props.ContactSupplier}</div>
											{this.props.Logo}
										</div>
									</div>
								</div>
							)}

							{this.props.ContactBuyer && (
								<div className={styles.contact}>
									<div {...autoTestId('invoice-contact-right')}>
										<div className={styles.h3} {...autoTestId('right-title')}>
											{this.props.contactBuyerTitle}
										</div>
										<div className={styles.buyer}>
											{this.props.ContactBuyer &&
												React.cloneElement(this.props.ContactBuyer, { view: this.props.view })}
											<div className={styles.hr} />
											{this.props.HeaderDates}
										</div>
									</div>
								</div>
							)}
						</div>

						{(this.props.BankAccounts || this.props.PaymentType || this.props.TotalsToPay) && (
							<div className={styles.paymentRoot}>
								<div className={styles.paymentType}>{this.props.PaymentType}</div>
								<div className={styles.paymentSummary} style={{ borderColor: color }}>
									<div className={styles.paymentAccounts}>{this.props.BankAccounts}</div>
									<div className={styles.paymentPayment}>{this.props.PaymentDetails}</div>
									{this.props.TotalsToPay}
								</div>
								{this.props.AddBankAccount}
							</div>
						)}

						{this.props.CreditNoteDescription}
						{this.props.LineItemsHeader}
						{this.props.LineItems}

						<div className={styles.row}>
							<div className={styles.vatRecap}>
								{this.props.PrintingNote}
								{this.props.VatRecap}
							</div>
							<div className={styles.totals}>
								{this.props.Totals}
								{this.props.InvoiceSignature}
							</div>
						</div>

						{!this.props.disableAttachments && (
							<AttachmentButton
								onClick={this.onOpenAttachments}
								accountingDocumentId={this.props.accountingDocumentId}
								hideIfOnlyAccountingDocuments={this.props.readonly}
								hideIfNoAttachment={this.props.readonly}
							/>
						)}
						<SideButtons sideButtons={this.props.sideButtons} />
						{this.props.children}
					</Paper>
					{this.props.ActivitiesDialog}
				</SplitView>
			</div>
		)
	}

	renderAttachments() {
		const attachments: any = this.props.Attachments
		const newAttachments: any = React.cloneElement(attachments, {
			onRequestClose: this.onCloseAttachments,
		})
		return (
			<PopupSection open onRequestClose={this.onCloseAttachments}>
				<div className={styles.view}>{newAttachments}</div>
			</PopupSection>
		)
	}

	render() {
		return this.state.isAttachmensOpen ? this.renderAttachments() : this.renderInvoice()
	}
}

export default withRouter(withTranslate(Invoice))
