/* @flow */

import { find, findIndex, get } from 'lodash-es'
import { set } from 'lodash/fp'
import type { ScanFactoryBbWord, ScanFactoryDocumentFileMetadata, ScanFactoryField } from 'types'
import type { BbWordCursor, BbWordFieldMap, FieldBbWordMap, FieldCursor, FieldSection } from '../types'
import { getBbWordCursor, getBbWordCursorKey } from './bbword'
import { getFieldSection, getFieldSections } from './section'

export function isActiveField(
	fieldCursor: FieldCursor,
	field: ScanFactoryField,
	index: number,
	section: FieldSection,
): boolean {
	return fieldCursor.index === index && fieldCursor.name === field.propertyName && fieldCursor.section === section.name
}

export function getField(metadata: ScanFactoryDocumentFileMetadata, fieldCursor: FieldCursor): ?ScanFactoryField {
	const withIndex = find(get(metadata, [fieldCursor.section, fieldCursor.index.toString()]), {
		propertyName: fieldCursor.name,
	})
	if (withIndex) {
		return withIndex
	}

	const withoutIndex = find(get(metadata, [fieldCursor.section]), { propertyName: fieldCursor.name })
	if (withoutIndex) {
		return withoutIndex
	}
}

export function setField(
	metadata: ScanFactoryDocumentFileMetadata,
	fieldCursor: FieldCursor,
	field: ScanFactoryField,
): ?ScanFactoryDocumentFileMetadata {
	const withIndex = find(get(metadata, [fieldCursor.section, fieldCursor.index.toString()]), {
		propertyName: fieldCursor.name,
	})
	if (withIndex) {
		const fieldIndex = findIndex(metadata[fieldCursor.section][fieldCursor.index], { propertyName: fieldCursor.name })
		return set([fieldCursor.section, fieldCursor.index.toString(), fieldIndex.toString()], { ...field }, metadata)
	}

	const withoutIndex = find(get(metadata, [fieldCursor.section]), { propertyName: fieldCursor.name })
	if (withoutIndex) {
		const fieldIndex = findIndex(metadata[fieldCursor.section], { propertyName: fieldCursor.name })
		return set([fieldCursor.section, fieldIndex.toString()], { ...field }, metadata)
	}
}

export function resetField(
	metadata: ScanFactoryDocumentFileMetadata,
	fieldCursor: FieldCursor,
): ScanFactoryDocumentFileMetadata {
	const field = getField(metadata, fieldCursor)

	if (!field) {
		return metadata
	}

	const newField = { ...field }
	delete newField.value
	delete newField.relatedBBWords

	const newMetadata = setField(metadata, fieldCursor, newField)
	if (newMetadata) {
		return newMetadata
	} else {
		return metadata
	}
}

export function fieldIterator(
	metadata: ScanFactoryDocumentFileMetadata,
	callback: (field: ScanFactoryField, fieldCursor: FieldCursor) => void,
) {
	getFieldSections().map((fieldSection: FieldSection) => {
		if (fieldSection.isRepeatable) {
			metadata[fieldSection.name].map((repeatableSection: Array<ScanFactoryField>, i: number) => {
				repeatableSection.map((field: ScanFactoryField) =>
					callback(field, { section: fieldSection.name, index: i, name: field.propertyName || '' }),
				)
			})
		} else {
			metadata[fieldSection.name].map((field: ScanFactoryField) =>
				callback(field, { section: fieldSection.name, index: 0, name: field.propertyName || '' }),
			)
		}
	})
}

export function getBbWordFieldMap(metadata: ScanFactoryDocumentFileMetadata): BbWordFieldMap {
	const map = {}
	fieldIterator(metadata, (field: ScanFactoryField, fieldCursor: FieldCursor) => {
		if (field.relatedBBWords) {
			field.relatedBBWords.map((bbWord: ScanFactoryBbWord) => {
				const bbWordCursor = getBbWordCursor(metadata, bbWord)
				if (bbWordCursor) {
					const bbWordCursorKey = getBbWordCursorKey(bbWordCursor)
					if (!map[bbWordCursorKey]) {
						map[bbWordCursorKey] = []
					}
					map[bbWordCursorKey].push(fieldCursor)
				}
			})
		}
	})

	return map
}

export function getFieldBbWordMap(metadata: ScanFactoryDocumentFileMetadata): FieldBbWordMap {
	const map = {}
	fieldIterator(metadata, (field: ScanFactoryField, fieldCursor: FieldCursor) => {
		if (field.relatedBBWords) {
			const bbWordCursors = []
			field.relatedBBWords.map((bbWord: ScanFactoryBbWord) => {
				const bbWordCursor = getBbWordCursor(metadata, bbWord)
				if (bbWordCursor) {
					bbWordCursors.push(bbWordCursor)
				}
			})
			map[getFieldCursorKey(fieldCursor)] = bbWordCursors
		}
	})

	return map
}

export function addRepeatableSection(
	metadata: ScanFactoryDocumentFileMetadata,
	section: string,
): ScanFactoryDocumentFileMetadata {
	const sectionToAdd = [...metadata[section][0]]

	sectionToAdd.forEach((field: ScanFactoryField, index: number) => {
		const newField = { ...field }
		delete newField.value
		delete newField.relatedBBWords
		sectionToAdd[index] = newField
	})

	return {
		...metadata,
		[section]: [...metadata[section], ...[sectionToAdd]],
	}
}

export function removeRepeatableSection(
	metadata: ScanFactoryDocumentFileMetadata,
	section: string,
	index: number,
): ScanFactoryDocumentFileMetadata {
	const updatedMetadata = {
		...metadata,
		[section]: [...metadata[section]],
	}

	updatedMetadata[section].splice(index, 1)

	return updatedMetadata
}

export function countRepeatableSections(metadata: ScanFactoryDocumentFileMetadata, section: string): number {
	const currentSection = getFieldSection(section)
	if (!currentSection) {
		return 0
	}

	if (!currentSection.isRepeatable) {
		return 1
	}

	return get(metadata, section, []).length
}

export function getNextFieldCursor(
	metadata: ScanFactoryDocumentFileMetadata,
	currentFieldCursor?: FieldCursor,
): ?FieldCursor {
	const array = getFieldCursorArray(metadata)
	return _next(array, currentFieldCursor)
}

export function getPrevFieldCursor(
	metadata: ScanFactoryDocumentFileMetadata,
	currentFieldCursor?: FieldCursor,
): ?FieldCursor {
	const array = getFieldCursorArray(metadata)
	return _prev(array, currentFieldCursor)
}

export function getFieldCursorArray(metadata: ScanFactoryDocumentFileMetadata): Array<FieldCursor> {
	const array: Array<FieldCursor> = []
	fieldIterator(metadata, (_: ScanFactoryField, fieldCursor: FieldCursor) => {
		array.push(fieldCursor)
	})

	return array
}

export function _next<T: Object>(array: Array<T>, current?: T): ?T {
	if ('undefined' === typeof current) {
		return array[0]
	}

	const currentIndex = findIndex(array, current)
	if (-1 === currentIndex || currentIndex === array.length - 1) {
		return undefined
	}

	return array[currentIndex + 1]
}

export function _prev<T: Object>(array: Array<T>, current?: T): ?T {
	if ('undefined' === typeof current) {
		return array[array.length - 1]
	}

	const currentIndex = findIndex(array, current)
	if (-1 === currentIndex || 0 === currentIndex) {
		return undefined
	}

	return array[currentIndex - 1]
}

export function isFieldCursorsEqual(cursor1?: ?FieldCursor, cursor2?: ?FieldCursor): boolean {
	if (!cursor1 || !cursor2) {
		return false
	}

	return cursor1.name === cursor2.name && cursor1.section === cursor2.section && cursor1.index === cursor2.index
}

export function isSomeFieldCursorEqual(cursor?: FieldCursor, cursors?: ?Array<FieldCursor>): boolean {
	if (!cursor || !cursors || 0 === cursors.length) {
		return false
	}

	return cursors.some((currentCursor: FieldCursor) => isFieldCursorsEqual(currentCursor, cursor))
}

export function getRelatedFieldCursors(bbWordFieldMap: BbWordFieldMap, bbWordCursor: BbWordCursor): Array<FieldCursor> {
	const key = getBbWordCursorKey(bbWordCursor)
	if (bbWordFieldMap[key]) {
		return bbWordFieldMap[key]
	}

	return []
}

export function getFieldCursorKey(fieldCursor: FieldCursor): string {
	return `${fieldCursor.section}-${fieldCursor.index}-${fieldCursor.name}`
}

function firstCaseToLower(string: string): string {
	return string.charAt(0).toLowerCase() + string.slice(1)
}

export function getFieldTranslationInfo(name: string): { group?: string, key: string } {
	const regexp = /^(Counterparty)(.*)/
	const match = name.match(regexp)
	let result = {}

	if (match && match[2]) {
		result.group = 'documentExtraction.' + firstCaseToLower(match[1])
		result.key = 'fieldName.contact.' + firstCaseToLower(match[2])
	} else {
		result.key = 'fieldName.accountingDocument.' + firstCaseToLower(name)
	}

	return result
}
