// @flow
/** @jsx jsx */

import { Component } from 'react'
import { jsx } from '@emotion/core'
import { confirmable } from 'react-confirm'
import Dialog from 'components/dialog'
import Button from 'components/button'
import DeleteButton from 'components/delete-button'
import ConfirmButton from 'components/confirm-button'

type Props = {
	show: boolean,
	confirmation: any,
	okLabel: string,
	cancelLabel: string,
	title: string,
	body?: React$Element<any>,
	waitForConfirm?: boolean,
	confirmButtonTooltip?: string,
	proceed: () => void,
	dismiss: () => void,
	cancel: () => void,
}

class DeleteDialog extends Component<Props> {
	handleCancel = () => {
		this.props.cancel()
	}

	render() {
		const { body, show, proceed, okLabel, cancelLabel, title, waitForConfirm, confirmButtonTooltip } = this.props
		const cancelButton = (
			<Button
				autoTestId="delete-dialog-button-cancel"
				key="cancel"
				labelText={cancelLabel}
				onClick={this.handleCancel}
				secondary
				wide
			/>
		)
		const deleteButton = waitForConfirm ? (
			<ConfirmButton
				autoTestId="confirm-dialog-button-confirm"
				tooltip={confirmButtonTooltip}
				key="delete"
				labelText={okLabel}
				onConfirm={proceed}
				wide
			/>
		) : (
			<DeleteButton autoTestId="confirm-dialog-button-delete" key="delete" labelText={okLabel} onClick={proceed} wide />
		)

		return (
			<Dialog open={show} title={title} actions={[deleteButton, cancelButton]} autoTestId="delete-dialog">
				<div css={style.container}>{body}</div>
			</Dialog>
		)
	}
}

const style = {
	container: {
		fontSize: 16,
		textAlign: 'center',
		fontWeight: 'normal',
	},
}

export default confirmable(DeleteDialog)
