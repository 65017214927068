/* @flow */
/** @jsx jsx */

import { Component } from 'react'
import { jsx } from '@emotion/core'
import memoize from 'memoize-one'
import { autoTestId, type AutoTestProps } from 'utils/tests/autotest'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import { getSections, type Section, type NavigationItem } from './add-button-sections'
import Popover from 'components/Popover'
import Separator from 'components/Separator'
import AddButtonIcon from './add-button-icon'
import { colors, depths } from 'variables'

type Props = {|
	...AutoTestProps,
	...WithTranslateProps,
	currentUserIsInternal: boolean,
	canEditIssuedAccountingDocuments: boolean,
	canEditReceivedAccountingDocuments: boolean,
	canEditContacts: boolean,
	onNavigate: (id: string, params: { [string]: any }) => void,
|}

type State = {|
	anchorEl: ?EventTarget,
	open: boolean,
|}

class AddButton extends Component<Props, State> {
	anchorOrigin = { horizontal: 'right', vertical: 'bottom' }
	targetOrigin = { horizontal: 'right', vertical: 'top' }

	state = {
		anchorEl: null,
		open: false,
	}

	sections: Array<Section>

	getSections = memoize((props: Props) => {
		return getSections(props.t, {
			showReceived: props.canEditReceivedAccountingDocuments && props.currentUserIsInternal,
			showUpload: props.canEditReceivedAccountingDocuments,
			showIssued: props.canEditIssuedAccountingDocuments,
			showCreateContact: props.canEditContacts,
			// showCreateTask: true,
		})
	})

	handleOnClick = () => {
		this.setState({
			anchorEl: this.refs.root,
			open: true,
		})
	}

	handleOnRequestClose = () => {
		this.setState({
			open: false,
		})
	}

	onNavigationItemClick = (item: NavigationItem) => {
		return () => {
			this.props.onNavigate(item.id, item.params || {})
			this.setState({
				open: false,
			})
		}
	}

	renderSections = (sections: Array<Section>) => {
		return sections.map((section: Section, index: number) => (
			<div key={index} style={style.list}>
				<Separator style={style.separator} left={<span css={style.headline}>{section.label}</span>} />
				{section.items.map((item: NavigationItem, index: number) => (
					<div
						{...autoTestId(
							this.props.autoTestId,
							`${item.id}-${(item.params && item.params.direction) || 'nodirection'}`,
						)}
						css={item.tall ? style.tallItem : style.item}
						onClick={this.onNavigationItemClick(item)}
						key={index}
					>
						{item.icon ? (
							<span id={'icon'} css={style.icon}>
								{item.icon}
							</span>
						) : (
							undefined
						)}
						<span>{item.label}</span>
					</div>
				))}
			</div>
		))
	}

	render() {
		const { open } = this.state
		return (
			<div style={style.root} ref="root" {...autoTestId(this.props.autoTestId)}>
				<AddButtonIcon label={this.props.t('accountingDocument.addButton')} open={open} onClick={this.handleOnClick} />
				<Popover
					open={this.state.open}
					anchorEl={this.state.anchorEl}
					anchorOrigin={this.anchorOrigin}
					targetOrigin={this.targetOrigin}
					zDepth={4}
					style={style.popover}
					useLayerForClickAway
					onRequestClose={this.handleOnRequestClose}
					arrow={'topRight'}
					maxHeight={640}
				>
					{this.renderSections(this.getSections(this.props))}
				</Popover>
			</div>
		)
	}
}

const itemStyle = {
	color: colors.black,
	padding: '12px 15px 12px 75px',
	cursor: 'pointer',
	position: 'relative',
	fontSize: 14,
	lineHeight: '16px',
	'&:hover': {
		background: '#ffedce',
		svg: {
			fill: colors.white,
		},
		'#icon': {
			backgroundColor: colors.orange,
			borderColor: colors.orange600,
		},
	},
}

const style = {
	popover: {
		minWidth: 270,
		marginLeft: -1,
		marginTop: 8,
	},
	list: {
		paddingTop: 10,
		paddingBottom: 10,
	},
	root: {
		position: 'fixed',
		top: 20,
		right: 83,
		zIndex: depths.addButton,
	},
	separator: {
		margin: '0 15px 10px 20px',
	},
	headline: {
		color: colors.blackFaded40,
		fontSize: 12,
		textTransform: 'uppercase',
		paddingRight: 20,
		lineHeight: '20px',
	},
	item: itemStyle,
	tallItem: {
		...itemStyle,
		marginBottom: 10,
	},
	icon: {
		position: 'absolute',
		top: 0,
		left: 20,
		width: 40,
		height: 40,
		padding: '8px 5px',
		backgroundColor: colors.white,
		boxShadow: `2px 2px 0 0 ${colors.blackFaded8}`,
		border: '1px solid #CDCDCD',
		borderRadius: '50%',
	},
}

export default withTranslate(AddButton)
