/* @flow */

import React, { Component } from 'react'
import { TabSwitch, TabSwitches } from 'components/TabSwitches'
import Button from 'components/button'
import Dialog from 'components/dialog'
import TextField from 'components/TextField'
import DatePicker from 'components/date-picker'
import TreeEnumSelector from 'components/tree-enum-selector'
import type { Enum } from 'types'

import {
	withTranslate,
	type WithTranslateProps,
	withNotify,
	type WithNotifyProps,
	validate,
	type FormValidationProps,
} from 'wrappers'

export const TYPES_TRANSLATIONS = {
	'0': 'settings.accountingSettings.payerTaxType',
	'1': 'settings.accountingSettings.vatFree',
	'2': 'settings.accountingSettings.identified',
}

export type Props = {|
	value: ?number,
	labelStyle?: {},
	confirmable?: boolean,
	type?: 'dropdown',
	disabled?: boolean,
	note?: string,
	from?: Date,
	inline?: boolean,
	minDate?: Date,
	vatPeriod?: number,
	hideVatPeriod?: boolean,
	onChange?: (type: number, from?: Date, note?: string, vatPeriod?: number) => void,
|}

type ComponentState = {|
	dialogOpen: boolean,
	from: Date,
	note: string,
	value: ?number,
	vatPeriod: number,
|}

type ComponentProps = { ...Props, ...FormValidationProps, ...WithTranslateProps, ...WithNotifyProps }

class PayerTaxTypeSelector extends Component<ComponentProps, ComponentState> {
	state: ComponentState = {
		dialogOpen: false,
		from: this.props.from || new Date(),
		note: this.props.note || '',
		value: this.props.value,
		vatPeriod: this.props.vatPeriod === 3 ? 1 : this.props.vatPeriod || 1,
	}

	UNSAFE_componentWillReceiveProps(nextProps: ComponentProps) {
		const vatPeriod = nextProps.vatPeriod === 3 ? 1 : nextProps.vatPeriod || 1
		this.setState({ value: nextProps.value, vatPeriod })
	}

	handleChange = (value: ?string | Array<string>) => {
		const parsedValue: number = parseInt(Array.isArray(value) ? value[0] : value)

		if (!this.props.confirmable) {
			return this.props.onChange && this.props.onChange(parsedValue)
		}
		this.showDialog(parsedValue)
	}

	showDialog = (value: number) => {
		this.setState({ dialogOpen: true, value })
	}

	closeDialog = (value: ?number) => {
		this.setState({
			dialogOpen: false,
			from: this.props.from || new Date(),
			note: this.props.note || '',
			value,
		})
	}

	dialogProcced = () => {
		const { t } = this.props
		const { value, from, note } = this.state
		const vatPeriod = value === 3 ? 3 : this.state.vatPeriod
		if (value != null && from) {
			this.props.onChange && this.props.onChange(value, from, note, vatPeriod)
			this.closeDialog(value)
		} else {
			this.props.notify(t('clientError.fillAll'), 'error')
		}
	}

	onNoteChange = (event: SyntheticInputEvent<HTMLInputElement>, note: ?string) => {
		if (note !== null && note !== undefined) {
			this.setState({ note })
		}
	}

	onFromChange = (event: ?SyntheticInputEvent<HTMLInputElement>, from: ?Date) => {
		from && this.setState({ from: new Date(from) })
	}

	onDropdownChange = (value: ?string | Array<string>) => {
		const parsedValue: ?number = parseInt(Array.isArray(value) ? value[0] : value)
		this.props.onChange &&
			parsedValue &&
			this.props.onChange(parsedValue, this.props.from, this.props.note, this.props.vatPeriod)
	}

	onVatPeriodChange = (vatPeriod: number) => {
		this.setState({ vatPeriod })
	}

	renderDialog() {
		const { t } = this.props
		this.props.validateForm({
			payerTaxTypeFrom: !this.state.from
				? t('clientError.noField', { field: t('settings.accountingSettings.payerTaxTypeFrom') })
				: null,
		})
		const showVatPeriod = !this.props.hideVatPeriod && this.state.value != 1
		const dialogActions = [
			<Button
				autoTestId="payer-tax-type-dialog-delete"
				key="delete"
				labelText={t('dialogs.yesOption')}
				onClick={this.dialogProcced}
				primary
				wide
			/>,
			<Button
				key="cancel"
				autoTestId="payer-tax-type-dialog-cancel"
				labelText={t('forms.cancelButtonLabel')}
				onClick={() => this.closeDialog(this.props.value)} //eslint-disable-line react/jsx-no-bind
				secondary
				wide
			/>,
		]
		return (
			<Dialog
				autoTestId="payer-pax-type-dialog"
				title={
					this.state.value != null
						? t('settings.accountingSettings.changePayerTaxTypeTitle', {
								type: t(TYPES_TRANSLATIONS[this.state.value]),
						  })
						: ''
				}
				actions={dialogActions}
				open={this.state.dialogOpen}
			>
				<div
					style={{
						width: 300,
						margin: '10px auto 0 auto',
					}}
				>
					<div style={{ marginBottom: 18 }}>
						<DatePicker
							fullWidth
							value={this.state.from}
							onChange={this.onFromChange}
							labelText={t('settings.accountingSettings.payerTaxTypeFrom')}
							clientError={this.props.validationMessage('payerTaxTypeFrom')}
							autoTestId="payer-tax-type-from-date"
							minDate={this.props.minDate}
						/>
					</div>
					{showVatPeriod && (
						<div style={{ marginBottom: 18 }}>
							<TabSwitches
								label={t('settings.accountingSettings.vatPeriod')}
								onChange={this.onVatPeriodChange}
								value={this.state.vatPeriod}
								autoTestId="payer-tax-type-selector-vat-period"
							>
								<TabSwitch label={t('settings.accountingSettings.monthly')} value={1} />
								<TabSwitch label={t('settings.accountingSettings.quartely')} value={2} />
							</TabSwitches>
						</div>
					)}
					<div style={{ marginBottom: 18 }}>
						<TextField
							fullWidth
							value={this.state.note}
							onChange={this.onNoteChange}
							labelText={t('settings.accountingSettings.payerTaxTypeNote')}
							clientError={this.props.validationMessage('payerTaxTypeNote')}
							autoTestId="payertax-type-note"
							name="note"
						/>
					</div>
				</div>
			</Dialog>
		)
	}

	render() {
		const {
			t,
			confirmable,
			notify,
			clearNotification,
			globalValidationMessage,
			validateForm,
			isFormValid,
			validationSubmit,
			validationProps,
			validationValue,
			validationMessage,
			resetValidation,
			type,
			note,
			from,
			onChange,
			value,
			inline,
			minDate,
			vatPeriod,
			hideVatPeriod,
			labelStyle,
			...rest
		} = this.props //eslint-disable-line

		switch (type) {
			case 'dropdown': {
				const items: Enum = Object.entries(TYPES_TRANSLATIONS).map(([type, translation]: any) => ({
					key: type,
					value: t(translation),
				}))
				return (
					<TreeEnumSelector
						{...rest}
						autoTestId="payer-pax-type-selector"
						value={`${this.props.value != null ? this.props.value : ''}`}
						values={items}
						onChange={this.onDropdownChange}
						fullWidth
						inline={inline}
					/>
				)
			}
			default:
				return (
					<div>
						<TabSwitches
							{...rest}
							labelStyle={this.props.labelStyle}
							label={t('settings.accountingSettings.payerTaxType')}
							onChange={this.handleChange}
							value={this.state.value}
							autoTestId="payer-tax-type-selector-tax-type"
						>
							{Object.entries(TYPES_TRANSLATIONS).map(([type, translation]: any) => (
								<TabSwitch key={type} label={t(translation)} value={parseInt(type)} />
							))}
						</TabSwitches>
						{this.props.confirmable && this.renderDialog()}
					</div>
				)
		}
	}
}

export default withTranslate(withNotify(validate()(PayerTaxTypeSelector)))
