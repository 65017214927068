/* @flow */

import React, { Component } from 'react'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import type { DocumentItem } from 'types'
import {
	getDocumentItemName,
	getIsFolder,
	getShowDocListItemCheckbox,
	getShowRightIconMenu,
	getShowDocAttachmentsCheckbox,
} from 'helpers'
import Checkbox from 'components/checkbox'
import DeleteDialog from 'components/delete-dialog'
import FileIcon from 'components/file-icon'
import IconMenu from 'components/icon-menu'
import MenuItem from 'components/menu-item'
import TextField from 'components/TextField'
import FullText from 'components/full-text'
import Folder from 'components/svg-icons/file/folder'
import Tooltip from 'components/tooltip'
import styles from './document-list-item.css'
import { colors } from 'variables'
import { handleDownloadFile } from '../../file/domain'

const TARGET_ORIGIN = { horizontal: 'right', vertical: 'top' }

export type Props = {|
	item: DocumentItem,
	canBeChanged: boolean,
	canBeRemoved: boolean,
	showInEditMode: boolean,
	checked: boolean,
	showActions: boolean,
	showFileCheckbox: boolean,
	showFolderCheckbox: boolean,
	deselectTooltip?: string,
	selectTooltip?: string,
	onClick?: (item: DocumentItem) => void,
	onDelete?: (itemId: string) => Promise<void>,
	onChange?: (item: DocumentItem) => ?Promise<void>,
	onMove?: (item: DocumentItem) => void,
	onCancelEdit?: (item: DocumentItem) => ?Promise<void>,
	onItemCheck?: (file: DocumentItem, checked: boolean) => void,
	isAttachDocumentList?: boolean,
	className?: string,
	style?: Object,
	...WithTranslateProps,
|}

type State = {|
	savedItem: DocumentItem,
	editedItem: DocumentItem,
	editedField: ?string,
|}

const focusTextField = (input: ?TextField) => {
	if (input) {
		setTimeout(() => {
			// $FlowFixMe
			input && input.getWrappedInstance().focus()
		}, 200)
	}
}

class DocumentListItem extends Component<Props, State> {
	static defaultProps = {
		canBeChanged: false,
		canBeRemoved: false,
		showInEditMode: false,
		checked: false,
		showActions: false,
		showFileCheckbox: false,
		showFolderCheckbox: false,
	}

	state = {
		savedItem: this.props.item,
		editedItem: this.props.item,
		editedField: this.props.showInEditMode ? 'name' : null,
	}

	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		if (this.props.item !== nextProps.item) {
			this.setState({
				editedItem: nextProps.item,
			})
		}
	}

	handleEditToggle = (editedField?: string) => {
		this.setState({
			editedField: editedField,
		})
	}

	handleClick = (e: Event) => {
		if (this.state.editedField) {
			return
		}

		e.stopPropagation()
		e.preventDefault()

		this.props.onClick && this.props.onClick(this.props.item)
	}

	handleItemNameChange = (event: SyntheticInputEvent<HTMLInputElement>, value: ?string) => {
		if (value !== null && value !== undefined) {
			this.setState({
				editedItem: { ...this.state.editedItem, name: value },
			})
		}
	}

	handleItemNoteChange = (event: SyntheticInputEvent<HTMLInputElement>, value: ?string) => {
		if (value !== null && value !== undefined) {
			this.setState({
				editedItem: { ...this.state.editedItem, note: value },
			})
		}
	}

	handleItemNameBlur = (event: ?SyntheticInputEvent<HTMLInputElement>) => {
		event && event.preventDefault()
		this.handleSave()
	}

	handleSave = () => {
		let name = !(this.state.editedItem.name || '').trim() ? this.state.savedItem.name : this.state.editedItem.name
		this.setState(
			{
				editedItem: { ...this.state.editedItem, name: name },
			},
			this.saveEditedItem,
		)
	}

	saveEditedItem = () => {
		this.props.onChange && this.props.onChange(this.state.editedItem)
		this.setState({ savedItem: this.state.editedItem })
		this.handleEditToggle()
	}

	deleteItem = async () => {
		const { item, onDelete } = this.props

		try {
			await DeleteDialog()
			if (item.uniqueId) {
				onDelete && onDelete(item.uniqueId)
			}
		} catch (e) {
			// Do nothing
		}
	}

	handleDeleteItem = () => {
		this.deleteItem()
	}

	handleDownloadItem = () => {
		this.downloadItem()
	}

	downloadItem = async () => {
		const { item } = this.props

		if (item.fileId && item.name) {
			item.fileId && item.name && (await handleDownloadFile(item.fileId, item.name, true))
		}
	}

	handleTextFieldKeyPress = (event: SyntheticKeyboardEvent<HTMLInputElement>) => {
		if (event.key === 'Enter') {
			event && event.preventDefault()
			this.handleSave()
		}
		if (event.key === 'Escape') {
			this.props.onCancelEdit && this.props.onCancelEdit(this.props.item)
			this.handleEditToggle()
		}
	}

	handleRenameClick = () => {
		this.handleEditToggle('name')
	}

	handleNoteClick = () => {
		this.handleEditToggle('note')
	}

	handleMoveClick = () => {
		this.props.onMove && this.props.onMove(this.props.item)
	}

	stopPropagation = (event: SyntheticEvent<HTMLElement>) => event.stopPropagation()

	renderRightIconMenu = () => {
		const { t, showActions, canBeChanged, canBeRemoved, item } = this.props

		if (!getShowRightIconMenu(item, showActions, canBeChanged, canBeRemoved)) {
			return null
		}

		return (
			<span className={styles.menu}>
				<IconMenu
					autoTestId="document-list-item-actions"
					onClick={this.stopPropagation}
					arrow={'topRight'}
					targetOrigin={TARGET_ORIGIN}
					offsetX={19}
					context
				>
					{canBeChanged ? (
						<MenuItem tall onClick={this.handleRenameClick} primaryText={t('grid.action.rename')} />
					) : null}
					{canBeRemoved ? (
						<MenuItem tall onClick={this.handleDeleteItem} primaryText={t('grid.action.delete')} />
					) : null}
					{!getIsFolder(item) ? (
						<MenuItem onClick={this.handleDownloadItem} primaryText={t('grid.action.download')} tall />
					) : null}
					{canBeChanged ? (
						<MenuItem tall onClick={this.handleNoteClick} primaryText={t('document.noteAction')} />
					) : null}
					{canBeChanged ? (
						<MenuItem tall onClick={this.handleMoveClick} primaryText={t('document.moveAction')} />
					) : null}
				</IconMenu>
			</span>
		)
	}

	renderFolderNameInput() {
		const hintTextKey = getIsFolder(this.props.item) ? 'document.folderName' : 'document.fileName'
		return (
			<TextField
				ref={focusTextField}
				onKeyDown={this.handleTextFieldKeyPress}
				hintText={this.props.t(hintTextKey)}
				value={this.state.editedItem.name}
				onBlur={this.handleItemNameBlur}
				onChange={this.handleItemNameChange}
				autoWidth
				autoFocus
				inline
				name="name"
				autoTestId="document-list-item-name"
			/>
		)
	}

	renderNoteInput() {
		return (
			<TextField
				ref={focusTextField}
				onKeyDown={this.handleTextFieldKeyPress}
				hintText={this.props.t('document.note')}
				value={this.state.editedItem.note || ''}
				onBlur={this.handleSave}
				onChange={this.handleItemNoteChange}
				autoTestId="document-list-item-note"
				autoWidth
				inline
				name="note"
			/>
		)
	}

	renderItemCheckbox = (item: DocumentItem) => {
		if (
			getShowDocAttachmentsCheckbox(item, this.props.isAttachDocumentList, this.props.showFileCheckbox) ||
			(!this.props.isAttachDocumentList &&
				getShowDocListItemCheckbox(
					item,
					this.props.showFileCheckbox,
					this.props.showFolderCheckbox,
					this.props.showActions,
					this.props.canBeChanged,
					this.props.canBeRemoved,
				))
		) {
			const onCheck = (event: SyntheticInputEvent<HTMLInputElement>, checked: boolean) => {
				this.props.onItemCheck && this.props.onItemCheck(item, checked)
			}
			const checked = this.props.checked
			return (
				<span className={styles.checkbox}>
					<Tooltip
						label={this.props.checked ? this.props.deselectTooltip : this.props.selectTooltip}
						wrapperStyle={style.wrapper}
						style={style.tooltip}
					>
						<Checkbox
							onCheck={onCheck} // eslint-disable-line react/jsx-no-bind
							checked={checked}
						/>
					</Tooltip>
				</span>
			)
		} else {
			return this.props.showFileCheckbox || this.props.showFolderCheckbox ? <span className={styles.checkbox} /> : null
		}
	}

	render() {
		const { item } = this.props
		const isFolder: boolean = getIsFolder(item)

		return (
			<div
				id={item.uniqueId}
				className={`${this.props.checked ? styles.checked : styles.normal} ${this.props.className || ''}`}
				onClick={this.handleClick}
				style={this.props.style}
			>
				{this.renderItemCheckbox(item)}
				{isFolder ? (
					<span className={styles.icon}>
						<Folder size={20} color={colors.gray} hoverColor={colors.gray} />
					</span>
				) : (
					<span className={styles.icon}>
						<FileIcon size={20} fileName={item.name} />
					</span>
				)}
				{this.state.editedField === 'name' ? (
					<span className={styles.editingName}>{this.renderFolderNameInput()}</span>
				) : (
					<div className={styles.name}>
						<FullText text={getDocumentItemName(this.props.t, item)} />
					</div>
				)}
				{this.state.editedField === 'note' ? (
					<span className={styles.editingNote}>{this.renderNoteInput()}</span>
				) : (
					<div style={style.note}>
						<FullText text={item.note} />
					</div>
				)}
				{this.renderRightIconMenu()}
			</div>
		)
	}
}

const style = {
	note: {
		lineHeight: '24px',
		height: 24,
		fontSize: 14,
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		paddingRight: 15,
		width: '30%',
		flex: '1 1 30%',
	},
	tooltip: { marginTop: 5 },
	wrapper: { width: 24 },
}

export default withTranslate(DocumentListItem)
