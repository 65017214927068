//@flow
import React, { Component } from 'react'
import type { UserProfile } from 'types'
import { withTranslate, withNotify, type WithTranslateProps, type WithNotifyProps } from 'wrappers'
import MembershipTypeSelector from 'modules/common/components/selectors/membership-type-selector'
import { getUserFullName } from 'modules/user/domain/user'
import Dialog from 'components/dialog'
import Button from 'components/button'
import styles from '../settings.css'

const HIDDEN_TYPES = [0, 1]

type Props = {|
	...WithTranslateProps,
	...WithNotifyProps,
	user: ?UserProfile,
	open: boolean,
	disabledTypes: Array<number>,
	onRequestClose: () => void,
	onSave: (userId: string, membershipType: number) => void,
|}

type State = {|
	membershipType: ?number,
|}

class InternalUserEdit extends Component<Props, State> {
	state = {
		membershipType: null,
	}

	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		nextProps.user &&
			this.setState({
				membershipType: nextProps.user.membershipType,
			})
	}

	save = () => {
		this.props.user &&
			this.props.user.id &&
			this.state.membershipType &&
			this.props.onSave(this.props.user.id, this.state.membershipType)
		this.props.onRequestClose()
	}

	onMembershipTypeChange = (membershipType: number) => {
		this.setState({ membershipType })
	}

	getUserName = () => {
		const { user } = this.props
		return user ? (user.firstname || user.lastname ? getUserFullName(user) : user.email || user.id || '') : ''
	}

	render() {
		const { disabledTypes, user } = this.props
		const notAvailableTypes = disabledTypes.filter((type: number) => {
			return user && user.membershipType !== type
		})
		const actions = [
			<Button
				autoTestId="settings-users-edit-dialog-save"
				primary
				wide
				key="okButton"
				labelText={this.props.t('settings.forms.saveButton')}
				onClick={this.save}
			/>,
			<Button
				secondary
				wide
				key="cancelButton"
				labelText={this.props.t('settings.forms.cancelButton')}
				onClick={this.props.onRequestClose}
				autoTestId="settings-users-edit-dialog-cancel"
			/>,
		]

		return (
			<Dialog
				autoTestId="internal-user-edit-dialog"
				title={this.props.t('settings.users.editInternal', { user: this.getUserName() })}
				actions={actions}
				open={this.props.open}
			>
				<div className={styles.oneColumnDialog}>
					<div className={styles.row}>
						<MembershipTypeSelector
							fullWidth
							onChange={this.onMembershipTypeChange}
							hiddenTypes={HIDDEN_TYPES}
							disabledTypes={notAvailableTypes}
							value={this.state.membershipType}
						/>
					</div>
				</div>
			</Dialog>
		)
	}
}

export default withTranslate(withNotify(InternalUserEdit))
