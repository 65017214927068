/* @flow */
/** @jsx jsx */

import { Component } from 'react'
import { jsx } from '@emotion/core'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import { autoTestId } from 'utils/tests/autotest'
import NativeInput from './native-input'
import Button from 'components/button'
import Loader from 'components/loader'

type Props = {|
	onLogin: (username: string, password: string, invitationToken?: string) => void,
	prefilledEmail?: ?string,
	invitationToken?: string,
	...WithTranslateProps,
|}

type State = {|
	username: string,
	password: string,
	loading: boolean,
|}

class LoginForm extends Component<Props, State> {
	state = {
		username: this.props.prefilledEmail || '',
		password: '',
		loading: false,
	}

	handleFormSubmit = async (event: Event) => {
		event.preventDefault()
		this.setState({ loading: true })
		await this.props.onLogin(this.state.username, this.state.password, this.props.invitationToken)
		this.setState({ loading: false })
	}

	handleUsernameChange = (event: Event) => {
		event.target instanceof HTMLInputElement && this.setState({ username: event.target.value })
	}

	handlePasswordChange = (event: Event) => {
		event.target instanceof HTMLInputElement && this.setState({ password: event.target.value })
	}

	renderFields() {
		const { t } = this.props
		const disabled = this.state.loading

		return (
			<div css={styles.fields}>
				<div css={styles.field}>
					<NativeInput
						onChange={this.handleUsernameChange}
						value={this.state.username || ''}
						placeholder={t('user.login.loginLabel')}
						type="email"
						name="login"
						disabled={disabled}
					/>
				</div>
				<div css={styles.field}>
					<NativeInput
						onChange={this.handlePasswordChange}
						value={this.state.password || ''}
						placeholder={t('user.login.passwordLabel')}
						type="password"
						name="password"
						disabled={disabled}
					/>
				</div>
				{this.state.loading && <Loader size={60} visible />}
			</div>
		)
	}

	render() {
		const { t } = this.props

		return (
			<form onSubmit={this.handleFormSubmit} css={styles.root} noValidate {...autoTestId('login-form')}>
				{this.renderFields()}
				<Button
					autoTestId="login-signin"
					disabled={this.state.loading}
					name="sign-in"
					type="submit"
					labelText={t('user.login.submitLabel')}
					fullWidth
					tall
				/>
			</form>
		)
	}
}

const styles = {
	root: {
		margin: '40px auto',
		textAlign: 'center',
		maxWidth: 340,
		width: '100%',
	},
	fields: {
		position: 'relative',
		marginBottom: 10,
	},
	field: {
		marginBottom: 25,
	},
}

export default withTranslate(LoginForm)
