/* @flow */

import type { Direction } from 'modules/data-grid-next/types'
import React, { Component } from 'react'
import type { Filter, SearchFilter, I18NextProps, SearchRequest, SearchResult } from 'types'
import { translate } from 'react-i18next'
import { Grid, applyGridConfig } from 'react-redux-grid'
import type { Action } from '../types'
import DataGridActions from './data-grid-actions'
import DataGridCheckbox from './data-grid-checkbox'
import MultiActionsToolbar from './multi-actions-toolbar'
import deepEqual from 'deep-equal'
import { Pager } from 'components'
import { EMPTY_ARRAY, MULTI_ACTION_TYPE_CLEAR_SELECTION } from 'trivi-constants'
import styles from './data-grid.css'

export type Props<Item> = {|
	columns: Array<*>,
	apiFunction: SearchRequest => Promise<SearchResult>,
	dataFieldName: string,
	stateKey: string,
	pageSize: number,
	filter: Filter,
	actions: ?Array<Action<Item>>,
	multiActions?: ?Array<Action<Array<Item>>>,
	currentPage: number,
	onFilterChange: ({
		pageIndex: number,
		dataSource: Function,
		stateKey: string,
	}) => void,
	onAction: ?(string, Item) => Promise<void>,
	onMultiAction?: ?(string, Array<Item>) => void,
	onActionMenuOpen?: (Item, () => void) => ?Promise<void>,
	onNeedUpdateMultiActionVisiblity?: (Array<Item>) => Promise<Array<string>>,
	onPageChange: ({
		pageIndex: number,
		dataSource: Function,
		stateKey: string,
	}) => void,
	//onPageSizeChange: (pageSize: number) => void,
	onColumnsChanged: (Array<{}>) => void,
	updateRow: string => void,
	deselectAll: () => void,
	sort: {
		field?: string,
		direction?: Direction,
	},
	additionalData?: ?{},
	light?: ?boolean,
	border?: ?boolean,
	showPageSize?: boolean,
	showItemsOfTotal?: boolean,
	showPrevButton?: boolean,
	showNextButton?: boolean,
	compactPager?: boolean,
	compact?: ?boolean,
	cancelSelection?: ?{ ['SORT' | 'FILTER' | 'PAGER']: boolean },
	getRefresh?: ?(Function) => void,
	selectable?: boolean,
|}

type State<Item> = {|
	selectedRows: { [string]: Item },
	visibleMultiActions: Array<string>,
	pageSize: number,
|}

type ComponentProps<Item> = {| ...I18NextProps, ...Props<Item> |}

class DataGrid<Item: { id?: string }> extends Component<ComponentProps<Item>, State<Item>> {
	columns: Array<{}>
	pageIndex: number
	pagesCount: ?number
	state: State<Item> = {
		selectedRows: {},
		visibleMultiActions: [],
		pageSize: this.props.pageSize,
	}

	static defaultProps = {
		pageSize: 10,
		onAction: () => {},
		onPageChange: () => {},
		onColumnsChanged: () => {},
		additionalData: {},
		light: false,
		cancelSelection: {
			FILTER: true,
			SORT: false,
			PAGER: true,
		},
		selectable: true,
	}

	handlePageChange = (page: number) => {
		this._onPageChange({
			pageIndex: page - 1,
			dataSource: this.dataSource,
			stateKey: this.props.stateKey,
		})
	}

	handlePageSizeChange = (pageSize: number) => {
		this.setState(
			{
				pageSize,
			},
			() => {
				this._onPageChange({
					pageIndex: 0,
					dataSource: this.dataSource,
					stateKey: this.props.stateKey,
				})
				//this.refresh()
			},
		)
	}

	constructor(props: ComponentProps<Item>) {
		super(props)
		this.setColumns(props.columns)
		this.props.onColumnsChanged(this.columns)
	}

	UNSAFE_componentWillReceiveProps(nextProps: ComponentProps<Item>) {
		const { getRefresh } = (this.props: ComponentProps<Item>)

		getRefresh && getRefresh(() => this.refresh())

		if (this.props.columns !== nextProps.columns || this.props.actions !== nextProps.actions) {
			this.setColumns(nextProps.columns)
			this.props.onColumnsChanged(this.columns)
		}
	}

	setColumns(columns: Array<{}>) {
		const { actions, multiActions } = this.props

		this.columns = []

		if (multiActions) {
			this.columns.push({
				dataIndex: 'context',
				width: '50px',
				renderer: ({ row }: { row: any }) => {
					const selected = row.context.state.selectedRows
					const handleCheck = row.context.handleCheck
					const checked = !!selected[row.id]
					return (
						<div className={checked ? styles.checkboxChecked : styles.checkbox}>
							<DataGridCheckbox id={row.id} rowData={row} checked={checked} onCheck={handleCheck} />
						</div>
					)
				},
			})
		}

		this.columns.push.apply(this.columns, columns)

		if (actions != null) {
			this.columns.push({
				name: '',
				dataIndex: 'rowmenu',
				sortable: false,
				width: '50px',
				renderer: ({
					column, // eslint-disable-line no-unused-vars
					value, // eslint-disable-line no-unused-vars
					row,
				}: {
					column: string,
					value: any,
					row: Item,
				}) => {
					return (
						<DataGridActions
							row={row}
							actions={actions}
							onAction={this.props.onAction}
							onActionMenuOpen={this.props.onActionMenuOpen}
						/>
					)
				},
			})
		}
	}

	refresh() {
		//not valid for clear selection
		this.props.onPageChange({
			pageIndex: this.props.currentPage,
			dataSource: this.dataSource,
			stateKey: this.props.stateKey,
		})
	}

	_onFilterChange(args: { pageIndex: number, dataSource: Function, stateKey: string }) {
		const cs = (this.props: ComponentProps<Item>).cancelSelection
		if (cs) {
			if (cs.FILTER) this.cancelSelection()
		}

		this.props.onFilterChange(args)
	}

	_onPageChange(args: { pageIndex: number, dataSource: Function, stateKey: string }) {
		const cs = (this.props: ComponentProps<Item>).cancelSelection
		if (cs) {
			if (cs.PAGER) this.cancelSelection()
		}

		this.props.onPageChange(args)
	}

	componentDidUpdate(prevProps: ComponentProps<Item>) {
		if (!deepEqual(this.props.filter, prevProps.filter)) {
			this._onFilterChange({
				pageIndex: 0,
				dataSource: this.dataSource,
				stateKey: this.props.stateKey,
			})
		}

		if (!deepEqual(this.props.sort, prevProps.sort)) {
			const cs = (this.props: ComponentProps<Item>).cancelSelection
			if (cs) {
				if (cs.SORT) this.cancelSelection()
			}
		}
	}

	dataSource = async (
		{ pageIndex }: { pageIndex: number },
		filterFields: {},
		sortParams: { sort: { property: string, direction: Direction } },
	) => {
		const sort = (sortParams && sortParams.sort) || {}
		const fulltextIndex: number = this.props.filter.findIndex(
			(filterItem: SearchFilter) => filterItem.field === 'searchText',
		)
		let filter = [...this.props.filter]
		let fulltextSearch: string

		if (fulltextIndex !== -1) {
			const filterItem: SearchFilter = this.props.filter[fulltextIndex]
			fulltextSearch = (filterItem.value || '').toString()
			filter.splice(fulltextIndex, 1)
		}
		let newPageIndex = pageIndex === undefined ? this.props.currentPage : pageIndex

		if (
			sort &&
			sort.property &&
			(sort.property !== this.props.sort.field || sort.direction !== this.props.sort.direction)
		) {
			newPageIndex = 0
		}
		const resultPromise = this.props.apiFunction({
			filters: filter.length > 0 ? filter : undefined,
			page: newPageIndex + 1,
			pageSize: this.state.pageSize,
			sortingField: sort.property || this.props.sort.field,
			sortingDirection: sort.direction || this.props.sort.direction,
			searchText: fulltextSearch,
		})
		const result: SearchResult | Array<Item> = await resultPromise
		let pagesCount: number = 0
		let dataField: Array<Item> = []
		if (!Array.isArray(result)) {
			this.pageIndex = newPageIndex
			this.pagesCount = result.pagesCount
			pagesCount = result.pagesCount || 0
			dataField = result[this.props.dataFieldName]

			if (dataField.length === 0 && newPageIndex > 0) {
				this._onFilterChange({
					pageIndex: 0,
					dataSource: this.dataSource,
					stateKey: this.props.stateKey,
				})
			}
		} else {
			if (this.props.dataFieldName == '') dataField = result
		}

		const data = dataField.map((item: Item) => {
			return { ...item, context: this, ...this.props.additionalData }
		}, this)
		return {
			data: data,
			total: pagesCount <= 0 ? data.length : (pagesCount - 1) * this.state.pageSize + data.length,
		}
	}

	handleCheck = (id: string, row: Item, checked: boolean) => {
		this.setState(
			(prevState: State<Item>) => {
				if (checked) {
					return { selectedRows: { ...prevState.selectedRows, [id]: row } }
				} else {
					const selectedRows = { ...prevState.selectedRows }
					delete selectedRows[id]
					return { selectedRows }
				}
			},
			async () => {
				this.props.updateRow(id)
				const selectedItems: Array<Item> = []
				for (let key in this.state.selectedRows) {
					selectedItems.push(this.state.selectedRows[key])
				}
				const visibleMultiActions =
					this.props.onNeedUpdateMultiActionVisiblity && selectedItems.length > 0
						? await this.props.onNeedUpdateMultiActionVisiblity(selectedItems)
						: EMPTY_ARRAY
				this.setState({
					visibleMultiActions,
				})
			},
		)
	}

	cancelSelection() {
		this.setState(
			{
				selectedRows: {},
			},
			() => {
				this.props.deselectAll()
			},
		)
	}

	handleMultiAction = (action: string, items: Array<Item>) => {
		this.setState(
			{
				selectedRows: {},
			},
			() => {
				items.forEach((item: Item) => item.id && this.props.updateRow(item.id))
				if (action != MULTI_ACTION_TYPE_CLEAR_SELECTION) {
					this.props.onMultiAction && this.props.onMultiAction(action, items)
				}
				this.props.deselectAll()
			},
		)
	}

	render() {
		const {
			t,
			light,
			compact,
			border,
			apiFunction, // eslint-disable-line no-unused-vars
			dataFieldName, // eslint-disable-line no-unused-vars
			columns, // eslint-disable-line no-unused-vars
			filter, // eslint-disable-line no-unused-vars
			...rest
		} = this.props

		applyGridConfig({
			CLASS_NAMES: {
				ACTIVE_CLASS: styles.active,
				INACTIVE_CLASS: styles.inactive,
				DRAG_HANDLE: styles.dragHandle,
				SORT_HANDLE: styles.sortHandle,
				SECONDARY_CLASS: styles.secondary,
				CONTAINER: compact ? styles.compactContainer : styles.container,
				TABLE: `${styles.table} ${border ? styles.borderedTable : ''} ${light ? styles.lightTable : ''} ${
					compact ? styles.compactTable : styles.noCompactTable
				}`,
				TABLE_CONTAINER: styles.tableContainer,
				HEADER: this.props.actions ? `${styles.header} ${styles.headerWithActions}` : styles.header,
				THEADER: styles.tableHeader,
				HEADER_HIDDEN: styles.headerHidden,
				HEADER_FIXED: styles.headerFixed,
				HEADER_FIXED_CONTAINER: styles.headerFixedContainer,
				HEADER_STUCK: styles.headerStuck,
				HEADER_STUCK_BOTTOM: styles.headerStuckBottom,
				ROW: this.props.actions ? styles.rowWithActions : styles.row,
				ROW_IS_DRAGGING: styles.rowIsDragging,
				CELL: styles.cell,
				CELL_TREE_ARROW: styles.cellTreeArrrow,
				CELL_HANDLE_CONTAINER: styles.cellHandleContainer,
				ROW_DRAG_HANDLE: styles.rowDragHandle,
				//PAGERTOOLBAR: styles.pagerToolbar,
				EMPTY_ROW: styles.emptyRow,
				EDITED_CELL: styles.editedCell,
				LOADING_BAR: styles.loadingBar,
				DRAGGABLE_COLUMN: styles.draggableColumn,
				COLUMN: styles.column,
				SORT_HANDLE_VISIBLE: styles.sortHandleVisible,
				BUTTONS: {
					PAGER: styles.pageButtons,
				},
				SELECTION_MODEL: {
					CHECKBOX: styles.checkbox,
					CHECKBOX_CONTAINER: styles.checkboxContainer,
				},
				ERROR_HANDLER: {
					CONTAINER: styles.errorContainer,
					MESSAGE: styles.errorMessage,
				},
				EDITOR: {
					INLINE: {
						CONTAINER: styles.inlineEditor,
						SHOWN: styles.shown,
						HIDDEN: styles.hidden,
						SAVE_BUTTON: styles.saveButton,
						CANCEL_BUTTON: styles.cancelButton,
						BUTTON_CONTAINER: styles.buttonContainer,
						INPUT_WRAPPER: styles.editorWrapper,
					},
				},
				GRID_ACTIONS: {
					CONTAINER: styles.actionContainer,
					SELECTED_CLASS: styles.actionMenuSelected,
					NO_ACTIONS: styles.noCctions,
					DISABLED: styles.disabled,
					ICON: styles.actionIcon,
					MENU: {
						CONTAINER: styles.actionMenuContainer,
						ITEM: styles.actionMenuItem,
					},
				},
				FILTER_CONTAINER: {
					CONTAINER: styles.filterContainer,
					INPUT: styles.filterInput,
					SEARCH_BUTTON: styles.filterSearchButton,
					MENU_BUTTON: styles.filterMenuButton,
					CLEAR_BUTTON: styles.filterClearButton,
					BUTTON_CONTAINER: styles.filterButtonContainer,
					MENU: {
						CONTAINER: styles.advancedFilterMenuContainer,
						TITLE: styles.advancedFilterMenuTitle,
						BUTTON: styles.advancedFilterMenuButton,
						BUTTON_CONTAINER: styles.advancedFilterMenuButtonContainer,
						FIELD: {
							CONTAINER: styles.advancedFilterMenuFieldContainer,
							LABEL: styles.advancedFilterMenuFieldLabel,
							INPUT: styles.advancedFilterMenuFieldInput,
						},
					},
				},
				BULK_ACTIONS: {
					CONTAINER: styles.bulkactionContainer,
					DESCRIPTION: styles.bulkactionDescription,
					SHOWN: styles.shown,
					HIDDEN: styles.hidden,
				},
			},
			CSS_PREFIX: '',
		})

		const gridConf = {
			plugins: {
				ROW: {
					enabled: true,
					renderer: ({ rowProps, cells, row }: { rowProps: any, cells: any, row: any }) => {
						const selected = this.state.selectedRows
						const checked = !!selected[row.get('id')]
						rowProps.className = `${rowProps.className} ${checked ? styles.selectedRow : styles.unselectedRow}`
						return <tr {...rowProps}>{cells}</tr>
					},
				},
				PAGER: {
					enabled: true,
					pagingType: 'remote',
					pagerComponent: this.getPagerComponent(),
				},
				LOADER: {
					enabled: true,
				},
				COLUMN_MANAGER: {
					sortable: {
						enabled: true,
						method: 'remote',
						sortingSource: this.dataSource,
					},
				},
			},
			reducerKeys: 'grid',
			columns: this.columns,
			dataSource: this.dataSource,
			pageSize: this.state.pageSize,
			height: false,
			emptyDataMessage: t('grid.noDataMessage'),
			...rest,
		}

		const selectedItems: Array<Item> = []
		for (let key in this.state.selectedRows) {
			selectedItems.push(this.state.selectedRows[key])
		}

		return (
			<div>
				{this.props.multiActions && (
					<MultiActionsToolbar
						visible={selectedItems.length > 0}
						// $FlowFixMe
						actions={this.props.multiActions}
						// $FlowFixMe
						selectedItems={selectedItems}
						// $FlowFixMe
						onMultiAction={this.handleMultiAction}
						visibleMultiActions={this.state.visibleMultiActions}
					/>
				)}
				<Grid {...gridConf} />
			</div>
		)
	}

	getPagerComponent() {
		return (
			<span className={this.props.compact ? styles.compactPager : styles.pager}>
				<Pager
					activePage={(this.pageIndex || 0) + 1}
					lastPage={this.pagesCount || 1}
					pageSize={this.state.pageSize}
					onPageChange={this.handlePageChange}
					onPageSizeChange={this.handlePageSizeChange}
					showPageSize={this.props.showPageSize}
					showItemsOfTotal={this.props.showItemsOfTotal}
					showPrevButton={this.props.showPrevButton}
					showNextButton={this.props.showNextButton}
					compact={!!this.props.compact || this.props.compactPager}
					autoTestId="data-grid-pager"
				/>
			</span>
		)
	}
}

export default translate(undefined, { withRef: true })(DataGrid)
