/* @flow */

import type { Filter } from 'types'

export function changeFilter(name: string, filter: Filter) {
	return {
		type: 'CHANGE_GRID_FILTER',
		name,
		filter,
	}
}

export function clearAllGridFilters(filterName: string) {
	return {
		type: 'CLEAR_ALL_FILTERS',
		filterName,
	}
}

export function clearGridFilterField(filterName: string, fieldName: string) {
	return {
		type: 'CLEAR_FILTER_FIELD',
		filterName,
		fieldName,
	}
}
