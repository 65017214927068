//@flow
import React, { Component } from 'react'
import Tooltip from 'components/tooltip/tooltip'

type Props = {|
	value?: string | number,
	rowSpan?: number,
	colSpan?: number,
	renderTooltip?: any,
|}

type State = {|
	tooltipVisible: boolean,
|}

class BillingTableColumn extends Component<Props, State> {
	state = {
		tooltipVisible: false,
	}
	computeStyle = () => {
		const { renderTooltip } = this.props
		return renderTooltip
			? {
					...Styles.th,
					...Styles.pointer,
			  }
			: {
					...Styles.th,
			  }
	}

	showTooltip = () => {
		this.setState({ tooltipVisible: true })
	}

	hideTooltip = () => {
		this.setState({ tooltipVisible: false })
	}

	render() {
		const { value, rowSpan, colSpan, renderTooltip } = this.props
		const { tooltipVisible } = this.state
		return (
			<th
				onMouseEnter={this.showTooltip}
				onMouseLeave={this.hideTooltip}
				rowSpan={rowSpan ? rowSpan : 1}
				colSpan={colSpan ? colSpan : 1}
				style={this.computeStyle()}
			>
				{renderTooltip && <Tooltip label={renderTooltip()} renderToLayer inline visible={tooltipVisible} />}
				{value}
			</th>
		)
	}
}

const Styles = {
	th: {
		border: '1px solid black',
		height: '35px',
	},
	pointer: {
		cursor: 'pointer',
	},
	documentsList: {
		marginTop: '10px',
	},
	flex: {
		display: 'flex',
		justifyContent: 'space-between',
	},
}

export default BillingTableColumn
