/* @flow */

import type { AccountingDocument, FormFieldErrorContext, SchedulerSettingsProps, TemplateScheduler } from 'types'
import React, { Component } from 'react'

import SettingsPanel from '../../components/invoice-settings/settings-panel'

type Props = {|
	domesticCurrencyId: ?string,
	accountingDocument: AccountingDocument,
	templateScheduler: ?TemplateScheduler,
	templateId?: string,
	formFieldErrorContext?: FormFieldErrorContext,
	onChange: (accountingDocument: AccountingDocument) => void,
	onTemplateSchedulerChange: (templateScheduler: ?TemplateScheduler, sendEmailAutomatically: boolean) => void,
	goToScheduler: boolean,
	onResetGoToScheduler: () => void,
|}

export default class SettingsPanelTemplate extends Component<Props> {
	render() {
		const schedulerSettingsProps: SchedulerSettingsProps = {
			formFieldErrorContext: this.props.formFieldErrorContext,
			templateScheduler: this.props.templateScheduler,
			onChange: this.props.onTemplateSchedulerChange,
			contactEmail: (this.props.accountingDocument.contact && this.props.accountingDocument.contact.email) || null,
			accountingDocument: this.props.accountingDocument,
		}

		return (
			<SettingsPanel
				isTemplate
				showSchedulerSettings
				readonly={false}
				direction={this.props.accountingDocument.direction}
				internalUser={null}
				accountingDocumentId={null}
				accountingDocument={this.props.accountingDocument}
				schedulerSettingsProps={schedulerSettingsProps}
				showRemindersSettings={false}
				showPeriodPlaceholders
				onChange={this.props.onChange}
				templateId={this.props.templateId}
				goToScheduler={this.props.goToScheduler}
				onResetGoToScheduler={this.props.onResetGoToScheduler}
			/>
		)
	}
}
