import React from 'react'
import SvgIcon from 'components/SvgIcon'
import pure from 'recompose/pure'

let Connected = props => (
	<SvgIcon {...props}>
		<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" opacity="0.400000006">
			<g fill="#FFFFFF" stroke="#000000">
				<rect x="0.5" y="0.5" width="23" height="23" rx="11.5" />
			</g>
		</g>
	</SvgIcon>
)
Connected = pure(Connected)

export default Connected
