/* @flow */

import Dashboard from 'modules/dashboard/pages/dashboard'
import Todo from 'modules/dashboard/pages/todo'
import Bank from 'modules/dashboard/pages/bank'
import Reports from 'modules/dashboard/pages/reports'
import EmptyDashBoard from 'modules/dashboard/containers/empty-dashboard'
import MainLayout from 'layouts/main-layout'
import React from 'react'
import { Route } from 'react-router'
import { UserIsAuthenticated } from 'lib/auth'

const EmptyDashboardComponent = UserIsAuthenticated(MainLayout('emptyDashboard')(EmptyDashBoard))
const DashboardComponent = UserIsAuthenticated(MainLayout('dashboard')(Dashboard))
const TodoComponent = UserIsAuthenticated(MainLayout('dashboard')(Todo))
const BankComponent = UserIsAuthenticated(MainLayout('dashboard')(Bank))
const ReportsComponent = UserIsAuthenticated(MainLayout('dashboard')(Reports))

const TodoComponentBasic = () => <TodoComponent section="company" isHistory={false} /> // eslint-disable-line
const TodoComponentHistory = () => <TodoComponent section="company" isHistory /> // eslint-disable-line

export default function() {
	return [
		<Route exact key="dashboard" path="/:organizationId/dashboard" component={DashboardComponent} />,
		<Route exact key="todo" path="/:organizationId/dashboard/todo" component={TodoComponentBasic} />,
		<Route exact key="todoHistory" path="/:organizationId/dashboard/todo/history" component={TodoComponentHistory} />,
		<Route exact key="reports" path="/:organizationId/dashboard/reports" component={ReportsComponent} />,
		<Route exact key="bank" path="/:organizationId/dashboard/todo/bank" component={BankComponent} />,
		<Route
			exact
			key="bank-paymentState"
			path="/:organizationId/dashboard/todo/bank/:paymentState"
			component={BankComponent}
		/>,
	]
}

export function EmptyDashboardRouting() {
	return [<Route exact key="emptyDashboard" path="/empty-dashboard" component={EmptyDashboardComponent} />]
}
