/* @flow */
/** @jsx jsx */

import { Component } from 'react'
import { jsx } from '@emotion/core'
import memoize from 'memoize-one'
import { isEqualDate } from 'components/date-picker/date-utils'
import type {
	Currency,
	AccountingDocumentLanguage,
	OrganizationSettingsOpen,
	I18NextTranslateFn,
	FormFieldErrorContext,
	// CountryVariantRequest,
	AccountingDocumentCountryVariantSpecific,
	AccountingDocument,
} from 'types'
import { CurrencySelector, TextField, DatePicker, Button, Avatar } from 'components'
import Tooltip from 'components/tooltip'
import AccountingDocumentLanguageSelector from 'modules/common/components/language-selector/accounting-document-language-selector'
import { colors } from 'variables'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import { formatToDateString, convertToDate } from 'utils/formatters'
import AlertErrorOutline from 'components/svg-icons/alert/error-outline'
import ActionChromeReaderMode from 'components/svg-icons/action/chrome-reader-mode'
import CalendarIcon from 'components/svg-icons/action/date-range'
import CommunicationBusiness from 'components/svg-icons/communication/business'
// import UpperSettingsEet from '../../containers/invoice-elements/upper-settings-eet'
// import UpperSettingsEetComponent from 'modules/accounting-document/components/invoice-elements/upper-settings-eet'
import type { View } from '../../types'
import { addSubPath } from 'helpers'
import ExchangeRateInput from '../../containers/invoice-elements/exchange-rate-input'

type Props = {|
	...WithTranslateProps,
	isTemplate?: boolean,
	template?: ?AccountingDocument,
	accountingDocumentId: string,
	bookingDate: ?string,
	onBookingDateChange: (bookingDate: Date) => void,
	taxReturnDate: ?Date,
	onTaxReturnDateChange: (taxReturnDate: Date) => void,
	currencyId: ?string,
	onCurrencyChange: (currency: ?Currency) => void,
	language: ?AccountingDocumentLanguage,
	onLanguageChange: (language: AccountingDocumentLanguage) => void,
	taxReturnMinDate: ?string,
	vatCountryType: ?number,
	readonly?: boolean,
	exchRate?: ?number,
	exchRateDefault?: ?number,
	vatExchRate?: ?number,
	sadExchRate?: ?number,
	hideExchRate?: boolean,
	hideCurrency?: boolean,
	hideTaxReturnDate?: boolean,
	hideBookingDate?: boolean,
	hideVatCountry?: boolean,
	hideLanguage?: boolean,
	hideNotes?: boolean,
	noteNotificationCount: number,
	languageReadonly?: boolean,
	showOrganizationInfo?: boolean,
	isNoteDisabled?: boolean,
	view: View,
	toggleShowNote?: () => void,
	// changeEET: (countryVariantRequest: CountryVariantRequest) => void,
	// showEet: boolean,
	// If forceCountryVariantSpecific is set, countryVariantSpecific prop is used
	// instead of loading from Redux by accDocId (we need it for templates).
	forceCountryVariantSpecific?: boolean,
	countryVariantSpecific?: AccountingDocumentCountryVariantSpecific,
	organizationSettingsOpen: ?OrganizationSettingsOpen,
	accDocState?: string,
	isInternal?: boolean,
	formFieldErrorContext?: FormFieldErrorContext,
	onChange?: (accountingDocument: AccountingDocument) => void,
|}

const VAT_COUNTRY_KEYS = new Map([
	[1, 'domestic'],
	[2, 'eu'],
	[3, 'foreign'],
])

const ACCOUNTING_TYPES_TRANSLATIONS = {
	'0': 'settings.accountingSettings.doubleEntry',
	'1': 'settings.accountingSettings.taxEvidence',
}

const PAYER_TAX_TYPES_TRANSLATIONS = {
	'0': 'settings.accountingSettings.payerTaxType',
	'1': 'settings.accountingSettings.vatFree',
	'2': 'settings.accountingSettings.identified',
}

const VAT_PERIOD_TRANSLATIONS = {
	'1': 'settings.accountingSettings.vatPeriodColMonthly',
	'2': 'settings.accountingSettings.vatPeriodColQuartely',
	'3': 'settings.accountingSettings.vatPeriodNotAVatPayer',
}

class UpperSettings extends Component<Props> {
	getOrganizationInfo = memoize((organizationSettingsOpen: ?OrganizationSettingsOpen, t: I18NextTranslateFn) => {
		if (!organizationSettingsOpen) {
			return {
				organizationSettingsName: '',
				companyRegNo: '',
				accountingType: '',
				payerTaxType: '',
				payerTaxTypeFrom: '',
			}
		}

		const { regNo, payerTaxType, accountingType, vatPeriod, payerTaxTypeFrom, name } = organizationSettingsOpen
		const _vatPeriod = vatPeriod && vatPeriod !== 3 ? `- ${t(VAT_PERIOD_TRANSLATIONS[vatPeriod.toString()])}` : ''
		const _payerTaxTypeFrom = payerTaxTypeFrom ? formatToDateString(payerTaxTypeFrom) : ''

		return {
			organizationSettingsName: name,
			companyRegNo: regNo ? `${t('invoice.companyRegNo')}: ${regNo.toString()}` : `${t('invoice.companyRegNo')}: `,
			payerTaxType:
				payerTaxType || payerTaxType === 0
					? `${t(PAYER_TAX_TYPES_TRANSLATIONS[payerTaxType.toString()])} ${_vatPeriod}`
					: '',
			payerTaxTypeFrom: payerTaxType === 0 ? `, ${t('invoice.taxPayerFrom')} ${_payerTaxTypeFrom}` : '',
			accountingType:
				accountingType || accountingType === 0 ? t(ACCOUNTING_TYPES_TRANSLATIONS[accountingType.toString()]) : '',
		}
	})

	onBookingDateChange = (event: ?SyntheticInputEvent<HTMLInputElement>, bookingDate: ?Date) => {
		const equals =
			isEqualDate(this.getBookingDate(this.props.bookingDate), bookingDate) ||
			(this.props.bookingDate == null && bookingDate == null)

		if (!equals && bookingDate) this.props.onBookingDateChange(bookingDate)
	}

	onTaxReturnChange = (event: ?SyntheticInputEvent<HTMLInputElement>, taxReturnDate: ?Date) => {
		const equals =
			isEqualDate(this.props.taxReturnDate, taxReturnDate) ||
			(this.props.taxReturnDate == null && taxReturnDate == null)

		if (!equals && taxReturnDate) this.props.onTaxReturnDateChange(taxReturnDate)
	}

	renderExchRate = () => {
		const exchRateFormFieldErrorContext = addSubPath(this.props.formFieldErrorContext, 'exchRate')
		return (
			<div css={style.item}>
				<ExchangeRateInput
					isInHeader
					disabled
					exchRate={this.props.exchRate}
					vatExchRate={this.props.vatExchRate}
					sadExchRate={this.props.sadExchRate}
					exchRateDefault={this.props.exchRateDefault}
					template={this.props.template}
					isTemplate={!!this.props.isTemplate}
					onTemplateChange={this.props.onChange}
					accountingDocumentId={this.props.accountingDocumentId}
					formFieldErrorContext={exchRateFormFieldErrorContext}
					name="exch-rate"
					fullWidth
					compact
				/>
			</div>
		)
	}

	renderCurrency = () => {
		const currencyFormFieldErrorContext = addSubPath(this.props.formFieldErrorContext, 'currency')
		return (
			<div css={style.item}>
				<CurrencySelector
					onChange={this.props.onCurrencyChange}
					value={this.props.currencyId}
					labelText={this.props.t('invoice.settings.booking.currencyLabel')}
					disabled={this.props.readonly}
					autoTestId="invoice-upper-settings-currency-selector"
					fullWidth
					compact
					formFieldErrorContext={currencyFormFieldErrorContext}
				/>
			</div>
		)
	}

	renderBookingDate = () => {
		return (
			<div css={style.item}>
				<DatePicker
					ref="bookingDatePicker"
					onChange={this.onBookingDateChange}
					value={this.getBookingDate(this.props.bookingDate)}
					labelText={this.props.t('invoice.settings.booking.bookingDateLabel')}
					disabled={this.props.readonly}
					autoTestId="upper-settings-booking-date"
					compact
					fullWidth
				/>
			</div>
		)
	}

	renderTaxReturnDate = () => {
		return (
			<div css={style.item}>
				<DatePicker
					onChange={this.onTaxReturnChange}
					value={this.props.taxReturnDate}
					labelText={this.props.t('invoice.settings.booking.taxReturnDateLabel')}
					disabled={this.props.readonly}
					minDate={this.getTaxReturnMinDate(this.props.taxReturnMinDate)}
					autoTestId="upper-settings-tax-return-date"
					compact
					fullWidth
				/>
			</div>
		)
	}

	renderVatCountry = () => {
		if (!this.props.vatCountryType) return null
		return (
			<div css={style.item}>
				<TextField
					value={this.props.t(
						`invoice.settings.booking.vatCountryTypes.${VAT_COUNTRY_KEYS.get(this.props.vatCountryType) || 'default'}`,
					)}
					labelText={this.props.t('invoice.settings.booking.vatCountryHeadline')}
					disabled
					compact
					fullWidth
					name="vatcountry"
					autoTestId="invoice-upper-settings-vat-country"
				/>
			</div>
		)
	}

	renderNoteButton = () => {
		const disabled =
			this.props.noteNotificationCount === 0 &&
			(this.props.isNoteDisabled ||
				(this.props.accDocState !== 'Draft' && this.props.accDocState !== 'Fixing' && !this.props.isInternal))

		return (
			<div css={style.button}>
				<Tooltip visible={disabled ? undefined : false} label={this.props.t('invoice.settings.booking.noteDisabled')}>
					<Button
						autoTestId="invoice-notes-button"
						tertiary
						labelText={this.props.t('invoice.notes')}
						onClick={this.props.toggleShowNote}
						disabled={disabled}
					/>
				</Tooltip>
				{this.props.noteNotificationCount > 0 && (
					<Avatar
						size={22}
						borderWidth={2}
						borderColor={colors.white}
						backgroundColor={colors.red}
						style={style.avatar}
					>
						{this.props.noteNotificationCount}
					</Avatar>
				)}
			</div>
		)
	}

	renderLanguage = () => {
		return (
			<div css={style.item}>
				<AccountingDocumentLanguageSelector
					labelText={this.props.t('invoice.settings.booking.languageHeadline')}
					hintText={this.props.t('invoice.settings.booking.languageHint')}
					onChange={this.props.onLanguageChange}
					language={this.props.language}
					readonly={this.props.languageReadonly}
					compact
				/>
			</div>
		)
	}

	renderOrganizationInfo = () => {
		if (!this.props.showOrganizationInfo) {
			return null
		}

		const {
			organizationSettingsName,
			companyRegNo,
			accountingType,
			payerTaxType,
			payerTaxTypeFrom,
		} = this.getOrganizationInfo(this.props.organizationSettingsOpen, this.props.t)

		return (
			<div css={style.line}>
				<div css={style.lineItem}>
					<CommunicationBusiness size={14} disabled style={style.icon} />
					{organizationSettingsName}
				</div>
				<div css={style.lineItem}>
					<AlertErrorOutline size={14} disabled style={style.icon} />
					{companyRegNo}
				</div>
				<div css={style.lineItem}>
					<CalendarIcon size={14} disabled style={style.icon} />
					{accountingType}
				</div>
				<div css={style.lineItem}>
					<ActionChromeReaderMode size={14} disabled style={style.icon} />
					{payerTaxType}
					{payerTaxTypeFrom}
				</div>
			</div>
		)
	}

	getBookingDate = memoize((date: ?string) => {
		return date ? convertToDate(date) : null
	})

	getTaxReturnMinDate = memoize((date: ?string) => {
		return date ? convertToDate(date) : null
	})

	render() {
		const {
			t,
			taxReturnDate,
			hideTaxReturnDate,
			hideCurrency,
			hideBookingDate,
			hideVatCountry,
			hideExchRate,
			hideLanguage,
			hideNotes,
			// showEet,
			showOrganizationInfo,
			view,
		} = this.props

		const bookingDate = this.getBookingDate(this.props.bookingDate)

		return (
			<div css={style.container}>
				<div css={style.line}>
					{/*{showEet && this.props.forceCountryVariantSpecific && (*/}
					{/*	<UpperSettingsEetComponent*/}
					{/*		countryVariantSpecific={this.props.countryVariantSpecific}*/}
					{/*		changeEET={this.props.changeEET}*/}
					{/*		accountingDocumentState={undefined}*/}
					{/*		relatedAccountingDocumentId={undefined}*/}
					{/*		relatedCountryVariantSpecific={undefined}*/}
					{/*		userCanEdit*/}
					{/*	/>*/}
					{/*)}*/}
					{/*{showEet && !this.props.forceCountryVariantSpecific && (*/}
					{/*	<UpperSettingsEet accountingDocumentId={this.props.accountingDocumentId} changeEET={this.props.changeEET} />*/}
					{/*)}*/}

					{!hideExchRate && this.renderExchRate()}

					{!hideCurrency && this.renderCurrency()}

					{!hideBookingDate && this.renderBookingDate()}

					{!hideTaxReturnDate && this.renderTaxReturnDate()}

					{!hideVatCountry && this.renderVatCountry()}

					{!hideLanguage && this.renderLanguage()}

					<div>{!hideNotes && this.renderNoteButton()}</div>
				</div>

				{view === 'scans' && showOrganizationInfo && this.renderOrganizationInfo()}

				{!hideTaxReturnDate && taxReturnDate && bookingDate && taxReturnDate.getTime() !== bookingDate.getTime() && (
					<div css={style.warning}>{t('invoice.settings.booking.bookingDateWarning')}</div>
				)}
			</div>
		)
	}
}

const style = {
	avatar: {
		position: 'absolute',
		top: -11,
		right: -9,
		zIndex: 10,
		pointerEvents: 'none',
	},
	button: {
		position: 'relative',
	},
	item: {
		minWidth: 100,
		maxWidth: 200,
		flex: '1 1 0',
		marginRight: 15,
	},
	warning: {
		fontSize: 14,
		lineHeight: '24px',
		marginTop: 10,
		marginBottom: 16,
		color: colors.blackFaded80,
	},
	container: {
		lineHeight: 1,
		marginTop: 4,
		marginBottom: 4,
	},
	line: {
		display: 'flex',
		alignContent: 'flex-end',
		justifyContent: 'flex-start',
		alignItems: 'flex-end',
	},
	lineItem: {
		margin: '18px 0 12px 20px',
		color: colors.black,
		fontSize: 14,
		lineHeight: '21px',
		fontWeight: 'normal',
		maxWidth: 270,
		'&:first-of-type': {
			marginLeft: 0,
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',
			overflow: 'hidden',
		},
		'&:nth-of-type(3)': {
			maxWidth: 300,
		},
	},
	icon: {
		verticalAlign: 'text-top',
		margin: '1px 7px 0 0',
	},
}

export default withTranslate(UpperSettings)
