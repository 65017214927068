/* @flow */
/** @jsx jsx */

import type { Contact, EmailTemplate } from 'types'
import {
	type FormValidationProps,
	type WithNotifyProps,
	type WithTranslateProps,
	validate,
	withNotify,
	withTranslate,
} from 'wrappers'

import Button from 'components/button'
import { Component } from 'react'
import ConfirmDialog from 'components/confirm-dialog'
import ContactEmailsSelector from '../../../accounting-document/containers/invoice-settings/contact-emails-selector'
import EmailTemplateSelector from 'modules/common/components/selectors/email-template-selector'
import InfoIcon from 'components/svg-icons/action/info'
import Paper from 'components/Paper'
import PopupSection from 'components/PopupSection'
import Tracking from 'utils/tracking'
import { colors } from 'variables'
import { jsx } from '@emotion/core'
import { saveNewEmails } from '../../domain/send-email'

const ALLOWED_TYPES = [1]

type Props = {|
	open: boolean,
	contact: ?Contact,
	contactId?: ?string,
	emailTemplates: ?Array<EmailTemplate>,
	accountingDocumentId?: ?string,
	onClose: () => void,
	onSend: (emails: Array<string>, templateId: number) => void,
	loadContact: (contactId: string) => void,
	...WithTranslateProps,
	...WithNotifyProps,
	...FormValidationProps,
|}

type State = {
	emails: Array<string>,
	templateId: ?string,
	emailTemplateId: ?string,
}

class SendReminderEmailDialog extends Component<Props, State> {
	options: null | Array<string> = null
	state: State = {
		emails: [],
		emailTemplateId: null,
		templateId: null,
	}

	componentWillMount() {
		if (!this.props.contact && this.props.contactId) {
			this.props.loadContact(this.props.contactId)
		}
		this.setState(this.getStateFromProps(this.props))
	}

	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		if (nextProps.contactId !== this.props.contactId) {
			nextProps.contactId && this.props.loadContact(nextProps.contactId)
			this.setState({ emails: [] })
		}
	}

	getStateFromProps = (props: Props) => {
		return {
			emailTemplateId: null,
			emails: [],
		}
	}

	onEmailTemplateChange = (emailTemplateId: string) => {
		this.setState({ emailTemplateId })
	}

	onEmailsChange = (emails: Array<string>) => {
		this.setState({ emails })
	}

	onSend = () => {
		const { t } = this.props
		const emails: Array<string> = [...this.state.emails]

		if (emails.length > 0 && this.state.emailTemplateId) {
			ConfirmDialog(t('dialogs.sendReminderConfirmation'), {
				okLabel: t('dialogs.sendButtonLabel'),
				cancelLabel: t('dialogs.goBackOption'),
			}).then(() => {
				this.props.onSend(emails, parseInt(this.state.emailTemplateId))
				saveNewEmails(emails)
				this.clear()
				this.props.accountingDocumentId && Tracking.trackSentEmail(this.props.accountingDocumentId, emails.length)
			})
		}
	}

	close = () => {
		this.clear(this.props.onClose)
		this.props.resetValidation()
	}

	clear(next?: () => void) {
		this.setState({ emails: [], templateId: null }, next)
	}

	getClientValidationErrors() {
		const errors = {}
		const { t } = this.props

		if (!this.state.emails || !this.state.emails.length) errors.emails = t('application.validation.mandatory')
		if (!this.state.emailTemplateId) errors.emailTemplateId = t('application.validation.mandatory')

		return errors
	}

	renderPreview() {
		const { t, emailTemplates, contact } = this.props

		const emailTemplate: ?EmailTemplate =
			emailTemplates && emailTemplates.find((t: EmailTemplate) => t.id === this.state.emailTemplateId)

		const emailTemplateSelector = (
			<EmailTemplateSelector
				label={t('invoice.settings.reminders.reminderTemplate')}
				value={this.props.validationValue('emailTemplateId', this.state.emailTemplateId)}
				clientError={this.props.validationMessage('emailTemplateId')}
				onChange={this.onEmailTemplateChange}
				disabled={false}
				allowedTypes={ALLOWED_TYPES}
				allowCreateTemplate
				autoSelect
			/>
		)

		return (
			<div>
				<div css={styles.field}>{emailTemplateSelector}</div>
				{emailTemplate && (
					<div css={styles.info}>
						<InfoIcon style={styles.infoIcon} size={18} color={colors.blue} disabled />
						{emailTemplate.body}
					</div>
				)}
				<ContactEmailsSelector
					readonly={false}
					contactId={contact && contact.id}
					onEmailsChange={this.onEmailsChange}
					emails={this.props.validationValue('emails', this.state.emails)}
					clientError={this.props.validationMessage('emails')}
				/>
			</div>
		)
	}

	render() {
		if (!this.props.open) return null

		const { t } = this.props

		this.props.validateForm(this.getClientValidationErrors())

		return (
			<PopupSection open={this.props.open} onRequestClose={this.close}>
				<div css={styles.root}>
					<Paper rounded zDepth={6} style={styles.form}>
						<div css={styles.headline}>{t('dialogs.sendReminderByMailHeader')}</div>
						{this.renderPreview()}
						<div css={styles.buttons}>
							<Button
								autoTestId="reminder-dialog-send"
								onClick={this.props.validationSubmit(this.onSend)}
								wide
								primary
								labelText={t('dialogs.sendReminder')}
							/>
							<div css={styles.cancelButton}>
								<Button
									autoTestId="reminder-dialog-cancel"
									onClick={this.close}
									secondary
									wide
									tertiary
									labelText={t('dialogs.goBackOption')}
								/>
							</div>
						</div>
					</Paper>
				</div>
			</PopupSection>
		)
	}
}

const styles = {
	root: {
		display: 'flex',
		padding: '20px 25px 40px 25px',
		overflow: 'hidden',
		flexWrap: 'wrap-reverse',
		justifyContent: 'center',
		alignItems: 'flex-end',
		alignContent: 'flex-start',
	},
	form: {
		marginTop: 68,
		flex: '1 1 500px',
		marginBottom: 35,
		marginLeft: 20,
		marginRight: 20,
		maxWidth: 885,
		minWidth: 500,
		padding: '40px 50px',
	},
	headline: {
		color: colors.black,
		fontSize: 24,
		fontWeight: 'bold',
		lineHeight: '32px',
		marginBottom: 10,
	},
	buttons: {
		display: 'flex',
		justifyContent: 'space-between',
		fontWeight: 'bold',
		lineHeight: '32px',
		marginBottom: 10,
		marginTop: 20,
	},
	info: {
		marginTop: -10,
		marginBottom: 30,
		color: colors.blackFaded60,
		fontSize: 14,
		fontStyle: 'italic',
		lineHeight: '20px',
		paddingLeft: 25,
		position: 'relative',
		paddingTop: 5,
		whiteSpace: 'pre-wrap',
	},
	infoIcon: {
		position: 'absolute',
		top: 5,
		left: 0,
	},
	field: {
		marginBottom: 15,
	},
	cancelButton: {
		marginTop: '5px',
	},
}

export default withTranslate(
	withNotify(validate('FINISH_SENDING_REMINDER_ACCOUNTING_DOCUMENT_EMAIL')(SendReminderEmailDialog)),
)
