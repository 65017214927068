/* @flow */

import React, { Component } from 'react'
import EnhancedButton from 'components/internal/EnhancedButton'
import { colors } from 'variables'
import { passAutoTestId, type AutoTestProps } from 'utils/tests/autotest'

type Props = {|
	...AutoTestProps,
	disabled?: boolean,
	/**
	 * If true, the button will take up the full width of its container.
	 */
	fullWidth?: boolean,
	/**
	 * The label to be displayed within the button.
	 */
	labelText: string,
	/**
	 * True to set wider padding
	 */
	wide?: boolean,
	name?: string,
	onClick?: () => any,
|}

type State = {|
	hovered: boolean,
|}

class Button extends Component<Props, State> {
	state = {
		hovered: false,
	}

	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		nextProps.disabled &&
			this.setState({
				hovered: false,
			})
	}

	handleMouseLeave = () => {
		this.setState({
			hovered: false,
		})
	}

	handleMouseEnter = () => {
		this.setState({
			hovered: true,
		})
	}

	handleClick = () => {
		this.props.onClick && this.props.onClick()
	}

	render() {
		const styles = getStyles(this.props, this.state)

		return (
			<EnhancedButton
				onClick={this.handleClick}
				onMouseLeave={this.handleMouseLeave}
				onMouseEnter={this.handleMouseEnter}
				disabled={this.props.disabled}
				style={styles.root}
				name={this.props.name}
				{...passAutoTestId(this.props.autoTestId)}
			>
				<span style={styles.label}>{this.props.labelText}</span>
			</EnhancedButton>
		)
	}
}

function getStyles(props: Props, state: State) {
	const { disabled, fullWidth, wide } = props

	const inFocus = state.hovered && !disabled
	const zDepth = disabled ? 0 : 3

	return {
		root: {
			display: 'inline-block',
			transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1)',
			minWidth: fullWidth ? '100%' : 0,
			borderStyle: 'solid',
			borderWidth: 1,
			overflow: 'hidden',
			opacity: disabled ? 0.4 : 1,
			boxShadow: `${zDepth}px ${zDepth}px 0 ${colors.blackFaded8}`,
			textAlign: 'center',
			userSelect: 'none',
			pointerEvents: disabled ? 'none' : 'all',
			height: 42,
			lineHeight: '40px',
			borderRadius: 3,
			backgroundColor: inFocus ? colors.redFaded80 : colors.red,
			borderColor: colors.red600,
			margin: wide ? '0 8px' : 0,
			padding: wide ? '0 25px' : 0,
		},
		label: {
			position: 'relative',
			verticalAlign: 'middle',
			whiteSpace: 'nowrap',
			opacity: 1,
			fontSize: 14,
			letterSpacing: 0,
			textTransform: 'uppercase',
			margin: 0,
			userSelect: 'none',
			color: colors.white,
			paddingLeft: 16,
			paddingRight: 16,
			fontWeight: 'normal',
		},
	}
}

export default Button
