// @flow
/** @jsx jsx */

import { jsx } from '@emotion/core'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import Paper from 'components/Paper'
import Button from 'components/button'
import PopupSection from 'components/PopupSection'
import cashbotLogo from 'assets/images/cashbot-logo.svg'

type Props = {|
	...WithTranslateProps,
	open: boolean,
	registerCompanyName?: string,
	onClose: () => void,
|}

function onRegisterCompanyClick() {
	window.location.href = 'https://klient.cashbot.cz/OnlineRegistration/Company'
}

function onMoreInfoClick() {
	window.location.href = 'https://www.cashbot.cz/#jak-to-funguje'
}

function CashbotRegisterDialog(props: Props) {
	if (!props.open) return null

	return (
		<PopupSection open={props.open} onRequestClose={props.onClose}>
			<div css={style.container}>
				<Paper zDepth={5}>
					<div css={style.content}>
						<img src={cashbotLogo} css={style.logo} />
						<div>
							<div css={style.headline}>
								{props.t('cashbot.dontExist', { company: props.registerCompanyName || '' })}
							</div>
							<Button
								autoTestId="cashbot-dialog-register"
								onClick={onRegisterCompanyClick}
								labelText={props.t('cashbot.register')}
								primary
							/>
							<div css={style.whatIsCashbot}>{props.t('cashbot.whatIsCashbot')}</div>
							<Button
								autoTestId="cashbot-dialog-more-info"
								onClick={onMoreInfoClick}
								labelText={props.t('cashbot.moreInfo')}
								secondary
							/>
						</div>
					</div>
				</Paper>
			</div>
		</PopupSection>
	)
}

const style = {
	container: {
		marginLeft: 'auto',
		marginRight: 'auto',
		marginTop: 65,
		maxWidth: 850,
		width: '80%',
	},
	content: {
		marginTop: 60,
		padding: 50,
		fontSize: 25,
		lineHeight: 1,
		textAlign: 'center',
		overflow: 'hidden',
		borderRadius: 3,
	},
	logo: {
		position: 'absolute',
		width: 120,
		height: 'auto',
		right: 25,
		top: 25,
	},
	headline: {
		lineHeight: '30px',
		padding: '0 50px',
	},
	whatIsCashbot: {
		fontSize: 16,
		marginTop: 50,
		marginBottom: 30,
	},
}

export default withTranslate(CashbotRegisterDialog)
