/* @flow */

import BankAccount from '../pages/bank-account-page'
import { connect } from 'react-redux'
import { loadBankAccount } from '../actions'

type DispatchProps = {|
	loadBankAccount: (id: string) => void,
|}

const mapDispatchToProps = (dispatch: any): DispatchProps => {
	return {
		loadBankAccount: (bankAccountId: string) => {
			dispatch(loadBankAccount(bankAccountId))
		},
	}
}

export default connect(undefined, mapDispatchToProps)(BankAccount)
