/* @flow */
/** @jsx jsx */

import { jsx } from '@emotion/core'
import { Component } from 'react'
import type { AccountingDocumentIdentities, AccountingDocumentIdentity } from 'modules/common/types'
import type {
	AccountingDocument,
	BankAccount,
	FormValidationProps,
	PaymentOrder,
	AccountingDocument_IssuedPaymentOrders,
} from 'types'

import MenuItem from 'components/menu-item'
import PopupSection from 'components/PopupSection'
import Button from 'components/button'
import SelectField from 'components/select-field'
import TextField from 'components/TextField'
import DatePicker from 'components/date-picker'
import TriviLink from 'modules/common/components/trivi-link'

import memoize from 'memoize-one'
import Tracking from 'utils/tracking'
import { withTranslate, withNotify, type WithNotifyProps, type WithTranslateProps } from 'wrappers'
import { accountingDocumentRoute } from 'modules/accounting-document/routing/routes'
import deepEqual from 'deep-equal'
import { groupBy, forEach } from 'lodash-es'
import { emptyBankAccount } from 'types/empty'
import { formatToDateString } from 'utils/formatters/date-formatter'
import { formatDateToIsoString } from 'utils/formatters'
import { isNullOrWhitespace } from 'utils'
import validate from 'wrappers/validate'
import { accountingDocumentNumber } from 'modules/accounting-document/helpers'
import { Number_PaymentType } from 'types/convertor'
import { filterDocumentsWithPaymentOrders } from 'modules/common/domain/payment-dialog'
import Issue from 'modules/accounting-document/components/issues/issue'
import IssuedPaymentsInfo from './issued-orders-info'
import FormBox from 'components/form-box'

export type Props = {|
	accountingDocuments: Array<AccountingDocument>,
	bankAccounts: Array<BankAccount>,
	onRequestClose: (reason: string) => void,
	open: boolean,
	placePaymentOrder: (paymentOrder: PaymentOrder) => Promise<*>,
	loadPaymentOrders: (accDocIds: Array<string>) => void,
	removeSelectedDocumentIds: () => void,
	ordersForSelectedDocuments: AccountingDocument_IssuedPaymentOrders,
	...WithTranslateProps,
	...FormValidationProps,
	...WithNotifyProps,
|}

type State = {
	amount: ?number,
	bankAccount: BankAccount,
	dueDate: Date,
	name: ?string,
	submitDisabled: boolean,
}

type PaymentTypeGroup = {
	paymentType: number,
	accountingDocuments: Array<AccountingDocument>,
}

class PaymentDialog extends Component<Props, State> {
	state = {
		amount: null,
		bankAccount: emptyBankAccount(),
		dueDate: new Date(),
		name: this.props.t('paymentDialog.defaultName', {
			date: formatToDateString(new Date()),
		}),
		submitDisabled: false,
	}

	UNSAFE_componentWillUpdate(nextProps: Props) {
		const { open } = this.props
		const { open: nextOpen, accountingDocuments } = nextProps
		if (!open && nextOpen) {
			const accDocIds = filterDocumentsWithPaymentOrders(accountingDocuments)
			this.props.loadPaymentOrders(accDocIds)
		} else if (open && !nextOpen) {
			this.props.removeSelectedDocumentIds()
		}
	}

	placePaymentOrder = () => {
		const { t } = this.props
		const { dueDate, bankAccount, name } = this.state

		if (!this.props.isFormValid()) {
			return
		}

		const accountingDocumentIds: AccountingDocumentIdentities = this.props.accountingDocuments.reduce(
			(result: AccountingDocumentIdentities, item: AccountingDocument) => {
				if (item.id != null && item.accountingDocumentNo != null)
					result.push({
						accountingDocumentId: item.id,
						accountingDocumentNo: item.accountingDocumentNo,
					})
				return result
			},
			[],
		)

		if (name != null && bankAccount.id != null) {
			const paymentOrder: PaymentOrder = {
				accountingDocuments: accountingDocumentIds,
				//amount, //TODO: [TK] currency ??
				bankAccountId: bankAccount.id,
				dueDate: formatDateToIsoString(dueDate),
				name,
			}
			this.setState({ submitDisabled: true })

			this.props
				.placePaymentOrder(paymentOrder)
				.then(() => {
					this.setState({
						amount: null,
						bankAccount: emptyBankAccount(),
						dueDate: new Date(),
						submitDisabled: false,
						name: t('paymentDialog.defaultName', {
							date: formatToDateString(new Date()),
						}),
					})
					this.props.notify(t('paymentDialog.paymentPlaced'), 'success')
					bankAccount.accountCode && Tracking.trackCreateBankOrder(bankAccount.accountCode)
				})
				.catch(() => {
					this.setState({ submitDisabled: false })
				})
		}
	}

	handleDueDateChange = (event: ?SyntheticInputEvent<HTMLInputElement>, date: ?Date) => {
		date &&
			this.setState({
				dueDate: date,
			})
	}

	handleBankAccountChange = (event: SyntheticEvent<HTMLElement>, idx: ?number, value: BankAccount) => {
		this.setState({
			bankAccount: value,
		})
	}

	handleNameChange = (event: SyntheticInputEvent<HTMLInputElement>, value: ?string) =>
		this.setState({
			name: value,
		})

	renderBankAccounts = () => {
		return this.props.bankAccounts
			.filter((bankAccount: BankAccount) => {
				return bankAccount.accountNo != null && bankAccount.accountCode != null
			})
			.map((bankAccount: BankAccount) => {
				let accountText: string = ''
				const accountName = `${bankAccount.name || ''} ${bankAccount.currency}`

				if (bankAccount.accountNo != null && bankAccount.accountCode != null)
					accountText = `${bankAccount.accountNo}/${bankAccount.accountCode}`

				return <MenuItem key={bankAccount.id} primaryText={accountName} value={bankAccount} rightText={accountText} />
			})
	}

	renderAccountingDocumentLink = (document: AccountingDocument) => {
		const id = document.id

		const identity: AccountingDocumentIdentity = {
			accountingDocumentId: document.id,
			accountingDocumentNo: document.accountingDocumentNo,
		}

		if (id) {
			return (
				<TriviLink
					key={[id, identity.accountingDocumentNo].join('|')}
					routeFn={accountingDocumentRoute}
					routeParams={[id]}
				>
					<span style={{ textDecoration: 'underline' }}>{accountingDocumentNumber(identity)}</span>
				</TriviLink>
			)
		} else {
			return null
		}
	}

	renderAccountingDocumentLinks = (documents: Array<AccountingDocument>) => {
		if (documents && documents.length > 0)
			return documents.map(this.renderAccountingDocumentLink).reduce((total: any, curr: any) => [total, ', ', curr])
		else return null
	}

	renderWarningDocuments = (documents: Array<AccountingDocument>) => {
		const { t } = this.props
		let warningDocuments = this.getPaymentTypeGroups(documents)

		return (
			warningDocuments &&
			warningDocuments.length > 0 && (
				<div css={style.issues}>
					<Issue>
						<div>{t('paymentDialog.paymentTypeWarning')}</div>
						{warningDocuments.map((group: PaymentTypeGroup, index: number) => {
							return (
								<div key={index}>
									{t(`accountingDocument.paymentTypes.${Number_PaymentType(group.paymentType)}`)}:{' '}
									{this.renderAccountingDocumentLinks(group.accountingDocuments)}
								</div>
							)
						})}
					</Issue>
				</div>
			)
		)
	}

	getClientValidationErrors() {
		const errors = {}
		const { bankAccount, dueDate, name } = (this.state: State)

		if (deepEqual(bankAccount, emptyBankAccount())) errors.bankSelect = this.props.t('application.validation.mandatory')

		if (dueDate < new Date(new Date().toDateString()))
			errors.dueDate = this.props.t('application.validation.dueDateNotBiggerThanToday')

		if (isNullOrWhitespace(name)) {
			errors.orderName = this.props.t('application.validation.mandatory')
		}

		return errors
	}

	getPaymentTypeGroups = (documents: Array<AccountingDocument>): Array<PaymentTypeGroup> => {
		const groups: Array<PaymentTypeGroup> = []
		const grouped = groupBy(documents, (d: AccountingDocument) => {
			return d.paymentType
		})

		forEach(grouped, function(value: Array<AccountingDocument>, key: string) {
			const paymentType = parseInt(key)
			if (paymentType === 1) {
				return
			}
			groups.push({ paymentType: paymentType, accountingDocuments: value })
		})
		return groups
	}

	// eslint-disable-next-line no-unused-vars
	getCurrentDate = memoize((day: number) => {
		return new Date()
	})

	renderActionButton() {
		const { t, validateForm } = this.props
		const { submitDisabled } = this.state

		validateForm(this.getClientValidationErrors())

		return (
			<Button
				autoTestId="payment-dialog-submit"
				disabled={submitDisabled}
				labelText={t('paymentDialog.pay')}
				onClick={this.props.validationSubmit(this.placePaymentOrder)}
			/>
		)
	}

	renderHeader() {
		const { t, accountingDocuments, validateForm, ordersForSelectedDocuments, bankAccounts } = this.props

		validateForm(this.getClientValidationErrors())
		let acLinks = this.renderAccountingDocumentLinks(accountingDocuments)

		let orderHeader = (
			<TextField
				autoWidth
				hintText={t('paymentDialog.orderName')}
				autoTestId="payment-dialog-order-name"
				name={'orderName'}
				clientError={this.props.validationMessage('orderName')}
				value={this.props.validationValue('orderName', this.state.name)}
				onChange={this.handleNameChange}
			/>
		)

		let amount = null

		return (
			<div>
				<div css={style.row}>
					<div css={style.name}>{orderHeader}</div>
					<div css={style.amount}>{amount}</div>
				</div>
				<div>
					{t('paymentDialog.summaryOrderEnumeration')} {acLinks}
				</div>
				{this.renderWarningDocuments(accountingDocuments)}
				{ordersForSelectedDocuments && (
					<IssuedPaymentsInfo
						paymentOrders={ordersForSelectedDocuments}
						bankAccounts={bankAccounts}
						accDocs={accountingDocuments}
					/>
				)}
			</div>
		)
	}

	render() {
		const { t, validateForm, open } = this.props
		const today = this.getCurrentDate(new Date().getDate())

		validateForm(this.getClientValidationErrors())

		return (
			<PopupSection open={open} onRequestClose={this.props.onRequestClose}>
				<FormBox header={this.renderHeader()} actions={this.renderActionButton()} headline={t('paymentDialog.title')}>
					<div>
						<div css={style.row}>
							<div css={style.column}>
								<SelectField
									labelText={t('paymentDialog.bankSelect')}
									fullWidth
									clientError={this.props.validationMessage('bankSelect')}
									name={'bankSelect'}
									value={this.props.validationValue('bankSelect', this.state.bankAccount)}
									onChange={this.handleBankAccountChange}
									autoTestId="payment-dialog-bank-select"
								>
									{this.renderBankAccounts()}
								</SelectField>
							</div>
							<div css={style.column}>
								<DatePicker
									fullWidth
									clientError={this.props.validationMessage('dueDate')}
									labelText={t('paymentDialog.dueDate')}
									autoTestId="payment-dialog-due-date"
									minDate={today}
									value={this.props.validationValue('dueDate', this.state.dueDate)}
									onChange={this.handleDueDateChange}
								/>
							</div>
						</div>
					</div>
				</FormBox>
			</PopupSection>
		)
	}
}

const style = {
	issues: {
		marginTop: 10,
	},
	columns: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	column: {
		flex: '0 0 calc(49.95% - 15px)',
	},
	row: {
		display: 'flex',
		justifyContent: 'space-between',
		marginBottom: 20,
	},
	name: {
		fontSize: 24,
	},
	amount: {
		fontSize: 24,
		textAlign: 'right',
	},
}

export default withNotify(withTranslate(validate()(PaymentDialog)))
