/* @flow */

import React from 'react'
import withTranslate from 'wrappers/with-translate'
import type { Props as WithTranslateProps } from 'wrappers/with-translate'
import IconButton from 'components/icon-button'
import Download from 'components/svg-icons/editor/vertical-align-bottom'

type Props = {|
	fileName?: ?string,
	downloadUrl: ?string,
	...WithTranslateProps,
|}

function DownloadButton(props: Props) {
	if (!props.downloadUrl) return null

	return (
		<a href={props.downloadUrl} download={props.fileName || ''}>
			<IconButton autoTestId="download-button" tooltip={props.t('imageViewer.download')}>
				<Download />
			</IconButton>
		</a>
	)
}

export default withTranslate(DownloadButton)
