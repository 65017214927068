//@flow
import buildUrl from 'build-url'
import type { CashbotCompany, CashbotInvoice, CashbotInvoiceCreate, CashbotError, CashbotOffer } from '../types'
import type { ValidationError } from 'types'
import { testClientId } from './test-data'
import { CASHBOT_ERROR_CODE_PREFIX } from 'trivi-constants'

declare var CASHBOT_API_HOST: string //global variable defined in webpack.config.js

const API_PREFIX = CASHBOT_API_HOST

function getOptions(method: 'POST' | 'GET', token: string, payload?: Object) {
	return {
		method,
		headers: {
			Authorization: 'Bearer ' + token,
			'Content-Type': 'application/json',
		},
		body: payload && JSON.stringify(payload),
	}
}

export async function login() {
	const url = buildUrl('https://demo.cashbot.cz/SecurityToken/AddPartner', {
		queryParams: {
			response_type: 'token',
			client_id: testClientId,
			redirect_uri: window.location.href,
			scope: 'api.company.read api.invoice.read api.invoice.write',
			// Maximum scope: 'api.user.read api.company.read api.companybalance.read api.invoice.read api.invoice.write'
		},
	})
	window.location.href = url
}

export async function getCompanyInfo(token: string, companyRegNo: string): Promise<CashbotCompany> {
	const url = API_PREFIX + '/company/detail/identificationNumber/' + companyRegNo
	const options = getOptions('GET', token)
	return fetchJson(url, options)
}

export async function getCompanyList(token: string): Promise<Array<CashbotCompany>> {
	const url = API_PREFIX + '/company/list/user/current'
	const options = getOptions('GET', token)
	return fetchJson(url, options)
}

export async function createInvoice(token: string, invoice: CashbotInvoiceCreate): Promise<CashbotInvoice> {
	const url = API_PREFIX + '/invoice/new'
	const options = getOptions('POST', token, invoice)
	return fetchJson(url, options)
}

export async function getInvoiceDetail(token: string, invoiceId: number): Promise<CashbotInvoice> {
	const url = API_PREFIX + '/invoice/detail/' + invoiceId
	const options = getOptions('GET', token)
	return fetchJson(url, options)
}

export async function getInvoiceOfferList(token: string, invoiceId: number): Promise<Array<CashbotOffer>> {
	const url = API_PREFIX + '/invoice/offer/list/' + invoiceId
	const options = getOptions('GET', token)
	return fetchJson(url, options)
}

export async function confirmInvoiceOffer(token: string, offer: CashbotOffer): Promise<CashbotInvoice> {
	const url = API_PREFIX + '/invoice/offer/confirm'
	const options = getOptions('POST', token, offer)
	return fetchJson(url, options)
}

async function fetchJson<T>(url: string, options?: RequestOptions): Promise<T> {
	const response = await fetch(url, options)
	if (response.status < 200 || response.status >= 300) {
		const cashbotError: CashbotError = await response.json()
		const triviError: ValidationError = {
			code: CASHBOT_ERROR_CODE_PREFIX + cashbotError.errorCode,
			message: cashbotError.errorDescription,
		}
		throw triviError
	}
	return await response.json()
}
