// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[3].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js!../../../variables/grid.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2UKdziUeP8WGbA_hfZACng {\n\tpadding: 30px 0;\n\tmin-height: 100%;\n\theight: 100%;\n}\n\n._2IPwVhZWaNOj75ziPvmFwG {\n\tpadding: 30px 0;\n\tmin-height: 100%;\n\theight: 100vh;\n}\n\n._3bzoUxPrP7WnB7zLhZ0wqt {\n\tposition: absolute;\n\ttop: 0;\n\tleft: 0;\n\tdisplay: block;\n\twidth: 100%;\n\tbackground: white;\n}\n\n._1pNuINr3qC3KZ2-l05hM33 {\n\tmin-width: 40px;\n\tmin-height: 40px;\n}\n\n._24r_OcbIQpbuu715tVuG0r {\n\tfont-size: 14px;\n\ttext-align: center;\n}\n\n.d9D8ou3pMFn6zNv63yaKJ {\n\tpadding: 20px;\n}\n\n.vxwwZCuFnpObVD32Y0uFu {\n\tmargin: 10px auto 0 auto;\n}\n", "",{"version":3,"sources":["webpack://./modules/file/components/file-viewer.css"],"names":[],"mappings":"AAAA;CAEC,eAAA;CACA,gBAAA;CACA,YAAA;AACD;;AAEA;CAEC,eAAA;CACA,gBAAA;CACA,aAAA;AACD;;AAEA;CACC,kBAAA;CACA,MAAA;CACA,OAAA;CACA,cAAA;CACA,WAAA;CACA,iBAAA;AACD;;AAEA;CAEC,eAAA;CACA,gBAAA;AACD;;AAEA;CACC,eAAA;CACA,kBAAA;AACD;;AAEA;CACC,aAAA;AACD;;AAEA;CACC,wBAAA;AACD","sourcesContent":[".autoHeight {\n\tcomposes: popupContainer from 'variables/grid.css';\n\tpadding: 30px 0;\n\tmin-height: 100%;\n\theight: 100%;\n}\n\n.normal {\n\tcomposes: popupContainer from 'variables/grid.css';\n\tpadding: 30px 0;\n\tmin-height: 100%;\n\theight: 100vh;\n}\n\n.viewer {\n\tposition: absolute;\n\ttop: 0;\n\tleft: 0;\n\tdisplay: block;\n\twidth: 100%;\n\tbackground: white;\n}\n\n.loadingViewer {\n\tcomposes: viewer;\n\tmin-width: 40px;\n\tmin-height: 40px;\n}\n\n.noPreview {\n\tfont-size: 14px;\n\ttext-align: center;\n}\n\n.noPreviewHeadline {\n\tpadding: 20px;\n}\n\n.download {\n\tmargin: 10px auto 0 auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"autoHeight": "_2UKdziUeP8WGbA_hfZACng " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["popupContainer"] + "",
	"normal": "_2IPwVhZWaNOj75ziPvmFwG " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["popupContainer"] + "",
	"viewer": "_3bzoUxPrP7WnB7zLhZ0wqt",
	"loadingViewer": "_1pNuINr3qC3KZ2-l05hM33 _3bzoUxPrP7WnB7zLhZ0wqt",
	"noPreview": "_24r_OcbIQpbuu715tVuG0r",
	"noPreviewHeadline": "d9D8ou3pMFn6zNv63yaKJ",
	"download": "vxwwZCuFnpObVD32Y0uFu"
};
export default ___CSS_LOADER_EXPORT___;
