/* @flow */

import type { Dispatch, SearchRequestGeneric, State, Task, TaskAction, TasksSearchResult, UserProfile } from 'types'
import { task as taskApi, tasks as tasksApi, tasksSearch as tasksSearchApi } from '../../common/models/api-model'

import { API_NOT_FOUND_CODE } from 'trivi-constants'
import diff from 'json-patch-gen'
import { notFoundRoute } from 'modules/navigation/routing/routes'
import { push } from 'react-router-redux'
import type { TaskView } from 'modules/task/types'

export function searchMyTasks(body: SearchRequestGeneric) {
	return async (dispatch: Dispatch<TaskAction>, getState: () => State) => {
		const state = getState()
		const me: ?UserProfile = state.user.me.data

		if (me != null) {
			const updatedBody: SearchRequestGeneric = Object.assign(({ page: 1 }: SearchRequestGeneric), body, {
				filters: [
					...(body.filters || []),
					{
						field: 'assigneeId',
						value: me.id,
					},
					{
						field: 'state',
						valueContains: [0, 1],
					},
				],
			})

			return dispatch(searchTasks(updatedBody))
		}
	}
}

export function searchTasks(body: SearchRequestGeneric) {
	return async (dispatch: Dispatch<TaskAction>) => {
		dispatch({
			type: 'START_SEARCH_TASKS',
			body,
		})
		try {
			const response: TasksSearchResult = await tasksSearchApi.post(body)

			return dispatch({
				type: 'FINISH_SEARCH_TASKS',
				response,
			})
		} catch (err) {
			return dispatch({
				type: 'FINISH_SEARCH_TASKS',
				response: null,
				validationError: err,
			})
		}
	}
}

export function loadTask(taskId: string) {
	return async (dispatch: Dispatch<TaskAction>) => {
		dispatch({
			type: 'START_LOAD_TASK',
			id: taskId,
		})
		try {
			const result: Task = await taskApi.get({
				taskId: parseInt(taskId, 10) || 0,
			})
			dispatch({
				type: 'FINISH_LOAD_TASK',
				id: taskId,
				task: result,
			})
		} catch (error) {
			await dispatch({
				type: 'FINISH_LOAD_TASK',
				id: taskId,
				task: null,
				serverError: error,
			})
			if (error.code === API_NOT_FOUND_CODE) {
				dispatch(push(notFoundRoute()))
			}
		}
	}
}

export function createTask(task: Task) {
	return async (dispatch: Dispatch<TaskAction>) => {
		dispatch({
			type: 'START_CREATE_TASK',
			task: task,
		})
		try {
			const result: Task = await tasksApi.post(task)
			return dispatch({
				type: 'FINISH_CREATE_TASK',
				task: result,
			})
		} catch (err) {
			return dispatch({
				type: 'FINISH_CREATE_TASK',
				task: null,
				validationError: err,
			})
		}
	}
}

export function replaceTask(task: Task) {
	return async (dispatch: Dispatch<TaskAction>) => {
		const taskId: string = task.id || ''
		dispatch({
			type: 'START_UPDATE_TASK',
			task: task,
		})
		try {
			const result: Task = await taskApi.put(
				{
					taskId: parseInt(task.id, 10) || 0,
				},
				task,
			)
			return dispatch({
				type: 'FINISH_UPDATE_TASK',
				id: taskId,
				task: result,
			})
		} catch (error) {
			return dispatch({
				type: 'FINISH_UPDATE_TASK',
				id: taskId,
				task: null,
				validationError: error,
			})
		}
	}
}

export function updateTask(oldTask: Task, newTask: Task) {
	return async (dispatch: Dispatch<TaskAction>) => {
		const taskId: string = newTask.id || ''
		dispatch({
			type: 'START_UPDATE_TASK',
			task: newTask,
		})
		try {
			const result: Task = await taskApi.patch(
				{
					taskId: parseInt(newTask.id, 10) || 0,
				},
				diff(oldTask, newTask),
			)
			return dispatch({
				type: 'FINISH_UPDATE_TASK',
				id: taskId,
				task: result,
			})
		} catch (error) {
			return dispatch({
				type: 'FINISH_UPDATE_TASK',
				id: taskId,
				task: null,
				validationError: error,
			})
		}
	}
}

export function removeTask(taskId: string) {
	return async (dispatch: Dispatch<TaskAction>) => {
		dispatch({
			type: 'START_REMOVE_TASK',
			id: taskId,
		})
		try {
			await taskApi.delete({
				taskId: parseInt(taskId, 10) || 0,
			})
			dispatch({
				type: 'FINISH_REMOVE_TASK',
				id: taskId,
				success: true,
			})
		} catch (error) {
			dispatch({
				type: 'FINISH_REMOVE_TASK',
				id: taskId,
				success: false,
				serverError: error,
			})
		}
	}
}

export function changeTaskListView(view: TaskView) {
	return {
		type: 'CHANGE_TASK_LIST_VIEW',
		view: view,
	}
}
