/* @flow */

import { withTranslate, type WithTranslateProps } from 'wrappers'
import BasicGridFilter from 'modules/data-grid/components/basic-grid-filter'
import React, { Component } from 'react'
import type { Filter } from 'types'

type Props = {|
	...WithTranslateProps,
	gridId: string,
	defaultFilter: Filter,
|}

class TagsAdvancedFilter extends Component<Props> {
	render() {
		const { t } = this.props
		return (
			<BasicGridFilter
				name={this.props.gridId}
				gridId={this.props.gridId}
				dataType="paymentOrders"
				defaultFilter={this.props.defaultFilter}
				dateFilterName="generatedDate"
				textFilterName="searchText"
				noFilterLabel={t('bankAccount.filter.allPaymentOrders')}
			/>
		)
	}
}

export default withTranslate(TagsAdvancedFilter)
