/* @flow */

import React, { Component } from 'react'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import Helmet from 'react-helmet'
import styles from './dashboard.css'
import Header from '../containers/header'
import Reports from '../components/report/reports'

type Props = {|
	...WithTranslateProps,
|}

class DashboardPage extends Component<Props> {
	render() {
		return (
			<div className={styles.root} id={'trivi-dashboard-reports'}>
				<Helmet>
					<title>{this.props.t('dashboard.reports.headline')}</title>
				</Helmet>
				<Header />
				<Reports />
			</div>
		)
	}
}

export default withTranslate(DashboardPage)
