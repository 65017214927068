/* @flow */
/** @jsx jsx */

import { Component, Fragment } from 'react'
import { jsx } from '@emotion/core'
import type { CardResponseParametersPayment, CardResponse } from 'types'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import { formatToDateString } from 'utils/formatters/date-formatter'
import CardIcon from 'components/card/card-icon'
import PopupSection from 'components/PopupSection'
import Paper from 'components/Paper'
import Loader from 'components/loader'
import { formatToMoney } from 'utils/formatters'

type Props = {|
	...WithTranslateProps,
	card: ?CardResponse,
	open: boolean,
	onRequestClose: () => void,
	loadQrCode: (payment: CardResponseParametersPayment) => Promise<*>,
|}

type State = {|
	qrCode: ?string,
	loading: boolean,
|}

class PaymentsDetailDialog extends Component<Props, State> {
	state: State = {
		qrCode: null,
		loading: true,
	}

	componentDidUpdate(prevProps: Props) {
		if (
			this.props.card &&
			this.props.card.params &&
			this.props.card.params.payment &&
			this.props.card.id !== (prevProps.card && prevProps.card.id)
		) {
			this.props.loadQrCode(this.props.card.params.payment).then(
				(qrCode: string) => this.setState({ loading: false, qrCode }),
				() => this.setState({ loading: false, qrCode: null }),
			)
		}
	}

	renderQrCode() {
		if (!this.props.card || !this.props.card.params || !this.props.card.params.payment) return null
		if (!this.state.loading && !this.state.qrCode) return null

		return (
			<div css={styles.qr}>
				<Loader visible={this.state.loading} size={40} />
				{this.state.qrCode && <img width={200} height={200} src={this.state.qrCode} />}
			</div>
		)
	}

	renderDetail(headline: string, value: ?string | ?number) {
		if (value === null || value === undefined) return null

		return (
			<Fragment>
				<div css={styles.detail}>
					<h4>{this.props.t(headline)}:</h4>
					<p>{value}</p>
				</div>
				<br />
			</Fragment>
		)
	}

	renderPaymentDetails() {
		const payment = this.props.card && this.props.card.params ? this.props.card.params.payment : null
		const bankAccount = payment ? payment.bankAccount : null
		if (!payment || !bankAccount) return null
		const formattedAmount = formatToMoney(Math.abs(payment.amount || 0), {
			currency: payment.currency,
			minimumFractionDigits: 2,
		})

		return (
			<div css={styles.details}>
				{this.renderDetail(
					'myCompany.card.paymentDetails.account',
					`${bankAccount.number || ''}/${bankAccount.bankCode || ''}`,
				)}
				{this.renderDetail('fields.iban', bankAccount.iban)}
				{this.renderDetail(
					'myCompany.card.paymentDetails.dueDate',
					payment.dueDate ? formatToDateString(payment.dueDate) : null,
				)}
				{this.renderDetail('myCompany.card.paymentDetails.variableSymbol', payment.variableSymbol)}
				{this.renderDetail('myCompany.card.paymentDetails.constantSymbol', payment.constantSymbol)}
				{this.renderDetail('myCompany.card.paymentDetails.specificSymbol', payment.specificSymbol)}
				{this.renderDetail('myCompany.card.paymentDetails.amount', formattedAmount)}
			</div>
		)
	}

	render() {
		if (!this.props.card) return null
		const { card } = this.props

		return (
			<PopupSection open={this.props.open} onRequestClose={this.props.onRequestClose}>
				<div css={styles.root}>
					<Paper zDepth={3}>
						<div css={styles.paper}>
							<div css={styles.head}>
								<CardIcon icon={card.icon} size={60} />
								<div css={styles.titleContainer}>
									<div css={styles.title}>{card.title}</div>
									{card.subtitle && <div css={styles.subtitle}>{card.subtitle}</div>}
								</div>
							</div>
							<div css={styles.body}>
								{this.renderPaymentDetails()}
								{this.renderQrCode()}
							</div>
						</div>
					</Paper>
				</div>
			</PopupSection>
		)
	}
}

const styles = {
	root: {
		padding: 80,
		display: 'flex',
		justifyContent: 'center',
	},
	paper: {
		width: 920,
		padding: 45,
	},
	head: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		overflow: 'hidden',
	},
	body: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'flex-end',
	},
	titleContainer: {
		flex: '1 1 100%',
		marginLeft: 22,
		position: 'relative',
		overflow: 'hidden',
	},
	title: {
		fontSize: 30,
		lineHeigt: 1.2,
		fontWeight: 600,
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
		overflow: 'hidden',
	},
	subtitle: {
		fontSize: 20,
		lineHeigt: 1.2,
		marginTop: 4,
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
		overflow: 'hidden',
	},
	details: {
		marginTop: 40,
	},
	detail: {
		h4: {
			fontSize: 17,
			lineHeigt: 1.25,
			fontWeigt: 600,
			margin: 0,
			padding: 0,
		},
		p: {
			fontSize: 17,
			lineHeigt: 1.25,
			fontWeigt: 'normal',
			margin: 0,
			padding: 0,
		},
	},
	qr: {
		position: 'relative',
		width: 200,
		height: 200,
	},
}

export default withTranslate(PaymentsDetailDialog)
