/* @flow */
import type { ValidationError, State } from 'types'
import { isCashbotEnabledSelector } from 'modules/organization/selectors'
import { organizationSettingsFeatures as organizationSettingsFeaturesApi } from 'modules/common/models/api-model'

type SetToggleCashbot = {|
	type: 'SET_CASHBOT_ENABLED',
	enabled: boolean,
	serverError?: ValidationError,
|}

export type Action = SetToggleCashbot

export function toggleCashbotEnabled(enabled: boolean) {
	return async (dispatch: Dispatch<Action>, getState: () => State) => {
		const currentValue = isCashbotEnabledSelector(getState())
		dispatch({
			type: 'SET_CASHBOT_ENABLED',
			enabled,
		})
		try {
			// $FlowFixMe
			await organizationSettingsFeaturesApi.patch({}, [
				{
					op: 'replace',
					path: '/integrations/cashbot/enabled',
					value: enabled,
				},
			])
			dispatch({
				type: 'SET_CASHBOT_ENABLED',
				enabled,
			})
		} catch (serverError) {
			dispatch({
				type: 'SET_CASHBOT_ENABLED',
				enabled: currentValue,
				serverError,
			})
		}
	}
}
