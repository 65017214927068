/* @flow */

import React, { Component } from 'react'

type Props = {|
	children: any,
	scroll: number,
|}

class DocumentFieldsScroller extends Component<Props> {
	static defaultProps = {
		scroll: 0,
	}

	componentDidUpdate(prevProps: Props) {
		if (prevProps.scroll !== this.props.scroll) {
			this.refs.root.scrollTop = this.props.scroll
		}
	}

	render() {
		return (
			<div
				style={{
					maxHeight: '100%',
					overflow: 'auto',
					position: 'relative',
				}}
				ref="root"
				className={'document-extraction-fields-scroller'}
			>
				{this.props.children}
			</div>
		)
	}
}

export default DocumentFieldsScroller
