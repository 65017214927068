// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[3].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js!../../../variables/grid.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2lvRiNhbeKhO5wImQ22JUD {\n\tposition: relative;\n\tpadding: 30px 0;\n\tmin-height: 100%;\n\theight: 100%;\n}\n\n.Jlkavgj4hsXhEjHI_c6DS {\n\tposition: absolute;\n\ttop: 0;\n\tleft: 0;\n\tdisplay: block;\n\twidth: 100%;\n\tbackground: white;\n}\n\n._1lvNbLuWFaiwyjDR361jJ_ {\n\tmin-width: 40px;\n\tmin-height: 40px;\n}\n", "",{"version":3,"sources":["webpack://./modules/user/components/terms-viewer.css"],"names":[],"mappings":"AAAA;CACC,kBAAA;CAEA,eAAA;CACA,gBAAA;CACA,YAAA;AACD;;AAEA;CACC,kBAAA;CACA,MAAA;CACA,OAAA;CACA,cAAA;CACA,WAAA;CACA,iBAAA;AACD;;AAEA;CAEC,eAAA;CACA,gBAAA;AACD","sourcesContent":[".autoHeight {\n\tposition: relative;\n\tcomposes: popupContainer from 'variables/grid.css';\n\tpadding: 30px 0;\n\tmin-height: 100%;\n\theight: 100%;\n}\n\n.viewer {\n\tposition: absolute;\n\ttop: 0;\n\tleft: 0;\n\tdisplay: block;\n\twidth: 100%;\n\tbackground: white;\n}\n\n.loadingViewer {\n\tcomposes: viewer;\n\tmin-width: 40px;\n\tmin-height: 40px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"autoHeight": "_2lvRiNhbeKhO5wImQ22JUD " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["popupContainer"] + "",
	"viewer": "Jlkavgj4hsXhEjHI_c6DS",
	"loadingViewer": "_1lvNbLuWFaiwyjDR361jJ_ Jlkavgj4hsXhEjHI_c6DS"
};
export default ___CSS_LOADER_EXPORT___;
