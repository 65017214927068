/* @flow */

import React, { Component } from 'react'
import type { WithTranslateProps } from '../../../../wrappers'
import { withTranslate } from '../../../../wrappers'
import Button from '../../../../components/button'
import Dialog from '../../../../components/dialog'
import AccountingDocumentPreview from '../../../common/containers/accounting-document-preview'

type Props = {|
	...WithTranslateProps,
	onConfirm: () => void,
	onClose: () => void,
	open: boolean,
	accountingDocumentId: ?string,
|}

class DocumentPreviewDialog extends Component<Props> {
	render() {
		if (!this.props.open) return null
		const { t } = this.props

		const actions = [
			<Button
				key="confirm"
				labelText={t('accountingDocument.states.processed')}
				onClick={this.props.onConfirm}
				primary
				wide
				autoTestId="document-preview-dialog-confirm"
			/>,
			<Button
				key="reject"
				labelText={t('accountingDocument.states.backToEdit')}
				autoTestId="document-preview-dialog-back"
				onClick={this.props.onClose}
				secondary
				wide
			/>,
		]

		return (
			<Dialog
				actions={actions}
				maxContentWidth={960}
				open={this.props.open}
				title={t('accountingDocument.states.confirmDocumentHeadline')}
				autoTestId="document-preview-dialog"
				isScrollable
			>
				<div
					style={{
						fontSize: 16,
						textAlign: 'center',
						fontWeight: 'normal',
						marginBottom: 30,
					}}
				>
					{t('accountingDocument.states.confirmDocumentSubheadline')}
				</div>
				<AccountingDocumentPreview accountingDocumentId={this.props.accountingDocumentId} />
			</Dialog>
		)
	}
}

export default withTranslate(DocumentPreviewDialog)
