// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[3].use[1]!../../../node_modules/postcss-loader/dist/cjs.js!../../variables/colors.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1ckNZfZ7wogRRRFZIuXot3 {\n\tdisplay: flex;\n\tflex-direction: column;\n\tmax-width: 900px;\n\tmargin: 40px auto;\n\tposition: relative;\n}\n\n._1Qa3odWL22zJxLfopSI6RC {\n\tposition: absolute;\n\tbackground-color: red;\n\theight: 100%;\n\tleft: 10px;\n\ttop: -31px;\n\tz-index: 0;\n\tborder-left: 1px solid " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["grey500"] + ";\n}\n\n._3dLfKtEj7ch2VqUwY37Gpq {\n\tdisplay: flex;\n\tmargin-bottom: 15px;\n\tz-index: 1;\n}\n\n._1csjqUonShaf3J3CQMr0Io {\n\tfont-size: 10px;\n\tcolor: white;\n\tmargin-right: 13px;\n\tbackground-color: " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["blue500"] + ";\n\tpadding: 5px;\n\tborder-radius: 50%;\n\theight: fit-content;\n}\n\n.Ry7N2GhRsVO_fF41TPekW {\n\tposition: absolute;\n\tleft: -150px;\n}\n\n\n", "",{"version":3,"sources":["webpack://./components/activities/activities.css"],"names":[],"mappings":"AAEA;CACC,aAAA;CACA,sBAAA;CACA,gBAAA;CACA,iBAAA;CACA,kBAAA;AASD;;AANA;CACC,kBAAA;CACA,qBAAA;CACA,YAAA;CACA,UAAA;CACA,UAAA;CACA,UAAA;CACA,mEAAA;AASD;;AANA;CACC,aAAA;CACA,mBAAA;CACA,UAAA;AASD;;AANA;CACC,eAAA;CACA,YAAA;CACA,kBAAA;CACA,8DAAA;CACA,YAAA;CACA,kBAAA;CACA,mBAAA;AASD;;AANA;CACC,kBAAA;CACA,YAAA;AASD","sourcesContent":["@value blue500, grey500 from \"variables/colors.css\";\n\n.activities {\n\tdisplay: flex;\n\tflex-direction: column;\n\tmax-width: 900px;\n\tmargin: 40px auto;\n\tposition: relative;\n}\n\n.border {\n\tposition: absolute;\n\tbackground-color: red;\n\theight: 100%;\n\tleft: 10px;\n\ttop: -31px;\n\tz-index: 0;\n\tborder-left: 1px solid grey500;\n}\n\n.activity {\n\tdisplay: flex;\n\tmargin-bottom: 15px;\n\tz-index: 1;\n}\n\n.avatar {\n\tfont-size: 10px;\n\tcolor: white;\n\tmargin-right: 13px;\n\tbackground-color: blue500;\n\tpadding: 5px;\n\tborder-radius: 50%;\n\theight: fit-content;\n}\n\n.loadingBar {\n\tposition: absolute;\n\tleft: -150px;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"blue500": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["blue500"] + "",
	"grey500": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["grey500"] + "",
	"activities": "_1ckNZfZ7wogRRRFZIuXot3",
	"border": "_1Qa3odWL22zJxLfopSI6RC",
	"activity": "_3dLfKtEj7ch2VqUwY37Gpq",
	"avatar": "_1csjqUonShaf3J3CQMr0Io",
	"loadingBar": "Ry7N2GhRsVO_fF41TPekW"
};
export default ___CSS_LOADER_EXPORT___;
