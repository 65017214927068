// @flow
/** @jsx jsx */

import { PureComponent } from 'react'
import { jsx } from '@emotion/core'
import memoize from 'memoize-one'
import type { AccountingDocument } from 'types'
import { AccountingDocumentDirection_Number } from 'types/convertor'
import {
	getAccDocToPayDomestic,
	getAccDocCurrencyDomestic,
	AccountingDocumentTypeNumber_isCashRegister,
} from 'types/operations'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import PaymentState from './payment-state'
import { AmountDisplay, Tooltip } from 'components'
import CreditCard from 'components/svg-icons/action/credit-card'
import { colors } from 'variables'

type Props = {|
	...WithTranslateProps,
	accountingDocument: AccountingDocument,
	showTotalToPay?: boolean,
	compact?: boolean,
	short?: boolean,
	offsetCashRegisterDocuments?: boolean,
|}

class AccountingDocumentGridTotalColumn extends PureComponent<Props> {
	isPaidConfirmed = (): boolean => {
		const { accountingDocument } = this.props
		return (accountingDocument.payments && accountingDocument.payments.paidConfirmed) || false
	}

	renderTotalToPay = () => {
		if (!this.props.showTotalToPay) return null

		const { t, accountingDocument } = this.props
		const styles = this.getStyles(this.props.compact, this.props.showTotalToPay)
		const toPayDomestic: number = getAccDocToPayDomestic(accountingDocument)
		const currencyDomestic: ?string = getAccDocCurrencyDomestic(accountingDocument)

		if (!toPayDomestic) {
			return (
				this.isPaidConfirmed() && (
					<div css={styles.amountToPay}>{t('accountingDocument.gridTotalColumn.paymentStates.paidConfirmed')}</div>
				)
			)
		} else {
			if (toPayDomestic !== accountingDocument.totalDomestic) {
				return (
					<div css={styles.amountToPay}>
						{t('accountingDocument.gridTotalColumn.toPay')}&nbsp;
						<AmountDisplay currency={currencyDomestic} amount={toPayDomestic} />
					</div>
				)
			}
		}

		return null
	}

	renderPaymentState = () => {
		const { accountingDocument } = this.props
		const styles = this.getStyles(this.props.compact, this.props.showTotalToPay)

		if (AccountingDocumentTypeNumber_isCashRegister(accountingDocument.type)) return <div css={styles.empty} />

		return (
			<div css={styles.payment}>
				<PaymentState
					compact={this.props.showTotalToPay || this.props.compact}
					accountingDocument={accountingDocument}
				/>
			</div>
		)
	}

	renderPaymentOrdersInfo = () => {
		const styles = this.getStyles(this.props.compact, this.props.showTotalToPay)
		return (
			<div css={styles.orders}>
				<Tooltip label={this.props.t('accountingDocument.gridTotalColumn.paymentOrdersInfo')} inline>
					<CreditCard style={styles.card} color={colors.grey400} disabled size={19} />
				</Tooltip>
			</div>
		)
	}

	renderTotal = () => {
		const { accountingDocument, compact } = this.props
		const { type, direction, currency, total } = accountingDocument
		const hasPaymentOrders = accountingDocument && accountingDocument.hasPaymentOrders
		const isReceivedCashRegisterDocument =
			AccountingDocumentTypeNumber_isCashRegister(type) && direction === AccountingDocumentDirection_Number('received')
		const amount = isReceivedCashRegisterDocument ? (total ? -total : 0) : total
		const color = isReceivedCashRegisterDocument ? colors.red : colors.black
		return (
			<span
				css={{
					display: 'flex',
					justifyContent: 'flex-end',
					alignItems: 'center',
					color,
				}}
			>
				{hasPaymentOrders && !compact && this.renderPaymentOrdersInfo()}
				<AmountDisplay currency={currency} amount={amount} />
			</span>
		)
	}

	getStyles = memoize((compact: ?boolean, showTotalToPay: ?boolean, short: ?boolean) => {
		return {
			root: {
				position: 'relative',
				display: 'flex',
				flexWrap: 'nowrap',
				justifyContent: 'flex-end',
				alignItems: 'center',
				paddingRight: short ? undefined : 20,
				textAlign: 'right',
				overflow: 'hidden',
				width: '100%',
				color: compact ? '#636363' : 'initial',
				fontSize: compact ? 12 : 'initial',
				lineHeight: compact ? '20px' : 'initial',
			},
			container: {
				overflow: 'hidden',
				maxWidth: '100%',
				width: short ? undefined : '100%',
			},
			amount: {
				fontWeight: compact ? 'normal' : 'bold',
				lineHeight: compact ? '20px' : 'initial',
			},
			progress: {
				marginBottom: 12,
			},
			amountToPay: {
				color: colors.blackFaded60,
				fontSize: 12,
				overflow: 'hidden',
				whiteSpace: 'nowrap',
				textOverflow: 'ellipsis',
			},
			payment: {
				position: 'reative',
				top: compact ? -1 : 0,
				marginLeft: showTotalToPay ? 12 : 4,
				marginRight: showTotalToPay ? -4 : 0,
				minWidth: showTotalToPay ? 24 : 0,
			},
			card: { position: 'relative', top: -1, verticalAlign: 'middle' },
			orders: {
				marginRight: 5,
				flex: '1 0 19px',
				textAlign: 'left',
			},
			empty: {
				width: 38,
			},
		}
	})

	render() {
		const styles = this.getStyles(this.props.compact, this.props.showTotalToPay, this.props.short)
		return (
			<div css={styles.root}>
				<div css={styles.container}>
					<div css={styles.amount}>{this.renderTotal()}</div>
					{this.renderTotalToPay()}
				</div>
				{this.renderPaymentState()}
			</div>
		)
	}
}

export default withTranslate(AccountingDocumentGridTotalColumn)
