// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n", "",{"version":3,"sources":[],"names":[],"mappings":"","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dialogOverlay": "1400",
	"dialog": "1500",
	"layer": "2000",
	"tooltip": "3000",
	"popover": "4000",
	"chat": "5000",
	"appLoader": "8000",
	"feedback": "8001",
	"snackbar": "9001",
	"addButton": "210",
	"support": "209"
};
export default ___CSS_LOADER_EXPORT___;
