/* @flow */

export function getExtensionFromFilename(fileName: ?string): ?string {
	if (!fileName) return null
	if (!fileName.includes('.')) return null

	return fileName
		.toLowerCase()
		.split('.')
		.pop()
}
