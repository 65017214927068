/* @flow */

import type { State } from 'types'
import store from 'store'

function getOrganizationId() {
	const state: State = store.getState()
	return state.user.currentOrganizationId || 'error'
}

export function addressBooksRoute() {
	return `/${getOrganizationId()}/address-book`
}

export function addressBookRoute(id: string) {
	return `/${getOrganizationId()}/address-book/${id}`
}
