// @flow
/** @jsx jsx */

import { jsx } from '@emotion/core'
import type { AccountingDocument } from 'types'
import { Number_AccountingDocumentDirection } from 'types/convertor'
import DirectionAmountDisplay from 'components/direction-amount-display'

export function moneyRenderer(value: ?number, row: AccountingDocument) {
	return (
		<div css={style}>
			<DirectionAmountDisplay
				amount={value}
				currency={row.currency}
				income={'received' === Number_AccountingDocumentDirection(row.direction)}
				outcome={'issued' === Number_AccountingDocumentDirection(row.direction)}
			/>
		</div>
	)
}

const style = {
	fontWeight: 600,
	textAlign: 'right',
	paddingRight: 40,
	paddingLeft: 0,
}
