/* @flow */

import React, { Component } from 'react'
import deepEqual from 'deep-equal'
import { validate, withTranslate, type WithTranslateProps, type FormValidationProps } from 'wrappers'
import { emptyOrganizationAddress } from 'types/empty'
import type {
	Country,
	Base64Image,
	Organization,
	OrganizationAddress,
	OrganizationCountrySpecificSettings,
	OrganizationSettings,
	CompanyType,
} from 'types'
import {
	Checkbox,
	Dropzone,
	TerritorialDivisionSelector,
	TextField,
	CountrySelector,
	EmailInput,
	PhoneInput,
} from 'components'
import CompanyTypeSelector from 'modules/common/components/selectors/company-type-selector'
import NaceCodeSelector from 'modules/common/components/selectors/nace-code-selector'
import TriviServices from '../trivi-settings/trivi-services'
import ActionDelete from 'components/svg-icons/action/delete'
import ActionAutorenew from 'components/svg-icons/action/autorenew'
import SaveSettings from '../save-settings'
import { colors } from 'variables'
import styles from '../settings.css'
import { Validator } from 'utils'

type Props = {
	...WithTranslateProps,
	...FormValidationProps,
	organization: ?Organization,
	organizationId: string,
	organizationSettings: ?OrganizationSettings,
	logoBinary: Base64Image,
	loading: ?boolean,
	currentUserIsInternal: boolean,
	currentUserIsAdmin: boolean,
	companyTypes: ?Array<CompanyType>,
	updateOrganizationSettings: (newSettings: OrganizationSettings) => void,
	changeOrganizationAddress: (address: OrganizationAddress) => Promise<any>,
	removeOrganizationAddress: (address: OrganizationAddress) => void,
	uploadOrganizationLogo: (body: File) => Promise<any>,
	removeOrganizationLogo: () => void,
	getOrganizationLogo: () => void,
	removeOrganization: (organizationId?: string) => Promise<any>,
}

const MAIN_ADDRESS_TYPE = 1
const ADDRESS_TYPES = [MAIN_ADDRESS_TYPE, 2]

const JURIDICAL_PERSON = 'JuridicalPerson'

type State = {
	name: string,
	regNo: string,
	taxId: string,
	addresses: Array<OrganizationAddress>,
	sameAddresses: boolean,
	editedAddresses: { [index: number]: boolean },
	mainNaceCode: string,
	sideNaceCode: string,
	court: string,
	fileNumber: string,
	email: string,
	homePage: string,
	phoneNumber: string,
	territorialDivisionId: ?string,
	companyTypeId: ?string,
}

class DefaultSettings extends Component<Props, State> {
	savedState: ?State = null
	altered: boolean = false
	state: State = this.getStateFromProps(this.props)
	dropzone: any = null

	saveState = () => {
		this.savedState = this.state
	}

	restoreSavedState = () => {
		if (this.savedState) {
			this.setState(this.savedState)
		}
		this.resetSavedState()
	}

	resetSavedState = () => {
		this.altered = false
		this.savedState = null
	}

	changeSettings = (partialState: Object) => {
		if (!this.altered) {
			this.altered = true
			this.saveState()
		}
		this.setState(partialState, () => {
			deepEqual(this.state, this.savedState) && this.restoreSavedState()
		})
	}

	UNSAFE_componentWillReceiveProps(newProps: Props) {
		if (!this.altered) {
			this.setState(this.getStateFromProps(newProps))
			if (newProps.loading === false && this.props.loading) this.resetSavedState()
		}
	}

	componentDidMount = () => {
		this.props.getOrganizationLogo()
	}

	getStateFromProps(props: Props): State {
		const settings: ?OrganizationSettings = props.organizationSettings

		const org: ?Organization = props.organization
		let addresses: Array<OrganizationAddress> = org && org.addresses ? org.addresses : []

		const sameAddresses: boolean =
			addresses.length === 0 || (addresses.length === 1 && addresses[0].type === MAIN_ADDRESS_TYPE)

		// if there is no addres of certain type -> add empty address of that type
		ADDRESS_TYPES.forEach((type: 1 | 2) => {
			if (addresses.findIndex((a: OrganizationAddress) => a.type === type) === -1) {
				const a: OrganizationAddress = Object.freeze({
					...emptyOrganizationAddress(),
					type,
				})
				addresses = [...addresses, a]
			}
		})

		const cz: ?OrganizationCountrySpecificSettings =
			settings && settings.countrySpecificSettings && settings.countrySpecificSettings.cz

		return {
			addresses,
			sameAddresses,
			editedAddresses: this.state ? this.state.editedAddresses : {},

			name: (settings && settings.name) || '',
			regNo: (settings && settings.regNo) || '',
			taxId: (settings && settings.taxId) || '',

			mainNaceCode: (cz && cz.mainNaceCode) || '',
			sideNaceCode: (cz && cz.sideNaceCode) || '',
			court: (cz && cz.court) || '',
			fileNumber: (cz && cz.fileNumber) || '',
			email: (cz && cz.email) || '',
			homePage: (cz && cz.homePage) || '',
			phoneNumber: (cz && cz.phoneNumber) || '',
			territorialDivisionId: cz && cz.territorialDivisionId,
			companyTypeId: cz && cz.companyTypeId != null ? cz.companyTypeId : undefined,
		}
	}

	saveSettings = async () => {
		this.updateOrganizationSettings()
		this.state.addresses.forEach(this.changeOrganizationAddress)
		this.resetSavedState()
	}

	changeOrganizationAddress = (a: OrganizationAddress, index: number) => {
		const edited: ?boolean = this.state.editedAddresses[index]

		if (!this.state.sameAddresses || (this.state.sameAddresses && a.type === MAIN_ADDRESS_TYPE)) {
			edited && this.props.changeOrganizationAddress(a)
		} else if (a.type !== MAIN_ADDRESS_TYPE && (a.street || a.city || a.country || a.zip)) {
			this.props.removeOrganizationAddress(a)
		}

		this.setState({ editedAddresses: {} })
	}

	toggleAddresses = () => {
		let addresses: Array<OrganizationAddress> = [...this.state.addresses]
		const invoicingIndex: number = addresses.findIndex((a: OrganizationAddress) => a.type == MAIN_ADDRESS_TYPE)
		const invoicingAddress: ?OrganizationAddress = invoicingIndex >= 0 ? addresses[invoicingIndex] : null

		const deliveryAddress: ?OrganizationAddress = addresses.find((a: OrganizationAddress) => a.type == 2)

		if (
			invoicingAddress &&
			deliveryAddress &&
			invoicingAddress.city === '' &&
			invoicingAddress.country === '' &&
			invoicingAddress.street === '' &&
			invoicingAddress.zip === ''
		) {
			addresses[invoicingIndex] = Object.freeze({
				...deliveryAddress,
				type: MAIN_ADDRESS_TYPE,
			})
		}

		this.changeSettings({
			addresses,
			sameAddresses: !this.state.sameAddresses,
		})
	}

	handleTextFieldChange = (field: string) => (event: SyntheticInputEvent<HTMLInputElement>, val: ?string) => {
		this.changeSettings({ [field]: val })
	}

	async updateOrganizationSettings(): Promise<any> {
		const organizationSettings: ?OrganizationSettings = this.props.organizationSettings
		if (organizationSettings) {
			const countrySpecificSettings: ?{ cz?: OrganizationCountrySpecificSettings } =
				organizationSettings.countrySpecificSettings

			const cz: OrganizationCountrySpecificSettings = Object.freeze({
				...((countrySpecificSettings && countrySpecificSettings.cz) || {}),
			})

			const newSettings: OrganizationSettings = Object.freeze({
				...organizationSettings,
				name: this.state.name,
				taxId: this.state.taxId,
				countrySpecificSettings: {
					...countrySpecificSettings,
					cz: {
						...cz,
						mainNaceCode: this.state.mainNaceCode,
						sideNaceCode: this.state.sideNaceCode,
						court: this.state.court,
						fileNumber: this.state.fileNumber,
						email: this.state.email,
						homePage: this.state.homePage,
						phoneNumber: this.state.phoneNumber,
						territorialDivisionId: this.state.territorialDivisionId || cz.territorialDivisionId,
						companyTypeId: this.state.companyTypeId || cz.companyTypeId,
					},
				},
			})

			return await this.props.updateOrganizationSettings(newSettings)
		}
	}

	handleAddressCountryChange = (index: number) => {
		return (country: Country) => {
			const addresses: Array<OrganizationAddress> = [...this.state.addresses]
			addresses[index] = Object.freeze({
				...addresses[index],
				country: country.id || '',
			})

			let { editedAddresses } = this.state
			editedAddresses = {
				...editedAddresses,
				[index]: this.hasAddressChanged(addresses[index].type, 'country', country, index),
			}

			this.changeSettings({ addresses, editedAddresses })
		}
	}

	hasAddressChanged(type: any, field: string, value: any, index: number): boolean {
		if (
			!this.props.organization ||
			!this.props.organization.addresses ||
			this.props.organization.addresses.length < index
		) {
			return true
		}

		const originalAddress: ?OrganizationAddress = this.props.organization.addresses.find(
			(a: OrganizationAddress) => a.type === type,
		)

		return !originalAddress || originalAddress[field] !== value
	}

	handleAddressTextFieldChange = (field: string, index: number) => {
		return (ev: SyntheticInputEvent<HTMLInputElement>, value: ?string) => {
			const addresses = [...this.state.addresses]
			addresses[index] = Object.freeze({
				...addresses[index],
				[field]: value,
			})

			let { editedAddresses } = this.state
			editedAddresses = {
				...editedAddresses,
				[index]: this.hasAddressChanged(addresses[index].type, field, value, index),
			}

			this.changeSettings({ addresses, editedAddresses })
		}
	}

	handleMainNaceCodeChange = (mainNaceCode: ?string) => {
		this.changeSettings({ mainNaceCode })
	}

	handleSideNaceCodeChange = (sideNaceCode: ?string) => {
		this.changeSettings({ sideNaceCode })
	}

	handleTerritorialDivisionChange = (id: number) => {
		this.changeSettings({ territorialDivisionId: id.toString() })
	}

	handleCompanyTypeChange = (type: ?CompanyType) => {
		type && this.changeSettings({ companyTypeId: type.id })
	}

	renderTextInput(field: string, label: string, disabled?: boolean = false, hint?: string, required?: boolean = false) {
		return (
			<TextField
				fullWidth
				disabled={disabled}
				onChange={this.handleTextFieldChange(field)}
				labelText={label}
				hintText={hint}
				value={this.state[field]}
				required={required}
				name={field}
				autoTestId={`settings-default-${field}`}
			/>
		)
	}

	renderAddressByType = (type: 1 | 2) => {
		const { t } = this.props
		const isDisabled = type === 1 && !this.props.currentUserIsAdmin
		const addressIndex: number = this.state.addresses.findIndex((a: OrganizationAddress) => a.type === type)

		const address: OrganizationAddress =
			addressIndex >= 0
				? this.state.addresses[addressIndex]
				: Object.freeze({
						...emptyOrganizationAddress(),
						type: MAIN_ADDRESS_TYPE,
				  })

		const label: string =
			address.type === 2 ? t('contactEdit.edit.deliveryAddress') : t('contactEdit.edit.billingAddress')

		return (
			<div key={'address' + addressIndex}>
				<h3 className={styles.h3}>{label}</h3>
				<div className={styles.halfRow}>
					<TextField
						fullWidth
						labelText={t('contactEdit.tile.hint.street')}
						onChange={this.handleAddressTextFieldChange('street', addressIndex)}
						value={this.props.validationValue(`street${type}`, address.street)}
						clientError={this.props.validationMessage(`street${type}`)}
						autoTestId="settings-default-street"
						disabled={isDisabled}
						name="street"
					/>
				</div>
				<div className={styles.halfRow}>
					<div className={styles.columnOneThird}>
						<TextField
							fullWidth
							labelText={t('contactEdit.tile.hint.zipCode')}
							onChange={this.handleAddressTextFieldChange('zip', addressIndex)}
							value={this.props.validationValue(`zip${type}`, address.zip)}
							clientError={this.props.validationMessage(`zip${type}`)}
							autoTestId="settings-default-zip"
							disabled={isDisabled}
							name="zip"
						/>
					</div>
					<div className={styles.columnTwoThirds}>
						<TextField
							fullWidth
							labelText={t('contactEdit.tile.hint.city')}
							onChange={this.handleAddressTextFieldChange('city', addressIndex)}
							value={this.props.validationValue(`city${type}`, address.city)}
							clientError={this.props.validationMessage(`city${type}`)}
							autoTestId="settings-default-city"
							disabled={isDisabled}
							name="city"
						/>
					</div>
				</div>
				<div className={styles.halfRow}>
					<CountrySelector
						label={this.props.t('contactEdit.tile.hint.country')}
						onChange={this.handleAddressCountryChange(addressIndex)}
						value={this.props.validationValue(`country${type}`, address.country)}
						error={this.props.validationMessage(`country${type}`)}
						autoTestId="settings-default-country"
						disabled={isDisabled}
					/>
				</div>
			</div>
		)
	}

	handleLogoUpdate = () => {
		this.dropzone &&
			this.dropzone
				.getWrappedInstance()
				.getWrappedInstance()
				.open()
	}

	bindDropZone = (element: any) => {
		this.dropzone = element
	}

	handleDropFiles = (files: Array<File>) => {
		return this.props.uploadOrganizationLogo(files[0])
	}

	renderLogoDropZone = (image: any) => {
		return (
			<div className={image ? styles.dropZoneHidden : styles.logoDropZone}>
				<Dropzone
					multiple
					accept={'image/jpeg,image/jpg,image/png,image/gif,image/tiff'}
					onDrop={this.handleDropFiles}
					ref={this.bindDropZone}
					minHeight={130}
					hintText={
						this.props.logoBinary
							? this.props.t('settings.basics.uploadLogo')
							: this.props.t('settings.basics.uploadNewLogo')
					}
				/>
			</div>
		)
	}

	handleLogoRemove = () => {
		if (this.props.logoBinary) {
			this.props.removeOrganizationLogo()
		}
	}

	renderLogoEdit = () => {
		let image = null

		if (this.props.logoBinary != null && this.props.logoBinary != 'data:image;base64,') {
			image = (
				<div className={styles.logoContainer}>
					<img className={styles.logoImage} src={this.props.logoBinary} />
				</div>
			)
		}

		return (
			<div className={styles.logos}>
				{image && <div className={styles.logo}>{image}</div>}
				{this.renderLogoDropZone(image)}
				{image && (
					<div className={styles.logoEditZone}>
						<div className={styles.removeButton} onClick={this.handleLogoUpdate}>
							<ActionAutorenew size={20} style={style.deleteIcon} color={colors.black} hoverColor={colors.blue} />
							<span>{this.props.t('settings.basics.updateLogo')}</span>
						</div>
						<div className={styles.removeButton} onClick={this.handleLogoRemove}>
							<ActionDelete size={20} style={style.deleteIcon} color={colors.black} hoverColor={colors.blue} />
							<span>{this.props.t('settings.basics.deleteLogo')}</span>
						</div>
					</div>
				)}
			</div>
		)
	}

	getClientValidationErrors() {
		const errors = {}
		const { t, currentUserIsInternal } = this.props
		const { phoneNumber, email, court, fileNumber } = this.state

		if (phoneNumber && phoneNumber.length && !Validator.phone(phoneNumber)) errors.phone = t('clientError.notAPhone')
		if (email && email.length && !Validator.email(email)) errors.email = t('clientError.notAnEmail')

		this.state.addresses.forEach((address: OrganizationAddress) => {
			if (!this.state.sameAddresses || (this.state.sameAddresses && MAIN_ADDRESS_TYPE === address.type)) {
				if (!address.street || !address.street.length)
					errors[`street${address.type || 0}`] = t('application.validation.mandatory')

				if (!address.city || !address.city.length)
					errors[`city${address.type || 0}`] = t('application.validation.mandatory')

				if (!address.country || !address.country.length)
					errors[`country${address.type || 0}`] = t('application.validation.mandatory')

				if (!address.zip || !address.zip.length)
					if (!(address.street && address.city && address.country && address.zip)) {
						errors[`zip${address.type || 0}`] = t('application.validation.mandatory')
					}
			}
		})

		const selectedType =
			this.props.companyTypes &&
			this.props.companyTypes.find((type: CompanyType) => this.state.companyTypeId === type.id)

		if (currentUserIsInternal && selectedType && JURIDICAL_PERSON === selectedType.legalType) {
			if (!court || !court.length) {
				errors.court = t('application.validation.mandatory')
			}
			if (currentUserIsInternal && (!fileNumber || !fileNumber.length)) {
				errors.fileNumber = t('application.validation.mandatory')
			}
		}

		return errors
	}

	render() {
		const { t, currentUserIsInternal, currentUserIsAdmin } = this.props
		this.props.validateForm(this.getClientValidationErrors())

		return (
			<div>
				<h3 className={styles.firstH3}>{t('settings.basics.logoPartTitle')}</h3>
				{this.renderLogoEdit()}

				<h3 className={styles.h3}>{t('settings.basics.companyPartTitle')}</h3>
				<div className={styles.row}>
					<div className={styles.column}>
						{this.renderTextInput('name', t('settings.basics.name'), !currentUserIsInternal, undefined, true)}
					</div>
					<div className={styles.columnOneFourth}>
						{this.renderTextInput('regNo', t('settings.basics.regNo'), true)}
					</div>
					<div className={styles.columnOneFourth}>
						{this.renderTextInput('taxId', t('settings.basics.taxId'), !currentUserIsInternal)}
					</div>
				</div>

				{this.state.sameAddresses
					? this.renderAddressByType(MAIN_ADDRESS_TYPE)
					: ADDRESS_TYPES.map(this.renderAddressByType)}
				<div className={styles.checkbox}>
					<Checkbox
						label={t('settings.basics.sameAddresses')}
						checked={this.state.sameAddresses}
						onCheck={this.toggleAddresses}
					/>
				</div>

				<div className={styles.halfRow}>
					<EmailInput
						fullWidth
						onChange={this.handleTextFieldChange('email')}
						labelText={t('settings.basics.email')}
						value={this.props.validationValue('email', this.state.email)}
						clientError={this.props.validationMessage('email')}
						autoTestId="settings-basics-email"
					/>
				</div>
				<div className={styles.halfRow}>
					<PhoneInput
						fullWidth
						onChange={this.handleTextFieldChange('phoneNumber')}
						labelText={t('settings.basics.phoneNumber')}
						clientError={this.props.validationMessage('phoneNumber')}
						value={this.props.validationValue('phoneNumber', this.state.phoneNumber)}
						autoTestId="settings-basics-phone"
					/>
				</div>

				<div className={styles.halfRow}>{this.renderTextInput('homePage', t('settings.basics.homePage'), false)}</div>

				<h3 className={styles.h3}>{t('settings.basics.classificationPartTitle')}</h3>
				<div className={styles.halfRow}>
					<TerritorialDivisionSelector
						value={this.state.territorialDivisionId}
						onChange={this.handleTerritorialDivisionChange}
						disabled={!currentUserIsInternal}
					/>
				</div>
				<div className={styles.halfRow}>
					<NaceCodeSelector
						main
						disabled={!currentUserIsInternal}
						value={this.state.mainNaceCode}
						onChange={this.handleMainNaceCodeChange}
					/>
				</div>
				<div className={styles.halfRow}>
					<NaceCodeSelector
						disabled={!currentUserIsInternal}
						value={this.state.sideNaceCode}
						onChange={this.handleSideNaceCodeChange}
					/>
				</div>
				<div className={styles.halfRow}>
					<CompanyTypeSelector
						labelText={t('settings.basics.companyType')}
						value={this.state.companyTypeId}
						onChange={this.handleCompanyTypeChange}
						disabled={!currentUserIsInternal}
					/>
				</div>
				<div className={styles.halfRow}>
					<TextField
						fullWidth
						disabled={!currentUserIsInternal}
						onChange={this.handleTextFieldChange('court')}
						labelText={t('settings.basics.court')}
						value={this.props.validationValue('court', this.state.court)}
						clientError={this.props.validationMessage('court')}
						name="court"
						autoTestId="settings-default-court"
					/>
				</div>
				<div className={styles.halfRow}>
					<TextField
						fullWidth
						disabled={!currentUserIsInternal}
						onChange={this.handleTextFieldChange('fileNumber')}
						labelText={t('settings.basics.fileNumber')}
						value={this.props.validationValue('fileNumber', this.state.fileNumber)}
						clientError={this.props.validationMessage('fileNumber')}
						name="fileNumber"
						autoTestId="settings-default-fileNumber"
					/>
				</div>
				{(currentUserIsInternal || currentUserIsAdmin) && (
					<div>
						<h3 className={styles.firstH3}>{t('settings.basics.triviServices')}</h3>
						<div className={styles.halfRow}>
							<TriviServices
								organizationId={this.props.organizationId}
								removeOrganization={this.props.removeOrganization}
								key={this.props.organizationId}
							/>
						</div>
					</div>
				)}
				<SaveSettings
					visible={this.altered}
					saving={!!this.props.loading}
					onSave={this.props.validationSubmit(this.saveSettings, this.props.t('forms.formIsNotValid'))}
					onRestore={this.restoreSavedState}
				/>
			</div>
		)
	}
}

const style = {
	deleteIcon: {
		margin: '0 4px',
		verticalAlign: 'middle',
		position: 'relative',
		top: -2,
	},
}

export default withTranslate(
	validate(['FINISH_UPDATING_ORGANIZATION_SETTINGS', 'FINISH_CHANGING_ORGANIZATION_ADDRESS'])(DefaultSettings),
)
