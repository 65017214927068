/* @flow */

import type { Currency, Dispatch, State, FormFieldErrorContext } from 'types'
import {
	loadAccountingDocumentVatRecapInfo,
	updateAccountingDocumentBookingDate,
	updateAccountingDocumentCurrency,
	updateAccountingDocumentTaxReturnDate,
	updateAccountingDocumentLanguage,
	// countryVariantUpdate,
} from 'modules/accounting-document/actions'
import {
	getAccountingDocument,
	getAccountingDocumentBookingDate,
	getAccountingDocumentCurrency,
	getAccountingDocumentExchRate,
	getAccountingDocumentExchRateDefault,
	getAccountingDocumentTaxReturnDate,
	getAccountingDocumentLanguage,
	isAccountingDocumentVatFree,
	getAccountingDocumentNotesCount,
	getAccountingDocumentExtractingNotesCount,
	getAccountingDocumentVatCountryType,
	getAccountingDocumentType,
	getAccountingDocumentTaxReturnMinDate,
	getIsAccDocNoteDisabled,
	getAccountingDocumentVatExchRate,
	getAccountingDocumentSadExchRate,
} from 'modules/accounting-document/selectors'
import { organizationSettingsOpenSelector } from 'modules/settings/selectors'
import { getCurrentOrganizationDomesticCurrency } from 'modules/organization/selectors'
// import { isCashRegisterEetActive } from 'modules/cash-register/selectors'
import { currentUserIsInternal } from 'modules/user/selectors'
import { AccountingDocumentTypeNumber_isCashRegister } from 'types/operations'
import UpperSettings from 'modules/accounting-document/components/invoice-elements/upper-settings'
import type { View } from '../../types'
import { connect } from 'react-redux'
// import { AccountingDocumentType_Number } from 'types/convertor'
import type { OptionType } from 'react-select/src/types'

type OwnProps = {|
	accountingDocumentId: string,
	direction: 'issued' | 'received',
	readonly?: boolean,
	hideBookingDate?: boolean,
	hideVatCountry?: boolean,
	showOrganizationInfo?: boolean,
	view: View,
	hideLanguage?: boolean,
	hideExchRate?: boolean,
	hideTaxReturnDate?: boolean,
	isNoteDisabled?: boolean,
	toggleShowNote?: () => void,
	formFieldErrorContext?: FormFieldErrorContext,
|}

function mapStateToProps(state: State, ownProps: OwnProps) {
	const accountingDocument = getAccountingDocument(state, ownProps.accountingDocumentId)
	const isAccDocVatFree = isAccountingDocumentVatFree(state, ownProps.accountingDocumentId)
	const isInternal = currentUserIsInternal(state)

	const hideBookingDate = !isInternal || ownProps.hideBookingDate
	const hideTaxReturnDate = !isInternal || ownProps.direction === 'issued' || isAccDocVatFree
	const isReceived = ownProps.direction === 'received'
	// const isIssued = ownProps.direction === 'issued'

	const exchRate =
		getAccountingDocumentExchRate(state, ownProps.accountingDocumentId) ||
		getAccountingDocumentExchRateDefault(state, ownProps.accountingDocumentId)
	const exchRateDefault = getAccountingDocumentExchRateDefault(state, ownProps.accountingDocumentId)
	const vatExchRate = getAccountingDocumentVatExchRate(state, ownProps.accountingDocumentId)
	const sadExchRate = getAccountingDocumentSadExchRate(state, ownProps.accountingDocumentId)
	const domesticCurrencyId = getCurrentOrganizationDomesticCurrency(state)
	const currencyId = getAccountingDocumentCurrency(state, ownProps.accountingDocumentId)
	const hideExchRate = !!(exchRate == null || (domesticCurrencyId && currencyId && domesticCurrencyId === currencyId))

	const accountingDocumentType = getAccountingDocumentType(state, ownProps.accountingDocumentId)
	const isCashRegister = AccountingDocumentTypeNumber_isCashRegister(accountingDocumentType)

	const accDocState = (accountingDocument && accountingDocument.state) || ''
	const isNoteDisabled = getIsAccDocNoteDisabled(state, ownProps.accountingDocumentId)

	const languageReadonly = !(
		!ownProps.readonly ||
		(accountingDocument && accountingDocument.processingState && accountingDocument.processingState <= 2)
	)

	// const isCashTransfer = AccountingDocumentType_Number('cash_transfer') === accountingDocumentType

	// let showEet = isIssued
	// if (showEet) {
	// 	if (isCashRegister) {
	// 		showEet =
	// 			!isCashTransfer && isCashRegisterEetActive(state, accountingDocument && accountingDocument.cashRegisterId)
	// 	} else {
	// 		showEet = isOrganizationEetActiveSelector(state)
	// 	}
	// }

	return {
		taxReturnMinDate: getAccountingDocumentTaxReturnMinDate(state, ownProps.accountingDocumentId),
		hideBookingDate,
		hideTaxReturnDate,
		hideExchRate,
		isInternal,
		noteNotificationCount:
			getAccountingDocumentNotesCount(state, ownProps.accountingDocumentId) +
			(isInternal ? getAccountingDocumentExtractingNotesCount(state, ownProps.accountingDocumentId) : 0),
		bookingDate: getAccountingDocumentBookingDate(state, ownProps.accountingDocumentId),
		taxReturnDate: getAccountingDocumentTaxReturnDate(state, ownProps.accountingDocumentId),
		currencyId: getAccountingDocumentCurrency(state, ownProps.accountingDocumentId),
		vatCountryType: getAccountingDocumentVatCountryType(state, ownProps.accountingDocumentId),
		// showEet,
		organizationSettingsOpen: organizationSettingsOpenSelector(state),
		hideCurrency: isCashRegister,
		language: getAccountingDocumentLanguage(state, ownProps.accountingDocumentId),
		languageReadonly: languageReadonly,
		hideLanguage: isReceived,
		accDocState: accDocState,
		isNoteDisabled,
		exchRate,
		exchRateDefault,
		vatExchRate,
		sadExchRate,
	}
}

function mapDispatchToProps(dispatch: Dispatch<*>, ownProps: OwnProps) {
	return {
		onBookingDateChange: (bookingDate: Date) => {
			dispatch(updateAccountingDocumentBookingDate(ownProps.accountingDocumentId, bookingDate)).then(() => {
				dispatch(loadAccountingDocumentVatRecapInfo(ownProps.accountingDocumentId))
			})
		},
		onTaxReturnDateChange: (taxReturnDate: Date) => {
			dispatch(updateAccountingDocumentTaxReturnDate(ownProps.accountingDocumentId, taxReturnDate))
		},
		onCurrencyChange: (currency: ?Currency) => {
			dispatch(updateAccountingDocumentCurrency(ownProps.accountingDocumentId, (currency && currency.id) || null)).then(
				() => {
					dispatch(loadAccountingDocumentVatRecapInfo(ownProps.accountingDocumentId))
				},
			)
		},
		// changeEET: (countryVariantRequest: CountryVariantRequest) => {
		// 	dispatch(countryVariantUpdate(ownProps.accountingDocumentId, countryVariantRequest))
		// },
		onLanguageChange: (language: OptionType) => {
			dispatch(updateAccountingDocumentLanguage(ownProps.accountingDocumentId, language.value)).then(() => {
				dispatch(loadAccountingDocumentVatRecapInfo(ownProps.accountingDocumentId))
			})
		},
	}
}

function mergeProps(stateProps, dispatchProps, ownProps: OwnProps) {//eslint-disable-line
	return {
		...stateProps,
		...dispatchProps,
		readonly: ownProps.readonly,
		hideVatCountry: ownProps.hideVatCountry,
		accountingDocumentId: ownProps.accountingDocumentId,
		view: ownProps.view,
		showOrganizationInfo: ownProps.showOrganizationInfo,
		hideTaxReturnDate: stateProps.hideTaxReturnDate || ownProps.hideTaxReturnDate,
		hideExchRate: stateProps.hideExchRate || ownProps.hideExchRate,
		hideLanguage: stateProps.hideLanguage || ownProps.hideLanguage,
		toggleShowNote: ownProps.toggleShowNote,
		formFieldErrorContext: ownProps.formFieldErrorContext,
		isNoteDisabled: stateProps.isNoteDisabled,
	}
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(UpperSettings)
