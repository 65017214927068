/* @flow */

import { loadAresContacts } from 'modules/user/actions'
import { getAresContacts, isAresContactsLoading } from 'modules/user/selectors'
import { connect } from 'react-redux'
import type { AddressBookAction, AresContact, Contact, Dispatch, State } from 'types'
import { AccountingDocumentContact_Contact, AresContact_AccountingDocumentContact } from 'types/convertor'
import ContactAres from '../../../components/invoice-elements/contact/contact-ares'

type OwnProps = {|
	search?: ?string,
	onSelect: (contact: Contact) => void,
|}

type StateProps = {|
	contacts: ?Array<AresContact>,
	loading: boolean,
|}

const mapStateToProps = (state: State): StateProps => {
	return {
		contacts: getAresContacts(state),
		loading: isAresContactsLoading(state),
	}
}

type DispatchProps = {
	onSearch: (companyRegNo: string) => void,
	onSelect: (contact: AresContact) => void,
}

const mapDispatchToProps = (dispatch: Dispatch<AddressBookAction>, ownProps: OwnProps): DispatchProps => {
	return {
		onSearch: (companyRegNo: string) => {
			dispatch(loadAresContacts(companyRegNo))
		},
		onSelect: (contact: AresContact) => {
			ownProps.onSelect(AccountingDocumentContact_Contact(AresContact_AccountingDocumentContact(contact)))
		},
	}
}

const mergeProps = (stateProps: StateProps, dispatchProps: DispatchProps, ownProps: OwnProps) => {
	return {
		search: ownProps.search,
		onSelect: dispatchProps.onSelect,
		onSearch: dispatchProps.onSearch,
		contacts: stateProps.contacts,
		loading: stateProps.loading,
	}
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(ContactAres)
