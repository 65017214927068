/* @flow */
/** @jsx jsx */

import { Component } from 'react'
import memoize from 'memoize-one'
import { jsx } from '@emotion/core'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import TriviContext from 'lib/context'
import { colors } from 'variables'

type Context = {
	triviContext: TriviContext,
}

type Props<T> = {|
	label?: string,
	routeParams: T,
	disabled?: boolean,
	title?: string,
	style?: Object,
	hoverStyle?: Object,
	children?: any,
	href?: string,
	onClick?: (SyntheticMouseEvent<HTMLElement>) => any,
	routeFn?: (...T) => string,
|}

class TriviLink<T: Array<*>> extends Component<Props<T>> {
	context: Context

	static defaultProps = {
		routeParams: [],
	}

	componentDidMount() {
		this.context.triviContext.subscribe(this.handleContextChange)
	}

	componentWillUnmount() {
		this.context.triviContext.unsubscribe(this.handleContextChange)
	}

	handleContextChange = (property: string) => {
		if (property === 'organizationId') {
			this.forceUpdate()
		}
	}

	onClick = (event: SyntheticMouseEvent<HTMLElement>) => {
		!this.props.disabled && this.props.onClick && this.props.onClick(event)
	}

	getStyles = memoize((style: ?Object, hoverStyle: ?Object, to: boolean, disabled: ?boolean) => {
		return {
			root: {
				fontSize: 'inherit',
				opacity: 'inherit',
				cursor: disabled ? 'default' : 'pointer',
				pointerEvents: disabled ? 'none' : 'all',
				color: 'inherit',
				textDecoration: 'none',
				display: to ? 'inline' : 'block',
				textOverflow: 'ellipsis',
				overflow: 'hidden',
				...style,
				'&:hover': {
					color: colors.blue,
					textDecoration: 'underline',
					...hoverStyle,
				},
			},
		}
	})

	render() {
		const to = !this.props.disabled && this.props.routeFn && this.props.routeFn(...this.props.routeParams)
		const styles = this.getStyles(this.props.style, this.props.hoverStyle, !!to, this.props.disabled)

		if (to) {
			return (
				<Link
					to={to}
					label={this.props.label}
					title={this.props.title}
					disabled={this.props.disabled}
					onClick={this.onClick}
					css={styles.root}
				>
					{this.props.children}
				</Link>
			)
		} else {
			return (
				<a
					href={this.props.href}
					label={this.props.label}
					title={this.props.title}
					disabled={this.props.disabled}
					onClick={this.onClick}
					css={styles.root}
				>
					{this.props.children}
				</a>
			)
		}
	}
}

TriviLink.contextTypes = {
	triviContext: PropTypes.object,
}

export default TriviLink
