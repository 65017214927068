/* @flow */
/* eslint-disable react/no-multi-comp */

import React from 'react'
import moize from 'moize'
import type { AccountingDocument, AccountingDocumentLineItem } from 'types'
import { type WithTranslateProps } from 'wrappers'
import TriviLink from 'modules/common/components/trivi-link'
import { Column } from 'modules/data-grid-next/components'
import type { Direction } from 'modules/data-grid-next/types'
import AccountingDocumentSentIcon from '../accounting-document-sent-icon'
import { DATA_GRID_CACHE_SIZE } from 'trivi-constants'
import { colors } from 'variables'

export type Props = {|
	width?: number | string,
	defaultSortDirection?: Direction,
	onClick?: (accountingDocument: AccountingDocument) => void,
	...WithTranslateProps,
|}

const styles = {
	root: {
		display: 'flex',
		overflow: 'hidden',
	},
	company: {
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
		minHeight: 21,
	},
	lineItem: {
		color: colors.blackFaded60,
		fontSize: 12,
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
		minHeight: 21,
	},
	info: {
		flex: '1 1 auto',
		overflow: 'hidden',
	},
	sent: {
		flex: '0 1 30px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
}

export default function getCompanyColumn(props: Props) {
	function columnRenderer(value: string, row: AccountingDocument) {
		function clickHandler() {
			props.onClick && props.onClick(row)
		}

		const contactName =
			(row.contact && row.contact.companyName) ||
			(row.contact && (row.contact.firstname || '') + ' ' + (row.contact.lastname || '')) ||
			''

		const firstLineItem: ?AccountingDocumentLineItem =
			row.lineItems && row.lineItems.length > 0 ? row.lineItems[0] : null

		return (
			<div style={styles.root}>
				<div style={styles.info}>
					<TriviLink onClick={clickHandler} disabled={!props.onClick}>
						<div style={styles.company}>{contactName}</div>
					</TriviLink>
					<div style={styles.lineItem}>{firstLineItem && firstLineItem.description}</div>
				</div>
				<div style={styles.sent}>
					<AccountingDocumentSentIcon accountingDocument={row} />
				</div>
			</div>
		)
	}

	const columnRendererCashed = moize(columnRenderer, { maxSize: DATA_GRID_CACHE_SIZE })

	return (
		<Column
			title={props.t('accountingDocument.columns.company')}
			width={props.width}
			columnId="contact.companyName"
			key="contact.companyName"
			render={columnRendererCashed}
			sortable
			defaultSortDirection={props.defaultSortDirection || undefined}
		/>
	)
}
