import React from 'react'
import pure from 'recompose/pure'
import SvgIcon from '../../SvgIcon'

let FileDownload = props => (
	<SvgIcon {...props}>
		<path d="M5 20h14v-2H5m14-9h-4V3H9v6H5l7 7l7-7z" />
	</SvgIcon>
)
FileDownload = pure(FileDownload)

export default FileDownload
