//@flow

import { combineReducers } from 'redux'
import balanceSummary, {
	initialState as balanceSummaryInitialState,
	type State as BalanceSummaryState,
} from './balance-summary'
import billingSummary, {
	initialState as billingSummaryInitialState,
	type State as BillingSummaryState,
} from './billing-summary'
import currency, { initialState as currencyInitialState, type State as CurrencyState } from './currency'
import invitation, { initialState as invitationInitialState, type State as InvitationState } from './invitation'
import report, { initialState as reportInitialState, type State as ReportState } from './report'
import currentTurnover, {
	initialState as currentTurnoverInitialState,
	type State as CurrentTurnoverState,
} from './current-turnover'
import cards, { initialState as cardsInitialState, type State as CardsState } from './cards'

export default combineReducers({
	currency: currency,
	report,
	billingSummary,
	balanceSummary,
	invitation,
	currentTurnover,
	cards,
})

export type State = {
	currency: CurrencyState,
	report: ReportState,
	billingSummary: BillingSummaryState,
	balanceSummary: BalanceSummaryState,
	invitation: InvitationState,
	currentTurnover: CurrentTurnoverState,
	cards: CardsState,
}

export const initialState = {
	report: reportInitialState,
	currency: currencyInitialState,
	billingSummary: billingSummaryInitialState,
	balanceSummary: balanceSummaryInitialState,
	invitation: invitationInitialState,
	currentTurnover: currentTurnoverInitialState,
	cards: cardsInitialState,
}
