/* @flow */
/* eslint-disable react/no-multi-comp */

import React from 'react'
import moize from 'moize'
import { type WithTranslateProps } from 'wrappers'
import { formatToDateString } from 'utils/formatters'
import { Column } from 'modules/data-grid-next/components'
import type { Direction } from 'modules/data-grid-next/types'
import { DATA_GRID_CACHE_SIZE } from 'trivi-constants'

export type Props = {|
	width?: number | string,
	defaultSortDirection?: Direction,
	singleLine?: boolean,
	...WithTranslateProps,
|}

export default function getIssueDateColumn(props: Props) {
	function columnRenderer(value: string) {
		return (
			<div>
				{formatToDateString(value)}
				{!props.singleLine && <br />}
				{!props.singleLine && <br />}
			</div>
		)
	}

	const columnRendererCashed = moize(columnRenderer, { maxSize: DATA_GRID_CACHE_SIZE })

	return (
		<Column
			title={props.t('accountingDocument.columns.issueDate')}
			width={props.width}
			columnId="issueDate"
			key="issueDate"
			render={columnRendererCashed}
			sortable
			defaultSortDirection={props.defaultSortDirection || undefined}
		/>
	)
}
