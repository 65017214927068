/* @flow */
/** @jsx jsx */

import { Component } from 'react'
import { jsx } from '@emotion/core'
import { withTranslate, type WithTranslateProps, withNotify, type WithNotifyProps } from 'wrappers'
import AddEntity from 'components/add-entity'
import TriviLink from 'modules/common/components/trivi-link'
import { cashRegisterCreateRoute } from 'modules/cash-register/routing/routes'
import type { CashRegisterAction, Dispatch, State } from 'types'
import { push } from 'react-router-redux'
import { connect } from 'react-redux'
import { userHasAccess } from 'permissions'
import { getCashRegistersCount } from '../selectors'

type Props = {|
	...WithNotifyProps,
	...WithTranslateProps,
	cashRegistersCount: number,
	canCreateCashRegister: boolean,
	redirectToCashRegisterCreateRoute: () => void,
|}

const mapStateToProps = (state: State) => {
	return {
		cashRegistersCount: getCashRegistersCount(state),
		canCreateCashRegister: userHasAccess(state, 'createCashRegisters'),
	}
}

const mapDispatchToProps = (dispatch: Dispatch<CashRegisterAction>) => {
	return {
		redirectToCashRegisterCreateRoute: () => {
			dispatch(push(cashRegisterCreateRoute()))
		},
	}
}

class CashRegisterItemNew extends Component<Props> {
	clickHandler = () => {
		if (this.props.cashRegistersCount >= 20) {
			this.props.notify('cashRegister.create.maxCashRegistersWarning', 'info')
			return
		}
		this.props.redirectToCashRegisterCreateRoute && this.props.redirectToCashRegisterCreateRoute()
	}

	render() {
		const disabled = !this.props.canCreateCashRegister

		return (
			<TriviLink disabled={disabled} onClick={this.clickHandler} style={style.link}>
				<AddEntity
					disabled={disabled}
					style={style.add}
					labelText={
						disabled
							? this.props.t('cashRegister.standardUserCantAddNew')
							: this.props.t('cashRegister.create.buttonLabel')
					}
				/>
			</TriviLink>
		)
	}
}

const style = {
	link: { display: 'block', textDecoration: 'none' },
	add: {
		height: 110,
	},
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslate(withNotify(CashRegisterItemNew)))
