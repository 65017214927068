// @flow
import { mergeWith, isArray } from 'lodash-es'
import type { SequencesData, AccountingDocumentDirection, AccountingDocumentType, Sequence } from 'types'
import { Type_AccountingDocumentDirectionName, Number_AccountingDocumentType } from 'types/convertor'

export function _updateSequenceOnSequencesData(
	sequencesData: SequencesData,
	direction: AccountingDocumentDirection,
	type: AccountingDocumentType,
	cashRegisterId: string,
	sequence: Sequence,
	action: 'remove' | 'update' | 'create',
): SequencesData {
	const sequences =
		sequencesData[direction][type] && sequencesData[direction][type][cashRegisterId]
			? sequencesData[direction][type][cashRegisterId]
			: []
	const updatedSequences =
		action === 'remove'
			? sequences.filter((item: Sequence) => item.id !== sequence.id)
			: action === 'update'
			? sequences.map((item: Sequence) => (item.id === sequence.id ? sequence : item))
			: [...sequences, sequence]
	return {
		...sequencesData,
		[direction]: {
			...sequencesData[direction],
			[type]: {
				...(sequencesData[direction][type] ? sequencesData[direction][type] : {}),
				[cashRegisterId]: [...updatedSequences],
			},
		},
	}
}

export function sortSequences(sequences: Array<Sequence>): SequencesData {
	const customizer = (objValue: any, srcValue: any) => {
		if (isArray(objValue)) {
			// return objValue.concat(srcValue);
			return [...objValue, ...srcValue]
		}
	}
	let data = {
		issued: {},
		received: {},
		unknown: {},
	}
	sequences.forEach((sequence: Sequence) => {
		const { accountingDocumentType, accountingDocumentDirection, cashRegisterId, isDisabled } = sequence
		if (isDisabled) return
		const accountingDocumentTypeName: AccountingDocumentType =
			Number_AccountingDocumentType(accountingDocumentType) || 'unknown'
		const accountingDocumentDirectionName: AccountingDocumentDirection =
			Type_AccountingDocumentDirectionName(accountingDocumentDirection) || 'unknown'
		data = mergeWith(
			data,
			{
				[accountingDocumentDirectionName]: {
					[accountingDocumentTypeName]: {
						[cashRegisterId || 'unknown']: [sequence],
					},
				},
			},
			customizer,
		)
	})
	return data
}
