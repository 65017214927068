/* @flow */

import type {
	AccountingDocumentLineItem,
	AccountingDocumentReduxAction,
	DataGridNextAction,
	Dispatch,
	OrganizationAction,
} from 'types'
import { addFavoriteLineItem, removeFavoriteLineItem } from 'modules/organization/actions/favorites'

import LineItemDescription from 'modules/accounting-document/components/invoice-elements/line-item-description'
import type { Action as ReportingAction } from 'modules/settings/actions/reporting-action-types'
import { accountingDocumentLineItemsSearch as accountingDocumentLineItemsSearchApi } from 'modules/common/models/api-model'
import { connect } from 'react-redux'

export type OwnProps = {|
	value: ?string,
	error?: Node,
	tabIndex?: number,
	disabled?: boolean,
	currency: ?string,
	direction: ?number,
	accountingDocumentId: ?string,
	isAccDocVatFree: boolean,
	favoriteLineItemIds: Array<string>,
	defaultLineItems?: Array<AccountingDocumentLineItem> | null,
	onDescriptionChange: (value: string) => void,
	onLineItemChange: (item: AccountingDocumentLineItem) => void,
	newLineTabIndex?: number,
	isInLastLineItem?: boolean,
	onLastLineEnter?: () => void,
|}

type DispatchProps = {|
	onLineItemFavorite: (id: ?string, isFavorite: boolean) => void,
	searchLineItems: (filter?: string, accountingDocumentId: string) => Promise<Array<AccountingDocumentLineItem>>,
|}

function mapDispatchToProps(
	dispatch: Dispatch<ReportingAction | OrganizationAction | DataGridNextAction | AccountingDocumentReduxAction>,
): DispatchProps {
	return {
		onLineItemFavorite: (id: ?string, isFavorite: boolean) => {
			if (!id) return
			if (isFavorite) {
				dispatch(addFavoriteLineItem(id))
			} else {
				dispatch(removeFavoriteLineItem(id))
			}
		},
		searchLineItems: async (filter?: string, accountingDocumentId: string) => {
			try {
				const result = await accountingDocumentLineItemsSearchApi.post({
					filters: {
						text: {
							text: filter || '',
						},
						accountingDocumentId,
					},
				})
				return result
			} catch (error) {
				return []
			}
		},
	}
}

export default connect(undefined, mapDispatchToProps)(LineItemDescription)
