/* @flow */

import React, { Component } from 'react'
import { emptyBankAccount } from 'types/empty'
import { validate, type FormValidationProps, withTranslate, type WithTranslateProps } from 'wrappers'
import { Chip, Button, Separator, TextField, SwiftInput, IbanInput, NumberInput } from 'components'
import SelectNext from 'components/select-next'
import Toggle from 'components/toggle'
import Tooltip from 'components/tooltip'
import BankCodeAutocomplete from 'modules/bank/containers/bank-code-autocomplete'
import styles from './bank-account-edit.css'
import type { BankAction, Currency, Bank, BankAccount, BankPaymentOrderType, BankStatementType } from 'types'
import { MAX_NUMBER_VALUE, FIO } from 'trivi-constants'
import FormBox from '../../../components/form-box'
import type { OptionType } from 'components/select-next'

export type ContainerProps = {|
	bankAccountId: ?string,
	save: (bankAccount: BankAccount) => Promise<BankAction>,
	handleClose: ?() => void,
	loadBanks: () => void,
	banks: ?Array<Bank>,
	bankAccount: ?BankAccount,
	loadStatementTypes: (bankId: string) => void,
	statementTypes: Array<BankStatementType>,
	loadPaymentOrderTypes: (bankId: string) => void,
	paymentOrderTypes: Array<BankPaymentOrderType>,
	currencies: ?Array<Currency>,
	loadCurrencies: () => void,
	create?: boolean,
	loadBankAccount: (id: string) => void,
	disableCheckAsMain: boolean,
	disabledCurrency?: boolean,
	currentUserIsInternal: boolean,
|}

type ComponentState = {
	bankAccount: BankAccount,
}

type ComponentProps = { ...ContainerProps, ...FormValidationProps, ...WithTranslateProps }

class BankAccountEdit extends Component<ComponentProps, ComponentState> {
	state: ComponentState = {
		bankAccount: {
			...emptyBankAccount(),
			...(this.props.bankAccount || {}),
		},
	}

	UNSAFE_componentWillMount() {
		!this.props.banks && this.props.loadBanks()
		!this.props.currencies && this.props.loadCurrencies()
		!this.props.bankAccount && this.props.bankAccountId && this.props.loadBankAccount(this.props.bankAccountId)
	}

	UNSAFE_componentWillReceiveProps(nextProps: ComponentProps) {
		const { bankAccount, statementTypes, paymentOrderTypes } = nextProps

		if (bankAccount && bankAccount !== this.props.bankAccount) {
			this.setState({ bankAccount })
		}

		if (
			bankAccount &&
			bankAccount.accountCode === FIO &&
			statementTypes.length > 0 &&
			paymentOrderTypes.length > 0 &&
			this.props.bankAccount &&
			!this.props.bankAccount.statementFormatId &&
			!this.props.bankAccount.paymentOrderFormatId
		) {
			const newBankAccount = { ...bankAccount }
			newBankAccount.statementFormatId = statementTypes[0].id
			newBankAccount.paymentOrderFormatId = paymentOrderTypes[0].id
			this.setState({ bankAccount: { ...newBankAccount } })
		}
	}

	handleAccountBankIdChange = (code: string) => {
		const { loadPaymentOrderTypes, loadStatementTypes } = this.props
		const found: ?Bank = this.props.banks && this.props.banks.find((b: Bank) => b.bankCode === code)
		const id: ?string = found && found.id ? `${found.id}` : null
		let params = {
			accountCode: code,
		}
		if (id) {
			loadPaymentOrderTypes(id)
			loadStatementTypes(id)
			params = { ...params, bankId: id }
		}

		this.setState({
			bankAccount: {
				...this.state.bankAccount,
				...params,
			},
		})
	}

	handleIsMainChange = () => {
		if (this.props.disableCheckAsMain) {
			return
		}
		this.handleBankAccountPropChange('isMain')(null, !this.state.bankAccount.isMain)
	}

	handleSelectFieldChange = (field: $Keys<BankAccount>) => (event: any, index: number, value: number) => {
		this.handleBankAccountPropChange(field)(null, value)
	}

	handleSelectFieldChange2 = (field: $Keys<BankAccount>) => (option: OptionType) => {
		this.handleBankAccountPropChange(field)(null, option.value)
	}

	handleBankAccountPropChange = (field: $Keys<BankAccount>) => (
		event: ?SyntheticInputEvent<HTMLInputElement>,
		value: any,
	) => {
		this.setState({
			bankAccount: {
				...this.state.bankAccount,
				[field]: value,
			},
		})
	}

	handleSubmit = async () => {
		const result: any = await this.props.save(this.state.bankAccount)
		!result.serverError && this.props.handleClose && this.props.handleClose()
	}

	validateForm = (): Object => {
		const { t } = this.props
		const { name, accountCode, accountNo, currency } = this.state.bankAccount
		const errors = {}

		if (!name) {
			errors.name = t('clientError.noFieldMicrovalidation', { field: t('fields.bankAccountName') })
		}

		if (!accountCode) {
			errors.accountCode = t('clientError.noFieldMicrovalidation', { field: t('fields.accountCode') })
		}

		if (!accountNo) {
			errors.accountNo = t('clientError.noFieldMicrovalidation', { field: t('fields.accountNo') })
		}

		if (!currency) {
			errors.currency = t('clientError.noFieldMicrovalidation', { field: t('fields.currency') })
		}

		return errors
	}

	renderActions = () => {
		return (
			<Button
				labelText={this.props.create ? this.props.t('bank.createButtonLabel') : this.props.t('bank.saveButtonLabel')}
				onClick={this.props.validationSubmit(this.handleSubmit)}
				autoTestId="bank-account-edit-create-save"
				type="submit"
			/>
		)
	}

	renderHeader = () => {
		const {
			t,
			bankAccount,
			currencies,
			create,
			disableCheckAsMain,
			disabledCurrency,
			currentUserIsInternal,
		} = this.props

		return (
			<div>
				{(this.state.bankAccount.isMain || (bankAccount && bankAccount.isMain)) && (
					<div className={styles.chip}>
						<Chip top>{t('bank.main')}</Chip>
					</div>
				)}
				<div className={styles.firstRow}>
					<div className={styles.name}>
						<TextField
							autoWidth
							autoFocus
							inline
							value={this.props.validationValue('name', this.state.bankAccount.name)}
							onChange={this.handleBankAccountPropChange('name')}
							hintText={t('bank.nameHint')}
							clientError={this.props.validationMessage('name')}
							autoTestId="bank-account-edit-name"
							maxLength={50}
							name="name"
						/>
					</div>
					<div className={styles.isMain}>
						<Tooltip label={t('bank.oneMainBankAccountWarning')} disabled={!disableCheckAsMain} inline>
							<Toggle
								autoWidth
								label={t('bank.main-account')}
								onToggle={this.handleIsMainChange}
								toggled={!!this.state.bankAccount.isMain}
								autoTestId="bank-account-is-main"
							/>
						</Tooltip>
					</div>
				</div>
				<div className={styles.row}>
					<div className={styles.halfColumn}>
						<TextField
							fullWidth
							disabled={!currentUserIsInternal || !create}
							value={this.props.validationValue('accountNo', this.state.bankAccount.accountNo)}
							onChange={this.handleBankAccountPropChange('accountNo')}
							clientError={this.props.validationMessage('accountNo')}
							autoTestId="bank-account-edit-number"
							labelText={t('bank.numberLabel')}
							name="number"
						/>
					</div>
					<div className={styles.code}>
						<BankCodeAutocomplete
							autoWidth
							disabled={!currentUserIsInternal || !create}
							labelText={t('bank.codeLabel')}
							value={this.props.validationValue('accountCode', this.state.bankAccount.accountCode || '')}
							clientError={this.props.validationMessage('accountCode')}
							onChange={this.handleAccountBankIdChange}
						/>
					</div>
					<div className={styles.currency}>
						<SelectNext
							value={this.props.validationValue('currency', this.state.bankAccount.currency)}
							onChange={this.handleSelectFieldChange2('currency')}
							label={t('bank.currencyLabel')}
							error={this.props.validationMessage('currency')}
							disabled={!currentUserIsInternal || disabledCurrency}
							autoTestId="bank-account-edit-currency"
							isSearchable
							options={(currencies && currencies.map((item: Currency) => ({ value: item.id, label: item.id }))) ?? []}
						/>
					</div>
				</div>
				<div className={styles.row}>
					<TextField
						fullWidth
						value={this.props.validationValue('displayNumber', this.state.bankAccount.displayNumber)}
						onChange={this.handleBankAccountPropChange('displayNumber')}
						disabled={!currentUserIsInternal}
						clientError={this.props.validationMessage('displayNumber')}
						labelText={t('bank.displayNumberLabel')}
						infoText={t('bank.displayNumberLabelInfo')}
						infoTextAlign="center-left"
						infoTextAlignArrow="center-right"
						autoTestId="bank-account-edit-display-number"
						name="display-number"
					/>
				</div>
			</div>
		)
	}

	render() {
		const { t, statementTypes, paymentOrderTypes, create, currentUserIsInternal } = this.props
		this.props.validateForm(this.validateForm())

		return (
			<FormBox
				actions={this.renderActions()}
				headline={create ? t('bank.createBankAccountHeadline') : t('bank.editBankAccountHeadline')}
				header={this.renderHeader()}
			>
				<div>
					<div className={styles.firstRow}>
						<div className={styles.halfColumn}>
							<SwiftInput
								fullWidth
								value={this.props.validationValue('accountSwift', this.state.bankAccount.accountSwift)}
								onChange={this.handleBankAccountPropChange('accountSwift')}
								labelText={t('bank.swiftLabel')}
								clientError={this.props.validationMessage('swift')}
								disabled={!currentUserIsInternal}
								autoTestId="bank-account-edit-swift"
							/>
						</div>
						<div className={styles.halfColumn}>
							<IbanInput
								fullWidth
								value={this.props.validationValue('accountIban', this.state.bankAccount.accountIban)}
								onChange={this.handleBankAccountPropChange('accountIban')}
								labelText={t('bank.ibanLabel')}
								clientError={this.props.validationMessage('iban')}
								disabled={!currentUserIsInternal}
								autoTestId="bank-account-edit-iban"
							/>
						</div>
					</div>
					<div className={styles.starting}>
						<Separator left={<span className={styles.separatorText}>{t('bank.initialState')}</span>} />
						<div className={styles.row}>
							<div className={styles.halfColumn}>
								<NumberInput
									fullWidth
									value={this.props.validationValue('initialAmount', this.state.bankAccount.initialAmount)}
									onChange={this.handleBankAccountPropChange('initialAmount')}
									labelText={t('bank.initialAmount')}
									clientError={this.props.validationMessage('initialAmount')}
									maxValue={MAX_NUMBER_VALUE}
									disabled={!currentUserIsInternal}
									name="initial-amount"
								/>
							</div>
						</div>
					</div>
					{paymentOrderTypes.length > 0 || statementTypes.length > 0 ? (
						<div>
							<Separator left={<span className={styles.separatorText}>{t('bank.formatSettings')}</span>} />
							<div className={styles.row}>
								{paymentOrderTypes.length > 0 && (
									<div className={styles.halfColumn}>
										<SelectNext
											value={this.props.validationValue(
												'paymentOrderFormatId',
												this.state.bankAccount.paymentOrderFormatId,
											)}
											onChange={this.handleSelectFieldChange2('paymentOrderFormatId')}
											label={t('bank.orderLabel')}
											error={this.props.validationMessage('paymentOrderFormatId')}
											disabled={!currentUserIsInternal}
											autoTestId="bank-account-edit-order-format"
											options={paymentOrderTypes.map((item: BankPaymentOrderType) => ({
												value: item.id,
												label: item.name,
											}))}
											portal
											openMenuPosition={'top'}
										/>
									</div>
								)}
								{statementTypes.length > 0 && (
									<div className={styles.halfColumn}>
										<SelectNext
											value={this.props.validationValue('statementFormatId', this.state.bankAccount.statementFormatId)}
											onChange={this.handleSelectFieldChange2('statementFormatId')}
											label={t('bank.statementLabel')}
											error={this.props.validationMessage('statementFormatId')}
											disabled={!currentUserIsInternal}
											autoTestId="bank-account-edit-statement-format-id"
											options={statementTypes.map((item: BankStatementType) => ({ value: item.id, label: item.name }))}
											portal
											openMenuPosition={'top'}
										/>
									</div>
								)}
							</div>
						</div>
					) : null}
				</div>
			</FormBox>
		)
	}
}

export default withTranslate(validate('FINISH_UPDATE_BANK_ACCOUNT')(BankAccountEdit))
