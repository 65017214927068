/* @flow */

import type { Dispatch, State, TemplateScheduler } from 'types'
import { loadContact } from 'modules/address-book'
import EmailSettings from '../../components/invoice-settings/email-settings'
import { connect } from 'react-redux'
import { getContact } from 'modules/address-book/selectors'

type OwnProps = {|
	contactId: ?string,
	disabled?: boolean,
	scheduler: ?TemplateScheduler,
	sendEmailAutomatically: boolean,
	onSendEmailAutomaticallyChange: (sendEmailAutomatically: boolean) => void,
	onChange: (emails: Array<string>, templateId: ?string) => void,
|}

function mapStateToProps(state: State, ownProps: OwnProps) {
	return {
		contact: ownProps.contactId ? getContact(state, ownProps.contactId) : null,
	}
}

function mapDispatchToProps(dispatch: Dispatch<*>) {
	return {
		loadContact: (contactId: string) => {
			return dispatch(loadContact(contactId))
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(EmailSettings)
