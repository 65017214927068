//@flow

export type ReduxStateUpdater<S> = (state: S) => S

export function updateReduxState<S: Object>(state: S, ...updaters: Array<ReduxStateUpdater<S>>): S {
	let updatedState: S = { ...state }

	updaters.forEach((updater: ReduxStateUpdater<S>) => {
		updatedState = updater(updatedState)
	})

	return updatedState
}
