/* @flow */

// $FlowFixMe - there is useEffect
import React, { useEffect } from 'react'
import { withRouter, matchPath } from 'react-router-dom'
import {
	withUserType,
	withUserProfile,
	withOrganizationId,
	withOrganizationInfo,
	withUserIsExtractor,
	withAccountantInfo,
	type WithUserTypeProps,
	type WithUserProfileProps,
	type WithOrganizationIdProps,
	type WithUserIsExtractorProps,
	type WithAccountantInfoProps,
	type WithOrganizationInfoProps,
} from 'wrappers'
import type { WithRouterProps } from 'types'
import { getAppSectionFromRoute } from 'utils/router'
import Tracking from 'utils/tracking'

type Props = {|
	...WithRouterProps,
	...WithUserTypeProps,
	...WithUserProfileProps,
	...WithOrganizationIdProps,
	...WithUserIsExtractorProps,
	...WithAccountantInfoProps,
	...WithOrganizationInfoProps,
|}

const ACC_DOC_MATCH_OPTIONS = {
	path: '/:organizationId/accounting-documents/:id/',
	exact: false,
	strict: true,
}

const CASH_REGISTER_MATCH_OPTIONS = {
	path: '/:organizationId/cash-register/:id',
	exact: false,
	strict: false,
}

const TASKS_MATCH_OPTIONS = {
	path: '/tasks/:id',
	exact: false,
	strict: false,
}

const BANK_ACCOUNT_MATCH_OPTIONS = {
	path: '/:organizationId/bank-account/:id',
	exact: false,
	strict: false,
}

function TrackingComponent(props: Props) {
	const pathName: ?string = (props.location && props.location.pathname) || null
	const cashRegisterMatch = matchPath(pathName, CASH_REGISTER_MATCH_OPTIONS)
	const bankAccountMatch = matchPath(pathName, BANK_ACCOUNT_MATCH_OPTIONS)
	const accDocMatch = matchPath(pathName, ACC_DOC_MATCH_OPTIONS)
	const tasksMatch = matchPath(pathName, TASKS_MATCH_OPTIONS)
	const appSection = getAppSectionFromRoute(pathName)

	const cashRegisterId = (cashRegisterMatch && cashRegisterMatch.params && cashRegisterMatch.params.id) || null
	const bankAccountId = (bankAccountMatch && bankAccountMatch.params && bankAccountMatch.params.id) || null
	const accDocId = (accDocMatch && accDocMatch.params && accDocMatch.params.id) || null
	const taskId = (tasksMatch && tasksMatch.params && tasksMatch.params.id) || null

	useEffect(() => {
		Tracking.init()
	}, [])

	useEffect(() => {
		Tracking.changeUserProfile(props.userProfile)
	}, [props.userProfile])

	useEffect(() => {
		if (!props.userType) return
		Tracking.changeUserType(props.userType)
		Tracking.changeUserIsExtractor(props.isExtractor)
	}, [props.userType, props.isExtractor])

	useEffect(() => {
		Tracking.changeOrganization(props.organizationId, props.organizationName)
	}, [props.organizationId, props.organizationName])

	useEffect(() => {
		Tracking.changeMainAccountant(props.mainAccountantId, props.mainAccountantName)
	}, [props.mainAccountantId, props.mainAccountantName])

	useEffect(() => {
		Tracking.changePayrollAccountant(props.wagesAccountantId, props.wagesAccountantName)
	}, [props.wagesAccountantId, props.wagesAccountantName])

	useEffect(() => {
		Tracking.changeAppSection(appSection)
	}, [appSection])

	useEffect(() => {
		Tracking.changeURL(window.location.href, appSection, {
			cashRegisterId,
			bankAccountId,
			accDocId,
			taskId,
		})
	}, [pathName, appSection, accDocId, cashRegisterId, bankAccountId, taskId])

	return <div />
}

export default withRouter(
	withUserProfile(
		withOrganizationId(withOrganizationInfo(withUserType(withUserIsExtractor(withAccountantInfo(TrackingComponent))))),
	),
)
