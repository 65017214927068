// @flow
/** @jsx jsx */

import { jsx } from '@emotion/core'
import TableHeaderCell from './table-header-cell'
import Tooltip from 'components/tooltip'
import TableCell from './table-cell'
import { colors } from 'variables'

type Props = {|
	tooltip?: string,
	children: Array<React$Element<typeof TableCell | typeof TableHeaderCell>>,
|}

function TableRow(props: Props) {
	return props.tooltip ? (
		<Tooltip wrapperStyle={styles.root} label={props.tooltip}>
			{props.children}
		</Tooltip>
	) : (
		<tr css={styles.root}>{props.children}</tr>
	)
}

const styles = {
	root: {
		display: 'table-row',
		color: colors.black,
		'&:hover': {
			'td #borderTop': {
				background: colors.gray400,
				left: -30,
				right: -30,
			},
			'td #borderBottom': {
				background: colors.gray400,
				left: -30,
				right: -30,
			},
		},
	},
}

export default TableRow
