//@flow
export type AppSection =
	| 'dashboard'
	| 'internalDashboard'
	| 'accountingDocuments'
	| 'accountingDocumentEdit'
	| 'accountingDocumentDetail'
	| 'cashRegister'
	| 'bank'
	| 'documents'
	| 'addressBook'
	| 'tasks'
	| 'settings'
	| 'features'
	| 'login'
	| 'register'
	// other section:
	| 'other'

/* eslint-disable prettier/prettier */
const SECTIONS_REGEXPS: {[key: AppSection]: RegExp} = {
	internalDashboard:        /^\/(internal-dashboard)/g,
	dashboard:                /^\/\d+(\/dashboard)/g,
	accountingDocuments:      /^\/\d+(\/accounting-documents)/g,
	accountingDocumentEdit:   /^\/\d+(\/accounting-documents)\/\d+\/edit/g,
	accountingDocumentDetail: /^\/\d+(\/accounting-documents)\/\d+\/(preview)|(detail)/g,
	cashRegister:             /^\/\d+(\/cash-register)/g,
	bank:                     /^\/\d+(\/bank)/g,
	documents:                /^\/\d+(\/documents)/g,
	addressBook:              /^\/\d+(\/address-book)/g,
	tasks:                    /^\/(tasks)/g,
	settings:                 /^\/\d+(\/settings)/g,
	features:                 /^\/(features)/g,
	login:                    /^\/(login)/g,
	register:                 /^\/(register)/g,
}

function _findInRegExps<T>(regexps: { [key: T]: RegExp }, pathName: ?string): ?T {
	let result: ?T = null
	Object.keys(regexps).forEach((key: T) => {
		const match: ?Array<string> = (pathName && pathName.match(regexps[key])) || null
		if (Array.isArray(match) && match.length) {
			result = key
		}
	})
	return result
}

export function getAppSectionFromRoute(pathName: ?string): AppSection {
	return _findInRegExps(SECTIONS_REGEXPS, pathName) || 'other'
}
