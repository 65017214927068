// @flow
import { formatToDateString } from 'utils/formatters/date-formatter'
import type {
	SortedAccountingDocuments,
	BillingReportGlobalResponseAccountingDocumentsDetail,
	SortedStatements,
	BillingReportGlobalResponseStatementsDetail,
	SortedDocumentItemType,
	BillingReportGlobalResponseAccountingDocumentsDetailCategory,
	SortedStatementItemType,
	BillingReportGlobalResponseStatementsDetailCategory,
} from 'types'
import { POSSIBLE_DOCUMENT_SOURCES } from '../utils/constants'
import { Number_AccountingDocumentDirection } from '../../../types/convertor'

export function formatPeriodToString(periodFrom: string, periodTo: string): string {
	const formatedFrom = formatToDateString(periodFrom)
	const formatedTo = formatToDateString(periodTo)
	return `${formatedFrom ? formatedFrom : ''} - ${formatedTo ? formatedTo : ''}`
}

export function sortDocumentsBySource(
	sortedDocuments: SortedAccountingDocuments,
	document: BillingReportGlobalResponseAccountingDocumentsDetail,
): SortedAccountingDocuments {
	const { source } = document
	const direction = Number_AccountingDocumentDirection(document.direction)
	if (
		direction !== null &&
		direction !== undefined &&
		source &&
		POSSIBLE_DOCUMENT_SOURCES.some((i: string) => i === source)
	) {
		const selectedSource = sortedDocuments[source]
		const { all, changed, sanctioned } = document
		return {
			...sortedDocuments,
			[source]: {
				...selectedSource,
				price: selectedSource.price + (document.price || 0),
				[direction]: {
					all: _updateSortDocumentItem(selectedSource[direction].all, all),
					changed: _updateSortDocumentItem(selectedSource[direction].changed, changed),
					sanctioned: _updateSortDocumentItem(selectedSource[direction].sanctioned, sanctioned),
				},
			},
		}
	} else {
		const oldErrorDocuments = sortedDocuments.error_documents ? sortedDocuments.error_documents : []
		return {
			...sortedDocuments,
			error_documents: [...oldErrorDocuments, document],
		}
	}
}

export function sortStatements(
	sortedStatements: SortedStatements,
	statement: BillingReportGlobalResponseStatementsDetail,
): SortedStatements {
	const { type, all, changed } = statement
	if (type !== null && type !== undefined) {
		const selectedType = sortedStatements[type]
		return {
			...sortedStatements,
			price: sortedStatements.price + (statement.price || 0),
			[type]: {
				all: _updateSortedStatementType(selectedType.all, all),
				changed: _updateSortedStatementType(selectedType.changed, changed),
			},
		}
	}
	const oldErrorStatements = sortedStatements.error_statements ? sortedStatements.error_statements : []
	return {
		...sortedStatements,
		error_statements: [...oldErrorStatements, statement],
	}
}

function _updateSortDocumentItem(
	baseItem: SortedDocumentItemType,
	newItem: ?BillingReportGlobalResponseAccountingDocumentsDetailCategory,
) {
	if (!newItem) {
		return baseItem
	}
	return {
		count: baseItem.count + (newItem.count ? newItem.count : 0),
		price: baseItem.price + (newItem.price ? newItem.price : 0),
		list: [...baseItem.list, ...(newItem.list ? newItem.list : [])],
	}
}

function _updateSortedStatementType(
	baseItem: SortedStatementItemType,
	newItem: ?BillingReportGlobalResponseStatementsDetailCategory,
) {
	if (!newItem) {
		return baseItem
	}
	return {
		count: baseItem.count + (newItem.count ? newItem.count : 0),
		price: baseItem.price + (newItem.price ? newItem.price : 0),
	}
}
