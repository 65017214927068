//@flow

import type { Enum } from 'types'

type PermissionKey =
	| 'touch_accountingdocument'
	| 'touch_accountingdocument_issued'
	| 'touch_accountingdocument_received'
	| 'touch_document'
	| 'touch_cashregister'
	| 'touch_bankaccount'
	| 'touch_contacts'
	| 'rem_accountingdocument'
	| 'rem_accountingdocument_issued'
	| 'rem_accountingdocument_received'
	| 'rem_document'
	| 'rem_user'
	| 'internal_full_access'
	| 'internal_assigned_access'
	| 'internal_assigned_access_accountingdocuments_issued'
	| 'internal_assigned_access_accountingdocuments_received'
	| 'internal_extract'
	| 'see_accountingdocuments'
	| 'see_accountingdocuments_issued'
	| 'see_accountingdocuments_received'
	| 'see_documents'
	| 'see_cashregisters'
	| 'see_bankaccounts'
	| 'see_contacts'
	| 'spec_approve'
	| 'set_user'
	| 'set_apiapps'
	| 'set_accounting'
	| 'set_profile'

export type Permission = {|
	key: PermissionKey,
	group: string,
	name: string,
	description: string,
	friendlyName?: string,
|}

// Accounting Documents ---------------------------------------------------------------------------------------------

const SEE_ACCDOCS: Permission = {
	key: 'see_accountingdocuments',
	group: 'see',
	name: 'accountingdocuments',
	description: 'Může vidět doklady',
}

const TOUCH_ACCDOC: Permission = {
	key: 'touch_accountingdocument',
	group: 'touch',
	name: 'accountingdocuments',
	description: 'Může vytvořit nebo editovat doklady',
}

const REM_ACCDOC: Permission = {
	key: 'rem_accountingdocument',
	group: 'rem',
	name: 'accountingdocuments',
	description: 'Může mazat doklady',
}

const SEE_ACCDOCS_ISSUED: Permission = {
	key: 'see_accountingdocuments_issued',
	group: 'see',
	name: 'accountingdocuments',
	description: 'Může vidět prodejní doklady',
}

const TOUCH_ACCDOC_ISSUED: Permission = {
	key: 'touch_accountingdocument_issued',
	group: 'touch',
	name: 'accountingdocuments',
	description: 'Může vytvořit nebo editovat prodejní doklady',
}

const REM_ACCDOC_ISSUED: Permission = {
	key: 'rem_accountingdocument_issued',
	group: 'rem',
	name: 'accountingdocuments',
	description: 'Může mazat prodejní doklady',
}

const SEE_ACCDOCS_RECEIVED: Permission = {
	key: 'see_accountingdocuments_received',
	group: 'see',
	name: 'accountingdocuments',
	description: 'Může vidět nákupní doklady',
}

const TOUCH_ACCDOC_RECEIVED: Permission = {
	key: 'touch_accountingdocument_received',
	group: 'touch',
	name: 'accountingdocuments',
	description: 'Může vytvořit nebo editovat nákupní doklady',
}

const REM_ACCDOC_RECEIVED: Permission = {
	key: 'rem_accountingdocument_received',
	group: 'rem',
	name: 'accountingdocuments',
	description: 'Může mazat nákupní doklady',
}

// Documents ---------------------------------------------------------------------------------------------

const SEE_DOCS: Permission = {
	key: 'see_documents',
	group: 'see',
	name: 'documents',
	description: 'Může vidět dokumenty',
}

const TOUCH_DOC: Permission = {
	key: 'touch_document',
	group: 'touch',
	name: 'documents',
	description: 'Může uploadovat dokument a nebo složky (documentItems v API)',
}

const REM_DOC: Permission = {
	key: 'rem_document',
	group: 'rem',
	name: 'documents',
	description: 'Může mazat soubory nebo složky',
}

// Cash Registers ---------------------------------------------------------------------------------------

const SEE_CASH_REGISTERS: Permission = {
	key: 'see_cashregisters',
	group: 'see',
	name: 'cashregisters',
	description: 'Může vidět cizí pokladny',
}

const TOUCH_CASH_REGISTER: Permission = {
	key: 'touch_cashregister',
	group: 'touch',
	name: 'cashregisters',
	description: 'Může editovat cizí pokladny',
}

// Bank Accounts ------------------------------------------------------------------------------------

const SEE_BANK_ACCOUNTS: Permission = {
	key: 'see_bankaccounts',
	group: 'see',
	name: 'bankaccounts',
	description: 'Může vidět bankovní účty',
}

const TOUCH_BANK_ACCOUNT: Permission = {
	key: 'touch_bankaccount',
	group: 'touch',
	name: 'bankaccounts',
	description: 'Může editovat bankovní účty',
}

// Contacts -----------------------------------------------------------------------------------------

const SEE_CONTACTS: Permission = {
	key: 'see_contacts',
	group: 'see',
	name: 'contacts',
	description: 'Může vidět kontakty',
}

const TOUCH_CONTACTS: Permission = {
	key: 'touch_contacts',
	group: 'touch',
	name: 'contacts',
	description: 'Může vytvořit, editovat a mazat kontakty',
}

// Users -----------------------------------------------------------------------------------

const SET_USER: Permission = {
	key: 'set_user',
	group: 'set',
	name: 'users',
	description: 'Může spravovat uživatele',
}

const REM_USER: Permission = {
	key: 'rem_user',
	group: 'rem',
	name: 'users',
	description: 'Může mazat Uživatele',
}

// Others -------------------------------------------------------------------------------

const SPEC_APPROVE: Permission = {
	key: 'spec_approve',
	group: 'spec',
	name: 'approve',
	description: 'Může schvalovat doklady (ve workflow)',
}

const SET_APIS: Permission = {
	key: 'set_apiapps',
	group: 'set',
	name: 'apiapps',
	description: 'Může spravovat nastavení API appek',
}

const SET_ACCOUNTING: Permission = {
	key: 'set_accounting',
	group: 'set',
	name: 'accounting',
	description: 'Může spravovat sekci nastavení účetnictví',
}

const SET_PROFILE: Permission = {
	key: 'set_profile',
	group: 'set',
	name: 'profile',
	description: 'Může spravovat informace o organizaci (adresy, název, IČO, DIČ)',
}

export const PERMISSION_ENUM: Enum = [
	{
		key: 'accounting_documents',
		value: 'Účetní doklady',
		children: [
			{
				key: SEE_ACCDOCS.key,
				value: SEE_ACCDOCS.description,
			},
			{
				key: TOUCH_ACCDOC.key,
				value: TOUCH_ACCDOC.description,
			},
			{
				key: REM_ACCDOC.key,
				value: REM_ACCDOC.description,
			},
			// {
			// 	key: SEE_ACCDOCS_ISSUED.key,
			// 	value: SEE_ACCDOCS_ISSUED.description,
			// },
			// {
			// 	key: TOUCH_ACCDOC_ISSUED.key,
			// 	value: TOUCH_ACCDOC_ISSUED.description,
			// },
			// {
			// 	key: REM_ACCDOC_ISSUED.key,
			// 	value: REM_ACCDOC_ISSUED.description,
			// },
			// {
			// 	key: SEE_ACCDOCS_RECEIVED.key,
			// 	value: SEE_ACCDOCS_RECEIVED.description,
			// },
			// {
			// 	key: TOUCH_ACCDOC_RECEIVED.key,
			// 	value: TOUCH_ACCDOC_RECEIVED.description,
			// },
			// {
			// 	key: REM_ACCDOC_RECEIVED.key,
			// 	value: REM_ACCDOC_RECEIVED.description,
			// },
		],
	},
	{
		key: 'documents',
		value: 'Dokumenty',
		children: [
			{
				key: SEE_DOCS.key,
				value: SEE_DOCS.description,
			},
			{
				key: TOUCH_DOC.key,
				value: TOUCH_DOC.description,
			},
			{
				key: REM_DOC.key,
				value: REM_DOC.description,
			},
		],
	},
	{
		key: 'cash_registers',
		value: 'Pokladny',
		children: [
			{
				key: 'FIXED',
				value: 'Může vidět a editovat vlastní pokladny',
			},
			{
				key: SEE_CASH_REGISTERS.key,
				value: SEE_CASH_REGISTERS.description,
			},
			{
				key: TOUCH_CASH_REGISTER.key,
				value: TOUCH_CASH_REGISTER.description,
			},
		],
	},
	{
		key: 'bank_accounts',
		value: 'Bankovní účty',
		children: [
			{
				key: SEE_BANK_ACCOUNTS.key,
				value: SEE_BANK_ACCOUNTS.description,
			},
			{
				key: TOUCH_BANK_ACCOUNT.key,
				value: TOUCH_BANK_ACCOUNT.description,
			},
		],
	},
	{
		key: 'contacts',
		value: 'Kontakty',
		children: [
			{
				key: SEE_CONTACTS.key,
				value: SEE_CONTACTS.description,
			},
			{
				key: TOUCH_CONTACTS.key,
				value: TOUCH_CONTACTS.description,
			},
		],
	},
	{
		key: 'users',
		value: 'Uživatelé',
		children: [
			{
				key: SET_USER.key,
				value: SET_USER.description,
			},
			{
				key: REM_USER.key,
				value: REM_USER.description,
			},
		],
	},
	{
		key: 'others',
		value: 'Ostatní',
		children: [
			{
				key: SPEC_APPROVE.key,
				value: SPEC_APPROVE.description,
			},
			{
				key: SET_APIS.key,
				value: SET_APIS.description,
			},
			{
				key: SET_ACCOUNTING.key,
				value: SET_ACCOUNTING.description,
			},
			{
				key: SET_PROFILE.key,
				value: SET_PROFILE.description,
			},
		],
	},
]

export const PERMISSIONS: Array<Permission> = [
	SEE_ACCDOCS,
	TOUCH_ACCDOC,
	REM_ACCDOC,
	SEE_ACCDOCS_ISSUED,
	TOUCH_ACCDOC_ISSUED,
	REM_ACCDOC_ISSUED,
	SEE_ACCDOCS_RECEIVED,
	TOUCH_ACCDOC_RECEIVED,
	REM_ACCDOC_RECEIVED,

	SEE_DOCS,
	TOUCH_DOC,
	REM_DOC,

	SEE_CASH_REGISTERS,
	TOUCH_CASH_REGISTER,

	SEE_BANK_ACCOUNTS,
	TOUCH_BANK_ACCOUNT,

	SEE_CONTACTS,
	TOUCH_CONTACTS,

	SET_USER,
	REM_USER,

	SPEC_APPROVE,
	SET_APIS,
	SET_ACCOUNTING,
	SET_PROFILE,
]
