// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[3].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js!../../../layouts/main-layout.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3W4B35363elLg65Vq9sLJr {\n\tmargin-bottom: 40px;\n}\n\n.kxYnhkCPdEUsthhMZTQK8 {\n\tmargin-top: 50px;\n}\n", "",{"version":3,"sources":["webpack://./modules/dashboard/pages/dashboard.css"],"names":[],"mappings":"AAAA;CAEC,mBAAA;AACD;;AAEA;CACC,gBAAA;AACD","sourcesContent":[".root {\n\tcomposes: page from \"layouts/main-layout.css\";\n\tmargin-bottom: 40px;\n}\n\n.buttonWrapper {\n\tmargin-top: 50px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "_3W4B35363elLg65Vq9sLJr " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["page"] + "",
	"buttonWrapper": "kxYnhkCPdEUsthhMZTQK8"
};
export default ___CSS_LOADER_EXPORT___;
