/* @flow */

import type {
	AccountingDocumentAssignedDirection,
	AccountingDocumentAssignedType,
	InvoiceSideButton,
	State,
} from 'types'
import React, { Component } from 'react'
import {
	type WithOrganizationSettingsProps,
	type WithTranslateProps,
	withOrganizationSettings,
	withTranslate,
} from 'wrappers'
import {
	getAccountingDocumentCurrency,
	getAccountingDocumentExchRate,
	getAccountingDocumentExchRateDefault,
	getAccountingDocumentSadExchRate,
	getAccountingDocumentVatExchRate,
} from '../../selectors'
import { getCurrentOrganizationDomesticCurrency, getOrganizationColor } from 'modules/organization/selectors'

import { ACCDOC_ERROR_MAIN_PATHS } from 'modules/accounting-document/constants'
import AccountingDocumentSummary from 'modules/accounting-document/containers/invoice-elements/document-summary'
import ActivitiesDialog from '../invoice-elements/activities-dialog'
import AddBankAccountContainer from 'modules/accounting-document/containers/invoice-elements-document/add-bank-account'
import AttachmentsContainer from 'modules/accounting-document/containers/invoice-elements-document/attachments'
import BankAccountsContainer from 'modules/accounting-document/containers/invoice-elements-document/bank-accounts'
import CashbotPanelAccDoc from 'modules/cashbot/components/cashbot-panel/cashbot-panel-accdoc'
import ContactContainer from 'modules/accounting-document/containers/invoice-elements/contact/contact'
import CreditNoteDescription from 'modules/accounting-document/containers/invoice-elements-document/credit-note-description'
import DocumentNote from 'modules/accounting-document/containers/invoice-elements/note'
import DocumentPrintingNote from 'modules/accounting-document/containers/invoice-elements/printing-note'
import EditButtonContainer from 'modules/accounting-document/containers/invoice-elements-document/edit-button'
import ExtractionNoteContainer from 'modules/accounting-document/containers/invoice-elements/extraction-note'
import HeaderDatesContainer from 'modules/accounting-document/containers/invoice-elements-document/header-dates'
import HeaderNoContainer from 'modules/accounting-document/containers/invoice-elements-document/header-no'
import HeaderOrderNoContainer from 'modules/accounting-document/containers/invoice-elements-document/header-order-no'
import Invoice from 'modules/accounting-document/components/invoices/invoice'
import InvoiceSignatureContainer from 'modules/accounting-document/containers/invoice-elements/invoice-signature'
import LineItemsContainer from 'modules/accounting-document/containers/invoice-elements-document/line-items'
import LineItemsHeaderContainer from 'modules/accounting-document/containers/invoice-elements/line-items-header'
import LogoContainer from 'modules/accounting-document/containers/invoice-elements/logo'
import Name from 'modules/accounting-document/components/invoice-elements/name'
import NeedHelpContainer from 'modules/accounting-document/containers/invoice-elements-document/need-help'
import PaymentDetailsContainer from 'modules/accounting-document/containers/invoice-elements-document/payment-details'
import PaymentTypeContainer from 'modules/accounting-document/containers/invoice-elements-document/payment-type'
import ScansContainer from 'modules/accounting-document/containers/invoice-elements-document/scans'
import SendEmailButton from 'modules/accounting-document/components/invoice-elements/send-email-button'
import SettingsPanelContainer from 'modules/accounting-document/containers/invoice-settings/settings-panel'
import StateChangerContainer from 'modules/accounting-document/containers/invoice-elements-document/state-changer'
import TotalToPayContainer from 'modules/accounting-document/containers/invoice-elements-document/total-to-pay'
import TotalsContainer from 'modules/accounting-document/containers/invoice-elements-document/totals'
import UpperSettingsContainer from 'modules/accounting-document/containers/invoice-elements/upper-settings'
import VatRecapContainer from 'modules/accounting-document/containers/invoice-elements-document/vat-recap'
import { connect } from 'react-redux'
import { getFormFieldErrorContexts } from 'helpers'
import { isAccountingDocumentExtractable } from '../../domain/accounting-document'
import { userHasAccess } from 'permissions'

type OwnProps = {
	direction: AccountingDocumentAssignedDirection,
	type: AccountingDocumentAssignedType,
	title: string,
	contactSupplierTitle: string,
	contactBuyerTitle: string,
	accountingDocumentId: string,
	view: 'default' | 'scans',
	scansCount: number,
	readonly: boolean,
	onViewChange: (view: 'default' | 'scans') => void,
	onClose: () => void,
	storing: boolean,
	internal: ?boolean,
	onEditClick: () => void,
	MoreActions: any,
	accountingDocumentState: string,
	sideButtons: Array<InvoiceSideButton>,
	showEditButton: boolean,
	showStateChanger: boolean,
	showSendEmailButton: boolean,
	showSaveAndStopExtraction: boolean,
	onCloseRedirectUrl?: string,
	showCashbotPanel: boolean,
	isCashRegister: boolean,
}

type StateProps = {|
	showDocuments: boolean,
	color: ?string,
	exchRate: ?number,
	exchRateDefault: ?number,
	vatExchRate: ?number,
	sadExchRate: ?number,
	currencyId: ?string,
	domesticCurrencyId: ?string,
|}

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => {
	return {
		showDocuments: userHasAccess(state, 'seeDocuments'),
		color: getOrganizationColor(state),
		exchRate: getAccountingDocumentExchRate(state, ownProps.accountingDocumentId),
		exchRateDefault: getAccountingDocumentExchRateDefault(state, ownProps.accountingDocumentId),
		vatExchRate: getAccountingDocumentVatExchRate(state, ownProps.accountingDocumentId),
		sadExchRate: getAccountingDocumentSadExchRate(state, ownProps.accountingDocumentId),
		domesticCurrencyId: getCurrentOrganizationDomesticCurrency(state),
		currencyId: getAccountingDocumentCurrency(state, ownProps.accountingDocumentId),
	}
}

type Props = OwnProps & StateProps & WithTranslateProps & WithOrganizationSettingsProps

type ComponentState = {
	isNoteOpen: boolean,
}

class EditInvoiceTypeDocument extends Component<Props, ComponentState> {
	state = {
		isNoteOpen: false,
	}

	componentDidMount() {
		if (!this.props.organizationSettings) {
			this.props.loadOrganizationSettings()
		}
	}

	toggleShowNote = () => {
		this.setState({ isNoteOpen: !this.state.isNoteOpen })
	}

	render() {
		const DIRECTION = this.props.direction
		const TYPE = this.props.type
		const DOCUMENT_ID = this.props.accountingDocumentId
		const READONLY = this.props.readonly
		const formFieldErrorContexts = !READONLY
			? getFormFieldErrorContexts(ACCDOC_ERROR_MAIN_PATHS, 'AccountingDocument', DOCUMENT_ID)
			: undefined

		const finAccountDisabled = this.props.accountingDocumentState === 'Extracting' ? true : false
		const showRemindersSettings = !(TYPE === 'tax_advance' || TYPE === 'credit_note')
		const isCreditNote = TYPE === 'credit_note'

		const HeaderNo =                 <HeaderNoContainer accountingDocumentId={DOCUMENT_ID} readonly={READONLY} direction={DIRECTION} type={TYPE} formFieldErrorContext={formFieldErrorContexts && formFieldErrorContexts['headerNo']} /> //eslint-disable-line
		const LineItems =               <LineItemsContainer accountingDocumentId={DOCUMENT_ID} readonly={READONLY} direction={DIRECTION} type={TYPE} formFieldErrorContext={formFieldErrorContexts && formFieldErrorContexts['lineItems']} disabledFinAccount={finAccountDisabled} documentState={this.props.accountingDocumentState} /> //eslint-disable-line
		const LineItemsHeader =   <LineItemsHeaderContainer accountingDocumentId={DOCUMENT_ID} readonly={READONLY} /> //eslint-disable-line
		const Attachments =           <AttachmentsContainer accountingDocumentId={DOCUMENT_ID} readonly={READONLY} direction={DIRECTION} type={TYPE} showDocuments={this.props.showDocuments} /> //eslint-disable-line
		const ContactBuyer =              <ContactContainer accountingDocumentId={DOCUMENT_ID} readonly={READONLY} direction={DIRECTION} isSupplier={DIRECTION === 'received'} formFieldErrorContext={formFieldErrorContexts && formFieldErrorContexts['contact']} /> //eslint-disable-line
		const AddBankAccount =     <AddBankAccountContainer accountingDocumentId={DOCUMENT_ID} readonly={READONLY} direction={DIRECTION} isCreditNote={isCreditNote} hideWhenZeroAccounts /> //eslint-disable-line
		const ContactSupplier =           <ContactContainer accountingDocumentId={DOCUMENT_ID} direction={DIRECTION} isSupplier={DIRECTION === 'issued'} isMe /> //eslint-disable-line
		const Logo =                         <LogoContainer showPlaceholder={!READONLY && 'issued' === DIRECTION} /> //eslint-disable-line
		const BankAccounts =         <BankAccountsContainer accountingDocumentId={DOCUMENT_ID} readonly={READONLY} isCreditNote={isCreditNote}  direction={DIRECTION} formFieldErrorContext={formFieldErrorContexts && formFieldErrorContexts['bankAccounts']} /> //eslint-disable-line
		const SettingsPanel =       <SettingsPanelContainer accountingDocumentId={DOCUMENT_ID} readonly={READONLY} direction={DIRECTION} showRemindersSettings={showRemindersSettings} showDuePeriodSettings formFieldErrorContext={formFieldErrorContexts && formFieldErrorContexts['upperSettings']} /> //eslint-disable-line
		const UpperSettings =       <UpperSettingsContainer accountingDocumentId={DOCUMENT_ID} readonly={READONLY} direction={DIRECTION} hideVatCountry={this.props.view !== 'scans'} view={this.props.view} showOrganizationInfo={this.props.showSaveAndStopExtraction} toggleShowNote={this.toggleShowNote} formFieldErrorContext={formFieldErrorContexts && formFieldErrorContexts['upperSettings']} /> //eslint-disable-line
		const HeaderOrderNo =       <HeaderOrderNoContainer accountingDocumentId={DOCUMENT_ID} readonly={READONLY} /> //eslint-disable-line
		const VatRecap =                 <VatRecapContainer accountingDocumentId={DOCUMENT_ID} readonly={READONLY} /> //eslint-disable-line
		const HeaderDates =           <HeaderDatesContainer accountingDocumentId={DOCUMENT_ID} readonly={READONLY} /> //eslint-disable-line
		const PaymentDetails =     <PaymentDetailsContainer accountingDocumentId={DOCUMENT_ID} readonly={READONLY} /> //eslint-disable-line
		const PaymentType =           <PaymentTypeContainer accountingDocumentId={DOCUMENT_ID} readonly={READONLY} formFieldErrorContext={formFieldErrorContexts && formFieldErrorContexts['paymentType']} /> //eslint-disable-line
		const PrintingNote =          <DocumentPrintingNote accountingDocumentId={DOCUMENT_ID} readonly={READONLY} /> //eslint-disable-line
		const Note =                          <DocumentNote accountingDocumentId={DOCUMENT_ID} readonly={READONLY} isOpen={this.state.isNoteOpen} /> //eslint-disable-line
		const Totals =                     <TotalsContainer accountingDocumentId={DOCUMENT_ID} readonly={READONLY} /> //eslint-disable-line
		const TotalsToPay =            <TotalToPayContainer accountingDocumentId={DOCUMENT_ID} /> //eslint-disable-line
		const Scans =                       <ScansContainer accountingDocumentId={DOCUMENT_ID} /> //eslint-disable-line
		const Summary =          <AccountingDocumentSummary accountingDocumentId={DOCUMENT_ID} /> //eslint-disable-line
		const Activities = 			 					<ActivitiesDialog accountingDocumentId={DOCUMENT_ID} /> //eslint-disable-line
		const ExtractionNote =     <ExtractionNoteContainer accountingDocumentId={DOCUMENT_ID} /> //eslint-disable-line

		const CashbotPanel =
			(this.props.showCashbotPanel && <CashbotPanelAccDoc accDocId={DOCUMENT_ID} direction={DIRECTION} />) || undefined
		const CreditNote =
			TYPE == 'credit_note' ? <CreditNoteDescription accountingDocumentId={DOCUMENT_ID} readonly={READONLY} formFieldErrorContext={formFieldErrorContexts && formFieldErrorContexts['creditNoteDescription']} /> : null //eslint-disable-line


		const StateChanger = (
			<>
				{this.props.showSendEmailButton && <SendEmailButton accountingDocumentId={DOCUMENT_ID} />}
				{this.props.showEditButton ? (
					<EditButtonContainer accountingDocumentId={DOCUMENT_ID} />
				) : (
					<>
						{this.props.showStateChanger && (
							<StateChangerContainer
								readonly={READONLY}
								repeatExtraction={this.props.showSaveAndStopExtraction}
								accountingDocumentId={DOCUMENT_ID}
								direction={DIRECTION}
							/>
						)}
						{this.props.showSaveAndStopExtraction && (
							<StateChangerContainer
								readonly={READONLY}
								repeatExtraction={false}
								saveAndStopExtraction={this.props.showSaveAndStopExtraction}
								accountingDocumentId={DOCUMENT_ID}
								direction={DIRECTION}
							/>
						)}
					</>
				)}
			</>
		)

		const NameComponent = <Name title={this.props.title} />

		const NeedHelp =
			this.props.internal &&
			!this.props.readonly &&
			isAccountingDocumentExtractable(this.props.accountingDocumentState) ? (
				<NeedHelpContainer accountingDocumentId={DOCUMENT_ID} />
			) : null

		const InvoiceSignature = DIRECTION !== 'received' && (
			<InvoiceSignatureContainer accountingDocumentId={DOCUMENT_ID} readonly={READONLY} />
		)

		return (
			<Invoice
				accountingDocumentId={this.props.accountingDocumentId}
				readonly={this.props.readonly}
				enabledViews={{
					default: true,
					scans: true, //this.props.scansCount > 0,
				}}
				view={this.props.view}
				color={this.props.color}
				exchRate={this.props.exchRate}
				exchRateDefault={this.props.exchRateDefault}
				vatExchRate={this.props.vatExchRate}
				sadExchRate={this.props.sadExchRate}
				currencyId={this.props.currencyId}
				domesticCurrencyId={this.props.domesticCurrencyId}
				isCashRegister={this.props.isCashRegister}
				contactSupplierTitle={this.props.contactSupplierTitle}
				contactBuyerTitle={this.props.contactBuyerTitle}
				scansCount={this.props.scansCount}
				CreditNoteDescription={CreditNote}
				LineItemsHeader={LineItemsHeader}
				LineItems={LineItems}
				HeaderNo={HeaderNo}
				HeaderOrderNo={HeaderOrderNo}
				ContactSupplier={ContactSupplier}
				Name={NameComponent}
				Logo={Logo}
				ContactBuyer={ContactBuyer}
				BankAccounts={BankAccounts}
				AddBankAccount={AddBankAccount}
				HeaderDates={HeaderDates}
				Attachments={Attachments}
				StateChanger={StateChanger}
				NeedHelp={NeedHelp}
				PaymentDetails={PaymentDetails}
				PaymentType={PaymentType}
				TotalsToPay={TotalsToPay}
				VatRecap={VatRecap}
				Totals={Totals}
				SettingsPanel={SettingsPanel}
				Scans={Scans}
				Summary={Summary}
				onViewChange={this.props.onViewChange}
				onCloseCallback={this.props.onClose}
				storing={this.props.storing}
				MoreActions={this.props.MoreActions}
				InvoiceSignature={InvoiceSignature}
				Note={Note}
				PrintingNote={PrintingNote}
				direction={DIRECTION}
				sideButtons={this.props.sideButtons}
				UpperSettings={UpperSettings}
				CashbotPanel={CashbotPanel}
				ActivitiesDialog={this.props.internal ? Activities : null}
				ExtractionNote={ExtractionNote}
				onCloseRedirectUrl={this.props.onCloseRedirectUrl}
				showOrganizationInfo={this.props.showSaveAndStopExtraction}
			/>
		)
	}
}

export default connect(mapStateToProps)(withTranslate(withOrganizationSettings(EditInvoiceTypeDocument)))
