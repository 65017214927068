/* @flow */

import type {
	BankAction,
	CommonAction,
	Currency,
	Dispatch,
	State,
	Bank,
	BankAccount,
	BankPaymentOrderType,
	BankStatementType,
} from 'types'
import {
	createBankAccount,
	loadBanks,
	loadPaymentOrderTypes,
	loadStatementTypes,
	updateBankAccount,
	loadBankAccount,
	loadBankAccounts,
} from 'modules/bank/actions'

import BankAccountEdit from 'modules/bank/components/bank-account-edit'
import type { ContainerProps as ComponentProps } from 'modules/bank/components/bank-account-edit'
import { connect } from 'react-redux'
import { loadCurrencies } from 'modules/common/actions'
import { getBankAccounts } from 'modules/bank/selectors'
import { currentUserIsAdmin, currentUserIsInternal } from 'modules/user/selectors'
import { getAllCurrencies } from 'modules/common/selectors'

type Props = {
	id?: string,
	onSave: (id: string) => void,
	handleClose?: () => void,
	create?: boolean,
}

type StateProps = {|
	banks: ?Array<Bank>,
	bankAccount: ?BankAccount,
	statementTypes: Array<BankStatementType>,
	paymentOrderTypes: Array<BankPaymentOrderType>,
	currencies: ?Array<Currency>,
	disableCheckAsMain: boolean,
	disabledCurrency?: boolean,
	currentUserIsInternal: boolean,
|}

const mapStateToProps = (state: State, ownProps: Props): StateProps => {
	const { data } = state.bank.bankAccount
	const disableCheckAsMain = (getBankAccounts(state) || []).some((ba: BankAccount) => ba.isMain && ba.id != ownProps.id)
	return {
		bankAccount: ownProps.id && data && ownProps.id == data.id ? data : null,
		statementTypes: state.bank.statementTypes.data,
		banks: state.bank.banks.data,
		paymentOrderTypes: state.bank.paymentOrderTypes.data,
		currencies: getAllCurrencies(state),
		disableCheckAsMain: disableCheckAsMain,
		disabledCurrency: ownProps.create ? false : !currentUserIsAdmin(state),
		currentUserIsInternal: currentUserIsInternal(state),
	}
}

type DispatchProps = {|
	save: (bankAccount: BankAccount) => Promise<BankAction>,
	loadBanks: () => void,
	loadStatementTypes: (bankId: string) => void,
	loadPaymentOrderTypes: (bankId: string) => void,
	loadCurrencies: () => void,
	loadBankAccount: (id: string) => void,
|}

const mapDispatchToProps = (dispatch: Dispatch<BankAction | CommonAction>, ownProps: Props): DispatchProps => {
	return {
		save: (bankAccount: BankAccount): Promise<BankAction> => {
			if (ownProps.id) {
				return dispatch(updateBankAccount(ownProps.id, bankAccount)).then((action: BankAction) => {
					if (action.bankAccount && action.bankAccount.id) {
						ownProps.onSave(action.bankAccount.id)
					}
					dispatch(loadBankAccounts(1, 200))
					return action
				})
			} else {
				return dispatch(createBankAccount(bankAccount)).then((action: BankAction) => {
					if (action.bankAccount && action.bankAccount.id) {
						ownProps.onSave(action.bankAccount.id)
					}
					dispatch(loadBankAccounts(1, 200))
					return action
				})
			}
		},
		loadStatementTypes: (bankId: string) => {
			dispatch(loadStatementTypes(bankId))
		},
		loadPaymentOrderTypes: (bankId: string) => {
			dispatch(loadPaymentOrderTypes(bankId))
		},
		loadBanks: (): void => {
			dispatch(loadBanks())
		},
		loadCurrencies: () => {
			dispatch(loadCurrencies())
		},
		loadBankAccount: (bankAccountId: string) => {
			dispatch(loadBankAccount(bankAccountId))
		},
	}
}

const mergeProps = (stateProps: StateProps, dispatchProps: DispatchProps, ownProps: Props): ComponentProps => {
	return {
		bankAccount: stateProps.bankAccount,
		save: (bankAccount: BankAccount): Promise<BankAction> => {
			if (bankAccount.bankId) {
				const selectedBank: ?Bank = stateProps.banks && stateProps.banks.find((i: Bank) => i.id == bankAccount.bankId)
				if (selectedBank) {
					bankAccount.accountCode = selectedBank.bankCode
				}
			}
			return dispatchProps.save(bankAccount)
		},
		loadStatementTypes: dispatchProps.loadStatementTypes,
		loadPaymentOrderTypes: dispatchProps.loadPaymentOrderTypes,
		statementTypes: stateProps.statementTypes,
		paymentOrderTypes: stateProps.paymentOrderTypes,
		banks: stateProps.banks,
		loadBanks: dispatchProps.loadBanks,
		currencies: stateProps.currencies,
		loadCurrencies: dispatchProps.loadCurrencies,
		create: ownProps.create,
		handleClose: ownProps.handleClose,
		bankAccountId: ownProps.id,
		loadBankAccount: dispatchProps.loadBankAccount,
		disableCheckAsMain: stateProps.disableCheckAsMain,
		disabledCurrency: stateProps.disabledCurrency,
		currentUserIsInternal: stateProps.currentUserIsInternal,
	}
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(BankAccountEdit)
