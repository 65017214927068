//@flow

import type { Dispatch, InternalUser, SignUpRequest } from 'types'
import type { FinishInternalCreate, Action as UserAction } from 'modules/user/actions/action-types'
import { createInternalUser, editInternalUser, removeInternalUser, signupInternalUser } from 'modules/user/actions'

import InternalUserSettings from '../components/internal-user-settings'
import { connect } from 'react-redux'

type DispatchProps = {|
	updateInternalUser: InternalUser => ?Promise<UserAction>,
	signUpInternalUser: SignUpRequest => Promise<UserAction>,
	createInternalUser: InternalUser => ?Promise<FinishInternalCreate>,
	removeInternalUser: string => Promise<void>,
|}
const mapDispatchToProps = (dispatch: Dispatch<UserAction>): DispatchProps => {
	return {
		updateInternalUser: (user: InternalUser) => (user.id == null ? null : dispatch(editInternalUser(user.id, user))),
		signUpInternalUser: (user: SignUpRequest) => dispatch(signupInternalUser(user)),
		createInternalUser: (user: InternalUser) => dispatch(createInternalUser(user)),
		removeInternalUser: (userId: string) => dispatch(removeInternalUser(userId)),
	}
}

export default connect(undefined, mapDispatchToProps)(InternalUserSettings)
