/* @flow */
/** @jsx jsx */

// $FlowFixMe - there is useMemo
import { type Node, useMemo } from 'react'
import SelectNext, { type ActionMeta, type OptionsType, type ValueType } from 'components/select-next'
import { type WithTranslateProps, withTranslate } from 'wrappers'
import { findVatRateById, findVatRateByLabel } from '../../domain/vat-rate'

import { type AutoTestProps } from 'utils/tests/autotest'
import { EMPTY_ARRAY } from 'trivi-constants'
import type { VatRate } from 'types'
import { jsx } from '@emotion/core'

type Props = {|
	...AutoTestProps,
	...WithTranslateProps,
	value: string,
	error?: ?Node,
	disabled?: boolean,
	tabIndex?: number,
	vatRates: null | Array<VatRate>,
	onChange: (vatRate: VatRate | null) => void,
	useKeyboardNavigation?: boolean,
	newLineTabIndex?: number,
	isInLastLineItem?: boolean,
	onLastLineEnter?: () => Promise<void>,
|}

function VatRateSelect(props: Props) {
	const found = findVatRateById(props.vatRates, props.value)
	const value = found ? found.id || null : null

	const options = useMemo((): OptionsType => {
		return props.vatRates
			? props.vatRates.map((item: VatRate) => {
					return { value: item.id, label: item.text }
			  })
			: EMPTY_ARRAY
	}, [props.vatRates])

	function onChange(value: ValueType, actionMeta: ActionMeta) {
		const newVatRateId = value && !Array.isArray(value) ? value.value : null
		const newVatLabel = value && !Array.isArray(value) ? value.label : null
		const foundId = findVatRateById(props.vatRates, newVatRateId)
		const foundByLabel = findVatRateByLabel(props.vatRates, newVatLabel)
		const foundValue = actionMeta.action === 'select-option' ? foundId || null : foundByLabel || null
		props.onChange(foundValue)
	}

	return (
		<SelectNext
			tabIndex={props.tabIndex}
			disabled={props.disabled}
			autoTestId={props.autoTestId}
			placeholder={props.t('invoice.lineItems.vat')}
			options={options}
			error={props.error}
			onChange={onChange}
			value={value}
			hideIndicator
			isSearchable
			autoWidth
			inline
			useKeyboardNavigation={props.useKeyboardNavigation}
			newLineTabIndex={props.newLineTabIndex}
			isInLastLineItem={props.isInLastLineItem}
			onLastLineEnter={props.onLastLineEnter}
			selectWithoutArrow
			useInputValue
			filterOptionConfig={{ matchFrom: 'start' }}
		/>
	)
}

export default withTranslate(VatRateSelect)
