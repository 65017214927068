/* @flow */

/*
Zobrazování tlačítka pro zapnutí automatického počítání rekapitulace
*/
export function isVatRecapResetButtonVisible(params: {
	isManual: boolean, // Je na dokladu zapnutá manuální rekapitulace?
	isVatFree: boolean, // Je doklad s nebo bez DPH?
	isReadonly: boolean, // Je doklad zobrazený v módu editace?
	isUserInternal: boolean, // Je uživatel interní?
	expectedTotal?: number,
	accDocState?: string,
}): boolean {
	const { isManual, isReadonly, isUserInternal, isVatFree, expectedTotal, accDocState } = params

	// https://triviit.atlassian.net/browse/TESTREP-2217
	if (!isUserInternal) {
		return false
	}

	if (
		// ZOBRAZOVAT tlačítko pouze v následujících stavech:
		accDocState !== 'Fixing' &&
		accDocState !== 'Draft' &&
		accDocState !== 'Extracting' &&
		accDocState !== 'Extracted'
	) {
		return false
	}

	// https://triviit.atlassian.net/browse/TESTREP-2089
	if (expectedTotal != null) {
		return true
	}

	// Zobrazovat resetovací tlačítko:
	const isResetButtonVisible =
		// - pouze při zapnuté ruční rekapitulaci
		isManual &&
		// - jen při editaci faktury
		!isReadonly &&
		// - pro standardního uživatele pouze u dokladů s DPH (interní uživatel ho vidí i u neplátcovských, aby mohl opravit případnou nesrovnalost)
		(isUserInternal || !isVatFree)

	return isResetButtonVisible
}
