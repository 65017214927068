/* @flow */
/** @jsx jsx */

import { Component } from 'react'
import { jsx } from '@emotion/core'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import Button from 'components/button'
import { colors } from 'variables'

type Props = {|
	...WithTranslateProps,
	visible?: boolean,
	disabled?: boolean,
	saving?: boolean,
	onSave?: () => any,
	onRestore?: () => any,
|}

class SaveSettings extends Component<Props> {
	render() {
		if (!this.props.visible) return null

		return this.props.saving ? (
			<span css={styles.saving}>{this.props.t('settings.forms.savingChanges')}</span>
		) : (
			<span css={styles.save}>
				<span>
					<Button
						primary
						onClick={this.props.onSave}
						labelText={this.props.t('settings.forms.saveChanges')}
						disabled={this.props.disabled}
						autoTestId="settings-save-changes"
					/>
					<span css={styles.cancelChanges}>
						<Button
							labelText={this.props.t('settings.forms.cancelChanges')}
							onClick={this.props.onRestore}
							transparent
							autoTestId="settings-cancel-changes"
						/>
					</span>
				</span>
			</span>
		)
	}
}

const styles = {
	saving: {
		display: 'block',
		marginTop: 25,
		lineHeight: '42px',
		color: colors.blackFaded60,
	},
	save: {
		display: 'block',
		marginTop: 25,
	},
	cancelChanges: {
		opacity: 0.8,
		transition: '100ms',
	},
}

export default withTranslate(SaveSettings)
