// @flow
/** @jsx jsx */

import { jsx } from '@emotion/core'
import { colors } from 'variables'

type Props = {|
	primaryText?: ?string,
	secondaryText?: ?string,
	children?: React$Node | Array<React$Node>,
	onClick?: () => void,
|}

export default function TableCell(props: Props) {
	const content =
		props.primaryText || props.secondaryText ? (
			<div>
				<div css={styles.primary}>{props.primaryText}</div>
				<div css={styles.secondary}>{props.secondaryText}</div>
			</div>
		) : null

	return (
		<td css={styles.root} onClick={props.onClick}>
			<div css={styles.wrapper}>
				<div css={styles.container}>{content || props.children}</div>
			</div>
			<span id="borderTop" css={styles.borderTop} />
			<span id="borderBottom" css={styles.borderBottom} />
		</td>
	)
}

const styles = {
	root: {
		paddingTop: 6,
		paddingBottom: 6,
		textAlign: 'left',
		paddingRight: 10,
		fontSize: 14,
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		color: colors.black,
		borderBottom: `1px solid ${colors.grayLight200}`,
		position: 'relative',
		verticalAlign: 'top',
		'&:lastChild': {
			paddingRight: 10,
		},
	},
	wrapper: {
		lineHeight: '17px',
		minHeight: 24,
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		display: 'flex',
		alignContent: 'center',
		alignItems: 'center',
		justifyContent: 'stretch',
	},
	container: {
		width: '100%',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
	},
	borderTop: {
		position: 'absolute',
		top: -1,
		height: 1,
		zIndex: 10,
		left: 0,
		right: 0,
		transition: 'all linear 200ms',
	},
	borderBottom: {
		position: 'absolute',
		bottom: -1,
		height: 1,
		zIndex: 10,
		transition: 'all linear 200ms',
	},
	primary: {
		fontSize: 14,
		minHeight: 17,
		lineHeight: '17px',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
		color: colors.black,
	},
	secondary: {
		fontSize: 12,
		minHeight: 17,
		lineHeight: '17px',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
		color: colors.blackFaded60,
	},
}
