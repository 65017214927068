/* @flow */

import type { WithRouterProps } from 'types'
import React, { Component } from 'react'
import { bankAccountRoute, bankAccountsRoute } from 'modules/bank/routing/routes'

import BankAccountEdit from '../containers/bank-account-edit'
import PopupSection from 'components/PopupSection'
import { withRouter } from 'react-router-dom'
import Helmet from 'react-helmet'
import { withTranslate, type WithTranslateProps } from 'wrappers'

class BankAccountCreatePage extends Component<WithTranslateProps & WithRouterProps> {
	handleBankAccountEditOnSave = (id: string) => {
		if (id) {
			this.props.history.push(bankAccountRoute(id))
		}
	}

	handlePageClose = () => {
		this.props.history.push(bankAccountsRoute())
	}

	render() {
		return (
			<PopupSection open onRequestClose={this.handlePageClose}>
				<Helmet>
					<title>{this.props.t('bank.createBankAccountHeadline')}</title>
				</Helmet>
				<BankAccountEdit create onSave={this.handleBankAccountEditOnSave} />
			</PopupSection>
		)
	}
}

export default withTranslate(withRouter(BankAccountCreatePage))
