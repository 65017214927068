// @flow
/** @jsx jsx */

import { jsx } from '@emotion/core'
import { getInvoiceAmount } from '../../domain'
import type { CashbotOffer, CashbotInvoice } from 'modules/cashbot/types'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import PopupSection from 'components/PopupSection'
import CashbotOfferPanel from './cashbot-offer-panel'
import cashbotLogo from 'assets/images/cashbot-logo.svg'
import Paper from 'components/Paper'

type Props = {|
	...WithTranslateProps,
	open: boolean,
	offers: ?Array<CashbotOffer>,
	invoice: ?CashbotInvoice,
	onClose: () => void,
	onOfferClick?: (offer: CashbotOffer) => void,
|}

function CashbotIssuedDialog(props: Props) {
	if (!props.open) return null
	return (
		<PopupSection open={props.open} onRequestClose={props.onClose}>
			<div css={style.container}>
				<Paper zDepth={5}>
					<div css={style.content}>
						<img src={cashbotLogo} css={style.logo} />
						<div>
							<div css={style.headline}>{props.t('cashbot.choose', { amount: getInvoiceAmount() })}</div>
							<div css={style.offers}>
								{props.offers &&
									props.offers.map((offer: CashbotOffer, index: number) => {
										return <CashbotOfferPanel key={index} offer={offer} onOfferClick={props.onOfferClick} />
									})}
							</div>
						</div>
					</div>
				</Paper>
			</div>
		</PopupSection>
	)
}

const style = {
	container: {
		marginLeft: 'auto',
		marginRight: 'auto',
		marginTop: 65,
		maxWidth: 850,
		width: '80%',
	},
	content: {
		marginTop: 60,
		padding: 50,
		fontSize: 25,
		lineHeight: 1,
		textAlign: 'center',
		overflow: 'hidden',
		borderRadius: 3,
	},
	logo: {
		position: 'absolute',
		width: 120,
		height: 'auto',
		right: 25,
		top: 25,
	},
	headline: {
		lineHeight: '30px',
		padding: '0 50px',
	},
	offers: {
		marginTop: 50,
		display: 'flex',
		justifyContent: 'center',
	},
}

export default withTranslate(CashbotIssuedDialog)
