// @flow
import { connect } from 'react-redux'
import type { State, BankAccountStatementOptions, Dispatch, BankAction } from 'types'
import { getBankAccountStatementOptions } from '../selectors'
import CsasStatementsOptions from '../components/csas-statements-options'
import withAutoload from 'wrappers/with-autoload'
import { loadStatementOptions, saveStatementOptions } from '../actions'

type OwnProps = {|
	id: string,
|}

type StateProps = {|
	statementsOptions: ?BankAccountStatementOptions,
|}

function mapStateToProps(state: State, ownProps: OwnProps): StateProps {
	return {
		statementsOptions: getBankAccountStatementOptions(state, ownProps.id),
	}
}

type DispatchProps = {|
	autoload: () => void,
	saveOptions: (statementOptions: BankAccountStatementOptions) => void,
|}

function mapDispatchToProps(dispatch: Dispatch<BankAction>, ownProps: OwnProps): DispatchProps {
	return {
		autoload: () => {
			dispatch(loadStatementOptions(ownProps.id))
		},
		saveOptions: (statementOptions: BankAccountStatementOptions) => {
			dispatch(saveStatementOptions(ownProps.id, statementOptions))
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(withAutoload(CsasStatementsOptions))
