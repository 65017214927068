/* @flow */
/** @jsx jsx */

import { jsx } from '@emotion/core'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import { formatToMoney } from 'utils/formatters'
import { autoTestId } from 'utils/tests/autotest'
import { colors } from 'variables'

type Props = {|
	...WithTranslateProps,
	color: ?string,
	total: ?number,
	currency?: string,
	accountingDocumentId?: string,
|}

function TotalToPay(props: Props) {
	return (
		<div css={style.root} style={{ backgroundColor: props.color }}>
			<div css={style.header}>{props.t('invoice.totalToPay')}</div>
			<div {...autoTestId('totalToPay')}>
				{formatToMoney(props.total, {
					currency: props.currency,
				})}
			</div>
		</div>
	)
}

const style = {
	root: {
		fontSize: 14,
		fontWeight: 'normal',
		background: colors.blue,
		flex: '1 1 22%',
		color: '#ffffff',
		padding: '9px 12px',
		textAlign: 'right',
		lineHeight: '24px',
	},
	header: {
		fontSize: 10,
		lineHeight: '12px',
		marginBottom: 5,
		color: 'inherit',
		opacity: 1,
	},
}

export default withTranslate(TotalToPay)
