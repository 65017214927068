// @flow
import React from 'react'
import type { PaymentOrder } from 'types'
import styles from './bank-order-info.css'
import { BANK_STATEMENTS_STATUS_TYPES } from 'types/convertor'
import { formatToDateTimeString, formatToDateString, formatToMoney } from 'utils/formatters'
import { TriviLink } from 'components'
import { bankAccountRoute } from 'modules/bank/routing/routes'
import { withTranslate, type WithTranslateProps } from 'wrappers'

type Props = {|
	order: PaymentOrder,
	bankAccountName: ?string,
	...WithTranslateProps,
|}

function BankOrderInfo({ order, t, bankAccountName }: Props) {
	const processingState = BANK_STATEMENTS_STATUS_TYPES[String(order.processingState)]
	return (
		<table className={styles.tooltipTable}>
			<tbody>
				{order.id && (
					<tr>
						<td>{t('paymentDialog.ordersTooltipLabels.orderId')}</td>
						<td>{order.id}</td>
					</tr>
				)}
				{order.name && (
					<tr>
						<td>{t('paymentDialog.ordersTooltipLabels.name')}</td>
						<td>{order.name}</td>
					</tr>
				)}
				{order.requestedTime && (
					<tr>
						<td>{t('paymentDialog.ordersTooltipLabels.requestedTime')}</td>
						<td>{formatToDateTimeString(order.requestedTime || '')}</td>
					</tr>
				)}
				{order.amount != null && (
					<tr>
						<td>{t('paymentDialog.ordersTooltipLabels.amount')}</td>
						<td>{formatToMoney(order.amount, { currency: order.currency })}</td>
					</tr>
				)}
				{order.bankAccountId && (
					<tr>
						<td>{t('paymentDialog.ordersTooltipLabels.bankAccount')}</td>
						<td>
							<TriviLink routeFn={bankAccountRoute} routeParams={[order.bankAccountId || '']}>
								{bankAccountName}
							</TriviLink>
						</td>
					</tr>
				)}
				{order.dueDate && (
					<tr>
						<td>{t('paymentDialog.ordersTooltipLabels.dueDate')}</td>
						<td>{formatToDateString(order.dueDate || '')}</td>
					</tr>
				)}
				{order.processingState != null && (
					<tr>
						<td>{t('paymentDialog.ordersTooltipLabels.processingState')}</td>
						<td>{t(`accountingDocument.filter.bankAccounts.processingStates.${processingState}`)}</td>
					</tr>
				)}
			</tbody>
		</table>
	)
}

export default withTranslate(BankOrderInfo)
