/* @flow */

import type {
	Currency,
	EmailListResponse,
	Enum,
	FinancialAccount,
	Invitation,
	Organization,
	OrganizationAction,
	OrganizationCreator,
	OrganizationPersonAuthorizedToSign,
	OrganizationTaxRepresentative,
	UserAction,
	UserProfile,
	OrganizationProcessIssue,
	Tag,
} from 'types'
import type { Action as ReportingAction } from 'modules/settings/actions/reporting-action-types'
import deepEqual from 'deep-equal'
import { union as arrayUnion, without as arrayWithout } from 'lodash-es'
import { EMPTY_ARRAY } from 'trivi-constants'
import type { UserAvatar } from '../types'

export type OrganizationDetailState = {
	data: ?Organization,
	loading: boolean,
	signAuthorized: ?Array<OrganizationPersonAuthorizedToSign>,
	creators: ?Array<OrganizationCreator>,
	taxRepresentatives: ?Array<OrganizationTaxRepresentative>,
	processingIssues: ?Array<OrganizationProcessIssue>,
}

export type State = {
	currenciesUsedInOrg: {
		loading: boolean,
		data: ?Array<Currency>,
	},
	branches: {
		loading: boolean,
		data: ?Enum,
	},
	projects: {
		loading: boolean,
		data: ?Enum,
	},
	financialAccounts: {
		loading: boolean,
		loadingForKey: ?string,
		data: {
			[key: string]: ?Array<FinancialAccount>,
		},
	},
	organizationDetail: OrganizationDetailState,
	favorites: {
		[section: string]: {
			loading: boolean,
			keys: Array<string>,
		},
	},
	users: {
		data: ?Array<UserProfile>,
		loading: boolean,
	},
	userAvatars: Array<UserAvatar>,
	invitations: {
		data: ?Array<Invitation>,
		loading: boolean,
	},
	emailList: ?EmailListResponse,
	loading: boolean,
}

export const initialState: State = {
	currenciesUsedInOrg: {
		loading: false,
		data: null,
	},
	branches: {
		loading: false,
		data: null,
	},
	projects: {
		loading: false,
		data: null,
	},
	financialAccounts: {
		loading: false,
		loadingForKey: null,
		data: {},
	},
	organizationDetail: {
		loading: false,
		data: null,
		signAuthorized: null,
		creators: null,
		taxRepresentatives: null,
		processingIssues: null,
	},
	favorites: {},
	users: {
		data: null,
		loading: false,
	},
	userAvatars: [],
	invitations: {
		data: null,
		loading: false,
	},
	emailList: null,
	loading: false,
}

// General
const organization = (
	state: State = initialState,
	action: OrganizationAction | UserAction | ReportingAction,
): State => {
	switch (action.type) {
		case 'START_LOAD_BOOTSTRAP':
			return {
				...state,
				organizationDetail: {
					...state.organizationDetail,
					loading: true,
				},
			}
		case 'FINISH_LOAD_BOOTSTRAP':
			return {
				...state,
				organizationDetail: {
					...state.organizationDetail,
					loading: false,
					data: {
						...((action.data && action.data.organization) || {}),
						report:
							(action.data && action.data.organizationReport) ||
							(state.organizationDetail.data ? state.organizationDetail.data.report : undefined),
					},
				},
			}
		case 'START_LOAD_CURRENCIES_USED_IN_ORG':
			return {
				...state,
				currenciesUsedInOrg: {
					...state.currenciesUsedInOrg,
					loading: true,
				},
			}

		case 'FINISH_LOAD_CURRENCIES_USED_IN_ORG': {
			const currencies = {
				loading: false,
				data: action.currencies || EMPTY_ARRAY,
			}
			return {
				...state,
				currenciesUsedInOrg: currencies,
			}
		}

		case 'START_LOAD_BRANCHES':
			return {
				...state,
				branches: {
					...state.branches,
					loading: true,
				},
			}

		case 'FINISH_LOAD_BRANCHES':
			return {
				...state,
				branches: {
					...state.branches,
					loading: false,
					data: action.branches,
				},
			}

		case 'FINISH_ADDING_TAG':
			return addNewTag(state, action.tag)

		case 'START_LOAD_PROJECTS':
			return {
				...state,
				projects: {
					...state.projects,
					loading: true,
				},
			}

		case 'FINISH_LOAD_PROJECTS':
			return {
				...state,
				projects: {
					...state.projects,
					loading: false,
					data: action.projects,
				},
			}

		case 'START_LOAD_FINANCIAL_ACCOUNTS':
			return {
				...state,
				financialAccounts: {
					...state.financialAccounts,
					loadingForKey: action.key,
					loading: true,
				},
			}

		case 'FINISH_LOAD_FINANCIAL_ACCOUNTS':
			if (action.serverError) {
				return {
					...state,
					financialAccounts: {
						...state.financialAccounts,
						loadingForKey: null,
						loading: false,
					},
				}
			}
			return {
				...state,
				financialAccounts: {
					loading: false,
					loadingForKey: null,
					data: {
						...state.financialAccounts.data,
						[action.key]: action.financialAccounts,
					},
				},
			}

		case 'START_LOAD_ORGANIZATION_DETAIL': {
			return {
				...state,
				organizationDetail: {
					...state.organizationDetail,
					loading: true,
				},
			}
		}

		case 'SET_ORGANIZATION':
		case 'FINISH_LOAD_ORGANIZATION_DETAIL': {
			return {
				...state,
				organizationDetail: {
					...state.organizationDetail,
					loading: false,
					data: action.organization,
				},
			}
		}

		case 'FINISH_LOAD_USER_AVATAR': {
			if (action.userId) {
				const existingAvatars = [...state.userAvatars]
				const updatedAvatars = [...existingAvatars, { userId: action.userId, avatar: action.avatar || '' }]
				return {
					...state,
					userAvatars: updatedAvatars,
				}
			}
			return { ...state }
		}

		case 'START_LOAD_FAVORITE_SECTION': {
			return setFavoriteSectionLoading(state, action.section, true)
		}

		case 'FINISH_LOAD_FAVORITE_SECTION': {
			return setFavoriteSectionKeys(
				setFavoriteSectionLoading(state, action.section, false),
				action.section,
				action.keys.length > 0 ? action.keys : EMPTY_ARRAY,
			)
		}

		case 'START_CREATE_FAVORITE_ITEM': {
			// optimistic update
			return addFavoriteSectionKey(state, action.section, action.key)
		}

		case 'FINISH_CREATE_FAVORITE_ITEM': {
			if (action.success) {
				return addFavoriteSectionKey(state, action.section, action.key)
			} else {
				// remove key on update error
				return removeFavoriteSectionKey(state, action.section, action.key)
			}
		}

		case 'START_REMOVE_FAVORITE_ITEM': {
			// optimistic update
			return removeFavoriteSectionKey(state, action.section, action.key)
		}

		case 'FINISH_REMOVE_FAVORITE_ITEM': {
			if (action.success) {
				return removeFavoriteSectionKey(state, action.section, action.key)
			} else {
				// add key back on update error
				return addFavoriteSectionKey(state, action.section, action.key)
			}
		}

		case 'START_LOAD_USERS': {
			return {
				...state,
				users: {
					...state.users,
					loading: true,
				},
			}
		}

		case 'FINISH_LOAD_USERS': {
			return {
				...state,
				users: {
					...state.users,
					data: action.users || state.users.data,
					loading: false,
				},
			}
		}

		case 'START_LOAD_INVITATIONS': {
			return {
				...state,
				invitations: {
					...state.invitations,
					loading: true,
				},
			}
		}

		case 'FINISH_LOAD_INVITATIONS': {
			return {
				...state,
				invitations: {
					...state.invitations,
					data: action.invitations || state.invitations.data,
					loading: false,
				},
			}
		}

		// optimistic update
		case 'START_INVITATION_SENDING': {
			return {
				...state,
				invitations: {
					...state.invitations,
					data: [
						...(state.invitations.data || EMPTY_ARRAY),
						{
							userId: action.invitationRequest.userId,
							email: action.invitationRequest.email,
							invitationCodes: [
								{
									groups: action.invitationRequest.groups,
								},
							],
						},
					],
				},
			}
		}

		case 'FINISH_CHANGING_MEMBERSHIP': {
			if (action.membershipType != null) {
				const id: string = action.userId
				const users: ?Array<UserProfile> = state.users.data && [...state.users.data]
				if (users) {
					const user: ?UserProfile = users.find((u: UserProfile) => u.id === id)
					if (user && action.membershipType) {
						user.membershipType = action.membershipType
					}
				}

				return {
					...state,
					users: {
						...state.users,
						data: users,
					},
				}
			}

			return state
		}

		case 'FINISH_LOADING_ORGANIZATION_SIGN_AUTHORIZED_PERSONS': {
			return setCurrentOrganizationDetailValue(state, 'signAuthorized', action.signAuthorized)
		}

		// optimistic update
		case 'START_DELETING_ORGANIZATION_SIGN_AUTHORIZED_PERSON': {
			return setCurrentOrganizationDetailValue(state, 'signAuthorized', action.newSignAuthorized)
		}

		case 'FINISH_DELETING_ORGANIZATION_SIGN_AUTHORIZED_PERSON': {
			return setCurrentOrganizationDetailValue(
				state,
				'signAuthorized',
				action.serverError ? action.oldSignAuthorized : action.newSignAuthorized,
			)
		}

		//optimistic update
		case 'START_UPDATING_ORGANIZATION_SIGN_AUTHORIZED_PERSON': {
			return setCurrentOrganizationDetailValue(state, 'signAuthorized', action.newSignAuthorized)
		}

		case 'FINISH_UPDATING_ORGANIZATION_SIGN_AUTHORIZED_PERSON': {
			return setCurrentOrganizationDetailValue(
				state,
				'signAuthorized',
				action.serverError ? action.oldSignAuthorized : action.newSignAuthorized,
			)
		}

		case 'FINISH_ADDING_ORGANIZATION_SIGN_AUTHORIZED_PERSON': {
			if (action.person) {
				const signAuthorized: ?Array<OrganizationPersonAuthorizedToSign> = state.organizationDetail.signAuthorized

				return setCurrentOrganizationDetailValue(state, 'signAuthorized', [...(signAuthorized || []), action.person])
			}
			return state
		}

		case 'FINISH_LOADING_ORGANIZATION_CREATORS': {
			if (action.creators) {
				return setCurrentOrganizationDetailValue(state, 'creators', action.creators)
			}
			return state
		}

		// optimistic update
		case 'START_DELETING_ORGANIZATION_CREATORS': {
			return setCurrentOrganizationDetailValue(state, 'creators', action.newCreators)
		}

		case 'FINISH_DELETING_ORGANIZATION_CREATORS': {
			const creators: ?Array<OrganizationCreator> = action.serverError ? action.oldCreators : action.newCreators
			return setCurrentOrganizationDetailValue(state, 'creators', creators)
		}

		case 'FINISH_ADDING_ORGANIZATION_CREATOR': {
			if (action.creator) {
				const creators: ?Array<OrganizationCreator> = [...(state.organizationDetail.creators || []), action.creator]
				return setCurrentOrganizationDetailValue(state, 'creators', creators)
			}
			return state
		}

		//optimistic update
		case 'START_UPDATING_ORGANIZATION_CREATOR': {
			return setCurrentOrganizationDetailValue(state, 'creators', action.newCreators)
		}

		case 'FINISH_UPDATING_ORGANIZATION_CREATOR': {
			return setCurrentOrganizationDetailValue(
				state,
				'creators',
				action.serverError ? action.oldCreators : action.newCreators,
			)
		}

		case 'FINISH_LOADING_ORGANIZATION_TAX_REPRESENTATIVES': {
			if (action.taxRepresentatives) {
				return setCurrentOrganizationDetailValue(state, 'taxRepresentatives', action.taxRepresentatives)
			}

			return state
		}

		case 'FINISH_ADDING_ORGANIZATION_TAX_REPRESENTATIVE': {
			if (action.taxRepresentative) {
				const taxRepresentatives: ?Array<OrganizationTaxRepresentative> = [
					...(state.organizationDetail.taxRepresentatives || EMPTY_ARRAY),
					action.taxRepresentative,
				]

				return setCurrentOrganizationDetailValue(state, 'taxRepresentatives', taxRepresentatives)
			}
			return state
		}

		//optimistic update
		case 'START_UPDATING_ORGANIZATION_TAX_REPRESENTATIVE': {
			return setCurrentOrganizationDetailValue(state, 'taxRepresentatives', action.newTaxRepresentatives)
		}

		case 'FINISH_UPDATING_ORGANIZATION_TAX_REPRESENTATIVE': {
			return setCurrentOrganizationDetailValue(
				state,
				'taxRepresentatives',
				action.serverError ? action.oldTaxRepresentatives : action.newTaxRepresentatives,
			)
		}

		// optimistic update
		case 'START_DELETING_ORGANIZATION_TAX_REPRESENTATIVE': {
			return setCurrentOrganizationDetailValue(state, 'taxRepresentatives', action.newTaxRepresentatives)
		}

		case 'FINISH_DELETING_ORGANIZATION_TAX_REPRESENTATIVE': {
			return setCurrentOrganizationDetailValue(
				state,
				'taxRepresentatives',
				action.serverError ? action.oldTaxRepresentatives : action.newTaxRepresentatives,
			)
		}

		case 'START_REMOVING_ORGANIZATION_ADDRESS':
		case 'START_CHANGING_ORGANIZATION_ADDRESS': {
			return setCurrentOrganizationValue(state, 'addresses', action.newAddresses, true)
		}

		case 'FINISH_REMOVING_ORGANIZATION_ADDRESS':
		case 'FINISH_CHANGING_ORGANIZATION_ADDRESS': {
			if (!action.serverError) {
				return {
					...state,
					loading: false,
				}
			}

			return setCurrentOrganizationValue(state, 'addresses', action.oldAddresses, false)
		}
		case 'FINISH_GET_INBOUND_EMAILS': {
			return {
				...state,
				emailList: deepEqual(action.response, state.emailList) ? state.emailList : action.response,
			}
		}

		case 'FINISH_LOADING_PROCESSING_ISSUES': {
			if (action.serverError) {
				return state
			}
			return setCurrentOrganizationDetailValue(state, 'processingIssues', action.issues || EMPTY_ARRAY)
		}

		default:
			return state
	}
}

const setFavoriteSectionLoading = (state: State, section: string, loading: boolean): State => {
	let favorites = { ...state.favorites }
	if (favorites[section]) {
		favorites[section].loading = loading
	} else {
		favorites[section] = {
			loading,
			keys: EMPTY_ARRAY,
		}
	}
	return {
		...state,
		favorites,
	}
}

const setFavoriteSectionKeys = (state: State, section: string, keys: Array<string>): State => {
	let favorites = { ...state.favorites }
	if (favorites[section]) {
		favorites[section].keys = keys
	} else {
		favorites[section] = {
			loading: false,
			keys,
		}
	}
	return {
		...state,
		favorites,
	}
}

const addFavoriteSectionKey = (state: State, section: string, key: string): State => {
	let favorites = { ...state.favorites }
	if (favorites[section]) {
		favorites[section].keys = arrayUnion(favorites[section].keys, [key])
	} else {
		favorites[section] = {
			loading: false,
			keys: [key],
		}
	}
	return {
		...state,
		favorites,
	}
}

const removeFavoriteSectionKey = (state: State, section: string, key: string): State => {
	let favorites = { ...state.favorites }
	if (favorites[section]) {
		favorites[section].keys = arrayWithout(favorites[section].keys, key)
	} else {
		favorites[section] = {
			loading: false,
			keys: EMPTY_ARRAY,
		}
	}
	return {
		...state,
		favorites,
	}
}

const setCurrentOrganizationDetailValue = (state: State, field: $Keys<OrganizationDetailState>, value: any): State => {
	return {
		...state,
		organizationDetail: {
			...state.organizationDetail,
			[field]: value,
		},
	}
}

const setCurrentOrganizationValue = (
	state: State,
	field: $Keys<Organization>,
	value: any,
	isLoading: boolean,
): State => {
	const data: ?Organization = state.organizationDetail.data
		? Object.freeze({
				...state.organizationDetail.data,
				[field]: value,
		  })
		: null

	return {
		...state,
		organizationDetail: {
			...state.organizationDetail,
			data,
		},
		loading: isLoading,
	}
}

const addNewTag = (state: State, tag: ?Tag) => {
	if (!tag) return state

	const isBranch = tag.type === 0
	const newTag = {
		key: tag.code,
		value: tag.name,
	}
	const propName = isBranch ? 'branches' : 'projects'

	return {
		...state,
		[propName]: {
			...state[propName],
			data: state[propName].data ? [...state[propName].data, newTag] : [newTag],
		},
	}
}

export default organization
