/* @flow */
import Dashboard from '../components/dashboard'
import { connect } from 'react-redux'
import { type DashboardAction, type Dispatch } from 'types'
import { initialLoadOrganizationCards } from '../actions'
import withAutoload from 'wrappers/with-autoload'

type DispatchProps = {|
	autoload: () => void,
|}

const mapDispatchToProps = (dispatch: Dispatch<DashboardAction>): DispatchProps => {
	return {
		autoload: () => {
			dispatch(initialLoadOrganizationCards())
		},
	}
}

export default connect(undefined, mapDispatchToProps)(withAutoload(Dashboard))
