/* @flow */
import { push } from 'react-router-redux'
import type { NavigationAction, State } from 'types'
import { getMainScreenRoute } from 'modules/navigation/selectors'

export function setNavigationCompact(compact: boolean) {
	return {
		type: 'SET_NAVIGATION_COMPACT',
		compact,
	}
}

export function navigateToMainScreen() {
	return (dispatch: Dispatch<NavigationAction>, getState: () => State) => {
		return dispatch(push(getMainScreenRoute(getState())))
	}
}
