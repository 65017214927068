/* @flow */

import { connect } from 'react-redux'
import PaymentDetails from 'modules/accounting-document/components/invoice-elements/payment-details'
import { updateAccountingDocumentDueDate, updateAccountingDocumentVariableSymbol } from '../../actions'
import type { State, AccountingDocument } from 'types'
import { getAccountingDocument } from 'modules/accounting-document/selectors'
import { getDefaultDueDate } from '../../selectors'
import { loadContact } from '../../../address-book'
import { loadAccountingDocumentDefaults } from '../../../settings/actions'
import { ACCOUNTING_DOCUMENT_DIRECTION_TYPES } from 'types/convertor'

export type OwnProps = {|
	accountingDocumentId: string,
	readonly?: boolean,
	readonlyDueDate?: ?boolean,
|}

type StateProps = {|
	dueDate: ?string,
	issueDate: ?string,
	variableSymbol: ?string,
	variableSymbolPreview: ?string,
	accDocDefaultInterval?: ?number,
	accDoc?: ?AccountingDocument,
|}

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => {
	const doc: ?AccountingDocument = getAccountingDocument(state, ownProps.accountingDocumentId)
	const { variableSymbolPreview, variableSymbol, issueDate, dueDate } = doc || {}

	const accDocDefaultInterval = doc && getDefaultDueDate(state, doc)

	return {
		variableSymbol,
		variableSymbolPreview,
		dueDate,
		issueDate: issueDate,
		accDocDefaultInterval,
		accDoc: doc,
	}
}

type DispatchProps = {|
	onDueDateChange: (dueDate: ?Date) => void,
	onVariableSymbolChange: (variableSymbol: ?string) => void,
	loadDefaults?: (accDoc: AccountingDocument) => void,
|}

const mapDispatchToProps = (dispatch: any, ownProps: OwnProps): DispatchProps => {
	function loadContactDefaults(accDoc: AccountingDocument) {
		if (accDoc.contact && accDoc.contact.contactId) {
			dispatch(loadContact(accDoc.contact.contactId))
		}
	}

	function loadAccDocDefaults(accDoc: AccountingDocument) {
		if (accDoc.direction) {
			dispatch(loadAccountingDocumentDefaults(null, ACCOUNTING_DOCUMENT_DIRECTION_TYPES[accDoc.direction.toString()]))
		}
	}

	return {
		onDueDateChange: (dueDate: ?Date) => {
			dispatch(updateAccountingDocumentDueDate(ownProps.accountingDocumentId, dueDate))
		},
		onVariableSymbolChange: (variableSymbol: ?string) => {
			dispatch(updateAccountingDocumentVariableSymbol(ownProps.accountingDocumentId, variableSymbol))
		},

		loadDefaults: (accDoc: AccountingDocument) => {
			loadContactDefaults(accDoc)
			loadAccDocDefaults(accDoc)
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentDetails)
