/* @flow */

import type { Action } from '../actions/action-types'
import { mergeChannel, removeChannel, mergeChannelUnsentMessage } from '../chat/channel'
import { getMember, mergeChannelMember } from '../chat/member'
import { removeChannelMessages } from '../chat/message'
import type { ChatState } from '../types'
import { initialState } from '../types'
import { createMerge, mergeMessagesByChannel, mergeChatUserInfo } from './merge'

export { initialState } from '../types'

export default function(state: ChatState = initialState, action: Action): ChatState {
	const merge = createMerge(state)
	switch (action.type) {
		case 'TOGGLE_CHANNEL_LIST':
			return merge({
				channelList: {
					...state.channelList,
					...{ window: action.window },
				},
			})
		case 'TOGGLE_CHANNEL_NEW':
			return merge({
				channelNew: {
					...state.channelNew,
					...{ window: action.window },
				},
			})
		case 'TOGGLE_CHANNEL':
			return merge({
				channels: {
					...state.channels,
					...{
						[action.channel.sid]: {
							...state.channels[action.channel.sid],
							...{ window: action.window, position: action.position },
						},
					},
				},
			})
		case 'FINISH_ADD_MESSAGE':
		case 'ADD_MESSAGE':
			return merge({
				messagesByChannel: mergeMessagesByChannel(state.messagesByChannel, action.channel, [action.message]),
			})
		case 'FINISH_LOAD_MESSAGES':
			if (!action.messages) {
				return state
			}
			return merge({
				messagesByChannel: mergeMessagesByChannel(state.messagesByChannel, action.channel, action.messages),
			})
		case 'UPDATE_LAST_CONSUMED_MESSAGE':
			return merge({
				channels: mergeChannel(
					state.channels,
					mergeChannelMember(state.channels[action.channelSid], {
						...getMember(state.channels[action.channelSid].members, action.userId),
						...{
							lastConsumedMessageIndex: action.index,
							lastConsumptionTimestamp: action.timestamp,
						},
					}),
				),
			})
		case 'UPDATE_MEMBER':
			return merge({
				channels: mergeChannel(state.channels, mergeChannelMember(state.channels[action.channel.sid], action.member)),
			})
		case 'CREATE_CHANNEL':
			return merge({
				channels: mergeChannel(state.channels, action.channel),
			})
		case 'FINISH_CREATE_CHANNEL':
			if (action.serverError) {
				return state
			}
			return merge({
				channels: mergeChannel(removeChannel(state.channels, action.localSid), action.channel),
				messagesByChannel: removeChannelMessages(state.messagesByChannel, action.localSid),
			})
		case 'RECEIVE_CHANNEL':
			return merge({
				channels: mergeChannel(state.channels, action.channel),
			})
		case 'LOAD_CHAT_USERS':
			return merge({
				usersLoading: true,
			})
		case 'FINISH_LOAD_CHAT_USERS':
			return merge({
				users: mergeChatUserInfo(state.users, action.users),
				usersLoading: false,
			})
		case 'SAVE_UNSENT_MESSAGE':
			return merge({
				channels: mergeChannelUnsentMessage(state.channels, action.channel, action.message),
			})
		default:
			return state
	}
}

export type State = ChatState
