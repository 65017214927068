/* @flow */

import { getInternalUsers } from 'modules/user/selectors'
import { createSelector } from 'reselect'
import type {
	AccountingDocumentContact,
	Currency,
	InternalUser,
	Organization,
	OrganizationAddress,
	OrganizationInternalUser,
	State,
	UserProfile,
	Enumeration,
	FinancialAccount,
	OrganizationReport,
	OrganizationReportVatPayment,
	OrganizationSettingsOpen,
} from 'types'
import { userHasAccess } from '../../../app/permissions'
import { isInternalUsersLoading } from '../../user/selectors'
import type { OrganizationDetailState } from '../reducers'
import { PayerTaxType_PayerTaxName } from 'types/convertor'
import { organizationSettingsOpenSelector } from 'modules/settings/selectors'
import { uniqueObjArray } from 'utils/array'
import type { UserAvatar } from '../types'

export function getCurrentOrganization(state: State): ?Organization {
	return state.organization && state.organization.organizationDetail.data
}

/**
 * Returns internal users from current organization.
 * Sorted by first name.
 */
export const getCurrentOrganizationInternalUsers: (state: State) => ?Array<OrganizationInternalUser> = createSelector(
	getCurrentOrganization,
	(organization: ?Organization): ?Array<OrganizationInternalUser> => {
		const users = organization && organization.internalUsers ? organization.internalUsers.sort(userSorter) : undefined
		return (users && uniqueObjArray(users, 'userId')) || undefined
	},
)

export const currentOrganizationUsersSelectors: (state: State) => ?Array<UserProfile> = createSelector(
	(state: State) => state.organization && state.organization.users && state.organization.users.data,
	(users: ?Array<UserProfile>): ?Array<UserProfile> => {
		return (users && uniqueObjArray(users, 'id')) || undefined
	},
)

/**
 * Returns not internal users from current organization.
 * Sorted by first name.
 */
export const getCurrentOrganizationUsers: (state: State) => ?Array<UserProfile> = createSelector(
	currentOrganizationUsersSelectors,
	(users: ?Array<UserProfile>): ?Array<UserProfile> => {
		if (!users) {
			return users
		}

		return users.filter((user: UserProfile) => user.isInternal !== true).sort(userSorter)
	},
)

/**
 * Returns one organization assigned accountant from current organization.
 */
export const getCurrentOrganizationAccountant: (state: State) => ?UserProfile = createSelector(
	currentOrganizationUsersSelectors,
	(users: ?Array<UserProfile>): ?UserProfile => {
		return (
			users &&
			users.find((user: UserProfile) => {
				return 2 === user.membershipType
			})
		)
	},
)

/**
 * Returns one organization assigned wages accountant from current organization.
 */
export const getCurrentOrganizationWagesAccountant: (state: State) => ?UserProfile = createSelector(
	currentOrganizationUsersSelectors,
	(users: ?Array<UserProfile>): ?UserProfile => {
		return (
			users &&
			users.find((user: UserProfile) => {
				return 4 === user.membershipType
			})
		)
	},
)

/**
 * Returns all users that the user has the right to see.
 * Sorted by first name.
 */
export const getUsers: (state: State) => ?Array<UserProfile | InternalUser | OrganizationInternalUser> = createSelector(
	(state: State) => getCurrentOrganizationUsers(state),
	(state: State) => getCurrentOrganizationInternalUsers(state),
	(state: State) => getInternalUsers(state),
	(state: State) => userHasAccess(state, 'seeInternalUsers'),
	(
		organizationUsers: ?Array<UserProfile>,
		organizationInternalUsers: ?Array<OrganizationInternalUser>,
		internalUsers: ?Array<InternalUser>,
		seeInternalUsers: boolean,
	): ?Array<UserProfile | InternalUser | OrganizationInternalUser> => {
		let users: Array<InternalUser | UserProfile | OrganizationInternalUser> = []

		users = users.concat(organizationUsers || [])

		if (seeInternalUsers) {
			users = users.concat(internalUsers || [])
		} else {
			users = users.concat(organizationInternalUsers || [])
		}

		if (0 === users.length) {
			return undefined
		}

		return users.sort(userSorter)
	},
)

export function isUsersLoading(state: State): boolean {
	return (
		isCurrentOrganizationUsersLoading(state) ||
		(userHasAccess(state, 'seeInternalUsers') && isInternalUsersLoading(state))
	)
}

export function isCurrentOrganizationUsersLoading(state: State): boolean {
	return !!(state.organization && state.organization.users && state.organization.users.loading)
}

export function isCurrentOrganizationInvitationsLoading(state: State): boolean {
	return !!(state.organization && state.organization.invitations && state.organization.invitations.loading)
}

export function getCurrentOrganizationLoading(state: State): boolean {
	const detail: ?OrganizationDetailState = getCurrentOrganizationDetail(state)
	return (detail && detail.loading) || false
}

export function getCurrentOrganizationDetail(state: State): ?OrganizationDetailState {
	return state.organization && state.organization.organizationDetail
}

export function getCurrentOrganizationDomesticCountry(state: State): ?string {
	const organization: ?Organization = getCurrentOrganization(state)
	return organization && organization.domesticCountry
}

export function getCurrentOrganizationAddress(state: State): ?OrganizationAddress {
	const organization: ?Organization = getCurrentOrganization(state)
	return (organization && organization.addresses && organization.addresses[0]) || null
}

export function getCurrentOrganizationADContact(state: State): ?AccountingDocumentContact {
	const organization: ?Organization = getCurrentOrganization(state)
	const address: ?OrganizationAddress = getCurrentOrganizationAddress(state)
	return (
		(organization && {
			// contactId?: string,
			firstname: organization && organization.firstname,
			lastname: organization && organization.lastname,
			companyRegNo: organization && organization.companyRegNo,
			companyName: organization && organization.name,
			taxId: organization && organization.taxId,
			city: (address && address.city) || undefined,
			street: (address && address.street) || undefined,
			country: organization && organization.domesticCountry,
			zipCode: (address && address.zip) || undefined,
			email: undefined, // TODO - email zmizel z typu Organization: (organization && organization.email),
			// externalId?: string,
		}) ||
		null
	)
}

export const getCurrentOrganizationRegNo = createSelector(
	getCurrentOrganization,
	(organization: ?Organization) => organization && organization.companyRegNo,
)

function userSorter<T: InternalUser | UserProfile | OrganizationInternalUser>(user1: T, user2: T): number {
	if ((user1.firstname || '') < (user2.firstname || '')) return -1
	if ((user1.firstname || '') > (user2.firstname || '')) return 1
	return 0
}

export function getOrganizationCurrencies(state: State): ?Array<Currency> {
	return state.organization.currenciesUsedInOrg.data
}

export function getCurrentOrganizationDomesticCurrency(state: State): ?string {
	const currentOrg = getCurrentOrganization(state)
	return currentOrg && currentOrg.domesticCurrency
}

export function getCurrentOrganizationName(state: State): ?string {
	const currentOrg = getCurrentOrganization(state)
	return currentOrg && currentOrg.name
}

export function getOrganizationProjects(state: State): ?Array<Enumeration> {
	return state.organization && state.organization.projects && state.organization.projects.data
}

export function getOrganizationBranches(state: State): ?Array<Enumeration> {
	return state.organization && state.organization.branches && state.organization.branches.data
}

export function getFinancialAccounts(state: State, key: ?string): ?Array<FinancialAccount> {
	return state.organization.financialAccounts.data[key || 'ALL']
}

export function getFinancialAccountsCurrentLoadingKey(state: State): ?string {
	return state.organization.financialAccounts.loadingForKey
}

export function getOrganizationColor(state: State): ?string {
	return (
		state.settings.organizationSettingsOpen &&
		state.settings.organizationSettingsOpen.printing &&
		state.settings.organizationSettingsOpen.printing.appearance &&
		state.settings.organizationSettingsOpen.printing.appearance.baseDecorationColor
	)
}

export function getOrganizationAutomaticApproval(state: State): boolean {
	return !!(
		state.settings.organizationSettings &&
		state.settings.organizationSettings.accountingDocuments &&
		state.settings.organizationSettings.accountingDocuments.upload &&
		state.settings.organizationSettings.accountingDocuments.upload.automaticApproval
	)
}

export function getOrganizationReport(state: State): ?OrganizationReport {
	const detail = getCurrentOrganizationDetail(state)
	return detail && detail.data && detail.data.report
}

export function getOrganizationReportVatPayment(state: State): ?OrganizationReportVatPayment {
	const report = getOrganizationReport(state)
	return report && report.vatPayment
}

export function isCurrentOrganizationTaxPayer(state: State): boolean {
	const currentOrganization = getCurrentOrganization(state)
	return 'VATpayer' === PayerTaxType_PayerTaxName((currentOrganization || {}).payerTaxType)
}

export const isOrganizationEetActiveSelector: (state: State) => boolean = createSelector(
	organizationSettingsOpenSelector,
	(organizationSettingsOpen: ?OrganizationSettingsOpen) => {
		return (
			(organizationSettingsOpen &&
				organizationSettingsOpen.countrySpecificSettings &&
				organizationSettingsOpen.countrySpecificSettings.cz &&
				organizationSettingsOpen.countrySpecificSettings.cz.eet &&
				organizationSettingsOpen.countrySpecificSettings.cz.eet.isActive) ||
			false
		)
	},
)

export const isCashbotEnabledSelector: (state: State) => boolean = createSelector(
	organizationSettingsOpenSelector,
	(organizationSettingsOpen: ?OrganizationSettingsOpen) => {
		return (
			(organizationSettingsOpen &&
				organizationSettingsOpen.features &&
				organizationSettingsOpen.features.integrations &&
				organizationSettingsOpen.features.integrations.cashbot &&
				organizationSettingsOpen.features.integrations.cashbot.enabled) ||
			false
		)
	},
)

export function getOrganizationUserAvatar(state: State, userId: string): ?UserAvatar {
	return (
		state.organization &&
		state.organization.userAvatars &&
		state.organization.userAvatars.find((ua: UserAvatar) => ua.userId === userId)
	)
}
