// @flow

import React, { Component } from 'react'
import type { Enum } from 'types'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import { type ReportScheme, getReportSchemes } from '../../constants/report'
import styles from './report-view.css'

type Props = {|
	...WithTranslateProps,
	branches: ?Enum,
	taxEvidence: boolean,
	onReportClick: (report: ReportScheme) => void,
|}

type State = {
	active: ?ReportScheme,
}

class ReportView extends Component<Props, State> {
	state: State = { active: null }

	isReportVisible = (reportScheme: ReportScheme) => {
		switch (reportScheme.type) {
			case 12:
				return this.props.branches && this.props.branches.length
			default:
				return true
		}
	}

	onReportSchemeClick = (reportScheme: ReportScheme) => {
		return () => {
			this.props.onReportClick(reportScheme)
			this.setState({
				active: reportScheme,
			})
		}
	}

	renderReports = (type: ?string) => {
		const { t } = this.props

		return (
			<div className={styles.container}>
				{getReportSchemes(type).map((reportScheme: ReportScheme) => {
					return this.isReportVisible(reportScheme) ? (
						<div className={styles.item} key={reportScheme.type} onClick={this.onReportSchemeClick(reportScheme)}>
							<div className={styles.name}>{t(`report.${reportScheme.type}`)}</div>
						</div>
					) : null
				})}
			</div>
		)
	}

	render() {
		const { t, taxEvidence } = this.props

		return (
			<div className={styles.root}>
				<div className={styles.headline} style={{ marginTop: '-10px' }}>
					{t('dashboard.widget.genericReports.invoices')}
				</div>
				{this.renderReports('invoices')}
				{taxEvidence ? (
					<div>
						<div className={styles.headline}>{t('dashboard.widget.genericReports.taxRecords')}</div>
						{this.renderReports('taxRecords')}
					</div>
				) : (
					<div>
						<div className={styles.headline}>{t('dashboard.widget.genericReports.accounting')}</div>
						{this.renderReports('accounting')}
					</div>
				)}
				<div className={styles.headline}>{t('dashboard.widget.genericReports.property')}</div>
				{this.renderReports('property')}
				{/* <div className={styles.title}>{t('dashboard.widget.genericReports.title')}</div> */}
				{/* <div className={styles.subheadline}>{t('dashboard.widget.genericReports.subtitle')}</div> */}
				{/* <div className={styles.locked} /> */}
				{/* <div className={styles.locked} /> */}
			</div>
		)
	}
}

export default withTranslate(ReportView)
