/* @flow */

import React, { Component } from 'react'
import type { CropReturnType } from 'components/image-cropper'
import Person from 'components/svg-icons/social/person'
import { autoTestId } from 'utils/tests/autotest'
import styles from './user-profile-avatar.css'
import { colors } from 'variables'

export type Props = {
	name: ?string,
	avatar?: ?string,
	size: number,
	style?: Object,
	crop?: CropReturnType,
	notifications?: number,
	progress?: boolean,
}

type State = {|
	showAvatar: boolean,
|}

export default class UserProfileAvatar extends Component<Props, State> {
	constructor(props: Props) {
		super(props)
		this.state = { showAvatar: false }
	}

	getInitials = (value: string) => {
		const names = value.split(' ')
		return names[0].substring(0, 1) + (names.length > 1 ? names[names.length - 1].substring(0, 1) : '')
	}

	renderBadge() {
		const { notifications, progress } = this.props

		if (!notifications || notifications < 1) return null

		return (
			<div className={progress ? styles.badgeInProgress : styles.badge}>
				<div className={styles.progress}>
					<div className={styles.innerProgress} />
				</div>
				<span>{notifications}</span>
			</div>
		)
	}

	render() {
		const { name, avatar, size, crop, notifications } = this.props
		const sizes = (crop && crop.px) || {}
		const percent = (crop && crop.percent) || {}

		const height = sizes.originalHeight && sizes.height ? (sizes.originalHeight / sizes.height) * size : size
		const width = sizes.originalWidth && sizes.width ? (sizes.originalWidth / sizes.width) * size : size

		const x = crop ? (width / 100) * percent.x : 0
		const y = crop ? (height / 100) * percent.y : 0

		const cropStyles: Object = {
			height: `${height}px`,
			width: `${width}px`,
			transform: crop && `translateX(${-x}px) translateY(${-y}px)`,
		}

		return (
			<div
				className={styles.userAvatar}
				style={{
					lineHeight: `${size}px`,
					fontSize: size / 3,
					height: `${size}px`,
					width: `${size}px`,
					overflow: notifications && notifications > 0 ? 'visible' : 'hidden',
					...this.props.style,
				}}
				{...autoTestId('user-profile-avatar')}
			>
				{this.renderBadge()}
				{/* Always render initials or icon (avatar loading or 404) */}
				{name ? (
					this.getInitials(name)
				) : (
					<Person
						size={Math.round(size * 0.7)}
						style={style.personIcon}
						color={colors.white}
						hoverColor={colors.white}
					/>
				)}

				{avatar && (
					<img
						onLoad={this.onImgLoad}
						onError={this.onImgError}
						className={this.state.showAvatar ? styles.avatarImage : styles.hiddenAvatarImage}
						style={{ ...cropStyles }}
						src={avatar}
					/>
				)}
			</div>
		)
	}

	onImgError = () => {
		this.setState({ showAvatar: false })
	}

	onImgLoad = () => {
		this.setState({ showAvatar: true })
	}
}

const style = {
	personIcon: {
		verticalAlign: 'middle',
		position: 'relative',
		top: -1,
		left: 0,
	},
}
