/* @flow */

import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import YearButton from './year-button'
import { colors } from 'variables'

export type Props = {|
	DateTimeFormat: (string, Object) => ?string,
	locale: string,
	maxDate: Date,
	minDate: Date,
	selectedDate: ?Date,
	utils: Object,
	onClickYear?: number => void,
|}

const styles = {
	root: {
		backgroundColor: colors.white,
		height: 'inherit',
		lineHeight: '35px',
		overflowX: 'hidden',
		overflowY: 'scroll',
		position: 'relative',
	},
	child: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		minHeight: '100%',
	},
}

class CalendarYear extends Component<Props> {
	componentDidMount() {
		this.scrollToSelectedYear()
	}

	componentDidUpdate() {
		this.scrollToSelectedYear()
	}

	getYears() {
		const { DateTimeFormat, locale, minDate, maxDate, selectedDate, utils } = this.props

		const minYear = utils.getYear(minDate)
		const maxYear = utils.getYear(maxDate)
		const years = []

		for (let year = minYear; year <= maxYear; year++) {
			const selected = utils.getYear(selectedDate) === year
			const selectedProps = {}
			if (selected) selectedProps.ref = 'selectedYearButton'

			const yearFormated = new DateTimeFormat(locale, {
				year: 'numeric',
			}).format(utils.setYear(selectedDate, year))

			const yearButton = (
				<YearButton
					key={`yb${year}`}
					onClick={this.handleClickYear}
					selected={selected}
					year={year}
					utils={utils}
					{...selectedProps}
				>
					{yearFormated}
				</YearButton>
			)

			years.push(yearButton)
		}

		return years
	}

	scrollToSelectedYear() {
		if (this.refs.selectedYearButton === undefined) return

		const container = ReactDOM.findDOMNode(this)
		const yearButtonNode = ReactDOM.findDOMNode(this.refs.selectedYearButton)

		if (container instanceof HTMLElement && yearButtonNode instanceof HTMLElement) {
			const containerHeight = container.clientHeight
			const yearButtonNodeHeight = yearButtonNode.clientHeight || 32

			const scrollYOffset = yearButtonNode.offsetTop + yearButtonNodeHeight / 2 - containerHeight / 2
			container.scrollTop = scrollYOffset
		}
	}

	handleClickYear = (year: number) => {
		this.props.onClickYear && this.props.onClickYear(year)
	}

	render() {
		return (
			<div style={styles.root}>
				<div style={styles.child}>{this.getYears()}</div>
			</div>
		)
	}
}

export default CalendarYear
