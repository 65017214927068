/* @flow */

import React, { Component } from 'react'
import Checkbox from 'components/checkbox'
import Tooltip from 'components/tooltip'

export type Props<RowData> = {|
	id: string,
	rowData: RowData,
	checked: boolean,
	disabled?: boolean,
	selectTooltip?: string,
	deselectTooltip?: string,
	onCheck?: (id: string, rowData: RowData, value: boolean) => Promise<void> | void,
|}

class DataGridCheckbox<RowData: any> extends Component<Props<RowData>> {
	handleCheck = (event: SyntheticInputEvent<HTMLInputElement>, checked: boolean) => {
		this.props.onCheck && this.props.onCheck(this.props.id, this.props.rowData, checked)
	}

	render() {
		return (
			<Tooltip
				label={this.props.checked ? this.props.deselectTooltip : this.props.selectTooltip}
				wrapperStyle={style.wrapper}
				style={style.tooltip}
				disabled={this.props.disabled}
			>
				<Checkbox disabled={this.props.disabled} checked={this.props.checked} onCheck={this.handleCheck} />
			</Tooltip>
		)
	}
}

const style = {
	tooltip: { marginTop: 5 },
	wrapper: { width: 24 },
}

export default DataGridCheckbox
