/* @flow */

import React, { Component } from 'react'
import Controls, { type ControlProps, type ControlRotateProps, type ControlScaleProps } from './controls'
import colors from 'variables/colors.css' //eslint-disable-line

type Props = {|
	src: string,
	overlay?: React$Element<*>,
	readonly?: boolean,
	stretch?: boolean,
	style?: Object,
	fileName?: ?string,
|}

type State = {
	angle: number,
	scale: number,
	dragging: boolean,
	start: {
		x: number,
		y: number,
	},
	offset: {
		x: number,
		y: number,
	},
	container: {
		x: number,
		y: number,
	},
}

class ImageViewer extends Component<Props, State> {
	state = {
		angle: 0,
		scale: 1,
		dragging: false,
		offset: {
			x: 0,
			y: 0,
		},
		start: {
			x: 0,
			y: 0,
		},
		container: {
			x: 0,
			y: 0,
		},
	}

	componentDidMount() {
		!this.props.readonly && document.addEventListener('mousemove', this.handleMouseMove)
	}

	componentWillUnmount() {
		document.removeEventListener('mousemove', this.handleMouseMove)
		document.removeEventListener('mouseup', this.handleMouseEnd)
	}

	handleMouseDown = (event: MouseEvent) => {
		if (this.props.readonly) return
		document.addEventListener('mouseup', this.handleMouseEnd)
		event.preventDefault()
		this.setState({
			dragging: true,
			start: {
				x: event.clientX,
				y: event.clientY,
			},
			offset: {
				x: this.state.container.x,
				y: this.state.container.y,
			},
		})
	}

	handleMouseEnd = () => {
		document.removeEventListener('mouseup', this.handleMouseEnd)
		this.setState({
			dragging: false,
		})
	}

	handleMouseMove = (event: MouseEvent) => {
		if (this.state.dragging) {
			this.setState({
				container: {
					x: this.state.offset.x + event.clientX - this.state.start.x,
					y: this.state.offset.y + event.clientY - this.state.start.y,
				},
			})
		}
	}

	isManipulated = (): boolean => {
		return (
			this.state.angle !== 0 || this.state.scale !== 1 || this.state.container.x !== 0 || this.state.container.y !== 0
		)
	}

	render() {
		const styles = getStyles(this.props, this.state)

		return (
			<div style={{ ...styles.root, ...this.props.style }}>
				<div style={styles.imageContainer}>
					<div style={styles.grabber}>
						<div style={styles.scaler} onMouseDown={this.handleMouseDown}>
							<div style={styles.rotator}>
								<img src={this.props.src} style={styles.image} alt="Image" />
								{this.props.overlay}
							</div>
						</div>
					</div>
				</div>
				{!this.props.readonly && (
					<div style={styles.controls}>
						<Controls
							fileName={this.props.fileName}
							downloadUrl={this.props.src}
							showReset={this.isManipulated()}
							onControl={this.onControl}
						/>
					</div>
				)}
			</div>
		)
	}

	onControl = (props: ControlProps) => {
		switch (props.type) {
			case 'reset':
				this.setState({ angle: 0, scale: 1, container: { x: 0, y: 0 } })
				break
			case 'rotate':
				this.setState({ angle: processRotate(this.state.angle, props) })
				break
			case 'scale':
				this.setState({ scale: processScale(this.state.scale, props) })
		}
	}
}

function getStyles(props: Props, state: State) {
	return {
		root: {
			position: 'relative',
			background: colors.white,
			display: 'block',
			overflow: 'hidden',
		},
		controls: {
			height: 40,
			position: 'absolute',
			top: 0,
			left: 0,
			right: 0,
			background: colors.white,
		},
		imageContainer: {
			position: 'relative',
			paddingTop: props.readonly ? 0 : 40,
		},
		grabber: {
			width: '100%',
			maxWidth: '100%',
			height: 'auto',
			maxHeight: '100%',
			position: 'relative',
			cursor: props.readonly ? 'inherit' : state.dragging ? 'grabbing' : 'grab',
			top: state.container.y,
			left: state.container.x,
		},
		scaler: {
			display: 'block',
			width: '100%',
			height: 'auto',
			transform: `scale(${state.scale || 1})`,
			transition: 'transform 100ms ease-in-out',
			transformOrigin: 'center center',
		},
		rotator: {
			display: 'block',
			width: '100%',
			height: 'auto',
			outlineOffset: '-1px',
			outline: props.readonly ? 'none' : `1px solid ${colors.gray400}`,
			transform: `rotate(${state.angle || 0}deg)`,
			transition: 'all 100ms ease-in-out',
			transformOrigin: 'center center',
		},
		image: {
			display: 'block',
			width: props.stretch ? '100%' : 'auto',
			height: 'auto',
			margin: props.stretch ? 0 : '0 auto',
			maxWidth: '100%',
			maxHeight: props.readonly ? '100%' : '100%',
		},
	}
}

export default ImageViewer

function processRotate(angle: number, controlProps: ControlRotateProps): number {
	if ('left' === controlProps.dir) {
		return angle - 90
	} else {
		return angle + 90
	}
}

function processScale(scale: number, controlProps: ControlScaleProps): number {
	if ('in' === controlProps.dir) {
		return scale + 0.25
	} else {
		return scale - 0.25
	}
}
