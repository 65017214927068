import React from 'react'
import pure from 'recompose/pure'
import SvgIcon from 'components/SvgIcon'

let Trivi = props => (
	<SvgIcon {...props}>
		<g fill="#FEFEFE" fillRule="evenodd">
			<path d="M11.853 22.774c-5.792 0-10.487-4.718-10.487-10.538S6.061 1.698 11.853 1.698 22.34 6.416 22.34 12.236s-4.695 10.538-10.487 10.538m0-22.449C5.317.325 0 5.668 0 12.235c0 6.569 5.317 11.912 11.853 11.912s11.853-5.343 11.853-11.911S18.39.326 11.853.326" />
			<path d="M8.727 9.805h2.42v6.282h1.413V9.805h2.42v-1.42H8.726z" />
		</g>
	</SvgIcon>
)

Trivi = pure(Trivi)

export default Trivi
