/* @flow */

import * as t from '../types'
import { authApiHandler } from 'lib/apiHandler'

export const token = {
	async post(body: t.TokenRequest): Promise<t.AccessToken> {
		return authApiHandler('auth/token').post(null, body)
	},
}

export const impersonateToken = {
	async post(body: t.ImpersonateTokenRequest): Promise<t.AccessToken> {
		return authApiHandler('auth/impersonate/token').post(null, body)
	},
}
