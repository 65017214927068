/* @flow */
/* eslint-disable react/no-multi-comp */

import React from 'react'
import moize from 'moize'
import type { AccountingDocument } from 'types'
import { type WithTranslateProps } from 'wrappers'
import { Tooltip, Avatar } from 'components'
import { Column } from 'modules/data-grid-next/components'
import type { Direction } from 'modules/data-grid-next/types'
import { DATA_GRID_CACHE_SIZE } from 'trivi-constants'
import { colors } from 'variables'

export type Props = {|
	width?: number | string,
	defaultSortDirection?: Direction,
	...WithTranslateProps,
|}

export default function getAuthorColumn(props: Props) {
	function columnRenderer(value: number, row: AccountingDocument) {
		const author = row.authorName
		const names = String(author).split(' ')
		const initials = names[0].substring(0, 1) + (names.length > 1 ? names[names.length - 1].substring(0, 1) : '')
		return (
			<span>
				{author && (
					<Tooltip label={author != null ? author.toString() : ''} style={style.tooltip} inline>
						<Avatar
							color={colors.grey}
							backgroundColor={colors.white}
							size={20}
							borderColor={colors.grey}
							borderWidth={2}
							style={style.avatar}
						>
							{initials}
						</Avatar>
					</Tooltip>
				)}
			</span>
		)
	}

	const columnRendererCashed = moize(columnRenderer, { maxSize: DATA_GRID_CACHE_SIZE })

	return (
		<Column
			title={props.t('accountingDocument.columns.author')}
			width={props.width}
			columnId="authorName"
			key="authorName"
			render={columnRendererCashed}
			sortable
			defaultSortDirection={props.defaultSortDirection || undefined}
		/>
	)
}

const style = {
	tooltip: {
		marginLeft: 2,
	},
	avatar: {
		marginLeft: 5,
		position: 'relative',
		top: -2,
		lineHeight: '20px',
	},
}
