/* @flow */
/** @jsx jsx */

import { type Node, Component } from 'react'
import { jsx } from '@emotion/core'
import { colors } from 'variables'
import { TAB_POSITIONS } from './Tabs'

type Props = {|
	className?: string,
	children?: Node,
	label: Node,
	selected?: boolean,
	style?: Object,
	shrink?: boolean,
	large?: boolean,
	right?: boolean,
	tall?: boolean,
	disabled?: boolean,
	value: void | number | string,
	width?: number | string,
	/**
	 * This property is for new design for dashboard TWU-2665, which will use version "2"
	 */
	designVersion?: string,
	/**
	 * This property is for new design for dashboard TWU-2665, which will use version "2"
	 * setted up by Tabs
	 */
	positionInTabs?: string,
	onActive?: () => void,
	onClick?: (void | number | string, SyntheticEvent<HTMLElement>, Tab) => void,
|}

function getStyles(props: Props) {
	const { width, selected, large, right, shrink, tall, disabled, designVersion = '1', positionInTabs } = props

	const styles = {
		root: {
			display: 'inline-block',
			width: width,
			marginRight: right ? 0 : 24,
			float: right ? 'right' : 'none',
			marginBottom: large ? -1 : 0,
			pointerEvents: disabled || selected ? 'none' : 'all',
		},
		container: {
			color: !disabled && selected && !large ? colors.blue : colors.black,
			fontWeight: 'normal',
			fontSize: large ? 24 : 12,
			width: width,
			textTransform: large ? 'none' : 'uppercase',
			border: 'none',
			padding: 0,
			margin: 0,
			whiteSpace: 'nowrap',
			'&:hover': {
				color: colors.blackFaded80,
			},
		},
		button: {
			cursor: disabled ? 'default' : 'pointer',
			position: 'relative',
			zIndex: 1,
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'center',
			height: large ? 48 : 38,
			borderBottom: selected && !disabled ? `3px solid ${colors.blue}` : '3px solid transparent',
		},
	}

	if (large) {
		styles.root.marginRight = right ? 0 : 50
		styles.container.fontWeight = selected ? 'bold' : 'normal'
	}

	if (tall) {
		styles.button.height = large ? 76 : 60
	}

	if (shrink && large) {
		styles.root.marginRight = right ? 0 : 16
	}

	if (designVersion === '2') {
		styles.root = {
			...styles.root,
			marginRight: positionInTabs === TAB_POSITIONS.single || positionInTabs === TAB_POSITIONS.last ? 0 : 1,
			boxShadow:
				positionInTabs === TAB_POSITIONS.single || positionInTabs === TAB_POSITIONS.last
					? 'none'
					: '1px 0px 0px 0px #c6c6c6',
			paddingLeft: 10,
			paddingRight: 10,
			cursor: selected ? 'default' : 'pointer',
			flexBasis: 0,
			flexGrow: 1,
			textAlign: 'center',
			backgroundColor: selected && !disabled ? 'white' : '#ececec',
			borderTop: selected && !disabled ? `5px solid ${colors.blue}` : '5px solid transparent',
		}

		styles.container = {
			...styles.container,
			color: !disabled && selected ? `${colors.blue} !important` : colors.black,
			fontSize: 20,
			fontWeight: selected ? 'bold' : 'normal',
			svg: {
				fill: !disabled && selected ? `${colors.blue} !important` : colors.black,
			},
		}

		styles.button = {
			...styles.button,
			height: 55,
			borderBottom: 'none',
		}
	}

	return styles
}

class Tab extends Component<Props> {
	handleClick = (event: SyntheticEvent<HTMLElement>) => {
		!this.props.disabled && this.props.onClick && this.props.onClick(this.props.value, event, this)
	}

	render() {
		const { style } = this.props

		const styles = getStyles(this.props)

		return (
			<div css={{ ...styles.root, ...style }} onClick={this.handleClick}>
				<div css={{ ...styles.container, ...style }}>
					<div css={styles.button}>{this.props.label}</div>
				</div>
			</div>
		)
	}
}

export default Tab
