/* @flow */

import type { Dispatch, PaymentReminder, State } from 'types'
import {
	paymentReminder as paymentReminderApi,
	paymentReminders as paymentRemindersApi,
} from 'modules/common/models/api-model'

import type { Action as PaymentRemindersAction } from 'modules/settings/actions/payment-reminders-action-types'

export function loadPaymentReminders() {
	return async (dispatch: Dispatch<PaymentRemindersAction>) => {
		dispatch({
			type: 'START_LOADING_PAYMENT_REMINDERS',
		})
		try {
			const paymentReminders: Array<PaymentReminder> = await paymentRemindersApi.get({})
			dispatch({
				type: 'FINISH_LOADING_PAYMENT_REMINDERS',
				paymentReminders,
			})
		} catch (serverError) {
			dispatch({
				type: 'FINISH_LOADING_PAYMENT_REMINDERS',
				serverError,
			})
		}
	}
}

export function createPaymentReminder(paymentReminder: PaymentReminder) {
	return async (dispatch: Dispatch<PaymentRemindersAction>) => {
		dispatch({
			type: 'START_CREATING_PAYMENT_REMINDER',
		})
		try {
			const resp: PaymentReminder = await paymentRemindersApi.post(paymentReminder)
			dispatch({
				type: 'FINISH_CREATING_PAYMENT_REMINDER',
				paymentReminder: resp,
			})
		} catch (serverError) {
			dispatch({
				type: 'FINISH_CREATING_PAYMENT_REMINDER',
				serverError,
			})
		}
	}
}

export function deletePaymentReminder(paymentReminderId: string) {
	return async (dispatch: Dispatch<PaymentRemindersAction>, getState: () => State) => {
		const state: State = getState()
		const oldReminders: ?Array<PaymentReminder> = state.settings.paymentReminders
		const newReminders: ?Array<PaymentReminder> = (oldReminders || []).filter(
			(r: PaymentReminder) => r.id !== paymentReminderId,
		)
		dispatch({
			type: 'START_DELETING_PAYMENT_REMINDER',
			newReminders,
		})
		try {
			await paymentReminderApi.delete({ paymentReminderId })
			dispatch({
				type: 'FINISH_DELETING_PAYMENT_REMINDER',
				newReminders,
			})
		} catch (serverError) {
			dispatch({
				type: 'FINISH_DELETING_PAYMENT_REMINDER',
				serverError,
				oldReminders,
			})
		}
	}
}

export function updatePaymentReminder(paymentReminderId: string, paymentReminder: PaymentReminder) {
	return async (dispatch: Dispatch<PaymentRemindersAction>, getState: () => State) => {
		const state: State = getState()
		const oldReminders: ?Array<PaymentReminder> = state.settings.paymentReminders
		const newReminders: ?Array<PaymentReminder> = (oldReminders || []).map((r: PaymentReminder) => {
			return r.id !== paymentReminderId ? r : paymentReminder
		})
		dispatch({
			type: 'START_UPDATING_PAYMENT_REMINDER',
			newReminders,
		})
		try {
			await paymentReminderApi.put({ paymentReminderId }, paymentReminder)
			dispatch({
				type: 'FINISH_UPDATING_PAYMENT_REMINDER',
				newReminders,
			})
		} catch (serverError) {
			dispatch({
				type: 'FINISH_UPDATING_PAYMENT_REMINDER',
				serverError,
				oldReminders,
			})
		}
	}
}
