import React from 'react'
import pure from 'recompose/pure'
import SvgIcon from '../../SvgIcon'

let ActionLockClose = props => (
	<SvgIcon {...props}>
		<path d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zM9 6c0-1.66 1.34-3 3-3s3 1.34 3 3v2H9V6zm9 14H6V10h12v10zm-6-3c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2z" />
	</SvgIcon>
)
ActionLockClose = pure(ActionLockClose)
ActionLockClose.displayName = 'ActionLockClose'
ActionLockClose.componentName = 'SvgIcon'

export default ActionLockClose
