/* @flow */

import { organizationReport as organizationReportApi } from 'modules/common/models/api-model'
import type { DashboardAction, Dispatch } from 'types'

export function loadOrganizationReport() {
	return async (dispatch: Dispatch<DashboardAction>) => {
		dispatch({
			type: 'START_LOAD_ORGANIZATION_REPORT',
		})
		try {
			const organizationReport = await organizationReportApi.get({})

			return dispatch({
				type: 'FINISH_LOAD_ORGANIZATION_REPORT',
				data: organizationReport,
			})
		} catch (error) {
			dispatch({
				type: 'FINISH_LOAD_ORGANIZATION_REPORT',
				serverError: error,
			})
		}
	}
}
