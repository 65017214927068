/* @flow */
/** @jsx jsx */

import { PureComponent } from 'react'
import { jsx } from '@emotion/core'
import memoize from 'memoize-one'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import type { AccountingDocumentContact } from 'types'
import { getUserDisplayName } from 'modules/user/user/user'
import ContactSelectStar from './contact-select-star'
import { colors } from 'variables'

type Props = {|
	...WithTranslateProps,
	index: number,
	contact: AccountingDocumentContact,
	isFavorite: boolean,
	onClick?: any => void,
	onFavorite: (any, boolean) => void,
|}

class ContactSelectItem extends PureComponent<Props> {
	onClick = () => {
		this.props.onClick && this.props.onClick(this.props.contact)
	}

	getStyles = memoize((isFavorite: boolean) => {
		return {
			hidden: {
				display: 'none',
			},
			div: {
				cursor: 'pointer',
			},
			line: {
				padding: '5px 15px 5px 40px',
				lineHeight: '20px',
				backgroundColor: isFavorite ? colors.grey200 : colors.white,
				cursor: 'pointer',
				verticalAlign: 'baseline',
				position: 'relative',
				'&:hover': {
					backgroundColor: isFavorite ? colors.grey300 : colors.blueFaded10,
					'#star': {
						opacity: 1,
					},
				},
			},
			star: {
				display: 'block',
				position: 'absolute',
				top: -6,
				left: 0,
				opacity: isFavorite ? 1 : 0,
			},
			person: {
				fontWeight: 100,
				flex: '1 1 auto',
				marginLeft: 10,
				fontSize: 10,
				color: 'rgba(0, 0, 0, 0.6)',
				overflow: 'hidden',
				textAlign: 'right',
				textOverflow: 'ellipsis',
				whiteSpace: 'nowrap',
			},
			lineSpan: {
				overflow: 'hidden',
				whiteSpace: 'nowrap',
				textOverflow: 'ellipsis',
				display: 'flex',
				alignItems: 'center',
				paddingRight: 5,
			},
		}
	})

	render() {
		const { t, contact } = this.props
		const style = this.getStyles(this.props.isFavorite)

		return (
			<div>
				{' '}
				<div css={style.div} onClick={this.onClick}>
					<div css={style.line}>
						<span css={style.star} id={'star'}>
							<ContactSelectStar
								onFavorite={this.props.onFavorite}
								isFavorite={this.props.isFavorite}
								contact={this.props.contact}
							/>
						</span>
						<span css={style.lineSpan}>
							{contact.companyName || getUserDisplayName(contact.firstname, contact.lastname, contact.email)}
							{contact.companyRegNo && (
								<span css={style.person}>{`${t('invoice.contact.companyRegNoLabel')}: ${contact.companyRegNo ||
									''}`}</span>
							)}
						</span>
					</div>
				</div>
			</div>
		)
	}
}

export default withTranslate(ContactSelectItem)
