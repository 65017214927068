//@flow
import React, { Component } from 'react'
import {
	withTranslate,
	withOrganizationSettings,
	type WithTranslateProps,
	type WithOrganizationSettingsProps,
} from 'wrappers'
import styles from '../settings.css'
import Button from 'components/button'
import DeleteDialog from 'components/delete-dialog'
import ConfirmDialog from 'components/confirm-dialog/confirm-dialog-container'

export type Props = {|
	...WithOrganizationSettingsProps,
	...WithTranslateProps,
	organizationId: string,
	removeOrganization: (organizationId?: string) => Promise<any>,
|}

type ComponentState = {|
	processing: boolean,
|}

class OrganizationEdit extends Component<Props, ComponentState> {
	state = { processing: false }

	removeHandler = () => {
		const { t } = this.props
		try {
			DeleteDialog().then(async () => {
				await ConfirmDialog(t('dialogs.deleteOrganizationQuestion'), {
					okLabel: t('dialogs.yesOption'),
					cancelLabel: t('dialogs.noOption'),
					waitForConfirm: true,
				})
				this.setState({ processing: true })
				this.props.removeOrganization(this.props.organizationId).then(() => {
					this.setState({ processing: false })
				})
			})
		} catch (e) {
			// Do nothing
		}
	}

	render() {
		const { t } = this.props
		return (
			<div className={styles.row}>
				{this.state.processing ? (
					<span>{t('settings.administration.removeProcessing')}</span>
				) : (
					<Button
						autoTestId="settings-services-remove"
						labelText={t('settings.administration.remove')}
						onClick={this.removeHandler}
					/>
				)}
			</div>
		)
	}
}

export default withTranslate(withOrganizationSettings(OrganizationEdit))
