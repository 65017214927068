import React from 'react'
import SvgIcon from 'components/SvgIcon'
import pure from 'recompose/pure'

let Address = props => (
	<SvgIcon {...props}>
		<g stroke="none" strokeWidth="1" fill="#666666" fillRule="evenodd">
			<path d="M7.8359375,4.796875 C9.05990195,3.59895234 10.5312414,3 12.25,3 C13.9687586,3 15.440098,3.59895234 16.6640625,4.796875 C17.888027,5.99479766 18.5,7.4400957 18.5,9.1328125 C18.5,10.4869859 17.9791719,12.1145738 16.9375,14.015625 C15.8958281,15.9166762 14.8541719,17.4791605 13.8125,18.703125 L12.25,20.5 C12.0677074,20.3177074 11.8398451,20.0638037 11.5664062,19.7382812 C11.2929674,19.4127588 10.7981807,18.7812547 10.0820312,17.84375 C9.36588184,16.9062453 8.73437773,15.9947961 8.1875,15.109375 C7.64062227,14.2239539 7.1393252,13.2213598 6.68359375,12.1015625 C6.2278623,10.9817652 6,9.9921918 6,9.1328125 C6,7.4400957 6.61197305,5.99479766 7.8359375,4.796875 Z M10.6484375,10.6953125 C11.091148,11.1119812 11.6249969,11.3203125 12.25,11.3203125 C12.8750031,11.3203125 13.4023416,11.1054709 13.8320312,10.6757812 C14.2617209,10.2460916 14.4765625,9.73177383 14.4765625,9.1328125 C14.4765625,8.53385117 14.2617209,8.0195334 13.8320312,7.58984375 C13.4023416,7.1601541 12.8750031,6.9453125 12.25,6.9453125 C11.6249969,6.9453125 11.0976584,7.1601541 10.6679688,7.58984375 C10.2382791,8.0195334 10.0234375,8.53385117 10.0234375,9.1328125 C10.0234375,9.73177383 10.2317688,10.252602 10.6484375,10.6953125 Z" />
		</g>
	</SvgIcon>
)
Address = pure(Address)

export default Address
