// @flow
/** @jsx jsx */

import { Component } from 'react'
import { jsx } from '@emotion/core'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import { formatToMoney } from 'utils/formatters'
import type { CashbotOffer } from 'modules/cashbot/types'
import Button from 'components/button'

type Props = {|
	...WithTranslateProps,
	offer: CashbotOffer,
	onOfferClick?: (offer: CashbotOffer) => void,
|}

class CashbotOfferPanel extends Component<Props> {
	onClick = () => {
		this.props.onOfferClick && this.props.onOfferClick(this.props.offer)
	}

	render() {
		const currency = this.props.offer.currency
		const percentage = this.props.offer.financedAmountPercent * 100
		const amount = formatToMoney(this.props.offer.financedAmount, { currency })
		const debt = formatToMoney(this.props.offer.financedAmount, { currency })
		const fee = formatToMoney(this.props.offer.totalFeeAmount, { currency })
		const { t } = this.props
		return (
			<div css={style.root}>
				<div css={style.head}>{t('cashbot.offer.headline', { percentage })}</div>
				<div css={style.details}>
					<div css={style.detail}>
						<span>{t('cashbot.offer.when')}</span>
						<span>{amount}</span>
					</div>
					<div css={style.detail}>
						<span>{t('cashbot.offer.debt')}</span>
						<span>{debt}</span>
					</div>
					<div css={style.detail}>
						<span>{t('cashbot.offer.fee')}</span>
						<span>{fee}</span>
					</div>
				</div>
				<div css={style.button}>
					<Button
						autoTestId="cashbot-offer-button"
						labelText={t('cashbot.offer.button')}
						onClick={this.onClick}
						primary
					/>
				</div>
			</div>
		)
	}
}

const style = {
	root: {
		flex: '0 1 50%',
		marginBottom: '3%',
		marginLeft: 12,
		marginRight: 12,
		borderRadius: '5px 5px 0 0',
		borderWidth: 1,
		borderStyle: 'solid',
		borderColor: '#DBDBDB',
		paddingBottom: 25,
		overflow: 'hidden',
	},
	head: {
		background: '#F8F8F8',
		lineHeight: '68px',
		fontWeight: 'bold',
		fontSize: 25,
		height: 68,
	},
	details: {
		padding: '35px 16px 40px 16px',
		lineHeight: '20px',
		fontSize: 16,
	},
	detail: {
		display: 'flex',
		justifyContent: 'space-between',
		padding: '3px 0',
	},
	button: {
		textAlign: 'center',
	},
}

export default withTranslate(CashbotOfferPanel)
