/* @flow */

import { connect } from 'react-redux'
import type { Contact, Dispatch, State, AddressBookAction, AccountingDocumentReduxAction, EmailTemplate } from 'types'
import { getAccountingDocumentContact } from '../../accounting-document/selectors'
import { loadContact } from 'modules/address-book/actions'
import SendReminderEmailDialog from '../components/dialogs/send-reminder-email-dialog'
import { getContact } from '../../address-book/selectors'

type OwnProps = {|
	open: boolean,
	accountingDocumentId: ?string,
	onClose: () => void,
	onSend: (emails: Array<string>, templateId: number) => void,
|}

type StateProps = {|
	contactId: ?string,
	contact: ?Contact,
	emailTemplates: ?Array<EmailTemplate>,
|}

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => {
	const contact = ownProps.accountingDocumentId
		? getAccountingDocumentContact(state, ownProps.accountingDocumentId)
		: undefined
	return {
		contact: contact && contact.contactId ? getContact(state, contact.contactId) : null,
		contactId: contact && contact.contactId,
		emailTemplates: state.settings.emailTemplates,
	}
}

type DispatchProps = {|
	loadContact: (contactId: string) => void,
|}

const mapDispatchToProps = (dispatch: Dispatch<AddressBookAction | AccountingDocumentReduxAction>): DispatchProps => {
	return {
		loadContact: (contactId: string) => {
			dispatch(loadContact(contactId))
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(SendReminderEmailDialog)
