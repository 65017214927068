/* @flow */
/** @jsx jsx */

import { jsx } from '@emotion/core'
import CheckIcon from 'components/svg-icons/trivi/check'
import Star from './star'
import { colors } from 'variables'

type Props = {
	innerRef: string,
	innerProps: Object,
	isDisabled: boolean,
	isSelected: boolean,
	isFocused: boolean,
	label: string,
	data: FavoriteOptionType,
}

export type FavoriteOptionType = {
	value: ?string,
	label: ?string,
	labelPrefix?: ?string,
	rightText?: ?string,
	isFavorite?: boolean,
	isHinted?: boolean,
	isFirst?: boolean,
	isLast?: boolean,
	disabled?: boolean,
	level?: number,
	isCategory?: boolean,
	__isNew__?: boolean,
	onFavorite: (value: ?string, isFavorite: boolean) => void,
}

export default function FavoriteOption(props: Props) {
	function onClick(event: SyntheticEvent<HTMLElement>) {
		event.stopPropagation()
		const newFavorite = !props.data.isFavorite
		props.data.onFavorite(props.data.value, newFavorite)
	}

	const styles = getStyles(
		props.isSelected,
		props.isFocused,
		props.data.isFavorite,
		props.data.isCategory,
		props.data.isHinted,
		props.data.isFirst,
		props.data.isLast,
		props.data.disabled,
		props.data.level,
		props.data.__isNew__,
	)

	return !props.isDisabled ? (
		<div
			id={props.innerProps.id}
			onClick={props.innerProps.onClick}
			tabIndex={props.innerProps.tabIndex}
			ref={props.innerRef}
			data-hinted={props.data.isHinted}
			css={props.data.isHinted ? styles.hintedOption : props.data.isFavorite ? styles.favoriteOption : styles.option}
		>
			<span css={styles.star} id={'star'}>
				{!props.data.isCategory && !props.data.isHinted && (
					<Star
						key={`${props.innerProps.id}-${props.data.isFavorite ? 'favorite' : 'not-favorite'}`}
						onClick={onClick}
						isFavorite={props.data.isFavorite}
					/>
				)}
			</span>
			{props.data.labelPrefix && <span css={styles.prefix}>{props.data.labelPrefix}</span>}
			<span css={styles.label}>{props.label}</span>
			{props.data.rightText && <span css={styles.rightText}>{props.data.rightText}</span>}
			{props.isSelected && (
				<span css={styles.check}>
					<CheckIcon disabled color={colors.blue} />
				</span>
			)}
		</div>
	) : null
}

function getStyles(
	isSelected: boolean,
	isFocused: boolean,
	isFavorite?: boolean,
	isCategory: ?boolean,
	isHinted: ?boolean,
	isFirst: ?boolean,
	isLast: ?boolean,
	disabled: ?boolean,
	level: number = 0,
	isNew: ?boolean,
) {
	const commonOption = {
		position: 'relative',
		outline: 'none',
		cursor: 'pointer',
		paddingLeft: 6 + level * 12,
		paddingRight: 16,
		lineHeight: '30px',
		fontSize: 14,
		color: isSelected ? colors.blue : colors.black,
		textOverflow: 'ellipsis',
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'flex-start',
		pointerEvents: disabled ? 'none' : 'all',
		'&:hover': {
			backgroundColor: colors.blueFaded10,
			'#star': {
				opacity: 1,
			},
		},
	}

	return {
		option: {
			...commonOption,
			backgroundColor: isFocused && !disabled ? colors.blueFaded10 : colors.white,
			fontWeight: isCategory ? 700 : 'normal',
		},
		hintedOption: {
			...commonOption,
			color: isSelected ? colors.white : colors.white,
			backgroundColor:
				isFocused && !disabled && !isSelected
					? colors.navy400Faded80
					: isSelected
					? colors.navy400Faded80
					: colors.navy400,
			marginBottom: isLast ? 7 : 0,
			marginTop: isFirst ? 40 : 0,
			'&:hover': {
				...commonOption['&:hover'],
				backgroundColor: colors.navy400Faded60,
			},
			'&:before': isFirst
				? {
						content: '""',
						display: 'block',
						position: 'absolute',
						left: 0,
						right: 0,
						top: -7,
						height: 7,
						background: colors.navy400,
				  }
				: null,
			'&:after': isLast
				? {
						content: '""',
						display: 'block',
						position: 'absolute',
						left: 0,
						right: 0,
						bottom: -7,
						height: 7,
						background: colors.navy400,
				  }
				: null,
		},
		favoriteOption: {
			...commonOption,
			backgroundColor: (isFocused && !disabled) || isSelected ? colors.grey300 : colors.grey200,
			marginTop: isFirst ? 4 : 0,
			marginBottom: isLast ? 7 : 0,
			'&:hover': {
				...commonOption['&:hover'],
				backgroundColor: colors.grey300,
			},
			'&:before': isFirst
				? {
						content: '""',
						display: 'block',
						position: 'absolute',
						left: 0,
						right: 0,
						top: -7,
						height: 7,
						background: colors.grey200,
				  }
				: null,
			'&:after': isLast
				? {
						content: '""',
						display: 'block',
						position: 'absolute',
						left: 0,
						right: 0,
						bottom: -7,
						height: 7,
						background: colors.grey200,
						borderBottom: '1px solid #dfdfdf',
				  }
				: null,
		},
		star: {
			flex: '0 0 26px',
			width: 26,
			height: 30,
			marginTop: 3,
			opacity: isFavorite || (isFocused && !disabled) ? 1 : 0,
			display: isNew ? 'none' : 'block',
		},
		check: {
			flex: '0 0 30px',
			width: 30,
			height: 24,
			paddingLeft: 6,
			marginTop: 4,
		},
		label: {
			flex: '1 1 100%',
			whiteSpace: 'normal',
			lineHeight: '22px',
			width: 'max-content',
			overflow: 'hidden',
			paddingTop: 5,
			paddingBottom: 5,
			maxWidth: 420,
		},
		prefix: {
			flex: '0 0 auto',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			fontFamily: 'Courier New, sans-serif',
			fontWeight: isCategory && !isFavorite && !isHinted ? 600 : 100,
			marginRight: 4,
			lineHeight: '22px',
			paddingTop: 5,
			paddingBottom: 5,
			paddingLeft: isNew ? 9 : 0,
		},
		rightText: {
			flex: '0 0 auto',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			fontSize: 11,
			fontWeight: 100,
			marginLeft: 10,
			lineHeight: '22px',
			paddingTop: 5,
			paddingBottom: 5,
			color: colors.black,
			textAlign: 'right',
			whiteSpace: 'nowrap',
		},
	}
}
