/* @flow */

import CashbotPanelLogic from './helpers/cashbot-panel-logic'
import { connect } from 'react-redux'
import {
	getCashbotInvoiceId,
	getCashbotInvoice,
	getIsCashbotInvoiceLoading,
	getIsCashbotAuthenticated,
	getCashbotOffers,
	getIsCashbotOffersLoading,
	getIsMarkedToBeFinanced,
} from 'modules/cashbot/selectors'
import type { CashbotInvoiceType } from 'modules/cashbot/types'
import { getAccountingDocument } from 'modules/accounting-document/selectors'
import { getCurrentOrganizationName } from 'modules/organization/selectors'
import {
	loadCashbotInvoice,
	getCashbotCompanyOfCurrentOrg,
	loginToCashbot,
	sendCashbotInvoice,
	downloadCashbotOffers,
	confirmCashbotOffer,
} from 'modules/cashbot/actions'
import type { CashbotAction } from '../../actions/action-types'
import type { CasbotCompanyResult } from '../../actions'
import type { CashbotInvoice, CashbotOffer } from '../../types'
import type { State, AccountingDocument } from 'types'

type OwnProps = {|
	accDocId: string,
	direction: CashbotInvoiceType,
|}

type StateProps = {|
	isCashbotAuthenticated: boolean,
	cashbotInvoiceId: ?number,
	isCashbotInvoiceLoading: boolean,
	cashbotInvoice: ?CashbotInvoice,
	cashbotOffers: ?Array<CashbotOffer>,
	isCashbotOffersLoading: boolean,
	currentOrganizationName: ?string,
	isMarkedToBeFinanced: boolean,
	accountingDocument?: ?AccountingDocument,
|}

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => {
	return {
		isCashbotAuthenticated: getIsCashbotAuthenticated(state),
		cashbotInvoiceId: getCashbotInvoiceId(state, ownProps.accDocId),
		isCashbotInvoiceLoading: getIsCashbotInvoiceLoading(state, ownProps.accDocId),
		cashbotInvoice: getCashbotInvoice(state, ownProps.accDocId),
		cashbotOffers: getCashbotOffers(state, ownProps.accDocId),
		isCashbotOffersLoading: getIsCashbotOffersLoading(state, ownProps.accDocId),
		currentOrganizationName: getCurrentOrganizationName(state) || undefined,
		isMarkedToBeFinanced: getIsMarkedToBeFinanced(state, ownProps.accDocId),
		accountingDocument: getAccountingDocument(state, ownProps.accDocId),
	}
}

type DispatchProps = {|
	authenticateCashbot?: () => Promise<void>,
	loadCashbotInvoice?: (accDocId: string) => Promise<void>,
	financeCashbotInvoice?: (
		accDocId: string,
		requestedFinancingDueDate?: string,
	) => Promise<'companyNotRegistered' | 'offersDownloaded' | null>,
	downloadCashbotOffers?: (accDocId: string) => Promise<void>,
	confirmOffer?: (accDocId: string, offer: CashbotOffer) => Promise<void>,
|}
const mapDispatchToProps = (dispatch: Dispatch<CashbotAction>): DispatchProps => {
	return {
		authenticateCashbot: async () => {
			return dispatch(loginToCashbot())
		},
		loadCashbotInvoice: async (accDocId: string) => {
			return dispatch(loadCashbotInvoice(accDocId))
		},
		financeCashbotInvoice: async (accDocId: string, requestedFinancingDueDate?: string) => {
			// 1) Verify company
			const cashbotCompanyResult: CasbotCompanyResult = await dispatch(getCashbotCompanyOfCurrentOrg())
			if (!cashbotCompanyResult.success) {
				if (cashbotCompanyResult.serverError.code === 'cashbot401') {
					// Not authorized, will be shown in global validation, no need to return anything special
					// TODO-CASHBOT: it would be better if getCashbotCompanyOfCurrentOrg returned simply ?CasbotCompanyResult
					// and call some other endpoint to recognize, if we are authenticated
					return null
				}
				return 'companyNotRegistered'
			}

			// 2) Create invoice in Cashbot
			const cashbotCompanyId = cashbotCompanyResult.company.id
			const invoiceCreated: boolean = await dispatch(
				sendCashbotInvoice(accDocId, cashbotCompanyId, requestedFinancingDueDate),
			)
			if (!invoiceCreated) {
				// False is returned on serverServer error, which caught by global validation, no need to show anything
				return null
			}

			// 3) Get offers from Cashbot
			const offers: ?Array<CashbotOffer> = await dispatch(downloadCashbotOffers(accDocId))
			if (offers) {
				return 'offersDownloaded'
			}

			return null //'success'
		},
		downloadCashbotOffers: async (accDocId: string) => {
			return dispatch(downloadCashbotOffers(accDocId))
		},
		confirmOffer: (accDocId: string, offer: CashbotOffer) => {
			return dispatch(confirmCashbotOffer(accDocId, offer))
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(CashbotPanelLogic)
