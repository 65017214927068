/* @flow */

import React, { Component } from 'react'
import type { DocumentItem } from 'types'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import { getDocumentItemName } from '../helpers'
import DocumentTreeItem from './document-tree-item'

type Props = {|
	documents: Array<DocumentItem>,
	selected?: Array<string>,
	hideFolderSystemTrivi?: boolean,
	loadDocuments: (documentId?: string) => void,
	onDocumentClick: (uniqueId: ?string, isFolder: boolean) => void,
|}

class DocumentTreeView extends Component<Props & WithTranslateProps> {
	componentDidMount() {
		if (!this.props.documents || 0 === this.props.documents.length) {
			this.props.loadDocuments()
		}
	}

	render() {
		return <div>{this.props.documents && this.props.documents.map(this.renderItem)}</div>
	}

	isHiddenItem = (item: DocumentItem) => {
		return item.name === 'folder.system.trivi' && this.props.hideFolderSystemTrivi
	}

	renderItem = (item: DocumentItem) => {
		if (this.isHiddenItem(item)) {
			return null
		}

		return (
			<DocumentTreeItem
				isSelected={this.props.selected && -1 !== this.props.selected.indexOf(item.uniqueId)}
				key={item.uniqueId}
				id={item.uniqueId}
				onClick={this.props.onDocumentClick}
				onNestedListToggle={this.handleTreeToggle}
				fileName={getDocumentItemName(this.props.t, item)}
				isFolder={1 === item.itemType}
				nestedItems={item.children && item.children.map(this.renderItem)}
			/>
		)
	}

	handleTreeToggle = (open: boolean, id: ?string) => {
		if (!open && id) {
			this.props.loadDocuments(id)
		}
	}
}

export default withTranslate(DocumentTreeView)
