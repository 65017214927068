/* @flow */

import type { Dispatch, SettingsAction, State, OrganizationSettingsTaxPaymentHistory } from 'types'
import { loadTaxPaymentHistory } from 'modules/settings/actions'
import { getTaxPaymentHistory } from 'modules/settings/selectors'
import { type ComponentType, type ElementConfig, type Component } from 'react'

import { connect } from 'react-redux'

type StateProps = {|
	taxPaymentHistory: ?Array<OrganizationSettingsTaxPaymentHistory>,
|}

type DispatchProps = {|
	loadTaxPaymentHistory: () => void,
|}

const mapStateToProps = (state: State): StateProps => {
	return {
		taxPaymentHistory: getTaxPaymentHistory(state),
	}
}

const mapDispatchToProps = (dispatch: Dispatch<SettingsAction>): DispatchProps => {
	return {
		loadTaxPaymentHistory: () => {
			dispatch(loadTaxPaymentHistory())
		},
	}
}

export type AddedProps = {| ...StateProps, ...DispatchProps |}

export default function withOrganizationSettings<P: any, C: ComponentType<P>>(
	WrappedComponent: C,
): Class<Component<$Diff<ElementConfig<C>, AddedProps>, any>> {
	return connect(mapStateToProps, mapDispatchToProps)(WrappedComponent)
}
