/* @flow */

// $FlowFixMe - there is useState
import React, { useState } from 'react'
import {
	withTranslate,
	withAccountingDocumentActions,
	type WithTranslateProps,
	type WithAccountingDocumentActionsProps,
} from 'wrappers'
import SendEmailDialog from 'modules/common/containers/send-email-dialog'
import Button from 'components/button'

type Props = {|
	...WithTranslateProps,
	...WithAccountingDocumentActionsProps,
	accountingDocumentId: string,
|}

function SendEmailButton(props: Props) {
	const [dialog, setDialog] = useState<boolean>(false)

	function closeDialog() {
		setDialog(false)
	}

	function openDialog() {
		setDialog(true)
	}

	function onSendDocument(emails: Array<string>, templateId: number) {
		props.sendAccountingDocument(props.accountingDocumentId, templateId, emails)
		closeDialog()
	}

	return (
		<div>
			<Button
				labelText={props.t('grid.action.sendEmail')}
				autoTestId="send-changer-button-email"
				onClick={openDialog}
			/>

			{dialog && (
				<SendEmailDialog
					onClose={closeDialog}
					onSend={onSendDocument}
					accountingDocumentId={props.accountingDocumentId}
					open
				/>
			)}
		</div>
	)
}

export default withTranslate(withAccountingDocumentActions(SendEmailButton))
