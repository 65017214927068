/* @flow */

import React from 'react'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import CashRegisterGrid from '../containers/cash-register-grid'
import styles from './cash-register.css'
import Helmet from 'react-helmet'

type Props = {|
	...WithTranslateProps,
|}

function CashRegisterList(props: Props) {
	return (
		<div className={styles.root}>
			<Helmet>
				<title>{props.t('cashRegister.header')}</title>
			</Helmet>
			<h1>{props.t('cashRegister.header')}</h1>
			<CashRegisterGrid />
		</div>
	)
}

export default withTranslate(CashRegisterList)
