/* @flow */

import type { WithRouterProps, RoutingContext } from 'types'
import React, { Component } from 'react'
import BankAccountEdit from '../containers/bank-account-edit'
import PopupSection from 'components/PopupSection'
import { bankAccountRoute } from 'modules/bank/routing/routes'
import { connect } from 'react-redux'
import { loadBankAccount } from '../actions'
import { withRouter } from 'react-router-dom'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import Helmet from 'react-helmet'

type Props = {
	load: (id: string) => void,
}

class BankAccountEditPage extends Component<WithTranslateProps & Props & RoutingContext & WithRouterProps> {
	componentDidMount() {
		this.props.load(this.props.match.params.id)
	}

	handleBankAccountEditOnSave = (id: string) => {
		if (id) {
			this.props.history.push(bankAccountRoute(id))
		}
	}

	handlePageClose = () => {
		this.props.history.push(bankAccountRoute(this.props.match.params.id))
	}

	render() {
		const id: string = this.props.match.params.id

		return (
			<PopupSection open onRequestClose={this.handlePageClose}>
				<Helmet>
					<title>{this.props.t('bank.editBankAccountHeadline')}</title>
				</Helmet>
				<BankAccountEdit
					create={false}
					onSave={this.handleBankAccountEditOnSave}
					handleClose={this.handlePageClose}
					id={id}
				/>
			</PopupSection>
		)
	}
}

type DispatchProps = {
	load: (id: string) => void,
}
const mapDispatchToProps = (dispatch: any): DispatchProps => {
	return {
		load: (id: string): void => dispatch(loadBankAccount(id)),
	}
}

export default withTranslate(withRouter(connect(undefined, mapDispatchToProps)(BankAccountEditPage)))
