/* @flow */

import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import type { WithRouterProps } from 'types'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import { BasicGridFilter } from 'components'
import { FilterTextField } from 'modules/data-grid/components/data-grid-filter'
import { addressBookRoute } from 'modules/address-book/routing/routes'
import Button from 'components/button'
import styles from './contact-advanced-filter.css'

type Props = {|
	...WithRouterProps,
	...WithTranslateProps,
	canEditContacts?: boolean,
|}
class ContactAdvancedFilter extends Component<Props> {
	onNewContact = () => {
		this.props.history.push(addressBookRoute('new'))
	}

	render() {
		const { t, canEditContacts } = this.props
		const rightElement = canEditContacts ? (
			<div>
				<Button autoTestId="contact-new" onClick={this.onNewContact} labelText={t('navigation.other.newContact')} />
			</div>
		) : null

		return (
			<BasicGridFilter
				default
				name="contacts"
				gridId="address-book"
				dataType="contacts"
				textFilterName="searchText"
				noFilterLabel={t('addressBook.grid.filter.allContacts')}
				rightElement={rightElement}
			>
				<FilterTextField
					className={styles.column}
					name="firstName"
					labelText={t('addressBook.grid.filter.firstName')}
				/>
				<FilterTextField className={styles.column} name="lastName" labelText={t('addressBook.grid.filter.lastName')} />
				<FilterTextField
					className={styles.column}
					name="companyName"
					labelText={t('addressBook.grid.filter.companyName')}
				/>
			</BasicGridFilter>
		)
	}
}

export default withRouter(withTranslate(ContactAdvancedFilter))
