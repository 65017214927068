/* @flow */

import React from 'react'
import DownloadButton from 'components/image-viewer/download-button'
import Loader from 'components/loader'
import styles from './terms-viewer.css'

const LOADER_HEIGHT = 40

type Props = {|
	fileUrl?: ?string,
	loading?: boolean,
|}

export default function TermsViewer(props: Props) {
	return (
		<div className={styles.autoHeight}>
			<div
				className={props.loading ? styles.loadingViewer : styles.viewer}
				style={{
					height: '100%',
					overflowY: 'auto',
					minHeight: props.loading ? LOADER_HEIGHT * 2 : 'unset',
				}}
			>
				{props.fileUrl && (
					<>
						<DownloadButton downloadUrl={props.fileUrl} />
						<object
							data={props.fileUrl}
							width="100%"
							height="100%"
							style={{
								display: 'block',
								minHeight: props.loading ? LOADER_HEIGHT * 2 : 'unset',
								objectFit: 'contain',
								height: 'calc(100% - 40px)',
							}}
							type="application/pdf"
						>
							<param name="wmode" value="transparent" />
						</object>
					</>
				)}
				<Loader visible={props.loading} />
			</div>
		</div>
	)
}
