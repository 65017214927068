/* @flow */
/** @jsx jsx */

import { Fragment } from 'react'
import { jsx } from '@emotion/core'
import { BANK_COLORS } from '../constants'
import type { BankAccount } from '../types'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import ProcessingState from 'components/processing-state/processing-state-container'
import Block from 'components/block/block'
import Chip from 'components/chip'

type Props = {|
	...WithTranslateProps,
	bankAccount: BankAccount,
	showProcessingState?: boolean,
	selected?: boolean,
	onClick?: BankAccount => void,
|}

function getBackgroundColor(code: ?string) {
	return code && BANK_COLORS[code] ? BANK_COLORS[code].background : undefined
}

function getTextColor(code: ?string) {
	return code && BANK_COLORS[code] ? BANK_COLORS[code].text : undefined
}

function BankAccountItem(props: Props) {
	if (!props.bankAccount) return null

	function onClick() {
		props.onClick && props.onClick(props.bankAccount)
	}

	return (
		<Block
			onClick={onClick}
			selected={props.selected}
			title={props.bankAccount.bankName}
			primary={props.bankAccount.name}
			secondary={
				props.bankAccount.displayNumber
					? props.bankAccount.displayNumber
					: (props.bankAccount.accountNo || '') + '/' + (props.bankAccount.accountCode || '')
			}
			accentColor={getBackgroundColor(props.bankAccount.accountCode)}
			titleColor={getTextColor(props.bankAccount.accountCode)}
			chips={
				<Fragment>
					{props.showProcessingState && (
						<ProcessingState
							processingState={props.bankAccount.processingState}
							processingMessage={props.bankAccount.processingMessage}
							errorTooltip={props.t('accountingDocument.processingStates.bankErrorTooltip')}
							waitingLabel={props.t('bank.esoWaiting')}
							errorLabel={props.t('bank.esoError')}
							inline={false}
							top
						/>
					)}
					{props.bankAccount.isMain ? <Chip>{props.t('bank.main')}</Chip> : null}
				</Fragment>
			}
		/>
	)
}

export default withTranslate(BankAccountItem)
