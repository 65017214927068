/* @flow */

import { changeCurrentOrganization, processInvitation } from 'modules/user/actions'
import { getLoginRoute, getInvitationRegisterRoute } from 'modules/user/routing/routes'
import { type Component, type ComponentType, type ElementConfig } from 'react'

import type { FinishProcessInvitation } from 'modules/user/actions/action-types'
import type { InvitationProcessResponse } from 'types'
import { connect } from 'react-redux'
import { dashboardRoute } from 'modules/dashboard/routing/routes'
import { push } from 'react-router-redux'

type DispatchProps = {|
	processInvitation: (token: string) => Promise<?InvitationProcessResponse>,
|}

const mapDispatchToProps = (dispatch: Dispatch<any>): DispatchProps => {
	return {
		processInvitation: async (token: string) => {
			const actionResp: FinishProcessInvitation = await dispatch(processInvitation(token))
			if (!actionResp.response) {
				return
			}

			const { state, userEmail, organizationId, organizationName } = actionResp.response

			const routeState = {
				invitationToken: token,
				email: userEmail,
				organizationName,
			}

			switch (state) {
				case 1: // accepted
					dispatch(changeCurrentOrganization(organizationId))
					dispatch(push(dashboardRoute(organizationId)))
					break
				case 2: // not logged
					dispatch(
						push({
							pathname: getLoginRoute(),
							state: routeState,
						}),
					)
					break
				case 3: // not registered
					dispatch(
						push({
							pathname: getInvitationRegisterRoute(),
							state: routeState,
						}),
					)
			}

			return actionResp.response
		},
	}
}

export type Props = DispatchProps

export default function withProcessInvitation<P: any, C: ComponentType<P>>(
	WrappedComponent: C,
): Class<Component<$Diff<ElementConfig<C>, Props>, any>> {
	return connect(undefined, mapDispatchToProps)(WrappedComponent)
}
