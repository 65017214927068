/* @flow */

import { find } from 'lodash-es'
import type { FieldSection, FieldSections } from '../types'
import { fieldSections } from '../constants'

export function getFieldSection(name: string): ?FieldSection {
	return find(fieldSections, { name })
}

export function getFieldSections(): FieldSections {
	return fieldSections
}

export function isFieldSectionRepeatable(name: string): boolean {
	const section = getFieldSection(name)
	return !!section && section.isRepeatable
}
