/* @flow*/

import {
	ACCOUNTING_DOCUMENT_CONTACT_SECTION,
	FINANCIAL_ACCOUNT_SECTION,
	COUNTRY_SECTION,
	ALREADY_SEEN_SECTION,
	LINE_ITEM_SECTION,
} from 'modules/organization/actions/favorites'
import type { State } from 'types'
import { EMPTY_ARRAY } from 'trivi-constants'

const getFavoriteSection = (section: string) => (state: State): Array<string> => {
	return (
		state.organization &&
		state.organization.favorites &&
		state.organization.favorites[section] &&
		state.organization.favorites[section].keys
	)
}

export const getFavoriteFinancialAccountIds = (state: State): Array<string> => {
	return (
		(state.organization &&
			state.organization.favorites &&
			state.organization.favorites[FINANCIAL_ACCOUNT_SECTION] &&
			state.organization.favorites[FINANCIAL_ACCOUNT_SECTION].keys) ||
		EMPTY_ARRAY
	)
}

export const getFavoriteLineItemIds = (state: State): Array<string> => {
	return (
		(state.organization &&
			state.organization.favorites &&
			state.organization.favorites[LINE_ITEM_SECTION] &&
			state.organization.favorites[LINE_ITEM_SECTION].keys) ||
		EMPTY_ARRAY
	)
}

export const getFavoriteAccountingDocumentContactIds = getFavoriteSection(ACCOUNTING_DOCUMENT_CONTACT_SECTION)
export const getFavoriteCountries = getFavoriteSection(COUNTRY_SECTION)
export const getFavoriteAlreadySeen = getFavoriteSection(ALREADY_SEEN_SECTION)

export function isAlreadySeen(state: State, seen: string): boolean {
	return getFavoriteAlreadySeen(state).some((seenItem: string) => seenItem === seen)
}

export function isFavoritesLoaded(state: State, section: string): boolean {
	return (
		state.organization &&
		state.organization.favorites &&
		'undefined' !== typeof state.organization.favorites[section] &&
		state.organization.favorites[section].loading !== true
	)
}

export function isFavoritesAlreadySeenLoaded(state: State): boolean {
	return isFavoritesLoaded(state, ALREADY_SEEN_SECTION)
}
