// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2F4et7yDKsEOYZBy_gnrnZ {\n\twidth: 48%;\n}\n\n._2F4et7yDKsEOYZBy_gnrnZ:nth-child(1n) {\n\tfloat: left;\n\tmargin-right: 16px;\n\tclear: none;\n}\n\n._2F4et7yDKsEOYZBy_gnrnZ:last-child {\n\tmargin-right: 0;\n}\n", "",{"version":3,"sources":["webpack://./modules/data-grid-next/implemented/financial-accounts-grid/financial-accounts-grid.css"],"names":[],"mappings":"AAAA;CACC,UAAA;AACD;;AAEA;CACC,WAAA;CACA,kBAAA;CACA,WAAA;AACD;;AAEA;CACC,eAAA;AACD","sourcesContent":[".column {\n\twidth: 48%;\n}\n\n.column:nth-child(1n) {\n\tfloat: left;\n\tmargin-right: 16px;\n\tclear: none;\n}\n\n.column:last-child {\n\tmargin-right: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"column": "_2F4et7yDKsEOYZBy_gnrnZ"
};
export default ___CSS_LOADER_EXPORT___;
