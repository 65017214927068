/* @flow */

import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import type {
	Dispatch,
	OrganizationAction,
	State,
	Task,
	TaskAction,
	UserAction,
	UserProfile,
	OrganizationMembers,
} from 'types'
import { createTask, replaceTask } from '../actions'
import type { Action } from '../actions/action-types'
import type { Props as TaskEditProps } from '../components/task-edit'
import TaskEdit from '../components/task-edit'
import { taskRoute } from 'modules/task/routing/routes'
import { getCurrentOrganizationId, getMyProfile, getCurrentUserOrganizations } from 'modules/user/selectors'
import { getTaskState } from '../selectors'
import { userHasAccess } from 'permissions'

type Props = {|
	id?: string,
	onClose?: () => void,
	defaultData?: Task,
	inline?: boolean,
	redirectBack?: boolean,
|}

type StateProps = {|
	task: ?Task,
	me: ?UserProfile,
	organizationId: number,
	organizations: ?OrganizationMembers,
	showAccDocs: boolean,
|}

const mapStateToProps = (state: State, ownProps: Props): StateProps => {
	let task: ?Task
	const organizationId = Number(getCurrentOrganizationId(state))

	if (ownProps.id) {
		task = getTaskState(state, ownProps.id) && getTaskState(state, ownProps.id).data
	}

	if (!task) {
		task = ownProps.defaultData
		task ? (task.organizationId = organizationId) : null
	}

	return {
		task: task,
		me: getMyProfile(state),
		organizationId: organizationId,
		organizations: getCurrentUserOrganizations(state),
		showAccDocs: userHasAccess(state, 'seeAccountingDocuments'),
	}
}

type DispatchProps = {|
	onSave: Task => ?Promise<TaskAction>,
|}

const mapDispatchToProps = (
	dispatch: Dispatch<TaskAction | OrganizationAction | UserAction>,
	ownProps: Props,
): DispatchProps => {
	return {
		onSave: (task: Task): ?Promise<TaskAction> => {
			let promise: Promise<Action>

			if (ownProps.id) {
				task.id = ownProps.id
				promise = dispatch(replaceTask(task))
			} else {
				promise = dispatch(createTask(task))
			}

			if (promise) {
				return promise.then((action: Action) => {
					if (ownProps.id && action.task && !ownProps.redirectBack) {
						dispatch(push(taskRoute((action.task: any).id)))
					} else {
						ownProps.onClose && ownProps.onClose()
					}
					return action
				})
			}
		},
	}
}

const mergeProps = (stateProps: StateProps, dispatchProps: DispatchProps, ownProps: Props): TaskEditProps => {
	return {
		task: stateProps.task,
		me: stateProps.me,
		organizationId: stateProps.organizationId,
		organizations: stateProps.organizations,
		showAccDocs: stateProps.showAccDocs,
		onSave: dispatchProps.onSave,
		onClose: ownProps.onClose || null,
		inline: ownProps.inline || false,
	}
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(TaskEdit)
