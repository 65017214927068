/* @flow */
/** @jsx jsx */

import { Component } from 'react'
import { jsx } from '@emotion/core'
import { Column } from 'modules/data-grid-next/components'
import {
	default as SingleLineDataGrid,
	type Props as SingleLineDataGridProps,
} from 'modules/data-grid-next/components/single-line-data-grid'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import AddIcon from 'components/svg-icons/content/add'
import type { Action as DataGridAction } from 'modules/data-grid/types'
import type { FinancialAccount, DataGridId, Filter } from 'types'
import TextInputDialog from 'components/dialogs/text-input-dialog'
import AdvancedFilter from './advanced-filter'
import type { DataType } from '../../types'
import { FINANCIAL_ACCOUNTS_DATA_TYPE, FINANCIAL_ACCOUNTS_GRID_ID } from '../../constants'
import { financialAccount as financialAccountApi } from 'modules/common/models/api-model'
import FullText from 'components/full-text'

const DataGrid: React$ComponentType<SingleLineDataGridProps<typeof FINANCIAL_ACCOUNTS_DATA_TYPE>> = SingleLineDataGrid

type Props = {|
	...WithTranslateProps,
	refreshGrid: (gridId: DataGridId, gridDataType: DataType, defaultFilter?: Filter) => void,
	canSeeSubAccounts: boolean,
	canEditSubAccounts: boolean,
|}

class FinancialAccountsGrid extends Component<Props> {
	getActions = (): Array<DataGridAction<FinancialAccount>> => {
		const actions = []
		if (this.props.canEditSubAccounts) {
			actions.push({
				name: this.props.t('settings.financialAccounts.addSubFinAccount'),
				type: 'addSubFinAccount',
				primary: true,
				icon: <AddIcon />,
			})
		}
		return actions
	}

	onAction = async (action: string, finAccount: FinancialAccount) => {
		const { t } = this.props
		switch (action) {
			case 'addSubFinAccount': {
				TextInputDialog.open({
					titleText: t('dialogs.subFinAccountAddTitle'),
					confirmButtonText: t('dialogs.addOption'),
				}).then((name: string) => {
					const subAccount: FinancialAccount = { name }
					finAccount.no &&
						financialAccountApi.post({ finAccountNumber: finAccount.no }, subAccount).then(() => {
							this.props.refreshGrid(FINANCIAL_ACCOUNTS_GRID_ID, FINANCIAL_ACCOUNTS_DATA_TYPE)
						})
				})
				break
			}
		}
	}

	subFinAccountsRenderer(value: Array<FinancialAccount> /*, row: FinancialAccount*/) {
		return value.map((a: FinancialAccount, i: number) => {
			const no: string = a.no ? `(${a.no})` : ''
			return (
				<div key={a.no + i} style={style.render}>
					<FullText text={`${a.name} ${no}`} />
				</div>
			)
		})
	}

	finAccountNameRenderer(name: string) {
		return (
			<div style={style.render}>
				<FullText text={name} />
			</div>
		)
	}

	render() {
		return (
			<div>
				<AdvancedFilter />
				<DataGrid
					light
					compactPager
					showPageSize={false}
					showItemsOfTotal={false}
					id={FINANCIAL_ACCOUNTS_GRID_ID}
					dataType={FINANCIAL_ACCOUNTS_DATA_TYPE}
					filterId={FINANCIAL_ACCOUNTS_GRID_ID}
					actions={this.getActions()}
					onAction={this.onAction}
				>
					<Column
						title={this.props.t('settings.financialAccounts.no')}
						width="15%"
						columnId="no"
						key="no"
						sortable
						defaultSortDirection="ASC"
					/>
					<Column
						title={this.props.t('settings.financialAccounts.name')}
						width="55%"
						columnId="name"
						key="name"
						sortable
						render={this.finAccountNameRenderer}
					/>
					{this.props.canSeeSubAccounts && (
						<Column
							title={this.props.t('settings.financialAccounts.subFinAccounts')}
							width="30%"
							columnId="subFinAccounts"
							key="subFinAccounts"
							render={this.subFinAccountsRenderer}
						/>
					)}
				</DataGrid>
			</div>
		)
	}
}

const style = {
	render: {
		textOverflow: 'ellipsis',
		overflow: 'hidden',
	},
}

export default withTranslate(FinancialAccountsGrid)
