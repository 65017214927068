/* @flow */

import React, { Component } from 'react'
import type { AccountingDocumentAttachment, AccountingDocumentConnection } from 'types'
import Attachment from 'components/svg-icons/file/attachment'
import styles from './attachment-button.css'
import { colors } from 'variables'
import { withTranslate, type WithTranslateProps } from 'wrappers'

type Props = {|
	...WithTranslateProps,
	accountingDocumentId: string,
	onClick: () => void,
	attachments: ?Array<AccountingDocumentAttachment>,
	connections: ?Array<AccountingDocumentConnection>,
	hideIfNoAttachment?: boolean,
	hideIfOnlyAccountingDocuments?: boolean,
	loadAccountingDocumentAttachments: () => void,
	loadAccountingDocumentConnections: () => void,
|}

class AttachmentButton extends Component<Props> {
	UNSAFE_componentWillMount() {
		!this.props.attachments && this.props.loadAccountingDocumentAttachments()
		!this.props.connections && this.props.loadAccountingDocumentConnections()
	}

	handleClick = () => {
		this.props.onClick()
	}

	render() {
		const { t, attachments, connections, hideIfNoAttachment, hideIfOnlyAccountingDocuments } = this.props

		const attachmentsCount: number = (attachments || []).length
		const connectionsCount: number = (connections || []).length
		const attachedDocumentsCount = attachmentsCount + connectionsCount

		if (hideIfNoAttachment && attachedDocumentsCount === 0) {
			return null
		}

		if (hideIfOnlyAccountingDocuments && connectionsCount > 0 && attachmentsCount === 0) {
			return null
		}

		return (
			<div onClick={this.handleClick} className={styles.root}>
				<div className={styles.decorationFront} />
				<div className={styles.decorationBack}>
					<div className={styles.decorationBackInner} />
				</div>
				<div className={styles.button}>
					<Attachment size={19} style={style.icon} hoverColor={colors.white} color={colors.white} />
					{attachedDocumentsCount > 0
						? t('attachments.attachedDocuments', { count: attachedDocumentsCount })
						: t('attachments.attach')}
				</div>
			</div>
		)
	}
}

const style = {
	icon: {
		marginRight: 6,
		marginTop: -2,
		transform: 'rotate(-45deg)',
		verticalAlign: 'middle',
	},
}

export default withTranslate(AttachmentButton)
