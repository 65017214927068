/* @flow */
/** @jsx jsx */

import { jsx } from '@emotion/core'
import CompareTwoChart, {
	CompareTwoChartLabel,
	type CompareTwoChartLabelProps,
} from 'components/charts/compare-two-chart'
import { formatToDateString } from 'utils/formatters'
import withTranslate, { type Props as WithTranslateProps } from 'wrappers/with-translate'
import type { BalanceSummaryItem } from '../../types'
import { colors } from 'variables'

type Props = {|
	...WithTranslateProps,
	receivables: ?BalanceSummaryItem,
	commitments: ?BalanceSummaryItem,
	currency: ?string,
	domesticCurrency: ?string,
	loading?: boolean,
	goAndFilterAccountingDocuments: (filter: AccountingDocumentsFilter) => void,
|}

export type AccountingDocumentsFilter = {|
	type: 'receivables' | 'commitments',
	status?: 'beforeDue' | 'overdue',
	currency: ?string,
|}

function BalanceSummary(props: Props) {
	const { t, currency, domesticCurrency, receivables: receivablesReport, commitments: commitmentsReport } = props
	const currentFormattedDate = formatToDateString(new Date())

	const commitmentsNonPaidSum = Math.round((commitmentsReport && commitmentsReport.nonPaidSum) || 0)
	const commitmentsNonPaidExpiredDueDateSum = Math.round(
		(commitmentsReport && commitmentsReport.nonPaidExpiredDueDateSum) || 0,
	)

	const receivablesNonPaidSum = Math.round((receivablesReport && receivablesReport.nonPaidSum) || 0)
	const receivablesNonPaidExpiredDueDateSum = Math.round(
		(receivablesReport && receivablesReport.nonPaidExpiredDueDateSum) || 0,
	)

	const receivables = {
		left: {
			label: t('dashboard.balanceSummary.beforeDue'),
			value: commitmentsNonPaidSum - commitmentsNonPaidExpiredDueDateSum,
			color: colors.blue,
			negative: commitmentsNonPaidSum - commitmentsNonPaidExpiredDueDateSum < 0,
			click: () => props.goAndFilterAccountingDocuments({ type: 'receivables', status: 'beforeDue', currency }),
		},
		right: {
			label: t('dashboard.balanceSummary.overdue'),
			value: commitmentsNonPaidExpiredDueDateSum,
			color: colors.red,
			negative: commitmentsNonPaidExpiredDueDateSum < 0,
			click: () => props.goAndFilterAccountingDocuments({ type: 'receivables', status: 'overdue', currency }),
		},
	}
	const commitments = {
		left: {
			label: t('dashboard.balanceSummary.beforeDue'),
			value: receivablesNonPaidSum - receivablesNonPaidExpiredDueDateSum,
			color: colors.blue,
			negative: receivablesNonPaidSum - receivablesNonPaidExpiredDueDateSum < 0,
			click: () => props.goAndFilterAccountingDocuments({ type: 'commitments', status: 'beforeDue', currency }),
		},
		right: {
			label: t('dashboard.balanceSummary.overdue'),
			value: receivablesNonPaidExpiredDueDateSum,
			color: colors.red,
			negative: receivablesNonPaidExpiredDueDateSum < 0,
			click: () => props.goAndFilterAccountingDocuments({ type: 'commitments', status: 'overdue', currency }),
		},
	}

	const formatCurrency = currency || domesticCurrency || ''
	const isReceivablesEmpty = 0 === receivables.left.value && 0 === receivables.right.value
	const isCommitmentsEmpty = 0 === commitments.left.value && 0 === commitments.right.value

	const isReceivedZero = receivables.left.value === 0 && receivables.right.value === 0
	const isCommitmentZero = commitments.right.value === 0 && commitments.left.value === 0

	const receivablesKey = isReceivablesEmpty ? 'receivablesEmptySubtitle' : 'subtitle'
	const commitmentsKey = isCommitmentsEmpty ? 'commitmentsEmptySubtitle' : 'subtitle'

	return (
		<div css={styles.flex}>
			<CompareTwoChart
				title={t('dashboard.balanceSummary.receivables')}
				subtitle={t(`dashboard.balanceSummary.${receivablesKey}`, { date: currentFormattedDate })}
				tooltipFormat={`{point.name}: <b>${
					isReceivedZero ? '0,00' : '{point.custom.extraInformation} {point.y:,.2f}'
				} ${formatCurrency}</b>`}
				sumLabel={props.t('dashboard.balanceSummary.total')}
				labelComponent={getLabel(formatCurrency, props.t)}
				backgroundColor={colors.gray100}
				loading={props.loading}
				width={460}
				height={300}
				{...receivables}
			/>
			<CompareTwoChart
				title={t('dashboard.balanceSummary.commitments')}
				subtitle={t(`dashboard.balanceSummary.${commitmentsKey}`, { date: currentFormattedDate })}
				tooltipFormat={`{point.name}: <b>${
					isCommitmentZero ? '0,00' : '{point.custom.extraInformation} {point.y:,.2f}'
				} ${formatCurrency}</b>`}
				sumLabel={props.t('dashboard.balanceSummary.total')}
				labelComponent={getLabel(formatCurrency, props.t)}
				backgroundColor={colors.gray100}
				loading={props.loading}
				width={460}
				height={300}
				{...commitments}
			/>
		</div>
	)
}

const styles = {
	flex: {
		display: 'flex',
		justifyContent: 'space-between',
	},
}

export default withTranslate(BalanceSummary)

function getLabel(currency: string, t: (s: string) => string) {
	// eslint-disable-next-line react/no-multi-comp
	return function Label(props: CompareTwoChartLabelProps) {
		return (
			<CompareTwoChartLabel
				{...props}
				valueFormat={`{value:,.0f} ${currency}`}
				note={t('dashboard.balanceSummary.showInvoices')}
			/>
		)
	}
}
