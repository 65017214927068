/* @flow */

import React, { Component, Children } from 'react'
import { Tabs, Tab } from 'components/Tabs'
import FilterTab from './filter-tab'
import type { SearchFilter } from 'types'
import type { ChildrenArray } from 'react'

export type Props = {
	name: string,
	filter?: SearchFilter,
	children: ChildrenArray<React$Element<typeof FilterTab>>,
	onChange?: (filter: SearchFilter) => void,
}

class FilterTabs extends Component<Props> {
	valueMap: { [string]: ?(number | string) }

	constructor(props: Props) {
		super(props)
		this.updateValueMap()
	}

	UNSAFE_componentWillReceiveProps() {
		this.updateValueMap()
	}

	updateValueMap() {
		this.valueMap = {}

		let value: ?(number | string), stringValue: string
		Children.forEach(this.props.children, (tab: React$Element<typeof FilterTab>) => {
			value = tab.props.value
			stringValue = convertToTabValue(value)
			this.valueMap[stringValue] = value
		})
	}

	handleTabChange = (tabValue: string) => {
		const value: ?(number | string) = this.valueMap[tabValue]

		let filter: SearchFilter = {
			field: this.props.name,
		}

		if (value !== null) {
			filter.value = value
		}

		this.props.onChange && this.props.onChange(filter)
	}

	render() {
		let {
			name, // eslint-disable-line no-unused-vars
			filter,
			onChange, // eslint-disable-line no-unused-vars
			...rest
		} = this.props
		let value: ?(number | string | boolean) = filter && filter.value

		return (
			<Tabs {...rest} onChange={this.handleTabChange} value={convertToTabValue(value)}>
				{Children.map(this.props.children, (tab: React$Element<typeof FilterTab>) => {
					const value = convertToTabValue(tab.props.value)
					return <Tab {...Object.assign({}, tab.props, { value })} key={value} />
				})}
			</Tabs>
		)
	}
}

function convertToTabValue(value: ?(number | string | boolean)): string {
	if (typeof value === 'number' || typeof value === 'boolean') {
		return value.toString()
	} else if (!value) {
		return '__NONE__'
	} else {
		return value
	}
}

export default FilterTabs
