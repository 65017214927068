/* @flow */
/** @jsx jsx */

import { jsx } from '@emotion/core'
import { withRouter } from 'react-router'
import {
	withTranslate,
	type WithTranslateProps,
	withAccountingDocumentActions,
	type WithAccountingDocumentActionsProps,
} from 'wrappers'
import type { AccountingDocumentAttachment, WithRouterProps } from 'types'
import { openFile } from 'modules/file/domain'
import Clear from 'components/svg-icons/content/clear'
import TriviLink from 'modules/common/components/trivi-link'
import IconButton from 'components/icon-button'
import colors from 'variables/colors.css'

type Props = {|
	...WithAccountingDocumentActionsProps,
	...WithTranslateProps,
	...WithRouterProps,
	readonly?: boolean,
	attachment: ?Array<AccountingDocumentAttachment>,
	onRemove: (attachment: AccountingDocumentAttachment) => void,
|}

function Attachment(props: Props) {
	function onClick() {
		props.attachment.fileId &&
			props.attachment.documentName &&
			openFile(props.attachment.fileId, props.attachment.documentName, 'popupPage')
	}

	function onRemove() {
		props.onRemove(props.attachment)
	}

	return (
		<div css={style.item}>
			<div>
				<TriviLink onClick={onClick}>{props.attachment.documentName}</TriviLink>
			</div>
			{!props.readonly && (
				<div css={style.icon}>
					<IconButton
						size={30}
						onClick={onRemove}
						autoTestId="attachment-list-remove"
						tooltip={props.t('attachments.removeAttachment')}
					>
						<Clear size={14} />
					</IconButton>
				</div>
			)}
		</div>
	)
}

const style = {
	icon: {
		position: 'absolute',
		padding: 0,
		right: 0,
		top: 0,
	},
	item: {
		display: 'block',
		position: 'relative',
		borderWidth: 1,
		borderStyle: 'solid',
		borderColor: colors.grey300,
		backgroundColor: colors.white,
		borderRadius: 3,
		padding: '5px 30px 5px 10px',
		margin: '10px 0',
		lineHeight: '20px',
		minHeight: 30,
		fontSize: 14,
		color: colors.blue,
		overflow: 'hidden',
		wordBreak: 'break-all',
		'&:hover': {
			boxShadow: '3px 3px 0 0 rgba(0,0,0,0.08)',
		},
	},
}

export default withTranslate(withAccountingDocumentActions(withRouter(Attachment)))
