/* @flow */

import type { CashRegister, CashRegisterAction, Dispatch, State } from 'types'
import { getCashRegisters, getCashRegistersLoading } from 'modules/cash-register/selectors'

import CashRegisterGrid from 'modules/cash-register/components/cash-register-grid'
import { connect } from 'react-redux'
import { currentUserIsInternal } from 'modules/user/selectors'
import { loadCashRegisters } from '../actions'

type StateProps = {|
	loading: boolean,
	isInternalUser: boolean,
	cashRegisters: Array<CashRegister>,
|}

const mapStateToProps = (state: State): StateProps => {
	return {
		cashRegisters: getCashRegisters(state),
		loading: getCashRegistersLoading(state),
		isInternalUser: currentUserIsInternal(state),
	}
}

type DispatchProps = {|
	loadCashRegisters: () => void,
|}

const mapDispatchToProps = (dispatch: Dispatch<CashRegisterAction>): DispatchProps => {
	return {
		loadCashRegisters: () => {
			dispatch(loadCashRegisters())
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(CashRegisterGrid)
