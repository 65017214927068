/* @flow */
/** @jsx jsx */

import { jsx } from '@emotion/core'
import { Column } from 'modules/data-grid-next/components'
import {
	default as SingleLineDataGrid,
	type Props as SingleLineDataGridProps,
} from 'modules/data-grid-next/components/single-line-data-grid'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import { Component } from 'react'
import type { Action as DataGridAction } from 'modules/data-grid/types'
import type { DataGridId, Filter, PaymentOrder, RelatedAccountingDocumentInfo } from 'types'
import AdvancedFilter from './advanced-filter'
import type { DataType } from '../../types'
import { getNdpFiles, getOtherFiles, getPdfFiles } from '../../../bank/selectors'
import { AmountDisplay, TriviLink } from 'components'
import { formatToDateString, formatToTimeString } from 'utils/formatters'
import RelatedAccountingDocuments from '../../../accounting-document/components/related-accounting-documents'
import { openFile } from 'modules/file/domain'
import Delete from 'components/svg-icons/action/delete'
import DeleteDialog from 'components/delete-dialog'
import FullText from 'components/full-text'

function getProcessingStateLabel(processingState: number) {
	switch (processingState) {
		case 0:
			return 'notProcessed'
		case 1:
			return 'processed'
		case 2:
			return 'error'
	}
}

function openPreview(file: { fileId?: string, title?: string }) {
	if (file != null) {
		openFile(file.fileId || '', file.title || '', 'popupPage')
	}
}

const GRID_ID: DataGridId = 'paymentOrders'
const DATA_TYPE = 'paymentOrders'
const DataGrid: React$ComponentType<SingleLineDataGridProps<typeof DATA_TYPE>> = SingleLineDataGrid

type Props = {|
	...WithTranslateProps,
	refreshGrid: (gridId: DataGridId, gridDataType: DataType, defaultFilter?: Filter) => void,
	canDeleteOrder: boolean,
	deletePaymentOrder: (bankAccountId: string, paymentOrderId: string) => Promise<void>,
	downloadFile: (fileId: string) => void,
	id: any,
|}

class PaymentOrdersGrid extends Component<Props> {
	defaultFilter = [
		{
			field: 'bankAccountId',
			value: this.props.id,
		},
	]

	isVisibleAction(po: PaymentOrder, type: string) {
		switch (type) {
			case 'dwn_ndp': {
				return !!getNdpFiles(po).length
			}
			case 'dwn_pdf': {
				return !!getPdfFiles(po).length
			}
			default: {
				return !!getOtherFiles(po).length
			}
		}
	}

	getActions = (): Array<DataGridAction<PaymentOrder>> => {
		const { t } = this.props
		return [
			{
				name: t('grid.action.download_payment_order'),
				type: 'download',
				isVisibleFn: (row: PaymentOrder) => this.isVisibleAction(row, 'download'),
			},
			{
				name: t('grid.action.download_ndp'),
				type: 'dwn_ndp',
				isVisibleFn: (row: PaymentOrder) => this.isVisibleAction(row, 'dwn_ndp'),
			},
			{
				name: t('grid.action.download_pdf'),
				type: 'dwn_pdf',
				isVisibleFn: (row: PaymentOrder) => this.isVisibleAction(row, 'dwn_pdf'),
			},
			{
				name: t('grid.action.delete'),
				type: 'delete',
				icon: <Delete />,
				isVisibleFn: () => this.props.canDeleteOrder,
			},
		]
	}

	onAction = async (action: string, paymentOrder: PaymentOrder) => {
		let files = []

		switch (action) {
			case 'delete':
				DeleteDialog().then(() => {
					this.props.deletePaymentOrder(paymentOrder.bankAccountId, paymentOrder.id || '').then(() => {
						this.props.refreshGrid(GRID_ID, DATA_TYPE, this.defaultFilter)
					})
				})
				break
			case 'dwn_ndp': {
				files = getNdpFiles(paymentOrder)
				break
			}
			case 'dwn_pdf': {
				files = getPdfFiles(paymentOrder)
				break
			}
			case 'download': {
				files = getOtherFiles(paymentOrder)
				break
			}
			default:
				break
		}

		if (files.length > 0) {
			const file = files[0]
			file && file.fileId && this.props.downloadFile(file.fileId)
		}
	}

	nameRenderer = (value: string, paymentOrder: PaymentOrder) => {
		let pdfFiles = getPdfFiles(paymentOrder)
		if (pdfFiles.length > 0) {
			return (
				<TriviLink
					onClick={() => openPreview(pdfFiles[0])} //eslint-disable-line
				>
					<div css={style.name}>
						<FullText text={value || this.props.t('application.hasnoname')} />
					</div>
				</TriviLink> // eslint-disable-line react/jsx-no-bind
			)
		} else {
			return (
				<div css={style.name}>
					<FullText text={value} />
				</div>
			)
		}
	}

	generatedDateRenderer = (value: string) => {
		return formatToDateString(value) + ' ' + formatToTimeString(value)
	}

	accountingDocumentsRenderer = (value: Array<RelatedAccountingDocumentInfo>) => {
		return <RelatedAccountingDocuments items={value} />
	}

	processingStateRenderer = (value: number, paymentOrder: PaymentOrder) => {
		return (
			<span title={paymentOrder.processingState === 2 ? paymentOrder.processingMessage : ''}>
				{this.props.t(`bank.processingStates.${getProcessingStateLabel(value) || ''}`)}
			</span>
		)
	}

	amountRenderer = (value: number) => {
		return (
			<div css={style.right}>
				<AmountDisplay currency={'CZK'} amount={value} />
			</div>
		)
	}

	render() {
		const { t } = this.props

		return (
			<div>
				<AdvancedFilter gridId={GRID_ID} defaultFilter={this.defaultFilter} />
				<DataGrid
					compactPager={false}
					id={GRID_ID}
					dataType={DATA_TYPE}
					filterId={GRID_ID}
					defaultFilter={this.defaultFilter}
					actions={this.getActions()}
					onAction={this.onAction}
				>
					<Column
						title={t('bank.columns.orderName')}
						width="20%"
						columnId="name"
						key="name"
						sortable
						render={this.nameRenderer}
						// defaultSortDirection="ASC"
					/>
					<Column
						title={t('bank.columns.generatedDate')}
						width="25%"
						columnId="generatedDate"
						key="generatedDate"
						sortable
						render={this.generatedDateRenderer}
						defaultSortDirection="DESC"
					/>
					<Column
						title={t('bank.columns.accountingDocuments')}
						width="auto"
						columnId="accountingDocuments"
						key="accountingDocuments"
						render={this.accountingDocumentsRenderer}
					/>
					<Column
						title={t('bank.columns.processingState')}
						width="auto"
						columnId="processingState"
						key="processingState"
						sortable
						render={this.processingStateRenderer}
					/>
					<Column
						title={t('bank.columns.amount')}
						width="auto"
						columnId="amount"
						key="amount"
						sortable
						render={this.amountRenderer}
						headerStyle={style.right}
					/>
				</DataGrid>
			</div>
		)
	}
}

export default withTranslate(PaymentOrdersGrid)

const style = {
	name: {
		paddingRight: 10,
	},
	right: {
		textAlign: 'right',
		paddingRight: 20,
	},
}
