/* @flow */
import React from 'react'
import Menu from '../containers/menu'
import InvitationBanner from '../containers/invitation/invitation-banner'
import type { Organization, OrganizationSettingsOpen } from '../../common/models/swagger-model'
import InfoIcon from '../../../components/svg-icons/action/info'
import { colors } from '../../../variables'
import { withTranslate, type WithTranslateProps } from 'wrappers'

type Props = {|
	...WithTranslateProps,
	showInvitationBanner: boolean,
	organizationSettings: ?OrganizationSettingsOpen,
	organization: ?Organization,
|}

function Header(props: Props) {
	const { organization, t } = props
	return (
		<div>
			{organization && organization.report && organization.report.shouldBecomeVatPayer && (
				<div style={styles.vatLimit}>
					<InfoIcon size={18} hoverColor={colors.red} color={colors.red} style={{ marginLeft: -1, marginTop: -1 }} />
					<span style={styles.vatLimitCopy}>{t('internal.widget.organizationStatistics.vatLimitReached')}</span>
				</div>
			)}
			{props.showInvitationBanner && <InvitationBanner />}
			<div style={styles.header}>
				<Menu showInvitationBanner={props.showInvitationBanner} />
			</div>
		</div>
	)
}

export default withTranslate(Header)

const styles = {
	header: {
		marginBottom: 40,
	},
	vatLimit: {
		display: 'flex',
		alignContent: 'center',
		justifyContent: 'flex-start',
		marginBottom: '20px',
		fontSize: '14px',
	},
	vatLimitCopy: {
		marginLeft: '6px',
	},
}
