/* @flow */

import { get } from 'lodash-es'
import type { ScanFactoryBbWord, ScanFactoryDocumentFileMetadata, ScanFactoryField } from 'types'
import type { BbWordCursor, FieldBbWordMap, FieldCursor } from '../types'
import { getField, getFieldCursorKey, setField } from './field'

export function assignBbWord(
	metadata: ScanFactoryDocumentFileMetadata,
	bbWord: ScanFactoryBbWord,
	fieldCursor: FieldCursor,
	append?: boolean = false,
): ScanFactoryDocumentFileMetadata {
	const field = getField(metadata, fieldCursor)
	if (!field) {
		return metadata
	}

	const allBbWords: Array<ScanFactoryBbWord> = append ? [...(field.relatedBBWords || []), ...[bbWord]] : [bbWord]
	const allBbWordsValues = allBbWords.reduce((accValue: Array<string>, bbWord: ScanFactoryBbWord) => {
		if (isResolvable(bbWord, field)) {
			accValue.push(resolveValue(bbWord, field) || '')
		}
		return accValue
	}, [])
	const newValue = allBbWordsValues.length ? allBbWordsValues.join(' ') : null

	if (field.value === newValue || null === newValue) {
		return metadata
	}

	const newField = {
		...field,
		value: newValue,
		relatedBBWords: allBbWords,
	}

	const updatedMetadata = setField(metadata, fieldCursor, newField)
	if (updatedMetadata) {
		return updatedMetadata
	} else {
		return metadata
	}
}

export function resolveValue(bbWord?: ?ScanFactoryBbWord, field?: ?ScanFactoryField): ?string {
	if (bbWord && field) {
		return get(bbWord.interpretations, field.interpreterName)
	}
}

export function isResolvable(bbWord?: ?ScanFactoryBbWord, field?: ?ScanFactoryField): boolean {
	const value = resolveValue(bbWord, field)

	return null !== value && 'undefined' !== typeof value
}

export function getBbWordCursorKey(bbWordCursor: BbWordCursor): string {
	return `${bbWordCursor.pageIndex}-${bbWordCursor.bbWordIndex}`
}

export function getRelatedBbWordCursors(fieldBbWordMap: FieldBbWordMap, fieldCursor: FieldCursor): Array<BbWordCursor> {
	const key = getFieldCursorKey(fieldCursor)
	if (fieldBbWordMap[key]) {
		return fieldBbWordMap[key]
	}

	return []
}

export function getBbWordCursor(
	metadata: ScanFactoryDocumentFileMetadata,
	searchBbWord: ScanFactoryBbWord,
): ?BbWordCursor {
	for (let pageIndex = 0; pageIndex < (metadata.pages || []).length; pageIndex++) {
		const pages = metadata.pages || []
		const bbWords = (pages[pageIndex] && pages[pageIndex].bbWords) || []
		for (let bbWordIndex = 0; bbWordIndex < bbWords.length; bbWordIndex++) {
			if (searchBbWord === (bbWords[bbWordIndex] || null)) {
				return { pageIndex, bbWordIndex }
			}
		}
	}
}
