/* @flow */

import React from 'react'
import StoreIcon, { type Props } from './store-icon'
import { APPLE_APP_LINK } from 'modules/navigation/constants'
import appStoreIcon from 'assets/images/mobile-app/app-store.svg'

function AppStoreIcon(props: Props) {
	return <StoreIcon {...props} alt={'App Store'} link={APPLE_APP_LINK} image={appStoreIcon} />
}

export default AppStoreIcon
