//@flow
import { connect } from 'react-redux'
import type { State, Dispatch, AccountingDocumentReduxAction, ActivityResponse } from 'types'
import { fetchAccountingDocumentActivities } from 'modules/accounting-document/actions'
import {
	getAccountingDocumentActivitiesLoadingFlag,
	getAccountingDocumentActivities,
} from 'modules/accounting-document/selectors'
import Activities from '../../components/invoice-elements/activities-dialog'

type StateProps = {|
	activities: Array<ActivityResponse>,
	loading: boolean,
|}

type OwnProps = {|
	accountingDocumentId: string,
|}

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => {
	return {
		activities: getAccountingDocumentActivities(state, ownProps.accountingDocumentId),
		loading: getAccountingDocumentActivitiesLoadingFlag(state, ownProps.accountingDocumentId),
	}
}

type DispatchProps = {|
	fetchAccountingDocumentActivities: () => void,
|}

const mapDispatchToProps = (dispatch: Dispatch<AccountingDocumentReduxAction>, ownProps: OwnProps): DispatchProps => {
	return {
		fetchAccountingDocumentActivities: () => {
			dispatch(fetchAccountingDocumentActivities(ownProps.accountingDocumentId))
		},
	}
}

const mergeProps = (stateProps: StateProps, dispatchProps: DispatchProps) => {
	return {
		...stateProps,
		...dispatchProps,
	}
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Activities)
