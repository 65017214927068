// @flow

import type { DataGridId, Enum } from 'types'
// $FlowFixMe - there is useMemo
import React, { useEffect, useMemo } from 'react'
import { type WithTranslateProps, withTranslate } from 'wrappers'

import { AccountingDocumentType_Number } from 'types/convertor'
import CashRegisterPayments from 'modules/cash-register/containers/cash-register-payments'
import CashRegisterPaymentsAdvancedFilter from '../components/cash-register-payments-advanced-filter'
import { getCashRegisterFilterName } from '../domain/cash-register'

type Props = {|
	...WithTranslateProps,
	cashRegisterId: string,
	showProcessingState: boolean,
	direction: 'issued' | 'received' | 'unknown',
	internal: ?boolean,
	type: ?number,
	branches: ?Enum,
	projects: ?Enum,
	resetSequencesFilter: (filterName: string) => void,
|}

const getGridId = (cashRegisterId: string): DataGridId => {
	// $FlowFixMe variabilní DataGridId ?
	return 'cashRegisterPayments' + cashRegisterId
}

function CashRegisterBalances(props: Props) {
	const { resetSequencesFilter } = props

	useEffect(() => {
		resetSequencesFilter(filterName)
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const defaultFilter = useMemo(
		() => [
			{
				field: 'cashRegisterId',
				value: props.cashRegisterId || '',
			},
			{
				field: 'type',
				valueContains: [
					AccountingDocumentType_Number('cash_receipt'),
					AccountingDocumentType_Number('simplified_invoice'),
					AccountingDocumentType_Number('employee_advance'),
					AccountingDocumentType_Number('cash_transfer'),
					AccountingDocumentType_Number('cash_uncategorized'),
				],
			},
			{
				field: 'excludeAccDocSearchThresholdId',
				value: true,
			},
		],
		[props.cashRegisterId],
	)
	const filterName = getCashRegisterFilterName(props.direction)

	return (
		<div>
			<CashRegisterPaymentsAdvancedFilter
				direction={props.direction || undefined}
				type={props.type || undefined}
				cashRegisterId={props.cashRegisterId}
				defaultFilter={defaultFilter}
				gridId={getGridId(props.cashRegisterId)}
				internal={props.internal}
				name={filterName}
				branches={props.branches}
				projects={props.projects}
			/>
			<CashRegisterPayments
				id={props.cashRegisterId}
				showProcessingState={!!props.showProcessingState}
				internal={props.internal}
				defaultFilter={defaultFilter}
				gridId={getGridId(props.cashRegisterId)}
				filterId={filterName}
			/>
		</div>
	)
}

export default withTranslate(CashRegisterBalances)
