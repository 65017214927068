// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[3].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js!../../../layouts/main-layout.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".I4H9LaiFjQ4275R74guNs {\n}\n\n@media screen and (min-width: 1440px) {\n\t.I4H9LaiFjQ4275R74guNs {\n\t\tmax-width: 1070px;\n\t}\n}\n\n._1iOCxz-Ee9Vy5qDNyNJGce {\n\tdisplay: flex;\n}\n\n._3q7ALEVsHYPHt9z4EmQrCu {\n\tflex: 0 0 36%;\n\tpadding-right: 30px;\n}\n\n.Q2SmeOmk23-6__drJzDnV {\n\tflex: 0 0 64%;\n\tmax-width: 64%;\n}\n\n._1jcnSQwEjuILdj2J86115I {\n\tmargin: 10px 0 15px 0;\n}\n\n._2CYfXfJ9AVYzWN9F2pBIk1 {\n\tfont-size: 12px;\n\topacity: 0.4;\n\tmargin-top: 10px;\n}\n", "",{"version":3,"sources":["webpack://./modules/settings/pages/settings-layout.css"],"names":[],"mappings":"AAAA;AAEA;;AAEA;CACC;EACC,iBAAA;CACD;AACD;;AAEA;CACC,aAAA;AACD;;AAEA;CACC,aAAA;CACA,mBAAA;AACD;;AAEA;CACC,aAAA;CACA,cAAA;AACD;;AAEA;CACC,qBAAA;AACD;;AAEA;CACC,eAAA;CACA,YAAA;CACA,gBAAA;AACD","sourcesContent":[".root {\n\tcomposes: page from \"layouts/main-layout.css\";\n}\n\n@media screen and (min-width: 1440px) {\n\t.root {\n\t\tmax-width: 1070px;\n\t}\n}\n\n.container {\n\tdisplay: flex;\n}\n\n.tabs {\n\tflex: 0 0 36%;\n\tpadding-right: 30px;\n}\n\n.content {\n\tflex: 0 0 64%;\n\tmax-width: 64%;\n}\n\n.h1 {\n\tmargin: 10px 0 15px 0;\n}\n\n.build {\n\tfont-size: 12px;\n\topacity: 0.4;\n\tmargin-top: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "I4H9LaiFjQ4275R74guNs " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["page"] + "",
	"container": "_1iOCxz-Ee9Vy5qDNyNJGce",
	"tabs": "_3q7ALEVsHYPHt9z4EmQrCu",
	"content": "Q2SmeOmk23-6__drJzDnV",
	"h1": "_1jcnSQwEjuILdj2J86115I",
	"build": "_2CYfXfJ9AVYzWN9F2pBIk1"
};
export default ___CSS_LOADER_EXPORT___;
