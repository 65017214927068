/* @flow */
/** @jsx jsx */

import React, { Component } from 'react'
import { jsx } from '@emotion/core'
import memoizeOne from 'memoize-one'
import type { TriviStoreCard as TriviStoreCardType, PortalLanguage } from 'types'
import TriviStoreCard from './trivi-store-card'
// import { getDialogComponent } from '../domain'
import TriviStoreDialog from './trivi-store-dialog'

export type Props = {|
	language: ?PortalLanguage,
	items: Array<TriviStoreCardType>,
|}

type State = {|
	openedCard: ?TriviStoreCardType,
|}

class TriviStoreGrid extends Component<Props, State> {
	state = {
		openedCard: null,
	}

	onCardClick = (openedCard: TriviStoreCardType) => {
		this.setState({ openedCard })
	}

	onRequestClose = () => {
		this.setState({ openedCard: null })
	}

	mapCards = memoizeOne((items: Array<TriviStoreCardType>) => {
		return items.map((card: TriviStoreCardType) => {
			return (
				<div key={card.headline} css={styles.card}>
					<TriviStoreCard card={card} onClick={this.onCardClick} />
				</div>
			)
		})
	})

	render() {
		// const Dialog = getDialogComponent(this.state.openedCard)
		const Dialog = TriviStoreDialog

		return (
			<React.Fragment>
				<div css={styles.cards}>{this.mapCards(this.props.items)}</div>
				<Dialog language={this.props.language} card={this.state.openedCard} onRequestClose={this.onRequestClose} />
			</React.Fragment>
		)
	}
}

const styles = {
	cards: {
		margin: -18,
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'flex-start',
		flexWrap: 'wrap',
	},
	card: {
		flex: '0 0 33.3%',
		padding: 18,
	},
}

export default TriviStoreGrid
