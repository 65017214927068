/* @flow */

import {
	bankAccountPaymentOrders as bankAccountPaymentOrdersApi,
	type BankAccountPaymentOrdersParams,
	companyTypes as companyTypesApi,
	countries as countriesApi,
	currencies as currenciesApi,
	enums as enumsApi,
	paymentOrders as paymentOrdersApi,
} from 'modules/common/models/api-model'
import { createXHR } from 'lib/apiHandler'
import type {
	CommonAction,
	CompanyType,
	Currency,
	Dispatch,
	Enum,
	PaymentOrder,
	State,
	ValidationError,
	NotificationType,
	CardResponseParametersPayment,
} from 'types'
import { clearLastFetchedTime, needsRefresh } from 'utils/fetch-throttle'
import { getNotification } from '../selectors'

export * from './country-variant-actions'

export function showServerError(serverError: ValidationError) {
	return {
		type: 'SHOW_SERVER_ERROR',
		serverError,
	}
}

export function clearServerError() {
	return {
		type: 'CLEAR_SERVER_ERROR',
	}
}

export function clearValidationError() {
	return {
		type: 'CLEAR_VALIDATION_ERROR',
	}
}

export function setNotification(notification: NotificationType, ttlSeconds?: number) {
	return (dispatch: Dispatch<CommonAction>, getState: () => State) => {
		if (ttlSeconds) {
			setTimeout(() => {
				const currentNotification = getNotification(getState())
				if (notification.message === (currentNotification && currentNotification.message)) {
					dispatch(clearNotification())
				}
			}, ttlSeconds * 1000)
		}

		dispatch({
			type: 'SET_NOTIFICATION',
			notification,
		})
	}
}

export function clearNotification() {
	return {
		type: 'CLEAR_NOTIFICATION',
	}
}

export function loadPaymentTypes() {
	return async (dispatch: Dispatch<CommonAction>) => {
		dispatch({
			type: 'START_LOAD_PAYMENT_TYPES',
		})
		try {
			const result: Enum = await enumsApi.get({ type: 'paymentTypes' })
			dispatch({
				type: 'FINISH_LOAD_PAYMENT_TYPES',
				paymentTypes: result || [],
			})
		} catch (error) {
			dispatch({
				type: 'FINISH_LOAD_PAYMENT_TYPES',
				paymentTypes: null,
				serverError: error,
			})
		}
	}
}

export function loadCurrencies() {
	return async (dispatch: Dispatch<CommonAction>) => {
		if (!needsRefresh('currenciesLastFetched')) {
			return
		}
		dispatch({
			type: 'START_LOAD_CURRENCIES',
		})
		try {
			const result: Array<Currency> = await currenciesApi.get({})
			dispatch({
				type: 'FINISH_LOAD_CURRENCIES',
				currencies: result,
			})
		} catch (error) {
			clearLastFetchedTime('currenciesLastFetched')
			dispatch({
				type: 'FINISH_LOAD_CURRENCIES',
				currencies: null,
				serverError: error,
			})
		}
	}
}

export const loadCountries = () => {
	return async (dispatch: Dispatch<CommonAction>) => {
		if (!needsRefresh('countriesLastFetched')) {
			return
		}
		dispatch({
			type: 'START_LOAD_COUNTRIES',
		})

		try {
			const result = await countriesApi.get({})

			dispatch({
				type: 'FINISH_LOAD_COUNTRIES',
				countries: result,
			})
		} catch (error) {
			clearLastFetchedTime('countriesLastFetched')
			dispatch({
				type: 'FINISH_LOAD_COUNTRIES',
				countries: null,
				serverError: error,
			})
		}
	}
}

export function loadNaceCodes() {
	return async (dispatch: Dispatch<CommonAction>) => {
		dispatch({
			type: 'START_LOAD_NACE_CODES',
		})
		try {
			const naceCodes: Enum = await enumsApi.get({ type: 'naceCodes', originCountry: 'cz' })
			dispatch({
				type: 'FINISH_LOAD_NACE_CODES',
				naceCodes,
			})
		} catch (serverError) {
			dispatch({
				type: 'FINISH_LOAD_NACE_CODES',
				naceCodes: null,
				serverError,
			})
		}
	}
}

export function loadTaxOffices() {
	return async (dispatch: Dispatch<CommonAction>) => {
		dispatch({
			type: 'START_LOAD_TAX_OFFICES',
		})
		try {
			const taxOffices: Enum = await enumsApi.get({ type: 'taxOffices' })
			dispatch({
				type: 'FINISH_LOAD_TAX_OFFICES',
				taxOffices,
			})
		} catch (serverError) {
			dispatch({
				type: 'FINISH_LOAD_TAX_OFFICES',
				taxOffices: null,
				serverError,
			})
		}
	}
}

export function loadLegalForms() {
	return async (dispatch: Dispatch<CommonAction>) => {
		dispatch({
			type: 'START_LOAD_LEGAL_FORMS',
		})
		try {
			const legalForms: Enum = await enumsApi.get({ type: 'legalForms' })
			dispatch({
				type: 'FINISH_LOAD_LEGAL_FORMS',
				legalForms,
			})
		} catch (serverError) {
			dispatch({
				type: 'FINISH_LOAD_LEGAL_FORMS',
				legalForms: null,
				serverError,
			})
		}
	}
}

export function loadCompanyTypes() {
	return async (dispatch: Dispatch<CommonAction>) => {
		dispatch({
			type: 'START_LOADING_COMPANY_TYPES',
		})
		try {
			const companyTypes: Array<CompanyType> = await companyTypesApi.get({})
			dispatch({
				type: 'FINISH_LOADING_COMPANY_TYPES',
				companyTypes,
			})
		} catch (serverError) {
			dispatch({
				type: 'FINISH_LOADING_COMPANY_TYPES',
				companyTypes: null,
				serverError,
			})
		}
	}
}

export function placePaymentOrder(placePaymentOrderArgs: BankAccountPaymentOrdersParams, paymentOrder: PaymentOrder) {
	return async (dispatch: Dispatch<CommonAction>) => {
		dispatch({
			type: 'START_PLACE_PAYMENT_ORDER',
		})
		try {
			await bankAccountPaymentOrdersApi.post(placePaymentOrderArgs, paymentOrder)

			return dispatch({
				type: 'FINISH_PLACE_PAYMENT_ORDER',
			})
		} catch (error) {
			dispatch({
				type: 'FINISH_PLACE_PAYMENT_ORDER',
				serverError: error,
			})
			return Promise.reject()
		}
	}
}

export function hotkeyEvent(key: string, event: SyntheticKeyboardEvent<HTMLElement>) {
	return {
		type: 'HOTKEY_EVENT',
		key,
		event,
	}
}

export function updateSelectedDocumentsIds(payload: Array<string>) {
	return {
		type: 'UPDATE_SELECTED_DOCUMENTS_IDS',
		payload,
	}
}

export function loadPaymentOrdersOfAccDocs(accDocIds: Array<string>) {
	return (dispatch: Dispatch<CommonAction>) => {
		accDocIds.map(async (accountingDocumentId: string) => {
			dispatch({
				type: 'START_LOADING_PAYMENT_ORDERS',
				accDocId: accountingDocumentId,
			})
			try {
				const response = await paymentOrdersApi.get({ accountingDocumentId })
				dispatch({
					type: 'FINISH_LOADING_PAYMENT_ORDERS',
					payload: response.paymentOrders,
					accDocId: accountingDocumentId,
				})
			} catch (serverError) {
				dispatch({
					type: 'FINISH_LOADING_PAYMENT_ORDERS',
					serverError,
				})
			}
		})
	}
}

export function loadQrCode(payment: CardResponseParametersPayment) {
	return async (dispatch: Dispatch<CommonAction>) => {
		dispatch({
			type: 'START_LOADING_QR_CODE',
		})
		return new Promise((resolve: Function, reject: Function) => {
			try {
				const xhr = createXHR('qr', 'POST', true)
				xhr.setRequestHeader('Content-type', 'application/json')
				xhr.responseType = 'arraybuffer'
				xhr.onload = (event: ProgressEvent) => {
					const target: Object = event.target
					if (target.status == 200) {
						const uInt8Array: Uint8Array = new Uint8Array(target.response)
						let i = uInt8Array.length
						let binaryString = new Array(i)
						while (i--) {
							binaryString[i] = String.fromCharCode(uInt8Array[i])
						}
						const base64 = window.btoa(binaryString.join(''))
						const response = 'data:image;base64,' + base64

						dispatch({
							type: 'FINISH_LOADING_QR_CODE',
							qrCode: response,
						})

						resolve(response)
					} else {
						dispatch({
							type: 'FINISH_LOADING_QR_CODE',
						})
						reject(null)
					}
				}
				xhr.send(
					JSON.stringify({
						bankAccountIban: payment.bankAccount && payment.bankAccount.iban ? payment.bankAccount.iban : null,
						bankAccountNumber: payment.bankAccount && payment.bankAccount.number ? payment.bankAccount.number : null,
						bankAccountCode: payment.bankAccount && payment.bankAccount.bankCode ? payment.bankAccount.bankCode : null,
						amount: payment.amount,
						currency: payment.currency,
						dueDate: payment.dueDate,
						variableSymbol: payment.variableSymbol,
						specificSymbol: payment.specificSymbol,
						constantSymbol: payment.constantSymbol,
					}),
				)
			} catch (serverError) {
				dispatch({
					type: 'FINISH_LOADING_QR_CODE',
					serverError,
				})
				reject(null)
			}
		})
	}
}
