/* @flow */
/** @jsx jsx */

import { jsx } from '@emotion/core'
import { Component } from 'react'
import memoize from 'memoize-one'
import type { Action } from 'modules/data-grid/types'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import IconMenu from 'components/icon-menu'
import MenuItem from 'components/menu-item'
import Tooltip from 'components/tooltip'

type Props<Item> = {|
	...WithTranslateProps,
	loading: boolean,
	actions: Array<Action<Item>>,
	row: Item,
	onAction: (string, Item) => void,
	onActionMenuOpen: (Item, () => void) => ?Promise<void>,
|}

type State = {|
	open: boolean,
	tooltip: boolean,
|}

class MoreActions<Item> extends Component<Props<Item>, State> {
	state = {
		open: false,
		tooltip: true,
	}

	handleActionChange = (event: SyntheticEvent<HTMLElement>, actionName: string) => {
		this.props.onAction(actionName, this.props.row)
	}

	handleMenuRequestChange = (open: boolean) => {
		if (open && this.props.onActionMenuOpen) {
			this.props.onActionMenuOpen(this.props.row, () => {
				this.setState({
					tooltip: false,
					open,
				})
			})
		} else {
			this.setState({
				tooltip: true,
				open,
			})
		}
	}

	getRelevantActions = memoize(<Item>(actions: Array<Action<Item>>, row: Item) => {
		const relevantActions = actions.filter((action: Action<Item>) => {
			return !action.isVisibleFn || action.isVisibleFn(row)
		})

		return relevantActions.map((action: Action<Item>) => (
			<MenuItem key={action.type} primaryText={action.name} icon={action.icon || <span />} value={action.type} tall />
		))
	})

	render() {
		if (!this.props.actions.length) return null

		return (
			<div css={styles.root}>
				<Tooltip disabled={!this.state.tooltip} label={this.props.t('invoice.moreActions')}>
					<IconMenu
						loading={this.props.loading}
						onChange={this.handleActionChange}
						onRequestChange={this.handleMenuRequestChange}
						open={this.state.open}
						autoTestId="invoice-more-actions-actions"
					>
						{this.getRelevantActions(this.props.actions, this.props.row)}
					</IconMenu>
				</Tooltip>
			</div>
		)
	}
}

const styles = {
	root: {
		display: 'inline-block',
		height: 50,
		fontSize: 14,
		lineHeight: '50px',
		verticalAlign: 'middle',
		whiteSpace: 'nowrap',
	},
}

export default withTranslate(MoreActions)
