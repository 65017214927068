/* @flow */

import type {
	AccountingDocument,
	AccountingDocumentAssignedDirection,
	AccountingDocumentConnection,
	AccountingDocumentContact,
	AccountingDocumentInfo,
	AccountingDocumentReduxAction,
	AccountingDocumentType,
	CommonAction,
	Dispatch,
	IssueAction,
	State,
	UserAction,
} from 'types'
import {
	connectAccountingDocumentConnection,
	disconnectAccountingDocumentConnection,
	loadAccountingDocumentVatRecapInfo,
	replaceAccountingDocumentBankAccounts,
	replaceAccountingDocumentLineItems,
	updateAccountingDocumentContact,
	updateAccountingDocumentRelatedAccountingDocument,
	updateAccountingDocumentTotalAndTotalVatExcl,
} from '../../actions'
import { getAccountingDocumentConnections, getAccountingDocumentContact } from '../../selectors'

import type { AccountingDocumentItem } from 'modules/accounting-document/reducers/accounting-documents'
import AccountingDocumentPicker from 'modules/accounting-document/containers/accounting-document-picker/picker'
import { AccountingDocumentType_Number } from 'types/convertor'
import type { AddConnectionOptions } from '../../components/accounting-document-picker/picker'
import type { FinishConnectAccountingDocumentConnectionAction } from 'modules/accounting-document/actions/accounting-document-action-types'
import { connect } from 'react-redux'
import { getAccDocToPayDomestic } from '../../../../types/operations'
import { getConnectionFromDocument } from 'modules/accounting-document/domain/accounting-document'

const mapStateToProps = (state: State, ownProps: OwnProps) => {
	const connections: ?Array<AccountingDocumentConnection> = getAccountingDocumentConnections(
		state,
		ownProps.accountingDocumentId,
	)
	const selected: ?Array<AccountingDocumentInfo> =
		connections &&
		connections.map((connection: AccountingDocumentConnection) => {
			return {
				accountingDocumentId: connection.connectedAccountingDocumentId,
				accountingDocumentNo: connection.connectedAccountingDocumentNo,
			}
		})

	const item: ?AccountingDocumentItem =
		state.accountingDocument.accountingDocuments.items[ownProps.accountingDocumentId]
	const accountingDocument: ?AccountingDocument = item && item.data
	const alreadyConnectedDocuments: number = !item ? 0 : (item.connections || []).length

	return {
		selected,
		accountingDocument,
		alreadyConnectedDocuments,
		currentContact: getAccountingDocumentContact(state, ownProps.accountingDocumentId),
	}
}

const mapDispatchToProps = (
	dispatch: Dispatch<AccountingDocumentReduxAction | IssueAction | UserAction | CommonAction>,
	ownProps: OwnProps,
) => {
	return {
		onAddConnection: async (
			document: AccountingDocument,
			connectingDocument: AccountingDocument,
			currentContact: ?AccountingDocumentContact,
			alreadyConnectedDocuments: number,
			options: AddConnectionOptions,
		) => {
			if (document.type === AccountingDocumentType_Number('credit_note') && alreadyConnectedDocuments >= 1) {
				return dispatch({
					type: 'SET_NOTIFICATION',
					notification: {
						message: 'clientError.noMoreDocumentConnections',
						type: 'error',
					},
				})
			}

			if (!options.asRelatedDocument) {
				// attaching advance to taxAdvance
				if (
					(connectingDocument.type === AccountingDocumentType_Number('advance') ||
						connectingDocument.type === AccountingDocumentType_Number('tax_advance')) &&
					!options.recalculateOnly
				) {
					const includeFinAccount = document.type !== AccountingDocumentType_Number('invoice')
					await dispatch(
						replaceAccountingDocumentLineItems(
							connectingDocument,
							document,
							false,
							!options.deletePrevItems ? false : alreadyConnectedDocuments === 0,
							includeFinAccount,
						),
					)
					if (document.type === AccountingDocumentType_Number('tax_advance') && !options.recalculateOnly) {
						await dispatch(replaceAccountingDocumentBankAccounts(connectingDocument, document))
					}
				}

				const connection: AccountingDocumentConnection = getConnectionFromDocument(connectingDocument)
				const connectionResult: FinishConnectAccountingDocumentConnectionAction = await dispatch(
					connectAccountingDocumentConnection(ownProps.accountingDocumentId, connection),
				)

				if (connectionResult.serverError) {
					return
				}

				if (!currentContact && connectingDocument.contact && !options.recalculateOnly) {
					await dispatch(
						updateAccountingDocumentContact(ownProps.accountingDocumentId, connectingDocument.contact, false),
					)
				}

				// attaching invoice to creditNote
				if (
					connectingDocument.type === AccountingDocumentType_Number('invoice') &&
					document.type === AccountingDocumentType_Number('credit_note') &&
					!options.recalculateOnly
				) {
					await dispatch(
						replaceAccountingDocumentLineItems(connectingDocument, document, true, options.deletePrevItems),
					)
				}

				// attaching advance or taxAdvance to invoice
				if (
					(connectingDocument.type === AccountingDocumentType_Number('advance') ||
						connectingDocument.type === AccountingDocumentType_Number('tax_advance')) &&
					document.type === AccountingDocumentType_Number('invoice') &&
					!options.recalculateOnly
				) {
					await dispatch(replaceAccountingDocumentBankAccounts(connectingDocument, document))
				}

				dispatch(loadAccountingDocumentVatRecapInfo(ownProps.accountingDocumentId))
			} else {
				await dispatch(
					updateAccountingDocumentRelatedAccountingDocument(ownProps.accountingDocumentId, connectingDocument.id || ''),
				)
				const amount = getAccDocToPayDomestic(connectingDocument)
				dispatch(updateAccountingDocumentTotalAndTotalVatExcl(ownProps.accountingDocumentId, amount))
			}
		},
		onRemoveConnection: async (connectingDocument: AccountingDocument, asRelatedDocument: boolean) => {
			if (!asRelatedDocument) {
				await dispatch(
					disconnectAccountingDocumentConnection(
						ownProps.accountingDocumentId,
						getConnectionFromDocument(connectingDocument),
					),
				)
				dispatch(loadAccountingDocumentVatRecapInfo(ownProps.accountingDocumentId))
			}

			dispatch(updateAccountingDocumentRelatedAccountingDocument(ownProps.accountingDocumentId, null))
		},
	}
}

export type OwnProps = {|
	accountingDocumentId: string,
	type: AccountingDocumentType,
	asRelatedDocument?: boolean,
	direction?: ?AccountingDocumentAssignedDirection,
	allowedTypes?: ?Array<number>,
	checkAllOption?: boolean,
	readonly?: boolean,
	light?: boolean,
|}

const mergeProps = (stateProps, dispatchProps, ownProps: OwnProps) => {//eslint-disable-line
	const doc: ?AccountingDocument = stateProps.accountingDocument

	return {
		readonly: ownProps.readonly,
		light: ownProps.light,
		selected: stateProps.selected || [],
		contactId: stateProps.currentContact && stateProps.currentContact.contactId,
		onAddConnection: async (connectingDocument: AccountingDocument, options: AddConnectionOptions) => {
			doc &&
				(await dispatchProps.onAddConnection(
					doc,
					connectingDocument,
					stateProps.currentContact,
					stateProps.alreadyConnectedDocuments,
					options,
				))
		},
		onRemoveConnection: dispatchProps.onRemoveConnection,
		hideSelectedFiles: true,
		allowedTypes: ownProps.allowedTypes,
		accountingDocumentId: ownProps.accountingDocumentId,
		asRelatedDocument: ownProps.asRelatedDocument,
		direction: ownProps.direction,
		paidConfirmed: doc && AccountingDocumentType_Number('cash_receipt') === doc.type ? false : undefined,
		checkAllOption: ownProps.checkAllOption,
	}
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(AccountingDocumentPicker)
