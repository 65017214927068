/* @flow */

import type {
	Dispatch,
	OrganizationAction,
	Organization,
	OrganizationSettings,
	OrganizationAddress,
	SettingsAction,
	State,
	OrganizationSettingsOpen,
	OrganizationSettingsOssCountries,
} from 'types'
import {
	getOrganizationLogo,
	loadOrganizationSettings,
	loadOrganizationSettingsOpen,
	removeOrganizationLogo,
	updateOrganizationSettings,
	uploadOrganizationLogo,
	loadTaxPaymentHistory,
	loadOrganizationSettingsOss,
} from 'modules/settings/actions'
import { changeOrganizationAddress } from 'modules/organization/actions'
import {
	getCurrentOrganization,
	isOrganizationEetActiveSelector,
	isCashbotEnabledSelector,
} from 'modules/organization/selectors'
import { getIsOrganizationVatFree, getOrganizationSettingsOss } from 'modules/settings/selectors'
import { type ComponentType, type ElementConfig, type Component } from 'react'
import { connect } from 'react-redux'

type StateProps = {|
	organization: ?Organization,
	organizationSettings: ?OrganizationSettings,
	isOrganizationVatFree: boolean,
	organizationSettingsOpen: ?OrganizationSettingsOpen,
	isEetSettingsDefined: boolean,
	isCashbotEnabled: boolean,
	oss: ?OrganizationSettingsOssCountries,
|}

const mapStateToProps = (state: State): StateProps => {
	return {
		organization: getCurrentOrganization(state),
		organizationSettings: state.settings.organizationSettings,
		organizationSettingsOpen: state.settings.organizationSettingsOpen,
		isOrganizationVatFree: getIsOrganizationVatFree(state),
		isEetSettingsDefined: isOrganizationEetActiveSelector(state),
		isCashbotEnabled: isCashbotEnabledSelector(state),
		oss: getOrganizationSettingsOss(state),
	}
}

type DispatchProps = {|
	uploadOrganizationLogo: (body: File) => Promise<any>,
	removeOrganizationLogo: () => void,
	getOrganizationLogo: () => void,
	loadOrganizationSettings: () => void,
	loadOrganizationSettingsOpen: () => void,
	updateOrganizationSettings: (oldSettings: OrganizationSettings, newSettings: OrganizationSettings) => Promise<any>,
	changeOrganizationAddress: (address: OrganizationAddress) => Promise<any>,
	loadOrganizationSettingsOss: () => void,
|}

const mapDispatchToProps = (dispatch: Dispatch<SettingsAction | OrganizationAction>): DispatchProps => {
	return {
		uploadOrganizationLogo: async (body: File) => {
			await dispatch(uploadOrganizationLogo({}, body)).then(async () => {
				return await dispatch(getOrganizationLogo())
			})
		},
		removeOrganizationLogo: () => {
			dispatch(removeOrganizationLogo({})).then(() => {
				dispatch(getOrganizationLogo())
			})
		},
		getOrganizationLogo: () => {
			dispatch(getOrganizationLogo())
		},
		loadOrganizationSettings: () => {
			dispatch(loadOrganizationSettings())
		},
		loadOrganizationSettingsOpen: () => {
			dispatch(loadOrganizationSettingsOpen())
		},
		loadOrganizationSettingsOss: () => {
			dispatch(loadOrganizationSettingsOss())
		},
		updateOrganizationSettings: async (oldSettings: OrganizationSettings, newSettings: OrganizationSettings) => {
			await dispatch(updateOrganizationSettings(oldSettings, newSettings))
			dispatch(loadTaxPaymentHistory())
		},
		changeOrganizationAddress: async (address: OrganizationAddress) => {
			await dispatch(changeOrganizationAddress(address))
		},
	}
}

export type AddedProps = {| ...StateProps, ...DispatchProps |}

export default function withOrganizationSettings<P: any, C: ComponentType<P>>(
	WrappedComponent: C,
): Class<Component<$Diff<ElementConfig<C>, AddedProps>, any>> {
	return connect(mapStateToProps, mapDispatchToProps)(WrappedComponent)
}
