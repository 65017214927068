//@flow
import { getHubConnection, getAllNotification } from '../selectors'
import type { ISignalMessage } from 'types'

export default function signalRInvokeMiddleware(store: any) {
	return (next: any) => async (action: any) => {
		const connection = getHubConnection(store.getState())
		const notifications = getAllNotification(store.getState())
		switch (action.type) {
			case 'MARK_AS_READ':
				connection && connection.invoke('markRead', [action.payload])
				break
			case 'MARK_ALL_AS_READ':
				if (connection) {
					connection.invoke(
						'markRead',
						notifications.map((i: ISignalMessage) => i.id),
					)
					connection.invoke('getSignalHistory')
				}
		}

		return next(action)
	}
}
