/* @flow */

import deepEqual from 'deep-equal'
import type {
	AccountingDocument,
	AccountingDocumentAssignedType,
	AccountingDocumentBankAccount,
	AccountingDocumentHeader,
	AccountingDocumentLineItem,
	AccountingDocumentVatRecapInfo,
	AccountingDocumentContact,
	Contact,
	Template,
	TemplateScheduler,
	EsoConnectionState,
	Organization,
	FinancialAccount,
} from 'types'
import { emptyAccountingDocument, emptyAccountingDocumentHeader, emptyContact } from 'types/empty'
import {
	AccountingDocumentContact_ContactAddress,
	Number_EsoConnectionState,
	Number_AccountingDocumentAssignedType,
	Number_AccountingDocumentAssignedDirection,
} from 'types/convertor'
import { compareStringValues } from 'utils'
import { difference } from 'lodash-es'

const ACCDOC_SIMPLE_TYPES = ['cash_receipt', 'cash_transfer', 'cash_uncategorized', 'employee_advance']

export function mergeAccountingDocumentToAccountingDocumentHeader(
	accountingDocument: ?AccountingDocument,
	header: ?AccountingDocumentHeader,//eslint-disable-line
): AccountingDocumentHeader {
	const accDoc: AccountingDocument = accountingDocument || emptyAccountingDocument()
	const merged: AccountingDocumentHeader = {
		issueDate: accDoc.issueDate,
		dueDate: accDoc.dueDate,
		bookingDate: accDoc.bookingDate,
		taxReturnDate: accDoc.taxReturnDate,
		orderNo: accDoc.orderNo,
		taxDate: accDoc.taxDate,
		paymentType: accDoc.paymentType,
		exchRate: accDoc.exchRate,
		vatExchRate: accDoc.vatExchRate,
		sadExchRate: accDoc.sadExchRate,
		currency: accDoc.currency,
		language: accDoc.language,
		variableSymbol: accDoc.variableSymbol,
		variableSymbolPreview: accDoc.variableSymbolPreview,
		specificSymbol: accDoc.specificSymbol,
		constSymbol: accDoc.constSymbol,
		externalNo: accDoc.externalNo,
		description: accDoc.description,
		explicitNo: accDoc.explicitNo,
		sequenceId: accDoc.sequenceId,
		printing: accDoc.printing,
		relatedAccountingDocumentId: accDoc.relatedAccountingDocumentId,
		relatedAccountingDocumentNo: accDoc.relatedAccountingDocumentNo,
		vatCountryType: accDoc.vatCountryType,
		roundingType: accDoc.roundingType,
		vatRecapCalculationMode: accDoc.vatRecapCalculationMode,
		creditNoteDescription: accDoc.creditNoteDescription,
		exchRateDefault: accDoc.exchRateDefault,
		creditNoteDelayedVat: accDoc.creditNoteDelayedVat,
		categoryId: accDoc.categoryId,
		categoryName: accDoc.categoryName,
		notes: accDoc.notes,
		signatureId: accDoc.signatureId,
		vatPaymentMode: accDoc.vatPaymentMode,
		accountingDocumentNoPreview: accDoc.accountingDocumentNoPreview,
		creditNoteNeedsConfirmation: accDoc.creditNoteNeedsConfirmation,
		customerInstructions: accDoc.customerInstructions,
		settings: accDoc.settings,
	}

	return merged
}

export function mergeAccountingDocumentHeaderToAccountingDocument(
	header: ?AccountingDocumentHeader,
	accountingDocument: ?AccountingDocument,
): AccountingDocument {
	const accDocHeader: AccountingDocumentHeader = header || emptyAccountingDocumentHeader()
	const document = accountingDocument || emptyAccountingDocument()
	const result: AccountingDocument = Object.seal({
		...document,
		issueDate: accDocHeader.issueDate,
		bookingDate: accDocHeader.bookingDate,
		dueDate: accDocHeader.dueDate,
		taxReturnDate: accDocHeader.taxReturnDate,
		orderNo: accDocHeader.orderNo,
		taxDate: accDocHeader.taxDate,
		paymentType: accDocHeader.paymentType,
		exchRate: accDocHeader.exchRate,
		vatExchRate: accDocHeader.vatExchRate,
		sadExchRate: accDocHeader.sadExchRate,
		currency: accDocHeader.currency || '',
		language: accDocHeader.language,
		variableSymbol: accDocHeader.variableSymbol,
		variableSymbolPreview: accDocHeader.variableSymbolPreview,
		specificSymbol: accDocHeader.specificSymbol,
		constSymbol: accDocHeader.constSymbol,
		externalNo: accDocHeader.externalNo,
		description: accDocHeader.description,
		explicitNo: accDocHeader.explicitNo,
		sequenceId: accDocHeader.sequenceId,
		printing: accDocHeader.printing,
		relatedAccountingDocumentId: accDocHeader.relatedAccountingDocumentId,
		relatedAccountingDocumentNo: accDocHeader.relatedAccountingDocumentNo,
		roundingType: accDocHeader.roundingType,
		exchRateDefault: accDocHeader.exchRateDefault,
		vatRecapCalculationMode: accDocHeader.vatRecapCalculationMode,
		vatCountryType: accDocHeader.vatCountryType,
		signatureId: accDocHeader.signatureId,
		creditNoteDescription: accDocHeader.creditNoteDescription,
		creditNoteDelayedVat: accDocHeader.creditNoteDelayedVat,
		categoryId: accDocHeader.categoryId,
		categoryName: accDocHeader.categoryName,
		notes: accDocHeader.notes,
		accountingDocumentNoPreview: accDocHeader.accountingDocumentNoPreview,
		vatPaymentMode: accDocHeader.vatPaymentMode,
		creditNoteNeedsConfirmation: accDocHeader.creditNoteNeedsConfirmation,
		customerInstructions: accDocHeader.customerInstructions,
		settings: accDocHeader.settings,
	})

	return result
}

export function addLineItemToAccountingDocument(
	accountingDocument: AccountingDocument,
	lineItem: AccountingDocumentLineItem,
): AccountingDocument {
	let lineItems: Array<AccountingDocumentLineItem> = (accountingDocument && accountingDocument.lineItems) || []
	if (lineItem) {
		lineItems = [...lineItems, lineItem]
	}

	return Object.seal({ ...accountingDocument, lineItems })
}

const DUPLICATE_PREFIX: string = 'duplicate_'

export function getTemplateDuplicateId(duplicateIndex: number): string {
	return DUPLICATE_PREFIX + duplicateIndex
}

export function isTemplateDuplicate(template: Template): boolean {
	return typeof template.id === 'string' && template.id.lastIndexOf(DUPLICATE_PREFIX, 0) === 0 //starts with
}

export function merge_AccountingDocumentVatRecapInfo_to_AccountingDocument(
	vatRecapInfo: AccountingDocumentVatRecapInfo,
	accountingDocument: ?AccountingDocument,
): AccountingDocument {
	let accDoc: AccountingDocument = accountingDocument ? { ...accountingDocument } : emptyAccountingDocument()
	if (!deepEqual(accDoc.vatRecap, vatRecapInfo.vatRecapLines)) accDoc.vatRecap = vatRecapInfo.vatRecapLines || []
	accDoc.total = vatRecapInfo.total
	accDoc.totalVatExcl = vatRecapInfo.totalVatExcl
	accDoc.vatRecapCalculationMode = vatRecapInfo.vatRecapCalculationMode
	accDoc.totalVatAmount = vatRecapInfo.totalVatAmount
	accDoc.roundingAmount = vatRecapInfo.roundingAmount

	return accDoc
}

export function isTemplateSchedulerChanged(
	oldScheduler: ?TemplateScheduler,
	newScheduler: ?TemplateScheduler,
): boolean {
	if (!oldScheduler || !newScheduler) {
		return !oldScheduler != !newScheduler
	}
	return (
		oldScheduler.start !== newScheduler.start ||
		oldScheduler.end !== newScheduler.end ||
		oldScheduler.generateMaxCount !== newScheduler.generateMaxCount ||
		oldScheduler.frequency !== newScheduler.frequency ||
		oldScheduler.useWorkflow !== newScheduler.useWorkflow ||
		oldScheduler.generateOnEndOfMonth !== newScheduler.generateOnEndOfMonth
	)
}

export function AccountingDocumentBankAccount_equalsValues(
	ba1: AccountingDocumentBankAccount,
	ba2: AccountingDocumentBankAccount,
): boolean {
	return (
		compareStringValues(ba1.number, ba2.number) &&
		compareStringValues(ba1.bankCode, ba2.bankCode) &&
		compareStringValues(ba1.iban, ba2.iban) &&
		compareStringValues(ba1.swift, ba2.swift)
	)
}

export function AccountingDocumentBankAccount_equals(
	ba1: AccountingDocumentBankAccount,
	ba2: AccountingDocumentBankAccount,
): boolean {
	return (
		AccountingDocumentBankAccount_equalsValues(ba1, ba2) && compareStringValues(ba1.bankAccountId, ba2.bankAccountId)
	)
}

export function findBankAccountIndex(
	haystack: Array<AccountingDocumentBankAccount>,
	needle: AccountingDocumentBankAccount,
): number {
	const index: ?number = haystack.findIndex((ba: AccountingDocumentBankAccount) => {
		return AccountingDocumentBankAccount_equals(ba, needle)
	})
	if (index === undefined || index === null) {
		return -1
	} else {
		return index
	}
}

export function merge_AccountingDocumentContact_into_Contact(
	contact: ?Contact,
	accDocContact: AccountingDocumentContact,
): Contact {
	return {
		...(contact || emptyContact()),
		externalId: accDocContact.externalId,
		companyName: accDocContact.companyName,
		companyRegNo: accDocContact.companyRegNo,
		taxId: accDocContact.taxId,
		firstName: accDocContact.firstname,
		lastName: accDocContact.lastname,
		email: accDocContact.email,
		addresses: [AccountingDocumentContact_ContactAddress(accDocContact)],
	}
}

export function AccountingDocumentAssignedType_isNonCashRegister(type: AccountingDocumentAssignedType): boolean {
	/* eslint-disable prettier/prettier */
	return [
		'invoice',
		'advance',
		'tax_advance',
		'credit_note',
	].indexOf(type) > -1
	/* eslint-enable prettier/prettier */
}

export function AccountingDocumentAssignedType_isCashRegister(type: AccountingDocumentAssignedType): boolean {
	/* eslint-disable prettier/prettier */
	return [
		'cash_receipt',
		'simplified_invoice',
		'employee_advance',
		'cash_transfer',
		'cash_uncategorized',
	].indexOf(type) > -1
	/* eslint-enable prettier/prettier */
}

export function AccountingDocumentTypeNumber_isCashRegister(number: ?number): boolean {
	const type: ?AccountingDocumentAssignedType = Number_AccountingDocumentAssignedType(number)
	return (type && AccountingDocumentAssignedType_isCashRegister(type)) || false
}

export function getOrganizationEsoConnectionState(organization: Organization): ?EsoConnectionState {
	const stateNumber = organization.connectionState
	return (stateNumber != null && Number_EsoConnectionState(stateNumber)) || null
}

export function getAccDocToPayDomestic(accDoc: AccountingDocument): number {
	return (accDoc.totalDomestic || 0) - (accDoc.amountPaidDomestic || 0)
}

export function getAccDocToPay(accDoc: AccountingDocument): number {
	return (accDoc.total || 0) - (accDoc.amountPaid || 0)
}

export function getAccDocCurrencyDomestic(accDoc: AccountingDocument): ?string {
	return accDoc.currencyDomestic
}

export function AccountingDocument_isCashRegister(accDoc: AccountingDocument): boolean {
	const type = Number_AccountingDocumentAssignedType(accDoc.type)
	return (type && AccountingDocumentAssignedType_isCashRegister(type)) || false
}

export function AccountingDocument_isNonCashRegister(accDoc: AccountingDocument): boolean {
	const type = Number_AccountingDocumentAssignedType(accDoc.type)
	return (type && AccountingDocumentAssignedType_isNonCashRegister(type)) || false
}

export function AccountingDocument_isReceived(accDoc: AccountingDocument): boolean {
	return Number_AccountingDocumentAssignedDirection(accDoc.direction) === 'received'
}

export function AccountingDocument_isIssued(accDoc: AccountingDocument): boolean {
	return Number_AccountingDocumentAssignedDirection(accDoc.direction) === 'issued'
}

export function AccountingDocument_isProcessed(accDoc: AccountingDocument): boolean {
	return accDoc.state === 'Processed'
}

export function AccountingDocumentTypeNumber_isCreditNote(type: ?number): boolean {
	const assignedType: ?AccountingDocumentAssignedType = Number_AccountingDocumentAssignedType(type)
	return assignedType === 'credit_note'
}

export function AccountingDocumentTypeNumber_isSimple(type: ?number): boolean {
	const assignedType: ?AccountingDocumentAssignedType = Number_AccountingDocumentAssignedType(type)
	return ACCDOC_SIMPLE_TYPES.includes(assignedType)
}

export function FinancialAccounts_getFlattenNos(accounts: Array<FinancialAccount>): Array<string> {
	return accounts.reduce((result: Array<string>, account: FinancialAccount) => {
		account.no && result.push(account.no)
		if (account.subFinAccounts && account.subFinAccounts.length) {
			return result.concat(FinancialAccounts_getFlattenNos(account.subFinAccounts))
		} else {
			return result
		}
	}, [])
}

// Asi docela náročná operace, nepoužívat v cyklech
export function FinancialAccounts_isEqualByNo(arr1: Array<FinancialAccount>, arr2: Array<FinancialAccount>): boolean {
	if (arr1.length !== arr2.length) {
		return false
	}

	const ids1 = FinancialAccounts_getFlattenNos(arr1)
	const ids2 = FinancialAccounts_getFlattenNos(arr2)
	if (ids1.length !== ids2.length) {
		return false
	}

	return difference(ids1, ids2).length === 0
}

export function FinancialAccounts_numberExist(arr: Array<FinancialAccount>, no?: string): boolean {
	if (!arr || !arr.length || no == null) {
		return false
	}
	return FinancialAccounts_getFlattenNos(arr).some((n: string) => n === no)
}

export function AccountingDocumentBankAccount_isEmpty(ba: AccountingDocumentBankAccount): boolean {
	return !ba.number && !ba.bankCode && !ba.iban && !ba.swift && !ba.displayNumber
}

export function AccountingDocumentBankAccountNumber_isEmpty(ba: AccountingDocumentBankAccount): boolean {
	return !ba.number && !ba.displayNumber
}
