import React from 'react'
import pure from 'recompose/pure'
import SvgIcon from 'components/SvgIcon'

let Word = props => {
	props = Object.assign({ viewBox: '-4 -2 24 24' }, props)
	return (
		<SvgIcon {...props}>
			<path
				d="M1.99,0 C0.89,0 0,0.9 0,2 L0.01,18 C0.01,19.1 0.9,20 2,20 L10,20 L16,14 L16,2 C16,0.9 15.1,0 14,0 L1.99,0 Z"
				fill="#34424E"
				transform="translate(8.000000, 10.000000) scale(1, -1) translate(-8.000000, -10.000000) "
			/>
			<path
				d="M10.8724664,14.4963117 C11.6072175,14.4963117 12.2171996,13.9972354 12.3974215,13.3456637 L14.3244103,6.62199776 C14.3521368,6.51109193 14.366,6.38632287 14.366,6.26155381 C14.366,5.56839238 13.8114709,5 13.1044462,5 C12.5083274,5 11.9953879,5.42976009 11.8706188,5.99815247 L10.6783812,11.2939058 L9.27819507,5.85952018 C9.15342601,5.36044395 8.70980269,5 8.183,5 C7.65619731,5 7.21257399,5.36044395 7.08780493,5.85952018 L5.68761883,11.2939058 L4.48151794,5.98428924 C4.35674888,5.42976009 3.85767265,5 3.24769058,5 C2.55452915,5 2,5.56839238 2,6.26155381 C2,6.38632287 2.01386323,6.4972287 2.04158969,6.60813453 L3.96857848,13.3456637 C4.14880045,13.9972354 4.75878251,14.4963117 5.49353363,14.4963117 C6.25601121,14.4963117 6.8798565,13.969509 7.03235202,13.2624843 L8.183,8.576713 L9.33364798,13.2624843 C9.4861435,13.969509 10.123852,14.4963117 10.8724664,14.4963117 Z"
				fill="#FFFFFF"
			/>
			<polygon
				fill="#08B0CD"
				transform="translate(13.000000, 3.000000) rotate(-90.000000) translate(-13.000000, -3.000000) "
				points="10 6 10 0 16 0"
			/>
		</SvgIcon>
	)
}
Word = pure(Word)

export default Word
