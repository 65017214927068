// @flow

import React, { PureComponent, type Node } from 'react'
import { autoTestId, type AutoTestProps } from 'utils/tests/autotest'
import memoize from 'memoize-one'
import keycode from 'keycode'
import MenuItem from 'components/menu-item'

type Child = ?React$Element<any>

type Props = {|
	...AutoTestProps,
	disabled?: boolean,
	autoWidth?: boolean,
	fullWidth?: boolean,
	children: ?Node,
	multiple?: boolean,
	style?: Object,
	value?: any,
	onChange?: (event: SyntheticMouseEvent<HTMLElement>, value: any) => void,
	onEscKeyDown?: (event: SyntheticKeyboardEvent<HTMLElement>) => void,
	onItemClick?: (event: SyntheticMouseEvent<HTMLElement>, item: React$Element<typeof MenuItem>, index: number) => void,
|}

class Menu extends PureComponent<Props> {
	static defaultProps = {
		autoWidth: true,
	}

	isChildMenuItem(child: Child) {
		return child && child.type && child.type === MenuItem
	}

	cloneMenuItem(child: React$Element<any>, index: number) {
		const selected = this.isChildSelected(child, this.props)

		let extraProps: Object = {
			checked: !!selected,
		}

		extraProps = {
			...extraProps,
			onItemClick: (event: SyntheticMouseEvent<HTMLElement>) => {
				this.handleMenuItemClick(event, child, index)
			},
		}

		return React.cloneElement(child, extraProps)
	}

	handleKeyDown = (event: SyntheticKeyboardEvent<HTMLElement>) => {
		switch (keycode(event)) {
			case 'esc':
				this.props.onEscKeyDown && this.props.onEscKeyDown(event)
				break
		}
	}

	handleMenuItemClick(event: SyntheticMouseEvent<HTMLElement>, item: React$Element<typeof MenuItem>, index: number) {
		const multiple = this.props.multiple
		let menuValue = this.props.value
		const itemValue = item.props.value

		if (multiple) {
			menuValue = menuValue || []

			const itemIndex = menuValue.indexOf(itemValue)
			const [...newMenuValue] = menuValue
			if (itemIndex === -1) {
				newMenuValue.push(itemValue)
			} else {
				newMenuValue.splice(itemIndex, 1)
			}

			this.props.onChange && this.props.onChange(event, newMenuValue)
		} else if (!multiple && itemValue !== menuValue) {
			this.props.onChange && this.props.onChange(event, itemValue)
		}

		this.props.onItemClick && this.props.onItemClick(event, item, index)
	}

	isChildSelected(child: React$Element<any>, props: Props) {
		const childValue = child.props.value
		const menuValue = this.props.value

		if (props.multiple) {
			return child.props.checked || (menuValue && menuValue.length && menuValue.indexOf(childValue) !== -1)
		} else {
			return child.props.hasOwnProperty('value') && childValue && menuValue === childValue
		}
	}

	getStyles = memoize((fullWidth: ?boolean, style: ?Object) => {
		return {
			root: {
				zIndex: 1000,
				minHeight: 24,
				height: '100%',
				minWidth: 60,
				...style,
			},
			list: {
				display: 'block',
				userSelect: 'none',
				width: fullWidth ? '100%' : 'auto',
				minWidth: 60,
			},
		}
	})

	render() {
		const styles = this.getStyles(this.props.fullWidth, this.props.style)

		const newChildren = React.Children.map(this.props.children, (child: React$Element<any>, index: number) => {
			if (this.isChildMenuItem(child)) {
				return this.cloneMenuItem(child, index)
			} else return child
		})

		return (
			<div onKeyDown={this.handleKeyDown} style={styles.root} {...autoTestId(this.props.autoTestId)}>
				<div style={styles.list}>{newChildren}</div>
			</div>
		)
	}
}

export default Menu
