import React from 'react'
import SvgIcon from 'components/SvgIcon'
import pure from 'recompose/pure'

let Done = props => {
	props = Object.assign({}, props, { viewBox: '0 0 12 12' })

	return (
		<SvgIcon {...props}>
			<g stroke="none" fill="none">
				<path
					d="M4.8,9 L1.8,6 L2.646,5.154 L4.8,7.302 L9.354,2.748 L10.2,3.6 L4.8,9 Z M6,0 C2.688,0 0,2.688 0,6 C0,9.312 2.688,12 6,12 C9.312,12 12,9.312 12,6 C12,2.688 9.312,0 6,0 L6,0 Z"
					fill="#FFFFFF"
				/>
			</g>
		</SvgIcon>
	)
}
Done = pure(Done)

export default Done
