// @flow

import type { Filter, SearchFilter } from 'types'

export function isSearchFilterEmpty(filter: SearchFilter) {
	return !hasSearchFilterValue(filter)
}

export function hasSearchFilterValue(filter: SearchFilter) {
	return (
		(filter.value !== null && filter.value !== undefined) ||
		(filter.valueFrom !== null && filter.valueFrom !== undefined) ||
		(filter.valueTo !== null && filter.valueTo !== undefined) ||
		(filter.valueContains !== null && filter.valueContains !== undefined) ||
		(filter.operation !== null && filter.operation !== undefined)
	)
}

export function getFilter(filter: Filter, field: string): ?SearchFilter {
	return filter.find((item: SearchFilter) => item.field === field)
}
