/* @flow */

import React, { Component } from 'react'
import type { AccountingDocumentView } from 'types'
import { STANDARD_LIST_VIEWS, DEFAULT_LIST_VIEW } from 'modules/accounting-document/constants'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import TabSwitches from 'components/TabSwitches/TabSwitches'
import TabSwitch from 'components/TabSwitches/TabSwitch'
import type { TabValue } from '../../pages/accounting-document-list'

export type Props = {|
	...WithTranslateProps,
	view: AccountingDocumentView,
	direction: ?TabValue,
	showIssuedAccountingDocuments: boolean,
	showReceivedAccountingDocuments: boolean,
	showUnknownAccountingDocuments: boolean,
	showTemplates: boolean,
	automaticApproval: boolean,
	onChangeDirection: (direction: TabValue, redirect?: boolean) => void,
	changeView: (view: AccountingDocumentView) => Promise<void>,
|}

class DirectionViewSwitcher extends Component<Props> {
	componentDidMount() {
		this.changeDirection(this.props.direction)
	}

	changeDirection = (direction: ?TabValue) => {
		if (!direction) return

		let newView = DEFAULT_LIST_VIEW
		if ('unknown' === direction) {
			newView = DEFAULT_LIST_VIEW
		} else {
			newView = STANDARD_LIST_VIEWS.includes(this.props.view) ? this.props.view : DEFAULT_LIST_VIEW
		}

		if (newView !== this.props.view || direction !== this.props.direction) this.props.changeView(newView)
		this.canChangeDirection(direction) && this.props.onChangeDirection(direction)
	}

	canChangeDirection = (direction: ?TabValue): boolean => {
		if ('received' === direction && this.props.showReceivedAccountingDocuments) return true
		if ('issued' === direction && this.props.showIssuedAccountingDocuments) return true
		if ('unknown' === direction && this.props.showUnknownAccountingDocuments) return true
		if ('templates' === direction && this.props.showTemplates) return true
		return false
	}

	render() {
		const { t } = this.props

		const tabs = []

		if (this.props.showReceivedAccountingDocuments)
			tabs.push(<TabSwitch key="received" value="received" label={t('accountingDocument.purchase')} />)

		if (this.props.showIssuedAccountingDocuments)
			tabs.push(<TabSwitch key="issued" value="issued" label={t('accountingDocument.sale')} />)

		if (this.props.showUnknownAccountingDocuments)
			tabs.push(<TabSwitch key="receunknownived" value="unknown" label={t('accountingDocument.uploaded')} />)

		if (this.props.showTemplates)
			tabs.push(<TabSwitch key="templates" value="templates" label={t('accountingDocument.directions.templates')} />)

		return (
			<TabSwitches
				autoTestId="direction-view-compact-tabs"
				onChange={this.changeDirection}
				value={this.props.direction}
			>
				{tabs}
			</TabSwitches>
		)
	}
}

export default withTranslate(DirectionViewSwitcher)
