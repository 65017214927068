/* @flow */

import type { Enum, Filter } from 'types'
import { BasicGridFilter } from 'components'
import { FilterUser } from 'modules/data-grid/components/data-grid-filter'
import type { FilterElement } from 'modules/data-grid/types'
import React, { Component } from 'react'
import type { DataTypes } from 'modules/data-grid-next/types'

import styles from './task-list-advanced-filter.css'
import { withTranslate, type WithTranslateProps } from 'wrappers'

type ComponentProps = WithTranslateProps & {|
	advanced?: boolean,
	filterId: string,
	dataType?: $Keys<DataTypes>, // This is only used to show result count
	gridId?: string, // This is only used to show result count
	defaultFilter?: Filter, // This is only used to show result count
|}

class TaskListAdvancedFilter extends Component<ComponentProps> {
	renderAdvancedFilterItems(): Array<FilterElement> {
		const { t } = this.props
		return [
			<FilterUser className={styles.column} key="authorId" name="authorId" labelText={t('task.filter.author')} />,
			<FilterUser className={styles.column} key="assigneeId" name="assigneeId" labelText={t('task.filter.assignee')} />,
		]
	}

	render() {
		const { t } = this.props

		const tabs: Enum = [
			{
				key: t('task.filter.state.all'),
				value: undefined,
			},
			{
				key: t('task.filter.state.new'),
				value: '0',
			},
			{
				key: t('task.filter.state.inProgress'),
				value: '1',
			},
			{
				key: t('task.filter.state.done'),
				value: '2',
			},
		]

		return (
			<div className={styles.root}>
				<BasicGridFilter
					name={this.props.filterId}
					gridId={this.props.gridId}
					dataType="tasks"
					defaultFilter={this.props.defaultFilter}
					noFilterLabel={t('task.filter.allTasks')}
					organizationFilterName="organizationId"
					dateFilterName="dueDate"
					textFilterName="searchText"
					filterTabsName="state"
					filterTabs={tabs}
				>
					{this.props.advanced && this.renderAdvancedFilterItems()}
				</BasicGridFilter>
			</div>
		)
	}
}

export default withTranslate(TaskListAdvancedFilter)
