/* @flow */

import { Component } from 'react'

type Props = {|
	value?: string | number,
	label: string,
|}

class FilterTabSwitch extends Component<Props> {
	render() {
		return null
	}
}

export default FilterTabSwitch
