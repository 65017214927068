// @flow

import { connect } from 'react-redux'
import type { Dispatch, PaymentReminder, State } from 'types'
import { createPaymentReminder, updatePaymentReminder, loadPaymentReminders } from 'modules/settings/actions'
import type { Action as EmailAction } from 'modules/settings/actions/email-action-types'
import EditPaymentReminder from 'modules/settings/components/trivi-settings/edit-payment-reminder'
import type { Action as PaymentRemindersAction } from 'modules/settings/actions/payment-reminders-action-types'

type StateProps = {|
	paymentReminders: ?Array<PaymentReminder>,
|}

const mapStateToProps = (state: State): StateProps => {
	return {
		paymentReminders: state.settings.paymentReminders,
	}
}

type DispatchProps = {|
	loadPaymentReminders: () => void,
	createPaymentReminder: (paymentReminder: PaymentReminder) => void,
	updatePaymentReminder: (paymentReminder: PaymentReminder) => void,
|}

const mapDispatchToProps = (dispatch: Dispatch<PaymentRemindersAction | EmailAction>): DispatchProps => {
	return {
		loadPaymentReminders: () => {
			dispatch(loadPaymentReminders())
		},
		createPaymentReminder: (paymentReminder: PaymentReminder) => {
			dispatch(createPaymentReminder(paymentReminder))
		},
		updatePaymentReminder: (paymentReminder: PaymentReminder) => {
			paymentReminder.id && dispatch(updatePaymentReminder(paymentReminder.id, paymentReminder))
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(EditPaymentReminder)
