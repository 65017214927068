/* @flow */

import type { Filter, I18NextTranslateFn, StatementsView } from 'types'
import React, { Component } from 'react'
import { type WithTranslateProps, withTranslate } from 'wrappers'

import { BANK_STATEMENTS_STATUS_TYPES } from 'types/convertor'
import BasicGridFilter from 'modules/data-grid/components/basic-grid-filter'
import Button from 'components/button'
import { FilterSelectField } from '../../../data-grid/components/data-grid-filter'
import Tab from 'components/Tabs/Tab'
import Tabs from 'components/Tabs/Tabs'
import memoize from 'memoize-one'
import styles from './bank-account-advanced-filter.css'

type Props = {|
	...WithTranslateProps,
	gridId: string,
	view: StatementsView,
	defaultFilter: Filter,
	statementOptionsButton: boolean,
	customStatementsOptionsRenderer: any,
	statementOptionsDialogToggle: (open: 'OPEN' | 'CLOSE', format: 'pdf' | 'gpc' | 'cba_xml') => void,
	onViewChange?: (view: StatementsView) => void,
|}

class StatementsAdvancedFilter extends Component<Props> {
	onOptionsOpen(format: 'pdf' | 'gpc' | 'cba_xml') {
		return () => this.props.statementOptionsDialogToggle('OPEN', format)
	}

	getProcessingStateOptions = memoize((t: I18NextTranslateFn) => {
		return Object.keys(BANK_STATEMENTS_STATUS_TYPES).map((key: string) => {
			return {
				value: key,
				label: t(`accountingDocument.filter.bankAccounts.processingStates.${BANK_STATEMENTS_STATUS_TYPES[key]}`),
			}
		})
	})

	renderSettings = () => {
		const { statementOptionsButton, customStatementsOptionsRenderer, t } = this.props
		if (customStatementsOptionsRenderer) {
			return customStatementsOptionsRenderer
		} else if (statementOptionsButton) {
			return (
				<span style={{ marginLeft: -24 }}>
					<Button
						transparent
						onClick={this.onOptionsOpen('pdf')}
						labelText={t('bank.statementOptions.openDialogButtonPdf')}
					/>
					<Button
						transparent
						onClick={this.onOptionsOpen('gpc')}
						labelText={t('bank.statementOptions.openDialogButtonGpc')}
					/>
					<Button
						transparent
						onClick={this.onOptionsOpen('cba_xml')}
						labelText={t('bank.statementOptions.openDialogButtonXml')}
					/>
				</span>
			)
		}
	}

	renderLeftElements = () => {
		return (
			<Tabs autoTestId="grid-filter-view-tabs" onChange={this.props.onViewChange} value={this.props.view}>
				<Tab className={styles.tab} label={this.props.t('bankAccount.filter.all')} value="all" />
				<Tab className={styles.tab} label={this.props.t('bankAccount.filter.pdf')} value="humanReadable" />
				<Tab className={styles.tab} label={this.props.t('bankAccount.filter.data')} value="data" />
			</Tabs>
		)
	}

	render() {
		const { t } = this.props
		return (
			<div>
				{this.renderSettings()}
				<BasicGridFilter
					name={this.props.gridId}
					gridId={this.props.gridId}
					dataType="statements"
					defaultFilter={this.props.defaultFilter}
					dateFilterName="uploaded"
					textFilterName="searchText"
					noFilterLabel={t('bankAccount.filter.allAccountStatements')}
					leftElement={this.renderLeftElements()}
				>
					<FilterSelectField
						compact
						className={styles.column}
						name="processingState"
						labelText={t('accountingDocument.filter.bankAccounts.state')}
						options={this.getProcessingStateOptions(this.props.t)}
						isClearable
						isSearchable
					/>
				</BasicGridFilter>
			</div>
		)
	}
}

export default withTranslate(StatementsAdvancedFilter)
