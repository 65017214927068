/* @flow */
/** @jsx jsx */

import { jsx } from '@emotion/core'
import DownArrow from 'components/svg-icons/hardware/keyboard-arrow-down'
import UpArrow from 'components/svg-icons/hardware/keyboard-arrow-up'
import IconButton from 'components/icon-button'
import { colors } from 'variables'

type Props = {|
	open: boolean,
	title: string | React$Element<any>,
	children: React$Element<any>,
	onOpen: () => void,
	onClose: () => void,
|}

export default function CollapsibleSettings(props: Props) {
	const styles = getStyles(props.open)

	function onToggle() {
		if (props.open) return void props.onClose()
		props.onOpen()
	}

	return (
		<div>
			<div css={styles.header}>
				<div css={styles.headline}>{props.title}</div>
				<div css={styles.icon}>
					<IconButton size={24} color={colors.grey900} onClick={onToggle}>
						{props.open ? <UpArrow size={18} /> : <DownArrow style={styles.down} size={18} />}
					</IconButton>
				</div>
			</div>
			{props.open ? props.children : null}
		</div>
	)
}

function getStyles(open: boolean) {
	return {
		header: {
			display: 'flex',
			justifyContent: 'space-around',
			alignItems: 'center',
			borderBottomWidth: 1,
			borderBottomStyle: 'solid',
			borderBottomColor: open ? colors.transparent : colors.grayLight200,
			paddingRight: 5,
			height: 60,
		},
		headline: {
			fontSize: 16,
			lineHeight: '20px',
			fontWeight: open ? 'bold' : 'normal',
			letterSpacing: open ? 0 : 0.25,
			flex: '1 1 auto',
		},
		icon: {
			width: 26,
			height: 26,
			borderRadius: '50%',
			border: `1px solid ${colors.grey400}`,
			backgroundColor: open ? colors.grey200 : colors.white,
			boxShadow: open ? `inset 2px 4px 0 0 ${colors.blackFaded8}` : 'none',
			flex: '0 0 26px',
		},
		down: {
			position: 'relative',
			top: 1,
		},
	}
}
