/* @flow */

import { connect } from 'react-redux'
import type { State, Dispatch, AccountingDocumentReduxAction } from 'types'
import HeaderOrderNumber from '../../components/invoice-elements/header-order-no'
import { setAccountingDocumentOrderNo } from 'modules/accounting-document/actions'

type StateProps = {|
	orderNo: ?string,
|}

type DispatchProps = {
	onOrderNumberChange: (orderNo: string) => void,
}

type OwnProps = {|
	readonly?: boolean,
	accountingDocumentId: string,
|}

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => {
	const item = state.accountingDocument.accountingDocuments.items[ownProps.accountingDocumentId]
	const orderNo: ?string = (item && item.data && item.data.orderNo) || null
	return { orderNo }
}

const mapDispatchToProps = (dispatch: Dispatch<AccountingDocumentReduxAction>, ownProps: OwnProps): DispatchProps => {
	return {
		onOrderNumberChange: (orderNo: string) => {
			dispatch(setAccountingDocumentOrderNo(ownProps.accountingDocumentId, orderNo))
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderOrderNumber)
