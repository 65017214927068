/* @flow */

import { connect } from 'react-redux'
import { isModifierHotKeyPressed } from 'modules/common/selectors'
import type {
	AccountingDocument,
	AccountingDocumentType,
	AccountingDocumentDirection,
	AccountingDocumentScan,
	AccountingDocumentView,
	Filter,
	State,
	Dispatch,
	AccountingDocumentReduxAction,
	BootstrapAction,
	// EETOperation,
} from 'types'
import {
	getAccountingDocument,
	getAccountingDocumentScans,
	getAccountingDocumentPossibleStates,
	getPreProcessingAccountingDocumentPreview,
} from '../../selectors'
import { currentUserIsInternal } from 'modules/user/selectors'
import type { AccountingDocumentItem } from 'modules/accounting-document/reducers/accounting-documents'
import { isAccDocValid } from 'modules/accounting-document/domain/accounting-document'
// import { countryVariantUpdate } from 'modules/accounting-document/actions'
import { loadAccountingDocumentDefaults } from 'modules/settings/actions'
import EditAccountingDocument from '../../components/invoices/edit-accounting-document'

type StateProps = {|
	accountingDocument: ?AccountingDocument,
	lastCreatedDocument: ?AccountingDocument,
	possibleStates: ?Array<string>,
	scans: ?Array<AccountingDocumentScan>,
	storing: ?boolean,
	internal: ?boolean,
	forceGoOnEditRoute: boolean,
	preProcessingAccountingDocumentPreview: boolean,
	direction: ?AccountingDocumentDirection,
	view: AccountingDocumentView,
	defaultFilter: ?Filter,
|}

function mapStateToProps(state: State, ownProps: OwnProps): StateProps {
	const accountingDocument: ?AccountingDocument = getAccountingDocument(state, ownProps.accountingDocumentId)
	const item: AccountingDocumentItem = state.accountingDocument.accountingDocuments.items[ownProps.accountingDocumentId]
	const key: string = getGridStateKeyForAccountingDocument(accountingDocument, true)

	const cashRegisterId: ?string = accountingDocument && accountingDocument.cashRegisterId
	const isAccounting: ?boolean = cashRegisterId == null

	return {
		accountingDocument: accountingDocument,
		possibleStates: getAccountingDocumentPossibleStates(state, ownProps.accountingDocumentId),
		scans: getAccountingDocumentScans(state, ownProps.accountingDocumentId),
		lastCreatedDocument: state.accountingDocument.accountingDocuments.lastCreatedDocument,
		storing: !!(item && item.storing),
		internal: currentUserIsInternal(state),
		forceGoOnEditRoute: isModifierHotKeyPressed(state, 'alt'),
		direction: isAccounting ? state.accountingDocument.accountingDocumentList.direction : state.cashRegister.direction,
		view: state.accountingDocument.accountingDocumentList.view,
		defaultFilter: state.grid.filter[key],
		preProcessingAccountingDocumentPreview: getPreProcessingAccountingDocumentPreview(state),
	}
}

type DispatchProps = {|
	loadAccountingDocumentDefaults: (
		accountingDocumentType: ?AccountingDocumentType,
		accountingDocumentDirection: ?AccountingDocumentDirection,
	) => void,
	validateAccountingDocument: (accountingDocumentId: string, state: string) => Promise<boolean>,
	// changeEET: (operation: EETOperation) => void,
|}

const mapDispatchToProps = (
	dispatch: Dispatch<AccountingDocumentReduxAction | BootstrapAction>,
	// ownProps: OwnProps,
): DispatchProps => {
	return {
		validateAccountingDocument: async (accountingDocumentId: string, state: string): Promise<boolean> =>
			isAccDocValid(accountingDocumentId, state, dispatch),
		// changeEET: (operation: EETOperation) => {
		// 	dispatch(countryVariantUpdate(ownProps.accountingDocumentId, { cz: { eet: { operation } } }))
		// },
		loadAccountingDocumentDefaults: (
			accountingDocumentType: ?AccountingDocumentType,
			accountingDocumentDirection: ?AccountingDocumentDirection,
		) => {
			dispatch(loadAccountingDocumentDefaults(accountingDocumentType, accountingDocumentDirection))
		},
	}
}

function getGridStateKeyForAccountingDocument(doc: ?AccountingDocument, forFilter?: boolean = false): string {
	const isAccounting: boolean = !!(doc && doc.cashRegisterId == null)
	if (isAccounting) {
		return forFilter ? 'accountingDocument' : 'detailAccountingDocument'
	} else {
		return forFilter ? 'cashRegisterPayments' : 'detailCashRegisterPayments'
	}
}

type OwnProps = {|
	accountingDocumentId: string,
	readonly: boolean,
|}

export default connect(mapStateToProps, mapDispatchToProps)(EditAccountingDocument)
