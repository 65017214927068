// @flow

import type {
	Sequence,
	SequencesAction,
	SequencesData,
	AccountingDocumentDirection,
	AccountingDocumentType,
} from 'types'
import { Number_AccountingDocumentDirection, Number_AccountingDocumentType } from 'types/convertor'
import { emptySequencesData } from 'types/empty'
import { sortSequences } from '../domain/sequences'
import { EMPTY_ARRAY } from 'trivi-constants'

export type State = {
	loading: boolean,
	data: SequencesData,
}

export const initialState: State = {
	loading: false,
	data: emptySequencesData,
}

function setSequences(sequencesData: SequencesData, state: State): State {
	return {
		...state,
		data: sequencesData,
	}
}

function setSequence(sequence: ?Sequence, state: State): State {
	let sequencesData: SequencesData = state.data
	if (sequence != null) {
		sequencesData = { ...state.data }
		const direction: ?AccountingDocumentDirection = Number_AccountingDocumentDirection(
			sequence.accountingDocumentDirection,
		)
		const type: ?AccountingDocumentType = Number_AccountingDocumentType(sequence.accountingDocumentType)
		const sequences: Array<Sequence> = sequencesData[direction][type][sequence.cashRegisterId || 'unknown'] || []
		const sequenceId: string = (sequence && sequence.id) || ''
		const index: number = sequences.findIndex((s: Sequence) => s.id === sequenceId)
		if (index >= 0) {
			sequences[index] = sequence
		}
	}
	return {
		...state,
		loading: false,
		data: sequencesData,
	}
}

export default function sequences(state: State = initialState, action: SequencesAction): State {
	switch (action.type) {
		case 'START_LOAD_SEQUENCES':
			return {
				...state,
				loading: true,
				data: emptySequencesData,
			}

		case 'FINISH_LOAD_SEQUENCES':
			return {
				...state,
				loading: false,
				data: action.serverError ? state.data : sortSequences(action.sequences || EMPTY_ARRAY),
			}

		case 'START_LOAD_SEQUENCE':
			return {
				...state,
				loading: true,
			}

		case 'FINISH_LOAD_SEQUENCE':
			return setSequence(action.sequence, state)

		case 'FINISH_CREATE_SEQUENCE': {
			if (action.newSequences) {
				return setSequences(action.newSequences, state)
			}
			return state
		}

		case 'START_EDIT_SEQUENCE':
		case 'START_REMOVE_SEQUENCE':
		case 'START_CHANGING_LAST_GENERATED_NUMBER':
			return setSequences(action.newSequences, state)

		case 'FINISH_EDIT_SEQUENCE':
		case 'FINISH_REMOVE_SEQUENCE':
		case 'FINISH_CHANGING_LAST_GENERATED_NUMBER':
			if (action.serverError) {
				return setSequences(action.oldSequences, state)
			}
			return setSequences(action.newSequences, state)

		default:
			return state
	}
}
