/* @flow */
import React, { Component } from 'react'
import deepEqual from 'deep-equal'
import store from 'store'
import type {
	AccountingDocument,
	Template,
	AccountingDocumentAssignedDirection,
	AccountingDocumentAssignedType,
	TemplateScheduler,
	AccountingDocumentType,
	AccountingDocumentDirection,
	AccountingDocumentPrintingInfo,
	MultiFormFieldMessages,
} from 'types'
import { isSchedulerSetToSendEmails } from '../../domain/templates'
import { emptyAccountingDocument, emptyTemplate } from 'types/empty'
import {
	Number_AccountingDocumentAssignedDirection,
	Number_AccountingDocumentAssignedType,
	Number_AccountingDocumentDirection,
	Number_AccountingDocumentType,
} from 'types/convertor'
import TemplateIssued from './template-issued'
import Button from 'components/button'
import {
	withNotify,
	withTranslate,
	type WithTranslateProps,
	withOrganizationSettings,
	type WithNotifyProps,
	type WithOrganizationSettingsProps,
} from 'wrappers'
import { SILENT_MESSAGE_CODE } from 'trivi-constants'
import { setCurrentTemplate } from '../../actions'
import moment from 'moment'
import Tooltip from 'components/tooltip'

type Props = {|
	...WithOrganizationSettingsProps,
	...WithTranslateProps,
	...WithNotifyProps,
	loading: boolean,
	saving: boolean,
	template: ?Template,
	templateId: string,
	isOrganizationVatFree: boolean,
	color: ?string,
	// isEetActive: boolean,
	domesticCurrencyId: ?string,
	lastCreatedTemplate: ?Template,
	onRemove: (template: Template) => Promise<void>,
	getVatCountryTypeByCountry: (country: ?string) => ?number,
	onSave: (template: Template) => Promise<any>,
	loadAccountingDocumentDefaults: (
		accountingDocumentType: ?AccountingDocumentType,
		accountingDocumentDirection: ?AccountingDocumentDirection,
	) => void,
	onCreateAccDoc: (template: Template) => void,
	setCurrentTemplate: (template: ?Template) => void,
	errorCount?: number,
	removeErrors: (template: Template) => void,
|}

type State = {|
	sendEmailAutomatically: boolean,
	template: ?Template,
	saved: boolean,
	hasErrors: boolean,
	goToScheduler: boolean,
|}

class EditTemplateSubtype extends Component<Props, State> {
	state = this.getInitialState(this.props)

	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		if (!this.state.saved) {
			return
		}

		if (nextProps.template !== this.props.template) {
			this.setState(this.getInitialState(nextProps))
			this.props.loadAccountingDocumentDefaults(
				Number_AccountingDocumentType(nextProps.template?.content?.type),
				Number_AccountingDocumentDirection(nextProps.template?.content?.direction),
			)
		}
	}

	getInitialState(props: Props): State {
		let template = props.template && { ...props.template }
		if (template) {
			template.scheduler = template.scheduler && { ...template.scheduler }
		}
		return {
			template,
			saved: true,
			sendEmailAutomatically: isSchedulerSetToSendEmails(template && template.scheduler),
			hasErrors: false,
			goToScheduler: false,
		}
	}

	componentDidMount() {
		if (!this.props.organizationSettings) {
			this.props.loadOrganizationSettings()
		}
	}

	onChange = (accountingDocument: AccountingDocument) => {
		const oldTemplate: ?Template = this.state.template
		const accountingDocumentCopy = { ...accountingDocument }

		if (oldTemplate && oldTemplate.content && oldTemplate.content.type !== accountingDocument.type) {
			accountingDocumentCopy.sequenceId = undefined
		}

		const newTemplate: ?Template =
			(oldTemplate && {
				content: accountingDocumentCopy,
				id: oldTemplate && oldTemplate.id,
				name: oldTemplate && oldTemplate.name,
				originAccountingDocumentId: oldTemplate && oldTemplate.originAccountingDocumentId,
				scheduler: oldTemplate && oldTemplate.scheduler,
			}) ||
			null

		this.props.setCurrentTemplate(newTemplate)
		this.setState({ template: newTemplate, saved: false })
	}

	onPrintingChange = (printing: $Shape<AccountingDocumentPrintingInfo>) => {
		this.setState((state: State) => ({
			template: {
				...state.template,
				content: {
					...(state.template?.content || {}),
					printing: {
						...(state.template?.content?.printing || {}),
						...printing,
					},
				},
			},
			saved: false,
		}))
	}

	onTitleChange = (title: ?string) => {
		if (title == null) return

		let template: ?Template = this.state.template

		if (template && template.name !== title) {
			template.name = title
			this.setState({ template, saved: false })
		}
	}

	onResetGoToScheduler = () => {
		this.setState({ goToScheduler: false })
	}

	validateScheduler = (): boolean => {
		const templateScheduler: ?TemplateScheduler = this.state.template && this.state.template.scheduler
		if (!templateScheduler) return true

		const { sendEmailAutomatically } = this.state
		const templateIdInvalid =
			templateScheduler.useWorkflow && sendEmailAutomatically && !templateScheduler.emailTemplateId
		const emailInvalid =
			templateScheduler.useWorkflow &&
			sendEmailAutomatically &&
			(!templateScheduler.emailRecipients || !templateScheduler.emailRecipients.length)

		const vatCountryTypeValid = !(
			templateScheduler.useWorkflow &&
			this.state.template &&
			this.state.template.content &&
			2 == this.state.template.content.vatCountryType
		)

		const templateIssueDate = moment(templateScheduler.start)
			.startOf('day')
			.toDate()

		const nowDate = moment()
			.startOf('day')
			.toDate()

		const issueDateInvalid = templateIssueDate <= nowDate

		const fieldsAffected = []
		!vatCountryTypeValid &&
			fieldsAffected.push({
				name: 'workflow',
				message: 'invoice.settings.scheduler.processing.cantUseWorkflow',
			})

		if (issueDateInvalid) {
			fieldsAffected.push({
				name: 'startDate',
				message: 'invoice.settings.scheduler.processing.startDateBeforeNow',
			})
			this.setState({ goToScheduler: true })
		}

		emailInvalid &&
			fieldsAffected.push({
				name: 'emails',
				message: 'invoice.settings.scheduler.emailConfiguration.selectEmailWarning',
			})

		templateIdInvalid &&
			fieldsAffected.push({
				name: 'templateId',
				message: 'invoice.settings.scheduler.emailConfiguration.selectTemplateWarning',
			})

		store.dispatch({
			type: 'TEMPLATE_SCHEDULER_VALIDATION',
			validationError: {
				code: SILENT_MESSAGE_CODE,
				fieldsAffected,
			},
		})

		return !fieldsAffected.length
	}

	onSave = async (): Promise<void> => {
		if (!this.validateScheduler()) {
			this.props.notify(this.props.t('invoice.settings.scheduler.errorsWarning'), 'error')
			this.setState({ hasErrors: true })
			throw new Error()
		}
		if (this.props.onSave && this.state.template) {
			try {
				await this.props.onSave(this.state.template)
				this.setState({ saved: true })
			} catch (e) {
				throw new Error(e)
			}
		}
	}

	onCreateAccDoc = () => {
		if (this.props.onCreateAccDoc) {
			this.onSave().then(() => {
				this.state.template && this.props.onCreateAccDoc(this.state.template)
			})
		}
	}

	getSaveButton = (): React$Element<any> => {
		const { t } = this.props
		const isNewTemplate =
			this.state.template && (this.state.template.name === undefined || this.state.template.name === '')
		const hasErrors = !!(this.props.errorCount && this.props.errorCount > 0)
		const label = this.props.saving
			? t('templates.saveButtonSaving')
			: this.state.saved && !isNewTemplate
			? t('templates.saveButtonSaved')
			: isNewTemplate
			? t('templates.saveButtonSave')
			: t('templates.saveButtonSave')
		return (
			<Button
				autoTestId="edit-template-save"
				disabled={this.state.saved || (hasErrors && !this.state.saved)}
				onClick={this.onSave}
				labelText={label}
			/>
		)
	}

	getSaveButtonWithTooltip = (): React$Element<any> => {
		const { t } = this.props
		const hasErrors = !!(this.props.errorCount && this.props.errorCount > 0)

		if (hasErrors && !this.state.saved) {
			return <Tooltip label={t('templates.cantSaveErrors')}>{this.getSaveButton()}</Tooltip>
		}
		if (this.state.saved) {
			return <Tooltip label={t('templates.cantSaveNoChanges')}>{this.getSaveButton()}</Tooltip>
		}
		return this.getSaveButton()
	}

	getCreateAccDocButton = (): React$Element<any> => {
		const { t } = this.props
		const label = t('templates.createDocument')

		return <Button autoTestId="edit-template-create-accDoc" onClick={this.onCreateAccDoc} labelText={label} />
	}

	onTemplateSchedulerChange = (templateScheduler: ?TemplateScheduler, sendEmailAutomatically: boolean) => {
		let template: Template = this.state.template || emptyTemplate()
		if (
			!deepEqual(template.scheduler, templateScheduler) ||
			sendEmailAutomatically !== this.state.sendEmailAutomatically
		) {
			template.scheduler = templateScheduler || undefined
			const callback = !this.state.saved ? this.validateScheduler : undefined
			this.setState({ template, saved: false, sendEmailAutomatically }, callback)
		}
	}

	onCloseCallback = () => {
		if (this.state.template && this.props.template && !this.props.template.name) {
			this.props.onRemove(this.state.template)
		} else if (this.props.template && !deepEqual(this.state.template, this.props.template) && !this.state.saved) {
			this.props.onSave(this.props.template)
		}
		this.state.template && this.props.removeErrors(this.state.template)
	}

	render() {
		const { template } = this.state

		if (!template) return null

		const accountingDocument: AccountingDocument = (template && template.content) || emptyAccountingDocument()
		const direction: ?AccountingDocumentAssignedDirection = Number_AccountingDocumentAssignedDirection(
			accountingDocument.direction,
		)
		const type: ?AccountingDocumentAssignedType = Number_AccountingDocumentAssignedType(accountingDocument.type)

		const templateScheduler: ?TemplateScheduler = (template && template.scheduler) || null

		return direction === 'issued' && (type === 'invoice' || type === 'advance') ? (
			<TemplateIssued
				accountingDocument={accountingDocument}
				onChange={this.onChange}
				title={(template && template.name) || ''}
				onTitleChange={this.onTitleChange}
				templateScheduler={templateScheduler}
				onPrintingChange={this.onPrintingChange}
				onTemplateSchedulerChange={this.onTemplateSchedulerChange}
				saving={this.props.saving}
				saved={this.state.saved}
				saveButton={this.getSaveButtonWithTooltip()}
				createAccDocButton={this.getCreateAccDocButton()}
				isOrganizationVatFree={this.props.isOrganizationVatFree}
				color={this.props.color}
				domesticCurrencyId={this.props.domesticCurrencyId}
				getVatCountryTypeByCountry={this.props.getVatCountryTypeByCountry}
				onCloseCallback={this.onCloseCallback}
				// isEetActive={this.props.isEetActive}
				templateId={this.props.templateId}
				goToScheduler={this.state.goToScheduler}
				onResetGoToScheduler={this.onResetGoToScheduler}
			/>
		) : (
			<div>{this.props.t('templates.notImplemented')}</div>
		)
	}
}

export default withTranslate(withOrganizationSettings(withNotify(EditTemplateSubtype)))
