/* @flow */

import type {
	AccountingDocument,
	AccountingDocumentInfo,
	DataGridReduxAction,
	AccountingDocumentReduxAction,
	AccountingDocumentAssignedDirection,
	Dispatch,
	State,
} from 'types'

import type { AccountingDocumentItem } from 'modules/accounting-document/reducers/accounting-documents'
import type { AddConnectionOptions } from '../../components/accounting-document-picker/picker'
import { Actions } from 'react-redux-grid'
import Picker from '../../components/accounting-document-picker/picker'
import { currentUserIsInternal } from 'modules/user/selectors'
import { clearAllGridFilters } from 'modules/data-grid/actions'
import { connect } from 'react-redux'
import { loadFinancialAccounts } from '../../../organization/actions'

export type OwnProps = {|
	readonly?: boolean,
	light?: boolean,
	selected: Array<AccountingDocumentInfo>,
	onSelectionChange?: (selection: Array<AccountingDocumentInfo>) => void,
	onAddConnection?: (connectingDocument: AccountingDocument, options: AddConnectionOptions) => Promise<any>,
	onRemoveConnection?: (connectingDocument: AccountingDocument, asRelatedDocument: boolean) => Promise<any>,
	hideSelectedFiles?: boolean,
	allowedTypes?: ?Array<number>,
	organizationId?: number,
	contactId?: ?string,
	accountingDocumentId?: string,
	asRelatedDocument?: boolean,
	direction?: ?AccountingDocumentAssignedDirection,
	paidConfirmed?: boolean,
	checkAllOption?: boolean,
|}

export type StateProps = {|
	preselectedValues: ?Array<string>,
	currency: ?string,
	accountingDocument: ?AccountingDocument,
	checkAllOption?: boolean,
	internal: ?boolean,
	selectedDocs: Array<AccountingDocument>,
|}

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => {
	const item: ?AccountingDocumentItem = ownProps.accountingDocumentId
		? state.accountingDocument.accountingDocuments.items[ownProps.accountingDocumentId]
		: null

	const selected =
		item && item.data && item.data.relatedAccountingDocumentId ? [item.data.relatedAccountingDocumentId] : []

	ownProps.selected.forEach((item: AccountingDocumentInfo) => {
		if (item.accountingDocumentId) {
			selected.push(item.accountingDocumentId)
		}
	})

	const dataSource = state.dataGrid.data.accountingDocuments

	const accDocs = dataSource ? ((Object.values(dataSource): any): Array<AccountingDocument>) : []

	let selectedDocs: AccountingDocument[] = []
	if (accDocs && accDocs.length > 0 && selected && selected.length > 0) {
		selectedDocs = accDocs.filter((accDoc: AccountingDocument) => {
			const selectedDoc = selected.find((id: string) => accDoc.id === id)
			return selectedDoc
		})
	}

	return {
		preselectedValues: selected,
		currency: item && item.data && item.data.currency,
		accountingDocument: item && item.data,
		checkAllOption: ownProps.checkAllOption,
		internal: currentUserIsInternal(state),
		selectedDocs,
	}
}

export type DispatchProps = {|
	updateRow?: (gridData: Array<*>, id: string) => void,
	clearAllFilters: (name: string) => void,
	loadFinAccs: (selectedAccDoc: AccountingDocument) => void,
|}

const mapDispatchToProps = (dispatch: Dispatch<AccountingDocumentReduxAction | DataGridReduxAction>): DispatchProps => {
	return {
		updateRow: (gridData: Array<*>, id: string) => {
			gridData.forEach((item: { get: (stateKey: string) => string }, index: number) => {
				if (item.get('id') === id) {
					dispatch(
						Actions.SelectionActions.setSelection({
							defaults: {
								allowDeselect: true,
								enabled: true,
							},
							id: 'row-' + index,
							index: index,
							stateKey: 'accountingDocumentPicker',
						}),
					)
				}
			})
		},
		clearAllFilters: (name: string) => {
			dispatch(clearAllGridFilters(name))
		},
		loadFinAccs: (selectedAccDoc: AccountingDocument) => {
			dispatch(
				loadFinancialAccounts({
					accountingDocumentType: selectedAccDoc.type,
					accountingDocumentDirection: selectedAccDoc.direction,
				}),
			)
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Picker)
