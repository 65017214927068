// @flow

import memoizeOne from 'memoize-one'
import type { Sequence } from 'types'

export const getSortedSequences = memoizeOne((sequences: ?Array<Sequence>): ?Array<Sequence> => {
	return (
		sequences &&
		[...sequences].sort(
			(left: Sequence, right: Sequence) =>
				(right.validTo ? new Date(right.validTo).getTime() : Number.POSITIVE_INFINITY) -
				(left.validTo ? new Date(left.validTo).getTime() : Number.POSITIVE_INFINITY),
		)
	)
})
