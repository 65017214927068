/* @flow */
/** @jsx jsx */

// $FlowFixMe - there is useState
import { Fragment, useState } from 'react'
import { jsx } from '@emotion/core'
import type { UnmatchedPayment, UnmatchedPaymentMatch } from 'types'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import UploadScan from 'modules/accounting-document/containers/upload-scan/upload-scan'
import {
	getUnmatchedPaymentAttachments,
	getUnmatchedPaymentComments,
	hasUnmatchedPaymentCommentsOrAttachments,
} from './unmatched-payment'
import Button from 'components/button'
import Attachment from './attachment'
import Comment from './comment'

type Props = {|
	...WithTranslateProps,
	canDeleteComments: boolean,
	canDeleteAttachments: boolean,
	showSetAsDoneButton: boolean,
	unmatchedPayment: UnmatchedPayment,
	isInternal: boolean,
	onDelete: (unmatchedPayment: UnmatchedPayment, match: UnmatchedPaymentMatch) => void,
	handOverToAccountant: (unmatchedPayment: UnmatchedPayment) => void,
	onAddAccountingDocuments: (unmatchedPayment: UnmatchedPayment, ids: Array<string>) => void,
	onAttachAccountingDocument: () => void,
	onAddComment: () => void,
	onSetAsDone: () => void,
|}

function ExpandedRow(props: Props) {
	const [dialog, setDialog] = useState(false)

	const comments = getUnmatchedPaymentComments(props.unmatchedPayment)
	const attachments = getUnmatchedPaymentAttachments(props.unmatchedPayment)
	const attachmentsOrComments = hasUnmatchedPaymentCommentsOrAttachments(comments, attachments)

	const showButtonRow = props.unmatchedPayment && props.unmatchedPayment.isPaymentAlreadyMatched !== true

	function onDelete(match: UnmatchedPaymentMatch) {
		props.onDelete(props.unmatchedPayment, match)
	}

	function onAddAccountingDocuments(ids: Array<string>) {
		props.onAddAccountingDocuments(props.unmatchedPayment, ids)
	}

	function onRequestClose() {
		setDialog(false)
	}

	function openDialog() {
		setDialog(true)
	}

	return (
		<Fragment>
			<div css={styles.root}>
				<div css={styles.content}>
					<div css={styles.comments}>
						{comments &&
							comments.map((document: UnmatchedPaymentMatch) => (
								<Comment
									document={document}
									onDelete={props.canDeleteComments ? onDelete : undefined}
									key={document.id || document.accountingDocumentId}
								/>
							))}
					</div>
					<div css={styles.attachments}>
						{attachments && attachments.length > 0 && (
							<Fragment>
								<span css={styles.headline}>{props.t('datagrid.implemented.unmatchedPayments.attachmentList')}</span>
								{attachments.map((document: UnmatchedPaymentMatch) => (
									<Attachment
										document={document}
										onDelete={props.canDeleteAttachments ? onDelete : undefined}
										key={document.id || document.accountingDocumentId}
										isInternal={props.isInternal}
									/>
								))}
							</Fragment>
						)}
					</div>
				</div>
				{!attachmentsOrComments && props.unmatchedPayment.isPaymentAlreadyMatched && (
					<div>{props.t('datagrid.implemented.unmatchedPayments.hasNoCommentsNorAttachments')}</div>
				)}
				{showButtonRow && (
					<div css={styles.actions}>
						<div css={styles.left}>
							{props.showSetAsDoneButton && (
								<Button
									labelText={props.t('datagrid.implemented.unmatchedPayments.setAsDone')}
									autoTestId="unmatched-payments-set-as-done"
									onClick={props.onSetAsDone}
									secondary
								/>
							)}
						</div>
						<div css={styles.right}>
							<div css={styles.action}>
								<Button
									labelText={props.t('datagrid.implemented.unmatchedPayments.addComment')}
									autoTestId="unmatched-payments-add-comment"
									onClick={props.onAddComment}
									secondary
								/>
							</div>
							<div css={styles.action}>
								<Button
									labelText={props.t('datagrid.implemented.unmatchedPayments.addAccountingDocument')}
									autoTestId="unmatched-payments-add-document"
									onClick={props.onAttachAccountingDocument}
									primary
								/>
							</div>
							<div css={styles.action}>
								<Button
									labelText={props.t('dashboard.todo.unmatchedPayments.uploadAccountingDocument')}
									autoTestId="unmatched-payments-upload-document"
									onClick={openDialog}
									primary
								/>
							</div>
						</div>
					</div>
				)}
			</div>
			{dialog && <UploadScan onCreateDocumentsFromScans={onAddAccountingDocuments} onRequestClose={onRequestClose} />}
		</Fragment>
	)
}

export default withTranslate(ExpandedRow)

const styles = {
	root: {
		backgroundColor: '#F9F9F9',
		textAlign: 'left',
		padding: 25,
		position: 'relative',
		borderTop: '1px solid #e6e6e6',
		marginTop: -2,
		fontSize: 14,

		'&:after, &:before': {
			content: '""',
			bottom: '100%',
			left: 62,
			border: 'solid transparent',
			height: 0,
			width: 0,
			position: 'absolute',
			pointerEvents: 'none',
		},
		'&:after': {
			borderColor: 'rgba(249, 249, 249, 0)',
			borderBottomColor: '#f9f9f9',
			borderWidth: 8,
			marginLeft: -8,
		},
		'&:before': {
			borderColor: 'rgba(230, 230, 230, 0)',
			borderBottomColor: '#e6e6e6',
			borderWidth: 9,
			marginLeft: -9,
		},
	},
	headline: {
		position: 'relative',
		fontSize: 14,
		fontWeight: 600,
		display: 'inline-block',
		alignItems: 'center',
	},
	content: {
		display: 'flex',
	},
	comments: {
		flex: '0 0 75%',
		paddingRight: 60,
		overflow: 'hidden',
	},
	attachments: {
		flex: '0 0 25%',
		overflow: 'hidden',
	},
	actions: {
		paddingTop: 5,
		display: 'flex',
		alignItems: 'center',
	},
	action: {
		marginLeft: 20,
	},
	left: {
		display: 'flex',
		justifyContent: 'flex-start',
		flex: '1 1 auto',
	},
	right: {
		display: 'flex',
		justifyContent: 'flex-end',
		flex: '1 1 auto',
	},
}
