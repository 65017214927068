/* @flow */
/* eslint-disable react/no-multi-comp */
/** @jsx jsx */

import moize from 'moize'
import { jsx } from '@emotion/core'
import type { AccountingDocument, AccountingDocumentExtractingState } from 'types'
import { type WithTranslateProps } from 'wrappers'
import Tooltip from 'components/tooltip'
import { Column } from 'modules/data-grid-next/components'
import type { Direction } from 'modules/data-grid-next/types'
import ActionHighlightOff from 'components/svg-icons/action/highlight-off'
import DeviceAccessTime from 'components/svg-icons/device/access-time'
import ActionLockClose from 'components/svg-icons/action/lock-close'
import Done from 'components/svg-icons/trivi/state/done'
import Error from 'components/svg-icons/trivi/state/error'
import { DATA_GRID_CACHE_SIZE } from 'trivi-constants'
import { colors } from 'variables'
import {
	UPLOAD_TO_BE_APPROVED,
	UPLOAD_APPROVED,
	UPLOAD_REJECTED,
	UPLOAD_LOCKED,
	UPLOAD_NEED_HELP,
} from 'modules/accounting-document/constants'

export type Props = {|
	...WithTranslateProps,
	defaultSortDirection?: Direction,
	onClick?: AccountingDocument => void,
	currentUserIsInternal: boolean,
|}

export default function getExtractingStateColumn(props: Props) {
	function columnRenderer(value: number, row: AccountingDocument) {
		const extractingState = row.__extractingState
		const label = getLabel(props, extractingState)
		const styles = getStyles(props, extractingState)
		const icon = getIcon(extractingState)
		const getClickHandler = () => () => {
			if (
				props.onClick &&
				[UPLOAD_NEED_HELP, UPLOAD_TO_BE_APPROVED, UPLOAD_APPROVED, UPLOAD_REJECTED, UPLOAD_LOCKED].includes(
					extractingState,
				)
			) {
				props.onClick(row)
			}
		}

		return (
			<div css={styles.root} onClick={getClickHandler()}>
				<Tooltip label={label} wrapperStyle={styles.tooltip} inline>
					{icon}
				</Tooltip>
			</div>
		)
	}

	const columnRendererCashed = moize(columnRenderer, { maxSize: DATA_GRID_CACHE_SIZE })

	return (
		<Column
			title=""
			width={28}
			columnId="extractingState"
			key="extractingState"
			render={columnRendererCashed}
			sortable
			defaultSortDirection={props.defaultSortDirection || undefined}
		/>
	)
}

function getIcon(extractingState?: AccountingDocumentExtractingState) {
	switch (extractingState) {
		case UPLOAD_TO_BE_APPROVED:
			return <DeviceAccessTime size={14} color={colors.white} hoverColor={colors.white} />
		case UPLOAD_APPROVED:
			return <Done size={12} color={colors.white} hoverColor={colors.white} />
		case UPLOAD_REJECTED:
			return <ActionHighlightOff size={14} color={colors.white} hoverColor={colors.white} />
		case UPLOAD_LOCKED:
			return <ActionLockClose size={14} color={colors.white} hoverColor={colors.white} />
		case UPLOAD_NEED_HELP:
			return <Error size={14} color={colors.white} hoverColor={colors.white} />
		default:
			return <ActionHighlightOff size={14} color={colors.white} hoverColor={colors.white} />
	}
}

function getLabel(props: Props, extractingState?: AccountingDocumentExtractingState) {
	const internalUser = props.currentUserIsInternal

	switch (extractingState) {
		case UPLOAD_TO_BE_APPROVED:
			return props.t('accountingDocument.columns.stateWaiting')
		case UPLOAD_APPROVED:
			return props.t('accountingDocument.columns.stateApproved')
		case UPLOAD_REJECTED:
			return props.t('accountingDocument.columns.stateRejected')
		case UPLOAD_LOCKED:
			return props.t('accountingDocument.columns.' + (internalUser ? 'stateLocked' : 'stateProcessing'))
		case UPLOAD_NEED_HELP:
			return props.t('accountingDocument.columns.' + (internalUser ? 'stateNeedHelp' : 'stateProcessing'))
		default:
			return props.t('accountingDocument.columns.stateRejected')
	}
}

function getColor(extractingState?: AccountingDocumentExtractingState) {
	switch (extractingState) {
		case UPLOAD_TO_BE_APPROVED:
			return colors.orangeFaded80
		case UPLOAD_APPROVED:
			return colors.greenFaded80
		case UPLOAD_REJECTED:
			return colors.redFaded80
		case UPLOAD_LOCKED:
			return colors.grey300
		case UPLOAD_NEED_HELP:
			return colors.grey300
		default:
			return colors.redFaded80
	}
}

function getStyles(props: Props, extractingState?: AccountingDocumentExtractingState) {
	return {
		root: {
			display: [UPLOAD_TO_BE_APPROVED, UPLOAD_APPROVED, UPLOAD_REJECTED, UPLOAD_LOCKED, UPLOAD_NEED_HELP].includes(
				extractingState,
			)
				? 'block'
				: 'none',
			overflow: 'hidden',
			height: '100%',
			width: 20,
			cursor: props.onClick ? 'pointer' : 'default',
		},
		tooltip: {
			backgroundColor: getColor(extractingState),
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			height: '100%',
			width: 20,
		},
	}
}
