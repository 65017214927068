/* @flow */

// $FlowFixMe - there is useEffect
import React, { useEffect } from 'react'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import ShareablePublicAccountingDocumentComponent from '../containers/invoices/shareable-public-accounting-document'
import { hideSplashscreen } from 'utils/splashscreen'
import type { WithRouterProps } from 'types'
import { withRouter } from 'react-router'
import Helmet from 'react-helmet'

type Props = { ...WithRouterProps, ...WithTranslateProps }

function PublicAccountingDocument(props: Props) {
	useEffect(hideSplashscreen, [])

	return (
		<div>
			<Helmet>
				<title>{props.t('accountingDocument.show.headline')}</title>
			</Helmet>
			<ShareablePublicAccountingDocumentComponent uniqueId={props.match.params.uniqueId} />
		</div>
	)
}

export default withTranslate(withRouter(PublicAccountingDocument))
