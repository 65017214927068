/* @flow */

import deepEqual from 'deep-equal'
import type {
	CashRegisters,
	CashRegister,
	CashRegisterPayments,
	CashRegisterInventories,
	AccountingDocumentDirection,
} from 'types'
import type { Action } from '../actions/action-types'
import { EMPTY_ARRAY, EMPTY_OBJECT } from 'trivi-constants'

export type CashRegisterState = {|
	id: string,
	loading: boolean,
	saving: boolean,
	data?: CashRegister,
|}

export type PaymentState = {|
	id: string,
	loading: boolean,
	data: CashRegisterPayments,
|}

export type InventoriesState = {
	[cashRegisterId: string]: {|
		cashRegisterId: string,
		loading: boolean,
		data: ?CashRegisterInventories,
	|},
}

export type State = {
	cashRegisters: {|
		loading: boolean,
		data: CashRegisters,
	|},
	cashRegister: Array<CashRegisterState>,
	payments: Array<PaymentState>,
	inventories: InventoriesState,
	makeInventory: {
		date?: Date,
		saving: boolean,
	},
	direction: ?AccountingDocumentDirection,
}

export const initialState: State = {
	cashRegisters: {
		loading: false,
		data: EMPTY_ARRAY,
	},
	cashRegister: EMPTY_ARRAY,
	payments: EMPTY_ARRAY,
	balances: EMPTY_OBJECT,
	inventories: EMPTY_OBJECT,
	makeInventory: {
		saving: false,
	},
	direction: undefined,
}

const cashRegisterInitialState: CashRegisterState = {
	id: '',
	loading: false,
	saving: false,
}

const paymentInitialState: PaymentState = {
	id: '',
	loading: false,
	data: EMPTY_ARRAY,
}

// TODO: Ve flow je chyba, u disjoint union typů nerozpozná případy, který nemůžou nastat, ale pracujou na tom
// https://github.com/facebook/flow/issues/2399

export default (state: State = initialState, action: Action): State => {
	switch (action.type) {
		case 'START_LOAD_CASH_REGISTERS': {
			return {
				...state,
				cashRegisters: {
					...state.cashRegisters,
					loading: true,
				},
			}
		}

		case 'FINISH_LOAD_CASH_REGISTERS': {
			return {
				...state,
				cashRegisters: {
					...state.cashRegisters,
					data: deepEqual(state.cashRegisters.data, action.cashRegisters)
						? state.cashRegisters.data
						: action.cashRegisters,
					loading: false,
				},
			}
		}

		case 'START_LOAD_CASH_REGISTER': {
			const cashRegisterId: string = action.id
			const cashRegistersState: Array<CashRegisterState> = updateCashRegister(state.cashRegister, cashRegisterId, {
				id: cashRegisterId,
				loading: true,
			})
			return Object.assign({}, state, { cashRegister: cashRegistersState })
		}

		case 'FINISH_LOAD_CASH_REGISTER': {
			const cashRegisterId: string = action.id
			const cashRegistersState: Array<CashRegisterState> = updateCashRegister(state.cashRegister, cashRegisterId, {
				id: cashRegisterId,
				loading: false,
				data: action.cashRegister,
			})
			return Object.assign({}, state, { cashRegister: cashRegistersState })
		}

		case 'START_UPDATE_CASH_REGISTER': {
			const cashRegisterId: string = action.id
			const cashRegistersState: Array<CashRegisterState> = updateCashRegister(state.cashRegister, cashRegisterId, {
				id: cashRegisterId,
				saving: true,
				data: action.cashRegister,
			})
			return Object.assign({}, state, { cashRegister: cashRegistersState })
		}

		case 'FINISH_UPDATE_CASH_REGISTER': {
			const cashRegisterId: string = action.id
			const cashRegistersState: Array<CashRegisterState> = updateCashRegister(
				state.cashRegister,
				cashRegisterId,
				action.cashRegister
					? {
							id: cashRegisterId,
							saving: false,
							data: action.cashRegister,
					  }
					: {
							id: cashRegisterId,
							saving: false,
					  },
			)
			return Object.assign({}, state, { cashRegister: cashRegistersState })
		}

		case 'START_UPDATE_CASH_REGISTER_SETTINGS': {
			const cashRegisterId: string = action.id
			let newCashRegister = {}
			Object.assign(newCashRegister, action.oldCashRegister, action.cashRegisterSettings)
			const cashRegistersState: Array<CashRegisterState> = updateCashRegister(state.cashRegister, cashRegisterId, {
				id: cashRegisterId,
				saving: true,
				data: newCashRegister,
			})
			return Object.assign({}, state, { cashRegister: cashRegistersState })
		}

		case 'FINISH_UPDATE_CASH_REGISTER_SETTINGS': {
			const cashRegisterId: string = action.id
			let newCashRegister = {}
			Object.assign(newCashRegister, action.oldCashRegister, action.cashRegisterSettings)

			const cashRegistersState: Array<CashRegisterState> = updateCashRegister(
				state.cashRegister,
				cashRegisterId,
				action.cashRegisterSettings
					? {
							id: cashRegisterId,
							saving: false,
							data: newCashRegister,
					  }
					: {
							id: cashRegisterId,
							saving: false,
					  },
			)
			return Object.assign({}, state, { cashRegister: cashRegistersState })
		}

		case 'FINISH_CREATE_CASH_REGISTER': {
			let cashRegistersState: Array<CashRegisterState>
			if (action.cashRegister && action.cashRegister.id) {
				const cashRegisterId: string = action.cashRegister.id
				cashRegistersState = updateCashRegister(state.cashRegister, cashRegisterId, {
					id: cashRegisterId,
					data: action.cashRegister,
				})
			}
			return Object.assign({}, state, {
				cashRegistersState: cashRegistersState,
			})
		}

		case 'START_LOAD_CASH_REGISTER_PAYMENTS': {
			const cashRegisterId: string = action.cashRegisterId
			const paymentsState: Array<PaymentState> = updatePayment(state.payments, cashRegisterId, {
				id: cashRegisterId,
				loading: true,
			})
			return Object.assign({}, state, { payments: paymentsState })
		}

		case 'FINISH_LOAD_CASH_REGISTER_PAYMENTS': {
			const cashRegisterId: string = action.cashRegisterId
			const paymentsState: Array<PaymentState> = updatePayment(state.payments, cashRegisterId, {
				id: cashRegisterId,
				loading: false,
				data: action.payments,
			})
			return Object.assign({}, state, { payments: paymentsState })
		}

		case 'START_LOAD_CASH_REGISTER_INVENTORIES': {
			const cashRegisterId: string = action.cashRegisterId

			let inventoriesState: InventoriesState = Object.assign({}, state.inventories)
			inventoriesState[cashRegisterId] = {
				cashRegisterId,
				loading: true,
				data: EMPTY_ARRAY,
			}

			return Object.assign({}, state, { inventories: inventoriesState })
		}

		case 'FINISH_LOAD_CASH_REGISTER_INVENTORIES': {
			const cashRegisterId: string = action.cashRegisterId
			const inventoriesState: InventoriesState = Object.assign({}, state.inventories)
			inventoriesState[cashRegisterId] = {
				cashRegisterId,
				loading: false,
				data: action.inventories,
			}
			return Object.assign({}, state, { inventories: inventoriesState })
		}

		case 'CHANGE_MAKE_INVENTORY': {
			return {
				...state,
				makeInventory: {
					...state.makeInventory,
					date: action.date,
				},
			}
		}

		case 'START_MAKE_INVENTORY': {
			return {
				...state,
				makeInventory: {
					...state.makeInventory,
					saving: true,
				},
			}
		}

		case 'FINISH_MAKE_INVENTORY': {
			return {
				...state,
				makeInventory: {
					...state.makeInventory,
					saving: false,
				},
			}
		}

		case 'CHANGE_CASH_REGISTER_LIST_DIRECTION':
			return {
				...state,
				direction: action.direction,
			}

		default:
			return state
	}
}

function updateCashRegister(cashRegisterStates: Array<CashRegisterState>, id: string, newCashRegister: any) {
	let found = false
	const newCashRegisters = cashRegisterStates.map((item: CashRegisterState) => {
		if (id === item.id) {
			found = true
			return Object.assign(item, newCashRegister)
		}
		return item
	})
	if (!found) {
		newCashRegisters.push(Object.assign(cashRegisterInitialState, newCashRegister))
	}
	return newCashRegisters
}

function updatePayment(paymentStates: Array<PaymentState>, id: string, newPayment: any) {
	let found = false
	const newPayments = paymentStates.map((item: PaymentState) => {
		if (id === item.id) {
			found = true
			return Object.assign(item, newPayment)
		}
		return item
	})
	if (!found) {
		newPayments.push(Object.assign(paymentInitialState, newPayment))
	}
	return newPayments
}
