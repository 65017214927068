// @flow

import type { OrganizationMember, OrganizationMembers, Organization } from 'types'
import { sortBy } from 'lodash-es'

export function isTaxEvidence(organization: Organization): boolean {
	return organization.accountingType === 1
}

function organizationSorter(organization: OrganizationMember) {
	return (organization.organizationName || '').toLowerCase()
}

export function sortOrganizations(organizations: OrganizationMembers): OrganizationMembers {
	return sortBy(organizations, organizationSorter)
}
