import React from 'react'
import pure from 'recompose/pure'
import SvgIcon from 'components/SvgIcon'

let Info = props => (
	<SvgIcon {...props}>
		<g stroke="none" strokeWidth="1" fill="none">
			<path
				d="M12,3 C7.032,3 3,7.032 3,12 C3,16.968 7.032,21 12,21 C16.968,21 21,16.968 21,12 C21,7.032 16.968,3 12,3 Z"
				fill="#FFFFFF"
			/>
			<path
				d="M12,2 C6.48,2 2,6.48 2,12 C2,17.52 6.48,22 12,22 C17.52,22 22,17.52 22,12 C22,6.48 17.52,2 12,2 Z M13,17 L11,17 L11,11 L13,11 L13,17 Z M13,9 L11,9 L11,7 L13,7 L13,9 Z"
				fill="#63686D"
			/>
		</g>
	</SvgIcon>
)
Info = pure(Info)

export default Info
