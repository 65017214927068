/* @flow */

import React, { Component } from 'react'
import { type WithTranslateProps, withTranslate } from 'wrappers'
import {
	settingsAccountignDocumentCategoriesRoute,
	settingsApiRoute,
	settingsEmailsRoute,
	settingsExtractionRoute,
	settingsPaymentRemindersRoute,
	settingsPrintingRoute,
	settingsUploadReminderRoute,
} from 'modules/settings/routing/routes'

import AccountingDocumentCategories from 'modules/settings/containers/accounting-document-categories'
import Api from 'modules/settings/containers/api'
import Emails from 'modules/settings/containers/emails'
import Extraction from '../components/trivi-settings/extraction'
import PaymentReminders from 'modules/settings/containers/payment-reminders'
import Printing from 'modules/settings/components/trivi-settings/printing'
import SettingsSection from 'modules/settings/components/settings-section'
import type { SubSectionType } from './settings-layout'
import UploadReminder from '../components/trivi-settings/upload-reminder'
import VatSupportingDocuments from '../components/trivi-settings/vat-supporting-documents'
import type { WithRouterProps } from 'types'
import { withRouter } from 'react-router-dom'

export type Props = {|
	subSection?: SubSectionType,
	currentUserIsInternal: boolean,
	canEditProfile: boolean,
	canEditApiApps: boolean,
	currentUserHasInternalFullAccess: boolean,
	organizationSettingsLoading: boolean,
|}

class TriviSettingsPage extends Component<Props & WithTranslateProps & WithRouterProps> {
	getToUrl = (url: string) => () => {
		this.props.history.push(url)
	}

	render() {
		const { t } = this.props

		return (
			<div>
				{this.props.canEditProfile && (
					<SettingsSection
						visible={this.props.subSection === 'emails'}
						title={t('settings.emails.title')}
						subTitle={t('settings.emails.subTitle')}
						onToggle={this.props.subSection !== 'emails' ? this.getToUrl(settingsEmailsRoute()) : undefined}
					>
						<Emails />
					</SettingsSection>
				)}

				{this.props.canEditProfile && (
					<SettingsSection
						visible={this.props.subSection === 'paymentReminders'}
						title={t('settings.paymentReminders.title')}
						onToggle={
							this.props.subSection !== 'paymentReminders' ? this.getToUrl(settingsPaymentRemindersRoute()) : undefined
						}
					>
						<PaymentReminders />
					</SettingsSection>
				)}

				{this.props.canEditProfile && (
					<SettingsSection
						visible={this.props.subSection === 'accountingDocumentCategories'}
						title={t('settings.accountingDocumentCategories.title')}
						onToggle={
							this.props.subSection !== 'accountingDocumentCategories'
								? this.getToUrl(settingsAccountignDocumentCategoriesRoute())
								: undefined
						}
					>
						<AccountingDocumentCategories />
					</SettingsSection>
				)}

				{this.props.canEditApiApps && (
					<SettingsSection
						visible={this.props.subSection === 'api'}
						title={t('settings.api.title')}
						onToggle={this.props.subSection !== 'api' ? this.getToUrl(settingsApiRoute()) : undefined}
					>
						<Api />
					</SettingsSection>
				)}

				<SettingsSection
					title={t('settings.printing.title')}
					visible={this.props.subSection === 'printing'}
					onToggle={this.props.subSection !== 'printing' ? this.getToUrl(settingsPrintingRoute()) : undefined}
				>
					<Printing loading={this.props.organizationSettingsLoading} />
				</SettingsSection>

				{this.props.currentUserIsInternal && (
					<SettingsSection
						visible={this.props.subSection === 'extraction'}
						title={t('settings.extraction.title')}
						onToggle={this.props.subSection !== 'extraction' ? this.getToUrl(settingsExtractionRoute()) : undefined}
					>
						<Extraction />
					</SettingsSection>
				)}

				{this.props.currentUserIsInternal && (
					<SettingsSection
						visible={this.props.subSection === 'upload-reminder'}
						title={t('settings.uploadReminder.title')}
						onToggle={
							this.props.subSection !== 'upload-reminder' ? this.getToUrl(settingsUploadReminderRoute()) : undefined
						}
					>
						<UploadReminder />
						<VatSupportingDocuments />
					</SettingsSection>
				)}
			</div>
		)
	}
}

export default withRouter(withTranslate(TriviSettingsPage))
