//@flow
import { connect } from 'react-redux'
import type { State, PortalLanguage, UserProfile } from 'types'
import TriviStoreGrid, { type Props as TriviStoreGridProps } from '../components/trivi-store-grid'
import { getTriviStoreItems } from 'modules/trivi-store/constants'
import type { CashbotAction } from 'modules/cashbot/actions/action-types'
import { loginToCashbot } from 'modules/cashbot/actions'
import { getIsCashbotAuthenticated } from 'modules/cashbot/selectors'
import { isCashbotEnabledSelector } from 'modules/organization/selectors'
import { getMyProfile } from 'modules/user/selectors'

type StateProps = {|
	language: ?PortalLanguage,
	isCashbotEnabled: boolean,
	isCashbotAuthenticated: boolean,
|}

const mapStateToProps = (state: State): StateProps => {
	const myUserProfile: ?UserProfile = getMyProfile(state)

	return {
		language: (myUserProfile && myUserProfile.language) || null,
		isCashbotEnabled: isCashbotEnabledSelector(state),
		isCashbotAuthenticated: getIsCashbotAuthenticated(state),
	}
}

type DispatchProps = {|
	loginToCashbot: () => Promise<void>,
|}
const mapDispatchToProps = (dispatch: Dispatch<CashbotAction>): DispatchProps => {
	return {
		loginToCashbot: async () => {
			return dispatch(loginToCashbot())
		},
	}
}

const mergeProps = (stateProps: StateProps, dispatchProps: DispatchProps): TriviStoreGridProps => {
	const config = {
		cashbot: {
			isCashbotEnabled: stateProps.isCashbotEnabled,
			isCashbotAuthenticated: stateProps.isCashbotAuthenticated,
			loginToCashbot: dispatchProps.loginToCashbot,
		},
	}
	return {
		items: getTriviStoreItems(config),
		language: stateProps.language,
	}
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(TriviStoreGrid)
