import React from 'react'
import SvgIcon from 'components/SvgIcon'
import pure from 'recompose/pure'

const overrides = { viewBox: '0 0 68 68' }

let DragDrop = props => {
	return (
		<SvgIcon {...props} {...overrides}>
			<g fill="none" fillRule="evenodd">
				<path
					d="M45.633 1.485h-5.678a.73.73 0 010-1.458h5.678c.404 0 .729.326.729.73 0 .402-.325.728-.73.728zm11.36 0h-5.68a.728.728 0 01-.73-.729c0-.403.325-.729.73-.729h5.68a.73.73 0 010 1.458zm9.792 3.266a.726.726 0 01-.712-.568 3.48 3.48 0 00-3.367-2.698.745.745 0 01-.749-.729c0-.403.31-.729.712-.729h.037c2.274 0 4.29 1.612 4.789 3.834a.728.728 0 01-.71.89zm-35.777.927a.718.718 0 01-.729-.717v-.025c0-2.281 1.618-4.297 3.846-4.794a.731.731 0 01.871.554.73.73 0 01-.552.87 3.475 3.475 0 00-2.706 3.37.74.74 0 01-.73.742zm35.877 10.423a.73.73 0 01-.729-.73V9.694a.73.73 0 011.458 0v5.679a.73.73 0 01-.729.729zm-35.877.946a.728.728 0 01-.729-.73V10.64a.728.728 0 111.459 0v5.678a.73.73 0 01-.73.73zm35.877 10.41a.73.73 0 01-.729-.728V21.05a.73.73 0 011.458 0v5.679a.73.73 0 01-.729.729zm-35.877.947a.728.728 0 01-.729-.73v-5.678a.728.728 0 111.459 0v5.678a.73.73 0 01-.73.73zm32.576 8.867a.73.73 0 01-.154-1.442 3.477 3.477 0 002.726-3.374c0-.403.328-.753.73-.753.4 0 .728.302.728.705v.048c0 2.295-1.632 4.313-3.877 4.799a.71.71 0 01-.153.017zm-28.386.094h-.01c-2.288 0-4.305-1.625-4.798-3.863a.728.728 0 01.556-.869.727.727 0 01.869.556 3.474 3.474 0 003.372 2.717c.402 0 .735.327.735.73 0 .403-.322.729-.724.729zm11.358 0h-5.68a.73.73 0 010-1.459h5.68c.404 0 .729.327.729.73 0 .403-.325.729-.73.729zm11.356 0h-5.677a.728.728 0 01-.729-.73c0-.402.325-.729.73-.729h5.676a.73.73 0 010 1.459z"
					fill="#232626"
				/>
				<path
					d="M5.059 14.872a3.592 3.592 0 00-3.588 3.587L1.47 46.88a3.59 3.59 0 003.587 3.586h28.421a3.587 3.587 0 003.586-3.586v-28.42a3.59 3.59 0 00-3.586-3.587H5.058zm28.419 37.051H5.058A5.05 5.05 0 01.01 46.88l.002-28.42a5.051 5.051 0 015.046-5.046h28.419a5.05 5.05 0 015.045 5.046v28.42a5.049 5.049 0 01-5.045 5.044z"
					fill="#232626"
				/>
				<path
					d="M37.794 46.879a4.329 4.329 0 01-4.316 4.315H5.058A4.33 4.33 0 01.74 46.88l.002-28.42a4.33 4.33 0 014.317-4.316h28.419a4.33 4.33 0 014.316 4.316v28.42"
					fill="#FFFFFE"
				/>
				<path
					d="M5.059 14.872a3.592 3.592 0 00-3.588 3.587L1.47 46.88a3.59 3.59 0 003.587 3.586h28.421a3.587 3.587 0 003.586-3.586v-28.42a3.59 3.59 0 00-3.586-3.587H5.058zm28.419 37.051H5.058A5.05 5.05 0 01.01 46.88l.002-28.42a5.051 5.051 0 015.046-5.046h28.419a5.05 5.05 0 015.045 5.046v28.42a5.049 5.049 0 01-5.045 5.044z"
					fill="#232626"
				/>
				<path
					d="M35.236 56.253c.188 0 .373.068.515.21l9.175 9.173c.69.69 1.897.692 2.587-.005l2.98-2.972a1.842 1.842 0 00-.007-2.589l-9.16-9.172a.729.729 0 010-1.031l4.66-4.658c.279-.281.413-.572.359-.771-.054-.2-.32-.382-.7-.485l-21.762-5.838c-.313-.084-.67-.146-.867.11-.121.157-.139.463-.045.816l5.83 21.758c.043.166.22.71.572.71.194 0 .439-.137.678-.374l4.669-4.671a.722.722 0 01.516-.21zm10.98 11.377a3.262 3.262 0 01-2.321-.963l-8.66-8.653-4.152 4.153c-1.34 1.339-3.202.82-3.69-.992l-5.832-21.76c-.215-.812-.11-1.552.298-2.08.501-.65 1.403-.892 2.397-.63l21.764 5.838c.906.242 1.538.795 1.732 1.513.194.72-.074 1.515-.735 2.182l-4.148 4.147 8.648 8.654c1.277 1.288 1.277 3.373.006 4.649l-2.98 2.974a3.262 3.262 0 01-2.326.968z"
					fill="#232626"
				/>
				<path
					d="M41.838 50.385l4.66-4.66c.992-.997.696-2.113-.666-2.478l-21.76-5.836c-1.356-.354-2.164.46-1.806 1.817l5.831 21.76c.365 1.367 1.482 1.652 2.47.66l4.669-4.666 9.172 9.167c.997 1 2.62 1 3.62 0l2.977-2.974a2.575 2.575 0 000-3.618l-9.167-9.172-2.25 1.538"
					fill="#08B0CD"
				/>
				<path
					d="M35.236 56.253c.188 0 .373.068.515.21l9.175 9.173c.69.69 1.897.692 2.587-.005l2.98-2.972a1.842 1.842 0 00-.007-2.589l-9.16-9.172a.729.729 0 010-1.031l4.66-4.658c.279-.281.413-.572.359-.771-.054-.2-.32-.382-.7-.485l-21.762-5.838c-.313-.084-.67-.146-.867.11-.121.157-.139.463-.045.816l5.83 21.758c.043.166.22.71.572.71.194 0 .439-.137.678-.374l4.669-4.671a.722.722 0 01.516-.21zm10.98 11.377a3.262 3.262 0 01-2.321-.963l-8.66-8.653-4.152 4.153c-1.34 1.339-3.202.82-3.69-.992l-5.832-21.76c-.215-.812-.11-1.552.298-2.08.501-.65 1.403-.892 2.397-.63l21.764 5.838c.906.242 1.538.795 1.732 1.513.194.72-.074 1.515-.735 2.182l-4.148 4.147 8.648 8.654c1.277 1.288 1.277 3.373.006 4.649l-2.98 2.974a3.262 3.262 0 01-2.326.968zM10.723 67.966a.729.729 0 01-.516-1.245l10.409-10.405a.729.729 0 111.03 1.031L11.24 67.752a.727.727 0 01-.516.214M11.79 60.936a.73.73 0 01-.516-.216.729.729 0 010-1.031l2.866-2.863a.729.729 0 111.03 1.03l-2.865 2.864a.73.73 0 01-.515.216M48.945 22.353a.73.73 0 01-.729-.729v-6.403a.73.73 0 011.459 0v6.403a.73.73 0 01-.73.73"
					fill="#232626"
				/>
				<path
					d="M48.945 22.9a.73.73 0 01-.515-.213l-3.034-3.031a.729.729 0 111.031-1.031l2.518 2.515 2.521-2.515a.73.73 0 111.032 1.03l-3.037 3.032a.73.73 0 01-.516.213"
					fill="#232626"
				/>
			</g>
		</SvgIcon>
	)
}
DragDrop = pure(DragDrop)

export default DragDrop
