/* @flow */
/** @jsx jsx */

import { Component } from 'react'
import { jsx } from '@emotion/core'
import { colors } from 'variables'

const rowsHeight = 24

type Props = {|
	id?: string,
	name?: string,
	autoFocus?: boolean,
	autoComplete?: boolean,
	autoWidth?: boolean,
	autoHeight?: boolean,
	disabled?: boolean,
	disableNewlines?: boolean,
	hintText?: string,
	right?: boolean,
	rows?: number,
	rowsMax?: number,
	size?: number,
	value?: ?string | ?number,
	style?: Object,
	tabIndex?: number,
	title?: string,
	onChange?: (SyntheticInputEvent<HTMLInputElement>) => void,
	onBlur?: (SyntheticInputEvent<HTMLInputElement>) => void,
	onFocus?: (SyntheticInputEvent<HTMLInputElement>) => void,
	onKeyUp?: (SyntheticKeyboardEvent<HTMLInputElement>) => void,
	onKeyDown?: (SyntheticKeyboardEvent<HTMLInputElement>) => void | Promise<void>,
|}

type State = {|
	height: ?number,
|}

class EnhancedTextarea extends Component<Props, State> {
	static defaultProps = {
		autoComplete: true,
	}

	state = {
		height: null,
	}

	UNSAFE_componentWillMount() {
		this.props.rows &&
			this.setState({
				height: this.props.rows * rowsHeight,
			})
	}

	componentDidMount() {
		this.syncHeightWithShadow(this.props.value)
	}

	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		if (nextProps.value !== this.props.value || nextProps.rowsMax !== this.props.rowsMax) {
			setTimeout(() => {
				this.syncHeightWithShadow(nextProps.value, null, nextProps)
			}, 1)
		}
	}

	getInputNode() {
		return this.refs.input
	}

	syncHeightWithShadow(newValue: ?string | ?number, event: ?Event, props: ?Props) {
		const shadow = this.refs.shadow
		const displayText =
			this.props.hintText && (newValue === '' || newValue === undefined || newValue === null)
				? this.props.hintText
				: newValue

		if (displayText !== undefined) shadow.value = displayText

		if (this.props.autoHeight) shadow.style.height = 0

		let newHeight = shadow.scrollHeight

		// Guarding for jsdom, where scrollHeight isn't present.
		// See https://github.com/tmpvar/jsdom/issues/1013
		if (newHeight === undefined) return

		props = props || this.props

		if (props.rows && props.rowsMax && props.rowsMax >= props.rows) {
			newHeight = Math.min(props.rowsMax * rowsHeight, newHeight)
		}

		newHeight = props.rows ? Math.max(newHeight, rowsHeight) : newHeight

		if (this.state.height !== newHeight) {
			this.setState({
				height: newHeight,
			})
		}
	}

	handleChange = (event: SyntheticInputEvent<HTMLInputElement>) => {
		const value = event.target instanceof HTMLInputElement ? event.target.value : undefined

		this.props.onChange && this.props.onChange(event)

		setTimeout(() => {
			this.syncHeightWithShadow(value)
		}, 1)
	}

	onKeyDown = (event: SyntheticKeyboardEvent<HTMLInputElement>) => {
		this.props.disableNewlines && event && event.keyCode === 13 && event.preventDefault()
		this.props.onKeyDown && this.props.onKeyDown(event)
	}

	render() {
		const styles = getStyles(this.props, this.state)
		const autoComplete = this.props.autoComplete ? 'on' : 'off'

		return (
			<div css={{ ...styles.root, ...this.props.style }}>
				<textarea
					ref="shadow"
					css={{ ...styles.textarea, ...styles.shadow }}
					tabIndex="-1"
					rows={this.props.rows || 1}
					readOnly
					value={this.props.value || ''}
					autoComplete={autoComplete}
					disabled={this.props.disabled}
					title={''}
				/>
				<textarea
					id={this.props.id}
					name={this.props.name}
					size={this.props.size}
					ref="input"
					rows={this.props.rows}
					css={styles.textarea}
					onChange={this.handleChange}
					onBlur={this.props.onBlur}
					onFocus={this.props.onFocus}
					onKeyUp={this.props.onKeyUp}
					onKeyDown={this.onKeyDown}
					autoComplete={autoComplete}
					tabIndex={this.props.tabIndex}
					disabled={this.props.disabled}
					value={this.props.value || ''}
					autoFocus={this.props.autoFocus}
					title={this.props.title || ''}
				/>
			</div>
		)
	}
}

function getStyles(props: Props, state: State) {
	return {
		root: {
			position: 'relative', // because the shadow has position: 'absolute'
			maxWidth: '100%',
			display: 'flex',
		},
		textarea: {
			height: state.height || 'auto',
			maxWidth: '100%',
			width: '100%',
			resize: 'none',
			font: 'inherit',
			padding: 0,
			cursor: 'inherit',
			boxSizing: 'border-box',
			position: 'relative',
			border: 'none',
			outline: 'none',
			backgroundColor: 'transparent',
			color: colors.black,
			margin: 0,
			textAlign: props.right ? 'right' : 'left',
			textOverflow: props.autoWidth ? 'clip' : 'ellipsis',
			appearance: 'textfield',
			overflow: state.height ? 'hidden' : 'auto',
		},
		shadow: {
			// Overflow also needed to here to remove the extra row
			// added to textareas in Firefox.
			overflow: 'hidden',
			// Visibility needed to hide the extra text area on ipads
			visibility: 'hidden',
			position: 'absolute',
		},
	}
}

export default EnhancedTextarea
