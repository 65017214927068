import React from 'react'
import pure from 'recompose/pure'
import SvgIcon from '../../SvgIcon'

let CZFlag = props => (
	<SvgIcon {...props} viewBox="0 0 27 16">
		<g id="Onboarding" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g id="1-Login" transform="translate(-1172.000000, -252.000000)" fillRule="nonzero">
				<g id="cz-flag" transform="translate(1172.000000, 252.000000)">
					<rect id="_x33_" fill="#D7141A" x="0" y="0.09" width="27" height="15.57" />
					<rect id="_x32_" fill="#FFFFFF" x="0" y="0" width="27" height="7.83" />
					<polygon id="_x31_" fill="#11457E" points="13.5 7.875 0 0.09 0 15.66" />
				</g>
			</g>
		</g>
	</SvgIcon>
)
CZFlag = pure(CZFlag)

export default CZFlag
