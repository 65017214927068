/* @flow */

import type { AccountingDocumentExtractionStats, ScanFactoryDocumentFileMetadata, State as AppState } from 'types'
import { getBbWordFieldMap as getBbWordFieldMapFn, getFieldBbWordMap as getFieldBbWordMapFn } from '../domain/field'
import type {
	BbWordFieldMap,
	DocumentExtractionState,
	ExtractionDocument,
	ExtractionErrors,
	FieldBbWordMap,
	FieldCursor,
} from '../types'

type State = AppState | DocumentExtractionState

function getDocumentExtractionState(state: State): DocumentExtractionState {
	return state.documentExtraction || state
}

export function getExtractionDocument(state: State): ?ExtractionDocument {
	return getDocumentExtractionState(state).extractionDocument
}

export function getFieldCursor(state: State): ?FieldCursor {
	const documentExtractionState = getDocumentExtractionState(state)
	return documentExtractionState.extractionDocument && documentExtractionState.extractionDocument.fieldCursor
}

export function isFieldEditing(state: State): boolean {
	const documentExtractionState = getDocumentExtractionState(state)
	return !!documentExtractionState.extractionDocument && documentExtractionState.extractionDocument.fieldEditing
}

export function getMetadata(state: State): ?ScanFactoryDocumentFileMetadata {
	const documentExtractionState = getDocumentExtractionState(state)
	return documentExtractionState.extractionDocument && documentExtractionState.extractionDocument.metadata
}

export const getBbWordFieldMap = (state: State): BbWordFieldMap => {
	const metadata = getMetadata(state)
	if (!metadata) {
		return {}
	}

	return getBbWordFieldMapFn(metadata)
}

export const getFieldBbWordMap = (state: State): FieldBbWordMap => {
	const metadata = getMetadata(state)
	if (!metadata) {
		return {}
	}

	return getFieldBbWordMapFn(metadata)
}

export function getPreviewWidth(state: State): ?number {
	return getDocumentExtractionState(state).previewWidth
}

export function hasExtractionErrors(state: State): boolean {
	const extractionDocument = getExtractionDocument(state)

	return !!extractionDocument && !!extractionDocument.errors
}

export function getExtractionErrors(state: State): ?ExtractionErrors {
	const extractionDocument = getExtractionDocument(state)

	return extractionDocument && extractionDocument.errors
}

export function getExtractionStats(state: State): ?AccountingDocumentExtractionStats {
	return getDocumentExtractionState(state).extractionStats
}

export function isSaving(state: State): boolean {
	return true === getDocumentExtractionState(state).saving
}

export function isLoading(state: State): boolean {
	return true === getDocumentExtractionState(state).loading
}

export function isWorking(state: State): boolean {
	return isSaving(state) || isLoading(state)
}
