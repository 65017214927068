// @flow
/** @jsx jsx */
/*  eslint-disable react/no-multi-comp */

import { jsx } from '@emotion/core'
import type { PaymentOrder, BankAccount, AccountingDocument, AccountingDocument_IssuedPaymentOrders } from 'types'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import { Tooltip, TriviLink } from 'components'
import InfoIcon from 'components/svg-icons/action/info'
import { BankAccounts_nameById } from 'modules/accounting-document/domain/bank-account'
import { AccDocs_numberById } from 'modules/accounting-document/domain/accounting-document'
import BankOrderInfo from './bank-order-info'
import { accountingDocumentRoute } from 'modules/accounting-document/routing/routes'

type Props = {|
	paymentOrders: AccountingDocument_IssuedPaymentOrders,
	bankAccounts: Array<BankAccount>,
	accDocs: Array<AccountingDocument>,
	...WithTranslateProps,
|}

type Order = {|
	paymentOrder: PaymentOrder,
	bankName: ?string,
|}

type AccDocOrders = {|
	accDocId: string,
	accDocNumber: ?string,
	orders: Array<Order>,
|}

const getAccDocOrdersList = (props: Props): Array<AccDocOrders> => {
	return Object.keys(props.paymentOrders).map((accDocId: string) => ({
		accDocId,
		accDocNumber: AccDocs_numberById(props.accDocs, accDocId),
		orders: props.paymentOrders[accDocId].paymentOrders.map((paymentOrder: PaymentOrder) => ({
			paymentOrder,
			bankName:
				BankAccounts_nameById(props.bankAccounts, paymentOrder.bankAccountId) || 'ID:' + paymentOrder.bankAccountId,
		})),
	}))
}

const renderOrder = (order: Order, index: number) => {
	return <BankOrderInfo key={index} order={order.paymentOrder} bankAccountName={order.bankName} />
}

const renderAccDocOrders = (accDocOrders: AccDocOrders, index: number) => {
	const label = <div> {accDocOrders.orders.map(renderOrder)} </div>
	return (
		<Tooltip hoverable key={index} label={label} inline>
			<TriviLink style={style.infoItem} routeFn={accountingDocumentRoute} routeParams={[accDocOrders.accDocId]}>
				<span style={style.span}>{accDocOrders.accDocNumber}</span>
			</TriviLink>
		</Tooltip>
	)
}

const IssuedOrdersInfo = (props: Props) => {
	const accDocOrdersList: Array<AccDocOrders> = getAccDocOrdersList(props)

	if (!accDocOrdersList.length) return null

	return (
		<div css={style.paymentOrdersInfo}>
			<InfoIcon disabled style={style.infoItem} />

			<div css={style.infoItem}>
				{props.t('paymentDialog.issuedPaymentOrdersInfo', { count: accDocOrdersList.length })}
			</div>

			{accDocOrdersList.map(renderAccDocOrders)}
		</div>
	)
}

const style = {
	infoItem: { marginRight: 5 },
	span: { textDecoration: 'underline' },
	paymentOrdersInfo: {
		marginTop: 10,
		display: 'flex',
		alignItems: 'center',
	},
}

export default withTranslate(IssuedOrdersInfo)
