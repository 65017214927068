/* @flow */
/** @jsx jsx */

import { Component } from 'react'
import { jsx } from '@emotion/core'
import type { Enum, EnumItem, Enumeration, Scan, UploadScanMassChange, AccountingDocumentCategory } from 'types'
import { PAYMENT_TYPES } from 'types/convertor'
import { autoTestId } from 'utils/tests/autotest'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import UploadScanListItem from './upload-scan-list-item'
import { getCategoriesOptions } from '../../domain/categories'
import CashRegisterSelector from 'modules/common/containers/cash-register-selector'
import SelectNext, { type OptionType } from 'components/select-next'
import IconButton from 'components/icon-button'
import Paper from 'components/Paper'
import Button from 'components/button'
import Checkbox from 'components/checkbox'
import DeleteDialog from 'components/delete-dialog'
import ChainIcon from 'components/svg-icons/trivi/chain'
import Delete from 'components/svg-icons/action/delete'
import { EMPTY_ARRAY } from 'trivi-constants'
import { colors } from 'variables'

function getStyles(props: Props) {
	const { compact } = props

	return {
		root: {
			padding: compact ? '10px 0' : '20px 0',
		},
		h2: {
			fontSize: compact ? 18 : 24,
			color: colors.black,
			lineHeight: compact ? '22px' : 1,
			fontWeight: 'normal',
			padding: 0,
			margin: 0,
		},
		toolbar: {
			height: 'auto',
			display: 'flex',
			width: 'auto',
			position: 'relative',
			verticalAlign: 'middle',
			minHeight: 60,
			fontSize: 14,
			color: colors.grey900,
		},
		counter: {
			paddingTop: 5,
			background: colors.blue,
			width: 60,
			height: '100%',
			minHeight: 60,
			flex: '0 0 60px',
			maxWidth: 60,
			textAlign: 'center',
			fontSize: 10,
			color: colors.white,
			lineHeight: '20px',
			fontWeight: 'normal',
			textTransform: 'uppercase',
		},
		counterNumber: {
			fontSize: 20,
			fontWeight: 'normal',
			lineHeight: '30px',
		},
		button: {
			margin: '0 10px',
			verticalAlign: 'middle',
		},
		selectField: {
			margin: '0 10px !important',
			padding: '6px 0',
		},
		toolbarItems: {
			paddingLeft: 12,
			flex: '1 1 auto',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'flex-start',
			flexWrap: 'wrap',
		},
		toolbarActions: {
			paddingRight: 6,
			display: 'flex',
			justifyContent: 'flex-end',
			alignItems: 'center',
			flex: '0 0 auto',
		},
		toolbarActionItem: {
			margin: '0 4px',
			lineHeight: '24px',
		},
		rows: {
			padding: '10px 0 20px 0',
		},
		category: {
			position: 'relative',
			marginBottom: 40,
		},
		categoryHeadline: {
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center',
			margin: compact ? '30px 0 18px 0' : '20px 0',
		},
		categoryCheckbox: {
			position: 'absolute',
			top: -1,
			left: -28,
		},
		select: {
			verticalAlign: 'middle',
			paddingRight: 5,
		},
		iconButton: {
			verticalAlign: 'middle',
		},
		reuploadAll: {
			display: 'block',
		},
	}
}

type Props = {|
	...WithTranslateProps,
	uploadingScanIds: Array<number>,
	scans: Array<Scan>,
	documentCategories?: ?Array<AccountingDocumentCategory>,
	branches: ?Enum,
	projects: ?Enum,
	paymentTypes: ?Enum,
	loadBranches: () => void,
	loadProjects: () => void,
	loadPaymentTypes: () => void,
	onToggle: (number, boolean) => void,
	uncheckAll: () => void,
	onBranchChange: (number, ?string) => void,
	onProjectChange: (number, ?string) => void,
	onPaymentTypeChange: (number, string) => void,
	onCustomerInstructionsChange: (number, string) => void,
	onCashRegisterChange: (number, ?string) => void,
	onCategoryChange: (scanId: number, category: ?string) => void,
	onMassChange: (items: Array<Scan>, change: UploadScanMassChange) => void,
	onDelete: (Array<Scan>) => void,
	onJoin: (Array<Scan>) => void,
	onUnjoin: Scan => void,
	reUploadScan?: (scanId: string) => void,
	reUploadAllScans?: () => void,
	errorScans?: Array<Scan>,
	compact?: boolean,
	onFileDeleted?: (id: string) => void,
	onFilePreview?: (fileId: string) => void,
|}

class UploadScanList extends Component<Props> {
	componentDidMount() {
		if (!this.props.compact) {
			!this.props.branches && this.props.loadBranches()
			!this.props.projects && this.props.loadProjects()
			!this.props.paymentTypes && this.props.loadPaymentTypes()
		}
	}

	getOptions = (items: Enum) => {
		return items.map((item: EnumItem) => {
			return { value: item.key, label: item.value }
		})
	}

	getCheckedItems = (items: Array<Scan>): Array<Scan> => {
		return items.filter((item: Scan) => {
			return item.checked
		})
	}

	getCheckedItemsByCategory = (items: Array<Scan>): Array<Scan> => {
		return items.filter((item: Scan) => {
			return item.checked
		})
	}

	// Can join only scans with the same documents category
	canJoinScans = (items: Array<Scan>): boolean => {
		const checked: Array<Scan> = this.getCheckedItems(items)
		const types: Array<?string> = checked.map(function(item: Scan) {
			return item.documentCategory
		})
		return (
			types.filter(function(value: ?string, index: number) {
				return index == types.lastIndexOf(value)
			}).length > 0 // TODO: disabled same categories control, to enable === 1
		)
	}

	handleMassChange = (scans: Array<Scan>, change: UploadScanMassChange) => {
		const items = this.getCheckedItems(scans)
		this.props.onMassChange(items, change)
	}

	handleTypeChange = (option: OptionType) => {
		this.handleMassChange(this.props.scans, { documentCategory: option.value })
	}

	handleBranchChange = (option: ?OptionType) => {
		const value = option ? option.value : null
		this.handleMassChange(this.props.scans, { branch: value })
	}

	handleProjectChange = (option: ?OptionType) => {
		const value = option ? option.value : null
		this.handleMassChange(this.props.scans, { project: value })
	}

	handlePaymentTypeChange = (option: OptionType) => {
		this.handleMassChange(this.props.scans, { paymentType: option.value })
	}

	handleCashRegisterChange = (value: ?string) => {
		this.handleMassChange(this.props.scans, { cashRegisterId: value })
	}

	handleDeleteFiles = () => {
		DeleteDialog()
			.then(() => {
				const { scans } = this.props
				const items: Array<Scan> = this.getCheckedItems(scans)
				this.props.onDelete(items)
			})
			.catch(() => {})
	}

	handleDeleteFile = (scan: Scan) => {
		DeleteDialog()
			.then(() => {
				this.props.onDelete([scan])
			})
			.catch(() => {})
	}

	handleJoinScans = () => {
		const { scans } = this.props
		const items = this.getCheckedItems(scans)
		this.props.onJoin(items)
		this.props.uncheckAll()
	}

	getPaymentTypes = (): ?Enum => {
		const { t, paymentTypes } = this.props

		if (!paymentTypes) {
			return null
		}

		return paymentTypes.map((item: Enumeration) => {
			return {
				key: item.key,
				value: t('accountingDocument.paymentTypes.' + PAYMENT_TYPES[item.key || '']),
			}
		})
	}

	handleCheckScans = (scans: Array<Scan>, checked: boolean) => {
		scans.forEach((scan: Scan) => {
			this.props.onToggle(scan.id, checked)
		})
	}

	onToggle = (id: number, value: boolean) => {
		this.props.onToggle(id, value)
	}

	renderScans(category: string, scans: Array<Scan>, error: boolean, index?: number) {
		const {
			documentCategories,
			branches,
			projects,
			paymentTypes,
			onBranchChange,
			onProjectChange,
			onPaymentTypeChange,
			onCustomerInstructionsChange,
			onCashRegisterChange,
			onCategoryChange,
		} = this.props
		const styles = getStyles(this.props)

		const selectedScans = this.getCheckedItems(scans)
		const allChecked = selectedScans.length === scans.length
		const someChecked = selectedScans.length > 0 && !allChecked
		const getHandler = () => (event: any, checked: boolean) => {
			this.handleCheckScans(scans, checked)
		}

		return (
			<div
				css={styles.category}
				key={`categoryWrapper${category}${error.toString()}-${(index || -1).toString()}`}
				{...autoTestId('upload-scan-list-category')}
			>
				<div css={styles.categoryHeadline} {...autoTestId('upload-scan-list-category-headline')}>
					{!error && (
						<div css={styles.categoryCheckbox}>
							<Checkbox
								checked={allChecked || someChecked}
								showMinusIcon={someChecked || !allChecked}
								onCheck={getHandler()}
								autoTestId="upload-scan-list-category-checkbox"
							/>
						</div>
					)}
					<h2 css={styles.h2}>{category}</h2>
					{error && this.props.reUploadAllScans && (
						<div css={styles.reuploadAll}>
							<Button
								transparent
								labelText={this.props.t('uploadScan.reuploadAllErrorScans')}
								onClick={this.props.reUploadAllScans}
								autoTestId="upload-scan-list-item-reupload-all"
							/>
						</div>
					)}
				</div>
				{scans.map((item: Scan) => (
					<UploadScanListItem
						key={`${category}-${item.id}`}
						compact={this.props.compact || undefined}
						scan={item}
						documentCategories={documentCategories || EMPTY_ARRAY}
						branches={branches || EMPTY_ARRAY}
						projects={projects || EMPTY_ARRAY}
						paymentTypes={paymentTypes || EMPTY_ARRAY}
						onToggle={this.onToggle}
						onBranchChange={onBranchChange}
						onProjectChange={onProjectChange}
						onPaymentTypeChange={onPaymentTypeChange}
						onCustomerInstructionsChange={onCustomerInstructionsChange}
						onCashRegisterChange={onCashRegisterChange}
						onCategoryChange={onCategoryChange}
						onDelete={this.handleDeleteFile}
						onUnjoin={this.props.onUnjoin}
						loading={this.props.uploadingScanIds.indexOf(item.id) > -1}
						error={error || false}
						reUploadScan={this.props.reUploadScan}
						onFilePreview={this.props.onFilePreview || undefined}
					/>
				))}
			</div>
		)
	}

	getCashEnumItemKey = (): ?string => {
		const cashEnumItem =
			this.props.paymentTypes && this.props.paymentTypes.find((paymentType: EnumItem) => paymentType.value === 'Cash')
		return cashEnumItem && cashEnumItem.key
	}

	getCommonValueFromSelected = (key: string) => (scans: Array<Scan>) => {
		if (!scans || !scans.length) return null
		return scans.reduce((result: ?string, current: Scan) => {
			return current[key] === result ? result : null
		}, scans[0][key])
	}

	getProjectFromSelected = this.getCommonValueFromSelected('project')
	getBranchFromSelected = this.getCommonValueFromSelected('branch')
	getPaymentTypeFromSelected = this.getCommonValueFromSelected('paymentType')
	getCashRegisterFromSelected = this.getCommonValueFromSelected('cashRegisterId')
	getCategoryFromSelected = this.getCommonValueFromSelected('documentCategory')

	renderSelectedOptions() {
		const { t, documentCategories, branches, projects, scans } = this.props
		const selectedScans = this.getCheckedItems(scans)
		const count = selectedScans.length
		const paymentTypes = this.getPaymentTypes()
		const showCashRegisterSelector = this.getPaymentTypeFromSelected(selectedScans) === this.getCashEnumItemKey()
		const styles = getStyles(this.props)

		return (
			<div>
				{count > 0 && (
					<Paper rounded zDepth={3} css={styles.toolbar} style={{ borderWith: 2, borderColor: colors.blue }}>
						<div css={styles.counter}>
							<span css={styles.counterNumber}>{count}</span>
							<br />
							{t('uploadScan.chosen')}
						</div>
						<div css={styles.toolbarItems}>
							{branches && branches.length > 0 && (
								<span css={styles.selectField}>
									<SelectNext
										isClearable
										isSearchable
										label={t('uploadScan.branch')}
										autoTestId="upload-list-branch"
										options={this.getOptions(branches)}
										onChange={this.handleBranchChange}
										value={this.getBranchFromSelected(selectedScans)}
										autoWidth
										portal
										inline
									/>
								</span>
							)}
							{projects && projects.length > 0 && (
								<span css={styles.selectField}>
									<SelectNext
										isClearable
										isSearchable
										label={t('uploadScan.project')}
										autoTestId="upload-list-project"
										options={this.getOptions(projects)}
										onChange={this.handleProjectChange}
										value={this.getProjectFromSelected(selectedScans)}
										autoWidth
										portal
										inline
									/>
								</span>
							)}
							{paymentTypes && paymentTypes.length > 0 && (
								<span css={styles.selectField}>
									<SelectNext
										onChange={this.handlePaymentTypeChange}
										label={t('uploadScan.paymentType')}
										autoTestId="upload-list-payment-type"
										options={this.getOptions(paymentTypes)}
										value={this.getPaymentTypeFromSelected(selectedScans)}
										autoWidth
										portal
										inline
									/>
								</span>
							)}
							{showCashRegisterSelector && (
								<span css={styles.selectField}>
									<CashRegisterSelector
										value={this.getCashRegisterFromSelected(selectedScans)}
										onChange={this.handleCashRegisterChange}
										label={t('uploadScan.cashRegister')}
										autoWidth
										inline
									/>
								</span>
							)}
							<span css={styles.selectField}>
								<SelectNext
									inline
									portal
									autoWidth
									onChange={this.handleTypeChange}
									value={this.getCategoryFromSelected(selectedScans) || ''}
									autoTestId="upload-list-type"
									options={[
										{ value: '', label: t('uploadScan.uploadedDocuments') },
										...getCategoriesOptions(documentCategories),
									]}
								/>
							</span>
						</div>
						<div css={styles.toolbarActions}>
							{count > 1 && (
								<div css={styles.toolbarActionItem}>
									<IconButton
										autoTestId="upload-list-connect"
										tooltip={t('uploadScan.connectDocuments')}
										disabled={!this.canJoinScans(scans)}
										onClick={this.handleJoinScans}
										style={styles.iconButton}
										circledWhenHovered
										size={34}
									>
										<ChainIcon />
									</IconButton>
								</div>
							)}
							<div css={styles.toolbarActionItem}>
								<IconButton
									autoTestId="upload-list-delete"
									tooltip={t('uploadScan.deleteTooltip')}
									disabled={!count}
									onClick={this.handleDeleteFiles}
									style={styles.iconButton}
									circledWhenHovered
									size={34}
								>
									<Delete size={22} />
								</IconButton>
							</div>
						</div>
					</Paper>
				)}
			</div>
		)
	}

	render() {
		const { t, documentCategories, scans, errorScans } = this.props
		const styles = getStyles(this.props)
		const scansByCategory: Object = {}
		scans.forEach((scan: Scan) => {
			let category: string = scan.documentCategory || ''
			scansByCategory[category] = scansByCategory[category] || []
			scansByCategory[category].push(scan)
		})

		const categoriesByKey: Object = {}
		documentCategories &&
			documentCategories.forEach((category: AccountingDocumentCategory) => {
				categoriesByKey[category.id] = category.name
			})

		return (
			<div css={styles.root}>
				{!this.props.compact && this.renderSelectedOptions()}

				{errorScans && errorScans.length > 0 && this.renderScans(t('uploadScan.errorScansCategory'), errorScans, true)}

				{Object.entries(scansByCategory).map(([category, categoryScans]: any, index: number) => {
					if (categoryScans && categoryScans.length > 0) {
						return this.renderScans(
							categoriesByKey[category] || t('uploadScan.uploadedDocuments'),
							categoryScans,
							false,
							index,
						)
					}
				})}
			</div>
		)
	}
}

export default withTranslate(UploadScanList)
