/* @flow */

import { head, last, toArray, uniq } from 'lodash-es'
import type { Channel, Member, Members, Message, Messages, MessagesByChannel, MessageStatus } from '../types'
import sid from '../utils/sid'
import { getChannelUnreadMessagesCount } from './channel'

export function createMessage(
	sid: string,
	body: string,
	from: string,
	status: MessageStatus,
	created: Date,
	index?: number,
	updated?: Date,
	attributes: { [string]: any } = {},
): Message {
	return {
		sid,
		body,
		from,
		status,
		created,
		updated,
		index,
		attributes,
	}
}

export function createNewMessage(body: string, from: string, index?: number): Message {
	const id = sid('LIM', 15)
	const message = createMessage(id, body, from, 'NEW', new Date(), index)

	message.attributes = { lid: id }

	return message
}

export function findMessageBySid(messages: Messages, sid: string): number {
	return messages.indexOf(messages.find((message: Message) => message.sid === sid))
}

export function findMessageByLid(messages: Messages, lid: string): number {
	return messages.indexOf(messages.find((message: Message) => message.attributes.lid === lid))
}

export function sortMessages(messages: Messages): Messages {
	return messages.sort((message1: Message, message2: Message): number => {
		if ('undefined' !== typeof message1.index && 'undefined' !== typeof message2.index) {
			// Both messages has an index (sort by index)
			return message1.index - message2.index
		} else if (
			// One message has an index (indexed messages first)
			('undefined' === typeof message1.index && 'undefined' !== typeof message2.index) ||
			('undefined' === typeof message2.index && 'undefined' !== typeof message1.index)
		) {
			return 'undefined' === typeof message1.index ? 1 : -1
		} else {
			// No message has an index (sort by created date)
			return message1.created > message2.created ? 1 : -1
		}
	})
}

export function mergeMessages(oldMessages: Messages, newMessages: Messages): Messages {
	let messages = [...oldMessages]

	newMessages.forEach((message: Message) => {
		let foundIndex: number

		foundIndex = findMessageBySid(messages, message.sid)
		if (-1 === foundIndex && 'undefined' !== message.attributes.lid) {
			foundIndex = findMessageByLid(messages, message.attributes.lid)
		}

		if (-1 !== foundIndex) {
			// Message is exists - replace with newer version
			messages[foundIndex] = message
		} else {
			// Message not exists - append
			messages = [...messages, ...[message]]
		}
	})

	return sortMessages(messages)
}

export type MessageReadStats = {
	[messageIndex: number]: Array<Member>,
}

export function createMessageReadStats(members: Members, currentUserId: string): MessageReadStats {
	return members
		.filter((member: Member) => member.id !== currentUserId)
		.reduce((stats: MessageReadStats, member: Member) => {
			if ('number' === typeof member.lastConsumedMessageIndex) {
				if ('undefined' === typeof stats[member.lastConsumedMessageIndex]) {
					stats[member.lastConsumedMessageIndex] = []
				}

				stats[member.lastConsumedMessageIndex].push(member)
			}
			return stats
		}, {})
}

export function getFirstMessageIndex(messages: Messages): number {
	// Messages are ordered by message.index
	const first = head(messages || [])
	return (first && first.index) || 0
}

export function getLastMessageIndex(messages: Messages): ?number {
	const lastMessage = getLastMessage(messages)
	return lastMessage ? lastMessage.index : null
}

export function getLastMessage(messages: Messages): ?Message {
	// Messages are ordered by message.index
	return last(messages)
}

export function getUnreadMessagesCount(
	channels: Array<Channel>,
	messagesByChannel: MessagesByChannel,
	currentUserId: string,
): number {
	return toArray(channels).reduce((total: number, channel: Channel) => {
		total += getChannelUnreadMessagesCount(channel, getLastMessage(messagesByChannel[channel.sid]), currentUserId)

		return total
	}, 0)
}

export function removeChannelMessages(messagesByChannel: MessagesByChannel, channelSid?: string): MessagesByChannel {
	const { [channelSid || '']: _, ...rest } = messagesByChannel
	return rest
}

export function getAllMessagesMemberIds(messagesByChannel: MessagesByChannel): Array<string> {
	return uniq(
		toArray(messagesByChannel).reduce((ids: Array<string>, messages: Array<Message>) => {
			messages.forEach((message: Message) => ids.push(message.from))
			return ids
		}, []),
	)
}
