// @flow
import React from 'react'
import type { AccountingDocument } from 'types'
import { AccountingDocument_Totals, RoundingType_RoundingTypeName } from 'types/convertor'
import { isRoundingVisible, getRoundedTotals } from 'modules/accounting-document/domain/templates'
import Totals from 'modules/accounting-document/components/invoice-elements/totals'
import { AccountingDocument_isProcessed } from 'types/operations'

type Props = {|
	isOrganizationVatFree: boolean,
	accountingDocument: AccountingDocument,
	onChange: (accountingDocument: AccountingDocument) => void,
|}

function TotalsTemplate({ accountingDocument, onChange, isOrganizationVatFree }: Props) {
	function onRoundingTypeChange(roundingType: ?number) {
		if (roundingType !== null && roundingType !== undefined) {
			onChange({
				...accountingDocument,
				roundingType,
			})
		}
	}

	const totals = getRoundedTotals(
		AccountingDocument_Totals(accountingDocument),
		RoundingType_RoundingTypeName(accountingDocument.roundingType),
	)
	const isProcessed = (accountingDocument && AccountingDocument_isProcessed(accountingDocument)) || false

	return (
		<Totals
			total={totals.total}
			totalVatExcl={totals.totalVatExcl}
			totalVatAmount={null}
			roundingAmount={totals.roundingAmount}
			connections={null}
			currency={accountingDocument.currency}
			domesticCurrency={null}
			showRounding={isRoundingVisible(accountingDocument)}
			isAccDocVatFree={isOrganizationVatFree}
			roundingType={accountingDocument.roundingType}
			onRoundingTypeChange={onRoundingTypeChange}
			// eslint-disable-next-line react/jsx-no-bind
			onConnectionChange={() => {}}
			isProcessed={isProcessed}
		/>
	)
}

export default TotalsTemplate
