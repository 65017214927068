// @flow
/** @jsx jsx */

import { Component } from 'react'
import { jsx } from '@emotion/core'
import memoize from 'memoize-one'
import TableHeader from './table-header'
import TableBody from './table-body'

type Child = React$Element<typeof TableBody | typeof TableHeader>
type Props = {|
	scrollable?: boolean,
	children: Child | Array<Child>,
|}

class Table extends Component<Props> {
	getStyles = memoize((scrollable: ?boolean) => {
		return {
			wrapper: {
				overflow: scrollable ? 'auto' : 'visible',
				overflowY: scrollable ? 'hidden' : 'visible',
				outline: 'none !important',
				paddingBottom: scrollable ? 18 : 0,
			},
			table: {
				width: scrollable ? 'auto' : '100%',
				overflow: scrollable ? 'hidden' : 'visible',
				minWidth: '100%',
				borderCollapse: 'collapse',
				borderSpacing: 0,
				tableLayout: 'fixed',
				cellPadding: 0,
				cellSpacing: 0,
			},
		}
	})

	render() {
		const styles = this.getStyles(this.props.scrollable)

		return (
			<div css={styles.wrapper}>
				<table css={styles.table}>{this.props.children}</table>
			</div>
		)
	}
}

export default Table
