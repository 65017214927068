/* @flow */

import React, { Component } from 'react'
import { emptyContactBankAccount } from 'types/empty'
import type { ContactBankAccount, Currency, FormValidationProps } from 'types'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import BankCodeAutocomplete from 'modules/bank/containers/bank-code-autocomplete'
import ContentClear from 'components/svg-icons/content/clear'
import GreenIcon from 'components/svg-icons/trivi/state/green'
import { CurrencySelector, IconButton, Paper, TextField, Tooltip, SwiftInput, IbanInput } from 'components'
import styles from './bank-account-edit.css'
import { colors } from 'variables'

type Props = {|
	...FormValidationProps,
	id: number,
	className?: string,
	disabled?: boolean,
	bankAccount: ContactBankAccount,
	onBlur: (address: ContactBankAccount) => void,
	onDelete: () => void,
|}

type State = {|
	bankAccount: ContactBankAccount,
|}

const style = {
	name: {
		fontSize: 24,
	},
	iconButton: {
		position: 'absolute',
		top: 0,
		right: 0,
	},
}

type ComponentProps = Props & WithTranslateProps

class BankAccountEdit extends Component<ComponentProps, State> {
	state: State = {
		bankAccount: this.props.bankAccount || emptyContactBankAccount(),
	}

	delete = this.props.onDelete || (() => {})

	onBankAccountCodeChange = (accountCode: string) => {
		const bankAccount = {
			...this.state.bankAccount,
			accountCode,
		}

		this.setState({ bankAccount })
		this.props.onBlur(bankAccount)
	}

	onFieldChange = (name: string, valIndex: number = 1) => (...args: Array<any>) => {
		let bankAccount = {
			...this.state.bankAccount,
			[name]: args[valIndex],
		}

		this.setState({ bankAccount })

		this.props.onBlur(bankAccount)
	}

	UNSAFE_componentWillReceiveProps(nextProps: ComponentProps) {
		this.setState({ bankAccount: nextProps.bankAccount })
	}

	onFieldChangeCurrency = (name: string) => (value: ?Currency) => {
		if (!value) {
			return
		}

		let bankAccount = {
			...this.state.bankAccount,
			[name]: value.id,
		}

		this.setState({ bankAccount })
		this.props.onBlur(bankAccount)
	}

	onFieldBlur = () => {
		this.props.onBlur(this.state.bankAccount)
	}

	getIsRegisteredIcon() {
		const { countryVariantSpecific } = this.state.bankAccount
		if (countryVariantSpecific != null && countryVariantSpecific.cz != null && countryVariantSpecific.cz.isRegistered) {
			return (
				<div className={styles.registred}>
					<Tooltip label={this.props.t('bankAccount.verified')}>
						<GreenIcon className={styles.verifiedIcon} />
					</Tooltip>
				</div>
			)
		} else {
			return null
		}
	}

	getValidationKey = (id: string): string => {
		return 'account_' + this.props.id + '_' + id
	}

	render() {
		const { t } = this.props
		const { bankAccount } = this.state

		return (
			<Paper rounded zDepth={4} className={styles.root} id={'bank-account-edit-container'}>
				<div className={styles.inner}>
					<div className={styles.up}>
						<div className={styles.name}>
							<TextField
								name={'name'}
								disabled={this.props.disabled}
								value={bankAccount.name}
								onChange={this.onFieldChange('name')}
								onBlur={this.onFieldBlur}
								hintText={t('bankAccount.tile.hint.name')}
								autoTestId="bank-account-edit-name"
								style={style.name}
								inline
								autoWidth
							/>
							{this.getIsRegisteredIcon()}
						</div>
						<CurrencySelector
							disabled={!!this.props.disabled}
							labelText={t('cashRegister.edit.currency')}
							value={bankAccount.currency}
							onChange={this.onFieldChangeCurrency('currency')}
							inline
							isHighlighted
						/>
					</div>
					<div className={styles.down}>
						<div className={styles.accountNo}>
							<TextField
								name={'accountNo'}
								disabled={this.props.disabled}
								value={this.props.validationValue(this.getValidationKey('number'), bankAccount.accountNo)}
								onChange={this.onFieldChange('accountNo')}
								autoTestId="bank-account-edit-no"
								onBlur={this.onFieldBlur}
								hintText={t('bankAccount.tile.hint.accountNo')}
								clientError={this.props.validationMessage(this.getValidationKey('number'))}
								inline
								autoWidth
							/>
							<div className={styles.slash}>/</div>
							<BankCodeAutocomplete
								inline
								autoWidth
								disabled={this.props.disabled}
								value={this.props.validationValue(this.getValidationKey('code'), bankAccount.accountCode)}
								onChange={this.onBankAccountCodeChange}
								hintText={t('bankAccount.tile.hint.accountCode')}
								autoTestId="bank-account-edit-code"
								clientError={this.props.validationMessage(this.getValidationKey('code'))}
								isCreatable
							/>
						</div>
						<SwiftInput
							name={'accountSwift'}
							disabled={this.props.disabled}
							value={this.props.validationValue(this.getValidationKey('swift'), bankAccount.accountSwift)}
							onChange={this.onFieldChange('accountSwift')}
							autoTestId="bank-account-edit-swift"
							onBlur={this.onFieldBlur}
							hintText={t('bankAccount.tile.hint.accountSwift')}
							clientError={this.props.validationMessage(this.getValidationKey('swift'))}
							inline
							autoWidth
						/>
						<br />
						<IbanInput
							name={'accountIban'}
							disabled={this.props.disabled}
							value={this.props.validationValue(this.getValidationKey('iban'), bankAccount.accountIban)}
							onChange={this.onFieldChange('accountIban')}
							autoTestId="bank-account-edit-iban"
							onBlur={this.onFieldBlur}
							hintText={t('bankAccount.tile.hint.accountIban')}
							clientError={this.props.validationMessage(this.getValidationKey('iban'))}
							inline
							autoWidth
						/>
					</div>
				</div>
				{!this.props.disabled && (
					<IconButton
						autoTestId="bank-account-edit-remove"
						style={style.iconButton}
						onClick={this.delete}
						color={colors.grey400}
						hoverColor={colors.grey}
					>
						<ContentClear size={20} />
					</IconButton>
				)}
			</Paper>
		)
	}
}

export default withTranslate(BankAccountEdit)
