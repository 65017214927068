/* @flow */
/** @jsx jsx */

import React, { PureComponent, type Element, type ChildrenArray } from 'react'
import { find } from 'lodash-es'
import memoize from 'memoize-one'
import { jsx } from '@emotion/core'
import Table from 'rc-table'
import { default as withTranslate, type Props as WithTranslateProps } from 'wrappers/with-translate'
import { Pager } from 'components/Pagination'
import { Column } from '../components'
import Checkbox from 'components/checkbox'
import ColumnHeader from '../components/column-header'
import DataGridActions from 'modules/data-grid/components/data-grid-actions'
import DataGridCheckbox from 'modules/data-grid/components/data-grid-checkbox'
import MultiActionsToolbar from 'modules/data-grid/components/multi-actions-toolbar'
import { filterToString } from '../utils'
import Sort from '../domain/sort'
import type { DataTypes, Direction, DataGridId, MultiAction } from '../types'
import type { Action, Filter } from 'modules/data-grid/types'
import noItemsImage from 'assets/images/grid-no-items.svg'
import { findFilterItemByFieldName } from '../selectors'
import RowExpandIcon from './row-expand-icon'
import getStyles from './data-grid-stateless.styles'
import { EMPTY_OBJECT, EMPTY_ARRAY, MULTI_ACTION_TYPE_CLEAR_SELECTION } from 'trivi-constants'

const { Column: OriginalColumn } = Table

export type Props<Item: $ElementType<DataTypes, string>> = {|
	id: DataGridId,
	dataType: Item,
	filterId?: string,
	children: ChildrenArray<Element<typeof Column> | null | false>,
	data: Array<Item>,
	style?: Object,
	loading: boolean,
	itemsCount: number,
	pageIndex: number,
	pageSize: number,
	sort?: Sort,
	defaultFilter?: Filter,
	defaultPageSize?: number,
	filter?: Filter,
	actions?: Array<Action<Item>>,
	multiActions?: Array<Action<Array<Item>>>,
	selectDisabled?: boolean,
	selectTooltip?: string,
	deselectTooltip?: string,
	selectOnClick?: boolean,
	selectedRows?: Array<string>,
	currentRowId?: ?string,
	showPageSize?: boolean,
	showItemsOfTotal?: boolean,
	showPrevButton?: boolean,
	showNextButton?: boolean,
	compactPager?: boolean,
	hideHeader?: boolean,
	light?: boolean,
	onNeedFreshData: (
		dataType: Item,
		id: DataGridId,
		page: number,
		pageSize: number,
		sort?: Sort,
		filter?: Filter,
	) => void,
	onPageChange: (id: DataGridId, page: number) => void,
	onPageSizeChange: (id: DataGridId, pageSize: number) => void,
	onSortChange: (id: DataGridId, sort: Sort) => void,
	onAction: (string, Item) => Promise<void>,
	onMultiAction?: (MultiAction, Array<Item>) => Promise<?MultiAction>,
	onActionMenuOpen?: (row: Item, needUpdate: () => void) => ?Promise<void>,
	onNeedUpdateMultiActionVisiblity?: (Array<Item>) => Promise<Array<string>>,
	onSelectionChange: (row: Item, selected: boolean) => Promise<void> | void,
	onCheckAllChange: (rows: { [string]: Item }, selected?: boolean) => void,
	checkAllOption?: boolean,
	beforeTableComponent?: any,
	expandedRowRender?: (record: Item, index: number, indent: number, expanded: boolean) => ?React$Element<any>,
	getRowClassName?: (
		row: Item,
		selectedRows: { [string]: Item },
		selectOnClick?: boolean,
		currentRowId?: ?string,
	) => string,
	expandRowByClick?: boolean,
	confirmOnCheckAction?: boolean,
|}

type State<Item> = {|
	expandedRowKeys: Array<string>,
	selectedRows: { [string]: Item },
	visibleMultiActions: Array<string>,
|}

class DataGrid<Item: $ElementType<DataTypes, string>> extends PureComponent<
	Props<Item> & WithTranslateProps,
	State<Item>,
> {
	static defaultProps = {
		onNeedFreshData: () => {},
		onPageChange: () => {},
		onPageSizeChange: () => {},
		onSortChange: () => {},
		onAction: async () => {},
		onMultiAction: () => Promise.resolve(),
		onSelectionChange: () => {},
		onCheckAllChange: () => {},
	}
	state: State<Item> = {
		expandedRowKeys: [],
		selectedRows: this.getSelectedRowObjects(this.props) || EMPTY_OBJECT,
		visibleMultiActions: EMPTY_ARRAY,
	}
	directionChangeHandlers: { [string]: (columnId: string, direction: Direction) => void } = {}
	defaultSort: Sort | void

	constructor(props: Props<Item> & WithTranslateProps) {
		super(props)
		this.updateFromChildren(props.children)
	}

	componentDidMount() {
		if (!this.props.sort && this.defaultSort) {
			this.props.onSortChange(this.props.id, this.defaultSort)
		}

		this.props.defaultPageSize && this.props.onPageSizeChange(this.props.id, this.props.defaultPageSize)

		this.props.onNeedFreshData(
			this.props.dataType,
			this.props.id,
			this.props.pageIndex,
			this.props.pageSize,
			this.props.sort || this.defaultSort,
			this.props.filter || this.props.defaultFilter,
		)
	}

	UNSAFE_componentWillReceiveProps(nextProps: Props<Item>) {
		if (this.props.children !== nextProps.children) {
			this.updateFromChildren(nextProps.children)
		}
		if (
			this.props.filter !== nextProps.filter &&
			filterToString(this.props.filter || []) !== filterToString(nextProps.filter || [])
		) {
			if (nextProps.pageIndex !== 0) {
				nextProps.onPageChange(nextProps.id, 0)
			}
			nextProps.onNeedFreshData(
				nextProps.dataType,
				nextProps.id,
				0,
				nextProps.pageSize,
				nextProps.sort,
				nextProps.filter,
			)
		}
		const oldDirection = this.props.filter && findFilterItemByFieldName(this.props.filter, 'direction')
		const newDirection = nextProps.filter && findFilterItemByFieldName(nextProps.filter, 'direction')
		const directionHasChanged = oldDirection !== newDirection
		if (
			(this.props.selectedRows !== nextProps.selectedRows || this.props.data !== nextProps.data) &&
			!directionHasChanged
		) {
			const selectedRows = this.getSelectedRowObjects(nextProps, this.state.selectedRows) || this.state.selectedRows
			this.setState({ selectedRows }, this.updateMultiActions)
		} else if (directionHasChanged) {
			this.resetSelectedRows()
		}
	}

	resetSelectedRows = () => {
		this.setState({ selectedRows: {} }, this.updateMultiActions)
		this.props.onCheckAllChange({})
	}

	getSelectedRowObjects(props: Props<Item>, old: ?{ [string]: Item }): ?{ [string]: Item } {
		if (props.selectedRows) {
			const selectedRows: { [string]: Item } = {}
			props.selectedRows.forEach((selectedRow: string) => {
				const match = props.data.find((dataItem: Item) => dataItem.id === selectedRow)
				if (match) {
					selectedRows[match.id || ''] = match
				}
			})
			return selectedRows
		}
		return old ? old : {}
	}

	updateFromChildren(children: ChildrenArray<Element<typeof Column> | null | false>) {
		this.defaultSort = undefined
		React.Children.forEach(
			children,
			(child: Element<typeof Column> | null | false) => {
				if (child) {
					if (child.props.onDirectionChange) {
						this.directionChangeHandlers[child.props.columnId] = child.props.onDirectionChange
					}

					if (child.props.defaultSortDirection) {
						if (this.defaultSort) {
							throw new Error('Cannot set multiple default sort directions')
						} else {
							this.defaultSort = new Sort(child.props.columnId, child.props.defaultSortDirection)
						}
					}
				}
			},
			this,
		)

		if (!this.defaultSort) {
			this.defaultSort = new Sort('id', 'DESC')
		}
	}

	updateMultiActions = async () => {
		const selectedItems: Array<Item> = []

		for (let key in this.state.selectedRows) {
			const row = this.state.selectedRows[key]
			const foundRow = this.props.data.find((dataItem: Item) => dataItem.id === row.id)
			selectedItems.push(foundRow || row)
		}

		const visibleMultiActions =
			selectedItems.length > 0
				? this.props.onNeedUpdateMultiActionVisiblity
					? await this.props.onNeedUpdateMultiActionVisiblity(selectedItems)
					: (this.props.multiActions || EMPTY_ARRAY).map((key: Action<Array<Item>>) => key.type)
				: EMPTY_ARRAY

		this.setState((state: State<Item>) => {
			const selectedRows = { ...state.selectedRows }

			for (let key in state.selectedRows) {
				const row = state.selectedRows[key]
				const foundRow = this.props.data.find((dataItem: Item) => dataItem.id === row.id)
				if (foundRow) {
					selectedRows[foundRow.id || ''] = foundRow
				}
			}

			return {
				selectedRows,
				visibleMultiActions,
			}
		})
	}

	handleCheckFunc = (id: string, row: Item, checked: boolean, prevState: State<Item>) => {
		if (checked) {
			const selectedRows = { ...prevState.selectedRows, [id]: row }
			return {
				selectedRows,
			}
		} else {
			const selectedRows = { ...prevState.selectedRows }
			delete selectedRows[id]
			return {
				selectedRows,
			}
		}
	}

	handleCheck = async (id: string, row: Item, checked: boolean) => {
		if (!this.props.confirmOnCheckAction) {
			this.setState((prevState: State<Item>) => {
				this.props.onSelectionChange(row, checked)
				return this.handleCheckFunc(id, row, checked, prevState)
			}, this.updateMultiActions)
		} else {
			try {
				// $FlowFixMe
				this.setState(async (prevState: State<Item>) => {
					await this.props.onSelectionChange(row, checked)
					return this.handleCheckFunc(id, row, checked, prevState)
				}, this.updateMultiActions)
				// eslint-disable-next-line no-empty
			} catch (e) {}
		}
	}

	handleMultiAction = async (action: MultiAction, items: Array<Item>, clearSelection: ?boolean): Promise<any> => {
		if (clearSelection) {
			this.setState({ selectedRows: {} })
		}
		if (MULTI_ACTION_TYPE_CLEAR_SELECTION !== action && this.props.onMultiAction) {
			const result = await this.props.onMultiAction(action, items)

			if (result === MULTI_ACTION_TYPE_CLEAR_SELECTION) {
				this.setState({ selectedRows: {} })
			}
		}
	}

	handleAction = async (action: string, item: Item) => {
		this.props
			.onAction(action, item)
			.then(() => {
				if (action === 'delete_contact' || action === 'accdoc_remove') {
					this.handleCheck(item.id, item, false)
				}
			})
			.catch(() => void 0)
	}

	handlePageChange = (page: number) => {
		const pageIndex = page - 1

		this.props.onPageChange(this.props.id, pageIndex)
		this.props.onNeedFreshData(
			this.props.dataType,
			this.props.id,
			pageIndex,
			this.props.pageSize,
			this.props.sort,
			this.props.filter,
		)
	}

	handlePageSizeChange = (pageSize: number) => {
		this.props.onPageSizeChange(this.props.id, pageSize)
		this.props.onNeedFreshData(this.props.dataType, this.props.id, 0, pageSize, this.props.sort, this.props.filter)
	}

	handleDirectionChange = (columnId: string, direction: Direction) => {
		const sort = new Sort(columnId, direction)

		if (this.directionChangeHandlers[columnId]) {
			this.directionChangeHandlers[columnId](columnId, direction)
		}
		this.props.onSortChange(this.props.id, sort)
		this.props.onNeedFreshData(
			this.props.dataType,
			this.props.id,
			this.props.pageIndex,
			this.props.pageSize,
			sort,
			this.props.filter,
		)
	}

	renderCheckbox = (value: string, row: Item) => {
		const selected = this.state.selectedRows
		const checked = !!selected[row.id]
		const style = getStyles(
			this.props.light,
			this.props.pageSize,
			this.getAreAnyRowsOnCurrentPageSelected(selected, this.props.data),
			this.props.style,
		)

		return (
			<div css={checked ? style.checkedCheckbox : style.checkbox} className={'checkbox'}>
				<DataGridCheckbox
					disabled={this.props.selectDisabled}
					selectTooltip={this.props.selectTooltip}
					deselectTooltip={this.props.deselectTooltip}
					id={row.id}
					rowData={row}
					checked={checked}
					onCheck={this.handleCheck}
				/>
			</div>
		)
	}

	handleCheckAll = (event: any, checked: boolean) => {
		const { data } = this.props
		let selectedRows = { ...this.state.selectedRows }
		if (checked) {
			const newSelectedRows = data.reduce((a: { [string]: Item }, b: Item) => {
				a[b.id] = b
				return a
			}, {})
			selectedRows = { ...selectedRows, ...newSelectedRows }
		} else {
			// $FlowFixMe
			selectedRows = Object.values(selectedRows).reduce(this.filterOutCurrentPageRowsFromSelection, {})
		}
		this.setState({ selectedRows }, () => {
			this.updateMultiActions()
			this.props.onCheckAllChange(selectedRows, checked)
		})
	}

	filterOutCurrentPageRowsFromSelection = (result: { [string]: Item }, rowItem: Item): { [string]: Item } => {
		if (this.props.data.some((item: Item) => item.id === rowItem.id)) {
			return result
		}
		result[rowItem.id] = rowItem
		return result
	}

	renderHeaderCheckbox = (styles: Object) => {
		const areAllRowsSelected = this.getAreAllRowsOnCurrentPageSelected(this.state.selectedRows, this.props.data)
		const areAnyRowsSelected = this.getAreAnyRowsOnCurrentPageSelected(this.state.selectedRows, this.props.data)

		return (
			<div css={styles.headerCheckbox}>
				<Checkbox
					checked={areAllRowsSelected || areAnyRowsSelected}
					showMinusIcon={areAnyRowsSelected && !areAllRowsSelected}
					onCheck={this.handleCheckAll}
				/>
			</div>
		)
	}

	renderMenu = (value: string, row: Item) => {
		return (
			<DataGridActions
				actions={this.props.actions || EMPTY_ARRAY}
				row={row}
				onAction={this.handleAction}
				onActionMenuOpen={this.props.onActionMenuOpen}
			/>
		)
	}

	renderLoadingText = (styles: Object) => () => {
		const loadingText = this.props.t('grid.loadingDataMessage')
		const row = (
			<div css={styles.loadingRow}>
				<span /> <span /> <span /> <span /> <span /> <span /> <span /> <span /> <span /> <span />
			</div>
		)
		return (
			<div css={styles.loading}>
				{loadingText}
				<div css={styles.loadingContainer}>
					{row}
					{row}
					{row}
					{row}
				</div>
			</div>
		)
	}

	renderEmptyText = (styles: Object) => () => {
		const emptyText = this.props.t('grid.noDataMessage')

		return (
			<div css={styles.empty}>
				<div>
					<div>{emptyText}</div>
					<img src={noItemsImage} alt={emptyText} css={styles.emptyVisual} />
				</div>
			</div>
		)
	}

	renderChildren = memoize((children: ChildrenArray<Element<typeof Column> | null | false>, sort: ?Sort) => {
		return React.Children.map(children, (child: Element<typeof Column> | false | null) => {
			if (child) {
				return (
					<OriginalColumn
						key={child.props.columnId}
						dataIndex={child.props.columnId}
						title={
							<ColumnHeader
								id={child.props.columnId}
								title={child.props.title || ''}
								sortable={child.props.sortable}
								direction={sort && child.props.columnId === sort.columnId ? sort.direction : undefined}
								onDirectionChange={this.handleDirectionChange}
								style={child.props.headerStyle}
								infoTooltip={child.props.infoTooltip}
							/>
						}
						width={child.props.width}
						fixed={child.props.fixed}
						colSpan={child.props.colSpan}
						className={child.props.className}
						render={child.props.render}
					/>
				)
			} else {
				return child
			}
		})
	})

	getRowClassName = (row: Item) => {
		if (this.props.getRowClassName)
			return this.props.getRowClassName(row, this.state.selectedRows, this.props.selectOnClick, this.props.currentRowId)

		const classNames = ['row']
		if (this.state.selectedRows[row.id] || this.props.currentRowId === row.id) classNames.push('selectedRow')
		if (this.props.selectOnClick) classNames.push('clickableRow')
		if (this.props.expandRowByClick) classNames.push('expandableRowByClick')
		return classNames.join(' ')
	}

	onRow = (row: Item) => ({
		onClick: () => {
			this.onRowClick(row)
		},
	})

	onRowClick = (row: Item) => {
		this.props.selectOnClick && this.handleCheck(row.id, row, this.state.selectedRows[row.id] ? false : true)
	}

	onExpand = (expanded: boolean, row: Item) => {
		this.setState({ expandedRowKeys: expanded ? [row.id] : [] })
		if (expanded) {
			const element = document.querySelectorAll(`[data-row-key="${row.id}"]`)[0]
			// $FlowFixMe - HTMLElement has a scrollIntoViewIfNeeded property
			element?.scrollIntoViewIfNeeded && element.scrollIntoViewIfNeeded()
		}
	}

	getSeletectRowsOnCurrentPage = memoize((selectedRows: { [string]: Item }, currentRows: Array<Item>): Array<Item> => {
		const arr = ((Object.values(selectedRows): any): Array<Item>)
		return arr.filter((item: Item) => {
			return !!find(currentRows, ['id', item.id])
		})
	})

	getAreAllRowsOnCurrentPageSelected = memoize(
		(selectedRows: { [string]: Item }, currentRows: Array<Item>): boolean => {
			const selectedRowsOnCurrentPage = this.getSeletectRowsOnCurrentPage(selectedRows, currentRows)
			return selectedRowsOnCurrentPage.length > 0 && selectedRowsOnCurrentPage.length === currentRows.length
		},
	)

	getAreAnyRowsOnCurrentPageSelected = memoize(
		(selectedRows: { [string]: Item }, currentRows: Array<Item>): boolean => {
			return this.getSeletectRowsOnCurrentPage(selectedRows, currentRows).length > 0
		},
	)

	render() {
		const styles = getStyles(
			this.props.light,
			this.props.pageSize,
			this.getAreAnyRowsOnCurrentPageSelected(this.state.selectedRows, this.props.data),
			this.props.style,
		)
		const selectedItems: Array<Item> = []
		for (let key in this.state.selectedRows) {
			selectedItems.push(this.state.selectedRows[key])
		}
		const children = this.renderChildren(this.props.children, this.props.sort)
		const expandProps = this.props.expandedRowRender
			? {
					expandedRowRender: this.props.expandedRowRender,
					expandIcon: RowExpandIcon,
					expandIconAsCell: false,
					expandIconColumnIndex: React.Children.count(children) + (this.props.actions != null ? 1 : 0),
					expandRowByClick: this.props.expandRowByClick,
					expandedRowKeys: this.state.expandedRowKeys,
					onExpand: this.onExpand,
			  }
			: {}

		const multiActionsVisible =
			this.props.multiActions && selectedItems.length > 0 && this.state.visibleMultiActions.length > 0

		return (
			<div css={styles.root}>
				{this.props.multiActions && (
					<div>
						<MultiActionsToolbar
							visible={multiActionsVisible}
							actions={this.props.multiActions}
							selectedItems={selectedItems}
							onMultiAction={this.handleMultiAction}
							visibleMultiActions={this.state.visibleMultiActions}
						/>
						{this.props.beforeTableComponent &&
							React.cloneElement(this.props.beforeTableComponent, {
								...this.props.beforeTableComponent.props,
								selectedDocuments: multiActionsVisible ? selectedItems : null,
							})}
					</div>
				)}
				<Table
					rowKey="id"
					className="container"
					rowClassName={this.getRowClassName}
					data={this.props.data}
					emptyText={this.props.loading ? this.renderLoadingText(styles) : this.renderEmptyText(styles)}
					showHeader={!this.props.hideHeader}
					onRow={this.onRow}
					{...expandProps}
				>
					{(this.props.multiActions != null || this.props.selectedRows) && (
						<OriginalColumn
							width={52}
							key="multiActions"
							title={this.props.checkAllOption && this.renderHeaderCheckbox(styles)}
							render={this.renderCheckbox}
						/>
					)}
					{children}
					{this.props.actions != null && <OriginalColumn width={50} key="actions" render={this.renderMenu} />}
					{this.props.expandedRowRender && <OriginalColumn width={50} key="expander" />}
				</Table>
				<div css={styles.pager}>
					<Pager
						activePage={this.props.pageIndex + 1}
						lastPage={Math.max(Math.ceil(this.props.itemsCount / this.props.pageSize), 1)}
						pageSize={this.props.pageSize}
						onPageChange={this.handlePageChange}
						onPageSizeChange={this.handlePageSizeChange}
						itemsCount={this.props.itemsCount}
						showPageSize={this.props.showPageSize}
						showItemsOfTotal={this.props.showItemsOfTotal}
						showPrevButton={this.props.showPrevButton}
						showNextButton={this.props.showNextButton}
						compact={this.props.compactPager}
						autoTestId="data-grid-next-pager"
					/>
				</div>
			</div>
		)
	}
}

export default withTranslate(DataGrid)
