/* @flow */

import React, { Component } from 'react'
import type { ApiApp } from 'types'
import { withTranslate, withNotify, type WithTranslateProps, type WithNotifyProps } from 'wrappers'
import DeleteDialog from 'components/delete-dialog'
import CopyToClipboard from 'components/copy-to-clipboard'
import Button from 'components/button'
import IconMenu from 'components/icon-menu'
import MenuItem from 'components/menu-item'
import TextField from 'components/TextField'
import Table from 'components/table/table'
import TableBody from 'components/table/table-body'
import TableHeader from 'components/table/table-header'
import TableHeaderCell from 'components/table/table-header-cell'
import TableRow from 'components/table/table-row'
import TableCell from 'components/table/table-cell'
import Delete from 'components/svg-icons/action/delete'
import Edit from 'components/svg-icons/image/edit'

export type Props = {
	apiApps: ?Array<ApiApp>,
	readOnly?: boolean,
	onApiAppChange: (apiAppId: number, apiApp: ApiApp) => void,
	onApiAppDelete: (apiAppId: number) => void,
}

type State = {
	apiAppNames: {
		[apiAppId: ?string]: string,
	},
	editedApiAppId: ?string,
}

type ComponentProps = { ...Props, ...WithTranslateProps, ...WithNotifyProps }

class Api extends Component<ComponentProps, State> {
	state: State = this.getStateFromProps(this.props)

	UNSAFE_componentWillReceiveProps(newProps: ComponentProps) {
		this.setState(this.getStateFromProps(newProps))
	}

	getStateFromProps(props: ComponentProps): State {
		const apiAppNames = {}
		props.apiApps && props.apiApps.forEach((app: ApiApp) => (apiAppNames[app.id != null ? app.id : ''] = app.name))
		return {
			apiAppNames,
			editedApiAppId: null,
		}
	}

	onApiAppDeleteButtonClick = (apiAppId: ?string) => {
		return () => {
			DeleteDialog()
				.then(() => {
					if (apiAppId != null) {
						// $FlowFixMe
						this.props.onApiAppDelete(parseInt(apiAppId))
					}
				})
				.catch(e => {}) //eslint-disable-line
		}
	}

	onApiAppEditButtonClick = (editedApiAppId: ?string) => {
		return () => {
			this.setState({ editedApiAppId })
		}
	}

	onApiAppNameChange = (id: ?string) => {
		return (event: SyntheticInputEvent<HTMLInputElement>, name: ?string) => {
			if (name !== null && name !== undefined) {
				const apiAppNames = this.state.apiAppNames
				apiAppNames[id] = name
				this.setState({ apiAppNames })
			}
		}
	}

	onApiAppNameBlur = (id: ?string) => {
		return () => {
			this.setState({ editedApiAppId: null }, () => {
				if (id != null) {
					// $FlowFixMe
					this.props.onApiAppChange(parseInt(id), { name: this.state.apiAppNames[id] })
				}
			})
		}
	}

	onDoneClick = () => {
		this.setState({ editedApiAppId: null })
	}

	renderActionButtons(apiApp: ApiApp) {
		if (apiApp.id !== this.state.editedApiAppId) {
			return (
				<IconMenu autoTestId="settings-api-list-actions" context>
					<MenuItem
						primaryText={this.props.t('settings.forms.editButton')}
						onClick={this.onApiAppEditButtonClick(apiApp.id)}
						icon={<Edit />}
					/>
					<MenuItem
						primaryText={this.props.t('settings.forms.removeButton')}
						onClick={this.onApiAppDeleteButtonClick(apiApp.id)}
						icon={<Delete />}
					/>
				</IconMenu>
			)
		}

		return <Button autoTestId="settings-api-done" transparent labelText={'DONE'} onClick={this.onDoneClick} />
	}

	render() {
		if (!this.props.apiApps || !this.props.apiApps.length) return this.props.t('application.noData')
		return (
			<div>
				<Table>
					<TableHeader>
						<TableRow>
							<TableHeaderCell>Název</TableHeaderCell>
							<TableHeaderCell>App ID</TableHeaderCell>
							<TableHeaderCell>APP Secret</TableHeaderCell>
							<TableHeaderCell width={35} />
						</TableRow>
					</TableHeader>
					<TableBody>
						{this.props.apiApps
							? this.props.apiApps.map((apiApp: ApiApp, index: number) => {
									return (
										<TableRow key={'apiApp' + index}>
											<TableCell>
												{this.state.editedApiAppId !== apiApp.id ? (
													apiApp.name
												) : (
													<TextField
														compact
														onChange={this.onApiAppNameChange(apiApp.id)}
														onBlur={this.onApiAppNameBlur(apiApp.id)}
														value={this.state.apiAppNames[apiApp.id]}
														autoTestId="settings-api-name"
														name="settings-api-list-name"
													/>
												)}
											</TableCell>
											<TableCell>
												<CopyToClipboard textToCopy={apiApp.appId}>
													<span style={style.copy}>{apiApp.appId}</span>
												</CopyToClipboard>
											</TableCell>
											<TableCell>
												<CopyToClipboard textToCopy={apiApp.appSecret}>
													<span style={style.copy}>{apiApp.appSecret}</span>
												</CopyToClipboard>
											</TableCell>
											<TableCell>{!this.props.readOnly ? this.renderActionButtons(apiApp) : null}</TableCell>
										</TableRow>
									)
							  })
							: null}
					</TableBody>
				</Table>
			</div>
		)
	}
}

const style = {
	copy: {
		textDecoration: 'underline',
	},
}

export default withNotify(withTranslate(Api))
