/* @flow */
/** @jsx jsx */

import { Component } from 'react'
import { jsx } from '@emotion/core'
import { EMPTY_ARRAY } from 'trivi-constants'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import DocumentTreeView from '../containers/document-tree-view'
import PopupSection from 'components/PopupSection'
import FormBox from 'components/form-box'
import Button from 'components/button'

type Props = {|
	open: boolean,
	amount: number,
	onSelect: (documentId: string) => any,
	onClose: () => void,
	hideFolderSystemTrivi?: boolean,
	...WithTranslateProps,
|}

type State = {|
	selected: ?string,
|}

class DocumentSelector extends Component<Props, State> {
	state = { selected: null }

	renderActionButton() {
		return (
			<Button
				labelText={this.props.t('document.moveButton')}
				disabled={!this.state.selected}
				onClick={this.handleSelect}
				autoTestId="document-move-button"
			/>
		)
	}

	renderHeader() {
		return <div css={styles.name}>{this.props.t('document.moveDocumentsTo', { count: this.props.amount })}</div>
	}

	render() {
		return (
			<PopupSection open={this.props.open} onRequestClose={this.props.onClose}>
				<FormBox
					header={this.renderHeader()}
					actions={this.renderActionButton()}
					headline={this.props.t('document.moveHeader')}
				>
					<DocumentTreeView
						selected={this.state.selected ? [this.state.selected] : EMPTY_ARRAY}
						onDocumentClick={this.handleDocumentClick}
						hideFolderSystemTrivi={this.props.hideFolderSystemTrivi}
					/>
				</FormBox>
			</PopupSection>
		)
	}

	handleDocumentClick = (uniqueId: ?string, isFolder: boolean) => {
		if (this.state.selected === uniqueId || !isFolder) {
			this.setState({ selected: null })
		} else {
			this.setState({ selected: uniqueId })
		}
	}

	handleSelect = () => {
		if (this.state.selected) {
			this.props.onSelect(this.state.selected)
		}
	}
}

const styles = {
	name: {
		fontSize: 24,
	},
}

export default withTranslate(DocumentSelector)
