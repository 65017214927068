// @flow

import type { Series } from 'components/charts/highcharts/types'
import { pickDefaultOptions } from 'components/charts/utils'
import React from 'react'
import { colors } from 'variables'
import type { BaseChartProps } from './highcharts/highchart'
import Highchart from './highcharts/highchart'
import type { DataNumbers, Options } from './highcharts/types'
import { pure } from 'recompose'

export type Props = {|
	...ColumnChartProps,
	...BaseChartProps,
|}

type ColumnChartProps = {|
	data: Data,
	categories: Categories,
	columnLabels?: boolean,
	columnStacking?: 'normal' | 'percent',
	showLegend?: boolean,
	configureEmptyData?: (props: Props) => Props,

	// Documentation: https://www.highcharts.com/docs/chart-concepts/labels-and-string-formatting
	tooltipValueFormat?: string,
	tooltipFormatter?: () => string,
	yAxisValueFormat?: string,
	yAxisPadding?: number,
|}

export type DataItem = {|
	values: DataNumbers,
	color?: string,
	label: string,
	index?: number,
	id: string,
|}

export type Data = {|
	columns: Array<DataItem>,
	splines?: Array<DataItem>,
|}

export type Categories = Array<string>

function ColumnChart(props: Props) {
	const emptyData = isEmptyData(props.data.columns) && isEmptyData(props.data.splines || [])
	const configuredProps = emptyData && props.configureEmptyData ? props.configureEmptyData(props) : props

	return <Highchart options={convertOptions(configuredProps)} {...pickDefaultOptions(configuredProps)} />
}

export default pure(ColumnChart)

ColumnChart.defaultProps = {
	showLegend: true,
	columnLabels: true,
	yAxisPadding: 0.1,
}

export function convertOptions(props: Props): Options {
	return {
		chart: { type: 'column' },
		plotOptions: {
			column: {
				stacking: props.columnStacking,
				dataLabels: {
					enabled: !!props.columnLabels,
				},
			},
			series: {
				borderWidth: 0,
				marker: { symbol: 'circle' },
			},
		},
		legend: { enabled: props.showLegend },
		series: [...convertSeries(props.data.columns), ...convertSplines(props.data.splines || [])],
		xAxis: {
			categories: props.categories,
		},
		yAxis: {
			labels: {
				format: props.yAxisValueFormat || '{value}',
			},
			title: {
				text: null,
			},
			minPadding: props.yAxisPadding,
			maxPadding: props.yAxisPadding,
		},
		tooltip: {
			shared: true,
			useHTML: true,
			borderColor: colors.grey300,
			borderRadius: 10,
			borderWidth: 1,
			shadow: false,
			formatter: props.tooltipFormatter,
		},
	}
}

export function convertSeries(data: Array<DataItem>): Series {
	return data.map((item: DataItem) => ({
		name: item.label,
		data: item.values,
		index: item.index,
		color: item.color,
		id: item.id,
	}))
}

export function convertSplines(data: Array<DataItem>): Series {
	return data.map((item: DataItem) => ({
		type: 'spline',
		name: item.label,
		data: item.values,
		color: item.color,
		index: item.index,
		id: item.id,
		marker: {
			lineWidth: 2,
			lineColor: item.color,
			fillColor: colors.white,
		},
	}))
}

export function isEmptyData(data: Array<DataItem>): boolean {
	return !data.some((item: DataItem) => {
		return item.values.some((value: number) => value !== 0)
	})
}
