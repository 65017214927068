/* @flow */
/** @jsx jsx */

import { PureComponent } from 'react'
import { jsx } from '@emotion/core'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import IconTextButton from 'components/icon-text-button'
import Person from 'components/svg-icons/social/person'
import Edit from 'components/svg-icons/image/edit'

type Props = {|
	isSupplier?: boolean,
	canChangeContact?: boolean,
	canEditContact?: boolean,
	onChange: () => void,
	onEdit: () => void,
	...WithTranslateProps,
|}

class ContactActions extends PureComponent<Props> {
	render() {
		const { t, canChangeContact, canEditContact } = this.props

		return (
			<div css={styles.root}>
				{canChangeContact && (
					<span css={styles.change}>
						<IconTextButton
							icon={<Person />}
							labelText={
								this.props.isSupplier ? t('invoice.contact.changeSupplier') : t('invoice.contact.changeCustomer')
							}
							onClick={this.props.onChange}
						/>
					</span>
				)}
				{canEditContact && (
					<IconTextButton
						icon={<Edit />}
						labelText={this.props.isSupplier ? t('invoice.contact.editSupplier') : t('invoice.contact.editCustomer')}
						onClick={this.props.onEdit}
					/>
				)}
			</div>
		)
	}
}

const styles = {
	root: {
		marginTop: 15,
		paddingLeft: 15,
	},
	change: {
		marginRight: 14,
	},
}

export default withTranslate(ContactActions)
