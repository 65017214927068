/* @flow */

import type { Dispatch, OrganizationSettingsTaxPaymentHistory, State } from 'types'
import { taxPaymentHistory as taxPaymentHistoryApi } from 'modules/common/models/api-model'
import type { CommonAction as Action } from 'modules/settings/actions/action-types'
import { canLoadTaxPaymentHistory } from 'permissions'

export * from './signature-actions'
export * from './api-apps-actions'
export * from './basics-actions'
export * from './email-actions'
export * from './reporting-actions'
export * from './accounting-documents-actions'
export * from './payment-reminders-actions'
export * from './accounting-document-categories-actions'
export * from './cashbot'
export * from './oss-actions'

export function loadTaxPaymentHistory() {
	return async (dispatch: Dispatch<Action>, getState: () => State) => {
		if (!canLoadTaxPaymentHistory(getState())) {
			return
		}

		dispatch({
			type: 'START_LOADING_TAX_PAYMENT_HISTORY',
		})
		try {
			const taxPaymentHistory: Array<OrganizationSettingsTaxPaymentHistory> = await taxPaymentHistoryApi.get({})
			dispatch({
				type: 'FINISH_LOADING_TAX_PAYMENT_HISTORY',
				taxPaymentHistory,
			})
		} catch (serverError) {
			dispatch({
				serverError,
				type: 'FINISH_LOADING_TAX_PAYMENT_HISTORY',
			})
		}
	}
}
