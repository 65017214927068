/* @flow */

import React, { Component } from 'react'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import type { Organization } from 'types'
import DocumentFields from '../containers/document-fields'
import DocumentPreview from '../containers/document-preview'
import { getRelatedFieldCursors } from '../domain/field'
import { getRelatedBbWordCursors } from '../domain/bbword'
import type { BbWordCursor, BbWordFieldMap, ExtractionDocument, FieldBbWordMap, FieldCursor } from '../types'
import { Button, Paper } from 'components'
import styles from './document-extraction.css'

type Props = {|
	extractionDocument: ?ExtractionDocument,
	documentId: string,
	fieldBbWordMap: FieldBbWordMap,
	bbWordFieldMap: BbWordFieldMap,
	organization: ?Organization,
	onSave: (exit?: boolean) => void,
	nextDocumentExtraction: () => void,
	working: boolean,
|}

type State = {|
	highlightFields: ?Array<FieldCursor>,
	highlightBbWords: ?Array<BbWordCursor>,
|}

class DocumentExtraction extends Component<Props & WithTranslateProps, State> {
	constructor(props: Props & WithTranslateProps) {
		super(props)

		this.state = {
			highlightFields: null,
			highlightBbWords: null,
		}
	}

	onFieldMouseOver() {
		return (fieldCursor: FieldCursor) =>
			this.setState({ highlightBbWords: getRelatedBbWordCursors(this.props.fieldBbWordMap, fieldCursor) })
	}

	onFieldMouseOut() {
		return () => this.setState({ highlightBbWords: null })
	}

	onBbWordMouseOver() {
		return (bbWordCursor: BbWordCursor) =>
			this.setState({ highlightFields: getRelatedFieldCursors(this.props.bbWordFieldMap, bbWordCursor) })
	}

	onBbWordMouseOut() {
		return () => this.setState({ highlightFields: null })
	}

	save = () => {
		this.props.onSave()
	}

	saveAndExit = () => {
		this.props.onSave(true)
	}

	render() {
		const { organization, extractionDocument, t, working } = this.props
		const success = extractionDocument && extractionDocument.metadata && extractionDocument.metadata.success
		return (
			<div className={styles.root}>
				<div>
					<h1>
						{t('documentExtraction.headline')}
						<span className={styles.organizationName}>{organization && organization.name}</span>
						<span className={styles.organizationRegNo}>{organization && organization.companyRegNo}</span>
					</h1>
				</div>
				{extractionDocument && this.renderExtraction(extractionDocument)}
				<div className={styles.button}>
					{success && <Button disabled={working} labelText={t('documentExtraction.save')} onClick={this.save} />}{' '}
					{success && (
						<Button disabled={working} labelText={t('documentExtraction.saveAndExit')} onClick={this.saveAndExit} />
					)}
					{!success && (
						<Button
							disabled={working}
							labelText={t('documentExtraction.next')}
							onClick={this.props.nextDocumentExtraction}
						/>
					)}
				</div>
			</div>
		)
	}

	renderExtraction(extractionDocument: ExtractionDocument) {
		return (
			<div className={styles.extraction}>
				<DocumentPreview
					extractionDocument={extractionDocument}
					documentId={this.props.documentId}
					highlightBbWords={this.state.highlightBbWords}
					onBbWordMouseOver={this.onBbWordMouseOver()}
					onBbWordMouseOut={this.onBbWordMouseOut()}
				/>
				<Paper className={styles.fields}>
					<DocumentFields
						extractionDocument={extractionDocument}
						onFieldMouseOver={this.onFieldMouseOver()}
						onFieldMouseOut={this.onFieldMouseOut()}
						highlightFields={this.state.highlightFields}
					/>
				</Paper>
			</div>
		)
	}
}

export default withTranslate(DocumentExtraction)
