/* @flow */

import React, { Component } from 'react'
import { TabSwitch, TabSwitches } from 'components'

import { withTranslate, type WithTranslateProps } from 'wrappers'

export type Props = {
	onChange: (type: number) => void,
	disabled?: boolean,
	value: ?number,
	labelStyle?: {},
}

type AccountingTypeValue = {|
	value: number,
	translation: string,
|}

const VALUES: Array<AccountingTypeValue> = [
	{ value: 0, translation: 'settings.accountingSettings.doubleEntry' },
	{ value: 1, translation: 'settings.accountingSettings.taxEvidence' },
]

class AccountingTypeSelector extends Component<Props & WithTranslateProps> {
	static defaultProps = {
		onChange: () => {},
	}

	handleChange = (ev: any, index: number, val: number) => {
		this.props.onChange(val)
	}

	render() {
		const { t, onChange, value, labelStyle, disabled, ...rest } = this.props //eslint-disable-line

		return (
			<TabSwitches
				{...rest}
				label={t('settings.accountingSettings.accountingType')}
				labelStyle={labelStyle}
				onChange={onChange}
				value={value}
				disabled={disabled}
				fullWidth
				autoTestId="accounting-type-selector-tabs"
			>
				{VALUES.map((item: AccountingTypeValue) => (
					<TabSwitch key={'accountingType' + item.value} label={t(item.translation)} value={item.value} />
				))}
			</TabSwitches>
		)
	}
}

export default withTranslate(AccountingTypeSelector)
