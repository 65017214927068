/* @flow */

import type { DocumentItem, DocumentDescriptor } from 'types'
import type { Action } from '../actions/action-types'

type DocumentsState = {
	loading: boolean,
	data: Array<DocumentItem>,
}

export type DocumentState = {
	data: DocumentItem,
	parentDocumentInfo: ?DocumentDescriptor,
	currentSortingDirection: 'ASC' | 'DESC',
	updating: boolean,
	removing: boolean,
}

export type DocumentListState = {
	id: string,
	children: Array<string>,
	count: number,
	currentPage: number,
	currentPageSize: number,
	loading: boolean,
}

export type State = {
	documents: { [string]: DocumentState },
	documentLists: { [string]: DocumentListState },
	currentSearch: string,
	uploadedDocuments: DocumentsState,
}

export const initialState: State = {
	documents: {},
	documentLists: {},
	currentSearch: '',
	uploadedDocuments: {
		loading: false,
		data: [],
	},
}

export default (state: State = initialState, action: Action): State => {
	switch (action.type) {
		case 'PRELOAD_DOCUMENT_FOLDER': {
			return {
				...state,
				documents: {
					...state.documents,
					[action.folderId]: {
						...state.documents[action.folderId],
						parentDocumentInfo: {
							uniqueId: action.currentId,
							name: state.documents[action.currentId].data.name,
							parentDocumentInfo: state.documents[action.currentId].parentDocumentInfo,
						},
					},
				},
			}
		}

		case 'START_LOAD_DOCUMENTS': {
			const documentListId = getDocumentsListsId(action.parentId, action.searchText)
			const oldDocumentList = state.documentLists[documentListId] || {
				id: documentListId,
				children: [],
				count: 0,
			}
			const documentList: DocumentListState = {
				...oldDocumentList,
				loading: true,
				currentPage: action.page,
				currentPageSize: action.pageSize,
			}
			return {
				...state,
				documentLists: { ...state.documentLists, [documentListId]: documentList },
			}
		}

		case 'FINISH_LOAD_DOCUMENTS': {
			const documentListId = getDocumentsListsId(action.parentId, action.searchText)
			const oldDocumentList = state.documentLists[documentListId]
			let documentList: DocumentListState = {
				...oldDocumentList,
				loading: false,
			}
			let documents = state.documents
			const actionDocuments = action.documents

			if (actionDocuments) {
				const updatedDocuments = actionDocuments.map((item: DocumentItem) => {
					return getUpdatedDocument(state, item.uniqueId, { data: item })
				})

				if (action.currentDocumentItem) {
					updatedDocuments.push(
						getUpdatedDocument(state, action.parentId, {
							data: action.currentDocumentItem,
							parentDocumentInfo: action.parentDocumentInfo,
						}),
					)
				}

				documents = Object.assign({}, state.documents, ...updatedDocuments)
				const children = documentList.children || []
				const indexBase = (action.page - 1) * action.pageSize
				actionDocuments.forEach((item: DocumentItem, i: number) => (children[indexBase + i] = item.uniqueId || ''))
				documentList = {
					...documentList,
					children,
					count: (action.pagesCount || 0) * (action.pageSize || 0),
				}
			}
			return {
				...state,
				documentLists: { ...state.documentLists, [documentListId]: documentList },
				documents,
			}
		}

		case 'START_UPDATE_DOCUMENT_ITEM': {
			const editedDocument = getUpdatedDocument(state, action.id, {
				data: action.newDocument,
				updating: true,
			})
			return {
				...state,
				documents: { ...state.documents, ...editedDocument },
			}
		}

		case 'FINISH_UPDATE_DOCUMENT_ITEM': {
			const editedDocument = getUpdatedDocument(state, action.id, {
				data: action.newDocument ? action.newDocument : action.oldDocument,
				updating: false,
			})
			return {
				...state,
				documents: { ...state.documents, ...editedDocument },
			}
		}

		case 'START_DELETE_DOCUMENT_ITEM': {
			const editedDocument = getUpdatedDocument(state, action.id, {
				removing: true,
			})
			return {
				...state,
				documents: { ...state.documents, ...editedDocument },
			}
		}

		case 'FINISH_DELETE_DOCUMENT_ITEM': {
			if (action.success) {
				const documents = { ...state.documents }
				delete documents[getDocumentsId(action.id)]
				const documentLists = { ...state.documentLists }

				for (const id in documentLists) {
					const children = documentLists[id].children
					const index = children.indexOf(action.id)
					if (index !== -1) {
						documentLists[id].children = [...children]
						documentLists[id].children.splice(index, 1)
					}
				}
				return {
					...state,
					documents,
					documentLists,
				}
			} else {
				const editedDocument = getUpdatedDocument(state, action.id, {
					removing: false,
				})
				return {
					...state,
					documents: { ...state.documents, ...editedDocument },
				}
			}
		}

		case 'MOVE_DOCUMENT': {
			const documentLists = { ...state.documentLists }

			if (action.documentIds) {
				action.documentIds.forEach((documentId: string) => {
					for (const id in documentLists) {
						const children = documentLists[id].children
						const index = children.indexOf(documentId)
						if (index !== -1) {
							documentLists[id].children = [...children]
							documentLists[id].children.splice(index, 1)
						}
					}
				})
			}

			return {
				...state,
				documentLists,
			}
		}

		case 'START_UPLOAD_DOCUMENT_FILES': {
			const uploadedDocuments = {
				loading: true,
				data: [],
			}
			return {
				...state,
				uploadedDocuments: uploadedDocuments,
			}
		}

		case 'FINISH_UPLOAD_DOCUMENT_FILES': {
			const uploadedDocuments = {
				loading: false,
				data: action.uploadedDocuments,
			}
			return {
				...state,
				uploadedDocuments: uploadedDocuments,
			}
		}

		case 'CHANGE_DOCUMENT_ITEM_SORT': {
			const editedDocument = getUpdatedDocument(state, action.id, {
				currentSortingDirection: action.sortingDirection,
			})
			return {
				...state,
				documents: { ...state.documents, ...editedDocument },
			}
		}

		case 'CHANGE_DOCUMENT_SEARCH': {
			return {
				...state,
				currentSearch: action.searchText,
			}
		}

		default:
			return state
	}
}

export function getDocumentsListsId(id: ?string, search: ?string): string {
	return id || 'root' + (search ? '|' + search : '')
}

export function getDocumentsId(id: ?string): string {
	return id || 'root'
}

function getUpdatedDocument(
	state: State,
	id: ?string,
	newState?: ?{|
		data?: ?DocumentItem,
		parentDocumentInfo?: ?DocumentDescriptor,
		currentSortingDirection?: 'ASC' | 'DESC',
		updating?: ?boolean,
		removing?: ?boolean,
	|},
): { [string]: DocumentItem } {
	const documentId = getDocumentsId(id)
	const oldDocument = state.documents[documentId]
	const document = {
		data: (newState && newState.data) || (oldDocument && oldDocument.data) || null,
		parentDocumentInfo:
			(newState && newState.parentDocumentInfo) || (oldDocument && oldDocument.parentDocumentInfo) || null,
		currentSortingDirection:
			(newState && newState.currentSortingDirection) || (oldDocument && oldDocument.currentSortingDirection) || 'ASC',
		updating: (newState && newState.updating) || (oldDocument && oldDocument.updating) || false,
		removing: (newState && newState.removing) || (oldDocument && oldDocument.removing) || false,
	}
	return { [documentId]: document }
}
