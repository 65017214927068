/* @flow */

import { API_NOT_FOUND_CODE, EMPTY_ARRAY } from 'trivi-constants'
import type {
	Bank,
	BankAccount,
	BankAccountStatementOptions,
	BankAccountStatementOptionsFormatType,
	BankAction,
	BankPaymentOrderType,
	BankStatementType,
	Dispatch,
	Filter,
	State,
	Statement,
	StatementPostBody,
	StatementSearchResult,
	StatementsView,
	ValidationError,
} from 'types'
import {
	bankAccount as bankAccountApi,
	bankAccountPaymentOrder as bankAccountPaymentOrderApi,
	bankAccounts as bankAccountsApi,
	banks as banksApi,
	bankPaymentOrderTypes as paymentOrderTypesApi,
	bankAccountPaymentOrdersSearch as paymentOrdersSearchApi,
	bankAccountStatement as statementApi,
	bankAccountStatementOptions as statementOptionsApi,
	bankAccountStatementOptionsFormat as statementOptionsApiFormat,
	bankAccountStatementProcessing as statementProcessingApi,
	bankStatementTypes as statementTypesApi,
	bankAccountStatements as statementsApi,
	bankAccountStatementsSearch as statementsSearchApi,
	bankAccountStatementState as statemetStateApi,
} from '../../common/models/api-model'
import { beginTask, endTask } from 'utils/loader'

import { STATEMENTS_VIEW_KEY } from '../constants'
import { getBankAccountStatementOptions } from '../selectors'
import { notFoundRoute } from 'modules/navigation/routing/routes'
import { push } from 'react-router-redux'
import { sortBanks } from '../domain'
import storage from 'utils/local-storage'

let loadBankAccountsDispatched = {}

export function loadBankAccounts(page?: number, pageSize?: number) {
	return async (dispatch: Dispatch<BankAction>) => {
		const lockKey = `P${page || 0}S${pageSize || 0}`
		if (loadBankAccountsDispatched[lockKey] != null) {
			return
		}
		loadBankAccountsDispatched[lockKey] = true

		dispatch({
			type: 'START_LOAD_BANK_ACCOUNTS',
		})
		const params = {}

		if (page) {
			params.page = page
		}

		if (pageSize) {
			params.pageSize = pageSize
		}
		try {
			const result = await bankAccountsApi.get(params)
			const bankAccounts = (Array.isArray(result.bankAccounts) && result.bankAccounts) || []

			dispatch({
				type: 'FINISH_LOAD_BANK_ACCOUNTS',
				bankAccounts,
			})
		} catch (error) {
			dispatch({
				type: 'FINISH_LOAD_BANK_ACCOUNTS',
				bankAccounts: [],
				serverError: error,
			})
		} finally {
			delete loadBankAccountsDispatched[lockKey]
		}
	}
}

export function loadBankAccount(id: string) {
	return async (dispatch: Dispatch<BankAction>) => {
		dispatch({
			type: 'START_LOAD_BANK_ACCOUNT',
		})
		try {
			const result: BankAccount = await bankAccountApi.get({
				bankAccountId: id,
			})
			dispatch({
				type: 'FINISH_LOAD_BANK_ACCOUNT',
				bankAccount: result,
			})
			result &&
				result.bankId &&
				dispatch(loadStatementTypes(result.bankId)) &&
				dispatch(loadPaymentOrderTypes(result.bankId))
		} catch (error) {
			await dispatch({
				type: 'FINISH_LOAD_BANK_ACCOUNT',
				bankAccount: null,
				serverError: error,
			})
			if (error.code === API_NOT_FOUND_CODE) {
				dispatch(push(notFoundRoute()))
			}
		}
	}
}

export function createBankAccount(bankAccount: BankAccount) {
	return async (dispatch: Dispatch<BankAction>) => {
		dispatch({
			type: 'START_CREATE_BANK_ACCOUNT',
		})
		try {
			const result: BankAccount = await bankAccountsApi.post(bankAccount)
			return dispatch({
				type: 'FINISH_CREATE_BANK_ACCOUNT',
				bankAccount: result,
			})
		} catch (error) {
			return dispatch({
				type: 'FINISH_CREATE_BANK_ACCOUNT',
				bankAccount: null,
				serverError: error,
			})
		}
	}
}

export function updateBankAccount(id: string, bankAccount: BankAccount) {
	return async (dispatch: Dispatch<BankAction>) => {
		dispatch({
			type: 'START_UPDATE_BANK_ACCOUNT',
		})
		try {
			const result: BankAccount = await bankAccountApi.put({ bankAccountId: id }, bankAccount)
			return dispatch({
				type: 'FINISH_UPDATE_BANK_ACCOUNT',
				bankAccount: result,
			})
		} catch (error) {
			return dispatch({
				type: 'FINISH_UPDATE_BANK_ACCOUNT',
				bankAccount: null,
				serverError: error,
			})
		}
	}
}

export function loadPaymentOrders(bankAccountId: string, filter?: Filter) {
	return async (dispatch: Dispatch<BankAction>) => {
		dispatch({
			type: 'START_LOAD_PAYMENT_ORDERS',
			bankAccountId: bankAccountId,
		})
		try {
			const result = await paymentOrdersSearchApi.post({
				filters: [
					...(filter || []),
					{
						field: 'bankAccountId',
						value: bankAccountId,
					},
				],
			})
			dispatch({
				type: 'FINISH_LOAD_PAYMENT_ORDERS',
				paymentOrders: (result && result.paymentOrders) || [],
				bankAccountId: bankAccountId,
			})
		} catch (error) {
			dispatch({
				type: 'FINISH_LOAD_PAYMENT_ORDERS',
				paymentOrders: [],
				bankAccountId: bankAccountId,
				serverError: error,
			})
		}
	}
}

export function loadStatements(bankAccountId: string, filter?: Filter) {
	return async (dispatch: Dispatch<BankAction>) => {
		return loadStatementsInternal(dispatch, bankAccountId, filter)
	}
}

async function loadStatementsInternal(dispatch: Dispatch<BankAction>, bankAccountId: string, filter?: Filter) {
	dispatch({
		type: 'START_LOAD_STATEMENTS',
		bankAccountId: bankAccountId,
	})
	try {
		const result: StatementSearchResult = await statementsSearchApi.post({
			filters: [
				...(filter || []),
				{
					field: 'bankAccountId',
					value: bankAccountId,
				},
			],
		})
		dispatch({
			type: 'FINISH_LOAD_STATEMENTS',
			statements: (result && result.statements) || [],
			bankAccountId: bankAccountId,
		})
	} catch (error) {
		dispatch({
			type: 'FINISH_LOAD_STATEMENTS',
			statements: [],
			bankAccountId: bankAccountId,
			serverError: error,
		})
	}
}

export function deletePaymentOrder(bankAccountId: string, paymentOrderId: string) {
	return async (dispatch: Dispatch<BankAction>) => {
		try {
			dispatch({ type: 'START_DELETE_PAYMENT_ORDER' })

			await bankAccountPaymentOrderApi.delete({ bankAccountId, paymentOrderId })

			dispatch({ type: 'FINISH_DELETE_PAYMENT_ORDER' })
		} catch (error) {
			dispatch({
				type: 'FINISH_DELETE_PAYMENT_ORDER',
				serverError: error,
			})
		}
	}
}

export function uploadStatement(
	bankAccountId: string,
	statements: Array<{|
		file: File,
		statement: Statement,
	|}>,
) {
	return async (dispatch: Dispatch<BankAction>) => {
		dispatch({
			type: 'START_UPLOAD_STATEMENTS',
			bankAccountId: bankAccountId,
		})

		const promise = Promise.all(
			statements.map(async (item: {| file: File, statement: Statement |}) => {
				const payload: StatementPostBody = {
					file: item.file,
					name: item.file.name,
					// state: item.statement.state,
				}

				const result = await statementsApi.post(
					{
						bankAccountId: bankAccountId,
					},
					payload,
				)
				return result
			}),
		)

		promise
			.then((items: Array<Statement>) => {
				dispatch({
					type: 'FINISH_UPLOAD_STATEMENTS',
					statements: items || [],
					bankAccountId: bankAccountId,
				})
			})
			.catch((error: ValidationError) => {
				dispatch({
					type: 'FINISH_UPLOAD_STATEMENTS',
					statements: [],
					bankAccountId: bankAccountId,
					serverError: error,
				})
			})

		await promise

		return loadStatementsInternal(dispatch, bankAccountId)
	}
}

export function renameStatement(statementId: string, bankAccountId: string, newStatementName: string) {
	return async (dispatch: Dispatch<BankAction>) => {
		dispatch({
			type: 'START_RENAME_STATEMENT',
			statementId: statementId,
			newStatementName: newStatementName,
			bankAccountId: bankAccountId,
		})
		try {
			await statementApi.put(
				{
					statementId: statementId,
					bankAccountId: bankAccountId,
				},
				{ newStatementName: newStatementName },
			)
			dispatch({
				type: 'FINISH_RENAME_STATEMENT',
				statementId: statementId,
				newStatementName: newStatementName,
				bankAccountId: bankAccountId,
			})

			loadStatementsInternal(dispatch, bankAccountId)
		} catch (error) {
			dispatch({
				type: 'FINISH_RENAME_STATEMENT',
				statementId: statementId,
				newStatementName: newStatementName,
				bankAccountId: bankAccountId,
				serverError: error,
			})
		}
	}
}

export function updateProccesStateStatement(statementId: string, bankAccountId: string, processState: string) {
	return async (dispatch: Dispatch<BankAction>) => {
		dispatch({
			type: 'START_PROCESS_STATE_STATEMENT',
			statementId: statementId,
			processState: processState,
			bankAccountId: bankAccountId,
		})
		try {
			await statemetStateApi.post({
				statementId: statementId,
				bankAccountId: bankAccountId,
				processState: processState,
			})
			dispatch({
				type: 'FINISH_PROCESS_STATE_STATEMENT',
				statementId: statementId,
				processState: processState,
				bankAccountId: bankAccountId,
			})

			loadStatementsInternal(dispatch, bankAccountId)
		} catch (error) {
			dispatch({
				type: 'FINISH_PROCESS_STATE_STATEMENT',
				statementId: statementId,
				processState: processState,
				bankAccountId: bankAccountId,
				serverError: error,
			})
		}
	}
}

export function deleteStatement(id: string, bankAccountId: string) {
	return async (dispatch: Dispatch<BankAction>) => {
		dispatch({
			type: 'START_DELETE_STATEMENT',
			bankAccountId: bankAccountId,
		})
		try {
			await statementApi.delete({
				statementId: id,
				bankAccountId: bankAccountId,
			})
			dispatch({
				type: 'FINISH_DELETE_STATEMENT',
				statement: null,
				bankAccountId: bankAccountId,
			})

			loadStatementsInternal(dispatch, bankAccountId)
		} catch (error) {
			dispatch({
				type: 'FINISH_DELETE_STATEMENT',
				statement: null,
				bankAccountId: bankAccountId,
				serverError: error,
			})
		}
	}
}

export function processStatement(id: string, bankAccountId: string) {
	return async (dispatch: Dispatch<BankAction>) => {
		dispatch({
			type: 'START_PROCESS_STATEMENT',
			statementId: id,
			bankAccountId: bankAccountId,
		})
		try {
			await statementProcessingApi.post({
				statementId: id,
				bankAccountId: bankAccountId,
			})
			dispatch({
				type: 'FINISH_PROCESS_STATEMENT',
				statementId: id,
				bankAccountId: bankAccountId,
			})
		} catch (error) {
			dispatch({
				type: 'FINISH_PROCESS_STATEMENT',
				statementId: id,
				bankAccountId: bankAccountId,
				serverError: error,
			})
		}
	}
}

export function loadBanks() {
	return async (dispatch: Dispatch<BankAction>) => {
		dispatch({
			type: 'START_LOAD_BANKS',
		})
		try {
			const result: Array<Bank> = await banksApi.get({})
			dispatch({
				type: 'FINISH_LOAD_BANKS',
				banks: sortBanks(result),
			})
		} catch (error) {
			dispatch({
				type: 'FINISH_LOAD_BANKS',
				banks: EMPTY_ARRAY,
				serverError: error,
			})
		}
	}
}

export function loadStatementTypes(bankId: string) {
	return async (dispatch: Dispatch<BankAction>) => {
		dispatch({
			type: 'START_LOAD_BANK_STATEMENT_TYPES',
			bankId: bankId,
		})
		try {
			const result: Array<BankStatementType> = await statementTypesApi.get({
				bankId: bankId,
			})
			dispatch({
				type: 'FINISH_LOAD_BANK_STATEMENT_TYPES',
				statementTypes: result || EMPTY_ARRAY,
				bankId: bankId,
			})
		} catch (error) {
			dispatch({
				type: 'FINISH_LOAD_BANK_STATEMENT_TYPES',
				statementTypes: EMPTY_ARRAY,
				bankId: bankId,
				serverError: error,
			})
		}
	}
}

export function loadStatementOptions(bankAccountId: string) {
	return async (dispatch: Dispatch<BankAction>, getState: () => State) => {
		const loaderId = 'statement_options'
		beginTask(loaderId)
		dispatch({
			type: 'START_LOAD_BANK_STATEMENT_OPTIONS',
			bankAccountId,
		})
		try {
			const state: State = getState()
			let response = null
			if (state.bank.statementOptions.format) {
				response = await statementOptionsApiFormat.get({
					bankAccountId,
					format: state.bank.statementOptions.format,
				})
			} else {
				response = await statementOptionsApi.get({
					bankAccountId,
				})
			}
			dispatch({
				type: 'FINISH_LOAD_BANK_STATEMENT_OPTIONS',
				bankAccountId,
				statementOptions: {
					isAutomaticDownloadEnabled: !!response.isAutomaticDownloadEnabled,
					isDownloadTokenSet: !!response.isDownloadTokenSet,
					nextDownloadAt: response.nextDownloadAt,
					encryptedToken: response.encryptedToken,
				},
			})
		} catch (error) {
			dispatch({
				type: 'FINISH_LOAD_BANK_STATEMENT_OPTIONS',
				bankAccountId,
				serverError: error,
			})
		} finally {
			endTask(loaderId)
		}
	}
}

export function saveStatementOptions(bankAccountId: string, statementOptions: BankAccountStatementOptions) {
	return async (dispatch: Dispatch<BankAction>, getState: () => State) => {
		const oldOptions = getBankAccountStatementOptions(getState(), bankAccountId)
		dispatch({
			type: 'START_SAVE_BANK_STATEMENT_OPTIONS',
			bankAccountId,
			statementOptions,
		})
		try {
			if (statementOptions.format) {
				await statementOptionsApiFormat.put(
					{
						organizationId: undefined,
						bankAccountId,
						format: statementOptions.format,
					},
					{
						isAutomaticDownloadEnabled: statementOptions.isAutomaticDownloadEnabled,
						downloadToken: statementOptions.downloadToken || undefined,
					},
				)
			} else {
				await statementOptionsApi.put(
					{
						organizationId: undefined,
						bankAccountId,
					},
					{
						isAutomaticDownloadEnabled: statementOptions.isAutomaticDownloadEnabled,
					},
				)
			}
		} catch (error) {
			if (oldOptions) {
				dispatch({
					type: 'FINISH_SAVE_BANK_STATEMENT_OPTIONS',
					bankAccountId,
					statementOptions: oldOptions,
					validationError: error,
					serverError: error,
				})
			}
		}
	}
}

export function toggleStatementOptionsDialog(
	status: 'OPEN' | 'CLOSE',
	format: ?BankAccountStatementOptionsFormatType = null,
) {
	return {
		type: 'BANK_STATEMENT_OPTIONS_DIALOG_TOGGLE',
		open: 'OPEN' === status,
		format,
	}
}

export function loadPaymentOrderTypes(bankId: string) {
	return async (dispatch: Dispatch<BankAction>) => {
		dispatch({
			type: 'START_LOAD_BANK_PAYMENT_ORDER_TYPES',
			bankId: bankId,
		})
		try {
			const result: Array<BankPaymentOrderType> = await paymentOrderTypesApi.get({
				bankId: bankId,
			})
			dispatch({
				type: 'FINISH_LOAD_BANK_PAYMENT_ORDER_TYPES',
				paymentOrderTypes: result || [],
				bankId: bankId,
			})
		} catch (error) {
			dispatch({
				type: 'FINISH_LOAD_BANK_PAYMENT_ORDER_TYPES',
				paymentOrderTypes: [],
				bankId: bankId,
				serverError: error,
			})
		}
	}
}

export function changeStatementsView(view: StatementsView): BankAction {
	storage.set(STATEMENTS_VIEW_KEY, view)
	return {
		type: 'CHANGE_STATEMENTS_LIST_VIEW',
		view: view,
	}
}
