/* @flow */
/** @jsx jsx */

import { ACCDOC_TAG_MAXLENGTH, REVERSE_CHARGE_CODES as reverseChargeCodes } from 'modules/accounting-document/constants'
import type {
	AccountingDocument,
	AccountingDocumentLineItem,
	Enum,
	EnumItem,
	FinancialAccount,
	FormFieldErrorContext,
	GreenboxSuggestionLineItemResponse,
	GreenboxSuggestionResponse,
	GreenboxSuggestionResponseLineItemFinancialAccount,
	MultiFormFieldMessages,
	Tag,
	VatRate,
} from 'types'
import { Checkbox, CurrencyInput, IconButton, MenuItem, NumberInput, SelectField, TextField } from 'components'
import { Component, createRef } from 'react'
import SelectNext, { type OptionType, type ValueType } from 'components/select-next'
import type { ValidationMessageType, ValidationValueType } from 'wrappers/validate'
import { type WithTranslateProps, withTranslate } from 'wrappers'
import { includes as arrayIncludes, isEqual } from 'lodash-es'
import { filterVatRates, findSameVatRate, findVatRateById, isVatRateAvailable } from '../../domain/vat-rate'
import { getAccDocType, isAccDocOutsideOfVatMode } from '../../domain/accounting-document'
import { getFinancialAccountsHintedOptions, isFinAccountNoAvailable } from '../../domain/financial-accounts'
import { getFormFieldErrorsFromMultiErros, getTooltipErrorLabel, hasFormFieldErrorsInMultiErrors } from 'helpers'

import ActionCheckCircle from 'components/svg-icons/action/check-circle'
import ActionHelpOutline from 'components/svg-icons/action/help-outline'
import AlertErrorOutline from 'components/svg-icons/alert/error-outline'
import AvRepeat from 'components/svg-icons/av/repeat'
import Copy from 'components/svg-icons/content/content-copy'
import Delete from 'components/svg-icons/action/delete'
import DropDownArrow from 'components/svg-icons/navigation/arrow-drop-down'
import { EMPTY_ARRAY } from 'trivi-constants'
import { type FavoriteOptionType } from 'components/favorite-select/favorite-option'
import FavoriteSelect from 'components/favorite-select'
import { FinancialAccounts_numberExist } from 'types/operations'
import LineItemDescription from '../../containers/invoice-elements/line-item-description'
import VatRateSelect from './vat-rate-select'
import { autoTestId } from 'utils/tests/autotest'
import { colors } from 'variables'
import { emptyAccountingDocumentLineItem } from '../../types/empty'
import { getFinancialAccountsOptions } from 'modules/accounting-document/domain/financial-accounts'
import { jsx } from '@emotion/core'
import memoize from 'memoize-one'
import styles from './line-item.css'

type FieldName = $Keys<AccountingDocumentLineItem>

const ZERO_PRICE_MIN_WIDTH = 31

const autocalcLoadingFields: Array<FieldName> = [
	'unitPrice',
	'unitPriceVatExcl',
	'total',
	'totalVatExcl',
	'vatValue',
	'qt',
	'vatRateId',
]

type Props = {|
	...WithTranslateProps,
	value: AccountingDocumentLineItem,
	public?: boolean,
	readonly?: boolean,
	financialAccounts: Array<FinancialAccount>,
	favoriteFinancialAccountIds: Array<string>,
	favoriteLineItemIds: Array<string>,
	greenboxSuggestion: ?GreenboxSuggestionResponse,
	availableProjects: Enum,
	availableBranches: Enum,
	isCalculating: boolean,
	currency: ?string,
	isCreating?: boolean,
	direction: ?number,
	index: number,
	isAccDocVatFree: boolean,
	validationMessage?: ValidationMessageType,
	validationValue?: ValidationValueType,
	formFieldErrorContext?: FormFieldErrorContext,
	defaultLineItems?: Array<AccountingDocumentLineItem> | null,
	accountingDocumentId?: ?string,
	accountingDocument?: ?AccountingDocument,
	vatRates: null | Array<VatRate>,
	onChange: (value: AccountingDocumentLineItem, autocalc: boolean) => void,
	onRemove: () => void,
	onClone: () => void,
	onExpand: (id: number) => void,
	onExpandClose: (id: number) => void,
	onFinancialAccountFavorite: (no: string, isFavorite: boolean) => void,
	createTag: (tag: Tag) => void,
	isExpanded: boolean,
	errors: ?MultiFormFieldMessages,
	removeError: (formFieldName: string) => void,
	disabledFinAccount?: boolean,
	isInternal?: boolean,
	isDescriptionChangeOnly?: boolean,
	isLastLineItem?: boolean,
	onCreate?: () => Promise<void>,
|}

type State = {
	expanded: boolean,
	autocalc: boolean,
	fieldState: {
		[fieldName: FieldName]: any,
	},
	editingField: ?string,
	suggestedFinAcc?: string,
	isSuggestedFinAcc?: boolean,
	isSuggestedFinAccUnavailable?: boolean,
}

class LineItem extends Component<Props, State> {
	state: State
	changedField: ?FieldName
	lastCountingField: ?FieldName
	lastCountingValue: ?any
	lineItems: Array<AccountingDocumentLineItem> = []
	disableSearchFor: string | null = null

	unitPriceRef = createRef()
	totalRef = createRef()

	ERROR_ENABLED_FIELDS = ['vatRateId', 'description', 'finAccount', 'reverseChargeCode']

	constructor(props: Props) {
		super(props)
		this.state = {
			expanded: false,
			autocalc: true,
			fieldState: this.createFieldState(props),
			editingField: null,
			suggestedFinAcc: '', //null,
			isSuggestedFinAcc: false,
			isSuggestedFinAccUnavailable: false,
		}
		this.changedField = null
		this.lastCountingField = null
		this.lastCountingValue = null
	}

	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		const item: AccountingDocumentLineItem = nextProps.value || emptyAccountingDocumentLineItem()
		this.setState({
			fieldState: this.createFieldState(nextProps),
			expanded: nextProps.isExpanded,
		})
		const greenboxSuggestion = this.getGreenboxSuggestion(nextProps.greenboxSuggestion, nextProps.index)

		const oldGgreenboxSuggestion = this.getGreenboxSuggestion(this.props.greenboxSuggestion, this.props.index)

		if (greenboxSuggestion && greenboxSuggestion.financialAccounts && greenboxSuggestion.financialAccounts.length > 0) {
			const firstAccount = greenboxSuggestion.financialAccounts[0]
			const suggestedFinAccount = firstAccount.financialAccountNumber

			this.setState({
				suggestedFinAcc:
					!!firstAccount.confidenceLevel && firstAccount.confidenceLevel === 1 ? suggestedFinAccount : '',
				isSuggestedFinAcc:
					!!firstAccount.confidenceLevel &&
					firstAccount.confidenceLevel === 1 &&
					this.state.fieldState.finAccount === suggestedFinAccount,
				isSuggestedFinAccUnavailable:
					!!firstAccount.confidenceLevel &&
					firstAccount.confidenceLevel === 1 &&
					!FinancialAccounts_numberExist(nextProps.financialAccounts, suggestedFinAccount),
			})

			if (
				!isGreenboxSuggestionEqual(oldGgreenboxSuggestion, greenboxSuggestion) &&
				item.description &&
				!item.finAccount // Pokud se nápověda z rákosáka nezměnila, nevyplňovat nic
			) {
				// [TWU-3250] Docházelo tady k zacyklení - i když byl napovězený účet z rákosáka nevalidní, předvyplnil se,
				// následoval blur a odeslala se úprava položky na BE. Z BE se vrátila chyba
				// a to opět vyvolalo předvyplnění položky z rákosáka. Nyní se předvyplní jen při změně.
				if (firstAccount.confidenceLevel === 1 && this.state.fieldState.finAccount !== suggestedFinAccount) {
					// [TWU-3250] Nepředvyplňovat, pokud číslo účtu neznáme
					if (FinancialAccounts_numberExist(nextProps.financialAccounts, suggestedFinAccount)) {
						this.changedField = 'finAccount'
						this.setState(
							{
								fieldState: { ...this.state.fieldState, finAccount: suggestedFinAccount },
								editingField: 'finAccount',
								suggestedFinAcc: suggestedFinAccount,
								isSuggestedFinAcc: true,
							},
							() => {
								this.onBlur()
							},
						)
					}
				}
			}
		} else {
			this.setState({
				suggestedFinAcc: '',
				isSuggestedFinAcc: false,
				isSuggestedFinAccUnavailable: false,
			})
		}
	}

	getGreenboxSuggestion = memoize((greenboxSuggestion: ?GreenboxSuggestionResponse, index: number) => {
		return greenboxSuggestion && greenboxSuggestion.lineItems && greenboxSuggestion.lineItems[index]
	})

	createFieldState(props: Props) {
		const item: AccountingDocumentLineItem = props.value || emptyAccountingDocumentLineItem()

		if (this.state && this.state.fieldState && this.state.editingField) {
			return {
				...item,
				// $FlowFixMe
				[this.state.editingField]: this.state.fieldState[this.state.editingField],
			}
		}

		return item
	}

	createItemFromState() {
		let lineItem: AccountingDocumentLineItem = emptyAccountingDocumentLineItem()

		const item = this.state.fieldState

		Object.keys(this.props.value).map((fieldName: FieldName) => {
			lineItem[fieldName] = item[fieldName]
		})

		//TODO hotfix, celý tenhle soubor je příšernej!!!
		Object.keys(this.state.fieldState).forEach((fieldName: any) => {
			lineItem[fieldName] = item[fieldName]
		})

		return lineItem
	}

	onBlur = () => {
		setTimeout(() => {
			if (this.changedField) {
				const stateItem = this.createItemFromState()

				let itemToSubmit: AccountingDocumentLineItem = Object.assign(emptyAccountingDocumentLineItem(), stateItem)

				//TODO udělat lepší odbočku
				if (!this.state.autocalc) {
					this.changedField = null
					itemToSubmit.vatValue = (itemToSubmit.total || 0) - (itemToSubmit.totalVatExcl || 0)
					this.props.onChange(itemToSubmit, false)
					return
				}

				if (this.changedField) {
					const changedValue = stateItem[this.changedField]

					const recountingFields: Array<FieldName> = [
						'unitPrice',
						'unitPriceVatExcl',
						'total',
						'totalVatExcl',
						'vatValue',
					]

					if (arrayIncludes(recountingFields, this.changedField)) {
						this.lastCountingField = this.changedField
						this.lastCountingValue = changedValue
						recountingFields.forEach((fieldName: FieldName) => {
							delete itemToSubmit[fieldName]
						})

						if (this.changedField) itemToSubmit[this.changedField] = (changedValue: any)
					} else if (this.changedField === 'qt' || this.changedField === 'vatRateId') {
						recountingFields.forEach((fieldName: FieldName) => {
							delete itemToSubmit[fieldName]
						})
						// No field was edited before
						if (!this.lastCountingField) {
							itemToSubmit.unitPriceVatExcl = stateItem.unitPriceVatExcl
						} else {
							if (this.lastCountingValue) itemToSubmit[this.lastCountingField] = this.lastCountingValue
						}

						if (isAccDocOutsideOfVatMode(this.props.accountingDocument)) {
							//If 'outside of VAT' mode - leave total and totalWithVAT same, instead recalculate unit price
							if (this.changedField === 'vatRateId' && stateItem.vatRateType === 2) {
								itemToSubmit.total = stateItem.total
								itemToSubmit.totalVatExcl = stateItem.total
								itemToSubmit.vatValue = 0
								if (stateItem.total && stateItem.qt) {
									itemToSubmit.unitPrice = stateItem.total / stateItem.qt
								}
								itemToSubmit.unitPriceVatExcl = itemToSubmit.unitPrice
							}
						}
					}

					const autocalc: boolean = this.changedField ? arrayIncludes(autocalcLoadingFields, this.changedField) : false
					this.props.onChange(itemToSubmit, autocalc)
					if (
						this.changedField &&
						this.ERROR_ENABLED_FIELDS.includes(this.changedField) &&
						hasFormFieldErrorsInMultiErrors(this.props.errors, this.changedField)
					) {
						this.props.removeError(this.changedField)
					}
				}
			}

			this.changedField = null
			this.setState({ editingField: null })
		}, 0)
	}

	closeExpand = () => {
		this.setState({
			expanded: false,
		})
		console.log(this.state)
		this.props.onExpandClose(this.props.index)
	}

	openExpand = () => {
		this.setState({
			expanded: true,
		})
		this.props.onExpand(this.props.index)
	}

	onAutocalcCheck = (event: SyntheticInputEvent<HTMLInputElement>, autocalc: boolean) => {
		this.setState({ autocalc })
	}

	onVatDeductionCheck = (event: SyntheticInputEvent<HTMLInputElement>, vatDeduction: boolean) => {
		const stateItem = this.createItemFromState()
		const item: AccountingDocumentLineItem = Object.assign(emptyAccountingDocumentLineItem(), stateItem, {
			vatDeduction,
		})
		this.props.onChange(item, this.state.autocalc)
	}

	getAutoCompleteOptions = (enumeration: Enum, value: ?string) => {
		let options = enumeration.map((item: EnumItem) => ({ value: item.key, label: item.value }))

		if (value && !options.find((option: OptionType) => option.value === value)) {
			options.push({ value: value, label: value })
		}

		return options
	}

	renderExpandButton = (style: Object) => {
		if (this.props.isCreating || this.props.public) {
			return null
		}
		return (
			<div className={styles.expand} onClick={this.openExpand}>
				<div className={styles.expandInner}>
					<DropDownArrow style={style.expandIcon} viewBox={'2 4 20 20'} />
				</div>
			</div>
		)
	}

	onVatRateChange = (vatRate: VatRate | null) => {
		this.changedField = 'vatRateId'
		this.setState(
			{
				fieldState: { ...this.state.fieldState, ...this.getNewVatRateFieldValues(vatRate) },
				expanded: vatRate && vatRate.type === 4 ? true : this.state.expanded,
				editingField: 'vatRateId',
			},
			() => {
				this.onBlur()
			},
		)
	}

	getNewVatRateFieldValues = (vatRate: VatRate | null): {} => {
		const fieldsToChange = {}

		if (vatRate) {
			if (vatRate.vatRate !== undefined) {
				fieldsToChange.vatRate = vatRate.vatRate * 100
			}
			if (vatRate.type !== undefined) {
				fieldsToChange.vatRateType = vatRate.type
			}
			if (vatRate.id !== undefined) {
				fieldsToChange.vatRateId = vatRate.id
			}
			if (vatRate.type !== 4) {
				fieldsToChange.reverseChargeCode = null
			}
		} else {
			fieldsToChange.vatRate = null
			fieldsToChange.vatRateType = null
			fieldsToChange.vatRateId = null
			fieldsToChange.reverseChargeCode = null
		}

		return fieldsToChange
	}

	createTagIfNeeded = (value: ?string, isBranch: boolean = true) => {
		if (value == null || '' === value) return
		const tagType = isBranch ? 0 : 1
		const tagsOnServer = isBranch ? this.props.availableBranches : this.props.availableProjects
		const isTagOnServer = tagsOnServer.find((tag: EnumItem) => tag.key === value)

		if (!isTagOnServer) {
			this.props.createTag({
				code: value,
				name: value,
				type: tagType,
			})
		}
	}

	onBranchChange = (value: ValueType) => {
		const newBranch = value && !Array.isArray(value) ? value.value : null
		this.changedField = 'branch'

		this.createTagIfNeeded(newBranch)
		this.setState(
			{
				fieldState: { ...this.state.fieldState, branch: newBranch },
				editingField: 'branch',
			},
			() => {
				this.onBlur()
			},
		)
	}

	onProjectChange = (value: ValueType) => {
		const newProject = value && !Array.isArray(value) ? value.value : null
		this.changedField = 'project'

		this.createTagIfNeeded(newProject, false)

		this.setState(
			{
				fieldState: { ...this.state.fieldState, project: newProject },
				editingField: 'project',
			},
			() => {
				this.onBlur()
			},
		)
	}

	onUnitNameChange = (event: SyntheticInputEvent<HTMLInputElement>, value: ?string) => {
		this.changedField = 'unitName'
		this.setState({
			fieldState: { ...this.state.fieldState, unitName: value },
			editingField: 'unitName',
		})
	}

	onUnitPriceChange = (event: SyntheticInputEvent<HTMLInputElement>, value: ?number) => {
		this.changedField = 'unitPrice'
		this.setState({
			fieldState: { ...this.state.fieldState, unitPrice: value },
			editingField: 'unitPrice',
		})
	}

	onUnitPriceVatExclChange = (event: SyntheticInputEvent<HTMLInputElement>, value: ?number) => {
		this.changedField = 'unitPriceVatExcl'
		this.setState({
			fieldState: { ...this.state.fieldState, unitPriceVatExcl: value },
			editingField: 'unitPriceVatExcl',
		})
	}

	onTotalChange = (event: SyntheticInputEvent<HTMLInputElement>, value: ?number) => {
		this.changedField = 'total'
		this.setState({
			fieldState: { ...this.state.fieldState, total: value },
			editingField: 'total',
		})
	}

	onTotalVatExclChange = (event: SyntheticInputEvent<HTMLInputElement>, value: ?number) => {
		this.changedField = 'totalVatExcl'
		this.setState({
			fieldState: { ...this.state.fieldState, totalVatExcl: value },
			editingField: 'totalVatExcl',
		})
	}

	onQuantityChange = (event: SyntheticInputEvent<HTMLInputElement>, value: ?number) => {
		this.changedField = 'qt'
		this.setState({
			fieldState: { ...this.state.fieldState, qt: value },
			editingField: 'qt',
		})
	}

	onFinAccountChange = (value: FavoriteOptionType) => {
		const vatRate = findVatRateById(this.props.vatRates, this.state.fieldState.vatRateId)
		const newVatRates = filterVatRates(this.props.vatRates, value.value)
		const needNewVatRate = !isVatRateAvailable(newVatRates, this.state.fieldState.vatRateId)
		let vatRateFieldValues = {}

		if (needNewVatRate) {
			const newVatRate = needNewVatRate
				? findSameVatRate(newVatRates, {
						id: this.props.value.vatRateId,
						type: this.props.value.vatRateType,
						vatRate: this.props.value.vatRate,
						text: vatRate ? vatRate.text : undefined,
				  })
				: null
			vatRateFieldValues = this.getNewVatRateFieldValues(newVatRate)
		}

		this.changedField = 'finAccount'
		this.setState(
			{
				fieldState: { ...this.state.fieldState, finAccount: value.value, ...vatRateFieldValues },
				editingField: 'finAccount',
			},
			() => {
				this.onBlur()
			},
		)
	}

	onReverseChargeCodeChange = (event: SyntheticEvent<HTMLElement>, index: ?number, value: any) => {
		this.changedField = 'reverseChargeCode'
		this.setState(
			{
				fieldState: { ...this.state.fieldState, reverseChargeCode: value },
				editingField: 'reverseChargeCode',
			},
			() => {
				this.props.removeError('reverseChargeCode')
				const stateItem = this.createItemFromState()
				this.props.onChange(stateItem, this.state.autocalc)
			},
		)
	}

	renderExpandedPart = (style: Object, errorReverseChargeCode: any) => {
		if (!this.state.expanded || this.props.isCreating || this.props.public) {
			return null
		}

		const { t } = this.props
		const isReverseCharge = this.props.value.vatRateType === 4

		return (
			<div className={styles.expandedPart} open={this.state.expanded}>
				<div className={styles.expandedContainer}>
					{isReverseCharge && (
						<div className={styles.column}>
							<SelectField
								compact
								fullWidth
								nullable
								clientError={getTooltipErrorLabel(errorReverseChargeCode, t)}
								labelText={t('invoice.lineItems.reverseChargeLabel')}
								hintText={t('invoice.lineItems.reverseChargeHint')}
								autoTestId="invoice-classification-reverse-charge"
								disabled={this.props.readonly}
								value={this.state.fieldState.reverseChargeCode}
								onChange={this.onReverseChargeCodeChange}
							>
								{reverseChargeCodes.map((code: { code: string, name: string }) => (
									<MenuItem key={code.code} value={code.code} primaryText={code.name} />
								))}
							</SelectField>
						</div>
					)}
					{this.props.availableBranches && this.props.availableBranches.length > 0 && (
						<div className={styles.column}>
							<SelectNext
								compact
								isClearable
								isSearchable
								label={t('invoice.lineItems.branchLabel')}
								autoTestId="invoice-line-item-branch-select"
								disabled={this.props.readonly}
								onChange={this.onBranchChange}
								options={this.getAutoCompleteOptions(this.props.availableBranches, this.state.fieldState.branch)}
								value={this.state.fieldState.branch}
								maxLength={ACCDOC_TAG_MAXLENGTH.branch}
								editCursor
							/>
						</div>
					)}
					{this.props.availableProjects && this.props.availableProjects.length > 0 && (
						<div className={styles.column}>
							<SelectNext
								compact
								isClearable
								isSearchable
								label={t('invoice.lineItems.projectLabel')}
								autoTestId="invoice-line-item-project-select"
								disabled={this.props.readonly}
								onChange={this.onProjectChange}
								options={this.getAutoCompleteOptions(this.props.availableProjects, this.state.fieldState.project)}
								value={this.state.fieldState.project}
								maxLength={ACCDOC_TAG_MAXLENGTH.project}
								editCursor
							/>
						</div>
					)}
				</div>
				<div className={styles.checkboxesContainer}>
					<span className={styles.expandedCheckbox}>
						<Checkbox
							label={t('invoice.lineItems.autocalcLabel')}
							checked={this.state.autocalc}
							onCheck={this.onAutocalcCheck}
							disabled={this.props.readonly}
						/>
					</span>
					{this.props.direction !== 1 && (
						<span className={styles.expandedCheckbox}>
							<Checkbox
								label={t('invoice.lineItems.vatDeductionLabel')}
								checked={!!this.props.value.vatDeduction}
								onCheck={this.onVatDeductionCheck}
								disabled={this.props.readonly}
							/>
						</span>
					)}
				</div>
				<div className={styles.reduce} onClick={this.closeExpand}>
					<div className={styles.reduceInner}>
						<DropDownArrow style={style.reduceIcon} viewBox={'2 4 20 20'} />
					</div>
				</div>
			</div>
		)
	}

	renderSideButtons = (style: Object) => {
		if (this.props.readonly || this.props.public || this.state.expanded || this.props.isCreating) {
			return null
		}
		return (
			<div className={styles.icons}>
				<div>
					<IconButton
						style={style.sideButton}
						onClick={this.props.onClone}
						tooltip={this.props.t('invoice.lineItems.copyLineItem')}
						tooltipAlign={'center-right'}
						tooltipArrowAlign={'center-left'}
						autoTestId="invoice-line-item-copy"
						size={24}
					>
						<Copy size={14} color={colors.grey600} />
					</IconButton>
				</div>
				<div>
					<IconButton
						style={style.sideButton}
						onClick={this.props.onRemove}
						tooltip={this.props.t('invoice.lineItems.deleteLineItem')}
						autoTestId="invoice-line-item-delete"
						tooltipAlign={'center-right'}
						tooltipArrowAlign={'center-left'}
						size={24}
					>
						<Delete size={14} color={colors.grey600} />
					</IconButton>
				</div>
			</div>
		)
	}

	getValErrorId(id: string) {
		return this.props.index.toString() + '_' + id
	}

	onFavorite = (value: ?string, isFavorite: boolean) => {
		value && this.props.onFinancialAccountFavorite(value, isFavorite)
	}

	getFinAccountTooltip = memoize((accounts: Array<FavoriteOptionType>, finAccount: ?string) => {
		const account = accounts.find((account: FavoriteOptionType) => account.labelPrefix === finAccount)
		const name = (account && account.label) || ''
		return name.length > 20 ? name : null
	})

	getOptions = memoize((accounts: Array<FinancialAccount>, favorites: Array<string>, hinted: Array<string>) => {
		return getFinancialAccountsOptions(accounts, favorites, hinted, this.onFavorite)
	})

	getHintedOptions = memoize((accounts: Array<FinancialAccount>, favorites: Array<string>, hinted: Array<string>) => {
		return getFinancialAccountsHintedOptions(accounts, favorites, hinted, this.onFavorite).filter(
			(opt: FavoriteOptionType) => !opt.isCategory,
		)
	})

	focusUnitPrice = () => {
		this.unitPriceRef.current && this.unitPriceRef.current.focus()
	}

	focusTotal = () => {
		this.totalRef.current && this.totalRef.current.focus()
	}

	onDescriptionChange = (value: string) => {
		this.changedField = 'description'
		this.setState(
			{
				fieldState: { ...this.state.fieldState, description: value },
				editingField: 'description',
			},
			() => {
				this.props.removeError('description')
				this.onBlur()
			},
		)
	}

	onLineItemChange = (item: AccountingDocumentLineItem) => {
		const newLineItem = {
			...(this.props.isDescriptionChangeOnly && this.props.isInternal ? this.props.value : item),
			description: item.description,
			branch: this.props.value.branch,
			project: this.props.value.project,
			id: this.state.fieldState.id,
		}

		const hasAllValues = null != newLineItem.vatRate && null != newLineItem.vatRateType && null != newLineItem.vatRateId
		const isFinAccountAvailable = !!(
			newLineItem.finAccount &&
			this.props.financialAccounts &&
			isFinAccountNoAvailable(this.props.financialAccounts, newLineItem.finAccount)
		)

		if (!isFinAccountAvailable || this.props.disabledFinAccount) delete newLineItem.finAccount
		if (!hasAllValues || !isVatRateAvailable(this.props.vatRates, newLineItem.vatRateId)) {
			delete newLineItem.vatRate
			delete newLineItem.vatRateId
			delete newLineItem.vatRateType
		}

		this.props.removeError('finAccount')
		this.props.removeError('description')
		this.props.onChange(newLineItem, false)
	}

	getFilteredVatRates = memoize((vatRates?: Array<VatRate> | null, financialAccount?: string): Array<VatRate> =>
		filterVatRates(vatRates, financialAccount),
	)

	getGBXVariant = (option: ?FavoriteOptionType, suggestionError?: string) => {
		const { t } = this.props
		if (this.state.fieldState.finAccount && this.state.suggestedFinAcc && !this.state.isSuggestedFinAccUnavailable) {
			//1
			if (this.state.fieldState.finAccount === this.state.suggestedFinAcc) {
				return {
					label: t('invoice.lineItems.gbxHelper.gbxAgrees'),
					icon: <ActionCheckCircle />,
					color: colors.green,
					onClick: undefined,
				}
				//2
			} else {
				return {
					label: t('invoice.lineItems.gbxHelper.changeTo', {
						finAcc: this.state.suggestedFinAcc,
						finAccName: (option && option.label) ?? '',
					}),
					icon: <AvRepeat />,
					color: colors.red,
					onClick: true,
				}
			}
		} else {
			//3
			if (this.state.suggestedFinAcc) {
				//6,7
				if (this.state.isSuggestedFinAccUnavailable) {
					return {
						label: t('invoice.lineItems.gbxHelper.gbxHelpNotIncluded', { finAcc: this.state.suggestedFinAcc }),
						icon: <AlertErrorOutline />,
						onClick: undefined,
						color: colors.purple,
					}
				} else {
					return {
						label: t('invoice.lineItems.gbxHelper.setTo', {
							finAcc: this.state.suggestedFinAcc,
							finAccName: (option && option.label) ?? '',
						}),
						icon: <AvRepeat />,
						color: colors.orange,
						onClick: true,
					}
				}
			}
			//4,5
			else {
				return {
					label: t('invoice.lineItems.gbxHelper.noGbxHelp', { message: suggestionError }),
					color: colors.grey,
					icon: <ActionHelpOutline />,
					onClick: undefined,
				}
			}
		}
	}

	renderGBXSuggestion = (options: FavoriteOptionType[], gbxSuggestion: ?GreenboxSuggestionResponse) => {
		let suggestionError = undefined

		if (gbxSuggestion && gbxSuggestion.lineItems) {
			const suggestionLineItem: ?GreenboxSuggestionLineItemResponse = gbxSuggestion.lineItems.find(
				(li: GreenboxSuggestionLineItemResponse) => li.uniqueId === this.props.value.id,
			)
			if (!suggestionLineItem) {
				suggestionError = gbxSuggestion.message
			} else {
				const confidentSuggestion =
					suggestionLineItem.preferredFinancialAccount ??
					(suggestionLineItem.financialAccounts &&
						suggestionLineItem.financialAccounts.find(
							(fa: GreenboxSuggestionResponseLineItemFinancialAccount) =>
								fa && fa.confidenceLevel && fa.confidenceLevel >= 1,
						))
				if (gbxSuggestion.message && !confidentSuggestion) {
					suggestionError = gbxSuggestion.message
				}
			}
		}
		const option = options.find((opt: FavoriteOptionType) => opt.value === this.state.suggestedFinAcc)
		const variant = this.getGBXVariant(option, suggestionError)
		if (this.props.readonly || this.props.public || this.props.disabledFinAccount || !this.props.isInternal) {
			return null
		}
		return (
			<IconButton
				size={25}
				iconSize={20}
				color={variant.color}
				onClick={() => variant.onClick && option && this.onFinAccountChange(option)}
				tooltip={variant.label}
			>
				{variant.icon}
			</IconButton>
		)
	}

	render() {
		const { isAccDocVatFree, t, errors } = this.props
		const foundBranch = this.props.availableBranches.find((branch: EnumItem) => branch.key === this.props.value.branch)
		const foundProject = this.props.availableProjects.find(
			(project: EnumItem) => project.key === this.props.value.project,
		)
		const branch = (foundBranch && foundBranch.value) || this.props.value.branch
		const project = (foundProject && foundProject.value) || this.props.value.project
		const offset = this.props.index * 10

		const greenboxSuggestion = this.getGreenboxSuggestion(this.props.greenboxSuggestion, this.props.index)
		const hintedFinancialAccountIds = (greenboxSuggestion && greenboxSuggestion.financialAccountNumbers) || EMPTY_ARRAY
		const finAccountErrorId = this.getValErrorId('finAccount')
		const options = this.getOptions(
			this.props.financialAccounts,
			this.props.favoriteFinancialAccountIds,
			hintedFinancialAccountIds,
		)

		const hintedOptions = this.getHintedOptions(
			this.props.financialAccounts,
			this.props.favoriteFinancialAccountIds,
			hintedFinancialAccountIds,
		)

		const descriptionErrors = getFormFieldErrorsFromMultiErros(errors, 'description')
		const finAccountErrors = getFormFieldErrorsFromMultiErros(errors, 'finAccount')
		const vatRateIdErrors = getFormFieldErrorsFromMultiErros(errors, 'vatRateId')
		const reverseChargeCodeErrors = getFormFieldErrorsFromMultiErros(errors, 'reverseChargeCode')
		const readonly = this.props.readonly || this.props.public

		const vatRateText = t(`invoice.lineItems.vatRateTypes.${this.props.value.vatRateType || 1}`, {
			vatRate: this.props.value.vatRate || 0,
		})

		const isVatRateUnavailable =
			!!this.state.fieldState.vatRateId && !isVatRateAvailable(this.props.vatRates, this.state.fieldState.vatRateId)
		const vatRateError = isVatRateUnavailable ? t('invoice.lineItems.vatRateNotFound', { text: vatRateText }) : null

		return (
			<div
				ref="root"
				className={`${this.state.expanded ? styles.opened : styles.normal} ${isAccDocVatFree ? styles.vatFree : ''}`}
				{...autoTestId('lineItem')}
			>
				<div className={styles.inner}>
					<div className={styles.item}>
						<div css={style.description}>
							<LineItemDescription
								defaultLineItems={this.props.defaultLineItems}
								favoriteLineItemIds={this.props.favoriteLineItemIds}
								onDescriptionChange={this.onDescriptionChange}
								onLineItemChange={this.onLineItemChange}
								isAccDocVatFree={this.props.isAccDocVatFree}
								value={this.state.fieldState.description}
								error={getTooltipErrorLabel(descriptionErrors, t)}
								direction={this.props.direction}
								accountingDocumentId={this.props.accountingDocumentId}
								currency={this.props.currency}
								tabIndex={offset + 1}
								disabled={readonly}
								newLineTabIndex={(this.props.index + 1) * 10 + 1}
								isInLastLineItem={this.props.isLastLineItem}
							/>
						</div>
						{!this.props.public && (
							<div css={style.accountPickerContainer}>
								<div css={this.props.isAccDocVatFree ? style.gbxSuggestionVatLess : style.gbxSuggestion}>
									{this.renderGBXSuggestion(options, this.props.greenboxSuggestion)}
								</div>
								<div css={style.accountPicker}>
									<FavoriteSelect
										value={this.props.value && this.props.value.finAccount}
										onChange={this.onFinAccountChange}
										placeholder={t('invoice.lineItems.financialAccountHint')}
										options={options}
										hintedOptions={hintedOptions}
										error={
											getTooltipErrorLabel(finAccountErrors, t) ||
											(this.props.validationMessage &&
												this.props.validationMessage('finAccount') &&
												this.props.validationMessage(finAccountErrorId))
										}
										tabIndex={offset + 7}
										disabled={readonly || this.props.disabledFinAccount}
										autoTestId="invoice-line-item-finaccount-select"
										tooltip={this.getFinAccountTooltip(options, this.props.value.finAccount)}
										inline
										useKeyboardNavigation
										newLineTabIndex={(this.props.index + 1) * 10 + 1}
										isInLastLineItem={this.props.isLastLineItem}
										onLastLineEnter={isAccDocVatFree ? this.props.onCreate : undefined}
										selectWithoutArrow
										navigateToNextLine={!!isAccDocVatFree}
									/>
								</div>
							</div>
						)}
						<div className={styles.projectBranchContainer}>
							{branch && <div className={styles.branch}>{branch}</div>}
							{project && <div className={styles.project}>{project}</div>}
						</div>
					</div>
					<div className={styles.price}>
						<CurrencyInput
							fullWidth
							right
							inline
							tabIndex={offset + 2}
							hintText={t('invoice.lineItems.priceHint')}
							disabled={this.props.isCalculating || readonly}
							value={this.state.fieldState.unitPriceVatExcl}
							onChange={this.onUnitPriceVatExclChange}
							onBlur={this.onBlur}
							autoTestId="unitPriceVatExcl"
							name="unitPriceVatExcl"
							useKeyboardNavigation
							newLineTabIndex={(this.props.index + 1) * 10 + 1}
							nextItemTabIndex={isAccDocVatFree ? offset + 4 : undefined}
						/>
						{!isAccDocVatFree && (
							<div css={style.priceWrapper}>
								<span onClick={this.focusUnitPrice} css={style.withVatLabel}>
									{t('invoice.lineItems.withVatLabel')}
								</span>
								<CurrencyInput
									autoWidth
									right
									inline
									tabIndex={offset + 8}
									disabled={this.props.isCalculating || readonly}
									value={this.state.fieldState.unitPrice}
									onChange={this.onUnitPriceChange}
									onBlur={this.onBlur}
									autoTestId="unitPrice"
									name="unitPrice"
									minWidth={ZERO_PRICE_MIN_WIDTH}
									ref={this.unitPriceRef}
									useKeyboardNavigation
									newLineTabIndex={(this.props.index + 1) * 10 + 1}
								/>
							</div>
						)}
					</div>

					{!isAccDocVatFree && !this.props.isCreating && (
						<div className={styles.vatRate}>
							<div css={style.vatRateSelector}>
								{this.props.public ? (
									<span css={style.publicVatRate}>{vatRateText}</span>
								) : (
									<VatRateSelect
										tabIndex={offset + 3}
										disabled={readonly}
										onChange={this.onVatRateChange}
										autoTestId="invoice-line-item-vatrate-select"
										error={getTooltipErrorLabel(vatRateIdErrors, t) || vatRateError}
										value={this.state.fieldState.vatRateId}
										vatRates={this.getFilteredVatRates(this.props.vatRates, this.props.value.finAccount)}
										useKeyboardNavigation
										newLineTabIndex={(this.props.index + 1) * 10 + 1}
									/>
								)}
							</div>
						</div>
					)}

					<div className={styles.quantity}>
						<NumberInput
							name="qt"
							tabIndex={offset + 4}
							hintText={t('invoice.lineItems.amountHint')}
							disabled={readonly}
							inline
							autoTestId={'qt'}
							onBlur={this.onBlur}
							value={this.state.fieldState.qt}
							onChange={this.onQuantityChange}
							useKeyboardNavigation
							newLineTabIndex={(this.props.index + 1) * 10 + 1}
						/>
					</div>
					<div className={styles.unit}>
						<TextField
							tabIndex={offset + 5}
							name="unitName"
							hintText={t('invoice.lineItems.unitNameHint')}
							disabled={readonly}
							inline
							style={style.unitName}
							onBlur={this.onBlur}
							value={this.state.fieldState.unitName}
							onChange={this.onUnitNameChange}
							autoTestId={'unitName'}
							editCursor
							useKeyboardNavigation
							isInLastLineItem={this.props.isLastLineItem}
						/>
					</div>
					<div className={styles.total}>
						<CurrencyInput
							tabIndex={offset + 6}
							disabled={this.props.isCalculating || readonly}
							fullWidth
							value={this.state.fieldState.totalVatExcl}
							onChange={this.onTotalVatExclChange}
							onBlur={this.onBlur}
							autoTestId="totalVatExcl"
							right
							inline
							name="totalVatExcl"
							useKeyboardNavigation
							newLineTabIndex={(this.props.index + 1) * 10 + 1}
						/>
						{!isAccDocVatFree && (
							<div css={style.priceWrapper}>
								<span onClick={this.focusTotal} css={style.withVatLabel}>
									{t('invoice.lineItems.withVatLabel')}
								</span>
								<CurrencyInput
									autoWidth
									right
									tabIndex={offset + 9}
									disabled={this.props.isCalculating || readonly}
									value={this.state.fieldState.total}
									onChange={this.onTotalChange}
									onBlur={this.onBlur}
									autoTestId="total"
									inline
									name="total"
									minWidth={ZERO_PRICE_MIN_WIDTH}
									ref={this.totalRef}
									useKeyboardNavigation
									newLineTabIndex={(this.props.index + 1) * 10 + 1}
									isInLastLineItem={this.props.isLastLineItem}
									onLastLineEnter={this.props.onCreate}
									navigateToNextLine={!isAccDocVatFree}
								/>
							</div>
						)}
						{this.renderSideButtons(style)}
					</div>
					{this.renderExpandButton(style)}
					{this.renderExpandedPart(style, reverseChargeCodeErrors)}
				</div>
			</div>
		)
	}
}

const style = {
	publicVatRate: {
		marginLeft: 4,
		lineHeight: '24px',
	},
	expandIcon: {
		height: 13,
		width: 28,
		padding: 0,
	},
	reduceIcon: {
		height: 13,
		width: 28,
		padding: 0,
		transform: 'rotate(180deg) translateY(4px)',
	},
	sideButton: { padding: 5 },
	description: { marginLeft: -4 },
	accountPicker: { marginLeft: -4, flex: 1 },
	accountPickerContainer: { display: 'inline-flex', width: '100%' },
	gbxSuggestion: { flex: '0 13%', marginLeft: '-28px', marginRight: '3px' },
	gbxSuggestionVatLess: { flex: '0 9%', marginLeft: '-28px', marginRight: '3px' },
	unitName: { height: 24, lineHeight: '24px' },
	withVatLabel: { paddingRight: 3, verticalAlign: 'middle', lineHeight: '24px' },
	priceWrapper: {
		display: 'flex',
		justifyContent: 'flex-end',
		textAlign: 'right',
	},
	vatRateSelector: { margin: '0 -4px' },
}

export default withTranslate(LineItem)

function isGreenboxSuggestionEqual(
	sugg1: ?GreenboxSuggestionLineItemResponse,
	sugg2: ?GreenboxSuggestionLineItemResponse,
): boolean {
	return isEqual(sugg1 && sugg1.financialAccountNumbers, sugg2 && sugg2.financialAccountNumbers)
}
