/* @flow */

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import CashRegisterEdit from '../containers/cash-register-edit'
import type { Dispatch, CommonAction, OrganizationAction } from 'types'
import { loadUsers } from 'modules/organization/actions'
import { loadCurrencies } from 'modules/common/actions'
import PopupSection from 'components/PopupSection'
import { cashRegistersRoute } from 'modules/cash-register/routing/routes'
import { push } from 'react-router-redux'
import withAutoload from 'wrappers/with-autoload'
import Helmet from 'react-helmet'

type Props = {|
	close: () => void,
	autoload: () => void,
|}

type ComponentProps = {| ...WithTranslateProps, ...Props |}

class CashRegisterCreatePage extends Component<ComponentProps> {
	render() {
		return (
			<PopupSection open onRequestClose={this.props.close}>
				<Helmet>
					<title>{this.props.t('cashRegister.create.header')}</title>
				</Helmet>
				<CashRegisterEdit isCreatingNewCashRegister />
			</PopupSection>
		)
	}
}

const mapDispatchToProps = (dispatch: Dispatch<OrganizationAction | CommonAction>): Props => {
	return {
		close: () => {
			dispatch(push(cashRegistersRoute()))
		},
		autoload: () => {
			dispatch(loadUsers())
			dispatch(loadCurrencies())
		},
	}
}

export default withTranslate(connect(null, mapDispatchToProps)(withAutoload(CashRegisterCreatePage)))
