/* @flow */

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslate, type WithTranslateProps, type WithOrganizationSettingsProps } from 'wrappers'
import type {
	// OrganizationCountrySpecificSettingsCzEet,
	OrganizationSettings,
	WithRouterProps,
	OrganizationAddress,
	Organization,
	Base64Image,
	State,
	UserAction,
	// FormEetSettings,
	CompanyType,
} from 'types'
import {
	settingsBasicsRoute,
	// settingsEetRoute,
	settingsResponsibilityRoute,
	settingsSignaturesRoute,
	settingsEsoRoute,
	settingsCashbotRoute,
	settingsAdministrationRoute,
} from 'modules/settings/routing/routes'
import EsoContainer from 'modules/settings/containers/eso'
import DefaultSettings from 'modules/settings/components/company/default-settings'
import StatutoryAuthorityContainer from 'modules/settings/containers/statutory-authority'
import Signatures from 'modules/settings/containers/signatures'
import { getCurrentOrganizationId } from 'modules/user/selectors'
import SettingsSection from 'modules/settings/components/settings-section'
// import EetSettings from 'components/eet-settings'
import CashbotContainer from 'modules/settings/containers/cashbot'
import type { SubSectionType } from './settings-layout'
import { removeOrganization } from 'modules/organization/actions'
import { navigateToMainScreen } from 'modules/navigation/actions'
import OrganizationEdit from '../components/trivi-settings/organization-edit'

type Props = {|
	...WithRouterProps,
	...WithOrganizationSettingsProps,
	subSection?: SubSectionType,
	currentUserIsInternal: boolean,
	currentUserIsAdmin: boolean,
	currentUserHasInternalFullAccess: boolean,
	organization: ?Organization,
	logoBinary: Base64Image,
	loading: boolean,
	organizationId: string,
	changeOrganizationAddress: (address: OrganizationAddress) => void,
	removeOrganizationAddress: (address: OrganizationAddress) => void,
	...WithTranslateProps,
|}

type StateProps = {|
	organizationId: string,
	companyTypes: ?Array<CompanyType>,
|}

type DispatchProps = {|
	removeOrganization: (organizationId?: string) => Promise<any>,
|}

const mapStateToProps = (state: State): StateProps => {
	return {
		organizationId: getCurrentOrganizationId(state) || '',
		companyTypes: state.common.companyTypes,
	}
}

const mapDispatchToProps = (dispatch: Dispatch<UserAction | UserAction>): DispatchProps => {
	return {
		removeOrganization: async (organizationId?: string) => {
			const response: any = await dispatch(removeOrganization(organizationId))
			const removed: boolean = response.serverError === undefined
			if (removed) {
				await dispatch(navigateToMainScreen())
				window.location.reload()
			}
			return removed
		},
	}
}

class CompanyPage extends Component<Props> {
	componentDidMount() {
		if (!this.props.organizationSettings) {
			this.props.loadOrganizationSettings()
		}
	}

	getToUrl = (url: string) => () => {
		this.props.history.push(url)
	}

	handleUpdateOrganizationSettings = (newSettings: OrganizationSettings) => {
		if (this.props.organizationSettings) {
			this.props.updateOrganizationSettings(this.props.organizationSettings, newSettings)
		}
	}

	// onEETSettingsChange = async (newEet: ?FormEetSettings) => {
	// 	const settings: ?OrganizationSettings = this.props.organizationSettings
	// 	if (settings) {
	// 		const oldOrgSettings: OrganizationSettings = settings
	//
	// 		// $FlowFixMe
	// 		const updated: OrganizationCountrySpecificSettingsCzEet = {
	// 			...(oldOrgSettings &&
	// 				oldOrgSettings.countrySpecificSettings &&
	// 				oldOrgSettings.countrySpecificSettings.cz &&
	// 				oldOrgSettings.countrySpecificSettings.cz.eet),
	// 		}
	//
	// 		if (updated && newEet && newEet.place) updated.place = newEet.place
	// 		if (updated && newEet && newEet.password) updated.password = newEet.password
	// 		if (updated && newEet && newEet.certificate) updated.certificate = newEet.certificate
	//
	// 		//set new EET settings
	// 		const newOrgSettings: OrganizationSettings = Object.freeze({
	// 			...oldOrgSettings,
	// 			countrySpecificSettings: {
	// 				...oldOrgSettings.countrySpecificSettings,
	// 				cz: {
	// 					...(oldOrgSettings.countrySpecificSettings && oldOrgSettings.countrySpecificSettings.cz),
	// 					eet: updated,
	// 				},
	// 			},
	// 		})
	//
	// 		await this.props.updateOrganizationSettings(settings, newOrgSettings)
	// 	}
	// }

	render() {
		const { t, organizationSettings } = this.props

		return (
			<div>
				<SettingsSection
					visible={this.props.subSection === 'basics'}
					title={t('settings.basics.title')}
					subTitle={t('settings.basics.subTitle')}
					onToggle={this.props.subSection !== 'basics' ? this.getToUrl(settingsBasicsRoute()) : undefined}
				>
					<DefaultSettings
						organization={this.props.organization}
						organizationId={this.props.organizationId}
						organizationSettings={this.props.organizationSettings}
						logoBinary={this.props.logoBinary}
						loading={this.props.loading}
						currentUserIsInternal={this.props.currentUserIsInternal}
						currentUserIsAdmin={this.props.currentUserIsAdmin}
						updateOrganizationSettings={this.handleUpdateOrganizationSettings}
						changeOrganizationAddress={this.props.changeOrganizationAddress}
						removeOrganizationAddress={this.props.removeOrganizationAddress}
						uploadOrganizationLogo={this.props.uploadOrganizationLogo}
						removeOrganizationLogo={this.props.removeOrganizationLogo}
						getOrganizationLogo={this.props.getOrganizationLogo}
						removeOrganization={this.props.removeOrganization}
						companyTypes={this.props.companyTypes}
					/>
				</SettingsSection>

				{this.props.currentUserIsInternal && (
					<SettingsSection
						visible={this.props.subSection === 'eso'}
						title={t('settings.eso.title')}
						subTitle={t('settings.eso.subTitle')}
						onToggle={this.props.subSection !== 'eso' ? this.getToUrl(settingsEsoRoute()) : undefined}
					>
						<EsoContainer />
					</SettingsSection>
				)}

				<SettingsSection
					visible={this.props.subSection === 'responsibility'}
					title={t('settings.responsibility.title')}
					subTitle={t('settings.responsibility.subTitle')}
					onToggle={
						this.props.subSection !== 'responsibility' ? this.getToUrl(settingsResponsibilityRoute()) : undefined
					}
				>
					<StatutoryAuthorityContainer />
				</SettingsSection>

				<SettingsSection
					visible={this.props.subSection === 'signatures'}
					title={t('settings.signatures.title')}
					subTitle={t('settings.signatures.subTitle')}
					onToggle={this.props.subSection !== 'signatures' ? this.getToUrl(settingsSignaturesRoute()) : undefined}
				>
					<Signatures />
				</SettingsSection>

				{/*<SettingsSection*/}
				{/*	visible={this.props.subSection === 'eet'}*/}
				{/*	title={t('settings.eet.title')}*/}
				{/*	onToggle={this.props.subSection !== 'eet' ? this.getToUrl(settingsEetRoute()) : undefined}*/}
				{/*>*/}
				{/*	<EetSettings*/}
				{/*		onChange={this.onEETSettingsChange}*/}
				{/*		eet={*/}
				{/*			organizationSettings &&*/}
				{/*			organizationSettings.countrySpecificSettings &&*/}
				{/*			organizationSettings.countrySpecificSettings.cz &&*/}
				{/*			organizationSettings.countrySpecificSettings.cz.eet*/}
				{/*		}*/}
				{/*	/>*/}
				{/*</SettingsSection>*/}

				{(this.props.currentUserHasInternalFullAccess || this.props.isCashbotEnabled) && (
					<SettingsSection
						visible={this.props.subSection === 'cashbot'}
						title={t('cashbot.title')}
						subTitle={t('cashbot.subTitle')}
						onToggle={this.props.subSection !== 'cashbot' ? this.getToUrl(settingsCashbotRoute()) : undefined}
					>
						<CashbotContainer />
					</SettingsSection>
				)}

				{this.props.currentUserHasInternalFullAccess && (
					<SettingsSection
						visible={this.props.subSection === 'administration'}
						title={t('settings.administration.title')}
						onToggle={
							this.props.subSection !== 'administration' ? this.getToUrl(settingsAdministrationRoute()) : undefined
						}
					>
						<OrganizationEdit
							organizationId={this.props.organizationId}
							removeOrganization={this.props.removeOrganization}
							key={this.props.organizationId}
						/>
					</SettingsSection>
				)}
			</div>
		)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslate(CompanyPage))
