/* @flow */

import type {
	CheckedDocItem,
	Dispatch,
	DocumentAction,
	DocumentDescriptor,
	DocumentItem,
	FileAction,
	State,
} from 'types'
import {
	changeSearch,
	changeSort,
	createFolder,
	deleteDocumentItem,
	preloadDocumentFolder,
	loadDocuments,
	moveDocuments,
	refreshDocuments,
	searchDocuments,
	updateDocumentItem,
	uploadDocumentFiles,
} from '../actions'
import {
	getCurrentSortingDirection,
	getDocumentItem,
	getDocumentItemChildren,
	getDocumentItemCount,
	getDocumentItemCurrentPage,
	getDocumentItemLoading,
	getParentDocumentInfo,
} from '../selectors'
import DocumentList from '../components/document-list'
import { connect } from 'react-redux'
import { userHasAccess } from 'permissions'
import { currentUserIsInternal } from 'modules/user/selectors'

export type OwnProps = {|
	onNavigationChange: (itemId?: string) => any,
	onSearchTextChange?: (searchText: string) => void,
	itemId?: string,
	searchText?: string,
	showAddButton: boolean,
	showActions: boolean,
	onFileOpen: (file: DocumentItem) => void,
	onFileView?: (file: DocumentItem) => void,
	showFileCheckbox?: boolean,
	showFolderCheckbox?: boolean,
	onItemCheck?: (
		file: DocumentItem,
		checked: boolean,
		createCheckedDocItemWithDocItemOnly: (item: DocumentItem) => CheckedDocItem,
	) => void,
	checkedDocItems?: Array<CheckedDocItem>,
	showBreadcrumbs?: boolean,
	showFilter?: boolean,
	showHeader?: boolean,
	smallBreadcrumbs?: boolean,
	light?: boolean,
	deselectTooltip?: string,
	selectTooltip?: string,
	isAttachDocumentList?: boolean,
|}

type StateProps = {|
	canUserChange: boolean,
	canUserRemove: boolean,
	canUserRemoveToFolderSystemTrivi: boolean,
	searchText?: string,
	documents: Array<DocumentItem>,
	currentDocument: ?DocumentItem,
	parentDocumentInfo: ?DocumentDescriptor,
	pagerProps: {
		//pagesCount: number,
		//pageSize: number,
		itemsCount: number,
		currentPage: number,
	},
	sortingDirection: string,
	loading: boolean,
|}

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => {
	const searchText = ownProps.searchText || state.document.currentSearch
	return {
		canUserChange: userHasAccess(state, 'editDocuments'),
		canUserRemove: userHasAccess(state, 'removeDocuments'),
		canUserRemoveToFolderSystemTrivi: userHasAccess(state, 'removeDocuments') && currentUserIsInternal(state),

		searchText: searchText,
		documents: getDocumentItemChildren(state, ownProps.itemId, searchText),
		currentDocument: getDocumentItem(state, ownProps.itemId),
		parentDocumentInfo: getParentDocumentInfo(state, ownProps.itemId),
		pagerProps: {
			//pagesCount: Math.ceil(getDocumentItemCount(state, ownProps.itemId) / pageSize),
			//pageSize: pageSize,
			itemsCount: getDocumentItemCount(state, ownProps.itemId, searchText),
			currentPage: getDocumentItemCurrentPage(state, ownProps.itemId, searchText),
		},
		sortingDirection: getCurrentSortingDirection(state, ownProps.itemId),
		loading: getDocumentItemLoading(state, ownProps.itemId, searchText),
	}
}

type DispatchProps = {|
	saveItemMetadata: (item: DocumentItem) => Promise<void>,
	createFolder: (item: DocumentItem) => Promise<void>,
	uploadDocuments: (files: Array<File>) => Promise<void>,
	deleteDocument: (itemId: string, refreshGrid?: boolean) => Promise<void>,
	moveDocuments: (itemIds: Array<string>, target: string) => Promise<void>,
	loadDocuments: (
		parentId?: string,
		searchText: ?string,
		sortingDirection: 'ASC' | 'DESC',
		page: number,
		pageSize: number,
	) => Promise<void>,
	changeSort: (sortingDirection: 'ASC' | 'DESC') => Promise<void>,
	onNavigationChange?: (itemId?: string) => ?Promise<void>,
	onBreadcrumbItemClick: (itemId?: string) => Promise<void>,
	onGoToParent: (itemId?: string) => Promise<void>,
	onSearchTextChange?: (searchText: string) => ?Promise<void>,
|}
const mapDispatchToProps = (dispatch: Dispatch<FileAction | DocumentAction>, ownProps: OwnProps): DispatchProps => {
	return {
		saveItemMetadata: async (item: DocumentItem) => {
			await dispatch(updateDocumentItem(item))
			await dispatch(refreshDocuments(ownProps.itemId))
		},
		createFolder: async (item: DocumentItem) => {
			const itemId: ?string = ownProps.itemId

			if (itemId) {
				await dispatch(createFolder(itemId, item))
				await dispatch(refreshDocuments(ownProps.itemId))
			}
		},
		uploadDocuments: async (files: Array<File>) => {
			const itemId: ?string = ownProps.itemId

			if (itemId) {
				const documentItems = files.map((file: File) => {
					const documentItem: DocumentItem = {
						name: file.name,
						itemType: 0,
					}
					return {
						file: file,
						documentItem: documentItem,
					}
				})
				await dispatch(uploadDocumentFiles(itemId, documentItems))
				return await dispatch(refreshDocuments(itemId))
			} else {
				return Promise.resolve()
			}
		},
		deleteDocument: async (itemId: string, refreshGrid?: boolean) => {
			await dispatch(deleteDocumentItem(itemId))
			if (refreshGrid) await dispatch(refreshDocuments(ownProps.itemId))
		},
		moveDocuments: async (itemIds: Array<string>, target: string) => {
			await dispatch(moveDocuments(itemIds, target))
			await dispatch(refreshDocuments(ownProps.itemId))
		},
		loadDocuments: (
			parentId?: string,
			searchText: ?string,
			sortingDirection: 'ASC' | 'DESC',
			page: number,
			pageSize: number,
		) => {
			if (searchText) {
				return dispatch(searchDocuments(searchText, sortingDirection, page, pageSize))
			} else {
				return dispatch(loadDocuments(parentId, sortingDirection, page, pageSize))
			}
		},
		changeSort: async (sortingDirection: 'ASC' | 'DESC') => {
			await dispatch(changeSort(ownProps.itemId, sortingDirection))
			await dispatch(refreshDocuments(ownProps.itemId))
		},
		onBreadcrumbItemClick: async (itemId?: string) => {
			dispatch(changeSearch(''))
			ownProps.onNavigationChange(itemId)
		},
		onGoToParent: async (itemId?: string) => {
			dispatch(changeSearch(''))
			ownProps.onNavigationChange(itemId)
		},
		onNavigationChange: async (itemId?: string) => {
			dispatch(changeSearch(''))
			if (ownProps.itemId && itemId) dispatch(preloadDocumentFolder(ownProps.itemId, itemId))
			ownProps.onNavigationChange(itemId)
		},
		onSearchTextChange: async (searchText: string) => {
			dispatch(changeSearch(searchText))
			ownProps.onSearchTextChange && ownProps.onSearchTextChange(searchText)
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(DocumentList)
