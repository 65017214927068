// @flow

import React, { Component } from 'react'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import type { RelatedAccountingDocumentInfo } from 'types'
import DataGridPopover from 'modules/data-grid/components/data-grid-popover'
import RelatedAccountingDocument from './related-accounting-document'
import styles from './related-accounting-documents.css'

type Props = {|
	...WithTranslateProps,
	items: ?Array<RelatedAccountingDocumentInfo>,
	onClick: (accountingDocumentId: string) => void,
	showPopoverOnHover?: boolean,
|}

class RelatedAccountingDocuments extends Component<Props> {
	static defaultProps = {
		onClick: () => {},
	}

	render() {
		const { t, items, showPopoverOnHover, onClick } = this.props
		if (!items) return null
		const inline = items.length < 3 ? items : items ? items.slice(0, 1) : null
		const dropdown = items.length >= 3 ? items.slice(1) : null

		let result

		result =
			inline &&
			inline.map((item: RelatedAccountingDocumentInfo, index: number) => (
				<div key={index}>
					<RelatedAccountingDocument item={item} onClick={onClick} />
				</div>
			))

		result = (
			<div>
				{result}
				{dropdown && (
					<DataGridPopover
						label={t('grid.moreItemsOnClick', { count: dropdown.length })}
						showPopoverOnHover={showPopoverOnHover}
					>
						{dropdown.map((item: RelatedAccountingDocumentInfo, index: number) => (
							<div key={index} className={styles.item}>
								<RelatedAccountingDocument item={item} onClick={onClick} />
							</div>
						))}
					</DataGridPopover>
				)}
			</div>
		)

		return result
	}
}

export default withTranslate(RelatedAccountingDocuments)
