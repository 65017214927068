/* @flow */

import React, { Component } from 'react'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import SettingsButton from 'modules/settings/components/settings-button'
import { colors } from 'variables'

type Props = {|
	visible: boolean,
	title?: string,
	subTitle?: string,
	children: any,
	onToggle?: () => void,
	...WithTranslateProps,
|}

type State = {|
	visible: boolean,
|}

class SettingsSection extends Component<Props, State> {
	state: State = {
		visible: this.props.visible,
	}

	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		this.setState({ visible: nextProps.visible })
	}

	toggle = () => {
		this.props.onToggle && this.props.onToggle()
		this.setState({ visible: !this.state.visible })
	}

	renderTitles() {
		const styles = getStyles(this.props, this.state)

		return (
			<div style={styles.titles}>
				<div style={styles.title}>{this.props.title}</div>
				<div style={styles.subtitle}>{this.props.subTitle}</div>
			</div>
		)
	}

	render() {
		const { t } = this.props
		const styles = getStyles(this.props, this.state)

		return (
			<div style={styles.root}>
				<div style={styles.opening}>
					{this.renderTitles()}
					<div style={styles.button}>
						<SettingsButton
							labelText={this.state.visible ? t('settings.closeButton') : t('settings.changeButton')}
							onClick={this.toggle}
						/>
					</div>
				</div>
				<div style={styles.content}>{this.state.visible && <div style={styles.opened}>{this.props.children}</div>}</div>
			</div>
		)
	}
}

function getStyles(props: Props, state: State) {
	const { visible } = state

	return {
		root: {
			marginBottom: 20,
			fontSize: 0,
			boxShadow: visible ? '0.5px 2.5px 1.5px 0px rgba(0,0,0,0.14)' : '0 2px 2px 0 rgba(0,0,0,0.14)',
		},
		titles: {
			flex: '1 1 auto',
			paddingRight: 15,
			minWidth: 0,
		},
		title: {
			fontSize: 16,
			fontWeight: visible ? 'bold' : 'normal',
			color: visible ? colors.blue : colors.black,
			marginRight: 10,
			lineHeight: '20px',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',
			marginBottom: 5,
		},
		subtitle: {
			fontSize: 14,
			lineHeight: '17px',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',
			color: colors.grey,
		},
		content: {
			overflow: 'hidden',
		},
		button: {
			flex: '0 0 auto',
		},
		opening: {
			backgroundColor: colors.white,
			fontSize: 14,
			padding: 30,
			margin: 0,
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center',
			borderRadius: visible ? '2px 2px 0 0' : 2,
		},
		opened: {
			fontSize: 14,
			backgroundColor: colors.white,
			borderRadius: '0 0 2px 2px',
			padding: '20px 30px 40px 30px',
		},
	}
}

export default withTranslate(SettingsSection)
