/* @flow */

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import type { CommonAction, Dispatch, Enum, State } from 'types'
import TreeEnumSelector from 'components/tree-enum-selector'
import { loadTaxOffices } from 'modules/common/actions'

type StateProps = {|
	availableTaxOffices: ?Enum,
|}

const mapStateToProps = (state: State): StateProps => {
	return {
		availableTaxOffices: state.common.taxOffices,
	}
}

type DispatchProps = {|
	loadTaxOffices: () => void,
|}

const mapDispatchToProps = (dispatch: Dispatch<CommonAction>): DispatchProps => {
	return {
		loadTaxOffices: () => {
			dispatch(loadTaxOffices())
		},
	}
}

type OwnProps = {|
	disabled?: boolean,
	onChange: (paymentType: number) => void,
	value: ?number | ?string,
|}

export type ComponentProps = {| ...StateProps, ...DispatchProps, ...OwnProps |}

class TerritorialDivisionSelector extends Component<{| ...ComponentProps, ...WithTranslateProps |}> {
	componentDidMount() {
		if (!this.props.availableTaxOffices) {
			this.props.loadTaxOffices()
		}
	}

	onChange = (value: ?string | Array<string>) => {
		const parsedValue: ?string = Array.isArray(value) ? value[0] : value
		this.props.onChange(parseInt(parsedValue))
	}

	render() {
		const {
			t,
			availableTaxOffices,
			value,
			disabled,
			onChange, //eslint-disable-line
			loadTaxOffices, //eslint-disable-line
			...rest
		} = this.props

		return (
			<TreeEnumSelector
				{...rest}
				showCategoryLabels
				values={availableTaxOffices}
				value={value ? value.toString() : undefined}
				onChange={this.onChange}
				labelText={t('settings.basics.territorialDivisionId')}
				disabled={disabled}
				fullWidth
			/>
		)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslate(TerritorialDivisionSelector))
