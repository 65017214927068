import React from 'react'
import SvgIcon from 'components/SvgIcon'
import pure from 'recompose/pure'

let Chain = props => (
	<SvgIcon {...props}>
		<g stroke="none" strokeWidth="1" fillRule="evenodd">
			<path d="M16.375,8.25 C17.2343793,8.25 17.970049,8.55598652 18.5820312,9.16796875 C19.1940135,9.77995098 19.5,10.5156207 19.5,11.375 L19.5,12.625 C19.5,13.4843793 19.1940135,14.220049 18.5820312,14.8320312 C17.970049,15.4440135 17.2343793,15.75 16.375,15.75 L12,15.75 C10.6718684,15.75 9.72135703,15.1250063 9.1484375,13.875 C8.96614492,13.4322895 8.875,13.015627 8.875,12.625 L8.875,11.375 L10.75,11.375 L10.75,12.625 C10.75,12.9895852 10.8671863,13.2890613 11.1015625,13.5234375 C11.3359387,13.7578137 11.6354148,13.875 12,13.875 L16.375,13.875 C16.7395852,13.875 17.0390613,13.7578137 17.2734375,13.5234375 C17.5078137,13.2890613 17.625,12.9895852 17.625,12.625 L17.625,11.375 C17.625,11.0104148 17.5078137,10.7109387 17.2734375,10.4765625 C17.0390613,10.2421863 16.7395852,10.125 16.375,10.125 L15.75,10.125 C15.6718746,9.76041484 15.5286469,9.42187656 15.3203125,9.109375 C15.1119781,8.79687344 14.9166676,8.57552148 14.734375,8.4453125 L14.5,8.25 L16.375,8.25 Z" />
			<path d="M14.890625,10.125 C15.0468758,10.515627 15.125,10.9322895 15.125,11.375 L15.125,12.625 L13.25,12.625 L13.25,11.375 C13.25,11.0104148 13.1328137,10.7109387 12.8984375,10.4765625 C12.6640613,10.2421863 12.3645852,10.125 12,10.125 L7.625,10.125 C7.26041484,10.125 6.96093867,10.2421863 6.7265625,10.4765625 C6.49218633,10.7109387 6.375,11.0104148 6.375,11.375 L6.375,12.625 C6.375,12.9895852 6.49218633,13.2890613 6.7265625,13.5234375 C6.96093867,13.7578137 7.26041484,13.875 7.625,13.875 L8.25,13.875 C8.32812539,14.2395852 8.47135313,14.5781234 8.6796875,14.890625 C8.88802187,15.2031266 9.08333242,15.4244785 9.265625,15.5546875 L9.5,15.75 L7.625,15.75 C6.7656207,15.75 6.02995098,15.4440135 5.41796875,14.8320312 C4.80598652,14.220049 4.5,13.4843793 4.5,12.625 L4.5,11.375 C4.5,10.5156207 4.80598652,9.77995098 5.41796875,9.16796875 C6.02995098,8.55598652 6.7656207,8.25 7.625,8.25 L12,8.25 C12.6250031,8.25 13.2044244,8.42577949 13.7382812,8.77734375 C14.2721381,9.12890801 14.6562488,9.57812227 14.890625,10.125 Z" />
		</g>
	</SvgIcon>
)
Chain = pure(Chain)

export default Chain
