/* @flow */

import React, { Component } from 'react'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import type { DocumentItem } from 'types'
import ParentFolder from 'components/svg-icons/trivi/parent-folder'
import styles from './document-list-item.css'

export type Props = {|
	key?: string,
	id?: string,
	className?: string,
	style?: Object,
	labelText: string,
	onClick: (item: DocumentItem) => void,
	...WithTranslateProps,
|}

class DocumentParentItem extends Component<Props> {
	static defaultProps = {
		onClick: () => {},
	}

	render() {
		return (
			<div
				key={this.props.key}
				id={this.props.id}
				className={`${styles.normal} ${this.props.className || ''}`}
				onClick={this.props.onClick}
				style={this.props.style}
			>
				<span className={styles.icon}>
					<ParentFolder size={20} />
				</span>
				<span className={styles.parent}>{this.props.labelText}</span>
			</div>
		)
	}
}

export default withTranslate(DocumentParentItem)
