/* @flow */

import { forEach } from 'lodash-es'
import memoize from 'memoize-one'
import type {
	AccountingDocument,
	AccountingDocumentAssignedDirection,
	AccountingDocumentAssignedType,
	AccountingDocumentBankAccount,
	AccountingDocumentContact,
	AccountingDocumentDirection,
	AccountingDocumentType,
	AccountingDocumentVatRecapInfo,
	AccountingDocumentLineItem,
	GreenboxSuggestionLineItemRequest,
	GreenboxSuggestionRequest,
	BankAccount,
	ContactBankAccount,
	Contact,
	AresContact,
	UploadScanFile,
	PaymentMatch,
	CrpdphBankAccount,
	ContactAddress,
	EsoConnectionState,
	EsoConnectionStateNumber,
	Organization,
	OrganizationSettings,
	BankStatementStatus,
	TagType,
	TagTypeNumber,
	VatCountryType,
	PayerTaxType,
	AccountingDocumentProcessingState,
	AccountingDocumentTotals,
	RoundingTypeName,
	UnmatchedPaymentMatch,
	AccountingDocumentVatRecapLine,
	AccountingDocumentDirectionTypes,
} from 'types'

export const Contact_CopyContactAddressEmailToContact = (contact: Contact): Contact => {
	return {
		...contact,
		email: (Array.isArray(contact.addresses) && contact.addresses[0] && contact.addresses[0].email) || contact.email,
	}
}

export const Contacts_CopyContactAddressEmailToContact = (contacts: Array<Contact>): Array<Contact> => {
	return contacts.map(Contact_CopyContactAddressEmailToContact)
}

export const Contact_AccountingDocumentContact = (contact: Contact): AccountingDocumentContact => {
	return {
		contactId: contact.id,
		firstname: contact.firstName,
		lastname: contact.lastName,
		companyRegNo: contact.companyRegNo,
		companyName: contact.companyName,
		taxId: contact.taxId,
		externalId: contact.externalId,
		// email: contact.email,
		email: (Array.isArray(contact.addresses) && contact.addresses[0] && contact.addresses[0].email) || '',
		city: (Array.isArray(contact.addresses) && contact.addresses[0] && contact.addresses[0].city) || '',
		street: (Array.isArray(contact.addresses) && contact.addresses[0] && contact.addresses[0].street) || '',
		country: (Array.isArray(contact.addresses) && contact.addresses[0] && contact.addresses[0].country) || '',
		zipCode: (Array.isArray(contact.addresses) && contact.addresses[0] && contact.addresses[0].zipCode) || '',
	}
}

export const Contacts_AccountingDocumentContacts = (contacts: Array<Contact>): Array<AccountingDocumentContact> => {
	return (
		(Array.isArray(contacts) &&
			contacts.map((contact: Contact) => {
				return Contact_AccountingDocumentContact(contact)
			})) ||
		[]
	)
}

export const AccountingDocumentContact_ContactAddress = (accDocContact: AccountingDocumentContact): ContactAddress => {
	return {
		id: undefined, //?: string,
		type: 1, //1 | 2,
		email: undefined, //?: string,
		phone: undefined, //?: string,
		city: accDocContact.city,
		street: accDocContact.street,
		country: accDocContact.country,
		zipCode: accDocContact.zipCode,
	}
}

export const AccountingDocument_Totals = (accountingDocument: AccountingDocument): AccountingDocumentTotals => {
	const total: ?number = accountingDocument.total
	const totalVatExcl: ?number = accountingDocument.totalVatExcl
	const totalVatAmount: ?number = accountingDocument.totalVatAmount
	const roundingAmount: ?number = accountingDocument.roundingAmount
	return {
		total,
		totalVatExcl,
		roundingAmount,
		vatAmount: totalVatAmount,
		amountToPay: total,
	}
}

export const AccountingDocumentContact_Contact = (accDocContact: AccountingDocumentContact): Contact => {
	return {
		id: accDocContact.contactId,
		firstName: accDocContact.firstname,
		lastName: accDocContact.lastname,
		companyRegNo: accDocContact.companyRegNo,
		companyName: accDocContact.companyName,
		taxId: accDocContact.taxId,
		email: accDocContact.email,
		externalId: accDocContact.externalId,
		addresses: [AccountingDocumentContact_ContactAddress(accDocContact)],
		persons: undefined, //Array<ContactPerson>,
		bankAccounts: undefined, //Array<ContactBankAccount>,
		defaultDuePeriod: undefined, //number,
	}
}

export const BankAccount_AccountingDocumentBankAccount = (bankAccount: BankAccount): AccountingDocumentBankAccount => {
	return {
		number: bankAccount.accountNo,
		displayNumber: bankAccount.displayNumber,
		bankCode: bankAccount.accountCode,
		iban: bankAccount.accountIban,
		swift: bankAccount.accountSwift,
		bankAccountId: bankAccount.id,
		// localId?: string,
	}
}

export const BankAccounts_AccountingDocumentBankAccounts = (
	bankAccounts: Array<BankAccount>,
): Array<AccountingDocumentBankAccount> => {
	return (
		(Array.isArray(bankAccounts) &&
			bankAccounts.map((bankAccount: BankAccount) => {
				return BankAccount_AccountingDocumentBankAccount(bankAccount)
			})) ||
		[]
	)
}

export const ContactBankAccount_AccountingDocumentBankAccount = (
	contactBankAccount: ContactBankAccount,
): AccountingDocumentBankAccount => {
	return {
		number: contactBankAccount.accountNo,
		bankCode: contactBankAccount.accountCode,
		iban: contactBankAccount.accountIban,
		swift: contactBankAccount.accountSwift,
		// bankAccountId?: string,
		// localId?: string,
	}
}

export const ACCOUNTING_DOCUMENT_PROCESSING_STATES: { [key: string]: AccountingDocumentProcessingState } = {
	'0': 'notProcessed',
	'1': 'processed',
	'2': 'error',
	'3': 'waiting',
}

export const ACCOUNTING_DOCUMENT_TYPES: { [key: string]: AccountingDocumentType } = {
	'0': 'invoice',
	'1': 'advance',
	'2': 'tax_advance',
	'3': 'credit_note',
	'7': 'cash_receipt',
	'8': 'simplified_invoice',
	'9': 'unknown',
	'10': 'employee_advance',
	'11': 'cash_transfer',
	'12': 'cash_uncategorized',
}

export const ACCOUNTING_DOCUMENT_ASSIGNED_TYPES: { [key: string]: AccountingDocumentAssignedType } = {
	'0': 'invoice',
	'1': 'advance',
	'2': 'tax_advance',
	'3': 'credit_note',
	'7': 'cash_receipt',
	'8': 'simplified_invoice',
	'10': 'employee_advance',
	'11': 'cash_transfer',
	'12': 'cash_uncategorized',
}

export const ACCOUNTING_DOCUMENT_NAMES = {
	'0': 'Invoice',
	'1': 'Advance',
	'2': 'TaxAdvance',
	'3': 'CreditNote',
	'7': 'CashReceipt',
	'8': 'SimplifiedInvoice',
	'9': 'Unknown',
	'10': 'EmployeeAdvance',
	'11': 'CashTransfer',
	'12': 'CashUncategorized',
}

export const PAYMENT_TYPES: { [index: string]: string } = {
	'1': 'bankTransfer',
	'2': 'cash',
	'3': 'cashOnDelivery',
	'4': 'paymentCard',
	'5': 'setOff',
	'6': 'loan',
}

export const ACCOUNTING_DOCUMENT_KNOWN_DIRECTION_TYPES: AccountingDocumentDirectionTypes = {
	'0': 'received',
	'1': 'issued',
}

export const ACCOUNTING_DOCUMENT_DIRECTION_TYPES: AccountingDocumentDirectionTypes = {
	...ACCOUNTING_DOCUMENT_KNOWN_DIRECTION_TYPES,
	'2': 'unknown',
}

export const ACCOUNTING_DOCUMENT_ASSIGNED_DIRECTION_TYPES: { [key: string]: AccountingDocumentAssignedDirection } = {
	'0': 'received',
	'1': 'issued',
}

export const ROUNDING_TYPES: { [type: string]: RoundingTypeName } = {
	'1': 'none',
	'2': 'roundNearest',
	'3': 'roundUp',
	'4': 'roundDown',
}

export const BANK_STATEMENTS_STATUS_TYPES: { [key: string]: BankStatementStatus } = {
	'0': 'not_processed',
	'1': 'processed',
	'2': 'error',
	'3': 'in_progress',
}

export const VAT_COUNTRY_TYPES: { [key: string]: VatCountryType } = {
	'1': 'domestic',
	'2': 'eu',
	'3': 'foreign',
}

export const PAYER_TAX_TYPES: { [key: string]: PayerTaxType } = {
	'0': 'VATpayer',
	'1': 'VATfree',
	'3': 'identified',
}

const ESO_CONNECTION_STATES: { [EsoConnectionState]: EsoConnectionStateNumber } = {
	notConnected: 1,
	connected: 2,
	waitingForConnection: 3,
	disconnected: 4,
	connectionError: 5,
}

export function TagType_TagTypeNumber(tagType: TagType): TagTypeNumber {
	return {
		branch: 0,
		project: 1,
	}[tagType]
}

export function TagTypeNumber_TagType(tagTypeNumber: TagTypeNumber): TagType {
	return {
		'0': 'branch',
		'1': 'project',
	}[tagTypeNumber]
}

export function number_TagType(number: number): ?TagType {
	return {
		'0': 'branch',
		'1': 'project',
	}[number]
}

export function EsoConnectionState_Number(state: EsoConnectionState): EsoConnectionStateNumber {
	const found: ?EsoConnectionStateNumber = ESO_CONNECTION_STATES[state]
	if (found != null) {
		return found
	} else {
		console.error('Number_EsoConnectionState: "' + state + '" cannot be converted to number') //eslint-disable-line no-console
		return 5
	}
}

export function Number_EsoConnectionState(number: number): ?EsoConnectionState {
	// const found: ?EsoConnectionState = _.findKey(n => n === number)
	let found: ?EsoConnectionState = null
	forEach(ESO_CONNECTION_STATES, (n: EsoConnectionStateNumber, s: EsoConnectionState) => {
		if (n === number) {
			found = s
		}
	})
	return found
}

export function Number_AccountingDocumentType(type: ?number): ?AccountingDocumentType {
	if (type === null || type === undefined) {
		return null
	}
	return ACCOUNTING_DOCUMENT_TYPES['' + type] || null
}

export function Number_AccountingDocumentAssignedType(type: ?number): ?AccountingDocumentAssignedType {
	const result = Number_AccountingDocumentType(type)
	return result !== 'unknown' ? result : null
}

export function Number_AccountingDocumentDirection(direction: ?number): ?AccountingDocumentDirection {
	if (direction === null || direction === undefined) {
		return null
	}
	return ACCOUNTING_DOCUMENT_DIRECTION_TYPES['' + direction] || null
}

export function Number_AccountingDocumentAssignedDirection(direction: ?number): ?AccountingDocumentAssignedDirection {
	const result = Number_AccountingDocumentDirection(direction)
	return result !== 'unknown' ? result : null
}

export function AccountingDocumentType_Number(type: AccountingDocumentType): number {
	for (let number in ACCOUNTING_DOCUMENT_TYPES) {
		if (ACCOUNTING_DOCUMENT_TYPES[number] === type) {
			return parseInt(number)
		}
	}
	return -1
}

export function AccountingDocumentDirection_Number(direction: AccountingDocumentDirection): 0 | 1 | -1 {
	for (let number in ACCOUNTING_DOCUMENT_DIRECTION_TYPES) {
		if (ACCOUNTING_DOCUMENT_DIRECTION_TYPES[number] === direction) {
			const parsedNumber = parseInt(number)
			if (parsedNumber === 0 || parsedNumber === 1) {
				return parsedNumber
			}
		}
	}
	return -1
}

export function AccountingDocumentAssignedDirection_Number(direction: AccountingDocumentAssignedDirection): 0 | 1 {
	if (direction === 'issued') {
		return 1
	} else {
		return 0
	}
}

export function Number_PaymentType(paymentType: ?number): string {
	if (paymentType === null || paymentType === undefined) {
		return 'unknown'
	}
	return PAYMENT_TYPES['' + paymentType] || 'unknown'
}

export const Type_AccountingDocumentName = (type: ?number): ?string => {
	return type === undefined || type === null ? undefined : ACCOUNTING_DOCUMENT_NAMES[type.toString()]
}

export const Name_AccountingDocumentType = (docName: string): number => {
	const key = Object.keys(ACCOUNTING_DOCUMENT_NAMES).find(
		(index: string) => ACCOUNTING_DOCUMENT_NAMES[index] === docName,
	)
	return key ? parseInt(key) : -1
}

export function Type_AccountingDocumentDirectionName(type: ?number): ?AccountingDocumentAssignedDirection {
	return type === undefined || type === null ? undefined : ACCOUNTING_DOCUMENT_ASSIGNED_DIRECTION_TYPES[type.toString()]
}

export const AccountingDocument_AccountingDocumentVatRecapInfo = memoize(
	(
		vatRecapCalculationMode: ?number,
		vatRecap?: Array<AccountingDocumentVatRecapLine>,
		total?: number,
		totalVatExcl?: number,
	): ?AccountingDocumentVatRecapInfo => {
		return {
			vatRecapCalculationMode: (vatRecapCalculationMode !== undefined && vatRecapCalculationMode) || -1,
			vatRecapLines: vatRecap,
			total: total,
			totalVatExcl: totalVatExcl,
		}
	},
)

export function AresContact_AccountingDocumentContact(ac: AresContact): AccountingDocumentContact {
	return {
		companyRegNo: ac.companyRegNo,
		companyName: ac.companyName,
		firstname: ac.firstname,
		lastname: ac.lastname,
		taxId: ac.taxId,
		city: ac.address && ac.address.city,
		street: ac.address && ac.address.street,
		country: ac.address && ac.address.country,
		zipCode: ac.address && ac.address.zipCode,
	}
}

export function File_UploadScanFile(file: File, documentCategory: ?string): UploadScanFile {
	return {
		fileName: file.name,
		icon: mimeTypeIcon(file.type),
		mimeType: file.type,
		documentCategory,
	}
}

const mimeTypeIcon = (type: string): string => {
	let icon = ''
	switch (type) {
		case 'image/jpeg':
		case 'image/jpg':
		case 'image/png':
		case 'image/bmp':
		case 'image/gif':
			icon = 'photo'
			break
		case 'application/pdf':
			icon = 'picture_as_pdf'
			break
		default:
			icon = 'photo'
			break
	}
	return icon
}

export function AccountingDocument_PaymentMatch(accountingDocument: AccountingDocument): PaymentMatch {
	return {
		accountingDocumentId: accountingDocument.id,
		amount: accountingDocument.total || 0,
		// financialAccount?: string,
		// branch?: string,
		// project?: string,
		// note?: string,
	}
}

export function AccountingDocument_UnmatchedPaymentMatch(
	accountingDocument: AccountingDocument,
): UnmatchedPaymentMatch {
	return {
		accountingDocumentId: accountingDocument.id,
		amount: accountingDocument.total || 0,
		// financialAccount?: string,
		// branch?: string,
		// project?: string,
		// note?: string,
	}
}

export function AccountingDocumentBankAccount_ContactBankAccount(
	ba: AccountingDocumentBankAccount,
): ContactBankAccount {
	return {
		accountNo: ba.number,
		accountCode: ba.bankCode,
		accountSwift: ba.swift,
		accountIban: ba.iban,
		// countryVariantSpecific?: {|
		// 	cz?: {|
		// 		isRegistered?: boolean,
		// 	|},
		// |},
		// name?: string,
		// currency?: string,
	}
}

export function AccountingDocumentBankAccounts_ContactBankAccounts(
	bankAccounts: Array<AccountingDocumentBankAccount>,
): Array<ContactBankAccount> {
	return bankAccounts.map((ba: AccountingDocumentBankAccount) => AccountingDocumentBankAccount_ContactBankAccount(ba))
}

export function CrpdphBankAccount_ContactBankAccount(crpdph: CrpdphBankAccount): ContactBankAccount {
	return {
		accountNo: crpdph.accountNumber,
		accountCode: crpdph.accountCode,
	}
}

export function CrpdphBankAccounts_ContactBankAccounts(
	crpdphAccounts: Array<CrpdphBankAccount>,
): Array<ContactBankAccount> {
	return crpdphAccounts.map((crpdph: CrpdphBankAccount) => CrpdphBankAccount_ContactBankAccount(crpdph))
}

export function AccountingDocumentLineItem_GreenboxSuggestionLineItemRequest(
	lineItem: AccountingDocumentLineItem,
): GreenboxSuggestionLineItemRequest {
	return {
		uniqueId: lineItem.id || '',
		description: lineItem.description,
		total: lineItem.total,
	}
}

export function AccountingDocumentLineItems_GreenboxSuggestionLineItemRequests(
	accDocLineItems: Array<AccountingDocumentLineItem>,
): Array<GreenboxSuggestionLineItemRequest> {
	return accDocLineItems.map((accDocLineItem: AccountingDocumentLineItem) =>
		AccountingDocumentLineItem_GreenboxSuggestionLineItemRequest(accDocLineItem),
	)
}

export function AccountingDocument_GreenboxSuggestionRequest(
	accDoc: AccountingDocument,
	ownRegNo: ?string,
): GreenboxSuggestionRequest {
	return {
		ownRegNo: ownRegNo || undefined,
		vendorRegNo: accDoc.contact && accDoc.contact.companyRegNo,
		vendorName: accDoc.contact && accDoc.contact.companyName,
		accountingDocumentType: accDoc.type,
		accountingDocumentDirection: accDoc.direction,
		lineItems: AccountingDocumentLineItems_GreenboxSuggestionLineItemRequests(accDoc.lineItems || []),
	}
}

export function OrganizationSettings_Organization(settings: OrganizationSettings): Organization {
	return {
		name: settings.name,
		companyRegNo: settings.regNo,
		taxId: settings.taxId,
		accountingType: settings.accountingType,
		payerTaxType: settings.payerTaxType,
		vatPeriod: settings.vatPeriod,
		firstname: settings.firstName,
		lastname: settings.lastName,
		domesticCountry: settings.domesticCountry,
		domesticCurrency: settings.domesticCurrency,
	}
}

export function Number_BankStatementType(type: ?number): ?BankStatementStatus {
	if (type === null || type === undefined) {
		return null
	}
	return BANK_STATEMENTS_STATUS_TYPES['' + type] || null
}

export function VatRateType_VatRateName(type: ?number): ?VatCountryType {
	if (type === null || type === undefined) {
		return null
	}

	return VAT_COUNTRY_TYPES['' + type]
}

export function VatRateName_VatRateType(name: VatCountryType): number {
	const key = Object.keys(VAT_COUNTRY_TYPES).find((index: string) => VAT_COUNTRY_TYPES[index] === name)
	return key ? parseInt(key) : -1
}

export function getVatRateTypesArray(): Array<{ value: number, name: VatCountryType }> {
	return Object.keys(VAT_COUNTRY_TYPES).map((index: string) => ({
		value: parseInt(index),
		name: VAT_COUNTRY_TYPES[index],
	}))
}

export function PayerTaxType_PayerTaxName(type: ?number): ?PayerTaxType {
	if (type === null || type === undefined) {
		return null
	}

	return PAYER_TAX_TYPES['' + type]
}

export function PayerTaxName_PayerTaxType(name: PayerTaxType): number {
	const key = Object.keys(PAYER_TAX_TYPES).find((index: string) => PAYER_TAX_TYPES[index] === name)
	return key ? parseInt(key) : -1
}

export function RoundingTypeName_RoundingType(name: RoundingTypeName): number {
	const key = Object.keys(ROUNDING_TYPES).find((index: string) => ROUNDING_TYPES[index] === name)
	return key ? parseInt(key) : -1
}

export function RoundingType_RoundingTypeName(type: ?number): ?RoundingTypeName {
	if (type === null || type === undefined) {
		return null
	}
	return ROUNDING_TYPES['' + type]
}

export function AccDocProcessingState_AccDocProcessingStateName(number: ?number): ?AccountingDocumentProcessingState {
	if (number === null || number === undefined) {
		return null
	}
	return ACCOUNTING_DOCUMENT_PROCESSING_STATES[number + '']
}

export function AccDocProcessingStateName_AccDocProcessingState(name: ?AccountingDocumentProcessingState): number {
	const foundKey = Object.keys(ACCOUNTING_DOCUMENT_PROCESSING_STATES).find(
		(key: string) => ACCOUNTING_DOCUMENT_PROCESSING_STATES[key] === name,
	)
	return foundKey ? parseInt(foundKey) : -1
}
