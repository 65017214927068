//@flow

import { routerActions } from 'react-router-redux'
import { connectedReduxRedirect } from 'redux-auth-wrapper/history4/redirect'
import locationHelperBuilder from 'redux-auth-wrapper/history4/locationHelper'
import { userIsNotLoggedAsDemo } from 'modules/common/selectors'
import { getMainScreenRoute } from 'modules/navigation/selectors'
import { currentUserIsInternal } from 'modules/user/selectors'

import type { State } from 'types'
import { getIsBootstrapLoaded } from '../modules/user/selectors'
import { getAccessToken } from 'utils/local-storage'

declare var DEMO_CREDENTIALS: {
	email: string,
	password: string,
}

const locationHelper = locationHelperBuilder({})

function hasInternalPermission(state: any) {//eslint-disable-line
	return currentUserIsInternal(state)
}

export const UserIsAuthenticated = connectedReduxRedirect({
	authenticatedSelector: () => !!getAccessToken(),
	wrapperDisplayName: 'UserIsAuthenticated',
	redirectPath: '/login',
	allowRedirectBack: true,
	redirectAction: routerActions.replace,
})

export const UserIsNotAuthenticated = connectedReduxRedirect({
	authenticatedSelector: (state: State) => !state.user || !getAccessToken() || !getIsBootstrapLoaded(state),
	wrapperDisplayName: 'UserIsNotAuthenticated',
	redirectPath: (state: State, ownProps: Object) => {
		const redirectQueryParam = locationHelper.getRedirectQueryParam(ownProps)
		if (!!redirectQueryParam && redirectQueryParam != '/') {
			return redirectQueryParam
		} else {
			return getMainScreenRoute(state)
		}
	},
	allowRedirectBack: false,
	redirectAction: routerActions.replace,
})

export const UserInternalPermission = connectedReduxRedirect({
	authenticatedSelector: (state: State) => state.user && hasInternalPermission(state),
	wrapperDisplayName: 'UserInternalPermission',
	redirectPath: (state: State) => getMainScreenRoute(state),
	redirectAction: routerActions.replace,
	allowRedirectBack: false,
})

export const UserIsNotLoggedAsDemo = connectedReduxRedirect({
	authenticatedSelector: (state: State) => !userIsNotLoggedAsDemo(DEMO_CREDENTIALS, state),
	wrapperDisplayName: 'UserIsNotLoggedAsDemo',
	redirectPath: (state: State) => getMainScreenRoute(state),
	redirectAction: routerActions.replace,
	allowRedirectBack: false,
})
