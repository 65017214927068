/* @flow */

import { connect } from 'react-redux'
import type { AccountingDocument, AccountingDocumentNote, State } from 'types'
import { currentUserIsInternal } from 'modules/user/selectors'
import Note from 'modules/accounting-document/components/invoice-elements/note'
import { getAccountingDocument, getIsAccDocNoteDisabled } from 'modules/accounting-document/selectors'
import { setAccountingDocumentOneNote, deleteAllAccountingDocumentNotes } from 'modules/accounting-document/actions'

type OwnProps = {|
	accountingDocumentId: string,
	readonly?: boolean,
	isOpen?: boolean,
|}

const mapStateToProps = (state: State, ownProps: OwnProps) => {
	const document: ?AccountingDocument = getAccountingDocument(state, ownProps.accountingDocumentId)
	const internalUser = currentUserIsInternal(state)
	const disabled = getIsAccDocNoteDisabled(state, ownProps.accountingDocumentId)

	return {
		note: document && document.notes && document.notes[0],
		extractingNote: internalUser
			? document && document._extracting && document._extracting.notes && document._extracting.notes[0]
			: null,
		me: state.user.me.data,
		disabled,
	}
}

const mapDispatchToProps = (dispatch: Dispatch<>, ownProps: OwnProps) => {
	return {
		updateNote: (newNote: ?AccountingDocumentNote) => {
			if (newNote && newNote.text) {
				dispatch(setAccountingDocumentOneNote(ownProps.accountingDocumentId, newNote))
			} else {
				dispatch(deleteAllAccountingDocumentNotes(ownProps.accountingDocumentId))
			}
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Note)
