/* @flow */

import type {
	Dispatch,
	NavigationAction,
	OrganizationAction,
	Task,
	TasksSearchResult,
	UserAction,
	UserProfile,
	State,
	TaskAction,
	AccountingDocumentReduxAction,
	OrganizationMember,
	DocumentExtractionAction,
	InternalDashboardAction,
} from 'types'
import InternalDashboard from 'modules/internal/pages/internal-dashboard'
import { connect } from 'react-redux'
import Tracking from 'utils/tracking'
import withAutoload from 'wrappers/with-autoload'
import { beginTask, endTask } from 'utils/loader'
import { changeCurrentOrganization, logout, login } from 'modules/user/actions'
import { push } from 'react-router-redux'
import { searchMyTasks } from 'modules/task/actions'
import { taskRoute } from 'modules/task/routing/routes'
import { getAccountingDocumentsToExtractCount } from 'modules/accounting-document/actions'
import { type FinishGettingAccountingDocumentsToExtractCount } from 'modules/accounting-document/actions/accounting-document-action-types'
import { userHasAccess } from 'permissions'
import { nextDocumentExtraction } from 'modules/document-extraction/actions'
import { impersonateToken } from '../actions'
import { getCurrentUserOrganizations } from '../../user/selectors'

type StateProps = {|
	me: ?UserProfile,
	myTasks: ?TasksSearchResult,
	organizations: ?Array<OrganizationMember>,
	showExtract: boolean,
	showEditInternalUsers: boolean,
	canLogInAsUser: boolean,
|}

const mapStateToProps = (state: State): StateProps => {
	return {
		me: state.user.me.data,
		myTasks: state.task.myTasks,
		organizations: getCurrentUserOrganizations(state),
		showExtract: userHasAccess(state, 'fetchAccountingDocumentsForExtraction'),
		showEditInternalUsers: userHasAccess(state, 'editInternalUsers'),
		canLogInAsUser: userHasAccess(state, 'logInAsUser'),
	}
}

type DispatchProps = {|
	changeCurrentOrganization: (organizationId: string) => Promise<void>,
	onTaskClick: (task: Task) => void,
	autoload: () => void,
	extractNextAccountingDocument: () => void,
	getAccountingDocumentsToExtractCount: () => Promise<number>,
	logAsUser: (userName: string) => Promise<void>,
|}

type Action =
	| UserAction
	| OrganizationAction
	| NavigationAction
	| TaskAction
	| AccountingDocumentReduxAction
	| DocumentExtractionAction
	| InternalDashboardAction

const mapDispatchToProps = (dispatch: Dispatch<Action>): DispatchProps => {
	return {
		changeCurrentOrganization: async (organizationId: string) => {
			const loaderId = 'internalDashboard_changeCurrentOrganization'
			beginTask(loaderId)
			dispatch(changeCurrentOrganization(`${organizationId}`)).finally(() => endTask(loaderId))
		},
		onTaskClick: (task: Task) => {
			if (task.id != null) {
				dispatch(push(taskRoute(task.id)))
			}
		},
		autoload: () => {
			const loaderId = 'internalDashboard_autoload'
			beginTask(loaderId)
			dispatch(searchMyTasks({})).finally(() => endTask(loaderId))
		},
		extractNextAccountingDocument: () => {
			dispatch(nextDocumentExtraction())
		},
		getAccountingDocumentsToExtractCount: async (): Promise<number> => {
			const resp: FinishGettingAccountingDocumentsToExtractCount = await dispatch(
				getAccountingDocumentsToExtractCount(),
			)
			return resp.count || 0
		},
		logAsUser: async (username: string): Promise<void> => {
			const action = await dispatch(impersonateToken(username))
			if (action.token) {
				await dispatch(logout())
				Tracking.impersonate()
				dispatch(push('/'))
				dispatch(login(username, action.token))
			}
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(withAutoload(InternalDashboard))
