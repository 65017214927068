/* @flow */
/** @jsx jsx */

import React, { Component } from 'react'
import { jsx } from '@emotion/core'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import type { TriviStoreCard as TriviStoreCardType } from 'types'
import Paper from 'components/Paper'
import { colors } from 'variables'

type Props = {|
	...WithTranslateProps,
	card: TriviStoreCardType,
	onClick: TriviStoreCardType => void,
|}

class TriviStoreCard extends Component<Props> {
	onClick = () => {
		this.props.onClick(this.props.card)
	}

	render() {
		const { t, card } = this.props

		return (
			<div css={styles.root}>
				<Paper zDepth={2} onClick={this.onClick}>
					<div css={styles.content}>
						<div css={styles.children}>
							<div css={styles.inner}>{card.content}</div>
						</div>
						<div css={styles.bottom}>
							<div css={styles.inner}>
								{card.offer ? (
									<div css={styles.offer}>{t(card.offer)}</div>
								) : (
									<>
										<div css={styles.price}>{t(card.price)}</div>
										{card.unit && <div css={styles.unit}>{t(card.unit)}</div>}
									</>
								)}
							</div>
						</div>
					</div>
				</Paper>
			</div>
		)
	}
}

const styles = {
	root: {
		display: 'block',
		cursor: 'pointer',

		'&:hover': {
			'> div': {
				boxShadow: '4px 4px 0 rgba(0,0,0,0.08)',
				borderColor: colors.blackFaded40,
			},
		},
	},
	content: {
		display: 'block',
		position: 'relative',
	},
	children: {
		position: 'relative',
		paddingBottom: '48%',
	},
	bottom: {
		margin: '0 15px',
		position: 'relative',
		paddingBottom: '32%',
		borderTop: `1px solid ${colors.grey400Faded40}`,
	},
	inner: {
		position: 'absolute',
		top: 0,
		left: 0,
		bottom: 0,
		right: 0,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		overflow: 'hidden',
	},
	price: {
		fontSize: 40,
		fontWeight: 700,
		margin: '0 8px',
	},
	offer: {
		fontSize: 30,
		fontWeight: 700,
		margin: '0 8px',
	},
	unit: {
		fontSize: 18,
		lineHeiht: '22px',
		margin: '0 8px',
	},
}

export default withTranslate(TriviStoreCard)
