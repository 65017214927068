/* @flow */

import type { Task, TasksSearchResult } from 'types'

import type { Action } from '../actions/action-types'
import type { TaskView } from 'modules/task/types'

export type TaskListState = { [taskId: string]: TaskState }
type Dictionary<K, T> = { [key: K]: T }

export type TaskState = {
	id: string,
	loading: boolean,
	updating: boolean,
	removing: boolean,
	data?: Task,
}

export type State = {
	task: Dictionary<string, TaskState>,
	creating: boolean,
	listView: TaskView,
	myTasks: ?TasksSearchResult,
}

export const initialState: State = {
	task: {},
	creating: false,
	listView: 'assignedToMe',
	myTasks: null,
}

export default (state: State = initialState, action: Action): State => {
	switch (action.type) {
		case 'FINISH_SEARCH_TASKS': {
			return {
				...state,
				myTasks: action.response,
			}
		}

		case 'START_LOAD_TASK': {
			const taskListState: TaskListState = { ...state.task }
			taskListState[action.id] = {
				...taskListState[action.id],
				...{
					id: action.id,
					loading: true,
				},
			}
			return { ...state, ...{ task: taskListState } }
		}

		case 'FINISH_LOAD_TASK': {
			const taskListState: TaskListState = { ...state.task }
			taskListState[action.id] = {
				...taskListState[action.id],
				...{
					id: action.id,
					loading: false,
				},
				...(action.task ? { data: action.task } : {}),
			}
			return { ...state, ...{ task: taskListState } }
		}

		case 'START_CREATE_TASK': {
			return { ...state, ...{ creating: true } }
		}

		case 'FINISH_CREATE_TASK': {
			const taskListState: TaskListState = { ...state.task }
			if (action.task) {
				const taskId = action.task.id || ''
				taskListState[taskId] = {
					id: taskId,
					loading: false,
					updating: false,
					removing: false,
					data: action.task,
				}
			}
			return { ...state, ...{ task: taskListState, creating: false } }
		}

		case 'START_UPDATE_TASK': {
			const taskId = action.task.id || ''
			const taskListState: TaskListState = { ...state.task }
			taskListState[taskId] = {
				...taskListState[taskId],
				...{
					id: taskId,
					updating: true,
				},
			}
			return { ...state, ...{ task: taskListState } }
		}

		case 'FINISH_UPDATE_TASK': {
			const taskListState: TaskListState = { ...state.task }
			taskListState[action.id] = {
				...taskListState[action.id],
				...{
					id: action.id,
					updating: false,
				},
				...(action.task ? { data: action.task } : {}),
			}
			return { ...state, ...{ task: taskListState } }
		}

		case 'START_REMOVE_TASK': {
			const taskListState: TaskListState = { ...state.task }
			taskListState[action.id] = {
				...taskListState[action.id],
				...{
					id: action.id,
					removing: true,
				},
			}
			return { ...state, ...{ task: taskListState } }
		}

		case 'FINISH_REMOVE_TASK': {
			const taskListState: TaskListState = { ...state.task }

			if (action.success) {
				delete taskListState[action.id]
			} else {
				taskListState[action.id] = {
					...taskListState[action.id],
					...{
						id: action.id,
						loading: false,
					},
				}
			}
			return { ...state, ...{ task: taskListState } }
		}

		case 'CHANGE_TASK_LIST_VIEW': {
			return { ...state, ...{ listView: action.view } }
		}

		default:
			return state
	}
}
