/* @flow */

import React, { Component } from 'react'
import memoize from 'memoize-one'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import type { AccountingDocumentType, AccountingDocumentAssignedDirection, DocumentItem } from 'types'
import { PopupSection, Separator, TabSwitch, TabSwitches } from 'components'
import { AccountingDocumentType_Number } from 'types/convertor'
import AttachAccountingDocument from '../../containers/invoice-elements-document/attach-accounting-document'
import AttachDocument from '../../containers/invoice-elements-document/attach-document'
import AttachmentList from '../../containers/invoice-elements-document/attachment-list'
import FileViewer from 'modules/file/containers/file-viewer'
import styles from './attachments.css'
import { handleDownloadFile } from '../../../file/domain'

export const getAllowedAccountingDocumentTypes = memoize((type: AccountingDocumentType): ?Array<number> => {
	switch (type) {
		case 'invoice':
			return [AccountingDocumentType_Number('advance'), AccountingDocumentType_Number('tax_advance')]
		case 'tax_advance':
			return [AccountingDocumentType_Number('advance')]
		case 'credit_note':
			return [
				AccountingDocumentType_Number('invoice'),
				AccountingDocumentType_Number('simplified_invoice'),
				AccountingDocumentType_Number('tax_advance'),
			]
		case 'simplified_invoice':
			return [AccountingDocumentType_Number('advance')]
		default:
			return null
	}
})

export type Props = {|
	accountingDocumentId: string,
	type: AccountingDocumentType,
	direction?: AccountingDocumentAssignedDirection,
	showAccountingDocuments?: boolean,
	showDocuments?: boolean,
	showUpload?: boolean,
	onRequestClose?: () => void,
	readonly: boolean,
|}

type ComponentProps = Props & WithTranslateProps

type ComponentState = {
	selectedTabValue: number,
	selectedAccountingDocuments: Array<string>,
	selectedDocuments: Array<string>,
	fileIdForPreview: ?string,
}

class Attachments extends Component<ComponentProps, ComponentState> {
	static defaultProps = {
		showAccountingDocuments: true,
		showUpload: false,
	}

	allowedTypes: ?Array<number> = getAllowedAccountingDocumentTypes(this.props.type)

	state: ComponentState = {
		selectedTabValue: this.getInitialTab(this.allowedTypes),
		selectedAccountingDocuments: [],
		selectedDocuments: [],
		fileIdForPreview: null,
	}

	getInitialTab(allowedTypes: ?Array<number>): number {
		if (this.props.showAccountingDocuments && allowedTypes) {
			return 1
		}

		if (this.props.showDocuments) {
			return 2
		}

		if (this.props.showUpload) {
			return 3
		}

		return -1
	}

	renderTabs() {
		const { t } = this.props
		const tabs = []

		if (this.props.showAccountingDocuments && this.allowedTypes) {
			tabs.push(<TabSwitch label={t('attachments.papers')} value={1} key="tab1" />)
		}

		if (this.props.showDocuments) {
			tabs.push(<TabSwitch label={t('attachments.documents')} value={2} key="tab2" />)
		}

		if (this.props.showUpload) {
			tabs.push(<TabSwitch label={t('attachments.upload')} value={3} key="tab3" />)
		}

		return tabs.length <= 1 ? null : (
			<div className={styles.tabs}>
				<Separator
					thick
					left={
						<TabSwitches
							autoTestId="invoice-attachments-tabs"
							onChange={this.handleTabChange}
							value={this.state.selectedTabValue}
						>
							{tabs}
						</TabSwitches>
					}
				/>
			</div>
		)
	}

	renderContent() {
		return (
			<div className={styles.content}>
				{this.state.selectedTabValue === 1 && this.allowedTypes && (
					<AttachAccountingDocument
						accountingDocumentId={this.props.accountingDocumentId}
						type={this.props.type}
						allowedTypes={this.allowedTypes}
						direction={this.props.direction}
						readonly={this.props.readonly}
					/>
				)}

				{this.state.selectedTabValue === 2 && (
					<AttachDocument
						accountingDocumentId={this.props.accountingDocumentId}
						onFileOpen={this.handleOpenFilePreview}
						readonly={this.props.readonly}
					/>
				)}
			</div>
		)
	}

	handleOpenFilePreview = (file: DocumentItem) => {
		this.openFilePreview(file)
	}

	openFilePreview = async (file: DocumentItem) => {
		const res = file.fileId && file.name && (await handleDownloadFile(file.fileId, file.name))
		!res && this.setState({ fileIdForPreview: file.fileId })
	}

	closeFilePreview = () => {
		this.setState({ fileIdForPreview: null })
	}

	handleTabChange = (value: number) => {
		this.setState({ selectedTabValue: value })
	}

	onRequestClose = () => {}

	render() {
		const { t } = this.props
		return (
			<div className={styles.root}>
				<PopupSection open={this.state.fileIdForPreview !== null} onRequestClose={this.closeFilePreview}>
					<FileViewer stretch fileId={this.state.fileIdForPreview || ''} />
				</PopupSection>
				<div className={styles.container}>
					<div className={styles.header}>
						<div className={styles.h1}>{t('attachments.headline')}</div>
						{this.renderTabs()}
					</div>
					<div className={styles.body}>
						{this.renderContent()}
						<div className={styles.list}>
							<div className={styles.h2}>{t('attachments.selectedAttachments')}</div>
							<AttachmentList
								accountingDocumentId={this.props.accountingDocumentId}
								showAccountingDocuments={this.props.showAccountingDocuments}
								showDocuments={this.props.showDocuments}
								onRequestClose={this.props.onRequestClose || this.onRequestClose}
								readonly={this.props.readonly}
							/>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default withTranslate(Attachments)
