/* @flow */

import React, { Component, type Node } from 'react'
import type { SearchFilter } from 'types'
import TextField from 'components/TextField'

type Props = {|
	name: string,
	style?: {},
	className?: string,
	fieldStyle?: {},
	filter?: SearchFilter,
	hintText?: string,
	labelText?: string,
	clientError?: Node,
	inline?: boolean,
	onChange: (filter: SearchFilter) => void,
	onValueChange?: (event: SyntheticInputEvent<HTMLInputElement>, value: ?string) => void,
|}

type State = {|
	value: string,
|}

class FilterTextField extends Component<Props, State> {
	static defaultProps = {
		onChange: () => {},
	}

	state = {
		value: this.props.filter && this.props.filter.value ? this.props.filter.value.toString() : '',
	}

	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		if (this.props.filter !== nextProps.filter) {
			this.setState({
				value: ((nextProps.filter && nextProps.filter.value) || '').toString(),
			})
		}
	}

	handleTextFieldChange = (event: SyntheticInputEvent<HTMLInputElement>, value: ?string) => {
		this.setState({
			value: value || '',
		})

		this.props.onValueChange && this.props.onValueChange(event, value)
	}

	handleTextFieldBlur = () => {
		this.fireChange()
	}

	handleTextFieldEnter = () => {
		this.fireChange()
	}

	fireChange() {
		const value = this.state.value

		if ((!this.props.filter && value === '') || (this.props.filter && value === this.props.filter.value)) {
			return
		}

		const filter: SearchFilter = {
			field: this.props.name,
		}

		if (value) {
			filter.value = value
		}

		this.props.onChange(filter)
	}

	render() {
		return (
			<div className={this.props.className} style={this.props.style}>
				<TextField
					style={this.props.fieldStyle}
					onChange={this.handleTextFieldChange}
					onBlur={this.handleTextFieldBlur}
					onKeyEnter={this.handleTextFieldEnter}
					value={this.state.value}
					labelText={this.props.labelText}
					hintText={this.props.hintText}
					fullWidth
					compact
					name={this.props.name}
				/>
			</div>
		)
	}
}

export default FilterTextField
