/* @flow */

import React, { Component } from 'react'
import Popover from 'components/Popover'
import styles from './data-grid-popover.css'

type Props = {|
	children: any,
	label: string,
	className?: string,
	style?: Object,
	showPopoverOnHover?: boolean,
|}

type State = {|
	anchorEl: ?EventTarget,
	open: boolean,
	timeout?: ?TimeoutID,
|}

class DataGridPopover extends Component<Props, State> {
	anchorOrigin = { horizontal: 'middle', vertical: 'bottom' }
	targetOrigin = { horizontal: 'middle', vertical: 'top' }

	state = {
		anchorEl: null,
		open: false,
		timeout: null,
	}

	handleOnClick = () => {
		this.setState({
			anchorEl: this.refs.root,
			open: true,
		})
	}

	handleOnRequestClose = () => {
		this.props.showPopoverOnHover ? this.hidePopoverAfterTimeout() : this.hidePopoverImmediately()
	}

	onMouseEnter = () => {
		this.state.timeout && clearTimeout(this.state.timeout)
		this.handleOnClick()
	}

	onMouseLeave = () => {
		this.hidePopoverAfterTimeout()
	}

	hidePopoverAfterTimeout = () => {
		let timeout = setTimeout(this.hidePopoverImmediately, 100)
		this.setState({ timeout })
	}

	hidePopoverImmediately = () => {
		this.setState({ open: false })
	}

	render() {
		const { open, anchorEl } = this.state
		const { style, className, label, children, showPopoverOnHover } = this.props

		return (
			<div style={style} className={className}>
				<div
					className={open ? styles.openedLabel : styles.label}
					ref="root"
					onClick={!showPopoverOnHover && this.handleOnClick}
					onMouseEnter={showPopoverOnHover && this.onMouseEnter}
					onMouseLeave={showPopoverOnHover && this.onMouseLeave}
				>
					{label}
				</div>
				<Popover
					open={open}
					anchorEl={anchorEl}
					anchorOrigin={this.anchorOrigin}
					targetOrigin={this.targetOrigin}
					className={styles.popover}
					useLayerForClickAway={!showPopoverOnHover}
					onRequestClose={this.handleOnRequestClose}
					arrow={'top'}
					zDepth={5}
					onMouseEnter={showPopoverOnHover && this.onMouseEnter}
					onMouseLeave={showPopoverOnHover && this.onMouseLeave}
				>
					<div className={styles.children}>{children}</div>
				</Popover>
			</div>
		)
	}
}

export default DataGridPopover
