/* @flow */

import React, { Component } from 'react'
import type { FinishInternalCreate, Action as UserAction } from 'modules/user/actions/action-types'
import type { InternalUser, SignUpRequest } from 'types'
import { withTranslate, withNotify, type WithTranslateProps } from 'wrappers'
import { Avatar } from 'components'
import ContentAdd from 'components/svg-icons/content/add'
import InternalRegisterForm from 'modules/user/components/internal/internal-register-form'
import InternalUserGrid from './internal-user-grid'
import type { WithNotifyProps } from 'wrappers'
import { colors } from 'variables'
import { emptyInternalUser } from 'types/empty'
import styles from './internal-user-settings.css'
import Helmet from 'react-helmet'

type Props = {|
	...WithNotifyProps,
	...WithTranslateProps,
	updateInternalUser: (user: InternalUser) => ?Promise<UserAction>,
	signUpInternalUser: (request: SignUpRequest) => Promise<UserAction>,
	createInternalUser: (user: InternalUser) => ?Promise<FinishInternalCreate>,
	removeInternalUser: (id: string) => Promise<void>,
|}

type State = {|
	editUser: ?InternalUser,
|}

class InternalUsersSettings extends Component<Props, State> {
	gridRefresh: ?Function = null

	state: State = {
		editUser: null,
	}

	handleNew = () => {
		this.setState({ editUser: emptyInternalUser() })
	}

	handleEdit = (editUser: InternalUser) => {
		this.setState({ editUser })
	}

	handleSuccessUserEdit = () => {
		const { t } = this.props
		this.props.notify(t('internalUsers.userSaved'), 'success')
		this.setState({ editUser: null })
		this.gridRefresh != null && this.gridRefresh()
	}

	handleDialogClose = () => {
		this.setState({ editUser: null })
		this.gridRefresh != null && this.gridRefresh()
	}

	getRefresh = (refresh: Function) => {
		this.gridRefresh = refresh //HACK
	}

	render() {
		const { t } = this.props

		return (
			<div className={styles.root}>
				<Helmet>
					<title>{t('internalUsers.header')}</title>
				</Helmet>
				<InternalRegisterForm
					editedUser={this.state.editUser}
					onInternalUserUpdate={this.props.updateInternalUser}
					onInternalUserSignUp={this.props.createInternalUser}
					onSuccess={this.handleSuccessUserEdit}
					open={!!this.state.editUser}
					onRequestClose={this.handleDialogClose}
				/>
				<div className={styles.header}>
					<h1 className={styles.headline}>{t('internalUsers.header')}</h1>
					<div className={styles.add} onClick={this.handleNew}>
						{t('user.internal.new')}
						<Avatar
							className={styles.addAvatar}
							size={32}
							backgroundColor={colors.orange}
							borderColor={colors.orange600}
							icon={<ContentAdd className={styles.addIcon} hoverColor={colors.white} />}
						/>
					</div>
				</div>

				<div className={styles.container}>
					<div className={styles.row}>
						<div className={styles.full}>
							<InternalUserGrid
								onEdit={this.handleEdit}
								removeInternalUser={this.props.removeInternalUser}
								getRefresh={this.getRefresh}
							/>
						</div>
					</div>
				</div>
			</div>
		)
	}
}
export default withTranslate(withNotify(InternalUsersSettings))
