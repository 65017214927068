/* @flow */

import BankAccountDetail from '../components/bank-account-detail'
import type { Props as ComponentProps } from '../components/bank-account-detail'
import type { State, BankAccount } from 'types'
import { bankAccountEditRoute } from 'modules/bank/routing/routes'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { userHasAccess } from 'permissions'

type Props = {|
	id: string,
|}

type StateProps = {|
	bankAccount: ?BankAccount,
	showEdit: boolean,
|}

const mapStateToProps = (state: State): StateProps => {
	const bankAccount = state.bank.bankAccount.data
	return {
		bankAccount: bankAccount,
		showEdit: userHasAccess(state, 'editBankAccounts'),
	}
}

type DispatchProps = {|
	onEdit: () => void,
|}

const mapDispatchToProps = (dispatch: any, ownProps: Props): DispatchProps => {
	return {
		onEdit: (): void => {
			dispatch(push(bankAccountEditRoute(ownProps.id)))
		},
	}
}

const mergeProps = (stateProps: StateProps, dispatchProps: DispatchProps): ComponentProps => {
	return {
		bankAccount: stateProps.bankAccount,
		onEdit: dispatchProps.onEdit,
		showEdit: stateProps.showEdit,
	}
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(BankAccountDetail)
