//@flow
/** @jsx jsx */

import { jsx } from '@emotion/core'
import type { ContactAddress } from 'types'
import Tooltip from 'components/tooltip'

export function formatContactAddress(address: ?ContactAddress, compact?: boolean, tooltip?: string) {
	if (!address) return null

	let cityArr = []
	address.zipCode && cityArr.push(address.zipCode)
	address.city && cityArr.push(address.city)
	address.country && cityArr.push(address.country)

	const showSeparator = !!address.street && cityArr.length > 0

	return (
		<Tooltip disabled={!compact} label={tooltip} align={'center-left'} arrowAlign={'center-right'}>
			<div css={style.container}>
				{address.street}
				{showSeparator && (compact ? ', ' : <br />)}
				{cityArr.map((val: string, i: number) => {
					return i === 0 ? val : ', ' + val
				})}
			</div>
		</Tooltip>
	)
}

const style = {
	container: {
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
	},
}
