/* @flow */

import { find } from 'lodash-es'
import React, { Component } from 'react'
import type {
	AccountingDocumentScan,
	ScanFactoryBbWord,
	ScanFactoryDocumentFileMetadata,
	ScanFactoryPage,
	ScanFactoryField,
} from 'types'
import { getRelatedFieldCursors, isSomeFieldCursorEqual } from '../domain/field'
import { isResolvable } from '../domain/bbword'
import { getPreviewImageUrl } from '../domain/preview'
import type { BbWordCursor, BbWordFieldMap, FieldCursor } from '../types'
import Paper from 'components/Paper'
import ImageViewer from 'components/image-viewer'
import styles from './document-preview.css'
import DocumentFieldName from './document-field-name'
import FileViewer from 'modules/file/containers/file-viewer'

export type Props = {|
	metadata: ScanFactoryDocumentFileMetadata,
	accountingDocumentScans: ?Array<AccountingDocumentScan>,
	documentId: string,
	bbWordFieldMap: BbWordFieldMap,
	fieldCursor: FieldCursor,
	field: ?ScanFactoryField,
	highlightBbWords: ?Array<BbWordCursor>,
	width: ?number,
	onBbWordClick: (bbWord: ScanFactoryBbWord, { metaKey: boolean }) => void,
	onPreviewClick: () => void,
	onPreviewShiftClick: () => void,
	onBbWordMouseOver: (bbWord: BbWordCursor) => void,
	onBbWordMouseOut: (bbWord: BbWordCursor) => void,
	onPreviewResize: (size: number) => void,
|}

export type State = {|
	width: ?number,
	dragging: boolean,
	hintPosition: {
		x: number,
		y: number,
	},
|}

class DocumentPreview extends Component<Props, State> {
	root: ?HTMLElement

	constructor(props: Props) {
		super(props)
		this.state = {
			width: this.props.width,
			dragging: false,
			hintPosition: {
				x: 0,
				y: 0,
			},
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		if (this.props.width !== nextProps.width) {
			this.setState({ width: nextProps.width })
		}
	}

	componentDidMount() {
		document.addEventListener('mousemove', this.handleMouseMove)
	}

	componentWillUnmount() {
		document.removeEventListener('mousemove', this.handleMouseMove)
		document.removeEventListener('mouseup', this.handleMouseEnd)
	}

	render() {
		return (
			<div
				className={styles.root}
				ref={this.setRootRef}
				style={{
					width: this.state.width || 'auto',
				}}
			>
				{this.props.metadata.success ? this.renderPages() : this.renderFallbackFileViewer()}
				<div className={styles.resizer} onMouseDown={this.handleMouseDown}>
					<div className={styles.resizerBar} />
				</div>
			</div>
		)
	}

	renderPages() {
		return (
			<Paper className={styles.preview}>
				{this.props.metadata.pages && this.props.metadata.pages.map(this.renderImageViewer)}
				<div className={styles.hintContainer}>
					<div
						className={styles.hint}
						style={{
							left: this.state.hintPosition.x,
							top: this.state.hintPosition.y,
						}}
					>
						<DocumentFieldName field={this.props.field} />
					</div>
				</div>
			</Paper>
		)
	}

	renderFallbackFileViewer() {
		return (
			<Paper className={styles.preview}>
				{this.props.accountingDocumentScans &&
					this.props.accountingDocumentScans.map((scan: AccountingDocumentScan) => (
						<FileViewer key={scan.fileId} fileId={scan.fileId || ''} downloadable />
					))}
			</Paper>
		)
	}

	renderImageViewer = (page: ScanFactoryPage, pageIndex: number) => {
		return (
			<div key={page.imageId} className={styles.fileViewer} style={{ maxWidth: page.imageWidth }}>
				<ImageViewer
					overlay={this.renderBBWords(page, pageIndex)}
					src={getPreviewImageUrl(this.props.documentId, page.imageLocation || '', page.imageId || '')}
					stretch
				/>
			</div>
		)
	}

	renderBBWords = (page: ScanFactoryPage, pageIndex: number) => {
		return (
			<div onClick={this.onPreviewClick} onContextMenu={this.onPreviewClick} className={styles.bbWords}>
				{page.bbWords &&
					page.bbWords.map((bbWord: ScanFactoryBbWord, bbWordIndex: number) =>
						this.renderBBWord(bbWord, page.imageWidth || 0, page.imageHeight || 0, bbWordIndex, pageIndex),
					)}
			</div>
		)
	}

	renderBBWord = (
		bbWord: ScanFactoryBbWord,
		pageWidth: number,
		pageHeight: number,
		bbWordIndex: number,
		pageIndex: number,
	) => {
		const { width = 0, height = 0, x = 0, y = 0 } = bbWord.area || {}

		const fieldCursors = getRelatedFieldCursors(this.props.bbWordFieldMap, { pageIndex, bbWordIndex })
		const bbWordCursor = { pageIndex, bbWordIndex }

		const classNames = [
			styles.bbWord,
			fieldCursors.length ? styles.assigned : '',
			isSomeFieldCursorEqual(this.props.fieldCursor, fieldCursors) ? styles.active : '',
			find(this.props.highlightBbWords, bbWordCursor) ? styles.highlight : '',
			isResolvable(bbWord, this.props.field) ? styles.resolvable : '',
		]

		return (
			<div
				key={bbWordIndex}
				title={bbWord.plainText}
				className={classNames.join(' ')}
				onClick={this.onBbWordClick(bbWord)}
				onMouseOver={this.onBbWordMouseOver(bbWordCursor)}
				onMouseOut={this.onBbWordMouseOut(bbWordCursor)}
				style={{
					width: `${width * 100}%`,
					height: `${((height * pageWidth) / pageHeight) * 100}%`,
					top: `${((y * pageWidth) / pageHeight) * 100}%`,
					left: `${x * 100}%`,
				}}
			/>
		)
	}

	onPreviewClick = (event: SyntheticMouseEvent<HTMLDivElement>) => {
		if (event.shiftKey || 'contextmenu' === event.type) {
			this.props.onPreviewShiftClick()
			event.preventDefault()
		} else {
			this.props.onPreviewClick()
		}
	}

	onBbWordClick(bbWord: ScanFactoryBbWord) {
		return (event: SyntheticMouseEvent<HTMLElement>) => {
			event.stopPropagation()
			this.props.onBbWordClick(bbWord, { metaKey: event.metaKey })
		}
	}

	onBbWordMouseOver(bbWord: BbWordCursor) {
		return () => this.props.onBbWordMouseOver(bbWord)
	}

	onBbWordMouseOut(bbWord: BbWordCursor) {
		return () => this.props.onBbWordMouseOut(bbWord)
	}

	setRootRef = (node: ?HTMLElement) => {
		this.root = node
	}

	handleMouseDown = (event: MouseEvent) => {
		document.addEventListener('mouseup', this.handleMouseEnd)
		event.preventDefault()
		this.setState({
			dragging: true,
		})
	}

	handleMouseEnd = (event: MouseEvent) => {
		document.removeEventListener('mouseup', this.handleMouseEnd)
		this.setState({
			dragging: false,
		})

		if (!this.root) return
		const bounding = this.root.getBoundingClientRect()
		this.props.onPreviewResize(event.clientX - bounding.left)
	}

	handleMouseMove = (event: MouseEvent) => {
		if (!this.root) return

		const bounding = this.root.getBoundingClientRect()

		if (this.state.dragging) {
			this.root &&
				this.setState({
					width: event.clientX - bounding.left,
				})
		} else {
			this.root &&
				this.setState({
					hintPosition: {
						x: event.clientX - bounding.left + 20,
						y: event.clientY - bounding.top + 18,
					},
				})
		}
	}
}

export default DocumentPreview
