/* @flow */
import { connect } from 'react-redux'
import { getCurrentOrganization } from 'modules/organization/selectors'
import { isTaxEvidence } from 'modules/organization/domain/organization'
import { refreshGridData } from 'modules/data-grid-next/actions'
import type { DataGridNextAction, Dispatch, Filter, State, DataGridId, Organization } from 'types'
import FinancialAccountsGrid from './financial-accounts-grid-component'
import type { DataType } from '../../types'

type StateProps = {|
	canSeeSubAccounts: boolean,
	canEditSubAccounts: boolean,
|}

function mapStateToProps(state: State): StateProps {
	const organization: ?Organization = getCurrentOrganization(state)
	const taxEvidence = organization && isTaxEvidence(organization)
	return {
		canSeeSubAccounts: (organization && !taxEvidence) || false,
		canEditSubAccounts: false, // currentUserIsInternal(state) && !taxEvidence, // Creating subAccounts is disabled for now - may be enabled in future
	}
}

type DispatchProps = {|
	refreshGrid: (gridId: DataGridId, gridDataType: DataType, defaultFilter?: Filter) => void,
|}

function mapDispatchToProps(dispatch: Dispatch<DataGridNextAction>): DispatchProps {
	return {
		refreshGrid: (gridId: DataGridId, gridDataType: DataType, defaultFilter?: Filter) => {
			dispatch(refreshGridData(gridDataType, gridId, gridId, defaultFilter))
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(FinancialAccountsGrid)
