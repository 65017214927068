// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PJpPINzXeeO9kpX5EYr_H {\n\tposition: relative;\n\tmargin-top: 50px;\n\ttext-align: right;\n\tdisplay: flex;\n\tjustify-content: flex-end;\n}\n\n._1cRvahX9NzXbzaRlmCvFAS {\n\tposition: absolute;\n\ttop: -13px;\n\tright: -13px;\n}\n", "",{"version":3,"sources":["webpack://./modules/user/components/signature-picker.css"],"names":[],"mappings":"AAAA;CACC,kBAAA;CACA,gBAAA;CACA,iBAAA;CACA,aAAA;CACA,yBAAA;AACD;;AAEA;CACC,kBAAA;CACA,UAAA;CACA,YAAA;AACD","sourcesContent":[".root {\n\tposition: relative;\n\tmargin-top: 50px;\n\ttext-align: right;\n\tdisplay: flex;\n\tjustify-content: flex-end;\n}\n\n.iconButton {\n\tposition: absolute;\n\ttop: -13px;\n\tright: -13px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "PJpPINzXeeO9kpX5EYr_H",
	"iconButton": "_1cRvahX9NzXbzaRlmCvFAS"
};
export default ___CSS_LOADER_EXPORT___;
