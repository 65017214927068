/* @flow */

import React, { Component } from 'react'
import { Paper, Loader, IconButton, TriviLink } from 'components'
import ActionDelete from 'components/svg-icons/action/delete'
import { editAccountingDocumentRoute } from 'modules/accounting-document/routing/routes'
import { accountingDocumentNumber } from 'helpers'
import type { AccountingDocument, AccountingDocumentInfo } from 'types'
import styles from './accounting-document-icon.css'

export type Props = {|
	accountingDocument: AccountingDocument | AccountingDocumentInfo,
	organizationId?: string,
	loading: boolean,
	editable: boolean,
	onRemove: (AccountingDocument | AccountingDocumentInfo) => void,
|}

class AccountingDocumentIcon extends Component<Props> {
	static defaultProps = {
		loading: false,
		editable: true,
		onRemove: () => {},
	}

	handleRemoveClick = () => {
		this.props.onRemove(this.props.accountingDocument)
	}

	render() {
		const { accountingDocument, editable, loading } = this.props
		const id = accountingDocument.accountingDocumentId || accountingDocument.id || ''
		const organizationId = this.props.organizationId ? this.props.organizationId.toString() : undefined
		return (
			<Paper rounded zDepth={3} className={styles.root}>
				{editable && <div className={styles.text}>{accountingDocumentNumber(accountingDocument)}</div>}
				{!editable && (
					<div className={styles.text}>
						<TriviLink routeFn={editAccountingDocumentRoute} routeParams={[id, organizationId]}>
							{accountingDocumentNumber(accountingDocument)}
						</TriviLink>
					</div>
				)}
				{editable && (
					<div className={styles.icon}>
						<IconButton autoTestId="accounting-document-icon-delete" onClick={this.handleRemoveClick}>
							<ActionDelete />
						</IconButton>
					</div>
				)}
				<Loader visible={loading} />
			</Paper>
		)
	}
}

export default AccountingDocumentIcon
