// @flow

import React, { Component } from 'react'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import type { GenericReport, GenericReportRequest, FinancialAccount } from 'types'
import type { Action } from '../../actions/action-types'
import type { ReportScheme } from '../../constants/report'
import GenerateReport from './generate-report'
import PopupSection from 'components/PopupSection'
import FileList from './file-list'
import { handleDownloadFile, openFile } from '../../../file/domain'
/** @jsx jsx */
import { jsx } from '@emotion/core'
import FileViewer from '../../../file/containers/file-viewer'

type Props = {|
	...WithTranslateProps,
	requestReport: (request: GenericReportRequest) => Promise<Action>,
	reportScheme: ?ReportScheme,
	loadFinancialAccounts: () => void,
	financialAccounts: ?Array<FinancialAccount>,
	favoriteFinancialAccountIds: Array<string>,
	onFinancialAccountFavorite: (no: ?string, isFavorite: boolean) => void,
	onRequestClose: (reason: string) => void,
	reports: Array<GenericReport>,
	open: boolean,
	loadReports: (pageSize?: number, page?: number) => ?Promise<*>,
	loadReport: (reportId: string) => void,
	deleteReport: (reportId: string) => Promise<void>,
|}

type State = {|
	activeFileId: ?string,
	fileOpen: boolean,
|}

class ReportDetail extends Component<Props, State> {
	state = {
		activeFileId: null,
		fileOpen: false,
	}

	async downloadFile(fileId: string, fileName: string) {
		await handleDownloadFile(fileId, fileName, true)
	}
	openFile = async (fileId: string, fileName: string) => {
		const handledViaDownload = await handleDownloadFile(fileId, fileName)
		if (!handledViaDownload) {
			this.setState({ activeFileId: fileId, fileOpen: true })
		}
	}

	closeFile = () => {
		this.setState({ fileOpen: false, activeFileId: null })
	}

	render() {
		const {
			reportScheme,
			open,
			onRequestClose,
			requestReport,
			reports,
			loadReports,
			deleteReport,
			financialAccounts,
			favoriteFinancialAccountIds,
			onFinancialAccountFavorite,
			loadFinancialAccounts,
		} = this.props

		if (this.props.reportScheme == null) {
			return null
		}

		return (
			<PopupSection open={open} onRequestClose={onRequestClose}>
				<GenerateReport
					reportScheme={reportScheme}
					requestReport={requestReport}
					loadFinancialAccounts={loadFinancialAccounts}
					financialAccounts={financialAccounts}
					favoriteFinancialAccountIds={favoriteFinancialAccountIds}
					onFinancialAccountFavorite={onFinancialAccountFavorite}
				/>
				<FileList
					reports={reports}
					loadReports={loadReports}
					downloadFile={this.downloadFile}
					openFile={this.openFile}
					deleteFile={deleteReport}
				/>
				<PopupSection open={this.state.fileOpen} onRequestClose={this.closeFile}>
					<div css={styles.fileContainer}>
						<FileViewer fileId={this.state.activeFileId} autoHeight downloadable />
					</div>
				</PopupSection>
			</PopupSection>
		)
	}
}

export default withTranslate(ReportDetail)

const styles = {
	fileContainer: {
		position: 'relative',
		marginLeft: 'auto',
		marginRight: 'auto',
		marginTop: 50,
		width: '100%',
		height: '96%',
	},
}
