/* @flow */
import React, { Component } from 'react'
import { validate, withTranslate, type WithTranslateProps, type FormValidationProps } from 'wrappers'
import UserProfileAvatar from '../user-icon/user-profile-avatar'
import type { CropReturnType } from 'components/image-cropper'
import { ImageCropper, TextField } from 'components'
import PhoneInput from 'components/phone-input'
import Dropzone from 'components/dropzone'
import styles from './styles.css'
import { Validator } from 'utils/validators'

type Props = {|
	...FormValidationProps,
	...WithTranslateProps,
	phone: ?string,
	firstName: ?string,
	lastName: ?string,
	avatar: ?string,
	originalAvatar: ?string,
	crop: ?CropReturnType,
	getOnChange: (field: string) => (ev: SyntheticInputEvent<HTMLInputElement>, value: ?string) => void,
	onDeleteAvatar: () => void,
	onChangeAvatar: (fileLocalUrl: ?string, file: ?File, crop: ?CropReturnType) => void,
|}

type State = {|
	croppingAvatar: boolean,
	crop: ?CropReturnType,
	avatarUrl: ?string,
	avatarFile: ?File,
	newFile: boolean,
|}

class ProfileSettings extends Component<Props, State> {
	state: State = {
		croppingAvatar: false,
		crop: null,
		avatarUrl: null,
		avatarFile: null,
		newFile: false,
	}
	dropzone: any = null

	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		this.setState({ crop: nextProps.crop })
	}

	onFileUpload = (file: File) => {
		this.setState({ croppingAvatar: true })
		const avatarFile = file
		const reader = new FileReader()
		if (avatarFile) {
			reader.readAsDataURL(avatarFile)
			reader.onloadend = () => {
				const avatarUrl: string = reader.result.toString()
				this.setState({ avatarUrl, avatarFile, newFile: true })
			}
		}
	}

	handleUpload = async (files: Array<File>): Promise<any> => {
		await Promise.all(
			files.map((file: File) => {
				return this.onFileUpload(file)
			}),
		)
	}

	handleClick = () => {
		this.dropzone &&
			this.dropzone
				.getWrappedInstance()
				.getWrappedInstance()
				.open()
	}

	bindDropZone = (element: any) => {
		this.dropzone = element
	}

	onCrop = (crop: ?CropReturnType) => {
		this.setState({ crop, croppingAvatar: false }, this.onChangeAvatar)
	}

	onCancelCrop = () => {
		this.setState({
			croppingAvatar: false,
			crop: null,
			avatarUrl: null,
			avatarFile: null,
			newFile: false,
		})
	}

	onChangeAvatar = () => {
		this.props.onChangeAvatar(this.state.avatarUrl, this.state.avatarFile, this.state.crop)
	}

	cropAvatar = () => {
		this.setState({ croppingAvatar: true })
	}

	onDeleteAvatar = () => {
		this.setState({ avatarFile: null, avatarUrl: null })
		this.props.onDeleteAvatar()
	}

	isValid = (value: ?string | ?number): boolean => {
		return value ? Validator.phone(value) : true
	}

	getClientValidationErrors() {
		const errors = {}
		const { t } = this.props

		if (
			(!this.props.firstName || !this.props.firstName.length) &&
			(!this.props.lastName || !this.props.lastName.length)
		) {
			errors.firstName = t('application.validation.mandatory')
			errors.lastName = t('application.validation.mandatory')
		}

		errors.emails = t('application.validation.mandatory')

		return errors
	}

	render() {
		const { t, firstName, lastName, originalAvatar } = this.props
		const { crop, newFile } = this.state

		this.props.validateForm(this.getClientValidationErrors())

		if (this.state.croppingAvatar) {
			const src: string = newFile ? this.state.avatarUrl || '' : originalAvatar || this.state.avatarUrl || ''
			return <ImageCropper square src={src} onCrop={this.onCrop} onCancelCrop={this.onCancelCrop} />
		}

		const elseAvatar = !crop ? this.props.avatar : originalAvatar || this.props.avatar
		const avatar = newFile ? this.state.avatarUrl : elseAvatar

		return (
			<div className={styles.settingsFlexContainer}>
				<div>
					<UserProfileAvatar
						size={112}
						avatar={avatar}
						name={firstName && lastName ? `${firstName} ${lastName}` : null}
						crop={crop || this.props.crop || undefined}
					/>
				</div>
				<div className={styles.profileSettingsContentWrapper}>
					<div className={styles.userInfo}>
						<div className={styles.userName}>
							<TextField
								autoWidth
								inline
								hintText={t('user.settings.firstName')}
								value={this.props.validationValue('firstName', this.props.firstName)}
								onChange={this.props.getOnChange('firstName')}
								clientError={this.props.validationMessage('firstName')}
								autoTestId="user-settings-profile-firstname"
								name="firstname"
							/>{' '}
							<TextField
								autoWidth
								inline
								hintText={t('user.settings.lastName')}
								autoTestId="user-settings-profile-lastname"
								value={this.props.validationValue('lastName', this.props.lastName)}
								onChange={this.props.getOnChange('lastName')}
								clientError={this.props.validationMessage('lastName')}
								name="lastname"
							/>
						</div>

						<div className={styles.avatarOptions}>
							<div className={styles.dropzone}>
								<Dropzone
									ref={this.bindDropZone}
									onDrop={this.handleUpload}
									accept={'image/jpeg,image/jpg,image/png,image/gif,image/tiff'}
									multiple={false}
								/>
							</div>
							<span className={styles.link} onClick={this.handleClick}>
								{t(
									this.props.avatar == null
										? 'user.settings.insertProfilePicture'
										: 'user.settings.changeProfilePicture',
								)}
							</span>

							{this.props.avatar && (
								<div>
									<span className={styles.link} onClick={this.cropAvatar}>
										{t('user.settings.cropProfilePicture')}
									</span>
									<span className={styles.link} onClick={this.onDeleteAvatar}>
										{t('user.settings.deleteProfilePicture')}
									</span>
								</div>
							)}
						</div>
					</div>

					<div className={styles.form}>
						<div className={styles.field}>
							<PhoneInput
								fullWidth
								type="phone"
								labelText={t('user.settings.phone')}
								autoTestId="user-settings-profile-phone"
								value={this.props.phone}
								onChange={this.props.getOnChange('phone')}
								clientError={this.isValid(this.props.phone) ? null : this.props.t('clientError.notAPhone')}
							/>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default withTranslate(validate('FINISH_UPDATING_ME')(ProfileSettings))
