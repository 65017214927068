// @flow
/** @jsx jsx */

import { Component } from 'react'
import { jsx } from '@emotion/core'
import { formatToDateString } from 'utils/formatters/date-formatter'
import Toggle from 'components/toggle'
import DatePicker from 'components/date-picker'

type Props = {|
	isActive?: ?boolean,
	value?: ?Date,
	labelText?: string,
	onChange: (isActive: ?boolean, value: ?Date) => void,
|}

class ToggleDatePicker extends Component<Props> {
	onToggleChange = () => {
		this.props.onChange(!this.props.isActive, this.props.value)
	}

	onDatePickerChange = (event: ?SyntheticInputEvent<HTMLInputElement>, value: ?Date) => {
		this.props.onChange(!!this.props.isActive, value)
	}

	render() {
		return (
			<div css={styles.root}>
				<Toggle
					labelPosition={'right'}
					toggled={!!this.props.isActive}
					onToggle={this.onToggleChange}
					label={this.props.labelText}
				/>
				<div css={styles.date}>
					{this.props.isActive ? (
						<DatePicker compact value={this.props.value} onChange={this.onDatePickerChange} />
					) : (
						this.props.value && formatToDateString(this.props.value)
					)}
				</div>
			</div>
		)
	}
}

const styles = {
	root: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginTop: 10,
		marginBottom: 10,
		minHeight: 32,
	},
	date: {
		textAlign: 'right',
		flex: '1 0 120px',
		fontSize: 14,
	},
}

export default ToggleDatePicker
