// @flow
/** @jsx jsx */

import { jsx } from '@emotion/core'
import { withTranslate, type WithTranslateProps } from 'wrappers'

type Props = {|
	...WithTranslateProps,
	children: ?React$Element<any> | Array<any>,
|}

function TableBody(props: Props) {
	if (!props.children)
		return (
			<tr>
				<td colSpan="99" css={styles.noData}>
					{props.t('application.noData')}
				</td>
			</tr>
		)
	return <tbody css={styles.root}>{props.children}</tbody>
}

const styles = {
	root: {
		textAlign: 'left',
	},
	noData: {
		padding: '25px 0',
		textAlign: 'center',
	},
}

export default withTranslate(TableBody)
