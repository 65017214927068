/* @flow */

import store from 'store'

function getOrganizationId() {
	const state = store.getState()
	return state.user.currentOrganizationId || 'error'
}

export function rootDocumentsRoute() {
	return `/${getOrganizationId()}/documents`
}

export function childDocumentsRoute(itemId: string) {
	return `/${getOrganizationId()}/documents/${itemId}`
}

export function searchDocumentsRoute(itemId: string) {
	return `/${getOrganizationId()}/documents/search/${itemId}`
}
