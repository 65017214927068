/* @flow */
/** @jsx jsx */

import { Component, type Node } from 'react'
import { jsx } from '@emotion/core'
import memoize from 'memoize-one'
import TagsInput from 'react-tagsinput'
import AutosizeInput from 'react-input-autosize'
import { withNotify, withTranslate } from 'wrappers'
import type { WithNotifyProps, WithTranslateProps } from 'wrappers'
import Clear from 'components/svg-icons/content/clear'
import IconButton from 'components/icon-button'
import Popover from 'components/Popover/Popover'
import TextField from 'components/TextField'
import MenuItem from 'components/menu-item'
import Menu from 'components/menu'
import { colors } from 'variables'
import styles from './styles.css'

const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ //eslint-disable-line

type Props = {
	...WithTranslateProps,
	...WithNotifyProps,
	value: ?Array<string>,
	maxCount?: number,
	fullWidth?: boolean,
	labelText?: string,
	hintText?: string,
	autoWidth?: boolean,
	disabled?: boolean,
	inline?: boolean,
	name?: string,
	clientError?: Node,
	options?: null | Array<string>,
	onBlur?: () => void,
	onChange: (emails: Array<string>) => void,
}

type State = {
	emails: Array<string>,
	filter: string | null,
	open: boolean,
}

class MultiEmailInput extends Component<Props, State> {
	anchorEl: ?HTMLElement = null
	state: State = {
		emails: [],
		open: false,
		filter: null,
	}

	componentDidMount() {
		this.checkValue(this.props)
	}

	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		this.checkValue(nextProps)
	}

	bindAnchor = (element: ?HTMLElement) => {
		this.anchorEl = element
	}

	checkValue = (props: Props) => {
		this.handleEmailsChange(props.value ? props.value : [], false)
	}

	handleEmailsChange = (emails: Array<string>, publish?: boolean = true) => {
		const newEmails: Array<string> = emails.filter((email: string) => EMAIL_REGEX.test(email))
		if (newEmails.length < emails.length) {
			this.props.notify('clientError.notAnEmail', 'error')
		}

		this.setState({ emails: newEmails }, () => {
			publish && this.props.onChange(newEmails)
		})
	}

	onTextFieldBlur = () => {
		const { emails } = this.state
		if (EMAIL_REGEX.test(emails[0])) {
			this.props.onChange(this.state.emails)
		} else {
			this.props.notify('clientError.notAnEmail', 'error')
			this.setState({ emails: [] })
		}
	}

	onTextFieldChange = (event: ?SyntheticInputEvent<HTMLInputElement>, value: ?string) => {
		if (value !== null && value !== undefined) {
			this.setState({ emails: [value] })
		}
	}

	openMenu = () => this.setState({ open: true })
	closeMenu = () => this.setState({ open: false })

	renderTag = (props: Object) => {
		const { tag, key, disabled, classNameRemove, getTagDisplayValue, onRemove, ...other } = props
		return (
			<span key={key} {...other} css={style.item}>
				<span css={style.tag}>{tag}</span>
				{!disabled && (
					<div css={style.icon}>
						<IconButton
							onClick={e => onRemove(key)} //eslint-disable-line
							color={colors.white}
							hoverColor={colors.white}
							size={24}
							autoTestId="multi-email-input-remove"
						>
							<Clear size={14} />
						</IconButton>
					</div>
				)}
			</span>
		)
	}

	onInput = (event: SyntheticInputEvent<HTMLInputElement>) => {
		if (event.target instanceof HTMLInputElement) {
			const filter = event.target && event.target.value
			this.setState({ filter })
		}
	}

	onAutocompleteChange = (event: SyntheticMouseEvent<HTMLElement>, value: any) => {
		this.props.onChange([...(this.props.value || []), value])
	}

	renderAutosizeInput = (props: Object) => {
		const { onChange, value, addTag, ...other } = props
		return (
			<AutosizeInput
				type="text"
				style={style.autoSize}
				inputStyle={style.autoSizeInput}
				onChange={onChange}
				onKeyUp={this.onInput}
				// Autocomplete hacks for Edge - see https://stackoverflow.com/questions/32785958/disable-input-text-suggestions-in-edge
				autoComplete="new-password"
				list="triviAutocompleteOff"
				value={value}
				{...other}
			/>
		)
	}

	getFilteredOptions = memoize(
		(options?: null | Array<string>, used: Array<string>, filter: ?string): null | Array<string> => {
			if (!options || !options.length) return null
			const lowercaseFilter = filter && filter.toLowerCase()
			const result = options.filter((email: string) => {
				if (used.includes(email)) return false
				if (lowercaseFilter && lowercaseFilter.length) return email.toLowerCase().startsWith(lowercaseFilter)
				return true
			})
			return result.length ? result : null
		},
	)

	render() {
		const options = this.getFilteredOptions(this.props.options, this.state.emails, this.state.filter)

		return (
			<div ref={this.bindAnchor}>
				<TextField
					clientError={this.props.clientError}
					autoTestId="multi-email-input-field"
					fullWidth
					onFocus={this.openMenu}
					onBlur={this.closeMenu}
				>
					<TagsInput
						addOnBlur
						maxTags={this.props.maxCount || -1}
						className={styles.tagsInput}
						value={this.state.emails}
						onChange={this.handleEmailsChange}
						inputProps={{ placeholder: this.props.t('application.addEmail') }}
						renderTag={this.renderTag}
						renderInput={this.renderAutosizeInput}
					/>
				</TextField>
				<Popover
					open={!!(this.state.open && options)}
					anchorEl={this.anchorEl}
					onRequestClose={this.closeMenu}
					maxHeight={320}
					offsetY={8}
					zDepth={4}
					stretch
				>
					<Menu onEscKeyDown={this.closeMenu} onChange={this.onAutocompleteChange}>
						{options && options.map((option: string) => <MenuItem key={option} value={option} primaryText={option} />)}
					</Menu>
				</Popover>
			</div>
		)
	}
}

const style = {
	icon: {
		position: 'absolute',
		padding: 0,
		right: 0,
		top: 3,
	},
	tag: {
		cursor: 'default',
		color: colors.white,
	},
	item: {
		display: 'inline-block',
		position: 'relative',
		border: '1px solid #0B8F2B',
		borderRadius: 2,
		padding: '3px 28px 3px 7px',
		marginRight: 7,
		lineHeight: '24px',
		height: 30,
		fontSize: 14,
		backgroundColor: colors.green,
		margin: '2px 7px 2px 0',
	},
	autoSize: {
		maxWidth: '99%',
	},
	autoSizeInput: {
		maxWidth: '100%',
	},
}

export default withTranslate(withNotify(MultiEmailInput))
