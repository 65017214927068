// @flow
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import type {
	State,
	ISignalMessage,
	NotificationsAction,
	Dispatch,
	ISignalMessageParams,
	ISignalMessageGroup,
} from 'types'
import { getNotificationPreview } from '../selectors'
import { hideNotificationPreview, markAsRead } from '../actions'
import { getNotificationRoute } from '../routing/routes'
import NotificationPreview from '../components/notification-preview'

type StateProps = {|
	open: boolean,
	notification: ?ISignalMessage,
|}

function mapStateToProps(state: State): StateProps {
	const notification = getNotificationPreview(state)
	return {
		open: !!notification,
		notification,
	}
}

type DispatchProps = {|
	onRequestClose: () => void,
	handleNotificationClick: (params: ISignalMessageParams, group: ISignalMessageGroup, notificationId: string) => void,
|}

function mapDispatchToProps(dispatch: Dispatch<NotificationsAction>): DispatchProps {
	return {
		onRequestClose: () => {
			dispatch(hideNotificationPreview())
		},
		handleNotificationClick: (params: ISignalMessageParams, group: ISignalMessageGroup, notificationId: string) => {
			dispatch(markAsRead(notificationId))
			dispatch(hideNotificationPreview())
			const route = getNotificationRoute(group, params)
			route && dispatch(push(route))
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationPreview)
