/* @flow */

import { connect } from 'react-redux'
import { push, replace } from 'react-router-redux'
import { isMeCashRegisterOwner } from 'modules/cash-register/selectors'
import type {
	State,
	Dispatch,
	CashRegister,
	AccountingDocumentType,
	AccountingDocumentDirection,
	AccountingDocumentReduxAction,
	SettingsAction,
	CashRegisterAction,
	SequencesAction,
	DataGridReduxAction,
	SearchFilter,
	AccountingDocumentListAction,
} from 'types'
import type { CashRegisterState } from '../reducers'
import CashRegisterDetail from '../components/cash-register-detail'
import { cashRegisterEditRoute, cashRegisterMakeInventoryRoute } from 'modules/cash-register/routing/routes'
import { createAccountingDocumentRoute, editAccountingDocumentRoute } from 'modules/accounting-document/routing/routes'
import { changeAccountingDocumentsDirection, createAccountingDocument } from 'modules/accounting-document/actions'
import {
	AccountingDocumentType_Number,
	AccountingDocumentDirection_Number,
	Number_AccountingDocumentAssignedType,
} from 'types/convertor'
import { getTodayIsoDateString } from 'utils/formatters'
import { emptyAccountingDocumentLineItem } from 'types/empty'
import { changeCashRegisterListDirection, downloadCashBook } from 'modules/cash-register/actions'
import { userHasAccess } from 'permissions'
import { changeFilter, clearAllGridFilters, clearGridFilterField } from '../../data-grid/actions'
import { getCashRegisterFilterName } from '../domain/cash-register'
import { type State as FilterState } from 'modules/data-grid/reducers'

type StateProps = {|
	cashRegister: ?CashRegister,
	canEditCashRegisters: boolean,
	isCashRegisterOwner: boolean,
	direction: ?AccountingDocumentDirection,
	gridFilter?: FilterState,
|}

let filter = {
	'cashRegisterPayments/unknown': [],
	'cashRegisterPayments/issued': [],
	'cashRegisterPayments/received': [],
}

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => {
	const cashRegister: ?CashRegisterState = state.cashRegister.cashRegister.find(
		(cashRegister: CashRegisterState): boolean => cashRegister.id === ownProps.id,
	)
	const cashRegisterData = cashRegister && cashRegister.data
	const isCashRegisterOwner = (cashRegisterData && isMeCashRegisterOwner(state, cashRegisterData)) || false

	filter['cashRegisterPayments/unknown'] = state.grid.filter['cashRegisterPayments/unknown']
	filter['cashRegisterPayments/issued'] = state.grid.filter['cashRegisterPayments/issued']
		? [...state.grid.filter['cashRegisterPayments/issued']]
		: []
	filter['cashRegisterPayments/received'] = state.grid.filter['cashRegisterPayments/received']
		? [...state.grid.filter['cashRegisterPayments/received']]
		: []

	const gridFilter: FilterState = state.grid.filter

	return {
		cashRegister: cashRegisterData,
		canEditCashRegisters: userHasAccess(state, 'editCashRegisters'),
		isCashRegisterOwner,
		direction: state.cashRegister.direction,
		gridFilter,
	}
}

type DispatchProps = {|
	onEdit: () => void,
	onMakeInventory: () => void,
	onCashBookDownload: (from: Date, to: Date) => void,
	onCreateAccountingDocument: (
		type: AccountingDocumentType,
		direction: AccountingDocumentDirection,
		currency: string,
	) => void,
	onChangeDirection: (direction: AccountingDocumentDirection, redirect?: boolean) => void,
	onChangeView: (gridFilter: FilterState) => void,
|}

const mapDispatchToProps = (
	//prettier-ignore
	dispatch: Dispatch<
		AccountingDocumentReduxAction | AccountingDocumentListAction | SettingsAction | CashRegisterAction | SequencesAction | DataGridReduxAction>,
	ownProps: OwnProps,
): DispatchProps => {
	return {
		onChangeView: (gridFilter: FilterState) => {
			if (
				gridFilter.hasOwnProperty('cashRegisterPayments/unknown') &&
				gridFilter.hasOwnProperty('cashRegisterPayments/received') &&
				gridFilter.hasOwnProperty('cashRegisterPayments/issued')
			) {
				filter = gridFilter
			}
		},
		onEdit: (): void => {
			dispatch(push(cashRegisterEditRoute(ownProps.id)))
		},
		onMakeInventory: (): void => {
			dispatch(push(cashRegisterMakeInventoryRoute(ownProps.id)))
		},
		onCashBookDownload: (from: Date, to: Date) => {
			dispatch(downloadCashBook(ownProps.id, from, to))
		},
		onCreateAccountingDocument: (
			type: AccountingDocumentType,
			direction: AccountingDocumentDirection,
			currency: string,
		) => {
			const directionNumber = AccountingDocumentDirection_Number(direction)

			if (directionNumber === 0 || directionNumber === 1) {
				const accDocType = AccountingDocumentType_Number(type)
				const simplifiedInvoiceType = AccountingDocumentType_Number('simplified_invoice')

				const lineItems = []
				if (accDocType === simplifiedInvoiceType) {
					lineItems.push(emptyAccountingDocumentLineItem())
				}

				dispatch(
					createAccountingDocument({
						currency,
						issueDate: getTodayIsoDateString(),
						lineItems: lineItems,
						paymentType: 2, // 2 = cash
						type: accDocType,
						// vatCountry: 'CZ',
						direction: directionNumber,
						cashRegisterId: ownProps.id,
					}),
				).then((action: AccountingDocumentReduxAction) => {
					if (action.type === 'FINISH_CREATE_ACCOUNTING_DOCUMENT' && action.result && action.result.id) {
						dispatch(
							replace({
								pathname: editAccountingDocumentRoute(action.result.id),
								state: {
									isNew: true,
								},
							}),
						)
					}
				})

				const assignedType = Number_AccountingDocumentAssignedType(accDocType)
				dispatch(push({ pathname: createAccountingDocumentRoute(assignedType, direction) }))
			}
		},
		onChangeDirection: (direction: AccountingDocumentDirection) => {
			const name = getCashRegisterFilterName(direction)

			if (filter[name]) {
				let oldFilter = [...filter[name]]
				if (oldFilter) {
					const oldFilterCleared = oldFilter.filter((item: SearchFilter) => item.field !== 'direction')

					switch (direction) {
						case 'issued':
							dispatch(clearGridFilterField(name, 'direction'))
							dispatch(changeFilter(name, [...oldFilterCleared, { field: 'direction', value: '0' }]))
							break
						case 'received':
							dispatch(clearGridFilterField(name, 'direction'))
							dispatch(changeFilter(name, [...oldFilterCleared, { field: 'direction', value: '1' }]))
							break
						case 'unknown':
							dispatch(clearGridFilterField(name, 'direction'))
							dispatch(changeFilter(name, [...oldFilterCleared]))
							break
						default:
					}
				}
			}
			dispatch(changeAccountingDocumentsDirection(direction))
			dispatch(changeCashRegisterListDirection(direction))
		},
	}
}

type OwnProps = {|
	id: string,
|}

export default connect(mapStateToProps, mapDispatchToProps)(CashRegisterDetail)
