/* @flow */

export const main = {
	goToNextField: 'down',
	goToPrevField: 'up',
	editField: 'f2',
	cancelEditField: 'esc',
	resetField: 'del',
	addRepeatableSection: 'a',
	removeRepeatableSection: 'r',
}
