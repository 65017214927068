/* @flow */

import { loadContactByRegNo } from 'modules/address-book/actions'
import { getContactByRegNo } from 'modules/address-book/selectors'
import { setNotification } from 'modules/common/actions'
import { clearAresContacts, loadAresContacts } from 'modules/user/actions'
import { getAresContacts } from 'modules/user/selectors'
import type { AresContact, Contact, Dispatch, ScanFactoryField, State } from 'types'
import { AccountingDocumentContact_Contact, AresContact_AccountingDocumentContact } from 'types/convertor'
import type { AutoFillMap } from '../types'

export function createAutoFillMap(dispatch: Dispatch<any>, getState: () => State): AutoFillMap {
	return [
		{
			fieldName: 'CounterpartyCompanyRegNo',
			filling: {
				CounterpartyFirstname: (contact: Contact) => contact.firstName,
				CounterpartyLastname: (contact: Contact) => contact.lastName,
				CounterpartyCompanyName: (contact: Contact) => contact.companyName,
				CounterpartyTaxId: (contact: Contact) => contact.taxId,
				CounterpartyCity: (contact: Contact) => contact.addresses && contact.addresses[0] && contact.addresses[0].city,
				CounterpartyStreet: (contact: Contact) =>
					contact.addresses && contact.addresses[0] && contact.addresses[0].street,
				CounterpartyCountry: (contact: Contact) =>
					contact.addresses && contact.addresses[0] && contact.addresses[0].country,
				CounterpartyZipCode: (contact: Contact) =>
					contact.addresses && contact.addresses[0] && contact.addresses[0].zipCode,
				CounterpartyEmail: (contact: Contact) => contact.email,
				CounterpartyExternalId: (contact: Contact) => contact.externalId,
			},
			load: async (field: ScanFactoryField) => {
				const regNo = field.value
				if (regNo) {
					await dispatch(loadContactByRegNo(regNo))

					if (!getContactByRegNo(getState(), regNo)) {
						await dispatch(loadAresContacts(regNo))

						const aresContacts = getAresContacts(getState())
						if (!aresContacts || 0 === aresContacts.length) {
							dispatch(setNotification({ message: 'documentExtraction.contactNotFound', type: 'error' }, 3))
						}
					}
				}
			},
			get: (field: ScanFactoryField) => {
				const regNo = field.value
				if (regNo) {
					const addressBookContact = getContactByRegNo(getState(), regNo)
					if (addressBookContact) {
						return addressBookContact
					}

					const aresContacts = getAresContacts(getState())
					if (aresContacts && aresContacts.length && aresContacts[0].companyRegNo === regNo) {
						dispatch(clearAresContacts())
						return aresContactToContact(aresContacts[0])
					}
				}
			},
		},
	]
}

function aresContactToContact(ares: AresContact): Contact {
	return AccountingDocumentContact_Contact(AresContact_AccountingDocumentContact(ares))
}
