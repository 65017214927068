// @flow

import React from 'react'
import memoize from 'memoize-one'
import type { AccountingDocumentCategory, Enum, I18NextTranslateFn } from 'types'
import { EMPTY_ARRAY } from 'trivi-constants'

export type CategoriesMenuItemsType = {
	value?: string,
	label?: string,
	id?: string,
	name?: string,
}

export const getCategoriesMenuItems = memoize((categories: ?Array<AccountingDocumentCategory>) => {
	return (categories || EMPTY_ARRAY).map((category: AccountingDocumentCategory) => ({
		value: category.id,
		id: category.id,
		label: category.name,
		name: category.name,
	}))
})

export const getNullableCategoriesMenuItems = memoize(
	(categories: ?Array<AccountingDocumentCategory>, t: I18NextTranslateFn) => {
		const items = getCategoriesMenuItems(categories)
		return [{ value: null, label: t('uploadScan.uploadedDocuments') }, ...items]
	},
)

export const getCategoriesOptions = memoize((categories: ?Array<AccountingDocumentCategory>) => {
	return (categories || EMPTY_ARRAY).map((category: AccountingDocumentCategory) => {
		return { value: category.id, label: category.name }
	})
})

export const getCategoriesEnum = memoize((categories: ?Array<AccountingDocumentCategory>): ?Enum => {
	return (categories || EMPTY_ARRAY).map((category: AccountingDocumentCategory) => ({
		key: category.id,
		value: category.name,
	}))
})
