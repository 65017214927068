/* @flow */

import { uniq } from 'lodash-es'
import type { ChatUserInfo } from 'types'
import { mergeMessages } from '../chat/message'
import { chatUserInfoToUser } from '../chat/user'
import type { Channel, ChatState, Messages, MessagesByChannel, User } from '../types'

export function merge<T>(state: T, mergeState: $Shape<T>): T {
	return { ...state, ...mergeState }
}

export function createMerge(state: ChatState) {
	return function(mergeState: $Shape<ChatState>) {
		return merge(state, mergeState)
	}
}

export function mergeMessagesByChannel(
	messagesByChannel: MessagesByChannel,
	channel: Channel,
	messages: Messages,
): MessagesByChannel {
	return {
		...messagesByChannel,
		...{
			[channel.sid]: mergeMessages(messagesByChannel[channel.sid] || [], messages),
		},
	}
}

export function mergeChatUserInfo(users: Array<User>, newUsers?: Array<ChatUserInfo>) {
	return uniq([...users, ...(newUsers || []).map(chatUserInfoToUser)])
}
