// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[3].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js!../../../variables/colors.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._32LW-z1lfYwzMJKRKDqS-t {\n\tposition: absolute;\n\ttop: 0; left: 0;\n\tright: 0; bottom: 0;\n\ttext-align: center;\n}\n\n._32LW-z1lfYwzMJKRKDqS-t > * {\n\tposition: absolute;\n\ttop: 50%;\n\tright: auto;\n\tbottom: auto;\n\tleft: 50%;\n\ttransform: translate(-50%, -50%);\n}\n\n._39dfwirhBhGvQAiEW_Z6-N {\n\tfont-size: 46px;\n\tcolor: " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["black"] + ";\n\tmargin-bottom: 30px;\n}\n\n._2mnDlzQW0oZfzgi-pwRvbK {\n\tmargin: 10px auto;\n\tbackground-size: contain;\n\tbackground: transparent url(/../assets/images/scenery.svg);\n\tbackground-position: center center;\n\tbackground-repeat: no-repeat;\n\twidth: 280px;\n\tpadding-bottom: 230px;\n\tmargin-bottom: 20px;\n}\n", "",{"version":3,"sources":["webpack://./modules/common/pages/not-found.css"],"names":[],"mappings":"AAEA;CACC,kBAAA;CACA,MAAA,EAAQ,OAAA;CACR,QAAA,EAAU,SAAA;CACV,kBAAA;AASD;;AANA;CACC,kBAAA;CACA,QAAA;CACA,WAAA;CACA,YAAA;CACA,SAAA;CACA,gCAAA;AASD;;AANA;CACC,eAAA;CACA,mDAAA;CACA,mBAAA;AASD;;AANA;CACC,iBAAA;CACA,wBAAA;CACA,0DAAA;CACA,kCAAA;CACA,4BAAA;CACA,YAAA;CACA,qBAAA;CACA,mBAAA;AASD","sourcesContent":["@value black, blue from \"variables/colors.css\";\n\n.root {\n\tposition: absolute;\n\ttop: 0; left: 0;\n\tright: 0; bottom: 0;\n\ttext-align: center;\n}\n\n.root > * {\n\tposition: absolute;\n\ttop: 50%;\n\tright: auto;\n\tbottom: auto;\n\tleft: 50%;\n\ttransform: translate(-50%, -50%);\n}\n\n.headline {\n\tfont-size: 46px;\n\tcolor: black;\n\tmargin-bottom: 30px;\n}\n\n.image {\n\tmargin: 10px auto;\n\tbackground-size: contain;\n\tbackground: transparent url(/../assets/images/scenery.svg);\n\tbackground-position: center center;\n\tbackground-repeat: no-repeat;\n\twidth: 280px;\n\tpadding-bottom: 230px;\n\tmargin-bottom: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"black": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["black"] + "",
	"blue": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["blue"] + "",
	"root": "_32LW-z1lfYwzMJKRKDqS-t",
	"headline": "_39dfwirhBhGvQAiEW_Z6-N",
	"image": "_2mnDlzQW0oZfzgi-pwRvbK"
};
export default ___CSS_LOADER_EXPORT___;
