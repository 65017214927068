// @flow

import {
	contactsSearch as contactsSearchApi,
	tasksSearch as tasksSearchApi,
	accountingDocumentSearch as accountingDocumentSearchApi,
	templates as templatesSearchApi,
	tagsSearch as tagsSearchApi,
	financialAccountsSearch as financialAccountsSearchApi,
	unmatchedPaymentsSearch as unmatchedPaymentsSearchApi,
	bankAccountPaymentOrdersSearch,
	bankAccountStatementsSearch,
	organizationsSearch,
} from 'modules/common/models/api-model'
import type { DataType } from '../types'
import type { AccountingDocumentSearchPostParams, SearchRequestGeneric, DataGridId, TemplatesGetParams } from 'types'
import { type MethodOptions } from 'lib/apiHandler'
import * as t from '../../common/models/swagger-model'

// TODO export only until dataGridNext will be implemented everywhere (TWU-1507)
// this function has different order of params then generated method in api-model
export const accDocGridSearch = {
	post: async function(body: SearchRequestGeneric, options?: MethodOptions, params?: AccountingDocumentSearchPostParams) { //eslint-disable-line
		return accountingDocumentSearchApi.post(options || {}, body, params || {})
	},
}

export const templatesGridSearch = {
	get: async function(
		body: SearchRequestGeneric,
		options?: MethodOptions,
		params?: TemplatesGetParams,
	): Promise<t.TemplatesSearchResult> {
		return templatesSearchApi.get(params || {}, options, body)
	},
}

export const apiCallFunctions: { [DataType]: { [string]: Function } } = {
	accountingDocuments: accDocGridSearch,
	templates: templatesGridSearch,
	contacts: contactsSearchApi,
	banks: contactsSearchApi,
	tasks: tasksSearchApi,
	tags: tagsSearchApi,
	financialAccounts: financialAccountsSearchApi,
	unmatchedPayments: unmatchedPaymentsSearchApi,
	paymentOrders: bankAccountPaymentOrdersSearch,
	statements: bankAccountStatementsSearch,
	organizations: organizationsSearch,
}

export const dataInitialState = {
	accountingDocuments: undefined,
	templates: undefined,
	contacts: undefined,
	banks: undefined,
	tasks: undefined,
	tags: undefined,
	financialAccounts: undefined,
	unmatchedPayments: undefined,
	paymentOrders: undefined,
	statements: undefined,
	organizations: undefined,
}

export const apiCallQueryParams: { [DataGridId]: Object } = {
	accountingDocumentPicker: { scope: 'CA' },
}

export const FINANCIAL_ACCOUNTS_GRID_ID: DataGridId = 'financialAccounts'
export const FINANCIAL_ACCOUNTS_DATA_TYPE = 'financialAccounts'
