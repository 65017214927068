//@flow

import type {
	CommonAction,
	Dispatch,
	Base64Image,
	Organization,
	OrganizationAction,
	OrganizationAddress,
	SettingsAction,
	State,
} from 'types'

import CompanyComponent from 'modules/settings/pages/company'
import { connect } from 'react-redux'
import { currentUserIsInternal, currentUserIsAdmin, getCurrentUserHasInternalFullAccess } from 'modules/user/selectors'
import { changeOrganizationAddress, removeOrganizationAddress } from 'modules/organization/actions'
import { withOrganizationSettings } from 'wrappers'
import { withRouter } from 'react-router-dom'

type StateProps = {|
	currentUserIsInternal: boolean,
	currentUserIsAdmin: boolean,
	currentUserHasInternalFullAccess: boolean,
	organization: ?Organization,
	logoBinary: ?Base64Image,
	loading: boolean,
|}

const mapStateToProps = (state: State): StateProps => {
	return {
		currentUserIsInternal: currentUserIsInternal(state),
		currentUserIsAdmin: currentUserIsAdmin(state),
		currentUserHasInternalFullAccess: getCurrentUserHasInternalFullAccess(state),
		organization: state.organization.organizationDetail.data,
		logoBinary: state.settings.organizationLogo,
		loading: state.settings.loading,
	}
}

type DispatchProps = {|
	changeOrganizationAddress: (address: OrganizationAddress) => void,
	removeOrganizationAddress: (address: OrganizationAddress) => void,
|}

const mapDispatchToProps = (dispatch: Dispatch<CommonAction | OrganizationAction | SettingsAction>): DispatchProps => {
	return {
		changeOrganizationAddress: (address: OrganizationAddress) => {
			dispatch(changeOrganizationAddress(address))
		},
		removeOrganizationAddress: (address: OrganizationAddress) => {
			dispatch(removeOrganizationAddress(address))
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withOrganizationSettings(CompanyComponent)))
