//@flow

export const CASH_REGISTER_FILTER_ID = 'cashRegisterPayments'
import { colors } from 'variables'

export const CURRENCY_COLORS = {
	CZK: {
		background: colors.blue,
		text: colors.white,
	},
	USD: {
		background: colors.orange,
		text: colors.white,
	},
	EUR: {
		background: colors.red,
		text: colors.white,
	},
	GBP: {
		background: colors.green,
		text: colors.white,
	},
	CHF: {
		background: colors.navy,
		text: colors.white,
	},
	DKK: {
		background: colors.purple,
		text: colors.white,
	},
	HRK: {
		background: colors.skyblue,
		text: colors.white,
	},
	HUF: {
		background: '#8FBE00',
		text: colors.white,
	},
	NOK: {
		background: '#ED1C24',
		text: colors.white,
	},
	PLN: {
		background: '#09cc33',
		text: colors.white,
	},
	RUB: {
		background: '#299e30',
		text: colors.white,
	},
	SEK: {
		background: '#b3e5fd',
		text: '#00497f',
	},
	UAH: {
		background: '#fcf000',
		text: colors.black,
	},
}
