/* @flow */

import React, { Children, Component, type ChildrenArray } from 'react'
import { TabSwitch, TabSwitches } from 'components/TabSwitches'
import type { SearchFilter } from 'types'
import FilterTabSwitch from './filter-tab-switch'

export type Props = {
	name: string,
	label?: string,
	style?: Object,
	filter?: SearchFilter,
	className?: string,
	fullWidth?: boolean,
	compact?: boolean,
	children: ChildrenArray<React$Element<typeof FilterTabSwitch>>,
	onChange: (filter: SearchFilter) => void,
}

class FilterTabSwitches extends Component<Props> {
	static defaultProps = {
		onChange: () => {},
	}
	valueMap: { [string]: ?(number | string) }

	constructor(props: Props) {
		super(props)
		this.updateValueMap()
	}

	UNSAFE_componentWillReceiveProps() {
		this.updateValueMap()
	}

	updateValueMap() {
		this.valueMap = {}

		let value: ?(number | string), stringValue: string
		Children.forEach(this.props.children, (tab: React$Element<typeof FilterTabSwitch>) => {
			value = tab.props.value
			stringValue = convertToTabValue(value)
			this.valueMap[stringValue] = value
		})
	}

	handleTabChange = (tabValue: string) => {
		const value: ?(number | string) = this.valueMap[tabValue]

		let filter: SearchFilter = {
			field: this.props.name,
		}

		if (value !== null) {
			filter.value = value
		}

		this.props.onChange(filter)
	}

	render() {
		let value: ?(number | string | boolean) = this.props.filter && this.props.filter.value

		return (
			<div className={this.props.className}>
				<TabSwitches
					label={this.props.label}
					onChange={this.handleTabChange}
					value={convertToTabValue(value)}
					fullWidth={this.props.fullWidth}
					compact={this.props.compact}
				>
					{Children.map(this.props.children, (tab: React$Element<typeof FilterTabSwitch>) => {
						const value = convertToTabValue(tab.props.value)
						return <TabSwitch {...Object.assign({}, tab.props, { value })} key={value} />
					})}
				</TabSwitches>
			</div>
		)
	}
}

function convertToTabValue(value: ?(number | string | boolean)): string {
	if (typeof value === 'number' || typeof value === 'boolean') {
		return value.toString()
	} else if (!value) {
		return '__NONE__'
	} else {
		return value
	}
}

export default FilterTabSwitches
