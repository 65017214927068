/* @flow */

import { internalDashboardRoute } from 'modules/internal/routing/routes'
import { getCurrentOrganization } from 'modules/organization/selectors'
import React, { Component } from 'react'
import { HotKeys } from 'react-hotkeys'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import type {
	AccountingDocumentReduxAction,
	CommonAction,
	Dispatch,
	DocumentExtractionAction,
	Organization,
	State,
} from 'types'
import {
	addRepeatableSection,
	addStatsEvent,
	editField,
	finishEditField,
	initDocumentExtraction,
	loadDocumentMetadata,
	moveFieldCursorNext,
	moveFieldCursorPrev,
	nextDocumentExtraction,
	removeRepeatableSection,
	resetField,
	saveAccountingDocument,
} from '../actions'
import DocumentExtraction from '../components/document-extraction'
import { main as keyMap } from '../constants/hotkeys'
import inactivityMonitor from '../domain/inactivity-monitor'
import { getBbWordFieldMap, getExtractionDocument, getFieldBbWordMap, isWorking } from '../selectors'
import type { BbWordFieldMap, ExtractionDocument, FieldBbWordMap } from '../types'

type OwnProps = {|
	documentId: string,
	organizationId: string,
|}

type StateProps = {|
	extractionDocument: ?ExtractionDocument,
	documentId: string,
	organizationId: string,
	fieldBbWordMap: FieldBbWordMap,
	bbWordFieldMap: BbWordFieldMap,
	organization: ?Organization,
	working: boolean,
|}

export const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => {
	return {
		extractionDocument: getExtractionDocument(state),
		documentId: ownProps.documentId,
		organizationId: ownProps.organizationId,
		fieldBbWordMap: getFieldBbWordMap(state),
		bbWordFieldMap: getBbWordFieldMap(state),
		organization: getCurrentOrganization(state),
		working: isWorking(state),
	}
}

type DispatchProps = {|
	initDocumentExtraction: () => void,
	loadDocumentMetadata: (documentId: string, organizationId: string) => void,
	goToNextField: (hotkey: string) => void,
	goToPrevField: (hotkey: string) => void,
	editField: (hotkey: string) => void,
	cancelEditField: (hotkey: string) => void,
	resetField: (hotkey: string) => void,
	addRepeatableSection: (hotkey: string) => void,
	removeRepeatableSection: (hotkey: string) => void,
	onSave: () => void,
	onInactivity: (seconds: number) => void,
	nextDocumentExtraction: () => void,
|}

const mapDispatchToProps = (
	dispatch: Dispatch<DocumentExtractionAction | AccountingDocumentReduxAction | CommonAction>,
	ownProps: OwnProps,
): DispatchProps => {
	return {
		loadDocumentMetadata: (documentId: string, organizationId: string) => {
			dispatch(loadDocumentMetadata('accountingDocument', documentId, organizationId))
		},
		initDocumentExtraction: () => {
			dispatch(initDocumentExtraction())
		},
		goToNextField: (hotkey: string) => {
			dispatch(moveFieldCursorNext(hotkey))
		},
		goToPrevField: (hotkey: string) => {
			dispatch(moveFieldCursorPrev(hotkey))
		},
		editField: (hotkey: string) => {
			dispatch(editField(undefined, hotkey))
		},
		cancelEditField: (hotkey: string) => {
			dispatch(finishEditField(undefined, undefined, hotkey))
		},
		resetField: (hotkey: string) => {
			dispatch(resetField(undefined, hotkey))
		},
		addRepeatableSection: (hotkey: string) => {
			dispatch(addRepeatableSection(undefined, hotkey))
		},
		removeRepeatableSection: (hotkey: string) => {
			dispatch(removeRepeatableSection(undefined, undefined, hotkey))
		},
		onSave: (exit: boolean = false) => {
			dispatch(saveAccountingDocument(ownProps.documentId))
			if (exit) {
				dispatch(push(internalDashboardRoute()))
			} else {
				dispatch(nextDocumentExtraction())
			}
		},
		onInactivity: (seconds: number) => {
			dispatch(addStatsEvent('USER_INACTIVITY', { seconds }))
		},
		nextDocumentExtraction: () => {
			dispatch(nextDocumentExtraction())
		},
	}
}

const mergeProps = (stateProps: StateProps, dispatchProps: DispatchProps): {| ...StateProps, ...DispatchProps |} => {
	return { ...stateProps, ...dispatchProps }
}

type Props = {| ...StateProps, ...DispatchProps |}

class DocumentExtractionContainer extends Component<Props> {
	handlerMap: { [key: string]: (event: SyntheticEvent<HTMLElement>, hotkey: string) => void }

	constructor(props: Props) {
		super(props)

		this.handlerMap = {
			goToNextField: (event: SyntheticEvent<HTMLElement>, hotkey: string) => this.props.goToNextField(hotkey),
			goToPrevField: (event: SyntheticEvent<HTMLElement>, hotkey: string) => this.props.goToPrevField(hotkey),
			editField: (event: SyntheticEvent<HTMLElement>, hotkey: string) => this.props.editField(hotkey),
			cancelEditField: (event: SyntheticEvent<HTMLElement>, hotkey: string) => this.props.cancelEditField(hotkey),
			resetField: (event: SyntheticEvent<HTMLElement>, hotkey: string) => this.props.resetField(hotkey),
			addRepeatableSection: (event: SyntheticEvent<HTMLElement>, hotkey: string) =>
				this.props.addRepeatableSection(hotkey),
			removeRepeatableSection: (event: SyntheticEvent<HTMLElement>, hotkey: string) =>
				this.props.removeRepeatableSection(hotkey),
		}
	}

	async componentDidMount() {
		const { documentId, organizationId } = this.props
		this.props.initDocumentExtraction()
		this.props.loadDocumentMetadata(documentId, organizationId)
		inactivityMonitor.init(60 * 3, this.props.onInactivity)
	}

	componentWillUnmount() {
		inactivityMonitor.shutdown()
	}

	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		const { documentId } = this.props
		if (documentId !== nextProps.documentId) {
			this.props.initDocumentExtraction()
			this.props.loadDocumentMetadata(nextProps.documentId, nextProps.organizationId)
		}
	}

	render() {
		return (
			<HotKeys focused attach={window} keyMap={keyMap} handlers={this.handlerMap} style={{ outline: 'none' }}>
				<DocumentExtraction
					documentId={this.props.documentId}
					extractionDocument={this.props.extractionDocument}
					fieldBbWordMap={this.props.fieldBbWordMap}
					bbWordFieldMap={this.props.bbWordFieldMap}
					organization={this.props.organization}
					onSave={this.props.onSave}
					nextDocumentExtraction={this.props.nextDocumentExtraction}
					working={this.props.working}
				/>
			</HotKeys>
		)
	}
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(DocumentExtractionContainer)
