// @flow
import React from 'react'
import type { OrganizationStatisticsKeysBankAccounts, OrganizationStatisticsKeysBankAccount } from 'types'
import styles from './statements-summary.css'
import { TriviLink } from 'components'

type Props = {|
	organizationId: string,
	bankAccounts: OrganizationStatisticsKeysBankAccounts,
	handleStatementClick: (organizationId: string, bankAccountId: string) => Promise<void>,
|}

export default function StatementsSummary(props: Props) {
	const { bankAccounts, handleStatementClick, organizationId } = props
	const accountsArray = bankAccounts.bankAccounts || []

	const getStatementClickHandler = (bankAccountId: string) => () => {
		handleStatementClick(organizationId, bankAccountId)
	}

	return (
		<table className={styles.tooltipTable}>
			<tbody>
				{accountsArray.map((bankAccount: OrganizationStatisticsKeysBankAccount, index: number) => {
					return (
						<tr key={index}>
							<td>
								<TriviLink onClick={getStatementClickHandler(bankAccount.bankAccountId || '')}>
									{bankAccount.bankAccountName}
								</TriviLink>
							</td>
							<td>{bankAccount.statements && bankAccount.statements.unprocessedCount}</td>
						</tr>
					)
				})}
			</tbody>
		</table>
	)
}
