/* @flow */
/** @jsx jsx */

import { Component } from 'react'
import { jsx } from '@emotion/core'
import { IconButton, Popover } from 'components'
import type { PortalLanguage, WithTranslateProps, WithRouterProps } from 'types'
import GearIcon from 'components/svg-icons/trivi/menu/gear'
import LanguageIcon from 'components/svg-icons/action/language'
import Lock from 'components/svg-icons/action/lock-open'
import Tooltip from 'components/tooltip'
import UserProfileAvatar from '../user-icon/user-profile-avatar'
import { colors } from 'variables'
import { getUserSettingsRoute } from 'modules/user/routing/routes'
import { withTranslate } from 'wrappers'
import { withRouter } from 'react-router'
import NotificationList from 'modules/notifications/containers/notification-list'
import { isEnabled } from 'utils/features'
import UserLanguageSelectorNext from '../../../common/components/language-selector/user-language-selector-next'

type Props = WithTranslateProps &
	WithRouterProps & {|
		name: ?string,
		open: boolean,
		anchorEl: any,
		onRequestClose: () => void,
		onLogout: () => void,
		avatar: ?string,
		language: ?PortalLanguage,
		onLanguageChange: (userLanguage: ?PortalLanguage) => void,
	|}

class UserPanel extends Component<Props> {
	anchorOrigin = { horizontal: 'right', vertical: 'bottom' }
	targetOrigin = { horizontal: 'right', vertical: 'top' }

	goToSettings = () => {
		this.props.history.push(getUserSettingsRoute(), {
			referer: this.props.location.pathname,
		})
	}

	render() {
		const { t, avatar, name, anchorEl, open, onRequestClose, onLogout } = this.props
		return (
			<Popover
				open={open}
				anchorEl={anchorEl}
				anchorOrigin={this.anchorOrigin}
				targetOrigin={this.targetOrigin}
				zDepth={7}
				style={style.root}
				onRequestClose={onRequestClose}
				useLayerForClickAway
			>
				<div css={style.head}>
					<div css={style.avatar}>
						<UserProfileAvatar size={44} avatar={avatar} name={name} />
					</div>
					{name}
					<Tooltip label={t('user.login.logoutTooltip')} wrapperStyle={style.wrapper} style={style.tooltip}>
						<IconButton
							autoTestId="user-panel-logout"
							onClick={onLogout}
							color={colors.white}
							hoverColor={colors.grey200}
						>
							<Lock />
						</IconButton>
					</Tooltip>
				</div>

				<div css={style.settings} onClick={this.goToSettings}>
					<GearIcon color={colors.white} style={style.icon} />
					{t('user.settings.link')}
				</div>

				<div css={style.language}>
					<LanguageIcon color={colors.white} hoverColor={colors.white} style={style.icon} />
					<span css={style.label}>{t('user.settings.language.title')}:</span>
					<UserLanguageSelectorNext
						userPanelStyle
						language={this.props.language}
						onLanguageChange={this.props.onLanguageChange}
					/>
				</div>
				{isEnabled('notifications') && <NotificationList />}
			</Popover>
		)
	}
}

const style = {
	root: {
		position: 'relative',
		width: 360,
		height: 'auto',
		maxHeight: 590,
		borderRadius: 7,
		background: colors.blue,
		marginTop: 8,
		overflow: 'hidden',
	},
	head: {
		background: colors.blue,
		textAlign: 'center',
		borderBottom: '1px solid #0da6c0',
		fontSize: 16,
		fontWeight: 'bold',
		color: colors.white,
		lineHeight: '40px',
		padding: '15px 0 5px 0',
		borderRadius: '7px 7px 0 0',
	},
	avatar: {
		border: '2px solid white',
		margin: '3px auto',
		width: 48,
		height: 48,
		borderRadius: '50%',
		background: colors.white,
	},
	wrapper: {
		position: 'absolute',
		top: 5,
		right: 8,
	},
	tooltip: {
		marginTop: -10,
	},
	icon: {
		verticalAlign: 'middle',
		marginRight: 15,
		marginTop: -2,
		height: 36,
	},
	language: {
		fontSize: 14,
		background: colors.blue,
		color: colors.white,
		lineHeight: '24px',
		padding: '10px 25px 15px 25px',
		borderRadius: '0 0 7px 7px',
		verticalAlign: 'middle',
		display: 'flex',
		alignItems: 'center',
		label: {
			color: '#fff !important',
		},
		div: {
			color: '#fff', //!important
		},
	},
	label: {
		marginRight: 3,
	},
	settings: {
		background: colors.blue,
		fontSize: 14,
		color: colors.white,
		lineHeight: '24px',
		padding: '15px 25px 10px 25px',
		borderRadius: '0 0 7px 7px',
		verticalAlign: 'middle',
		cursor: 'pointer',
		'&:hover': {
			textDecoration: 'underline',
		},
	},
}

export default withRouter(withTranslate(UserPanel))
