/* @flow */
/** @jsx jsx */

import type { AccountingDocumentBankAccount, BankAccount, Contact } from 'types'
import { type AutoTestProps, autoTestId, passAutoTestId } from 'utils/tests/autotest'
import { Menu, MenuItem, Popover } from 'components'
import { type WithTranslateProps, withTranslate } from 'wrappers'

import { AccountingDocumentBankAccount_isEmpty } from 'types/operations'
import { BankAccounts_findById } from 'modules/accounting-document/domain/bank-account'
import { Component } from 'react'
import ContentAdd from 'components/svg-icons/content/add'
import EnhancedButton from 'components/internal/EnhancedButton'
import IconTextButton from 'components/icon-text-button'
import { colors } from 'variables'
import { getRemainingBankAccounts } from 'helpers'
import { jsx } from '@emotion/core'
import memoize from 'memoize-one'
import styles from './add-bank-account.css'

export type Props = {|
	accountingDocumentId: string,
	direction: 'issued' | 'received',
	readonly?: boolean,
	bankAccounts: Array<AccountingDocumentBankAccount>,
	availableBankAccounts: Array<AccountingDocumentBankAccount>,
	onBankAccountAttach: (bankAccount: AccountingDocumentBankAccount) => void,
	onBankAccountCreate?: (bankAccount?: AccountingDocumentBankAccount) => void,
	buttonText?: string,
	hideWhenZeroAccounts?: boolean,
	accountingDocumentContact: ?Contact,
	accountingDocumentContactId: ?string,
	isCreditNote?: boolean,
	loadContact: (id: string) => void,
	organizationBankAccounts: Array<BankAccount>,
	...AutoTestProps,
|}

type ComponentProps = { ...Props, ...WithTranslateProps }

type State = {|
	accountMenuOpen: boolean,
	hovered: boolean,
|}

class AddBankAccount extends Component<ComponentProps, State> {
	anchor = null
	anchorOrigin = { horizontal: 'left', vertical: 'bottom' }
	targetOrigin = { horizontal: 'left', vertical: 'top' }

	state: State = {
		accountMenuOpen: false,
		hovered: false,
	}

	UNSAFE_componentWillMount() {
		if (this.props.accountingDocumentContactId && !this.props.accountingDocumentContact) {
			this.props.loadContact(this.props.accountingDocumentContactId)
		}
	}

	handleAvailableBankAccountsChange = (
		event: SyntheticEvent<HTMLElement>,
		bankAccount: AccountingDocumentBankAccount,
	) => {
		const availableAccounts: Array<AccountingDocumentBankAccount> = this.props.availableBankAccounts || []
		const selectedBankAccount: ?AccountingDocumentBankAccount = availableAccounts.find(
			(account: AccountingDocumentBankAccount) => {
				return bankAccount.bankAccountId != null && account.bankAccountId != null
					? account.bankAccountId === bankAccount.bankAccountId
					: bankAccount.number === account.number && bankAccount.bankCode === account.bankCode
			},
		)
		if (selectedBankAccount) {
			selectedBankAccount.bankAccountId && this.props.onBankAccountAttach(selectedBankAccount)
			!selectedBankAccount.bankAccountId &&
				this.props.onBankAccountCreate &&
				this.props.onBankAccountCreate(selectedBankAccount)
		}

		this.setState({ accountMenuOpen: false })
	}

	someEmptyBankAccount = (): boolean => {
		return this.props.bankAccounts.some((ba: AccountingDocumentBankAccount) => {
			return AccountingDocumentBankAccount_isEmpty(ba)
		})
	}

	createEmptyBankAccount = () => {
		if (this.someEmptyBankAccount()) {
			return undefined
		}
		this.props.onBankAccountCreate && this.props.onBankAccountCreate()
		this.closeAccountMenu()
	}

	openAccountMenu = () => {
		this.setState({ accountMenuOpen: true })
	}

	closeAccountMenu = () => {
		this.setState({ accountMenuOpen: false })
	}

	bindAnchor = (element: any) => {
		this.anchor = element
	}

	handleMouseLeave = () => {
		this.setState({
			hovered: false,
		})
	}

	handleMouseEnter = () => {
		this.setState({
			hovered: true,
		})
	}

	getButton(handler: () => void) {
		const style = this.getStyles(this.state.accountMenuOpen, this.state.hovered)
		if (this.props.bankAccounts.length === 0) {
			return (
				<div className={styles.add} ref={this.bindAnchor}>
					<EnhancedButton
						onClick={handler}
						onMouseLeave={this.handleMouseLeave}
						onMouseEnter={this.handleMouseEnter}
						style={style.button}
						{...passAutoTestId(this.props.autoTestId, 'select-bank-account')}
					>
						<div css={style.label}>{this.props.buttonText}</div>
					</EnhancedButton>
				</div>
			)
		}

		return (
			<div>
				<div className={styles.addNext} ref={this.bindAnchor}>
					<IconTextButton
						icon={<ContentAdd />}
						labelText={this.props.t('invoice.addNextBankAccount')}
						onClick={handler}
					/>
				</div>
			</div>
		)
	}

	renderAddAccount = () => {
		const isFirst = this.props.bankAccounts.length < 1
		const accounts = getRemainingBankAccounts(this.props.availableBankAccounts, this.props.bankAccounts)
		if (accounts.length <= 0) {
			return this.getButton(this.createEmptyBankAccount)
		}
		const handler =
			this.props.direction === 'received' && !this.props.isCreditNote
				? this.createEmptyBankAccount
				: this.openAccountMenu
		return (
			<div className={styles.root}>
				{this.getButton(handler)}
				<Popover
					anchorEl={this.anchor}
					open={this.state.accountMenuOpen && accounts.length > 0}
					onRequestClose={this.closeAccountMenu}
					anchorOrigin={this.anchorOrigin}
					targetOrigin={this.targetOrigin}
					arrow={'topLeft'}
					zDepth={6}
					stretch={isFirst}
					maxHeight={400}
					autoTestId="invoice-add-bank-account-popover"
				>
					<Menu
						autoTestId="invoice-add-bank-account-menu"
						onChange={this.handleAvailableBankAccountsChange}
						fullWidth={isFirst}
					>
						{accounts.map((item: AccountingDocumentBankAccount, index: number) => {
							const account =
								item.bankAccountId && BankAccounts_findById(this.props.organizationBankAccounts, item.bankAccountId)
							return (
								<MenuItem
									key={index}
									value={item}
									primaryText={((account && account.name) || '') + ' ' + ((account && account.currency) || '')}
									rightText={
										item.displayNumber ? item.displayNumber : (item.number || '') + '/' + (item.bankCode || '')
									}
								/>
							)
						})}
					</Menu>
				</Popover>
			</div>
		)
	}

	getStyles = memoize((accountMenuOpen: boolean, hovered: boolean) => {
		return {
			button: {
				display: 'block',
				width: '100%',
				textAlign: 'left',
				height: 27,
				lineHeight: '27px',
				marginTop: 4,
				borderWidth: 1,
				borderStyle: 'solid',
				borderRadius: 3,
				borderColor: accountMenuOpen || hovered ? colors.blue : 'transparent',
				backgroundColor: accountMenuOpen || hovered ? colors.grey100 : 'transparent',
				boxShadow: accountMenuOpen || hovered ? `0 3px 4px 0 ${colors.blueFaded20}` : null,
			},
			label: {
				padding: '0 8px',
				width: '100%',
				textTransform: 'none',
				fontWeight: 600,
				fontSize: 15,
			},
		}
	})

	render() {
		if (this.props.hideWhenZeroAccounts && this.props.bankAccounts.length < 1) {
			return null
		}

		return (
			<div className={styles.root} {...autoTestId('invoice-add-bank-account')}>
				{!this.props.readonly && this.renderAddAccount()}
			</div>
		)
	}
}

export default withTranslate(AddBankAccount)
