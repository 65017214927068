import React from 'react'
import SvgIcon from 'components/SvgIcon'
import pure from 'recompose/pure'

let BreadcrumbArrow = props => (
	<SvgIcon {...props}>
		<g stroke="none" strokeWidth="1" fill="#000000" fillRule="evenodd">
			<path d="M8.51269531,19.4853516 C8.4443356,19.4853516 8.37841829,19.4731447 8.31494141,19.4487305 C8.25146453,19.4243163 8.19531274,19.3876956 8.14648438,19.3388672 C8.04882764,19.2412104 8,19.1240241 8,18.9873047 C8,18.8505853 8.04882764,18.7333989 8.14648438,18.6357422 L14.8408203,11.9414062 L8.24902344,5.36425781 C8.1513667,5.26660107 8.10253906,5.14697336 8.10253906,5.00537109 C8.10253906,4.86376882 8.1513667,4.74414111 8.24902344,4.64648438 C8.34668018,4.54882764 8.46630789,4.5 8.60791016,4.5 C8.74951243,4.5 8.86914014,4.54882764 8.96679688,4.64648438 L15.8955078,11.5898438 C15.9931646,11.6875005 16.0419922,11.8046868 16.0419922,11.9414062 C16.0419922,12.0781257 15.9931646,12.195312 15.8955078,12.2929688 L8.86425781,19.3388672 C8.81542944,19.3876956 8.75927766,19.4243163 8.69580078,19.4487305 C8.6323239,19.4731447 8.57128936,19.4853516 8.51269531,19.4853516 Z" />
		</g>
	</SvgIcon>
)
BreadcrumbArrow = pure(BreadcrumbArrow)

export default BreadcrumbArrow
