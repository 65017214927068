/* @flow */
/** @jsx jsx */

import { jsx } from '@emotion/core'
import type { AccountingDocumentIssue, AccountingDocumentIssues } from 'types'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import { getIssueSeverity } from '../../domain/issues'
import { colors } from 'variables'
import Issue from './issue'

type Props = {|
	...WithTranslateProps,
	accountingDocumentId: string,
	issuesResponse: ?AccountingDocumentIssues,
	hideTitle?: boolean,
	autoload: () => void,
|}

function Issues(props: Props) {
	if (!props.issuesResponse) return null

	const connectedIssues = props.issuesResponse.connectedIssues || []
	const issues = props.issuesResponse.issues || []
	if (!(connectedIssues.length + issues.length)) return null

	function issueMapper(issue: AccountingDocumentIssue) {
		return (
			<Issue key={`${issue.code || ''}${issue.accountingDocumentId || ''}`} severity={getIssueSeverity(issue.severity)}>
				{props.t(['serverError.' + (issue.code || ''), issue.message || ''], issue.params)}
			</Issue>
		)
	}

	return (
		<div css={styles.root}>
			{issues.length > 0 && (
				<div css={styles.section}>
					{!props.hideTitle && <div css={styles.heading}>{props.t('accountingDocument.issues.issues-header')}</div>}
					<div>{issues.map(issueMapper)}</div>
				</div>
			)}

			{connectedIssues.length > 0 && (
				<div css={styles.section}>
					{!props.hideTitle && (
						<div css={styles.heading}>{props.t('accountingDocument.issues.connected-issues-header')}</div>
					)}
					<div>{connectedIssues.map(issueMapper)}</div>
				</div>
			)}
		</div>
	)
}

const styles = {
	root: {
		paddingRight: 10,
	},
	section: {
		marginBottom: 30,
		display: 'block',
	},
	heading: {
		padding: 0,
		fontSize: 24,
		color: colors.black,
		fontWeight: 'bold',
		letterSpacing: 0,
		lineHeight: '32px',
		verticalAlign: 'middle',
		pointerEvents: 'none',
		userSelect: 'none',
		margin: '10px 0',
	},
	issue: {
		padding: '7px 10px',
		position: 'relative',
		display: 'block',
		fontSize: 16,
		color: colors.black,
		lineHeight: '20px',
		overflow: 'hidden',
		wordWrap: 'break-word',
		marginBottom: 10,
	},
	red: {
		backgroundColor: colors.redFaded60,
	},
	blue: {
		backgroundColor: colors.blue300,
	},
	yellow: {
		backgroundColor: colors.yellow300,
	},
}

export default withTranslate(Issues)
