// @flow

import { connect } from 'react-redux'
import withAutoload from 'wrappers/with-autoload'
import type { Dispatch, EmailTemplate, State } from 'types'
import type { Action as EmailAction } from 'modules/settings/actions/email-action-types'
import EditEmailTemplate from 'modules/settings/components/trivi-settings/edit-email-template'
import { createEmailTemplate, loadEmailTemplates, editEmailTemplate } from 'modules/settings/actions/email-actions'

type StateProps = {|
	emailTemplates: ?Array<EmailTemplate>,
|}

const mapStateToProps = (state: State): StateProps => {
	return {
		emailTemplates: state.settings.emailTemplates,
	}
}

type DispatchProps = {|
	autoload: () => void,
	editEmailTemplate: (template: EmailTemplate) => void,
	createEmailTemplate: (template: EmailTemplate) => void,
|}

const mapDispatchToProps = (dispatch: Dispatch<EmailAction>): DispatchProps => {
	return {
		autoload: () => {
			dispatch(loadEmailTemplates())
		},
		createEmailTemplate: (template: EmailTemplate) => {
			dispatch(createEmailTemplate(template))
		},
		editEmailTemplate: (template: EmailTemplate) => {
			template.id && dispatch(editEmailTemplate(template.id, template))
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(withAutoload(EditEmailTemplate))
