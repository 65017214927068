import React from 'react'
import pure from 'recompose/pure'
import SvgIcon from '../../SvgIcon'

let NavigationChevronDown = props => (
	<SvgIcon {...props} style={{ transform: 'rotate(90deg)' }}>
		<path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
	</SvgIcon>
)
NavigationChevronDown = pure(NavigationChevronDown)

export default NavigationChevronDown
