import React from 'react'
import SvgIcon from 'components/SvgIcon'
import pure from 'recompose/pure'

let Error = props => {
	props = Object.assign({}, props, { viewBox: '0 0 12 12' })

	return (
		<SvgIcon {...props}>
			<g stroke="none" fill="none">
				<path
					d="M6.59134615,6.59134615 L6.59134615,2.98557692 L5.40865385,2.98557692 L5.40865385,6.59134615 L6.59134615,6.59134615 Z M6.59134615,9.01442308 L6.59134615,7.80288462 L5.40865385,7.80288462 L5.40865385,9.01442308 L6.59134615,9.01442308 Z M6,-1.0658141e-14 C7.09615933,-1.0658141e-14 8.11057226,0.274035721 9.04326923,0.822115385 C9.9471199,1.34134875 10.6586513,2.0528801 11.1778846,2.95673077 C11.7259643,3.88942774 12,4.90384067 12,6 C12,7.09615933 11.7259643,8.11057226 11.1778846,9.04326923 C10.6586513,9.9471199 9.9471199,10.6586512 9.04326923,11.1778846 C8.11057226,11.7259643 7.09615933,12 6,12 C4.90384067,12 3.88942774,11.7259643 2.95673077,11.1778846 C2.0528801,10.6490358 1.34134875,9.93269683 0.822115385,9.02884615 C0.274035721,8.09614918 1.22124533e-14,7.08654389 1.22124533e-14,6 C1.22124533e-14,4.91345611 0.274035721,3.90385082 0.822115385,2.97115385 C1.35096418,2.06730317 2.06730317,1.35096418 2.97115385,0.822115385 C3.90385082,0.274035721 4.91345611,-1.0658141e-14 6,-1.0658141e-14 Z"
					fill="#FFFFFF"
				/>
			</g>
		</SvgIcon>
	)
}
Error = pure(Error)

export default Error
