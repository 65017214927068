/* @flow */

// $FlowFixMe - there is useEffect
import React, { useEffect, useState } from 'react'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import type { AccountingDocumentDirection, AccountingDocumentView } from 'types'
import TabSwitches from 'components/TabSwitches/TabSwitches'
import TabSwitch from 'components/TabSwitches/TabSwitch'

export type Props = {|
	...WithTranslateProps,
	showTabs: boolean,
	view: AccountingDocumentView,
	direction: ?AccountingDocumentDirection,
	onChangeDirection: (direction: AccountingDocumentDirection, redirect?: boolean) => void,
	stateFilter?: Object,
|}

function CashRegisterDirectionViewSwitcher(props: Props) {
	const [filter, setFilter] = useState({
		'cashRegisterPayments/unknown': {},
		'cashRegisterPayments/issued': {},
		'cashRegisterPayments/received': {},
	})

	useEffect(() => {
		setFilter(props.stateFilter)
	}, [props.stateFilter])

	return (
		<TabSwitches
			autoTestId="direction-view-compact-tabs"
			onChange={(value: AccountingDocumentDirection) => props.onChangeDirection(value, filter)}
			value={props.direction}
			fullWidth
			compact
		>
			<TabSwitch key="all" value={'unknown'} label={props.t('accountingDocument.filter.tabs.all')} />
			<TabSwitch key="income" value={'received'} label={props.t('cashRegister.direction.issued')} />
			<TabSwitch key="expense" value={'issued'} label={props.t('cashRegister.direction.received')} />
		</TabSwitches>
	)
}

export default withTranslate(CashRegisterDirectionViewSwitcher)
