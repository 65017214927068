/* @flow */

import BankAccount from 'modules/bank/containers/bank-account-page'
import BankAccountCreatePage from 'modules/bank/pages/bank-account-create-page'
import BankAccountEdit from 'modules/bank/pages/bank-account-edit-page'
import BankAccountList from 'modules/bank/pages/bank-account-list-page'
import MainLayout from 'layouts/main-layout'
import React from 'react'
import { Route } from 'react-router'

// Libraries
import { UserIsAuthenticated, UserInternalPermission } from 'lib/auth'

const ListComponent = UserIsAuthenticated(MainLayout('bank')(BankAccountList))
const EditComponent = UserIsAuthenticated(MainLayout('bank')(BankAccountEdit))
const CreatePageComponent = UserInternalPermission(UserIsAuthenticated(MainLayout('bank')(BankAccountCreatePage)))
const DetailComponent = UserIsAuthenticated(MainLayout('bank')(BankAccount))

const BankRouting = () => [
	<Route exact key="/:organizationId/bank" path="/:organizationId/bank" component={ListComponent} />,
	<Route
		exact
		key="/:organizationId/bank-account/:id/edit"
		path="/:organizationId/bank-account/:id/edit"
		component={EditComponent}
	/>,
	<Route
		exact
		key="/:organizationId/bank-account/create"
		path="/:organizationId/bank-account/create"
		component={CreatePageComponent}
	/>,
	<Route
		exact
		key="/:organizationId/bank-account/:id/statements"
		path="/:organizationId/bank-account/:id/statements"
		component={DetailComponent}
	/>,
	<Route
		exact
		key="/:organizationId/bank-account/:id/orders"
		path="/:organizationId/bank-account/:id/orders"
		component={DetailComponent}
	/>,
	<Route exact key="/:organizationId/bank-account" path="/:organizationId/bank-account" component={ListComponent} />,
]

export default BankRouting
