// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1zo9_-Fm7tABmTplJ3eORa{\n\ttext-align: center!important;\n}\n", "",{"version":3,"sources":["webpack://./modules/accounting-document/components/template-grid-next.css"],"names":[],"mappings":"AAAA;CACC,4BAAA;AACD","sourcesContent":[".center{\n\ttext-align: center!important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"center": "_1zo9_-Fm7tABmTplJ3eORa"
};
export default ___CSS_LOADER_EXPORT___;
