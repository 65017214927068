/* @flow */

import { connect } from 'react-redux'
import type { Dispatch, OrganizationMembers, State, Task, TaskAction, FileAction, FileDescription } from 'types'
import TaskComponent from '../components/task'
import type { Props as TaskProps } from '../components/task'
import { updateTask } from '../actions'
import { downloadWithToken } from 'modules/file/actions'
import { EMPTY_ARRAY } from 'trivi-constants'
import { getCurrentUserOrganizations } from '../../user/selectors'

type Props = {|
	id: string,
|}

type StateProps = {|
	organizations: OrganizationMembers,
	task: ?Task,
|}

function mapStateToProps(state: State, ownProps: Props): StateProps {
	return {
		organizations: getCurrentUserOrganizations(state) || EMPTY_ARRAY,
		task: state.task.task[ownProps.id] && state.task.task[ownProps.id].data,
	}
}

type DispatchProps = {|
	changeState: (Task, number) => void,
	onFileOpen: (file: FileDescription) => void,
|}

function mapDispatchToProps(dispatch: Dispatch<TaskAction | FileAction>): DispatchProps {
	return {
		changeState: (task: Task, state: number) => {
			dispatch(updateTask(task, { ...task, state }))
		},
		onFileOpen: (file: FileDescription) => {
			if (file.fileId) {
				dispatch(downloadWithToken(file.fileId))
			}
		},
	}
}

function mergeProps(stateProps: StateProps, dispatchProps: DispatchProps): TaskProps {
	return {
		organizations: stateProps.organizations,
		task: stateProps.task,
		changeState: dispatchProps.changeState,
		onFileOpen: dispatchProps.onFileOpen,
	}
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(TaskComponent)
