/* @flow */

const BLUR_CLASS = 'blur'

export function setScrollLockOnBody(lock: boolean) {
	if (lock) {
		if (document.body) document.body.style.overflow = 'hidden'
	} else {
		if (document.body) document.body.style.overflow = ''
	}
}

export function setBlurOnBackground(blur: boolean) {
	const root = document.getElementById('root')
	if (blur) {
		root && root.classList.add(BLUR_CLASS)
	} else {
		root && root.classList.remove(BLUR_CLASS)
	}
}

declare var CSS: {|
	supports: (string, ?string) => boolean,
|}

export function getCSSBlurSupport(): boolean {
	return !!window.MSInputMethodContext && !!document.documentMode
		? false
		: CSS &&
				CSS.supports &&
				(CSS.supports('backdrop-filter', 'blur(9px)') || CSS.supports('-webkit-backdrop-filter', 'blur(9px)'))
}
