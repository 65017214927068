// @flow

import { createStore, applyMiddleware, compose } from 'redux'
import { routerMiddleware } from 'react-router-redux'
import { createBrowserHistory } from 'history'
import thunk from 'redux-thunk'
import { changeCurrentOrganization } from 'modules/user/actions'
import storage from 'utils/local-storage'
import eventMiddleware from '../utils/events'
import signalRInvokeMiddleware from 'modules/notifications/middlewares'

// import the root reducer
import rootReducer from './root-reducer'

export const history = createBrowserHistory()

const middleware = [thunk, routerMiddleware(history), eventMiddleware, signalRInvokeMiddleware]

const tools = []

if (window.__REDUX_DEVTOOLS_EXTENSION__) {
	tools.push(window.__REDUX_DEVTOOLS_EXTENSION__())
}

const store = createStore(rootReducer, {}, compose(applyMiddleware(...middleware), ...tools))
store.dispatch(changeCurrentOrganization(storage.get('organizationId') || ''))

if (module.hot) {
	module.hot.accept('./', () => {
		const nextRootReducer = require('./root-reducer').default
		store.replaceReducer(nextRootReducer)
	})
}

export default store
