/* @flow */

import type { CardResponse } from 'types'

export function findMinimalDate(cards: ?Array<CardResponse>): Date {
	if (!cards || !cards.length) return new Date()
	return cards.reduce((date: Date, card: CardResponse) => {
		return new Date(Math.min(date, new Date(card.deadlineDate || new Date())))
	}, new Date())
}

export function findMaximalDate(cards: ?Array<CardResponse>): Date {
	if (!cards || !cards.length) return new Date()
	return cards.reduce((date: Date, card: CardResponse) => {
		return new Date(Math.max(date, new Date(card.deadlineDate || new Date())))
	}, new Date())
}
