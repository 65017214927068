/* @flow */
import type { I18NextTranslateFn } from 'types'

export type NavigationItem = {
	id: string,
	direction?: 'received' | 'issued',
	value?: string,
	params?: { [string]: any },
	label: string,
	icon?: React$Element<any>,
	tall?: boolean,
}

export type Section = {
	label?: string,
	type?: 'received' | 'issued',
	items: Array<NavigationItem>,
}

type SectionRules = {|
	showIssued: boolean,
	showReceived: boolean,
	showUpload: boolean,
	showCreateContact: boolean,
	// showCreateTask: boolean,
|}

function getIssuedSection(t: I18NextTranslateFn, sectionRules: SectionRules): ?Section {
	if (!sectionRules.showIssued) {
		return null
	}

	return {
		items: [
			{
				id: 'Invoice',
				params: { direction: 'issued' },
				label: t('navigation.accountingDocument.newInvoice'),
			},
			{
				id: 'TaxAdvance',
				params: { direction: 'issued' },
				label: t('navigation.accountingDocument.newTaxAdvance'),
			},
			{
				id: 'Advance',
				params: { direction: 'issued' },
				label: t('navigation.accountingDocument.newAdvance'),
			},
			{
				id: 'CreditNote',
				params: { direction: 'issued' },
				label: t('navigation.accountingDocument.newCreditNote'),
			},
		],
	}
}

export function getSections(t: I18NextTranslateFn, sectionRules: SectionRules): Array<Section> {
	const sections: Array<Section> = []

	const issuedSection = getIssuedSection(t, sectionRules)

	issuedSection && sections.push(issuedSection)

	return sections
}
