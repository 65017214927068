// @flow

import type { Node } from 'react'
import React, { Component } from 'react'
import { HotKeys } from 'react-hotkeys'
import type { HotKeyHandler, HotKeyMap } from '../types'

type Props = {|
	hotkeyMap: HotKeyMap,
	children: Node,
	...HandlerMapProps,
|}

export type HandlerMapProps = {|
	altDown: HotKeyHandler,
	altUp: HotKeyHandler,
|}

class AppHotKeys extends Component<Props> {
	render() {
		const { hotkeyMap, children, ...handlers } = this.props

		return (
			<HotKeys
				focused
				attach={window}
				keyMap={hotkeyMap}
				handlers={handlers}
				style={{ outline: 'none', height: '100%', maxHeight: '100%' }}
			>
				{children}
			</HotKeys>
		)
	}
}

export default AppHotKeys
