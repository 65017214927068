/* @flow */

import React, { Component } from 'react'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import type { TagType, Filter } from 'types'
import BasicGridFilter from 'modules/data-grid/components/basic-grid-filter'
import { FilterTextField } from 'modules/data-grid/components/data-grid-filter'
import styles from './tags-advanced-filter.css'
import Button from 'components/button'

type Props = {|
	...WithTranslateProps,
	onTagCreateClick: () => void,
	tagType: TagType,
	gridId: string,
	defaultFilter?: Filter,
|}

class TagsAdvancedFilter extends Component<Props> {
	render() {
		const { t } = this.props

		const noFilterLabel = {
			project: t('settings.reporting.allProjects'),
			branch: t('settings.reporting.allBranches'),
		}[this.props.tagType]

		const addTooltip = {
			project: t('settings.reporting.addProjectTag'),
			branch: t('settings.reporting.addBranchTag'),
		}[this.props.tagType]

		return (
			<BasicGridFilter
				name={this.props.gridId}
				gridId={this.props.gridId}
				dataType="tags"
				textFilterName="searchText"
				noFilterLabel={noFilterLabel}
				leftElement={<Button primary labelText={addTooltip} onClick={this.props.onTagCreateClick} />}
				default
				defaultFilter={this.props.defaultFilter}
			>
				<FilterTextField className={styles.column} name="name" labelText={t('settings.reporting.name')} />
				<FilterTextField className={styles.column} name="code" labelText={t('settings.reporting.code')} />
			</BasicGridFilter>
		)
	}
}

export default withTranslate(TagsAdvancedFilter)
