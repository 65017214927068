/* @flow */
/** @jsx jsx */

import { Component } from 'react'
import { jsx } from '@emotion/core'
import { withTranslate, type WithTranslateProps, withNotify, type WithNotifyProps } from 'wrappers'
import { bankAccountCreateRoute } from 'modules/bank/routing/routes'
import TriviLink from 'modules/common/components/trivi-link'
import AddEntity from 'components/add-entity'
import type { BankAction, Dispatch, State } from 'types'
import { push } from 'react-router-redux'
import { connect } from 'react-redux'
import { currentUserIsInternal } from 'modules/user/selectors'
import { userHasAccess } from 'permissions'
import { colors } from 'variables'

type Props = {|
	...WithNotifyProps,
	...WithTranslateProps,
	bankAccountsCount: number,
	currentUserIsInternal: boolean,
	canEditBankAccount: boolean,
	redirectToBankAccountCreateRoute: () => void,
|}

const mapStateToProps = (state: State) => {
	return {
		bankAccountsCount: state.bank.bankAccounts.data.length,
		currentUserIsInternal: currentUserIsInternal(state),
		canEditBankAccount: userHasAccess(state, 'editBankAccounts'),
	}
}

const mapDispatchToProps = (dispatch: Dispatch<BankAction>) => {
	return {
		redirectToBankAccountCreateRoute: () => {
			dispatch(push(bankAccountCreateRoute()))
		},
	}
}

class BankAccountItemNew extends Component<Props> {
	onClick = () => {
		if (this.props.bankAccountsCount >= 20) {
			this.props.notify('bank.maxBankAccountsWarning', 'info')
			return
		}
		this.props.redirectToBankAccountCreateRoute && this.props.redirectToBankAccountCreateRoute()
	}

	render() {
		const disabled = !this.props.canEditBankAccount || !this.props.currentUserIsInternal

		return (
			<TriviLink disabled={disabled} onClick={this.onClick} style={style.link}>
				<AddEntity
					style={style.add}
					disabled={disabled}
					labelText={disabled ? this.props.t('bank.standardUserCantAddNew') : this.props.t('bank.createNewBankAccount')}
				/>
			</TriviLink>
		)
	}
}

const style = {
	link: { display: 'block', textDecoration: 'none' },
	add: { height: 110 },
	disabled: {
		borderWidth: 1,
		borderStyle: 'dashed',
		borderColor: colors.gray400,
		borderRadius: 3,
		background: colors.whiteFaded60,
		fontSize: 16,
		color: colors.black,
		lineHeight: '20px',
		textAlign: 'center',
		paddingLeft: 90,
		paddingRight: 90,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		width: '100%',
		height: 110,
	},
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslate(withNotify(BankAccountItemNew)))
