// @flow

import React, { Component } from 'react'
import SelectNext from 'components/select-next'

type LangObj<L> = {
	key: L,
	name: string,
}

export type Props<L> = {|
	language: ?L,
	onChange: (?L) => void | Promise<*>,
	availableLanguages: Array<LangObj<L>>,
	labelText?: ?string,
	hintText?: string,
	infoText?: string,
	infoTextAlign?: string,
	infoTextAlignArrow?: string,
	readonly?: boolean,
	compact?: boolean,
	_extraProps?: Object,
|}

export default class LanguageSelectorNext<L: string> extends Component<Props<L>> {
	onLanguageChange = (language: L) => {
		this.props.onChange(language)
	}

	render() {
		return (
			<SelectNext
				value={this.props.language}
				onChange={this.onLanguageChange}
				disabled={this.props.readonly}
				label={this.props.labelText}
				compact={this.props.compact}
				options={this.props.availableLanguages.map((lang: LangObj<L>) => ({ value: lang.key, label: lang.name }))}
				{...this.props._extraProps}
				placeholder={this.props.hintText}
			/>
		)
	}
}
