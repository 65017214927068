//@flow

import type { Group, GroupsAction, UserAction } from 'types'

export type GroupList = {|
	data: ?Array<Group>,
	loading: boolean,
|}

export type State = {
	list: GroupList,
}

export const initialState: State = {
	list: {
		data: null,
		loading: false,
	},
}

export default (state: State = initialState, action: GroupsAction | UserAction): State => {
	switch (action.type) {
		case 'START_LOAD_BOOTSTRAP':
			return {
				...state,
				list: {
					data: state.list.data,
					loading: true,
				},
			}
		case 'FINISH_LOAD_BOOTSTRAP':
			return {
				...state,
				list: {
					data: action.data && action.data.groups,
					loading: false,
				},
			}
		case 'START_GET_GROUPS': {
			return {
				...state,
				list: {
					data: state.list.data,
					loading: true,
				},
			}
		}
		case 'FINISH_GET_GROUPS': {
			return {
				...state,
				list: {
					data: action.groups,
					loading: false,
				},
			}
		}
		default:
			return state
	}
}
