/* @flow */

import { connect } from 'react-redux'
import type {
	AccountingDocumentReduxAction,
	AccountingDocumentVatRecapInfo,
	Dispatch,
	IssueAction,
	State,
	AccountingDocument,
	Organization,
	AccountingDocumentLineItem,
	AccountingDocumentVatRecapLine,
	AccountingDocumentConnection,
} from 'types'
import {
	getAccountingDocumentVatRecapInfoLoading,
	getAccountingDocument,
	isAccountingDocumentVatFree,
	getAccountingDocumentConnections,
} from 'modules/accounting-document/selectors'
import { currentUserIsInternal } from 'modules/user/selectors'
import { getCurrentOrganization } from 'modules/organization/selectors'
import VatRecap from 'modules/accounting-document/components/invoice-elements/vat-recap'
import { updateAccountingDocumentVatRecapInfo } from 'modules/accounting-document/actions'

type OwnProps = {|
	accountingDocumentId: string,
	readonly?: boolean,
|}

type StateProps = {|
	vatRecapCalculationMode: ?number,
	vatRecapLines: ?Array<AccountingDocumentVatRecapLine>,
	total: ?number,
	totalVatExcl: ?number,
	connections: ?Array<AccountingDocumentConnection>,
	isLoading?: boolean,
	isDomestic?: boolean,
	lineItemsTotal: number,
	lineItemsTotalVatExcl: number,
	isAccDocVatFree?: boolean,
	currentUserIsInternal?: boolean,
	expectedTotal?: number,
	accDocState?: string,
	accDocType?: ?number,
|}

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => {
	const isLoading: boolean = getAccountingDocumentVatRecapInfoLoading(state, ownProps.accountingDocumentId)
	const doc: ?AccountingDocument = getAccountingDocument(state, ownProps.accountingDocumentId)
	const org: ?Organization = getCurrentOrganization(state)
	const lineItems = doc && doc.lineItems
	// $FlowFixMe - swagger model needs to be updated
	const expectedTotal: ?number = doc && doc.price && doc.price.expectedTotal

	return {
		currentUserIsInternal: currentUserIsInternal(state),
		isAccDocVatFree: isAccountingDocumentVatFree(state, ownProps.accountingDocumentId),
		vatRecapCalculationMode: (doc && doc.vatRecapCalculationMode !== undefined && doc.vatRecapCalculationMode) || -1,
		vatRecapLines: doc && doc.vatRecap,
		total: doc && doc.total,
		totalVatExcl: doc && doc.totalVatExcl,
		connections: getAccountingDocumentConnections(state, ownProps.accountingDocumentId),
		isLoading,
		isDomestic: !!(doc && org && doc.currency === org.domesticCurrency),
		lineItemsTotal: lineItems
			? lineItems.reduce((result: number, item: AccountingDocumentLineItem) => {
					return result + item.total
			  }, 0)
			: 0,
		lineItemsTotalVatExcl: lineItems
			? lineItems.reduce((result: number, item: AccountingDocumentLineItem) => {
					return result + item.totalVatExcl
			  }, 0)
			: 0,
		expectedTotal,
		accDocState: (doc && doc.state) ?? undefined,
		accDocType: doc && doc.type,
	}
}

type DispatchProps = {|
	onChange: (vatRecap: AccountingDocumentVatRecapInfo) => void,
|}

const mapDispatchToProps = (
	dispatch: Dispatch<AccountingDocumentReduxAction | IssueAction>,
	ownProps: OwnProps,
): DispatchProps => {
	return {
		onChange: (vatRecap: AccountingDocumentVatRecapInfo) => {
			dispatch(updateAccountingDocumentVatRecapInfo(ownProps.accountingDocumentId, vatRecap))
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(VatRecap)
