/* @flow */

import { PureComponent } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { replace } from 'react-router-redux'
import type { State, AccountingDocument, AccountingDocumentReduxAction, WithRouterProps } from 'types'
import { editAccountingDocumentRoute } from '../routing/routes'
import { getAccountingDocumentRaw } from '../selectors'
import { currentUserIsInternal } from 'modules/user/selectors'
import { isAccDocReadOnly } from '../domain/accounting-document'
import { withAccountingDocumentActions, type WithAccountingDocumentActionsProps } from 'wrappers'

type OwnProps = {|
	...WithAccountingDocumentActionsProps,
	...WithRouterProps,
|}

type StateProps = {|
	accountingDocument: ?AccountingDocument,
	internal: boolean,
|}

type DispatchProps = {|
	loadAccountingDocument: (id: string) => void,
	redirect: (type: 'edit' | 'preview') => void,
|}

export type MergedProps = {
	...OwnProps,
	...StateProps,
	...DispatchProps,
}

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => {
	const accountingDocumentId = ownProps.match.params.id
	return {
		internal: currentUserIsInternal(state),
		accountingDocument: getAccountingDocumentRaw(state, accountingDocumentId),
	}
}

const mapDispatchToProps = (dispatch: Dispatch<AccountingDocumentReduxAction>, ownProps: OwnProps): DispatchProps => {
	const accountingDocumentId = ownProps.match.params.id
	return {
		loadAccountingDocument: (id: string) => {
			dispatch(ownProps.loadAccountingDocument(id))
		},
		redirect: (type: 'edit' | 'preview') => {
			dispatch(replace(editAccountingDocumentRoute(accountingDocumentId, undefined, type), { fromRedirect: true }))
		},
	}
}

class Redir extends PureComponent<MergedProps> {
	componentDidMount() {
		const {
			loadAccountingDocument,
			internal,
			accountingDocument,
			redirect,
			canEditIssuedAccountingDocuments,
			canEditReceivedAccountingDocuments,
		} = this.props
		const accountingDocumentId = this.props.match.params.id

		if (!accountingDocument) {
			loadAccountingDocument(accountingDocumentId)
		} else {
			this.tryToRedirect(
				internal,
				accountingDocument,
				redirect,
				canEditIssuedAccountingDocuments,
				canEditReceivedAccountingDocuments,
			)
		}
	}

	componentDidUpdate() {
		const {
			internal,
			accountingDocument,
			redirect,
			canEditIssuedAccountingDocuments,
			canEditReceivedAccountingDocuments,
		} = this.props

		if (accountingDocument) {
			this.tryToRedirect(
				internal,
				accountingDocument,
				redirect,
				canEditIssuedAccountingDocuments,
				canEditReceivedAccountingDocuments,
			)
		}
	}

	tryToRedirect(
		internal: boolean,
		accountingDocument: ?AccountingDocument,
		redirect: (type: 'edit' | 'preview') => void,
		canEditIssuedAccountingDocuments: boolean,
		canEditReceivedAccountingDocuments: boolean,
	) {
		if (
			accountingDocument &&
			isAccDocReadOnly(
				accountingDocument,
				internal,
				canEditIssuedAccountingDocuments,
				canEditReceivedAccountingDocuments,
			)
		) {
			redirect('preview')
		} else {
			redirect('edit')
		}
	}

	render() {
		return null
	}
}

const RedirRouter = withRouter(Redir)
const WithDocAccAcctionsRedirRouter = withAccountingDocumentActions(RedirRouter)
export default connect(mapStateToProps, mapDispatchToProps)(WithDocAccAcctionsRedirRouter)
