/* @flow */

import React, { Component } from 'react'
import { autoTestId, type AutoTestProps } from 'utils/tests/autotest'
import Avatar from 'components/avatar'
import IconButton from 'components/icon-button'
import { colors } from 'variables'

type Props = {|
	...AutoTestProps,
	labelText: string,
	icon: React$Element<any>,
	onClick?: (SyntheticEvent<HTMLElement>) => any,
	disabled?: boolean,
|}

type State = {|
	hovered: boolean,
|}

class IconTextButton extends Component<Props, State> {
	state = {
		hovered: false,
	}

	handleClick = (event: SyntheticEvent<HTMLElement>) => {
		!this.props.disabled && this.props.onClick && this.props.onClick(event)
	}

	handleMouseLeave = () => {
		!this.props.disabled &&
			this.setState({
				hovered: false,
			})
	}

	handleMouseEnter = () => {
		!this.props.disabled &&
			this.setState({
				hovered: true,
			})
	}

	render() {
		const { hovered } = this.state
		const style = getStyles(this.props, this.state)
		const color = this.props.disabled ? colors.disabled : hovered ? colors.blue600 : colors.blue

		return (
			<div
				style={style.root}
				onClick={this.handleClick}
				onMouseLeave={this.handleMouseLeave}
				onMouseEnter={this.handleMouseEnter}
				{...autoTestId(this.props.autoTestId)}
			>
				<IconButton disabled={this.props.disabled} color={color} hoverColor={color} style={style.icon}>
					<Avatar size={24} borderWidth={2} borderColor={color} backgroundColor="transparent" icon={this.props.icon} />
				</IconButton>
				{this.props.labelText}
			</div>
		)
	}
}

function getStyles(props: Props, state: State) {
	const { hovered } = state
	const { disabled } = props
	return {
		root: {
			display: 'inline-flex',
			alignItems: 'center',
			fontSize: 14,
			color: disabled ? colors.disabled : hovered ? colors.blue600 : colors.blue,
			fontWeight: 600,
			cursor: disabled ? 'auto' : 'pointer',
			letterSpacing: '-0.2px',
		},
		icon: {
			padding: 0,
			marginRight: 6,
			width: 24,
			height: 24,
			borderWidth: 0,
		},
	}
}

export default IconTextButton
