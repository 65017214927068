// @flow
/** @jsx jsx */

// $FlowFixMe - there is useState
import { useRef, useState } from 'react'
import { jsx } from '@emotion/core'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import { accountingDocumentPublicShareableRoute } from '../../routing/routes'
import FileDownload from 'components/svg-icons/file/download'
import SendIcon from 'components/svg-icons/content/send'
import LinkIcon from 'components/svg-icons/action/link'
import IconButton from 'components/icon-button'

type Props = {|
	...WithTranslateProps,
	uniqueId: string,
	accountingDocumentId: string,
	onSendEmail: () => void,
	onDownload: (id: string) => void,
|}

function PreviewActions(props: Props) {
	const [copied, setCopied] = useState<boolean>(false)
	const linkRef = useRef()

	function onDownload() {
		props.accountingDocumentId && props.onDownload(props.accountingDocumentId)
	}

	function copyLink() {
		if (linkRef.current) {
			linkRef.current.select()
			document.execCommand('Copy')
			setCopied(true)
		}
	}

	function handleMouseLeave() {
		setCopied(false)
	}

	return (
		<div css={style.root} onMouseLeave={handleMouseLeave}>
			<IconButton
				circledWhenHovered
				tooltip={copied ? props.t('copyToClipboard.tooltipCopied') : props.t('grid.action.copyShareLink')}
				onClick={copyLink}
			>
				<LinkIcon />
			</IconButton>
			<IconButton circledWhenHovered tooltip={props.t('grid.action.download_pdf')} onClick={onDownload}>
				<FileDownload />
			</IconButton>
			<IconButton circledWhenHovered tooltip={props.t('grid.action.sendEmail')} onClick={props.onSendEmail}>
				<SendIcon />
			</IconButton>
			<input readOnly ref={linkRef} css={style.hidden} value={accountingDocumentPublicShareableRoute(props.uniqueId)} />
		</div>
	)
}

const style = {
	root: {
		display: 'flex',
		alignItems: 'center',

		'> *': {
			marginLeft: 5,
		},
	},
	hidden: {
		position: 'absolute',
		top: -9999,
		left: -9999,
		opacity: 0,
	},
}

export default withTranslate(PreviewActions)
