// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[3].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js!../../../variables/colors.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3ttX5nmP-PSox9ftvMskkR {\n\tmargin-bottom: 15px;\n}\n\n._3ttX5nmP-PSox9ftvMskkR:before {\n\tcontent: '';\n\tdisplay: table;\n}\n\n._3ttX5nmP-PSox9ftvMskkR:after {\n\tcontent: '';\n\tdisplay: table;\n\tclear: both;\n}\n\n._1eF2avD0injj1eb15D3VvB {\n\twidth: 100%;\n\tfloat: left;\n\tclear: none;\n}\n\n._3tP0ZT5AcnUhrGjLHB_Ne4 {\n\tcolor: " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["red"] + ";\n}\n", "",{"version":3,"sources":["webpack://./modules/bank/components/csas-statements-options-dialog.css"],"names":[],"mappings":"AAEA;CACC,mBAAA;AAOD;;AAJA;CACC,WAAA;CACA,cAAA;AAOD;;AAJA;CACC,WAAA;CACA,cAAA;CACA,WAAA;AAOD;;AAJA;CACC,WAAA;CACA,WAAA;CACA,WAAA;AAOD;;AAJA;CACC,mDAAA;AAOD","sourcesContent":["@value red from \"variables/colors.css\";\n\n.row {\n\tmargin-bottom: 15px;\n}\n\n.row:before {\n\tcontent: '';\n\tdisplay: table;\n}\n\n.row:after {\n\tcontent: '';\n\tdisplay: table;\n\tclear: both;\n}\n\n.column {\n\twidth: 100%;\n\tfloat: left;\n\tclear: none;\n}\n\n.error {\n\tcolor: red;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"red": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["red"] + "",
	"row": "_3ttX5nmP-PSox9ftvMskkR",
	"column": "_1eF2avD0injj1eb15D3VvB",
	"error": "_3tP0ZT5AcnUhrGjLHB_Ne4"
};
export default ___CSS_LOADER_EXPORT___;
