/* @flow */

import type {
	AccountingDocumentHeader,
	AccountingDocumentReduxAction,
	AccountingDocumentVatRatesAction,
	AddressBookAction,
	CashRegisterAction,
	CashRegisterInventories,
	CommonAction,
	Dispatch,
	IssueAction,
	State,
} from 'types'
import {
	fetchAccountingDocumentVatRates,
	loadAccountingDocumentLineItems,
	loadAccountingDocumentVatRecapInfo,
	loadDefaultLineItems,
	updateAccountingDocumentDatesByIssueDate,
	updateAccountingDocumentTaxDate,
} from 'modules/accounting-document/actions'

import HeaderDates from '../../components/invoice-elements/header-dates'
import { clearServerError } from '../../../common/actions'
import { connect } from 'react-redux'
import { getCashRegisterInventoriesById } from 'modules/accounting-document/selectors'
import { loadInventories } from 'modules/cash-register/actions'

export type OwnProps = {|
	accountingDocumentId: string,
	readonly?: boolean,
	isInternalUser?: ?boolean,
	cashRegisterId?: ?string,
|}

type StateProps = {|
	issueDate: ?string,
	taxDate: ?string,
	inventories: ?CashRegisterInventories,
|}

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => {
	const item = state.accountingDocument.accountingDocuments.items[ownProps.accountingDocumentId]
	const header: ?AccountingDocumentHeader = item && item.header

	return {
		issueDate: header ? header.issueDate : null,
		taxDate: header ? header.taxDate : null,
		inventories: (ownProps.cashRegisterId && getCashRegisterInventoriesById(state, ownProps.cashRegisterId)) || null,
	}
}

type DispatchProps = {|
	onIssueDateChange: (issueDate: ?Date) => ?Promise<*>,
	onTaxDateChange: (taxDate: ?Date) => ?Promise<*>,
	loadInventories?: () => void,
|}

type Action =
	| AccountingDocumentVatRatesAction
	| AccountingDocumentReduxAction
	| CashRegisterAction
	| AddressBookAction
	| IssueAction

const mapDispatchToProps = (dispatch: Dispatch<Action | CommonAction>, ownProps: OwnProps): DispatchProps => {
	const accountingDocumentId: string = ownProps.accountingDocumentId
	return {
		onIssueDateChange: async (issueDate: ?Date) => {
			await dispatch(updateAccountingDocumentDatesByIssueDate(accountingDocumentId, issueDate))
			dispatch(clearServerError())
			dispatch(loadAccountingDocumentVatRecapInfo(accountingDocumentId))
			dispatch(fetchAccountingDocumentVatRates(accountingDocumentId))
			dispatch(loadDefaultLineItems(accountingDocumentId))
		},
		onTaxDateChange: async (taxDate: ?Date) => {
			await dispatch(updateAccountingDocumentTaxDate(accountingDocumentId, taxDate))
			dispatch(loadAccountingDocumentVatRecapInfo(accountingDocumentId))
			dispatch(fetchAccountingDocumentVatRates(accountingDocumentId))
			dispatch(loadAccountingDocumentLineItems(accountingDocumentId))
			dispatch(loadDefaultLineItems(accountingDocumentId))
		},
		loadInventories: () => {
			ownProps.cashRegisterId && dispatch(loadInventories(ownProps.cashRegisterId))
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderDates)
