/* @flow */

import { contactsSearch as contactsSearchApi } from 'modules/common/models/api-model'
import {
	addFavoriteAccountingDocumentContact,
	loadFavoriteAccountingDocumentContact,
	removeFavoriteAccountingDocumentContact,
} from 'modules/organization/actions/favorites'
import { getFavoriteAccountingDocumentContactIds } from 'modules/organization/selectors/favorites'
import { connect } from 'react-redux'
import type { AccountingDocumentContact, Dispatch, OrganizationAction, State } from 'types'
import { Contacts_AccountingDocumentContacts, Contacts_CopyContactAddressEmailToContact } from 'types/convertor'
import { withAutoload } from 'wrappers'
import ContactSelect from '../../../components/invoice-elements/contact/contact-select'

type StateProps = {|
	favoriteContactIds: Array<string>,
|}

const mapStateToProps = (state: State): StateProps => {
	return {
		favoriteContactIds: getFavoriteAccountingDocumentContactIds(state),
	}
}

type DispatchProps = {|
	searchContact?: (value: string) => Promise<Array<AccountingDocumentContact>>,
	onContactFavorite: (contact: AccountingDocumentContact, isFavorite: boolean) => void,
	autoload: () => void,
|}

const mapDispatchToProps = (dispatch: Dispatch<OrganizationAction>): DispatchProps => {
	return {
		searchContact: async (value: string): Promise<Array<AccountingDocumentContact>> => {
			const result = await contactsSearchApi.post({
				page: 1,
				pageSize: 10,
				searchText: value,
			})
			return Contacts_AccountingDocumentContacts(Contacts_CopyContactAddressEmailToContact(result.contacts || []))
		},
		onContactFavorite: (contact: AccountingDocumentContact, isFavorite: boolean) => {
			if (isFavorite) {
				dispatch(addFavoriteAccountingDocumentContact(contact))
			} else {
				dispatch(removeFavoriteAccountingDocumentContact(contact))
			}
		},
		autoload: () => {
			dispatch(loadFavoriteAccountingDocumentContact())
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(withAutoload(ContactSelect))
