/* @flow */

import { type Node } from 'react'
import { connect } from 'react-redux'
import { loadCashRegisters } from 'modules/cash-register/actions'
import { getCashRegisters } from 'modules/cash-register/selectors'
import type { CashRegisterAction, Dispatch, CashRegister, State } from 'types'
import CashRegisterSelector from '../components/selectors/cash-register-selector'

type OwnProps = {
	cashRegisters?: ?Array<CashRegister>,
	autoWidth?: boolean,
	inline?: boolean,
	value: ?string,
	label?: string,
	compact?: boolean,
	autoSelect?: boolean,
	isClearable?: boolean,
	placeholder?: ?string,
	error?: ?Node,
	onChange: (cashRegisterId: ?string) => void,
}

type StateProps = {|
	cashRegisters?: ?Array<CashRegister>,
|}

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => {
	return {
		cashRegisters: ownProps.cashRegisters || getCashRegisters(state),
	}
}

type DispatchProps = {|
	loadCashRegisters?: () => void,
|}

const mapDispatchToProps = (dispatch: Dispatch<CashRegisterAction>): DispatchProps => {
	return {
		loadCashRegisters: () => {
			dispatch(loadCashRegisters())
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(CashRegisterSelector)
