// @flow

import type { AccountingDocumentReduxAction, ActionFunction, Dispatch, State } from 'types'

export default function createThrottleAD(ms: number) {
	let latestActionFunction: ?ActionFunction<AccountingDocumentReduxAction>
	let promise: Promise<?AccountingDocumentReduxAction>
	return (actionFunction: ActionFunction<AccountingDocumentReduxAction>) => {
		return async (
			dispatch: Dispatch<AccountingDocumentReduxAction>,
			getState: () => State,
		): Promise<?AccountingDocumentReduxAction> => {
			let returnPromise: Promise<?AccountingDocumentReduxAction>

			if (!latestActionFunction) {
				promise = new Promise((resolve: (?Promise<?AccountingDocumentReduxAction>) => void) => {
					const firstActionFunction = actionFunction
					setTimeout(() => {
						if (latestActionFunction && firstActionFunction !== latestActionFunction) {
							resolve(latestActionFunction(dispatch, getState))
						}
						latestActionFunction = null
					}, ms)
				})
				returnPromise = Promise.resolve(actionFunction(dispatch, getState))
			} else {
				returnPromise = promise
			}
			latestActionFunction = actionFunction
			return returnPromise
		}
	}
}
