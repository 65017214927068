//@flow

import type { Dispatch, FeaturesAction, FeatureId, Permission } from 'types'
import {
	enableFeature as _enableFeature,
	disableFeature as _disableFeature,
	removeFeature as _removeFeature,
	enableTestPermission as _enableTestPermission,
	disableTestPermission as _disableTestPermission,
	removeTestPermission as _removeTestPermission,
	removeAllTestPermission as _removeAllTestPermission,
} from 'utils/features'

export function enableFeature(featureId: FeatureId) {
	return async (dispatch: Dispatch<FeaturesAction>): Promise<FeaturesAction> => {
		_enableFeature(featureId)
		return dispatch({
			type: 'ENABLE_FEATURE',
			featureId,
		})
	}
}

export function disableFeature(featureId: FeatureId) {
	return async (dispatch: Dispatch<FeaturesAction>): Promise<FeaturesAction> => {
		_disableFeature(featureId)
		return dispatch({
			type: 'DISABLE_FEATURE',
			featureId,
		})
	}
}

export function removeFeature(featureId: FeatureId) {
	return async (dispatch: Dispatch<FeaturesAction>): Promise<FeaturesAction> => {
		_removeFeature(featureId)
		return dispatch({
			type: 'REMOVE_FEATURE',
			featureId,
		})
	}
}

export function enableTestPermission(permission: Permission) {
	return async (dispatch: Dispatch<FeaturesAction>): Promise<FeaturesAction> => {
		_enableTestPermission(permission)
		return dispatch({
			type: 'ENABLE_TEST_PERMISSION',
			permission,
		})
	}
}

export function disableTestPermission(permission: Permission) {
	return async (dispatch: Dispatch<FeaturesAction>): Promise<FeaturesAction> => {
		_disableTestPermission(permission)
		return dispatch({
			type: 'DISABLE_TEST_PERMISSION',
			permission,
		})
	}
}

export function removeTestPermission(permission: Permission) {
	return async (dispatch: Dispatch<FeaturesAction>): Promise<FeaturesAction> => {
		_removeTestPermission(permission)
		return dispatch({
			type: 'REMOVE_TEST_PERMISSION',
			permission,
		})
	}
}

export function removeAllTestPermissions() {
	return async (dispatch: Dispatch<FeaturesAction>): Promise<FeaturesAction> => {
		_removeAllTestPermission()
		return dispatch({
			type: 'REMOVE_ALL_TEST_PERMISSIONS',
		})
	}
}
