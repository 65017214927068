/* @flow */
import { connect } from 'react-redux'
import type { Dispatch, UserAction } from 'types'
import NotFound from 'modules/common/pages/not-found'
import { navigateToMainScreen } from 'modules/navigation/actions'

type DispatchProps = {|
	redirectToMainScreen: () => void,
|}

const mapDispatchToProps = (dispatch: Dispatch<UserAction>): DispatchProps => {
	return {
		redirectToMainScreen: () => {
			dispatch(navigateToMainScreen())
		},
	}
}

export default connect(undefined, mapDispatchToProps)(NotFound)
