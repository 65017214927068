/* @flow */

import React, { Component } from 'react'
import HeaderDates from 'modules/accounting-document/components/invoice-elements/header-dates'
import type { AccountingDocument } from 'types'
import { formatDateToIsoString } from 'utils/formatters'
import { emptyAccountingDocument } from 'types/empty'

type Props = {|
	accountingDocument: AccountingDocument,
	onChange: (accountingDocument: AccountingDocument) => void,
	readonly?: boolean,
|}

export default class HeaderDatesTemplate extends Component<Props> {
	onIssueDateChange = (issueDate: ?Date) => {
		const issueDateString: ?string = (issueDate && formatDateToIsoString(issueDate)) || null
		const newDocument: AccountingDocument = Object.assign(emptyAccountingDocument(), this.props.accountingDocument, {
			issueDate: issueDateString || undefined,
		})
		this.props.onChange(newDocument)
	}

	onTaxDateChange = (taxDate: ?Date) => {
		const taxDateString: ?string = (taxDate && formatDateToIsoString(taxDate)) || null
		const newDocument: AccountingDocument = Object.assign(emptyAccountingDocument(), this.props.accountingDocument, {
			taxDate: taxDateString || undefined,
		})
		this.props.onChange(newDocument)
	}

	render() {
		return (
			<HeaderDates
				accountingDocumentId={''}
				issueDate={this.props.accountingDocument.issueDate}
				taxDate={this.props.accountingDocument.taxDate}
				readonly={this.props.readonly}
				onIssueDateChange={this.onIssueDateChange}
				onTaxDateChange={this.onTaxDateChange}
				isTemplate
			/>
		)
	}
}
