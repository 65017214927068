// @flow

export const API_NOT_FOUND_CODE: string = 'api0001'
export const FE_NOT_FOUND_CODE: string = 'fe0001'
export const SILENT_MESSAGE_CODE: string = 'silent_message'

export const NEW_TEMPLATE_NAME: string = '__new_template_name__'

// Calling following requests will show global progress bar laoder
export const API_REQUESTS_SHOW_LOADER: Array<string> = [
	'bootstrap',

	'banks',
	'groups',
	'projects',
	'branches',
	'documents',
	'countries',
	'sequences',
	'currencies',
	'stickynotes',
	'financialaccounts',
	'reports/accountingdocuments',
	'organizations/stats',
	'countryvariantspecific/cz/companytypes',
	'uploads/{fileId}/downloadrequest',

	'{organizationId}',
	'{organizationId}/enums',
	'{organizationId}/settings',
	'{organizationId}/settings/open',
	'{organizationId}/chat/users/me/accesstoken',
	'{organizationId}/settings/internal/notes/extraction',
	'{organizationId}/search/accountingdocuments/xlsx/downloadrequest',
	'{organizationId}/settings/defaults/accountingdocuments/{accountingDocumentTypeId}/{accountingDocumentDirectionId}',
	'{organizationId}/settings/countryvariantspecific/xeu/oss',

	'{organizationId}/accountingdocuments/{accountingDocumentId}/acknowledge',

	// SCAN FACTORY
	'{organizationId}/scanfactory/accountingdocuments/{accountingDocumentId}/metadata',

	// SEARCH
	'search/tasks',
	'search/organizations',
	'search/accountingdocuments',
	'search/payments/unmatched',
	'search/countryvariantspecific/cz/ares',

	// ME
	'users/me',
	'users/me/organizations',
	'users/me/favorites/{section}',

	// TEMPLATES
	'templates',
	'templates/{templateId}',
	'templates/{templateId}/scheduler',
	'templates/{templateId}/accountingdocuments',

	// BANK ACCOUNTS
	'bankaccounts',
	'bankaccounts/{bankAccountId}',

	// ACCOUNTING DOCUMENTS
	'accountingdocuments/uniqueid/{uniqueId}',
	'accountingdocuments/{accountingDocumentId}',
	'accountingdocuments/drafts',
	'accountingdocuments/extracts/fetch',
	'bulk/accountingdocuments',

	// CASH REGISTERS
	'cashregisters',
	'cashregisters/{cashRegisterId}',
	'contacts',

	// UNMATCHED PAYMENTS
	'{organizationId}/payments/unmatched/{unmatchedPaymentId}',
	'{organizationId}/search/payments/unmatched',
	'{organizationId}/payments/unmatched/{unmatchedPaymentId}/matches/bulk',

	// REPORTS
	'reports/accountingdocuments/aggregations',
	'{organizationId}/reports/organization',

	// TASKS
	'tasks/{taskId}',
	'internalusers',

	// CASHBOT
	'oauth/link/generate',
]

// Following requests re ignored by global loader
export const API_REQUESTS_IGNORE_LOADER: Array<string> = [
	'tags',
	'tags/{tagId}',
	'vatrates',
	'vatrates/all',
	'contacts/{contactId}',
	'documents/{documentItemUniqueId}',
	'{organizationId}/settings/taxpayment/history',
	'{organizationId}/settings/internal/features',
	'groups/{groupId}',

	'{organizationId}/users',
	'{organizationId}/users/invitations',
	'{organizationId}/users/invitations/users/{userId}',
	'{organizationId}/users/invitations/emails/{email}',
	'{organizationId}/banks/{bankId}/statementtypes',
	'{organizationId}/banks/{bankId}/paymentordertypes',
	'{organizationId}/accountingdocuments/actions',
	'{organizationId}/settings/signatures',
	'{organizationId}/inbound/emails',
	'{organizationId}/uploads',
	'{organizationId}/cashregisters/{cashRegisterId}/cashbook/pdf/downloadreques',

	// ACCOUNTING DOCUMENTS
	'accountingdocuments/{accountingDocumentId}/attachments',
	'accountingdocuments/{accountingDocumentId}/header',
	'accountingdocuments/{accountingDocumentId}/issues',
	'accountingdocuments/{accountingDocumentId}/issues',
	'accountingdocuments/{accountingDocumentId}/bankaccounts',
	'accountingdocuments/{accountingDocumentId}/nextstates',
	'accountingdocuments/{accountingDocumentId}/connections',
	'accountingdocuments/{accountingDocumentId}/lineitems/{lineItemId}',
	'accountingdocuments/{accountingDocumentId}/bankaccounts/{localBankAccountId}',
	'accountingdocuments/{accountingDocumentId}/contact',
	'accountingdocuments/{accountingDocumentId}/payments',
	'accountingdocuments/{accountingDocumentId}/vatrecap',
	'accountingdocumentcategories',
	'{organizationId}/greenbox/accountingdocuments/{accountingDocumentId}/suggest/financialaccounts',
	'accountingdocuments/{accountingDocumentId}/send',
	'{organizationId}/accountingdocuments/{accountingDocumentId}/pdf/downloadrequest',
	'search/accountingdocuments/lineitems',
	'accountingdocuments/{accountingDocumentId}/lookups/vatrates',

	// CARDS
	'{organizationId}/cards',
	'{organizationId}/cards/{cardId}/completion',

	// SEARCH
	'search/contacts',
	'search/bankstatements',
	'search/tags',

	//BANKS
	'{organizationId}/bankaccounts/{bankAccountId}/statements/options/{format}',
	'{organizationId}/bankaccounts/{bankAccountId}/statements/options',

	// CONTACTME
	'misc/contactme',
	'{organizationId}/greenbox/accountingdocuments/{accountingDocumentId}/suggest/financialaccounts',
	'accountingdocumentcategories',
	'accountingdocuments/extracts/count',
	'{organizationId}/inbound/emails',
	'accountingdocuments/{accountingDocumentId}/attachments',

	'accountingdocuments/{accountingDocumentId}/lineitems',
	'search/financialaccounts',
	'cashregisters/{cashRegisterId}/balances',
	'sequences/all',
	'cashregisters/{cashRegisterId}/sequences/{sequenceId}',
	'cashregisters/{cashRegisterId}/inventories',
	'sequences/{sequenceId}',

	// CARDS
	'{organizationId}/cards',

	// USERS
	'users/me/favorites/{section}/{key}',
]

export const TIMEOUT_FOR_UPLOADING_FILES: number = 45000
export const MAX_REQUEST_WAITING_TIME: number = 15000

// multiActions that should not clear selection after execution
export const PERSIST_STATE_MULTI_ACTION_TYPES: Array<string> = [
	'change_category',
	'change_branch',
	'change_project',
	'change_paymenttype',
	'change_cashregister',
	'remove',
	'accdoc_move',
	'delete_contact',
]

export const MULTI_ACTION_TYPE_CLEAR_SELECTION = '_clearselection'

export const MAX_NUMBER_VALUE: number = 999999999999999

export const EMPTY_ARRAY = Object.freeze([])
export const EMPTY_OBJECT = Object.freeze({})
export const EMPTY_FUNCTION = () => {}

export const DATA_GRID_CACHE_SIZE = 20

export const VISITOR_LANGUAGE_NAME: string = 'VisitorLanguage'
export const VISITOR_LANGUAGE_MODIFICATION_TIME_NAME: string = 'VisitorLanguageModificationTime'
export const BOOTSTRAP_VISITOR_LANGUAGE_CHANGE_DELAY: number = 1000

export const Z_INDEXES = {
	dialogs: {
		mobileApp: 999999001,
		termsAndConditions: 999999009,
	},
}

export const DOCUMENT_ITEM_TYPE_FILE = 0
export const DOCUMENT_ITEM_TYPE_FOLDER = 1

export const CASHBOT_ERROR_CODE_PREFIX = 'cashbot'

export const FIO = '2010'

export const MAIN_ACCOUNTANT_EMAIL = 'ucto@trivi.com'

export const WAGES_ACCOUNTANT_EMAIL = 'mzdy@trivi.com'
