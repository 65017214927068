/* @flow */

import type { AccountingDocumentContact, CommonAction, Dispatch, State } from 'types'

import type { AutoTestProps } from 'utils/tests/autotest'
import ContactDetail from '../../../components/invoice-elements/contact/contact-detail'
import { connect } from 'react-redux'
import { currentUserIsInternal } from 'modules/user/selectors'
import { getCountryName } from 'modules/common/selectors'
import { loadCountries } from 'modules/common/actions'
import { withAutoload } from 'wrappers'

export type OwnProps = {|
	contact: ?AccountingDocumentContact,
	public?: boolean,
	isMe?: boolean,
	showLoader?: boolean,
	...AutoTestProps,
|}

type StateProps = {|
	countryName: ?string,
	isInternalUser?: boolean,
|}

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => {
	return {
		isInternalUser: currentUserIsInternal(state),
		countryName: ownProps.public
			? ownProps.contact && ownProps.contact.country
			: getCountryName(state, ownProps.contact && ownProps.contact.country),
	}
}

type DispatchProps = {
	autoload: () => void,
}

const mapDispatchToProps = (dispatch: Dispatch<CommonAction>, ownProps: OwnProps): DispatchProps => {
	return {
		autoload: () => {
			!ownProps.public && dispatch(loadCountries())
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(withAutoload(ContactDetail))
