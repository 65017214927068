/* @flow */

import type { State } from 'types'
import { currentUserIsInternal } from 'modules/user/selectors'

import store from 'store'

function getOrganizationId() {
	const state: State = store.getState()
	return state.user.currentOrganizationId || 'error'
}

export function bankRoute() {
	return `/${getOrganizationId()}/bank`
}

export function bankAccountsRoute() {
	return `/${getOrganizationId()}/bank-account`
}

export function bankAccountRoute(id: string) {
	const state: State = store.getState()
	const internalUser = currentUserIsInternal(state)

	if (internalUser) {
		return bankAccountStatementsRoute(id)
	} else {
		return bankAccountOrdersRoute(id)
	}
}

export function bankAccountOrdersRoute(id: string) {
	return `/${getOrganizationId()}/bank-account/${id}/orders`
}

export function bankAccountStatementsRoute(id: string, organizationId?: string) {
	return `/${organizationId || getOrganizationId()}/bank-account/${id}/statements`
}

export function bankAccountUnmatchedPaymentsRoute(id: string, unmatchedPaymentId?: string) {
	let url = `/${getOrganizationId()}/bank-account/${id}/unmatched-payments`
	unmatchedPaymentId && (url += `/${unmatchedPaymentId}`)
	return url
}

export function bankAccountCreateRoute() {
	return `/${getOrganizationId()}/bank-account/create`
}

export function bankAccountEditRoute(id: string) {
	return `/${getOrganizationId()}/bank-account/${id}/edit`
}
