/* @flow */

import React, { Component } from 'react'
import { emptyAccountingDocument } from 'types/empty'
import type { AccountingDocument } from 'types'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import { RadioButton, RadioButtonGroup } from 'components'

type Props = {|
	...WithTranslateProps,
	accountingDocument: AccountingDocument,
	onChange: (accountingDocument: AccountingDocument) => void,
	availableTypes: Array<'invoice' | 'advance'>,
|}

class TypeSwitcher extends Component<Props> {
	onTypeChange = (event: Event, type: number) => {
		const newAccDoc: AccountingDocument = Object.assign(emptyAccountingDocument(), this.props.accountingDocument, {
			type,
		})
		this.props.onChange && this.props.onChange(newAccDoc)
	}

	renderInvoice = () => {
		if (this.props.availableTypes.indexOf('invoice') > -1) {
			return <RadioButton value={0} label={this.props.t('accountingDocument.types.Invoice')} />
		}
	}

	renderAdvance = () => {
		if (this.props.availableTypes.indexOf('advance') > -1) {
			return <RadioButton value={1} label={this.props.t('accountingDocument.types.Advance')} />
		}
	}

	render() {
		return (
			<RadioButtonGroup
				valueSelected={this.props.accountingDocument.type}
				onChange={this.onTypeChange}
				name={'type-switcher-radios'}
				inline
				style={{
					lineHeight: '24px',
					height: 24,
				}}
			>
				{this.renderInvoice()}
				{this.renderAdvance()}
			</RadioButtonGroup>
		)
	}
}

export default withTranslate(TypeSwitcher)
