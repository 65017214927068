// @flow

import type { GroupsAction } from 'types'

export function hasUserBulkProcessResponseServerError(response: ?Array<GroupsAction>): boolean {
	const filtered =
		response &&
		response.filter((action: ?GroupsAction) => {
			return !!(action && action.hasOwnProperty('serverError'))
		})
	return !!(filtered && filtered.length)
}
