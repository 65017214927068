//@flow

import type { Dispatch, Signatures } from 'types'
import type {
	SignatureDeleteDeleteParams,
	SignaturesGetParams,
	SignaturesPostParams,
} from 'modules/common/models/api-model'
import { signatureDelete as signatureDeleteApi, signatures as signaturesApi } from 'modules/common/models/api-model'

import type { Action as SignatureAction } from './signature-action-types'
import { createXHR } from 'lib/apiHandler'

export function dismissUploadProgress(key: string) {
	return (dispatch: Dispatch<SignatureAction>) => {
		return dispatch({
			type: 'DISMISS_UPLOAD_PROGRESS',
			key,
		})
	}
}

export function updateUploadProgress(e: ProgressEvent, file: File) {
	return (dispatch: Dispatch<SignatureAction>) => {
		let uploadProgress = {
			[escape(file.name || '')]: {
				progress: Math.round((e.loaded / e.total) * 100),
				name: file.name,
			},
		}
		return dispatch({
			type: 'UPDATE_UPLOAD_PROGRESS',
			uploadProgress,
		})
	}
}

export function uploadSignature(args: SignaturesPostParams, body: File) {
	return (dispatch: Dispatch<SignatureAction>): Promise<void> => {
		dispatch({
			type: 'START_UPLOAD_SIGNATURE',
		})

		let upload = (resolve: Function, reject: Function) => {
			try {
				let formData = new FormData()
				formData.append('attachment', body)

				let xhr = createXHR('settings/signatures')
				xhr.onload = (response: any) => {
					dispatch({
						type: 'FINISH_UPLOAD_SIGNATURE',
						signatureId: response.id,
					})
					resolve(response)
				}
				xhr.upload.onprogress = (e: ProgressEvent) => {
					dispatch(updateUploadProgress(e, body))
				}

				xhr.send(formData)
			} catch (serverError) {
				dispatch({
					type: 'FINISH_UPLOAD_SIGNATURE',
					signatureId: null,
					serverError,
				})
				reject(null)
			}
		}

		return new Promise(upload)
	}
}

export function listSignatures(args: SignaturesGetParams) {
	return async (dispatch: Dispatch<SignatureAction>) => {
		dispatch({
			type: 'START_LIST_SIGNATURES',
		})
		try {
			const response: Signatures = await signaturesApi.get(args)
			dispatch({
				type: 'FINISH_LIST_SIGNATURES',
				signatures: response,
			})
		} catch (serverError) {
			dispatch({
				type: 'FINISH_LIST_SIGNATURES',
				signatures: null,
				serverError,
			})
		}
	}
}

export function removeSignature(args: SignatureDeleteDeleteParams) {
	return async (dispatch: Dispatch<SignatureAction>) => {
		dispatch({
			type: 'START_REMOVE_SIGNATURE',
		})
		try {
			await signatureDeleteApi.delete(args)
			dispatch({
				type: 'FINISH_REMOVE_SIGNATURE',
			})
			// $FlowFixMe
			dispatch(listSignatures({}))
		} catch (serverError) {
			dispatch({
				type: 'FINISH_REMOVE_SIGNATURE',
				serverError,
			})
		}
	}
}
