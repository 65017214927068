/* @flow */

import type { Dispatch, UserAction, ContactMeAction, CommonAction } from 'types'
import type { FinishSignUp } from 'modules/user/actions/action-types'
import { changeCurrentOrganization, signUp, authenticateAndProcessInvitation } from 'modules/user/actions'

import RegisterPage from 'modules/user/pages/register'
import { connect } from 'react-redux'
import { withNotify } from 'wrappers'
import { dashboardRoute } from 'modules/dashboard/routing/routes'
import { push } from 'react-router-redux'

type DispatchProps = {|
	onRegister: (
		firstName: string,
		lastName: string,
		email: string,
		password: string,
		invitationToken: string,
	) => Promise<FinishSignUp>,
|}

const mapDispatchToProps = (dispatch: Dispatch<UserAction | ContactMeAction | CommonAction>): DispatchProps => {
	return {
		onRegister: async (
			firstName: string,
			lastName: string,
			email: string,
			password: string,
			invitationToken: string,
		): Promise<FinishSignUp> => {
			await dispatch(changeCurrentOrganization(undefined))
			const signUpResp: FinishSignUp = await dispatch(signUp(firstName, lastName, email, password, invitationToken))
			if (!signUpResp.serverError && invitationToken) {
				await dispatch(authenticateAndProcessInvitation(email, password, invitationToken))
				dispatch(push(dashboardRoute()))
				dispatch({
					type: 'SET_NOTIFICATION',
					notification: {
						message: 'user.register.invitationAccepted',
						type: 'success',
					},
				})
			}
			return signUpResp
		},
	}
}

export default connect(undefined, mapDispatchToProps)(withNotify(RegisterPage))
