// @flow
/** @jsx jsx */

import { Component } from 'react'
import { jsx } from '@emotion/core'
import { colors } from 'variables'

type Props = {|
	/**
	 * True if the hint text should be visible.
	 */
	visible: boolean,
	/**
	 * Override the inline-styles of the root element.
	 */
	style?: Object,
	/**
	 * The hint text displayed.
	 */
	text?: string,
|}

class TextFieldHint extends Component<Props> {
	static defaultProps = {
		visible: true,
	}
	render() {
		const styles = getStyles(this.props)

		return <input css={Object.assign(styles.root, this.props.style)} value={this.props.text || ''} disabled readOnly />
	}
}

function getStyles(props: Props) {
	return {
		root: {
			position: 'absolute',
			color: colors.blackFaded60,
			WebkitTextFillColor: colors.blackFaded40,
			opacity: props.visible ? 1 : 0,
			top: 0,
			userSelect: 'none',
			background: 'transparent',
			maxWidth: '100%',
			width: '100%',
			margin: 0,
		},
	}
}

export default TextFieldHint
