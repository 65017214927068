// @flow
/** @jsx jsx */
// import React from 'react'
import { jsx } from '@emotion/core'
// import DueText from 'components/due-text'
import type { CashbotInvoiceStatus } from 'modules/cashbot/types'
import cashbotLogo from 'assets/images/cashbot-logo.svg'
import CircularProgress from 'components/circular-progress'
import { withTranslate, type WithTranslateProps } from 'wrappers'

export type OwnProps = {|
	loading: boolean,
	cashbotStatus: ?CashbotInvoiceStatus,
|}

type Props = {|
	...WithTranslateProps,
	...OwnProps,
|}

function translateStatus(t: string => string, cashbotStatus: ?CashbotInvoiceStatus): string {
	if (!cashbotStatus) {
		return ''
	}
	return {
		pending: t('cashbot.gridLine.status.pending'),
		approving: t('cashbot.gridLine.status.approving'),
		approved: t('cashbot.gridLine.status.approved'),
		financed: t('cashbot.gridLine.status.financed'),
		repaid: t('cashbot.gridLine.status.repaid'),
		rejected: t('cashbot.gridLine.status.rejected'),
	}[cashbotStatus]
}

function CashbotGridState(props: Props) {
	return (
		<div css={styles.days}>
			<img src={cashbotLogo} css={styles.logo} />
			{props.loading ? <CircularProgress size={14} /> : translateStatus(props.t, props.cashbotStatus)}
			{/* {props.state === 'paid' && <span>Financováno Cashbotem</span>}
			{props.state === 'overdue' && props.overdueDays && (
				<span>
					Splatnost Cashbotu: <DueText daysToGo={props.overdueDays} />
				</span>
			)} */}
		</div>
	)
}

export default withTranslate(CashbotGridState)

const styles = {
	days: {
		fontSize: 12,
		minHeight: 21,
		display: 'flex',
		alignItems: 'center',
		lineHeight: '21px',
		whiteSpace: 'nowrap',
	},
	logo: {
		height: 11,
		marginRight: 5,
		position: 'relative',
		top: -1,
	},
}
