import React from 'react'
import pure from 'recompose/pure'
import SvgIcon from 'components/SvgIcon'

let File = props => {
	props = Object.assign({ viewBox: '-4 -2 24 24' }, props)
	return (
		<SvgIcon {...props}>
			<path
				d="M1.99,0 C0.89,0 0,0.9 0,2 L0.01,18 C0.01,19.1 0.9,20 2,20 L10,20 L16,14 L16,2 C16,0.9 15.1,0 14,0 L1.99,0 Z"
				fill="#34424E"
				transform="translate(8.000000, 10.000000) scale(1, -1) translate(-8.000000, -10.000000) "
			/>
			<path
				d="M4.156,7.002 C4.156,5.946 4.702,4.938 5.872,4.938 C7.042,4.938 7.588,5.946 7.588,7.002 C7.588,8.058 7.042,9.072 5.872,9.072 C4.702,9.072 4.156,8.058 4.156,7.002 Z M6.544,7.002 C6.544,6.294 6.352,5.85 5.872,5.85 C5.392,5.85 5.2,6.294 5.2,7.002 C5.2,7.71 5.392,8.16 5.872,8.16 C6.352,8.16 6.544,7.71 6.544,7.002 Z M9.87400001,9.054 C10.156,9.054 10.39,8.814 10.39,8.532 L10.39,5.544 C10.39,5.214 10.12,4.944 9.79000001,4.944 C9.62800001,4.944 9.47800001,5.016 9.37000001,5.118 L8.35600001,6.12 C8.28400001,6.198 8.23000001,6.306 8.23000001,6.426 C8.23000001,6.66 8.42200001,6.846 8.65000001,6.846 C8.77000001,6.846 8.87200001,6.798 8.95000001,6.726 L9.35200001,6.318 L9.35200001,8.532 C9.35200001,8.814 9.58600001,9.054 9.87400001,9.054 Z M4.156,14.002 C4.156,12.946 4.702,11.938 5.872,11.938 C7.042,11.938 7.588,12.946 7.588,14.002 C7.588,15.058 7.042,16.072 5.872,16.072 C4.702,16.072 4.156,15.058 4.156,14.002 Z M6.544,14.002 C6.544,13.294 6.352,12.85 5.872,12.85 C5.392,12.85 5.2,13.294 5.2,14.002 C5.2,14.71 5.392,15.16 5.872,15.16 C6.352,15.16 6.544,14.71 6.544,14.002 Z M8.26000001,14.002 C8.26000001,12.946 8.80600001,11.938 9.97600001,11.938 C11.146,11.938 11.692,12.946 11.692,14.002 C11.692,15.058 11.146,16.072 9.97600001,16.072 C8.80600001,16.072 8.26000001,15.058 8.26000001,14.002 Z M10.648,14.002 C10.648,13.294 10.456,12.85 9.97600001,12.85 C9.49600001,12.85 9.30400001,13.294 9.30400001,14.002 C9.30400001,14.71 9.49600001,15.16 9.97600001,15.16 C10.456,15.16 10.648,14.71 10.648,14.002 Z"
				fill="#FFFFFF"
			/>
			<polygon
				fill="#08B0CD"
				transform="translate(13.000000, 3.000000) rotate(-90.000000) translate(-13.000000, -3.000000) "
				points="10 6 10 0 16 0"
			/>
		</SvgIcon>
	)
}
File = pure(File)

export default File
