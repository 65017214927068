/* @flow */
import { connect } from 'react-redux'
import type { State, Organization, Enum } from 'types'
import { getCurrentOrganization, getOrganizationBranches } from 'modules/organization/selectors'
import { isTaxEvidence } from 'modules/organization/domain/organization'
import ReportView from 'modules/dashboard/components/report/report-view'

type StateProps = {|
	taxEvidence: boolean,
	branches: ?Enum,
|}

function mapStateToProps(state: State): StateProps {
	const organization: ?Organization = getCurrentOrganization(state)

	const taxEvidence = organization && isTaxEvidence(organization)
	return {
		taxEvidence: taxEvidence || false,
		branches: getOrganizationBranches(state),
	}
}

export default connect(mapStateToProps, null)(ReportView)
