//
import React from 'react'
import pure from 'recompose/pure'
import SvgIcon from '../../SvgIcon'

let TemplatesIcon = props => (
	<SvgIcon {...props}>
		{/* <path d="M101,23c0-2.209-1.791-4-4-4H29c-2.209,0-4,1.791-4,4v80c0,2.209,1.791,4,4,4h2 V25h70V23z M54,57h46c1.656,0,3-1.343,3-3s-1.344-3-3-3H54c-1.656,0-3,1.343-3,3S52.344,57,54,57z M54,69h46c1.656,0,3-1.343,3-3 c0-1.657-1.344-3-3-3H54c-1.656,0-3,1.343-3,3C51,67.657,52.344,69,54,69z M111,33H43c-2.209,0-4,1.791-4,4v80 c0,2.209,1.791,4,4,4h44l28-28V37C115,34.791,113.209,33,111,33z M109,89H87c-2.209,0-4,1.791-4,4v22H45l0-76h64V89z M54,81h46 c1.656,0,3-1.344,3-3s-1.344-3-3-3H54c-1.656,0-3,1.344-3,3S52.344,81,54,81z" /> */}
		<path
			style={{
				transform: 'translate(3px, 2px)',
			}}
			d="M15.2 0.823529C15.2 0.368735 14.8418 0 14.4 0H0.8C0.3582 0 0 0.368735 0 0.823529V17.2941C0 17.7489 0.3582 18.1176 0.8 18.1176H1.2V1.23529H15.2V0.823529ZM5.8 7.82353H15C15.3312 7.82353 15.6 7.54703 15.6 7.20588C15.6 6.86474 15.3312 6.58824 15 6.58824H5.8C5.4688 6.58824 5.2 6.86474 5.2 7.20588C5.2 7.54703 5.4688 7.82353 5.8 7.82353ZM5.8 10.2941H15C15.3312 10.2941 15.6 10.0176 15.6 9.67647C15.6 9.33532 15.3312 9.05882 15 9.05882H5.8C5.4688 9.05882 5.2 9.33532 5.2 9.67647C5.2 10.0176 5.4688 10.2941 5.8 10.2941ZM17.2 2.88235H3.6C3.1582 2.88235 2.8 3.25109 2.8 3.70588V20.1765C2.8 20.6313 3.1582 21 3.6 21H12.4L18 15.2353V3.70588C18 3.25109 17.6418 2.88235 17.2 2.88235ZM16.8 14.4118H12.4C11.9582 14.4118 11.6 14.7805 11.6 15.2353V19.7647H4V4.11765H16.8V14.4118ZM5.8 12.7647H15C15.3312 12.7647 15.6 12.488 15.6 12.1471C15.6 11.8061 15.3312 11.5294 15 11.5294H5.8C5.4688 11.5294 5.2 11.8061 5.2 12.1471C5.2 12.488 5.4688 12.7647 5.8 12.7647Z"
		/>
	</SvgIcon>
)
TemplatesIcon = pure(TemplatesIcon)

export default TemplatesIcon
