/* @flow */
/** @jsx jsx */

import { Component, type Ref } from 'react'
import { jsx } from '@emotion/core'
import { dateTimeFormat, isAfterDate, isBeforeDate, localizedWeekday } from './date-utils'
import CalendarMonth from './calendar-month'
import CalendarToolbar from './calendar-toolbar'
import CalendarYear from './calendar-year'
import CalendarSlide from './calendar-slide'
import { colors } from 'variables'
import { HotKeys } from 'react-hotkeys'

const daysArray = ['', '', '', '', '', '', '']

export type Props = {|
	DateTimeFormat: (string, Object) => ?string,
	initialDate: ?Date,
	maxDate: Date,
	minDate: Date,
	open: boolean,
	today: string,
	todayLabel: string,
	utils: Object,
	onClickCancel?: () => void,
	onClickDay?: () => void,
	onClickOk?: () => void,
	keyRef?: Ref<typeof Component>,
|}

export type State = {
	displayDate: ?Date,
	displayMonthDay: boolean,
	selectedDate: ?Date,
	transitionDirection: 'left' | 'right',
	transitionEnter: boolean,
}

class Calendar extends Component<Props, State> {
	static defaultProps = {
		DateTimeFormat: dateTimeFormat,
		initialDate: new Date(),
	}

	state = {
		displayDate: null,
		displayMonthDay: true,
		selectedDate: null,
		transitionDirection: 'left',
		transitionEnter: true,
	}

	UNSAFE_componentWillMount() {
		this.setState({
			displayDate: this.props.utils.getFirstDayOfMonth(this.props.initialDate),
			selectedDate: this.props.initialDate,
		})
	}

	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		if (nextProps.initialDate !== this.props.initialDate) {
			const date = nextProps.initialDate || new Date()
			this.setState({
				displayDate: this.props.utils.getFirstDayOfMonth(date),
				selectedDate: date,
			})
		}
	}

	getSelectedDate = () => {
		return this.state.selectedDate
	}

	isSelectedDateDisabled = () => {
		if (!this.state.displayMonthDay) {
			return false
		}

		return this.refs.calendar && this.refs.calendar.isSelectedDateDisabled()
	}

	addSelectedDays(days: number) {
		this.setSelectedDate(this.props.utils.addDays(this.state.selectedDate, days))
	}

	addSelectedMonths(months: number) {
		this.setSelectedDate(this.props.utils.addMonths(this.state.selectedDate, months))
	}

	addSelectedYears(years: number) {
		this.setSelectedDate(this.props.utils.addYears(this.state.selectedDate, years))
	}

	setDisplayDate(date: Date, newSelectedDate: Date) {
		const newDisplayDate = this.props.utils.getFirstDayOfMonth(date)
		const direction = newDisplayDate > this.state.displayDate ? 'left' : 'right'

		if (newDisplayDate !== this.state.displayDate) {
			this.setState({
				displayDate: newDisplayDate,
				transitionDirection: direction,
				selectedDate: newSelectedDate || this.state.selectedDate,
			})
		}
	}

	setSelectedDate(date: Date) {
		let adjustedDate = date
		if (isBeforeDate(date, this.props.minDate)) {
			adjustedDate = this.props.minDate
		} else if (isAfterDate(date, this.props.maxDate)) {
			adjustedDate = this.props.maxDate
		}

		const newDisplayDate = this.props.utils.getFirstDayOfMonth(adjustedDate)
		if (newDisplayDate !== this.state.displayDate) {
			this.setDisplayDate(newDisplayDate, adjustedDate)
		} else {
			this.setState({
				selectedDate: adjustedDate,
			})
		}
	}

	handleClickDay = (date: Date) => {
		this.setSelectedDate(date)
		this.props.onClickDay && this.props.onClickDay()
	}

	handleMonthChange = (months: number) => {
		this.setState({
			transitionDirection: months >= 0 ? 'left' : 'right',
			displayDate: this.props.utils.addMonths(this.state.displayDate, months),
		})
	}

	handleClickYear = (year: number) => {
		this.setSelectedDate(this.props.utils.setYear(this.state.selectedDate, year))
		this.handleClickDateDisplayMonthDay()
	}

	getToolbarInteractions() {
		return {
			prevMonth: this.props.utils.monthDiff(this.state.displayDate, this.props.minDate) > 0,
			nextMonth: this.props.utils.monthDiff(this.state.displayDate, this.props.maxDate) < 0,
		}
	}

	handleClickDateDisplayMonthDay = () => {
		this.setState({
			displayMonthDay: true,
		})
	}

	handleClickDateDisplayYear = () => {
		this.setState({
			displayMonthDay: false,
		})
	}

	handleClickToday = () => {
		this.setSelectedDate(new Date())
		this.props.onClickDay && this.props.onClickDay()
	}

	stopEvent(event: KeyboardEvent) {
		event.stopPropagation()
		event.preventDefault()
		return true
	}

	handlers = {
		nextDay: (event: KeyboardEvent) => this.stopEvent(event) && this.addSelectedDays(1),
		nextWeek: (event: KeyboardEvent) => this.stopEvent(event) && this.addSelectedDays(7),
		nextMonth: (event: KeyboardEvent) => this.stopEvent(event) && this.addSelectedMonths(1),
		nextYear: (event: KeyboardEvent) => this.stopEvent(event) && this.addSelectedYears(1),

		prevDay: (event: KeyboardEvent) => this.stopEvent(event) && this.addSelectedDays(-1),
		prevWeek: (event: KeyboardEvent) => this.stopEvent(event) && this.addSelectedDays(-7),
		prevMonth: (event: KeyboardEvent) => this.stopEvent(event) && this.addSelectedMonths(-1),
		prevYear: (event: KeyboardEvent) => this.stopEvent(event) && this.addSelectedYears(-1),
	}

	keyMap = {
		nextDay: 'right',
		nextWeek: 'down',
		nextMonth: ['shift+down', 'shift+right'],
		nextYear: ['shift+alt+down', 'shift+alt+right'],

		prevDay: 'left',
		prevWeek: 'up',
		prevMonth: ['shift+up', 'shift+left'],
		prevYear: ['shift+alt+up', 'shift+alt+left'],
	}

	yearSelector() {
		return (
			<CalendarYear
				key="years"
				DateTimeFormat={this.props.DateTimeFormat}
				locale={'en-US'}
				onClickYear={this.handleClickYear}
				selectedDate={this.state.selectedDate}
				minDate={this.props.minDate}
				maxDate={this.props.maxDate}
				utils={this.props.utils}
			/>
		)
	}

	render() {
		const toolbarInteractions = this.getToolbarInteractions()

		const styles = {
			root: {
				color: colors.black,
				userSelect: 'none',
				width: 240,
			},
			calendar: {
				display: 'flex',
				flexDirection: 'column',
				overflow: 'hidden',
			},
			calendarContainer: {
				display: 'flex',
				alignContent: 'space-between',
				justifyContent: 'space-between',
				flexDirection: 'column',
				fontSize: 12,
				fontWeight: 400,
				padding: '0px 20px',
				transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1)',
				overflow: 'visible',
			},
			yearContainer: {
				display: 'flex',
				justifyContent: 'space-between',
				flexDirection: 'column',
				height: 272,
				marginTop: 10,
				overflow: 'hidden',
				width: 240,
			},
			weekTitle: {
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'space-between',
				height: 20,
				lineHeight: '15px',
				textAlign: 'center',
				marginTop: 20,
				marginBottom: 5,
				fontSize: 12,
				color: '#34424E',
				fontWeight: '600',
				marginLeft: -9,
				marginRight: -9,
			},
			weekTitleDay: {
				width: 42,
			},
			transitionSlide: {
				height: 160,
				margin: '0 -20px',
			},
			todayHelper: {
				display: 'flex',
				flexWrap: 'nowrap',
				justifyContent: 'space-between',
				alignItems: 'center',
				height: 48,
				borderTop: '1px solid #D6D9DC',
				clear: 'both',
				margin: '0 -20px',
				padding: '0px 20px',
			},
			todayHelperTitle: {
				flex: 1,
				fontSize: 12,
				fontWeight: '600',
				color: '#34424E',
			},
			todayHelperDate: {
				fontSize: 12,
				fontWeight: '600',
				color: '#34424E',
				'&:hover': {
					cursor: 'pointer',
					color: colors.white,
					backgroundColor: colors.blue,
					borderRadius: 2,
					border: `4px solid ${colors.blue}`,
					marginRight: '-4px',
				},
			},
		}

		const weekTitleDayStyle = styles.weekTitleDay

		const {
			DateTimeFormat,
			onClickCancel, // eslint-disable-line no-unused-vars
			onClickOk, // eslint-disable-line no-unused-vars
			utils,
			today,
			todayLabel,
		} = this.props

		return (
			<div style={styles.root}>
				<HotKeys handlers={this.handlers} keyMap={this.keyMap} attach={this.props.keyRef} focused>
					<div style={styles.calendar}>
						{this.state.displayMonthDay && (
							<div style={styles.calendarContainer}>
								<CalendarToolbar
									DateTimeFormat={DateTimeFormat}
									locale={'en-US'}
									displayDate={this.state.displayDate}
									onMonthChange={this.handleMonthChange}
									prevMonth={toolbarInteractions.prevMonth}
									nextMonth={toolbarInteractions.nextMonth}
								/>
								<div style={styles.weekTitle}>
									{daysArray.map((event: string, i: number) => (
										<span key={i} style={weekTitleDayStyle}>
											{localizedWeekday(DateTimeFormat, 'en-US', i)}
										</span>
									))}
								</div>
								<CalendarSlide direction={this.state.transitionDirection} style={styles.transitionSlide}>
									<CalendarMonth
										DateTimeFormat={DateTimeFormat}
										locale={'en-US'}
										displayDate={this.state.displayDate}
										key={this.state.displayDate && this.state.displayDate.toDateString()}
										minDate={this.props.minDate}
										maxDate={this.props.maxDate}
										onClickDay={this.handleClickDay}
										ref="calendar"
										selectedDate={this.state.selectedDate}
										utils={utils}
									/>
								</CalendarSlide>
								<div style={styles.todayHelper}>
									<div style={styles.todayHelperTitle}>{todayLabel}</div>
									<div css={styles.todayHelperDate} onClick={this.handleClickToday}>
										{today}
									</div>
								</div>
							</div>
						)}
						{!this.state.displayMonthDay && <div style={styles.yearContainer}>{this.yearSelector()}</div>}
					</div>
				</HotKeys>
			</div>
		)
	}
}

export default Calendar
