// @flow
import React, { Component } from 'react'
import { Tooltip } from 'components'
import type { Filter } from 'types'
import styles from './documents-direction-tooltip.css'
import { withTranslate, type WithTranslateProps } from 'wrappers'

type Props = {|
	renderValue: ?number,
	organizationId: string,
	filter: Filter,
	onUnmatchedClick: (organizationId: string, filter: Filter) => Promise<void>,
	onMatchedClick: (organizationId: string, filter: Filter) => Promise<void>,
	...WithTranslateProps,
|}

class PaymentsTooltip extends Component<Props> {
	getUnmatchedPaymentsClickHandler = () => {
		this.props.onUnmatchedClick(this.props.organizationId, this.props.filter)
	}

	getMatchedPaymentsClickHandler = () => {
		this.props.onMatchedClick(this.props.organizationId, this.props.filter)
	}

	renderLabel = () => {
		const { renderValue, t } = this.props
		return (
			<table className={styles.tooltipTable}>
				<tbody>
					<tr>
						<th>
							<div onClick={this.getMatchedPaymentsClickHandler} className={styles.link}>
								{t('internal.widget.organizationStatistics.unmatched')}
							</div>
						</th>
						<th>
							<div onClick={this.getUnmatchedPaymentsClickHandler} className={styles.link}>
								{t('internal.widget.organizationStatistics.matched')}
							</div>
						</th>
					</tr>
					<tr>
						<td>{renderValue || 0}</td>
						<td />
					</tr>
				</tbody>
			</table>
		)
	}

	render() {
		const { renderValue } = this.props
		return (
			<Tooltip hoverable inline label={this.renderLabel()}>
				{renderValue}
			</Tooltip>
		)
	}
}

export default withTranslate(PaymentsTooltip)
