/* @flow */

import React, { Component } from 'react'
import { getExtensionFromFilename } from './file-icon-logic'
import Excel from 'components/svg-icons/trivi/files/excel'
import File from 'components/svg-icons/trivi/files/file'
import Image from 'components/svg-icons/trivi/files/image'
import LargeExcel from 'components/svg-icons/trivi/files/large-excel'
import LargeFile from 'components/svg-icons/trivi/files/large-file'
import LargeImage from 'components/svg-icons/trivi/files/large-image'
import LargePdf from 'components/svg-icons/trivi/files/large-pdf'
import LargePng from 'components/svg-icons/trivi/files/large-png'
import LargeWord from 'components/svg-icons/trivi/files/large-word'
import Pdf from 'components/svg-icons/trivi/files/pdf'
import Png from 'components/svg-icons/trivi/files/png'
import Word from 'components/svg-icons/trivi/files/word'
import { isFilePreviewable, isObscureImageType } from 'modules/file/domain'

export type Props = {|
	fileName?: ?string,
	mimeType?: ?string,
	large?: boolean,
	size?: number,
|}

class FileIcon extends Component<Props> {
	render() {
		const noPointer =
			this.props.mimeType &&
			this.props.fileName &&
			!isFilePreviewable(this.props.mimeType) &&
			!isObscureImageType(this.props.fileName, this.props.mimeType)

		const iconProps = {
			size: this.props.size || (this.props.large ? 32 : 24),
			style: {
				verticalAlign: 'middle',
				cursor: noPointer ? 'default' : 'pointer',
			},
		}
		const extension = getExtensionFromFilename(this.props.fileName)

		if (!this.props.large) {
			switch (extension) {
				case 'png':
					return <Png {...iconProps} />
				case 'jpg':
					return <Image {...iconProps} />
				case 'gif':
					return <Image {...iconProps} />
				case 'jpeg':
					return <Image {...iconProps} />
				case 'pjpeg':
					return <Image {...iconProps} />
				case 'pjp':
					return <Image {...iconProps} />
				case 'jfif':
					return <Image {...iconProps} />
				case 'doc':
					return <Word {...iconProps} />
				case 'docx':
					return <Word {...iconProps} />
				case 'xls':
					return <Excel {...iconProps} />
				case 'xlsx':
					return <Excel {...iconProps} />
				case 'pdf':
					return <Pdf {...iconProps} />
				default:
					return <File {...iconProps} />
			}
		} else {
			switch (extension) {
				case 'png':
					return <LargePng {...iconProps} />
				case 'jpg':
					return <LargeImage {...iconProps} />
				case 'gif':
					return <LargeImage {...iconProps} />
				case 'jpeg':
					return <LargeImage {...iconProps} />
				case 'pjpeg':
					return <LargeImage {...iconProps} />
				case 'pjp':
					return <LargeImage {...iconProps} />
				case 'jfif':
					return <LargeImage {...iconProps} />
				case 'doc':
					return <LargeWord {...iconProps} />
				case 'docx':
					return <LargeWord {...iconProps} />
				case 'xls':
					return <LargeExcel {...iconProps} />
				case 'xlsx':
					return <LargeExcel {...iconProps} />
				case 'pdf':
					return <LargePdf {...iconProps} />
				default:
					return <LargeFile {...iconProps} />
			}
		}
	}
}

export default FileIcon
