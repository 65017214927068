/* @flow */
/** @jsx jsx */

// $FlowFixMe - there is useState
import React, { Fragment, useCallback, useMemo, useState } from 'react'
import moment from 'moment'
import { jsx, keyframes } from '@emotion/core'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import colors from 'variables/colors.css'
import TriviLink from '../../../common/components/trivi-link'
import type { AccountingDocument } from 'types'
import IconButton from 'components/icon-button'
import Autorenew from 'components/svg-icons/action/autorenew'

type Props = {|
	...WithTranslateProps,
	value: ?number,
	isTemplate: boolean,
	isInHeader?: boolean,
	taxDate?: ?Date,
	exchRate: ?number,
	vatExchRate: ?number,
	sadExchRate: ?number,
	exchRateDefault: ?number,
	accountingDocumentId: string,
	template?: ?AccountingDocument,
	exchRateValue: ?number,
	onRefresh: () => Promise<any>,
|}

function ExchangeRateInfo(props: Props) {
	const { onRefresh } = props

	const [loading, setLoading] = useState<boolean>(false)
	const isRefreshEnabled = props.taxDate && moment(props.taxDate).isSameOrBefore(moment(), 'day', '[]')

	const styles = useMemo(() => {
		return {
			root: {
				fontSize: 14,
				color: colors.green,
			},
			link: {
				display: 'inline',
			},
			hover: {
				color: colors.green700,
			},
			loading: {
				opacity: 0.6,
			},
			icon: {
				position: 'relative',
				top: '6px',
				marginLeft: '3px',
				transition: 'opacity 200ms linear',
				animationName: loading ? spin : 'none',
				animationIterationCount: 'infinite',
				animationTimingFunction: 'linear',
				animationDuration: '1000ms',
				cursor: loading || !isRefreshEnabled ? 'default' : 'pointer',
				opacity: loading || !isRefreshEnabled ? 0.4 : 1,
			},
		}
	}, [loading, isRefreshEnabled])

	const handleRefresh = useCallback(async () => {
		setLoading(true)
		await onRefresh()
		setLoading(false)
	}, [onRefresh])

	const isDefaultUsed = props.value === props.exchRateDefault
	const isZeroRateUsed = props.value === 0 && isDefaultUsed
	const showRateNotUsedMessage = (!props.exchRateValue && !props.exchRateDefault) || isZeroRateUsed

	if (props.isTemplate && props.isInHeader) {
		return null
	}

	if (props.isTemplate && !props.exchRate && !props.exchRateDefault)
		return <Fragment>{props.t('invoice.settings.booking.templateExchRate')}</Fragment>

	return (
		<div css={styles.root}>
			{showRateNotUsedMessage && (
				<Fragment>
					{props.t('invoice.settings.booking.defaultRateIsNotUsed')}
					{isRefreshEnabled && (
						<Fragment>
							{' - '}
							<span css={loading ? styles.loading : null}>
								<TriviLink disabled={loading} onClick={handleRefresh} style={styles.link} hoverStyle={styles.hover}>
									{props.t('invoice.settings.booking.refreshExchRateShort')}
									<span css={styles.icon}>
										<IconButton
											style={{ display: 'inline-flex' }}
											color={colors.green}
											hoverColor={colors.green700}
											disabled={loading}
											size={16}
										>
											<Autorenew />}
										</IconButton>
									</span>
								</TriviLink>
							</span>
						</Fragment>
					)}
				</Fragment>
			)}
			{isDefaultUsed && !showRateNotUsedMessage && (
				<Fragment>
					{props.t('invoice.settings.booking.defaultRateIsUsed', {
						value: props && props.exchRateDefault && props.exchRateDefault.toFixed(3),
					})}
				</Fragment>
			)}
		</div>
	)
}

const spin = keyframes`
	0%{
		transform: rotate(0deg);
	}
	100%{
		transform: rotate(360deg);
	}
`

export default withTranslate(ExchangeRateInfo)
