/* @flow */
import React, { Component } from 'react'
import type {
	AccountingDocument,
	Currency,
	TemplateScheduler,
	// CountryVariantRequest,
	FormFieldErrorContext,
} from 'types'
import UpperSettings from 'modules/accounting-document/components/invoice-elements/upper-settings'
import { emptyAccountingDocument } from 'types/empty'
import type { OptionType } from 'react-select/src/types'

type Props = {|
	formFieldErrorContext?: FormFieldErrorContext,
	accountingDocument: AccountingDocument,
	templateScheduler: ?TemplateScheduler,
	// isEetActive: boolean,
	onChange: (accountingDocument: AccountingDocument) => void,
|}

export default class UpperSettingsTemplate extends Component<Props> {
	render() {
		const { accountingDocument } = this.props

		// const exchRateDefault = accountingDocument.exchRateDefault || 0
		// const exchRate = accountingDocument.exchRate || null

		const exchRate = accountingDocument.exchRate || accountingDocument.exchRateDefault

		const upperSettingsProps = {
			isTemplate: true,
			template: accountingDocument,
			accountingDocumentId: accountingDocument.id,
			// hideExchRate: accountingDocument && accountingDocument.currency === 'CZK',
			hideExchRate: exchRate == null || (accountingDocument && accountingDocument.currency === 'CZK'),
			formFieldErrorContext: this.props.formFieldErrorContext,
			noteNotificationCount: 0,
			bookingDate: null,
			onBookingDateChange: () => {},
			hideBookingDate: true,
			taxReturnMinDate: null,
			taxReturnDate: null,
			onTaxReturnDateChange: () => {},
			hideTaxReturnDate: true,
			currencyId: this.props.accountingDocument.currency || null,
			onCurrencyChange: (currency: ?Currency) => {
				const newDocument = Object.assign(emptyAccountingDocument(), this.props.accountingDocument, {
					currency: (currency && currency.id) || undefined,
				})
				this.props.onChange(newDocument)
			},
			onChange: this.props.onChange,
			languageReadonly: false,
			hideLanguage: false,
			language: this.props.accountingDocument.language || null,
			onLanguageChange: (language: ?OptionType) => {
				const newDocument = Object.assign(emptyAccountingDocument(), this.props.accountingDocument, {
					language: (language && language.value) || undefined,
				})
				this.props.onChange(newDocument)
			},
			vatCountryType: this.props.accountingDocument.vatCountryType,
			hideVatCountry: true,
			// showEet: !!(this.props.isEetActive && this.props.templateScheduler && this.props.templateScheduler.useWorkflow),
			// exchRate: exchRate || exchRateDefault,
			hideNotes: true,
			forceCountryVariantSpecific: true,
			countryVariantSpecific: this.props.accountingDocument.countryVariantSpecific,
			// changeEET: (payload: CountryVariantRequest) => {
			// 	const cvs = this.props.accountingDocument.countryVariantSpecific || {}
			// 	if (payload && payload.cz) {
			// 		this.props.onChange(
			// 			Object.freeze({
			// 				...this.props.accountingDocument,
			// 				countryVariantSpecific: {
			// 					cz: {
			// 						...cvs.cz,
			// 						eet: {
			// 							...(cvs.cz ? cvs.cz.eet : {}),
			// 							use: payload.cz.eet.use,
			// 						},
			// 					},
			// 				},
			// 			}),
			// 		)
			// 	}
			// },

			view: 'default',
			organizationSettingsOpen: undefined,
		}
		return <UpperSettings {...upperSettingsProps} />
	}
}
