/* @flow */
import BankAccountGrid from '../components/bank-account-grid'
import type { State, BankAccount } from 'types'
import { connect } from 'react-redux'
import { loadBankAccounts } from '../actions'
import { userHasAccess } from 'permissions'

type StateProps = {|
	bankAccounts: Array<BankAccount>,
	loading: boolean,
	canSeeBankAccounts: boolean,
|}

const mapStateToProps = (state: State): StateProps => {
	return {
		bankAccounts: state.bank.bankAccounts.data,
		loading: state.bank.bankAccounts.loading,
		canSeeBankAccounts: userHasAccess(state, 'seeBankAccounts'),
	}
}

type DispatchProps = {|
	loadData: () => void,
|}

const mapDispatchToProps = (dispatch: any): DispatchProps => {
	return {
		loadData: () => {
			dispatch(loadBankAccounts(1, 200))
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(BankAccountGrid)
