//@flow

import { Component } from 'react'
import { HubConnectionBuilder, HubConnection } from '@aspnet/signalr'
import type { ISignalMessage } from 'types'
import { buildNotificationsUrl } from 'lib/apiHandler'

type Props = {|
	accessToken: string,
	historyLoaded: boolean,
	initializeConnection: () => void,
	addNewMessage: (message: ISignalMessage) => void,
	stopConnection: () => void,
	loadHistory: (messages: Array<ISignalMessage>) => void,
	connectionInitializationSuccess: (connection: HubConnection) => void,
	removeReadMessages: (messages: Array<string>) => void,
	showNotificationPreview: (message: ISignalMessage) => void,
|}

type State = {|
	connection: ?HubConnection,
|}

export default class SignalR extends Component<Props, State> {
	state = {
		connection: null,
	}

	UNSAFE_componentWillMount() {
		const { accessToken } = this.props
		accessToken && this.signalRInicialization(accessToken)
	}

	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		if (nextProps.accessToken && nextProps.accessToken !== this.props.accessToken) {
			this.signalRInicialization(nextProps.accessToken)
		} else if (!nextProps.accessToken && this.state.connection) {
			this.state.connection.stop()
			this.props.stopConnection()
		}
	}

	signalRInicialization(token: string) {
		if (this.state.connection) {
			this.state.connection.stop()
			this.props.stopConnection()
		}
		const connection = new HubConnectionBuilder()
			.withUrl(buildNotificationsUrl(), {
				accessTokenFactory: () => token.split(' ')[1],
			})
			.build()

		this.setState({ connection })
		this.props.connectionInitializationSuccess(connection)

		connection.start().then(() => connection.invoke('getSignalHistory'))

		connection.on('sendsignals', (data: Array<ISignalMessage>) => {
			if (!this.props.historyLoaded) {
				this.props.loadHistory(data)
			} else {
				data.forEach((message: ISignalMessage) => {
					this.props.addNewMessage(message)
					this.props.showNotificationPreview(message)
				})
			}
		})

		connection.on('updateSignals', (data: any) => {
			this.props.removeReadMessages(data.map((i: ISignalMessage) => i.id))
		})
	}

	render() {
		return null
	}
}

// function getMockedNotifications(): Array<ISignalMessage> {
// 	return [
// 		{
// 			id: '1',
// 			timeStamp: '2019-01-21T16:57:27.5650355Z',
// 			name: 'AccountingDocumentCreate',
// 			type: 'DONE',
// 			group: 'AccountingDocument',
// 			params: null,
// 			authorIdentifier: 'f54a6910-6420-48dc-b22f-456de325d890',
// 			organizationId: 1,
// 			source: null,
// 			wasRead: false,
// 			target: 2,
// 			origin: null,
// 		},
// 		{
// 			id: '2',
// 			timeStamp: '2019-01-21T16:57:27.5650355Z',
// 			name: 'BankAccountUpdate',
// 			type: 'DONE',
// 			group: 'BankAccount',
// 			params: null,
// 			authorIdentifier: 'f54a6910-6420-48dc-b22f-456de325d890',
// 			organizationId: 1,
// 			source: null,
// 			wasRead: false,
// 			target: 2,
// 			origin: null,
// 		},
// 		{
// 			id: '3',
// 			timeStamp: '2019-01-21T16:57:27.5650355Z',
// 			name: 'CashRegisterUpdate',
// 			type: 'DONE',
// 			group: 'CashRegister',
// 			params: null,
// 			authorIdentifier: 'f54a6910-6420-48dc-b22f-456de325d890',
// 			organizationId: 1,
// 			source: null,
// 			wasRead: false,
// 			target: 2,
// 			origin: null,
// 		},
// 		{
// 			id: '4',
// 			timeStamp: '2019-01-21T16:57:27.5650355Z',
// 			name: 'TaskCreate',
// 			type: 'DONE',
// 			group: 'Task',
// 			params: null,
// 			authorIdentifier: 'f54a6910-6420-48dc-b22f-456de325d890',
// 			organizationId: 1,
// 			source: null,
// 			wasRead: false,
// 			target: 2,
// 			origin: null,
// 		},
// 		{
// 			id: '5',
// 			timeStamp: '2019-01-21T16:57:27.5650355Z',
// 			name: 'OrganizationUpdate',
// 			type: 'DONE',
// 			group: 'Organization',
// 			params: null,
// 			authorIdentifier: 'f54a6910-6420-48dc-b22f-456de325d890',
// 			organizationId: 1,
// 			source: null,
// 			wasRead: false,
// 			target: 2,
// 			origin: null,
// 		},
// 	]
// }
