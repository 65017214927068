/* @flow */

import type {
	DocumentExtractionAction,
	ScanFactoryBbWord,
	ScanFactoryDocumentFileMetadata,
	BrowserClient,
	BrowserNavigator,
	AccountingDocumentExtractionStatsEvent,
} from 'types'
import { set, size } from 'lodash/fp'
import { assignBbWord } from '../domain/bbword'
import {
	addRepeatableSection,
	countRepeatableSections,
	getField,
	getNextFieldCursor,
	getPrevFieldCursor,
	removeRepeatableSection,
	resetField,
	setField,
} from '../domain/field'
import { isFieldSectionRepeatable } from '../domain/section'
import { getFieldCursor, getMetadata, hasExtractionErrors } from '../selectors'
import {
	type ExtractionErrors,
	type DocumentExtractionState,
	type FieldCursor,
	initialState,
	type ExtractionStatParameters,
} from '../types'

export { initialState } from '../types'
export type State = DocumentExtractionState

export default function(
	state: DocumentExtractionState = initialState,
	action: DocumentExtractionAction,
): DocumentExtractionState {
	switch (action.type) {
		case 'INIT_DOCUMENT_EXTRACTION':
			return initDocumentExtractionReducer(state, action.previewWidth, action.extractionStatsData)
		case 'START_LOAD_DOCUMENT_METADATA':
			return startLoadDocumentMetadataReducer(state)
		case 'FINISH_LOAD_DOCUMENT_METADATA':
			return finishLoadDocumentMetadataReducer(state, action.documentType, action.documentId, action.documentMetadata)
		case 'START_SAVE_ACCOUNTING_DOCUMENT':
			return saveAccountingDocumentStartReducer(state)
		case 'FINISH_SAVE_ACCOUNTING_DOCUMENT':
			return saveAccountingDocumentFinishReducer(state, action.serverError)
		case 'VALIDATE_DOCUMENT_METADATA':
			return validateDocumentMetadataReducer(state, action.errors)
		case 'SELECT_FIELD':
			return changeFieldCursorReducer(state, action.fieldCursor)
		case 'RESET_FIELD':
			return resetFieldReducer(state, action.fieldCursor)
		case 'EDIT_FIELD':
			return editFieldReducer(state)
		case 'FINISH_EDIT_FIELD':
			return finishEditFieldReducer(state, action.fieldCursor, action.value)
		case 'ASSIGN_BB_WORD':
			return assignBbWordReducer(state, action.bbWord, action.append)
		case 'MOVE_FIELD_CURSOR_NEXT':
			return moveNextFieldCursorReducer(state)
		case 'MOVE_FIELD_CURSOR_PREV':
			return movePrevFieldCursorReducer(state)
		case 'ADD_REPEATABLE_SECTION':
			return addRepeatableSectionReducer(state, action.section)
		case 'REMOVE_REPEATABLE_SECTION':
			return removeRepeatableSectionReducer(state, action.section, action.index)
		case 'RESIZE_PREVIEW':
			return resizePreviewReducer(state, action.size)
		case 'ADD_STATS_EVENT':
			return addStatsEventReducer(state, action.name, action.timestamp, action.parameters)
		case 'FINISH_SAVE_STATS':
			return finishSaveStatsReducer(state, !action.serverError)
		case 'START_NEXT_DOCUMENT_EXTRACTION':
			return startNextDocumentExtraction(state)
		case 'FINISH_NEXT_DOCUMENT_EXTRACTION':
			return finishNextDocumentExtraction(state)
		default:
			return state
	}
}

export function initDocumentExtractionReducer(
	state: State,
	previewWidth: ?number,
	extractionStatsData: { navigator: BrowserNavigator, client: BrowserClient },
): State {
	const newState = { ...state }

	delete newState.extractionDocument
	delete newState.extractionStats

	newState.extractionStats = { events: [], ...extractionStatsData }
	newState.loading = true

	if (previewWidth) {
		newState.previewWidth = previewWidth
	}

	return newState
}

export function saveAccountingDocumentStartReducer(state: State): State {
	const newState = { ...state }
	newState.saving = true

	return newState
}

export function saveAccountingDocumentFinishReducer(state: State, resultError?: any): State {
	const newState = { ...state }
	newState.saving = false

	if (!resultError) {
		delete newState.extractionDocument
	}

	return newState
}

export function moveNextFieldCursorReducer(state: DocumentExtractionState): DocumentExtractionState {
	const metadata = getMetadata(state)
	const fieldCursor = getFieldCursor(state)
	if (!metadata || !fieldCursor) {
		return state
	}

	const nextFieldCursor = getNextFieldCursor(metadata, fieldCursor)
	if (!nextFieldCursor) {
		return state
	}

	return changeFieldCursorReducer(state, nextFieldCursor)
}

export function movePrevFieldCursorReducer(state: DocumentExtractionState): DocumentExtractionState {
	const metadata = getMetadata(state)
	const fieldCursor = getFieldCursor(state)
	if (!metadata || !fieldCursor) {
		return state
	}

	const prevFieldCursor = getPrevFieldCursor(metadata, fieldCursor)
	if (!prevFieldCursor) {
		return state
	}

	return changeFieldCursorReducer(state, prevFieldCursor)
}

export function assignBbWordReducer(
	state: DocumentExtractionState,
	bbWord: ScanFactoryBbWord,
	append: boolean = false,
): DocumentExtractionState {
	const metadata = getMetadata(state)
	const fieldCursor = getFieldCursor(state)
	if (!metadata || !fieldCursor) {
		return state
	}

	const newMetadata = assignBbWord(metadata, bbWord, fieldCursor, append)
	if (newMetadata !== metadata && !append) {
		state = moveNextFieldCursorReducer(state)
	}

	return {
		...state,
		extractionDocument: {
			...state.extractionDocument,
			metadata: { ...newMetadata },
		},
	}
}

function startLoadDocumentMetadataReducer(state: DocumentExtractionState): DocumentExtractionState {
	return { ...state, loading: true }
}

function finishLoadDocumentMetadataReducer(
	state: DocumentExtractionState,
	documentType: string,
	documentId: string,
	documentMetadata?: ScanFactoryDocumentFileMetadata,
): DocumentExtractionState {
	if (!documentMetadata) {
		return state
	}

	if (state.extractionDocument) {
		return {
			...state,
			extractionDocument: {
				...state.extractionDocument,
				metadata: documentMetadata,
			},
		}
	} else {
		const nextCursor = getNextFieldCursor(documentMetadata)
		if (!nextCursor) {
			return state
		}

		return {
			...state,
			loading: false,
			extractionDocument: {
				metadata: documentMetadata,
				fieldEditing: false,
				fieldCursor: nextCursor,
			},
		}
	}
}

function changeFieldCursorReducer(state: DocumentExtractionState, fieldCursor: FieldCursor): DocumentExtractionState {
	return {
		...state,
		extractionDocument: {
			...state.extractionDocument,
			fieldCursor: { ...fieldCursor },
			fieldEditing: false,
		},
	}
}

function resetFieldReducer(state: DocumentExtractionState, fieldCursor: FieldCursor): DocumentExtractionState {
	const metadata = getMetadata(state)
	if (!metadata) {
		return state
	}

	return {
		...state,
		extractionDocument: {
			...state.extractionDocument,
			metadata: resetField(metadata, fieldCursor),
		},
	}
}

export function editFieldReducer(state: DocumentExtractionState): State {
	return set(['extractionDocument', 'fieldEditing'], true, state)
}

export function finishEditFieldReducer(
	state: DocumentExtractionState,
	fieldCursor: FieldCursor,
	value?: string,
): DocumentExtractionState {
	if ('undefined' === typeof value) {
		return set(['extractionDocument', 'fieldEditing'], false, state)
	} else {
		if (!state.extractionDocument || !state.extractionDocument.metadata) {
			return state
		}

		const metadata = state.extractionDocument.metadata

		const field = { ...getField(metadata, fieldCursor) }
		if (!field) {
			return state
		}

		if (field.value === value) {
			return set(['extractionDocument', 'fieldEditing'], false, state)
		} else {
			field.value = value
			delete field.relatedBBWords

			const updatedMetadata = setField(metadata, fieldCursor, field)
			if (!updatedMetadata) {
				return state
			}

			const updatedState = set(['extractionDocument', 'metadata'], updatedMetadata, state)
			return set(['extractionDocument', 'fieldEditing'], false, updatedState)
		}
	}
}

export function addRepeatableSectionReducer(state: State, section: string): State {
	const metadata = getMetadata(state)
	if (!metadata || !isFieldSectionRepeatable(section)) {
		return state
	}

	return set(['extractionDocument', 'metadata'], addRepeatableSection(metadata, section), state)
}

export function removeRepeatableSectionReducer(state: State, section: string, index: number): State {
	const metadata = getMetadata(state)
	if (!metadata || !isFieldSectionRepeatable(section) || 1 >= countRepeatableSections(metadata, section)) {
		return state
	}

	return set(['extractionDocument', 'metadata'], removeRepeatableSection(metadata, section, index), state)
}

export function resizePreviewReducer(state: State, size: number): State {
	return { ...state, previewWidth: size }
}

export function validateDocumentMetadataReducer(state: State, errors: ?ExtractionErrors): State {
	if (errors && size(errors)) {
		return {
			...state,
			extractionDocument: {
				...state.extractionDocument,
				errors,
			},
		}
	} else if (hasExtractionErrors(state) && (!errors || 0 === size(errors))) {
		const newState = {
			...state,
			extractionDocument: {
				...state.extractionDocument,
			},
		}
		delete newState.extractionDocument.errors

		return newState
	} else {
		return state
	}
}

export function addStatsEventReducer(
	state: State,
	name: string,
	timestamp: string,
	parameters: ExtractionStatParameters,
): State {
	const event: AccountingDocumentExtractionStatsEvent = {
		timestamp,
		type: name,
		parameters: JSON.stringify(parameters),
	}

	if (!state.extractionStats) {
		return state
	}

	return {
		...state,
		extractionStats: {
			...state.extractionStats,
			events: [...((state.extractionStats && state.extractionStats.events) || []), ...[event]],
		},
	}
}

export function finishSaveStatsReducer(state: State, success: boolean): State {
	if (success) {
		const newState = { ...state }
		delete newState.extractionStats

		return newState
	} else {
		return state
	}
}

function startNextDocumentExtraction(state: State): State {
	return { ...state, loading: true }
}

function finishNextDocumentExtraction(state: State): State {
	return { ...state, loading: false }
}
