/* @flow */

import React, { Component } from 'react'
import memoize from 'memoize-one'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import DatePicker from 'components/date-picker'
import { type AutoTestProps } from 'utils/tests/autotest'
import styles from './header-dates.css'
import { convertToDate, addOneDayToDate, formatToDateString } from 'utils/formatters'
import type { CashRegisterInventories, CashRegisterInventory } from 'types'
import ConfirmDialog from 'components/confirm-dialog'

type Props = {|
	...AutoTestProps,
	...WithTranslateProps,
	issueDate: ?string,
	accountingDocumentId: string,
	taxDate: ?string,
	readonly?: boolean,
	isTemplate?: boolean,
	isInternalUser?: ?boolean,
	cashRegisterId?: ?string,
	inventories?: ?CashRegisterInventories,
	onIssueDateChange: (issueDate: ?Date) => ?Promise<*>,
	onTaxDateChange: (taxDate: ?Date) => ?Promise<*>,
	loadInventories?: () => void,
|}

type State = {|
	lastInventoryDate: ?Date,
|}

class HeaderDates extends Component<Props, State> {
	state = {
		lastInventoryDate: null,
	}

	componentDidMount() {
		if (!this.props.inventories) {
			this.props.loadInventories && this.props.loadInventories()
		}
	}

	componentDidUpdate() {
		this.updateLastInventoryDate()
	}

	updateLastInventoryDate = (): void => {
		if (this.state.lastInventoryDate === null) {
			if (this.props.inventories && this.props.inventories.length > 0) {
				const dates = this.props.inventories.map((inventory: CashRegisterInventory) => convertToDate(inventory.date))
				const lastInventoryDate: ?Date = new Date(Math.max(...dates))
				lastInventoryDate && this.setState({ lastInventoryDate: addOneDayToDate(lastInventoryDate) })
			}
		}
	}

	getMinimalIssueDate = (): ?Date => {
		return !this.props.isInternalUser ? this.state.lastInventoryDate : undefined
	}

	onIssueDateChange = (event: ?SyntheticInputEvent<HTMLInputElement>, issueDate: ?Date): void => {
		if (
			issueDate &&
			this.props.isInternalUser &&
			this.state.lastInventoryDate &&
			issueDate < this.state.lastInventoryDate
		) {
			ConfirmDialog(this.props.t('dialogs.setIssuedDateQuestion', { date: formatToDateString(issueDate) }), {
				okLabel: this.props.t('dialogs.yesOption'),
				cancelLabel: this.props.t('dialogs.noOption'),
				confirmButtonTooltip: this.props.t('dialogs.confirmButtonTooltip'),
			}).then(() => {
				this.props.onIssueDateChange && this.props.onIssueDateChange(issueDate)
			})
		} else {
			this.props.onIssueDateChange && this.props.onIssueDateChange(issueDate)
		}
	}

	onTaxDateChange = (event: ?SyntheticInputEvent<HTMLInputElement>, taxDate: ?Date): void => {
		this.props.onTaxDateChange && this.props.onTaxDateChange(taxDate)
	}

	getIssueDate = memoize((date: ?string) => {
		return date ? convertToDate(date) : null
	})

	getTaxDate = memoize((date: ?string) => {
		return date ? convertToDate(date) : null
	})

	render() {
		const { t, readonly, isTemplate } = this.props

		const issueDate = this.getIssueDate(this.props.issueDate)
		const taxDate = this.getTaxDate(this.props.taxDate)

		return (
			<div className={styles.dates}>
				<div className={styles.leftDate}>
					<div>{t('invoice.issuedDate')}</div>
					<DatePicker
						onChange={this.onIssueDateChange}
						value={issueDate}
						disabled={readonly}
						hintText={isTemplate ? t('invoice.willBeGenerated') : t('invoice.issuedDateHint')}
						autoTestId={'invoice-header-dates-issue-date'}
						autoWidth
						inline
						minDate={this.getMinimalIssueDate()}
						required={!isTemplate}
					/>
				</div>
				<div className={styles.rightDate}>
					<div>{t('invoice.taxDate')}</div>
					<DatePicker
						onChange={this.onTaxDateChange}
						value={taxDate}
						disabled={readonly}
						hintText={isTemplate ? t('invoice.willBeGenerated') : t('invoice.taxDateHint')}
						maxDate={issueDate}
						autoTestId={'invoice-header-dates-tax-date'}
						autoWidth
						inline
						required={!isTemplate}
					/>
				</div>
			</div>
		)
	}
}

export default withTranslate(HeaderDates)
