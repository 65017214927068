/* @flow */
/** @jsx jsx */

import { type ChildrenArray, Fragment, PureComponent } from 'react'
import type { DataGridReduxAction, Dispatch, Enum, EnumItem, Filter, SearchFilter, State } from 'types'
import {
	FilterDate,
	FilterOrganization,
	FilterTab,
	FilterTabs,
	FilterTextField,
} from 'modules/data-grid/components/data-grid-filter'
import { type WithTranslateProps, withTranslate } from 'wrappers'
import { changeFilter, clearGridFilterField } from '../actions'

import ArrowUp from 'components/svg-icons/navigation/arrow-drop-up'
import BreadcrumbArrow from 'components/svg-icons/trivi/breadcrumb-arrow'
import Clear from 'components/svg-icons/content/clear'
import DataGridFilter from 'modules/data-grid/containers/data-grid-filter'
import type { DataTypes } from 'modules/data-grid-next/types'
import ExportButton from 'modules/accounting-document/components/export/export-button'
import type { FilterElement } from '../types'
import FilterExpand from 'components/svg-icons/trivi/filter-expand'
import FilterToggle from './filter-checkbox'
import IconButton from 'components/icon-button'
import TemplateButton from '../../accounting-document/components/accounting-document-list/template-button'
import { colors } from 'variables'
import { connect } from 'react-redux'
import { getDataGridItemsCount } from 'modules/data-grid-next/selectors'
import { jsx } from '@emotion/core'
import memoize from 'memoize-one'
import styles from './basic-grid-filter.css'

type StateProps = {|
	filters: ?Filter,
	resultCount: number,
|}

const hiddenFilters = ['direction', 'waitingFor', 'isPaymentAlreadyMatched']

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => {
	const resultCount =
		(ownProps.dataType &&
			ownProps.gridId &&
			getDataGridItemsCount(state, ownProps.dataType, ownProps.gridId, ownProps.name, ownProps.defaultFilter)) ||
		0
	let filters = state.grid.filter[ownProps.name]
	if (filters) {
		filters = filters.filter(
			(item: SearchFilter) => !hiddenFilters.includes(item.field) && !(item && Object.keys(item).length === 0),
		)
	}
	return {
		filters: filters,
		resultCount,
	}
}

type DispatchProps = {|
	clearAllFilters: (name: string, filters: Filter) => void,
	turnOffOptimzedFilter: (name: string) => void,
|}

const mapDispatchToProps = (dispatch: Dispatch<DataGridReduxAction>): DispatchProps => {
	return {
		clearAllFilters: (name: string, filters: Filter) => {
			if (filters && filters.length > 0) {
				filters.forEach((filter: SearchFilter) => {
					filter.field && dispatch(clearGridFilterField(name, filter.field))
				})
			}
		},
		turnOffOptimzedFilter: (name: string) => {
			const filter: Filter = [{ field: optimizedFilterName, value: true }]
			dispatch(changeFilter(name, filter))
		},
	}
}

type OwnProps = {|
	children?: ChildrenArray<FilterElement | null>,
	noFilterLabel: string,
	name: string,
	dataType?: $Keys<DataTypes>, // This is only used to show result count
	gridId?: string, // This is only used to show result count
	defaultFilter?: Filter,
	organizationFilterName?: string,
	dateFilterName?: string,
	dateFilterHintText?: string,
	textFilterName?: string,
	filterTabs?: Enum,
	filterTabsName?: string,
	filterTransformFn?: {
		fromFilterItem: SearchFilter => Filter,
		toFilterItem: (Filter, string) => ?SearchFilter,
	},
	leftElement?: any,
	rightElement?: React$Element<any>,
	compact?: ?boolean,
	onOpen?: () => void,
	onClose?: () => void,
	exportButton?: ?React$Element<typeof ExportButton>,
	importButton?: React$Element<typeof IconButton>,
	templateButton?: React$Element<typeof TemplateButton>,
	containerClassName?: string,
	hideBreadcrumb?: boolean,
	direction?: 'issued' | 'received' | 'unknown',
	isFilterOpenDefault?: boolean,
	checkboxFilterName?: string,
	checkboxFilterLabelText?: string,
	showOptimizeFilter?: boolean,
	isUsedOptimizeFilter?: boolean,
|}

export type Props = {|
	...WithTranslateProps,
	...StateProps,
	...DispatchProps,
	...OwnProps,
|}

type ComponentState = {
	advanceFilterVisible: boolean,
}

class BasicGridFilter extends PureComponent<Props, ComponentState> {
	static defaultProps = {
		onOpen: () => {},
		onClose: () => {},
	}

	state: ComponentState = {
		advanceFilterVisible: this.props.isFilterOpenDefault ?? false,
	}

	toggleAdvanceFilter = () => {
		this.state.advanceFilterVisible && this.closeAdvanceFilter()
		!this.state.advanceFilterVisible && this.openAdvanceFilter()
	}

	openAdvanceFilter = () => {
		this.setState({ advanceFilterVisible: true })
		this.props.onOpen && this.props.onOpen()
	}

	closeAdvanceFilter = () => {
		this.setState({ advanceFilterVisible: false })
		this.props.onClose && this.props.onClose()
	}

	getBasicFilterFields = (): Array<string> => {
		const res: Array<string> = []
		const { organizationFilterName, dateFilterName, textFilterName, checkboxFilterName } = this.props
		if (organizationFilterName) {
			res.push(organizationFilterName)
		}
		if (dateFilterName) {
			res.push(dateFilterName)
		}
		if (textFilterName) {
			res.push(textFilterName)
		}
		if (checkboxFilterName) {
			res.push(checkboxFilterName)
		}
		return res
	}

	renderToggleIcon() {
		const { t } = this.props
		const basicFields: Array<string> = this.getBasicFilterFields()

		if (!this.props.children) return null

		let advanceFiltersCount: number = 0
		if (this.props.filters) {
			advanceFiltersCount = this.props.filters.filter((item: SearchFilter) => basicFields.indexOf(item.field || '') < 0)
				.length
		}

		return (
			<div className={styles.expander} onClick={this.toggleAdvanceFilter}>
				<span className={advanceFiltersCount > 0 ? styles.activeIconButton : styles.iconButton}>
					<IconButton autoTestId="grid-filter-expand" circled style={style.iconButton}>
						<FilterExpand />
					</IconButton>
				</span>
				{t('application.filter.openAdvancedFilter')}
			</div>
		)
	}

	renderCloseButton() {
		const { t } = this.props
		return (
			<div className={styles.reducer} onClick={this.toggleAdvanceFilter}>
				<span css={style.filterButtonIcon}>
					<ArrowUp size={16} />
				</span>
				{t('application.filter.closeAdvancedFilter')}
			</div>
		)
	}

	renderOptimizeFilter() {
		const { t, name, checkboxFilterName, checkboxFilterLabelText } = this.props

		return (
			<DataGridFilter name={name}>
				{checkboxFilterName ? (
					<FilterToggle
						name={checkboxFilterName}
						labelText={checkboxFilterLabelText}
						hintText={t('application.filter.searchHint')}
						style={style.textField}
						header={<div css={style.optimizedFilterTitle}>{t('invoice.accDocThresholdHeader')}</div>}
					/>
				) : null}
			</DataGridFilter>
		)
	}

	renderStandardFilter() {
		const { t, name, organizationFilterName, dateFilterName, textFilterName, dateFilterHintText } = this.props

		return (
			<div className={styles.normal}>
				<DataGridFilter name={name}>
					{organizationFilterName != null ? <FilterOrganization name={organizationFilterName} /> : null}
					{dateFilterName != null ? (
						<FilterDate
							inline
							name={dateFilterName}
							className={organizationFilterName ? styles.dateWithOrganization : undefined}
							tooltipText={dateFilterHintText || ''}
						/>
					) : null}
					{textFilterName != null ? (
						<FilterTextField
							name={textFilterName}
							hintText={t('application.filter.searchHint')}
							fieldStyle={dateFilterName ? style.fieldStyleWithDate : style.fieldStyle}
							style={style.textField}
						/>
					) : null}
					{this.renderToggleIcon()}
				</DataGridFilter>
			</div>
		)
	}

	clearAllFilters = () => {
		const filters: ?Filter = this.props.filters
		const defaultFilter: ?Filter = this.props.defaultFilter
		let isUsedOptimizeFilter = this.props.isUsedOptimizeFilter
		isUsedOptimizeFilter = false
		filters && this.props.clearAllFilters(this.props.name, filters)
		if (
			(defaultFilter && defaultFilter.find((x: SearchFilter) => x.field === optimizedFilterName)) ||
			(filters && filters.find((x: SearchFilter) => x.field === optimizedFilterName)) ||
			(!filters && isUsedOptimizeFilter)
		) {
			this.props.turnOffOptimzedFilter(this.props.name)
		}
	}

	renderClearFiltersButton() {
		const { t } = this.props

		return (
			<div className={this.props.compact ? styles.compactCancel : styles.cancel} onClick={this.clearAllFilters}>
				<span css={style.filterButtonIcon}>
					<Clear disabled size={16} />
				</span>
				{t('application.filter.clearAllFilters')}
			</div>
		)
	}

	renderCompact() {
		return (
			<div className={styles.compactFilterWrapper}>
				<span className={styles.compactFilterText}>{this.props.t('application.filter.filterResults')}</span>
				<span className={styles.compactFilterButton}>{this.renderClearFiltersButton()}</span>
			</div>
		)
	}

	showClearFilter = () => {
		let isUsedOptimizeFilter = this.props.isUsedOptimizeFilter
		isUsedOptimizeFilter = false
		const filters: ?Filter = this.props.filters
		const defaultFilter: ?Filter = this.props.defaultFilter
		const optimizedDefault = defaultFilter
			? defaultFilter.find((x: SearchFilter) => x.field === optimizedFilterName)
			: null
		let optimizedFilter = filters
			? filters.find((x: SearchFilter) => x.field === optimizedFilterName)
			: optimizedDefault

		if (filters && filters.length > 1) {
			optimizedFilter = null
		}
		return (
			(optimizedFilter && optimizedFilter.value === false) ||
			(!filters && isUsedOptimizeFilter) ||
			(filters && !optimizedFilter)
		)
	}

	renderTabs = memoize((filterTabsName: ?string, name: string, filterTabs: ?Enum) => {
		return (
			filterTabsName != null && (
				<DataGridFilter className={styles.filterTabs} name={name}>
					<FilterTabs name={filterTabsName || ''}>
						{(filterTabs || []).map((tab: EnumItem) => (
							<FilterTab key={tab.key} label={tab.key || ''} value={tab.value} />
						))}
					</FilterTabs>
				</DataGridFilter>
			)
		)
	})

	renderBreadcrumb = () => {
		const { t, filters } = this.props
		const defaultFilter: ?Filter = this.props.defaultFilter
		let showBreadcrumb = false

		if (filters && filters.length > 0) {
			showBreadcrumb = !(filters.length === 1 && filters[0].field === optimizedFilterName && filters[0].value)
		} else {
			if (defaultFilter) {
				const optimizedFilter = defaultFilter.find((x: SearchFilter) => x.field === optimizedFilterName)
				showBreadcrumb = optimizedFilter && !optimizedFilter.value
			}
		}

		return (
			showBreadcrumb && (
				<div className={styles.breadcrumbs}>
					<span className={styles.breadcrumbHome} onClick={this.clearAllFilters}>
						{this.props.noFilterLabel}
					</span>
					<BreadcrumbArrow style={style.arrow} hoverColor={colors.black} />
					<span className={styles.breadcrumb}>
						{t('application.filter.filterResults')}
						<div css={style.chip}>{this.props.resultCount}</div>
					</span>
				</div>
			)
		)
	}

	render() {
		let { hideBreadcrumb, showOptimizeFilter } = this.props
		showOptimizeFilter = false
		if (this.props.compact) return this.showClearFilter() ? this.renderCompact() : null

		const breadcrumbs = this.renderBreadcrumb()
		const tabs = this.renderTabs(this.props.filterTabsName, this.props.name, this.props.filterTabs)
		const hasLeft = !!(this.props.leftElement || tabs)

		return (
			<div className={styles.clearfix}>
				<div className={styles.head}>
					<div css={style.tabs}>
						{hasLeft ? (
							<Fragment>
								{this.props.leftElement}
								{tabs}
							</Fragment>
						) : (
							!hideBreadcrumb && breadcrumbs
						)}
					</div>
					{showOptimizeFilter ? this.renderOptimizeFilter() : null}
					{this.renderStandardFilter()}

					<div css={style.rightElements}>
						{this.props.exportButton}
						{this.props.importButton}
						{this.props.rightElement}
					</div>
				</div>

				{hasLeft && breadcrumbs && <div css={style.spacedBreadcrumbs}>{breadcrumbs}</div>}

				<div className={!this.state.advanceFilterVisible ? styles.hidden : ''}>
					<DataGridFilter
						name={this.props.name}
						filterTransformFn={this.props.filterTransformFn}
						defaultFilter={this.props.defaultFilter}
						className={this.props.containerClassName}
					>
						{this.props.children || null}
					</DataGridFilter>
				</div>

				{this.state.advanceFilterVisible && (
					<div className={styles.foot}>
						<div className={styles.footInner}>
							<span className={styles.border} />
							{this.showClearFilter() && this.renderClearFiltersButton()}
							{this.props.children && this.renderCloseButton()}
						</div>
					</div>
				)}
			</div>
		)
	}
}

const optimizedFilterName = 'excludeAccDocSearchThresholdId'

const style = {
	spacedBreadcrumbs: {
		marginBottom: 25,
	},
	optimizedFilterTitle: {
		display: 'flex',
		alignItems: 'center',
		color: colors.black,
		fontSize: '1.2em',
		fontWeight: 'bold',
		padding: 0,
		margin: 0,
	},
	tabs: {
		flex: 1,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-start',
	},
	rightElements: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		'> *': {
			margin: '20px 0 20px 10px',
		},
	},
	iconButton: {
		marginRight: 12,
	},
	fieldStyle: {
		borderRadius: 21,
		height: 40,
		padding: '8px 16px',
		fontSize: 12,
	},
	fieldStyleWithDate: {
		borderRadius: '21px 0 0 21px',
		borderRight: 'none',
		padding: '8px 16px',
		fontSize: 12,
		height: 40,
	},
	textField: {
		float: 'right',
		maxWidth: '140px',
	},
	chip: {
		display: 'inline-flex',
		border: `1px solid ${colors.green600}`,
		background: colors.green,
		verticalAlign: 'middle',
		whiteSpace: 'nowrap',
		lineHeight: '20px',
		overflow: 'hidden',
		textAlign: 'center',
		borderRadius: 7,
		marginLeft: 7,
		height: 20,
		color: colors.white,
		userSelect: 'none',
		textTransform: 'uppercase',
		alignItems: 'center',
		alignContent: 'center',
		justifyContent: 'flex-start',
		padding: '0 4px',
		fontSize: 12,
		fontWeight: 'normal',
	},
	filterButtonIcon: {
		marginRight: 5,
		height: 17,
	},
	arrow: {
		margin: '-2px 6px 0 6px',
		verticalAlign: 'middle',
		width: '18px',
		height: '18px',
	},
}

export default withTranslate(connect(mapStateToProps, mapDispatchToProps)(BasicGridFilter))
