// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[3].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js!../../../variables/colors.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2EhfIloqsf92EN1YvvKYoE {\n\tmargin: 20px;\n}\n", "",{"version":3,"sources":["webpack://./modules/features/pages/features.css"],"names":[],"mappings":"AAEA;CACC,YAAA;AAKD","sourcesContent":["@value grey from \"variables/colors.css\";\n\n.container {\n\tmargin: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"grey": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["grey"] + "",
	"container": "_2EhfIloqsf92EN1YvvKYoE"
};
export default ___CSS_LOADER_EXPORT___;
