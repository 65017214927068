import React from 'react'
import pure from 'recompose/pure'
import SvgIcon from 'components/SvgIcon'

let Valid = props => {
	props = Object.assign({}, { viewBox: '0 0 21 21' }, { style: { width: 21, height: 21 } }, props)
	return (
		<SvgIcon {...props}>
			<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<circle fill="#29BB4D" cx="10.5" cy="10.5" r="10.5" />
				<path
					d="M9.5519323,11.701569 L6.99136276,9.08794361 L6,10.0793064 L8.53280387,12.6645907 L8.50863724,12.6874269 L9.5,13.6787897 L9.51371656,13.6658283 L9.52641486,13.6787897 L9.97980299,13.2254015 L15.5187823,7.99136276 L14.5274195,7 L9.5519323,11.701569 Z"
					fill="#FFFFFF"
				/>
			</g>
		</SvgIcon>
	)
}
Valid = pure(Valid)

export default Valid
