/* @flow */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import cashbotLogo from 'assets/images/cashbot-logo.svg'
import Button from 'components/button'
import Loader from 'components/loader'
import { colors } from 'variables'

type Props = {|
	text?: string | React$Node,
	showLoader?: boolean,
	button?: {
		text: string,
		onClick: Function,
		disabled: boolean,
	},
|}

export default function CashbotPanel(props: Props) {
	return (
		<div css={style.root}>
			<img src={cashbotLogo} css={style.logo} />
			<div css={style.text}>{props.text}</div>
			{props.showLoader && (
				<div css={style.right}>
					<Loader visible size={24} />
				</div>
			)}
			{props.button && (
				<div css={style.right}>
					<Button
						autoTestId="cashbot-panel-button"
						disabled={props.button.disabled}
						onClick={props.button.onClick}
						labelText={props.button.text}
						tertiary
					/>
				</div>
			)}
		</div>
	)
}

const style = {
	root: {
		paddingLeft: 15,
		paddingRight: 15,
		minHeight: 50,
		display: 'flex',
		alignItems: 'center',
		background: colors.white,
		borderWidth: 1,
		borderColor: '#D8D8D8',
		borderStyle: 'solid',
		borderRadius: 2,
		lineHeight: '18px',
		fontSize: 14,
		marginTop: 12,
		marginBottom: 3,
	},
	logo: {
		display: 'block',
		height: 'auto',
		width: 54,
		marginRight: 10,
		flex: '0 0 54px',
		position: 'relative',
		top: -1,
	},
	text: {
		flex: '1 1 100%',
		paddingTop: 10,
		paddingBottom: 10,
	},
	right: {
		display: 'block',
		flex: '0 0 auto',
		position: 'relative',
		marginLeft: 15,
		minWidth: 24,
		minHeight: 24,
	},
}
