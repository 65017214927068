/* @flow */

import type { Filter, SearchFilter } from 'types'
import type { Action } from '../actions/action-types'

export type State = {
	[filterName: string]: Filter,
}

export const initialState: State = {}

export default (state: State = initialState, action: Action): State => {
	switch (action.type) {
		case 'CHANGE_GRID_FILTER': {
			return {
				...state,
				[action.name]: action.filter,
			}
		}

		case 'CLEAR_FILTER_FIELD': {
			const newState = { ...state }
			const filter = [...newState[action.filterName]]
			const index = findFilterFieldIndex(filter, action.fieldName)
			if (index >= 0) {
				filter.splice(index, 1)
				return {
					...newState,
					[action.filterName]: filter,
				}
			} else return state
		}

		case 'CLEAR_ALL_FILTERS': {
			const newState = { ...state }
			delete newState[action.filterName]
			return newState
		}

		default:
			return state
	}
}

function findFilterFieldIndex(filter: Filter, fieldName: string) {
	return filter.findIndex((searchFilter: SearchFilter) => !!searchFilter && fieldName === searchFilter.field)
}
