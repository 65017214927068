// @flow
/** @jsx jsx */

import { Component } from 'react'
import { jsx } from '@emotion/core'
import memoize from 'memoize-one'
import { Checkbox, FileIcon } from 'components'
import { colors } from 'variables'

type Props = {|
	guid: string,
	name: string,
	onCheck: (guid: string) => void,
|}

type State = {|
	checked: boolean,
|}

class UnjoinScanLine extends Component<Props, State> {
	state = {
		checked: false,
	}

	onCheck = () => {
		this.setState(
			(prevState: State) => ({
				checked: !prevState.checked,
			}),
			this.props.onCheck(this.props.guid),
		)
	}

	getStyles = memoize((checked: boolean) => {
		return {
			file: {
				padding: '0 20px',
				position: 'relative',
				overflow: 'visible',
				lineHeight: '30px',
				display: 'flex',
				alignItems: 'center',
				marginBottom: 1,
				borderRadius: checked ? 1 : 0,
				backgroundColor: checked ? colors.blueFaded10 : 'transparent',

				'&:hover': {
					borderRadius: 1,
					backgroundColor: colors.blueFaded10,
				},
			},
			icon: {
				padding: '0 13px',
				lineHeight: '30px',
				display: 'flex',
				alignItems: 'center',
				marginBottom: 1,
			},
			filename: {
				lineHeight: '30px',
				display: 'flex',
				alignItems: 'center',
				marginBottom: 1,
				textOverflow: 'ellipsis',
				overflow: 'hidden',
				whiteSpace: 'nowrap',
			},
		}
	})

	render() {
		const { guid, name } = this.props
		const styles = this.getStyles(this.state.checked)

		return (
			<div css={styles.file} key={`scan-${guid || ''}-file-${name || ''}-idx${guid}`}>
				<div>
					<Checkbox checked={this.state.checked} onCheck={this.onCheck} />
				</div>
				<span css={styles.icon}>
					<FileIcon size={20} fileName={name} />
				</span>
				<span css={styles.filename}>{name}</span>
			</div>
		)
	}
}

export default UnjoinScanLine
