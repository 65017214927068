// @flow
/** @jsx jsx */

import { PureComponent, type Node } from 'react'
import { jsx } from '@emotion/core'
import memoize from 'memoize-one'
import { colors } from 'variables'

export type Props = {|
	disabled?: boolean,
	children: Node,
	color?: string,
	hoverColor?: string,
	style?: Object,
	size?: number,
	viewBox?: string,
	onClick?: () => void,
	onMouseEnter?: () => void,
	onMouseLeave?: () => void,
|}

class SvgIcon extends PureComponent<Props> {
	static defaultProps = {
		viewBox: '0 0 24 24',
	}

	getStyles = memoize(
		(disabled?: boolean, color?: string, hoverColor?: string, style?: Object, size?: number, onClick: boolean) => {
			const offColor = color ? color : 'currentColor'
			const onColor = hoverColor ? hoverColor : colors.blue

			const styles = {
				svg: {
					display: 'inline-block',
					color: colors.black,
					fill: offColor,
					userSelect: 'none',
					transition: 'all 300ms cubic-bezier(0.23, 1, 0.32, 1)',
					height: 24,
					width: 24,
					cursor: disabled && !onClick ? 'unset' : 'pointer',
					...style,
					'&:hover': {
						fill: disabled ? offColor : onColor,
					},
				},
			}

			if (size) {
				styles.svg.height = size
				styles.svg.width = size
			}

			return styles
		},
	)

	render() {
		const styles = this.getStyles(
			this.props.disabled,
			this.props.color,
			this.props.hoverColor,
			this.props.style,
			this.props.size,
			!!this.props.onClick,
		)

		return (
			<svg
				css={styles.svg}
				viewBox={this.props.viewBox}
				onClick={this.props.onClick}
				onMouseEnter={!this.props.disabled ? this.props.onMouseEnter : undefined}
				onMouseLeave={!this.props.disabled ? this.props.onMouseLeave : undefined}
			>
				{this.props.children}
			</svg>
		)
	}
}

export default SvgIcon
