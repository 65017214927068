// @flow
/** @jsx jsx */

import { jsx } from '@emotion/core'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import Button from 'components/button'

type Props = {|
	...WithTranslateProps,
	onEdit: () => void,
|}

function EditButton(props: Props) {
	return (
		<div>
			<Button autoTestId="edit-button" onClick={props.onEdit} labelText={props.t('grid.action.edit')} />
		</div>
	)
}

export default withTranslate(EditButton)
