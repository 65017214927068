// @flow

import React from 'react'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import UnmatchedPaymentsGrid from 'modules/data-grid-next/implemented/unmatched-payment-grid'
import Section from '../todo/section'

function UnmatchedPayments(props: WithTranslateProps) {
	return (
		<Section header={props.t('dashboard.todo.unmatchedPayments.header')}>
			<UnmatchedPaymentsGrid />
		</Section>
	)
}

export default withTranslate(UnmatchedPayments)
