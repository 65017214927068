/* @flow */

import type { Dispatch, State, StickyNote } from 'types'
import { createStickyNote, deleteStickyNote, getStickyNotes, updateStickyNote } from '../actions'

import type { Action as StickyNoteAction } from '../actions/action-types'
import StickyNotes from '../components/sticky-notes'
import { connect } from 'react-redux'
import withAutoload from 'wrappers/with-autoload'

type StateProps = {|
	stickyNotes: Array<StickyNote>,
|}
const mapStateToProps = (state: State): StateProps => {
	const { stickyNotes } = state.internalDashboard
	return {
		stickyNotes,
	}
}

type DispatchProps = {|
	createStickyNote: (stickyNote: StickyNote) => Promise<StickyNote>,
	updateStickyNote: (stickyNote: StickyNote) => ?Promise<StickyNote>,
	deleteStickyNote: (stickyNoteId: string) => Promise<void>,
	getStickyNotes: () => Promise<Array<StickyNote>>,
	autoload: () => void,
|}
const mapDispatchToProps = (dispatch: Dispatch<StickyNoteAction>): DispatchProps => {
	return {
		createStickyNote: (stickyNote: StickyNote) => dispatch(createStickyNote(stickyNote)),
		deleteStickyNote: (stickyNoteId: string) => dispatch(deleteStickyNote({ stickyNoteId })),
		getStickyNotes: () => dispatch(getStickyNotes({})),
		updateStickyNote: (stickyNote: StickyNote) => {
			return stickyNote.id != null ? dispatch(updateStickyNote({ stickyNoteId: stickyNote.id }, stickyNote)) : null
		},
		autoload: () => {
			dispatch(getStickyNotes({}))
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(withAutoload(StickyNotes))
