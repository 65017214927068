/* @flow */

import InvitationPage from '../pages/invitation'
import type { UserAction } from 'types'
import { connect } from 'react-redux'
import { logout } from 'modules/user/actions'

type DispatchProps = {|
	logout: () => void,
|}

const mapDispatchToProps = (dispatch: Dispatch<UserAction>): DispatchProps => {
	return {
		logout: () => {
			dispatch(logout())
		},
	}
}

export default connect(undefined, mapDispatchToProps)(InvitationPage)
