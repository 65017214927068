/* @flow */

import React, { Component } from 'react'
import DOMPurify from 'dompurify'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import type { AccountingDocument } from 'types'
import Paper from 'components/Paper'
import PopupSection from 'components/PopupSection'
import TextField from 'components/TextField'
import styles from './show-email-dialog.css'

export type Props = {|
	open: boolean,
	accountingDocument: ?AccountingDocument,
	onRequestClose: () => void,
|}

class ShowEmailDialog extends Component<{| ...Props, ...WithTranslateProps |}> {
	render() {
		const { t, accountingDocument } = this.props
		const email = accountingDocument && accountingDocument._sourceData && accountingDocument._sourceData.email
		if (!email) {
			return null
		}

		return (
			<PopupSection opaque open={this.props.open} onRequestClose={this.props.onRequestClose}>
				<div className={styles.root}>
					<Paper rounded zDepth={6} className={styles.box}>
						<div className={styles.head}>
							<div className={styles.headline}>{t('dialogs.sentInvoiceByMailHeadline')}</div>
							<div className={styles.field}>
								<TextField
									fullWidth
									disabled
									labelText={t('dialogs.sentInvoiceByMailFrom')}
									autoTestId="email-dialog-from"
									value={email.from}
									name="mail-from"
								/>
							</div>
							<div className={styles.field}>
								<TextField
									fullWidth
									disabled
									labelText={t('dialogs.sentInvoiceByMailSubject')}
									autoTestId="email-dialog-subject"
									value={email.subject}
									name="subject"
								/>
							</div>
						</div>
						<div className={styles.body}>
							<div className={styles.bodyLabel}>{t('dialogs.sentInvoiceByMailBody')}</div>
							<div
								className={styles.emailBody}
								dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(email.body) }} // eslint-disable-line react/no-danger
							/>
						</div>
					</Paper>
				</div>
			</PopupSection>
		)
	}
}

export default withTranslate(ShowEmailDialog)
