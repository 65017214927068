/* @flow */
import React, { Component } from 'react'
import type { WithRouterProps } from 'types'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import { withRouter } from 'react-router'
import { accountingDocumentsTemplatesRoute } from '../../routing/routes'

import IconButton from 'components/icon-button'
import TemplatesIcon from 'components/svg-icons/file/templates'

type Props = {|
	...WithTranslateProps,
	...WithRouterProps,
|}

class TemplateButton extends Component<Props> {
	openTemplates = () => {
		this.props.history.push(accountingDocumentsTemplatesRoute())
	}
	render() {
		return (
			<IconButton
				onClick={this.openTemplates}
				tooltip={this.props.t('accountingDocument.templateTooltip')}
				autoTestId="document-template"
				circled
			>
				<TemplatesIcon />
			</IconButton>
		)
	}
}

export default withRouter(withTranslate(TemplateButton))
