/* @flow */

import { get, isEqual, maxBy, toArray, uniq } from 'lodash-es'
import type { Channel, Channels, ChannelStatus, Member, Members, Message, WindowState } from '../types'
import sid from '../utils/sid'
import { getMember } from './member'
import type { WindowProps } from './window'

export function createChannel(
	sid: string,
	members: Members,
	name: string,
	window: WindowState,
	status: ChannelStatus,
	position?: number,
	unsentMessage?: ?Message,
): Channel {
	return {
		sid,
		name,
		window,
		status,
		members,
		position,
		unsentMessage,
	}
}

export function createNewChannel(members: Members, name?: string, window?: WindowState = 'OPEN'): Channel {
	if (!name) {
		name = createChannelDefaultName(members)
	}

	return createChannel(sid('LCH', 15), members, name, window, 'NEW')
}

export function createChannelDefaultName(members: Members | Array<string>): string {
	return `Channel with ${members
		.map((member: Member | string) => {
			return 'string' === typeof member ? member : member.id
		})
		.join(', ')} created at ${new Date().toISOString()}`
}

export function mergeChannel(channels: Channels, channel: Channel): Channels {
	return {
		...channels,
		...{
			[channel.sid]: {
				...channel,
				// Sometimes received channel may not contain a members => use members from a prev version
				...{
					members:
						(channel.members && channel.members.length) || !channels[channel.sid]
							? channel.members
							: channels[channel.sid].members,
				},
			},
		},
	}
}

export function mergeChannelUnsentMessage(channels: Channels, channel: Channel, message: ?Message) {
	return {
		...channels,
		...{
			[channel.sid]: {
				...channel,
				unsentMessage: message,
			},
		},
	}
}

export function removeChannel(channels: Channels, sid: ?string): Channels {
	const { [sid || '']: _, ...rest } = channels
	return rest
}

export function getChannelUnreadMessagesCount(channel: ?Channel, lastMessage: ?Message, currentUser: string): number {
	// Channel not exists => no unread messages
	if (!channel) return 0

	const currentMember = getMember(channel.members, currentUser)
	// => no unread messages
	if (!lastMessage || !currentMember) return 0

	// All messages are unread
	if ('number' !== typeof currentMember.lastConsumedMessageIndex) {
		return (lastMessage.index || 0) + 1
	}

	return Math.max((lastMessage.index || 0) - currentMember.lastConsumedMessageIndex, 0)
}

export function getChannelPosition(channels: Channels, channel: Channel, windowState: WindowState): ?number {
	if ('CLOSED' === windowState) {
		return
	}
	if ('undefined' !== typeof channel.position) {
		return channel.position
	} else {
		return getMaxChannelPosition(channels) + 1
	}
}

export function getMaxChannelPosition(channels: Channels): number {
	return get(maxBy(toArray(channels), 'position'), 'position', 0)
}

export function mergeChannelLocalState(channel: Channel, localChannel: ?Channel, windowProps: WindowProps): Channel {
	if (windowProps.window && 'number' === typeof windowProps.position) {
		channel.window = windowProps.window
		channel.position = windowProps.position
	} else if (localChannel) {
		channel.window = localChannel.window
		channel.position = localChannel.position
	}

	return channel
}

export function getAllChannelsMemberIds(channels: Channels): Array<string> {
	return uniq(
		toArray(channels).reduce((ids: Array<string>, channel: Channel) => {
			getChannelMemberIds(channel).map((id: string) => ids.push(id))
			return ids
		}, []),
	)
}

export function getChannelMemberIds(channel: Channel): Array<string> {
	return channel.members.map((member: Member) => member.id)
}

export function findChannelByMemberIds(channels: Array<Channel>, memberIds: Array<string>): ?Channel {
	return channels.find((channel: Channel) => {
		if (isEqual([...memberIds].sort(), getChannelMemberIds(channel).sort())) {
			return channel
		}
	})
}
