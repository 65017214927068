/* @flow */

import React from 'react'
import { Route } from 'react-router'
import MainLayout from 'layouts/main-layout'

// Libraries
import { UserIsAuthenticated } from 'lib/auth'

import DocumentListPage from '../pages/document-list-page'

const DocumentListPageComponent = UserIsAuthenticated(MainLayout('document')(DocumentListPage))

const DocumentRouting = () => [
	<Route
		exact
		key="/:organizationId/documents"
		path="/:organizationId/documents"
		component={DocumentListPageComponent}
	/>,
	<Route
		exact
		key="/:organizationId/documents/:itemId"
		path="/:organizationId/documents/:itemId"
		component={DocumentListPageComponent}
	/>,
	<Route
		exact
		key="/:organizationId/documents/search/:searchText"
		path="/:organizationId/documents/search/:searchText"
		component={DocumentListPageComponent}
	/>,
]

export default DocumentRouting
