// @flow

import React, { Component } from 'react'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import TextField from 'components/TextField'
import Tooltip from 'components/tooltip'

type Props = {|
	...WithTranslateProps,
	value: ?string,
	onChange?: string => void,
	disabled?: boolean,
|}

type State = {|
	value: string,
	isTooltipVisible: boolean,
|}

class AccountingDocumentGridNoteEditor extends Component<Props, State> {
	state = {
		value: this.props.value || '',
		isTooltipVisible: false,
	}

	UNSAFE_componentWillMount() {
		this.setState({
			isTooltipVisible: this.shouldShowTooltip(this.props.value),
		})
	}

	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		if (this.props.value !== nextProps.value) {
			this.setState({
				value: nextProps.value || '',
			})
		}
	}

	shouldShowTooltip = (value: ?string) => {
		return !!value && value.length > 12
	}

	handleTextFieldChange = (event: SyntheticInputEvent<HTMLInputElement>, value: ?string) => {
		if (value !== null && value !== undefined) {
			this.setState({
				isTooltipVisible: this.shouldShowTooltip(value),
				value: value,
			})
		}
	}

	handleKeyDown = (event: SyntheticKeyboardEvent<HTMLInputElement>) => {
		// $FlowFixMe
		const target: TextField = event.target
		if (event.key === 'Enter') {
			// $FlowFixMe
			target.blur()
		}
		if (event.key === 'Escape') {
			this.setState(
				{
					value: this.props.value || '',
				},
				() => {
					// $FlowFixMe
					target.blur()
				},
			)
		}
	}

	handleTextFieldBlur = () => {
		if (this.props.value !== this.state.value) {
			this.props.onChange && this.props.onChange(this.state.value)
		}
	}

	render() {
		return (
			<Tooltip label={this.state.value} disabled={!this.state.isTooltipVisible} multiLine inline>
				<TextField
					inline
					fullWidth
					value={this.state.value}
					onBlur={this.handleTextFieldBlur}
					onChange={this.handleTextFieldChange}
					onKeyDown={this.handleKeyDown}
					hintText={this.props.disabled ? '' : this.props.t('accountingDocument.columns.noteHint')}
					disabled={this.props.disabled}
					autoTestId="grid-note-editor-input"
					name="note"
				/>
			</Tooltip>
		)
	}
}

export default withTranslate(AccountingDocumentGridNoteEditor)
