// @flow
// $FlowFixMe - there is useMemo, useCallback and useState
import React, { type ElementRef, useState, useCallback, useMemo } from 'react'
import TextareaAutosize from 'react-textarea-autosize'

type Props = {
	cx?: Function,
	className?: string,
	'aria-autocomplete'?: string,
	'aria-label'?: string,
	'aria-labelledby'?: string,
	autoCapitalize?: string,
	autoComplete?: string,
	autoCorrect?: string,
	getStyles?: Function,
	id?: string,
	innerRef: (ElementRef<*>) => void,
	isDisabled?: boolean,
	isHidden?: boolean,
	onBlur?: Function,
	onChange?: Function,
	onFocus?: Function,
	selectProps?: Object,
	spellCheck?: boolean,
	tabIndex?: number,
	theme?: Object,
	type?: string,
	value?: string,
	maxRows?: number,
}

function onMouseDown(e: SyntheticMouseEvent<HTMLTextAreaElement>) {
	e.stopPropagation()
}

function onKeyPress(e: SyntheticKeyboardEvent<HTMLTextAreaElement>) {
	if (e.key === 'Enter' || e.charCode === 13) {
		// We are not allowed to break the lines, enter is used to select option
		e.preventDefault()
	}
}

export default function MultilineInput(props: Props) {
	const { onFocus: props_onFocus, onBlur: props_onBlur } = props
	const [visible, setVisible] = useState(false)

	const onFocus = useCallback(
		(...args: any) => {
			setVisible(true)
			props_onFocus && props_onFocus(...args)
		},
		[props_onFocus],
	)

	const onBlur = useCallback(
		(...args: any) => {
			setVisible(false)
			props_onBlur && props_onBlur(...args)
		},
		[props_onBlur],
	)

	const wrapperStyle = useMemo(
		() => ({
			height: visible ? undefined : '0px',
			opacity: props.isHidden ? 0 : 1,
			width: '100%',
		}),
		[props.isHidden, visible],
	)

	const inputStyle = useMemo(
		() => ({
			label: 'input',
			background: 0,
			border: 0,
			fontSize: 'inherit',
			outline: 0,
			padding: '0px 6px 0px 2px',
			marginBottom: '-4px',
			color: 'inherit',
			fontFamily: 'inherit',
			width: '100%',
			lineHeight: '20px',
			whiteSpace: 'pre-wrap',
			overflow: 'hidden',
			resize: 'none',
		}),
		[],
	)

	return (
		<div style={wrapperStyle}>
			<TextareaAutosize
				{...cleanInputProps(props)}
				ref={props.innerRef}
				disabled={props.isDisabled}
				style={inputStyle}
				onFocus={onFocus}
				onBlur={onBlur}
				onMouseDown={onMouseDown}
				onKeyPress={onKeyPress}
				maxRows={props.maxRows}
			/>
		</div>
	)
}

const INPUT_PROPS_BLACKLIST = [
	'extraWidth',
	'injectStyles',
	'inputClassName',
	'inputRef',
	'inputStyle',
	'minWidth',
	'onAutosize',
	'placeholderIsMinWidth',
	'cx',
	'selectProps',
	'isHidden',
	'isDisabled',
	'innerRef',
	'getStyles',
]

const cleanInputProps = (props: Props) => {
	const inputProps = { ...props }
	INPUT_PROPS_BLACKLIST.forEach((field: string) => delete inputProps[field])
	return inputProps
}
