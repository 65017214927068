/* @flow */

import React from 'react'
import type { I18NextTranslateFn, TriviStoreCard } from 'types'
import eAdvokacie from 'assets/images/trivi-store/eadvokacie.svg'
import shoptet from 'assets/images/trivi-store/shoptet.svg'
import cashbotLogo from 'assets/images/cashbot-logo.svg'
// import kb from 'assets/images/trivi-store/kb.svg'

export const CARD_TYPES = {
	// KB: 'kb',
	DEFAULT: 'default',
}

const styles = {
	eadvokacie: {
		content: {
			maxWidth: '85%',
		},
	},
	// kb: {
	// 	logo: {
	// 		maxWidth: 160,
	// 	},
	// },
	shoptet: {
		content: {
			maxWidth: '80%',
		},
		logo: {
			maxWidth: 300,
		},
		list: {
			marginLeft: 30,
		},
	},
	cashbot: {
		content: {
			maxWidth: '60%',
		},
		logo: {
			maxWidth: 200,
		},
	},
}

const eAdvokacieCard: TriviStoreCard = {
	type: CARD_TYPES.DEFAULT,
	content: <img src={eAdvokacie} style={styles.eadvokacie.content} />,
	logo: <img src={eAdvokacie} />,
	price: '20%',
	unit: 'triviStore.cards.eadvokacie.unit',
	headline: 'triviStore.cards.eadvokacie.headline',
	subheadline: 'triviStore.cards.eadvokacie.subheadline',
	about: 'triviStore.cards.eadvokacie.about',
	button: {
		label: 'triviStore.cards.eadvokacie.label',
		url: {
			EN: 'https://online.e-advokacie.cz/trivi-klienti?lang=en',
			CS: 'https://online.e-advokacie.cz/trivi-klienti?lang=cs',
			default: 'https://online.e-advokacie.cz/trivi-klienti',
		},
	},
}

const formattedText = (t: I18NextTranslateFn) => {
	return (
		<ul style={styles.shoptet.list}>
			<li>{t('triviStore.cards.shoptet.formattedText.item1')}</li>
			<li>{t('triviStore.cards.shoptet.formattedText.item2')}</li>
		</ul>
	)
}

const shoptetCard: TriviStoreCard = {
	type: CARD_TYPES.DEFAULT,
	content: <img src={shoptet} style={styles.shoptet.content} />,
	logo: <img src={shoptet} style={styles.shoptet.logo} />,
	offer: 'triviStore.cards.shoptet.offer',
	unit: '',
	headline: 'triviStore.cards.shoptet.headline',
	subheadline: 'triviStore.cards.shoptet.subheadline',
	about: 'triviStore.cards.shoptet.about',
	formattedText,
	button: {
		label: 'triviStore.cards.shoptet.label',
		url: {
			EN: 'https://doplnky.shoptet.cz/trivi',
			CS: 'https://doplnky.shoptet.cz/trivi',
			default: 'https://doplnky.shoptet.cz/trivi',
		},
	},
}

// const kbCard: TriviStoreCard = {
// 	type: CARD_TYPES.KB,
// 	content: <img src={kb} style={styles.kb.logo} />,
// 	logo: <img src={kb} style={styles.kb.logo} />,
// 	unit: 'triviStore.cards.kb.unit',
// }

function getCashbotCard(config: CashbotConfig): TriviStoreCard {
	return {
		type: CARD_TYPES.DEFAULT,
		content: <img src={cashbotLogo} style={styles.cashbot.content} />,
		logo: <img src={cashbotLogo} style={styles.cashbot.logo} />,
		price: 'triviStore.cards.cashbot.price',
		unit: '',
		headline: 'triviStore.cards.cashbot.headline',
		subheadline: 'triviStore.cards.cashbot.subheadline',
		about: 'triviStore.cards.cashbot.about',
		button: {
			label: config.isCashbotAuthenticated
				? 'triviStore.cards.cashbot.button.labelConnected'
				: 'triviStore.cards.cashbot.button.labelConnect',
			disabled: config.isCashbotAuthenticated,
			onClick: config.loginToCashbot,
		},
	}
}

type CashbotConfig = {|
	isCashbotEnabled: boolean,
	isCashbotAuthenticated: boolean,
	loginToCashbot: () => Promise<void>,
|}
type CardConfig = {|
	cashbot: CashbotConfig,
|}

export function getTriviStoreItems(config: CardConfig) {
	const cards = [eAdvokacieCard, shoptetCard]

	if (config.cashbot.isCashbotEnabled) {
		cards.push(getCashbotCard(config.cashbot))
	}

	return cards
}
