//@flow
import moment from 'moment'
import type { CashbotInvoice } from 'modules/cashbot/types'
import type { AccountingDocument, AccountingDocumentDirection } from 'types'
import { Number_AccountingDocumentAssignedDirection } from 'types/convertor'
import { isAccDocOverdue, getAccDocCashbotId } from 'modules/accounting-document/domain/accounting-document'
import { isCashbotEnabledSelector } from 'modules/organization/selectors'
import { getIsCashbotAuthenticated } from 'modules/cashbot/selectors'
import { formatToMoney } from 'utils/formatters'
import store from 'store'

function isCashbotEnabled(): boolean {
	// TODO-CASHBOT nějak jinak než přímým přístupem do storu?
	return isCashbotEnabledSelector(store.getState())
}

function isCashbotAuthenticated(): boolean {
	// TODO-CASHBOT nějak jinak než přímým přístupem do storu?
	return getIsCashbotAuthenticated(store.getState())
}

export function formatCashbotDate(date: Date | string): string {
	return moment(date).format('YYYY-MM-DD')
}

export function showCashbotPanelOnAccDoc(accDoc: AccountingDocument): boolean {
	if (!isCashbotEnabled()) {
		return false
	}

	const direction = Number_AccountingDocumentAssignedDirection(accDoc.direction)
	const accountingDocumentState = (accDoc && accDoc.state) || ''

	if (isAccDocOverdue(accDoc)) return false
	if ('received' === direction && (!accDoc.scans || 0 === accDoc.scans.length)) return false

	// TODO-CASHBOT Zobrazovat panel jen na zpracovaných dokladech?
	return accountingDocumentState === 'Processed' && (direction === 'issued' || direction === 'received')
}

export function showCashbotPanelOnAccDocGrid(direction: ?AccountingDocumentDirection) {
	return direction === 'issued' && isCashbotEnabled()
}

export function showCashbotStatusOnAccDocGridLine(accDoc: AccountingDocument) {
	return getAccDocCashbotId(accDoc) && isCashbotAuthenticated() && showCashbotPanelOnAccDoc(accDoc)
}

export function showCashbotPayOnAccDocGridLine(accDoc: AccountingDocument) {
	// TODO-CASHBOT - akci zobrazit jen kdyz je cashbot zaputy atd
	return !getAccDocCashbotId(accDoc) && isCashbotAuthenticated() && showCashbotPanelOnAccDoc(accDoc)
}

export function getInvoiceAmount(invoice: ?CashbotInvoice): string {
	const amount = invoice && invoice.amount
	const currency = invoice && invoice.currency
	return formatToMoney(amount, { currency })
}
