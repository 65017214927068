//@flow
/** @jsx jsx */

import { Component } from 'react'
import { jsx } from '@emotion/core'
import memoize from 'memoize-one'
import type { WithTranslateProps } from 'wrappers'
import { withTranslate } from 'wrappers'
import SelectNext, { type OptionType, type ValueType } from 'components/select-next'
import Snackbar from 'components/snackbar'
import TriviLink from 'modules/common/components/trivi-link'
import { addressBookRoute } from 'modules/address-book/routing/routes'
import type { Contact } from 'modules/common/models/swagger-model'
import { getContactEmails } from '../../helpers'
import { colors } from 'variables'

type Props = {|
	...WithTranslateProps,
	contact: ?Contact,
	contactId: ?string,
	readonly: boolean,
	clientError?: ?string,
	emails?: Array<string>,
	onEmailsChange: (emails: Array<string>) => void,
	loadContact: (id: string) => void,
|}

type State = {|
	allEmails: Array<string>,
	emails: any,
|}

class ContactEmailsSelector extends Component<Props, State> {
	state: State = { emails: [], allEmails: [] }

	static getDerivedStateFromProps(nextProps: Props) {
		return nextProps && nextProps.emails ? { emails: nextProps.emails } : null
	}

	componentDidMount() {
		if (!this.props.contact && this.props.contactId) {
			this.props.loadContact(this.props.contactId)
		}
	}

	renderNoContact() {
		if (!this.props.contact) return null
		const { t, contact } = this.props
		const message = (
			<div>
				{t('invoice.settings.reminders.noContactPerson', {
					name: contact && contact.companyName,
				})}
				<div css={styles.snackbarLink}>
					<TriviLink
						style={styles.link}
						hoverStyle={styles.hoveredLink}
						routeFn={addressBookRoute}
						routeParams={[contact.id || '']}
					>
						{t('contactEdit.edit.addContactPerson')}
					</TriviLink>
				</div>
			</div>
		)

		return (
			<div css={styles.warning}>
				<Snackbar open inline showIcon message={message} type={'error'} />
			</div>
		)
	}

	onEmailsChange = (value: ValueType) => {
		if (value && Array.isArray(value)) {
			const selected = []
			value
				.map((email: OptionType) => email.value || '')
				.forEach((email: ?string) => {
					if (email && email.length > 0) {
						const str = email.toString()
						selected.push(str)
					}
				})

			const difference: Array<string> = []
			this.props.emails &&
				this.props.emails
					.filter((email: string) => !selected.includes(email) && !this.state.allEmails.includes(email))
					.forEach((email: ?string) => {
						if (email && email.length > 0) {
							const str = email.toString()
							difference.push(str)
						}
					})

			const emails: Array<string> = selected.concat(difference)
			this.setState({ emails }, this.props.onEmailsChange(emails))
		}
	}

	getEmails = memoize((contact: Contact): Array<string> => {
		return getContactEmails(contact)
	})

	getEmailsOptions = memoize((emails: Array<string>) => {
		return emails.map((email: string) => {
			return { value: email, label: email }
		})
	})

	render() {
		const { t, contact } = this.props
		const emails: Array<string> = contact ? this.getEmails(contact) : []
		if (emails.length > 0 && this.state.allEmails.length === 0) this.setState({ allEmails: emails })

		return (
			<div>
				{emails.length > 0 && (
					<div css={styles.field}>
						<SelectNext
							isMulti
							portal
							label={t('invoice.settings.reminders.selectEmailsLabel')}
							autoTestId="invoice-settings-reminders-emails-select"
							disabled={this.props.readonly}
							onChange={this.onEmailsChange}
							options={this.getEmailsOptions(emails)}
							value={this.state.emails}
							error={this.props.clientError}
						/>
					</div>
				)}
				{emails.length <= 0 && this.renderNoContact()}
			</div>
		)
	}
}

const styles = {
	field: {
		marginBottom: 15,
	},
	warning: {
		marginTop: 25,
		marginBottom: 30,
	},
	snackbarLink: {
		display: 'block',
		marginTop: 10,
	},
	link: {
		textDecoration: 'underline',
	},
	hoveredLink: {
		color: colors.white,
		textDecoration: 'none',
	},
}

export default withTranslate(ContactEmailsSelector)
