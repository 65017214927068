/* @flow */

import MainLayout from 'layouts/main-layout'
import React from 'react'
import { Route } from 'react-router'
import Task from 'modules/task/pages/task'
// import TaskCreate from 'modules/task/pages/task-create'
import TaskEdit from 'modules/task/pages/task-edit'
import TaskList from 'modules/task/pages/task-list'
import { UserIsAuthenticated } from 'lib/auth'

const ListComponent = UserIsAuthenticated(MainLayout('task')(TaskList))
// const CreateComponent = UserIsAuthenticated(MainLayout('task')(TaskCreate))
const DetailComponent = UserIsAuthenticated(MainLayout('task')(Task))
const EditComponent = UserIsAuthenticated(MainLayout('task')(TaskEdit))

const CashRegisterRouting = () => [
	<Route exact key="/tasks" path="/tasks" component={ListComponent} />,
	// <Route exact key="/tasks/create" path="/tasks/create" component={CreateComponent} />,
	<Route exact key="/tasks/:id" path="/tasks/:id" component={DetailComponent} />,
	<Route exact key="/tasks/:id/edit" path="/tasks/:id/edit" component={EditComponent} />,
]

export default CashRegisterRouting
