/* @flow */

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import { TagType_TagTypeNumber, number_TagType } from 'types/convertor'
import { deleteTag, editTag, createTag } from '../actions'
import { settingsBranchesRoute, settingsProjectsRoute } from 'modules/settings/routing/routes'
import { refreshGridData } from 'modules/data-grid-next/actions'
import SettingsSection from 'modules/settings/components/settings-section'
import TagsGrid from '../components/reporting/tags-grid'
import TagEditDialog from '../components/reporting/tag-edit-dialog'
import type { Dispatch, WithRouterProps, Tag, OrganizationAction, DataGridNextAction, TagType } from 'types'
import type { Action as ReportingAction } from '../actions/reporting-action-types'
import type { SubSectionType } from './settings-layout'

type DispatchProps = {|
	editTag: (tagId: string, tag: Tag, gridId: GridId) => Promise<void>,
	createTag: (tag: Tag, gridId: GridId) => void,
	deleteTag: (tag: Tag, gridId: GridId) => void,
|}

function mapDispatchToProps(
	dispatch: Dispatch<ReportingAction | OrganizationAction | DataGridNextAction>,
): DispatchProps {
	function refreshGrid(gridId: GridId, tag: Tag) {
		dispatch(
			refreshGridData('tags', gridId, gridId, [
				{
					field: 'type',
					value: tag.type,
				},
			]),
		)
	}
	return {
		editTag: async (tagId: string, tag: Tag, gridId: GridId) => {
			await dispatch(editTag(tagId, tag))
			await refreshGrid(gridId, tag)
		},
		deleteTag: (tag: Tag, gridId: GridId) => {
			if (tag.id) {
				dispatch(deleteTag(tag.id)).then(() => refreshGrid(gridId, tag))
			}
		},
		createTag: (tag: Tag, gridId: GridId) => {
			dispatch(createTag(tag)).then(() => refreshGrid(gridId, tag))
		},
	}
}

type GridId = 'branches' | 'projects'

const GRID_IDS: { [TagType]: GridId } = {
	branch: 'branches',
	project: 'projects',
}

type Props = {|
	...WithTranslateProps,
	...WithRouterProps,
	subSection?: SubSectionType,
	editTag: (id: string, tag: Tag, gridId: GridId) => void,
	createTag: (tag: Tag, gridId: GridId) => void,
	deleteTag: (tag: Tag, gridId: GridId) => void,
|}

type State = {|
	dialogOpen: boolean,
	tag: ?Tag,
|}

class Reporting extends Component<Props, State> {
	state = {
		dialogOpen: false,
		tag: null,
	}

	getGoToUrl = (url: string) => {
		return () => {
			this.props.history.push(url)
		}
	}

	getOnTagCreateClick = (tagType: TagType) => {
		return () => {
			this.setState({
				dialogOpen: true,
				tag: {
					name: '',
					code: '',
					type: TagType_TagTypeNumber(tagType),
				},
			})
		}
	}

	getOnTagEditClick = () => {
		return (tagId: string, tag: Tag) => {
			this.setState({
				dialogOpen: true,
				tag: tag,
			})
		}
	}

	getOnTagRemoveClick = (tagType: TagType) => {
		return (tag: Tag) => {
			this.props.deleteTag(tag, GRID_IDS[tagType])
		}
	}

	onTagEditConfirm = async (tag: Tag) => {
		const type: ?TagType = number_TagType(tag.type)
		if (!type) {
			console.error('onTagEditConfirm Unknown type of tag') //eslint-disable-line
			return
		}
		const gridId: GridId = GRID_IDS[type]
		if (tag.id != null) {
			await this.props.editTag(tag.id, tag, gridId)
		} else {
			await this.props.createTag(tag, gridId)
		}
		this.setState({ tag })
		this.closeDialog()
	}

	onTagEditCancel = () => {
		this.closeDialog()
	}

	closeDialog = () => {
		this.setState({
			dialogOpen: false,
		})
	}

	render() {
		const { t } = this.props
		return (
			<div>
				<SettingsSection
					visible={this.props.subSection === 'branches'}
					title={t('settings.reporting.branches')}
					onToggle={this.props.subSection !== 'branches' ? this.getGoToUrl(settingsBranchesRoute()) : undefined}
				>
					<TagsGrid
						gridId={GRID_IDS['branch']}
						tagType="branch"
						onTagCreateClick={this.getOnTagCreateClick('branch')}
						onTagEdit={this.getOnTagEditClick()}
						onTagRemove={this.getOnTagRemoveClick('branch')}
					/>
				</SettingsSection>

				<SettingsSection
					visible={this.props.subSection === 'projects'}
					title={t('settings.reporting.projects')}
					onToggle={this.props.subSection !== 'projects' ? this.getGoToUrl(settingsProjectsRoute()) : undefined}
				>
					<TagsGrid
						gridId={GRID_IDS['project']}
						tagType="project"
						onTagCreateClick={this.getOnTagCreateClick('project')}
						onTagEdit={this.getOnTagEditClick()}
						onTagRemove={this.getOnTagRemoveClick('project')}
					/>
				</SettingsSection>

				<TagEditDialog
					open={this.state.dialogOpen}
					tag={this.state.tag}
					onConfirm={this.onTagEditConfirm}
					onCancel={this.onTagEditCancel}
				/>
			</div>
		)
	}
}

export default withRouter(connect(undefined, mapDispatchToProps)(withTranslate(Reporting)))
