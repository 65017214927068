/* @flow */

import type { CommonAction, Country, Dispatch, State, OrganizationAction } from 'types'
import CountrySelector from 'modules/common/components/selectors/country-selector'
import { connect } from 'react-redux'
import { loadCountries } from 'modules/common/actions'
import {
	loadFavoriteCountries,
	addFavoriteCountry,
	removeFavoriteCountry,
} from 'modules/organization/actions/favorites'
import { getFavoriteCountries } from 'modules/organization/selectors/favorites'
import { getSortedCountries } from 'modules/common/selectors'

type StateProps = {|
	loading: boolean,
	countries: ?Array<Country>,
	favoriteCountries: ?Array<string>,
|}

const mapStateToProps = (state: State): StateProps => {
	return {
		loading: state.common.countries.loading,
		countries: getSortedCountries(state),
		favoriteCountries: getFavoriteCountries(state),
	}
}

type DispatchProps = {|
	loadCountries: () => void,
	onFavorite: (country: Country, isFavorite: boolean) => void,
	loadFavoriteCountries: () => void,
|}

const mapDispatchToProps = (dispatch: Dispatch<CommonAction | OrganizationAction>): DispatchProps => {
	return {
		loadCountries: () => {
			dispatch(loadCountries())
		},
		onFavorite: (country: Country, isFavorite: boolean) => {
			if (isFavorite) {
				dispatch(addFavoriteCountry(country))
			} else {
				dispatch(removeFavoriteCountry(country))
			}
		},
		loadFavoriteCountries: () => {
			dispatch(loadFavoriteCountries())
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(CountrySelector)
