// @flow

import type { AccountingDocument, DeferredPromise, I18NextTranslateFn, FormFieldErrorMessage } from 'types'
import { CASHBOT_ERROR_CODE_PREFIX } from 'trivi-constants'

export * from 'modules/accounting-document/helpers'
export * from 'modules/document/helpers'
export * from './errors-helpers'

export function getValues<Obj: Object>(o: Obj): Array<$Values<Obj>> {
	return Object.values(o)
}

export function getProcessingStateLabel(processingState: number): string {
	switch (processingState) {
		case 0:
			return 'notProcessed'
		case 1:
			return 'processed'
		case 2:
			return 'error'
		case 3:
			return 'processing'
		case 4:
			return 'unprocessable'
		default:
			return 'unknown'
	}
}

export function isDraft(accountingDocument: ?AccountingDocument): boolean {
	return (
		(accountingDocument && accountingDocument.state && accountingDocument.state.toLocaleLowerCase() === 'draft') ||
		false
	)
}

export function getPaymentState(accountingDocument: ?AccountingDocument): string {
	if (!accountingDocument || accountingDocument.total === 0) {
		return 'none'
	}

	if (accountingDocument.payments) {
		if (accountingDocument.payments.paidConfirmed) {
			return 'paidConfirmed'
		} else if (accountingDocument.payments.paid) {
			return 'paid'
		} else if (accountingDocument.payments.payments && accountingDocument.payments.payments.length > 0) {
			return 'partialyPaid'
		}
	}

	return 'unpaid'
}

type ServerMessage = $Supertype<{|
	code?: string,
	message?: string,
	params?: any,
	name?: string,
|}>

export function getServerErrorMessage(t: I18NextTranslateFn, message: ServerMessage | FormFieldErrorMessage): string {
	const concat = message.params && message.params.fieldNamesConcat && message.params.fieldNamesConcat.match(/'\w*'/g)
	// $FlowFixMe - false warning (FormFieldErrorMessage does not have 'name' property but its ok)
	const name = message && message.hasOwnProperty('name') ? message.name : ''
	const fieldName = (message.params && message.params.fieldName) || (message.params && message.params.FieldName) || name
	const params = {
		...(message.params || {}),
		fieldName: fieldName ? t(`errorFieldName.${fieldName}`) : undefined,
		fieldNamesConcat:
			concat && concat.length
				? `"${concat
						.map((field: string) => field.replace(/'/g, ''))
						.map((field: string) => t([`errorFieldName.${field}`, field]))
						.filter((field: string) => !!field.length)
						.join('", "')}"`
				: undefined,
	}

	// TODO-cashbot - pokud je zde tato podmínka, nepoužívají se překlady.
	// Čili pokud se tato podmínka odstraní, je nutno doplnit do překladových souborů
	// chybové hlášky pro všechny chybové kódy z Cashbota. Zatím objevené:
	// "cashbot401": "Cashbot: Nepovolená akce, přihlašte se.",
	// "cashbot60120040": "Cashbot: Neplatný datum splatnosti.",
	// "cashbot60120070": "Cashbot: Číslo faktury musí být zadáno.",
	// "cashbot60120020": "Cashbot: Faktura již byla financována.",
	// "cashbot60120030": "Cashbot: Neplatný datum vystavení."
	if (message.code && message.code.startsWith(CASHBOT_ERROR_CODE_PREFIX)) {
		return 'Cashbot: ' + (message.message || '')
	}

	return t(
		[(message.message || '').replace(/[:]/g, ''), 'serverError.' + (message.code || ''), 'serverError.fe0001'],
		params,
	).replace(/""/g, '')
}

export function createDeferredPromise(): DeferredPromise<any> {
	const obj = {}
	// $FlowFixMe: not sure how to improve it
	return Object.assign(
		new Promise((resolve: (?any) => any, reject: (?any) => any) => Object.assign(obj, { resolve, reject })),
		obj,
	)
}
