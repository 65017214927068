/* @flow */

import type {
	CashRegister,
	CashRegisterAction,
	CashRegisterSettings,
	Dispatch,
	State,
	SequencesAction,
	// OrganizationCountrySpecificSettingsCzEet,
} from 'types'
import { createCashRegister, updateCashRegisterSettings /* updateCashRegisterEet */ } from '../actions'
import CashRegisterEdit from '../components/cash-register-edit'
import type { CashRegisterState } from '../reducers'
import { cashRegisterRoute } from 'modules/cash-register/routing/routes'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { withNotify } from 'wrappers'
import { currentUserIsAdmin, currentUserIsInternal } from 'modules/user/selectors'
import { userHasAccess } from 'permissions/acl-logic'
import { loadSequences } from 'modules/accounting-document/actions'
import { getCashRegister } from '../selectors'

type Props = {|
	id?: string,
	isCreatingNewCashRegister?: boolean,
|}

type StateProps = {|
	cashRegister?: ?CashRegister,
	disabledCurrency?: boolean,
	currentUserIsInternal: boolean,
	canEditProfile: boolean,
	editCashRegisters: boolean,
|}

const mapStateToProps = (state: State, ownProps: Props): StateProps => {
	return {
		cashRegister: getCashRegister(state, ownProps.id),
		disabledCurrency: ownProps.isCreatingNewCashRegister ? false : !currentUserIsAdmin(state),
		currentUserIsInternal: currentUserIsInternal(state),
		canEditProfile: userHasAccess(state, 'setOrganizationProfile'),
		editCashRegisters: userHasAccess(state, 'editCashRegisters'),
	}
}

type DispatchProps = {|
	onCreate: (cashRegister: CashRegister) => ?Promise<CashRegisterAction>,
	onUpdateSettings: (
		cashRegister: CashRegisterSettings,
		oldCashRegister?: CashRegister,
	) => ?Promise<CashRegisterAction>,
	// onUpdateEet: (data: OrganizationCountrySpecificSettingsCzEet) => ?Promise<?CashRegisterAction>,
|}

const mapDispatchToProps = (
	dispatch: Dispatch<CashRegisterAction | SequencesAction>,
	ownProps: Props,
): DispatchProps => {
	return {
		onCreate: (cashRegister: CashRegister): ?Promise<CashRegisterAction> => {
			let promise: Promise<CashRegisterAction>

			promise = dispatch(createCashRegister(cashRegister)).then((action: CashRegisterAction) => {
				dispatch(loadSequences(false, false))
				return action
			})

			if (promise) {
				return promise.then((action: CashRegisterAction) => {
					if (action.cashRegister && action.cashRegister.id) {
						dispatch(push(cashRegisterRoute(action.cashRegister.id)))
					}
					return action
				})
			}
		},
		onUpdateSettings: (
			cashRegister: CashRegisterSettings,
			oldCashRegister?: CashRegister,
		): ?Promise<CashRegisterAction> => {
			let promise: ?Promise<CashRegisterAction> = null

			if (ownProps.id) {
				promise = dispatch(updateCashRegisterSettings(ownProps.id, cashRegister, oldCashRegister))
			}

			if (promise) {
				return promise.then((action: CashRegisterAction) => {
					if (ownProps.id) {
						dispatch(push(cashRegisterRoute(ownProps.id)))
					}
					return action
				})
			}
		},
		// onUpdateEet: async (data: OrganizationCountrySpecificSettingsCzEet): ?Promise<?CashRegisterAction> => {
		// 	if (ownProps.id) {
		// 		return await dispatch(updateCashRegisterEet(ownProps.id, data))
		// 	}
		// },
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(withNotify(CashRegisterEdit))
