/* @flow */

import type { AccountingDocumentReportsPostResponse as ReportResponse } from 'modules/common/models/api-model'
import { accountingDocumentReports as reportApi } from 'modules/common/models/api-model'
import type { AccountingDocumentReportRequest, DashboardAction, Dispatch } from 'types'

export function loadBalanceSummary(request: AccountingDocumentReportRequest) {
	return async (dispatch: Dispatch<DashboardAction>) => {
		dispatch({
			type: 'START_LOAD_BALANCE_SUMMARY',
			request,
		})
		try {
			const data: ReportResponse = await reportApi.post(request)

			return dispatch({
				type: 'FINISH_LOAD_BALANCE_SUMMARY',
				request,
				data,
			})
		} catch (error) {
			dispatch({
				type: 'FINISH_LOAD_BALANCE_SUMMARY',
				request,
				serverError: error,
			})
		}
	}
}
