//@flow
import nprogress from 'nprogress'
import { keys } from 'lodash-es'

const PROGRESS_TIMEOUT = 30000
const TASKS = {}

export function beginTask(taskId: string) {
	nprogress.start()

	if (TASKS[taskId]) {
		clearTimeout(TASKS[taskId])
	}

	TASKS[taskId] = setTimeout(() => {
		endTask(taskId)
	}, PROGRESS_TIMEOUT)
}

export function endTask(taskId: string) {
	clearTimeout(TASKS[taskId])
	delete TASKS[taskId]

	if (!keys(TASKS).length) {
		nprogress.done()
	}
}
