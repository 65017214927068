/* @flow */
/** @jsx jsx */

import { Component } from 'react'
import Loader from 'components/loader'
import { colors } from 'variables'
import { jsx } from '@emotion/core'

type Props = {|
	pdfUrl?: string,
	accountingDocumentId: ?string,
|}

class AccountingDocumentPreview extends Component<Props> {
	render() {
		return (
			<div css={styles.root}>
				{this.props.pdfUrl ? (
					<object css={styles.invoice} data={this.props.pdfUrl} type={'application/pdf'} />
				) : (
					<Loader size={60} visible />
				)}
			</div>
		)
	}
}

const styles = {
	root: {
		background: colors.white,
		minHeight: 1150,
		position: 'relative',
	},
	invoice: {
		display: 'block',
		width: '100%',
		height: 1150,
	},
}

export default AccountingDocumentPreview
