// @flow

import type { Country } from 'types'

export function getCountryNameById(countries: Array<Country>, id: string): ?string {
	const found = getCountryById(countries, id)
	return found ? found.name || null : null
}

export function getCountryById(countries: Array<Country>, id: string): ?Country {
	return countries.find((country: Country) => id === country.id)
}
