// @flow
import {
	POSSIBLE_DOCUMENT_TYPES,
	POSSIBLE_DOCUMENT_SOURCES,
	SORTED_DOCUMENT_ITEM,
	SORTED_STATEMENT_ITEM,
	POSSIBLE_STATEMENT_TYPES,
} from '../utils/constants'
import type { SortedAccountingDocuments, SortedStatements } from 'types'
import { ACCOUNTING_DOCUMENT_DIRECTION_TYPES } from 'types/convertor'

const spreadArrayToObject = (value: any) => (result: any, item: any): any => {
	return {
		...result,
		[item]: value,
	}
}

export function createEmptySortedDocumentsObject(): SortedAccountingDocuments {
	const directions = Object.values(ACCOUNTING_DOCUMENT_DIRECTION_TYPES)
	const typesObj = POSSIBLE_DOCUMENT_TYPES.reduce(spreadArrayToObject(SORTED_DOCUMENT_ITEM), {})
	const directionsObj = directions.reduce(spreadArrayToObject(typesObj), {})
	return POSSIBLE_DOCUMENT_SOURCES.reduce(spreadArrayToObject({ ...directionsObj, price: 0 }), {})
}

export function createEmptySortedStatementsObject(): SortedStatements {
	const states = [1, 2]
	const typesObj = POSSIBLE_STATEMENT_TYPES.reduce(spreadArrayToObject(SORTED_STATEMENT_ITEM), {})
	return {
		...states.reduce(spreadArrayToObject(typesObj), {}),
		price: 0,
	}
}
