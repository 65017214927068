/* @flow */

import React, { Component } from 'react'
import { connect } from 'react-redux'
import type {
	AccountingDocument,
	Dispatch,
	DocumentExtractionAction,
	AccountingDocumentReduxAction,
	ScanFactoryDocumentFileMetadata,
	ScanFactoryBbWord,
	ScanFactoryField,
	State,
} from 'types'
import { loadAccountingDocument } from '../../accounting-document/actions'
import { getAccountingDocumentRaw } from '../../accounting-document/selectors'
import { assignBbWord, moveFieldCursorNext, moveFieldCursorPrev, resizePreview } from '../actions'
import DocumentPreview from '../components/document-preview'
import { getField } from '../domain/field'
import { getBbWordFieldMap, getPreviewWidth } from '../selectors'
import type { BbWordFieldMap, BbWordCursor, ExtractionDocument, FieldCursor } from '../types'

type OwnProps = {|
	documentId: string,
	extractionDocument: ExtractionDocument,
	highlightBbWords: ?Array<BbWordCursor>,
	onBbWordMouseOver: (bbWord: BbWordCursor) => void,
	onBbWordMouseOut: (bbWord: BbWordCursor) => void,
|}

export type StateProps = {|
	metadata: ScanFactoryDocumentFileMetadata,
	documentId: string,
	bbWordFieldMap: BbWordFieldMap,
	fieldCursor: FieldCursor,
	field: ?ScanFactoryField,
	highlightBbWords: ?Array<BbWordCursor>,
	width: ?number,
	accountingDocument: ?AccountingDocument,
|}

export type DispatchProps = {|
	onBbWordClick: (bbWord: ScanFactoryBbWord, { metaKey: boolean }) => void,
	onPreviewClick: () => void,
	onPreviewShiftClick: () => void,
	onBbWordMouseOver: (bbWord: BbWordCursor) => void,
	onBbWordMouseOut: (bbWord: BbWordCursor) => void,
	onPreviewResize: (size: number) => void,
	loadAccountingDocument: (id: string) => void,
|}

export const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => {
	return {
		metadata: ownProps.extractionDocument.metadata,
		fieldCursor: ownProps.extractionDocument.fieldCursor,
		field: getField(ownProps.extractionDocument.metadata, ownProps.extractionDocument.fieldCursor),
		documentId: ownProps.documentId,
		bbWordFieldMap: getBbWordFieldMap(state),
		highlightBbWords: ownProps.highlightBbWords,
		width: getPreviewWidth(state),
		accountingDocument: getAccountingDocumentRaw(state, ownProps.documentId),
	}
}

const mapDispatchToProps = (
	dispatch: Dispatch<DocumentExtractionAction | AccountingDocumentReduxAction>,
	ownProps: OwnProps,
): DispatchProps => {
	return {
		onBbWordClick: (bbWord: ScanFactoryBbWord, { metaKey = false }: { metaKey: boolean }) => {
			dispatch(assignBbWord(bbWord, metaKey))
		},
		onPreviewClick: () => {
			dispatch(moveFieldCursorNext())
		},
		onPreviewShiftClick: () => {
			dispatch(moveFieldCursorPrev())
		},
		onBbWordMouseOver: (bbWord: BbWordCursor) => {
			ownProps.onBbWordMouseOver(bbWord)
		},
		onBbWordMouseOut: (bbWord: BbWordCursor) => {
			ownProps.onBbWordMouseOut(bbWord)
		},
		onPreviewResize: (size: number) => {
			dispatch(resizePreview(size))
		},
		loadAccountingDocument: (id: string) => {
			dispatch(loadAccountingDocument(id))
		},
	}
}

const mergeProps = (stateProps: StateProps, dispatchProps: DispatchProps): {| ...StateProps, ...DispatchProps |} => {
	return { ...stateProps, ...dispatchProps }
}

type Props = {| ...StateProps, ...DispatchProps |}

class DocumentPreviewContainer extends Component<Props> {
	async componentDidMount() {
		this.processAccountingDocument(this.props)
	}

	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		if (nextProps.documentId !== this.props.documentId) {
			this.processAccountingDocument(nextProps)
		}
	}

	processAccountingDocument(props: Props) {
		if (false === props.metadata.success && !this.props.accountingDocument) {
			this.props.loadAccountingDocument(props.documentId)
		}
	}

	render() {
		const accountingDocumentScans =
			false === this.props.metadata.success && this.props.accountingDocument
				? this.props.accountingDocument.scans
				: null

		return (
			<DocumentPreview
				metadata={this.props.metadata}
				accountingDocumentScans={accountingDocumentScans}
				documentId={this.props.documentId}
				bbWordFieldMap={this.props.bbWordFieldMap}
				fieldCursor={this.props.fieldCursor}
				field={this.props.field}
				highlightBbWords={this.props.highlightBbWords}
				width={this.props.width}
				onBbWordClick={this.props.onBbWordClick}
				onPreviewClick={this.props.onPreviewClick}
				onPreviewShiftClick={this.props.onPreviewShiftClick}
				onBbWordMouseOver={this.props.onBbWordMouseOver}
				onBbWordMouseOut={this.props.onBbWordMouseOut}
				onPreviewResize={this.props.onPreviewResize}
			/>
		)
	}
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(DocumentPreviewContainer)
