//@flow
/** @jsx jsx */

import { Component } from 'react'
import { jsx } from '@emotion/core'
import type { FormValidationProps, WithTranslateProps } from 'wrappers'
import { validate, withTranslate } from 'wrappers'
import Checkbox from 'components/checkbox'
import MultiEmailInput from 'components/multi-email-input'
import EmailTemplateSelector from '../../../common/components/selectors/email-template-selector'
import type { Contact, TemplateScheduler } from 'types'
import { getContactEmails } from '../../helpers'
import { uniq } from 'lodash-es'
import { colors } from 'variables'

type Props = {|
	...WithTranslateProps,
	...FormValidationProps,
	scheduler: ?TemplateScheduler,
	disabled?: boolean,
	contact: ?Contact,
	contactId: ?string,
	sendEmailAutomatically: boolean,
	loadContact: (id: string) => Promise<void>,
	onSendEmailAutomaticallyChange: (sendEmailAutomatically: boolean) => void,
	onChange: (emails: Array<string>, templateId: ?string) => void,
|}

type State = {
	emails: Array<string>,
	allEmails: Array<string>,
	templateId: ?string,
	contactId: ?string,
}

const ALLOWED_TYPES = [2]

class EmailSettings extends Component<Props, State> {
	constructor(props: Props) {
		super(props)
		this.state = {
			allEmails: [],
			contactId: null,
			emails:
				this.props.scheduler && this.props.scheduler.emailRecipients ? uniq(this.props.scheduler.emailRecipients) : [],
			templateId: (this.props.scheduler && this.props.scheduler.emailTemplateId) || null,
		}
	}

	componentDidUpdate(prevProps: Props) {
		const { contactId } = this.props
		const { contactId: oldContactId } = prevProps
		if (contactId !== oldContactId) {
			if (contactId) {
				this.props.loadContact(contactId).then(() => {
					if (this.props.contact) {
						this.setState({
							allEmails: getContactEmails(this.props.contact),
							contactId: contactId,
						})
					}
				})
			}
		}
	}

	componentDidMount() {
		const { contactId } = this.props
		if (!contactId) return
		this.props.loadContact(contactId).then(() => {
			if (this.props.contact && this.state.emails.length === 0) {
				// eslint-disable-next-line react/no-did-mount-set-state
				this.setState({
					allEmails: getContactEmails(this.props.contact),
					contactId: contactId,
				})
			}
		})
	}

	toggleShow = () => void this.props.onSendEmailAutomaticallyChange(!this.props.sendEmailAutomatically)

	handleEmailsChange = (emails: Array<string>) => {
		this.setState({ emails }, this.onChange)
	}

	handleTemplateChange = (templateId: string) => {
		this.setState({ templateId }, this.onChange)
	}

	onChange = () => {
		const emails: Array<string> = [...this.state.emails]
		this.props.onChange(emails, this.state.templateId)
	}

	addEmailFromContact = (event: SyntheticInputEvent<HTMLInputElement>) => {
		const email = (event && event.target && event.target.innerText) || null
		email &&
			this.setState(
				(prevState: State) => ({
					emails: uniq([...prevState.emails, email]),
				}),
				this.onChange,
			)
	}

	renderEmail = () => {
		return (
			<div css={styles.emails}>
				{this.props.t('invoice.settings.scheduler.emailConfiguration.contactEmail')}
				{': '}
				{this.state.allEmails.map((email: ?string, index: number) => (
					<span key={index} css={styles.email} onClick={this.addEmailFromContact}>
						{email}
					</span>
				))}
			</div>
		)
	}

	render() {
		if (this.props.disabled) return null
		return (
			<div>
				<div>
					<Checkbox
						checked={this.props.sendEmailAutomatically}
						onCheck={this.toggleShow}
						label={this.props.t('invoice.settings.scheduler.emailConfiguration.sendEmail')}
						inline
					/>
				</div>
				{this.props.sendEmailAutomatically && (
					<div>
						<div css={styles.input}>
							<MultiEmailInput
								clientError={this.props.validationMessage('emails')}
								value={this.props.validationValue('emails', this.state.emails)}
								onChange={this.handleEmailsChange}
							/>
							{this.renderEmail()}
						</div>
						<div css={styles.input}>
							<EmailTemplateSelector
								onChange={this.handleTemplateChange}
								label={this.props.t('invoice.settings.scheduler.emailConfiguration.template')}
								clientError={this.props.validationMessage('templateId')}
								value={this.props.validationValue('templateId', this.state.templateId)}
								allowedTypes={ALLOWED_TYPES}
								defaultEmailType={2}
								allowCreateTemplate
							/>
						</div>
					</div>
				)}
			</div>
		)
	}
}

const styles = {
	input: {
		marginBottom: 20,
	},
	emails: {
		fontSize: 12,
		marginTop: 8,
		marginBottom: 16,
	},
	email: {
		display: 'inline-block',
		marginRight: 4,
		textDecoration: 'underline',
		cursor: 'pointer',
		'&:hover': {
			color: colors.blue,
		},
	},
}

export default withTranslate(
	validate(['FINISH_SENDING_ACCOUNTING_DOCUMENT_EMAIL', 'TEMPLATE_SCHEDULER_VALIDATION'])(EmailSettings),
)
