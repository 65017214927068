// @flow
import React, { Component } from 'react'
import { Tooltip } from 'components'
import type { AccountingDocumentDirection, Filter } from 'types'
import styles from './documents-direction-tooltip.css'
import { withTranslate, type WithTranslateProps } from 'wrappers'

type Props = {|
	renderValue: ?number,
	organizationId: ?string,
	receivedValue: ?number,
	issuedValue: ?number,
	filter: Filter,
	onFilterChange: (organizationID: string, direction: AccountingDocumentDirection, filter: Filter) => Promise<void>,
	...WithTranslateProps,
|}

class DocumentsDirectionTooltip extends Component<Props> {
	getDirectionHandler = (direction: AccountingDocumentDirection) => () => {
		const { organizationId, onFilterChange, filter } = this.props
		organizationId && onFilterChange(organizationId, direction, filter)
	}

	renderLabel = () => {
		const { receivedValue, issuedValue, t } = this.props
		return (
			<table className={styles.tooltipTable}>
				<tbody>
					<tr>
						<th>
							<div onClick={this.getDirectionHandler('received')} className={styles.link}>
								{t('internal.widget.organizationStatistics.received')}
							</div>
						</th>
						<th>
							<div onClick={this.getDirectionHandler('issued')} className={styles.link}>
								{t('internal.widget.organizationStatistics.issued')}
							</div>
						</th>
					</tr>
					<tr>
						<td>{receivedValue || 0}</td>
						<td>{issuedValue || 0}</td>
					</tr>
				</tbody>
			</table>
		)
	}

	render() {
		const { renderValue } = this.props
		return (
			<Tooltip hoverable inline label={this.renderLabel()}>
				{renderValue}
			</Tooltip>
		)
	}
}

export default withTranslate(DocumentsDirectionTooltip)
