//@flow

export const RecievedInvoicesListType = 24
export const UnpaidRecievedInvoicesListType = 28
export const AdvanceRecievedInvoicesListType = 26
export const IssuedInvoicesListType = 23
export const UnpaidIssuedInvoicesListType = 27
export const AdvanceIssuedInvoicesListType = 25
export const LiabilitiesByDelayType = 10
export const ReceivablesByDelayType = 9
export const ProfitAndLossAccountType = 11
export const ProfitAndLossAccountByMonthsType = 20
export const ProfitAndLossAccountByBranchesType = 12
export const SumOfMainBookType = 13
export const MainBookType = 18
export const DetailedMainBookType = 19
export const SummaryOfMoneyDiaryType = 21
export const SummaryByPeriodsType = 22
export const StatisticalSurveyType = 29
export const PropertyReportType = 4
export const InventoryListOfValuesType = 6
export const InventoryTaxValuesListingType = 7
export const DiaryOfAccountingDocumentWithDetailsType = 8

export const invoicesReportTypes: Array<ReportScheme> = [
	{
		type: RecievedInvoicesListType, //< SeznamFakturPrijatych;
		title: 'invoices',
		name: 'RecievedInvoicesList', //Seznam faktur přijatých
		components: ['START_YEAR', 'START_MONTH', 'END_YEAR', 'END_MONTH'],
	},
	{
		type: UnpaidRecievedInvoicesListType, //< SeznamFakturPrijatychNezaplacenych;
		title: 'invoices',
		name: 'UnpaidRecievedInvoicesList', //Seznam faktur přijatých nezaplacených
		components: ['START_YEAR', 'START_MONTH', 'END_YEAR', 'END_MONTH'],
	},
	{
		type: AdvanceRecievedInvoicesListType, //< SeznamPrijatychZalohovychFaktur;
		title: 'invoices',
		name: 'AdvanceRecievedInvoicesList', //Seznam přijatých zálohových faktur
		components: ['START_YEAR', 'START_MONTH', 'END_YEAR', 'END_MONTH'],
	},
	{
		type: IssuedInvoicesListType, //< SeznamFakturVydanych;
		title: 'invoices',
		name: 'IssuedInvoicesList', //Seznam faktur vydaných
		components: ['START_YEAR', 'START_MONTH', 'END_YEAR', 'END_MONTH'],
	},
	{
		type: UnpaidIssuedInvoicesListType, //< SeznamFakturVydanychNezaplacenych;
		title: 'invoices',
		name: 'UnpaidIssuedInvoicesList', //Seznam faktur vydaných nezaplacených
		components: ['START_YEAR', 'START_MONTH', 'END_YEAR', 'END_MONTH'],
	},
	{
		type: AdvanceIssuedInvoicesListType, //< SeznamVydanychZalohovychFaktur;
		title: 'invoices',
		name: 'AdvanceIssuedInvoicesList', //Seznam vydaných zálohových faktur
		components: ['START_YEAR', 'START_MONTH', 'END_YEAR', 'END_MONTH'],
	},
	{
		type: LiabilitiesByDelayType, //< ZavazkyDleZpozdeni;
		title: 'invoices',
		name: 'LiabilitiesByDelay', //Závazky dle zpoždění
		components: ['END_DATE'],
	},
	{
		type: ReceivablesByDelayType, //< PohledavkyDleZpozdeni;
		title: 'invoices',
		name: 'ReceivablesByDelay', //Pohledávky dle zpoždění
		components: ['END_DATE'],
	},
]

export const accountingReportTypes: Array<ReportScheme> = [
	{
		type: ProfitAndLossAccountType, //< Vysledovka;
		title: 'accounting',
		name: 'ProfitAndLossAccount', //Výsledovka
		components: ['START_YEAR', 'START_MONTH', 'END_YEAR', 'END_MONTH'],
	},
	{
		type: ProfitAndLossAccountByMonthsType, //< VysledovkaPoObdobich;
		title: 'accounting',
		name: 'ProfitAndLossAccountByMonths', //Výsledovka - přehled po obdobích
		components: ['START_YEAR', 'START_MONTH', 'END_YEAR', 'END_MONTH'],
	},
	{
		type: DiaryOfAccountingDocumentWithDetailsType, //< DenikDokladuSRozuctovanim;
		title: 'accounting',
		name: 'DiaryOfAccountingDocumentWithDetails', //DenikDokladuSRozuctovanim
		components: ['START_YEAR', 'START_MONTH', 'END_YEAR', 'END_MONTH'],
	},
	{
		type: ProfitAndLossAccountByBranchesType, //< VysledovkaPoStrediscich;
		title: 'accounting',
		name: 'ProfitAndLossAccountByBranches', //Výsledovka - po střediscích vše
		components: ['START_YEAR', 'START_MONTH', 'END_YEAR', 'END_MONTH'],
	},
	{
		type: SumOfMainBookType, //< ObratovaPredvaha;
		title: 'accounting',
		name: 'SumOfMainBook', //Obratová předvaha
		components: ['START_YEAR', 'START_MONTH', 'END_YEAR', 'END_MONTH'],
	},
	{
		type: MainBookType, //< PohybyVybranehoUctuHlavniKnihy;
		title: 'accounting',
		name: 'MainBook', //Pohyby vybraného účtu hlavní knihy
		components: ['START_YEAR', 'START_MONTH', 'END_YEAR', 'END_MONTH', 'FIN_ACCOUNT'],
	},
	{
		type: DetailedMainBookType, //< DetailniHlavniKniha;
		title: 'accounting',
		name: 'DetailedMainBook', //Detail více účtů hlavní knihy
		components: ['START_YEAR', 'START_MONTH', 'END_YEAR', 'END_MONTH', 'FIN_ACCOUNT'],
	},
]

export const taxRecordsReportTypes: Array<ReportScheme> = [
	{
		type: SummaryOfMoneyDiaryType, //< SouhrnnyPrehledPeneznihoDeniku;
		title: 'taxRecords',
		name: 'SummaryOfMoneyDiary', //Souhrnný přehled peněžního deníku
		components: ['START_YEAR', 'START_MONTH', 'END_YEAR', 'END_MONTH'],
	},
	{
		type: SummaryByPeriodsType, //< SouhrnnyPrehledPoObdobich;
		title: 'taxRecords',
		name: 'SummaryByPeriods', //Souhrnný přehled po obdobích
		components: ['START_YEAR', 'START_MONTH', 'END_YEAR', 'END_MONTH'],
	},
	{
		type: StatisticalSurveyType, //< StatistickyPrehledHospodareni;
		title: 'taxRecords',
		name: 'StatisticalSurvey', //Statistický přehled hospodaření
		components: ['START_YEAR', 'START_MONTH', 'END_YEAR', 'END_MONTH'],
	},
]

export const propertyReportTypes: Array<ReportScheme> = [
	{
		type: PropertyReportType, //< OdpisyMajetku;
		title: 'property',
		name: 'PropertyReport', //Odpisy majetku
		components: ['START_YEAR', 'START_MONTH', 'END_YEAR', 'END_MONTH'],
	},
	{
		type: InventoryListOfValuesType, //< InventurniSoupisRocni;
		title: 'property',
		name: 'InventoryListOfValues', //Inventurní soupis - roční účetní
		components: ['END_YEAR'],
	},
	{
		type: InventoryTaxValuesListingType, //< InventurniSoupisDanovy;
		title: 'property',
		name: 'InventoryTaxValuesListing', //Inventurní soupis - roční daňový
		components: ['END_YEAR'],
	},
]

export const emptyReportTypes: Array<ReportScheme> = []

export type ReportScheme = {
	type: number,
	title: string,
	name: string,
	components: Array<string>,
}

export const getReportSchemes: (reportType: ?string) => Array<ReportScheme> = (reportType: ?string) => {
	switch (reportType) {
		case 'invoices':
			return invoicesReportTypes
		case 'accounting':
			return accountingReportTypes
		case 'taxRecords':
			return taxRecordsReportTypes
		case 'property':
			return propertyReportTypes
		default:
			return emptyReportTypes
	}
}

//EMPTY OBJECTS
export const emptyReportScheme = {
	type: 0,
	title: '',
	name: '',
	components: [],
}
