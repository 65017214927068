/* @flow */

// $FlowFixMe - there is useMemo
import React, { useCallback, useMemo } from 'react'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import type { PagerActions, PagerProps, Template } from 'types'
import DeleteDialog from 'components/delete-dialog'
import type { DataGridProps as DataGridNextProps } from '../../data-grid-next/containers'
import { DataGrid as DataGridNext } from '../../data-grid-next/containers'
import { Column } from '../../data-grid-next/components'
import type { Action as DataGridAction } from '../../data-grid/types'
import Delete from 'components/svg-icons/action/delete'
import Edit from 'components/svg-icons/image/edit'
import CommunicationEmail from 'components/svg-icons/communication/email'
import ContentContentCopy from 'components/svg-icons/content/content-copy'
import Invoice from 'components/svg-icons/trivi/add-menu/invoice'
import TriviLink from '../../common/components/trivi-link'
import Tooltip from 'components/tooltip'
import { formatToDateString } from '../../common/formatters/dateFormatters'
import FullText from 'components/full-text'
import styles from './template-grid-next.css'
import { formatToMoney } from 'utils/formatters'

type Props = {|
	...WithTranslateProps,
	loading: boolean,
	isSomeTemplateCreating: boolean,
	templates: Array<?Template>,
	createDocumentEnabled: boolean,
	removeEnabled: boolean,
	pagerProps: PagerProps,
	pagerActions: PagerActions,
	onCreateTemplate: () => void,
	onCreateDocument: (template: Template) => void,
	onOpenTemplate: (template: Template) => void,
	onDuplicate: (template: Template) => Promise<void>,
	onRemove: (template: Template) => Promise<void>,
|}

const TemplateDataGrid: React$ComponentType<DataGridNextProps<'templates'>> = DataGridNext

const GRID_TYPE = 'templates'
const GRID_ID = 'templates'
const FILTER_ID = 'templates'

function TemplateGridNext(props: Props) {
	const { t, createDocumentEnabled, removeEnabled, onOpenTemplate, onRemove, onDuplicate, onCreateDocument } = props

	const actions: Array<DataGridAction<Template>> = useMemo(() => {
		return [
			{
				name: t('templates.grid.actions.createAccDoc'),
				type: 'create_document',
				icon: <Invoice />,
				primary: true,
			},
			{
				name: t('templates.grid.actions.editTemplate'),
				type: 'edit_template',
				icon: <Edit />,
				primary: true,
			},
			{
				name: t('templates.grid.actions.duplicateTemplate'),
				type: 'duplicate_template',
				icon: <ContentContentCopy />,
				primary: true,
			},
			{
				name: t('templates.grid.actions.deleteTemplate'),
				type: 'delete_template',
				icon: <Delete />,
				primary: true,
			},
		]
	}, [t])

	const onAction = useCallback(
		async (action: string, template: Template) => {
			switch (action) {
				case 'edit_template':
					template && onOpenTemplate && onOpenTemplate(template)

					break
				case 'delete_template':
					if (removeEnabled) {
						DeleteDialog()
							.then(() => {
								template && onRemove && onRemove(template)
							})
							.catch(() => {})
					}
					break
				case 'duplicate_template':
					template && onDuplicate && onDuplicate(template)
					break
				case 'create_document':
					if (createDocumentEnabled) {
						template && onCreateDocument && onCreateDocument(template)
					}
					break
			}
		},
		[onOpenTemplate, onRemove, onDuplicate, onCreateDocument, createDocumentEnabled, removeEnabled],
	)

	const renderTemplateName = useCallback(
		(value: string, row: Template) => {
			if (!row.name) {
				return null
			}
			return (
				<div style={style.nameColumn}>
					<TriviLink onClick={() => onOpenTemplate(row)}>
						<FullText text={row.name} />
					</TriviLink>
				</div>
			)
		},
		[onOpenTemplate],
	)

	const renderTemplateTotalAmount = useCallback((value: string, row: Template) => {
		if (!row.content) {
			return null
		}

		const formattedAmount = formatToMoney(row.content.total, {
			currency: row.content.currency,
		})
		const spacedFormattedAmount = formattedAmount.replace(/\u00a0/g, ' ')

		return <FullText text={spacedFormattedAmount} />
	}, [])

	const renderTemplateType = useCallback(
		(value: number) => {
			if (value === 1) {
				return <FullText text={t('accountingDocument.types.Advance')} />
			} else {
				return <FullText text={t('accountingDocument.types.Invoice')} />
			}
		},
		[t],
	)

	const getSchedulerFrequencyIntervals = useCallback(
		(frequency?: string) => {
			const schedulerFrequencyIntervals = []

			const frequencyNo = frequency && parseInt(frequency)

			schedulerFrequencyIntervals[1] = t('invoice.settings.scheduler.frequency.daily')
			schedulerFrequencyIntervals[2] = t('invoice.settings.scheduler.frequency.weekly')
			schedulerFrequencyIntervals[3] = t('invoice.settings.scheduler.frequency.monthly')
			schedulerFrequencyIntervals[4] = t('invoice.settings.scheduler.frequency.yearly')

			return !frequencyNo ? t('invoice.settings.scheduler.frequency.none') : schedulerFrequencyIntervals[frequencyNo]
		},
		[t],
	)

	const renderSchedulerFrequency = useCallback(
		(value: string) => {
			return <FullText text={getSchedulerFrequencyIntervals(value)} />
		},
		[getSchedulerFrequencyIntervals],
	)

	const renderSchedulerTime = useCallback(
		(value: string, row: Template) => {
			if (!row.scheduler) {
				return <FullText text={t('invoice.settings.scheduler.frequency.none')} />
			}

			if (!row.scheduler.end && !row.scheduler.generateMaxCount) {
				return <FullText text={t('invoice.settings.scheduler.repeating.infinity')} />
			}

			if (row.scheduler.generateMaxCount) {
				return (
					<FullText text={`${row.scheduler.generateMaxCount} ${t('invoice.settings.scheduler.repeating.count')}`} />
				)
			}

			if (row.scheduler.end) {
				const endDate = formatToDateString(row.scheduler.end)
				return <FullText text={`${t('invoice.settings.scheduler.repeating.toDate')} ${endDate}`} />
			}

			return <FullText text={getSchedulerFrequencyIntervals(value)} />
		},
		[getSchedulerFrequencyIntervals, t],
	)

	const renderAccDocWorkflow = useCallback(
		(value: string, row: Template) => {
			if (!row.scheduler) {
				return null
			}

			if (!row.scheduler.useWorkflow) {
				return <div>{t('templates.scheduler.accDocWorkflow.createConcept')}</div>
			} else {
				return <div>{t('templates.scheduler.accDocWorkflow.moveToProcessing')}</div>
			}
		},
		[t],
	)

	const renderEmailRecipients = useCallback((recipients: Array<string>) => {
		if (recipients && recipients.length) {
			return (
				<span>
					<Tooltip
						inline
						label={recipients.map((email: string, index: number) => {
							return (
								<span key={index}>
									{email}
									<br />
								</span>
							)
						})}
					>
						<CommunicationEmail />
					</Tooltip>
				</span>
			)
		} else return null
	}, [])

	return (
		<TemplateDataGrid id={GRID_ID} dataType={GRID_TYPE} filterId={FILTER_ID} actions={actions} onAction={onAction}>
			<Column
				title={t('templates.grid.columns.name')}
				columnId="name"
				key="name"
				render={renderTemplateName}
				sortable
				width="255px"
				defaultSortDirection="ASC"
			/>
			<Column
				title={t('templates.grid.columns.total')}
				columnId="content.total"
				key="content.total"
				width="110px"
				sortable
				render={renderTemplateTotalAmount}
			/>
			<Column
				title={t('templates.grid.columns.type')}
				columnId="content.type"
				key="content.type"
				render={renderTemplateType}
				sortable
				width="110px"
			/>
			<Column
				title={t('templates.grid.columns.schedulerFrequency')}
				columnId="scheduler.frequency"
				key="scheduler.frequency"
				render={renderSchedulerFrequency}
				sortable
				width="165px"
			/>
			<Column
				title={t('templates.grid.columns.schedulerTime')}
				columnId="scheduler.end"
				key="scheduler.end"
				render={renderSchedulerTime}
				width="140px"
			/>
			<Column
				title={t('templates.grid.columns.accDocWorkflow')}
				columnId="scheduler.useWorkflow"
				key="scheduler.useWorkflow"
				render={renderAccDocWorkflow}
				width="150px"
				sortable
			/>
			<Column
				title={t('templates.grid.columns.sendByEmail')}
				width="60px"
				className={styles.center}
				columnId="scheduler.emailRecipients"
				key="scheduler.emailRecipients"
				render={renderEmailRecipients}
				sortable
			/>
		</TemplateDataGrid>
	)
}

export default withTranslate(TemplateGridNext)

const style = {
	nameColumn: {
		marginRight: '15px',
	},
}
