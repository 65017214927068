/* @flow */

import { connect } from 'react-redux'
import type { OrganizationReportVatPayment, State } from 'types'
import CurrentTurnover from '../../components/current-turnover/current-turnover'
import { getCurrentOrganizationDomesticCurrency, isCurrentOrganizationTaxPayer } from 'modules/organization/selectors'
import { withAutoload } from 'wrappers'
import { type DashboardAction, type Dispatch } from 'types'
import { loadOrganizationReport } from '../../actions'
import { getOrganizationReportVatPayment } from '../../selectors/current-turnover'

type StateProps = {|
	vatPayment: ?OrganizationReportVatPayment,
	currency: ?string,
	taxPayer: boolean,
|}

const mapStateToProps = (state: State): StateProps => {
	return {
		vatPayment: getOrganizationReportVatPayment(state),
		currency: getCurrentOrganizationDomesticCurrency(state),
		taxPayer: isCurrentOrganizationTaxPayer(state),
	}
}

type DispatchProps = {|
	autoload: () => void,
|}

const mapDispatchToProps = (dispatch: Dispatch<DashboardAction>): DispatchProps => {
	return {
		autoload: () => {
			dispatch(loadOrganizationReport())
		},
	}
}

const mergeProps = (stateProps: StateProps, dispatchProps: DispatchProps): {| ...StateProps, ...DispatchProps |} => {
	return {
		...stateProps,
		...dispatchProps,
		autoload: stateProps.taxPayer ? () => {} : dispatchProps.autoload,
	}
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(withAutoload(CurrentTurnover))
