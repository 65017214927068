/* @flow */
/** @jsx jsx */

import { PureComponent } from 'react'
import { jsx } from '@emotion/core'
import memoize from 'memoize-one'
import { colors } from 'variables'

type Props = {|
	left: any,
	center: any,
	right: any,
	thick: boolean,
	className?: string,
	style?: Object,
|}

export default class Separator extends PureComponent<Props> {
	getStyles = memoize((style?: Object) => {
		return {
			root: {
				flex: 1,
				flexDirection: 'row',
				display: 'flex',
				alignItems: 'center',
				whiteSpace: 'nowrap',
				position: 'relative',
				marginBottom: '10px',
				...style,
			},
			line: {
				flex: 1,
				height: 1,
				borderBottom: `1px solid ${colors.grey400}`,
			},
			thickLine: {
				flex: 1,
				height: 1,
				borderBottom: `2px solid ${colors.grey300}`,
			},
		}
	})

	render() {
		const style = this.getStyles(this.props.style)

		return (
			<div className={this.props.className} style={style.root}>
				<div>{this.props.left}</div>
				<div css={this.props.thick ? style.thickLine : style.line} />
				<div>{this.props.center}</div>
				<div css={this.props.thick ? style.thickLine : style.line} />
				<div>{this.props.right}</div>
			</div>
		)
	}
}
