/* @flow */

import { connect } from 'react-redux'
import type { ChildrenArray } from 'react'
import type { State, Dispatch, Filter, SearchFilter, DataGridReduxAction } from 'types'
import type { FilterElement } from '../types'
import { changeFilter, clearGridFilterField } from '../actions'
import DataGridFilter from '../components/data-grid-filter'

export { default as FilterCurrency } from './filter-currency'

export type Props = {|
	name: string,
	defaultFilter?: Filter,
	filterTransformFn?: {
		fromFilterItem: SearchFilter => Filter,
		toFilterItem: (Filter, string) => ?SearchFilter,
	},
	children: ChildrenArray<FilterElement | null>,
	className?: string,
|}

type StateProps = {|
	filter?: Filter,
|}

const mapStateToProps = (state: State, ownProps: Props): StateProps => {
	return {
		filter: state.grid.filter[ownProps.name],
	}
}

type DispatchProps = {|
	onChange?: (name: string, filter: Filter) => void,
	clearFilterField: (filterName: string, fieldName: string) => void,
|}

const mapDispatchToProps = (dispatch: Dispatch<DataGridReduxAction>): DispatchProps => {
	return {
		onChange: (name: string, filter: Filter): void => {
			dispatch(changeFilter(name, filter))
		},
		clearFilterField: (filterName: string, fieldName: string) => {
			dispatch(clearGridFilterField(filterName, fieldName))
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(DataGridFilter)
