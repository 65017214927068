/* @flow */
import { colors, depths } from 'variables'

const styles = {
	overlay: {
		position: 'fixed',
		height: '100%',
		width: '100%',
		top: 0,
		willChange: 'opacity',
		transform: 'translateZ(0)',
		transition: 'left 0ms cubic-bezier(0.23, 1, 0.32, 1) 400ms, opacity 400ms cubic-bezier(0.23, 1, 0.32, 1)',
		zIndex: depths.dialogOverlay,
		backdropFilter: 'blur(9px)',
		WebkitBackdropFilter: 'blur(9px)',
		pointerEvents: 'none',
	},
	closed: {
		left: '-100%',
		opacity: 0,
	},
	opened: {
		left: 0,
		opacity: 1,
	},
	transparent: {
		background: colors.grey200Faded90,
	},
	opaque: {
		background: colors.gray200Faded97,
	},
}

export default styles
