/* @flow */

import { fetchNextAccountingDocumentToExtract } from 'modules/accounting-document/actions'
import { setNotification } from 'modules/common/actions'
import {
	accountingDocumentExtractingTemplate,
	scanFactoryAccountingDocumentMetadata,
	scanFactoryAccountingDocumentStats,
} from 'modules/common/models/api-model'
import { push } from 'react-router-redux'
import { beginTask, endTask } from 'utils/loader'
import type {
	AccountingDocumentReduxAction,
	CommonAction,
	Dispatch,
	DocumentExtractionAction,
	ScanFactoryBbWord,
	ScanFactoryDocumentFileMetadata,
	State,
} from 'types'
import storage from 'utils/local-storage'
import { metadataToAccountingDocument } from '../domain/accounting-document'
import runAutoFill from '../domain/autofill-run'
import { resolveValue } from '../domain/bbword'
import { getField } from '../domain/field'
import { isFieldSectionRepeatable } from '../domain/section'
import validate, { validateInput } from '../domain/validator'
import { extractDashboardAccountingDocumentRoute } from 'modules/accounting-document/routing/routes'
import { getExtractionErrors, getExtractionStats, getFieldCursor, getMetadata, hasExtractionErrors } from '../selectors'
import type { ExtractionStatParameters, FieldCursor } from '../types'

const storagePreviewWidthKey = 'documentExtraction:previewWidth'

export function initDocumentExtraction() {
	return {
		type: 'INIT_DOCUMENT_EXTRACTION',
		previewWidth: storage.get(storagePreviewWidthKey),
		extractionStatsData: {
			navigator: {
				applicationCodeName: navigator.appCodeName,
				applicationName: navigator.appName,
				applicationVersion: navigator.appVersion,
				platform: navigator.platform,
				userAgent: navigator.userAgent,
			},
			client: {
				screenWidth: window.screen.width,
				screenHeight: window.screen.height,
				windowWidth: window.innerWidth,
				windowHeight: window.innerHeight,
			},
		},
	}
}

export function loadDocumentMetadata(documentType: string, documentId: string, organizationId: string) {
	return async function(dispatch: Dispatch<DocumentExtractionAction>) {
		dispatch({ type: 'START_LOAD_DOCUMENT_METADATA', documentType, documentId })
		try {
			const response = await scanFactoryAccountingDocumentMetadata.get(
				{
					accountingDocumentId: documentId,
					organizationId,
				},
				{ timeout: 1000 * 60 },
			)
			const documentMetadata = (response.documentFiles && response.documentFiles[0]) || null
			if (!(documentMetadata && validateInput(documentMetadata))) {
				throw { message: 'documentExtraction.metadataValidationError' }
			}

			dispatch(addStatsEvent('LOAD_DOCUMENT_METADATA', {}))
			dispatch({ type: 'FINISH_LOAD_DOCUMENT_METADATA', documentType, documentId, documentMetadata })
		} catch (e) {
			dispatch({ type: 'FINISH_LOAD_DOCUMENT_METADATA', documentType, documentId, serverError: e })
		}
	}
}

export function saveAccountingDocument(documentId: string) {
	return async function(
		dispatch: Dispatch<DocumentExtractionAction | AccountingDocumentReduxAction | CommonAction>,
		getState: () => State,
	) {
		beginTask('document-extraction-save')
		const metadata = getMetadata(getState())
		if (!metadata) {
			return
		}

		dispatch(validateDocumentMetadata(metadata))
		const state = getState()
		if (hasExtractionErrors(state)) {
			dispatch(setNotification({ type: 'error', message: 'documentExtraction.metadataValidationError' }, 2))
			dispatch(
				addStatsEvent('SAVE_ACCOUNTING_DOCUMENT', { saved: false, validationErrors: getExtractionErrors(state) }),
			)
			return
		}

		const accountingDocument = metadataToAccountingDocument(metadata, documentId)

		dispatch({ type: 'START_SAVE_ACCOUNTING_DOCUMENT', documentId })
		try {
			await accountingDocumentExtractingTemplate.put(
				{ accountingDocumentId: documentId },
				{ extractingTemplate: JSON.stringify(accountingDocument) },
			)
			dispatch({ type: 'FINISH_SAVE_ACCOUNTING_DOCUMENT', documentId })
			dispatch(addStatsEvent('SAVE_ACCOUNTING_DOCUMENT', { saved: true }))

			await dispatch(saveStats(documentId))
		} catch (e) {
			dispatch({ type: 'FINISH_SAVE_ACCOUNTING_DOCUMENT', documentId, serverError: e })
			dispatch(addStatsEvent('SAVE_ACCOUNTING_DOCUMENT', { saved: false, serverError: e }))
			await dispatch(saveStats(documentId))
		} finally {
			endTask('document-extraction-save')
		}
	}
}

export function validateDocumentMetadata(metadata: ScanFactoryDocumentFileMetadata) {
	return {
		type: 'VALIDATE_DOCUMENT_METADATA',
		errors: validate(metadata),
	}
}

export function nextDocumentExtraction() {
	return async function(dispatch: Dispatch<AccountingDocumentReduxAction | DocumentExtractionAction>) {
		dispatch({ type: 'START_NEXT_DOCUMENT_EXTRACTION' })

		const resp = await dispatch(fetchNextAccountingDocumentToExtract())
		if (resp && resp.result) {
			const { accountingDocumentId, organizationId } = resp.result
			dispatch(push(extractDashboardAccountingDocumentRoute(accountingDocumentId, organizationId)))
		}

		dispatch({ type: 'FINISH_NEXT_DOCUMENT_EXTRACTION' })
	}
}

export function assignBbWord(bbWord: ScanFactoryBbWord, append: boolean = false) {
	return async function(dispatch: Dispatch<DocumentExtractionAction>, getState: () => State) {
		const state = getState()
		const fieldCursor = getFieldCursor(state)
		const metadata = getMetadata(state)

		dispatch({
			type: 'ASSIGN_BB_WORD',
			bbWord,
			append,
		})

		if (fieldCursor && metadata) {
			await runAutoFill(dispatch, getState, fieldCursor)

			dispatch(
				addStatsEvent('ASSIGN_BB_WORD', {
					bbWord: resolveValue(bbWord, getField(metadata, fieldCursor)),
					fieldCursor,
					append,
				}),
			)
		}

		const updatedMetadata = getMetadata(getState())
		if (updatedMetadata) {
			dispatch(validateDocumentMetadata(updatedMetadata))
		}
	}
}

export function selectField(fieldCursor: FieldCursor) {
	return function(dispatch: Dispatch<DocumentExtractionAction>) {
		dispatch(addStatsEvent('SELECT_FIELD', { fieldCursor }))

		return dispatch({
			type: 'SELECT_FIELD',
			fieldCursor,
		})
	}
}

export function resetField(fieldCursor?: ?FieldCursor, hotkey?: string) {
	return function(dispatch: Dispatch<DocumentExtractionAction>, getState: () => State) {
		if (!fieldCursor) fieldCursor = getFieldCursor(getState())
		if (!fieldCursor) return

		dispatch(addStatsEvent('RESET_FIELD', { fieldCursor, hotkey }))
		dispatch({
			type: 'RESET_FIELD',
			fieldCursor,
		})

		const metadata = getMetadata(getState())
		if (metadata) {
			dispatch(validateDocumentMetadata(metadata))
		}
	}
}

export function editField(fieldCursor?: ?FieldCursor, hotkey?: string) {
	return function(dispatch: Dispatch<DocumentExtractionAction>, getState: () => State) {
		if (!fieldCursor) fieldCursor = getFieldCursor(getState())
		if (!fieldCursor) return

		dispatch({
			type: 'EDIT_FIELD',
			fieldCursor,
		})

		dispatch(addStatsEvent('START_EDIT_FIELD', { fieldCursor, hotkey }))
	}
}

export function finishEditField(
	fieldCursor?: ?FieldCursor,
	value?: string,
	hotkey?: string,
	autofill?: boolean = false,
) {
	return function(dispatch: Dispatch<DocumentExtractionAction>, getState: () => State) {
		if (!fieldCursor) fieldCursor = getFieldCursor(getState())
		if (!fieldCursor) return

		dispatch({
			type: 'FINISH_EDIT_FIELD',
			fieldCursor,
			value,
		})

		if (value) {
			runAutoFill(dispatch, getState, fieldCursor)
		}
		dispatch(addStatsEvent('FINISH_EDIT_FIELD', { fieldCursor, value, hotkey, autofill }))

		const metadata = getMetadata(getState())
		if (metadata) {
			dispatch(validateDocumentMetadata(metadata))
		}
	}
}

export function moveFieldCursorNext(hotkey?: string) {
	return function(dispatch: Dispatch<DocumentExtractionAction>) {
		dispatch(addStatsEvent('MOVE_FIELD_CURSOR_NEXT', { hotkey }))
		return dispatch({
			type: 'MOVE_FIELD_CURSOR_NEXT',
		})
	}
}

export function moveFieldCursorPrev(hotkey?: string) {
	return function(dispatch: Dispatch<DocumentExtractionAction>) {
		dispatch(addStatsEvent('MOVE_FIELD_CURSOR_PREV', { hotkey }))
		return dispatch({
			type: 'MOVE_FIELD_CURSOR_PREV',
		})
	}
}

export function addRepeatableSection(section?: string, hotkey?: string) {
	return async function(dispatch: Dispatch<DocumentExtractionAction>, getState: () => State) {
		if (!section) {
			const fieldCursor = getFieldCursor(getState())
			if (fieldCursor && isFieldSectionRepeatable(fieldCursor.section)) {
				section = fieldCursor.section
			} else {
				return
			}
		}

		dispatch(addStatsEvent('ADD_REPEATABLE_SECTION', { section, hotkey }))
		return dispatch({
			type: 'ADD_REPEATABLE_SECTION',
			section,
		})
	}
}

export function removeRepeatableSection(section?: string, index?: number, hotkey?: string) {
	return async function(dispatch: Dispatch<DocumentExtractionAction>, getState: () => State) {
		if (!section || !index) {
			const fieldCursor = getFieldCursor(getState())
			if (fieldCursor && isFieldSectionRepeatable(fieldCursor.section)) {
				section = fieldCursor.section
				index = fieldCursor.index
			} else {
				return
			}
		}

		dispatch(addStatsEvent('REMOVE_REPEATABLE_SECTION', { section, index, hotkey }))
		dispatch({
			type: 'REMOVE_REPEATABLE_SECTION',
			section,
			index,
		})

		const metadata = getMetadata(getState())
		if (metadata) {
			dispatch(validateDocumentMetadata(metadata))
		}
	}
}

export function resizePreview(size: number) {
	return async function(dispatch: Dispatch<DocumentExtractionAction>) {
		storage.set(storagePreviewWidthKey, size)

		dispatch(addStatsEvent('RESIZE_PREVIEW', { size }))
		return dispatch({
			type: 'RESIZE_PREVIEW',
			size,
		})
	}
}

export function addStatsEvent(name: string, parameters: ExtractionStatParameters) {
	return {
		type: 'ADD_STATS_EVENT',
		name,
		parameters,
		timestamp: new Date().toISOString(),
	}
}

export function saveStats(documentId: string) {
	return async function(dispatch: Dispatch<DocumentExtractionAction>, getState: () => State) {
		const stats = getExtractionStats(getState())
		if (stats) {
			dispatch({
				type: 'START_SAVE_STATS',
			})

			try {
				await scanFactoryAccountingDocumentStats.post({ accountingDocumentId: documentId }, stats)
				dispatch({
					type: 'FINISH_SAVE_STATS',
				})
			} catch (e) {
				dispatch({
					type: 'FINISH_SAVE_STATS',
					serverError: e,
				})
			}
		}
	}
}
