// @flow

/** @jsx jsx */
import { jsx } from '@emotion/core'
import React, { Component } from 'react'
import withTranslate, { type Props as WithTranslateProps } from 'wrappers/with-translate'
import type { Categories as ColumnChartCategories, Data as ColumnChartData } from 'components/charts/column-chart'
import ColumnChart, { type Props as ColumnChartProps } from 'components/charts/column-chart'
import type { BaseChartProps } from 'components/charts/highcharts/highchart'
import { pickDefaultOptions } from 'components/charts/utils'
import IntervalSelector, { type Interval } from 'components/date-picker/interval-selector'
import TabSwitch from 'components/TabSwitches/TabSwitch'
import TabSwitches from 'components/TabSwitches/TabSwitches'
import Loader from 'components/loader'
import { findIndex } from 'lodash-es'
import type { BillingSummaryFilter, TimeGranularity } from '../../types'
import { formatToMoney } from 'utils/formatters'
import { colors } from 'variables'
import type { Currency } from '../../../common/models/swagger-model'
import CurrencySelectorOld from '../../../common/containers/currency-selector-old'
import Tooltip from 'components/tooltip'
import ActionInfo from 'components/svg-icons/action/info'

type Props = {|
	data: ?ColumnChartData,
	categories: ?ColumnChartCategories,
	filter: BillingSummaryFilter,
	onFilterChange: (filter: BillingSummaryFilter) => void,
	currentYear: number,
	domesticCurrency: ?string,
	loading: boolean,
	currency: ?string,
	onCurrencyChange: (currency: ?Currency) => void,
	...BaseChartProps,
	...WithTranslateProps,
|}

const styles = {
	label: {
		display: 'flex',
		alignItems: 'flex-end',
	},
}

class BillingSummary extends Component<Props> {
	render() {
		return (
			<div>
				{this.renderControl()}
				{this.renderChart()}
			</div>
		)
	}

	renderChart() {
		const currencySymbol = this.props.filter.currency || this.props.domesticCurrency || ''
		return (
			<ColumnChart
				data={this.props.data || { columns: [] }}
				categories={this.props.categories || []}
				columnLabels={false}
				columnStacking={'normal'}
				showLegend={false}
				tooltipValueFormat={'{point.y:,.2f} ' + currencySymbol}
				tooltipFormatter={formatTooltip(this.props.t, currencySymbol)}
				yAxisValueFormat={'{value:.:,.0f} ' + currencySymbol}
				configureEmptyData={this.configureEmptyData}
				{...pickDefaultOptions({ ...this.props, backgroundColor: colors.gray100 })}
			/>
		)
	}

	renderInfoLabel() {
		const t = this.props.t
		return (
			<div>
				<p css={{ marginBottom: '3px' }}>{t('dashboard.billingSummary.tooltipHint.section1.headline')}</p>
				<ul css={{ marginLeft: '20px' }}>
					<li>{t('dashboard.billingSummary.tooltipHint.section1.description1')}</li>
					<li>{t('dashboard.billingSummary.tooltipHint.section1.description2')}</li>
					<li>{t('dashboard.billingSummary.tooltipHint.section1.description3')}</li>
				</ul>

				<p css={{ marginBottom: '3px' }}>{t('dashboard.billingSummary.tooltipHint.section2.headline')}</p>
				<ul css={{ marginLeft: '20px' }}>
					<li>{t('dashboard.billingSummary.tooltipHint.section2.description1')}</li>
					<li>{t('dashboard.billingSummary.tooltipHint.section2.description2')}</li>
					<li>{t('dashboard.billingSummary.tooltipHint.section2.description3')}</li>
				</ul>

				<p css={{ marginBottom: '3px' }}>{t('dashboard.billingSummary.tooltipHint.section3.headline')}</p>
				<ul css={{ marginLeft: '20px' }}>
					<li>{t('dashboard.billingSummary.tooltipHint.section3.description1')}</li>
					<li>{t('dashboard.billingSummary.tooltipHint.section3.description2')}</li>
				</ul>
			</div>
		)
	}

	renderControl() {
		const filterIntervals = getIntervals(this.props.filter.timeGranularity)

		return (
			<>
				<div style={{ display: 'flex', alignItems: 'start', marginBottom: 10 }}>
					<p css={style.title}>{this.props.t('dashboard.billingSummary.title')}</p>
					<span style={style.titleHint}>
						<Tooltip inline uncentered label={this.renderInfoLabel()} multiLine>
							<ActionInfo hoverColor={colors.black} size={16} style={{ verticalAlign: 'text-top' }} />
						</Tooltip>
					</span>
				</div>
				<div style={{ display: 'flex', alignItems: 'start' }}>
					<div style={{ flex: '1 1 0%', alignSelf: 'flex-start' }}>
						<IntervalSelector
							intervals={filterIntervals}
							showLabel={false}
							value={findIndex(filterIntervals, this.props.filter.interval)}
							onChange={this.onIntervalChange}
						/>
					</div>
					<div style={{ flex: '1 1 100%', alignSelf: 'flex-start', marginLeft: 10 }}>
						<div css={styles.label}>
							<CurrencySelectorOld
								allowEmpty
								emptyText={this.props.t('dashboard.userDashboard.convertToDomestic')}
								onChange={this.props.onCurrencyChange}
								value={this.props.currency}
								onlyUsedInOrg
								inline
							/>
						</div>
					</div>
					<div style={{ flex: '1 1 auto', alignSelf: 'flex-end' }}>
						<TabSwitches
							autoTestId="dashboard-billing-tabs"
							onChange={this.onTimeGranularityChange}
							value={this.props.filter.timeGranularity}
						>
							<TabSwitch label={this.props.t('application.monthly')} value={3} />
							<TabSwitch label={this.props.t('application.quarterly')} value={4} />
							<TabSwitch label={this.props.t('application.yearly')} value={5} />
						</TabSwitches>
					</div>
				</div>
			</>
		)
	}

	onIntervalChange = (event: Event, intervalIndex: number, { interval }: { interval: Interval }) => {
		this.onFilterChange({ interval })
	}

	onTimeGranularityChange = (timeGranularity: TimeGranularity) => {
		this.onFilterChange({ timeGranularity, interval: getIntervals(timeGranularity)[0] })
	}

	onFilterChange(filter: $Shape<BillingSummaryFilter>) {
		this.props.onFilterChange({ ...this.props.filter, ...filter })
	}

	configureEmptyData = (props: ColumnChartProps) => {
		const count = (props.data.columns[0] && props.data.columns[0].values.length) || 0
		return {
			...props,
			overlayContent: this.renderEmptyDataOverlay(),
			disabled: true,
			yAxisPadding: 2,
			data: {
				columns: [
					{ ...props.data.columns[0], values: new Array(count).fill(2000) },
					{ ...props.data.columns[1], values: new Array(count).fill(2000) },
					{ ...props.data.columns[2], values: new Array(count).fill(-2000) },
					{ ...props.data.columns[3], values: new Array(count).fill(-2000) },
				],
			},
		}
	}

	renderEmptyDataOverlay() {
		if (this.props.loading) return <Loader transparent visible />

		const style = {
			color: colors.blue,
			textAlign: 'center',
			marginTop: '4Opx',
			padding: 0,
			fontSize: '25px',
			fontFamily: 'Caveat',
		}
		return <p style={style}>{this.props.t('dashboard.billingSummary.emptyDataInfo')}</p>
	}
}

export default withTranslate(BillingSummary)

function formatTooltip(t: (s: string) => string, currency: ?string) {
	return function(): string {
		const issuedVat = getPointData(this.points[0])
		const issuedExcludingVat = getPointData(this.points[1])
		const receivedExcludingVat = getPointData(this.points[3])
		const receivedVat = getPointData(this.points[2])
		const totalVAT = getVatLessDifference(
			this.points[0],
			this.points[2],
			t('dashboard.billingSummary.differenceWithVAT'),
		)
		const totalVATless = getVatLessDifference(
			this.points[1],
			this.points[3],
			t('dashboard.billingSummary.differenceWithoutVAT'),
		)
		const format = (amount: number) => formatToMoney(amount, { currency })
		const totalVATColor = totalVAT.value < 0 ? colors.red500 : colors.green500
		const totalVATLessColor = totalVATless.value < 0 ? colors.red500 : colors.green500

		return `
			<table style="border-collapse: collapse">
				<tr><td> </td><td style="padding: 4px; font-size: 1.1em;" colspan="2"><strong>${this.x}</strong></td></tr>
				<tr style="font-weight: bold">
					<td style="padding: 4px; color: ${issuedExcludingVat.color}">▌</td>
					<td style="padding: 4px;">${t('dashboard.billingSummary.issuedExcludingVat')}</td>
					<td style="padding: 4px; text-align: right">${format(issuedExcludingVat.value)}</td>
				</tr>
				<tr>
					<td style="padding: 4px; color: ${issuedVat.color}">▌</td>
					<td style="padding: 4px;">${t('dashboard.billingSummary.vat')}</td>
					<td style="padding: 4px; text-align: right">${format(issuedVat.value)}</td>
				</tr>
				<tr>
					<td colspan="3" style="padding: 4px;"> </td>
				</tr>
				<tr style="font-weight: bold">
					<td style="padding: 4px; color: ${receivedExcludingVat.color}">▌</td>
					<td style="padding: 4px;">${t('dashboard.billingSummary.receivedExcludingVat')}</td>
					<td style="padding: 4px; text-align: right">${format(receivedExcludingVat.value)}</td>
				</tr>
				<tr>
					<td style="padding: 4px; color: ${receivedVat.color}">▌</td>
					<td style="padding: 4px;">${t('dashboard.billingSummary.vat')}</td>
					<td style="padding: 4px; text-align: right">${format(receivedVat.value)}</td>
				</tr>
				<tr>
					<td colspan="3">
						<hr style="border: none; height: 1px; background-color: ${colors.grey300}; color: ${colors.grey300}"/>
					</td>
				</tr>
				<tr style="font-weight: bold">
					<td style="padding: 4px; color: ${totalVATless.color}">▌</td>
					<td style="padding: 4px;">${t('dashboard.billingSummary.differenceWithoutVAT')}</td>
					<td style="padding: 4px; text-align: right; color: ${totalVATLessColor}">${format(totalVATless.value)}</td>
				</tr>
				<tr style="font-weight: bold">
					<td style="padding: 4px; color: ${totalVAT.color}; opacity: 0.5">▌</td>
					<td style="padding: 4px;">${t('dashboard.billingSummary.differenceWithVAT')}</td>
					<td style="padding: 4px; text-align: right; color: ${totalVATColor}">${format(totalVAT.value)}</td>
				</tr>
			</table>
	`
	}
}

function getPointData(point: any) {
	return {
		value: point.y,
		name: point.series.name,
		color: point.color,
	}
}

function getVatLessDifference(point1: any, point2: any, name: string) {
	const difference = point1.y - -point2.y

	return {
		value: difference,
		name,
		color: '#08B0CD',
	}
}

function getIntervals(timeGranularity: number) {
	switch (timeGranularity) {
		case 3: // month
			return [
				{ interval: 'year', count: 1, shift: 0 },
				{ interval: 'month', count: 6, shift: -1, initialOffset: 1 },
				{ interval: 'month', count: 12, shift: -1, initialOffset: 1 },
				{ interval: 'month', count: 24, shift: -1, initialOffset: 1 },
			]
		case 4: // quarter
			return [
				{ interval: 'year', count: 1, shift: 0 },
				{ interval: 'quarter', count: 8, shift: -1, initialOffset: 1 },
				{ interval: 'year', count: 5, shift: -1, initialOffset: 1 },
			]
		default:
			// year
			return [
				{ interval: 'year', count: 2, shift: -1, initialOffset: 1 },
				{ interval: 'year', count: 5, shift: -1, initialOffset: 1 },
			]
	}
}

const style = {
	title: {
		fontSize: '1.2em',
		fontWeight: 'bold',
		padding: 0,
		margin: 0,
		marginRight: 10,
	},
	titleHint: {
		marginTop: 2,
	},
}
