//@flow

import React, { Component } from 'react'
import type { OrganizationProcessIssue } from 'types'
import { withNotify, type WithNotifyProps, withTranslate, type WithTranslateProps } from 'wrappers'
import { getServerErrorMessage } from 'helpers'
import Button from 'components/button'
import Snackbar from 'components/snackbar'
import EsoIcon from 'components/eso-icon'

const ESO_NOT_CONNECTED_STATES: Array<number> = [
	1, // Not Connected
	4, // Disconnected
	5, // Connection Error
]

type ComponentProps = WithTranslateProps &
	WithNotifyProps & {|
		currentOrganizationId: ?string,
		processingIssues: ?Array<OrganizationProcessIssue>,
		connectionState: ?number,
		processOrganization: (organizationId: string) => Promise<void>,
		loadProcessingIssues: () => void,
	|}

class EsoComponent extends Component<ComponentProps> {
	componentDidMount() {
		this.props.loadProcessingIssues()
	}

	onProcessClick = async () => {
		const { t, currentOrganizationId } = this.props
		if (!currentOrganizationId) {
			return
		}

		try {
			await this.props.processOrganization(currentOrganizationId)
			this.props.notify(t('settings.eso.successfullyProccessed'), 'success')
		} catch (error) {
			this.props.notify(t('clientError.genericError'), 'error')
		}
	}

	render() {
		const { t, processingIssues, connectionState } = this.props
		const message =
			processingIssues && processingIssues.length > 0 ? t('settings.eso.issuesInfo') : t('settings.eso.currentState')

		return (
			<div>
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
					}}
				>
					<div>{message}</div>
					<EsoIcon state={connectionState} />
				</div>
				{processingIssues && (
					<div>
						<br />
						<br />
						{processingIssues.map((issue: OrganizationProcessIssue, i: number) => (
							<div style={{ marginBottom: 15 }} key={`${issue.code || ''}/${i}`}>
								<Snackbar open inline type={'warn'} showIcon message={getServerErrorMessage(t, issue)} />
							</div>
						))}
					</div>
				)}
				{ESO_NOT_CONNECTED_STATES.indexOf(connectionState) !== -1 && (
					<div>
						<br />
						<br />
						<Button
							primary
							labelText={t('settings.eso.processButtonLabel')}
							onClick={this.onProcessClick}
							disabled={!processingIssues || processingIssues.length > 0}
							autoTestId="settings-eso-process"
						/>
					</div>
				)}
			</div>
		)
	}
}

export default withTranslate(withNotify(EsoComponent))
