//@flow

import type { Feature, State, Dispatch, FeaturesAction } from 'types'
import { connect } from 'react-redux'
import FeaturesList from 'modules/features/components/features-list'
import {
	getAvailableFeatures,
	getServerEnabledFeatures,
	getUserEnabledFeatures,
	getUserDisabledFeatures,
} from 'modules/features/selectors'
import { enableFeature, disableFeature, removeFeature } from 'modules/features/actions'

type StateProps = {|
	availableFeatures: Array<Feature>,
	serverEnabledFeatures: Array<Feature>,
	userEnabledFeatures: Array<Feature>,
	userDisabledFeatures: Array<Feature>,
|}

type DispatchProps = {|
	onEnableFeature: (feature: Feature) => void,
	onDisableFeature: (feature: Feature) => void,
	onRemoveFeature: (feature: Feature) => void,
	onReloadPress: () => void,
|}

const mapStateToProps = (state: State): StateProps => {
	return {
		availableFeatures: getAvailableFeatures(state),
		serverEnabledFeatures: getServerEnabledFeatures(state),
		userEnabledFeatures: getUserEnabledFeatures(state),
		userDisabledFeatures: getUserDisabledFeatures(state),
	}
}

const mapDispatchToProps = (dispatch: Dispatch<FeaturesAction>): DispatchProps => {
	return {
		onEnableFeature: (feature: Feature) => {
			dispatch(enableFeature(feature.id))
		},
		onDisableFeature: (feature: Feature) => {
			dispatch(disableFeature(feature.id))
		},
		onRemoveFeature: (feature: Feature) => {
			dispatch(removeFeature(feature.id))
		},
		onReloadPress: () => {
			window.location.reload()
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(FeaturesList)
