/* @flow */

import type { AccountingDocument, State, CountryVariantSpecific } from 'types'
import {
	getAccountingDocumentExchRate,
	getAccountingDocumentRoundingTypeMode,
	getAccountingDocumentSadExchRate,
	getAccountingDocumentVatExchRate,
	getAccountingDocumentCountryVariantSpecific,
} from '../../selectors'
import { getPaymentState, getProcessingStateLabel } from 'helpers'

import AccountingDocumentSummary from '../../components/invoice-elements/document-summary'
import { connect } from 'react-redux'

type OwnProps = {|
	accountingDocumentId: string,
|}

type StateProps = {|
	exchRate: ?number,
	vatExchRate: ?number,
	sadExchRate: ?number,
	roundingType: ?number,
	paymentState: string,
	processingState: string,
	accountingDocument: ?AccountingDocument,
	countryVariantSpecific: ?CountryVariantSpecific,
|}

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => {
	const data: ?{ data: ?AccountingDocument } =
		state.accountingDocument.accountingDocuments.items[ownProps.accountingDocumentId]
	const doc: ?AccountingDocument = data && data.data

	return {
		exchRate: getAccountingDocumentExchRate(state, ownProps.accountingDocumentId),
		vatExchRate: getAccountingDocumentVatExchRate(state, ownProps.accountingDocumentId),
		sadExchRate: getAccountingDocumentSadExchRate(state, ownProps.accountingDocumentId),
		roundingType: getAccountingDocumentRoundingTypeMode(state, ownProps.accountingDocumentId),
		paymentState: getPaymentState(doc),
		processingState: getProcessingStateLabel((doc && doc.processingState) || -1),
		accountingDocument: doc,
		countryVariantSpecific: getAccountingDocumentCountryVariantSpecific(state, ownProps.accountingDocumentId),
	}
}

export default connect(mapStateToProps)(AccountingDocumentSummary)
