import React from 'react'
import pure from 'recompose/pure'
import SvgIcon from 'components/SvgIcon'

let NestedOpen = props => (
	<SvgIcon {...props}>
		<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g transform="translate(6, 6)">
				<rect
					stroke="#08B0CD"
					fillOpacity="0.6"
					fill="#FFFFFF"
					opacity="0.7"
					x="0.5"
					y="0.5"
					width="11"
					height="11"
					rx="3"
				/>
				<polygon
					fill="#08B0CD"
					points="8.646 6.276 6.342 6.276 6.342 8.832 5.658 8.832 5.658 6.276 3.354 6.276 3.354 5.652 5.658 5.652 5.658 3.168 6.342 3.168 6.342 5.652 8.646 5.652"
				/>
			</g>
		</g>
	</SvgIcon>
)
NestedOpen = pure(NestedOpen)

export default NestedOpen
