// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[3].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js!../../../variables/colors.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".qu5lj2554btuNjgZZaold {\n\toverflow: hidden;\n}\n\n.H4PMSF6iVD-z9Z6fEmG2h {\n\tdisplay: flex;\n\tflex-direction: row;\n\toverflow: hidden;\n\tmax-height: calc(100vh - 150px);\n}\n\n._8hWQTAqfOf94JNo-iGk1F {\n\tflex: 1 1 240px;\n\tmax-height: 100%;\n\toverflow: hidden;\n\tmin-width: 240px;\n}\n\n._37pgqo7qXEinvCavuz-rvu, ._1lxUeGy5OlJjnFdn88bk-J {\n\tcolor: " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["grey500"] + ";\n\tmargin-left: .5em;\n}\n\n.HBrxT2H3-STo9zp1NSUP {\n\tposition: fixed;\n\ttop: 20px;\n\tright: 137px;\n}\n", "",{"version":3,"sources":["webpack://./modules/document-extraction/components/document-extraction.css"],"names":[],"mappings":"AAEA;CACC,gBAAA;AASD;;AANA;CACC,aAAA;CACA,mBAAA;CACA,gBAAA;CACA,+BAAA;AASD;;AANA;CACC,eAAA;CACA,gBAAA;CACA,gBAAA;CACA,gBAAA;AASD;;AANA;CACC,mDAAA;CACA,iBAAA;AASD;;AANA;CACC,eAAA;CACA,SAAA;CACA,YAAA;AASD","sourcesContent":["@value grey500, red from \"variables/colors.css\";\n\n.root {\n\toverflow: hidden;\n}\n\n.extraction {\n\tdisplay: flex;\n\tflex-direction: row;\n\toverflow: hidden;\n\tmax-height: calc(100vh - 150px);\n}\n\n.fields {\n\tflex: 1 1 240px;\n\tmax-height: 100%;\n\toverflow: hidden;\n\tmin-width: 240px;\n}\n\n.organizationRegNo, .organizationName {\n\tcolor: grey500;\n\tmargin-left: .5em;\n}\n\n.button {\n\tposition: fixed;\n\ttop: 20px;\n\tright: 137px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"grey500": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["grey500"] + "",
	"red": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["red"] + "",
	"root": "qu5lj2554btuNjgZZaold",
	"extraction": "H4PMSF6iVD-z9Z6fEmG2h",
	"fields": "_8hWQTAqfOf94JNo-iGk1F",
	"organizationRegNo": "_37pgqo7qXEinvCavuz-rvu",
	"organizationName": "_1lxUeGy5OlJjnFdn88bk-J",
	"button": "HBrxT2H3-STo9zp1NSUP"
};
export default ___CSS_LOADER_EXPORT___;
