/* @flow */

import { connect } from 'react-redux'
import { getAccessToken } from 'utils/local-storage'
import type { AccountingDocument, State } from 'types'
import { getPublicAccountingDocument } from '../../selectors'
import { loadPublicAccountingDocument } from '../../actions'
import ShareablePublicInvoiceDocument from '../../components/invoices/shareable-public-invoice-document'
import { getOrganizationColor, getCurrentOrganizationRegNo } from 'modules/organization/selectors'

type StateProps = {|
	color: ?string,
	userIsIssuer: boolean,
	accountingDocument: ?AccountingDocument,
|}

function mapStateToProps(state: State, ownProps: OwnProps): StateProps {
	const accountingDocument: ?AccountingDocument = getPublicAccountingDocument(state, ownProps.uniqueId)
	const accDocRegNo = accountingDocument && accountingDocument.ownContact && accountingDocument.ownContact.companyRegNo
	const organizationRegNo = getCurrentOrganizationRegNo(state)
	const hasToken = !!getAccessToken()

	return {
		color: hasToken ? getOrganizationColor(state) : null,
		accountingDocument: accountingDocument,
		userIsIssuer: hasToken && organizationRegNo === accDocRegNo,
	}
}

type DispatchProps = {|
	loadPublicAccountingDocument: (uniqueId: string) => Promise<void>,
|}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
	return {
		loadPublicAccountingDocument: (uniqueId: string) => {
			return dispatch(loadPublicAccountingDocument(uniqueId))
		},
	}
}

type OwnProps = {|
	uniqueId: string,
|}

export default connect(mapStateToProps, mapDispatchToProps)(ShareablePublicInvoiceDocument)
