/* @flow */
/** @jsx jsx */

import { Component } from 'react'
import { jsx } from '@emotion/core'
import { withRouter } from 'react-router-dom'
import { withTranslate } from 'wrappers'
import { accountingDocumentsRoute } from 'modules/accounting-document/routing/routes'
import type { WithRouterProps, FormFieldErrorContext } from 'types'
import type { View } from '../../types'
import type { WithTranslateProps } from 'wrappers'
import { autoTestId } from 'utils/tests/autotest'
import NavigationChevronLeft from 'components/svg-icons/navigation/chevron-left'
import ConfirmDialog from 'components/confirm-dialog'
import PopupSection from 'components/PopupSection'
import Paper from 'components/Paper'
import { colors } from 'variables'
import styles from './invoice.css'

export type Props = {|
	...WithTranslateProps,
	...WithRouterProps,
	formFieldErrorContext?: FormFieldErrorContext,
	enabledViews: { [key: View]: boolean },
	view: View,
	readonly?: boolean,
	storing?: boolean,
	scansCount?: number,
	onViewChange?: ('default' | 'scans') => void,
	children?: any,
	confirmClose?: boolean,
	onCloseRedirectUrl?: string,
	onCloseCallback?: () => void,
	// ---- ELEMENTS ----
	Name: any,
	TypeSwitcher?: any,
	StateChanger: any,
	CreateAccDocButton: any,
	NeedHelp: any,
	ExtractionNote?: React$Element<any>,
	SettingsPanel?: any,
	Scans?: any,
	ScansSaveButton?: any,
	Summary?: any,
	MoreActions?: any,
	Note?: any,
	UpperSettings?: any,
	CashbotPanel?: any,
	Direction?: any,
	ExchRateInfo?: any,
	showOrganizationInfo?: boolean,
|}

type State = {|
	dirty?: boolean,
|}

class SplitView extends Component<Props, State> {
	state = {
		dirty: false,
	}

	static getDerivedStateFromProps(props: Props, state: State) {
		return { dirty: props.storing || state.dirty }
	}

	handleScansViewButtonClick = () => {
		this.props.onViewChange && this.props.onViewChange('scans')
	}

	handleDefaultViewButtonClick = () => {
		this.props.onViewChange && this.props.onViewChange('default')
	}

	handlePageClose = () => {
		if (this.props.confirmClose) {
			ConfirmDialog(this.props.t('dialogs.continueWithoutSave'), {
				okLabel: this.props.t('dialogs.dontSave'),
				cancelLabel: this.props.t('application.cancel'),
				body: this.props.t('dialogs.closeWhenUnsavedWarningBody'),
			}).then(() => {
				this.close()
			})
		} else this.close()
	}

	close = () => {
		this.props.onCloseCallback && this.props.onCloseCallback()
		if (this.props.onCloseRedirectUrl) {
			this.props.history.push(this.props.onCloseRedirectUrl)
		} else {
			this.props.history.length < 3 ? this.props.history.push(accountingDocumentsRoute()) : this.props.history.goBack()
		}
	}

	renderSaveState() {
		if (!this.state.dirty) return null
		return (
			this.props.storing !== undefined &&
			!this.props.readonly && (
				<div className={styles.state}>
					{this.props.storing && <div className={styles.loader} />}
					<span className={styles.stateText}>
						{this.props.storing ? this.props.t('invoice.states.saving') : this.props.t('invoice.states.saved')}
					</span>
				</div>
			)
		)
	}

	renderHeader() {
		return (
			this.props.view !== 'scans' && (
				<div className={styles.wideHeader}>
					<div className={styles.headerRow}>
						<div className={styles.headings} id={'issued-invoice-header'} {...autoTestId('document-header')}>
							{this.props.Name}
							{this.props.Direction && <span css={style.direction}>{this.props.Direction}</span>}
							{/*{this.props.ExchRateInfo && <span css={style.exchRateInfo}>{this.props.ExchRateInfo}</span>}*/}
						</div>
						<div className={styles.actions}>
							{this.renderSaveState()}
							{this.props.CreateAccDocButton}
							{this.props.StateChanger}
							{this.props.MoreActions}
							{this.props.NeedHelp}
						</div>
					</div>
					{this.props.TypeSwitcher}
					{/* this.props.readonly && this.props.Summary && <div className={styles.details}>{this.props.Summary}</div> */}
					{this.props.UpperSettings}
					{this.props.CashbotPanel}
				</div>
			)
		)
	}

	renderContent() {
		const contentStyle =
			!this.props.readonly && this.props.view === 'scans'
				? styles.scansView
				: this.props.view === 'scans'
				? styles.classificationView
				: ''
		const showOrganizationInfo = this.props.showOrganizationInfo ? styles.showOrganizationInfo : ''

		return (
			<div className={`${contentStyle} ${showOrganizationInfo}`}>
				<div className={styles.scroller}>
					{this.props.view === 'scans' && (
						<div className={styles.wideHeader}>
							<div className={styles.headerRow}>
								<div className={styles.headings}>
									{this.props.Name}
									{this.props.showOrganizationInfo && <span>{this.props.t('scans.company')}</span>}
								</div>
								<div className={styles.upperSettingsInHead}>{this.props.UpperSettings}</div>
								<div className={styles.actions}>
									{this.props.ScansSaveButton}
									{this.renderSaveState()}
									{this.props.StateChanger}
									{this.props.MoreActions}
									{this.props.NeedHelp}
								</div>
							</div>
						</div>
					)}

					<div className={`${styles[this.props.view]} ${this.props.readonly ? styles.readonly : styles.editable}`}>
						<div className={styles.scansContainerWrapper} {...autoTestId('scan-container')}>
							<div className={styles.scansNoteContainer}>{this.props.ExtractionNote}</div>
							<Paper rounded zDepth={3} className={styles.scansContainer}>
								{this.props.Scans}
							</Paper>
						</div>
						<div className={styles.invoiceContainer} {...autoTestId('document-container')}>
							{this.renderHeader()}
							<div>{this.props.Note}</div>
							{this.props.children}
						</div>

						{!this.props.readonly && this.props.view !== 'scans' && (
							<div className={styles.menu}>{this.props.SettingsPanel}</div>
						)}

						{!this.props.readonly && this.props.enabledViews.scans && this.props.view !== 'scans' && (
							<div
								className={this.props.scansCount ? styles.showScanButton : styles.uploadScanButton}
								onClick={this.handleScansViewButtonClick}
							>
								<div className={styles.shadowBooklet} />
								<div className={styles.booklet}>
									<div className={styles.bookletContent}>
										<div className={styles.text}>
											{this.props.scansCount
												? this.props.t('invoice.showUploadedFiles')
												: this.props.t('invoice.uploadFile')}
										</div>
										<div className={styles.chevron}>
											<NavigationChevronLeft color={colors.blue} hoverColor={colors.blue} />
										</div>
									</div>
								</div>
							</div>
						)}

						{this.props.enabledViews.default && this.props.view !== 'default' && (
							<div className={styles.hideScanButton} onClick={this.handleDefaultViewButtonClick}>
								<div className={styles.shadowBooklet} />
								<div className={styles.booklet}>
									<div className={styles.bookletContent}>
										<div className={styles.text}>{this.props.t('invoice.showDocument')}</div>
										<div className={styles.chevron}>
											<NavigationChevronLeft color={colors.blue} hoverColor={colors.blue} />
										</div>
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
		)
	}

	render() {
		return !this.props.readonly ? (
			<PopupSection open onRequestClose={this.handlePageClose}>
				{this.renderContent()}
			</PopupSection>
		) : (
			this.renderContent()
		)
	}
}

const style = {
	direction: {
		display: 'block',
		height: 50,
		marginLeft: 10,
	},
	exchRateInfo: {
		display: 'block',
		height: 50,
		marginLeft: 20,
	},
}

export default withRouter(withTranslate(SplitView))
