/* @flow */

import type { ScanFactoryDocumentFileMetadata, ScanFactoryField } from 'types'
import type { ExtractionErrors, FieldCursor } from '../types'
import { fieldIterator, getFieldCursorKey } from './field'
import { Validator } from 'utils/validators'
import { getFieldSections } from './section'

export default function validate(metadata: ScanFactoryDocumentFileMetadata): ExtractionErrors {
	const errors: ExtractionErrors = {}

	fieldIterator(metadata, (field: ScanFactoryField, fieldCursor: FieldCursor) => {
		if (field.value) {
			const validator = validators[field.interpreterName || '']
			if (validator) {
				let error = validator(field.value)
				if (error) {
					errors[getFieldCursorKey(fieldCursor)] = error
				}
			}
		}
	})

	return errors
}

export const validators: { [interpreterName: string]: (value: string) => ?string } = {
	emailInterpreter: (value: string) => getErrorMessage(Validator.email, value, 'email'),
	// currencyInterpreter: (value: string) => {},
	dateInterpreter: (value: string) => getErrorMessage(Validator.date, value, 'date'),
	// vatRateInterpreter: (value: string) => {},
	decimalInterpreter: (value: string) =>
		getErrorMessage((value: string) => Validator.decimal(value, { forceDataType: false }), value, 'decimal'),
	zipCodeInterpreter: (value: string) => getErrorMessage(Validator.zip, value, 'zip'),
	integerInterpreter: (value: string) =>
		getErrorMessage((value: string) => Validator.integer(value, { forceDataType: false }), value, 'integer'),
	regNoInterpreter: (value: string) => getErrorMessage(Validator.ICO, value, 'regNo'),
	// bankAccountInterpreter: (value: string) => {},
	// taxNoInterpreter: (value: string) => {},
}

function getErrorMessage(fn: (value: string) => boolean, value: string, validatorType: string): ?string {
	if (!fn(value)) {
		return validatorType
	}
}

export function getFieldError(errors: ?ExtractionErrors, fieldCursor: FieldCursor): ?string {
	if (!errors) {
		return undefined
	}

	const key = getFieldCursorKey(fieldCursor)
	if (errors[key]) {
		return errors[key]
	}
}

export function validateInput(metadata?: ?ScanFactoryDocumentFileMetadata): boolean {
	if (!metadata || 'object' !== typeof metadata) {
		return false
	}

	const sections = getFieldSections()
	for (const section of sections) {
		if (section.isRepeatable) {
			if (
				'undefined' === typeof metadata[section.name][0] ||
				!Array.isArray(metadata[section.name][0]) ||
				0 === metadata[section.name][0].length
			) {
				return false
			}
		} else {
			if (!Array.isArray(metadata[section.name]) || 0 === metadata[section.name].length) {
				return false
			}
		}
	}

	if (!Array.isArray(metadata.pages) || 0 === metadata.pages.length) {
		return false
	}

	return true
}
