/* @flow */

import type { CommonAction, Dispatch, State, ValidationError, NotificationType } from 'types'
import { clearNotification, clearServerError } from 'modules/common/actions'

import NotificationCenter from '../components/notification-center'
import { connect } from 'react-redux'

type StateProps = {|
	notification: ?NotificationType,
	serverError: ?ValidationError,
|}

const mapStateToProps = (state: State): StateProps => {
	return {
		notification: state.common.notification,
		serverError: state.common.serverError,
	}
}

type DispatchProps = {
	clearNotification: () => void,
	clearServerError: () => void,
}

const mapDispatchToProps = (dispatch: Dispatch<CommonAction>): DispatchProps => {
	return {
		clearNotification: () => {
			dispatch(clearNotification())
		},
		clearServerError: () => {
			dispatch(clearServerError())
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationCenter)
