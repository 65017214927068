import React from 'react'
import pure from 'recompose/pure'
import SvgIcon from 'components/SvgIcon'

let Blue = props => (
	<SvgIcon {...props}>
		<g stroke="none" strokeWidth="1" fill="rgba(74, 144, 226, 0.6)" fillRule="evenodd">
			<path d="M10.4,16 L6.4,12 L7.528,10.872 L10.4,13.736 L16.472,7.664 L17.6,8.8 L10.4,16 Z M12,4 C7.584,4 4,7.584 4,12 C4,16.416 7.584,20 12,20 C16.416,20 20,16.416 20,12 C20,7.584 16.416,4 12,4 L12,4 Z" />
		</g>
	</SvgIcon>
)
Blue = pure(Blue)

export default Blue
