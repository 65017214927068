/* @flow */

import MainLayout from 'layouts/main-layout'
import React from 'react'
import { Route } from 'react-router'
import Settings from 'modules/settings/pages/settings-layout'
import SettingsRedir from 'modules/settings/pages/settings-redir'
import { UserInternalPermission, UserIsAuthenticated } from 'lib/auth'

const SettingsRedirComponent = UserIsAuthenticated(MainLayout('settings')(SettingsRedir))
const SettingsComponent = UserIsAuthenticated(MainLayout('settings')(Settings))
const InternalSettingsComponent = UserInternalPermission(UserIsAuthenticated(MainLayout('settings')(Settings)))

const RedirComponent = () => <SettingsRedirComponent />

const CompanyComponent = () => <SettingsComponent section="company" /> // eslint-disable-line
const EsoComponent = () => <InternalSettingsComponent section="company" subSection="eso" /> // eslint-disable-line
const BasicsComponent = () => <SettingsComponent section="company" subSection="basics" /> // eslint-disable-line
const DesignComponent = () => <SettingsComponent section="company" subSection="design" /> // eslint-disable-line
const ResponsibilityComponent = () => <SettingsComponent section="company" subSection="responsibility" /> // eslint-disable-line
const SignaturesComponent = () => <SettingsComponent section="company" subSection="signatures" /> // eslint-disable-line
// const EetComponent = () => <SettingsComponent section="company" subSection="eet" /> // eslint-disable-line
const CashbotComponent = () => <SettingsComponent section="company" subSection="cashbot" /> // eslint-disable-line
const AdministrationComponent = () => <SettingsComponent section="company" subSection="administration" /> // eslint-disable-line

const AccountingComponent = () => <SettingsComponent section="accounting" /> // eslint-disable-line
const AccountingSettingsComponent = () => <SettingsComponent section="accounting" subSection="accountingSettings" /> // eslint-disable-line
const SequencesComponent = () => <SettingsComponent section="accounting" subSection="sequences" /> // eslint-disable-line
const AccountingDocumentComponent = () => <SettingsComponent section="accounting" subSection="accountingDocuments" /> // eslint-disable-line
const FinancialAccountsComponent = () => <SettingsComponent section="accounting" subSection="financialAccounts" /> // eslint-disable-line

const ReportingComponent = () => <SettingsComponent section="reporting" /> // eslint-disable-line
const BrandsComponent = () => <SettingsComponent section="reporting" subSection="branches" /> // eslint-disable-line
const ProjectsComponent = () => <SettingsComponent section="reporting" subSection="projects" /> // eslint-disable-line

const UsersComponent = () => <SettingsComponent section="users" /> // eslint-disable-line
const UsersSettingsComponent = () => <SettingsComponent section="users" subSection="users" /> // eslint-disable-line
const InternalComponent = () => <SettingsComponent section="users" subSection="internal" /> // eslint-disable-line
const GroupsComponent = () => <SettingsComponent section="users" subSection="groups" /> // eslint-disable-line

const TriviComponent = () => <SettingsComponent section="triviSettings" /> // eslint-disable-line
const EmailsComponent = () => <SettingsComponent section="triviSettings" subSection="emails" /> // eslint-disable-line
const RemindersComponet = () => <SettingsComponent section="triviSettings" subSection="paymentReminders" /> // eslint-disable-line
const AccountingDocumentCategoriesComponent = () => (<SettingsComponent section="triviSettings" subSection="accountingDocumentCategories" />) // eslint-disable-line
const ApiComponent = () => <SettingsComponent section="triviSettings" subSection="api" /> // eslint-disable-line
const PrintingComponent = () => <SettingsComponent section="triviSettings" subSection="printing" /> // eslint-disable-line
const ExtractionComponent = () => <SettingsComponent section="triviSettings" subSection="extraction" /> // eslint-disable-line

const UploadReminderComponent = () => <SettingsComponent section="triviSettings" subSection="upload-reminder" /> // eslint-disable-line

export default () => {
	return [
		<Route exact key="redir" path="/:organizationId/settings" render={RedirComponent} />,
		//COMPANY --------------------------------------------------------------
		<Route exact key="company" path="/:organizationId/settings/company" render={CompanyComponent} />,
		<Route exact key="company" path="/:organizationId/settings/company/eso" render={EsoComponent} />,
		<Route exact key="basics" path="/:organizationId/settings/company/basics" render={BasicsComponent} />,
		<Route
			exact
			key="responsibility"
			path="/:organizationId/settings/company/responsibility"
			render={ResponsibilityComponent}
		/>,
		<Route exact key="signatures" path="/:organizationId/settings/company/signatures" render={SignaturesComponent} />,
		// <Route exact key="eet" path="/:organizationId/settings/company/eet" render={EetComponent} />,
		<Route exact key="cashbot" path="/:organizationId/settings/company/cashbot" render={CashbotComponent} />,
		<Route
			exact
			key="administration"
			path="/:organizationId/settings/company/administration"
			render={AdministrationComponent}
		/>,

		//ACCOUNTING --------------------------------------------------------------
		<Route exact key="accounting" path="/:organizationId/settings/accounting" render={AccountingComponent} />,
		<Route
			exact
			key="accountingSettings"
			path="/:organizationId/settings/accounting/accounting-settings"
			render={AccountingSettingsComponent}
		/>,
		<Route exact key="sequences" path="/:organizationId/settings/accounting/sequences" render={SequencesComponent} />,
		<Route
			exact
			key="accountingDocuments"
			path="/:organizationId/settings/accounting/accounting-documents"
			render={AccountingDocumentComponent}
		/>,
		<Route
			exact
			key="financialAccounts"
			path="/:organizationId/settings/accounting/financial-accounts"
			render={FinancialAccountsComponent}
		/>,

		//REPORTING --------------------------------------------------------------
		<Route exact key="reporting" path="/:organizationId/settings/reporting" render={ReportingComponent} />,
		<Route exact key="branches" path="/:organizationId/settings/reporting/branches" render={BrandsComponent} />,
		<Route exact key="projects" path="/:organizationId/settings/reporting/projects" render={ProjectsComponent} />,

		//USERS --------------------------------------------------------------
		<Route exact key="usersPage" path="/:organizationId/settings/users" render={UsersComponent} />,
		<Route exact key="users" path="/:organizationId/settings/users/users" render={UsersSettingsComponent} />,
		<Route exact key="internalUsers" path="/:organizationId/settings/users/internal" render={InternalComponent} />,
		<Route exact key="groups" path="/:organizationId/settings/users/groups" render={GroupsComponent} />,

		//TRIVI SETTINGS --------------------------------------------------------------
		<Route exact key="triviSettings" path="/:organizationId/settings/trivi-settings" render={TriviComponent} />,
		<Route exact key="emails" path="/:organizationId/settings/trivi-settings/emails" render={EmailsComponent} />,
		<Route
			exact
			key="paymentReminders"
			path="/:organizationId/settings/trivi-settings/payment-reminders"
			render={RemindersComponet}
		/>,
		<Route
			exact
			key="accountingDocumentCategories"
			path="/:organizationId/settings/trivi-settings/accounting-document-categories"
			render={AccountingDocumentCategoriesComponent}
		/>,
		<Route exact key="api" path="/:organizationId/settings/trivi-settings/api" render={ApiComponent} />,
		<Route exact key="printing" path="/:organizationId/settings/trivi-settings/printing" render={PrintingComponent} />,
		<Route
			exact
			key="extraction"
			path="/:organizationId/settings/trivi-settings/extraction"
			render={ExtractionComponent}
		/>,
		<Route
			exact
			key="upload-reminder"
			path="/:organizationId/settings/trivi-settings/upload-reminder"
			render={UploadReminderComponent}
		/>,
	]
}
