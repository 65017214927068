/* @flow */
/** @jsx jsx */

import moment from 'moment'
import { Component } from 'react'
import { jsx } from '@emotion/core'
import { type OrganizationReportVatPayment } from 'types'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import InfoIcon from 'components/svg-icons/action/info-outline'
import Section from '../todo/section'
import { formatToMoney } from 'utils/formatters'
import PopupSection from 'components/PopupSection'
import { colors } from 'variables'

type Props = {|
	...WithTranslateProps,
	vatPayment: ?OrganizationReportVatPayment,
	currency: ?string,
	taxPayer: boolean,
|}

type State = {|
	dialogOpen: boolean,
|}

class CurrentTurnover extends Component<Props, State> {
	state = {
		dialogOpen: false,
	}

	render() {
		if (this.props.taxPayer) {
			return null
		}

		const { currentTurnover = 0, maxTurnoverForMandatoryVatRegistration = 0 } = this.props.vatPayment || {}
		const currentTurnoverLimitPercent = Math.floor((currentTurnover / maxTurnoverForMandatoryVatRegistration) * 100)
		const showInfo = currentTurnoverLimitPercent >= 80
		const overflowed = currentTurnoverLimitPercent >= 100
		const styles = getStyles(currentTurnoverLimitPercent)
		const turnoverInfo = (
			<div>
				<div css={styles.type}>{this.props.t('dashboard.todo.currentTurnover.turnover')}</div>
				<div css={styles.amount}>
					{formatToMoney(currentTurnover, {
						currency: this.props.currency,
						maximumFractionDigits: 0,
						minimumFractionDigits: 0,
					})}
				</div>
			</div>
		)

		const limitInfo = (
			<div>
				<div css={styles.type}>{this.props.t('dashboard.todo.currentTurnover.limit')}</div>
				<div css={styles.amount}>
					{formatToMoney(maxTurnoverForMandatoryVatRegistration, {
						currency: this.props.currency,
						maximumFractionDigits: 0,
						minimumFractionDigits: 0,
					})}
				</div>
			</div>
		)

		return (
			<Section
				header={this.props.t('dashboard.todo.currentTurnover.title')}
				subHeadline={this.props.t('dashboard.todo.currentTurnover.subtitle', {
					date: moment()
						.subtract(1, 'months')
						.endOf('month')
						.format('DD.MM.YYYY'),
				})}
				rightHeader={showInfo && this.renderRightHeader(currentTurnoverLimitPercent, styles)}
			>
				<div css={styles.bar}>
					<div css={styles.turnover}>{overflowed ? limitInfo : turnoverInfo}</div>
					<div css={styles.limit}>{overflowed ? turnoverInfo : limitInfo}</div>
				</div>
				{this.renderDialog()}
			</Section>
		)
	}

	renderRightHeader(currentTurnoverLimitPercent: number, styles: Object) {
		return (
			<div style={styles.info}>
				<InfoIcon size={18} style={styles.infoIcon} disabled />
				<span>
					{this.props.t('dashboard.todo.currentTurnover.exceededLimitInfo', {
						percent: currentTurnoverLimitPercent,
					})}
					&nbsp;
				</span>
				{/*<TriviLink onClick={this.openDialog} style={styles.link}>*/}
				{/*	{this.props.t('dashboard.todo.currentTurnover.exceededLimitMoreInfo')}*/}
				{/*</TriviLink>*/}
			</div>
		)
	}

	renderDialog() {
		return (
			<PopupSection open={this.state.dialogOpen} modal onRequestClose={this.closeDialog}>
				Info content.
			</PopupSection>
		)
	}

	openDialog = () => {
		this.setState({ dialogOpen: true })
	}

	closeDialog = () => {
		this.setState({ dialogOpen: false })
	}
}

function getStyles(currentTurnoverLimitPercent: number) {
	const overflowed = currentTurnoverLimitPercent >= 100
	const width = overflowed ? (100 / currentTurnoverLimitPercent) * 100 : currentTurnoverLimitPercent

	return {
		info: {
			whiteSpace: 'nowrap',
			display: 'flex',
			alignItems: 'center',
		},
		infoIcon: {
			verticalAlign: 'middle',
			marginRight: 5,
		},
		link: {
			textDecoration: 'underline',
		},
		type: {
			fontSize: 14,
			lineHeight: '23px',
		},
		amount: {
			fontSize: 16,
			lineHeight: '23px',
			fontWeight: 600,
		},
		bar: {
			display: 'flex',
			background: overflowed ? 'linear-gradient(90deg, rgba(182,191,225,1) 0%, rgba(110,119,152,1) 100%)' : '#DAE9F4',
			borderRadius: 20,
			overflow: 'hidden',
			margin: '0 20px 20px 20px',
			textAlign: 'right',
		},
		turnover: {
			flex: `0 1 ${width}%`,
			background: overflowed ? colors.transparent : '#6E7798',
			borderRadius: overflowed ? 0 : 20,
			borderRight: overflowed ? `1px dashed ${colors.whiteFaded80}` : 'none',
			overflow: 'hidden',
			padding: '9px 30px',
			color: colors.whiteFaded80,
			minWidth: overflowed ? 200 : 130,
		},
		limit: {
			flex: '1 0 auto',
			padding: '9px 30px 9px 12px',
			color: overflowed ? colors.white : colors.black,
		},
	}
}

export default withTranslate(CurrentTurnover)
