//@flow

import type { Currency, DashboardAction } from 'types'

export type State = {
	activeCurrency: ?Currency,
}

export const initialState: State = {
	activeCurrency: undefined,
}

export default (state: State = initialState, action: DashboardAction): State => {
	switch (action.type) {
		case 'SELECT_CURRENCY': {
			return {
				...state,
				activeCurrency: action.currency,
			}
		}
	}

	return state
}
