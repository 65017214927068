/* @flow */

import React, { Component, type ComponentType, type ElementConfig } from 'react'

export type AutoloadProps = {|
	autoload: (props: any) => void,
|}

export default <P: any, C: ComponentType<P>>(
	WrappedComponent: C,
	// $FlowFixMe
): Class<Component<{| ...AutoloadProps, ...ElementConfig<C> |}, any>> => {
	class Wrapper extends Component<{| ...AutoloadProps, ...ElementConfig<C> |}> {
		componentDidMount() {
			this.props.autoload && this.props.autoload(this.props)
		}

		render() {
			const { autoload, ...rest } = this.props //eslint-disable-line
			return <WrappedComponent {...rest} />
		}
	}

	return Wrapper
}
