/* @flow */

import { difference, toArray, uniq } from 'lodash-es'
import type { ChatUserInfo, ChatOrganizationInfo, InternalUser, UserProfile, OrganizationInternalUser } from 'types'
import type { Channels, Member, Members, MessagesByChannel, User, Users } from '../types'
import { getUserFullName } from 'modules/user/domain/user'
import { getAllChannelsMemberIds } from './channel'
import { getAllMessagesMemberIds } from './message'

export const userToMember = (user: User): Member => {
	return { id: user.id }
}

export function usersToMembers(users: Array<User>): Members {
	return users.map(userToMember)
}

export const toUser = (
	user: InternalUser | UserProfile | OrganizationInternalUser,
	organizationName?: string,
): User => {
	const firstLast = getUserFullName(user)
	const name = (firstLast || user.email || '').toString()

	return {
		id: user.chatIdentity || '',
		appId: (user.id || user.userId || '').toString(),
		name,
		organization: organizationName || 'Trivi',
	}
}

export const isValidUser = (user: InternalUser | UserProfile | OrganizationInternalUser): boolean => {
	const id = user.userId ? user.userId : user.id ? user.id : undefined
	return 'string' === typeof user.chatIdentity && user.chatIdentity.length > 0 && 'undefined' !== typeof id
}

export function matchUser(user: User, search: string): boolean {
	return search
		? -1 !== user.name.toLowerCase().indexOf(search.toLowerCase()) ||
				-1 !== user.organization.toLowerCase().indexOf(search.toLowerCase())
		: true
}

export function createEmptyUser(id: string): User {
	const no = (id.match(/\d+/) || [id])[0]

	return {
		id: id,
		appId: no,
		name: '',
		organization: '',
	}
}

export function getUnknownUsersIds(channels: Channels, messagesByChannel: MessagesByChannel, users: Users) {
	const allUserIds = uniq([...getAllChannelsMemberIds(channels), ...getAllMessagesMemberIds(messagesByChannel)])
	// 1, 2, 3

	const loadedUserIds = toArray(users).map((user: User) => user.id)
	// 1, 2, 4

	// => load 3
	return difference(allUserIds, loadedUserIds)
}

export function chatUserInfoToUser(chatUserInfo: ChatUserInfo): User {
	return {
		id: chatUserInfo.chatIdentity || '',
		appId: chatUserInfo.id || '',
		name: `${chatUserInfo.firstName || ''} ${chatUserInfo.lastName || ''}`,
		organization: (chatUserInfo.organizationsInfo || [])
			.map((info: ChatOrganizationInfo) => info.name || '')
			.join(', '),
	}
}
