//@flow

import type { AccountingDocumentBankAccount, UnmatchedPayment } from 'types'

export function formatBankAccountValues(bankAccount: ?string, code: ?string): string {
	return !!bankAccount && !!code && code.length === 4 ? `${bankAccount}/${code}` : ''
}

export function formatUnmatchedPaymentBankAccount(unmatchedPayment: ?UnmatchedPayment): ?string {
	if (!unmatchedPayment) {
		return null
	}
	return formatBankAccountValues(unmatchedPayment.counterpartBankAccount, unmatchedPayment.counterpartBankCode)
}

export function formatAccountingDocumentBankAccount(bankAccount: AccountingDocumentBankAccount): string {
	return formatBankAccountValues(bankAccount.number, bankAccount.bankCode)
}
