// @flow
/** @jsx jsx */

import { Component } from 'react'
import memoize from 'memoize-one'
import { jsx } from '@emotion/core'
import type { Currency, FormFieldErrorMessage } from 'types'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import { type AutoTestProps } from 'utils/tests/autotest'
import SelectField from 'components/select-field'
import MenuItem from 'components/menu-item'
import { EMPTY_ARRAY } from 'trivi-constants'
import { getTooltipErrorLabel } from 'helpers'

type Props = {|
	...AutoTestProps,
	...WithTranslateProps,
	currencies: ?Array<Currency>,
	autoFocus?: boolean,
	allowEmpty?: boolean,
	emptyText?: string,
	labelText?: string,
	disabled?: boolean,
	fullWidth?: boolean,
	inline?: boolean,
	compact?: boolean,
	onlyUsedInOrg?: boolean,
	value?: ?string | ?number | Array<any>,
	onChange?: (?Currency) => void,
	errors: ?Array<FormFieldErrorMessage>,
	removeError: () => void,
	loadCurrencies: () => void,
|}

class CurrencySelectorOld extends Component<Props> {
	select: any

	static defaultProps = {
		currencies: EMPTY_ARRAY,
		emptyText: '',
		value: null,
	}

	componentDidMount() {
		if (!this.props.currencies) {
			this.props.loadCurrencies()
		}
	}

	focus() {
		this.select && this.select.getWrappedInstance().focus && this.select.getWrappedInstance().focus()
	}

	bindSelect = (element: ?SelectField) => {
		this.select = element
	}

	handleCurrencyChange = (event: SyntheticEvent<HTMLElement>, index: ?number, currencyId: ?string) => {
		let currency: ?Currency

		currency =
			this.props.currencies &&
			this.props.currencies.find((c: Currency) => {
				return c.id === currencyId
			})

		this.props.onChange && this.props.onChange(currency)
		this.props.removeError()
	}

	getOptions = memoize((currencies: ?Array<Currency>, allowEmpty: ?boolean, text: ?string) => {
		const options = currencies
			? currencies.map((currency: Currency) => (
					<MenuItem key={currency.id} value={currency.id} primaryText={currency.id} />
			  ))
			: EMPTY_ARRAY

		return [allowEmpty ? <MenuItem key={0} value={null} primaryText={text} /> : null, ...options]
	})

	render() {
		const { errors, t } = this.props

		return (
			<SelectField
				labelText={this.props.labelText}
				disabled={this.props.disabled}
				fullWidth={this.props.fullWidth}
				inline={this.props.inline}
				value={this.props.value}
				autoFocus={this.props.autoFocus}
				onChange={this.handleCurrencyChange}
				compact={this.props.compact}
				ref={this.bindSelect}
				autoTestId={this.props.autoTestId}
				clientError={getTooltipErrorLabel(errors, t)}
			>
				{this.getOptions(this.props.currencies, this.props.allowEmpty, this.props.emptyText)}
			</SelectField>
		)
	}
}

export default withTranslate(CurrencySelectorOld)
