// @flow
/* eslint-disable react/no-multi-comp */
import React from 'react'
import { Tooltip, TriviLink } from 'components'
import type {
	AccountingDocumentDirection,
	Filter,
	OrganizationStatisticsKeysAccountingDocumentsStatistics,
	OrganizationStatisticsKeysCashRegister,
	OrganizationStatisticsKeysCashRegisters,
} from 'types'
import styles from './extracted-documents-direction-tooltip.css'
import { withTranslate, type WithTranslateProps } from 'wrappers'

type Props = {|
	...WithTranslateProps,
	renderValue: ?number,
	organizationId: ?string,
	receivedValue: ?number,
	issuedValue: ?number,
	unknownsValue: ?number,
	onFilterChange: (organizationID: string, direction: AccountingDocumentDirection, filter: Filter) => Promise<void>,
	cashRegisters?: OrganizationStatisticsKeysCashRegisters,
	onRedirect: (organizationId: string, cashRegisterId: string, filter: Filter) => Promise<void>,
|}

const CASH_REGISTER_FILTERS: Filter = [
	{
		field: 'ExtractingState',
		value: 4,
	},
]

const ACC_DOC_FILTERS: Filter = [
	{
		field: 'ExtractingState',
		value: 4,
	},
]

function ExtractedDocumentsDirectionTooltip(props: Props) {
	function getDirectionHandler(direction: AccountingDocumentDirection) {
		return () => {
			const { organizationId, onFilterChange } = props
			organizationId && onFilterChange(organizationId, direction, ACC_DOC_FILTERS)
		}
	}

	function getCashRegisterCountColumnHandler(cashRegisterId?: string) {
		return () => {
			const { onRedirect, organizationId } = props
			cashRegisterId && organizationId && onRedirect(organizationId, cashRegisterId, CASH_REGISTER_FILTERS)
		}
	}

	function renderCountColumn(
		value?: OrganizationStatisticsKeysAccountingDocumentsStatistics,
		clickHandler: () => void,
	) {
		return (
			<td>
				<TriviLink onClick={clickHandler}>{value && value.count ? value.count : 0}</TriviLink>
			</td>
		)
	}

	function renderCashRegisterRow(cashRegister: OrganizationStatisticsKeysCashRegister) {
		const { needHelp } = cashRegister.accountingDocuments || {}
		return (
			<tr key={cashRegister.id}>
				<td>{cashRegister.name}</td>
				{renderCountColumn(needHelp, getCashRegisterCountColumnHandler(cashRegister.id))}
			</tr>
		)
	}

	function renderLabel() {
		const { receivedValue, issuedValue, unknownsValue, cashRegisters: source, t } = props
		const cashRegisters = source && source.cashRegisters ? source.cashRegisters : []
		return (
			<div>
				<table className={styles.tooltipTable}>
					<tbody>
						<tr>
							<th>
								<div onClick={getDirectionHandler('received')} className={styles.link}>
									{t('internal.widget.organizationStatistics.received')}
								</div>
							</th>
							<th>
								<div onClick={getDirectionHandler('issued')} className={styles.link}>
									{t('internal.widget.organizationStatistics.issued')}
								</div>
							</th>
							<th>
								<div onClick={getDirectionHandler('unknown')} className={styles.link}>
									{t('internal.widget.organizationStatistics.unknowns')}
								</div>
							</th>
						</tr>
						<tr>
							<td>{receivedValue || 0}</td>
							<td>{issuedValue || 0}</td>
							<td>{unknownsValue || 0}</td>
						</tr>
					</tbody>
				</table>
				<table className={styles.tooltipTable}>
					<tbody>
						<tr className={styles.thBorder}>
							<th>{t('internal.widget.organizationStatistics.cashRegistersTooltip.cashRegister')}</th>
							<th />
						</tr>
						{cashRegisters.map(renderCashRegisterRow)}
					</tbody>
				</table>
			</div>
		)
	}

	return (
		<Tooltip hoverable inline label={renderLabel()}>
			{props.renderValue}
		</Tooltip>
	)
}

export default withTranslate(ExtractedDocumentsDirectionTooltip)
