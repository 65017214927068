/* @flow */

import { connect } from 'react-redux'
import { push, goBack } from 'react-router-redux'
import { withRouter } from 'react-router-dom'
import Tracking from 'utils/tracking'
import NeedHelp from 'modules/accounting-document/components/invoice-elements/need-help'
import type { State, Dispatch, AccountingDocumentReduxAction, AccountingDocumentNote, WithRouterProps } from 'types'
import {
	setAccountingDocumentExtractingState,
	setAccountingDocumentExtractingNote,
	fetchNextAccountingDocumentToExtract,
} from 'modules/accounting-document/actions'
import type { FinishFetchingNextAccountingDocumentToExtract } from 'modules/accounting-document/actions/accounting-document-action-types'
import { extractDashboardAccountingDocumentRoute } from 'modules/accounting-document/routing/routes'
import { internalDashboardRoute } from '../../../internal/routing/routes'
import { getIsExtractionQueue } from 'modules/accounting-document/helpers'

type OwnProps = {|
	...WithRouterProps,
	accountingDocumentId: string,
|}

const mapStateToProps = (state: State) => {
	return {
		me: state.user.me.data,
	}
}

type DispatchProps = {|
	onSend: (note: ?AccountingDocumentNote) => Promise<*>,
|}

const mapDispatchToProps = (dispatch: Dispatch<AccountingDocumentReduxAction>, ownProps: OwnProps): DispatchProps => {
	return {
		onSend: async (note: ?AccountingDocumentNote) => {
			await dispatch(setAccountingDocumentExtractingState(ownProps.accountingDocumentId, 4))
			Tracking.trackStateChangeToNeedHelp(ownProps.accountingDocumentId)

			if (note) {
				await dispatch(setAccountingDocumentExtractingNote(ownProps.accountingDocumentId, note))

				const isExtractionQueue = getIsExtractionQueue(ownProps)

				if (isExtractionQueue) {
					const resp: FinishFetchingNextAccountingDocumentToExtract = await dispatch(
						fetchNextAccountingDocumentToExtract(ownProps.accountingDocumentId),
					)
					const id: ?string = resp && resp.result && resp.result.accountingDocumentId
					const organizationId: ?string = resp && resp.result && resp.result.organizationId

					if (!id) {
						dispatch(push(internalDashboardRoute()))
						return
					}

					id && organizationId && dispatch(push(extractDashboardAccountingDocumentRoute(id, organizationId)))
				} else {
					dispatch(goBack())
				}
			}
		},
	}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NeedHelp))
