// @flow

import type { Bank } from 'types'
import { EMPTY_ARRAY } from 'trivi-constants'

export function sortBanks(banks: ?Array<Bank>): Array<Bank> {
	return banks
		? banks.sort((a: Bank, b: Bank) => {
				let aValue = getBankSortValue(a)
				let bValue = getBankSortValue(b)
				if (aValue < bValue) return -1
				if (aValue > bValue) return 1
				return 0
		  })
		: EMPTY_ARRAY
}

function getBankSortValue(bank: Bank): string {
	return (bank.bankCode || bank.name || '').toLowerCase()
}
