//@flow

import React from 'react'
import type { ISignalMessage, ISignalMessageParams, ISignalMessageGroup } from 'types'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import MenuReceiptIcon from 'components/svg-icons/trivi/menu/receipt'
import MenuCashdeskIcon from 'components/svg-icons/trivi/menu/cashdesk'
import MenuBankIcon from 'components/svg-icons/trivi/menu/bank'
import MenuTasksIcon from 'components/svg-icons/trivi/menu/tasks'
import MenuDashboardIcon from 'components/svg-icons/trivi/menu/dashboard'
import progress from 'assets/images/loader-notification.svg'
import { colors } from 'variables'

const ICONS = {
	AccountingDocument: MenuReceiptIcon,
	CashRegister: MenuCashdeskIcon,
	BankAccount: MenuBankIcon,
	Task: MenuTasksIcon,
	Organization: MenuDashboardIcon,
}

type Props = {|
	...WithTranslateProps,
	notification: ISignalMessage,
	onNotificationClick: (params: ISignalMessageParams, group: ISignalMessageGroup, notificationId: string) => void,
|}

type State = {|
	hovered: boolean,
|}

class NotificationItem extends React.Component<Props, State> {
	state = {
		hovered: false,
	}

	handleNotificationClick = () => {
		const { group, params, id: notificationId } = this.props.notification
		params && params.id && this.props.onNotificationClick(params, group, notificationId)
	}

	handleMouseLeave = () => {
		this.setState({
			hovered: false,
		})
	}

	handleMouseEnter = () => {
		this.setState({
			hovered: true,
		})
	}

	isInProgress = () => {
		return 'START' === this.props.notification.type
	}

	render() {
		const { group, name } = this.props.notification
		const label = this.props.t(`notifications.${group}.label`)
		const activityName = this.props.t(`notifications.${group}.${name}`)
		const Icon = React.createElement(ICONS[group], { disabled: true, size: 38 })
		const styles = getStyles(this.state)

		return (
			<div
				style={styles.root}
				onClick={this.handleNotificationClick}
				onMouseEnter={this.handleMouseEnter}
				onMouseLeave={this.handleMouseLeave}
			>
				<div style={styles.icon}>{Icon}</div>
				<div style={styles.content}>
					<div>{label}</div>
					<div style={styles.message}>{activityName}</div>
					{this.isInProgress() && <img src={progress} style={styles.progress} />}
				</div>
			</div>
		)
	}
}

function getStyles(state: State) {
	return {
		root: {
			display: 'flex',
			padding: '10px 20px',
			cursor: 'pointer',
			minHeight: 70,
			boxShadow: `0 -1px 0 ${colors.greyLight200}`,
			backgroundColor: state.hovered ? colors.blueFaded7 : colors.white,
		},
		icon: {
			flex: '0 0 38px',
			marginRight: 17,
		},
		content: {
			fontSize: 12,
			lineHeight: '20px',
			color: colors.blackFaded60,
			flex: '1 0 auto',
		},
		message: {
			fontWeight: 'bold',
			fontSize: 14,
			marginTop: 0,
			color: colors.black,
		},
		progress: {
			marginTop: 9,
			marginBottom: 3,
			width: '100%',
			height: 'auto',
			display: 'block',
		},
	}
}

export default withTranslate(NotificationItem)
