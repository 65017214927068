import React, { Component } from 'react'
import Arrow from 'components/svg-icons/hardware/keyboard-arrow-right'
import EnhancedButton from 'components/internal/EnhancedButton'
import PropTypes from 'prop-types'
import { colors } from 'variables'
import styles from './VerticalTab.css'

class VerticalTab extends Component {
	static propTypes = {
		/**
		 * The css class name of the root element.
		 */
		className: PropTypes.string,
		/**
		 * @ignore
		 */
		index: PropTypes.any, // eslint-disable-line
		/**
		 * Sets the text value of the tab item to the string specified.
		 */
		label: PropTypes.node,
		subLabel: PropTypes.node,
		/**
		 * Fired when the active tab changes by click.
		 * Use this event to specify any functionality when an active tab changes.
		 * For example - we are using this to route to home when the third tab becomes active.
		 * This function will always recieve the active tab as it\'s first argument.
		 */
		onActive: PropTypes.func,
		/**
		 * @ignore
		 * This property is overriden by the Tabs component.
		 */
		onClick: PropTypes.func,
		/**
		 * @ignore
		 * Defines if the current tab is selected or not.
		 * The Tabs component is responsible for setting this property.
		 */
		selected: PropTypes.bool,
		/**
		 * If value prop passed to Tabs component, this value prop is also required.
		 * It assigns a value to the tab so that it can be selected by the Tabs.
		 */
		value: PropTypes.any, // eslint-disable-line
	}

	handleClick = event => {
		if (this.props.onClick) {
			this.props.onClick(this.props.value, event, this)
		}
	}

	render() {
		const {
			index, // eslint-disable-line no-unused-vars
			onActive, // eslint-disable-line no-unused-vars
			onClick, // eslint-disable-line no-unused-vars
			selected, // eslint-disable-line no-unused-vars
			label,
			subLabel,
			value, // eslint-disable-line no-unused-vars
			...other
		} = this.props

		return (
			<div className={selected ? styles.selected : styles.normal}>
				<EnhancedButton
					{...other}
					style={{
						border: 'none',
						padding: 0,
						display: 'block',
						width: '100%',
					}}
					onClick={this.handleClick}
				>
					<div className={styles.inner}>
						<div className={styles.button}>
							<div className={styles.label}>{label}</div>
							{subLabel && <div className={styles.subLabel}>{subLabel}</div>}
							<Arrow hoverColor={colors.black} color={colors.black} style={style.icon} />
						</div>
					</div>
				</EnhancedButton>
				{selected && <div className={styles.bar} />}
			</div>
		)
	}
}

const style = {
	icon: {
		position: 'absolute',
		right: 5,
		top: '50%',
		marginTop: -12,
	},
}

export default VerticalTab
