/* @flow */

import React, { PureComponent } from 'react'
import moment from 'moment'
import {
	withTranslate,
	withOrganizationSettings,
	withTaxPaymentHistory,
	type WithTranslateProps,
	type WithOrganizationSettingsProps,
	type WithTaxPaymentHistoryProps,
} from 'wrappers'
import { parseFloatOr, parseIntOr } from 'utils/math'
import { formatDateToIsoString, formatToDateString } from 'utils/formatters'
import type {
	OrganizationSettings,
	OrganizationCountrySpecificSettings,
	OrganizationCountrySpecificSettingsCzTaxRegistration,
	OrganizationCountrySpecificSettingsCzAuthorization,
	ActivationWithStartDate,
	OrganizationSettingsTaxPaymentHistory,
	OrganizationSettingsOssCountries,
	OssCountryRegistration,
	Country,
} from 'types'
import AccountingTypeSelector from 'modules/common/components/selectors/accounting-type-selector'
import PayerTaxTypeSelector, { TYPES_TRANSLATIONS } from 'modules/common/components/selectors/payer-tax-type-selector'
import NumberInput from 'components/number-input'
import { TabSwitches, TabSwitch } from 'components/TabSwitches'
import SelectNext from 'components/select-next'
import TextField from 'components/TextField'
import DatePicker from 'components/date-picker'
import ToggleDatePicker from 'components/toggle-date-picker'
import styles from '../settings.css'
import { getCountryNameById } from '../../../common/domain/countries'
import type { OptionType } from 'components/select-next'
import { notNullAndUndefined } from 'utils'

const VAT_PERIOD_TRANSLATIONS = {
	'1': 'settings.accountingSettings.vatPeriodColMonthly',
	'2': 'settings.accountingSettings.vatPeriodColQuartely',
}

type TaxRegistrationKey = $Keys<OrganizationCountrySpecificSettingsCzTaxRegistration>

type AuthorizationKey = $Keys<OrganizationCountrySpecificSettingsCzAuthorization>

type Field = TaxRegistrationKey | AuthorizationKey

type Props = {|
	...WithTranslateProps,
	...WithTaxPaymentHistoryProps,
	...WithOrganizationSettingsProps,
	countries: ?Array<Country>,
	organizationSettings: ?OrganizationSettings,
	loadOrganizationSettings: () => void,
	updateOrganizationSettings: (oldSettings: OrganizationSettings, newSettings: OrganizationSettings) => Promise<any>,
	currentUserIsInternal: boolean,
	canSetInternalOrganizationSettings: boolean,
	taxPaymentHistory: ?Array<OrganizationSettingsTaxPaymentHistory>,
	oss: ?OrganizationSettingsOssCountries,
	loadTaxPaymentHistory: () => void,
	loadOrganizationSettingsOss: () => void,
	loadCountries: () => void,
|}

type State = {|
	accountingMonthFrom?: number,
	accountingType?: number,
	payerTaxType?: number,
	payerTaxTypeFrom?: string,
	payerTaxTypeNote?: string,
	vatPeriod?: number,
	employeeCount?: number,
	hasForeignCurrencyOperations?: boolean,
	workflowTypeId?: string,
	fixedAssetsFrom?: number,
	isPairAccountingDocumentsCreationAllowed?: boolean,
	automaticAccountingDocumentNumberingBy?: number,
	isConnectedWithForeignPersons?: boolean,
	isConnectedWithRelatedPersons?: boolean,
	hasStore?: boolean,
	accountingPeriod: boolean,
	taxRegistration?: ?OrganizationCountrySpecificSettingsCzTaxRegistration,
	authorization?: ?OrganizationCountrySpecificSettingsCzAuthorization,
|}

class AccountingSettings extends PureComponent<Props, State> {
	state: State = {
		...this.getStateFromProps(this.props),
	}

	UNSAFE_componentWillMount() {
		if (!this.props.countries) {
			this.props.loadCountries()
		}
		if (!this.props.oss) {
			this.props.loadOrganizationSettingsOss()
		}
		if (!this.props.organizationSettings) {
			this.props.loadOrganizationSettings()
		}
		if (!this.props.taxPaymentHistory) {
			this.props.loadTaxPaymentHistory()
		}
	}

	UNSAFE_componentWillReceiveProps(newProps: Props) {
		this.setState(this.getStateFromProps(newProps))
	}

	getStateFromProps(props: Props) {
		const settings: ?OrganizationSettings = props.organizationSettings
		if (settings) {
			const { cz } = settings.countrySpecificSettings || {}
			return {
				accountingMonthFrom: settings.accountingMonthFrom,
				accountingType: settings.accountingType,
				payerTaxType: settings.payerTaxType,
				payerTaxTypeFrom: props.taxPaymentHistory
					? props.taxPaymentHistory[props.taxPaymentHistory.length - 1].dateFrom
					: '',
				payerTaxTypeNote: props.taxPaymentHistory
					? props.taxPaymentHistory[props.taxPaymentHistory.length - 1].note
					: '',
				vatPeriod: settings.vatPeriod,
				employeeCount: settings.employeeCount,
				hasForeignCurrencyOperations: settings.hasForeignCurrencyOperations,
				workflowTypeId: settings.workflowTypeId,
				fixedAssetsFrom: settings.fixedAssetsFrom,
				isPairAccountingDocumentsCreationAllowed: settings.isPairAccountingDocumentsCreationAllowed,
				automaticAccountingDocumentNumberingBy: settings.automaticAccountingDocumentNumberingBy,
				isConnectedWithForeignPersons: settings.isConnectedWithForeignPersons,
				isConnectedWithRelatedPersons: settings.isConnectedWithRelatedPersons,
				hasStore: settings.hasStore,
				accountingPeriod: settings.accountingMonthFrom ? settings.accountingMonthFrom > 1 : false,
				taxRegistration: cz && cz.taxRegistration ? cz.taxRegistration : null,
				authorization: cz && cz.authorization ? cz.authorization : null,
			}
		}

		return {
			accountingPeriod: false,
		}
	}

	getAccountingMonths = () => {
		const { t } = this.props

		return [
			{ value: 2, label: t('application.months.february') },
			{ value: 3, label: t('application.months.march') },
			{ value: 4, label: t('application.months.april') },
			{ value: 5, label: t('application.months.may') },
			{ value: 6, label: t('application.months.june') },
			{ value: 7, label: t('application.months.july') },
			{ value: 8, label: t('application.months.august') },
			{ value: 9, label: t('application.months.september') },
			{ value: 10, label: t('application.months.october') },
			{ value: 11, label: t('application.months.november') },
			{ value: 12, label: t('application.months.december') },
		]
	}

	saveSettings = () => {
		const oldSettings: ?OrganizationSettings = this.props.organizationSettings

		const oldCz: ?OrganizationCountrySpecificSettings =
			oldSettings && oldSettings.countrySpecificSettings && oldSettings.countrySpecificSettings.cz

		let { accountingPeriod, taxRegistration, authorization, ...restState } = this.state //eslint-disable-line

		if (oldSettings) {
			const newSettings: OrganizationSettings = Object.freeze({
				...oldSettings,
				...restState,
				countrySpecificSettings: {
					...oldSettings.countrySpecificSettings,
					cz: {
						...(oldCz || {}),
						taxRegistration: taxRegistration || undefined,
						authorization: authorization || undefined,
					},
				},
			})
			this.props.updateOrganizationSettings(oldSettings, newSettings)
		}
	}

	onAccountingMonthFromChange = (accountingMonthFrom: ?OptionType) => {
		this.setState(
			{
				accountingMonthFrom:
					accountingMonthFrom && notNullAndUndefined(accountingMonthFrom.value)
						? parseInt(accountingMonthFrom.value)
						: undefined,
			},
			this.saveSettings,
		)
	}

	onAccountingTypeChange = (accountingType: number) => {
		this.setState({ accountingType }, this.saveSettings)
	}

	onPayerTaxTypeChange = (
		payerTaxType: number,
		payerTaxTypeFrom?: Date,
		payerTaxTypeNote?: string,
		vatPeriod?: number,
	) => {
		let state = {
			payerTaxType,
			vatPeriod: payerTaxType === 1 ? 3 : vatPeriod || this.state.vatPeriod,
		}

		if (payerTaxTypeFrom) {
			state = { ...state, payerTaxTypeFrom: formatDateToIsoString(payerTaxTypeFrom) }
		}

		state = { ...state, payerTaxTypeNote }

		this.setState(state, this.saveSettings)
	}

	onPayerTaxTypeNoteChange = (event: SyntheticInputEvent<HTMLInputElement>, payerTaxTypeNote: ?string) => {
		if (payerTaxTypeNote !== null && payerTaxTypeNote !== undefined) {
			this.setState({ payerTaxTypeNote })
		}
	}

	onPayerTaxTypeFromChange = (ev: any, payerTaxTypeFrom: ?Date) => {
		this.setState(
			{
				payerTaxTypeFrom: (payerTaxTypeFrom && formatDateToIsoString(payerTaxTypeFrom)) || undefined,
			},
			this.saveSettings,
		)
	}

	onVatPeriodChange = (vatPeriod: number) => {
		this.setState({ vatPeriod }, this.saveSettings)
	}

	onEmployeeCountChange = (event: SyntheticInputEvent<HTMLInputElement>, value: ?number) => {
		this.setState({ employeeCount: parseIntOr(value) })
	}

	onHasForeignCurrencyOperationsChange = (hasForeignCurrencyOperations: boolean) => {
		this.setState({ hasForeignCurrencyOperations }, this.saveSettings)
	}

	onWorkflowTypeIdChange = (workflowTypeId: string) => {
		this.setState({ workflowTypeId: workflowTypeId }, this.saveSettings)
	}

	onFixedAssetsFromChange = (event: SyntheticInputEvent<HTMLInputElement>, value: ?number) => {
		this.setState({
			fixedAssetsFrom: parseFloatOr(value),
		})
	}

	onIsPairAccountingDocumentsCreationAllowedChange = (isPairAccountingDocumentsCreationAllowed: boolean) => {
		this.setState({ isPairAccountingDocumentsCreationAllowed }, this.saveSettings)
	}

	onAutomaticAccountingDocumentNumberingByChange = (automaticAccountingDocumentNumberingBy: number) => {
		this.setState({ automaticAccountingDocumentNumberingBy }, this.saveSettings)
	}

	onIsConnectedWithForeignPersonsChange = (isConnectedWithForeignPersons: boolean) => {
		this.setState({ isConnectedWithForeignPersons }, this.saveSettings)
	}

	onIsConnectedWithRelatedPersonsChange = (isConnectedWithRelatedPersons: boolean) => {
		this.setState({ isConnectedWithRelatedPersons }, this.saveSettings)
	}

	onHasStoreChange = (hasStore: boolean) => {
		this.setState({ hasStore }, this.saveSettings)
	}

	onAccountingPeriodChange = (accountingPeriod: OptionType) => {
		let { accountingMonthFrom } = this.state
		let next = undefined
		if (!accountingPeriod.value) {
			accountingMonthFrom = undefined
			next = this.saveSettings
		}
		const period = accountingPeriod && accountingPeriod.value ? accountingPeriod.value : undefined
		this.setState({ accountingPeriod: !!period, accountingMonthFrom }, next)
	}

	onActivationDateChange = (field: Field, isAuthorization?: boolean) => (isActive: ?boolean, startDate: ?Date) => {
		const newValue: ActivationWithStartDate = {
			isActive: isActive != null ? isActive : undefined,
			startDate: startDate ? formatDateToIsoString(startDate) : undefined,
		}

		if (isAuthorization) {
			const key: AuthorizationKey = ((field: any): AuthorizationKey)
			this.setState(
				{
					authorization: {
						...(this.state.authorization || {}),
						[key]: newValue,
					},
				},
				this.saveSettings,
			)
		} else {
			const key: TaxRegistrationKey = ((field: any): TaxRegistrationKey)
			this.setState(
				{
					taxRegistration: {
						...(this.state.taxRegistration || {}),
						[key]: newValue,
					},
				},
				this.saveSettings,
			)
		}
	}

	getTaxPaymentChangeMinDate = (): Date => {
		const { taxPaymentHistory } = this.props
		const taxPaymentHistoryLength: number = (taxPaymentHistory && taxPaymentHistory.length) || 0

		if (!taxPaymentHistory || !taxPaymentHistoryLength) return new Date(0)

		const dates: Array<Date> = taxPaymentHistory.map((item: OrganizationSettingsTaxPaymentHistory, index: number) => {
			return index === taxPaymentHistory.length - 1
				? new Date(0)
				: item.dateFrom
				? new Date(item.dateFrom)
				: new Date(0)
		})

		const lastDate = dates.reduce((a: Date, b: Date) => {
			return a > b ? a : b
		})

		return moment(lastDate)
			.add(1, 'day')
			.toDate()
	}

	renderTaxPaymentHistoryBody = () => {
		const { t, taxPaymentHistory, currentUserIsInternal } = this.props
		const taxPaymentHistoryLength: number = (taxPaymentHistory && taxPaymentHistory.length) || 0

		return (
			taxPaymentHistory &&
			taxPaymentHistoryLength &&
			taxPaymentHistory.map((item: OrganizationSettingsTaxPaymentHistory, index: number) => {
				const { payerTaxTypeFrom, payerTaxType } = this.state
				const isLast: boolean = index === taxPaymentHistoryLength - 1
				const payerTaxTypeVal: ?number = !isLast ? item.taxPaymentType : payerTaxType
				const isDisabled = !isLast || !currentUserIsInternal
				const vatPeriodText = item.vatPeriod && t(VAT_PERIOD_TRANSLATIONS[item.vatPeriod.toString()])

				return (
					<div key={index} className={styles.historyRow}>
						<div className={styles.historyColumnnState}>{t(TYPES_TRANSLATIONS[payerTaxTypeVal || 0])}</div>
						<div className={styles.historyColumnnVatPeriod}>
							<span>{vatPeriodText}</span>
						</div>
						<div className={styles.historyColumnnDate}>
							{isLast && !isDisabled ? (
								<DatePicker
									compact
									autoWidth
									value={payerTaxTypeFrom ? new Date(payerTaxTypeFrom) : null}
									onChange={this.onPayerTaxTypeFromChange}
									minDate={this.getTaxPaymentChangeMinDate()}
									autoTestId="payer-tax-type-from-date"
								/>
							) : item.dateFrom ? (
								formatToDateString(item.dateFrom)
							) : null}
						</div>
						<div className={styles.historyColumnnNote}>
							{isLast && !isDisabled ? (
								<TextField
									compact
									autoWidth
									value={this.state.payerTaxTypeNote}
									onChange={this.onPayerTaxTypeNoteChange}
									onBlur={this.saveSettings}
									hintText={this.props.t('settings.accountingSettings.payerTaxTypeNoteHint')}
									autoTestId="payer-pax-type-note"
									name="note"
								/>
							) : (
								item.note
							)}
						</div>
					</div>
				)
			})
		)
	}

	renderTaxPaymentHistory = () => {
		const { t, taxPaymentHistory } = this.props
		const hasTaxPaymentHistory: boolean = !!(taxPaymentHistory && taxPaymentHistory.length)

		return (
			hasTaxPaymentHistory && (
				<div className={styles.row}>
					<div className={styles.history}>
						<div className={styles.historyHeadline}>{t('settings.accountingSettings.history')}</div>
						<div className={styles.historyHeader}>
							<div className={styles.historyRow}>
								<div className={styles.historyColumnnState}>{t('settings.accountingSettings.state')}</div>
								<div className={styles.historyColumnnVatPeriod}>{t('settings.accountingSettings.vatPeriodCol')}</div>
								<div className={styles.historyColumnnDate}>{t('settings.accountingSettings.payerTaxTypeFrom')}</div>
								<div className={styles.historyColumnnNote}>{t('settings.accountingSettings.payerTaxTypeNote')}</div>
							</div>
						</div>
						{this.renderTaxPaymentHistoryBody()}
					</div>
				</div>
			)
		)
	}

	renderOss = () => {
		const { t, oss } = this.props
		const countries = oss && Object.keys(oss)
		if (!countries || !countries.length) return null

		return (
			<div className={styles.row}>
				<h3>{t('settings.accountingSettings.oss.headline')}</h3>
				<div className={styles.history}>
					{countries.map((country: string) => {
						const registrations = oss && oss[country] && oss[country].registrations
						if (!registrations || !registrations.length) return null

						return (
							<div key={country} className={styles.ossCountry}>
								<div className={styles.historyHeadline}>
									{this.props.countries ? getCountryNameById(this.props.countries, country) : country}
								</div>
								<div className={styles.historyHeader}>
									<div className={styles.historyRow}>
										<div className={styles.ossColumnnState}>{t('settings.accountingSettings.oss.state')}</div>
										<div className={styles.ossColumnDate}>{t('settings.accountingSettings.oss.from')}</div>
										<div className={styles.ossColumnDate}>{t('settings.accountingSettings.oss.to')}</div>
										<div className={styles.ossColumnnNote}>{t('settings.accountingSettings.oss.note')}</div>
									</div>
								</div>
								{registrations.map((registration: OssCountryRegistration, i: number) => {
									return (
										<div
											key={`${registration.registrationState ?? ''}-${registration.dateFrom ??
												''}-${registration.dateTo ?? ''}-${registration.note ?? ''}-${i}`}
											className={styles.historyRow}
										>
											<div className={styles.ossColumnnState}>
												{t(`settings.accountingSettings.oss.states.${registration.registrationState ?? ''}`)}
											</div>
											<div className={styles.ossColumnDate}>
												{registration.dateFrom && formatToDateString(registration.dateFrom)}
											</div>
											<div className={styles.ossColumnDate}>
												{registration.dateTo
													? formatToDateString(registration.dateTo)
													: t('settings.accountingSettings.oss.noDateTo')}
											</div>
											<div className={styles.ossColumnnNote}>{registration.note}</div>
										</div>
									)
								})}
							</div>
						)
					})}
				</div>
			</div>
		)
	}

	renderToggleDatePicker = (field: Field, activation: ?ActivationWithStartDate, isAuthorization?: boolean) => {
		const { t } = this.props
		return (
			<ToggleDatePicker
				isActive={activation && activation.isActive}
				value={activation && activation.startDate ? new Date(activation.startDate) : null}
				onChange={this.onActivationDateChange(field, isAuthorization)}
				labelText={t(`settings.accountingSettings.${field}`)}
			/>
		)
	}

	renderInternalOrganizationSettings = () => {
		const { t } = this.props

		const taxRegistration: OrganizationCountrySpecificSettingsCzTaxRegistration =
			this.state.taxRegistration || Object.freeze({})

		const authorization: OrganizationCountrySpecificSettingsCzAuthorization =
			this.state.authorization || Object.freeze({})

		return (
			<div>
				<br />
				<h3>{t('settings.accountingSettings.taxRegistration')}</h3>
				{this.renderToggleDatePicker('incomeTax', taxRegistration.incomeTax)}
				{this.renderToggleDatePicker('roadTax', taxRegistration.roadTax)}
				{this.renderToggleDatePicker('incomeTaxOnDependentActivity', taxRegistration.incomeTaxOnDependentActivity)}
				{this.renderToggleDatePicker(
					'naturalPersonIncomeWithholdingTax',
					taxRegistration.naturalPersonIncomeWithholdingTax,
				)}
				{this.renderToggleDatePicker('securingTheTax', taxRegistration.securingTheTax)}
				{this.renderToggleDatePicker('exciseDuty', taxRegistration.exciseDuty)}
				{this.renderToggleDatePicker('immovablePropertyTax', taxRegistration.immovablePropertyTax)}

				<h3>{t('settings.accountingSettings.authorization')}</h3>
				{this.renderToggleDatePicker('generalAuthorizationReceived', authorization.generalAuthorizationReceived, true)}
				{this.renderToggleDatePicker('otherAuthorizationReceived', authorization.otherAuthorizationReceived, true)}
			</div>
		)
	}

	render() {
		const { t, currentUserIsInternal } = this.props

		return (
			<div>
				<h3 className={styles.firstH3}>{t('settings.accountingSettings.basicSettings')}</h3>
				<div className={styles.row}>
					<div className={styles.columnOneHalf}>
						<NumberInput
							fullWidth
							labelText={t('settings.accountingSettings.fixedAssetsFrom')}
							value={this.state.fixedAssetsFrom}
							onChange={this.onFixedAssetsFromChange}
							onBlur={this.saveSettings}
							disabled={!currentUserIsInternal}
							minValue={0}
							name="fixed-from"
						/>
					</div>
					<div className={styles.columnOneHalf}>
						<NumberInput
							fullWidth
							labelText={t('settings.accountingSettings.employeeCount')}
							onChange={this.onEmployeeCountChange}
							onBlur={this.saveSettings}
							value={this.state.employeeCount}
							disabled={!currentUserIsInternal}
							minValue={0}
							name="employee-count"
						/>
					</div>
				</div>

				<div className={styles.row}>
					<div className={styles.columnOneHalf}>
						<SelectNext
							label={t('settings.accountingSettings.accountingPeriod')}
							autoTestId="settings-accounting-settings-period"
							onChange={this.onAccountingPeriodChange}
							value={this.state.accountingPeriod}
							disabled={!currentUserIsInternal}
							options={[
								{ value: false, label: t('settings.accountingSettings.calendarYear') },
								{ value: true, label: t('settings.accountingSettings.marketingYear') },
							]}
						/>
					</div>
					{this.state.accountingPeriod && (
						<div className={styles.columnOneHalf}>
							<SelectNext
								onChange={this.onAccountingMonthFromChange}
								value={this.state.accountingMonthFrom}
								label={t('settings.accountingSettings.accountingMonthFrom')}
								autoTestId="settings-accounting-settings-month-from"
								disabled={!currentUserIsInternal}
								options={this.getAccountingMonths()}
							/>
						</div>
					)}
				</div>

				<div className={styles.row}>
					<div className={styles.columnOneHalf}>
						<AccountingTypeSelector
							disabled={!currentUserIsInternal}
							onChange={this.onAccountingTypeChange}
							value={this.state.accountingType}
						/>
					</div>
				</div>

				<div className={styles.row}>
					<div className={styles.columnOneHalf}>
						<TabSwitches
							label={t('settings.accountingSettings.workflowTypeId')}
							autoTestId="settings-accounting-workflow-type-tabs"
							onChange={this.onWorkflowTypeIdChange}
							value={this.state.workflowTypeId}
							disabled={!currentUserIsInternal}
							fullWidth
						>
							<TabSwitch label={t('settings.accountingSettings.processImmediately')} value={'1'} />
							<TabSwitch label={t('settings.accountingSettings.needsApproval')} value={'2'} />
						</TabSwitches>
					</div>
				</div>

				<div className={styles.row}>
					<div className={styles.columnOneHalf}>
						<TabSwitches
							label={t('settings.accountingSettings.automaticAccountingDocumentNumberingBy')}
							autoTestId="settings-accounting-numbering-tabs"
							onChange={this.onAutomaticAccountingDocumentNumberingByChange}
							value={this.state.automaticAccountingDocumentNumberingBy}
							disabled={!currentUserIsInternal}
							fullWidth
						>
							<TabSwitch label={t('settings.accountingSettings.byIssueDate')} value={1} />
							<TabSwitch label={t('settings.accountingSettings.byBookingDate')} value={2} />
						</TabSwitches>
					</div>
				</div>

				<h3 className={styles.h3}>{t('settings.accountingSettings.vatSettings')}</h3>

				<div className={styles.row}>
					<PayerTaxTypeSelector
						disabled={!currentUserIsInternal}
						confirmable
						onChange={this.onPayerTaxTypeChange}
						value={this.state.payerTaxType}
						minDate={this.getTaxPaymentChangeMinDate()}
						vatPeriod={this.state.vatPeriod}
					/>
				</div>

				{this.state.payerTaxType !== 1 && (
					<div>
						<div className={styles.row}>
							<TabSwitches
								label={t('settings.accountingSettings.vatPeriod')}
								autoTestId="settings-accounting-vat-period"
								onChange={this.onVatPeriodChange}
								value={this.state.vatPeriod}
								disabled={!currentUserIsInternal}
							>
								<TabSwitch label={t('settings.accountingSettings.monthly')} value={1} />
								<TabSwitch label={t('settings.accountingSettings.quartely')} value={2} />
							</TabSwitches>
						</div>
					</div>
				)}
				{this.renderTaxPaymentHistory()}
				{this.renderOss()}
				{this.props.canSetInternalOrganizationSettings && this.renderInternalOrganizationSettings()}
			</div>
		)
	}
}

export default withOrganizationSettings(withTaxPaymentHistory(withTranslate(AccountingSettings)))
