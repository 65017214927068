//@flow
import React from 'react'
import type { BillingSourceEnum, SortedAccountingDocuments, SortedStatements, IdNumberPair } from 'types'
import BillingTableColumn from './billing-table-column'
import type { Props as WithTranslateProps } from 'wrappers/with-translate'
import withTranslate from 'wrappers/with-translate'

type Props = {|
	name: string,
	index: number,
	sortedDocuments: SortedAccountingDocuments,
	sortedStatements: SortedStatements,
	totalPrice: number,
	...WithTranslateProps,
|}

class OrganizationItem extends React.Component<Props> {
	sources = ['public_api', 'private_api_internal', 'private_api_standard']

	renderSourceTitle = (keyValue: string) => {
		const { t } = this.props
		switch (keyValue) {
			case 'public_api':
				return t('internal.billings.tableHeader.api')
			case 'private_api_internal':
				return t('internal.billings.tableHeader.accountant')
			case 'private_api_standard':
				return t('internal.billings.tableHeader.client')
			default:
				return ''
		}
	}

	getTooltipRenderer = (computedPrice: number, priceForItem: number, list: ?Array<IdNumberPair>) => {
		const { t } = this.props
		return () => {
			return (
				<div>
					<div>
						<div>
							{t('internal.billings.fullAmount')}: <span>{computedPrice},-</span>
						</div>
						<div>
							{t('internal.billings.itemPrice')}: <span>{priceForItem},-</span>
						</div>
						{list && (
							<ul style={{ marginTop: '10px' }}>
								{list.map((item: any, index: number) => (
									<li key={index}>{item.number}</li>
								))}
							</ul>
						)}
					</div>
				</div>
			)
		}
	}

	renderSortedDocuments = (source: string) => {
		const { sortedDocuments } = this.props
		const dataForSource = sortedDocuments[source]
		const issuedDocuments = dataForSource.issued
		const receivedDocuments = dataForSource.received
		const priceForDocument = sortedDocuments.priceForDocument || 0
		return [
			<BillingTableColumn key="1" colSpan={3} value={this.renderSourceTitle(source)} />,
			<BillingTableColumn
				key="2"
				value={receivedDocuments.all.count}
				renderTooltip={this.getTooltipRenderer(
					receivedDocuments.all.price,
					priceForDocument,
					receivedDocuments.all.list,
				)}
			/>,
			<BillingTableColumn
				key="3"
				value={receivedDocuments.changed.count}
				renderTooltip={this.getTooltipRenderer(
					receivedDocuments.changed.price,
					priceForDocument,
					receivedDocuments.changed.list,
				)}
			/>,
			<BillingTableColumn
				key="4"
				value={receivedDocuments.sanctioned.count}
				renderTooltip={this.getTooltipRenderer(
					receivedDocuments.sanctioned.price,
					priceForDocument,
					receivedDocuments.sanctioned.list,
				)}
			/>,
			<BillingTableColumn
				key="5"
				value={issuedDocuments.all.count}
				renderTooltip={this.getTooltipRenderer(issuedDocuments.all.price, priceForDocument, issuedDocuments.all.list)}
			/>,
			<BillingTableColumn
				key="6"
				value={issuedDocuments.changed.count}
				renderTooltip={this.getTooltipRenderer(
					issuedDocuments.changed.price,
					priceForDocument,
					issuedDocuments.changed.list,
				)}
			/>,
			<BillingTableColumn
				key="7"
				value={issuedDocuments.sanctioned.count}
				renderTooltip={this.getTooltipRenderer(
					issuedDocuments.sanctioned.price,
					priceForDocument,
					issuedDocuments.sanctioned.list,
				)}
			/>,
		]
	}

	renderSortedStatements = () => {
		const { sortedStatements } = this.props
		const bankingStatement = sortedStatements[1]
		const nonBankingStatements = sortedStatements[2]
		const priceForStatement = sortedStatements.priceForStatement || 0
		return [
			<BillingTableColumn
				key="1"
				rowSpan={3}
				value={bankingStatement.all.count}
				renderTooltip={this.getTooltipRenderer(bankingStatement.all.price, priceForStatement)}
			/>,
			<BillingTableColumn
				key="2"
				rowSpan={3}
				value={bankingStatement.changed.count}
				renderTooltip={this.getTooltipRenderer(bankingStatement.changed.price, priceForStatement)}
			/>,
			<BillingTableColumn
				key="3"
				rowSpan={3}
				value={nonBankingStatements.all.count}
				renderTooltip={this.getTooltipRenderer(nonBankingStatements.all.price, priceForStatement)}
			/>,
			<BillingTableColumn
				key="4"
				rowSpan={3}
				value={nonBankingStatements.changed.count}
				renderTooltip={this.getTooltipRenderer(nonBankingStatements.changed.price, priceForStatement)}
			/>,
		]
	}

	renderOrganizationSourceData = (): Array<any> => {
		const { name, sortedDocuments, sortedStatements, totalPrice } = this.props
		return this.sources.map((source: BillingSourceEnum, index: number) => {
			const isFirstRow = index === 0
			return (
				<tr key={index}>
					{isFirstRow && <BillingTableColumn rowSpan={3} value={name} />}
					{this.renderSortedDocuments(source)}
					{isFirstRow && this.renderSortedStatements()}

					<BillingTableColumn value={sortedDocuments[source].price} />
					{isFirstRow && <BillingTableColumn rowSpan={3} value={sortedStatements.price} />}
					{isFirstRow && <BillingTableColumn rowSpan={3} value={totalPrice} />}
				</tr>
			)
		})
	}

	render() {
		return <tbody>{this.renderOrganizationSourceData()}</tbody>
	}
}

export default withTranslate(OrganizationItem)
