/* @flow */
/** @jsx jsx */

// $FlowFixMe - there is useMemo
import { useMemo } from 'react'
import { jsx, ClassNames } from '@emotion/core'
import type { BankAccount } from 'types'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import { FilterSelectField } from 'modules/data-grid/components/data-grid-filter'
import BasicGridFilter from 'modules/data-grid/components/basic-grid-filter'
import { GRID_ID } from './constants'

type Props = {|
	...WithTranslateProps,
	bankAccounts: Array<BankAccount>,
|}

function UnmatchedPaymentsAdvancedFilter(props: Props) {
	const accountsOptions = useMemo(
		() =>
			props.bankAccounts.map((account: BankAccount) => {
				return {
					value: account.id,
					label: ((account && account.name) || '') + ' ' + ((account && account.currency) || ''),
				}
			}),
		[props.bankAccounts],
	)

	const actionOptions = useMemo(
		() => [
			{ value: true, label: props.t('dashboard.todo.unmatchedPayments.withActivity') },
			{ value: false, label: props.t('dashboard.todo.unmatchedPayments.withoutActivity') },
		],
		[props.t], // eslint-disable-line react-hooks/exhaustive-deps
	)

	const directionOptions = useMemo(
		() => [
			{ value: 0, label: props.t('cashRegister.direction.issued') },
			{ value: 1, label: props.t('cashRegister.direction.received') },
		],
		[props.t], // eslint-disable-line react-hooks/exhaustive-deps
	)

	return (
		<ClassNames>
			{({ css }: { css: (styles: Object) => string }) => (
				<BasicGridFilter
					name={GRID_ID}
					gridId={GRID_ID}
					dataType="unmatchedPayments"
					noFilterLabel={props.t('datagrid.implemented.unmatchedPayments.allPayments')}
					textFilterName="searchText"
					dateFilterName="paymentDate"
					containerClassName={css(styles.filters)}
				>
					<FilterSelectField
						name="direction"
						labelText={props.t('dashboard.todo.unmatchedPayments.filterPaymentDirection')}
						options={directionOptions}
						isClearable
						compact
						portal
					/>
					<FilterSelectField
						name="bankAccountId"
						labelText={props.t('dashboard.todo.unmatchedPayments.filterBankAccount')}
						options={accountsOptions}
						isClearable
						compact
						portal
					/>
					<FilterSelectField
						name="hasResponse"
						labelText={props.t('dashboard.todo.unmatchedPayments.filterActivity')}
						options={actionOptions}
						isClearable
						compact
						portal
					/>
				</BasicGridFilter>
			)}
		</ClassNames>
	)
}

const styles = {
	filters: {
		display: 'flex',
		justifyContent: 'space-between',
		gap: 20,
		'&>*': {
			flex: '1 1 33%',
		},
		'&:before, &:after': {
			content: 'none',
		},
	},
}

export default withTranslate(UnmatchedPaymentsAdvancedFilter)
