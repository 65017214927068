/* @flow */

import React, { Component } from 'react'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import { Loader, Separator, TabSwitch, TabSwitches, PopupSection, TriviLink, Button } from 'components'
import FileViewer from 'modules/file/containers/file-viewer'
import DeleteDialog from 'components/delete-dialog'
import TaskListAttachments from './task-list-attachments'
import type {
	Task,
	UserProfile,
	RelatedAccountingDocumentInfo,
	TaskAccountingDocument,
	DataGridId,
	Filter,
	WithRouterProps,
} from 'types'
import TaskListAdvancedFilter from './task-list-advanced-filter'
import DataGrid from 'modules/data-grid/containers/data-grid'
import { DataGrid as DataGridNext, type DataGridProps as DataGridNextProps } from 'modules/data-grid-next/containers'
import { Column } from 'modules/data-grid-next/components'
import TaskDone from 'components/svg-icons/trivi/task-done'
import { colors } from 'variables'
import { formatToDateString } from 'utils/formatters'
import { /*taskCreateRoute,*/ taskRoute } from 'modules/task/routing/routes'
import RelatedAccountingDocuments from 'modules/accounting-document/components/related-accounting-documents'
import type { TaskView } from 'modules/task/types'
import IconButton from 'components/icon-button'
import Delete from 'components/svg-icons/action/delete'
import Edit from 'components/svg-icons/image/edit'
import styles from './task-list.css'
import { withRouter } from 'react-router'

const TaskDataGrid: React$ComponentType<DataGridNextProps<'tasks'>> = DataGridNext

type AttachedFile = { fileId: string, name: string }

type Props = {|
	...WithTranslateProps,
	...WithRouterProps,
	currentUser: ?UserProfile,
	listView: TaskView,
	editTask: string => void,
	canSeeAllTasks: boolean,
	deleteTask: (taskId: string, defaultFilter?: Filter) => Promise<void>,
	changeListView: (view: TaskView) => void,
	markTaskAsDone: (task: Task, defaultFilter?: Filter) => Promise<void>,
|}

type State = {|
	anchorEl: ?EventTarget,
	fileForPreview: ?AttachedFile,
|}

const GRID_ID: DataGridId = 'tasks'
const FILTER_ID: string = 'tasks'

class CashRegisterPayments extends Component<Props, State> {
	state: State = {
		anchorEl: null,
		fileForPreview: null,
	}

	dataGrid: ?typeof DataGrid
	defaultFilter: Filter = this.getDefaultFilter(this.props)

	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		if (this.props.listView !== nextProps.listView || this.props.currentUser !== nextProps.currentUser) {
			this.defaultFilter = this.getDefaultFilter(nextProps)
		}
	}

	getDefaultFilter(props: Props): Filter {
		let defaultFilter = []
		if (props.listView !== 'all') {
			if (props.listView === 'assignedToMe') {
				defaultFilter.push({
					field: 'assigneeId',
					value: (props.currentUser && props.currentUser.id) || '',
				})
			} else {
				defaultFilter.push({
					field: 'authorId',
					value: (props.currentUser && props.currentUser.id) || '',
				})
			}
		}
		return defaultFilter
	}

	openPreview = (fileForPreview: AttachedFile) => () => {
		this.setState({ fileForPreview })
	}

	closeFilePreview = () => {
		this.setState({ fileForPreview: null })
	}

	handleOnRequestClose = () => {
		this.setState({
			anchorEl: null,
		})
	}

	handleDataGridAction = async (action: string, row: Task) => {
		switch (action) {
			case 'edit':
				row.id && this.props.editTask(row.id)
				break
			case 'delete':
				DeleteDialog().then(async () => {
					if (row.id) {
						await this.props.deleteTask(row.id, this.defaultFilter)
					}
				})
				break
		}
	}

	bindDataGrid = (dataGrid: ?typeof DataGrid) => (this.dataGrid = dataGrid)

	stateRenderer = (value: ?number, row: Task) => {
		const isDone = value === 2
		const color = isDone ? colors.green : value === 1 ? colors.orange : '#8A8A8A'
		const tooltip = isDone ? this.props.t('task.states.done') : this.props.t('task.markAsDone')
		const getClickHandler = () => () => {
			this.props.markTaskAsDone(row, this.defaultFilter)
		}

		return (
			<IconButton
				autoTestId="task-list-done"
				tooltip={tooltip}
				disabled={isDone}
				onClick={getClickHandler()}
				color={color}
				hoverColor={color}
			>
				<TaskDone disabled={isDone} />
			</IconButton>
		)
	}

	nameRenderer = (value: string, row: Task) => {
		return (
			<TriviLink routeFn={taskRoute} routeParams={[row.id || '']}>
				{value}
			</TriviLink>
		)
	}

	attachedAccountingDocumentRenderer = (value: ?Array<TaskAccountingDocument>) => {
		let items: ?Array<RelatedAccountingDocumentInfo> = undefined
		if (value) {
			items = value.map((i: TaskAccountingDocument) => {
				return { accountingDocumentId: i.id, accountingDocumentNo: i.number, organizationId: i.organizationId }
			})
		}
		return <RelatedAccountingDocuments items={items} showPopoverOnHover />
	}

	attachmentRenderer = (value: ?[AttachedFile]) => {
		return (
			value &&
			value.length > 0 && (
				<div className={styles.right}>
					<TaskListAttachments>
						{value.map((f: AttachedFile) => (
							<TriviLink key={f.fileId} style={style.item} onClick={this.openPreview(f)}>
								{f.name}
							</TriviLink>
						))}
					</TaskListAttachments>
				</div>
			)
		)
	}

	assigneeRenderer = (value: ?string) => {
		return <div className={styles.right}>{value || ''}</div>
	}

	dueDateRenderer = (value: ?string) => {
		return <div className={styles.right}>{value ? formatToDateString(value) : ''}</div>
	}

	handleTabClick = (view: TaskView) => {
		this.props.changeListView(view)
	}

	// onNewTask = () => {
	// 	this.props.history.push(taskCreateRoute())
	// }

	render() {
		if (!this.props.currentUser) {
			return <Loader visible />
		}

		const { t } = this.props

		// const createNewButton = (
		// 	<div>
		// 		<Button autoTestId="task-new" onClick={this.onNewTask} labelText={t('navigation.other.newTask')} />
		// 	</div>
		// )

		return (
			<div>
				<Separator
					center={
						<TabSwitches name="type" onChange={this.handleTabClick} value={this.props.listView}>
							<TabSwitch label={t('task.assignedToMe')} value="assignedToMe" />
							<TabSwitch label={t('task.createdByMe')} value="createdByMe" />
							{this.props.canSeeAllTasks && <TabSwitch label={t('task.all')} value="all" />}
						</TabSwitches>
					}
					//right={createNewButton}
				/>
				<TaskListAdvancedFilter
					gridId={GRID_ID}
					filterId={FILTER_ID}
					defaultFilter={this.defaultFilter}
					advanced={this.props.listView === 'all'}
				/>
				<TaskDataGrid
					id={GRID_ID}
					dataType="tasks"
					filterId={FILTER_ID}
					defaultFilter={this.defaultFilter}
					actions={[
						{
							name: t('grid.action.edit'),
							icon: <Edit />,
							type: 'edit',
						},
						{
							name: t('grid.action.delete'),
							icon: <Delete />,
							type: 'delete',
						},
					]}
					onAction={this.handleDataGridAction}
				>
					<Column width="6%" columnId="state" key="state" render={this.stateRenderer} sortable />
					<Column
						title={t('task.gridColumn.name')}
						width="40%"
						columnId="title"
						key="title"
						render={this.nameRenderer}
						sortable
					/>
					<Column
						title={t('task.gridColumn.attachedAccountingDocument')}
						columnId="attachedAccountingDocuments"
						key="attachedAccountingDocuments"
						className={styles.right}
						render={this.attachedAccountingDocumentRenderer}
					/>
					<Column
						title={t('task.gridColumn.attachment')}
						width="9%"
						columnId="attachedFiles"
						key="attachedFiles"
						className={styles.right}
						render={this.attachmentRenderer}
					/>
					<Column
						title={t('task.gridColumn.assignee')}
						width="9%"
						columnId="assigneeName"
						key="assigneeName"
						className={styles.right}
						render={this.assigneeRenderer}
						sortable
					/>
					<Column
						title={t('task.gridColumn.dueDate')}
						width="10%"
						columnId="dueDate"
						key="dueDate"
						className={styles.right}
						render={this.dueDateRenderer}
						sortable
					/>
				</TaskDataGrid>

				<PopupSection open={this.state.fileForPreview != null} onRequestClose={this.closeFilePreview}>
					<FileViewer
						stretch
						isInternal
						downloadable
						tokenDownload
						fileId={(this.state.fileForPreview && this.state.fileForPreview.fileId) || ''}
					/>
				</PopupSection>
			</div>
		)
	}
}

const style = {
	item: {
		display: 'block',
		fontSize: 16,
		lineHeight: '20px',
		padding: '2px 15px',
	},
}

export default withRouter(withTranslate(CashRegisterPayments))
