// @flow
/** @jsx jsx */

import { jsx } from '@emotion/core'
import { colors } from 'variables'

type Props = {|
	hovered?: boolean,
	style?: Object,
	last?: boolean,
	width?: number | string,
	children?: null | void | string | number | React$Element<any> | Array<React$Element<any>>,
|}

function TableHeaderCell(props: Props) {
	const styles = getStyles(props)
	return <th css={{ ...styles.root, ...props.style, width: props.width || 'auto' }}>{props.children}</th>
}

function getStyles(props: Props) {
	return {
		root: {
			fontSize: 12,
			lineHeight: '36px',
			paddingRight: props.last ? 0 : 30,
			textAlign: 'left',
			whiteSpace: 'nowrap',
			textOverflow: 'ellipsis',
			position: 'relative',
			overflow: 'hidden',
			color: colors.blackFaded60,
			textTransform: 'uppercase',
			fontWeight: 'normal',
			borderBottom: `1px solid ${colors.grayLight200}`,
		},
	}
}

export default TableHeaderCell
