// @flow

import { PureComponent, type Node } from 'react'
import type { Direction } from '../types'

export type Props = {|
	columnId: string,
	title?: Node,
	sortable?: boolean,
	defaultSortDirection?: Direction,
	width?: number | string,
	fixed?: boolean | 'left' | 'right',
	colSpan?: number,
	className?: string,
	headerStyle?: Object,
	infoTooltip?: string,
	render?: (
		value: any,
		row: any,
		index: number,
	) => Node | { children: Node, props: {| colspan?: number, rowspan?: number |} },
	onDirectionChange?: (columnId: string, direction: Direction) => void,
|}

export default class Column extends PureComponent<Props> {
	render() {
		return null
	}
}
