// @flow

import type { AccountingDocumentDirection, AccountingDocumentType, SearchFilter, Sequence, State } from 'types'
import FilterSequence from '../components/filter-sequence'
import { connect } from 'react-redux'
import { getAccountingDocumentSequences } from 'modules/accounting-document/selectors'
import { useSimplifiedNumbering } from 'modules/accounting-document/domain/accounting-document'

type StateProps = {|
	sequences: ?Array<Sequence>,
|}

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => {
	const simplifiedNumbering = useSimplifiedNumbering(ownProps.type, state)
	return {
		sequences: getAccountingDocumentSequences(
			state,
			ownProps.direction,
			ownProps.type,
			undefined,
			undefined,
			!!simplifiedNumbering,
		),
	}
}

type OwnProps = {|
	direction?: ?AccountingDocumentDirection,
	type?: ?AccountingDocumentType,
	cashRegisterId?: ?string,
	name: string,
	filter?: SearchFilter,
	onChange?: (filter: SearchFilter) => void,
	labelText?: string,
	className?: string,
	allowedAccountingDocumentTypes?: ?Array<number>,
|}

export default connect(mapStateToProps)(FilterSequence)
