/* @flow */

import { isMeCashRegisterOwner } from 'modules/cash-register/selectors'
import type { DataGridNextAction, Dispatch, Filter, State, DataGridId } from 'types'
import { isAccDocValid } from 'modules/accounting-document/domain/accounting-document'
import type { CashRegisterState } from '../reducers'
import { refreshGridData } from 'modules/data-grid-next/actions'
import { getDataGridCurrentSort } from 'modules/data-grid-next/selectors'
// Components and wrappers
import CashRegisterPayments from 'modules/cash-register/components/cash-register-payments'
import { connect } from 'react-redux'

type OwnProps = {|
	id: string,
	defaultFilter: Filter,
	gridId: DataGridId,
	filterId: string,
	showProcessingState?: ?boolean,
	internal: ?boolean,
|}

type StateProps = {|
	isMeCashRegisterOwner: boolean,
	currentSortColumnId: ?string,
|}

function mapStateToProps(state: State, ownProps: OwnProps): StateProps {
	const sort = getDataGridCurrentSort(state, ownProps.gridId)
	const cashRegister: ?CashRegisterState = state.cashRegister.cashRegister.find(
		(cashRegister: CashRegisterState): boolean => cashRegister.id === ownProps.id,
	)
	const cashRegisterData = cashRegister && cashRegister.data

	return {
		isMeCashRegisterOwner: (cashRegisterData && isMeCashRegisterOwner(state, cashRegisterData)) || false,
		currentSortColumnId: (sort && sort.columnId) || null,
	}
}

type DispatchProps = {|
	refreshGrid: (id: DataGridId, defaultFilter?: Filter, filterId: string) => Promise<void>,
	validateAccountingDocument: (accountingDocumentId: string, state: string) => Promise<boolean>,
|}

function mapDispatchToProps(dispatch: Dispatch<DataGridNextAction>): DispatchProps {
	return {
		validateAccountingDocument: async (accountingDocumentId: string, state: string): Promise<boolean> =>
			isAccDocValid(accountingDocumentId, state, dispatch, true),
		refreshGrid: async (id: DataGridId, defaultFilter?: Filter, filterId: string) => {
			await dispatch(refreshGridData('accountingDocuments', id, filterId, defaultFilter))
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(CashRegisterPayments)
