//@flow
import type { State, Dispatch, DashboardAction } from 'types'
import { connect } from 'react-redux'
import {
	getInvitationEmails,
	getInvitationSendingFlag,
	getInvitationButtonDisabledFlag,
	getInvitationInputDisabledFlag,
	getInvitationDialogOpenState,
} from '../../selectors'
import { getCurrentOrganizationId } from 'modules/user/selectors'
import { updateInvitationEmails, confirmInvitationEmails, switchInvitationDialog } from '../../actions'
import InvitationDialog from '../../components/invitation/invitation-dialog'

type StateToProps = {|
	organizationId: ?string,
	emails: Array<string>,
	sending: boolean,
	buttonDisabled: boolean,
	inputDisabled: boolean,
	open: boolean,
|}

const mapStateToProps = (state: State): StateToProps => {
	return {
		organizationId: getCurrentOrganizationId(state),
		emails: getInvitationEmails(state),
		sending: getInvitationSendingFlag(state),
		buttonDisabled: getInvitationButtonDisabledFlag(state),
		inputDisabled: getInvitationInputDisabledFlag(state),
		open: getInvitationDialogOpenState(state),
	}
}

type DispatchToProps = {|
	updateInvitationEmails: (emails: Array<string>) => void,
	confirmInvitationEmails: (emails: Array<string>, organizationId?: string) => Promise<any>,
	closeInvitationDialog: () => void,
|}

const mapDispatchToProps = (dispatch: Dispatch<DashboardAction>): DispatchToProps => {
	return {
		updateInvitationEmails: (emails: Array<string>) => {
			dispatch(updateInvitationEmails(emails))
		},
		confirmInvitationEmails: (emails: Array<string>, organizationId?: string) => {
			return dispatch(confirmInvitationEmails(organizationId || '', emails))
		},
		closeInvitationDialog: () => {
			dispatch(switchInvitationDialog(false))
		},
	}
}

const mergeProps = (stateProps: StateToProps, dispatchProps: DispatchToProps) => {
	return {
		...stateProps,
		...dispatchProps,
		confirmInvitationEmails: (emails: Array<string>) => {
			return dispatchProps.confirmInvitationEmails(emails, stateProps.organizationId || '')
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(InvitationDialog)
