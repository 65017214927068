/* @flow */

import { connect } from 'react-redux'
import type { Dispatch, State } from 'types'
import MobileAppInvitation from '../components/mobile-app-invitation'
import { isAlreadySeen, isFavoritesAlreadySeenLoaded } from '../../organization/selectors/favorites'
import { addFavoriteAlreadySeen, loadFavoriteAlreadySeen } from '../../organization/actions/favorites'
import withAutoload, { type AutoloadProps } from 'wrappers/with-autoload'
import { type OrganizationAction } from 'types'
import { getTermsFromState } from 'modules/user/selectors'

type StateProps = {|
	open: boolean,
|}

const mapStateToProps = (state: State): StateProps => {
	return {
		open:
			!getTermsFromState(state) &&
			isFavoritesAlreadySeenLoaded(state) &&
			false === isAlreadySeen(state, 'mobile_beta_popup'),
	}
}

type DispatchProps = {|
	...AutoloadProps,
	onClose: () => void,
|}

const mapDispatchToProps = (dispatch: Dispatch<OrganizationAction>): DispatchProps => {
	return {
		onClose: () => {
			dispatch(addFavoriteAlreadySeen('mobile_beta_popup'))
		},
		autoload: () => {
			dispatch(loadFavoriteAlreadySeen())
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(withAutoload(MobileAppInvitation))
