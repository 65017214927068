/** @jsx jsx */

import { jsx } from '@emotion/core'
import { Children, Component, cloneElement, createElement, isValidElement } from 'react'
import PropTypes from 'prop-types'
import VerticalTabTemplate from './VerticalTabTemplate'

class VerticalTabs extends Component {
	static propTypes = {
		children: PropTypes.node,
		initialSelectedIndex: PropTypes.number,
		onChange: PropTypes.func,
		value: PropTypes.any, // eslint-disable-line
	}

	static defaultProps = {
		initialSelectedIndex: 0,
		onChange: () => {},
	}

	state = { selectedIndex: 0 }

	UNSAFE_componentWillMount() {
		const valueLink = this.getValueLink(this.props)
		const initialIndex = this.props.initialSelectedIndex

		this.setState({
			selectedIndex:
				valueLink.value !== undefined
					? this.getSelectedIndex(this.props)
					: initialIndex < this.getTabCount()
					? initialIndex
					: 0,
		})
	}

	UNSAFE_componentWillReceiveProps(newProps) {
		const valueLink = this.getValueLink(newProps)
		const newState = {}

		if (valueLink.value !== undefined) {
			newState.selectedIndex = this.getSelectedIndex(newProps)
		}

		this.setState(newState)
	}

	getTabs(props = this.props) {
		const tabs = []

		Children.forEach(props.children, tab => {
			if (isValidElement(tab)) {
				tabs.push(tab)
			}
		})

		return tabs
	}

	getTabCount() {
		return this.getTabs().length
	}

	// Do not use outside of this component, it will be removed once valueLink is deprecated
	getValueLink(props) {
		return (
			props.valueLink || {
				value: props.value,
				requestChange: props.onChange,
			}
		)
	}

	getSelectedIndex(props) {
		const valueLink = this.getValueLink(props)
		let selectedIndex = -1

		this.getTabs(props).forEach((tab, index) => {
			if (valueLink.value === tab.props.value) {
				selectedIndex = index
			}
		})

		return selectedIndex
	}

	handleTabClick = (value, event, tab) => {
		const valueLink = this.getValueLink(this.props)
		const index = tab.props.index

		if ((valueLink.value && valueLink.value !== value) || this.state.selectedIndex !== index) {
			valueLink.requestChange(value, event, tab)
		}

		this.setState({ selectedIndex: index })

		if (tab.props.onActive) {
			tab.props.onActive(tab)
		}
	}

	getSelected(tab, index) {
		const valueLink = this.getValueLink(this.props)
		return valueLink.value ? valueLink.value === tab.props.value : this.state.selectedIndex === index
	}

	render() {
		const tabContent = []

		const tabs = this.getTabs().map((tab, index) => {
			tabContent.push(
				tab.props.children
					? createElement(
							VerticalTabTemplate,
							{
								key: index,
								selected: this.getSelected(tab, index),
							},
							tab.props.children,
					  )
					: undefined,
			)

			return cloneElement(tab, {
				key: index,
				index: index,
				selected: this.getSelected(tab, index),
				onClick: this.handleTabClick,
			})
		})

		return (
			<div>
				<div css={inlineStyles.tabItemContainer}>{tabs}</div>
				<div>{tabContent}</div>
			</div>
		)
	}
}

const inlineStyles = {
	tabItemContainer: {
		backgroundColor: 'transparent',
		display: 'block',
	},
}

export default VerticalTabs
