// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[3].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js!../../../variables/colors.css";
import ___CSS_LOADER_ICSS_IMPORT_1___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[3].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js!../../../variables/depths.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_1___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".E9E5qNLUtk8a6v5FYcsrh {\n\tposition: absolute;\n\ttop: 0px;\n\tright: 200px;\n\tbackground-color: " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["blue"] + ";\n\tz-index: " + ___CSS_LOADER_ICSS_IMPORT_1___.locals["popover"] + ";\n\toverflow: hidden;\n\tpadding-left: 10px;\n\tpadding-right: 10px;\n\tcolor: white;\n}\n\n.EgHQy5CXT8Q7r1HkhPeSO {\n\tcursor: pointer;\n}\n\n._1PNwWQl4YRwFpWQ8LN7tlU {\n\tbackground-color: " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["blue"] + ";\n\tfont-style: normal;\n\tfont-weight: 200;\n\tfont-size: 14px;\n\theight: 95%;\n\toverflow: scroll;\n}\n", "",{"version":3,"sources":["webpack://./app/permissions/test-tool/test-tool.css"],"names":[],"mappings":"AAGA;CACC,kBAAA;CACA,QAAA;CACA,YAAA;CACA,8DAAA;CACA,qDAAA;CACA,gBAAA;CACA,kBAAA;CACA,mBAAA;CACA,YAAA;AAWD;;AARA;CACC,eAAA;AAWD;;AARA;CACC,8DAAA;CACA,kBAAA;CACA,gBAAA;CACA,eAAA;CACA,WAAA;CACA,gBAAA;AAWD","sourcesContent":["@value blue from \"variables/colors.css\";\n@value popover from \"variables/depths.css\";\n\n.container {\n\tposition: absolute;\n\ttop: 0px;\n\tright: 200px;\n\tbackground-color: blue;\n\tz-index: popover;\n\toverflow: hidden;\n\tpadding-left: 10px;\n\tpadding-right: 10px;\n\tcolor: white;\n}\n\n.header {\n\tcursor: pointer;\n}\n\n.list {\n\tbackground-color: blue;\n\tfont-style: normal;\n\tfont-weight: 200;\n\tfont-size: 14px;\n\theight: 95%;\n\toverflow: scroll;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"blue": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["blue"] + "",
	"popover": "" + ___CSS_LOADER_ICSS_IMPORT_1___.locals["popover"] + "",
	"container": "E9E5qNLUtk8a6v5FYcsrh",
	"header": "EgHQy5CXT8Q7r1HkhPeSO",
	"list": "_1PNwWQl4YRwFpWQ8LN7tlU"
};
export default ___CSS_LOADER_EXPORT___;
