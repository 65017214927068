/* @flow */

import { connect } from 'react-redux'
import type { OrganizationContractState, OrganizationSettingsOpen, State } from 'types'
import { getOrganizationSettingsOpen } from '../../dashboard/selectors'
import ContractState from '../components/contract-state'

type StateProps = {|
	contractState: ?OrganizationContractState,
|}

const mapStateToProps = (state: State): StateProps => {
	const organizationSettings: ?OrganizationSettingsOpen = getOrganizationSettingsOpen(state)

	return {
		contractState: organizationSettings && organizationSettings.contractState,
	}
}

export default connect(mapStateToProps)(ContractState)
