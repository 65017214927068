/* @flow */

import { createXHR, loadBase64Data } from 'lib/apiHandler'
import type {
	Dispatch,
	Organization,
	OrganizationAction,
	OrganizationSettings,
	SettingsAction,
	State,
	OrganizationSettingsOpen,
	ActionFunction,
	OrganizationContractState,
	OrganizationContractStateGetParams,
	OrganizationContractStatePatchParams,
	OrganizationContractStateRequest,
	OrganizationNoteExtraction,
	OrganizationNote,
	ValidationError,
} from 'types'
import type { OrganizationLogoDeleteParams, OrganizationLogoPutParams } from 'modules/common/models/api-model'
import {
	organizationLogo as organizationLogoApi,
	organizationSettings as organizationSettingsApi,
	organizationSettingsOpen as organizationSettingsOpenApi,
	organizationNoteExtraction as organizationNoteExtractionApi,
} from 'modules/common/models/api-model'
import { getCurrentOrganization } from 'modules/organization/selectors'
import { OrganizationSettings_Organization } from 'types/convertor'
import diff from 'json-patch-gen'
import { setOrganizationSettingsOpen } from 'modules/user/actions'
import { beginTask, endTask } from 'utils/loader'
import { organizationContractState as organizationContractStateApi } from 'types'
import { canLoadOrganizationSettings } from 'permissions'

export function uploadOrganizationLogo(params: OrganizationLogoPutParams, body: File) {
	return async (dispatch: Dispatch<SettingsAction>) => {
		dispatch({
			type: 'START_UPLOAD_ORGANIZATION_LOGO',
			params,
		})

		let upload = (resolve: Function, reject: Function) => {
			try {
				let formData = new FormData()
				formData.append('attachment', body)

				let xhr = createXHR('{organizationId}/settings/logo', 'PUT')
				xhr.onload = (response: any) => {
					dispatch({
						type: 'FINISH_UPLOAD_ORGANIZATION_LOGO',
					})

					resolve(response)
				}

				xhr.send(formData)

				//await organizationLogoApi.put(params, body)
			} catch (serverError) {
				dispatch({
					type: 'FINISH_UPLOAD_ORGANIZATION_LOGO',
					serverError,
				})
				reject(null)
			}
		}
		return new Promise(upload)
	}
}
export function removeOrganizationLogo(params: OrganizationLogoDeleteParams) {
	return async (dispatch: Dispatch<SettingsAction>) => {
		dispatch({
			type: 'START_REMOVE_ORGANIZATION_LOGO',
			params,
		})

		try {
			await organizationLogoApi.delete(params)
			dispatch({
				type: 'FINISH_REMOVE_ORGANIZATION_LOGO',
			})
		} catch (serverError) {
			dispatch({
				type: 'FINISH_REMOVE_ORGANIZATION_LOGO',
				serverError,
			})
		}
	}
}

export function getOrganizationLogo() {
	return async (dispatch: Dispatch<SettingsAction>, getState: () => State) => {
		dispatch({
			type: 'START_GET_ORGANIZATION_LOGO',
		})

		const state: State = getState()
		let logo = state.settings.organizationLogo
		if (logo != null) {
			dispatch({
				type: 'FINISH_GET_ORGANIZATION_LOGO',
				response: logo,
			})
			return
		}

		loadBase64Data('{organizationId}/settings/logo')
			.then((response: string) => {
				dispatch({
					type: 'FINISH_GET_ORGANIZATION_LOGO',
					response,
				})
			})
			.catch((serverError: ValidationError) => {
				dispatch({
					type: 'FINISH_GET_ORGANIZATION_LOGO',
					serverError,
				})
			})
	}
}

export function loadOrganizationSettings() {
	return async (dispatch: Dispatch<SettingsAction>, getState: () => State) => {
		if (!canLoadOrganizationSettings(getState())) {
			return dispatch(loadOrganizationSettingsOpen())
		}

		dispatch({
			type: 'START_LOADING_ORGANIZATION_SETTINGS',
		})
		try {
			const organizationSettings: ?OrganizationSettings = await organizationSettingsApi.get({})
			dispatch({
				type: 'FINISH_LOADING_ORGANIZATION_SETTINGS',
				organizationSettings,
			})
		} catch (serverError) {
			dispatch({
				serverError,
				type: 'FINISH_LOADING_ORGANIZATION_SETTINGS',
				organizationSettings: null,
			})
		}
	}
}

export function loadOrganizationSettingsOpen() {
	return async (dispatch: Dispatch<SettingsAction>) => {
		dispatch({
			type: 'START_LOADING_ORGANIZATION_SETTINGS_OPEN',
		})
		try {
			const organizationSettingsOpen: ?OrganizationSettingsOpen = await organizationSettingsOpenApi.get({})
			dispatch({
				type: 'FINISH_LOADING_ORGANIZATION_SETTINGS_OPEN',
				organizationSettingsOpen,
			})
		} catch (serverError) {
			dispatch({
				serverError,
				type: 'FINISH_LOADING_ORGANIZATION_SETTINGS_OPEN',
				organizationSettingsOpen: null,
			})
		}
	}
}

export function loadOrganizationNoteExtraction() {
	return async (dispatch: Dispatch<SettingsAction>) => {
		dispatch({
			type: 'START_LOADING_ORGANIZATION_NOTE_EXTRACTION',
		})
		try {
			const organizationNote = await organizationNoteExtractionApi.get({})
			const note: OrganizationNote = {
				extraction: organizationNote ? organizationNote.text : undefined,
			}
			dispatch({
				type: 'FINISH_LOADING_ORGANIZATION_NOTE_EXTRACTION',
				note: note,
			})
		} catch (serverError) {
			dispatch({
				serverError,
				type: 'FINISH_LOADING_ORGANIZATION_NOTE_EXTRACTION',
				note: null,
			})
		}
	}
}

export function updateOrganizationNoteExtraction(note: OrganizationNoteExtraction) {
	return async (dispatch: Dispatch<SettingsAction>, getState: () => State) => {
		const state: State = getState()
		const currentOrganizationId: ?string = state.user.currentOrganizationId

		if (!currentOrganizationId) {
			return
		}

		const params: OrganizationContractStatePatchParams = {
			organizationId: currentOrganizationId,
		}

		dispatch({
			type: 'START_UPDATING_ORGANIZATION_NOTE_EXTRACTION',
		})
		try {
			const response: OrganizationNoteExtraction = await organizationNoteExtractionApi.put(params, note)
			const newNote: OrganizationNote = {
				extraction: response.text,
			}

			return dispatch({
				type: 'FINISH_UPDATING_ORGANIZATION_NOTE_EXTRACTION',
				note: newNote,
			})
		} catch (serverError) {
			return dispatch({
				serverError,
				type: 'FINISH_UPDATING_ORGANIZATION_NOTE_EXTRACTION',
				note: null,
			})
		}
	}
}

export function updateOrganizationSettings(
	oldOrganizationSettings: OrganizationSettings,
	newOrganizationSettings: OrganizationSettings,
) {
	return async (dispatch: Dispatch<SettingsAction | OrganizationAction>, getState: () => State) => {
		dispatch({
			type: 'START_UPDATING_ORGANIZATION_SETTINGS',
			newOrganizationSettings,
		})
		const settingsDiff = diff(oldOrganizationSettings, newOrganizationSettings)

		try {
			const organizationSettings: OrganizationSettings =
				settingsDiff.length > 0 ? await organizationSettingsApi.patch({}, settingsDiff) : oldOrganizationSettings

			dispatch({
				type: 'FINISH_UPDATING_ORGANIZATION_SETTINGS',
				newOrganizationSettings: organizationSettings,
			})

			const state: State = getState()
			const organization: ?Organization = getCurrentOrganization(state)
			if (organization) {
				const organizationFromSettings: Organization = OrganizationSettings_Organization(newOrganizationSettings)
				const newOrganization: Organization = Object.freeze({
					...organization,
					...organizationFromSettings,
				})
				dispatch({
					type: 'SET_ORGANIZATION',
					organization: newOrganization,
				})

				// After every update of organizationSettings, organizationSettingsOpen should be updated also

				const organizationSettingsOpen: OrganizationSettingsOpen = await organizationSettingsOpenApi.get({
					organizationId: organization.id,
				})

				dispatch(setOrganizationSettingsOpen(organizationSettingsOpen))
			}
		} catch (serverError) {
			dispatch({
				serverError,
				type: 'FINISH_UPDATING_ORGANIZATION_SETTINGS',
				oldOrganizationSettings,
			})
		}
	}
}

export function getOrganizationContractState(): ActionFunction<?SettingsAction> {
	return async (dispatch: Dispatch<SettingsAction>, getState: () => State) => {
		const state: State = getState()
		const currentOrganizationId: ?string = state.user.currentOrganizationId

		if (!currentOrganizationId) {
			return
		}

		const params: OrganizationContractStateGetParams = {
			organizationId: currentOrganizationId,
		}

		dispatch({
			type: 'START_LOADING_CONTRACT_STATE',
			params,
		})
		try {
			const response: OrganizationContractState = await organizationContractStateApi.get({
				organizationId: currentOrganizationId,
			})
			return dispatch({
				type: 'FINISH_LOADING_CONTRACT_STATE',
				contractState: response,
			})
		} catch (serverError) {
			return dispatch({
				type: 'FINISH_LOADING_CONTRACT_STATE',
				serverError,
			})
		}
	}
}

export function updateOrganizationContractState(
	contract: OrganizationContractStateRequest,
): ActionFunction<?SettingsAction> {
	return async (dispatch: Dispatch<SettingsAction>, getState: () => State) => {
		const state: State = getState()
		const currentOrganizationId: ?string = state.user.currentOrganizationId
		const loaderId = 'contract_state'
		beginTask(loaderId)

		if (!currentOrganizationId) {
			return
		}

		const params: OrganizationContractStatePatchParams = {
			organizationId: currentOrganizationId,
		}

		dispatch({
			type: 'START_PATCH_CONTRACT_STATE',
			params,
		})
		try {
			const response: OrganizationContractState = await organizationContractStateApi.patch(params, contract)
			return dispatch({
				type: 'FINISH_PATCH_CONTRACT_STATE',
				contractState: response,
			})
		} catch (serverError) {
			return dispatch({
				type: 'FINISH_PATCH_CONTRACT_STATE',
				serverError,
			})
		} finally {
			endTask(loaderId)
		}
	}
}
