// @flow

import memoize from 'memoize-one'
import type { State, VatRate } from 'types'
import { vatRateIsValid } from 'modules/accounting-document/domain/vat-rate'
import { EMPTY_ARRAY } from 'trivi-constants'

export function getAccountingDocumentVatRates(state: State, accountingDocumentId: string): null | Array<VatRate> {
	return state.accountingDocument.vatRate.accountingDocumentVatRates[accountingDocumentId] || null
}

export const getVatRates = (state: State, direction: ?number, date: ?string, vatCountryType: ?number): Array<VatRate> =>
	getValidVatRates(getAllVatRates(state), direction, date, vatCountryType)

export function getDefaultVatRates(state: State, direction: ?number): Array<VatRate> {
	const NOW = new Date().toDateString()
	return getVatRates(state, direction, NOW, 1).filter((vatRate: VatRate) => {
		return 'CZ' === vatRate.countryCode && (!vatRate.financialAccountPrefix || !vatRate.financialAccountPrefix.length)
	})
}

function getAllVatRates(state: State): Array<VatRate> {
	return state.accountingDocument.vatRate.vatRates || EMPTY_ARRAY
}

const getValidVatRates = memoize(
	(vatRates: Array<VatRate>, direction: ?number, date: ?string, vatCountryType: ?number) => {
		return vatRates.filter((vatRate: VatRate) => vatRateIsValid(vatRate, direction, date, vatCountryType))
	},
)
