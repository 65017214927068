//@flow
import type { AccountingDocumentDirection, CashRegister } from 'types'
import { CASH_REGISTER_FILTER_ID } from '../constants'

export function getCashierName(cashRegister: CashRegister): null | string {
	if (cashRegister && (cashRegister.ownerUserFirstname || cashRegister.ownerUserLastname)) {
		return `${cashRegister.ownerUserFirstname || ''} ${cashRegister.ownerUserLastname || ''}`.trim()
	}
	return null
}

export function getCashRegisterFilterName(direction?: ?AccountingDocumentDirection): string {
	if (!direction) return ''
	return `${CASH_REGISTER_FILTER_ID}/${direction}`
}
