//@flow
import type { CommonAction, Dispatch, State, CrpdphSearchResult } from 'types'

import { connect } from 'react-redux'
import { searchCRPDPH_DB } from '../actions'
import { type ComponentType, type ElementConfig, type Component } from 'react'

type StateProps = {|
	countryVariant: {
		cz: {
			crpdphResult: {
				[companyVATRegNo: string]: ?CrpdphSearchResult,
			},
		},
	},
|}

function mapStateToProps(state: State): StateProps {
	return {
		countryVariant: state.common.countryVariant,
	}
}

type DispatchProps = {|
	loadContantsFromCrpDphDb: (companyVATRegNo: string) => Promise<CommonAction>,
|}

function mapDispatchToProps(dispatch: Dispatch<CommonAction>): DispatchProps {
	return {
		loadContantsFromCrpDphDb: (companyVATRegNo: string) => {
			return dispatch(searchCRPDPH_DB(companyVATRegNo))
		},
	}
}

export type Props = DispatchProps & StateProps

export default function withCountryVariant<P: any, C: ComponentType<P>>(
	WrappedComponent: C,
): Class<Component<$Diff<ElementConfig<C>, Props>, any>> {
	return connect(mapStateToProps, mapDispatchToProps)(WrappedComponent)
}
