// @flow

import type { Direction } from '../types'

export default class Sort {
	columnId: string
	direction: Direction

	constructor(columnId: string, direction: Direction): void {
		this.columnId = columnId
		this.direction = direction
	}

	toString(): string {
		return `${this.columnId}-${this.direction}`
	}
}
