// @flow
/** @jsx jsx */

import { Component } from 'react'
import memoize from 'memoize-one'
import { jsx } from '@emotion/core'
import { colors } from 'variables'

type Props = {|
	className?: string,
	compact?: boolean,
	disabled?: boolean,
	index?: number,
	last?: boolean,
	label: string,
	selected?: boolean,
	width?: string,
	icon?: React$Element<*>,
	value: string | number,
	onClick?: (value: string | number) => void,
|}

class TabSwitch extends Component<Props> {
	handleClick = () => {
		!this.props.disabled && this.props.onClick && this.props.onClick(this.props.value)
	}

	getStyles = memoize(
		(selected: ?boolean, index: ?number, last: ?boolean, compact: ?boolean, disabled: ?boolean, width: ?string) => {
			const styles = {
				root: {
					display: 'block',
					color: colors.black,
					fontWeight: 'normal',
					fontSize: 12,
					width: width,
					textTransform: 'uppercase',
					padding: compact ? '0 15px' : '0 22px',
					border: `1px solid ${colors.gray400}`,
					background: disabled ? colors.gray100 : colors.white,
					boxShadow: `0 2px 0 0 ${colors.blackFaded8}`,
					borderRadius: 0,
					margin: '0 0 0 -1px',
					zIndex: 1,
					height: 30,
					textAlign: 'center',
					lineHeight: '30px',
					opacity: disabled ? 0.8 : 1,
					cursor: disabled ? 'default' : 'pointer',
					borderTopLeftRadius: 0,
					borderBottomLeftRadius: 0,
					borderTopRightRadius: 0,
					borderBottomRightRadius: 0,
					pointerEvents: disabled || selected ? 'none' : 'all',
					'&:hover': {
						background: colors.gray100,
					},
				},
				cell: {
					borderLeft: `1px solid ${colors.gray400}`,
				},
				button: {
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					justifyContent: 'center',
					height: 30,
					width: '100%',
				},
				flexWrapper: {
					display: 'flex',
				},
			}

			if (selected) {
				styles.root.color = colors.blue
				styles.root.border = `1px solid ${colors.blue}`
				styles.root.background = '#E4FBFF'
				styles.root.boxShadow = 'inset -4px 4px 3px 0 rgba(0,0,0,0.15)'
				styles.root.zIndex = 2
			}

			if (index == 0) {
				styles.root.borderTopLeftRadius = 15
				styles.root.borderBottomLeftRadius = 15
			}

			if (last) {
				styles.root.borderTopRightRadius = 15
				styles.root.borderBottomRightRadius = 15
			}

			return styles
		},
	)

	render() {
		const styles = this.getStyles(
			this.props.selected,
			this.props.index,
			this.props.last,
			this.props.compact,
			this.props.disabled,
			this.props.width,
		)

		return (
			<div css={styles.root} onClick={this.handleClick}>
				<div css={styles.flexWrapper}>
					<div css={styles.button}>
						{this.props.icon && <div>{this.props.icon}</div>}
						{this.props.label}
					</div>
				</div>
			</div>
		)
	}
}

export default TabSwitch
