/* @flow */
/** @jsx jsx */

import { jsx } from '@emotion/core'
import withTranslate, { type Props as WithTranslateProps } from 'wrappers/with-translate'
import splash from 'assets/images/overview-splashscreen-done.svg'

export type Props = {|
	...WithTranslateProps,
|}

function SplashScreen(props: Props) {
	return (
		<div css={styles.root}>
			<img css={styles.img} src={splash} />
			<div>
				<p css={styles.line1}>{props.t('dashboard.todo.splashScreen.everythingIsDoneLine1')}</p>
				<p>{props.t('dashboard.todo.splashScreen.everythingIsDoneLine2')}</p>
			</div>
		</div>
	)
}

const styles = {
	root: {
		textAlign: 'center',
		fontSize: 24,
	},
	img: {
		marginTop: 100,
		marginBottom: 50,
	},
	line1: {
		fontWeight: 'bold',
	},
}

export default withTranslate(SplashScreen)
