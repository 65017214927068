/* @flow */

import React, { PureComponent } from 'react'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import { colors } from 'variables'

type Props = {|
	...WithTranslateProps,
	daysToGo: number,
|}

class DueText extends PureComponent<Props> {
	render() {
		const { t, daysToGo } = this.props

		return (
			<span style={daysToGo > 0 ? styles.left : styles.ago}>
				{daysToGo > 0
					? t('utils.dateInterval.inDays', { count: daysToGo })
					: daysToGo < 0
					? t('accountingDocument.columns.daysAgo', { count: Math.abs(daysToGo) })
					: t('utils.dateInterval.today')}
			</span>
		)
	}
}

const styles = {
	left: {
		color: colors.blackFaded60,
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
	},
	ago: {
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
		color: '#FB0015',
		opacity: 0.6,
	},
}

export default withTranslate(DueText)
