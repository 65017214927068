//@flow
//TODO: [TK] use when api is ready

import type {
	AccountingDocumentDirection,
	AccountingDocumentType,
	Dispatch,
	Sequence,
	SequencesAction,
	State,
	SequencesData,
} from 'types'
import { Number_AccountingDocumentDirection, Number_AccountingDocumentType } from 'types/convertor'
import {
	allSequences as allSequencesApi,
	lastGeneratedSequenceNumber as lastGeneratedSequenceNumberApi,
	sequence as sequenceApi,
	sequences as sequencesApi,
	cashRegisterSequences as cashRegisterSequencesApi,
	cashRegisterSequence as cashRegisterSequenceApi,
	lastGeneratedCashRegisterSequenceNumber as lastGeneratedCashRegisterSequenceNumberApi,
} from 'modules/common/models/api-model'
import { getAccountingDocumentSequencesData } from 'modules/accounting-document/selectors'
import { _updateSequenceOnSequencesData } from 'modules/accounting-document/domain/sequences'
import { beginTask, endTask } from 'utils/loader'

export function loadSequences(includeDisabled: boolean, includeInvalidated: boolean) {
	return async (dispatch: Dispatch<SequencesAction>) => {
		const loaderId = 'sequences_all'
		beginTask(loaderId)
		dispatch({
			type: 'START_LOAD_SEQUENCES',
		})

		try {
			const sequences: Array<Sequence> = await allSequencesApi.get({ includeDisabled, includeInvalidated })

			return dispatch({
				type: 'FINISH_LOAD_SEQUENCES',
				sequences,
			})
		} catch (serverError) {
			return dispatch({
				type: 'FINISH_LOAD_SEQUENCES',
				serverError,
			})
		} finally {
			endTask(loaderId)
		}
	}
}

export function createSequence(sequence: Sequence, cashRegisterId?: ?string) {
	return async (dispatch: Dispatch<SequencesAction>, getState: () => State) => {
		const state: State = getState()
		const acType: ?AccountingDocumentType = Number_AccountingDocumentType(sequence.accountingDocumentType)
		const acDirection: ?AccountingDocumentDirection = Number_AccountingDocumentDirection(
			sequence.accountingDocumentDirection,
		)
		const oldSequences: SequencesData = getAccountingDocumentSequencesData(state)

		dispatch({
			type: 'START_CREATE_SEQUENCE',
		})
		try {
			let result: Sequence
			if (cashRegisterId) {
				result = await cashRegisterSequencesApi.post({ cashRegisterId }, sequence)
			} else {
				result = await sequencesApi.post(sequence)
			}

			const newSequences: SequencesData = _updateSequenceOnSequencesData(
				oldSequences,
				acDirection || 'unknown',
				acType || 'unknown',
				cashRegisterId || 'unknown',
				result,
				'create',
			)

			dispatch({
				type: 'FINISH_CREATE_SEQUENCE',
				newSequences,
			})
		} catch (serverError) {
			dispatch({
				type: 'FINISH_CREATE_SEQUENCE',
				serverError,
			})
		}
	}
}

export function loadSequence(sequenceId: string, cashRegisterId?: ?string) {
	return async (dispatch: Dispatch<SequencesAction>) => {
		const loaderId = 'sequence'
		beginTask(loaderId)
		dispatch({
			type: 'START_LOAD_SEQUENCE',
		})

		try {
			let sequence: ?Sequence = null

			if (cashRegisterId) {
				sequence = await cashRegisterSequenceApi.get({ sequenceId, cashRegisterId })
			} else {
				sequence = await sequenceApi.get({ sequenceId })
			}

			return dispatch({
				type: 'FINISH_LOAD_SEQUENCE',
				sequence,
			})
		} catch (serverError) {
			return dispatch({
				type: 'FINISH_LOAD_SEQUENCE',
				sequence: null,
				serverError,
			})
		} finally {
			endTask(loaderId)
		}
	}
}

export function editSequence(sequenceId: string, sequence: Sequence, cashRegisterId?: string) {
	return async (dispatch: Dispatch<SequencesAction>, getState: () => State) => {
		const state: State = getState()
		const acType: ?AccountingDocumentType = Number_AccountingDocumentType(sequence.accountingDocumentType)
		const acDirection: ?AccountingDocumentDirection = Number_AccountingDocumentDirection(
			sequence.accountingDocumentDirection,
		)
		const oldSequences: SequencesData = getAccountingDocumentSequencesData(state)
		const newSequences: SequencesData = _updateSequenceOnSequencesData(
			oldSequences,
			acDirection || 'unknown',
			acType || 'unknown',
			cashRegisterId || 'unknown',
			sequence,
			'update',
		)

		dispatch({
			type: 'START_EDIT_SEQUENCE',
			newSequences,
		})

		try {
			if (cashRegisterId) {
				await cashRegisterSequenceApi.put({ sequenceId, cashRegisterId }, sequence)
			} else {
				await sequenceApi.put({ sequenceId }, sequence)
			}

			dispatch({
				type: 'FINISH_EDIT_SEQUENCE',
				newSequences,
				oldSequences,
			})
		} catch (serverError) {
			dispatch({
				type: 'FINISH_EDIT_SEQUENCE',
				serverError,
				oldSequences,
				newSequences,
			})
		}
	}
}

export function removeSequence(sequence: Sequence, cashRegisterId?: string) {
	return async (dispatch: Dispatch<SequencesAction>, getState: () => State) => {
		const state: State = getState()
		const acType: ?AccountingDocumentType = Number_AccountingDocumentType(sequence.accountingDocumentType)
		const acDirection: ?AccountingDocumentDirection = Number_AccountingDocumentDirection(
			sequence.accountingDocumentDirection,
		)
		const oldSequences: SequencesData = getAccountingDocumentSequencesData(state)
		const newSequences: SequencesData = _updateSequenceOnSequencesData(
			oldSequences,
			acDirection || 'unknown',
			acType || 'unknown',
			cashRegisterId || 'unknown',
			sequence,
			'remove',
		)

		dispatch({
			type: 'START_REMOVE_SEQUENCE',
			newSequences,
		})

		try {
			if (cashRegisterId) {
				sequence.id && (await cashRegisterSequenceApi.delete({ sequenceId: sequence.id, cashRegisterId }))
			} else {
				sequence.id && (await sequenceApi.delete({ sequenceId: sequence.id }))
			}

			dispatch({
				type: 'FINISH_REMOVE_SEQUENCE',
				newSequences,
				oldSequences,
			})
		} catch (serverError) {
			dispatch({
				type: 'FINISH_REMOVE_SEQUENCE',
				serverError,
				oldSequences,
				newSequences,
			})
		}
	}
}

export function changeLastGeneratedNumber(
	sequence: Sequence,
	lastGeneratedNumber: number,
	year: number,
	cashRegisterId?: string,
) {
	return async (dispatch: Dispatch<SequencesAction>, getState: () => State) => {
		const state: State = getState()
		const acType: ?AccountingDocumentType = Number_AccountingDocumentType(sequence.accountingDocumentType)
		const acDirection: ?AccountingDocumentDirection = Number_AccountingDocumentDirection(
			sequence.accountingDocumentDirection,
		)
		let lastGeneratedNumbers = [...(sequence.lastGeneratedNumbers || [])]
		const index: number = lastGeneratedNumbers.findIndex((item: { year?: number }) => item.year === year)
		if (index !== -1) {
			lastGeneratedNumbers[index] = { year, lastGeneratedNumber }
		} else {
			lastGeneratedNumbers = [...lastGeneratedNumbers, { year, lastGeneratedNumber }]
		}
		const updatedSequence = {
			...sequence,
			lastGeneratedNumbers,
		}
		const oldSequences: SequencesData = getAccountingDocumentSequencesData(state)
		const newSequences: SequencesData = _updateSequenceOnSequencesData(
			oldSequences,
			acDirection || 'unknown',
			acType || 'unknown',
			cashRegisterId || 'unknown',
			updatedSequence,
			'update',
		)
		dispatch({
			type: 'START_CHANGING_LAST_GENERATED_NUMBER',
			newSequences,
		})
		try {
			if (cashRegisterId) {
				sequence.id &&
					(await lastGeneratedCashRegisterSequenceNumberApi.put(
						// $FlowFixMe -- špatný typ ve swageru. Všude jinde int64 a tady int32
						{ sequenceId: sequence.id, year, cashRegisterId },
						{ lastGeneratedNumber },
					))
			} else {
				sequence.id &&
					(await lastGeneratedSequenceNumberApi.put({ sequenceId: sequence.id, year }, { lastGeneratedNumber }))
			}

			dispatch({
				type: 'FINISH_CHANGING_LAST_GENERATED_NUMBER',
				newSequences,
				oldSequences,
			})
		} catch (serverError) {
			dispatch({
				type: 'FINISH_CHANGING_LAST_GENERATED_NUMBER',
				serverError,
				oldSequences,
				newSequences,
			})
		}
	}
}
