/* @flow */

import { connect } from 'react-redux'
import type { State } from 'types'
import ContactMe from '../components/contact-me'
import { userHasAccess } from 'permissions'

type StateProps = {|
	show: boolean,
|}
const mapStateToPros = (state: State): StateProps => {
	return {
		show: !userHasAccess(state, 'seeNonDemoFeatures'),
	}
}

export default connect(mapStateToPros)(ContactMe)
