/* @flow */

import { connect } from 'react-redux'
import { userHasAccess } from 'permissions'
import type {
	AccountingDocumentDirection,
	AccountingDocumentListAction,
	AccountingDocumentView,
	DataGridNextAction,
	Dispatch,
	State,
	CashRegisterAction,
	DataGridReduxAction,
	SearchFilter,
} from 'types'
import CashRegisterDirectionViewSwitcher from '../components/detail-direction-view-switcher'
import { changeCashRegisterListDirection } from '../actions'
import { changeFilter, clearGridFilterField } from '../../data-grid/actions'
import { getCashRegisterFilterName } from '../domain/cash-register'
import { changeAccountingDocumentsDirection } from '../../accounting-document/actions'

type StateProps = {|
	showTabs: boolean,
	view: AccountingDocumentView,
	stateFilter?: Object,
|}

function mapStateToProps(state: State): StateProps {
	return {
		view: state.accountingDocument.accountingDocumentList.view,
		showTabs:
			userHasAccess(state, 'seeIssuedAccountingDocuments') && userHasAccess(state, 'seeReceivedAccountingDocuments'),
		stateFilter: state.grid.filter,
	}
}

type DispatchProps = {|
	onChangeDirection: (direction: AccountingDocumentDirection, redirect?: boolean) => void,
|}

function mapDispatchToProps(
	dispatch: Dispatch<CashRegisterAction | AccountingDocumentListAction | DataGridNextAction | DataGridReduxAction>,
): DispatchProps {
	return {
		onChangeDirection: (direction: AccountingDocumentDirection, stateFilter: any) => {
			const name = getCashRegisterFilterName(direction)
			let oldFilter = []

			if (stateFilter[name]) {
				oldFilter = [...stateFilter[name]]
				if (oldFilter) {
					oldFilter = oldFilter.filter((value: SearchFilter) => value.field !== 'direction')
				}
			}
			switch (direction) {
				case 'issued':
					dispatch(clearGridFilterField(name, 'direction'))
					dispatch(changeFilter(name, [...oldFilter, { field: 'direction', value: '0' }]))
					break
				case 'received':
					dispatch(clearGridFilterField(name, 'direction'))
					dispatch(changeFilter(name, [...oldFilter, { field: 'direction', value: '1' }]))
					break
				case 'unknown':
					dispatch(clearGridFilterField(name, 'direction'))
					dispatch(changeFilter(name, [...oldFilter]))
					break
				default:
			}
			dispatch(changeAccountingDocumentsDirection(direction))
			dispatch(changeCashRegisterListDirection(direction))
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(CashRegisterDirectionViewSwitcher)
