import DeleteDialog from './delete-dialog'
import { createConfirmation } from 'react-confirm'
import i18n from 'i18next'

const confirm = createConfirmation(DeleteDialog)

export default (options = {}) => {
	const props = {
		title: i18n.t('dialogs.deleteQuestion'),
		cancelLabel: i18n.t('dialogs.dontDelete'),
		okLabel: i18n.t('dialogs.delete'),
		...options,
	}

	return confirm(props)
}
