import React from 'react'
import pure from 'recompose/pure'
import SvgIcon from 'components/SvgIcon'

let FilterExpand = props => (
	<SvgIcon {...props}>
		<g>
			<path d="M3,8 L21,8 L21,6 L3,6 L3,8 Z M6,13 L18.001,13 L18.001,11 L6,11 L6,13 Z M10,18 L14,18 L14,16 L10,16 L10,18 Z" />
		</g>
	</SvgIcon>
)
FilterExpand = pure(FilterExpand)

export default FilterExpand
