/* @flow */

import React from 'react'
import type { ScanFactoryField } from 'types'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import { getFieldTranslationInfo } from '../domain/field'

function DocumentFieldName({ field, t }: { field: ?ScanFactoryField, ...WithTranslateProps }) {
	if (!field) {
		return
	}

	const translationInfo = getFieldTranslationInfo(field.propertyName || '')

	return (
		<span>
			{translationInfo.group && t(translationInfo.group) + ': '} {t(translationInfo.key)}
		</span>
	)
}

export default withTranslate(DocumentFieldName)
