/* @flow */

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { includes } from 'lodash-es'
import type {
	AccountingDocumentReduxAction,
	AccountingDocumentListAction,
	Dispatch,
	InvoiceSideButton,
	State as StateType,
	WithRouterProps,
} from 'types'
import { accountingDocumentsRoute } from 'modules/accounting-document/routing/routes'
import {
	acknowledgeAccountingDocument,
	changeAccountingDocumentsDirection,
	createAccountingDocumentLineItem,
	loadAccountingDocument,
} from '../../actions'
import {
	getAccountingDocumentCurrency,
	getAccountingDocumentDirection,
	getAccountingDocumentExchRate,
	getAccountingDocumentExchRateDefault,
	getAccountingDocumentSadExchRate,
	getAccountingDocumentType,
	getAccountingDocumentVatExchRate,
} from '../../selectors'
import AddBankAccountContainer from '../invoice-elements-document/add-bank-account'
import BankAccountsContainer from '../invoice-elements-document/bank-accounts'
import ClassificationContainer from '../invoice-elements-document/classification'
import type { ClassificationType } from '../../components/invoice-elements/classification'
import ContactContainer from '../invoice-elements/contact/contact'
import type { FinishAcknowledgeAccountingDocumentAction } from '../../actions/accounting-document-action-types'
import HeaderDatesContainer from '../invoice-elements-document/header-dates'
//INVOICE ELEMENTS
import Name from 'modules/accounting-document/components/invoice-elements/name'
import UpperSettingsContainer from 'modules/accounting-document/containers/invoice-elements/upper-settings'
import NoteContainer from 'modules/accounting-document/containers/invoice-elements/note'
import HeaderNoContainer from '../invoice-elements-document/header-no'
import Invoice from '../../components/invoices/invoice'
import LineItemsContainer from '../invoice-elements-document/line-items'
import LineItemsHeaderContainer from 'modules/accounting-document/containers/invoice-elements/line-items-header'
import PaymentDetailsContainer from '../invoice-elements-document/payment-details'
import PaymentTypeContainer from '../invoice-elements-document/payment-type'
import { Button } from 'components'
import ScansContainer from '../invoice-elements-document/scans'
import SettingsPanelContainer from '../invoice-settings/settings-panel'
import TotalToPayContainer from '../invoice-elements-document/total-to-pay'
import TotalsContainer from '../invoice-elements-document/totals'
import VatRecapContainer from '../invoice-elements-document/vat-recap'
import NeedHelpContainer from 'modules/accounting-document/containers/invoice-elements-document/need-help'
import ExtractionNoteContainer from 'modules/accounting-document/containers/invoice-elements/extraction-note'
import Tracking from 'utils/tracking'
import {
	withTranslate,
	type WithTranslateProps,
	withOrganizationSettings,
	type WithOrganizationSettingsProps,
} from 'wrappers'
import {
	AccountingDocumentDirection_Number,
	AccountingDocumentType_Number,
	Number_AccountingDocumentDirection,
} from 'types/convertor'
import { passAutoTestId } from 'utils/tests/autotest'
import { getCurrentOrganizationDomesticCurrency, getOrganizationColor } from 'modules/organization/selectors'
import PopupSection from 'components/PopupSection'
import { getFormFieldErrorContexts } from 'helpers'
import { EXTRACTION_ERROR_MAIN_PATHS } from 'modules/accounting-document/constants'

const DIRECTION = 'received'
const TYPE = 'invoice'

type OwnProps = {|
	accountingDocumentId: string,
	internal: boolean,
	onEditClick: () => void,
	scansCount: number,
	view: 'default' | 'scans',
	readonly: boolean,
	onViewChange: (view: 'default' | 'scans') => void,
	storing: boolean,
	MoreActions: any,
	sideButtons?: Array<InvoiceSideButton>,
	isExtractionQueue: boolean,
	onCloseRedirectUrl?: string,
	onCloseCallback?: () => void,
|}

type State = {|
	classification: ClassificationType,
	isNoteOpen: boolean,
|}

type ComponentProps = {
	...OwnProps,
	...DispatchProps,
	...StateProps,
	...WithTranslateProps,
	...WithOrganizationSettingsProps,
	...WithRouterProps,
}

class EditScannedInvoice extends Component<ComponentProps, State> {
	state = {
		classification: this.props.classification,
		isNoteOpen: false,
	}

	UNSAFE_componentWillReceiveProps(nextProps: ComponentProps) {
		if (this.props.classification !== this.props.classification) {
			this.setState({ classification: nextProps.classification })
		}
	}

	componentDidMount() {
		!this.props.readonly && this.props.onViewChange('scans')
		if (!this.props.organizationSettings) {
			this.props.loadOrganizationSettings()
		}
	}

	toggleShowNote = () => {
		this.setState({ isNoteOpen: !this.state.isNoteOpen })
	}

	handleOnClassificationChange = (classification: ClassificationType) => {
		this.setState({ classification })
	}

	handleSaveClick = () => {
		this.props.save(this.state.classification)
	}

	onRequestClose = () => {
		this.props.onCloseCallback && this.props.onCloseCallback()
		if (this.props.onCloseRedirectUrl) {
			this.props.history.push(this.props.onCloseRedirectUrl)
		} else {
			this.props.history.length < 3 ? this.props.history.push(accountingDocumentsRoute()) : this.props.history.goBack()
		}
	}

	render() {
		const { t } = this.props
		const DOCUMENT_ID = this.props.accountingDocumentId
		const READONLY = this.props.readonly
		const formFieldErrorContexts = !READONLY
			? getFormFieldErrorContexts(EXTRACTION_ERROR_MAIN_PATHS, 'AccountingDocument', DOCUMENT_ID)
			: undefined

		const AddBankAccount =   <AddBankAccountContainer accountingDocumentId={DOCUMENT_ID} direction={DIRECTION} readonly hideWhenZeroAccounts /> //eslint-disable-line
		const HeaderNo =               <HeaderNoContainer accountingDocumentId={DOCUMENT_ID} direction={DIRECTION} type={TYPE} readonly /> //eslint-disable-line
		const LineItems =             <LineItemsContainer accountingDocumentId={DOCUMENT_ID} direction={DIRECTION} type={TYPE} readonly extracting /> //eslint-disable-line
		const LineItemsHeader = <LineItemsHeaderContainer accountingDocumentId={DOCUMENT_ID} readonly /> //eslint-disable-line
		const UpperSettings =     <UpperSettingsContainer accountingDocumentId={DOCUMENT_ID} direction={DIRECTION} readonly hideVatCountry={this.props.view !== 'scans'} view={this.props.view} showOrganizationInfo={this.props.isExtractionQueue} toggleShowNote={this.toggleShowNote} /> //eslint-disable-line
		const ContactBuyer =            <ContactContainer accountingDocumentId={DOCUMENT_ID} direction={DIRECTION} readonly /> //eslint-disable-line
		const BankAccounts =       <BankAccountsContainer accountingDocumentId={DOCUMENT_ID} direction={DIRECTION} readonly /> //eslint-disable-line
		const SettingsPanel =     <SettingsPanelContainer accountingDocumentId={DOCUMENT_ID} direction={DIRECTION} readonly showDuePeriodSettings formFieldErrorContext={formFieldErrorContexts && formFieldErrorContexts['upperSettings']} /> //eslint-disable-line
		const ContactSupplier =         <ContactContainer accountingDocumentId={DOCUMENT_ID} direction={DIRECTION} isMe /> //eslint-disable-line
		const Note =                       <NoteContainer accountingDocumentId={DOCUMENT_ID} readonly isOpen={this.state.isNoteOpen} /> //eslint-disable-line
		const HeaderDates =         <HeaderDatesContainer accountingDocumentId={DOCUMENT_ID} readonly /> //eslint-disable-line
		const PaymentDetails =   <PaymentDetailsContainer accountingDocumentId={DOCUMENT_ID} readonly /> //eslint-disable-line
		const VatRecap =               <VatRecapContainer accountingDocumentId={DOCUMENT_ID} readonly /> //eslint-disable-line
		const PaymentType =         <PaymentTypeContainer accountingDocumentId={DOCUMENT_ID} readonly /> //eslint-disable-line
		const Totals =                   <TotalsContainer accountingDocumentId={DOCUMENT_ID} readonly /> //eslint-disable-line
		const TotalsToPay =          <TotalToPayContainer accountingDocumentId={DOCUMENT_ID} /> //eslint-disable-line
		const Classification =   <ClassificationContainer accountingDocumentId={DOCUMENT_ID} classification={this.state.classification} formFieldErrorContext={formFieldErrorContexts && formFieldErrorContexts['cashRegisterId']} onChange={this.handleOnClassificationChange} /> //eslint-disable-line
		const Scans =                     <ScansContainer accountingDocumentId={DOCUMENT_ID} unclassified Classification={Classification} /> //eslint-disable-line
		const ExtractionNote =   <ExtractionNoteContainer accountingDocumentId={DOCUMENT_ID} /> //eslint-disable-line

		const ScansSaveButton = (
			<Button
				labelText={t('application.saveAndContinue')}
				autoTestId="scans-save-button"
				onClick={this.handleSaveClick}
				disabled={this.props.storing}
				primaryRounded
				{...passAutoTestId('application-save')}
			/>
		)

		const NeedHelp = this.props.internal ? <NeedHelpContainer accountingDocumentId={DOCUMENT_ID} /> : null
		const NameComponent = <Name title={t('navigation.accountingDocument.newInvoice')} />

		return (
			<PopupSection open onRequestClose={this.onRequestClose}>
				<div style={style.container}>
					<Invoice
						readonly
						disableAttachments
						enabledViews={{ scans: true }}
						view={this.props.view}
						color={this.props.color}
						exchRate={this.props.exchRate}
						exchRateDefault={this.props.exchRateDefault}
						vatExchRate={this.props.vatExchRate}
						sadExchRate={this.props.sadExchRate}
						currencyId={this.props.currencyId}
						domesticCurrencyId={this.props.domesticCurrencyId}
						contactSupplierTitle={t('invoice.contact.supplier')}
						contactBuyerTitle={t('invoice.contact.custommer')}
						scansCount={this.props.scansCount}
						Name={NameComponent}
						LineItems={LineItems}
						LineItemsHeader={LineItemsHeader}
						HeaderNo={HeaderNo}
						ContactSupplier={ContactSupplier}
						ContactBuyer={ContactBuyer}
						BankAccounts={BankAccounts}
						AddBankAccount={AddBankAccount}
						HeaderDates={HeaderDates}
						Attachments={null}
						StateChanger={null}
						PaymentDetails={PaymentDetails}
						PaymentType={PaymentType}
						TotalsToPay={TotalsToPay}
						VatRecap={VatRecap}
						Totals={Totals}
						SettingsPanel={SettingsPanel}
						Scans={Scans}
						ScansSaveButton={ScansSaveButton}
						storing={this.props.storing}
						sideButtons={this.props.sideButtons}
						UpperSettings={UpperSettings}
						Note={Note}
						NeedHelp={NeedHelp}
						onCloseRedirectUrl={this.props.onCloseRedirectUrl}
						showOrganizationInfo={this.props.isExtractionQueue}
						ExtractionNote={ExtractionNote}
					/>
				</div>
			</PopupSection>
		)
	}
}

type StateProps = {|
	classification: ClassificationType,
	color: ?string,
	exchRate: ?number,
	exchRateDefault: ?number,
	vatExchRate: ?number,
	sadExchRate: ?number,
	currencyId: ?string,
	domesticCurrencyId: ?string,
|}

const mapStateToProps = (state: StateType, ownProps: OwnProps): StateProps => {
	const type = getAccountingDocumentType(state, ownProps.accountingDocumentId) || 0
	const direction = getAccountingDocumentDirection(state, ownProps.accountingDocumentId) || 0

	return {
		classification: {
			type: type === 9 ? 0 : type,
			direction: direction === 2 ? 0 : direction,
		},
		color: getOrganizationColor(state),
		exchRate: getAccountingDocumentExchRate(state, ownProps.accountingDocumentId),
		exchRateDefault: getAccountingDocumentExchRateDefault(state, ownProps.accountingDocumentId),
		vatExchRate: getAccountingDocumentVatExchRate(state, ownProps.accountingDocumentId),
		sadExchRate: getAccountingDocumentSadExchRate(state, ownProps.accountingDocumentId),
		domesticCurrencyId: getCurrentOrganizationDomesticCurrency(state),
		currencyId: getAccountingDocumentCurrency(state, ownProps.accountingDocumentId),
	}
}

type DispatchProps = {|
	save: ClassificationType => void,
|}

const mapDispatchToProps = (
	dispatch: Dispatch<AccountingDocumentReduxAction | AccountingDocumentListAction>,
	ownProps: OwnProps,
): DispatchProps => {
	const addFirstLineTypes = [
		AccountingDocumentType_Number('invoice'),
		AccountingDocumentType_Number('advance'),
		AccountingDocumentType_Number('tax_advance'),
		AccountingDocumentType_Number('credit_note'),
		AccountingDocumentType_Number('simplified_invoice'),
	]

	return {
		save: (classification: ClassificationType) => {
			const addFirstLine = includes(addFirstLineTypes, classification.type)
			const direction = Number_AccountingDocumentDirection(classification.direction)

			direction && dispatch(changeAccountingDocumentsDirection(direction))

			dispatch(
				acknowledgeAccountingDocument(ownProps.accountingDocumentId, {
					direction: classification.direction,
					type: classification.type,
					cashRegisterId: classification.cashRegisterId,
					addFirstEmptyVendorBankAccount:
						addFirstLine && classification.direction === AccountingDocumentDirection_Number('received'),
					// Neposíláme addFirstEmptyLineItem pro vytvoření první prázdný řádky, protože backend nedoplňuje
					// branch a project podle preferred fieldů. Místo toho vytvoření řádky provádíme ručně.
					// (viz dál dispatch(createAccountingDocumentLineItem)...)
					//
					// addFirstEmptyLineItem: addFirstLine,
					setFirstSequence: true,
					setDefaultDatesToCurrent: true,
				}),
			).then((action: FinishAcknowledgeAccountingDocumentAction) => {
				if (action.success) {
					dispatch(loadAccountingDocument(ownProps.accountingDocumentId)).then(() => {
						if (addFirstLine) {
							// Ruční vytvoření první prázdné řádky místo parametru addFirstEmptyLineItem
							dispatch(createAccountingDocumentLineItem(ownProps.accountingDocumentId))
						}
					})
					Tracking.trackStateChangeToExtracting(ownProps.accountingDocumentId, ownProps.isExtractionQueue)
				}
			})
		},
	}
}

const style = {
	container: { padding: '0 100px 25px 25px' },
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(withRouter(withTranslate(withOrganizationSettings(EditScannedInvoice))))
