// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jo5bMp94LeojOCQFQwyhI {\n\tpadding: 30px;\n\ttext-align: center;\n}\n\n._2UG_Z-5OHozOJxB6nn4bJ_ {\n\tmargin-top: 25px;\n\ttext-align: right;\n}\n", "",{"version":3,"sources":["webpack://./components/image-cropper/image-cropper.css"],"names":[],"mappings":"AAAA;CACC,aAAA;CACA,kBAAA;AACD;;AAEA;CACC,gBAAA;CACA,iBAAA;AACD","sourcesContent":[".root {\n\tpadding: 30px;\n\ttext-align: center;\n}\n\n.actions {\n\tmargin-top: 25px;\n\ttext-align: right;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "jo5bMp94LeojOCQFQwyhI",
	"actions": "_2UG_Z-5OHozOJxB6nn4bJ_"
};
export default ___CSS_LOADER_EXPORT___;
