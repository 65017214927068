// @flow

import store from 'store'
import { lookup } from 'mime-types'
import type { NotificationType } from 'types'
import { getFileNameFromContentDisposition } from 'modules/file/domain'
import { MAX_REQUEST_WAITING_TIME } from 'trivi-constants'

/*
Wrapper around fetch API for AJAX comunications
*/

// function readStream(errorBody: ReadableStream): Promise<string> {
// 	const reader = errorBody.getReader()
// 	const decoder = new TextDecoder()
// 	return reader.read().then((result: any) => {
// 		const value = result.value ? decoder.decode(result.value) : ''
// 		reader.cancel('No more reading needed')
// 		return value
// 	})
// }

// process http response and returns promise based on result
async function processStatus(
	response: Response,
	refreshTokenFn: ?() => Promise<?string>,
	retryFn: (token: string) => Promise<{}>,
): Promise<{}> {
	if (response.ok) {
		if (response.headers.get('content-type') === 'application/octet-stream') {
			const fileName = parseContentDispositionFileName(response.headers)
			const mimeType = (fileName && lookup(fileName)) || 'application/octet-stream'
			const b = await response.blob()
			return new Blob([b], { type: mimeType })
		} else if (response.status === 204) {
			return {}
		} else {
			let json
			try {
				const text = await response.text()
				// Backend dirty fix to send int64 as strings
				json = JSON.parse(text.replace(/((?:"i|[a-z]I)d":)(\d+)/g, '$1"$2"'))
			} catch (e) {
				json = {}
			}
			return json
		}
	}

	if (response.status === 401) {
		if (refreshTokenFn && retryFn) {
			const token = await refreshTokenFn()
			if (token) {
				return retryFn(token)
			}
		}
		throw {
			code: 'fe0002',
			message: `Permission denied for: ${response.url}`,
			params: {
				url: response.url,
			},
		}
	}

	if (response.status && response.status && response.status.toString()[0] === '5') {
		window.Raven &&
			window.Raven.captureMessage('HTTP response 5xx', {
				level: 'error',
				extra: {
					url: response.url,
				},
			})
	}

	const body: string = await response.text()

	if (body) {
		let jsonError

		try {
			jsonError = JSON.parse(body)
		} catch (e) {
			window.Raven &&
				window.Raven.captureMessage('HTTP response HTML', {
					level: 'error',
					extra: {
						url: response.url,
					},
				})
		}

		if (jsonError) {
			throw jsonError
		}
	}
	throw {
		code: 'fe0001',
		message: 'Unknown server error',
	}
}

function parseContentDispositionFileName(headers: Headers): ?string {
	return getFileNameFromContentDisposition(headers.get('content-disposition'))
}

/* @returns {wrapped Promise} with .resolve/.reject methods */
// It goes against Promise concept to not have external access to .resolve/.reject methods,
// but provides more flexibility
function getWrappedPromise<Type>(): WrappedPromise<Type> {
	const wrappedPromise = {}
	const promise = new Promise((resolve: (Promise<Type> | Type) => void, reject: any => void) => {
		wrappedPromise.resolve = resolve
		wrappedPromise.reject = reject
	})
	// e.g. if you want to provide somewhere only promise, without .resolve/.reject methods
	wrappedPromise.promise = promise
	return wrappedPromise
}

function getWrappedFetch(requestParams: {
	url: string,
	method: string,
	headers: { [string]: string },
	body: ?(string | Uint8Array | FormData),
}): WrappedPromise<Response> {
	const wrappedPromise = getWrappedPromise()
	const request = new Request(requestParams.url, {
		method: requestParams.method,
		headers: requestParams.headers,
		body:
			requestParams.method.toLocaleLowerCase() !== 'get' && requestParams.body !== null
				? requestParams.body
				: undefined,
	})

	fetch(request) // calling original fetch() method
		.then(
			(response: Response) => {
				wrappedPromise.resolve(response)
			},
			() => {
				wrappedPromise.reject({
					code: 'fe0000',
					message: 'Connection error',
				})
			},
		)
	return wrappedPromise
}

function readFileAsArray(file: File) {
	return new Promise((resolve: (value: any) => void) => {
		const reader = new FileReader()
		reader.readAsArrayBuffer(file)
		reader.onload = function() {
			if (reader.result instanceof ArrayBuffer) {
				const arrayBuffer = reader.result
				const bytes = new Uint8Array(arrayBuffer)
				resolve(bytes)
			}
		}
	})
}

function stringToByteString(string: string): string {
	return unescape(encodeURIComponent(string))
}

async function generateMultipartArray(file: File, metadata: {}, boundery: string | number): Promise<Uint8Array> {
	const fileBytes = await readFileAsArray(file)
	const fileContentType = lookup(file.name)
	const newLine = '\r\n'
	const newLineDoubled = '\r\n\r\n'

	const jsonBoundery = [
		`--${boundery}`,
		newLine,
		'Content-Type: application/json; charset=utf-8',
		newLineDoubled,
		stringToByteString(JSON.stringify(metadata)),
		newLineDoubled,
	].join('')

	const fileBoundery = [`--${boundery}`, newLine, `Content-Type: ${fileContentType}`, newLineDoubled].join('')

	const closingBoundery = [newLine, `--${boundery}--`].join('')

	//construct bytearray
	let size = jsonBoundery.length + fileBoundery.length + fileBytes.byteLength + closingBoundery.length
	let uint8array = new Uint8Array(size)
	let i = 0

	// Append json boundery data
	for (; i < jsonBoundery.length; i++) {
		uint8array[i] = jsonBoundery.charCodeAt(i) & 0xff
	}

	// Append fileBoundery data
	for (let j = 0; j < fileBoundery.length; i++, j++) {
		uint8array[i] = fileBoundery.charCodeAt(j) & 0xff
	}

	// Append file bytes
	for (let j = 0; j < fileBytes.byteLength; i++, j++) {
		uint8array[i] = fileBytes[j]
	}

	// Append the closing boundery
	for (let j = 0; j < closingBoundery.length; i++, j++) {
		uint8array[i] = closingBoundery.charCodeAt(j) & 0xff
	}

	return uint8array
}

async function prepareRequest(params: Params, payload: Body) {
	const headers = params.headers
	if (headers && headers['Content-Type'] && headers['Content-Type'].indexOf('multipart/form-data') > -1) {
		let formData = new FormData()
		for (let name in payload) {
			formData.append(name, payload[name])
		}
		//workaround for fetch api multipart post bug
		delete headers['Content-Type']
		return {
			url: params.url,
			method: params.method,
			body: formData,
			headers: {
				...(headers || {}),
			},
		}
	} else if (
		payload['file'] &&
		headers &&
		headers['Content-Type'] &&
		headers['Content-Type'].indexOf('multipart') > -1
	) {
		const file = payload['file']
		const metadata = { ...payload }
		delete metadata['file']
		const boundary = Date.now()
		headers['Content-Type'] = `${headers['Content-Type'] || ''}; boundary="${boundary}"`
		const body = await generateMultipartArray(file, metadata, boundary)
		return {
			url: params.url,
			method: params.method,
			body: body,
			headers: {
				...(headers || {}),
			},
		}
	} else {
		return {
			url: params.url,
			method: params.method,
			body: JSON.stringify(payload),
			headers: {
				...(headers || {}),
				'Content-Type': 'application/json',
				Accept: 'application/json',
			},
		}
	}
}

type WrappedPromise<Type> = {
	resolve: (Promise<Type> | Type) => void,
	reject: any => void,
	promise: Promise<Type>,
}

type Params = {
	url: string,
	method: string,
	headers: ?{
		'Content-Type'?: string,
		[string]: string,
	},
}

type Body = {
	file?: File,
	[string]: string,
}

/**
 * Fetch JSON by url
 */
export async function fetchJson(
	params: Params,
	json: ?Body,
	timeout: number = MAX_REQUEST_WAITING_TIME,
	refreshTokenFn: ?() => Promise<?string> = undefined,
): Promise<{}> {
	let request = await prepareRequest(params, json || {})
	const wrappedFetch = getWrappedFetch(request)

	const response = await wrappedFetch.promise // getting clear promise from wrapped
	return processStatus(response, refreshTokenFn, (token: string) =>
		fetchJson({ ...params, headers: { ...params.headers, Authorization: token } }, json, timeout),
	)
}
