/* @flow */

import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { userHasAccess } from 'permissions'
import type { DataGridNextAction, Dispatch, PagerActions, PagerProps, State, Template, TemplatesAction } from 'types'
import { editAccountingDocumentRoute, editTemplateRoute } from 'modules/accounting-document/routing/routes'
import {
	createDraft,
	createNewTemplate,
	duplicateTemplate,
	loadTemplatesPage,
	loadTemplatesPageSize,
	removeTemplate,
} from 'modules/accounting-document/actions/templates'
import {
	getCurrentTemplates,
	getCurrentTemplatesLoading,
	isSomeTemplateCreating,
	getPagerProps,
} from 'modules/accounting-document/selectors/templates'
import TemplateGridNext from '../../components/template-grid-next'
import { refreshGridData } from '../../../data-grid-next/actions'

const GRID_TYPE = 'templates'
const GRID_ID = 'templates'
const FILTER_ID = 'templates'

type StateProps = {|
	templates: Array<?Template>,
	loading: boolean,
	isSomeTemplateCreating: boolean,
	pagerProps: PagerProps,
	createDocumentEnabled: boolean,
	removeEnabled: boolean,
|}
const mapStateToProps = (state: State): StateProps => {
	return {
		templates: getCurrentTemplates(state),
		loading: getCurrentTemplatesLoading(state),
		isSomeTemplateCreating: isSomeTemplateCreating(state),
		pagerProps: getPagerProps(state),
		createDocumentEnabled: userHasAccess(state, 'editIssuedAccountingDocuments'),
		removeEnabled: userHasAccess(state, 'removeAccountingDocumentTemplates'),
	}
}

type DispatchProps = {|
	onCreateTemplate: () => void,
	onOpenTemplate: (template: Template) => void,
	onCreateDocument: (template: Template) => void,
	onDuplicate: (template: Template) => Promise<void>,
	onRemove: (template: Template, index: number) => Promise<void>,
	pagerActions: PagerActions,
|}
const mapDispatchToProps = (dispatch: Dispatch<TemplatesAction | DataGridNextAction>): DispatchProps => {
	return {
		onCreateTemplate: () => {
			dispatch(createNewTemplate())
		},
		onOpenTemplate: (template: Template) => {
			template.id && dispatch(push(editTemplateRoute(template.id)))
		},
		onCreateDocument: (template: Template) => {
			dispatch(createDraft(template)).then((action: TemplatesAction) => {
				if (action.confirmedDocument && action.confirmedDocument.id) {
					//přesměrovat na editaci nově vytvořeného dokumentu
					dispatch(push(editAccountingDocumentRoute(action.confirmedDocument.id)))
				}
			})
		},
		onDuplicate: async (template: Template) => {
			await dispatch(duplicateTemplate(template))
			await dispatch(refreshGridData(GRID_TYPE, GRID_ID, FILTER_ID))
		},
		onRemove: async (template: Template) => {
			await dispatch(removeTemplate(template))
			await dispatch(refreshGridData(GRID_TYPE, GRID_ID, FILTER_ID))
		},
		pagerActions: {
			onPageChange: (page: number) => {
				dispatch(loadTemplatesPage(page))
			},
			onPageSizeChange: (pageSize: number) => {
				dispatch(loadTemplatesPageSize(pageSize))
			},
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(TemplateGridNext)
