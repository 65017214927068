/* @flow */
import type { AccessToken } from 'types'

const TEST_KEY = 'test'

const _localStorageAvailable: ?boolean = _isLocalStorageAvailable()

function _isLocalStorageAvailable(): boolean {
	const s = window.localStorage
	if (!s) {
		return false
	}
	try {
		s.setItem(TEST_KEY, '1')
		s.removeItem(TEST_KEY)
		return true
	} catch (error) {
		return false
	}
}

function set(key: string, value: any) {
	if (_localStorageAvailable) {
		localStorage.setItem(key, JSON.stringify(value))
	}
}

function get<T>(key: string): ?T {
	if (!_localStorageAvailable) {
		return null
	}

	const json = localStorage.getItem(key)
	try {
		return json ? JSON.parse(json) : null
	} catch (e) {
		return null
	}
}

function update(key: string, updater: (value: any) => any) {
	if (_localStorageAvailable) {
		set(key, updater(get(key)))
	}
}

function remove(key: string) {
	if (_localStorageAvailable) {
		localStorage.removeItem(key)
	}
}

function setAccessToken(token: ?AccessToken) {
	set('token', token)
}

function removeAccessToken() {
	remove('token')
}

export function getAccessToken(): ?AccessToken {
	return get('token')
}

export default {
	get,
	set,
	update,
	remove,
	setAccessToken,
	removeAccessToken,
}
