/* @flow */
/** @jsx jsx */

import { Component, Fragment } from 'react'
import { jsx } from '@emotion/core'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import type { SearchFilter } from 'types'
import { convertToDate, formatDateToIsoString } from 'utils/formatters'
import Cancel from 'components/svg-icons/navigation/cancel'
import DatePicker from 'components/date-picker'
import Paper from 'components/Paper'
import Tooltip from 'components/tooltip'
import IconButton from 'components/icon-button'
import { colors } from 'variables'

type Props = {|
	...WithTranslateProps,
	name: string,
	filter?: SearchFilter,
	className?: ?string,
	tooltipText: string,
	inline?: boolean,
	labelText?: ?string,
	onChange: (filter: SearchFilter) => void,
|}

class FilterDate extends Component<Props> {
	static defaultProps = {
		onChange: () => {},
	}

	handleDateFromPickerChange = (event: ?SyntheticInputEvent<HTMLInputElement>, value: ?Date) => {
		let filter: SearchFilter = {
			field: this.props.name,
		}

		if (this.props.filter && this.props.filter.valueTo) {
			filter.valueTo = this.props.filter.valueTo
		}

		filter.valueFrom = value ? formatDateToIsoString(value) : null

		if (filter.valueFrom && filter.valueTo && filter.valueFrom > filter.valueTo) {
			filter.valueFrom = filter.valueTo
		}

		this.props.onChange(filter)
	}

	handleDateToPickerChange = (event: ?SyntheticInputEvent<HTMLInputElement>, value: ?Date) => {
		let filter: SearchFilter = {
			field: this.props.name,
		}

		if (this.props.filter && this.props.filter.valueFrom) {
			filter.valueFrom = this.props.filter.valueFrom
		}

		filter.valueTo = value
			? formatDateToIsoString(new Date(value.getFullYear(), value.getMonth(), value.getDate() + 1, 0, 0, -1))
			: null

		if (filter.valueFrom && filter.valueTo && filter.valueFrom > filter.valueTo) {
			filter.valueTo = filter.valueFrom
		}

		this.props.onChange(filter)
	}

	hasFilter = () => {
		return this.props.filter && (this.props.filter.valueFrom || this.props.filter.valueTo)
	}

	onCancel = () => {
		this.props.onChange({
			field: this.props.name,
		})
	}

	render() {
		let { t, filter, inline } = this.props

		const valueFrom: string = filter && filter.valueFrom ? filter.valueFrom.toString() : ''
		const valueTo: string = filter && filter.valueTo ? filter.valueTo.toString() : ''
		const dateFrom: Date = convertToDate(valueFrom)
		const dateTo: Date = convertToDate(valueTo)

		const body = (
			<Tooltip label={this.props.tooltipText} inline={false}>
				<div css={inline ? { ...styles.dates, ...styles.datesInline } : styles.dates}>
					<div css={styles.from}>
						<DatePicker
							hintText={t('application.filter.dateFrom')}
							autoTestId="filter-from-date"
							onChange={this.handleDateFromPickerChange}
							value={isNaN(dateFrom.getTime()) ? null : dateFrom}
							maxDate={isNaN(dateTo.getTime()) ? null : dateTo}
							compact={!inline}
							fullWidth={!inline}
							autoWidth={inline}
							inline={inline}
						/>
					</div>
					<div css={styles.to}>
						<DatePicker
							hintText={t('application.filter.dateTo')}
							autoTestId="filter-to-date"
							onChange={this.handleDateToPickerChange}
							value={isNaN(dateTo.getTime()) ? null : dateTo}
							minDate={isNaN(dateFrom.getTime()) ? null : dateFrom}
							compact={!inline}
							fullWidth={!inline}
							autoWidth={inline}
							inline={inline}
						/>
					</div>
				</div>
			</Tooltip>
		)

		const clearFilterIcon = (
			<Fragment>
				{this.hasFilter() && (
					<div css={inline ? styles.inlineClearButton : styles.clearButton}>
						<IconButton
							autoTestId="grid-filter-date.cancel"
							size={24}
							onClick={this.onCancel}
							tooltip={this.props.t('application.filter.cancel')}
						>
							<Cancel size={14} />
						</IconButton>
					</div>
				)}
			</Fragment>
		)

		const component = inline ? (
			<Paper zDepth={3} style={styles.root} className={this.props.className}>
				{body}
				{clearFilterIcon}
			</Paper>
		) : (
			<div className={this.props.className}>
				<div css={styles.label}>
					{this.props.labelText && <label>{this.props.labelText}</label>}
					{clearFilterIcon}
				</div>
				{body}
			</div>
		)

		return component
	}
}

const styles = {
	root: {
		display: 'flex',
		alignItems: 'center',
		height: 40,
		lineHeight: '24px',
		padding: '8px 12px 8px 12px',
		borderRadius: '0 20px 20px 0',
		float: 'right',
	},
	dates: {
		display: 'flex',
		alignItems: 'center',
		position: 'relative',
	},
	datesInline: {},
	from: {
		marginRight: 3,
		flex: 1,
	},
	to: {
		marginLeft: 3,
		flex: 1,
	},
	label: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginTop: -3,
		paddingBottom: 3,
		userSelect: 'none',
		color: colors.black,
		whiteSpace: 'nowrap',
		fontSize: 14,
		lineHeight: '24px',
	},
	clearButton: {
		display: 'flex',
	},
	inlineClearButton: {
		display: 'flex',
	},
}

export default withTranslate(FilterDate)
