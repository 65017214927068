// @flow

import React, { PureComponent } from 'react'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import type { AccountingDocumentDirection, SearchFilter, Sequence, AccountingDocumentType } from 'types'
import SequencesSelector from 'modules/common/components/selectors/sequences-selector'

type Props = {|
	sequences: ?Array<Sequence>,
	name: string,
	filter?: SearchFilter,
	onChange?: (filter: SearchFilter) => void,
	labelText?: string,
	className?: string,
	allowedAccountingDocumentTypes?: ?Array<number | string>,
	direction?: ?AccountingDocumentDirection,
	type?: ?AccountingDocumentType,
	cashRegisterId?: ?string,
	...WithTranslateProps,
|}

class FilterSequence extends PureComponent<Props> {
	onChange = (value: ?number) => {
		let filter: SearchFilter = {
			field: this.props.name,
		}

		if (value !== undefined && value !== null) filter.value = value
		this.props.onChange && this.props.onChange(filter)
	}

	render() {
		return (
			<div className={this.props.className}>
				<SequencesSelector
					compact
					onChange={this.onChange}
					value={this.props.filter && this.props.filter.value ? parseInt(this.props.filter.value) : null}
					name={this.props.name}
					labelText={this.props.labelText}
					hintText={this.props.t('application.emptySelectField')}
					direction={this.props.direction}
					type={this.props.type}
					cashRegisterId={this.props.cashRegisterId}
				/>
			</div>
		)
	}
}

export default withTranslate(FilterSequence)
