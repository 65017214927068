/* @flow */

import React, { Component } from 'react'
import type { PortalLanguage } from 'types'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import { LocaleSelector } from 'components'
import Checkbox from 'components/checkbox'
import styles from './styles.css'
import UserLanguageSelectorNext from '../../../common/components/language-selector/user-language-selector-next'

type Props = {|
	...WithTranslateProps,
	locale: ?string,
	language: ?PortalLanguage,
	showConfirmation?: boolean,
	generateDailyDigestEmail?: boolean,
	showConfirmationCheckbox?: boolean,
	onLocaleChange: (userLocale: ?string) => void,
	onLanguageChange: (userLanguage: ?PortalLanguage) => void,
	onShowConfirmationChange: boolean => void,
	onGenerateDailyDigestEmailChange: boolean => void,
|}

class MainSettings extends Component<Props> {
	handleDocumentDialogCheck = (event: SyntheticInputEvent<HTMLInputElement>, value: boolean) => {
		this.props.onShowConfirmationChange(value)
	}

	handleGenerateDailyDigestEmailCheck = (event: SyntheticInputEvent<HTMLInputElement>, value: boolean) => {
		this.props.onGenerateDailyDigestEmailChange(value)
	}

	render() {
		const { t } = this.props

		return (
			<div>
				<div className={styles.contentWrapper}>
					<div className={styles.field}>
						<UserLanguageSelectorNext
							labelText={t('user.settings.appLanguage')}
							language={this.props.language}
							onLanguageChange={this.props.onLanguageChange}
						/>
					</div>

					<div className={styles.field}>
						<LocaleSelector
							labelText={t('user.settings.documentFormatting')}
							locale={this.props.locale}
							onLocaleChange={this.props.onLocaleChange}
						/>
					</div>

					{this.props.showConfirmationCheckbox && (
						<div className={styles.field}>
							<Checkbox
								label={t('user.settings.showConfirmDocumentDialog')}
								checked={!!this.props.showConfirmation}
								onCheck={this.handleDocumentDialogCheck}
							/>
						</div>
					)}
					{/* <div className={styles.field}>
						<Checkbox
							label={t('user.settings.generateDailyDigestEmail')}
							checked={!!this.props.generateDailyDigestEmail}
							onCheck={this.handleGenerateDailyDigestEmailCheck}
						/>
					</div> */}
				</div>
			</div>
		)
	}
}

export default withTranslate(MainSettings)
