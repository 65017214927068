/* @flow */
/** @jsx jsx */

import { jsx } from '@emotion/core'
import { withRouter } from 'react-router'
import {
	withTranslate,
	type WithTranslateProps,
	withAccountingDocumentActions,
	type WithAccountingDocumentActionsProps,
} from 'wrappers'
import type { AccountingDocumentConnection, WithRouterProps } from 'types'
import { showAccountingDocumentRoute } from 'modules/accounting-document/routing/routes'
import Clear from 'components/svg-icons/content/clear'
import TriviLink from 'modules/common/components/trivi-link'
import IconButton from 'components/icon-button'
import colors from 'variables/colors.css'
import confirmDialog from 'components/confirm-dialog'

type Props = {|
	...WithAccountingDocumentActionsProps,
	...WithTranslateProps,
	...WithRouterProps,
	readonly?: boolean,
	connection: ?Array<AccountingDocumentConnection>,
	onRemove: (connection: AccountingDocumentConnection) => void,
|}

function Connection(props: Props) {
	function onClick() {
		props.history.replace(props.location.pathname, {
			isAttachmensOpen: true,
		})
		props.history.push(showAccountingDocumentRoute(props.connection.connectedAccountingDocumentId), {
			link: props.t('navigation.accountingDocument.backToDocument'),
			goBack: true,
		})
	}

	function onRemove() {
		confirmDialog(props.t('attachments.removeConnectionWarning'), {
			okLabel: props.t('dialogs.understandOption'),
			cancelLabel: props.t('dialogs.goBackOption'),
		}).then(() => {
			props.onRemove(props.connection)
		})
	}

	return (
		<div css={style.item}>
			<TriviLink onClick={onClick}>
				{props.connection.connectedAccountingDocumentNo ||
					`${props.t('accountingDocument.columns.id')}: ${props.connection.connectedAccountingDocumentId}`}
			</TriviLink>
			{!props.readonly && (
				<div css={style.icon}>
					<IconButton
						size={30}
						onClick={onRemove}
						autoTestId="attachment-list-remove"
						tooltip={props.t('attachments.removeAttachment')}
					>
						<Clear size={14} />
					</IconButton>
				</div>
			)}
		</div>
	)
}

const style = {
	icon: {
		position: 'absolute',
		padding: 0,
		right: 0,
		top: 0,
	},
	item: {
		display: 'block',
		position: 'relative',
		borderWidth: 1,
		borderStyle: 'solid',
		borderColor: colors.grey300,
		backgroundColor: colors.white,
		borderRadius: 3,
		padding: '5px 30px 5px 10px',
		margin: '10px 0',
		lineHeight: '20px',
		minHeight: 30,
		fontSize: 14,
		color: colors.blue,
		overflow: 'hidden',
		wordBreak: 'break-all',
		'&:hover': {
			boxShadow: '3px 3px 0 0 rgba(0,0,0,0.08)',
		},
	},
}

export default withTranslate(withAccountingDocumentActions(withRouter(Connection)))
