/* @flow */
/** @jsx jsx */

import { Component } from 'react'
import { jsx } from '@emotion/core'
import { uniq } from 'lodash-es'
import {
	validate,
	withTranslate,
	withNotify,
	type WithTranslateProps,
	type WithNotifyProps,
	type FormValidationProps,
} from 'wrappers'
import type { ContactPerson, ContactAddress, Contact, AccountingDocumentContact } from 'types'
import EmailTemplateSelector from 'modules/common/components/selectors/email-template-selector'
import AccountingDocumentPreview from 'modules/common/containers/accounting-document-preview'
import { getSavedEmails, saveNewEmails } from '../../domain/send-email'
import Tracking from 'utils/tracking'
import MultiEmailInput from 'components/multi-email-input'
import PopupSection from 'components/PopupSection'
import Checkbox from 'components/checkbox'
import Paper from 'components/Paper'
import Button from 'components/button'
import { colors } from 'variables'

const ALLOWED_TYPES = [2]

type Props = {|
	open: boolean,
	contact?: ?AccountingDocumentContact,
	myEmail: ?string,
	addressBookContact: ?Contact,
	accountingDocumentId?: ?string,
	onClose: () => void,
	onSend: (emails: Array<string>, templateId: number) => void,
	loadContact: (contactId: string) => void,
	...WithTranslateProps,
	...WithNotifyProps,
	...FormValidationProps,
|}

type State = {
	emails: Array<string>,
	sendToMe: boolean,
	templateId: ?string,
}

class SendEmailDialog extends Component<Props, State> {
	options: null | Array<string> = null
	state: State = {
		emails: [],
		sendToMe: false,
		templateId: null,
	}

	updateEmails = (prevProps?: Props) => {
		const { contact, addressBookContact } = this.props
		let hasToLoadContact = contact && contact.contactId
		let updateContactEmail = contact && contact.email
		let updateEmailsFromAddressBook = !!addressBookContact

		if (prevProps) {
			const { contact: prevContact, addressBookContact: prevAddressBookContact } = prevProps
			hasToLoadContact = contact && contact !== prevContact && contact.contactId
			updateContactEmail = !prevContact && contact && contact.email
			updateEmailsFromAddressBook = !prevAddressBookContact && !!addressBookContact
		}

		const newEmails: Array<string> = []
		if (contact) {
			if (hasToLoadContact) {
				contact.contactId && this.props.loadContact(contact.contactId)
			}

			if (updateContactEmail) {
				contact && contact.email && newEmails.push(contact.email)
			}
		}
		if (updateEmailsFromAddressBook && addressBookContact) {
			addressBookContact.email && newEmails.push(addressBookContact.email)

			addressBookContact.persons &&
				addressBookContact.persons.forEach((p: ContactPerson) => {
					p.email && newEmails.push(p.email)
				})

			addressBookContact.addresses &&
				addressBookContact.addresses.forEach((a: ContactAddress) => {
					a.email && newEmails.push(a.email)
				})
		}

		if (newEmails.length > 0) {
			this.setState({
				emails: uniq([...newEmails, ...this.state.emails]),
			})
		}
	}

	componentDidMount() {
		this.updateEmails()
	}

	componentWillMount() {
		this.options = getSavedEmails()
	}

	componentDidUpdate(prevProps: Props) {
		this.updateEmails(prevProps)
		this.options = getSavedEmails()
	}

	onSend = () => {
		const emails: Array<string> = [...this.state.emails]
		if (this.state.sendToMe && this.props.myEmail) {
			emails.push(this.props.myEmail)
		}

		if (emails.length > 0 && this.state.templateId) {
			this.props.onSend(emails, parseInt(this.state.templateId))
			saveNewEmails(emails)
			this.clear()
			this.props.accountingDocumentId && Tracking.trackSentEmail(this.props.accountingDocumentId, emails.length)
		}
	}

	close = () => {
		this.clear(this.props.onClose)
		this.props.resetValidation()
	}

	clear(next?: () => void) {
		this.setState({ emails: [], sendToMe: false, templateId: null }, next)
	}

	handleEmailsChange = (emails: Array<string>) => {
		this.setState({ emails })
	}

	toggleSendToMe = () => {
		this.setState({ sendToMe: !this.state.sendToMe })
	}

	handleTemplateChange = (templateId: string) => {
		this.setState({ templateId })
	}

	getClientValidationErrors() {
		const errors = {}
		const { t } = this.props

		if (!this.state.emails || !this.state.emails.length) errors.emails = t('application.validation.mandatory')
		if (!this.state.templateId) errors.templateId = t('application.validation.mandatory')

		return errors
	}

	renderPreview() {
		return (
			<div css={styles.preview}>
				<div css={styles.previewHeadline}>{this.props.t('dialogs.sendInvoiceByMailPreview')}</div>
				<div css={styles.previewContainer}>
					<AccountingDocumentPreview accountingDocumentId={this.props.accountingDocumentId} />
				</div>
			</div>
		)
	}

	render() {
		if (!this.props.open) return null

		const { t } = this.props

		this.props.validateForm(this.getClientValidationErrors())

		return (
			<PopupSection open={this.props.open} onRequestClose={this.close}>
				<div css={styles.root}>
					{this.renderPreview()}
					<Paper rounded zDepth={6} style={styles.form}>
						<div css={styles.headline}>{t('dialogs.sendInvoiceByMailHeadline')}</div>
						<MultiEmailInput
							value={this.props.validationValue('emails', this.state.emails)}
							clientError={this.props.validationMessage('emails')}
							onChange={this.handleEmailsChange}
							options={this.options}
						/>
						<div css={styles.checkbox}>
							<Checkbox
								checked={this.state.sendToMe}
								onCheck={this.toggleSendToMe}
								label={t('dialogs.sendToMe')}
								inline
							/>
						</div>
						<EmailTemplateSelector
							value={this.props.validationValue('templateId', this.state.templateId)}
							onChange={this.handleTemplateChange}
							allowedTypes={ALLOWED_TYPES}
							defaultEmailType={2}
							clientError={this.props.validationMessage('templateId')}
							allowCreateTemplate
							autoSelect
						/>
						<div css={styles.button}>
							<Button
								autoTestId="send-email-dialog-send"
								labelText={t('dialogs.sendInvoiceButtonLabel')}
								onClick={this.props.validationSubmit(this.onSend)}
							/>
						</div>
					</Paper>
				</div>
			</PopupSection>
		)
	}
}

const styles = {
	root: {
		display: 'flex',
		padding: '20px 25px 40px 25px',
		overflow: 'hidden',
		flexWrap: 'wrap-reverse',
		justifyContent: 'center',
		alignItems: 'flex-end',
		alignContent: 'flex-start',
	},
	form: {
		marginTop: 68,
		flex: '1 1 500px',
		marginBottom: 35,
		marginLeft: 20,
		marginRight: 20,
		maxWidth: 885,
		minWidth: 500,
		padding: '40px 50px',
	},
	preview: {
		flex: '1 1 700px',
		marginTop: 10,
		marginLeft: 20,
		marginRight: 20,
		maxWidth: 885,
		minWidth: 640,
	},
	previewContainer: {
		background: colors.white,
		minHeight: 1150,
		position: 'relative',
	},
	previewHeadline: {
		fontSize: 24,
		marginTop: 15,
		marginBottom: 15,
	},
	invoice: {
		display: 'block',
		width: '100%',
		height: 1150,
	},
	headline: {
		color: colors.black,
		fontSize: 24,
		fontWeight: 'bold',
		lineHeight: '32px',
		marginBottom: 10,
	},
	checkbox: {
		margin: '8px 0 24px -4px',
	},
	button: {
		textAlign: 'center',
		margin: '30px 0 0 0',
	},
}

export default withTranslate(withNotify(validate('FINISH_SENDING_ACCOUNTING_DOCUMENT_EMAIL')(SendEmailDialog)))
