/* @flow */

import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import Helmet from 'react-helmet'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import DocumentButton from '../containers/accounting-document-list/document-button'
import type { WithRouterProps, AccountingDocumentDirection } from 'types'
import AccountingDocumentGrid from 'modules/accounting-document/containers/accounting-document-list/grid'
import DirectionViewSwitcher from 'modules/accounting-document/containers/accounting-document-list/accounting-document-direction-view-switcher'
import Separator from 'components/Separator'
import { Button } from 'components'
import { uploadScanRoute } from 'modules/accounting-document/routing/routes'
import styles from './accounting-document-list.css'
import CreateTemplateButton from '../containers/accounting-document-list/create-template-button'
import TemplateGridNext from '../containers/templates/template-grid-next'
type Props = {|
	...WithTranslateProps,
	...WithRouterProps,
|}

export type TabValue = AccountingDocumentDirection | 'templates'

function createAccountingDocumentList(tabValue: TabValue) {
	return class AccountingDocumentList extends Component<Props> {
		onUploadDocument = () => {
			this.props.history.push(uploadScanRoute())
		}
		render() {
			const { t } = this.props
			const isTemplates = 'templates' === tabValue

			const renderDocumentCreateBtn = (
				<DocumentButton autoTestId={'document-create'} label={this.props.t('accountingDocument.documentCreate')} />
			)
			const renderDocumentUploadBtn = (
				<div style={style.root} ref="root" autoTestId="document-upload">
					<Button
						autoTestId="document-upload"
						onClick={this.onUploadDocument}
						labelText={t('accountingDocument.documentUpload')}
					/>
				</div>
			)

			const displayedTabValue =
				(tabValue === 'templates' && (
					<div style={style.root} ref="root" autoTestId="template-direction-tab">
						<CreateTemplateButton />
					</div>
				)) ||
				(tabValue === 'issued' ? renderDocumentCreateBtn : renderDocumentUploadBtn)

			return (
				<div className={styles.root} id={'trivi-accounting-documents'}>
					<Helmet>
						<title>{t('accountingDocument.list.headline')}</title>
					</Helmet>
					<h1>{t('accountingDocument.list.headline')}</h1>
					<Separator center={<DirectionViewSwitcher direction={tabValue} />} right={displayedTabValue} />
					{!isTemplates && <AccountingDocumentGrid />}
					{isTemplates && (
						<div style={style.templates}>
							<TemplateGridNext />
						</div>
					)}
				</div>
			)
		}
	}
}
const style = {
	root: {
		position: 'absolute',
		transform: 'translateY(-50%)',
		right: 0,
	},
	templates: {
		marginTop: '15px',
	},
}

export const AccDocListFactory = {
	received: withRouter(withTranslate(createAccountingDocumentList('received'))),
	issued: withRouter(withTranslate(createAccountingDocumentList('issued'))),
	uploaded: withRouter(withTranslate(createAccountingDocumentList('unknown'))),
	templates: withRouter(withTranslate(createAccountingDocumentList('templates'))),
}
