/* @flow */

import styles from './side-buttons.css'
import React, { Component, type Node } from 'react'
import SideButton from './side-button'

export type InvoiceSideButton = {|
	onClick?: () => void,
	content: Node,
|}

export type Props = {|
	sideButtons?: Array<InvoiceSideButton>,
|}

export default class SideButtons extends Component<Props> {
	render() {
		const buttons =
			this.props.sideButtons &&
			this.props.sideButtons.map((sideButton: InvoiceSideButton, index: number) => {
				return (
					<SideButton key={index} onClick={sideButton.onClick}>
						{sideButton.content}
					</SideButton>
				)
			})
		return <div className={styles.sideButtonsWrapper}>{buttons}</div>
	}
}
