/* @flow */

import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { userHasAccess } from 'permissions'
import type {
	AccountingDocumentListAction,
	AccountingDocumentView,
	DataGridReduxAction,
	DataGridNextAction,
	Dispatch,
	State,
} from 'types'
import { accountingDocumentsRoute, accountingDocumentsTemplatesRoute } from 'modules/accounting-document/routing/routes'
import { changeAccountingDocumentsDirection, changeAccountingDocumentsView } from '../../actions'
import { getOrganizationAutomaticApproval } from 'modules/organization/selectors'
import DirectionViewSwitcher from '../../components/accounting-document-list/accounting-document-direction-view-switcher'
import { changeCurrentPage } from 'modules/data-grid-next/actions'
import type { TabValue } from '../../pages/accounting-document-list'

type StateProps = {|
	view: AccountingDocumentView,
	showIssuedAccountingDocuments: boolean,
	showReceivedAccountingDocuments: boolean,
	showUnknownAccountingDocuments: boolean,
	showTemplates: boolean,
	automaticApproval: boolean,
|}

function mapStateToProps(state: State): StateProps {
	return {
		view: state.accountingDocument.accountingDocumentList.view,
		showIssuedAccountingDocuments: userHasAccess(state, 'seeIssuedAccountingDocuments'),
		showReceivedAccountingDocuments: userHasAccess(state, 'seeReceivedAccountingDocuments'),
		showUnknownAccountingDocuments: userHasAccess(state, 'seeUnknownAccountingDocuments'),
		showTemplates: userHasAccess(state, 'seeAccountingDocumentTemplates'),
		automaticApproval: getOrganizationAutomaticApproval(state),
	}
}

type DispatchProps = {|
	onChangeDirection: (direction: TabValue, redirect?: boolean) => void,
	changeView: (view: AccountingDocumentView) => Promise<void>,
|}

function mapDispatchToProps(
	dispatch: Dispatch<AccountingDocumentListAction | DataGridNextAction | DataGridReduxAction>,
): DispatchProps {
	return {
		onChangeDirection: (direction: TabValue) => {
			if (direction === 'templates') {
				dispatch(push(accountingDocumentsTemplatesRoute()))
			} else {
				dispatch(changeAccountingDocumentsDirection(direction))
				dispatch(push(accountingDocumentsRoute(direction)))
			}
		},
		changeView: async (view: AccountingDocumentView): Promise<void> => {
			await dispatch(changeCurrentPage('accountingDocuments', 0))
			await dispatch(changeAccountingDocumentsView(view))
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(DirectionViewSwitcher)
