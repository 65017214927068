// @flow
/** @jsx jsx */

// $FlowFixMe - there is useState
import { useState, useEffect } from 'react'
import { jsx } from '@emotion/core'
import { EMPTY_OBJECT } from 'trivi-constants'
import type { PaymentReminder } from 'types'
import { validate, withTranslate, type WithTranslateProps, type FormValidationProps } from 'wrappers'
import EmailTemplateSelector from 'modules/common/components/selectors/email-template-selector'
import { findReminderById } from '../../domain/reminders'
import NumberInput from 'components/number-input'
import Dialog from 'components/dialog'
import Button from 'components/button'
import styles from '../settings.css'

const ALLOWED_TYPES = [1] // only reminders

type Props = {|
	...FormValidationProps,
	...WithTranslateProps,
	id?: ?string,
	open?: boolean,
	paymentReminders: ?Array<PaymentReminder>,
	loadPaymentReminders: () => void,
	createPaymentReminder: (paymentReminder: PaymentReminder) => void,
	updatePaymentReminder: (paymentReminder: PaymentReminder) => void,
	onRequestClose: () => void,
|}

function EditPaymentReminder(props: Props) {
	const creating = null == props.id
	const [reminder, setReminder] = useState<PaymentReminder>(
		creating ? EMPTY_OBJECT : findReminderById(props.id, props.paymentReminders),
	)

	useEffect(() => {
		if (!props.paymentReminders) {
			props.loadPaymentReminders()
		}
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		const found = findReminderById(props.id, props.paymentReminders)
		setReminder(found || EMPTY_OBJECT)
	}, [props.id, props.paymentReminders])

	if (!reminder) return null
	props.validateForm(getClientValidationErrors())

	function onSubmit() {
		if (!reminder) return
		const api = creating ? props.createPaymentReminder : props.updatePaymentReminder
		api(reminder)
		if (props.isFormValid()) {
			props.onRequestClose()
			props.resetValidation()
		}
	}

	function onEmailTemplateChange(emailTemplateId: string) {
		setReminder({ ...reminder, emailTemplateId })
	}

	function onExceededDaysChange(event: SyntheticInputEvent<HTMLInputElement>, exceededDays: ?number) {
		setReminder({ ...reminder, exceededDays })
	}

	function getClientValidationErrors() {
		const errors = {}
		if (!reminder.emailTemplateId) errors.emailTemplateId = props.t('application.validation.mandatory')
		if (null == reminder.exceededDays) errors.exceededDays = props.t('application.validation.mandatory')
		return errors
	}

	const label = creating ? props.t('settings.forms.addButton') : props.t('settings.forms.editButton')

	const actions = [
		<Button
			wide
			primary
			key="create"
			labelText={label}
			autoTestId="settings-reminders-dialog-create"
			onClick={props.validationSubmit(onSubmit)}
		/>,
		<Button
			wide
			secondary
			key="cancel"
			autoTestId="settings-reminders-dialog-cancel"
			labelText={props.t('settings.forms.cancelButton')}
			onClick={props.onRequestClose}
		/>,
	]

	return (
		<Dialog
			title={
				creating
					? props.t('settings.paymentReminders.createPaymentReminder')
					: props.t('settings.paymentReminders.editPaymentReminder')
			}
			actions={actions}
			open={!!props.open}
			autoTestId="payment-reminders-dialog"
		>
			<div className={styles.oneColumnDialog}>
				<div className={styles.row}>
					<NumberInput
						onChange={onExceededDaysChange}
						labelText={props.t('settings.paymentReminders.daysLabel')}
						value={props.validationValue('exceededDays', reminder.exceededDays)}
						clientError={props.validationMessage('exceededDays')}
						name="days"
						fullWidth
					/>
				</div>
				<div className={styles.row}>
					<EmailTemplateSelector
						value={props.validationValue('emailTemplateId', reminder.emailTemplateId)}
						clientError={props.validationMessage('emailTemplateId')}
						onChange={onEmailTemplateChange}
						allowedTypes={ALLOWED_TYPES}
					/>
				</div>
			</div>
		</Dialog>
	)
}

export default withTranslate(
	validate(['FINISH_CREATING_PAYMENT_REMINDER', 'FINISH_UPDATING_PAYMENT_REMINDER'])(EditPaymentReminder),
)
