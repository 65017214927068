/* @flow */

import { get } from 'lodash-es'
import type { WindowState } from '../types'
import storage from 'utils/local-storage'

// Returns new windows state based on current state, allows force new state
export function getWindowState(currentState: WindowState, newState?: WindowState): WindowState {
	if (newState) {
		// New window state is forced, use them
		if ('MINIMIZED' === currentState && 'MINIMIZED' === newState) {
			// If current and new window state is minimized => open window (toggle minimized window)
			return 'OPEN'
		}
		return newState
	} else {
		// New window state is not defined, toggle window state based on current state
		switch (currentState) {
			case 'CLOSED':
				return 'OPEN'
			case 'MINIMIZED':
				return 'OPEN'
			case 'OPEN':
				return 'CLOSED'
			default:
				return 'CLOSED'
		}
	}
}

export type WindowProps = {|
	window: WindowState,
	position: ?number,
|}

export function saveWindowProps(windowId: string, props: WindowProps) {
	storage.update('chat:windows', (windows: ?{ [string]: WindowProps }) => {
		windows = windows ? windows : {}

		if ('CLOSED' === props.window) {
			delete windows[windowId]
		} else {
			windows[windowId] = props
		}

		return windows
	})
}

export function getWindowProps(windowId: string): WindowProps {
	const state: { [string]: WindowProps } = storage.get('chat:windows') || {}
	return {
		window: get(state, [windowId, 'window'], 'CLOSED'),
		position: get(state, [windowId, 'position']),
	}
}

export function cleanWindowProps() {
	storage.set('chat:windows', {})
}
