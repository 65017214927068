//@flow
import type { Group } from 'types'

export function emptyGroup(): Group {
	return {
		id: undefined,
		name: undefined,
		permissions: undefined,
		isDefault: undefined,
	}
}
