//@flow
import { connect } from 'react-redux'
import { loadQrCode } from 'modules/common/actions'
import type { CardResponseParametersPayment, Dispatch } from 'types'
import PaymentsDetailDialog from '../components/dialogs/payments-detail-dialog'

type DispatchProps = {|
	loadQrCode: (payment: CardResponseParametersPayment) => Promise<*>,
|}

const mapDispatchToProps = (dispatch: Dispatch<*>): DispatchProps => {
	return {
		loadQrCode: async (payment: CardResponseParametersPayment) => {
			return dispatch(loadQrCode(payment))
		},
	}
}

export default connect(undefined, mapDispatchToProps)(PaymentsDetailDialog)
