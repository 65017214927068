/* @flow */

import type { Dispatch, AccountingDocumentContact, OrganizationAction, Country } from 'types'
import { createFavoriteItem, removeFavoriteItem, loadFavoriteSection } from './index'

export const ACCOUNTING_DOCUMENT_CONTACT_SECTION = 'accounting_document_contact'
export const FINANCIAL_ACCOUNT_SECTION = 'financial_account'
export const COUNTRY_SECTION = 'country'
export const ALREADY_SEEN_SECTION = 'already_seen'
export const LINE_ITEM_SECTION = 'line_item'

// Financial account
export function addFavoriteFinancialAccount(no: string) {
	return async (dispatch: Dispatch<OrganizationAction>): Promise<OrganizationAction> => {
		return dispatch(createFavoriteItem(FINANCIAL_ACCOUNT_SECTION, no + ''))
	}
}

export function removeFavoriteFinancialAccount(no: string) {
	return async (dispatch: Dispatch<OrganizationAction>): Promise<OrganizationAction> => {
		return dispatch(removeFavoriteItem(FINANCIAL_ACCOUNT_SECTION, no + ''))
	}
}

export function loadFavoriteFinancialAccounts() {
	return async (dispatch: Dispatch<OrganizationAction>): Promise<OrganizationAction> => {
		return dispatch(loadFavoriteSection(FINANCIAL_ACCOUNT_SECTION))
	}
}

// Line items
export function addFavoriteLineItem(id: string) {
	return async (dispatch: Dispatch<OrganizationAction>): Promise<OrganizationAction> => {
		return dispatch(createFavoriteItem(LINE_ITEM_SECTION, id + ''))
	}
}

export function removeFavoriteLineItem(id: string) {
	return async (dispatch: Dispatch<OrganizationAction>): Promise<OrganizationAction> => {
		return dispatch(removeFavoriteItem(LINE_ITEM_SECTION, id + ''))
	}
}

export function loadFavoriteLineItems() {
	return async (dispatch: Dispatch<OrganizationAction>): Promise<OrganizationAction> => {
		return dispatch(loadFavoriteSection(LINE_ITEM_SECTION))
	}
}

// AccountingDocumentContact
export function addFavoriteAccountingDocumentContact(contact: AccountingDocumentContact) {
	return async (dispatch: Dispatch<OrganizationAction>): Promise<OrganizationAction> => {
		return dispatch(createFavoriteItem(ACCOUNTING_DOCUMENT_CONTACT_SECTION, (contact.contactId || '') + ''))
	}
}

export function removeFavoriteAccountingDocumentContact(contact: AccountingDocumentContact) {
	return async (dispatch: Dispatch<OrganizationAction>): Promise<OrganizationAction> => {
		return dispatch(removeFavoriteItem(ACCOUNTING_DOCUMENT_CONTACT_SECTION, (contact.contactId || '') + ''))
	}
}

export function loadFavoriteAccountingDocumentContact() {
	return async (dispatch: Dispatch<OrganizationAction>): Promise<OrganizationAction> => {
		return dispatch(loadFavoriteSection(ACCOUNTING_DOCUMENT_CONTACT_SECTION))
	}
}

// COUNTRIES
export function addFavoriteCountry(country: Country) {
	return async (dispatch: Dispatch<OrganizationAction>): Promise<OrganizationAction> => {
		return dispatch(createFavoriteItem(COUNTRY_SECTION, (country.id || '') + ''))
	}
}

export function removeFavoriteCountry(country: Country) {
	return async (dispatch: Dispatch<OrganizationAction>): Promise<OrganizationAction> => {
		return dispatch(removeFavoriteItem(COUNTRY_SECTION, (country.id || '') + ''))
	}
}

export function loadFavoriteCountries() {
	return async (dispatch: Dispatch<OrganizationAction>): Promise<OrganizationAction> => {
		return dispatch(loadFavoriteSection(COUNTRY_SECTION))
	}
}

// Mobile app invitation
export function addFavoriteAlreadySeen(seen: string) {
	return async (dispatch: Dispatch<OrganizationAction>): Promise<OrganizationAction> => {
		return dispatch(createFavoriteItem(ALREADY_SEEN_SECTION, seen))
	}
}

export function removeFavoriteAlreadySeen(seen: string) {
	return async (dispatch: Dispatch<OrganizationAction>): Promise<OrganizationAction> => {
		return dispatch(removeFavoriteItem(ALREADY_SEEN_SECTION, seen))
	}
}

export function loadFavoriteAlreadySeen() {
	return async (dispatch: Dispatch<OrganizationAction>): Promise<OrganizationAction> => {
		return dispatch(loadFavoriteSection(ALREADY_SEEN_SECTION))
	}
}
