/* @flow */

import type {
	AccountingDocumentDirection,
	InternalDashboardAction,
	DataGridReduxAction,
	AccountingDocumentListAction,
	Dispatch,
	Filter,
	CashRegisterAction,
} from 'types'
import { organizationAccountingDocumentsRoute } from 'modules/accounting-document/routing/routes'
import { changeFilter, clearAllGridFilters } from 'modules/data-grid/actions'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { withRouter } from 'react-router'
import { getAccDocFilterName } from 'modules/accounting-document/domain/accounting-document'
import OrganizationStatisticsGrid from '../components/statistics-grid/organization-statistics-grid'
import { bankAccountStatementsRoute } from 'modules/bank/routing/routes'
import { cashRegisterRoute } from '../../cash-register/routing/routes'
import { unmatchedPaymentsProcessingRoute, unmatchedPaymentsWaitingRoute } from 'modules/data-grid-next/routing/routes'
import { getCashRegisterFilterName } from '../../cash-register/domain/cash-register'
import { changeAccountingDocumentsDirection } from '../../accounting-document/actions'
import { changeCashRegisterListDirection } from '../../cash-register/actions'

type DispatchProps = {
	onFilterChange: (organizationID: string, direction: AccountingDocumentDirection, filter: Filter) => Promise<void>,
	redirectToNoProcessedStatements: (organizationId: string, bankAccountId: string) => Promise<void>,
	redirectToCashRegisterRoute: (organizationId: string, cashRegisterId: string, filter: Filter) => Promise<void>,
	redirectToUnmatchedPaymentsRoute: (organizationId: string, filter: Filter) => Promise<void>,
	redirectToMatchedPaymentsRoute: (organizationId: string, filter: Filter) => Promise<void>,
}

const mapDispatchToProps = (
	dispatch: Dispatch<InternalDashboardAction | AccountingDocumentListAction | CashRegisterAction | DataGridReduxAction>,
): DispatchProps => {
	const clearAllCashRegisterFilters = () => {
		dispatch(clearAllGridFilters(getCashRegisterFilterName('received')))
		dispatch(clearAllGridFilters(getCashRegisterFilterName('issued')))
		dispatch(clearAllGridFilters(getCashRegisterFilterName('unknown')))
	}

	const setDefaultFilters = (filter: Filter) => {
		dispatch(changeFilter(getCashRegisterFilterName('issued'), [{ field: 'direction', value: '0' }]))
		dispatch(changeFilter(getCashRegisterFilterName('received'), [{ field: 'direction', value: '1' }]))
		dispatch(changeFilter(getCashRegisterFilterName('unknown'), filter))
	}

	return {
		redirectToNoProcessedStatements: async (organizationId: string, bankAccountId: string) => {
			const notProcessedFilter = [{ field: 'processingState', valueContains: [0, 2, 3] }]
			await dispatch(push(bankAccountStatementsRoute(bankAccountId, organizationId)))
			dispatch(changeFilter('bankAccountStatements', notProcessedFilter))
		},
		redirectToCashRegisterRoute: async (organizationId: string, cashRegisterId: string, filter: Filter) => {
			await dispatch(push(cashRegisterRoute(cashRegisterId, organizationId)))
			clearAllCashRegisterFilters()
			setDefaultFilters(filter)
			dispatch(changeAccountingDocumentsDirection('unknown'))
			dispatch(changeCashRegisterListDirection('unknown'))
		},
		onFilterChange: async (organizationId: string, direction: AccountingDocumentDirection, filter: Filter) => {
			await dispatch(push(organizationAccountingDocumentsRoute(organizationId, direction)))
			dispatch(changeFilter(getAccDocFilterName(direction), filter))
		},
		redirectToUnmatchedPaymentsRoute: async (organizationId: string, filter: Filter) => {
			await dispatch(push(unmatchedPaymentsProcessingRoute(organizationId)))
			dispatch(changeFilter('unmatchedPayments', filter))
		},
		redirectToMatchedPaymentsRoute: async (organizationId: string, filter: Filter) => {
			await dispatch(push(unmatchedPaymentsWaitingRoute(organizationId)))
			dispatch(changeFilter('unmatchedPayments', filter))
		},
	}
}

export default connect(null, mapDispatchToProps)(withRouter(OrganizationStatisticsGrid))
