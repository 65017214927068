/* @flow */

// $FlowFixMe - there is useState available
import React, { useState } from 'react'
import { type WithTranslateProps, withTranslate } from 'wrappers'
import GeminiScrollbar from 'react-gemini-scrollbar'
import NavigationPrimary from './navigation-primary'
import OrganizationSwitcher from 'modules/organization/containers/organization-switcher'
import MobileAppInvitationToggle from '../containers/mobile-app-invitation-toggle'
import RightArrow from 'components/svg-icons/hardware/keyboard-arrow-right'
import LeftArrow from 'components/svg-icons/hardware/keyboard-arrow-left'
import { colors } from 'variables'
import styles from './navigation.css'

export type Props = {|
	...WithTranslateProps,
	organizationId: ?string,
	selected: ?string,
	compact: boolean,
	isCompactRoute: boolean,
	showOrganizationSelect: boolean,
	showOrganizationRelatedItems: boolean,
	onNavigate?: (id: string) => void,
	setCompact: (compact: boolean) => void,
	isInternal?: boolean,
|}

function Navigation(props: Props) {
	const [isCompact, setIsCompact] = useState(props.isCompactRoute || props.compact)

	function toggleCompact() {
		props.isCompactRoute && setIsCompact(!isCompact)
		if (!props.isCompactRoute) {
			props.setCompact(!props.compact)
			setIsCompact(!props.compact)
		}
	}

	return (
		<div className={isCompact ? styles.compact : styles.normal}>
			<GeminiScrollbar className={styles.gemini}>
				<div className={styles.scroller}>
					{props.showOrganizationSelect && <OrganizationSwitcher createNewOrganization compact={isCompact} />}
					<div
						className={props.showOrganizationSelect ? styles.navigation : styles.navigationWithoutOrganizationSelect}
					>
						<NavigationPrimary
							inOrganization={!!props.organizationId}
							compact={isCompact}
							selected={props.selected}
							onNavigate={props.onNavigate}
							showOrganizationRelatedItems={props.showOrganizationRelatedItems}
							currentUserIsInternal={props.isInternal}
						/>
						{!isCompact && <MobileAppInvitationToggle />}
						<div className={styles.footer}>
							<div className={styles.logo} />
						</div>
					</div>
				</div>
			</GeminiScrollbar>
			<div className={styles.toggle} onClick={toggleCompact}>
				{isCompact ? (
					<RightArrow color={colors.white} hoverColor={colors.white} />
				) : (
					<LeftArrow color={colors.white} hoverColor={colors.white} />
				)}
			</div>
		</div>
	)
}

export default withTranslate(Navigation)
