import React from 'react'
import pure from 'recompose/pure'
import SvgIcon from 'components/SvgIcon'

let Check = props => (
	<SvgIcon {...props}>
		<g stroke="none" strokeWidth="1" fillRule="evenodd">
			<g fillRule="nonzero">
				<polygon points="7.82473526 10.110698 6 11.8195986 10.2566408 16.364768 17.7919131 8.75958565 16.0160026 7 10.3073613 12.7616043" />
			</g>
		</g>
	</SvgIcon>
)
Check = pure(Check)

export default Check
