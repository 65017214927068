/* @flow */

import React, { Component } from 'react'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import ReactCrop, { makeAspectCrop } from 'react-image-crop' //eslint-disable-line
import 'react-image-crop/dist/ReactCrop.css'
import Button from 'components/button'
import Loader from 'components/loader'
import styles from './image-cropper.css'

export type CropType = {|
	x?: number,
	y?: number,
	height?: number,
	width?: number,
	aspect?: number,
|}

export type CropReturnType = {|
	percent: {|
		x: number,
		y: number,
		height: number,
		width: number,
	|},
	px: {|
		x: number,
		y: number,
		height: number,
		width: number,
		originalHeight: number,
		originalWidth: number,
	|},
|}

type Props = {|
	...WithTranslateProps,
	src: string,
	onCrop?: (crop: ?CropReturnType) => void,
	onCancelCrop?: () => void,
	keepSelection?: boolean,
	square?: boolean,
|}

type State = {|
	crop: CropType,
|}

class ImageCropper extends Component<Props, State> {
	static defaultProps = {
		keepSelection: true,
	}

	state: State = {
		crop: this.props.square ? { aspect: 1 } : { x: 0, y: 0, height: 100, width: 100 },
	}

	onChange = (crop: CropType) => {
		this.setState({ crop })
	}

	onImageLoaded = (image: HTMLImageElement) => {
		const width = image.naturalWidth > image.naturalHeight ? (image.naturalHeight / image.naturalWidth) * 100 : 100
		const height = image.naturalHeight > image.naturalWidth ? (image.naturalWidth / image.naturalHeight) * 100 : 100

		this.setState({
			crop: {
				aspect: 1,
				width,
				height,
				x: (100 - width) / 2,
				y: (100 - height) / 2,
			},
		})
	}

	onCrop = () => {
		const { crop } = this.state
		const { onCrop } = this.props

		if (!crop.height || !crop.width || (crop.height === 100 && crop.width === 100)) {
			return onCrop && onCrop(null)
		}

		const image = new Image()
		image.onload = function() {
			if (crop.width && crop.height) {
				const cropParam: CropReturnType = {
					percent: {
						x: crop.x || 0,
						y: crop.y || 0,
						height: crop.height || 100,
						width: crop.width || 100,
					},
					px: {
						x: (this.width / 100) * (crop.x || 0),
						y: (this.height / 100) * (crop.y || 0),
						height: (this.height / 100) * crop.height,
						width: (this.width / 100) * crop.width,
						originalHeight: this.height,
						originalWidth: this.width,
					},
				}
				onCrop && onCrop(cropParam)
			}
		}
		image.src = this.props.src
	}

	onCancelCrop = () => {
		this.props.onCancelCrop && this.props.onCancelCrop()
	}

	render() {
		const { onCrop, onCancelCrop, t, ...rest } = this.props
		if (!this.props.src) {
			return <Loader visible />
		}
		return (
			<div className={styles.root}>
				<ReactCrop {...rest} onImageLoaded={this.onImageLoaded} crop={this.state.crop} onChange={this.onChange} />
				<div className={styles.actions}>
					<Button
						autoTestId="image-cropper-crop"
						primary
						wide
						onClick={this.onCrop}
						labelText={t('user.settings.cropImage')}
					/>
					<Button
						autoTestId="image-cropper-cancel"
						secondary
						wide
						onClick={this.onCancelCrop}
						labelText={t('application.back')}
					/>
				</div>
			</div>
		)
	}
}

export default withTranslate(ImageCropper)
