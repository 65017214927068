/* @flow */

import React, { Component } from 'react'
import moment from 'moment'
import {
	withTranslate,
	withNotify,
	validate,
	type WithTranslateProps,
	type WithNotifyProps,
	type FormValidationProps,
} from 'wrappers'
import type { Sequence } from 'types'
import { MAX_DOCUMENT_NUMBER_LENGTH } from 'modules/accounting-document/constants'
import SequenceItem from './sequence-item'
import DeleteDialog from 'components/delete-dialog'
import Dialog from 'components/dialog'
import Button from 'components/button'
import TextField from 'components/TextField'
import NumberInput from 'components/number-input'
import DatePicker from 'components/date-picker'
import Table from 'components/table/table'
import TableBody from 'components/table/table-body'
import TableHeader from 'components/table/table-header'
import TableHeaderCell from 'components/table/table-header-cell'
import TableRow from 'components/table/table-row'
import CollapsibleSettings from '../collapsible-settings'
import { getSortedSequences } from '../../domain/sequences'
import SequenceFormatHelper from './sequence-format-helper'
import { convertToDate, formatToIsoDateString } from 'utils/formatters'
import styles from '../settings.css'
import { colors } from 'variables'

export type LastGeneratedNumberType = {|
	year?: number,
	lastGeneratedNumber?: number,
|}

export type Props = {
	title?: string,
	sequences: ?Array<Sequence>,
	readOnly?: boolean,
	editSequence: (sequenceId: string, sequence: Sequence) => void,
	removeSequence: (sequence: Sequence) => void,
	changeLastGeneratedNumber: (sequence: Sequence, lastGeneratedNumber: number, year: number) => void,
}

type State = {
	editedSequence?: ?Sequence,
	open: boolean,
}

type ComponentProps = Props & WithTranslateProps & WithNotifyProps & FormValidationProps

class SequenceList extends Component<ComponentProps, State> {
	state: State = {
		editedSequence: null,
		open: false,
	}

	onDeleteButtonClick = (sequence: Sequence) => () => {
		DeleteDialog()
			.then(() => {
				this.props.removeSequence(sequence)
			})
			.catch(e => {}) //eslint-disable-line
	}

	onEditButtonClick = (editedSequence: Sequence) => () => {
		this.setState({ editedSequence: Object.freeze({ ...editedSequence }) })
	}

	onSequenceFieldChange = (field: string) => (ev: any, value: any) => {
		this.setState({
			editedSequence: Object.freeze({
				...this.state.editedSequence,
				[field]: value,
			}),
		})
	}

	onSequenceValidFromChange = (ev: any, date: ?Date) => {
		this.setState({
			editedSequence: Object.freeze({
				...this.state.editedSequence,
				validFrom: date ? formatToIsoDateString(date) : undefined,
			}),
		})
	}

	onSequenceValidToChange = (ev: any, date: ?Date) => {
		this.setState({
			editedSequence: Object.freeze({
				...this.state.editedSequence,
				validTo: date ? formatToIsoDateString(date) : undefined,
			}),
		})
	}

	editButtonClick = () => {
		const { editedSequence } = this.state
		const { id } = editedSequence || {}
		if (editedSequence && id) {
			const originalSequence: ?Sequence =
				this.props.sequences && this.props.sequences.find((s: Sequence) => s.id === id)

			if (!originalSequence) {
				return
			}

			getNearYears(new Date().getFullYear()).forEach((year: number) => {
				const editedFound: ?LastGeneratedNumberType = getLastGeneratedNumber(year, editedSequence)
				const originalFound: ?LastGeneratedNumberType = getLastGeneratedNumber(year, originalSequence)

				if (
					(!editedFound && originalFound) ||
					(!originalFound && editedFound) ||
					(originalFound && editedFound && originalFound.lastGeneratedNumber !== editedFound.lastGeneratedNumber)
				) {
					this.props.changeLastGeneratedNumber(
						editedSequence,
						editedFound ? editedFound.lastGeneratedNumber || 0 : 0,
						year,
					)
				}
			})

			if (
				originalSequence.name !== editedSequence.name ||
				originalSequence.format !== editedSequence.format ||
				originalSequence.validFrom !== editedSequence.validFrom ||
				originalSequence.validTo !== editedSequence.validTo
			) {
				this.props.editSequence(id, editedSequence)
			}
			this.closeDialog()
		}
	}

	closeDialog = () => {
		this.setState({ editedSequence: null })
	}

	onLastGeneratedNumberChange = (year: number) => (event: SyntheticInputEvent<HTMLInputElement>, value: ?string) => {
		if (value !== null && value !== undefined) {
			const { editedSequence } = this.state
			const lastGeneratedNumbers: Array<LastGeneratedNumberType> = replaceLastGeneratedNumber(
				editedSequence && editedSequence.lastGeneratedNumbers,
				year,
				value,
			)

			this.setState({
				editedSequence: Object.freeze({
					...editedSequence,
					lastGeneratedNumbers,
				}),
			})
		}
	}

	renderDialog() {
		const { t } = this.props
		const { editedSequence } = this.state

		const actions = [
			<Button
				primary
				wide
				key="okButton"
				labelText={t('settings.forms.editButton')}
				onClick={this.props.validationSubmit(this.editButtonClick)}
				autoTestId="sequences-list-edit-button"
			/>,
			<Button
				secondary
				wide
				key="cancelButton"
				labelText={t('settings.forms.cancelButton')}
				onClick={this.closeDialog}
				autoTestId="sequences-list-cancel-button"
			/>,
		]

		return (
			<Dialog
				autoTestId="sequence-list-dialog"
				title={t('settings.sequences.editSequenceDialogTitle')}
				open={!!editedSequence}
				actions={actions}
			>
				<div className={styles.row}>
					<div className={styles.column}>
						<TextField
							fullWidth
							labelText={t('settings.sequences.name')}
							value={this.props.validationValue('name', editedSequence && editedSequence.name)}
							clientError={this.props.validationMessage('name')}
							onChange={this.onSequenceFieldChange('name')}
							autoTestId="sequences-list-name"
							name="name"
						/>
					</div>
					<div className={styles.column}>
						<TextField
							fullWidth
							labelText={t('settings.sequences.format')}
							value={this.props.validationValue('name', editedSequence && editedSequence.format)}
							clientError={this.props.validationMessage('format')}
							infoText={t('settings.sequences.placeholderTooltip')}
							onChange={this.onSequenceFieldChange('format')}
							autoTestId="sequences-list-format"
							name="format"
						/>
					</div>
				</div>

				{getNearYears(new Date().getFullYear()).map((year: number) => {
					const found: ?LastGeneratedNumberType = editedSequence && getLastGeneratedNumber(year, editedSequence)
					return (
						<div
							className={styles.row}
							key={((editedSequence && editedSequence.id) || '') + 'lastGeneratedNumber' + year}
						>
							<NumberInput
								fullWidth
								labelText={`${t('settings.sequences.lastNumberInYear')} ${year}`}
								value={found && found.lastGeneratedNumber}
								onChange={this.onLastGeneratedNumberChange(year)}
								autoTestId="sequences-list-last-number-in-year"
								minValue={0}
								name="last-year"
							/>
						</div>
					)
				})}
				<div className={styles.row}>
					<div className={styles.column}>
						<DatePicker
							fullWidth
							labelText={t('settings.sequences.validFrom')}
							onChange={this.onSequenceValidFromChange}
							value={this.props.validationValue(
								'validFrom',
								editedSequence && editedSequence.validFrom
									? new Date(editedSequence && editedSequence.validFrom)
									: null,
							)}
							clientError={this.props.validationMessage('validFrom')}
							autoTestId="sequence-list-valid-from-date"
						/>
					</div>
					<div className={styles.column}>
						<DatePicker
							fullWidth
							labelText={t('settings.sequences.validTo')}
							onChange={this.onSequenceValidToChange}
							value={this.props.validationValue(
								'validTo',
								editedSequence && editedSequence.validTo ? new Date(editedSequence && editedSequence.validTo) : null,
							)}
							clientError={this.props.validationMessage('validTo')}
							autoTestId="sequence-list-valid-to-date"
						/>
					</div>
				</div>
				<SequenceFormatHelper />
			</Dialog>
		)
	}

	renderBody() {
		const { t } = this.props
		const sequences = getSortedSequences(this.props.sequences)

		return (
			<div>
				{sequences && sequences.length > 0 ? (
					<Table>
						<TableHeader>
							<TableRow>
								<TableHeaderCell>{t('settings.sequences.name')}</TableHeaderCell>
								<TableHeaderCell>{t('settings.sequences.format')}</TableHeaderCell>
								<TableHeaderCell width={100}>{t('settings.sequences.validFrom')}</TableHeaderCell>
								<TableHeaderCell width={100}>{t('settings.sequences.validTo')}</TableHeaderCell>
								<TableHeaderCell width={130}>{t('settings.sequences.lastNumber')}</TableHeaderCell>
								<TableHeaderCell last width={40} />
							</TableRow>
						</TableHeader>
						<TableBody>
							{sequences.map((s: Sequence) => (
								<SequenceItem
									sequence={s}
									key={s.id}
									readOnly={this.props.readOnly}
									onDeleteButtonClick={this.onDeleteButtonClick(s)}
									onEditButtonClick={this.onEditButtonClick(s)}
								/>
							))}
						</TableBody>
					</Table>
				) : (
					<span>{t('application.noData')}</span>
				)}
			</div>
		)
	}

	formatValidation = (name: string, format: string, validFrom: Date, validTo: ?Date) => {
		const errors = {}
		const { t } = this.props

		if (!name) errors.name = t('application.validation.mandatory')
		if (!validFrom) errors.validFrom = t('application.validation.mandatory')
		if (validFrom && validTo) {
			if (moment(validFrom).startOf('day') > moment(validTo).startOf('day')) {
				errors.validTo = t('settings.sequences.validToLowerThanValidFrom')
			}
		}
		if (format) {
			const regexp = /\(c+\)|\(yyyy\)|\(yy\)|\(mm\)/gi
			const match = format.match(regexp) || []
			const countOfBrackets = match.length * 2
			if (format.length - countOfBrackets > MAX_DOCUMENT_NUMBER_LENGTH) {
				errors.format = t('settings.sequences.documentNumberMaxLength', { maxLength: MAX_DOCUMENT_NUMBER_LENGTH })
			}
		} else {
			errors.format = t('application.validation.mandatory')
		}

		return errors
	}

	getClientValidationErrors() {
		const { editedSequence } = this.state

		if (editedSequence) {
			const { name, format, validFrom, validTo } = editedSequence
			return this.formatValidation(name, format, convertToDate(validFrom), validTo ? convertToDate(validTo) : undefined)
		} else return {}
	}

	onOpen = () => this.setState({ open: true })
	onClose = () => this.setState({ open: false })

	render() {
		const { sequences } = this.props
		this.props.validateForm(this.getClientValidationErrors())

		const title = (
			<div>
				{this.props.title}
				{sequences && sequences.length > 0 && <span style={style.count}>{sequences.length}</span>}
			</div>
		)

		return (
			<div>
				<CollapsibleSettings open={this.state.open} onOpen={this.onOpen} onClose={this.onClose} title={title}>
					{this.renderBody()}
				</CollapsibleSettings>
				{this.renderDialog()}
			</div>
		)
	}
}

/**
 * returns array of years. Two years back, current and one year forward
 *
 * @param {numer} year
 */
export function getNearYears(year: number): Array<number> {
	return [year - 2, year - 1, year, year + 1]
}

/**
 * returns LastGeneratedNumberType of some year if there is some in sequence
 *
 * @param {numer} year
 * @param {Sequence} s
 */
export function getLastGeneratedNumber(year: number, s: Sequence): ?LastGeneratedNumberType {
	const found =
		s.lastGeneratedNumbers && s.lastGeneratedNumbers.find((number: LastGeneratedNumberType) => number.year === year)
	return found || null
}

/**
 * Will replace value of lastGeneratedNumber in array of LastGeneratedNumberType if there is some for given year.
 * If not, new LastGeneratedNumberType will be inserted.
 *
 * @param {?Array<LastGeneratedNumberType>} lastGeneratedNumbers
 * @param {number} year
 * @param {string} value if empty string, zero will be used instead
 */
export function replaceLastGeneratedNumber(
	lastGeneratedNumbers: ?Array<LastGeneratedNumberType>,
	year: number,
	value: string,
): Array<LastGeneratedNumberType> {
	let numbers: Array<LastGeneratedNumberType> = [...(lastGeneratedNumbers || [])]
	const index: number = numbers.findIndex((number: LastGeneratedNumberType) => number.year === year)

	if (index > -1) {
		numbers[index] = {
			year,
			lastGeneratedNumber: value || value === 0 ? parseInt(value) : 0,
		}
	} else {
		numbers = [
			...numbers,
			{
				year,
				lastGeneratedNumber: value || value === 0 ? parseInt(value) : 0,
			},
		]
	}

	return numbers
}

const style = {
	count: {
		display: 'inline-block',
		height: 14,
		paddingLeft: 2,
		paddingRight: 2,
		border: `1px solid ${colors.grey400}`,
		borderRadius: 2,
		color: colors.blackFaded60,
		fontSize: 10,
		lineHeight: '14px',
		marginLeft: 7,
		position: 'relative',
		top: -1,
		fontWeight: 'normal',
	},
}

export default withNotify(withTranslate(validate('FINISH_EDIT_SEQUENCE')(SequenceList)))
