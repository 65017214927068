/* @flow */

import React, { Component } from 'react'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import { withRouter } from 'react-router'
import EditAccountingDocumentComponent from '../containers/invoices/edit-accounting-document'
import type { WithRouterProps } from 'types'
import Helmet from 'react-helmet'

type Props = WithRouterProps & WithTranslateProps

class EditAccountingDocument extends Component<Props> {
	render() {
		return (
			<div>
				<Helmet>
					<title>{this.props.t('accountingDocument.show.headline')}</title>
				</Helmet>
				<EditAccountingDocumentComponent accountingDocumentId={this.props.match.params.id} readonly />
			</div>
		)
	}
}

export default withTranslate(withRouter(EditAccountingDocument))
