// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2hlveClkdGtHFPfSflI9pV {\n  margin-top: 20px;\n  margin-bottom: 20px;\n}\n\n._2hlveClkdGtHFPfSflI9pV:before {\n\tcontent: '';\n\tdisplay: table;\n}\n\n._2hlveClkdGtHFPfSflI9pV:after {\n\tcontent: '';\n\tdisplay: table;\n\tclear: both;\n}\n", "",{"version":3,"sources":["webpack://./modules/data-grid/components/data-grid-filter.css"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,mBAAA;AACF;;AAEA;CACC,WAAA;CACA,cAAA;AACD;;AAEA;CACC,WAAA;CACA,cAAA;CACA,WAAA;AACD","sourcesContent":[".gridFilter {\n  margin-top: 20px;\n  margin-bottom: 20px;\n}\n\n.gridFilter:before {\n\tcontent: '';\n\tdisplay: table;\n}\n\n.gridFilter:after {\n\tcontent: '';\n\tdisplay: table;\n\tclear: both;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"gridFilter": "_2hlveClkdGtHFPfSflI9pV"
};
export default ___CSS_LOADER_EXPORT___;
