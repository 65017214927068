/* @flow */

import React, { Component } from 'react'
import { connect } from 'react-redux'
import type { CommonAction, Dispatch, Enum, EnumItem, State } from 'types'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import { Number_PaymentType } from 'types/convertor'
import SelectNext from 'components/select-next'
import { loadPaymentTypes } from 'modules/common/actions'
import type { OptionsType, OptionType } from 'components/select-next'

type StateProps = {|
	paymentTypesAvailable: ?Enum,
|}

const mapStateToProps = (state: State): StateProps => {
	return {
		paymentTypesAvailable: state.common.paymentTypes.data,
	}
}

type DispatchProps = {|
	loadPaymentTypes: () => void,
|}

const mapDispatchToProps = (dispatch: Dispatch<CommonAction>): DispatchProps => {
	return {
		loadPaymentTypes: () => {
			dispatch(loadPaymentTypes())
		},
	}
}

type OwnProps = {|
	onChange: (paymentType: string) => void,
	value: ?number | ?string,
	label?: string,
	portal?: boolean,
|}

const mergeProps = (stateProps: StateProps, dispatchProps: DispatchProps, ownProps: OwnProps): ComponentProps => {
	return {
		paymentTypesAvailable: stateProps.paymentTypesAvailable,
		loadPaymentTypes: dispatchProps.loadPaymentTypes,
		onChange: ownProps.onChange,
		value: ownProps.value,
		label: ownProps.label,
		portal: ownProps.portal,
	}
}

export type ComponentProps = {| ...StateProps, ...DispatchProps, ...OwnProps |}

class PaymentSelector extends Component<{| ...ComponentProps, ...WithTranslateProps |}> {
	componentDidMount() {
		if (!this.props.paymentTypesAvailable) {
			this.props.loadPaymentTypes()
		}
	}

	handleChange = (option: ?OptionType | ?OptionsType) => {
		// $FlowFixMe
		option && option.value && this.props.onChange(option.value)
	}

	render() {
		const { t, paymentTypesAvailable, loadPaymentTypes, label, onChange, portal, ...rest } = this.props //eslint-disable-line
		let { value } = this.props
		let key: ?string
		return (
			<SelectNext
				label={label}
				onChange={this.handleChange}
				portal
				value={value ? value.toString() : ''}
				{...rest}
				options={
					this.props.paymentTypesAvailable
						? this.props.paymentTypesAvailable.map((paymentType: EnumItem) => {
								key = paymentType.key ? paymentType.key : null
								return {
									value: key || '',
									label: t(`accountingDocument.paymentTypes.${Number_PaymentType(parseInt(key))}`),
								}
						  })
						: []
				}
			/>
		)
	}
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(withTranslate(PaymentSelector))
