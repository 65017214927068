//@flow

import type {
	AccountingDocument,
	Dispatch,
	PagerProps,
	PagerPropsNullable,
	State,
	Template,
	TemplateScheduler,
	TemplatesAction,
	TemplatesSearchResult,
	AccountingDocumentDefaults,
} from 'types'
import { emptyAccountingDocument } from 'types/empty'
import { editTemplateRoute, createAccountingDocumentRoute } from 'modules/accounting-document/routing/routes'
import diff from 'json-patch-gen'
import { push } from 'react-router-redux'
import { beginTask, endTask } from 'utils/loader'
import { getPagerProps, getTemplate, getTemplateIndex } from 'modules/accounting-document/selectors'
import { getBankAccounts } from 'modules/bank/selectors'
import { getDefaultBankAccount } from 'modules/accounting-document/domain/bank-account'
import {
	template as templateApi,
	templateDraft as templateDraftApi,
	templateScheduler as templateSchedulerApi,
	templates as templatesApi,
} from 'modules/common/models/api-model'
import { generateNewTemplate } from 'modules/accounting-document/domain/templates'
import { isCurrentOrganizationVatFree } from 'modules/accounting-document/domain/accounting-document'
import { clearVatRatesInLineItems } from 'modules/accounting-document/helpers'
import { getServerAndValidationErrors } from 'helpers'
import { NEW_TEMPLATE_NAME } from 'trivi-constants'
import { getAccDocDefaults } from '.'
import { getPrintingInfo } from '../domain/accounting-document'
import { getAppLanguage, toAvailableAccountingDocumentLanguage } from 'locales'

export function setPagerProps(pagerPropsNullable: PagerPropsNullable) {
	return async (dispatch: Dispatch<TemplatesAction>) => {
		dispatch({
			type: 'SET_TEMPLATE_PAGER_PROPS',
			pagerPropsNullable,
		})
	}
}

export function setCurrentTemplate(template: ?Template) {
	return async (dispatch: Dispatch<TemplatesAction>) => {
		dispatch({
			type: 'SET_CURRENT_TEMPLATE',
			currentTemplate: template,
		})
	}
}

export function loadTemplates(page: number, pageSize: number) {
	return async (dispatch: Dispatch<TemplatesAction>, getState: () => State) => {
		const state: State = getState()
		const currentPagerProps: PagerProps = getPagerProps(state)
		const loaderId = 'templates_loadTemplates'
		dispatch(
			setPagerProps({
				currentPage: page,
				pageSize: pageSize,
				pagesCount: currentPagerProps.pagesCount || 2,
			}),
		)
		dispatch({
			type: 'START_LOAD_TEMPLATES',
			page,
			pageSize,
		})
		try {
			beginTask(loaderId)
			const result: TemplatesSearchResult = await templatesApi.get({ page, pageSize })
			const templates = (Array.isArray(result.templates) && result.templates) || []
			dispatch(
				setPagerProps({
					// currentPage: result.currentPage || 1,// IT IS IMPORTANT NOT TO SET cuurentPage here!
					pageSize: result.pageSize || 1,
					pagesCount: result.pagesCount || 1,
				}),
			)
			dispatch({
				type: 'FINISH_LOAD_TEMPLATES',
				templates: templates,
				page,
				pageSize,
				success: true,
			})
		} catch (error) {
			dispatch({
				type: 'FINISH_LOAD_TEMPLATES',
				templates: [],
				page,
				pageSize,
				success: false,
				serverError: error,
			})
		} finally {
			endTask(loaderId)
		}
	}
}

export function loadTemplatesPage(page: number) {
	return async (dispatch: Dispatch<TemplatesAction>, getState: () => State) => {
		const state: State = getState()
		const pagerProps: PagerProps = getPagerProps(state)
		return dispatch(loadTemplates(page, pagerProps.pageSize))
	}
}

export function loadTemplatesPageSize(pageSize: number) {
	return async (dispatch: Dispatch<TemplatesAction>, getState: () => State) => {
		const state: State = getState()
		const pagerProps: PagerProps = getPagerProps(state)
		return dispatch(loadTemplates(pagerProps.currentPage, pageSize))
	}
}

export function loadTemplate(templateId: string) {
	return async (dispatch: Dispatch<TemplatesAction>) => {
		const loaderId = 'templates_loadTemplate'
		dispatch({
			type: 'START_LOAD_TEMPLATE',
			templateId,
		})
		try {
			beginTask(loaderId)
			const template: Template = await templateApi.get({ templateId })
			dispatch({
				type: 'FINISH_LOAD_TEMPLATE',
				templateId,
				template,
			})
		} catch (error) {
			dispatch({
				type: 'FINISH_LOAD_TEMPLATE',
				templateId,
				template: null,
				serverError: error,
			})
		} finally {
			endTask(loaderId)
		}
	}
}

export function createNewTemplate() {
	return async (dispatch: Dispatch<TemplatesAction>, getState: () => State): Promise<any> => {
		const state: State = getState()
		return dispatch(createTemplate(generateNewTemplate(isCurrentOrganizationVatFree(state)), true))
	}
}

let createTemplateId: number = 0
export function createTemplate(originTemplate: Template, redirectAfterCreation?: boolean) {
	return async (dispatch: Dispatch<TemplatesAction>, getState: () => State) => {
		const state: State = getState()
		const language = toAvailableAccountingDocumentLanguage(getAppLanguage())
		const documentDefaults: AccountingDocumentDefaults = await getAccDocDefaults(
			state,
			dispatch,
			originTemplate?.content || emptyAccountingDocument({ vatCountryType: 1 }),
		)

		const template = {
			...originTemplate,
			content:
				!originTemplate.originAccountingDocumentId && originTemplate.content
					? {
							...originTemplate.content,
							printing: {
								...originTemplate.content?.printing,
								...(language ? getPrintingInfo(documentDefaults, language) : {}),
							},
					  }
					: undefined,
		}
		if (!template.name) {
			template.name = NEW_TEMPLATE_NAME
		}

		// Default bank accounts
		const defaultBankAccount = getDefaultBankAccount(getBankAccounts(state))
		if (template.content && !template.content.bankAccounts && defaultBankAccount) {
			template.content.bankAccounts = [{ ...defaultBankAccount, localId: '0' }]
		}

		const loaderId = 'templates_createTemplate'
		const id: number = createTemplateId++
		dispatch({
			id,
			type: 'START_CREATE_TEMPLATE',
			template,
		})
		dispatch(push({ pathname: createAccountingDocumentRoute('template', 'unknown') }))
		try {
			beginTask(loaderId)
			const confirmedTemplate: Template = await templatesApi.post(template)
			if (redirectAfterCreation && confirmedTemplate && confirmedTemplate.id) {
				if (confirmedTemplate.id) await dispatch(loadTemplate(confirmedTemplate.id))
				if (confirmedTemplate.id) dispatch(push(editTemplateRoute(confirmedTemplate.id)))
			}
			return dispatch({
				id,
				type: 'FINISH_CREATE_TEMPLATE',
				oldTemplate: template,
				confirmedTemplate: confirmedTemplate,
				success: true,
			})
		} catch (error) {
			return dispatch({
				id,
				type: 'FINISH_CREATE_TEMPLATE',
				oldTemplate: template,
				confirmedTemplate: null,
				success: false,
				serverError: error,
			})
		} finally {
			endTask(loaderId)
		}
	}
}

export function createTemplateFromAccountingDocument(accountingDocumentId: string) {
	return async (dispatch: Dispatch<TemplatesAction>): Promise<any> => {
		return dispatch(
			createTemplate(
				{
					name: 'Template_' + (createTemplateId + 1),
					originAccountingDocumentId: parseInt(accountingDocumentId),
				},
				true,
			),
		)
	}
}

let duplicateTemplateId: number = 0
export function duplicateTemplate(template: Template) {
	return async (dispatch: Dispatch<TemplatesAction>) => {
		const duplicateId: number = duplicateTemplateId++
		const loaderId = 'templates_duplicateTemplate'

		// In POST If set, content has to be empty. The template will be created from the given accounting document
		if (template && template.content) {
			delete template.originAccountingDocumentId
		}

		dispatch({
			type: 'START_DUPLICATE_TEMPLATE',
			originalTemplate: template,
			duplicateId,
		})
		try {
			beginTask(loaderId)
			const confirmedTemplate: Template = await templatesApi.post(template)
			return dispatch({
				type: 'FINISH_DUPLICATE_TEMPLATE',
				originalTemplate: template,
				confirmedTemplate: confirmedTemplate,
				success: true,
				duplicateId,
			})
		} catch (error) {
			return dispatch({
				type: 'FINISH_DUPLICATE_TEMPLATE',
				originalTemplate: template,
				confirmedTemplate: null,
				success: false,
				duplicateId,
				serverError: error,
			})
		} finally {
			endTask(loaderId)
		}
	}
}

export function updateTemplate(template: Template) {
	return async (dispatch: Dispatch<TemplatesAction>, getState: () => State) => {
		const state = getState()
		const oldTemplate: ?Template = getTemplate(state, template.id || '')
		if (!oldTemplate) {
			console.error('templates/updateTemplate oldTemplate doesnt exist') //eslint-disable-line
			return
		}
		const loaderId = 'templates_updateTemplate'
		const isOrganizationVatFree = isCurrentOrganizationVatFree(state)
		let newTemplate = template

		if (isOrganizationVatFree && newTemplate.content && newTemplate.content.lineItems) {
			newTemplate = {
				...newTemplate,
				content: clearVatRatesInLineItems(newTemplate.content),
			}
		}

		dispatch({
			type: 'START_UPDATE_TEMPLATE',
			oldTemplate,
			newTemplate,
		})
		try {
			beginTask(loaderId)
			if (newTemplate.id) {
				const params = {
					templateId: newTemplate.id,
				}
				let confirmedTemplate: Template = await templateApi.put(params, newTemplate)
				let confirmedScheduler: ?TemplateScheduler = newTemplate.scheduler

				if (!newTemplate.scheduler || !newTemplate.scheduler.frequency) {
					//DELETE scheduler
					await templateSchedulerApi.delete(params)
				} else {
					//UPDATE scheduler
					if (oldTemplate.scheduler) {
						const _diffResult = diff(oldTemplate.scheduler, newTemplate.scheduler)

						const diffResult = _diffResult.filter((diffValue: any) => !diffValue.path.includes('emailRecipients'))
						const emailRecipients = newTemplate && newTemplate.scheduler && newTemplate.scheduler.emailRecipients
						diffResult.push({
							op: 'replace',
							path: '/emailRecipients',
							value: emailRecipients,
						})

						try {
							confirmedScheduler = await templateSchedulerApi.patch(params, diffResult)
						} catch (error) {
							const { serverError, validationError } = getServerAndValidationErrors(error)
							dispatch({
								type: 'FINISH_UPDATE_TEMPLATE',
								oldTemplate,
								newTemplate,
								confirmedTemplate: null,
								success: false,
								serverError,
								validationError,
							})
							return Promise.reject()
						}
					} else {
						//INSERT scheduler
						try {
							confirmedScheduler = await templateSchedulerApi.put(params, newTemplate.scheduler)
						} catch (error) {
							const { serverError, validationError } = getServerAndValidationErrors(error)
							dispatch({
								type: 'FINISH_UPDATE_TEMPLATE',
								oldTemplate,
								newTemplate,
								confirmedTemplate: null,
								success: false,
								serverError,
								validationError,
							})
							return Promise.reject()
						}
					}
				}

				confirmedTemplate.scheduler = confirmedScheduler || undefined
				dispatch({
					type: 'FINISH_UPDATE_TEMPLATE',
					oldTemplate,
					newTemplate,
					confirmedTemplate,
					success: true,
				})
			} else {
				dispatch({
					type: 'FINISH_UPDATE_TEMPLATE',
					oldTemplate,
					newTemplate,
					confirmedTemplate: null,
					success: false,
					serverError: {
						message: 'template doesnt have an id',
					},
				})
				return Promise.reject()
			}
		} catch (error) {
			const { serverError, validationError } = getServerAndValidationErrors(error)
			dispatch({
				type: 'FINISH_UPDATE_TEMPLATE',
				oldTemplate,
				newTemplate,
				confirmedTemplate: null,
				success: false,
				serverError,
				validationError,
			})
			return Promise.reject()
		} finally {
			endTask(loaderId)
		}
	}
}

export function removeTemplate(template: Template) {
	return async (dispatch: Dispatch<TemplatesAction>, getState: () => State) => {
		const index: number = getTemplateIndex(getState(), template.id || '')
		dispatch({
			type: 'START_REMOVE_TEMPLATE',
			template,
			index,
		})
		try {
			if (template.id) {
				const params = {
					templateId: template.id,
				}
				await templateApi.delete(params)
				dispatch({
					type: 'FINISH_REMOVE_TEMPLATE',
					template,
					index,
					success: true,
				})
			} else {
				dispatch({
					type: 'FINISH_REMOVE_TEMPLATE',
					template,
					index,
					success: false,
					serverError: {
						message: 'template doesnt have an id',
					},
				})
			}
		} catch (error) {
			dispatch({
				type: 'FINISH_REMOVE_TEMPLATE',
				template,
				index,
				success: false,
				serverError: error,
			})
		}
	}
}

let createDraftId: number = 0
export function createDraft(template: Template) {
	return async (dispatch: Dispatch<TemplatesAction>) => {
		const createId: number = createDraftId++
		const loaderId = 'templates_createDraft'
		dispatch({
			type: 'START_CREATE_DRAFT',
			template,
			createId,
		})
		try {
			beginTask(loaderId)
			if (template.id) {
				const params = { templateId: template.id }
				const confirmedDocument: AccountingDocument = await templateDraftApi.post(params)
				return dispatch({
					type: 'FINISH_CREATE_DRAFT',
					template,
					confirmedDocument,
					createId,
					success: true,
				})
			} else {
				return dispatch({
					type: 'FINISH_CREATE_DRAFT',
					template,
					createId,
					confirmedDocument: null,
					success: false,
					serverError: {
						message: 'template doesnt have an id',
					},
				})
			}
		} catch (error) {
			return dispatch({
				type: 'FINISH_CREATE_DRAFT',
				template,
				createId,
				confirmedDocument: null,
				success: false,
				serverError: error,
			})
		} finally {
			endTask(loaderId)
		}
	}
}
