/* @flow */

import { tag as tagApi, tags as tagsApi } from 'modules/common/models/api-model'
import { loadBranches, loadProjects } from 'modules/organization/actions'
import type { Dispatch, OrganizationAction, Tag } from 'types'
import type { Action as ReportingAction } from './reporting-action-types'

export function deleteTag(tagId: string) {
	return async (dispatch: Dispatch<ReportingAction | OrganizationAction>) => {
		dispatch({
			type: 'START_DELETING_TAG',
		})
		try {
			await tagApi.delete({ tagId })
			await dispatch({
				type: 'FINISH_DELETING_TAG',
				tagId,
			})

			dispatch(loadBranches())
			dispatch(loadProjects())
		} catch (serverError) {
			await dispatch({
				serverError,
				type: 'FINISH_DELETING_TAG',
				tagId: null,
			})
		}
	}
}

export function editTag(tagId: string, tag: Tag) {
	return async (dispatch: Dispatch<ReportingAction | OrganizationAction>) => {
		dispatch({
			type: 'START_EDITING_TAG',
		})
		try {
			const result: Tag = await tagApi.put({ tagId }, tag)
			dispatch({
				type: 'FINISH_EDITING_TAG',
				tag: result,
			})

			dispatch(loadBranches())
			dispatch(loadProjects())
		} catch (serverError) {
			dispatch({
				serverError,
				type: 'FINISH_EDITING_TAG',
				tag: null,
			})
		}
	}
}

export function createTag(tag: Tag) {
	return async (dispatch: Dispatch<ReportingAction | OrganizationAction>) => {
		dispatch({
			type: 'START_ADDING_TAG',
			tag: tag,
		})
		try {
			const result: Tag = await tagsApi.post(tag)
			dispatch({
				type: 'FINISH_ADDING_TAG',
				tag: result,
			})
		} catch (serverError) {
			dispatch({
				type: 'FINISH_ADDING_TAG',
				tag: null,
				serverError,
			})
		}
	}
}
