/* @flow */
/** @jsx jsx */

import { jsx } from '@emotion/core'
import { PureComponent, type Node } from 'react'
import memoize from 'memoize-one'
import Select from 'react-select'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import { autoTestId, passAutoTestId, type AutoTestProps } from 'utils/tests/autotest'
import DropdownIndicator from 'components/select-next/dropdown-indicator'
import type { AutoCompleteActionMeta } from '../auto-complete'
import FavoriteOption, { type FavoriteOptionType } from '../favorite-select/favorite-option'
import InfoIcon from 'components/svg-icons/trivi/input/info'
import Tooltip from 'components/tooltip'
import { colors } from 'variables'
import MultilineInput from './MultilineInput'

type Props = {|
	...AutoTestProps,
	...WithTranslateProps,
	options?: ?Array<FavoriteOptionType>,
	error?: Node,
	label?: string,
	info?: string,
	autoFocus?: boolean,
	compact?: boolean,
	inline?: boolean,
	autoWidth?: boolean,
	disabled?: boolean,
	placeholder?: string,
	value?: ?string,
	portal?: boolean,
	tabIndex?: number,
	tooltip?: ?string,
	multiline?: boolean,
	onChange: (value: string) => void,
	onSelect: (option: FavoriteOptionType, action: AutoCompleteActionMeta) => void,
	onType?: (value: string, action: AutoCompleteActionMeta) => void,
	newLineTabIndex?: number,
	isInLastLineItem?: boolean,
	onLastLineEnter?: () => void,
|}

type State = {|
	open: boolean,
	wasArrowUsed: boolean,
|}

const MENU_MIN_WIDTH = 100

class FavoriteSelect extends PureComponent<Props, State> {
	components = {
		DropdownIndicator,
		Option: FavoriteOption,
		Input: MultilineInput,
	}
	isBlurDisabled: boolean = false
	select: any = null

	state = {
		open: false,
		wasArrowUsed: false,
	}

	handleKeyPress = async (event: KeyboardEvent) => {
		if ('Escape' === event.key) {
			event.preventDefault()
			this.props.onChange(this.select.state.inputValue)
			this.select && this.select.blur()
		}

		if ('Enter' === event.key) {
			event.preventDefault()
			if (!(this.select && this.select.select.state.focusedOption) && !this.select.state.inputValue) {
				return
			}
			if (this.state.wasArrowUsed) {
				if (this.select.select.state.focusedOption) {
					this.onChange(this.select.select.state.focusedOption, { action: 'select-option' })
				} else {
					this.props.onChange(this.select.state.inputValue)
				}
			}
			this.select.blur()
			if (!this.state.wasArrowUsed) {
				this.props.onChange(this.select.state.inputValue)
				this.select && this.select.blur()
				if (this.props.isInLastLineItem) {
					this.props.onLastLineEnter && (await this.props.onLastLineEnter())
				}
			}
			if (this.props.tabIndex) {
				document.querySelectorAll(`[tabindex="${this.props.tabIndex + 1}"]`)[0].focus()
			}
			this.setState({ wasArrowUsed: false })
		}

		if ('Tab' === event.key) {
			event.preventDefault()
			if (!(this.select && this.select.select.state.focusedOption) && !this.select.state.inputValue) {
				return
			}
			if (this.state.wasArrowUsed) {
				if (this.select.select.state.focusedOption) {
					this.onChange(this.select.select.state.focusedOption, { action: 'select-option' })
				} else {
					this.props.onChange(this.select.state.inputValue)
				}
			} else {
				this.props.onChange(this.select.state.inputValue)
			}
			this.select.blur()
			document.querySelectorAll(`[tabindex="${this.props.tabIndex + 1}"]`)[0].focus()
			this.setState({ wasArrowUsed: false })
		}

		if ('ArrowUp' === event.key || 'ArrowDown' === event.key) {
			this.setState({ wasArrowUsed: true })
		}
	}

	getStyles = memoize(
		(
			compact?: boolean,
			inline?: boolean,
			multiline?: boolean,
			autoWidth?: boolean,
			disabled?: boolean,
			error?: boolean,
		) => {
			const isInvalid = !!error
			const theme = inline ? inlineTheme : normalTheme

			return {
				root: {
					position: 'relative',
					display: inline && autoWidth ? 'inline-flex' : 'block',
					maxWidth: '100%',
				},
				tooltip: {
					maxWidth: '100%',
					cursor: 'text',
				},
				label: {
					display: 'block',
					userSelect: 'none',
					color: colors.black,
					whiteSpace: 'nowrap',
					fontSize: 14,
					paddingBottom: 7,
					lineHeight: '17px',
				},
				indicatorSeparator: () => ({ display: 'none' }),
				indicatorsContainer: (provided: Object) => ({
					...provided,
					marginRight: inline ? 0 : compact ? 2 : 8,
				}),
				dropdownIndicator: (provided: Object) => ({
					...provided,
					display: inline ? 'none' : provided.display,
					padding: compact ? 0 : provided.padding,
				}),
				clearIndicator: (provided: Object) => ({
					...provided,
					marginRight: compact ? 0 : 4,
				}),
				control: (provided: Object, state: Object) => ({
					...provided,
					...(isInvalid
						? theme.invalid
						: state.isDisabled
						? theme.disabled
						: state.isFocused
						? theme.focused
						: theme.normal),
					minHeight: inline ? 22 : compact ? 32 : 46,
					height: compact ? 32 : provided.height,
					borderRadius: compact || inline ? 2 : 3,
					boxShadow: 'none',
					fontSize: 14,
					cursor: 'text',
					'.select__single-value': {
						whiteSpace: 'pre-wrap',
						color:
							state.isFocused && state.menuIsOpen
								? colors.grey
								: disabled && !inline
								? colors.disabledText
								: colors.black,
						opacity: state.isFocused && state.menuIsOpen ? 0 : 1,
					},
				}),
				valueContainer: (provided: Object) => ({
					...provided,
					paddingTop: 1,
					paddingBottom: 1,
					paddingLeft: inline ? 1 : compact ? 8 : 15,
					paddingRight: inline ? 1 : compact ? 8 : 15,
					flexDirection: autoWidth ? 'row-reverse' : provided.flexDirection,
					minHeight: inline ? 22 : provided.minHeight,
				}),
				placeholder: (provided: Object, state: Object) => ({
					...provided,
					color: colors.blackFaded40,
					position: autoWidth && !state.hasValue ? 'static' : provided.position,
					transform: autoWidth && !state.hasValue ? 'none' : provided.transform,
					marginLeft: autoWidth ? -4 : provided.marginLeft,
					cursor: 'text',
				}),
				input: (provided: Object, state: Object) => ({
					...provided,
					fontSize: 14,
					color: colors.black,
					padding: inline ? 0 : provided.padding,
					margin: inline ? '0 2px' : provided.margin,
					height: inline ? 20 : provided.height,
					lineHeight: inline ? '20px' : provided.lineHeight,
					position: inline && multiline && !state.isFocused ? 'absolute' : provided.position,
					div: {
						overflow: 'hidden !important',
					},
					input: {
						fontFamily: 'inherit',
					},
				}),
				singleValue: (provided: Object) => ({
					...provided,
					position: inline && multiline ? 'relative' : autoWidth ? 'static' : provided.position,
					transform: inline && multiline ? 'none' : autoWidth ? 'none' : provided.transform,
					whiteSpace: inline && multiline ? 'normal' : provided.whiteSpace,
					marginLeft: autoWidth ? -3 : provided.marginLeft,
					marginRight: autoWidth ? 5 : provided.marginRight,
					textOverflow: autoWidth ? 'clip' : 'ellipsis',
					lineHeight: inline ? '20px' : provided.lineHeight,
					color: colors.black,
				}),
				menuPortal: (provided: Object) => ({
					...provided,
					zIndex: 2000,
				}),
				menu: (provided: Object) => ({
					...provided,
					borderWidth: 1,
					borderStyle: 'solid',
					borderColor: colors.gray400,
					borderRadius: compact || inline ? 2 : 3,
					boxShadow: `${colors.blackFaded8} 4px 4px 0`,
					width: 'max-content',
					minWidth: autoWidth ? MENU_MIN_WIDTH : Math.max(MENU_MIN_WIDTH, provided.width),
					whiteSpace: autoWidth ? 'nowrap' : provided.whiteSpace,
					overflow: 'hidden',
					maxWidth: 460,
					zIndex: 2000,
				}),
				menuList: (provided: Object) => ({
					...provided,
					overflowX: 'hidden',
					overflowY: 'auto',
					maxWidth: 460,
				}),
				noOptionsMessage: (provided: Object) => ({
					...provided,
					fontSize: 14,
					color: colors.grey,
					height: 40,
					lineHeight: '24px',
					position: 'relative',
					background: colors.white,
					zIndex: 2,
				}),
				info: {
					position: 'absolute',
					top: -10,
					right: -10,
				},
			}
		},
	)

	onMenuOpen = () => {
		this.setState({ open: true })
	}

	onMenuClose = () => {
		this.isBlurDisabled = true
		this.select && this.select.blur()
		this.isBlurDisabled = false
		this.setState({ open: false })
	}

	onChange = (option: FavoriteOptionType, meta: AutoCompleteActionMeta) => {
		!option.disabled && this.props.onSelect(option, meta)
	}

	onBlur = () => {
		if (!this.select.select.state.focusedOption && this.select.state.inputValue) {
			this.props.onChange(this.select.state.inputValue)
		}
	}

	onFocus = () => {
		if (this.select && this.props.value) {
			this.select.setState &&
				this.select.setState({
					inputValue: this.props.value,
				})
			this.select.select &&
				this.select.select.setState &&
				this.select.select.setState({
					inputValue: this.props.value,
				})
		}
	}

	bindSelect = (element: ?HTMLElement) => {
		this.select = element
	}

	getNoOptionsMessage = () => null

	formatCreateLabel = (value: string) => {
		return this.props.t('application.useOption', { value })
	}

	getFavoriteOptions = memoize((options: Array<FavoriteOptionType>) => {
		return options.filter((option: FavoriteOptionType) => option.isFavorite)
	})

	getRegularOptions = memoize((options: Array<FavoriteOptionType>) => {
		return options
			.filter((option: FavoriteOptionType) => !option.isFavorite)
			.map((option: FavoriteOptionType) => ({ ...option, isFavorite: false }))
	})

	getSortedOptions = memoize((options: ?Array<FavoriteOptionType>) => {
		if (!options) return []
		const favoriteOptions = this.getFavoriteOptions(options)
		const regularOptions = this.getRegularOptions(options)
		if (favoriteOptions.length) {
			favoriteOptions[0].isFirst = true
			favoriteOptions[favoriteOptions.length - 1].isLast = true
		}
		return [...favoriteOptions, ...regularOptions]
	})

	render() {
		const styles = this.getStyles(
			this.props.compact,
			this.props.inline,
			this.props.multiline,
			this.props.autoWidth,
			this.props.disabled,
			!!this.props.error,
		)

		const value = this.props.value ? { value: this.props.value, label: this.props.value } : null

		return (
			<div css={styles.root} {...autoTestId(this.props.autoTestId)}>
				{this.props.label && <label css={styles.label}>{this.props.label}</label>}
				<Tooltip
					label={!this.props.disabled ? this.props.error || this.props.tooltip : this.props.tooltip}
					disabled={this.state.open}
					wrapperStyle={styles.tooltip}
				>
					<Select
						ref={this.bindSelect}
						options={this.getSortedOptions(this.props.options)}
						isDisabled={this.props.disabled}
						autoFocus={this.props.autoFocus}
						placeholder={this.props.placeholder || ''}
						onChange={this.onChange}
						noOptionsMessage={this.getNoOptionsMessage}
						formatCreateLabel={this.formatCreateLabel}
						components={this.components}
						value={value}
						menuPortalTarget={this.props.portal ? document.getElementById('render-to-layer') : null}
						closeMenuOnScroll={this.props.portal}
						onInputChange={this.props.onType}
						tabIndex={this.props.tabIndex}
						menuShouldScrollIntoView
						classNamePrefix={'select'}
						menuPlacement={'bottom'}
						onMenuClose={this.onMenuClose}
						onMenuOpen={this.onMenuOpen}
						onFocus={this.onFocus}
						onBlur={this.onBlur}
						onKeyDown={this.handleKeyPress}
						styles={styles}
						openMenuOnFocus
						isSearchable
					/>
					{this.props.info && (
						<div css={styles.info}>
							<Tooltip
								label={this.props.info}
								renderToLayer
								style={styles.tooltip}
								{...passAutoTestId(this.props.autoTestId, 'tooltip')}
							>
								<InfoIcon color={colors.gray} hoverColor={colors.gray900} size={18} />
							</Tooltip>
						</div>
					)}
				</Tooltip>
			</div>
		)
	}
}

const normalTheme = {
	invalid: {
		backgroundColor: colors.redFaded4,
		borderColor: colors.red,
		'&:hover': {
			borderColor: colors.red,
		},
	},
	disabled: {
		backgroundColor: '#f9f9f9',
		borderColor: colors.grayLight200,
	},
	focused: {
		backgroundColor: colors.blue100Faded40,
		borderColor: colors.blue,
		'&:hover': {
			borderColor: colors.blue,
		},
	},
	normal: {
		backgroundColor: colors.white,
		borderColor: colors.gray400,
		'&:hover': {
			borderColor: colors.blackFaded40,
		},
	},
}

const inlineTheme = {
	invalid: {
		backgroundColor: colors.redFaded4,
		borderColor: colors.red,
		'&:hover': {
			borderColor: colors.red,
		},
	},
	disabled: {
		backgroundColor: colors.transparent,
		borderColor: colors.transparent,
	},
	focused: {
		backgroundColor: colors.blue100,
		borderColor: colors.blue,
		'&:hover': {
			backgroundColor: colors.blue100,
			borderColor: colors.blue,
		},
	},
	normal: {
		backgroundColor: colors.transparent,
		borderColor: colors.transparent,
		'&:hover': {
			borderColor: colors.blueFaded40,
			backgroundColor: colors.blue100Faded40,
		},
	},
}

export default withTranslate(FavoriteSelect)
