// @flow

// import { CARD_TYPES } from '../constants'
// import TriviStoreDialog from '../components/trivi-store-dialog'
// import TriviStoreDialogKb from '../components/trivi-store-dialog-kb'
import type { TriviStoreCard, PortalLanguage } from 'types'

// export const getDialogComponent = (card: ?TriviStoreCard) => {
// 	switch (card && card.type) {
// 		case CARD_TYPES.KB:
// 			return TriviStoreDialogKb
//
// 		default:
// 			return TriviStoreDialog
// 	}
// }

export const getCardUrl = (card: ?TriviStoreCard, language: ?PortalLanguage): ?string => {
	return (card && card.button && card.button.url && card.button.url[language || 'default']) || null
}
