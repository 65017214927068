/* @flow */

import { connect } from 'react-redux'
import type { ContactMeAction, ChatAction, Dispatch, State } from 'types'
import { toggleForm } from '../actions'

import Icon from '../components/icon'
import { getContactMeOpen } from '../selectors'

type StateProps = {|
	isOpen: boolean,
|}
const mapStateToProps = (state: State): StateProps => {
	return {
		isOpen: getContactMeOpen(state),
	}
}

type DispatchProps = {|
	onClick: () => void,
|}
const mapDispatchToProps = (dispatch: Dispatch<ContactMeAction | ChatAction>): DispatchProps => {
	return {
		onClick: () => {
			dispatch(toggleForm())
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Icon)
