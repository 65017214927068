//@flow

import storage from 'utils/local-storage'
import type { State, PaymentOrder, BankAccount, BankAccountStatementOptions, StatementsView } from 'types'
import { STATEMENTS_VIEW_KEY } from '../constants'

export type PaymentOrderFile = {|
	fileId?: string,
	title?: string,
	message?: string,
	state?: number,
	type?: number,
|}

export const getOtherFiles = (po: PaymentOrder): Array<PaymentOrderFile> => {
	return (po.files || []).filter((file: PaymentOrderFile) => {
		return file.type === 1
	})
}

export const getNdpFiles = (po: PaymentOrder): Array<PaymentOrderFile> => {
	return (po.files || []).filter((file: PaymentOrderFile) => {
		return file.type === 2
	})
}

export const getPdfFiles = (po: PaymentOrder): Array<PaymentOrderFile> => {
	return (po.files || []).filter((file: PaymentOrderFile) => {
		return file.type === 3
	})
}

export const getBankAccounts = (state: State): ?Array<BankAccount> => {
	return state.bank.bankAccounts.data
}

export function getBankAccountStatementOptions(state: State, id: string): ?BankAccountStatementOptions {
	return state.bank.statementOptions.data[id]
}

export function getBackAccountCode(state: State): ?string {
	return state.bank && state.bank.bankAccount && state.bank.bankAccount.data && state.bank.bankAccount.data.accountCode
}

export function getStatementsView(): StatementsView {
	return storage.get(STATEMENTS_VIEW_KEY) || 'all'
}
