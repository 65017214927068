/* @flow */

import type {
	AccountingDocument,
	UnmatchedPayment,
	UnmatchedPaymentMatch,
	UnmatchedPaymentMatchesRequestBulk as UnmatchedPaymentMatches,
} from 'types'

export const WAITING_FOR_ACCOUNTANT = 2

export function isWaitingForAccountant(unmatchedPayment: UnmatchedPayment): boolean {
	return WAITING_FOR_ACCOUNTANT === unmatchedPayment.waitingFor
}

export function getUnmatchedPaymentComments(unmatchedPayment: UnmatchedPayment): ?Array<UnmatchedPaymentMatch> {
	return (
		unmatchedPayment.matches &&
		unmatchedPayment.matches.generalMatches &&
		unmatchedPayment.matches.generalMatches.filter(
			(match: UnmatchedPaymentMatch) => !match.accountingDocumentId && match.note,
		)
	)
}

export function getUnmatchedPaymentCommentCount(unmatchedPayment: UnmatchedPayment): number {
	return (getUnmatchedPaymentComments(unmatchedPayment) || []).length
}

export function getUnmatchedPaymentAttachments(unmatchedPayment: UnmatchedPayment): ?Array<UnmatchedPaymentMatch> {
	return (
		unmatchedPayment.matches &&
		unmatchedPayment.matches.generalMatches &&
		unmatchedPayment.matches.generalMatches.filter((match: UnmatchedPaymentMatch) => {
			return !!match.accountingDocumentId
		})
	)
}

export function getUnmatchedPaymentAttachmentCount(unmatchedPayment: UnmatchedPayment): number {
	return (getUnmatchedPaymentAttachments(unmatchedPayment) || []).length
}

export function hasUnmatchedPaymentCommentsOrAttachments(
	comments: ?Array<UnmatchedPaymentMatch>,
	attachments: ?Array<UnmatchedPaymentMatch>,
): boolean {
	return !!((comments && comments.length) || (attachments && attachments.length))
}
getUnmatchedPaymentCommentCount

export function calculateMatchesDiff(
	originalMatch: Array<UnmatchedPaymentMatch>,
	accountingDocumentIds: Array<string>,
): UnmatchedPaymentMatches {
	const matchesToBeCreated = accountingDocumentIds
		.filter(
			(accountingDocumentId: string) =>
				'undefined' ===
				typeof originalMatch.find(
					(match: UnmatchedPaymentMatch) => match.accountingDocumentId === accountingDocumentId,
				),
		)
		.map((accountingDocumentId: string) => ({ accountingDocumentId }))

	const matchesToBeDeleted = originalMatch
		.filter((match: UnmatchedPaymentMatch) => -1 === accountingDocumentIds.indexOf(match.accountingDocumentId))
		.map((match: UnmatchedPaymentMatch) => match.id || '')

	return { matchesToBeCreated, matchesToBeDeleted }
}

export function addAccountingDocuments(
	unmatchedPayment: UnmatchedPayment,
	accountingDocuments: Array<AccountingDocument>,
) {
	const accountingDocumentIds = accountingDocuments
		.filter((ad: AccountingDocument) => !!ad.id)
		.map((ad: AccountingDocument) => ad.id || '')

	return calculateMatchesDiff(getUnmatchedPaymentAttachments(unmatchedPayment) || [], accountingDocumentIds)
}

export function addAccountingDocumentIds(unmatchedPayment: UnmatchedPayment, accountingDocumentIds: Array<string>) {
	const originalMatch = getUnmatchedPaymentAttachments(unmatchedPayment)

	const matchesToBeCreated = accountingDocumentIds
		.filter(
			(accountingDocumentId: string) =>
				!originalMatch ||
				'undefined' ===
					typeof originalMatch.find(
						(match: UnmatchedPaymentMatch) => match.accountingDocumentId === accountingDocumentId,
					),
		)
		.map<any>((accountingDocumentId: string) => ({ accountingDocumentId }))

	return { matchesToBeCreated }
}
