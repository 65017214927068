import React from 'react'
import pure from 'recompose/pure'
import SvgIcon from 'components/SvgIcon'

let Flag = props => (
	<SvgIcon {...props}>
		<g fillRule="evenodd">
			<path d="M17.25,5.25 C17.3437505,5.25 17.4687492,5.24218758 17.625,5.2265625 C17.7812508,5.21093742 17.9062495,5.18750016 18,5.15625 L18,13.265625 C17.9062495,13.2968752 17.7812508,13.3203124 17.625,13.3359375 C17.4687492,13.3515626 17.3437505,13.359375 17.25,13.359375 C16.2187448,13.4531255 15.6093759,13.5 15.421875,13.5 C14.5156205,13.5 13.4375063,13.3593764 12.1875,13.078125 C10.4062411,12.671873 9.40625109,12.46875 9.1875,12.46875 C8.21874516,12.46875 7.40625328,12.5781239 6.75,12.796875 L6.75,19.5 L6,19.5 L6,5.15625 L6.140625,5.015625 C6.82812844,4.67187328 7.84374328,4.5 9.1875,4.5 C10.0312542,4.5 11.0781187,4.64062359 12.328125,4.921875 C14.1406341,5.26562672 15.1562489,5.4375 15.375,5.4375 C15.6562514,5.4375 16.0312477,5.40625031 16.5,5.34375 C16.9687523,5.28124969 17.2187498,5.25 17.25,5.25 Z" />
		</g>
	</SvgIcon>
)
Flag = pure(Flag)

export default Flag
