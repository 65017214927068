// @flow

import { connect } from 'react-redux'
import type {
	AccountingDocumentListAction,
	AccountingDocumentFromScanRequest,
	Dispatch,
	Scan,
	ScanFile,
	State,
	UploadScanAction,
	AccountingDocumentCategory,
	AccountingDocumentReduxAction,
	SettingsAction,
} from 'types'
import { uploadScan, createAccountingDocumentWithScans } from 'modules/accounting-document/actions'
import { loadAccountingDocumentCategories } from 'modules/settings/actions'
import { getAccountingDocumentCategories } from 'modules/settings/selectors'
import type { ErrorScanUpload } from 'modules/accounting-document/reducers/upload-scan'
import UploadScan from '../../components/upload-scan/upload-scan'

type OwnProps = {|
	onCreateDocumentsFromScans?: (accountingDocumentIds: Array<string>) => void,
	onRequestClose: (afterSave?: boolean) => void,
|}

type StateProps = {|
	scans: Array<Scan>,
	errorScans: Array<ErrorScanUpload>,
	uploading: boolean,
	documentCategories: ?Array<AccountingDocumentCategory>,
|}

function mapStateToProps(state: State): StateProps {
	return {
		scans: state.accountingDocument.uploadScan.scans,
		errorScans: state.accountingDocument.uploadScan.errorScans,
		uploading: state.accountingDocument.uploadScan.uploadingScanIds.length > 0,
		documentCategories: getAccountingDocumentCategories(state),
	}
}

type DispatchProps = {|
	uploadScans: (scans: Array<Scan>) => Promise<any>,
	uploadScan: (file: File, documentCategory: ?string, customUploadId?: number) => Promise<any>,
	loadAccountingDocumentCategories: () => void,
|}

type Action = UploadScanAction | AccountingDocumentListAction | AccountingDocumentReduxAction | SettingsAction
function mapDispatchToProps(dispatch: Dispatch<Action>, ownProps: OwnProps): DispatchProps {
	return {
		uploadScans: async (scans: Array<Scan>) => {
			const scansBody: Array<AccountingDocumentFromScanRequest> = []
			scans.forEach((item: Scan) => {
				let scan: AccountingDocumentFromScanRequest = {
					files: [],
					project: item.project || '',
					cashRegisterId: item.cashRegisterId || '',
					customerInstructions: item.customerInstructions || '',
					branch: item.branch || '',
					paymentType: item.paymentType ? parseInt(item.paymentType) : undefined,
					categoryId: item.documentCategory || '',
				}

				item.files.forEach((file: ScanFile) => {
					scan && scan.files && scan.files.push(file.fileId)
				})
				scansBody.push(scan)
			})
			await dispatch(createAccountingDocumentWithScans(scansBody, ownProps.onCreateDocumentsFromScans))
		},
		uploadScan: async (file: File, documentCategory: ?string, customUploadId?: number): Promise<any> => {
			return dispatch(uploadScan(file, documentCategory, customUploadId))
		},
		loadAccountingDocumentCategories: () => {
			dispatch(loadAccountingDocumentCategories())
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(UploadScan)
