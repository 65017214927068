// @flow

import type { AccountingDocumentCategory } from 'types'
import { EMPTY_ARRAY } from 'trivi-constants'

export function sortAccountingDocumentCategories(
	accountingDocumentCategories: ?Array<AccountingDocumentCategory>,
): Array<AccountingDocumentCategory> {
	return accountingDocumentCategories
		? accountingDocumentCategories.sort((a: AccountingDocumentCategory, b: AccountingDocumentCategory) => {
				let aValue = getCategorySortValue(a)
				let bValue = getCategorySortValue(b)
				if (aValue < bValue) return -1
				if (aValue > bValue) return 1
				return 0
		  })
		: EMPTY_ARRAY
}

function getCategorySortValue(category: AccountingDocumentCategory): string {
	return category.name.toLowerCase()
}
