/* @flow */
import { connect } from 'react-redux'
import CashbotGridlineStatusPure, { type OwnProps as CashbotGridlineStatusProps } from './cashbot-gridline-status-pure'
import { getCashbotInvoiceId, getCashbotInvoice, getIsCashbotInvoiceLoading } from 'modules/cashbot/selectors'
import withAutoload, { type AutoloadProps } from 'wrappers/with-autoload'
import { loadCashbotInvoice } from '../../actions'
import type { CashbotAction } from '../../actions/action-types'
import type { CashbotInvoice } from '../../types'
import type { State } from 'types'

type OwnProps = {|
	accDocId: string,
|}

type StateProps = {|
	cashbotInvoiceId: ?number,
	isCashbotInvoiceLoading: boolean,
	cashbotInvoice: ?CashbotInvoice,
|}

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => {
	return {
		cashbotInvoiceId: getCashbotInvoiceId(state, ownProps.accDocId),
		isCashbotInvoiceLoading: getIsCashbotInvoiceLoading(state, ownProps.accDocId),
		cashbotInvoice: getCashbotInvoice(state, ownProps.accDocId),
	}
}

type DispatchProps = {|
	loadCashbotInvoice: () => void,
|}
const mapDispatchToProps = (dispatch: Dispatch<CashbotAction>, ownProps: OwnProps): DispatchProps => {
	return {
		loadCashbotInvoice: () => {
			ownProps.accDocId && dispatch(loadCashbotInvoice(ownProps.accDocId))
		},
	}
}

const mergeProps = (
	stateProps: StateProps,
	dispatchProps: DispatchProps,
): {| ...CashbotGridlineStatusProps, ...AutoloadProps |} => {
	return {
		loading: stateProps.isCashbotInvoiceLoading,
		cashbotStatus: stateProps.cashbotInvoice && stateProps.cashbotInvoice.status,
		autoload: () => {
			if (stateProps.cashbotInvoiceId) {
				dispatchProps.loadCashbotInvoice()
			}
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(withAutoload(CashbotGridlineStatusPure))
