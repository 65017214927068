/* @flow */

import { connect } from 'react-redux'
import type { AccountingDocument, State, AccountingDocumentPrintingInfo } from 'types'
import LineItemsHeader from 'modules/accounting-document/components/invoice-elements/line-items-header'
import { Number_AccountingDocumentAssignedType, Number_AccountingDocumentDirection } from '../../../../types/convertor'
import { getPrintingInfo } from 'modules/accounting-document/domain/accounting-document'
import { getAccountingDocumentDefaults } from 'modules/settings/selectors'
import { getAppLanguage, toAvailableAccountingDocumentLanguage } from 'locales'

type OwnProps = {|
	accountingDocument: AccountingDocument,
	onChange: (printing: $Shape<AccountingDocumentPrintingInfo>) => void,
|}

type StateProps = {|
	note: ?string,
	defaultNote: ?string,
|}

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => {
	const language = toAvailableAccountingDocumentLanguage(ownProps.accountingDocument.language || getAppLanguage())
	const direction = Number_AccountingDocumentDirection(ownProps.accountingDocument.direction)
	const type = Number_AccountingDocumentAssignedType(ownProps.accountingDocument.type)
	const defaults = direction && type && getAccountingDocumentDefaults(state, direction, type)
	const printing = defaults && language && getPrintingInfo(defaults, language)

	return {
		note: ownProps.accountingDocument.printing?.lineItemsHeader,
		defaultNote: printing?.lineItemsHeader || null,
	}
}

type DispatchProps = {|
	onChange: (printing: ?string) => void,
|}

const mapDispatchToProps = (dispatch: Dispatch<>, ownProps: OwnProps): DispatchProps => {
	return {
		onChange: (note: ?string) => {
			ownProps.onChange({ lineItemsHeader: note ?? '' })
		},
	}
}

const mergeProps = (stateProps: StateProps, dispatchProps: DispatchProps) => {
	return {
		note: stateProps.note,
		defaultNote: stateProps.defaultNote,
		onChange: dispatchProps.onChange,
	}
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(LineItemsHeader)
