// @flow

import { connect } from 'react-redux'
import { buildPrivateUrl } from 'lib/apiHandler'
import DownloadButton from 'modules/accounting-document/components/invoice-elements/download-button'

type DispatchProps = {|
	onDownload: (id: string) => void,
|}

function mapDispatchToProps(): DispatchProps {
	return {
		onDownload: (uniqueId: string) => {
			const url = buildPrivateUrl(`accountingdocuments/uniqueid/${uniqueId}/pdf`)
			window.location.href = url
		},
	}
}

export default connect(undefined, mapDispatchToProps)(DownloadButton)
