// @flow

import React, { Component } from 'react'
import type { GenericReport } from 'types'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import FileIcon from 'components/file-icon'
import DeleteDialog from 'components/delete-dialog'
import DeleteIcon from 'components/svg-icons/action/delete'
import IconButton from 'components/icon-button'
import styles from './file-list.css'
import IconMenu from 'components/icon-menu'
import MenuItem from 'components/menu-item'
import FileDownload from '../../../../components/svg-icons/file/download'

type Props = {
	...WithTranslateProps,
	reports: Array<GenericReport>,
	loadReports: (pageSize?: number, page?: number) => ?Promise<*>,
	downloadFile: (fileId: string, fileName: string) => Promise<*>,
	openFile: (fileId: string, fileName: string) => Promise<*>,
	deleteFile: (reportId: string) => Promise<*>,
}

type ComponentState = {
	intervalId: ?IntervalID,
}

class FileList extends Component<Props, ComponentState> {
	UNSAFE_componentWillMount() {
		this.loadReports()

		let intervalId = setInterval(this.loadReports, 30 * 1000)
		this.setState({
			intervalId,
		})
	}

	componentWillUnmount() {
		if (this.state.intervalId) {
			clearInterval(this.state.intervalId)
		}
	}

	loadReports = () => {
		this.props.loadReports && this.props.loadReports(10, 1)
	}
	onDeleteFile = async (reportId: string) => {
		try {
			await DeleteDialog()
			if (reportId) {
				this.props.deleteFile && this.props.deleteFile(reportId.toString())
			}
		} catch (e) {
			// Do nothing
		}
	}

	handleFileDownload = (fileId: number, fileName: string) => () => {
		this.props.downloadFile(fileId.toString(), fileName)
	}
	handleOpenFile = (fileId: number, fileName: string) => () => {
		this.props.openFile(fileId.toString(), fileName)
	}
	handleDeleteFile = (reportId: string) => () => {
		this.onDeleteFile(reportId)
	}
	stopPropagation = (event: SyntheticEvent<HTMLElement>) => event.stopPropagation()

	renderActionMenu = (report: GenericReport) => {
		const { t } = this.props
		return (
			<span>
				<IconMenu
					autoTestId="file-list-item-action"
					onClick={this.stopPropagation}
					arrow={'topRight'}
					targetOrigin={{ horizontal: 'right', vertical: 'top' }}
					offsetX={19}
					context
				>
					<MenuItem
						icon={<FileDownload />}
						tall
						// $FlowFixMe
						onClick={report.fileIds && this.handleFileDownload(report.fileIds[0], report.name)}
						primaryText={t('grid.action.download')}
					/>
					<MenuItem
						icon={<DeleteIcon />}
						tall
						// $FlowFixMe
						onClick={report.id && this.handleDeleteFile(report.id)}
						primaryText={t('grid.action.delete')}
					/>
				</IconMenu>
			</span>
		)
	}

	render() {
		const { t, reports } = this.props
		if (!reports.length) return null
		return (
			<div className={styles.root}>
				<div className={styles.headline}>{t('dashboard.widget.genericReports.results')}</div>
				<div className={styles.grid}>
					{reports.map((r: GenericReport) => {
						return (
							<div className={styles.row} key={r.id || ''}>
								<div
									onClick={r.fileIds && r.name && this.handleOpenFile(r.fileIds[0], r.name)}
									className={r.processingState == 2 ? styles.name : styles.info}
								>
									{r.processingState == 2 ? r.name : t('dashboard.widget.genericReports.processing')}
								</div>
								{r.processingState == 2 && <div className={styles.actions}>{this.renderActionMenu(r)}</div>}
							</div>
						)
					})}
				</div>
			</div>
		)
	}
}

export default withTranslate(FileList)
