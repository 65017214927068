/* @flow */

import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { userHasAccess } from 'permissions'
import type { Dispatch, State, TaskAction, UserAction, DataGridNextAction, UserProfile, Task, Filter } from 'types'
import { changeTaskListView, removeTask, updateTask } from '../actions'
import TaskList from '../components/task-list'
import { taskEditRoute } from 'modules/task/routing/routes'
import { refreshGridData } from 'modules/data-grid-next/actions'
import type { TaskView } from 'modules/task/types'

type StateProps = {|
	currentUser: ?UserProfile,
	listView: TaskView,
	canSeeAllTasks: boolean,
|}

function mapStateToProps(state: State): StateProps {
	return {
		currentUser: state.user.me.data,
		listView: state.task.listView,
		canSeeAllTasks: userHasAccess(state, 'seeAllTasks'),
	}
}

type DispatchProps = {|
	editTask: string => void,
	deleteTask: string => Promise<void>,
	changeListView: (view: TaskView) => void,
	markTaskAsDone: (task: Task, defaultFilter?: Filter) => Promise<void>,
|}

function mapDispatchToProps(dispatch: Dispatch<TaskAction | UserAction | DataGridNextAction>): DispatchProps {
	return {
		markTaskAsDone: async (task: Task, defaultFilter?: Filter): Promise<void> => {
			await dispatch(updateTask(task, { ...task, state: 2 }))
			await dispatch(refreshGridData('tasks', 'tasks', 'tasks', defaultFilter))
			return
		},
		editTask: (taskId: string): void => {
			dispatch(push(taskEditRoute(taskId)))
		},
		deleteTask: async (taskId: string, defaultFilter?: Filter): Promise<void> => {
			await dispatch(removeTask(taskId))
			await dispatch(refreshGridData('tasks', 'tasks', 'tasks', defaultFilter))
			return
		},
		changeListView: (view: TaskView) => {
			dispatch(changeTaskListView(view))
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(TaskList)
