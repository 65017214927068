// @flow

import type { AccountingDocumentVatRatesAction, Dispatch } from 'types'
import {
	accountingDocumentVatRates as accountingDocumentVatRatesApi,
	vatRatesAll as vatRatesAllApi,
} from 'modules/common/models/api-model'

export function loadAllVatRates() {
	return async (dispatch: Dispatch<AccountingDocumentVatRatesAction>) => {
		dispatch({
			type: 'LOAD_ALL_VAT_RATES_START',
		})
		try {
			const payload = await vatRatesAllApi.get({})
			dispatch({
				type: 'LOAD_ALL_VAT_RATES_FINISH',
				payload,
			})
		} catch (serverError) {
			dispatch({
				type: 'LOAD_ALL_VAT_RATES_FINISH',
				payload: null,
				serverError,
			})
		}
	}
}

export function fetchAccountingDocumentVatRates(accountingDocumentId?: ?string) {
	return async (dispatch: Dispatch<AccountingDocumentVatRatesAction>) => {
		if (!accountingDocumentId) return null
		try {
			const vatRates = await accountingDocumentVatRatesApi.get({ accountingDocumentId })
			dispatch({
				type: 'FINISH_LOAD_ACCOUNTING_DOCUMENT_VATRATES',
				accountingDocumentId,
				vatRates,
			})
		} catch (serverError) {
			dispatch({
				type: 'FINISH_LOAD_ACCOUNTING_DOCUMENT_VATRATES',
				accountingDocumentId,
				serverError,
			})
		}
	}
}
