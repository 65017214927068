/* @flow */

import type { Dispatch, State } from 'types'
import type { Action, Listener } from 'utils/events'
import { shutdownChat } from '../actions'

const listeners: Array<Listener> = [
	{
		actionType: 'LOGOUT',
		listener: (action: Action, getState: () => State, dispatch: Dispatch<any>) => {
			dispatch(shutdownChat())
		},
	},
]

export default listeners
