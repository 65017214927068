//@flow

import React, { Component } from 'react'
import type { AccountingDocumentLanguage } from 'types'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import AccountingDocumentLanguageSelector from 'modules/common/components/language-selector/accounting-document-language-selector'
import NumberInput from 'components/number-input'
import styles from './contact-settings.css'

type Props = {|
	...WithTranslateProps,
	defaultDuePeriod: ?number,
	onDefaultDuePeriodChange: (defaultDuePeriod: ?number) => void,
	language: ?AccountingDocumentLanguage,
	onLanguageChange: (?AccountingDocumentLanguage) => void,
	disabled?: boolean,
|}

type State = {|
	defaultDuePeriod: ?number,
|}

class ContactSettings extends Component<Props, State> {
	state: State = {
		defaultDuePeriod: this.props.defaultDuePeriod,
	}

	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		this.setState({
			defaultDuePeriod: nextProps.defaultDuePeriod,
		})
	}

	onDefaultDuePeriodChange = (event: SyntheticInputEvent<HTMLInputElement>, defaultDuePeriod: ?number) => {
		this.props.onDefaultDuePeriodChange && this.props.onDefaultDuePeriodChange(defaultDuePeriod)
		this.setState({ defaultDuePeriod: defaultDuePeriod })
	}

	render() {
		const { t } = this.props

		return (
			<div className={styles.root}>
				<div className={styles.heading}>{t('contactEdit.settings.headline')}</div>

				<div className={styles.item}>
					<NumberInput
						name="defaultDuePeriod"
						ref="defaultDuePeriod"
						onChange={this.onDefaultDuePeriodChange}
						value={this.state.defaultDuePeriod}
						labelText={t('contactEdit.settings.defaultDuePeriodLabel')}
						disabled={this.props.disabled}
						minValue={0}
						fullWidth
					/>
				</div>

				<div className={styles.item}>
					<AccountingDocumentLanguageSelector
						labelText={t('contactEdit.edit.language')}
						language={this.props.language}
						onChange={this.props.onLanguageChange}
						readonly={this.props.disabled}
					/>
				</div>
			</div>
		)
	}
}

export default withTranslate(ContactSettings)
