/* @flow */
/** @jsx jsx */

import { jsx } from '@emotion/core'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import GooglePlay from 'components/svg-icons/trivi/mobile/google-play'
import AppStore from 'components/svg-icons/trivi/mobile/app-store'
import RightArrow from 'components/svg-icons/hardware/keyboard-arrow-right'
import { colors } from 'variables'

type Props = {|
	...WithTranslateProps,
	onClick: () => void,
|}

function MobileAppInvitation(props: Props) {
	return (
		<div css={styles.root} onClick={props.onClick}>
			<GooglePlay />
			<div css={styles.space} />
			<AppStore />
			<div css={styles.space} />
			<p>{props.t('mobileAppInvitation.toggle')}</p>
			<div css={styles.space} />
			<RightArrow color={colors.white} hoverColor={colors.white} size={14} />
		</div>
	)
}

const styles = {
	root: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		cursor: 'pointer',
		color: colors.white,
		fontSize: '14px',
	},
	space: {
		width: 10,
	},
}

export default withTranslate(MobileAppInvitation)
