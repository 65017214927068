/* @flow */
/** @jsx jsx */

import { jsx } from '@emotion/core'
import { Component } from 'react'
import { getUrlFromBlob } from 'modules/file/domain'
import { getFileData } from 'modules/file/actions'
import Loader from 'components/loader'

const SIZE = 140

type Props = {|
	fileId: string,
|}

type State = {|
	fileUrl: ?string,
	loading: boolean,
|}

class SignatureViewer extends Component<Props, State> {
	state = {
		fileUrl: null,
		loading: false,
	}

	componentDidMount() {
		this.getFile(this.props.fileId)
	}

	async getFile(fileId: string) {
		this.setState({ loading: true })

		const fileData = await getFileData(fileId)
		if (!fileData) return this.setState({ loading: false })

		this.setState({
			fileUrl: getUrlFromBlob(fileData.blob),
			loading: false,
		})
	}

	render() {
		return (
			<div css={styles.content}>
				{this.state.fileUrl && <img src={this.state.fileUrl} css={styles.image} />}
				<Loader visible={this.state.loading} />
			</div>
		)
	}
}

const styles = {
	content: {
		display: 'block',
		overflow: 'hidden',
		position: 'relative',
		width: SIZE,
		height: SIZE,
	},
	image: {
		display: 'block',
		objectFit: 'contain',
		objectPosition: 'center',
		width: '100%',
		height: '100%',
		maxHeight: SIZE,
		maxWidth: SIZE,
	},
}

export default SignatureViewer
