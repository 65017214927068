/* @flow */
/** @jsx jsx */

import { jsx } from '@emotion/core'
import DropDownArrow from 'components/svg-icons/navigation/arrow-drop-down'
import { colors } from 'variables'

type Props = {
	innerProps: Object,
	isDisabled: boolean,
	getStyles: (string, Props) => Object,
}

const DropdownIndicator = (props: Props) =>
	!props.isDisabled ? (
		<div {...props.innerProps} css={props.getStyles('dropdownIndicator', props)}>
			<div className="dropdown-indicator__arrow">
				<DropDownArrow
					style={styles.arrow}
					color={props.getStyles('dropdownIndicator', props).indicatorColor ?? colors.grey}
					hoverColor={props.getStyles('dropdownIndicator', props).indicatorHoverColor ?? colors.black}
				/>
			</div>
		</div>
	) : null

const styles = {
	arrow: {
		display: 'block',
	},
}

export default DropdownIndicator
