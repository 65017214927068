/* @flow */
/** @jsx jsx */

import { Component } from 'react'
import { jsx } from '@emotion/core'
import { getUrlFromBlob, getMimeTypeBlob, isFilePreviewable, isObscureImageType } from 'modules/file/domain'
import { getFileData } from 'modules/file/actions'
import Popover from 'components/Popover'
import { lookup } from 'mime-types'

const SIZE = 400
const ANCHOR_ORIGIN = { horizontal: 'middle', vertical: 'bottom' }
const TARGET_ORIGIN = { horizontal: 'middle', vertical: 'top' }

type Props = {|
	fileId: string,
	children?: any,
|}

type State = {|
	src: ?string,
	contentType: ?string,
	fileName: ?string,
	open: boolean,
|}

class UploadScanFilePreview extends Component<Props, State> {
	anchorEl: any

	state = {
		src: null,
		contentType: null,
		fileName: null,
		open: false,
	}

	componentDidMount() {
		this.getFile(this.props.fileId)
	}

	async getFile(fileId: string) {
		const fileData = await getFileData(fileId)
		if (!fileData) return

		this.setState({
			src: getUrlFromBlob(fileData.blob),
			contentType: (fileData.fileName && lookup(fileData.fileName)) || getMimeTypeBlob(fileData.blob),
			fileName: fileData.fileName,
		})
	}

	renderPDFContent = (src: string) => {
		const size = `${SIZE}px`

		return (
			<object data={src} width={size} height={size} css={styles.object} type="application/pdf">
				<param name="wmode" value="transparent" />
			</object>
		)
	}

	renderFilePreview = (src: ?string, contentType: ?string, fileName: ?string) => {
		if (!src || !contentType) return null

		switch (contentType) {
			case 'image/jpeg':
			case 'image/jpg':
			case 'image/png':
			case 'image/bmp':
			case 'image/gif':
				return <img src={src} css={styles.image} />
			case 'application/pdf':
				return this.renderPDFContent(src)
			default:
				if (isObscureImageType(fileName, contentType)) {
					return <img src={src} css={styles.image} />
				} else {
					return null
				}
		}
	}

	onMouseEnter = () => {
		if (isFilePreviewable(this.state.contentType) || isObscureImageType(this.state.fileName, this.state.contentType)) {
			this.setState({ open: true })
		}
	}

	onMouseLeave = () => {
		this.setState({ open: false })
	}

	bindChildrenWrapper = (el: any) => {
		this.anchorEl = el
	}

	render() {
		return (
			<div>
				<div onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave} ref={this.bindChildrenWrapper}>
					{this.props.children}
				</div>
				<Popover
					anchorEl={this.anchorEl}
					open={this.state.open}
					anchorOrigin={ANCHOR_ORIGIN}
					targetOrigin={TARGET_ORIGIN}
					onRequestClose={this.onMouseLeave}
					onMouseEnter={this.onMouseEnter}
					onMouseLeave={this.onMouseLeave}
					arrow="top"
					zDepth={5}
				>
					<div css={styles.content}>
						{this.renderFilePreview(this.state.src, this.state.contentType, this.state.fileName)}
					</div>
				</Popover>
			</div>
		)
	}
}

const styles = {
	content: {
		padding: 12,
		overflow: 'hidden',
	},
	object: {
		width: SIZE,
		height: SIZE,
		objectFit: 'contain',
		display: 'block',
	},
	image: {
		display: 'block',
		objectFit: 'contain',
		width: '100%',
		height: '100%',
		maxHeight: SIZE,
		maxWidth: SIZE,
	},
}

export default UploadScanFilePreview
