/* @flow */
import { createConfirmation, confirmable } from 'react-confirm'
import { i18n } from 'locales'
import { I18nextProvider } from 'react-i18next'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import Button from 'components/button'
import Dialog from 'components/dialog'
import React, { Component } from 'react'
import TextField from 'components/TextField'

import styles from './styles.css'

type Options = {|
	titleText?: string,
	labelText?: string,
	placeholderText?: string,
	cancelButtonText?: string,
	confirmButtonText?: string,
|}

// from confirmable
type ConfirmProps = {|
	show: boolean, // indicates if the dialog is shown or not.
	proceed: (reason: any) => void, // call to close the dialog with promise resolved.
	cancel: (reason: any) => void, // call to close the dialog with promise rejected.
	dismiss: () => void, // call to only close the dialog.
|}

type Props = {|
	...WithTranslateProps,
	...ConfirmProps,
	...Options, // arguments of your confirm function
|}

type State = {|
	text: string,
|}

class TextInputDialog extends Component<Props, State> {
	state = {
		text: '',
	}

	onCancel = () => {
		this.props.dismiss()
	}

	onConfirm = () => {
		this.props.proceed(this.state.text)
	}

	onTextChange = (event: SyntheticInputEvent<HTMLInputElement>, text: string) => {
		this.setState({ text })
	}

	getActions = () => [
		<Button
			primary
			wide
			key="confirmButton"
			labelText={this.props.confirmButtonText || this.props.t('application.dialogs.textInput.confirm')}
			onClick={this.onConfirm}
			autoTestId="text-input-dialog-button-ok"
		/>,
		<Button
			secondary
			wide
			key="cancelButton"
			labelText={this.props.cancelButtonText || this.props.t('application.dialogs.textInput.cancel')}
			onClick={this.onCancel}
			autoTestId="text-input-dialog-button-cancel"
		/>,
	]

	render() {
		return (
			<Dialog
				title={this.props.titleText || this.props.t('application.dialogs.textInput.title')}
				actions={this.getActions()}
				open={this.props.show}
				autoTestId="text-input-dialog"
			>
				<div className={styles.oneColumnDialog}>
					<div className={styles.row}>
						<TextField
							onChange={this.onTextChange}
							labelText={this.props.labelText || ''}
							hintText={this.props.placeholderText || ''}
							fullWidth
						/>
					</div>
				</div>
			</Dialog>
		)
	}
}

const TranslatedTextInputDialog = withTranslate(TextInputDialog)

const TranslatedTextInputDialogWithProvider = (props) => ( //eslint-disable-line
	<I18nextProvider i18n={i18n}>
		<TranslatedTextInputDialog {...props} />
	</I18nextProvider>
)

const confirm = createConfirmation(confirmable(TranslatedTextInputDialogWithProvider))

function open(options?: Options): Promise<string> {
	return confirm(options)
}

export default { open }
