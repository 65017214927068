/* @flow */

import type {
	AccountingDocument,
	AccountingDocumentDirection,
	AccountingDocumentLanguage,
	AccountingDocumentLineItem,
	AccountingDocumentReduxAction,
	AccountingDocumentType,
	AccountingDocumentVatRatesAction,
	Dispatch,
	Enum,
	FinancialAccount,
	FormFieldErrorContext,
	FormFieldErrorMessage,
	GreenboxSuggestionResponse,
	OrganizationAction,
	State,
	VatRate,
} from 'types'
import type { ValidationMessageType, ValidationValueType } from 'wrappers/validate'
import {
	addFavoriteFinancialAccount,
	loadFavoriteFinancialAccounts,
	loadFavoriteLineItems,
	removeFavoriteFinancialAccount,
} from 'modules/organization/actions/favorites'
import { getAccountingDocumentVatRates, getVatRates } from '../../selectors/vat-rates'
import { getFavoriteFinancialAccountIds, getFavoriteLineItemIds } from 'modules/organization/selectors/favorites'
import { getOrganizationBranches, getOrganizationProjects } from 'modules/organization/selectors'

import { EMPTY_ARRAY } from 'trivi-constants'
import InvoiceLineItems from '../../components/invoice-elements/line-items'
import React from 'react'
import { connect } from 'react-redux'
import { fetchAccountingDocumentVatRates } from '../../actions/vat-rates'
import { getDefaultLineItems } from 'modules/accounting-document/selectors'
import { getFormFieldErrors } from 'modules/common/selectors'
import { loadDefaultLineItems } from 'modules/accounting-document/actions/accounting-document'
import { removeError } from 'helpers'

export type Props = {|
	financialAccounts: Array<FinancialAccount>,
	readonly?: boolean,
	accountingDocumentId?: ?string,
	items: Array<AccountingDocumentLineItem>,
	onLineItemCreate: () => Promise<void>,
	onLineItemRemove: (lineItem: AccountingDocumentLineItem, index: number) => void,
	onLineItemClone: (lineItem: AccountingDocumentLineItem) => void,
	onLineItemChange: (
		lineItem: AccountingDocumentLineItem,
		autocalc: boolean,
		index: number,
		accountingDocument: ?AccountingDocument,
	) => Promise<any>,
	direction: AccountingDocumentDirection,
	type: AccountingDocumentType,
	calculatingLineItemIds: Array<string>,
	currency?: string,
	language: ?AccountingDocumentLanguage,
	accountingDocument?: ?AccountingDocument,
	lineItemsProcessing: boolean,
	addingLineItem?: boolean,
	isAccDocVatFree?: boolean,
	greenboxSuggestion: ?GreenboxSuggestionResponse,
	validationMessage?: ValidationMessageType,
	validationValue?: ValidationValueType,
	formFieldErrorContext?: FormFieldErrorContext,
	extracting?: boolean,
	public?: boolean,
	disabledFinAccount?: boolean,
	...StateProps,
	...DispatchProps,
|}

function InvoiceLineItemsContainer(props: Props) {
	return (
		<InvoiceLineItems
			readonly={props.readonly}
			lineItems={props.items}
			accountingDocumentId={props.accountingDocumentId}
			financialAccounts={props.financialAccounts}
			favoriteFinancialAccountIds={props.favoriteFinancialAccountIds}
			greenboxSuggestion={props.greenboxSuggestion}
			onLineItemCreate={props.onLineItemCreateHandler}
			onLineItemRemove={props.onLineItemRemove}
			onLineItemClone={props.onLineItemClone}
			onLineItemChange={props.onLineItemChange}
			onFinancialAccountFavorite={props.onFinancialAccountFavorite}
			loadFavoriteFinancialAccounts={props.loadFavoriteFinancialAccounts}
			loadAccountingDocumentVatRates={props.loadAccountingDocumentVatRates}
			availableProjects={props.availableProjects}
			availableBranches={props.availableBranches}
			calculatingLineItemIds={props.calculatingLineItemIds}
			currency={props.currency}
			language={props.language}
			accountingDocument={props.accountingDocument}
			lineItemsProcessing={props.lineItemsProcessing}
			addingLineItem={!!props.addingLineItem}
			isAccDocVatFree={!!props.isAccDocVatFree}
			validationMessage={props.validationMessage}
			validationValue={props.validationValue}
			formFieldErrorContext={props.formFieldErrorContext}
			loadDefaultLineItems={props.loadDefaultLineItems}
			loadFavoriteLineItems={props.loadFavoriteLineItems}
			defaultLineItems={props.defaultLineItems}
			favoriteLineItemIds={props.favoriteLineItemIds}
			extracting={props.extracting}
			vatRates={props.vatRates}
			errors={props.errors}
			public={props.public}
			disabledFinAccount={props.disabledFinAccount}
		/>
	)
}

//-------- connect FAVORITE FINNANCIAL ACCOUNTS --------
type StateProps = {|
	favoriteFinancialAccountIds: Array<string>,
	favoriteLineItemIds: Array<string>,
	defaultLineItems: Array<AccountingDocumentLineItem> | null,
	availableProjects: Enum,
	availableBranches: Enum,
	errors: ?Array<FormFieldErrorMessage>,
	vatRates: null | Array<VatRate>,
|}

const makeMapStateToProps = () => {
	const mapStateToProps = (state: State, ownProps: Props): StateProps => {
		const accountingDocumentId = ownProps.accountingDocument && ownProps.accountingDocument.id

		return {
			defaultLineItems: null != accountingDocumentId ? getDefaultLineItems(state, accountingDocumentId) : null,
			favoriteLineItemIds: getFavoriteLineItemIds(state),
			favoriteFinancialAccountIds: getFavoriteFinancialAccountIds(state),
			availableProjects: getOrganizationProjects(state) || EMPTY_ARRAY,
			availableBranches: getOrganizationBranches(state) || EMPTY_ARRAY,
			errors: ownProps.formFieldErrorContext && getFormFieldErrors(state, ownProps.formFieldErrorContext),
			vatRates: ownProps.accountingDocumentId
				? getAccountingDocumentVatRates(state, ownProps.accountingDocumentId)
				: ownProps.accountingDocument
				? getVatRates(
						state,
						ownProps.accountingDocument.direction,
						ownProps.accountingDocument.taxDate,
						ownProps.accountingDocument.vatCountryType,
				  )
				: EMPTY_ARRAY,
		}
	}
	// $FlowFixMe - returning function is ok
	return mapStateToProps
}

type DispatchProps = {|
	loadAccountingDocumentVatRates: (accountingDocumentId: string) => void,
	onFinancialAccountFavorite: (no: string, isFavorite: boolean) => void,
	loadDefaultLineItems: (accountingDocumentId: string) => void,
	loadFavoriteFinancialAccounts: () => void,
	loadFavoriteLineItems: () => void,
	onLineItemCreateHandler: () => Promise<void>,
|}
const makeMapDispatchToProps = () => {
	const mapDispatchToProps = (
		dispatch: Dispatch<OrganizationAction | AccountingDocumentReduxAction | AccountingDocumentVatRatesAction>,
		ownProps: Props,
	): DispatchProps => {
		return {
			loadAccountingDocumentVatRates: (accountingDocumentId?: string) => {
				accountingDocumentId && dispatch(fetchAccountingDocumentVatRates(accountingDocumentId))
			},
			onFinancialAccountFavorite: (no: string, isFavorite: boolean) => {
				if (isFavorite) {
					dispatch(addFavoriteFinancialAccount(no))
				} else {
					dispatch(removeFavoriteFinancialAccount(no))
				}
			},
			loadFavoriteFinancialAccounts: () => {
				dispatch(loadFavoriteFinancialAccounts())
			},
			loadFavoriteLineItems: () => {
				dispatch(loadFavoriteLineItems())
			},
			loadDefaultLineItems: (accountingDocumentId: string) => {
				dispatch(loadDefaultLineItems(accountingDocumentId))
			},
			onLineItemCreateHandler: async () => {
				removeError({
					formFieldErrorContext: ownProps.formFieldErrorContext,
					dispatch,
				})
				await ownProps.onLineItemCreate()
			},
		}
	}

	return mapDispatchToProps
}

export default connect(makeMapStateToProps, makeMapDispatchToProps)(InvoiceLineItemsContainer)
