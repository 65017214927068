/* @flow */

import type {
	Dispatch,
	OrganizationAction,
	OrganizationCreator,
	OrganizationPersonAuthorizedToSign,
	OrganizationTaxRepresentative,
	State,
} from 'types'
import {
	addOrganizationCreator,
	addOrganizationSignAuthorizedPerson,
	addOrganizationTaxRepresentative,
	deleteOrganizationCreator,
	deleteOrganizationSignAuthorizedPerson,
	deleteOrganizationTaxRepresentative,
	loadOrganizationCreators,
	loadOrganizationSignAuthorizedPersons,
	loadOrganizationTaxRepresentatives,
	updateOrganizationCreator,
	updateOrganizationSignAuthorizedPerson,
	updateOrganizationTaxRepresentative,
} from 'modules/organization/actions'

import type { OrganizationDetailState } from 'modules/organization/reducers'
import StatutoryAuthority from 'modules/settings/components/company/statutory-authority'
import { connect } from 'react-redux'
import { getCurrentOrganizationDetail } from 'modules/organization/selectors'
import { currentUserIsInternal } from 'modules/user/selectors'

type StateProps = {
	currentUserIsInternal: boolean,
	signAuthorized: ?Array<OrganizationPersonAuthorizedToSign>,
	creators: ?Array<OrganizationCreator>,
	taxRepresentatives: ?Array<OrganizationTaxRepresentative>,
}

const mapStateToProps = (state: State): StateProps => {
	const detail: ?OrganizationDetailState = getCurrentOrganizationDetail(state)
	return {
		currentUserIsInternal: currentUserIsInternal(state),
		signAuthorized: detail && detail.signAuthorized,
		creators: detail && detail.creators,
		taxRepresentatives: detail && detail.taxRepresentatives,
	}
}

type DispatchProps = {
	loadSignAuthorizedPersons: () => void,
	updateSignAuthorizedPerson: (id: string, person: OrganizationPersonAuthorizedToSign) => void,
	addSignAuthorizedPerson: (person: OrganizationPersonAuthorizedToSign) => void,
	deleteSignAuthorizedPerson: (id: string) => void,

	loadCreators: () => void,
	updateCreator: (id: string, creator: OrganizationCreator) => void,
	addCreator: (creator: OrganizationCreator) => void,
	deleteCreator: (id: string) => void,

	loadTaxRepresentatives: () => void,
	updateTaxRepresentative: (id: string, taxRepresentative: OrganizationTaxRepresentative) => void,
	addTaxRepresentative: (taxRepresentative: OrganizationTaxRepresentative) => void,
	deleteTaxRepresentative: (id: string) => void,
}

const mapDispatchToProps = (dispatch: Dispatch<OrganizationAction>): DispatchProps => {
	return {
		loadSignAuthorizedPersons: () => {
			dispatch(loadOrganizationSignAuthorizedPersons())
		},
		updateSignAuthorizedPerson: (id: string, person: OrganizationPersonAuthorizedToSign) => {
			dispatch(updateOrganizationSignAuthorizedPerson(id, person))
		},
		addSignAuthorizedPerson: (person: OrganizationPersonAuthorizedToSign) => {
			dispatch(addOrganizationSignAuthorizedPerson(person))
		},
		deleteSignAuthorizedPerson: (id: string) => {
			dispatch(deleteOrganizationSignAuthorizedPerson(id))
		},

		loadCreators: () => {
			dispatch(loadOrganizationCreators())
		},
		updateCreator: (id: string, creator: OrganizationCreator) => {
			dispatch(updateOrganizationCreator(id, creator))
		},
		addCreator: (creator: OrganizationCreator) => {
			dispatch(addOrganizationCreator(creator))
		},
		deleteCreator: (id: string) => {
			dispatch(deleteOrganizationCreator(id))
		},

		loadTaxRepresentatives: () => {
			dispatch(loadOrganizationTaxRepresentatives())
		},
		updateTaxRepresentative: (id: string, taxRepresentative: OrganizationTaxRepresentative) => {
			dispatch(updateOrganizationTaxRepresentative(id, taxRepresentative))
		},
		addTaxRepresentative: (taxRepresentative: OrganizationTaxRepresentative) => {
			dispatch(addOrganizationTaxRepresentative(taxRepresentative))
		},
		deleteTaxRepresentative: (id: string) => {
			dispatch(deleteOrganizationTaxRepresentative(id))
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(StatutoryAuthority)
