// @flow

export function getUrlParameter(url: string, parameter: string): ?string {
	if (-1 !== url.indexOf('://')) {
		return new URL(url).searchParams.get(parameter)
	} else {
		return new URLSearchParams(url).get(parameter)
	}
}

export function addUrlParameter(url: string, parameter: string, value: string): string {
	if (-1 !== url.indexOf('://')) {
		const urlObject = new URL(url)
		urlObject.searchParams.append(parameter, value)

		return urlObject.toString()
	} else {
		const searchParamsObject = new URLSearchParams(url)
		searchParamsObject.append(parameter, value)

		const appendedUri = decodeURIComponent(searchParamsObject.toString())

		// Replace needed for correct path only parameter append (some/path and param=value)
		return appendedUri.replace('=&' + parameter, '?' + parameter)
	}
}

export function getRedirectToParameter(url: string): ?string {
	return getUrlParameter(url, 'redirectTo')
}

export function addRedirectToParameter(url: string, target: string): string {
	return addUrlParameter(url, 'redirectTo', target)
}

export function addRedirectToHereParameter(url: string): string {
	return addRedirectToParameter(url, getCurrentRelativeUrl())
}

export function getCurrentRelativeUrl(): string {
	return window.location.pathname + window.location.search + window.location.hash
}
