//@flow
/** @jsx jsx */

import { Component } from 'react'
import { jsx } from '@emotion/core'
import { Trans } from 'react-i18next'
import { withTranslate, type WithTranslateProps, type WithNotifyProps, withNotify } from 'wrappers'
import MultiEmail from 'components/multi-email-input'
import PopupSection from 'components/PopupSection'
import Button from 'components/button'
import scenery from 'assets/images/scenery-invite.svg'
import { colors } from 'variables'

type Props = {|
	organizationId: ?string,
	open: boolean,
	emails: Array<string>,
	sending: boolean,
	buttonDisabled: boolean,
	inputDisabled: boolean,
	updateInvitationEmails: (emails: Array<string>) => void,
	confirmInvitationEmails: (emails: Array<string>) => Promise<any>,
	closeInvitationDialog: () => void,
|}

class InvitationDialog extends Component<Props & WithTranslateProps & WithNotifyProps> {
	handleMultiEmailChange = (emails: Array<string>) => {
		this.props.updateInvitationEmails(emails)
	}

	renderButtonLabel = () => {
		const { sending, t } = this.props
		if (sending) {
			return t('dashboard.invitation.dialog.buttonStates.sending')
		} else {
			return t('dashboard.invitation.dialog.buttonStates.send')
		}
	}
	removeHash = () => {
		history.pushState('', document.title, window.location.pathname)
	}
	confirmInvitationEmails = () => {
		const { emails, confirmInvitationEmails, notify, t } = this.props
		confirmInvitationEmails(emails).then(() => {
			notify(t('dashboard.invitation.requestSuccess'), 'success')
			this.removeHash()
			history.pushState('', document.title, window.location.pathname)
		})
	}

	closeInvitationDialog = () => {
		this.props.clearNotification()
		this.props.closeInvitationDialog()
		this.removeHash()
	}

	render() {
		const { open, emails, buttonDisabled, inputDisabled, t } = this.props
		const label = this.renderButtonLabel()

		return (
			<div>
				<PopupSection open={open} onRequestClose={this.closeInvitationDialog}>
					<div css={styles.root}>
						<img css={styles.image} src={scenery} />
						<div css={styles.title}>{t('dashboard.invitation.dialog.title')}</div>
						<div>{t('dashboard.invitation.dialog.subTitle')}</div>
						<ol css={styles.bullets}>
							<li css={styles.bullet}>
								<Trans i18nKey="dashboard.invitation.dialog.bullets.1">
									Vyplňte email osoby, které chcete doporučit <strong>Trivi</strong>.
								</Trans>
							</li>
							<li css={styles.bullet}>{t('dashboard.invitation.dialog.bullets.2')}</li>
							<li css={styles.bullet}>
								<Trans i18nKey="dashboard.invitation.dialog.bullets.3">
									Jakmile se Vámi doporučená osoba rozhodne začít využívat služeb <strong>Trivi</strong> a dojde k první
									fakturaci obdržíte bonus ve výši <strong>3 000 Kč</strong> (za pravnickou osobu) nebo
									<strong>1000 Kč</strong> (za fyzickou osobou).
								</Trans>
							</li>
							<li css={styles.bullet}>
								<Trans i18nKey="dashboard.invitation.dialog.bullets.4">
									Vámi doporučená osoba obdrží také <strong>slevu</strong> a to na 5 měsíčních paušálu
									<strong>Trivi!</strong>
								</Trans>
							</li>
						</ol>
						<div css={styles.download}>
							{t('dashboard.invitation.dialog.download.prefix')}{' '}
							<a css={styles.url} download target="_blank" href={t('dashboard.invitation.dialog.download.url')}>
								{t('dashboard.invitation.dialog.download.link')}
							</a>
							{'.'}
						</div>
						<div css={styles.form}>
							<MultiEmail fullWidth disabled={inputDisabled} onChange={this.handleMultiEmailChange} value={emails} />
							<div css={styles.button}>
								<Button
									autoTestId="invitation-dialog-confirm"
									disabled={buttonDisabled}
									onClick={this.confirmInvitationEmails}
									labelText={label}
									fullWidth
								/>
							</div>
						</div>
					</div>
				</PopupSection>
			</div>
		)
	}
}

const styles = {
	root: {
		margin: '50px auto',
		width: '80%',
		maxWidth: 980,
		textAlign: 'center',
		fontSize: 20,
		lineHeight: '24px',
	},
	image: {
		display: 'block',
		margin: '0 auto',
		width: '100%',
		maxWidth: 876,
	},
	title: {
		fontWeight: 'bold',
		marginTop: 55,
		marginBottom: 35,
	},
	bullets: {
		marginTop: 35,
		marginBottom: 60,
		textAlign: 'left',
	},
	bullet: {
		margin: '10px 0',
	},
	form: {
		width: '80%',
		maxWidth: 350,
		margin: '0 auto',
	},
	button: {
		marginTop: 20,
	},
	download: {
		marginBottom: 60,
	},
	url: {
		fontSize: 'inherit',
		opacity: 'inherit',
		color: 'inherit',
		textDecoration: 'underline',
		'&:hover': {
			color: colors.blue,
			textDecoration: 'underline',
		},
	},
}

export default withTranslate(withNotify(InvitationDialog))
