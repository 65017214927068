/* @flow */

import type { Channel, Member, Members } from '../types'

export function mergeMember(members: Members, member: Member): Members {
	const merged = [...members]
	const foundIndex = findMember(members, member.id)
	if (-1 === foundIndex) {
		merged.push({ ...member })
	} else {
		merged[foundIndex] = { ...member }
	}

	return merged
}

export function findMember(members: Members, id: string): number {
	return members.indexOf(getMember(members, id))
}

export function mergeChannelMember(channel: Channel, member: Member): Channel {
	return Object.freeze({ ...channel, ...{ members: mergeMember(channel.members, member) } })
}

export function getMember(members: Members, id: string): ?Member {
	return members.find((member: Member) => member.id === id)
}
