/* @flow */

import type { Dispatch, Sequence, SequencesAction, State, SequencesData } from 'types'
import {
	changeLastGeneratedNumber,
	createSequence,
	editSequence,
	removeSequence,
	loadSequences,
} from 'modules/accounting-document/actions'
import { currentUserIsInternal } from 'modules/user/selectors'
import { getSequencesData } from 'modules/accounting-document/selectors/sequences'

import Sequences from 'modules/settings/components/accounting/sequences'
import { connect } from 'react-redux'
import withAutoload from 'wrappers/with-autoload'

type StateProps = {|
	sequences: SequencesData,
	currentUserIsInternal: boolean,
|}

const mapStateToProps = (state: State): StateProps => {
	return {
		sequences: getSequencesData(state),
		currentUserIsInternal: currentUserIsInternal(state),
	}
}

type DispatchProps = {|
	editSequence: (sequenceId: string, sequence: Sequence, cashRegisterId?: string) => void,
	createSequence: (sequence: Sequence, cashRegisterId?: string) => void,
	removeSequence: (sequence: Sequence, cashRegisterId?: string) => void,
	changeLastGeneratedNumber: (
		sequence: Sequence,
		lastGeneratedNumber: number,
		year: number,
		cashRegisterId?: string,
	) => void,
	autoload: () => void,
|}

const mapDispatchToProps = (dispatch: Dispatch<SequencesAction>): DispatchProps => {
	return {
		createSequence: (sequence: Sequence, cashRegisterId?: ?string) => {
			dispatch(createSequence(sequence, cashRegisterId))
		},
		editSequence: (sequenceId: string, sequence: Sequence, cashRegisterId?: string) => {
			dispatch(editSequence(sequenceId, sequence, cashRegisterId))
		},
		removeSequence: (sequence: Sequence, cashRegisterId?: string) => {
			dispatch(removeSequence(sequence, cashRegisterId))
		},
		changeLastGeneratedNumber: (
			sequence: Sequence,
			lastGeneratedNumber: number,
			year: number,
			cashRegisterId?: string,
		) => {
			dispatch(changeLastGeneratedNumber(sequence, lastGeneratedNumber, year, cashRegisterId))
		},
		autoload: () => {
			dispatch(loadSequences(true, true))
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(withAutoload(Sequences))
