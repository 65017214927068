//@flow

import React, { Component } from 'react'
import memoize from 'memoize-one'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import type {
	Dispatch,
	Group,
	GroupUser,
	GroupsAction,
	Invitation,
	InvitationRequest,
	WithRouterProps,
	State,
	UserGroup,
	UserProfile,
	Organization,
} from 'types'
import {
	assignUserToGroup,
	createGroup,
	getGroups,
	removeGroup,
	removeUserFromGroup,
	updateGroup,
} from '../../groups/actions'
import { inviteUser, loadUsers, loadInvitations, removeUserInvitations, removeInvitation } from '../../organization'
import {
	settingsUsersGroupsRoute,
	settingsUsersInternalRoute,
	settingsUsersUsersRoute,
} from 'modules/settings/routing/routes'
import GroupList from '../components/users/group-list'
import InternalUsers from 'modules/settings/containers/internal-users'
import InviteUser from '../components/users/invite-user'
import type { SubSectionType } from './settings-layout'
import type { Action as UserAction } from '../../user/actions/action-types'
import UserGroupList from '../components/users/user-group-list'
import { selectGroups } from 'modules/groups/selectors'
import { userHasAccess } from 'permissions'
import { type WithTranslateProps, withTranslate } from 'wrappers'
import SettingsSection from 'modules/settings/components/settings-section'
import Button from 'components/button'
import Tooltip from 'components/tooltip'
import styles from '../components/settings.css'
import { currentUserIsInternal, getCurrentUserId, getNonInternalUsers } from 'modules/user/selectors'
import { getCurrentOrganization } from 'modules/organization/selectors'
import { EMPTY_ARRAY } from 'trivi-constants'

type StateProps = {|
	userProfiles: ?Array<UserProfile>,
	userInvitations: ?Array<Invitation>,
	groups: ?Array<{ group: Group, userCount: number }>,
	canSetUsers: boolean,
	confirmationRequired: boolean,
	organization: ?Organization,
|}

const mapStateToProps = (state: State) => {
	const userProfiles: ?Array<UserProfile> = getNonInternalUsers(state)
	const userInvitations: ?Array<Invitation> = state.organization.invitations.data

	let groups = null
	groups = (selectGroups(state) || []).reduce((result: Array<{ group: Group, userCount: number }>, item: Group) => {
		result.push({
			group: item,
			userCount: (userProfiles || []).reduce((sum: number, userProfile: UserProfile) => {
				const userHasGroup =
					userProfile.groups == null
						? false
						: userProfile.groups.filter((userGroup: UserGroup) => userGroup.id == item.id).length > 0

				return userHasGroup ? sum + 1 : sum
			}, 0),
		})
		return result
	}, [])

	return {
		userProfiles,
		userInvitations,
		groups,
		currentUserId: getCurrentUserId(state),
		confirmationRequired: currentUserIsInternal(state),
		organization: getCurrentOrganization(state),
		canSetUsers: userHasAccess(state, 'editUsers'),
		canSeeNonDemoFeatures: userHasAccess(state, 'seeNonDemoFeatures'),
		canSetInternalUsers: userHasAccess(state, 'editUsers') && userHasAccess(state, 'seeInternalUsers'),
	}
}

type DispatchProps = {|
	loadUsers: () => void,
	loadInvitations: () => void,
	loadGroups: () => void,
	bulkProcessUserGroups: (
		profile: UserProfile,
		requests: Array<{ groupId: string, operation: 'add' | 'remove' }>,
	) => Promise<*>,
	createGroup: (group: Group) => void,
	removeGroup: (group: Group) => void,
	updateGroup: (group: Group) => void,
	onSendInvitation: InvitationRequest => Promise<void>,
	removeUserInvitations: (user: UserProfile) => void,
	removeInvitation: (email: string) => void,
|}

const mapDispatchToProps = (dispatch: Dispatch<UserAction | GroupsAction>): DispatchProps => {
	return {
		loadUsers: () => {
			dispatch(loadUsers())
		},
		loadInvitations: () => {
			dispatch(loadInvitations())
		},
		onSendInvitation: (invitationRequest: InvitationRequest) => {
			const response = dispatch(inviteUser(invitationRequest))
			response.then(() => {
				dispatch(loadInvitations())
			})
			return response
		},
		loadGroups: () => {
			dispatch(getGroups())
		},
		bulkProcessUserGroups: (
			profile: UserProfile,
			requests: Array<{ groupId: string, operation: 'add' | 'remove' }>,
		) => {
			let allFinished = Promise.all(
				requests.map((request: *) => {
					let groupUser: GroupUser = {
						userId: profile.id,
					}

					if (request.operation === 'add') {
						return dispatch(assignUserToGroup({ groupId: request.groupId }, groupUser))
					}
					if (profile.id != null) return dispatch(removeUserFromGroup({ groupId: request.groupId, userId: profile.id }))
				}),
			)

			allFinished.then(() => {
				dispatch(loadUsers())
				dispatch(loadInvitations())
			})

			return allFinished
		},
		createGroup: (group: Group) => {
			dispatch(createGroup(group)).then(() => {
				dispatch(getGroups())
			})
		},
		removeGroup: (group: Group) => {
			if (group.id == null) return

			dispatch(removeGroup({ groupId: group.id })).then(() => {
				dispatch(getGroups())
			})
		},
		updateGroup: (group: Group) => {
			if (group.id == null) return

			dispatch(updateGroup({ groupId: group.id }, group)).then(() => {
				dispatch(getGroups())
			})
		},
		removeUserInvitations: (user: UserProfile) => {
			if (user.id == null) return
			dispatch(removeUserInvitations(user.id || '')).then(() => {
				dispatch(loadInvitations())
			})
		},
		removeInvitation: (email: string) => {
			dispatch(removeInvitation(email)).then(() => {
				dispatch(loadInvitations())
			})
		},
	}
}

type OwnProps = {|
	subSection?: SubSectionType,
|}

export type Props = { ...StateProps, ...DispatchProps, ...OwnProps }

type ComponentProps = WithTranslateProps & WithRouterProps & Props
type ComponentState = {
	invitePopupOpen: boolean,
	inviteAgainEmail: ?string,
	inviteAgainGroups: ?Array<string>,
}

class UsersPage extends Component<ComponentProps, ComponentState> {
	constructor(props: ComponentProps) {
		super(props)
		this.state = {
			invitePopupOpen: false,
			inviteAgainEmail: null,
			inviteAgainGroups: null,
		}
	}

	componentDidMount() {
		if (this.props.canSetUsers) {
			if (this.props.userProfiles == null) {
				this.props.loadUsers()
			}

			if (this.props.userInvites == null) {
				this.props.loadInvitations()
			}

			if (this.props.groups == null || !this.props.groups.length) {
				this.props.loadGroups()
			}
		}
	}

	getToUrl = (url: string) => () => {
		this.props.history.push(url)
	}

	closeInviteUsers = () => {
		this.setState({
			invitePopupOpen: false,
			inviteAgainEmail: null,
			inviteAgainGroups: null,
		})
	}

	openInviteUsers = () => {
		if (!this.props.canSeeNonDemoFeatures) {
			return
		}
		this.setState({
			invitePopupOpen: true,
		})
	}

	openInviteAgain = (email: string, groups: Array<string>) => {
		this.setState({
			invitePopupOpen: true,
			inviteAgainEmail: email,
			inviteAgainGroups: groups,
		})
	}

	mapGroups = memoize((groups: ?Array<{ group: Group, userCount: number }>) => {
		return (groups || []).map((grp: { group: Group, userCount: number }) => grp.group)
	})

	render() {
		const { t } = this.props
		const groups = this.mapGroups(this.props.groups)

		if (!this.props.canSetUsers) {
			return <div>{t('settings.accessDenied')}</div>
		}

		return (
			<div>
				<SettingsSection
					visible={this.props.subSection === 'users'}
					title={t('settings.users.users')}
					subTitle={t('settings.users.usersSubtitle')}
					onToggle={this.props.subSection !== 'users' ? this.getToUrl(settingsUsersUsersRoute()) : undefined}
				>
					<div className={styles.addButton}>
						<Tooltip
							inline
							label={(!this.props.canSeeNonDemoFeatures && t('settings.users.demoUserCantInvite')) || null}
						>
							<Button
								primary
								labelText={t('settings.users.invite')}
								onClick={this.openInviteUsers}
								disabled={!this.props.canSeeNonDemoFeatures}
								autoTestId="settings-users-invite"
							/>
						</Tooltip>
					</div>
					<UserGroupList
						currentUserId={this.props.currentUserId}
						canSetUsers={this.props.canSetUsers}
						canSeeNonDemoFeatures={this.props.canSeeNonDemoFeatures}
						profiles={this.props.userProfiles || EMPTY_ARRAY}
						invitations={this.props.userInvitations || EMPTY_ARRAY}
						onInviteAgain={this.openInviteAgain}
						onRemoveUserInvitations={this.props.removeUserInvitations}
						onRemoveInvitation={this.props.removeInvitation}
						bulkProcessUserGroups={this.props.bulkProcessUserGroups}
						groups={groups}
					/>
					<InviteUser
						email={this.state.inviteAgainEmail}
						groups={this.state.inviteAgainGroups}
						open={this.state.invitePopupOpen}
						confirmationRequired={this.props.confirmationRequired}
						organization={this.props.organization}
						onClose={this.closeInviteUsers}
						onSendInvitation={this.props.onSendInvitation}
					/>
				</SettingsSection>
				<SettingsSection
					visible={this.props.subSection === 'groups'}
					title={t('settings.users.groups')}
					subTitle={t('settings.users.groupsSubtitle')}
					onToggle={this.props.subSection !== 'groups' ? this.getToUrl(settingsUsersGroupsRoute()) : undefined}
				>
					<GroupList
						canSeeNonDemoFeatures={this.props.canSeeNonDemoFeatures}
						groups={this.props.groups}
						createGroup={this.props.createGroup}
						removeGroup={this.props.removeGroup}
						updateGroup={this.props.updateGroup}
					/>
				</SettingsSection>
				{this.props.canSetInternalUsers && this.props.canSeeNonDemoFeatures && (
					<SettingsSection
						visible={this.props.subSection === 'internal'}
						title={t('settings.users.internalUsers')}
						subTitle={t('settings.users.internalSubtitle')}
						onToggle={this.props.subSection !== 'internal' ? this.getToUrl(settingsUsersInternalRoute()) : undefined}
					>
						<InternalUsers />
					</SettingsSection>
				)}
			</div>
		)
	}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslate(UsersPage)))
