/* @flow */

import type { Categories as ColumnChartCategories, Data as ColumnChartData } from 'components/charts/column-chart'
import {
	getBillingSummaryChartData,
	getIsBillingSummaryLoading,
	getBillingSummaryFilter,
	getUserDashboardCurrency,
	getBillingSummary,
	getBillingSummaryChartCategoriesPure,
} from '../../selectors'
import { getCurrentOrganization, getCurrentOrganizationDomesticCurrency } from 'modules/organization/selectors'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import type { Currency, DashboardAction, Dispatch, Organization, State } from 'types'
import { loadBillingSummaryReportAggregations, selectCurrency, setBillingSummaryFilter } from '../../actions'
import BillingSummary from '../../components/billing-summary/billing-summary'
import type { BillingSummaryFilter } from '../../types'

type StateProps = {|
	filter: BillingSummaryFilter,
	currentYear: number,
	data: ?ColumnChartData,
	categories: ?ColumnChartCategories,
	currency: ?string,
	domesticCurrency: ?string,
	organization: ?Organization,
	loading: boolean,
|}

const mapStateToProps = (state: State): StateProps => {
	return {
		filter: getBillingSummaryFilter(state),
		currentYear: new Date().getFullYear(),
		data: getBillingSummaryChartData(state),
		categories: getBillingSummaryChartCategoriesPure(getBillingSummary(state)),
		currency: getUserDashboardCurrency(state),
		domesticCurrency: getCurrentOrganizationDomesticCurrency(state),
		organization: getCurrentOrganization(state),
		loading: getIsBillingSummaryLoading(state),
	}
}

type DispatchProps = {|
	loadData: (filter: BillingSummaryFilter) => void,
	onFilterChange: (filter: BillingSummaryFilter) => void,
	onCurrencyChange: (currency: ?Currency) => void,
|}

const mapDispatchToProps = (dispatch: Dispatch<DashboardAction>): DispatchProps => {
	return {
		loadData: (filter: BillingSummaryFilter) => {
			dispatch(loadBillingSummaryReportAggregations(filter))
		},
		onFilterChange: (filter: BillingSummaryFilter) => {
			dispatch(loadBillingSummaryReportAggregations(filter))
			dispatch(setBillingSummaryFilter(filter))
		},
		onCurrencyChange: (currency: ?Currency) => {
			dispatch(selectCurrency(currency))
		},
	}
}

type Props = {| ...StateProps, ...DispatchProps |}

class BillingSummaryContainer extends Component<Props> {
	componentDidMount() {
		this.props.loadData(this.props.filter)
	}

	componentDidUpdate(props: Props) {
		if (this.props.currency !== props.filter.currency || this.props.organization !== props.organization) {
			this.props.onFilterChange({ ...props.filter, currency: this.props.currency })
		}
	}

	render() {
		return (
			<BillingSummary
				data={this.props.data}
				categories={this.props.categories}
				filter={this.props.filter}
				currentYear={this.props.currentYear}
				onFilterChange={this.props.onFilterChange}
				domesticCurrency={this.props.domesticCurrency}
				loading={this.props.loading}
				currency={this.props.currency}
				onCurrencyChange={this.props.onCurrencyChange}
			/>
		)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(BillingSummaryContainer)
