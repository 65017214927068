/* @flow */

import type { Action } from '../actions/action-types'

export type ContactMeState = {
	isOpen: boolean,
	sending: boolean,
}

export const initialState: ContactMeState = {
	isOpen: false,
	sending: false,
}

export default (state: ContactMeState = initialState, action: Action): ContactMeState => {
	switch (action.type) {
		case 'TOGGLE_CONTACT_ME_FORM':
			return {
				...state,
				isOpen: action.isOpen === null || action.isOpen === undefined ? !state.isOpen : action.isOpen,
			}
		case 'SEND_CONTACT_START':
			return {
				...state,
				sending: true,
			}
		case 'SEND_CONTACT_ERROR':
			return {
				...state,
				sending: false,
			}
		case 'SEND_CONTACT_SUCCESS':
			return {
				...state,
				sending: false,
				isOpen: false,
			}
		default:
			return state
	}
}

export type State = ContactMeState
