// @flow

import React, { Component } from 'react'
import { AVAILABLE_USER_LANGUAGES, getPortalLanguageName } from 'locales'
import type { PortalLanguage } from 'types'
import { colors } from 'variables'
import LanguageSelectorNext from './_language-selector-next'
import type { OptionType } from 'react-select/src/types'

type Props = {|
	language: ?PortalLanguage,
	onLanguageChange: (userLanguage: ?PortalLanguage) => void,
	labelText?: string,
	readonly?: boolean,
	userPanelStyle?: boolean,
|}

type Empty = '__automatic__'

const AUTOMATIC: Empty = '__automatic__'

export default class UserLanguageSelectorNext extends Component<Props> {
	onLanguageChange = (language: ?OptionType) => {
		const languageValue = language && language.value
		const lang: ?PortalLanguage = AVAILABLE_USER_LANGUAGES.find((item: PortalLanguage) => item === languageValue)
		this.props.onLanguageChange(lang)
	}

	getExtraProps = (): Object => {
		if (!this.props.userPanelStyle) {
			return {}
		}
		return {
			autoWidth: true,
			inline: true,
			iconButtonColor: colors.white,
			userPanelStyle: true,
			openMenuPosition: 'top',
		}
	}

	getLabelText = () => {
		if (!this.props.labelText) return null
		if (!this.props.userPanelStyle) {
			return this.props.labelText
		} else {
			return this.props.labelText + ':'
		}
	}

	render() {
		const autoValue = {
			key: AUTOMATIC,
			name: 'Automaticky',
		}

		const availableLanguages = AVAILABLE_USER_LANGUAGES.map((lang: PortalLanguage) => {
			return {
				key: lang,
				name: getPortalLanguageName(lang),
			}
		})

		return (
			<LanguageSelectorNext
				labelText={this.getLabelText()}
				availableLanguages={[autoValue, ...availableLanguages]}
				language={this.props.language || AUTOMATIC}
				onChange={this.onLanguageChange}
				readonly={this.props.readonly}
				_extraProps={this.getExtraProps()}
			/>
		)
	}
}
