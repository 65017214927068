// @flow

import { type ComponentType, type ElementConfig, type Component } from 'react'
import { connect } from 'react-redux'
import { getMyProfile } from 'modules/user/selectors'
import type { UserProfile, State } from 'types'

type StateProps = {|
	userProfile: ?UserProfile,
|}

const mapStateToProps = (state: State): StateProps => {
	return {
		userProfile: getMyProfile(state),
	}
}

export type Props = StateProps
export default function withUserProfile<P: any, C: ComponentType<P>>(
	WrappedComponent: C,
): Class<Component<$Diff<ElementConfig<C>, Props>, any>> {
	return connect(mapStateToProps)(WrappedComponent)
}
