//@flow
/** @jsx jsx */

import { Component } from 'react'
import { jsx } from '@emotion/core'
import memoize from 'memoize-one'
import type { CashRegister } from 'types'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import CashRegisterSelector from 'modules/common/containers/cash-register-selector'
import Dialog from 'components/dialog'
import Button from 'components/button'
import TriviLink from 'modules/common/components/trivi-link'
import { cashRegistersRoute } from 'modules/cash-register/routing/routes'
import { EMPTY_ARRAY } from 'trivi-constants'

type Props = {|
	...WithTranslateProps,
	cashRegisters?: ?Array<CashRegister>,
	currency: string,
	loading: boolean,
	open: boolean,
	onRequestClose: () => void,
	onSubmit: (cashRegister: ?CashRegister) => Promise<*>,
	autoload: () => void,
|}

type State = {|
	selectedCashRegister: ?string,
	renderDialog: boolean,
	dialogSubmitted: boolean,
|}

class CashRegisterDialog extends Component<Props, State> {
	state = {
		selectedCashRegister: null,
		renderDialog: false,
		dialogSubmitted: false,
	}

	UNSAFE_componentWillReceiveProps = (nextProps: Props) => {
		const { dialogSubmitted, renderDialog } = this.state
		const { cashRegisters, loading, open } = nextProps
		if (!loading && !dialogSubmitted && cashRegisters && !renderDialog && open) {
			if (cashRegisters.length === 0) {
				this.props.onSubmit(null).then(() => {
					this.setState({ dialogSubmitted: true })
				})
				this.props.onRequestClose()
			} else if (cashRegisters.length === 1) {
				this.props.onSubmit(cashRegisters[0]).then(() => {
					this.setState({ dialogSubmitted: true })
				})
				this.props.onRequestClose()
			} else {
				this.setState({ renderDialog: true })
			}
		}
	}

	getCashRegisters = memoize((cashRegisters: ?Array<CashRegister>, currency: string) => {
		return cashRegisters
			? cashRegisters.filter((cashRegister: CashRegister) => cashRegister.currency === currency)
			: EMPTY_ARRAY
	})

	getNoCashRegisterWithRequestedCurrencyText = memoize((currency: string) =>
		this.props.t('cashRegister.noCashRegisterWithRequestedCurrency', { currency }),
	)

	handleCashRegisterChange = (selectedCashRegister: ?string) => {
		this.setState({ selectedCashRegister })
	}

	handleSubmit = () => {
		const { selectedCashRegister } = this.state
		const { cashRegisters } = this.props
		const cashRegister = cashRegisters && cashRegisters.find((i: CashRegister) => i.id === selectedCashRegister)
		if (selectedCashRegister) {
			this.setState({ dialogSubmitted: true })
			this.props
				.onSubmit(cashRegister)
				.then(() => {
					this.props.onRequestClose()
				})
				.catch(() => {
					this.setState({ dialogSubmitted: false })
				})
		}
	}

	handleCancel = () => {
		this.setState({ renderDialog: false })
		this.props.onRequestClose()
	}

	render() {
		if (!this.state.renderDialog) return null

		const { t } = this.props
		const { selectedCashRegister } = this.state

		const relevantCashRegisters = this.getCashRegisters(this.props.cashRegisters, this.props.currency)

		const actions = [
			<Button
				primary
				wide
				key="addModalButton"
				labelText={t('paymentDialog.pay')}
				onClick={this.handleSubmit}
				disabled={!selectedCashRegister || this.state.dialogSubmitted}
				autoTestId="cash-register-dialog-pay"
			/>,
			<Button
				secondary
				wide
				key="cancelModalButton"
				labelText={t('settings.forms.cancelButton')}
				onClick={this.handleCancel}
				autoTestId="cash-register-dialog-cancel"
			/>,
		]

		return (
			<Dialog
				open={this.props.open}
				title={t('cashRegister.payByCashRegister')}
				actions={actions}
				autoTestId="cash-register-dialog"
			>
				{relevantCashRegisters.length > 0 ? (
					<div css={style.root}>
						<CashRegisterSelector
							cashRegisters={relevantCashRegisters}
							onChange={this.handleCashRegisterChange}
							value={selectedCashRegister}
						/>
					</div>
				) : (
					<div css={style.text}>
						<span>{this.getNoCashRegisterWithRequestedCurrencyText(this.props.currency)}.</span>
						&nbsp;
						<TriviLink routeFn={cashRegistersRoute}>
							<span css={style.link}>{t('cashRegister.goToCashRegister')}.</span>
						</TriviLink>
					</div>
				)}
			</Dialog>
		)
	}
}

const style = {
	root: {
		maxWidth: 300,
		margin: '0 auto',
	},
	text: {
		fontSize: 16,
		textAlign: 'center',
	},
	link: {
		textDecoration: 'underline',
	},
}

export default withTranslate(CashRegisterDialog)
