// @flow
import i18n from 'i18next'
//import Cache from 'i18next-localstorage-cache';
import LanguageDetector from 'i18next-browser-languagedetector'
import intervalPlural from 'i18next-intervalplural-postprocessor'
import XHR from 'i18next-xhr-backend'
import moment from 'moment'

declare var BUILD_NUMBER: string

i18n
	.use(XHR)
	// .use(Cache)
	.use(LanguageDetector)
	.use(intervalPlural)
	.init({
		backend: {
			queryStringParams: {
				b: BUILD_NUMBER,
			},
		},
		fallbackLng: {
			sk: ['cs', 'en'],
			'sk-SK': ['cs', 'en'],
			default: ['en'],
		},
		ns: ['trivi'],
		defaultNS: 'trivi',
		debug: process.env.NODE_ENV !== 'production',
		load: 'languageOnly',
		withRef: true,

		react: {
			wait: true,
		},
		detection: {
			order: ['navigator'],
		},

		// cache: {
		//	 enabled: true
		// },

		interpolation: {
			escapeValue: false, // not needed for react!!
			formatSeparator: ',',
			format: function(value: any, format: any) {
				if (format === 'uppercase') return value.toUpperCase()
				if (value instanceof Date) return moment(value).format(format)
				return value
			},
		},
	})

export default i18n
