// @flow

import React from 'react'
import Helmet from 'react-helmet'
import { withRouter } from 'react-router'
import type { WithRouterProps } from 'types'
import { getRedirectToParameter } from 'utils/url'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import UploadScan from '../containers/upload-scan/upload-scan'
import { accountingDocumentsRoute } from 'modules/accounting-document/routing/routes'

type Props = {|
	...WithRouterProps,
	...WithTranslateProps,
|}

function UploadScanPage(props: Props) {
	function onRequestClose(afterSave?: boolean) {
		const redirectTo = getRedirectToParameter(props.location.search)
		if (redirectTo) return props.history.push(redirectTo)
		if (!afterSave) return props.history.goBack()
		props.history.push(accountingDocumentsRoute('unknown'))
	}

	return (
		<div>
			<Helmet>
				<title>{props.t('uploadScan.headline')}</title>
			</Helmet>
			<UploadScan onRequestClose={onRequestClose} />
		</div>
	)
}

export default withTranslate(withRouter(UploadScanPage))
