/* @flow */

import React, { Component, type ChildrenArray, type Element } from 'react'
import DataGridContainer from '../containers/data-grid'
import type { DataTypes, DataGridId, MultiAction } from '../types'
import type { Action, Filter } from 'modules/data-grid/types'
import { Column } from '../components'

export type Props<K: $Keys<DataTypes>> = {|
	id: DataGridId,
	dataType: K,
	filterId?: string,
	defaultFilter?: Filter,
	actions?: Array<Action<$ElementType<DataTypes, K>>>,
	multiActions?: Array<Action<Array<$ElementType<DataTypes, K>>>>,
	selectTooltip?: string,
	deselectTooltip?: string,
	selectOnClick?: boolean,
	selectedRows?: Array<string>,
	currentRowId?: string,
	light?: boolean,
	showPageSize?: boolean,
	showItemsOfTotal?: boolean,
	showPrevButton?: boolean,
	showNextButton?: boolean,
	compactPager?: boolean,
	hideHeader?: boolean,
	children: ChildrenArray<Element<typeof Column> | null | false>,
	onAction?: (string, $ElementType<DataTypes, K>) => Promise<void>,
	onMultiAction?: (MultiAction, Array<$ElementType<DataTypes, K>>) => Promise<?MultiAction>,
	onActionMenuOpen?: ($ElementType<DataTypes, K>, () => void) => ?Promise<void>,
	onNeedUpdateMultiActionVisiblity?: (Array<$ElementType<DataTypes, K>>) => Promise<Array<string>>,
	onSelectionChange?: (row: $ElementType<DataTypes, K>, selected: boolean) => void,
	onCheckAllChange?: (rows: { [string]: $ElementType<DataTypes, K> }, selected?: boolean) => void,
	checkAllOption?: boolean,
	beforeTableComponent?: any,
	expandedRowRender?: (
		record: $ElementType<DataTypes, K>,
		index: number,
		indent: number,
		expanded: boolean,
	) => ?React$Element<any>,
|}

export default class SidebarDataGrid extends Component<Props<$Keys<DataTypes>>> {
	render() {
		return <DataGridContainer {...this.props} style={style} compactPager />
	}
}

const style = {
	root: {
		'.container': { marginTop: 20 },
		thead: {
			tr: { display: 'none' },
		},
		tbody: { boxShadow: 'none' },
		'.row': {
			'&:last-of-type': {
				td: {
					'&:after': { display: 'block' },
				},
			},
			td: {
				'&:first-of-type': {
					paddingLeft: 20,
					'&:before': { left: 0 },
					'&:after': { left: 0 },
				},
				'&:last-of-type': {
					'&:before': { right: 0 },
					'&:after': { right: 0 },
				},
			},
		},
	},
	empty: {
		fontSize: 16,
		lineHeight: '20px',
		padding: '30px 20px 10px 20px',
	},
	pager: {
		margin: '5px 20px',
		display: 'flex',
		justifyContent: 'flex-end',
	},
	loading: {
		fontSize: 16,
		lineHeight: '20px',
		paddingTop: 30,
	},
	loadingContainer: {
		margin: '15px 20px 10px 20px',
	},
	loadingRow: {
		'&:nth-of-type(2)': {
			'span:nth-of-type(10)': { animationDelay: '-350ms' },
		},
		'&:nth-of-type(3)': {
			'span:nth-of-type(10)': { animationDelay: '-250ms' },
		},
		'&:nth-of-type(4)': {
			'span:nth-of-type(10)': { animationDelay: '-150ms' },
		},
		span: {
			'&:nth-of-type(1)': {
				left: 10,
				width: 150,
			},
			'&:nth-of-type(2)': {
				display: 'none',
			},
			'&:nth-of-type(3)': {
				display: 'none',
			},
			'&:nth-of-type(4)': {
				display: 'none',
			},
			'&:nth-of-type(5)': {
				display: 'none',
			},
			'&:nth-of-type(6)': {
				display: 'none',
			},
			'&:nth-of-type(7)': {
				left: 10,
			},
			'&:nth-of-type(8)': {
				display: 'none',
			},
			'&:nth-of-type(9)': {
				display: 'none',
			},
			'&:nth-of-type(10)': {
				left: 240,
				animationDelay: '-450ms',
			},
		},
	},
}
