// @flow
import type { FinancialAccountsGetParams } from 'types'

export function generateKeyFromFinancialAccountsGetParams(requestParams?: FinancialAccountsGetParams): string {
	if (requestParams) {
		const { accountingDocumentType, accountingDocumentDirection } = requestParams
		const typeKey =
			accountingDocumentType !== null && accountingDocumentType !== undefined ? accountingDocumentType : ''
		const directionKey =
			accountingDocumentDirection !== null && accountingDocumentDirection !== undefined
				? accountingDocumentDirection
				: ''
		return `${typeKey}__${directionKey}`
	}
	return 'ALL'
}
