/* @flow */

import { connect } from 'react-redux'
import { loadStatementOptions, saveStatementOptions, toggleStatementOptionsDialog } from '../actions'
import type { Props as ComponentProps } from '../components/bank-account-statement-options'
import BankAccountStatementOptions from '../components/bank-account-statement-options'
import type {
	BankAccountStatementOptions as BankAccountStatementOptionsType,
	BankAccountStatementOptionsFormatType,
	BankAction,
	Dispatch,
	State,
} from 'types'
import { getBankAccountStatementOptions } from '../selectors'

type Props = {
	id: string,
}

type StateProps = Props & {
	statementOptions?: BankAccountStatementOptionsType,
	format: ?BankAccountStatementOptionsFormatType,
}

const mapStateToProps = (state: State, ownProps: Props): StateProps => {
	return {
		id: ownProps.id,
		statementOptions: getBankAccountStatementOptions(state, ownProps.id) || undefined,
		format: state.bank.statementOptions.format,
	}
}

type DispatchProps = {|
	loadOptions: () => void,
	saveOptions: (options: BankAccountStatementOptionsType) => void,
	onClose: () => void,
|}

const mapDispatchToProps = (dispatch: Dispatch<BankAction>, ownProps: Props): DispatchProps => {
	return {
		loadOptions: () => {
			dispatch(loadStatementOptions(ownProps.id))
		},
		saveOptions: (statementOptions: BankAccountStatementOptionsType) => {
			dispatch(saveStatementOptions(ownProps.id, statementOptions))
		},
		onClose: () => {
			dispatch(toggleStatementOptionsDialog('CLOSE'))
		},
	}
}

const mergeProps = (stateProps: StateProps, dispatchProps: DispatchProps): ComponentProps => {
	return Object.freeze({ ...stateProps, ...dispatchProps })
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(BankAccountStatementOptions)
