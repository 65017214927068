//@flow

import type { State, UserAction } from 'types'
import TriviSettingsPage from 'modules/settings/pages/trivi-settings'
import { connect } from 'react-redux'
import {
	currentUserIsInternal,
	getCurrentUserHasInternalFullAccess,
	getCurrentOrganizationId,
} from 'modules/user/selectors'
import { userHasAccess } from 'permissions'
import { removeOrganization } from 'modules/organization/actions'
import { navigateToMainScreen } from 'modules/navigation/actions'

type StateProps = {|
	currentUserIsInternal: boolean,
	canEditProfile: boolean,
	canEditApiApps: boolean,
	currentUserHasInternalFullAccess: boolean,
	organizationSettingsLoading: boolean,
	organizationId: string,
|}

type DispatchProps = {|
	removeOrganization: (organizationId?: string) => Promise<any>,
|}

const mapStateToProps = (state: State): StateProps => {
	return {
		currentUserIsInternal: currentUserIsInternal(state),
		canEditProfile: userHasAccess(state, 'setOrganizationProfile'),
		canEditApiApps: userHasAccess(state, 'setOrganizationApiApps'),
		currentUserHasInternalFullAccess: getCurrentUserHasInternalFullAccess(state),
		organizationSettingsLoading: state.settings.loading || state.organization.loading,
		organizationId: getCurrentOrganizationId(state) || '',
	}
}

const mapDispatchToProps = (dispatch: Dispatch<UserAction | UserAction>): DispatchProps => {
	return {
		removeOrganization: async (organizationId?: string) => {
			const response: any = await dispatch(removeOrganization(organizationId))
			const removed: boolean = response.serverError === undefined
			if (removed) {
				await dispatch(navigateToMainScreen())
				window.location.reload()
			}
			return removed
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(TriviSettingsPage)
