/* @flow */
/** @jsx jsx */

import { jsx } from '@emotion/core'
import { withRouter } from 'react-router-dom'
import FileViewer from '../containers/file-viewer'
import PopupSection from 'components/PopupSection'
import type { I18NextProps, WithRouterProps } from 'types'

type Props = {
	...I18NextProps,
	...WithRouterProps,
	params: {
		fileId: string,
	},
}

function FileView(props: Props) {
	return (
		<PopupSection open onRequestClose={props.history.goBack}>
			<div css={style.viewer}>
				<FileViewer fileId={props.match.params.fileId} autoHeight downloadable />
			</div>
		</PopupSection>
	)
}

const style = {
	viewer: {
		display: 'block',
		marginTop: 40,
		marginBottom: 40,
		height: 'calc(100% - 80px)',
		marginLeft: 'auto',
		marginRight: 'auto',
		overflow: 'hidden',
		position: 'relative',
	},
}

export default withRouter(FileView)
