/* @flow */
/** @jsx jsx */

import { jsx } from '@emotion/core'
import type { AccountingDocument } from 'types'
import PopupSection from 'components/PopupSection'
import ScanView from './scan-view'

type Props = {|
	open: boolean,
	accountingDocument: AccountingDocument,
	onClose: () => void,
|}

export default function PreviewDialog(props: Props) {
	if (!props.open) return null
	if (!props.accountingDocument.scans || !props.accountingDocument.scans.length) return null

	return (
		<PopupSection open={props.open} onRequestClose={props.onClose}>
			<div css={styles.container}>
				<div css={styles.content}>
					<ScanView scans={props.accountingDocument.scans} />
				</div>
			</div>
		</PopupSection>
	)
}

const styles = {
	container: {
		position: 'relative',
		marginLeft: 'auto',
		marginRight: 'auto',
		maxWidth: '80%',
		width: 970,
	},
	content: {
		height: '100vh',
		padding: '80px 0',
	},
}
