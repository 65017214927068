// @flow

import type { AccountingDocumentReduxAction, Enum, ReduxStateUpdater, Scan, ScanFile, UploadScanAction } from 'types'

import { emptyScanWithFile } from 'types/empty'
import { updateReduxState } from 'utils/redux'

export type ErrorScanUpload = {|
	file: File,
	documentCategory: ?string,
	uploadId: number,
|}

export type State = {
	scans: Array<Scan>,
	errorScans: Array<ErrorScanUpload>,
	uploadingScanIds: Array<number>,
	documentCategories: ?Enum,
	loading: boolean,
	error: ?Object,
}

export const initialState: State = {
	scans: [],
	errorScans: [],
	uploadingScanIds: [],
	documentCategories: null,
	loading: false,
	error: null,
}

const setLoading = (loading: boolean): ReduxStateUpdater<State> => {
	return (state: State): State => {
		return {
			...state,
			loading,
		}
	}
}

const setDocumentCategories = (documentCategories: Enum) => {
	return (state: State): State => {
		return {
			...state,
			documentCategories,
		}
	}
}

const prependScan = (scan: Scan) => {
	return (state: State): State => {
		return {
			...state,
			scans: [scan, ...state.scans],
		}
	}
}

const removeErrorScanUpload = (uploadId: number) => {
	return (state: State): State => {
		return {
			...state,
			errorScans: state.errorScans.filter((item: ErrorScanUpload) => item.uploadId !== uploadId),
		}
	}
}

const addErrorScanUpload = (file: File, documentCategory: ?string, uploadId: number) => {
	return (state: State): State => {
		return {
			...state,
			errorScans: [...state.errorScans, { file, documentCategory, uploadId }],
		}
	}
}

const removeScan = (scanId: number) => {
	return (state: State): State => {
		return {
			...state,
			scans: state.scans.filter((scan: Scan) => {
				return scan.id !== scanId
			}),
		}
	}
}

const updateFileId = (scanId: number, oldFileId: string, newFileId: string) => {
	return (state: State): State => {
		return {
			...state,
			scans: state.scans.map((scan: Scan) => {
				if (scan.id !== scanId) {
					return scan
				}
				return {
					...scan,
					files: scan.files.map((file: ScanFile) => {
						if (file.fileId !== oldFileId) {
							return file
						}
						return {
							...file,
							fileId: newFileId,
						}
					}),
				}
			}),
		}
	}
}

const addUploadingScanId = (scanId: number) => {
	return (state: State): State => {
		return {
			...state,
			uploadingScanIds: state.uploadingScanIds.concat(scanId),
		}
	}
}

const removeUploadingScanId = (scanId: number) => {
	return (state: State): State => {
		return {
			...state,
			uploadingScanIds: state.uploadingScanIds.filter((id: number) => id !== scanId),
		}
	}
}

const uploadScanReducer = (
	state: State = initialState,
	action: AccountingDocumentReduxAction | UploadScanAction,
): State => {
	switch (action.type) {
		case 'START_LOAD_ACCOUNTING_DOCUMENT_CATEGORIES': {
			return updateReduxState(state, setLoading(true))
		}
		case 'FINISH_LOAD_ACCOUNTING_DOCUMENT_CATEGORIES': {
			return updateReduxState(state, setLoading(false), setDocumentCategories(action.documentCategories || []))
		}
		case 'START_UPLOAD_ACCOUNTING_DOCUMENT_SCAN': {
			const scanFile: ScanFile = {
				fileId: '',
				fileName: action.uploadScanFile.fileName,
				icon: action.uploadScanFile.icon,
				mimeType: action.uploadScanFile.mimeType,
			}
			const newScan: Scan = emptyScanWithFile(action.uploadId, scanFile, action.uploadScanFile.documentCategory)
			return updateReduxState(
				state,
				setLoading(true),
				addUploadingScanId(action.uploadId),
				prependScan(newScan),
				removeErrorScanUpload(action.uploadId),
			)
		}
		case 'FINISH_UPLOAD_ACCOUNTING_DOCUMENT_SCAN': {
			return updateReduxState(
				state,
				setLoading(false),
				removeUploadingScanId(action.uploadId),
				removeErrorScanUpload(action.uploadId),
				updateFileId(action.uploadId, '', action.confirmedFileId),
			)
		}
		case 'ERROR_UPLOAD_ACCOUNTING_DOCUMENT_SCAN': {
			return updateReduxState(
				state,
				setLoading(false),
				removeUploadingScanId(action.uploadId),
				removeScan(action.uploadId),
				addErrorScanUpload(action.file, action.documentCategory, action.uploadId),
			)
		}

		case 'ADD_ACCOUNTING_DOCUMENT_SCAN': {
			return {
				...state,
				scans: [...state.scans, action.scan],
			}
		}

		case 'TOGGLE_ALL_ACCOUNTING_DOCUMENT_SCANS': {
			const newScans = state.scans.map((scan: Scan) => {
				return { ...scan, checked: false }
			})

			return {
				...state,
				scans: newScans,
			}
		}

		case 'TOGGLE_ACCOUNTING_DOCUMENT_SCAN':
		case 'CHANGE_ACCOUNTING_DOCUMENT_SCAN_BRANCH':
		case 'CHANGE_ACCOUNTING_DOCUMENT_SCAN_PROJECT':
		case 'CHANGE_ACCOUNTING_DOCUMENT_SCAN_PAYMENT_TYPE':
		case 'CHANGE_ACCOUNTING_DOCUMENT_SCAN_CASH_REGISTER':
		case 'CHANGE_ACCOUNTING_DOCUMENT_SCAN_CUSTOMER_INSTRUCTIONS':
		case 'CHANGE_ACCOUNTING_DOCUMENT_SCAN_CATEGORY': {
			const id: string = action.payload.id + ''
			const scansState: Array<Scan> = updateScan(state.scans, id, action.payload)

			return {
				...state,
				loading: false,
				scans: scansState,
			}
		}

		case 'DELETE_ACCOUNTING_DOCUMENT_SCAN': {
			const { payload } = action
			return updateReduxState(state, removeScan(payload.id), removeErrorScanUpload(payload.id))
		}

		case 'UPDATE_ACCOUNTING_DOCUMENT_SCAN': {
			const id: string = action.payload.id + ''
			const scansState: Array<Scan> = updateScan(state.scans, id, action.payload)
			return {
				...state,
				loading: false,
				scans: scansState,
			}
		}

		case 'START_CREATE_AD_WITH_SCANS':
			return {
				...state,
				loading: true,
			}

		case 'CLEAR_SCANS':
		case 'FINISH_CREATE_AD_WITH_SCANS':
			return {
				...state,
				scans: [],
				loading: false,
			}

		case 'ERROR_CREATE_AD_WITH_SCANS':
			return {
				...state,
				loading: false,
			}

		default:
			return state
	}
}

// Update scan state helper
function updateScan(scanStates: Array<Scan>, id: string, newScan: any) {
	const newScans = scanStates.map((item: Scan) => {
		if (`${id}` == `${item.id}`) {
			return { ...item, ...newScan }
		}
		return item
	})
	return newScans
}

export default uploadScanReducer
