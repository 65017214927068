// @flow

import { connect } from 'react-redux'
import type { State, AccountingDocument } from 'types'
import { getAccountingDocument } from 'modules/accounting-document/selectors'
import PaymentState from 'modules/accounting-document/components/accounting-document-list/payment-state'

type OwnProps = {|
	accountingDocumentId: string,
	hideEmpty?: boolean,
	compact?: boolean,
|}

type StateProps = {|
	accountingDocument: ?AccountingDocument,
|}

function mapStateToProps(state: State, ownProps: OwnProps): StateProps {
	return {
		accountingDocument: getAccountingDocument(state, ownProps.accountingDocumentId),
	}
}

export default connect(mapStateToProps)(PaymentState)
