/* @flow */

import React, { Component } from 'react'
import { isEqualDate } from './date-utils'
import EnhancedButton from 'components/internal/EnhancedButton'
import { colors } from 'variables'

export type Props = {|
	DateTimeFormat: (string, Object) => ?string,
	date: ?Date,
	disabled: boolean,
	locale: string,
	selected: boolean,
	onClick?: Date => void,
|}

export type State = {|
	hovered: boolean,
|}

function getStyles(props: Props, state: State) {
	const { date, disabled, selected } = props
	const { hovered } = state

	const styles = {
		root: {
			display: 'block',
			boxSizing: 'border-box',
			fontWeight: '600',
			opacity: disabled && '0.4',
			padding: '0px 0px',
			position: 'relative',
			width: 24,
			height: 20,
			marginLeft: 3.5,
			marginRight: 3.5,
			color: colors.black,
			flex: '0 0 24px',
		},
		label: {
			color: colors.black,
			position: 'relative',
		},
		buttonState: {
			backgroundColor: colors.white,
			border: '2px solid #ffffff',
			borderRadius: 2,
			height: 20,
			left: 0,
			position: 'absolute',
			top: 0,
			transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1)',
			width: 24,
		},
	}

	if (hovered || selected) {
		styles.label.color = colors.white
		styles.buttonState.backgroundColor = colors.blue
		styles.buttonState.border = `2px solid ${colors.blue}`
	}

	if (isEqualDate(date, new Date())) {
		styles.buttonState.border = `2px solid ${colors.blue}`
	}

	return styles
}

class DayButton extends Component<Props, State> {
	static defaultProps = {
		selected: false,
		disabled: false,
	}

	state = {
		hovered: false,
	}

	handleMouseEnter = () => {
		if (!this.props.disabled) {
			this.setState({ hovered: true })
		}
	}

	handleMouseLeave = () => {
		if (!this.props.disabled) {
			this.setState({ hovered: false })
		}
	}

	handleClick = () => {
		this.props.date && !this.props.disabled && this.props.onClick && this.props.onClick(this.props.date)
	}

	render() {
		const { DateTimeFormat, date, disabled, locale } = this.props

		const styles = getStyles(this.props, this.state)

		return date ? (
			<EnhancedButton
				disabled={disabled}
				onMouseEnter={this.handleMouseEnter}
				onMouseLeave={this.handleMouseLeave}
				onClick={this.handleClick}
				style={styles.root}
			>
				<div style={styles.buttonState} />
				<span style={styles.label}>
					{new DateTimeFormat(locale, {
						day: 'numeric',
					}).format(date)}
				</span>
			</EnhancedButton>
		) : (
			<span style={styles.root} />
		)
	}
}

export default DayButton
