/* @flow */

import type {
	BankAccountStatementOptionsFormatType,
	BankAccountStatementOptions as BankAccountStatementOptionsType,
	FormValidationProps,
} from 'types'
import React, { Component } from 'react'
import { type WithTranslateProps, withTranslate } from 'wrappers'

import Button from 'components/button'
import Dialog from 'components/dialog'
import Helmet from 'react-helmet'
import TextField from 'components/TextField'
import Toggle from 'components/toggle'
import { formatToDateTimeString } from 'utils/formatters'
import styles from './bank-account-statement-options.css'
import validate from 'wrappers/validate'

export type Props = {|
	id: string,
	onClose: () => void,
	loadOptions: () => void,
	saveOptions: (options: BankAccountStatementOptionsType) => void,
	statementOptions: BankAccountStatementOptionsType,
	format: ?BankAccountStatementOptionsFormatType,
|}

type State = {|
	downloadToken: string,
	isAutomaticDownloadEnabled: boolean,
	showChangeTokenInput: boolean,
	isSaveDisabled: boolean,
|}

class BankAccountStatementOptions extends Component<Props & WithTranslateProps & FormValidationProps, State> {
	constructor(props: Props & WithTranslateProps & FormValidationProps) {
		super(props)

		this.state = {
			downloadToken: '',
			showChangeTokenInput: false,
			isSaveDisabled: true,
			isAutomaticDownloadEnabled:
				(this.props.statementOptions && this.props.statementOptions.isAutomaticDownloadEnabled) || false,
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps: Props & WithTranslateProps) {
		this.setState({
			isAutomaticDownloadEnabled: nextProps.statementOptions && nextProps.statementOptions.isAutomaticDownloadEnabled,
		})
	}

	componentDidMount() {
		this.props.loadOptions()
	}

	onSave = () => {
		const downloadToken = this.state.isAutomaticDownloadEnabled ? this.state.downloadToken : ''

		this.props.format &&
			this.props.saveOptions({
				isAutomaticDownloadEnabled: this.state.isAutomaticDownloadEnabled,
				downloadToken: downloadToken,
				format: this.props.format,
			})

		this.setState({ showChangeTokenInput: false, isSaveDisabled: true, downloadToken })
	}

	onDownloadTokenChange = (event: SyntheticInputEvent<HTMLInputElement>, value: ?string) => {
		this.setState({ downloadToken: value || '', isSaveDisabled: value === '' })
	}

	onAutomaticDownloadChange = (event: any, value: boolean) => {
		this.setState({ isAutomaticDownloadEnabled: value, isSaveDisabled: false, showChangeTokenInput: false })
	}

	onChangeTokenClick = () => {
		this.setState((prevState: State) => ({
			showChangeTokenInput: !prevState.showChangeTokenInput,
		}))
	}

	getClientValidationErrors() {
		const errors: Object = {}
		if (this.state.isAutomaticDownloadEnabled) {
			if (!this.state.downloadToken && !this.props.statementOptions.isDownloadTokenSet) {
				errors.downloadToken = this.props.t('application.validation.mandatory')
			}
		}
		return errors
	}

	render() {
		if (!this.props.statementOptions) return null
		const { t, onClose } = this.props
		const clientErrors = this.getClientValidationErrors()
		const isValid = Object.keys(clientErrors).length === 0
		this.props.validateForm(clientErrors)

		const actions = [
			<Button
				wide
				primary
				key="saveModalButton"
				labelText={t('application.save')}
				onClick={this.onSave}
				disabled={!isValid || this.state.isSaveDisabled}
				autoTestId="bank-account-statement-save"
			/>,
			<Button
				autoTestId="bank-account-statement-cancel"
				secondary
				key="cancelModalButton"
				labelText={t('forms.cancelButtonLabel')}
				onClick={onClose}
				wide
			/>,
		]

		return (
			<Dialog
				autoTestId="bank-account-statement-options-dialog"
				title={t('bank.statementOptions.header')}
				actions={actions}
				open
			>
				<div className={styles.dialog}>
					<Helmet>
						<title>{this.props.t('bank.statementOptions.header')}</title>
					</Helmet>
					<div className={styles.row}>
						<div className={styles.column}>
							{this.props.validationMessage('isAutomaticDownloadEnabled') && (
								<p className={styles.error}>{this.props.validationMessage('isAutomaticDownloadEnabled')}</p>
							)}
						</div>
					</div>
					<div className={styles.row}>
						<div className={styles.column}>
							<div className={styles.center}>
								<Toggle
									label={t('bank.statementOptions.automaticDownload')}
									labelInside={t('bank.statementOptions.off')}
									labelInsideChecked={t('bank.statementOptions.on')}
									toggled={this.state.isAutomaticDownloadEnabled}
									onToggle={this.onAutomaticDownloadChange}
									autoTestId="bank-statement-options-automatic-download"
									labelPosition={'right'}
									autoWidth
								/>
							</div>
						</div>
					</div>
					{this.renderEnabledForm()}
				</div>
			</Dialog>
		)
	}

	renderEnabledForm() {
		const { t, statementOptions = {} } = this.props
		const { isDownloadTokenSet } = statementOptions
		const { encryptedToken } = statementOptions
		const { showChangeTokenInput } = this.state
		const showTokenInput = !isDownloadTokenSet || showChangeTokenInput
		const hideRow = this.state.isAutomaticDownloadEnabled ? {} : styles.hideRow

		return (
			<div>
				{showTokenInput && isDownloadTokenSet !== undefined && (
					<div className={styles.row}>
						<div className={styles.column}>
							<TextField
								fullWidth
								onChange={this.onDownloadTokenChange}
								value={this.props.validationValue('downloadToken', this.state.downloadToken)}
								hintText={t('bank.statementOptions.downloadTokenHint')}
								clientError={this.props.validationMessage('downloadToken')}
								autoTestId="bank-account-statement-token"
								name="token"
							/>
						</div>
					</div>
				)}
				<div className={`${styles.row} ${hideRow.toString()}`}>
					<div className={styles.column}>
						{isDownloadTokenSet && (
							<div className={styles.text}>
								<div>
									{t('bank.statementOptions.downloadTokenSet')}{' '}
									<span className={styles.link} onClick={this.onChangeTokenClick}>
										{showChangeTokenInput
											? t('bank.statementOptions.keepCurrentToken')
											: t('bank.statementOptions.changeToken')}
									</span>
								</div>
								<div className={styles.text}>
									<span>{encryptedToken}</span>
								</div>
								<div>
									{t('bank.statementOptions.nextDownloadAt')}{' '}
									{statementOptions.nextDownloadAt ? formatToDateTimeString(statementOptions.nextDownloadAt) : ''}
								</div>
							</div>
						)}
						{isDownloadTokenSet === false && (
							<div className={styles.text}>
								<span>{t('bank.statementOptions.downloadTokenNotSet')}</span>
							</div>
						)}
						{isDownloadTokenSet === undefined && <div className={styles.text} />}
					</div>
				</div>
			</div>
		)
	}
}

export default withTranslate(validate(['FINISH_SAVE_BANK_STATEMENT_OPTIONS'])(BankAccountStatementOptions))
