/* @flow */

import React, { Component } from 'react'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import validate from 'wrappers/validate'
import { convertToDate, formatDateToIsoString } from 'utils/formatters'
import { users as usersApi } from 'modules/common/models/api-model'
import type {
	AccountingDocumentInfo,
	FileDescription,
	FormValidationProps,
	Task,
	TaskAction,
	UserProfile,
	OrganizationMembers,
} from 'types'
import { PopupSection, Button, Separator, TextField, DatePicker } from 'components'
import SelectNext from 'components/select-next'
import OrganizationPicker from 'modules/organization/containers/organization-picker'
import FormBox from 'components/form-box'
import AccountingDocumentPicker from 'modules/accounting-document/containers/accounting-document-picker/picker'
import TaskEditFiles from './task-edit-files'
import UserPicker from 'modules/user/containers/user-picker'
import styles from './task.css'
import { uniqueObjArray } from 'utils/array'
import type { OptionType } from 'components/select-next'
import { notNullAndUndefined } from 'utils'

export type Props = {|
	task: ?Task,
	me: ?UserProfile,
	organizationId: number,
	organizations: ?OrganizationMembers,
	onSave: Task => ?Promise<TaskAction>,
	onClose: ?() => void,
	inline: boolean,
	showAccDocs: boolean,
|}

type State = {|
	title: string,
	assigneeId: ?string,
	dueDate: ?Date,
	organizationId: ?number,
	priority?: number,
	attachedFiles: Array<FileDescription>,
	attachedAccountingDocumentIds: Array<AccountingDocumentInfo>,
	state: number,
	selectedOrganizationUsers: Array<UserProfile>,
|}

type AttachementFileItem = {|
	fileId?: string,
	name?: string,
|}

type ComponentProps = {| ...WithTranslateProps, ...FormValidationProps, ...Props |}

const ALLOWED_TYPES = [0, 1, 2, 3]

class TaskEdit extends Component<ComponentProps, State> {
	state = this.getInitialState(this.props)

	UNSAFE_componentWillReceiveProps(nextProps: ComponentProps) {
		if (nextProps.task !== this.props.task) {
			this.setState(this.getInitialState(nextProps))
		}
	}

	componentDidMount = async () => {
		if (this.state.selectedOrganizationUsers.length === 0 && this.state.organizationId) {
			const selectedOrganizationUsers =
				this.state.organizationId && (await this.loadOrganizationUsers(this.state.organizationId.toString()))
			// eslint-disable-next-line react/no-did-mount-set-state
			this.setState({ selectedOrganizationUsers: selectedOrganizationUsers || [] })
		}
	}

	getInitialState(props: ComponentProps): State {
		let dueDate: ?Date
		let attachedFiles: Array<FileDescription> = []
		let attachedAccountingDocumentIds: Array<AccountingDocumentInfo> = []

		if (props.task && props.task.dueDate) {
			dueDate = convertToDate(props.task.dueDate)
		}

		if (props.task && props.task.attachedFiles) {
			attachedFiles = props.task.attachedFiles.map((item: AttachementFileItem) => {
				return {
					id: item.fileId || '',
					fileId: item.fileId,
					name: item.name || '',
					loading: false,
				}
			})
		}

		if (props.task && props.task.attachedAccountingDocumentIds) {
			attachedAccountingDocumentIds = [...props.task.attachedAccountingDocumentIds]
		}

		return {
			title: (props.task && props.task.title) || '',
			assigneeId: (props.task && props.task.assigneeId && props.task.assigneeId.toString()) || '',
			dueDate: dueDate,
			organizationId: (props.task && props.task.organizationId) || props.organizationId,
			priority: props.task && null != props.task.priority ? props.task.priority : 1, // Normal
			attachedFiles: attachedFiles,
			attachedAccountingDocumentIds: attachedAccountingDocumentIds,
			state: (props.task && props.task.state) || 0, // New
			selectedOrganizationUsers: [],
		}
	}

	handleTitleChange = (event: SyntheticInputEvent<HTMLInputElement>, title: ?string) => {
		if (title !== null && title !== undefined) {
			this.setState({ title })
		}
	}

	handleUserPickerChange = (value: ?string) => {
		this.setState({
			assigneeId: value,
		})
	}

	handleDueDateChange = (event: ?SyntheticInputEvent<HTMLInputElement>, date: ?Date) => {
		if (date && !isNaN(date)) {
			this.setState({
				dueDate: date,
			})
		}
	}

	handleOrganizationChange = async (organizationId: ?string): Promise<void> => {
		const id = parseInt(organizationId, 10)
		const selectedOrganizationUsers = await this.loadOrganizationUsers(organizationId)
		this.setState({
			organizationId: id || null,
			assigneeId: id ? this.state.assigneeId : null,
			attachedAccountingDocumentIds: id ? this.state.attachedAccountingDocumentIds : [],
			selectedOrganizationUsers: selectedOrganizationUsers || [],
		})
	}

	handlePriorityChange = (option: ?OptionType) => {
		this.setState({
			priority: option && notNullAndUndefined(option.value) ? parseInt(option.value) : undefined,
		})
	}

	handleFilesChange = (files: Array<FileDescription>) => {
		this.setState({
			attachedFiles: files,
		})
	}

	handleSelectionChange = (selection: Array<AccountingDocumentInfo>) => {
		this.setState({
			attachedAccountingDocumentIds: selection,
		})
	}

	handleSaveClick = () => {
		let task: Task = {
			title: this.state.title,
			priority: this.state.priority,
			state: this.state.state,
		}

		if (this.state.assigneeId) {
			task.assigneeId = this.state.assigneeId
		}

		if (this.state.dueDate) {
			task.dueDate = formatDateToIsoString(this.state.dueDate)
		}

		if (this.state.organizationId) {
			task.organizationId = this.state.organizationId
		}

		if (this.state.attachedFiles) {
			task.attachedFiles = this.state.attachedFiles.map((file: FileDescription) => {
				return {
					fileId: file.fileId,
					name: file.name,
				}
			})
		}

		if (this.state.attachedAccountingDocumentIds) {
			task.attachedAccountingDocumentIds = this.state.attachedAccountingDocumentIds
		}

		this.props.onSave(task)
	}

	getClientValidationErrors(t: (key: string) => string) {
		const errors = {}

		if (!this.state.title) {
			errors.title = t('application.validation.mandatory')
		}

		if (!this.state.organizationId) {
			errors.organizationId = t('application.validation.mandatory')
		}

		if (!this.state.assigneeId) {
			errors.assigneeId = t('application.validation.mandatory')
		}

		return errors
	}

	renderActionButton() {
		return (
			<Button
				labelText={this.isNew() ? this.props.t('application.create') : this.props.t('application.save')}
				disabled={this.state.attachedFiles.some((file: FileDescription) => file.loading)}
				onClick={this.props.validationSubmit(this.handleSaveClick)}
				autoTestId="task-edit-create"
			/>
		)
	}

	renderHeader() {
		return (
			<TextField
				autoWidth
				autoHeight
				multiLine
				rows={1}
				rowsMax={5}
				value={this.props.validationValue('title', this.state.title)}
				onChange={this.handleTitleChange}
				hintText={this.props.t('task.edit.nameHint')}
				autoTestId="task-edit-name"
				autoFocus
				inline
				clientError={this.props.validationMessage('title')}
				name="title"
			/>
		)
	}

	isNew = () => {
		return !(this.props.task && this.props.task.id)
	}

	loadOrganizationUsers = async (organizationId: ?string): Promise<Array<UserProfile>> => {
		try {
			const params = organizationId ? { organizationId } : {}
			const options = organizationId ? { customOrganizationId: organizationId } : {}

			// Načítat to tady napřímo je dost nešikovný
			const loadedUsers = await usersApi.get(params, options)

			return uniqueObjArray(loadedUsers, 'id')
		} catch (error) {
			return Promise.reject()
		}
	}

	renderContent() {
		const { organizations, t } = this.props
		const organizationId = (this.props.organizationId && this.props.organizationId.toString()) || null
		const showOrganizationPicker = organizations && organizations.length > 0
		const columnClass = showOrganizationPicker ? styles.halfColumn : styles.thirdColumn

		return (
			<FormBox
				header={this.renderHeader()}
				actions={this.renderActionButton()}
				headline={this.isNew() ? t('task.create.header') : t('task.edit.header')}
			>
				<div>
					<div className={styles.row}>
						<div className={columnClass}>
							<UserPicker
								isClearable
								labelText={t('task.edit.assignee')}
								placeholder={t('task.edit.selectAssignee')}
								value={this.props.validationValue('assigneeId', this.state.assigneeId)}
								onChange={this.handleUserPickerChange}
								show={this.state.organizationId ? 'ALL' : 'INTERNAL'}
								selectedOrganizationUsers={this.state.selectedOrganizationUsers}
								clientError={this.props.validationMessage('assigneeId')}
								autoTestId="task-edit-user-picker"
								showMe
							/>
						</div>

						{showOrganizationPicker && (
							<div className={columnClass}>
								<OrganizationPicker
									value={this.props.validationValue(
										'organizationId',
										this.state.organizationId ? this.state.organizationId.toString() : organizationId,
									)}
									onChange={this.handleOrganizationChange}
									fullWidth
									labelText={t('task.edit.organization')}
									hintText={t('user.organizationSelect.selectOrganization')}
									autoSelect
									clientError={this.props.validationMessage('organizationId')}
								/>
							</div>
						)}
						<div className={columnClass}>
							<DatePicker
								labelText={t('task.edit.dueDate')}
								hintText={t('task.edit.selectDueDate')}
								value={this.state.dueDate || undefined}
								onChange={this.handleDueDateChange}
								fullWidth
								minDate={this.isNew() ? new Date() : null}
								autoTestId="task-edit-due-date"
							/>
						</div>
						<div className={columnClass}>
							<SelectNext
								label={t('task.edit.priority')}
								autoTestId="task-edit-priority"
								value={this.state.priority}
								onChange={this.handlePriorityChange}
								options={[
									{ value: 0, label: t('task.priorityLevel.low') },
									{ value: 1, label: t('task.priorityLevel.normal') },
									{ value: 2, label: t('task.priorityLevel.high') },
								]}
							/>
						</div>
					</div>

					<div className={styles.rowInitial}>
						<Separator
							left={
								<span className={styles.separatorText}>
									{this.state.attachedFiles.length > 0 ? t('task.edit.attachedFiles') : t('task.edit.attachFiles')}
								</span>
							}
						/>
						<TaskEditFiles
							internal={!this.state.organizationId}
							value={this.state.attachedFiles}
							onChange={this.handleFilesChange}
						/>
					</div>

					{this.state.organizationId && this.props.showAccDocs && (
						<div className={styles.rowInitial}>
							<Separator
								left={
									<span className={styles.separatorText}>
										{this.state.attachedAccountingDocumentIds.length > 0
											? t('task.edit.attachedAccountingDocuments')
											: t('task.edit.attachAccountingDocuments')}
									</span>
								}
							/>
							<AccountingDocumentPicker
								checkAllOption
								selected={this.state.attachedAccountingDocumentIds}
								onSelectionChange={this.handleSelectionChange}
								organizationId={this.state.organizationId || undefined}
								allowedTypes={ALLOWED_TYPES}
								light
							/>
						</div>
					)}
				</div>
			</FormBox>
		)
	}

	render() {
		const { t } = this.props
		this.props.validateForm(this.getClientValidationErrors(t))
		if (this.props.inline) {
			return this.renderContent()
		} else {
			return (
				<PopupSection open onRequestClose={this.props.onClose}>
					{this.renderContent()}
				</PopupSection>
			)
		}
	}
}

export default withTranslate(validate(['FINISH_CREATE_TASK', 'FINISH_UPDATE_TASK'])(TaskEdit))
