/* @flow */

import React, { Component } from 'react'
import BankAccountGrid from '../containers/bank-account-grid'
import styles from './bank-account.css'
import Helmet from 'react-helmet'
import { withTranslate, type WithTranslateProps } from 'wrappers'

type Props = {|
	...WithTranslateProps,
|}

class BankAccountList extends Component<Props> {
	render() {
		const { t } = this.props
		return (
			<div className={styles.root}>
				<Helmet>
					<title>{this.props.t('bank.headline')}</title>
				</Helmet>
				<h1>{t('navigation.bank')}</h1>
				<BankAccountGrid />
			</div>
		)
	}
}

export default withTranslate(BankAccountList)
