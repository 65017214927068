/* @flow */

import type { FeatureId, FeaturesAction, UserAction, Permission, FeaturesAndPermissions } from 'types'
import { updateReduxState } from 'utils/redux'

export type State = FeaturesAndPermissions

export const initialState: FeaturesAndPermissions = {
	serverEnabledFeatures: [],
	userEnabledFeatures: [],
	userDisabledFeatures: [],
	userEnabledTestPermissions: [],
	userDisabledTestPermissions: [],
}

const addEnabledFeature = (featureId: FeatureId) => {
	return (state: State): State => {
		return {
			...state,
			userEnabledFeatures: state.userEnabledFeatures.concat(featureId),
		}
	}
}

const removeEnabledFeature = (featureId: FeatureId) => {
	return (state: State): State => {
		return {
			...state,
			userEnabledFeatures: state.userEnabledFeatures.filter((id: FeatureId) => id !== featureId),
		}
	}
}

const addDisabledFeature = (featureId: FeatureId) => {
	return (state: State): State => {
		return {
			...state,
			userDisabledFeatures: state.userDisabledFeatures.concat(featureId),
		}
	}
}

const removeDisabledFeature = (featureId: FeatureId) => {
	return (state: State): State => {
		return {
			...state,
			userDisabledFeatures: state.userDisabledFeatures.filter((id: FeatureId) => id !== featureId),
		}
	}
}

const addEnabledTestPermission = (permission: Permission) => {
	return (state: State): State => {
		return {
			...state,
			userEnabledTestPermissions: state.userEnabledTestPermissions.concat(permission),
		}
	}
}

const removeEnabledTestPermission = (permission: Permission) => {
	return (state: State): State => {
		return {
			...state,
			userEnabledTestPermissions: state.userEnabledTestPermissions.filter((p: Permission) => p !== permission),
		}
	}
}

const addDisabledTestPermission = (permission: Permission) => {
	return (state: State): State => {
		return {
			...state,
			userDisabledTestPermissions: state.userDisabledTestPermissions.concat(permission),
		}
	}
}

const removeDisabledTestPermission = (permission: Permission) => {
	return (state: State): State => {
		return {
			...state,
			userDisabledTestPermissions: state.userDisabledTestPermissions.filter((p: Permission) => p !== permission),
		}
	}
}

export default (state: State = initialState, action: UserAction | FeaturesAction): State => {
	switch (action.type) {
		case 'LOAD_FEATURES_AND_TEST_PERMISSIONS': {
			return {
				...state,
				...action.payload,
			}
		}

		case 'ENABLE_FEATURE': {
			return updateReduxState(state, removeDisabledFeature(action.featureId), addEnabledFeature(action.featureId))
		}

		case 'DISABLE_FEATURE': {
			return updateReduxState(state, addDisabledFeature(action.featureId), removeEnabledFeature(action.featureId))
		}

		case 'REMOVE_FEATURE': {
			return updateReduxState(state, removeDisabledFeature(action.featureId), removeEnabledFeature(action.featureId))
		}

		case 'ENABLE_TEST_PERMISSION': {
			return updateReduxState(
				state,
				removeDisabledTestPermission(action.permission),
				addEnabledTestPermission(action.permission),
			)
		}

		case 'DISABLE_TEST_PERMISSION': {
			return updateReduxState(
				state,
				addDisabledTestPermission(action.permission),
				removeEnabledTestPermission(action.permission),
			)
		}

		case 'REMOVE_TEST_PERMISSION': {
			return updateReduxState(
				state,
				removeDisabledTestPermission(action.permission),
				removeEnabledTestPermission(action.permission),
			)
		}

		case 'REMOVE_ALL_TEST_PERMISSIONS': {
			return {
				...state,
				userEnabledTestPermissions: [],
				userDisabledTestPermissions: [],
			}
		}

		default:
			return state
	}
}
