/* @flow */

import type { Action } from '../actions/action-types'

export type State = {
	compact: boolean,
}

export const initialState: State = {
	compact: false,
}

export default (state: State = initialState, action: Action): State => {
	switch (action.type) {
		case 'SET_NAVIGATION_COMPACT':
			return {
				...state,
				compact: action.compact != null ? action.compact : !state.compact,
			}
		default:
			return state
	}
}
