//@flow
import type { Action } from '../actions/actions-types'
import type { ISignalMessage, HubConnection } from 'types'

export type State = {
	messages: Array<ISignalMessage>,
	connecting: boolean,
	hubConnection: ?HubConnection,
	notificationPreview: ?ISignalMessage,
	historyLoaded: boolean,
}

export const initialState: State = {
	messages: [],
	connecting: false,
	hubConnection: null,
	notificationPreview: null,
	historyLoaded: false,
}

export default (state: State = initialState, action: Action): State => {
	switch (action.type) {
		case 'ESTABLISHING_CONNECTION_START':
			return {
				...state,
				connecting: true,
			}
		case 'ESTABLISHING_CONNECTION_SUCCESS':
			return {
				...state,
				connecting: false,
				hubConnection: action.payload,
			}
		case 'STOP_CONNECTION':
			return {
				...state,
				connecting: false,
				hubConnection: null,
				messages: [],
			}
		case 'LOAD_HISTORY':
			return {
				...state,
				historyLoaded: true,
				messages: [...action.payload],
			}
		case 'ADD_NOTIFICATION_MESSAGE':
			return {
				...state,
				messages: [...state.messages, action.payload],
			}
		case 'REMOVE_READ_MESSAGES':
			return removeFilteredMessages(state, action)
		case 'SHOW_NOTIFICATION_PREVIEW':
			return {
				...state,
				notificationPreview: action.payload,
			}
		case 'HIDE_NOTIFICATION_PREVIEW':
			return {
				...state,
				notificationPreview: null,
			}
		default:
			return state
	}
}

const removeFilteredMessages = (state: State, action: any): State => {
	const payload = action.payload
	const messages = state.messages.filter((i: ISignalMessage) => !payload.some((id: string) => id === i.id))
	return {
		...state,
		messages,
	}
}
