/* @flow */

import type { AccountingDocumentCategory, AccountingDocumentCategoryUpdateRequest, OrganizationSettings } from 'types'
import Dialog from 'components/dialog'
import DeleteDialog from 'components/delete-dialog'
import Button from 'components/button'
import TextField from 'components/TextField'
import Table from 'components/table/table'
import TableBody from 'components/table/table-body'
import TableHeader from 'components/table/table-header'
import TableHeaderCell from 'components/table/table-header-cell'
import TableRow from 'components/table/table-row'
import TableCell from 'components/table/table-cell'
import Toggle from 'components/toggle'
import ConfirmDialog from 'components/confirm-dialog'
import React, { Component } from 'react'
import type { FinishEditingAccountingDocumentCategory } from 'modules/settings/actions/accounting-document-categories-action-types'
import { withTranslate, type WithTranslateProps, withNotify, type WithNotifyProps } from 'wrappers'
import { emptyAccountingDocumentCategory } from 'types/empty'
import styles from '../settings.css'
import IconMenu from 'components/icon-menu'
import MenuItem from 'components/menu-item'
import Edit from 'components/svg-icons/image/edit'
import Delete from 'components/svg-icons/action/delete'

type Props = {|
	...WithTranslateProps,
	...WithNotifyProps,
	organizationSettings: ?OrganizationSettings,
	accountingDocumentCategories: ?Array<AccountingDocumentCategory>,
	loadAccountingDocumentCategories: () => void,
	editAccountingDocumentCategory: (
		id: string,
		data: AccountingDocumentCategoryUpdateRequest,
	) => Promise<FinishEditingAccountingDocumentCategory>,
	deleteAccountingDocumentCategory: (id: string) => void,
	createAccountingDocumentCategory: (category: AccountingDocumentCategory) => void,
	loadOrganizationSettings: () => void,
	updateOrganizationSettings: (oldSettings: OrganizationSettings, newSettings: OrganizationSettings) => Promise<any>,
	resetAccountingDocumentsView: () => Promise<void>,
|}

type State = {
	category: ?AccountingDocumentCategory,
	accDocUploadAutomaticApproval: boolean,
}

class AccountingDocumentCategories extends Component<Props, State> {
	constructor(props: Props) {
		super(props)
		this.state = {
			category: null,
			accDocUploadAutomaticApproval: false,
		}

		if (!props.accountingDocumentCategories) {
			props.loadAccountingDocumentCategories()
		}
		if (!props.organizationSettings) {
			props.loadOrganizationSettings()
		}
	}

	static getDerivedStateFromProps(nextProps: Props, prevState: State) {
		const { organizationSettings } = nextProps
		const accountingDocuments: ?boolean =
			organizationSettings &&
			organizationSettings.accountingDocuments &&
			organizationSettings.accountingDocuments.upload &&
			organizationSettings.accountingDocuments.upload.automaticApproval

		if (accountingDocuments && prevState.accDocUploadAutomaticApproval !== accountingDocuments) {
			return { accDocUploadAutomaticApproval: accountingDocuments }
		}

		return null
	}

	onNameChange = (event: SyntheticInputEvent<HTMLInputElement>, name: ?string) => {
		if (name !== null && name !== undefined) {
			this.setState({
				category: Object.freeze({
					...this.state.category,
					name,
				}),
			})
		}
	}

	onSaveAccountingDocumentCategory = async () => {
		const { t } = this.props
		const { category } = this.state
		if (!category) {
			return
		}

		if (category.id) {
			if (!category.name) {
				return this.props.notify(t('clientError.fillAll'), 'error')
			}
			const resp: FinishEditingAccountingDocumentCategory = await this.props.editAccountingDocumentCategory(
				category.id,
				{ name: category.name },
			)
			!resp.serverError && this.closeDialog()
		} else {
			if (!category.name) {
				return this.props.notify(t('clientError.fillAll'), 'error')
			}
			this.props.createAccountingDocumentCategory(category)
			this.closeDialog()
		}
	}

	onCreateCategory = () => {
		this.setState({
			category: emptyAccountingDocumentCategory(),
		})
	}

	editCategory = (category: AccountingDocumentCategory) => () => {
		this.setState({ category })
	}

	deleteCategory = (id: string) => () => {
		DeleteDialog()
			.then(() => {
				this.props.deleteAccountingDocumentCategory(id)
			})
			.catch(() => {})
	}

	togglePermalink = () => {
		const { organizationSettings } = this.props

		if (!organizationSettings) return

		const newSettings: OrganizationSettings = Object.freeze({
			...organizationSettings,
			allowAccountingDocumentPermalinks: !organizationSettings.allowAccountingDocumentPermalinks,
		})

		this.props.organizationSettings &&
			this.props.updateOrganizationSettings(this.props.organizationSettings, newSettings)
	}

	toggleAccDocUploadAutomaticApproval = () => {
		const { t } = this.props
		const { organizationSettings } = this.props

		if (!organizationSettings) return

		const newSettings: OrganizationSettings = Object.freeze({
			...organizationSettings,
			accountingDocuments: {
				upload: {
					automaticApproval: !this.state.accDocUploadAutomaticApproval,
				},
			},
		})

		if (!this.state.accDocUploadAutomaticApproval) {
			ConfirmDialog(t('settings.accountingDocumentCategories.disableAccDocApproval'), {
				okLabel: t('settings.accountingDocumentCategories.confirmDisableAccDocApproval'),
				cancelLabel: t('dialogs.dontDelete'),
				waitForConfirm: true,
			}).then(() => {
				this.setState((state: State) => {
					return { accDocUploadAutomaticApproval: !state.accDocUploadAutomaticApproval }
				})

				this.props.organizationSettings &&
					this.props.updateOrganizationSettings(this.props.organizationSettings, newSettings)
			})
		} else {
			this.setState((state: State) => {
				return { accDocUploadAutomaticApproval: !state.accDocUploadAutomaticApproval }
			})

			this.props.organizationSettings &&
				this.props.updateOrganizationSettings(this.props.organizationSettings, newSettings)
		}

		this.props.resetAccountingDocumentsView()
	}

	closeDialog = () => {
		this.setState({ category: null })
	}

	renderDialog() {
		const { t } = this.props
		const { category } = this.state
		const isNew: boolean = !!(category && !category.id)
		const actions = [
			<Button
				primary
				wide
				key="addModalButton"
				labelText={isNew ? t('settings.forms.createButton') : t('settings.forms.editButton')}
				onClick={this.onSaveAccountingDocumentCategory}
				autoTestId="settings-categories-dialog-add"
			/>,
			<Button
				secondary
				wide
				key="cancelModalButton"
				labelText={t('settings.forms.cancelButton')}
				onClick={this.closeDialog}
				autoTestId="settings-categories-dialog-cancel"
			/>,
		]
		return (
			<Dialog
				autoTestId="accounting-document-categories-dialog"
				title={
					isNew
						? t('settings.accountingDocumentCategories.newCategoryTitle')
						: t('settings.accountingDocumentCategories.editCategoryTitle')
				}
				actions={actions}
				open={!!this.state.category}
			>
				<div className={styles.row}>
					<TextField
						labelText={t('settings.accountingDocumentCategories.name')}
						onChange={this.onNameChange}
						value={(category && category.name) || ''}
						fullWidth
						name="name"
						autoTestId="settings-accounting-document-name"
					/>
				</div>
			</Dialog>
		)
	}

	renderCategories(categories: ?Array<AccountingDocumentCategory>, editable: boolean) {
		const { t } = this.props
		if (!categories) return null

		return (
			<div>
				<h3 className={editable ? styles.h3 : styles.firstH3}>
					{t(`settings.accountingDocumentCategories.${editable ? 'customCategories' : 'defaultCategories'}`)}
				</h3>
				<Table>
					<TableHeader>
						<TableRow>
							<TableHeaderCell>{t('settings.accountingDocumentCategories.name')}</TableHeaderCell>
							<TableHeaderCell width={35} />
						</TableRow>
					</TableHeader>

					<TableBody>
						{categories.map((category: AccountingDocumentCategory, i: number) => (
							<TableRow key={category.id || 'newCategory' + i}>
								<TableCell>{category.name}</TableCell>
								<TableCell>
									{editable ? (
										<IconMenu autoTestId="accounting-document-categories-actions" disabled={!editable} context>
											{[
												<MenuItem
													key={'edit'}
													disabled={!category.id || !category.organizationId} // if it is new category od global category
													primaryText={this.props.t('settings.forms.editButton')}
													icon={<Edit />}
													onClick={this.editCategory(category)}
													tall
												/>,
												<MenuItem
													key={'delete'}
													primaryText={this.props.t('settings.forms.removeButton')}
													icon={<Delete />}
													onClick={this.deleteCategory(category.id || '')}
													tall
												/>,
											]}
										</IconMenu>
									) : null}
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</div>
		)
	}

	render() {
		const { t, organizationSettings } = this.props
		const defaultCategories =
			this.props.accountingDocumentCategories &&
			this.props.accountingDocumentCategories.filter(
				(category: AccountingDocumentCategory) => !category.id || !category.organizationId,
			)
		const customCategories =
			this.props.accountingDocumentCategories &&
			this.props.accountingDocumentCategories.filter(
				(category: AccountingDocumentCategory) => category.id && category.organizationId,
			)

		return (
			<div>
				<h2 className={styles.firstH2}>{t('settings.accountingDocumentCategories.permalink')}</h2>
				<div>
					<Toggle
						autoTestId="accounting-documents-permalink"
						label={t('settings.accountingDocumentCategories.accountingDocumentsPermalink')}
						toggled={!!(organizationSettings && organizationSettings.allowAccountingDocumentPermalinks)}
						onToggle={this.togglePermalink}
					/>
				</div>
				<h2 className={styles.h2}>{t('settings.accountingDocumentCategories.approval')}</h2>
				<div>
					<Toggle
						autoTestId="accounting-documents-approval"
						label={t('settings.accountingDocumentCategories.accountingDocumentsApproval')}
						toggled={!this.state.accDocUploadAutomaticApproval}
						onToggle={this.toggleAccDocUploadAutomaticApproval}
					/>
				</div>
				<h2 className={styles.h2}>{t('settings.accountingDocumentCategories.category')}</h2>
				<div className={styles.addButton}>
					<Button
						primary
						labelText={t('settings.accountingDocumentCategories.newCategory')}
						onClick={this.onCreateCategory}
						autoTestId="settings-categories-new"
					/>
				</div>
				{this.renderCategories(defaultCategories, false)}
				{this.renderCategories(customCategories, true)}
				{this.renderDialog()}
			</div>
		)
	}
}

export default withNotify(withTranslate(AccountingDocumentCategories))
