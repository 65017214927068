// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[3].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js!../../../variables/colors.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3EEpIBIiWymfditQ_4SJSq {\n\tmargin-top: 50px;\n}\n\n.OY_2H6EvUtbe9XQHuiB7G {\n\tflex-direction: row;\n\tdisplay: flex;\n}\n\n._3NVV7TelsNYQ13V0A-MfDR {\n\tflex-direction: column;\n\theight: 25px;\n}\n\n.K5SAsuMp3ChFple0zsBy0 {\n\twidth: 30px;\n}\n", "",{"version":3,"sources":["webpack://./app/permissions/test-tool/permissions-list.css"],"names":[],"mappings":"AAEA;CACC,gBAAA;AAOD;;AAJA;CACC,mBAAA;CACA,aAAA;AAOD;;AAJA;CACC,sBAAA;CACA,YAAA;AAOD;;AAJA;CACC,WAAA;AAOD","sourcesContent":["@value grey from \"variables/colors.css\";\n\n.buttonWrapper {\n\tmargin-top: 50px;\n}\n\n.row {\n\tflex-direction: row;\n\tdisplay: flex;\n}\n\n.rowTexts {\n\tflex-direction: column;\n\theight: 25px;\n}\n\n.deleteButtonWrapper {\n\twidth: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"grey": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["grey"] + "",
	"buttonWrapper": "_3EEpIBIiWymfditQ_4SJSq",
	"row": "OY_2H6EvUtbe9XQHuiB7G",
	"rowTexts": "_3NVV7TelsNYQ13V0A-MfDR",
	"deleteButtonWrapper": "K5SAsuMp3ChFple0zsBy0"
};
export default ___CSS_LOADER_EXPORT___;
