/* @flow */

import { connect } from 'react-redux'
import PaymentType from '../invoice-elements/payment-type'
import type { InterfaceProps as PaymentTypeProps } from '../invoice-elements/payment-type'
import type { State, Dispatch, AccountingDocumentReduxAction, FormFieldErrorContext } from 'types'
import { getAccountingDocumentPaymentType } from '../../selectors'
import { updateAccountingDocumentPaymentType } from '../../actions'

type InterfaceProps = {|
	accountingDocumentId: string,
	readonly?: boolean,
	formFieldErrorContext?: FormFieldErrorContext,
|}

type StateProps = {|
	paymentType: ?number,
|}
const mapStateToProps = (state: State, ownProps: InterfaceProps): StateProps => {
	return {
		paymentType: getAccountingDocumentPaymentType(state, ownProps.accountingDocumentId),
	}
}

type DispatchProps = {|
	onPaymentTypeChange: (paymentType: number) => void,
|}
const mapDispatchToProps = (
	dispatch: Dispatch<AccountingDocumentReduxAction>,
	ownProps: InterfaceProps,
): DispatchProps => {
	return {
		onPaymentTypeChange: (paymentType: number) => {
			dispatch(updateAccountingDocumentPaymentType(ownProps.accountingDocumentId, paymentType))
		},
	}
}

const mergeProps = (
	stateProps: StateProps,
	dispatchProps: DispatchProps,
	ownProps: InterfaceProps,
): PaymentTypeProps => {
	return {
		readonly: ownProps.readonly,
		paymentType: stateProps.paymentType,
		onPaymentTypeChange: dispatchProps.onPaymentTypeChange,
		formFieldErrorContext: ownProps.formFieldErrorContext,
	}
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(PaymentType)
