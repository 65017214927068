/* @flow */

import { connect } from 'react-redux'
import { userHasAccess } from 'permissions'
import type {
	AccountingDocumentDirection,
	AccountingDocumentListAction,
	AccountingDocumentView,
	DataGridNextAction,
	Dispatch,
	State,
} from 'types'
import { changeAccountingDocumentsDirection, changeAccountingDocumentsView } from '../../actions'
import DetailDirectionViewSwitcher from '../../components/accounting-document-list/detail-direction-view-switcher'
import { changeCurrentPage } from 'modules/data-grid-next/actions'
import { DEFAULT_LIST_VIEW } from '../../constants'

type StateProps = {|
	showTabs: boolean,
	view: AccountingDocumentView,
|}

function mapStateToProps(state: State): StateProps {
	return {
		view: state.accountingDocument.accountingDocumentList.view,
		showTabs:
			userHasAccess(state, 'seeIssuedAccountingDocuments') && userHasAccess(state, 'seeReceivedAccountingDocuments'),
	}
}

type DispatchProps = {|
	resetView: () => Promise<void>,
	onChangeDirection: (direction: AccountingDocumentDirection, redirect?: boolean) => void,
|}

function mapDispatchToProps(dispatch: Dispatch<AccountingDocumentListAction | DataGridNextAction>): DispatchProps {
	return {
		onChangeDirection: (direction: AccountingDocumentDirection) => {
			dispatch(changeAccountingDocumentsDirection(direction))
		},
		resetView: async (): Promise<void> => {
			await dispatch(changeCurrentPage('accountingDocuments', 0))
			await dispatch(changeAccountingDocumentsView(DEFAULT_LIST_VIEW))
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(DetailDirectionViewSwitcher)
