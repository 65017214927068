/* @flow */
/* eslint-disable react/no-multi-comp */

import React from 'react'
import moize from 'moize'
import type { AccountingDocument } from 'types'
import { type WithTranslateProps } from 'wrappers'
import AccountingDocumentGridNoteEditor from '../accounting-document-list/grid-note-editor'
import { Column } from 'modules/data-grid-next/components'
import type { Direction } from 'modules/data-grid-next/types'
import { DATA_GRID_CACHE_SIZE } from 'trivi-constants'

type Props = {|
	width?: number | string,
	prop?: 'description' | 'customerInstructions',
	defaultSortDirection?: Direction,
	onChangeNote?: (note: string, accountingDocument: AccountingDocument) => void,
	disabled?: boolean,
	...WithTranslateProps,
|}

export default function getNoteColumn(props: Props) {
	function columnRenderer(value: string, row: AccountingDocument) {
		function changeNoteHandler(note: string) {
			props.onChangeNote && props.onChangeNote(note, row)
		}

		return <AccountingDocumentGridNoteEditor value={value} onChange={changeNoteHandler} disabled={props.disabled} />
	}

	const columnRendererCashed = moize(columnRenderer, { maxSize: DATA_GRID_CACHE_SIZE })

	return (
		<Column
			title={props.t('accountingDocument.columns.note')}
			width={props.width}
			columnId={props.prop || 'description'}
			key={props.prop || 'description'}
			render={columnRendererCashed}
			sortable
			defaultSortDirection={props.defaultSortDirection || undefined}
		/>
	)
}
