import React from 'react'
import pure from 'recompose/pure'
import SvgIcon from 'components/SvgIcon'

let Receipt = props => (
	<SvgIcon {...props}>
		<g id="Page-1" stroke="none" strokeWidth="1" fillRule="evenodd">
			<g id="Apple-TV">
				<g id="Group">
					<g id="receipt">
						<path
							d="M15.3071909,21.6647994 L7.01379837,21.6647994 L7.01379837,19.8871995 L15.3071909,19.8871995 C16.2066565,19.8871995 16.9381389,19.1557171 16.9381389,18.2571403 L16.9381389,6.40765909 C16.9381389,5.50908232 16.2066565,4.77759995 15.3071909,4.77759995 L7.01379837,4.77759995 C6.1152216,4.77759995 5.38373922,5.50908232 5.38373922,6.40765909 L5.38373922,14.9987996 L3.60613928,14.9987996 L3.60613928,6.40765909 C3.60613928,4.52873595 5.13487523,3 7.01379837,3 L15.3071909,3 C17.1870029,3 18.7157388,4.52873595 18.7157388,6.40765909 L18.7157388,18.2571403 C18.7157388,20.1360635 17.1870029,21.6647994 15.3071909,21.6647994 Z"
							id="Fill-1"
						/>
						<path
							d="M2.77759995,16.6258369 L2.77759995,18.2567848 C2.77759995,19.1562504 3.50908232,19.886844 4.40854789,19.886844 L12.845926,19.886844 C12.6646108,19.4415552 12.5543996,18.9029424 12.5543996,18.2567848 L12.5543996,16.6258369 L2.77759995,16.6258369 Z M15.3070132,21.6644439 L4.40854789,21.6644439 C2.52873595,21.6644439 1,20.135708 1,18.2567848 L1,14.8482369 L14.3319996,14.8482369 L14.3319996,18.2567848 C14.3319996,19.8681792 15.2972364,19.886844 15.3070132,19.886844 L15.3070132,21.6644439 Z"
							id="Fill-3"
						/>
						<path
							d="M13.2073121,7.81436281 C13.2073121,8.80093078 12.4073921,9.60173956 11.4199354,9.60173956 C10.4333674,9.60173956 9.63344741,8.80093078 9.63344741,7.81436281 C9.63344741,6.82779484 10.4333674,6.02698607 11.4199354,6.02698607 C12.4073921,6.02698607 13.2073121,6.82779484 13.2073121,7.81436281 Z"
							id="Stroke-5"
							stroke="#FFFFFF"
						/>
						<path
							d="M20.8888,17.5445894 L21.7775999,19.0626597 L22.6663999,17.5445894 L22.6663999,5.88886655 L20.8888,5.88886655 L20.8888,17.5445894 Z M21.7775999,20.8224837 L20,17.7854542 L20,5.00006658 L23.5551999,5.00006658 L23.5551999,17.7854542 L21.7775999,20.8224837 Z"
							id="Fill-7"
						/>
						<polygon
							id="Fill-9"
							points="20.0504386 8.70342942 22.7168385 8.70342942 22.7168385 5.44419992 20.0504386 5.44419992"
						/>
						<polygon
							id="Path"
							opacity="0.439999998"
							points="21.4948386 8.25902944 23.2724385 8.25902944 23.2724385 5.88859991 21.4948386 5.88859991"
						/>
						<polygon
							id="Fill-13"
							opacity="0.600000024"
							points="6.97451342 12.9430053 12.2513189 12.9430053 12.2513189 11.7768997 6.97451342 11.7768997"
						/>
						<polygon
							id="Fill-14"
							opacity="0.600000024"
							points="13.6396244 12.9430053 15.4172244 12.9430053 15.4172244 11.7768997 13.6396244 11.7768997"
						/>
						<polygon
							id="Fill-15"
							opacity="0.600000024"
							points="6.90429822 14.845926 12.1811037 14.845926 12.1811037 14.1793261 6.90429822 14.1793261"
						/>
					</g>
				</g>
			</g>
		</g>
	</SvgIcon>
)
Receipt = pure(Receipt)

export default Receipt
