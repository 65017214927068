/* @flow */
/** @jsx jsx */

import { Component } from 'react'
import { jsx } from '@emotion/core'
import type { Tag, TagType, DataGridId } from 'types'
import { TagType_TagTypeNumber } from 'types/convertor'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import {
	default as SingleLineDataGrid,
	type Props as SingleLineDataGridProps,
} from 'modules/data-grid-next/components/single-line-data-grid'
import type { Action as DataGridAction } from 'modules/data-grid/types'
import { Column } from 'modules/data-grid-next/components'
import Delete from 'components/svg-icons/action/delete'
import Edit from 'components/svg-icons/image/edit'
import DeleteDialog from 'components/delete-dialog'
import TagsAdvancedFilter from './tags-advanced-filter'
import FullText from 'components/full-text'

const DATA_TYPE = 'tags'
const TagsDataGrid: React$ComponentType<SingleLineDataGridProps<typeof DATA_TYPE>> = SingleLineDataGrid

type Props = {|
	...WithTranslateProps,
	onTagCreateClick: () => void,
	onTagEdit: (tagId: string, tag: Tag) => void,
	onTagRemove: (tag: Tag) => void,
	tagType: TagType,
	gridId: DataGridId,
|}

class TagsGrid extends Component<Props> {
	actions: Array<DataGridAction<Tag>> = [
		{
			name: this.props.t('grid.action.edit'),
			type: 'edit',
			primary: true,
			icon: <Edit />,
		},
		{
			name: this.props.t('grid.action.delete'),
			type: 'remove',
			primary: true,
			icon: <Delete />,
		},
	]

	onAction = async (action: string, tag: Tag) => {
		switch (action) {
			case 'edit': {
				tag.id && this.props.onTagEdit(tag.id, tag)
				break
			}
			case 'remove': {
				DeleteDialog()
					.then(() => {
						tag && this.props.onTagRemove(tag)
					})
					.catch(() => {})
				break
			}
		}
	}

	renderColumn = (value: string) => {
		return (
			<div css={styles.column}>
				<FullText text={value} />
			</div>
		)
	}

	render() {
		const defaultFilter = [
			{
				field: 'type',
				value: TagType_TagTypeNumber(this.props.tagType),
			},
		]

		return (
			<div>
				<TagsAdvancedFilter
					onTagCreateClick={this.props.onTagCreateClick}
					tagType={this.props.tagType}
					gridId={this.props.gridId}
					defaultFilter={defaultFilter}
				/>
				<TagsDataGrid
					light
					compactPager
					showPageSize={false}
					showItemsOfTotal={false}
					id={this.props.gridId}
					dataType="tags"
					filterId={this.props.gridId}
					actions={this.actions}
					onAction={this.onAction}
					defaultFilter={defaultFilter}
				>
					<Column
						title={this.props.t('settings.reporting.name')}
						render={this.renderColumn}
						width="70%"
						columnId="name"
						key="name"
						sortable
						defaultSortDirection="ASC"
					/>
					<Column
						title={this.props.t('settings.reporting.code')}
						render={this.renderColumn}
						width="30%"
						columnId="code"
						key="code"
						sortable
					/>
				</TagsDataGrid>
			</div>
		)
	}
}

const styles = {
	column: {
		paddingRight: 8,
	},
}

export default withTranslate(TagsGrid)
