/* @flow */
/** @jsx jsx */

import React from 'react'
import { jsx } from '@emotion/core'
import type { UserProfileSettingsNotifications } from 'types'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import NotificationToggle from './notification-toggle'
import { colors } from 'variables'

type Props = {|
	...WithTranslateProps,
	notifications?: UserProfileSettingsNotifications,
	onChange: (notifications: UserProfileSettingsNotifications) => void,
|}

function NotificationsSettings(props: Props) {
	const { t, notifications, onChange } = props

	return (
		<React.Fragment>
			<div css={style.row}>
				<div css={style.info} />
				<div css={style.check}>{props.t('user.settings.email')}</div>
				<div css={style.check}>{props.t('user.settings.push')}</div>
			</div>

			<div css={style.row}>
				<div css={style.info}>
					<div css={style.title}>{t('user.settings.immediate.title')}</div>
					<div css={style.subtitle}>{t('user.settings.immediate.subTitle')}</div>
				</div>
				<div css={style.check}>
					<NotificationToggle
						type="email"
						categories={['taxSubmission', 'vatSubmission']}
						notifications={notifications}
						onChange={onChange}
					/>
				</div>
				<div css={style.check}>
					<NotificationToggle
						type="push"
						categories={['taxSubmission', 'vatSubmission']}
						notifications={notifications}
						onChange={onChange}
					/>
				</div>
			</div>

			<div css={style.row}>
				<div css={style.info}>
					<div css={style.title}>{t('user.settings.dailyDigest.title')}</div>
					<div css={style.subtitle}>{t('user.settings.dailyDigest.subTitle')}</div>
				</div>
				<div css={style.check}>
					<NotificationToggle
						type="email"
						categories={['dailyDigest']}
						notifications={notifications}
						onChange={onChange}
					/>
				</div>
				<div css={style.check}>
					<NotificationToggle
						type="push"
						categories={['dailyDigest']}
						notifications={notifications}
						onChange={onChange}
					/>
				</div>
			</div>
		</React.Fragment>
	)
}

const style = {
	row: {
		paddingTop: 15,
		paddingBottom: 15,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	info: {
		flex: '0 0 60%',
	},
	check: {
		flex: '0 0 20%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		textAlign: 'center',
		padding: 10,
	},
	title: {
		fontSize: 16,
		fontWeight: 'bold',
		color: colors.black,
		marginRight: 10,
		lineHeight: '20px',
		overflow: 'hidden',
		marginBottom: 5,
	},
	subtitle: {
		fontSize: 14,
		lineHeight: '17px',
		overflow: 'hidden',
		color: colors.grey,
	},
}

export default withTranslate(NotificationsSettings)
