/* @flow */
import Header from '../components/header'
import { connect } from 'react-redux'
import { userHasAccess } from 'permissions'
import type { Organization, OrganizationSettingsOpen, State } from 'types'
import { getOrganizationSettingsOpen } from '../selectors'

type StateProps = {|
	showInvitationBanner: boolean,
	organizationSettings: ?OrganizationSettingsOpen,
	organization: ?Organization,
|}

const mapStateToProps = (state: State): StateProps => {
	return {
		showInvitationBanner: userHasAccess(state, 'seeNonDemoFeatures'),
		organizationSettings: getOrganizationSettingsOpen(state),
		organization: state.organization.organizationDetail.data,
	}
}

export default connect(mapStateToProps)(Header)
