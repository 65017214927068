// @flow

import { emptyTemplate } from 'types/empty'
import type { Template, PagerProps, PagerPropsNullable, FormFieldErrorsMap } from 'types'
import { getTemplateDuplicateId } from 'types/operations'
import type { Action as TemplatesAction } from '../actions/templates-action-types'
import { NEW_TEMPLATE_NAME } from 'trivi-constants'
import { getPagerIndexes } from 'utils'

export type TemplateItem = {|
	template: ?Template,
	templateLoading: boolean,
	templateUpdating: boolean,
	errors?: ?FormFieldErrorsMap,
|}

export type State = {
	templateList: Array<?TemplateItem>,
	creatingTemplates: { [id: number]: Template },
	duplicatingTemplates: { [id: number]: Template }, //Template is original template
	creatingDrafts: { [id: number]: string }, //string is the templateId
	pagerProps: PagerProps,
	lastCreatedTemplate: ?Template,
	currentTemplate: ?Template,
}

export const initialState: State = {
	templateList: [],
	creatingTemplates: {},
	duplicatingTemplates: {},
	creatingDrafts: {},
	lastCreatedTemplate: null,
	pagerProps: {
		currentPage: 1,
		pageSize: 1,
		pagesCount: 1,
	},
	currentTemplate: null,
}

const emptyTemplateItem = (template?: Template): TemplateItem => {
	return {
		template: template || null,
		templateLoading: false,
		templateUpdating: false,
	}
}

const insertTemplate = (index: number, template: Template, state: State): State => {
	let templateList: Array<?TemplateItem> = [...state.templateList]
	const newTemplateItem: TemplateItem = emptyTemplateItem(template)
	templateList.splice(index, 0, newTemplateItem)
	return { ...state, templateList }
}

const removeTemplate = (templateId: ?string, state: State): State => {
	if (!templateId) {
		console.error('templateId is not set')//eslint-disable-line
		return state
	}

	let templateList: Array<?TemplateItem> = state.templateList.slice(0)
	const indexOfRemovedTemplate: number = templateList.findIndex(
		(templateItem: ?TemplateItem) => templateItem && templateItem.template && templateItem.template.id === templateId,
	)
	if (indexOfRemovedTemplate === -1) {
		return state
	}
	templateList.splice(indexOfRemovedTemplate, 1)
	return { ...state, templateList }
}

const upsertTemplate = (templateId: ?string, template: Template, state: State): State => {
	if (!templateId) {
		console.error('templateId is not set')//eslint-disable-line
		return state
	}
	let templateList: Array<?TemplateItem> = [...state.templateList]
	const existingIndex: number = templateList.findIndex((item: ?TemplateItem) => {
		return item && item.template && item.template.id === templateId
	})
	if (existingIndex > -1) {
		templateList[existingIndex] && (templateList[existingIndex].template = template)
	} else {
		const newTemplateItem: TemplateItem = emptyTemplateItem(template)
		templateList.push(newTemplateItem)
	}
	return { ...state, templateList }
}

const setTemplateLoading = (templateId: ?string, templateLoading: boolean, state: State): State => {
	if (!templateId) {
		console.error('templateId is not set')//eslint-disable-line
		return state
	}
	const templateList: Array<?TemplateItem> = state.templateList.map((templateItem: ?TemplateItem) => {
		if (templateItem && templateItem.template && templateItem.template.id === templateId) {
			return Object.assign(templateItem, { templateLoading })
		} else {
			return templateItem
		}
	})
	return { ...state, templateList }
}

const setTemplateErros = (
	state: State,
	templateId: ?string,
	errors: 'delete' | FormFieldErrorsMap,
	templateUpdating: boolean = false,
): State => {
	if (!templateId) return state
	const templateList: Array<?TemplateItem> = state.templateList.map((templateItem: ?TemplateItem) => {
		if (templateItem && templateItem.template && templateItem.template.id === templateId) {
			const newTemplate = { ...templateItem, templateUpdating }

			if (errors === 'delete') {
				delete newTemplate.errors
			} else if (errors != null) {
				newTemplate.errors = errors
			}

			return newTemplate
		} else {
			return templateItem
		}
	})
	return { ...state, templateList }
}

const removeError = (state: State, templateId: ?string, errorUniqueId: string): State => {
	if (!templateId) return state
	const templateList: Array<?TemplateItem> = state.templateList.map((templateItem: ?TemplateItem) => {
		if (templateItem && templateItem.template && templateItem.template.id === templateId) {
			const newTemplate = { ...templateItem }
			if (newTemplate.errors && newTemplate.errors[errorUniqueId]) delete newTemplate.errors[errorUniqueId]
			return newTemplate
		} else {
			return templateItem
		}
	})
	return { ...state, templateList }
}

const setTemplateUpdating = (templateId: ?string, templateUpdating: boolean, state: State): State => {
	if (!templateId) {
		console.error('templateId is not set')//eslint-disable-line
		return state
	}
	const templateList: Array<?TemplateItem> = state.templateList.map((templateItem: ?TemplateItem) => {
		if (templateItem && templateItem.template && templateItem.template.id === templateId) {
			return Object.assign(templateItem, { templateUpdating })
		} else {
			return templateItem
		}
	})
	return { ...state, templateList, lastCreatedTemplate: null }
}

const setTemplateListTemplates = (
	templates: Array<Template>,
	currentPage: number,
	pageSize: number,
	state: State,
): State => {
	let templateList = [...state.templateList]
	const { pagesCount } = state.pagerProps
	const { indexFrom, indexTo } = getPagerIndexes({ currentPage, pageSize, pagesCount })

	let dataIndex: number = 0
	for (let i = indexFrom; i <= indexTo; i++) {
		if (templateList[i]) {
			templateList[i].template = templates[dataIndex] || null
		} else {
			templateList[i] = emptyTemplateItem(templates[dataIndex])
		}
		dataIndex++
	}
	return { ...state, templateList }
}

const setTemplateListLoading = (
	templateListLoading: boolean,
	currentPage: number,
	pageSize: number,
	state: State,
): State => {
	let templateList = [...state.templateList]
	const { pagesCount } = state.pagerProps
	const { indexFrom, indexTo } = getPagerIndexes({ currentPage, pageSize, pagesCount })

	for (let i = indexFrom; i <= indexTo; i++) {
		if (templateList[i]) {
			templateList[i].templateLoading = templateListLoading
		} else {
			let newItem: TemplateItem = emptyTemplateItem()
			newItem.templateLoading = templateListLoading
			templateList[i] = newItem
		}
	}

	return { ...state, templateList }
}

const addCreatingTemplate = (template: Template, id: number, state: State): State => {
	let creatingTemplates = { ...state.creatingTemplates }
	creatingTemplates[id] = template
	return { ...state, creatingTemplates }
}

const removeCreatingTemplate = (template: Template, id: number, state: State): State => {
	let creatingTemplates = { ...state.creatingTemplates }
	delete creatingTemplates[id]
	return { ...state, creatingTemplates }
}

const addDuplicatingTemplate = (template: Template, duplicateId: number, state: State): State => {
	let duplicatingTemplates = { ...state.duplicatingTemplates }
	duplicatingTemplates[duplicateId] = template
	return { ...state, duplicatingTemplates }
}

const removeDuplicatingTemplate = (duplicateId: number, state: State): State => {
	let duplicatingTemplates = { ...state.duplicatingTemplates }
	delete duplicatingTemplates[duplicateId]
	return { ...state, duplicatingTemplates }
}

const addCreatingDraft = (templateId: ?string, createId: number, state: State) => {
	if (!templateId) {
		console.error('templateId is not set')//eslint-disable-line
		return state
	}
	let creatingDrafts = { ...state.creatingDrafts }
	creatingDrafts[createId] = templateId
	return { ...state, creatingDrafts }
}

const removeCreatingDraft = (createId: number, state: State): State => {
	let creatingDrafts = { ...state.creatingDrafts }
	delete creatingDrafts[createId]
	return { ...state, creatingDrafts }
}

const setPagerProps = (newPagerProps: PagerPropsNullable, state: State): State => {
	let pagerProps: PagerProps = Object.assign(state.pagerProps)
	newPagerProps.currentPage && (pagerProps.currentPage = newPagerProps.currentPage)
	newPagerProps.pageSize && (pagerProps.pageSize = newPagerProps.pageSize)
	newPagerProps.pagesCount && (pagerProps.pagesCount = newPagerProps.pagesCount)
	return { ...state, pagerProps }
}

export default function templatesReducer(state: State = initialState, action: TemplatesAction): State {
	switch (action.type) {
		case 'START_LOAD_TEMPLATES': {
			return setTemplateListLoading(true, action.page, action.pageSize, state)
		}
		case 'FINISH_LOAD_TEMPLATES': {
			const NOT_LOADING: State = setTemplateListLoading(false, action.page, action.pageSize, state)
			if (action.success) {
				return setTemplateListTemplates(action.templates, action.page, action.pageSize, NOT_LOADING)
			} else {
				return NOT_LOADING
			}
		}
		case 'START_LOAD_TEMPLATE': {
			return setTemplateLoading(action.templateId, true, state)
		}
		case 'FINISH_LOAD_TEMPLATE': {
			const notLoadingState: State = setTemplateLoading(action.templateId, false, state)
			if (action.template) {
				const resState = upsertTemplate(action.templateId, action.template, notLoadingState)
				return resState
			} else {
				return notLoadingState
			}
		}
		case 'START_REMOVE_TEMPLATE': {
			return removeTemplate(action.template.id, state)
		}
		case 'FINISH_REMOVE_TEMPLATE': {
			if (action.success) {
				return removeTemplate(action.template.id, state)
			} else {
				return insertTemplate(action.index, action.template, state)
			}
		}
		case 'START_CREATE_TEMPLATE': {
			return addCreatingTemplate(action.template, action.id, state)
		}
		case 'FINISH_CREATE_TEMPLATE': {
			const name = NEW_TEMPLATE_NAME === action.confirmedTemplate?.name ? undefined : action.confirmedTemplate?.name
			return {
				...state,
				...removeCreatingTemplate(action.oldTemplate, action.id, state),
				lastCreatedTemplate: { ...action.confirmedTemplate, name },
			}
		}
		case 'START_UPDATE_TEMPLATE': {
			return setTemplateUpdating(action.newTemplate.id, true, state)
		}
		case 'FINISH_UPDATE_TEMPLATE': {
			if (action.validationError) {
				return setTemplateErros(
					state,
					action.newTemplate.id,
					action.validationError ? action.validationError : 'delete',
					false,
				)
			} else {
				const updatedTemplate: Template = (action.success && action.confirmedTemplate) || action.oldTemplate
				return upsertTemplate(
					action.newTemplate.id,
					updatedTemplate, //order is again important, upsert can change ID
					setTemplateUpdating(action.newTemplate.id, false, state),
				)
			}
		}
		case 'REMOVE_TEMPLATE_ERROR': {
			const { templateId, errorUniqueId } = action
			return removeError(state, templateId, errorUniqueId)
		}
		case 'START_DUPLICATE_TEMPLATE': {
			const duplicateTemplateId: string = getTemplateDuplicateId(action.duplicateId)
			const originalTemplateId: ?string = action.originalTemplate.id
			const originalIndex: number = state.templateList.findIndex((item: ?TemplateItem) => {
				return item && item.template && item.template.id === originalTemplateId
			})
			const duplicateTemplate: Template = Object.assign(emptyTemplate(), action.originalTemplate, {
				id: duplicateTemplateId,
				name: (action.originalTemplate.name || '') + ' copy',
			})
			return addDuplicatingTemplate(
				action.originalTemplate,
				action.duplicateId,
				insertTemplate(originalIndex + 1, duplicateTemplate, state),
			)
		}
		case 'FINISH_DUPLICATE_TEMPLATE': {
			const duplicateTemplateId: string = getTemplateDuplicateId(action.duplicateId)
			if (action.success && action.confirmedTemplate) {
				return removeDuplicatingTemplate(
					action.duplicateId,
					upsertTemplate(duplicateTemplateId, action.confirmedTemplate, state),
				)
			} else {
				return removeDuplicatingTemplate(action.duplicateId, removeTemplate(duplicateTemplateId, state))
			}
		}
		case 'START_CREATE_DRAFT': {
			return addCreatingDraft(action.template.id, action.createId, state)
		}
		case 'FINISH_CREATE_DRAFT': {
			return removeCreatingDraft(action.createId, state)
		}
		case 'SET_TEMPLATE_PAGER_PROPS': {
			return setPagerProps(action.pagerPropsNullable, state)
		}
		case 'SET_CURRENT_TEMPLATE': {
			return {
				...state,
				currentTemplate: action.currentTemplate,
			}
		}
		default:
			return state
	}
}
