/* @flow */

import React, { Component } from 'react'
import TinyColor from 'tinycolor2'
import EnhancedButton from 'components/internal/EnhancedButton'
import CheckIcon from 'components/svg-icons/trivi/check'
import { colors } from 'variables'

type Props = {|
	color: string,
	switched: boolean,
	onClick: (color: string) => void,
|}

type State = {|
	hovered: boolean,
|}

class SettingsColorSwitch extends Component<Props, State> {
	state = {
		hovered: false,
	}

	handleMouseLeave = () => {
		this.setState({
			hovered: false,
		})
	}

	handleMouseEnter = () => {
		this.setState({
			hovered: true,
		})
	}

	handleClick = () => {
		this.props.onClick(this.props.color)
	}

	render() {
		const styles = getStyles(this.props, this.state)
		const checkColor = TinyColor(this.props.color).isDark() ? colors.white : colors.black

		return (
			<EnhancedButton
				onClick={this.handleClick}
				onMouseLeave={this.handleMouseLeave}
				onMouseEnter={this.handleMouseEnter}
				style={styles.root}
			>
				<div style={styles.check}>
					<CheckIcon size={20} disabled color={this.props.switched ? checkColor : colors.transparent} />
				</div>
			</EnhancedButton>
		)
	}
}

function getStyles(props: Props, state: State) {
	return {
		root: {
			display: 'block',
			transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1)',
			borderStyle: 'solid',
			borderWidth: 4,
			borderColor: props.switched ? colors.black : colors.grey200,
			overflow: 'hidden',
			width: 35,
			height: 35,
			borderRadius: '50%',
			background: props.color,
		},
		check: {
			width: 27,
			height: 27,
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			backgroundColor: state.hovered ? colors.whiteFaded20 : colors.transparent,
			transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1)',
		},
	}
}

export default SettingsColorSwitch
