// @flow
import type { ISignalMessageGroup, ISignalMessageParams } from 'types'
import { showAccountingDocumentRoute } from 'modules/accounting-document/routing/routes'
import { cashRegisterRoute } from 'modules/cash-register/routing/routes'
import { bankAccountRoute } from 'modules/bank/routing/routes'
import { taskRoute } from 'modules/task/routing/routes'
import { dashboardRoute } from 'modules/dashboard/routing/routes'

export function getNotificationRoute(notificationGroup: ISignalMessageGroup, params: ISignalMessageParams): ?string {
	switch (notificationGroup) {
		case 'AccountingDocument':
			return params.id && showAccountingDocumentRoute(params.id)
		case 'CashRegister':
			return params.id && cashRegisterRoute(params.id)
		case 'BankAccount':
			return params.id && bankAccountRoute(params.id)
		case 'Task':
			return params.id && taskRoute(params.id)
		case 'Organization':
			return params.id && dashboardRoute(params.id)
	}
}
