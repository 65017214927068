import React from 'react'
import pure from 'recompose/pure'
import SvgIcon from 'components/SvgIcon'

let Dashboard = props => (
	<SvgIcon {...props}>
		<g id="Group-2" transform="translate(1.000000, 4.000000)">
			<path d="M2.35087593,14.1096996 L19.5127146,14.1096996 C19.893121,13.0893572 20.0859906,12.0219084 20.0859906,10.9313509 C20.0859906,5.88385582 15.9788459,1.77759995 10.9313509,1.77759995 C5.88385582,1.77759995 1.77759995,5.88385582 1.77759995,10.9313509 C1.77759995,12.0219084 1.97046954,13.0884684 2.35087593,14.1096996 L2.35087593,14.1096996 Z M20.6868194,15.8872995 L1.17588236,15.8872995 L0.942127971,15.3593523 C0.31641279,13.9506044 0,12.4609756 0,10.9313509 C0,4.90439825 4.90439825,0 10.9313509,0 C16.9591923,0 21.8635905,4.90439825 21.8635905,10.9313509 C21.8635905,12.4618644 21.5462889,13.9514932 20.9214626,15.3593523 L20.6868194,15.8872995 Z" />
			<polygon opacity="0.6" points="9.64312418 15.7909536 6.73852587 6.00704349 12.8259169 14.2062232" />
		</g>
	</SvgIcon>
)
Dashboard = pure(Dashboard)

export default Dashboard
