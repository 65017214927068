/* @flow */

import React, { Component } from 'react'
import { isBetweenDates, isEqualDate } from './date-utils'
import DayButton from './day-button'

export type Props = {|
	DateTimeFormat: (string, Object) => ?string,
	displayDate: ?Date,
	locale: string,
	maxDate: Date,
	minDate: Date,
	selectedDate: ?Date,
	utils: Object,
	onClickDay?: Date => void,
|}

const styles = {
	root: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
		fontWeight: 400,
		height: 150,
		lineHeight: 2,
		position: 'relative',
		textAlign: 'center',
		MozPaddingStart: 0,
		padding: '0 11px',
	},
	week: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		height: 24,
		marginBottom: 3,
		marginTop: 3,
	},
	firstWeek: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		height: 24,
		marginBottom: 3,
		marginTop: 3,
	},
}

class CalendarMonth extends Component<Props> {
	selectedDateDisabled: ?boolean = null

	isSelectedDateDisabled = () => {
		return this.selectedDateDisabled
	}

	handleClickDay = (date: Date) => {
		this.props.onClickDay && this.props.onClickDay(date)
	}

	shouldDisableDate(day: ?Date): boolean {
		if (!day) return false
		let disabled =
			!this.props.minDate || !this.props.maxDate || !isBetweenDates(day, this.props.minDate, this.props.maxDate)

		return disabled
	}

	getWeekElements(): Array<React$Element<any>> {
		const weekArray: Array<Array<?Date>> = this.props.utils.getWeekArray(this.props.displayDate)

		return weekArray.map((week: Array<?Date>, i: number) => {
			return (
				<div key={i} style={styles.week}>
					{this.getDayElements(week, i)}
				</div>
			)
		}, this)
	}

	getDayElements(week: Array<?Date>, i: number): Array<React$Element<any>> {
		const { DateTimeFormat, locale, selectedDate } = this.props

		return week.map((day: ?Date, j: number) => {
			const isSameDate = isEqualDate(selectedDate, day)
			const disabled = this.shouldDisableDate(day)
			const selected = !disabled && !!isSameDate

			if (isSameDate) this.selectedDateDisabled = disabled

			return (
				<DayButton
					DateTimeFormat={DateTimeFormat}
					locale={locale}
					date={day}
					disabled={disabled}
					key={`db${i + j}`}
					onClick={this.handleClickDay}
					selected={selected}
				/>
			)
		}, this)
	}

	render() {
		return <div style={styles.root}>{this.getWeekElements()}</div>
	}
}

export default CalendarMonth
