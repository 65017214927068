/* @flow */

import moment from 'moment'
import type { DashboardAction, CardResponse } from 'types'
import { findMinimalDate, findMaximalDate } from '../domain/cards'
import { getHistoryCardsByDeadlineDatePure } from '../selectors/cards'
import { EMPTY_OBJECT } from 'trivi-constants'

export type State = {
	data: { [string]: CardResponse },
	loadedToDate: Date,
	loadedSinceDate: Date,
	allFutureCardsLoaded: boolean,
	allHistoryCardsLoaded: boolean,
	loading: boolean,
}

export const initialState: State = {
	data: EMPTY_OBJECT,
	loading: true,
	loadedToDate: new Date(),
	loadedSinceDate: new Date(),
	allFutureCardsLoaded: false,
	allHistoryCardsLoaded: false,
}

export default (state: State = initialState, action: DashboardAction): State => {
	switch (action.type) {
		case 'DELETE_CURRENT_ORGANIZATION_CARDS':
			return deleteCurrentOrganizationCardsReducer(state)
		case 'START_LOAD_ORGANIZATION_CARDS':
			return startLoadOrganizationCardsReducer(state)
		case 'FINISH_LOAD_ORGANIZATION_CARDS':
			return action.serverError
				? state
				: finishLoadOrganizationCardsReducer(
						state,
						action.cards,
						action.numberOfDatesToFuture,
						action.numberOfDatesToPast,
						action.startDate,
				  )
		case 'START_COMPLETE_ORGANIZATION_CARD':
			return {
				...state,
				data: {
					...state.data,
					[action.cardId]: {
						...(state.data ? state.data[action.cardId] : {}),
						completion: {
							...(state.data ? state.data[action.cardId].completion || {} : {}),
							isCompleted: action.isCompleted,
						},
					},
				},
			}
		case 'FINISH_COMPLETE_ORGANIZATION_CARD':
			return {
				...state,
				data: {
					...state.data,
					[action.cardId]: {
						...(state.data ? state.data[action.cardId] : {}),
						completion: action.completion,
					},
				},
			}

		default:
			return state
	}
}

function deleteCurrentOrganizationCardsReducer(state: State): State {
	return {
		...state,
		data: EMPTY_OBJECT,
		loading: true,
	}
}

function startLoadOrganizationCardsReducer(state: State): State {
	return {
		...state,
		loading: true,
	}
}

function finishLoadOrganizationCardsReducer(
	state: State,
	data: ?Array<CardResponse>,
	numberOfDatesToFuture?: number = 0,
	numberOfDatesToPast?: number = 0,
	startDate?: string,
): State {
	if (!data)
		return {
			...state,
			loading: false,
		}

	const loadedToDate = new Date(Math.max(state.loadedToDate, findMaximalDate(data)))
	const loadedSinceDate = new Date(Math.min(state.loadedSinceDate, findMinimalDate(data)))

	const cardsById = {}
	data.forEach((card: CardResponse) => {
		cardsById[card.id || ''] = card
	})

	const notCurrentCards = data.filter((card: CardResponse) => !card.isCurrent)

	const date = moment(startDate)
		.startOf('day')
		.toISOString()

	const historyCards = getHistoryCardsByDeadlineDatePure(data, date)

	return {
		...state,
		loading: false,
		allFutureCardsLoaded: numberOfDatesToFuture
			? notCurrentCards.length < numberOfDatesToFuture ||
			  loadedToDate.toISOString() === state.loadedToDate.toISOString()
			: state.allFutureCardsLoaded,
		allHistoryCardsLoaded: numberOfDatesToPast
			? historyCards.length < numberOfDatesToPast ||
			  loadedSinceDate.toISOString() === state.loadedSinceDate.toISOString()
			: state.allHistoryCardsLoaded,
		loadedToDate,
		loadedSinceDate,
		data: {
			...state.data,
			...cardsById,
		},
	}
}
