/* @flow */

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { userHasAccess } from 'permissions'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import type { WithRouterProps, State, Organization, OrganizationSettingsTaxPaymentHistory } from 'types'
import {
	settingsAccountingDocumentsRoute,
	settingsAccountingSettingsRoute,
	settingsFinancialAccountsRoute,
	settingsSequencesRoute,
} from 'modules/settings/routing/routes'
import AccountingSettings from 'modules/settings/containers/accounting/accounting-settings'
import AccountingDocuments from 'modules/settings/components/accounting/accounting-documents'
import FinancialAccounts from 'modules/settings/components/accounting/financial-accounts'
import SettingsSection from 'modules/settings/components/settings-section'
import Sequences from 'modules/settings/containers/sequences'
import { getCurrentOrganization } from 'modules/organization/selectors'
import { currentUserIsInternal } from 'modules/user/selectors'
import { getTaxPaymentHistory } from 'modules/settings/selectors'
import { loadTaxPaymentHistory } from 'modules/settings/actions'
import type { SubSectionType } from './settings-layout'

type StateProps = {|
	currentUserIsInternal: boolean,
	organization: ?Organization,
	canSetInternalOrganizationSettings: boolean,
	taxPaymentHistory: ?Array<OrganizationSettingsTaxPaymentHistory>,
|}

const mapStateToProps = (state: State): StateProps => {
	return {
		currentUserIsInternal: currentUserIsInternal(state),
		organization: getCurrentOrganization(state),
		canSetInternalOrganizationSettings: userHasAccess(state, 'setInternalOrganizationSettings'),
		taxPaymentHistory: getTaxPaymentHistory(state),
	}
}

type DispatchProps = {|
	loadTaxPaymentHistory: () => void,
|}

const mapDispatchToProps = (dispatch: Dispatch<>): DispatchProps => {
	return {
		loadTaxPaymentHistory: () => {
			dispatch(loadTaxPaymentHistory())
		},
	}
}

export type Props = {|
	subSection?: SubSectionType,
|}

type ComponentProps = Props & WithTranslateProps & WithRouterProps & StateProps

class CompanyPage extends Component<ComponentProps> {
	getToUrl = (url: string) => () => {
		this.props.history.push(url)
	}

	render() {
		const { t } = this.props

		return (
			<div>
				<SettingsSection
					visible={this.props.subSection === 'accountingSettings'}
					title={t('settings.accountingSettings.title')}
					subTitle={t('settings.accountingSettings.subTitle')}
					onToggle={
						this.props.subSection !== 'accountingSettings'
							? this.getToUrl(settingsAccountingSettingsRoute())
							: undefined
					}
				>
					<AccountingSettings
						currentUserIsInternal={this.props.currentUserIsInternal}
						canSetInternalOrganizationSettings={this.props.canSetInternalOrganizationSettings}
					/>
				</SettingsSection>

				<SettingsSection
					visible={this.props.subSection === 'sequences'}
					title={t('settings.sequences.title')}
					subTitle={t('settings.sequences.subTitle')}
					onToggle={this.props.subSection !== 'sequences' ? this.getToUrl(settingsSequencesRoute()) : undefined}
				>
					<Sequences />
				</SettingsSection>

				<SettingsSection
					visible={this.props.subSection === 'financialAccounts'}
					title={t('settings.financialAccounts.title')}
					subTitle={t('settings.financialAccounts.subTitle')}
					onToggle={
						this.props.subSection !== 'financialAccounts' ? this.getToUrl(settingsFinancialAccountsRoute()) : undefined
					}
				>
					<FinancialAccounts />
				</SettingsSection>

				<SettingsSection
					visible={this.props.subSection === 'accountingDocuments'}
					title={t('settings.accountingDocuments.title')}
					subTitle={t('settings.accountingDocuments.subTitle')}
					onToggle={
						this.props.subSection !== 'accountingDocuments'
							? this.getToUrl(settingsAccountingDocumentsRoute())
							: undefined
					}
				>
					<AccountingDocuments currentUserIsInternal={this.props.currentUserIsInternal} />
				</SettingsSection>
			</div>
		)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslate(CompanyPage)))
