// @flow

import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import type { Import, ImportEntity, WithRouterProps } from 'types'
import {
	uploadScanRoute,
	accountingDocumentsRoute,
	showAccountingDocumentRoute,
} from 'modules/accounting-document/routing/routes'
import { Paper, Separator, Avatar, Loader, LinearProgress, Button, Chip, TriviLink } from 'components'
import CheckIcon from 'components/svg-icons/navigation/check'
import CloseIcon from 'components/svg-icons/navigation/close'
import styles from './multi-import-detail.css'
import { colors } from 'variables'

import { type WithTranslateProps, withTranslate } from 'wrappers'
import Clock from './clock'

export const ENTITY_NOT_PROCESSED_STATE: number = 1

export const ENTITY_PROCESSING_STATE: number = 2

export const ENTITY_DONE_STATE: number = 3

export const ENTITY_ERROR_STATE: number = 4

export const PROCESSING_DELAY_STATE: number = 5

type ComponentState = {|
	importDetail: ?Import,
	importError?: any, // TODO: Remove this (testing)
	percent: number,
|}

type ComponentProps = WithTranslateProps &
	WithRouterProps & {|
		importId: string,
		importDetail: ?Import,
		loadImport: () => void,
	|}

class MultipleImportDetail extends Component<ComponentProps, ComponentState> {
	state: ComponentState = {
		importDetail: null,
		percent: 0,
		importError: null,
	}

	interval: ?IntervalID = null

	UNSAFE_componentWillMount() {
		this.loadImport() //initiate loading loop
	}

	UNSAFE_componentWillReceiveProps(nextProps: ComponentProps) {
		const percent: number = getPercentDone(nextProps.importDetail)
		this.setState({
			percent,
			importDetail: nextProps.importDetail,
		})

		if (nextProps.importDetail && nextProps.importDetail.processingState === PROCESSING_DELAY_STATE) {
			this.setState({
				importError: this.props.t('accountingDocument.multiImport.importDelayed'),
			})
		}

		if (percent === 100 && this.interval) {
			clearInterval(this.interval)
		}
	}

	componentWillUnmount() {
		this.interval && clearInterval(this.interval)
	}

	loadImport = () => {
		this.props.loadImport()
		this.interval && clearInterval(this.interval)
		this.interval = setInterval(this.loadImport, 5 * 1000) // every 5 seconds
	}

	onManualImportClick = () => {
		this.props.history.push(uploadScanRoute())
	}

	onAccountingDocumentListButtonClick = () => {
		this.props.history.push(accountingDocumentsRoute())
	}

	renderEntities(entities: Array<ImportEntity>) {
		const { t } = this.props

		return (
			<div className={styles.entries}>
				{entities.map((entity: ImportEntity, index: number) => {
					const isError: boolean = entity.processingState === 4

					return (
						<div className={styles.entry} key={index}>
							<div className={styles.chip}>
								<Chip color={isError ? 'red' : 'green'}>
									{isError ? t('accountingDocument.multiImport.error') : t('accountingDocument.multiImport.info')}
								</Chip>
							</div>
							<div className={styles.info}>
								{isError ? (
									entity.processingMessage
								) : entity.triviId ? (
									<TriviLink routeFn={showAccountingDocumentRoute} routeParams={[entity.triviId.toString()]}>
										{entity.triviName}
									</TriviLink>
								) : (
									entity.triviName
								)}
							</div>
						</div>
					)
				})}
			</div>
		)
	}

	render() {
		const { t } = this.props
		const { percent, importDetail, importError } = this.state

		if (!importDetail) {
			return <Loader visible /> //TODO: REMOVE
		}

		const errorEntities: Array<ImportEntity> = getEntities(importDetail, ENTITY_ERROR_STATE)
		const doneEntities: Array<ImportEntity> = getEntities(importDetail, ENTITY_DONE_STATE)

		const { createdDate } = importDetail
		return (
			<div className={styles.root}>
				<div className={styles.header}>
					<div className={styles.visual} />
					<div className={styles.info}>
						<h2 className={styles.infoHeadline}>
							{importError
								? t('accountingDocument.multiImport.errorTitle')
								: t('accountingDocument.multiImport.processingTitle')}
						</h2>
						<div className={styles.infoText}>
							{importError
								? t('accountingDocument.multiImport.errorSubTitle')
								: t('accountingDocument.multiImport.processingSubTitle')}
						</div>

						{/* Hidden for now according to TWU-1904
						!importError && percent !== 100 && <div className={styles.percent}>{percent} %</div>
						*/}

						{importError || percent === 100 ? (
							<div className={styles.done}>
								<Button
									secondary
									labelText={t('accountingDocument.multiImport.accountingDocumentListButton')}
									onClick={this.onAccountingDocumentListButtonClick}
									autoTestId="multi-import-list-button"
								/>
								{!importError && (
									<span className={styles.doneText}>
										{t('accountingDocument.multiImport.overview', { count: doneEntities.length })}
									</span>
								)}
							</div>
						) : null}

						<div className={styles.progress}>
							<LinearProgress
								mode="determinate"
								className={styles.progressBar}
								backgroundColor={colors.grey300}
								color={importError ? colors.red : colors.blue}
								outlineColor={'transparent'}
								min={0}
								max={100}
								value={importError ? 100 : percent}
								rounded
								animated
								tall
							/>
							<Avatar
								className={styles.avatar}
								size={42}
								backgroundColor={importError ? colors.red : percent === 100 ? colors.blue : colors.grey300}
							>
								{importError ? (
									<CloseIcon color={colors.white} hoverColor={colors.white} />
								) : (
									<CheckIcon color={colors.white} hoverColor={colors.white} />
								)}
							</Avatar>
						</div>

						<div className={styles.elapsed}>
							{createdDate && percent !== 100 && !importError && (
								<span>
									{t('accountingDocument.multiImport.passedTime')}: <Clock timeZero={new Date(createdDate + 'Z')} />
								</span>
							)}
						</div>
					</div>
				</div>

				<Paper rounded zDepth={7} className={styles.body}>
					{importError ? (
						<div>
							<div className={styles.row}>
								<h4 className={styles.h4}>{t('accountingDocument.multiImport.errorListTitle')}</h4>
							</div>
							<div className={styles.entries}>{importError}</div>
						</div>
					) : (
						<div>
							<div className={styles.row}>
								<div className={styles.column}>
									<h4 className={styles.h4}>{t('accountingDocument.multiImport.successfullImports')}</h4>
									{t('accountingDocument.multiImport.successCountTitle')} {doneEntities.length}
								</div>
								<div className={styles.column}>
									<h4 className={styles.h4}>{t('accountingDocument.multiImport.unsuccessfullImports')}</h4>
									<div className={styles.errors}>
										{t('accountingDocument.multiImport.errorCountTitle')}
										<div style={{ marginTop: -1, marginLeft: 5 }}>
											<Chip color="red">{errorEntities.length.toString()}</Chip>
										</div>
									</div>
									<div className={styles.sum}>
										{t('accountingDocument.multiImport.amount', {
											count: importDetail.affectedEntities ? importDetail.affectedEntities.length : 0,
										})}
									</div>
									{percent === 100 && errorEntities.length > 0 && (
										<div className={styles.manual}>
											<Button
												tertiary
												labelText={t('accountingDocument.multiImport.manualUpload')}
												autoTestId="multi-import-manual-upload"
												onClick={this.onManualImportClick}
											/>
										</div>
									)}
								</div>
							</div>
							<Separator className={styles.separator} />
							<div className={styles.row}>
								<div className={styles.column}>{this.renderEntities(doneEntities)}</div>
								<div className={styles.column}>{this.renderEntities(errorEntities)}</div>
							</div>
						</div>
					)}
				</Paper>
			</div>
		)
	}
}

export function getEntities(importDetail: ?Import, state: number): Array<ImportEntity> {
	const { affectedEntities } = importDetail || {}
	return affectedEntities && importDetail
		? affectedEntities.filter((entry: ImportEntity) => entry.processingState === state)
		: []
}

export function getPercentDone(importDetail: ?Import): number {
	// Backend does not work as expected now. This is simplified solution. Shows only 0% or 100%¨
	if (
		!importDetail ||
		importDetail.processingState === ENTITY_NOT_PROCESSED_STATE ||
		importDetail.processingState === ENTITY_PROCESSING_STATE
	) {
		return 0
	}

	return 100

	/*
	const { affectedEntities } = importDetail || {}
	if (!importDetail || !affectedEntities || affectedEntities.length === 0) {
		return 100
	}

	return Math.round(
		100 *
			((getEntities(importDetail, ENTITY_DONE_STATE).length + getEntities(importDetail, ENTITY_ERROR_STATE).length) /
				affectedEntities.length),import ProcessingState from '../../../../components/processing-state/processing-state-container';

	)
	*/
}

export default withTranslate(withRouter(MultipleImportDetail))
