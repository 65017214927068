/* @flow */

import type {
	CommonAction,
	CompanyType,
	Country,
	Currency,
	Enum,
	ValidationError,
	CrpdphSearchResult,
	AccountingDocument_IssuedPaymentOrders,
	NotificationType,
} from 'types'
import { modifierKeys } from '../constants/hotkeys'

// State nezávislý na organizationId! Sem nepatří nic, co by se měnilo se změnou organizace
export type State = {
	serverError: ?ValidationError,
	validationError: ?{
		type: string,
		data: ValidationError,
	},
	notification: ?NotificationType,
	naceCodes: ?Enum,
	taxOffices: ?Enum,
	legalForms: ?Enum,
	companyTypes: ?Array<CompanyType>,
	currencies: {
		loading: boolean,
		data: ?Array<Currency>,
	},
	paymentTypes: {
		loading: boolean,
		data: ?Enum,
	},
	countries: {
		loading: boolean,
		countries: ?Array<Country>,
	},
	countryVariant: {
		cz: {
			crpdphResult: {
				[companyVATRegNo: string]: ?CrpdphSearchResult,
			},
		},
	},
	hotkeys: {
		modifierPressed: {
			alt: boolean,
			shift: boolean,
			ctrl: boolean,
			meta: boolean,
		},
	},
	selectedDocumentsIdsInPaymentDialog: Array<string>,
	loadedPaymentOrdersForDocuments: AccountingDocument_IssuedPaymentOrders,
}

export const initialState: State = {
	serverError: null,
	validationError: null,
	notification: null,
	companyTypes: null,
	naceCodes: null,
	taxOffices: null,
	legalForms: null,
	currencies: {
		loading: false,
		data: null,
	},
	paymentTypes: {
		loading: false,
		data: null,
	},
	countries: {
		loading: false,
		loaded: false,
		countries: null,
	},
	countryVariant: {
		cz: {
			crpdphResult: {},
		},
	},
	hotkeys: {
		modifierPressed: {
			alt: false,
			shift: false,
			ctrl: false,
			meta: false,
		},
	},
	selectedDocumentsIdsInPaymentDialog: [],
	loadedPaymentOrdersForDocuments: {},
}

// General
const common = (state: State = initialState, action: CommonAction): State => {
	if (action.serverError) {
		state = { ...state, serverError: action.serverError }

		if (action.serverError instanceof Error) {
			if (process.env.NODE_ENV !== 'production') {
				console.error( //eslint-disable-line
					'The client error was incorrectly catched in redux action. Try/Catch in redux action must be used only for catching server errors.',
				)
				console.error(action.serverError) //eslint-disable-line
			}

			window.Raven && window.Raven.captureException(action.serverError)
		}
	}

	if (action.validationError) {
		state = {
			...state,
			validationError: {
				type: action.type,
				data: action.validationError,
			},
		}
	} else if (state.validationError && state.validationError.type === action.type) {
		state = {
			...state,
			validationError: null,
		}
	}

	switch (action.type) {
		case 'START_LOADING_PAYMENT_ORDERS':
			return {
				...state,
				loadedPaymentOrdersForDocuments: {
					...state.loadedPaymentOrdersForDocuments,
					[action.accDocId]: {
						paymentOrders: [],
						loading: true,
					},
				},
			}

		case 'FINISH_LOADING_PAYMENT_ORDERS':
			if (action.serverError) {
				return {
					...state,
					loadedPaymentOrdersForDocuments: {
						...state.loadedPaymentOrdersForDocuments,
						[action.accDocId]: {
							paymentOrders: [],
							loading: false,
						},
					},
				}
			}
			return {
				...state,
				loadedPaymentOrdersForDocuments: {
					...state.loadedPaymentOrdersForDocuments,
					[action.accDocId]: {
						paymentOrders: action.payload,
						loading: true,
					},
				},
			}
		case 'UPDATE_SELECTED_DOCUMENTS_IDS':
			return {
				...state,
				selectedDocumentsIdsInPaymentDialog: action.payload,
			}
		case 'CLEAR_SERVER_ERROR':
			return { ...state, serverError: null }

		case 'CLEAR_VALIDATION_ERROR':
			return { ...state, validationError: null }

		case 'SET_NOTIFICATION':
			return {
				...state,
				notification: action.notification,
			}

		case 'CLEAR_NOTIFICATION':
			return { ...state, notification: null }

		case 'START_LOAD_CURRENCIES':
			return {
				...state,
				currencies: {
					...state.currencies,
					loading: true,
				},
			}

		case 'FINISH_LOAD_CURRENCIES': {
			const currencies = {
				loading: false,
				data: action.currencies || [],
			}
			return {
				...state,
				currencies: currencies,
			}
		}

		case 'START_LOAD_PAYMENT_TYPES':
			return {
				...state,
				paymentTypes: {
					...state.paymentTypes,
					loading: true,
				},
			}

		case 'FINISH_LOAD_PAYMENT_TYPES':
			return {
				...state,
				paymentTypes: {
					...state.paymentTypes,
					loading: false,
					data: action.paymentTypes,
				},
			}

		case 'START_LOAD_COUNTRIES': {
			return {
				...state,
				countries: {
					loading: true,
					countries: state.countries.countries,
				},
			}
		}

		case 'FINISH_LOAD_COUNTRIES': {
			return {
				...state,
				countries: {
					loading: false,
					countries: action.countries,
				},
			}
		}

		case 'FINISH_LOAD_NACE_CODES': {
			return {
				...state,
				naceCodes: action.naceCodes,
			}
		}

		case 'FINISH_LOAD_TAX_OFFICES': {
			return {
				...state,
				taxOffices: action.taxOffices,
			}
		}

		case 'FINISH_LOAD_LEGAL_FORMS': {
			return {
				...state,
				legalForms: action.legalForms,
			}
		}

		case 'FINISH_LOADING_COMPANY_TYPES': {
			if (!action.serverError) {
				return {
					...state,
					companyTypes: action.companyTypes,
				}
			}
			return state
		}

		case 'FINISH_SEARCH_CONTACT_IN_CRPDPH_DB': {
			return {
				...state,
				countryVariant: {
					...state.countryVariant,
					cz: {
						...state.countryVariant.cz,
						crpdphResult: {
							...state.countryVariant.cz.crpdphResult,
							[action.companyVATRegNo]: action.response || null,
						},
					},
				},
			}
		}

		case 'HOTKEY_EVENT':
			return hotkeyEventReducer(state, action.key, action.event)

		case 'FINISH_SEARCH_TASKS': {
			if (action.validationError) {
				return {
					...state,
					serverError: action.validationError,
				}
			}
			return state
		}
		default:
			return state
	}
}

export default common

function hotkeyEventReducer(state: State, key: string, event: SyntheticKeyboardEvent<HTMLElement>): State {
	if (-1 !== modifierKeys.indexOf(key)) {
		return {
			...state,
			hotkeys: {
				...state.hotkeys,
				modifierPressed: {
					...state.hotkeys.modifierPressed,
					[key]: 'keydown' === event.type,
				},
			},
		}
	}

	return state
}
