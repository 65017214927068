// @flow
import { Component } from 'react'
import { connect } from 'react-redux'
import type { State, Dispatch } from 'types'
import confirmDialog from 'components/confirm-dialog'
import { logout, authenticate, switchSplashScreen } from 'modules/user/actions'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import { navigateToMainScreen } from 'modules/navigation/actions'
import { getAccessToken } from 'utils/local-storage'

declare var DEMO_CREDENTIALS: {
	email: string,
	password: string,
}

type Props = {|
	...StateProps,
	...DispatchProps,
	...WithTranslateProps,
	isAuthenticating: boolean,
|}

class DemoPage extends Component<Props> {
	componentDidMount() {
		const { isAuthenticating, currentUserEmail } = this.props
		const isAuthenticated = !!getAccessToken()

		if (isAuthenticated && !isAuthenticating && currentUserEmail) {
			this.handleLoggedUser()
		} else if (!isAuthenticating && !isAuthenticated) {
			this.loginDemoUser(false)
		}
	}

	handleLoggedUser = () => {
		const { t } = this.props
		setTimeout(() => {
			confirmDialog(t('dialogs.redirectToDemo'), {
				okLabel: t('dialogs.yesOption'),
				cancelLabel: t('dialogs.noOption'),
			})
				.then(() => {
					this.loginDemoUser(true)
				})
				.catch(() => {
					this.props.redirectToMainScreen()
				})
		}, 0)
	}

	loginDemoUser = (logoutCurrentUser: boolean) => {
		this.props.switchUser(DEMO_CREDENTIALS, logoutCurrentUser)
	}

	render() {
		return null
	}
}

type DispatchProps = {|
	redirectToMainScreen: () => void,
	switchUser: (userCredentials: { email: string, password: string }, logoutCurrentUser: boolean) => Promise<*>,
|}

type StateProps = {|
	currentUserEmail: ?string,
|}

function mapStateToProps(state: State): StateProps {
	return {
		currentUserEmail: state.user && state.user.me.data && state.user.me.data.email,
	}
}

function mapDispatchToProps(dispatch: Dispatch<*>): DispatchProps {
	return {
		redirectToMainScreen: () => {
			dispatch(navigateToMainScreen())
		},
		switchUser: async ({ email, password }: { email: string, password: string }, logoutCurrentUser: boolean) => {
			if (logoutCurrentUser) {
				await dispatch(logout())
			}
			dispatch(switchSplashScreen(true))
			await dispatch(authenticate(email, password))
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslate(DemoPage))
