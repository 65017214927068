/* @flow */
/** @jsx jsx */

// $FlowFixMe - there is useState, useEffect, useRef
import { useState, useEffect, useRef } from 'react'
import { jsx } from '@emotion/core'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import TextField from 'components/TextField'

type Props = {|
	...WithTranslateProps,
	readonly?: boolean,
	accountingDocumentId?: string,
	note: ?string,
	defaultNote: ?string,
	onChange: (note: ?string) => void,
|}

function LineItemsHeader(props: Props) {
	const [note, setNote] = useState<string>(props.note || '')
	const previous = useRef(props.defaultNote || '')

	useEffect(() => {
		const newNote = props.defaultNote || ''
		if (note === previous.current && newNote !== note) {
			setNote(newNote)
			props.onChange(newNote)
		}
		previous.current = newNote
	}, [props.defaultNote]) // eslint-disable-line react-hooks/exhaustive-deps

	function onChange(event: SyntheticInputEvent<HTMLInputElement>, note: ?string) {
		setNote(note)
	}

	function onBlur() {
		if ((note || '') === (props.defaultNote || '')) return
		props.onChange(note)
	}

	return (
		<div css={styles.root}>
			<h3>
				{props.readonly && !note ? null : (
					<TextField
						editCursor
						inline
						multiLine
						fullWidth
						autoHeight
						value={note}
						hintText={props.t('invoice.lineItems.headlineHint')}
						disabled={props.readonly}
						onChange={onChange}
						onBlur={onBlur}
						tabIndex={-1}
						style={styles.textField}
						name="line-items-header"
						autoTestId="line-items-header"
					/>
				)}
			</h3>
		</div>
	)
}

const styles = {
	root: { minHeight: 30 },
	textField: {
		minHeight: 24,
		lineHeight: '22px',
	},
}

export default withTranslate(LineItemsHeader)
