/* @flow */

import React, { Component } from 'react'
import { type WithTranslateProps, withTranslate } from 'wrappers'

import PeriodPlaceholder from '../../../settings/components/trivi-settings/period-placeholder'
import Separator from 'components/Separator'
import SettingsHeading from './settings-heading'
import SettingsItem from './settings-item'

type Props = {|
	...WithTranslateProps,
	showSchedulerSettings: ?boolean,
	showDuePeriodSettings: ?boolean,
	showRemindersSettings: ?boolean,
	showPeriodPlaceholders: ?boolean,
	displayContactSettings: boolean,
	accountingDocumentId: ?string,
	showBookingSettings?: boolean,
	onBookingSettingsClick: () => void,
	onContactSettingsClick: () => void,
	onRemindersSettingsClick: () => void,
	onSchedulerSettingsClick: () => void,
|}

class MainSettings extends Component<Props> {
	static defaultProps = {
		showBookingSettings: true,
	}

	renderDocumentSection = () => {
		return (
			<div style={styles.section}>
				<SettingsHeading text={this.props.t('invoice.settings.main.headline')} />
				{this.showBookingSettings() && (
					<div>
						<SettingsItem
							title={this.props.t('invoice.settings.main.setBooking')}
							subtitle={this.props.t('invoice.settings.main.setBookingSubtitle')}
							onClick={this.props.onBookingSettingsClick}
						/>
						<Separator />
					</div>
				)}

				{this.showSchedulerSettings() && (
					<div>
						<SettingsItem
							title={this.props.t('invoice.settings.scheduler.headline')}
							subtitle={this.props.t('invoice.settings.scheduler.subtitle')}
							onClick={this.props.onSchedulerSettingsClick}
						/>
						<Separator />
					</div>
				)}
			</div>
		)
	}

	showBookingSettings = () => {
		return this.props.showBookingSettings
	}

	showSchedulerSettings = () => {
		return this.props.showSchedulerSettings
	}

	showPeriodPlaceholders = () => {
		return this.props.showPeriodPlaceholders
	}

	hideContactSection = () => {
		return (
			!this.props.displayContactSettings || (!this.props.showDuePeriodSettings && !this.props.showRemindersSettings)
		)
	}

	renderContactSection = () => {
		if (this.hideContactSection()) return null

		return (
			<div style={styles.section}>
				<SettingsHeading text={this.props.t('invoice.settings.main.forPurchaser')} />
				{this.props.showDuePeriodSettings && (
					<SettingsItem
						title={this.props.t('invoice.settings.main.setDefaultDuePeriod')}
						subtitle={this.props.t('invoice.settings.main.setDefaultDuePeriodSubtitle')}
						onClick={this.props.onContactSettingsClick}
					/>
				)}
				<Separator />
				{this.props.showRemindersSettings && (
					<SettingsItem
						title={this.props.t('invoice.settings.reminders.label')}
						subtitle={this.props.t('invoice.settings.reminders.subtitle')}
						onClick={this.props.onRemindersSettingsClick}
					/>
				)}
			</div>
		)
	}

	renderPeriodPlaceholderSection = () => {
		if (!this.showPeriodPlaceholders()) return null

		return (
			<div style={styles.section}>
				<PeriodPlaceholder />
			</div>
		)
	}

	render() {
		if (!this.showBookingSettings() && !this.showSchedulerSettings() && this.hideContactSection()) {
			return null
		}

		return (
			<div style={styles.root}>
				{this.renderDocumentSection()}
				{this.renderContactSection()}
				{this.renderPeriodPlaceholderSection()}
			</div>
		)
	}
}

const styles = {
	root: {
		fontSize: 14,
		paddingRight: 10,
	},
	section: {
		marginBottom: 30,
	},
}

export default withTranslate(MainSettings)
