/* @flow */

import type { State } from 'types'
import store from 'store'

function getOrganizationId() {
	const state: State = store.getState()
	return state.user.currentOrganizationId || 'error'
}

export function settingsRoute() {
	return `/${getOrganizationId()}/settings`
}

//COMPANY --------------------------------------------------------------

export function settingsCompanyRoute() {
	return `/${getOrganizationId()}/settings/company`
}

export function settingsBasicsRoute() {
	return `/${getOrganizationId()}/settings/company/basics`
}

export function settingsEsoRoute() {
	return `/${getOrganizationId()}/settings/company/eso`
}

export function settingsResponsibilityRoute() {
	return `/${getOrganizationId()}/settings/company/responsibility`
}

export function settingsSignaturesRoute() {
	return `/${getOrganizationId()}/settings/company/signatures`
}

// export function settingsEetRoute() {
// 	return `/${getOrganizationId()}/settings/company/eet`
// }

export function settingsCashbotRoute() {
	return `/${getOrganizationId()}/settings/company/cashbot`
}

export function settingsAdministrationRoute() {
	return `/${getOrganizationId()}/settings/company/administration`
}

//ACCOUNTING --------------------------------------------------------------

export function settingsAccountingRoute() {
	return `/${getOrganizationId()}/settings/accounting`
}

export function settingsAccountingSettingsRoute() {
	return `/${getOrganizationId()}/settings/accounting/accounting-settings`
}

export function settingsSequencesRoute() {
	return `/${getOrganizationId()}/settings/accounting/sequences`
}

export function settingsAccountingDocumentsRoute() {
	return `/${getOrganizationId()}/settings/accounting/accounting-documents`
}

export function settingsFinancialAccountsRoute() {
	return `/${getOrganizationId()}/settings/accounting/financial-accounts`
}

//REPORTING --------------------------------------------------------------

export function settingsReportingRoute() {
	return `/${getOrganizationId()}/settings/reporting`
}

export function settingsBranchesRoute() {
	return `/${getOrganizationId()}/settings/reporting/branches`
}

export function settingsProjectsRoute() {
	return `/${getOrganizationId()}/settings/reporting/projects`
}

//USERS --------------------------------------------------------------

export function settingsUsersRoute() {
	return `/${getOrganizationId()}/settings/users`
}

export function settingsUsersUsersRoute() {
	return `/${getOrganizationId()}/settings/users/users`
}

export function settingsUsersInternalRoute() {
	return `/${getOrganizationId()}/settings/users/internal`
}

export function settingsUsersGroupsRoute() {
	return `/${getOrganizationId()}/settings/users/groups`
}

//TRIVI SETTINGS --------------------------------------------------------------

export function settingsTriviSettingsRoute() {
	return `/${getOrganizationId()}/settings/trivi-settings`
}

export function settingsEmailsRoute() {
	return `/${getOrganizationId()}/settings/trivi-settings/emails`
}
export function settingsPaymentRemindersRoute() {
	return `/${getOrganizationId()}/settings/trivi-settings/payment-reminders`
}

export function settingsApiRoute() {
	return `/${getOrganizationId()}/settings/trivi-settings/api`
}

export function settingsPrintingRoute() {
	return `/${getOrganizationId()}/settings/trivi-settings/printing`
}

export function settingsExtractionRoute() {
	return `/${getOrganizationId()}/settings/trivi-settings/extraction`
}

export function settingsUploadReminderRoute() {
	return `/${getOrganizationId()}/settings/trivi-settings/upload-reminder`
}

export function settingsAccountignDocumentCategoriesRoute() {
	return `/${getOrganizationId()}/settings/trivi-settings/accounting-document-categories`
}
