//@flow

import type { Dispatch, AccountingDocumentCategory, State, AccountingDocumentCategoryUpdateRequest } from 'types'
import {
	accountingDocumentCategories as accountingDocumentCategoriesApi,
	accountingDocumentCategory as accountingDocumentCategoryApi,
} from 'modules/common/models/api-model'
import { getAccountingDocumentCategories } from 'modules/settings/selectors'

import type { Action } from './accounting-document-categories-action-types'

export function loadAccountingDocumentCategories() {
	return async (dispatch: Dispatch<Action>) => {
		dispatch({
			type: 'START_LOADING_ACCOUNTING_DOCUMENT_CATEGORIES',
		})
		try {
			const accountingDocumentCategories: Array<AccountingDocumentCategory> = await accountingDocumentCategoriesApi.get(
				{},
			)
			dispatch({
				type: 'FINISH_LOADING_ACCOUNTING_DOCUMENT_CATEGORIES',
				accountingDocumentCategories,
			})
		} catch (serverError) {
			dispatch({
				serverError,
				type: 'FINISH_LOADING_ACCOUNTING_DOCUMENT_CATEGORIES',
			})
		}
	}
}

export function createAccountingDocumentCategory(category: AccountingDocumentCategory) {
	return async (dispatch: Dispatch<Action>, getState: () => State) => {
		const oldAccountingDocumentCategories: ?Array<AccountingDocumentCategory> = getAccountingDocumentCategories(
			getState(),
		)

		if (!oldAccountingDocumentCategories) {
			return
		}

		let newAccountingDocumentCategories: Array<AccountingDocumentCategory> = [
			...oldAccountingDocumentCategories,
			category,
		]

		dispatch({
			type: 'START_CREATING_ACCOUNTING_DOCUMENT_CATEGORY',
			newAccountingDocumentCategories,
		})

		try {
			const newCategory: AccountingDocumentCategory = await accountingDocumentCategoriesApi.post(category)
			newAccountingDocumentCategories = [...oldAccountingDocumentCategories, newCategory] //append correct category with id filled

			return dispatch({
				type: 'FINISH_CREATING_ACCOUNTING_DOCUMENT_CATEGORY',
				newAccountingDocumentCategories,
			})
		} catch (serverError) {
			return dispatch({
				type: 'FINISH_CREATING_ACCOUNTING_DOCUMENT_CATEGORY',
				serverError,
				oldAccountingDocumentCategories,
			})
		}
	}
}

export function editAccountingDocumentCategory(
	accountingDocumentCategoryId: string,
	data: AccountingDocumentCategoryUpdateRequest,
) {
	return async (dispatch: Dispatch<Action>, getState: () => State) => {
		const oldAccountingDocumentCategories: ?Array<AccountingDocumentCategory> = getAccountingDocumentCategories(
			getState(),
		)

		if (!oldAccountingDocumentCategories) {
			return
		}

		const newAccountingDocumentCategories: Array<AccountingDocumentCategory> = oldAccountingDocumentCategories.map(
			(c: AccountingDocumentCategory) => {
				return c.id !== accountingDocumentCategoryId
					? c
					: Object.freeze({
							...c,
							name: data.name,
					  })
			},
		)

		dispatch({
			type: 'START_EDITING_ACCOUNTING_DOCUMENT_CATEGORY',
			newAccountingDocumentCategories,
		})

		try {
			await accountingDocumentCategoryApi.put({ accountingDocumentCategoryId }, data)
			return dispatch({
				type: 'FINISH_EDITING_ACCOUNTING_DOCUMENT_CATEGORY',
				newAccountingDocumentCategories,
			})
		} catch (serverError) {
			return dispatch({
				type: 'FINISH_EDITING_ACCOUNTING_DOCUMENT_CATEGORY',
				serverError,
				oldAccountingDocumentCategories,
			})
		}
	}
}

export function deleteAccountingDocumentCategory(accountingDocumentCategoryId: string) {
	return async (dispatch: Dispatch<Action>, getState: () => State) => {
		const oldAccountingDocumentCategories: ?Array<AccountingDocumentCategory> = getAccountingDocumentCategories(
			getState(),
		)

		if (!oldAccountingDocumentCategories) {
			return
		}

		const newAccountingDocumentCategories: Array<AccountingDocumentCategory> = oldAccountingDocumentCategories.filter(
			(c: AccountingDocumentCategory) => c.id !== accountingDocumentCategoryId,
		)

		dispatch({
			type: 'START_DELETING_ACCOUNTING_DOCUMENT_CATEGORY',
			newAccountingDocumentCategories,
		})

		try {
			await accountingDocumentCategoryApi.delete({ accountingDocumentCategoryId })
			return dispatch({
				type: 'FINISH_DELETING_ACCOUNTING_DOCUMENT_CATEGORY',
				newAccountingDocumentCategories,
			})
		} catch (serverError) {
			return dispatch({
				type: 'FINISH_DELETING_ACCOUNTING_DOCUMENT_CATEGORY',
				serverError,
				oldAccountingDocumentCategories,
			})
		}
	}
}
