//@flow
import React from 'react'
import { SelectField, MenuItem } from 'components'
import type { PeriodParams } from 'types'
import styles from './period-picker.css'
import { withTranslate, type WithTranslateProps } from 'wrappers'

type Props = {|
	onChange: (periodParams: PeriodParams) => void,
|}

type State = {|
	maxDate: Date,
	month: number,
	year: number,
|}

class PeriodPicker extends React.Component<Props & WithTranslateProps, State> {
	state = {
		maxDate: new Date(),
		month: new Date().getMonth() + 1,
		year: new Date().getFullYear(),
	}

	renderMonthMenuItems = () => {
		const { maxDate, year } = this.state
		const { t } = this.props
		const maxDateYear = maxDate.getFullYear()
		const maxDateMonth = year === maxDateYear ? maxDate.getMonth() + 1 : 12
		const months = [
			'january',
			'february',
			'march',
			'april',
			'may',
			'june',
			'july',
			'august',
			'september',
			'october',
			'november',
			'december',
		]
		return months.reduce((a: Array<any>, b: string, i: number) => {
			if (i + 1 <= maxDateMonth) {
				const primaryText = t(`application.months.${b}`)
				a.push(<MenuItem key={i} primaryText={primaryText} value={i + 1} />)
			}
			return a
		}, [])
	}

	renderYearMenuItems = () => {
		const { maxDate } = this.state
		const maxDateYear = maxDate.getFullYear()

		const years = []
		for (let i = maxDateYear; i >= maxDateYear - 10; i--) {
			years.push(i)
		}
		return years.map((i: number) => <MenuItem key={i} primaryText={i.toString()} value={i} />)
	}

	onMonthSelectChange = (event: any, index: number, value: number) => {
		this.setState({ month: value }, () => {
			this.props.onChange({
				periodMonth: this.state.month,
				periodYear: this.state.year,
			})
		})
	}

	onYearSelectChange = (event: any, index: number, value: number) => {
		const { maxDate } = this.state
		const maxDateYear = maxDate.getFullYear()
		const maxDateMonth = maxDate.getMonth() + 1
		const newState = {
			...this.state,
			year: value,
		}
		if (value === maxDateYear) {
			newState.month = maxDateMonth
		}
		this.setState(newState, () => {
			this.props.onChange({
				periodMonth: this.state.month,
				periodYear: this.state.year,
			})
		})
	}

	render() {
		const { month, year } = this.state
		return (
			<div className={styles.wrapper}>
				<SelectField autoTestId="period-picker-month" onChange={this.onMonthSelectChange} value={month} fullWidth>
					{this.renderMonthMenuItems()}
				</SelectField>
				<SelectField autoTestId="period-picker-year" onChange={this.onYearSelectChange} value={year} fullWidth>
					{this.renderYearMenuItems()}
				</SelectField>
			</div>
		)
	}
}

export default withTranslate(PeriodPicker)
