/* @flow */
import { colors } from 'variables'

export default {
	root: {
		position: 'relative',
	},
	disabled: {
		opacity: 0.7,
		filter: 'grayscale(1)',
		pointerEvents: 'none',
	},
	container: {
		position: 'relative',
		height: 110,
		overflow: 'hidden',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		flexWrap: 'nowrap',
		cursor: 'pointer',
		'&:hover': {
			boxShadow: '1px 1px 0 rgba(0, 0, 0, 0.08)',
		},
	},
	selected: {
		borderColor: colors.blue,
		backgroundColor: colors.blueFaded10,
	},
	circle: {
		position: 'absolute',
		display: 'block',
		left: -78,
		top: -106,
		width: 250,
		height: 250,
		borderRadius: '50%',
	},
	title: {
		flex: '1',
		fontSize: 13,
		color: colors.white,
		letterSpacing: '0.7px',
		fontWeight: 'bold',
		textTransform: 'uppercase',
		maxWidth: 160,
		padding: '0 30px',
		position: 'relative',
	},
	info: {
		display: 'flex',
		flexDirection: 'column',
		position: 'relative',
		flex: '1',
		paddingLeft: 35,
	},
	primary: {
		fontSize: 16,
		lineHeight: '20px',
		color: colors.black,
	},
	secondary: {
		color: colors.blackFaded60,
		fontSize: 14,
		lineHeight: '20px',
	},
	mainChip: {
		borderTopLeftRadius: 0,
		borderTopRightRadius: 0,
		position: 'absolute',
		display: 'block',
		right: 10,
		top: 0,
	},
	paymentsChip: {
		borderTopLeftRadius: 0,
		borderTopRightRadius: 0,
		position: 'absolute',
		display: 'block',
		right: 10,
		bottom: -20,
	},
	processingState: {
		position: 'absolute',
		left: 10,
		bottom: -20,
		fontSize: 0,
	},
	processingChip: {
		borderTopLeftRadius: 0,
		borderTopRightRadius: 0,
	},
	link: {
		color: colors.white,
	},
	chips: {
		position: 'absolute',
		right: 15,
		top: -1,
		justifyContent: 'space-between',
		display: 'flex',
		gap: 8,
	},
	loader: {
		display: 'block',
		width: '100%',
		height: '100%',
	},
}
