// @flow
import { addSubPath, getFormFieldErrorContexts, getValues, removeError } from 'helpers'
import { TEMPLATE_ERROR_MAIN_PATHS } from '../constants'
import type {
	Dispatch,
	CommonAction,
	FormFieldErrorContext,
	AccountingDocumentLineItem,
	BootstrapAction,
	TemplatesAction,
	AccountingDocument,
} from 'types'

export function removeErrors(
	templateId?: ?string,
	accDoc: ?AccountingDocument,
	dispatch: Dispatch<BootstrapAction | TemplatesAction | CommonAction>,
) {
	if (templateId) {
		const formFieldErrorContexts = getFormFieldErrorContexts(TEMPLATE_ERROR_MAIN_PATHS, 'Template', templateId)
		const fieldErrors: Array<FormFieldErrorContext> = getValues(formFieldErrorContexts)

		fieldErrors.forEach((ec: FormFieldErrorContext) => {
			removeError({
				formFieldErrorContext: ec,
				dispatch,
			})
		})

		accDoc &&
			accDoc.lineItems &&
			accDoc.lineItems.forEach((li: AccountingDocumentLineItem, index: number) => {
				let lineItemErrorContext = addSubPath(formFieldErrorContexts['lineItems'], li.id || `@${index}`)
				removeError({
					formFieldErrorContext: lineItemErrorContext,
					dispatch,
					extraPath: ['description'],
				})
				removeError({
					formFieldErrorContext: lineItemErrorContext,
					dispatch,
					extraPath: ['vatRateId'],
				})
				removeError({
					formFieldErrorContext: lineItemErrorContext,
					dispatch,
					extraPath: ['finAccount'],
				})
			})
	}
}
