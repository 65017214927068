/* @flow */
/* eslint-disable react/no-multi-comp */
/** @jsx jsx */

import { jsx } from '@emotion/core'
import moize from 'moize'
import type { AccountingDocument } from 'types'
import { type WithTranslateProps } from 'wrappers'
import TriviLink from 'modules/common/components/trivi-link'
import { Column } from 'modules/data-grid-next/components'
import { Type_AccountingDocumentName } from 'types/convertor'
import type { Direction } from 'modules/data-grid-next/types'
import Tooltip from 'components/tooltip'
import LocalOfferIcon from 'components/svg-icons/maps/local-offer'
import { DATA_GRID_CACHE_SIZE } from 'trivi-constants'
import { colors } from 'variables'
// import EETBadge from 'modules/accounting-document/components/accounting-document-list/eet-badge'

export type Props = {|
	...WithTranslateProps,
	width?: number | string,
	defaultSortDirection?: Direction,
	onClick?: (accountingDocument: AccountingDocument) => void,
	routeFn?: (...args: any[]) => string,
|}

const styles = {
	container: {
		position: 'absolute',
		left: -10,
		top: '20%',
		width: 3,
		height: '60%',
	},
	default: {},
	Invoice: {
		background: colors.navy400Faded60,
	},
	Advance: {
		background: 'rgba(255, 193, 62, 0.6)',
	},
	TaxAdvance: {
		background: colors.greenFaded60,
	},
	CreditNote: {
		background: colors.skyblue400Faded60,
	},
	CashReceipt: {},
	SimplifiedInvoice: {},
	Unknown: {},
	EmployeeAdvance: {},
	CashTransfer: {},
	CashUncategorized: {},
	no: {
		verticalAlign: 'middle',
		overflow: 'hidden',
		minHeight: 21,
		maxWidth: '100%',
		display: 'inline-flex',
		alignItems: 'center',
		paddingRight: 15,
	},
	link: {
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
		flex: '1 1 auto',
	},
	vs: {
		color: colors.blackFaded60,
		fontSize: 12,
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
		minHeight: 21,
	},
	customerInstructions: {
		marginLeft: 6,
		marginRight: 2,
		flex: '0 0 16px',
	},
	eet: {
		display: 'inline-flex',
		marginBottom: 1,
		marginLeft: 4,
	},
}

export function renderContent(props: Props, value: string, row: AccountingDocument) {
	function clickHandler(event: SyntheticMouseEvent<HTMLElement>) {
		event.stopPropagation()
		props.onClick && props.onClick(row)
	}

	const id: string = `${props.t('accountingDocument.columns.id')}: ${row.id || ''}`
	const documentType = Type_AccountingDocumentName(row.type)
	const tooltip = documentType ? `${props.t('accountingDocument.types.' + documentType)}` : ''

	return (
		<div>
			<Tooltip label={tooltip} wrapperStyle={{ ...styles.container, ...styles[documentType || 'default'] }} />
			<div css={styles.no}>
				<TriviLink style={styles.link} routeFn={props.routeFn} onClick={clickHandler} routeParams={[row.id || '']}>
					{row.accountingDocumentNo || id}
				</TriviLink>
				{/*<div css={styles.eet}>*/}
				{/*	<EETBadge countryVariantSpecific={row.countryVariantSpecific} />*/}
				{/*</div>*/}
				{(row.customerInstructions || '').trim() && (
					<span style={styles.customerInstructions}>
						<Tooltip inline label={row.customerInstructions} multiLine>
							<LocalOfferIcon hoverColor={colors.black} size={16} style={{ verticalAlign: 'text-top' }} />
						</Tooltip>
					</span>
				)}
			</div>
			<div style={styles.vs}>{row.variableSymbol}</div>
		</div>
	)
}

export default function getAccountingDocumentNoColumn(props: Props) {
	function columnRenderer(value: string, row: AccountingDocument) {
		return renderContent(props, value, row)
	}

	const columnRendererCashed = moize(columnRenderer, { maxSize: DATA_GRID_CACHE_SIZE })

	return (
		<Column
			title={props.t('accountingDocument.columns.accountingDocumentNo')}
			defaultSortDirection={props.defaultSortDirection || undefined}
			columnId="accountingDocumentNo"
			key="accountingDocumentNo"
			render={columnRendererCashed}
			width={props.width}
			sortable
		/>
	)
}
