/* @flow */

import React, { Component } from 'react'
import type { AccountingDocument, AccountingDocumentBankAccount } from 'types'
import { findBankAccountIndex } from 'types/operations'
import AddBankAccount from '../invoice-elements/add-bank-account'
import { EMPTY_ARRAY } from 'trivi-constants'

type Props = {|
	accountingDocument: AccountingDocument,
	onChange: (accountingDocument: AccountingDocument) => void,
	direction: 'issued' | 'received',
	hideWhenZeroAccounts?: boolean,
|}

export default class AddBankAccountTemplate extends Component<Props> {
	findIndex = (bankAccount: AccountingDocumentBankAccount): number => {
		return findBankAccountIndex(this.props.accountingDocument.bankAccounts || [], bankAccount)
	}

	onBankAccountAttach = (bankAccount: AccountingDocumentBankAccount) => {
		const foundIndex: number = this.findIndex(bankAccount)
		if (foundIndex < 0) {
			// New bank account
			bankAccount.localId =
				(this.props.accountingDocument.bankAccounts && this.props.accountingDocument.bankAccounts.length.toString()) ||
				'0'
			let bankAccounts = Array.from(this.props.accountingDocument.bankAccounts || [])
			bankAccounts.push(bankAccount)
			this.props.onChange(Object.seal({ ...this.props.accountingDocument, bankAccounts }))
		}
	}

	render() {
		const show: boolean =
			(this.props.accountingDocument.bankAccounts && this.props.accountingDocument.bankAccounts.length > 0) || false
		const readonly: boolean = !show
		return (
			<AddBankAccount
				accountingDocumentId={this.props.accountingDocument.id || ''}
				direction={this.props.direction}
				readonly={readonly}
				bankAccounts={this.props.accountingDocument.bankAccounts || EMPTY_ARRAY}
				onBankAccountAttach={this.onBankAccountAttach}
				hideWhenZeroAccounts={this.props.hideWhenZeroAccounts}
			/>
		)
	}
}
