/* @flow */
/** @jsx jsx */

import { Fragment } from 'react'
import { jsx } from '@emotion/core'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import type { AccountingDocument, AccountingDocumentExtractingState } from 'types'
import Button from 'components/button'
import PopupSection from 'components/PopupSection'
import ConfirmDialog from 'components/confirm-dialog'
import ScanView from './scan-view'

type Props = {|
	...WithTranslateProps,
	open: boolean,
	accountingDocument: AccountingDocument,
	showConfirmation?: boolean,
	onStateChange: (extractingState: AccountingDocumentExtractingState) => Promise<*>,
	onClose: () => void,
|}

function ApproveDialog(props: Props) {
	if (!props.open) return null
	if (!props.accountingDocument.scans || !props.accountingDocument.scans.length) return null

	const showReturn =
		props.accountingDocument?.__extractingState === 6 || props.accountingDocument?.__extractingState === 7
	const showReject =
		props.accountingDocument?.__extractingState === 5 || props.accountingDocument?.__extractingState === 6
	const showApprove =
		props.accountingDocument?.__extractingState === 5 || props.accountingDocument?.__extractingState === 7

	function onReturn() {
		confirm(
			props.t('accountingDocument.approval.confirmReturnHeadline'),
			props.t('accountingDocument.approval.return'),
			() => {
				props.accountingDocument?.__extractingState !== 5 && props.onStateChange(5)
				props.onClose()
			},
		)
	}

	function onReject() {
		confirm(
			props.t('accountingDocument.approval.confirmRejectHeadline'),
			props.t('accountingDocument.approval.reject'),
			() => {
				props.accountingDocument?.__extractingState !== 7 && props.onStateChange(7)
				props.onClose()
			},
		)
	}

	function onApprove() {
		confirm(
			props.t('accountingDocument.approval.confirmApproveHeadline'),
			props.t('accountingDocument.approval.approve'),
			() => {
				props.accountingDocument?.__extractingState !== 6 && props.onStateChange(6)
				props.onClose()
			},
		)
	}

	function confirm(question: string, okLabel: string, then: Function) {
		if (!props.showConfirmation) return then()
		ConfirmDialog(question, {
			cancelLabel: props.t('dialogs.goBackOption'),
			body: props.t('accountingDocument.approval.confirmSubheadline'),
			waitForConfirm: true,
			okLabel,
		}).then(then)
	}

	function renderButtons() {
		return (
			<Fragment>
				{showReturn && (
					<Button
						autoTestId="approve-dialog-return"
						onClick={onReturn}
						wide
						labelText={props.t('accountingDocument.approval.return')}
					/>
				)}
				{showReject && (
					<Button
						autoTestId="approve-dialog-reject"
						onClick={onReject}
						wide
						dangerRounded
						labelText={props.t('accountingDocument.approval.reject')}
					/>
				)}
				{showApprove && (
					<Button
						autoTestId="approve-dialog-approve"
						onClick={onApprove}
						wide
						success
						labelText={props.t('accountingDocument.approval.approve')}
					/>
				)}
			</Fragment>
		)
	}

	return (
		<PopupSection open={props.open} onRequestClose={props.onClose}>
			<div css={styles.container}>
				<div css={styles.header}>{renderButtons()}</div>
				<div css={styles.content}>
					<ScanView scans={props.accountingDocument.scans} />
				</div>
			</div>
		</PopupSection>
	)
}

const SAFEZONE = 180

const styles = {
	container: {
		position: 'relative',
		marginLeft: 'auto',
		marginRight: 'auto',
		marginBottom: 50,
		maxWidth: '80%',
		width: 970,
	},
	header: {
		paddingTop: 20,
		paddingBottom: 20,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		minHeight: SAFEZONE / 2,
	},
	content: {
		height: `calc(100vh - ${SAFEZONE}px)`,
	},
}

export default withTranslate(ApproveDialog)
