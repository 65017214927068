//@flow
import React, { Component } from 'react'
import { withTranslate, withNotify, type WithTranslateProps, type WithNotifyProps } from 'wrappers'
import type { InternalUser } from 'types'
import MembershipTypeSelector from 'modules/common/components/selectors/membership-type-selector'
import UserPicker from 'modules/user/components/user-picker'
import Dialog from 'components/dialog'
import Button from 'components/button'
import styles from '../settings.css'

const HIDDEN_TYPES = [0, 1]

type Props = {|
	...WithTranslateProps,
	...WithNotifyProps,
	users: ?Array<InternalUser>,
	open: boolean,
	disabledTypes: Array<number>,
	onRequestClose: () => void,
	onAdd: (userId: ?string, membershipType: ?number) => void,
|}

type State = {|
	userId: ?string,
	membershipType: ?number,
|}

class InternalUserAdd extends Component<Props, State> {
	state = {
		userId: null,
		membershipType: null,
	}

	save = () => {
		this.props.onAdd(this.state.userId, this.state.membershipType)
		this.onClose()
	}

	onClose = () => {
		this.setState(
			{
				userId: null,
				membershipType: null,
			},
			this.props.onRequestClose,
		)
	}

	onUserChange = (userId: ?string) => {
		this.setState({ userId })
	}

	onMembershipTypeChange = (membershipType: number) => {
		this.setState({ membershipType })
	}

	render() {
		const isValid = this.state.userId && this.state.membershipType
		const actions = [
			<Button
				primary
				wide
				key="okButton"
				labelText={this.props.t('settings.forms.saveButton')}
				onClick={this.save}
				disabled={!isValid}
				autoTestId="settings-users-dialog-save"
			/>,
			<Button
				secondary
				wide
				key="cancelButton"
				labelText={this.props.t('settings.forms.cancelButton')}
				onClick={this.onClose}
				autoTestId="settings-users-dialog-cancel"
			/>,
		]

		return (
			<Dialog
				autoTestId="internal-user-add-dialog"
				title={this.props.t('settings.users.addInternal')}
				actions={actions}
				open={this.props.open}
			>
				<div className={styles.oneColumnDialog}>
					<div className={styles.row}>
						<UserPicker
							isClearable
							labelText={this.props.t('settings.forms.addUserLabel')}
							users={[...(this.props.users || [])]}
							onChange={this.onUserChange}
							value={this.state.userId}
							displayEmail
						/>
					</div>
					<div className={styles.row}>
						<MembershipTypeSelector
							fullWidth
							onChange={this.onMembershipTypeChange}
							disabledTypes={this.props.disabledTypes}
							value={this.state.membershipType}
							hiddenTypes={HIDDEN_TYPES}
						/>
					</div>
				</div>
			</Dialog>
		)
	}
}

export default withTranslate(withNotify(InternalUserAdd))
