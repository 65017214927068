// @flow
/** @jsx jsx */

import { PureComponent } from 'react'
import { jsx } from '@emotion/core'
import memoize from 'memoize-one'
import type { AccountingDocument, AccountingDocumentPayment } from 'types'
import { AmountDisplay, LinearProgress, Tooltip } from 'components'
import Green from 'components/svg-icons/trivi/state/green'
import Blue from 'components/svg-icons/trivi/state/blue'
import Cash from 'components/svg-icons/trivi/state/cash'
import { Number_PaymentType } from 'types/convertor'
import { getAccDocToPayDomestic, getAccDocCurrencyDomestic } from 'types/operations'
import Red from 'components/svg-icons/trivi/state/red'
import { formatToDateString } from 'utils/formatters'
import { getPaymentState, isDraft } from 'helpers'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import { colors } from 'variables'

type Props = {|
	...WithTranslateProps,
	accountingDocument: ?AccountingDocument,
	hideEmpty?: boolean,
	compact?: boolean,
|}

class PaymentState extends PureComponent<Props> {
	getStyles = memoize((compact: ?boolean) => {
		const stateStyle = {
			cursor: 'default',
			position: 'relative',
			marginLeft: 2,
			'&:before': {
				content: '""',
				position: 'absolute',
				display: 'block',
				bottom: -1,
				left: -1,
				right: -1,
				height: 1,
			},
		}

		return {
			root: {
				lineHeight: compact ? 'initial' : '24px',
				color: compact ? 'initial' : colors.black,
				position: 'relative',
			},
			popover: {
				width: 'auto',
				height: 'auto',
				padding: '15px 20px',
				lineHeight: '20px',
				fontSize: 12,
				color: colors.white,
				background: colors.grey900,
				borderRadius: 0,
				border: 'none',
				marginTop: 5,
				maxHeight: 480,
				overflow: 'auto',
			},
			progress: {
				width: 290,
			},
			stateWrapper: {
				cursor: 'default',
				display: 'flex',
				justifyContent: 'flex-start',
				alignContent: 'center',
				alignItems: 'center',
			},
			none: {
				...stateStyle,
			},
			unpaid: {
				...stateStyle,
				'&:before': {
					background: colors.redFaded60,
				},
			},
			paid: {
				...stateStyle,
				'&:before': {
					background: colors.greenFaded60,
				},
			},
			partialyPaid: {
				...stateStyle,
				'&:before': {
					background: colors.orangeFaded60,
				},
			},
			paidConfirmed: {
				...stateStyle,
			},
			table: {
				textAlign: 'left',
				fontWeight: 'normal',
				width: '100%',
				tr: {
					height: 20,
					lineHeight: '20px',
				},
				th: {
					fontSize: 10,
					fontWeight: 'normal',
					'&:first-child': {
						width: 80,
					},
					'&:last-child': {
						textAlign: 'right',
					},
				},
				td: {
					'&:last-child': {
						textAlign: 'right',
					},
				},
			},
			toPay: {
				fontWeight: 'bold',
				marginTop: 5,
				textAlign: 'right',
			},
		}
	})

	getPaymentIcon = memoize((state: string, isDraft: boolean) => {
		switch (state) {
			case 'none':
				return <Green />
			case 'paidConfirmed':
				return isDraft ? <Red /> : <Green />
			case 'paid':
				return <Cash />
			case 'partialyPaid':
				return <Blue />
			case 'unpaid':
				return <Red />
		}
	})

	renderLabel() {
		const accountingDocument: ?AccountingDocument = this.props.accountingDocument
		if (!accountingDocument || 0 === accountingDocument.total) return null

		const { t } = this.props
		const styles = this.getStyles(this.props.compact)
		const payments = (accountingDocument && accountingDocument.payments && accountingDocument.payments.payments) || []
		const paymentSum = payments.reduce(
			(result: number, payment: AccountingDocumentPayment) => result + (payment.amount || 0),
			0,
		)
		const toPayDomestic: number = getAccDocToPayDomestic(accountingDocument)
		const currencyDomestic: ?string = getAccDocCurrencyDomestic(accountingDocument)

		return (
			<div css={styles.popover}>
				{((accountingDocument.payments && accountingDocument.payments.paid) || payments.length !== 0) && (
					<LinearProgress
						style={styles.progress}
						color={paymentSum === accountingDocument.total ? colors.green : colors.orange}
						mode="determinate"
						min={0}
						max={accountingDocument.total}
						value={
							accountingDocument.payments && accountingDocument.payments.paid ? accountingDocument.total : paymentSum
						}
					/>
				)}
				<table css={styles.table}>
					<tbody>
						{payments.length !== 0 ? (
							<tr>
								<th>{t('accountingDocument.gridTotalColumn.dueDate')}</th>
								<th>{t('accountingDocument.gridTotalColumn.paymentType')}</th>
								<th>{t('accountingDocument.gridTotalColumn.amount')}</th>
							</tr>
						) : (
							<tr>
								<td colSpan="3">{t('accountingDocument.gridTotalColumn.noPayments')}</td>
							</tr>
						)}
						{payments.map((payment: AccountingDocumentPayment) => (
							<tr key={payment.id}>
								<td>{payment.date && formatToDateString(payment.date)}</td>
								<td>{t(`accountingDocument.paymentTypes.${Number_PaymentType(payment.type)}`)}</td>
								<td>
									<AmountDisplay currency={currencyDomestic} amount={payment.domesticAmount} />
								</td>
							</tr>
						))}
					</tbody>
				</table>
				<div css={styles.toPay}>
					{t('accountingDocument.gridTotalColumn.toPay')}&nbsp;
					<AmountDisplay currency={currencyDomestic} amount={toPayDomestic} />
				</div>
			</div>
		)
	}

	render() {
		const { t, accountingDocument } = this.props
		if (this.props.hideEmpty && (!accountingDocument || 0 === accountingDocument.total)) return null

		const styles = this.getStyles(this.props.compact)
		const paymentState = getPaymentState(accountingDocument)

		return (
			<div css={styles.root}>
				<Tooltip hoverable label={this.renderLabel()}>
					<div css={styles.stateWrapper}>
						{this.getPaymentIcon(paymentState, isDraft(accountingDocument))}
						{!this.props.compact && (
							<span css={styles[paymentState]}>
								{t(`accountingDocument.gridTotalColumn.paymentStates.${paymentState}`)}
							</span>
						)}
					</div>
				</Tooltip>
			</div>
		)
	}
}

export default withTranslate(PaymentState)
