/* @flow */
import React, { Component } from 'react'
import Features from 'utils/features'
import styles from './test-tool.css'
import PermissionsList from './permissions-list'
import DeleteIcon from 'components/svg-icons/action/delete'
import { colors } from 'variables'
import type { Permission } from 'types'

type Props = {|
	availablePermissions: Array<Permission>,
	serverEnabledPermissions: Array<Permission>,
	userEnabledPermissions: Array<Permission>,
	userDisabledPermissions: Array<Permission>,
	onEnablePermission: (permission: Permission) => void,
	onDisablePermission: (permission: Permission) => void,
	onRemovePermission: (permission: Permission) => void,
	onRemoveAllPermissions: () => void,
	onReloadPress: () => void,
|}

type State = {|
	open: boolean,
|}

class PermissionsTestTool extends Component<Props, State> {
	state = {
		open: false,
	}

	toggleOpen = () => {
		this.setState({
			open: !this.state.open,
		})
	}

	render() {
		const containerStyle = { height: this.state.open ? '90%' : undefined }
		const listStyle = { height: this.state.open ? undefined : 0 }
		const showDeleteAll: boolean =
			this.props.userEnabledPermissions.length > 0 || this.props.userDisabledPermissions.length > 0 || false

		if (Features.isEnabled('permissionsTestTool')) {
			return (
				<div className={styles.container} style={containerStyle}>
					<div className={styles.header} onClick={this.toggleOpen}>
						Permissions Test Tool
						{showDeleteAll && (
							<DeleteIcon color={colors.grey500} style={style.delete} onClick={this.props.onRemoveAllPermissions} />
						)}
					</div>
					<div className={styles.list} style={listStyle}>
						<PermissionsList
							availablePermissions={this.props.availablePermissions}
							serverEnabledPermissions={this.props.serverEnabledPermissions}
							userEnabledPermissions={this.props.userEnabledPermissions}
							userDisabledPermissions={this.props.userDisabledPermissions}
							onEnablePermission={this.props.onEnablePermission}
							onDisablePermission={this.props.onDisablePermission}
							onRemovePermission={this.props.onRemovePermission}
							onReloadPress={this.props.onReloadPress}
						/>
					</div>
				</div>
			)
		} else {
			return null
		}
	}
}

const style = {
	delete: { cursor: 'pointer' },
}

export default PermissionsTestTool
