/* @flow */

import React, { Component } from 'react'
import { TransitionGroup } from 'react-transition-group'
import CalendarSlideChild from './calendar-slide-child'

export type Props = {|
	children: any,
	direction: 'left' | 'right',
	style?: Object,
|}

class CalendarSlide extends Component<Props> {
	static defaultProps = {
		direction: 'left',
	}

	render() {
		const mergedRootStyles = Object.assign(
			{},
			{
				position: 'relative',
				overflow: 'hidden',
				height: '100%',
			},
			this.props.style,
		)

		const newChildren = React.Children.map(
			this.props.children,
			(child: React$Element<any>) => {
				return (
					<CalendarSlideChild key={child.key} direction={this.props.direction}>
						{child}
					</CalendarSlideChild>
				)
			},
			this,
		)

		return (
			<TransitionGroup style={mergedRootStyles} component="div">
				{newChildren}
			</TransitionGroup>
		)
	}
}

export default CalendarSlide
