// @flow

import React, { Component } from 'react'
import NotificationCenter from 'modules/common/containers/notification-center'

export default function basicLayout() {
	return (Content: React$ComponentType<any>): React$ComponentType<any> => {
		return class BasicLayout extends Component<{}> {
			render() {
				return (
					<div>
						<Content {...this.props} />
						<NotificationCenter />
						<div id="render-to-layer" />
					</div>
				)
			}
		}
	}
}
