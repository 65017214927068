/* @flow */

import React, { Component, type Node } from 'react'
import { ACCOUNTING_DOCUMENT_NAMES, AccountingDocumentType_Number } from 'types/convertor'
import type { AccountingDocumentType, AccountingDocumentAssignedType, I18NextTranslateFn } from 'types'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import memoize from 'memoize-one'
import SelectNext, { type OptionsType, type OptionType, type ValueType } from 'components/select-next'

type Props = {|
	...WithTranslateProps,
	value: ?number,
	allowedTypes?: Array<AccountingDocumentAssignedType>,
	labelText?: string,
	clientError?: Node,
	onChange: (type: number) => void | Promise<*>,
|}

class AccountingDocumentTypeSelector extends Component<Props> {
	handleChange = (value: ValueType) => {
		const newAccDocType = value && !Array.isArray(value) ? parseInt(value.value) : null
		if (newAccDocType || newAccDocType === 0) {
			this.props.onChange(newAccDocType)
		}
	}

	getOptions = memoize((allowedTypes: ?Array<AccountingDocumentAssignedType>, t: I18NextTranslateFn): OptionsType => {
		if (!allowedTypes) return []
		let names = {}

		if (allowedTypes) {
			allowedTypes.forEach((type: AccountingDocumentType | AccountingDocumentAssignedType) => {
				const index: string = AccountingDocumentType_Number(type) + ''
				names[index] = ACCOUNTING_DOCUMENT_NAMES[index]
			})
		} else {
			names = { ...ACCOUNTING_DOCUMENT_NAMES }
		}

		delete names['9']

		return Object.entries(names).map(([type: string, name: string]: any): OptionType => {
			return { value: type, label: t(`accountingDocument.types.${name}`) }
		})
	})

	render() {
		const { t, allowedTypes, value } = this.props
		const selectedValue = value !== null && value !== undefined ? value.toString() : null

		return (
			<SelectNext
				value={selectedValue}
				error={this.props.clientError}
				options={this.getOptions(allowedTypes, t)}
				label={this.props.labelText || t('accountingDocument.filter.type.label')}
				placeholder={t('invoice.classification.selectType')}
				onChange={this.handleChange}
			/>
		)
	}
}

export default withTranslate(AccountingDocumentTypeSelector)
