// @flow
import { dashboardRoute, emptyDashboardRoute } from 'modules/dashboard/routing/routes'
import { accountingDocumentsRoute } from 'modules/accounting-document/routing/routes'
import { addressBooksRoute } from 'modules/address-book/routing/routes'
import { bankRoute } from 'modules/bank/routing/routes'
import { cashRegistersRoute } from 'modules/cash-register/routing/routes'
import { featuresRoute } from 'modules/features/routing/routes'
import { internalDashboardRoute } from 'modules/internal/routing/routes'
import { rootDocumentsRoute } from 'modules/document/routing/routes'
import { settingsRoute } from 'modules/settings/routing/routes'
import { tasksRoute } from 'modules/task/routing/routes'
import { triviStoreRoute } from 'modules/trivi-store/routing/routes'

import GearIcon from 'components/svg-icons/trivi/menu/gear'
import MenuAddressBookIcon from 'components/svg-icons/trivi/menu/addressbook'
import MenuBankIcon from 'components/svg-icons/trivi/menu/bank'
import MenuCashdeskIcon from 'components/svg-icons/trivi/menu/cashdesk'
import MenuDashboardIcon from 'components/svg-icons/trivi/menu/dashboard'
import MenuDocumentsIcon from 'components/svg-icons/trivi/menu/documents'
import MenuInternalDashboardIcon from 'components/svg-icons/action/dashboard'
import MenuReceiptIcon from 'components/svg-icons/trivi/menu/receipt'
import MenuTasksIcon from 'components/svg-icons/trivi/menu/tasks'
import TriviIcon from 'components/svg-icons/trivi/menu/trivi'
import PlayIcon from 'components/svg-icons/av/play-arrow'

import type { NavigationSection, NavigationItem } from '../types'

export const NAVIGATION_SECTION_ROUTE_FNS: { [NavigationSection]: () => string } = {
	tasks: tasksRoute,
	internalDashboard: internalDashboardRoute,
	dashboard: dashboardRoute,
	settings: settingsRoute,
	documents: rootDocumentsRoute,
	cashRegisters: cashRegistersRoute,
	bankAccounts: bankRoute,
	contacts: addressBooksRoute,
	accountingDocuments: accountingDocumentsRoute,
	emptyDashboard: emptyDashboardRoute,
	triviStore: triviStoreRoute,
}

// In this array is defined, how the main route is detected
// First route with permission is rendered
export const MAIN_ROUTE_SECTION_PRIORITY: Array<NavigationSection> = [
	'internalDashboard',
	'emptyDashboard',
	'dashboard',
	'accountingDocuments',
	'documents',
	'cashRegisters',
	'bankAccounts',
	'contacts',
	'settings',
	'tasks',
	'triviStore',
]

export const COMPACT_NAVIGATION_ROUTES: Array<string> = [
	'/internal-dashboard',
	'/internal-dashboard/users',
	'/internal-dashboard/billing',
	'/:organizationId/accounting-documents/:id/edit',
	'/:organizationId/accounting-documents/:id/detail',
	'/:organizationId/accounting-documents/:id/preview',
]

export const EMPTY_DASHBOARD: NavigationItem = {
	id: 'emptyDashboard',
	routeFn: emptyDashboardRoute,
	label: 'navigation.dashboard',
	icon: MenuDashboardIcon,
}

export const DASHBOARD: NavigationItem = {
	id: 'dashboard',
	routeFn: dashboardRoute,
	label: 'navigation.dashboard',
	icon: MenuDashboardIcon,
}

export const DASHBOARD_NEW: NavigationItem = {
	id: 'dashboard',
	routeFn: dashboardRoute,
	label: 'navigation.dashboardNew',
	icon: MenuDashboardIcon,
}

export const INTERNAL_DASHBOARD: NavigationItem = {
	id: 'internalDashboard',
	routeFn: internalDashboardRoute,
	label: 'navigation.internalDashboard',
	icon: MenuInternalDashboardIcon,
}

export const ACCOUNTING_DOCUMENT: NavigationItem = {
	id: 'accountingDocument',
	routeFn: accountingDocumentsRoute,
	label: 'navigation.accountingDocument.label',
	icon: MenuReceiptIcon,
}

export const CASH_REGISTER: NavigationItem = {
	id: 'cashRegister',
	routeFn: cashRegistersRoute,
	label: 'navigation.cashRegister',
	icon: MenuCashdeskIcon,
}

export const BANK: NavigationItem = {
	id: 'bank',
	routeFn: bankRoute,
	label: 'navigation.bank',
	icon: MenuBankIcon,
}

export const DOCUMENT: NavigationItem = {
	id: 'document',
	routeFn: rootDocumentsRoute,
	label: 'navigation.document',
	icon: MenuDocumentsIcon,
}

export const ADDRESS_BOOK: NavigationItem = {
	id: 'addressBook',
	routeFn: addressBooksRoute,
	label: 'navigation.addressBook',
	icon: MenuAddressBookIcon,
}

export const TASK: NavigationItem = {
	id: 'task',
	routeFn: tasksRoute,
	label: 'navigation.task',
	icon: MenuTasksIcon,
}

export const SETTINGS: NavigationItem = {
	id: 'settings',
	routeFn: settingsRoute,
	label: 'navigation.settings',
	icon: GearIcon,
}

export const FEATURES: NavigationItem = {
	id: 'features',
	routeFn: featuresRoute,
	label: 'navigation.features',
	icon: PlayIcon,
}

export const TRIVI_STORE: NavigationItem = {
	id: 'triviStore',
	routeFn: triviStoreRoute,
	label: 'navigation.triviStore',
	icon: TriviIcon,
}

export const GOOGLE_APP_LINK = 'https://play.google.com/store/apps/details?id=com.trivi.app'
export const APPLE_APP_LINK = 'https://itunes.apple.com/cz/app/my-trivi/id1359230332'
export const APPLE_BETA_LINK = 'https://testflight.apple.com/join/quSfidWa'
