/* @flow */
/** @jsx jsx */

import { jsx } from '@emotion/core'
import { PureComponent } from 'react'
import type { Signature } from 'types'
import SignatureViewer from './signature-viewer'
import { colors } from 'variables'

type Props = {|
	signature: Signature,
	onClick: Signature => void,
|}

class SignaturePickerItem extends PureComponent<Props> {
	onClick = () => {
		this.props.onClick(this.props.signature)
	}

	render() {
		if (!this.props.signature.fileId) return null

		return (
			<div css={style.root} onClick={this.onClick}>
				<div css={style.signature}>
					<SignatureViewer fileId={this.props.signature.fileId} />
				</div>
			</div>
		)
	}
}

const style = {
	root: {
		border: 0,
		boxSizing: 'border-box',
		cursor: 'pointer',
		outline: 'none',
		zIndex: 1,
		position: 'relative',
		paddingLeft: 16,
		paddingRight: 16,
		minHeight: 140,
		lineHeight: '140px',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		'&:hover': {
			backgroundColor: colors.blueFaded10,
		},
	},
	signature: {
		display: 'block',
		verticalAlign: 'middle',
		width: 'auto',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		cursor: 'pointer',
		background: colors.white,
		'&:hover': {
			opacity: 0.5,
			zIndex: 100,
		},
	},
}

export default SignaturePickerItem
