/* @flow */
/** @jsx jsx */

import { type Node } from 'react'
import { jsx } from '@emotion/core'
import Paper from 'components/Paper'

export type Props = {|
	children: Node,
	header: string,
	subHeadline?: string,
	rightHeader?: Node,
|}

export default function Section(props: Props) {
	return (
		<Paper css={styles.root} zDepth={2}>
			<div css={styles.header}>
				<div>
					<h2 css={styles.headline}>{props.header}</h2>
					{props.subHeadline && <h4 css={styles.subHeadline}>{props.subHeadline}</h4>}
				</div>
				<div>{props.rightHeader}</div>
			</div>
			{props.children}
		</Paper>
	)
}

const styles = {
	root: {
		marginTop: 10,
		marginBottom: 10,
	},
	header: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		padding: 20,
	},
	headline: {
		margin: 0,
		flex: '1 1 100%',
	},
	subHeadline: {
		opacity: 0.8,
		fontSize: 12,
		textAlign: 'left',
		fontWeight: 'normal',
		margin: 0,
	},
}
