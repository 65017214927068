/* @flow */
/** @jsx jsx */

import { Component } from 'react'
import { jsx } from '@emotion/core'
import memoize from 'memoize-one'
import { PAYMENT_TYPES } from 'types/convertor'
import type { Enum, EnumItem, Enumeration, Scan, ScanFile, AccountingDocumentCategory } from 'types'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import { autoTestId, passAutoTestId } from 'utils/tests/autotest'
import CashRegisterSelector from 'modules/common/containers/cash-register-selector'
import { getCategoriesOptions } from 'modules/accounting-document/domain/categories'
import { openFile } from 'modules/file/domain'
import UploadScanFilePreview from './upload-scan-file-preview'
import loader from 'assets/images/loader-upload-document.svg'
import compactLoader from 'assets/images/loader-upload-document-compact.svg'
import ActionLinkOff from 'components/svg-icons/action/link-off'
import SelectNext, { type OptionType } from 'components/select-next'
import Delete from 'components/svg-icons/action/delete'
import Checkbox from 'components/checkbox'
import FileIcon from 'components/file-icon'
import IconButton from 'components/icon-button'
import TextField from 'components/TextField'
import Tooltip from 'components/tooltip'
import Button from 'components/button'
import Paper from 'components/Paper'
import { colors } from 'variables'

export type Props = {|
	scan: Scan,
	documentCategories: ?Array<AccountingDocumentCategory>,
	branches: ?Enum,
	projects: ?Enum,
	paymentTypes: ?Enum,
	loading?: boolean,
	error?: boolean,
	compact?: boolean,
	onToggle: (number, boolean) => void,
	onBranchChange: (number, ?string) => void,
	onProjectChange: (number, ?string) => void,
	onPaymentTypeChange: (number, string) => void,
	onCustomerInstructionsChange: (number, string) => void,
	onCashRegisterChange: (number, ?string) => void,
	onCategoryChange: (scanId: number, category: ?string) => void,
	onDelete: (scan: Scan) => void,
	reUploadScan: (scanId: string) => void,
	onFilePreview?: (fileId: string) => void,
	onUnjoin: (scan: Scan) => void,
	...WithTranslateProps,
|}

class UploadScanListItem extends Component<Props> {
	cashEnumItemKey: ?string

	constructor(props: Props) {
		super(props)

		const cashEnumItem =
			this.props.paymentTypes && this.props.paymentTypes.find((paymentType: EnumItem) => paymentType.value === 'Cash')
		this.cashEnumItemKey = cashEnumItem && cashEnumItem.key
	}

	static defaultProps = {
		onToggle: () => {},
		onBranchChange: () => {},
		onProjectChange: () => {},
		onPaymentTypeChange: () => {},
		onCustomerInstructionsChange: () => {},
		onCashRegisterChange: () => {},
		onCategoryChange: () => {},
		reUploadScan: () => {},
	}

	getOptions = (items: Enum) => {
		return items.map((item: EnumItem) => {
			return { value: item.key, label: item.value }
		})
	}

	getDocumentCategoryTitle = (key: string): string => {
		let category: ?AccountingDocumentCategory

		if (this.props.documentCategories) {
			category = this.props.documentCategories.find((item: AccountingDocumentCategory) => {
				return item.id && item.id === key
			})
		}
		return (category && category.name) || ''
	}

	getPaymentTypes = (): ?Enum => {
		const { t, paymentTypes } = this.props

		if (!paymentTypes) {
			return null
		}

		return paymentTypes.map((item: Enumeration) => {
			return {
				key: item.key,
				value: t('accountingDocument.paymentTypes.' + PAYMENT_TYPES[item.key || '']),
			}
		})
	}

	onToggle = (event: SyntheticInputEvent<HTMLInputElement>, value: boolean) => {
		this.props.onToggle(this.props.scan.id, value)
	}

	onBranchChange = (option: ?OptionType) => {
		this.props.onBranchChange(this.props.scan.id, option ? option.value : null)
	}

	onProjectChange = (option: ?OptionType) => {
		this.props.onProjectChange(this.props.scan.id, option ? option.value : null)
	}

	onPaymentTypeChange = (option: OptionType) => {
		const value = option.value
		if (value !== this.cashEnumItemKey && this.props.scan.cashRegisterId) {
			this.props.onCashRegisterChange(this.props.scan.id, '')
		}
		value && this.props.onPaymentTypeChange(this.props.scan.id, value)
	}

	onCustomerInstructionsChange = (event: SyntheticInputEvent<HTMLInputElement>, value: ?string) => {
		this.props.onCustomerInstructionsChange(this.props.scan.id, value || '')
	}

	onCategoryChange = (option: OptionType) => {
		const value = option.value
		this.props.onCategoryChange(this.props.scan.id, value)
	}

	onCashRegisterChange = (value: ?string) => {
		value && this.props.onCashRegisterChange(this.props.scan.id, value)
	}

	createFileOpenHandler(fileId: string, fileName: string) {
		return () => {
			if (this.props.onFilePreview) {
				this.props.onFilePreview(fileId)
			} else {
				openFile(fileId, fileName, 'popupPage')
			}
		}
	}

	onDelete = () => {
		this.props.onDelete(this.props.scan)
	}

	onUnjoin = () => {
		this.props.onUnjoin(this.props.scan)
	}

	renderCompactDom() {
		const { t } = this.props
		const { files } = this.props.scan
		const joined = files.length > 1
		const styles = this.getStyles(this.props)

		return (
			<div css={styles.row}>
				<div css={styles.filename}>
					{joined && <div css={styles.connectedText}>{t('uploadScan.connectedScansName')}</div>}
					{!joined &&
						files.map((file: ScanFile, index: number) => (
							<div css={styles.text} key={index} onClick={this.createFileOpenHandler(file.fileId, file.fileName)}>
								{file.fileName}
							</div>
						))}
				</div>
			</div>
		)
	}

	renderStandardDom() {
		const {
			id,
			files,
			documentCategory,
			branch,
			project,
			paymentType,
			cashRegisterId,
			customerInstructions,
		} = this.props.scan
		const { t, branches, projects, documentCategories, error } = this.props
		const paymentTypes = this.getPaymentTypes()
		const joined = files.length > 1
		const styles = this.getStyles(this.props)

		return (
			<div css={styles.rows}>
				<div css={styles.row}>
					<div css={styles.filename} {...autoTestId('upload-scan-list-item-filename')}>
						{joined && <div css={styles.connectedText}>{t('uploadScan.connectedScansName')}</div>}
						{!joined &&
							files.map((file: ScanFile, index: number) => (
								<div css={styles.text} key={index} onClick={this.createFileOpenHandler(file.fileId, file.fileName)}>
									{file.fileName}
								</div>
							))}
					</div>
					{!error ? (
						<div css={styles.customerInstructions}>
							<Tooltip
								label={customerInstructions}
								disabled={!customerInstructions || customerInstructions.length < 58}
								wrapperStyle={styles.tooltip}
								multiLine
							>
								<TextField
									onChange={this.onCustomerInstructionsChange}
									hintText={t('uploadScan.customerInstructionsHint')}
									value={customerInstructions}
									inline
									fullWidth
									name="instructions"
									autoTestId="upload-instructions"
								/>
							</Tooltip>
						</div>
					) : (
						<Button
							labelText={t('uploadScan.reupload')}
							onClick={() => this.props.reUploadScan(id.toString())} //eslint-disable-line
							transparent
							autoTestId="upload-scan-list-item-reupload"
						/>
					)}
				</div>
				{joined && (
					<div css={styles.joinedRows}>
						{files.map((file: ScanFile) => (
							<div css={styles.itemRow} key={file.fileId}>
								<div css={styles.rowIcon}>
									<UploadScanFilePreview key={file.fileId} fileId={file.fileId}>
										{this.renderFileIcon(file.fileName, file.mimeType)}
									</UploadScanFilePreview>
								</div>
								<div css={styles.rowText}>
									<div
										css={styles.text}
										key={file.fileId}
										onClick={this.createFileOpenHandler(file.fileId, file.fileName)}
									>
										{file.fileName}
									</div>
								</div>
							</div>
						))}
					</div>
				)}

				{!this.props.error && (
					<div css={styles.row}>
						{branches && branches.length > 0 && (
							<div css={styles.select} {...autoTestId('upload-list-item-branch')}>
								<SelectNext
									isClearable
									isSearchable
									label={t('uploadScan.branch')}
									autoTestId="upload-list-item-branch-select"
									options={this.getOptions(branches)}
									onChange={this.onBranchChange}
									value={branch}
									autoWidth
									portal
									inline
								/>
							</div>
						)}

						{projects && projects.length > 0 && (
							<div css={styles.select} {...autoTestId('upload-list-item-project')}>
								<SelectNext
									isClearable
									isSearchable
									label={t('uploadScan.project')}
									autoTestId="upload-list-item-project-select"
									options={this.getOptions(projects)}
									onChange={this.onProjectChange}
									value={project}
									autoWidth
									portal
									inline
								/>
							</div>
						)}

						{paymentTypes && paymentTypes.length > 0 && (
							<div css={styles.select} {...autoTestId('upload-list-item-payment-type')}>
								<SelectNext
									label={t('uploadScan.paymentType')}
									autoTestId="upload-list-item-payment-type-select"
									onChange={this.onPaymentTypeChange}
									options={this.getOptions(paymentTypes)}
									value={paymentType}
									autoWidth
									portal
									inline
								/>
							</div>
						)}
						{paymentType === this.cashEnumItemKey && (
							<div css={styles.select} {...autoTestId('upload-list-item-cash-register')}>
								<CashRegisterSelector
									value={cashRegisterId}
									onChange={this.onCashRegisterChange}
									label={t('uploadScan.cashRegister')}
									autoWidth
									inline
								/>
							</div>
						)}

						<div css={styles.select} {...autoTestId('upload-list-item-category')}>
							<SelectNext
								inline
								portal
								autoWidth
								onChange={this.onCategoryChange}
								value={documentCategory ? documentCategory.toString() : ''}
								autoTestId="upload-list-item-category-select"
								options={[
									{ value: '', label: t('uploadScan.uploadedDocuments') },
									...getCategoriesOptions(documentCategories),
								]}
							/>
						</div>
					</div>
				)}
			</div>
		)
	}

	renderFileIcon(fileName: string, mimeType?: string) {
		return (
			<FileIcon
				mimeType={mimeType}
				large={!this.props.compact}
				size={this.props.compact ? 28 : 32}
				fileName={fileName}
			/>
		)
	}

	renderLoader() {
		if (!this.props.loading) return null
		const styles = this.getStyles(this.props)
		const source = this.props.compact ? compactLoader : loader

		return <img css={styles.loader} src={source} />
	}

	renderContent() {
		const { files } = this.props.scan
		const { compact, error } = this.props
		const joined = files.length > 1
		const styles = this.getStyles(this.props)

		return (
			<div css={styles.container} {...autoTestId('upload-scan-list-item-content')}>
				{!compact && !error && (
					<div css={styles.checkbox}>
						<Checkbox
							autoTestId="upload-scan-list-item-checkobox"
							checked={this.props.scan.checked}
							onCheck={this.onToggle}
						/>
					</div>
				)}
				{!joined && (
					<div css={styles.icon}>
						{files.map((file: ScanFile) => (
							<UploadScanFilePreview key={file.fileId} fileId={file.fileId}>
								{this.renderFileIcon(file.fileName, file.mimeType)}
							</UploadScanFilePreview>
						))}
					</div>
				)}

				{compact ? this.renderCompactDom() : this.renderStandardDom()}

				{joined && (
					<div css={styles.unjoinButton}>
						<IconButton
							autoTestId="upload-list-item-disconnect"
							tooltip={this.props.t('uploadScan.disconnectDocuments')}
							onClick={this.onUnjoin}
							circledWhenHovered
							size={34}
						>
							<ActionLinkOff size={20} />
						</IconButton>
					</div>
				)}

				<div css={styles.deleteButton}>
					<IconButton
						autoTestId="upload-list-item-delete"
						tooltip={this.props.t('uploadScan.deleteTooltip')}
						onClick={this.onDelete}
						circledWhenHovered
						size={34}
					>
						<Delete size={22} />
					</IconButton>
				</div>
			</div>
		)
	}

	render() {
		const { compact } = this.props
		const styles = this.getStyles(this.props)

		return (
			<Paper rounded zDepth={compact ? 2 : 3} css={styles.root} {...passAutoTestId('upload-scan-list-item')}>
				{this.props.loading ? this.renderLoader() : this.renderContent()}
			</Paper>
		)
	}

	getStyles = memoize((props: Props) => {
		const { compact } = props
		const { checked } = props.scan
		const joined = props.scan.files.length > 1

		return {
			root: {
				borderColor: checked ? colors.blue : colors.gray400,
				marginTop: 10,
				marginBottom: 10,
				position: 'relative',
				fontSize: 14,
				overflow: 'hidden',
			},
			loader: {
				display: 'block',
				width: compact ? 320 : 670,
				height: compact ? 42 : 80,
			},
			container: {
				padding: compact ? 0 : 10,
				lineHeight: compact ? '42px' : '24px',
				display: 'flex',
				justifyContent: 'flex-start',
				alignItems: 'center',
				position: 'relative',
				fontSize: 14,
			},
			checkbox: {
				marginRight: joined ? 10 : 0,
				alignSelf: joined ? 'flex-start' : 'center',
				marginTop: joined ? 2 : 0,
			},
			icon: {
				width: 26,
				marginLeft: 15,
				marginRight: compact ? 20 : 35,
				flex: '0 0 26px',
			},
			text: {
				display: 'inline-block',
				cursor: 'pointer',
				lineHeight: compact ? 'inherit' : '24px',
				'&:hover': {
					textDecoration: 'underline',
				},
			},
			connectedText: {
				lineHeight: compact ? 'inherit' : '24px',
			},
			select: {
				marginRight: 20,
				flex: '0 0 auto',
				height: 24,
			},
			filename: {
				flex: '1 1 60%',
			},
			customerInstructions: {
				flex: '1 1 40%',
			},
			row: {
				overflow: 'hidden',
				height: compact ? 42 : 'auto',
				marginTop: compact ? 0 : 6,
				lineHeight: compact ? '42px' : '24px',
				paddingRight: compact ? 20 : 85,
				textOverflow: 'ellipsis',
				whiteSpace: 'nowrap',
				display: 'flex',
				justifyContent: 'flex-start',
				alignItems: 'center',
				flexWrap: 'wrap',
			},
			rows: {
				flex: '1 1 auto',
				minHeight: 60,
				display: 'block',
			},
			joinedRows: {
				marginTop: 5,
				marginBottom: 7,
			},
			rowIcon: {
				marginRight: 20,
			},
			rowText: {
				fontSize: 14,
			},
			itemRow: {
				height: 70,
				border: '2px solid #f2f2f2',
				borderRadius: 2,
				overflow: 'hidden',
				marginTop: -2,
				padding: '0 20px',
				width: '100%',
				display: 'flex',
				justifyContent: 'flex-start',
				alignItems: 'center',
			},
			deleteButton: {
				position: 'absolute',
				top: joined ? 22 : '50%',
				right: joined ? 8 : 24,
				marginTop: -17,
			},
			unjoinButton: {
				position: 'absolute',
				marginTop: -17,
				top: 22,
				right: 48,
			},
			tooltip: {
				width: '100%',
				maxWidth: '100%',
			},
		}
	})
}

export default withTranslate(UploadScanListItem)
