// @flow

import type { Dispatch, State, AccountingDocumentImportAction, Import } from 'types'

import MultiImportDetail from 'modules/accounting-document/components/import/multi-import-detail'
import { connect } from 'react-redux'
import { loadAccountingDocumentImport } from 'modules/accounting-document/actions'

type StateProps = {|
	importDetail: ?Import,
|}

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => {
	return {
		importDetail: state.accountingDocument.imports.details[ownProps.importId],
	}
}

type DispatchProps = {|
	loadImport: () => void,
|}

const mapDispatchToProps = (dispatch: Dispatch<AccountingDocumentImportAction>, ownProps: OwnProps): DispatchProps => {
	return {
		loadImport: () => {
			dispatch(loadAccountingDocumentImport(ownProps.importId))
		},
	}
}

type OwnProps = {|
	importId: string,
|}

export default connect(mapStateToProps, mapDispatchToProps)(MultiImportDetail)
