// @flow

import React, { Component } from 'react'
import type { EmailItem } from 'types'
import { withNotify, withTranslate, type WithTranslateProps, type WithNotifyProps } from 'wrappers'
import { Paper, Snackbar, PopupSection } from 'components'
import CopyToClipboard from 'components/copy-to-clipboard'
import styles from './inbound-emails.css'

type Props = {|
	onClose: () => void,
	items: ?Array<EmailItem>,
	open: boolean,
|}

class InboundEmails extends Component<Props & WithTranslateProps & WithNotifyProps> {
	renderEmail = (email: ?string) => {
		const { t } = this.props

		return (
			<div key={email}>
				<CopyToClipboard
					copyTooltip={t('application.emailCopy')}
					copiedTooltip={t('application.emailCopied')}
					textToCopy={email}
				>
					<span>{email || ''}</span>
				</CopyToClipboard>
			</div>
		)
	}

	renderInboundEmail = (email: EmailItem, index: number) => {
		const addresses = email.emailAddresses || []
		if (addresses.length < 1) return null

		const key = `${email.key || ''}|${index}`
		return (
			<Paper
				className={styles.itemRow}
				key={key}
				rounded={false}
				style={{
					borderRadius: 0,
					boxShadow: '0 2px 2px 0 rgba(0,0,0,0.14)',
					border: 0,
				}}
			>
				<div className={styles.itemRowTitle}>{email.name || ''}</div>
				<div className={styles.itemRowValue}>{addresses.map(this.renderEmail)}</div>
			</Paper>
		)
	}

	render() {
		const { t, items, open } = this.props

		if (items != null) {
			return (
				<PopupSection open={open} onRequestClose={this.props.onClose}>
					<div className={styles.popupInner}>
						<h2 className={styles.popupTitle}>{t('inboundEmail.header')}</h2>
						<div className={styles.warning}>
							<Snackbar open inline showIcon message={t('inboundEmail.warning')} type={'error'} />
						</div>
						{items.map(this.renderInboundEmail)}
					</div>
				</PopupSection>
			)
		} else return null
	}
}

export default withTranslate(withNotify(InboundEmails))
