/* @flow */

import OrganizationCreate from 'modules/organization/containers/organization-create'
import React from 'react'
import { Route } from 'react-router'
import basicLayout from 'layouts/basic-layout'

const CreateComponent = basicLayout()(OrganizationCreate)

const OrganizationRouting = () => {
	return [<Route exact key="/create-organization" path="/create-organization" component={CreateComponent} />]
}

export default OrganizationRouting
