// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".YpskQPq6wiwJPImnwbcKe {\n\tdisplay: block;\n\tfont-size: 16px;\n\tline-height: 20px;\n\tpadding: 2px 15px;\n}\n", "",{"version":3,"sources":["webpack://./modules/accounting-document/components/related-accounting-documents.css"],"names":[],"mappings":"AAAA;CACC,cAAA;CACA,eAAA;CACA,iBAAA;CACA,iBAAA;AACD","sourcesContent":[".item {\n\tdisplay: block;\n\tfont-size: 16px;\n\tline-height: 20px;\n\tpadding: 2px 15px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": "YpskQPq6wiwJPImnwbcKe"
};
export default ___CSS_LOADER_EXPORT___;
