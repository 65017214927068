/* @flow */
/** @jsx jsx */

import { Component, Fragment } from 'react'
import { jsx } from '@emotion/core'
import memoize from 'memoize-one'
import { OSS_MODES } from '../../constants'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import type { AccountingDocument, VatCountryType, I18NextTranslateFn, OssMode, FormFieldErrorContext } from 'types'
import SettingsHeading from './settings-heading'
import SettingsSeparator from './settings-separator'
import { getVatRateTypesArray } from 'types/convertor'
import CurrencyInput from 'components/currency-input'
import RadioButton from 'components/RadioButton/RadioButton'
import RadioButtonGroup from 'components/RadioButton/RadioButtonGroup'
import SelectNext, { type OptionType } from 'components/select-next'
import Snackbar from 'components/snackbar/snackbar'
import Checkbox from 'components/checkbox'
import { colors } from 'variables'
import ExchangeRateInfo from '../../containers/invoice-settings/exchange-rate-info'
import ExchangeRateInput from '../../containers/invoice-elements/exchange-rate-input'
import Tooltip from 'components/tooltip'
import { addSubPath } from 'helpers'

type Props = {|
	...WithTranslateProps,
	accountingDocumentId: string,
	template?: ?AccountingDocument,
	isTemplate: boolean,
	readonly?: boolean,
	currencyId: ?string,
	vatCountryType: ?number,
	exchRate: ?number,
	exchRateDefault: ?number,
	vatExchRate: ?number,
	sadExchRate: ?number,
	creditNoteDelayedVat: boolean,
	isCreditNote?: boolean,
	domesticCurrencyId: ?string,
	isOssEnabled?: boolean,
	ossMode?: ?OssMode,
	ossValue?: ?OssMode,
	onTemplateChange?: (accountingDocument: AccountingDocument) => void,
	onVatCountryTypeChange: (vatCountryType: number) => void,
	onVatExchRateChange: (vatExchRate: ?number) => void,
	onSadExchRateChange: (sadExchRate: ?number) => void,
	enableCreditNoteDelayedVatFlag: (confirmed: boolean) => Promise<*>,
	onOssModeChange: (mode: ?OssMode) => void,
	onBackwardClick: () => void,
	formFieldErrorContext?: FormFieldErrorContext,
|}

const VAT_COUNTRY_TYPES: Array<{ value: number, name: VatCountryType }> = getVatRateTypesArray()

type State = {|
	exchRateValue: ?number,
	vatExchRate: ?number,
	sadExchRate: ?number,
	creditNoteDelayedVat: boolean,
|}

class BookingSettings extends Component<Props, State> {
	state: State

	constructor(props: Props) {
		super(props)
		this.state = {
			exchRateValue: props.exchRate,
			vatExchRate: props.vatExchRate,
			sadExchRate: props.sadExchRate,
			creditNoteDelayedVat: false,
		}
	}

	UNSAFE_componentWillMount() {
		this.setState({ creditNoteDelayedVat: this.props.creditNoteDelayedVat })
	}

	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		const { vatExchRate, sadExchRate } = nextProps
		this.setState({ vatExchRate, sadExchRate })
	}

	onVatCountryTypeChange = (event: Event, vatCountryType: number) => {
		this.props.onVatCountryTypeChange && this.props.onVatCountryTypeChange(vatCountryType)
	}

	onVatExchRateChange = (event: ?SyntheticInputEvent<HTMLInputElement>, vatExchRate: ?number) => {
		this.setState({ vatExchRate })
	}

	onExchRateValueChange = (exchRateValue: ?number) => {
		this.setState({ exchRateValue })
	}

	onVatExchRateBlur = () => {
		const value = this.state.vatExchRate
		const vatExchRate = isNaN(value) ? null : value

		if (this.props.vatExchRate != vatExchRate)
			this.props.onVatExchRateChange && this.props.onVatExchRateChange(vatExchRate)
	}

	onSadExchRateChange = (event: ?SyntheticInputEvent<HTMLInputElement>, sadExchRate: ?number) => {
		this.setState({ sadExchRate })
	}

	onSadExchRateBlur = () => {
		const value = this.state.sadExchRate
		const sadExchRate = isNaN(value) ? null : value

		if (this.props.sadExchRate != sadExchRate)
			this.props.onSadExchRateChange && this.props.onSadExchRateChange(sadExchRate)
	}

	renderExchRates = () => {
		const { t, currencyId, domesticCurrencyId, isTemplate } = this.props

		if (!isTemplate && currencyId && domesticCurrencyId && currencyId === domesticCurrencyId) {
			return null
		}

		const vatRateInput = (
			<CurrencyInput
				labelText={t('invoice.settings.booking.vatExchRateLabel')}
				hintText={t('invoice.settings.booking.vatExchRateHint')}
				onBlur={this.onVatExchRateBlur}
				onChange={this.onVatExchRateChange}
				value={this.state.vatExchRate}
				disabled={this.props.readonly || this.props.isTemplate}
				fullWidth
				name="vatExchRate"
			/>
		)

		const sadRateInput = (
			<CurrencyInput
				labelText={t('invoice.settings.booking.sadExchRateLabel')}
				hintText={t('invoice.settings.booking.sadExchRateHint')}
				onBlur={this.onSadExchRateBlur}
				onChange={this.onSadExchRateChange}
				value={this.state.sadExchRate}
				disabled={this.props.readonly || this.props.isTemplate}
				fullWidth
				name="sadExchRate"
			/>
		)

		const exchRateFormFieldErrorContext = addSubPath(this.props.formFieldErrorContext, 'exchRate')

		return (
			<div>
				<ExchangeRateInfo
					exchRate={this.props.exchRate}
					vatExchRate={this.props.vatExchRate}
					sadExchRate={this.props.sadExchRate}
					exchRateDefault={this.props.exchRateDefault}
					accountingDocumentId={this.props.accountingDocumentId}
					isTemplate={this.props.isTemplate}
					template={this.props.template}
					exchRateValue={this.state.exchRateValue}
				/>
				<div css={styles.item}>
					<div css={styles.row}>
						<div css={styles.columnOneThird}>
							<ExchangeRateInput
								exchRate={this.props.exchRate}
								vatExchRate={this.props.vatExchRate}
								sadExchRate={this.props.sadExchRate}
								exchRateDefault={this.props.exchRateDefault}
								template={this.props.template}
								isTemplate={this.props.isTemplate}
								onTemplateChange={this.props.onTemplateChange}
								changeExchRateValue={this.onExchRateValueChange}
								accountingDocumentId={this.props.accountingDocumentId}
								disabled={this.props.readonly || this.props.isTemplate}
								name="exchRateTextField"
								fullWidth
								formFieldErrorContext={exchRateFormFieldErrorContext}
							/>
						</div>
						<div css={styles.columnOneThird}>
							{this.props.isTemplate ? (
								<Tooltip label={this.props.t('invoice.settings.booking.templateExchRate')}>{vatRateInput}</Tooltip>
							) : (
								vatRateInput
							)}
						</div>
						<div css={styles.columnOneThird}>
							{this.props.isTemplate ? (
								<Tooltip label={this.props.t('invoice.settings.booking.templateExchRate')}>{sadRateInput}</Tooltip>
							) : (
								sadRateInput
							)}
						</div>
					</div>
				</div>
			</div>
		)
	}

	toggleCreditNoteDelayedVatFlag = (event: SyntheticInputEvent<HTMLInputElement>, creditNoteDelayedVat: boolean) => {
		const { enableCreditNoteDelayedVatFlag } = this.props
		if (enableCreditNoteDelayedVatFlag) {
			this.setState({ creditNoteDelayedVat })
			enableCreditNoteDelayedVatFlag(creditNoteDelayedVat).then((action?: { success: boolean }) => {
				if (action && !action.success) {
					this.setState({ creditNoteDelayedVat: !creditNoteDelayedVat })
				}
			})
		}
	}

	onOssModeChange = (option: OptionType) => {
		// TODO: SelectNext - Generic types
		const mode = ((option.value: any): OssMode)
		this.props.onOssModeChange(mode)
	}

	getOssOptions = memoize((t: I18NextTranslateFn) => {
		return OSS_MODES.map((mode: ?string) => ({
			value: mode,
			label: t(`invoice.settings.booking.oss.modes.${mode || 'auto'}`),
		}))
	})

	render() {
		const { t, readonly, isCreditNote } = this.props
		const { creditNoteDelayedVat } = this.state

		return (
			<div css={styles.root}>
				<SettingsHeading onGoBack={this.props.onBackwardClick} text={t('invoice.settings.booking.headline')} />
				{!this.props.isTemplate && this.props.isOssEnabled && (
					<Fragment>
						<SettingsSeparator text={t('invoice.settings.booking.oss.headline')} />
						<div css={styles.item}>
							<SelectNext value={this.props.ossMode} options={this.getOssOptions(t)} onChange={this.onOssModeChange} />
							<div css={styles.ossValue}>
								{!this.props.ossMode &&
									!!this.props.ossValue &&
									t(`invoice.settings.booking.oss.values.${this.props.ossValue}`)}
							</div>
						</div>
					</Fragment>
				)}
				{this.props.isTemplate && 2 === this.props.vatCountryType && this.props.isOssEnabled && (
					<Fragment>
						<SettingsSeparator text={t('invoice.settings.booking.oss.headline')} />
						<div css={styles.item}>
							<Snackbar open inline showIcon message={t('invoice.settings.booking.oss.template')} type="info" />
						</div>
					</Fragment>
				)}
				<SettingsSeparator text={t('invoice.settings.booking.priceSettingsHeadline')} />
				<div css={styles.item}>
					<RadioButtonGroup
						name="vatCountryType"
						onChange={this.onVatCountryTypeChange}
						valueSelected={this.props.vatCountryType}
						label={t('invoice.settings.booking.vatCountryHeadline')}
					>
						{VAT_COUNTRY_TYPES.map((item: any) => {
							return (
								<RadioButton
									disabled={readonly}
									key={item.value}
									value={item.value}
									label={t(`invoice.settings.booking.vatCountryTypes.${item.name}`)}
								/>
							)
						})}
					</RadioButtonGroup>
				</div>
				{this.renderExchRates()}
				{isCreditNote && (
					<div>
						<SettingsSeparator />
						<div css={styles.item}>
							<Checkbox
								checked={creditNoteDelayedVat}
								label={t('invoice.settings.booking.creditNoteConfirmationLabel')}
								onCheck={this.toggleCreditNoteDelayedVatFlag}
							/>
						</div>
					</div>
				)}
			</div>
		)
	}
}

const styles = {
	root: {
		fontSize: 14,
		paddingRight: 10,
	},
	item: {
		minHeight: 70,
		padding: '12px 0',
	},
	row: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'flex-start',
	},
	columnOneThird: {
		flex: '0 0 30%',
	},
	defaultRateIsUsed: {
		color: '#29BB4D',
	},
	ossValue: {
		marginTop: 8,
		color: colors.blackFaded60,
	},
}

export default withTranslate(BookingSettings)
