/* @flow */

import { connect } from 'react-redux'
import type {
	AccountingDocumentReduxAction,
	AccountingDocument,
	AccountingDocumentType,
	Dispatch,
	State,
	FormFieldErrorContext,
} from 'types'
import {
	setAccountingDocumentExplicitNo,
	setAccountingDocumentExternalNo,
	setAccountingDocumentSequenceId,
} from 'modules/accounting-document/actions'
import { getAccountingDocument } from 'modules/accounting-document/selectors'
import InvoiceHeaderNo from 'modules/accounting-document/containers/invoice-elements/header-no'
import type { ValidationMessageType, ValidationValueType } from 'wrappers/validate'

type StateProps = {|
	sequenceId: ?string,
	explicitNo: ?string,
	externalNo: ?string,
	accountingDocumentNo: ?string,
	accountingDocumentNoPreview: ?string,
	cashRegisterId: ?string,
|}

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => {
	const doc: ?AccountingDocument = getAccountingDocument(state, ownProps.accountingDocumentId)
	return {
		sequenceId: doc && doc.sequenceId,
		explicitNo: doc && doc.explicitNo,
		externalNo: doc && doc.externalNo,
		accountingDocumentNo: doc && doc.accountingDocumentNo,
		accountingDocumentNoPreview: doc && doc.accountingDocumentNoPreview,
		cashRegisterId: doc && doc.cashRegisterId,
	}
}

type DispatchProps = {|
	onExplicitNoChange: (explicitNo: string) => void,
	onSequenceIdChange: (sequenceId: string) => void,
	onExternalNoChange: (externalNo: string) => void,
|}

const mapDispatchToProps = (dispatch: Dispatch<AccountingDocumentReduxAction>, ownProps: OwnProps): DispatchProps => {
	return {
		onExplicitNoChange: (explicitNo: string) => {
			dispatch(setAccountingDocumentExplicitNo(ownProps.accountingDocumentId, explicitNo))
		},
		onSequenceIdChange: (sequenceId: string) => {
			dispatch(setAccountingDocumentSequenceId(ownProps.accountingDocumentId, sequenceId))
		},
		onExternalNoChange: (externalNo: string) => {
			dispatch(setAccountingDocumentExternalNo(ownProps.accountingDocumentId, externalNo))
		},
	}
}

type OwnProps = {|
	accountingDocumentId: string,
	direction: 'issued' | 'received',
	type: AccountingDocumentType,
	readonly?: boolean,
	disableExplicitNo?: boolean,
	validationMessage?: ValidationMessageType,
	validationValue?: ValidationValueType,
	formFieldErrorContext?: FormFieldErrorContext,
|}

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceHeaderNo)
