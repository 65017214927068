/* @flow */
/** @jsx jsx */

import { Component } from 'react'
import { jsx } from '@emotion/core'
import type { AresContact } from 'types'
import { withTranslate, withNotify, type WithTranslateProps, type WithNotifyProps } from 'wrappers'
import SearchIcon from 'components/svg-icons/action/search'
import IconButton from 'components/icon-button'
import TextField from 'components/TextField'
import colors from 'variables/colors.css'

export type Props = {|
	onSearch: (companyId: string) => void,
	onSelect: (contact: AresContact) => void,
	contacts: ?Array<AresContact>,
	loading: boolean,
	search?: ?string,
|}

type ComponentProps = {|
	...Props,
	...WithTranslateProps,
	...WithNotifyProps,
|}

type State = {|
	companyRegNo: string,
|}

class ContactAres extends Component<ComponentProps, State> {
	input: ?TextField = null

	state = {
		companyRegNo: '',
	}

	componentWillMount() {
		if (this.props.search) {
			this.setState(
				{
					companyRegNo: this.props.search,
				},
				() => {
					this.props.onSearch(this.state.companyRegNo)
				},
			)
		}
	}

	componentDidUpdate(prevProps: ComponentProps) {
		if (prevProps.loading && !this.props.loading && !this.props.contacts) {
			this.props.notify(this.props.t('invoice.contact.regNoError'), 'error')
			this.focusTextField()
		}

		// Automatically submit the first contact found (no UI interaction)
		if (this.props.contacts !== prevProps.contacts && this.props.contacts && this.props.contacts.length > 0) {
			this.onSelect(this.props.contacts[0])
		}
	}

	onSelect = (contact: AresContact) => {
		this.props.onSelect(contact)
		this.setState({ companyRegNo: '' })
	}

	bindInput = (element: ?TextField) => {
		this.input = element
	}

	focusTextField = () => {
		// $FlowFixMe
		this.input && this.input.getWrappedInstance().focus()
	}

	render() {
		const { t } = this.props

		return (
			<div css={styles.root}>
				<div css={styles.icon}>
					<IconButton
						autoTestId="contact-ares-search"
						disabled={!this.state.companyRegNo}
						tooltip={this.props.t('application.search')}
						onClick={this.onSearch}
						color={colors.blackFaded60}
					>
						<SearchIcon />
					</IconButton>
				</div>
				<div css={styles.field}>
					<TextField
						ref={this.bindInput}
						hintText={t('invoice.contact.aresHint')}
						value={this.state.companyRegNo}
						onChange={this.onChange}
						disabled={this.props.loading}
						onKeyEnter={this.onSearch}
						autoWidth
						inline
						name="ares"
						autoTestId="contact-ares-input"
					/>
				</div>
			</div>
		)
	}

	onChange = (e: Event, companyRegNo: string) => {
		this.setState({ companyRegNo })
	}

	onSearch = () => {
		this.props.onSearch(this.state.companyRegNo.replace(/\s/g, ''))
	}
}

const styles = {
	root: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-start',
	},
	icon: {
		marginRight: 5,
	},
	field: {
		fontSize: 24,
	},
}

export default withNotify(withTranslate(ContactAres))
