// @flow

import type { AccountingDocumentImportAction, Import, UploadScanAction } from 'types'
import {
	accountingDocumentImports as accountingDocumentImportsApi,
	accountingDocumentImport as accountingDocumentImportApi,
	accountingDocumentImportProcess as accountingDocumentImportProcessApi,
} from 'modules/common/models/api-model'

export function createAccountingDocumentImport(importObject: Import) {
	return async (dispatch: Dispatch<AccountingDocumentImportAction | UploadScanAction>) => {
		dispatch({
			type: 'START_CREATING_ACCOUNTING_DOCUMENT_IMPORT',
		})
		try {
			// createImport
			let resp: Import = await accountingDocumentImportsApi.post({}, importObject)
			// process import
			if (resp.id) {
				resp = await accountingDocumentImportProcessApi.post({ importId: resp.id })
			}

			dispatch({ type: 'CLEAR_SCANS' })
			return dispatch({
				type: 'FINISH_CREATING_ACCOUNTING_DOCUMENT_IMPORT',
				import: resp,
			})
		} catch (serverError) {
			return dispatch({
				type: 'FINISH_CREATING_ACCOUNTING_DOCUMENT_IMPORT',
				serverError,
			})
		}
	}
}

export function loadAccountingDocumentImport(importId: string) {
	return async (dispatch: Dispatch<AccountingDocumentImportAction>) => {
		dispatch({
			type: 'START_LOADING_ACCOUNTING_DOCUMENT_IMPORT',
		})
		try {
			const resp: Import = await accountingDocumentImportApi.get({ importId })
			return dispatch({
				type: 'FINISH_LOADING_ACCOUNTING_DOCUMENT_IMPORT',
				import: resp,
			})
		} catch (serverError) {
			return dispatch({
				type: 'FINISH_LOADING_ACCOUNTING_DOCUMENT_IMPORT',
				serverError,
			})
		}
	}
}
