/* @flow */

import type {
	AccountingDocument,
	AccountingDocumentDirection,
	AccountingDocumentLineItem,
	AccountingDocumentType,
	State,
	FinancialAccount,
	Dispatch,
	OrganizationAction,
	FormFieldErrorContext,
} from 'types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { emptyAccountingDocument, emptyAccountingDocumentLineItem } from 'types/empty'
import {
	autocalculateLineItem,
	calculateTotals,
	updateTotalsOnAccountingDocument,
} from 'modules/accounting-document/domain/templates'
import { getFinancialAccounts } from 'modules/organization/selectors'
import { generateKeyFromFinancialAccountsGetParams } from 'modules/organization/utils'
import { AccountingDocumentType_Number, AccountingDocumentDirection_Number } from 'types/convertor'
import { loadFinancialAccounts } from 'modules/organization/actions'
import withAutoload from 'wrappers/with-autoload'
import type { ValidationMessageType, ValidationValueType } from 'wrappers/validate'
import { EMPTY_ARRAY } from 'trivi-constants'
import LineItems from '../invoice-elements/line-items'

export type Props = {|
	accountingDocument: AccountingDocument,
	onChange: (accountingDocument: AccountingDocument) => void,
	direction: AccountingDocumentDirection,
	type: AccountingDocumentType,
	isOrganizationVatFree: boolean,
	financialAccounts: Array<FinancialAccount>,
	loadFinancialAccounts: (type: AccountingDocumentType, direction: AccountingDocumentDirection) => void,
	validationMessage?: ValidationMessageType,
	validationValue?: ValidationValueType,
	formFieldErrorContext?: FormFieldErrorContext,
|}

class LineItemsInterface extends Component<Props> {
	componentWillReceiveProps = (nextProps: Props) => {
		const { type: nextType, direction: nextDirection } = nextProps
		const { type, direction } = this.props
		if (nextType !== type || nextDirection !== direction) {
			this.props.loadFinancialAccounts(nextType, nextDirection)
		}
	}

	onLineItemCreate = () => {
		const currentLineItems = this.props.accountingDocument.lineItems || []
		let emptyLineItem: AccountingDocumentLineItem = emptyAccountingDocumentLineItem()
		const newLineItems = currentLineItems.concat(emptyLineItem)
		const newDocument: AccountingDocument = Object.assign(emptyAccountingDocument(), this.props.accountingDocument, {
			lineItems: newLineItems,
		})
		const totals = calculateTotals(newLineItems)
		this.props.onChange(updateTotalsOnAccountingDocument(newDocument, totals))
	}

	onLineItemRemove = (lineItem: AccountingDocumentLineItem, index: number) => {
		let newLineItems = [...(this.props.accountingDocument.lineItems || [])]
		newLineItems.splice(index, 1)
		const newDocument: AccountingDocument = Object.assign(emptyAccountingDocument(), this.props.accountingDocument, {
			lineItems: newLineItems,
		})
		const totals = calculateTotals(newLineItems)
		this.props.onChange(updateTotalsOnAccountingDocument(newDocument, totals))
	}

	onLineItemClone = (lineItem: AccountingDocumentLineItem) => {
		const duplicate: AccountingDocumentLineItem = Object.assign(emptyAccountingDocumentLineItem(), lineItem)
		const newLineItems = (this.props.accountingDocument.lineItems || []).concat(duplicate)
		const newDocument: AccountingDocument = Object.assign(emptyAccountingDocument(), this.props.accountingDocument, {
			lineItems: newLineItems,
		})
		const totals = calculateTotals(newLineItems)
		this.props.onChange(updateTotalsOnAccountingDocument(newDocument, totals))
	}

	onLineItemChange = (changedLineItem: AccountingDocumentLineItem, autocalc: boolean, index: number): Promise<any> => {
		// const originalLineItem: AccountingDocumentLineItem =
		// 	(this.props.accountingDocument.lineItems && this.props.accountingDocument.lineItems[index]) ||
		// 	emptyAccountingDocumentLineItem()
		let newLineItems = [...(this.props.accountingDocument.lineItems || [])]

		newLineItems[index] = autocalc ? autocalculateLineItem(changedLineItem) : changedLineItem

		const totals = calculateTotals(newLineItems)

		const newDocument: AccountingDocument = Object.assign(emptyAccountingDocument(), this.props.accountingDocument, {
			lineItems: newLineItems,
		})
		this.props.onChange(updateTotalsOnAccountingDocument(newDocument, totals))
		return new Promise(() => {})
	}

	onLineItemsHeaderChange = (lineItemsHeader: ?string) => {
		const newDocument: AccountingDocument = Object.assign(emptyAccountingDocument(), this.props.accountingDocument, {
			printing: {
				...this.props.accountingDocument.printing,
				lineItemsHeader: lineItemsHeader || '',
			},
		})
		this.props.onChange(newDocument)
	}

	render() {
		return (
			<LineItems
				readonly={false}
				lineItemsProcessing={false}
				direction={this.props.direction}
				type={this.props.type}
				items={this.props.accountingDocument.lineItems || EMPTY_ARRAY}
				onLineItemCreate={this.onLineItemCreate}
				onLineItemRemove={this.onLineItemRemove}
				onLineItemClone={this.onLineItemClone}
				onLineItemChange={this.onLineItemChange}
				calculatingLineItemIds={EMPTY_ARRAY}
				currency={this.props.accountingDocument.currency}
				language={this.props.accountingDocument.language}
				onLineItemsHeaderChange={this.onLineItemsHeaderChange}
				lineItemsHeader={
					this.props.accountingDocument.printing && this.props.accountingDocument.printing.lineItemsHeader
				}
				accountingDocumentId={this.props.accountingDocument.id || ''}
				accountingDocument={this.props.accountingDocument}
				financialAccounts={this.props.financialAccounts}
				greenboxSuggestion={null}
				isAccDocVatFree={this.props.isOrganizationVatFree}
				validationMessage={this.props.validationMessage}
				validationValue={this.props.validationValue}
				formFieldErrorContext={this.props.formFieldErrorContext}
			/>
		)
	}
}

type OwnProps = {|
	formFieldErrorContext?: FormFieldErrorContext,
	accountingDocument: AccountingDocument,
	onChange: (accountingDocument: AccountingDocument) => void,
	direction: AccountingDocumentDirection,
	type: AccountingDocumentType,
	isOrganizationVatFree: boolean,
	validationMessage?: ValidationMessageType,
	validationValue?: ValidationValueType,
|}

type StateProps = {|
	financialAccounts: Array<FinancialAccount>,
|}

function mapStateToProps(state: State, ownProps: OwnProps): StateProps {
	const financialAccountsKey = generateKeyFromFinancialAccountsGetParams({
		accountingDocumentType: AccountingDocumentType_Number(ownProps.type),
		accountingDocumentDirection: AccountingDocumentDirection_Number(ownProps.direction),
	})
	return {
		financialAccounts: getFinancialAccounts(state, financialAccountsKey) || [],
	}
}

type DispatchProps = {|
	autoload: () => void,
	loadFinancialAccounts: (type: AccountingDocumentType, direction: AccountingDocumentDirection) => void,
|}

function mapDispatchToProps(dispatch: Dispatch<OrganizationAction>, ownProps: OwnProps): DispatchProps {
	return {
		loadFinancialAccounts: (type: AccountingDocumentType, direction: AccountingDocumentDirection) => {
			dispatch(
				loadFinancialAccounts({
					accountingDocumentType: AccountingDocumentType_Number(type),
					accountingDocumentDirection: AccountingDocumentDirection_Number(direction),
				}),
			)
		},
		autoload: () => {
			dispatch(
				loadFinancialAccounts({
					accountingDocumentType: AccountingDocumentType_Number(ownProps.type),
					accountingDocumentDirection: AccountingDocumentDirection_Number(ownProps.direction),
				}),
			)
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(withAutoload(LineItemsInterface))
