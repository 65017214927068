// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[3].use[1]!../../../../../node_modules/postcss-loader/dist/cjs.js!../../../../variables/colors.css";
import ___CSS_LOADER_ICSS_IMPORT_1___ from "-!../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[3].use[1]!../../../../../node_modules/postcss-loader/dist/cjs.js!../../../../variables/grid.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_1___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3K7jfXAaUWpmZhEbqihIJH {\n  font-size: 10px;\n  color: #333;\n  line-height: 14px;\n}\n\n._3K7jfXAaUWpmZhEbqihIJH:before {\n\tcontent: '';\n\tdisplay: table;\n}\n\n._3K7jfXAaUWpmZhEbqihIJH:after {\n\tcontent: '';\n\tdisplay: table;\n\tclear: both;\n}\n\n._2c5HHLC21-Two4A5NiKons {\n  padding: 9px 15px 5px 15px;\n}\n\n._29aPdAKU7DrqZWo5H8CWe1 {\n  border-right: 2px solid " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["grey200"] + ";\n}\n\n._2kRcJUP3Or08wDKq78jGTu {\n}\n", "",{"version":3,"sources":["webpack://./modules/accounting-document/components/invoice-elements/header-dates.css"],"names":[],"mappings":"AAEA;EACE,eAAA;EACA,WAAA;EACA,iBAAA;AAOF;;AAJA;CACC,WAAA;CACA,cAAA;AAOD;;AAJA;CACC,WAAA;CACA,cAAA;CACA,WAAA;AAOD;;AAJA;EAEE,0BAAA;AAOF;;AAJA;EAEE,oEAAA;AAOF;;AAJA;AAQA","sourcesContent":["@value grey200 from \"variables/colors.css\";\n\n.dates {\n  font-size: 10px;\n  color: #333;\n  line-height: 14px;\n}\n\n.dates:before {\n\tcontent: '';\n\tdisplay: table;\n}\n\n.dates:after {\n\tcontent: '';\n\tdisplay: table;\n\tclear: both;\n}\n\n.date {\n  composes: columnOneHalfGutterless from 'variables/grid.css';\n  padding: 9px 15px 5px 15px;\n}\n\n.leftDate {\n  composes: date;\n  border-right: 2px solid grey200;\n}\n\n.rightDate {\n  composes: date;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"grey200": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["grey200"] + "",
	"dates": "_3K7jfXAaUWpmZhEbqihIJH",
	"date": "_2c5HHLC21-Two4A5NiKons " + ___CSS_LOADER_ICSS_IMPORT_1___.locals["columnOneHalfGutterless"] + "",
	"leftDate": "_29aPdAKU7DrqZWo5H8CWe1 _2c5HHLC21-Two4A5NiKons " + ___CSS_LOADER_ICSS_IMPORT_1___.locals["columnOneHalfGutterless"] + "",
	"rightDate": "_2kRcJUP3Or08wDKq78jGTu _2c5HHLC21-Two4A5NiKons " + ___CSS_LOADER_ICSS_IMPORT_1___.locals["columnOneHalfGutterless"] + ""
};
export default ___CSS_LOADER_EXPORT___;
