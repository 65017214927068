import React, { Fragment, Children, Component, cloneElement, createElement, isValidElement } from 'react'
import { autoTestId } from 'utils/tests/autotest'
import PropTypes from 'prop-types'
import TabTemplate from './TabTemplate'
import { colors } from 'variables'

export const TAB_POSITIONS = {
	single: 'single',
	first: 'first',
	middle: 'middle',
	last: 'last',
}

function getStyles(props) {
	return {
		tabItemContainer: {
			width: props.large ? '100%' : 'auto',
			borderBottom: props.large ? `1px solid ${colors.grey300}` : 'none',
			backgroundColor: 'transparent',
			whiteSpace: 'nowrap',
			display: 'flex',
			alignItems: 'flex-end',
			justifyContent: 'flex-start',
		},
	}
}

class Tabs extends Component {
	static propTypes = {
		/**
		 * Should be used to pass `Tab` components.
		 */
		children: PropTypes.node,
		/**
		 * The css class name of the root element.
		 */
		className: PropTypes.string,
		/**
		 * If true, large tabs will be rendered.
		 */
		large: PropTypes.bool,
		/**
		 * If true, narrow tabs will be rendered.
		 */
		shrink: PropTypes.bool,
		/**
		 * Specify initial visible tab index.
		 * If `initialSelectedIndex` is set but larger than the total amount of specified tabs,
		 * `initialSelectedIndex` will revert back to default.
		 * If `initialSelectedIndex` is set to any negative value, no tab will be selected intially.
		 */
		initialSelectedIndex: PropTypes.number,
		/**
		 * Called when the selected value change.
		 */
		onChange: PropTypes.func,
		/**
		 * Override the inline-styles of the root element.
		 */
		style: PropTypes.object,
		/**
		 * Override the inline-styles of the tab-labels container.
		 */
		tabItemContainerStyle: PropTypes.object,
		tabContentStyle: PropTypes.object,
		/**
		 * Makes Tabs controllable and selects the tab whose value prop matches this prop.
		 */
		value: PropTypes.any, // eslint-disable-line
		tailContent: PropTypes.any, // eslint-disable-line
	}

	static defaultProps = {
		initialSelectedIndex: 0,
		onChange: () => {},
	}

	state = { selectedIndex: 0 }

	UNSAFE_componentWillMount() {
		const valueLink = this.getValueLink(this.props)
		const initialIndex = this.props.initialSelectedIndex

		this.setState({
			selectedIndex:
				valueLink.value !== undefined
					? this.getSelectedIndex(this.props)
					: initialIndex < this.getTabCount()
					? initialIndex
					: 0,
		})
	}

	UNSAFE_componentWillReceiveProps(newProps) {
		const valueLink = this.getValueLink(newProps)
		const newState = {}

		if (valueLink.value !== undefined) {
			newState.selectedIndex = this.getSelectedIndex(newProps)
		}

		this.setState(newState)
	}

	getTabs(props = this.props) {
		const tabs = []

		Children.forEach(props.children, tab => {
			if (isValidElement(tab)) {
				tabs.push(tab)
			}
		})

		return tabs
	}

	getTabCount() {
		return this.getTabs().length
	}

	// Do not use outside of this component, it will be removed once valueLink is deprecated
	getValueLink(props) {
		return (
			props.valueLink || {
				value: props.value,
				requestChange: props.onChange,
			}
		)
	}

	getSelectedIndex(props) {
		const valueLink = this.getValueLink(props)
		let selectedIndex = -1

		this.getTabs(props).forEach((tab, index) => {
			if (valueLink.value === tab.props.value) {
				selectedIndex = index
			}
		})

		return selectedIndex
	}

	handleTabClick = (value, event, tab) => {
		const valueLink = this.getValueLink(this.props)
		const index = tab.props.index

		if ((valueLink.value && valueLink.value !== value) || this.state.selectedIndex !== index) {
			valueLink.requestChange(value, event, tab)
		}

		this.setState({ selectedIndex: index })
		tab.props.onActive && tab.props.onActive(tab)
	}

	getSelected(tab, index) {
		const valueLink = this.getValueLink(this.props)
		return valueLink.value ? valueLink.value === tab.props.value : this.state.selectedIndex === index
	}

	render() {
		const {
			initialSelectedIndex, // eslint-disable-line no-unused-vars
			onChange, // eslint-disable-line no-unused-vars
			large,
			shrink,
			style,
			tabItemContainerStyle,
			tall,
			tailContent,
			tabContentStyle,
		} = this.props

		const styles = getStyles(this.props)
		const tabContent = []
		const tabCount = this.getTabCount()

		const tabs = this.getTabs().map((tab, index) => {
			tabContent.push(
				tab.props.children
					? createElement(
							TabTemplate,
							{
								key: index,
								selected: this.getSelected(tab, index),
								style: tabContentStyle,
							},
							tab.props.children,
					  )
					: undefined,
			)

			let tabPosition = TAB_POSITIONS.middle
			if (tabCount === 1) tabPosition = TAB_POSITIONS.single
			else {
				if (index === 0) tabPosition = TAB_POSITIONS.first
				else if (index === tabCount - 1) tabPosition = TAB_POSITIONS.last
			}

			return cloneElement(tab, {
				key: index,
				index: index,
				selected: this.getSelected(tab, index),
				large: large,
				tall: tall,
				shrink: shrink,
				onClick: this.handleTabClick,
				positionInTabs: tabPosition,
			})
		})

		return (
			<div className={this.props.className} style={style} {...autoTestId(this.props.autoTestId)}>
				<div style={{ ...styles.tabItemContainer, ...tabItemContainerStyle }}>
					{tabs}
					{tailContent}
				</div>
				<Fragment>{tabContent}</Fragment>
			</div>
		)
	}
}

export default Tabs
