//@flow
import type { DashboardAction } from 'types'

export type State = {
	invitationBannerOpened: boolean,
	sending: boolean,
	selectedEmails: Array<string>,
	buttonDisabled: boolean,
	inputDisabled: boolean,
	invitationDialogOpened: boolean,
}

export const initialState: State = {
	invitationBannerOpened: true,
	sending: false,
	inputDisabled: false,
	buttonDisabled: true,
	invitationDialogOpened: false,
	selectedEmails: [],
}

export default (state: State = initialState, action: DashboardAction): State => {
	switch (action.type) {
		case 'SWITCH_INVITATION_BANNER':
			return {
				...state,
				invitationBannerOpened: action.payload,
			}
		case 'UPDATE_INVITATION_EMAILS':
			return {
				...state,
				selectedEmails: action.payload,
				buttonDisabled: action.payload.length === 0,
			}
		case 'CONFIRM_INVITATION_EMAILS_START':
			return {
				...state,
				sending: true,
				inputDisabled: true,
				buttonDisabled: true,
			}
		case 'CONFIRM_INVITATION_EMAILS_ERROR':
			return {
				...state,
				sending: false,
				inputDisabled: false,
				buttonDisabled: false,
			}
		case 'CONFIRM_INVITATION_EMAILS_SUCCESS':
			return initialState
		case 'SWITCH_INVITATION_DIALOG':
			if (!action.payload) return initialState
			return {
				...state,
				invitationDialogOpened: action.payload,
			}
		case 'RESET_INVITATION_DIALOG':
			return {
				...initialState,
				invitationDialogOpened: state.invitationDialogOpened,
			}
		default:
			return state
	}
}
