// @flow

import { getCurrentUserId } from 'modules/user/selectors'
import type { CashRegisters, CashRegister, State, Permission, AccountingDocumentDirection } from 'types'
import type { CashRegisterState } from 'modules/cash-register/reducers'
import { EMPTY_ARRAY } from 'trivi-constants'
import { createSelector } from 'reselect'
import { AVAILABLE_PERMISSIONS } from '../../../utils/features'
import { userHasAccess } from 'permissions/acl-logic'

export function getCashRegister(state: State, cashRegisterId?: string): ?CashRegister {
	const cashRegisterState: ?CashRegisterState =
		state.cashRegister.cashRegister &&
		state.cashRegister.cashRegister.find(
			(cashRegisterState: CashRegisterState) =>
				(cashRegisterState.data && cashRegisterState.data.id == cashRegisterId) ||
				cashRegisterState.id == cashRegisterId,
		)
	return cashRegisterState && cashRegisterState.data
}

export function findCashRegister(state: State, cashRegisterId: string): ?CashRegister {
	return (
		state.cashRegister &&
		state.cashRegister.cashRegisters &&
		state.cashRegister.cashRegisters.data &&
		state.cashRegister.cashRegisters.data.find((cashRegister: CashRegister) => cashRegister.id == cashRegisterId)
	)
}

export function getCashRegistersCount(state: State): number {
	return (
		state.cashRegister &&
		state.cashRegister.cashRegisters &&
		state.cashRegister.cashRegisters.data &&
		state.cashRegister.cashRegisters.data.length
	)
}

export function getCashRegisters(state: State): CashRegisters {
	return (
		(state.cashRegister && state.cashRegister.cashRegisters && state.cashRegister.cashRegisters.data) || EMPTY_ARRAY
	)
}

export function getCashRegistersLoading(state: State): boolean {
	return state && state.cashRegister && state.cashRegister.cashRegisters && state.cashRegister.cashRegisters.loading
}

export function isMeCashRegisterOwner(state: State, cashRegister: CashRegister | string): boolean {
	return getCurrentUserId(state) === ('string' === typeof cashRegister ? cashRegister : cashRegister.ownerUserId)
}

export function getMakeInventorySaving(state: State): boolean {
	return state.cashRegister.makeInventory.saving
}

export const cashRegistersSelector = createSelector(getCashRegisters, (cashRegisters: CashRegisters) => cashRegisters)

export function isCashRegisterEetActive(state: State, cashRegisterId: ?string): boolean {
	if (!cashRegisterId) {
		return false
	}
	const cashRegisters = cashRegistersSelector(state)
	const cashRegister = getCashRegisterById(cashRegisters, cashRegisterId)
	return (
		(cashRegister &&
			cashRegister.countryVariantSpecific &&
			cashRegister.countryVariantSpecific.cz &&
			cashRegister.countryVariantSpecific.cz.eet &&
			cashRegister.countryVariantSpecific.cz.eet.isActive) ||
		false
	)
}

function getCashRegisterById(cashRegisters: CashRegisters, cashRegisterId: string): ?CashRegister {
	return cashRegisters.find((cashRegister: CashRegister) => cashRegister.id == cashRegisterId)
}

/**
 * If only cashregister permissions allowed, hide Settings.
 * https://triviit.atlassian.net/browse/TESTREP-1144
 *
 * @param {State} state - redux state of application
 * @returns {boolean}
 */
export function hasCashRegisterSettingsAccess(state: State): boolean {
	const serverEnabledPermissions = AVAILABLE_PERMISSIONS.filter((p: Permission) => userHasAccess(state, p))
	return !serverEnabledPermissions.every((p: string) =>
		['seeCashRegisters', 'editCashRegisters', 'seeNonDemoFeatures', 'seeContacts', 'editContacts'].includes(p),
	)
}

export const isMeOwnerOfAnyCashRegisterSelector = createSelector(
	cashRegistersSelector,
	getCurrentUserId,
	(registers: CashRegisters, userId: ?string) =>
		registers.some((cashRegister: CashRegister) => cashRegister.ownerUserId === userId),
)

export function flipAccDocDirection(direction: AccountingDocumentDirection): AccountingDocumentDirection {
	return direction === 'issued' ? 'received' : direction === 'received' ? 'issued' : direction
}
