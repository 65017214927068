/* @flow */

import React, { Component } from 'react'
import EnhancedButton from 'components/internal/EnhancedButton'
import { colors } from 'variables'

export type Props = {|
	children?: any,
	selected: boolean,
	year: number,
	utils: Object,
	onClick?: number => void,
|}

export type State = {|
	hovered: boolean,
|}

function getStyles(props: Props, state: State) {
	const { selected, year, utils } = props
	const { hovered } = state

	return {
		root: {
			boxSizing: 'border-box',
			color: year === utils.getYear(new Date()) && colors.blue,
			display: 'block',
			fontSize: 14,
			margin: '0 auto',
			position: 'relative',
			textAlign: 'center',
			lineHeight: 'inherit',
		},
		label: {
			alignSelf: 'center',
			color: hovered || selected ? colors.blue : colors.black,
			fontSize: selected ? 26 : 17,
			fontWeight: hovered ? 450 : selected ? 500 : 400,
			position: 'relative',
			top: -1,
		},
	}
}

class YearButton extends Component<Props, State> {
	static defaultProps = {
		selected: false,
	}

	state = {
		hovered: false,
	}

	handleMouseEnter = () => {
		this.setState({ hovered: true })
	}

	handleMouseLeave = () => {
		this.setState({ hovered: false })
	}

	handleClick = () => {
		this.props.onClick && this.props.onClick(this.props.year)
	}

	render() {
		const styles = getStyles(this.props, this.state)

		return (
			<EnhancedButton
				onMouseEnter={this.handleMouseEnter}
				onMouseLeave={this.handleMouseLeave}
				onClick={this.handleClick}
				style={styles.root}
			>
				<span style={styles.label}>{this.props.children}</span>
			</EnhancedButton>
		)
	}
}

export default YearButton
