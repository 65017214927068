// @flow

import { createGridMappingKey } from '../utils'
import { dataInitialState } from '../constants'
import type { Action } from '../actions/action-types'
import type { DataTypes, Sort } from '../types'
import { EMPTY_OBJECT } from 'trivi-constants'

export type DataGridAllData = $Exact<$ObjMap<DataTypes, <T>(T) => { [string]: T } | void>>

export type DataGridView = {
	loading: boolean,
	count: number,
	list: Array<string>,
}

export type DataGridViews = { [string]: DataGridView }

export type DataGridState = {|
	currentSort?: Sort,
	currentPage: number,
	currentPageSize: number,
	isFilterOpened?: boolean,
|}

export type State = {|
	data: DataGridAllData, // A map of maps of fetched data, 1st key is dataType, 2nd key is data item id retrieved from server
	view: DataGridViews, // Holds all views on data according to selected filter and ordering
	gridState: {| [string]: DataGridState |}, // Holds grid state to be kept even when leaving and returning to the grid (current sort, page and page size)
|}

export const initialState: State = {
	data: dataInitialState,
	view: EMPTY_OBJECT,
	gridState: EMPTY_OBJECT,
}

export default (state: State = initialState, action: Action): State => {
	switch (action.type) {
		case 'START_LOAD_GRID_DATA': {
			const key = action.dataType
			const viewKey = createGridMappingKey(key, action.sort, action.filter)
			const stateView: DataGridView = { count: 0, list: [], ...state.view[viewKey], loading: true }
			const newState = { ...state }
			newState.view = { ...newState.view }
			newState.view[viewKey] = stateView

			return newState
		}

		case 'FINISH_LOAD_GRID_DATA': {
			const dataArray = action.data
			const key = action.dataType
			const pagesCount = action.pagesCount || 1
			const pageSize = action.pageSize
			const currentPage = action.currentPage
			const viewKey = createGridMappingKey(key, action.sort, action.filter)

			const newState = { ...state }

			const stateData = { ...state.data[key] }
			newState.data = { ...newState.data }
			newState.data[key] = stateData

			const stateView: DataGridView = { count: 0, list: [], ...state.view[viewKey], loading: false }
			const stateViewList: Array<string> = [...stateView.list]
			stateView.list = stateViewList
			newState.view = { ...newState.view }
			newState.view[viewKey] = stateView

			let i = currentPage * pageSize

			dataArray &&
				dataArray.forEach((dataArrayItem: $Values<DataTypes>) => {
					const data = dataArrayItem

					if (data) {
						// $FlowFixMe FinancialAccount doesnt have id
						const id = data.id // TODO: This must be configurable

						if (id) {
							stateData[id] = data
							stateViewList[i] = id
						}

						i++
					}
				})

			if (action.pagesCount) {
				stateView.count = (pagesCount - 1) * pageSize + (dataArray || []).length // This is a hack util server supports exact item count instead of pages count
			} else {
				stateView.count = (dataArray || []).length
			}

			// Remove items at the end of the list when the fresh data has less items then cached data
			if (stateView.list.length > stateView.count) {
				const diff = stateView.list.length - stateView.count
				stateView.list.splice(-diff, diff)
			}

			return newState
		}

		case 'FINISH_LOAD_GRID_LINE': {
			const data = action.data
			const key = action.dataType
			const id = action.id

			const newState = { ...state }

			const stateData = { ...state.data[key] }
			newState.data = { ...newState.data }
			newState.data[key] = stateData

			if (!action.serverError) {
				if (data) {
					stateData[id] = data
				} else {
					delete stateData[id]

					newState.view = { ...newState.view }
					Object.keys(newState.view).forEach((viewKey: string) => {
						if (viewKey.substr(0, key.length + 1) === key + '/') {
							const view = newState.view[viewKey]
							const viewList = view.list

							viewList.forEach((listItem: string, index: number) => {
								if (listItem === id) {
									view.list = [...viewList].splice(index, 1)
									view.count--
								}
							})
						}
					})
				}
			}

			return newState
		}

		case 'GRID_CHANGE_CURRENT_PAGE': {
			const key = action.name

			const newState = { ...state }
			const gridState = { ...state.gridState[key] }
			newState.gridState = { ...state.gridState }
			newState.gridState[key] = gridState

			gridState.currentPage = action.page

			return newState
		}

		case 'GRID_CHANGE_OPENED_FILTER': {
			const key = action.name

			const newState = { ...state }
			const gridState = { ...state.gridState[key] }
			newState.gridState = { ...state.gridState }
			newState.gridState[key] = gridState

			gridState.isFilterOpened = action.isFilterOpened

			return newState
		}

		case 'GRID_CHANGE_PAGE_SIZE': {
			const key = action.name

			const newState = { ...state }
			const gridState = { ...state.gridState[key] }
			newState.gridState = { ...state.gridState }
			newState.gridState[key] = gridState

			gridState.currentPageSize = action.pageSize
			gridState.currentPage = 0

			return newState
		}

		case 'GRID_CHANGE_SORT': {
			const key = action.name

			const newState = { ...state }
			const gridState = { ...state.gridState[key] }
			newState.gridState = { ...state.gridState }
			newState.gridState[key] = gridState

			gridState.currentSort = action.sort

			return newState
		}

		default:
			return state
	}
}
