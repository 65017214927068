// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[3].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js!../../../variables/grid.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1CQ8A0kpW4uRVzevkI2v1T {\n\tposition: relative;\n\tmin-height: 190px;\n\tmargin: 15px 0;\n\tfont-size: 14px;\n\tline-height: 26px;\n}\n\n._2NzMl3KScXM7SONveicbmC {\n\tposition: absolute;\n\tleft: 30px;\n\tright: 30px;\n\ttop: 20px;\n\tbottom: 20px;\n}\n\n._2QBU4DCkE_lVJcXqw2oQqf {\n\tposition: absolute;\n\twidth: 100%;\n\tleft: 0;\n\ttop: 0;\n}\n\n._12YP7xfT6YDlqm-NfZHDkc {\n\tposition: absolute;\n\twidth: 100%;\n\tleft: 0;\n\tbottom: 0;\n\tpadding-left: 28px;\n}\n\n.obIIXx-TX9bKbiGBhyen- {\n\tposition: relative;\n}\n", "",{"version":3,"sources":["webpack://./modules/address-book/components/contact-person-edit.css"],"names":[],"mappings":"AAAA;CAEC,kBAAA;CACA,iBAAA;CACA,cAAA;CACA,eAAA;CACA,iBAAA;AACD;;AAEA;CACC,kBAAA;CACA,UAAA;CACA,WAAA;CACA,SAAA;CACA,YAAA;AACD;;AAEA;CACC,kBAAA;CACA,WAAA;CACA,OAAA;CACA,MAAA;AACD;;AAEA;CACC,kBAAA;CACA,WAAA;CACA,OAAA;CACA,SAAA;CACA,kBAAA;AACD;;AAEA;CACC,kBAAA;AACD","sourcesContent":[".root {\n\tcomposes: columnOneHalf from 'variables/grid.css';\n\tposition: relative;\n\tmin-height: 190px;\n\tmargin: 15px 0;\n\tfont-size: 14px;\n\tline-height: 26px;\n}\n\n.inner {\n\tposition: absolute;\n\tleft: 30px;\n\tright: 30px;\n\ttop: 20px;\n\tbottom: 20px;\n}\n\n.up {\n\tposition: absolute;\n\twidth: 100%;\n\tleft: 0;\n\ttop: 0;\n}\n\n.down {\n\tposition: absolute;\n\twidth: 100%;\n\tleft: 0;\n\tbottom: 0;\n\tpadding-left: 28px;\n}\n\n.iconized {\n\tposition: relative;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "_1CQ8A0kpW4uRVzevkI2v1T " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["columnOneHalf"] + "",
	"inner": "_2NzMl3KScXM7SONveicbmC",
	"up": "_2QBU4DCkE_lVJcXqw2oQqf",
	"down": "_12YP7xfT6YDlqm-NfZHDkc",
	"iconized": "obIIXx-TX9bKbiGBhyen-"
};
export default ___CSS_LOADER_EXPORT___;
