/* @flow */
/** @jsx jsx */

import { Component } from 'react'
import { jsx } from '@emotion/core'
import type { SearchFilter } from 'types'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import OrganizationPicker from 'modules/organization/containers/organization-picker'
import Paper from 'components/Paper'

type Props = {|
	...WithTranslateProps,
	name: string,
	filter?: SearchFilter,
	onChange?: (filter: SearchFilter) => void,
|}

class FilterOrganization extends Component<Props> {
	handleOrganizationChange = (value: ?string): void => {
		let filter: SearchFilter = {
			field: this.props.name,
		}

		if (value !== null) {
			filter.value = value
		}

		this.props.onChange && this.props.onChange(filter)
	}

	render() {
		let { filter } = this.props

		return (
			<Paper zDepth={3} style={style.paper}>
				<div css={style.picker}>
					<OrganizationPicker
						isClearable
						value={filter && filter.value ? filter.value.toString() : null}
						onChange={this.handleOrganizationChange}
						hintText={this.props.t('user.organizationSelect.selectOrganization')}
						autoWidth
						inline
					/>
				</div>
			</Paper>
		)
	}
}

const style = {
	paper: {
		position: 'relative',
		width: 'auto',
		float: 'right',
		borderRadius: '0 20px 20px 0',
		zIndex: 2,
		height: 40,
	},
	picker: {
		padding: '7px 14px 7px 14px',
	},
}

export default withTranslate(FilterOrganization)
