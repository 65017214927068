//@flow

import React, { Component } from 'react'
import { default as _ } from 'lodash-es'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import type { Group } from 'types'
import PermissionPicker from 'modules/organization/containers/permissions-picker'
import Dialog from 'components/dialog'
import Button from 'components/button'
import TextField from 'components/TextField'
import styles from '../settings.css'

type Props = {|
	...WithTranslateProps,
	value: ?Group,
	open: boolean,
	onSave: (group: Group) => ?Promise<void>,
	onClose: () => void,
|}

type State = {
	group: ?Group,
}

class GroupEdit extends Component<Props, State> {
	state: State = this.getStateFromProps(this.props)

	getStateFromProps(props: Props): State {
		if (props.value == null)
			return {
				group: null,
			}

		return {
			group: {
				...props.value,
			},
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		this.setState({
			group: {
				...nextProps.value,
			},
		})
	}

	createGroupFromFields: (fields: Group) => Group = (fields: Group) => {
		if (this.props.value == null)
			return {
				name: fields.name,
				permissions: fields.permissions,
				isDefault: fields.isDefault,
			}
		else
			return {
				id: this.props.value.id,
				name: fields.name,
				permissions: fields.permissions,
				isDefault: fields.isDefault,
			}
	}

	handleOnSave = () => {
		this.props.onSave && this.state.group && this.props.onSave(this.createGroupFromFields(this.state.group))
		this.props.onClose && this.props.onClose()
	}

	onNameChange = (event: SyntheticInputEvent<HTMLInputElement>, value: string) => {
		this.setState({
			group: {
				...this.state.group,
				name: value,
			},
		})
	}

	onToggle = (event: Event, toggled: boolean) => {
		this.setState({
			group: {
				...this.state.group,
				isDefault: toggled,
			},
		})
	}

	isPermissionDisabled = (permission: string): boolean => {
		let hasActiveDependendParent = false
		if (PERMISSIONS_DEPENDENCIES_INVERTED[permission] && this.state.group && this.state.group.permissions) {
			hasActiveDependendParent = !_.isEmpty(
				_.intersection(PERMISSIONS_DEPENDENCIES_INVERTED[permission], this.state.group.permissions),
			)
		}

		return hasActiveDependendParent
	}

	getPermissionTree(permission: string): Array<string> {
		let permissionTree = [permission, ...PERMISSIONS_DEPENDENCIES[permission]]
		let sizeRemovedPermissionTree = 0

		do {
			sizeRemovedPermissionTree = permissionTree.length
			permissionTree.forEach((perm: string) => {
				if (PERMISSIONS_DEPENDENCIES[perm]) {
					permissionTree = _.union(permissionTree, PERMISSIONS_DEPENDENCIES[perm])
				}
			})
		} while (sizeRemovedPermissionTree < permissionTree.length)

		return permissionTree
	}

	getRemovableDependendPermissions(removedPermission: string, currentPermissions: Array<string>): Array<string> {
		const dependendPermissions = PERMISSIONS_DEPENDENCIES[removedPermission] || []
		const toBeRemovedPermissions = []
		const removedPermissionTree = this.getPermissionTree(removedPermission)

		dependendPermissions.forEach((depPerm: string) => {
			const foundParentPermInCurrentPermissions = _.intersection(
				PERMISSIONS_DEPENDENCIES_INVERTED[depPerm],
				currentPermissions,
			)
			const foundParentPermInCurrentPermissionsAndNotToBeRemoved =
				_.difference(foundParentPermInCurrentPermissions, removedPermissionTree).length > 0
			if (!foundParentPermInCurrentPermissionsAndNotToBeRemoved) {
				toBeRemovedPermissions.push(depPerm)
			}
		})

		return toBeRemovedPermissions
	}

	updateDependendPermissions = (
		isAddition: boolean, //add or remove
		oldPermissions: Array<string>,
		newPermissions: Array<string>,
	): Array<string> => {
		const updatedValues = isAddition
			? _.difference(newPermissions, oldPermissions)
			: _.difference(oldPermissions, newPermissions)
		if (updatedValues.length > 0) {
			updatedValues.forEach((item: string) => {
				if (PERMISSIONS_DEPENDENCIES[item]) {
					newPermissions = isAddition
						? _.union(newPermissions, PERMISSIONS_DEPENDENCIES[item])
						: _.without(newPermissions, ...this.getRemovableDependendPermissions(item, newPermissions))
				}
			})
		}
		return newPermissions
	}

	addOrRemoveDependendPermissions = (newPermissions: Array<string>): Array<string> => {
		const permissions = (this.state.group && this.state.group.permissions) || []
		newPermissions =
			permissions.length < newPermissions.length
				? this.updateDependendPermissions(true, permissions, newPermissions)
				: this.updateDependendPermissions(false, permissions, newPermissions)
		return newPermissions
	}

	onPermissionChange = (value: string | Array<string>) => {
		let newPermissions: Array<string> = !Array.isArray(value) ? [value] : value

		newPermissions = this.addOrRemoveDependendPermissions(newPermissions)
		this.setState({
			group: {
				...this.state.group,
				permissions: newPermissions,
			},
		})
	}

	render() {
		const { t, value } = this.props
		const { group } = this.state

		if (!group || !value) return null

		const actions = [
			<Button
				primary
				wide
				key="okButton"
				labelText={t('settings.groups.saveLabel')}
				onClick={this.handleOnSave}
				disabled={!group.name}
				autoTestId="settings-group-dialog-save"
			/>,
			<Button
				secondary
				wide
				key="cancelButton"
				labelText={t('settings.forms.cancelButton')}
				onClick={this.props.onClose}
				autoTestId="settings-group-dialog-cancel"
			/>,
		]

		return (
			<Dialog
				autoTestId="group-edit-dialog"
				title={t('settings.groups.editGroupTitle', { groupName: value.name || '' })}
				actions={actions}
				open
			>
				<div className={styles.oneColumnDialog}>
					<div className={styles.row}>
						<TextField
							fullWidth
							value={group.name}
							onChange={this.onNameChange}
							labelText={t('settings.groups.label.name')}
							autoTestId="settings-groups-label-name"
							autoFocus
							name="name"
						/>
					</div>
					<div className={styles.row}>
						<PermissionPicker
							fullWidth
							value={group.permissions}
							onChange={this.onPermissionChange}
							labelText={t('settings.groups.label.permissions')}
							disableItemFn={this.isPermissionDisabled}
						/>
					</div>
				</div>
			</Dialog>
		)
	}
}

const PERMISSIONS_DEPENDENCIES = {
	touch_cashregister: ['see_contacts', 'touch_contacts'],
	touch_contacts: ['see_contacts'],
}

const PERMISSIONS_DEPENDENCIES_INVERTED = (() => {
	const inverted = {}
	Object.keys(PERMISSIONS_DEPENDENCIES).forEach((parentPerm: string) => {
		PERMISSIONS_DEPENDENCIES[parentPerm].forEach((childPerm: string) =>
			!inverted[childPerm] ? (inverted[childPerm] = [parentPerm]) : inverted[childPerm].push(parentPerm),
		)
	})
	return inverted
})()

export default withTranslate(GroupEdit)
