import React from 'react'
import pure from 'recompose/pure'
import SvgIcon from 'components/SvgIcon'

let Excel = props => {
	props = Object.assign({ viewBox: '-4 -2 24 24' }, props)
	return (
		<SvgIcon {...props}>
			<path
				d="M1.99,0 C0.89,0 0,0.9 0,2 L0.01,18 C0.01,19.1 0.9,20 2,20 L10,20 L16,14 L16,2 C16,0.9 15.1,0 14,0 L1.99,0 Z"
				fill="#34424E"
				transform="translate(8.000000, 10.000000) scale(1, -1) translate(-8.000000, -10.000000) "
			/>
			<path
				d="M12.5374449,13.4091816 L9.96035242,10.3572854 L12.3832599,7.59081836 C12.5594714,7.37125749 12.7797357,6.9760479 12.7797357,6.55888224 C12.7797357,5.74650699 12.1189427,5 11.215859,5 C10.8414097,5 10.4229075,5.08782435 10.0704846,5.52694611 L8,8.0738523 L5.90748899,5.52694611 C5.57709251,5.08782435 5.13656388,5 4.78414097,5 C3.88105727,5 3.19823789,5.74650699 3.19823789,6.55888224 C3.19823789,6.9760479 3.4185022,7.37125749 3.59471366,7.59081836 L6.01762115,10.3792415 L3.44052863,13.4091816 C3.22026432,13.6506986 3,14.0459082 3,14.4411178 C3,15.2315369 3.63876652,16 4.54185022,16 C4.98237885,16 5.40088106,15.8682635 5.68722467,15.49501 L7.97797357,12.5968064 L10.2907489,15.5169661 C10.5770925,15.8902196 10.9955947,16 11.4361233,16 C12.339207,16 13,15.2315369 13,14.4411178 C13,14.0459082 12.7797357,13.6506986 12.5374449,13.4091816 Z"
				fill="#FFFFFF"
			/>
			<polygon
				fill="#52D046"
				transform="translate(13.000000, 3.000000) rotate(-90.000000) translate(-13.000000, -3.000000) "
				points="10 6 10 0 16 0"
			/>
		</SvgIcon>
	)
}
Excel = pure(Excel)

export default Excel
