//@flow

import store from 'store'
import { keys } from 'lodash-es'
import { AVAILABLE_FEATURES, type FeatureId } from './list'
import { getServerEnabledFeatures } from 'modules/features/selectors'
import storage from 'utils/local-storage'
import type { Permission, State } from 'types'
import ACL from '../../app/permissions/acl'

type Feature = {|
	id: FeatureId,
	name: string,
	description: string,
|}

const AVAILABLE_PERMISSIONS = keys(ACL)

const FEATURES_ENABLED_KEY = 'enabledFeatureFlags'
const FEATURES_DISABLED_KEY = 'disabledFeatureFlags'
const FEATURES_VISIBLE_KEY = 'featureFlagsSectionVisible'

const TEST_PERMISSIONS_ENABLED_KEY = 'enabledTestPermissions'
const TEST_PERMISSIONS_DISABLED_KEY = 'disabledTestPermissions'

const addToLocaleStorageArray = (arrayKey: string, value: string) => {
	const currentArray: Array<string> = (storage.get(arrayKey) || '').split(',')
	const newArray: Array<string> = currentArray.concat(value)
	// console.log('addToLocaleStorageArray', { arrayKey, 'newArray.join': newArray.join(',') })
	storage.set(arrayKey, newArray.join(','))
}

const removeFromLocaleStorageArray = (arrayKey: string, value: string) => {
	const currentArray: Array<string> = (storage.get(arrayKey) || '').split(',')
	const newArray: Array<string> = currentArray.filter((id: string) => {
		return id !== value
	})
	// console.log('removeFromLocaleStorageArray', { arrayKey, 'newArray.join': newArray.join(',') })
	storage.set(arrayKey, newArray.join(','))
}

const emptyLocaleStorageArray = (arrayKey: string) => {
	storage.set(arrayKey, [].join(','))
}

const enableFeature = (featureId: FeatureId) => {
	addToLocaleStorageArray(FEATURES_ENABLED_KEY, featureId)
	removeFromLocaleStorageArray(FEATURES_DISABLED_KEY, featureId)
}

const disableFeature = (featureId: FeatureId) => {
	removeFromLocaleStorageArray(FEATURES_ENABLED_KEY, featureId)
	addToLocaleStorageArray(FEATURES_DISABLED_KEY, featureId)
}

const removeFeature = (featureId: FeatureId) => {
	removeFromLocaleStorageArray(FEATURES_ENABLED_KEY, featureId)
	removeFromLocaleStorageArray(FEATURES_DISABLED_KEY, featureId)
}

const enableTestPermission = (permission: Permission) => {
	addToLocaleStorageArray(TEST_PERMISSIONS_ENABLED_KEY, permission)
	removeFromLocaleStorageArray(TEST_PERMISSIONS_DISABLED_KEY, permission)
}

const disableTestPermission = (permission: Permission) => {
	removeFromLocaleStorageArray(TEST_PERMISSIONS_ENABLED_KEY, permission)
	addToLocaleStorageArray(TEST_PERMISSIONS_DISABLED_KEY, permission)
}

const removeTestPermission = (permission: Permission) => {
	removeFromLocaleStorageArray(TEST_PERMISSIONS_ENABLED_KEY, permission)
	removeFromLocaleStorageArray(TEST_PERMISSIONS_DISABLED_KEY, permission)
}

const removeAllTestPermission = () => {
	emptyLocaleStorageArray(TEST_PERMISSIONS_ENABLED_KEY)
	emptyLocaleStorageArray(TEST_PERMISSIONS_DISABLED_KEY)
}

// (JZ:) This function should never be used directly!
// It should be used ony only as a redux selector through withFeatures wrapper.
//
// Using without state argument is depracated - in that case,
// feature flags are loaded from local storage only once when app is started,
// any changes during run are not reflected properly. For example, feature flags
// loaded from backend wouldnt work.
const isEnabled = (id: ?FeatureId, state?: State): boolean => {
	if (!id) {
		return false
	}

	const serverEnabledFeatures: Array<Feature> = getServerEnabledFeatures(state || store.getState())
	const serverEnabled: boolean = serverEnabledFeatures.findIndex((f: Feature) => f.id === id) > -1

	const userEnabledFeatures: Array<string> = (storage.get(FEATURES_ENABLED_KEY) || '').split(',')
	const userEnabled: boolean = userEnabledFeatures.indexOf(id) > -1

	const userDisabledFeatures: Array<string> = (storage.get(FEATURES_DISABLED_KEY) || '').split(',')
	const userDisabled: boolean = userDisabledFeatures.indexOf(id) > -1

	if (userEnabled) {
		return true
	}
	if (userDisabled) {
		return false
	}
	return serverEnabled
}

const getUserEnabledFeatures = (): Array<Feature> => {
	return AVAILABLE_FEATURES.filter((f: Feature) => isEnabled(f.id))
}

const getUserDisabledFeatures = (): Array<Feature> => {
	return AVAILABLE_FEATURES.filter((f: Feature) => isEnabled(f.id))
}

export function canSeeFeatures(): boolean {
	return storage.get(FEATURES_VISIBLE_KEY) === 'true' || false
}

// For usage:
//    import Features from 'utils/features
//    ...
//    Features.isEnabled('xyz')
export default {
	isEnabled,
}

// named exports
export type { FeatureId, Feature }
export {
	// constants
	AVAILABLE_FEATURES,
	FEATURES_VISIBLE_KEY,
	FEATURES_DISABLED_KEY,
	FEATURES_ENABLED_KEY,
	// methods
	enableFeature,
	disableFeature,
	removeFeature,
	isEnabled,
	getUserEnabledFeatures,
	getUserDisabledFeatures,
	// test permissions
	AVAILABLE_PERMISSIONS,
	TEST_PERMISSIONS_ENABLED_KEY,
	TEST_PERMISSIONS_DISABLED_KEY,
	enableTestPermission,
	disableTestPermission,
	removeTestPermission,
	removeAllTestPermission,
}

// Enabling feature menu from console:
window['fíčury'] = () => {
	if (storage.get(FEATURES_VISIBLE_KEY)) {
		storage.remove(FEATURES_VISIBLE_KEY)
		console.log('Fíčury VYPNUTY, reloadni') //eslint-disable-line no-console
	} else {
		storage.set(FEATURES_VISIBLE_KEY, 'true')
		console.log('Fíčury ZAPNUTY, reloadni') //eslint-disable-line no-console
	}
}
// -----
