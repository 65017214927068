/* @flow */

import React, { Component } from 'react'
import ReportDetail from 'modules/dashboard/containers/report/report-detail'
import type { ReportScheme } from 'modules/dashboard/constants/report'
import ReportView from 'modules/dashboard/containers/report/report-view'

type State = {|
	activeReport: ?ReportScheme,
	reportOpen: boolean,
|}

class DashboardPage extends Component<{}, State> {
	state = {
		activeReport: null,
		reportOpen: false,
	}

	handleReportClick = (scheme: ReportScheme) => {
		this.setState({
			activeReport: scheme,
			reportOpen: true,
		})
	}

	handleDetailClose = () => {
		this.setState({
			activeReport: null,
			reportOpen: false,
		})
	}

	render() {
		return (
			<div>
				<ReportView onReportClick={this.handleReportClick} />

				{this.state.reportOpen && (
					<ReportDetail
						open={this.state.reportOpen}
						reportScheme={this.state.activeReport}
						onRequestClose={this.handleDetailClose}
					/>
				)}
			</div>
		)
	}
}

export default DashboardPage
