// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[3].use[1]!../../../../../../node_modules/postcss-loader/dist/cjs.js!../../../../../variables/colors.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".mnTHywrK23SYWpw7m873w {\n\ttext-align: left;\n\tfont-weight: normal;\n\tmargin: 10px 8px;\n\tborder-collapse: collapse;\n}\n\n.mnTHywrK23SYWpw7m873w tr {\n\theight: 20px;\n\tline-height: 20px;\n\tborder: none;\n\tpadding: 0;\n}\n\n.mnTHywrK23SYWpw7m873w th {\n\tfont-size: 12px;\n\tfont-weight: normal;\n\tpadding: 0 12px;\n}\n\n.mnTHywrK23SYWpw7m873w td {\n\tpadding: 0 12px;\n}\n\n.mnTHywrK23SYWpw7m873w th:first-child, .mnTHywrK23SYWpw7m873w td:first-child {\n\tborder-right: 1px solid " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["whiteFaded10"] + ";\n}\n", "",{"version":3,"sources":["webpack://./modules/common/components/dialogs/payment-dialog/bank-order-info.css"],"names":[],"mappings":"AAEA;CACC,gBAAA;CACA,mBAAA;CACA,gBAAA;CACA,yBAAA;AAOD;;AAJA;CACC,YAAA;CACA,iBAAA;CACA,YAAA;CACA,UAAA;AAOD;;AAJA;CACC,eAAA;CACA,mBAAA;CACA,eAAA;AAOD;;AAJA;CACC,eAAA;AAOD;;AAJA;CACC,oEAAA;AAOD","sourcesContent":["@value whiteFaded10 from \"variables/colors.css\";\n\n.tooltipTable {\n\ttext-align: left;\n\tfont-weight: normal;\n\tmargin: 10px 8px;\n\tborder-collapse: collapse;\n}\n\n.tooltipTable tr {\n\theight: 20px;\n\tline-height: 20px;\n\tborder: none;\n\tpadding: 0;\n}\n\n.tooltipTable th {\n\tfont-size: 12px;\n\tfont-weight: normal;\n\tpadding: 0 12px;\n}\n\n.tooltipTable td {\n\tpadding: 0 12px;\n}\n\n.tooltipTable th:first-child, .tooltipTable td:first-child {\n\tborder-right: 1px solid whiteFaded10;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"whiteFaded10": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["whiteFaded10"] + "",
	"tooltipTable": "mnTHywrK23SYWpw7m873w"
};
export default ___CSS_LOADER_EXPORT___;
