/* @flow */

import RegisterPage from './register-page'
import type { UserAction } from 'types'
import { connect } from 'react-redux'
import { processInvitation } from 'modules/user/actions'
import { getLoginRoute } from 'modules/user/routing/routes'
import withAutoload from 'wrappers/with-autoload'
import { push } from 'react-router-redux'
import type { FinishProcessInvitation } from 'modules/user/actions/action-types'

type DispatchProps = {|
	autoload: () => Promise<void>,
|}

const mapDispatchToProps = (dispatch: Dispatch<UserAction>, ownProps: Object): DispatchProps => {
	return {
		autoload: async () => {
			const { invitationToken } = ownProps.location.state || {}
			const actionResp: FinishProcessInvitation = await dispatch(processInvitation(invitationToken))
			const tokenValid = (actionResp && actionResp.response && actionResp.response.state === 3) || false
			if (!tokenValid) {
				dispatch(push(getLoginRoute()))
			}
		},
	}
}

export default connect(undefined, mapDispatchToProps)(withAutoload(RegisterPage))
