//@flow
import { colors } from 'variables'

export const STATEMENTS_VIEW_KEY = 'bankStatementsView'

export const BANK_COLORS = {
	'5500': {
		// Raiffeisenbank
		background: '#fcf000',
		text: colors.black,
	},
	'0800': {
		// Ceska sporitelna
		background: '#b3e5fd',
		text: '#00497f',
	},
	'2010': {
		// FIO
		background: '#8FBE00',
		text: colors.white,
	},
	'6210': {
		// mBank
		background: '#F79322',
		text: colors.white,
	},
	'0600': {
		// GE
		background: '#ED1C24',
		text: colors.white,
	},
	'2700': {
		// UniCredit
		background: '#e11925',
		text: colors.white,
	},
	'0100': {
		// KB
		background: '#cc0033',
		text: colors.white,
	},
	'0300': {
		// CSOB
		background: '#0099cc',
		text: colors.white,
	},
	'3030': {
		// AirBank
		background: '#09cc33',
		text: colors.white,
	},
	'2250': {
		// Creditas
		background: '#4dd0e1',
		text: colors.white,
	},
	'2600': {
		// CityBank
		background: '#00B4E6',
		text: colors.white,
	},
	'6100': {
		// Equa
		background: '#f68120',
		text: colors.white,
	},
	'8150': {
		// HSBC
		background: '#de0011',
		text: colors.white,
	},
	'6800': {
		// Sberbank
		background: '#299e30',
		text: colors.white,
	},
}
