/* @flow */
/** @jsx jsx */

import type { CashRegister, WithRouterProps } from 'types'
import { type WithTranslateProps, withTranslate } from 'wrappers'
import { formatToDateString, formatToMoney } from 'utils/formatters'

import Block from 'components/block/block'
import { CURRENCY_COLORS } from '../constants'
import Chip from 'components/chip/chip'
import { Fragment } from 'react'
import ProcessingState from 'components/processing-state/processing-state-container'
import { cashRegisterRoute } from 'modules/cash-register/routing/routes'
import { getCashierName } from '../domain/cash-register'
import { jsx } from '@emotion/core'
import { withRouter } from 'react-router-dom'

type Props = {|
	...WithRouterProps,
	...WithTranslateProps,
	isInternalUser: boolean,
	cashRegister: CashRegister,
|}

function getBackgroundColor(currency: ?string) {
	return currency && CURRENCY_COLORS[currency] ? CURRENCY_COLORS[currency].background : undefined
}

function getTextColor(currency: ?string) {
	return currency && CURRENCY_COLORS[currency] ? CURRENCY_COLORS[currency].text : undefined
}

function CashRegisterItem(props: Props) {
	const disabled = !props.cashRegister.isActive
	const cashier = getCashierName(props.cashRegister)

	const endOfLastYearAmount = props.cashRegister.endOfLastYearAmount
	const isInternalUser = props.isInternalUser
	const endDateOfLastYear = new Date(new Date().getFullYear() - 1, 11, 31)

	function onClick() {
		props.cashRegister.id && props.history.push(cashRegisterRoute(props.cashRegister.id))
	}

	return (
		<Block
			disabled={disabled}
			onClick={onClick}
			selected={props.selected}
			title={props.cashRegister.name}
			primary={formatToMoney(props.cashRegister.currentAmount, {
				currency: props.cashRegister.currency,
			})}
			secondary={cashier ? `${props.t('cashRegister.cashier')}: ${cashier}` : undefined}
			third={
				endOfLastYearAmount && isInternalUser
					? `${props.t('cashRegister.state')} ${formatToDateString(endDateOfLastYear)}: ${formatToMoney(
							endOfLastYearAmount,
							{
								currency: props.cashRegister.currency,
							},
					  )}`
					: undefined
			}
			accentColor={getBackgroundColor(props.cashRegister.currency)}
			titleColor={getTextColor(props.cashRegister.currency)}
			chips={
				<Fragment>
					<ProcessingState
						processingState={props.cashRegister.processingState}
						processingMessage={props.cashRegister.processingMessage}
						waitingLabel={props.t('cashRegister.esoWaiting')}
						errorLabel={props.t('cashRegister.esoError')}
						top
					/>
					{disabled && (
						<Chip color="orange" top>
							{props.t('cashRegister.archived')}
						</Chip>
					)}
				</Fragment>
			}
		/>
	)
}

export default withTranslate(withRouter(CashRegisterItem))
