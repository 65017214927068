/* @flow */
import React, { Component } from 'react'

import styles from './simple-color-picker.css'

export type Props = {|
	colors: Array<Color>,
	selected: Color,
	onChange: (color: Color) => void,
|}

export type Color = {
	id: string,
	value: string,
}

class SimpleColorPicker extends Component<Props> {
	getHandleChange = (color: Color) => {
		return () => this.props.onChange(color)
	}

	renderColor = (color: Color) => {
		const style = {
			backgroundColor: color.value,
		}

		return (
			<div
				key={color.id}
				className={this.props.selected.id == color.id ? styles.active : styles.normal}
				style={style}
				onClick={this.getHandleChange(color)}
			/>
		)
	}

	renderColors = () => {
		const { colors } = this.props
		return colors.map(this.renderColor)
	}

	render() {
		return <div>{this.renderColors()}</div>
	}
}
export default SimpleColorPicker
