/* @flow */

export function parseIntOr<T>(value?: ?string | ?number, NaNValue: T): number | T {
	const parsed = parseInt(value)
	if (isNaN(parsed)) {
		return NaNValue
	} else {
		return parsed
	}
}

export function parseFloatOr<T>(value?: ?string | ?number, NaNValue: T): number | T {
	const normalized = 'number' === typeof value ? value : (value || '').toString().replace(',', '.')
	const parsed = parseFloat(normalized)
	if (isNaN(parsed)) {
		return NaNValue
	} else {
		return parsed
	}
}
