// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._297q4QQ0riUB3m8MI92MaM {\n\tmax-width: 1920px;\n\tmargin-left: auto;\n\tmargin-right: auto;\n\tpadding-top: 20px;\n}\n\n._3H6pMThAHvAHP5PFynRZKJ {\n\tdisplay: flex;\n\tjustify-content: space-between;\n\talign-items: center;\n\talign-content: center;\n}\n\n.dndEZMA5D5nq0wcd8I6aD {\n\tline-height: 80px;\n\tmargin: 0;\n}\n\n._3nOP_uZLxq8oRGaIyFVBDT {\n\tdisplay: block;\n\tfont-size: 18px;\n\tline-height: 60px;\n\tcursor: pointer;\n\tuser-select: none;\n}\n", "",{"version":3,"sources":["webpack://./modules/internal/components/billing/billing-page.css"],"names":[],"mappings":"AAAA;CACC,iBAAA;CACA,iBAAA;CACA,kBAAA;CACA,iBAAA;AACD;;AAEA;CACC,aAAA;CACA,8BAAA;CACA,mBAAA;CACA,qBAAA;AACD;;AAEA;CACC,iBAAA;CACA,SAAA;AACD;;AAEA;CACC,cAAA;CACA,eAAA;CACA,iBAAA;CACA,eAAA;CACA,iBAAA;AACD","sourcesContent":[".root {\n\tmax-width: 1920px;\n\tmargin-left: auto;\n\tmargin-right: auto;\n\tpadding-top: 20px;\n}\n\n.header {\n\tdisplay: flex;\n\tjustify-content: space-between;\n\talign-items: center;\n\talign-content: center;\n}\n\n.headline {\n\tline-height: 80px;\n\tmargin: 0;\n}\n\n.dateFilter {\n\tdisplay: block;\n\tfont-size: 18px;\n\tline-height: 60px;\n\tcursor: pointer;\n\tuser-select: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "_297q4QQ0riUB3m8MI92MaM",
	"header": "_3H6pMThAHvAHP5PFynRZKJ",
	"headline": "dndEZMA5D5nq0wcd8I6aD",
	"dateFilter": "_3nOP_uZLxq8oRGaIyFVBDT"
};
export default ___CSS_LOADER_EXPORT___;
