/* @flow */

import React, { Component } from 'react'
import { colors, depths } from 'variables'

import Button from 'components/button'
import ClickAwayListener from 'components/internal/ClickAwayListener'
import ContentClear from 'components/svg-icons/content/clear'
import IconButton from 'components/icon-button'
import InfoIcon from 'components/svg-icons/trivi/state/error'
import type { NotificationAction } from 'types'
import Paper from 'components/Paper'

type Props = {|
	open?: boolean,
	inline?: boolean,
	showIcon?: boolean,
	persist?: boolean,
	secondaryAction?: ?NotificationAction,
	type: 'info' | 'warn' | 'error' | 'success',
	message?: string | React$Element<any> | Array<React$Element<any>>,
	onRequestClose?: () => void,
|}

type State = {|
	open: boolean,
	message: any,
|}

class Snackbar extends Component<Props, State> {
	timerTransitionId: ?TimeoutID = null
	timerOneAtTheTimeId: ?TimeoutID = null

	static defaultProp = {
		type: 'info',
	}

	state = {
		open: false,
		message: null,
	}

	UNSAFE_componentWillMount() {
		this.setState({
			open: this.props.open,
			message: this.props.message,
		})
	}

	componentDidMount() {
		this.state.open && this.setTransitionTimer()
	}

	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		if (this.props.open && nextProps.open && nextProps.message !== this.props.message) {
			this.setState({
				open: false,
			})

			this.timerOneAtTheTimeId && clearTimeout(this.timerOneAtTheTimeId)
			this.timerOneAtTheTimeId = setTimeout(() => {
				this.setState({
					message: nextProps.message,
					open: true,
				})
			}, 400)
		} else {
			const open = nextProps.open

			this.setState({
				open: open !== null ? open : this.state.open,
				message: nextProps.message,
			})
		}
	}

	componentDidUpdate(prevProps: Props, prevState: State) {
		if (prevState.open !== this.state.open) {
			this.state.open && this.setTransitionTimer()
		}
	}

	componentWillUnmount() {
		this.timerTransitionId && clearTimeout(this.timerTransitionId)
		this.timerOneAtTheTimeId && clearTimeout(this.timerOneAtTheTimeId)
	}

	componentClickAway = () => {
		if (this.timerTransitionId) {
			// If transitioning, don't close the snackbar.
			return
		}

		if (this.props.open !== null && this.props.onRequestClose) {
			this.props.onRequestClose()
		} else {
			this.setState({ open: false })
		}
	}

	// Snackbar closing functionality
	closeSnackbar = () => {
		if (this.props.open !== null && this.props.onRequestClose) {
			this.props.onRequestClose()
		} else {
			this.setState({ open: false })
		}
	}

	// Timer that controls delay before click-away events are captured (based on when animation completes)
	setTransitionTimer() {
		this.timerTransitionId = setTimeout(() => {
			this.timerTransitionId = undefined
		}, 400)
	}

	renderSnackbar() {
		const { inline, secondaryAction } = this.props
		const { message } = this.state
		const styles = getStyles(this.props, this.state)

		if (!message || message.length < 1) return null

		return (
			<Paper zDepth={inline ? 5 : 7} style={styles.root}>
				<div style={styles.body}>
					{this.props.showIcon && (
						<div style={styles.iconPanel}>
							<InfoIcon disabled size={18} color={colors.white} hoverColor={colors.white} />
						</div>
					)}
					<div style={styles.content}>{message}</div>
					{!inline && (
						<div style={styles.actions}>
							{secondaryAction && (
								<div>
									<Button
										labelText={secondaryAction.label.toUpperCase()}
										transparent
										labelColor="white"
										onClick={secondaryAction.fn}
										autoTestId="snackbar-button"
									/>
								</div>
							)}
							<IconButton
								style={styles.close}
								onClick={this.closeSnackbar}
								color={colors.white}
								hoverColor={colors.white}
								autoTestId="snackbar-close"
							>
								<ContentClear size={20} />
							</IconButton>
						</div>
					)}
				</div>
			</Paper>
		)
	}

	render() {
		return this.props.inline || this.props.persist ? (
			this.renderSnackbar()
		) : (
			<ClickAwayListener onClickAway={this.state.open ? this.componentClickAway : null}>
				{this.renderSnackbar()}
			</ClickAwayListener>
		)
	}
}

function getStyles(props: Props, state: State) {
	const { type, showIcon, inline } = props
	const { open } = state

	const styles = {
		root: {
			margin: inline ? 0 : 30,
			border: 'none',
			borderRadius: 5,
			position: inline ? 'relative' : 'fixed',
			display: inline && !open ? 'none' : 'block',
			left: 0,
			bottom: 0,
			zIndex: inline ? 'auto' : depths.snackbar,
			overflow: 'hidden',
			visibility: open ? 'visible' : 'hidden',
			transform: open ? 'translateY(0)' : 'translateY(110px)',
			transition: 'transform 400ms cubic-bezier(0.23, 1, 0.32, 1), visibility 400ms cubic-bezier(0.23, 1, 0.32, 1)',
		},
		body: {
			backgroundColor: colors.grey,
			padding: `0 17px 0 ${showIcon ? 62 : 17}px`,
			borderRadius: 5,
			border: 'none',
			minHeight: 70,
			minWidth: 320,
			width: 'auto',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
			opacity: open ? 1 : 0,
			transition: open
				? 'opacity 500ms cubic-bezier(0.23, 1, 0.32, 1) 100ms'
				: 'opacity 400ms cubic-bezier(0.23, 1, 0.32, 1)',
		},
		iconPanel: {
			position: 'absolute',
			top: 0,
			left: 0,
			bottom: 0,
			width: 50,
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-around',
			backgroundColor: colors.grey600,
		},
		content: {
			fontSize: 14,
			color: colors.white,
			padding: '20px 15px',
			whiteSpace: 'pre-wrap',
			wordBreak: 'break-word',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
		},
		actions: {
			display: 'flex',
			alignItems: 'center',
		},
		close: {
			verticalAlign: 'middle',
		},
	}

	if (type === 'success') {
		styles.body.backgroundColor = colors.green
		styles.iconPanel.backgroundColor = '#0B8F2B'
		styles.body.border = '1px solid #0B8F2B'
	}
	if (type === 'warn') {
		styles.body.backgroundColor = colors.orange600
		styles.iconPanel.backgroundColor = '#AE6E00'
	}
	if (type === 'error') {
		styles.body.backgroundColor = colors.red
		styles.iconPanel.backgroundColor = '#AF0721'
	}

	return styles
}

export default Snackbar
