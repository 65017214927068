/* @flow */

import type { AccountingDocumentBankAccount, AccountingDocumentReduxAction, Dispatch, State } from 'types'

import AddBankAccount from 'modules/accounting-document/containers/invoice-elements/add-bank-account'
import { addAccountingDocumentBankAccount } from 'modules/accounting-document/actions'
import { connect } from 'react-redux'
import { emptyAccountingDocumentBankAccount } from 'types/empty'

type OwnProps = {|
	accountingDocumentId: string,
	direction: 'issued' | 'received',
	readonly?: boolean,
	hideWhenZeroAccounts?: boolean,
	buttonText?: string,
	isCreditNote?: boolean,
|}

type StateProps = {|
	bankAccounts: Array<AccountingDocumentBankAccount>,
|}

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => {
	const item = state.accountingDocument.accountingDocuments.items[ownProps.accountingDocumentId]
	const bankAccounts: Array<AccountingDocumentBankAccount> = (item && item.data && item.data.bankAccounts) || []
	return {
		bankAccounts,
	}
}

type DispatchProps = {|
	onBankAccountAttach: (bankAccount: AccountingDocumentBankAccount) => void,
	onBankAccountCreate: (bankAccount?: AccountingDocumentBankAccount) => void,
|}

const mapDispatchToProps = (dispatch: Dispatch<AccountingDocumentReduxAction>, ownProps: OwnProps): DispatchProps => {
	return {
		onBankAccountAttach: (bankAccount: AccountingDocumentBankAccount) => {
			dispatch(addAccountingDocumentBankAccount(ownProps.accountingDocumentId, bankAccount))
		},
		onBankAccountCreate: (bankAccount?: AccountingDocumentBankAccount) => {
			dispatch(
				addAccountingDocumentBankAccount(
					ownProps.accountingDocumentId,
					bankAccount || emptyAccountingDocumentBankAccount(),
				),
			)
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AddBankAccount)
