/* @flow */

import React, { Component } from 'react'
import { withRouter } from 'react-router'
import { withTranslate, type WithTranslateProps, withNotify, type WithNotifyProps } from 'wrappers'
import type { InvitationProcessResponse, WithRouterProps } from 'types'
import type { Props as WithProcessInvitationProps } from 'modules/user/wrappers/with-process-invitation'
import withProcessInvitation from 'modules/user/wrappers/with-process-invitation'
import { Loader } from 'components'
import { getLoginRoute } from 'modules/user/routing/routes'

type Props = {|
	...WithRouterProps,
	...WithTranslateProps,
	...WithNotifyProps,
	...WithProcessInvitationProps,
	processInvitation: (token: string) => Promise<InvitationProcessResponse>,
	logout: () => void,
|}

class InvitationPage extends Component<Props> {
	componentDidMount = async () => {
		const result: InvitationProcessResponse = await this.props.processInvitation(this.props.match.params.token)
		if (result && result.state === 1) {
			this.props.notify(this.props.t('user.register.invitationAccepted'), 'success')
		} else if (!result) {
			this.props.history.push(getLoginRoute())
		}
	}

	render() {
		return <Loader visible />
	}
}

export default withTranslate(withRouter(withProcessInvitation(withNotify(InvitationPage))))
