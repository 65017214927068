/* @flow */

import { connect } from 'react-redux'
import type { Dispatch, UserAction } from 'types'
import { changePassword } from '../actions'
import ChangePasswordForm from '../components/change-password-form'
import type { Props as ComponentProps } from '../components/change-password-form'
import { withRouter } from 'react-router'

const mapDispatchToProps = (dispatch: Dispatch<UserAction>): ComponentProps => {
	return {
		changePassword: (newPassword: string, token: string): void => {
			dispatch(changePassword(newPassword, token))
		},
	}
}

export default connect(undefined, mapDispatchToProps)(withRouter(ChangePasswordForm))
