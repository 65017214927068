//@flow
import React from 'react'
import { Button, PopupSection, Activities } from 'components'
import type { ActivityResponse } from 'types'
import { withTranslate, type WithTranslateProps } from 'wrappers'

export type Props = {|
	activities: Array<ActivityResponse>,
	loading: boolean,
	fetchAccountingDocumentActivities: () => void,
|}

type State = {|
	dialogOpened: boolean,
|}

class ActivitiesDialog extends React.Component<Props & WithTranslateProps, State> {
	state = {
		dialogOpened: false,
	}

	openDialog = () => {
		this.props.fetchAccountingDocumentActivities()
		this.setState({ dialogOpened: true })
	}

	closeDialog = () => {
		this.setState({ dialogOpened: false })
	}

	render() {
		const { dialogOpened } = this.state
		const { activities, t, loading } = this.props
		return (
			<div style={{ marginTop: '15px' }}>
				<Button autoTestId="activities-open" onClick={this.openDialog} labelText={t('invoice.activitiesButtonLabel')} />
				<PopupSection open={dialogOpened} onRequestClose={this.closeDialog}>
					<Activities activities={activities} loading={loading} />
				</PopupSection>
			</div>
		)
	}
}

export default withTranslate(ActivitiesDialog)
