//@flow

import React, { Component } from 'react'
import deepEqual from 'deep-equal'
import {
	withTranslate,
	type WithTranslateProps,
	withOrganizationSettings,
	type WithOrganizationSettingsProps,
} from 'wrappers'
import type { OrganizationAddress } from 'types'
import { emptyOrganizationAddress } from 'types/empty'
import Toggle from 'components/toggle'
import EmailInput from 'components/email-input'
import TextField from 'components/TextField'
import ColorPicker from 'components/color-picker'
import SettingsColorSwitch from '../settings-color-switch'
import SaveSettings from '../save-settings'
import styles from '../settings.css'
import { colors } from 'variables'
import { Validator } from 'utils'

const COLORS = [colors.blue, colors.orange, colors.red, colors.purple, colors.greenPrinting, colors.black]
const DEFAULT_COLOR = COLORS[0]
const DEFAULT_USER_COLOR = '#ffffff'
const MAIN_ADDRESS_TYPE = 1

type Props = {|
	...WithOrganizationSettingsProps,
	...WithTranslateProps,
	loading: boolean,
|}

type State = {
	billingEmail: ?string,
	emailSender: ?string,
	printEmail: ?boolean,
	color: string,
}

class Printing extends Component<Props, State> {
	savedState: ?State = null
	altered: boolean = false

	state: State = this.getStateFromProps(this.props)

	saveState = () => {
		this.savedState = this.state
	}

	restoreSavedState = () => {
		if (this.savedState) {
			this.setState(this.savedState)
		}
		this.resetSavedState()
	}

	resetSavedState = () => {
		this.altered = false
		this.savedState = null
	}

	handleRestoreClick = () => {
		this.restoreSavedState()
	}

	isDefaultColor = (color: string) => {
		return !!COLORS.find((defaultColor: string) => color === defaultColor)
	}

	getUserColor = (): string => {
		if (this.isDefaultColor(this.state.color)) {
			return DEFAULT_USER_COLOR
		} else {
			return this.state.color
		}
	}

	changeSettings = (partialState: Object) => {
		if (!this.altered) {
			this.altered = true
			this.saveState()
		}
		this.setState(partialState, () => {
			deepEqual(this.state, this.savedState) && this.restoreSavedState()
		})
	}

	saveSettings = async () => {
		const { organizationSettings } = this.props

		const billingAddress: OrganizationAddress = this.getBillingAddress(this.props)

		if (billingAddress.email !== this.state.billingEmail || billingAddress.emailSender !== this.state.emailSender) {
			const address: OrganizationAddress = {
				...billingAddress,
				email: this.state.billingEmail || undefined,
				emailSender: this.state.emailSender || undefined,
			}

			this.props.changeOrganizationAddress(address)
		}

		if (organizationSettings) {
			const newSettings = {
				...organizationSettings,
				printing: {
					...organizationSettings.printing,
					printAccountingDocumentFooter: !!this.state.printEmail,
					appearance: {
						...(organizationSettings.printing ? organizationSettings.printing.appearance : {}),
						baseDecorationColor: this.state.color,
					},
				},
			}
			this.props.updateOrganizationSettings(organizationSettings, newSettings)
		}
	}

	handleTextFieldChange = (field: string) => (event: SyntheticInputEvent<HTMLInputElement>, val: ?string) => {
		this.changeSettings({ [field]: val })
	}

	UNSAFE_componentWillMount() {
		this.props.loadOrganizationSettings()
	}

	UNSAFE_componentWillReceiveProps(newProps: Props) {
		this.setState(this.getStateFromProps(newProps))
		if (newProps.loading === false && this.props.loading) this.resetSavedState()
	}

	getStateFromProps(props: Props): State {
		const { organizationSettings } = props
		const billingAddress: OrganizationAddress = this.getBillingAddress(props)
		return {
			color:
				organizationSettings &&
				organizationSettings.printing &&
				organizationSettings.printing.appearance &&
				organizationSettings.printing.appearance.baseDecorationColor
					? organizationSettings.printing.appearance.baseDecorationColor
					: DEFAULT_COLOR,
			billingEmail: billingAddress.email || '',
			emailSender: billingAddress.emailSender || '',
			printEmail:
				organizationSettings &&
				organizationSettings.printing &&
				organizationSettings.printing.printAccountingDocumentFooter
					? organizationSettings.printing.printAccountingDocumentFooter
					: false,
		}
	}

	getBillingAddress(props: Props): OrganizationAddress {
		const { organization } = props
		let addresses: Array<OrganizationAddress> = organization && organization.addresses ? organization.addresses : []
		let billingAddressIndex: number = addresses.findIndex((a: OrganizationAddress) => a.type === MAIN_ADDRESS_TYPE)
		let billingAddress: OrganizationAddress =
			billingAddressIndex !== -1
				? addresses[billingAddressIndex]
				: { ...emptyOrganizationAddress(), type: MAIN_ADDRESS_TYPE }
		return billingAddress
	}

	onToggle = (event: SyntheticEvent<HTMLInputElement>, toggled: boolean) => {
		this.changeSettings({
			printEmail: toggled,
		})
	}

	onBillingEmailChange = (event: SyntheticInputEvent<HTMLInputElement>, value: ?string) => {
		this.changeSettings({
			billingEmail: value,
		})
	}

	onEmailSenderChange = (event: SyntheticInputEvent<HTMLInputElement>, value: ?string) => {
		this.changeSettings({
			emailSender: value,
		})
	}

	handleColorSwitch = (color: string) => {
		this.changeSettings({
			color,
		})
	}

	renderColorSwitch = (color: string) => {
		return (
			<div style={style.color} key={color}>
				<SettingsColorSwitch color={color} onClick={this.handleColorSwitch} switched={color === this.state.color} />
			</div>
		)
	}

	renderColors = () => {
		return (
			<div style={style.colors}>
				{COLORS.map((color: string) => this.renderColorSwitch(color))}
				<ColorPicker
					color={this.getUserColor()}
					onColor={this.handleColorSwitch}
					switched={!this.isDefaultColor(this.state.color)}
				/>
			</div>
		)
	}

	isEmailValid = (): boolean => {
		const { billingEmail } = this.state
		let valid: boolean = true
		if (billingEmail && !Validator.email(billingEmail)) {
			valid = false
		}
		return valid
	}

	render() {
		//TODO remove mocked printing setting
		const { t } = this.props
		return (
			<div>
				<h3 className={styles.firstH3}>{t('settings.printing.chooseColor')}</h3>
				<div className={styles.row}>{this.renderColors()}</div>
				<div className={styles.row}>
					<EmailInput
						fullWidth
						labelText={t('settings.printing.billingEmail')}
						onChange={this.onBillingEmailChange}
						value={this.state.billingEmail}
						infoText={t('settings.printing.billingEmailTooltip')}
						autoTestId="settings-printing-billing-email"
						disabled={this.props.loading}
					/>
				</div>
				<div className={styles.row}>
					<TextField
						fullWidth
						labelText={t('settings.printing.emailSender')}
						onChange={this.handleTextFieldChange('emailSender')}
						value={this.state.emailSender}
						autoTestId="settings-printing-sender"
						disabled={this.props.loading}
						name="sender"
					/>
				</div>
				<div className={styles.row}>
					<Toggle
						autoTestId="settings-printing-createdInTrivi"
						toggled={!!this.state.printEmail}
						onToggle={this.onToggle}
						label={t('settings.printing.createdInTrivi')}
						disabled={this.props.loading}
					/>
				</div>
				<SaveSettings
					visible={this.altered}
					saving={!!this.props.loading}
					onSave={this.saveSettings}
					onRestore={this.restoreSavedState}
					disabled={!this.isEmailValid()}
				/>
			</div>
		)
	}
}

const style = {
	colors: {
		display: 'flex',
	},
	color: {
		marginRight: 15,
	},
}

export default withTranslate(withOrganizationSettings(Printing))
