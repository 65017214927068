/* @flow */

import { connect } from 'react-redux'
import type { State, AccountingDocument, FormFieldErrorMessage, FormFieldErrorContext } from 'types'
import CreditNoteDescription, {
	type Props as CreditNoteDescriptionProps,
} from '../../components/invoice-elements/credit-note-description'
import { getAccountingDocument } from 'modules/accounting-document/selectors'
import { updateAccountingDocumentCreditNoteDescription } from '../../actions/accounting-document'
import { getFormFieldErrors } from 'modules/common/selectors'
import { removeError } from 'helpers'

type Props = {|
	accountingDocumentId: string,
	readonly: boolean,
	formFieldErrorContext?: FormFieldErrorContext,
|}

type StateProps = {|
	creditNoteDescription: ?string,
	errors: ?Array<FormFieldErrorMessage>,
|}

const mapStateToProps = (state: State, ownProps: Props): StateProps => {
	const accountingDocument: ?AccountingDocument = getAccountingDocument(state, ownProps.accountingDocumentId)
	const creditNoteDescription = accountingDocument ? accountingDocument.creditNoteDescription : ''
	const errors = ownProps.formFieldErrorContext && getFormFieldErrors(state, ownProps.formFieldErrorContext)

	return {
		creditNoteDescription,
		errors,
	}
}

type DispatchProps = {
	updateCreditNoteDescription: (creditNoteDescription: ?string) => void,
	removeError: () => void,
}

const mapDispatchToProps = (dispatch: Function, ownProps: Props): DispatchProps => {
	return {
		updateCreditNoteDescription: (creditNoteDescription: ?string) =>
			dispatch(updateAccountingDocumentCreditNoteDescription(ownProps.accountingDocumentId, creditNoteDescription)),
		removeError: () => {
			removeError({
				formFieldErrorContext: ownProps.formFieldErrorContext,
				dispatch,
			})
		},
	}
}

const mergeProps = (
	stateProps: StateProps,
	dispatchProps: DispatchProps,
	ownProps: Props,
): CreditNoteDescriptionProps => {
	return {
		creditNoteDescription: stateProps.creditNoteDescription,
		updateCreditNoteDescription: dispatchProps.updateCreditNoteDescription,
		readonly: ownProps.readonly,
		errors: stateProps.errors,
		removeError: dispatchProps.removeError,
	}
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(CreditNoteDescription)
