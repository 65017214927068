/* @flow */

import React from 'react'
import CloseIcon from 'components/svg-icons/navigation/close'
import Email from 'components/svg-icons/communication/email'
import IconButton from 'components/icon-button'
import { colors } from 'variables'
import styles from './icon.css'

type Props = {|
	isOpen: boolean,
	onClick: () => void,
|}

const Icon = (props: Props) => {
	const { isOpen, onClick } = props
	const iconColor = isOpen ? colors.gray900 : colors.black
	const iconHoverColor = isOpen ? colors.gray : colors.gray900
	return (
		<div className={isOpen ? styles.opened : styles.normal}>
			<IconButton autoTestId="contact-me-close" onClick={onClick} color={iconColor} hoverColor={iconHoverColor}>
				{isOpen ? <CloseIcon size={20} /> : <Email size={22} />}
			</IconButton>
		</div>
	)
}

export default Icon
