// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n._1VOH3IEgXCCnSmItT-0qib {\n\tflex: 1;\n\tflex-grow: 0;\n\tdisplay: block;\n\tmin-height: 56px;\n  }", "",{"version":3,"sources":["webpack://./modules/common/components/trivi-logo.css"],"names":[],"mappings":";AACA;CACC,OAAA;CACA,YAAA;CACA,cAAA;CACA,gBAAA;EACC","sourcesContent":["\n.logo {\n\tflex: 1;\n\tflex-grow: 0;\n\tdisplay: block;\n\tmin-height: 56px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logo": "_1VOH3IEgXCCnSmItT-0qib"
};
export default ___CSS_LOADER_EXPORT___;
