/* @flow */

import { keyframes } from '@emotion/core'
import { colors, depths } from 'variables'

const fadeIn = keyframes`
	0% {
		opacity: 0;
		transform: translate(0, -2rem);
	}
	100% {
		opacity: 1;
		transform: translate(0, 0);
	}
`

const fadeOut = keyframes`
	0% {
		opacity: 1;
		transform: translate(0, 0);
	}
	100% {
		opacity: 0;
		transform: translate(0, -2rem);
	}
`

const styles = {
	root: {
		pointerEvents: 'all',
		position: 'fixed',
		zIndex: depths.dialog,
		top: 0,
		width: '100%',
		height: '100%',
		overflow: 'auto',
	},
	rootScrollable: {
		overflow: 'overlay',
	},
	scroller: {
		pointerEvents: 'all',
		position: 'absolute',
		width: '100%',
		minHeight: '100%',
		height: 'auto',
		display: 'flex',
		justifyContent: 'center',
		alignContent: 'center',
		alignItems: 'center',
	},
	content: {
		width: '75%',
		position: 'relative',
		zIndex: depths.dialog,
		padding: '70px 0',
		fontWeight: 'normal',
		animationDuration: '400ms',
		animationFillMode: 'forwards',
		animationTimingFunction: 'cubic-bezier(0.215, 0.61, 0.355, 1)',
		transform: 'translate(0, -64px)',
		pointerEvents: 'none',
		opacity: 0,
		animationName: fadeOut,
	},
	openContent: {
		animationName: fadeIn,
		pointerEvents: 'all',
	},
	actionsContainer: {
		padding: 8,
		width: '100%',
		textAlign: 'center',
		paddingBottom: 35,
	},
	title: {
		margin: 0,
		padding: 0,
		color: colors.black,
		fontSize: 24,
		lineHeight: '32px',
		fontWeight: 700,
		textAlign: 'center',
	},
	body: {
		fontSize: 12,
		textAlign: 'left',
		fontWeight: 'normal',
		color: colors.black,
		padding: 24,
	},
}

export default styles
