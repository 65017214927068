/* @flow */
/** @jsx jsx */

import { Component } from 'react'
import { jsx } from '@emotion/core'
import { uniq } from 'lodash'
import type { ValidationError, ValidationFieldError, NotificationType } from 'types'
import { Snackbar } from 'components'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import { getServerErrorMessage } from 'helpers'

export type Props = {
	notification: ?NotificationType,
	clearNotification: () => void,
	serverError: ?ValidationError,
	clearServerError: () => void,
}

class NotificationCenter extends Component<Props & WithTranslateProps> {
	render() {
		const { t, notification, serverError } = this.props

		if (notification) {
			return (
				<Snackbar
					open={notification != null}
					message={t(notification.message)}
					type={notification.type}
					onRequestClose={this.props.clearNotification}
					secondaryAction={{
						...notification.secondaryAction,
						label: t(notification.secondaryAction ? notification.secondaryAction.label : ''),
					}}
				/>
			)
		}

		if (serverError) {
			if (process.env.NODE_ENV !== 'production') {
				console.error('>>> SERVER ERROR:', serverError) //eslint-disable-line
			}
			const message = getServerErrorMessage(t, serverError)
			const hasServerMessage = message || (serverError.fieldsAffected && serverError.fieldsAffected.length > 0)
			const messages =
				serverError.fieldsAffected &&
				serverError.fieldsAffected.length > 0 &&
				uniq(serverError.fieldsAffected.map((field: ValidationFieldError) => getServerErrorMessage(t, field)))

			return (
				<Snackbar
					open
					message={
						hasServerMessage ? (
							<div>
								{message}
								{messages && (
									<ul css={styles.ul}>
										{messages.map((message: string, index: number) => (
											<li key={index}>{message}</li>
										))}
									</ul>
								)}
							</div>
						) : (
							t('serverError.fe0001')
						)
					}
					onRequestClose={this.props.clearServerError}
					type="error"
				/>
			)
		}

		return null
	}
}

const styles = {
	ul: {
		margin: '10px 0 0 0',
		listStylePosition: 'inside',
	},
}

export default withTranslate(NotificationCenter)
