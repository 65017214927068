/* @flow */

import React, { Component } from 'react'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import type { FileDescription, OrganizationMember, OrganizationMembers, Task, TaskAccountingDocument } from 'types'
import { MenuItem, ButtonSelect, Separator, FileThumbnail } from 'components'
import AccountingDocumentIcon from 'modules/accounting-document/components/accounting-document-icon'
import CalendarIcon from 'components/svg-icons/action/date-range'
import FlagIcon from 'components/svg-icons/trivi/flag'
import BusinessIcon from 'components/svg-icons/communication/business'
import TaskDone from 'components/svg-icons/trivi/task-done'
import { colors } from 'variables'
import { formatToDateString } from 'utils/formatters'
import FormBox from '../../../components/form-box'
import styles from './task.css'
import { handleDownloadFile } from '../../file/domain'

const priorityTexts = ['task.priorityLevel.low', 'task.priorityLevel.normal', 'task.priorityLevel.high']
const buttonStateTexts = ['task.buttonState.setInProgress', 'task.buttonState.setDone', 'task.buttonState.setNew']

export type Props = {|
	task: ?Task,
	organizations: OrganizationMembers,
	changeState: (Task, number) => void,
	onFileOpen: (File: FileDescription) => void,
|}

type ComponentProps = {| ...WithTranslateProps, ...Props |}

const style = {
	icon: {
		width: 20,
		height: 20,
		verticalAlign: 'middle',
		marginRight: 5,
	},
}

class TaskComponent extends Component<ComponentProps> {
	handleStateChangeButtonClick = () => {
		if (this.props.task) {
			const nextState = (this.props.task.state + 1) % 3
			this.props.changeState(this.props.task, nextState)
		}
	}

	handleStateChangeItemClick = (event: SyntheticMouseEvent<HTMLElement>, item: any) => {
		if (this.props.task) {
			const state = this.props.task.state
			let nextState: number = 0

			if (item && item.key) {
				nextState = (item.key + 1) % 3
			} else {
				nextState = (state + 1) % 3
			}
			this.props.changeState(this.props.task, nextState)
		}
	}

	handleDownloadFile = (fileId?: string, fileName?: string) => {
		fileId && fileName && this.downloadFile(fileId, fileName)
	}

	downloadFile = async (fileId: string, fileName: string) => {
		await handleDownloadFile(fileId, fileName, true, true)
	}

	renderAttachedFiles = () => {
		const { task, t } = this.props
		if (!task) return null

		if (!task.attachedFiles || !task.attachedFiles.length) return null

		let attachedFiles = []
		if (task.attachedFiles) {
			attachedFiles = task.attachedFiles.map((item: {| fileId?: string, name?: string |}) => {
				return {
					id: item.fileId || '',
					fileId: item.fileId || '',
					name: item.name || '',
					loading: false,
				}
			})
		}

		return (
			<div>
				<Separator left={<span className={styles.separatorText}>{t('task.attachedFiles')}</span>} />
				<div className={styles.row}>
					{attachedFiles.map((file: FileDescription) => (
						<FileThumbnail
							key={file.id}
							file={file}
							editable={false}
							onOpen={() => this.handleDownloadFile(file.fileId, file.name)}
						/>
					))}
				</div>
			</div>
		)
	}

	renderConnectedDocuments = () => {
		const { task, t } = this.props
		if (!task) return null
		if (!task.attachedAccountingDocuments || !task.attachedAccountingDocuments.length) return null

		return (
			<div className={styles.connected}>
				<Separator left={<span className={styles.separatorText}>{t('task.attachedAccountingDocuments')}</span>} />
				<div className={styles.row}>
					{task.attachedAccountingDocuments &&
						task.attachedAccountingDocuments.map((item: TaskAccountingDocument) => (
							<AccountingDocumentIcon
								key={item.id}
								accountingDocument={{ accountingDocumentId: item.id, accountingDocumentNo: item.number }}
								organizationId={item.organizationId}
								editable={false}
							/>
						))}
				</div>
			</div>
		)
	}

	renderBody = () => {
		const { task } = this.props
		if (!task) return null

		if (
			(!task.attachedAccountingDocuments || !task.attachedAccountingDocuments.length) &&
			(!task.attachedFiles || !task.attachedFiles.length)
		)
			return null

		return (
			<div>
				{this.renderAttachedFiles()}
				{this.renderConnectedDocuments()}
			</div>
		)
	}

	renderActions = () => {
		const { task, t } = this.props
		if (!task) return null

		return (
			<ButtonSelect
				labelText={t(buttonStateTexts[task.state || 0])}
				onClick={this.handleStateChangeButtonClick}
				onItemClick={this.handleStateChangeItemClick}
			>
				{buttonStateTexts.map((item: string, index: number) =>
					index === task.state || (index + 1) % 3 === task.state ? null : (
						<MenuItem key={index} primaryText={t(item)} />
					),
				)}
			</ButtonSelect>
		)
	}

	renderHeader = () => {
		const { task, t } = this.props
		if (!task) return null

		const taskColor = task.state === 0 ? '#8A8A8A' : task.state === 1 ? colors.orange : colors.green
		const organizationName = (
			this.props.organizations.find((org: OrganizationMember) => org.organizationId === task.organizationId) || {}
		).organizationName

		return (
			<div>
				<div className={styles.state}>
					<TaskDone color={taskColor} hoverColor={taskColor} disabled />
				</div>
				<div className={styles.nameState}>{task.title}</div>
				<div className={styles.row}>
					<div className={styles.infopanel}>
						<div className={styles.info}>
							<FlagIcon style={style.icon} color={colors.orange} disabled />
							{t('task.priority', { priority: t(priorityTexts[task.priority || 0]) })}
						</div>
						<div className={styles.info}>
							<CalendarIcon style={style.icon} color={colors.orange} disabled />
							{t('task.createdDate', { date: formatToDateString(task.created || '') })}
						</div>
						{task.dueDate && (
							<div className={styles.info}>
								<CalendarIcon style={style.icon} color={colors.orange} disabled />
								{t('task.dueDate', { date: formatToDateString(task.dueDate || '') })}
							</div>
						)}
						<div className={styles.info}>
							<BusinessIcon style={style.icon} color={colors.orange} disabled />
							{t('task.organization', { organization: organizationName || '' })}
						</div>
					</div>
				</div>
			</div>
		)
	}

	render() {
		return (
			<FormBox header={this.renderHeader()} actions={this.renderActions()} headline={this.props.t('task.detail')}>
				{this.renderBody()}
			</FormBox>
		)
	}
}

export default withTranslate(TaskComponent)
