// @flow
/** @jsx jsx */

import { PureComponent } from 'react'
import { jsx } from '@emotion/core'

type Props = {|
	children?: string,
|}

class MenuCategory extends PureComponent<Props> {
	render() {
		return (
			<div css={styles.root}>
				<div>{this.props.children}</div>
			</div>
		)
	}
}

const styles = {
	root: {
		textAlign: 'left',
		fontWeight: 700,
		fontSize: 14,
		lineHeight: '24px',
		paddingLeft: 16,
		paddingRight: 16,
		marginTop: 15,
		marginBottom: 2,
	},
}

export default MenuCategory
