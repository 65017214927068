/* @flow */

import type { Currency, DashboardAction } from 'types'

export function selectCurrency(currency: ?Currency): DashboardAction {
	return {
		type: 'SELECT_CURRENCY',
		currency: currency,
	}
}
