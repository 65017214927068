/* @flow */

import warning from 'warning'
import moment from 'moment'
import { i18n } from 'locales'

const NUMERIC = 'numeric'
const SHORT = 'short'
const LONG = 'long'
const DIGIT2 = '2-digit'
const NARROW = 'narrow'

export function dateTimeFormat(locale: string, options: Object): ?string {
	warning(
		locale === 'en-US',
		`Material-UI: The ${locale} locale is not supported by the built-in DateTimeFormat.
  Use the \`DateTimeFormat\` prop to supply an alternative implementation.`,
	)

	this.format = function(date: Date) {
		const momentDate = moment(date)
		momentDate.locale('en')

		if (options.month === SHORT && options.weekday === SHORT && options.day === DIGIT2) {
			const day = momentDate.format('dddd').toLowerCase() // tuesday
			const dayTranslated = i18n.t(`application.daysShort.${day}`)
			const month = momentDate.format('MMMM').toLowerCase() // february
			const monthTranslated = i18n.t(`application.monthsShort.${month}`)
			return `${dayTranslated}, ${monthTranslated} ${momentDate.format('D')}`
		} else if (options.year === NUMERIC && options.month === NUMERIC && options.day === NUMERIC) {
			return momentDate.format('M/D/YYYY')
		} else if (options.year === NUMERIC && options.month === LONG) {
			const month = momentDate.format('MMMM').toLowerCase()
			const monthTranslated = i18n.t(`application.months.${month}`)
			return `${monthTranslated} ${momentDate.format('YYYY')}`
		} else if (options.weekday === NARROW) {
			const day = momentDate.format('dddd').toLowerCase()
			return i18n.t(`application.daysShort.${day}`)
		} else if (options.year === NUMERIC) {
			return momentDate.format('YYYY').toString()
		} else if (options.day === NUMERIC) {
			return momentDate.format('D')
		} else {
			warning(false, 'Material-UI: Wrong usage of DateTimeFormat')
		}
	}
}

export function getYear(d: Date) {
	return d.getFullYear()
}

export function setYear(d: Date, year: number) {
	const newDate = cloneDate(d)
	newDate.setFullYear(year)
	return newDate
}

export function addDays(d: Date, days: number) {
	const newDate = cloneDate(d)
	newDate.setDate(d.getDate() + days)
	return newDate
}

export function addMonths(d: Date, months: number) {
	const newDate = cloneDate(d)
	newDate.setMonth(d.getMonth() + months)
	return newDate
}

export function addYears(d: Date, years: number) {
	const newDate = cloneDate(d)
	newDate.setFullYear(d.getFullYear() + years)
	return newDate
}

export function cloneDate(d: Date) {
	return new Date(d.getTime())
}

export function cloneAsDate(d: Date) {
	const clonedDate = cloneDate(d)
	clonedDate.setHours(0, 0, 0, 0)
	return clonedDate
}

export function getDaysInMonth(d: Date) {
	const resultDate = getFirstDayOfMonth(d)

	resultDate.setMonth(resultDate.getMonth() + 1)
	resultDate.setDate(resultDate.getDate() - 1)

	return resultDate.getDate()
}

export function getFirstDayOfMonth(d: Date) {
	return new Date(d.getFullYear(), d.getMonth(), 1)
}

export function getFirstDayOfWeek() {
	const now = new Date()
	return new Date(now.setDate(now.getDate() - now.getDay()))
}

export function getWeekArray(d: Date, firstDayOfWeek: number = 1) {
	const dayArray = []
	const daysInMonth = getDaysInMonth(d)
	const weekArray = []
	let week = []

	for (let i = 1; i <= daysInMonth; i++) {
		dayArray.push(new Date(d.getFullYear(), d.getMonth(), i))
	}

	const addWeek = (week: Array<?Date>) => {
		const emptyDays = 7 - week.length
		for (let i = 0; i < emptyDays; ++i) {
			if (weekArray.length) {
				week.push(null)
			} else {
				week.unshift(null)
			}
		}
		weekArray.push(week)
	}

	dayArray.forEach((day: Date) => {
		if (week.length > 0 && day.getDay() === firstDayOfWeek) {
			addWeek(week)
			week = []
		}
		week.push(day)
		if (dayArray.indexOf(day) === dayArray.length - 1) {
			addWeek(week)
		}
	})

	return weekArray
}

export function localizedWeekday(
	DateTimeFormat: (string, Object) => ?string,
	locale: string,
	day: number,
	firstDayOfWeek: number = 1,
) {
	const weekdayFormatter = new DateTimeFormat(locale, { weekday: 'narrow' })
	const firstDayDate = getFirstDayOfWeek()

	return weekdayFormatter.format(addDays(firstDayDate, day + firstDayOfWeek))
}

// Convert date to ISO 8601 (YYYY-MM-DD) date string, accounting for current timezone
export function formatIso(date: Date) {
	return new Date(`${date.toDateString()} 12:00:00 +0000`).toISOString().substring(0, 10)
}

export function isEqualDate(d1: ?Date, d2: ?Date) {
	if (!d1 && !d2) return true
	return (
		d1 &&
		d2 &&
		d1.getFullYear() === d2.getFullYear() &&
		d1.getMonth() === d2.getMonth() &&
		d1.getDate() === d2.getDate()
	)
}

export function isBeforeDate(d1: Date, d2: Date) {
	const date1 = cloneAsDate(d1)
	const date2 = cloneAsDate(d2)

	return date1.getTime() < date2.getTime()
}

export function isAfterDate(d1: Date, d2: Date) {
	const date1 = cloneAsDate(d1)
	const date2 = cloneAsDate(d2)

	return date1.getTime() > date2.getTime()
}

export function isBetweenDates(dateToCheck: ?Date, startDate: Date, endDate: Date) {
	return dateToCheck && !isBeforeDate(dateToCheck, startDate) && !isAfterDate(dateToCheck, endDate)
}

export function monthDiff(d1: Date, d2: Date) {
	let m
	m = (d1.getFullYear() - d2.getFullYear()) * 12
	m += d1.getMonth()
	m -= d2.getMonth()
	return m
}

export function yearDiff(d1: Date, d2: Date) {
	return ~~(monthDiff(d1, d2) / 12)
}

export const defaultUtils = {
	getYear,
	setYear,
	addDays,
	addMonths,
	addYears,
	getFirstDayOfMonth,
	getWeekArray,
	monthDiff,
}
