/* @flow */

import { connect } from 'react-redux'
import type { State } from 'types'
import TotalToPay from '../../components/invoice-elements/total-to-pay'
import { getAccountingDocumentTotal, getAccountingDocumentCurrency } from 'modules/accounting-document/selectors'
import { getOrganizationColor } from 'modules/organization/selectors'

type OwnProps = {|
	accountingDocumentId: string,
	color?: string,
|}

type StateProps = {|
	total: ?number,
	currency?: string,
	color: ?string,
|}

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => {
	return {
		total: getAccountingDocumentTotal(state, ownProps.accountingDocumentId),
		currency: getAccountingDocumentCurrency(state, ownProps.accountingDocumentId) || undefined,
		color: ownProps.color || getOrganizationColor(state),
	}
}

export default connect(mapStateToProps)(TotalToPay)
