/* @flow */
/** @jsx jsx */

import { PureComponent } from 'react'
import { jsx } from '@emotion/core'
import memoize from 'memoize-one'
import ContentAdd from 'components/svg-icons/content/add'
import { colors } from 'variables'

type Props = {|
	open: boolean,
	onClick?: (SyntheticEvent<HTMLElement>) => any,
	label: string,
|}

class AddButtonIcon extends PureComponent<Props> {
	getStyles = memoize((open: boolean) => {
		return {
			root: {
				boxSizing: 'border-box',
				overflow: 'visible',
				padding: 0,
				width: 100,
				height: 40,
				fontSize: 0,
				cursor: 'pointer',
				borderRadius: '20px',
				display: 'flex',
				justifyContent: 'space-around',
				alignContent: 'center',
				alignItems: 'center',
				borderWidth: 1,
				borderStyle: 'solid',
				transition: 'all 200ms ease-in-out',
				borderColor: open ? colors.gray400 : colors.orange600,
				backgroundColor: open ? colors.gray200 : colors.orange,
				boxShadow: open ? `inset 2px 4px 0 0 ${colors.blackFaded8}` : `3px 3px 0 0 ${colors.blackFaded8}`,
				'&:hover': {
					backgroundColor: colors.orangeFaded90,
				},
			},
			icon: {
				transform: open ? 'rotate(45deg)' : 'rotate(0deg)',
			},
			btnText: {
				position: 'relative',
				fontSize: '14px',
				textTransform: 'uppercase',
				fontWeight: 'bold',
				paddingRight: '15px',
				color: 'rgb(255, 255, 255)',
			},
		}
	})

	render() {
		const { open } = this.props
		const styles = this.getStyles(open)

		return (
			<div css={styles.root} onClick={this.props.onClick}>
				<ContentAdd
					style={styles.icon}
					color={open ? colors.gray : colors.white}
					hoverColor={open ? colors.gray900 : colors.white}
				/>
				<div style={{ color: open ? colors.gray900 : colors.white }} css={styles.btnText}>
					{this.props.label}
				</div>
			</div>
		)
	}
}

export default AddButtonIcon
