// @flow
/** @jsx jsx */

import { type ChildrenArray, Component, cloneElement } from 'react'
import { jsx } from '@emotion/core'
import memoize from 'memoize-one'
import { autoTestId, type AutoTestProps } from 'utils/tests/autotest'
import TabSwitch from './TabSwitch'
import { colors } from 'variables'

type Props<T> = {|
	...AutoTestProps,
	children: ChildrenArray<React$Element<typeof TabSwitch>>,
	initialSelectedIndex?: number,
	label?: string,
	labelStyle?: Object,
	value?: ?T,
	compact?: boolean,
	disabled?: boolean,
	fullWidth?: boolean,
	onChange: T => void,
|}

class TabSwitches<T> extends Component<Props<T>> {
	handleTabClick = (value: string | number) => {
		if (this.props.value !== value) {
			// $FlowFixMe: we cannot check this.props.value, because it is initially null in multi-import
			this.props.onChange && this.props.onChange(value)
		}
	}

	getStyles = memoize((fullWidth: boolean, labelStyle: ?Object) => {
		return {
			root: {
				display: 'inline-block',
				marginLeft: 'auto',
				marginRight: 'auto',
				width: fullWidth ? '100%' : 'auto',
			},
			label: {
				paddingBottom: 7,
				color: colors.black,
				fontSize: 14,
				lineHeight: '17px',
				...labelStyle,
			},
			tabItemContainer: {
				width: 'auto',
				backgroundColor: 'transparent',
				whiteSpace: 'nowrap',
				display: 'flex',
			},
		}
	})

	getTabs = memoize(
		(
			children: ChildrenArray<React$Element<typeof TabSwitch>>,
			fullWidth: boolean,
			disabled: boolean,
			compact: boolean,
		) => {
			// $FlowFixMe
			const _children: $ReadOnlyArray<React$Element<typeof TabSwitch>> = Array.isArray(children) ? children : [children]
			const childrenArray = _children.filter((tab: React$Element<typeof TabSwitch>) => !!tab)
			const width = 100 / childrenArray.length

			return childrenArray.map((tab: React$Element<typeof TabSwitch>, index: number) => {
				return cloneElement(tab, {
					key: index,
					index: index,
					last: index === childrenArray.length - 1,
					selected: !!(this.props.value === tab.props.value),
					width: fullWidth ? `${width}%` : 'auto',
					onClick: this.handleTabClick,
					disabled: disabled,
					compact: compact,
				})
			})
		},
	)

	render() {
		const styles = this.getStyles(!!this.props.fullWidth, this.props.labelStyle)
		const tabs = this.getTabs(this.props.children, !!this.props.fullWidth, !!this.props.disabled, !!this.props.compact)

		return (
			<div css={styles.root} {...autoTestId(this.props.autoTestId)}>
				{this.props.label && <div css={styles.label}>{this.props.label}</div>}
				<div>
					<div css={styles.tabItemContainer}>{tabs}</div>
				</div>
			</div>
		)
	}
}

export default TabSwitches
