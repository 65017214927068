/* @flow */
/** @jsx jsx */

import { jsx } from '@emotion/core'
import type { UnmatchedPaymentMatch } from 'types'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import { formatToDateString, formatToTimeString } from 'utils/formatters'
import DeleteIcon from 'components/svg-icons/action/delete'
import deleteDialog from 'components/delete-dialog'
import IconButton from 'components/icon-button'
import { colors } from 'variables'

type Props = {|
	...WithTranslateProps,
	document: UnmatchedPaymentMatch,
	onDelete?: (match: UnmatchedPaymentMatch) => void,
|}

function Comment(props: Props) {
	function onDelete() {
		deleteDialog().then(() => props.onDelete && props.onDelete(props.document))
	}

	return (
		<div css={styles.comment}>
			<div css={styles.headline}>
				{`${formatToDateString(props.document.paymentMatchedAt || '')} ${formatToTimeString(
					props.document.paymentMatchedAt || '',
					undefined,
					{ hour: '2-digit', minute: '2-digit' },
				)}`.trim()}
				{!!props.onDelete && (
					<div css={styles.delete} className={'delete'}>
						<IconButton autoTestId="grid-expanded-delete" onClick={onDelete}>
							<DeleteIcon size={14} />
						</IconButton>
					</div>
				)}
			</div>
			<div css={styles.author}>
				{props.document.paymentMatchedBy &&
					`${props.document.paymentMatchedBy.firstName || ''} ${props.document.paymentMatchedBy.lastName || ''}`}
			</div>
			<div css={styles.text}>{props.document.note}</div>
		</div>
	)
}

export default withTranslate(Comment)

const styles = {
	comment: {
		borderBottomWidth: 1,
		borderBottomStyle: 'solid',
		borderBottomColor: '#DFDFDF',
		marginBottom: 15,
		'&:hover': {
			'.delete': {
				opacity: 1,
			},
		},
		'&:last-of-type': {
			borderBottomColor: colors.transparent,
		},
	},
	delete: {
		position: 'absolute',
		opacity: 0,
		top: -12,
		right: -32,
		transition: 'all 300ms',
		cursor: 'pointer',
	},
	headline: {
		position: 'relative',
		fontSize: 14,
		fontWeight: 600,
		display: 'inline-block',
		alignItems: 'center',
	},
	author: {
		padding: '10px 10px 15px 0',
		overflow: 'hidden',
		whiteSpace: 'normal',
	},
	text: {
		padding: '10px 10px 15px 0',
		overflow: 'hidden',
		whiteSpace: 'pre-wrap',
	},
}
