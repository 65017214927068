// @flow

import { type ComponentType, type ElementConfig, type Component } from 'react'
import { connect } from 'react-redux'
import type { State } from 'types'
import { getCurrentOrganizationName } from '../../organization/selectors'

type StateProps = {|
	organizationName: ?string,
|}

const mapStateToProps = (state: State): StateProps => {
	return {
		organizationName: getCurrentOrganizationName(state),
	}
}

export type Props = StateProps
export default function withOrganizationName<P: any, C: ComponentType<P>>(
	WrappedComponent: C,
): Class<Component<$Diff<ElementConfig<C>, Props>, any>> {
	return connect(mapStateToProps)(WrappedComponent)
}
