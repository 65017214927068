/* @flow */
/** @jsx jsx */

import type { CardResponseCta, CardResponseParameters } from 'types'
import { Component, Fragment } from 'react'
import { type WithTranslateProps, withTranslate } from 'wrappers'

import ChevronRight from 'components/svg-icons/navigation/chevron-right'
import { EMPTY_FUNCTION } from 'trivi-constants'
import { colors } from 'variables'
import { formatToMoney } from 'utils/formatters'
import { jsx } from '@emotion/core'
import memoize from 'memoize-one'

type Props = {|
	...WithTranslateProps,
	type?: CardResponseCta,
	params?: CardResponseParameters,
	disabled?: boolean,
	onPayment: () => void,
	onMatch: () => void,
	onAccountDocumentsUploadRemainer: () => void,
	onWageUploadRemainer: () => void,
|}

class CardButton extends Component<Props> {
	onClick = () => {
		if (!this.props.type) return null
		switch (this.props.type) {
			case 'unmatchedPayment.match':
				return this.props.onMatch()
			case 'payment.pay':
				return this.props.onPayment()
			case 'accountingdocument.upload.reminder':
				return this.props.onAccountDocumentsUploadRemainer()
			case 'wage.upload.reminder':
				return this.props.onWageUploadRemainer()
			default:
				return EMPTY_FUNCTION
		}
	}

	getLabelWithAmount = (label: string, receiving: ?boolean) => {
		const payment = this.props.params && this.props.params.payment
		const amount = payment && payment.amount ? (receiving ? -1 * payment.amount : payment.amount) : 0
		const currency = payment && payment.currency ? payment.currency : false
		return (
			<Fragment>
				{label}
				{payment && amount && currency && (
					<span>
						{formatToMoney(amount, {
							currency,
							minimumFractionDigits: 2,
						})}
					</span>
				)}
			</Fragment>
		)
	}

	getLabelText = () => {
		if (!this.props.type) return null
		switch (this.props.type) {
			case 'payment.pay':
				if (this.props.params && this.props.params.payment) {
					if (this.props.params.payment.amount === 0) {
						return this.props.t('myCompany.card.buttons.zero')
					}
					if (this.props.params.payment.amount && this.props.params.payment.amount < 0) {
						return this.getLabelWithAmount(this.props.t('myCompany.card.buttons.receive'), true)
					}
				}
				return this.getLabelWithAmount(this.props.t('myCompany.card.buttons.pay'))

			case 'payment.zero':
				return this.props.t('myCompany.card.buttons.zero')
			case 'accountingdocument.upload.reminder':
				return this.props.t('myCompany.card.buttons.upload')
			case 'wage.upload.reminder':
				return this.props.t('myCompany.card.buttons.uploadWage')
			case 'payment.receive':
				return this.getLabelWithAmount(this.props.t('myCompany.card.buttons.receive'), true)
			case 'unmatchedPayment.match':
				return this.props.t('myCompany.card.buttons.match_interval', {
					postProcess: 'interval',
					count: (this.props.params && this.props.params.unmatchedPaymentCount) || 0,
				})
			default:
				null
		}
	}

	isCtaDisabled = (disabled: ?boolean, type: ?CardResponseCta) => {
		return disabled || !this.showIcon(type)
	}

	showIcon = (type: ?CardResponseCta) => {
		return !!(
			type !== 'payment.zero' &&
			type !== 'payment.receive' &&
			((type === 'payment.pay' &&
				this.props.params &&
				this.props.params.payment &&
				this.props.params.payment.amount &&
				this.props.params.payment.amount > 0) ||
				type === 'accountingdocument.upload.reminder' ||
				type === 'wage.upload.reminder' ||
				type === 'unmatchedPayment.match')
		)
	}

	getStyles = memoize((disabled: boolean, showIcon: boolean) => {
		return {
			root: {
				display: 'inline-block',
				pointerEvents: disabled ? 'none' : 'all',
				cursor: disabled ? 'initial' : 'pointer',
				transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1)',
				borderStyle: 'solid',
				borderWidth: 1,
				backgroundColor: colors.white,
				overflow: 'hidden',
				textAlign: 'center',
				userSelect: 'none',
				height: 34,
				lineHeight: '32px',
				borderRadius: 8,
				borderColor: '#979797',
				margin: 0,
				padding: 0,
				marginBottom: 5,
				'&:hover': {
					backgroundColor: colors.blackFaded3,
				},
			},
			label: {
				position: 'relative',
				verticalAlign: 'middle',
				whiteSpace: 'nowrap',
				fontSize: 15,
				letterSpacing: 0,
				margin: 0,
				userSelect: 'none',
				color: colors.black,
				fontWeight: 'normal',
				paddingLeft: 14,
				paddingRight: showIcon ? 10 : 14,
				display: 'flex',
				justifyContent: 'flex-start',
				alignItems: 'center',
				span: {
					marginLeft: 4,
					fontWeight: 600,
				},
			},
			icon: {
				display: showIcon ? 'block' : 'none',
				position: 'relative',
				marginLeft: 5,
				height: 22,
			},
		}
	})

	render() {
		if (!this.props.type) return null
		const disabled = this.isCtaDisabled(this.props.disabled, this.props.type)
		const showIcon = this.showIcon(this.props.type)
		const styles = this.getStyles(disabled, showIcon)

		return (
			<div onClick={this.onClick} css={styles.root}>
				<span css={styles.label}>
					{this.getLabelText()}
					<span css={styles.icon}>
						<ChevronRight size={20} disabled />
					</span>
				</span>
			</div>
		)
	}
}

export default withTranslate(CardButton)
