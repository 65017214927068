import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { throttle } from 'lodash-es'
import EventListener, { withOptions } from 'react-event-listener'
import { autoTestId, passAutoTestId } from 'utils/tests/autotest'
import PropTypes from 'prop-types'
import RenderToLayer from 'components/internal/RenderToLayer'
import EnhancedButton from 'components/internal/EnhancedButton'
import IconButton from 'components/icon-button'
import Menu from 'components/menu'
import Paper from 'components/Paper'
import Direction from 'components/direction'
import DropDownArrow from 'components/svg-icons/navigation/arrow-drop-down'
import DropUpArrow from 'components/svg-icons/navigation/arrow-drop-up'
import { colors } from 'variables'

class SelectButton extends Component {
	static propTypes = {
		/**
		 * The `MenuItem` elements to populate the select field with.
		 * If the menu items have a `label` prop, that value will
		 * represent the selected menu item in the rendered select field.
		 */
		children: PropTypes.node,
		/**
		 * The content of the label.
		 */
		labelText: PropTypes.node,
		/**
		 * The id prop for the text field.
		 */
		id: PropTypes.string,
		/**
		 * Callback function fired when the button is clicked.
		 *
		 * @param {object} event Click event targeting the button.
		 */
		onClick: PropTypes.func,
		/**
		 * Callback function fired when a menu item is selected with a clicked.
		 *
		 * @param {object} event Click event targeting the selected menu item element.
		 * @param {object} child The selected element.
		 */
		onItemClick: PropTypes.func,
		iconTooltip: PropTypes.string,
		disabled: PropTypes.bool,
	}

	state = {
		hovered: false,
	}

	static defaultProps = {
		onItemClick: () => {},
		onClick: () => {},
	}

	constructor(props) {
		super(props)
		this.handleResize = throttle(this.setPlacement, 100)
		this.handleScroll = throttle(this.setPlacement.bind(this, true), 10, { leading: true, trailing: true })

		this.state = {
			open: false,
		}
	}

	componentDidMount() {
		this.setPlacement()
	}

	componentDidUpdate() {
		this.setPlacement()
	}

	componentWillUnmount() {
		this.handleResize.cancel()
		this.handleScroll.cancel()
		clearTimeout(this.timerCloseId)
	}

	isOpen() {
		return this.state.open
	}

	close() {
		if (!this.isOpen()) {
			return
		}

		this.setState({ open: false })
	}

	open(event) {
		this.setState({
			open: true,
		})

		event.preventDefault()
	}

	handleItemClick = (event, child) => {
		if (!this.props.disabled) {
			this.timerCloseId = setTimeout(() => {
				this.close()
			}, 200)
		}

		this.props.onItemClick(event, child)
	}

	handleIconClick = event => {
		if (!this.props.disabled && this.isOpen()) {
			this.close()
		} else {
			this.open(event)
		}
	}

	handleEscKeyDownMenu = event => {
		this.close(event)
	}

	componentClickAway = event => {
		event.preventDefault()
		this.close()
	}

	getAnchorPosition(el) {
		if (!el) {
			el = ReactDOM.findDOMNode(this)
		}

		const rect = el.getBoundingClientRect()

		return {
			top: rect.top,
			left: rect.left,
			width: el.offsetWidth,
			bottom: rect.bottom || rect.top + el.offsetHeight,
		}
	}

	setPlacement = async () => {
		if (!this.state.open) return
		const layer = await this.refs.layer.getLayer()
		if (!layer) return

		const targetEl = layer.children[0]
		if (!targetEl) {
			return
		}

		const anchorEl = this.root

		const anchor = this.getAnchorPosition(anchorEl)

		const targetPosition = {
			top: anchor.bottom,
			left: anchor.left,
			width: anchor.width,
		}

		targetEl.style.top = `${Math.round(Math.max(0, targetPosition.top - 1))}px`
		targetEl.style.left = `${Math.round(Math.max(0, targetPosition.left))}px`
		targetEl.style.width = `${targetPosition.width}px`
		targetEl.style.maxHeight = `${window.innerHeight}px`
	}

	handleMouseEnter = () => {
		this.setState({ hovered: true })
	}

	handleMouseLeave = () => {
		this.setState({ hovered: false })
	}

	bindRoot = element => {
		this.root = element
	}

	renderLayer = () => {
		const styles = getStyles(this.props, this.state)

		return (
			<Paper style={styles.dropdown} zDepth={3} {...passAutoTestId(this.props.autoTestId, 'dropdown')}>
				<Menu
					onEscKeyDown={this.handleEscKeyDownMenu}
					onItemClick={this.handleItemClick}
					style={styles.menu}
					autoWidth={false}
					{...passAutoTestId(this.props.autoTestId, 'menu')}
				>
					{this.props.children}
				</Menu>
			</Paper>
		)
	}

	renderIcon = () => {
		const styles = getStyles(this.props, this.state)

		return (
			(this.props.issued || this.props.received) && (
				<span style={styles.direction}>
					<Direction issued={this.props.issued} received={this.props.received} size={32} />
				</span>
			)
		)
	}

	render() {
		const styles = getStyles(this.props, this.state)

		return (
			<div style={styles.root} ref={this.bindRoot} {...autoTestId(this.props.autoTestId)}>
				<div style={styles.buttonContainer}>
					<div style={styles.container} ref={'container'} {...autoTestId(this.props.autoTestId, 'button')}>
						<EnhancedButton
							onMouseLeave={this.handleMouseLeave}
							onMouseEnter={this.handleMouseEnter}
							onClick={this.props.onClick}
							style={styles.button}
						>
							<div style={styles.buttonContent}>
								{this.renderIcon()}
								<span style={styles.label}>{this.props.labelText}</span>
							</div>
						</EnhancedButton>

						{this.props.children ? <div style={styles.line} /> : null}
						{this.props.children ? (
							<IconButton
								ref="iconButton"
								onClick={this.handleIconClick}
								style={styles.iconButton}
								color={colors.blackFaded40}
								hoverColor={this.isOpen() ? colors.blackFaded40 : colors.black}
								tooltip={!this.isOpen() ? this.props.iconTooltip : null}
								{...passAutoTestId(this.props.autoTestId, 'icon-button')}
							>
								{this.isOpen() ? <DropUpArrow /> : <DropDownArrow />}
							</IconButton>
						) : null}
					</div>
				</div>
				<EventListener
					target="window"
					onScroll={withOptions(this.handleScroll, { capture: true })}
					onResize={this.handleResize}
				/>
				<RenderToLayer
					ref="layer"
					open={this.isOpen()}
					componentClickAway={this.componentClickAway}
					useLayerForClickAway={false}
					render={this.renderLayer}
					anchorEl={this.root}
				/>
			</div>
		)
	}
}

function getStyles(props, state) {
	const { received, issued, disabled } = props
	const { open, hovered } = state
	const isLarge = received || issued

	return {
		root: {
			display: isLarge ? 'block' : 'inline-block',
			width: 'auto',
			position: 'relative',
			height: 'auto',
			zIndex: 1,
			pointerEvents: disabled ? 'none' : 'all',
			opacity: disabled ? 0.8 : 1,
		},
		buttonContainer: {
			display: 'block',
			width: 'auto',
			height: 'auto',
			position: 'relative',
			overflow: 'hidden',
			borderStyle: 'solid',
			borderColor: colors.gray400,
			borderWidth: 1,
			boxShadow: `3px 3px 0 ${colors.blackFaded8}`,
			borderTopLeftRadius: 3,
			borderTopRightRadius: 3,
			borderBottomRightRadius: open ? 0 : 3,
			borderBottomLeftRadius: open ? 0 : 3,
			background: open ? colors.gray200 : hovered ? colors.gray100 : colors.white,
		},
		container: {
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center',
			alignContent: 'center',
			width: '100%',
		},
		buttonContent: {
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center',
			alignContent: 'center',
			width: '100%',
		},
		button: {
			flexGrow: 1,
			height: isLarge ? 64 : 36,
			lineHeight: isLarge ? '64px' : '36px',
			borderBottom: open ? `1px solid ${colors.grey400}` : 'none',
			transition: 'none',
		},
		label: {
			verticalAlign: 'baseline',
			padding: '0px 18px',
			fontWeight: 'normal',
			color: disabled
				? colors.blackFaded80
				: issued
				? colors.black
				: received
				? colors.red
				: open
				? '#7f7f7f'
				: colors.black,
			flex: '1 0 auto',
			fontSize: isLarge ? 24 : 14,
			lineHeight: isLarge ? '64px' : '36px',
		},
		line: {
			height: open ? (isLarge ? 64 : 36) : isLarge ? 45 : 26,
			backgroundColor: open ? colors.grey400 : '#d5d5d5',
			boxShadow: open ? `-1px 0px 0px 1px ${colors.blackFaded8}` : 'none',
			width: 1,
			flex: '0 0 1px',
			alignSelf: 'center',
			borderBottom: open ? `1px solid ${colors.grey400}` : 'none',
		},
		iconButton: {
			width: isLarge ? 64 : 40,
			height: isLarge ? 64 : 36,
			padding: isLarge ? 20 : 7,
			margin: 0,
			border: 'none',
			borderWidth: 0,
			position: 'relative',
			flex: `0 0 ${isLarge ? '64px' : '40px'}`,
			transition: 'none',
			borderRadius: 0,
			backgroundColor: open ? colors.white : hovered ? colors.gray100 : colors.transparent,
		},
		dropdown: {
			width: '100%',
			borderTop: 'none',
			borderTopLeftRadius: 0,
			borderTopRightRadius: 0,
			position: 'absolute',
			transition: 'none',
			boxShadow: '3px 4px 0 rgba(0, 0, 0, 0.08)',
			pointerEvents: 'all',
		},
		menu: {
			position: 'relative',
			minWidth: '100%',
			padding: isLarge ? '10px 0' : 0,
		},
		direction: {
			marginLeft: 24,
			flex: '0 0 auto',
		},
	}
}

export default SelectButton
