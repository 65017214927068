import React from 'react'
import SvgIcon from 'components/SvgIcon'
import pure from 'recompose/pure'

let Eye = props => (
	<SvgIcon {...props}>
		<g stroke="none" strokeWidth="1" fill="#121D27" fillRule="evenodd" opacity="0.600000024">
			<path d="M11.75,7 C14.6145977,7 17.5312352,8.6796707 20.5,12.0390625 C19.7968715,12.742191 19.3476572,13.1848949 19.1523438,13.3671875 C18.9570303,13.5494801 18.4817746,13.9531219 17.7265625,14.578125 C16.9713504,15.2031281 16.3593773,15.6328113 15.890625,15.8671875 C15.4218727,16.1015637 14.8033893,16.348957 14.0351562,16.609375 C13.2669232,16.869793 12.5052121,17 11.75,17 C10.8124953,17 9.95312891,16.8828137 9.171875,16.6484375 C8.39062109,16.4140613 7.60937891,16.0104195 6.828125,15.4375 C6.04687109,14.8645805 5.43489805,14.3763041 4.9921875,13.9726562 C4.54947695,13.5690084 3.88542109,12.9114629 3,12 C4.6927168,10.3072832 6.19660801,9.05078535 7.51171875,8.23046875 C8.82682949,7.41015215 10.2395758,7 11.75,7 Z M9.25,14.5 C9.95312852,15.2031285 10.7864535,15.5546875 11.75,15.5546875 C12.7135465,15.5546875 13.5403611,15.2031285 14.2304688,14.5 C14.9205764,13.7968715 15.265625,12.9635465 15.265625,12 C15.265625,11.0364535 14.9205764,10.2031285 14.2304688,9.5 C13.5403611,8.79687148 12.7135465,8.4453125 11.75,8.4453125 C10.7864535,8.4453125 9.95963887,8.79687148 9.26953125,9.5 C8.57942363,10.2031285 8.234375,11.0364535 8.234375,12 C8.234375,12.9635465 8.57291328,13.7968715 9.25,14.5 Z M11.75,10.75 C11.75,11.1145852 11.8671863,11.4140613 12.1015625,11.6484375 C12.3359387,11.8828137 12.6354148,12 13,12 C13.260418,12 13.520832,11.9088551 13.78125,11.7265625 L13.78125,12 C13.78125,12.5729195 13.5859395,13.0611959 13.1953125,13.4648438 C12.8046855,13.8684916 12.3229195,14.0703125 11.75,14.0703125 C11.1770805,14.0703125 10.6953145,13.8684916 10.3046875,13.4648438 C9.91406055,13.0611959 9.71875,12.5729195 9.71875,12 C9.71875,11.4270805 9.91406055,10.9388041 10.3046875,10.5351562 C10.6953145,10.1315084 11.1770805,9.9296875 11.75,9.9296875 L12.0625,9.9296875 C11.8541656,10.1901055 11.75,10.4635402 11.75,10.75 Z" />
		</g>
	</SvgIcon>
)
Eye = pure(Eye)

export default Eye
