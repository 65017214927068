// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1zzhT2T8TPJ7iEaFhJP7gX {\n\tpointer-events: all;\n\tposition: absolute;\n\ttop: 40px;\n\tright: 0px;\n\ttransform: translateX(100%);\n\tvertical-align: middle;\n}\n", "",{"version":3,"sources":["webpack://./modules/accounting-document/components/invoice-elements/side-buttons.css"],"names":[],"mappings":"AAAA;CACC,mBAAA;CACA,kBAAA;CACA,SAAA;CACA,UAAA;CACA,2BAAA;CACA,sBAAA;AACD","sourcesContent":[".sideButtonsWrapper {\n\tpointer-events: all;\n\tposition: absolute;\n\ttop: 40px;\n\tright: 0px;\n\ttransform: translateX(100%);\n\tvertical-align: middle;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sideButtonsWrapper": "_1zzhT2T8TPJ7iEaFhJP7gX"
};
export default ___CSS_LOADER_EXPORT___;
