//@flow

import React, { Component } from 'react'
import { connect } from 'react-redux'
import deepEqual from 'deep-equal'
import {
	withTranslate,
	withOrganizationSettings,
	type WithTranslateProps,
	type WithOrganizationSettingsProps,
} from 'wrappers'
import TextField from 'components/TextField'
import styles from '../settings.css'
import type { BootstrapAction, Dispatch, State } from 'types'
import type { OrganizationNoteExtraction } from '../../../common/models/swagger-model'
import { getCurrentOrganizationNoteExtraction } from '../../selectors'
import { loadOrganizationNoteExtraction, updateOrganizationNoteExtraction } from '../../actions/basics-actions'
import SaveSettings from '../save-settings'

export type Props = {|
	...StateProps,
	...DispatchProps,
	...WithOrganizationSettingsProps,
	...WithTranslateProps,
|}

type StateProps = {|
	noteExtraction: ?string,
|}

type DispatchProps = {|
	getOrganizationNoteExtraction: () => void,
	updateOrganizationNoteExtraction: (note: OrganizationNoteExtraction) => Promise<*>,
|}

type ComponentState = {|
	noteExtraction: ?string,
|}

class Extraction extends Component<Props, ComponentState> {
	savedState: ?ComponentState = null
	altered: boolean = false
	loading: boolean = false

	state = this.getStateFromProps(this.props)

	saveState = () => {
		this.savedState = this.state
	}

	restoreSavedState = () => {
		if (this.savedState) {
			this.setState(this.savedState)
		}
		this.resetSavedState()
	}

	resetSavedState = () => {
		this.altered = false
		this.savedState = null
		this.loading = false
		this.forceUpdate()
	}

	handleRestoreClick = () => {
		this.restoreSavedState()
	}

	getStateFromProps(props: Props): ComponentState {
		return {
			noteExtraction: props.noteExtraction,
		}
	}

	UNSAFE_componentWillReceiveProps(newProps: Props) {
		this.setState(this.getStateFromProps(newProps))
	}

	componentDidMount() {
		if (!this.props.noteExtraction) {
			this.props.getOrganizationNoteExtraction()
		}
	}

	onNoteExtractionChange = (event: ?SyntheticInputEvent<HTMLInputElement>, noteExtraction: string) => {
		this.changeSettings({ noteExtraction })
	}

	changeSettings = (partialState: Object) => {
		if (!this.altered) {
			this.altered = true
			this.saveState()
		}
		this.setState(partialState, () => {
			deepEqual(this.state, this.savedState) && this.restoreSavedState()
		})
	}

	saveSettings = async () => {
		this.loading = true
		await this.props.updateOrganizationNoteExtraction({ text: this.state.noteExtraction })
		this.resetSavedState()
	}

	render() {
		const { t } = this.props

		return (
			<div>
				<div className={styles.row}>
					<TextField
						value={this.state.noteExtraction}
						onChange={this.onNoteExtractionChange}
						labelText={t('settings.extraction.noteExtraction')}
						autoTestId="settings-organization-edit-note-extraction"
						fullWidth
						multiLine
						autoHeight
						name="note-extraction"
					/>
				</div>
				<SaveSettings
					visible={this.altered}
					saving={!!this.loading}
					onSave={this.saveSettings}
					onRestore={this.restoreSavedState}
				/>
			</div>
		)
	}
}

const mapStateToProps = (state: State): StateProps => {
	return {
		noteExtraction: getCurrentOrganizationNoteExtraction(state),
	}
}

const mapDispatchToProps = (dispatch: Dispatch<BootstrapAction>): DispatchProps => {
	return {
		getOrganizationNoteExtraction: () => {
			dispatch(loadOrganizationNoteExtraction())
		},
		updateOrganizationNoteExtraction: async (note: OrganizationNoteExtraction) => {
			await dispatch(updateOrganizationNoteExtraction(note))
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslate(withOrganizationSettings(Extraction)))
