/* @flow */

export function tasksRoute() {
	return '/tasks'
}

// export function taskCreateRoute() {
// 	return '/tasks/create'
// }

export function taskRoute(taskId: string) {
	return `/tasks/${taskId}`
}

export function taskEditRoute(taskId: string) {
	return `/tasks/${taskId}/edit`
}
