/* @flow */

import React, { Component } from 'react'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import type { AccountingDocument, CountryVariantSpecific } from 'types'
import PaymentState from 'modules/accounting-document/components/accounting-document-list/payment-state'
import { ROUNDING_TYPES } from 'types/convertor'
// import { EetText } from 'components'
import styles from './document-summary.css'

type Props = {|
	...WithTranslateProps,
	exchRate: ?number,
	vatExchRate: ?number,
	sadExchRate: ?number,
	roundingType: ?number,
	processingState: string,
	accountingDocument: ?AccountingDocument,
	countryVariantSpecific: ?CountryVariantSpecific,
|}

class AccountingDocumentSummary extends Component<Props> {
	render() {
		const {
			t,
			processingState,
			roundingType,
			exchRate,
			vatExchRate,
			sadExchRate,
			// countryVariantSpecific,
			accountingDocument,
		} = this.props

		return (
			<div className={styles.root}>
				<div className={styles.column}>
					<div className={styles.head}>{t('bank.columns.paymentState')}</div>
					<div className={styles.body}>
						{accountingDocument && <PaymentState accountingDocument={accountingDocument} />}
					</div>
				</div>

				<div className={styles.column}>
					<div className={styles.head}>{t('bank.columns.processingState')}</div>
					<div className={styles.body}>{t(`bank.processingStates.${processingState}`)}</div>
				</div>

				<div className={styles.column}>
					<div className={styles.head}>{t('invoice.settings.booking.roundingTypeLabel')}</div>
					<div className={styles.body}>
						{roundingType && t(`invoice.settings.booking.roundingTypes.${ROUNDING_TYPES[roundingType.toString()]}`)}
					</div>
				</div>

				<div className={styles.column}>
					<div className={styles.head}>{t('invoice.settings.booking.exchRateLabel')}</div>
					<div className={styles.body}>{exchRate}</div>
				</div>

				<div className={styles.column}>
					<div className={styles.head}>{t('invoice.settings.booking.vatExchRateLabel')}</div>
					<div className={styles.body}>{vatExchRate}</div>
				</div>

				<div className={styles.column}>
					<div className={styles.head}>{t('invoice.settings.booking.sadExchRateLabel')}</div>
					<div className={styles.body}>{sadExchRate}</div>
				</div>

				{/*<div className={styles.column}>*/}
				{/*	<div className={styles.head}>EET</div>*/}
				{/*	<div className={styles.body}>*/}
				{/*		<EetText countryVariantSpecific={countryVariantSpecific} />*/}
				{/*	</div>*/}
				{/*</div>*/}
			</div>
		)
	}
}

export default withTranslate(AccountingDocumentSummary)
