/* @flow */

import { type Node } from 'react'
import { connect } from 'react-redux'
import Scans from '../../components/invoice-elements/scans'
import type {
	State,
	Dispatch,
	AccountingDocument,
	AccountingDocumentScan,
	CashRegisters,
	AccountingDocumentReduxAction,
} from 'types'
import { getCashRegisters } from 'modules/cash-register/selectors'
import { getAccountingDocumentScans, getAccountingDocument } from '../../selectors'
import { attachAccountingDocumentScan, deleteAccountingDocumentScan } from 'modules/accounting-document/actions'

type OwnProps = {|
	accountingDocumentId: string,
	unclassified?: boolean,
	Classification?: Node,
|}

type StateProps = {|
	scans: ?Array<AccountingDocumentScan>,
	accountingDocument: ?AccountingDocument,
	cashRegisters: CashRegisters,
|}

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => {
	return {
		scans: getAccountingDocumentScans(state, ownProps.accountingDocumentId),
		accountingDocument: getAccountingDocument(state, ownProps.accountingDocumentId),
		cashRegisters: getCashRegisters(state),
	}
}

type DispatchProps = {|
	deleteScan: (scanId: string) => void,
	attachAccountingDocumentScan: (scan: File) => Promise<*>,
|}

const mapDispatchToProps = (dispatch: Dispatch<AccountingDocumentReduxAction>, ownProps: OwnProps): DispatchProps => {
	return {
		deleteScan: (scanId: string) => {
			dispatch(deleteAccountingDocumentScan(ownProps.accountingDocumentId, scanId))
		},
		attachAccountingDocumentScan: (scan: File) => {
			return dispatch(attachAccountingDocumentScan(ownProps.accountingDocumentId, scan))
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Scans)
