/* @flow */

import React, { Component } from 'react'
import { autoTestId, type AutoTestProps } from 'utils/tests/autotest'
import { colors } from 'variables'

function getStyles(props: Props) {
	const { disabled, inline } = props

	const styles = {
		root: {
			cursor: props.disabled ? 'not-allowed' : 'pointer',
			position: 'relative',
			overflow: 'visible',
			display: 'table',
			transition: 'all 200ms ease-in-out',
			width: inline ? 'auto' : '100%',
		},
		icon: {
			transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1)',
			position: 'relative',
			display: 'block',
			flexShrink: 0,
			marginLeft: 0,
			height: 16,
			width: 16,
			marginRight: 10,
		},
		target: {
			transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1)',
			position: 'absolute',
			opacity: 1,
			transform: 'scale(1)',
		},
		iconDefault: {
			position: 'absolute',
			width: 16,
			height: 16,
			top: 0,
			left: 0,
			borderRadius: '50%',
			border: '2px solid transparent',
		},
		iconDefaultDot: {
			position: 'absolute',
			top: 2,
			left: 2,
			width: 8,
			height: 8,
			borderRadius: '50%',
		},
		targetDefault: {
			borderColor: '#979797',
			background: 'transparent',
		},
		fill: {
			position: 'absolute',
			opacity: 1,
			transform: 'scale(0)',
			transformOrigin: '50% 50%',
			transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1)',
		},
		fillDefault: {
			borderColor: colors.blue,
		},
		fillDefaultDot: {
			backgroundColor: colors.blue,
		},
		targetWhenChecked: {
			opacity: 0,
			transform: 'scale(0)',
		},
		fillWhenChecked: {
			opacity: 1,
			transform: 'scale(1)',
		},
		targetDefaultWhenDisabled: {
			borderColor: '#979797',
		},
		fillDefaultWhenDisabled: {
			borderColor: colors.blue,
		},
		fillDefaultDotWhenDisabled: {
			backgroundColor: colors.blue,
		},
		label: {
			float: 'left',
			position: 'relative',
			display: 'block',
			lineHeight: '24px',
			color: disabled ? colors.disabled : colors.black,
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',
			width: 'calc(100% - 26px)',
			fontSize: 14,
			margin: '0',
		},
		input: {
			position: 'absolute',
			cursor: 'inherit',
			pointerEvents: 'all',
			opacity: 0,
			width: '100%',
			height: '100%',
			zIndex: 2,
			left: 0,
			boxSizing: 'border-box',
			padding: 0,
			margin: 0,
		},
		controls: {
			width: '100%',
			height: '100%',
			display: 'flex',
			justifyContent: 'flex-start',
			alignItems: 'center',
			alignContent: 'center',
		},
	}

	if (inline) {
		styles.root.display = 'inline-block'
		styles.label.lineHeight = '24px'
		styles.label.width = 'auto'
		styles.label.float = 'none'
		styles.label.display = 'inline'
		styles.label.margin = '0 20px 0 0px'
	}

	return styles
}

type Props = {|
	...AutoTestProps,
	checked?: boolean,
	disabled?: boolean,
	inline?: boolean,
	iconStyle?: Object,
	labelStyle?: Object,
	style?: Object,
	value: string | number,
	label?: string,
	onCheck?: (SyntheticInputEvent<HTMLInputElement>, string | number) => void,
	onClick?: () => void,
|}

class RadioButton extends Component<Props> {
	handleChange = (event: SyntheticInputEvent<HTMLInputElement>) => {
		this.props.onCheck && this.props.onCheck(event, this.props.value)
	}

	handleClick = (event: SyntheticMouseEvent<HTMLInputElement>) => {
		this.props.onClick && this.props.onClick()
		event.stopPropagation()
	}

	render() {
		const { checked, iconStyle, labelStyle, disabled, style, label } = this.props

		const styles = getStyles(this.props)

		const uncheckedStylesDefault = {
			...styles.iconDefault,
			...styles.target,
			...styles.targetDefault,
			...(checked ? styles.targetWhenChecked : {}),
			...iconStyle,
			...(disabled ? styles.targetDefaultWhenDisabled : {}),
		}

		const checkedStylesDefault = {
			...styles.iconDefault,
			...styles.fill,
			...styles.fillDefault,
			...(checked ? styles.fillWhenChecked : {}),
			...iconStyle,
			...(disabled ? styles.fillDefaultWhenDisabled : {}),
		}

		const checkedStylesIconDot = {
			...styles.iconDefaultDot,
			...styles.fill,
			...styles.fillDefaultDot,
			...(checked ? styles.fillWhenChecked : {}),
			...(disabled ? styles.fillDefaultDotWhenDisabled : {}),
		}

		return (
			<div style={{ ...styles.root, ...style }} onClick={this.handleClick} {...autoTestId(this.props.autoTestId)}>
				<input
					onClick={this.handleClick}
					ref="checkbox"
					type="radio"
					style={styles.input}
					disabled={this.props.disabled}
					onChange={this.handleChange}
					checked={this.props.checked}
				/>
				<div style={styles.controls}>
					<div style={{ ...styles.icon, ...iconStyle }}>
						<div>
							<div style={uncheckedStylesDefault} />
							<div style={checkedStylesDefault}>
								<div style={checkedStylesIconDot} />
							</div>
						</div>
					</div>
					{this.props.label && <label style={{ ...styles.label, ...labelStyle }}>{this.props.label}</label>}
				</div>
			</div>
		)
	}
}

export default RadioButton
