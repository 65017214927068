/* @flow */

import { concat, filter, find, unionBy } from 'lodash-es'
import type { Action } from '../actions/action-types'
import type { StickyNote, BillingsState } from 'types'

export type State = {
	stickyNotes: Array<StickyNote>,
	billings: BillingsState,
}

export const initialState: State = {
	stickyNotes: [],
	billings: {
		selectedPeriod: null,
		cashedBillings: {},
		fetching: false,
	},
}

function cloneState(state: State) {
	return { ...state }
}

export default (state: State = initialState, action: Action): State => {
	switch (action.type) {
		case 'START_DELETE_STICKY_NOTE': {
			const { params } = action
			if (action.params != null && action.params.stickyNoteId != null)
				return {
					...state,
					stickyNotes: filter(state.stickyNotes, (stickyNote: StickyNote) => stickyNote.id != params.stickyNoteId),
				}
			else return cloneState(state)
		}
		case 'FINISH_DELETE_STICKY_NOTE':
			if (action.serverError != null)
				return {
					...state,
					stickyNotes: unionBy([action.original], state.stickyNotes, 'id'),
				}
			else return cloneState(state)

		case 'START_CREATE_STICKY_NOTE':
			return {
				...state,
				stickyNotes: concat(state.stickyNotes, [action.body]),
			}
		case 'FINISH_CREATE_STICKY_NOTE':
			if (action.response != null)
				return {
					...state,
					stickyNotes: assignCreated(action.response, state.stickyNotes),
				}
			else
				return {
					...state,
					stickyNotes: remove(action.original, state.stickyNotes),
				}
		case 'START_GET_STICKY_NOTES':
			return cloneState(state)
		case 'FINISH_GET_STICKY_NOTES':
			if (action.response != null)
				return {
					...state,
					stickyNotes: unionBy(action.response, state.stickyNotes, 'id'),
				}
			else return cloneState(state)
		case 'START_UPDATE_STICKY_NOTE':
			return {
				...state,
				stickyNotes: assignById(action.body, state.stickyNotes),
			}
		case 'FINISH_UPDATE_STICKY_NOTE':
			if (action.response != null)
				return {
					...state,
					stickyNotes: assignById(action.response, state.stickyNotes),
				}
			else
				return {
					...state,
					stickyNotes: assignById(action.original, state.stickyNotes),
				}
		case 'START_FETCHING_BILLING_REPORT':
			return {
				...state,
				billings: {
					...state.billings,
					fetching: true,
				},
			}
		case 'CHANGE_SELECTED_PERIOD':
			return {
				...state,
				billings: {
					...state.billings,
					selectedPeriod: action.payload,
				},
			}
		case 'FINISH_FETCHING_BILLING_REPORT':
			if (!action.serverError) {
				return {
					...state,
					billings: {
						...state.billings,
						fetching: false,
						cashedBillings: {
							...state.billings.cashedBillings,
							[action.selectedDate]: action.payload,
						},
					},
				}
			} else {
				return {
					...state,
					billings: {
						...state.billings,
						fetching: false,
						selectedPeriod: null,
					},
				}
			}

		default:
			return cloneState(state)
	}
}

function assignCreated(item: StickyNote, items: Array<StickyNote>) {
	const newArray = [...items]

	const origItem = find(newArray, (sn: StickyNote) => sn.id == null)
	const index = items.indexOf(origItem)
	newArray[index] = item

	return newArray
}

function assignById(item: StickyNote, items: Array<StickyNote>) {
	const newArray = [...items]

	const origItem = find(newArray, (sn: StickyNote) => sn.id == item.id)
	const index = items.indexOf(origItem)
	newArray[index] = item

	return newArray
}

function remove(item: StickyNote, items: Array<StickyNote>) {
	return [
		...items.filter((sn: StickyNote) => {
			return sn.id != item.id
		}),
	]
}
