/* @flow */

import React, { Component } from 'react'
import { withRouter } from 'react-router'
import Helmet from 'react-helmet'
import { withTranslate, withNotify, type WithTranslateProps, type WithNotifyProps } from 'wrappers'
import type { Event, FormValidationProps, InternalUser, WithRouterProps } from 'types'
import type { InternalUsersSignupPostResponse } from 'modules/common/models/api-model'
import type { Action as UserAction } from 'modules/user/actions/action-types'
import { Dialog, Button, TextField } from 'components'
import SelectNext from 'components/select-next'
import styles from './internal-register-form.css'
import { Validator } from 'utils'
import type { OptionsType, OptionType } from 'components/select-next'

type Props = {|
	...WithTranslateProps,
	...FormValidationProps,
	...WithNotifyProps,
	...WithRouterProps,
	onInternalUserUpdate: InternalUser => ?Promise<UserAction>,
	onInternalUserSignUp: InternalUser => Promise<UserAction>,
	onSuccess?: InternalUsersSignupPostResponse => void,
	editedUser: ?InternalUser,
	open: boolean,
	onRequestClose: () => void,
|}

type State = {|
	email: string,
	firstName: string,
	lastName: string,
	password: ?string,
	permissions: Array<string>,
	errors: Object,
|}

class InternalRegisterForm extends Component<Props, State> {
	constructor(props: Props) {
		super(props)

		const { editedUser } = props
		if (editedUser != null)
			this.state = {
				email: editedUser.email || '',
				permissions: editedUser.permissions || [],
				firstName: editedUser.firstname || '',
				lastName: editedUser.lastname || '',
				password: null,
				errors: {},
			}
		else
			this.state = {
				email: '',
				permissions: [],
				firstName: '',
				lastName: '',
				password: '',
				errors: {},
			}
	}

	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		const { editedUser } = nextProps
		if (editedUser != null)
			this.setState({
				email: editedUser.email,
				firstName: editedUser.firstname || '',
				lastName: editedUser.lastname || '',
				permissions: editedUser.permissions || [],
				errors: {},
			})
	}

	getClientValidationErrors() {
		const errors: Object = {}
		const { t } = this.props

		if (this.state.email) {
			if (!Validator.email(this.state.email)) {
				errors.email = t('clientError.notAnEmail')
			}
		} else {
			errors.email = t('application.validation.mandatory')
		}

		if (this.state.permissions.length == 0) {
			errors.permissions = t('application.validation.mandatory')
		}

		return errors
	}

	handleFormSubmit = async (event: ?Event) => {
		event && event.preventDefault()

		const errors = this.getClientValidationErrors()
		const isValid = Object.keys(errors).length === 0

		if (!isValid) {
			this.setState({ errors })
			return
		}

		if (this.props.editedUser && this.props.editedUser.id) {
			const result: UserAction = await this.props.onInternalUserUpdate({
				id: this.props.editedUser.id,
				email: this.state.email,
				firstName: this.state.firstName,
				lastName: this.state.lastName,
				permissions: this.state.permissions,
			})

			if (result != null && !result.serverError) {
				this.props.onSuccess && this.props.onSuccess({})
			}
		} else {
			const result: UserAction = await this.props.onInternalUserSignUp({
				email: this.state.email,
				password: null,
				firstName: this.state.firstName,
				lastName: this.state.lastName,
				permissions: this.state.permissions,
			})

			if (result != null && !result.serverError) {
				this.props.onSuccess && this.props.onSuccess({})
			}
		}
	}

	handleFirstNameChange = (event: SyntheticInputEvent<HTMLInputElement>, firstName: ?string) => {
		if (firstName !== null && firstName !== undefined) {
			this.setState({ firstName })
		}
	}

	handleLastNameChange = (event: SyntheticInputEvent<HTMLInputElement>, lastName: ?string) => {
		if (lastName !== null && lastName !== undefined) {
			this.setState({ lastName })
		}
	}

	handleEmailChange = (event: SyntheticInputEvent<HTMLInputElement>, email: ?string) => {
		if (email !== null && email !== undefined) {
			this.setState({ email, errors: {} })
		}
	}

	handlePermissionChange = (permissions: OptionsType) => {
		this.setState({
			// $FlowFixMe
			permissions: permissions && permissions.map((permission: OptionType) => permission.value && permission.value),
			errors: {},
		})
	}

	render() {
		const { t } = this.props
		const { errors } = this.state
		const title =
			this.props.editedUser && this.props.editedUser.id
				? t('user.internal.editHeadline')
				: t('user.internal.createHeadline')
		const actions = [
			<Button
				tertiary
				key="sign-up"
				labelText={t('user.internal.saveInternalUser')}
				onClick={this.handleFormSubmit}
				autoTestId="internal-register-form-signup"
			/>,
			<Button
				key="cancel"
				labelText={t('settings.forms.cancelButton')}
				onClick={this.props.onRequestClose}
				transparent
				autoTestId="internal-register-form-cancel"
			/>,
		]

		return (
			<Dialog autoTestId="internal-register-form-dialog" actions={actions} title={title} open={this.props.open}>
				<Helmet>
					<title>{title}</title>
				</Helmet>
				<form onSubmit={this.handleFormSubmit} className={styles.root} noValidate>
					<div className={styles.row}>
						<TextField
							fullWidth
							labelText={t('user.register.emailLabel')}
							autoTestId="register-email"
							name="email"
							value={this.state.email}
							style={{
								fontSize: 14,
							}}
							onChange={this.handleEmailChange}
							clientError={errors.email}
						/>
					</div>
					<div className={styles.row}>
						<TextField
							fullWidth
							name="firstname"
							autoTestId="register-firstname"
							value={this.state.firstName}
							style={{
								fontSize: 14,
							}}
							labelText={t('user.register.firstNameLabel')}
							onChange={this.handleFirstNameChange}
						/>
					</div>
					<div className={styles.row}>
						<TextField
							fullWidth
							name="lastname"
							autoTestId="register-lastname"
							value={this.state.lastName}
							style={{
								fontSize: 14,
							}}
							labelText={t('user.register.lastNameLabel')}
							onChange={this.handleLastNameChange}
						/>
					</div>
					<div className={styles.row}>
						<SelectNext
							label={t('user.register.intenalUserPermissions')}
							autoTestId="register-permissions"
							value={this.state.permissions}
							onChange={this.handlePermissionChange}
							isMulti
							error={errors.permissions}
							options={[
								{ value: 'internal_assigned_access', label: t('user.permission.internal_assigned_access') },
								{ value: 'internal_full_access', label: t('user.permission.internal_full_access') },
								{ value: 'internal_extract', label: t('user.permission.internal_extract') },
							]}
						/>
					</div>
				</form>
			</Dialog>
		)
	}
}

export default withRouter(withTranslate(withNotify(InternalRegisterForm)))
