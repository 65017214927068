/* @flow */
/** @jsx jsx */

import { jsx } from '@emotion/core'
import IconButton from 'components/icon-button'
import DownIcon from 'components/svg-icons/hardware/keyboard-arrow-down'
import UpIcon from 'components/svg-icons/hardware/keyboard-arrow-up'

type Props = {|
	expanded: boolean,
	record: any,
	onExpand: (record: any, e: SyntheticEvent<HTMLElement>) => void,
|}

export default function RowExpandIcon(props: Props) {
	// (because rc-table)
	const onClick = (e: SyntheticEvent<HTMLElement>) => props.onExpand(props.record, e)
	return (
		<div css={styles.root}>
			<IconButton
				autoTestId="grid-row-expand"
				onClick={onClick} // eslint-disable-line react/jsx-no-bind
			>
				{props.expanded ? <UpIcon size={20} /> : <DownIcon size={20} />}
			</IconButton>
		</div>
	)
}

const styles = {
	root: {
		cursor: 'pointer',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
	},
}
