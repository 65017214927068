/* @flow */

import React, { Component } from 'react'
import { connect } from 'react-redux'
import CashRegisterMakeInventory from '../containers/cash-register-make-inventory'
import type { Dispatch, CashRegisterAction, WithRouterProps } from 'types'
import PopupSection from 'components/PopupSection'
import { cashRegisterRoute } from 'modules/cash-register/routing/routes'
import { push } from 'react-router-redux'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import Helmet from 'react-helmet'

type Props = {|
	...WithRouterProps,
	...WithTranslateProps,
	loadUsers: () => void,
	close: () => void,
|}

class CashRegisterMakeInventoryPage extends Component<Props> {
	render() {
		return (
			<PopupSection open onRequestClose={this.props.close}>
				<Helmet>
					<title>{this.props.t('cashRegister.inventory.header')}</title>
				</Helmet>
				<CashRegisterMakeInventory id={this.props.match.params.id} />
			</PopupSection>
		)
	}
}

const mapDispatchToProps = (dispatch: Dispatch<CashRegisterAction>, ownProps: Props & WithRouterProps) => {
	return {
		close: () => {
			dispatch(push(cashRegisterRoute(ownProps.match.params.id)))
		},
	}
}

export default withTranslate(connect(null, mapDispatchToProps)(CashRegisterMakeInventoryPage))
