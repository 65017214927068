/* @flow */

import type { CardResponse, CardsGrouped, State } from 'types'
import { groupBy, reduce, sortBy } from 'lodash-es'

import { ASAP } from '../constants/cards'
import { createSelector } from 'reselect'
import memoize from 'memoize-one'
import moment from 'moment'

export function getUncompletedCardsCount(state: State): number {
	const cards = getOrganizationCardsArray(state)
	if (!cards) return 0
	return cards.reduce((count: number, card: CardResponse) => {
		return card.completion && card.completion.isCompleted
			? count
			: card.type === 'trivi.unmatchedPayments.match.request' &&
			  card.params &&
			  parseInt(card.params.unmatchedPaymentCount) <= 0
			? count
			: count + 1
	}, 0)
}

export function getOrganizationCardsLoadedToDate(state: State): Date {
	return state.dashboard.cards.loadedToDate
}

export function getOrganizationCardsLoadedSinceDate(state: State): Date {
	return state.dashboard.cards.loadedSinceDate
}

export function getOrganizationCards(state: State): { [string]: CardResponse } {
	return state.dashboard.cards.data
}

export const getOrganizationCardsArray = createSelector(getOrganizationCards, (cards: { [string]: CardResponse }) => {
	return cards && Object.keys(cards).map((key: string) => cards[key])
})

export const getFutureCardsByDeadlineDatePure = memoize(
	(cards: Array<CardResponse>, todayIso: string): CardsGrouped => {
		const todayDate = new Date(todayIso)
		let sortedCards = sortBy(cards, (card: CardResponse) => card.deadlineDate || todayIso).filter(
			(card: CardResponse) => card.isCurrent || new Date(card.deadlineDate || todayDate) >= todayDate,
		)

		let firstCards = sortedCards.filter((card: CardResponse) => card.type === 'trivi.unmatchedPayments.match.request')
		let otherCards = sortedCards.filter((card: CardResponse) => card.type !== 'trivi.unmatchedPayments.match.request')

		sortedCards = firstCards.concat(otherCards)

		const groupedCards = groupBy(sortedCards, (card: CardResponse) => {
			const deadline = moment(card.deadlineDate || todayIso)
			if (card.isCurrent) {
				return ASAP
			} else {
				return deadline.format('YYYY-MM-DD')
			}
		})

		return reduce(
			groupedCards,
			(acc: CardsGrouped, cardGroup: Array<CardResponse>, groupKey: string) => {
				acc.push({ title: groupKey, data: cardGroup })
				return acc
			},
			[],
		)
	},
)

export const getHistoryCardsByDeadlineDatePure = memoize(
	(cards: Array<CardResponse>, todayIso: string): CardsGrouped => {
		const todayDate = new Date(todayIso)
		const sortedCards = sortBy(cards, (card: CardResponse) => card.deadlineDate || todayIso)
			.filter((card: CardResponse) => !card.isCurrent && new Date(card.deadlineDate || todayDate) < todayDate)
			.reverse()
		const groupedCards = groupBy(sortedCards, (card: CardResponse) => {
			return moment(card.deadlineDate || todayDate).format('YYYY-MM-DD')
		})

		return reduce(
			groupedCards,
			(acc: CardsGrouped, cardGroup: Array<CardResponse>, groupKey: string) => {
				acc.push({ title: groupKey, data: cardGroup })
				return acc
			},
			[],
		)
	},
)
