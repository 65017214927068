// @flow

import React, { PureComponent } from 'react'

type ComponentProps = {|
	timeZero: Date,
	stop?: ?boolean,
|}

type ComponentState = {|
	currentTime: Date,
|}

export default class Clock extends PureComponent<ComponentProps, ComponentState> {
	state: ComponentState = {
		currentTime: new Date(),
	}

	interval: ?IntervalID = null

	shouldComponentUpdate(nextProps: ComponentProps, nextState: ComponentState) {
		return this.state.currentTime !== nextState.currentTime || this.props.stop !== nextProps.stop
	}

	UNSAFE_componentWillMount() {
		!this.props.stop && this.updateClock()
	}

	UNSAFE_componentWillReceiveProps(nextProps: ComponentProps) {
		if (!this.props.stop && nextProps.stop && this.interval) {
			clearInterval(this.interval)
		}
	}

	componentWillUnmount() {
		this.interval && clearInterval(this.interval)
	}

	updateClock = () => {
		this.setState({ currentTime: new Date() })
		this.interval && clearInterval(this.interval)
		this.interval = setInterval(this.updateClock, 1000)
	}

	render() {
		const diff: number = Math.max(0, (new Date().getTime() - new Date(this.props.timeZero).getTime()) / 1000)
		const hours = Math.floor(diff / 60 / 60)
		const minutes = (Math.floor(diff / 60) % 60).toString().padStart(2, '0')
		const seconds = (Math.floor(diff) % 60).toString().padStart(2, '0')

		return (
			<span>
				{hours}:{minutes}:{seconds}
			</span>
		)
	}
}
