// @flow
/** @jsx jsx */

import { NEXT_PERIOD_PLACEHOLDERS, PREV_PERIOD_PLACEHOLDERS, THIS_PERIOD_PLACEHOLDERS } from '../../domain/emails'
import { type WithTranslateProps, withTranslate } from 'wrappers'

import CopyToClipboard from 'components/copy-to-clipboard'
import type I18NextTranslateFn from 'i18next'
import { colors } from 'variables'
import { getYear } from 'components/date-picker/date-utils'
import { jsx } from '@emotion/core'
import moment from 'moment'
// $FlowFixMe - there is useCallback
import { useCallback } from 'react'

type Props = {|
	...WithTranslateProps,
|}

function PeriodPlaceholder(props: Props) {
	const currentDate = new Date()
	const currentYear = getYear(currentDate)
	const nextYear = moment(currentDate)
		.add(1, 'year')
		.year()
	const prevYear = moment(currentDate)
		.subtract(1, 'year')
		.year()

	const renderPlaceholder = useCallback((t: I18NextTranslateFn, placeholder: string, index: number, year: number) => {
		return (
			<div key={'placeholder-' + index} css={styles.placeholder}>
				<div css={styles.placeholderCode}>
					<CopyToClipboard textToCopy={placeholder} hoverStyle={{ color: colors.blue, textDecoration: 'underline' }}>
						{placeholder}
					</CopyToClipboard>
				</div>
				<div css={styles.placeholderInfo}>{t(`invoice.settings.period.variants.${placeholder}`, { year })}</div>
			</div>
		)
	}, [])

	return (
		<div css={styles.helper}>
			<div css={styles.helperHeadline}>{props.t('invoice.settings.period.headline', { year: currentYear })}</div>
			<div css={styles.helperBox}>
				<div css={styles.periodBlock}>
					{THIS_PERIOD_PLACEHOLDERS.map((placeholder: string, index: number) =>
						renderPlaceholder(props.t, placeholder, index, currentYear),
					)}
				</div>
				<div css={styles.periodBlock}>
					{NEXT_PERIOD_PLACEHOLDERS.map((placeholder: string, index: number) =>
						renderPlaceholder(props.t, placeholder, index, nextYear),
					)}
				</div>
				<div>
					{PREV_PERIOD_PLACEHOLDERS.map((placeholder: string, index: number) =>
						renderPlaceholder(props.t, placeholder, index, prevYear),
					)}
				</div>
			</div>
		</div>
	)
}

const styles = {
	hover: {
		color: colors.blue,
		textDecoration: 'underline',
	},
	helper: {
		margin: '20px 0',
	},
	periodBlock: { marginBottom: '5px' },
	helperHeadline: {
		fontSize: 14,
		lineHheight: '17px',
		marginBottom: 10,
	},
	helperBox: {
		borderRadius: 2,
		backgroundColor: colors.blue100,
		padding: '15px 15px',
	},
	helperBoxPlaceholder: {
		display: 'inline-flex',
	},
	placeholder: {
		lineHeight: '17px',
		padding: '6px 0',
		fontSize: 13,
		display: 'flex',
	},
	placeholderCode: {
		fontSize: 13,
		fontFamily: '"Courier New", serif',
		flex: '0 0 45%',
		paddingRight: 5,
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
	},
	placeholderInfo: {
		flex: '0 0 55%',
		paddingLeft: 5,
	},
}

export default withTranslate(PeriodPlaceholder)
