/* @flow */

const monitoredEvents = ['mousemove', 'mousedown', 'click', 'scroll', 'keypress']

let timer: IntervalID
let idleSeconds = 0
let idleThreshold: number
let onInactivity: (idleSeconds: number) => void

function init(_idleThreshold: number, _onInactivity: (idleSeconds: number) => void) {
	monitoredEvents.forEach((monitoredEvent: string) => {
		window.addEventListener(monitoredEvent, resetTimer, true)
	})

	timer = setInterval(() => {
		++idleSeconds
	}, 1000)

	onInactivity = _onInactivity
	idleThreshold = _idleThreshold
}

function shutdown() {
	monitoredEvents.forEach((monitoredEvent: string) => {
		window.removeEventListener(monitoredEvent, resetTimer, true)
	})

	if (timer) {
		clearInterval(timer)
	}
}

function resetTimer() {
	if (idleThreshold <= idleSeconds) {
		onInactivity(idleSeconds)
	}

	idleSeconds = 0
}

export default {
	init,
	shutdown,
}
