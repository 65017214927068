//@flow

import React, { Component } from 'react'
import { xor } from 'lodash-es'
import type { Group, UserGroup, UserProfile } from 'types'
import { withNotify, withTranslate, type WithTranslateProps, type WithNotifyProps } from 'wrappers'
import { hasUserBulkProcessResponseServerError } from 'modules/settings/domain/users'
import { getUserFullName } from 'modules/user/domain/user'
import Checkbox from 'components/checkbox'
import Dialog from 'components/dialog'
import Button from 'components/button'
import styles from '../settings.css'

type Props = {|
	...WithTranslateProps,
	...WithNotifyProps,
	user: UserProfile,
	groups: Array<Group>,
	open?: boolean,
	onClose: () => void,
	bulkProcessUserGroups: (
		profile: UserProfile,
		requests: Array<{ groupId: string, operation: 'add' | 'remove' }>,
	) => Promise<*>,
|}

type State = {|
	memberOfGroupIds: Array<string>,
	dirty: boolean,
|}

class UserEdit extends Component<Props, State> {
	constructor(props: Props) {
		super(props)

		this.state = {
			...this.getStateFromProps(props),
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		this.setState({
			...this.getStateFromProps(nextProps),
		})
	}

	getStateFromProps(props: Props) {
		let memberOfGroupIds = []
		if (props.user.groups != null) {
			memberOfGroupIds = props.user.groups.map((ug: UserGroup) => ug.id || '')
		}
		return {
			dirty: false,
			memberOfGroupIds,
		}
	}

	getChanged = (_memberOfGroupIds?: Array<string>) => {
		const { memberOfGroupIds } = this.state
		const { user } = this.props

		return xor(
			_memberOfGroupIds || memberOfGroupIds,
			(user.groups || []).map((item: *) => {
				return item.id || ''
			}),
		)
	}

	save = async () => {
		const toAdd = this.toAdd()
		const toRemove = this.toRemove()

		const requests = [
			...toRemove.map((item: string) => {
				return { groupId: item, operation: 'remove' }
			}),
			...toAdd.map((item: string) => {
				return { groupId: item, operation: 'add' }
			}),
		]

		try {
			const addLength = (toAdd && toAdd.length) || 0
			const removeLength = (toRemove && toRemove.length) || 0
			const response = await this.props.bulkProcessUserGroups(this.props.user, requests)
			const hasError = hasUserBulkProcessResponseServerError(response)

			if (!hasError) {
				if (addLength || removeLength) {
					const key = addLength && !removeLength ? 'settings.users.added' : 'settings.users.edited'
					this.props.notify(
						this.props.t(key, {
							fullName: getUserFullName(this.props.user),
							email: this.props.user.email,
						}),
						'success',
					)
				}
				this.props.onClose()
			}
		} catch (error) {
			return null
		}
	}

	toRemove = () => {
		const { memberOfGroupIds } = this.state

		return this.getChanged().filter((item: string) => {
			return memberOfGroupIds.indexOf(item) == -1
		})
	}

	toAdd = () => {
		const { memberOfGroupIds } = this.state

		return this.getChanged().filter((item: string) => {
			return memberOfGroupIds.indexOf(item) > -1
		})
	}

	render() {
		const { groups, user } = this.props

		const actions = [
			<Button
				primary
				wide
				key="okButton"
				labelText={this.props.t('settings.forms.saveButton')}
				onClick={this.save}
				disabled={this.state.memberOfGroupIds.length == 0}
				autoTestId="settings-user-edit-dialog-save"
			/>,
			<Button
				secondary
				wide
				key="cancelButton"
				labelText={this.props.t('settings.forms.cancelButton')}
				onClick={this.props.onClose}
				autoTestId="settings-user-edit-dialog-cancel"
			/>,
		]

		return (
			<Dialog
				autoTestId="user-edit-dialog"
				title={this.props.t('settings.users.memberOfGroups', {
					user: user.email || '',
				})}
				actions={actions}
				open
			>
				{groups.map((group: Group, i: number) => {
					const checked =
						this.state.memberOfGroupIds == null
							? false
							: this.state.memberOfGroupIds.filter((item: string) => item == group.id).length > 0

					return (
						<div key={group.id || i} className={styles.row}>
							<Checkbox
								label={group.name}
								checked={!!checked}
								onCheck={() => { //eslint-disable-line
									let memberOfGroupIds = xor(this.state.memberOfGroupIds, [group.id || ''])
									this.setState({
										dirty: this.getChanged(memberOfGroupIds).length > 0,
										memberOfGroupIds,
									})
								}}
							/>
						</div>
					)
				})}
			</Dialog>
		)
	}
}

export default withTranslate(withNotify(UserEdit))
