/* @flow */

import React from 'react'
import { cloneDeep, get, setWith } from 'lodash'
import type { UserProfileSettingsNotifications } from 'types'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import Toggle from 'components/toggle'

type Props = {|
	type: $Keys<typeof TYPES>,
	categories: string[],
	notifications: ?UserProfileSettingsNotifications,
	onChange: (notifications: UserProfileSettingsNotifications) => void,
	...WithTranslateProps,
|}

const TYPES = {
	email: 'isEmailEnabled',
	push: 'isMobilePushEnabled',
}

function NotificationToggle(props: Props) {
	const paths = props.categories.map((category: string) => [category, TYPES[props.type]])

	function onToggle(_: SyntheticEvent<HTMLInputElement>, toggled: boolean) {
		const notifications = paths.reduce(
			(value: UserProfileSettingsNotifications, path: string[]) => setWith(value, path, toggled, cloneDeep),
			{ ...props.notifications },
		)
		props.onChange(notifications)
	}

	return <Toggle toggled={!!get(props.notifications, paths[0])} onToggle={onToggle} autoWidth />
}

export default withTranslate(NotificationToggle)
