/* @flow */
/** @jsx jsx */
/* eslint-disable react/no-multi-comp */

import moize from 'moize'
import { jsx } from '@emotion/core'
import type { AccountingDocument } from 'types'
import { type WithTranslateProps } from 'wrappers'
import { formatToDateString } from 'utils/formatters'
import { Column } from 'modules/data-grid-next/components'
import { DATA_GRID_CACHE_SIZE } from 'trivi-constants'
import DueText from 'components/due-text'
import CashbotGridlineStatus from 'modules/cashbot/components/cashbot-gridline-status'
import { showCashbotStatusOnAccDocGridLine } from 'modules/cashbot/domain'

export type Props = {|
	width?: number | string,
	...WithTranslateProps,
|}

const styles = {
	days: {
		fontSize: 12,
		minHeight: 21,
		display: 'flex',
	},
}

function renderAccDocState(value: string, row: AccountingDocument) {
	const today: Date = new Date()
	const dueDate: Date = value ? new Date(value) : new Date()
	today.setHours(0, 0, 0, 0)
	dueDate.setHours(0, 0, 0, 0)
	const diff: number = parseInt(Math.round((dueDate - today) / 1000 / 60 / 60 / 24)) // to days

	return (
		<div css={styles.days}>
			{!((row.payments && (row.payments.paid || row.payments.paidConfirmed)) || row.total === 0) && (
				<DueText daysToGo={diff} />
			)}
		</div>
	)
}

function columnRenderer(value: string, row: AccountingDocument) {
	const showCashbotStatus = showCashbotStatusOnAccDocGridLine(row)
	return (
		<div>
			<span title={formatToDateString(value || '')}>{formatToDateString(value || '')}</span>
			{showCashbotStatus && <CashbotGridlineStatus accDocId={row.id || ''} />}
			{!showCashbotStatus && value && renderAccDocState(value, row)}
		</div>
	)
}

export default function getDueDateColumn(props: Props) {
	const columnRendererCashed = moize(columnRenderer, { maxSize: DATA_GRID_CACHE_SIZE })

	return (
		<Column
			title={props.t('accountingDocument.columns.dueDate')}
			width={props.width}
			columnId="dueDate"
			key="dueDate"
			render={columnRendererCashed}
			sortable
		/>
	)
}
