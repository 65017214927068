//@flow
import { connect } from 'react-redux'
import withAutoload from 'wrappers/with-autoload'
import CashRegisterDialog from '../components/dialogs/cash-register-dialog'
import { loadCashRegisters } from 'modules/cash-register/actions'
import { getCashRegisters } from 'modules/cash-register/selectors'
import type { State, CashRegister, Dispatch } from 'types'

type StateProps = {|
	cashRegisters?: ?Array<CashRegister>,
	loading: boolean,
|}

const mapStateToProps = (state: State): StateProps => {
	return {
		cashRegisters: getCashRegisters(state),
		loading: state.cashRegister.cashRegisters.loading,
	}
}

type DispatchProps = {|
	autoload: () => void,
|}

const mapDispatchToProps = (dispatch: Dispatch<any>): DispatchProps => {
	return {
		autoload: () => {
			dispatch(loadCashRegisters())
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(withAutoload(CashRegisterDialog))
