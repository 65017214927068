// @flow

import { apiCallFunctions, apiCallQueryParams } from '../constants'
import {
	getDataGridCurrentPage,
	getDataGridCurrentPageSize,
	getDataGridCurrentSort,
	getDataGridFilter,
} from '../selectors'
import type {
	Dispatch,
	State,
	SearchFilter,
	DataGridId,
	UnmatchedPayment,
	UnmatchedPaymentMatchesRequestBulk as UnmatchedPaymentMatches,
} from 'types'
import type { Action } from './action-types'
import type { Action as CommonAction } from 'modules/common/actions/action-types'
import type { DataTypes, Sort } from '../types'
import type { Filter } from 'modules/data-grid/types'
import diff from 'json-patch-gen'
import {
	unmatchedPaymentsBulk as unmatchedPaymentsBulkApi,
	unmatchedPayment as unmatchedPaymentApi,
} from 'modules/common/models/api-model'
import { showServerError } from '../../common/actions'

type CombinedFilter = { from: Array<string>, to: string }
const combinedFilters: CombinedFilter[] = [
	{ from: ['lineItem.tagsBranch', 'lineItem.tagsProject'], to: 'lineItem.tags' },
]

function combineFilters(defaultFilter: Filter) {
	combinedFilters.forEach((filter: CombinedFilter) => {
		let additionalFilterValues = []
		filter.from.forEach((filterFrom: string) => {
			if (defaultFilter.find((f: SearchFilter) => f.field === filterFrom)) {
				const filterValues = defaultFilter.find((f: SearchFilter) => f.field === filterFrom)
				filterValues && filterValues.valueContains && additionalFilterValues.push(...filterValues.valueContains)
				const index = defaultFilter.indexOf(filterValues)
				if (index > -1) {
					defaultFilter.splice(index, 1)
				}
			}
		})
		if (additionalFilterValues && additionalFilterValues.length > 0) {
			defaultFilter.push({ field: filter.to, valueContains: additionalFilterValues })
		}
	})
	return defaultFilter
}

export function loadGridData(
	type: $Keys<DataTypes>,
	gridId: DataGridId,
	page: number = 0,
	pageSize: number = 10,
	sort?: Sort,
	filter?: Filter,
) {
	let searchText: string = ''
	let filterWithoutSearchText: ?Filter = filter && filter.slice()

	if (filter) {
		const searchTextIndex: number = filter.findIndex((filterItem: SearchFilter) => filterItem.field === 'searchText')
		if (filter && searchTextIndex > -1 && filter[searchTextIndex].value) {
			searchText = filter[searchTextIndex].value.toString()
			filterWithoutSearchText && filterWithoutSearchText.splice(searchTextIndex, 1)
		}
	}

	let filterWithoutSearchTextCombined = filterWithoutSearchText
	if (filterWithoutSearchText && filterWithoutSearchText.length > 0) {
		filterWithoutSearchTextCombined = combineFilters(filterWithoutSearchText)
	}

	return async (dispatch: Dispatch<Action>) => {
		dispatch({
			type: 'START_LOAD_GRID_DATA',
			dataType: type,
			currentPage: page,
			pageSize,
			sort,
			filter,
		})

		const apiCallFunction = apiCallFunctions[type]
		try {
			let response
			//FIXME: there is currently no POST /search/templates endpoint on BE
			if (type === 'templates') {
				response = await apiCallFunction.get(
					{
						page: page + 1,
						pageSize,
						sortingField: (sort && sort.columnId) || undefined,
						sortingDirection: (sort && sort.direction) || undefined,
						searchText: searchText,
						filters: filterWithoutSearchTextCombined,
					},
					undefined,
					apiCallQueryParams[gridId],
				)
			} else {
				response = await apiCallFunction.post(
					{
						page: page + 1,
						pageSize,
						sortingField: (sort && sort.columnId) || undefined,
						sortingDirection: (sort && sort.direction) || undefined,
						searchText: searchText,
						filters: filterWithoutSearchTextCombined,
					},
					apiCallQueryParams[gridId],
				)
			}

			dispatch({
				type: 'FINISH_LOAD_GRID_DATA',
				dataType: type,
				data: response[type],
				pagesCount: response.pagesCount,
				currentPage: response.currentPage - 1 || page,
				pageSize: response.pageSize || pageSize,
				sort,
				filter,
			})
		} catch (serverError) {
			dispatch({
				type: 'FINISH_LOAD_GRID_DATA',
				dataType: type,
				currentPage: page,
				pageSize,
				sort,
				filter,
				serverError,
			})
		}
	}
}

export function refreshGridData(type: $Keys<DataTypes>, gridId: DataGridId, filterId?: string, defaultFilter?: Filter) {
	return async (dispatch: Dispatch<Action>, getState: () => State) => {
		const state = getState()
		const pageIndex = getDataGridCurrentPage(state, gridId)
		const pageSize = getDataGridCurrentPageSize(state, gridId)
		const sort = getDataGridCurrentSort(state, gridId) || undefined
		const filter = getDataGridFilter(state, filterId, defaultFilter)
		await dispatch(loadGridData(type, gridId, pageIndex, pageSize, sort, filter))
	}
}

export function refreshGridLine(type: $Keys<DataTypes>, gridId: DataGridId, lineId: string) {
	return async (dispatch: Dispatch<Action>) => {
		dispatch({
			type: 'START_LOAD_GRID_LINE',
			dataType: type,
			id: lineId,
		})

		const apiCallFunction = apiCallFunctions[type]
		try {
			const response = await apiCallFunction.post(
				{
					filters: [{ field: 'id', value: lineId }],
				},
				undefined,
				apiCallQueryParams[gridId],
			)

			dispatch({
				type: 'FINISH_LOAD_GRID_LINE',
				dataType: type,
				id: lineId,
				data: (response[type] && response[type][0]) || undefined,
			})
		} catch (serverError) {
			dispatch({
				type: 'FINISH_LOAD_GRID_LINE',
				dataType: type,
				id: lineId,
				serverError,
			})
		}
	}
}

export function changeCurrentPage(name: string, page: number) {
	return (dispatch: Dispatch<Action>) => {
		dispatch({
			type: 'GRID_CHANGE_CURRENT_PAGE',
			name,
			page,
		})
	}
}

export function changePageSize(name: string, pageSize: number) {
	return (dispatch: Dispatch<Action>) => {
		dispatch({
			type: 'GRID_CHANGE_PAGE_SIZE',
			name,
			pageSize,
		})
	}
}

export function changeSort(name: string, sort: Sort) {
	return (dispatch: Dispatch<Action>) => {
		dispatch({
			type: 'GRID_CHANGE_SORT',
			name,
			sort,
		})
	}
}

// Implemented data grid actions
export function updateUnmatchedPaymentMatches(
	unmatchedPayment: UnmatchedPayment,
	unmatchedPaymentMatches: UnmatchedPaymentMatches,
) {
	return async (dispatch: Dispatch<CommonAction>) => {
		if (unmatchedPayment.id) {
			try {
				await unmatchedPaymentsBulkApi.post({ unmatchedPaymentId: unmatchedPayment.id }, unmatchedPaymentMatches)
			} catch (e) {
				dispatch(showServerError(e))
				throw e
			}
		}
	}
}

export function updateUnmatchedPayment(
	unmatchedPayment: UnmatchedPayment,
	newUnmatchedPayment: { ...UnmatchedPayment & { isPaymentAlreadyMatched?: boolean } },
) {
	return async (dispatch: Dispatch<CommonAction>) => {
		if (unmatchedPayment.id) {
			try {
				const diffResult = diff(unmatchedPayment, newUnmatchedPayment, { op: 'replace' })
				unmatchedPayment.id &&
					(await unmatchedPaymentApi.patch({ unmatchedPaymentId: unmatchedPayment.id }, diffResult))
			} catch (e) {
				dispatch(showServerError(e))
				throw e
			}
		}
	}
}
