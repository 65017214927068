/* @flow */

import type { AccountingDocumentConnection, AccountingDocumentReduxAction, Dispatch, IssueAction, State } from 'types'
import {
	disconnectAccountingDocumentConnection,
	loadAccountingDocumentConnections,
	loadAccountingDocumentVatRecapInfo,
	updateAccountingDocumentConnection,
	updateAccountingDocumentRelatedAccountingDocument,
	updateAccountingDocumentRoundingType,
} from '../../actions'
import {
	getAccountingDocument,
	getAccountingDocumentConnections,
	getAccountingDocumentRoundingTypeMode,
	isAccountingDocumentVatFree,
} from '../../selectors'

import { AccountingDocument_isProcessed } from 'types/operations'
import Totals from '../../components/invoice-elements/totals'
import { connect } from 'react-redux'
import { isRoundingVisible } from 'modules/accounting-document/domain/templates'

type OwnProps = {|
	accountingDocumentId: string,
	readonly?: boolean,
|}

const mapStateToProps = (state: State, ownProps: OwnProps) => {
	const accountingDocument = getAccountingDocument(state, ownProps.accountingDocumentId)
	const isProcessed = (accountingDocument && AccountingDocument_isProcessed(accountingDocument)) || false

	return {
		total: accountingDocument ? accountingDocument.total : null,
		totalVatExcl: accountingDocument ? accountingDocument.totalVatExcl : null,
		totalVatAmount: accountingDocument ? accountingDocument.totalVatAmount : null,
		roundingAmount: accountingDocument ? accountingDocument.roundingAmount : null,
		showRounding: accountingDocument && isRoundingVisible(accountingDocument),
		currency: (accountingDocument && accountingDocument.currency) || undefined,
		connections: getAccountingDocumentConnections(state, ownProps.accountingDocumentId),
		domesticCurrency: accountingDocument ? accountingDocument.currencyDomestic : null,
		isAccDocVatFree: isAccountingDocumentVatFree(state, ownProps.accountingDocumentId),
		roundingType: getAccountingDocumentRoundingTypeMode(state, ownProps.accountingDocumentId),
		isProcessed,
	}
}

type DispatchProps = {|
	onRoundingTypeChange: (roundingType: ?number) => void,
	onConnectionChange: (connection: AccountingDocumentConnection) => void,
	onRemoveConnection?: (connection: AccountingDocumentConnection, asRelatedDocument: boolean) => Promise<any>,
|}

const mapDispatchToProps = (
	dispatch: Dispatch<AccountingDocumentReduxAction | IssueAction>,
	ownProps: OwnProps,
): DispatchProps => {
	return {
		onConnectionChange: (connection: AccountingDocumentConnection) => {
			dispatch(updateAccountingDocumentConnection(ownProps.accountingDocumentId, connection)).then(() => {
				dispatch(loadAccountingDocumentConnections(ownProps.accountingDocumentId))
			})
		},
		onRoundingTypeChange: (roundingType: ?number) => {
			dispatch(updateAccountingDocumentRoundingType(ownProps.accountingDocumentId, roundingType)).then(() => {
				dispatch(loadAccountingDocumentVatRecapInfo(ownProps.accountingDocumentId))
			})
		},
		onRemoveConnection: async (connection: AccountingDocumentConnection, asRelatedAccountingDocument: boolean) => {
			if (!asRelatedAccountingDocument) {
				await dispatch(disconnectAccountingDocumentConnection(ownProps.accountingDocumentId, connection))
				dispatch(loadAccountingDocumentVatRecapInfo(ownProps.accountingDocumentId))
			}
			await dispatch(updateAccountingDocumentRelatedAccountingDocument(ownProps.accountingDocumentId, null))
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Totals)
