/* @flow */

type Subscribtion = (property: string) => void

export default class Context {
	organizationId: ?string
	subscribtions: Array<Subscribtion>

	constructor(organizationId: ?string) {
		this.organizationId = organizationId
		this.subscribtions = []
	}

	subscribe(subscribtion: Subscribtion) {
		this.subscribtions.push(subscribtion)
	}

	unsubscribe(subscribtion: Subscribtion) {
		const index = this.subscribtions.indexOf(subscribtion)

		if (index !== -1) {
			this.subscribtions.splice(index, 1)
		}
	}

	notify(property: string) {
		this.subscribtions.forEach((subscribtion: Subscribtion) => subscribtion(property))
	}

	getOrganizationId(): ?string {
		return this.organizationId
	}

	setOrganizationId(organizationId: ?string) {
		if (this.organizationId !== organizationId) {
			this.organizationId = organizationId
			this.notify('organizationId')
		}
	}
}
