// @flow

import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import EditButton from 'modules/accounting-document/components/invoice-elements/edit-button'
import { editAccountingDocumentRoute } from 'modules/accounting-document/routing/routes'
import type { Dispatch, AccountingDocumentReduxAction } from 'types'

type OwnProps = {|
	accountingDocumentId: string,
|}

function mapStateToProps(): {||} {
	return Object.freeze({})
}

type DispatchProps = {|
	onEdit: () => void,
|}

function mapDispatchToProps(dispatch: Dispatch<AccountingDocumentReduxAction>, ownProps: OwnProps): DispatchProps {
	return {
		onEdit: () => {
			dispatch(push(editAccountingDocumentRoute(ownProps.accountingDocumentId)))
		},
	}
}

function mergeProps(stateProps: {||}, dispatchProps: DispatchProps) {
	return dispatchProps
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(EditButton)
