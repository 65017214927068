// @flow
/** @jsx jsx */

// $FlowFixMe - there is useState
import { useEffect, useState } from 'react'
import { jsx } from '@emotion/core'
import type { EmailTemplate } from 'types'
import { findTemplateById } from '../../domain/emails'
import { validate, withTranslate, type WithTranslateProps, type FormValidationProps } from 'wrappers'
import { TabSwitches, TabSwitch } from 'components/TabSwitches'
import Placeholders from './placeholders'
import TemplateEditor from './template-editor'
import TextField from 'components/TextField'
import Dialog from 'components/dialog'
import Button from 'components/button'

type Props = {|
	...FormValidationProps,
	...WithTranslateProps,
	id?: ?string,
	open?: boolean,
	defaultEmailType?: number,
	emailTemplates: ?Array<EmailTemplate>,
	editEmailTemplate: (template: EmailTemplate) => void,
	createEmailTemplate: (template: EmailTemplate) => void,
	onRequestClose: () => void,
|}

function EditEmailTemplate(props: Props) {
	const creating = null == props.id
	const [template, setTemplate] = useState<EmailTemplate>(
		creating
			? {
					name: '',
					type: props.defaultEmailType || 1,
			  }
			: findTemplateById(props.id, props.emailTemplates),
	)

	useEffect(() => {
		const found = findTemplateById(props.id, props.emailTemplates)
		setTemplate(
			found || {
				name: '',
				type: props.defaultEmailType || 1,
			},
		)
	}, [props.id, props.emailTemplates, props.defaultEmailType])

	if (!template) return null
	props.validateForm(getClientValidationErrors())

	function onSubmit() {
		if (!template) return
		const api = creating ? props.createEmailTemplate : props.editEmailTemplate
		const newTemplate = Object.freeze({
			...template,
			body: template.body ? template.body.toString('markdown') : null,
		})

		api(newTemplate)
		props.isFormValid() && props.onRequestClose()
	}

	function onTypeChange(type: number) {
		setTemplate({ ...template, type })
	}

	function onNameChange(event: SyntheticInputEvent<HTMLInputElement>, name: ?string) {
		setTemplate({ ...template, name })
	}

	function onSubjectChange(event: SyntheticInputEvent<HTMLInputElement>, subject: ?string) {
		setTemplate({ ...template, subject })
	}

	function onBodyChange(markdown: string) {
		// TODO: Hotfix markdown newline [TWU-3372]
		// Přidat dvojmezeru před "single" new line (newline které nepředchází newline ani jí nenásleduje newline)
		// https://stackoverflow.com/questions/58460501/js-regex-lookbehind-not-working-in-firefox-and-safari
		const body = markdown.replace(/([^\n])[\n]([^\n])/g, '$1  \n$2')
		setTemplate({ ...template, body })
	}

	function getClientValidationErrors() {
		const errors = {}

		if (!template.name) errors.name = props.t('application.validation.mandatory')
		if (!template.subject) errors.subject = props.t('application.validation.mandatory')
		if (!template.body) errors.body = props.t('application.validation.mandatory')
		if (!template.type) errors.type = props.t('application.validation.mandatory')

		return errors
	}

	const actions = [
		<Button
			primary
			wide
			key="okButton"
			labelText={props.t(`settings.forms.${creating ? 'addButton' : 'saveButton'}`)}
			onClick={props.validationSubmit(onSubmit)}
			autoTestId="settings-create-email-template-add"
		/>,
		<Button
			secondary
			wide
			key="cancelButton"
			labelText={props.t('settings.forms.cancelButton')}
			onClick={props.onRequestClose}
			autoTestId="settings-create-email-template-cancel"
		/>,
	]

	return (
		<div>
			<Dialog
				open={!!props.open}
				autoTestId="create-email-template-dialog"
				title={props.t(`settings.emails.${creating ? 'new' : 'edit'}EmailTemplate`)}
				actions={actions}
			>
				<div css={styles.email}>
					<div css={styles.header}>
						<div css={styles.name}>
							<TextField
								hintText={props.t('settings.emails.nameHint')}
								value={props.validationValue('name', template.name || '')}
								clientError={props.validationMessage('name')}
								autoTestId="create-email-template-name"
								onChange={onNameChange}
								name="name"
								autoFocus
								autoWidth
								inline
							/>
						</div>
						<div css={styles.switch}>
							<TabSwitches
								autoTestId="create-email-template-type-tabs"
								onChange={onTypeChange}
								label={props.t('settings.emails.type')}
								value={props.validationValue('type', template.type)}
								disabled={!creating}
							>
								<TabSwitch
									key={1}
									value={1}
									label={props.t('accountingDocument.emailTemplateAccountingDocumentReminder')}
								/>
								<TabSwitch key={2} value={2} label={props.t('accountingDocument.emailTemplateAccountingDocument')} />
							</TabSwitches>
						</div>
					</div>
					<div css={styles.info}>
						<TextField
							labelText={props.t('settings.emails.subject')}
							value={props.validationValue('subject', template.subject || '')}
							clientError={props.validationMessage('subject')}
							autoTestId="create-email-template-subject"
							onChange={onSubjectChange}
							name="subject"
							fullWidth
						/>
					</div>
					<div css={styles.row}>
						<TemplateEditor
							type={template.type}
							error={props.validationMessage('body')}
							value={props.validationValue('body', template.body || '')}
							onChange={onBodyChange}
						/>
					</div>
					<Placeholders type={template.type} />
				</div>
			</Dialog>
		</div>
	)
}

const styles = {
	email: {
		marginTop: 45,
	},
	header: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'flex-end',
		alignContent: 'flex-end',
		marginBottom: 25,
	},
	name: {
		minWidth: 0,
		fontSize: 24,
		paddingRight: 20,
		flex: '1 1 100%',
	},
	info: {
		display: 'flex',
		marginBottom: 20,
		justifyContent: 'space-between',
	},
	switch: {
		flex: '1 1 auto',
	},
	row: {
		marginBottom: 18,
	},
}

export default withTranslate(
	validate(['FINISH_CREATING_EMAIL_TEMPLATE', 'FINISH_EDITING_EMAIL_TEMPLATE'])(EditEmailTemplate),
)
