// @flow

import type { Currency, SearchFilter } from 'types'
import { FilterSelectField, FilterTabSwitch, FilterTabSwitches } from 'modules/data-grid/components/data-grid-filter'
import React, { Component } from 'react'

import memoize from 'memoize-one'

type Props = {|
	currencies: Array<Currency>,
	name: string,
	filter?: SearchFilter,
	labelText?: string,
	className?: string,
	operation?: 'NotEqual' | 'Equal' | 'Exclusive',
	onChange?: (filter: SearchFilter) => void,
|}

class FilterCurrency extends Component<Props> {
	getCurrenciesOptions = memoize((currencies: Array<Currency>) => {
		return currencies.map((currency: Currency) => ({ value: currency.id, label: currency.id }))
	})

	getCurrenciesSwitches = memoize((currencies: Array<Currency>) => {
		return currencies.map((currency: Currency) => (
			<FilterTabSwitch key={currency.id} label={currency.id || ''} value={currency.id} />
		))
	})

	render() {
		let { currencies } = this.props

		if (currencies.length <= 1) {
			return null
		}

		if (currencies && currencies.length <= 3) {
			return (
				<FilterTabSwitches
					name={this.props.name}
					onChange={this.props.onChange}
					filter={this.props.filter}
					className={this.props.className}
					label={this.props.labelText}
				>
					{this.getCurrenciesSwitches(currencies)}
				</FilterTabSwitches>
			)
		}

		return (
			<FilterSelectField
				name={this.props.name}
				onChange={this.props.onChange}
				filter={this.props.filter}
				className={this.props.className}
				labelText={this.props.labelText}
				options={this.getCurrenciesOptions(currencies)}
				operation={this.props.operation}
				compact
				isClearable
				isSearchable
			/>
		)
	}
}

export default FilterCurrency
