// @flow
import type { FeatureFlag, FeatureId, Feature, Permission, FeaturesAndPermissions, FeatureFlagsResponse } from 'types'
import storage from 'utils/local-storage'
import {
	FEATURES_ENABLED_KEY,
	FEATURES_DISABLED_KEY,
	TEST_PERMISSIONS_ENABLED_KEY,
	TEST_PERMISSIONS_DISABLED_KEY,
} from 'utils/features'

export function loadFeaturesAndPermissionsFromStorage(): { [key: string]: Array<string> } {
	const storageEnabledFeatures: Array<string> = (storage.get(FEATURES_ENABLED_KEY) || '').split(',')
	const storageDisabledFeatures: Array<string> = (storage.get(FEATURES_DISABLED_KEY) || '').split(',')
	const storageEnabledTestPermissions: Array<string> = (storage.get(TEST_PERMISSIONS_ENABLED_KEY) || '').split(',')
	const storageDisabledTestPermissions: Array<string> = (storage.get(TEST_PERMISSIONS_DISABLED_KEY) || '').split(',')
	return {
		storageEnabledFeatures,
		storageDisabledFeatures,
		storageEnabledTestPermissions,
		storageDisabledTestPermissions,
	}
}

export function getUserEnabledFeaturesAndPermissions(
	featureFlags: ?FeatureFlagsResponse,
	storageData: { [key: string]: Array<string> },
	availableFeatures: Array<Feature>,
	availablePermission: Array<Permission>,
): FeaturesAndPermissions {
	const serverFeatureFlags: Array<FeatureFlag> = (featureFlags && featureFlags.featureFlags) || []

	let serverEnabledFeatures: Array<FeatureId> = []

	availableFeatures.forEach((f: Feature) => {
		const found: ?FeatureFlag = serverFeatureFlags.find((ff: FeatureFlag) => ff.name === f.id)
		found && serverEnabledFeatures.push(f.id)
	})

	const userEnabledFeatures: Array<FeatureId> = []
	const userDisabledFeatures: Array<FeatureId> = []
	const userEnabledTestPermissions: Array<Permission> = []
	const userDisabledTestPermissions: Array<Permission> = []
	const {
		storageEnabledFeatures,
		storageDisabledFeatures,
		storageEnabledTestPermissions,
		storageDisabledTestPermissions,
	} = storageData

	availableFeatures.forEach((f: Feature) => {
		storageEnabledFeatures.indexOf(f.id) !== -1 && userEnabledFeatures.push(f.id)
		storageDisabledFeatures.indexOf(f.id) !== -1 && userDisabledFeatures.push(f.id)
	})

	availablePermission.forEach((p: Permission) => {
		storageEnabledTestPermissions.indexOf(p) !== -1 && userEnabledTestPermissions.push(p)
		storageDisabledTestPermissions.indexOf(p) !== -1 && userDisabledTestPermissions.push(p)
	})

	return {
		serverEnabledFeatures,
		userEnabledFeatures,
		userDisabledFeatures,
		userEnabledTestPermissions,
		userDisabledTestPermissions,
	}
}
