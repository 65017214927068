// @flow

import { getInboundEmails } from 'modules/organization/actions'
import { connect } from 'react-redux'
import type { Dispatch, EmailItem, OrganizationAction, State } from 'types'
import withAutoload from 'wrappers/with-autoload'
import InboundEmails from '../../components/accounting-document-list/inbound-emails'

export type OwnProps = {|
	open: boolean,
	onClose: () => void,
|}

type StateProps = {|
	items: ?Array<EmailItem>,
|}

const mapStateToProps = (state: State): StateProps => {
	return {
		items: state.organization.emailList,
	}
}

type DispatchProps = {|
	autoload: () => void,
|}

const mapDispatchToProps = (dispatch: Dispatch<OrganizationAction>): DispatchProps => {
	return {
		autoload: () => {
			dispatch(getInboundEmails())
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(withAutoload(InboundEmails))
