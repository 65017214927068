// @flow

import { connect } from 'react-redux'
import { DataGridStateless, Column } from '../components'
import { loadGridData, changeCurrentPage, changePageSize, changeSort } from '../actions'
import {
	getDataGridData,
	getDataGridCurrentPage,
	getDataGridItemsLoading,
	getDataGridItemsCount,
	getDataGridCurrentSort,
	getDataGridFilter,
	getDataGridCurrentPageSizeWithDefaultSelector,
} from '../selectors'
import type { Element, ChildrenArray } from 'react'
import type { Action as DataGridNextAction } from '../actions/action-types'
import type { Action as DataGridAction } from 'modules/data-grid/actions/action-types'
import type { Dispatch, State } from 'types'
import type { DataTypes, Sort, DataGridId, MultiAction } from '../types'
import type { Action, Filter } from 'modules/data-grid/types'

export type OwnProps<K: $Keys<DataTypes>> = {|
	id: DataGridId,
	dataType: K,
	filterId?: string,
	defaultFilter?: Filter,
	defaultPageSize?: number,
	actions?: Array<Action<$ElementType<DataTypes, K>>>,
	multiActions?: Array<Action<Array<$ElementType<DataTypes, K>>>>,
	selectDisabled?: boolean,
	selectTooltip?: string,
	deselectTooltip?: string,
	selectOnClick?: boolean,
	selectedRows?: Array<string>,
	currentRowId?: ?string,
	light?: boolean,
	showPageSize?: boolean,
	showItemsOfTotal?: boolean,
	showPrevButton?: boolean,
	showNextButton?: boolean,
	compactPager?: boolean,
	hideHeader?: boolean,
	style?: Object,
	children: ChildrenArray<Element<typeof Column> | null | false>,
	onAction?: (string, $ElementType<DataTypes, K>) => Promise<void>,
	onMultiAction?: (MultiAction, Array<$ElementType<DataTypes, K>>) => Promise<?MultiAction>,
	onActionMenuOpen?: ($ElementType<DataTypes, K>, () => void) => ?Promise<void>,
	onNeedUpdateMultiActionVisiblity?: (Array<$ElementType<DataTypes, K>>) => Promise<Array<string>>,
	onSelectionChange?: (
		row: $ElementType<DataTypes, K>,
		selected: boolean,
		opt1?: boolean,
		opt2?: boolean,
	) => Promise<void> | void,
	onCheckAllChange?: (rows: { [string]: $ElementType<DataTypes, K> }, selected?: boolean) => void,
	checkAllOption?: boolean,
	beforeTableComponent?: any,
	expandedRowRender?: (
		record: $ElementType<DataTypes, K>,
		index: number,
		indent: number,
		expanded: boolean,
	) => ?React$Element<any>,
	// Flow hotfix:
	filter?: Filter,
	sort?: Sort,
	onNeedFreshData?: (dataType: K, id: DataGridId, page: number, pageSize: number, sort?: Sort, filter?: Filter) => void,
	onPageChange?: (id: DataGridId, page: number) => void,
	onPageSizeChange?: (id: DataGridId, pageSize: number) => void,
	onSortChange?: (id: DataGridId, sort: Sort) => void,
	getRowClassName?: (
		row: $ElementType<DataTypes, K>,
		selectedRows: { [string]: $ElementType<DataTypes, K> },
		selectOnClick?: boolean,
		currentRowId?: ?string,
	) => string,
	expandRowByClick?: boolean,
	confirmOnCheckAction?: boolean,
|}

type StateProps<K: $Keys<DataTypes>> = {|
	data: Array<$ElementType<DataTypes, K>>,
	loading: boolean,
	itemsCount: number,
	pageIndex: number,
	pageSize: number,
	sort?: Sort,
	filter?: Filter,
	defaultPageSize?: number,
|}

function mapStateToProps<K: $Keys<DataTypes>>(state: State, ownProps: OwnProps<K>): StateProps<K> {
	return {
		data: getDataGridData(state, (ownProps.dataType: string), ownProps.id, ownProps.filterId, ownProps.defaultFilter),
		loading: getDataGridItemsLoading(state, ownProps.dataType, ownProps.id, ownProps.filterId, ownProps.defaultFilter),
		itemsCount: getDataGridItemsCount(state, ownProps.dataType, ownProps.id, ownProps.filterId, ownProps.defaultFilter),
		pageIndex: getDataGridCurrentPage(state, ownProps.id),
		pageSize: getDataGridCurrentPageSizeWithDefaultSelector(state, ownProps.id, ownProps.defaultPageSize),
		sort: getDataGridCurrentSort(state, ownProps.id) || undefined,
		filter: getDataGridFilter(state, ownProps.filterId, ownProps.defaultFilter),
		defaultPageSize: ownProps.defaultPageSize,
	}
}

type DispatchProps<K: $Keys<DataTypes>> = {|
	onNeedFreshData?: (dataType: K, id: DataGridId, page: number, pageSize: number, sort?: Sort, filter?: Filter) => void,
	onPageChange?: (id: DataGridId, page: number) => void,
	onPageSizeChange?: (id: DataGridId, pageSize: number) => void,
	onSortChange?: (id: DataGridId, sort: Sort) => void,
|}

function mapDispatchToProps<K: $Keys<DataTypes>>(
	dispatch: Dispatch<DataGridNextAction | DataGridAction>,
): DispatchProps<K> {
	return {
		onNeedFreshData: (
			dataType: K,
			id: DataGridId,
			page: number,
			pageSize: number,
			sort?: Sort,
			filter?: Filter,
		): void => {
			dispatch(loadGridData(dataType, id, page, pageSize, sort, filter))
		},
		onPageChange: (id: DataGridId, page: number): void => {
			dispatch(changeCurrentPage(id, page))
		},
		onPageSizeChange: (id: DataGridId, pageSize: number): void => {
			dispatch(changePageSize(id, pageSize))
		},
		onSortChange: (id: DataGridId, sort: Sort) => {
			dispatch(changeSort(id, sort))
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(DataGridStateless)
export type { OwnProps as Props }
