//@flow
/** @jsx jsx */

import { Component } from 'react'
import { jsx } from '@emotion/core'
import type { UserGroup, UserProfile } from 'types'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import { getUserFullName } from 'modules/user/domain/user'
import Edit from 'components/svg-icons/image/edit'
import Delete from 'components/svg-icons/action/delete'
import CopyToClipboard from 'components/copy-to-clipboard'
import TableRow from 'components/table/table-row'
import TableCell from 'components/table/table-cell'
import Tooltip from 'components/tooltip'
import IconMenu from 'components/icon-menu'
import MenuItem from 'components/menu-item'
import FullText from 'components/full-text'

export type Props = {|
	userProfile: UserProfile,
	editable: boolean,
	canDelete: boolean,
	notEditableReason?: string,
	onEdit: UserProfile => void,
	onDelete: UserProfile => void,
	...WithTranslateProps,
|}

class UserGroupUserItem extends Component<Props> {
	handleClickEdit = () => {
		if (!this.props.editable) {
			return
		}
		this.props.onEdit(this.props.userProfile)
	}

	handleClickDelete = () => {
		this.props.onDelete(this.props.userProfile)
	}

	render = (): React$Element<typeof TableRow> => {
		const { userProfile, editable, canDelete, t } = this.props

		return (
			<TableRow key={userProfile.id || userProfile.email}>
				<TableCell>
					<span css={styles.name}>
						<CopyToClipboard
							copyTooltip={`${t('application.emailCopy')} ${userProfile.email || ''}`}
							copiedTooltip={t('application.emailCopied')}
							textToCopy={userProfile.email}
						>
							{userProfile.email}
						</CopyToClipboard>
					</span>
				</TableCell>
				<TableCell>{getUserFullName(userProfile)}</TableCell>
				<TableCell>
					<FullText
						text={userProfile.groups && userProfile.groups.map((userGroup: UserGroup) => userGroup.name).join(', ')}
					/>
				</TableCell>
				<TableCell>
					{(editable || this.props.notEditableReason) && (
						<Tooltip inline label={this.props.notEditableReason}>
							<IconMenu autoTestId="settings-group-user-item-actions" disabled={!editable} context>
								{[
									<MenuItem
										key={'edit'}
										primaryText={this.props.t('grid.action.edit')}
										onClick={this.handleClickEdit}
										icon={<Edit />}
										tall
									/>,
									<MenuItem
										key={'delete'}
										disabled={!canDelete}
										primaryText={this.props.t('settings.users.removeFromOrganization')}
										onClick={this.handleClickDelete}
										icon={<Delete />}
										tall
									/>,
								]}
							</IconMenu>
						</Tooltip>
					)}
				</TableCell>
			</TableRow>
		)
	}
}

const styles = {
	name: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-start',
	},
	space: {
		flex: '0 0 6px',
	},
	time: {
		flex: '0 0 20px',
	},
}

export default withTranslate(UserGroupUserItem)
