// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3RBfzT5jaR3Wlb6HxSiHxU {\n\tbackground: #E4FBFF;\n\tposition: relative;\n\theight: 40px;\n\tborder-radius: 20px;\n\tborder: 1px solid #08B0CD;\n\tpadding-left: 20px;\n\tpadding-right: 50px;\n\tfont-size: 13px;\n\tcolor: black;\n\ttext-align: right;\n\ttransition: all 80ms ease-in-out;\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: start;\n\tcursor: pointer;\n}\n.cmbse5-9D1HwQ-6qtoS2O {\n\tmin-width: 117px;\n}\n.hrx5yvJMcm8I_wSt8J7v_ {\n\tbackground: #08B0CD;\n\tcolor: white;\n\tjustify-content: center;\n\tcursor: initial;\n}\n._3RBfzT5jaR3Wlb6HxSiHxU:hover {\n\tbackground: #08B0CD;\n\tcolor: white;\n}\n", "",{"version":3,"sources":["webpack://./modules/navigation/components/support.css"],"names":[],"mappings":"AAAA;CACC,mBAAA;CACA,kBAAA;CACA,YAAA;CACA,mBAAA;CACA,yBAAA;CACA,kBAAA;CACA,mBAAA;CACA,eAAA;CACA,YAAA;CACA,iBAAA;CACA,gCAAA;CACA,aAAA;CACA,mBAAA;CACA,sBAAA;CACA,eAAA;AACD;AACA;CACC,gBAAA;AACD;AACA;CACC,mBAAA;CACA,YAAA;CACA,uBAAA;CACA,eAAA;AACD;AACA;CACC,mBAAA;CACA,YAAA;AACD","sourcesContent":[".box {\n\tbackground: #E4FBFF;\n\tposition: relative;\n\theight: 40px;\n\tborder-radius: 20px;\n\tborder: 1px solid #08B0CD;\n\tpadding-left: 20px;\n\tpadding-right: 50px;\n\tfont-size: 13px;\n\tcolor: black;\n\ttext-align: right;\n\ttransition: all 80ms ease-in-out;\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: start;\n\tcursor: pointer;\n}\n.boxClosed {\n\tmin-width: 117px;\n}\n.boxOpen {\n\tbackground: #08B0CD;\n\tcolor: white;\n\tjustify-content: center;\n\tcursor: initial;\n}\n.box:hover {\n\tbackground: #08B0CD;\n\tcolor: white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": "_3RBfzT5jaR3Wlb6HxSiHxU",
	"boxClosed": "cmbse5-9D1HwQ-6qtoS2O",
	"boxOpen": "hrx5yvJMcm8I_wSt8J7v_"
};
export default ___CSS_LOADER_EXPORT___;
