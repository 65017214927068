// @flow
/** @jsx jsx */

// $FlowFixMe - there is useState available
import { useState, useCallback } from 'react'
import { jsx } from '@emotion/core'
import { getInvoiceAmount } from '../../domain'
import type { AccountingDocument } from 'types'
import type { CashbotOffer, CashbotInvoice } from 'modules/cashbot/types'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import { addDays } from 'components/date-picker/date-utils'
import { formatToMoney } from 'utils/formatters'
import PopupSection from 'components/PopupSection'
import cashbotLogo from 'assets/images/cashbot-logo.svg'
import DatePicker from 'components/date-picker'
import Button from 'components/button'
import Paper from 'components/Paper'
import Loader from 'components/loader'

type Props = {|
	...WithTranslateProps,
	open: boolean,
	offer: ?CashbotOffer,
	invoice: ?CashbotInvoice,
	loading?: boolean,
	accountingDocument: ?AccountingDocument,
	onClose: () => void,
	onGetOffer: (date: Date) => any,
	onConfirm: (offer: CashbotOffer) => void,
|}

const DEFAULT_DUE_DATE_OFFSET = 120
const MAX_DUE_DATE_OFFSET = 120

function CashbotReceivedDialog(props: Props) {
	if (!props.open) return null
	const { offer, onConfirm, onGetOffer } = props

	const accDocDueDate =
		props.accountingDocument && props.accountingDocument.dueDate && new Date(props.accountingDocument.dueDate)
	const [date, setDate] = useState<Date>(
		(props.invoice && props.invoice.dueDate) || (accDocDueDate && addDays(accDocDueDate, DEFAULT_DUE_DATE_OFFSET)),
	)

	const currency = (offer && offer.currency) || (props.accountingDocument && props.accountingDocument.currency)
	const accDocAmount = props.accountingDocument && props.accountingDocument.total
	const amount = (offer && offer.financedAmount) || 0
	const fee = (offer && offer.totalFeeAmount) || 0
	const total = amount + fee

	const handleConfirm = useCallback(() => {
		offer && onConfirm(offer)
	}, [onConfirm, offer])

	const handleGetOffer = useCallback(() => {
		!offer && onGetOffer(date)
	}, [offer, onGetOffer, date])

	function onDateChange(event: ?SyntheticInputEvent<HTMLInputElement>, date: ?Date) {
		setDate(date)
	}

	return (
		<PopupSection open={props.open} onRequestClose={props.onClose}>
			<div css={style.container}>
				<Paper zDepth={5}>
					<div css={style.content}>
						<img src={cashbotLogo} css={style.logo} />
						<div>
							<div css={style.headline}>
								{props.t('cashbot.received.headline', {
									amount: getInvoiceAmount(props.invoice) || formatToMoney(accDocAmount || 0, { currency }),
								})}
							</div>
							<div css={style.panels}>
								<div css={style.panel}>
									<div css={style.head}>{props.t('cashbot.received.date')}</div>
									<div css={style.body}>
										<DatePicker
											value={date}
											disabled={!!offer}
											minDate={accDocDueDate && addDays(new Date(accDocDueDate), 1)}
											maxDate={accDocDueDate && addDays(accDocDueDate, MAX_DUE_DATE_OFFSET)}
											onChange={onDateChange}
											fullWidth
										/>
									</div>
								</div>
								<div css={style.panel}>
									<div css={style.head}>{props.t('cashbot.received.fee')}</div>
									<div css={style.body}>{offer && formatToMoney(fee, { currency })}</div>
								</div>
								<div css={style.panel}>
									<div css={style.head}>{props.t('cashbot.received.total')}</div>
									<div css={style.body}>{offer && formatToMoney(total, { currency })}</div>
								</div>
							</div>
							<div css={style.buttons}>
								<div css={style.button}>
									<Button secondary onClick={props.onClose} labelText={props.t('application.cancel')} />
								</div>
								<div css={style.button}>
									<Button
										primary
										onClick={offer ? handleConfirm : handleGetOffer}
										labelText={offer ? props.t('cashbot.received.confirmOffer') : props.t('cashbot.received.getOffer')}
									/>
								</div>
							</div>
						</div>
						<Loader visible={props.loading} />
					</div>
				</Paper>
			</div>
		</PopupSection>
	)
}

const style = {
	container: {
		marginLeft: 'auto',
		marginRight: 'auto',
		marginTop: 65,
		maxWidth: 850,
		width: '80%',
	},
	content: {
		marginTop: 60,
		padding: 50,
		fontSize: 25,
		lineHeight: 1,
		textAlign: 'center',
		overflow: 'hidden',
		borderRadius: 3,
	},
	logo: {
		position: 'absolute',
		width: 120,
		height: 'auto',
		right: 25,
		top: 25,
	},
	headline: {
		lineHeight: '30px',
		padding: '0 50px',
	},
	panels: {
		marginTop: 50,
		display: 'flex',
		alignItems: 'flex-start',
		justifyContent: 'space-between',
	},
	panel: {
		flex: '0 0 31.5%',
		marginBottom: '3%',
		borderRadius: '5px 5px 0 0',
		borderWidth: 1,
		borderStyle: 'solid',
		borderColor: '#DBDBDB',
		overflow: 'hidden',
	},
	head: {
		background: '#F8F8F8',
		lineHeight: '68px',
		fontWeight: 'bold',
		fontSize: 25,
		height: 68,
	},
	body: {
		padding: '35px 15px',
		lineHeight: '46px',
		fontSize: 20,
		minHeight: 116,
	},
	buttons: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		marginTop: 10,
	},
	button: {
		margin: '0 10px',
	},
}

export default withTranslate(CashbotReceivedDialog)
