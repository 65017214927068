/* @flow */
/** @jsx jsx */

import { jsx } from '@emotion/core'
import type { UserProfile } from 'types'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import { getUserDisplayName } from 'modules/user/user/user'
import Button from 'components/button'
import Paper from 'components/Paper'

type Props = {|
	...WithTranslateProps,
	me: ?UserProfile,
|}

function reload() {
	window.location.href = '/'
}

function EmptyDashboard(props: Props) {
	return (
		<div>
			{props.me && (
				<h1>
					{props.t('dashboard.empty.welcome', {
						name: getUserDisplayName(props.me.firstname, props.me.lastname, props.me.email),
					})}
				</h1>
			)}
			<Paper rounded zDepth={3}>
				<div css={styles.main}>
					<div css={styles.text}>{props.t('dashboard.empty.noOrganizationRegistered')}</div>
					<div css={styles.scenery} />
					<Button onClick={reload} labelText={props.t('dashboard.empty.reload')} />
				</div>
			</Paper>
		</div>
	)
}

const styles = {
	scenery: {
		width: 319,
		height: 176,
		margin: '40px auto',
		backgroundSize: 'contain',
		backgroundImage: 'url(/../assets/images/scenery-bw.svg)',
		backgroundPosition: 'center center',
		backgroundRepeat: 'no-repeat',
	},
	main: {
		flex: '1 0 auto',
		textAlign: 'center',
		padding: '40px 20px',
	},
	text: {
		fontSize: 16,
		fontWeight: 'bold',
	},
}

export default withTranslate(EmptyDashboard)
