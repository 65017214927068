/* @flow */

import React, { Component } from 'react'
import { Validator } from 'utils'
import { withTranslate, validate, type WithTranslateProps, type FormValidationProps } from 'wrappers'
import type { OrganizationCreator, OrganizationPersonAuthorizedToSign, OrganizationTaxRepresentative } from 'types'
import LegalFormSelector from 'modules/common/components/selectors/legal-form-selector'
import SigningCodeSelector from 'modules/common/components/selectors/signing-person-code-selector'
import Checkbox from 'components/checkbox'
import Dialog from 'components/dialog'
import Button from 'components/button'
import TextField from 'components/TextField'
import PhoneInput from 'components/phone-input'
import EmailInput from 'components/email-input'
import IconMenu from 'components/icon-menu'
import MenuItem from 'components/menu-item'
import Table from 'components/table/table'
import TableBody from 'components/table/table-body'
import TableRow from 'components/table/table-row'
import TableCell from 'components/table/table-cell'
import TableHeader from 'components/table/table-header'
import TableHeaderCell from 'components/table/table-header-cell'
import DeleteDialog from 'components/delete-dialog'
import Delete from 'components/svg-icons/action/delete'
import Edit from 'components/svg-icons/image/edit'
import styles from '../settings.css'

type User = OrganizationCreator | OrganizationPersonAuthorizedToSign | OrganizationTaxRepresentative

type Field = $Keys<OrganizationTaxRepresentative>

type StateArray = 'signAuthorized' | 'creators' | 'taxRepresentatives'

const SIGN_AUTHORIZED_FIELDS: Array<Field> = [
	'name',
	'surname',
	'preNominalLetters',
	'postNominalLetters',
	'phone',
	'email',
	'relation',
	'dataBoxId',
]

const CREATOR_FIELDS: Array<Field> = ['name', 'surname', 'preNominalLetters', 'postNominalLetters', 'phone', 'email']

const TAX_REPRESENTATIVE_FIELDS: Array<Field> = [
	'legalName',
	'regNo',
	'name',
	'surname',
	'preNominalLetters',
	'postNominalLetters',
	'phone',
	'email',
	'dataBoxId',
	'relation',
	'legalForm',
	'signingPersonCode',
	'isAuthorizedToSign',
]

type Props = {|
	...WithTranslateProps,
	...FormValidationProps,

	currentUserIsInternal: boolean,
	signAuthorized: ?Array<OrganizationPersonAuthorizedToSign>,
	loadSignAuthorizedPersons: () => void,
	updateSignAuthorizedPerson: (id: string, person: OrganizationPersonAuthorizedToSign) => void,
	addSignAuthorizedPerson: (person: OrganizationPersonAuthorizedToSign) => void,
	deleteSignAuthorizedPerson: (id: string) => void,

	creators: ?Array<OrganizationCreator>,
	loadCreators: () => void,
	updateCreator: (id: string, creator: OrganizationCreator) => void,
	addCreator: (creator: OrganizationCreator) => void,
	deleteCreator: (id: string) => void,

	taxRepresentatives: ?Array<OrganizationTaxRepresentative>,
	loadTaxRepresentatives: () => void,
	updateTaxRepresentative: (id: string, taxRepresentative: OrganizationTaxRepresentative) => void,
	addTaxRepresentative: (taxRepresentative: OrganizationTaxRepresentative) => void,
	deleteTaxRepresentative: (id: string) => void,
|}

type State = {
	signAuthorized: Array<OrganizationPersonAuthorizedToSign>,
	creators: Array<OrganizationCreator>,
	taxRepresentatives: Array<OrganizationTaxRepresentative>,
	dialogOpen?: ?StateArray,
	editingIndex?: ?number,
	newItems: {
		signAuthorized: ?OrganizationPersonAuthorizedToSign,
		creators: ?OrganizationCreator,
		taxRepresentatives: ?OrganizationTaxRepresentative,
	},
}

class StatutoryAuthority extends Component<Props, State> {
	state: State = this.getStateFromProps(this.props, true)

	componentDidMount = () => {
		if (!this.props.signAuthorized) {
			this.props.loadSignAuthorizedPersons && this.props.loadSignAuthorizedPersons()
		}
		if (!this.props.creators) {
			this.props.loadCreators && this.props.loadCreators()
		}
		if (!this.props.taxRepresentatives) {
			this.props.loadTaxRepresentatives && this.props.loadTaxRepresentatives()
		}
	}

	UNSAFE_componentWillReceiveProps(newProps: Props) {
		this.setState(this.getStateFromProps(newProps, false))
	}

	getStateFromProps(props: Props, initNewItems: boolean): State {
		const newItems = initNewItems
			? {
					newItems: (this.state && this.state.newItems) || {
						signAuthorized: {},
						creators: {},
						taxRepresentatives: {},
					},
			  }
			: {}

		return {
			...newItems,
			signAuthorized: props.signAuthorized || [],
			creators: props.creators || [],
			taxRepresentatives: props.taxRepresentatives || [],
		}
	}

	renderInput = (
		field: string,
		value: any,
		stateArray: StateArray,
		onChange: (value: string | number | boolean, next?: Function) => void,
		onBlur?: () => void,
	) => {
		const { t } = this.props
		const fieldName = `${stateArray}${field}`

		const textFieldChange = (event: SyntheticInputEvent<HTMLInputElement>, val: ?string) => {
			if (val !== null && val !== undefined) {
				onChange(val)
			}
		}

		const selectorChange = (val: number) => onChange(val, onBlur)
		const checkBoxChange = (ev: any, checked: boolean) => onChange(checked, onBlur)

		switch (field) {
			case 'signingPersonCode':
				return (
					<SigningCodeSelector
						value={value}
						onChange={selectorChange} //eslint-disable-line
						openMenuPosition={'top'}
					/>
				)
			case 'legalForm':
				return (
					<LegalFormSelector
						value={value}
						onChange={selectorChange} //eslint-disable-line
						labelText={t(`user.fields.${field}`)}
						openMenuPosition={'top'}
					/>
				)
			case 'isAuthorizedToSign':
				return (
					<Checkbox
						checked={!!value}
						onCheck={checkBoxChange} //eslint-disable-line
						label={t(`user.fields.${field}`)}
					/>
				)
			case 'phone':
				return (
					<PhoneInput
						fullWidth
						onBlur={onBlur}
						onChange={textFieldChange} //eslint-disable-line
						labelText={t(`user.fields.${field}`)}
						value={this.props.validationValue(fieldName, value || null)}
						clientError={this.props.validationMessage(fieldName)}
						autoTestId="settings-statutory-phone"
					/>
				)
			case 'email':
				return (
					<EmailInput
						fullWidth
						onBlur={onBlur}
						onChange={textFieldChange} //eslint-disable-line
						labelText={t(`user.fields.${field}`)}
						value={this.props.validationValue(fieldName, value || null)}
						clientError={this.props.validationMessage(fieldName)}
						autoTestId="settings-statutory-email"
					/>
				)
			default:
				return (
					<TextField
						fullWidth
						onBlur={onBlur}
						onChange={textFieldChange} //eslint-disable-line
						labelText={t(`user.fields.${field}`)}
						value={this.props.validationValue(fieldName, value || null)}
						clientError={this.props.validationMessage(fieldName)}
						autoTestId={`settings-statutory-${field}`}
						name={field}
					/>
				)
		}
	}

	renderValue = (user: User, field: string) => {
		const { t } = this.props
		const value = user[field.toString()]

		switch (field) {
			case 'signingPersonCode':
				return <SigningCodeSelector labelText={null} inline disabled value={value} />
			case 'legalForm':
				return <LegalFormSelector labelText={null} inline disabled value={value} />
			case 'isAuthorizedToSign':
				return value ? t('user.fields.userIsAuthorizedToSign') : t('user.fields.userIsNotAuthorizedToSign')
			default:
				return value
		}
	}

	onChange = (array: string, index: number, field: string) => (value: string | number | boolean, next?: Function) => {
		const arr: Array<User> = this.state[array]
		const newState = {
			[array]: arr.map((p: User, i: number) => {
				if (i === index) {
					return {
						...p,
						[field]: value,
					}
				}
				return p
			}),
		}

		this.setState(newState, () => {
			next && next()
		})
	}

	editItem = (stateArray: StateArray) => {
		if (Object.keys(this.state.newItems[stateArray] || {}).length > 0) {
			switch (stateArray) {
				case 'signAuthorized': {
					const person: ?OrganizationPersonAuthorizedToSign = this.state.newItems.signAuthorized
					if (person) {
						this.props.updateSignAuthorizedPerson &&
							person.id != null &&
							this.props.updateSignAuthorizedPerson(person.id || '', person)
					}
					break
				}
				case 'creators': {
					const person: ?OrganizationCreator = this.state.newItems.creators
					if (person) {
						this.props.updateCreator && this.props.updateCreator(person.id || '', person)
					}
					break
				}
				case 'taxRepresentatives': {
					const person: ?OrganizationTaxRepresentative = this.state.newItems.taxRepresentatives
					if (person) {
						this.props.updateTaxRepresentative && this.props.updateTaxRepresentative(person.id || '', person)
					}
					break
				}
			}
			this.closeDialog(stateArray)
		}
	}

	addItem = (stateArray: StateArray) => {
		const newItems = this.state.newItems
		switch (stateArray) {
			case 'signAuthorized': {
				newItems['signAuthorized'] && this.props.addSignAuthorizedPerson(newItems['signAuthorized'])
				break
			}
			case 'creators': {
				newItems['creators'] && this.props.addCreator(newItems['creators'])
				break
			}
			case 'taxRepresentatives': {
				newItems['taxRepresentatives'] && this.props.addTaxRepresentative(newItems['taxRepresentatives'])
				break
			}
		}
		this.closeDialog(stateArray)
	}

	openDialog = (stateArray: StateArray) => {
		this.setState({
			dialogOpen: stateArray,
			editingIndex: null,
		})
	}

	closeDialog = (stateArray: StateArray) => {
		this.setState(
			{
				dialogOpen: null,
				newItems: {
					[stateArray]: null,
				},
			},
			() => {
				this.props.resetValidation()
			},
		)
	}

	onStateFieldChange = (stateArray: StateArray, field: Field) => (value: string | number | boolean) => {
		// This must be typed better
		this.setState({
			newItems: {
				// $FlowFixMe
				[stateArray]: {
					...this.state.newItems[stateArray], // $FlowFixMe
					[field]: value,
				},
			},
		})
	}

	getEditUserHandler = (stateArray: StateArray, index: number) => () => {
		// $FlowFixMe
		this.setState({
			newItems: {
				[stateArray]: this.state[stateArray][index],
			},
			dialogOpen: stateArray,
			editingIndex: index,
		})
	}

	getDeleteUserHandler = (user: User, onDelete: (id: string) => void) => () => {
		DeleteDialog().then(() => {
			onDelete(user.id || '')
		})
	}

	renderUsers<User: User>(stateArray: StateArray, renderFields: Array<Field>, onDelete: (id: string) => void) {
		const { t } = this.props
		const users: Array<User> = this.state[stateArray.toString()]

		return (
			<div className={styles.users}>
				{users && users.length ? (
					<Table scrollable>
						<TableHeader>
							<TableRow>
								{[
									...renderFields.map((field: Field, j: number) => (
										<TableHeaderCell key={stateArray + j + field}>
											{t(`user.fields.${field.toString()}`)}
										</TableHeaderCell>
									)),
									...(this.props.currentUserIsInternal ? [<TableHeaderCell key={'actions'} width={24} />] : []),
								]}
							</TableRow>
						</TableHeader>
						<TableBody>
							{users.map((user: User, index: number) => {
								return (
									<TableRow key={stateArray + index}>
										{[
											...renderFields.map((field: Field, j: number) => {
												return <TableCell key={stateArray + j + field}>{this.renderValue(user, field)}</TableCell>
											}),
											...(this.props.currentUserIsInternal
												? [
														<TableCell key={index}>
															<IconMenu autoTestId="settings-statutory-actions" context>
																<MenuItem
																	primaryText={t('settings.forms.editButton')}
																	onClick={this.getEditUserHandler(stateArray, index)}
																	icon={<Edit />}
																	tall
																/>
																<MenuItem
																	primaryText={t('application.delete')}
																	onClick={this.getDeleteUserHandler(user, onDelete)}
																	icon={<Delete />}
																	tall
																/>
															</IconMenu>
														</TableCell>,
												  ]
												: []),
										]}
									</TableRow>
								)
							})}
						</TableBody>
					</Table>
				) : null}
				{this.renderDialog(stateArray, renderFields)}
			</div>
		)
	}

	renderDialog(stateArray: StateArray, renderFields: Array<Field>) {
		const { t } = this.props

		const open = () => this.openDialog(stateArray)
		const close = () => this.closeDialog(stateArray)

		const isOpen = this.state.dialogOpen === stateArray
		const editMode = this.state.editingIndex !== null && this.state.editingIndex !== undefined
		const newItems = this.state.newItems[stateArray]

		isOpen && this.props.validateForm(this.getClientValidationErrors(stateArray))

		const getHandler = () => () => {
			if (editMode) {
				this.editItem(stateArray)
			} else {
				this.addItem(stateArray)
			}
		}

		const actions = [
			<Button
				primary
				wide
				key="okButton"
				labelText={editMode ? t('settings.forms.editButton') : t('settings.forms.addButton')}
				onClick={this.props.validationSubmit(getHandler())}
				autoTestId="settings-statutory-dialog-add"
			/>,
			<Button
				key="cancelButton"
				labelText={t('settings.forms.cancelButton')}
				autoTestId="settings-statutory-dialog-cancel"
				onClick={close} //eslint-disable-line
				secondary
				wide
			/>,
		]

		return (
			<div className={styles.userAdd}>
				{this.props.currentUserIsInternal && (
					<Button
						labelText={t('settings.forms.addButton')}
						autoTestId="settings-statutory-add"
						onClick={open} //eslint-disable-line
						tertiary
					/>
				)}
				<Dialog
					autoTestId="statutory-dialog"
					title={editMode ? t('settings.forms.editUserDialogHeadline') : t('settings.forms.addUserDialogHeadline')}
					actions={actions}
					open={isOpen}
				>
					<h3 className={styles.dialogHeadline}>{t(`settings.responsibility.${stateArray}`)}</h3>
					<div className={styles.inputs}>
						{renderFields.map((field: Field, j: number) => {
							const cssStyle = field === 'signingPersonCode' ? 'inputPersonCode' : 'input'
							return (
								<div className={styles[cssStyle]} key={stateArray + j + field}>
									{this.renderInput(
										field,
										newItems ? newItems[field.toString()] : null,
										stateArray,
										this.onStateFieldChange(stateArray, field),
									)}
								</div>
							)
						})}
					</div>
				</Dialog>
			</div>
		)
	}

	getClientValidationErrors(stateArray: StateArray) {
		const { t } = this.props
		const errors = {}

		if (!this.state.dialogOpen) return errors

		if (
			!this.state.newItems[stateArray] ||
			(!this.state.newItems[stateArray].name && !this.state.newItems[stateArray].surname)
		) {
			errors[`${stateArray}name`] = t('clientError.fillNameOrLastname')
			errors[`${stateArray}surname`] = t('clientError.fillNameOrLastname')
		}

		if (
			this.state.newItems[stateArray] &&
			this.state.newItems[stateArray].phone &&
			this.state.newItems[stateArray].phone.length &&
			!Validator.phone(this.state.newItems[stateArray].phone)
		) {
			errors[`${stateArray}phone`] = t('clientError.notAPhone')
		}

		if (
			this.state.newItems[stateArray] &&
			this.state.newItems[stateArray].email &&
			this.state.newItems[stateArray].email.length &&
			!Validator.email(this.state.newItems[stateArray].email)
		) {
			errors[`${stateArray}email`] = t('clientError.notAnEmail')
		}

		return errors
	}

	render() {
		const { t } = this.props

		return (
			<div>
				<h3 className={styles.firstH3}>{t('settings.responsibility.signAuthorized')}</h3>
				{this.renderUsers('signAuthorized', SIGN_AUTHORIZED_FIELDS, this.props.deleteSignAuthorizedPerson)}

				<h3 className={styles.h3}>{t('settings.responsibility.creators')}</h3>
				{this.renderUsers('creators', CREATOR_FIELDS, this.props.deleteCreator)}

				<h3 className={styles.h3}>{t('settings.responsibility.taxRepresentatives')}</h3>
				{this.renderUsers('taxRepresentatives', TAX_REPRESENTATIVE_FIELDS, this.props.deleteTaxRepresentative)}
			</div>
		)
	}
}

export default withTranslate(validate()(StatutoryAuthority))
