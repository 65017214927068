import React from 'react'
import pure from 'recompose/pure'
import SvgIcon from 'components/SvgIcon'

let ParentFolder = props => (
	<SvgIcon {...props}>
		<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g transform="translate(1.2, 3.6)">
				<path
					d="M8.784,0.384 L10.8,2.4 L18.816,2.4 C19.3600027,2.4 19.8239981,2.599998 20.208,3 C20.5920019,3.400002 20.784,3.87199728 20.784,4.416 L20.784,14.4 C20.784,14.9440027 20.5920019,15.415998 20.208,15.816 C19.8239981,16.216002 19.3600027,16.416 18.816,16.416 L2.784,16.416 C2.23999728,16.416 1.77600192,16.216002 1.392,15.816 C1.00799808,15.415998 0.816,14.9440027 0.816,14.4 L0.816,2.4 C0.816,1.85599728 1.00799808,1.384002 1.392,0.984 C1.77600192,0.583998 2.23999728,0.384 2.784,0.384 L8.784,0.384 Z"
					fill="#B6B6B6"
				/>
				<path
					d="M11.775,7.6 L7.785,7.6 L9.5175,5.752 C9.7275,5.528 9.7275,5.192 9.5175,4.968 C9.3075,4.744 8.9925,4.744 8.7825,4.968 L6.1575,7.768 C5.9475,7.992 5.9475,8.328 6.1575,8.552 L8.7825,11.352 C8.8875,11.464 9.01875,11.52 9.15,11.52 C9.28125,11.52 9.4125,11.464 9.5175,11.352 C9.7275,11.128 9.7275,10.792 9.5175,10.568 L7.785,8.72 L11.775,8.72 C12.64125,8.72 13.35,9.476 13.35,10.4 C13.35,11.324 12.64125,12.08 11.775,12.08 C11.48625,12.08 11.25,12.332 11.25,12.64 C11.25,12.948 11.48625,13.2 11.775,13.2 C13.21875,13.2 14.4,11.94 14.4,10.4 C14.4,8.86 13.21875,7.6 11.775,7.6 Z"
					fill="#FFFFFF"
					fillRule="nonzero"
				/>
			</g>
		</g>
	</SvgIcon>
)
ParentFolder = pure(ParentFolder)

export default ParentFolder
