// @flow
/* eslint-disable prettier/prettier */

export type Permission =
	// accountingDocuments
	| 'seeAccountingDocuments'
	| 'seeIssuedAccountingDocuments'
	| 'seeReceivedAccountingDocuments'
	| 'seeUnknownAccountingDocuments'
	| 'seeAccountingDocumentTemplates'
	| 'editIssuedAccountingDocuments'
	| 'editReceivedAccountingDocuments'
	| 'editUnknownAccountingDocuments'
	| 'removeIssuedAccountingDocuments'
	| 'removeReceivedAccountingDocuments'
	| 'removeUnknownAccountingDocuments'
	| 'removeAccountingDocumentTemplates'
	| 'extractAccountingDocuments'
	| 'fetchAccountingDocumentsForExtraction'
	| 'processAccountingDocuments'
	// documents
	| 'seeDocuments'
	| 'editDocuments'
	| 'removeDocuments'
	// cashRegisters
	| 'seeCashRegisters'
	| 'editCashRegisters'
	| 'createCashRegisters'
	// bankAccounts
	| 'seeBankAccounts'
	| 'editBankAccounts'
	| 'manageCsas'
	// user
	| 'removeUsers'
	| 'editUsers'
	// internalUsers
	| 'seeInternalUsers'
	| 'editInternalUsers'
	// contacts
	| 'seeContacts'
	| 'editContacts'
	// settings
	| 'setOrganizationApiApps'
	| 'setOrganizationAccounting'
	| 'setOrganizationProfile'
	| 'setInternalOrganizationSettings'
	// tasks
	| 'seeAllTasks'
	// routes
	| 'seeInternalDashboard'
	// others
	| 'seeOrganizationSwitcher'
	| 'seeScanFactoryAction'
	| 'logInAsUser'
	// demo user - this information is only on frontend
	| 'seeNonDemoFeatures'
	| 'demoUser'

export type PermissionStruc = {|
	rights?: Array<string>,
	internalRights?: Array<string>,
	isAdmin?: boolean,
	isInternal?: boolean,
|}

const ACL: { [permission: Permission]: PermissionStruc } = {
	// ACCOUNTING DOCUMENTS
	seeAccountingDocuments: {
		rights: [
			'see_accountingdocuments',
		],
		internalRights: [
			'internal_full_access',
			'internal_assigned_access',
			'internal_extract',
		],
	},
	seeIssuedAccountingDocuments: {
		rights: [
			'see_accountingdocuments',
			'see_accountingdocuments_issued',
		],
		internalRights: [
			'internal_full_access',
			'internal_assigned_access',
			'internal_extract',
		],
	},
	seeReceivedAccountingDocuments: {
		rights: [
			'see_accountingdocuments',
			'see_accountingdocuments_received',
		],
		internalRights: [
			'internal_full_access',
			'internal_assigned_access',
			'internal_extract',
		],
	},
	seeUnknownAccountingDocuments: {
		rights: ['see_accountingdocuments', 'see_accounting_documents_received'],
		internalRights: [
			'internal_full_access',
			'internal_assigned_access',
			'internal_extract',
		],
	},
	seeAccountingDocumentTemplates: {
		rights: ['see_accountingdocuments'],
		internalRights: [
			'internal_full_access',
			'internal_assigned_access',
		],
	},
	editUnknownAccountingDocuments: {
		internalRights: [
			'internal_full_access',
			'internal_assigned_access',
			'internal_extract',
		],
	},
	editIssuedAccountingDocuments: {
		rights: [
			'touch_accountingdocument',
			'touch_accountingdocument_issued',
		],
		internalRights: [
			'internal_full_access',
			'internal_assigned_access',
			'internal_extract',
		],
	},
	editReceivedAccountingDocuments: {
		rights: [
			'touch_accountingdocument',
			'touch_accountingdocument_received',
		], // Následující komentář byl před úpravami: Tohle je správně! 'touch_accountingdocument' == 'touch_accountingdocument_issued'. Tzn. pro editaci přijatých dokladů mi právo 'touch_accountingdocument' nestačí, ale musím mít 'touch_accountingdocument_received'.
		internalRights: [
			'internal_full_access',
			'internal_assigned_access',
			'internal_extract',
		],
	},
	removeIssuedAccountingDocuments: {
		rights: [
			'rem_accountingdocument',
			'rem_accountingdocument_issued',
		],
		internalRights: [
			'internal_full_access',
			'internal_assigned_access',
			'internal_extract',
		],
	},
	removeReceivedAccountingDocuments: {
		rights: ['rem_accountingdocument', 'rem_accountingdocument_received'],
		internalRights: [
			'internal_full_access',
			'internal_assigned_access',
			'internal_extract',
		],
	},
	removeUnknownAccountingDocuments: {
		rights: ['rem_accountingdocument'],
		internalRights: [
			'internal_full_access',
			'internal_assigned_access',
			'internal_extract',
		],
	},
	removeAccountingDocumentTemplates: {
		rights: [
			'rem_accountingdocument',
			'rem_accountingdocument_issued',
		],
		internalRights: [
			'internal_full_access',
			'internal_assigned_access',
			'internal_extract',
		],
	},
	extractAccountingDocuments: {
		internalRights: [
			'internal_full_access',
			'internal_assigned_access',
			'internal_extract',
		],
	},
	fetchAccountingDocumentsForExtraction: {
		internalRights: [
			'internal_full_access',
			'internal_extract',
		],
	},
	processAccountingDocuments: {
		isInternal: true,
	},
	// DOCUMENTS
	seeDocuments: {
		rights: ['see_documents'],
		internalRights: [
			'internal_full_access',
			'internal_assigned_access',
		],
	},
	editDocuments: {
		rights: ['touch_document'],
		internalRights: [
			'internal_full_access',
			'internal_assigned_access',
		],
	},
	removeDocuments: {
		rights: ['rem_document'],
		internalRights: [
			'internal_full_access',
			'internal_assigned_access',
		],
	},
	// CASH REGISTERS
	seeCashRegisters: {
		rights: ['see_cashregisters'],
		internalRights: [
			'internal_full_access',
			'internal_assigned_access',
		],
	},
	editCashRegisters: {
		rights: ['touch_cashregister'],
		internalRights: [
			'internal_full_access',
			'internal_assigned_access',
		],
	},
	createCashRegisters: {
		internalRights: [
			'internal_full_access',
			'internal_assigned_access',
		],
	},
	// BANK ACCOUNTS
	seeBankAccounts: {
		rights: ['see_bankaccounts'],
		internalRights: [
			'internal_full_access',
			'internal_assigned_access',
		],
	},
	editBankAccounts: {
		rights: ['touch_bankaccount'],
		internalRights: [
			'internal_full_access',
			'internal_assigned_access',
		],
	},
	manageCsas: {
		isAdmin: true,
	},
	// USERS
	removeUsers: {
		rights: ['rem_user'],
		internalRights: [
			'internal_full_access',
			'internal_assigned_access',
		],
	},
	editUsers: {
		rights: ['set_user'],
		internalRights: [
			'internal_full_access',
			'internal_assigned_access',
		],
	},
	// INTERNAL USERS
	seeInternalUsers: {
		isInternal: true,
	},
	editInternalUsers: {
		isAdmin: true,
	},
	// CONTACTS
	seeContacts: {
		rights: ['see_contacts'],
		internalRights: [
			'internal_full_access',
			'internal_assigned_access',
		],
	},
	editContacts: {
		rights: ['touch_contacts'],
		internalRights: [
			'internal_full_access',
			'internal_assigned_access',
		],
	},
	// SETTINGS
	setOrganizationApiApps: {
		rights: ['set_apiapps'],
		internalRights: [
			'internal_full_access',
			'internal_assigned_access',
		],
	},
	setOrganizationAccounting: {
		rights: ['set_accounting'],
		internalRights: [
			'internal_full_access',
			'internal_assigned_access',
		],
	},
	setOrganizationProfile: {
		rights: ['set_profile'],
		internalRights: [
			'internal_full_access',
			'internal_assigned_access',
		],
	},
	setInternalOrganizationSettings: {
		internalRights: [
			'internal_full_access',
			'internal_assigned_access',
		],
	},
	// TASKS
	seeAllTasks: {
		internalRights: [
			'internal_full_access',
			'internal_assigned_access',
		],
	},
	// ROUTES
	seeInternalDashboard: {
		isInternal: true,
	},
	// OTHERS
	seeOrganizationSwitcher: {
		internalRights: [
			'internal_full_access',
			'internal_assigned_access',
		],
	},
	seeScanFactoryAction: {
		internalRights: [
			'internal_full_access',
			'internal_assigned_access',
			'internal_extract',
		],
	},
	logInAsUser: {
		isAdmin: true,
	},
	seeNonDemoFeatures: {
		rights: [
			'see_nondemo',// [TWU-1612] this doesnt come from backend, we mock it on frontend according to user email in acl-logic.js
		],
	},
	demoUser: {
		rights: [
			'demo_user',// [TWU-1612] this doesnt come from backend, we mock it on frontend according to user email in acl-logic.js
		],
	},
}

export default ACL
