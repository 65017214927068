/* @flow */
import { colors } from 'variables'

export default {
	root: {
		padding: 20,
		display: 'flex',
		width: 370,
	},
	qr: {
		width: 150,
		height: 150,
		flex: '0 0 150px',
		overflow: 'hidden',
		position: 'relative',
		background: colors.grey300,
	},
	image: {
		width: 150,
		height: 150,
	},
	text: {
		marginLeft: 35,
	},
	headline: {
		fontWeight: 700,
		fontSize: 20,
		lineHeight: 1.2,
		marginBottom: 10,
	},
	paragraph: {
		fontWeight: 500,
		fontSize: 14,
		lineHeight: 1.2,
	},
}
