// @flow
import React, { Component } from 'react'
import { Button, ConfirmDialog as confirmDialog } from 'components'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import type { BankAccountStatementOptions } from 'types'
import CsasStatementsOptionsDialog from './csas-statements-options-dialog'

type Props = {|
	statementsOptions: ?BankAccountStatementOptions,
	autoload: () => void,
	saveOptions: (statementOptions: BankAccountStatementOptions) => void,
	id: string,
	...WithTranslateProps,
|}

type State = {|
	dialogOpened: boolean,
|}

class CsasStatementsOptions extends Component<Props, State> {
	state = {
		dialogOpened: false,
	}

	openDialog = () => {
		this.setState({ dialogOpened: true })
	}

	closeDialog = () => {
		this.setState({ dialogOpened: false })
	}

	handleConfirmButtonClick = () => {
		const { statementsOptions, saveOptions, t } = this.props
		const isAutomaticDownloadEnabled = statementsOptions && statementsOptions.isAutomaticDownloadEnabled
		const newOptions = {
			...statementsOptions,
			isAutomaticDownloadEnabled: !isAutomaticDownloadEnabled,
		}
		if (isAutomaticDownloadEnabled) {
			confirmDialog(t('dialogs.csasStatementsOptionsConfirmDialog'), {
				okLabel: t('dialogs.csasStatementsOptionsConfirmYes'),
				cancelLabel: t('dialogs.csasStatementsOptionsConfirmNo'),
			}).then(() => {
				saveOptions(newOptions)
			})
		} else {
			saveOptions(newOptions)
		}
	}

	render() {
		const { dialogOpened } = this.state
		const { t, statementsOptions } = this.props
		const isAutomaticDownloadEnabled = statementsOptions && statementsOptions.isAutomaticDownloadEnabled

		return (
			<div>
				{!isAutomaticDownloadEnabled && (
					<span style={{ marginLeft: -24 }}>
						<Button
							autoTestId="csas-statement-open"
							transparent
							labelText={t('bank.statementOptions.openDialogButton')}
							onClick={this.openDialog}
						/>
					</span>
				)}
				{isAutomaticDownloadEnabled && (
					<span>
						<span style={{ fontSize: 14, lineHeight: '32px', verticalAlign: 'middle', marginRight: -15 }}>
							{t('bank.statementOptions.automaticDownloadAllowed')}
						</span>
						<Button
							transparent
							labelText={t('bank.statementOptions.cancelButton')}
							onClick={this.handleConfirmButtonClick}
							autoTestId="csas-statement-cancel"
						/>
					</span>
				)}
				<CsasStatementsOptionsDialog
					open={dialogOpened}
					handleClose={this.closeDialog}
					handleConfirmButtonClick={this.handleConfirmButtonClick}
					automaticDownloadEnabled={isAutomaticDownloadEnabled}
				/>
			</div>
		)
	}
}

export default withTranslate(CsasStatementsOptions)
