// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[3].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js!../../../variables/colors.css";
import ___CSS_LOADER_ICSS_IMPORT_1___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[3].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js!../../../variables/grid.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_1___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1XAiyOxGGw3epEzYjTZWh9 {\n}\n\n._1XAiyOxGGw3epEzYjTZWh9:before {\n\tcontent: '';\n\tdisplay: table;\n}\n\n._1XAiyOxGGw3epEzYjTZWh9:after {\n\tcontent: '';\n\tdisplay: table;\n\tclear: both;\n}\n\n._3-ozdeXNzHv13xRFkAKk-V {\n\tmargin: 25px 0 30px 0;\n}\n\n.zFjY0NOlMqY9tvP9rUUlX {\n}\n\n.xhsCV901kOE3WLUxDatEw {\n}\n\n._1vCcrHi1ZpnOXu8hxzENR1 {\n}\n\n._30K-yo-TXYMiUJl4Mmg5H7 {\n}\n\n._3lx6ygOuWVIpA5Y4qrmG9V {\n}\n\n._3-rW7uTKiA40x-p44pHbsE {\n\tmargin-bottom: 20px;\n\tmin-height: 30px;\n}\n\n._3N_02KphNdtyTy6TIHqoYL {\n\tfont-size: 12px;\n\tcolor: " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["black"] + ";\n\ttext-transform: uppercase;\n\tmargin-right: 10px;\n}\n", "",{"version":3,"sources":["webpack://./modules/cash-register/components/cash-register-edit.css"],"names":[],"mappings":"AAEA;AASA;;AANA;CACC,WAAA;CACA,cAAA;AASD;;AANA;CACC,WAAA;CACA,cAAA;CACA,WAAA;AASD;;AANA;CAEC,qBAAA;AASD;;AANA;AAUA;;AANA;AAUA;;AANA;AAUA;;AANA;AAUA;;AANA;AAUA;;AANA;CAEC,mBAAA;CACA,gBAAA;AASD;;AANA;CACC,eAAA;CACA,mDAAA;CACA,yBAAA;CACA,kBAAA;AASD","sourcesContent":["@value black, grey200 from \"variables/colors.css\";\n\n.row {\n}\n\n.row:before {\n\tcontent: '';\n\tdisplay: table;\n}\n\n.row:after {\n\tcontent: '';\n\tdisplay: table;\n\tclear: both;\n}\n\n.rowInitial {\n\tcomposes: row;\n\tmargin: 25px 0 30px 0;\n}\n\n.currency {\n\tcomposes: columnOneHalf from 'variables/grid.css';\n}\n\n.user {\n\tcomposes: columnOneHalf from 'variables/grid.css';\n}\n\n.amount {\n\tcomposes: columnOneHalf from 'variables/grid.css';\n}\n\n.shop {\n\tcomposes: columnOneHalf from 'variables/grid.css';\n}\n\n.password {\n\tcomposes: columnOneHalf from 'variables/grid.css';\n}\n\n.name {\n\tcomposes: row;\n\tmargin-bottom: 20px;\n\tmin-height: 30px;\n}\n\n.separatorText {\n\tfont-size: 12px;\n\tcolor: black;\n\ttext-transform: uppercase;\n\tmargin-right: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"black": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["black"] + "",
	"grey200": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["grey200"] + "",
	"row": "_1XAiyOxGGw3epEzYjTZWh9",
	"rowInitial": "_3-ozdeXNzHv13xRFkAKk-V _1XAiyOxGGw3epEzYjTZWh9",
	"currency": "zFjY0NOlMqY9tvP9rUUlX " + ___CSS_LOADER_ICSS_IMPORT_1___.locals["columnOneHalf"] + "",
	"user": "xhsCV901kOE3WLUxDatEw " + ___CSS_LOADER_ICSS_IMPORT_1___.locals["columnOneHalf"] + "",
	"amount": "_1vCcrHi1ZpnOXu8hxzENR1 " + ___CSS_LOADER_ICSS_IMPORT_1___.locals["columnOneHalf"] + "",
	"shop": "_30K-yo-TXYMiUJl4Mmg5H7 " + ___CSS_LOADER_ICSS_IMPORT_1___.locals["columnOneHalf"] + "",
	"password": "_3lx6ygOuWVIpA5Y4qrmG9V " + ___CSS_LOADER_ICSS_IMPORT_1___.locals["columnOneHalf"] + "",
	"name": "_3-rW7uTKiA40x-p44pHbsE _1XAiyOxGGw3epEzYjTZWh9",
	"separatorText": "_3N_02KphNdtyTy6TIHqoYL"
};
export default ___CSS_LOADER_EXPORT___;
