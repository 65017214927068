/* @flow */
/** @jsx jsx */

import { jsx } from '@emotion/core'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import DirectionArrowDown from 'components/svg-icons/trivi/direction-arrow-down'
import DirectionArrowUp from 'components/svg-icons/trivi/direction-arrow-up'
import Avatar from 'components/avatar'
import { colors } from 'variables'

export type Props = {|
	size?: number,
	issued?: boolean,
	received?: boolean,
	transparent?: boolean,
	showLabel?: boolean,
	isCashRegister?: boolean,
	...WithTranslateProps,
|}

function getIcon(issued: ?boolean, received: ?boolean) {
	if (issued) return DirectionArrowDown
	if (received) return DirectionArrowUp
	return null
}

function Direction(props: Props) {
	const size = props.size ?? 24
	const Icon = getIcon(props.issued, props.received)
	if (!Icon) return null

	const iconSize = Math.ceil(size * 0.66)
	const isIncome = props.issued
	const color = isIncome ? colors.green : colors.red
	const iconColor = props.transparent ? color : colors.white
	const key = props.isCashRegister ? 'cashRegister' : 'invoice'

	return (
		<div css={styles.root} style={{ color: props.issued ? colors.green : colors.red }}>
			<Avatar
				backgroundColor={props.transparent ? colors.transparent : color}
				style={styles.avatar}
				borderColor={color}
				borderWidth={1}
				size={size}
			>
				<Icon size={iconSize} color={iconColor} hoverColor={iconColor} style={styles.icon} disabled />
			</Avatar>
			{props.showLabel && (
				<span css={styles.label}>
					{isIncome ? props.t(`${key}.direction.issued`) : props.t(`${key}.direction.received`)}
				</span>
			)}
		</div>
	)
}

const styles = {
	root: {
		display: 'inline-block',
		textTransform: 'uppercase',
		fontSize: 12,
	},
	avatar: {
		verticalAlign: 'middle',
	},
	label: {
		verticalAlign: 'middle',
		marginLeft: 6,
	},
	icon: {
		verticalAlign: 'middle',
	},
}

export default withTranslate(Direction)
