/* @flow */

import React from 'react'
import MainLayout from 'layouts/main-layout'
import { Route } from 'react-router'
import Features from 'modules/features/pages/features'

const FeaturesComponent = MainLayout('features')(Features)

export default () => [<Route exact key="features" path="/features" component={FeaturesComponent} />]
