/* @flow */

import React, { Component } from 'react'
import DeleteButton from 'components/delete-button'
import { type AutoTestProps } from 'utils/tests/autotest'
import Tooltip from 'components/tooltip'
import { colors } from 'variables'

const confirmTimeout: number = 1800

function getStyles(props: Props, state: State) {
	return {
		root: {
			display: 'inline-block',
			minWidth: props.fullWidth ? '100%' : 0,
			position: 'relative',
			verticalAlign: 'top',
			cursor: props.disabled ? 'default' : 'pointer',
		},
		overlayContainer: {
			position: 'absolute',
			top: 0,
			left: 0,
			bottom: 0,
			right: 0,
			borderRadius: 3,
			zIndex: 2,
			overflow: 'hidden',
		},
		overlay: {
			position: 'absolute',
			top: 0,
			left: 0,
			bottom: 0,
			width: state.waiting || state.finished ? '100%' : '0%',
			opacity: state.finished ? 0 : 1,
			background: colors.whiteFaded20,
			transition: `width ${state.waiting ? confirmTimeout : '300'}ms linear, opacity 200ms linear`,
		},
	}
}

type Props = {|
	...AutoTestProps,
	disabled?: boolean,
	/**
	 * If true, the button will take up the full width of its container.
	 */
	fullWidth?: boolean,
	onClick?: () => void,
	onConfirm?: () => void,
	/**
	 * The label to be displayed within the button.
	 */
	labelText: string,
	/**
	 * The tooltip to be displayed when the buttonn is hovered.
	 */
	tooltip?: string,
	/**
	 * The tooltip to be displayed after waiting is finished and user must release button to confirm.
	 */
	tooltipFinished?: string,
	/**
	 * True to set wider padding
	 */
	wide?: boolean,
|}

type State = {|
	waiting: boolean,
	finished: boolean,
	isTooltipVisible: boolean,
|}

class ConfirmButton extends Component<Props, State> {
	mouseDownTimeout: ?TimeoutID = null

	state = {
		waiting: false,
		finished: false,
		isTooltipVisible: false,
	}

	handleMouseDown = () => {
		this.startWaiting()
	}

	handleMouseUp = () => {
		if (this.state.finished) {
			this.props.onConfirm && this.props.onConfirm()
		}
		this.stopWaiting()
	}

	handleMouseLeave = () => {
		this.setState({
			isTooltipVisible: false,
		})
		this.stopWaiting()
	}

	handleMouseEnter = () => {
		this.setState({
			isTooltipVisible: true,
		})
	}

	startWaiting = () => {
		this.mouseDownTimeout = setTimeout(this.finishWaiting, confirmTimeout)
		this.setState({
			waiting: true,
			finished: false,
		})
	}

	stopWaiting = () => {
		this.mouseDownTimeout && clearTimeout(this.mouseDownTimeout)
		this.setState({
			waiting: false,
			finished: false,
			isTooltipVisible: false,
		})
	}

	finishWaiting = () => {
		this.mouseDownTimeout && clearTimeout(this.mouseDownTimeout)
		this.setState({
			waiting: false,
			finished: true,
		})
	}

	render() {
		const styles = getStyles(this.props, this.state)

		return (
			<div
				style={styles.root}
				onMouseEnter={this.handleMouseEnter}
				onMouseLeave={this.handleMouseLeave}
				onMouseDown={this.handleMouseDown}
				onMouseUp={this.handleMouseUp}
				autoTestId={this.props.autoTestId}
			>
				<Tooltip
					inline
					visible={this.state.isTooltipVisible}
					label={this.state.finished ? this.props.tooltipFinished : this.props.tooltip}
				>
					<DeleteButton
						onClick={this.props.onClick}
						disabled={this.props.disabled}
						fullWidth={this.props.fullWidth}
						wide={this.props.wide}
						labelText={this.props.labelText}
					/>
					<div style={styles.overlayContainer}>
						<div style={styles.overlay} />
					</div>
				</Tooltip>
			</div>
		)
	}
}

export default ConfirmButton
