/* @flow */

import React, { Component } from 'react'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import TextField from 'components/TextField'
import styles from './styles.css'

export type Props = {|
	onChange: (field: string) => (event: SyntheticInputEvent<HTMLInputElement>, value: ?string) => void,
	newPassword: ?string,
	currentPassword: ?string,
	confirmPassword: ?string,
	validationValue: <T>(id: string, value: T, transformFn?: (T) => any) => T,
	validationMessage: (id: string, customMessageFn: ?(code: string, params: any) => ?string) => ?string,
|}

type ComponentProps = Props & WithTranslateProps

class PasswordSettings extends Component<ComponentProps> {
	renderInput(field: string) {
		const { t } = this.props
		return (
			<div className={styles.field}>
				<TextField
					fullWidth
					type="password"
					labelText={t(`user.settings.${field}`)}
					value={this.props.validationValue(field, this.props[field])}
					onChange={this.props.onChange(field)}
					name="password"
					autoTestId={`user-settings-password-${field}`}
					clientError={this.props.validationMessage(field)}
				/>
			</div>
		)
	}
	render() {
		const { t } = this.props

		return (
			<div className={styles.contentWrapper}>
				{this.renderInput('currentPassword')}
				{this.renderInput('newPassword')}
				<div className={styles.instructions}>{t('user.settings.newPasswordInstructions')}</div>
				{this.renderInput('confirmPassword')}
			</div>
		)
	}
}

export default withTranslate(PasswordSettings)
