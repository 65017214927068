// @flow
/** @jsx jsx */

// $FlowFixMe
import { useRef, useMemo } from 'react'
import { jsx } from '@emotion/core'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import { getPlaceholders } from '../../domain/emails'
import SelectNext from 'components/select-next'
import Tooltip from 'components/tooltip'
import { Editor } from '@toast-ui/react-editor'
import type { OptionsType, OptionType } from 'components/select-next'

type Props = {|
	...WithTranslateProps,
	type: number,
	value: ?string,
	error?: ?string,
	onChange: (value: any) => void,
|}

const TOOLBAR_ITEMS = ['bold', 'italic', 'divider', 'ul', 'ol', 'indent', 'outdent']

function TemplateEditor(props: Props) {
	const { type, t } = props

	const editorRef = useRef()
	const options = useMemo(() => {
		const placeholders = getPlaceholders(type) || []
		return placeholders.map((placeholder: string) => ({
			value: placeholder,
			label: `${placeholder} - ${t(`settings.emails.placeholders.${placeholder}`)}`,
		}))
	}, [type, t])

	function onChange() {
		props.onChange(editorRef.current.getInstance().getMarkdown())
	}

	function onPlaceholderChange(placeholder: ?OptionsType | ?OptionType) {
		placeholder && placeholder.value && editorRef.current.editorInst.insertText(placeholder.value)
	}

	return (
		<div css={style.root}>
			<div css={style.placeholders}>
				<SelectNext
					label={props.t('settings.emails.specialString')}
					onChange={onPlaceholderChange}
					inline
					autoWidth
					options={options}
					hideScrollbar
				/>
			</div>
			<Tooltip label={props.error}>
				<div className={props.error ? 'template-editor--invalid' : 'template-editor--valid'}>
					<Editor
						ref={editorRef}
						hideModeSwitch
						usageStatistics={false}
						onChange={onChange}
						value={props.value}
						initialValue={props.value}
						toolbarItems={TOOLBAR_ITEMS}
						initialEditType="wysiwyg"
						height="auto"
					/>
				</div>
			</Tooltip>
		</div>
	)
}

const style = {
	root: {
		position: 'relative',
	},
	placeholders: {
		position: 'absolute',
		top: 8,
		fontSize: 14,
		lineHeight: '24px',
		right: 12,
		zIndex: 1,
	},
}

export default withTranslate(TemplateEditor)
