/* @flow */

import type {
	AccountingDocument,
	AccountingDocumentAssignedDirection,
	AccountingDocumentAssignedType,
	State,
	InvoiceSideButton,
} from 'types'
import React, { Component } from 'react'
import { userHasAccess } from 'permissions'
import { withOrganizationSettings, type WithOrganizationSettingsProps } from 'wrappers'
import type { AccountingDocumentItem } from 'modules/accounting-document/reducers/accounting-documents'
import Name from 'modules/accounting-document/components/invoice-elements/name'
import AttachmentsContainer from '../invoice-elements-document/attachments'
import UpperSettingsContainer from 'modules/accounting-document/containers/invoice-elements/upper-settings'
import ContactContainer from '../invoice-elements/contact/contact'
import DocumentNote from 'modules/accounting-document/containers/invoice-elements/note'
import DocumentPrintingNote from 'modules/accounting-document/containers/invoice-elements/printing-note'
import HeaderDatesContainer from '../invoice-elements-document/header-dates'
import HeaderNoContainer from '../invoice-elements-document/header-no'
import HeaderOrderNoContainer from '../invoice-elements-document/header-order-no'
import Invoice from '../../components/invoices/invoice'
import LineItemsHeaderContainer from 'modules/accounting-document/containers/invoice-elements/line-items-header'
import LineItemsContainer from '../invoice-elements-document/line-items'
import ScansContainer from '../invoice-elements-document/scans'
import SettingsPanelContainer from '../invoice-settings/settings-panel'
import StateChangerContainer from '../invoice-elements-document/state-changer'
import SendEmailButton from 'modules/accounting-document/components/invoice-elements/send-email-button'
import EditButtonContainer from 'modules/accounting-document/containers/invoice-elements-document/edit-button'
import TotalToPayContainer from '../invoice-elements-document/total-to-pay'
import TotalsContainer from '../invoice-elements-document/totals'
import VatRecapContainer from '../invoice-elements-document/vat-recap'
import NeedHelpContainer from 'modules/accounting-document/containers/invoice-elements-document/need-help'
import ExtractionNoteContainer from '../invoice-elements/extraction-note'
import { cashRegisterRoute } from 'modules/cash-register/routing/routes'
import { connect } from 'react-redux'
import ActivitiesDialog from '../invoice-elements/activities-dialog'
import { getCurrentOrganizationDomesticCurrency, getOrganizationColor } from 'modules/organization/selectors'
import { getFormFieldErrorContexts } from 'helpers'
import { ACCDOC_ERROR_MAIN_PATHS } from 'modules/accounting-document/constants'
import { isAccountingDocumentExtractable } from '../../domain/accounting-document'
import {
	getAccountingDocumentCurrency,
	getAccountingDocumentExchRate,
	getAccountingDocumentExchRateDefault,
	getAccountingDocumentSadExchRate,
	getAccountingDocumentVatExchRate,
} from '../../selectors'

type StateProps = {|
	accountingDocument: ?AccountingDocument,
	showDocuments: boolean,
	color: ?string,
	exchRate: ?number,
	exchRateDefault: ?number,
	vatExchRate: ?number,
	sadExchRate: ?number,
	currencyId: ?string,
	domesticCurrencyId: ?string,
|}

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => {
	const doc: ?AccountingDocumentItem = state.accountingDocument.accountingDocuments.items[ownProps.accountingDocumentId]

	return {
		accountingDocument: doc && doc.data,
		showDocuments: userHasAccess(state, 'seeDocuments'),
		color: getOrganizationColor(state),
		exchRate: getAccountingDocumentExchRate(state, ownProps.accountingDocumentId),
		exchRateDefault: getAccountingDocumentExchRateDefault(state, ownProps.accountingDocumentId),
		vatExchRate: getAccountingDocumentVatExchRate(state, ownProps.accountingDocumentId),
		sadExchRate: getAccountingDocumentSadExchRate(state, ownProps.accountingDocumentId),
		domesticCurrencyId: getCurrentOrganizationDomesticCurrency(state),
		currencyId: getAccountingDocumentCurrency(state, ownProps.accountingDocumentId),
	}
}

type OwnProps = {|
	accountingDocumentId: string,
	direction: AccountingDocumentAssignedDirection,
	type: AccountingDocumentAssignedType,
	accountingDocumentState: string,
	title: string,
	contactSupplierTitle: string,
	contactBuyerTitle: string,
	view: 'default' | 'scans',
	scansCount: number,
	readonly: boolean,
	onViewChange: (view: 'default' | 'scans') => void,
	onClose: () => void,
	storing: boolean,
	MoreActions: any,
	sideButtons?: Array<InvoiceSideButton>,
	showSendEmailButton: boolean,
	showEditButton: boolean,
	showStateChanger: boolean,
	internal: ?boolean,
	onEditClick: () => void,
	showSaveAndStopExtraction: boolean,
	showCashbotPanel: boolean,
	isCashRegister: boolean,
|}

type Props = {
	...WithOrganizationSettingsProps,
	...StateProps,
	...OwnProps,
}

type ComponentState = {|
	isNoteOpen: boolean,
|}

class EditSimplifiedInvoice extends Component<Props, ComponentState> {
	state = {
		isNoteOpen: false,
	}

	componentDidMount() {
		if (!this.props.organizationSettingsOpen) {
			this.props.loadOrganizationSettingsOpen()
		}
	}

	toggleShowNote = () => {
		this.setState({ isNoteOpen: !this.state.isNoteOpen })
	}

	render() {
		const DIRECTION = this.props.direction
		const TYPE = this.props.type
		const DOCUMENT_ID = this.props.accountingDocumentId
		const READONLY = this.props.readonly
		const cashRegisterId = this.props.accountingDocument && this.props.accountingDocument.cashRegisterId
		const formFieldErrorContexts = !READONLY
			? getFormFieldErrorContexts(ACCDOC_ERROR_MAIN_PATHS, 'AccountingDocument', DOCUMENT_ID)
			: undefined
		const finAccountDisabled = this.props.accountingDocumentState === 'Extracting'

		const Attachments =         <AttachmentsContainer accountingDocumentId={DOCUMENT_ID} readonly={READONLY} type={TYPE} showAccountingDocuments={false} showDocuments={this.props.showDocuments} /> //eslint-disable-line
		const HeaderNo =               <HeaderNoContainer accountingDocumentId={DOCUMENT_ID} readonly={READONLY} direction={DIRECTION} type={TYPE} formFieldErrorContext={formFieldErrorContexts && formFieldErrorContexts['headerNo']} /> //eslint-disable-line
		const LineItems =             <LineItemsContainer accountingDocumentId={DOCUMENT_ID} readonly={READONLY} direction={DIRECTION} type={TYPE} disabledFinAccount={finAccountDisabled} formFieldErrorContext={formFieldErrorContexts && formFieldErrorContexts['lineItems']} /> //eslint-disable-line
		const LineItemsHeader = <LineItemsHeaderContainer accountingDocumentId={DOCUMENT_ID} readonly={READONLY} /> //eslint-disable-line
		const SettingsPanel =     <SettingsPanelContainer accountingDocumentId={DOCUMENT_ID} readonly={READONLY} direction={DIRECTION} showRemindersSettings={false} formFieldErrorContext={formFieldErrorContexts && formFieldErrorContexts['upperSettings']}/> //eslint-disable-line
		const UpperSettings =     <UpperSettingsContainer accountingDocumentId={DOCUMENT_ID} readonly={READONLY} direction={DIRECTION} hideVatCountry={this.props.view !== 'scans'} view={this.props.view} showOrganizationInfo={this.props.showSaveAndStopExtraction} toggleShowNote={this.toggleShowNote} formFieldErrorContext={formFieldErrorContexts && formFieldErrorContexts['upperSettings']} /> //eslint-disable-line
		const ContactBuyer =            <ContactContainer accountingDocumentId={DOCUMENT_ID} readonly={READONLY} direction={DIRECTION}  formFieldErrorContext={formFieldErrorContexts && formFieldErrorContexts['contact']} /> //eslint-disable-line
		const HeaderOrderNo =     <HeaderOrderNoContainer accountingDocumentId={DOCUMENT_ID} readonly={READONLY} /> //eslint-disable-line
		const HeaderDates =         <HeaderDatesContainer accountingDocumentId={DOCUMENT_ID} readonly={READONLY} isInternalUser={this.props.internal} cashRegisterId={cashRegisterId} /> //eslint-disable-line
		const VatRecap =               <VatRecapContainer accountingDocumentId={DOCUMENT_ID} readonly={READONLY} /> //eslint-disable-line
		const Totals =                   <TotalsContainer accountingDocumentId={DOCUMENT_ID} readonly={READONLY} /> //eslint-disable-line
		const Note =                        <DocumentNote accountingDocumentId={DOCUMENT_ID} readonly={READONLY} isOpen={this.state.isNoteOpen} /> //eslint-disable-line
		const PrintingNote =        <DocumentPrintingNote accountingDocumentId={DOCUMENT_ID} readonly={READONLY} /> //eslint-disable-line
		const ContactSupplier =         <ContactContainer accountingDocumentId={DOCUMENT_ID} direction={DIRECTION} isMe /> //eslint-disable-line
		const TotalsToPay =          <TotalToPayContainer accountingDocumentId={DOCUMENT_ID} /> //eslint-disable-line
		const Scans =                     <ScansContainer accountingDocumentId={DOCUMENT_ID} /> //eslint-disable-line
		const ExtractionNote =   <ExtractionNoteContainer accountingDocumentId={DOCUMENT_ID} /> //eslint-disable-line
		const Activities =              <ActivitiesDialog accountingDocumentId={DOCUMENT_ID} /> //eslint-disable-line

		const StateChanger = (
			<>
				{this.props.showSendEmailButton && <SendEmailButton accountingDocumentId={DOCUMENT_ID} />}
				{this.props.showEditButton ? (
					<EditButtonContainer accountingDocumentId={DOCUMENT_ID} />
				) : (
					<>
						{this.props.showStateChanger && (
							<StateChangerContainer
								repeatExtraction={this.props.showSaveAndStopExtraction}
								accountingDocumentId={DOCUMENT_ID}
								cashRegisterId={this.props.accountingDocument && this.props.accountingDocument.cashRegisterId}
								direction={DIRECTION}
								readonly={READONLY}
							/>
						)}
						{this.props.showSaveAndStopExtraction && (
							<StateChangerContainer
								repeatExtraction={false}
								saveAndStopExtraction={this.props.showSaveAndStopExtraction}
								accountingDocumentId={DOCUMENT_ID}
								cashRegisterId={this.props.accountingDocument && this.props.accountingDocument.cashRegisterId}
								direction={DIRECTION}
								readonly={READONLY}
							/>
						)}
					</>
				)}
			</>
		)

		const NameComponent = <Name title={this.props.title} />

		const NeedHelp =
			this.props.internal &&
			!this.props.readonly &&
			this.props.accountingDocument &&
			isAccountingDocumentExtractable(this.props.accountingDocument.state) ? (
				<NeedHelpContainer accountingDocumentId={DOCUMENT_ID} />
			) : null

		return (
			<Invoice
				accountingDocumentId={this.props.accountingDocumentId}
				readonly={this.props.readonly}
				direction={this.props.direction}
				enabledViews={{
					default: true,
					scans: true, //this.props.scansCount > 0,
				}}
				view={this.props.view}
				color={this.props.color}
				exchRate={this.props.exchRate}
				exchRateDefault={this.props.exchRateDefault}
				vatExchRate={this.props.vatExchRate}
				sadExchRate={this.props.sadExchRate}
				currencyId={this.props.currencyId}
				domesticCurrencyId={this.props.domesticCurrencyId}
				contactSupplierTitle={this.props.contactSupplierTitle}
				contactBuyerTitle={this.props.contactBuyerTitle}
				scansCount={this.props.scansCount}
				Name={NameComponent}
				LineItems={LineItems}
				LineItemsHeader={LineItemsHeader}
				HeaderNo={HeaderNo}
				HeaderOrderNo={HeaderOrderNo}
				ContactSupplier={ContactSupplier}
				ContactBuyer={ContactBuyer}
				BankAccounts={null}
				AddBankAccount={null}
				HeaderDates={HeaderDates}
				Attachments={Attachments}
				StateChanger={StateChanger}
				PaymentDetails={null}
				PaymentType={null}
				TotalsToPay={TotalsToPay}
				VatRecap={VatRecap}
				Totals={Totals}
				SettingsPanel={SettingsPanel}
				Scans={Scans}
				onViewChange={this.props.onViewChange}
				onCloseRedirectUrl={cashRegisterId && cashRegisterRoute(cashRegisterId)}
				onCloseCallback={this.props.onClose}
				storing={this.props.storing}
				MoreActions={this.props.MoreActions}
				Note={Note}
				NeedHelp={NeedHelp}
				PrintingNote={PrintingNote}
				ExtractionNote={ExtractionNote}
				sideButtons={this.props.sideButtons}
				UpperSettings={UpperSettings}
				ActivitiesDialog={this.props.internal ? Activities : null}
				showOrganizationInfo={this.props.showSaveAndStopExtraction}
				isCashRegister={this.props.isCashRegister}
			/>
		)
	}
}

export default connect(mapStateToProps)(withOrganizationSettings(EditSimplifiedInvoice))
