//@flow

import { connect } from 'react-redux'
import type { Dispatch, Signatures, State } from 'types'
import { dismissUploadProgress, listSignatures, removeSignature, uploadSignature } from '../actions'
import type { Action as SignatureAction } from 'modules/settings/actions/signature-action-types'
import SignaturesComponent from 'modules/settings/components/company/signatures'

type StateProps = {|
	signatures: ?Signatures,
	loading: boolean,
	signaturesUploadProgress: {
		[key: string]: {
			progress: ?number,
			name: ?string,
		},
	},
|}

const mapStateToProps = (state: State): StateProps => {
	return {
		signatures: state.settings.signatures.list,
		loading: state.settings.signatures.loading,
		signaturesUploadProgress: state.settings.signaturesUploadProgress,
	}
}

type DispatchProps = {|
	uploadSignatures: (Array<File>, onProgress: (e: ProgressEvent, file: File) => void) => Promise<*>,
	listSignatures: () => void,
	removeSignature: (signatureId: string) => void,
	dismissUploadProgress: (key: string) => void,
|}

const mapDispatchToProps = (dispatch: Dispatch<SignatureAction>): DispatchProps => {
	return {
		uploadSignatures: (signatures: Array<File>) => {
			let promises = signatures.map((signature: File) => {
				let promise = dispatch(uploadSignature({}, signature))
				return promise
			})

			let allPromises = Promise.all(promises)
			allPromises.then(() => {
				dispatch(listSignatures({}))
			})

			return allPromises
		},
		dismissUploadProgress: (key: string) => {
			dispatch(dismissUploadProgress(key))
		},
		listSignatures: () => {
			dispatch(listSignatures({}))
		},
		removeSignature: (signatureId: string) => {
			dispatch(removeSignature({ signatureId }))
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(SignaturesComponent)
