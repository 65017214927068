import React from 'react'
import SvgIcon from 'components/SvgIcon'
import pure from 'recompose/pure'

let AppStore = props => (
	<SvgIcon {...props}>
		<g id="AppStoreIcon" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g id="Dashboard" transform="translate(-55.000000, -658.000000)">
				<g id="Group-14" transform="translate(23.000000, 658.000000)">
					<g id="Group-13">
						<g id="apple-outline">
							<g id="Group-16" transform="translate(32.000000, 0.000000)">
								<rect
									id="Rectangle"
									stroke="#FFFFFF"
									strokeWidth="1.008"
									x="0.504"
									y="0.504"
									width="22.992"
									height="22.992"
									rx="3.528"
								/>
								<g id="Group" transform="translate(5.500000, 4.000000)" fill="#FFFFFF">
									<path
										d="M10.7720244,8.41167257 C10.7541401,6.42579031 12.3994914,5.45965212 12.4747286,5.41467247 C11.5428974,4.05727296 10.0985898,3.87180893 9.59104698,3.85702111 C8.37800126,3.72947608 7.20134074,4.58224091 6.58340896,4.58224091 C5.95314321,4.58224091 5.0015776,3.8693443 3.97600819,3.89029372 C2.65627365,3.91062699 1.42164348,4.67404868 0.744508653,5.85953967 C-0.652929887,8.27673363 0.389290383,11.8288935 1.72814258,13.7827355 C2.39787702,14.7396312 3.18046727,15.8080519 4.20480329,15.7704662 C5.20693817,15.7291835 5.58127408,15.1321249 6.79061962,15.1321249 C7.98886458,15.1321249 8.34038266,15.7704662 9.38506972,15.746436 C10.4605917,15.7291835 11.1377265,14.7852271 11.7840264,13.819705 C12.5579829,12.722941 12.8687989,11.6428133 12.8811329,11.5873589 C12.8558482,11.5787327 10.7923754,10.791897 10.7720244,8.41167257 L10.7720244,8.41167257 Z"
										id="Shape"
									/>
									<path
										d="M8.79858956,2.57171226 C9.33758395,1.89824987 9.70636958,0.9820206 9.60399765,0.0522358242 C8.82387419,0.0867407595 7.84825735,0.591375439 7.28644512,1.25005001 C6.78938622,1.83047231 6.34536338,2.78182267 6.46006928,3.67648635 C7.33639769,3.74179927 8.23616063,3.23469995 8.79858956,2.57171226 L8.79858956,2.57171226 Z"
										id="Shape"
									/>
								</g>
							</g>
						</g>
					</g>
				</g>
			</g>
		</g>
	</SvgIcon>
)
AppStore = pure(AppStore)

export default AppStore
