// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[3].use[1]!../../../node_modules/postcss-loader/dist/cjs.js!../../variables/colors.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1562AXrpFGpO8tMnGeBb-- {\n\toverflow: hidden;\n\tmargin: -2px 0;\n}\n\n._1562AXrpFGpO8tMnGeBb-- > span {\n\tpadding: 0;\n\tborder: none;\n\tdisplay: block;\n\twidth: 100%;\n\theight: auto;\n\toutline: none;\n\tbackground: transparent;\n\tmargin: 0;\n\twhite-space: normal;\n}\n\n._1562AXrpFGpO8tMnGeBb-- input {\n\t-webkit-appearance: none;\n\toutline: none;\n\tborder: none;\n\tfont-size: 14px;\n\tbackground: transparent;\n\tline-height: 24px;\n\tmargin: 4px 0;\n}\n\n\n", "",{"version":3,"sources":["webpack://./components/multi-email-input/styles.css"],"names":[],"mappings":"AAEA;CACC,gBAAA;CACA,cAAA;AAmBD;;AAhBA;CACC,UAAA;CACA,YAAA;CACA,cAAA;CACA,WAAA;CACA,YAAA;CACA,aAAA;CACA,uBAAA;CACA,SAAA;CACA,mBAAA;AAmBD;;AAhBA;CACC,wBAAA;CACA,aAAA;CACA,YAAA;CACA,eAAA;CACA,uBAAA;CACA,iBAAA;CACA,aAAA;AAmBD","sourcesContent":["@value white, black, grey400, green600, green400, green, red from \"variables/colors.css\";\n\n.tagsInput {\n\toverflow: hidden;\n\tmargin: -2px 0;\n}\n\n.tagsInput > span {\n\tpadding: 0;\n\tborder: none;\n\tdisplay: block;\n\twidth: 100%;\n\theight: auto;\n\toutline: none;\n\tbackground: transparent;\n\tmargin: 0;\n\twhite-space: normal;\n}\n\n.tagsInput input {\n\t-webkit-appearance: none;\n\toutline: none;\n\tborder: none;\n\tfont-size: 14px;\n\tbackground: transparent;\n\tline-height: 24px;\n\tmargin: 4px 0;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"white": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["white"] + "",
	"black": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["black"] + "",
	"grey400": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["grey400"] + "",
	"green600": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["green600"] + "",
	"green400": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["green400"] + "",
	"green": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["green"] + "",
	"red": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["red"] + "",
	"tagsInput": "_1562AXrpFGpO8tMnGeBb--"
};
export default ___CSS_LOADER_EXPORT___;
