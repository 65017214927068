// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[3].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js!../../../variables/colors.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._329mFRRvpGouYw15OYRmtZ {\n\tmargin: 40px auto;\n\ttext-align: center;\n\tmax-width: 340px;\n\twidth: 100%;\n}\n\n.AaeF71ce4c1RZ3bxR0Z-V {\n\tmargin-bottom: 25px;\n}\n\n._2BkjtCl0sJUgD1qMNvhCbw {\n\tmargin-top: 10px;\n}\n\n._3omu6uTmv1CrviCYMJV75X {\n\topacity: 0.6;\n\tcolor: " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["black"] + ";\n\tfont-size: 12px;\n\tline-height: 15px;\n\tmargin-bottom: 20px;\n\tmargin-top: -5px;\n\ttext-align: left;\n}\n", "",{"version":3,"sources":["webpack://./modules/user/components/styles.css"],"names":[],"mappings":"AAEA;CACC,iBAAA;CACA,kBAAA;CACA,gBAAA;CACA,WAAA;AAWD;;AARA;CACC,mBAAA;AAWD;;AARA;CACC,gBAAA;AAWD;;AARA;CACC,YAAA;CACA,mDAAA;CACA,eAAA;CACA,iBAAA;CACA,mBAAA;CACA,gBAAA;CACA,gBAAA;AAWD","sourcesContent":["@value blue, white, black from \"variables/colors.css\";\n\n.wrapper {\n\tmargin: 40px auto;\n\ttext-align: center;\n\tmax-width: 340px;\n\twidth: 100%;\n}\n\n.field {\n\tmargin-bottom: 25px;\n}\n\n.button {\n\tmargin-top: 10px;\n}\n\n.instructions {\n\topacity: 0.6;\n\tcolor: black;\n\tfont-size: 12px;\n\tline-height: 15px;\n\tmargin-bottom: 20px;\n\tmargin-top: -5px;\n\ttext-align: left;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"blue": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["blue"] + "",
	"white": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["white"] + "",
	"black": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["black"] + "",
	"wrapper": "_329mFRRvpGouYw15OYRmtZ",
	"field": "AaeF71ce4c1RZ3bxR0Z-V",
	"button": "_2BkjtCl0sJUgD1qMNvhCbw",
	"instructions": "_3omu6uTmv1CrviCYMJV75X"
};
export default ___CSS_LOADER_EXPORT___;
