/* @flow */

import React, { Component } from 'react'
import PaymentDetails from './payment-details'
import { formatDateToIsoString } from 'utils/formatters'
import { emptyAccountingDocument as emptyAccDoc } from 'types/empty'
import type { AccountingDocument } from 'types'

type Props = {|
	accDoc: AccountingDocument,
	onChange: (accDoc: AccountingDocument) => void,
	readonlyDueDate?: boolean,
	accDocDefaultDueDate: ?number,
	loadDefaults: () => void,
	refreshDefaults: () => void,
|}

export default class InvoiceContactTemplate extends Component<Props> {
	componentDidMount() {
		this.props.loadDefaults()
	}

	UNSAFE_componentWillReceiveProps() {
		this.props.refreshDefaults()
	}

	onDueDateChange = (dueDate: ?Date) => {
		const dueDateString: ?string = (dueDate && formatDateToIsoString(dueDate)) || null
		const newDocument: AccountingDocument = Object.assign(emptyAccDoc(), this.props.accDoc, {
			dueDate: dueDateString || undefined,
		})
		this.props.onChange(newDocument)
	}

	onVariableSymbolChange = (variableSymbol: ?string) => {
		const newDocument: AccountingDocument = Object.assign(emptyAccDoc(), this.props.accDoc, {
			variableSymbol: variableSymbol || undefined,
		})
		this.props.onChange(newDocument)
	}

	render() {
		return (
			<PaymentDetails
				accountingDocumentId={this.props.accDoc.id || ''}
				issueDate={this.props.accDoc.issueDate}
				dueDate={this.props.accDoc.dueDate}
				hintTextDueDate={this.props.accDocDefaultDueDate}
				variableSymbol={this.props.accDoc.variableSymbol}
				variableSymbolPreview={this.props.accDoc.variableSymbolPreview}
				onDueDateChange={this.onDueDateChange}
				onVariableSymbolChange={this.onVariableSymbolChange}
				readonly={false}
				readonlyDueDate={this.props.readonlyDueDate}
				isTemplate
			/>
		)
	}
}
