/* @flow */

import type {
	Dispatch,
	State,
	OAuthLinkRequest,
	OAuthLinkResponse,
	AccountingDocumentReduxAction,
	OAuthTokenRemoveRequest,
} from 'types'
import type { CashbotAction } from '../actions/action-types'
import type { CashbotInvoice, CashbotInvoiceCreate, CashbotOffer, CashbotCompany } from '../types'
import { Number_AccountingDocumentAssignedDirection } from 'types/convertor'
import { getCashbotInvoiceId, getCashbotToken } from '../selectors'
import { getInvoiceDetail, getCompanyInfo, createInvoice, getInvoiceOfferList, confirmInvoiceOffer } from '../api'
import { getCurrentUserId, getCurrentOrganizationId } from 'modules/user/selectors'
import { getCurrentOrganizationRegNo } from 'modules/settings/selectors'
import { oauthLink as oauthLinkApi, oauthLinkRemove as oauthLinkRemoveApi } from 'modules/common/models/api-model'
import {
	getAccountingDocument,
	getAccDocCashbotId,
	getAccDocPdfUrl,
	getAccDocFileIds,
	getReceivedAccDocType,
} from 'modules/accounting-document/selectors'
import {
	updateAccDocCashbotId,
	loadAccountingDocumentPdf,
	loadAccountingDocumentScans,
} from 'modules/accounting-document/actions'
import { formatCashbotDate } from '../domain'
import { fetchBlobAsBase64 } from 'utils/blob'
import { getFileData } from 'modules/file/actions'
import { getUrlFromBlob } from '../../file/domain'
// import { getTestPdfBase64 } from '../api/test-data'

export function loadCashbotInvoice(accDocId: string) {
	return async (dispatch: Dispatch<CashbotAction>, getState: () => State) => {
		const cashbotInvoiceId = getCashbotInvoiceId(getState(), accDocId) || 0
		const cashbotToken = getCashbotToken(getState()) || ''
		dispatch({
			type: 'START_LOAD_CASHBOT_INVOICE',
			accDocId,
			cashbotInvoiceId,
		})
		try {
			const cashbotInvoice: ?CashbotInvoice = await getInvoiceDetail(cashbotToken, cashbotInvoiceId)
			return dispatch({
				type: 'FINISH_LOAD_CASHBOT_INVOICE',
				accDocId,
				cashbotInvoiceId,
				cashbotInvoice,
			})
		} catch (serverError) {
			return dispatch({
				type: 'FINISH_LOAD_CASHBOT_INVOICE',
				accDocId,
				cashbotInvoiceId,
				cashbotInvoice: null,
				serverError,
			})
		}
	}
}

export function loginToCashbot() {
	return async (dispatch: Dispatch<CashbotAction>, getState: () => State) => {
		const body: OAuthLinkRequest = {
			service: 'cashbot',
			reference: {
				userId: getCurrentUserId(getState()) || '',
				organizationId: getCurrentOrganizationId(getState()) || '',
			},
			returnUrl: window.location.href,
		}

		try {
			const oauthLink: ?OAuthLinkResponse = await oauthLinkApi.post(body)
			if (oauthLink && oauthLink.oauthUrl) {
				window.location.href = oauthLink.oauthUrl
			}
		} catch (serverError) {
			return dispatch({
				type: 'CASHBOT_API',
				serverError,
			})
		}
	}
}

export function logoutFromCashbot() {
	return async (dispatch: Dispatch<CashbotAction>, getState: () => State) => {
		dispatch({
			type: 'LOGOUT_FROM_CASHBOT',
			phase: 'START',
		})
		const body: OAuthTokenRemoveRequest = {
			service: 'cashbot',
			reference: {
				userId: getCurrentUserId(getState()) || '',
				organizationId: getCurrentOrganizationId(getState()) || '',
			},
		}
		try {
			await oauthLinkRemoveApi.post(body)
			return dispatch({
				type: 'LOGOUT_FROM_CASHBOT',
				phase: 'SUCCESS',
			})
		} catch (serverError) {
			return dispatch({
				type: 'LOGOUT_FROM_CASHBOT',
				phase: 'ERROR',
				serverError,
			})
		}
	}
}

type CasbotCompanySuccess = {| success: true, company: CashbotCompany |}
type CasbotCompanyError = {| success: false, serverError: { code: any } |}
export type CasbotCompanyResult = CasbotCompanySuccess | CasbotCompanyError

export function getCashbotCompanyOfCurrentOrg() {
	return async (dispatch: Dispatch<CashbotAction>, getState: () => State): Promise<CasbotCompanyResult> => {
		const cashbotToken = getCashbotToken(getState()) || ''
		const organizationRegNo = getCurrentOrganizationRegNo(getState()) || ''
		try {
			return {
				success: true,
				company: await getCompanyInfo(cashbotToken, organizationRegNo),
			}
		} catch (serverError) {
			dispatch({
				type: 'CASHBOT_API',
				serverError,
			})
			return {
				success: false,
				serverError,
			}
		}
	}
}

export function sendCashbotInvoice(accDocId: string, cashbotCompanyId: number, requestedFinancingDueDate?: string) {
	return async (
		dispatch: Dispatch<CashbotAction | AccountingDocumentReduxAction>,
		getState: () => State,
	): Promise<boolean> => {
		const accDoc = getAccountingDocument(getState(), accDocId)
		const cashbotToken = getCashbotToken(getState()) || ''

		if (!accDoc) {
			return false
		}

		const direction = Number_AccountingDocumentAssignedDirection(accDoc.direction) || 'issued'
		const pdfBase64Array = []
		let mimeType = ''

		try {
			if ('issued' === direction) {
				await dispatch(loadAccountingDocumentPdf(accDocId))
				const pdfBlobUrl = getAccDocPdfUrl(getState(), accDocId)
				pdfBlobUrl && pdfBase64Array.push(await fetchBlobAsBase64(pdfBlobUrl))
			} else if ('received' === direction) {
				await dispatch(loadAccountingDocumentScans(accDocId))
				const fileType = getReceivedAccDocType(getState(), accDocId)
				const ids = getAccDocFileIds(getState(), accDocId, fileType) || []
				for (const id of ids) {
					const file = await getFileData(id)
					if (file && file.blob) {
						mimeType = file.blob.type
						pdfBase64Array.push(await fetchBlobAsBase64(getUrlFromBlob(file.blob)))
					}
				}
			}
		} catch (serverError) {
			dispatch({
				type: 'CASHBOT_API',
				serverError,
			})
			return false
		}

		const directionSpecificProps =
			'issued' === direction
				? {
						purchaserIdentificationNumber: (accDoc.contact && accDoc.contact.companyRegNo) || '',
				  }
				: {
						mimeType,
						supplierIdentificationNumber: (accDoc.contact && accDoc.contact.companyRegNo) || '',
						requestedFinancingDueDate,
				  }

		const cashbotInvoiceCreate: CashbotInvoiceCreate = {
			companyId: cashbotCompanyId,
			type: direction,
			...directionSpecificProps,
			// Pokud by se cashbot posílal z dokladu, který není zpracovaný, neni číslo dokladu vždy v poli
			// accountingDocumentNo, ale může být i v explicitNo.
			// Tím, že zobzaujeme Cashbot jen na zpracovaných, můžeme se opřít pevně o accountingDocumentNo.
			invoiceNumber: /*accDoc.explicitNo ||*/ accDoc.accountingDocumentNo || '',
			variableSymbol: accDoc.variableSymbol || '',
			issueDate: accDoc.issueDate && formatCashbotDate(accDoc.issueDate),
			dueDate: accDoc.dueDate && formatCashbotDate(accDoc.dueDate),
			amount: accDoc.total || 0,
			currency: accDoc.currency,
			binaryData: pdfBase64Array,
		}

		dispatch({
			type: 'START_CREATE_CASHBOT_INVOICE',
			accDocId,
			cashbotInvoiceCreate,
		})

		try {
			const cashbotInvoice: CashbotInvoice = await createInvoice(cashbotToken, cashbotInvoiceCreate)
			await dispatch(updateAccDocCashbotId(accDocId, cashbotInvoice.id))
			// TODO-CASHBOT pokud by se nepovedlo uložení ID k dokladu, informaci o Cashbot ID ztratíme.
			// Zatím nebudeme řešit, bude podchyceno, až se naimplementuje volání cashbota přes
			// BE proxy, kdy se o uložení ID bude starat BE.
			dispatch({
				type: 'FINISH_CREATE_CASHBOT_INVOICE',
				accDocId,
				cashbotInvoice,
			})
			return true
		} catch (serverError) {
			dispatch({
				type: 'FINISH_CREATE_CASHBOT_INVOICE',
				accDocId,
				serverError,
			})
			return false
		}
	}
}

export function downloadCashbotOffers(accDocId: string) {
	return async (dispatch: Dispatch<CashbotAction>, getState: () => State): Promise<?Array<CashbotOffer>> => {
		const cashbotInvoiceId = getAccDocCashbotId(getState(), accDocId)
		const cashbotToken = getCashbotToken(getState()) || ''
		if (!cashbotInvoiceId) {
			return undefined
		}

		dispatch({
			type: 'START_DOWNLOAD_CASHBOT_OFFERS',
			accDocId,
			cashbotInvoiceId,
		})

		try {
			const cashbotOffers: Array<CashbotOffer> = await getInvoiceOfferList(cashbotToken, cashbotInvoiceId)
			dispatch({
				type: 'FINISH_DOWNLOAD_CASHBOT_OFFERS',
				accDocId,
				cashbotOffers,
			})
			return cashbotOffers
		} catch (serverError) {
			dispatch({
				type: 'FINISH_CREATE_CASHBOT_INVOICE',
				accDocId,
				serverError,
			})
			return undefined
		}
	}
}

export function confirmCashbotOffer(accDocId: string, offer: CashbotOffer) {
	return async (dispatch: Dispatch<CashbotAction>, getState: () => State): Promise<?CashbotInvoice> => {
		const cashbotToken = getCashbotToken(getState()) || ''
		dispatch({
			type: 'START_CONFIRM_CASHBOT_OFFER',
			accDocId,
			offer,
		})
		try {
			const cashbotInvoice: CashbotInvoice = await confirmInvoiceOffer(cashbotToken, offer)
			dispatch({
				type: 'FINISH_CONFIRM_CASHBOT_OFFER',
				accDocId,
				offer,
				cashbotInvoice,
			})
			return cashbotInvoice
		} catch (serverError) {
			dispatch({
				type: 'FINISH_CONFIRM_CASHBOT_OFFER',
				accDocId,
				offer,
				serverError,
			})
			return undefined
		}
	}
}

export function markAccDocToBeFinancedByCashbot(accDocId: string, toBeFinanced: boolean) {
	return async (dispatch: Dispatch<CashbotAction>): Promise<void> => {
		return dispatch({
			type: 'MARK_TO_BE_FINANCED',
			accDocId,
			toBeFinanced,
		})
	}
}
