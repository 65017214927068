/* @flow */

import { connect } from 'react-redux'
import type { State, AccountingDocument, FormFieldErrorMessage, FormFieldErrorContext } from 'types'
import type { ClassificationType } from '../../components/invoice-elements/classification'
import Classification from '../../components/invoice-elements/classification'
import { getFormFieldErrors } from 'modules/common/selectors'
import { getAccountingDocument } from '../../selectors'

type OwnProps = {|
	accountingDocumentId: string,
	classification: ClassificationType,
	formFieldErrorContext?: FormFieldErrorContext,
	onChange: (classification: ClassificationType) => void,
|}

type StateProps = {|
	accountingDocument: ?AccountingDocument,
	errors: ?Array<FormFieldErrorMessage>,
|}

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => {
	return {
		accountingDocument: getAccountingDocument(state, ownProps.accountingDocumentId),
		errors: ownProps.formFieldErrorContext && getFormFieldErrors(state, ownProps.formFieldErrorContext),
	}
}

export default connect(mapStateToProps)(Classification)
