/* @flow */

import { connect } from 'react-redux'
import type {
	Contact,
	Dispatch,
	State,
	AddressBookAction,
	AccountingDocumentReduxAction,
	AccountingDocumentContact,
} from 'types'
import { getAccountingDocumentContact } from '../../accounting-document/selectors'
import SendEmailDialog from '../components/dialogs/send-email-dialog'
import { loadContact } from 'modules/address-book/actions'

type OwnProps = {|
	open: boolean,
	accountingDocumentId: ?string,
	onClose: () => void,
	onSend: (emails: Array<string>, templateId: number) => void,
|}

type StateProps = {|
	myEmail: ?string,
	addressBookContact: ?Contact,
	contact?: ?AccountingDocumentContact,
|}

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => {
	const contact = ownProps.accountingDocumentId
		? getAccountingDocumentContact(state, ownProps.accountingDocumentId)
		: undefined
	return {
		contact,
		myEmail: state.user.me.data && state.user.me.data.email,
		addressBookContact:
			contact && contact.contactId
				? state.addressBook.contactItems[contact.contactId] && state.addressBook.contactItems[contact.contactId].contact
				: null,
	}
}

type DispatchProps = {|
	loadContact: (contactId: string) => void,
|}

const mapDispatchToProps = (dispatch: Dispatch<AddressBookAction | AccountingDocumentReduxAction>): DispatchProps => {
	return {
		loadContact: (contactId: string) => {
			dispatch(loadContact(contactId))
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(SendEmailDialog)
