/* @flow */
/** @jsx jsx */

import { Component } from 'react'
import { jsx } from '@emotion/core'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import type {
	Contact,
	ContactAddress,
	FormValidationProps,
	ContactPerson,
	ContactBankAccount,
	ContactAddressType,
} from 'types'
import { getContactAddressByType } from '../domain/contact-address'
import { Validator } from 'utils'
import BankAccountEdit from './bank-account-edit'
import { emptyContact } from 'types/empty'
import ContactPersonEdit from './contact-person-edit'
import ContactAddressEdit from '../components/contact-address-edit'
import ContactSettings from '../components/contact-settings'
import SearchIcon from 'components/svg-icons/action/search'
import EmailInput from 'components/email-input'
import AddEntity from 'components/add-entity'
import TextField from 'components/TextField'
import Separator from 'components/Separator'
import Tooltip from 'components/tooltip'
import Divider from 'components/divider'
import Toggle from 'components/toggle'
import Paper from 'components/Paper'
import styles from './contact-edit.css'
import { colors } from 'variables'
import type { OptionType } from 'react-select/src/types'

type Props = {|
	...FormValidationProps,
	...WithTranslateProps,
	isNew?: boolean,
	contact: Contact,
	billingIsSameAsShipping: boolean,
	disabled?: boolean,
	setContactState: (contact: Contact) => void,
	setBillingIsSameAsShipping: (value: boolean) => void,
	loadContactFromARES: (contact: Contact) => Promise<*>,
|}

type State = {|
	aresLoadedFor: string | null,
|}

class ContactEdit extends Component<Props, State> {
	state = {
		aresLoadedFor: null,
	}

	billingIsSameAsShippingToggle = () => {
		this.props.setBillingIsSameAsShipping(!this.props.billingIsSameAsShipping)
	}

	getOnFieldChange = (name: string) => (event: ?SyntheticInputEvent<HTMLInputElement>, value: ?string) => {
		this.props.setContactState({
			...this.props.contact,
			[name]: value,
		})
	}

	onFieldBlur = () => {
		let contact: Contact = this.props.contact
		this.props.setContactState(contact)
	}

	getArrayUpdater = (name: string, index: number) => (value: any) => {
		const { contact } = this.props
		if (!contact || !contact[name]) {
			return
		}

		let arrValue = contact[name].slice()
		arrValue[index] = value

		this.props.setContactState({
			...contact,
			[name]: arrValue,
		})
	}

	removeArrayItem = (name: string, index: number) => () => {
		const { contact } = this.props
		if (!contact[name]) {
			return
		}

		let newArr = contact[name].slice()
		newArr.splice(index, 1)

		this.props.setContactState({
			...contact,
			[name]: newArr,
		})
	}

	getArrayNewObject = (name: string) => () => {
		const { contact } = this.props
		if (!contact) {
			return
		}

		let arrVal = (contact[name] || []).slice()
		let len = arrVal.length
		arrVal[len] = {}

		this.props.setContactState({
			...contact,
			[name]: arrVal,
		})
	}

	handleDefaultDuePeriodChange = (defaultDuePeriod: ?number) => {
		let contact: Contact = this.props.contact

		if (typeof defaultDuePeriod === 'number') {
			contact.defaultDuePeriod = defaultDuePeriod
		} else {
			delete contact.defaultDuePeriod
		}

		this.props.setContactState(contact || this.props.contact)
	}

	handleDueDateChange = (dueDateInterval: Date) => {
		let contact: Contact = this.props.contact

		contact.defaultDuePeriod = dueDateInterval
		this.props.setContactState(contact || this.props.contact)
	}

	getContactAddressUpdater = (type: ContactAddressType) => (value: ContactAddress) => {
		const { contact } = this.props

		if (!contact) {
			return
		}

		let addresses = (contact.addresses || []).filter((it: ContactAddress) => it.type != type)
		addresses.push(value)

		this.props.setContactState({
			...emptyContact(),
			...contact,
			addresses,
		})
	}

	renderBillingIsSameAsShipping = (t: Function) => {
		return (
			<Separator
				className={styles.separator}
				left={<span className={styles.separatorText}>{t('contactEdit.edit.billingAddress')}</span>}
				right={
					<span>
						<span css={style.billingIsSameAsDelivery}>{t('contactEdit.edit.billingIsSameAsDelivery')}</span>
						<span css={style.toggle}>
							<Toggle
								autoWidth
								onToggle={this.billingIsSameAsShippingToggle}
								toggled={this.props.billingIsSameAsShipping}
								autoTestId="contact-edit-billingIsSameAsShipping"
								disabled={this.props.disabled}
							/>
						</span>
					</span>
				}
			/>
		)
	}

	getLoadFromARES = (contact: Contact) => () => {
		this.props.loadContactFromARES(contact).then(() => {
			this.setState({ aresLoadedFor: contact.companyRegNo })
			this.props.setContactState(contact)
		})
	}

	onLanguageChange = (language: ?OptionType) => {
		this.getOnFieldChange('language')(null, language && language.value ? language.value : '')
	}

	renderIc = () => {
		const { t, contact } = this.props

		return (
			<div className={styles.ic}>
				<span>{t('contactEdit.edit.IC') + ':'}</span>
				<TextField
					inline
					autoWidth
					disabled={this.props.disabled}
					name={'companyRegNo'}
					value={contact.companyRegNo || ''}
					onChange={this.getOnFieldChange('companyRegNo')}
					onBlur={this.onFieldBlur}
					hintText={t('contactEdit.hint.companyRegNo')}
					labelText={t('contactEdit.edit.IC') + ':'}
					clientError={this.props.validationMessage('companyRegNo')}
					autoFocus={this.props.isNew}
					autoTestId="contact-edit-companyno"
				/>
				{!this.props.disabled &&
					this.props.isNew &&
					this.state.aresLoadedFor !== contact.companyRegNo &&
					Validator.ICO(contact.companyRegNo) && (
						<div autoTestId="contact-edit-ares" className={styles.ares} onClick={this.getLoadFromARES(contact)}>
							<Tooltip label={t('invoice.contact.aresHint')} inline>
								<span>{t('invoice.contact.searchAresHint')}</span>
								<div className={styles.icIcon}>
									<SearchIcon size={20} hoverColor={colors.black} />
								</div>
							</Tooltip>
						</div>
					)}
			</div>
		)
	}

	render() {
		const { t, contact } = this.props

		return (
			<div>
				<Paper rounded zDepth={3} className={styles.root}>
					<div className={styles.mainContainer}>
						{this.renderIc()}

						<div className={styles.name}>
							<TextField
								autoWidth
								disabled={this.props.disabled}
								name={'companyName'}
								value={this.props.validationValue('companyName', contact.companyName)}
								onChange={this.getOnFieldChange('companyName')}
								onBlur={this.onFieldBlur}
								hintText={t('contactEdit.hint.companyName')}
								clientError={this.props.validationMessage('companyName')}
								autoTestId="contact-edit-companyname"
								inline
							/>
						</div>
						<div>
							<div className={styles.firstName}>
								<TextField
									inline
									autoWidth
									disabled={this.props.disabled}
									name={'firstName'}
									value={this.props.validationValue('firstName', contact.firstName)}
									onChange={this.getOnFieldChange('firstName')}
									onBlur={this.onFieldBlur}
									hintText={t('contactEdit.hint.firstName')}
									clientError={this.props.validationMessage('firstName')}
									autoTestId="contact-edit-firstname"
								/>
							</div>
							<div className={styles.lastName}>
								<TextField
									inline
									autoWidth
									disabled={this.props.disabled}
									name={'lastName'}
									value={this.props.validationValue('lastName', contact.lastName)}
									onChange={this.getOnFieldChange('lastName')}
									onBlur={this.onFieldBlur}
									hintText={t('contactEdit.hint.lastName')}
									clientError={this.props.validationMessage('lastName')}
									autoTestId="contact-edit-lastname"
								/>
							</div>
							<div className={styles.dic}>
								<span>{t('contactEdit.edit.DIC') + ':'}</span>
								<TextField
									inline
									autoWidth
									disabled={this.props.disabled}
									name={'taxId'}
									value={contact.taxId || ''}
									onChange={this.getOnFieldChange('taxId')}
									onBlur={this.onFieldBlur}
									hintText={t('contactEdit.hint.taxId')}
									labelText={t('contactEdit.edit.DIC') + ':'}
									autoTestId="contact-edit-dic"
								/>
							</div>
							<div className={styles.email}>
								<span>{t('contactEdit.tile.hint.email') + ':'}</span>
								<EmailInput
									inline
									autoWidth
									disabled={this.props.disabled}
									name={'email'}
									value={this.props.validationValue('email', contact.email || '')}
									onChange={this.getOnFieldChange('email')}
									onBlur={this.onFieldBlur}
									hintText={t('contactEdit.hint.email')}
									labelText={t('contactEdit.tile.hint.email') + ':'}
									clientError={this.props.validationMessage('email')}
									autoTestId="contact-edit-email"
								/>
							</div>
						</div>
					</div>
					<Divider />
					<div className={styles.othersContainer}>
						{this.renderBillingIsSameAsShipping(t)}
						<div className={styles.addresses}>
							<ContactAddressEdit
								disabled={this.props.disabled}
								address={getContactAddressByType(contact.addresses, 1)}
								onBlur={this.getContactAddressUpdater(1)}
								type={1}
								{...this.props.validationProps()}
							/>
							{!this.props.billingIsSameAsShipping && (
								<ContactAddressEdit
									disabled={this.props.disabled}
									address={getContactAddressByType(contact.addresses, 2)}
									onBlur={this.getContactAddressUpdater(2)}
									type={2}
									{...this.props.validationProps()}
								/>
							)}
						</div>
						<Separator
							className={styles.separator}
							left={<span className={styles.separatorText}>{t('contactEdit.edit.bankContact')}</span>}
						/>
						<div className={styles.bankContacts}>
							{contact.bankAccounts &&
								contact.bankAccounts.map(
									(bankAccount: ContactBankAccount, index: number, allItems: Array<ContactBankAccount>) => (
										<BankAccountEdit
											id={index}
											key={`${index}/${allItems.length}`}
											disabled={this.props.disabled}
											bankAccount={bankAccount}
											onBlur={this.getArrayUpdater('bankAccounts', index)}
											onDelete={this.removeArrayItem('bankAccounts', index)}
											className={styles.bankContact}
											{...this.props.validationProps()}
										/>
									),
								)}
							{!this.props.disabled && (
								<div className={styles.addBankContact}>
									<AddEntity
										labelText={t('contactEdit.edit.addBankContact')}
										onClick={this.getArrayNewObject('bankAccounts')}
									/>
								</div>
							)}
						</div>

						<Separator
							className={styles.separator}
							left={<span className={styles.separatorText}>{t('contactEdit.edit.contactPersons')}</span>}
						/>
						<div className={styles.contactsPeople}>
							{contact.persons &&
								contact.persons.map((person: ContactPerson, index: number, allItems: Array<ContactPerson>) => (
									<ContactPersonEdit
										id={index}
										key={`${index}/${allItems.length}`}
										contactPerson={person}
										disabled={this.props.disabled}
										onBlur={this.getArrayUpdater('persons', index)}
										onDelete={this.removeArrayItem('persons', index)}
										className={styles.contactPerson}
										{...this.props.validationProps()}
									/>
								))}
							{!this.props.disabled && (
								<div className={styles.addContactPerson}>
									<AddEntity
										labelText={t('contactEdit.edit.addContactPerson')}
										onClick={this.getArrayNewObject('persons')}
									/>
								</div>
							)}
						</div>
					</div>
				</Paper>
				<ContactSettings
					onDefaultDuePeriodChange={this.handleDefaultDuePeriodChange}
					defaultDuePeriod={this.props.contact.defaultDuePeriod}
					language={this.props.contact.language}
					onLanguageChange={this.onLanguageChange}
					disabled={this.props.disabled}
				/>
			</div>
		)
	}
}

const style = {
	toggle: {
		display: 'inline-block',
		verticalAlign: 'middle',
	},
	billingIsSameAsDelivery: {
		fontSize: 12,
		textTransform: 'uppercase',
		marginLeft: 10,
		marginRight: 8,
	},
}

export default withTranslate(ContactEdit, { withRef: true })
