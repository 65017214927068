/* @flow */

import { ACCOUNTING_DOCUMENT_TITLES, ACCOUNTING_DOCUMENT_TITLES_NO } from '../../constants'
import type { AccountingDocument, AccountingDocumentAssignedType, AccountingDocumentType, WithRouterProps } from 'types'
import { Number_AccountingDocumentAssignedDirection, Number_AccountingDocumentAssignedType } from 'types/convertor'
// $FlowFixMe - there is useState
import React, { useEffect, useState } from 'react'
import {
	type WithAccountingDocumentActionsProps,
	type WithTranslateProps,
	withAccountingDocumentActions,
	withTranslate,
} from 'wrappers'

import AccountingDocumentSummary from 'modules/accounting-document/containers/invoice-elements/document-summary'
import BankAccountsContainer from 'modules/accounting-document/containers/invoice-elements-document/bank-accounts'
import ContactContainer from 'modules/accounting-document/containers/invoice-elements/contact/contact'
import DocumentPrintingNote from 'modules/accounting-document/containers/invoice-elements/printing-note'
import DownloadButtonContainer from 'modules/accounting-document/containers/invoice-elements-document/download-button'
import HeaderDatesContainer from 'modules/accounting-document/containers/invoice-elements-document/header-dates'
import HeaderNoContainer from 'modules/accounting-document/containers/invoice-elements-document/header-no'
import HeaderOrderNoContainer from 'modules/accounting-document/containers/invoice-elements-document/header-order-no'
import LineItemsContainer from 'modules/accounting-document/containers/invoice-elements-document/line-items'
import LineItemsHeaderContainer from 'modules/accounting-document/containers/invoice-elements/line-items-header'
import Loader from 'components/loader'
import PaymentDetailsContainer from 'modules/accounting-document/containers/invoice-elements-document/payment-details'
import PaymentStateContainer from 'modules/accounting-document/containers/invoice-elements-document/payment-state'
import PaymentTypeContainer from 'modules/accounting-document/containers/invoice-elements-document/payment-type'
import PreviewActionsContainer from 'modules/accounting-document/containers/invoice-elements-document/preview-actions'
import PublicInvoice from 'modules/accounting-document/components/invoices/public-invoice'
import PublicLogoContainer from 'modules/accounting-document/containers/invoice-elements/public-logo'
import QrContainer from 'modules/accounting-document/containers/invoice-elements-document/qr'
import SendEmailDialog from 'modules/common/containers/send-email-dialog'
import SettingsPanelContainer from 'modules/accounting-document/containers/invoice-settings/settings-panel'
import TotalToPayContainer from 'modules/accounting-document/containers/invoice-elements-document/total-to-pay'
import TotalsContainer from 'modules/accounting-document/containers/invoice-elements-document/totals'
import VatRecapContainer from 'modules/accounting-document/containers/invoice-elements-document/vat-recap'
import { accountingDocumentsRoute } from '../../routing/routes'
import styles from './edit-accounting-document.css'
import { withRouter } from 'react-router'

type Props = {|
	...WithRouterProps,
	...WithTranslateProps,
	...WithAccountingDocumentActionsProps,
	type: AccountingDocumentAssignedType,
	uniqueId: string,
	userIsIssuer: boolean,
	accountingDocument: ?AccountingDocument,
	loadPublicAccountingDocument: (uniqueId: string) => Promise<void>,
|}

function ShareablePublicInvoiceDocument(props: Props) {
	const { uniqueId, loadPublicAccountingDocument } = props
	const [sendDocument, setSendDocument] = useState<null | AccountingDocument>(null)
	const accountingDocumentId = props.accountingDocument && props.accountingDocument.id

	useEffect(() => {
		loadPublicAccountingDocument(uniqueId)
	}, [uniqueId, loadPublicAccountingDocument])

	if (!accountingDocumentId) return <Loader visible />

	function openEmailSendDialog() {
		setSendDocument(props.accountingDocument)
	}

	function closeEmailSendDialog() {
		setSendDocument(null)
	}

	function onSendDocument(emails: Array<string>, templateId: number) {
		props.sendAccountingDocument((sendDocument && sendDocument.id) || '', templateId, emails)
		closeEmailSendDialog()
	}

	const accType: AccountingDocumentType =
		Number_AccountingDocumentAssignedType(props.accountingDocument.type) || 'unknown'

	/* eslint-disable */
	const HeaderNo =                 <HeaderNoContainer accountingDocumentId={accountingDocumentId} readonly direction="issued" type={props.type} />
	const LineItems =               <LineItemsContainer accountingDocumentId={accountingDocumentId} readonly direction="issued" type={props.type} public />
	const LineItemsHeader =   <LineItemsHeaderContainer accountingDocumentId={accountingDocumentId} readonly />
	const ContactBuyer =              <ContactContainer accountingDocumentId={accountingDocumentId} readonly direction="issued" isSupplier={false} public />
	const ContactSupplier =           <ContactContainer accountingDocumentId={accountingDocumentId} readonly direction="issued" isSupplier={true} contact={props.accountingDocument.ownContact} public isMe />
	const PublicLogo =             <PublicLogoContainer uniqueId={props.uniqueId} />
	const BankAccounts =         <BankAccountsContainer accountingDocumentId={accountingDocumentId} readonly direction="issued" />
	const SettingsPanel =       <SettingsPanelContainer accountingDocumentId={accountingDocumentId} readonly direction="issued" />
	const HeaderOrderNo =       <HeaderOrderNoContainer accountingDocumentId={accountingDocumentId} readonly />
	const VatRecap =                 <VatRecapContainer accountingDocumentId={accountingDocumentId} readonly />
	const HeaderDates =           <HeaderDatesContainer accountingDocumentId={accountingDocumentId} readonly />
	const PaymentDetails =     <PaymentDetailsContainer accountingDocumentId={accountingDocumentId} readonly />
	const PaymentType =           <PaymentTypeContainer accountingDocumentId={accountingDocumentId} readonly />
	const PrintingNote =          <DocumentPrintingNote accountingDocumentId={accountingDocumentId} readonly />
	const Totals =                     <TotalsContainer accountingDocumentId={accountingDocumentId} readonly />
	const TotalsToPay =            <TotalToPayContainer accountingDocumentId={accountingDocumentId} />
	const Summary =          <AccountingDocumentSummary accountingDocumentId={accountingDocumentId} />
	const PaymentState =         <PaymentStateContainer accountingDocumentId={accountingDocumentId} hideEmpty/>
	const QR = accType === 'advance' || accType === 'invoice' ? <QrContainer uniqueId={uniqueId} /> : null
	/* eslint-enable */

	const StateChanger = props.userIsIssuer ? (
		<PreviewActionsContainer
			uniqueId={uniqueId}
			onSendEmail={openEmailSendDialog}
			accountingDocumentId={accountingDocumentId}
		/>
	) : (
		<DownloadButtonContainer uniqueId={uniqueId} />
	)

	function onClose() {
		if (1 < props.history.length) {
			props.history.goBack()
		} else {
			props.history.push(accountingDocumentsRoute('issued'))
		}
	}

	const direction = Number_AccountingDocumentAssignedDirection(props.accountingDocument.direction)
	const type = Number_AccountingDocumentAssignedType(props.accountingDocument.type)
	const no =
		props.accountingDocument &&
		(props.accountingDocument.accountingDocumentNo || props.accountingDocument.accountingDocumentNoPreview)

	const title =
		direction && type
			? no
				? props.t(ACCOUNTING_DOCUMENT_TITLES_NO[direction][type], { no })
				: props.t(ACCOUNTING_DOCUMENT_TITLES[direction][type])
			: ''

	return (
		<div className={styles.normal}>
			<div className={styles.container}>
				<PublicInvoice
					title={title}
					accountingDocumentId={accountingDocumentId}
					color={props.color}
					Logo={PublicLogo}
					LineItems={LineItems}
					LineItemsHeader={LineItemsHeader}
					HeaderNo={HeaderNo}
					HeaderOrderNo={HeaderOrderNo}
					ContactSupplier={ContactSupplier}
					ContactBuyer={ContactBuyer}
					BankAccounts={BankAccounts}
					HeaderDates={HeaderDates}
					StateChanger={StateChanger}
					PaymentDetails={PaymentDetails}
					PaymentType={PaymentType}
					TotalsToPay={TotalsToPay}
					VatRecap={VatRecap}
					Totals={Totals}
					Summary={Summary}
					PrintingNote={PrintingNote}
					PaymentState={PaymentState}
					QR={QR}
					onRequestClose={props.userIsIssuer ? onClose : undefined}
				/>
			</div>
			<SendEmailDialog
				open={!!sendDocument}
				onClose={closeEmailSendDialog}
				onSend={onSendDocument}
				accountingDocumentId={sendDocument && sendDocument.id}
			/>
		</div>
	)
}

export default withTranslate(withRouter(withAccountingDocumentActions(ShareablePublicInvoiceDocument)))
