/* @flow */
/** @jsx jsx */

import { jsx } from '@emotion/core'
import { Fragment, Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { userHasAccess } from 'permissions'
import { relatedAccountingDocumentNumber } from 'helpers'
import {
	withTranslate,
	type WithTranslateProps,
	withNavigationPermissions,
	type WithNavigationPermissionsProps,
} from 'wrappers'
import type {
	AccountingDocument,
	AccountingDocumentAssignedDirection,
	AccountingDocumentAssignedType,
	AccountingDocumentReduxAction,
	Dispatch,
	IssueAction,
	WithRouterProps,
	State,
	InvoiceSideButton,
} from 'types'
import {
	updateAccountingDocumentDescription,
	updateAccountingDocumentHeaderMoreFields,
	updateAccountingDocumentTotalAndTotalVatExcl,
	updateAccountingDocumentRelatedAccountingDocument,
} from '../../actions'
import { AccountingDocumentType_Number } from 'types/convertor'
import AttachAccountingDocument from '../invoice-elements-document/attach-accounting-document'
import AttachmentButton from '../../containers/invoice-elements-document/attachment-button'
import AttachmentsContainer from '../invoice-elements-document/attachments'
import Name from 'modules/accounting-document/components/invoice-elements/name'
import DocumentNote from 'modules/accounting-document/containers/invoice-elements/note'
import HeaderNoContainer from '../invoice-elements-document/header-no'
import HeaderOrderNoContainer from '../invoice-elements-document/header-order-no'
import ScansContainer from 'modules/accounting-document/containers/invoice-elements-document/scans'
import SettingsPanelContainer from '../invoice-settings/settings-panel'
import SplitView from 'modules/accounting-document/components/invoices/split-view'
import SendEmailButton from 'modules/accounting-document/components/invoice-elements/send-email-button'
import StateChangerContainer from '../invoice-elements-document/state-changer'
import EditButtonContainer from 'modules/accounting-document/containers/invoice-elements-document/edit-button'
import NeedHelpContainer from 'modules/accounting-document/containers/invoice-elements-document/need-help'
import UpperSettingsContainer from 'modules/accounting-document/containers/invoice-elements/upper-settings'
import { showAccountingDocumentRoute } from 'modules/accounting-document/routing/routes'
import SideButtons from '../../components/invoice-elements/side-buttons'
import TriviLink from 'modules/common/components/trivi-link'
import CurrencyInput from 'components/currency-input'
import Direction from 'components/direction'
import Paper from 'components/Paper'
import PopupSection from 'components/PopupSection'
import Separator from 'components/Separator'
import TextField from 'components/TextField'
import DatePicker from 'components/date-picker'
import ActivitiesDialog from '../invoice-elements/activities-dialog'
import ExtractionNoteContainer from '../invoice-elements/extraction-note'
import DeleteIcon from 'components/svg-icons/action/delete'
import IconButton from 'components/icon-button'
import { EMPTY_OBJECT } from 'trivi-constants'
import { isAccountingDocumentExtractable } from '../../domain/accounting-document'
import styles from './accounting-documents.css'
import { formatDateToIsoString } from 'utils/formatters/date-formatter'

const ALLOWED_TYPES = [
	AccountingDocumentType_Number('invoice'),
	AccountingDocumentType_Number('advance'),
	AccountingDocumentType_Number('tax_advance'),
	AccountingDocumentType_Number('credit_note'),
]

type StateProps = {|
	accountingDocument: ?AccountingDocument,
	showDocuments: boolean,
|}

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => {
	const doc = state.accountingDocument.accountingDocuments.items[ownProps.accountingDocumentId]
	return {
		accountingDocument: doc && doc.data,
		showDocuments: userHasAccess(state, 'seeDocuments'),
	}
}

type DispatchProps = {|
	onIssueDateChange: (issueDate: Date) => void,
	onTotalChange: (newTotal: ?number) => void,
	onDescriptionChange: (newDescription: string) => void,
	onDisconnectDocument: () => void,
|}

const mapDispatchToProps = (
	dispatch: Dispatch<AccountingDocumentReduxAction | IssueAction>,
	ownProps: OwnProps,
): DispatchProps => {
	return {
		onIssueDateChange: (issueDate: Date): void => {
			const date = formatDateToIsoString(new Date(issueDate))
			const fields = {
				issueDate: date,
				taxDate: date,
				bookingDate: date,
			}
			dispatch(updateAccountingDocumentHeaderMoreFields(ownProps.accountingDocumentId, fields))
		},
		onTotalChange: (newTotal: ?number): void => {
			dispatch(updateAccountingDocumentTotalAndTotalVatExcl(ownProps.accountingDocumentId, newTotal))
		},
		onDescriptionChange: (newDescription: string): void => {
			dispatch(updateAccountingDocumentDescription(ownProps.accountingDocumentId, newDescription))
		},
		onDisconnectDocument: (): void => {
			dispatch(updateAccountingDocumentRelatedAccountingDocument(ownProps.accountingDocumentId, null))
		},
	}
}

type OwnProps = {
	readonly: boolean,
	storing: boolean,
	showAccountingDocumentConnection?: boolean,
	accountingDocumentId: string,
	title: string,
	scansCount: number,
	view: 'default' | 'scans',
	direction: AccountingDocumentAssignedDirection,
	type: AccountingDocumentAssignedType,
	accountingDocumentState: string,
	MoreActions: any,
	sideButtons?: Array<InvoiceSideButton>,
	showSendEmailButton: boolean,
	showEditButton: boolean,
	showStateChanger: boolean,
	isCashRegister: boolean,
	onClose: () => void,
	onViewChange: (view: 'default' | 'scans') => void,
}

type Props = {
	...StateProps,
	...DispatchProps,
	...OwnProps,
	...WithTranslateProps,
	...WithRouterProps,
	...WithNavigationPermissionsProps,
}

type EditSimpleState = {|
	oldTotal: ?number,
	oldDescription: string,
	total: ?number,
	description: string,
	isAttachmensOpen: boolean,
	isNoteOpen: boolean,
|}

class EditSimpleDocument extends Component<Props, EditSimpleState> {
	static defaultProps = {
		showAccountingDocumentConnection: true,
	}

	state: EditSimpleState = {
		oldTotal: (this.props.accountingDocument && this.props.accountingDocument.total) || 0,
		oldDescription: (this.props.accountingDocument && this.props.accountingDocument.description) || '',
		total: (this.props.accountingDocument && this.props.accountingDocument.total) || 0,
		description: (this.props.accountingDocument && this.props.accountingDocument.description) || '',
		isAttachmensOpen: false,
		isNoteOpen: false,
	}

	UNSAFE_componentWillReceiveProps(newProps: Props) {
		let { total, description } = this.state

		const doc: ?AccountingDocument = newProps.accountingDocument
		const oldDoc: ?AccountingDocument = this.props.accountingDocument

		if (doc && this.props.accountingDocument !== newProps.accountingDocument) {
			if (!oldDoc || doc.total !== oldDoc.total) {
				total = doc.total
			}
			if (!oldDoc || doc.description !== oldDoc.description) {
				description = doc.description
			}
			this.setState({ total, description })
		}
	}

	onDescriptionChange = (event: SyntheticInputEvent<HTMLInputElement>, description: ?string) => {
		this.setState({ description: description || '' })
	}

	onDescriptionBlur = () => {
		if (this.state.description !== this.state.oldDescription) {
			this.props.onDescriptionChange(this.state.description)
			this.setState({ oldDescription: this.state.description })
		}
	}

	onDateChange = (event: ?SyntheticInputEvent<HTMLInputElement>, issueDate: ?Date) => {
		issueDate && this.props.onIssueDateChange(new Date(issueDate))
	}

	onTotalChange = (event: SyntheticInputEvent<HTMLInputElement>, total: ?number) => {
		this.setState({ total })
	}

	onTotalBlur = () => {
		if (this.state.total !== this.state.oldTotal) {
			this.props.onTotalChange(this.state.total)
			this.setState({ oldTotal: this.state.total })
		}
	}

	openAttachment = () => {
		this.setState({ isAttachmensOpen: true })
	}

	closeAttachment = () => {
		this.setState({ isAttachmensOpen: false })
	}

	onClose = () => {
		if (this.state.isAttachmensOpen) {
			return this.closeAttachment()
		}
		this.props.onClose()
	}

	onRelatedClick = () => {
		this.props.history.push(showAccountingDocumentRoute(this.props.accountingDocument.relatedAccountingDocumentId), {
			link: this.props.t('navigation.accountingDocument.backToDocument'),
			goBack: true,
		})
	}

	renderRelatedAccountingDocumentLink() {
		if (this.props.accountingDocument) {
			const id = this.props.accountingDocument.relatedAccountingDocumentId
			const readOnlyLink = !this.props.canSeeAccountingDocuments

			if (!id) {
				return null
			}

			return (
				<Paper rounded zDepth={3} className={styles.connected}>
					<div className={styles.connectedContainer}>
						{this.props.t('invoice.connectedDocument')}&nbsp;
						<TriviLink
							onClick={this.onRelatedClick}
							disabled={readOnlyLink}
							hoverStyle={readOnlyLink ? style.relatedAccDocLink : EMPTY_OBJECT}
						>
							{relatedAccountingDocumentNumber(this.props.accountingDocument)}
						</TriviLink>
						{!this.props.readonly && (
							<div css={style.deleteIcon}>
								<IconButton
									autoTestId="invoice-simple-disconnect"
									onClick={this.props.onDisconnectDocument}
									tooltip={this.props.t('invoice.disconnectDocument')}
								>
									<DeleteIcon size={20} />
								</IconButton>
							</div>
						)}
					</div>
				</Paper>
			)
		}
	}

	renderDescription() {
		const READONLY = this.props.readonly
		const { t } = this.props

		return (
			(!READONLY || this.state.description) && (
				<div className={styles.row}>
					<div className={styles.name}>
						<TextField
							editCursor
							autoFocus
							autoWidth
							autoHeight
							multiLine
							hintText={t('invoice.descriptionHint')}
							value={this.state.description || ''}
							onBlur={this.onDescriptionBlur}
							onChange={this.onDescriptionChange}
							disabled={READONLY}
							inline
							name="description"
							autoTestId="simple-document-description"
						/>
					</div>
				</div>
			)
		)
	}

	renderHeader() {
		const DOCUMENT_ID = this.props.accountingDocumentId
		const READONLY = this.props.readonly
		const DIRECTION = this.props.direction
		const TYPE = this.props.type

		return (
			<div>
				<div className={styles.nos}>
					<div className={styles.no}>
						<HeaderOrderNoContainer accountingDocumentId={DOCUMENT_ID} readonly={READONLY} />
					</div>
					<div className={styles.no}>
						<HeaderNoContainer
							accountingDocumentId={DOCUMENT_ID}
							readonly={READONLY}
							direction={DIRECTION}
							type={TYPE}
						/>
					</div>
				</div>
				<div className={styles.hr} />
				<div className={styles.main}>{this.renderDescription()}</div>
			</div>
		)
	}

	renderContent() {
		const DIRECTION = this.props.direction
		const TYPE = this.props.type
		const READONLY = this.props.readonly
		const { t } = this.props

		if (this.state.isAttachmensOpen) {
			return this.renderAttachments()
		}

		return (
			<Paper rounded zDepth={3} className={styles.box}>
				{this.renderHeader()}
				<div className={styles.others}>
					<div className={styles.section}>
						<div className={styles.row}>
							<div className={styles.column}>
								<CurrencyInput
									value={this.state.total}
									onChange={this.onTotalChange}
									onBlur={this.onTotalBlur}
									hintText={t('invoice.totals.totalWithVat')}
									labelText={t('invoice.totals.total')}
									fullWidth
									disabled={READONLY}
									name="totalWithVat"
								/>
							</div>
							<div className={styles.column}>
								<DatePicker
									onChange={this.onDateChange}
									value={
										this.props.accountingDocument && this.props.accountingDocument.issueDate
											? new Date(this.props.accountingDocument.issueDate)
											: null
									}
									labelText={t('invoice.issuedDateHint')}
									autoTestId="simple-document-issue-date"
									disabled={READONLY}
									fullWidth
								/>
							</div>
						</div>
					</div>
					{((this.props.accountingDocument && this.props.accountingDocument.relatedAccountingDocumentId) ||
						!READONLY) &&
						this.props.showAccountingDocumentConnection && (
							<div>
								<Separator className={styles.separator} left={<span className={styles.separatorText}>Doklad</span>} />
								<div className={styles.section}>
									{this.renderRelatedAccountingDocumentLink()}
									{!READONLY && (
										<AttachAccountingDocument
											light
											asRelatedDocument
											accountingDocumentId={this.props.accountingDocumentId}
											type={TYPE}
											direction={DIRECTION}
											allowedTypes={ALLOWED_TYPES}
										/>
									)}
								</div>
							</div>
						)}

					<div className={styles.attachments}>
						<AttachmentButton
							onClick={this.openAttachment}
							accountingDocumentId={this.props.accountingDocumentId}
							hideIfOnlyAccountingDocuments={this.props.readonly}
							hideIfNoAttachment={this.props.readonly}
						/>
					</div>
				</div>
				<SideButtons sideButtons={this.props.sideButtons} />
			</Paper>
		)
	}

	renderAttachments() {
		const TYPE = this.props.type
		const DOCUMENT_ID = this.props.accountingDocumentId
		return (
			<PopupSection open onRequestClose={this.closeAttachment}>
				<AttachmentsContainer
					accountingDocumentId={DOCUMENT_ID}
					type={TYPE}
					showAccountingDocuments={false}
					showDocuments={this.props.showDocuments}
					showUpload={false}
					onRequestClose={this.closeAttachment}
					readonly={this.props.readonly}
				/>
			</PopupSection>
		)
	}

	onStateChange = async (state: string) => {
		if (
			state == 'Processed' &&
			this.props.accountingDocument.relatedAccountingDocumentId &&
			(!this.state.description || this.state.description === '')
		) {
			await this.props.onDescriptionChange(
				this.props.t('invoice.defaultDescription', { no: this.props.accountingDocument.relatedAccountingDocumentId }),
			)
		}
	}

	toggleShowNote = () => {
		this.setState({ isNoteOpen: !this.state.isNoteOpen })
	}

	render() {
		const DIRECTION = this.props.direction
		const DOCUMENT_ID = this.props.accountingDocumentId
		const READONLY = this.props.readonly
		const cashRegisterId: ?string = this.props.accountingDocument && this.props.accountingDocument.cashRegisterId

		if (this.state.isAttachmensOpen) {
			return this.renderAttachments()
		}

		const Scans = <ScansContainer accountingDocumentId={DOCUMENT_ID} />
		const Note = <DocumentNote accountingDocumentId={DOCUMENT_ID} readonly={READONLY} isOpen={this.state.isNoteOpen} />
		const UpperSettings = (
			<UpperSettingsContainer
				accountingDocumentId={DOCUMENT_ID}
				readonly={READONLY}
				direction={DIRECTION}
				hideBookingDate
				hideVatCountry
				hideExchRate
				hideTaxReturnDate
				hideLanguage
				view={this.props.view}
				toggleShowNote={this.toggleShowNote}
			/>
		)

		const StateChanger = (
			<Fragment>
				{this.props.showSendEmailButton && <SendEmailButton accountingDocumentId={DOCUMENT_ID} />}
				{this.props.showEditButton ? (
					<EditButtonContainer accountingDocumentId={DOCUMENT_ID} />
				) : (
					<Fragment>
						{this.props.showStateChanger && (
							<StateChangerContainer
								onStateChange={this.onStateChange}
								accountingDocumentId={DOCUMENT_ID}
								cashRegisterId={this.props.accountingDocument && this.props.accountingDocument.cashRegisterId}
								direction={DIRECTION}
								readonly={READONLY}
							/>
						)}
					</Fragment>
				)}
			</Fragment>
		)

		const SettingsPanel = (
			<SettingsPanelContainer
				cashRegisterId={cashRegisterId}
				accountingDocumentId={DOCUMENT_ID}
				readonly={READONLY}
				direction={DIRECTION}
				showBookingSettings={false}
				showDuePeriodSettings
			/>
		)

		const NeedHelp =
			this.props.internal &&
			!this.props.readonly &&
			this.props.accountingDocument &&
			isAccountingDocumentExtractable(this.props.accountingDocument.state) ? (
				<NeedHelpContainer accountingDocumentId={DOCUMENT_ID} />
			) : null

		const NameComponent = <Name title={this.props.title} />
		const ExtractionNote = <ExtractionNoteContainer accountingDocumentId={DOCUMENT_ID} />
		return (
			<SplitView
				storing={this.props.storing}
				readonly={this.props.readonly}
				onViewChange={this.props.onViewChange}
				onCloseCallback={this.onClose}
				view={this.props.view}
				enabledViews={{
					default: true,
					scans: true, //this.props.scansCount > 0,
				}}
				scansCount={this.props.scansCount}
				MoreActions={this.props.MoreActions}
				StateChanger={StateChanger}
				Scans={Scans}
				Note={Note}
				Name={NameComponent}
				SettingsPanel={SettingsPanel}
				NeedHelp={NeedHelp}
				ExtractionNote={ExtractionNote}
				UpperSettings={UpperSettings}
				Direction={
					<Direction
						showLabel
						issued={this.props.direction === 'issued'}
						received={this.props.direction === 'received'}
						isCashRegister={this.props.isCashRegister}
					/>
				}
			>
				{this.renderContent()}
				{this.props.internal && <ActivitiesDialog accountingDocumentId={DOCUMENT_ID} />}
			</SplitView>
		)
	}
}

const style = {
	relatedAccDocLink: {
		textDecoration: 'none',
		color: 'black',
		cursor: 'auto',
	},
	deleteIcon: {
		display: 'flex',
		marginRight: -10,
	},
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(withTranslate(withNavigationPermissions(withRouter(EditSimpleDocument))))
