/* @flow*/

import type {
	State,
	Country,
	NotificationType,
	CrpdphSearchResult,
	Currency,
	FormFieldErrorContext,
	FormFieldErrorMessage,
	FormFieldErrorsMap,
	MultiFormFieldMessages,
} from 'types'
import type { HotKeyModifier, AccountingDocument_IssuedPaymentOrders } from '../types'
import { createSelector, createSelectorCreator, defaultMemoize } from 'reselect'
import { getFormFieldErrorUniquePath, toMultiFormFieldErrors } from 'helpers'
import { getAccountingDocumentErrors, getTemplateErrors } from 'modules/accounting-document/selectors'
import { EMPTY_ARRAY } from 'trivi-constants'
import { isEqual } from 'lodash-es'

export const isCountryEu = (state: State, country: ?string): boolean => {
	if (!country) {
		return false
	}
	const countries: Array<Country> = (state.common.countries && state.common.countries.countries) || []

	return !!countries.find((c: Country) => country === c.id && c.isEu === true)
}

export function getNotification(state: State): ?NotificationType {
	return state.common && state.common.notification
}

export function isModifierHotKeyPressed(state: State, key: HotKeyModifier): boolean {
	return state.common.hotkeys.modifierPressed[key]
}

export const ordersOfAllSelectedDocumentsSelector = createSelector(
	_selectedDocumentIdsSelector,
	getAllPaymentOrdersSelector,
	(
		selectedIds: Array<string>,
		allLoadedOrders: AccountingDocument_IssuedPaymentOrders,
	): AccountingDocument_IssuedPaymentOrders => {
		return selectedIds.reduce((result: AccountingDocument_IssuedPaymentOrders, item: string) => {
			if (allLoadedOrders[item]) {
				result[item] = allLoadedOrders[item]
			}
			return result
		}, {})
	},
)

export function getAllPaymentOrdersSelector(state: State): AccountingDocument_IssuedPaymentOrders {
	return state.common.loadedPaymentOrdersForDocuments
}

export function userIsNotLoggedAsDemo(demoCredentials: { email: string, password: string }, state: State) {
	const emailOfCurrentUser = state.user && state.user.me.data && state.user.me.data.email
	return emailOfCurrentUser === demoCredentials.email
}

function _selectedDocumentIdsSelector(state: State): Array<string> {
	return state.common.selectedDocumentsIdsInPaymentDialog
}

export function getCrdphResult(state: State, companyRegId: string): ?CrpdphSearchResult {
	return state.common.countryVariant.cz.crpdphResult[companyRegId]
}

export function getCountryName(state: State, countryCode: ?string): ?string {
	const found = (state.common.countries.countries || []).find((country: Country) => country.id === countryCode)
	return found && found.name
}

export const getAllCountries = (state: State) => state.common.countries.countries

export const getSortedCountries = createSelector([getAllCountries], (countries: ?Array<Country>) => {
	return (
		countries &&
		countries.sort((country1: Country, country2: Country) => {
			const name1 = country1.name || ''
			const name2 = country2.name || ''
			return name1.localeCompare(name2)
		})
	)
})

export function getAllCurrencies(state: State): ?Array<Currency> {
	return state.common.currencies.data
}

const getErrors = (state: State, formFieldErrorContext: FormFieldErrorContext): ?FormFieldErrorsMap => {
	const { resource } = formFieldErrorContext
	if (!resource) return undefined
	const { id, name } = resource
	let result = undefined
	if (name === 'AccountingDocument' && id != null) {
		result = getAccountingDocumentErrors(state, id)
	}
	if (name === 'Template' && id != null) {
		result = getTemplateErrors(state, id)
	}

	if (name === 'TemplateScheduler' && id != null) {
		result = getTemplateErrors(state, id)
	}
	return result
}

export const getFormFieldErrors = (
	state: State,
	formFieldErrorContext: FormFieldErrorContext,
): ?Array<FormFieldErrorMessage> => {
	const { resource, path } = formFieldErrorContext
	const uniqueId = getFormFieldErrorUniquePath(resource, path)
	const errors = getErrors(state, formFieldErrorContext)
	const result = (errors && errors[uniqueId] && errors[uniqueId].messages) || EMPTY_ARRAY
	return result
}

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual)

const getMultiUniqueId = (state: State, formFieldErrorContext: FormFieldErrorContext): string => {
	const { resource, path } = formFieldErrorContext
	const uniqueId = getFormFieldErrorUniquePath(resource, path, true)
	return uniqueId
}

export const makeGetMultiFormFieldErrors = () => {
	return createDeepEqualSelector(
		[getErrors, getMultiUniqueId],
		(errors: ?FormFieldErrorsMap, uniqueId: string): ?MultiFormFieldMessages =>
			errors && toMultiFormFieldErrors(errors, uniqueId),
	)
}
