/* @flow */
/** @jsx jsx */

import moment from 'moment'
import { Component, Fragment } from 'react'
import { jsx } from '@emotion/core'
import type { AccountingDocument } from 'types'
import type { CashbotInvoiceStatus, CashbotInvoice, CashbotOffer, CashbotInvoiceType } from 'modules/cashbot/types'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import { formatCashbotDate } from '../../../domain'
import CashbotPanelPure from './cashbot-panel-pure'
import CashbotRegisterDialog from 'modules/cashbot/components/cashbot-dialog/cashbot-register-dialog'
import CashbotIssuedDialog from 'modules/cashbot/components/cashbot-dialog/cashbot-issued-dialog'
import CashbotReceivedDialog from 'modules/cashbot/components/cashbot-dialog/cashbot-received-dialog'
import TriviLink from 'modules/common/components/trivi-link'
import DueText from 'components/due-text'
import { colors } from 'variables'

type CashbotDialogType = 'registerCompany' | CashbotInvoiceType

export type OwnProps = {|
	accDocId?: string,
	amount?: string,
	direction?: CashbotInvoiceType,
	onlyAuthentication?: boolean,
	currentOrganizationName?: string,
	accountingDocument?: ?AccountingDocument,
	// State of dialog is decided by following properties:
	isCashbotAuthenticated: boolean,
	cashbotInvoiceId: ?number,
	isCashbotInvoiceLoading: boolean,
	cashbotInvoice: ?CashbotInvoice,
	cashbotOffers: ?Array<CashbotOffer>,
	isCashbotOffersLoading: boolean,
	isMarkedToBeFinanced: boolean,
	//
	authenticateCashbot?: () => Promise<void>,
	loadCashbotInvoice?: (accDocId: string) => Promise<void>,
	financeCashbotInvoice?: (
		accDocId: string,
		requestedFinancingDueDate?: string,
	) => Promise<'companyNotRegistered' | 'offersDownloaded' | null>,
	downloadCashbotOffers?: (accDocId: string) => Promise<void>,
	confirmOffer?: (accDocId: string, offer: CashbotOffer) => Promise<void>,
|}

type Props = {|
	...WithTranslateProps,
	...OwnProps,
|}

type State = {|
	isAuthenticating: boolean,
	isFinancing: boolean,
	isLoading: boolean,
	openDialog: ?CashbotDialogType,
|}

class CashbotPanel extends Component<Props, State> {
	state = {
		isAuthenticating: false,
		isFinancing: false,
		isLoading: false,
		openDialog: null,
	}

	componentDidMount() {
		if (this.props.cashbotInvoiceId && this.props.isCashbotAuthenticated && this.props.accDocId) {
			this.props.loadCashbotInvoice && this.props.loadCashbotInvoice(this.props.accDocId)
		}
		if (this.props.isMarkedToBeFinanced) {
			this.onPayInvoiceClick()
		}
	}

	onConnectClick = () => {
		this.setState({ isAuthenticating: true })
		this.props.authenticateCashbot && this.props.authenticateCashbot()
	}

	onLoadInvoiceClick = () => {
		this.props.accDocId && this.props.loadCashbotInvoice && this.props.loadCashbotInvoice(this.props.accDocId)
	}

	onPayInvoiceClick = async () => {
		if ('received' === this.props.direction) {
			this.openDialog('received')
			return
		}

		this.setState({ isFinancing: true })

		if (this.props.financeCashbotInvoice && this.props.accDocId) {
			const result = await this.props.financeCashbotInvoice(this.props.accDocId)

			switch (result) {
				case 'companyNotRegistered':
					this.openDialog('registerCompany')
					break
				case 'offersDownloaded':
					this.props.cashbotInvoice && this.openDialog(this.props.cashbotInvoice.type)
					break
				default:
					break
			}
		}

		this.setState({ isFinancing: false })
	}

	onGetReceivedOffer = async (date: Date) => {
		const dateString = formatCashbotDate(date)

		this.setState({ isFinancing: true })

		if (this.props.financeCashbotInvoice && this.props.accDocId) {
			await this.props.financeCashbotInvoice(this.props.accDocId, dateString)
		}

		this.setState({ isFinancing: false })
	}

	onGetOffersClick = () => {
		this.setState({ isLoading: true })
		this.props.accDocId &&
			this.props.downloadCashbotOffers &&
			this.props.downloadCashbotOffers(this.props.accDocId).then(() => {
				this.setState({ isLoading: false })
				this.props.cashbotInvoice && this.openDialog(this.props.cashbotInvoice.type)
			})
	}

	onShowOffersClick = () => {
		this.props.cashbotInvoice && this.openDialog(this.props.cashbotInvoice.type)
	}

	onOfferClick = (offer: CashbotOffer) => {
		this.setState({ isLoading: true })
		this.closeDialog()
		this.props.accDocId &&
			this.props.confirmOffer &&
			this.props.confirmOffer(this.props.accDocId, offer).then(() => {
				this.setState({ isLoading: false })
			})
	}

	openDialog = (dialogType: ?CashbotDialogType) => {
		this.setState({ openDialog: dialogType })
	}

	closeDialog = () => {
		this.setState({ openDialog: null })
	}

	renderPanel = () => {
		if (!this.props.isCashbotAuthenticated) {
			return (
				<CashbotPanelPure
					text={this.props.t('cashbot.panel.textConnect')}
					showLoader={this.state.isAuthenticating}
					button={{
						text: this.props.t('cashbot.panel.buttonConnect'),
						onClick: this.onConnectClick,
						disabled: this.state.isAuthenticating,
					}}
				/>
			)
		}

		if (this.props.onlyAuthentication) {
			return null
		}

		if (!this.props.cashbotInvoiceId) {
			return (
				<CashbotPanelPure
					text={this.props.t('cashbot.panel.textPay')}
					showLoader={this.state.isFinancing}
					button={{
						text: this.props.t('cashbot.panel.buttonPay'),
						onClick: this.onPayInvoiceClick,
						disabled: this.state.isFinancing,
					}}
				/>
			)
		}

		// if (this.props.isCashbotInvoiceLoading) {
		// 	return <CashbotPanelPure showLoader text={this.props.t('cashbot.panel.textCashbotLoading')} />
		// }

		if (!this.props.cashbotInvoice) {
			const text = this.props.isCashbotInvoiceLoading
				? this.props.t('cashbot.panel.textCashbotLoading')
				: this.props.t('cashbot.panel.textCashbotInvoiceNotLoaded')

			return (
				<CashbotPanelPure
					text={text}
					showLoader={this.props.isCashbotInvoiceLoading}
					button={{
						text: this.props.t('cashbot.panel.buttonLoadInvoiceFromCashbot'),
						onClick: this.onLoadInvoiceClick,
						disabled: this.props.isCashbotInvoiceLoading,
					}}
				/>
			)
		}

		const status: CashbotInvoiceStatus = this.props.cashbotInvoice.status

		if (status === 'pending') {
			if (!this.props.cashbotOffers) {
				return (
					<CashbotPanelPure
						text={this.props.t('cashbot.panel.statusText.pending')}
						showLoader={this.props.isCashbotOffersLoading}
						button={{
							text: this.props.t('cashbot.panel.buttonGetOffers'),
							onClick: this.onGetOffersClick,
							disabled: this.props.isCashbotOffersLoading,
						}}
					/>
				)
			} else {
				return (
					<CashbotPanelPure
						text={this.props.t('cashbot.panel.statusText.pending')}
						showLoader={this.state.isLoading}
						button={{
							text: this.props.t('cashbot.panel.buttonShowOffers'),
							disabled: this.state.isLoading,
							onClick: this.onShowOffersClick,
						}}
					/>
				)
			}
		}

		if (['approving', 'approved', 'repaid'].includes(status)) {
			return <CashbotPanelPure text={this.props.t(`cashbot.panel.statusText.${status}`)} />
		}

		if (status === 'financed') {
			const invoice = this.props.cashbotInvoice
			const financedMoment = moment(invoice && invoice.paidDate)
			const dueMoment = moment(invoice && invoice.dueDate)
			const text = (
				<div css={style.text}>
					{this.props.t('cashbot.panel.statusText.financed', {
						financed: financedMoment.format('l'),
						due: dueMoment.format('l'),
					})}
					{' - '}
					<DueText daysToGo={dueMoment.add(1, 'days').diff(moment(), 'days')} />
				</div>
			)

			return <CashbotPanelPure text={text} />
		}

		if (status === 'rejected') {
			const text = (
				<span>
					{this.props.t('cashbot.panel.rejected.result')}{' '}
					<span css={style.red}>{this.props.t('cashbot.panel.rejected.status')}</span>
					{this.props.t('cashbot.panel.rejected.contact')}{' '}
					<TriviLink style={style.link} href={'mailto:info@cashbot.cz'}>
						{this.props.t('cashbot.panel.rejected.link')}
					</TriviLink>{' '}
					{this.props.t('cashbot.panel.rejected.cashbot')}
				</span>
			)
			return <CashbotPanelPure text={text} />
		}

		return null
	}

	render() {
		return (
			<Fragment>
				{this.renderPanel()}
				<CashbotRegisterDialog
					open={'registerCompany' === this.state.openDialog}
					onClose={this.closeDialog}
					registerCompanyName={this.props.currentOrganizationName}
				/>
				<CashbotIssuedDialog
					open={'issued' === this.state.openDialog}
					onClose={this.closeDialog}
					offers={this.props.cashbotOffers}
					invoice={this.props.cashbotInvoice}
					onOfferClick={this.onOfferClick}
				/>
				<CashbotReceivedDialog
					open={'received' === this.state.openDialog}
					onClose={this.closeDialog}
					offer={this.props.cashbotOffers && this.props.cashbotOffers[0]}
					invoice={this.props.cashbotInvoice}
					onConfirm={this.onOfferClick}
					onGetOffer={this.onGetReceivedOffer}
					loading={this.props.isCashbotOffersLoading || this.state.isFinancing}
					accountingDocument={this.props.accountingDocument}
				/>
			</Fragment>
		)
	}
}

const style = {
	text: {
		flex: '1 1 100%',
		paddingTop: 10,
		paddingBottom: 10,
	},
	link: {
		textDecoration: 'underline',
		display: 'inline',
	},
	red: {
		color: colors.red,
		fontWeight: 'bold',
	},
}

export default withTranslate(CashbotPanel)
