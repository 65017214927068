// @flow

import type { ContactAddressType, ContactAddress } from 'types'

export function getContactAddressByType(items: ?Array<ContactAddress>, type: ContactAddressType): ?ContactAddress {
	return (items || []).find((address: ContactAddress) => address.type == type)
}

export function isContactAddressEmpty(address: ContactAddress): boolean {
	return !(
		(address.street && address.street.length) ||
		(address.city && address.city.length) ||
		(address.zipCode && address.zipCode.length) ||
		(address.country && address.country.length) ||
		(address.email && address.email.length) ||
		(address.phone && address.phone.length)
	)
}
