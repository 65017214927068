// @flow

import type { DashboardAction, OrganizationReport } from 'types'

export type State = {
	organizationReport: ?OrganizationReport,
}

export const initialState: State = {
	organizationReport: null,
}

export default (state: State = initialState, action: DashboardAction): State => {
	switch (action.type) {
		case 'FINISH_LOAD_ORGANIZATION_REPORT':
			return action.serverError ? state : finishLoadOrganizationReportReducer(state, action.data)
		default:
			return state
	}
}

function finishLoadOrganizationReportReducer(state: State, data: ?OrganizationReport): State {
	return {
		...state,
		organizationReport: data,
	}
}
