/* @flow */
import React from 'react'
import ContactIcon from 'components/svg-icons/trivi/add-menu/contact'
import InvoiceIcon from 'components/svg-icons/trivi/add-menu/invoice'
import TasksIcon from 'components/svg-icons/trivi/add-menu/task'
import UploadIcon from 'components/svg-icons/trivi/add-menu/upload'
import type { I18NextTranslateFn } from 'types'
import { colors } from 'variables'

export type NavigationItem = {
	id: string,
	direction?: 'received' | 'issued',
	params?: { [string]: any },
	label: string,
	icon?: React$Element<any>,
	tall?: boolean,
}

export type Section = {
	label: string,
	items: Array<NavigationItem>,
}

type SectionRules = {|
	showIssued: boolean,
	showReceived: boolean,
	showUpload: boolean,
	showCreateContact: boolean,
	// showCreateTask: boolean,
|}

function getIssuedSection(t: I18NextTranslateFn, sectionRules: SectionRules): ?Section {
	if (!sectionRules.showIssued) {
		return null
	}

	return {
		label: t('accountingDocument.sale'),
		items: [
			{
				id: 'Invoice',
				params: { direction: 'issued' },
				label: t('navigation.accountingDocument.newInvoice'),
				icon: <InvoiceIcon color={colors.grey} disabled />,
			},
			{
				id: 'TaxAdvance',
				params: { direction: 'issued' },
				label: t('navigation.accountingDocument.newTaxAdvance'),
			},
			{
				id: 'Advance',
				params: { direction: 'issued' },
				label: t('navigation.accountingDocument.newAdvance'),
			},
			{
				id: 'CreditNote',
				params: { direction: 'issued' },
				label: t('navigation.accountingDocument.newCreditNote'),
			},
		],
	}
}

function getReceivedSection(t: I18NextTranslateFn, sectionRules: SectionRules): ?Section {
	const receivedItems = []

	if (sectionRules.showReceived) {
		receivedItems.push(
			{
				id: 'Invoice',
				params: { direction: 'received' },
				label: t('navigation.accountingDocument.newInvoice'),
			},
			{
				id: 'TaxAdvance',
				params: { direction: 'received' },
				label: t('navigation.accountingDocument.newTaxAdvance'),
			},
			{
				id: 'Advance',
				params: { direction: 'received' },
				label: t('navigation.accountingDocument.newAdvance'),
			},
			{
				id: 'CreditNote',
				params: { direction: 'received' },
				label: t('navigation.accountingDocument.newCreditNote'),
			},
		)
	}

	if (!receivedItems.length) {
		return null
	}

	return {
		label: t('accountingDocument.purchase'),
		items: receivedItems,
	}
}

function getOtherSection(t: I18NextTranslateFn, sectionRules: SectionRules): ?Section {
	const otherItems = []

	if (sectionRules.showUpload) {
		otherItems.push({
			id: 'uploadScan',
			label: t('navigation.accountingDocument.upload'),
			icon: <UploadIcon color={colors.grey} disabled />,
			tall: true,
		})
	}

	if (sectionRules.showCreateContact) {
		otherItems.push({
			id: 'createContact',
			label: t('navigation.other.newContact'),
			icon: <ContactIcon color={colors.grey} disabled />,
			tall: true,
		})
	}

	// if (sectionRules.showCreateTask) {
	// 	otherItems.push({
	// 		id: 'createTask',
	// 		label: t('navigation.other.newTask'),
	// 		icon: <TasksIcon color={colors.grey} disabled />,
	// 	})
	// }

	if (!otherItems.length) {
		return null
	}

	return {
		label: t('navigation.other.label'),
		items: otherItems,
	}
}

export function getSections(t: I18NextTranslateFn, sectionRules: SectionRules): Array<Section> {
	const sections: Array<Section> = []

	const issuedSection = getIssuedSection(t, sectionRules)
	const receivedSection = getReceivedSection(t, sectionRules)
	const otherSection = getOtherSection(t, sectionRules)

	issuedSection && sections.push(issuedSection)
	receivedSection && sections.push(receivedSection)
	otherSection && sections.push(otherSection)

	return sections
}
