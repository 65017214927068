//@flow
import React from 'react'
import { withTranslate, type WithTranslateProps } from 'wrappers'

const Styles = {
	th: {
		border: '2px solid black',
		minWidth: '60px',
		padding: '5px',
	},
	empty: {
		align: 'right',
	},
}

const TableHeader = (props: WithTranslateProps) => {
	const { t } = props
	return (
		<thead>
			<tr>
				<th style={Styles.th} rowSpan="3">
					{t('internal.billings.tableHeader.company')}
				</th>
				<th style={Styles.th} colSpan="9">
					{t('internal.billings.tableHeader.accountingDocuments')}
				</th>
				<th style={Styles.th} colSpan="4">
					{t('internal.billings.tableHeader.statements')}
				</th>
				<th style={Styles.th} colSpan="3 ">
					{t('internal.billings.fullAmount')}
				</th>
			</tr>
			<tr>
				<th style={Styles.th} colSpan="3" />
				<th style={Styles.th} colSpan="3">
					{t('internal.billings.tableHeader.received')}
				</th>
				<th style={Styles.th} colSpan="3">
					{t('internal.billings.tableHeader.issued')}
				</th>
				<th style={Styles.th} colSpan="2">
					{t('internal.billings.tableHeader.banking')}
				</th>
				<th style={Styles.th} colSpan="2">
					{t('internal.billings.tableHeader.nonBanking')}
				</th>
				<th style={Styles.th} colSpan="3" />
			</tr>
			<tr>
				<th style={Styles.th} colSpan="3" />
				<th style={Styles.th}>{t('internal.billings.tableHeader.all')}</th>
				<th style={Styles.th}>{t('internal.billings.tableHeader.changed')}</th>
				<th style={Styles.th}>{t('internal.billings.tableHeader.sanctioned')}</th>
				<th style={Styles.th}>{t('internal.billings.tableHeader.all')}</th>
				<th style={Styles.th}>{t('internal.billings.tableHeader.changed')}</th>
				<th style={Styles.th}>{t('internal.billings.tableHeader.sanctioned')}</th>
				<th style={Styles.th}>{t('internal.billings.tableHeader.all')}</th>
				<th style={Styles.th}>{t('internal.billings.tableHeader.changed')}</th>
				<th style={Styles.th}>{t('internal.billings.tableHeader.all')}</th>
				<th style={Styles.th}>{t('internal.billings.tableHeader.changed')}</th>
				<th style={Styles.th}>{t('internal.billings.tableHeader.accountingDocuments')}</th>
				<th style={Styles.th}>{t('internal.billings.tableHeader.statements')}</th>
				<th style={Styles.th}>{t('internal.billings.amount')}</th>
			</tr>
		</thead>
	)
}

export default withTranslate(TableHeader)
