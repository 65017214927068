/* @flow */

import React, { Children, cloneElement, isValidElement, PureComponent } from 'react'

type Props = {|
	children?: ?React$Element<any> | Array<?React$Element<any>>,
	nestedLevel: number,
	open?: boolean,
|}

class DocumentTreeNestedList extends PureComponent<Props> {
	render() {
		return (
			<div style={{ padding: 0, display: this.props.open ? 'block' : 'none' }}>
				{Children.map(this.props.children, (child: ?React$Element<any>) => {
					return child && isValidElement(child)
						? cloneElement(child, {
								nestedLevel: this.props.nestedLevel + 1,
						  })
						: child
				})}
			</div>
		)
	}
}

export default DocumentTreeNestedList
