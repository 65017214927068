// @flow

import React, { Component, type Node } from 'react'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import type { AccountingDocument, AccountingDocumentScan, CashRegisters } from 'types'
import { isAccountingDocumentExtractable } from '../../domain/accounting-document'
import FileViewer from 'modules/file/containers/file-viewer'
import ClassificationInfo from '../../containers/invoice-elements/classification-info'
import CustomerInstructions from '../../components/invoice-elements/customer-instructions'
import DeleteDialog from 'components/delete-dialog'
import Tab from 'components/Tabs/Tab'
import Tabs from 'components/Tabs/Tabs'
import Dropzone from 'components/dropzone'
import styles from './scans.css'
import { colors } from 'variables'
import IconButton from 'components/icon-button'
import Delete from 'components/svg-icons/action/delete'

type Props = {|
	...WithTranslateProps,
	accountingDocumentId: string,
	scans: ?Array<AccountingDocumentScan>,
	accountingDocument: ?AccountingDocument,
	cashRegisters: CashRegisters,
	unclassified?: boolean, // REMOVE
	Classification?: Node,
	deleteScan: (id: string) => void,
	attachAccountingDocumentScan: (scan: File) => Promise<*>,
|}

type State = {|
	currentScan: ?AccountingDocumentScan,
|}

function getLastScanFromProps(props: Props): ?AccountingDocumentScan {
	return props.scans && props.scans.length > 0 ? props.scans[props.scans.length - 1] : null
}

function getFirstScanFromProps(props: Props): ?AccountingDocumentScan {
	return props.scans && props.scans.length > 0 ? props.scans[0] : null
}

class Scans extends Component<Props, State> {
	state = { currentScan: null }
	openedScans: { [string]: boolean } = {} // Allows to load files from server when needed and only once
	dropzone: any = null

	constructor(props: Props) {
		super(props)
		const firstScan = getFirstScanFromProps(props)
		this.updateOpenedScans(firstScan)
		this.state = {
			currentScan: firstScan,
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		if (this.props.scans !== nextProps.scans) {
			const currentScan =
				this.props.scans && nextProps.scans && this.props.scans.length > nextProps.scans.length
					? getFirstScanFromProps(nextProps)
					: getLastScanFromProps(nextProps)

			this.updateOpenedScans(currentScan)
			this.setState({ currentScan })
		}
	}

	updateOpenedScans(firstScan: ?AccountingDocumentScan) {
		if (firstScan && firstScan.guid) {
			this.openedScans[firstScan.guid] = true
		}
	}

	handleTabChange = (value: string) => {
		this.openedScans[value] = true
		if (this.props.scans) {
			this.setState({
				currentScan: this.props.scans && this.props.scans.find((scan: AccountingDocumentScan) => scan.guid === value),
			})
		}
	}

	onRemove = (id: string) => () => {
		DeleteDialog()
			.then(() => {
				this.props.deleteScan(id)
			})
			.catch(() => {})
	}

	handleUpload = async (files: Array<File>): Promise<any> => {
		await Promise.all(
			files.map((file: File) => {
				return this.props.attachAccountingDocumentScan(file)
			}),
		)
	}

	handleClick = () => {
		this.dropzone &&
			this.dropzone
				.getWrappedInstance()
				.getWrappedInstance()
				.open()
	}

	bindDropZone = (element: any) => {
		this.dropzone = element
	}

	renderAddButton() {
		return (
			<div className={styles.add} onClick={this.handleClick}>
				{this.props.t('scans.uploadNewScan')}
			</div>
		)
	}

	render() {
		const hasScans = this.props.scans && this.props.scans.length
		const isExtractable =
			this.props.accountingDocument && isAccountingDocumentExtractable(this.props.accountingDocument.state)
		const isUploaded = this.props.accountingDocument && this.props.accountingDocument.state === 'Uploaded'
		const isExtracting = this.props.accountingDocument && this.props.accountingDocument.state === 'Extracting'

		return (
			<div className={styles.root}>
				{isExtractable && this.props.Classification}
				<ClassificationInfo
					isExtractable={isExtractable}
					isUploaded={isUploaded}
					isExtracting={isExtracting}
					isEditable
					accDoc={this.props.accountingDocument}
				/>
				<CustomerInstructions accountingDocument={this.props.accountingDocument} />

				<div className={styles.scans}>
					{hasScans ? (
						<Tabs
							onChange={this.handleTabChange}
							tailContent={this.renderAddButton()}
							tabContentStyle={style.tabContent}
							style={style.tabs}
							tabItemContainerStyle={style.tabItemContainer}
							autoTestId="scans-files-tabs"
						>
							{this.props.scans &&
								this.props.scans.map((scan: AccountingDocumentScan, i: number) => (
									<Tab key={scan.guid} value={scan.guid} label={`${this.props.t('scans.scanTabLabel')} ${i + 1}`}>
										{scan.guid && this.openedScans[scan.guid] && (
											<div className={styles.viewerContainer}>
												<div className={styles.deleteButtonContainer}>
													<IconButton
														autoTestId="scans-delete"
														tooltip={this.props.t('uploadScan.deleteTooltip')}
														onClick={this.onRemove(scan.guid || '')}
													>
														<Delete color={colors.grey600} />
													</IconButton>
												</div>
												<FileViewer stretch autoHeight fileId={scan.fileId || ''} downloadable />
											</div>
										)}
									</Tab>
								))}
						</Tabs>
					) : null}

					<div style={{ display: hasScans ? 'none' : 'block', paddingBottom: 40 }}>
						<Dropzone
							ref={this.bindDropZone}
							onDrop={this.handleUpload}
							minHeight={100}
							accept={
								'image/jpeg,image/jpg,image/png,image/gif,image/tiff,application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/docx,application/msword'
							}
						/>
					</div>
				</div>
			</div>
		)
	}
}

const style = {
	tabs: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'stretch',
	},
	tabItemContainer: {
		flexWrap: 'wrap',
	},
	tabContent: {
		height: '100%',
	},
}

export default withTranslate(Scans)
