/* @flow */
import { userHasAccess } from 'permissions'
import type { State, OrganizationMember } from 'types'
import Features, { canSeeFeatures as userCanSeeFeatures } from 'utils/features'
import { notFoundRoute } from 'modules/navigation/routing/routes'
import { getCurrentOrganizationId, getCurrentUserOrganizations } from 'modules/user/selectors'
import { hasCashRegisterSettingsAccess, isMeOwnerOfAnyCashRegisterSelector } from 'modules/cash-register/selectors'
import { MAIN_ROUTE_SECTION_PRIORITY, NAVIGATION_SECTION_ROUTE_FNS } from '../constants'
import type { NavigationSection, NavigationSectionPermissions } from '../types'

export function getNavigationSectionPermissions(state: State): NavigationSectionPermissions {
	const orgId: ?string = getCurrentOrganizationId(state)
	const organizations: ?Array<OrganizationMember> = getCurrentUserOrganizations(state)

	const accountingDocuments: boolean =
		userHasAccess(state, 'seeIssuedAccountingDocuments') ||
		userHasAccess(state, 'seeReceivedAccountingDocuments') ||
		userHasAccess(state, 'seeUnknownAccountingDocuments') ||
		userHasAccess(state, 'seeAccountingDocumentTemplates')

	const seeCashRegisters = userHasAccess(state, 'seeCashRegisters')
	const editCashRegisters = userHasAccess(state, 'editCashRegisters')
	const isMeOwnerOfAnyCashRegister = isMeOwnerOfAnyCashRegisterSelector(state)

	return {
		tasks: true,
		internalDashboard: userHasAccess(state, 'seeInternalDashboard'),
		dashboard: !!orgId && userHasAccess(state, 'seeAccountingDocuments'),
		emptyDashboard: !organizations || organizations.length === 0,
		settings: !!orgId && hasCashRegisterSettingsAccess(state),
		features: userCanSeeFeatures(),
		documents: userHasAccess(state, 'seeDocuments'),
		cashRegisters: true,
		bankAccounts: userHasAccess(state, 'seeBankAccounts'),
		contacts: userHasAccess(state, 'seeContacts'),
		accountingDocuments,
		accountingDocumentsDetail:
			accountingDocuments || seeCashRegisters || editCashRegisters || isMeOwnerOfAnyCashRegister,
		accountingDocumentsEdit: accountingDocuments || editCashRegisters || isMeOwnerOfAnyCashRegister,
		extractAccountingDocuments: userHasAccess(state, 'extractAccountingDocuments'),
		triviStore: !!orgId,
		registration: Features.isEnabled('registration'),
	}
}

export function getMainScreenRoute(state: State) {
	const navPerms: NavigationSectionPermissions = getNavigationSectionPermissions(state)

	const mainSection: ?NavigationSection = MAIN_ROUTE_SECTION_PRIORITY.find((s: NavigationSection) => {
		return navPerms[s] === true
	})

	const mainRouteFn = mainSection && NAVIGATION_SECTION_ROUTE_FNS[mainSection]

	if (mainRouteFn) {
		return mainRouteFn()
	} else {
		return notFoundRoute()
	}
}
