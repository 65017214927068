/* @flow */

import React, { Component } from 'react'
import { type WithTranslateProps, withTranslate } from 'wrappers'
import type { AccountingDocumentNote, UserProfile } from 'types'
import { emptyAccountingDocumentNote } from 'types/empty'
import { getTodayIsoDateString } from 'utils/formatters'
import { getUserDisplayName } from 'modules/user/user/user'
import { TextField } from 'components'
import styles from './note.css'

export type Props = {|
	...WithTranslateProps,
	accountingDocumentId: string,
	readonly?: boolean,
	note: ?AccountingDocumentNote,
	extractingNote: ?AccountingDocumentNote,
	isOpen?: boolean,
	readonly?: boolean,
	me: ?UserProfile,
	disabled?: boolean,
	updateNote: (newNote: ?AccountingDocumentNote) => void,
|}

type State = {|
	note: AccountingDocumentNote,
|}

class Note extends Component<Props, State> {
	state: State = {
		note: this.props.note || emptyAccountingDocumentNote(),
	}

	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		this.setState({
			note: nextProps.note || emptyAccountingDocumentNote(),
		})
	}

	getExtractingNote = () => {
		return this.props.extractingNote && this.props.extractingNote.text ? this.props.extractingNote.text : null
	}

	onNoteChange = (event: SyntheticInputEvent<HTMLInputElement>, text: ?string) => {
		if (text !== null && text !== undefined && !this.props.disabled) {
			this.setState({
				note: Object.freeze({
					...this.state.note,
					text,
				}),
			})
		}
	}

	onNoteBlur = () => {
		const { note } = this.state
		const { me } = this.props

		if (me && note && note !== this.props.note && !this.props.disabled) {
			this.props.updateNote(
				Object.freeze({
					...note,
					updatedTime: getTodayIsoDateString(),
					authorUniqueId: me.id,
					authorName: getUserDisplayName(me.firstname, me.lastname, me.email),
				}),
			)
		}
	}

	render() {
		if (!this.props.isOpen) return null
		if (this.props.disabled && !this.props.note) return null

		const { t } = this.props
		const extractingNote = this.getExtractingNote()

		return (
			<div className={styles.root}>
				<div className={styles.note}>
					{extractingNote && (
						<div className={styles.extractingNote}>{`${t('invoice.extractingNote')}: ${extractingNote}`}</div>
					)}
					<TextField
						multiLine
						inline
						transparent
						fullWidth
						value={this.state.note.text}
						hintText={t('invoice.newNoteLabel')}
						onChange={this.onNoteChange}
						onBlur={this.onNoteBlur}
						disabled={this.props.disabled}
						style={style.tf}
						name="new-note"
						autoTestId="invoice-note-input"
					/>
				</div>
			</div>
		)
	}
}

const style = {
	tf: {
		fontFamily: 'Caveat',
		lineHeight: '24px',
		fontSize: 18,
	},
}

export default withTranslate(Note)
