//@flow
/** @jsx jsx */

import { Component } from 'react'
import { jsx } from '@emotion/core'
import { Spring, animated } from 'react-spring'
import type { AccountingDocument } from 'types'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import { formatToMoney } from 'utils/formatters'
import { colors } from 'variables'

type Props = {|
	selectedDocuments?: Array<AccountingDocument>,
|}

type State = {|
	documentsByCurrency: DocumentsByCurrency,
|}

type DocumentsByCurrency = {
	[currency: string]: {
		count: number,
		total: number,
	},
}

class DocumentsCounter extends Component<Props & WithTranslateProps, State> {
	state = {
		documentsByCurrency: {},
	}

	UNSAFE_componentWillReceiveProps = (nextProps: Props) => {
		const { selectedDocuments } = this.props
		const { selectedDocuments: nextSelectedDocuments } = nextProps
		if ((selectedDocuments && selectedDocuments) !== (nextSelectedDocuments && nextSelectedDocuments)) {
			this.updateStateFromProps(nextProps)
		}
	}

	updateStateFromProps = (props: Props) => {
		const { selectedDocuments } = props
		this.setState({
			documentsByCurrency: selectedDocuments
				? selectedDocuments.reduce(this.filterSelectedDocumentsByCurrency, {})
				: {},
		})
	}

	filterSelectedDocumentsByCurrency = (
		documents: DocumentsByCurrency,
		item: AccountingDocument,
	): DocumentsByCurrency => {
		const currency = item.currency
		const currentTotal = documents[currency] ? documents[currency].total : 0
		const currentCount = documents[currency] ? documents[currency].count : 0
		documents[currency] = {
			total: item.total ? currentTotal + item.total : currentTotal,
			count: currentCount + 1,
		}
		return documents
	}

	renderCurrencyItem = (currency: string, index: number) => {
		const { documentsByCurrency } = this.state
		const { t } = this.props
		const count = documentsByCurrency[currency].count
		const total = documentsByCurrency[currency].total

		return (
			<span css={styles.item} key={index}>
				{count} {t('grid.DocumentsCounter.accountingDocument', { count })}{' '}
				<span css={styles.number}>
					{formatToMoney(total, {
						currency,
					})}
				</span>
			</span>
		)
	}

	render() {
		const { documentsByCurrency } = this.state
		const { t } = this.props
		const selectedDocuments = this.props.selectedDocuments || []
		const currencyKeys = Object.keys(documentsByCurrency)

		return (
			<Spring native from={{ height: 0 }} to={{ height: selectedDocuments.length > 0 ? 'auto' : 0 }}>
				{(props: Object) => (
					<animated.div style={{ overflow: 'hidden', ...props }}>
						<div css={styles.root}>
							<span>{t('grid.DocumentsCounter.title')} </span>
							{currencyKeys.map(this.renderCurrencyItem)}
						</div>
					</animated.div>
				)}
			</Spring>
		)
	}
}

const styles = {
	root: {
		fontSize: 12,
		color: colors.blackFaded60,
		textAlign: 'right',
		paddingBottom: 3,
		overflow: 'hidden',
	},
	item: {
		marginRight: 10,
	},
	number: {
		fontWeight: 'bold',
	},
}

export default withTranslate(DocumentsCounter)
