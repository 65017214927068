/* @flow */

import React, { Component } from 'react'
import type { WithRouterProps, AddressBookAction, State } from 'types'
import PopupSection from 'components/PopupSection'
import Edit from '../containers/address-book-edit'
import { push, goBack } from 'react-router-redux'
import Helmet from 'react-helmet'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { refererRoute } from 'utils'
import { addressBooksRoute } from 'modules/address-book/routing/routes'
import ConfirmDialog from 'components/confirm-dialog'
import { getContactSaved } from '../selectors'

type Props = {
	...WithTranslateProps,
	...WithRouterProps,
	...DisaptchProps,
}
class ContactEdit extends Component<Props> {
	onClose = () => {
		if (!this.props.saved) {
			ConfirmDialog(this.props.t('dialogs.continueWithoutSave'), {
				okLabel: this.props.t('dialogs.dontSave'),
				cancelLabel: this.props.t('application.cancel'),
				body: this.props.t('dialogs.closeWhenUnsavedWarningBody'),
			}).then(() => {
				this.props.close()
				this.props.setSaved(true)
			})
		} else {
			this.props.close()
			this.props.setSaved(true)
		}
	}
	render() {
		return (
			<PopupSection open onRequestClose={this.onClose}>
				<Helmet>
					<title>{this.props.t('contactEdit.edit.title')}</title>
				</Helmet>
				<Edit contactId={this.props.match.params.id} />
			</PopupSection>
		)
	}
}

type StateProps = {|
	saved: boolean,
|}
const mapStateToProps = (state: State): StateProps => {
	return {
		saved: getContactSaved(state),
	}
}

type DisaptchProps = {|
	close: () => void,
	setSaved: (saved: boolean) => void,
|}

const mapDispatchToProps = (dispatch: Dispatch<AddressBookAction>): DisaptchProps => {
	return {
		close: () => {
			if (refererRoute()) {
				dispatch(goBack())
				return
			}
			dispatch(push(addressBooksRoute()))
		},
		setSaved: (saved: boolean) => {
			dispatch({
				type: 'SET_CONTACT_SAVED',
				saved,
			})
		},
	}
}

export default withTranslate(connect(mapStateToProps, mapDispatchToProps)(withRouter(ContactEdit)))
