// @flow
/** @jsx jsx */

import { jsx } from '@emotion/core'
import { Component } from 'react'
import { groupBy } from 'lodash-es'
import { withRouter } from 'react-router'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import PopupSection from 'components/PopupSection'
import FormBox from 'components/form-box'
import Button from 'components/button'
import type {
	WithRouterProps,
	AccountingDocument,
	UnmatchedPayment,
	UnmatchedPaymentMatch,
	UnmatchedPaymentMatchesRequestBulk as UnmatchedPaymentMatches,
} from 'types'
import { formatToDateString, formatToMoney } from 'utils/formatters'
import { formatUnmatchedPaymentBankAccount } from 'utils/formatters/bank-account-formatter'
import Direction from 'components/direction/direction'
import Separator from 'components/Separator'
import {
	default as SingleLineDataGrid,
	type Props as SingleLineDataGridProps,
} from 'modules/data-grid-next/components/single-line-data-grid'
import {
	getIssueDateColumn,
	getTotalColumn,
	getDueDateColumn,
	getCompanyColumn,
	getAccountingDocumentNoCategoryColumn,
} from 'modules/accounting-document/components/data-grid-columns'
import { colors } from 'variables'
import { connect } from 'react-redux'
import { accountingDocumentSearch } from 'modules/common/models/api-model'
import AccountingDocumentAdvancedFilter from 'modules/accounting-document/components/accounting-document-list/advanced-filter'
import { addAccountingDocuments } from './unmatched-payment'
import { showAccountingDocumentRoute } from 'modules/accounting-document/routing/routes'

const DATA_TYPE = 'accountingDocuments'
const DataGrid: React$ComponentType<SingleLineDataGridProps<typeof DATA_TYPE>> = SingleLineDataGrid

type Props = {|
	...WithRouterProps,
	...WithTranslateProps,
	updateMatches: (unmatchedPayment: UnmatchedPayment, unmatchedPaymentMatches: UnmatchedPaymentMatches) => void,
	onClose: () => void,
	unmatchedPayment: UnmatchedPayment,
	handOverToAccountant: (unmatchedPayment: UnmatchedPayment, internal: boolean) => void,
	getAccountingDocumentsByIds: (ids: Array<string>) => Promise<Array<AccountingDocument>>,
	internal: ?boolean,
|}

type State = {|
	accountingDocuments: Array<AccountingDocument>,
	changed: boolean,
|}

class UpdateAccountingDocumentsDialog extends Component<Props, State> {
	state = {
		accountingDocuments: [],
		changed: false,
	}

	defaultFilter = [
		{
			field: 'payments.paidConfirmed',
			value: false,
		},
	]

	filters = [
		'accountingDocumentNo',
		'externalNo',
		'contact.normalizedName',
		'contact.companyRegNo',
		'variableSymbol',
		'currency',
		'total',
		'type',
		'state',
		'processingState',
		'sequence',
		'vatCountryType',
	]

	async componentDidMount() {
		const accountingDocuments = await this.props.getAccountingDocumentsByIds(
			this.getUnmatchedPaymentAccountingDocumentsId(),
		)
		// eslint-disable-next-line react/no-did-mount-set-state
		this.setState({ accountingDocuments })
	}

	getUnmatchedPaymentAccountingDocumentsId() {
		return ((this.props.unmatchedPayment.matches && this.props.unmatchedPayment.matches.generalMatches) || [])
			.filter((ad: UnmatchedPaymentMatch) => ad.accountingDocumentId)
			.map((ad: UnmatchedPaymentMatch) => ad.accountingDocumentId || '')
	}
	handOverToAccountant = () => {
		this.props.handOverToAccountant(this.props.unmatchedPayment, !!this.props.internal)
	}

	render() {
		return (
			<PopupSection open onRequestClose={this.props.onClose}>
				<FormBox
					actions={
						<Button
							disabled={!this.state.changed}
							onClick={this.onAddAccountingDocument}
							labelText={this.props.t('datagrid.implemented.unmatchedPayments.addAccountingDocument')}
						/>
					}
					header={
						<div>
							{this.props.t('datagrid.implemented.unmatchedPayments.addAccountingDocumentHeader')}{' '}
							<span css={styles.date}>{formatToDateString(this.props.unmatchedPayment.paymentDate || '')}</span>
						</div>
					}
					children={
						<div>
							<div css={styles.content}>
								<div css={styles.info}>
									<div>{formatUnmatchedPaymentBankAccount(this.props.unmatchedPayment)}</div>
									<div>{this.props.unmatchedPayment.description}</div>
								</div>

								<div css={styles.amount}>
									<div css={styles.direction}>
										<Direction
											issued={0 === this.props.unmatchedPayment.direction}
											received={1 === this.props.unmatchedPayment.direction}
										/>
									</div>
									{formatToMoney(this.props.unmatchedPayment.amount, {
										currency: this.props.unmatchedPayment.currency,
									})}
								</div>
							</div>
							<Separator
								left={
									<span css={styles.separatorText}>
										{this.props.t('datagrid.implemented.unmatchedPayments.addAccountingDocumentTitle')}
									</span>
								}
							/>
							<div>{this.renderFilter()}</div>
							<div>{this.renderGrid()}</div>
							<div>{this.renderTotal()}</div>
						</div>
					}
				/>
			</PopupSection>
		)
	}

	renderFilter() {
		return (
			<div>
				<AccountingDocumentAdvancedFilter
					showViewSwitch={false}
					name="accountingDocumentPicker"
					defaultFilter={this.defaultFilter}
					filterItems={this.filters}
					internal={this.props.internal}
					hideCurrency
				/>
			</div>
		)
	}

	onDocumentClick = (accountingDocument: AccountingDocument) => {
		this.props.history.push(showAccountingDocumentRoute(accountingDocument.id || ''), {
			goBack: true,
		})
	}

	renderGrid() {
		return (
			<DataGrid
				id="accountingDocumentPicker"
				dataType="accountingDocuments"
				filterId="accountingDocumentPicker"
				defaultFilter={this.defaultFilter}
				selectedRows={this.state.accountingDocuments.map((ad: AccountingDocument) => ad.id || '')}
				onSelectionChange={this.handleSelectionChange}
				selectOnClick
				compactPager
				light
				style={styles.dataGrid}
			>
				{getAccountingDocumentNoCategoryColumn({
					t: this.props.t,
					width: '25%',
					onClick: this.onDocumentClick,
				})}
				{getCompanyColumn({ t: this.props.t, width: '26%' })}
				{getIssueDateColumn({ singleLine: true, t: this.props.t, width: '11%' })}
				{getDueDateColumn({ t: this.props.t, width: '15%' })}
				{getTotalColumn({ t: this.props.t, type: 'simple', width: '23%' })}
			</DataGrid>
		)
	}

	renderTotal() {
		const currencies = Object.entries(groupBy(this.state.accountingDocuments, 'currency'))

		const text = currencies
			// $FlowFixMe - Object.entries is not typed properly
			.map(([currency, ads]: [string, Array<AccountingDocument>]) => {
				return formatToMoney(
					ads.reduce((sum: number, ad: AccountingDocument) => sum + (ad.total || 0), 0),
					{ currency, minimumFractionDigits: 2, maximumFractionDigits: 2 },
				)
			})
			.join(', ')

		return (
			<div css={styles.total}>
				<div css={styles.totalHeadline}>
					{this.props.t('datagrid.implemented.unmatchedPayments.addAccountingDocumentTotal')}
				</div>
				<div css={styles.totalAmount}>{text}</div>
			</div>
		)
	}

	handleSelectionChange = (accountingDocument: AccountingDocument, selected: boolean) => {
		if (selected) {
			this.setState({
				changed: true,
				accountingDocuments: [...this.state.accountingDocuments, ...[accountingDocument]],
			})
		} else {
			const newAccountingDocuments = this.state.accountingDocuments.filter(
				(ad: AccountingDocument) => ad.id !== accountingDocument.id,
			)
			this.setState({ changed: true, accountingDocuments: newAccountingDocuments })
		}
	}

	onAddAccountingDocument = () => {
		this.props.updateMatches(
			this.props.unmatchedPayment,
			addAccountingDocuments(this.props.unmatchedPayment, this.state.accountingDocuments),
		)
		this.handOverToAccountant()
	}
}

const styles = {
	date: { fontWeight: 600 },
	content: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		fontSize: 20,
		lineHeight: 1.4,
		marginBottom: 30,
		fontWeight: 'normal',
	},
	info: {
		flex: '1 1 100%',
	},
	amount: {
		flex: '1 0 auto',
		fontWeight: 600,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
	},
	direction: {
		display: 'flex',
		marginRight: 6,
		marginLeft: 30,
	},
	separatorText: {
		fontSize: 12,
		textTransform: 'uppercase',
		marginRight: 10,
	},
	dataGrid: {
		height: 70,
	},
	total: {
		fontSize: 20,
		lineHeight: '60px',
		borderTop: `1px solid ${colors.grey400}`,
		borderBottom: `1px solid ${colors.grey400}`,
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	totalHeadline: {
		flex: '1 1 100%',
	},
	totalAmount: {
		flex: '1 0 auto',
		fontWeight: 600,
	},
}

function mapDispatchToProps() {
	return {
		getAccountingDocumentsByIds: async (ids: Array<number>) => {
			if (ids.length > 0) {
				const response = await accountingDocumentSearch.post({}, { filters: [{ field: 'id', valueContains: ids }] })
				return response.accountingDocuments
			} else {
				return await []
			}
		},
	}
}

export default connect(undefined, mapDispatchToProps)(withTranslate(withRouter(UpdateAccountingDocumentsDialog)))
