/* @flow */

import React, { Component, type Node } from 'react'
import memoize from 'memoize-one'
import SelectNext from 'components/select-next'
import type { OrganizationMember, OrganizationMembers } from 'types'
import type { ValueType } from 'components/select-next'

type Props = {|
	organizations: ?OrganizationMembers,
	value: ?string,
	isClearable?: boolean,
	disabled?: boolean,
	inline?: boolean,
	fullWidth?: boolean,
	hintText?: string,
	labelText?: string,
	autoSelect?: boolean,
	autoWidth?: boolean,
	clientError?: Node,
	onChange: (organizationId: ?string) => void,
|}

class OrganizationSelect extends Component<Props> {
	componentDidMount() {
		this.props.autoSelect &&
			this.props.organizations &&
			this.props.organizations.length === 1 &&
			this.props.onChange(this.props.organizations[0].organizationId)
	}

	handleChange = (value: ValueType) => {
		const newValue = value && !Array.isArray(value) ? value.value : null
		this.props.onChange(newValue)
	}

	getOptions = memoize((organizations: ?OrganizationMembers) => {
		if (!organizations || organizations.length < 1) return []
		return organizations.map((organization: OrganizationMember) => {
			return {
				label: organization.organizationName,
				value: organization.organizationId,
			}
		})
	})

	getValue = memoize((value: ?string, organizations: ?OrganizationMembers) => {
		if (!organizations || organizations.length < 1) return null
		const organization = organizations.find((org: OrganizationMember) => org.organizationId === value)
		return organization ? organization.organizationId : null
	})

	render() {
		const { organizations, value, isClearable, disabled, clientError } = this.props
		if (!organizations || organizations.length < 1) return null

		return (
			<SelectNext
				disabled={disabled}
				isSearchable
				isClearable={isClearable}
				value={this.getValue(value, organizations)}
				options={this.getOptions(organizations)}
				onChange={this.handleChange}
				autoWidth={this.props.autoWidth}
				isMulti={false}
				placeholder={this.props.hintText}
				label={this.props.labelText}
				inline={this.props.inline}
				autoTestId="organization-select"
				error={clientError}
				portal
			/>
		)
	}
}

export default OrganizationSelect
