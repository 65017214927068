/* @flow */

import type {
	BulkOperationRequest,
	BulkOperationResult,
	Dispatch,
	DocumentAction,
	SearchRequestGeneric,
	State,
	ValidationError,
} from 'types'
import {
	documents as documentsApi,
	documentsBulk as documentsBulkApi,
	documentsRoot as documentsRootApi,
	documentsSearch as documentsSearchApi,
} from '../../common/models/api-model'
import { getDocumentItem, getDocumentListState, getDocumentState } from '../selectors'
import type { DocumentItem, DocumentItemPostBody, DocumentsGetParams, DocumentsRootGetParams } from '../types'

export function loadDocuments(
	parentId?: ?string,
	sortingDirection: 'ASC' | 'DESC',
	page?: number = 1,
	pageSize?: number = 20,
) {
	return async (dispatch: Dispatch<DocumentAction>) => {
		dispatch({
			type: 'START_LOAD_DOCUMENTS',
			parentId,
			page,
			pageSize,
		})
		try {
			let result

			if (parentId) {
				const params: DocumentsGetParams = {
					documentItemUniqueId: parentId,
					sortingDirection,
				}

				if (page) {
					params.page = page
				}

				if (pageSize) {
					params.pageSize = pageSize
				}
				result = await documentsApi.get(params)
			} else {
				const params: DocumentsRootGetParams = {
					sortingDirection,
				}

				if (page) {
					params.page = page
				}

				if (pageSize) {
					params.pageSize = pageSize
				}
				result = await documentsRootApi.get(params)
			}

			dispatch({
				type: 'FINISH_LOAD_DOCUMENTS',
				parentId,
				page,
				pageSize,
				pagesCount: result.pagesCount,
				documents: result.documentItems,
				currentDocumentItem: result.currentDocumentItem,
				parentDocumentInfo: result.parentDocumentInfo,
			})
		} catch (error) {
			dispatch({
				type: 'FINISH_LOAD_DOCUMENTS',
				parentId,
				page,
				pageSize,
				documents: null,
				serverError: error,
			})
		}
	}
}

export function preloadDocumentFolder(currentId: string, folderId: string) {
	return async (dispatch: Dispatch<DocumentAction>) => {
		dispatch({
			type: 'PRELOAD_DOCUMENT_FOLDER',
			currentId,
			folderId,
		})
	}
}

export function searchDocuments(
	searchText: string,
	sortingDirection: 'ASC' | 'DESC',
	page?: number = 1,
	pageSize?: number = 20,
) {
	return async (dispatch: Dispatch<DocumentAction>) => {
		dispatch({
			type: 'START_LOAD_DOCUMENTS',
			searchText,
			page,
			pageSize,
		})
		try {
			let result

			const params: SearchRequestGeneric = {
				searchText,
				sortingField: 'name',
				sortingDirection,
			}

			if (page) {
				params.page = page
			}

			if (pageSize) {
				params.pageSize = pageSize
			}
			result = await documentsSearchApi.post(params)

			dispatch({
				type: 'FINISH_LOAD_DOCUMENTS',
				searchText,
				page,
				pageSize,
				pagesCount: result.pagesCount,
				documents: result.documentItems,
			})
		} catch (error) {
			dispatch({
				type: 'FINISH_LOAD_DOCUMENTS',
				searchText,
				page,
				pageSize,
				documents: null,
				serverError: error,
			})
		}
	}
}

export function changeSearch(searchText: string) {
	return {
		type: 'CHANGE_DOCUMENT_SEARCH',
		searchText,
	}
}

export function refreshDocuments(parentId?: ?string) {
	return async (dispatch: Dispatch<DocumentAction>, getState: () => State) => {
		const searchText = getState().document.currentSearch
		const documentListItem = getDocumentListState(getState(), parentId, searchText)
		const document = getDocumentState(getState(), parentId)

		if (documentListItem && document) {
			if (searchText) {
				dispatch(
					searchDocuments(
						searchText,
						document.currentSortingDirection,
						documentListItem.currentPage,
						documentListItem.currentPageSize,
					),
				)
			} else {
				dispatch(
					loadDocuments(
						parentId,
						document.currentSortingDirection,
						documentListItem.currentPage,
						documentListItem.currentPageSize,
					),
				)
			}
		}
	}
}

export function createFolder(parentId: string, documentItem: DocumentItem) {
	return async (dispatch: Dispatch<DocumentAction>) => {
		dispatch({
			type: 'START_CREATE_DOCUMENT_FOLDER',
		})
		try {
			const result: DocumentItem = await documentsApi.post(
				{
					documentItemUniqueId: parentId,
				},
				{ ...documentItem },
			)
			dispatch({
				type: 'FINISH_CREATE_DOCUMENT_FOLDER',
				document: result,
			})
		} catch (error) {
			dispatch({
				type: 'FINISH_CREATE_DOCUMENT_FOLDER',
				document: null,
				serverError: error,
			})
		}
	}
}

export function updateDocumentItem(newDocument: DocumentItem) {
	return async (dispatch: Dispatch<DocumentAction>, getState: () => State) => {
		const id = newDocument.uniqueId
		if (id) {
			const oldDocument = getDocumentItem(getState(), newDocument.uniqueId)
			dispatch({
				type: 'START_UPDATE_DOCUMENT_ITEM',
				id,
				oldDocument,
				newDocument,
			})
			try {
				const result: DocumentItem = await documentsApi.put({ documentItemUniqueId: id }, newDocument)
				dispatch({
					type: 'FINISH_UPDATE_DOCUMENT_ITEM',
					id,
					oldDocument,
					newDocument: { ...result, canBeChanged: (oldDocument && oldDocument.canBeChanged) ?? result.canBeChanged },
				})
			} catch (error) {
				dispatch({
					type: 'FINISH_UPDATE_DOCUMENT_ITEM',
					id,
					oldDocument,
					newDocument: null,
					serverError: error,
				})
			}
		} else {
			throw new Error('id cannot be null')
		}
	}
}

export function uploadDocumentFiles(
	documentItemUniqueId: string,
	documents: Array<{|
		file: File,
		documentItem: DocumentItem,
	|}>,
) {
	return async (dispatch: Dispatch<DocumentAction>) => {
		dispatch({
			type: 'START_UPLOAD_DOCUMENT_FILES',
		})
		try {
			const items: Array<DocumentItem> = await Promise.all(
				documents.map(async (item: {| file: File, documentItem: DocumentItem |}) => {
					const payload: DocumentItemPostBody = {
						file: item.file,
						name: item.documentItem.name,
						itemType: item.documentItem.itemType,
						note: item.documentItem.note || '',
					}
					const postParams = {
						documentItemUniqueId,
					}

					const result = await documentsApi.post(postParams, payload, { timeout: 0 })
					return result
				}),
			)
			dispatch({
				type: 'FINISH_UPLOAD_DOCUMENT_FILES',
				uploadedDocuments: items || [],
			})
		} catch (serverError) {
			dispatch({
				type: 'FINISH_UPLOAD_DOCUMENT_FILES',
				uploadedDocuments: [],
				serverError,
			})
		}
	}
}

export function deleteDocumentItem(itemId: string) {
	return async (dispatch: Dispatch<DocumentAction>) => {
		dispatch({
			type: 'START_DELETE_DOCUMENT_ITEM',
			id: itemId,
		})
		try {
			await documentsApi.delete({
				documentItemUniqueId: itemId,
			})
			dispatch({
				type: 'FINISH_DELETE_DOCUMENT_ITEM',
				id: itemId,
				success: true,
			})
		} catch (error) {
			dispatch({
				type: 'FINISH_DELETE_DOCUMENT_ITEM',
				id: itemId,
				success: false,
				serverError: error,
			})
		}
	}
}

export function moveDocuments(documentIds: Array<string>, target: string) {
	return async (dispatch: Dispatch<DocumentAction>) => {
		dispatch({
			type: 'MOVE_DOCUMENT',
			documentIds,
			target,
		})
		return await documentBulk(dispatch, 'moveto', documentIds, { documentUniqueId: target })
	}
}

async function documentBulk(
	dispatch: Dispatch<DocumentAction>,
	operation: string,
	uniqueIds: Array<string>,
	additionalData?: any,
) {
	const request = { operation, uniqueIds, additionalData }
	dispatch(startDocumentsBulk(request))
	try {
		const result = await documentsBulkApi.post(request)
		dispatch(finishDocumentsBulk(request, result))
	} catch (error) {
		dispatch(finishDocumentsBulk(request, undefined, error))
	}
}

function startDocumentsBulk(request: BulkOperationRequest) {
	return {
		type: 'START_DOCUMENTS_BULK',
		request,
	}
}

function finishDocumentsBulk(request: BulkOperationRequest, result?: BulkOperationResult, error?: ValidationError) {
	return {
		type: 'FINISH_DOCUMENTS_BULK',
		request,
		result,
		serverError: error,
	}
}

export function changeSort(id: ?string, sortingDirection: 'ASC' | 'DESC') {
	return async (dispatch: Dispatch<DocumentAction>) => {
		dispatch({
			type: 'CHANGE_DOCUMENT_ITEM_SORT',
			id,
			sortingDirection: sortingDirection,
		})
	}
}
