/* @flow */

import { connect } from 'react-redux'
import { withAutoload } from 'wrappers'
import type { AccountingDocumentReduxAction, Dispatch, Base64Image, SettingsAction, State, UserAction } from 'types'
import { loadPublicAccountingDocumentLogo } from 'modules/accounting-document/actions/accounting-document'
import { getPublicAccountingDocumentLogo } from 'modules/accounting-document/selectors'
import Logo from '../../components/invoice-elements/logo'

export type OwnProps = {|
	uniqueId: string,
|}

type StateProps = {|
	binary: ?Base64Image,
|}

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => {
	return {
		binary: getPublicAccountingDocumentLogo(state, ownProps.uniqueId),
	}
}

type DispatchProps = {|
	autoload: () => void,
|}

const mapDispatchToProps = (
	dispatch: Dispatch<AccountingDocumentReduxAction | UserAction | SettingsAction>,
	ownProps: OwnProps,
): DispatchProps => {
	return {
		autoload: () => {
			dispatch(loadPublicAccountingDocumentLogo(ownProps.uniqueId))
		},
	}
}

function mergeProps(stateProps: StateProps, dispatchProps: DispatchProps) {
	return {
		autoload: dispatchProps.autoload,
		binary: stateProps.binary,
	}
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(withAutoload(Logo))
