/* @flow */

import React, { Component } from 'react'
import { connect } from 'react-redux'
import type { CommonAction, CompanyType, Dispatch, State } from 'types'
import { loadCompanyTypes } from 'modules/common/actions'
import SelectNext from 'components/select-next'
import type { OptionType } from 'components/select-next'

type StateProps = {|
	companyTypes: ?Array<CompanyType>,
|}

const mapStateToProps = (state: State): StateProps => {
	return {
		companyTypes: state.common.companyTypes,
	}
}

type DispatchProps = {|
	loadCompanyTypes: () => void,
|}

const mapDispatchToProps = (dispatch: Dispatch<CommonAction>): DispatchProps => {
	return {
		loadCompanyTypes: () => {
			dispatch(loadCompanyTypes())
		},
	}
}

type OwnProps = {|
	value: ?string,
	labelText?: string,
	disabled?: boolean,
	onChange: (type: ?CompanyType) => void,
|}

export type ComponentProps = { ...StateProps, ...DispatchProps, ...OwnProps }

class CompanyTypeSelector extends Component<ComponentProps> {
	componentDidMount() {
		if (!this.props.companyTypes) {
			this.props.loadCompanyTypes()
		}
	}

	handleChange = (option: OptionType) => {
		this.props.companyTypes &&
			this.props.onChange(this.props.companyTypes.find((type: CompanyType) => option.value === type.id))
	}

	render() {
		return (
			<SelectNext
				label={this.props.labelText}
				onChange={this.handleChange}
				disabled={this.props.disabled}
				value={this.props.value}
				options={
					this.props.companyTypes
						? this.props.companyTypes.map((type: CompanyType) => {
								const id = type.id ? type.id.toString() : ''
								return { value: id, label: type.name }
						  })
						: []
				}
				isSearchable
			/>
		)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyTypeSelector)
