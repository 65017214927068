/* @flow */
/** @jsx jsx */

import type { Node } from 'react'
import { jsx } from '@emotion/core'
import type { AutoTestProps } from 'utils/tests/autotest'
import { autoTestId } from 'utils/tests/autotest'
import styles from './Paper.css'

type Props = {|
	...AutoTestProps,
	children: Node,
	className?: string,
	circle?: boolean,
	style?: Object,
	arrow?: 'none' | 'top' | 'bottom' | 'left' | 'right',
	zDepth: number,
	id?: string,
	onClick?: () => void,
|}

export default function Paper(props: Props) {
	const depth = props.zDepth || 1

	return (
		<div
			{...autoTestId(props.autoTestId)}
			className={`${styles.root} ${styles[props.arrow || 'none']} ${props.className || ''}`}
			onClick={props.onClick}
			id={props.id}
			style={{
				boxShadow: `${depth}px ${depth}px 0 rgba(0, 0, 0, 0.08)`,
				borderRadius: props.circle ? '50%' : 3,
				...props.style,
			}}
		>
			{props.children}
		</div>
	)
}
