/* @flow */

import React, { Component } from 'react'
import Helmet from 'react-helmet'
import { withRouter } from 'react-router-dom'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import type { DocumentItem, WithRouterProps } from 'types'
import { childDocumentsRoute, rootDocumentsRoute, searchDocumentsRoute } from 'modules/document/routing/routes'
import DocumentList from '../containers/document-list'
import { openFile } from 'modules/file/domain'
import styles from './document-list-page.css'

type Props = {|
	...WithTranslateProps,
	...WithRouterProps,
	params: {
		itemId?: string,
		searchText?: string,
	},
|}

class DocumentListPage extends Component<Props> {
	onFolderOpen = (itemId?: string) => {
		if (itemId) {
			this.props.history.push(childDocumentsRoute(itemId))
		} else {
			this.props.history.push(rootDocumentsRoute())
		}
	}

	onFileOpen = (item: DocumentItem) => {
		item.fileId && item.name && openFile(item.fileId, item.name, 'popupPage')
	}

	onSearchTextChange = (searchText: string) => {
		if (searchText) {
			this.props.history.push(searchDocumentsRoute(searchText))
		} else if (this.props.match.params.searchText) {
			this.props.history.push(rootDocumentsRoute())
		}
	}

	render() {
		const { match } = this.props
		return (
			<div className={styles.root} id={'trivi-documents'}>
				<Helmet>
					<title>{this.props.t('document.headline')}</title>
				</Helmet>
				<DocumentList
					showAddButton
					showActions
					showFilter
					showBreadcrumbs
					showHeader
					showFileCheckbox
					showFolderCheckbox
					itemId={match.params.itemId}
					searchText={match.params.searchText}
					onSearchTextChange={this.onSearchTextChange}
					onNavigationChange={this.onFolderOpen}
					onFileOpen={this.onFileOpen}
				/>
			</div>
		)
	}
}

export default withRouter(withTranslate(DocumentListPage))
