/* @flow */
/** @jsx jsx */

import { Component } from 'react'
import { jsx } from '@emotion/core'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import TextField from 'components/TextField'

type Props = {|
	...WithTranslateProps,
	orderNo: ?string,
	readonly?: boolean,
	accountingDocumentId?: string,
	onOrderNumberChange: (orderNo: string) => void,
|}

type State = {|
	orderNo: string,
|}

class InvoiceHeaderOrderNo extends Component<Props, State> {
	state = {
		orderNo: this.props.orderNo || '',
	}

	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		if (this.state.orderNo !== nextProps.orderNo) {
			this.setState({
				orderNo: nextProps.orderNo || '',
			})
		}
	}

	onOrderNumberBlur = () => {
		if ((this.props.orderNo || '') != this.state.orderNo) this.props.onOrderNumberChange(this.state.orderNo)
	}

	onOrderNumberChange = (event: SyntheticInputEvent<HTMLInputElement>, orderNo: ?string) => {
		if (orderNo !== null && orderNo !== undefined) {
			this.setState({ orderNo })
		}
	}

	render() {
		const { t } = this.props
		if (this.props.readonly && (!this.state.orderNo || !this.state.orderNo.length)) return null

		return (
			<div css={style.root}>
				<div css={style.label}>
					{t('invoice.orderNoLabel')}
					{': '}
				</div>

				<TextField
					name="orderNo"
					disabled={this.props.readonly}
					value={this.state.orderNo}
					onBlur={this.onOrderNumberBlur}
					onChange={this.onOrderNumberChange}
					hintText={!this.props.readonly && t('invoice.orderNoHint')}
					autoWidth
					inline
					editCursor
				/>
			</div>
		)
	}
}

const style = {
	root: {
		display: 'flex',
		alignItems: 'flex-end',
		lineHeight: '24px',
		flex: '1 1 0',
	},
	label: {
		marginRight: 4,
	},
}

export default withTranslate(InvoiceHeaderOrderNo)
