// @flow
import type { BankAccount, AccountingDocumentBankAccount } from 'types'
import { BankAccount_AccountingDocumentBankAccount } from 'types/convertor'

export function BankAccounts_findById(bankAccounts: Array<BankAccount>, bankAccountId: string): ?BankAccount {
	return bankAccounts.find((ba: BankAccount) => {
		return ba.id === bankAccountId
	})
}

export function BankAccounts_nameById(bankAccounts: Array<BankAccount>, bankAccountId: string): ?string {
	const ba: ?BankAccount = BankAccounts_findById(bankAccounts, bankAccountId)
	return (ba && ba.name) || null
}

export function getDefaultBankAccount(bankAccounts: ?Array<BankAccount>): ?AccountingDocumentBankAccount {
	const mainAccount = (bankAccounts || []).find((account: BankAccount) => account.isMain)
	return mainAccount
		? BankAccount_AccountingDocumentBankAccount(mainAccount)
		: bankAccounts && bankAccounts[0]
		? BankAccount_AccountingDocumentBankAccount(bankAccounts[0])
		: null
}
