/* @flow */

import React, { Component } from 'react'
import type { InternalUser, UserProfile } from 'types'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import { getUserFullName } from 'modules/user/domain/user'
import InternalUserAdd from './internal-user-add'
import InternalUserEdit from './internal-user-edit'
import Edit from 'components/svg-icons/image/edit'
import Delete from 'components/svg-icons/action/delete'
import CopyToClipboard from 'components/copy-to-clipboard'
import IconMenu from 'components/icon-menu'
import MenuItem from 'components/menu-item'
import Button from 'components/button'
import Table from 'components/table/table'
import TableBody from 'components/table/table-body'
import TableRow from 'components/table/table-row'
import TableCell from 'components/table/table-cell'
import TableHeader from 'components/table/table-header'
import TableHeaderCell from 'components/table/table-header-cell'
import DeleteDialog from 'components/delete-dialog'
import styles from '../settings.css'

type Props = {|
	internalUsers: ?Array<InternalUser>,
	users: ?Array<UserProfile>,
	me: ?UserProfile,
	disabledTypes: Array<number>,
	loadInternalUsers: (internal?: boolean) => void,
	changeUserMembership: (userId: string, membershipType: number) => void,
	unassignUserFromOrganization: (userId: string) => void,
	...WithTranslateProps,
|}

type ComponentState = {
	editedUser: ?UserProfile,
	addDialogOpen: boolean,
}

class InternalUsers extends Component<Props, ComponentState> {
	state: ComponentState = {
		editedUser: null,
		addDialogOpen: false,
	}

	componentDidMount() {
		if (!this.props.internalUsers) {
			this.props.loadInternalUsers(true)
		}
		if (!this.props.users) {
			this.props.loadInternalUsers()
		}
	}

	handleUserEditRequestClose = () => {
		this.setState({
			editedUser: null,
		})
	}

	handleAddRequestClose = () => {
		this.setState({
			addDialogOpen: false,
		})
	}

	handleAddButtonClick = () => {
		this.setState({
			addDialogOpen: true,
		})
	}

	handleUserEditSave = (userId: string, membershipType: number) => {
		this.props.changeUserMembership(userId, membershipType)
	}

	handleAddUser = (userId: ?string, membershipType: ?number) => {
		userId && membershipType && this.props.changeUserMembership(userId, membershipType)
	}

	getUserRemove = (userId: string) => {
		return () => {
			DeleteDialog()
				.then(() => {
					this.props.unassignUserFromOrganization(userId)
				})
				.catch(() => {})
		}
	}

	getUserEdit = (user: UserProfile) => {
		return () => {
			this.setState({ editedUser: user })
		}
	}

	renderUsers() {
		const { t, users, me } = this.props
		const orgInternalUsers: ?Array<UserProfile> = users && users.filter((u: UserProfile) => u.isInternal)

		return (
			orgInternalUsers &&
			orgInternalUsers.map((user: UserProfile, index: number) => {
				const isDeleteVisible = me && me.id !== user.id

				return (
					<TableRow key={index}>
						<TableCell>
							<CopyToClipboard
								copyTooltip={t('application.emailCopy')}
								copiedTooltip={t('application.emailCopied')}
								textToCopy={user.email}
							>
								{user.email}
							</CopyToClipboard>
						</TableCell>
						<TableCell>{getUserFullName(user)}</TableCell>
						<TableCell>{user.membershipType && t(`membershipType.${user.membershipType}.singular`)}</TableCell>
						<TableCell>
							<IconMenu autoTestId="settings-users-actions" context>
								{[
									<MenuItem
										key={'edit'}
										primaryText={this.props.t('grid.action.edit')}
										icon={<Edit />}
										onClick={this.getUserEdit(user)}
										tall
									/>,
									isDeleteVisible ? (
										<MenuItem
											key={'delete'}
											primaryText={this.props.t('grid.action.delete')}
											icon={<Delete />}
											onClick={this.getUserRemove(user.id || '')}
											tall
										/>
									) : null,
								]}
							</IconMenu>
						</TableCell>
					</TableRow>
				)
			})
		)
	}

	render() {
		const { t, internalUsers, users } = this.props
		const orgInternalUsers: ?Array<UserProfile> = users && users.filter((u: UserProfile) => u.isInternal)

		const orgInternalUsersIds: Array<string> = orgInternalUsers
			? orgInternalUsers.map((u: UserProfile) => u.id || '')
			: []

		const usersToSelect: ?Array<InternalUser> =
			internalUsers && internalUsers.filter((u: InternalUser) => orgInternalUsersIds.indexOf(u.id || '') < 0)

		return (
			<div>
				<div className={styles.addButton}>
					{usersToSelect && usersToSelect.length > 0 ? (
						<Button
							autoTestId="settings-users-add-internal"
							primary
							labelText={t('settings.users.addInternal')}
							onClick={this.handleAddButtonClick}
						/>
					) : (
						<h3>Všichni uživatelé mají přiřazen typ</h3>
					)}
				</div>

				<Table>
					<TableHeader>
						<TableRow>
							<TableHeaderCell>{this.props.t('settings.users.email')}</TableHeaderCell>
							<TableHeaderCell>{this.props.t('settings.users.name')}</TableHeaderCell>
							<TableHeaderCell>{this.props.t('membershipType.label')}</TableHeaderCell>
							<TableHeaderCell width={35} />
						</TableRow>
					</TableHeader>
					<TableBody>{this.renderUsers()}</TableBody>
				</Table>
				<InternalUserAdd
					open={this.state.addDialogOpen}
					users={usersToSelect}
					onRequestClose={this.handleAddRequestClose}
					onAdd={this.handleAddUser}
					disabledTypes={this.props.disabledTypes}
				/>
				<InternalUserEdit
					open={this.state.editedUser != null}
					user={this.state.editedUser}
					onRequestClose={this.handleUserEditRequestClose}
					onSave={this.handleUserEditSave}
					disabledTypes={this.props.disabledTypes}
				/>
			</div>
		)
	}
}

export default withTranslate(InternalUsers)
