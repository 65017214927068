/* @flow */

import { connect } from 'react-redux'
import type { AccountingDocument, State } from 'types'
import { loadAccountingDocumentDefaults } from '../../../settings/actions'
import { ACCOUNTING_DOCUMENT_DIRECTION_TYPES } from 'types/convertor'
import { loadContact } from '../../../address-book'
import { getDefaultDueDate } from '../../selectors'
import InvoiceContactTemplate from '../../components/invoice-elements/payment-details-template'

type OwnProps = {|
	accDoc: AccountingDocument,
	onChange: (accDoc: AccountingDocument) => void,
	readonlyDueDate?: boolean,
|}

type StateProps = {|
	accDoc: AccountingDocument,
	accDocDefaultDueDate: ?number,
	readonlyDueDate?: boolean,
|}

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => {
	const accDocDefaultInterval: number = getDefaultDueDate(state, ownProps.accDoc)

	return {
		accDocDefaultDueDate: accDocDefaultInterval,
		accDoc: ownProps.accDoc,
		readonlyDueDate: ownProps.readonlyDueDate,
	}
}

type DispatchProps = {|
	onChange: (accDoc: AccountingDocument) => void,
	loadDefaults: () => void,
	refreshDefaults: () => void,
|}

const mapDispatchToProps = (dispatch: Dispatch<>, ownProps: OwnProps): DispatchProps => {
	function loadContactDefaults() {
		if (ownProps.accDoc.contact && ownProps.accDoc.contact.contactId) {
			dispatch(loadContact(ownProps.accDoc.contact.contactId))
		}
	}

	function loadAccDocDefaults() {
		if (ownProps.accDoc.direction) {
			dispatch(
				loadAccountingDocumentDefaults(null, ACCOUNTING_DOCUMENT_DIRECTION_TYPES[ownProps.accDoc.direction.toString()]),
			)
		}
	}

	return {
		onChange: (accDoc: AccountingDocument) => {
			ownProps.onChange(accDoc)
		},
		loadDefaults: () => {
			loadContactDefaults()
			loadAccDocDefaults()
		},
		refreshDefaults: () => {
			loadContactDefaults()
		},
	}
}

const mergeProps = (stateProps: StateProps, dispatchProps: DispatchProps) => {
	return {
		accDoc: stateProps.accDoc,
		accDocDefaultDueDate: stateProps.accDocDefaultDueDate,
		readonlyDueDate: stateProps.readonlyDueDate,
		onChange: dispatchProps.onChange,
		loadDefaults: dispatchProps.loadDefaults,
		refreshDefaults: dispatchProps.refreshDefaults,
	}
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(InvoiceContactTemplate)
