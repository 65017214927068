// @flow
/** @jsx jsx */

import { jsx } from '@emotion/core'
import Direction from 'components/direction'

export function directionRenderer(value: ?number) {
	return (
		<div css={style}>
			<Direction transparent issued={0 === value} received={1 === value} />
		</div>
	)
}

const style = {
	textAlign: 'left',
}
