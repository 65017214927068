/* @flow */
/* eslint-disable react/display-name, react/no-multi-comp */

import React, { Component } from 'react'
import { IconButton } from 'components'
import NavigationChevronLeft from 'components/svg-icons/navigation/chevron-left'
import { colors } from 'variables'

type Props = {|
	text: string,
	onGoBack?: () => void,
|}

type State = {|
	hovered: boolean,
|}

class SettingsHeading extends Component<Props, State> {
	state = {
		hovered: false,
	}

	handleMouseLeave = () => {
		this.setState({
			hovered: false,
		})
	}

	handleMouseEnter = () => {
		this.setState({
			hovered: true,
		})
	}

	render() {
		const styles = getStyles(this.props, this.state)

		return (
			<div
				style={styles.root}
				onMouseLeave={this.handleMouseLeave}
				onMouseEnter={this.handleMouseEnter}
				onClick={this.props.onGoBack}
			>
				{this.props.onGoBack && (
					<IconButton
						autoTestId="invoice-settings-heading-back"
						color={this.props.onGoBack && this.state.hovered ? colors.blue : colors.black}
						hoverColor={colors.blue}
						style={styles.icon}
						size={32}
					>
						<NavigationChevronLeft />
					</IconButton>
				)}
				{this.props.text}
			</div>
		)
	}
}

function getStyles(props: Props, state: State) {
	return {
		root: {
			fontSize: 24,
			lineHeight: '32px',
			fontWeight: 'bold',
			color: props.onGoBack && state.hovered ? colors.blue : colors.black,
			cursor: props.onGoBack ? 'pointer' : 'default',
			margin: '10px 0',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'flex-start',
			transition: 'all 250ms ease-in-out',
		},
		icon: {
			marginRight: 10,
			marginLeft: -10,
		},
	}
}

export default SettingsHeading
