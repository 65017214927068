/* @flow */

/** @jsx jsx */
import { jsx } from '@emotion/core'
import type { WithRouterProps } from 'types'
import { Component } from 'react'
import { bankAccountOrdersRoute, bankAccountStatementsRoute } from 'modules/bank/routing/routes'
import BankAccountDetail from '../containers/bank-account-detail'
import PaymentOrdersGrid from 'modules/data-grid-next/implemented/bank-paymentorders'
import StatementsGrid from 'modules/data-grid-next/implemented/bank-statements'
import styles from './bank-account.css'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import Helmet from 'react-helmet'
import { withRouter } from 'react-router-dom'
import TabSwitches from 'components/TabSwitches/TabSwitches'
import TabSwitch from 'components/TabSwitches/TabSwitch'
import Separator from 'components/Separator'

const PAYMENT_ORDERS: SelectedTabType = 'payment-orders'
const STATEMENTS: SelectedTabType = 'statements'

type SelectedTabType = 'payment-orders' | 'statements'

type State = {
	selectedTab: ?SelectedTabType,
}

type ComponentProps = WithTranslateProps &
	WithRouterProps & {|
		loadBankAccount: (id: string) => void,
	|}

class BankAccount extends Component<ComponentProps, State> {
	state: State = {
		selectedTab: null,
	}

	UNSAFE_componentWillMount() {
		this.props.loadBankAccount(this.props.match.params.id)
		this.init()
	}

	UNSAFE_componentWillReceiveProps(nextProps: ComponentProps) {
		if (this.props.match.params.id !== nextProps.match.params.id) {
			this.props.loadBankAccount(nextProps.match.params.id)
		}
		this.init()
	}

	init() {
		if (this.props.match.path.indexOf('/statements') > -1) {
			return this.setState({ selectedTab: STATEMENTS })
		}
		if (this.props.match.path.indexOf('/orders') > -1) {
			return this.setState({ selectedTab: PAYMENT_ORDERS })
		}
	}

	onTabChange = (value: SelectedTabType) => {
		const { id } = this.props.match.params
		switch (value) {
			case PAYMENT_ORDERS:
				this.props.history.push(bankAccountOrdersRoute(id))
				return this.setState({ selectedTab: PAYMENT_ORDERS })
			case STATEMENTS:
				this.props.history.push(bankAccountStatementsRoute(id))
				return this.setState({ selectedTab: STATEMENTS })
		}
	}

	render() {
		const { t } = this.props
		const { id } = this.props.match.params
		let content = null
		let title = null

		switch (this.state.selectedTab) {
			case PAYMENT_ORDERS:
				content = <PaymentOrdersGrid id={id} />
				title = t('bank.orders.headline')
				break
			case STATEMENTS:
				content = <StatementsGrid id={id} />
				title = t('bank.statements.headline')
				break
		}

		return (
			<div className={styles.root}>
				<Helmet>{title}</Helmet>
				<BankAccountDetail id={id} />
				<div
					style={{
						margin: '30px 0',
					}}
				>
					<Separator
						center={
							<TabSwitches
								autoTestId="direction-view-compact-tabs"
								onChange={this.onTabChange}
								value={this.state.selectedTab}
							>
								<TabSwitch key="payment-orders" value="payment-orders" label={t('bank.tabs.orders')} />
								<TabSwitch key="statements" value="statements" label={t('bank.tabs.statements')} />
							</TabSwitches>
						}
					/>
				</div>
				{content}
			</div>
		)
	}
}

export default withTranslate(withRouter(BankAccount))
