/* @flow */

import React, { Component } from 'react'
import InvoiceHeaderNo from '../invoice-elements/header-no'
import type { AccountingDocument, AccountingDocumentType, FormFieldErrorContext } from 'types'
import { emptyAccountingDocument } from 'types/empty'
import type { ValidationMessageType, ValidationValueType } from 'wrappers/validate'

type Props = {|
	accountingDocument: AccountingDocument,
	onChange: (accountingDocument: AccountingDocument) => void,
	type: AccountingDocumentType,
	direction: 'issued' | 'received',
	validationMessage?: ValidationMessageType,
	validationValue?: ValidationValueType,
	formFieldErrorContext?: FormFieldErrorContext,
|}

export default class InvoiceContactTemplate extends Component<Props> {
	onExplicitNoChange = (explicitNo: string) => {
		const newDocument: AccountingDocument = Object.assign(emptyAccountingDocument(), this.props.accountingDocument, {
			explicitNo,
			sequenceId: undefined,
		})
		this.props.onChange(newDocument)
	}

	onSequenceIdChange = (sequenceId: string) => {
		const newDocument: AccountingDocument = Object.assign(emptyAccountingDocument(), this.props.accountingDocument, {
			sequenceId,
			explicitNo: undefined,
		})
		this.props.onChange(newDocument)
	}

	onExternalNoChange = (externalNo: string) => {
		const newDocument: AccountingDocument = Object.assign(emptyAccountingDocument(), this.props.accountingDocument, {
			externalNo,
		})
		this.props.onChange(newDocument)
	}

	render() {
		return (
			<InvoiceHeaderNo
				disableExplicitNo
				direction={this.props.direction}
				type={this.props.type}
				formFieldErrorContext={this.props.formFieldErrorContext}
				cashRegisterId={this.props.accountingDocument.cashRegisterId}
				accountingDocumentId={this.props.accountingDocument.id || ''}
				accountingDocumentNo={this.props.accountingDocument.accountingDocumentNo || ''}
				accountingDocumentNoPreview={this.props.accountingDocument.accountingDocumentNoPreview || ''}
				sequenceId={this.props.accountingDocument.sequenceId}
				explicitNo={this.props.accountingDocument.explicitNo}
				externalNo={this.props.accountingDocument.externalNo}
				onExplicitNoChange={this.onExplicitNoChange}
				onSequenceIdChange={this.onSequenceIdChange}
				onExternalNoChange={this.onExternalNoChange}
				validationMessage={this.props.validationMessage}
				validationValue={this.props.validationValue}
				isTemplate
			/>
		)
	}
}
