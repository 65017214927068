/* @flow */

import { connect } from 'react-redux'
import type { Dispatch, UserAction } from 'types'
import { resetPassword } from '../actions'
import ResetPasswordForm from '../components/reset-password-form'

type DispatchProps = {|
	resetPassword: (email: string) => void,
|}

const mapDispatchToProps = (dispatch: Dispatch<UserAction>): DispatchProps => {
	return {
		resetPassword: (email: string): void => {
			dispatch(resetPassword(email))
		},
	}
}

export default connect(undefined, mapDispatchToProps)(ResetPasswordForm)
