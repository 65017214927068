/* @flow */
/** @jsx jsx */
/* eslint-disable react/no-multi-comp */

import { jsx } from '@emotion/core'
import moize from 'moize'
import type { AccountingDocument } from 'types'
import { type WithTranslateProps } from 'wrappers'
import { Column } from 'modules/data-grid-next/components'
import { DATA_GRID_CACHE_SIZE } from 'trivi-constants'
import { renderContent as renderCategoryContent } from './category-column'
import { renderContent as renderNoContent } from './accounting-document-no-column'

export type Props = {|
	...WithTranslateProps,
	width?: number | string,
	onClick?: (accountingDocument: AccountingDocument) => void,
|}

export default function getAccountingDocumentNoCategoryColumn(props: Props) {
	function columnRenderer(value: string, row: AccountingDocument) {
		return 9 === row.type
			? renderCategoryContent(
					{ width: props.width, t: props.t, onClickToPreview: props.onClick, openPreview: true },
					value,
					row,
			  )
			: renderNoContent({ width: props.width, t: props.t, onClick: props.onClick }, value, row)
	}

	const columnRendererCached = moize(columnRenderer, { maxSize: DATA_GRID_CACHE_SIZE })

	return (
		<Column
			title={props.t('accountingDocument.columns.accountingDocumentNo')}
			columnId="accountingDocumentNoCategory"
			key="accountingDocumentNoCategory"
			render={columnRendererCached}
			width={props.width}
			sortable
		/>
	)
}
