// @flow
/** @jsx jsx */

import { Fragment, type ComponentType } from 'react'
import { jsx } from '@emotion/core'
import { pure } from 'recompose'
import type { BaseChartProps } from './highcharts/highchart'
import { format, pickDefaultOptions } from 'components/charts/utils'
import PieChart, { type Props as PieChartProps, type Value } from './pie-chart'
import Loader from 'components/loader'
import { colors } from 'variables'

type Props = {|
	left: Value,
	right: Value,
	loading?: boolean,
	labelComponent?: ComponentType<CompareTwoChartLabelProps>,
	sumLabel?: string,
	sumClick?: () => any,
	subtitle?: string,
	tooltipFormat?: string,
	...BaseChartProps,
|}

function CompareTwoChart(props: Props) {
	return <PieChart {...convertOptions(props)} {...convertDefaultOptions(props)} />
}

export default pure(CompareTwoChart)

export function convertOptions(props: Props): PieChartProps {
	const empty = isDataEmpty(props)

	return {
		values: props.loading
			? null
			: [
					{ ...props.left, value: empty ? 0.01 : Math.abs(props.left.value) },
					{ ...props.right, value: empty ? 0.01 : Math.abs(props.right.value) },
			  ],
		innerSize: '70%',
		startAngle: getStartAngle(props.left.value, props.right.value),
		dataLabels: false,
		tooltipFormat: props.tooltipFormat,
	}
}

// eslint-disable-next-line react/no-multi-comp
function renderOverlay(props: Props) {
	const empty = false
	const styles = getStyles({ ...props, empty })
	const Label = props.labelComponent || CompareTwoChartLabel

	return (
		<div css={styles.overlay}>
			<div css={styles.header}>
				<p css={styles.title}>{props.title}</p>
				<p css={styles.subtitle}>{props.subtitle}</p>
			</div>
			{props.loading ? (
				<Loader visible transparent />
			) : (
				<Fragment>
					<div css={styles.overlayLeft} onClick={props.left.click}>
						<Label value={props.left.value} label={props.left.label} position={'left'} empty={empty} />
					</div>
					<div css={styles.overlayCenter} onClick={props.sumClick}>
						<Label
							value={props.left.value + props.right.value}
							label={props.sumLabel || ''}
							position={'center'}
							empty={empty}
						/>
					</div>
					<div css={styles.overlayRight} onClick={props.right.click}>
						<Label value={props.right.value} label={props.right.label} position={'right'} empty={empty} />
					</div>
				</Fragment>
			)}
		</div>
	)
}

function convertDefaultOptions(props: Props): BaseChartProps {
	const width = props.width || 300
	const height = props.height || 300
	return {
		...pickDefaultOptions(props),
		padding: [height / 9, width / 4, height / 9, width / 4],
		overlayContent: renderOverlay(props),
		title: '',
	}
}

export function getStartAngle(leftValue: number, rightValue: number): number {
	const min = Math.abs(Math.min(leftValue, rightValue))
	const max = Math.abs(Math.max(leftValue, rightValue))
	const total = min + max
	const ratio = total / min
	let angle = 360 / 2 / ratio + 90

	if (0 === leftValue && 0 === rightValue) {
		return -180
	}

	return Math.round(leftValue < rightValue || leftValue === rightValue ? -angle : angle)
}

function getStyles(props: {| ...Props, empty: boolean |}) {
	return {
		header: {
			position: 'absolute',
			top: 0,
			left: 0,
		},
		title: {
			fontSize: '1.2em',
			fontWeight: 'bold',
			padding: 0,
			margin: 0,
		},
		subtitle: {
			color: colors.grey500,
			fontSize: '0.8em',
			padding: 0,
			margin: 0,
		},
		overlay: {
			display: 'flex',
			alignItems: 'center',
			alignContent: 'center',
			justifyContent: 'space-between',
			height: '100%',
			pointerEvents: 'none',
			userSelect: 'none',
			background: 'none',
			position: 'relative',
		},
		overlayLeft: {
			width: '25%',
			overflow: 'hidden',
			pointerEvents: props.left.click && !props.empty ? 'auto' : 'none',
			cursor: props.left.click && !props.empty ? 'pointer' : 'auto',
		},
		overlayCenter: {
			textAlign: 'center',
			width: '25%',
			overflow: 'hidden',
			pointerEvents: props.sumClick && !props.empty ? 'auto' : 'none',
			cursor: props.sumClick && !props.empty ? 'pointer' : 'auto',
		},
		overlayRight: {
			textAlign: 'right',
			width: '25%',
			overflow: 'hidden',
			pointerEvents: props.right.click && !props.empty ? 'auto' : 'none',
			cursor: props.right.click && !props.empty ? 'pointer' : 'auto',
		},
	}
}

export type CompareTwoChartLabelProps = {
	label: string,
	value: number,
	valueFormat?: string,
	note?: string,
	empty?: boolean,
	position: 'left' | 'center' | 'right',
}

// eslint-disable-next-line react/no-multi-comp
export function CompareTwoChartLabel(props: CompareTwoChartLabelProps) {
	const styles = getLabelStyles(props)
	const formattedValue = format(props.valueFormat || '{value}', { value: props.value })

	return (
		<div css={styles.root}>
			<div css={styles.label}>{props.label}</div>
			<div css={styles.value}>{props.empty ? <div css={styles.empty}>-</div> : formattedValue}</div>
			<div css={styles.note}>{'center' !== props.position ? props.note : <br />}</div>
		</div>
	)
}

function getLabelStyles(props: CompareTwoChartLabelProps) {
	return {
		root: {
			textAlign: props.position,
		},
		label: {
			textOverflow: 'ellipsis',
			overflow: 'hidden',
			whiteSpace: 'nowrap',
		},
		value: {
			fontWeight: 'bold',
			textOverflow: 'ellipsis',
			overflow: 'hidden',
			whiteSpace: 'nowrap',
		},
		note: {
			fontSize: '0.8em',
			color: props.empty ? colors.grey300 : colors.grey500,
			textOverflow: 'ellipsis',
			overflow: 'hidden',
			whiteSpace: 'nowrap',
		},
		empty: {
			backgroundColor: props.empty ? colors.grey200 : colors.grey300,
			color: props.empty ? colors.grey200 : colors.grey300,
			width: '100px',
			margin: `5px ${'center' === props.position ? 'auto' : 0}`,
			float: 'right' === props.position ? 'right' : 'none',
		},
	}
}

function isDataEmptyOrNegative(props: Props): boolean {
	return 0 >= props.left.value && 0 >= props.right.value
}

function isDataEmpty(props: Props): boolean {
	return 0 === props.left.value && 0 === props.right.value
}
