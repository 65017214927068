/* @flow */

import React, { Component } from 'react'
import Helmet from 'react-helmet'
import type { WithRouterProps } from 'types'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import EditTemplate from '../containers/templates/edit-template'

class EditTemplatePage extends Component<{ ...WithRouterProps, ...WithTranslateProps }> {
	render() {
		return (
			<div>
				<Helmet>
					<title>{this.props.t('accountingDocument.templateEdit.headline')}</title>
				</Helmet>
				<EditTemplate templateId={this.props.match.params.id} />
			</div>
		)
	}
}

export default withTranslate(EditTemplatePage)
