/* @flow */

import { connect } from 'react-redux'
import type { State } from 'types'
import type { Props as ComponentProps } from '../pages/login'
import Login from '../pages/login'
import { splashScreenVisible } from 'modules/user/selectors'

const mapStateToProps = (state: State): ComponentProps => {
	return {
		changingPasswordComplete: state.user.changingPasswordComplete,
		splashScreenVisible: splashScreenVisible(state),
	}
}

export default connect(mapStateToProps)(Login)
