import React from 'react'
import SvgIcon from 'components/SvgIcon'
import pure from 'recompose/pure'

let GooglePlay = props => (
	<SvgIcon {...props}>
		<g id="GooglePlayIcon" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g id="Dashboard" transform="translate(-23.000000, -658.000000)">
				<g id="Group-14" transform="translate(23.000000, 658.000000)">
					<g id="Group-13">
						<g id="apple-outline">
							<g id="Group-15" transform="translate(-0.000000, 0.000000)">
								<rect
									id="Rectangle"
									stroke="#FFFFFF"
									strokeWidth="1.008"
									x="0.504"
									y="0.504"
									width="22.992"
									height="22.992"
									rx="3.528"
								/>
								<path
									d="M5.84783289,5.08317175 L12.9346463,12.0103002 L5.88195517,18.9978822 C5.67604148,18.8701404 5.56570951,18.592805 5.56384114,18.2345119 C5.56030108,17.5479599 5.50425008,6.65015058 5.50002167,5.83239133 C5.49795663,5.44021343 5.64368924,5.20099839 5.84783289,5.08317175 L5.84783289,5.08317175 Z M15.2715798,9.69496816 L6.65408231,5.09250932 C6.56204066,5.04331862 6.45751046,5.01338064 6.35327526,5 L13.2278815,11.7197767 L15.2715798,9.69496816 Z M15.6547916,14.0920968 L13.5230835,12.0082787 L15.6526282,9.89846941 L18.585374,11.4645819 C19.3079403,11.8502138 18.938397,12.332687 18.585374,12.5217487 L15.6547916,14.0920968 Z M6.46616395,19 C6.52614836,18.9779556 6.58898448,18.9497504 6.65408231,18.914903 L15.2733498,14.2962718 L13.2298482,12.2988022 L6.46616395,19 Z"
									id="Fill-95"
									fill="#FFFFFF"
								/>
							</g>
						</g>
					</g>
				</g>
			</g>
		</g>
	</SvgIcon>
)
GooglePlay = pure(GooglePlay)

export default GooglePlay
