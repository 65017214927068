/* @flow */

import { connect } from 'react-redux'
import { GRID_ID, DATA_TYPE } from './constants'
import type {
	DataGridReduxAction,
	Dispatch,
	Filter,
	State,
	UnmatchedPayment,
	UnmatchedPaymentMatchesRequestBulk,
} from 'types'
import { updateUnmatchedPaymentMatches, updateUnmatchedPayment, loadGridData } from '../../actions'
import type { Action as CommonAction } from 'modules/common/actions/action-types'
import type { Action as DataGridAction } from '../../actions/action-types'
import { refreshGridData } from 'modules/data-grid-next/actions'
import { currentUserIsInternal } from 'modules/user/selectors'
import UnmatchedPaymentsGrid from './unmatched-payments-grid-component'
import { todoBankRouteFiltered } from '../../../dashboard/routing/routes'
import { push } from 'react-router-redux'
import { changeFilter, clearGridFilterField } from 'modules/data-grid/actions'

type StateProps = {|
	currentUserIsInternal: boolean,
|}

const mapStateToProps = (state: State): StateProps => {
	return {
		currentUserIsInternal: currentUserIsInternal(state),
	}
}

type DispatchProps = {|
	updateMatches: (
		unmatchedPayment: UnmatchedPayment,
		unmatchedPaymentMatches: UnmatchedPaymentMatchesRequestBulk,
	) => Promise<boolean>,
	updateUnmatchedPayment: (
		unmatchedPayment: UnmatchedPayment,
		newUnmatchedPayment: { ...UnmatchedPayment & { isPaymentAlreadyMatched?: boolean } },
	) => Promise<boolean>,
	onTabChange: (direction: string, isInternal?: boolean) => void,
	goToProcessingTab: (isInternal?: boolean) => void,
|}

function mapDispatchToProps(dispatch: Dispatch<CommonAction | DataGridAction | DataGridReduxAction>): DispatchProps {
	return {
		updateMatches: async (
			unmatchedPayment: UnmatchedPayment,
			matches: UnmatchedPaymentMatchesRequestBulk,
		): Promise<boolean> => {
			try {
				await dispatch(updateUnmatchedPaymentMatches(unmatchedPayment, matches))
				await dispatch(loadGridData(DATA_TYPE, GRID_ID))
				await dispatch(refreshGridData(DATA_TYPE, GRID_ID, GRID_ID))
				return true
			} catch (e) {
				return false
			}
		},
		updateUnmatchedPayment: async (
			unmatchedPayment: UnmatchedPayment,
			newUnmatchedPayment: { ...UnmatchedPayment & { isPaymentAlreadyMatched?: boolean } },
		) => {
			try {
				await dispatch(updateUnmatchedPayment(unmatchedPayment, newUnmatchedPayment))
				await dispatch(loadGridData(DATA_TYPE, GRID_ID))
				await dispatch(refreshGridData(DATA_TYPE, GRID_ID, GRID_ID))
				return true
			} catch (e) {
				return false
			}
		},
		goToProcessingTab: (isInternal?: boolean) => {
			const waitingForFilter: Filter = [
				{ field: 'waitingFor', value: isInternal ? 1 : 2 },
				{ field: 'isPaymentAlreadyMatched', value: false },
			]

			dispatch(changeFilter('unmatchedPayments', waitingForFilter))
			dispatch(push(todoBankRouteFiltered(null, 'processing')))
		},
		onTabChange: (direction: string, isInternal?: boolean) => {
			if (!direction) {
				direction = 'waiting'
			}

			const waitingForFilter1: Filter = [
				{ field: 'waitingFor', value: 1 },
				{ field: 'isPaymentAlreadyMatched', value: false },
			]
			const waitingForFilter2: Filter = [
				{ field: 'waitingFor', value: 2 },
				{ field: 'isPaymentAlreadyMatched', value: false },
			]

			switch (direction) {
				case 'waiting':
					isInternal
						? dispatch(changeFilter('unmatchedPayments', waitingForFilter2))
						: dispatch(changeFilter('unmatchedPayments', waitingForFilter1))
					break
				case 'processing':
					isInternal
						? dispatch(changeFilter('unmatchedPayments', waitingForFilter1))
						: dispatch(changeFilter('unmatchedPayments', waitingForFilter2))
					break
				case 'done':
					dispatch(clearGridFilterField('unmatchedPayments', 'waitingFor'))
					dispatch(changeFilter('unmatchedPayments', [{ field: 'isPaymentAlreadyMatched', value: true }]))
					break
				default:
			}
			dispatch(push(todoBankRouteFiltered(null, direction)))
		},
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(UnmatchedPaymentsGrid)
