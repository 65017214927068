/* @flow */
/** @jsx jsx */

import { Component } from 'react'
import { jsx } from '@emotion/core'
import memoize from 'memoize-one'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import Tooltip from 'components/tooltip'

type Props = {|
	...WithTranslateProps,
	textToCopy?: ?string,
	copyTooltip?: string,
	copiedTooltip?: string,
	hoverStyle?: Object,
	children: ?string | React$Element<any>,
|}

type State = {|
	copied: boolean,
|}

class CopyToClipboard extends Component<Props, State> {
	state = {
		copied: false,
	}

	input: ?HTMLInputElement = null

	bindInput = (element: ?HTMLInputElement) => {
		this.input = element
	}

	renderInput = () => {
		const { textToCopy } = this.props
		const styles = this.getStyles(this.props.hoverStyle)

		return <input type={'text'} value={textToCopy} css={styles.input} readOnly ref={this.bindInput} />
	}

	handleClick = () => {
		if (this.input) {
			this.input.select()
			document.execCommand('Copy')
			this.setState({ copied: true })
		}
	}

	handleMouseLeave = () => {
		this.setState({ copied: false })
	}

	getStyles = memoize((hoverStyle?: Object) => {
		return {
			root: {
				cursor: 'pointer',
				overflow: 'hidden',
				textOverflow: 'ellipsis',
				whiteSpace: 'nowrap',
				'&:hover': {
					...hoverStyle,
				},
			},
			mouse: {
				overflow: 'hidden',
			},
			inactiveRoot: {
				overflow: 'hidden',
				textOverflow: 'ellipsis',
				whiteSpace: 'nowrap',
			},
			wrapper: {
				maxWidth: '100%',
			},
			input: {
				opacity: 0,
				position: 'absolute',
				zIndex: -11111,
			},
		}
	})

	render() {
		const { t, copyTooltip, copiedTooltip, textToCopy, children } = this.props
		const tooltipCopy = copyTooltip && copyTooltip.length > 0 ? copyTooltip : t('copyToClipboard.tooltipCopy')
		const tooltipCopied = copiedTooltip && copiedTooltip.length > 0 ? copiedTooltip : t('copyToClipboard.tooltipCopied')

		const label = this.state.copied ? tooltipCopied : tooltipCopy
		const renderTooltip = textToCopy && textToCopy.length > 0
		const styles = this.getStyles(this.props.hoverStyle)

		return renderTooltip ? (
			<div onMouseLeave={this.handleMouseLeave} css={styles.mouse}>
				<Tooltip wrapperStyle={styles.wrapper} label={label}>
					<div css={styles.root} onClick={this.handleClick}>
						{children}
					</div>
				</Tooltip>
				{this.renderInput()}
			</div>
		) : (
			<div css={styles.inactiveRoot}>{children}</div>
		)
	}
}

export default withTranslate(CopyToClipboard)
