// @flow
import { connect } from 'react-redux'
import type { State, PeriodParams, CashedBillings } from 'types'
import {
	getBillingsLoadingFlag,
	organizationNamesSelector,
	getBillingsCash,
	currentBillingPeriodSelector,
} from '../../selectors'
import type { Action as InternalAction } from '../../actions/action-types'
import BillingPage from 'modules/internal/components/billing/billing-page'
import type { Props as ComponentProps } from 'modules/internal/components/billing/billing-page'
import { changeSelectedPeriod, fetchBillingReport } from '../../actions'

type StateProps = {|
	cashedBillings: CashedBillings,
	loading: boolean,
	organizationNames: Array<string>,
	currentPeriod: ?string,
|}

const mapStateToProps = (state: State): StateProps => {
	return {
		cashedBillings: getBillingsCash(state),
		loading: getBillingsLoadingFlag(state),
		organizationNames: organizationNamesSelector(state),
		currentPeriod: currentBillingPeriodSelector(state),
	}
}

type DispatchProps = {|
	changePeriodParams: (value: string) => void,
	fetchBillingReport: (params: PeriodParams) => void,
|}

const mapDispatchToProps = (dispatch: Dispatch<InternalAction>): DispatchProps => {
	return {
		changePeriodParams: (value: string) => {
			dispatch(changeSelectedPeriod(value))
		},
		fetchBillingReport: (params: PeriodParams) => {
			dispatch(fetchBillingReport(params))
		},
	}
}

const mergeProps = (stateProps: StateProps, dispatchProps: DispatchProps): ComponentProps => {
	return {
		loading: stateProps.loading,
		organizationNames: stateProps.organizationNames,
		currentPeriod: stateProps.currentPeriod,
		changePeriodParams: (value: PeriodParams) => {
			const periodString = `${value.periodMonth}/${value.periodYear}`
			if (!stateProps.cashedBillings[periodString]) {
				dispatchProps.fetchBillingReport(value)
			}
			dispatchProps.changePeriodParams(periodString)
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(BillingPage)
