// @flow
import { createSelector } from 'reselect'
import type {
	State,
	BillingReportGlobalResponse,
	BillingReportGlobalResponseOrganization,
	CashedBillings,
	SortedAccountingDocuments,
	SortedStatements,
} from 'types'
import { formatPeriodToString, sortDocumentsBySource, sortStatements } from '../domain/billings'
import { createEmptySortedDocumentsObject, createEmptySortedStatementsObject } from '../types/empty'

function getSelectedBillingPeriod(state: State): ?string {
	return state.internalDashboard.billings.selectedPeriod
}

export const getBillingsCash = (state: State): CashedBillings => {
	return state.internalDashboard.billings.cashedBillings
}

export function getBillingsLoadingFlag(state: State): boolean {
	return state.internalDashboard.billings.fetching
}

const getSelectedBilling = createSelector(
	getBillingsCash,
	getSelectedBillingPeriod,
	(billings: CashedBillings, period: ?string): ?BillingReportGlobalResponse => {
		if (period) {
			return billings[period]
		}
		return null
	},
)

export const organizationNamesSelector = createSelector(
	getSelectedBilling,
	(billings: ?BillingReportGlobalResponse): Array<string> => {
		return billings && billings.organizations
			? billings.organizations.map((i: BillingReportGlobalResponseOrganization) => i.organizationName || '')
			: []
	},
)

export const currentBillingPeriodSelector = createSelector(
	getSelectedBilling,
	(billings: ?BillingReportGlobalResponse): ?string => {
		const { periodFrom, periodTo } = billings || {}
		if (periodFrom && periodTo) {
			return formatPeriodToString(periodFrom, periodTo)
		}
	},
)

export const sortedDocumentsSelector = (index: number) =>
	createSelector(
		_organizationByIndexSelector(index),
		(organization: ?BillingReportGlobalResponseOrganization): SortedAccountingDocuments => {
			const documents =
				organization && organization.accountingDocuments && organization.accountingDocuments.accountingDocuments
					? organization.accountingDocuments
					: null
			if (documents && documents.accountingDocuments) {
				return {
					...documents.accountingDocuments.reduce(sortDocumentsBySource, createEmptySortedDocumentsObject()),
					priceForDocument: documents.price || 0,
				}
			} else {
				return createEmptySortedDocumentsObject()
			}
		},
	)

export const sortedStatementsSelector = (index: number) =>
	createSelector(
		_organizationByIndexSelector(index),
		(organization: ?BillingReportGlobalResponseOrganization): SortedStatements => {
			const statements =
				organization && organization.statements && organization.statements.statements ? organization.statements : null
			if (statements && statements.statements) {
				return {
					...statements.statements.reduce(sortStatements, createEmptySortedStatementsObject()),
					priceForStatement: statements.price || 0,
				}
			} else {
				return createEmptySortedStatementsObject()
			}
		},
	)

export const totalPriceForOrganizationSelector = (index: number) =>
	createSelector(
		_organizationByIndexSelector(index),
		(organization: ?BillingReportGlobalResponseOrganization): number => {
			return organization && organization.totalPrice ? organization.totalPrice : 0
		},
	)

const _organizationByIndexSelector = (index: number) =>
	createSelector(
		getSelectedBilling,
		(billings: ?BillingReportGlobalResponse): ?BillingReportGlobalResponseOrganization => {
			return billings && billings.organizations ? billings.organizations[index] : null
		},
	)
