/* @flow */

import { connect } from 'react-redux'
import type { State, Country, CommonAction } from 'types'
import AccountingSettings from 'modules/settings/components/accounting/accounting-settings'
import { getAllCountries } from '../../../common/selectors'
import { loadCountries } from 'modules/common/actions'

type StateProps = {
	countries: ?Array<Country>,
}

const mapStateToProps = (state: State): StateProps => {
	return {
		countries: getAllCountries(state),
	}
}

type DispatchProps = {
	loadCountries: () => void,
}

const mapDispatchToProps = (dispatch: Dispatch<CommonAction>): DispatchProps => {
	return {
		loadCountries: () => {
			dispatch(loadCountries())
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountingSettings)
