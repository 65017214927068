//@flow
import React, { Component } from 'react'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import type { Signature, Signatures } from 'types'
import IconButton from 'components/icon-button'
import Paper from 'components/Paper'
import ActionDelete from 'components/svg-icons/action/delete'
import SignatureThumbnail from './signature-thumbnail'
import styles from './signatures-list.css'

type Props = {|
	...WithTranslateProps,
	signatures: ?Signatures,
	removeSignature: (signatureId: string) => void,
|}

class SignaturesList extends Component<Props> {
	renderSignature = (signature: Signature) => {
		const { removeSignature } = this.props
		const removeHandler = removeSignature
			? () => {
					if (signature.id != null) removeSignature(signature.id)
			  }
			: () => {}

		const deleteButton = removeSignature ? (
			/* eslint-disable-next-line react/jsx-no-bind */
			<IconButton autoTestId="settings-signatures-list-delete" circled onClick={removeHandler} size={32}>
				<ActionDelete size={20} color="#7F7F7F" />
			</IconButton>
		) : null

		return (
			signature.fileId && (
				<div className={styles.itemWrapper} key={signature.id}>
					<Paper zDepth={3} className={styles.item}>
						<SignatureThumbnail fileId={signature.fileId} />
					</Paper>
					<div className={styles.actions}>{deleteButton}</div>
				</div>
			)
		)
	}

	render() {
		if (!this.props.signatures || !this.props.signatures.length) return null
		return <div className={styles.root}>{this.props.signatures.map(this.renderSignature)}</div>
	}
}

export default withTranslate(SignaturesList)
