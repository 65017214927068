/* @flow */
/** @jsx jsx */

import { Component } from 'react'
import { jsx } from '@emotion/core'
import BankIcon from 'components/svg-icons/trivi/menu/bank'
import TaxIcon from 'components/svg-icons/action/account-balance'
import { colors } from 'variables'

const ICONS = {
	bank: {
		icon: BankIcon,
		color: colors.blue,
	},
	tax: {
		icon: TaxIcon,
		color: colors.orange,
	},
	'tax.income': {
		icon: TaxIcon,
		color: colors.green,
	},
	default: {
		icon: BankIcon,
		color: colors.blue,
	},
}

type Props = {|
	icon: ?string,
	size: number,
|}

class CardIcon extends Component<Props> {
	render() {
		const Icon = ICONS[this.props.icon || 'default'].icon
		const styles = getStyles(this.props)

		return (
			<div css={styles.icon}>
				<Icon disabled color={colors.white} size={Math.round(this.props.size * 0.6)} />
			</div>
		)
	}
}

function getStyles(props: Props) {
	return {
		icon: {
			width: props.size,
			height: props.size,
			backgroundColor: ICONS[props.icon || 'default'].color,
			borderRadius: '50%',
			flex: `0 0 ${props.size}px`,
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		},
	}
}

export default CardIcon
