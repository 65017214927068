/* @flow */
/** @jsx jsx */

import { jsx } from '@emotion/core'
import type { Base64Image } from 'types'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import Tooltip from 'components/tooltip'
import { colors } from 'variables'

type Props = {|
	...WithTranslateProps,
	binary: ?Base64Image,
	showPlaceholder?: boolean,
|}

function Logo(props: Props) {
	return (
		<div css={styles.root}>
			{props.binary && (
				<div css={styles.logo}>
					<Tooltip label={props.showPlaceholder && props.t('settings.basics.deleteLogoFromInvoice')}>
						<div css={styles.imageWrapper}>
							<img css={styles.image} src={props.binary} />
						</div>
					</Tooltip>
				</div>
			)}
			{!props.binary && props.showPlaceholder && (
				<div css={styles.dropzone}>
					<span css={styles.dropzoneHint}>{props.t('settings.basics.uploadLogoHint')}</span>
				</div>
			)}
		</div>
	)
}

const styles = {
	root: {
		flex: '1 0 50%',
	},
	dropzone: {
		height: 100,
		marginLeft: 15,
		flex: '0 0 50%',
		borderRadius: 3,
		borderWidth: 1,
		display: 'flex',
		textAlign: 'center',
		borderStyle: 'dashed',
		borderColor: colors.grey400,
		justifyContent: 'space-around',
		alignContent: 'center',
		alignItems: 'center',
		padding: '9px 20px',
	},
	logo: {
		paddingLeft: 15,
		flex: '0 0 50%',
		position: 'relative',
	},
	imageWrapper: {
		height: 100,
		maxHeight: 100,
		minHeight: 100,
		overflow: 'hidden',
	},
	image: {
		width: '100%',
		height: '100%',
		objectFit: 'scale-down',
	},
	dropzoneHint: {
		color: colors.grey900,
		fontSize: 10,
		lineHeight: '12px',
	},
}

export default withTranslate(Logo)
