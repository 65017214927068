/* @flow */

import { type FieldSections } from '../types'

export const fieldSections: FieldSections = [
	{
		name: 'fields',
		isRepeatable: false,
	},
	{
		name: 'bankAccounts',
		isRepeatable: true,
	},
	{
		name: 'lineItems',
		isRepeatable: true,
	},
	{
		name: 'recapItems',
		isRepeatable: true,
	},
]
