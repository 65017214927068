/* @flow */
import TestTool from './test-tool'
import { connect } from 'react-redux'
import { userHasAccess } from 'permissions'
import { getUserEnabledTestPermissions, getUserDisabledTestPermissions } from 'modules/features/selectors'
import { AVAILABLE_PERMISSIONS } from 'utils/features'
import {
	enableTestPermission,
	disableTestPermission,
	removeTestPermission,
	removeAllTestPermissions,
} from 'modules/features/actions'

import type { Dispatch, State, FeaturesAction, Permission } from 'types'

type StateProps = {|
	availablePermissions: Array<Permission>,
	serverEnabledPermissions: Array<Permission>,
	userEnabledPermissions: Array<Permission>,
	userDisabledPermissions: Array<Permission>,
|}

type DispatchProps = {|
	onEnablePermission: (permission: Permission) => void,
	onDisablePermission: (permission: Permission) => void,
	onRemovePermission: (permission: Permission) => void,
	onRemoveAllPermissions: () => void,
	onReloadPress: () => void,
|}

const mapStateToProps = (state: State): StateProps => {
	return {
		availablePermissions: AVAILABLE_PERMISSIONS,
		serverEnabledPermissions: AVAILABLE_PERMISSIONS.filter((p: Permission) => userHasAccess(state, p)),
		userEnabledPermissions: getUserEnabledTestPermissions(state),
		userDisabledPermissions: getUserDisabledTestPermissions(state),
	}
}

const mapDispatchToProps = (dispatch: Dispatch<FeaturesAction>): DispatchProps => {
	return {
		onEnablePermission: (permission: Permission) => {
			dispatch(enableTestPermission(permission))
		},
		onDisablePermission: (permission: Permission) => {
			dispatch(disableTestPermission(permission))
		},
		onRemovePermission: (permission: Permission) => {
			dispatch(removeTestPermission(permission))
		},
		onRemoveAllPermissions: () => {
			dispatch(removeAllTestPermissions())
		},
		onReloadPress: () => {
			window.location.reload()
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(TestTool)
