/* @flow */

import { connect } from 'react-redux'
import type { BootstrapAction, State } from 'types'
import { getAccountingDocumentState } from '../../selectors'
import { currentUserIsInternal } from 'modules/user/selectors'
import { getCurrentOrganizationNoteExtraction } from '../../../settings/selectors'
import { loadOrganizationNoteExtraction } from '../../../settings/actions'
import { isAccountingDocumentExtractable } from '../../domain/accounting-document'
import ExtractionNote from 'modules/accounting-document/components/invoice-elements/extraction-note'

type OwnProps = {|
	accountingDocumentId: string,
|}

type StateProps = {|
	note: ?string,
	internal: boolean,
	extractable: boolean,
|}

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => {
	return {
		note: getCurrentOrganizationNoteExtraction(state),
		internal: currentUserIsInternal(state),
		extractable: isAccountingDocumentExtractable(getAccountingDocumentState(state, ownProps.accountingDocumentId)),
	}
}

type DispatchProps = {|
	getOrganizationNoteExtraction: () => void,
|}

const mapDispatchToProps = (dispatch: Dispatch<BootstrapAction>): DispatchProps => {
	return {
		getOrganizationNoteExtraction: () => {
			dispatch(loadOrganizationNoteExtraction())
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ExtractionNote)
