/* @flow */

import { connect } from 'react-redux'
import type { Dispatch, DocumentAction, DocumentItem, State } from 'types'
import { loadDocuments } from '../actions'
import DocumentTreeView from '../components/document-tree-view'
import { buildDocumentTree } from '../selectors'

type OwnProps = {|
	onDocumentClick: (uniqueId: ?string, isFolder: boolean) => void,
	selected?: Array<string>,
	hideFolderSystemTrivi?: boolean,
|}

type StateProps = {|
	documents: Array<DocumentItem>,
	selected?: Array<string>,
	hideFolderSystemTrivi?: boolean,
|}

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => {
	return {
		documents: buildDocumentTree(state),
		selected: ownProps.selected,
		hideFolderSystemTrivi: ownProps.hideFolderSystemTrivi,
	}
}

type DispatchProps = {|
	loadDocuments: (parentId?: string) => void,
|}

const mapDispatchToProps = (dispatch: Dispatch<DocumentAction>): DispatchProps => {
	return {
		loadDocuments: (parentId?: string) => {
			dispatch(loadDocuments(parentId, 'ASC', 1, 100))
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(DocumentTreeView)
