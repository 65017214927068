/* @flow */

import type { Dispatch, WithRouterProps, TaskAction } from 'types'
import React, { Component } from 'react'
import TaskEditContainer from '../containers/task-edit'
import { connect } from 'react-redux'
import { loadTask } from '../actions'
import { push, goBack } from 'react-router-redux'
import { tasksRoute } from 'modules/task/routing/routes'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import Helmet from 'react-helmet'
import { refererRoute } from 'utils'

type Props = {}

class TaskList extends Component<WithTranslateProps & Props & DispatchProps & WithRouterProps> {
	UNSAFE_componentWillReceiveProps(nextProps: WithTranslateProps & Props & DispatchProps & WithRouterProps) {
		if (this.props.match.params.id !== nextProps.match.params.id) {
			this.reload(nextProps.match.params.id)
		}
	}

	componentDidMount() {
		this.reload(this.props.match.params.id)
	}

	reload(id: string) {
		this.props.loadTask(id)
	}

	render() {
		return (
			<div>
				<Helmet>
					<title>{this.props.t('task.edit.header')}</title>
				</Helmet>
				<TaskEditContainer onClose={this.props.close} id={this.props.match.params.id} />
			</div>
		)
	}
}

type DispatchProps = {|
	loadTask: string => void,
	close: () => void,
|}

function mapDispatchToProps(dispatch: Dispatch<TaskAction>): DispatchProps {
	return {
		loadTask: (id: string) => {
			dispatch(loadTask(id))
		},
		close: () => {
			if (refererRoute()) {
				dispatch(goBack())
				return
			}
			dispatch(push(tasksRoute()))
		},
	}
}

export default connect(null, mapDispatchToProps)(withTranslate(TaskList))
