import React from 'react'
import SvgIcon from 'components/SvgIcon'
import pure from 'recompose/pure'

let DirectionArrow = props => (
	<SvgIcon {...props}>
		<polygon
			stroke="none"
			points="12.9824562 4 12.9824562 16.1637428 18.5964913 10.5497076 20 12 12 20 4 12 5.40350878 10.5964913 11.0175438 16.1637428 11.0175438 4"
		/>
	</SvgIcon>
)
DirectionArrow = pure(DirectionArrow)

export default DirectionArrow
