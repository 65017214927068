//@flow
import type { State } from 'store/root-reducer'
import type { Dispatch } from 'types'
import type { Action, Listener } from 'utils/events'
import { changeCurrentOrganization } from 'modules/user/actions'

const listeners: Array<Listener> = [
	{
		actionType: 'LOGOUT',
		listener: (action: Action, getState: () => State, dispatch: Dispatch<any>) => {
			dispatch(changeCurrentOrganization(undefined))
		},
	},
]

export default listeners
