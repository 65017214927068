/* @flow */

import { connect } from 'react-redux'
import type { CommonAction, Dispatch, NotificationTypeType } from 'types'
import { type ComponentType, type ElementConfig, type Component } from 'react'

type NotifyOptions = {|
	withRef?: boolean,
|}

type DispatchProps = {|
	notify: (message: string, type: NotificationTypeType) => void,
	clearNotification: () => void,
|}

function mapDispatchToProps(dispatch: Dispatch<CommonAction>): DispatchProps {
	return {
		notify: (message: string, type: NotificationTypeType) => {
			dispatch({
				type: 'SET_NOTIFICATION',
				notification: {
					message,
					type,
				},
			})
		},
		clearNotification: () => {
			dispatch({ type: 'CLEAR_NOTIFICATION' })
		},
	}
}

export type Props = DispatchProps

export default function withNotify<P: any, C: ComponentType<P>>(
	WrappedComponent: C,
	args?: ?NotifyOptions,
): Class<Component<$Diff<ElementConfig<C>, Props>, any>> {
	return connect(undefined, mapDispatchToProps, undefined, {
		withRef: true,
		...args,
	})(WrappedComponent)
}
