import React from 'react'
import SvgIcon from 'components/SvgIcon'
import pure from 'recompose/pure'

let Upload = props => {
	props = Object.assign({}, { viewBox: '0 0 27 22' }, { style: { width: 27, height: 22, transition: 'none' } }, props)
	return (
		<SvgIcon {...props}>
			<g stroke="none" strokeWidth="1" transform="translate(3.000000, 0.000000)" fillRule="evenodd">
				<path d="M12.68,11.5 L15.18,11.5 L11,7.36 L6.82,11.5 L9.32,11.5 L9.32,14.86 L12.68,14.86 L12.68,11.5 Z M17.14,9.04 C17.8466702,9.0933336 18.4933304,9.3099981 19.08,9.69 C19.6666696,10.0700019 20.1333316,10.5666636 20.48,11.18 C20.8266684,11.7933364 21,12.4599964 21,13.18 C21,13.9400038 20.8133352,14.6399968 20.44,15.28 C20.0666648,15.9200032 19.5600032,16.4266648 18.92,16.8 C18.2799968,17.1733352 17.5800038,17.36 16.82,17.36 L6,17.36 C5.0933288,17.36 4.2566705,17.1366689 3.49,16.69 C2.7233295,16.2433311 2.1166689,15.6366705 1.67,14.87 C1.2233311,14.1033295 1,13.2666712 1,12.36 C1,11.5199958 1.1966647,10.7366703 1.59,10.01 C1.9833353,9.2833297 2.5199966,8.6900023 3.2,8.23 C3.8800034,7.7699977 4.6333292,7.4933338 5.46,7.4 C5.993336,6.3733282 6.7466618,5.5533364 7.72,4.94 C8.720005,4.3133302 9.8133274,4 11,4 C12.000005,4 12.933329,4.2199978 13.8,4.66 C14.6533376,5.0866688 15.3733304,5.6833295 15.96,6.45 C16.5466696,7.2166705 16.939999,8.0799952 17.14,9.04 Z" />
			</g>
		</SvgIcon>
	)
}
Upload = pure(Upload)

export default Upload
