/* @flow */

import React, { Component } from 'react'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import type { OrganizationMember, OrganizationMembers, Organization } from 'types'
import Features from 'utils/features'
import { Menu, MenuItem, Popover } from 'components'
import Add from 'components/svg-icons/trivi/add'
import DownArrow from 'components/svg-icons/navigation/arrow-drop-down'
import UpArrow from 'components/svg-icons/navigation/arrow-drop-up'
import { colors } from 'variables'
import styles from './organization-switcher.css'

type Props = {|
	...WithTranslateProps,
	organizations: ?OrganizationMembers,
	organizationDetail: ?Organization,
	createNewOrganization: ?boolean,
	value: ?string,
	compact: boolean,
	onChange?: (organizationId: string) => void,
|}

type State = {|
	anchorEl: ?EventTarget,
	open: boolean,
|}

class OrganizationSwitcher extends Component<Props, State> {
	anchorOrigin = { horizontal: 'left', vertical: 'bottom' }
	targetOrigin = { horizontal: 'left', vertical: 'top' }

	state = {
		anchorEl: null,
		open: false,
	}

	handleOnClick = () => {
		this.setState({
			anchorEl: this.refs.root,
			open: true,
		})
	}

	handleOnRequestClose = () => {
		this.setState({
			open: false,
		})
	}

	handleChange = (event: SyntheticEvent<HTMLElement>, value: any) => {
		value && this.props.onChange && this.props.onChange(value)
		this.setState({
			open: false,
		})
	}

	getName(value: ?string): ?string {
		if (!this.props.organizations || this.props.organizations.length < 1) {
			return null
		}
		const organization = this.props.organizations.find((organization: OrganizationMember) => {
			return organization.organizationId === value
		})
		return organization && organization.organizationName
	}

	render() {
		const { organizations, organizationDetail, value, createNewOrganization, compact, t } = this.props
		const { open } = this.state

		if (!organizations || !organizations.length) return null

		return (
			<div className={open ? styles.open : styles.normal} ref="root" onClick={this.handleOnClick}>
				<div className={compact ? styles.compactName : styles.name}>
					{this.getName(value) || (organizationDetail && organizationDetail.name)}
				</div>
				<span className={compact ? styles.compactButton : styles.button}>
					{open ? (
						<UpArrow
							style={compact ? style.compactIcon : null}
							size={compact ? 16 : 24}
							color={colors.white}
							hoverColor={colors.white}
						/>
					) : (
						<DownArrow
							style={compact ? style.compactIcon : null}
							size={compact ? 16 : 24}
							color={colors.white}
							hoverColor={colors.white}
						/>
					)}
				</span>
				<Popover
					open={open}
					anchorEl={this.state.anchorEl}
					anchorOrigin={this.anchorOrigin}
					targetOrigin={this.targetOrigin}
					zDepth={7}
					className={styles.popover}
					arrow={'topLeft'}
					useLayerForClickAway
					onRequestClose={this.handleOnRequestClose}
				>
					<Menu onChange={this.handleChange} value={value}>
						{[
							...organizations.map((organization: OrganizationMember) => (
								<MenuItem
									key={organization.organizationId}
									value={organization.organizationId}
									primaryText={organization.organizationName}
									tall
								/>
							)),
							createNewOrganization && Features.isEnabled('addOrganizationButton') ? (
								<MenuItem
									key="new"
									value="new"
									icon={<Add />}
									primaryText={t('user.organizationSelect.createNewOrganization')}
									tall
								/>
							) : null,
						]}
					</Menu>
				</Popover>
			</div>
		)
	}
}

const style = {
	compactIcon: {
		position: 'absolute',
		top: -2,
		left: -2,
	},
}

export default withTranslate(OrganizationSwitcher)
