//@flow

import { find } from 'lodash-es'
import type { Contact, State } from 'types'
import type { ContactItem } from 'modules/address-book/reducers'

export const getContact = (state: State, contactId: ?string): ?Contact => {
	if (contactId == null) {
		return null
	}

	return state.addressBook.contactItems[contactId] && state.addressBook.contactItems[contactId].contact
}

export const getContactSaved = (state: State): boolean => {
	return state.addressBook.saved || false
}

export const getContactByRegNo = (state: State, regNo: string): ?Contact => {
	const contactItem = find(
		state.addressBook.contactItems,
		(contactItem: ContactItem) => regNo === (contactItem.contact && contactItem.contact.companyRegNo),
	)

	return contactItem && contactItem.contact
}

export const getContactLoading = (state: State, contactId: string): boolean => {
	return !!(state.addressBook.contactItems[contactId] && state.addressBook.contactItems[contactId].contactLoading)
}

export const getContactDefaultDueDate = (state: State, contactId: string): ?number => {
	const contact = getContact(state, contactId)
	if (contact) {
		return contact.defaultDuePeriod
	}
}
