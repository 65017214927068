/* @flow */
/** @jsx jsx */

import { Component } from 'react'
import memoize from 'memoize-one'
import { type OptionType } from './index'
import CheckIcon from 'components/svg-icons/trivi/check'
import { colors } from 'variables'
import { jsx } from '@emotion/core'

type ExtendedOptionType = OptionType | { isCategory: boolean } | { level: number } | { disabled: boolean }

type Props = {
	innerRef: string,
	innerProps: Object,
	isDisabled: boolean,
	isSelected: boolean,
	isFocused: boolean,
	label: string,
	data: ExtendedOptionType,
}

class Option extends Component<Props> {
	shouldComponentUpdate(nextProps: Props) {
		if (
			this.props.label !== nextProps.label ||
			this.props.data !== nextProps.data ||
			this.props.innerRef !== nextProps.innerRef ||
			this.props.isFocused !== nextProps.isFocused ||
			this.props.isDisabled !== nextProps.isDisabled ||
			this.props.isSelected !== nextProps.isSelected
		) {
			return true
		}
		return false
	}

	getStyles = memoize((isSelected: boolean, isFocused: boolean, data: ExtendedOptionType) => ({
		option: {
			outline: 'none',
			padding: '0 16px',
			lineHeight: '30px',
			color: isSelected ? colors.blue : colors.black,
			backgroundColor: isFocused ? colors.blueFaded10 : colors.white,
			cursor: 'pointer',
			overflow: 'hidden',
			fontWeight: data.isCategory ? 'bold' : undefined,
			paddingLeft: data.level === 1 ? '20px' : undefined,
			'&:hover': {
				backgroundColor: colors.blueFaded10,
			},
		},
		label: {
			flex: '1 1 100%',
			lineHeight: '30px',
			overflow: 'hidden',
			whiteSpace: 'nowrap',
			textOverflow: 'ellipsis',
			fontSize: 14,
		},
		prefix: {
			flex: '0 0 auto',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			fontFamily: 'Courier New, sans-serif',
			lineHeight: '30px',
			fontWeight: 100,
			marginRight: 4,
			fontSize: 14,
		},
		line: {
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center',
		},
		rightText: {
			flex: '0 0 auto',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			fontSize: 10,
			marginLeft: 10,
			lineHeight: '30px',
			color: colors.blackFaded60,
			textAlign: 'right',
			whiteSpace: 'nowrap',
		},
	}))

	render() {
		const styles = this.getStyles(this.props.isSelected, this.props.isFocused, this.props.data)

		return !this.props.isDisabled ? (
			<div
				id={this.props.innerProps.id}
				onClick={!this.props.data.disabled ? this.props.innerProps.onClick : undefined}
				tabIndex={this.props.innerProps.tabIndex}
				ref={this.props.innerRef}
				css={styles.option}
			>
				<div css={styles.line}>
					{/* $FlowFixMe*/}
					{this.props.data.labelPrefix && <span css={styles.prefix}>{this.props.data.labelPrefix}</span>}
					<span css={styles.label}>{this.props.label}</span>
					{/* $FlowFixMe*/}
					{this.props.data.rightText && <span css={styles.rightText}>{this.props.data.rightText}</span>}
					{this.props.isSelected && <CheckIcon disabled color={colors.blue} />}
				</div>
			</div>
		) : null
	}
}

export default Option
