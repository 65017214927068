/* @flow */

import React, { Component } from 'react'
import { IconButton, Loader, Paper, TriviLink } from 'components'
import ActionDelete from 'components/svg-icons/action/delete'
import type { FileDescription } from 'types'
import styles from './file-thumbnail.css'

export type Props = {|
	file: FileDescription,
	editable: boolean,
	onRemove: FileDescription => void,
	onOpen: FileDescription => void,
|}

class FileThumbnail extends Component<Props> {
	static defaultProps = {
		file: {
			id: '',
			name: '',
			fileId: undefined,
			loading: false,
		},
		editable: true,
		onRemove: () => {},
		onOpen: () => {},
	}

	handleRemoveClick = () => {
		this.props.onRemove(this.props.file)
	}

	handleFileClick = () => {
		this.props.onOpen(this.props.file)
	}

	render() {
		return (
			<Paper rounded zDepth={3} className={styles.root}>
				{this.props.editable && <div className={styles.text}>{this.props.file.name}</div>}
				{!this.props.editable && (
					<div className={styles.text} onClick={this.handleFileClick}>
						<TriviLink>{this.props.file.name}</TriviLink>
					</div>
				)}
				{this.props.editable && (
					<div className={styles.icon}>
						<IconButton autoTestId="file-thumbnail-remove" onClick={this.handleRemoveClick}>
							<ActionDelete />
						</IconButton>
					</div>
				)}
				<Loader visible={this.props.file.loading} />
			</Paper>
		)
	}
}

export default FileThumbnail
