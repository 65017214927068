//@flow

import type { I18NextTranslateFn, OrganizationStatistics } from 'types'
import { convertToDate, formatToDateString } from './date-formatter'

import React from 'react'

export function formatOrganizationVATType(t: I18NextTranslateFn, organization: OrganizationStatistics) {
	if (organization.vatPayerType === 2) {
		return <span>{t('dashboard.organization.identified')}</span>
	}

	if (organization.vatPayerType === 1 || organization.vatPaymentPeriod == 3) {
		return <span>{t('dashboard.organization.noVAT')}</span>
	}

	if (organization.vatPayerType === 0 && organization.vatPayerTypeSince != null) {
		const formattedDate = formatToDateString(convertToDate(organization.vatPayerTypeSince))
		return organization.vatPaymentPeriod == null ? (
			<span>{formattedDate}</span>
		) : (
			<span>
				{t(`dashboard.organization.vatPaymentPeriod.${organization.vatPaymentPeriod || ''}`)} {formattedDate}
			</span>
		)
	}
	return null
}
