/* @flow */

import type {
	AccountingDocumentBankAccount,
	AccountingDocumentContact,
	BankAccount,
	BankAction,
	Contact,
	ContactBankAccount,
	FormFieldErrorContext,
	FormFieldErrorMessage,
	State,
} from 'types'
import { addBankAccountToContact, loadContact } from 'modules/address-book/actions'
import {
	getAccountingDocumentAvailableBankAccounts,
	getAccountingDocumentContact,
	getAccountingDocumentContactBankAccounts,
	getAccountingDocumentPaymentType,
	getIssuedAccountingDocumentBankAccounts,
} from 'modules/accounting-document/selectors'

import BankAccounts from 'modules/accounting-document/components/invoice-elements/bank-accounts'
import { EMPTY_ARRAY } from 'trivi-constants'
import { connect } from 'react-redux'
import { getBankAccounts } from 'modules/bank/selectors'
import { getContact } from 'modules/address-book/selectors'
import { getFormFieldErrors } from 'modules/common/selectors'
import { removeError } from 'helpers'

type OwnProps = {|
	accountingDocumentId: string,
	direction: 'issued' | 'received',
	readonly?: boolean,
	isCreditNote?: boolean,
	loading: boolean,
	bankAccounts?: Array<AccountingDocumentBankAccount>,
	onBankAccountRemove: (accountingDocumentId: string, bankAccount: AccountingDocumentBankAccount) => void,
	onBankAccountAdd: (accountingDocumentId: string, bankAccount: AccountingDocumentBankAccount) => void,
	formFieldErrorContext?: FormFieldErrorContext,
|}

type StateProps = {|
	paymentType: ?number,
	contactBankAccounts: Array<ContactBankAccount>,
	availableBankAccounts: Array<AccountingDocumentBankAccount>,
	accountingDocumentContact: ?Contact,
	accountingDocumentContactId: ?string,
	organizationBankAccounts: Array<BankAccount>,
	errors: ?Array<FormFieldErrorMessage>,
|}

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => {
	const c: ?AccountingDocumentContact = getAccountingDocumentContact(state, ownProps.accountingDocumentId)
	return {
		accountingDocumentContactId: c && c.contactId,
		accountingDocumentContact: c && c.contactId ? getContact(state, c.contactId) : null,
		paymentType: getAccountingDocumentPaymentType(state, ownProps.accountingDocumentId),
		contactBankAccounts: getAccountingDocumentContactBankAccounts(state, ownProps),
		availableBankAccounts:
			ownProps.direction === 'issued'
				? ownProps.isCreditNote
					? getAccountingDocumentAvailableBankAccounts(state, ownProps)
					: getIssuedAccountingDocumentBankAccounts(state)
				: ownProps.isCreditNote
				? getIssuedAccountingDocumentBankAccounts(state)
				: getAccountingDocumentAvailableBankAccounts(state, ownProps),
		organizationBankAccounts: getBankAccounts(state) || EMPTY_ARRAY,
		errors: ownProps.formFieldErrorContext && getFormFieldErrors(state, ownProps.formFieldErrorContext),
	}
}

type DispatchProps = {|
	loadContact: (id: string) => void,
	onAddBankAccountToContact: (bankAccount: AccountingDocumentBankAccount, contactId: string) => void,
	removeError: () => void,
|}

const mapDispatchToProps = (dispatch: Dispatch<BankAction>, ownProps: OwnProps): DispatchProps => {
	return {
		loadContact: (id: string) => {
			dispatch(loadContact(id, false, false))
		},
		onAddBankAccountToContact: (bankAccount: AccountingDocumentBankAccount, contactId: string) => {
			dispatch(addBankAccountToContact(bankAccount, contactId))
		},
		removeError: () => {
			removeError({
				formFieldErrorContext: ownProps.formFieldErrorContext,
				dispatch,
			})
		},
	}
}

const mergeProps = (stateProps: StateProps, dispatchProps: DispatchProps, ownProps: OwnProps) => {
	return {
		accountingDocumentId: ownProps.accountingDocumentId,
		direction:
			ownProps.direction === 'received' && ownProps.isCreditNote
				? 'issued'
				: ownProps.direction === 'issued' && ownProps.isCreditNote
				? 'received'
				: ownProps.direction,
		readonly: ownProps.readonly,
		loading: ownProps.loading,
		bankAccounts: ownProps.bankAccounts,
		contactBankAccounts: stateProps.contactBankAccounts,
		availableBankAccounts: stateProps.availableBankAccounts,
		organizationBankAccounts: stateProps.organizationBankAccounts,
		paymentType: stateProps.paymentType,
		accountingDocumentContact: stateProps.accountingDocumentContact,
		accountingDocumentContactId: stateProps.accountingDocumentContactId,
		loadContact: dispatchProps.loadContact,
		onBankAccountRemove: ownProps.onBankAccountRemove,
		onBankAccountAdd: ownProps.onBankAccountAdd,
		onAddBankAccountToContact: dispatchProps.onAddBankAccountToContact,
		errors: stateProps.errors,
		removeError: dispatchProps.removeError,
	}
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(BankAccounts)
