/* @flow */

/** @jsx jsx */
import { jsx } from '@emotion/core'
import React, { Component } from 'react'
import { differenceBy } from 'lodash-es'
import type {
	AccountingDocument,
	AccountingDocumentInfo,
	AccountingDocumentView,
	AccountingDocumentAssignedDirection,
	Filter,
	AccountingDocumentLineItem,
} from 'types'
import AccountingDocumentAdvancedFilter from '../accounting-document-list/advanced-filter'
import { AccountingDocumentDirection_Number } from 'types/convertor'
import AccountingDocumentIcon from '../accounting-document-icon'
import {
	withAccountingDocumentActions,
	type WithAccountingDocumentActionsProps,
	withTranslate,
	type WithTranslateProps,
} from 'wrappers'
import {
	getAccountingDocumentNoColumn,
	getCompanyColumn,
	getDueDateColumn,
	getIssueDateColumn,
	getTotalColumn,
} from '../data-grid-columns'
import { DataGrid as DataGridNext, type DataGridProps as DataGridNextProps } from 'modules/data-grid-next/containers'
import { Column } from 'modules/data-grid-next/components'
import { Button, LinearProgress, PopupSection, Paper } from 'components'
import FullText from 'components/full-text'
import View from 'components/svg-icons/action/visibility'
import { colors } from 'variables'
import styles from './picker.css'
import type { DataTypes } from 'modules/data-grid-next/types'
import { formatToMoney } from 'utils/formatters'
import { EMPTY_ARRAY, EMPTY_FUNCTION } from 'trivi-constants'
import AccountingDocumentGridTotalColumn from '../accounting-document-list/grid-total-column'
import LineItem from '../../containers/invoice-elements/line-item'
import confirmDialog from 'components/confirm-dialog'

const AccountingDocumentDataGrid: React$ComponentType<DataGridNextProps<'accountingDocuments'>> = DataGridNext

export type AddConnectionOptions = {
	asRelatedDocument?: boolean,
	deletePrevItems?: boolean,
	recalculateOnly?: boolean,
}

type Props = {|
	...WithTranslateProps,
	...WithAccountingDocumentActionsProps,
	readonly?: boolean,
	selected: Array<AccountingDocumentInfo>,
	onSelectionChange?: (
		selection: Array<AccountingDocumentInfo>,
		selectionFullObject: Array<AccountingDocument>,
	) => void,
	onAddConnection?: (connectingDocument: AccountingDocument, options: AddConnectionOptions) => Promise<any>,
	onRemoveConnection?: (connectingDocument: AccountingDocument, asRelatedDocument: boolean) => Promise<any>,
	hideSelectedFiles?: boolean,
	allowedTypes?: ?Array<number>,
	organizationId?: number,
	contactId?: ?string,
	accountingDocumentId?: string,
	asRelatedDocument?: boolean,
	direction?: ?AccountingDocumentAssignedDirection,
	paidConfirmed?: boolean,
	gridData?: ?Array<*>,
	preselectedValues?: ?Array<string>,
	currency?: ?string,
	accountingDocument?: ?AccountingDocument,
	updateRow: (gridData: Array<*>, id: string) => void,
	light?: boolean,
	checkAllOption?: boolean,
	internal: ?boolean,
	selectedDocs: Array<AccountingDocument>,
	clearAllFilters: (name: string) => void,
	loadFinAccs: (selectedAccDoc: AccountingDocument) => void,
|}

type State = {|
	view: AccountingDocumentView,
	selectedDocuments: Array<AccountingDocument>,
	connecting: boolean,
	openedDocument: ?AccountingDocument,
|}

type RowType = {
	...AccountingDocument,
	context: {
		props: Props,
		getHandleCheck: (
			accountingDocument: AccountingDocument | RowType,
		) => (id: string, number: ?string, value: boolean) => void,
		getHandleBlur: (accountingDocument: AccountingDocument | RowType) => (id: ?string, value: number) => void,
	},
}

class AccountingDocumentPicker extends Component<Props, State> {
	static defaultProps = {
		updateRow: () => {},
	}

	state = {
		view: 'all',
		selectedDocuments: this.props.selectedDocs,
		connecting: false,
		openedDocument: null,
	}

	defaultFilter: Filter
	selectedRowIds: Array<string>

	constructor(props: Props) {
		super(props)
		this.defaultFilter = this.getDefaultFilter(props, this.state.view)
		this.selectedRowIds = this.getSelectedRowIds(props)
	}

	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		if (
			this.props.allowedTypes !== nextProps.allowedTypes ||
			this.props.organizationId !== nextProps.organizationId ||
			this.props.contactId !== nextProps.contactId ||
			this.props.direction !== nextProps.direction ||
			this.props.currency !== nextProps.currency ||
			this.props.paidConfirmed !== nextProps.paidConfirmed
		) {
			this.defaultFilter = this.getDefaultFilter(nextProps, this.state.view)
		}

		if (this.props.selected !== nextProps.selected || this.props.preselectedValues !== nextProps.preselectedValues) {
			this.selectedRowIds = this.getSelectedRowIds(nextProps)
		}

		if (this.state.selectedDocuments !== nextProps.selectedDocs) {
			this.selectedRowIds = this.getSelectedRowIds(nextProps)
			this.setState({ selectedDocuments: this.props.selectedDocs })
		}
	}

	componentDidUpdate(prevProps: Props) {
		const toUpdate = [
			...differenceBy(prevProps.selected, this.props.selected, 'accountingDocumentId'),
			...differenceBy(this.props.selected, prevProps.selected, 'accountingDocumentId'),
		]
		toUpdate.forEach((info: AccountingDocumentInfo) => {
			this.props.updateRow(this.props.gridData || [], info.accountingDocumentId || '')
		})
	}

	componentWillUnmount() {
		this.props.clearAllFilters('accountingDocumentPicker')
	}

	getDefaultFilter(props: Props, view: AccountingDocumentView) {
		let defaultFilter = []

		if (props.allowedTypes) {
			defaultFilter.push({
				field: 'type',
				valueContains: props.allowedTypes,
			})
		}

		if (props.organizationId) {
			defaultFilter.push({
				field: 'organizationId',
				value: props.organizationId,
			})
		}

		if (props.contactId) {
			defaultFilter.push({
				field: 'contact.contactId',
				value: props.contactId,
			})
		}

		if (props.direction) {
			defaultFilter.push({
				field: 'direction',
				value: AccountingDocumentDirection_Number(props.direction),
			})
		}

		if (props.currency) {
			defaultFilter.push({
				field: 'currency',
				value: props.currency,
			})
		}

		if (props.paidConfirmed != null) {
			defaultFilter.push({
				field: 'payments.paidConfirmed',
				value: props.paidConfirmed,
			})
		}

		switch (view) {
			case 'errors':
				defaultFilter.push({
					field: 'processingState',
					value: 3,
				})
				break
			case 'drafts':
				defaultFilter.push({
					field: 'state',
					value: 'Draft',
				})
				break
		}

		return defaultFilter
	}

	getSelectedRowIds(props: Props): Array<string> {
		const selected = [...(props.preselectedValues || [])]

		props.selected.forEach((item: AccountingDocumentInfo) => {
			if (item.accountingDocumentId) {
				selected.push(item.accountingDocumentId)
			}
		})

		return selected
	}

	addToSelection(accountingDocument: AccountingDocument, options: AddConnectionOptions) {
		const selectedDocumentInfo: Array<AccountingDocumentInfo> = [
			...this.props.selected,
			{
				accountingDocumentId: accountingDocument.id,
				accountingDocumentNo: accountingDocument.accountingDocumentNo || '',
			},
		]

		if (this.props.onAddConnection) {
			this.props.onAddConnection &&
				this.props.onAddConnection(accountingDocument, {
					asRelatedDocument: this.props.asRelatedDocument || false,
					deletePrevItems: options.deletePrevItems,
					recalculateOnly: options.recalculateOnly,
				})
		}

		this.setState(
			{
				selectedDocuments: [...this.state.selectedDocuments, accountingDocument],
			},
			() => {
				this.props.onSelectionChange && this.props.onSelectionChange(selectedDocumentInfo, this.state.selectedDocuments)
			},
		)
	}

	removeFromSelection(id: string, accountingDocument: ?AccountingDocument) {
		const selected: Array<AccountingDocumentInfo> = [...this.props.selected]
		const index = selected.findIndex((item: AccountingDocumentInfo) => item.accountingDocumentId === id)

		if (index !== -1) {
			selected.splice(index, 1)
		}

		const selectedDocuments = this.state.selectedDocuments.filter((i: AccountingDocument) => i.id !== id)
		this.setState({ selectedDocuments })

		this.props.onRemoveConnection &&
			accountingDocument &&
			this.props.onRemoveConnection(accountingDocument, this.props.asRelatedDocument || false)

		this.props.onSelectionChange && this.props.onSelectionChange(selected, selectedDocuments)
	}

	handleAccountingDocumentRemove = (accountingDocument: AccountingDocument | AccountingDocumentInfo) => {
		if (accountingDocument.accountingDocumentId) {
			this.removeFromSelection(accountingDocument.accountingDocumentId)
		}
	}

	handleViewChange = (view: AccountingDocumentView) => {
		this.defaultFilter = this.getDefaultFilter(this.props, view)
		this.setState({
			view,
		})
	}

	handleAccountingDocumentClick = (accountingDocument: AccountingDocument) => {
		this.props.loadFinAccs(accountingDocument)
		this.setState({ openedDocument: accountingDocument })
	}

	handleAccountingDocumentCheckboxClick = async (accountingDocument: AccountingDocument, checked: boolean) => {
		if (!checked) {
			await this.handleSelectionChange(accountingDocument, checked)
		} else {
			this.props.loadFinAccs(accountingDocument)
			this.setState({ openedDocument: accountingDocument })
		}
	}

	closeOpenedDocument = () => {
		this.setState({ openedDocument: null })
	}

	handleSelectionChange = async (
		accountingDocument: AccountingDocument,
		selected: boolean,
		deletePrevItems?: boolean,
		recalculateOnly?: boolean,
	) => {
		if (accountingDocument.id) {
			if (selected) {
				this.addToSelection(accountingDocument, { deletePrevItems, recalculateOnly })
			} else {
				await confirmDialog(this.props.t('attachments.removeConnectionWarning'), {
					okLabel: this.props.t('dialogs.understandOption'),
					cancelLabel: this.props.t('dialogs.goBackOption'),
				})
					.then(() => {
						accountingDocument.id && this.removeFromSelection(accountingDocument.id, accountingDocument)
					})
					.catch(() => {})
			}
		}
		this.setState({ openedDocument: null })
	}

	handleCheckAll = (data: { [id: string]: AccountingDocument }) => {
		const selectedDocuments: Array<any> = Object.values(data)
		const selectedDocumentInfo: Array<AccountingDocumentInfo> = selectedDocuments.map((item: any) => ({
			accountingDocumentId: item.id,
			accountingDocumentNo: item.accountingDocumentNo,
		}))
		this.props.onSelectionChange && this.props.onSelectionChange(selectedDocumentInfo, selectedDocuments)
	}

	connectableAmountRenderer(value: string, row: RowType) {
		const { total } = row
		const connectableAmount: number = value ? parseFloat(value) : 0

		const progressValue: number = total != null ? total - connectableAmount : 0
		const progressFormattedValue = formatToMoney(progressValue, {
			currency: row.currency,
			minimumFractionDigits: 2,
		})
		const max: number = row.total || 100
		return (
			<div>
				{progressFormattedValue}
				<LinearProgress
					className={styles.progress}
					color={total === progressValue ? colors.green : colors.orange}
					mode="determinate"
					min={0}
					max={max}
					value={progressValue}
					backgroundColor={colors.grey200}
					small
				/>
			</div>
		)
	}

	emptyRenderer() {
		return <span>&nbsp;</span>
	}

	getRowClassName = (
		row: $ElementType<DataTypes, string>,
		selectedRows: { [string]: $ElementType<DataTypes, string> },
		selectOnClick?: boolean,
		currentRowId?: ?string,
	) => {
		const { connectableAmount } = row
		const isConsumedRow = connectableAmount <= 0
		const isSelectedRow = selectedRows[row.id] || currentRowId === row.id
		const classNames = ['row']

		if (isConsumedRow && isSelectedRow) {
			classNames.push('selectedConsumedRow')
		} else if (isConsumedRow) {
			classNames.push('consumedRow')
		} else if (isSelectedRow) {
			classNames.push('selectedRow')
		}

		if (selectOnClick) classNames.push('clickableRow')
		return classNames.join(' ')
	}

	renderOverwriteButton = (accDoc: AccountingDocument, isSelected: boolean) => {
		return this.state.selectedDocuments && this.state.selectedDocuments.length > 0 ? null : (
			<Button
				autoTestId="overwrite-lineitems-button"
				key="overwrite"
				disabled={!accDoc.lineItems || accDoc.lineItems.length < 1}
				labelText={this.props.t('attachments.replaceItems')}
				onClick={() => this.handleSelectionChange(accDoc, !isSelected, true)}
			/>
		)
	}

	renderUndoButton = (accDoc: AccountingDocument, isSelected: boolean) => {
		return isSelected ? (
			<Button
				autoTestId="undo-lineitems-button"
				key="undo"
				disabled={!accDoc.lineItems || accDoc.lineItems.length < 1}
				labelText={this.props.t('attachments.undoItems')}
				onClick={() => this.handleSelectionChange(accDoc, !isSelected, true)}
			/>
		) : null
	}

	renderAddButton = (accDoc: AccountingDocument, isSelected: boolean) => {
		return !isSelected ? (
			<Button
				autoTestId="add-lineitems-button"
				key="add"
				labelText={this.props.t('attachments.addItems')}
				onClick={() => this.handleSelectionChange(accDoc, !isSelected, false)}
			/>
		) : null
	}

	renderSubtractButton = (accDoc: AccountingDocument, isSelected: boolean) => {
		return !isSelected ? (
			<Button
				autoTestId="subtract-total-button"
				key="subtract"
				labelText={this.props.t('attachments.subtractValue')}
				onClick={() => this.handleSelectionChange(accDoc, !isSelected, false, true)}
			/>
		) : null
	}

	render() {
		const { t } = this.props
		const isSelected = this.state.openedDocument && this.props.selectedDocs.includes(this.state.openedDocument)

		return (
			<div className={styles.root}>
				{!this.props.hideSelectedFiles && (
					<div className={styles.files}>
						{this.props.selected.map((accountingDocument: AccountingDocumentInfo) => (
							<AccountingDocumentIcon
								key={accountingDocument.accountingDocumentId}
								accountingDocument={accountingDocument}
								onRemove={this.handleAccountingDocumentRemove}
							/>
						))}
					</div>
				)}
				<AccountingDocumentAdvancedFilter
					showViewSwitch
					gridId="accountingDocumentPicker"
					name="accountingDocumentPicker"
					dataType="accountingDocuments"
					defaultFilter={this.defaultFilter}
					view={this.state.view}
					allowedTypes={this.props.allowedTypes}
					onViewChange={this.handleViewChange}
					internal={this.props.internal}
					hideCurrency
				/>

				<AccountingDocumentDataGrid
					checkAllOption={this.props.checkAllOption}
					id="accountingDocumentPicker"
					dataType="accountingDocuments"
					filterId="accountingDocumentPicker"
					defaultFilter={this.defaultFilter}
					getRowClassName={this.getRowClassName}
					selectedRows={this.selectedRowIds}
					onSelectionChange={this.handleAccountingDocumentCheckboxClick}
					onCheckAllChange={this.handleCheckAll}
					selectDisabled={this.props.readonly || this.state.connecting}
					selectTooltip={t('accountingDocument.gridActions.attachDocument')}
					deselectTooltip={t('accountingDocument.gridActions.unAttachDocument')}
					light={this.props.light}
					onAction={this.props.defaultOnAction}
					compactPager
					confirmOnCheckAction
					actions={[
						{
							name: t('accountingDocument.gridActions.view'),
							type: 'accdoc_show',
							icon: <View />,
						},
					]}
				>
					{getAccountingDocumentNoColumn({
						t,
						width: '25%',
						onClick: this.handleAccountingDocumentClick,
						defaultSortDirection: 'DESC',
					})}
					{!this.props.accountingDocument && getCompanyColumn({ t, width: '30%' })}
					{getIssueDateColumn({ t, width: '16%' })}
					{!!this.props.accountingDocument && !this.props.accountingDocument.cashRegisterId && (
						<Column
							title={t('accountingDocument.columns.connectableAmount')}
							width="30%"
							columnId="connectableAmount"
							key="connectableAmount"
							render={this.connectableAmountRenderer}
						/>
					)}
					{!!this.props.accountingDocument &&
						!!this.props.accountingDocument.cashRegisterId &&
						getDueDateColumn({ t, width: '16%' })}
					{getTotalColumn({
						t,
						width: '25%',
						offsetCashRegisterDocuments: true,
					})}
					{this.state.view === 'errors' && <Column width="15px" columnId="id" key="id" render={this.emptyRenderer} />}
				</AccountingDocumentDataGrid>
				<PopupSection
					containerStyles={styles.noScroll}
					open={!!this.state.openedDocument}
					onRequestClose={this.closeOpenedDocument}
				>
					{this.state.openedDocument && (
						<div css={style.container}>
							<Paper>
								<div css={style.lineItemsContainer}>
									<div css={style.header}>
										{this.state.openedDocument &&
											(this.state.openedDocument.accountingDocumentNoPreview ||
												this.state.openedDocument.accountingDocumentNo) && (
												<FullText
													text={`${t('fieldName.accountingDocument.number')}: ${(this.state.openedDocument &&
														this.state.openedDocument.accountingDocumentNoPreview) ??
														(this.state.openedDocument && this.state.openedDocument.accountingDocumentNo) ??
														''}`}
												/>
											)}
										{this.state.openedDocument && this.state.openedDocument.id && (
											<FullText
												text={`${t('fieldName.accountingDocument.id')}: ${(this.state.openedDocument &&
													this.state.openedDocument.id) ??
													''}`}
											/>
										)}
									</div>
									<div css={style.content}>
										<FullText text={t('documentExtraction.lineItems')} />
										<Paper style={style.paper}>
											{this.state.openedDocument &&
												this.state.openedDocument.lineItems &&
												this.state.openedDocument.lineItems.map((li: AccountingDocumentLineItem) => (
													<div key={`${li.id ?? ''}-container`} css={style.lineItemContainer}>
														<LineItem
															key={li.id}
															isCreating
															readonly
															value={li}
															onChange={EMPTY_FUNCTION}
															onRemove={EMPTY_FUNCTION}
															onClone={EMPTY_FUNCTION}
															onExpand={EMPTY_FUNCTION}
															onExpandClose={EMPTY_FUNCTION}
															financialAccounts={EMPTY_ARRAY}
															onFinancialAccountFavorite={EMPTY_FUNCTION}
															favoriteFinancialAccountIds={EMPTY_ARRAY}
															favoriteLineItemIds={EMPTY_ARRAY}
															greenboxSuggestion={null}
															availableProjects={EMPTY_ARRAY}
															availableBranches={EMPTY_ARRAY}
															isCalculating={false}
															currency={null}
															isAccDocVatFree={false}
															direction={this.state.openedDocument && this.state.openedDocument.direction}
															type={this.state.openedDocument && this.state.openedDocument.type}
															index={0}
															isExpanded={false}
															vatRates={null}
															disabledFinAccount={false}
															accountingDocumentId={this.state.openedDocument && this.state.openedDocument.id}
															accountingDocument={this.state.openedDocument}
															readonlyOptions
														/>
													</div>
												))}
										</Paper>
										<br />
										<p>
											{this.state.openedDocument && (
												<div style={style.totalContainer}>
													<span>{t('invoice.lineItems.total')}</span>
													<div style={style.total}>
														{this.state.openedDocument && (
															<AccountingDocumentGridTotalColumn
																showTotalToPay
																short
																accountingDocument={this.state.openedDocument}
															/>
														)}
													</div>
												</div>
											)}
										</p>
									</div>
									{this.state.openedDocument && (
										<div css={style.footer}>
											{this.state.openedDocument && this.renderOverwriteButton(this.state.openedDocument, !!isSelected)}{' '}
											{this.state.openedDocument && this.renderUndoButton(this.state.openedDocument, !!isSelected)}{' '}
											{this.state.openedDocument && this.renderAddButton(this.state.openedDocument, !!isSelected)}{' '}
											{this.state.openedDocument && this.renderSubtractButton(this.state.openedDocument, !!isSelected)}
										</div>
									)}
								</div>
							</Paper>
						</div>
					)}
				</PopupSection>
			</div>
		)
	}
}

const SAFEZONE = 180

const style = {
	container: {
		position: 'relative',
		marginLeft: 'auto',
		marginRight: 'auto',
		marginBottom: 50,
		maxWidth: '80%',
		width: 970,
	},
	lineItemsContainer: {
		width: '80%',
		marginLeft: '10%',
	},
	header: {
		paddingTop: 20,
		paddingBottom: 20,
		alignItems: 'center',
		minHeight: SAFEZONE / 2,
		fontWeight: 'bold',
	},
	footer: {
		paddingTop: 20,
		paddingBottom: 20,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-evenly',
		minHeight: 0,
	},
	content: {
		height: `calc(100vh - ${SAFEZONE}px)`,
	},
	total: {
		overflow: 'hidden',
		flex: '1 1 auto',
		textAlign: 'right',
		margin: '-2px 0 -2px 0',
		fontWeight: 'bold',
	},
	totalContainer: {
		display: 'flex',
		justifyContent: 'flex-end',
	},
	paper: {
		maxHeight: 700,
		overflowX: 'hidden',
	},
	lineItemContainer: {
		paddingLeft: 5,
	},
}

export default withTranslate(withAccountingDocumentActions(AccountingDocumentPicker))
