/* @flow */

import type {
	AccountingDocument,
	AccountingDocumentLineItem,
	AccountingDocumentReduxAction,
	DataGridNextAction,
	Dispatch,
	Enum,
	FinancialAccount,
	FormFieldErrorContext,
	GreenboxSuggestionResponse,
	MultiFormFieldMessages,
	OrganizationAction,
	State,
	Tag,
	VatRate,
} from 'types'
import type { ValidationMessageType, ValidationValueType } from 'wrappers/validate'
import { getFormFieldErrorsFromMultiErros, removeError } from 'helpers'

import { AccountingDocumentAssignedType_isNonCashRegister } from 'types/operations'
import { EMPTY_ARRAY } from 'trivi-constants'
import LineItem from 'modules/accounting-document/components/invoice-elements/line-item'
import { Number_AccountingDocumentDirection } from 'types/convertor'
import type { Action as ReportingAction } from 'modules/settings/actions/reporting-action-types'
import { connect } from 'react-redux'
import { createTag } from 'modules/settings/actions'
import { currentUserIsInternal } from '../../../user/selectors'
import { generateKeyFromFinancialAccountsGetParams } from '../../../organization/utils'
import { getAccDocType } from '../../domain/accounting-document'
import { getFinancialAccounts } from '../../../organization/selectors'
import { makeGetMultiFormFieldErrors } from 'modules/common/selectors'

export type OwnProps = {|
	public?: boolean,
	readonly?: boolean,
	value: AccountingDocumentLineItem,
	financialAccounts: Array<FinancialAccount>,
	favoriteFinancialAccountIds: Array<string>,
	favoriteLineItemIds: Array<string>,
	greenboxSuggestion: ?GreenboxSuggestionResponse,
	availableProjects: Enum,
	availableBranches: Enum,
	isCalculating: boolean,
	currency: ?string,
	isCreating?: boolean,
	direction: ?number,
	type?: ?number,
	index: number,
	isAccDocVatFree: boolean,
	validationMessage?: ValidationMessageType,
	validationValue?: ValidationValueType,
	defaultLineItems?: Array<AccountingDocumentLineItem> | null,
	accountingDocumentId?: ?string,
	accountingDocument?: ?AccountingDocument,
	vatRates: null | Array<VatRate>,
	onChange: (value: AccountingDocumentLineItem, autocalc: boolean) => void,
	onRemove: () => void,
	onClone: () => void,
	onExpand: (id: number) => void,
	onExpandClose: (id: number) => void,
	onFinancialAccountFavorite: (no: string, isFavorite: boolean) => void,
	isExpanded: boolean,
	formFieldErrorContext?: FormFieldErrorContext,
	disabledFinAccount?: boolean,
	readonlyOptions?: boolean,
	isLastLineItem?: boolean,
	onCreate?: () => Promise<void>,
|}

type StateProps = {|
	errors: ?MultiFormFieldMessages,
	financialAccounts: ?Array<FinancialAccount>,
	isInternal?: boolean,
	isDescriptionChangeOnly?: boolean,
	isExpanded?: boolean,
|}

const makeMapStateToProps = () => {
	const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => {
		const financialAccountsKey = generateKeyFromFinancialAccountsGetParams({
			accountingDocumentType: ownProps.type ?? undefined,
			accountingDocumentDirection: ownProps.direction ?? undefined,
		})

		const getMultiFormFieldErrors = makeGetMultiFormFieldErrors()

		const accDocType = getAccDocType(ownProps.accountingDocument)
		const accDocDirection = Number_AccountingDocumentDirection(ownProps.direction)

		const isSimplifiedCash = accDocType && accDocType === 'simplified_invoice'
		const isSimpleInvoice =
			accDocType && accDocType !== 'unknown' ? AccountingDocumentAssignedType_isNonCashRegister(accDocType) : false
		const isSimpleReceived = isSimpleInvoice && accDocDirection === 'received'
		const isDescriptionChangeOnly = isSimplifiedCash || isSimpleReceived || false

		const errors = ownProps.formFieldErrorContext && getMultiFormFieldErrors(state, ownProps.formFieldErrorContext)

		let isExpanded = ownProps.isExpanded

		if (getFormFieldErrorsFromMultiErros(errors, 'reverseChargeCode')) {
			isExpanded = true
		}

		if (ownProps.value && ownProps.value.openExpanded) {
			isExpanded = true
		}

		return {
			errors,
			isInternal: currentUserIsInternal(state),
			financialAccounts: ownProps.readonlyOptions
				? getFinancialAccounts(state, financialAccountsKey) || EMPTY_ARRAY
				: ownProps.financialAccounts,
			isDescriptionChangeOnly,
			isExpanded,
		}
	}

	// $FlowFixMe
	return mapStateToProps
}

type DispatchProps = {|
	createTag: (tag: Tag) => void,
	removeError: (formFieldName: string) => void,
|}

function mapDispatchToProps(
	dispatch: Dispatch<ReportingAction | OrganizationAction | DataGridNextAction | AccountingDocumentReduxAction>,
	ownProps: OwnProps,
): DispatchProps {
	return {
		createTag: (tag: Tag) => {
			dispatch(createTag(tag))
		},
		removeError: (formFieldName: string) => {
			removeError({
				formFieldErrorContext: ownProps.formFieldErrorContext,
				dispatch,
				extraPath: [formFieldName],
			})
		},
	}
}

export default connect(makeMapStateToProps, mapDispatchToProps)(LineItem)
