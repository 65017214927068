/* @flow */

import type {
	AccountingDocument,
	AccountingDocumentDirection,
	AccountingDocumentListAction,
	AccountingDocumentReduxAction,
	DataGridNextAction,
	AccountingDocumentView,
	CashRegisterAction,
	CashRegisters,
	CommonAction,
	OrganizationAction,
	DashboardAction,
	Dispatch,
	// EETOperation,
	Enum,
	State,
	SettingsAction,
	AccountingDocumentCategory,
	Filter,
	Currency,
} from 'types'
import type { CashbotAction } from 'modules/cashbot/actions/action-types'
import {
	changeAccountingDocumentsView,
	loadAccountingDocument,
	updateAccountingDocumentCustomerInstructions,
	// countryVariantUpdate,
} from 'modules/accounting-document/actions'
import { refreshGridData, refreshGridLine, changeCurrentPage } from 'modules/data-grid-next/actions'
import { loadAccountingDocumentCategories } from 'modules/settings/actions'
// Actions
import { loadBranches, loadCurrenciesUsedInOrg, loadProjects } from 'modules/organization/actions'
import { loadPaymentTypes } from 'modules/common/actions'
import { loadCashRegisters } from 'modules/cash-register/actions'
import { markAccDocToBeFinancedByCashbot } from 'modules/cashbot/actions'
// Selectors
import { currentUserIsInternal } from 'modules/user/selectors'
import { getCashRegisters } from 'modules/cash-register/selectors'
import {
	getOrganizationProjects,
	getOrganizationBranches,
	getOrganizationAutomaticApproval,
} from 'modules/organization/selectors'
import { getAccountingDocumentCategories } from 'modules/settings/selectors'
import {
	getAccountingDocumentListFilterOpened,
	getPreProcessingAccountingDocumentPreview,
} from 'modules/accounting-document/selectors'
// Components and wrappers
import AccountingDocumentGrid from 'modules/accounting-document/components/accounting-document-list/grid'
import { connect } from 'react-redux'
import { isAccDocValid } from 'modules/accounting-document/domain/accounting-document'
import { EMPTY_ARRAY } from 'trivi-constants'

type StateProps = {|
	direction: ?AccountingDocumentDirection,
	view: AccountingDocumentView,
	showProcessingState: boolean,
	documentCategories: ?Array<AccountingDocumentCategory>,
	branches: ?Enum,
	projects: ?Enum,
	currencies: Array<Currency>,
	cashRegisters: ?CashRegisters,
	paymentTypes: ?Enum,
	currentUserIsInternal: boolean,
	automaticApproval: boolean,
	preProcessingAccountingDocumentPreview: boolean,
	isAdvancedFilterOpen: boolean,
|}

function mapStateToProps(state: State): StateProps {
	return {
		direction: state.accountingDocument.accountingDocumentList.direction,
		view: state.accountingDocument.accountingDocumentList.view,
		showProcessingState: true,

		isAdvancedFilterOpen: getAccountingDocumentListFilterOpened(state),

		currencies: state.organization.currenciesUsedInOrg.data || EMPTY_ARRAY,

		documentCategories: getAccountingDocumentCategories(state),
		branches: getOrganizationBranches(state),
		projects: getOrganizationProjects(state),
		cashRegisters: getCashRegisters(state),
		paymentTypes: state.common.paymentTypes.data,

		currentUserIsInternal: currentUserIsInternal(state),
		automaticApproval: getOrganizationAutomaticApproval(state),
		preProcessingAccountingDocumentPreview: getPreProcessingAccountingDocumentPreview(state),
	}
}

type DispatchProps = {|
	onViewChange: AccountingDocumentView => Promise<void>,
	onCustomerInstructionsChange: (string, AccountingDocument) => void,

	loadAccountingDocumentCategories: () => void,
	loadBranches: () => void,
	loadProjects: () => void,
	loadCurrencies: () => void,
	loadCashRegisters: () => void,
	loadPaymentTypes: () => void,
	refreshGrid: (defaultFilter?: Filter, filterName: string) => Promise<void>,
	refreshGridLine: (accountingDocumentId: string) => void,
	validateAccountingDocument: (accountingDocumentId: string, state: string) => Promise<boolean>,
	// changeEET: (accountingDocument: AccountingDocument, operation: EETOperation) => void,
	markToBeFinancedByCashbot: (accDocId: string) => void,
	onOpenFilter: () => void,
	onCloseFilter: () => void,
|}

type Action =
	| AccountingDocumentListAction
	| AccountingDocumentReduxAction
	| DashboardAction
	| CashRegisterAction
	| CommonAction
	| OrganizationAction
	| SettingsAction

const GRID_TYPE = 'accountingDocuments'
const GRID_ID = 'accountingDocuments'

function mapDispatchToProps(dispatch: Dispatch<Action | DataGridNextAction | CashbotAction>): DispatchProps {
	return {
		onViewChange: async (view: AccountingDocumentView): Promise<void> => {
			await dispatch(changeCurrentPage('accountingDocuments', 0))
			await dispatch(changeAccountingDocumentsView(view))
		},
		onCustomerInstructionsChange: (value: string, accountingDocument: AccountingDocument) => {
			if (accountingDocument.id) {
				dispatch(loadAccountingDocument(accountingDocument.id)).then(() => {
					dispatch(updateAccountingDocumentCustomerInstructions(accountingDocument.id || '', value)).then(() => {
						if (accountingDocument.id) {
							dispatch(refreshGridLine(GRID_TYPE, GRID_ID, accountingDocument.id))
						}
					})
				})
			}
		},

		loadAccountingDocumentCategories: () => {
			dispatch(loadAccountingDocumentCategories())
		},
		loadBranches: () => {
			dispatch(loadBranches())
		},
		loadProjects: () => {
			dispatch(loadProjects())
		},
		loadCashRegisters: () => {
			dispatch(loadCashRegisters())
		},
		loadCurrencies: () => {
			dispatch(loadCurrenciesUsedInOrg())
		},
		loadPaymentTypes: () => {
			dispatch(loadPaymentTypes())
		},
		refreshGrid: async (defaultFilter?: Filter, filterName: string) => {
			await dispatch(refreshGridData(GRID_TYPE, GRID_ID, filterName, defaultFilter))
		},
		refreshGridLine: (accountingDocumentId: string) => {
			dispatch(refreshGridLine(GRID_TYPE, GRID_ID, accountingDocumentId))
		},
		validateAccountingDocument: async (accountingDocumentId: string, state: string): Promise<boolean> =>
			isAccDocValid(accountingDocumentId, state, dispatch, true),
		// changeEET: (accountingDocument: AccountingDocument, operation: EETOperation) => {
		// 	dispatch(countryVariantUpdate(accountingDocument.id || '', { cz: { eet: { operation } } }))
		// },
		markToBeFinancedByCashbot: (accDocId: string) => {
			dispatch(markAccDocToBeFinancedByCashbot(accDocId, true))
		},
		onOpenFilter: () => {
			dispatch({
				type: 'GRID_CHANGE_OPENED_FILTER',
				name: 'accountingDocuments',
				isFilterOpened: true,
			})
		},
		onCloseFilter: () => {
			dispatch({
				type: 'GRID_CHANGE_OPENED_FILTER',
				name: 'accountingDocuments',
				isFilterOpened: false,
			})
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountingDocumentGrid)
