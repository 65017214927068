// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[3].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js!../../../variables/colors.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._36ZFCfjmNgp82aAGCbaUSg {\n\tdisplay: inline-block;\n\twidth: 20px;\n\theight: 20px;\n\tborder: 1px solid " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["grey400"] + ";\n\tbox-shadow: 3px 3px 0 0 rgba(0,0,0,0.08);\n\tborder-radius: 50%;\n\tcursor: pointer;\n\tmargin-right: 8px;\n}\n\n._2usQ-YWOvmn0eLqrOQYp0B {\n\tborder: 2px solid " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["white"] + ";\n}\n\n._36ZFCfjmNgp82aAGCbaUSg:hover {\n\tborder: 2px solid " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["white"] + ";\n}\n\n@media (max-width: 1650px) {\n\t._36ZFCfjmNgp82aAGCbaUSg {\n\t\twidth: 14px;\n\t\theight: 14px;\n\t\tbox-shadow: 2px 2px 0 0 rgba(0,0,0,0.08);\n\t\tmargin-right: 5px;\n\t}\n}\n", "",{"version":3,"sources":["webpack://./modules/internal/components/simple-color-picker.css"],"names":[],"mappings":"AAEA;CACC,qBAAA;CACA,WAAA;CACA,YAAA;CACA,8DAAA;CACA,wCAAA;CACA,kBAAA;CACA,eAAA;CACA,iBAAA;AAaD;;AAVA;CAEC,8DAAA;AAaD;;AAVA;CACC,8DAAA;AAaD;;AAVA;CACC;EACC,WAAA;EACA,YAAA;EACA,wCAAA;EACA,iBAAA;CAaD;AACD","sourcesContent":["@value black, white, blue, grey400 from \"variables/colors.css\";\n\n.normal {\n\tdisplay: inline-block;\n\twidth: 20px;\n\theight: 20px;\n\tborder: 1px solid grey400;\n\tbox-shadow: 3px 3px 0 0 rgba(0,0,0,0.08);\n\tborder-radius: 50%;\n\tcursor: pointer;\n\tmargin-right: 8px;\n}\n\n.active {\n\tcomposes: normal;\n\tborder: 2px solid white;\n}\n\n.normal:hover {\n\tborder: 2px solid white;\n}\n\n@media (max-width: 1650px) {\n\t.normal {\n\t\twidth: 14px;\n\t\theight: 14px;\n\t\tbox-shadow: 2px 2px 0 0 rgba(0,0,0,0.08);\n\t\tmargin-right: 5px;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"black": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["black"] + "",
	"white": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["white"] + "",
	"blue": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["blue"] + "",
	"grey400": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["grey400"] + "",
	"normal": "_36ZFCfjmNgp82aAGCbaUSg",
	"active": "_2usQ-YWOvmn0eLqrOQYp0B _36ZFCfjmNgp82aAGCbaUSg"
};
export default ___CSS_LOADER_EXPORT___;
