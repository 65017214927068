// @flow
/** @jsx jsx */

import { createRef, PureComponent, Fragment } from 'react'
import { jsx } from '@emotion/core'
import memoize from 'memoize-one'
import Tooltip from 'components/tooltip'

type Props = {|
	text: ?string,
|}

type State = {|
	enabledTooltip: boolean,
|}

/** show whole text or shortened text with ellipsis and whole text in tooltip */
class FullText extends PureComponent<Props, State> {
	textRef = createRef()
	constructor(props: Props) {
		super(props)
		this.state = {
			enabledTooltip: false,
		}
	}

	checkTextOverflow = memoize((element: any) => {
		this.setState({ enabledTooltip: element ? element.scrollWidth > element.clientWidth : false })
	})

	componentDidMount() {
		this.checkTextOverflow(this.textRef.current)
	}

	render() {
		const textElement = (
			<div css={styles.wrapper}>
				<div css={styles.text} ref={this.textRef}>
					{this.props.text}
				</div>
			</div>
		)

		return this.state.enabledTooltip ? (
			<Tooltip wrapperStyle={styles.tooltip} label={this.props.text} multiLine>
				{textElement}
			</Tooltip>
		) : (
			<Fragment>{textElement}</Fragment>
		)
	}
}

const styles = {
	wrapper: {
		width: '100%',
		overflow: 'hidden',
	},
	text: {
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
	tooltip: {
		display: 'flex',
		flexGrow: 1,
		minWidth: 0, // aby sa spravne zobrazil v pripade ze je to flex-item
	},
}

export default FullText
