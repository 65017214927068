/* @flow */
/** @jsx jsx */

import { jsx } from '@emotion/core'
import { Component } from 'react'
import { /*Avatar,*/ Paper /*TriviLink*/ } from 'components'
import type { OrganizationMember, OrganizationMembers, Task, TasksSearchResult } from 'types'
// import ContentAdd from 'components/svg-icons/content/add'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import { colors } from 'variables'
import { find } from 'lodash-es'
import { formatToDateString } from 'utils/formatters'
import styles from './my-tasks.css'
// import { taskCreateRoute } from 'modules/task/routing/routes'

type Props = {|
	...WithTranslateProps,
	myTasks: ?TasksSearchResult,
	organizations: ?OrganizationMembers,
	onTaskClick: (task: Task) => void,
|}

class MyTasks extends Component<Props> {
	getHandleTaskClick = (task: Task) => {
		const { onTaskClick } = this.props
		return () => onTaskClick(task)
	}

	renderMyTask = (task: Task, index: number, tasks: Array<Task>) => {
		const organization = find(
			this.props.organizations,
			(om: OrganizationMember) => (om.organizationId || '') == (task.organizationId || 0).toString(),
		)
		const organizationName =
			organization == null ? null : organization.organizationName == null ? null : organization.organizationName

		const className = task.state === 0 ? 'greyTask' : task.state === 1 ? 'orangeTask' : 'greenTask'

		return (
			<div
				className={styles[className]}
				key={`${index}/${tasks.length}|${task.id || ''}`}
				onClick={this.getHandleTaskClick(task)}
			>
				<div className={styles.title}>{task.title}</div>
				<div css={style.info}>
					{task.authorName} {task.dueDate ? formatToDateString(task.dueDate) : ''}
				</div>
				<div css={style.info}>{organizationName}</div>
			</div>
		)
	}

	renderMyTasks = () => {
		const { myTasks } = this.props

		if (myTasks != null && myTasks.tasks != null && myTasks.tasks.length) return myTasks.tasks.map(this.renderMyTask)
		else return this.props.t('application.noData')
	}

	render() {
		const { t } = this.props

		return (
			<div>
				<div css={style.header}>
					<h4 css={style.headline}>{t('internal.widget.myTasks.headline')}</h4>
					{/*<TriviLink style={style.add} routeFn={taskCreateRoute}>*/}
					{/*	{t('internal.widget.myTasks.add')}*/}
					{/*	<Avatar*/}
					{/*		style={style.avatar}*/}
					{/*		size={32}*/}
					{/*		backgroundColor={colors.orange}*/}
					{/*		borderColor={colors.orange600}*/}
					{/*		icon={<ContentAdd size={18} hoverColor={colors.white} />}*/}
					{/*	/>*/}
					{/*</TriviLink>*/}
				</div>
				<Paper rounded zDepth={2} style={style.list}>
					{this.renderMyTasks()}
				</Paper>
			</div>
		)
	}
}

const style = {
	add: {
		display: 'block',
		fontSize: 18,
		lineHeight: '60px',
	},
	headline: {
		color: 'black',
		fontSize: 24,
		lineHeight: '80px',
		fontWeight: 'normal',
		margin: 0,
	},
	header: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		alignContent: 'center',
	},
	avatar: {
		marginLeft: 14,
		verticalAlign: 'middle',
	},
	list: {
		padding: 15,
		maxHeight: 601,
		overflow: 'auto',
	},
	info: {
		fontSize: 12,
		lineHeight: '20px',
		color: colors.blackFaded60,
	},
}

export default withTranslate(MyTasks)
