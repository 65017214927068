/* @flow */

import { connect } from 'react-redux'
import type {
	AccountingDocumentType,
	AccountingDocument,
	Sequence,
	State,
	FormFieldErrorContext,
	FormFieldErrorMessage,
} from 'types'
import InvoiceHeaderNo from 'modules/accounting-document/components/invoice-elements/header-no'
import { getAccountingDocument } from 'modules/accounting-document/selectors'
import { getAccountingDocumentSequences } from 'modules/accounting-document/selectors/sequences'
import { useSimplifiedNumbering } from 'modules/accounting-document/domain/accounting-document'
import type { ValidationMessageType, ValidationValueType } from 'wrappers/validate'
import { getFormFieldErrors } from 'modules/common/selectors'
import { removeError } from 'helpers'
import { getAccountingDocumentNumberingDate } from '../../selectors'
import { currentUserIsInternal } from '../../../user/selectors'

export type OwnProps = {|
	accountingDocumentId: string,
	direction: 'issued' | 'received',
	type: AccountingDocumentType,
	readonly?: boolean,
	disableExplicitNo?: boolean,

	sequenceId?: string,
	explicitNo?: string,
	externalNo?: string,
	accountingDocumentNo?: string,
	accountingDocumentNoPreview?: string,
	cashRegisterId?: ?string,

	onExplicitNoChange: (explicitNo: string) => void,
	onSequenceIdChange: (sequenceId: string) => void,
	onExternalNoChange: (externalNo: string) => void,

	validationMessage?: ValidationMessageType,
	validationValue?: ValidationValueType,
	isTemplate?: boolean,
	formFieldErrorContext?: FormFieldErrorContext,
|}

type StateProps = {|
	availableSequences: ?Array<Sequence>,
	isAllowedToChangeToSequence: boolean,
	readonly?: boolean,
	errors: ?Array<FormFieldErrorMessage>,
|}

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => {
	const doc: ?AccountingDocument = getAccountingDocument(state, ownProps.accountingDocumentId)
	const readonly =
		ownProps.readonly ||
		(!!ownProps.accountingDocumentNo && (!doc || doc.state !== 'Fixing')) ||
		(!currentUserIsInternal(state) && !!doc && doc.state === 'Fixing')
	const date = getAccountingDocumentNumberingDate(state, ownProps.accountingDocumentId)

	return {
		availableSequences: !readonly
			? getAccountingDocumentSequences(
					state,
					ownProps.direction,
					ownProps.type,
					ownProps.cashRegisterId || undefined,
					date || new Date(),
					!!useSimplifiedNumbering(ownProps.type, state),
			  )
			: null,
		isAllowedToChangeToSequence: !doc || doc.state !== 'Fixing',
		errors: ownProps.formFieldErrorContext && getFormFieldErrors(state, ownProps.formFieldErrorContext),
		readonly,
	}
}

type DispatchProps = {|
	removeError: () => void,
|}

const mapDispatchToProps = (dispatch: Dispatch<any>, ownProps: OwnProps): DispatchProps => {
	return {
		removeError: () => {
			removeError({
				formFieldErrorContext: ownProps.formFieldErrorContext,
				dispatch,
			})
		},
	}
}

const mergeProps = (stateProps: StateProps, dispatchProps: DispatchProps, ownProps: OwnProps) => {
	const { formFieldErrorContext, ...reducedOwnProps } = ownProps

	return {
		...dispatchProps,
		...reducedOwnProps,
		...stateProps,
	}
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(InvoiceHeaderNo)
