import React from 'react'
import SvgIcon from 'components/SvgIcon'
import pure from 'recompose/pure'

let LargeWord = props => {
	props = {
		viewBox: '0 0 24 30',
		...props,
	}
	return (
		<SvgIcon {...props}>
			<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g transform="translate(-731.000000, -10404.000000)">
					<g id="word-ico-24" transform="translate(731.000000, 10404.000000)">
						<path
							d="M2.985,0 C1.335,0 0,1.35 0,3 L0.015,27 C0.015,28.65 1.35,30 3,30 L15,30 L24,21 L24,3 C24,1.35 22.65,0 21,0 L2.985,0 Z"
							fill="#34424E"
							transform="translate(12.000000, 15.000000) scale(1, -1) translate(-12.000000, -15.000000) "
						/>
						<path
							d="M14.8724664,24.4963117 C15.6072175,24.4963117 16.2171996,23.9972354 16.3974215,23.3456637 L18.3244103,16.6219978 C18.3521368,16.5110919 18.366,16.3863229 18.366,16.2615538 C18.366,15.5683924 17.8114709,15 17.1044462,15 C16.5083274,15 15.9953879,15.4297601 15.8706188,15.9981525 L14.6783812,21.2939058 L13.2781951,15.8595202 C13.153426,15.3604439 12.7098027,15 12.183,15 C11.6561973,15 11.212574,15.3604439 11.0878049,15.8595202 L9.68761883,21.2939058 L8.48151794,15.9842892 C8.35674888,15.4297601 7.85767265,15 7.24769058,15 C6.55452915,15 6,15.5683924 6,16.2615538 C6,16.3863229 6.01386323,16.4972287 6.04158969,16.6081345 L7.96857848,23.3456637 C8.14880045,23.9972354 8.75878251,24.4963117 9.49353363,24.4963117 C10.2560112,24.4963117 10.8798565,23.969509 11.032352,23.2624843 L12.183,18.576713 L13.333648,23.2624843 C13.4861435,23.969509 14.123852,24.4963117 14.8724664,24.4963117 Z"
							fill="#FFFFFF"
						/>
						<polygon
							fill="#08B0CD"
							transform="translate(19.500000, 4.500000) rotate(-90.000000) translate(-19.500000, -4.500000) "
							points="15 9 15 0 24 0"
						/>
					</g>
				</g>
			</g>
		</SvgIcon>
	)
}
LargeWord = pure(LargeWord)

export default LargeWord
