/* @flow */

import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { Popover } from 'components'
import Attachment from 'components/svg-icons/file/attachment'
import styles from './task-list-attachments.css'

export type Props = {|
	children: any,
|}

type State = {|
	anchorEl: null | Element | Text,
	timeout: ?TimeoutID,
|}

class TaskListAttachments extends Component<Props, State> {
	anchorOrigin = { horizontal: 'middle', vertical: 'bottom' }
	targetOrigin = { horizontal: 'middle', vertical: 'top' }

	state = {
		anchorEl: null,
		timeout: null,
	}

	onMouseEnter = () => {
		this.state.timeout && clearTimeout(this.state.timeout)
		this.setState({
			anchorEl: ReactDOM.findDOMNode(this.refs.root),
		})
	}

	onMouseLeave = () => {
		this.hidePopoverAfterTimeout()
	}

	handleOnRequestClose = () => {
		this.hidePopoverAfterTimeout()
	}

	hidePopoverAfterTimeout = () => {
		let timeout = setTimeout(this.hidePopoverImmediately, 100)
		this.setState({
			timeout,
		})
	}

	hidePopoverImmediately = () => {
		this.setState({
			anchorEl: null,
		})
	}

	render() {
		return (
			<div ref="root" className={styles.root}>
				<Attachment
					className={styles.attachmentIcon}
					onMouseEnter={this.onMouseEnter}
					onMouseLeave={this.onMouseLeave}
				/>
				<Popover
					open={!!this.state.anchorEl}
					anchorEl={this.state.anchorEl}
					anchorOrigin={this.anchorOrigin}
					targetOrigin={this.targetOrigin}
					zDepth={5}
					className={styles.popover}
					onRequestClose={this.handleOnRequestClose}
					arrow={'top'}
					onMouseEnter={this.onMouseEnter}
					onMouseLeave={this.onMouseLeave}
				>
					<div className={styles.children}>{this.props.children}</div>
				</Popover>
			</div>
		)
	}
}

export default TaskListAttachments
