/* @flow */

import { connect } from 'react-redux'
import type { State, UserProfile } from 'types'
import EmptyDashBoard from 'modules/dashboard/pages/empty-dashboard'

type StateProps = {
	me: ?UserProfile,
}

const mapStateToProps = (state: State): StateProps => {
	return {
		me: state.user.me.data,
	}
}

export default connect(mapStateToProps)(EmptyDashBoard)
