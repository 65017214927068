/* @flow */

import {
	ACCOUNTING_DOCUMENT_DIRECTION_TYPES,
	AccountingDocumentDirection_Number,
	AccountingDocument_GreenboxSuggestionRequest,
	ContactBankAccount_AccountingDocumentBankAccount,
	Name_AccountingDocumentType,
	Number_AccountingDocumentAssignedDirection,
	Number_AccountingDocumentAssignedType,
	Number_AccountingDocumentDirection,
	Number_AccountingDocumentType,
} from 'types/convertor'
import { API_NOT_FOUND_CODE, TIMEOUT_FOR_UPLOADING_FILES } from 'trivi-constants'
import type {
	AccountingDocument,
	AccountingDocumentAttachment,
	AccountingDocumentBankAccount,
	AccountingDocumentConnection,
	AccountingDocumentContact,
	AccountingDocumentCreateRequest,
	AccountingDocumentDefaults,
	AccountingDocumentDirection,
	AccountingDocumentExtractingCustomerInstructions,
	AccountingDocumentExtractingNoteBody,
	AccountingDocumentExtractingState,
	AccountingDocumentExtractsCountResponse,
	AccountingDocumentExtractsFetchResponse,
	AccountingDocumentHeader,
	AccountingDocumentIntegrationRequest,
	AccountingDocumentIntegrationsResponse,
	AccountingDocumentLanguage,
	AccountingDocumentLineItem,
	AccountingDocumentNote,
	AccountingDocumentPatchPreferredBranchAndProject,
	AccountingDocumentPaymentInfo,
	AccountingDocumentPrintingInfo,
	AccountingDocumentProcessingStateRequest,
	AccountingDocumentReduxAction,
	AccountingDocumentScan,
	AccountingDocumentSearchExport,
	AccountingDocumentStateInfo,
	AccountingDocumentType,
	AccountingDocumentVatRecapInfo,
	AcknowledgeRequest,
	ActionFunction,
	AddressBookAction,
	CommonAction,
	Contact,
	ContactBankAccount,
	CountryVariantRequest,
	Dispatch,
	DownloadToken,
	EnumItem,
	ExtractingState,
	FinishConnectAccountingDocumentConnectionAction,
	GetState,
	GreenboxSuggestionRequest,
	GreenboxSuggestionResponse,
	IssueAction,
	OrganizationAction,
	OssMode,
	PaymentReminder,
	PortalLanguage,
	PrintLanguage,
	PublicAccountingDocumentResponse,
	SequencesAction,
	SettingsAction,
	State,
	UserAction,
	ValidationError,
} from 'types'
import {
	AccountingDocumentScanMoveToBanks as AccountingDocumentScanMoveToBanksApi,
	AccountingDocumentScanMoveToDocuments as AccountingDocumentScanMoveToDocumentsApi,
	accDocintegrations as accDocintegrationsApi,
	accountingDocumentAcknowledge as accountingDocumentAcknowledgeApi,
	accountingDocumentActivities as accountingDocumentActivitiesApi,
	accountingDocument as accountingDocumentApi,
	accountingDocumentAttachmentDocument as accountingDocumentAttachmentDocumentApi,
	accountingDocumentAttachments as accountingDocumentAttachmentsApi,
	accountingDocumentBankAccount as accountingDocumentBankAccountApi,
	accountingDocumentBankAccounts as accountingDocumentBankAccountsApi,
	accountingDocumentConnnection as accountingDocumentConnnectionApi,
	accountingDocumentConnnections as accountingDocumentConnnectionsApi,
	accountingDocumentContact as accountingDocumentContactApi,
	accountingDocumentDraft as accountingDocumentDraftApi,
	accountingDocumentExtractingInstructions as accountingDocumentExtractingInstructionsApi,
	accountingDocumentExtractingNote as accountingDocumentExtractingNoteApi,
	accountingDocumentExtractingState as accountingDocumentExtractingStateApi,
	accountingDocumentExtracts as accountingDocumentExtractsApi,
	accountingDocumentHeader as accountingDocumentHeaderApi,
	accountingDocumentLineItem as accountingDocumentLineItemApi,
	accountingDocumentLineItems as accountingDocumentLineItemsApi,
	accountingDocumentLineItemsSearch as accountingDocumentLineItemsSearchApi,
	accountingDocumentNextStates as accountingDocumentNextStatesApi,
	accountingDocumentNote as accountingDocumentNoteApi,
	accountingDocumentPaymentReminder as accountingDocumentPaymentReminderApi,
	accountingDocumentPaymentReminders as accountingDocumentPaymentRemindersApi,
	accountingDocumentPayments as accountingDocumentPaymentsApi,
	preferredBranchandProject as accountingDocumentProjectAndBranchDefaultsApi,
	accountingDocumentReset as accountingDocumentResetApi,
	accountingDocumentScan as accountingDocumentScanApi,
	accountingDocumentScans as accountingDocumentScansApi,
	accountingDocumentState as accountingDocumentStateApi,
	accountingDocumentStateToProcessed as accountingDocumentStateToProcessedApi,
	accountingDocumentsExportRequest as accountingDocumentsExportRequestApi,
	accountingDocumentsSend as accountingDocumentsSendApi,
	accountingDocumentsToExtractCount as accountingDocumentsToExtractCountApi,
	countryVariantSpecific as countryVariantSpecificApi,
	creditNote as creditNoteApi,
	exchRateResetRequest as exchRateResetRequestApi,
	forceProcessing as forceProcessingApi,
	greenboxAccountingDocumentFinAccounts as greenboxAccountingDocumentFinAccountsApi,
	printRequest as printRequestApi,
	publicAccountingDocument as publicAccountingDocumentApi,
	vatRecap as vatRecapApi,
} from 'modules/common/models/api-model'
import { beginTask, endTask } from 'utils/loader'
import { buildPublicUrl, createXHR, loadBase64Data } from 'lib/apiHandler'
import { convertToDate, formatDateToIsoString, toDateOnlyIsoString } from 'utils/formatters'
import {
	createEmptyLineItem,
	getAccDocType,
	getConnectionFromDocument,
	getDefaultSequence,
	getDuePeriod,
	getPrintingInfo,
	isAccDocDomestic,
	isCurrentOrganizationVatFree,
} from '../domain/accounting-document'
import {
	emptyAccountingDocument,
	emptyAccountingDocumentBankAccount,
	emptyAccountingDocumentDefaults,
	emptyAccountingDocumentHeader,
	emptyAccountingDocumentLineItem,
} from 'types/empty'
import {
	getAccDocDefaultDueDate,
	getAccountingDocumentDefaults,
	getCurrentOrganizationRegNo,
} from 'modules/settings/selectors'
import {
	getAccountingDocument,
	getAccountingDocumentBankAccount,
	getAccountingDocumentConnection,
	getAccountingDocumentContact,
	getAccountingDocumentLineItem,
	getAccountingDocumentPrintLanguage,
	getAccountingDocumentRaw,
	getAccountingDocumentVatRecapInfo,
	getDefaultDuePeriod,
	getVatCountryTypeByCountry,
} from 'modules/accounting-document/selectors'
import { getAppLanguage, toAvailableAccountingDocumentLanguage } from 'locales'
import { getOrganizationBranches, getOrganizationProjects } from 'modules/organization/selectors'
import {
	isAccountingDocumentOssEnabled,
	isAccountingDocumentUnknownExtracted,
	isAccountingDocumentVatFree,
} from '../selectors'
import { isNil, mergeWith, set } from 'lodash-es'
import { loadBranches, loadProjects } from 'modules/organization/actions'
import { loadCountries, showServerError } from '../../common/actions'

import type { AccountingDocumentItem } from 'modules/accounting-document/reducers/accounting-documents'
import { AccountingDocumentTypeNumber_isSimple } from '../../../types/operations'
import Tracking from 'utils/tracking'
import { accountingDocumentsSendReminder } from '../../common/models/api-model'
import { clearVatRatesInLineItem } from '../helpers'
import createThrottle from 'lib/create-throttle'
import diff from 'json-patch-gen'
import { editAccountingDocumentRoute } from 'modules/accounting-document/routing/routes'
import { getBankAccounts } from 'modules/bank/selectors'
import { getContact } from 'modules/address-book/selectors'
import { getDefaultBankAccount } from 'modules/accounting-document/domain/bank-account'
// import { isEetUsedOrSent } from 'modules/accounting-document/domain/eet'
import { getServerAndValidationErrors } from 'helpers'
import { loadAccountingDocumentDefaults } from 'modules/settings/actions'
import { loadContact } from 'modules/address-book/actions'
import { loadIssues } from './issues'
import { loadSequence } from 'modules/accounting-document/actions'
import { notFoundRoute } from 'modules/navigation/routing/routes'
import { push } from 'react-router-redux'

export const DEFAULT_DUE_DATE_INTERVAL = 14

function getDefaultVatRecapCalculationMode(type: ?number) {
	if (AccountingDocumentTypeNumber_isSimple(type)) return 2
	return 0
}

function getAccDocDirection(accDoc: AccountingDocument): ?AccountingDocumentDirection {
	return (!isNil(accDoc.direction) && Number_AccountingDocumentDirection(accDoc.direction)) || null
}

export async function getAccDocDefaults(
	state: State,
	dispatch: Dispatch<*>,
	accDoc: AccountingDocument,
): Promise<AccountingDocumentDefaults> {
	const type: ?AccountingDocumentType = getAccDocType(accDoc)
	const direction: ?AccountingDocumentDirection = getAccDocDirection(accDoc)
	const accDocDefaults: AccountingDocumentDefaults =
		(type && direction && (await getOrLoadAccountingDocumentDefaults(state, dispatch, direction, type))) ||
		emptyAccountingDocumentDefaults()

	return new Promise((resolve: Function) => {
		resolve(accDocDefaults)
	})
}

async function getAccDocDefaultDuePeriod(
	state: State,
	dispatch: Dispatch<any>,
	accDoc: AccountingDocument,
): Promise<number> {
	const documentDefaults: AccountingDocumentDefaults = await getAccDocDefaults(state, dispatch, accDoc)
	return new Promise((resolve: Function) => {
		resolve(documentDefaults.dueDateInterval || getDefaultDuePeriod())
	})
}

export function createAccountingDocument(presetFields: $Shape<AccountingDocument>) {
	return async (
		dispatch: Dispatch<AccountingDocumentReduxAction | SettingsAction | SequencesAction>,
		getState: () => State,
	) => {
		dispatch({
			type: 'PREPARE_CREATE_ACCOUNTING_DOCUMENT',
		})

		const state: State = getState()
		let accountingDocument: AccountingDocument = { ...presetFields } || emptyAccountingDocument()
		const isAccounting: boolean = !accountingDocument.cashRegisterId

		const type: ?AccountingDocumentType = getAccDocType(accountingDocument)
		const direction: ?AccountingDocumentDirection = getAccDocDirection(accountingDocument)
		const documentDefaults: AccountingDocumentDefaults = await getAccDocDefaults(state, dispatch, accountingDocument)

		// Default currency
		if (isNil(accountingDocument.currency)) {
			accountingDocument.currency = documentDefaults.currency || 'CZK'
		}

		// Default payment type
		if (isNil(accountingDocument.paymentType)) {
			accountingDocument.paymentType = documentDefaults.paymentType || 1 // 1 = BankTransfer
		}

		// Default constant symbol
		if (isNil(accountingDocument.constSymbol)) {
			accountingDocument.constSymbol = documentDefaults.constantSymbol
		}

		// Default rounding type
		if (isNil(accountingDocument.roundingType) && isAccounting) {
			accountingDocument.roundingType = documentDefaults.roundingType
		}

		// Default issue date
		const issueDate: Date = accountingDocument.issueDate ? new Date(accountingDocument.issueDate) : new Date()
		const issueDateString: string = formatDateToIsoString(issueDate)
		accountingDocument.issueDate = issueDateString

		// Default tax date
		if (isNil(accountingDocument.taxDate)) {
			accountingDocument.taxDate = issueDateString
		}

		// Default booking date
		if (isNil(accountingDocument.bookingDate)) {
			accountingDocument.bookingDate = issueDateString
		}

		// Default tax return date
		if (direction === 'received') {
			accountingDocument.taxReturnDate =
				isNil(accountingDocument.taxReturnDate) ||
				(accountingDocument.taxReturnDate && new Date(accountingDocument.taxReturnDate) < issueDate)
					? issueDateString
					: accountingDocument.taxReturnDate
		}

		// Default bank accounts on issued
		if (direction === 'issued') {
			const defaultBankAccount = getDefaultBankAccount(getBankAccounts(state))
			if (!accountingDocument.bankAccounts && defaultBankAccount) {
				accountingDocument.bankAccounts = [defaultBankAccount]
			}
		}

		// Default bank accounts on received
		if (direction === 'received') {
			// do not override preset value
			if (!accountingDocument.bankAccounts) {
				accountingDocument.bankAccounts = [emptyAccountingDocumentBankAccount()]
			}
		}

		// Default due date
		if (isNil(accountingDocument.dueDate)) {
			const dueDate = new Date()
			dueDate.setDate(issueDate.getDate() + (documentDefaults.dueDateInterval || DEFAULT_DUE_DATE_INTERVAL))
			const dueDateString: string = formatDateToIsoString(dueDate)
			accountingDocument.dueDate = dueDateString
		}

		// Default vat country type
		if (isNil(accountingDocument.vatCountryType)) {
			accountingDocument.vatCountryType = 1
		}

		// Default vatRecapCalculationMode
		if (isNil(accountingDocument.vatRecapCalculationMode)) {
			accountingDocument.vatRecapCalculationMode = getDefaultVatRecapCalculationMode(accountingDocument.type)
		}

		// Default line items
		if (isNil(accountingDocument.lineItems)) {
			const defaultVatRateId = documentDefaults && documentDefaults.domesticVatRateId
			accountingDocument.lineItems = [createEmptyLineItem(defaultVatRateId, isCurrentOrganizationVatFree(state))]
		}
		// Default printing info
		const userLanguage: PortalLanguage = getAppLanguage()
		const accDocLanguage = toAvailableAccountingDocumentLanguage(userLanguage)

		accountingDocument.printing = {
			...(accDocLanguage ? getPrintingInfo(documentDefaults, accDocLanguage) : {}),
			...accountingDocument.printing,
		}

		// Default paymentType for issued credit note
		if ('credit_note' === type) {
			accountingDocument.paymentType = 5 // 5 - SetOff
			accountingDocument.bankAccounts = []
		}

		const loaderId = 'accDoc_createAccountingDocument'
		dispatch({
			type: 'START_CREATE_ACCOUNTING_DOCUMENT',
			accountingDocument: accountingDocument,
		})
		try {
			beginTask(loaderId)
			//set default sequence
			if (!accountingDocument.sequenceId) {
				const defaultSequence = getDefaultSequence(state, type, direction, accountingDocument.cashRegisterId, issueDate)
				if (defaultSequence) {
					accountingDocument.sequenceId = defaultSequence.id
				}
			}

			// $FlowFixMe
			const accountingDocumentCreateRequest: AccountingDocumentCreateRequest = {
				...accountingDocument,
				settings: {
					oss: {
						value:
							(accountingDocument.settings &&
								accountingDocument.settings.oss &&
								accountingDocument.settings.oss.forcedValue) ||
							null,
					},
				},
			}

			const result: AccountingDocument = await accountingDocumentDraftApi.post({}, accountingDocumentCreateRequest)
			return dispatch({
				type: 'FINISH_CREATE_ACCOUNTING_DOCUMENT',
				result,
			})
		} catch (error) {
			dispatch({
				type: 'FINISH_CREATE_ACCOUNTING_DOCUMENT',
				result: null,
				serverError: error,
			})
			return Promise.reject()
		} finally {
			endTask(loaderId)
		}
	}
}

export function loadPublicAccountingDocumentLogo(uniqueId: string) {
	return async (dispatch: Dispatch<AccountingDocumentReduxAction>) => {
		dispatch({
			type: 'START_LOAD_PUBLIC_ACCOUNTING_DOCUMENT_LOGO',
			uniqueId,
		})
		loadBase64Data(`accountingdocuments/uniqueid/${uniqueId}/logo`, true)
			.then((binary: string) => {
				dispatch({
					type: 'FINISH_LOAD_PUBLIC_ACCOUNTING_DOCUMENT_LOGO',
					uniqueId,
					binary,
				})
			})
			.catch((serverError: ValidationError) => {
				dispatch({
					type: 'FINISH_LOAD_PUBLIC_ACCOUNTING_DOCUMENT_LOGO',
					binary: null,
					serverError,
					uniqueId,
				})
			})
	}
}

export function loadPublicAccountingDocument(uniqueId: string) {
	return async (dispatch: Dispatch<AccountingDocumentReduxAction>) => {
		const loaderId = 'accDoc_loadPublicAccountingDocument'
		dispatch({
			type: 'START_LOAD_PUBLIC_ACCOUNTING_DOCUMENT',
			uniqueId,
		})
		try {
			beginTask(loaderId)
			const response: PublicAccountingDocumentResponse = await publicAccountingDocumentApi.get({
				uniqueId,
			})

			dispatch({
				type: 'FINISH_LOAD_PUBLIC_ACCOUNTING_DOCUMENT',
				accountingDocument: response.accountingDocument,
				printingSettings: response.printingSettings,
				uniqueId,
			})
		} catch (error) {
			await dispatch({
				type: 'FINISH_LOAD_PUBLIC_ACCOUNTING_DOCUMENT',
				accountingDocument: null,
				printingSettings: null,
				serverError: error,
				uniqueId,
			})

			if (error.code === API_NOT_FOUND_CODE) {
				dispatch(push(notFoundRoute()))
			}
		} finally {
			endTask(loaderId)
		}
	}
}

export function loadAccountingDocument(accountingDocumentId: string) {
	return async (dispatch: Dispatch<AccountingDocumentReduxAction>) => {
		const loaderId = 'accDoc_loadAccountingDocument'
		dispatch({
			type: 'START_LOAD_ACCOUNTING_DOCUMENT',
			accountingDocumentId,
		})
		try {
			beginTask(loaderId)
			const accountingDocument: AccountingDocument = await accountingDocumentApi.get({
				accountingDocumentId,
			})
			dispatch({
				type: 'FINISH_LOAD_ACCOUNTING_DOCUMENT',
				accountingDocumentId,
				accountingDocument,
			})
		} catch (error) {
			await dispatch({
				type: 'FINISH_LOAD_ACCOUNTING_DOCUMENT',
				accountingDocumentId,
				accountingDocument: null,
				serverError: error,
			})

			if (error.code === API_NOT_FOUND_CODE) {
				dispatch(push(notFoundRoute()))
			}
		} finally {
			endTask(loaderId)
		}
	}
}

export function updateAccountingDocument(
	oldAccountingDocument: AccountingDocument,
	newAccountingDocument: AccountingDocument,
) {
	return async (dispatch: Dispatch<AccountingDocumentReduxAction>) => {
		dispatch({
			type: 'START_UPDATE_ACCOUNTING_DOCUMENT',
			newAccountingDocument,
		})
		try {
			const patch = diff(oldAccountingDocument, newAccountingDocument)
			const response: AccountingDocument = await accountingDocumentApi.patch(
				{ accountingDocumentId: newAccountingDocument.id || '' },
				patch,
			)

			dispatch({
				type: 'FINISH_UPDATE_ACCOUNTING_DOCUMENT',
				newAccountingDocument: response,
				oldAccountingDocument,
				serverError: null,
			})
		} catch (error) {
			dispatch({
				type: 'FINISH_UPDATE_ACCOUNTING_DOCUMENT',
				newAccountingDocument: null,
				oldAccountingDocument,
				serverError: error,
			})
		}
	}
}

export function resetAccountingDocument(accountingDocumentId: string) {
	return async (dispatch: Dispatch<AccountingDocumentReduxAction>) => {
		dispatch({
			type: 'START_RESET_ACCOUNTING_DOCUMENT',
			accountingDocumentId,
		})
		try {
			const accDoc: AccountingDocument = await accountingDocumentResetApi.post({
				accountingDocumentId,
			})
			return dispatch({
				type: 'FINISH_RESET_ACCOUNTING_DOCUMENT',
				accountingDocumentId,
				accountingDocument: accDoc,
				success: true,
			})
		} catch (error) {
			return dispatch({
				type: 'FINISH_RESET_ACCOUNTING_DOCUMENT',
				accountingDocumentId,
				success: false,
				serverError: error,
			})
		}
	}
}

export function removeAccountingDocument(accountingDocumentId: string) {
	return async (dispatch: Dispatch<AccountingDocumentReduxAction>) => {
		dispatch({
			type: 'START_REMOVE_ACCOUNTING_DOCUMENT',
			accountingDocumentId,
		})
		try {
			await accountingDocumentApi.delete({
				accountingDocumentId,
			})
			await dispatch({
				type: 'FINISH_REMOVE_ACCOUNTING_DOCUMENT',
				accountingDocumentId,
				success: true,
			})
		} catch (error) {
			dispatch({
				type: 'FINISH_REMOVE_ACCOUNTING_DOCUMENT',
				accountingDocumentId,
				success: false,
				serverError: error,
			})
		}
	}
}

export function clearLineItemsVatRates(accountingDocumentId: string) {
	return async (dispatch: Dispatch<AccountingDocumentReduxAction>, getState: () => State) => {
		const state: State = getState()
		const accDoc: ?AccountingDocument = getAccountingDocument(state, accountingDocumentId)
		if (accDoc && accDoc.lineItems && accDoc.lineItems.length) {
			return await Promise.all(
				accDoc.lineItems.map((item: AccountingDocumentLineItem) => {
					return dispatch(updateAccountingDocumentLineItem(accountingDocumentId, clearVatRatesInLineItem(item), false))
				}),
			)
		}
	}
}

export function createAccountingDocumentLineItem(accountingDocumentId: string) {
	return async (
		dispatch: Dispatch<AccountingDocumentReduxAction>,
		getState: () => State,
	): Promise<AccountingDocumentReduxAction> => {
		const state: State = getState()
		const accDoc: ?AccountingDocument = getAccountingDocument(state, accountingDocumentId)

		const documentDefaults: AccountingDocumentDefaults =
			(accDoc && (await getAccDocDefaults(state, dispatch, accDoc))) || emptyAccountingDocumentDefaults()

		const lineItem = emptyAccountingDocumentLineItem()
		if (!isAccountingDocumentVatFree(state, accountingDocumentId) && isAccDocDomestic(accDoc)) {
			lineItem.vatRateId = documentDefaults.domesticVatRateId
		}

		// Při vytvoření nové řádky se předvyplňuje středisko a zakázka, které jsou nastavené u dokladu jako preferované
		lineItem.branch = (accDoc && accDoc.preferredBranch) || undefined
		lineItem.project = (accDoc && accDoc.preferredProject) || undefined

		return dispatch(addAccountingDocumentLineItem(accountingDocumentId, lineItem))
	}
}

export function addAccountingDocumentLineItem(accountingDocumentId: string, lineItem: AccountingDocumentLineItem) {
	return async (dispatch: Dispatch<AccountingDocumentReduxAction>): Promise<AccountingDocumentReduxAction> => {
		dispatch({
			type: 'START_ADD_ACCOUNTING_DOCUMENT_LINE_ITEM',
			accountingDocumentId,
			lineItem,
		})

		try {
			const result: AccountingDocumentLineItem = await accountingDocumentLineItemsApi.post(
				{ accountingDocumentId },
				lineItem,
			)
			return dispatch({
				type: 'FINISH_ADD_ACCOUNTING_DOCUMENT_LINE_ITEM',
				accountingDocumentId,
				lineItem: result,
			})
		} catch (error) {
			return dispatch({
				type: 'FINISH_ADD_ACCOUNTING_DOCUMENT_LINE_ITEM',
				accountingDocumentId,
				lineItem: null,
				serverError: error,
			})
		}
	}
}

export function updateAccountingDocumentLineItem(
	accountingDocumentId: string,
	newLineItem: AccountingDocumentLineItem,
	autocalc: boolean,
) {
	return async (
		dispatch: Dispatch<AccountingDocumentReduxAction>,
		getState: () => State,
	): Promise<AccountingDocumentReduxAction> => {
		const state: State = getState()
		const oldLineItem: ?AccountingDocumentLineItem = getAccountingDocumentLineItem(
			state,
			accountingDocumentId,
			newLineItem.id || '',
		)
		dispatch({
			type: 'START_UPDATE_ACCOUNTING_DOCUMENT_LINE_ITEM',
			accountingDocumentId,
			oldLineItem,
			newLineItem,
			autocalc,
		})
		try {
			const params = {
				accountingDocumentId,
				lineItemId: newLineItem.id || '',
			}
			const confirmedLineItem: AccountingDocumentLineItem = await accountingDocumentLineItemApi.put(params, {
				...newLineItem,
			})
			dispatch(loadBranchesIfNotFound(confirmedLineItem.branch))
			dispatch(loadProjectsIfNotFound(confirmedLineItem.project))
			return dispatch({
				type: 'FINISH_UPDATE_ACCOUNTING_DOCUMENT_LINE_ITEM',
				accountingDocumentId,
				oldLineItem,
				confirmedLineItem,
				autocalc,
				success: true,
			})
		} catch (error) {
			return dispatch({
				type: 'FINISH_UPDATE_ACCOUNTING_DOCUMENT_LINE_ITEM',
				accountingDocumentId,
				oldLineItem,
				autocalc,
				confirmedLineItem: null,
				serverError: error,
				success: false,
			})
		}
	}
}

export function loadDefaultLineItems(accountingDocumentId: string) {
	return async (dispatch: Dispatch<AccountingDocumentReduxAction>): Promise<AccountingDocumentReduxAction> => {
		try {
			const result = await accountingDocumentLineItemsSearchApi.post({
				filters: {
					text: {
						text: '',
					},
					accountingDocumentId,
				},
			})
			return dispatch({
				type: 'FINISH_LOAD_DEFAULT_ACCOUNTING_DOCUMENT_LINE_ITEMS',
				accountingDocumentId: accountingDocumentId,
				lineItems: result.lineItems,
			})
		} catch (error) {
			return dispatch({
				type: 'FINISH_LOAD_DEFAULT_ACCOUNTING_DOCUMENT_LINE_ITEMS',
				accountingDocumentId: accountingDocumentId,
				serverError: error,
				lineItems: null,
			})
		}
	}
}

export function loadAccountingDocumentLineItems(accountingDocumentId: string) {
	return async (dispatch: Dispatch<AccountingDocumentReduxAction>): Promise<AccountingDocumentReduxAction> => {
		try {
			const lineItems = await accountingDocumentLineItemsApi.get({ accountingDocumentId })
			return dispatch({
				type: 'FINISH_LOAD_ACCOUNTING_DOCUMENT_LINE_ITEMS',
				accountingDocumentId,
				lineItems,
			})
		} catch (error) {
			return dispatch({
				type: 'FINISH_LOAD_ACCOUNTING_DOCUMENT_LINE_ITEMS',
				accountingDocumentId,
				serverError: error,
				lineItems: null,
			})
		}
	}
}

function loadBranchesIfNotFound(branch?: string): ActionFunction<OrganizationAction> {
	return async (dispatch: Dispatch<OrganizationAction>, getState: () => State) => {
		if (!branch) {
			return
		}
		const branches = getOrganizationBranches(getState()) || []
		const foundBranch: ?EnumItem = branches.find((b: EnumItem) => b.key === branch)
		if (foundBranch) {
			return
		}
		dispatch(loadBranches())
	}
}

function loadProjectsIfNotFound(project?: string): ActionFunction<OrganizationAction> {
	return async (dispatch: Dispatch<OrganizationAction>, getState: () => State) => {
		if (!project) {
			return
		}
		const projects = getOrganizationProjects(getState()) || []
		const foundProject: ?EnumItem = projects.find((p: EnumItem) => p.key === project)
		if (foundProject) {
			return
		}
		dispatch(loadProjects())
	}
}

export function removeAccountingDocumentLineItem(accountingDocumentId: string, lineItemId: string) {
	return async (
		dispatch: Dispatch<AccountingDocumentReduxAction>,
		getState: () => State,
	): Promise<AccountingDocumentReduxAction> => {
		const state: State = getState()
		const data: ?AccountingDocument = state.accountingDocument.accountingDocuments.items[accountingDocumentId].data
		const oldLineItems: Array<AccountingDocumentLineItem> = (data && data.lineItems) || []
		const newLineItems: Array<AccountingDocumentLineItem> = oldLineItems.filter(
			(item: AccountingDocumentLineItem) => item.id !== lineItemId,
		)

		dispatch({
			type: 'START_REMOVE_ACCOUNTING_DOCUMENT_LINE_ITEM',
			accountingDocumentId,
			oldLineItems,
			newLineItems,
		})
		try {
			await accountingDocumentLineItemApi.delete({
				accountingDocumentId,
				lineItemId,
			})

			return dispatch({
				type: 'FINISH_REMOVE_ACCOUNTING_DOCUMENT_LINE_ITEM',
				accountingDocumentId,
				oldLineItems,
				newLineItems,
			})
		} catch (error) {
			return dispatch({
				type: 'FINISH_REMOVE_ACCOUNTING_DOCUMENT_LINE_ITEM',
				accountingDocumentId,
				oldLineItems,
				newLineItems,
				serverError: error,
			})
		}
	}
}

export function replaceAccountingDocumentLineItems(
	srcDoc: AccountingDocument,
	destDoc: AccountingDocument,
	invertValue: boolean = false,
	deletePreviousItems: boolean = true,
	includeFinAccount: boolean = true,
) {
	return async (dispatch: Dispatch<AccountingDocumentReduxAction>) => {
		if (!destDoc.id) {
			return
		}

		const oldLineItems: ?Array<AccountingDocumentLineItem> = destDoc.lineItems
		const newLineItems: ?Array<AccountingDocumentLineItem> =
			srcDoc.lineItems &&
			srcDoc.lineItems.map((item: AccountingDocumentLineItem) => {
				const newItem = { ...item }
				if (invertValue) {
					const vat: boolean = item.vatRateId != null
					newItem.id = undefined
					newItem.total = item.total != null ? -item.total : undefined
					newItem.totalVatExcl = item.totalVatExcl != null ? -item.totalVatExcl : undefined
					newItem.unitPrice = item.unitPrice != null ? -item.unitPrice : undefined
					newItem.unitPriceVatExcl = item.unitPriceVatExcl != null ? -item.unitPriceVatExcl : undefined
					newItem.vatRate = vat ? item.vatRate : undefined
					newItem.vatRateType = vat ? item.vatRateType : undefined
					newItem.vatRateId = vat ? item.vatRateId : undefined

					// Classic vat rate 15, 10 no exist at 2024
					// Remove no exist vat rate id for Credit Note = 3 from Invoice = 0
					// But exist special vat 15, 10 for credit note in db
					if (
						srcDoc.taxDate !== null &&
						srcDoc.taxDate !== undefined &&
						new Date(srcDoc.taxDate) <= new Date(2023, 11, 31, 23, 59, 59) &&
						destDoc.type === 3 &&
						srcDoc.type === 0 &&
						(item.vatRate === 15 || item.vatRate === 10)
					) {
						newItem.vatRateId = undefined
					}
				}
				if (!includeFinAccount) {
					newItem.finAccount = undefined
				}
				return newItem
			})

		dispatch({
			type: 'START_REPLACE_LINE_ITEMS',
			accountingDocumentId: destDoc.id || '',
		})

		try {
			//remove all line items from destDoc
			let lineItems: Array<AccountingDocumentLineItem> = oldLineItems || []
			if (deletePreviousItems) {
				await Promise.all(
					lineItems.map((item: AccountingDocumentLineItem) => {
						return dispatch(removeAccountingDocumentLineItem(destDoc.id || '', item.id || ''))
					}),
				)
			}

			//add all line items from srcDoc to destDoc
			lineItems = newLineItems || []
			await Promise.all(
				lineItems.map((item: AccountingDocumentLineItem) => {
					return dispatch(addAccountingDocumentLineItem(destDoc.id || '', item))
				}),
			)

			dispatch({
				type: 'FINISH_REPLACE_LINE_ITEMS',
				accountingDocumentId: destDoc.id || '',
			})
		} catch (serverError) {
			dispatch({
				type: 'FINISH_REPLACE_LINE_ITEMS',
				accountingDocumentId: destDoc.id || '',
				serverError,
			})
		}
	}
}

export function updateAccountingDocumentContact(
	accountingDocumentId: string,
	newContact: ?AccountingDocumentContact,
	updateBankAccounts: boolean = true,
) {
	return async (dispatch: Dispatch<AccountingDocumentReduxAction | UserAction>, getState: () => State) => {
		const state = getState()
		const oldContact = getAccountingDocumentContact(state, accountingDocumentId)
		await dispatch({
			type: 'START_UPDATE_ACCOUNTING_DOCUMENT_CONTACT',
			accountingDocumentId,
			oldContact,
			newContact,
		})

		await dispatch({ type: 'CLEAR_ARES_CONTACTS' })

		try {
			let confirmedContact: ?AccountingDocumentContact
			if (newContact) {
				confirmedContact = await accountingDocumentContactApi.put({ accountingDocumentId }, newContact)
				await dispatch(postUpdateAccountingDocumentContact(accountingDocumentId, confirmedContact, updateBankAccounts))
			} else {
				await accountingDocumentContactApi.delete({ accountingDocumentId })
			}

			return await dispatch({
				type: 'FINISH_UPDATE_ACCOUNTING_DOCUMENT_CONTACT',
				accountingDocumentId,
				oldContact,
				confirmedContact,
				success: true,
			})
		} catch (error) {
			let errorMessage: ?ValidationError

			if (
				error.code === 'api0003' &&
				error.fieldsAffected &&
				error.fieldsAffected[0] &&
				error.fieldsAffected[0].name === 'Country'
			) {
				errorMessage = { code: 'custom0001', message: '' }
			}

			return await dispatch({
				type: 'FINISH_UPDATE_ACCOUNTING_DOCUMENT_CONTACT',
				accountingDocumentId,
				oldContact,
				confirmedContact: null,
				serverError: errorMessage || error,
				success: false,
			})
		}
	}
}

function postUpdateAccountingDocumentContact(
	accountingDocumentId: string,
	accountingDocumentContact: AccountingDocumentContact,
	updateBankAccounts: boolean,
) {
	return async (dispatch: Dispatch<any>, getState: () => State) => {
		const state = getState()

		if (!isAccountingDocumentOssEnabled(state, accountingDocumentId))
			await dispatch(resetAccountingDocumentOssMode(accountingDocumentId))

		await dispatch(
			updateAccountingDocumentLanguageByAccountingDocumentContact(accountingDocumentId, accountingDocumentContact),
		)
		if (updateBankAccounts) {
			await dispatch(
				updateAccountingDocumentBankAccountByAccountingDocumentContact(accountingDocumentId, accountingDocumentContact),
			)
		}

		await Promise.all([
			dispatch(
				updateAccountingDocumentDueDateByAccountingDocumentContact(accountingDocumentId, accountingDocumentContact),
			),
			dispatch(
				updateAccountingDocumentVatCountryTypeByAccountingDocumentContact(
					accountingDocumentId,
					accountingDocumentContact,
				),
			),
		])
	}
}

function updateAccountingDocumentLanguageByAccountingDocumentContact(
	accountingDocumentId: string,
	accountingDocumentContact: AccountingDocumentContact,
) {
	return async (dispatch: Dispatch<any>, getState: () => State) => {
		const contactId = accountingDocumentContact && accountingDocumentContact.contactId
		const contact = contactId ? await getOrLoadContact(getState(), dispatch, contactId) : null
		await dispatch(updateAccountingDocumentLanguage(accountingDocumentId, contact && contact.language))
	}
}

function updateAccountingDocumentBankAccountByAccountingDocumentContact(
	accountingDocumentId: string,
	accountingDocumentContact: AccountingDocumentContact,
) {
	return async (dispatch: Dispatch<any>, getState: () => State) => {
		const state = getState()
		const accountingDocument = getAccountingDocument(state, accountingDocumentId)
		if (!accountingDocument || !accountingDocumentContact.contactId) {
			return
		}

		const contact = await getOrLoadContact(state, dispatch, accountingDocumentContact.contactId)
		const account: ?ContactBankAccount = contact && contact.bankAccounts && contact.bankAccounts[0]
		if (
			'received' === Number_AccountingDocumentAssignedDirection(accountingDocument.direction) &&
			'credit_note' !== Number_AccountingDocumentAssignedType(accountingDocument.type)
		) {
			if (accountingDocument.bankAccounts) {
				await dispatch(removeAllAccDocBankAccounts(accountingDocumentId))
			}
			if (account) {
				await dispatch(
					addAccountingDocumentBankAccount(
						accountingDocumentId,
						ContactBankAccount_AccountingDocumentBankAccount(account),
					),
				)
			}
		}
	}
}

function updateAccountingDocumentDueDateByAccountingDocumentContact(
	accountingDocumentId: string,
	accountingDocumentContact: AccountingDocumentContact,
) {
	return async (dispatch: Dispatch<any>, getState: () => State) => {
		const state = getState()
		const accountingDocument = getAccountingDocument(state, accountingDocumentId)
		if (!accountingDocument || !accountingDocumentContact.contactId) {
			return
		}

		const contact = await getOrLoadContact(state, dispatch, accountingDocumentContact.contactId)
		const issueDate = accountingDocument && accountingDocument.issueDate && convertToDate(accountingDocument.issueDate)
		let duePeriod = 0
		if (!issueDate || !contact) {
			return
		}

		// set contact or default due date
		if (contact && contact.defaultDuePeriod != null) {
			duePeriod = contact.defaultDuePeriod
		} else {
			const documentDefaults: AccountingDocumentDefaults = await getAccDocDefaults(state, dispatch, accountingDocument)
			duePeriod = documentDefaults.dueDateInterval ?? DEFAULT_DUE_DATE_INTERVAL
		}

		// create and save new due date
		let newDueDate = new Date(issueDate)
		newDueDate.setDate(newDueDate.getDate() + duePeriod)
		dispatch(updateAccountingDocumentDueDate(accountingDocumentId, newDueDate))
	}
}

function updateAccountingDocumentVatCountryTypeByAccountingDocumentContact(
	accountingDocumentId: string,
	accountingDocumentContact: AccountingDocumentContact,
) {
	return async (dispatch: Dispatch<any>, getState: () => State) => {
		const state = getState()
		const newCountry = accountingDocumentContact && accountingDocumentContact.country
		const accountingDocument = getAccountingDocument(state, accountingDocumentId)
		const newVatCountryType = getVatCountryTypeByCountry(state, newCountry)
		await dispatch(loadCountries())
		if (newVatCountryType && accountingDocument && newVatCountryType !== accountingDocument.vatCountryType) {
			await dispatch(updateAccountingDocumentVatCountryType(accountingDocumentId, newVatCountryType))
		}
	}
}

export function addAccountingDocumentBankAccount(
	accountingDocumentId: string,
	newBankAccount: AccountingDocumentBankAccount,
) {
	return async (
		dispatch: Dispatch<AccountingDocumentReduxAction>,
		getState: () => State,
	): Promise<AccountingDocumentReduxAction> => {
		const localId = newBankAccount.localId
		const oldBankAccount = localId ? getAccountingDocumentBankAccount(getState(), accountingDocumentId, localId) : null

		dispatch({
			type: 'START_ADD_ACCOUNTING_DOCUMENT_BANK_ACCOUNT',
			accountingDocumentId,
			newBankAccount,
		})
		try {
			let result: AccountingDocumentBankAccount

			if (localId) {
				const params = { accountingDocumentId, localBankAccountId: localId }
				result = await accountingDocumentBankAccountApi.put(params, newBankAccount)
			} else {
				const params = { accountingDocumentId }
				if (
					(!newBankAccount.number || !newBankAccount.number.length) &&
					(!newBankAccount.bankCode || !newBankAccount.bankCode.length)
				) {
					result = newBankAccount
				} else {
					result = await accountingDocumentBankAccountsApi.post(params, newBankAccount)
				}
			}

			return dispatch({
				type: 'FINISH_ADD_ACCOUNTING_DOCUMENT_BANK_ACCOUNT',
				accountingDocumentId,
				oldBankAccount,
				newBankAccount,
				confirmedBankAccount: (Array.isArray(result) && result[0]) || result,
				success: true,
			})
		} catch (error) {
			return dispatch({
				type: 'FINISH_ADD_ACCOUNTING_DOCUMENT_BANK_ACCOUNT',
				accountingDocumentId,
				oldBankAccount,
				newBankAccount,
				confirmedBankAccount: null,
				serverError: error,
				success: false,
			})
		}
	}
}

export function removeAccountingDocumentBankAccount(
	accountingDocumentId: string,
	bankAccount: AccountingDocumentBankAccount,
) {
	return async (dispatch: Dispatch<AccountingDocumentReduxAction>) => {
		dispatch({
			type: 'START_REMOVE_ACCOUNTING_DOCUMENT_BANK_ACCOUNT',
			accountingDocumentId,
			bankAccount,
		})
		try {
			const params = {
				accountingDocumentId,
				localBankAccountId: bankAccount.localId || '',
			}

			if (bankAccount.localId) {
				await accountingDocumentBankAccountApi.delete(params)
			}
			dispatch({
				type: 'FINISH_REMOVE_ACCOUNTING_DOCUMENT_BANK_ACCOUNT',
				accountingDocumentId,
				bankAccount,
				success: true,
			})
		} catch (error) {
			dispatch({
				type: 'FINISH_REMOVE_ACCOUNTING_DOCUMENT_BANK_ACCOUNT',
				accountingDocumentId,
				bankAccount,
				serverError: error,
				success: false,
			})
		}
	}
}

export function removeAllAccDocBankAccounts(accDocId: string) {
	return async (dispatch: Dispatch<AccountingDocumentReduxAction>, getState: () => State) => {
		const accDoc: AccountingDocument = await getOrLoadAccountingDocument(getState(), accDocId)
		let bankAccounts: Array<AccountingDocumentBankAccount> = accDoc.bankAccounts || []
		await Promise.all(
			bankAccounts.map((account: AccountingDocumentBankAccount) => {
				return dispatch(removeAccountingDocumentBankAccount(accDoc.id || '', account))
			}),
		)
	}
}

export function replaceAccountingDocumentBankAccounts(srcDoc: AccountingDocument, destDoc: AccountingDocument) {
	return async (dispatch: Dispatch<AccountingDocumentReduxAction>) => {
		if (!destDoc.id) {
			return
		}

		dispatch({
			type: 'START_REPLACE_BANK_ACCOUNTS',
			accountingDocumentId: destDoc.id || '',
		})

		try {
			//remove all accounts from destDoc
			let bankAccounts: Array<AccountingDocumentBankAccount> = destDoc.bankAccounts || []
			await Promise.all(
				bankAccounts.map((account: AccountingDocumentBankAccount) => {
					return dispatch(removeAccountingDocumentBankAccount(destDoc.id || '', account))
				}),
			)

			//add all accounts from srcDoc to destDoc
			bankAccounts = srcDoc.bankAccounts || []
			await Promise.all(
				bankAccounts.map((account: AccountingDocumentBankAccount) => {
					const paramAccount: AccountingDocumentBankAccount = {
						number: account.number,
						displayNumber: account.displayNumber,
						bankCode: account.bankCode,
						iban: account.iban,
						swift: account.swift,
						bankAccountId: account.bankAccountId,
					}
					if (destDoc.direction === AccountingDocumentDirection_Number('received')) {
						delete paramAccount.bankAccountId
					}
					return dispatch(addAccountingDocumentBankAccount(destDoc.id || '', paramAccount))
				}),
			)

			dispatch({
				type: 'FINISH_REPLACE_BANK_ACCOUNTS',
				accountingDocumentId: destDoc.id || '',
			})
		} catch (serverError) {
			dispatch({
				type: 'FINISH_REPLACE_BANK_ACCOUNTS',
				accountingDocumentId: destDoc.id || '',
				serverError,
			})
		}
	}
}

export function loadAccountingDocumentHeader(accountingDocumentId: string) {
	return async (dispatch: Dispatch<AccountingDocumentReduxAction>) => {
		dispatch({
			type: 'START_LOAD_ACCOUNTING_DOCUMENT_HEADER',
			accountingDocumentId,
		})
		try {
			const confirmedHeader: AccountingDocumentHeader = await accountingDocumentHeaderApi.get({
				accountingDocumentId,
			})
			dispatch({
				type: 'FINISH_LOAD_ACCOUNTING_DOCUMENT_HEADER',
				accountingDocumentId,
				accountingDocumentHeader: confirmedHeader,
				success: true,
			})
		} catch (error) {
			dispatch({
				type: 'FINISH_LOAD_ACCOUNTING_DOCUMENT_HEADER',
				accountingDocumentId,
				accountingDocumentHeader: null,
				serverError: error,
				success: false,
			})
		}
	}
}

export function loadAccountingDocumentAttachments(accountingDocumentId: string) {
	return async (dispatch: Dispatch<AccountingDocumentReduxAction>, getState: () => State) => {
		const state: State = getState()
		const item = state.accountingDocument.accountingDocuments.items[accountingDocumentId]
		const oldAttachments: ?Array<AccountingDocumentAttachment> = item && item.attachments
		dispatch({
			type: 'START_LOAD_ACCOUNTING_DOCUMENT_ATTACHMENTS',
			accountingDocumentId,
		})
		try {
			let attachments: Array<AccountingDocumentAttachment> = []
			attachments = await accountingDocumentAttachmentsApi.get({ accountingDocumentId })
			dispatch({
				type: 'FINISH_LOAD_ACCOUNTING_DOCUMENT_ATTACHMENTS',
				accountingDocumentId,
				attachments,
			})
		} catch (error) {
			dispatch({
				type: 'FINISH_LOAD_ACCOUNTING_DOCUMENT_ATTACHMENTS',
				accountingDocumentId,
				attachments: oldAttachments,
				serverError: error,
			})
		}
	}
}

export function loadAccountingDocumentScans(accountingDocumentId: string) {
	return async (dispatch: Dispatch<AccountingDocumentReduxAction>) => {
		dispatch({
			type: 'START_LOAD_ACCOUNTING_DOCUMENT_SCANS',
			accountingDocumentId,
		})
		try {
			const scans: Array<AccountingDocumentScan> = await accountingDocumentScansApi.get({
				accountingDocumentId,
			})
			dispatch({
				type: 'FINISH_LOAD_ACCOUNTING_DOCUMENT_SCANS',
				accountingDocumentId,
				scans,
			})
		} catch (error) {
			dispatch({
				type: 'FINISH_LOAD_ACCOUNTING_DOCUMENT_SCANS',
				accountingDocumentId,
				scans: null,
				serverError: error,
			})
		}
	}
}

export function attachAccountingDocumentScan(accountingDocumentId: string, file: File) {
	return async (dispatch: Dispatch<AccountingDocumentReduxAction>) => {
		dispatch({
			type: 'START_ATTACH_ACCOUNTING_DOCUMENT_SCAN',
		})
		try {
			await accountingDocumentScansApi.post(
				{ accountingDocumentId },
				{
					file,
					name: file.name || new Date().toString(),
				},
				{
					timeout: TIMEOUT_FOR_UPLOADING_FILES,
				},
			)

			dispatch({
				type: 'FINISH_ATTACH_ACCOUNTING_DOCUMENT_SCAN',
			})

			dispatch(loadAccountingDocumentScans(accountingDocumentId))
		} catch (error) {
			dispatch({
				type: 'FINISH_ATTACH_ACCOUNTING_DOCUMENT_SCAN',
				serverError: error,
			})
		}
	}
}

export function deleteAccountingDocumentScan(accountingDocumentId: string, guid: string) {
	return async (dispatch: Dispatch<AccountingDocumentReduxAction>, getState: () => State) => {
		const doc: ?AccountingDocument = getAccountingDocument(getState(), accountingDocumentId)
		const oldScans: ?Array<AccountingDocumentScan> = doc && doc.scans
		const newScans: ?Array<AccountingDocumentScan> = oldScans
			? oldScans.filter((s: AccountingDocumentScan) => s.guid !== guid)
			: undefined

		dispatch({
			type: 'START_DELETE_ACCOUNTING_DOCUMENT_SCAN',
			scans: newScans,
			accountingDocumentId,
		})
		try {
			await accountingDocumentScanApi.delete({ accountingDocumentId, guid })

			dispatch({
				type: 'FINISH_DELETE_ACCOUNTING_DOCUMENT_SCAN',
				scans: newScans,
				accountingDocumentId,
			})
		} catch (serverError) {
			dispatch({
				type: 'FINISH_DELETE_ACCOUNTING_DOCUMENT_SCAN',
				serverError,
				scans: oldScans,
				accountingDocumentId,
			})
		}
	}
}

export function moveAccountingDocumentToBankStatements(accountingDocumentId: string, destinationBankAccountId: string) {
	return async (dispatch: Dispatch<AccountingDocumentReduxAction>) => {
		dispatch({
			type: 'START_MOVE_ACCOUNTING_DOCUMENT_SCAN_TO_BANK_STATEMENTS',
		})
		try {
			await AccountingDocumentScanMoveToBanksApi.post(
				{ accountingDocumentId },
				{
					destinationBankAccountId,
				},
			)

			dispatch({
				type: 'FINISH_MOVE_ACCOUNTING_DOCUMENT_SCAN_TO_BANK_STATEMENTS',
			})
		} catch (error) {
			dispatch({
				type: 'FINISH_MOVE_ACCOUNTING_DOCUMENT_SCAN_TO_BANK_STATEMENTS',
				serverError: error,
			})
		}
	}
}

export function moveAccountingDocumentToDocuments(
	accountingDocumentId: string,
	destinationDocumentItemUniqueId: string,
) {
	return async (dispatch: Dispatch<AccountingDocumentReduxAction>) => {
		dispatch({
			type: 'START_MOVE_ACCOUNTING_DOCUMENT_SCAN_TO_DOCUMENTS',
		})
		try {
			await AccountingDocumentScanMoveToDocumentsApi.post(
				{ accountingDocumentId },
				{
					destinationDocumentItemUniqueId,
				},
			)

			dispatch({
				type: 'FINISH_MOVE_ACCOUNTING_DOCUMENT_SCAN_TO_DOCUMENTS',
			})
		} catch (error) {
			dispatch({
				type: 'FINISH_MOVE_ACCOUNTING_DOCUMENT_SCAN_TO_DOCUMENTS',
				serverError: error,
			})
		}
	}
}

export function setAccountingDocumentExtractingState(
	accountingDocumentId: string,
	newExtractingState: AccountingDocumentExtractingState,
) {
	return async (dispatch: Dispatch<AccountingDocumentReduxAction>, getState: GetState) => {
		const document: ?AccountingDocument = getAccountingDocument(getState(), accountingDocumentId)
		const oldExtractingState = document ? document.__extractingState : 1

		if (document && document.__extractingState === newExtractingState) return null

		dispatch({
			type: 'START_SET_ACCOUNTING_DOCUMENT_EXTRACTING_STATE',
			accountingDocumentId,
			newExtractingState,
		})
		try {
			const result: ExtractingState = await accountingDocumentExtractingStateApi.put(
				{ accountingDocumentId },
				{ extractingState: newExtractingState },
			)

			dispatch({
				type: 'FINISH_SET_ACCOUNTING_DOCUMENT_EXTRACTING_STATE',
				accountingDocumentId,
				newExtractingState: result.extractingState,
			})
		} catch (error) {
			dispatch({
				type: 'FINISH_SET_ACCOUNTING_DOCUMENT_EXTRACTING_STATE',
				serverError: error,
				accountingDocumentId,
				oldExtractingState,
			})
		}
	}
}

export function setAccDocDefaultBranchAndProject(
	accDocId: string,
	newBranchAndProject: AccountingDocumentPatchPreferredBranchAndProject,
) {
	return async (dispatch: Dispatch<AccountingDocumentReduxAction>, getState: GetState) => {
		const document: ?AccountingDocument = getAccountingDocument(getState(), accDocId)

		if (
			document &&
			document.preferredProject === newBranchAndProject.preferredProject &&
			document.preferredBranch === newBranchAndProject.preferredBranch
		) {
			return null
		}

		const updatedDocument = {
			...document,
			preferredBranch: newBranchAndProject.preferredBranch,
			preferredProject: newBranchAndProject.preferredProject,
		}

		const patch = diff(document, updatedDocument)

		// dispatch({
		// 	type: 'START_SET_ACCOUNTING_DOCUMENT_PREFERRED_BRANCH_AND_PROJECT',
		// 	accountingDocumentId,
		// 	newBranchAndProject,
		// 	document,
		// })

		try {
			const result: AccountingDocumentPatchPreferredBranchAndProject = await accountingDocumentProjectAndBranchDefaultsApi.patch(
				{ accountingDocumentId: accDocId },
				patch,
			)

			dispatch({
				type: 'FINISH_SET_ACCOUNTING_DOCUMENT_PREFERRED_BRANCH_AND_PROJECT',
				accountingDocumentId: accDocId,
				newPreferredBranch: result.preferredBranch,
				newPreferredProject: result.preferredProject,
				document,
			})
		} catch (error) {
			dispatch({
				type: 'FINISH_SET_ACCOUNTING_DOCUMENT_PREFERRED_BRANCH_AND_PROJECT',
				serverError: error,
				accountingDocumentId: accDocId,
				document,
			})
		}
	}
}

export function setAccountingDocumentExtractingNote(
	accountingDocumentId: string,
	newExtractingNote: AccountingDocumentNote,
) {
	return async (dispatch: Dispatch<AccountingDocumentReduxAction>, getState: GetState) => {
		const document: ?AccountingDocument = getAccountingDocument(getState(), accountingDocumentId)
		const oldExtractingNote =
			(document && document._extracting && document._extracting.notes && document._extracting.notes[0]) || null

		dispatch({
			type: 'START_SET_ACCOUNTING_DOCUMENT_EXTRACTING_NOTE',
			accountingDocumentId,
			newExtractingNote,
		})
		try {
			await accountingDocumentExtractingNoteApi.put({ accountingDocumentId }, { notes: [newExtractingNote] })

			dispatch({
				type: 'FINISH_SET_ACCOUNTING_DOCUMENT_EXTRACTING_NOTE',
				accountingDocumentId,
				newExtractingNote,
			})
		} catch (error) {
			dispatch({
				type: 'FINISH_SET_ACCOUNTING_DOCUMENT_EXTRACTING_NOTE',
				serverError: error,
				accountingDocumentId,
				oldExtractingNote,
			})
		}
	}
}

export function attachAccountingDocumentAttachment(
	accountingDocumentId: string,
	attachment: AccountingDocumentAttachment,
) {
	return async (dispatch: Dispatch<AccountingDocumentReduxAction>) => {
		dispatch({
			type: 'START_ATTACH_ACCOUNTING_DOCUMENT_ATTACHMENT',
			accountingDocumentId,
			attachment,
		})
		try {
			const result: AccountingDocumentAttachment = await accountingDocumentAttachmentsApi.post(
				{ accountingDocumentId },
				attachment,
			)
			dispatch({
				type: 'FINISH_ATTACH_ACCOUNTING_DOCUMENT_ATTACHMENT',
				accountingDocumentId,
				attachment: result,
			})
		} catch (error) {
			dispatch({
				type: 'FINISH_ATTACH_ACCOUNTING_DOCUMENT_ATTACHMENT',
				accountingDocumentId,
				attachment: null,
				serverError: error,
			})
		}
	}
}

export function removeAccountingDocumentAttachment(
	accountingDocumentId: string,
	attachment: AccountingDocumentAttachment,
) {
	return async (dispatch: Dispatch<AccountingDocumentReduxAction>) => {
		dispatch({
			type: 'START_REMOVE_ACCOUNTING_DOCUMENT_ATTACHMENT',
			accountingDocumentId,
			attachment,
		})
		try {
			const params = {
				accountingDocumentId: accountingDocumentId || '',
				documentId: attachment.documentUniqueId || '',
			}
			await accountingDocumentAttachmentDocumentApi.delete(params)
			dispatch({
				type: 'FINISH_REMOVE_ACCOUNTING_DOCUMENT_ATTACHMENT',
				accountingDocumentId,
				attachment,
				success: true,
			})
		} catch (error) {
			dispatch({
				type: 'FINISH_REMOVE_ACCOUNTING_DOCUMENT_ATTACHMENT',
				accountingDocumentId,
				attachment,
				success: false,
				serverError: error,
			})
		}
	}
}

export function confirmCreditNote(accountingDocumentId: string, vatDate: string, fileId: string) {
	return async (dispatch: Dispatch<AccountingDocumentReduxAction>) => {
		dispatch({
			type: 'START_CONFIRM_NOTE',
		})
		try {
			await creditNoteApi.post({ accountingDocumentId }, { vatDate, fileId })
			dispatch({
				type: 'FINISH_CONFIRM_NOTE',
			})
		} catch (error) {
			dispatch({
				type: 'FINISH_CONFIRM_NOTE',
				serverError: error,
			})
		}
	}
}

const headerThrottle = createThrottle(3000)

export function updateAccountingDocumentHeader(accountingDocumentId: string, newHeader: AccountingDocumentHeader) {
	return async (
		dispatch: Dispatch<AccountingDocumentReduxAction>,
		getState: () => State,
	): Promise<AccountingDocumentReduxAction> => {
		//start TODO commented hotfixa1b2c3
		// const issueDate: Date = new Date(newHeader.issueDate || 0)
		// const bookingDate: Date = new Date(newHeader.bookingDate || 0)
		// const dueDate: Date = new Date(newHeader.dueDate || 0)
		// const taxReturnDate: Date = new Date(newHeader.taxReturnDate || 0)
		// const taxDate: Date = new Date(newHeader.taxDate || 0)
		//
		// if (bookingDate < issueDate) {newHeader.bookingDate = newHeader.issueDate}
		// if (dueDate < issueDate) {newHeader.dueDate = newHeader.issueDate}
		// if (dueDate < issueDate) {newHeader.dueDate = newHeader.issueDate}
		// if (taxReturnDate < issueDate) {newHeader.taxReturnDate = newHeader.issueDate}
		// if (taxDate < issueDate) {newHeader.taxDate = newHeader.issueDate}
		//end TODO commented hotfixa1b2c3

		const state: State = getState()
		const item = state.accountingDocument.accountingDocuments.items[accountingDocumentId]
		const oldHeader: ?AccountingDocumentHeader = item && item.header

		dispatch({
			type: 'START_UPDATE_ACCOUNTING_DOCUMENT_HEADER',
			accountingDocumentId,
			oldHeader,
			newHeader,
		})
		return dispatch(
			headerThrottle(async () => {
				try {
					const confirmedHeader: AccountingDocumentHeader = await accountingDocumentHeaderApi.put(
						{ accountingDocumentId },
						newHeader,
					)
					return dispatch({
						type: 'FINISH_UPDATE_ACCOUNTING_DOCUMENT_HEADER',
						accountingDocumentId,
						oldHeader,
						confirmedHeader,
						success: true,
					})
				} catch (error) {
					return dispatch({
						type: 'FINISH_UPDATE_ACCOUNTING_DOCUMENT_HEADER',
						accountingDocumentId,
						oldHeader,
						confirmedHeader: null,
						serverError: error,
						success: false,
					})
				}
			}),
		)
	}
}

function updateAccountingDocumentHeaderField(
	accountingDocumentId: string,
	fieldName: string,
	fieldValue: ?string | ?number | Array<any> | boolean | {},
) {
	return async (dispatch: Dispatch<AccountingDocumentReduxAction>): Promise<AccountingDocumentReduxAction> => {
		const fields = { [fieldName]: fieldValue }
		return dispatch(updateAccountingDocumentHeaderMoreFields(accountingDocumentId, fields))
	}
}

export function updateAccountingDocumentHeaderMoreFields(accountingDocumentId: string, fields: Object) {
	return async (
		dispatch: Dispatch<AccountingDocumentReduxAction>,
		getState: () => State,
	): Promise<AccountingDocumentReduxAction> => {
		const state: State = getState()
		const item = state.accountingDocument.accountingDocuments.items[accountingDocumentId]
		const oldHeader: ?AccountingDocumentHeader = item && item.header
		const newHeader: AccountingDocumentHeader = {
			...emptyAccountingDocumentHeader(),
			...(oldHeader || {}),
			...fields,
		}

		const doc: ?AccountingDocument = getAccountingDocument(state, accountingDocumentId)
		if (doc && doc.cashRegisterId && Number_AccountingDocumentType(doc.type) !== 'simplified_invoice') {
			newHeader.vatRecapCalculationMode = 2 // TODO: doufam že tahle prasárna se někdy odstraní
		}

		return dispatch(updateAccountingDocumentHeader(accountingDocumentId, newHeader))
	}
}

export function updateAccountingDocumentPrinting(
	accountingDocumentId: string,
	printing: ?AccountingDocumentPrintingInfo,
) {
	return async (dispatch: Dispatch<AccountingDocumentReduxAction>): Promise<AccountingDocumentReduxAction> => {
		return dispatch(updateAccountingDocumentHeaderMoreFields(accountingDocumentId, { printing }))
	}
}

export function updateAccountingDocumentPrintingHeader(accountingDocumentId: string, note: ?string) {
	return async (
		dispatch: Dispatch<AccountingDocumentReduxAction>,
		getState: () => State,
	): Promise<AccountingDocumentReduxAction> => {
		const state = getState()
		const accountingDocument: ?AccountingDocument = getAccountingDocument(state, accountingDocumentId)
		const printing = (accountingDocument && accountingDocument.printing) || {}
		return dispatch(
			updateAccountingDocumentHeaderMoreFields(accountingDocumentId, {
				printing: { ...printing, lineItemsHeader: note || '' },
			}),
		)
	}
}

export function updateAccountingDocumentPrintingFooter(accountingDocumentId: string, note: ?string) {
	return async (
		dispatch: Dispatch<AccountingDocumentReduxAction>,
		getState: () => State,
	): Promise<AccountingDocumentReduxAction> => {
		const state = getState()
		const accountingDocument: ?AccountingDocument = getAccountingDocument(state, accountingDocumentId)
		const printing = (accountingDocument && accountingDocument.printing) || {}
		return dispatch(
			updateAccountingDocumentHeaderMoreFields(accountingDocumentId, {
				printing: { ...printing, lineItemsFooter: note || '' },
			}),
		)
	}
}

export function updateAccountingDocumentDueDate(accountingDocumentId: string, dueDate: ?Date) {
	return async (dispatch: Dispatch<AccountingDocumentReduxAction>): Promise<AccountingDocumentReduxAction> => {
		const dueDateString: ?string = (dueDate && toDateOnlyIsoString(dueDate)) || null
		return dispatch(updateAccountingDocumentHeaderField(accountingDocumentId, 'dueDate', dueDateString))
	}
}

export function updateAccountingDocumentIssueDate(accountingDocumentId: string, issueDate: ?Date) {
	return async (dispatch: Dispatch<AccountingDocumentReduxAction>): Promise<AccountingDocumentReduxAction> => {
		const issueDateString: ?string = (issueDate && formatDateToIsoString(issueDate)) || null
		return dispatch(updateAccountingDocumentHeaderField(accountingDocumentId, 'issueDate', issueDateString))
	}
}

export async function getOrLoadAccountingDocument(
	state: State,
	accountingDocumentId: string,
): Promise<AccountingDocument> {
	let stateDoc: ?AccountingDocument = getAccountingDocument(state, accountingDocumentId) //try to get document from state first
	if (stateDoc) {
		return new Promise((resolve: Function) => {
			resolve(stateDoc || emptyAccountingDocument()) //emptyAccountingDocument is just because of flow, will not happen
		})
	} else {
		return await accountingDocumentApi.get({ accountingDocumentId })
	}
}

async function getOrLoadAccountingDocumentDefaults(
	state: State,
	dispatch: Dispatch<any>,
	direction: AccountingDocumentDirection,
	type: AccountingDocumentType,
): Promise<?AccountingDocumentDefaults> {
	let defaults: ?AccountingDocumentDefaults = getAccountingDocumentDefaults(state, direction, type)
	let genericDefaults: ?AccountingDocumentDefaults = getAccountingDocumentDefaults(state, direction)

	if (!defaults || !genericDefaults) {
		const results = await Promise.all([
			dispatch(loadAccountingDocumentDefaults(type, direction)),
			dispatch(loadAccountingDocumentDefaults(null, direction)),
		])
		defaults = results[0].defaults || {}
		genericDefaults = results[1].defaults || {}
	}

	return new Promise((resolve: Function) => {
		resolve(
			mergeWith(genericDefaults, defaults, (objValue: any, srcValue: any, key: string) => {
				if (objValue && srcValue === null) {
					return objValue
				}
				if (key === 'dueDateInterval') {
					return objValue ?? srcValue
				}
			}),
		)
	})
}

export async function getOrLoadContact(
	state: State,
	dispatch: Dispatch<AccountingDocumentReduxAction | AddressBookAction>,
	contactId: string,
): Promise<?Contact> {
	let contact: ?Contact = (contactId && getContact(state, contactId)) || null //try to get contact from state first
	if (contact) {
		return new Promise((resolve: Function) => {
			resolve(contact)
		})
	} else {
		//if it doenst exist in state, load it
		const action = contactId && (await dispatch(loadContact(contactId)))
		if (action) {
			contact = action.contact
		}
		return contact
	}
}

export function updateAccountingDocumentDatesByDuePeriod(accountingDocumentId: string, duePeriod: number) {
	return async (dispatch: Dispatch<AccountingDocumentReduxAction | AddressBookAction>, getState: () => State) => {
		const state: State = getState()
		const doc: AccountingDocument = await getOrLoadAccountingDocument(state, accountingDocumentId)
		const contactId: ?string = doc.contact && doc.contact.contactId
		const contact: ?Contact = (contactId && (await getOrLoadContact(state, dispatch, contactId))) || null

		let fields = {}
		if (contact && doc.issueDate) {
			let tmpDueDate: ?Date = (doc.issueDate && new Date(doc.issueDate)) || null
			if (tmpDueDate) {
				tmpDueDate.setDate(tmpDueDate.getDate() + duePeriod)
				fields.dueDate = formatDateToIsoString(tmpDueDate)
			}
		}

		return dispatch(updateAccountingDocumentHeaderMoreFields(accountingDocumentId, fields))
	}
}

export function updateAccountingDocumentDatesByIssueDate(accountingDocumentId: string, issueDate: ?Date) {
	return async (dispatch: Dispatch<AccountingDocumentReduxAction | AddressBookAction>, getState: () => State) => {
		const doc: AccountingDocument = await getOrLoadAccountingDocument(getState(), accountingDocumentId)
		const issueDateString: ?string = (issueDate && toDateOnlyIsoString(issueDate)) || null

		type FieldsType = {
			issueDate: ?string,
			taxDate?: ?string,
			bookingDate?: ?string,
			taxReturnDate?: ?string,
			dueDate?: ?string,
		}

		let fields: FieldsType = { issueDate: issueDateString }

		if (!doc.taxDate || doc.issueDate === doc.taxDate || (issueDate && issueDate <= new Date(doc.taxDate))) {
			fields.taxDate = issueDateString
		}

		if (!doc.bookingDate || doc.issueDate === doc.bookingDate) {
			fields.bookingDate = issueDateString
		}

		if (Number_AccountingDocumentDirection(doc.direction) === 'received') {
			if (!doc.taxReturnDate || doc.issueDate === doc.taxReturnDate) {
				fields.taxReturnDate = issueDateString
			}
			const taxDateStr: ?string = fields.taxDate || doc.taxDate
			const taxReturnDateStr: ?string = fields.taxReturnDate || doc.taxReturnDate
			if (taxDateStr && taxReturnDateStr && new Date(taxDateStr) > new Date(taxReturnDateStr)) {
				fields.taxReturnDate = fields.taxDate || doc.taxDate
			}
		}

		// UPDATE DUE DATE ACCORDING TO CONTACT DUE DATE

		if (issueDate) {
			const accDocDirection = doc.direction
				? ACCOUNTING_DOCUMENT_DIRECTION_TYPES[doc.direction.toString()]
				: ACCOUNTING_DOCUMENT_DIRECTION_TYPES['0']

			let DUE_PERIOD = 0
			const state: State = getState()
			const contactId: ?string = doc.contact && doc.contact.contactId
			let contact: ?Contact = (contactId && (await getOrLoadContact(state, dispatch, contactId))) || null
			if (contact && contact.defaultDuePeriod != null) {
				DUE_PERIOD = contact.defaultDuePeriod
			} else {
				DUE_PERIOD = getAccDocDefaultDueDate(state, accDocDirection) ?? DEFAULT_DUE_DATE_INTERVAL
			}
			// Pokud změním datum vystavení faktury a datum splatnosti se neměnil,
			// tak přepočítám datum splatnosti na základě změněného datumu vystavení.
			// "datum splatnosti se neměnil" = rozdíl mezi datumem vystavení faktury a datumem splatnosti
			// odpovídá splatnosti kontaktu (případně defaultní splatnosti,
			// pokud není konktakt vybrán nebo nemá splatnost vyplněnou)
			const accDocDuePeriod = getDuePeriod(doc)

			if (accDocDuePeriod === DUE_PERIOD) {
				let tmpDueDate = new Date(issueDate)
				tmpDueDate.setDate(issueDate.getDate() + DUE_PERIOD)
				fields.dueDate = toDateOnlyIsoString(tmpDueDate)
			}
		}

		return dispatch(updateAccountingDocumentHeaderMoreFields(accountingDocumentId, fields))
	}
}

export function updateAccountingDocumentTaxDate(accountingDocumentId: string, taxDate: ?Date) {
	return async (dispatch: Dispatch<AccountingDocumentReduxAction>) => {
		const taxDateString: ?string = (taxDate && toDateOnlyIsoString(taxDate)) || null
		await dispatch(
			updateAccountingDocumentHeaderMoreFields(accountingDocumentId, {
				taxDate: taxDateString,
				bookingDate: taxDateString, // change booking date as well
			}),
		)
	}
}

export function updateAccountingDocumentTotalAndTotalVatExcl(accountingDocumentId: string, newTotal: ?number) {
	return async (
		dispatch: Dispatch<AccountingDocumentReduxAction | IssueAction>,
	): Promise<AccountingDocumentReduxAction | IssueAction> => {
		const vatRecap: AccountingDocumentVatRecapInfo = {
			vatRecapCalculationMode: 2,
			vatRecapLines: [
				{
					vatRate: 0,
					vatRateType: 1,
					base: 0,
					vatAmount: 0,
					total: 0,
				},
			],
			total: newTotal || 0,
			totalVatExcl: newTotal || 0,
		}
		return dispatch(updateAccountingDocumentVatRecapInfo(accountingDocumentId, vatRecap))
	}
}

export function updateAccountingDocumentRelatedAccountingDocument(
	accountingDocumentId: string,
	relatedAccountingDocumentId: ?string,
) {
	return async (
		dispatch: Dispatch<AccountingDocumentReduxAction>,
		getState: () => State,
	): Promise<AccountingDocumentReduxAction> => {
		// const state = getState()
		// const cvs = getAccountingDocumentCountryVariantSpecific(state, relatedAccountingDocumentId || '')
		// const isEetUsed = isEetUsedOrSent(cvs)

		// isEetUsed &&
		// 	dispatch(
		// 		countryVariantUpdate(accountingDocumentId, {
		// 			cz: {
		// 				eet: {
		// 					use: false,
		// 				},
		// 			},
		// 		}),
		// 	)

		return dispatch(
			updateAccountingDocumentHeaderField(
				accountingDocumentId,
				'relatedAccountingDocumentId',
				relatedAccountingDocumentId,
			),
		)
	}
}

export function updateAccountingDocumentOssMode(accountingDocumentId: string, mode: ?OssMode) {
	return async (dispatch: Dispatch<AccountingDocumentReduxAction>, getState: () => State) => {
		const state = getState()
		const oldAccountingDocument: ?AccountingDocument = getAccountingDocument(state, accountingDocumentId)
		if (!oldAccountingDocument) return
		const settings = { ...oldAccountingDocument.settings }
		set(settings, 'oss.forcedValue', mode)
		return dispatch(updateAccountingDocumentHeaderField(accountingDocumentId, 'settings', settings))
	}
}

export function resetAccountingDocumentOssMode(accountingDocumentId: string) {
	return async (dispatch: Dispatch<AccountingDocumentReduxAction>, getState: () => State) => {
		const state = getState()
		const oldAccountingDocument: ?AccountingDocument = getAccountingDocument(state, accountingDocumentId)
		if (!oldAccountingDocument) return
		const newAccountingDocumentSettings = { ...oldAccountingDocument.settings, oss: {} }
		return dispatch(
			updateAccountingDocumentHeaderField(accountingDocumentId, 'settings', newAccountingDocumentSettings),
		)
	}
}

export function updateAccountingDocumentBookingDate(accountingDocumentId: string, bookingDate: ?Date) {
	return async (dispatch: Dispatch<AccountingDocumentReduxAction>): Promise<AccountingDocumentReduxAction> => {
		const bookingDateString: ?string = (bookingDate && formatDateToIsoString(bookingDate)) || null
		return dispatch(updateAccountingDocumentHeaderField(accountingDocumentId, 'bookingDate', bookingDateString))
	}
}

export function updateAccountingDocumentTaxReturnDate(accountingDocumentId: string, taxReturnDate: ?Date) {
	return async (dispatch: Dispatch<AccountingDocumentReduxAction>): Promise<AccountingDocumentReduxAction> => {
		const taxReturnDateString: ?string = (taxReturnDate && formatDateToIsoString(taxReturnDate)) || null
		return dispatch(updateAccountingDocumentHeaderField(accountingDocumentId, 'taxReturnDate', taxReturnDateString))
	}
}

export function updateAccountingDocumentSignatureId(accountingDocumentId: string, signatureId: ?string) {
	return async (dispatch: Dispatch<AccountingDocumentReduxAction>): Promise<AccountingDocumentReduxAction> => {
		return dispatch(updateAccountingDocumentHeaderField(accountingDocumentId, 'signatureId', signatureId))
	}
}

export function updateAccountingDocumentDescription(accountingDocumentId: string, newDescription: ?string) {
	return async (dispatch: Dispatch<AccountingDocumentReduxAction>): Promise<AccountingDocumentReduxAction> => {
		return dispatch(updateAccountingDocumentHeaderField(accountingDocumentId, 'description', newDescription))
	}
}

export function updateAccountingDocumentCustomerInstructions(
	accountingDocumentId: string,
	newCustomerInstructions: ?string,
) {
	return async (dispatch: Dispatch<AccountingDocumentReduxAction>): Promise<AccountingDocumentReduxAction> => {
		dispatch({
			type: 'START_UPDATE_ACCOUNTING_DOCUMENT_CUSTOMER_INSTRUCTIONS',
			accountingDocumentId,
			note: newCustomerInstructions,
		})

		try {
			const updatedNote: AccountingDocumentExtractingCustomerInstructions = await accountingDocumentExtractingInstructionsApi.put(
				{ accountingDocumentId },
				{ note: newCustomerInstructions ?? undefined },
			)

			return dispatch({
				type: 'FINISH_UPDATE_ACCOUNTING_DOCUMENT_CUSTOMER_INSTRUCTIONS',
				accountingDocumentId,
				note: updatedNote.note,
				success: true,
			})
		} catch (error) {
			return dispatch({
				type: 'FINISH_UPDATE_ACCOUNTING_DOCUMENT_CUSTOMER_INSTRUCTIONS',
				accountingDocumentId,
				serverError: error,
				success: false,
			})
		}
	}
}

export function setAccountingDocumentOneNote(accountingDocumentId: string, note: ?AccountingDocumentNote) {
	return async (dispatch: Dispatch<AccountingDocumentReduxAction>): Promise<AccountingDocumentReduxAction> => {
		dispatch({
			type: 'START_UPDATE_ACCOUNTING_DOCUMENT_NOTE',
			accountingDocumentId,
		})

		try {
			const updatedNote: AccountingDocumentExtractingNoteBody = await accountingDocumentNoteApi.put(
				{ accountingDocumentId },
				{ notes: note ? [note] : [] },
			)

			return dispatch({
				type: 'FINISH_UPDATE_ACCOUNTING_DOCUMENT_NOTE',
				accountingDocumentId,
				notes: updatedNote.notes,
				success: true,
			})
		} catch (error) {
			return dispatch({
				type: 'FINISH_UPDATE_ACCOUNTING_DOCUMENT_NOTE',
				accountingDocumentId,
				serverError: error,
				success: false,
			})
		}
	}
}

export function deleteAllAccountingDocumentNotes(accountingDocumentId: string) {
	return async (dispatch: Dispatch<AccountingDocumentReduxAction>): Promise<AccountingDocumentReduxAction> => {
		return dispatch(updateAccountingDocumentHeaderMoreFields(accountingDocumentId, { notes: [] }))
	}
}

export function updateAccountingDocumentVariableSymbol(accountingDocumentId: string, variableSymbol: ?string) {
	return async (dispatch: Dispatch<AccountingDocumentReduxAction>): Promise<AccountingDocumentReduxAction> => {
		return dispatch(updateAccountingDocumentHeaderField(accountingDocumentId, 'variableSymbol', variableSymbol))
	}
}

export function updateAccountingDocumentCreditNoteDelayedVat(accountingDocumentId: string, confirmed: boolean) {
	return async (dispatch: Dispatch<AccountingDocumentReduxAction>): Promise<AccountingDocumentReduxAction> => {
		return dispatch(updateAccountingDocumentHeaderField(accountingDocumentId, 'creditNoteDelayedVat', confirmed))
	}
}

export function setAccountingDocumentExplicitNo(accountingDocumentId: string, explicitNo: string) {
	return async (dispatch: Dispatch<AccountingDocumentReduxAction>): Promise<AccountingDocumentReduxAction> => {
		return dispatch(
			updateAccountingDocumentHeaderMoreFields(accountingDocumentId, {
				explicitNo,
				sequenceId: null,
			}),
		)
	}
}

export function setAccountingDocumentSequenceId(accountingDocumentId: string, sequenceId: string) {
	return async (dispatch: Dispatch<AccountingDocumentReduxAction>): Promise<AccountingDocumentReduxAction> => {
		return dispatch(
			updateAccountingDocumentHeaderMoreFields(accountingDocumentId, {
				sequenceId,
				explicitNo: null,
			}),
		)
	}
}

export function setAccountingDocumentOrderNo(accountingDocumentId: string, orderNo: ?string) {
	return async (dispatch: Dispatch<AccountingDocumentReduxAction>): Promise<AccountingDocumentReduxAction> => {
		return dispatch(updateAccountingDocumentHeaderField(accountingDocumentId, 'orderNo', orderNo))
	}
}

export function setAccountingDocumentExternalNo(accountingDocumentId: string, externalNo: string) {
	return async (dispatch: Dispatch<AccountingDocumentReduxAction>): Promise<AccountingDocumentReduxAction> => {
		return dispatch(updateAccountingDocumentHeaderField(accountingDocumentId, 'externalNo', externalNo))
	}
}

export function updateAccountingDocumentVatCountryType(accountingDocumentId: string, vatCountryType: number) {
	return async (dispatch: Dispatch<AccountingDocumentReduxAction>): Promise<AccountingDocumentReduxAction> => {
		// TWU-2905 (Doklady - Identifikovaná osoba nemá pro ČR vidět pole DPH)
		// For correct vatRateId property of Line Items API response, AccDoc vatCountryType has to be updated first
		return dispatch(updateAccountingDocumentHeaderField(accountingDocumentId, 'vatCountryType', vatCountryType))
	}
}

export function updateAccountingDocumentCurrency(accountingDocumentId: string, currency: ?string) {
	return async (dispatch: Dispatch<AccountingDocumentReduxAction>): Promise<AccountingDocumentReduxAction> => {
		return dispatch(updateAccountingDocumentHeaderField(accountingDocumentId, 'currency', currency))
	}
}

export function updateAccountingDocumentLanguage(
	accountingDocumentId: string,
	newLanguage: ?AccountingDocumentLanguage,
) {
	return async (dispatch: Dispatch<AccountingDocumentReduxAction | UserAction>, getState: () => State) => {
		const state: State = getState()
		const item = state.accountingDocument.accountingDocuments.items[accountingDocumentId]
		const oldHeader: ?AccountingDocumentHeader = item && item.header
		const oldLanguage: ?AccountingDocumentLanguage = oldHeader && oldHeader.language
		if (oldLanguage === newLanguage) return null

		dispatch({
			type: 'START_UPDATE_ACCOUNTING_DOCUMENT_LANGUAGE',
			accountingDocumentId,
			newLanguage,
		})

		try {
			const patch = diff(oldHeader, { ...oldHeader, language: newLanguage })
			const confirmedHeader: AccountingDocumentHeader = await accountingDocumentHeaderApi.patch(
				{ accountingDocumentId },
				patch,
			)
			const confirmedLanguage: ?AccountingDocumentLanguage = confirmedHeader.language

			return dispatch({
				type: 'FINISH_UPDATE_ACCOUNTING_DOCUMENT_LANGUAGE',
				accountingDocumentId,
				oldLanguage,
				confirmedLanguage,
				success: true,
			})
		} catch (error) {
			return dispatch({
				type: 'FINISH_UPDATE_ACCOUNTING_DOCUMENT_LANGUAGE',
				accountingDocumentId,
				oldLanguage,
				confirmedLanguage: null,
				serverError: error,
				success: false,
			})
		}
	}
}

export function updateAccountingDocumentExchRate(accountingDocumentId: string, exchRate: ?number) {
	return async (dispatch: Dispatch<AccountingDocumentReduxAction>): Promise<AccountingDocumentReduxAction> => {
		return dispatch(updateAccountingDocumentHeaderField(accountingDocumentId, 'exchRate', exchRate)) //TODO flow
	}
}

export function updateAccountingDocumentExchRateDefault(accountingDocumentId: string, exchRate: ?number) {
	return async (dispatch: Dispatch<AccountingDocumentReduxAction>): Promise<AccountingDocumentReduxAction> => {
		return dispatch(updateAccountingDocumentHeaderField(accountingDocumentId, 'exchRateDefault', exchRate)) //TODO flow
	}
}

export function updateAccountingDocumentVatExchRate(accountingDocumentId: string, vatExchRate: ?number) {
	return async (dispatch: Dispatch<AccountingDocumentReduxAction>): Promise<AccountingDocumentReduxAction> => {
		return dispatch(updateAccountingDocumentHeaderField(accountingDocumentId, 'vatExchRate', vatExchRate)) //TODO flow
	}
}

export function updateAccountingDocumentSadExchRate(accountingDocumentId: string, sadExchRate: ?number) {
	return async (dispatch: Dispatch<AccountingDocumentReduxAction>): Promise<AccountingDocumentReduxAction> => {
		return dispatch(updateAccountingDocumentHeaderField(accountingDocumentId, 'sadExchRate', sadExchRate)) //TODO flow
	}
}

export function updateAccountingDocumentPaymentType(accountingDocumentId: string, paymentType: ?number) {
	return async (dispatch: Dispatch<AccountingDocumentReduxAction>): Promise<AccountingDocumentReduxAction> => {
		return dispatch(updateAccountingDocumentHeaderField(accountingDocumentId, 'paymentType', paymentType)) //TODO flow
	}
}

export function updateAccountingDocumentVatRecapCalculationMode(
	accountingDocumentId: string,
	vatRecapCalculationMode: ?number,
) {
	return async (dispatch: Dispatch<AccountingDocumentReduxAction>): Promise<AccountingDocumentReduxAction> => {
		return dispatch(
			updateAccountingDocumentHeaderField(accountingDocumentId, 'vatRecapCalculationMode', vatRecapCalculationMode),
		)
	}
}

export function updateAccountingDocumentRoundingType(accountingDocumentId: string, roundingType: ?number) {
	return async (dispatch: Dispatch<AccountingDocumentReduxAction>): Promise<AccountingDocumentReduxAction> => {
		return dispatch(updateAccountingDocumentHeaderField(accountingDocumentId, 'roundingType', roundingType))
	}
}

export function updateAccountingDocumentCreditNoteDescription(
	accountingDocumentId: string,
	creditNoteDescription: ?string,
) {
	return async (dispatch: Dispatch<AccountingDocumentReduxAction>): Promise<AccountingDocumentReduxAction> => {
		return dispatch(
			updateAccountingDocumentHeaderField(accountingDocumentId, 'creditNoteDescription', creditNoteDescription),
		)
	}
}

export function changeAccountingDocumentState(accountingDocumentId: string, state: string) {
	return async (
		dispatch: Dispatch<AccountingDocumentReduxAction>,
		getState: () => State,
	): Promise<AccountingDocumentReduxAction> => {
		const accountingDocument = getAccountingDocument(getState(), accountingDocumentId)
		const isSimple = accountingDocument && AccountingDocumentTypeNumber_isSimple(accountingDocument.type)

		dispatch({
			type: 'START_CHANGE_ACCOUNTING_DOCUMENT_STATE',
			accountingDocumentId,
			state,
		})
		try {
			await accountingDocumentStateApi.put({ accountingDocumentId }, { state })

			dispatch(loadAccountingDocument(accountingDocumentId)).then(() => {
				dispatch(loadSequenceIfNeeded(accountingDocumentId))
			})

			dispatch(loadAccountingDocumentPossibleStates(accountingDocumentId)) //load new possible states
			Tracking.trackStateChange(accountingDocumentId, state, accountingDocument ? accountingDocument.state : null)

			return dispatch({
				type: 'FINISH_CHANGE_ACCOUNTING_DOCUMENT_STATE',
				accountingDocumentId,
			})
		} catch (error) {
			const { serverError, validationError } = getServerAndValidationErrors(error)
			return dispatch({
				type: 'FINISH_CHANGE_ACCOUNTING_DOCUMENT_STATE',
				accountingDocumentId,
				serverError: isSimple ? error : serverError,
				validationErrorAccDoc: isSimple ? undefined : validationError,
			})
		}
	}
}

function loadSequenceIfNeeded(accountingDocumentId: string): ActionFunction<SequencesAction> {
	return async (dispatch: Dispatch<SequencesAction>, getState: () => State) => {
		const accountingDocument = getAccountingDocument(getState(), accountingDocumentId)
		if (!(accountingDocument && accountingDocument.sequenceId && accountingDocument.state === 'Processed')) {
			return
		}
		dispatch(loadSequence(accountingDocument.sequenceId, accountingDocument.cashRegisterId))
	}
}

const vatRateRecapInfoThrottle = createThrottle(3000)

export function loadAccountingDocumentVatRecapInfo(accountingDocumentId: string) {
	return async (dispatch: Dispatch<AccountingDocumentReduxAction | IssueAction>) => {
		dispatch({
			type: 'START_LOAD_ACCOUNTING_DOCUMENT_VAT_RECAP_INFO',
			accountingDocumentId,
		})
		return dispatch(
			vatRateRecapInfoThrottle(async () => {
				try {
					const loadedVatRecapInfo: AccountingDocumentVatRecapInfo = await vatRecapApi.get({
						accountingDocumentId,
					})

					dispatch(loadIssues(accountingDocumentId))

					dispatch({
						type: 'FINISH_LOAD_ACCOUNTING_DOCUMENT_VAT_RECAP_INFO',
						accountingDocumentId,
						loadedVatRecapInfo,
						success: true,
					})
				} catch (error) {
					dispatch({
						type: 'FINISH_LOAD_ACCOUNTING_DOCUMENT_VAT_RECAP_INFO',
						accountingDocumentId,
						loadedVatRecapInfo: null,
						serverError: error,
						success: false,
					})
				}
			}),
		)
	}
}

export function updateAccountingDocumentVatRecapInfo(
	accountingDocumentId: string,
	newVatRecapInfo: AccountingDocumentVatRecapInfo,
) {
	return async (
		dispatch: Dispatch<AccountingDocumentReduxAction | IssueAction>,
		getState: () => State,
	): Promise<AccountingDocumentReduxAction | IssueAction> => {
		const state: State = getState()
		const oldVatRecapinfo: ?AccountingDocumentVatRecapInfo = getAccountingDocumentVatRecapInfo(
			state,
			accountingDocumentId,
		)
		dispatch({
			type: 'START_UPDATE_ACCOUNTING_DOCUMENT_VAT_RECAP_INFO',
			accountingDocumentId,
			oldVatRecapInfo: oldVatRecapinfo,
			newVatRecapInfo,
		})
		try {
			const confirmedVatRecapInfo: AccountingDocumentVatRecapInfo = await vatRecapApi.put(
				{ accountingDocumentId },
				newVatRecapInfo,
			)

			dispatch(loadIssues(accountingDocumentId))

			return dispatch({
				type: 'FINISH_UPDATE_ACCOUNTING_DOCUMENT_VAT_RECAP_INFO',
				accountingDocumentId,
				oldVatRecapInfo: oldVatRecapinfo,
				confirmedVatRecapInfo,
				success: true,
			})
		} catch (error) {
			return dispatch({
				type: 'FINISH_UPDATE_ACCOUNTING_DOCUMENT_VAT_RECAP_INFO',
				accountingDocumentId,
				oldVatRecapInfo: oldVatRecapinfo,
				confirmedVatRecapInfo: null,
				serverError: error,
				success: false,
			})
		}
	}
}

export function countryVariantUpdate(accountingDocumentId: string, countryVariantRequest: CountryVariantRequest) {
	return async (dispatch: Dispatch<AccountingDocumentReduxAction>): Promise<AccountingDocumentReduxAction> => {
		dispatch({
			type: 'START_COUNTRY_VARIANT_UPDATE',
			accountingDocumentId,
			countryVariantRequest,
		})

		try {
			const countryVariantResponse = await countryVariantSpecificApi.put(
				{ accountingDocumentId },
				countryVariantRequest,
			)
			return dispatch({
				type: 'FINISH_COUNTRY_VARIANT_UPDATE',
				accountingDocumentId,
				countryVariantResponse,
			})
		} catch (error) {
			return dispatch({
				type: 'FINISH_COUNTRY_VARIANT_UPDATE',
				accountingDocumentId,
				countryVariantResponse: null,
				serverError: error,
			})
		}
	}
}

export function updateAccountingDocumentPaymentInfo(accountingDocumentId: string, paid: boolean) {
	return async (dispatch: Dispatch<AccountingDocumentReduxAction>): Promise<AccountingDocumentReduxAction> => {
		dispatch({
			type: 'START_UPDATE_ACCOUNTING_DOCUMENT_PAYMENT_INFO',
			accountingDocumentId: accountingDocumentId,
			paid,
		})
		try {
			const result: AccountingDocumentPaymentInfo = await accountingDocumentPaymentsApi.put(
				{ accountingDocumentId },
				{ paid },
			)
			return dispatch({
				type: 'FINISH_UPDATE_ACCOUNTING_DOCUMENT_PAYMENT_INFO',
				accountingDocumentId: accountingDocumentId,
				paymentInfo: result,
			})
		} catch (error) {
			return dispatch({
				type: 'FINISH_UPDATE_ACCOUNTING_DOCUMENT_PAYMENT_INFO',
				accountingDocumentId: accountingDocumentId,
				paymentInfo: null,
				serverError: error,
			})
		}
	}
}

export function loadAccountingDocumentConnections(accountingDocumentId: string) {
	return async (dispatch: Dispatch<AccountingDocumentReduxAction>) => {
		dispatch({
			type: 'START_LOAD_ACCOUNTING_DOCUMENT_CONNECTIONS',
			accountingDocumentId,
		})
		try {
			let connections: Array<AccountingDocumentConnection> = []
			connections = await accountingDocumentConnnectionsApi.get({ accountingDocumentId })
			return dispatch({
				type: 'FINISH_LOAD_ACCOUNTING_DOCUMENT_CONNECTIONS',
				accountingDocumentId,
				connections,
				success: true,
			})
		} catch (error) {
			return dispatch({
				type: 'FINISH_LOAD_ACCOUNTING_DOCUMENT_CONNECTIONS',
				accountingDocumentId,
				connections: [],
				serverError: error,
				success: false,
			})
		}
	}
}

export function connectAccountingDocumentConnection(
	accountingDocumentId: string,
	newConnection: AccountingDocumentConnection,
) {
	return async (dispatch: Dispatch<AccountingDocumentReduxAction | IssueAction>, getState: () => State) => {
		const state: State = getState()
		const oldConnection: ?AccountingDocumentConnection = getAccountingDocumentConnection(
			state,
			accountingDocumentId,
			newConnection.connectedAccountingDocumentId,
		)
		const oldAccountingDocument: ?AccountingDocument = getAccountingDocument(state, accountingDocumentId)
		dispatch({
			type: 'START_CONNECT_ACCOUNTING_DOCUMENT_CONNECTION',
			accountingDocumentId,
			oldAccountingDocument,
			oldConnection,
			newConnection,
		})

		try {
			const confirmedAccountingDocument: AccountingDocument = await accountingDocumentConnnectionsApi.post(
				{ accountingDocumentId },
				newConnection,
			)

			return dispatch({
				type: 'FINISH_CONNECT_ACCOUNTING_DOCUMENT_CONNECTION',
				accountingDocumentId,
				oldAccountingDocument,
				oldConnection,
				newConnection,
				confirmedAccountingDocument,
				success: true,
			})
		} catch (error) {
			return dispatch({
				type: 'FINISH_CONNECT_ACCOUNTING_DOCUMENT_CONNECTION',
				accountingDocumentId,
				oldAccountingDocument,
				oldConnection,
				newConnection,
				confirmedAccountingDocument: null,
				serverError: error,
				success: false,
			})
		}
	}
}

export function updateAccountingDocumentConnection(
	accountingDocumentId: string,
	newConnection: AccountingDocumentConnection,
) {
	return async (dispatch: Dispatch<AccountingDocumentReduxAction>, getState: () => State) => {
		const state: State = getState()
		const oldConnection: ?AccountingDocumentConnection = getAccountingDocumentConnection(
			state,
			accountingDocumentId,
			newConnection.connectedAccountingDocumentId,
		)
		const oldAccountingDocument: ?AccountingDocument = getAccountingDocument(state, accountingDocumentId)
		dispatch({
			type: 'START_UPDATE_ACCOUNTING_DOCUMENT_CONNECTION',
			accountingDocumentId,
			oldAccountingDocument,
			oldConnection,
			newConnection,
		})
		try {
			const params = {
				accountingDocumentId,
				connectedAccountingDocumentId: newConnection.connectedAccountingDocumentId,
			}
			const confirmedAccountingDocument: AccountingDocument = await accountingDocumentConnnectionApi.put(
				params,
				newConnection,
			)
			return dispatch({
				type: 'FINISH_UPDATE_ACCOUNTING_DOCUMENT_CONNECTION',
				accountingDocumentId,
				oldConnection,
				newConnection,
				oldAccountingDocument,
				confirmedAccountingDocument,
				success: true,
			})
		} catch (error) {
			return dispatch({
				type: 'FINISH_UPDATE_ACCOUNTING_DOCUMENT_CONNECTION',
				accountingDocumentId,
				oldConnection,
				newConnection,
				oldAccountingDocument,
				confirmedAccountingDocument: null,
				serverError: error,
				success: false,
			})
		}
	}
}

export function disconnectAccountingDocumentConnection(
	accountingDocumentId: string,
	connection: AccountingDocumentConnection,
) {
	return async (dispatch: Dispatch<AccountingDocumentReduxAction | IssueAction>) => {
		dispatch({
			type: 'START_DISCONNECT_ACCOUNTING_DOCUMENT_CONNECTION',
			accountingDocumentId,
			connection,
		})
		try {
			const params = {
				accountingDocumentId,
				connectedAccountingDocumentId: connection.connectedAccountingDocumentId,
			}
			await accountingDocumentConnnectionApi.delete(params)

			return dispatch({
				type: 'FINISH_DISCONNECT_ACCOUNTING_DOCUMENT_CONNECTION',
				accountingDocumentId,
				connection,
				success: true,
			})
		} catch (error) {
			return dispatch({
				type: 'FINISH_DISCONNECT_ACCOUNTING_DOCUMENT_CONNECTION',
				accountingDocumentId,
				connection,
				serverError: error,
				success: false,
			})
		}
	}
}

export function acknowledgeAccountingDocument(accountingDocumentId: string, data: AcknowledgeRequest) {
	return async (
		dispatch: Dispatch<AccountingDocumentReduxAction>,
		getState: () => State,
	): Promise<AccountingDocumentReduxAction> => {
		dispatch({
			type: 'START_ACKNOWLEDGE_ACCOUNTING_DOCUMENT',
			accountingDocumentId,
			direction: data.direction,
			documentType: data.type,
		})
		try {
			const accountingDocument = getAccountingDocumentRaw(getState(), accountingDocumentId)
			if (accountingDocument && isAccountingDocumentUnknownExtracted(accountingDocument)) {
				data.replaceWithExtractingTemplate = true
			}

			await accountingDocumentAcknowledgeApi.post({ accountingDocumentId }, data)
			return dispatch({
				type: 'FINISH_ACKNOWLEDGE_ACCOUNTING_DOCUMENT',
				accountingDocumentId,
				success: true,
			})
		} catch (error) {
			const { serverError, validationError } = getServerAndValidationErrors(error)
			return dispatch({
				type: 'FINISH_ACKNOWLEDGE_ACCOUNTING_DOCUMENT',
				accountingDocumentId,
				success: false,
				serverError,
				validationError,
			})
		}
	}
}

export function printAccountingDocument(accountingDocumentId: string) {
	return async (dispatch: Dispatch<AccountingDocumentReduxAction>, getState: () => State) => {
		const printLang: PrintLanguage = getAccountingDocumentPrintLanguage(getState(), accountingDocumentId)
		dispatch({ type: 'START_PRINTING_ACCOUNTING_DOCUMENT' })
		try {
			const resp = await printRequestApi.post({ accountingDocumentId })
			if (resp.token) {
				const url = buildPublicUrl(
					`accountingdocuments/${accountingDocumentId}/pdf/${resp.token}?language=${printLang}`,
				)
				window.location.href = url
			}
		} finally {
			dispatch({ type: 'FINISH_PRINTING_ACCOUNTING_DOCUMENT' })
		}
	}
}

export function exportAccountingDocuments(searchExport: AccountingDocumentSearchExport) {
	return async (dispatch: Dispatch<AccountingDocumentReduxAction>) => {
		const loaderId = 'accDoc_exportAccountingDocuments'
		dispatch({ type: 'START_EXPORTING_ACCOUNTING_DOCUMENTS' })
		try {
			beginTask(loaderId)
			const downloadToken: DownloadToken = await accountingDocumentsExportRequestApi.post(
				{ organizationId: undefined },
				searchExport,
			)
			if (downloadToken.token) {
				const url = buildPublicUrl(`downloads/tokens/${downloadToken.token}`)
				window.location.href = url
			}
		} finally {
			endTask(loaderId)
			dispatch({ type: 'FINISH_EXPORTING_ACCOUNTING_DOCUMENTS' })
		}
	}
}

export function sendAccountingDocument(accountingDocumentId: string, emailTemplateId: number, emails?: Array<string>) {
	return async (dispatch: Dispatch<CommonAction | AccountingDocumentReduxAction>) => {
		dispatch({
			type: 'START_SENDING_ACCOUNTING_DOCUMENT_EMAIL',
		})
		try {
			await accountingDocumentsSendApi.post(
				{ accountingDocumentId },
				{
					emails: emails,
					formats: [1],
					emailTemplateId,
				},
			)
			dispatch({
				type: 'FINISH_SENDING_ACCOUNTING_DOCUMENT_EMAIL',
			})
			dispatch({
				type: 'SET_NOTIFICATION',
				notification: {
					message: 'notifications.emailSent',
					type: 'success',
				},
			})
		} catch (serverError) {
			dispatch({
				type: 'FINISH_SENDING_ACCOUNTING_DOCUMENT_EMAIL',
				validationError: serverError,
			})
			dispatch({
				type: 'SET_NOTIFICATION',
				notification: {
					message: 'notifications.emailNotSent',
					type: 'error',
				},
			})
		}
	}
}

export function sendReminderAccountingDocument(
	accountingDocumentId: string,
	emailTemplateId: number,
	emails?: Array<string>,
) {
	return async (dispatch: Dispatch<CommonAction | AccountingDocumentReduxAction>) => {
		dispatch({
			type: 'START_SENDING_REMINDER_ACCOUNTING_DOCUMENT_EMAIL',
		})
		try {
			await accountingDocumentsSendReminder.post(
				{ accountingDocumentId },
				{
					emails: emails,
					emailTemplateId,
				},
			)
			dispatch({
				type: 'FINISH_SENDING_REMINDER_ACCOUNTING_DOCUMENT_EMAIL',
			})
			dispatch({
				type: 'SET_NOTIFICATION',
				notification: {
					message: 'notifications.emailSentReminder',
					type: 'success',
				},
			})
		} catch (serverError) {
			dispatch({
				type: 'FINISH_SENDING_REMINDER_ACCOUNTING_DOCUMENT_EMAIL',
				validationError: serverError,
			})
			dispatch({
				type: 'SET_NOTIFICATION',
				notification: {
					message: 'notifications.emailNotSentReminder',
					type: 'error',
				},
			})
		}
	}
}

export function forceProcess(accountingDocumentId: number) {
	return async (dispatch: Dispatch<AccountingDocumentReduxAction>, getState: () => State) => {
		const state: State = getState()
		const item: ?AccountingDocumentItem =
			state.accountingDocument.accountingDocuments.items[accountingDocumentId.toString()]
		const doc: ?AccountingDocument = item && item.data
		const oldProcessingState: ?number = doc && doc.processingState

		const newProcessingState: number = 2 // waiting fot processing
		try {
			dispatch({
				type: 'START_FORCE_PROCESS',
				accountingDocumentId,
				newProcessingState,
			})

			await forceProcessingApi.post({ accountingDocumentId: accountingDocumentId.toString() })

			dispatch({
				type: 'FINISH_FORCE_PROCESS',
				accountingDocumentId,
				oldProcessingState,
				newProcessingState,
			})
		} catch (serverError) {
			dispatch({
				type: 'FINISH_FORCE_PROCESS',
				accountingDocumentId,
				oldProcessingState,
				newProcessingState,
				serverError,
			})
		}
	}
}

export function loadAccountingDocumentPaymentReminders(accountingDocumentId: string) {
	return async (dispatch: Dispatch<AccountingDocumentReduxAction>) => {
		dispatch({
			type: 'START_LOADING_ACCOUNTING_DOCUMENT_PAYMENT_REMINDERS',
		})
		try {
			const resp: ?Array<PaymentReminder> = await accountingDocumentPaymentRemindersApi.get({
				accountingDocumentId,
			})
			dispatch({
				type: 'FINISH_LOADING_ACCOUNTING_DOCUMENT_PAYMENT_REMINDERS',
				accountingDocumentId,
				paymentReminders: resp,
			})
		} catch (serverError) {
			dispatch({
				type: 'FINISH_LOADING_ACCOUNTING_DOCUMENT_PAYMENT_REMINDERS',
				serverError,
				accountingDocumentId,
				paymentReminders: null,
			})
		}
	}
}

export function createAccountingDocumentPaymentReminder(
	accountingDocumentId: string,
	paymentReminder: PaymentReminder,
) {
	return async (dispatch: Dispatch<AccountingDocumentReduxAction>) => {
		dispatch({
			type: 'START_CREATING_ACCOUNTING_DOCUMENT_PAYMENT_REMINDERS',
		})
		try {
			const result: PaymentReminder = await accountingDocumentPaymentRemindersApi.post(
				{
					accountingDocumentId,
				},
				paymentReminder,
			)
			dispatch({
				type: 'FINISH_CREATING_ACCOUNTING_DOCUMENT_PAYMENT_REMINDERS',
				accountingDocumentId,
				paymentReminder: result,
			})
		} catch (serverError) {
			dispatch({
				type: 'FINISH_CREATING_ACCOUNTING_DOCUMENT_PAYMENT_REMINDERS',
				serverError,
				accountingDocumentId,
			})
		}
	}
}

export function updateAccountingDocumentPaymentReminder(
	accountingDocumentId: string,
	reminderId: string,
	paymentReminder: PaymentReminder,
) {
	return async (dispatch: Dispatch<AccountingDocumentReduxAction>, getState: () => State) => {
		const state: State = getState()
		const item: ?AccountingDocumentItem = state.accountingDocument.accountingDocuments.items[accountingDocumentId]

		const oldPaymentReminders: ?Array<PaymentReminder> = item && item.paymentReminders && [...item.paymentReminders]

		const newPaymentReminders: ?Array<PaymentReminder> =
			item &&
			item.paymentReminders &&
			item.paymentReminders.map((r: PaymentReminder) => {
				return r.id === reminderId ? paymentReminder : r
			})

		dispatch({
			type: 'START_UPDATING_ACCOUNTING_DOCUMENT_PAYMENT_REMINER',
			accountingDocumentId,
			newPaymentReminders,
		})
		try {
			await accountingDocumentPaymentReminderApi.put(
				{
					accountingDocumentId,
					reminderId,
				},
				paymentReminder,
			)
			dispatch({
				type: 'FINISH_UPDATING_ACCOUNTING_DOCUMENT_PAYMENT_REMINER',
				accountingDocumentId,
				newPaymentReminders,
			})
		} catch (serverError) {
			dispatch({
				type: 'FINISH_UPDATING_ACCOUNTING_DOCUMENT_PAYMENT_REMINER',
				serverError,
				accountingDocumentId,
				oldPaymentReminders,
			})
		}
	}
}

export function deleteAccountingDocumentPaymentReminder(accountingDocumentId: string, reminderId: string) {
	return async (dispatch: Dispatch<AccountingDocumentReduxAction>, getState: () => State) => {
		const state: State = getState()
		const item: ?AccountingDocumentItem = state.accountingDocument.accountingDocuments.items[accountingDocumentId]

		const oldPaymentReminders: ?Array<PaymentReminder> = item && item.paymentReminders && [...item.paymentReminders]

		const newPaymentReminders: ?Array<PaymentReminder> =
			item && item.paymentReminders && item.paymentReminders.filter((r: PaymentReminder) => r.id !== reminderId)

		dispatch({
			type: 'START_DELETING_ACCOUNTING_DOCUMENT_PAYMENT_REMINER',
			accountingDocumentId,
			newPaymentReminders,
		})
		try {
			await accountingDocumentPaymentReminderApi.delete({
				accountingDocumentId,
				reminderId,
			})
			dispatch({
				type: 'FINISH_DELETING_ACCOUNTING_DOCUMENT_PAYMENT_REMINER',
				accountingDocumentId,
				newPaymentReminders,
			})
		} catch (serverError) {
			dispatch({
				type: 'FINISH_DELETING_ACCOUNTING_DOCUMENT_PAYMENT_REMINER',
				serverError,
				accountingDocumentId,
				oldPaymentReminders,
			})
		}
	}
}

export function loadAccountingDocumentQrCode(accountingDocumentId: string) {
	return async (dispatch: Dispatch<AccountingDocumentReduxAction>) => {
		dispatch({
			type: 'START_LOADING_ACCOUNTING_DOCUMENT_QR_CODE',
		})
		loadBase64Data(`accountingdocuments/${accountingDocumentId}/qr`)
			.then((qrCode: string) => {
				dispatch({
					type: 'FINISH_LOADING_ACCOUNTING_DOCUMENT_QR_CODE',
					accountingDocumentId,
					qrCode,
				})
			})
			.catch((serverError: ValidationError) => {
				dispatch({
					type: 'FINISH_LOADING_ACCOUNTING_DOCUMENT_QR_CODE',
					accountingDocumentId,
					qrCode: null,
					serverError,
				})
			})
	}
}

export function loadPublicAccountingDocumentQrCode(uniqueId: string) {
	return async (dispatch: Dispatch<AccountingDocumentReduxAction>) => {
		dispatch({
			type: 'START_LOAD_PUBLIC_ACCOUNTING_DOCUMENT_QR_CODE',
			uniqueId,
		})
		loadBase64Data(`accountingdocuments/uniqueid/${uniqueId}/qr`, true)
			.then((qrCode: string) => {
				dispatch({
					type: 'FINISH_LOAD_PUBLIC_ACCOUNTING_DOCUMENT_QR_CODE',
					uniqueId,
					qrCode,
				})
			})
			.catch((serverError: ValidationError) => {
				dispatch({
					type: 'FINISH_LOAD_PUBLIC_ACCOUNTING_DOCUMENT_QR_CODE',
					qrCode: null,
					serverError,
					uniqueId,
				})
			})
	}
}

export function loadAccountingDocumentPdf(accountingDocumentId: string) {
	return async (dispatch: Dispatch<AccountingDocumentReduxAction>) => {
		dispatch({
			type: 'START_LOADING_ACCOUNTING_DOCUMENT_PDF',
			accountingDocumentId,
		})
		return new Promise((resolve: Function, reject: Function) => {
			try {
				let xhr = createXHR(`accountingdocuments/${accountingDocumentId}/pdf`, 'GET')
				xhr.responseType = 'blob'
				xhr.onreadystatechange = () => {
					if (4 === xhr.readyState) {
						const blob = new Blob([xhr.response], { type: 'application/pdf' })
						const fileUrl = URL.createObjectURL(blob)

						dispatch({
							type: 'FINISH_LOADING_ACCOUNTING_DOCUMENT_PDF',
							accountingDocumentId,
							pdfUrl: fileUrl,
						})

						resolve(fileUrl)
					}
				}
				xhr.send({})
			} catch (serverError) {
				dispatch({
					type: 'FINISH_LOADING_ACCOUNTING_DOCUMENT_PDF',
					serverError,
					accountingDocumentId,
				})
				reject(null)
			}
		})
	}
}

export function loadAccountingDocumentPossibleStates(accountingDocumentId: string) {
	return async (dispatch: Dispatch<AccountingDocumentReduxAction>) => {
		dispatch({
			type: 'START_LOADING_ACCOUNTING_DOCUMENT_POSSIBLE_STATES',
			accountingDocumentId,
		})
		try {
			const result: AccountingDocumentStateInfo = await accountingDocumentNextStatesApi.get({ accountingDocumentId })
			const possibleStates: ?Array<string> = result.possibleStates
			dispatch({
				type: 'FINISH_LOADING_ACCOUNTING_DOCUMENT_POSSIBLE_STATES',
				accountingDocumentId,
				possibleStates,
			})
		} catch (serverError) {
			dispatch({
				type: 'FINISH_LOADING_ACCOUNTING_DOCUMENT_POSSIBLE_STATES',
				serverError,
				accountingDocumentId,
			})
		}
	}
}

export function fetchNextAccountingDocumentToExtract(lastExtractedAccountingDocumentId?: string) {
	return async (dispatch: Dispatch<AccountingDocumentReduxAction>) => {
		dispatch({
			type: 'START_FETCHING_NEXT_ACCOUNTING_DOCUMENT_TO_EXTRACT',
		})
		try {
			const result: AccountingDocumentExtractsFetchResponse = await accountingDocumentExtractsApi.post(
				{
					lastExtractedAccountingDocumentId,
				},
				{
					timeout: 60 * 1000,
				},
			)

			result.accountingDocumentId && Tracking.trackExtractingQueuePop(result.accountingDocumentId)

			return dispatch({
				type: 'FINISH_FETCHING_NEXT_ACCOUNTING_DOCUMENT_TO_EXTRACT',
				result,
			})
		} catch (serverError) {
			return dispatch({
				type: 'FINISH_FETCHING_NEXT_ACCOUNTING_DOCUMENT_TO_EXTRACT',
				serverError,
			})
		}
	}
}

export function getAccountingDocumentsToExtractCount() {
	return async (dispatch: Dispatch<AccountingDocumentReduxAction>) => {
		dispatch({
			type: 'START_GETTING_ACCOUNTING_DOCUMENTS_TO_EXTRACT_COUNT',
		})
		try {
			const result: AccountingDocumentExtractsCountResponse = await accountingDocumentsToExtractCountApi.get({})
			return dispatch({
				type: 'FINISH_GETTING_ACCOUNTING_DOCUMENTS_TO_EXTRACT_COUNT',
				count: result.toBeExtractedCount,
			})
		} catch (serverError) {
			return dispatch({
				type: 'FINISH_GETTING_ACCOUNTING_DOCUMENTS_TO_EXTRACT_COUNT',
				serverError,
			})
		}
	}
}

export function loadAccDocGreenboxSuggestion(accDocId: string) {
	return async (dispatch: Dispatch<AccountingDocumentReduxAction>, getState: GetState) => {
		dispatch({
			type: 'START_LOADING_GREENBOX_SUGGESTION',
			accDocId,
		})
		try {
			const ownRegNo: ?string = getCurrentOrganizationRegNo(getState())
			const accDoc: AccountingDocument = await getOrLoadAccountingDocument(getState(), accDocId)
			const requestBody: GreenboxSuggestionRequest = AccountingDocument_GreenboxSuggestionRequest(accDoc, ownRegNo)
			const greenboxSuggestion: GreenboxSuggestionResponse = await greenboxAccountingDocumentFinAccountsApi.post(
				{ accountingDocumentId: accDoc.id || '' },
				requestBody,
			)
			return dispatch({
				type: 'FINISH_LOADING_GREENBOX_SUGGESTION',
				greenboxSuggestion,
				accDocId,
			})
		} catch (serverError) {
			return dispatch({
				type: 'FINISH_LOADING_GREENBOX_SUGGESTION',
				greenboxSuggestion: null,
				accDocId,
			})
		}
	}
}

export function removeAccountingDocumentActivities(accountingDocumentId: string) {
	return {
		type: 'REMOVE_ACCOUNTING_DOCUMENT_ACTIVITIES',
		accountingDocumentId,
	}
}

export function fetchAccountingDocumentActivities(accountingDocumentId: string) {
	return async (dispatch: Dispatch<AccountingDocumentReduxAction>) => {
		dispatch({
			type: 'FETCH_ACCOUNTING_DOCUMENT_ACTIVITIES_START',
			accountingDocumentId,
		})
		try {
			const response = await accountingDocumentActivitiesApi.get({ accountingDocumentId })
			dispatch({
				type: 'FETCH_ACCOUNTING_DOCUMENT_ACTIVITIES_FINISH',
				accountingDocumentId,
				activities: response.activities,
			})
		} catch (serverError) {
			dispatch({
				type: 'FETCH_ACCOUNTING_DOCUMENT_ACTIVITIES_FINISH',
				accountingDocumentId,
				serverError,
			})
		}
	}
}

export function changeStateToProcessed(accountingDocumentId: string) {
	return async (dispatch: Dispatch<any>, getState: GetState) => {
		const newProcessingState: AccountingDocumentProcessingStateRequest = { processingState: 1 }
		const state = getState()
		const item: ?AccountingDocumentItem =
			state.accountingDocument.accountingDocuments.items[accountingDocumentId.toString()]
		const doc: ?AccountingDocument = item && item.data
		const oldProcessingState: ?number = doc && doc.processingState
		dispatch({
			type: 'START_FORCE_PROCESS',
			accountingDocumentId: accountingDocumentId,
			newProcessingState: newProcessingState.processingState,
		})
		try {
			const response = await accountingDocumentStateToProcessedApi.put({ accountingDocumentId }, newProcessingState)
			return dispatch({
				type: 'FINISH_FORCE_PROCESS',
				accountingDocumentId: accountingDocumentId,
				newProcessingState: response.processingState,
			})
		} catch (err) {
			return dispatch({
				type: 'FINISH_FORCE_PROCESS',
				accountingDocumentId,
				oldProcessingState,
				serverError: err,
			})
		}
	}
}

export function createCreditNote(accDoc: AccountingDocument) {
	return async (dispatch: Dispatch<any>): Promise<*> => {
		const accountingDocumentType = Name_AccountingDocumentType('CreditNote')

		const body = {
			type: accountingDocumentType,
			direction: accDoc.direction,
			currency: accDoc.currency,
			contact: accDoc.contact,
		}

		try {
			const action: { result: AccountingDocument, type: string } = await dispatch(createAccountingDocument(body))
			if (action.result && accDoc.id) {
				return dispatch(createNewCreditNoteWithConnection(accDoc, action.result))
			}
		} catch (err) {
			dispatch(showServerError(err))
		}
	}
}

export function createNewCreditNoteWithConnection(
	connectingDocument: AccountingDocument,
	document: AccountingDocument,
) {
	return async (dispatch: Dispatch<any>) => {
		// console.log({ accDocId, connectingDocument, currentContact, document, alreadyConnectedDocuments })

		const connection: AccountingDocumentConnection = getConnectionFromDocument(connectingDocument)
		const connectionResult: FinishConnectAccountingDocumentConnectionAction = await dispatch(
			connectAccountingDocumentConnection(document.id || '', connection),
		)

		if (connectionResult.serverError) {
			return
		}

		if (!connectingDocument.contact && connectingDocument.contact) {
			dispatch(updateAccountingDocumentContact(document.id || '', connectingDocument.contact))
		}

		await dispatch(replaceAccountingDocumentLineItems(connectingDocument, document, true))

		await dispatch(loadAccountingDocumentVatRecapInfo(document.id || ''))
		dispatch(
			push({
				pathname: editAccountingDocumentRoute(document.id || ''),
				state: {
					isNew: true,
				},
			}),
		)
	}
}

export function validateAccountingDocumentError(error: ValidationError, accountingDocumentId: string, state: string) {
	const { serverError, validationError } = getServerAndValidationErrors(error)
	return {
		type: 'FINISH_VALIDATE_ACCOUNTING_DOCUMENT',
		accountingDocumentId,
		state,
		serverError: serverError,
		validationErrorAccDoc: validationError,
	}
}

export function updateAccDocCashbotId(accDocId: string, cashbotId: number) {
	return async (dispatch: Dispatch<AccountingDocumentReduxAction>) => {
		try {
			// TODO-CASHBOT když už doklad ID obsahuje, mělo by se nahradit, ne přidat nové.
			// Reálně se taková situace asi stát nemůže.
			dispatch({
				type: 'START_UPDATE_ACCDOC_CASHBOT_ID',
				accDocId,
				cashbotId,
			})
			const params = { accountingDocumentId: accDocId }
			const body: AccountingDocumentIntegrationRequest = {
				type: 'cashbot',
				direction: 2,
				data: {
					cashbot: {
						cashbotId: cashbotId + '',
					},
				},
			}
			const newIntegration: AccountingDocumentIntegrationsResponse = await accDocintegrationsApi.post(params, body)
			return dispatch({
				type: 'FINISH_UPDATE_ACCDOC_CASHBOT_ID',
				accDocId,
				newIntegration,
			})
		} catch (serverError) {
			return dispatch({
				type: 'FINISH_UPDATE_ACCDOC_CASHBOT_ID',
				accDocId,
				serverError,
			})
		}
	}
}

export function resetExchangeRate(accountingDocumentId: string) {
	return async (dispatch: Dispatch<AccountingDocumentReduxAction | IssueAction>) => {
		if (accountingDocumentId) {
			try {
				const response = await exchRateResetRequestApi.post({
					accountingDocumentId,
				})

				return dispatch(updateAccountingDocumentExchRateDefault(accountingDocumentId, response.value)).then(() => {
					dispatch(loadAccountingDocumentVatRecapInfo(accountingDocumentId))
				})
			} catch (serverError) {
				dispatch({
					type: 'FINISH_RESET_EXCHANGE_RATE',
					serverError,
				})
			}
		}
	}
}
