// @flow

import type { FinancialAccount } from 'types'
import type { FavoriteOptionType } from 'components/favorite-select/favorite-option'

const getFlatOptions = (financialAccounts: Array<FinancialAccount>): Array<FinancialAccount> => {
	let accounts = []
	financialAccounts.forEach((account: FinancialAccount) => {
		accounts.push(account)
		if (account.subFinAccounts && account.subFinAccounts.length) {
			const subItems = getFlatOptions(account.subFinAccounts)
			accounts = accounts.concat(subItems)
		}
	})
	return accounts
}

export const isFinAccountNoAvailable = (financialAccounts: Array<FinancialAccount>, no: string): boolean => {
	return !!financialAccounts.find((account: FinancialAccount) => {
		if (account.no === no) return true
		if (account.subFinAccounts && account.subFinAccounts.length)
			return isFinAccountNoAvailable(account.subFinAccounts, no)
		return false
	})
}

const getFinAccountNo = (acc: FinancialAccount) =>
	acc.no || (acc.subFinAccounts && acc.subFinAccounts[0] && acc.subFinAccounts[0].no) || undefined

const sortByNo = (left: FinancialAccount, right: FinancialAccount): number => {
	const leftNo = getFinAccountNo(left)
	const rightNo = getFinAccountNo(right)
	return leftNo && rightNo ? parseInt(leftNo) - parseInt(rightNo) : 0
}

export const getHintedOptions = (
	financialAccounts: Array<FinancialAccount>,
	hinted: Array<string>,
	onFavorite: (value: ?string, isFavorite: boolean) => void,
): Array<FavoriteOptionType> => {
	const result = []
	const options = getFlatOptions(financialAccounts)
	hinted.forEach((no: string) => {
		const account = options.find((account: FinancialAccount) => account.no === no)
		account &&
			result.push({
				isHinted: true,
				value: account.no,
				labelPrefix: account.no,
				label: account.name,
				onFavorite: onFavorite,
				isCategory: !!(account.subFinAccounts && account.subFinAccounts.length),
			})
	})
	return result
}

export const getNormalOptions = (
	financialAccounts: Array<FinancialAccount>,
	favorites?: Array<string>,
	hinted: ?Array<string>,
	onFavorite?: (value: ?string, isFavorite: boolean) => void,
	level: number = 0,
): Array<FavoriteOptionType> => {
	let accounts = []
	const sorted = financialAccounts.concat().sort(sortByNo)
	sorted.forEach((account: FinancialAccount) => {
		const useCategoryName = account.subFinAccounts && account.subFinAccounts.length && account.category
		accounts.push({
			level,
			isHinted: hinted && hinted.includes(account.no),
			value: account.no || account.name || account.category,
			labelPrefix: useCategoryName ? null : account.no,
			label: useCategoryName ? account.category || account.name : account.name,
			isFavorite: favorites && favorites.includes(account.no),
			isCategory: !!(account.subFinAccounts && account.subFinAccounts.length),
			onFavorite: onFavorite,
			disabled: !!(account.subFinAccounts && account.subFinAccounts.length && account.category),
		})
		if (account.subFinAccounts && account.subFinAccounts.length) {
			const subItems = getNormalOptions(account.subFinAccounts, favorites, hinted, onFavorite, level + 1)
			accounts = accounts.concat(subItems)
		}
	})

	return accounts
}

export const getFinancialAccountsOptions = (
	financialAccounts: Array<FinancialAccount>,
	favorites: Array<string>,
	hinted: ?Array<string>,
	onFavorite: (value: ?string, isFavorite: boolean) => void,
): Array<FavoriteOptionType> => {
	return getNormalOptions(financialAccounts, favorites, hinted, onFavorite, 0)
}

export const getBasicFinancialAccountsOptions = (
	financialAccounts: Array<FinancialAccount>,
): Array<FavoriteOptionType> => {
	return getNormalOptions(financialAccounts, undefined, undefined, undefined, 0)
}

export const getFinancialAccountsHintedOptions = (
	financialAccounts: Array<FinancialAccount>,
	favorites: Array<string>,
	hinted: ?Array<string>,
	onFavorite: (value: ?string, isFavorite: boolean) => void,
): Array<FavoriteOptionType> => {
	return hinted ? getHintedOptions(financialAccounts, hinted, onFavorite) : []
}
