/* @flow */

import React from 'react'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import { FINANCIAL_ACCOUNTS_DATA_TYPE, FINANCIAL_ACCOUNTS_GRID_ID } from '../../constants'
import { FilterTextField } from 'modules/data-grid/components/data-grid-filter'
import BasicGridFilter from 'modules/data-grid/components/basic-grid-filter'
import styles from './financial-accounts-grid.css'

type Props = {|
	...WithTranslateProps,
|}

function FinancialAccountsAdvancedFilter(props: Props) {
	return (
		<BasicGridFilter
			name={FINANCIAL_ACCOUNTS_GRID_ID}
			gridId={FINANCIAL_ACCOUNTS_GRID_ID}
			dataType={FINANCIAL_ACCOUNTS_DATA_TYPE}
			noFilterLabel={props.t('settings.financialAccounts.allAccounts')}
			textFilterName="searchText"
		>
			<FilterTextField className={styles.column} name="no" labelText={props.t('settings.financialAccounts.no')} />
			<FilterTextField className={styles.column} name="name" labelText={props.t('settings.financialAccounts.name')} />
		</BasicGridFilter>
	)
}

export default withTranslate(FinancialAccountsAdvancedFilter)
