//@flow
import React from 'react'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import type { ISignalMessage, ISignalMessageParams, ISignalMessageGroup } from 'types'
import NotificationItem from './notification-item'
import styles from './notification-list.css'

type Props = {|
	notifications: Array<ISignalMessage>,
	markAllAsRead: () => void,
	markAsRead: (id: string) => void,
	handleNotificationClick: (params: ISignalMessageParams, group: ISignalMessageGroup, notificationId: string) => void,
	...WithTranslateProps,
|}

function NotificationList({ notifications, markAllAsRead, t, handleNotificationClick }: Props) {
	return (
		<div className={styles.wrapper}>
			<div className={styles.title}>
				<div>{t('notifications.badgeLabel')}</div>
				<div className={styles.badge}>{notifications.length}</div>
				{notifications.length > 0 && (
					<div className={styles.mark} onClick={markAllAsRead}>
						{t('notifications.markAllAsRead')}
					</div>
				)}
			</div>
			{notifications.map((i: ISignalMessage) => (
				<NotificationItem key={i.id} notification={i} onNotificationClick={handleNotificationClick} />
			))}
		</div>
	)
}

export default withTranslate(NotificationList)
