/* @flow */

import React, { Component } from 'react'
import { withNotify, withTranslate, type WithTranslateProps, type WithNotifyProps } from 'wrappers'
import {
	AccountingTypeSelector,
	Button,
	Paper,
	PayerTaxTypeSelector,
	TextField,
	Tooltip,
	TriviLink,
	CountrySelector,
} from 'components'
import type { AresContact, AresContactAddress, Country, OrganizationCreateRequest } from 'types'
import { dashboardRoute, emptyDashboardRoute } from 'modules/dashboard/routing/routes'
import type { FinishLoadingAresContacts } from 'modules/user/actions/action-types'
import InfoIcon from 'components/svg-icons/action/info'
import TriviAddress from 'components/svg-icons/trivi/address'
import TriviLogo from 'modules/common/components/trivi-logo'
import styles from './organization-create.css'
import { colors } from 'variables'

type IntDataType = 'accountingType' | 'vatPayerType'

type StrDataType =
	| 'name'
	| 'companyRegNo'
	| 'taxId'
	| 'invoicingAddressStreet'
	| 'invoicingAddressCity'
	| 'invoicingAddressZip'
	| 'invoicingAddressCountry'

type Props = {|
	...WithTranslateProps,
	...WithNotifyProps,
	createOrganization: (data: OrganizationCreateRequest) => Promise<any>,
	loadAresContacts: (companyRegNo: string) => Promise<FinishLoadingAresContacts>,
	currentOrganizationId: ?string,
|}

type State = {|
	index: number,
	data: OrganizationCreateRequest,
|}

class OrganizationCreate extends Component<Props, State> {
	state: State = {
		index: 0,
		data: {
			name: '',
			companyRegNo: '',
			taxId: '',
			invoicingAddressStreet: '',
			invoicingAddressCity: '',
			invoicingAddressZip: '',
			invoicingAddressCountry: '',
			accountingType: 0,
			vatPayerType: 0,
		},
	}

	onStrInputChange = (field: StrDataType) => (event: SyntheticInputEvent<HTMLInputElement>, value: ?string) => {
		if (value !== null && value !== undefined) {
			this.setState({
				data: Object.freeze({
					...this.state.data,
					[field]: value,
				}),
			})
		}
	}

	onIntInputChange = (field: IntDataType) => (value: number) => {
		this.setState({
			data: Object.freeze({
				...this.state.data,
				[field]: value,
			}),
		})
	}

	handleCountryChange = (country: Country) => {
		this.setState({
			data: Object.freeze({
				...this.state.data,
				invoicingAddressCountry: country.id,
			}),
		})
	}

	onDoneClick = () => {
		this.props.createOrganization(this.state.data)
	}

	loadAresContact = async () => {
		const resp: FinishLoadingAresContacts = await this.props.loadAresContacts(this.state.data.companyRegNo)
		const { aresContacts } = resp
		if (aresContacts && aresContacts.length > 0) {
			const c: AresContact = aresContacts[0]
			const address: ?AresContactAddress = c.address
			this.setState({
				data: Object.freeze({
					...this.state.data,
					name: c.companyName || this.state.data.name || '',
					companyRegNo: c.companyRegNo || this.state.data.companyRegNo || '',
					taxId: c.taxId || this.state.data.taxId || '',
					invoicingAddressStreet: (address && address.street) || this.state.data.invoicingAddressStreet || '',
					invoicingAddressCity: (address && address.city) || this.state.data.invoicingAddressCity || '',
					invoicingAddressZip: (address && address.zipCode) || this.state.data.invoicingAddressZip || '',
					invoicingAddressCountry: (address && address.country) || this.state.data.invoicingAddressCountry || '',
				}),
			})
		}
	}

	goToOrganizationInfoView = () => {
		this.setState({ index: 0 })
	}

	goToAccountingInfoView = () => {
		const { t } = this.props
		const {
			name,
			companyRegNo,
			invoicingAddressCity,
			invoicingAddressCountry,
			invoicingAddressStreet,
			invoicingAddressZip,
		} = this.state.data

		if (
			name &&
			companyRegNo &&
			invoicingAddressCity &&
			invoicingAddressCountry &&
			invoicingAddressStreet &&
			invoicingAddressZip
		) {
			return this.setState({ index: 1 })
		}

		this.props.notify(t('clientError.fillAll'), 'error')
	}

	renderRightSide() {
		return (
			<div className={styles.right}>
				<TriviLogo />
				<div className={styles.scenery} />
				<div className={styles[`stepper${this.state.index}`]}>
					<div className={styles.stepperImage} />
					{this.state.index === 0
						? this.props.t('dashboard.newOrganization.organizationInfoStepper')
						: this.props.t('dashboard.newOrganization.accountingInfoStepper')}
				</div>
			</div>
		)
	}

	renderOrganizationInfo() {
		const { t } = this.props
		const {
			name,
			companyRegNo,
			taxId,
			invoicingAddressCity,
			invoicingAddressCountry,
			invoicingAddressStreet,
			invoicingAddressZip,
		} = this.state.data

		return (
			<Paper zDepth={6} rounded className={styles.container} key="organizationInfo">
				<div className={styles.left}>
					<div className={styles.h1}>{t('dashboard.newOrganization.organizationInfoTitle')}</div>
					<div className={styles.h2}>{t('dashboard.newOrganization.organizationInfoSubTitle')}</div>

					<div className={styles.icDic}>
						<TextField
							inline
							autoWidth
							value={companyRegNo}
							onChange={this.onStrInputChange('companyRegNo')}
							labelText={t('invoice.contact.companyRegNoLabel')}
							hintText={t('dashboard.newOrganization.companyRegNoHint')}
							autoTestId="create-organization-companyno"
							name="company-no"
						/>
						<Tooltip style={style.tooltip} label={t('dashboard.newOrganization.companyRegNoInfo')} inline>
							<InfoIcon className={styles.infoIcon} color={colors.blue} hoverColor={colors.blue} />
						</Tooltip>
						<TextField
							inline
							autoWidth
							value={taxId}
							onChange={this.onStrInputChange('taxId')}
							labelText={t('invoice.contact.taxIdLabel')}
							hintText={t('dashboard.newOrganization.companyTaxIdHint')}
							autoTestId="create-organization-taxid"
							name="taxid"
						/>
					</div>
					{companyRegNo && (
						<div>
							<Button
								tertiary
								labelText={t('dashboard.newOrganization.loadAresButtonLabel')}
								onClick={this.loadAresContact}
								autoTestId="organization-create-load-ares"
							/>
						</div>
					)}

					<div className={styles.address}>
						<TriviAddress className={styles.addressIcon} hoverColor={'#666666'} />
						<TextField
							inline
							autoWidth
							value={name}
							onChange={this.onStrInputChange('name')}
							hintText={t('invoice.contact.companyNameHint')}
							autoTestId="create-organization-companyname"
							name="company"
						/>
						<br />
						<TextField
							inline
							autoWidth
							value={invoicingAddressStreet}
							onChange={this.onStrInputChange('invoicingAddressStreet')}
							hintText={t('invoice.contact.streetHint')}
							autoTestId="create-organization-street"
							name="street"
						/>
						<br />
						<TextField
							inline
							autoWidth
							value={invoicingAddressZip}
							onChange={this.onStrInputChange('invoicingAddressZip')}
							hintText={t('invoice.contact.zipCodeHint')}
							autoTestId="create-organization-zip"
							name="zip"
						/>
						{'  '}
						<TextField
							inline
							autoWidth
							value={invoicingAddressCity}
							onChange={this.onStrInputChange('invoicingAddressCity')}
							hintText={t('invoice.contact.cityHint')}
							autoTestId="create-organization-city"
							name="city"
						/>
						<br />
						<CountrySelector
							value={invoicingAddressCountry}
							placeholder={t('invoice.contact.countryHint')}
							autoTestId="create-organization-country"
							onChange={this.handleCountryChange}
							inline
						/>
					</div>
					<Button
						tall
						wide
						labelText={t('dashboard.newOrganization.organizationInfoButtonLabel')}
						onClick={this.goToAccountingInfoView}
						autoTestId="organization-create-info"
					/>
				</div>
				{this.renderRightSide()}
			</Paper>
		)
	}

	renderAccountingInfo() {
		const { t } = this.props
		const { accountingType, vatPayerType } = this.state.data
		const labelStyle = {
			fontSize: 24,
			textTransform: 'none',
			lineHeight: '40px',
		}
		return (
			<Paper zDepth={6} rounded className={styles.container} key="accountingInfo">
				<div className={styles.left}>
					<div className={styles.h1}>{t('dashboard.newOrganization.accountingInfoTitle')}</div>
					<div className={styles.h2}>{t('dashboard.newOrganization.accountingInfoSubTitle')}</div>

					<div className={styles.selectors}>
						<div className={styles.selector}>
							<AccountingTypeSelector
								labelStyle={labelStyle}
								value={accountingType}
								onChange={this.onIntInputChange('accountingType')}
							/>
						</div>
						<div className={styles.selector}>
							<PayerTaxTypeSelector
								labelStyle={labelStyle}
								value={vatPayerType}
								onChange={this.onIntInputChange('vatPayerType')}
								hideVatPeriod
							/>
						</div>
					</div>
					<Button
						tall
						wide
						labelText={t('dashboard.newOrganization.accountingInfoButtonLabel')}
						onClick={this.onDoneClick}
						autoTestId="organization-create-done"
					/>
				</div>
				{this.renderRightSide()}
			</Paper>
		)
	}

	render() {
		const { t, currentOrganizationId } = this.props
		const content = this.state.index === 0 ? this.renderOrganizationInfo() : this.renderAccountingInfo()

		return (
			<div>
				<div className={styles.root}>{content}</div>
				<div className={styles.close}>
					{this.state.index === 0 ? (
						<TriviLink
							hoverStyle={style.hoverLink}
							routeFn={currentOrganizationId ? dashboardRoute : emptyDashboardRoute}
						>
							{t('application.pageNotFound.backToHomeButton')}
						</TriviLink>
					) : (
						<TriviLink hoverStyle={style.hoverLink} onClick={this.goToOrganizationInfoView}>
							{t('dashboard.newOrganization.back')}
						</TriviLink>
					)}
				</div>
			</div>
		)
	}
}

const style = {
	tooltip: { margin: '0 7px 0 35px' },
	hoverLink: {
		color: colors.black,
	},
}

export default withTranslate(withNotify(OrganizationCreate))
