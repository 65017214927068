/* @flow */

import type { DashboardAction, Dispatch, State } from 'types'
import {
	organizationCards as organizationCardsApi,
	organizationCardCompletion as organizationCardCompletionApi,
} from 'modules/common/models/api-model'
import { getOrganizationCardsLoadedToDate, getOrganizationCardsLoadedSinceDate } from '../selectors/cards'
import { getTodayIsoDateString, substractOneDayFromDate, addOneDayToDate } from 'utils/formatters'
import { getAppLanguage } from 'locales'

export function loadNewOrganizationCards(numberOfDatesToFuture: number) {
	return async (dispatch: Dispatch<DashboardAction>, getState: () => State) => {
		const startDate = addOneDayToDate(getOrganizationCardsLoadedToDate(getState())).toISOString()
		return dispatch(loadOrganizationCards(startDate, numberOfDatesToFuture, 0))
	}
}

export function loadOldOrganizationCards(numberOfDatesToPast: number) {
	return async (dispatch: Dispatch<DashboardAction>, getState: () => State) => {
		const startDate = substractOneDayFromDate(getOrganizationCardsLoadedSinceDate(getState())).toISOString()
		return dispatch(loadOrganizationCards(startDate, 0, numberOfDatesToPast))
	}
}

export function reloadCurrentOrganizationCards() {
	return async (dispatch: Dispatch<DashboardAction>) => {
		return dispatch(loadOrganizationCards(getTodayIsoDateString(), 0, 0, true))
	}
}

export function initialLoadOrganizationCards() {
	return async (dispatch: Dispatch<DashboardAction>) => {
		dispatch({
			type: 'START_LOAD_ORGANIZATION_CARDS',
		})

		const startDate = getTodayIsoDateString()
		const numberOfDatesToFuture = 7
		const numberOfDatesToPast = 7
		const includeCurrent = true

		try {
			const language = getAppLanguage().toLowerCase()
			const response = await organizationCardsApi.get({
				startDate,
				numberOfDatesToPast,
				numberOfDatesToFuture,
				includeCurrent,
				language,
			})

			dispatch({
				type: 'DELETE_CURRENT_ORGANIZATION_CARDS',
			})

			return dispatch({
				type: 'FINISH_LOAD_ORGANIZATION_CARDS',
				cards: response.cards,
				numberOfDatesToFuture,
				numberOfDatesToPast,
				startDate,
			})
		} catch (error) {
			dispatch({
				type: 'FINISH_LOAD_ORGANIZATION_CARDS',
				serverError: error,
			})
		}
	}
}

export function loadOrganizationCards(
	startDate: string,
	numberOfDatesToFuture: number,
	numberOfDatesToPast: number,
	includeCurrent: boolean = false,
) {
	return async (dispatch: Dispatch<DashboardAction>) => {
		dispatch({
			type: 'START_LOAD_ORGANIZATION_CARDS',
		})

		try {
			const language = getAppLanguage().toLowerCase()
			const response = await organizationCardsApi.get({
				startDate,
				numberOfDatesToPast,
				numberOfDatesToFuture,
				includeCurrent,
				language,
			})

			return dispatch({
				type: 'FINISH_LOAD_ORGANIZATION_CARDS',
				cards: response.cards,
				numberOfDatesToFuture,
				numberOfDatesToPast,
				startDate,
			})
		} catch (error) {
			dispatch({
				type: 'FINISH_LOAD_ORGANIZATION_CARDS',
				serverError: error,
			})
		}
	}
}

export function completeOrganizationCard(cardId: string, isCompleted: boolean) {
	return async (dispatch: Dispatch<DashboardAction>, getState: () => State) => {
		const state: State = getState()
		const oldCompletion = state.dashboard.cards.data ? state.dashboard.cards.data[cardId].completion || {} : {}

		dispatch({
			type: 'START_COMPLETE_ORGANIZATION_CARD',
			isCompleted,
			cardId,
		})
		try {
			const response = await organizationCardCompletionApi.put(
				{ cardId },
				{
					isCompleted,
				},
			)

			!response.isCompleted && dispatch(reloadCurrentOrganizationCards())

			return dispatch({
				type: 'FINISH_COMPLETE_ORGANIZATION_CARD',
				completion: response,
				cardId,
			})
		} catch (error) {
			dispatch({
				type: 'FINISH_COMPLETE_ORGANIZATION_CARD',
				serverError: error,
				completion: oldCompletion,
				cardId,
			})
		}
	}
}
