/* @flow */

import React, { Component } from 'react'
import type { Signature, Signatures } from 'types'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import SignaturePickerItem from './signature-picker-item'
import ContentClear from 'components/svg-icons/content/clear'
import SignatureViewer from './signature-viewer'
import SelectField from 'components/select-field'
import IconButton from 'components/icon-button'
import styles from './signature-picker.css'

type Props = {|
	...WithTranslateProps,
	value: ?string,
	readonly?: boolean,
	signatures: ?Signatures,
	accountingDocumentId?: string,
	onChange: (signature: ?Signature) => void,
|}

class SignaturePicker extends Component<Props> {
	onRemoveClick = () => {
		this.props.onChange && this.props.onChange(null)
	}

	renderSignature(fileId: ?string) {
		return (
			fileId && (
				<span>
					<SignatureViewer fileId={fileId} key={fileId} />
				</span>
			)
		)
	}

	render() {
		const { signatures, value, readonly, t } = this.props

		if (!signatures || signatures.length === 0) return null

		let val: ?Signature = null
		val = value ? signatures.find((signature: Signature) => signature.id == value) : null

		if (readonly) {
			return val ? <div className={styles.root}>{this.renderSignature(val.fileId)}</div> : null
		}

		return (
			<div className={styles.root}>
				{!val && (
					<SelectField
						hintText={t('settings.signatures.invoice-select')}
						autoTestId="settings-signatures-select"
						inline
					>
						{signatures.map((signature: Signature) => (
							<SignaturePickerItem key={signature.fileId} signature={signature} onClick={this.props.onChange} />
						))}
					</SelectField>
				)}
				{val && this.renderSignature(val.fileId)}
				{val && (
					<div className={styles.iconButton}>
						<IconButton
							autoTestId="signature-picker-remove"
							tooltip={t('settings.signatures.invoice-remove')}
							onClick={this.onRemoveClick}
							size={28}
							circled
						>
							<ContentClear size={20} />
						</IconButton>
					</div>
				)}
			</div>
		)
	}
}

export default withTranslate(SignaturePicker)
