//@flow
import React, { Component } from 'react'
import FeaturesList from '../containers/features-list'
import styles from './features.css'
import Helmet from 'react-helmet'

export default class FeaturesPage extends Component<{}> {
	render() {
		return (
			<div className={styles.container}>
				<Helmet>
					<title>Features</title>
				</Helmet>
				<h1>Features</h1>
				<FeaturesList />
			</div>
		)
	}
}
