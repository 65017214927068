// @flow

import type { DataObjects } from 'components/charts/highcharts/types'
import { pickDefaultOptions } from 'components/charts/utils'
import React from 'react'
import { pure } from 'recompose'
import type { BaseChartProps } from './highcharts/highchart'
import Highchart from './highcharts/highchart'
import type { Options } from './highcharts/types'

export type Value = {|
	value: number,
	color?: string,
	label: string,
	negative?: boolean,
	click?: () => any,
|}

export type Props = {|
	values: ?Array<Value>,
	innerSize?: string | number,
	startAngle?: number,
	dataLabels?: boolean,
	tooltipFormat?: string,
	...BaseChartProps,
|}

function PieChart(props: Props) {
	return <Highchart options={convertOptions(props)} {...pickDefaultOptions(props)} />
}

export default pure(PieChart)

export function convertOptions(props: Props): Options {
	return {
		chart: { type: 'pie' },
		plotOptions: {
			series: {
				cursor: 'pointer',
				dataLabels: { enabled: false !== props.dataLabels },
			},
		},
		tooltip: {
			headerFormat: '',
			pointFormat: props.tooltipFormat || '{point.name}: <b>{point.custom.extraInformation} {point.y}</b>',
		},
		series: props.values
			? [
					{
						data: convertData(props.values),
						innerSize: props.innerSize,
						startAngle: props.startAngle,
						id: 'series1',
					},
			  ]
			: undefined,
	}
}

export function convertData(data: Array<Value>): DataObjects {
	return data.map((value: Value) => ({
		y: value.value,
		name: value.label,
		color: value.color,
		events: { click: value.click },
		custom: {
			extraInformation: value.negative ? '-' : '',
		},
	}))
}
