/* @flow */

import React, { Component } from 'react'
import { type FormikBag, type FormikProps, withFormik } from 'formik'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import type { Contact, Country } from 'types'
import ContactAres from 'modules/accounting-document/containers/invoice-elements/contact/contact-ares'
import CountrySelector from 'modules/common/containers/country-selector'
import EmailInput from 'components/email-input'
import TextField from 'components/TextField'
import Divider from 'components/divider'
import Paper from 'components/Paper'
import Button from 'components/button'
import colors from 'variables/colors.css'
import styles from './contact-form.css'
import { Validator } from 'utils'

export type Props = {|
	contact: ?Contact,
	editType: EditType,
	aresLoading?: boolean,
	inline?: boolean,
	searchCompany?: ?string,
	onCancel: () => void,
	onSave: (contact: ?Contact) => Promise<void>,
|}

type ComponentProps = {|
	...Props,
	...WithTranslateProps,
	...FormikProps<FormValues>,
|}

export type EditType = 'UPDATE' | 'CREATE'

class ContactForm extends Component<ComponentProps> {
	renderForm() {
		const { t, values, errors } = this.props
		const isNew = 'CREATE' === this.props.editType

		return (
			<div>
				{isNew && (
					<div>
						<div className={styles.mainContainer}>
							<ContactAres onSelect={this.setContact} search={this.props.searchCompany} />
						</div>
						<Divider />
					</div>
				)}
				<div className={styles.othersContainer}>
					<div>
						<div className={styles.row}>
							<div className={styles.halfColumn}>
								<TextField
									labelText={t('invoice.contact.companyNameHint')}
									value={values.companyName}
									clientError={t(errors.companyName)}
									onChange={this.onChange('companyName')}
									disabled={this.isFormDisabled()}
									fullWidth
									name="company-name"
									autoTestId="contact-name-input"
								/>
							</div>
							<div className={styles.halfColumn} />
							<div className={styles.halfColumn}>
								<TextField
									labelText={t('invoice.contact.companyRegNoLabel')}
									value={values.companyRegNo}
									clientError={t(errors.companyRegNo)}
									onChange={this.onChange('companyRegNo')}
									disabled={this.isFormDisabled()}
									fullWidth
									name="company-no"
									autoTestId="contact-companyno-input"
								/>
							</div>
							<div className={styles.halfColumn}>
								<TextField
									labelText={t('invoice.contact.taxIdLabel')}
									value={values.taxId}
									clientError={t(errors.taxId)}
									onChange={this.onChange('taxId')}
									disabled={this.isFormDisabled()}
									fullWidth
									name="taxid"
									autoTestId="contact-taxid-input"
								/>
							</div>
							<div className={styles.halfColumn}>
								<TextField
									labelText={t('invoice.contact.firstnameHint')}
									value={values.firstName}
									clientError={t(errors.firstName)}
									onChange={this.onChange('firstName')}
									disabled={this.isFormDisabled()}
									fullWidth
									name="firstname"
									autoTestId="contact-firstname-input"
								/>
							</div>
							<div className={styles.halfColumn}>
								<TextField
									labelText={t('invoice.contact.lastnameHint')}
									value={values.lastName}
									clientError={t(errors.lastName)}
									onChange={this.onChange('lastName')}
									disabled={this.isFormDisabled()}
									fullWidth
									name="lastname"
									autoTestId="contact-lastname-input"
								/>
							</div>
							<div className={styles.halfColumn}>
								<EmailInput
									labelText={t('invoice.contact.emailHint')}
									value={values.email}
									clientError={t(errors.email)}
									onChange={this.onChange('email')}
									disabled={this.isFormDisabled()}
									fullWidth
									autoTestId="contact-email-input"
								/>
							</div>
							<div className={styles.halfColumn} />
							<div className={styles.halfColumn}>
								<TextField
									labelText={t('invoice.contact.streetHint')}
									value={values.street}
									clientError={t(errors.street)}
									onChange={this.onChange('street')}
									disabled={this.isFormDisabled()}
									fullWidth
									name="street"
									autoTestId="contact-street-input"
								/>
							</div>
							<div className={styles.halfColumn} />
							<div className={styles.halfColumn}>
								<div className={styles.zip}>
									<TextField
										labelText={t('invoice.contact.zipCodeHint')}
										value={values.zipCode}
										clientError={t(errors.zipCode)}
										onChange={this.onChange('zipCode')}
										disabled={this.isFormDisabled()}
										name="zip"
										autoTestId="contact-zip-input"
									/>
								</div>
								<div className={styles.city}>
									<TextField
										labelText={t('invoice.contact.cityHint')}
										value={values.city}
										clientError={t(errors.city)}
										onChange={this.onChange('city')}
										disabled={this.isFormDisabled()}
										fullWidth
										name="city"
										autoTestId="contact-city-input"
									/>
								</div>
							</div>
							<div className={styles.halfColumn}>
								<CountrySelector
									label={t('invoice.contact.countryHint')}
									value={values.country}
									error={t(errors.country)}
									disabled={this.isFormDisabled()}
									onChange={this.changeCountry}
									autoTestId="contact-country-input"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}

	render() {
		const { t, submitForm, isValid } = this.props
		const isNew = 'CREATE' === this.props.editType
		const form = this.renderForm()

		return (
			<div className={styles.container} style={{ background: this.props.inline ? colors.white : 0 }}>
				<div className={styles.header}>
					<div className={styles.row}>
						<div className={styles.headings}>
							<h1 className={styles.h1} style={{ paddingLeft: this.props.inline ? 30 : 0 }}>
								{isNew ? t('invoice.contact.addContactHeadline') : t('invoice.contact.editContactHeadline')}
							</h1>
						</div>
						<div className={styles.actions} style={{ paddingRight: this.props.inline ? 120 : 0 }}>
							<Button
								disabled={!isValid || this.isFormDisabled()}
								labelText={this.props.t('application.saveAndContinue')}
								onClick={submitForm}
								autoTestId="contact-form-save"
							/>
						</div>
					</div>
				</div>

				{this.props.inline ? (
					<div className={styles.paper}>{form}</div>
				) : (
					<Paper rounded zDepth={3} className={styles.paper}>
						{form}
					</Paper>
				)}
			</div>
		)
	}

	onChange = (field: $Keys<FormValues>) => {
		return (e: SyntheticInputEvent<HTMLInputElement>, value: ?string) => {
			this.props.setFieldValue(field, value || '')
		}
	}

	changeCountry = (country: Country) => {
		this.props.setFieldValue('country', country.id)
	}

	onCancel = () => {
		this.props.onCancel()
	}

	setContact = (contact: Contact) => {
		this.props.setValues(contactToForm(contact))
	}

	isFormDisabled() {
		return !this.props.contact || this.props.aresLoading || this.props.isSubmitting
	}
}

const formikConfig = {
	handleSubmit: (values: FormValues, formikBag: FormikBag<ComponentProps, FormValues>) => {
		formikBag.props.onSave(formToContact(values, formikBag.props.contact)).finally(() => {
			// formikBag.setSubmitting(false) - TODO: dořešit po aktualizaci formiku, který podporuje async submit handler
		})
	},
	validate: (values: FormValues) => {
		let errors: Object = {}
		if (!values.companyName && !values.firstName && !values.lastName) {
			errors.companyName = errors.firstName = errors.lastName = 'clientError.fillContact'
		}

		if (!values.country) {
			errors.country = 'clientError.fillCountry'
		}

		if (values.country == 'CZ' && values.companyName && !values.companyRegNo) {
			errors.companyRegNo = 'clientError.fillRegNo'
		}

		if (values.email && !Validator.email(values.email)) {
			errors.email = 'clientError.notAnEmail'
		}

		return errors
	},
	mapPropsToValues: (props: ComponentProps) => contactToForm(props.contact),
	enableReinitialize: true,
	// validationSchema: getAccountingDocumentContactSchema(),
}

export default withTranslate(withFormik(formikConfig)(ContactForm))

type FormValues = {|
	companyRegNo?: string,
	companyName?: string,
	firstName?: string,
	lastName?: string,
	email?: string,
	taxId?: string,
	street?: string,
	city?: string,
	zipCode?: string,
	country?: string,
|}

function contactToForm(contact: ?Contact): FormValues {
	const { companyRegNo, companyName, firstName, lastName, taxId } = contact || {}
	const { city, street, zipCode, country, email } = (contact && contact.addresses && contact.addresses[0]) || {}

	return {
		companyRegNo,
		companyName,
		firstName,
		lastName,
		email,
		taxId,
		city,
		street,
		zipCode,
		country,
	}
}

function formToContact(values: FormValues, contact: ?Contact): Contact {
	const { companyRegNo, companyName, firstName, lastName, taxId, ...address } = values
	const addresses = [...((contact && contact.addresses) || [])]
	addresses[0] = { ...((contact && contact.addresses && contact.addresses[0]) || { type: 1 }), ...address }

	return {
		...contact,
		companyRegNo,
		companyName,
		firstName,
		lastName,
		taxId,
		addresses,
	}
}
