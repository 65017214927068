// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[3].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js!../../../variables/colors.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".fgefxm4Hy-LN8WlCGr-l5 {\n\tfont-size: 12px;\n\tcolor: " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["blackFaded60"] + ";\n}\n\n._1CApXOpfXNGwhkk1OO5kaV {\n\ttext-align: right;\n\tpadding-right: 20px;\n\twhite-space: nowrap;\n\ttext-overflow: ellipsis;\n\toverflow: hidden;\n}\n", "",{"version":3,"sources":["webpack://./modules/address-book/components/contact-grid.css"],"names":[],"mappings":"AAEA;CACC,eAAA;CACA,mDAAA;AASD;;AANA;CACC,iBAAA;CACA,mBAAA;CACA,mBAAA;CACA,uBAAA;CACA,gBAAA;AASD","sourcesContent":["@value blue,blackFaded60 from \"variables/colors.css\";\n\n.name {\n\tfont-size: 12px;\n\tcolor: blackFaded60;\n}\n\n.icDicColumn {\n\ttext-align: right;\n\tpadding-right: 20px;\n\twhite-space: nowrap;\n\ttext-overflow: ellipsis;\n\toverflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"blue": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["blue"] + "",
	"blackFaded60": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["blackFaded60"] + "",
	"name": "fgefxm4Hy-LN8WlCGr-l5",
	"icDicColumn": "_1CApXOpfXNGwhkk1OO5kaV"
};
export default ___CSS_LOADER_EXPORT___;
