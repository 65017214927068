// @flow
/** @jsx jsx */

import { jsx } from '@emotion/core'
import { confirmable } from 'react-confirm'
import { I18nextProvider } from 'react-i18next'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import ConfirmButton from 'components/confirm-button'
import Dialog from 'components/dialog'
import Button from 'components/button'
import { i18n } from 'locales'

type Props = {|
	show: boolean,
	title?: string,
	okLabel?: string,
	confirmation: any,
	cancelLabel?: string,
	body?: React$Element<any>,
	waitForConfirm?: boolean,
	confirmButtonTooltip?: string,
	confirmButtonTooltipFinished?: string,
	proceed: () => void,
	dismiss: () => void,
	cancel: () => void,
|}

function ConfirmDialog(props: { ...Props, ...WithTranslateProps }) {
	const ok = props.okLabel || props.t('application.ok')

	const cancelButton = (
		<Button
			key="cancel"
			autoTestId="confirm-dialog-button-cancel"
			labelText={props.cancelLabel || props.t('application.cancel')}
			onClick={props.cancel}
			secondary
			wide
		/>
	)
	const confirmButton = props.waitForConfirm ? (
		<ConfirmButton
			autoTestId="confirm-dialog-button-confirm"
			tooltip={props.confirmButtonTooltip || props.t('dialogs.confirmButtonTooltip')}
			tooltipFinished={props.confirmButtonTooltipFinished || props.t('dialogs.confirmButtonTooltipFinished')}
			onConfirm={props.proceed}
			labelText={ok}
			key="confirm"
			wide
		/>
	) : (
		<Button autoTestId="confirm-dialog-button-ok" key="confirm" labelText={ok} onClick={props.proceed} primary wide />
	)

	return (
		<Dialog open={props.show} title={props.title} actions={[confirmButton, cancelButton]} autoTestId="confirm-dialog">
			<div css={styles.inner}>{props.body}</div>
		</Dialog>
	)
}

const TranslatedConfirmDialog = withTranslate(ConfirmDialog)

// eslint-disable-next-line react/no-multi-comp
function ConfirmDialogWrapper(props: Props) {
	return (
		<I18nextProvider i18n={i18n}>
			<TranslatedConfirmDialog {...props} />
		</I18nextProvider>
	)
}

const styles = {
	inner: {
		fontSize: 16,
		textAlign: 'center',
		fontWeight: 'normal',
	},
}

export default confirmable(ConfirmDialogWrapper)
