import React from 'react'
import SvgIcon from 'components/SvgIcon'
import pure from 'recompose/pure'

let LargeImage = props => {
	props = {
		viewBox: '0 0 24 30',
		...props,
	}
	return (
		<SvgIcon {...props}>
			<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g transform="translate(-673.000000, -10404.000000)">
					<g transform="translate(673.000000, 10404.000000)">
						<path
							d="M2.985,0 C1.335,0 0,1.35 0,3 L0.015,27 C0.015,28.65 1.35,30 3,30 L15,30 L24,21 L24,3 C24,1.35 22.65,0 21,0 L2.985,0 Z"
							fill="#34424E"
							transform="translate(12.000000, 15.000000) scale(1, -1) translate(-12.000000, -15.000000) "
						/>
						<path
							d="M19.2,15 C18.5372592,15 18,15.537258 18,16.2 C18,16.862742 18.5372592,17.4 19.2,17.4 C19.862742,17.4 20.4,16.862742 20.4,16.2 C20.4,15.537258 19.862742,15 19.2,15 L19.2,15 Z"
							fill="#FFFFFF"
						/>
						<polygon
							fill="#FFFFFF"
							points="10.1625 16.5 9.3 17.325 4.5 22.125 6.225 23.85 10.1625 19.9125 12.9 22.65 13.7625 23.475 14.625 22.65 16.1625 21.1125 18.9 23.85 20.625 22.125 17.025 18.525 16.1625 17.7 15.3 18.525 13.7625 20.0625 11.025 17.325"
						/>
						<polygon
							fill="#FFA409"
							transform="translate(19.500000, 4.500000) rotate(-90.000000) translate(-19.500000, -4.500000) "
							points="15 9 15 0 24 0"
						/>
					</g>
				</g>
			</g>
		</SvgIcon>
	)
}
LargeImage = pure(LargeImage)

export default LargeImage
