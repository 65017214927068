/* @flow */

import { connect } from 'react-redux'
import { Actions } from 'react-redux-grid'
import type { DataGridReduxAction, Dispatch, Filter, SearchRequest, SearchResult, State } from 'types'
import DataGrid, { type Props as DataGridProps } from '../components/data-grid'
import type { Action as DataGridAction } from '../types'

type Props<Item> = {
	columns: Array<*>,
	apiFunction?: SearchRequest => Promise<SearchResult>, //?
	dataFieldName: string,
	stateKey: string,
	pageSize?: number,
	filter?: Filter,
	actions?: ?Array<DataGridAction<Item>>,
	multiActions?: ?Array<DataGridAction<Array<Item>>>,
	onAction?: ?(string, Item) => Promise<void>,
	onMultiAction?: ?(string, Array<Item>) => void,
	onActionMenuOpen?: (Item, () => void) => ?Promise<void>,
	onNeedUpdateMultiActionVisiblity?: (Array<Item>) => Promise<Array<string>>,
	sort?: {
		field?: string,
		direction?: string,
	},
	additionalData?: ?{},
	light?: ?boolean,
	border?: ?boolean,
	showPageSize?: boolean,
	showItemsOfTotal?: boolean,
	showPrevButton?: boolean,
	showNextButton?: boolean,
	compactPager?: boolean,
	compact?: ?boolean,
	cancelSelection?: ?{ ['SORT' | 'FILTER' | 'PAGER']: boolean },
	getRefresh?: ?(Function) => void,
	selectable?: boolean,
}

type StateProps = {|
	filter: Filter,
	currentPage: number,
	sort: {
		field?: string,
		direction?: string,
	},
	gridData: ?Array<*>,
|}

function mapStateToProps<Item>(state: State, ownProps: Props<Item>): StateProps {
	const grid: ?{ columns: Array<*> } = state.grid.grid.get(ownProps.stateKey)
	const columns: Array<*> = (grid && grid.columns) || []
	const sortColumn: ?{
		dataIndex: string,
		sortDirection: string,
	} =
		columns.find((column: { sortDirection: string }) => !!column.sortDirection) ||
		ownProps.columns.find((column: { sortDirection: string }) => !!column.sortDirection)
	const filter = ownProps.filter || []
	const stateFilter = state.grid.filter[ownProps.stateKey] || []
	const pager: ?{ pageIndex: number } = state.grid.pager.get(ownProps.stateKey)
	const dataSource = state.grid.dataSource.get(ownProps.stateKey)

	const sort = sortColumn ? { field: sortColumn.dataIndex, direction: sortColumn.sortDirection } : ownProps.sort || {}

	return {
		filter: [...filter, ...stateFilter],
		currentPage: (pager && pager.pageIndex) || 0,
		sort,
		gridData: dataSource && dataSource.get('data'),
	}
}

type DispatchProps = {|
	onFilterChange: ({
		pageIndex: number,
		dataSource: Function,
		stateKey: string,
	}) => void,
	onPageChange: ({
		pageIndex: number,
		dataSource: Function,
		stateKey: string,
	}) => void,
	onColumnsChanged: (Array<{}>) => void,
	updateRow: (?Array<*>, string) => void,
	deselectAll: (gridData: ?Array<*>) => void,
|}

function mapDispatchToProps<Item>(dispatch: Dispatch<DataGridReduxAction>, ownProps: Props<Item>): DispatchProps {
	return {
		onFilterChange: (gridConfig: {}) => {
			dispatch(Actions.PagerActions.setPageIndexAsync(gridConfig))
		},
		onPageChange: (gridConfig: {}) => {
			dispatch(Actions.PagerActions.setPageIndexAsync(gridConfig))
		},
		onColumnsChanged: (columns: Array<{}>) => {
			dispatch(
				Actions.GridActions.setColumns({
					columns,
					stateKey: ownProps.stateKey,
				}),
			)
		},
		deselectAll: (gridData: ?Array<*>) => {
			dispatch(Actions.SelectionActions.deselectAll({ stateKey: ownProps.stateKey }))
			if (gridData) {
				gridData.forEach((item: { get: (stateKey: string) => string, _rand: number }) => {
					//HACK: no better itea to unselect row
					item['_rand'] = Math.random()
				})
			}
		},
		updateRow: (gridData: ?Array<*>, id: string) => {
			if (gridData) {
				gridData.forEach((item: { get: (stateKey: string) => string }, index: number) => {
					if (item.get('id') === id) {
						dispatch(
							Actions.SelectionActions.setSelection({
								defaults: {
									allowDeselect: true,
									enabled: true,
								},
								id: 'row-' + index,
								index: index,
								ownProps: ownProps.stateKey,
							}),
						)
					}
				})
			}
		},
	}
}

function mergeProps<Item>(stateProps: StateProps, dispatchProps: DispatchProps, ownProps: Props<Item>) {
	return {
		...ownProps,
		filter: stateProps.filter,
		currentPage: stateProps.currentPage,
		sort: stateProps.sort,
		onFilterChange: dispatchProps.onFilterChange,
		onPageChange: dispatchProps.onPageChange,
		onColumnsChanged: dispatchProps.onColumnsChanged,
		updateRow: (id: string) => dispatchProps.updateRow(stateProps.gridData, id),
		deselectAll: () => dispatchProps.deselectAll(stateProps.gridData),
	}
}

const connector = <I: { id?: string }, P: DataGridProps<I>, C: React$ComponentType<P>>(
	wrappedComponent: C,
): React$ComponentType<Props<I>> => {
	return connect(mapStateToProps, mapDispatchToProps, mergeProps, { withRef: true })(wrappedComponent)
}

/*const ConnectedDataGrid = <I: { id?: string }>(props: Props<I>) => {
	const Grid = connector(DataGrid)
	return <Grid {...props} />
}*/

// $FlowFixMe
export default connector(DataGrid)
