// @flow

import React from 'react'
import { formatToDateString } from 'utils/formatters/date-formatter'

export function dateRenderer(value: ?string) {
	return (
		<div style={{ textAlign: 'left', fontWeight: 600, paddingRight: 15 }}>{value ? formatToDateString(value) : ''}</div>
	)
}
