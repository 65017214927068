// @flow
/** @jsx jsx */

import { PureComponent } from 'react'
import { jsx } from '@emotion/core'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import type { PortalLanguage } from 'types'
import { IconMenu, MenuItem } from 'components'
import { colors } from 'variables'
import { setAppLanguage, getAppLanguage } from 'locales'
import { VISITOR_LANGUAGE_NAME, VISITOR_LANGUAGE_MODIFICATION_TIME_NAME } from 'trivi-constants'
import UKFlag from 'components/svg-icons/flags/uk'
import CZFlag from 'components/svg-icons/flags/cz'
import ChevronDown from 'components/svg-icons/navigation/chevron-down'

export function isTimeWithinLastMiliseconds(timeString: ?string, rangeInMilliseconds: number = 1 * 60 * 1000): boolean {
	if (!timeString) return false
	const parsedTime = parseInt(timeString)
	if (isNaN(parsedTime)) return false
	const now = new Date().getTime()
	return parsedTime + rangeInMilliseconds >= now
}

type ComponentProps = {|
	...WithTranslateProps,
|}

type ComponentState = {|
	language: ?PortalLanguage,
|}

class ChangeLanguageForNonLoggedIn extends PureComponent<ComponentProps, ComponentState> {
	constructor(props: ComponentProps) {
		super(props)
		this.state = { language: getAppLanguage() }
		this.onLanguageChange.bind(this)
		sessionStorage.setItem(VISITOR_LANGUAGE_NAME, '')
		sessionStorage.setItem(VISITOR_LANGUAGE_MODIFICATION_TIME_NAME, '')
	}

	onLanguageChange = (event: SyntheticEvent<HTMLElement>, language: ?PortalLanguage) => {
		setAppLanguage(language)
		this.setState({ language })
		sessionStorage.setItem(VISITOR_LANGUAGE_NAME, language ? language : '')
		sessionStorage.setItem(VISITOR_LANGUAGE_MODIFICATION_TIME_NAME, new Date().getTime().toString())
	}

	render() {
		return (
			<div css={styles.language}>
				{this.state.language === 'EN' ? <UKFlag /> : <CZFlag />}
				<div css={styles.iconMenu}>
					<IconMenu
						autoTestId="change-language-not-logged"
						icon={<ChevronDown size={17} />}
						onChange={this.onLanguageChange}
						context
					>
						<MenuItem primaryText={<CZFlag style={styles.icon} />} value="CS" />
						<MenuItem primaryText={<UKFlag style={styles.icon} />} value="EN" />
					</IconMenu>
				</div>
			</div>
		)
	}
}

const styles = {
	language: {
		display: 'inline-flex',
		alignItems: 'center',
		background: colors.blueFaded20,
		padding: '3px 6px 3px 15px',
		borderRadius: 3,
	},
	iconMenu: {
		marginLeft: 7,
	},
	icon: {
		display: 'inline-block',
		verticalAlign: 'middle',
		marginLeft: 2,
	},
}

export default withTranslate(ChangeLanguageForNonLoggedIn)
