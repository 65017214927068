/* @flow */
import Cashbot from 'modules/settings/components/company/cashbot'
import { connect } from 'react-redux'
import { getCurrentUserHasInternalFullAccess } from 'modules/user/selectors'
import { isCashbotEnabledSelector } from 'modules/organization/selectors'
import { toggleCashbotEnabled } from 'modules/settings/actions'
import { getIsCashbotAuthenticated } from 'modules/cashbot/selectors'
import { loginToCashbot, logoutFromCashbot } from 'modules/cashbot/actions'
import type { State, SettingsAction, Dispatch } from 'types'
import type { CashbotAction } from 'modules/cashbot/actions/action-types'

type StateProps = {|
	currentUserHasInternalFullAccess: boolean,
	isCashbotEnabled: boolean,
	isCashbotAuthenticated: boolean,
|}

const mapStateToProps = (state: State): StateProps => {
	return {
		currentUserHasInternalFullAccess: getCurrentUserHasInternalFullAccess(state),
		isCashbotEnabled: isCashbotEnabledSelector(state),
		isCashbotAuthenticated: getIsCashbotAuthenticated(state),
	}
}

type DispatchProps = {
	toggleCashbotEnabled: (enabled: boolean) => void,
	loginToCashbot: () => Promise<void>,
	logoutFromCashbot: () => Promise<void>,
}

const mapDispatchToProps = (dispatch: Dispatch<SettingsAction | CashbotAction>): DispatchProps => {
	return {
		toggleCashbotEnabled: (enabled: boolean) => {
			dispatch(toggleCashbotEnabled(enabled))
		},
		loginToCashbot: async () => {
			return dispatch(loginToCashbot())
		},
		logoutFromCashbot: async () => {
			return dispatch(logoutFromCashbot())
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Cashbot)
