/* @flow */
/** @jsx jsx */

import { jsx } from '@emotion/core'
import Close from 'components/svg-icons/navigation/close'
import { colors } from 'variables'

type Props = {
	innerProps: Object,
	isDisabled: boolean,
}

const ClearIndicator = (props: Props) =>
	!props.isDisabled ? (
		<div css={style.root} {...props.innerProps}>
			<Close style={style.icon} size={18} color={colors.grey} hoverColor={colors.black} />
		</div>
	) : null

const style = {
	root: { cursor: 'pointer' },
	icon: { display: 'block' },
}

export default ClearIndicator
