/* @flow */

import type { State } from 'types'

export function getContactMeOpen(state: State): boolean {
	return state.contactMe && state.contactMe.isOpen
}

export function getContactMeSendingFlag(state: State): boolean {
	return state.contactMe && state.contactMe.sending
}
