//@flow
import React, { Component } from 'react'
import { withNotify, withTranslate, type WithNotifyProps, type WithTranslateProps } from 'wrappers'
import type { InvitationRequest, Organization } from 'types'
import GroupSelector from 'modules/groups/containers/group-selector'
import Dialog from 'components/dialog'
import ConfirmDialog from 'components/confirm-dialog'
import Button from 'components/button'
import EmailInput from 'components/email-input'
import styles from '../settings.css'
import { colors } from 'variables'
import { Validator } from 'utils'
import type { OptionsType, OptionType } from 'react-select/src/types'

export type Props = {
	open: boolean,
	email?: ?string,
	groups?: ?Array<string>,
	confirmationRequired: boolean,
	organization: ?Organization,
	onClose: () => void,
	onSendInvitation: InvitationRequest => Promise<void>,
	...WithTranslateProps,
	...WithNotifyProps,
}

type State = {
	invitationRequest: InvitationRequest,
}

const emptyInvitationRequest: () => InvitationRequest = () => {
	return {
		groups: [],
	}
}

class InviteUser extends Component<Props, State> {
	state = {
		invitationRequest: emptyInvitationRequest(),
	}

	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		if (nextProps.email && nextProps.email !== this.props.email) {
			this.onEmailChange(null, nextProps.email)
		}

		if (nextProps.groups && nextProps.groups !== this.props.groups) {
			this.onGroupsChange(nextProps.groups)
		}
	}

	send = async () => {
		const resp: any = await this.props.onSendInvitation(this.state.invitationRequest)
		if (!resp.serverError) {
			this.props.notify(this.props.t('settings.users.invitationSent'), 'success')
			this.onClose()
		}
	}

	getConfirmationBody = () => {
		const { t } = this.props
		return (
			<div>
				<span>{t('settings.invitations.confirmation.question.invitingUser') + ' '}</span>
				<span style={{ color: colors.red, whiteSpace: 'nowrap' }}>{this.state.invitationRequest.email}</span>
				<br />
				<span>{t('settings.invitations.confirmation.question.toCompany') + ' '}</span>
				<span style={{ color: colors.red, whiteSpace: 'nowrap' }}>
					{this.props.organization && this.props.organization.name}
				</span>
				<br />
				<br />
				<span>{t('settings.invitations.confirmation.areYouSure')}</span>
			</div>
		)
	}

	handleSendInvitations = async () => {
		const { t } = this.props
		if (this.props.onSendInvitation) {
			if (this.props.confirmationRequired) {
				ConfirmDialog(this.getConfirmationBody(), {
					okLabel: t('dialogs.yesOption'),
					cancelLabel: t('dialogs.noOption'),
				}).then(() => {
					this.send()
				})
			} else {
				this.send()
			}
		}
	}

	onClose = () => {
		this.setState({ invitationRequest: emptyInvitationRequest() })
		this.props.onClose()
	}

	onEmailChange = (event: ?SyntheticInputEvent<HTMLInputElement>, value: ?string) => {
		if (value !== null && value !== undefined) {
			this.setState((prevState: State) => {
				const { invitationRequest } = prevState
				return {
					invitationRequest: Object.freeze({
						...invitationRequest,
						email: value || '',
					}),
				}
			})
		}
	}

	onGroupsChange = (groups: OptionType | OptionsType) => {
		let value: Array<string> = Array.isArray(groups) ? groups.map((group: OptionType) => group.value) : [groups.value]
		this.setState((prevState: State) => {
			const { invitationRequest } = prevState
			return {
				invitationRequest: Object.freeze({
					...invitationRequest,
					groups: value,
				}),
			}
		})
	}

	render() {
		const { t } = this.props
		const { invitationRequest } = this.state
		const { email, groups } = invitationRequest
		const groupIds = invitationRequest.groups
		const isValid = email && Validator.email(email) && groups && groups.length

		const actions = [
			<Button
				primary
				wide
				key="okButton"
				labelText={t('settings.invitations.sendButtonLabel')}
				onClick={this.handleSendInvitations}
				disabled={!isValid}
				autoTestId="settings-invitations-dialog-send"
			/>,
			<Button
				secondary
				wide
				key="cancelButton"
				labelText={this.props.t('settings.forms.cancelButton')}
				onClick={this.onClose}
				autoTestId="settings-invitations-dialog-cancel"
			/>,
		]
		return (
			<Dialog
				autoTestId="invite-user-dialog"
				title={t('settings.invitations.title')}
				actions={actions}
				open={this.props.open}
			>
				<div className={styles.oneColumnDialog}>
					<div className={styles.row}>
						<EmailInput
							fullWidth
							value={email}
							onChange={this.onEmailChange}
							labelText={t('settings.invitations.label.email')}
							autoTestId="settings-invitations-email"
						/>
					</div>
					<div className={styles.row}>
						<GroupSelector
							fullWidth
							isMulti
							label={t('settings.invitations.label.group')}
							value={groupIds}
							onChange={this.onGroupsChange}
						/>
					</div>
				</div>
			</Dialog>
		)
	}
}

export default withNotify(withTranslate(InviteUser))
