import React from 'react'
import pure from 'recompose/pure'
import SvgIcon from 'components/SvgIcon'

let Card = props => (
	<SvgIcon {...props}>
		<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<path
				d="M12,4 C7.584,4 4,7.584 4,12 C4,16.416 7.584,20 12,20 C16.416,20 20,16.416 20,12 C20,7.584 16.416,4 12,4 Z"
				fillOpacity="0.6"
				fill="#FFA409"
				fillRule="nonzero"
			/>
			<path
				d="M12.24,11.325 C12.8200029,11.4750007 13.2849983,11.6349992 13.635,11.805 C14.1150024,12.0350011 14.4799988,12.3099984 14.73,12.63 C15.0300015,13.0200019 15.18,13.4949972 15.18,14.055 C15.18,14.6650031 14.9650022,15.1649981 14.535,15.555 C14.1549981,15.9050018 13.6350033,16.1449993 12.975,16.275 L12.975,17.625 L11.1,17.625 L11.1,16.275 C10.4599968,16.1349993 9.940002,15.8750019 9.54,15.495 C9.0999978,15.0749979 8.8600002,14.5350033 8.82,13.875 L10.2,13.875 C10.2200001,14.275002 10.3699986,14.5849989 10.65,14.805 C10.9700016,15.0650013 11.4349969,15.195 12.045,15.195 C12.655003,15.195 13.1099985,15.0650013 13.41,14.805 C13.6300011,14.604999 13.74,14.3650014 13.74,14.085 C13.74,13.7449983 13.6200012,13.4650011 13.38,13.245 C13.0799985,12.9549986 12.5750036,12.7250009 11.865,12.555 C10.9249953,12.3349989 10.2200024,12.0350019 9.75,11.655 C9.2099973,11.2149978 8.94,10.6550034 8.94,9.975 C8.94,9.3949971 9.1499979,8.90000205 9.57,8.49 C9.96000195,8.1099981 10.4699969,7.85500065 11.1,7.725 L11.1,6.375 L12.975,6.375 L12.975,7.755 C13.6150032,7.90500075 14.1149982,8.20499775 14.475,8.655 C14.8050017,9.0750021 14.9799999,9.5649972 15,10.125 L13.62,10.125 C13.5899999,9.2449956 13.0650051,8.805 12.045,8.805 C11.5249974,8.805 11.1150015,8.91249892 10.815,9.1275 C10.5149985,9.34250107 10.365,9.62499825 10.365,9.975 C10.365,10.2750015 10.5049986,10.529999 10.785,10.74 C11.0650014,10.950001 11.5499965,11.1449991 12.24,11.325 Z"
				fill="#FFFFFF"
			/>
		</g>
	</SvgIcon>
)
Card = pure(Card)

export default Card
