/* @flow */

import React, { PureComponent } from 'react'
import deepEqual from 'deep-equal'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import { Number_AccountingDocumentType } from 'types/convertor'
import type { DefaultsType } from 'modules/settings/reducers'
import type {
	AccountingDocumentDefaults,
	AccountingDocumentType,
	MultilanguageText,
	AccountingDocumentLanguage,
} from 'types'
import { AccountingDocumentTypeSelector, TextField } from 'components'
import { AVAILABLE_ACCOUNTING_DOCUMENT_LANGUAGES, getAccountingDocumentLanguageName } from 'locales'
import CollapsibleSettings from '../collapsible-settings'
import SaveSettings from '../save-settings'
import styles from '../settings.css'
import LanguageSelectorNext from '../../../common/components/language-selector/_language-selector-next'
import type { OptionType } from 'react-select/src/types'

const ALLOWED_TYPES = ['invoice', 'advance', 'tax_advance', 'credit_note']

type TextType = {|
	language?: AccountingDocumentLanguage,
	text?: string,
|}

type Props = {|
	open: boolean,
	defaults: DefaultsType,
	loading: boolean,
	onOpen: () => void,
	onClose: () => void,
	loadAccountingDocumentDefaults: (accountingDocumentType: ?AccountingDocumentType) => void,
	editAccountingDocumentDefaults: (
		oldDefaults: ?AccountingDocumentDefaults,
		newDefaults: AccountingDocumentDefaults,
		type: ?AccountingDocumentType,
	) => Promise<any>,
	...WithTranslateProps,
|}

type State = {
	accountingDocument: number,
	language?: AccountingDocumentLanguage,
	defaults: DefaultsType,
}

class CustomizationValues extends PureComponent<Props, State> {
	savedState: ?DefaultsType = null
	altered: boolean = false
	loading: boolean = false

	state: State = {
		defaults: { ...this.props.defaults },
		language: 'CS',
		accountingDocument: 0,
	}

	saveState = () => {
		this.savedState = this.state.defaults
	}

	restoreSavedState = () => {
		const saved = this.savedState
		this.resetSavedState()
		if (saved) {
			this.setState({ defaults: saved })
		}
	}

	resetSavedState = () => {
		this.altered = false
		this.savedState = null
		this.loading = false
	}

	changeSettings = async (partialState: Object) => {
		if (!this.altered) {
			this.altered = true
			this.saveState()
		}
		this.setState(partialState, () => {
			deepEqual(this.state, this.savedState) && this.restoreSavedState()
		})
	}

	changeCategory = async (partialState: Object) => {
		this.altered = false
		this.loading = false
		this.setState({ ...partialState, defaults: this.savedState || this.state.defaults })
	}

	componentDidMount() {
		const { defaults } = this.props
		const type: ?AccountingDocumentType = Number_AccountingDocumentType(this.state.accountingDocument)

		if (!defaults.issued || !defaults.issued[type || '']) {
			this.props.loadAccountingDocumentDefaults()
		}

		if (!defaults.issued || !defaults.issued[type || '']) {
			this.props.loadAccountingDocumentDefaults(type)
		}

		this.saveState()
	}

	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		const type: ?AccountingDocumentType = Number_AccountingDocumentType(this.state.accountingDocument)
		if (!type) return

		if (
			!this.props.defaults.issued ||
			!this.props.defaults.issued[type] ||
			nextProps.defaults.issued[type] !== this.props.defaults.issued[type]
		) {
			this.setState(
				{
					...this.state,
					defaults: { ...nextProps.defaults },
				},
				this.saveState,
			)
		}
		if (nextProps.loading === false && this.loading) this.resetSavedState()
	}

	UNSAFE_componentWillUpdate(nextProps: Props, nextState: State) {
		if (nextState.accountingDocument !== this.state.accountingDocument) {
			const defaults: DefaultsType = nextProps.defaults
			const type: ?AccountingDocumentType = Number_AccountingDocumentType(nextState.accountingDocument)

			if (!defaults.issued || !defaults.issued[type || '']) {
				this.props.loadAccountingDocumentDefaults(type)
			}
		}
	}

	changeStateField = (field: string, value: any, type: ?string) => {
		const typeIndex: string = type || ''

		const directionDefaults = this.state.defaults.issued || {}
		this.changeSettings({
			defaults: {
				...this.state.defaults,
				issued: {
					...directionDefaults,
					[typeIndex]: {
						...(directionDefaults[typeIndex] || {}),
						[field]: value,
					},
				},
			},
		})
	}

	removeEmptyRows = (text: string): string => {
		let rows = text.split('\n')
		let notEmptyRows = rows.filter((r: string) => r.trim().length > 0)
		return notEmptyRows.join('\n')
	}

	saveSettings = async () => {
		const { defaults } = this.props
		const type: ?AccountingDocumentType = Number_AccountingDocumentType(this.state.accountingDocument)

		const oldDefaults: ?AccountingDocumentDefaults = type && defaults.issued && defaults.issued[type]
		const stateDefaults: ?AccountingDocumentDefaults =
			type && this.state.defaults.issued && this.state.defaults.issued[type]
		const newDefaults: AccountingDocumentDefaults = { ...oldDefaults }

		if (stateDefaults) {
			if (stateDefaults.header) {
				newDefaults.header = stateDefaults.header
			}
			if (stateDefaults.footer) {
				newDefaults.footer = stateDefaults.footer
			}
			if (stateDefaults.itemPrefix) {
				newDefaults.itemPrefix = stateDefaults.itemPrefix
			}
			if (stateDefaults.itemSuffix) {
				newDefaults.itemSuffix = stateDefaults.itemSuffix
			}
		}

		if (newDefaults.itemPrefix) {
			newDefaults.itemPrefix.forEach((item: { language?: AccountingDocumentLanguage, text?: string }) => {
				if (item.text) {
					item.text = this.removeEmptyRows(item.text)
				}
			})
		}

		if (newDefaults.itemSuffix) {
			newDefaults.itemSuffix.forEach((item: { language?: AccountingDocumentLanguage, text?: string }) => {
				if (item.text) {
					item.text = this.removeEmptyRows(item.text)
				}
			})
		}

		this.loading = true
		await this.props.editAccountingDocumentDefaults(oldDefaults, newDefaults, type)
	}

	onAccountingDocumentChange = async (id: number) => {
		await this.changeCategory({ accountingDocument: id })
	}

	onLanguageChange = async (option: ?OptionType) => {
		await this.changeCategory({ language: (option && option.value) || 'CS' })
	}

	onMultilanguageTextChange = (field: string, oldTexts: ?MultilanguageText, type: ?AccountingDocumentType) => (
		ev: SyntheticInputEvent<HTMLInputElement>,
		value: ?string,
	) => {
		if (value !== null && value !== undefined) {
			const newVal: TextType = {
				language: this.state.language,
				text: value,
			}

			const found: ?boolean =
				oldTexts && !!oldTexts.find((text: TextType) => text.language && text.language === this.state.language)

			const newMultilanguagetext: MultilanguageText =
				!found || !oldTexts
					? [...(oldTexts || []), newVal]
					: oldTexts.map((text: TextType) => {
							if (text.language && text.language === this.state.language) {
								return newVal
							}
							return text
					  })

			this.changeStateField(field, newMultilanguagetext, type)
		}
	}

	renderMultiLanguageArea = (field: string) => {
		const { t } = this.props
		const { defaults } = this.state
		const type: ?AccountingDocumentType = Number_AccountingDocumentType(this.state.accountingDocument)
		const typeDefaults: ?AccountingDocumentDefaults = defaults.issued && defaults.issued[type || '']

		const text: ?TextType =
			typeDefaults &&
			typeDefaults[field] &&
			typeDefaults[field].find((text: TextType) => text.language === this.state.language)

		return (
			<TextField
				labelText={t(`settings.accountingDocuments.${field}`)}
				multiLine
				fullWidth
				value={(text && text.text) || ''}
				onChange={this.onMultilanguageTextChange(field, typeDefaults && typeDefaults[field], type)}
				name={field}
			/>
		)
	}

	renderTextsBefore = (accountingDocument: ?number, language?: AccountingDocumentLanguage) => {
		const { t } = this.props

		const availableLanguages = AVAILABLE_ACCOUNTING_DOCUMENT_LANGUAGES.map((lang: AccountingDocumentLanguage) => {
			return {
				key: lang,
				name: getAccountingDocumentLanguageName(lang),
			}
		})

		return (
			<div>
				<div className={styles.halfRow}>
					<AccountingDocumentTypeSelector
						allowedTypes={ALLOWED_TYPES}
						value={accountingDocument}
						onChange={this.onAccountingDocumentChange}
						labelText={t('settings.accountingDocuments.texts')}
					/>
				</div>
				<div className={styles.halfRow}>
					<LanguageSelectorNext
						labelText={t('settings.accountingDocuments.language')}
						availableLanguages={availableLanguages}
						onChange={this.onLanguageChange}
						language={language}
					/>
				</div>
				<div style={{ marginTop: 50 }}>
					{/*
					// Hidden for now. Header & footer are not used anywhere. TWU-2028
					<div className={styles.halfRow}>{this.renderMultiLanguageArea('header')}</div>
					<div className={styles.halfRow}>{this.renderMultiLanguageArea('footer')}</div>
					*/}
					<div className={styles.halfRow}>{this.renderMultiLanguageArea('itemPrefix')}</div>
					<div className={styles.halfRow}>{this.renderMultiLanguageArea('itemSuffix')}</div>
				</div>
			</div>
		)
	}

	renderBody = () => {
		return (
			<div className={styles.hideOutSection}>
				{this.renderTextsBefore(this.state.accountingDocument, this.state.language)}

				<div style={{ marginTop: 20 }}>
					<SaveSettings
						visible={this.altered}
						saving={this.loading}
						onSave={this.saveSettings}
						onRestore={this.restoreSavedState}
					/>
				</div>
			</div>
		)
	}

	render() {
		return (
			<CollapsibleSettings
				open={this.props.open}
				onOpen={this.props.onOpen}
				onClose={this.props.onClose}
				title={this.props.t('settings.accountingDocuments.customizationValues')}
			>
				{this.renderBody()}
			</CollapsibleSettings>
		)
	}
}

export default withTranslate(CustomizationValues)
