/* @flow */

import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withNavigationPermissions, type WithNavigationPermissionsProps } from 'wrappers'
import { loadBootstrap, changeCurrentOrganization, logout } from 'modules/user/actions'
import type { Dispatch, State, WithRouterProps, BootstrapAction } from 'types'
import { getRegisterRoute, getInvitationRegisterRoute } from 'modules/user/routing/routes'
import { getIsBootstrapLoaded } from 'modules/user/selectors'
import { navigateToMainScreen } from 'modules/navigation/actions'
import AppComponent from './app-component'
import { getAccessToken } from 'utils/local-storage'

type OwnProps = {|
	children: React$Element<any>,
	...WithRouterProps,
	...WithNavigationPermissionsProps,
|}

type StateProps = {|
	organizationId: ?string,
	readyToLoadBootstrap: boolean,
	bootstrapLoaded: boolean,
|}

type DispatchProps = {|
	loadBootstrap: (organizationId: ?string) => Promise<void>,
	changeCurrentOrganization: (organizationId: string) => void,
|}

const mapStateToProps = (state: State): StateProps => {
	return {
		organizationId: state.user.currentOrganizationId,
		readyToLoadBootstrap: !state.user.invitationInProgress && !!getAccessToken(),
		bootstrapLoaded: getIsBootstrapLoaded(state),
	}
}

const mapDispatchToProps = (dispatch: Dispatch<BootstrapAction>, ownProps: OwnProps): DispatchProps => {
	return {
		loadBootstrap: async (organizationId: ?string) => {
			const action = await dispatch(loadBootstrap(organizationId))
			const locationPathname = ownProps.location.pathname

			if (action) {
				if (!action.data) {
					dispatch(logout())
				} else if (
					locationPathname === '/' ||
					locationPathname === getRegisterRoute() ||
					locationPathname === getInvitationRegisterRoute()
				) {
					dispatch(navigateToMainScreen())
				}
			}
		},
		changeCurrentOrganization: (organizationId: string) => {
			dispatch(changeCurrentOrganization(organizationId))
		},
	}
}

export default withNavigationPermissions(withRouter(connect(mapStateToProps, mapDispatchToProps)(AppComponent)))
