/* @flow */

import React, { Component } from 'react'
import { type WithTranslateProps, withTranslate } from 'wrappers'
import EsoNone from 'components/svg-icons/trivi/eso/none'
import EsoWaiting from 'components/svg-icons/trivi/eso/waiting'
import EsoConnected from 'components/svg-icons/trivi/eso/connected'
import EsoDisconnected from 'components/svg-icons/trivi/eso/disconnected'
import { colors } from 'variables'

type Props = {|
	state: ?number,
	...WithTranslateProps,
|}

class EsoIcon extends Component<Props> {
	getLabelText() {
		switch (this.props.state) {
			case 1:
				return this.props.t('settings.eso.notConnected')
			case 2:
				return this.props.t('settings.eso.connected')
			case 3:
				return this.props.t('settings.eso.waitingForConnection')
			case 4:
				return this.props.t('settings.eso.disconnected')
			case 5:
				return this.props.t('settings.eso.connectionError')
			default:
				return this.props.t('settings.eso.title')
		}
	}

	renderIcon() {
		const iconProps = {
			size: 10,
		}

		switch (this.props.state) {
			case 1:
				return <EsoNone {...iconProps} />
			case 2:
				return <EsoConnected {...iconProps} />
			case 3:
				return <EsoWaiting {...iconProps} />
			case 4:
				return <EsoDisconnected {...iconProps} />
			case 5:
				return <EsoDisconnected {...iconProps} />
			default:
				return <EsoNone {...iconProps} />
		}
	}

	render() {
		const styles = getStyles(this.props)

		return this.props.state ? (
			<div style={styles.root}>
				{this.getLabelText()}&nbsp;&nbsp;{this.renderIcon()}
			</div>
		) : null
	}
}

function getBorderColor(state: ?number) {
	switch (state) {
		case 2:
			return colors.greenFaded80
		case 3:
			return colors.blueFaded80
		case 4:
			return colors.redFaded80
		case 5:
			return colors.redFaded80
		default:
			return colors.gray300
	}
}

function getStyles(props: Props) {
	const borderColor = getBorderColor(props.state)

	return {
		root: {
			backgroundColor: colors.white,
			borderRadius: 3,
			borderWidth: 1,
			borderStyle: 'solid',
			borderColor: borderColor,
			whiteSpace: 'nowrap',
			lineHeight: '20px',
			overflow: 'hidden',
			color: colors.gray,
			fontSize: 10,
			height: 20,
			padding: '0 7px',
			userSelect: 'none',
			textTransform: 'uppercase',
			display: 'inline-flex',
			alignItems: 'center',
			alignContent: 'center',
			justifyContent: 'flex-start',
		},
	}
}

export default withTranslate(EsoIcon)
