/* @flow */
/** @jsx jsx */

import { jsx } from '@emotion/core'
import { type FavoriteOptionType } from './favorite-option'

type Props = {
	className?: string,
	children: React$Node,
	innerRef: string,
	innerProps: Object,
	data: FavoriteOptionType,
	isDisabled: boolean,
	getStyles: (string, any) => {},
	cx: (state: ?Object, className: ?string) => ?string,
}

const SingleValue = (props: Props) => {
	return (
		<div
			css={props.getStyles('singleValue', props)}
			className={props.cx(
				{
					'single-value': true,
					'single-value--is-disabled': props.isDisabled,
				},
				props.className,
			)}
			{...props.innerProps}
		>
			{props.data.labelPrefix} {props.children}
		</div>
	)
}

export default SingleValue
