// @flow
/** @jsx jsx */

import { Component } from 'react'
import { jsx } from '@emotion/core'
import type { TermsItemResponse } from 'types'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import { Button, PopupSection } from 'components'
import TermsViewer from './terms-viewer'
import { Z_INDEXES } from 'trivi-constants'

type Props = {|
	open: boolean,
	terms: ?TermsItemResponse,
	loading: boolean,
	disagree: () => void,
	agree: (terms: TermsItemResponse) => void,
	...WithTranslateProps,
|}

class TermsDialog extends Component<Props> {
	onAgree = () => {
		const { terms, agree } = this.props
		terms && agree(terms)
	}

	render() {
		const { open, t, disagree, loading, terms } = this.props
		return (
			<PopupSection onRequestClose={disagree} open={open} notClosable zIndex={Z_INDEXES.dialogs.termsAndConditions}>
				<div css={style.viewer}>
					<TermsViewer loading={loading} fileUrl={terms && terms.fileUrl} />
				</div>
				<div css={style.buttons}>
					<Button
						autoTestId="terms-dialog-agree"
						disabled={loading}
						wide
						primary
						labelText={t('dialogs.agreeWithTerms')}
						onClick={this.onAgree}
					/>
					<Button
						autoTestId="terms-dialog-disagree"
						disabled={loading}
						secondary
						labelText={t('dialogs.disagreeWithTerms')}
						wide
						onClick={disagree}
					/>
				</div>
			</PopupSection>
		)
	}
}

const style = {
	viewer: {
		display: 'block',
		paddingTop: 30,
		paddingBottom: 30,
		height: 'calc(100% - 80px)',
		maxWidth: 1170,
		marginLeft: 'auto',
		marginRight: 'auto',
		overflow: 'hidden',
		position: 'relative',
	},
	buttons: {
		textAlign: 'center',
		paddingBottom: 30,
	},
}

export default withTranslate(TermsDialog)
