//@flow

import type { Dispatch, State, StickyNote, BillingReportGlobalResponse, PeriodParams } from 'types'
import type {
	StickyNoteDeleteParams,
	StickyNotePutParams,
	StickyNotesGetParams,
	StickyNotesGetResponse,
} from 'modules/common/models/api-model'
import { find } from 'lodash-es'
import { stickyNote, stickyNotes, billingReports } from 'modules/common/models/api-model'
import { impersonateToken as impersonateTokenApi } from 'modules/common/models/auth-api-model'
import type { Action as InternalAction } from './action-types'

export function createStickyNote(body: StickyNote) {
	return async (dispatch: Dispatch<InternalAction>) => {
		dispatch({
			type: 'START_CREATE_STICKY_NOTE',
			body,
		})
		try {
			const response: StickyNote = await stickyNotes.post(body)
			//const response: StickyNote = await fakeCreate(body)
			return dispatch({
				type: 'FINISH_CREATE_STICKY_NOTE',
				original: body,
				response,
			})
		} catch (error) {
			return dispatch({
				type: 'FINISH_CREATE_STICKY_NOTE',
				original: body,
				serverError: error,
			})
		}
	}
}

export function getStickyNotes(params: StickyNotesGetParams) {
	return async (dispatch: Dispatch<InternalAction>) => {
		dispatch({
			type: 'START_GET_STICKY_NOTES',
			params,
		})
		try {
			const response: StickyNotesGetResponse = await stickyNotes.get(params)
			//const response: StickyNotesGetResponse = await fakeGet(params)

			return dispatch({
				type: 'FINISH_GET_STICKY_NOTES',
				response,
			})
		} catch (error) {
			return dispatch({
				type: 'FINISH_GET_STICKY_NOTES',
				serverError: error,
			})
		}
	}
}

export function updateStickyNote(params: StickyNotePutParams, body: StickyNote) {
	return async (dispatch: Dispatch<InternalAction>, getState: () => State) => {
		if (body.id != null) {
			const original = selectStickyNote(getState, body.id)

			if (original != null) {
				dispatch({
					type: 'START_UPDATE_STICKY_NOTE',
					params,
					body,
				})

				try {
					const response: StickyNote = await stickyNote.put(params, body)
					//const response: StickyNote = await fakeUpdate(params, body)
					return dispatch({
						type: 'FINISH_UPDATE_STICKY_NOTE',
						response,
						original,
					})
				} catch (error) {
					return dispatch({
						type: 'FINISH_UPDATE_STICKY_NOTE',
						serverError: error,
						original,
					})
				}
			}
		}
	}
}

export function deleteStickyNote(params: StickyNoteDeleteParams) {
	return async (dispatch: Dispatch<InternalAction>, getState: () => State) => {
		const original = selectStickyNote(getState, params.stickyNoteId)

		if (original != null) {
			dispatch({
				type: 'START_DELETE_STICKY_NOTE',
				params,
			})
			try {
				await stickyNote.delete(params)
				//await fakeDelete(params)

				return dispatch({
					type: 'FINISH_DELETE_STICKY_NOTE',
					original,
				})
			} catch (error) {
				return dispatch({
					type: 'FINISH_DELETE_STICKY_NOTE',
					serverError: error,
					original,
				})
			}
		}
	}
}

export function fetchBillingReport(params: PeriodParams) {
	return async (dispatch: Dispatch<InternalAction>) => {
		dispatch({ type: 'START_FETCHING_BILLING_REPORT' })
		// const billingsMock = {
		// 	periodFrom: '2018-07-01T00:00:00',
		// 	periodTo: '2018-07-31T00:00:00',
		// 	organizations: [generateBillingMock()],
		// }
		// dispatch({
		// 	type: 'FINISH_FETCHING_BILLING_REPORT',
		// 	selectedDate: `${periodMonth}/${periodYear}`,
		// 	payload: billingsMock,
		// })
		try {
			const { periodYear, periodMonth } = params
			const response: BillingReportGlobalResponse = await billingReports.get({ periodYear, periodMonth })
			dispatch({
				type: 'FINISH_FETCHING_BILLING_REPORT',
				selectedDate: `${periodMonth}/${periodYear}`,
				payload: response,
			})
		} catch (err) {
			dispatch({
				type: 'FINISH_FETCHING_BILLING_REPORT',
				serverError: err,
			})
		}
	}
}

export function changeSelectedPeriod(payload: string) {
	return {
		type: 'CHANGE_SELECTED_PERIOD',
		payload,
	}
}

function selectStickyNote(getState: () => State, stickyNoteId: string) {
	const state = getState()
	const stateStickyNotes = state.internalDashboard.stickyNotes
	return find(stateStickyNotes, (stickyNote: StickyNote) => stickyNote.id == stickyNoteId)
}

export function impersonateToken(username: string) {
	return async (dispatch: Dispatch<InternalAction>) => {
		dispatch({
			type: 'START_IMPERSONATE',
		})

		try {
			const token = await impersonateTokenApi.post({ username })
			return dispatch({
				type: 'FINISH_IMPERSONATE',
				token,
			})
		} catch (error) {
			return dispatch({
				type: 'FINISH_IMPERSONATE',
				serverError: error,
			})
		}
	}
}
