//@flow
import React, { Component } from 'react'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import type { PeriodParams } from 'types'
import Helmet from 'react-helmet'
import styles from './billing-page.css'
import BillingTable from './billing-table'
import PeriodPicker from './period-picker'

export type Props = {|
	changePeriodParams: (value: PeriodParams) => void,
	loading: boolean,
	organizationNames: Array<string>,
	currentPeriod: ?string,
|}

type ComponentProps = {|
	...Props,
	...WithTranslateProps,
|}

class BillingPage extends Component<ComponentProps> {
	UNSAFE_componentWillMount = () => {
		this.props.changePeriodParams({
			periodMonth: new Date().getMonth() + 1,
			periodYear: new Date().getFullYear(),
		})
	}
	handlePeriodPickerChange = (value: PeriodParams) => {
		this.props.changePeriodParams(value)
	}

	render() {
		const { t, organizationNames, currentPeriod, loading } = this.props
		return (
			<div className={styles.root}>
				<Helmet>
					<title>{t('internal.billing')}</title>
				</Helmet>
				<div className={styles.header}>
					<h1 className={styles.headline}>{t('internal.billing')}</h1>
					<div className={styles.dateFilter}>
						<PeriodPicker onChange={this.handlePeriodPickerChange} />
					</div>
				</div>
				<div>
					<div>
						{currentPeriod && (
							<div>
								{t('internal.billingPeriod')}: <span>{currentPeriod}</span>
							</div>
						)}
						<BillingTable organizationNames={organizationNames} loading={loading} />
					</div>
				</div>
			</div>
		)
	}
}

export default withTranslate(BillingPage)
