import React from 'react'
import SvgIcon from 'components/SvgIcon'
import pure from 'recompose/pure'

let Disconnected = props => (
	<SvgIcon {...props}>
		<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g fill="#E60028">
				<path d="M13.8947368,12 L18,7.89473684 L16.1052632,6 L12,10.1052632 L7.89473684,6 L6,7.89473684 L10.1052632,12 L6,16.1052632 L7.89473684,18 L12,13.8947368 L16.1052632,18 L18,16.1052632 L13.8947368,12 Z M0,12 C0,5.372583 5.37112582,0 12,0 C18.627417,0 24,5.37112582 24,12 C24,18.627417 18.6288742,24 12,24 C5.372583,24 0,18.6288742 0,12 Z" />
			</g>
		</g>
	</SvgIcon>
)
Disconnected = pure(Disconnected)

export default Disconnected
