/* @flow */

import React, { PureComponent, type Node } from 'react'
import memoize from 'memoize-one'
import type { Country } from 'types'
import { type AutoTestProps } from 'utils/tests/autotest'
import FavoriteSelect from 'components/favorite-select'
import { type FavoriteOptionType } from 'components/favorite-select/favorite-option'
import { EMPTY_ARRAY } from 'trivi-constants'

type Props = {|
	...AutoTestProps,
	value: ?string,
	loading: boolean,
	countries: ?Array<Country>,
	favoriteCountries: ?Array<string>,
	label?: string,
	placeholder?: string,
	disabled?: boolean,
	inline?: boolean,
	autoWidth?: boolean,
	error?: Node,
	onChange: (country: Country) => void,
	onFavorite: (contact: Country, isFavorite: boolean) => void,
	loadCountries: () => void,
	loadFavoriteCountries: () => void,
|}

class CountrySelector extends PureComponent<Props> {
	UNSAFE_componentWillMount() {
		!this.props.countries && this.props.loadCountries()
		!this.props.favoriteCountries && this.props.loadFavoriteCountries()
	}

	getOptions = memoize((countries: ?Array<Country>, favoriteCountries: ?Array<string>) => {
		return countries
			? countries.map((country: Country) => {
					return {
						label: `${country.name || ''} (${country.id || ''})`,
						value: country.id,
						isFavorite: (favoriteCountries || []).includes(country.id),
						onFavorite: this.onFavorite,
					}
			  })
			: EMPTY_ARRAY
	})

	getCountryById = (id: ?string) => {
		return id && this.props.countries && this.props.countries.find((country: Country) => country.id === id)
	}

	onChange = (option: FavoriteOptionType) => {
		const country = this.getCountryById(option.value)
		country && this.props.onChange(country)
	}

	onFavorite = (value: ?string, isFavorite: boolean) => {
		const country = this.getCountryById(value)
		country && this.props.onFavorite(country, isFavorite)
	}

	render() {
		return (
			<FavoriteSelect
				options={this.getOptions(this.props.countries, this.props.favoriteCountries)}
				value={this.props.value}
				onChange={this.onChange}
				placeholder={this.props.placeholder}
				label={this.props.label}
				disabled={this.props.disabled}
				inline={this.props.inline}
				autoWidth={this.props.autoWidth}
				error={this.props.error}
				autoTestId={this.props.autoTestId}
			/>
		)
	}
}

export default CountrySelector
