/* @flow */

import { connect } from 'react-redux'
import { push, replace } from 'react-router-redux'
import { userHasAccess } from 'permissions'
import type {
	AccountingDocument,
	AccountingDocumentReduxAction,
	Dispatch,
	NavigationAction,
	SettingsAction,
	State,
	SequencesAction,
} from 'types'
import { Name_AccountingDocumentType, Number_AccountingDocumentAssignedType } from 'types/convertor'
import { createAccountingDocument } from 'modules/accounting-document/actions'
import {
	editAccountingDocumentRoute,
	createAccountingDocumentRoute,
	uploadScanRoute,
} from 'modules/accounting-document/routing/routes'
import { currentUserIsInternal } from 'modules/user/selectors'
import AddButton from '../components/add-button'
import { addressBookRoute } from 'modules/address-book/routing/routes'
// import { taskCreateRoute } from 'modules/task/routing/routes'

type StateProps = {|
	currentUserIsInternal: boolean,
	canEditIssuedAccountingDocuments: boolean,
	canEditReceivedAccountingDocuments: boolean,
	canEditContacts: boolean,
|}

const mapStateToProps = (state: State): StateProps => {
	return {
		currentUserIsInternal: currentUserIsInternal(state),
		canEditIssuedAccountingDocuments: userHasAccess(state, 'editIssuedAccountingDocuments'),
		canEditReceivedAccountingDocuments: userHasAccess(state, 'editReceivedAccountingDocuments'),
		canEditContacts: userHasAccess(state, 'editContacts'),
	}
}

type DispatchProps = {|
	onNavigate: (id: string, params: { [string]: any }) => ?Promise<*>,
|}

const mapDispatchToProps = (
	dispatch: Dispatch<AccountingDocumentReduxAction | NavigationAction | SettingsAction | SequencesAction>,
): DispatchProps => {
	return {
		onNavigate: (itemId: string, params: { [string]: any }) => {
			if (itemId === 'uploadScan') {
				return dispatch(push(uploadScanRoute()))
			}

			if (itemId === 'createContact') {
				return dispatch(push(addressBookRoute('new')))
			}

			// if (itemId === 'createTask') {
			// 	return dispatch(push(taskCreateRoute()))
			// }

			const accountingDocumentType = Name_AccountingDocumentType(itemId)

			// create accounting document
			if (accountingDocumentType > -1) {
				const body = {
					type: accountingDocumentType,
					direction: params.direction === 'issued' ? 1 : 0,
				}

				dispatch(createAccountingDocument(body)).then((action: { result: AccountingDocument, type: string }) => {
					if (!action.result) {
						return
					}
					const documentId: string = action.result.id || ''
					dispatch(
						replace({
							pathname: editAccountingDocumentRoute(documentId),
							state: {
								isNew: true,
							},
						}),
					)
				})

				const type = Number_AccountingDocumentAssignedType(accountingDocumentType)
				dispatch(push({ pathname: createAccountingDocumentRoute(type, params.direction) }))
			}
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AddButton)
