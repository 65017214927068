/* @flow */

import React, { Component } from 'react'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import Button from 'components/button'
import styles from './not-found.css'

type Props = {
	...WithTranslateProps,
	redirectToMainScreen: () => void,
}

class NotFound extends Component<Props> {
	render() {
		return (
			<div className={styles.root}>
				<div>
					<h1 className={styles.headline}>{this.props.t('application.pageNotFound.headline')}</h1>
					<div className={styles.image} />
					<Button
						autoTestId="not-found-back"
						labelText={this.props.t('application.pageNotFound.backToHomeButton')}
						onClick={this.props.redirectToMainScreen}
						tall
						wide
					/>
				</div>
			</div>
		)
	}
}

export default withTranslate(NotFound)
