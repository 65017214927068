// @flow

import { connect } from 'react-redux'
import type { State, TermsItemResponse, Dispatch, UserAction } from 'types'
import { getTermsFromState, getTermsLoadingFlag } from '../selectors'
import { agreeWithTerms } from '../actions'
import TermsDialog from '../components/terms-dialog'
import { logout } from 'modules/user/actions'

type StateProps = {|
	open: boolean,
	terms: ?TermsItemResponse,
	loading: boolean,
|}

function mapStateToProps(state: State): StateProps {
	return {
		open: !!getTermsFromState(state),
		terms: getTermsFromState(state),
		loading: getTermsLoadingFlag(state),
	}
}

type DispatchProps = {|
	disagree: () => void,
	agree: (terms: TermsItemResponse) => void,
|}

function mapDispatchToProps(dispatch: Dispatch<UserAction>): DispatchProps {
	return {
		disagree: () => {
			dispatch(logout())
		},
		agree: (terms: TermsItemResponse) => {
			dispatch(agreeWithTerms(terms))
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(TermsDialog)
