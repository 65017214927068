// @flow

import { connect } from 'react-redux'
import type { Dispatch, AccountingDocumentReduxAction, Base64Image, State } from 'types'
import { loadPublicAccountingDocumentQrCode } from 'modules/accounting-document/actions'
import { getPublicAccountingDocumentQr } from 'modules/accounting-document/selectors'
import Qr from 'modules/accounting-document/components/invoice-elements/qr'

type OwnProps = {|
	uniqueId: string,
|}

type StateProps = {|
	qr: ?Base64Image,
|}

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => {
	return {
		qr: getPublicAccountingDocumentQr(state, ownProps.uniqueId),
	}
}

type DispatchProps = {|
	loadQrCode: (id: string) => void,
|}

function mapDispatchToProps(dispatch: Dispatch<AccountingDocumentReduxAction>): DispatchProps {
	return {
		loadQrCode: (uniqueId: string) => {
			dispatch(loadPublicAccountingDocumentQrCode(uniqueId))
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Qr)
