// @flow

/** @jsx jsx */
import { jsx } from '@emotion/core'
import React, { Component } from 'react'
import type { Scan, AccountingDocumentCategory } from 'types'
import { PopupSection, Button } from 'components'
import UploadScanListContainer from '../../containers/upload-scan/upload-scan-list'
import UploadScanZone from './upload-scan-zone'
import HowToUpload from './how-to-upload'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import type { ErrorScanUpload } from 'modules/accounting-document/reducers/upload-scan'
import { File_UploadScanFile } from 'types/convertor'
import { passAutoTestId } from 'utils/tests/autotest'
import ConfirmDialog from 'components/confirm-dialog'
import styles from './upload-scan.css'

type Props = {
	...WithTranslateProps,
	scans: Array<Scan>,
	uploading: boolean,
	errorScans: Array<ErrorScanUpload>,
	documentCategories: ?Array<AccountingDocumentCategory>,
	uploadScan: (file: File, documentCategory: ?string, customUploadId?: number) => Promise<any>,
	uploadScans: (scans: Array<Scan>) => Promise<any>,
	loadAccountingDocumentCategories: () => void,
	onRequestClose: (afterSave?: boolean) => void,
}

type State = {|
	uploading: boolean,
|}

class UploadScan extends Component<Props, State> {
	state: State = {
		uploading: this.props.uploading,
	}
	_isMounted = false

	UNSAFE_componentWillMount() {
		!this.props.documentCategories && this.props.loadAccountingDocumentCategories()
	}

	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		this.props.uploading !== nextProps.uploading && this.setState({ uploading: nextProps.uploading })
	}

	componentDidMount() {
		this._isMounted = true
	}

	componentWillUnmount() {
		this._isMounted = false
	}

	onSaveButtonClick = () => {
		this.save()
	}

	save = async () => {
		this.setState({ uploading: true })
		await this.props.uploadScans(this.props.scans)
		if (this._isMounted) {
			this.setState({ uploading: false })
		}
		this.props.onRequestClose(true)
	}

	reUploadScan = (uploadId: string) => {
		const found: ?ErrorScanUpload = this.props.errorScans.find(
			(s: ErrorScanUpload) => s.uploadId.toString() === uploadId,
		)
		found && this.props.uploadScan(found.file, found.documentCategory, found.uploadId)
	}

	reUploadAllScans = () => {
		this.props.errorScans.forEach((scan: ErrorScanUpload) => {
			this.props.uploadScan(scan.file, scan.documentCategory, scan.uploadId)
		})
	}

	closeDialog = () => {
		this.props.onRequestClose()
	}

	confirmCloseDialog = () => {
		ConfirmDialog(this.props.t('uploadScan.closeWhenUploadingWarningHeadline'), {
			okLabel: this.props.t('application.closePopupSection'),
			cancelLabel: this.props.t('dialogs.goBackOption'),
			body: this.props.t('uploadScan.closeWhenUploadingWarningBody'),
		}).then(() => {
			this.closeDialog()
		})
	}

	onRequestClose = () => {
		if (this.state.uploading || this.props.errorScans.length > 0 || this.props.scans.length > 0)
			return this.confirmCloseDialog()

		this.closeDialog()
	}

	hasScans = () => {
		return this.props.scans.length || this.props.errorScans.length
	}

	render() {
		const { t } = this.props

		return (
			<PopupSection open onRequestClose={this.onRequestClose}>
				<div className={styles.container} id={'upload-scan-container'}>
					<div className={this.hasScans() ? styles.contentWithScans : styles.content}>
						<div className={styles.header}>
							<div className={styles.headings} id={'upload-scan-header'}>
								<h1 className={styles.h1}>{t('uploadScan.headline')}</h1>
							</div>
							<div className={styles.actions}>
								<Button
									disabled={this.props.scans.length === 0 || this.state.uploading || this.props.errorScans.length > 0}
									onClick={this.onSaveButtonClick}
									labelText={t('uploadScan.saveButtonLabel')}
									{...passAutoTestId('upload-save-button')}
								/>
							</div>
						</div>
						<div className={styles.upload}>
							<div className={styles.uploads}>
								<div className={styles.warningBox}>
									<h5>{t('uploadScan.fileAmountWarning')}</h5>
									<p>{t('uploadScan.uploadWarning')}</p>
								</div>
								<UploadScanZone
									uploading={this.state.uploading}
									uploadScan={this.props.uploadScan}
									documentCategories={this.props.documentCategories}
									accept="image/jpeg,image/jpg,image/png,image/gif,image/tiff,application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/docx,application/msword"
								/>
								<UploadScanListContainer
									documentCategories={this.props.documentCategories}
									scans={this.props.scans}
									reUploadScan={this.reUploadScan}
									reUploadAllScans={this.reUploadAllScans}
									errorScans={this.props.errorScans.map((item: ErrorScanUpload) => ({
										id: item.uploadId,
										documentCategory: item.documentCategory,
										files: [{ fileId: '', ...File_UploadScanFile(item.file) }],
										checked: false, // not used
										branch: null,
										project: null,
										paymentType: null,
										cashRegisterId: null,
										customerInstructions: null,
									}))}
								/>
							</div>
							{!this.hasScans() && <HowToUpload />}
						</div>
					</div>
				</div>
			</PopupSection>
		)
	}
}

export default withTranslate(UploadScan)
