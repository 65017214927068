/* @flow */

import type { Dispatch, State } from 'types'
import type { Action, Listener } from 'utils/events'
import { loadIssues } from '../actions'

const loadIssuesActionTypes = [
	'FINISH_UPDATE_ACCOUNTING_DOCUMENT_HEADER',
	'FINISH_ADD_ACCOUNTING_DOCUMENT_BANK_ACCOUNT',
	'FINISH_REMOVE_ACCOUNTING_DOCUMENT_BANK_ACCOUNT',
	'FINISH_UPDATE_ACCOUNTING_DOCUMENT_LINE_ITEM',
	'FINISH_UPDATE_ACCOUNTING_DOCUMENT_VAT_RECAP_INFO',
	'FINISH_ATTACH_ACCOUNTING_DOCUMENT_ATTACHMENT',
	'FINISH_REMOVE_ACCOUNTING_DOCUMENT_ATTACHMENT',
]

function loadIssuesListener(action: Action, getState: () => State, dispatch: Dispatch<any>) {
	// $FlowFixMe - cannot type action here
	const accountingDocumentId = action.accountingDocumentId

	if (accountingDocumentId) {
		dispatch(loadIssues(accountingDocumentId))
	}
}

const listeners: Array<Listener> = loadIssuesActionTypes.map((actionType: string) => ({
	actionType,
	listener: loadIssuesListener,
}))

export default listeners
