/* @flow */
/** @jsx jsx */

import { PureComponent } from 'react'
import { jsx } from '@emotion/core'
import memoize from 'memoize-one'
import CloseIcon from 'components/svg-icons/trivi/nested-close'
import OpenIcon from 'components/svg-icons/trivi/nested-open'
import Folder from 'components/svg-icons/file/folder'
import IconButton from 'components/icon-button'
import FileIcon from 'components/file-icon'
import DocumentTreeNestedList from './document-tree-nested-list'
import { colors } from 'variables'

type Props = {|
	id: ?string,
	isFolder: boolean,
	isSelected: ?boolean,
	nestedItems: ?Array<?React$Element<any>>,
	nestedLevel?: number,
	fileName: ?string,
	onClick: (uniqueId: ?string, isFolder: boolean) => void,
	onNestedListToggle?: (boolean, ?string) => void,
|}

type State = {|
	open: boolean,
|}

class DocumentTreeItem extends PureComponent<Props, State> {
	state = {
		open: false,
	}

	handleClick = () => {
		this.props.onClick(this.props.id, this.props.isFolder)
	}

	handleNestedListToggle = (event: SyntheticEvent<HTMLElement>) => {
		event.stopPropagation()

		this.setState({ open: !this.state.open }, () => {
			this.props.onNestedListToggle && this.props.onNestedListToggle(!this.state.open, this.props.id)
		})
	}

	handleNestedListButtonClick = (event: SyntheticMouseEvent<HTMLElement>) => {
		event.stopPropagation()
	}

	getStyles = memoize((isFolder: boolean, isSelected: ?boolean, nestedLevel: ?number, showNestedButton: boolean) => {
		return {
			root: {
				color: colors.black,
				cursor: 'pointer',
				display: 'block',
				fontSize: 14,
				lineHeight: '16px',
				position: 'relative',
				opacity: !isFolder ? 0.8 : 1,
				pointerEvents: !isFolder ? 'none' : 'all',
				backgroundColor: isSelected ? colors.blueFaded10 : colors.white,
				fontWeight: isSelected ? 'bold' : 'normal',
				'&:hover': {
					backgroundColor: colors.blueFaded10,
					color: colors.blue,
				},
			},
			innerDiv: {
				marginLeft: (nestedLevel || 0) * 20,
				position: 'relative',
				overflow: 'visible',
				padding: '0 20px',
				lineHeight: '30px',
				display: 'flex',
				alignItems: 'center',
			},
			icon: {
				height: showNestedButton ? 20 : 24,
				width: showNestedButton ? 20 : 24,
				position: showNestedButton ? 'relative' : 'absolute',
				top: 0,
				margin: showNestedButton ? '5px 8px 5px -2px' : 12,
				transition: 'none',
				lineHeight: '24px',
				textAlign: 'center',
				display: showNestedButton ? 'inline-block' : 'flex',
				verticalAlign: 'middle',
				alignItems: 'center',
				justifyContent: 'center',
				left: showNestedButton ? 0 : 10,
				fill: showNestedButton ? colors.grey900 : undefined,
				flex: '0 0 24px',
			},
			fileIcon: {
				flex: '0 0 24px',
			},
			label: {
				cursor: 'pointer',
			},
			fileName: {
				display: 'block',
				verticalAlign: 'middle',
				width: 'auto',
				overflow: 'hidden',
				whiteSpace: 'nowrap',
				textOverflow: 'ellipsis',
			},
			nestedListIconButton: {
				width: 30,
				height: 30,
				flex: '0 0 30px',
				position: 'relative',
				verticalAlign: 'middle',
			},
		}
	})

	render() {
		const showNestedButton = !!(this.props.nestedItems && this.props.nestedItems.length) || this.props.isFolder
		const styles = this.getStyles(this.props.isFolder, this.props.isSelected, this.props.nestedLevel, showNestedButton)

		const nestedListIconButton = this.state.open ? <CloseIcon /> : <OpenIcon />

		const nestedList =
			this.props.nestedItems && this.props.nestedItems.length ? (
				<DocumentTreeNestedList nestedLevel={this.props.nestedLevel || 0} open={this.state.open}>
					{this.props.nestedItems}
				</DocumentTreeNestedList>
			) : (
				undefined
			)

		return (
			<div>
				<div onClick={this.handleClick} css={styles.root}>
					<div css={styles.innerDiv}>
						{showNestedButton ? (
							<IconButton style={styles.nestedListIconButton} onClick={this.handleNestedListToggle}>
								{nestedListIconButton}
							</IconButton>
						) : null}
						{this.props.isFolder ? (
							<Folder style={styles.icon} size={20} color={colors.gray} hoverColor={colors.blue} />
						) : (
							<div css={styles.fileIcon}>
								<FileIcon fileName={this.props.fileName} size={20} />
							</div>
						)}
						<div css={styles.fileName}>{this.props.fileName}</div>
					</div>
				</div>
				{nestedList}
			</div>
		)
	}
}

export default DocumentTreeItem
