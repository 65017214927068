/* @flow */

import React, { Component } from 'react'
import moment from 'moment'
import type { Currency } from 'types'
import { parseFloatOr, parseIntOr } from 'utils/math'
import CurrencySelector from 'modules/common/containers/currency-selector'
import { DatePicker } from 'components'
import TextField from 'components/TextField'
import NumberInput from 'components/number-input'
import { colors } from 'variables'

export type Props = {|
	value: string,
	interpreter: string,
	onChange: (value: ?string) => void,
	onSave: (hotkey?: string) => void,
	onCancel: (hotkey?: string) => void,
|}

class DocumentFieldEditor extends Component<Props> {
	render() {
		const { value, interpreter } = this.props
		const commonProps = {
			compact: true,
			autoFocus: true,
			style: { borderColor: colors.blue },
			onKeyEnter: this.onSave,
			onKeyEscape: this.onCancel,
		}
		const textFieldProps = {
			...commonProps,
			value,
			onChange: this.onChange,
		}

		switch (interpreter) {
			case 'emailInterpreter':
				return <TextField {...textFieldProps} type="email" />
			case 'currencyInterpreter':
				return <CurrencySelector fullWidth compact onChange={this.onCurrencyChange} value={value} autoFocus />
			case 'dateInterpreter':
				return (
					<DatePicker
						fullWidth
						compact
						value={this.toDate(value)}
						onChange={this.onDateChange}
						onKeyEscape={this.onCancel}
						onKeyEnter={this.onSave}
						autoFocus
					/>
				)
			case 'vatRateInterpreter':
			case 'decimalInterpreter':
				return <NumberInput {...commonProps} onChange={this.onNumberChange} value={parseFloatOr(value)} precision={2} />
			case 'zipCodeInterpreter':
			case 'integerInterpreter':
				return <NumberInput {...commonProps} onChange={this.onNumberChange} value={parseIntOr(value)} />
			case 'regNoInterpreter':
			case 'bankAccountInterpreter':
			case 'taxNoInterpreter':
			case 'plainTextInterpreter':
			case 'alphaInterpreter':
			case 'pureAlphaInterpreter':
			default:
				return <TextField {...textFieldProps} />
		}
	}

	toDate = (stringDate: string): ?Date => {
		const momentDate = moment(stringDate)
		if (momentDate.isValid()) {
			return momentDate.toDate()
		}
	}

	onDateChange = (event: ?SyntheticInputEvent<HTMLInputElement>, date: ?Date) => {
		this.props.onChange(date ? moment(date).format('YYYY-M-D') : null)
	}

	onCurrencyChange = (currency: ?Currency) => {
		this.props.onChange((currency && currency.id) || '')
	}

	onChange = (event: SyntheticInputEvent<HTMLInputElement>) => {
		this.props.onChange(event.target.value)
	}

	onNumberChange = (event: SyntheticInputEvent<HTMLInputElement>, value: ?number) => {
		this.props.onChange((value || '').toString())
	}

	onSave = () => {
		this.props.onSave('enter')
	}

	onCancel = () => {
		this.props.onCancel('esc')
	}
}

export default DocumentFieldEditor
