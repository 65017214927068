import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styles from './linear-progress.css'
import { colors } from 'variables'

function getRelativeValue(value, min, max) {
	const clampedValue = Math.min(Math.max(min, value), max)
	const rangeValue = max - min
	const relValue = Math.round(((clampedValue - min) / rangeValue) * 10000) / 10000
	return relValue * 100
}

function getStyles(props) {
	const { max, min, value, backgroundColor, outlineColor, small, tall, rounded, animated } = props
	const offset = small ? 0 : 2
	const styles = {
		root: {
			position: 'relative',
			height: small ? 5 : tall ? 13 : 11,
			display: 'block',
			backgroundColor: outlineColor || colors.white,
			borderRadius: small ? 0 : rounded ? (tall ? 6 : 5) : 2,
			overflow: 'hidden',
			padding: small ? 0 : 2,
		},
		barBackground: {
			backgroundColor: backgroundColor || 'transparent',
			position: 'absolute',
			top: offset,
			left: offset,
			bottom: offset,
			right: offset,
			height: small ? 5 : tall ? 9 : 7,
			borderRadius: rounded ? (small ? 2 : tall ? 4 : 3) : 0,
			backgroundRepeat: 'repeat-x',
			backgroundSize: '18px 18px',
			backgroundPosition: '0px -2px',
			backgroundImage: animated
				? 'linear-gradient(45deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 45%, rgba(0, 0, 0, 0.04) 45%, rgba(0, 0, 0, 0.04) 55%, rgba(0, 0, 0, 0) 55%, rgba(0, 0, 0, 0) 100%)'
				: 'none',
		},

		barContainer: {
			position: 'absolute',
			top: offset,
			left: offset,
			bottom: offset,
			right: offset,
			height: small ? 5 : tall ? 9 : 7,
			borderRadius: rounded ? (small ? 2 : tall ? 4 : 3) : 0,
			overflow: 'hidden',
		},
		bar: {
			position: 'absolute',
			top: 0,
			left: 0,
			bottom: 0,
			right: 0,
		},
		barFragment1: {},
		barFragment2: {},
	}

	if (props.mode === 'indeterminate') {
		styles.barFragment1 = {
			position: 'absolute',
			backgroundColor: props.color || colors.blue,
			top: offset,
			left: offset,
			bottom: offset,
			transition: 'all 840ms cubic-bezier(0.650, 0.815, 0.735, 0.395)',
		}

		styles.barFragment2 = {
			position: 'absolute',
			backgroundColor: props.color || colors.blue400,
			top: offset,
			left: offset,
			bottom: offset,
			transition: 'all 840ms cubic-bezier(0.165, 0.840, 0.440, 1.000)',
		}
	} else {
		styles.bar.backgroundColor = props.color || colors.blue
		styles.bar.transition = 'width 300ms linear'
		styles.bar.width = `${getRelativeValue(value, min, max)}%`
	}

	return styles
}

class LinearProgress extends Component {
	static propTypes = {
		/**
		 * The color of the progress bar, defaults to
		 * primary color of theme.
		 */
		className: PropTypes.string,
		animated: PropTypes.bool,
		color: PropTypes.string,
		backgroundColor: PropTypes.string,
		outlineColor: PropTypes.string,
		small: PropTypes.bool,
		/**
		 * The max value of progress, only works in determinate mode.
		 */
		max: PropTypes.number,
		/**
		 * The min value of progress, only works in determinate mode.
		 */
		min: PropTypes.number,
		/**
		 * The mode of show your progress, indeterminate for when
		 * there is no value for progress.
		 */
		mode: PropTypes.oneOf(['determinate', 'indeterminate']),
		/**
		 * Override the inline-styles of the root element.
		 */
		style: PropTypes.object,
		/**
		 * The value of progress, only works in determinate mode.
		 */
		value: PropTypes.number,
		rounded: PropTypes.bool,
	}

	static defaultProps = {
		mode: 'indeterminate',
		animated: false,
		value: 0,
		min: 0,
		max: 100,
		rounded: false,
	}

	componentDidMount() {
		this.timers = {}

		this.timers.bar1 = this.barUpdate(
			'bar1',
			0,
			this.refs.bar1,
			[
				[-35, 100],
				[100, -90],
			],
			0,
		)

		this.timers.bar2 = setTimeout(() => {
			this.barUpdate(
				'bar2',
				0,
				this.refs.bar2,
				[
					[-200, 100],
					[107, -8],
				],
				0,
			)
		}, 850)
	}

	componentWillUnmount() {
		clearTimeout(this.timers.bar1)
		clearTimeout(this.timers.bar2)
	}

	barUpdate(id, step, barElement, stepValues, timeToNextStep) {
		if (this.props.mode !== 'indeterminate') return

		timeToNextStep = timeToNextStep || 420
		step = step || 0
		step %= 4

		if (step === 0) {
			barElement.style.left = `${stepValues[0][0]}%`
			barElement.style.right = `${stepValues[0][1]}%`
		} else if (step === 1) {
			barElement.style.transitionDuration = '840ms'
		} else if (step === 2) {
			barElement.style.left = `${stepValues[1][0]}%`
			barElement.style.right = `${stepValues[1][1]}%`
		} else if (step === 3) {
			barElement.style.transitionDuration = '0ms'
		}
		this.timers[id] = setTimeout(() => this.barUpdate(id, step + 1, barElement, stepValues), timeToNextStep)
	}

	render() {
		const style = getStyles(this.props)

		return (
			<div className={this.props.className} style={Object.assign(style.root, this.props.style)}>
				<div
					className={this.props.animated ? styles.animatedBarBackground : styles.barBackground}
					style={style.barBackground}
				/>
				<div style={style.barContainer}>
					<div style={style.bar}>
						<div ref="bar1" style={style.barFragment1} />
						<div ref="bar2" style={style.barFragment2} />
					</div>
				</div>
			</div>
		)
	}
}

export default LinearProgress
