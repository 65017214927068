import React from 'react'
import SvgIcon from 'components/SvgIcon'
import pure from 'recompose/pure'

let LargePng = props => {
	props = {
		viewBox: '0 0 24 30',
		...props,
	}
	return (
		<SvgIcon {...props}>
			<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
				<g transform="translate(-615.000000, -10404.000000)">
					<g id="png-ico-24" transform="translate(615.000000, 10404.000000)">
						<path
							d="M2.985,0 C1.335,0 0,1.35 0,3 L0.015,27 C0.015,28.65 1.35,30 3,30 L15,30 L24,21 L24,3 C24,1.35 22.65,0 21,0 L2.985,0 Z"
							fill="#34424E"
							transform="translate(12.000000, 15.000000) scale(1, -1) translate(-12.000000, -15.000000) "
						/>
						<path
							d="M4.688,25.496 C4.304,25.496 4,25.176 4,24.8 L4,20.816 C4,20.344 4.256,20.088 4.728,20.088 L6.672,20.088 C7.872,20.088 8.528,20.896 8.528,21.864 C8.528,22.824 7.872,23.616 6.672,23.616 L5.376,23.616 L5.376,24.8 C5.376,25.176 5.072,25.496 4.688,25.496 Z M7.128,21.856 C7.128,21.488 6.856,21.248 6.488,21.248 L5.376,21.248 L5.376,22.448 L6.488,22.448 C6.856,22.448 7.128,22.216 7.128,21.856 Z M13.256,25.496 C13.728,25.496 14.104,25.112 14.104,24.656 L14.104,20.712 C14.104,20.336 13.792,20.016 13.408,20.016 C13.032,20.016 12.72,20.336 12.72,20.712 L12.72,23.16 L10.784,20.408 C10.616,20.168 10.352,20.016 10.048,20.016 C9.544,20.016 9.144,20.432 9.144,20.92 L9.144,24.8 C9.144,25.176 9.448,25.496 9.832,25.496 C10.216,25.496 10.52,25.176 10.52,24.8 L10.52,22.2 L12.568,25.136 C12.72,25.352 12.976,25.496 13.256,25.496 Z M19.424,22.36 L17.968,22.36 C17.656,22.36 17.392,22.664 17.392,22.976 C17.392,23.296 17.656,23.568 17.968,23.568 L18.664,23.568 L18.664,23.984 C18.48,24.152 18.144,24.304 17.68,24.304 C16.808,24.304 16.192,23.632 16.192,22.76 C16.192,21.888 16.808,21.216 17.68,21.216 C18.04,21.216 18.336,21.368 18.568,21.584 C18.68,21.688 18.84,21.752 19.008,21.752 C19.36,21.752 19.648,21.472 19.648,21.112 C19.648,20.92 19.56,20.736 19.416,20.624 C19,20.248 18.44,20 17.68,20 C16.088,20 14.792,21.064 14.792,22.76 C14.792,24.456 16.088,25.52 17.68,25.52 C18.48,25.52 19.136,25.264 19.656,24.84 C19.944,24.6 20.04,24.32 20.04,23.952 L20.04,22.992 C20.04,22.64 19.776,22.36 19.424,22.36 Z"
							fill="#FFFFFF"
						/>
						<polygon
							fill="#FFA409"
							transform="translate(19.500000, 4.500000) rotate(-90.000000) translate(-19.500000, -4.500000) "
							points="15 9 15 0 24 0"
						/>
					</g>
				</g>
			</g>
		</SvgIcon>
	)
}
LargePng = pure(LargePng)

export default LargePng
