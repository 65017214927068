/* @flow */

import React from 'react'
import Helmet from 'react-helmet'
import { withRouter } from 'react-router'
import type { WithRouterProps } from 'types'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import AccountingDocumentLoader from '../containers/accounting-document-loader'

function CreateAccountingDocument(props: {| ...WithRouterProps, ...WithTranslateProps |}) {
	return (
		<div>
			<Helmet>
				<title>{props.t('accountingDocument.edit.headline')}</title>
			</Helmet>
			<AccountingDocumentLoader type={props.match.params.type} direction={props.match.params.direction} />
		</div>
	)
}

export default withTranslate(withRouter(CreateAccountingDocument))
