/* @flow */

import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import Context from 'lib/context'
import type { WithRouterProps } from 'types'
import { showSplashscreen, hideSplashscreen } from 'utils/splashscreen'

export type Props = WithRouterProps & {|
	organizationId: ?string,
	readyToLoadBootstrap: boolean,
	bootstrapLoaded: boolean,
	loadBootstrap: (organizationId: ?string) => Promise<void>,
	changeCurrentOrganization: (organizationId: string) => void,
	children: React$Element<any>,
|}

class AppComponent extends Component<Props> {
	waitingForBootstrap = false
	lastParamsOrganizationIdString = undefined

	triviContext: Context

	constructor(props: Props) {
		super(props)
		this.triviContext = new Context(this.props.organizationId)
	}

	getChildContext() {
		return {
			triviContext: this.triviContext,
		}
	}

	componentDidMount() {
		this.init(this.props)
	}

	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		this.init(nextProps, this.props)

		if (this.props.organizationId !== nextProps.organizationId) {
			this.triviContext.setOrganizationId(nextProps.organizationId)
		}

		if (!this.waitingForBootstrap || (nextProps.bootstrapLoaded && !this.props.bootstrapLoaded)) {
			hideSplashscreen()
		}

		window.previousLocation = this.props.location.pathname
	}

	shouldComponentUpdate(nextProps: Props) {
		return this.props.children !== nextProps.children
	}

	init(props: Props, lastProps: ?Props) {
		const { readyToLoadBootstrap, organizationId, loadBootstrap, changeCurrentOrganization, bootstrapLoaded } = props

		const paramsOrganizationId: string = props.match.params.organizationId
		const paramsOrganizationIdInt = parseInt(paramsOrganizationId, 10)
		const paramsOrganizationIdString = paramsOrganizationIdInt ? paramsOrganizationIdInt.toString() : undefined

		if (paramsOrganizationIdString && this.lastParamsOrganizationIdString !== paramsOrganizationIdString) {
			changeCurrentOrganization(paramsOrganizationIdString) // When organizationId in URL changes
		}
		this.lastParamsOrganizationIdString = paramsOrganizationIdString

		if (readyToLoadBootstrap && (!lastProps || !lastProps.readyToLoadBootstrap) && !bootstrapLoaded) {
			loadBootstrap(paramsOrganizationIdString || organizationId) // When authenticated
			this.waitingForBootstrap = true
			showSplashscreen()
		} else if (bootstrapLoaded && (!lastProps || organizationId !== lastProps.organizationId)) {
			loadBootstrap(organizationId) // When organizationId changes
		}
	}

	render() {
		const { children } = this.props
		const content = children ? React.cloneElement(children, this.props) : null
		return content
	}
}

AppComponent.childContextTypes = {
	triviContext: PropTypes.object,
}

export default withRouter(AppComponent)
