//@flow
import React, { Component } from 'react'
import withTranslate, { type Props as WithTranslateProps } from 'wrappers/with-translate'
import withNotify, { type Props as WithNotifyProps } from 'modules/common/wrappers/with-notify'
import TextField from 'components/TextField/TextField'
import IconButton from 'components/icon-button'
import CloseIcon from 'components/svg-icons/navigation/close'
import { colors } from 'variables'

type Props = {|
	...WithTranslateProps,
	...WithNotifyProps,
	isOpen: boolean,
	onSubmit: (contact: string) => Promise<any>,
	onClose: () => void,
|}

type State = {|
	contact: string,
|}

class Dialog extends Component<Props, State> {
	state: State = {
		contact: '',
	}

	handleContactChange = (event: SyntheticInputEvent<HTMLInputElement>, contact: ?string) => {
		contact = contact !== null && contact !== undefined ? contact : ''
		if (contact.length > 255) {
			return
		}
		this.setState({ contact })
	}

	onSubmit = () => {
		this.props.onSubmit(this.state.contact).then(() => {
			this.setState({ contact: '' })
			this.props.notify(this.props.t('contactMe.submitSucces'), 'success')
		})
	}

	render() {
		const { t, isOpen, onClose } = this.props
		const { contact } = this.state
		const styles = getStyles(this.props, this.state)

		if (!isOpen) {
			return null
		}

		return (
			<div style={styles.bar}>
				<div style={styles.window}>
					<div style={styles.windowHeader}>
						<div style={styles.windowTitle}>{t('contactMe.dialogTitle')}</div>
						<div style={styles.close}>
							<IconButton
								autoTestId="dialog-close"
								onClick={onClose}
								color={colors.blackFaded80}
								hoverColor={colors.black}
							>
								<CloseIcon size={20} />
							</IconButton>
						</div>
					</div>
					<div style={styles.windowContent}>
						<div style={styles.input}>
							<TextField
								fullWidth
								name="contact"
								labelText={t('contactMe.inputTitle')}
								hintText={t('contactMe.inputHintText')}
								autoTestId="contact-me-title"
								onChange={this.handleContactChange}
								value={contact}
								autoComplete={false}
							/>
						</div>
						<div style={styles.buttonWrapper}>
							<div style={styles.button} onClick={this.onSubmit}>
								{t('contactMe.buttonLabel')}
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

function getStyles(props: Props, state: State) {
	const buttonDisabled = !(state.contact && state.contact.length > 0)

	return {
		bar: {
			position: 'absolute',
			bottom: 20,
			right: 150,
			display: 'flex',
			flexWrap: 'nowrap',
			flexDirection: 'row-reverse',
			alignItems: 'flex-end',
			width: '100%',
			height: 0,
			zIndex: 10,
		},
		window: {
			width: 380,
			background: colors.white,
			marginLeft: 10,
			display: 'flex',
			flexDirection: 'column',
			position: 'relative',
			fontSize: 14,
			boxShadow: '7px 7px 0 0 rgba(0, 0, 0, 0.08)',
			borderRadius: 6,
		},
		windowContent: {
			display: 'flex',
			flexDirection: 'column',
			borderLeft: `1px solid ${colors.grey400}`,
			borderRight: `1px solid ${colors.grey400}`,
			borderBottom: `1px solid ${colors.grey400}`,
			borderRadius: '0 0 6px 6px',
			overflow: 'hidden',
		},
		windowHeader: {
			position: 'relative',
			color: colors.black,
			padding: '10px 50px 10px 17px',
			height: 70,
			display: 'flex',
			flex: '0 0 auto',
			flexDirection: 'column',
			justifyContent: 'center',
			fontSize: 16,
			borderRadius: '6px 6px 0 0',
			cursor: 'pointer',
			background: '#FFE01B',
			fontWeight: 'bold',
		},
		windowHeaderIcon: {
			position: 'absolute',
			top: '50%',
			left: 18,
			transform: 'translateY(-50%)',
		},
		windowTitle: {
			userSelect: 'none',
		},
		close: {
			position: 'absolute',
			top: 15,
			right: 10,
		},
		input: {
			padding: '20px 15px 0px',
		},
		buttonWrapper: {
			padding: '20px 15px 20px',
			textAlign: 'center',
		},
		button: {
			color: colors.black,
			padding: '0 22px',
			display: 'inline-block',
			height: 32,
			lineHeight: '32px',
			flexDirection: 'column',
			justifyContent: 'center',
			fontSize: 12,
			textTransform: 'uppercase',
			borderRadius: 15,
			cursor: 'pointer',
			background: '#FFE01B',
			fontWeight: 'bold',
			boxShadow: buttonDisabled ? 'none' : '3px 3px 0 0 rgba(0,0,0,0.08)',
			opacity: buttonDisabled ? 0.5 : 1,
			pointerEvents: buttonDisabled ? 'none' : 'all',
		},
	}
}

export default withTranslate(withNotify(Dialog))
