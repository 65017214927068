/* @flow */
/** @jsx jsx */

import { Component } from 'react'
import { jsx } from '@emotion/core'
import type { FinishSignUp } from 'modules/user/actions/action-types'
import {
	withNotify,
	withTranslate,
	validate,
	type FormValidationProps,
	type WithNotifyProps,
	type WithTranslateProps,
} from 'wrappers'
import { autoTestId } from 'utils/tests/autotest'
import Button from 'components/button'
import TextField from 'components/TextField'
import EmailInput from 'components/email-input'
import Loader from 'components/loader'
import { Validator } from 'utils'

export type Props = {|
	onRegister: (firstName: string, lastName: string, email: string, password: string) => Promise<FinishSignUp>,
	onSuccess?: (firstName: string, lastName: string, email: string) => void,
	invitationToken?: ?string,
	invitationEmail?: ?string,
|}

type State = {|
	firstname: ?string,
	lastname: ?string,
	email: string,
	password: string,
	loading: boolean,
|}

type ComponentProps = {| ...Props, ...WithTranslateProps, ...WithNotifyProps, ...FormValidationProps |}

class RegisterForm extends Component<ComponentProps, State> {
	state: State = {
		firstname: '',
		lastname: '',
		email: '',
		password: '',
		loading: false,
	}

	validateForm = (): Object => {
		const { t, invitationToken } = this.props
		const { email, password, firstname, lastname } = this.state
		const errors = {}

		if (!this.isFilledText(firstname)) {
			errors.firstname = t('clientError.noField', { field: t('fields.name') })
		}
		if (!this.isFilledText(lastname)) {
			errors.lastname = t('clientError.noField', { field: t('fields.surname') })
		}

		if (!invitationToken && !email) {
			errors.email = t('clientError.noField', { field: t('fields.email') })
		} else if (email && !Validator.email(email)) {
			errors.email = t('clientError.invalidValue', { field: t('fields.email') })
		}

		if (invitationToken) {
			if (!password) {
				errors.password = t('clientError.noField', { field: t('fields.password') })
			} else if (!Validator.password(password)) {
				errors.password = t('clientError.newPasswordInvalid')
			}
		}

		return errors
	}

	handleFormSubmit = async () => {
		const { invitationToken } = this.props
		const { email, password, firstname, lastname } = this.state
		let firstnameText = this.getText(firstname)
		let lastnameText = this.getText(lastname)

		this.setState({ loading: true })
		const result: FinishSignUp = await this.props.onRegister(firstnameText, lastnameText, email, password)
		this.setState({ loading: false })

		if (!invitationToken && !result.serverError) {
			this.props.onSuccess && this.props.onSuccess(firstnameText, lastnameText, email)
		}
	}

	handleFirstnameChange = (event: SyntheticInputEvent<HTMLInputElement>, firstname: ?string) => {
		this.setState({ firstname })
	}
	handleLastnameChange = (event: SyntheticInputEvent<HTMLInputElement>, lastname: ?string) => {
		this.setState({ lastname })
	}

	handleEmailChange = (event: SyntheticInputEvent<HTMLInputElement>, email: ?string) => {
		if (email !== null && email !== undefined) {
			this.setState({ email })
		}
	}

	handlePasswordChange = (event: SyntheticInputEvent<HTMLInputElement>, password: ?string) => {
		if (password !== null && password !== undefined) {
			this.setState({ password })
		}
	}

	getText = (value: ?string) => (typeof value === 'string' ? value.trim() : '')

	isFilledText = (value: ?string) => value && value.match(/^\s*$/) === null

	renderFields() {
		const { t, invitationToken, invitationEmail } = this.props
		const disabled = this.state.loading

		this.props.validateForm(this.validateForm())

		return (
			<div css={styles.fields}>
				{invitationEmail && (
					<div css={styles.field}>
						<TextField
							fullWidth
							disabled
							value={invitationEmail}
							name="register-form-email"
							autoTestId="register-form-email"
						/>
					</div>
				)}

				<div css={styles.field}>
					<TextField
						disabled={disabled}
						fullWidth
						name="firstname"
						hintText={t('user.register.firstNameLabel')}
						onChange={this.handleFirstnameChange}
						value={this.props.validationValue('firstname', this.state.firstname)}
						autoComplete={false}
						clientError={this.props.validationMessage('firstname')}
						autoTestId="register-firstname"
					/>
				</div>

				<div css={styles.field}>
					<TextField
						disabled={disabled}
						fullWidth
						name="lastname"
						hintText={t('user.register.lastNameLabel')}
						onChange={this.handleLastnameChange}
						value={this.props.validationValue('lastname', this.state.lastname)}
						autoComplete={false}
						clientError={this.props.validationMessage('lastname')}
						autoTestId="register-lastname"
					/>
				</div>

				{!invitationToken && (
					<div css={styles.field}>
						<EmailInput
							disabled={disabled}
							fullWidth
							name="email"
							type="email"
							hintText={t('user.register.emailLabel')}
							onChange={this.handleEmailChange}
							value={this.props.validationValue('email', invitationToken ? invitationEmail : this.state.email)}
							clientError={this.props.validationMessage('email')}
							autoTestId="register-email"
						/>
					</div>
				)}

				{invitationToken && (
					<div css={styles.field}>
						<TextField
							disabled={disabled}
							fullWidth
							name="password"
							type="password"
							hintText={t('user.register.passwordLabel')}
							onChange={this.handlePasswordChange}
							value={this.props.validationValue('password', this.state.password)}
							clientError={this.props.validationMessage('password')}
							autoComplete={false}
							autoTestId="register-password"
						/>
						<div css={styles.instructions}>{t('user.register.newPasswordInstructions')}</div>
					</div>
				)}
				{this.state.loading && <Loader size={60} visible />}
			</div>
		)
	}

	render() {
		const { t } = this.props
		this.props.validateForm(this.validateForm())

		return (
			<div css={styles.root} {...autoTestId('register-form')}>
				{this.renderFields()}
				<Button
					disabled={this.state.loading}
					fullWidth
					tall
					name="sign-up"
					type="submit"
					onClick={this.props.validationSubmit(this.handleFormSubmit)}
					autoTestId="register-signup"
					labelText={
						this.props.invitationToken
							? t('user.register.continueInvitationButtonLabel')
							: t('user.register.continueButtonLabel')
					}
				/>
			</div>
		)
	}
}

const styles = {
	root: {
		margin: '40px auto',
		textAlign: 'center',
		maxWidth: 340,
		width: '100%',
	},
	fields: {
		position: 'relative',
		marginBottom: 10,
	},
	field: {
		marginBottom: 25,
	},
	instructions: {
		opacity: '0.6',
		color: 'black',
		fontSize: '12px',
		lineHeight: '15px',
		margin: '10px auto',
		textAlign: 'left',
	},
}

export default withTranslate(withNotify(validate('FINISH_SIGN_UP')(RegisterForm)))
