import React from 'react'
import pure from 'recompose/pure'
import SvgIcon from 'components/SvgIcon'

let Image = props => {
	props = Object.assign({ viewBox: '-4 -2 24 24' }, props)
	return (
		<SvgIcon {...props}>
			<path
				d="M1.99,0 C0.89,0 0,0.9 0,2 L0.01,18 C0.01,19.1 0.9,20 2,20 L10,20 L16,14 L16,2 C16,0.9 15.1,0 14,0 L1.99,0 Z"
				fill="#34424E"
				transform="translate(8.000000, 10.000000) scale(1, -1) translate(-8.000000, -10.000000) "
			/>
			<path
				d="M12.8,10 C12.3581728,10 12,10.358172 12,10.8 C12,11.241828 12.3581728,11.6 12.8,11.6 C13.241828,11.6 13.6,11.241828 13.6,10.8 C13.6,10.358172 13.241828,10 12.8,10 L12.8,10 Z"
				fill="#FFFFFF"
			/>
			<polygon
				fill="#FFFFFF"
				points="6.775 11 6.2 11.55 3 14.75 4.15 15.9 6.775 13.275 8.6 15.1 9.175 15.65 9.75 15.1 10.775 14.075 12.6 15.9 13.75 14.75 11.35 12.35 10.775 11.8 10.2 12.35 9.175 13.375 7.35 11.55"
			/>
			<polygon
				fill="#FFA409"
				transform="translate(13.000000, 3.000000) rotate(-90.000000) translate(-13.000000, -3.000000) "
				points="10 6 10 0 16 0"
			/>
		</SvgIcon>
	)
}
Image = pure(Image)

export default Image
