/* @flow */

import { connect } from 'react-redux'
import type {
	State,
	Dispatch,
	TemplateScheduler,
	AccountingDocument,
	FormFieldErrorMessage,
	FormFieldErrorContext,
} from 'types'
import SchedulerSettings from '../../components/invoice-settings/scheduler-settings'
import { getFormFieldErrors } from 'modules/common/selectors'
import { removeError } from 'helpers'
import { removeErrors } from '../../helpers/templates'

type OwnProps = {|
	contactEmail: ?string,
	contactEmail: ?string,
	templateId?: ?string,
	templateScheduler: ?TemplateScheduler,
	accountingDocument?: AccountingDocument,
	formFieldErrorContext?: FormFieldErrorContext,
	onChange: (templateScheduler: ?TemplateScheduler, sendEmailAutomatically: boolean) => void,
	onBack: () => void,
|}

type StateProps = {|
	errors?: ?Array<FormFieldErrorMessage>,
|}

function mapStateToProps(state: State, ownProps: OwnProps): StateProps {
	return {
		errors: ownProps.formFieldErrorContext && getFormFieldErrors(state, ownProps.formFieldErrorContext),
	}
}

type DispatchProps = {|
	removeError?: () => void,
	removeTemplateErrors?: () => void,
|}

function mapDispatchToProps(dispatch: Dispatch<*>, ownProps: OwnProps): DispatchProps {
	return {
		removeError: () => {
			removeError({
				formFieldErrorContext: ownProps.formFieldErrorContext,
				dispatch,
			})
		},
		removeTemplateErrors: () => {
			if (ownProps.templateId) {
				removeErrors(ownProps.templateId, ownProps.accountingDocument, dispatch)
			}
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(SchedulerSettings)
