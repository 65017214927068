/* @flow */

import React, { Component } from 'react'
import PaymentType from '../invoice-elements/payment-type'
import type { AccountingDocument, FormFieldErrorContext } from 'types'
import { emptyAccountingDocument } from 'types/empty'

type Props = {|
	accountingDocument: AccountingDocument,
	formFieldErrorContext?: FormFieldErrorContext,
	onChange: (accountingDocument: AccountingDocument) => void,
|}

export default class InvoiceContactTemplate extends Component<Props> {
	onPaymentTypeChange = (paymentType: number) => {
		const newDocument: AccountingDocument = Object.assign(emptyAccountingDocument(), this.props.accountingDocument, {
			paymentType,
		})
		this.props.onChange(newDocument)
	}

	render() {
		return (
			<PaymentType
				formFieldErrorContext={this.props.formFieldErrorContext}
				paymentType={this.props.accountingDocument.paymentType}
				onPaymentTypeChange={this.onPaymentTypeChange}
				readonly={false}
			/>
		)
	}
}
