/* @flow */

import React, { Component } from 'react'
import { emptyAccountingDocument } from 'types/empty'
import type { AccountingDocument, Signature } from 'types'
import SignaturePicker from 'modules/user/containers/signature-picker'

type Props = {|
	accountingDocument: AccountingDocument,
	onChange: (accountingDocument: AccountingDocument) => void,
|}

export default class InvoiceSignatureTemplate extends Component<Props> {
	onChange = (signature: ?Signature) => {
		const newDocument: AccountingDocument = Object.assign(emptyAccountingDocument(), this.props.accountingDocument, {
			signatureId: signature ? signature.id : '',
		})
		this.props.onChange(newDocument)
	}

	render() {
		return <SignaturePicker value={this.props.accountingDocument.signatureId} onChange={this.onChange} />
	}
}
