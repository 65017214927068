//@flow

import React, { Component } from 'react'
import ContactGrid from '../containers/contact-grid'
import styles from './address-book-list.css'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import Helmet from 'react-helmet'

type Props = {
	router: {
		push: string => void,
	},
}

class AddressBookList extends Component<WithTranslateProps & Props> {
	render() {
		const { t } = this.props
		return (
			<div className={styles.root} id={'trivi-address-book'}>
				<Helmet>
					<title>{this.props.t('addressBook.headline')}</title>
				</Helmet>
				<h1>{t('addressBook.headline')}</h1>
				<ContactGrid />
			</div>
		)
	}
}

export default withTranslate(AddressBookList)
