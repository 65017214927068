/* @flow */

import { combineReducers } from 'redux'
import { Reducers as gridReducers } from 'react-redux-grid'
import { routerReducer } from 'react-router-redux'
import { nprogress } from 'redux-nprogress'

import accountingDocument, { type State as AccountingDocumentState, initialState as accountingDocumentInitialState } from 'modules/accounting-document/reducers'  //eslint-disable-line
import addressBook,        { type State as AddressBookState, initialState as addressBookInitialState }               from 'modules/address-book/reducers'  //eslint-disable-line
import bank,               { type State as BankState, initialState as bankInitialState }                             from 'modules/bank/reducers'  //eslint-disable-line
import cashRegister,       { type State as CashRegisterState, initialState as cashRegisterInitialState }             from 'modules/cash-register/reducers'  //eslint-disable-line
import chat,               { type State as ChatState, initialState as chatInitialState }                             from 'modules/chat/reducers'  //eslint-disable-line
import contactMe,          { type State as ContactMeState, initialState as contactMeInitialState }                   from 'modules/contact-me/reducers'  //eslint-disable-line
import common,             { type State as CommonState, initialState as commonInitialState }                         from 'modules/common/reducers/common'  //eslint-disable-line
import dashboard,          { type State as DashboardState, initialState as dashboardInitialState }                   from 'modules/dashboard/reducers' //eslint-disable-line
import document,           { type State as DocumentState, initialState as documentInitialState }                     from 'modules/document/reducers'  //eslint-disable-line
import filter,             { type State as FilterState, initialState as filterInitialState }                         from 'modules/data-grid/reducers'  //eslint-disable-line
import group,              { type State as GroupState, initialState as groupInitialState }                           from 'modules/groups/reducers'  //eslint-disable-line
import internalDashboard,  { type State as InternalDashboardState, initialState as internalDashboardInitialState }   from 'modules/internal/reducers'  //eslint-disable-line
import navigation,         { type State as NavigationState, initialState as navigationInitialState }                 from 'modules/navigation/reducers'  //eslint-disable-line
import organization,       { type State as OrganizationState, initialState as organizationInitialState }             from 'modules/organization/reducers'  //eslint-disable-line
import settings,           { type State as SettingsState, initialState as settingsInitialState }                     from 'modules/settings/reducers'  //eslint-disable-line
import task,               { type State as TaskState, initialState as taskInitialState }                             from 'modules/task/reducers'  //eslint-disable-line
import user,               { type State as UserState, initialState as userInitialState }                             from 'modules/user/reducers'  //eslint-disable-line
import features,           { type State as FeaturesState, initialState as featuresInitialState }                     from 'modules/features/reducers'  //eslint-disable-line
import documentExtraction, { type State as DocumentExtractionState, initialState as documentExtractionInitialState } from 'modules/document-extraction/reducers'  //eslint-disable-line
import dataGrid,           { type State as DataGridState, initialState as dataGridInitialState }                     from 'modules/data-grid-next/reducers'  //eslint-disable-line
import notifications,      { type State as NotificationsState, initialState as NotificationsInitialState }           from 'modules/notifications/reducers' //eslint-disable-line
import cashbot,            { type State as CashbotState, initialState as cashbotInitialState }                       from 'modules/cashbot/reducers' //eslint-disable-line

type CompanyState = {|
	common: CommonState,
	organization: OrganizationState,
	cashRegister: CashRegisterState,
	bank: BankState,
	document: DocumentState,
	addressBook: AddressBookState,
	accountingDocument: AccountingDocumentState,
	task: TaskState,
	grid: {
		grid: {
			get: (stateKey: string) => { columns: Array<*> } | void,
		},
		pager: {
			get: (stateKey: string) => { pageIndex: number } | void,
		},
		dataSource: {
			get: (stateKey: string) => { //eslint-disable-line
				get: (stateKey: string) => Array<*> | void,
			} | void,
		},
		filter: FilterState,
	},
	dataGrid: DataGridState,
	dashboard: DashboardState,
	settings: SettingsState,
	group: GroupState,
	documentExtraction: DocumentExtractionState,
	notifications: NotificationsState,
|}

export type State = {
	features: FeaturesState,
	company: { [string]: CompanyState },
	user: UserState,
	navigation: NavigationState,
	chat: ChatState,
	contactMe: ContactMeState,
	internalDashboard: InternalDashboardState,
	cashbot: CashbotState,
	...CompanyState,
}

export const initialState: State = {
	features: featuresInitialState,
	company: {},
	user: userInitialState,
	navigation: navigationInitialState,
	chat: chatInitialState,
	contactMe: contactMeInitialState,
	internalDashboard: internalDashboardInitialState,
	common: commonInitialState,
	organization: organizationInitialState,
	cashRegister: cashRegisterInitialState,
	bank: bankInitialState,
	document: documentInitialState,
	addressBook: addressBookInitialState,
	accountingDocument: accountingDocumentInitialState,
	task: taskInitialState,
	grid: {
		grid: {
			get: (stateKey: string) => undefined, // eslint-disable-line
		},
		pager: {
			get: (stateKey: string) => undefined, // eslint-disable-line
		},
		dataSource: {
			get: (stateKey: string) => undefined, // eslint-disable-line
		},
		filter: filterInitialState,
	},
	dataGrid: dataGridInitialState,
	dashboard: dashboardInitialState,
	settings: settingsInitialState,
	group: groupInitialState,
	documentExtraction: documentExtractionInitialState,
	notifications: NotificationsInitialState,
	cashbot: cashbotInitialState,
}

const appReducer = combineCompanyReducers(
	{
		features,
		common,
		user,
		task,
		navigation,
		chat,
		contactMe,
		internalDashboard,
		router: routerReducer,
		grid: combineReducers({ ...gridReducers, filter }),
	},
	{
		organization,
		addressBook,
		cashRegister,
		bank,
		document,
		accountingDocument,
		dashboard,
		settings,
		group,
		documentExtraction,
		dataGrid,
		notifications,
		cashbot,
		nprogress,
	},
)

//TODO (JZ): action tady asi nenatypujem, nemáme už globální moloch Action (nechceme ho)
type Action = any
//---

const rootReducer = (state: ?State, action: Action): State => {
	let newState: ?State = state
	// wipe out state on logout
	if (action.type === 'LOGOUT') {
		newState = undefined
	}

	return appReducer(newState, action)
}

function combineCompanyReducers(reducers: { [string]: any }, companyReducers: { [string]: any }) {
	const reducer: ({}, Action) => State = combineReducers(reducers)
	const companyReducer: (?{}, Action) => CompanyState = combineReducers(companyReducers)

	return function combination(state: any = {}, action: Action): State {
		let companyState: ?CompanyState
		let nextState: State
		const company = state.company || {}
		const baseState = {}
		Object.keys(reducers).forEach((key: string) => (baseState[key] = state[key]))
		const nextBaseState = reducer(baseState, action)

		if (nextBaseState === baseState) {
			nextState = state
		} else {
			nextState = nextBaseState
		}

		const key: string =
			nextState.user && nextState.user.currentOrganizationId ? nextState.user.currentOrganizationId : '-'

		if (key) {
			if (company[key]) {
				companyState = company[key]
			}
			const nextCompanyState: CompanyState = companyReducer(companyState, action)

			if (nextCompanyState !== companyState || nextState !== state) {
				nextState = { ...nextState, ...nextCompanyState }
				nextState.company = { ...company }
				nextState.company[key] = nextCompanyState
			}
		}

		return nextState
	}
}

export default rootReducer
