/* @flow */

import Dashboard from '../containers/dashboard'
import React, { Component } from 'react'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import Helmet from 'react-helmet'
import styles from './dashboard.css'
import Header from '../containers/header'
import CurrentTurnover from '../containers/current-turnover/current-turnover'

class DashboardPage extends Component<WithTranslateProps> {
	render() {
		return (
			<div className={styles.root} id={'trivi-dashboard'}>
				<Helmet>
					<title>{this.props.t('dashboard.headline')}</title>
				</Helmet>
				<Header />
				<Dashboard />
				<CurrentTurnover />
			</div>
		)
	}
}

export default withTranslate(DashboardPage)
