/** @jsx jsx */

import React, { Component } from 'react'
import { jsx } from '@emotion/core'
import PropTypes from 'prop-types'
import warning from 'warning'
import RadioButton from './RadioButton'

class RadioButtonGroup extends Component {
	static propTypes = {
		/**
		 * Should be used to pass `RadioButton` components.
		 */
		children: PropTypes.node,
		/**
		 * The CSS class name of the root element.
		 */
		className: PropTypes.string,
		/**
		 * The `value` property of the radio button that will be
		 * selected by default. This takes precedence over the `checked` property
		 * of the `RadioButton` elements.
		 */
		defaultSelected: PropTypes.any, // eslint-disable-line
		label: PropTypes.string,
		/**
		 * The name that will be applied to all child radio buttons.
		 */
		name: PropTypes.string.isRequired,
		/**
		 * Callback function that is fired when a radio button has
		 * been checked.
		 *
		 * @param {object} event `change` event targeting the selected
		 * radio button.
		 * @param {*} value The `value` of the selected radio button.
		 */
		onChange: PropTypes.func,
		/**
		 * Override the inline-styles of the root element.
		 */
		style: PropTypes.object,
		inline: PropTypes.bool,
		/**
		 * The `value` of the currently selected radio button.
		 */
		valueSelected: PropTypes.any, // eslint-disable-line

		forceSelected: PropTypes.any, // eslint-disable-line
	}

	static defaultProps = {
		style: {},
	}

	state = {
		numberCheckedRadioButtons: 0,
		selected: '',
	}

	UNSAFE_componentWillMount() {
		let cnt = 0
		let selected = ''
		const { valueSelected, defaultSelected } = this.props
		if (valueSelected !== undefined) {
			selected = valueSelected
		} else if (defaultSelected !== undefined) {
			selected = defaultSelected
		}

		React.Children.forEach(
			this.props.children,
			option => {
				if (this.hasCheckAttribute(option)) cnt++
			},
			this,
		)

		this.setState({
			numberCheckedRadioButtons: cnt,
			selected,
		})
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.hasOwnProperty('valueSelected')) {
			this.setState({
				selected: nextProps.valueSelected,
			})
		}
	}

	hasCheckAttribute(radioButton) {
		return radioButton.props.hasOwnProperty('checked') && radioButton.props.checked
	}

	updateRadioButtons(newSelection) {
		if (this.state.numberCheckedRadioButtons === 0) {
			this.setState({ selected: newSelection })
		} else {
			warning(
				false,
				`RadioButtonGroup: Cannot select a different radio button while another radio button
        has the 'checked' property set to true.`,
			)
		}
	}

	handleChange = (event, newSelection) => {
		!this.props.hasOwnProperty('valueSelected') && this.updateRadioButtons(newSelection)
		this.state.numberCheckedRadioButtons === 0 && this.props.onChange && this.props.onChange(event, newSelection)
	}

	getSelectedValue() {
		return this.state.selected
	}

	setSelectedValue(newSelectionValue) {
		this.updateRadioButtons(newSelectionValue)
	}

	clearValue() {
		this.setSelectedValue('')
	}

	render() {
		const options = React.Children.map(
			this.props.children,
			option => {
				const {
					name, // eslint-disable-line no-unused-vars
					value, // eslint-disable-line no-unused-vars
					label, // eslint-disable-line no-unused-vars
					onCheck, // eslint-disable-line no-unused-vars
					...other
				} = option.props

				let checked = option.props.value === this.state.selected
				if (this.props.forceSelected) {
					checked = option.props.value === this.props.forceSelected
				}
				//END TODO

				return (
					<RadioButton
						{...other}
						ref={option.props.value}
						name={this.props.name}
						key={option.props.value}
						value={option.props.value}
						label={option.props.label}
						inline={this.props.inline}
						onCheck={this.handleChange}
						checked={checked}
					/>
				)
			},
			this,
		)

		return (
			<div style={this.props.style} className={this.props.className}>
				{this.props.label && <div css={styles.label}>{this.props.label}</div>}
				{options}
			</div>
		)
	}
}

const styles = {
	label: {
		marginBottom: 2,
		fontSize: 14,
		lineHeight: '20px',
		textTransform: 'uppercase',
	},
}

export default RadioButtonGroup
