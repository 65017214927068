//@flow
//TODO: [TK] use when api is ready

import type { Dispatch, IssueAction } from 'types'

import type { AccountingDocumentIssues as Response } from 'modules/common/models/swagger-model'
import { accountingDocumentIssues as accountingDocumentIssuesApi } from 'modules/common/models/api-model'

export function loadIssues(accountingDocumentId: string) {
	return async (dispatch: Dispatch<IssueAction>) => {
		dispatch({
			type: 'START_LOAD_ACCOUNTING_DOCUMENT_ISSUES',
			accountingDocumentId,
		})
		try {
			const result: Response = await accountingDocumentIssuesApi.get({
				accountingDocumentId,
			})

			dispatch({
				type: 'FINISH_LOAD_ACCOUNTING_DOCUMENT_ISSUES',
				result,
				accountingDocumentId,
			})
		} catch (error) {
			dispatch({
				type: 'FINISH_LOAD_ACCOUNTING_DOCUMENT_ISSUES',
				result: null,
				accountingDocumentId,
				serverError: error,
			})
		}
	}
}
