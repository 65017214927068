// @flow
import React from 'react'
import type { FileDescription } from 'types'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import { uploads as uploadsApi } from 'modules/common/models/api-model'
import FileThumbnail from 'components/file-thumbnail'
import PopupSection from 'components/PopupSection'
import DatePicker from 'components/date-picker'
import Separator from 'components/Separator'
import Dropzone from 'components/dropzone'
import FormBox from 'components/form-box'
import Button from 'components/button'
import styles from './credit-note-dialog.css'

type Props = {|
	open: boolean,
	organizationId: ?string,
	hideCreditNote: () => void,
	saveCreditNote: (value: { date: string, files: Array<FileDescription> }) => void,
	...WithTranslateProps,
|}

type State = {|
	date: ?string,
	files: Array<FileDescription>,
|}

class CreditNoteDialog extends React.Component<Props, State> {
	state = {
		date: null,
		files: [],
	}

	onCreditNoteChange = (event: ?SyntheticInputEvent<HTMLInputElement>, date: ?string) => {
		this.setState({ date })
	}

	onSave = () => {
		const { date, files } = this.state
		date && this.props.saveCreditNote({ date, files })
		this.setState({ date: null, files: [] })
	}

	onClose = () => {
		this.setState({ date: null, files: [] })
		this.props.hideCreditNote()
	}

	handleDropFiles = (files: Array<File>): ?Promise<void> => {
		const { organizationId } = this.props
		let fileDescriptions: Array<FileDescription> = [...this.state.files]
		let fileDescription: FileDescription

		files.forEach((file: File) => {
			fileDescription = {
				id: Math.floor(Math.random() * 10000).toString(),
				name: file.name,
				loading: true,
			}
			fileDescriptions.push(fileDescription)

			if (organizationId) {
				uploadsApi.post({ organizationId }, { file }).then(
					(result: { id?: string }) => {
						fileDescriptions = [...this.state.files]
						if (result.id) {
							fileDescriptions.splice(fileDescriptions.indexOf(fileDescription), 1, {
								id: fileDescription.id,
								name: fileDescription.name,
								fileId: result.id,
								loading: false,
							})
						} else {
							fileDescriptions.splice(fileDescriptions.indexOf(fileDescription), 1)
						}
						this.setState({ files: fileDescriptions })
					},
					() => {
						fileDescriptions = [...this.state.files]
						fileDescriptions.splice(fileDescriptions.indexOf(fileDescription), 1)
						this.setState({ files: fileDescriptions })
					},
				)
			}
		})
		this.setState({ files: fileDescriptions })
	}

	handleFileRemove = (file: FileDescription) => {
		const fileDescriptions = [...this.state.files]
		fileDescriptions.splice(fileDescriptions.indexOf(file), 1)
		this.setState({ files: fileDescriptions })
	}

	render() {
		const { open, t } = this.props
		const { date, files } = this.state
		const dropZoneIsVisible = files.length < 1
		const confirmButtonDisabled = !date || dropZoneIsVisible
		const separatorTitle = dropZoneIsVisible
			? t('accountingDocument.creditNoteDialog.attachFile')
			: t('accountingDocument.creditNoteDialog.attachedFile')

		return (
			<PopupSection open={open} onRequestClose={this.onClose}>
				<FormBox
					headline={this.props.t('accountingDocument.creditNoteDialog.title')}
					children={
						<div>
							<div>
								<div className={styles.row}>
									<div className={styles.halfColumn}>
										<DatePicker
											labelText={t('accountingDocument.creditNoteDialog.dateLabel')}
											autoTestId="credit-note-dialog"
											value={date}
											onChange={this.onCreditNoteChange}
											fullWidth
										/>
									</div>
								</div>
								<div className={styles.separator}>
									<Separator left={<span className={styles.separatorText}>{separatorTitle}</span>} />
								</div>
								<div className={styles.row}>
									{files.map((file: FileDescription) => (
										<FileThumbnail key={file.id} file={file} onRemove={this.handleFileRemove} />
									))}
								</div>
								{dropZoneIsVisible && (
									<div className={styles.dropzone}>
										<Dropzone
											onDrop={this.handleDropFiles}
											hintText={t('accountingDocument.creditNoteDialog.fileHint')}
											minHeight={100}
										/>
									</div>
								)}
							</div>
							<div className={styles.button}>
								<Button
									disabled={confirmButtonDisabled}
									labelText={t('accountingDocument.creditNoteDialog.submitButton')}
									onClick={this.onSave}
									autoTestId="credit-note-submit"
								/>
							</div>
						</div>
					}
				/>
			</PopupSection>
		)
	}
}

export default withTranslate(CreditNoteDialog)
