/* @flow */

import React, { Component } from 'react'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import type { ApiApp } from 'types'
import Dialog from 'components/dialog'
import Button from 'components/button'
import TextField from 'components/TextField'
import ApiAppList from './api-app-list'
import styles from '../settings.css'

type Props = {|
	apiApps?: ?Array<ApiApp>,
	canSetApi?: boolean,
	currentUserIsInternal: boolean,
	loadApiApps: () => void,
	onApiAppAdded: (name: string) => void,
	onApiAppDelete: (apiAppId: number) => void,
	onApiAppChange: (apiAppId: number, apiApp: ApiApp) => void,
	...WithTranslateProps,
|}

type State = {
	dialogOpen: boolean,
	newApiAppName: string,
}

class Api extends Component<Props, State> {
	state: State = {
		dialogOpen: false,
		newApiAppName: '',
	}

	componentDidMount() {
		if (!this.props.apiApps && this.props.canSetApi) {
			this.props.loadApiApps()
		}
	}

	onAddButtonClick = () => {
		this.setState({ dialogOpen: true })
	}

	onDialogClose = () => {
		this.setState({ dialogOpen: false, newApiAppName: '' })
	}

	handleAddApiApp = () => {
		if (this.state.newApiAppName) {
			this.props.onApiAppAdded(this.state.newApiAppName)
			this.onDialogClose()
		}
	}

	onNewApiAppNameChange = (event: SyntheticInputEvent<HTMLInputElement>, newApiAppName: ?string) => {
		if (newApiAppName !== null && newApiAppName !== undefined) {
			this.setState({ newApiAppName })
		}
	}

	renderDialog() {
		const { t } = this.props
		const actions = [
			<Button
				primary
				wide
				key="addModalButton"
				labelText={t('settings.forms.addButton')}
				onClick={this.handleAddApiApp}
				autoTestId="settings-api-dialog-add"
			/>,
			<Button
				secondary
				wide
				key="cancelModalButton"
				labelText={t('settings.forms.cancelButton')}
				onClick={this.onDialogClose}
				autoTestId="settings-api-dialog-cancel"
			/>,
		]
		return (
			<Dialog
				autoTestId="api-dialog"
				title={t('settings.api.newApiAppDialogTitle')}
				actions={actions}
				open={this.state.dialogOpen}
			>
				<TextField
					fullWidth
					onChange={this.onNewApiAppNameChange}
					value={this.state.newApiAppName}
					hintText={t('settings.api.newApiAppTextFieldHint')}
					autoTestId="settings-api-name"
					name="api"
				/>
			</Dialog>
		)
	}

	renderAddButton() {
		if (!this.props.currentUserIsInternal) {
			return <h3 className={styles.firstH3}>{this.props.t('settings.api.contactTrivi')}</h3>
		}

		return (
			<div className={styles.addButton}>
				<Button
					autoTestId="settings-api-add"
					primary
					labelText={this.props.t('settings.forms.addButton')}
					onClick={this.onAddButtonClick}
				/>
			</div>
		)
	}

	render() {
		const { t } = this.props

		if (!this.props.canSetApi) {
			return <div>{t('settings.accessDenied')}</div>
		}

		return (
			<div>
				{this.renderAddButton()}
				<ApiAppList
					apiApps={this.props.apiApps}
					onApiAppDelete={this.props.onApiAppDelete}
					onApiAppChange={this.props.onApiAppChange}
					readOnly={!this.props.currentUserIsInternal}
				/>
				{this.renderDialog()}
			</div>
		)
	}
}

export default withTranslate(Api)
