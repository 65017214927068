/* @flow */

import { changeAccountingDocumentsDirection } from 'modules/accounting-document/actions'
import { accountingDocumentsRoute } from 'modules/accounting-document/routing/routes'
import { loadBalanceSummary } from '../../actions'
import { changeCurrentPage } from 'modules/data-grid-next/actions'
import { changeFilter } from 'modules/data-grid/actions'
import { getCurrentOrganization, getCurrentOrganizationDomesticCurrency } from 'modules/organization/selectors'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import type {
	AccountingDocumentListAction,
	AccountingDocumentReportRequest as ReportRequest,
	DashboardAction,
	DataGridReduxAction,
	DataGridNextAction,
	Dispatch,
	Organization,
	State,
} from 'types'
import { formatDateToIsoString } from 'utils/formatters'
import { getAccDocFilterName } from 'modules/accounting-document/domain/accounting-document'
import BalanceSummary, { type AccountingDocumentsFilter } from '../../components/balance-summary/balance-summary'
import { getBalanceSummary, getIsBalanceSummaryLoading, getUserDashboardCurrency } from '../../selectors'
import type { BalanceSummaryItem } from '../../types'
import { convertToDomesticKey } from '../../constants'
import { addDays } from 'components/date-picker/date-utils'

type StateProps = {|
	currency: ?string,
	domesticCurrency: ?string,
	receivables: ?BalanceSummaryItem,
	commitments: ?BalanceSummaryItem,
	organization: ?Organization,
	loading: boolean,
|}

const mapStateToProps = (state: State): StateProps => {
	const currency = getUserDashboardCurrency(state) || convertToDomesticKey
	return {
		currency: getUserDashboardCurrency(state),
		domesticCurrency: getCurrentOrganizationDomesticCurrency(state),
		receivables: getBalanceSummary(state, 1, currency),
		commitments: getBalanceSummary(state, 0, currency),
		organization: getCurrentOrganization(state),
		loading: getIsBalanceSummaryLoading(state),
	}
}

type DispatchProps = {|
	loadData: (request: ReportRequest) => void,
	goAndFilterAccountingDocuments: (filter: AccountingDocumentsFilter) => any,
|}

const mapDispatchToProps = (
	dispatch: Dispatch<DashboardAction | AccountingDocumentListAction | DataGridReduxAction | DataGridNextAction>,
): DispatchProps => {
	return {
		loadData: (request: ReportRequest) => {
			dispatch(loadBalanceSummary(request))
		},
		goAndFilterAccountingDocuments: async (filter: AccountingDocumentsFilter) => {
			const direction = 'commitments' === filter.type ? 'received' : 'issued'
			dispatch(changeAccountingDocumentsDirection(direction))

			const paidFilters = [
				{ field: 'payments.paidConfirmed', value: false },
				{ field: 'payments.paid', value: false },
			]
			const currentDate = new Date()
			const yesterdayDate = addDays(currentDate, -1)

			const dueDateFilter = {
				field: 'dueDate',
				valueFrom: filter.status === 'beforeDue' ? formatDateToIsoString(currentDate) : undefined,
				valueTo: filter.status === 'overdue' ? formatDateToIsoString(yesterdayDate) : undefined,
			}
			const stateFilter = {
				field: 'state',
				value: 'Processed',
			}
			const totalFilter = {
				field: 'total',
				value: 0,
				operation: 'NotEqual',
			}
			const typeFilter = {
				field: 'type',
				valueContains: [0, 1, 3],
			}

			const filters = [...paidFilters, dueDateFilter, stateFilter, totalFilter, typeFilter]

			if (filter.currency) {
				filters.push({ field: 'currency', value: filter.currency })
			}

			await dispatch(changeFilter(getAccDocFilterName(direction), filters))
			await dispatch(changeCurrentPage('accountingDocuments', 0))
			await dispatch(push(accountingDocumentsRoute(direction)))
		},
	}
}

type Props = {| ...StateProps, ...DispatchProps |}

class BalanceSummaryContainer extends Component<Props> {
	loadData() {
		const request = this.props.currency ? { filterCurrency: this.props.currency } : { convertToDomesticCurrency: true }

		this.props.loadData({ ...request, type: 0 })
		this.props.loadData({ ...request, type: 1 })
	}

	componentDidMount() {
		this.loadData()
	}

	componentDidUpdate(props: Props) {
		if (props.currency !== this.props.currency || this.props.organization !== props.organization) {
			this.loadData()
		}
	}

	render() {
		return (
			<BalanceSummary
				goAndFilterAccountingDocuments={this.props.goAndFilterAccountingDocuments}
				domesticCurrency={this.props.domesticCurrency}
				receivables={this.props.receivables}
				commitments={this.props.commitments}
				currency={this.props.currency}
				loading={this.props.loading}
			/>
		)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(BalanceSummaryContainer)
