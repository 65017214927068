/* @flow */

import { type WithTranslateProps, withTranslate } from 'wrappers'

import { FilterSelectField } from 'modules/data-grid/components/data-grid-filter'
import React from 'react'
import type { SearchFilter } from 'types'

type Props = {|
	...WithTranslateProps,
	name: string,
	operation?: 'NotEqual' | 'Equal' | 'Exclusive',
	className?: string,
	filter?: SearchFilter,
	onChange?: (filter: SearchFilter) => void,
|}

function FilterState({ t, onChange, filter, name, className, operation }: Props) {
	const options = [
		{ value: 'Draft', label: t('accountingDocument.columns.stateDraft') },
		{ value: 'Waiting For Approval', label: t('accountingDocument.columns.stateWaiting') },
		{ value: 'Processed', label: t('accountingDocument.columns.stateProcessed') },
		{ value: 'Extracting', label: t('accountingDocument.columns.stateExtracting') },
		{ value: 'Extracted', label: t('accountingDocument.columns.stateExtracted') },
		{ value: 'Fixing', label: t('accountingDocument.columns.stateFixing') },
		{ value: 'Rejected', label: t('accountingDocument.columns.stateRejected') },
	]

	return (
		<FilterSelectField
			compact
			isClearable
			isSearchable
			name={name}
			filter={filter}
			options={options}
			className={className}
			onChange={onChange}
			operation={operation}
			labelText={t('accountingDocument.columns.state')}
		/>
	)
}

export default withTranslate(FilterState)
