/* @flow */
/** @jsx jsx */

import { Component } from 'react'
import { jsx } from '@emotion/core'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import TextField from 'components/TextField'
import type { FormFieldErrorMessage } from 'types'
import { getTooltipErrorLabel } from 'helpers'

export type Props = {|
	readonly: boolean,
	creditNoteDescription: ?string,
	updateCreditNoteDescription: (creditNoteDescription: ?string) => void,
	errors: ?Array<FormFieldErrorMessage>,
	removeError: () => void,
|}

type State = {|
	creditNoteDescription: ?string,
|}

type ComponentProps = {|
	...WithTranslateProps,
	...Props,
|}

class CreditNoteDescription extends Component<ComponentProps, State> {
	state = {
		creditNoteDescription: this.props.creditNoteDescription,
	}

	UNSAFE_componentWillReceiveProps = (nextProps: ComponentProps) => {
		const { creditNoteDescription } = this.state
		this.setState({
			creditNoteDescription:
				creditNoteDescription !== nextProps.creditNoteDescription
					? creditNoteDescription
					: nextProps.creditNoteDescription,
		})
	}

	handleOnChange = (event: SyntheticInputEvent<HTMLInputElement>, value: ?string) => {
		this.setState({
			creditNoteDescription: value,
		})
		this.props.removeError()
	}

	handleOnBlur = () => {
		this.props.updateCreditNoteDescription && this.props.updateCreditNoteDescription(this.state.creditNoteDescription)
	}

	render() {
		const { readonly, t, errors } = this.props

		return (
			<div css={style.root}>
				<TextField
					fullWidth
					disabled={readonly}
					hintText={t('invoice.creditNoteDescriptionHint')}
					autoTestId="credit-note-description"
					value={this.state.creditNoteDescription}
					onChange={this.handleOnChange}
					onBlur={this.handleOnBlur}
					compact
					name="credit-note-description"
					clientError={getTooltipErrorLabel(errors, t)}
				/>
			</div>
		)
	}
}

const style = {
	root: { marginTop: 4 },
}

export default withTranslate(CreditNoteDescription)
