/* @flow */

import React, { Component } from 'react'
import { connect } from 'react-redux'
import type {
	AccountingDocumentAttachment,
	AccountingDocumentReduxAction,
	CheckedDocItem,
	Dispatch,
	DocumentItem,
	State,
} from 'types'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import { attachAccountingDocumentAttachment, removeAccountingDocumentAttachment } from '../../actions'
import DocumentList from 'modules/document/containers/document-list'
import { createCheckedDocItemFromAttachment } from 'helpers'

type StateProps = {|
	checkedDocItems: Array<CheckedDocItem>,
|}

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => {
	const item = state.accountingDocument.accountingDocuments.items[ownProps.accountingDocumentId]
	const attachments: Array<AccountingDocumentAttachment> = (item && item.attachments) || []
	return {
		checkedDocItems: attachments.map((attachment: AccountingDocumentAttachment) => {
			return createCheckedDocItemFromAttachment(attachment)
		}),
	}
}

type DispatchProps = {|
	onItemCheck: (file: DocumentItem, checked: boolean) => void,
|}

const mapDispatchToProps = (dispatch: Dispatch<AccountingDocumentReduxAction>, ownProps: OwnProps): DispatchProps => {
	return {
		onItemCheck: (file: DocumentItem, checked: boolean) => {
			const accountingDocumentId: string = ownProps.accountingDocumentId
			const attachment: AccountingDocumentAttachment = {
				documentUniqueId: file.uniqueId,
			}
			if (checked) {
				dispatch(attachAccountingDocumentAttachment(accountingDocumentId, attachment))
			} else {
				dispatch(removeAccountingDocumentAttachment(accountingDocumentId, attachment))
			}
		},
	}
}

type OwnProps = {|
	accountingDocumentId: string,
	onFileOpen: (file: DocumentItem) => void,
	readonly?: boolean,
|}

type ComponentProps = {| ...StateProps, ...DispatchProps, ...OwnProps, ...WithTranslateProps |}

type AttachDocumentState = {|
	itemType?: number,
	itemId?: string,
	filter: string,
|}

class AttachDocument extends Component<ComponentProps, AttachDocumentState> {
	state = {
		itemId: undefined,
		filter: '',
	}

	onFolderOpen = (itemId?: string) => {
		this.setState({ itemId: itemId })
	}

	onFileOpen = (file: DocumentItem) => {
		file.fileId && this.props.onFileOpen(file)
	}

	render() {
		const { t } = this.props

		return (
			<DocumentList
				smallBreadcrumbs
				showActions={!this.props.readonly}
				showAddButton={!this.props.readonly}
				showBreadcrumbs={!this.props.readonly}
				showFileCheckbox={!this.props.readonly}
				deselectTooltip={t('document.gridActions.unAttachDocument')}
				selectTooltip={t('document.gridActions.attachDocument')}
				itemId={this.state.itemId}
				onNavigationChange={this.onFolderOpen}
				onFileOpen={this.onFileOpen}
				onItemCheck={this.props.onItemCheck}
				checkedDocItems={this.props.checkedDocItems}
				isAttachDocumentList
			/>
		)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslate(AttachDocument))
