/* @flow */

import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import IconButton from 'components/icon-button'
import Menu from 'components/menu'
import MenuItem from 'components/menu-item'
import EnhancedButton from 'components/internal/EnhancedButton'
import Popover from 'components/Popover'
import DropDownArrow from 'components/svg-icons/hardware/keyboard-arrow-down'
import { colors } from 'variables'
import { type AutoTestProps, passAutoTestId } from 'utils/tests/autotest'

type Props = {|
	children?: any,
	disabled?: boolean,
	useBlueStyle?: boolean,
	onClick?: () => void,
	onItemClick?: (SyntheticMouseEvent<HTMLElement>, React$Element<typeof MenuItem>) => void,
	labelText?: string,
	...AutoTestProps,
|}

type State = {|
	anchorEl: ?Object,
	hovered: boolean,
|}

class ButtonSelect extends Component<Props, State> {
	anchorOrigin = { horizontal: 'left', vertical: 'bottom' }
	targetOrigin = { horizontal: 'left', vertical: 'top' }

	state = {
		anchorEl: null,
		hovered: false,
	}

	toggleMenu = () => {
		this.state.anchorEl ? this.closeMenu() : this.openMenu()
	}

	openMenu() {
		this.setState({
			anchorEl: ReactDOM.findDOMNode(this.refs.button),
		})
	}

	closeMenu() {
		this.setState({
			anchorEl: null,
		})
	}

	handleRequestClose = () => {
		this.closeMenu()
	}

	handleClick = () => {
		this.props.onClick && this.props.onClick()
	}

	handleItemClick = (event: SyntheticMouseEvent<HTMLElement>, child: React$Element<typeof MenuItem>) => {
		this.props.onItemClick && this.props.onItemClick(event, child)
	}

	handleMouseLeave = () => {
		this.setState({
			hovered: false,
		})
	}

	handleMouseEnter = () => {
		this.setState({
			hovered: true,
		})
	}

	render() {
		const styles = getStyles(this.props, this.state)

		return (
			<div style={styles.root} ref="root">
				<div style={styles.buttonContainer} ref="button">
					<div style={styles.container}>
						<EnhancedButton
							disabled={this.props.disabled}
							onClick={this.handleClick}
							onMouseLeave={this.handleMouseLeave}
							onMouseEnter={this.handleMouseEnter}
							style={styles.button}
							{...passAutoTestId(this.props.autoTestId)}
						>
							<div style={styles.label}>{this.props.labelText}</div>
						</EnhancedButton>

						{this.props.children ? (
							<div style={styles.iconContainer}>
								<div style={styles.line} />
								<IconButton
									disabled={this.props.disabled}
									ref="icon"
									onClick={this.toggleMenu}
									color={colors.orange600}
									hoverColor={colors.blackFaded40}
									style={styles.iconButton}
									size={36}
								>
									<DropDownArrow style={styles.icon} size={22} />
								</IconButton>
							</div>
						) : null}
					</div>
				</div>
				<Popover
					open={!!this.state.anchorEl}
					anchorEl={this.state.anchorEl}
					anchorOrigin={this.anchorOrigin}
					targetOrigin={this.targetOrigin}
					zDepth={5}
					style={styles.popover}
					useLayerForClickAway
					onRequestClose={this.handleRequestClose}
					arrow={'topRight'}
					stretch
				>
					{this.props.children && (
						<Menu fullWidth onItemClick={this.handleItemClick}>
							{this.props.children}
						</Menu>
					)}
				</Popover>
			</div>
		)
	}
}

function getStyles(props: Props, state: State) {
	const { disabled, children, useBlueStyle } = props
	const { hovered } = state
	const open = !!state.anchorEl
	const zDepth = disabled ? 0 : 3

	const color = useBlueStyle ? colors.blue : colors.orange
	const color400 = useBlueStyle ? colors.blue400 : colors.orange400
	const color600 = useBlueStyle ? colors.blue600 : colors.orange600

	return {
		root: {
			display: 'inline-block',
			width: 'auto',
			position: 'relative',
			zIndex: 100,
		},
		buttonContainer: {
			display: 'inline-block',
			width: 'auto',
			position: 'relative',
		},
		container: {
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center',
			width: '100%',
			color: '#7f7f7f',
			borderRadius: 20,
			boxShadow: `${zDepth}px ${zDepth}px 0 ${colors.blackFaded8}`,
		},
		button: {
			display: 'inline-block',
			transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1)',
			border: `1px solid ${color600}`,
			backgroundColor: hovered && !disabled ? color400 : color,
			overflow: 'hidden',
			height: 40,
			lineHeight: '38px',
			padding: 0,
			opacity: disabled ? 0.4 : 1,
			borderRadius: children ? '20px 0 0 20px' : 20,
			borderRight: children ? 'none' : `1px solid ${color600}`,
		},
		label: {
			position: 'relative',
			opacity: 1,
			fontSize: 14,
			letterSpacing: 0,
			textTransform: 'uppercase',
			fontWeight: 'bold',
			margin: 0,
			userSelect: 'none',
			paddingLeft: 25,
			paddingRight: 25,
			color: colors.white,
			whiteSpace: 'nowrap',
		},
		line: {
			height: 26,
			opacity: 0.85,
			backgroundColor: color600,
			width: 1,
			display: 'inline-block',
			verticalAlign: 'middle',
		},
		iconContainer: {
			height: 40,
			borderTopRightRadius: 20,
			borderBottomRightRadius: 20,
			backgroundColor: open ? '#eb9605' : color,
			border: `1px solid ${color600}`,
			borderLeft: 'none',
			borderBottom: `1px solid ${color600}`,
			display: 'inline-block',
			position: 'relative',
			verticalAlign: 'middle',
			lineHeight: '38px',
			whiteSpace: 'nowrap',
			opacity: disabled ? 0.4 : 1,
			transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1)',
		},
		icon: {
			opacity: 1,
			fill: colors.white,
			transition: 'none',
			transform: open ? 'rotate(180deg) translateY(1px)' : 'none',
		},
		iconButton: {
			padding: 7,
			display: 'inline-block',
			position: 'relative',
			verticalAlign: 'middle',
			cursor: disabled ? 'auto' : 'pointer',
			borderWidth: 0,
			marginRight: 1,
		},
		popover: {
			marginTop: 10,
		},
	}
}

export default ButtonSelect
