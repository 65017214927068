// @flow
/** @jsx jsx */

import { jsx } from '@emotion/core'
import type { EmailTemplate } from 'types'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import Delete from 'components/svg-icons/action/delete'
import Edit from 'components/svg-icons/image/edit'
import TableCell from 'components/table/table-cell'
import TableRow from 'components/table/table-row'
import MenuItem from 'components/menu-item'
import IconMenu from 'components/icon-menu'
import FullText from 'components/full-text'

export type Props = {|
	...WithTranslateProps,
	template: EmailTemplate,
	onEdit: (emailTemplateId: string) => void,
	onDelete: (emailTemplateId: string) => void,
|}

function EmailTemplateItem(props: Props) {
	const type = 1 === props.template.type ? 'emailTemplateAccountingDocumentReminder' : 'emailTemplateAccountingDocument'

	function onEdit() {
		props.template.id && props.onEdit(props.template.id)
	}

	function onDelete() {
		props.template.id && props.onDelete(props.template.id)
	}

	return (
		<TableRow key={props.template.id || props.template.name}>
			<TableCell onClick={onEdit}>
				<span css={style.link}>
					<FullText text={props.template.name} />
				</span>
			</TableCell>
			<TableCell>
				<FullText text={props.t(`accountingDocument.${type}`)} />
			</TableCell>
			<TableCell>
				<IconMenu autoTestId="settings-email-template-item-actions" context>
					{[
						<MenuItem key="edit" primaryText={props.t('grid.action.edit')} icon={<Edit />} onClick={onEdit} tall />,
						<MenuItem
							primaryText={props.t('grid.action.delete')}
							icon={<Delete />}
							onClick={onDelete}
							key="delete"
							tall
						/>,
					]}
				</IconMenu>
			</TableCell>
		</TableRow>
	)
}

const style = {
	link: {
		'&:hover': {
			cursor: 'pointer',
			textDecoration: 'underline',
		},
	},
}

export default withTranslate(EmailTemplateItem)
