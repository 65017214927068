/* @flow */

import React, { Component } from 'react'
import type { ContactPerson, FormValidationProps } from 'types'
import { emptyContactPerson } from 'types/empty'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import { IconButton, Paper, TextField, EmailInput, PhoneInput } from 'components'
import MailIcon from 'components/svg-icons/action/perm-contact-calendar'
import PhoneIcon from 'components/svg-icons/communication/phone'
import ContentClear from 'components/svg-icons/content/clear'
import styles from './contact-person-edit.css'
import { colors } from 'variables'

type Props = {|
	...WithTranslateProps,
	...FormValidationProps,
	contactPerson: ContactPerson,
	onBlur: (address: ContactPerson) => void,
	onDelete: () => void,
	className?: string,
	disabled?: boolean,
	id: number,
|}

type State = {|
	contactPerson: ContactPerson,
|}

const style = {
	name: {
		fontSize: 24,
	},
	iconButton: {
		position: 'absolute',
		top: 0,
		right: 0,
	},
	icon: {
		position: 'absolute',
		top: 5,
		left: -30,
	},
}

class ContactPersonEdit extends Component<Props, State> {
	state = {
		contactPerson: this.props.contactPerson,
	}

	getOnFieldChange = (name: string) => (
		event: ?SyntheticInputEvent<HTMLInputElement>,
		value: ?string,
		next?: () => void,
	) => {
		this.setState(
			{
				contactPerson: {
					...emptyContactPerson(),
					...this.state.contactPerson,
					[name]: value,
				},
			},
			next,
		)
	}

	onFieldBlur = () => {
		this.props.onBlur(this.state.contactPerson)
	}

	onEmailChange = (event: SyntheticInputEvent<HTMLInputElement>, value: ?string) => {
		if (value !== null && value !== undefined) {
			this.getOnFieldChange('email')(event, value, this.onFieldBlur)
		}
	}

	getValidationKey = (id: string): string => {
		return 'person' + this.props.id + '_' + id
	}

	render() {
		const { t } = this.props
		const { contactPerson } = this.state

		return (
			<Paper rounded zDepth={4} className={styles.root} id={'contact-edit-container'}>
				<div className={styles.inner}>
					<div className={styles.up}>
						<TextField
							name={'firstname'}
							disabled={this.props.disabled}
							value={contactPerson.firstname}
							onChange={this.getOnFieldChange('firstname')}
							onBlur={this.onFieldBlur}
							hintText={t('contactEdit.tile.hint.firstName')}
							autoTestId="contact-person-edit-firstname"
							style={style.name}
							inline
						/>
						<TextField
							name={'lastname'}
							disabled={this.props.disabled}
							value={contactPerson.lastname}
							onChange={this.getOnFieldChange('lastname')}
							onBlur={this.onFieldBlur}
							hintText={t('contactEdit.tile.hint.lastName')}
							autoTestId="contact-person-edit-lastname"
							style={style.name}
							inline
						/>
						<TextField
							name={'jobTitle'}
							disabled={this.props.disabled}
							value={contactPerson.jobTitle}
							onChange={this.getOnFieldChange('jobTitle')}
							onBlur={this.onFieldBlur}
							hintText={t('contactEdit.tile.hint.jobTitle')}
							autoTestId="contact-person-edit-job"
							inline
						/>
					</div>
					<div className={styles.down}>
						<div className={styles.iconized}>
							<EmailInput
								inline
								autoWidth
								name={'email'}
								disabled={this.props.disabled}
								value={contactPerson.email}
								onChange={this.onEmailChange}
								onBlur={this.onFieldBlur}
								hintText={t('contactEdit.tile.hint.email')}
								autoTestId="contact-person-edit-email"
								clientError={this.props.validationMessage(this.getValidationKey('email'))}
							/>
							<MailIcon disabled size={16} style={style.icon} />
						</div>
						<div className={styles.iconized}>
							<PhoneInput
								name={'phone'}
								disabled={this.props.disabled}
								value={contactPerson.phone}
								onChange={this.getOnFieldChange('phone')}
								onBlur={this.onFieldBlur}
								hintText={t('contactEdit.tile.hint.phone')}
								autoTestId="contact-person-edit-phone"
								inline
								autoWidth
								clientError={this.props.validationMessage(this.getValidationKey('phone'))}
							/>
							<PhoneIcon disabled size={16} style={style.icon} />
						</div>
					</div>
				</div>
				{!this.props.disabled && (
					<IconButton
						style={style.iconButton}
						onClick={this.props.onDelete}
						color={colors.grey400}
						hoverColor={colors.grey}
						autoTestId="contact-person-edit-delete"
					>
						<ContentClear size={20} />
					</IconButton>
				)}
			</Paper>
		)
	}
}

export default withTranslate(ContactPersonEdit)
