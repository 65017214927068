// @flow
/** @jsx jsx */

import React from 'react'
import { jsx } from '@emotion/core'
import type {
	OrganizationStatisticsKeysCashRegisters,
	OrganizationStatisticsKeysCashRegister,
	OrganizationStatisticsKeysAccountingDocumentsSimpleStatistics,
	SearchFilter,
	Filter,
} from 'types'
import { Tooltip, TriviLink } from 'components'
import { countCashRegistersDocuments } from 'modules/internal/domain/statistics-grid'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import styles from './cash-registers-tooltip.css'

const TRANSLATION_PREFIX = 'internal.widget.organizationStatistics.cashRegistersTooltip.'

type Props = {|
	organizationId: ?string,
	cashRegisters?: OrganizationStatisticsKeysCashRegisters,
	onRedirect: (organizationId: string, cashRegisterId: string, filter: Filter) => Promise<void>,
	...WithTranslateProps,
|}

class CashRegistersTooltip extends React.Component<Props> {
	getCountColumnHandler = (filter: SearchFilter, cashRegisterId?: string) => () => {
		const { onRedirect, organizationId } = this.props
		cashRegisterId && organizationId && onRedirect(organizationId, cashRegisterId, [filter])
	}

	renderCountColumn(value?: OrganizationStatisticsKeysAccountingDocumentsSimpleStatistics, clickHandler: () => void) {
		return (
			<td>
				<TriviLink onClick={clickHandler}>{value && value.count ? value.count : 0}</TriviLink>
			</td>
		)
	}

	renderCashRegisterRow = (cashRegister: OrganizationStatisticsKeysCashRegister) => {
		const {
			notProcessedWithOK,
			processingErrors,
			waitingForAccountingProcessing,
			waitingForApprovals,
			extractedDrafts,
		} = cashRegister.accountingDocuments || {}
		return (
			<tr key={cashRegister.id}>
				<td>{cashRegister.name}</td>
				{this.renderCountColumn(
					notProcessedWithOK,
					this.getCountColumnHandler(
						{
							field: 'isProcessedWithOK',
							value: false,
						},
						cashRegister.id,
					),
				)}
				{this.renderCountColumn(
					processingErrors,
					this.getCountColumnHandler(
						{
							field: 'processingState',
							value: 3,
						},
						cashRegister.id,
					),
				)}
				{this.renderCountColumn(
					waitingForAccountingProcessing,
					this.getCountColumnHandler(
						{
							field: 'processingState',
							value: 2,
						},
						cashRegister.id,
					),
				)}
				{this.renderCountColumn(
					waitingForApprovals,
					this.getCountColumnHandler(
						{
							field: 'state',
							value: 'Waiting For Approval',
						},
						cashRegister.id,
					),
				)}
				{this.renderCountColumn(
					extractedDrafts,
					this.getCountColumnHandler(
						{
							field: 'state',
							value: 'Extracted',
						},
						cashRegister.id,
					),
				)}
			</tr>
		)
	}

	renderLabel = () => {
		const { cashRegisters: source, t } = this.props
		const cashRegisters = source && source.cashRegisters ? source.cashRegisters : []
		return (
			<div css={style.content}>
				<table className={styles.tooltipTable}>
					<tbody>
						<tr>
							<th>{t(TRANSLATION_PREFIX + 'cashRegister')}</th>
							<th>{t(TRANSLATION_PREFIX + 'notProcessedWithOK')}</th>
							<th>{t(TRANSLATION_PREFIX + 'processingErrors')}</th>
							<th>{t(TRANSLATION_PREFIX + 'waitingForAccountingProcessing')}</th>
							<th>{t(TRANSLATION_PREFIX + 'waitingForApprovals')}</th>
							<th>{t(TRANSLATION_PREFIX + 'extractedDrafts')}</th>
						</tr>
						{cashRegisters.map(this.renderCashRegisterRow)}
					</tbody>
				</table>
			</div>
		)
	}

	render() {
		const count = countCashRegistersDocuments(this.props.cashRegisters)

		return count ? (
			<Tooltip hoverable inline label={this.renderLabel()} maxWidth={800}>
				{count}
			</Tooltip>
		) : (
			<span>{count}</span>
		)
	}
}

const style = {
	content: {
		maxWidth: '100%',
		overflow: 'auto',
	},
}

export default withTranslate(CashRegistersTooltip)
