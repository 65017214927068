/* @flow */
/* eslint-disable react/no-multi-comp */

import React from 'react'
import moize from 'moize'
import type { AccountingDocument } from 'types'
import { Number_PaymentType } from 'types/convertor'
import { type WithTranslateProps } from 'wrappers'
import { Column } from 'modules/data-grid-next/components'
import type { Direction } from 'modules/data-grid-next/types'
import ClassificationInfo from 'modules/accounting-document/containers/invoice-elements/classification-info'
import { DATA_GRID_CACHE_SIZE } from 'trivi-constants'

export type Props = {|
	width?: number | string,
	defaultSortDirection?: Direction,
	...WithTranslateProps,
|}

export default function getUploadDetailsColumn(props: Props) {
	function columnRenderer(value: number, row: AccountingDocument) {
		const type = props.t('accountingDocument.paymentTypes.' + Number_PaymentType(row.paymentType))

		return (
			<span>
				<span>{type}</span>
				<ClassificationInfo inline accDoc={row} />
			</span>
		)
	}

	const columnRendererCashed = moize(columnRenderer, { maxSize: DATA_GRID_CACHE_SIZE })

	return (
		<Column
			title={props.t('accountingDocument.columns.uploadDetails')}
			width={props.width}
			columnId="paymentType"
			key="paymentType"
			render={columnRendererCashed}
			sortable
			defaultSortDirection={props.defaultSortDirection || undefined}
		/>
	)
}
