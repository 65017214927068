// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2AfkHKFuqD3jgfPnlLFF1t {\n\tmargin-top: 10px;\n}\n\n._1gIBnV_vdJO98zWsLPZuV8 {\n\tpadding: 10px;\n\tdisplay: flex;\n}\n\n", "",{"version":3,"sources":["webpack://./modules/internal/components/billing/billing-table.css"],"names":[],"mappings":"AAAA;CACC,gBAAA;AACD;;AAEA;CACC,aAAA;CACA,aAAA;AACD","sourcesContent":[".wrapper {\n\tmargin-top: 10px;\n}\n\n.paper {\n\tpadding: 10px;\n\tdisplay: flex;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "_2AfkHKFuqD3jgfPnlLFF1t",
	"paper": "_1gIBnV_vdJO98zWsLPZuV8"
};
export default ___CSS_LOADER_EXPORT___;
