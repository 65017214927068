// @flow

import React, { Component } from 'react'
import memoize from 'memoize-one'
import type { GenericReportRequest, FinancialAccount, Enum, Enumeration } from 'types'
import type { ActionMeta, OptionType } from 'components/select-next'
import { EMPTY_ARRAY } from 'trivi-constants'
import type { Action } from '../../actions/action-types'
import type { ReportScheme } from '../../constants/report'
import { getFinancialAccountsOptions } from 'modules/accounting-document/domain/financial-accounts'
import type { WithNotifyProps, WithTranslateProps, FormValidationProps } from 'wrappers'
import { RadioButton, RadioButtonGroup, Button, DatePicker } from 'components'
import FavoriteSelect from 'components/favorite-select'
import { type FavoriteOptionType } from 'components/favorite-select/favorite-option'
import { emptyReportScheme, MainBookType, SummaryByPeriodsType, SummaryOfMoneyDiaryType } from '../../constants/report'
import { formatDateToIsoString } from 'utils/formatters'
import { genSeries } from 'utils'
import { withNotify, withTranslate } from 'wrappers'
import validate from 'wrappers/validate'
import FormBox from '../../../../components/form-box'
import styles from './report-detail.css'
import moment from 'moment'
import SelectNext from 'components/select-next'

type Props = {
	...FormValidationProps,
	...WithNotifyProps,
	...WithTranslateProps,
	reportScheme: ?ReportScheme,
	financialAccounts: ?Array<FinancialAccount>,
	favoriteFinancialAccountIds: Array<string>,
	requestReport: (request: GenericReportRequest) => Promise<Action>,
	loadFinancialAccounts: () => void,
	onFinancialAccountFavorite: (no: ?string, isFavorite: boolean) => void,
}

export type ComponentState = {
	component: {
		[identifer: string]: any,
	},
	format: number,
	finAccountError: ?string,
}

const now = new Date()

class GenerateReport extends Component<Props, ComponentState> {
	state: ComponentState = {
		component: {
			START_YEAR: now.getFullYear(),
			START_MONTH: 1,
			END_YEAR: now.getFullYear(),
			END_MONTH: now.getMonth(),
			FIN_ACCOUNT: null,
		},
		format: 1,
		finAccountError: null,
	}

	getAutoCompleteOptions = memoize((options: ?Enum) => {
		if (!options) return EMPTY_ARRAY
		return options.map((option: Enumeration) => ({
			label: option.value,
			value: option.key,
		}))
	})

	getAvailableComponents = () => {
		const { t, financialAccounts, reportScheme } = this.props
		const isMainBook = this.props.reportScheme && this.props.reportScheme.type === MainBookType

		const isHiddenEndYear =
			(reportScheme && reportScheme.type === SummaryOfMoneyDiaryType) ||
			(reportScheme && reportScheme.type === SummaryByPeriodsType)

		return {
			START_YEAR: (
				<SelectNext
					value={this.state.component['START_YEAR']}
					onChange={this.getComponentStateUpdater('START_YEAR')}
					label={isHiddenEndYear ? t('application.year') : t('dashboard.widget.genericReports.startYearLabel')}
					portal
					name="startYear"
					autoTestId="generate-report-start-year"
					options={genSeries(2010, new Date().getFullYear())
						.reverse()
						.map((num: number) => ({ value: num, label: num.toString() }))}
				/>
			),
			START_MONTH: (
				<SelectNext
					value={this.state.component['START_MONTH']}
					onChange={this.getComponentStateUpdater('START_MONTH')}
					label={t('dashboard.widget.genericReports.startMonthLabel')}
					name="startMonth"
					portal
					autoTestId="generate-report-start-month"
					options={genSeries(1, 12).map((num: number) => {
						return { value: num, label: num.toString() }
					})}
				/>
			),
			END_YEAR: (
				<SelectNext
					value={this.state.component['END_YEAR']}
					onChange={this.getComponentStateUpdater('END_YEAR')}
					label={isHiddenEndYear ? null : t('dashboard.widget.genericReports.endYearLabel')}
					isHidden={isHiddenEndYear}
					name="endYear"
					portal
					autoTestId="generate-report-end-year"
					options={genSeries(2010, new Date().getFullYear())
						.reverse()
						.map((num: number) => {
							return { value: num, label: num.toString() }
						})}
				/>
			),
			END_MONTH: (
				<SelectNext
					value={this.state.component['END_MONTH']}
					onChange={this.getComponentStateUpdater('END_MONTH')}
					label={t('dashboard.widget.genericReports.endMonthLabel')}
					name="endMonth"
					portal
					autoTestId="generate-report-end-month"
					options={genSeries(1, 12).map((num: number) => {
						return { value: num, label: num.toString() }
					})}
				/>
			),
			END_DATE: (
				<DatePicker
					labelText={t('dashboard.widget.genericReports.endDateLabel')}
					autoTestId="generate-report-end-date"
					value={this.state.component['END_DATE']}
					onChange={this.getComponentStateUpdater('END_DATE', 1)}
					maxDate={new Date()}
					fullWidth
				/>
			),
			FIN_ACCOUNT: (
				<FavoriteSelect
					label={t('dashboard.widget.genericReports.accountPickerLabel')}
					value={this.props.validationValue('finAccount', this.state.component['FIN_ACCOUNT'] || null)}
					onChange={this.onFinAccountChange}
					options={this.getOptions(financialAccounts || EMPTY_ARRAY, this.props.favoriteFinancialAccountIds)}
					info={!isMainBook ? t('dashboard.widget.genericReports.accountPickerInfo') : null}
					isCreatable={!isMainBook}
					error={this.props.validationMessage('finAccount')}
					autoTestId="generate-report-finaccount-select"
					portal
					cursorToRight
				/>
			),
		}
	}

	setComponentState(componentKey: string, value: number) {
		const { reportScheme } = this.props
		let newComponent = { ...this.state.component, [componentKey]: value }
		let { START_YEAR, START_MONTH, END_YEAR, END_MONTH, FIN_ACCOUNT } = newComponent

		if (START_YEAR > END_YEAR || (START_YEAR === END_YEAR && START_MONTH > END_MONTH)) {
			newComponent = componentKey.includes('START_')
				? { START_YEAR, START_MONTH, END_YEAR: START_YEAR, END_MONTH: START_MONTH, FIN_ACCOUNT }
				: { START_YEAR: END_YEAR, START_MONTH: END_MONTH, END_YEAR, END_MONTH, FIN_ACCOUNT }
		}

		if (componentKey.includes('START_YEAR')) {
			const isHiddenEndYear =
				(reportScheme && reportScheme.type === SummaryOfMoneyDiaryType) ||
				(reportScheme && reportScheme.type === SummaryByPeriodsType)

			if (isHiddenEndYear) {
				newComponent = { START_YEAR, START_MONTH, END_YEAR: START_YEAR, END_MONTH, FIN_ACCOUNT }
			}
		}

		this.setState({
			...this.state,
			component: newComponent,
		})
	}

	getComponentStateUpdater(componentKey: string, argIndex?: number) {
		if (argIndex) {
			return (...args: Array<any>) => {
				const valIndex = argIndex && Number.isInteger(argIndex) ? argIndex : 2
				this.setComponentState(componentKey, args[valIndex])
			}
		} else {
			return (option: OptionType) => {
				this.setComponentState(componentKey, parseInt(option.value))
			}
		}
	}

	onFinAccountChange = (value: FavoriteOptionType, meta: ActionMeta) => {
		this.setState({
			...this.state,
			component: {
				...this.state.component,
				FIN_ACCOUNT: value.value,
			},
			finAccountError: meta.action === 'create-option' ? this.getSelectValidationError(value.value) : null,
		})
	}

	onFavorite = (value: ?string, isFavorite: boolean) => {
		value && this.props.onFinancialAccountFavorite(value, isFavorite)
	}

	getOptions = memoize((accounts: Array<FinancialAccount>, favorites: Array<string>) => {
		return getFinancialAccountsOptions(accounts, favorites, null, this.onFavorite)
	})

	updateComponentKeys = () => {
		const { component } = this.state
		const dateComponents = ['START_YEAR', 'START_MONTH', 'END_YEAR', 'END_MONTH']
		return Object.keys(component).reduce((a: any, b: any) => {
			if (!dateComponents.includes(b)) {
				a[b] = component[b]
			}
			return a
		}, {})
	}

	UNSAFE_componentWillMount() {
		if (
			this.props.reportScheme &&
			this.props.reportScheme.components.indexOf('FIN_ACCOUNT') > -1 &&
			this.props.financialAccounts == null
		)
			this.props.loadFinancialAccounts()
	}

	requestReportGeneration = () => {
		const { requestReport, reportScheme, t } = this.props

		let requestParams: GenericReportRequest = {
			type: (reportScheme && reportScheme.type) || 0,
			exportType: this.state.format,
		}

		const hasField = (val: string) => {
			return reportScheme && reportScheme.components.indexOf(val) > -1
		}

		if (hasField('START_YEAR') || hasField('START_MONTH')) {
			let dateFrom = formatDateToIsoString(
				new Date(this.state.component.START_YEAR, this.state.component.START_MONTH - 1),
			)
			requestParams.dateFrom = dateFrom
		}

		if (hasField('END_YEAR') || hasField('END_MONTH')) {
			requestParams.dateTo = moment(new Date(this.state.component.END_YEAR, this.state.component.END_MONTH - 1))
				.endOf(hasField('END_MONTH') ? 'month' : 'year')
				.format('YYYY-MM-DD')
		}

		if (hasField('END_DATE')) {
			if (this.state.component.END_DATE) {
				requestParams.dateTo = formatDateToIsoString(this.state.component.END_DATE)
			}
		}

		if (hasField('FIN_ACCOUNT')) {
			requestParams.finAccount = this.state.component.FIN_ACCOUNT
		}

		requestReport &&
			requestReport(requestParams).then((action: Action) => {
				if (action.apiResponse != null)
					this.props.notify(t('dashboard.widget.genericReports.requestSuccess'), 'success')
			})
	}

	getHandleFieldChange = (fieldName: string) => {
		return (proxy: any, value: any) => {
			const newState = {
				[fieldName]: value,
			}

			this.setState(newState)
		}
	}

	getSelectValidationError(value: ?string) {
		const { t } = this.props

		if (value && value.indexOf('%') === 0) return t('dashboard.widget.genericReports.errors.firstNumber')

		if (value && value.length > 1) {
			if ((value.match(/%/g) || []).length > 1) return t('dashboard.widget.genericReports.errors.singlePercentChar')
			if (value.match(/[^0-9%]/g)) return t('dashboard.widget.genericReports.errors.onlyNumbers')
			if (!value.match(/^[0-6].*$/)) return t('dashboard.widget.genericReports.errors.firstRange')
			if (value.length > 7) return t('dashboard.widget.genericReports.errors.length')
			if (value.length < 7 && value.charAt(value.length - 1) !== '%')
				return t('dashboard.widget.genericReports.errors.lastChar')
		}
		return null
	}

	renderComponents(reportScheme: ReportScheme) {
		if (reportScheme !== emptyReportScheme) {
			const availComponents = this.getAvailableComponents()
			return reportScheme.components.map((component: string) => {
				const isFinAccount = component === 'FIN_ACCOUNT'
				const style = `${styles.item} ${isFinAccount ? styles.fullWidth : ''}`
				return (
					<div key={component} className={style}>
						{availComponents[component]}
					</div>
				)
			})
		} else return null
	}

	validateForm = (): Object => {
		const finAccountError = this.state.finAccountError
		const errors = {}

		if (finAccountError) {
			errors.finAccount = finAccountError
		}

		return errors
	}

	renderActionButton() {
		return (
			<Button
				autoTestId="generate-report-generate-button"
				labelText={this.props.t('dashboard.widget.genericReports.button')}
				onClick={this.props.validationSubmit(this.requestReportGeneration)}
			/>
		)
	}

	renderHeader() {
		return (
			<div className={styles.name}>
				{this.props.reportScheme && this.props.t(`report.${this.props.reportScheme.type}`)}
			</div>
		)
	}

	render() {
		const { t, reportScheme } = this.props
		this.props.validateForm(this.validateForm())

		return (
			<FormBox
				header={this.renderHeader()}
				actions={this.renderActionButton()}
				headline={reportScheme && t(`report.title.${reportScheme.title}`)}
			>
				<div>
					<div className={styles.rowInitial}>{reportScheme && this.renderComponents(reportScheme)}</div>
					<div className={styles.format}>
						{t('dashboard.widget.genericReports.format')}
						<br />
						<div className={styles.radios}>
							<RadioButtonGroup
								name="format"
								onChange={this.getHandleFieldChange('format')}
								valueSelected={this.state.format}
								inline
							>
								<RadioButton value={1} label={t('dashboard.widget.genericReports.pdf')} />
								<RadioButton value={2} label={t('dashboard.widget.genericReports.excel')} />
							</RadioButtonGroup>
						</div>
					</div>
				</div>
			</FormBox>
		)
	}
}

export default withNotify(withTranslate(validate(['FINISH_REQUEST_NEW_GENERIC_REPORT'])(GenerateReport)))
