// @flow

import type {
	AccountingDocumentDefaults,
	AccountingDocumentDirection,
	AccountingDocumentType,
	Dispatch,
	SettingsAction,
} from 'types'
import { AccountingDocumentDirection_Number, AccountingDocumentType_Number } from 'types/convertor'

import { accountingDocumentDefaults as accountingDocumentDefaultsApi } from 'modules/common/models/api-model'
import diff from 'json-patch-gen'

export function loadAccountingDocumentDefaults(
	accountingDocumentType: ?AccountingDocumentType,
	accountingDocumentDirection: ?AccountingDocumentDirection,
) {
	return async (dispatch: Dispatch<SettingsAction>) => {
		dispatch({
			type: 'START_LOADING_ACCOUNTING_DOCUMENT_DEFAULTS',
		})
		try {
			let accountingDocumentTypeId: string = ''
			let accountingDocumentDirectionId: string = ''

			// TYPE
			if (accountingDocumentType) {
				accountingDocumentTypeId = AccountingDocumentType_Number(accountingDocumentType) + ''
			} else {
				accountingDocumentTypeId = 'generic'
			}

			// DIRECTION
			if (accountingDocumentDirection && AccountingDocumentDirection_Number(accountingDocumentDirection) == -1) {
				accountingDocumentDirectionId = 'generic'
			} else if (accountingDocumentDirection) {
				accountingDocumentDirectionId = AccountingDocumentDirection_Number(accountingDocumentDirection) + ''
			} else {
				accountingDocumentDirectionId = 'generic'
			}

			let defaults: ?AccountingDocumentDefaults = await accountingDocumentDefaultsApi.get({
				accountingDocumentTypeId,
				accountingDocumentDirectionId,
			})

			defaults = defaults && Object.keys(defaults).length === 0 ? null : defaults

			return dispatch({
				type: 'FINISH_LOADING_ACCOUNTING_DOCUMENT_DEFAULTS',
				accDirection: accountingDocumentDirection,
				accType: accountingDocumentType,
				defaults,
			})
		} catch (serverError) {
			//error is ignored for now
			return dispatch({
				type: 'FINISH_LOADING_ACCOUNTING_DOCUMENT_DEFAULTS',
				defaults: null,
				accDirection: null,
				accType: null,
			})
		}
	}
}

export function editAccountingDocumentDefaults(
	oldDefaults: ?AccountingDocumentDefaults,
	newDefaults: AccountingDocumentDefaults,
	accountingDocumentDirection: ?AccountingDocumentDirection,
	accountingDocumentType: ?AccountingDocumentType,
) {
	return async (dispatch: Dispatch<SettingsAction>) => {
		const type: number = accountingDocumentType ? AccountingDocumentType_Number(accountingDocumentType) : -1
		const direction: number = accountingDocumentDirection
			? AccountingDocumentDirection_Number(accountingDocumentDirection)
			: -1

		dispatch({
			type: 'START_EDITING_ACCOUNTING_DOCUMENT_DEFAULTS',
			newDefaults,
			accDirection: accountingDocumentDirection || null,
			accType: accountingDocumentType || null,
		})
		try {
			const typeId = type !== -1 ? type + '' : 'generic'
			const directionId = direction !== -1 ? direction + '' : 'generic'

			const patch = diff(oldDefaults, newDefaults)
			await accountingDocumentDefaultsApi.patch(
				{
					accountingDocumentTypeId: typeId,
					accountingDocumentDirectionId: directionId,
				},
				patch,
			)

			dispatch({
				type: 'FINISH_EDITING_ACCOUNTING_DOCUMENT_DEFAULTS',
				accDirection: accountingDocumentDirection,
				accType: accountingDocumentType,
				oldDefaults: null,
				newDefaults,
			})
		} catch (serverError) {
			dispatch({
				serverError,
				type: 'FINISH_EDITING_ACCOUNTING_DOCUMENT_DEFAULTS',
				oldDefaults,
				accDirection: accountingDocumentDirection,
				accType: accountingDocumentType,
				newDefaults: null,
			})
		}
	}
}
