// @flow

import React from 'react'
import { autoTestId, type AutoTestProps } from 'utils/tests/autotest'

type Props = {
	...AutoTestProps,
	children: React$Element<any> | Array<React$Element<any>>,
	style?: {},
	name?: string,
	type?: string,
	disabled?: boolean,
	onMouseEnter?: () => void,
	onMouseLeave?: () => void,
	onClick: () => void,
}

function EnhancedButton(props: Props) {
	const styles = {
		cursor: props.disabled ? 'default' : 'pointer',
		...defaultStyle,
		...props.style,
	}

	return (
		<button
			style={styles}
			disabled={props.disabled}
			onClick={props.disabled ? null : props.onClick}
			onMouseEnter={props.onMouseEnter}
			onMouseLeave={props.onMouseLeave}
			tabIndex={props.disabled ? -1 : 0}
			name={props.name}
			type={props.type || 'button'}
			{...autoTestId(props.autoTestId)}
		>
			{props.children}
		</button>
	)
}

const defaultStyle = {
	border: 0,
	MozAppearance: 'none',
	WebkitAppearance: 'none',
	appearance: 'none',
	boxSizing: 'border-box',
	display: 'inline-block',
	textDecoration: 'none',
	outline: 'none',
	fontFamily: 'inherit',
	fontSize: 'inherit',
	fontWeight: 'inherit',
	position: 'relative',
	verticalAlign: null,
	backgroundColor: 'transparent',
	margin: 0,
	padding: 0,
	zIndex: 1,
}

export default EnhancedButton
