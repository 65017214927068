/* @flow */

import { downloadRequest as downloadRequestApi } from 'modules/common/models/api-model'
import type { Dispatch, FileAction } from 'types'
import { type GetFileResult } from 'modules/file/types'
import { getFileNameFromContentDisposition } from 'modules/file/domain'
import { createXHR, buildPrivateUrl } from 'lib/apiHandler'

export function downloadWithToken(fileId: string) {
	return async (dispatch: Dispatch<FileAction>) => {
		dispatch({
			type: 'START_GETTING_DOWNLOAD_TOKEN',
			fileId,
		})
		try {
			const resp = await downloadRequestApi.post({ fileId })
			if (resp.token) {
				const url = buildPrivateUrl(`uploads/${resp.token}`)
				window.location.href = url
			}
			dispatch({ type: 'FINISH_GETTING_DOWNLOAD_TOKEN' })
		} catch (error) {
			dispatch({
				type: 'FINISH_GETTING_DOWNLOAD_TOKEN',
				serverError: error,
			})
		}
	}
}

/**
 * @param {useDownloadRequest}
 * If true, "/uploads/{fileId}/downloadrequest" is used to get a download token first,
 * the file is then downloaded with this token. We can use it if we dont know organization ID.
 *
 * If false, "/{organizationId}/uploads/{fileId}" is used directly to get the file (with current organization ID).
 */
export async function getFileData(fileId: string, params?: { useDownloadRequest: boolean }): Promise<?GetFileResult> {
	let token = undefined
	if (params && params.useDownloadRequest) {
		// GET /uploads/{fileId}/downloadrequest
		const resp = await downloadRequestApi.post({ fileId })
		token = resp.token
	}

	return new Promise((resolve: Function, reject: Function) => {
		try {
			let xhr
			if (token) {
				// GET /uploads/{token}
				xhr = createXHR(`uploads/${token}`, 'GET', true) // True means private api without organzation ID
			} else {
				// GET /{organizationId}/uploads/{fileId}
				xhr = createXHR(`uploads/${fileId}`, 'GET')
			}
			xhr.responseType = 'blob'
			xhr.onreadystatechange = () => {
				if (4 === xhr.readyState) {
					resolve({
						fileName: getFileNameFromContentDisposition(xhr.getResponseHeader('content-disposition')),
						blob: xhr.response,
					})
				}
			}
			xhr.send({})
		} catch (serverError) {
			reject(null)
		}
	})
}
