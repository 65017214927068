// @flow

const DEFAULT_CACHE_TRESHOLD_IN_HOURS = 12
let lastFetchedTimes: { [string]: Date } = {}

export function needsRefresh(key: string, cacheTresholdInHours: number = DEFAULT_CACHE_TRESHOLD_IN_HOURS): boolean {
	const lastFetched = lastFetchedTimes[key]

	if (!lastFetched) {
		lastFetchedTimes[key] = new Date()
		return true
	}

	const now = new Date()
	const hoursDifference = Math.floor(Math.abs(now - lastFetched) / 36e5)

	if (hoursDifference <= cacheTresholdInHours) {
		return false
	}

	lastFetchedTimes[key] = new Date()
	return true
}

export function clearLastFetchedTime(key: string) {
	delete lastFetchedTimes[key]
}

export function clearLastFetchedTimes() {
	lastFetchedTimes = {}
}
