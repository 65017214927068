/* @flow */

import { getAppLocale, i18n } from 'locales'

export const formatToPercentage = (amount: ?number): string => {
	if (amount == null) {
		return ''
	}
	return `${amount} %`
}

type FormatOptions = {
	currency?: ?string,
	minimumFractionDigits?: ?number,
	maximumFractionDigits?: ?number,
}

export const formatToMoney = (amount: ?number, options?: FormatOptions = {}): string => {
	if (amount == null) {
		return ''
	}
	const { currency } = options
	const minimumFractionDigits =
		options.minimumFractionDigits !== null && options.minimumFractionDigits !== undefined
			? options.minimumFractionDigits
			: 2
	const maximumFractionDigits =
		options.maximumFractionDigits !== null && options.maximumFractionDigits !== undefined
			? options.maximumFractionDigits
			: 2
	try {
		if (toLocaleStringSupportsOptions()) {
			return (amount || 0).toLocaleString(getAppLocale(), {
				style: currency ? 'currency' : 'decimal',
				currency: currency ? currency : undefined,
				currencyDisplay: 'code',
				minimumFractionDigits: minimumFractionDigits,
				maximumFractionDigits: Math.max(minimumFractionDigits, maximumFractionDigits),
			})
		} else {
			return (amount || 0) + ' ' + (currency || '')
		}
	} catch (e) {
		console.error(e)//eslint-disable-line
		return ''
	}
}

export const formatToInteger = (number: ?number): string => {
	if (number == null) {
		return ''
	}
	if (toLocaleStringSupportsOptions()) {
		return (number || 0).toLocaleString(getAppLocale(), {
			style: 'decimal',
			maximumFractionDigits: 0,
		})
	} else {
		return (number || 0) + ''
	}
}

function toLocaleStringSupportsOptions() {
	return !!(typeof window.Intl === 'object' && window.Intl && typeof window.Intl.NumberFormat == 'function')
}

export function getThousandsSeparator() {
	return i18n.t('application.number.thousandsSep')
}

export function getDecimalPoint() {
	return i18n.t('application.number.decimalPoint')
}
