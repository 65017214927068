/* @flow */

import MainLayout from 'layouts/main-layout'
import { UserIsAuthenticated } from 'lib/auth'
import React from 'react'
import { Route } from 'react-router'
import DocumentExtractionPage from '../pages/document-extraction-page'
import type { AccountingDocumentExtractsFetchResponse } from 'types'

const DocumentExtractionComponent = UserIsAuthenticated(MainLayout('documentExtraction')(DocumentExtractionPage))

const DocumentExtractionRouting = () => [
	<Route
		exact
		key="document-extraction"
		path="/:organizationId/documents-extraction/:id"
		component={DocumentExtractionComponent}
	/>,
]

export default DocumentExtractionRouting

export function documentExtractionRoute(result: AccountingDocumentExtractsFetchResponse) {
	return `/${result.organizationId || 'error'}/documents-extraction/${result.accountingDocumentId || 'error'}`
}
