/* @flow */

import type { Dispatch, EmailTemplate, State } from 'types'
import { emailTemplate as emailTemplateApi, emailTemplates as emailTemplatesApi } from 'modules/common/models/api-model'

import type { Action } from './email-action-types'

export function loadEmailTemplates() {
	return async (dispatch: Dispatch<Action>) => {
		dispatch({
			type: 'START_LOADING_EMAIL_TEMPLATES',
		})
		try {
			const emailTemplates: ?Array<EmailTemplate> = await emailTemplatesApi.get({})
			dispatch({
				type: 'FINISH_LOADING_EMAIL_TEMPLATES',
				emailTemplates,
			})
		} catch (serverError) {
			dispatch({
				serverError,
				type: 'FINISH_LOADING_EMAIL_TEMPLATES',
				emailTemplates: null,
			})
		}
	}
}

export function createEmailTemplate(emailTemplate: EmailTemplate) {
	return async (dispatch: Dispatch<Action>) => {
		dispatch({
			type: 'START_CREATING_EMAIL_TEMPLATE',
		})
		try {
			const result: EmailTemplate = await emailTemplatesApi.post(emailTemplate)
			dispatch({
				type: 'FINISH_CREATING_EMAIL_TEMPLATE',
				emailTemplate: result,
			})
		} catch (error) {
			dispatch({
				validationError: error,
				type: 'FINISH_CREATING_EMAIL_TEMPLATE',
				emailTemplate: null,
			})
		}
	}
}

export function editEmailTemplate(emailTemplateId: string, emailTemplate: EmailTemplate) {
	return async (dispatch: Dispatch<Action>, getState: () => State) => {
		const state: State = getState()
		const oldEmailTemplate: ?EmailTemplate =
			state.settings.emailTemplates &&
			state.settings.emailTemplates.find((t: EmailTemplate) => t.id === emailTemplateId)

		if (!oldEmailTemplate) {
			return
		}

		let newEmailTemplate: EmailTemplate = emailTemplate
		dispatch({
			type: 'START_EDITING_EMAIL_TEMPLATE',
			newEmailTemplate,
		})
		try {
			newEmailTemplate = await emailTemplateApi.put({ emailTemplateId }, emailTemplate)
			dispatch({
				type: 'FINISH_EDITING_EMAIL_TEMPLATE',
				newEmailTemplate,
				oldEmailTemplate,
			})
		} catch (serverError) {
			dispatch({
				serverError,
				type: 'FINISH_EDITING_EMAIL_TEMPLATE',
				newEmailTemplate,
				oldEmailTemplate,
			})
		}
	}
}

export function deleteEmailTemplate(emailTemplateId: string) {
	return async (dispatch: Dispatch<Action>, getState: () => State) => {
		const state: State = getState()
		const oldEmailTemplates: ?Array<EmailTemplate> = state.settings.emailTemplates
		const newEmailTemplates: ?Array<EmailTemplate> =
			state.settings.emailTemplates &&
			state.settings.emailTemplates.filter((t: EmailTemplate) => t.id !== emailTemplateId)

		dispatch({
			type: 'START_DELETING_EMAIL_TEMPLATE',
			newEmailTemplates,
		})
		try {
			await emailTemplateApi.delete({ emailTemplateId })
			dispatch({
				type: 'FINISH_DELETING_EMAIL_TEMPLATE',
				newEmailTemplates,
				oldEmailTemplates,
			})
		} catch (serverError) {
			dispatch({
				serverError,
				type: 'FINISH_DELETING_EMAIL_TEMPLATE',
				newEmailTemplates: null,
				oldEmailTemplates,
			})
		}
	}
}
