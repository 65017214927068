/* @flow */

import React from 'react'
import StoreIcon, { type Props } from './store-icon'
import { GOOGLE_APP_LINK } from 'modules/navigation/constants'
import googlePlayIcon from 'assets/images/mobile-app/google-play.svg'

function GooglePlayIcon(props: Props) {
	return <StoreIcon {...props} alt={'Google Play'} link={GOOGLE_APP_LINK} image={googlePlayIcon} />
}

export default GooglePlayIcon
