/* @flow */

import { connect } from 'react-redux'
import type { Currency, Dispatch, State, OrganizationAction } from 'types'
import FilterCurrency from '../components/filter-currency'
import { loadCurrenciesUsedInOrg } from 'modules/organization/actions'
import withAutoload from 'wrappers/with-autoload'
import { EMPTY_ARRAY } from 'trivi-constants'

type StateProps = {
	currencies: Array<Currency>,
}

const mapStateToProps = (state: State): StateProps => {
	return {
		currencies: state.organization.currenciesUsedInOrg.data || EMPTY_ARRAY,
	}
}

type DispatchProps = {|
	autoload: () => void,
|}

const mapDispatchToProps = (dispatch: Dispatch<OrganizationAction>): DispatchProps => {
	return {
		autoload: (): void => {
			dispatch(loadCurrenciesUsedInOrg())
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(withAutoload(FilterCurrency))
