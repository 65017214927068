import React from 'react'
import pure from 'recompose/pure'
import SvgIcon from 'components/SvgIcon'

let Documents = props => (
	<SvgIcon {...props}>
		<defs>
			<polygon
				id="path-1"
				points="7.96720295 0.181404074 7.96720295 7.00524577 0.214556313 7.00524577 0.214556313 0.181404074 7.96720295 0.181404074"
			/>
			<polygon id="path-3" points="2 23.0420616 21.5216026 23.0420616 21.5216026 1.88862221 2 1.88862221" />
		</defs>
		<g id="Page-1" stroke="none" strokeWidth="1" fillRule="evenodd">
			<g id="Apple-TV">
				<g id="Group">
					<g id="documents">
						<g id="Group-3" transform="translate(13.554400, 1.710862)">
							<mask id="mask-2">
								<use xlinkHref="#path-1" />
							</mask>
							<g id="Clip-2" />
							<path
								d="M4.55901058,0.181404074 L0.214556313,0.181404074 C0.442977906,0.496039265 0.671399499,0.812452055 0.904265092,1.12086565 C1.00469949,1.25418564 1.09091309,1.39194964 1.16734988,1.53238003 C1.30422508,1.54926723 1.44110028,1.56437683 1.58153067,1.59637363 C1.89883226,1.66925523 2.19835785,1.80168642 2.48721784,1.95900402 L4.55901058,1.95900402 C5.45847615,1.95900402 6.18995853,2.6904864 6.18995853,3.58906317 L6.18995853,5.22001112 L2.15036265,5.22001112 C2.19569145,5.47865191 2.21968905,5.7381815 2.21257865,5.99682229 C2.24724185,6.08747989 2.16458345,6.24924149 2.07125946,6.26879509 C1.64819067,6.35767508 1.75929067,7.08737986 2.18235945,6.99761106 L7.96755847,6.99761106 L7.96755847,3.58906317 C7.96755847,1.71014003 6.43882252,0.181404074 4.55901058,0.181404074"
								id="Fill-1"
								mask="url(#mask-2)"
							/>
						</g>
						<g id="Group-6" transform="translate(2.000000, 1.710862)">
							<path
								d="M11.37344,16.5395898 L13.3110239,17.9101194 L13.3323551,17.2088562 L13.3323551,5.19672456 C13.3092463,4.68210938 13.2736943,2.84762623 13.7749775,1.95527106 L3.40801461,1.95527106 C2.50854904,1.95527106 1.77795547,2.68675344 1.77795547,3.58621901 L1.77795547,17.3972818 L3.14581862,16.5502554 L5.18294816,17.716361 L7.1614169,16.360941 L9.17810404,17.7305818 L11.37344,16.5395898 Z M15.0672927,21.3311105 L11.249008,18.6300473 L9.07500324,19.8094849 L7.1658609,18.5127257 L5.26738416,19.8130401 L3.18137062,18.6184929 L0.000355519989,20.5871849 L0.000355519989,3.58621901 C0.000355519989,1.70729587 1.52909147,0.177671115 3.40801461,0.177671115 L16.3293886,0.177671115 L16.372051,1.95438226 C16.3773838,1.95527106 15.8183286,2.03170786 15.3197118,2.83340543 C15.1686159,3.08582462 15.0672927,4.19415819 15.1090663,5.15939496 L15.1099551,17.2177442 L15.0672927,21.3311105 Z"
								id="Fill-4"
							/>
						</g>
						<polygon
							id="Fill-7"
							points="5.1392415 13.3105907 13.9730244 13.3105907 13.9730244 11.2592403 5.1392415 11.2592403"
						/>
						<polygon
							id="Fill-9"
							points="5.1392415 16.3378434 13.9730244 16.3378434 13.9730244 14.286493 5.1392415 14.286493"
						/>
						<polygon
							id="Fill-11"
							points="5.1392415 19.3428761 13.9730244 19.3428761 13.9730244 17.2915257 5.1392415 17.2915257"
						/>
						<polygon
							id="Fill-13"
							points="5.1392415 9.29943638 7.19059184 9.29943638 7.19059184 7.24808605 5.1392415 7.24808605"
						/>
						<mask id="mask-4">
							<use xlinkHref="#path-3" />
						</mask>
						<g id="Clip-16" />
						<polygon
							id="Fill-15"
							mask="url(#mask-4)"
							points="8.5886742 9.29943638 13.9730244 9.29943638 13.9730244 7.24808605 8.5886742 7.24808605"
						/>
					</g>
				</g>
			</g>
		</g>
	</SvgIcon>
)
Documents = pure(Documents)

export default Documents
