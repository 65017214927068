// @flow

import deepEqual from 'deep-equal'
import type {
	AccountingDocumentActions,
	AccountingDocumentDirection,
	AccountingDocumentListAction,
	AccountingDocumentView,
} from 'types'
import { EMPTY_OBJECT } from 'trivi-constants'

export type ActionsListState = { [string]: ActionsState }

type ActionsState = {
	ids: Array<string>,
	data?: ?AccountingDocumentActions,
}

export type State = {
	direction: ?AccountingDocumentDirection,
	view: AccountingDocumentView,
	actions: ActionsListState,
}

export const initialState: State = {
	direction: null,
	view: 'all',
	actions: EMPTY_OBJECT,
}

function accountingDocumentListReducer(state: State = initialState, action: AccountingDocumentListAction): State {
	switch (action.type) {
		case 'CHANGE_ACCOUNTING_DOCUMENT_LIST_DIRECTION':
			return {
				...state,
				direction: action.direction,
			}

		case 'CHANGE_ACCOUNTING_DOCUMENT_LIST_VIEW':
			return {
				...state,
				view: action.view,
			}

		case 'FINISH_LOAD_ACCOUNTING_DOCUMENT_ACTIONS': {
			const key: string = action.ids.join('_')
			const actionsListState: ActionsListState = { ...state.actions }
			const newActions = action.actions || []
			const oldActions = actionsListState[key] ? actionsListState[key].data : []
			if (!deepEqual(oldActions, newActions)) {
				actionsListState[key] = {
					...actionsListState[key],
					data: newActions,
				}
			} else {
				return state
			}
			return { ...state, actions: actionsListState }
		}

		default:
			return state
	}
}

export default accountingDocumentListReducer
