// @flow

import type { AccountingDocumentFromScanRequest, Dispatch, Scan, UploadScanAction, UploadScanFile } from 'types'
import {
	accountingDocumentsScans as accountingDocumentsScansApi,
	uploads as uploadsApi,
	type AccountingDocumentsScansPostResponse,
} from 'modules/common/models/api-model'
import { File_UploadScanFile } from 'types/convertor'
import { map } from 'lodash-es'

let uploadScanId = 1

export function uploadScan(file: File, documentCategory: ?string, customUploadScanId?: number) {
	return async (dispatch: Dispatch<UploadScanAction>) => {
		const uploadScanFile: UploadScanFile = File_UploadScanFile(file, documentCategory)
		const uploadId = customUploadScanId || ++uploadScanId

		dispatch({
			type: 'START_UPLOAD_ACCOUNTING_DOCUMENT_SCAN',
			uploadId,
			uploadScanFile,
		})
		try {
			const result = await uploadsApi.post({}, { file }, { timeout: 0 })
			return dispatch({
				type: 'FINISH_UPLOAD_ACCOUNTING_DOCUMENT_SCAN',
				uploadId,
				uploadScanFile,
				confirmedFileId: result.id || '', //TODO
			})
		} catch (serverError) {
			return dispatch({
				type: 'ERROR_UPLOAD_ACCOUNTING_DOCUMENT_SCAN',
				uploadId,
				serverError,
				file,
				documentCategory,
			})
		}
	}
}

export function addScan(scan: Scan) {
	const newScan: Scan = { ...scan, id: ++uploadScanId }
	return {
		type: 'ADD_ACCOUNTING_DOCUMENT_SCAN',
		scan: newScan,
	}
}

export function checkToggle(id: number, value: boolean): UploadScanAction {
	return {
		type: 'TOGGLE_ACCOUNTING_DOCUMENT_SCAN',
		payload: {
			id,
			checked: value,
		},
	}
}

export function checkToggleAll(value: boolean): UploadScanAction {
	return {
		type: 'TOGGLE_ALL_ACCOUNTING_DOCUMENT_SCANS',
		payload: {
			checked: value,
		},
	}
}

export function changePaymentType(id: number, paymentType: string): UploadScanAction {
	return {
		type: 'CHANGE_ACCOUNTING_DOCUMENT_SCAN_PAYMENT_TYPE',
		payload: {
			id,
			paymentType,
		},
	}
}

export function changeBranch(id: number, branch: ?string): UploadScanAction {
	return {
		type: 'CHANGE_ACCOUNTING_DOCUMENT_SCAN_BRANCH',
		payload: {
			id,
			branch: branch || null,
		},
	}
}

export function changeProject(id: number, project: ?string): UploadScanAction {
	return {
		type: 'CHANGE_ACCOUNTING_DOCUMENT_SCAN_PROJECT',
		payload: {
			id,
			project: project || null,
		},
	}
}

export function changeCashRegister(id: number, cashRegisterId: string): UploadScanAction {
	return {
		type: 'CHANGE_ACCOUNTING_DOCUMENT_SCAN_CASH_REGISTER',
		payload: {
			id,
			cashRegisterId,
		},
	}
}

export function changeCustomerInstructions(id: number, customerInstructions: string): UploadScanAction {
	return {
		type: 'CHANGE_ACCOUNTING_DOCUMENT_SCAN_CUSTOMER_INSTRUCTIONS',
		payload: {
			id,
			customerInstructions,
		},
	}
}

export function changeCategory(id: number, documentCategory: ?string): UploadScanAction {
	return {
		type: 'CHANGE_ACCOUNTING_DOCUMENT_SCAN_CATEGORY',
		payload: {
			id,
			documentCategory,
		},
	}
}

export function deleteScan(id: number): UploadScanAction {
	return {
		type: 'DELETE_ACCOUNTING_DOCUMENT_SCAN',
		payload: {
			id,
		},
	}
}

//TODO: specifikovat payload
export function updateScan(payload: Object): UploadScanAction {
	return {
		type: 'UPDATE_ACCOUNTING_DOCUMENT_SCAN',
		payload,
	}
}

export function joinScans(files: Array<Object>): UploadScanAction {
	return {
		type: 'JOIN_ACCOUNTING_DOCUMENT_SCANS',
		payload: {
			files,
		},
	}
}

// Create new accounting documnent with scans
export function createAccountingDocumentWithScans(
	data: Array<AccountingDocumentFromScanRequest>,
	callback?: (accountingDocumentIds: Array<string>) => void,
) {
	return async (dispatch: Dispatch<UploadScanAction>) => {
		dispatch({
			type: 'START_CREATE_AD_WITH_SCANS',
		})
		try {
			const scans: AccountingDocumentsScansPostResponse = await accountingDocumentsScansApi.post({}, data, {
				timeout: 0,
			})
			callback && callback(map(scans, 'accountingDocumentId'))
			return dispatch({
				type: 'FINISH_CREATE_AD_WITH_SCANS',
				scans,
			})
		} catch (error) {
			return dispatch({
				type: 'ERROR_CREATE_AD_WITH_SCANS',
				serverError: error,
			})
		}
	}
}
