import PropTypes from 'prop-types'
import React from 'react'

const styles = {
	width: '100%',
	position: 'relative',
	textAlign: 'initial',
}

const VerticalTabTemplate = ({ children, selected, style }) => {
	const templateStyle = Object.assign({}, styles, style)
	if (!selected) {
		templateStyle.height = 0
		templateStyle.overflow = 'hidden'
	}

	return <div style={templateStyle}>{children}</div>
}

VerticalTabTemplate.propTypes = {
	children: PropTypes.node,
	selected: PropTypes.bool,
	style: PropTypes.object,
}

export default VerticalTabTemplate
