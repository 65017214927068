/* @flow */

import React, { Component } from 'react'
import { default as DropzoneComponent } from 'react-dropzone'
import { type WithTranslateProps, type WithNotifyProps, withNotify, withTranslate } from 'wrappers'
import { type AutoTestProps } from 'utils/tests/autotest'
import ActionBackup from 'components/svg-icons/action/backup'
import Loader from 'components/loader'
import Avatar from 'components/avatar'
import styles from './dropzone.css'
import { colors } from 'variables'
import { formatBytes } from 'utils/formatters/size-formatter'

const MAX_SIZE = 20971520 // 20 MB in bytes

type Props = {|
	...AutoTestProps,
	...WithNotifyProps,
	...WithTranslateProps,
	children?: any,
	showDropHint: boolean,
	disableClick?: boolean,
	accept?: string,
	multiple?: boolean,
	hintText?: string,
	minHeight?: number,
	onDrop?: (Array<File>) => ?Promise<*>,
	onDropAccepted?: (files: Array<File>) => ?Promise<void>,
	onDropRejected?: (files: Array<File>) => ?Promise<void>,
	onFileDialogCancel?: () => ?Promise<void>,
|}

type State = {|
	uploading: boolean,
	hovering: boolean,
|}

class Dropzone extends Component<Props, State> {
	static defaultProps = {
		showDropHint: true,
	}

	state: State = {
		uploading: false,
		hovering: false,
	}

	dropzone: ?DropzoneComponent

	onDropFiles = async (files: Array<File>) => {
		const { onDrop } = this.props
		if (onDrop) {
			this.setState({ uploading: true })
			await onDrop(files)
			this.setState({ uploading: false })
		}
	}

	onDropAccepted = (files: Array<File>) => {
		this.setState({ hovering: false })
		this.props.onDropAccepted && this.props.onDropAccepted(files)
	}

	getMaxSizeString = () => {
		return formatBytes(MAX_SIZE)
	}

	onDropRejected = (files: Array<File>) => {
		let maxSizeMessages: Array<string> = []
		let acceptMessages: Array<string> = []

		const largeFiles = files.filter((file: File) => file.size > MAX_SIZE)
		largeFiles.forEach((file: File) => {
			maxSizeMessages.push(this.props.t('dropzone.fileIsTooLarge', { fileName: file.name }))
		})
		largeFiles.length > 0 &&
			maxSizeMessages.push(this.props.t('dropzone.maxSizeIs', { maxSize: this.getMaxSizeString() }))

		files
			.filter((file: File) => !file.type || (this.props.accept && this.props.accept.indexOf(file.type) < 0))
			.forEach((file: File) => {
				acceptMessages.push(this.props.t('dropzone.unsupportedFileTypeError', { fileName: file.name }))
			})

		this.props.notify([...maxSizeMessages, acceptMessages].join('\n'), 'error')

		this.setState({ hovering: false })
	}

	onDragLeave = () => {
		this.setState({ hovering: false })
	}

	onDragEnter = () => {
		this.setState({ hovering: true })
	}

	open() {
		this.dropzone && this.dropzone.open()
	}

	bindDropZone = (d: DropzoneComponent) => {
		this.dropzone = d
	}

	renderContent() {
		return (
			<div className={styles.content}>
				<Avatar
					className={styles.avatar}
					size={32}
					backgroundColor={colors.orange}
					hoverColor={colors.white}
					icon={<ActionBackup />}
				/>
				<div className={styles.hintText}>{this.props.hintText || this.props.t('dropzone.hintText')}</div>
				<div className={styles.acceptText}>{this.props.t('dropzone.dropFilesHereToUpload')}</div>
				<div className={styles.rejectText}>{this.props.t('dropzone.unsupportedFileTypeHint')}</div>
			</div>
		)
	}

	render() {
		return (
			<DropzoneComponent
				className={`${this.props.disableClick ? styles.clickDisabled : styles.normal}`}
				style={{ minHeight: this.props.minHeight || 'auto' }}
				activeClassName={styles.active}
				acceptClassName={styles.accept}
				rejectClassName={styles.reject}
				ref={this.bindDropZone}
				disabled={this.state.uploading}
				multiple={this.props.multiple}
				onDrop={this.onDropFiles}
				onDragEnter={this.onDragEnter}
				onDragLeave={this.onDragLeave}
				onDropAccepted={this.onDropAccepted}
				onDropRejected={this.onDropRejected}
				accept={this.props.accept}
				disableClick={this.props.disableClick}
				onFileDialogCancel={this.props.onFileDialogCancel}
				id={this.props.autoTestId}
				maxSize={MAX_SIZE}
			>
				<Loader visible={this.state.uploading} />
				{(this.props.children && !this.state.hovering) || !this.props.showDropHint
					? this.props.children
					: this.renderContent()}
			</DropzoneComponent>
		)
	}
}

export default withTranslate(withNotify(Dropzone), { withRef: true })
