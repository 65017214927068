// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._204nvpq-Aavf3ljyNs8fUd {\n\n}\n\n._204nvpq-Aavf3ljyNs8fUd:before {\n\tcontent: '';\n\tdisplay: table;\n}\n\n._204nvpq-Aavf3ljyNs8fUd:after {\n\tcontent: '';\n\tdisplay: table;\n\tclear: both;\n}\n\n.a40AxRaDk-n9pn_EveSZh {\n\tmargin: 30px 0;\n}\n", "",{"version":3,"sources":["webpack://./modules/task/components/task-edit-files.css"],"names":[],"mappings":"AAAA;;AAEA;;AAEA;CACC,WAAA;CACA,cAAA;AACD;;AAEA;CACC,WAAA;CACA,cAAA;CACA,WAAA;AACD;;AAEA;CACC,cAAA;AACD","sourcesContent":[".row {\n\n}\n\n.row:before {\n\tcontent: '';\n\tdisplay: table;\n}\n\n.row:after {\n\tcontent: '';\n\tdisplay: table;\n\tclear: both;\n}\n\n.dropzone {\n\tmargin: 30px 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"row": "_204nvpq-Aavf3ljyNs8fUd",
	"dropzone": "a40AxRaDk-n9pn_EveSZh"
};
export default ___CSS_LOADER_EXPORT___;
