// @flow
/** @jsx jsx */

// $FlowFixMe - there is useState
import { useState, useEffect } from 'react'
import { jsx } from '@emotion/core'
import type { PaymentReminder, EmailTemplate } from 'types'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import EditEmailTemplate from '../../containers/edit-email-template'
import TableHeaderCell from 'components/table/table-header-cell'
import EditPaymentReminder from '../../containers/edit-payment-reminder'
import TableHeader from 'components/table/table-header'
import TableBody from 'components/table/table-body'
import TableRow from 'components/table/table-row'
import Table from 'components/table/table'
import PaymentReminderItem from './payment-reminder-item'
import Button from 'components/button'

type Props = {|
	...WithTranslateProps,
	emailTemplates: ?Array<EmailTemplate>,
	paymentReminders: ?Array<PaymentReminder>,
	loadEmailTemplates: () => void,
	loadPaymentReminders: () => void,
	deletePaymentReminder: (id: string) => void,
|}

function PaymentReminders(props: Props) {
	const [edit, setEdit] = useState<string>(undefined)
	const [create, setCreate] = useState<boolean>(false)
	const [templateEdit, setTemplateEdit] = useState<string>(undefined)

	useEffect(() => {
		if (!props.paymentReminders) {
			props.loadPaymentReminders()
		}
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		if (!props.emailTemplates) {
			props.loadEmailTemplates()
		}
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	function closeDialog() {
		setEdit(null)
		setCreate(false)
	}

	function closeTemplateDialog() {
		setTemplateEdit(null)
	}

	function createPaymentReminder() {
		setCreate(true)
	}

	function onEdit(paymentReminderId: string) {
		setEdit(paymentReminderId)
	}

	function onTemplateEdit(emailTemplateId: string) {
		setTemplateEdit(emailTemplateId)
	}

	return (
		<div>
			<div css={style.addButton}>
				<Button
					primary
					labelText={props.t('settings.paymentReminders.createButton')}
					onClick={createPaymentReminder}
					autoTestId="settings-reminders-create"
				/>
			</div>

			<div css={style.table}>
				{props.paymentReminders && props.paymentReminders.length ? (
					<Table>
						<TableHeader>
							<TableRow>
								<TableHeaderCell width="65%">{props.t('settings.paymentReminders.templateName')}</TableHeaderCell>
								<TableHeaderCell width="35%">{props.t('settings.paymentReminders.exceededDays')}</TableHeaderCell>
								<TableHeaderCell width={35} />
							</TableRow>
						</TableHeader>
						<TableBody>
							{(props.paymentReminders || []).map((reminder: PaymentReminder) => (
								<PaymentReminderItem
									onEdit={onEdit}
									reminder={reminder}
									onTemplateEdit={onTemplateEdit}
									onDelete={props.deletePaymentReminder}
									emailTemplates={props.emailTemplates}
									key={reminder.id}
								/>
							))}
						</TableBody>
					</Table>
				) : (
					props.t('settings.paymentReminders.noReminders')
				)}
			</div>
			{(edit || create) && <EditPaymentReminder key={edit || create} open id={edit} onRequestClose={closeDialog} />}
			{!!templateEdit && (
				<EditEmailTemplate key={templateEdit} open id={templateEdit} onRequestClose={closeTemplateDialog} />
			)}
		</div>
	)
}

const style = {
	addButton: {
		display: 'block',
		marginBottom: 30,
	},
	table: {
		marginTop: 25,
	},
}

export default withTranslate(PaymentReminders)
