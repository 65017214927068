//@flow
/** @jsx jsx */

import { Component } from 'react'
import { jsx } from '@emotion/core'
import type { Contact } from 'types'
import { emptyContact } from 'types/empty'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import SettingsHeading from './settings-heading'
import Button from 'components/button'
import NumberInput from 'components/number-input'

type Props = {|
	...WithTranslateProps,
	accountingDocumentId: ?string,
	readonly?: boolean,
	contact: ?Contact,
	isTemplate?: boolean,
	saveContact: (contact: Contact) => Promise<any>,
	onBackwardClick: () => void,
|}

type State = {
	fields: {
		defaultDuePeriod: ?number,
	},
	contact: Contact,
	saving: boolean,
}

class ContactSettings extends Component<Props, State> {
	constructor(props: Props) {
		super(props)
		this.state = {
			fields: {
				defaultDuePeriod:
					(props.contact && props.contact.defaultDuePeriod && props.contact.defaultDuePeriod) ?? undefined,
			},
			contact: props.contact || emptyContact(),
			saving: false,
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		this.setState({
			fields: {
				defaultDuePeriod:
					(nextProps.contact && nextProps.contact.defaultDuePeriod && nextProps.contact.defaultDuePeriod) ?? undefined,
			},
			contact: nextProps.contact || emptyContact(),
		})
	}

	onDefaultDuePeriodChange = (event: SyntheticInputEvent<HTMLInputElement>, defaultDuePeriod: ?number) => {
		this.setState({
			fields: {
				...this.state.fields,
				defaultDuePeriod: defaultDuePeriod ?? undefined,
			},
		})
	}

	onDefaultDuePeriodBlur = () => {
		let defaultDuePeriod: ?number = parseInt(this.state.fields.defaultDuePeriod)
		if (Number.isNaN(defaultDuePeriod)) {
			defaultDuePeriod = null
		}
		const contact: Contact = Object.assign(this.state.contact, {
			defaultDuePeriod: defaultDuePeriod ?? undefined,
		})
		this.setState({ contact })
	}

	onCancel = () => {
		this.props.onBackwardClick()
	}

	onSave = () => {
		this.setState({ saving: true })
		if (this.props.isTemplate) {
			this.close()
		} else {
			this.props.saveContact(this.state.contact).then(this.close)
		}
	}

	close = () => {
		this.setState({ saving: false })
		this.props.onBackwardClick()
	}

	isDisabled = () => {
		return this.props.readonly || !this.props.contact || this.state.saving
	}

	renderButtons = () => {
		if (this.props.readonly) {
			return null
		}
		return (
			<div>
				<Button
					tertiary
					labelText={this.props.t('settings.forms.saveButton')}
					disabled={this.isDisabled()}
					onClick={this.onSave}
					autoTestId="inovice-settings-contact-save"
				/>
				<Button
					autoTestId="inovice-settings-contact-cancel"
					transparent
					labelText={this.props.t('settings.forms.cancelButton')}
					onClick={this.onCancel}
				/>
			</div>
		)
	}

	renderContent = () => {
		return (
			<div>
				<div css={styles.input}>
					<NumberInput
						onChange={this.onDefaultDuePeriodChange}
						onBlur={this.onDefaultDuePeriodBlur}
						value={this.state.fields.defaultDuePeriod}
						labelText={this.props.t('invoice.settings.duePeriod.label')}
						disabled={this.isDisabled()}
						minValue={0}
						fullWidth
						name="due-period"
					/>
				</div>
				{this.renderButtons()}
			</div>
		)
	}

	render() {
		return (
			<div css={styles.root}>
				<SettingsHeading
					onGoBack={this.props.onBackwardClick}
					text={this.props.t('invoice.settings.duePeriod.headline')}
				/>
				{this.renderContent()}
			</div>
		)
	}
}

const styles = {
	root: {
		paddingRight: 10,
	},
	input: {
		marginBottom: 20,
	},
}

export default withTranslate(ContactSettings)
