// @flow
import type { OrganizationStatisticsKeysCashRegisters, OrganizationStatisticsKeysCashRegister } from 'types'

export function countCashRegistersDocuments(cashRegisters?: OrganizationStatisticsKeysCashRegisters): number {
	if (!cashRegisters) {
		return 0
	}
	const result = (cashRegisters.cashRegisters || []).reduce(
		(result: number, item: OrganizationStatisticsKeysCashRegister) => {
			let count = 0
			if (item.accountingDocuments && item.accountingDocuments.notProcessedWithOK) {
				const value = item.accountingDocuments.notProcessedWithOK.count
				if (value) {
					count += value
				}
			}
			return (result += count)
		},
		0,
	)
	return result
}
