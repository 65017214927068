/* @flow */

import { connect } from 'react-redux'
import PaymentOrdersGrid from './bank-paymentorders-grid-component'
import { deletePaymentOrder } from 'modules/bank/actions'
import { downloadWithToken } from 'modules/file/actions'
import { userHasAccess } from 'permissions'
import type { DataGridId, DataGridNextAction, Dispatch, Filter, State } from 'types'
import type { DataType } from '../../types'
import { refreshGridData } from '../../actions'

type StateProps = {|
	canDeleteOrder: boolean,
|}

const mapStateToProps = (state: State): StateProps => {
	return {
		canDeleteOrder: userHasAccess(state, 'editBankAccounts'),
	}
}

type DispatchProps = {|
	deletePaymentOrder: (bankAccountId: string, paymentOrderId: string) => Promise<void>,
	downloadFile: (fileId: string) => void,
	refreshGrid: (gridId: DataGridId, gridDataType: DataType, defaultFilter?: Filter) => void,
|}

const mapDispatchToProps = (dispatch: Dispatch<DataGridNextAction | any>): DispatchProps => {
	return {
		deletePaymentOrder: async (bankAccountId: string, paymentOrderId: string): Promise<void> => {
			await dispatch(deletePaymentOrder(bankAccountId, paymentOrderId))
		},
		downloadFile: (fileId: string): void => {
			dispatch(downloadWithToken(fileId))
		},
		refreshGrid: (gridId: DataGridId, gridDataType: DataType, defaultFilter?: Filter) => {
			dispatch(refreshGridData(gridDataType, gridId, gridId, defaultFilter))
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentOrdersGrid)
