/* @flow */

import { connect } from 'react-redux'
import type { State, AccountingDocument } from 'types'
import { getOrganizationColor } from 'modules/organization/selectors'
import TotalToPay from '../../components/invoice-elements/total-to-pay'

type OwnProps = {|
	accountingDocument: AccountingDocument,
|}

type StateProps = {|
	total: ?number,
	currency?: string,
	color: ?string,
|}

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => {
	return {
		total: ownProps.accountingDocument.total,
		currency: ownProps.accountingDocument.currency,
		color: getOrganizationColor(state),
	}
}

export default connect(mapStateToProps)(TotalToPay)
