// @flow

import type {
	AccountingDocumentAttachment,
	CheckedDocItem,
	DocumentItem,
	DocumentDescriptor,
	I18NextTranslateFn,
} from 'types'
import { DOCUMENT_ITEM_TYPE_FILE, DOCUMENT_ITEM_TYPE_FOLDER } from 'trivi-constants'

export function getDocumentItemName(t: I18NextTranslateFn, documentItem: DocumentItem | DocumentDescriptor): string {
	const name = documentItem.name || ''
	if (name.indexOf('folder.system.') === 0) {
		return t('document.' + name + '._name')
	}
	return name
}

export function createCheckedDocItem(
	docItem: DocumentItem,
	showFileCheckbox?: boolean,
	showFolderCheckbox?: boolean,
	showActions: boolean,
	canBeChanged: boolean,
	canBeRemoved: boolean,
): CheckedDocItem {
	const checkedDocItem = {
		uniqueId: docItem && docItem.uniqueId ? docItem.uniqueId : '',
		fileId: docItem && docItem.fileId ? docItem.fileId : '',
		itemType: docItem && docItem.itemType ? docItem.itemType : DOCUMENT_ITEM_TYPE_FILE,
		hasVisibleCheckboxBasic: getShowDocListItemCheckboxBasic(docItem, showFileCheckbox, showFolderCheckbox),
		hasVisibleRightActions: getShowRightIconMenu(docItem, showActions, canBeChanged, canBeRemoved),
	}

	return checkedDocItem
}

export function createCheckedDocItemFromAttachment(document: AccountingDocumentAttachment): CheckedDocItem {
	const checkedDocItem = {
		uniqueId: document && document.documentUniqueId ? document.documentUniqueId : '',
		fileId: document && document.fileId ? document.fileId : '',
		itemType: DOCUMENT_ITEM_TYPE_FILE,
		hasVisibleCheckboxBasic: true,
		hasVisibleRightActions: false,
	}

	return checkedDocItem
}

export function getIsFolder(documentItem: DocumentItem): boolean {
	return documentItem.itemType === DOCUMENT_ITEM_TYPE_FOLDER
}

export function getIsRootFolder(documentItem: DocumentItem): boolean {
	return !!(getIsFolder(documentItem) && documentItem.parentUniqueId == null)
}

export function getShowRightIconMenu(
	docItem: DocumentItem,
	showActions: boolean,
	canBeChanged: boolean,
	canBeRemoved: boolean,
): boolean {
	const result = !(!showActions || (!canBeChanged && !canBeRemoved && getIsFolder(docItem)) || getIsRootFolder(docItem))
	return result
}

export function getShowDocListItemCheckbox(
	docItem: DocumentItem,
	showFileCheckbox?: boolean,
	showFolderCheckbox?: boolean,
	showActions: boolean,
	canBeChanged: boolean,
	canBeRemoved: boolean,
): boolean {
	return !!(
		getShowDocListItemCheckboxBasic(docItem, showFileCheckbox, showFolderCheckbox) ||
		// [TWU-2837] in Trivi docs basic user can see files actions(download) and now also checkbox
		getShowRightIconMenu(docItem, showActions, canBeChanged, canBeRemoved)
	)
}

// [TWU-2837] For multiActionsToolbar We need to differentiate if checkbox is visible becasuse of visibility of rightIconMenu
//   which implies it is non-internal user in Trivi docs and can only do multi download
export function getShowDocListItemCheckboxBasic(
	docItem: DocumentItem,
	showFileCheckbox?: boolean,
	showFolderCheckbox?: boolean,
): boolean {
	return !!(
		docItem.canBeChanged &&
		!getIsRootFolder(docItem) &&
		((showFileCheckbox && !getIsFolder(docItem)) || (showFolderCheckbox && getIsFolder(docItem)))
	)
}

export function getShowDocAttachmentsCheckbox(
	docItem: DocumentItem,
	isAttachDocumentList: ?boolean,
	showFileCheckbox?: boolean,
): boolean {
	return !!(isAttachDocumentList && showFileCheckbox && !getIsFolder(docItem))
}
