// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".fZE7OUXWjkYwBs6fBI5zt {\n\tdisplay: flex;\n\tjustify-content: flex-start;\n\talign-content: center;\n}\n", "",{"version":3,"sources":["webpack://./modules/document-extraction/components/document-field.css"],"names":[],"mappings":"AAAA;CACC,aAAA;CACA,2BAAA;CACA,qBAAA;AACD","sourcesContent":[".edit {\n\tdisplay: flex;\n\tjustify-content: flex-start;\n\talign-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"edit": "fZE7OUXWjkYwBs6fBI5zt"
};
export default ___CSS_LOADER_EXPORT___;
