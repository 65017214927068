/* @flow */
/** @jsx jsx */

import { PureComponent, Fragment } from 'react'
import { jsx } from '@emotion/core'
import ChevronRight from 'components/svg-icons/navigation/chevron-right'
import FullText from 'components/full-text'
import { colors } from 'variables'

type Props = {|
	title: string,
	subtitle?: string,
	toggle?: React$Element<any>,
	disabled?: boolean,
	onClick?: () => any,
|}

class SettingsItem extends PureComponent<Props> {
	render() {
		const children = (
			<Fragment>
				{this.props.toggle ? (
					<div css={styles.toggle}>{this.props.toggle}</div>
				) : (
					<div css={styles.icon}>
						<ChevronRight color={colors.grey900} />
					</div>
				)}
				<div id={'title'} css={styles.title}>
					{this.props.title}
				</div>
				<div css={styles.subtitle}>
					<FullText text={this.props.subtitle} />
				</div>
			</Fragment>
		)

		return (
			<div>
				{this.props.toggle ? (
					<label css={styles.label}>
						<div css={styles.container}>{children}</div>
					</label>
				) : (
					<div onClick={this.props.onClick} css={styles.root}>
						<div css={styles.container}>{children}</div>
					</div>
				)}
			</div>
		)
	}
}

const styles = {
	root: {
		height: 70,
		fontSize: 16,
		lineHeight: '16px',
		position: 'relative',
		cursor: 'pointer',
		'&:hover': {
			'#title': {
				color: colors.blue, // DODELAT HOVER
			},
			svg: {
				fill: colors.blue,
			},
		},
	},
	icon: {
		position: 'absolute',
		top: 12,
		margin: 12,
		transition: 'none',
		lineHeight: '24px',
		textAlign: 'center',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		right: 10,
	},
	toggle: {
		position: 'absolute',
		display: 'block',
		width: 'auto',
		top: 25,
		right: 0,
	},
	title: {
		display: 'block',
		verticalAlign: 'middle',
		width: 'auto',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
	},
	subtitle: {
		color: colors.grey,
		fontSize: 12,
		lineHeight: '15px',
		height: 15,
		margin: 0,
		marginTop: 4,
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
	},
	label: {
		height: 70,
		fontSize: 16,
		lineHeight: '16px',
		position: 'relative',
		cursor: 'pointer',
	},
	container: {
		paddingRight: 56,
		paddingBottom: 16,
		paddingTop: 20,
		position: 'relative',
	},
}

export default SettingsItem
