// @flow

import { createSelector } from 'reselect'
import { getDocumentsId, getDocumentsListsId } from '../reducers'
import type { State, DocumentItem, DocumentDescriptor } from 'types'
import type { DocumentState, DocumentListState } from '../reducers'
import { cloneDeep } from 'lodash-es'

export const getDocumentStates = (state: State): { [string]: DocumentState } => state.document.documents

export const getDocumentListState = (
	state: State,
	documentItemUniqueId: ?string,
	search: ?string,
): ?DocumentListState => state.document.documentLists[getDocumentsListsId(documentItemUniqueId, search)]

export const getDocumentState = (state: State, documentItemUniqueId: ?string): ?DocumentState =>
	state.document.documents[getDocumentsId(documentItemUniqueId)]

export const getDocumentItemChildren: (
	state: State,
	documentItemUniqueId: ?string,
	search: ?string,
) => Array<DocumentItem> = createSelector(
	getDocumentStates,
	getDocumentListState,
	(documents: { [string]: DocumentState }, documentListState: ?DocumentListState) => {
		if (documentListState) {
			const startIndex = (documentListState.currentPage - 1) * documentListState.currentPageSize
			const endIndex = startIndex + documentListState.currentPageSize
			return documentListState.children
				.slice(startIndex, endIndex)
				.map((child: string) => documents[getDocumentsId(child)].data)
		}
		return []
	},
)

const buildDocumentTreeRecur = (state: State, documentItem: DocumentItem): DocumentItem => {
	const documentItemClone = documentItem
	documentItemClone.children = cloneDeep(getDocumentItemChildren(state, documentItem.uniqueId))
	documentItemClone.children.map((child: DocumentItem) => {
		return buildDocumentTreeRecur(state, child)
	})
	return documentItemClone
}

export const buildDocumentTree = (state: State): Array<DocumentItem> => {
	const rootItems = cloneDeep(getDocumentItemChildren(state))
	rootItems.map((child: DocumentItem) => {
		return buildDocumentTreeRecur(state, child)
	})
	return rootItems
}

export const getDocumentItem: (state: State, documentItemUniqueId: ?string) => ?DocumentItem = createSelector(
	getDocumentState,
	(document: ?DocumentState) => {
		return document ? document.data : null
	},
)

export const getParentDocumentInfo: (
	state: State,
	documentItemUniqueId: ?string,
) => ?DocumentDescriptor = createSelector(getDocumentState, (document: ?DocumentState) => {
	return document ? document.parentDocumentInfo : null
})

export const getDocumentItemLoading: (
	state: State,
	documentItemUniqueId: ?string,
	search: ?string,
) => boolean = createSelector(getDocumentListState, (documentListState: ?DocumentListState) => {
	return documentListState ? documentListState.loading : false
})

export const getDocumentItemCount: (
	state: State,
	documentItemUniqueId: ?string,
	search: ?string,
) => number = createSelector(getDocumentListState, (documentListState: ?DocumentListState) => {
	return documentListState ? documentListState.count : 0
})

export const getDocumentItemCurrentPage: (
	state: State,
	documentItemUniqueId: ?string,
	search: ?string,
) => number = createSelector(getDocumentListState, (documentListState: ?DocumentListState) => {
	return documentListState ? documentListState.currentPage : 1
})

export const getCurrentSortingDirection: (
	state: State,
	documentItemUniqueId: ?string,
) => 'ASC' | 'DESC' = createSelector(getDocumentState, (document: ?DocumentState) => {
	return document ? document.currentSortingDirection : 'ASC'
})
