// @flow

import {
	Button,
	LinearProgress,
	Paper,
	Separator,
	TabSwitch,
	TabSwitches,
	TextField,
	PopupSection,
	Avatar,
} from 'components'
import SelectNext from 'components/select-next'
import React, { Component } from 'react'
import type { CheckedDocItem, DocumentItem, Import, Scan, ScanFile } from 'types'
import FileViewer from 'modules/file/containers/file-viewer'
import UploadScanZone from 'modules/accounting-document/components/upload-scan/upload-scan-zone'
import UploadScanListContainer from 'modules/accounting-document/containers/upload-scan/upload-scan-list'
import CheckIcon from 'components/svg-icons/navigation/check'
import type { WithNotifyProps, WithTranslateProps, WithOrganizationSettingsProps } from 'wrappers'
import { withNotify, withTranslate, withOrganizationSettings } from 'wrappers'
import DocumentList from 'modules/document/containers/document-list'
import CloudIcon from 'components/svg-icons/file/cloud-upload'
import PlanetIcon from 'components/svg-icons/social/public'
import FolderIcon from 'components/svg-icons/file/folder'
import { colors } from 'variables'
import styles from './multi-import.css'
import type { OptionType } from 'components/select-next'

const STEPS_COUNT = 2

type SourceType = {|
	value: number,
|}

const IMPORT_SOURCE_TYPES: Array<SourceType> = [{ value: 1 }, { value: 2 }, { value: 3 }, { value: 4 }]

const style = {
	icon: {
		width: 18,
		height: 18,
		marginLeft: 4,
		marginRight: 16,
		verticalAlign: 'middle',
		marginTop: -3,
	},
}

export function canSelectSourceType(state: State): boolean {
	const { type, files, checkedDocItems, url } = state
	return !!(
		type &&
		((type === 'pc' && files.length > 0) ||
			(type === 'url' && url) ||
			(type === 'existing' && checkedDocItems.length > 0))
	)
}

export type ImportFile = {|
	fileId?: string,
	fileName?: string,
	fileUrl?: string,
|}

export function convertStateToImport(state: State, name: string = new Date().toISOString()): ?Import {
	const { source, useWorkflow, useSequenceId, sameEntityAction, files, existingFiles, url } = state
	let importFiles: ?Array<ImportFile> = null

	switch (state.type) {
		case 'existing':
			importFiles = existingFiles
			break
		case 'pc':
			importFiles = files.map((fileId: string) => Object.freeze({ fileId }))
			break
		case 'url':
			importFiles = url ? [Object.freeze({ fileUrl: url })] : null
			break
	}

	return {
		name,
		useWorkflow,
		sameEntityAction,
		useSequenceId: useSequenceId != null ? useSequenceId : undefined, //TODO hotfix
		sourceType: source || 1,
		files: importFiles || undefined,
	}
}

export type ImportType = 'pc' | 'url' | 'existing'
export type SameEntityActionType = 1 | 2 | 3

export type State = {|
	type: ?ImportType,
	step: number,
	url: ?string,
	source: ?number,
	files: Array<string>,
	existingFiles: Array<ImportFile>,
	folderId: ?string,
	checkedDocItems: Array<CheckedDocItem>,
	fileIdForPreview: ?string,
	useSequenceId: ?number,
	sameEntityAction: SameEntityActionType,
	useWorkflow: boolean,
	cashRegisterId: ?string,
|}

type ComponentProps = {|
	...WithOrganizationSettingsProps,
	...WithTranslateProps,
	...WithNotifyProps,
	scans: Array<Scan>,
	uploading: boolean,
	createImport: (importObject: Import) => void,
	uploadScan: (file: File, documentCategory: ?string, customUploadId?: number) => Promise<any>,
|}

class MultipleImport extends Component<ComponentProps, State> {
	state: State = {
		type: null,
		step: 1,
		url: null,
		source: null,
		files: [],
		existingFiles: [],
		folderId: null,
		checkedDocItems: [],
		fileIdForPreview: null,
		useSequenceId: null,
		sameEntityAction: 1,
		useWorkflow: !!(this.props.organizationSettingsOpen && this.props.organizationSettingsOpen.workflowTypeId === '2'),
		cashRegisterId: null,
	}

	UNSAFE_componentWillMount() {
		if (this.props.scans.length > 0) {
			const files: Array<string> = []
			this.props.scans.forEach((s: Scan) => s.files && s.files.forEach((file: ScanFile) => files.push(file.fileId)))
			this.setState({
				type: 'pc',
				files,
			})
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps: ComponentProps) {
		if (this.props.organizationSettingsOpen !== nextProps.organizationSettingsOpen) {
			this.setState({
				useWorkflow: !!(
					nextProps.organizationSettingsOpen && nextProps.organizationSettingsOpen.workflowTypeId === '2'
				),
			})
		}
	}

	onFolderIdChange = (folderId?: string) => {
		this.setState({ folderId })
	}

	onTypeChange = (type: ImportType) => {
		this.setState({ type })
	}

	onUrlChange = (event: SyntheticInputEvent<HTMLInputElement>, url: ?string) => {
		this.setState({ url })
	}

	onImportSourceChange = (source: number) => () => {
		canSelectSourceType(this.state) && this.setState({ source })
	}

	onFileUploaded = (id: string) => {
		this.setState({ files: [...this.state.files, id] })
	}

	onFileDeleted = (fileId: string) => {
		const files: Array<string> = [...this.state.files]
		const index: number = files.findIndex((id: string) => id === fileId)
		index > -1 && files.splice(index, 1)
		this.setState({ files })
	}

	onSequenceChange = (useSequenceId: number) => {
		this.setState({ useSequenceId })
	}

	onContinueButtonClick = () => {
		this.setState({ step: this.state.step + 1 })
	}

	onBackButtonClick = () => {
		this.setState({ step: this.state.step - 1 })
	}

	onExistingFileSelect = (
		file: DocumentItem,
		checked: boolean,
		createCheckedDocItemWithDocItemOnly: (item: DocumentItem) => CheckedDocItem,
	) => {
		const existingFiles: Array<ImportFile> = [...this.state.existingFiles]
		let newCheckedDocItems: Array<CheckedDocItem> = [...this.state.checkedDocItems]
		const checkedDocItem = createCheckedDocItemWithDocItemOnly(file)

		if (checked) {
			file.uniqueId && newCheckedDocItems.push(checkedDocItem)
			file.fileId && existingFiles.push({ fileId: file.fileId })
		} else {
			newCheckedDocItems = this.state.checkedDocItems.filter((doc: CheckedDocItem) => doc.uniqueId !== file.uniqueId)

			const index = existingFiles.findIndex((f: ImportFile) => f.fileId && file.fileId && f.fileId === file.fileId)
			existingFiles.splice(index, 1)
		}
		this.setState({ checkedDocItems: newCheckedDocItems, existingFiles })
	}

	onExistingFilePreview = (file: DocumentItem) => {
		file.fileId && this.setState({ fileIdForPreview: file.fileId })
	}

	onUploadedFilePreview = (fileIdForPreview: string) => {
		this.setState({ fileIdForPreview })
	}

	closeFilePreview = () => {
		this.setState({ fileIdForPreview: null })
	}

	onSameEntityChange = (sameEntityAction: OptionType) => {
		const value = parseInt(sameEntityAction.value)
		this.setState({
			// $FlowFixMe
			sameEntityAction: (value: SameEntityActionType),
		})
	}
	onUseWorkflowChange = (ev: Event, index: number, val: number) => {
		this.setState({ useWorkflow: !!val })
	}

	onProcessButtonClick = () => {
		const obj: ?Import = convertStateToImport(this.state)
		obj && this.props.createImport(obj)
	}

	onCashRegisterIdChange = (cashRegisterId: ?string) => {
		this.setState({ cashRegisterId })
	}

	renderSourceType = (sourceItem: SourceType, i: number) => {
		const { source } = this.state
		const disabled = !canSelectSourceType(this.state)
		const selected = source === i + 1

		return (
			<Paper
				className={selected ? styles.selectedLogo : styles.logo}
				key={'source' + i}
				onClick={!disabled ? this.onImportSourceChange(sourceItem.value) : undefined}
			>
				{disabled ? (
					<div className={styles.disabledLogoContent}>
						<span />
						<span />
					</div>
				) : (
					<div className={styles.image} />
				)}
			</Paper>
		)
	}

	renderImportingFileDom(type: ?ImportType) {
		const { t } = this.props
		const { url } = this.state
		switch (type) {
			case 'pc':
				return (
					<div>
						<UploadScanZone
							noCategories
							uploading={this.props.uploading}
							onFileUploaded={this.onFileUploaded}
							uploadScan={this.props.uploadScan}
							accept={'text/xml,text/isdoc,application/xml,.isdoc,.isdocx'}
						/>
						<UploadScanListContainer
							compact
							scans={this.props.scans}
							onFileDeleted={this.onFileDeleted}
							onFilePreview={this.onUploadedFilePreview}
						/>
					</div>
				)
			case 'url':
				return (
					<div className={styles.url}>
						<TextField
							fullWidth
							value={url || ''}
							onChange={this.onUrlChange}
							labelText={t('accountingDocument.multiImport.importingFileUrl')}
							autoTestId="multi-import-file-url"
							name="url"
						/>
					</div>
				)
			case 'existing':
				return (
					<DocumentList
						showBreadcrumbs
						smallBreadcrumbs
						showFileCheckbox
						showAddButton={false}
						showActions={false}
						itemId={this.state.folderId || undefined}
						onNavigationChange={this.onFolderIdChange}
						onFileOpen={this.onExistingFilePreview}
						onItemCheck={this.onExistingFileSelect}
						checkedDocItems={this.state.checkedDocItems}
						light
					/>
				)
			default:
				return null
		}
	}

	renderBottomPart() {
		const { t } = this.props
		const { step, source } = this.state
		return (
			<div className={styles.bottom}>
				{step < STEPS_COUNT && (
					<Button
						disabled={!canSelectSourceType(this.state) || !source}
						labelText={t('accountingDocument.multiImport.continueButton')}
						autoTestId="multi-import-continue"
						onClick={this.onContinueButtonClick}
						primary
					/>
				)}

				{step === STEPS_COUNT && (
					<Button
						labelText={t('accountingDocument.multiImport.processButton')}
						autoTestId="multi-import-process"
						onClick={this.onProcessButtonClick}
						primary
					/>
				)}

				{step > 1 && (
					<span className={styles.backButton}>
						<Button
							transparent
							labelText={t('accountingDocument.multiImport.backButton')}
							autoTestId="multi-import-back"
							onClick={this.onBackButtonClick}
						/>
					</span>
				)}

				<div className={styles.stepper}>
					{t('accountingDocument.multiImport.stepLabel')}
					<span className={styles.steps}>
						{t('accountingDocument.multiImport.step', { currentStep: step, lastStep: STEPS_COUNT })}
					</span>
					<LinearProgress
						mode="determinate"
						className={styles.progress}
						backgroundColor={colors.grey300}
						color={colors.blue}
						min={0}
						max={STEPS_COUNT}
						value={step}
						rounded
						tall
					/>
					<Avatar className={styles.avatar} size={42} backgroundColor={colors.grey300}>
						<CheckIcon color={colors.white} hoverColor={colors.white} />
					</Avatar>
				</div>
			</div>
		)
	}

	render() {
		const { t } = this.props
		const { type, step, sameEntityAction } = this.state
		return (
			<Paper rounded zDepth={7} className={styles.root}>
				{step === 1 && (
					<div>
						<h3 className={styles.h3}>{t('accountingDocument.multiImport.chooseTitle')}</h3>
						<TabSwitches autoTestId="multi-import-type" onChange={this.onTypeChange} value={type}>
							<TabSwitch
								className={styles.tab}
								value="pc"
								icon={<CloudIcon color={colors.blue} style={style.icon} />}
								label={t('accountingDocument.multiImport.pcImport')}
							/>
							<TabSwitch
								className={styles.tab}
								value="url"
								icon={<PlanetIcon color={colors.blue} style={style.icon} />}
								label={t('accountingDocument.multiImport.urlImport')}
							/>
							<TabSwitch
								className={styles.tab}
								value="existing"
								icon={<FolderIcon color={colors.blue} style={style.icon} />}
								label={t('accountingDocument.multiImport.existingImport')}
							/>
						</TabSwitches>

						{type && <h3 className={styles.h3}>{t('accountingDocument.multiImport.importingFile')}</h3>}
						{this.renderImportingFileDom(type)}

						<div className={canSelectSourceType(this.state) ? styles.sources : styles.disabledSources}>
							<h3 className={styles.h3}>{t('accountingDocument.multiImport.chooseSource')}</h3>
							<div className={styles.logos}>{IMPORT_SOURCE_TYPES.map(this.renderSourceType)}</div>
						</div>
					</div>
				)}

				{step == 2 && (
					<div className={styles.step2}>
						<h2 className={styles.h2}>{t('accountingDocument.multiImport.almostReady')}</h2>
						<div className={styles.info}>{t('accountingDocument.multiImport.lastInfo')}</div>

						{/* Hidden for now according to TWU-1904
						<Paper rounded zDepth={2} className={styles.block}>
							<div className={styles.blockInfo}>
								<div className={styles.blockHeadline}>{t('accountingDocument.multiImport.sequence')}</div>
								<div className={styles.blockParagraph}>{t('accountingDocument.multiImport.sequenceInfo')}</div>
							</div>
							<div className={styles.blockField}>
								{// <CashRegisterSelector nullable value={cashRegisterId} onChange={this.onCashRegisterIdChange} /> // NOT YET}
								<SequencesSelector
									showCategoryLabels
									value={useSequenceId}
									onChange={this.onSequenceChange}
									cashRegisterId={cashRegisterId || undefined}
								/>
							</div>
						</Paper>
						*/}

						{/* Hidden for now according to TWU-1904
						<Paper rounded zDepth={2} className={styles.block}>
							<div className={styles.blockInfo}>
								<div className={styles.blockHeadline}>{t('accountingDocument.multiImport.finalState')}</div>
								<div className={styles.blockParagraph}>{t('accountingDocument.multiImport.finalStateInfo')}</div>
							</div>
							<div className={styles.blockField}>
								<SelectField fullWidth value={useWorkflow ? 1 : 0} onChange={this.onUseWorkflowChange}>
									<MenuItem value={0} primaryText={t('accountingDocument.multiImport.drafts')} />
									<MenuItem value={1} primaryText={t('accountingDocument.multiImport.waiting')} />
								</SelectField>
							</div>
						</Paper>
						*/}

						<Paper rounded zDepth={2} className={styles.block}>
							<div className={styles.blockInfo}>
								<div className={styles.blockHeadline}>{t('accountingDocument.multiImport.sameNoInfo')}</div>
							</div>
							<div className={styles.blockField}>
								<SelectNext
									value={sameEntityAction}
									onChange={this.onSameEntityChange}
									autoTestId="multi-import-action"
									options={[
										{ value: 1, label: t('accountingDocument.multiImport.overwrite') },
										{ value: 2, label: t('accountingDocument.multiImport.skip') },
										{ value: 3, label: t('accountingDocument.multiImport.createNew') },
									]}
								/>
							</div>
						</Paper>
					</div>
				)}

				<Separator className={styles.separator} />

				{this.renderBottomPart()}

				<PopupSection open={!!this.state.fileIdForPreview} onRequestClose={this.closeFilePreview}>
					<FileViewer fileId={this.state.fileIdForPreview || ''} />
				</PopupSection>
			</Paper>
		)
	}
}

export default withOrganizationSettings(withTranslate(withNotify(MultipleImport)))
