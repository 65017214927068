/* @flow */

import { connect } from 'react-redux'
import type { AccountingDocument, State } from 'types'
import { Number_AccountingDocumentAssignedType, Number_AccountingDocumentDirection } from '../../../../types/convertor'
import { getAccountingDocument, getAccountingDocumentLanguage } from 'modules/accounting-document/selectors'
import { updateAccountingDocumentPrintingHeader } from 'modules/accounting-document/actions'
import LineItemsHeader from 'modules/accounting-document/components/invoice-elements/line-items-header'
import { getAccountingDocumentDefaults } from 'modules/settings/selectors'
import { getPrintingInfo } from 'modules/accounting-document/domain/accounting-document'
import { getAppLanguage, toAvailableAccountingDocumentLanguage } from 'locales'

type OwnProps = {|
	readonly?: boolean,
	accountingDocumentId: string,
|}

type StateProps = {|
	note: ?string,
	defaultNote: ?string,
|}

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => {
	const doc: ?AccountingDocument = getAccountingDocument(state, ownProps.accountingDocumentId)
	const language =
		getAccountingDocumentLanguage(state, ownProps.accountingDocumentId) ||
		toAvailableAccountingDocumentLanguage(getAppLanguage())
	const direction = doc && Number_AccountingDocumentDirection(doc.direction)
	const type = doc && Number_AccountingDocumentAssignedType(doc.type)
	const defaults = direction && type && getAccountingDocumentDefaults(state, direction, type)
	const printing = defaults && language && getPrintingInfo(defaults, language)

	return {
		note: doc?.printing?.lineItemsHeader,
		defaultNote: printing?.lineItemsHeader || null,
	}
}

type DispatchProps = {|
	onChange: (printing: ?string) => void,
|}

const mapDispatchToProps = (dispatch: Dispatch<>, ownProps: OwnProps): DispatchProps => {
	return {
		onChange: (note: ?string) => {
			dispatch(updateAccountingDocumentPrintingHeader(ownProps.accountingDocumentId, note))
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(LineItemsHeader)
