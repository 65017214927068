// @flow

import type { HotKeyModifier } from '../types'

export const main = {
	altDown: { sequence: 'alt', action: 'keydown' },
	altUp: { sequence: 'alt', action: 'keyup' },
}

export const modifierKeys: HotKeyModifier[] = ['shift', 'ctrl', 'alt', 'meta']
