/* @flow*/

import { createSelector } from 'reselect'
import type { State, FeatureId, Feature, Permission } from 'types'
import storage from 'utils/local-storage'
import { FEATURES_ENABLED_KEY, FEATURES_DISABLED_KEY, AVAILABLE_FEATURES } from 'utils/features'

const mapIdToFeature = (id: FeatureId): Feature => {
	return (
		AVAILABLE_FEATURES.find((availableFeature: Feature) => {
			return availableFeature.id === id
		}) || { id: 'unknownFeature', name: '', description: '' } // because of flow, will never happen
	)
}

export const getServerEnabledFeaturesList = (state: State): Array<FeatureId> => {
	return state.features.serverEnabledFeatures
}

export const getServerEnabledFeatures: (state: State) => Array<Feature> = createSelector(
	getServerEnabledFeaturesList,
	(list: Array<FeatureId>) => {
		return list.map(mapIdToFeature)
	},
)

export const getUserEnabledFeatures = (state: State): Array<Feature> => {
	return state.features.userEnabledFeatures.map(mapIdToFeature)
}

export const getUserDisabledFeatures = (state: State): Array<Feature> => {
	return state.features.userDisabledFeatures.map(mapIdToFeature)
}

export const getAvailableFeatures = (state: State): Array<Feature> => { //eslint-disable-line
	return AVAILABLE_FEATURES
}

export const getUserEnabledTestPermissions = (state: State): Array<Permission> => {
	return state.features.userEnabledTestPermissions
}

export const getUserDisabledTestPermissions = (state: State): Array<Permission> => {
	return state.features.userDisabledTestPermissions
}

export const isTestPermissionEnabled = (state: State, permission: Permission): boolean => {
	return getUserEnabledTestPermissions(state).indexOf(permission) > -1
}

export const isTestPermissionDisabled = (state: State, permission: Permission): boolean => {
	return getUserDisabledTestPermissions(state).indexOf(permission) > -1
}

export const getLocalStorageUserEnabledFeatures = (): string => {
	return storage.get(FEATURES_ENABLED_KEY) || ''
}

export const getLocalStorageUserDisabledFeatures = (): string => {
	return storage.get(FEATURES_DISABLED_KEY) || ''
}

export const getLocalStorageUserEnabledFeaturesList: (state: State) => Array<string> = createSelector(
	getLocalStorageUserEnabledFeatures,
	(value: string) => {
		return value.split(',')
	},
)

export const getLocalStorageUserDisabledFeaturesList: (state: State) => Array<string> = createSelector(
	getLocalStorageUserEnabledFeatures,
	(value: string) => {
		return value.split(',')
	},
)

export const getEnabledFeatures: (state: State) => Array<string> = createSelector(
	[getServerEnabledFeaturesList, getLocalStorageUserEnabledFeaturesList, getLocalStorageUserDisabledFeaturesList],
	(serverEnabled: Array<FeatureId>, userEnabled: Array<string>, userDisabled: Array<string>) => {
		return [...serverEnabled.filter((id: string) => !userDisabled.includes(id)), ...userEnabled]
	},
)
