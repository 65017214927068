// @flow
/** @jsx jsx */

import { jsx } from '@emotion/core'
import { Component } from 'react'
import type { UnmatchedPayment } from 'types'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import { formatToDateString, formatToMoney } from 'utils/formatters'
import { formatUnmatchedPaymentBankAccount } from 'utils/formatters/bank-account-formatter'
import PopupSection from 'components/PopupSection'
import Separator from 'components/Separator'
import Direction from 'components/direction'
import TextField from 'components/TextField'
import FormBox from 'components/form-box'
import Button from 'components/button'

type Props = {|
	...WithTranslateProps,
	unmatchedPayment: UnmatchedPayment,
	onAddComment: (unmatchedPayment: UnmatchedPayment, comment: string) => Promise<void>,
	onClose: () => void,
|}

type State = {|
	value: string,
|}

class AddCommentDialog extends Component<Props, State> {
	state = {
		value: '',
	}

	render() {
		return (
			<PopupSection open onRequestClose={this.props.onClose}>
				<FormBox
					actions={
						<div css={styles.actions}>
							<div>
								<Button
									disabled={!this.state.value.length}
									onClick={this.onAddComment}
									autoTestId="add-comment-submit"
									labelText={this.props.t('datagrid.implemented.unmatchedPayments.addCommentSubmit')}
								/>
							</div>
						</div>
					}
					header={
						<div>
							{this.props.t('datagrid.implemented.unmatchedPayments.addCommentHeader')}{' '}
							<span css={styles.date}>{formatToDateString(this.props.unmatchedPayment.paymentDate || '')}</span>
						</div>
					}
					children={
						<div>
							<div css={styles.content}>
								<div css={styles.info}>
									<div>{formatUnmatchedPaymentBankAccount(this.props.unmatchedPayment)}</div>
									<div>{this.props.unmatchedPayment.description}</div>
								</div>
								<div css={styles.amount}>
									<div css={styles.direction}>
										<Direction
											issued={0 === this.props.unmatchedPayment.direction}
											received={1 === this.props.unmatchedPayment.direction}
										/>
									</div>
									{formatToMoney(this.props.unmatchedPayment.amount, {
										currency: this.props.unmatchedPayment.currency,
									})}
								</div>
							</div>
							<Separator
								left={
									<span css={styles.separatorText}>
										{this.props.t('datagrid.implemented.unmatchedPayments.addCommentTitle')}
									</span>
								}
							/>
							<div css={styles.text}>
								<TextField
									fullWidth
									multiLine
									rows={5}
									value={this.state.value}
									onChange={this.onChange}
									name="comment"
									autoTestId="comment-dialog-input"
								/>
							</div>
						</div>
					}
				/>
			</PopupSection>
		)
	}

	onChange = (event: Event, value: string) => {
		this.setState({ value })
	}

	onAddComment = () => {
		this.props.onAddComment(this.props.unmatchedPayment, this.state.value)
	}
}

const styles = {
	date: { fontWeight: 600 },
	content: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		fontSize: 20,
		lineHeight: 1.4,
		marginBottom: 30,
		fontWeight: 'normal',
	},
	info: {
		flex: '1 1 100%',
	},
	amount: {
		flex: '1 0 auto',
		fontWeight: 600,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
	},
	direction: {
		display: 'flex',
		marginRight: 6,
		marginLeft: 30,
	},
	separatorText: {
		fontSize: 12,
		textTransform: 'uppercase',
		marginRight: 10,
	},
	text: { marginTop: 15 },
	actions: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		'> *': {
			marginLeft: 15,
		},
	},
}

export default withTranslate(AddCommentDialog)
