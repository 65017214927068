/* @flow */

import React, { Component } from 'react'
import SelectNext, { type OptionType, type OptionsType, type ValueType } from 'components/select-next'
import { type WithTranslateProps, withTranslate } from 'wrappers'

import type { SearchFilter } from 'types'

type Props = {|
	name: string,
	filter?: SearchFilter,
	multiple?: boolean,
	className?: string,
	labelText?: string,
	hintText?: string,
	options: OptionsType,
	compact?: boolean,
	portal?: boolean,
	operation?: 'NotEqual' | 'Equal' | 'Exclusive',
	isClearable?: boolean,
	isSearchable?: boolean,
	onChange?: (filter: SearchFilter) => void,
	...WithTranslateProps,
|}

class FilterSelectField extends Component<Props> {
	static defaultProps = {
		isClearable: false,
		isSearchable: false,
	}

	handleSelectChange = (value: ValueType) => {
		let filter: SearchFilter = {
			field: this.props.name,
		}

		if (value !== undefined && value !== null) {
			if (value instanceof Array) {
				if (value.length) filter.valueContains = value.map((option: OptionType) => option.value)
			} else {
				filter.value = value.value
			}
		}
		if (this.props.operation) {
			filter.operation = this.props.operation
		}

		this.props.onChange && this.props.onChange(filter)
	}

	render() {
		const { filter } = this.props

		let val = null
		if (filter) {
			if (filter.value !== undefined) {
				val = filter.value
			} else if (filter.valueContains !== undefined) {
				val = filter.valueContains
			}
		}

		return (
			<div className={this.props.className}>
				<SelectNext
					isClearable={this.props.isClearable}
					isSearchable={this.props.isSearchable}
					compact={this.props.compact}
					label={this.props.labelText}
					portal={this.props.portal}
					placeholder={this.props.hintText != null ? this.props.hintText : this.props.t('application.emptySelectField')}
					onChange={this.handleSelectChange}
					isMulti={this.props.multiple}
					options={this.props.options}
					value={val}
				/>
			</div>
		)
	}
}

export default withTranslate(FilterSelectField)
