/* @flow */
import { userHasAccess } from 'permissions'
import { connect } from 'react-redux'
import { getNavigationSectionPermissions } from 'modules/navigation/selectors'
import { getCurrentOrganizationId } from 'modules/user/selectors'
import type { NavigationSectionPermissions } from '../types'
import type { State } from 'types'

type StateProps = {|
	canSeeEmptyDashboard: boolean,
	canSeeDashboard: boolean,
	canSeeAccountingDocuments: boolean,
	canSeeAccountingDocumentsDetail: boolean,
	canSeeAccountingDocumentsEdit: boolean,
	canSeeCashRegisters: boolean,
	canSeeBankAccounts: boolean,
	canSeeDocuments: boolean,
	canSeeContacts: boolean,
	canSeeTasks: boolean,
	canSeeInternalDashboard: boolean,
	canSeeDocuments: boolean,
	canSeeCashRegisters: boolean,
	canSeeContacts: boolean,
	canSeeSettings: boolean,
	canSeeFeatures: boolean,
	canExtractAccountingDocuments: boolean,
	canSeeNonDemoFeatures: boolean,
	canSeeFiles: boolean,
	canSeeTriviStore: boolean,
	canSeeRegistration: boolean,
|}

const mapStateToProps = (state: State): StateProps => {
	const perms: NavigationSectionPermissions = getNavigationSectionPermissions(state)
	return {
		canSeeTasks: perms.tasks,
		canSeeDashboard: perms.dashboard,
		canSeeSettings: perms.settings,
		canSeeFeatures: perms.features,
		canSeeInternalDashboard: perms.internalDashboard,
		canSeeDocuments: perms.documents,
		canSeeCashRegisters: perms.cashRegisters,
		canSeeBankAccounts: perms.bankAccounts,
		canSeeContacts: perms.contacts,
		canSeeAccountingDocuments: perms.accountingDocuments,
		canSeeAccountingDocumentsDetail: perms.accountingDocumentsDetail,
		canSeeAccountingDocumentsEdit: perms.accountingDocumentsEdit,
		canExtractAccountingDocuments: perms.extractAccountingDocuments,
		canSeeEmptyDashboard: perms.emptyDashboard,
		canSeeNonDemoFeatures: userHasAccess(state, 'seeNonDemoFeatures'),
		canSeeFiles: !!getCurrentOrganizationId(state),
		canSeeTriviStore: perms.triviStore,
		canSeeRegistration: perms.registration,
	}
}

export type Props = StateProps

export default connect(mapStateToProps)
