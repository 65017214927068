/* @flow*/

import type {
	AresContact,
	State,
	InternalUser,
	UserProfile,
	PrintLanguage,
	AvatarSize,
	OrganizationMember,
	TermsItemResponse,
	UserType,
} from 'types'
import { PortalLanguage_PrintLanguage, Locale_PrintLanguage } from 'locales'
import { loadMyAvatar } from 'modules/user/actions'
import store from 'store'
import { createSelector } from 'reselect'
import { userHasAccess } from 'permissions'
import { uniqueObjArray } from 'utils/array'

export function getMyProfile(state: State): ?UserProfile {
	return state.user.me.data || null
}

export function getCurrentOrganizationId(state: State): ?string {
	return (state.user.currentOrganizationId !== undefined && state.user.currentOrganizationId) || null
}

export function getCurrentUserOrganizations(state: State): ?Array<OrganizationMember> {
	return state.user.organizations.data
}

export function getCurrentUserUsername(state: State): ?string {
	return state.user.username || null
}

export function getCurrentUserEmail(state: State): ?string {
	return state.user.me.data ? state.user.me.data.email : null
}

export function getCurrentUserType(state: State): ?UserType {
	if (userHasAccess(state, 'demoUser')) {
		return 'demo'
	}

	if (state.user.me.data == null || state.user.me.data.isInternal == null) {
		return undefined
	}

	return state.user.me.data.isInternal ? 'internal' : 'client'
}

export function getCurrentUserId(state: State): ?string {
	const profile = getMyProfile(state)
	return (profile && profile.id) || null
}

export function getAvatar(state: State, size: AvatarSize): ?string {
	const avatar =
		(state.user &&
			state.user.me &&
			state.user.me.avatar &&
			state.user.me.avatar[size] &&
			state.user.me.avatar[size].crop) ??
		null
	return avatar
}

export function getUncroppedAvatar(state: State, size: AvatarSize): ?string {
	const uncroppedAvatar =
		(state.user &&
			state.user.me &&
			state.user.me.avatar &&
			state.user.me.avatar[size] &&
			state.user.me.avatar[size].original) ??
		null
	return uncroppedAvatar
}

export function getMyPrintLanguage(state: State): ?PrintLanguage {
	const me: ?UserProfile = getMyProfile(state)
	if (me) {
		if (me.locale) {
			return Locale_PrintLanguage(me.locale)
		} else if (me.language) {
			const langPringLang: ?PrintLanguage = PortalLanguage_PrintLanguage(me.language)
			if (langPringLang) {
				return langPringLang
			}
		}
	} else {
		return null
	}
}

export function currentUserIsInternal(state: State): boolean {
	return !!(state.user.me.data && state.user.me.data.isInternal)
}

export function currentUserIsAdmin(state: State): boolean {
	return !!(state.user.me.data && state.user.me.data.isAdmin)
}

export function currentUserHasInternalPermission(state: State, permission: string): boolean {
	return !!(
		state.user.me.data &&
		state.user.me.data.internalPermissions &&
		state.user.me.data.internalPermissions.indexOf(permission) >= 0
	)
}

export function getCurrentUserHasInternalFullAccess(state: State): boolean {
	return currentUserHasInternalPermission(state, 'internal_full_access')
}

export function getCurrentUserIsExtractor(state: State): boolean {
	return currentUserHasInternalPermission(state, 'internal_extract')
}

export const getInternalUsers: (state: State) => ?Array<InternalUser> = createSelector(
	(state: State) => state.user.internalUsers.data,
	(users: ?Array<InternalUser>) => {
		if (!users) {
			return undefined
		}

		return uniqueObjArray(users, 'id').sort((user1: InternalUser, user2: InternalUser) => {
			if ((user1.firstname || '') < (user2.firstname || '')) return -1
			if ((user1.firstname || '') > (user2.firstname || '')) return 1
			return 0
		})
	},
)

export function isInternalUsersLoading(state: State): boolean {
	return state.user.internalUsers.loading
}

export function getAresContacts(state: State): ?Array<AresContact> {
	return state.user.aresContacts.data
}

export function isAresContactsLoading(state: State): boolean {
	return state.user.aresContacts.loading
}

export function getTermsFromState(state: State): ?TermsItemResponse {
	return state.user.terms.data || null
}

export function getTermsLoadingFlag(state: State): boolean {
	return state.user.terms.loading
}

export function splashScreenVisible(state: State): boolean {
	return state.user.splashScreenVisible
}

export function getIsBootstrapLoaded(state: State): boolean {
	return !!(state.user.me.data && state.user.me.data.id)
}

export function getNonInternalUsers(state: State): ?Array<UserProfile> {
	const seen = new Set()
	const data: ?Array<UserProfile> = state.organization.users.data
	const userProfiles: ?Array<UserProfile> =
		data &&
		data
			.filter((u: UserProfile) => !u.isInternal)
			.filter((u: UserProfile) => {
				const duplicate = seen.has(u.id)
				seen.add(u.id)
				return !duplicate
			})
	return userProfiles
}
