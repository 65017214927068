import React from 'react'
import pure from 'recompose/pure'
import SvgIcon from 'components/SvgIcon'

let Import = props => (
	<SvgIcon {...props}>
		<g stroke="none" fillRule="evenodd">
			<path d="M16.5454545,4 L16.5454545,5.77777778 L20.1818182,5.77777778 L20.1818182,18.2222222 L3.81818182,18.2222222 L3.81818182,5.77777778 L7.45454545,5.77777778 L7.45454545,4 L2,4 L2,20 L21.9990909,20 L21.9990909,4 L16.5454545,4 Z M10.1818182,5.77777778 L10.1818182,9.33333333 L7,9.33333333 L12,16.4444444 L16.9990909,9.33333333 L13.8172727,9.33333333 L13.8172727,5.77777778 L10.1818182,5.77777778 Z" />
		</g>
	</SvgIcon>
)
Import = pure(Import)

export default Import
