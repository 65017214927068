/* @flow */

import React, { Component } from 'react'
import { validate, withTranslate, type FormValidationProps, type WithTranslateProps } from 'wrappers'
import type { AccountingDocumentDirection, AccountingDocumentType, CashRegister, WithRouterProps } from 'types'
import { Chip, Dialog, Button, DatePicker, IconMenu, MenuItem } from 'components'
import { formatToMoney, formatToDateString } from 'utils/formatters'
import styles from './cash-register-detail.css'
import TabSwitches from 'components/TabSwitches/TabSwitches'
import TabSwitch from 'components/TabSwitches/TabSwitch'
import Separator from 'components/Separator'
import DocumentButton from '../../accounting-document/containers/accounting-document-list/document-button'
import type { NavigationItem } from '../../accounting-document/components/accounting-document-list/document-button-sections'
import { type State as FilterState } from 'modules/data-grid/reducers'

type Props = {|
	...WithTranslateProps,
	...FormValidationProps,
	...WithRouterProps,
	id: string,
	cashRegister: ?CashRegister,
	direction: ?AccountingDocumentDirection,
	gridFilter?: FilterState,
	onEdit: () => void,
	onMakeInventory: () => void,
	onCashBookDownload: (from: Date, to: Date) => void,
	onCreateAccountingDocument: (
		type: AccountingDocumentType,
		direction: AccountingDocumentDirection,
		currency: string,
	) => void,
	canEditCashRegisters: boolean,
	isCashRegisterOwner: boolean,
	onChangeDirection: (direction: AccountingDocumentDirection, redirect?: boolean) => void,
	onChangeView: (gridFilter: FilterState) => void,
|}

type State = {|
	dialogOpen: boolean,
	from: ?Date,
	to: ?Date,
	selectedTab: 'issued' | 'received' | 'unknown',
|}

const NOW = new Date()

class CashRegisterDetail extends Component<Props, State> {
	state: State = {
		dialogOpen: false,
		from: null,
		to: NOW,
		selectedTab: this.props.direction ?? 'unknown',
	}

	componentDidMount() {
		this.changeDirection(this.props.direction ?? 'unknown')
		this.props.onChangeView(this.props.gridFilter)
	}

	onCombinedButtonClick = (item: NavigationItem, type?: string) => {
		switch (type) {
			case 'received':
				this.handleCreateReceivedAccountingDocument(item)
				break
			case 'issued':
				this.handleCreateIssuedAccountingDocument(item)
				break
			default:
		}
	}

	handleCreateIssuedAccountingDocument = (item: NavigationItem) => {
		const currency = (this.props.cashRegister && this.props.cashRegister.currency) || 'CZK'
		// $FlowFixMe
		const type: string = item && item.value ? item.value : 'simplified_invoice'
		this.props.onCreateAccountingDocument(type, 'issued', currency)
	}

	handleCreateReceivedAccountingDocument = (item: NavigationItem) => {
		const currency = (this.props.cashRegister && this.props.cashRegister.currency) || 'CZK'
		// $FlowFixMe
		const type: AccountingDocumentType = item && item.value ? item.value : 'simplified_invoice'
		this.props.onCreateAccountingDocument(type, 'received', currency)
	}

	showDialog = () => {
		this.setState({ dialogOpen: true })
	}

	closeDialog = () => {
		this.setState(
			{
				dialogOpen: false,
				from: null,
				to: NOW,
			},
			this.props.resetValidation,
		)
	}

	onFromChange = (event: ?SyntheticInputEvent<HTMLInputElement>, from: ?Date) => {
		this.setState({ from })
	}

	onToChange = (event: ?SyntheticInputEvent<HTMLInputElement>, to: ?Date) => {
		this.setState({ to })
	}

	onDownloadClick = () => {
		const { from, to } = this.state
		if (from && to) {
			this.props.onCashBookDownload(from, to)
			this.closeDialog()
		}
	}

	changeDirection = (direction: AccountingDocumentDirection) => {
		this.props.onChangeDirection(direction ?? 'unknown')
		return this.setState({ selectedTab: direction })
	}

	renderSellActionButton = () => {
		const { t, canEditCashRegisters, isCashRegisterOwner } = this.props

		if (!(canEditCashRegisters || isCashRegisterOwner)) {
			return null
		}

		return (
			<div className={styles.buttonContainer}>
				<DocumentButton
					onNavigationClick={this.handleCreateReceivedAccountingDocument}
					cashRegisterMode={'received'}
					isCashRegister
					label={t('cashRegister.createAccountingDocument.newReceivedInvoice')}
				/>
			</div>
		)
	}

	renderBuyActionButton = () => {
		const { t, canEditCashRegisters, isCashRegisterOwner } = this.props

		if (!(canEditCashRegisters || isCashRegisterOwner)) {
			return null
		}

		return (
			<div className={styles.buttonContainer}>
				<DocumentButton
					onNavigationClick={this.handleCreateIssuedAccountingDocument}
					cashRegisterMode={'issued'}
					isCashRegister
					label={t('cashRegister.createAccountingDocument.newIssuedInvoice')}
				/>
			</div>
		)
	}

	renderCombinedActionButton = () => {
		const { t, canEditCashRegisters, isCashRegisterOwner } = this.props

		if (!(canEditCashRegisters || isCashRegisterOwner)) {
			return null
		}

		return (
			<div className={styles.buttonContainer}>
				<DocumentButton
					onNavigationClick={this.onCombinedButtonClick}
					cashRegisterMode={'combined'}
					isCashRegister
					showLabel
					label={t('cashRegister.createAccountingDocument.newInvoice')}
				/>
			</div>
		)
	}

	getClientValidationErrors() {
		const errors = {}
		const { t } = this.props
		const { from, to } = (this.state: State)

		if (!from) {
			errors.from = t('application.validation.mandatory')
		}

		if (!to) {
			errors.to = t('application.validation.mandatory')
		}

		if (from && to) {
			if (from > to) {
				errors.to = t('cashRegister.toLowerThanFrom')
			}
		}

		return errors
	}

	render() {
		const { cashRegister, t } = this.props

		if (!cashRegister) {
			return null
		}

		this.props.validateForm(this.getClientValidationErrors())

		const title = t('cashRegister.downloadDialogTitle')
		const actions = [
			<Button
				primary
				wide
				key="sign-up"
				labelText={t('cashRegister.downloadButton')}
				onClick={this.props.validationSubmit(this.onDownloadClick)}
				autoTestId="cash-register-download"
			/>,
			<Button
				autoTestId="cash-register-cancel"
				secondary
				wide
				key="cancel"
				labelText={t('cashRegister.cancelButton')}
				onClick={this.closeDialog}
			/>,
		]

		return (
			<div>
				<div className={styles.header}>
					<div className={styles.nameContainer}>
						<h1 id={'cash-register-name'} className={styles.name}>
							{cashRegister.name}
						</h1>
						<div>
							<Chip>{cashRegister.currency}</Chip>
						</div>
					</div>
					<div className={styles.right}>
						<div className={styles.menuContainer}>
							<IconMenu autoTestId="cash-register-detail-settings">
								{this.props.canEditCashRegisters || this.props.isCashRegisterOwner ? (
									<MenuItem primaryText={t('cashRegister.inventory.button')} onClick={this.props.onMakeInventory} />
								) : null}
								<MenuItem primaryText={t('cashRegister.cashBookDownload')} onClick={this.showDialog} />
								<MenuItem primaryText={t('cashRegister.settings')} onClick={this.props.onEdit} />
							</IconMenu>
						</div>
						<div className={styles.balanceContainer}>
							<div className={styles.balanceInfo} id={'balance-date'}>
								{t('cashRegister.currentBalance', {
									date: formatToDateString(new Date()),
								})}
							</div>
							<div className={styles.balance} id={'balance-amount'}>
								{formatToMoney(cashRegister.currentAmount, {
									currency: cashRegister.currency,
								})}
							</div>
						</div>
					</div>
				</div>

				<Separator
					center={
						<TabSwitches
							autoTestId="direction-view-compact-tabs"
							onChange={this.changeDirection}
							value={this.props.direction}
						>
							<TabSwitch key="unknown" value="unknown" label={t('application.filter.all')} />
							<TabSwitch key="issued" value="received" label={t('cashRegister.direction.issued')} />
							<TabSwitch key="received" value="issued" label={t('cashRegister.direction.received')} />
						</TabSwitches>
					}
					right={
						this.props.direction === 'issued'
							? this.renderSellActionButton()
							: this.props.direction === 'received'
							? this.renderBuyActionButton()
							: this.renderCombinedActionButton()
					}
				/>

				<Dialog autoTestId="cash-register-detail-dialog" actions={actions} title={title} open={this.state.dialogOpen}>
					<div className={styles.row}>
						<div className={styles.column}>
							<DatePicker
								hintText={t('cashRegister.dateHint')}
								labelText={t('cashRegister.from')}
								onChange={this.onFromChange}
								value={this.props.validationValue('from', this.state.from)}
								clientError={this.props.validationMessage('from')}
								autoTestId="cash-register-detail-from-date"
								fullWidth
								required
							/>
						</div>
						<div className={styles.column}>
							<DatePicker
								hintText={t('cashRegister.dateHint')}
								labelText={t('cashRegister.to')}
								onChange={this.onToChange}
								value={this.props.validationValue('to', this.state.to)}
								clientError={this.props.validationMessage('to')}
								autoTestId="cash-register-detail-to-date"
								fullWidth
								required
							/>
						</div>
					</div>
				</Dialog>
			</div>
		)
	}
}

export default withTranslate(validate()(CashRegisterDetail))
