/* @flow */

import type {
	FinishChangingMyAvatar,
	FinishCroppingMyAvatar,
	FinishDeletingMyAvatar,
	FinishUpdateMe,
} from 'modules/user/actions/action-types'
import type {
	PortalLanguage,
	WithRouterProps,
	UserProfile,
	UserProfileSettings,
	UserProfileSettingsNotifications,
	FormValidationProps,
} from 'types'
import React, { Component } from 'react'
import { setAppLanguage, setAppLocale } from 'locales'
import {
	getUserSettingsNotificationsRoute,
	getUserSettingsPasswordRoute,
	getUserSettingsProfileRoute,
	getUserSettingsMainRoute,
} from 'modules/user/routing/routes'
import type { CropReturnType } from 'components/image-cropper'
import PasswordSettings from 'modules/user/components/user-settings/password-settings'
import ProfileSettings from 'modules/user/components/user-settings/profile-settings'
import MainSettings from 'modules/user/components/user-settings/main-settings'
import NotificationsSettings from 'modules/user/components/user-settings/notifications-settings'
import type { WithNotifyProps, WithTranslateProps, WithNavigationPermissionsProps } from 'wrappers'
import { withNotify, withTranslate, withNavigationPermissions } from 'wrappers'
import { withRouter } from 'react-router-dom'
import { getServerErrorMessage } from 'helpers'
import Helmet from 'react-helmet'
import { Validator } from 'utils'
import PopupSection from 'components/PopupSection'
import { Tabs, Tab } from 'components/Tabs'
import Button from 'components/button'
import Paper from 'components/Paper'
import styles from './user-settings.css'
import ConfirmDialog from 'components/confirm-dialog'
import validate from 'wrappers/validate'
import Tooltip from 'components/tooltip'

type SectionType = 'profile' | 'password' | 'emails' | 'dataBox' | 'notifications' | 'settings'

type TabsType = { [section: SectionType]: () => string }

const TABS: TabsType = {
	profile: getUserSettingsProfileRoute,
	password: getUserSettingsPasswordRoute,
	settings: getUserSettingsMainRoute,
	notifications: getUserSettingsNotificationsRoute,
	// emails: getUserSettingsEmailsRoute,
	// dataBox: getUserSettingsDataBoxRoute,
}

export type Props = {|
	...FormValidationProps,
	me: ?UserProfile,
	avatar: ?string,
	originalAvatar: any,
	section: SectionType,
	isInternal: boolean,
	changePassword: (oldPassword: string, newPassword: string) => Promise<any>,
	updateMe: (userProfile: UserProfile) => Promise<FinishUpdateMe>,
	deleteMyAvatar: () => Promise<FinishDeletingMyAvatar>,
	changeMyAvatar: (file: File, localFilePath: string) => Promise<FinishChangingMyAvatar>,
	loadMyAvatar: (width?: number, height?: number, original?: boolean) => void,
	cropMyAvatar: (x: number, y: number, height: number, width: number) => Promise<any>,
	redirectToMainScreen: () => void,
	redirectToPreviousPage: (url: string) => void,
	loadSettingsAvatar: (refresh?: boolean) => Promise<void>,
|}

type ComponentProps = {|
	...Props,
	...WithTranslateProps,
	...WithNotifyProps,
	...WithRouterProps,
	...WithNavigationPermissionsProps,
|}

type ProfileState = {|
	phone: ?string,
	language: ?PortalLanguage,
	locale: ?string,
	firstName: ?string,
	lastName: ?string,
	avatar: ?string,
	avatarFile: ?File,
	crop: ?CropReturnType,
	settings: UserProfileSettings,
|}

type ComponentState = {|
	settings: {
		profile: ProfileState,
		password: {
			newPassword: ?string,
			currentPassword: ?string,
			confirmPassword: ?string,
		},
	},
	profileSettingsChanged: boolean,
	passwordSettingsChanged: boolean,
	emailSettingsChanged: boolean,
	dataBoxSettingsChanged: boolean,
	hasErrors: boolean,
	wasSaved: boolean,
|}

class UserSettingsPage extends Component<ComponentProps, ComponentState> {
	state: ComponentState = {
		settings: {
			profile: this.getProfileStateFromProps(this.props),
			password: {
				newPassword: null,
				currentPassword: '',
				confirmPassword: null,
			},
		},
		profileSettingsChanged: false,
		passwordSettingsChanged: false,
		emailSettingsChanged: false,
		dataBoxSettingsChanged: false,
		hasErrors: false,
		wasSaved: false,
	}

	componentDidMount() {
		if (this.props.avatar === null || this.props.avatar === undefined) {
			this.props.loadSettingsAvatar && this.props.loadSettingsAvatar()
		}
	}

	getProfileStateFromProps(props: ComponentProps): ProfileState {
		return {
			phone: props.me && props.me.phone,
			firstName: props.me && props.me.firstname,
			lastName: props.me && props.me.lastname,
			avatar: props.avatar,
			avatarFile: (this.state && this.state.settings.profile.avatarFile) || null,
			language: (props.me && props.me.language) || null,
			locale: (props.me && props.me.locale) || null,
			settings: (props.me && props.me.settings) || {
				displayAccountingDocumentPreviewBeforeProcessing: false,
				generateDailyDigestEmail: true,
				notifications: props.me && props.me.settings && props.me.settings.notifications,
			},
			crop:
				(props.avatar && props.avatar !== this.props.avatar) || (props.avatar && !this.props.avatar)
					? (this.state && this.state.settings.profile.crop) || null
					: null,
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps: ComponentProps) {
		if (this.props.me !== nextProps.me || this.props.avatar !== nextProps.avatar) {
			this.setState({
				settings: {
					...this.state.settings,
					profile: this.getProfileStateFromProps(nextProps),
				},
			})
		}
	}

	close = () => {
		const { profileSettingsChanged, passwordSettingsChanged, emailSettingsChanged, dataBoxSettingsChanged } = this.state
		const hasChanges =
			profileSettingsChanged || emailSettingsChanged || dataBoxSettingsChanged || passwordSettingsChanged

		if (hasChanges) {
			ConfirmDialog(this.props.t('dialogs.continueWithoutSave'), {
				okLabel: this.props.t('dialogs.dontSave'),
				cancelLabel: this.props.t('application.cancel'),
				body: this.props.t('dialogs.closeWhenUnsavedWarningBody'),
			}).then(() => {
				this.props.redirectToPreviousPage(this.props.history.location.state.referer)
			})
		} else {
			this.props.redirectToPreviousPage(this.props.history.location.state.referer)
		}
	}

	saveSettings = async () => {
		let success: boolean = true
		let { profileSettingsChanged, passwordSettingsChanged, emailSettingsChanged, dataBoxSettingsChanged } = this.state

		if (success && profileSettingsChanged) {
			success = await this.saveProfileSettings()
			profileSettingsChanged = !success
		}

		if (success && passwordSettingsChanged) {
			success = await this.savePasswordSettings()
			passwordSettingsChanged = !success
		}

		if (success && emailSettingsChanged) {
			success = this.saveEmailsSettings()
			emailSettingsChanged = !success
		}

		if (success && dataBoxSettingsChanged) {
			success = this.saveDataBoxSettings()
			dataBoxSettingsChanged = !success
		}

		this.setState({
			profileSettingsChanged,
			passwordSettingsChanged,
			emailSettingsChanged,
			dataBoxSettingsChanged,
		})

		success && this.close()
	}

	saveProfileSettings = async (): Promise<boolean> => {
		const {
			avatar,
			avatarFile,
			crop,
			lastName,
			firstName,
			phone,
			language,
			locale,
			settings,
		} = this.state.settings.profile

		if (avatar && avatar !== this.props.avatar && avatarFile) {
			const changeResponse: FinishChangingMyAvatar = await this.props.changeMyAvatar(avatarFile, avatar)
			if (changeResponse.avatar) {
				return false
			}
		} else if (!avatar && !avatarFile && this.props.avatar) {
			const deleteResponse: FinishDeletingMyAvatar = await this.props.deleteMyAvatar()
			if (deleteResponse.serverError) {
				return false
			}
		}

		if (crop) {
			const { x, y, height, width } = crop.px
			const resp: FinishCroppingMyAvatar = await this.props.cropMyAvatar(x, y, height, width)
			if (resp.serverError) {
				return false
			}
		}

		await this.props.loadSettingsAvatar(true)

		const { me } = this.props
		if (
			me &&
			(me.firstname !== firstName ||
				me.lastname !== lastName ||
				me.phone !== phone ||
				me.language !== language ||
				me.locale !== locale ||
				me.settings !== settings)
		) {
			const profileData: UserProfile = Object.freeze({
				...me,
				lastname: lastName || undefined,
				firstname: firstName || undefined,
				phone: phone || undefined,
				language: language || undefined,
				locale: locale || undefined,
				settings: settings || undefined,
			})

			const resp: FinishUpdateMe = await this.props.updateMe(profileData)
			if (resp.serverError) {
				return false
			} else {
				if (me.language !== language) {
					setAppLanguage(language)

					if (!locale) {
						setAppLocale()
					}
				}

				if (me.locale !== locale) {
					setAppLocale(locale)
				}
			}
		}

		return true
	}

	savePasswordSettings = async (): Promise<boolean> => {
		const { t } = this.props
		const { newPassword, currentPassword, confirmPassword } = this.state.settings.password

		if (!newPassword || !currentPassword || !confirmPassword) {
			this.props.notify(t('clientError.fillAll'), 'error')
			return false
		}

		try {
			await this.props.changePassword(currentPassword, newPassword)
		} catch (serverError) {
			this.props.notify(getServerErrorMessage(this.props.t, serverError), 'error')
			return false
		}

		this.setState({
			settings: {
				...this.state.settings,
				password: {
					newPassword: null,
					currentPassword: null,
					confirmPassword: null,
				},
			},
		})

		return true
	}

	getClientValidationErrors() {
		const errors = {}
		const { t } = this.props

		const { passwordSettingsChanged } = this.state
		if (passwordSettingsChanged) {
			if (!this.state.settings.password.newPassword) {
				errors.newPassword = t('application.validation.mandatory')
			}
			if (
				this.state.settings.password.newPassword &&
				this.state.settings.password.newPassword.length > 0 &&
				!this.state.settings.password.currentPassword
			) {
				errors.currentPassword = t('application.validation.mandatory')
			}
			if (!this.state.settings.password.confirmPassword) {
				errors.confirmPassword = t('application.validation.mandatory')
			}

			if (!Validator.password(this.state.settings.password.newPassword)) {
				errors.newPassword = t('application.validation.wrongPasswordFormat')
			}

			if (this.state.settings.password.newPassword != this.state.settings.password.confirmPassword) {
				errors.confirmPassword = t('application.validation.samePassword')
			}
		}

		return errors
	}

	saveEmailsSettings(): boolean {
		return true
	}

	saveDataBoxSettings(): boolean {
		return true
	}

	saveNotificationsSettings = async (): Promise<boolean> => {
		return true
	}

	onFieldChanged = (stateField: string, field: string) => (ev: any, value: any) => {
		this.props.validateForm(this.getClientValidationErrors())
		const changedSettingsField: string = `${stateField}SettingsChanged`
		this.setState({
			settings: {
				...this.state.settings,
				[stateField]: {
					...this.state.settings[stateField],
					[field]: value,
				},
			},
			[changedSettingsField]: true,
		})
	}

	onPasswordSettingsChange = (field: string) => {
		return this.onFieldChanged('password', field)
	}

	onProfileSettingsTextFieldChange = (field: string) => {
		return this.onFieldChanged('profile', field)
	}

	onLanguageChange = (language: ?PortalLanguage) => {
		const fn = this.onProfileSettingsTextFieldChange('language')
		fn(null, language)
	}

	onLocaleChange = (locale: ?string) => {
		const fn = this.onProfileSettingsTextFieldChange('locale')
		fn(null, locale)
	}

	onShowConfirmationChange = (checked: boolean) => {
		this.setState({
			settings: {
				...this.state.settings,
				profile: {
					...this.state.settings.profile,
					settings: {
						...this.state.settings.profile.settings,
						displayAccountingDocumentPreviewBeforeProcessing: checked,
					},
				},
			},
			profileSettingsChanged: true,
		})
	}

	onGenerateDailyDigestEmailChange = (checked: boolean) => {
		this.setState({
			settings: {
				...this.state.settings,
				profile: {
					...this.state.settings.profile,
					settings: {
						...this.state.settings.profile.settings,
						generateDailyDigestEmail: checked,
					},
				},
			},
			profileSettingsChanged: true,
		})
	}

	onNotificationsSettingsChange = (notifications: UserProfileSettingsNotifications) => {
		this.setState({
			settings: {
				...this.state.settings,
				profile: {
					...this.state.settings.profile,
					settings: {
						...this.state.settings.profile.settings,
						notifications: notifications,
					},
				},
			},
			profileSettingsChanged: true,
		})
	}

	onDeleteAvatar = () => {
		if (this.props.avatar || this.state.settings.profile.avatar || this.state.settings.profile.avatarFile) {
			this.changeMoreSettingsField('profile', {
				avatar: null,
				avatarFile: null,
				crop: null,
			})
		}
	}

	onChangeAvatar = (avatar: ?string, avatarFile: ?File, crop: ?CropReturnType) => {
		const params = {
			avatar: avatar || this.state.settings.profile.avatar,
			avatarFile: avatarFile || this.state.settings.profile.avatarFile,
			crop: crop || undefined,
		}
		this.changeMoreSettingsField('profile', params)
	}

	changeMoreSettingsField(stateField: string, fields: Object) {
		const changedSettingsField: string = `${stateField}SettingsChanged`
		this.setState({
			settings: {
				...this.state.settings,
				[stateField]: Object.freeze({
					...this.state.settings[stateField],
					...fields,
				}),
			},
			[changedSettingsField]: true,
		})
	}

	handleTabChange = (url: string) => {
		this.props.history.push(url, {
			referer: this.props.history.location.state.referer,
		})
	}

	isValid = (): boolean => {
		let profile = this.state.settings.profile

		const isPhoneValid =
			(profile.phone && Validator.phone(profile.phone)) ||
			profile.phone === undefined ||
			profile.phone === null ||
			profile.phone === ''

		const errors = this.getClientValidationErrors()
		const hasErrors = Object.keys(errors).length > 0

		return !!(profile.firstName || profile.lastName) && isPhoneValid && !hasErrors
	}

	getSaveButtonWithTooltip = (hasChanges: boolean, isValid: boolean): React$Element<any> => {
		const { t } = this.props

		const saveButton = (
			<Button
				onClick={this.props.validationSubmit(this.saveSettings, undefined, this.setSaved)}
				disabled={!(hasChanges && isValid)}
				labelText={t(`user.settings.${!(hasChanges && isValid) ? 'saved' : 'save'}`)}
				autoTestId="user-settings-save"
			/>
		)

		if (!isValid && hasChanges) {
			return <Tooltip label={t('templates.cantSaveErrors')}>{saveButton}</Tooltip>
		}
		if (!hasChanges) {
			return <Tooltip label={t('templates.cantSaveNoChanges')}>{saveButton}</Tooltip>
		}
		return saveButton
	}

	renderTabs() {
		const { t, canSeeNonDemoFeatures } = this.props
		const initialSelectedIndex = Object.keys(TABS).findIndex((item: string) => item === this.props.section)

		return (
			<Tabs autoTestId="user-settings-tabs" onChange={this.handleTabChange} initialSelectedIndex={initialSelectedIndex}>
				{Object.entries(TABS).map(([section, url]: any) => {
					if (!canSeeNonDemoFeatures && section === 'password') {
						return null
					}
					return <Tab key={section} value={url()} label={t(`user.settings.${section}`)} />
				})}
			</Tabs>
		)
	}

	renderTabContent() {
		this.props.validateForm(this.getClientValidationErrors())
		this.props.validationValue('currentPassword')

		switch (this.props.section) {
			case 'profile':
				return (
					<ProfileSettings
						phone={this.state.settings.profile.phone}
						firstName={this.state.settings.profile.firstName}
						lastName={this.state.settings.profile.lastName}
						avatar={this.state.settings.profile.avatar}
						crop={this.state.settings.profile.crop}
						getOnChange={this.onProfileSettingsTextFieldChange}
						originalAvatar={this.props.originalAvatar}
						onDeleteAvatar={this.onDeleteAvatar}
						onChangeAvatar={this.onChangeAvatar}
					/>
				)
			case 'password':
				return (
					<PasswordSettings
						{...this.state.settings.password}
						validationValue={this.props.validationValue}
						onChange={this.onPasswordSettingsChange}
						validationMessage={this.props.validationMessage}
					/>
				)
			case 'settings':
				return (
					<MainSettings
						locale={this.state.settings.profile.locale}
						language={this.state.settings.profile.language}
						showConfirmation={
							this.state.settings.profile &&
							this.state.settings.profile.settings.displayAccountingDocumentPreviewBeforeProcessing
						}
						generateDailyDigestEmail={
							this.state.settings.profile && this.state.settings.profile.settings.generateDailyDigestEmail
						}
						onLanguageChange={this.onLanguageChange}
						onLocaleChange={this.onLocaleChange}
						onShowConfirmationChange={this.onShowConfirmationChange}
						onGenerateDailyDigestEmailChange={this.onGenerateDailyDigestEmailChange}
						showConfirmationCheckbox={!this.props.isInternal}
					/>
				)
			case 'notifications':
				return (
					<NotificationsSettings
						notifications={this.state.settings.profile.settings.notifications}
						onChange={this.onNotificationsSettingsChange}
					/>
				)
			default:
				return <div>{this.props.section}</div>
		}
	}

	setSaved = () => {
		this.setState({ wasSaved: true })
	}

	render() {
		const { t } = this.props
		const isValid = this.isValid()
		const { profileSettingsChanged, passwordSettingsChanged, emailSettingsChanged, dataBoxSettingsChanged } = this.state
		const hasChanges =
			profileSettingsChanged || emailSettingsChanged || dataBoxSettingsChanged || passwordSettingsChanged

		return (
			<PopupSection open onRequestClose={this.close}>
				<Helmet>
					<title>{this.props.t('user.settings.link')}</title>
				</Helmet>
				<div className={styles.container}>
					<div className={styles.header}>
						<div className={styles.headings}>
							<h1 className={styles.h1}>{t('user.settings.link')}</h1>
						</div>
						<div className={styles.actions}>{this.getSaveButtonWithTooltip(hasChanges, isValid)}</div>
					</div>
					<div className={styles.tabs}>{this.renderTabs()}</div>
					<Paper rounded zDepth={3}>
						<div className={styles.content}>{this.renderTabContent()}</div>
					</Paper>
				</div>
			</PopupSection>
		)
	}
}

export default withTranslate(
	withNavigationPermissions(withNotify(withRouter(validate()(UserSettingsPage), { withRef: false }))),
)
