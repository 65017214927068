//@flow
import type { State } from 'types'
import type { CashbotInvoice, CashbotOffer } from '../types'
import { getAccDocCashbotId } from 'modules/accounting-document/selectors'
import storage from 'utils/local-storage'

export function getCashbotInvoiceId(state: State, accDocId: string): ?number {
	return getAccDocCashbotId(state, accDocId)
}

export function getCashbotInvoice(state: State, accDocId: string): ?CashbotInvoice {
	return state.cashbot.data[accDocId] && state.cashbot.data[accDocId].cashbotInvoice
}

export function getCashbotOffers(state: State, accDocId: string): ?Array<CashbotOffer> {
	return state.cashbot.data[accDocId] && state.cashbot.data[accDocId].cashbotOffers
}

export function getIsCashbotOffersLoading(state: State, accDocId: string): boolean {
	return state.cashbot.data[accDocId] && state.cashbot.data[accDocId].isCashbotOffersLoading
}

export function getIsCashbotInvoiceLoading(state: State, accDocId: string): boolean {
	return state.cashbot.data[accDocId] && state.cashbot.data[accDocId].loading
}

export function getCashbotToken(state: State): ?string {
	// TODO-CASHBOT jen pro testování, po nasazení odstranit používání testovacího tokenu z localStorage
	const testToken = storage.get('cashbotTestToken')
	if (testToken != null) {
		return testToken
	} else {
		return state.cashbot.token
	}
}

export function getIsCashbotAuthenticated(state: State): boolean {
	return !!getCashbotToken(state)
}

export function getIsMarkedToBeFinanced(state: State, accDocId: string): boolean {
	return state.cashbot.data[accDocId] && state.cashbot.data[accDocId].isMarkedToBeFinanced
}
