/* @flow */
/** @jsx jsx */

import { Component } from 'react'
import { jsx, keyframes } from '@emotion/core'
import { colors } from 'variables'

const UNIQUE_ID = Math.random().toString()

export type Props = {|
	checked: boolean,
	disabled?: boolean,
	onCheck: boolean => void,
|}

class RoundCheckbox extends Component<Props> {
	handleCheck = () => {
		this.props.onCheck(!this.refs.checkbox.checked)
	}

	render() {
		const styles = getStyles(this.props)

		return (
			<div css={styles.container}>
				<input readOnly ref="checkbox" css={styles.input} id={UNIQUE_ID} checked={this.props.checked} type="checkbox" />
				<label css={styles.circle} htmlFor={UNIQUE_ID} onClick={this.handleCheck} />
				<div css={styles.checkWrapper}>
					<div css={styles.check} />
				</div>
			</div>
		)
	}
}

function getStyles(props: Props) {
	const { disabled, checked } = props
	const size = 24
	const uncheckedColor = '#cfcfcf'
	const checkedColor = disabled ? uncheckedColor : colors.green

	return {
		container: {
			display: 'block',
			position: 'relative',
			overflow: 'hidden',
			borderRadius: size / 2,
			pointerEvents: disabled ? 'none' : 'all',
			height: size,
			width: size,
		},
		input: {
			height: size,
			width: size,
			visibility: 'hidden',
			position: 'absolute',
			padding: 0,
			margin: 0,
		},
		circle: {
			display: 'block',
			position: 'relative',
			overflow: 'hidden',
			padding: 0,
			margin: 0,
			width: size,
			height: size,
			background: colors.transparent,
			borderRadius: size / 2,
			cursor: 'pointer',
			transition: 'all 250ms cubic-bezier(.4,.0,.23,1)',
			border: checked ? `${size / 2}px solid ${checkedColor}` : `1px solid ${uncheckedColor}`,
			animationName: checked ? `${shrinkBounce}` : 'none',
			animationTimingFunction: 'cubic-bezier(.4,.0,.23,1)',
			animationDuration: '200ms',
			'&:hover': {
				borderColor: checkedColor,
			},
		},
		checkWrapper: {
			position: 'absolute',
			display: 'block',
			top: 8,
			left: 7,
			width: 10,
			height: 5,
			transform: 'rotate(-45deg)',
			transformOrigin: '50% 50%',
			pointerEvents: 'none',
			'&:before': {
				content: '""',
				position: 'absolute',
				display: 'block',
				borderLeft: `1px solid ${uncheckedColor}`,
				borderBottom: `1px solid ${uncheckedColor}`,
				transition: 'opacity 50ms lienar',
				opacity: checked ? 0 : 1,
				pointerEvents: 'none',
				top: 0,
				left: 0,
				right: 0,
				bottom: 0,
			},
		},
		check: {
			position: 'absolute',
			display: 'block',
			borderLeft: '1px solid transparent',
			borderBottom: '1px solid transparent',
			animation: checked ? `${check} 200ms 150ms cubic-bezier(.4,.0,.23,1) forwards` : 'none',
			pointerEvents: 'none',
			top: 0,
			left: 0,
			width: 0,
			height: 0,
			zIndex: 20,
		},
	}
}

const shrinkBounce = keyframes`
	0%{
		transform: scale(1);
	}
	33%{
		transform: scale(.75);
	}
	100%{
		transform: scale(1);
	}
`

const check = keyframes`
	0%{
		border-color: #ffffff;
		height: 0;
		width: 0;
	}
	33%{
		border-color: #ffffff;
		height: 5px;
		width: 0;
	}
	100%{
		border-color: #ffffff;
		height: 5px;
		width: 10px;
	}
`

export default RoundCheckbox
