/* @flow */

import React, { Component, type Node } from 'react'
import keycode from 'keycode'
import { autoTestId, type AutoTestProps } from 'utils/tests/autotest'
import type { OptionType, OptionsType } from 'components/select-next'
import memoize from 'memoize-one'
import Select from 'react-select'
import Tooltip from 'components/tooltip'
import DropdownIndicator from 'components/select-next/dropdown-indicator'
import ClearIndicator from 'components/select-next/clear-indicator'
import Option from 'components/select-next/option'
import { colors } from 'variables'

export type ValueType = ?OptionType | string

export type AutoCompleteActionTypes =
	| 'clear'
	| 'create-option'
	| 'deselect-option'
	| 'pop-value'
	| 'remove-value'
	| 'select-option'
	| 'set-value'
	| 'autocomplete-blur'
	| 'input-change'
export type AutoCompleteActionMeta = { action: AutoCompleteActionTypes }

type Props = {|
	...AutoTestProps,
	options: OptionsType,
	error?: Node,
	label?: string,
	autoFocus?: boolean,
	compact?: boolean,
	inline?: boolean,
	autoWidth?: boolean,
	disabled?: boolean,
	placeholder?: string,
	value?: ?string,
	portal?: boolean,
	tabIndex?: number,
	onChange: (ValueType, AutoCompleteActionMeta) => any,
	onType?: (value: string) => void,
|}

const MENU_MIN_WIDTH = 100

class AutoComplete extends Component<Props> {
	components = { DropdownIndicator, ClearIndicator, Option }
	isBlurDisabled: boolean = false
	select = null

	onKeyDown = (event: SyntheticKeyboardEvent<HTMLElement>) => {
		if ('enter' === keycode(event)) this.select && this.select.blur()
	}

	onBlur = (event: SyntheticFocusEvent<HTMLInputElement>) => {
		if (!this.isBlurDisabled && event.target instanceof HTMLInputElement) {
			this.props.onChange(event.target.value, { action: 'autocomplete-blur' })
		}
	}

	onFocus = () => {
		if (this.select && this.props.value) {
			// $FlowFixMe
			this.select.setState({
				inputValue: this.props.value,
			})
		}
	}

	handleMenuClose = () => {
		this.isBlurDisabled = true
		this.select && this.select.blur()
		this.isBlurDisabled = false
	}

	bindSelect = (element: ?HTMLElement) => {
		this.select = element
	}

	getNoOptionsMessage = () => null

	findOptionByValue = memoize((options: OptionsType, value: ?string) => {
		const found = options.find((option: OptionType) => option.value === value)
		return found ? found : value ? { value: value, label: value } : null
	})

	getStyles = memoize(
		(compact?: boolean, inline?: boolean, autoWidth?: boolean, disabled?: boolean, error?: boolean) => {
			const isInvalid = !!error
			const theme = inline ? inlineTheme : normalTheme

			return {
				root: {
					position: 'relative',
					display: inline && autoWidth ? 'inline-flex' : 'block',
					maxWidth: '100%',
				},
				tooltip: {
					maxWidth: '100%',
				},
				label: {
					display: 'block',
					userSelect: 'none',
					color: colors.black,
					whiteSpace: 'nowrap',
					fontSize: 14,
					paddingBottom: inline ? 0 : 7,
					lineHeight: inline ? '24px' : '17px',
				},
				indicatorSeparator: () => ({ display: 'none' }),
				indicatorsContainer: (provided: Object) => ({
					...provided,
					marginRight: inline ? 0 : compact ? 2 : 8,
				}),
				dropdownIndicator: (provided: Object) => ({
					...provided,
					display: inline ? 'none' : provided.display,
					padding: compact ? 0 : provided.padding,
				}),
				clearIndicator: (provided: Object) => ({
					...provided,
					marginRight: compact ? 0 : 4,
				}),
				control: (provided: Object, state: Object) => ({
					...provided,
					...(isInvalid
						? theme.invalid
						: state.isDisabled
						? theme.disabled
						: state.isFocused
						? theme.focused
						: theme.normal),
					minHeight: inline ? 22 : compact ? 32 : 46,
					height: compact ? 32 : provided.height,
					borderRadius: compact || inline ? 2 : 3,
					boxShadow: 'none',
					fontSize: 14,
					cursor: 'text',
					'.select__single-value': {
						color:
							state.isFocused && state.menuIsOpen
								? colors.grey
								: disabled && !inline
								? colors.disabledText
								: colors.black,
						display: state.isFocused && state.menuIsOpen ? 'none' : 'block',
					},
				}),
				valueContainer: (provided: Object) => ({
					...provided,
					paddingTop: 1,
					paddingBottom: 1,
					paddingLeft: inline ? 1 : compact ? 8 : 15,
					paddingRight: inline ? 1 : compact ? 8 : 15,
					flexDirection: autoWidth ? 'row-reverse' : provided.flexDirection,
				}),
				placeholder: (provided: Object, state: Object) => ({
					...provided,
					color: colors.blackFaded40,
					position: autoWidth && !state.hasValue ? 'static' : provided.position,
					transform: autoWidth && !state.hasValue ? 'none' : provided.transform,
					marginLeft: autoWidth ? -4 : provided.marginLeft,
				}),
				input: (provided: Object) => ({
					...provided,
					fontSize: 14,
					color: colors.black,
					padding: inline ? 0 : provided.padding,
					margin: inline ? '0 2px' : provided.margin,
					height: inline ? 20 : provided.height,
					div: {
						overflow: 'hidden !important',
						display: 'block !important',
						verticalAlign: 'middle',
					},
					input: {
						fontFamily: 'inherit',
					},
				}),
				singleValue: (provided: Object) => ({
					...provided,
					position: autoWidth ? 'relative' : provided.position,
					transform: autoWidth ? 'none' : provided.transform,
					marginLeft: autoWidth ? 0 : provided.marginLeft,
					marginRight: autoWidth ? 0 : provided.marginRight,
					textOverflow: autoWidth ? 'clip' : 'ellipsis',
					lineHeight: inline ? '20px' : provided.lineHeight,
					maxWidth: autoWidth ? 'calc(100% - 6px)' : provided.maxWidth,
					left: autoWidth ? -4 : provided.left,
				}),
				menuPortal: (provided: Object) => ({
					...provided,
					zIndex: 2000,
				}),
				menu: (provided: Object) => ({
					...provided,
					borderWidth: 1,
					borderStyle: 'solid',
					borderColor: colors.gray400,
					borderRadius: compact || inline ? 2 : 3,
					boxShadow: `${colors.blackFaded8} 4px 4px 0`,
					width: 'max-content',
					minWidth: autoWidth ? MENU_MIN_WIDTH : Math.max(MENU_MIN_WIDTH, provided.width),
					whiteSpace: autoWidth ? 'nowrap' : provided.whiteSpace,
					overflow: 'hidden',
					maxWidth: 480,
					zIndex: 2000,
				}),
				menuList: (provided: Object) => ({
					...provided,
					paddingTop: 3,
					paddingBottom: 3,
				}),
				noOptionsMessage: (provided: Object) => ({
					...provided,
					fontSize: 14,
					color: colors.grey,
				}),
			}
		},
	)

	onLabelClick = () => void (this.select && this.select.focus && this.select.focus())

	render() {
		const styles = this.getStyles(
			this.props.compact,
			this.props.inline,
			this.props.autoWidth,
			this.props.disabled,
			!!this.props.error,
		)

		return (
			<div style={styles.root} {...autoTestId(this.props.autoTestId)}>
				{this.props.label && (
					<label style={styles.label} onClick={this.onLabelClick}>
						{this.props.label}
					</label>
				)}
				<Tooltip
					inline={!!this.props.inline && !!this.props.autoWidth}
					label={!this.props.disabled ? this.props.error : ''}
					wrapperStyle={styles.tooltip}
				>
					<Select
						isSearchable
						options={this.props.options}
						isDisabled={this.props.disabled}
						autoFocus={this.props.autoFocus}
						placeholder={this.props.placeholder || ''}
						onChange={this.props.onChange}
						components={this.components}
						noOptionsMessage={this.getNoOptionsMessage}
						value={this.findOptionByValue(this.props.options, this.props.value)}
						menuPortalTarget={this.props.portal ? document.getElementById('render-to-layer') : null}
						closeMenuOnScroll={this.props.portal}
						tabIndex={this.props.tabIndex}
						menuShouldScrollIntoView
						classNamePrefix={'select'}
						styles={styles}
						menuShouldBlockScroll
						ref={this.bindSelect}
						onMenuClose={this.handleMenuClose}
						onFocus={this.onFocus}
						onBlur={this.onBlur}
						onKeyDown={this.onKeyDown}
						onInputChange={this.props.onType}
					/>
				</Tooltip>
			</div>
		)
	}
}

const normalTheme = {
	invalid: {
		backgroundColor: colors.redFaded4,
		borderColor: colors.red,
		'&:hover': {
			borderColor: colors.red,
		},
	},
	disabled: {
		backgroundColor: '#f9f9f9',
		borderColor: colors.grayLight200,
	},
	focused: {
		backgroundColor: colors.blue100Faded40,
		borderColor: colors.blue,
		'&:hover': {
			borderColor: colors.blue,
		},
	},
	normal: {
		backgroundColor: colors.white,
		borderColor: colors.gray400,
		'&:hover': {
			borderColor: colors.blackFaded40,
		},
	},
}

const inlineTheme = {
	invalid: {
		backgroundColor: colors.redFaded4,
		borderColor: colors.red,
		'&:hover': {
			borderColor: colors.red,
		},
	},
	disabled: {
		backgroundColor: colors.transparent,
		borderColor: colors.transparent,
	},
	focused: {
		backgroundColor: colors.blue100Faded40,
		borderColor: colors.blue,
		'&:hover': {
			backgroundColor: colors.blue100Faded40,
			borderColor: colors.blue,
		},
	},
	normal: {
		backgroundColor: colors.transparent,
		borderColor: colors.transparent,
		'&:hover': {
			borderColor: colors.blueFaded40,
			backgroundColor: colors.blue100Faded40,
		},
	},
}

export default AutoComplete
