// @flow

import type { Import, AccountingDocumentImportAction } from 'types'

export type State = {
	details: {
		[importId: string]: Import,
	},
}

export const initialState: State = {
	details: {},
}

export default function sequences(state: State = initialState, action: AccountingDocumentImportAction): State {
	switch (action.type) {
		case 'FINISH_CREATING_ACCOUNTING_DOCUMENT_IMPORT':
		case 'FINISH_LOADING_ACCOUNTING_DOCUMENT_IMPORT': {
			const importDetail = action.import
			if (!importDetail || !importDetail.id) {
				return state
			}
			return {
				...state,
				details: {
					...state.details,
					[importDetail.id]: importDetail,
				},
			}
		}
		default:
			return state
	}
}
