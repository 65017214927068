/* @flow */

import React, { PureComponent } from 'react'
import deepEqual from 'deep-equal'
import { parseIntOr } from 'utils/math'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import { ROUNDING_TYPES } from 'types/convertor'
import type { DefaultsType } from 'modules/settings/reducers'
import type {
	AccountingDocumentDefaults,
	AccountingDocumentDirection,
	AccountingDocumentType,
	Currency,
	VatRate,
	AccountingDocumentLanguage,
} from 'types'
import { CurrencySelector, PaymentTypeSelector, TabSwitch, TabSwitches, NumberInput } from 'components'
import SelectNext from 'components/select-next'
import CollapsibleSettings from '../collapsible-settings'
import SaveSettings from '../save-settings'
import styles from '../settings.css'
import type { OptionType } from 'components/select-next'

export type Props = {|
	open: boolean,
	defaults: DefaultsType,
	direction: AccountingDocumentDirection,
	loading: boolean,
	readOnly?: boolean,
	isOrganizationVatFree: boolean,
	vatRates: {
		[direction: AccountingDocumentDirection]: Array<VatRate>,
	},
	onOpen: () => void,
	onClose: () => void,
	loadAccountingDocumentDefaults: (
		accountingDocumentType: ?AccountingDocumentType,
		accountingDocumentDirection: ?AccountingDocumentDirection,
	) => void,
	editAccountingDocumentDefaults: (
		oldDefaults: ?AccountingDocumentDefaults,
		newDefaults: AccountingDocumentDefaults,
		direction: ?AccountingDocumentDirection,
		type: ?AccountingDocumentType,
	) => Promise<any>,
	...WithTranslateProps,
|}

type State = {
	language?: AccountingDocumentLanguage,
	defaults: DefaultsType,
}

class AccountingDocumentDefaultsComponent extends PureComponent<Props, State> {
	savedState: ?State = null
	altered: boolean = false
	loading: boolean = false

	state: State = {
		defaults: { ...this.props.defaults },
		language: 'CS',
	}

	saveState = () => {
		this.savedState = this.state
	}

	restoreSavedState = () => {
		if (this.savedState) {
			this.setState(this.savedState)
		}
		this.resetSavedState()
	}

	resetSavedState = () => {
		this.altered = false
		this.savedState = null
		this.loading = false
	}

	changeSettings = (partialState: Object) => {
		if (!this.altered) {
			this.altered = true
			this.saveState()
		}
		this.setState(partialState, () => {
			deepEqual(this.state, this.savedState) && this.restoreSavedState()
		})
	}

	componentDidMount() {
		const { defaults, direction } = this.props
		const dir: string = direction.toString()

		if (!defaults[dir] || !defaults[dir]['']) {
			this.props.loadAccountingDocumentDefaults(null, direction)
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		if (
			!this.props.defaults[nextProps.direction] ||
			!this.props.defaults[nextProps.direction][''] ||
			nextProps.defaults[nextProps.direction][''] !== this.props.defaults[nextProps.direction]['']
		) {
			this.setState({
				...this.state,
				defaults: { ...nextProps.defaults },
			})
		}
		if (nextProps.loading === false && this.loading) this.resetSavedState()
	}

	changeStateField = (field: string, value: any, direction: ?string, type: ?string) => {
		const directionIndex: string = direction || this.props.direction
		const typeIndex: string = type || ''

		const directionDefaults = this.state.defaults[directionIndex] || {}
		this.changeSettings({
			defaults: {
				...this.state.defaults,
				[directionIndex]: {
					...directionDefaults,
					[typeIndex]: {
						...(directionDefaults[typeIndex] || {}),
						[field]: value,
					},
				},
			},
		})
	}

	saveSettings = async () => {
		const { defaults, direction } = this.props

		const oldDefaults: ?AccountingDocumentDefaults = defaults[direction] && defaults[direction]['']
		const settings = this.state.defaults[direction][''] || {}
		const newDefaults: AccountingDocumentDefaults = Object.freeze({ ...settings })

		this.loading = true
		await this.props.editAccountingDocumentDefaults(oldDefaults, newDefaults, direction)
	}

	changeDueDateInterval = (event: SyntheticInputEvent<HTMLInputElement>, value: ?number) => {
		this.changeStateField('dueDateInterval', parseIntOr(value, null), undefined, undefined)
	}

	onCurrencyChange = (currency: ?Currency) => {
		currency && this.changeStateField('currency', currency.id)
	}

	onPaymentTypeChange = (paymentType: string) => {
		this.changeStateField('paymentType', parseInt(paymentType))
	}

	onVatRateChange = (domesticVatRateId: OptionType) => {
		this.changeStateField('domesticVatRateId', domesticVatRateId ? domesticVatRateId.value : null)
	}

	onRoundingTypeChange = (roundingType: number) => {
		this.changeStateField('roundingType', roundingType)
	}

	onUseRoundingForChange = (useRoundingFor: number) => {
		this.changeStateField('useRoundingFor', useRoundingFor)
	}

	onLanguageChange = (value: ?AccountingDocumentLanguage) => {
		this.changeSettings({ language: value || 'CS' })
	}

	renderBody() {
		const { t, direction, isOrganizationVatFree } = this.props
		const readOnly: boolean = !!this.props.readOnly
		const defaults: ?AccountingDocumentDefaults = this.state.defaults[direction] && this.state.defaults[direction]['']
		const { dueDateInterval, currency, domesticVatRateId, paymentType, roundingType } = defaults || {}
		const showVatRate: boolean = !isOrganizationVatFree
		return (
			<div className={styles.hideOutSection}>
				<div className={styles.halfRow}>
					<NumberInput
						fullWidth
						labelText={t('settings.accountingDocuments.dueDataInterval')}
						value={dueDateInterval}
						onChange={this.changeDueDateInterval}
						disabled={readOnly}
						minValue={0}
						name="due-data"
					/>
				</div>

				<div className={styles.halfRow}>
					<CurrencySelector
						labelText={t('settings.accountingDocuments.currency')}
						onChange={this.onCurrencyChange}
						value={currency}
						disabled={readOnly}
						fullWidth
						portal
					/>
				</div>

				<div className={styles.halfRow}>
					<PaymentTypeSelector
						label={t('settings.accountingDocuments.paymentType')}
						value={paymentType && paymentType.toString()}
						onChange={this.onPaymentTypeChange}
						portal
					/>
				</div>

				{showVatRate && (
					<div className={styles.halfRow}>
						<SelectNext
							label={t('settings.accountingDocuments.vatRate')}
							autoTestId="settings-accounting-documents-vatRate"
							value={domesticVatRateId}
							onChange={this.onVatRateChange}
							portal
							isClearable
							options={
								this.props.vatRates[this.props.direction] &&
								this.props.vatRates[this.props.direction].map((vatRate: VatRate) => ({
									value: vatRate.id,
									label: vatRate.text,
								}))
							}
						/>
					</div>
				)}

				<div className={styles.row}>
					<TabSwitches
						autoTestId="settings-defaults-rounding-tabs"
						label={t('settings.accountingDocuments.roundingType')}
						onChange={this.onRoundingTypeChange}
						value={roundingType}
					>
						{Object.entries(ROUNDING_TYPES).map(([type, name]: any) => (
							<TabSwitch
								key={type}
								label={t(`invoice.settings.booking.roundingTypes.${name}`)}
								value={parseInt(type)}
							/>
						))}
					</TabSwitches>
				</div>

				<div style={{ marginTop: 20 }}>
					<SaveSettings
						visible={this.altered}
						saving={this.loading}
						onSave={this.saveSettings}
						onRestore={this.restoreSavedState}
					/>
				</div>
			</div>
		)
	}

	render() {
		return (
			<CollapsibleSettings
				open={this.props.open}
				onOpen={this.props.onOpen}
				onClose={this.props.onClose}
				title={
					'issued' === this.props.direction
						? this.props.t('settings.accountingDocuments.issuedDocumentsDefaults')
						: this.props.t('settings.accountingDocuments.receivedDocumentsDefaults')
				}
			>
				{this.renderBody()}
			</CollapsibleSettings>
		)
	}
}

export default withTranslate(AccountingDocumentDefaultsComponent)
