/* @flow */
/** @jsx jsx */

import { jsx } from '@emotion/core'
import { withTranslate, type WithTranslateProps } from 'wrappers'
import Paper from 'components/Paper'
import { colors } from 'variables'

type Props = {|
	...WithTranslateProps,
	severity?: 'info' | 'warn' | 'error',
	children: React$Node,
|}

function Issue({ severity, children }: Props) {
	return (
		<Paper zDepth={2} css={style.issue}>
			<div css={style[severity || 'warn']}>
				<div css={style.content}>{children}</div>
			</div>
		</Paper>
	)
}
const style = {
	issue: {
		display: 'block',
		position: 'relative',
		marginBottom: 5,
		marginTop: 5,
	},
	error: {
		backgroundColor: colors.redFaded60,
	},
	info: {
		backgroundColor: colors.blue300,
	},
	warn: {
		backgroundColor: colors.yellow300,
	},
	content: {
		padding: '7px 10px',
		color: colors.black,
		lineHeight: '20px',
		overflow: 'hidden',
		wordWrap: 'break-word',
		fontSize: 16,
	},
}

export default withTranslate(Issue)
